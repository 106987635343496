import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  Typography,
  Icon,
  Card,
  Avatar,
  Row,
  message,
} from 'antd';

const { Meta } = Card;
const { Text, Title, Paragraph } = Typography;

/**
 * 
 * @param {object} jobapp Includes jobapplication information 
 * @param {function} publish sends jobapp info for update
 * @param {function} handleChange updates local jobapp info prev to publish
 * @param {array} countries array of strings containing country names
 * @returns {React.ReactNode}
 */
function PostJobOnFreePortal({ jobapp, publish, handleChange, provider, providerName, modalContent, countries }) {
  const [modalVisible, setModalVisible] = useState(false);

  const sendJobData = () => {
    if (!checkInfo()) return;
    const { job_portals } = jobapp;
    handleChange('job_portals')(job_portals);
    publish(false);
    job_portals[provider] = true;
  }

  const checkInfo = () => {
    if (!jobapp.description || jobapp.description.length === 0){
      message.error('Por favor, agrega una descripción al campo "sobre el cargo" antes de publicar en un portal de empleo');
      return false;
      } else {
        return true;
      }
    }

  const published = Boolean(jobapp.job_portals[provider]);
  const countryAvailable = Boolean(!countries || countries.includes(jobapp.location_country))
  return (
    <>
     <Card
      style={{ width: '100%', border: '1px solid #d9d9d9' }}
      actions={[
        published ? 
        <Button type="link"  onClick={() => setModalVisible(true)}>
          <Icon style={{ color: "#47E197" }} type="check-circle" /> Publicado
        </Button> : 
        <Button type="link" onClick={() => countryAvailable && setModalVisible(true)} >
          <Icon style={{ color: "#5e5e5e" }} type="notification" /> 
          {countryAvailable ? "Publicar" : "No disponible en el país"}
        </Button>
      ]}
    >
      <Meta
        avatar={<Avatar src={`https://genoma-assets.s3.us-east-2.amazonaws.com/jobportals/${provider}.png`} size="large" />}
        title={providerName}
        description="Gratis"
      />
    </Card>
    <Modal 
      visible={modalVisible} 
      okText="Publicar"
      cancelText="Cancelar"
      onOk={() => {
        sendJobData();
        setModalVisible(false);
      }}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      {modalContent}
    </Modal>
    </>
  )
}

PostJobOnFreePortal.propTypes = {
  jobapp: PropTypes.object.isRequired,
  publish: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  provider: PropTypes.string.isRequired,
  providerName: PropTypes.string.isRequired,
  modalContent: PropTypes.node.isRequired,
  isBeta: PropTypes.bool,
  isAvailable: PropTypes.bool,
  countries: PropTypes.arrayOf(PropTypes.string)
}

export default PostJobOnFreePortal;
