import {
    AUTH_LOGIN_USER_REQUEST,
    AUTH_LOGIN_USER_SUCCESS,
    AUTH_LOGIN_USER_FAILURE,
    AUTH_FIRST_LOGIN_BUSINESSUSER_SUCCESS,
    AUTH_SOCIAL_LOGIN_USER_REQUEST,
    AUTH_SOCIAL_LOGIN_USER_SUCCESS,
    AUTH_SOCIAL_LOGIN_USER_FAILURE,
    AUTH_LOGOUT_USER,
    AUTH_SIGNUP_USER_REQUEST,
    AUTH_SIGNUP_USER_FAILURE,
    AUTH_SIGNUP_USER_SUCCESS,
    AUTH_SIGNUP_USER_ALREADY_EXISTS,
    AUTH_GO_BACK,
    LOGO_UPDATE,
    LOGO_RESET,
    UPDATE_EMAIL,
    UPDATE_TOS,
    UPDATE_AUTH_DATA,
    UPDATE_NOTIFICATION_COUNT,
} from '../constants';


const initialState = {
    token: null,
    userName: null,
    userAvatar: 'GW',
    isAuthenticated: false,
    isAuthenticating: false,
    isCreatingAccount: false,
    isAccountCreated: false,
    statusText: null,
    userID: null,
    isDNIAlreadyUsed: false,
    imgUser: '',
    logoURL: '/media/logos/genoma-logo.png',
    acceptedTos: null,
    mfaHash: null,
    addOns: [],
    contract: null,
    socialAuth: {
        email: null,
        provider: null,
        jobAppId: null, // Para saber a que JobApplication redirigir, cuando sea necesario
        tokenObj: null,
        newCandidate: null, // Para saber si existía el PersonalUser desde antes
    },
    errors: null,
    socialErrors: null,
    businessPermissions: {}, // BusinessPermissions del usuario
    currentBusiness: {}, // Business activo

    notificationCount: 0,
};

export default function authReducer(state = initialState, action) {
    switch (action.type) {
        case AUTH_GO_BACK:
            return initialState;

        case AUTH_LOGIN_USER_REQUEST:
            return Object.assign({}, state, {
                isAuthenticating: true,
                statusText: null,
                // no se cambia nada respecto a objeto socialAuth en este caso
            });

        case AUTH_SOCIAL_LOGIN_USER_REQUEST:
            return Object.assign({}, state, {
                isAuthenticating: true,
                statusText: null,
                socialAuth: {
                    email: action.payload.email,
                    provider: action.payload.provider,
                    jobAppId: action.payload.jobappid,
                    tokenObj: action.payload.token,
                    newCandidate: action.payload.newCandidate,
                },
            });

        case AUTH_LOGIN_USER_SUCCESS:
            return Object.assign({}, state, {
                isAuthenticating: false,
                isAuthenticated: true,
                business: action.payload.user.business,
                token: action.payload.token,
                userName: action.payload.user.email,
                userID: action.payload.user.id,
                name: action.payload.user.name,
                userAvatar: action.payload.user.avatar_initials,
                confirmedEmail: action.payload.user.confirmed_email,
                userType: action.payload.user.user_type,
                imgUser: action.payload.user.img_user,
                // TODO: REMOVE THIS FIELD FROM REDUX
                businessOwner: action.payload.user.business_owner,
                // TODO: REMOVE THIS FIELD FROM REDUX
                businessOwnerEmail: action.payload.user.business_owner_email,
                jobPortalIntegrations: action.payload.user.job_portal_integrations,
                statusText: 'Has iniciado sesión satisfactoriamente.',
                acceptedTos: action.payload.user.accepted_tos,
                mfaHash: action.payload.user.mfa_hash,
                contract: action.payload.user.active_contract,
                addOns: action.payload.user.active_contract ? action.payload.user.active_contract.addons : [],
                businessPermissions: action.payload.user.businesspermission_set,
                currentBusiness: action.payload.user.business,
                errors: null,
                businessPermissions: action.payload.user.businesspermission_set,
                currentBusiness: action.payload.user.business,
            });

        case AUTH_FIRST_LOGIN_BUSINESSUSER_SUCCESS:
            return Object.assign({}, state, {
                name: action.payload.name,
            });

        case AUTH_SOCIAL_LOGIN_USER_SUCCESS:
            return Object.assign({}, state, {
                isAuthenticating: false,
                isAuthenticated: true,
                token: action.payload.token,
                userName: action.payload.user.email,
                userID: action.payload.user.id,
                userAvatar: action.payload.user.avatar_initials,
                confirmedEmail: action.payload.user.confirmed_email,
                userType: action.payload.user.user_type,
                imgUser: action.payload.user.img_user,
                businessOwner: action.payload.user.business_owner,
                businessOwnerEmail: action.payload.user.business_owner_email,
                jobPortalIntegrations: action.payload.user.job_portal_integrations,
                statusText: 'Has iniciado sesión satisfactoriamente.',
                acceptedTos: action.payload.user.accepted_tos,
                addOns: action.payload.user.active_contract ? action.payload.user.active_contract.addons : [],
                socialAuth: {
                    ...action.payload.socialAuth,
                },
                socialErrors: null,
            });

        case AUTH_LOGIN_USER_FAILURE:
            return Object.assign({}, state, {
                isAuthenticating: false,
                isAuthenticated: false,
                token: null,
                userName: state.userName,
                errors: action.payload.errors
            });

        case AUTH_SOCIAL_LOGIN_USER_FAILURE:
            return Object.assign({}, state, {
                isAuthenticating: false,
                isAuthenticated: false,
                token: null,
                userName: state.userName,
                socialErrors: action.payload.errors
            });

        case AUTH_SIGNUP_USER_ALREADY_EXISTS:
            return Object.assign({}, state, {
                isCreatingAccount: false,
                isAccountCreated: false,
                token: null,
                userName: action.payload.censoredmail,
                userID: action.payload.userid,
                isDNIAlreadyUsed: true,
                errors: action.payload.errors
            });

        case AUTH_LOGOUT_USER:
            return Object.assign({}, state, {
                isAuthenticated: false,
                token: null,
                userName: null,
                userType: null,
                errors: action.payload.errors
            });

        case AUTH_SIGNUP_USER_REQUEST:
            return Object.assign({}, state, {
                isCreatingAccount: true,
                statusText: null
            });

        case AUTH_SIGNUP_USER_SUCCESS:
            return Object.assign({}, state, {
                isCreatingAccount: false,
                isAccountCreated: true,
                statusText: 'Cuenta Creada.'
            });

        case AUTH_SIGNUP_USER_FAILURE:
            return Object.assign({}, state, {
                isCreatingAccount: false,
                isAccountCreated: false,
                token: null,
                userName: null,
                errors: action.payload.errors
            });

        case LOGO_UPDATE:
            return Object.assign({}, state, {
                logoURL: action.payload.logo_url,
            });
        case LOGO_RESET:
            return Object.assign({}, state, {
                logoURL: '/media/logos/genoma-logo.png'
            });
        case UPDATE_EMAIL:
            return Object.assign({}, state, {
                userName: action.payload.updatedEmail,
            });

        case UPDATE_TOS:
            return Object.assign({}, state, {
                acceptedTos: action.payload.acceptedTos,
            });

        case UPDATE_AUTH_DATA:
            return Object.assign({}, state, {
                [action.payload.keyName]: action.payload.value,
            });

        // Update the notification count using a value or a function
        case UPDATE_NOTIFICATION_COUNT:
            if (typeof action.payload === 'function') {
                const callbackFun = action.payload;
                const newCount = callbackFun(state.notificationCount);
                return Object.assign({}, state, {
                    notificationCount: newCount >= 0 ? newCount : 0,
                });
            }
            return Object.assign({}, state, {
                notificationCount: action.payload,
            });

        default:
            return state;
    }
}