import React, { useState } from 'react';
import { ResponsiveStream } from '@nivo/stream'

export default function GenomaScoreStream({ data, keys, labels }) {
  return (
    <ResponsiveStream
      data={data}
      keys={keys}
      margin={{ top: 30, right: 50, bottom: 50, left: 50 }}
      stack={false}
      curve='basis'
      axisBottom={null}
      axisLeft={{
        orient: 'natural',
        tickSize: 5,
        tickRotation: 0,
        legend: '',
        legendOffset: 0
      }}
      label={labels}
      maxValue={'auto'}
      minValue={0}
      enableGridX={false}
      enableGridY={true}
      offsetType="none"
      colors={['#096DD9', '#002766']}
      fillOpacity={0.85}
      borderColor={{ theme: 'background' }}
      legends={[
        {
          anchor: 'top-left',
          direction: 'row',
          translateX: -30,
          translateY: -20,
          itemWidth: 130,
          itemHeight: 0,
          itemTextColor: '#5E5E5E',
          symbolSize: 16,
          symbolShape: 'square',
        }
      ]}
    />
  )
}

