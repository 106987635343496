import React, { createContext, useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { v4 as uuidv4 } from 'uuid';
import { Modal } from 'antd';
import { textLevels } from '../utils';

import * as actionCreators from '../../../../actions/data';

export const customTestContext = createContext();

const situationalBaseQuestions = Array.from({ length: 3 }, () => ({ 
  component: "page",
  label: "",
  description: "",
  image: "",
  _uid: uuidv4(),
  fields: [{
    component: "SJT_GW",
    label: "¿Cuál crees que es la mejor y la peor forma de abordar esta situación?",
    type: "SJT_GW",
    _uid: uuidv4(),
    required: true,
    options: [
      { component: "option", label: "", _uid: uuidv4() },
      { component: "option", label: "", _uid: uuidv4() },
      { component: "option", label: "", _uid: uuidv4() },
      { component: "option", label: "", _uid: uuidv4() },
    ]
  }]
}));

const instructionBase = [{
  _uid: uuidv4(),
  label: 'Instrucciones',
  fields: [
    {
      _uid: uuidv4(),
      label: 'Instrucciones',
      component: 'read_only',
      paragraphs: [],
    },
  ],
  component: 'page',
}]

const TestProvider = (props) => {
  const [_, cuteType, action, cuteId] = window.location.pathname.split('/');

  const [multiple, setMultiple] = useState(null);
  const [ranges, setRanges] = useState(Array.from({ length: 5 }, () => 0));

  const [segments, setSegments] = useState(Array.from({ length: 5 }, () => ({ min: 0, max: 0, refRange: 0 })));

  const [thumbs, setThumbs] = useState(Array.from({ length: 5 }, () => 0));

  const [showStep1, setShowStep1] = useState(true);
  const [showStep2, setShowStep2] = useState(false);
  const [showStep3, setShowStep3] = useState(false);
  const [showStep4, setShowStep4] = useState(false);
  const [stepCurrent, setStepCurrent] = useState(0);
  const [questionsContext, setQuestionsContext] = useState( cuteType === 'customtest' ?
    [] : [...situationalBaseQuestions]
  );
  const [questionNumber, setQuestionNumber] = useState(0);
  const [globalTimeContext, setGlobalTimeContext] = useState(null);
  const [levels, setLevels] = useState(null);
  const [editLevel, setEditLevel] = useState({
    segments: [],
    levelReference: null,
    totalQuestion: null,
  });
  const [showDescriptions, setShowDescriptions] = useState(true);
  const [textDescriptions, setTextDescription] = useState(false);
  const [colorDescriptions, setColorDescriptions] = useState([
    { background: '', border: '' },
  ]);
  const [configStep1, setConfigStep1] = useState({
    title: '',
    description: '',
    skills: [],
    inputValue: '',
    inputVisible: false,
  });
  const [configStep2, setConfigStep2] = useState({
    candidateTitle: '',
    questions: {
      data: cuteType === 'customtest' ? 
        [...instructionBase] :
        [...instructionBase, ...situationalBaseQuestions],
      global_time: null,
    },
  });

  const [randomQuestion, setRandomQuestion] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [shareWith, setShareWith] = useState([]);
  const [createdBy, setCreatedBy] = useState('');
  const [scoreConfig, setScoreConfig] = useState(cuteType === 'customtest' ? {
    type: 'ALTERNATIVES',
    segments: [],
    correct_answers: {},
    questions_to_evaluate: null,
  } : {
    type: 'SJT_GW',
    correct_answers: situationalBaseQuestions.reduce((acc, curr) => {
      acc[curr.fields[0]._uid] = {
        type: 'SJT_GW',
        value: curr.fields[0].options.reduce((acc2, option) => {
          acc2[option._uid] = 0
          return acc2;
        }, {})
      } 
      return acc;
    }, {}),
    segments: []
  });
  const [correct_answers, setCorrectAnswer] = useState([]);
  const [radioTimeButton, setRadioTimeButton] = useState(false);

  const questionPerSegments = cuteType === 'situationals' ? Math.floor(100 / levels) : Math.floor(questionNumber / levels);

  let body = {
    title: configStep1.title,
    description: configStep1.description,
    candidate_path_title: configStep2.candidateTitle,
    questions: configStep2.questions,
    randomize_questions: false,
    public: false,
    shared_with: [props.business],
    created_by: props.business,
    scores_config: scoreConfig,
  };

  if (cuteType === 'situationals') {
    body.cute_type = 'KNOWLEDGE';
    body.questions.cute_type = 'SITUATIONAL';
  }

  const postCustomTest = () => {
    let endpoint = '/api/v1/gamesandtests/customtests/';
    try {
      props.actions
        .fetchAndReturn(props.token, endpoint, 'post', body)
        .then((response) => {
          if (response.status === 201) {
            Modal.success({
              title: '¡Prueba creada!',
              content: 'Se creó la prueba correctamente',
              okText: 'Aceptar',
              onOk: props.dispatch(push(`/${cuteType}`)),
              centered: true,
            });
          }
        });
    } catch (error) {
      Modal.error({
        title: 'Hubo un error la creación de la prueba fallo',
        okText: 'Aceptar',
        onOk: props.dispatch(push(`/${cuteType}`)),
        centered: true,
      });
    }
  };

  const updateCustomTest = (id) => {
    const body = {
      title: configStep1.title,
      description: configStep1.description,
      candidate_path_title: configStep2.candidateTitle,
      questions: configStep2.questions,
      randomize_questions: false,
      public: false,
      shared_with: [props.business],
      created_by: props.business,
      scores_config: scoreConfig,
    };
    let endpoint = `/api/v1/gamesandtests/customtests/${id}/`;
    try {
      props.actions
        .fetchAndReturn(props.token, endpoint, 'put', body)
        .then((response) => {
          if (response.status === 200) {
            Modal.success({
              title: '¡Prueba guardada!',
              content:
                'Puedes continuar editando tu prueba cuando lo necesites.',
              okText: 'Aceptar',
              onOk: props.dispatch(push(`/${cuteType}`)),
              centered: true,
            });
          }
        });
    } catch (error) {
      Modal.error({
        title: 'Hubo un error la edicion de la prueba fallo',
        okText: 'Aceptar',
        onOk: props.dispatch(push(`/${cuteType}`)),
        centered: true,
      });
    }
  };
  const getSkills = (questions) => {
    const skills = [];
    questions.data.slice(1).map((page) => {
      page.fields.map((field) => {
        if (!skills.includes(field.category) && field.category !== '' && field.category !== undefined) {
          skills.push(field.category);
        }
      });
    });
    return skills;
  };
  const getCustomTest = () => {
    let endpoint = `/api/v1/gamesandtests/customtests/${cuteId}/`;
    props.actions.fetchAndReturn(props.token, endpoint).then((response) => {
      if (response.status === 200) {
        const {
          title,
          description,
          questions,
          candidate_path_title,
          scores_config,
        } = response.body;
        setLevels(scores_config.segments.length);
        setEditLevel({
          segments: scores_config.segments,
          levelReference: scores_config.segments.length,
          totalQuestion: scores_config.questions_to_evaluate,
        });
        setConfigStep1({
          ...configStep1,
          title: title,
          description: description,
          skills: getSkills(questions),
        });
        setConfigStep2({
          ...configStep2,
          candidateTitle: candidate_path_title,
          questions: questions,
        });
        setQuestionsContext(questions.data.slice(1));
        setScoreConfig(scores_config);

        setQuestionNumber(scores_config.questions_to_evaluate);
        setSegments(scores_config.segments.map(x => ({...x, refRange: 100})));
        const newThumbs = scores_config.segments.map((segment) => segment.max - 1);
        setThumbs(newThumbs);
        const newRanges = scores_config.segments.map((segment, index) => {
          if (index === 0) {
            return segment.max - 1
          } else {
            return segment.max - segment.min
          }
        });
        setRanges(newRanges);
        if (questions.global_time !== null) {
          setRadioTimeButton(true);
          setGlobalTimeContext(questions.global_time / 60);
        }
      }
    });
  };

  const getQuestionMaxScore = (correctAnswers) => {
    const arr = Object.values(correctAnswers);
    const min = Math.min(...arr);
    const max = Math.max(...arr);

    return max - min;
  };

  const submitStep = (step) => {
    if (step === 1 && globalTimeContext !== null) {
      setConfigStep2({
        ...configStep2,
        questions: {
          data: [...configStep2.questions.data],
          global_time: globalTimeContext * 60,
        },
      });
    } else if (step === 1 && globalTimeContext === null) {
      setConfigStep2({
        ...configStep2,
        questions: {
          data: [...configStep2.questions.data],
          global_time: globalTimeContext,
        },
      });
    }
    if (step === 2) {
      configStep2.questions.data.splice(1);
      questionsContext.forEach((page) => {
        configStep2.questions.data.push(page);
      });
      setScoreConfig({
        ...scoreConfig,
        questions_to_evaluate: questionNumber,
      });
      if (cuteType === 'situationals') {
        let totalMaxScore = 0;
        Object.keys(scoreConfig.correct_answers).forEach((key) => {
          Object.keys(scoreConfig.correct_answers[key].value).forEach((key2) => {
            scoreConfig.correct_answers[key].value[key2] = Number(scoreConfig.correct_answers[key].value[key2]) || 0;
          });
          const maxScore =  getQuestionMaxScore(scoreConfig.correct_answers[key].value)
          scoreConfig.correct_answers[key].max_score = maxScore;
          totalMaxScore += maxScore;
        });
        setScoreConfig({
          ...scoreConfig,
          total_max_score: totalMaxScore * 2,
        });
      }
    }
  };

  const changeSegments = (newSegments) => {
    const newScoresConfig = structuredClone(scoreConfig);
    newScoresConfig.segments = [...newSegments];
    newScoresConfig.segments.forEach((segment, index) => {
      segment.label = editLevel.segments[index]?.label || textLevels[levels][index].title;
      segment.description = editLevel.segments[index]?.description || textLevels[levels][index].text;
      segment.refRange = undefined;
    });
    setScoreConfig(
      newScoresConfig
    );
  };

  useEffect(() => {
    const percentPerSegment = Math.floor(100 / levels);
    setMultiple(Math.floor(percentPerSegment / questionPerSegments));
    setColorDescriptions(textLevels[levels]);
    for (let i = 0; i < levels; i++) {
      if (i === 0) {
        segments[i] = { min: 0, max: questionPerSegments, refRange: percentPerSegment };
      } 
      else if (i === levels - 1) {
        segments[i] = { min: segments[i - 1].max + 1, max: cuteType === 'situationals' ? 100 : questionNumber, refRange: 100 };
      } else {
        if (questionPerSegments === 1) {
          segments[i] = { min: segments[i - 1].max + 1, max: segments[i - 1].max + 1 + questionPerSegments, refRange: percentPerSegment };
        } else {
          segments[i] = { min: segments[i - 1].max + 1, max: segments[i - 1].max + questionPerSegments, refRange: percentPerSegment };
        }
      }
      thumbs[i] = segments[i].max;
      ranges[i] = (segments[i].max - (i===0 ? 1 : segments[i].min))/questionNumber * 100;
      setRanges([...ranges]);
      setSegments([...segments]);
      setThumbs([...thumbs]);

    }
    if (editLevel.levelReference !== levels) {
      setScoreConfig({
        ...scoreConfig,
        questions_to_evaluate: questionNumber,
        segments: Array.from({ length: levels }, (_, index) => index)
          .map((level) => ( 
            {
              min: segments[level].min,
              max: segments[level].max,
              label: textLevels[levels][level].title,
              description: textLevels[levels][level].text,
            }
          ))
      });
    }
  }, [levels, questionNumber]);

  useEffect(() => {
    let questions = 0;
    questionsContext.forEach((page) => {
      page.fields.forEach((field) => {
        questions += 1;
      });
    });
    setQuestionNumber(questions);
  }, [questionsContext]);


  useEffect(() => {
    if (action !== 'new') {
      getCustomTest();
    } else {
      setLevels(2);
    }
  }, []);

  const getSegments = (idx, side, n) => {
    if (idx !== undefined) {
      return scoreConfig.segments[idx][side] = segments[idx][side]
    }
  };

  return (
    <customTestContext.Provider
      value={{
        multiple,
        setMultiple,
        ranges,
        setRanges,
        segments,
        setSegments,
        thumbs,
        setThumbs,
        showStep1,
        setShowStep1,
        showStep2,
        setShowStep2,
        showStep3,
        setShowStep3,
        showStep4,
        setShowStep4,
        stepCurrent,
        setStepCurrent,
        questionsContext,
        setQuestionsContext,
        questionNumber,
        setQuestionNumber,
        globalTimeContext,
        setGlobalTimeContext,
        levels,
        setLevels,
        showDescriptions,
        setShowDescriptions,
        textDescriptions,
        setTextDescription,
        colorDescriptions,
        setColorDescriptions,
        configStep1,
        setConfigStep1,
        configStep2,
        setConfigStep2,
        randomQuestion,
        setRandomQuestion,
        isPublic,
        setIsPublic,
        shareWith,
        setShareWith,
        createdBy,
        setCreatedBy,
        scoreConfig,
        setScoreConfig,
        radioTimeButton,
        setRadioTimeButton,
        correct_answers,
        setCorrectAnswer,
        submitStep,
        postCustomTest,
        updateCustomTest,
        changeSegments,
        action,
        cuteId,
        cuteType,
      }}
    >
      {props.children}
    </customTestContext.Provider>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    business: state.auth.business.id,
    state: state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestProvider);
