import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";

import {
  Button,
  Checkbox,
  Col,
  Icon,
  Input,
  message,
  Modal,
  Row,
  Select,
  Typography,
} from 'antd';

import {
  businessPermissionName,
  businessPermissionStatusName,
  validateEmailFormat
} from './utils';

import i18n from '../../../i18n';

const { Text, Title } = Typography;
const { Option } = Select;

const NewCollaborator = ({ removeCollaborator, roles, ...props }) => {
  const { index, handleEmailChange, handlePermissionsChange, handleRoleChange, email, userPermissions, userRole, errors } = props;
  const invalidEmail = errors.emailInputs[`${index}`] === 'invalidEmail';
  const [detailVisible, setDetailVisible] = useState(false);

  const currentRole = roles.find(obj => obj.id == userRole);
  const currentKey = userRole ? Object.keys(currentRole).filter(key => key.includes('can_')) : [];

  const DetailRole = () => {
    return (
      <div style={{ marginBottom: 15 }}>
        <div style={{ marginBottom: 10, marginTop: 10 }}>
          <Text style={{ color: '#454545', fontSize: 16, weigth: 700 }}>Permisos Independientes</Text>
        </div>

        <div>
          <Checkbox
            checked={userPermissions.includes('can_view_all_jobapps')}
            onChange={(e) => handlePermissionsChange('can_view_all_jobapps', index)}
          >
            Acceso a todos los procesos
          </Checkbox>
        </div>
        <div style={{ marginBottom: 10, marginTop: 15 }}>
          <Text style={{ color: '#454545', fontSize: 16, weigth: 700 }}>Permisos del Rol</Text>
        </div>
        <div>
          {
            currentKey.map(elemKey => {
              return (
                <div style={{ marginBottom: 5 }} key={elemKey}>

                  <Checkbox
                    checked={currentRole[elemKey]}
                    disabled={true}
                  >
                    {i18n.t(elemKey, {ns: 'businessUser'})}
                  </Checkbox>
                </div>
              )
            })
          }

        </div>
      </div>
    )
  }

  const sortFixedRoles = (roles) => {
    const fixedOrder = ['super admin', 'admin', 'gestor', 'evaluador', 'observador'];
    roles.sort((a, b) => fixedOrder.indexOf(a.name.toLowerCase()) - fixedOrder.indexOf(b.name.toLowerCase()));
    return roles;
  };

  return (
    // TODO: AGREGAR BASURERITO?
    <>
      <Row
        type="flex"
        justify="space-between"
        gutter={[16, 48]}
      >
        <Col span={8}>
          <Input
            allowClear
            prefix={<Icon type="mail" style={{ color: invalidEmail ? "red" : "rgba(0,0,0,.25)" }} />}
            placeholder="Correo eléctrónico"
            type="email"
            value={email}
            style={{
              maxHeight: 100,
              borderRadius: "2px",
            }}
            onChange={(event) => handleEmailChange(event, index)}
          />
          {invalidEmail && <span style={{ color: "red" }}>Formato de correo inválido</span>}
        </Col>
        <Col span={16} style={{ borderRadius: "2px" }}>
          <Row type='flex' align='middle' justify='space-between'>
            <Select
              allowClear
              style={{ width: "90%" }}
              placeholder="Seleccionar permisos"
              onChange={
                (roleId) => handleRoleChange(roleId, index)
              }
              //value={userPermissions}
              value={userRole}
              notFoundContent="Permiso no existe"
            >
              {
                sortFixedRoles(roles).map(obj => (
                  <Option key={obj.id} value={obj.id}>{obj.name}</Option>
                ))
              }
            </Select>
            <Icon type="delete" onClick={removeCollaborator} />
          </Row>
          {userRole &&
            <Row type='flex' align='middle' justify='start' style={{ marginTop: 10 }}>
              {
                detailVisible ?
                  <div style={{ color: '#597EF7', cursor: 'pointer' }} onClick={() => setDetailVisible(false)} >
                    <Icon type="up" />
                    Ocultar permisos rol
                  </div>
                  :
                  <div style={{ color: '#597EF7', cursor: 'pointer' }} onClick={() => setDetailVisible(true)}>
                    <Icon type="down" />
                    Mostrar permisos del rol
                  </div>
              }
            </Row>
          }
        </Col>
      </Row>
      {
        detailVisible &&
        <DetailRole />
      }
    </>
  )
}

const InviteToBusinessModal = ({ roles, ...props }) => {
  const [newCollaborators, setNewCollaborators] = useState({
    "0": {
      email: null,
      permissions: [],
      role: null,
    },
    "1": {
      email: null,
      permissions: [],
      role: null,
    },
  });
  const [submittingData, setSubmittingData] = useState(false);
  const [invitationsSent, setInvitationsSent] = useState(0);
  const [errors, setErrors] = useState({
    emailInputs: {},
    apiRequests: {},
  });

  const { showInvitationModal, setShowInvitationModal, sendData, token } = props;

  useEffect(() => {
    if (!submittingData)
      return;
    const invitationsNotSent = Object.keys(errors.apiRequests).length;
    const totalNewCollaborators = Object.keys(newCollaborators).filter(
      (i) => newCollaborators[`${i}`].email
    ).length;
    if (!showInvitationModal && (invitationsSent + invitationsNotSent === totalNewCollaborators)) {
      if (invitationsSent > 0) {
        message.success({
          duration: 5,
          content: `${invitationsSent} ${invitationsSent == 1 ? 'invitación enviada' : 'invitaciones enviadas'}.`,
        });
      }
      Object.keys(errors.apiRequests).map((index) => {
        if (errors.apiRequests[index] === "businessPermissionAlreadyExists") {
          message.error({
            duration: 5,
            content: `${newCollaborators[`${index}`].email} ya fue invitado a Genoma.`,
          });
        } else {
          message.error({
            duration: 5,
            content: `No se pudo enviar la invitación a ${newCollaborators[`${index}`].email}.`,
          });
        }
      });
      setInvitationsSent(0);
      setNewCollaborators({
        "0": {
          email: null,
          permissions: [],
          role: null,
        },
        "1": {
          email: null,
          permissions: [],
          role: null,
        },
      });
      setErrors({
        emailInputs: {},
        apiRequests: {},
      })
      setSubmittingData(false);
    }
  }, [invitationsSent, showInvitationModal])

  const handleEmailChange = (event, index) => {
    const aux = newCollaborators[index];
    aux.email = event.target.value.toLowerCase();
    if (!aux.email || validateEmailFormat(aux.email)) {
      if (Object.keys(errors.emailInputs).includes(index)) {
        setErrors((prevErrors) => {
          delete prevErrors.emailInputs[`${index}`];
          return prevErrors;
        })
      }
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        emailInputs: {
          ...prevErrors.emailInputs,
          [index]: "invalidEmail",
        }
      }))
    }
    setNewCollaborators((prevState) => ({ ...prevState, [index]: aux }));
  };

  const handlePermissionsChange = (permission, index) => {
    const aux = newCollaborators[index];
    const permIdx = aux.permissions.findIndex(elem => elem === permission)

    if (permIdx != -1) {
      aux.permissions.splice(permIdx, 1)
    }
    else {
      aux.permissions.push(permission);
    }
    setNewCollaborators((prevState) => ({ ...prevState, [index]: aux }));
  }

  const handleRoleChange = (roleId, index) => {
    const aux = newCollaborators[index];
    aux.role = roleId;
    setNewCollaborators((prevState) => ({ ...prevState, [index]: aux }));
  }

  const submitData = () => {
    setSubmittingData(true);
    const newCollaboratorsData = Object.keys(newCollaborators).map((i) => {
      const { email, permissions, role } = newCollaborators[`${i}`];
      let newBusinessUserData;
      if (email) {
        let url = `/api/v1/accounts/business/${props.businessId}/businessusers/`;
        let data = { "email": email }
        // Data for new row in BusinessPermissions management table
        newBusinessUserData = {
          key: null,
          businessUser: {
            id: null,
            name: "",
            email: "",
          },
          businessPermissions: {
            business_admin: false,
            can_manage: false,
            can_invite: false,
            can_create_cell: false,
            can_view_all_jobapps: false,
            text: "",
            role: null,
          },
          status: {
            code: "",
            text: "",
          },
          invitedBy: {
            id: null,
            name: "",
          },
          invitationDate: null,
        };
        // TODO: ALINEAR MESSAGES
        return sendData(token, url, "POST", data).then((response) => {
          if (response.status >= 400) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              apiRequests: {
                ...prevErrors.apiRequests,
                [i]: "businessUserNotCreatedCorrectly",
              },
            }));
          } else {
            newBusinessUserData.key = response.body.id;
            newBusinessUserData.businessUser.id = response.body.id;
            newBusinessUserData.businessUser.email = response.body.email;
            newBusinessUserData.businessUser.name = response.body.name;
            url = `/api/v1/permissions/business/${props.businessId}/`;
            data = {
              "business_user": response.body.id,
              "business_admin": permissions.includes("business_admin"),
              "can_manage": permissions.includes("can_manage_business"),
              "can_invite": permissions.includes("can_invite_to_business"),
              "can_create_cell": permissions.includes("can_create_cell"),
              "can_view_all_jobapps": permissions.includes("can_view_all_jobapps"),
              // Role id
              "role": role
            }
            return sendData(token, url, "POST", data).then((response) => {
              if (response.status >= 400) {
                let errorMsg;
                if (response.status === 409) {
                  errorMsg = "businessPermissionAlreadyExists";
                } else {
                  errorMsg = "businessPermissionNotCreatedCorrectly";
                }
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  apiRequests: {
                    ...prevErrors.apiRequests,
                    [i]: errorMsg,
                  },
                }));
              } else {
                const { business_admin, can_manage, can_invite, can_create_cell, can_view_all_jobapps, status, role } = response.body.permission;
                newBusinessUserData.businessPermissions.business_admin = business_admin;
                newBusinessUserData.businessPermissions.can_manage = can_manage;
                newBusinessUserData.businessPermissions.can_invite = can_invite;
                newBusinessUserData.businessPermissions.can_create_cell = can_create_cell;
                newBusinessUserData.businessPermissions.can_view_all_jobapps = can_view_all_jobapps;
                newBusinessUserData.businessPermissions.role = role;

                newBusinessUserData.businessPermissions.text = businessPermissionName(response.body.permission);
                newBusinessUserData.status.code = status;
                newBusinessUserData.status.text = businessPermissionStatusName[status];
                newBusinessUserData.invitedBy.id = response.body.permission.created_by.id;
                newBusinessUserData.invitedBy.name = response.body.permission.created_by.name;
                newBusinessUserData.invitationDate = response.body.permission.date_created;
                setInvitationsSent((prev) => (prev + 1));
                return newBusinessUserData;
              }
            })
          }
        });
      }
    });
    Promise.all(newCollaboratorsData).then((results) => {
      props.addBusinessPermissionData(results.filter((value) => value !== null && value !== undefined));
      setShowInvitationModal(false);
    });
  }

  const handleCancelModal = () => {
    setShowInvitationModal(false);
    setSubmittingData(false);
    setInvitationsSent(0);
  }

  const handleResetModal = () => {
    setNewCollaborators({
      "0": {
        email: null,
        permissions: [],
        role: null,
      },
      "1": {
        email: null,
        permissions: [],
        role: null,
      },
    });
    setErrors({
      emailInputs: {},
      apiRequests: {},
    });
  }

  const removeCollaborator = (indexToRemove) => {
    let tmpCollaborators = { ...newCollaborators };
    delete tmpCollaborators[indexToRemove];
    setNewCollaborators(tmpCollaborators)
  }

  return (
    <Modal
      bodyStyle={{ height: '75vh', overflowY: 'scroll' }}
      className="invitation-modal"
      style={{ fontFamily: 'Lato' }}
      title={
        <Text style={{ fontWeight: 700 }}>Agregar usuario</Text>
      }
      visible={showInvitationModal}
      onCancel={handleCancelModal}
      footer={(
        <Row type='flex' justify='space-between'>
          <Col pull={14}>
            <Button
              key="reset-button"
              onClick={handleResetModal}
              disabled={submittingData}
              type='link'
            >
              Limpiar todos los campos
            </Button>
          </Col>
          <Col>
            <Button
              key="cancel-button"
              onClick={handleCancelModal}
              disabled={submittingData}
            >
              Cancelar
            </Button>
            <Button
              key="submit-button"
              type="primary"
              onClick={submitData}
              disabled={
                submittingData ||
                Object.keys(errors.emailInputs).length > 0 ||
                Object.values(newCollaborators).filter((collabData) => collabData.email).length === 0
              }
            >
              Enviar invitaciones
            </Button>
          </Col>
        </Row>
      )}
    >
      {Object.keys(newCollaborators).map((i) => (
        <NewCollaborator
          key={`NewCollaborator-${i}`}
          index={i}
          handleEmailChange={handleEmailChange}
          handlePermissionsChange={handlePermissionsChange}
          handleRoleChange={handleRoleChange}
          removeCollaborator={() => removeCollaborator(i)}
          email={newCollaborators[`${i}`].email}
          userPermissions={newCollaborators[`${i}`].permissions}
          userRole={newCollaborators[`${i}`].role}
          errors={errors}
          isBusinessAdmin={props.isBusinessAdmin}
          roles={roles}
        />
      ))}
      <Button
        type="link"
        onClick={() => {
          setNewCollaborators({
            ...newCollaborators,
            [`${Object.keys(newCollaborators).length}`]: {
              email: null,
              permissions: [],
              role: null,
            }
          })
        }}
        style={{ color: "#5E5E5E", marginTop: "1em", padding: 0 }}
      >
        <Icon type="plus-circle" /> Agregar Usuario
      </Button>

      <Row type='flex' justify='center'>
        <img style={{maxWidth:'90%'}} src="https://genoma-assets.s3.us-east-2.amazonaws.com/invite-collaborators.png"></img>
      </Row>

    </Modal>
  )
}

InviteToBusinessModal.propTypes = {
  showInvitationModal: PropTypes.bool.isRequired,
  setShowInvitationModal: PropTypes.func.isRequired,
  sendData: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  businessId: PropTypes.string.isRequired,
  addBusinessPermissionData: PropTypes.func.isRequired,
  isBusinessAdmin: PropTypes.bool.isRequired,
};

export default InviteToBusinessModal;
