import React from 'react';
import '../../pdf-css.scss';

import { Row, Col, Typography, Avatar } from 'antd';

const backgroundPalette = [
  '#D3ADF7',
  '#91D5FF',
  '#87E8DE',
  '#FFD591',
  '#ADC6FF',
];
const colorPallete = ['#531DAB', '#096DD9', '#08979C', '#D46B08', '#1D39C4'];

const HeatmapPDF = ({ candidates, type }) => {
  let validData = false;
  let questions = [];

  candidates.map((candidate) => {
    if (candidate[`${type}_scores`].length > 0) {
      validData = true;
    }
    candidate[`${type}_scores`].map((t) => {
      let traitsLength = candidate[`${type}_scores`].length;
      if (questions.length < traitsLength) {
        questions.push(t.trait);
      }
    });
  });

  const allTraits = [];
  candidates.map((candidate) => {
    candidate[`${type}_scores`].forEach((t) => {
      // Hacer un set de las combinaciones de traits 
      // y activities para luego mapear segun esto
      if (!allTraits.find(x => x.code === t.code && x.game_code === t.game_code)) {
        allTraits.push({ game_code: t.game_code, trait: t.trait, code: t.code });
      }
    });
  });

  return (
    <Row type='flex' justify='center' className='heatmap-container-pdf'>
      {validData ? (
        <Row>
          <Col span={12} className='heatmap-container-pdf__heatmap-questions'>
            <table>
              <tbody>
                {candidates[0]
                  ? questions.map((question, index) => (
                      <tr key={index}>
                        <td>
                          <Typography.Text style={{fontSize:12}}>{question}</Typography.Text>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </Col>

          <Col span={12} className='heatmap-container-pdf__heatmap-percent'>
            <table>
              <thead>
                <tr>
                  {candidates.map(
                    (candidate, index) =>
                      candidate[`${type}_scores`].length > 0 && (
                        <th key={index}>
                          <Avatar
                            size={32}
                            style={{
                              background: backgroundPalette[index],
                              color: colorPallete[index],
                            }}
                          >
                            {candidate.name[0]}
                            {candidate.surname[0]}
                          </Avatar>
                        </th>
                      )
                  )}
                </tr>
              </thead>
              <tbody>
                {candidates.map((candidate, index) => (
                  <tr
                    style={{ display: 'flex', flexDirection: 'column' }}
                    key={index}
                  >
                    {candidate[`${type}_scores`]
                      ? allTraits.map((trait, index) => {
                        const candidateScore = candidate[`${type}_scores`].find(
                          cScore => cScore.code === trait.code && cScore.game_code === trait.game_code
                        )?.score;
                        return candidateScore >= 90 ? (
                            <td key={index} style={{ background: '#C41D7F' }}>
                              {candidateScore}
                            </td>
                          ) : candidateScore >= 60 ? (
                            <td key={index} style={{ background: '#F759AB' }}>
                              {candidateScore}
                            </td>
                          ) : (
                            <td key={index} style={{ background: '#FFADD2' }}>
                              {candidateScore}
                            </td>
                          )
                        })
                      : null}
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>
      ) : (
        <Col span={24}>
          <Row
            type='flex'
            justify='center'
            align='middle'
            style={{ height: '100%' }}
          >
            <Typography.Title>Sin datos</Typography.Title>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default HeatmapPDF;
