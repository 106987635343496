import React from 'react';
import { Modal, Button } from 'antd';

import TemplateCard from './TemplateCard';
import { processNivoData } from '../../assets/utils';
import i18n from '../../../../../i18n';

const ModalDiscardReasons = ({ discardReasons, showModal, setShowModal }) => {

  return (
    <Modal
      visible={showModal}
      footer={null}
      onCancel={() => setShowModal(false)}
      centered
      width={850}
    >
      <TemplateCard
          title={i18n.t('analytics__candidates_discard_reasons')}
          templateGraphic
          height={350}
          topic='discard_reasons'
          graphicType='bar'
          customDirection='horizontal'
          customMargin={{ top: 10, right: 20, bottom: 40, left: 180 }}
          text='' 
          data={processNivoData(discardReasons, ['title'], '')}
      />
    </Modal>
  );
}
 
export default ModalDiscardReasons;