import React, { useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../actions/data'
import { 
  Row, 
  Col, 
  Typography, 
  Form, 
  Input, 
  Select, 
  Button, 
  Icon, 
  message} from 'antd'

const { Text, Paragraph } = Typography;
const { Option } = Select;

let id = 1;

const InviteUser = (props) => {
  const [loading, setLoading] = useState(false)

  const remove = k => {
    const { form } = props;
    const keys = form.getFieldValue('keys');

    if (keys.length === 1) {
      return;
    }

    form.setFieldsValue({
      keys: keys.filter(key => key !== k),
    });
  };

  const add = () => {
    const { form } = props;
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(id++);

    form.setFieldsValue({
      keys: nextKeys,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        setLoading(true)

        const { keys, names, emails, rols } = values;
        const url = 'gamesandtests/sendmailinvitation/'

        keys.forEach((key) => {
          const data = {
            department: names[key],
            email: [emails[key].toLowerCase()],
            //rol: rols[key]  <- TODO backend
          }

          props.actions.sendData(props.token, url, JSON.stringify(data), 'post', '').then(() =>{
            setLoading(false)
            // TODO Manejar errores (correo existente)
          })
        })

        message.success('Las invitaciones fueron enviadas', 3)

        props.form.setFieldsValue({
          keys: [0],
          names: [undefined],
          emails: [undefined],
          rols: ["collaborator"]
        })

        id = 1
      }
    });
  };

  const { getFieldDecorator, getFieldValue } = props.form;

  getFieldDecorator('keys', { initialValue: [0] });
  const keys = getFieldValue('keys');

  const formItems = keys.map((k, index) => (
    <Row key={index} type='flex' gutter={[20, 0]}>
      <Col md={8} lg={6}>
        <Form.Item label='Nombre (opcional)'>
          {getFieldDecorator(`names[${k}]`, {
            validateTrigger: ['onChange', 'onBlur']
          })(<Input />)}
        </Form.Item>
      </Col>

      <Col md={8} lg={6}>
        <Form.Item label='Email' hasFeedback>
          {getFieldDecorator(`emails[${k}]`, {
            validateTrigger: ['onChange', 'onBlur'],
            rules: [
              {
                type: 'email',
                message: 'El email ingresado no es válido',
              },
              {
                required: true,
                whitespace: true,
                message: 'Porfavor ingrese email',
              },
            ],
          })(<Input />)}
        </Form.Item>
      </Col>

      {/* TODO backend
      
      <Col md={8} lg={6}>
        <Form.Item label='Rol'>
          {getFieldDecorator(`rols[${k}]`, {
            validateTrigger: ['onChange', 'onBlur'],
            initialValue: 'collaborator'
          })(
            <Select>
              <Option value='admin'>Administrador</Option>
              <Option value='collaborator'>Colaborador</Option>
            </Select>
          )}
        </Form.Item>
      </Col>*/}

      <Col span={1} className='delete-field'>
        {keys.length > 1 ? (
          <Icon
            className="dynamic-delete-button"
            type="minus-circle-o"
            onClick={() => remove(k)}
          />
        ) : null}
      </Col>
    </Row>
  ));

  return (
    <Row type='flex' className='gw-as__users'>
      <Col lg={20}>
        <div className='gw-as__users__headline'>
          <Text strong>Invitar usuarios</Text>
          <Paragraph>
            Invitar usuarios Los usuarios ingresarán con su propio e-mail y contraseña, donde podrán publicar empleos y consultar postulaciones. Los usuarios con rol Administrador podrán además modificar el perfil de tu empresa en Genomawork e invitar a otros miembros.
          </Paragraph>
          <Text strong>
            Para invitar usuarios, contacte a su agente comercial.
          </Text>
        </div>

        {/* NOTE: Funcionalidad para invitar colaboradores deshabilitad hasta que CamiTi diga lo contrario */}
        {/* <Form onSubmit={handleSubmit}>
          {formItems}

          <Form.Item>
            <Button type="dashed" icon='plus' onClick={add}>
              Agregar otro
            </Button>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Enviar invitación
            </Button>
          </Form.Item>
        </Form> */}
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  };
};

const WrappedInviteUser = Form.create()(InviteUser);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedInviteUser);