import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../actions/data';
import * as actions from '../../actions/auth';
import ThemeProvider from './context/ThemeProvider';

import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Typography,
  Checkbox,
  Modal,
  message,
  Layout,
  Tabs,
  Divider,
} from 'antd';
import './JobListing.scss';

import SiteBackground from './settingsComponents/SiteBackground';
import WelcomeText from './settingsComponents/WelcomeText';
import ColorsTheme from './settingsComponents/ColorsTheme';
import Profile from '../AccountV2/profile/profile';

//new
import ConfidentialBrand from './components/ConfidentialBrand';

const SHARE_URL = process.env.REACT_APP_SHARE_URL;

const { TextArea } = Input;
const { TabPane } = Tabs;
const { Title } = Typography;

class RecruitmentPortalSettingsView extends React.Component {
  state = {
    facebook: '',
    instagram: '',
    twitter: '',
    pk: '',
    linkedin: '',
    jobs_site_activated: false,
    jobs_site_welcome_text: '',
    bgImage: null,
    imgProcess: null,
    errorTxt: null,
    errors: {
      facebook: '',
      instagram: '',
      twitter: '',
      linkedin: '',
      video_id: '',
    },
    loading: false,
    isLoadingComponent: true,
    openSnack: false,
    snackbarmessage: '',
    fields: [
      {
        title: '',
        content: '',
      },
    ],
    color_theme: {
      background: '',
      color: '',
    },
    intro_video: {
      source: '',
      video_id: '',
    },
  };
  updateData = (e) => {
    e.preventDefault();
    this.setState({ errorTxt: null });
    this.setState({ loading: true });
    const token = this.props.token;
    const url = `/api/v1/accounts/business/${this.props.currentBusiness.id}/`;
   
    const submitData = {
      rrss_facebook: this.state.facebook,
      rrss_instagram: this.state.instagram,
      rrss_twitter: this.state.twitter,
      rrss_linkedin: this.state.linkedin,
      jobs_site_activated: this.state.jobs_site_activated,
      // welcome text was repleced with business_info
      //jobs_site_welcome_text: this.state.jobs_site_welcome_text,
      business_info: { sections: this.state.fields },
      intro_video: this.state.intro_video,
      color_theme: this.state.color_theme,
    };
    this.props.actions.fetchAndReturn(token, url, "PATCH", submitData).then((response) => {
      if (response.status < 400) {
        message.success("El portal de empleos ha sido actualizado exitosamente", 3);
      } 
      else{
        message.success("Error al actualizar el portal", 3);
      }
      this.setState({ loading: false });

    });
    if (this.state.bgImage !== null) {
      // let url2 = `accounts/jobsbackground/${this.state.bgImage.name}`
      let url2 = `accounts/business/${this.state.pk}/jobs/background/${this.state.bgImage.name}/`;
      this.props.actions.sendFile(
        token,
        url2,
        this.state.bgImage,
        this.state.bgImage.name
      );
    }
  };

  copyToClipboard = () => {
    if (SHARE_URL.includes('app.genoma.work')) {
      navigator.clipboard.writeText(
        `https://jobs.genoma.work/${this.state.slug}`
      );
    } else {
      navigator.clipboard.writeText(`${SHARE_URL}/jobs/${this.state.slug}`);
    }
    message.success('Link copiado en el portapapeles', 3);
  };

  openPortal = () => {
    if (SHARE_URL.includes('app.genoma.work')) {
      window.open(`https://jobs.genoma.work/${this.state.slug}`);
    } else {
      window.open(`${SHARE_URL}/jobs/${this.state.slug}`);
    }
  };

  handleChange = (prop) => (event) => {
    let pokeProp = ['youtube'];
    let tempErrors = this.state.errors;
    tempErrors[prop] = '';
    if (pokeProp.includes(prop)) {
      let youtubeUrl = event.target.value;
      this.setState({ intro_video: { source: prop, video_id: youtubeUrl } });
    } else {
      this.setState({ errors: tempErrors, [prop]: event.target.value });
    }
  };

  handleBooleanChange = (prop) => (event) => {
    let bool = !this.state[prop];
    this.setState({ [prop]: bool });
  };

  closeSnack = () => {
    this.setState({ snackbarmessage: '', openSnack: false });
  };

  handleChangeDescriptions = (i, event) => {
    const { name, value } = event.target;
    let descriptions = [...this.state.fields];
    descriptions[i] = { ...descriptions[i], [name]: value };
    this.setState({ fields: descriptions });
  };

  addDescriptionSet = () => {
    this.setState((prevState) => ({
      fields: [...prevState.fields, { title: '', content: '' }],
    }));
  };

  handleRemove = (i) => {
    let removeFields = [...this.state.fields];
    removeFields.splice(i, 1);
    this.setState({ fields: removeFields });
  };

  openModalDeleteConfirmation = (i) => {
    Modal.confirm({
      title: `Borrar sección`,
      content: `¿Confirmas que deseas eliminar la sección ${i + 1}?`,
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => this.handleRemove(i),
      onCancel: () => {},
    });
  };

  createUI() {
    return this.state.fields?.map((field, index) => (
      <div className="rps__job-descriptions" key={`${field}-${index}`}>
        <Typography.Title level={4}>Sección {index + 1}</Typography.Title>
        <Row>
          <Col>
            <Input
              name="title"
              placeholder="Ej. Nuestra misión, Visión, Lo que nos motiva, Presentación, Introducción de la empresa, etc."
              onChange={(e) => this.handleChangeDescriptions(index, e)}
              value={field.title || ''}
            />

            <TextArea
              name="content"
              rows={6}
              onChange={(e) => this.handleChangeDescriptions(index, e)}
              value={field.content || ''}
              placeholder="Ingresa en esta área información sobre tu empresa, se le asociará el titulo que acabas de ingresar en el campo de arriba. "
            />

            {index !== 0 && (
              <Button
                type="secondary"
                icon="delete"
                onClick={this.openModalDeleteConfirmation.bind(this, index)}
              >
                Eliminar
              </Button>
            )}
          </Col>
        </Row>
      </div>
    ));
  }

  componentDidMount() {
    const token = this.props.token;
    const url = `accounts/business/${this.props.currentBusiness.id}/`;

    this.props.actions.fetchData(token, url, 'get').then(() => {
      const sections = this.props.data.business_info?.sections
        ? this.props.data.business_info.sections
        : this.props.data.business_info;
        
      this.setState({
        pk: this.props.currentBusiness.id,
        facebook: this.props.data.rrss_facebook,
        instagram: this.props.data.rrss_instagram,
        twitter: this.props.data.rrss_twitter,
        linkedin: this.props.data.rrss_linkedin,
        jobs_site_activated: this.props.data.jobs_site_activated,
        jobs_site_welcome_text: this.props.data.jobs_site_welcome_text,
        jobs_site_background_image: this.props.data.jobs_site_background_image,
        slug: this.props.data.slug,
        imgProcess: this.props.data.background_image,
        isLoadingComponent: false,
        fields: sections,
        color_theme: {
          background: this.props.data.color_theme?.background ?? '#f175a5',
          color: this.props.data.color_theme?.color ?? '#ffffff',
        },
        intro_video: {
          source: this.props.data.intro_video?.source ?? '',
          video_id: this.props.data.intro_video?.video_id ?? '',
        },
      });
    });
  }

  render() {
    const {
      confidential_name,
      confidential_logo,
      confidential_background_image,
      confidential_color_theme,
    } = this.props.data;

    const defaultTab = this.props.history.location.pathname.includes('confidential') ? '3' : '1';

    return (
      <Layout className="branding-dashboard-layout" style={{ margin: 40 }}>
        <Row>
          <Typography.Title level={1}>
            Configurar mi Marca Empleadora
          </Typography.Title>
          <Typography.Paragraph>
            Desde esta sección podrás configurar la forma en la cual tus
            postulantes te ven a través de Genomawork. Puedes modificar tu
            portal de empleo, tu logo de la empresa y la imagen de portada que
            se verá por defecto en todos los cargos que publiques.
          </Typography.Paragraph>
        </Row>
        <Tabs
          className="branding-tabs"
          defaultActiveKey={defaultTab}
          animated={{ tabPane: false, inkBar: true }}
        >
          <TabPane tab="Mi marca" key="1">
            <Row style={{ margin: 20 }}>
              <Title level={4}>Imágenes</Title>
              <Profile imgProcess={this.state.imgProcess} />
              <Divider />
              <Title level={4}>Colores</Title>
              <Row>
                <ColorsTheme
                  setColors={(color_theme) => this.setState({ color_theme })}
                  theme={this.state.color_theme}
                />
              </Row>
              <div className="rps__update-btn">
                <Button
                  disabled={this.state.loading}
                  htmlType="submit"
                  type="primary"
                  onClick={this.updateData}
                  loading={this.state.loading}
                  size="large"
                >
                  Actualizar
                </Button>
              </div>
            </Row>
          </TabPane>
          <TabPane tab="Mi marca (confidencial)" key="3">
            <ConfidentialBrand
              confidentialInfo={{
                confidential_name,
                confidential_logo,
                confidential_background_image,
                confidential_color_theme,
              }}
              actions={this.props.actions}
              token={this.props.token}
              businessId={this.props.currentBusiness.id}
              defaultColors={this.state.color_theme}
            />
          </TabPane>
          <TabPane tab="Portal de empleo" key="2">
            <Row className="rps" type="flex" style={{ margin: 20 }}>
              <Col xs={22} sm={20} md={18} lg={16} xl={14}>
                <div className="rps__job-title">
                  <Typography.Title level={3}>
                    Actualizar portal de empleo
                  </Typography.Title>
                </div>
                {this.state.isLoadingComponent ? (
                  <></>
                ) : (
                  <Form>
                    <Row>
                      <Col>
                        <Checkbox
                          checked={this.state.jobs_site_activated}
                          onChange={this.handleBooleanChange(
                            'jobs_site_activated'
                          )}
                          value="jobs_site_activated"
                        >
                          Activar portal de empleo
                        </Checkbox>

                        {this.props.data.jobs_site_activated ? (
                          <div className="rps__job-btns">
                            <Button type="primary" onClick={this.openPortal}>
                              Ir al portal de empleos
                            </Button>
                            <Button
                              icon="copy"
                              type="secondary"
                              onClick={this.copyToClipboard}
                            >
                              Copiar link en el portapapeles
                            </Button>
                          </div>
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>

                    <div>
                      <WelcomeText />
                    </div>

                    {this.createUI()}

                    <div className="rps__job-add">
                      <Button
                        type="dashed"
                        icon="plus"
                        onClick={this.addDescriptionSet}
                      >
                        Agregar otro set de campos
                      </Button>
                    </div>

                    <div className="rps__rrss">
                      <Typography.Title level={4} style={{ marginTop: 15 }}>
                        Video Introducción
                      </Typography.Title>
                      <Row>
                        <Typography.Text>
                          Copia y pega aquí el link que aparece en la parte
                          superior del navegador en el sitio de youtube.
                          Asegurate que el link no sea una lista de
                          reproducción.
                        </Typography.Text>
                        <Col>
                          <Form.Item>
                            <Input
                              placeholder="Ej.: https://www.youtube.com/watch?v=CJraMO_rJ48"
                              type="text"
                              value={this.state.intro_video.video_id}
                              onChange={this.handleChange('youtube')}
                              error={this.state.errors.video_id}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <SiteBackground
                        bgImage={this.state.jobs_site_background_image}
                        background={(bgImage) => this.setState({ bgImage })}
                      />
                      <Divider />
                    </div>
                    <div className="rps__rrss">
                      <Typography.Title level={4} style={{ marginTop: 15 }}>
                        Redes Sociales
                      </Typography.Title>
                      <Row>
                        <Col>
                          <Form.Item label="Facebook">
                            <Input
                              placeholder="Facebook"
                              type="text"
                              id="facebook"
                              value={this.state.facebook}
                              onChange={this.handleChange('facebook')}
                              error={this.state.errors.facebook}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Item label="Instagram">
                            <Input
                              type="text"
                              id="instagram"
                              placeholder="Instagram"
                              value={this.state.instagram}
                              onChange={this.handleChange('instagram')}
                              error={this.state.errors.instagram}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Item label="Twitter">
                            <Input
                              type="text"
                              placeholder="Twitter"
                              value={this.state.twitter}
                              onChange={this.handleChange('twitter')}
                              error={this.state.errors.twitter}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Item label="LinkedIn">
                            <Input
                              type="text"
                              id="linkedin"
                              placeholder="LinkedIn"
                              value={this.state.linkedin}
                              onChange={this.handleChange('linkedin')}
                              error={this.state.errors.linkedin}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                    <div className="rps__update-btn">
                      <Button
                        disabled={this.state.loading}
                        htmlType="submit"
                        type="primary"
                        onClick={this.updateData}
                        loading={this.state.loading}
                        size="large"
                      >
                        Actualizar
                      </Button>
                    </div>
                  </Form>
                )}
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isAuthenticating: state.auth.isAuthenticating,
    isLoading: state.data.isFetching,
    token: state.auth.token,
    email: state.auth.userName,
    error: state.data.error,
    alerts: state.alerts,
    data: state.data.data,
    currentBusiness: state.auth.currentBusiness,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    actionsAuth: bindActionCreators(actions, dispatch),
    dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecruitmentPortalSettingsView);
