import React from 'react';
import {
  Typography,
  Modal
} from 'antd';
import ParseHtml from '../../components/parseHtml';

const { Text, Title } = Typography;

const QuestionsModal = ({ test, setSelectedTest }) => {

  const formatTestQuestions = () => {
    const questions = test.questions.data.filter(({ label }) => label !== 'Instrucciones').map((question) => {
      return question.fields.map((field) => {
        return field.label;
      })
    })
    return questions.flat();
  }

  return (
    test &&
      <Modal 
        visible={test !== null} 
        onCancel={() => setSelectedTest(null)} 
        footer={null} 
        closable
        width={900}
      >
        {formatTestQuestions().map((question, idx) => 
          <div key={idx}>
            <Title level={4}>{`Pregunta ${idx + 1}`}</Title>
            <Text>{ParseHtml(question)}</Text>
          </div>
        )}
      </Modal>
  )

};

export default QuestionsModal;