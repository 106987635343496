import React from 'react';
import { Col, Row, Typography } from 'antd';

const colorScheme = {
  twoTone: ['#D9F7BE', '#FFCCC7'],
  threeTone: ['#D9F7BE', '#FFF1B8', '#FFCCC7'],
  fourTone: ['#D9F7BE', '#F4FFB8', '#FFD8BF', '#FFCCC7'],
  fiveTone: ['#D9F7BE', '#F4FFB8', '#FFF1B8', '#FFD8BF', '#FFCCC7'],
};
const borderColorScheme = {
  twoTone: ['#52C41A', '#FF4D4F'],
  threeTone: ['#52C41A', '#FFF1B8', '#FF4D4F'],
  fourTone: ['#52C41A', '#BAE637', '#FF7A45', '#FF4D4F'],
  fiveTone: ['#52C41A', '#BAE637', '#FAAD14', '#FF7A45', '#FF4D4F'],
};

const { Text } = Typography;

const Segments = ({ scores, scoresConfig }) => {
  if (!scoresConfig.segments) return null;

  let tmpReverseSegment = [...scoresConfig.segments].reverse();
  const colorsToneArray = ['twoTone', 'threeTone', 'fourTone', 'fiveTone'];
  let colorTone = scoresConfig.segments?.length;
  colorTone = colorTone ? colorScheme[colorsToneArray[colorTone - 2]] : [];
  let borderColorTone = scoresConfig.segments?.length;
  borderColorTone = borderColorTone
    ? borderColorScheme[colorsToneArray[borderColorTone - 2]]
    : [];

  const bgColor = (obj, idx, baseColor) =>
    scores.result >= obj.min && scores.result < obj.max
      ? colorTone[idx]
      : baseColor;
  const borderColor = (obj, idx, baseColor) =>
    scores.result >= obj.min && scores.result < obj.max
      ? borderColorTone[idx]
      : baseColor;


  return (
    <>
      {scoresConfig.segments && tmpReverseSegment.map((obj, idx) => {
          return (
            <Row
              key={idx}
              style={{ minHeight: 50 }}
              type="flex"
              justify="center"
            >
              <Col
                span={6}
                style={{
                  padding: '2px 5px 2px 5px',
                  display: 'flex',
                  alignItems: 'center',
                  // background: segmentLength === 2 ? '#FFF2E8' : null,
                  background: bgColor(obj, idx, '#FFF'),
                  border: `1px solid ${borderColor(obj, idx, '#D9D9D9')}`,
                  borderRight: 0,
                }}
              >
                {scoresConfig.segments.length - 1 != idx
                  ? `Entre ${obj.min} y ${obj.max - 1}`
                  : `Entre ${obj.min} y ${obj.max - 1}`}
              </Col>
              <Col
                span={6}
                style={{
                  background: bgColor(obj, idx, '#FFF'),
                  border: `1px solid ${borderColor(obj, idx, '#D9D9D9')}`,
                  color: 'fff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Text strong style={{ color: 'rgba(0, 0, 0, 0.65)' }}>
                  {obj.label}
                </Text>
              </Col>
              {obj.description && (
                <Col
                  span={12}
                  style={{
                    padding: '2px 5px 2px 5px',
                    background: bgColor(obj, idx, '#FFF'),
                    border: `1px solid ${borderColor(obj, idx, '#FFF')}`,
                    border: `1px solid ${borderColor(obj, idx, '#D9D9D9')}`,
                  }}
                >
                  {obj.description}
                </Col>
              )}
            </Row>
          );
      })}
    </>
  );
};
export default Segments;