import React from 'react';
import {
  Badge,
  Typography
} from 'antd';
import './styles/processstatusbadge.scss';

const { Text } = Typography;

export default function ProcessStatusBadge({ status, text }) {
  let color;
  if (status === 'ACTIV') {
    color = '#87d068'
  }
  else if (status === 'INACT') {
    color = '#ff4543';
  }
  else if (status === 'OTHER') {
    color = '#848484';
  }
  return (
    <>
      <Badge color={color} className='stat' />
      {text ? <Text style={{ color }}>{text}</Text> : null}
    </>
  )
}
