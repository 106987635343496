import ParseHtml from "../../../components/parseHtml";

import i18n from "../../../i18n";

/**
 * @function getNotificationBusinessUser
 * @param {Object} notification - The notification object. This object contains all the information 
 * about the notification.
 * @param {number} [n=1] - An optional parameter that defaults to 1. It represents the number of 
 * notifications that are grouped together. If it's 1, it's just one notification.
 * @returns {Object} The notification text to be shown, and the path to redirect to.
 */
export const getNotificationBusinessUser = (notification, n=1) => {
  // The translation key for the notification text
  let notificationTextTranslationKey = '';
  // The kwargs for the translation key, if needed
  let kwargs = {};
  // The path to redirect to when clicking on the notification
  let redirectPath = '';
  const { notification_type } = notification;
  switch (notification_type) {
    case 'MSG': { // Nuevo mensaje
      const { personal_user, job_application } = notification;
      if (n === 1) {
        notificationTextTranslationKey = 'notifications__new_message';
        kwargs = {
          business: `<strong>${personal_user?.name}</strong>`,
          jobapp: `<strong>${job_application.job_application}</strong>`
        };
        redirectPath = `/processes/${job_application.id}?id=${personal_user.id}#messages`;
      } else { // n > 1
        notificationTextTranslationKey = 'notifications__many__new_message';
        kwargs = { n, jobapp: `<strong>${job_application.job_application}</strong>` };
        redirectPath = `/processes/${job_application.id}`;
      }
      break;
    }
    case 'FREQ': { // Candidato/a completó solicitud de documentos
      const { personal_user, job_application, extra } = notification;
      if (n === 1) {
        notificationTextTranslationKey = 'notifications__files_request_completed';
        kwargs = {
          candidate: `<strong>${personal_user?.name}</strong>`,
          filesRequestName: `<strong>${extra?.files_request_name ?? ''}</strong>`,
          jobapp: `<strong>${job_application.job_application}</strong>`,
        };
        redirectPath = `/processes/${job_application.id}?id=${personal_user.id}#filesrequest`;
      } else { // n > 1
        notificationTextTranslationKey = 'notifications__many__files_request_completed';
        kwargs = {
          n,
          filesRequestName: `<strong>${extra?.files_request_name ?? ''}</strong>`,
          jobapp: `<strong>${job_application.job_application}</strong>`,
        };
        redirectPath = `/processes/${job_application.id}`;
      }
      break;
    }
    case 'REFSENT': { // Referencias enviadas por candidato/a
      const { personal_user, job_application } = notification;
      if (n === 1) {
        notificationTextTranslationKey = 'notifications__references_sent_by_candidate';
        kwargs = {
          candidate: `<strong>${personal_user?.name}</strong>`,
          jobapp: `<strong>${job_application.job_application}</strong>`,
        };
        redirectPath = `/processes/${job_application.id}?id=${personal_user.id}#references`;
      } else { // n > 1
        notificationTextTranslationKey = 'notifications__many__references_sent_by_candidate';
        kwargs = {
          n, jobapp: `<strong>${job_application.job_application}</strong>`,
        };
        redirectPath = `/processes/${job_application.id}`;
      }
      break;
    }
    case 'REFANSW': { // Referencia respondió cuestionario
      const { personal_user, job_application } = notification;
      if (n === 1) {
        notificationTextTranslationKey = 'notifications__reference_answered';
        kwargs = {
          candidate: `<strong>${personal_user?.name}</strong>`,
          jobapp: `<strong>${job_application.job_application}</strong>`,
        };
        redirectPath = `/processes/${job_application.id}?id=${personal_user.id}#references`;
      } else { // n > 1
        notificationTextTranslationKey = 'notifications__many__reference_answered';
        kwargs = {
          n, jobapp: `<strong>${job_application.job_application}</strong>`,
        };
        redirectPath = `/processes/${job_application.id}`;
      }
      break;
    }
    case 'MTN': { // Mención en comentario
      const { job_application, user_job_application } = notification;
      if (n === 1) {
        notificationTextTranslationKey = 'notifications__tagged_on_comment';
        kwargs = {
          candidate: `<strong>${user_job_application.candidate_name}</strong>`,
          jobapp: `<strong>${job_application.job_application}</strong>`,
        };
        redirectPath = `/processes/${job_application.id}?id=${user_job_application.candidate_id}#comments`;
      } else { // n > 1
        notificationTextTranslationKey = 'notifications__many__tagged_on_comment';
        kwargs = { n, jobapp: `<strong>${job_application.job_application}</strong>` };
        redirectPath = `/processes/${job_application.id}`;
      }
      break;
    }
    case 'CRSP': { // Respuesta a comentario
      const { business_user, job_application, user_job_application } = notification;
      if (n === 1) {
        notificationTextTranslationKey = 'notifications__comment_response';
        kwargs = {
          business_user: `<strong>${business_user.business_name}</strong>`,
          candidate: `<strong>${user_job_application.candidate_name}</strong>`,
          jobapp: `<strong>${job_application.job_application}</strong>`,
        };
        redirectPath = `/processes/${job_application.id}?id=${user_job_application.candidate_id}#comments`;
      } else { // n > 1
        notificationTextTranslationKey = 'notifications__many__comment_response'; 
        kwargs = {
          n, jobapp: `<strong>${job_application.job_application}</strong>`,
        };
        redirectPath = `/processes/${job_application.id}`;
      }
      break;
    }
    case 'NEWUJA': { // Nueva postulación
      const { personal_user, job_application } = notification;
      if (n === 1) {
        notificationTextTranslationKey = 'notifications__new_candidate';
        kwargs = { jobapp: `<strong>${job_application.job_application}</strong>` };
        redirectPath = `/processes/${job_application.id}?id=${personal_user.id}`;
      } else { // n > 1
        notificationTextTranslationKey = 'notifications__many__new_candidate';
        kwargs = {
          n, jobapp: `<strong>${job_application.job_application}</strong>`,
        };
        redirectPath = `/processes/${job_application.id}`;
      }
      break;
    }
    case 'STGCMPLTD': { // Candidato/a completó etapa
      const { personal_user, job_application, extra } = notification;
      if (n === 1) {
        notificationTextTranslationKey = 'notifications__stage_completed';
        kwargs = {
          candidate: `<strong>${personal_user?.name}</strong>`,
          stageName: `<strong>${extra?.stage_name ?? ''}</strong>`,
          jobapp: `<strong>${job_application.job_application}</strong>`,
        };
        redirectPath = `/processes/${job_application.id}?id=${personal_user.id}`;
      } else { // n > 1
        notificationTextTranslationKey = 'notifications__many__stage_completed';
        kwargs = {
          n,
          stageName: `<strong>${extra?.stage_name ?? ''}</strong>`,
          jobapp: `<strong>${job_application.job_application}</strong>`,
        };
        redirectPath = `/processes/${job_application.id}`;
      }
      break;
    }
    case 'BUINVJA': { // Invitación a proceso
      const { job_application, business_user } = notification;
      if (n === 1) {
        notificationTextTranslationKey = 'notifications__business_user_invitation_to_process';
        kwargs = {
          sender: business_user.business_name,
          jobapp: `<strong>${job_application.job_application}</strong>`,
        };
        redirectPath = `/processes/${job_application.id}`;
      } else { // n > 1
        notificationTextTranslationKey = 'notifications__many__business_user_invitation_to_process';
        kwargs = { n };
        redirectPath = `/processes`;
      }
      break;
    }
    case 'INTSTATUS': { // Candidato/a aceptó/rechazó entrevista
      const { personal_user, job_application, extra } = notification;
      redirectPath = `/calendar`;
      let newStatus;
      if (n === 1) {
        newStatus = extra?.new_status ? (
          extra.new_status === 'ACCEPTED'
            ? i18n.t('notifications__interview_accepted', { ns: 'notifications' })
            : i18n.t('notifications__interview_declined', { ns: 'notifications' })
        ) : null;
        notificationTextTranslationKey = 'notifications__interview_invitation_status';
        kwargs = {
          candidate: `<strong>${personal_user?.name}</strong>`,
          status: newStatus,
          jobapp: `<strong>${job_application.job_application}</strong>`,
        };
      } else { // n > 1
        newStatus = extra?.new_status ? (
          extra.new_status === 'ACCEPTED'
            ? i18n.t('notifications__many__interview_accepted', { ns: 'notifications' })
            : i18n.t('notifications__many__interview_declined', { ns: 'notifications' })
        ) : null;
        notificationTextTranslationKey = 'notifications__many__interview_invitation_status';
        kwargs = {
          n,
          status: newStatus,
          jobapp: `<strong>${job_application.job_application}</strong>`,
        };
      }
      break;
    }
    case 'NEWJOBAPP': { // Nuevo proceso creado
      const { job_application, business_user } = notification;
      if (n === 1) {
        notificationTextTranslationKey = 'notifications__new_job_application';
        kwargs = { sender: `<strong>${business_user.name}</strong>` };
        redirectPath = `/processes/${job_application.id}`;
      } else { // n > 1
        notificationTextTranslationKey = 'notifications__many__new_job_application';
        kwargs = { n };
        redirectPath = `/processes`;
      }
      break;
    }
  }
  return ({
    notificationText: ParseHtml(
      i18n.t(notificationTextTranslationKey, { ...kwargs, ns: 'notifications' })
    ),
    redirectPath,
  });
};
