import React from 'react'
import { ResponsiveRadar } from '@nivo/radar'
import { Card } from 'antd'
import i18n from '../../../../i18n'


const ReasoningChart = (props) => {
  const yourScore = i18n.t('feedback__your__score')
  const data =
    [
      {
        'key': 'verbal',
        'label': i18n.t('feedback__verbal'),
      },
      {
        'key': 'logico',
        'label': i18n.t('feedback__logic'),
      },
      {
        'key': 'numerico',
        'label': i18n.t('feedback__numeric'),
      },
    ]
  data[0][i18n.t('feedback__your__score')] = props.verbalReasoning
  data[0][i18n.t('feedback__average__score')] = props.verbalAverage
  data[1][i18n.t('feedback__your__score')] = props.logicReasoning
  data[1][i18n.t('feedback__average__score')] = props.logicAverage
  data[2][i18n.t('feedback__your__score')] = props.numericalReasoning
  data[2][i18n.t('feedback__average__score')] = props.numericalAverage

  return (
    <Card
      style={{ width: '70%', height: '600px' }}
      className='reasoning-chart-card'
    >
      <div style={{ height: 550 }}>
        <ResponsiveRadar
          data={data}
          keys={[i18n.t('feedback__your__score'), i18n.t('feedback__average__score')]}
          indexBy='label'
          maxValue='100'
          margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
          theme={{ 'fontSize': 14, 'textColor': '#828282' }}
          curve='linearClosed'
          borderWidth={2}
          borderColor={{ from: 'color' }}
          gridLevels={5}
          gridShape='linear'
          gridLabelOffset={36}
          enableDots={true}
          dotSize={10}
          dotColor={{ theme: 'background' }}
          dotBorderWidth={2}
          dotBorderColor={{ from: 'color' }}
          enableDotLabel={true}
          dotLabel='value'
          dotLabelYOffset={-12}
          colors={['#ffa7d9', '#81ecec']}
          fillOpacity={0.25}
          blendMode='multiply'
          animate={true}
          motionConfig='wobbly'
          isInteractive={true}
          legends={[
            {
              anchor: 'top-left',
              direction: 'column',
              translateX: -50,
              translateY: -40,
              itemWidth: 80,
              itemHeight: 20,
              itemTextColor: '#828282',
              symbolSize: 14,
              symbolShape: 'circle',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemTextColor: '#ea4c89'
                  }
                }
              ]
            }
          ]}
        />
      </div>
    </Card>
  )
}

export default ReasoningChart
