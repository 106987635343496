import React, { useState} from "react";

import {
  Button,
  Col,
  Row,
  Icon,
  Typography,
} from "antd";
import i18n from "../../../../../i18n";
import CustomTabs from "../../../../JobApplicationProcess/components/NewWelcomeView/CustomTabs/CustomTabs";
import ParseHtml from "../../../../../components/parseHtml";


const FLAG_URL = "https://genoma-assets.s3.us-east-2.amazonaws.com/flags/";

const languages = {
  es: {
    flag: "ES",
    language: i18n.t("commons__ES"),
  },
  en: {
    flag: "US",
    language: i18n.t("commons__EN"),
  },
  pt: {
    flag: "BR",
    language: i18n.t("commons__PT"),
  },
};


function StartProcessPreview({ isMobile = false, data, banner, business }) {
  const [currentTab, setCurrentTab] = useState("job");
  const logo = data.is_confidential && business.confidential_logo ? business.confidential_logo : business.logo;
  const theme = data.is_confidential && business.confidential_color_theme ? business.confidential_color_theme : business.colorTheme;
  const background = data.is_confidential && business.confidential_background_image ? business.confidential_background_image : banner;

  return (
    <>
      {!isMobile && (
        <>
          <Row className="navbar" type="flex">
            <Col sm={2} style={{ display: 'grid', alignItems: 'center' }}>
              <img className="logo" src={logo} />
            </Col>
            <Col sm={21}>
              <Row
                type="flex"
                align="middle"
                justify="end"
                style={{ width: '100%', height: '100%' }}
              >
                <div style={{ border: '0.522852px solid #CCCCCC' }}>
                  <img
                    className="flag"
                    src={`${FLAG_URL}${languages[
                      data.language.toLowerCase()
                    ].flag.toUpperCase()}.svg`}
                  />

                  <Typography.Text
                    style={{
                      color: '#2B2B2B',
                      fontSize: 12,
                      paddingInline: '0.5em',
                    }}
                  >
                    {languages['es'].language}
                  </Typography.Text>
                  <Icon
                    type="down"
                    style={{ fontSize: 10, paddingRight: '0.5em' }}
                  />
                </div>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col
              sm={24}
              className="job-app-information"
              style={{
                backgroundImage: `url(${background})`,
              }}
            >
              {!isMobile && (
                <>
                  <Row className="jobapp-data">
                    <Typography.Title
                      level={3}
                      style={{
                        paddingTop: '2rem',
                        textAlign: 'center',
                        color: '#f0f0f0',
                      }}
                    >
                      {data.name?.toUpperCase()}
                    </Typography.Title>
                    <Col sm={14} className="icons-header">
                      <div>
                        <Icon type="idcard" style={{ color: '#f0f0f0' }} />
                        <span>{data.department}</span>
                      </div>
                      <div className="icons-header__separator" />

                      <div>
                        <Icon type="environment" style={{ color: '#f0f0f0' }} />
                        <span>
                          {data.city}, {data.country}
                        </span>
                      </div>
                      <div className="icons-header__separator" />
                      <div style={{ alignContent: 'center' }}>
                        <img
                          src={`${FLAG_URL}${
                            languages[data.language.toLowerCase()].flag
                          }.svg`}
                          style={{ height: 24, alignItems: 'center' }}
                        />
                        <span style={{ marginTop: '0.4rem' }}>
                          {languages[data.language].language}
                        </span>
                      </div>
                    </Col>
                    <Col sm={10} className="icons-header">
                      {business.name &&
                        !business.name
                          .toLowerCase()
                          .includes('minera centinela') && (
                          <Button
                            style={{
                              backgroundColor: theme.color,
                              borderColor: theme.background,
                              color: theme.background,
                            }}
                            className="start-process-button"
                          >
                            {i18n.t('start_process__know_more')}
                          </Button>
                        )}
                      <Button
                        style={{ ...theme, border: '0px' }}
                        className="start-process-button"
                      >
                        {i18n.t('job_portal__apply')}
                      </Button>
                    </Col>

                    <Col
                      sm={24}
                      style={{ paddingTop: '2.5rem', minHeight: '3rem' }}
                    >
                      <Row
                        type="flex"
                        justify="center"
                        style={{ width: '100%', height: '100%' }}
                      >
                        <Icon
                          type="down-circle"
                          style={{ fontSize: 24, color: '#f0f0f0' }}
                        />
                      </Row>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </>
      )}
      {isMobile && (
        <div className="smartphone">
          <Col sm={24} className="inner-margin">
            <Row className="navbar" type="flex">
              <Col sm={2} style={{ display: 'grid', alignItems: 'center' }}>
                <img className="logo" src={logo} />
              </Col>
              <Col sm={21}>
                <Row
                  type="flex"
                  align="middle"
                  justify="end"
                  style={{ width: '100%', height: '100%' }}
                >
                  <div style={{ border: '0.522852px solid #CCCCCC' }}>
                    <img
                      className="flag"
                      src={`${FLAG_URL}${languages[
                        data.language.toLowerCase()
                      ].flag.toUpperCase()}.svg`}
                    />
                    <Icon
                      type="down"
                      style={{ fontSize: 10, paddingRight: '0.5em' }}
                    />
                  </div>
                </Row>
              </Col>
            </Row>

            <Row
              className="content-mobile-preview"
              style={{
                backgroundImage: `url(${background})`,
              }}
            >
              <Col sm={24}>
                <Typography.Title
                  style={{
                    fontSize: 12,
                    textAlign: 'center',
                    color: '#f0f0f0',
                  }}
                >
                  {data.name?.toUpperCase()}
                </Typography.Title>
              </Col>
              <Col
                sm={22}
                className="icons-header"
                style={{ margin: '0.1rem' }}
              >
                <div>
                  <Icon
                    type="environment"
                    style={{ color: '#f0f0f0', fontSize: 12 }}
                  />
                  <span style={{ fontSize: '12' }}>
                    {data.city}, {data.country}
                  </span>
                </div>
                <div
                  className="icons-header__separator"
                  style={{ height: '1rem', marginTop: '0.2rem' }}
                />
                <div style={{ alignContent: 'center' }}>
                  <img
                    src={`${FLAG_URL}${
                      languages[data.language.toLowerCase()].flag
                    }.svg`}
                    className="flag"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <span style={{ fontSize: '12', marginTop: '0.6rem' }}>
                    {languages[data.language].language}
                  </span>
                </div>
              </Col>
            </Row>

            <Row className="mobile-buttons">
              {business.name &&
                !business.name.toLowerCase().includes('minera centinela') && (
                  <Button
                    size="small"
                    style={{
                      backgroundColor: theme.color,
                      borderColor: theme.background,
                      color: theme.background,
                      width: '5.5rem',
                    }}
                  >
                    {i18n.t('start_process__know_more')}
                  </Button>
                )}
              <Button size="small" style={{ ...theme, width: '5.5rem' }}>
                {i18n.t('job_portal__apply')}
              </Button>
            </Row>
            <CustomTabs
              setCurrentTab={setCurrentTab}
              currentTab={'job'}
              tabs={['job', 'company']}
              color="black"
              preview={true}
            />
            {data.welcomeText && (
              <div className="mobile-info">{ParseHtml(data.welcomeText)}</div>
            )}
          </Col>
        </div>
      )}
    </>
  );
}

export default StartProcessPreview;
