import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Icon,
  Layout,
  message,
  Modal,
  Row,
  Spin,
  Typography,
  Upload,
} from "antd";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { SERVER_URL } from "../../utils/config";
import * as Sentry from "@sentry/browser";
import { push } from "react-router-redux";
import * as actionCreators from "../../actions/data";
import i18n from "../../i18n";
import useBoolean from "../../hooks/useBoolean";

import "./FilesRequest.scss";

const coinIMG = "https://genoma-assets.s3.us-east-2.amazonaws.com/coin.png";
const docsIMG = "https://genoma-assets.s3.us-east-2.amazonaws.com/candidate-docs.svg"

const DOCRView = (props) => {
  const [request, setRequest] = useState();
  const [currentAnswers, setCurrentAnswers] = useState([]);
  const [initialAnswers, setInitialAnswers] = useState([]);
  const [completed, setCompleted] = useState({
    initial: false,
    current: false,
  });
  const [submittingAnswers, handleSubmittingAnswers] = useBoolean(false);

  const queryString = new URLSearchParams(window.location.search);
  const requestId = queryString.get("requestId");

  useEffect(() => {
    getDOCRequest();
    getAnswers();
  }, []);

  useEffect(() => {
    checkCompleted();
  }, [currentAnswers, initialAnswers]);

  const dummyRequest = ({ onSuccess }) => setTimeout(() => onSuccess("ok"), 0);

  const goBack = () => props.dispatch(push(`/jobapplication/${props.match.params["jobappid"]}`));

  const getDOCRequest = () => {
    const url = `/api/v1/gamesandtests/filesrequests/${requestId}/`;
    props.actions.fetchAndReturn(props.token, url)
      .then((response) => {
        if (response.status < 400) {
          setRequest(response.body);
        } else {
          message.error(i18n.t('docr__cannot_access_to_file_request'));
        }
      })
    .catch(() => message.error(i18n.t('docr__cannot_access_to_file_request')));
  };

  const checkCompleted = () => setCompleted({
    current:
      currentAnswers.length === request?.questions.length
        && !currentAnswers.some((q) => q[1].length === 0),
    initial:
      initialAnswers.length === request?.questions.length
        && !initialAnswers.some((q) => q[1].length === 0),
  });

  const getAnswers = () => {
    const url = `/api/v1/gamesandtests/filesrequests/${requestId}/filesquestionanswers/?job_application_id=${props.match.params["jobappid"]}`;
    props.actions.fetchAndReturn(props.token, url)
      .then((response) => {
        if (response.status < 400) {
          const answers = response.body.results?.map((req) => Array(req.question.id, req.files));
          setInitialAnswers([...answers]);
          setCurrentAnswers([...answers]);
        } else {
          message.error(i18n.t('docr__cannot_access_to_file_request'));
        }
      })
      .catch(() => message.error(i18n.t('docr__cannot_access_to_file_request')));
  };

  const handleAttachFile = ({ file, fileList }, id) => {
    // TODO: arreglar? (Rafa: este comentario estaba de antes, no cacho que hay que arreglar)
    const current = currentAnswers.filter((a) => a[0] === id);
    const filteredFiles = fileList.filter((file) => file.status !== "removed");
    const totalFiles = currentAnswers
      .map((answer) => answer[1].length)
      .reduce((partialSum, a) => partialSum + a, 0);
    let newCurrent;
    if (totalFiles < filteredFiles) {
      if (current.length > 0) {
        const files = current[0][1].push(file);
        newCurrent = Array(current[0][0], files);
      } else {
        newCurrent = Array(id, Array(file));
      }
      const newCurrentAnswers = currentAnswers.filter((a) => a[0] !== id);
      setCurrentAnswers([...newCurrentAnswers, newCurrent]);
    } else {
      const files = filteredFiles;
      newCurrent = Array(id, files);
      const newCurrentAnswers = currentAnswers.filter((a) => a[0] !== id);
      setCurrentAnswers([...newCurrentAnswers, newCurrent]);
    }
  };

  // TODO: Ver si se puede mover a otro archivo como función global, ya que al parecer se usa en
  // otras partes del código también
  const getBlobObj = async (file) => {
    let url;
    if (!file.business) {
      const blob = new Blob([file.originFileObj]);
      url = window.URL.createObjectURL(blob);
    } else {
      // NOTE: process.env.REACT_APP_FILE_RECORD_URL es undefined
      // url = process.env.REACT_APP_FILE_RECORD_URL + file.file;
      url = file.file;
    }
    let aux = document.createElement("a");
    document.body.appendChild(aux);
    aux.style = "display: none";
    aux.href = url;
    aux.target = '_blank'
    aux.click();
    window.URL.revokeObjectURL(url);
  };

  const getFileList = (question) => {
    const answers = currentAnswers.filter((obj) => obj[0] === question.id);
    if (answers.length > 0) {
      return answers[0][1].map((file, index) => ({
        ...file,
        uid: index,
        key: index,
      }));
    }
    return [];
  };

  const submitAnswers = () => {
    handleSubmittingAnswers.on();
    const url = `/api/v1/gamesandtests/filesrequests/${requestId}/filesquestionanswers/`;
    let promiseArray = [];
    request?.questions.forEach((question) => {
      const currentAnswersQuestions = currentAnswers.filter((obj) => obj[0] === question.id);
      const initialAnswersQuestions = initialAnswers.filter((obj) => obj[0] === question.id);
      if (initialAnswersQuestions.length > 0 && currentAnswersQuestions[0][1].length === 0) {
        message.error(i18n.t('docr__missing_answers'));
      } else {
        let method;
        // Answering for the first time
        if (initialAnswersQuestions.length === 0 && currentAnswersQuestions.length !== 0) method = "POST";
        // Updating an answer
        if (initialAnswersQuestions.length > 0 && currentAnswersQuestions[0][1] !== initialAnswersQuestions[0][1]) method = "PATCH";

        currentAnswersQuestions.forEach((answer) => {
          let formData = new FormData();
          formData.append("question", question.id);
          const existingFiles = [];
          answer[1].map((file) => {
            if (!file.business) {
              formData.append(file.name, file.originFileObj);
            } else {
              existingFiles.push(file.id);
            }
          });
          formData.append("old_files", existingFiles);
          promiseArray.push(
            fetch(
              `${SERVER_URL}${url}${method === "PATCH" ? answer[0] + "/" : ''}`,
              {
                // Necesario definir method así para que no levante "TypeError: Failed to execute 'fetch' on 'Window': Request with GET/HEAD method cannot have body.""
                method: method === "PATCH" ? 'PATCH' : "POST",
                headers: {
                  'Accept': 'application/json, text/plain, */*',
                  'Authorization': `Token ${props.token}`,
                },
                body: formData,
              },
            )
              .then((response) => {
                if (response.status < 400) {
                  message.success(i18n.t('docr__uploaded_docs_sent'));
                } else {
                  message.error(i18n.t('docr__error_uploading_docs').replace("{{question}}", question.name));
                }
              })
              .catch((error) => Sentry.captureException(error))
          );
        });
      }
    });
    Promise.all(promiseArray)
      .then(() => {
        handleSubmittingAnswers.off();
        goBack();
      });
  };

  return (
    <Layout style={{ width: '100%' }}>
      <Spin spinning={request === undefined || submittingAnswers}>
        <Row type="flex">
          <Col span={4} />
          <Col span={16}>
            <Row style={{ paddingBottom: '2em', paddingTop: '2em' }} type="flex">
              <a onClick={goBack} style={{ color: '#5E5E5E' }}>
                <Icon type="arrow-left"/> {i18n.t('docr__go_back_to_activities')}
              </a>
            </Row>
            <Row align="middle" justify="space-between" type="flex">
              <Col span={20}>
                <Row type="flex">
                  <Col style={{ height: '100%' }}>
                    <img src={docsIMG} style={{ width:'100%' }} />
                  </Col>
                  <Col span={18} style={{ paddingLeft: '1em' }}>
                    <Typography.Title style={{ fontSize: 22 }}>
                      {request?.name}
                    </Typography.Title>
                    <Typography.Paragraph style={{ fontSize: 16 }}>
                      {i18n.t("docr__activity_description")}
                    </Typography.Paragraph>
                  </Col>
                </Row>
              </Col>
              <Col span={4}>
                <div
                  className={completed?.initial ? 'completed-request' : 'not-completed-request'}
                >
                  <Row
                    align="stretch"
                    justify="space-around"
                    style={{
                      paddingBottom: '0.5em',
                      paddingLeft: '1.5em',
                      paddingRight: '1.5em',
                      paddingTop: '0.5em',
                      width: '100%',
                    }}
                    type="flex"
                  >
                    <Col span={12}>
                      <img
                        className={completed?.initial ? '' : 'not-completed-image'}
                        height={40}
                        src={coinIMG}
                        width={40}
                      />
                    </Col>
                    <Col span={12}>
                      <Typography.Title
                        style={{
                          color: completed?.initial ? '#FAAD14' : '#B2B2B2',
                          marginBottom: 0,
                          paddingBottom: 0,
                        }}
                      >
                        +2
                      </Typography.Title>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row
              style={{
                border: '1px solid #D9D9D9',
                padding: '1.5em',
                marginTop: '2em',
                width: '100%',
              }}
              type="flex"
            >
              {completed?.initial ? (
                <>
                  <Col span={24}>
                    <Row type="flex" justify="space-between">
                      <Typography.Title level={3}>
                        {request?.questions.length} documentos adjuntos
                      </Typography.Title>
                      <Typography.Text style={{ color: '#52C41A' }}>
                        <Icon theme="filled" type="check-circle" /> Enviados
                      </Typography.Text>
                    </Row>
                  </Col>

                  {initialAnswers.map(([questionId, answers]) => (
                    <Col span={24}>
                      <Row align="top" justify="space-between" type="flex" style={{ marginBottom: '2rem' }}>
                        <Col span={12}>
                          <Typography.Text>
                            {request.questions.find((q) => q.id === questionId).name}
                          </Typography.Text>
                        </Col>
                        <Col span={8}>
                          <Row style={{ flexDirection: 'column' }} type="flex">
                            {answers.map((answer) => (
                              <a
                                href={answer.file}
                                target="_blank"
                                type="link"
                                style={{
                                  color: '#1890FF',
                                  maxWidth: '100%',
                                  paddingRight: 0,
                                  textAlign: 'right',
                                }}
                              >
                                <Icon type="paper-clip" /> {answer.name}
                              </a>
                            ))}
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </>
              ) : (
                <>
                  <Col span={24}>
                    <Typography.Text strong>
                      {i18n.t('commons__instructions')}
                    </Typography.Text>
                    <Typography.Paragraph style={{ whiteSpace: 'pre-line' }}>{request?.instruction}</Typography.Paragraph>
                  </Col>
    
                  {/* TODO: Test */}
                  {request?.instruction_files?.map((file) => (
                    <Col span={24} style={{ padding:'0.25em' }}>
                      <Button onClick={() => getBlobObj(file)}>
                        <Icon type="paper-clip" /> {file.name}
                      </Button>
                    </Col>
                  ))}
    
                  <Col span={24}>
                    <Typography.Text strong>
                      {i18n.t('docr__upload_documents')}
                    </Typography.Text>
                  </Col>
                  {request?.questions.map((q, idx) => (
                    <>
                      <Col span={24} style={{ paddingBottom: '1em', paddingTop: '1em', width: '100%' }}>
                        <Typography.Text style={{ fontSize: '12' }}>
                          <span style={{ color: 'red' }}>* </span>
                          {q.name}
                        </Typography.Text>
                      </Col>
                      <Col className="file-uploader" span={24} style={{ paddingBottom: '1em', width: '100%' }}>
                        <Upload.Dragger
                          accept={q.extensions.map(ext => (ext.includes('.') ? ext : `.${ext}`)).join(',')}
                          key={q.id}
                          multiple={true}
                          onChange={(info) => {
                            if (info.file.status === 'uploading') {
                              info.file.status = 'done';
                              const currentFileListSize = info.fileList.reduce((x, y) => x + y.size, 0);
                              if (currentFileListSize >= 10485760) {
                                message.error(i18n.t('profile__max_size_file', { ns: 'candidateProfile' }));
                              } else if (info.fileList.length > 3) {
                                message.error(i18n.t('docr__only_three_files_per_question'));
                              } else if (!q.extensions.map((ext) => ext.includes('.') ? ext.split('.').pop() : ext).includes(info.file.name.split('.').pop())) {
                                message.error(i18n.t('form__error__cv__3'));
                              } else {
                                handleAttachFile(info, q.id);
                              }
                            } else if (info.file.status === 'error') {
                              message.error(
                                `${info.file.name} ${i18n.t('profile__no_uploaded', { ns: "candidateProfile" })}`
                              );
                            } else if (info.file.status === 'removed') {
                              handleAttachFile(info, q.id);
                            }
                          }}
                          customRequest={(response) => dummyRequest(response)}
                          fileList={getFileList(q)}
                          onPreview={(file) => getBlobObj(file)}
                        >
                          <Row align="middle" gutter={[24, 24]} justify="center" type="flex">
                            <Col
                              align="middle"
                              span={24}
                              style={{ paddingBottom: '0.5em', height: '3em', width: '100%' }}
                            >
                              <Icon style={{ color: '#1890FF', fontSize: 40 }} type="inbox" />
                            </Col>
                            <Col align="middle" span={24} style={{ height: '0.9em' }}>
                              <Typography.Text style={{ fontSize: 19 }}>
                                <span>{i18n.t('docr__attach_file_answers')}</span>
                              </Typography.Text>
                            </Col>
                            <Col align="middle" span={24}>
                              <Typography.Text style={{ color: '#5E5E5E', fontSize: 13 }}>
                                <span>
                                  {i18n.t('docr__support_extensions')}{' '}
                                  {q.extensions.join(', ')}
                                </span>
                              </Typography.Text>
                              <br />
                              <Typography.Text style={{ color: '#5E5E5E', fontSize: 13 }}>
                                <span>({i18n.t('docr__max_size')})</span>
                              </Typography.Text>
                            </Col>
                          </Row>
                        </Upload.Dragger>
                      </Col>
                    </>
                  ))}
                </>
              )}
            </Row>
            {!(completed?.initial) ? (
              <Row style={{ marginBottom: '1em', marginTop: '1em' }} type="flex">
                <Col span={4} style={{ padding: 0 }}>
                  <Button
                    disabled={!completed?.current}
                    onClick={() => (
                      Modal.confirm({
                        cancelText: i18n.t('commons__cancel'),
                        content: i18n.t('docr__confirmation__content'),
                        title: i18n.t('docr__confirmation__title'),
                        okText: i18n.t('commons__send'),
                        onOk: submitAnswers,
                      })
                    )}
                    type="primary"
                  >
                    {i18n.t('docr__send_files')}
                  </Button>
                </Col>
                <Col style={{ padding: 0 }}>
                  <Button onClick={goBack}>
                    {i18n.t('commons__cancel')}
                  </Button>
                </Col>
              </Row>
            ) : null}
          </Col>
          <Col span={4} />
        </Row>
      </Spin>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DOCRView);
