import React, { Component } from 'react'
import { 
  Row, 
  Col, 
  Input, 
  Button, 
  Modal, 
  Icon, 
  Typography,
  Switch
} from 'antd';
import { v4 as uuidv4 } from 'uuid';

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../actions/data'

import DragAndDrop from '../../../../../Common/DragAndDrop';
import './Stages.scss'

const strictStages = ['INITIAL', 'ACTIV', 'DONE', 'V_INT', 'V_INTE', 'V_INTR', 'HIRED', 'OTHER']

class Stages extends Component {
  state = {
    stageInput: '',
    stages: this.props.jobapp.stages['stages'],
    stagesDnd: this.props.jobapp.stages['stages'].filter((stage) => {
      if (!strictStages.includes(stage.code)) {
        return ({ ...stage, isDragDisable: false })
      }
    }),
    currentStage: null,
    inputVisible: false,
    applicantsStage: false || this.props.jobapp.stages.stages.find(x => x.code === 'INITIAL')
  }

  /**
   * Formatea las etapas para ingresarlas
   * Se hizo de esta forma ya que la libreria drag n drop no soporta bloquear elementos
   * Por lo que se tuvieron que agregar fuera de esta
   * @memberof Stages
   */
  normalizeAndUpdateStages = () => {
    const { jobapp } = this.props
    let newStages = this.state.stagesDnd;
    if (jobapp.interview_id) {
      newStages = [
        { code: 'ACTIV', name: 'En proceso' },
        { code: 'DONE', name: 'Completado' },
        { code: 'V_INT', name: 'Video entrevista' },
        { code: 'V_INTR', name: 'Video entrevista terminada' },
        { code: 'V_INTE', name: 'Video entrevista evaluada' },
        ...newStages,
        { code: 'HIRED', name: 'Contratado' },
        { code: 'OTHER', name: 'Descartado' }
      ]
    } else {
      newStages = [
        { code: 'ACTIV', name: 'En proceso' },
        { code: 'DONE', name: 'Completado' },
        ...newStages,
        { code: 'HIRED', name: 'Contratado' },
        { code: 'OTHER', name: 'Descartado' }
      ]
    }
    if (this.state.applicantsStage) {
      newStages = [
        { code: 'INITIAL', name: 'Postulantes' },
        ...newStages
      ]
    }
    this.props.addStage(newStages);
  }

  onChange = items => {
    let stagesDnd = [...this.state.stagesDnd]
    items.forEach((element, index) => {
      stagesDnd[index] = this.state.stagesDnd[element.id]
    });
    this.setState({ stagesDnd }, this.normalizeAndUpdateStages)
  }

  deleteStage = (index) => {
    let stagesDnd = [...this.state.stagesDnd]
    stagesDnd.splice(index, 1);

    this.setState({ stagesDnd }, this.normalizeAndUpdateStages);
  }

  onPressEnter = (value) => {
    let stagesDnd = [...this.state.stagesDnd]
    stagesDnd.push({ code: uuidv4(), name: this.state.stageInput })
    this.setState({ stagesDnd, inputVisible: false, stageInput: '' }, this.normalizeAndUpdateStages)
  }

  onChangeStageInput = e => {
    this.setState({ stageInput: e.target.value })
  }

  showModal = (index) => {
    const { jobapp } = this.props
    let url = `gamesandtests/candidatelist/${jobapp.id}/?serializer=simple&pagination=true&status=${this.state.stagesDnd[index].code}`
    this.props.actions.fetchData(this.props.token, url).then((res) => {

      // if lane has 0 candidates delete
      if (res.count == 0) {
        this.setState({ currentStage: index });
        this.infoModal(this.state.stagesDnd[index]['name'])
      }
      // else current stage null
      else {
        this.setState({ currentStage: null });
        this.errorModal(res.count)
      }
    });

  };

  infoModal = (stage) => {
    Modal.confirm({
      title: 'Advertencia',
      content: `A continuación se eliminará la etapa ${stage}. Recuerda que luego de guardar no podrás recuperar la etapa borrada.`,
      closable: true,
      maskClosable: true,
      cancelText: 'Cancelar',
      onOk: () => {
        this.deleteStage(this.state.currentStage)
        this.setState({ currentStage: null });
      },
      onCancel: () => { }
    })
  }

  errorModal = (count) => {
    Modal.error({
      title: 'Error, no es posible realizar la acción',
      content: `La etapa seleccionada contiene ${count} ${count == 1 ? "usuario activo" : "usuarios activos"}.`,
      closable: true,
      maskClosable: true,
      cancelText: 'Cancelar',
      onOk: () => {
        this.setState({ modalVisible: false, currentStage: null });
      },
      onCancel: () => { }
    })
  }

  showInput = () => {
    this.setState({ inputVisible: true });
  }

  render() {
    const { jobapp, addOns } = this.props
    const applicantsAddOn = addOns.find((addOn) => (addOn.add_on === 'APPL'));
    const { stagesDnd, stageInput, inputVisible } = this.state
    let fixedStages = []
    if (jobapp.interview_id) {
      fixedStages = ['En Proceso', 'Completado', 'Video entrevista', 'Video entrevista finalizada', 'Video entrevista evaluada']
    } else {
      fixedStages = ['En Proceso', 'Completado']
    }
    if (this.state.applicantsStage) {
      fixedStages = ['Postulantes', ...fixedStages]
    }

    // if canEdit == true, process is closed or other else is active
    const processActive = !this.props.canEdit
    return (
      <Row className='se'>
        <Col xs={16}>
          {fixedStages.map((fstage, index) => <div key={index} className='dnd-wrap' style={{ display: 'flex', alignItems: 'center', paddingBottom: 16 }} >
            {/* <img style={{ paddingLeft: 8, minWidth: 35 }} src='https://dashboard.myinterview.com/static/img/icons/drag-reorder-icon.svg' /> */}
            <Icon style={{ paddingLeft: 10, fontSize: 24 }} type="lock" />
            <div className=''
              style={{
                paddingLeft: 10,
                borderRadius: '7px',
                "#4c99ea": "#fff",
                cursor: 'default',
                width: '100%'
              }}>
              {fstage}
            </div>
          </div>)}
          <DragAndDrop
            ko={false}
            onChange={this.onChange}
            isDragDisabled={processActive}
            items={stagesDnd}
            render={(index) =>
              <div>
                {stagesDnd[index]['name']}
                {!processActive && <Button icon='delete' style={{ marginLeft: 10 }} onClick={() => this.showModal(index)} />}
              </div>
            }
          >
          </DragAndDrop>
          <div className='dnd-wrap' style={{ display: 'flex', alignItems: 'center', paddingBottom: 16 }} >
            <Icon style={{ paddingLeft: 10, fontSize: 24 }} type="lock" />
            <div
              style={{
                paddingLeft: 10,
                borderRadius: '7px',
                "#4c99ea": "#fff",
                cursor: 'default',
                width: '100%'
              }}>
              Contratado
            </div>
          </div>
          <div className='dnd-wrap' style={{ display: 'flex', alignItems: 'center', paddingBottom: 16 }} >
            <Icon style={{ paddingLeft: 10, fontSize: 24 }} type="lock" />
            <div className=''
              style={{
                paddingLeft: 10,
                borderRadius: '7px',
                "#4c99ea": "#fff",
                cursor: 'default',
                width: '100%'
              }}>
              Descartado
            </div>
          </div>
        </Col>

        <Col xs={16}>
          {!processActive &&
            <div className='group'>
              <Typography.Text strong>Agrega una nueva etapa al proceso:</Typography.Text>
              <div className='group__add'>
                <div className='group__add-stage-input'>
                  <Input
                    onChange={this.onChangeStageInput}
                    value={stageInput}
                    placeholder="Escribe aquí el nombre de la nueva etapa"
                    style={{ cursor: 'pointer' }}
                  />
                </div>

                <div className='group__add-stage-button'>
                  <Button type="primary" icon='plus' onClick={this.onPressEnter} >
                    Agregar
                  </Button>
                </div>
              </div>
            </div>
          }
        </Col>
        <Col xs={16}>
          {(!processActive && applicantsAddOn) ?
            <div className='group'>
              <Typography.Text strong>Agregar etapa Postulantes </Typography.Text>
              <Switch checked={this.state.applicantsStage} onChange={(selected) => {this.setState({ applicantsStage: selected }, this.normalizeAndUpdateStages)}} />
              <div className='group__add'>
                <Typography.Paragraph>
                  Al seleccionar esta opción, los candidatos que lleguen a tu proceso solo tendrán que 
                  rellenar sus datos personales y preguntas adicionales, en caso de tener.
                  Para realizar el resto de las actividades (juegos), deberán ser movidos a la etapa 
                  <strong> En Proceso</strong>.
                </Typography.Paragraph>
              </div>
            </div> : null
          }
        </Col>
      </Row>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    addOns: state.auth.addOns
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Stages);

