import React from 'react';
import { isMobile } from 'react-device-detect';

const Cover = (props) => {
  return (
    <>
      {
        !isMobile && <div className='main-layout-cards__bg-bar' style={{ background: props.theme.background }} />
      }
      <img
        src={props.background_image}
        style={{
          width: '100%',
          position: 'absolute'
        }}
      >
      </img>
    </>

  )
}

export default Cover;

