import React from 'react';
import { Row, Col, Divider } from 'antd';
import ProfilesPDF from './ProfilesPDF';
import PercentagesPDF from './PercentagesPDF';
import TraitsPDF from './TraitsPDF';
import VideoInterviewPDF from './VideoInterviewPDF';


import moment from 'moment';

import "../../pdf-css.scss";

function SecondPagePdf({ candidates, jobName }) {
  return (
    <Row className='second-page-container'>
      <Row>
        <Col lg={12} sm={12} xs={12}>
          <img
            alt='logo'
            id='logo'
            style={{ height: '30px' }}
            src={`https://genoma-assets.s3.us-east-2.amazonaws.com/logo.svg`}
          />
        </Col>
        <Col lg={12} sm={12} xs={12}>
          <Row className='second-page-container__date second-page-container__date--small'>
            <strong>{`${moment().format('DD/MM/YYYY')}`}</strong>
          </Row>
        </Col>
      </Row>

      <Divider />

      <Col>
        <Row className='second-page-container__job-name'>{jobName}</Row>
      </Col>
      <ProfilesPDF candidates={candidates} />
      <Row className='dividers'>
        <p>Puntaje Global</p>
      </Row>
      <PercentagesPDF candidates={candidates} />
      <Row className='dividers'>
        <p>Rasgos Generales</p>
      </Row>
      <TraitsPDF candidates={candidates} />
      <Row className='dividers'>
        <p>Video entrevista</p>
      </Row>
      <VideoInterviewPDF candidates={candidates} />
      <Row style={{ minHeight: 45 }} />
    </Row>
  );
}

export default SecondPagePdf;
