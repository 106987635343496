import React, { useContext, useEffect, useState } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from 'react-router-redux';
import {
  Layout,
  Breadcrumb,
  PageHeader,
  Steps,
  Row,
  Button,
  Icon,
  message,
} from 'antd'

import * as actionCreators from '../../../../actions/data';
import { customTestContext } from '../context/CustomTestContext';

import '../CustomTestCreate.scss';

import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';

import SituationalStep2 from '../../../SituationalTests/SituationalStep2';

const { Footer } = Layout;
const { Step } = Steps;

const NewCustomTest = (props) => {
  const {
    showStep1, setShowStep1,
    showStep2, setShowStep2,
    showStep3, setShowStep3,
    showStep4, setShowStep4,
    stepCurrent, setStepCurrent,
    configStep1, configStep2,
    submitStep, postCustomTest,
    radioTimeButton, globalTimeContext,
    questionNumber, updateCustomTest,
    action, cuteType, cuteId
  } = useContext(customTestContext)

  const { title, description, skills } = configStep1;
  const { candidateTitle } = configStep2;

  const checkMinimumRequirements = () => {
    if (title !== '' && description !== '' && candidateTitle !== '' && ((!radioTimeButton && globalTimeContext === null) || (radioTimeButton && globalTimeContext !== null))) {
      if (skills.length == 0 && cuteType !== 'situationals'){
        return message.error('Los campos obligatorios no pueden estar vacíos')
      }
      if (questionNumber > 2) {
        if (action === 'new') {
          postCustomTest()
        } else if (action === 'edit') {
          updateCustomTest(cuteId)
        } else if (action === 'copy') {
          postCustomTest()
        }
      } else {
        return message.error('Debes tener un minimo de 3 preguntas creadas y configurar tus segmentos')
      }
    } else {
      return message.error('Los campos obligatorios no pueden estar vacíos')
    }
  }

  return (
    <Layout className='new-custom-test'>
      <Layout className='new-custom-test__frame'>
        <Row type='flex' className='new-custom-test__frame__breadcrumbs'>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item>Mis herramientas</Breadcrumb.Item>
            <Breadcrumb.Item>Herramientas de evaluación</Breadcrumb.Item>
            <Breadcrumb.Item>
              {cuteType === 'situationals' ? 
                'Pruebas de juicio situacional' : 
                ' Evaluaciones adicionales'}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Row>
        <PageHeader
          className='new-custom-test__frame__page-header'
          title={title ? title : 'Nueva prueba'}
          onBack={() => props.dispatch(push(`/${cuteType}`))}
        />
        <Row type='flex' justify='center'>
          <Row type='flex' className='new-custom-test__frame__steps-row'>
            <Steps className='new-custom-test__steps-row__step' current={stepCurrent}>
              <Step title='Configuración' />
              <Step title='Prueba' />
              <Step title='Niveles' />
              <Step title='Resumen' />
            </Steps>
          </Row>
        </Row>

        <Layout className='new-custom-test__frame__step-by-step'>
          {
            showStep1 ? <Step1 /> :
              showStep2 ? cuteType === 'customtest' ? <Step2 /> : <SituationalStep2 />
                : showStep3 ? <Step3 cuteType={cuteType}/> :
                  showStep4 ? <Step4 cuteType={cuteType}/> : null
          }
        </Layout>
      </Layout>
      <Footer className='new-custom-test__footer-app'>
        <Row type='flex' justify='end'>
          {
            !showStep4 ?
              (
                <React.Fragment>
                  <Button
                    className='new-custom-test__footer-app__btn-left'
                    icon='left'
                    onClick={
                      showStep1 ? () => {
                        { props.dispatch(push(`/${cuteType}`)) }
                      } : showStep2 ? () => {
                        { setShowStep1(true); setShowStep2(false); setStepCurrent(0) }
                      } : showStep3 ? () => {
                        { setShowStep2(true); setShowStep3(false); setStepCurrent(1) }
                      } : null
                    }
                  >
                    {showStep1 ? 'Cancelar' : 'Atrás'}
                  </Button>
                  <Button
                    className='new-custom-test__footer-app__btn-right'
                    type='primary'
                    onClick={
                      showStep1 ? () => {
                        { setShowStep1(false); setShowStep2(true); setStepCurrent(1); submitStep(1) }
                      } : showStep2 ? () => {
                        { setShowStep2(false); setShowStep3(true); setStepCurrent(2); submitStep(2) }
                      } : showStep3 ? () => {
                        { setShowStep3(false); setShowStep4(true); setStepCurrent(3) }
                      } : null
                    }
                  >
                    Siguiente
                    <Icon type='right' style={{ margin: '0 0 0 5px' }} />
                  </Button>
                </React.Fragment>
              ) :
              (
                <React.Fragment>
                  <Button
                    className='new-custom-test__footer-app__btn-left'
                    icon='left'
                    onClick={() => { setShowStep4(false); setShowStep3(true); setStepCurrent(2) }}
                  >
                    Atrás
                  </Button>
                  <Button
                    className='new-custom-test__footer-app__btn-right'
                    type='primary'
                    onClick={() => { checkMinimumRequirements() }}
                  >
                    {action === 'new' && 'Crear prueba'}
                    {action === 'edit' && 'Guardar prueba'}
                    {action === 'copy' && 'Copiar prueba'}
                  </Button>
                </React.Fragment>
              )
          }
        </Row>
      </Footer>
    </Layout>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch
  };
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    token: state.auth.token,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCustomTest);
