import React, { useState } from 'react';
import { Button, Icon, Typography, Col, Row, Tooltip } from 'antd';
import LaborumJobForm from './forms/JobForm';
import './Trabajando.scss';

function PostJobOnLaborum({ jobapp, shareProcessUrl, jobPortalIntegrations, publish, handleChange }) {

  const [visibleModal, setVisibleModal] = useState(false)

  const toggleForm = () => {
    setVisibleModal(!visibleModal)
  }
  const published = Boolean(jobapp.job_portals['trabajando']) && jobapp.job_portals['trabajando'].published !== false;
  return (
    <Col span={6}>
      <Row
        type='flex' justify='center' align='middle' style={{flexDirection: 'column', rowGap: '8px', padding: '20px 0px', border: '1px solid #D9D9D9'}}>
        <img src={"https://genoma-assets.s3.us-east-2.amazonaws.com/jobportals/trabajando.jpeg"} style={{width: '50px'}} title='Trabajando.com' description='pagado'/>
        <Typography.Title level={3}>Trabajando</Typography.Title>
          {published ? 
          <Button onClick={toggleForm}>
            <Icon style={{ color: "#47E197" }} type="check-circle" /> Publicado
          </Button> : jobPortalIntegrations.trabajando.active ? 
          <Button onClick={toggleForm} className='share-card-button'>
            Publicar en Trabajando
          </Button> : <Tooltip title="Comunícate con nosotros para integrar a Trabajando.com"><span>No disponible</span></Tooltip>}
      </Row>
      <LaborumJobForm shareProcessUrl={shareProcessUrl} publish={publish} jobApp={jobapp} visible={visibleModal} closeForm={toggleForm} handleChange={handleChange} />
    </Col>
  )
};

export default PostJobOnLaborum;