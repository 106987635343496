import React from 'react';

import BreadcrumPage from '../../../pdfCommons/Breadcrum';
import CommonHeader from '../../../pdfCommons/CommonHeader';
import FooterPage from '../../../pdfCommons/FooterPage';

const Page4 = ({ candidateInfo, jobName }) => {
  return (
    <main className='dat-pdf-modal__mainsection__page4'>
      <section className='dat-pdf-modal__mainsection__page4__header'>
        <BreadcrumPage candidateInfo={candidateInfo} jobName={jobName}/>
        <CommonHeader reportName={"Diccionario"}/>
      </section>
      <hr style={{ margin: '14px 0 20px 0' }}/>

      <section className='dat-pdf-modal__mainsection__page4__references'>
        <h6>Referencias</h6>
        <ol>
          <li>
            1. Acar, S., Runco, M. A. (2014). Assessing associative distance among ideas elicited by tests of divergent thinking. Creativ. Res. J. 26, 229–238.
          </li>
          <li>
            2. Becker, M., Wiedemann, G., Kuhn, S. (2018). Quantifying insightful problem solving: A modified compound remote associates paradigm using lexical priming to parametrically modulate different sources of task difficulty. Psychol. Res. 84, 528–545.
          </li>
          <li>
            3. Benedek, M.,Konen, T., Neubauer, A. C. (2012). Associative abilities underlying creativity. Psychol. Aesthet. Creat. Arts 6, 273–281.
          </li>
          <li>
            4. Bowden, E. M., Jung-Beeman, M. (2003). Normative data for 144 compound remote associate problems. Behav. Res. Methods Instrum. Comput. 35, 634–639.
          </li>
          <li>
            5. Hass, R. W. (2017). Semantic search during divergent thinking. Cognition 166, 344–357.
          </li>
          <li>
            6. Mednick, S. (1962). The associative basis of the creative process. Psychol. Rev. 69, 220–232.
          </li>
          <li>
            7. Olsona, J. A., Nahas, J., Chmoulevitch, D., Cropper, S. J., & Webb, M. E. (2021). Naming unrelated words predicts creativity. PNAS Proceedings of the National Academy of Sciences of the United States of America, 118(25), Article e2022340118.
          </li>
          <li>
            8. Runco, M. A. (2011). Divergent Thinking. Encyclopedia of Creativity, 400–403. doi:10.1016/b978-0-12-375038-9.00077-7 
          </li>
        </ol>
      </section>

      <FooterPage />
    </main>
  );
}
 
export default Page4;
