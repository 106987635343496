import React, { useState, useRef } from 'react'
import { connect } from "react-redux";
import { push } from 'react-router-redux';
import { bindActionCreators } from "redux";
import { v4 as randomID } from 'uuid';

import * as actionCreators from "../../../../actions/data";
import '../InterviewKit.scss';

import { Row, Col, Breadcrumb, Input, Button, Tabs, Typography, Icon, Tag, Modal, Tooltip, Form, Layout, Divider } from "antd";
import ModalDelete from '../components/ModalDelete';
import InterviewList from '../components/InterviewList';

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs

const NewInterview = (props) => {

  const textInput = useRef(null)

  const [showEdit, setShowEdit] = useState(false)
  const [specificId, setSpecificId] = useState(null)

  const [interviewkit, setInterviewkit] = useState({
    name: '',
    questions: [],
    type: 'INTERVIEW',
    // creation: Date.now()
  })
  const [inputQuestion, setInputQuestion] = useState({
    text: '',
    skill: [],
    question_number: ''
  })

  const [allTags, setAllTags] = useState({
    tags: [],
    inputVisible: false,
    inputValue: ''
  })
  const [showDelete, setShowDelete] = useState({
    state: false,
    idQuestion: ''
  });

  const handleInterviewName = (e) => {
    setInterviewkit({
      ...interviewkit,
      name: e.target.value
    })
  }

  const handleInput = (e) => {
    setInputQuestion({
      ...inputQuestion,
      [e.target.name]: e.target.value
    })
  }
  const addQuestion = () => {
    if (inputQuestion.text === '') {
      Modal.error({
        title: '¡Error!',
        content: 'Debes llenar el campo "Pregunta"',
        centered: true
      })
      return;
    }
    setInterviewkit({
      ...interviewkit,
      questions: [...interviewkit.questions, { ...inputQuestion, question_number: randomID() }]
    })
    setAllTags({
      ...allTags,
      tags: []
    })
    setInputQuestion({ text: '', skill: [], question_number: '' })

  }
  const confirmDelete = (id) => {
    const questionFilter = interviewkit.questions.filter(item => item.question_number !== id)
    setInterviewkit({
      ...interviewkit,
      questions: questionFilter
    })
    setShowDelete({ state: false, idQuestion: '' })
  }
  const cancelDelete = () => {
    setShowDelete(false)
  }
  const copyQuestion = (index) => {
    const copyInput = interviewkit.questions[index].text
    const copyTags = interviewkit.questions[index].skill
    setInputQuestion({
      text: copyInput,
      skill: copyTags
    })
    setAllTags({
      tags: copyTags
    })
  }
  const cleanInput = () => {
    setInputQuestion({ ...inputQuestion, text: '', skill: [], question_number: '' })
    setAllTags({ ...allTags, tags: [] })
    setShowEdit(false)
  }

  const newTag = () => {
    setAllTags({ ...allTags, inputVisible: true })
  }
  const newTagChange = (e) => {
    setAllTags({ ...allTags, inputValue: e.target.value })
  }
  const newTagConfirm = () => {
    if (allTags.inputValue && allTags.tags.indexOf(allTags.inputValue) === -1) {
      allTags.tags = [...allTags.tags, allTags.inputValue]
    }
    setInputQuestion({ ...inputQuestion, skill: allTags.tags })
    setAllTags({ tags: allTags.tags, inputVisible: false, inputValue: '' })
  }
  const deleteTag = (removed) => {
    const newTags = allTags.tags.filter(tag => tag !== removed)
    setInputQuestion({ ...inputQuestion, skill: newTags })
    setAllTags({ ...allTags, tags: newTags })
  }

  const clickEdit = (index, idQuestion) => {
    const { text, skill, question_number } = interviewkit.questions[index]
    setShowEdit(true)
    setInputQuestion({ ...inputQuestion, text: text, skill: skill, question_number:question_number })
    setAllTags({ ...allTags, tags: skill })
  }
  const editConfirm = () => {
    interviewkit.questions.splice(specificId, 1, inputQuestion)
    setInputQuestion({ ...inputQuestion, question_number: '', skill: [], text: '' })
    setAllTags({ ...allTags, tags: [] })
    setSpecificId(null)
  }
  const cancelEdit = () => {
    setInputQuestion({ ...inputQuestion, text: '', skill: [], question_number: '' })
    setAllTags({ ...allTags, tags: [] })
    setSpecificId(null)
    setShowEdit(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, type, questions } = interviewkit
    let endpoint = '/api/v1/evaluations/interviewkit/'
    let token = props.token
    let questionData = questions.map((value, index) => {
      return { ...value, question_number: index, question_type: type, feedback: true }
    })
    let body = { name: name, kit_type: type, questions: questionData }
    if (name === '') {
      Modal.error({
        title: '¡Error!',
        content: 'La nueva entrevista debe tener un nombre',
        centered: true
      })
      return;
    }
    try {
      props.actions.fetchAndReturn(token, endpoint, 'post', body)
        .then(res => {
          if (res.status < 400) {
            Modal.success({
              title: '¡Plantilla guardada!',
              content: 'Se guardó la plantilla configurada.',
              centered: true
            })
            props.dispatch(push('/account/interviewkit'))
          }
        })
    } catch (error) {
      console.log('Error al enviar el formulario -->', error)
    }
  }

  const cancelButton = () => {
    props.dispatch(push('/account/interviewkit'))
  }

  return (
    <Layout className="layoutCustomInterview">
      <Row className="beadcrumNav">
        <Col>
          <Breadcrumb separator=">">
            <Breadcrumb.Item href="/processes">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/tools">Mis herramientas</Breadcrumb.Item>
            <Breadcrumb.Item href="/account/interviewkit">Entrevistas Personalizadas</Breadcrumb.Item>
            <Breadcrumb.Item href="/account/interviewkit/new/interview">Plantilla Predeterminada</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row className="navTitle" gutter={20}>
        <Col>
          <Icon onClick={() => cancelButton()} type='arrow-left' style={{ fontSize: '25px' }} />
        </Col>
        <Col>
          <Title level={1}>Plantilla Predeterminada</Title>
        </Col>
      </Row>
      <Row className="infoSection">
        <Col>
          <Paragraph>Desde esta sección podrás configurar las preguntas que solicites para tus candidatos.</Paragraph>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit}>
        <Row>
          <div className="newInterviewTable">
            <Tabs>
              <TabPane key='1' tab='Vista de Edición'>
                <Row style={{ margin: '0px 20px' }}>
                  <Col>
                    <Title style={{ color: '#5E5E5E' }} level={4}>Tipo de Entrevista Personalizada</Title>
                  </Col>
                  <Col>
                    <Paragraph>Entrevista</Paragraph>
                  </Col>
                </Row>

                <Row style={{ margin: '20px 20px' }}>
                  <Col>
                    <Title level={4} style={{ color: '#5E5E5E' }} >Nombre de la Plantilla</Title>
                  </Col>
                  <Col>
                    <Input onChange={handleInterviewName} placeholder='Ingresa el nombre' size='large' />
                  </Col>
                </Row>

                <Row className="formNewQuestions">
                  <Col style={{ marginRight: '40px' }}>
                    {
                      showEdit ?
                        (<Title level={4} style={{ color: '#5E5E5E' }} >Editar pregunta</Title>) :
                        (<Title level={4} style={{ color: '#5E5E5E' }} >Nueva pregunta</Title>)
                    }
                    <Input value={inputQuestion.text} name='text' onChange={handleInput} size='large' placeholder='Ingrese la pregunta para tus candidatos' />
                  </Col>

                  <Col style={{ marginRight: '40px' }}>
                    <Title level={4} style={{ color: '#5E5E5E' }} >Etiquetas</Title>
                    {allTags.tags.map((tag, index) => {
                      const longTag = tag.length > 20
                      const tagElem = (
                        <Tag key={tag} closable onClose={() => deleteTag(tag)}>
                          {longTag ? `${tag.slice(0, 20)}...` : tag}
                        </Tag>
                      )
                      return longTag ? (
                        <Tooltip title={tag} key={tag}>
                          {tagElem}
                        </Tooltip>
                      ) : (
                        tagElem
                      )
                    })}
                    {allTags.inputVisible && (
                      <Input
                        ref={textInput}
                        type="text"
                        size="small"
                        style={{ width: 78 }}
                        value={allTags.inputValue}
                        onChange={newTagChange}
                        onBlur={() => newTagConfirm()}
                        onPressEnter={newTagConfirm}
                      />
                    )}
                    {!allTags.inputVisible && (
                      <Tag onClick={() => newTag()} style={{ background: '#fff', borderStyle: 'dashed' }}>
                        <Icon type="plus" /> New tag
                      </Tag>
                    )}
                  </Col>

                  <Col>
                    <Title level={4} style={{ color: '#5E5E5E' }} >Acciones</Title>
                    <Button icon='delete' onClick={() => cleanInput()} style={{ border: 'none', color: '#A4A4A4', boxShadow: 'none' }} />
                  </Col>
                </Row>

                <Row type="flex" align="middle" style={{ margin: '20px 20px' }}>
                  <Col>
                    {
                      showEdit ?
                        (
                          <Button icon='edit' onClick={() => { editConfirm(); setShowEdit(false) }} style={{ color: '#F175A5', border: 'none', boxShadow: 'none', padding: '0' }} >
                            Editar pregunta
                          </Button>
                        ) :
                        (
                          <Button onClick={() => addQuestion()} icon='plus-circle' style={{ color: '#F175A5', border: 'none', boxShadow: 'none', padding: '0' }} >
                            Agregar pregunta
                          </Button>
                        )
                    }
                  </Col>
                  <Divider />
                </Row>
                <InterviewList list={interviewkit.questions} setShowEdit={setShowEdit} fnDelete={setShowDelete} fnCopy={copyQuestion} fnEdit={clickEdit} setSpecificId={setSpecificId} />
              </TabPane>
            </Tabs>
          </div>
        </Row>

        <Row className="actionsNewInterview" >
          {
            showEdit ?
            (<Col style={{ marginRight: '20px' }}> <Button onClick={() => cancelEdit()}>Cancelar edicion</Button></Col>)
            :
            (<Col style={{ marginRight: '20px' }}> <Button onClick={() => cancelButton()}>Cancelar</Button></Col>)
          }
          {
            !showEdit &&
            (<Col><Button htmlType="submit" style={{ background: '#F175A5', color: 'white' }}>Crear Plantilla</Button></Col>)
          }
        </Row>
      </Form>
      <ModalDelete showDelete={showDelete} cancelDelete={cancelDelete} confirmDelete={confirmDelete} type={'pregunta'} />
    </Layout>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch
  };
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    token: state.auth.token,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewInterview);