import React from 'react';

import { Divider, Col, Row } from 'antd';

import BreadcrumPage from './Breadcrum';
import ParseHtml from '../../../../../components/parseHtml';


const Summary = ({
  candidateInfo,
  jobName,
  testName,
  description,
  dateCreated,
}) => {

  return (
    <>
      <main className="alternatives-pdf-modal__mainsection__summary">
        <section className="alternatives-pdf-modal__mainsection__summary__header">
          <BreadcrumPage candidateInfo={candidateInfo} jobName={jobName} />
          <div className="alternatives-pdf-modal__mainsection__summary__header__candidateinformation">
            <div>
              <h5>{candidateInfo.user_name}</h5>
              <p>Proceso: {jobName}</p>
              <p>
                DNI O RUT: <strong>{candidateInfo.dni}</strong>
              </p>
              {dateCreated && (
                <p>
                  Fecha de finalizacion <strong>{dateCreated}</strong>
                </p>
              )}
            </div>
            <div>
              <img src="https://genoma-assets.s3.us-east-2.amazonaws.com/logo.svg" />
            </div>
          </div>
        </section>

        <hr />

        <h4>{testName}</h4>
        <Row style={{ marginBottom: 24 }}>
          <Col span={24}>{description ? ParseHtml(description) : ''}</Col>
        </Row>

        <Divider />
      </main>
    </>
  );
};

export default Summary;
