import React, { useState } from 'react'
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../actions/data"
import LogoBusiness from './components/LogoBusiness'
import BackgroundBusiness from './components/BackgroundBusiness'
import { 
  Card, 
  Divider, 
  Button, 
  message,
  Row,
} from 'antd';

const Profile = (props) => {
  const [logo, setLogo] = useState(null)
  const [background, setBackground] = useState(null)
  const [loading, setLoading] = useState(false)

   //TODO hacer la validación antes de submit
  const handleSubmit = async () => {
    setLoading(true)

    if (logo) {
      let url = `accounts/business/${props.currentBusiness.id}/logo/${logo.name}/`
      await props.actions.sendFile(props.token, url, logo, logo.name)
    }

    if (background) {
      let url = `accounts/business/${props.currentBusiness.id}/background/${background.name}/`
      await props.actions.sendFile(props.token, url, background, background.name)
    }

    message.success('¡Actualizado! Para visualizar la actualización es necesario cerrar sesión y volver a iniciar', 5)
    setLoading(false)
  }

  return (
    <Row>
      <LogoBusiness logo={(imgInfo) => setLogo(imgInfo)} />
      <Button 
        type='primary' 
        onClick={handleSubmit} 
        loading={loading}
      >
        Guardar cambios
      </Button>
      <Divider />
      <BackgroundBusiness background={(imgInfo) => setBackground(imgInfo)} imgProcess={props.imgProcess}/>
      <Button 
        type='primary' 
        onClick={handleSubmit} 
        loading={loading}
      >
        Guardar cambios
      </Button>
    </Row>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    currentBusiness: state.auth.currentBusiness
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(Profile)
