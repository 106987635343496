import React from 'react';
import { Result, Button, Typography } from 'antd';

export default class ForbiddenUserView extends React.Component {
    render() {
        return (
            <Result
            title={<Typography.Title>Error 403</Typography.Title>}
            icon={<img src="https://genoma-assets.s3.us-east-2.amazonaws.com/genomin.png" />}
            subTitle={<><Typography.Paragraph>Error Ha intentado ingresar como candidato a la sección de empresa.</Typography.Paragraph>
            <Typography.Paragraph>Para poder realizar el inicio de sesión le recomendamos hacerlo desde un proceso</Typography.Paragraph></>
            }
            extra={<a href='/'><Button type="primary">Volver</Button></a>}
            />
        );
    }
}
