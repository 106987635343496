import React from 'react';

import { Select } from 'antd';
const { Option } = Select;

const CustomSelect = ({ options, placeholder, action, value }) => {
  return (
    <Select placeholder={placeholder} onChange={value => action(value)} value={value}>
      {options.map(opt => (
        <Option value={opt.value} key={opt.value}>
          {opt.text}
        </Option>
      ))}
    </Select>
  );
};

export default CustomSelect;
