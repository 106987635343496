import React from 'react';

import CoverPage from './components/CoverPage';
import Page1 from './components/Page1';
import Page2 from './components/Page2';
import Page3 from './components/Page3';
import Page4 from './components/Page4';

import './dat-pdf.scss';

const DatPdf = ({ nivoData, refPrint, candidateInfo, jobName, score }) => {
  return (
    <div ref={refPrint} className='dat-pdf-modal'>
      <section className='dat-pdf-modal__coverpage print'>
        <CoverPage />
      </section>
      <section className='dat-pdf-modal__mainsection print'>
        <Page1 candidateInfo={candidateInfo} jobName={jobName} score={score}/>
      </section>
      <section className='dat-pdf-modal__mainsection print'>
        <Page2 nivoData={nivoData} candidateInfo={candidateInfo} jobName={jobName}/>
      </section>
      <section className='dat-pdf-modal__mainsection print'>
        <Page3 candidateInfo={candidateInfo} jobName={jobName}/>
      </section>
      <section className='dat-pdf-modal__mainsection print'>
        <Page4 candidateInfo={candidateInfo} jobName={jobName}/>
      </section>
    </div>
  );
}

export default DatPdf;
