import React, { useState, useEffect } from 'react'

import {
  Icon, Col, Row,
} from 'antd';

function EvaluationCard({ logo, evaluationTypeImg, title, description, viewMoreContent=null, buttons=null, game=false, additionalInformation=[] }) {
  const [additionalInfoLength, setAdditionalInfoLength] = useState(24 / additionalInformation.length);
  const [openViewMore, setOpenViewMore] = useState(false);

  useEffect(() => {
    setAdditionalInfoLength(24 / additionalInformation.length)
  }, [additionalInformation])

  return (
    <Col sm={24} md={24} lg={12}>
      <div className='evaluation-detail'>
        <Row type='flex' justify='space-around'>
          <Col span={12} >
            <img src={logo} style={{ height: 50, borderRadius: game ? '50%' : '0%' }} />
          </Col>
          <Col span={12} style={{ display: 'flex', justifyContent: 'end', marginBottom: 15 }}>
            {evaluationTypeImg.map((src, idx) => <img key={idx} src={src} style={{ height: 20, paddingRight: 5 }} />)}
          </Col>
        </Row>

        <Row>
          <div className='evaluations-sub-title'>{title}</div>
          <div style={{ margin: '10px 0px' }}>{description}</div>
        </Row>
        <Row>
          {openViewMore && viewMoreContent && viewMoreContent()}
        </Row>
        <Row type='flex' gutter={[20, 20]} style={{ marginTop: 10 }}>
          {additionalInformation.map((obj, idx) => (
            <Col sm={12} md={6} lg={6} key={idx}>
              <div style={{ fontSize: obj.sm ? 13 : 16 }}>
                <Icon type={obj.icon} style={{ margin: '8px 8px 8px 0px', color: '#597EF7' }} />
                {obj.value}
              </div>
            </Col>
          ))}
        </Row>
        <Row type='flex' justify='space-around' style={{ marginTop: 10 }}>
          <Col span={16} style={{ display: 'flex', gap: 20 }}>
            {buttons && buttons()}
          </Col>
          <Col span={8} style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
            {
              (
                openViewMore ?
                  <div onClick={() => setOpenViewMore(oldState => !oldState)} style={{ cursor: 'pointer' }}>
                    <Icon type="minus" style={{ marginRight: 8 }} />Ocultar
                  </div>
                  :
                  <div onClick={() => setOpenViewMore(oldState => !oldState)} style={{ cursor: 'pointer' }}>
                    <Icon type="plus" style={{ marginRight: 8 }} />Ver más
                  </div>
              )
            }
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default EvaluationCard;
