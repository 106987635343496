import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../actions/data';
import * as authActionCreators from '../../actions/auth';

// Amplify GraphQL client, easier than Apollo when working with AppSync
import { API } from '@aws-amplify/api'
import { onCreateNotification } from '../../GraphQL/subscription';
import i18n from '../../i18n';

import {
  Badge,
  Button,
  Col,
  Divider,
  Empty,
  Icon,
  Popover,
  Row,
  Skeleton,
  Spin,
  Tabs,
  Typography
} from 'antd';

import './notification-style.scss';
import { BusinessUserNotificationItem } from './NotificationItems/BusinessUser';
import { getNotificationBusinessUser } from './NotificationItems/utils';
import { MsgPersonalUser } from './NotificationItems/PersonalUser';

const { TabPane } = Tabs;

function NotificationBell(props) {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popoverVisible, setPopoverVisible] = useState(false);

  const subscribe = () => {
    API.graphql({
      query: onCreateNotification,
      variables: {
        userId: props.userId
      }
    })
      .subscribe({
        next: notificationData => {
          props.authActions.updateNotificationCount(oldState => oldState + 1);
        }
      })
  };

  useEffect(() => {
    fetchNotificationsCount()
    subscribe();
  }, [])

  const closePopover = () => setPopoverVisible(false);

  const fetchNotifications = async () => {
    const url = `/api/v1/notifications/?status=CREATED&page=1`;
    const response = await props.actions.fetchAndReturn(props.token, url);
    const notifications = response.body ? response.body : [];
    setNotifications(notifications.results);
    setLoading(false);
  }

  const updateNotification = async (notificationId) => {
    const url = `/api/v1/notifications/${notificationId}/`;
    const body = {
      status: 'READ'
    };
    const response = await props.actions.fetchAndReturn(props.token, url, 'PATCH', body);
    if (response.status < 400) {
      closePopover();
      props.authActions.updateNotificationCount(oldState => oldState - 1);
    };
  }

  const fetchNotificationsCount = async () => {
    const url = `/api/v1/notifications/count/?status=CREATED`;
    const response = await props.actions.fetchAndReturn(props.token, url);
    const notificationCountResponse = response.body ? response.body : []
    const total = notificationCountResponse.reduce((acc, obj) => acc + obj.count, 0);
    // This should be fetched only the first time the whole app is rendered. Notification count should be 0 and updated value should be 0 + total
    props.authActions.updateNotificationCount(total);
  }

  const markAllAsRead = async () => {
    const url = `/api/v2/messaging/notificationsupdate/`;
    const response = await props.actions.fetchAndReturn(props.token, url, 'PATCH');

    if (response.status < 400) {
      props.authActions.updateNotificationCount(0);
      setNotifications([]);
    }
  }

  const onVisibleChange = visible => {
    if (visible) {
      setPopoverVisible(true);
      setLoading(true);
      fetchNotifications();
    }
    else {
      closePopover();
    }
  };

  // TODO: Agrupar también?
  const getNotificationPersonalUser = (notification) => {
    switch (notification.notification_type) {
      case 'MSG':
        return <MsgPersonalUser key={notification.id} notification={notification} callback={() => updateNotification(notification.id)} />
    }
  };

  const getBusinessUserNotifications = () => {
    const notificationCounter = {};
    notifications.forEach((notification, idx) => {
      const { job_application, notification_type, extra } = notification;
      let code;
      if (notification_type === 'STGCMPLTD') {
        // En el caso de notificaciones por etapa, es necesario identificar también la etapa para la
        // agrupación
        code = `${job_application.id}-STGCMPLTD-${extra?.stage_name ?? ''}`;
      } else if (notification_type === 'INTSTATUS') {
        // En el caso de notificaciones por estado de entrevista, es necesario identificar también el
        // estado para la agrupación
        code = `${job_application.id}-INTSTATUS-${extra?.new_status ?? ''}`;
      } else if (notification_type === 'NEWJOBAPP') {
        // En el caso de notificaciones por nuevo proceso, no es necesario identificar el proceso para
        // la agrupación
        code = 'NEWJOBAPP';
      } else {
        code = `${job_application.id}-${notification_type}`;
      }
      notifications[idx] = { ...notification, code };
      if (notificationCounter[code]) {
        notificationCounter[code] += 1;
      } else {
        notificationCounter[code] = 1;
      }
    });

    const notificationItems = []; // Components to be rendered
    const codesRendered = []; // Codes already rendered
    notifications.forEach(notification => {
      const { id, code } = notification;
      if (codesRendered.includes(code)) {
        return;
      }
      const { notificationText, redirectPath } = getNotificationBusinessUser(notification, notificationCounter[code]);
      notificationItems.push(
        <BusinessUserNotificationItem
          key={id}
          callback={() => (
            notifications
              .filter(({ code: notification_code }) => notification_code === code)
              .map(({ id: notification_id }) => updateNotification(notification_id))
          )}
          redirectPath={redirectPath}
          text={notificationText}
        />
      );
      codesRendered.push(code);
    });

    return notificationItems;
  };

  const notificationItem = () => {
    return (
      loading
        ? <Skeleton />
        : (
          !notifications?.length
            ? <Empty description={i18n.t('notifications__no_new_notifications', { ns: 'notifications' })} />
            : (
              props.userType === 'personal'
                ? notifications.map(obj => getNotificationPersonalUser(obj))
                : getBusinessUserNotifications()
            )
        )
    );
  };

  const NotificationContent = () => {
    return (
      <div className='popover-notification-content'>
        <Spin spinning={loading}>
          <Tabs
            defaultActiveKey="0"
            tabBarExtraContent={
              <Link to="/account/settings/notifications">
                <Icon type="setting" style={{ paddingTop: 15 }} />
              </Link>
            }
          >
            <TabPane
              tab={i18n.t('commons__processes')}
              key="0"
              style={{ overflow: 'auto', maxHeight: 400 }}
            >
              {notificationItem()}
            </TabPane>
          </Tabs>
        </Spin>

        <Divider style={{ margin: '8px 0' }} />
        <Link to="/notifications">
          {i18n.t('notifications__view_all', { ns: 'notifications' })}
        </Link>
      </div >
    )
  };

  const popOverTitle = (
    <Row type='flex' justify='space-between' style={{ marginTop: 5, marginBottom: 5 }}>
      <Col sm={12} style={{ display: 'flex', alignItems: 'center' }}>
        <Typography.Text strong>
          {i18n.t('commons__notifications')}
        </Typography.Text>

      </Col>
      <Col sm={12}>
        <Row type='flex' justify='end'>
          <Button
            disabled={props.notificationCount === 0}
            type='link'
            style={{ fontSize: 12 }}
            onClick={markAllAsRead}
          >
            {i18n.t('notifications__all_read', { ns: 'notifications' })}
          </Button>
        </Row>
      </Col>
    </Row>
  );

  return (
    <Row type='flex' className='custom-notification-container' style={{ height: '100%' }}>
      <Popover
        placement="bottomRight"
        title={popOverTitle}
        content={<NotificationContent />}
        trigger="click"
        arrowPointAtCenter
        visible={popoverVisible}
        onVisibleChange={(visible) => onVisibleChange(visible)}
        overlayStyle={{
          width: 400,
        }}
      >
        {
          <Badge
            count={props.notificationCount}
            overflowCount={99}
            className="custom-notification-badge"
          >
            <Icon style={{ fontSize: 26 }} type="bell" />
          </Badge>
        }
      </Popover >
    </Row>
  )
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    authActions: bindActionCreators(authActionCreators, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userID,
    userType: state.auth.userType,
    notificationCount: state.auth.notificationCount,
    token: state.auth.token,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBell);