import React from 'react';

import { Avatar, Col, Icon, Progress, Row, Tooltip, Typography } from 'antd';
import { isMobile } from 'react-device-detect';

import { recommendedVEvaluation, rateValues } from '../../helpers';
import i18n from '../../../../i18n'
const { Text } = Typography;

const GlobalEvaluation = (globalEvaluation) => {
  const GEvaluations = globalEvaluation.globalevaluation.evaluations;
  const allRecommendations =
    globalEvaluation.globalevaluation.recommendations['OBJEC'] +
    globalEvaluation.globalevaluation.recommendations['RECOM'] +
    globalEvaluation.globalevaluation.recommendations['NOREC'];
  return (
    <Col xs={24} lg={24} md={24} className="">
      <div className="global-evaluations-evaluators">
        <Text strong style={{ width: '100%' }}>
          {i18n.t('profile__evaluators', {ns: 'candidateProfile'})}
        </Text>
        <Row>
          {globalEvaluation.globalevaluation.business_evaluations.map(
            (evaluation, key) => {
              const bu = evaluation.business_user.business_user
                ? evaluation.business_user.business_user
                : evaluation.business_user.email;
              return (
                <Avatar size={35} className="avatar-VE" key={key}>
                  <Tooltip
                    key={evaluation.business_user.id}
                    placement="topLeft"
                    title={bu}
                    trigger="hover"
                  >
                    <Text>
                      {bu[0]}
                      {bu.split(' ').length > 1 ? bu.split(' ')[1][0] : bu[1]}
                    </Text>
                  </Tooltip>
                </Avatar>
              );
            }
          )}
        </Row>
      </div>

      <Col className="global-evaluations-container">
        <Row className="global-evaluations-title">
          <Text strong>{i18n.t('profile__overall_score', {ns: 'candidateProfile'})}</Text>
        </Row>
        {recommendedVEvaluation.map((recommendation) => (
          <Row
            type="flex"
            justify="space-around"
            style={{ marginLeft: '1em', marginBottom: '1em' }}
          >
            <Col lg={8} md={8} xs={14}>
              <Col lg={2} md={3} xs={3}>
                <Icon
                  type={recommendation['icon']}
                  theme="filled"
                  style={{ fontSize: 16, color: recommendation['color'] }}
                />
              </Col>
              <Col lg={14} md={14} xs={16}>
                <Text type="secondary">{recommendation['text']}</Text>
              </Col>
            </Col>
            <Col lg={16} md={16} xs={8}>
              <Progress
                percent={
                  (100 *
                    globalEvaluation.globalevaluation.recommendations[
                      recommendation['value']
                    ]) /
                  allRecommendations
                }
                format={(percent) =>
                  `${
                    globalEvaluation.globalevaluation.recommendations[
                      recommendation['value']
                    ]
                  }`
                }
                status="normal"
                strokeColor="#F175A5"
                strokeWidth={16}
              />
            </Col>
          </Row>
        ))}
      </Col>
      {GEvaluations['average'] && (
        <Col className="questions-and-answers-container">
          <Row
            style={{ marginLeft: '1em', marginTop: '1em' }}
            align="middle"
            type="flex"
            justify="space-around"
          >
            <Col lg={19} md={19} xs={18}>
              <Text strong>{i18n.t('profile__questions_and_scoring', { ns:'candidateProfile' })}</Text>
            </Col>
            <Col lg={3} md={3} xs={3}>
              {!isMobile ? <Text>{i18n.t('profile__average', { ns:'candidateProfile' })}</Text> : null}
            </Col>
            <Col lg={2} md={2} xs={4}>
              <Progress
                percent={(100 * GEvaluations['average']) / 5}
                format={(percent) =>
                  `${Math.round(GEvaluations['average'] * 10) / 10}`
                }
                type="circle"
                width={30}
                status="normal"
                strokeColor={
                  rateValues[parseInt(GEvaluations['average'] + 0.5)]['color']
                }
              />
            </Col>
          </Row>
          <Row style={{ marginLeft: '1em', marginTop: '1em',  marginBottom: '0.5em'  }} />
          {Object.keys(globalEvaluation.globalevaluation.evaluations).map(
            (evaluation, idx) => {
              return evaluation !== 'average' ? (
                <Row
                  type="flex"
                  justify="space-around"
                  className="evaluations-container"
                  align="middle"
                >
                  <Col lg={18} md={18} xs={18} style={{marginLeft: '1em', marginBottom: '1em'}}>
                    <Text>
                      {idx}. {GEvaluations[evaluation]['question']}
                    </Text>
                  </Col>
                  <Col lg={3} md={3} xs={3}>
                    {!isMobile ? (
                      <Text
                        style={{
                          float: 'right',
                          color:
                            rateValues[
                              Math.round(GEvaluations[evaluation]['rating'])
                            ]['color'],
                        }}
                      >
                        {
                          rateValues[
                            Math.round(GEvaluations[evaluation]['rating'])
                          ]['text']
                        }
                      </Text>
                    ) : null}
                  </Col>
                  <Col lg={2} md={2} xs={4}>
                    <Progress
                      percent={(100 * GEvaluations[evaluation]['rating']) / 5}
                      format={(percent) =>
                        `${
                          Math.round(GEvaluations[evaluation]['rating'] * 10) /
                          10
                        }`
                      }
                      type="circle"
                      width={26}
                      status="normal"
                      strokeColor={
                        rateValues[
                          Math.round(GEvaluations[evaluation]['rating'])
                        ]['color']
                      }
                    />
                  </Col>
                </Row>
              ) : (
                ''
              );
            }
          )}
        </Col>
      )}
    </Col>
  );
};

export default GlobalEvaluation;
