import React from 'react';

const CommonHeader = ({ reportName, hideGenomaLogo }) => {
  return (
    <div
      style={{
        margin: '20px 0 0 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {
         hideGenomaLogo ? null : <img src='https://genoma-assets.s3.us-east-2.amazonaws.com/creativity_icon.svg' style={{ width: '17px', height: '17px' }}/>
        }
        <h6
          style={{
            color: '#454545',
            margin: '0 0 0 5px',
            fontWeight: 700
          }}
        >
          {`Reporte ${reportName}`}
        </h6>
      </div>
      <img src='https://genoma-assets.s3.us-east-2.amazonaws.com/logo.svg' style={{ height: '24px' }}/>
    </div>
  );
}

export default CommonHeader;