import React from 'react';
import { Row, Col, Typography, Icon, Tabs, Divider } from 'antd';

const { Text, Title } = Typography;

const Header = ({ text }) => {
  return (
    <div className="report-pdf-header">
      <span>{text}</span>
      <Divider />
    </div>
  );
};

export default Header;
