import React from 'react';
import ReactDOM from 'react-dom';
import createHistory from 'history/createBrowserHistory';

import { authLoginUserSuccess } from './actions/auth';
import Root from './containers/Root/Root';
import configureStore from './store/configureStore';
import * as Sentry from '@sentry/browser';
import './i18n';

import { Amplify } from '@aws-amplify/core';
import config from "./aws-exports";
Amplify.configure(config);

const initialState = {};
const target = document.getElementById('root');

const history = createHistory();
const store = configureStore(initialState, history);

const node = (
    <Root store={store} history={history} />
);

const token = localStorage.getItem('token');
let user = {};
try {
    user = JSON.parse(localStorage.getItem('user'));
} catch (e) {
    // Failed to parse
}

if (token !== null) {
    store.dispatch(authLoginUserSuccess(token, user));
}

// Configuración inicial para el tracking de errores mediante Sentry
Sentry.init({
    dsn: process.env.REACT_APP_SENTRY
});


ReactDOM.render(node, target);
