import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from 'antd'
import i18n from "../../../../../../i18n";

const { Title, Text } = Typography

const styles = theme => ({
    card: {
        marginTop: 20,
        padding: "18px 20px 28px 20px",
        borderRadius: '8px',
        background: 'linear-gradient(0deg, rgba(243,243,244,0.8), rgba(243,243,244,0.5)),#fff'
    }
})

const CandidateInstructions = props => {
    const { classes, items } = props;

    const levelTwo = items.filter(e => e.code !== 'FORM' && e.code !== 'KO')
    return (
        <Fragment>
            {levelTwo.length !== 0 &&
                <div className={classes.card}>
                    <Grid container>
                        <Grid item xs={12}>
                            <div className='title'>
                            <Text strong>
                                {i18n.t('candidate_path__instructions')}
                            </Text>
                            </div>
                            <div className='item-list'><Text>{i18n.t('candidate_path__instructions__1')}</Text></div>
                            <div className='item-list'><Text>{i18n.t('candidate_path__instructions__2')}</Text></div>
                            <div className='item-list'><Text>{i18n.t('candidate_path__instructions__3')}</Text></div>
                            <div className='item-list'><Text>{i18n.t('candidate_path__instructions__4')}</Text></div>
                            <div className='item-list'><Text>{i18n.t('candidate_path__instructions__5')}</Text></div>
                            <div className='item-list'><Text>{i18n.t('candidate_path__instructions__6')}</Text></div>
                            <div className='item-list'><Text>{i18n.t('candidate_path__instructions__7')}</Text></div>
                        </Grid>
                    </Grid>
                </div>
            }
        </Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        items: state.data.data.items,
    };
};

export default withStyles(styles)(connect(mapStateToProps)(CandidateInstructions));
