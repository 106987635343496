import React from 'react';
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';

/**
 * Check props and redirect
 * @param {*} any number of props and the redirected component 
 */
const CandidateRoute = ({ component: Component, ...props }) => {

  return (
    <Route {...props} render={redirectProps => {
      if (!props.tos) {
        return <Redirect to='/tos' />        
      }
      else
        return <Component {...redirectProps} />
    }
    }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    tos: state.auth.acceptedTos,
    defaultPass: state.auth.defaultPassword,
  };
};

export default connect(mapStateToProps)(CandidateRoute);
