import { Typography } from 'antd';

const { Text } = Typography;

export const texts = {
  INFIDE: {
    5: 'Esta persona tiende  a no considerar que su  comportamiento es un ejemplo para otros ni tampoco busca convertirse en un modelo a seguir para sus subalternos. No suele comportarse de una forma que  despierte una mayor admiración o respeto en los miembros del equipo.',
    8: 'Esta persona tiene una tendencia moderada por comportarse de una forma que despierte admiración y una mayor confianza por parte de sus subalternos. No suele buscar ser un modelo a seguir en todo momento, pero en ciertas ocasiones busca inspirar a otros y dar el ejemplo a través de su propia conducta.',
    12: 'Esta persona tiene una tendencia a comportarse de una forma que  potencie la confianza y admiración en sus subalternos, principalmente a través de sus conductas y ejemplo personal. Busca convertirse en un modelo a seguir para su equipo y comunicar su visión a través de su comportamiento. ',
  },
  MOTINS: {
    5: 'Esta persona presenta una inclinación por mantener una comunicación con sus subalternos centrada en la ejecución de tareas y alcanzar metas concretas,  sin dedicar tiempo a compartir una visión a largo plazo o hacia un propósito.',
    8: 'Esta persona presenta una inclinación moderada a inspirar propósito en sus subalternos. En ciertas ocasiones muestra un enfoque centrado en el logro de metas concretas, pero en otras  fomenta una visión de largo plazo a la que el equipo puede contribuir con su trabajo.',
    12: 'Esta persona presenta una inclinación por potenciar la motivación y el sentido de propósito en sus subalternos. Suele dedicar tiempo a compartir con ellos una visión inspiradora para que  se sientan conectados a los objetivos de la organización.',
  },
  ESTINT: {
    5: 'Esta persona tiende a promover en sus subalternos métodos convencionales y prácticas conocidas para que cumplan sus tareas. Suele preferir que exista un apego a las formas tradicionales de hacer las cosas y mantener una estructura estable en lugar de buscar nuevas perspectivas y enfoques.',
    8: 'Esta persona tiende a promover de forma moderada  la exploración de nuevas alternativas e ideas en sus subalternos. En ciertas ocasiones puede recurrir a soluciones pre-establecidas y probadas, mientras que en otras puede impulsar a su equipo a generar acciones innovadoras.',
    12: 'Esta persona tiende a estimular a sus subalternos para que sean innovadores y creativos para generar soluciones a los problemas. Suele animarlos a investigar, explorar nuevas ideas y plantear formas diferentes de hacer las cosas, fomentando un ambiente creativo.',
  },
  CONIND: {
    5: 'Esta persona tiende a proporcionar una orientación uniforme y semejante para todos sus subalternos. Frente a las distintas necesidades de los trabajadores, suele aplicar guías y orientaciones que se han probado previamente y que son aplicables a la mayoría de las situaciones. ',
    8: 'Esta persona tiende a proporcionar orientación a sus subalternos combinando directrices aplicables para  todos y personalizadas a cada situación particular. En ocasiones busca entender las circunstancias individuales de cada colaborador para dirigirlo, mientras que en otras entrega guías aplicables para la mayoría.',
    12: 'Esta persona tiende a proporcionar una orientación individual y personalizada para sus subalternos. Suele dedicar tiempo para comprender las necesidades individuales de cada colaborador de manera de adaptar su orientación y ofrecer un apoyo específico en función de éstas. ',
  },
  RECCON: {
    5: 'Esta persona tiende a no utilizar sistemas de recompensas y sanciones y prefiere emplear otros enfoques para impulsar a sus subalternos hacia el logro de objetivos. Suele priorizar otras formas para motivar a los trabajadores, por ejemplo, el fomentar un propósito, un buen ambiente laboral o dar mayor autonomía.',
    8: 'Esta persona tiene una tendencia moderada por utilizar sistemas de recompensas para reconocer el logro de objetivos y sanciones para castigar los errores de sus subalternos.. Busca aplicar estas acciones de forma ocasional, por lo que podría buscar también otros enfoques para mantenerlos motivados.',
    12: 'Esta persona tiende a destacar el desempeño de sus subalternos a partir de  recompensas y castigar sus errores al aplicar sanciones. Busca entregar recompensas cuando las metas establecidas se logran a tiempo y aplicar sanciones cuando detecta que el desempeño cae por debajo de los estándares.',
  },
  MANEXC: {
    5: 'Frente a errores en el trabajo de sus subalternos, esta tiende a darles espacio para resolverlos sin imponer una forma particular para solucionarlos. Al conocer sobre un problema,  permite al equipo manejarlo sin su supervisión directa. ',
    8: 'Frente a errores en el trabajo de sus subalternos, esta persona tiene una tendencia moderada por involucrarse en su solución. Suele entregar directrices ocasionales para corregir estos problemas, sobre todo cuando éstos impactarán significativamente en los resultados.',
    12: 'Frente a errores en el trabajo de sus subalternos, esta persona tiende a  involucrarse activamente en su solución. Suele dar instrucciones directas al equipo para que estos sepan exactamente  cómo deben enfrentar el conflicto.',
  },
  LAIFAI: {
    5: 'Esta persona en general muestra una tendencia por involucrarse en las tareas de sus subalternos, sin entregar autonomía ni libertad para que ellos definan cómo realizar sus  actividades. Suele realizar  acciones de monitoreo y supervisión para intervenir activamente en la forma en que realizan sus tareas.',
    8: 'Esta persona en general muestra una tendencia moderada por brindar autonomía y libertad a sus subalternos para definir cómo realizar sus tareas y  actividades. Supervisa de forma ocasional a sus colaboradores, ya que suele preferir que ellos cuenten con la responsabilidad de definir cómo hacer su trabajo.',
    12: 'Esta persona en general muestra una tendencia por entregar autonomía y libertad a sus subalternos para definir cómo realizar sus tareas y  actividades. Suele evitar acciones de monitoreo y control de los colaboradores, interviniendo únicamente cuando se le es solicitado su apoyo.',
  },
};

export const findScore = (traits, code) => {
  return traits.find((trait) => trait.code === code)?.score;
};

export const findText = (texts, code, score) => {
  return texts[code][Object.keys(texts[code]).find((key) => score <= key)];
};

export const traitsNames = {
  INFIDE: 'Influencia idealizada',
  MOTINS: 'Motivación inspiradora',
  ESTINT: 'Estimulación intelectual',
  CONIND: 'Consideración individual',
  RECCON: 'Recompensa contingente',
  MANEXC: 'Manejo por excepción',
  LAIFAI: 'Laissez-faire',
};

export const traitsColors = {
  INFIDE: '#FF85C0',
  MOTINS: '#FF85C0',
  ESTINT: '#FF85C0',
  CONIND: '#FF85C0',
  RECCON: '#EB2F96',
  MANEXC: '#EB2F96',
  LAIFAI: '#780650',
};

export const getEvaluationText = (traits) => {
  const minScoreItem = traits.find(
    (item) => item.score === Math.min(...traits.map((item) => item.score))
  )?.code;
  const maxScoreItem = traits.find(
    (item) => item.score === Math.max(...traits.map((item) => item.score))
  )?.code;

  return (
    <Text>
      La persona evaluada tiene una tendencia a optar por un estilo de
      liderazgo de <strong>{traitsNames[maxScoreItem]}</strong> y a evitar un
      estilo de liderazgo basado en <strong>{traitsNames[minScoreItem]}</strong>
      .
    </Text>
  );
};
