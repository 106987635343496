import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as dataActionCreators from '../../../../actions/data'
import { Progress, Typography, Card, Divider, Button } from "antd";
import i18n from "../../../../i18n";

const coinIMG = 'https://genoma-assets.s3.us-east-2.amazonaws.com/coin.png';
const clockIMG = 'https://genoma-assets.s3.us-east-2.amazonaws.com/wall-clock.svg';

const { Title, Text } = Typography;

const styles = (theme) => ({
  progresSec: {
    marginTop: 88,
    marginBottom: 28,
    [theme.breakpoints.down('xs')]: {
      marginTop: 44,
      marginBottom: 14,
    },
  },
  progressMeasurement: {
    height: 23,
    width: 269,
    margin: 'auto',
    border: '1px solid #999999',
    borderRadius: 4,
  },
  progressTitle: {
    textAlign: 'center',
    fontWeight: '300',
    fontSize: 45,
    paddingTop: 10,
  },
  container: {
    paddingLeft: 15,
    paddingRight: 15,
  },
  chip: {
    backgroundColor: '#56b756',
    marginTop: '12px',
    maxWidth: '100%',
  },
  item: {
    textAlign: 'center',
  },
  '@media (max-width: 600px)': {
    progresSec: {
      paddingBottom: 20,
    },
    chip: {
      fontSize: '10px',
    },
  },
  '@media (min-width: 600px)': {
    container: {
      maxWidth: 594,
    },
  },
  '@media (min-width: 960px)': {
    container: {
      maxWidth: 938,
    },
  },
  '@media (min-width: 1280px)': {
    container: {
      maxWidth: 1170,
    },
  },
  '@media (min-width: 1920px)': {
    container: {
      maxWidth: 1872,
    },
  },
});

let hasSendedStatus = false;

const LinearBuffer = (props) => {
  const { classes, items } = props;
  const minutes = items.reduce(
    (init, next) => init + (next.progress ? next.progress.time_remaining : 0),
    0
  );
  const total = items.reduce((init, next) => init + next.time_estimated, 0);
  const progress = ((total - minutes) * 100) / total;
  if (progress === 100 && !hasSendedStatus) {
    hasSendedStatus = true;
  }

  const completedReward = minutes;
  const totalReward = items.reduce(
    (init, next) => init + next.time_estimated,
    0
  );

    return (
      <div className='candidate-progress'>
          <Card>
            <div className='name-and-progess'>
              <Text strong>{i18n.t('candidate_path__your_progress')}</Text>
              <Divider />
              <Progress percent={total != 0 ? progress : 0} strokeWidth={26} showInfo={false} />
            </div>

            <div className='rewards'>
              <div className='reward'>
                <img src={clockIMG} />
                <Text type='secondary'>{i18n.t('candidate_path__duration')} <strong>{totalReward} {i18n.t('candidate_path__minutes')}</strong></Text>
              </div>
            </div>
          </Card>

          <Card>
            <div className='total-reward'>
            <div>
              <Text type='secondary' strong>{i18n.t('candidate_path__earned_coins')}</Text> <br />
              <Text className='counter'><strong>{totalReward-completedReward} / {totalReward}</strong></Text>
            </div>
            <div>
              <img src={coinIMG} />
            </div>
            </div>
          </Card>
      </div>
       
    );
}

LinearBuffer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    status: state.data.data.status,
    items: state.data.data.items,
    jobappId: state.data.data.jobapp_id,
    token: state.auth.token,
    completed: state.data.data.items.every((item) => item.status === 'DONE'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(dataActionCreators, dispatch),
    dispatch,
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(LinearBuffer)
);
