import fetch from 'isomorphic-fetch';
import { push } from 'react-router-redux';

import { SERVER_URL } from '../utils/config';
import { GAME_DATA_SEND_BEGIN,
         GAME_DATA_SEND_FAILURE,
         GAME_DATA_SEND_SUCCESS } from '../constants';
import { alertActions } from './alerts';
import * as Sentry from '@sentry/browser';


//import { authLoginUserFailure } from './auth';

export function sendGameDataBegin(url,gameOutcome) {
    return {
        type: GAME_DATA_SEND_BEGIN,
        payload: { 
            url,
            gameOutcome
        }
    };
}

export function sendGameDataSuccess(gameOutcomeResponse) {
    return {
        type: GAME_DATA_SEND_SUCCESS,
        payload: { gameOutcomeResponse }
    };
}

export function sendGameDataFailure(gameOutcomeError) {
    return {
        type: GAME_DATA_SEND_FAILURE,
        payload: { gameOutcomeError }
    };
}


export function sendGameData(token,url,gameOutcome) {
    return dispatch => {
        dispatch(sendGameDataBegin(url,gameOutcome));
        //const outdata = ${rawdata};
        return fetch(`${SERVER_URL}/api/v1/${url}`, {
            method: 'post',
            // credentials: 'include',
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${token}`,
                "Content-Type": `application/json`,
            },
            body: `${gameOutcome}`
        })
        .then(handleErrors)
/*        .then(res => {dispatch(fetchSuccess(res.json()));
            return res.json();})*/
        .then(res => res.json())
        .then(json => {
            dispatch(sendGameDataSuccess(json));
            return Promise.resolve()
        })
        .catch(error => {
            Sentry.captureException('Send Game Data');
            dispatch(sendGameDataFailure(error));
            return Promise.reject();
        })
    };
}

export function sendGameDataAndRedirect(token,url,gameOutcome, redirect, message) {
  return dispatch => {
      dispatch(sendGameDataBegin(url,gameOutcome));
      //const outdata = ${rawdata};
      return fetch(`${SERVER_URL}/api/v1/${url}`, {
          method: 'post',
        //   credentials: 'include',
          headers: {
              Accept: 'application/json',
              Authorization: `Token ${token}`,
              "Content-Type": `application/json`,
          },
          body: `${gameOutcome}`
      })
      .then(handleErrors)
/*        .then(res => {dispatch(fetchSuccess(res.json()));
          return res.json();})*/
      .then(res => res.json())
      .then(json => {
          dispatch(sendGameDataSuccess(json));
          dispatch(push(redirect));
          dispatch(alertActions.success(message))
      })
      .catch(error => {
          Sentry.captureException('Send Game data and redirect');
          dispatch(sendGameDataFailure(error));
          dispatch(alertActions.error(" Los resultados no fueron enviados correctamente"))
          return Promise.resolve();
      })
  };
}
// Handle HTTP errors since fetch won't.
function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
