import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Sentry from '@sentry/browser';

import {
  Alert,
  Col,
  Icon,
  Layout,
  message,
  Row,
  Spin,
  Typography,
} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import './CandidateProfile.scss';

import * as actionCreators from '../../../actions/data';
import { careerStateOptions, careerTypeOptions } from '../../../constants/careers';
import useBoolean from '../../../hooks/useBoolean';
import i18n from '../../../i18n';
import AdditionalInformationSection from './components/AdditionalInformationSection';
import PersonalDataSection from './components/PersonalInformation';
import CurriculumSection from './components/Curriculum';
import EditAdditionalInformationModal from './modals/EditAdditionalInformation';
import EditPersonalDataModal from './modals/EditPersonalData';
import UploadCurriculumModal from './modals/UploadCurriculum';
import { monthsOptions } from './constants';

const { Title } = Typography

function CandidateProfile(props) {
  const [countriesOptions, setCountriesOptions] = useState();
  const [data, setData] = useState({});
  const [editAdditionalInformationVisible, setEditAdditionalInformationVisible] = useState();
  const [editCurriculumVisible, setEditCurriculumVisible] = useBoolean(false);
  const [editPersonalDataVisible, setEditPersonalDataVisible] = useBoolean(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    props.actions.fetchAndReturn(props.token, '/api/v1/accounts/personal/', 'GET')
      .then(({ body, status }) => {
        if (status >= 400) {
          message.error(i18n.t('profile__error'));
        } else {
          setData({
            ...body,
            birth_date: body.birth_date ? moment(body.birth_date) : null,
            certifications: body.certifications?.map((certification) => ({
              ...certification,
              start_date: certification.start_date ? moment(certification.start_date) : null,
              end_date: certification.end_date ? moment(certification.end_date) : null,
            })),
            country: body.country
              ? `${body.country.charAt(0).toUpperCase()}${body.country.slice(1)}`
              : null,
            experiences: body.experiences?.map((experience) => ({
              ...experience,
              month_start: experience.month_start ? monthsOptions[parseInt(experience.month_start)] : null,
              month_end: experience.month_end ? monthsOptions[parseInt(experience.month_end)] : null,
            })),
            nationality: body.nationality
              ? `${body.nationality.charAt(0).toUpperCase()}${body.nationality.slice(1)}`
              : null,
          });            
          if (countriesOptions === undefined) {
            props.actions.fetchAndReturn(props.token, '/api/v1/constants/countries/', 'GET')
              .then(({ body, status }) => {
                if (status >= 400) {
                  setCountriesOptions([]);
                } else {
                  setCountriesOptions(body);
                }
                setLoading(false);
              })
              .catch((error) => Sentry.captureException(error));
          }
        }
      })
      .catch((error) => Sentry.captureException(error));
  }, [props.token]);

  return (
    <Layout className='profile-section'>
      <Spin spinning={loading}>
        <Row
          align="middle"
          style={{ marginBottom: '1.5rem' }}
          type="flex"
        >
          <Col xs={24} lg={4}>
            <Row align="middle" type="flex">
              <Icon
                onClick={() => props.history.push('/account/settings')}
                style={{ marginRight: '5px', fontSize: '20px' }}
                type="arrow-left"
              />
              <Title level={2} style={{ margin: 0 }}>
                {i18n.t('profile__title')}
              </Title>
            </Row>
          </Col>
          <Col xs={24} lg={16}>
            <Alert
              banner
              closable={false}
              message={i18n.t('profile__text')}
              type="info"
            />
          </Col>
        </Row>
        <Row gutter={[24, 0]} justify="space-between" type="flex">
          <Col xs={24} xl={8}>
            <Row gutter={[0, 16]} style={{ flexDirection: 'column' }} type="flex">
              <Col>
                <PersonalDataSection
                  data={data}
                  setEditPersonalDataVisible={setEditPersonalDataVisible}
                />
              </Col>
              <Col>
                <CurriculumSection
                  data={data}
                  setEditCurriculumVisible={setEditCurriculumVisible}
                />
              </Col>
              {/*
                Se agrega una columna vacía para que en pantallas pequeñas, cuando las tarjetas se
                muestran apiladas hacia abajo, la tarjeta de cv no quede pegada a la de información
                académida
              */}
              <Col />
            </Row>
          </Col>
          <Col xs={24} xl={16}>
            <Row gutter={[0, 16]} style={{ flexDirection: 'column', flexWrap: 'nowrap' }} justify="start" type="flex">
              {data.careers !== undefined && (
                <Col>
                  <AdditionalInformationSection
                    addButtonSubmitMethod="POST"
                    addText={i18n.t('commons__add_career')}
                    candidateFieldName="careers"
                    data={data.careers?.map((career) => ({
                      id: career.id,
                      title: career.career_name,
                      subtitle: career.career_institution,
                      startDate: career.career_entry_year,
                      endDate: career.career_last_year ?? i18n.t('form__present'),
                      extra: `${careerTypeOptions.find(({ value }) => value === career.career_type)?.label} - ${careerStateOptions.find(({ value }) => value === career.career_state)?.label}`,
                    }))}
                    emptySubtitle={i18n.t('profile__empty_careers__subtitle')}
                    emptyTitle={i18n.t('profile__empty_careers__title')}
                    handleDelete={(item, index) => {
                      if (item.id) {
                        props.actions.fetchAndReturn(props.token, `/api/v1/accounts/careerinformations/${item.id}/`, 'DELETE')
                          .then(({ status }) => {
                            if (status === 204) {
                              message.success(i18n.t('form__successfully_uploading'));
                            } else {
                              message.error(i18n.t('profile__error_updating'));
                            }
                          })
                          .catch((error) => {
                            Sentry.captureException(error);
                            message.error(i18n.t('profile__error_updating'));
                          });
                      }
                      setData((prevData) => ({
                        ...prevData,
                        careers: prevData.careers.filter((_, i) => i !== index),
                      }));
                    }}
                    handleResponse={({ status, body }) => {
                      if (status >= 400) {
                        message.error(i18n.t('profile__error_updating'));
                      } else {
                        if (status !== 201) {
                          setData((prevData) => {
                            const newData = { ...prevData };
                            newData.careers = newData.careers.map(
                              (item) => (item.id === body.id ? body : item)
                            );
                            return newData;
                          });
                        } else {
                          setData((prevData) => ({
                            ...prevData,
                            careers: [...prevData.careers, body],
                          }));
                        }
                        message.success(i18n.t('form__successfully_uploading'));
                      }
                      return status;
                    }}
                    rawData={[...data.careers]}
                    sectionTitle={i18n.t('form__academic_info')}
                    showForm={setEditAdditionalInformationVisible}
                    submitUrl="/api/v1/accounts/careerinformations/"
                    type="list"
                  />
                </Col>
              )}
              {data.experiences !== undefined && (
                <Col>
                  <AdditionalInformationSection
                    addButtonSubmitMethod="POST"
                    addText={i18n.t('profile__add__experience')}
                    candidateFieldName="experiences"
                    data={data.experiences?.map((experience) => ({
                      id: experience.id,
                      title: experience.position,
                      subtitle: experience.company,
                      startDate: `${experience.month_start} ${experience.year_start}`,
                      endDate: (experience.month_end && experience.year_end) ? `${experience.month_end} ${experience.year_end}` : i18n.t('form__present'),
                      extra: experience.country,
                    }))}
                    emptySubtitle={i18n.t('profile__empty_experiences__subtitle')}
                    emptyTitle={i18n.t('profile__empty_experiences__title')}
                    handleDelete={(item, index) => {
                      if (item.id) {
                        props.actions.fetchAndReturn(props.token, `/api/v1/accounts/experienceinformations/${item.id}/`, 'DELETE')
                          .then(({ status }) => {
                            if (status === 204) {
                              message.success(i18n.t('form__successfully_uploading'));
                            } else {
                              message.error(i18n.t('profile__error_updating'));
                            }
                          })
                          .catch((error) => {
                            Sentry.captureException(error);
                            message.error(i18n.t('profile__error_updating'));
                          });
                      }
                      setData((prevData) => ({
                        ...prevData,
                        experiences: prevData.experiences.filter((_, i) => i !== index),
                      }));
                    }}
                    handleResponse={({ status, body }) => {
                      if (status >= 400) {
                        message.error(i18n.t('profile__error_updating'));
                      } else {
                        const parsedBody = {
                          ...body,
                          month_start: body.month_start ? monthsOptions[parseInt(body.month_start)] : null,
                          month_end: body.month_end ? monthsOptions[parseInt(body.month_end)] : null,
                        };
                        if (status !== 201) {
                          setData((prevData) => {
                            const newData = { ...prevData };
                            newData.experiences = newData.experiences.map(
                              (item) => (item.id === body.id ? parsedBody : item)
                            );
                            return newData;
                          });
                        } else {
                          setData((prevData) => ({
                            ...prevData,
                            experiences: [...prevData.experiences, parsedBody],
                          }));
                        }
                        message.success(i18n.t('form__successfully_uploading'));
                      }
                      return status;
                    }}
                    rawData={[...data.experiences]}
                    sectionTitle={i18n.t('form__experience')}
                    showForm={setEditAdditionalInformationVisible}
                    submitUrl="/api/v1/accounts/experienceinformations/"
                    type="list"
                  />
                </Col>
              )}
              {data.skills !== undefined && (
                <Col>
                  <AdditionalInformationSection
                    addButtonSubmitMethod="PATCH"
                    addText={i18n.t('profile__add_skill')}
                    candidateFieldName="skills"
                    data={data.skills}
                    emptySubtitle={i18n.t('profile__empty_skills__subtitle')}
                    emptyTitle={i18n.t('profile__empty_skills__title')}
                    handleDelete={(item) => {
                      const updatedSkills = data.skills.filter((skill) => skill !== item);
                      props.actions.fetchAndReturn(props.token, `/api/v1/accounts/personal/`, 'PATCH', { skills: updatedSkills })
                        .then(({ status, body }) => {
                          if (status === 200) {
                            setData(body);
                            message.success(i18n.t('form__successfully_uploading'));
                          } else {
                            message.error(i18n.t('profile__error_updating'));
                          }
                        })
                        .catch((error) => {
                          Sentry.captureException(error);
                          message.error(i18n.t('profile__error_updating'));
                        });
                    }}
                    handleResponse={({ status, body }) => {
                      if (status >= 400) {
                        message.error(i18n.t('profile__error_updating'));
                      } else {
                        setData(() => {
                          const newData = { ...body };
                          newData.certifications = newData.certifications?.map((certification) => ({
                            ...certification,
                            start_date: certification.start_date ? moment(certification.start_date) : null,
                            end_date: certification.end_date ? moment(certification.end_date) : null,
                          }));
                          newData.experiences = newData.experiences?.map((experience) => ({
                            ...experience,
                            month_start: experience.month_start ? monthsOptions[parseInt(experience.month_start)] : null,
                            month_end: experience.month_end ? monthsOptions[parseInt(experience.month_end)] : null,
                          }));
                          return newData;
                        });
                        message.success(i18n.t('form__successfully_uploading'));
                      }
                      return status;
                    }}
                    rawData={[...data.skills]}
                    sectionTitle={i18n.t('form__skills_and_languages')}
                    showForm={setEditAdditionalInformationVisible}
                    submitUrl="/api/v1/accounts/personal/"
                    type="tags"
                  />
                </Col>
              )}
              {data.certifications !== undefined && (
                <Col>
                  <AdditionalInformationSection
                    addButtonSubmitMethod="POST"
                    addText={i18n.t('form__add_certification')}
                    candidateFieldName="certifications"
                    data={data.certifications?.map((certification) => ({
                      id: certification.id,
                      title: certification.name,
                      subtitle: certification.institution,
                      startDate: certification.start_date.format('DD-MM-YYYY'),
                      endDate: certification.end_date?.format('DD-MM-YYYY') ?? i18n.t('form__present'),
                      extra: certification.area,
                    }))}
                    emptySubtitle={i18n.t('profile__empty_certifications__subtitle')}
                    emptyTitle={i18n.t('profile__empty_certifications__title')}
                    handleDelete={(item, index) => {
                      if (item.id) {
                        props.actions.fetchAndReturn(props.token, `/api/v1/accounts/personalusers/${data.id}/certifications/${item.id}/`, 'DELETE')
                          .then(({ status }) => {
                            if (status === 204) {
                              message.success(i18n.t('form__successfully_uploading'));
                            } else {
                              message.error(i18n.t('profile__error_updating'));
                            }
                          })
                          .catch((error) => {
                            Sentry.captureException(error);
                            message.error(i18n.t('profile__error_updating'));
                          });
                      }
                      setData((prevData) => ({
                        ...prevData,
                        certifications: prevData.certifications.filter((_, i) => i !== index),
                      }));
                    }}
                    handleResponse={({ status, body }) => {
                      if (status >= 400) {
                        message.error(i18n.t('profile__error_updating'));
                      } else {
                        const parsedBody = {
                          ...body,
                          start_date: moment(body.start_date, 'YYYY-MM-DD'),
                          end_date: body.end_date ? moment(body.end_date, 'YYYY-MM-DD') : null,
                        };
                        if (status !== 201) {
                          setData((prevData) => {
                            const newData = { ...prevData };
                            newData.certifications = newData.certifications.map(
                              (item) => (item.id === body.id ? parsedBody : item)
                            );
                            return newData;
                          });
                        } else {
                          setData((prevData) => ({
                            ...prevData,
                            certifications: [...prevData.certifications, parsedBody],
                          }));
                        }
                        message.success(i18n.t('form__successfully_uploading'));
                      }
                      return status;
                    }}
                    rawData={[...data.certifications]}
                    sectionTitle={i18n.t('form__certifications')}
                    showForm={setEditAdditionalInformationVisible}
                    submitUrl={`/api/v1/accounts/personalusers/${data.id}/certifications/`}
                    type="list"
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Spin>

      {/* MODALS */}
      {editPersonalDataVisible && (
        <EditPersonalDataModal
          closeModal={setEditPersonalDataVisible.off}
          countriesOptions={countriesOptions ?? []}
          data={data}
          setData={setData}
        />
      )}
      {editCurriculumVisible && (
        <UploadCurriculumModal
          candidateId={data.id}
          closeModal={setEditCurriculumVisible.off}
          setCv={(newCv) => setData((prevData) => ({ ...prevData, cv: newCv }))}
        />
      )}
      {editAdditionalInformationVisible?.config && (
        <EditAdditionalInformationModal
          closeModal={() => setEditAdditionalInformationVisible(undefined)}
          config={editAdditionalInformationVisible.config}
          countriesOptions={countriesOptions ?? []}
          title={editAdditionalInformationVisible.title}
        />
      )}
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

CandidateProfile.propTypes = {
  actions: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateProfile);
