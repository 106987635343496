import React, { useState } from 'react';
import { Row, Col } from 'antd';
import './SiteBackground.scss'

import UploadCoverPage from '../settingsComponents/UploadCoverPage';

const SiteBackground = (props) => {

  const [uploadedImg, setUploadedImg] = useState(null)

  const getImg = (img, info) => {
    setUploadedImg(img)
    props.background(info);
  }

  const exampleImg = (
    <div className='uploaded-bg'>
      {uploadedImg && <img src={uploadedImg} />}
    </div>
  )

  return (
    <div className='sb__bg'>
      <UploadCoverPage
        getImage={getImg}
        bgImage={props.bgImage}
        bgImageReady={props.bgImageReady}
        type='bg'
        title='Imagen para el Portal de Empleo'
        description='Imagen de portada del portal de empleo. No poner información relevante en la imagen, 
        dado que ésta puede verse cortada en función del tamaño de la pantalla y dispositivo que el postulante ocupe. 
        Además se oscurece para dar protagonismo al nombre de la empresa. Archivos soportados JPG y PNG, con un tamaño máximo de 2MB. 
        Se recomiendan imágenes de 1350 píxeles de ancho x 400 píxeles de alto.'
      />
    </div>
  )
}

export default SiteBackground;
