import React, { useState } from 'react';

import { Divider, Col, Row, Progress, Typography } from 'antd';

import RavenDictionaryComponent from '../../RavenComponent';
import BreadcrumPage from '../../../pdfCommons/Breadcrum';
import ParseHtml from '../../../../../../../components/parseHtml';

const { Text, Title } = Typography;

const colorScheme = {
  twoTone: ['#D9F7BE', '#FFCCC7'],
  threeTone: ['#D9F7BE', '#FFF1B8', '#FFCCC7'],
  fourTone: ['#D9F7BE', '#F4FFB8', '#FFD8BF', '#FFCCC7'],
  fiveTone: ['#D9F7BE', '#F4FFB8', '#FFF1B8', '#FFD8BF', '#FFCCC7'],
};
const borderColorScheme = {
  twoTone: ['#52C41A', '#FF4D4F'],
  threeTone: ['#52C41A', '#FFF1B8', '#FF4D4F'],
  fourTone: ['#52C41A', '#BAE637', '#FF7A45', '#FF4D4F'],
  fiveTone: ['#52C41A', '#BAE637', '#FAAD14', '#FF7A45', '#FF4D4F'],
};

const Summary = ({
  candidateInfo,
  jobName,
  testName,
  description,
  questions,
  scores,
  scoresConfig,
  categoriesCount,
  categoriesFiltered,
  dateCreated,
  hideGenomaLogo,
}) => {
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const SegmentComponent = () => {
    if (!scoresConfig.segments) {
      return null;
    }
    let tmpReverseSegment = [...scoresConfig.segments].reverse();
    const colorsToneArray = ['twoTone', 'threeTone', 'fourTone', 'fiveTone'];
    let colorTone = scoresConfig.segments?.length;
    colorTone = colorTone ? colorScheme[colorsToneArray[colorTone - 2]] : [];
    let borderColorTone = scoresConfig.segments?.length;
    borderColorTone = borderColorTone
      ? borderColorScheme[colorsToneArray[borderColorTone - 2]]
      : [];

    const bgColor = (obj, idx, baseColor) =>
      scores.result >= obj.min && scores.result < obj.max
        ? colorTone[idx]
        : baseColor;
    const borderColor = (obj, idx, baseColor) =>
      scores.result >= obj.min && scores.result < obj.max
        ? borderColorTone[idx]
        : baseColor;

    return (
      <>
        {scoresConfig.segments &&
          tmpReverseSegment.map((obj, idx) => {
            return (
              <Row
                key={idx}
                style={{ minHeight: 50 }}
                type="flex"
                justify="center"
              >
                <Col
                  span={6}
                  style={{
                    padding: '2px 5px 2px 5px',
                    display: 'flex',
                    alignItems: 'center',
                    background: bgColor(obj, idx, '#FFF'),
                    border: `1px solid ${borderColor(obj, idx, '#D9D9D9')}`,
                    borderRight: 0,
                  }}
                >
                  {scoresConfig.segments.length - 1 != idx
                    ? `Entre ${obj.min} y ${obj.max - 1}`
                    : `Entre ${obj.min} y ${obj.max - 1}`}
                </Col>
                <Col
                  span={6}
                  style={{
                    background: bgColor(obj, idx, '#FFF'),
                    border: `1px solid ${borderColor(obj, idx, '#D9D9D9')}`,
                    color: 'fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text strong style={{ color: 'rgba(0, 0, 0, 0.65)' }}>
                    {obj.label}
                  </Text>
                </Col>
                {obj.description && (
                  <Col
                    span={12}
                    style={{
                      padding: '2px 5px 2px 5px',
                      background: bgColor(obj, idx, '#FFF'),
                      border: `1px solid ${borderColor(obj, idx, '#FFF')}`,
                      border: `1px solid ${borderColor(obj, idx, '#D9D9D9')}`,
                    }}
                  >
                    {obj.description}
                  </Col>
                )}
              </Row>
            );
          })}
      </>
    );
  };

  const realQuestionNumber = Object.values(questions).filter(
    (q) => q.type !== 'text'
  ).length;

  return (
    <>
      <main className="alternatives-pdf-modal__mainsection__summary">
        <section className="alternatives-pdf-modal__mainsection__summary__header">
          <BreadcrumPage candidateInfo={candidateInfo} jobName={jobName} />
          <div className="alternatives-pdf-modal__mainsection__summary__header__candidateinformation">
            <div>
              <h5>{candidateInfo.user_name}</h5>
              <p>Proceso: {jobName}</p>
              <p>
                DNI O RUT: <strong>{candidateInfo.dni}</strong>
              </p>
              {dateCreated && (
                <p>
                  Fecha de finalizacion <strong>{dateCreated}</strong>
                </p>
              )}
            </div>
            <div>
              {
                hideGenomaLogo ? null :
                <img src="https://genoma-assets.s3.us-east-2.amazonaws.com/logo.svg" />
              }
            </div>
          </div>
        </section>

        <hr />

        <h4>{testName}</h4>
        <Row style={{ marginBottom: 24 }}>
          <Col span={24}>{description ? ParseHtml(description) : ''}</Col>
        </Row>

        <Divider />

        <h5>Resultados del evaluado</h5>
        <Divider />
        {
          scoresConfig.type !== 'RAVEN' ?
            <Row type="flex" justify="center" align="middle">
              <Col span={6}>
                <Row style={{ flexDirection: 'column' }}>
                  <span>
                    <strong>
                      {` ${scores.result} respuestas correctas de ${realQuestionNumber}`}
                    </strong>
                  </span>
                  <Progress
                    percent={Math.round(
                      (scores.result / realQuestionNumber) * 100
                    )}
                    strokeColor="95DE64"
                    strokeWidth={20}
                    strokeLinecap="square"
                    type="dashboard"
                    gapDegree={126}
                  />
                </Row>
              </Col>
              <Col span={18}>
                <SegmentComponent />
              </Col>
            </Row>
            :
            <RavenDictionaryComponent dictionary={scores?.custom_scores} scoresConfig={scoresConfig} />
        }

        <Divider />
        <h5>Resultado en dimensiones evaluadas</h5>

        <Row type="flex">
          {scores.category_result &&
            Object.keys(scores.category_result).map((elem) => {
              return (
                <Col span={24} key={elem} style={{ marginBottom: 15 }}>
                  <span>{capitalizeFirstLetter(elem)}</span>
                  <span>
                    <strong>
                      {` ${scores.category_result[elem]} de ${categoriesCount[elem]}`}
                    </strong>
                  </span>
                  <Progress
                    percent={Math.floor(
                      (scores.category_result[elem] / categoriesCount[elem]) *
                      100
                    )}
                    size="default"
                    strokeWidth={10}
                    strokeColor="#ADC6FF"
                  />
                </Col>
              );
            })}
        </Row>
        {
          // This is for soprole
          Object.values(scoresConfig.correct_answers).find(
            (obj) => obj.alternatives_skill
          ) && (
            <>
              <div className="break-page">
                <Row type="flex" justify="space-between">
                  <Col>
                    <Title level={4}>Componentes evaluados</Title>
                  </Col>
                  {Object.keys(categoriesFiltered).map((name, index) => (
                    <Col
                      key={index}
                      span={24}
                      style={{ padding: '5px', margin: '5px 0' }}
                      className="protected-div-cute-answer-fff"
                    >
                      <Col style={{ marginBottom: '5px ' }}>
                        <Text strong>{name}</Text>
                      </Col>
                      <Col span={24} style={{ marginBottom: '3px' }}>
                        <Text underline>
                          Los ámbitos logrados en esta competencia son:
                        </Text>
                      </Col>
                      <Col span={24}>
                        {categoriesFiltered[name].correct.map(
                          ({ alternatives_skill, id }) => (
                            <Col
                              key={id}
                              span={24}
                              style={{ padding: '0 5px' }}
                            >
                              <Text> - {alternatives_skill}</Text>
                            </Col>
                          )
                        )}
                      </Col>
                      <Col span={24} style={{ margin: '5px 0 3px 0' }}>
                        <Text underline>
                          Los ámbitos <Text strong> NO </Text> logrados en esta
                          competencia son:
                        </Text>
                      </Col>
                      <Col span={24}>
                        {categoriesFiltered[name].wrong.map(
                          ({ alternatives_skill, id }) => (
                            <Col
                              key={id}
                              span={24}
                              style={{ padding: '0 5px' }}
                            >
                              <Text> - {alternatives_skill}</Text>
                            </Col>
                          )
                        )}
                      </Col>
                    </Col>
                  ))}
                </Row>
              </div>
            </>
          )
          // End Soprole
        }
      </main>
    </>
  );
};

export default Summary;
