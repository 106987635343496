import React from 'react';
import PropTypes from 'prop-types';

import {
  Col,
  Icon,
  Input,
  Row,
  Select,
  Typography,
} from 'antd';

const { Option } = Select;
const { Text } = Typography;


// TODO: HACER VISTA RESPONSIVE
export default function ReferenceQuestionForm(props) {
  return (
    <>
      <Row
        className='reference-question'
        type='flex'
        justify='space-around'
        align='middle'
        style={{ marginTop: '15px' }}
      >
        <Col span={16}>
          <Row type='flex' align='middle'>
            <Col
              span={1}
              style={{ paddingLeft: '1vw' }}
            >
              {props.question.position+1}.
            </Col>
            <Col
              span={23}
              style={{ paddingRight: '20px' }}
            >
              <Input
                placeholder='Escribe tu pregunta'
                onChange={(e) => props.handleQuestionInputChange(props.idx, e.target.value)}
                value={props.question.text}
              />
            </Col>
          </Row>
        </Col>

        <Col
          span={6}
          style={{ paddingRight: '20px' }}
        >
          <Select
            value={props.question.type}
            onChange={(option) => props.handleQuestionTypeChange(props.idx, option)}
          >
            <Option value='singleline'>Texto corto</Option>
            <Option value='multiline'>Texto largo</Option>
            <Option value='integer'>Numérico</Option>
            <Option value='singleselection'>Selección única</Option>
            <Option value='multiselection'>Selección múltiple</Option>
          </Select>
        </Col>

        <Col span={2}>
          <Row type='flex' justify='start'>
            <Col span={8}>
              <Icon
                type='copy'
                onClick={() => props.handleDuplicateQuestion(props.idx)}
              />
            </Col>
            <Col span={12}>
              <Icon
                type='delete'
                onClick={() => props.handleRemoveQuestion(props.idx)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      
      {props.question.type.includes('selection') && (
        <>
          {/* TODO: DRAG & DROP */}
          {props.question.configuration.alternatives.map((alternativeText, alternativeIndex) => (
            <Row
              className='reference-question-alternative'
              type='flex'
              justify='space-around'
              align='middle'
              style={{ marginTop: '15px' }}
            >
              <Col span={16}>
                <Row type='flex' align='middle'>
                  <Col
                    span={2}
                    style={{ paddingLeft: '3vw' }}
                  >
                    {props.question.position+1}.{alternativeIndex+1}
                  </Col>
                  <Col
                    span={22}
                    style={{ paddingRight: '20px' }}
                  >
                    <Input
                      placeholder='Escribe una alternativa'
                      onChange={(e) => props.handleQuestionAlternatives(props.idx, alternativeIndex, e.target.value)}
                      defaultValue={alternativeText}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <Row type='flex'>
                  <Col span={2}>
                    <Icon
                      type='copy'
                      onClick={() => props.duplicateAlternative(props.idx, alternativeIndex)}
                    />
                  </Col>
                  <Col span={18}>
                    <Icon
                      type='delete'
                      onClick={() => props.deleteAlternative(props.idx, alternativeIndex)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>))
          }
          <Row className='add-alternative'>
            <Col
              offset={2}
              span={22}
              style={{ marginTop: '10px' }}
              onClick={() => props.addAlternativeInput(props.idx)}
            >
              <Icon type='plus-circle'/>
              <Text>Agregar alternativa</Text>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

ReferenceQuestionForm.propTypes = {
  question: PropTypes.shape({
    type: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    mandatory: PropTypes.bool.isRequired,
    configuration: PropTypes.shape({
      alternatives: PropTypes.arrayOf(PropTypes.string)
    }).isRequired,
    position: PropTypes.number.isRequired,
  }).isRequired,
  idx: PropTypes.number,
  addAlternativeInput: PropTypes.func.isRequired,
  deleteAlternative: PropTypes.func.isRequired,
  duplicateAlternative: PropTypes.func.isRequired,
  handleQuestionAlternatives: PropTypes.func.isRequired,
  handleQuestionInputChange: PropTypes.func.isRequired,
  handleQuestionTypeChange: PropTypes.func.isRequired,
  handleRemoveQuestion: PropTypes.func.isRequired,
  handleDuplicateQuestion: PropTypes.func.isRequired,
};
