import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Col,
  Form,
  Layout,
  message,
  Modal,
  Row,
  Typography,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import ReCAPTCHA from 'react-google-recaptcha';

import Registration from '../JobApplicationProcess/components/Cards/Registration.jsx'
import { validateDNI } from '../../utils/dniValidate';
import * as dataActions from '../../actions/data';
import * as authActions from '../../actions/auth';
import i18n from '../../i18n';
import { countriesIdentification } from '../../constants/personalUser.js';
import './signup.scss';

const { Title } = Typography;

function SignUpView(props) {
  const {
    authActions,
    dataActions,
    form,
  } = props;
  const [state, setState] = useState({
    errors: {},
    countries: [],
    tos: false,
    color_theme : {
      background: '#f175a5',
    },
    tosDialog: false,
    tokenCaptcha: null,
    fields: {},
    utm: {},
    // User related state
    email: '',
    emailConfirm: '',
    dni: '',
    password: '',
    country_document: 'chile',
  })
  const captchaRef = useRef(null);
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const verifyCallback = (response) => {
    setState((s) => ({ ...s, tokenCaptcha: response }));
  };

  const validateToNextPassword = (rule, value, callback) => {
    const re = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[\w\D]{6,}/;
    // validar si la contraseña nueva tiene un número y una letra minúscula
    if (value && !value.match(re)) {
      callback(true);
    }
    callback(); // correcto
  };

  const handleTosDialog = () => setState(
    { ...state, tosDialog: !state.tosDialog }
  );

  const handleChangeCountry = name => event => {
    const errors = {...state.errors};
    errors.dni = false;
    setState((s) => ({ ...s, [name]: event, errors: errors }));
    props.form.setFieldsValue({
      dni: '',
    });
  }

  const submitEmail = e => {
    // Check if email already exists
    e.preventDefault();
    
    const isValidEmail = emailRegex.test(this.state.email.toLowerCase());
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!isValidEmail) {
        let errors = { ...this.state.errors };
        errors.email = true;
        this.setState({ errors: errors })
      } else {
        const url = 'accounts/exist/?format=json';
        dataActions.fetchAndReturn(this.props.token, url, '{"mail": "' + this.state.email.toLowerCase() + '"}', 'get')
          .then((data) => {
            const { body } = data;
            const {
              user_exists,
              personal_user
            } = body;
            if (user_exists && personal_user) {
              props.dispatch(push(`/login?email=${this.state.email}`))
            }
          });
      }
    });
  };

  const submitSignupData = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      let myDNI = ''
      myDNI = state.dni.toLowerCase().replace(/[^\d\w]/gi, '');

      if (!state.errors.dni && !err) {
        setState({ ...state, isLoading: true })
        authActions.authSignupUser(state.email.toLowerCase(), state.password, state.tokenCaptcha, null, null, myDNI, state.country_document).then((e) => {
          setState((s) => ({ ...s, isLoading: false }))
          message.success('Registrado correctamente.')
          props.dispatch(push('/me')) 
        }).catch(e => {
          setState((s) => ({ ...s, isLoading: false }))
          message.error(e);
        })
      } else {
        const errors = { ...state.errors };
        errors.password = true;
        errors.passwordConfirm = true;
        setState((s) => ({ ...s, errors }))
      }
    });
  };

  const handleTosChange = prop => event => {
    captchaRef.current.executeAsync();
    let v = !state.tos;
    setState({ ...state, tos: v });
  };

  const handleDNIChange = (rule, value, callback) => {
    if (value && !validateDNI(state.country_document, value)) {
      const errors = { ...state.errors };
      errors.dni = true;
      setState((s) => ({ ...s, errors }));
      callback(true);
    }
    callback();
  };

  const handleChange = (prop) => (event) => {
    event.persist();
    const hideAlert = false;
    const hideErrors = { ...state.errors };
    hideErrors[prop] = false;
    setState((s) => ({ ...s, showPasswordError: hideAlert, errors: hideErrors, [prop]: event.target.value }));
  };

  const handleConfirmEmailChange = (rule, value, callback) => {
    let errors = { ...state.errors };
    if (value && value.toLowerCase() != state.email.toLowerCase()) {
      errors.emailConfirm = true;
      setState((s) => ({ ...s, errors: errors }));
      callback(i18n.t('start_process__mails__doesnt__match'));
    } else {
      errors.emailConfirm = false;
      setState((s) =>({ ...s, errors: errors }));
    }
    callback();
  };

  const checkFields = () => {
    if (
      state.password !== '' && state.tokenCaptcha !== null && state.tos &&
      (state.dni !== '' || !state.countries.includes(state.country_document.toLowerCase())) &&
      state.emailConfirm.toLowerCase().trim() === state.email.toLowerCase().trim()
    ) {
      return false
    } else {
      return true
    }
  }

  const getCountries = () => {
    const url = '/api/v1/constants/countries/?p=[country,translation]'
    dataActions.fetchAndReturn('', url, 'GET').then((response) => {
      const data = response.body
      
      setState((s) => ({ ...s, countries: data }))
    })
  }

  useEffect(() => {
    getCountries()
  }, [])

  return (
    <Layout style={{ height: '100%' }}>
      <Row style={{ height: '100%', backgroundColor: 'white' }}>
        <Col xs={24} lg={12} style={{ padding: '24px', height: '100%' }}>
          <Row type='flex' align='middle' style={{ height: '100%' }}>
            <Registration
              props={props}
              state={state}
              goBack={() => props.dispatch(push('/'))}
              handleChange={handleChange}
              submitSignupData={submitSignupData}
              handleConfirmEmailChange={handleConfirmEmailChange}
              validateToNextPassword={validateToNextPassword}
              handleChangeCountry={handleChangeCountry}
              countriesIdentification={countriesIdentification}
              handleDNIChange={handleDNIChange}
              handleTosChange={handleTosChange}
              handleTosDialog={handleTosDialog}
              checkFields={checkFields}
              captchaRef={captchaRef}
            />
          </Row>
        </Col>
        <Col xs={0} lg={12} style={{ height: '100%' }} className="candidate-login__right-side">
          <Row className="candidate-right-def">
            <Row type='flex' justify='center' align='middle' style={{ width: '100%', height: '20%' }}>
                <div style={{
                  height: '61px',
                  width: '266px',
                  // Set background image
                  backgroundImage: `url(https://genoma-assets.s3.us-east-2.amazonaws.com/Logo+Genoma.png)`,
                  // Set background size
                  backgroundSize: 'cover',
                  // Make it a background image so it will repeat
                  backgroundRepeat: 'no-repeat',
                }} />
            </Row>
            <Row style={{ width: '100%', height: '80%' }} type='flex' justify='center'>
              <div style={{
                borderRadius: '69px 69px 0px 0px',
                backgroundColor: '#f175a5',
                height: '100%',
                width: '90%',
                maxWidth: '789px',
                bottom: 0,
              }}>
                <Row
                  type='flex'
                  justify='center'
                  align='middle'
                  style={{ width: '100%', height: '30%', padding: 30 }}
                >
                  <Title level={1} style={{ color: 'white', textAlign: 'center' }}>
                    {i18n.t('login__our_dream')}
                  </Title>
                </Row>
                <Row type='flex' justify='center' align='bottom' style={{ width: '100%', height: '70%' }}>
                  <div
                    style={{
                      height: '100%',
                      width: 'inherit',
                      // Set background image
                      backgroundImage: `url(https://genoma-assets.s3.us-east-2.amazonaws.com/login-candidate-image.png)`,
                      // Set background size
                      backgroundSize: 'contain',
                      // Make it a background image so it will repeat
                      backgroundRepeat: 'no-repeat',
                      // Make the background stick to the bottom
                      backgroundPosition: 'bottom',
                    }}
                  />
                </Row>
              </div>
            </Row>
          </Row>
        </Col>
      </Row>
      <ReCAPTCHA
        ref={captchaRef}
        sitekey={process.env.REACT_APP_CAPTCHA_API_KEY}
        onChange={verifyCallback}
        hl={i18n.language.split('-')[0]}
        size='invisible'
      />
      <Modal
        title={i18n.t('form__terms_of_use__genomawork')}
        visible={state.tosDialog}
        onOk={handleTosDialog}
        onCancel={handleTosDialog}
        centered={true}
        className="terms-and-conditions-modal"
        footer={[
          <Button
            key="back"
            type="primary"
            onClick={handleTosDialog}
          >
            {i18n.t('commons__close')}
          </Button>,
        ]}
      >
        <iframe
          credentialless=''
          width="500"
          height="500"
          src="https://docs.google.com/document/d/e/2PACX-1vT4odFJk2URzMvEF3GADBndjXdLqVhPHaGNEdhEvZmieRH00HQiamys_mQECH-17aXTbiUaW2iqSiek/pub?embedded=true"
        ></iframe>
      </Modal>
    </Layout>
  )
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isAuthenticating: state.auth.isAuthenticating,
    token: state.auth.token,
    email: state.auth.userName,
    error: state.data.error,
    alerts: state.alerts,
    userID: state.auth.userID
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dataActions: bindActionCreators(dataActions, dispatch),
    authActions: bindActionCreators(authActions, dispatch),
    dispatch,

  };
};
const SignUpForm = Form.create({ name: 'signup-form' })(SignUpView);
export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm);
