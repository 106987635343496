import React from 'react';
import { ResponsiveBar } from '@nivo/bar';

const BarChart = ({ customDirection, customMargin, data, text, topic }) => {

  const label = () => {
    switch (topic) {
      case 'question':
        return (obj) => `${obj.value}%`;
      default:
        return (obj) => obj.value;
    }
  };

  const maxValue = () => {
    switch (topic) {
      case 'channel':
        return 'auto';
      case 'discard_reasons':
        return 'auto';
      case 'auto':
        return 'auto';
      case 'age':
        return 'auto';
      default:
        return 100;
    }
  }
  const axisBottom = () => {
    switch (topic) {
      case 'age':
      case 'channel':
      case 'auto':
      case 'discard_reasons':
        return  {
          tickValues: 6,
          tickPadding: 10,
          format: (e) => `${e}`,
        };
      case 'education':
        return  {
          tickPadding: 10,
          tickValues: (value) => Math.floor(value) === value,
        };
      default:
        return  {
          tickValues: 6,
          tickPadding: 10,
          format: (e) => `${e}%`,
        };
    }
  }

  const axisLeft = () => {
    switch (topic) {
      case 'channel':
        return {
          tickPadding: 4,
        }
      case 'discard_reasons':
        return {
          tickPadding: 4,
        }
      case 'age':
        return {
          legend: 'Cantidad de candidatos',
          legendOffset: -50,
        }
      default:
        return {
          tickPadding: 4,
          format: (e) => Math.floor(e) === e && e,
      }
    }
  }

  const tooltip = () => {
    const defaultStyle = {
      padding: 15,
      background: '#FFFFFF',
      boxShadow: '0px 1px 2px gray',
      borderRadius: 2,
    };

    switch (topic) {
      case 'channel':
      case 'discard_reasons':
      case 'education':
        return ({ value, indexValue }) => (
          <div style={defaultStyle}>
            <div style={{ fontSize: 14 }}>
              <p>{indexValue}:</p>
              <strong>
                {value} candidato(s)
              </strong>
            </div>
          </div>)
      default:
        return ({ value, indexValue }) => (
          <div style={defaultStyle}>
            <div style={{ fontSize: 14 }}>
              <p>{`${indexValue} ${text ?? ''}`}</p>
              <strong>
                {value}
              </strong>
            </div>
          </div>
        )
    }
  }

  return (
    <ResponsiveBar
      data={data}
      keys={['value']}
      label={label}
      labelFormat={(d) => (
        <tspan style={{ fontSize: '14px', fill: 'white' }}>
          {d.label}
        </tspan>
      )}
      minValue={0}
      maxValue={maxValue()}
      indexBy="label"
      margin={customMargin}
      padding={0.3}
      layout={customDirection}
      colors={'#096DD9'}
      axisBottom={axisBottom()}
      axisLeft={axisLeft()}
      theme={{
        grid: {
          line: {
            strokeWidth: 1,
            strokeDasharray: '4 4',
          },
        },
      }}
      tooltip={tooltip()}
    />
  )
};
export default BarChart;