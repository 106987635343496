import React, { useEffect, useState } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from 'react-router-redux';
import {
  Layout,
  Breadcrumb,
  PageHeader,
  Typography,
  Row,
  Col,
  Button,
  Input,
  Icon,
  Select,
} from 'antd';

import * as actionCreators from "../../../actions/data";

import IfExistCute from './components/CustomTestComponents/IfExistCute';
import AgreementModal from './components/CustomTestComponents/AgreementModal';
import InformationModal from './components/CustomTestComponents/InformationModal';
import InformationProcess from './components/CustomTestComponents/InformationProcess';
import DeleteConfirmModal from './components/CustomTestComponents/DeleteConfirmModal';

import './CustomTestCreate.scss';

const { Text } = Typography;
const { Option } = Select;

function CustomTestCreate(props) {
  const [customTest, setCustomtest] = useState([]);
  const [idCute, setIdCute] = useState([]);
  const [activeCustomTest, setActiveCustomTest] = useState([]);
  const [idActiveCustomTest, setIdActiveCustomTest] = useState([]);
  const [customTestSpecific, setCustomTestSpecific] = useState({ questions: { data: [] } });
  const [searchFilter, setSearchFilter] = useState({ cute: '' });
  const [showModal, setShowModal] = useState(false);
  const [showProcess, setShowProcess] = useState(false);
  const [showModalInformation, setShowModalInformation] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [agreement, setAgreement] = useState(false);
  const [loading, setLoading] = useState(true)
  const [allBusinessUser, setAllBusinessUser] = useState([]);

  const tableColumns = [
    {
      title: 'NOMBRE DE LA PRUEBA',
      dataIndex: 'title',
      key: 'title',
      width: '30%',
      ellipsis: true,
    },
    {
      title: 'N° DE PROCESOS ASIGNADOS',
      dataIndex: 'id',
      key: 'process',
      width: '15%',
      ellipsis: true,
      render: (item) => (
        <Row type='flex' align='middle'>
          {
            idActiveCustomTest.includes(item) ?
              (
                <Button
                  type='link'
                  onClick={() => { setShowProcess(true); setCustomTestSpecific(activeCustomTest[item]) }}
                  style={{ padding: '0' }}
                >
                  <Text>
                    {activeCustomTest[item].length} {activeCustomTest[item].length > 1 ? 'procesos' : 'proceso'}
                  </Text>
                </Button>
              ) :
              (
                <Text>0 procesos</Text>
              )
          }
        </Row>
      ),
    },
    {
      title: 'CREADO POR',
      dataIndex: 'created_by_detail.business_user_name',
      key: 'created_by_detail',
      width: '25%',
      ellipsis: true
    },
    {
      title: 'CREACIÓN',
      dataIndex: 'date_created',
      key: 'date_created',
      width: '15%',
      ellipsis: true,
      render: (item) => (
        <Text>{item.slice(0, 10)}</Text>
      )
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: '15%',
      ellipsis: true,
      render: (id, item) => (
        <Row type='flex' justify='end' style={{ margin: '0 0 0 0', }}>
          <Col>
            {
              // Shared with tiene al menos largo 1 (al crearse el test se comparte conmigo mismo), lboquear editar si esta comaprtido con otros bu
            }
            <Button
              disabled={!idActiveCustomTest.includes(id) && !(item.shared_with.length > 1) ? false : true}
              icon='edit'
              onClick={() => props.dispatch(push(`/customtest/edit/${id}`))}
              style={{ border: 'none', boxShadow: 'none', background: 'transparent' }}
            />
          </Col>
          <Col>
            <Button
              disabled={false}
              icon='copy'
              onClick={() => props.dispatch(push(`/customtest/copy/${id}`))}
              style={{ border: 'none', boxShadow: 'none', background: 'transparent' }}
            />
          </Col>
          <Col>
            <Button icon='info-circle' onClick={() => { setShowModalInformation(true); getCustomTestSpecific(id) }} style={{ border: 'none', boxShadow: 'none', background: 'transparent' }} />
          </Col>
          <Col>
            <Button
              disabled={!idActiveCustomTest.includes(id) && !(item.shared_with.length > 1) ? false : true}
              icon='delete'
              onClick={() => { setShowDeleteModal(true), setIdCute(id) }}
              style={{ border: 'none', boxShadow: 'none', background: 'transparent' }}
            />
          </Col>
        </Row>
      )
    }
  ]

  const getCustomTest = () => {
    let endpoint = '/api/v1/gamesandtests/customtests/'
    setLoading(true)
    props.actions.fetchAndReturn(props.token, endpoint).then(response => {
      if (response.status === 200) {
        setCustomtest(response.body.filter(cute => cute.cute_type === 'CUSTOM'))
        response.body.map(cute => {
          if (!allBusinessUser.includes(cute.created_by_detail.business_user_name) && cute.created_by_detail.business_user_name !== '') {
            setAllBusinessUser([...allBusinessUser, cute.created_by_detail.business_user_name])
          }
        })
        setLoading(false)
      } else {
        setLoading(false)
      }
    })
  }
  const getActiveCustomTest = () => {
    let endpoint = '/api/v1/gamesandtests/activecutes/'
    props.actions.fetchAndReturn(props.token, endpoint).then(response => {
      if (response.status === 200) {
        setActiveCustomTest(response.body.assigned_cutes)
        const allIDS = Object.keys(response.body.assigned_cutes).map((active) => active)
        setIdActiveCustomTest(allIDS)
      }
    })
  }
  const getCustomTestSpecific = (id) => {
    let endpoint = `/api/v1/gamesandtests/customtests/${id}/`
    props.actions.fetchAndReturn(props.token, endpoint, 'get').then(response => {
      setCustomTestSpecific(response.body)
    })
  }
  const deleteCustomTest = (id) => {
    let endpoint = `/api/v1/gamesandtests/customtests/${id}/`
    try {
      props.actions.fetchAndReturn(props.token, endpoint, 'delete').then(response => {
        if (response.status === 204) {
          getCustomTest()
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const filterCustomTest = () => {
    if (searchFilter.cute !== '') {
      const filterCute = customTest.filter(cute => {
        const result = cute.title.toUpperCase()
        return result.indexOf(searchFilter.cute.toUpperCase()) > -1
      })
      setCustomtest(filterCute)
    } else {
      getCustomTest()
    }
  }
  const changeSearchFilter = (e) => {
    setSearchFilter({ ...searchFilter, [e.target.name]: e.target.value })
  }
  const changeUserFilter = (e) => {
    if (e !== '') {
      const filterCute = customTest.filter(cute => {
        const result = cute.created_by_detail.business_user_name.toUpperCase()
        return result.indexOf(e.toUpperCase()) > -1
      })
      setCustomtest(filterCute)
    } else {
      getCustomTest()
    }
  }

  useEffect(() => {
    getCustomTest()
    getActiveCustomTest()
  }, [])

  return (
    <Layout className='custom-test-table'>
      <Row type='flex' className='custom-test-table__rowbreadcrumb'>
        <Breadcrumb separator='>'>
          <Breadcrumb.Item>Mis herramientas</Breadcrumb.Item>
          <Breadcrumb.Item>Herramientas de evaluación</Breadcrumb.Item>
          <Breadcrumb.Item>Evaluaciones adicionales</Breadcrumb.Item>
        </Breadcrumb>
      </Row>

      <PageHeader
        title='Evaluaciones adicionales'
        backIcon={<Icon type='arrow-left' />}
        onBack={() => props.dispatch(push('/home'))}
      />

      <Row className='custom-test-table__headertext'>
        <Text>
          Crea evaluaciones adicionales para evaluar a tus candidatos. Ten en cuenta que no podrás editar pruebas que se encuentren asignadas a un proceso.
        </Text>
      </Row>

      <Row type='flex' justify='space-between' className='custom-test-table__filters'>
        <Col xl={12} className='custom-test-table__filters__colinput'>
          <Row type='flex' gutter={[10, 10]} className='custom-test-table__filters__colinput__row'>
            <Col md={24} xl={12}>
              <Input.Search
                placeholder='Buscar prueba...'
                value={searchFilter.cute}
                onChange={changeSearchFilter}
                onSearch={filterCustomTest}
                name='cute'
              />
            </Col>
            <Col md={24} xl={8}>
              <Select placeholder='Creado por'
                onChange={changeUserFilter}
                className='custom-test-table__filters__colinput__row__select'
              >
                {
                  allBusinessUser.map(user => (
                    <Option key={user} value={user}>{user}</Option>
                  ))
                }
                <Option value={''}>Todos</Option>
              </Select>
            </Col>
          </Row>
        </Col>

        <Col xl={12} className='custom-test-table__filters__colbutton'>
          <Row type='flex' justify='end' className='custom-test-table__filters__colbutton__row'>
            <Button
              className='custom-test-table__filters__colbutton__row__button'
              type='primary'
              onClick={() => setShowModal(true)}
            >
              Crear prueba
            </Button>
          </Row>
        </Col>
      </Row>

      <Row className='custom-test-table__table'>
        <IfExistCute
          customTest={customTest}
          setShowModal={setShowModal}
          tableColumns={tableColumns}
          loading={loading}
        />
      </Row>
      <AgreementModal
        props={props}
        showModal={showModal}
        agreement={agreement}
        setShowModal={setShowModal}
        setAgreement={setAgreement}
      />
      <InformationModal
        showModalInformation={showModalInformation}
        customTestSpecific={customTestSpecific}
        setShowModalInformation={setShowModalInformation}
      />
      <InformationProcess
        process={customTestSpecific}
        showModal={showProcess}
        setShowModal={setShowProcess}
      />
      <DeleteConfirmModal
        deleteCustomTest={deleteCustomTest}
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        idCute={idCute}
      />
    </Layout>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch
  };
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    token: state.auth.token,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomTestCreate);