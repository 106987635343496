import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import * as authActionCreators from '../actions/auth';

export default function requireAuthentication(Component, loginType='business') {
    class AuthenticatedComponent extends React.Component {
        static propTypes = {
            isAuthenticated: PropTypes.bool.isRequired,
            location: PropTypes.shape({
                pathname: PropTypes.string.isRequired
            }).isRequired,
            dispatch: PropTypes.func.isRequired
        };

        componentWillMount() {
            this.checkAuth();
            if (this.props.token != null) {
                this.checkFirstPassword();
            }
        }

        componentWillReceiveProps(nextProps) {
            if (this.props.history.action === 'POP' && this.props.logoUrl !== this.props.imgUser) {
                this.props.authActions.updateLogo(this.props.imgUser);
            }
            this.checkAuth();
            if (this.props.token != null) {
                this.checkFirstPassword();
            }
        }

        checkFirstPassword() {
            this.props.authActions.authCheckFirstPassword(this.props.token,this.props.location.pathname);
        }

        checkAuth() {
            const loginPath = loginType === 'business' ? 'businesslogin' : 'login';
            const redirectAfterLogin = this.props.location.pathname;
            const storageToken = localStorage.getItem('token');
            if (!this.props.isAuthenticated && storageToken != null) {
                this.props.authActions.authLoginUserSuccess(storageToken, JSON.parse(localStorage.getItem('user')));
                this.props.authActions.authRedirect(redirectAfterLogin);
            } else if (!this.props.isAuthenticated) {
                this.props.authActions.authLogout(this.props.token, `/${loginPath}?next=${redirectAfterLogin}`);
            } else if (localStorage.getItem('token') == null) {
                this.props.authActions.authLogout(this.props.token, `/${loginPath}?next=${redirectAfterLogin}`,
                    { generic: 'Se ha cerrado la sesión remotamente, por favor inicie sesión nuevamente' });
            } else if (this.props.userType === 'personal' && loginType !== 'candidate') {
                    this.props.authActions.authLogout(this.props.token, '/forbiddenAccess');
            } else if (this.props.userType === 'business' && loginType !== 'business') {
                    this.props.authActions.authLogout(this.props.token, '/forbiddenAccess');
            }
        }

        render() {
            return (
                <div>
                    {this.props.isAuthenticated === true
                        ? <Component {...this.props} />
                        : null
                    }
                </div>
            );
        }
    }

    const mapStateToProps = (state) => {
        return {
            isAuthenticated: state.auth.isAuthenticated,
            userType: state.auth.userType,
            imgUser: state.auth.imgUser,
            logoUrl: state.auth.logoURL,
            token: state.auth.token
        };
    };

    const mapDispatchToProps = (dispatch) => {
        return {
            authActions: bindActionCreators(authActionCreators, dispatch),
            dispatch
        };
    };

    return connect(mapStateToProps,mapDispatchToProps)(AuthenticatedComponent);
}
