import React, { useMemo, useState } from 'react';
import { Row, Col, Typography, Avatar } from 'antd';
import propTypes from 'prop-types';

const backgroundPalette = [
  '#D3ADF7',
  '#91D5FF',
  '#87E8DE',
  '#FFD591',
  '#ADC6FF',
];
const colorPallete = ['#531DAB', '#096DD9', '#08979C', '#D46B08', '#1D39C4'];
const { Text, Title } = Typography;

/**
 * @param {Array<Object>} candidates
 * @param {string} type reasoning or cognitive, at least one is required
 * @returns
 */

const Heatmap = ({ candidates, type }) => {
  const [validData, setValidData] = useState(false);

  const allTraits = useMemo(() => {
    const tmpTraits = []
    candidates.forEach((candidate) => {
      if (candidate[`${type}_scores`].length > 0) {
        setValidData(true);
      }
      candidate[`${type}_scores`].forEach((t) => {
        // Hacer un set de las combinaciones de traits 
        // y activities para luego mapear segun esto
        if (!tmpTraits.find(x => x.code === t.code && x.game_code === t.game_code)) {
          tmpTraits.push({ game_code: t.game_code, trait: t.trait, code: t.code })
        }
      });
    })
    return tmpTraits
  }
    , [candidates])

  return (
    <Row type='flex' className='heatmap-container'>
      {validData ? (
        <>
          <Col span={8} className='heatmap-container__heatmap-questions'>
            <table>
              <tbody>
                {candidates[0]
                  ? allTraits.map((question, index) => (
                    <tr key={index}>
                      <td>
                        <Text>{question.trait}</Text>
                      </td>
                    </tr>
                  ))
                  : null}
              </tbody>
            </table>
          </Col>

          <Col span={8} className='heatmap-container__heatmap-percent'>
            <table>
              <thead>
                <tr>
                  {candidates.map(
                    (candidate, index) =>
                      candidate[`${type}_scores`].length > 0 && (
                        <th key={index}>
                          <Avatar
                            size={62}
                            style={{
                              background: backgroundPalette[index],
                              color: colorPallete[index],
                            }}
                          >
                            {(candidate.name && candidate.surname && candidate.name !== '') ? (
                              `${candidate.name[0].toUpperCase()}${candidate.surname[0].toUpperCase()}`
                            ) : (
                              `${candidate.email[0].toUpperCase()}${candidate.email[1].toUpperCase()}`
                            )}
                          </Avatar>
                        </th>
                      )
                  )}
                </tr>
              </thead>
              <tbody>
                {candidates.map((candidate, index) => (
                  <tr
                    style={{ display: 'flex', flexDirection: 'column' }}
                    key={index}
                  >
                    {candidate[`${type}_scores`]
                      ? allTraits.map((trait, index) => {
                        const candidateScore = candidate[`${type}_scores`].find(cScore => cScore.code === trait.code && cScore.game_code === trait.game_code)?.score
                        return candidateScore >= 90 ? (
                          <td key={index} style={{ background: '#C41D7F' }}>
                            {candidateScore}
                          </td>
                        ) : candidateScore >= 60 ? (
                          <td key={index} style={{ background: '#F759AB' }}>
                            {candidateScore}
                          </td>
                        ) : candidateScore ? (
                          <td key={index} style={{ background: '#FFADD2' }}>
                            {candidateScore}
                          </td>
                        ) :
                          <td key={index} style={{ background: '#000' }}>

                          </td>
                      }
                      )
                      : null}
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </>
      ) : (
        <Col span={5}>
          <Row
            type='flex'
            justify='center'
            align='middle'
            style={{ height: '100%' }}
          >
            <Title level={4} style={{ marginLeft: '10px' }}>Sin datos</Title>
          </Row>
        </Col>
      )}

      {type === 'cognitive' && validData ? (
        <Col span={4} className='heatmap-container__heatmap-colors'>
          <Col className='color-info'>
            <div
              style={{
                width: '48px',
                height: '25px',
                background: '#FFADD2',
                marginRight: '28px',
              }}
            />
            <Text>Bajo</Text>
          </Col>
          <Col className='color-info'>
            <div
              style={{
                width: '48px',
                height: '25px',
                background: '#F759AB',
                marginRight: '13px',
              }}
            />
            <Text>Medio</Text>
          </Col>
          <Col className='color-info'>
            <div
              style={{
                width: '48px',
                height: '25px',
                background: '#C41D7F',
                marginRight: '28px',
              }}
            />
            <Text>Alto</Text>
          </Col>
        </Col>
      ) : type === 'reasoning' && validData ? (
        <Col span={4} className='heatmap-container__heatmap-colors'>
          <Col className='color-info'>
            <div
              style={{
                width: '48px',
                height: '25px',
                background: '#FFADD2',
                marginRight: '28px',
              }}
            />
            <Text>Bajo</Text>
          </Col>
          <Col className='color-info'>
            <div
              style={{
                width: '48px',
                height: '25px',
                background: '#F759AB',
                marginRight: '13px',
              }}
            />
            <Text>Medio</Text>
          </Col>
          <Col className='color-info'>
            <div
              style={{
                width: '48px',
                height: '25px',
                background: '#C41D7F',
                marginRight: '28px',
              }}
            />
            <Text>Alto</Text>
          </Col>
        </Col>
      ) : null}
    </Row>
  );
};

Heatmap.propTypes = {
  candidates: propTypes.array.isRequired,
  type: propTypes.string.isRequired,
};

export default Heatmap;
