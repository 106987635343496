import React from 'react';
import { Button } from 'antd';
//import styles from '../../styles/balloon.scss';

export const tourConfig = playMeasurementGame => {
  const config = [
    {
      selector: '[data-tut="reactour__questions"]',
      content: function DemoHelperComponent() {
        return (
          <div>
            <p>
              A continuación, encontrarás una serie de afirmaciones sobre ti. <br /> Por favor, lee cada una
              y decide en qué medida estás de acuerdo o no con ellas haciendo click en una de las alternativas que se señalen. <br /> <br />
            </p>
            <p>De izquierda a derecha se mostrarán las siguientes alternativas:</p>
            <p style={{ display: 'grid', textAlign: 'center' }}>
              <span style={{ color: 'rgb(232, 74, 120)' }}>Muy en desacuerdo</span>
              <span style={{ color: 'rgb(232, 74, 120)' }}>En desacuerdo</span>
              <span style={{ color: 'rgb(172, 172, 172)' }}>Ni de acuerdo ni en desacuerdo (Neutro)</span>
              <span style={{ color: 'rgb(48, 189, 206)' }}>De acuerdo</span>
              <span style={{ color: 'rgb(48, 189, 206)' }}>Muy de acuerdo</span>
            </p>
          </div>
        );
      }
    },
    {
      selector: '[data-tut="reactour__play"]',
      content: 'Por favor responde cada afirmación incluso si no estás completamente seguro de tu respuesta.'
    },
    {
      selector: '[data-tut="reactour__percent"]',
      content: 'Siempre trendrás a la vista tu progreso en la barra de arriba. Por lo general, completar el test toma alrededor de 2 minutos.'
    },
    {
      selector: '[data-tut="reactour__play"]',
      content: function DemoHelperComponent() {
        return (
          <div>
            <p>
              <strong style={{ color: 'rgb(230, 35, 100)', fontSize: '17px' }}>
                Has finalizado la fase de instrucciones.
              </strong>
              <br /> <br /> Una vez que presiones el botón <strong style={{ color: '#ff2d7a', fontWeight: '600' }}>
                Jugar
              </strong>, comenzará la fase de medición y tus resultados serán considerados en tu evaluación.
            </p>
            <div align="center" justify="center">
              <Button type='primary' style={{background: '#ff5faa', borderColor: '#ff5faa'  }} onClick={playMeasurementGame}>Jugar</Button>
            </div>
          </div>
        );
      }
    }
  ];
  return config;
};

export const questions = [
  {
    set: [
      {
        text: 'Tengo claro lo que se espera de mí en mi rol.',
        userAnswer: null,
        index: '0'
      },
      {
        text: 'Tengo lo necesario para realizar mi trabajo lo mejor que pueda.',
        userAnswer: null,
        index: '1'
      },
      {
        text: 'Puedo aplicar mis fortalezas y habilidades en mi puesto de trabajo.',
        userAnswer: null,
        index: '2'
      },
      {
        text: 'He sido reconocido por mi desempeño o contribución en el último tiempo.',
        userAnswer: null,
        index: '3'

      },
      {
        text: 'Mi Jefatura se interesa por mis motivaciones o intereses personales.',
        userAnswer: null,
        index: '4'
      },
      {
        text: 'Mi Jefatura apoya mi desarrollo profesional, incluyendo mis metas y aspiraciones.',
        userAnswer: null,
        index: '5'

      },
      {
        text: 'Me siento cómodo compartiendo mis opiniones, incluso si estas son diferentes a las de mi Jefatura.',
        userAnswer: null,
        index: '6'
      },
      {
        text: 'Comprendo cómo contribuye mi rol al éxito de la organización.',
        userAnswer: null,
        index: '7'
      },
      {
        text: 'Las personas de mi equipo se responsabilizan por su desempeño y resultados.',
        userAnswer: null,
        index: '8'
      },
      {
        text: 'Tengo buenas relaciones con las personas de mi equipo.',
        userAnswer: null,
        index: '9'
      },
      {
        text: 'Mi Jefatura y yo tenemos conversaciones de retroalimentación recurrentes.',
        userAnswer: null,
        index: '10'
      },
      {
        text: 'He tenido oportunidades de aprender y crecer profesionalmente en el último tiempo.',
        userAnswer: null,
        index: '11'
      }
    ]
  },
  
];

export const gameConfig = {
  steps:1,
  image: 'https://genoma-assets.s3.us-east-2.amazonaws.com/PCLA.png',
  name: 'Motivación'
}