import React, { Fragment, useContext, useEffect, useState } from "react";
import { CandidateContext } from "../config/CandidateContext";

import ListItem from '../components/ListItem';
import { Button, Col, Icon, Row, Table, Tag, Typography } from 'antd';

import i18n from '../../../i18n';
import { countriesIdentification, disabilityTypeOptions, genderOptions } from '../../../constants/personalUser';
import { months } from '../helpers';
import AdditionalDocuments from '../components/AdditionalDocuments';
import CustomFields from '../components/CustomFields';
import DocsResetModal from '../../BusinessDashboard/CandidateList/components/FileRequestsModal';

const { Text } = Typography;

// TODO: Revisar este componente
const Background = () => {
  const {
    application: { additional_documents, candidate_type, date_started },
    candidate: {
      dni,
      country_document,
      birth_date,
      age,
      gender,
      education_level,
      disability,
      disability_type,
      nationality,
      full_phone,
      email,
      careers,
      experiences,
      other_contact_info,
      otherJobApps,
      id,
      phone_prefix,
      phone,
      user_name,
    },
    jobApp,
    translationFile,
    getCountryTranslation,
    filesRequests,
    token,
  } = useContext(CandidateContext);

  const [nationalityTranslation, setNationalityTranslation ] = useState('');
  const [openResetModal, setOpenResetModal] = useState(false);

  const oldProcessStyle = (row) => {
    const stageText = row.jobapp.stages.stages.find((stage) => stage.code === row.status).name
    if (row.status === 'HIRED') {
      return {
        color: 'green',
        tagText: 'commons__hired',
        stageText,
      }
    }
    if (row.state === 'DISCAR') {
      return {
        color: 'red',
        tagText: 'commons__discarded',
        stageText,
      }
    }
    return {
      color: 'blue',
      tagText: 'commons__activ',
      stageText,
    }
  }

  const columns = [
    {
      title: i18n.t('commons__process'),
      dataIndex: 'jobapp',
      key: 'jobapp',
      render: (jobapp, row) => {
        return (
          <a href={`/processes/${row.jobappId}?id=${id}`}>
            {jobapp.name}
          </a>
        );
      },
    },
    {
      title: i18n.t('commons__stage'),
      dataIndex: 'status',
      key: 'status',
      render: (status, row) => {
        return (
          <div>
            {oldProcessStyle(row).stageText}
          </div>
        );
      },
    },
    {
      title: i18n.t('commons__status'),
      dataIndex: 'state',
      key: 'state',
      render: (state, row) => {
        return (
          <Tag color={oldProcessStyle(row).color}>
            {i18n.t(oldProcessStyle(row).tagText, translationFile)}
          </Tag>
        );
      },
    },
    {
      title: i18n.t('profile__application_date', translationFile),
      dataIndex: 'date',
      key: 'date',
      render: (date) => <Text>{date}</Text>,
    },
  ];

  useEffect(() => {
    async function fetchData() {
      const newNationality = await getCountryTranslation(nationality);
      setNationalityTranslation(newNationality);
    }
    fetchData();
  }, [nationality]);

  const getFileObj = async (file) => {
    const url = file.file
    let aux = document.createElement("a");
    document.body.appendChild(aux);
    aux.style = "display: none";
    aux.href = url;
    aux.target = '_blank'
    aux.click();
    window.URL.revokeObjectURL(url);
  };
  
  const jobAppsData = (otherJobApps ?? [])
    .map((obj) => {
      return {
        jobapp: { 
          name: obj.job_application_name,
          status: obj.status,
          stages: obj.job_application_stages 
        },
        date: obj.date_started,
        jobappId: obj.job_application_id,
        state: obj.state,
        status: obj.status,
      };
    })
    .filter((obj) => obj.jobappId !== jobApp.id)
    .sort((a, b) => Date.parse(b.date) - Date.parse(a.date));

  return (
    <Row
      className="new-modal-content__background"
      type="flex"
      gutter={[16, 16]}
    >
      {candidate_type === 'passive'
        ? (
          <Col xs={24}>
            <Icon type="clock-circle" style={{ color: '#A4A4A4' }} />
            <Text style={{ color: '#A4A4A4', marginLeft: '0.25rem' }}>
              {i18n.t('profile__added_date', { ns:'candidateProfile' }).split('[')[0]} {date_started}
            </Text>
          </Col>
        ) : null}

      <Col xs={12}>
        <div className="new-modal-content__background__section">
          <Text strong className="text-16px">
            {i18n.t('profile__personal_information', translationFile)}
          </Text>
          <Text>
            <strong>{countriesIdentification[country_document?.toLowerCase()] ?? i18n.t('profile__id', translationFile)}:</strong> {dni}
          </Text>
          <Text>
            <strong>{i18n.t('commons__date_of_birth', translationFile)}:</strong> {birth_date} ({age} {i18n.t('profile__years', translationFile)})
          </Text>
          {jobApp.optional_fields.gender !== 2
            ? (
              <Text>
                <strong>{i18n.t('commons__gender', translationFile)}:</strong>{' '}
                {genderOptions[gender]}
              </Text>
            ) : null}
          <Text>
            <strong>{i18n.t('commons__academic_level', translationFile)}: </strong>
            {education_level || i18n.t('profile__not_declared', translationFile)}
          </Text>
          <Text>
            <strong>{i18n.t('commons__disability', translationFile)}:</strong> {
              disability === 'YES'
                ? (disability_type ? disabilityTypeOptions[disability_type] : i18n.t('commons__yes', translationFile))
                : (disability === 'NO' ? i18n.t('commons__no', translationFile) : i18n.t('profile__not_declared', translationFile))
            }
          </Text>
          {jobApp.optional_fields.nationality !== 2
            ? (
              <Text>
                <strong>{i18n.t('commons__nationality', translationFile)}: </strong>
                {nationalityTranslation || i18n.t('profile__not_declared', translationFile)}
              </Text>
            ) : null}
            {jobApp.optional_fields.country !== 2
            ? (
              <Text>
                <strong>{i18n.t('form__residence_country')}: </strong>
                {nationalityTranslation || i18n.t('profile__not_declared', translationFile)}
              </Text>
            ) : null}
        </div>
      </Col>

      <Col xs={12}>
        <div className="new-modal-content__background__section">
          <Text strong className="text-16px">
          {i18n.t('commons__contact_information', translationFile)}
          </Text>
          <Text style={{ wordBreak: 'break-all' }}>
            <strong>{i18n.t('commons__phone', translationFile)}: </strong>
            {phone_prefix && phone ? full_phone : i18n.t('profile__not_declared', translationFile)}
          </Text>
          <Text style={{ wordBreak: 'break-all' }}>
            <strong>{i18n.t('commons__email', translationFile)}: </strong>
            {email || i18n.t('profile__not_declared', translationFile)}
          </Text>
          {other_contact_info && 
            <Text style={{ wordBreak: 'break-all' }}>
              <strong>LinkedIn: </strong> 
              {other_contact_info.linkedin
                ? <a href={other_contact_info.linkedin}>{other_contact_info.linkedin}</a>
                : i18n.t('profile__not_declared', translationFile)
              }
            </Text>
          }
        </div>
      </Col>

      {candidate_type !== 'passive' ? (
        <>
          <Col xs={12}>
            <div className="new-modal-content__background__section">
              <Text strong className="text-16px">
                {i18n.t('commons__work_experience',translationFile)}
              </Text>
              <div>
                {experiences?.length
                  ? experiences.map((exp) => (
                    <Fragment key={exp.id}>
                      <ListItem
                        text={`${exp.position} ${i18n.t('profile__in', translationFile)} ${exp.company}`}
                        sub={`${i18n.t(months[exp.month_start])} ${exp.year_start
                          } - ${exp.year_end
                            ? `${i18n.t(months[exp.month_end]) ?? ''} ${exp.year_end}`
                            : i18n.t('form__present')
                          }`}
                      />
                    </Fragment>
                  ))
                  : i18n.t('profile__does_not_have', translationFile)}
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <div className="new-modal-content__background__section">
              <Text strong className="text-16px">
                {i18n.t('commons__academic_background', translationFile)}
              </Text>
              <div>
                {careers?.length
                  ? careers.map((career) => (
                    <Fragment key={career.id}>
                      <ListItem
                        text={`${career.career_name}, ${career.career_institution}`}
                        sub={`${career.career_entry_year} - ${career.career_last_year}`}
                      />
                    </Fragment>
                  ))
                  : i18n.t('profile__does_not_have', translationFile)}
              </div>
            </div>
          </Col>
        </>
      ) : null}

      <Col xs={12}>
        <CustomFields fieldType="USERJA" />
      </Col>
      <Col xs={12}>
        <CustomFields fieldType="CANDID" />
      </Col>

      <Col xs={24}>
        <div className="new-modal-content__background__section">
          <Row style={{ width: '100%' }}>
            <Col xs={22}>
              <Text strong className="text-16px">
                {i18n.t('profile__docs', translationFile)}
              </Text>
            </Col>
            <Col align='end' xs={2}>
              <Icon type="reload" onClick={() => setOpenResetModal(true)} />
            </Col>
          </Row>
         
          {filesRequests.map((request, idx) => (
            <Row
              align='middle'
              justify='start'
              key={idx}
              style={{ paddingBottom:'1em', width:'100%' }}
              type='flex'
            >
              <Col span={12}>
                <Icon type="folder" style={{ paddingRight: '0.5em' }} />
                <Text strong>{request.files_request.name}</Text>
              </Col>
              <Col span={12} style={{ paddingLeft: '1.25em', textAlign: 'right' }}>
                <Text style={{ color: '#A4A4A4' }}>
                  {request.files_request.questions.filter((q) => q?.answers?.files?.length).length} / {request.files_request.questions.length} {i18n.t('profile__uploaded_files', translationFile)}
                </Text>
              </Col>
              {request.status !== 'INITIAL' && request.files_request.questions.map((q, id) => (
                q.answers.files ? (
                  <Row key={id} style={{ width:'100%', padding: '0.25em', paddingLeft: '1.5em' }} type='flex'>
                    <Col span={12}>
                      <Text style={{ fontSize: 14 }}>{q.name}</Text>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}> 
                      {q.answers.files.map((obj, idx) => (
                        <Row key={`${idx}-${obj.name}`}>
                          <a onClick={() => getFileObj(obj)}>
                            <Text style={{ fontSize: 12, color:'#1890FF' }}>
                              <Icon type='paper-clip'/> {obj.name}
                            </Text>
                          </a>
                        </Row>
                      ))}
                    </Col>
                  </Row>
                ) : null
              ))}
            </Row>
          ))}

          <DocsResetModal
            candidateDocsReset={[{ candidate: { personal_user_id: id, user_name }, files_requests: filesRequests }]}
            handleCancel={setOpenResetModal}
            jobAppId={jobApp.id}
            source="candidateprofile"
            token={token}
            visible={openResetModal}
          />

        </div>
      </Col>

      {jobAppsData?.length ? (
        <Col xs={24}>
          <div
            className="new-modal-content__background__section"
            style={{ maxHeight: 360, overflowY: "scroll" }}
          >
            <Text strong className="text-16px">
              {i18n.t(
                "profile__participation_in_other_processes",
                translationFile
              )}
            </Text>
            <Table
              columns={columns}
              dataSource={jobAppsData}
              pagination={{ hideOnSinglePage: true }}
              rowKey={"jobappId"}
              locale={{
                emptyText: i18n.t("profile__does_not_have", translationFile),
              }}
            />
          </div>
        </Col>
      ) : null}

      {additional_documents?.length ? (
        <Col xs={24}>
          <div
            className="new-modal-content__background__section"
            style={{ maxHeight: 360, overflowY: "scroll" }}
          >
            <Text strong className="text-16px">
              {i18n.t("profile__additional_docs", translationFile)}
            </Text>
            <AdditionalDocuments additionalDocuments={additional_documents} />
          </div>
        </Col>
      ) : null}
    </Row>
  );
};

export default Background;
