import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../actions/data";

import { Upload, Icon, message, Typography, Row } from "antd";

const { Title, Paragraph } = Typography;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

const UploadCoverPage = (props) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [bgReady, setBgReady] = useState(false);

  const beforeUpload = (file) => {
    const isLt2MB = file.size < 2000000;
    if (!isLt2MB) {
      setBgReady(false);
      message.error("Imagen debe pesar menos de 2MB!");
    }
    return isLt2MB;
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setBgReady(true);
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        setBgReady(true);
        setImageUrl(imageUrl);
        setLoading(false);
        props.getImage(imageUrl, info.file.originFileObj, bgReady);
        message.success("Imagen adecuada, con el peso soportado");
      });
    }
  };

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const uploadButton = (
    <div className='upload-btn'>
      <span>
        <Icon type={loading ? "loading" : "plus"} />
        <div className='ant-upload-text'>Subir imagen</div>
      </span>
    </div>
  );

  return (
    <Row type='flex'>
      <div className='img-description'>
        <Title level={4} style={{ marginTop: 15 }}>
          {props.title}
        </Title>

        <Paragraph>{props.description}</Paragraph>
      </div>

      <div>
        <Upload
          name='avatar'
          listType='picture-card'
          className='avatar-uploader'
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          accept='.jpg,.jpeg,.png'
          customRequest={dummyRequest}
        >
          {imageUrl && (
            <img
              src={imageUrl}
              alt='Previsualizacion'
              style={{ width: "100%" }}
            />
          )}
          {uploadButton}
        </Upload>
      </div>
      <div className='sb__bg__preview'>
        <div className='sb__bg__preview-container'>
          <div className='sb__bg__preview-home'>
            <img
              className='sb__bg__preview-home-img'
              src='https://genoma-assets.s3.us-east-2.amazonaws.com/jobsite-assets/jobsite.png'
            />
              <div className='uploaded-bg'>{<img src={imageUrl || props.bgImage} />}</div>
          </div>
          <sup>Previsualización portada portal de empleo</sup>
        </div>
      </div>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.data.data,
    isFetching: state.data.isFetching,
    error: state.data.error,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadCoverPage);
