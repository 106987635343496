import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';

import EndProcess from './EndProcess/EndProcess'

import { withStyles } from "@material-ui/core/styles";
import { Paper, Grid, Typography, Button, Tooltip, ClickAwayListener } from "@material-ui/core";
import HeartShape from "../../../../../../assets/images/heartshapeorig2.png";
import { push } from "connected-react-router";

const styles = theme => ({
	card: {
		padding: "18px 20px 28px 20px",
		marginBottom: 20,
		borderRadius: '8px',
        background: 'linear-gradient(0deg, rgba(243,243,244,0.8), rgba(243,243,244,0.5)),#fff'
	},
	levelsTitle: {
		marginBottom: 20
	},
	heading: {
		fontWeight: "300"
	},
	root: {
		backgroundImage: `url(${HeartShape})`,
		backgroundSize: "cover",
		backgroundPosition: "center center",
		backgroundRepeat: "no-repeat",
		width: 150,
		height: 150,
		paddingTop: 55,
		textAlign: "center",
		margin: "0 auto",
		boxSizing: "border-box"
	},
	levels: {
		fontSize: 40,
		fontWeight: "900",
        color: "#f5f5f5",
        textShadow: '-1px 0 grey, 0 1px grey, 1px 0 grey, 0 -1px grey'
    },
    buttonCont: {
        paddingTop: 20,
        textAlign: "center",
    },
    disabledB: {
        filter: 'grayscale(100%)',
    },
});

const LevelsCompleted = props => {
        const { classes, items } = props;
        const minutes = items.reduce((init, next) => init + next.progress.time_remaining, 0);
        const total = items.reduce((init, next) => init + next.time_estimated, 0);
		return (
			<Fragment>
			{/*<div className={classes.card}>
				<Grid container>
					<Grid item xs={12} className={classes.levelsTitle}>
						<Typography variant="h6" className={classes.heading}>
							Monedas Recolectadas {total-minutes}/{ total }
						</Typography>
					</Grid>
					<Grid item xs={12} className={classes.levelsItem}>
						<div className={classes.root}>
							<span className={classes.levels}>{total-minutes}</span>
						</div>
					</Grid>
                    <EndProcess />
				</Grid>
                
		</div>*/}
			</Fragment>
		);
}

LevelsCompleted.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        items: state.data.data.items,
    };
};

export default withStyles(styles)(connect(mapStateToProps)(LevelsCompleted));
