import React from 'react';

const CvIcon = ({ color }) => (
  <svg
    width='18'
    height='22'
    viewBox='0 0 18 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.2126 5.06389C13.2126 4.95857 13.1265 4.8724 13.0211 4.8724H3.82975C3.72443 4.8724 3.63826 4.95857 3.63826 5.06389V6.21281C3.63826 6.31813 3.72443 6.4043 3.82975 6.4043H13.0211C13.1265 6.4043 13.2126 6.31813 13.2126 6.21281V5.06389ZM13.0211 8.31917H3.82975C3.72443 8.31917 3.63826 8.40534 3.63826 8.51066V9.65958C3.63826 9.7649 3.72443 9.85107 3.82975 9.85107H13.0211C13.1265 9.85107 13.2126 9.7649 13.2126 9.65958V8.51066C13.2126 8.40534 13.1265 8.31917 13.0211 8.31917ZM8.23396 11.7659H3.82975C3.72443 11.7659 3.63826 11.8521 3.63826 11.9574V13.1064C3.63826 13.2117 3.72443 13.2978 3.82975 13.2978H8.23396C8.33927 13.2978 8.42544 13.2117 8.42544 13.1064V11.9574C8.42544 11.8521 8.33927 11.7659 8.23396 11.7659ZM7.08503 19.1382H1.72339V2.28732H15.1275V10.5213C15.1275 10.6266 15.2137 10.7128 15.319 10.7128H16.6594C16.7647 10.7128 16.8509 10.6266 16.8509 10.5213V1.32988C16.8509 0.906218 16.5086 0.563934 16.0849 0.563934H0.765949C0.342284 0.563934 0 0.906218 0 1.32988V20.0956C0 20.5193 0.342284 20.8616 0.765949 20.8616H7.08503C7.19035 20.8616 7.27652 20.7754 7.27652 20.6701V19.3297C7.27652 19.2244 7.19035 19.1382 7.08503 19.1382ZM15.6254 17.3574C16.3195 16.7279 16.7551 15.8183 16.7551 14.8058C16.7551 12.9029 15.2113 11.359 13.3084 11.359C11.4055 11.359 9.8616 12.9029 9.8616 14.8058C9.8616 15.8183 10.2972 16.7279 10.9914 17.3574C9.62703 18.1353 8.68874 19.5762 8.61693 21.2374C8.61214 21.3451 8.70071 21.4361 8.80842 21.4361H9.95973C10.0603 21.4361 10.144 21.3571 10.1512 21.2541C10.2446 19.5978 11.6257 18.2765 13.3084 18.2765C14.9911 18.2765 16.3722 19.5978 16.4655 21.2541C16.4703 21.3547 16.5541 21.4361 16.657 21.4361H17.8083C17.9184 21.4361 18.0046 21.3451 17.9998 21.2374C17.9304 19.5738 16.9897 18.1353 15.6254 17.3574ZM13.3084 12.8909C14.3663 12.8909 15.2232 13.7478 15.2232 14.8058C15.2232 15.8638 14.3663 16.7207 13.3084 16.7207C12.2504 16.7207 11.3935 15.8638 11.3935 14.8058C11.3935 13.7478 12.2504 12.8909 13.3084 12.8909Z'
      //fill='#F175A5'
      fill={color}
    />
  </svg>
);

export { CvIcon };
