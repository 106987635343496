import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import * as actionCreators from '../../actions/data';
import * as actions from '../../actions/auth';

import {
  Col,
  Icon,
  List,
  message,
  Row,
  Switch,
  Typography,
} from 'antd';

import './notification-style.scss';

const { Paragraph, Text, Title } = Typography;

function NotificationSettings(props) {
  const baseUrl = `/api/v1/notifications/users/${props.userId}/notificationconfigurations/`;

  const [notificationConfig, setNotificationConfig] = useState({});

  useEffect(() => {
    props.actions.fetchAndReturn(props.token, baseUrl, 'GET').then((response) => {
      if (response.status < 400) {
        // This get returns a list
        setNotificationConfig(response.body[0]);
      }
      else {
        message.error({
          duration: 5,
          content: 'Error al cargar los datos',
        });
      }
    });
  }, []);

  const updateNotificationConfig = (body) => {
    props.actions.fetchAndReturn(props.token, `${baseUrl}${notificationConfig.id}/`, 'PATCH', body).then((response) => {
      if (response.status < 400) {
        setNotificationConfig(response.body);
        message.success({
          duration: 5,
          content: 'Datos actualizados',
        });
      }
      else {
        message.error({
          duration: 5,
          content: 'Error al cargar los datos',
        });
      }
    }
    );
  };

  const data = [
    {
      key: 'message_notification',
      title: 'Mensajes',
      description: `Recibe notificaciones cuando ${props.userType === 'personal' ? 'reclutadores' : 'candidatos'} te envíen mensajes.`,
    },
    {
      key: 'filesrequest_notification',
      title: 'Solicitud de documentos',
      description: 'Recibe notificaciones cuando candidatos completen solicitudes de documentos.',
      userType: 'business',
    },
    {
      key: 'references_notification',
      title: 'Referencias',
      description: 'Recibe notificaciones cuando candidatos envíen referencias, y cuando las referencias respondan el cuestionario enviado.',
      userType: 'business',
    },
    {
      key: 'new_candidate_notification',
      title: 'Postulaciones nuevas',
      description: 'Recibe notificaciones cuando nuevos candidatos postulen a un proceso.',
      userType: 'business',
    },
    {
      key: 'stage_completed_notification',
      title: 'Etapa completada',
      description: 'Recibe notificaciones cuando los candidatos completen una etapa.',
      userType: 'business',
    },
    {
      key: 'invitation_notification',
      title: 'Invitaciones a procesos',
      description: 'Recibe notificaciones cuando otros usuarios de la empresa te inviten a ver un proceso.',
      userType: 'business',
    },
    {
      key: 'candidate_interview_status_notification',
      title: 'Estado de aceptación de entrevistas',
      description: 'Recibe notificaciones cuando los candidatos acepten o rechazen una entrevista.',
      userType: 'business',
    },
    {
      key: 'new_jobapplication_notification',
      title: 'Nuevo proceso creado',
      description: 'Recibe notificaciones cuando otro usuario crea un proceso.',
      userType: 'business',
    },
    {
      key: 'mention_notification',
      title: 'Menciones',
      description: 'Recibe notificaciones cuando otros usuarios te mencionen en un comentario.',
      userType: 'business',
    },
    {
      key: 'comment_response_notification',
      title: 'Respuestas a comentarios',
      description: 'Recibe notificaciones cuando otros usuarios respondan tus comentarios.',
      userType: 'business',
    },
  ];

  return (
    <div className='account-config-container'>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Icon type="arrow-left" style={{ display: 'flex', marginBottom: '0.5em', marginRight: 10 }} onClick={() => props.dispatch(push('/account/settings'))} />
        <Title level={2}>Notificaciones</Title>
      </div>
      <Paragraph style={{ fontSize: 16 }}>
        Selecciona qué notificaciones deseas recibir y dónde recibirlas.
      </Paragraph>
      {
        props.userType !== 'personal' &&
        <Paragraph style={{ fontSize: 16 }}>
          Si no has configurado el ingreso con un correo corporativo puedes hacerlo gestionando tus colaboradores <Link to={'/business/users'}>acá</Link>.
        </Paragraph>
      }

      <div className="account-base-card ">
        <div>
          <Title level={4}>Actividad de procesos</Title>
        </div>
        <List
          itemLayout="horizontal"
          dataSource={data.filter(({ userType }) => !userType || userType === props.userType)}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={item.title}
                description={
                  <Row type='flex' justify='space-between'>
                    <Col sm={14} lg={18}>{item.description}</Col>
                    <Col sm={10} lg={6}>
                      <Row type='flex' justify='end' gutter={[10, 10]}>
                        <Col>
                          <Text style={{ marginRight: 5 }}>Correo electrónico</Text>
                          <Switch
                            checked={notificationConfig[`${item.key}_email`]}
                            onChange={value => updateNotificationConfig({ [`${item.key}_email`]: value })}
                          />
                        </Col>
                        <Col>
                          <Text style={{ marginRight: 5 }}>Plataforma</Text>
                          <Switch
                            checked={notificationConfig[item.key]}
                            onChange={value => updateNotificationConfig({ [item.key]: value })}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                }
              />
            </List.Item>
          )}
        />
      </div>
    </div>
  )
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userId: state.auth.userID,
    userType: state.auth.userType
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    actionsAuth: bindActionCreators(actions, dispatch),
    dispatch,

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettings);
