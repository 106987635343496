import React, { useContext, useEffect, useState } from 'react'
import { Col, Row, Typography, Button } from 'antd'

import TemplateCard from './components/TemplateCard';

import { AnalyticsContext } from '../context/AnalyticsContext';
import { processNivoData, parseDates, fillTimeline, formatUTMSrcName, keysToUpperCase } from '../assets/utils';
import ModalDiscardReasons from './components/ModalDiscardReasons';
import i18n from '../../../../i18n';

const { Text } = Typography;

const Overview = ({ pdfReport }) => {
  const [showModal, setShowModal] = useState(false);
  const { sectionsData: { overview }, jobAppId } = useContext(AnalyticsContext);

  const {
    ages,
    area_distribution,
    applications_total,
    average_time_per_status,
    candidate_timeline,
    candidates_total,
    completion_rate_avg,
    completion_time_avg,
    disability_types,
    discard_reasons,
    education_levels,
    first_application_date,
    first_hired_date,
    fit_distribution,
    genders,
    hired_total,
    hired_versus_vacancies_rate,
    hiring_rate,
    hiring_rate_avg,
    nationalities,
    nps,
    utm_sources,
    visitor_to_candidate_rate,
  } = overview;
  const candidateTimeline = fillTimeline(candidate_timeline, 10);
  const parsedDates = parseDates(
    {
      first_application_date,
      first_hired_date,
    }
  )

  const nationality_higher = nationalities.reduce((acc, v) => {
    if (acc[1] < v.count && v.nationality !== '') {
      return [v.nationality, v.count];
    }
    return acc;
  }, ['', 0])[0];

  const nationality_higher_code = {
    'chile': 'CL',
    'perú': 'PE',
    'peru': 'PE',
    'colombia': 'CO',
    'argentina': 'AR',
    'venezuela': 'VE',
    'bolivia': 'BO',
    'ecuador': 'EC',
    'uruguay': 'UY',
    'paraguay': 'PY',
    'brasil': 'BR',
    'méxico': 'MX',
    'guatemala': 'GT',
    'honduras': 'HN',
    'el Salvador': 'SV',
    'nicaragua': 'NI',
    'costa rica': 'CR',
    'panamá': 'PA',
    'puerto rico': 'PR',
    'república dominicana': 'DO',
    'españa': 'ES',
    'estados unidos': 'US',
    'canadá': 'CA',
  }[nationality_higher?.toLowerCase()]

  const nationality_avg = { code: nationality_higher_code, label: nationality_higher }

  const maxCountUtmSources = () => utm_sources.reduce((maxObject, currentObject) => {
    if (currentObject.source && currentObject.count > maxObject.count) {
      return currentObject;
    }
    return maxObject;
  }, { count: -Infinity });

  return (
    <Col xs={24} className="new-analytics__content" style={{ background: pdfReport ? '#FFFFFF' : '#FAFAFA' }}>
      {
        !pdfReport &&
        (
          <Row style={{ margin: '0 0 15px 0' }}>
            <Text>Visión general</Text>
          </Row>
        )
      }
      {
        !pdfReport &&
        (
          <Row className='card-print'>
            <TemplateCard
              templateGraphic
              graphicType='timeline'
              topic='candidate_timeline'
              title='Línea del tiempo de Candidatos'
              data={processNivoData(candidateTimeline, ['month', 'year'], '-')}              
            />
          </Row>
        )
      }
      {
        jobAppId && (
          <Row>
            <TemplateCard
              templateGraphic
              graphicType='funnel'
              title='Candidatos por etapa'
            />
          </Row>
        )
      }

      <Row type='flex' gutter={[20, 20]} className='card-print'>
        <Col xs={24} lg={pdfReport ? 24 : 12}>
          <TemplateCard
            templateText
            data={applications_total}
            title='Postulaciones'
            text={jobAppId ? i18n.t('analytics__total_candidates_text_process') : i18n.t('analytics__total_candidates_text_general')}

            height={250}
          />
        </Col>
        <Col xs={24} lg={pdfReport ? 24 : 12}>
          <TemplateCard
            templateText
            data={hired_total}
            title='Contratados totales'
            text='postulantes han sido contratados'
            height={250}
            actionButton={<Button style={{ height: 24 }} type='link' href='#hiring'>Ver más</Button>}
          />
        </Col>
      </Row>
      <Row type='flex' gutter={[20, 20]} className='card-print'>
        {/* Postergado para otra iteración */}
        {/* <Col xs={24} lg={pdfReport ? 24 : 12}>
            <TemplateCard
              templateGraphic
              graphicType='pieChart'
              title='Distribución de procesos por área'
              data={processNivoData(area_distribution, ['area'] )}
              height={300}
            />
        </Col> */}
        <Col xs={24} lg={pdfReport ? 24 : 12}>
          <TemplateCard
            templateText
            data={hired_versus_vacancies_rate ? `${(hired_versus_vacancies_rate * 100).toFixed(2)}%` : null}
            title={i18n.t('analytics__occupation_rate')}
            text={i18n.t('analytics__occupation_rate_text')}
            extra={i18n.t('analytics__occupation_rate_info')}
            height={340}
          />
        </Col>
        {!jobAppId ?
        <Col xs={24} lg={pdfReport ? 24 : 12} className='break-page'>
          <TemplateCard
            templateText
            data={nps ? `${nps.toFixed(1)}/10` : null}
            height={342}
            iconType={
              nps > 8 ?
                'smile'
                : nps > 5 ?
                  'meh'
                  : 'frown'
            }
            title='Satisfacción del Candidato'
            text={
              nps > 8 ?
                '¡Felicitaciones! Esta es una buena nota por parte de tus postulantes.'
                : 'Es la nota promedio con la que tus candidatos han evaluado los procesos seleccionados.'
            }
          />
        </Col>
        :  
        <Col xs={24} lg={12}>
          <TemplateCard
            templateGraphic
            height={300}
            topic='FIT'
            graphicType='pieChart'
            title={i18n.t('analytics__fit_distribution')}
            extra={i18n.t('analytics__fit_distribution_extra')}
            data={processNivoData(fit_distribution, ['fit'])}
          />
        </Col>}
      </Row>
      <Row type='flex' gutter={[20, 20]}>
        <Col xs={24} lg={pdfReport ? 24 : 12} className='break-page'>
          <TemplateCard
            templateGraphic
            topic='channel'
            graphicType='bar'
            title={i18n.t('analytics__utm_sources')}
            extra={i18n.t('analytics__utm_sources_info')}
            customDirection='horizontal'
            data={processNivoData(keysToUpperCase(utm_sources), ['source'])}
            height={300}
            customMargin={{ top: 0, right: 20, bottom: 30, left: 85 }}
          />
        </Col>
        <Col xs={24} lg={pdfReport ? 24 : 12}>
          <TemplateCard
            templateText
            data={utm_sources.length > 0 ? formatUTMSrcName(maxCountUtmSources().source) : null}
            title='Canal de reclutamiento de mayor impacto'
            text='Es tu fuente de reclutamiento más importante de todos los procesos'
            height={342}
          />
        </Col>
      </Row>
      {jobAppId ? (discard_reasons.stages &&
        <Row type='flex'>
          <Col xs={24} className='break-page'>
            <TemplateCard
              templateGraphic
              graphicType='stagecards'
              title={i18n.t('analytics__candidates_discard_reasons')}
              extra={i18n.t('analytics__candidates_discard_reasons_extra')}
              data={discard_reasons.stages}
              height={300}
            />
          </Col>
        </Row>
      ) : (
        discard_reasons.overview &&
        <Row type='flex'>
          <Col xs={24} className='break-page'>
            <TemplateCard
              templateGraphic
              graphicType='bar'
              title={i18n.t('analytics__candidates_discard_reasons')}
              data={processNivoData(
                discard_reasons.overview.filter((discard_reason) => discard_reason.count > 0),
                ['title']
              ).sort((a, b) => b.value - a.value).slice(0, 4)}
              customMargin={{ top: 10, right: 20, bottom: 30, left: 85 }}
              topic={'auto'}
              height={300}
              actionButton={
                <Button
                  style={{ height: 24 }}
                  type='link'
                  onClick={() => setShowModal(true)}
                >
                  {i18n.t('commons__see_more')}
                </Button>}
            />
            <ModalDiscardReasons
              discardReasons={discard_reasons.overview}
              showModal={showModal}
              setShowModal={setShowModal}
            />
          </Col>
        </Row>
      )
      }
      {jobAppId &&
        (<>
          {!pdfReport &&
            (
              <Row style={{ margin: '30px 0 15px 0' }}>
                <Text>Información sobre el proceso</Text>
              </Row>
            )
          }
          <Row type='flex' gutter={[20, 20]}>
            <Col xs={24} lg={pdfReport ? 24 : 12}>
              <TemplateCard
                templateText
                data={parsedDates.first_application_date}
                title='Primera postulacion'
                text='Fue la fecha de la primera postulación en este proceso.'
                height={250}
              />
            </Col>
            <Col xs={24} lg={pdfReport ? 24 : 12}>
              <TemplateCard
                templateText
                data={parsedDates.first_hired_date}
                title='Primera contratación'
                text='Fue la fecha de la primera contratación para este proceso.'
                height={250}
              />
            </Col>
          </Row>
        </>)
      }
      {!pdfReport &&
        (
          <Row style={{ margin: '30px 0 15px 0' }}>
            <Text>Descubre tu eficiencia</Text>
          </Row>
        )
      }
      <Row type='flex' gutter={[20, 20]}>
        <Col xs={24} lg={pdfReport ? 24 : 12}>
          <TemplateCard
            templateText
            height={250}
            data={completion_rate_avg ? `${completion_rate_avg?.toFixed(2) * 100}%` : 'Sin información'}
            title={i18n.t('analytics__completion_rate_average')}
            text={i18n.t('analytics__completion_rate_average_text')}
            extra={i18n.t('analytics__completion_rate_average_info')}
          />
        </Col>
        <Col xs={24} lg={pdfReport ? 24 : 12}>
          <TemplateCard
            templateText
            data={completion_time_avg ? `${(completion_time_avg)} Días` : null}
            title='Tiempo de completación medio'
            text='Tardan en promedio tus candidatos en realizar los juegos del proceso seleccionado.'
            extra={i18n.t('analytics__completion_time_avg_extra')}
            height={250}
          />
        </Col>
      </Row>
      <Row type='flex' gutter={[20, 20]}>
        {jobAppId &&
          <Col xs={24} lg={pdfReport ? 24 : 12}>
            <TemplateCard
              templateText
              data={visitor_to_candidate_rate ? `${visitor_to_candidate_rate}%` : null}
              title={i18n.t('analytics__visitor_to_candidate_rate')}
              text={i18n.t('analytics__visitor_to_candidate_rate_text')}
              height={250}
              extra={i18n.t('analytics__visitor_to_candidate_rate_info')}
            />
          </Col>
        }
        <Col xs={24} lg={pdfReport ? 24 : 12}>
          {
            jobAppId ?
              <TemplateCard
                templateText
                data={hiring_rate_avg ? `${(hiring_rate_avg).toFixed(2)} Días` : null}
                title='Tiempo promedio de contratación'
                text='Toma en promedio contratar a un candidato en este proceso.'
                flexDirection='column'
                height={250}
              /> :
              <TemplateCard
                templateText
                data={hired_total ? `${((hired_total / candidates_total) * 100) < 1 ? '<1' : ((hired_total / candidates_total) * 100).toFixed(0)} contrataciones` : null}
                title='Tasa de contratación media'
                text='realizadas por cada 100 candidatos considerando todos tus procesos.'
                height={250}
              />
          }
        </Col>
      </Row>
      {jobAppId &&
        <Row>
          <Col>
            <TemplateCard
              templateGraphic
              graphicType='stackedBar'
              topic='average_time_per_status'
              customDirection='horizontal'
              data={[average_time_per_status.reduce(
                (obj, item) => Object.assign(obj, { [item.status]: item.count }), { id: "" })]}
              title={i18n.t('analytics__time_per_status')}
              extra={i18n.t('analytics__time_per_status_info')}
              height={300}
            />
          </Col>
        </Row>
      }
      {!pdfReport &&
        (
          <Row style={{ margin: '30px 0 15px 0' }}>
            <Text>
              ¿Qué tan diversos están siendo tus procesos?
            </Text>
          </Row>
        )
      }
      <Row gutter={[20, 20]}>
        <Col xs={24} lg={pdfReport ? 24 : 18} className='break-page'>
          <TemplateCard
            templateGraphic
            graphicType='geoMap'
            data={processNivoData(nationalities, ['nationality'])}
            height={250}
            title='Nacionalidad - Candidatos'
            text={nationality_avg}
          />

          <TemplateCard
            templateGraphic
            graphicType='bar'
            topic='age'
            data={processNivoData(ages, ['age_range'])}
            customMargin={{ top: 20, right: 20, bottom: 40, left: 55 }}
            title='Edad - Candidatos'
            customDirection='vertical'
            text='años'
            height={300}
          />
        </Col>
        <Col xs={24} lg={pdfReport ? 24 : 6}>
          <TemplateCard
            templateGraphic
            topic='gender'
            graphicType='pieChart'
            title='Género - Candidatos'
            text={genders.length ? genders[genders.length - 1] : null}
            data={processNivoData(genders, ['gender'])}
            height={320}
            customMargin={{ top: 50, right: 5, bottom: 110, left: 0 }}
            translateX={10}
            translateY={170}
          />
        </Col>
      </Row>
      {jobAppId && (
        <Row gutter={[20, 20]}>
          <TemplateCard
            templateGraphic
            graphicType='bar'
            data={processNivoData(education_levels, ['education_level'], '', true)}
            topic='education'
            title='Formación academica - Candidatos'
            customDirection={'vertical'}
            customMargin={{ top: 20, right: 20, bottom: 50, left: 50 }}
            height={250}
          />
        </Row>
      )}
      {!pdfReport &&
        (
          <Row type='flex' justify='center' style={{ margin: '30px 0 120px 0' }}>
            <Button icon='to-top' onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
              Volver arriba
            </Button>
          </Row>
        )
      }
    </Col>
  );
}

export default Overview;