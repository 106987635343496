import React, { useState, useEffect } from 'react'
import {
  Modal,
  Button,
  Row,
  Typography,
  Tabs,
  Col,
  Icon,
  Tag
} from 'antd';

import ParseHtml from '../../../../../components/parseHtml';

const { Title, Text } = Typography;
const { TabPane } = Tabs

const InformationModal = ({ showModalInformation, setShowModalInformation, customTestSpecific }) => {
  const [correctAnswer, setCorrectAnswer] = useState([])
  const { questions, scores_config } = customTestSpecific

  const getCorrectAnswers = () => {
    const correctAlternative = []
    if (scores_config !== undefined) {
        questions.data.slice(1).map((page) => {
          page.fields.map((question) => {
            const id = Object.keys(scores_config.correct_answers).find(id => id === question._uid)
            question.options?.map((alternative) => {
              if (scores_config.correct_answers[id]?.value === alternative?.value) {
                correctAlternative.push({ answer: alternative.label, id: question._uid })
              }
            })
            if (scores_config.correct_answers[id]?.value === '' && id === question._uid) {
              correctAlternative.push({ answer: 'Alternativa no seleccionada', id: question._uid })
            }
          })
        })
    }
    setCorrectAnswer(correctAlternative)
  }

  useEffect(() => {
    getCorrectAnswers()
  }, [customTestSpecific])

  return (
    <Modal
      title='Detalles'
      visible={showModalInformation}
      onCancel={() => setShowModalInformation(false)}
      centered
      width={600}
      footer={
        <Button type='primary' onClick={() => setShowModalInformation(false)}>
          Salir
        </Button>
      }
    >
      <Title level={4}>
        {customTestSpecific.title ? customTestSpecific.title : 'Titulo'}
      </Title>
      <Tabs>
        <TabPane tab='Detalles' key='1'>
          <Row type='flex' className='custom-test-table__toprow'>
            <Col xs={24} sm={12} className='custom-test-table__toprow__colleft'>
              <Row type='flex'>
                <Col className='custom-test-table__toprow__boxinformation1'>
                  <Row type='flex' align='top' className='custom-test-table__toprow__boxinformation1__box'>
                    <Icon type='clock-circle' className='custom-test-table__toprow__boxinformation1__box__icon' />
                    <Text strong className='custom-test-table__toprow__boxinformation1__box__title'>
                      Tiempo
                    </Text>
                    <Text className='custom-test-table__toprow__boxinformation1__box__text'>
                      {
                        customTestSpecific.questions ? customTestSpecific.questions.global_time ?
                          `${customTestSpecific.questions.global_time / 60} minutos` : 'Sin tiempo' : 'Sin tiempo'
                      }
                    </Text>
                  </Row>
                </Col>
                <Col className='custom-test-table__toprow__boxinformation2'>
                  <Row type='flex' align='top' className='custom-test-table__toprow__boxinformation2__box'>
                    <Icon type='clock-circle' className='custom-test-table__toprow__boxinformation2__box__icon' />
                    <Text strong className='custom-test-table__toprow__boxinformation2__box__title'>
                      Escala de evaluación
                    </Text>
                    <Text className='custom-test-table__toprow__boxinformation2__box__text'>
                      {
                        customTestSpecific.scores_config ? customTestSpecific.scores_config.segments ?
                          `${customTestSpecific.scores_config.segments.length} niveles` : 'Sin escala' : 'Sin escala'
                      }
                    </Text>
                  </Row>
                </Col>

              </Row>
            </Col>

            <Col xs={24} sm={12} className='custom-test-table__toprow__colright'>
              <Row type='flex' className='custom-test-table__toprow__description'>
                <Text strong>
                  Descripción
                </Text>
                <Text>
                  {
                    customTestSpecific.description === null || customTestSpecific.description === '' ?
                      'Sin descripción' : ParseHtml(customTestSpecific.description)
                  }
                </Text>
              </Row>
            </Col>
          </Row>
        </TabPane>

        <TabPane tab='Preguntas' key='2'>
          <Row type='flex'>
            <Col span={7}>
              <Text strong>Dimensión</Text>
            </Col>
            <Col span={10}>
              <Text strong>Pregunta</Text>
            </Col>
            <Col span={7}>
              <Text strong>Respuesta correcta</Text>
            </Col>
          </Row>

          <Row style={{ padding: '10px 0' }}>
            {
              customTestSpecific.questions ? customTestSpecific.questions.data ?
                customTestSpecific.questions.data.map((page, i) => (
                  page.fields.map((question, i) => (
                    question.component !== 'read_only' ?
                      (
                        <Row type='flex' key={i}>
                          <Col span={7}>
                            <Row type='flex' align='top' style={{ height: '100%', padding: '0 20px 20px 0' }}>
                              {
                                question.category ?
                                  (
                                    <Tag style={{ padding: '1px 10px', border: '1px solid #597EF7', background: 'white', borderRadius: '2px' }}>
                                      {question.category}
                                    </Tag>
                                  ) :
                                  (
                                    <Text style={{ margin: '0 0 15px 0' }}>Sin dimensión</Text>
                                  )
                              }
                            </Row>
                          </Col>
                          <Col span={10}>
                            <Row type='flex' style={{ height: '100%', padding: '0 20px 20px 0' }}>
                              {ParseHtml(question.label)}
                            </Row>
                          </Col>
                          <Col span={7}>
                            {
                              correctAnswer.map((answer, id) => (
                                answer.id === question._uid ?
                                  (
                                    <Text key={id}>{answer.answer ? answer.answer : 'Alternativa no selecciona'}</Text>
                                  ) : null
                              ))
                            }
                          </Col>
                        </Row>
                      ) : null
                  ))
                )) : null : null
            }
          </Row>
        </TabPane>
      </Tabs>
    </Modal>
  );
}

export default InformationModal;