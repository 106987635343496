import React from 'react';

import { Icon, Col, Row, Tag } from 'antd';

import ParseHtml from '../../../../../../../components/parseHtml';

const Detail = ({ testName, answers, questions, sortedQuestions, scores, scoresConfig }) => {
  const answerComponent = (answerId, idx) => {
    const answerValue = answers[answerId];
    const answerValueLabel = questions[answerId]['options']?.find(
      (obj) => obj['value'] === answerValue
    )?.label;
    const answerIsCorrect = scores['raw_result']?.[answerId]?.correct;

    const answerType = questions[answerId]['type'];

    const questionLabel = questions[answerId]['label'];
    const questionCategory = questions[answerId]['category'];

    return (
      <Col span={24} className="protected-div-cute-answer-pdf ">
        {questionCategory ? (
          <Tag color="geekblue" style={{ width: 'fit-content' }}>
            {questionCategory}
          </Tag>
        ) : null}
        <div style={{ marginBottom: 5 }}>
          {ParseHtml(`<span>${idx + 1}.</span> ${questionLabel}`)}
        </div>
        {answerValue && answerType === 'text' ? (
          <div className="alternatives-pdf-modal__cute">{answerValue}</div>
        ) : answerValueLabel ? (
          <div
            className={
              answerIsCorrect
                ? 'alternatives-pdf-modal__cute alternatives-pdf-modal__cute__correct'
                : 'alternatives-pdf-modal__cute alternatives-pdf-modal__cute__wrong'
            }
          >
            <div
              style={{
                color: answerIsCorrect ? '#389E0D' : '#FF4D4F',
              }}
            >
              {ParseHtml(answerValueLabel)}
            </div>
            <Icon
              type={answerIsCorrect ? 'check-circle' : 'close-circle'}
              theme="twoTone"
              twoToneColor={answerIsCorrect ? '#389E0D' : '#FF4D4F'}
            />
          </div>
        ) : (
          <div
            className={
              'alternatives-pdf-modal__cute alternatives-pdf-modal__cute__wrong'
            }
          >
            <div style={{ color: '#FF4D4F' }}>Sin respuesta</div>
            <Icon
              type="close-circle"
              theme="twoTone"
              twoToneColor={'#FF4D4F'}
            />
          </div>
        )}
      </Col>
    );
  };

  const multipleAnswerComponent = (answerId, idx) => {
    const question = questions[answerId];
    const answerValue = answers[answerId];
    const answerIsCorrect = scores.raw_result[answerId]?.correct;
    const answerOptionIndex = question['options']?.findIndex(
      (obj) => obj.value === answerValue
    );
    const optionsSelected = question.options.filter((opt) => answers[answerId].includes(opt.value))
    const {
      label: questionLabel,
      category: questionCategory,
      type: answerType,
    } = question;

    return (
      <Col
        span={24}
        style={{
          marginBottom: '2rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.25rem',
        }}
      >
        <div>
          <div style={{ display: 'flex', gap: '0.25rem' }}>
            {idx + 1}. {ParseHtml(questionLabel)}
          </div>
        </div>
        {answerValue && answerType === 'radio' ?
          (
          <div
            className={
              answerIsCorrect
                ? 'cute-answer cute-answer__correct'
                : 'cute-answer cute-answer__wrong'
            }
            style={{ display: 'flex', flexDirection: 'row', gap: '0.25rem' }}
          >
          <Row type='flex' align='middle'>
            {optionsSelected.map((opt, idx) => (
              <Tag key={idx} type='flex' style={{padding: '5px', width: '30vw', whiteSpace: 'normal'}}>{ParseHtml(opt.label)}</Tag>
            ))}
          </Row>
            <Icon
              type={answerIsCorrect ? 'check-circle' : 'close-circle'}
              theme="twoTone"
              twoToneColor={answerIsCorrect ? '#389E0D' : '#FF4D4F'}
            />
          </div>
        ) : (
          <div className={'cute-answer cute-answer__wrong'}>
            <Icon
              type="close-circle"
              theme="twoTone"
              twoToneColor={'#FF4D4F'}
            />
            <Text type="danger">Sin respuesta</Text>
          </div>
        )}
      </Col>
    )
  }

  return (
    <main className="alternatives-pdf-modal__mainsection__details">
      <h4>{testName}</h4>
      <div>
        {answers
          ? sortedQuestions.map((elem, idx) => {
              return (
                <div
                  span={24}
                  key={idx}
                  className="protected-div-container-pdf"
                >
                  {scoresConfig.correct_answers[elem]?.type === 'multiple_answers' ? multipleAnswerComponent(elem, idx) : answerComponent(elem, idx)}
                </div>
              );
            })
          : 'Sin Información'}
      </div>
    </main>
  );
};

export default Detail;
