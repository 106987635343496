import { Button, Icon, Row, Tabs, Tooltip, Typography } from 'antd';

import React from 'react';

const { Text, Title } = Typography;

const Complementary = ({ evaluation, disabled, history }) => {
  return (
    <div className="complementary-evaluations__card">
      <div className="complementary-evaluations__card__header">
        <Icon type={evaluation.icon} />
        <Text strong>{evaluation.name}</Text>
      </div>
      <div className="complementary-evaluations__card__body">
        <Text style={{ textAlign: 'justify ' }}>{evaluation.description}</Text>
        <Tooltip
          title={
            disabled
              ? 'Contacta a mariajose@genomawork.com para contratar esta funcionalidad.'
              : null
          }
        >
          <Button
            disabled={disabled}
            type="primary"
            icon={
              evaluation.name === 'Entrevistas por competencias' ? 'bulb' : ''
            }
            onClick={() => !disabled && evaluation.action(history)}
            style={
              evaluation.name === 'Entrevistas por competencias'
                ? {
                    background: '#9254DE',
                    borderColor: '#9254DE',
                  }
                : {}
            }
          >
            Ingresar
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export default Complementary;
