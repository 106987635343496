import React, { useContext, useState, useEffect, useMemo } from 'react';
import { push } from 'react-router-redux';

import {
  Button,
  Card,
  Col,
  Form,
  Icon,
  Layout,
  Modal,
  Row,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import PropTypes from 'prop-types';

import AcademicInformation from './components/AcademicInformation';
import Certifications from './components/Certifications';
// import CoverLetter from './components/CoverLetter';
import Curriculum from './components/Curriculum';
import Experience from './components/Experience';
import PersonalInformation from './components/PersonalInformation';
import Skills from './components/Skills';
import { PersonalFormContext } from './config/Context';
import { personalFormLabels, stepTitles } from './constants';
import ProgressBar from './ProgressBar';
import CvForm from './components/CvForm';
import i18n from '../../i18n';

const ASSETS_URL = 'https://genoma-assets.s3.us-east-2.amazonaws.com';

const { Text, Title } = Typography;

const {
  personalInformation,
  careersInformation,
  certificationsInformation,
  experiencesInformation,
  filesInformation,
} = personalFormLabels;

const PersonalFormLayout = (props) => {
  const cvDataFillerAvailability = ["es", "en"];
  const {
    candidate: {
      careers,
      certifications,
      // cover_letter,
      experiences,
      has_cv,
      // newCoverLetter,
      newCv,
      skills,
    },
    currentStep,
    jobApplication: { id: jobappid, optional_fields },
    jumpToStep,
    loading,
    saveChanges,
    stepBack,
    stepForward,
  } = useContext(PersonalFormContext);

  const { dispatch, form } = props;
  const currentLanguage = i18n.language.split('-')[0];
  const [displayCvForm, setDisplayCvForm] = useState(cvDataFillerAvailability.includes(currentLanguage) ? true : false);
  const [parserReady, setParserReady] = useState(false);
  const [parserOutcome, setParserOutcome] = useState('');
  const [totalSteps, setTotalSteps] = useState(4);
  const [choseToNotAutocompleteForm, setChoseToNotAutocompleteForm] = useState();

  useEffect(() => {
    if (optional_fields.cv === 2) {
      setDisplayCvForm(false);
      setTotalSteps(3);
    }
  }, [optional_fields.cv])

  useEffect(() => {
    if (optional_fields.careers === 2) {
      setTotalSteps((prevState) => prevState - 1);
    }
    if (optional_fields.experiences === 2) {
      setTotalSteps((prevState) => prevState - 1);
    }
    if (optional_fields.cv === 2) {
      setTotalSteps((prevState) => prevState - 1);
    }
  }, [optional_fields]);

  const goBackToPath = () => dispatch(push(`/jobapplication/${jobappid}/`));

  const validateForm = () => {
    let validationOutcome = false;
    form.validateFields((errors) => {
      const {
        careers: careersOptional,
        certifications: certificationsOptional,
        cv: cvOptional,
        experiences: experiencesOptional,
        // cover_letter: coverLetterOptional,
        skills: skillsOptional,
      } = optional_fields;
      const requiredSectionsMissing = (
      //   careersOptional !== undefined && !careersOptional && careers.length === 0
      // ) || (
      //     certificationsOptional !== undefined && !certificationsOptional && certifications.length === 0
      //   ) || (
      //     skillsOptional !== undefined && !skillsOptional && skills.length === 0
      //   ) || (
      //     experiencesOptional !== undefined && !experiencesOptional && experiencesInformation.length === 0
      //   ) || (
          cvOptional !== undefined && !cvOptional && !has_cv && !newCv
          // ) || (
          //   !coverLetterOptional && !cover_letter && !newCoverLetter
        );
      if (!errors && !requiredSectionsMissing) {
        validationOutcome = true;
      }
      else {
        const errorLabels = errors ? Object.keys(errors).map((fieldNameKey) => fieldNameKey.split('-')[0]) : [];
        const personalInformationKeys = Object.keys(personalInformation);
        const careersInformationKeys = Object.keys(careersInformation);
        const certificationsInformationKeys = Object.keys(certificationsInformation);
        const experiencesInformationKeys = Object.keys(experiencesInformation);
        const filesInformationKeys = Object.keys(filesInformation);
        const content = (
          <>
            {personalInformationKeys.some((fieldNameKey) => errorLabels.includes(fieldNameKey))
              && (
                <>
                  <Text
                    strong
                    style={{ cursor: 'pointer' }}
                  >
                    {i18n.t('form__step')} 1 - {stepTitles[0]}:
                  </Text>
                  <ul style={{ marginBottom: '10px' }}>
                    {personalInformationKeys.map((fieldNameKey, index) => (
                      errorLabels.includes(fieldNameKey)
                      && (
                        <li key={index} style={{ listStyle: 'inside' }}>
                          {personalInformation[fieldNameKey]}
                        </li>
                      )))}
                  </ul>
                  <Button
                    onClick={() => {
                      jumpToStep(0);
                      Modal.destroyAll();
                    }}
                  >
                    {i18n.t('form__errors__tooltip')}
                  </Button>
                </>
              )}
            {(!careersOptional && careers.length === 0)
              && (
                <>
                  <Text
                    strong
                    style={{ cursor: 'pointer' }}
                  >
                    {i18n.t('form__step')} 2 - {stepTitles[1]}:
                  </Text>
                  <ul style={{ marginBottom: '10px' }}>
                    <li style={{ listStyle: 'inside' }}>
                      {i18n.t('form__career__error')}
                    </li>
                  </ul>
                  <Button
                    onClick={() => {
                      jumpToStep(1);
                      Modal.destroyAll();
                    }}
                  >
                    {i18n.t('form__errors__tooltip')}
                  </Button>
                </>
              )}
            {careersInformationKeys.some((fieldNameKey) => errorLabels.includes(fieldNameKey))
              && (
                <>
                  <Text
                    strong
                    style={{ cursor: 'pointer' }}
                  >
                    {i18n.t('form__step')} 2 - {stepTitles[1]}:
                  </Text>
                  <ul style={{ marginBottom: '10px' }}>
                    {careersInformationKeys.map((fieldNameKey, index) => (
                      errorLabels.includes(fieldNameKey)
                      && (
                        <li key={index} style={{ listStyle: 'inside' }}>
                          {careersInformation[fieldNameKey]}
                        </li>
                      )))}
                  </ul>
                  <Button
                    onClick={() => {
                      jumpToStep(1);
                      Modal.destroyAll();
                    }}
                  >
                    {i18n.t('form__errors__tooltip')}
                  </Button>
                </>
              )}
            {(!certificationsOptional ? false : (!certifications || certifications.length === 0))
              && (
                <>
                  <Text
                    strong
                    style={{ cursor: 'pointer' }}
                  >
                    {i18n.t('form__step')} 2 - {i18n.t("form__certifications")}:
                  </Text>
                  <ul style={{ marginBottom: '10px' }}>
                    <li style={{ listStyle: 'inside' }}>
                      {i18n.t('form__certification__error')}
                    </li>
                  </ul>
                  <Button
                    onClick={() => {
                      jumpToStep(1);
                      Modal.destroyAll();
                    }}
                  >
                    {i18n.t('form__errors__tooltip')}
                  </Button>
                </>
              )}
            {certificationsInformationKeys.some((fieldNameKey) => errorLabels.includes(fieldNameKey))
              && (
                <>
                  <Text
                    strong
                    style={{ cursor: 'pointer' }}
                  >
                    {i18n.t('form__step')} 2 - {i18n.t("form__certifications")}:
                  </Text>
                  <ul style={{ marginBottom: '10px' }}>
                    {certificationsInformationKeys.map((fieldNameKey, index) => (
                      errorLabels.includes(fieldNameKey) && (
                        <li key={index} style={{ listStyle: 'inside' }}>
                          {certificationsInformation[fieldNameKey]}
                        </li>
                      )))}
                  </ul>
                  <Button
                    onClick={() => {
                      jumpToStep(1);
                      Modal.destroyAll();
                    }}
                  >
                    {i18n.t('form__errors__tooltip')}
                  </Button>
                </>
              )}
            {(!skillsOptional ? false : (!skillsOptional || skillsOptional?.length === 0))
              && (
                <>
                  <Text
                    strong
                    style={{ cursor: 'pointer' }}
                  >
                    {i18n.t('form__step')} 2 - {i18n.t("form__skills_and_languages")}:
                  </Text>
                  <ul style={{ marginBottom: '10px' }}>
                    <li style={{ listStyle: 'inside' }}>
                      {i18n.t('form__skills_and_languages__error')}
                    </li>
                  </ul>
                  <Button
                    onClick={() => {
                      jumpToStep(1);
                      Modal.destroyAll();
                    }}
                  >
                    {i18n.t('form__errors__tooltip')}
                  </Button>
                </>
              )}
            {experiencesInformationKeys.some((fieldNameKey) => errorLabels.includes(fieldNameKey))
              && (
                <>
                  <Text
                    strong
                    style={{ cursor: 'pointer' }}
                  >
                    {i18n.t('form__step')} 3 - {stepTitles[2]}:
                  </Text>
                  <ul style={{ marginBottom: '10px' }}>
                    {experiencesInformationKeys.map((fieldNameKey, index) => (
                      errorLabels.includes(fieldNameKey)
                      && (
                        <li key={index} style={{ listStyle: 'inside' }}>
                          {experiencesInformation[fieldNameKey]}
                        </li>
                      )))}
                  </ul>
                  <Button
                    onClick={() => {
                      jumpToStep(2);
                      Modal.destroyAll();
                    }}
                  >
                    {i18n.t('form__errors__tooltip')}
                  </Button>
                </>
              )}
            {(!experiencesOptional && experiences.length === 0)
              && (
                <>
                  <Text
                    strong
                    style={{ cursor: 'pointer' }}
                  >
                    {i18n.t('form__step')} 3 - {stepTitles[2]}:
                  </Text>
                  <ul style={{ marginBottom: '10px' }}>
                    <li style={{ listStyle: 'inside' }}>
                      {i18n.t('form__experience__error')}
                    </li>
                  </ul>
                  <Button
                    onClick={() => {
                      jumpToStep(2);
                      Modal.destroyAll();
                    }}
                  >
                    {i18n.t('form__errors__tooltip')}
                  </Button>
                </>
              )}
            {(!cvOptional && !has_cv && !newCv)
              && (
                <>
                  <Text
                    strong
                    style={{ cursor: 'pointer' }}
                  >
                    {i18n.t('form__step')} 4 - {i18n.t("form__cv")}:
                  </Text>
                  <ul style={{ marginBottom: '10px' }}>
                    <li style={{ listStyle: 'inside' }}>
                      {i18n.t('form__cv__error')}
                    </li>
                  </ul>
                </>
              )}
            {/* {(!coverLetterOptional && !cover_letter && !newCoverLetter)
              && (
                <>
                  <Text
                    onClick={() => modal.destroy()}
                    strong
                    style={{ cursor: 'pointer' }}
                  >
                    {i18n.t('form__step')} 4 - {i18n.t("form__cover_letter")}:
                  </Text>
                  <ul style={{ marginBottom: '10px' }}>
                    <li style={{ listStyle: 'inside' }}>
                      {i18n.t('form__cover_letter__error')}
                    </li>
                  </ul>
                </>
              )} */}
            {filesInformationKeys.some((fieldNameKey) => errorLabels.includes(fieldNameKey))
              && (
                <>
                  <Text
                    strong
                    style={{ cursor: 'pointer' }}
                  >
                    {i18n.t('form__step')} 4 - {stepTitles[3]}:
                  </Text>
                  <ul style={{ marginBottom: '10px' }}>
                    {filesInformationKeys.map((fieldNameKey, index) => (
                      errorLabels.includes(fieldNameKey)
                      && (
                        <li key={index} style={{ listStyle: 'inside' }}>
                          {filesInformation[fieldNameKey]}
                        </li>
                      )
                    ))}
                  </ul>
                </>
              )}
          </>
        );
        if (parserReady) {
          if (parserOutcome === 'success') {
            Modal.success({
              title: '¡Los datos de tu CV se han importado con éxito!',
              content: (<Text>
                Te recomendamos <strong>revisar y verificar la información importada</strong>, así como completar los campos que estén faltantes. Si encuentras algún error, tendrás la opción de editarlo.
              </Text>),
              width: 500,
              okText: 'Siguiente',
              onOk: () => {
                setParserReady(false);
              }
            });
          } else if (parserOutcome === 'error') {
            Modal.confirm({
              title: 'Hemos tenido un problema cargando tu CV',
              content: (<Text>
                Te recomendamos cargarlo otra vez o completar los datos manualmente.
              </Text>),
              icon: <Icon type="close-circle" style={{ color: 'red' }} />,
              okText: 'Volver a intentar',
              cancelText: 'Continuar',
              okType: 'primary',
              onOk: () => {
                setParserReady(false);
                setDisplayCvForm(true);
              },
              width: 500,
            });
          }
        } else {
          Modal.error({
            content,
            title: i18n.t('form__errors__title'),
            width: 500,
          });
        }
      }
    });
    return validationOutcome;
  };

  useEffect(() => {
    if (parserReady === true) validateForm();
  }, [parserReady]);

  const submitForm = () => {
    if (validateForm()) {
      saveChanges().then(successfullySaved => successfullySaved && goBackToPath());
    }
  }

  const isLastStep = useMemo(() => {
    switch (currentStep) {
      case 0:
        return optional_fields.careers === 2 && optional_fields.experiences === 2;
      case 1:
        return optional_fields.experiences === 2;
      case 2:
        return optional_fields.cv === 2 || !choseToNotAutocompleteForm;
      case 3:
        return true;
    }
  }, [currentStep, optional_fields]);

  return (
    <Layout className='form-activity'>
      <div style={{ display: displayCvForm ? 'block' : 'none' }}>
        <CvForm
          {...props}
          setDisplayForm={setDisplayCvForm}
          setParserReady={setParserReady}
          goBackToPath={goBackToPath}
          setParserOutcome={setParserOutcome}
          setChoseToNotAutocompleteForm={setChoseToNotAutocompleteForm}
          setTotalSteps={setTotalSteps}
        />
      </div>
      <div style={{ display: displayCvForm ? 'none' : 'block' }}>
        <Spin spinning={loading}>
          <Row type='flex' style={{ width: '100%', padding: '20px 20px' }}>
            <Col
              onClick={() => Modal.confirm({
                cancelText: i18n.t('commons__cancel'),
                content: i18n.t('form__go_back__content'),
                okText: i18n.t('commons__go_back'),
                onOk: goBackToPath,
                title: i18n.t('form__go_back__title'),
              })}
              xs={24}
              lg={6}
              style={{ cursor: 'pointer', marginTop: '5px' }}
            >
              <Icon style={{ marginRight: '5px' }} type="left" /> {i18n.t('commons__go_back')}
            </Col>
          </Row>
          <Row type='flex' justify='center' style={{ marginTop: '40px', width: '100%' }}>
            <Col xs={24} lg={12}>
              <Card className='form-card'>
                <Row className='form-card-row'>
                  <img
                    src={`${ASSETS_URL}/formulario_de_postulacion.svg`}
                    style={{ height: '85px', marginBottom: '1em', width: '85px' }}
                  />
                </Row>
                <Row className='form-card-row'>
                  <Title level={2}>{i18n.t('form__title').toLocaleUpperCase()}</Title>
                </Row>
                <ProgressBar
                  currentStep={currentStep}
                  optionalFields={optional_fields}
                  totalSteps={totalSteps}
                />
                <Row className='form-card-row'>
                  <Form style={{ width: '100%' }}>
                    {/* Step 1 */}
                    <div style={{ display: currentStep === 0 ? 'block' : 'none' }}>
                      <Row className='form-section-row' type='flex'>
                        <PersonalInformation form={form} />
                      </Row>
                    </div>
                    {/* Step 2 */}
                    <div style={{ display: currentStep === 1 ? 'block' : 'none' }}>
                      <Row className='form-section-row' type='flex'>
                        <AcademicInformation form={form} />
                      </Row>
                      <Row className='form-section-row' type="flex">
                        <Certifications form={form} />
                      </Row>
                      <Row className='form-section-row' type="flex">
                        <Skills form={form} />
                      </Row>
                    </div>
                    {/* Step 3 */}
                    <div style={{ display: currentStep === 2 ? 'block' : 'none' }}>
                      <Row className='form-section-row' type='flex'>
                        <Experience form={form} />
                      </Row>
                    </div>
                    {/* Step 4 */}
                    <div style={{ display: (currentStep === 3 && choseToNotAutocompleteForm) ? 'block' : 'none' }}>
                      <Row className='form-section-row' type='flex'>
                        <Curriculum form={form} />
                      </Row>
                      {/* <Row className='form-section-row' type="flex">
                        <CoverLetter form={form} />
                      </Row> */}
                    </div>
                  </Form>
                </Row>

                <Row align="middle" justify="space-between" style={{ width: '100%' }} type="flex">
                  <Col span={12}>
                    {(cvDataFillerAvailability.includes(currentLanguage) && optional_fields.cv !== 2) || currentStep > 0
                      ? (
                        <Button
                          onClick={currentStep > 0 ? stepBack : () => {
                            setDisplayCvForm(true);
                            setParserReady(false);
                          }}
                        >
                          <Icon type="arrow-left" /> {i18n.t("commons__back")}
                        </Button>
                      )
                      : null}
                  </Col>
                  <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {!isLastStep ? (
                      <Button onClick={stepForward} type="primary">
                        {i18n.t("commons__next")} <Icon type="arrow-right" />
                      </Button>
                    ) : (
                      <Button onClick={submitForm} type="primary">
                        {i18n.t("commons__save")}
                      </Button>
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Spin>
      </div>
    </Layout>
  );
};

PersonalFormLayout.propTypes = {
  dispatch: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
};

export default PersonalFormLayout;
