import React from 'react';
import { NoTags } from './svgContainer';

const NoOptionsSelect = () => {
  return (
    <div style={{padding: 15}}>
      <NoTags />
    </div>
  );
};

export default NoOptionsSelect;
