import React, { useContext }  from 'react';

import { Row, Typography } from 'antd';
import PropTypes from 'prop-types';

import { PersonalFormContext } from '../config/Context';
import i18n from '../../../i18n';
import DebouncedSelect from '../../../components/input/DebouncedSelect';

const { Text, Title } = Typography;

function Skills(props) {
  const { form } = props;
  const { getFieldDecorator } = form;
  const {
    candidate: { skills },
    jobApplication,
    updateCandidate,
  } = useContext(PersonalFormContext);

  const optional = jobApplication.optional_fields.skills || jobApplication.optional_fields.skills === undefined;

  return (
    <>
      <Row>
        <Title level={3}>
          {!optional ? <Text style={{ color: 'red' }}>*</Text> : ''}{i18n.t("form__skills_and_languages")}
        </Title>
      </Row>
      {getFieldDecorator('skills', {
        initialValue: skills,
      })(
          <DebouncedSelect
            fieldName="skills"
            placeholder={i18n.t('form__skills_and_languages__placeholder')}
            searchApiUrl={`/api/v1/accounts/skills/?language=${i18n.language.split('-')[0]}`}
            setValue={(fieldObj) => updateCandidate('skills', fieldObj.skills)}
            type="multiple"
          />
      )}
    </>
  );
}

Skills.propTypes = {
  form: PropTypes.object.isRequired,
};

export default Skills;
