import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';

import {
  Button,
  Col,
  Row,
  Typography,
  TimePicker,
} from 'antd'
import { v4 } from 'uuid';
const { Title, Text } = Typography;
import i18n from '../../../i18n';

const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

const oneHotToHourOfDay = (oneHot, end=false) => {
  const adjustedOneHot = end ? oneHot + 1 : oneHot;
  const hour = Math.floor(adjustedOneHot / 4);
  const minutes = (adjustedOneHot % 4) * 15;
  return [hour, minutes];
}

const hourOfDayToOneHot = (hour, minutes, end=false) => {
  // Blocks of time are 15 minutes
  return hour * 4 + minutes / 15 - end * 1;
}

const BlockComponent = ({
  day,
  handleScheduleChangeBlock,
  handleAddBlock,
  handleDeleteBlock,
  isLastBlock,
  block,
  key,
}) => {
  const [currentBlock, setCurrentBlock] = useState(block);

  const handleTemporaryBlockChange = (day, position) => (time, timeString) => {
    const newBlock = structuredClone(currentBlock);

    newBlock[position] = timeString.split(':').map(x => Number.parseInt(x))
    
    setCurrentBlock(newBlock);

    const startOneHot = hourOfDayToOneHot(newBlock.start[0], newBlock.start[1]);
    const endOneHot = hourOfDayToOneHot(newBlock.end[0], newBlock.end[1], true);
    if (endOneHot < startOneHot) {
      newBlock.end = [...newBlock.start];
    }
    if (endOneHot >= startOneHot) {
      handleScheduleChangeBlock(day, block, newBlock);
    }
  }

  return (
    <Row key={key} type='flex' justify='start' style={{ maxWidth: 400 }}>
      <Col span={10}>
        <TimePicker
          onChange={handleTemporaryBlockChange(day, 'start')}
          value={moment(`${currentBlock.start[0]}:${currentBlock.start[1]}`, 'HH:mm')}
          minuteStep={15} format='HH:mm'
          inputReadOnly allowClear={false} 
        />
      </Col>
      <Col span={10}>
        <TimePicker
          onChange={handleTemporaryBlockChange(day, 'end')}
          value={moment(`${currentBlock.end[0]}:${currentBlock.end[1]}`, 'HH:mm')}
          minuteStep={15} format='HH:mm'
          inputReadOnly allowClear={false} disabledHours={() => Array(24).fill(0).map((_, i) => i).filter(h => h < currentBlock.start[0])}
        />
      </Col>
      
        <Col span={4}>
          <Button type='link' icon='delete' onClick={() => handleDeleteBlock(day, block)} />
          {isLastBlock ? <Button type='link' icon='plus-circle' onClick={() => handleAddBlock(day, block)} /> : null}
        </Col>
    </Row>
  )
}


export default function Schedule({
  handleChange,
  index,
  question,
}) {
  const [schedule, setSchedule] = useState({
    'monday': Array(96).fill(0),
    'tuesday': Array(96).fill(0),
    'wednesday': Array(96).fill(0),
    'thursday': Array(96).fill(0),
    'friday': Array(96).fill(0),
    'saturday': Array(96).fill(0),
    'sunday': Array(96).fill(0),
  });

  useEffect(() => {
    // Initialize with at least one block each day.
    const newSchedule = structuredClone(schedule);
    daysOfWeek.forEach((day) => {
      newSchedule[day][0] = 1;
    });
    setSchedule(newSchedule);
  }, [])

  const replicateSchedule = (day) => () => {
    const newSchedule = structuredClone(schedule);
    daysOfWeek.map(d => {
      if (d !== day) {
        newSchedule[d] = [...newSchedule[day]];
      }
    })
    setSchedule(newSchedule);
  }

  const handleAddBlock = (day, selectedBlock) => {
    const newSchedule = structuredClone(schedule);
    const endOneHot = hourOfDayToOneHot(selectedBlock.end[0], selectedBlock.end[1], true);
    let newIndex = endOneHot + 2;
    while ((newSchedule[day][newIndex] === 1 || newSchedule[day][newIndex+1] === 1) && newIndex < 96 ) {
      newIndex++;
    }
    newSchedule[day][newIndex] = 1;
    setSchedule(newSchedule);
  }

  const handleAddFirstBlock = (day) => {
    const newSchedule = structuredClone(schedule);
    newSchedule[day][0] = 1;
    setSchedule(newSchedule);
  }

  const handleScheduleChangeBlock = (day, oldBlock, newBlock) => {
    const newSchedule = structuredClone(schedule);
    const oldStartOneHot = hourOfDayToOneHot(oldBlock.start[0], oldBlock.start[1]);
    const oldEndOneHot = hourOfDayToOneHot(oldBlock.end[0], oldBlock.end[1], true);
    newSchedule[day].fill(0, oldStartOneHot, oldEndOneHot + 1);

    const startOneHot = hourOfDayToOneHot(newBlock.start[0], newBlock.start[1]);
    const endOneHot = hourOfDayToOneHot(newBlock.end[0], newBlock.end[1], true);
    newSchedule[day].fill(1, startOneHot, endOneHot + 1);

    setSchedule(newSchedule);
  }

  const handleDeleteBlock = (day, block) => {
    const newSchedule = structuredClone(schedule);
    const startOneHot = hourOfDayToOneHot(block.start[0], block.start[1]);
    const endOneHot = hourOfDayToOneHot(block.end[0], block.end[1], true);
    newSchedule[day].fill(0, startOneHot, endOneHot + 1);
    setSchedule(newSchedule);
  }

  const dayBlocks = useMemo(() => {
    const blocks = {};
    let inABlock = false;
    let newBlock;
    daysOfWeek.forEach((day) => {
      blocks[day] = [];
      schedule[day].forEach((oneHot, index) => {
        if (oneHot) {
          if (!inABlock) {
            inABlock = true;
            newBlock = {
              start: [],
              end: []
            };
            newBlock.start = oneHotToHourOfDay(index);
            newBlock.end = oneHotToHourOfDay(index, true);
          } else {
            newBlock.end = oneHotToHourOfDay(index, true);
          }
        } else if (inABlock) {
          inABlock = false;
          blocks[day].push(newBlock);
        }
      })
    });
    return blocks;
  }, [schedule])

  useEffect(() => {
    handleChange(index, schedule);
  }, [schedule])

  return (
    <Row type='flex' gutter={[10, 10]}>
      <Title level={3}>{question}</Title>
      {daysOfWeek.map((day) => {
        const atLeastOne = dayBlocks[day].length > 0;
        return (
          <Col sm={24}>
            <Row type='flex' align='middle'>
              <Col span={16}>
                <Title level={4}>{i18n.t(`commons__dayofweek__${day}`)}</Title>
              </Col>
              <Col span={8}>
                <Button onClick={replicateSchedule(day)} type='link' icon='copy'>Copiar horario</Button>
              </Col>
            </Row>
            <Row type='flex' gutter={[20, 20]}>
              {atLeastOne ?
                <Col span={24}>
                  {dayBlocks[day].map((block, index) => {
                    const isLastBlock = index === dayBlocks[day].length - 1;
                    return (
                      <BlockComponent
                        key={v4()}
                        day={day}
                        block={block}
                        handleScheduleChangeBlock={handleScheduleChangeBlock}
                        handleAddBlock={handleAddBlock}
                        handleDeleteBlock={handleDeleteBlock}
                        isLastBlock={isLastBlock}
                      />
                    )
                  })}
                </Col> :
                <Col span={24}>
                  <Text>Sin disponibilidad</Text>
                  <Button type='link' icon='plus-circle' onClick={() => handleAddFirstBlock(day)} />
                </Col>
              }
            </Row>
          </Col>
        )
      })}
    </Row>
  )
}
