import React from 'react'
import { Card } from 'antd'
import '../Feedback.scss'


const Chapter = (props) => {

  return (
    <Card
      className='chapter-header'
      title={props.title}
      style={{ width: '70%', marginBottom: '10px' }}
    >
      <div className='chapter-header-description' >
        <p>{props.description}</p>
      </div>
      <div className='chapter-header-image'>
        <img key={"bigImage"} src={props.bigImage} alt="different games" />
      </div>
    </Card>
  )
}

export default Chapter
