import React, { useContext } from 'react';

import JobAppContext from '../../context/JobAppContext';

import { Icon, Radio } from 'antd';

const PostulationType = () => {
  const { updateJobApplication, jobApplication } = useContext(JobAppContext);

  const types = [
    {
      value: false,
      icon: 'unlock',
      title: 'No confidencial',
      text: 'La información de tu empresa será visible para todos los postulantes.',
    },
    {
      value: true,
      icon: 'lock',
      title: 'Confidencial',
      text: 'La información, logo y colores de tu empresa estará oculta para todos los postulantes.',
    },
  ];

  return (
    <div className="jobapp-types-radio">
      <Radio.Group
        value={jobApplication.is_confidential}
        onChange={(e) =>
          updateJobApplication('is_confidential', e.target.value)
        }
      >
        {types.map((type) => (
          <Radio.Button value={type.value}>
            <div className="jobapp-types-radio__type">
              <Icon type={type.icon} />
              <div>
                <span>
                  <strong>{type.title}</strong>
                </span>
                <span>{type.text}</span>
              </div>
            </div>
          </Radio.Button>
        ))}
      </Radio.Group>
    </div>
  );
};

export default PostulationType;
