import React, { useEffect, useState } from 'react';
import {
  Table,
  Modal,
  Row,
  Typography,
  Button,
  Select,
  Icon,
  Input,
  message,
} from 'antd';

import ExpandedRowCutes from './ExpandedRowCutes';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actionCreators from '../../../../../../../../../../../actions/data';

import debounce from 'lodash.debounce';

const { Title, Text } = Typography;

const CuteConfiguration = (props) => {
  const columns = [
    {
      title: 'NOMBRE DE LA PRUEBA',
      dataIndex: 'title',
      key: 'title',
      width: '25%',
    },
    {
      title: 'PREGUNTAS',
      key: 'questions',
      width: '25%',
      render: (item) => (
        <span>{countQuestions(item.questions.data, item.scores_config)}</span>
      ),
    },
    {
      key: 'id',
    },
  ];

  const stageCol = {
    title: 'ETAPA',
    dataIndex: 'stageName',
    key: 'stageName',
    width: '25%',
  };

  const countQuestions = (dataArray, config) => {
    if (config && config.questions_to_evaluate) {
      return config.questions_to_evaluate;
    }
    let total = 0;
    dataArray.forEach((obj) => {
      // Don't count conditional pages or read only fields
      if (obj.conditional === undefined) {
        let fieldsLength = obj.fields.filter(
          (field) => field.component !== 'read_only'
        ).length;
        total = fieldsLength + total;
      }
    });
    return total;
  };

  const removeCol = {
    title: '',
    key: 'action',
    width: '25%',
    render: (item) => (
      <Row type="flex" justify="end">
        <Button
          onClick={() => removeCute(item)}
          type={'primary'}
          icon="minus"
        />
      </Row>
    ),
  };

  const addCol = {
    title: '',
    key: 'add',
    width: '25%',
    render: (item) => {
      return (
        <Row type="flex" justify="end">
          <Button
            onClick={() => addCute(item)}
            type={'primary'}
            disabled={item.stage === undefined ? true : false}
            icon="plus"
          />
        </Row>
      );
    },
  };

  const selectStage = {
    title: 'ETAPA',
    key: 'stage',
    render: (item) => (
      <span>
        <Select
          defaultValue={'ACTIV'}
          style={{ width: '100%' }}
          onChange={(value) => changeCuteStage(item, value)}
        >
          {
            /*
            props.jobapp.jobApplication.stages.stages.map(obj =>
              <Select.Option key={obj.code} value={obj.code}>{obj.name}</Select.Option>
            )
            */
            <Select.Option key={'ACTIV'} value={'ACTIV'}>
              {'En proceso'}
            </Select.Option>
          }
        </Select>
      </span>
    ),
  };

  const [addedCuTe, setAddedCuTe] = useState([]);
  const [cuTes, setCuTes] = useState([]); // available cutes that are currently not assigned

  const [filteredCutes, setFilteredCutes] = useState([]); // available cutes that are currently not assigned

  useEffect(() => {
    if (addedCuTe.length > 0) {
      props.setAddedValue(true);
    } else {
      props.setAddedValue(false);
    }
  }, [addedCuTe]);

  useEffect(() => {
    const endpointTests = `/api/v1/gamesandtests/customtests/`;
    props.actions
      .fetchAndReturn(props.token, endpointTests)
      .then((response) => {
        if (response.status < 400) {
          // get the assigned activities ids, CUTEs are store like CUTE : [ {cuteObj, ...} ]
          const propsCutes = props.currentCustomTests
            ? props.currentCustomTests
            : [];
          let currentAssignedCuTes = [];
          let currentCuTesIds = [];

          if (props.currentCustomTests) {
            currentCuTesIds = props.currentCustomTests.map((obj) => obj.id);
          } else {
            currentAssignedCuTes = props.assignedActivities['CUTE']
              ? props.assignedActivities['CUTE']
              : [];
            currentAssignedCuTes.forEach((obj) => {
              const tmpCute = response.body.find(
                (cute) => cute.id == obj.configuration.id
              );
              obj.questions = tmpCute.questions;
              obj.description = tmpCute.description;
              obj.scores_config = tmpCute.scores_config;
              const tmpStage = props.jobapp.jobApplication.stages.stages.find(
                (stage) => stage.code === obj.stage
              );
              obj.stageName = tmpStage ? tmpStage.name : undefined;
              obj.title = tmpCute.title;
            });
            currentCuTesIds = currentAssignedCuTes.map(
              (obj) => obj.configuration.id
            );
          }

          let cuTesToAdd = [...response.body];

          currentCuTesIds.forEach((id) => {
            // remove from add list if already added
            const tmpRemoveCuTeIndex = cuTesToAdd.findIndex(
              (obj) => obj.id === id
            );
            cuTesToAdd.splice(tmpRemoveCuTeIndex, 1);
          });

          setAddedCuTe(currentAssignedCuTes);
          // All cutes by default are added in the ACTIV stage
          cuTesToAdd.forEach((obj) => {
            if (obj.stage === undefined) {
              obj.stage = 'ACTIV';
              obj.stageName = props.jobapp.jobApplication.stages.stages.find(
                (stage) => stage.code === obj.stage
              ).name;
            }
          });
          setCuTes(cuTesToAdd);
          setFilteredCutes(cuTesToAdd);
        } else {
          setCuTes([]);
        }
      });
  }, []);

  const addCute = (newCuTe) => {
    if (addedCuTe.some((cute) => cute.id === newCuTe.id)) {
      message.error('Ya agregaste esta prueba');
      return;
    }

    const cuteActivity = props.jobapp.activities.results.find(
      (obj) => obj.code === 'CUTE'
    );
    newCuTe.activity_id = cuteActivity.id;
    newCuTe.code = 'CUTE';
    newCuTe.category = cuteActivity.category;
    newCuTe.configuration = {
      id: newCuTe.id,
      name: newCuTe.candidate_path_title,
    };
    newCuTe.job_application_id = props.jobapp.jobAppId;

    // The og id (newCute.id) is the cute id not de assigned activity id, we also storre this id in the configuration field. The
    // endpoint should ignore this id and create a new one

    // we dont really need this value but in the model it's set as not null :(
    newCuTe.height = 1;
    newCuTe.order = 1;

    let newCuTes = [...addedCuTe, newCuTe];

    setAddedCuTe(newCuTes);
    const indexToDelete = cuTes.findIndex((item) => item.id === newCuTe.id);
    let updatedCuTe = [...cuTes];
    updatedCuTe.splice(indexToDelete, 1);
    setCuTes(updatedCuTe);

    props.onChangeCustomTest(newCuTe, newCuTes, 'add');
  };

  const removeCute = (newCuTe) => {
    setCuTes([...cuTes, newCuTe]);
    const indexToDelete = addedCuTe.findIndex((item) => item.id === newCuTe.id);
    //addedCuTe[indexToDelete].stage = undefined;

    let updatedCuTe = [...addedCuTe];
    updatedCuTe.splice(indexToDelete, 1);
    setAddedCuTe(updatedCuTe);

    props.onChangeCustomTest(newCuTe, updatedCuTe, 'delete');
  };

  const changeCuteStage = (item, stage) => {
    const cuteId = item.id;
    const cuteIndex = cuTes.findIndex((obj) => obj.id === cuteId);
    cuTes[cuteIndex].stage = stage;
    cuTes[cuteIndex].stageName = props.jobapp.jobApplication.stages.stages.find(
      (obj) => obj.code === stage
    ).name;
    setCuTes([...cuTes]);
  };

  const handleOk = () => {
    props.setVisible(false);
  };

  const customExpandIcon = (props) => {
    if (props.expanded) {
      return (
        <Icon
          type="down"
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        />
      );
    } else {
      return (
        <Icon
          type="right"
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        />
      );
    }
  };

  const handleSearch = debounce((value) => {
    setFilteredCutes(() =>
      cuTes.filter((cute) =>
        cute.title.toLowerCase().includes(value.toLowerCase())
      )
    );
  }, 500);

  return (
    <Modal
      title="Evaluaciones adicionales"
      width={1060}
      visible={props.visible}
      onCancel={handleOk}
      footer={
        <Button type="primary" onClick={handleOk}>
          Cerrar
        </Button>
      }
      style={{ top: '5vh' }}
      bodyStyle={{ height: '85vh', overflowY: 'auto' }}
    >
      <Row>
        <Text>
          Estas pruebas te permiten evaluar conocimientos específicos para el
          cargo que requieras.
        </Text>
      </Row>
      <Row>
        <Title level={3} style={{ margin: '20px 0 10px 0' }}>
          Pruebas asignadas
        </Title>
        <Table
          pagination={false}
          dataSource={addedCuTe}
          columns={[...columns, stageCol, removeCol]}
          expandedRowRender={(cute) => ExpandedRowCutes(cute)}
          expandIcon={(props) => customExpandIcon(props)}
          rowKey="title"
          style={{ border: '1px solid #D9D9D9', borderBottom: 'none' }}
        />
      </Row>
      <Row style={{ margin: '30px 0 10px 0' }}>
        <Title level={3}>Pruebas disponibles</Title>
        <Input
          placeholder="Buscar prueba personalizada..."
          style={{ marginBottom: '0.5rem' }}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <Table
          pagination={false}
          dataSource={filteredCutes}
          columns={[...columns, selectStage, addCol]}
          expandedRowRender={(cute) => ExpandedRowCutes(cute)}
          expandIcon={(props) => customExpandIcon(props)}
          style={{ border: '1px solid #D9D9D9', borderBottom: 'none' }}
          rowKey="id"
        />
      </Row>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    assignedActivities: state.jobapp.assignedActivities,
    jobapp: state.jobapp,
    token: state.auth.token,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CuteConfiguration);
