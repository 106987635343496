import React, {useState, useEffect, useRef} from "react";

import {
  Typography
} from 'antd';

import OptionButton from "../commons/OptionButton";
import ParseHtml from "../../../../../components/parseHtml";

const alternativesDict = (idx) => String.fromCharCode(97 + idx);

const MultiAnswer = ({ field, fieldChanged, values, disableNextPage }) => {
  const [state, setState] = useState([]);
  const disableNextPageStable = useRef(disableNextPage);

  useEffect(() => {
    setState(values)
  }, [values])

  useEffect(() => {
    if (state.length > 0) {
      disableNextPageStable.current(field._uid, false);
    }
  }, [state, field._uid])

  const onClick = (id, index, value) => {
    if (state[index] === value) {
      // logic to remove answer
      const newState = [...state];
      newState[index] = null;
      setState(newState);
      return;
    }
    fieldChanged(id, index, value);
    const newState = [...state];
    newState[index] = value;
    setState(newState);
  }

  return (
    <div>
      <div style={{ marginTop: 20 }}>
        <Typography.Text style={{ fontSize: 14, color: '#A4A4A4' }}>SELECCIÓN MÚLTIPLE</Typography.Text>
      </div>
      <div style={{ marginBottom: 10 }}>
        <Typography.Text style={{ fontSize: 16, color: '#5E5E5E', userSelect: 'none' }}>{ParseHtml(field.label)}</Typography.Text>
      </div>
      {field.options.map((option, index) => {
        return (
          <div key={option.value}>
            <OptionButton
              checked={state ? state[index] === option.value : false}
              id={field._uid}
              label={option.label}
              value={option.value}
              index={index}
              prefix={alternativesDict(index)}
              type={'multi'}
              onClick={(id, index, value) => onClick(id, index, value)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default MultiAnswer;
