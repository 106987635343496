import React, { useState, useEffect } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import CustomSnack from '../Common/CustomSnackbar';

import * as actionCreators from '../../actions/data';
import * as actions from '../../actions/auth';

import {
  Button,
  Form,
  Icon,
  Typography,
  Row,
  Col,
  Input
} from 'antd';
import i18n from "../../i18n";


const { Title, Paragraph } = Typography;

function AccountView(props) {

  const initialState = {
    confirmDirty: false,
    emailUpdate: '',
    oldPassword: '',
    passwordUpdate: '',
    confirmNewPassword: '',
    errorTxt: null,
    openSnack: false,
    errors: {
      email: false,
      confirmNewPassword: false,
      oldPassword: false,
    },
    loading: false
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState(oldState => ({ ...oldState, emailUpdate: props.email }));
  }, []);

  const closeSnack = () => {
    setState(oldState => ({ ...oldState, openSnack: false }));
  }

  const handleChange = prop => event => {
    const value = event.target.value;
    let tempErrors = state.errors;
    tempErrors[prop] = false;
    setState(oldState => ({ ...oldState, errors: tempErrors, [prop]: value }));
  };

  const handleSubmit = e => {
    e.preventDefault();

    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        setState(oldState => ({ ...oldState, errorTxt: null, loading: true }));

        const token = props.token;
        const url = props.userType === 'personal' ? (
          '/api/v1/accounts/updatecandidatedata/'
        ) : (
          `/api/v1/accounts/businessusers/${props.userId}/`
        );
        const submitData = {
          email: state.emailUpdate.toLowerCase(),
          old_password: state.oldPassword,
          password: state.passwordUpdate,
        }
        props.actions.fetchAndReturn(token, url, 'PATCH', submitData).then(response => {
          if (response.status === 500) {
            if (response.body.error) {
              if (response.body.error.includes('already_used')) {
                setState(oldState => ({
                  ...oldState,
                  openSnack: false,
                  loading: false,
                  errorTxt: 'La contraseña no puede haber sido usada en el último año.'
                }));
              }
              else if (response.body.error.includes('password_too_common')) {
                setState(oldState => ({
                  ...oldState,
                  openSnack: false,
                  loading: false,
                  errorTxt: 'La contraseña es muy común, intenta con otra menos predecible.'
                }));
              }
              else {
                setState(oldState => ({
                  ...oldState,
                  openSnack: false,
                  loading: false,
                  errorTxt: 'Hubo un error enviando los datos.'
                }));
              }
            }
            else {
              setState(oldState => ({
                ...oldState,
                openSnack: false,
                loading: false,
                errorTxt: 'Hubo un error enviando los datos.'
              }));
            }
          } else if (response.status >= 400) {
            if (response.status === 403 && response.message === 'Invalid credentials.') {
              setState(oldState => ({
                ...oldState,
                openSnack: false,
                loading: false,
                errorTxt: 'La contraseña actual ingresada es incorrecta.'
              }));
            } else {
              setState(oldState => ({
                ...oldState,
                openSnack: false,
                loading: false,
                errorTxt: 'Hubo un error enviando los datos.'
              }));
            }
          } else {
            setState(oldState => ({
              ...oldState,
              errorTxt: null,
              openSnack: true,
              oldPassword: '',
              passwordUpdate: '',
              confirmNewPassword: '',
              loading: false
            }));
            props.form.setFieldsValue({
              oldPassword: '',
              passwordUpdate: '',
              confirmNewPassword: '',
            });
            props.actionsAuth.updateEmailAcc(state.emailUpdate.toLowerCase())

            // limpiar los campos (excepto el mail) después de cambiar la contraseña
            props.form.setFieldsValue({
              oldPassword: '',
              password: '',
              confirm: '',
            });
          }
        });
      }
    });
  };

  const handleConfirmBlur = e => {
    const { value } = e.target;
    setState(oldState => ({ ...oldState, confirmDirty: state.confirmDirty || !!value }));
  };

  const compareToFirstPassword = (rule, value, callback) => {
    const { form } = props;
    // confirmar que las contraseñas sean iguales
    if (value && value !== form.getFieldValue('password')) {
      callback(i18n.t('change__password__error__3'));
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule, value, callback) => {
    const { form } = props;

    // 1 lower, 1 upper, 1 number, 1 symbol
    const re = props.userType === 'business' ? /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$%&\*\+\-_:;.=?@~])/ : /^(?=.*\d)(?=.*[A-Z])|(?=.*[a-z]).{8,}$/;

    if (value && state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }

    if (value && !re.test(value)) {
      callback(true); // mal
    }

    callback(); // correcto
  };

  const { getFieldDecorator } = props.form;
  return (
    <div className='account-config-container'>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Icon type="arrow-left" style={{ display: 'flex', marginBottom: '0.5em', marginRight: 10 }} onClick={() => props.dispatch(push('/account/settings'))} />
        <Title level={2}>{i18n.t('account__change__password')}</Title>
      </div>
      <Paragraph style={{ fontSize: 16 }}>
        {i18n.t('my__account__description')}
      </Paragraph>

      <div className="account-base-card ">
        <Row type='flex' justify='center' align='top' gutter={[24, 24]}>
          <Col xs={24} sm={20} md={12}>
            <Form onSubmit={handleSubmit}>
              <Form.Item label={i18n.t('my__account__password')}>
                {getFieldDecorator('oldPassword', {
                  onChange: handleChange('oldPassword'),
                  rules: [
                    {
                      required: true,
                      message: i18n.t('change__password__please__enter__your__password'),
                    },
                  ],
                })(<Input.Password size='large' />)}
              </Form.Item>

              <Form.Item label={i18n.t('commons__new__password')} hasFeedback>
                {getFieldDecorator('password', {
                  onChange: handleChange('passwordUpdate'),
                  rules: [
                    {
                      required: true,
                      message: i18n.t('account__please__enter__new__password'),
                    },
                    {
                      validator: validateToNextPassword,
                    },
                    { min: 8 }
                  ],
                })(
                  <Input.Password
                    size='large'
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  />
                )}
              </Form.Item>

              <Form.Item label={i18n.t('commons__password_confirmation')} hasFeedback>
                {getFieldDecorator('confirm', {
                  onChange: handleChange('confirmNewPassword'),
                  rules: [
                    {
                      required: true,
                      message: i18n.t('account__please__confirm__password'),
                    },
                    {
                      validator: compareToFirstPassword,
                    },
                  ],
                })(
                  <Input.Password
                    size='large'
                    onBlur={handleConfirmBlur}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  />
                )}
              </Form.Item>


              <Button type="secondary" htmlType="submit" loading={state.loading} onClick={() => props.dispatch(push('/account/settings'))} style={{ marginRight: 15 }}>
                {i18n.t('commons__cancel')}
              </Button>
              <Button type="primary" htmlType="submit" loading={state.loading}>
                {i18n.t('my__account__password__confirmation__button')}
              </Button>

            </Form>

            <CustomSnack
              msg={i18n.t('account__update__password')}
              open={state.openSnack}
              handleClose={closeSnack}
            />
          </Col>
          <Col xs={24} sm={20} md={12}>
            <Title level={3}>
              {i18n.t('my__account__password__description_constrain')}
            </Title>
            {/* change to password new rules */}
            <Paragraph style={{ fontSize: 16 }}>{
              props.userType === 'business' ?
                i18n.t('my__account__password__constrain__business')
                :
                i18n.t('my__account__password__constrain__personal')
            }</Paragraph>
            <Paragraph style={{ fontSize: 16 }}>{i18n.t('my__account__password__constrain__2')}</Paragraph>
          </Col>
        </Row>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    alerts: state.alerts,
    email: state.auth.userName,
    error: state.data.error,
    isAuthenticated: state.auth.isAuthenticated,
    isAuthenticating: state.auth.isAuthenticating,
    token: state.auth.token,
    userId: state.auth.userID,
    userType: state.auth.userType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    actionsAuth: bindActionCreators(actions, dispatch),
    dispatch,
  };
};

const AccountForm = Form.create({ name: 'account-form' })(AccountView);
export default connect(mapStateToProps, mapDispatchToProps)(AccountForm);
