import React from 'react';
import { Result, Button, Typography } from 'antd';
import i18n from '../../../i18n';
export default class NotFoundView extends React.Component {
    render() {
        return (
            <Result
            title={<Typography.Title>Error 404</Typography.Title>}
            icon={<img src="https://genoma-assets.s3.us-east-2.amazonaws.com/genomin.png" />}
            subTitle={<Typography.Paragraph>{i18n.t('commons__404')}</Typography.Paragraph>}
            extra={<a href='/'><Button type="primary">{i18n.t('commons__back')[0].toUpperCase() + i18n.t('commons__back').slice(1)}</Button></a>}
            />
        );
    }
}
