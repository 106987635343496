import React, { Component, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

import { Button, Icon, Upload, message } from 'antd';
import PropTypes from 'prop-types';
import { SERVER_URL } from '../../../../../utils/config';
import i18n from '../../../../../i18n';

const MceEditor = (props) => {
  const { handleAttachFile } = props;
  const [fileList, setFileList] = useState([]);

  const uploadOptions = {
    name: 'file',
    accept: '.pdf,.doc,.docx,.ppt,.pptx,.xlx,.xlsx',
    action: `${SERVER_URL}/api/v1/gamesandtests/fileupload/`,
    headers: {
      authorization: `Token ${props.token}`,
    },
    onChange: (info) => {
      if (info.file.status === 'uploading') {
        const currentFileListSize = info.fileList.reduce(
          (x, y) => x + y.size,
          0
        );
        if (currentFileListSize >= 10485760) {
          message.error(
            'Tamaño máximo del total de archivos de 10 MB excedido.'
          );
        } else if (info.fileList.length > 3) {
          message.error('Solo puedes adjuntar un máximo de 3 documentos.');
        } else {
          setFileList(info.fileList);
        }
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
        handleAttachFile(info);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      } else if (info.file.status === 'removed') {
        setFileList(info.fileList);
        handleAttachFile(info);
      }
    },
  };
  // This is the real
  const handleEditorChange = (disabled) => (text) => {
    !disabled && props.handleChange(props.name)({ target: { value: text } });
  };

  return (
    <div>
      {props.body === undefined ? (
        <p>Cargando...</p>
      ) : (
        <Editor
          // value={props.text}
          onEditorChange={handleEditorChange(props.disabled)}
          value={props.disabled ? props.readOnlyBody : props.body}
          disabled={props.disabled}
          init={{
            entity_encoding: 'raw',
            height: props.height ? props.height : 200,
            branding: false,
            menubar: false,
            elementpath: false,
            language: i18n.language,
            images_upload_url: `${SERVER_URL}/api/v1/gamesandtests/fileupload/`,
            plugins: props.plugins
              ? props.plugins
              : 'lists,anchor,link,image,code,visualchars,paste',
            file_picker_types: 'file image media',
            toolbar: props.toolbar ??
              'fontsizeselect | forecolor | backcolor | bold | italic | underline | alignleft | aligncenter | alignright | alignjustify | link | undo | redo | visualchars | image | code',
            selector: 'textarea',
            paste_as_text: true,
          }}
        />
      )}
      {handleAttachFile && (
        <Upload {...uploadOptions} fileList={fileList}>
          <Button style={{ marginTop: '0.5rem' }}>
            <Icon type="upload" /> {i18n.t('profile__add_files', { ns: 'candidateProfile' })}
          </Button>
        </Upload>
      )}
    </div>
  );
};

MceEditor.propTypes = {
    body: PropTypes.string.isRequired,
    readOnlyBody: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleAttachFile: PropTypes.func,
    disabled: PropTypes.bool,
    plugins: PropTypes.string,
    token: PropTypes.string,
    height: PropTypes.number,
}


export default MceEditor;
