import React, { useEffect } from 'react';
import { Col, Avatar, Row, Tooltip } from 'antd';
import propTypes from 'prop-types';


/**
 * @param {number} span
 * @param {string} height
 * @param {string} background
 * @param {string} margin
 * @param {object} avatars
 * @param {array} withoutData
 */

const CustomSlider = ({ span, height, background, margin, avatars, withoutData }) => {

  const backgroundPalette = ['#D3ADF7', '#91D5FF', '#87E8DE', '#FFD591', '#ADC6FF'];
  const colorPallete = ['#531DAB', '#096DD9', '#08979C', '#D46B08', '#1D39C4']

  if(withoutData.length > 0) {
    withoutData.forEach((item) => {
      backgroundPalette.splice(item, 1)
    })
    withoutData.forEach((item) => {
      colorPallete.splice(item, 1)
    })
  }

  // Group avatars by score
  const avatarsByScore = avatars.reduce((acc, avatar, idx) => {
    const score = avatar[1];
    if (!acc[score]) {
      acc[score] = [];
    }
    acc[score].push({ ...avatar, background: backgroundPalette[idx], color: colorPallete[idx] });
    return acc;
  }, {});

  return (
    <Row style={{ margin: margin }}>
      <Col
        span={span}
        style={{ display: 'flex', alignItems: 'center', height: height, background: background, width: '100%' }}
      >
        {Object.keys(avatarsByScore).map((score, index) => (
          <div style={{ position: 'absolute', left: `${score}%` }}>
            {avatarsByScore[score].map((avatar, index) => (
              <Tooltip key={index} placement='topLeft' title={`${avatar[1]}%`}>
                <Avatar
                  className='avatar'
                  size={25}
                  style={{
                    background: avatar.background,
                    color: avatar.color,
                    marginTop: `${index * -50}px`, // This creates the stack effect
                    marginLeft: '-25px',
                  }}
                >
                  {avatar[0].toUpperCase()}
                </Avatar>
              </Tooltip>
            ))}
          </div>
        ))}
      </Col>
    </Row>
  );
}

CustomSlider.propTypes = {
  span: propTypes.number.isRequired,
  height: propTypes.string.isRequired,
  background: propTypes.string.isRequired,
  margin: propTypes.string.isRequired,
  avatars: propTypes.array.isRequired,
  withoutData: propTypes.array.isRequired
}

export default CustomSlider;