import React from 'react';
import { Typography, Row, Modal, Radio, Checkbox, Input } from 'antd';
import i18n from '../../../../i18n';
const { Text } = Typography;
const { TextArea } = Input;

const ResetInterviewModal = ({
  checkMailNotification,
  name,
  openVideoReset,
  setOpenVideoReset,
  sendEmailNotification,
  onChange,
  onChangeEmail,
  resetVideoOption,
  deleteVideoAction
}) => {
  return (
    <Modal
      title={`¿${i18n.t('profile__do_you_want_to_reset_ve', {ns: 'candidateProfile'}).split('{')[0]} ${name}?`}
      centered
      visible={openVideoReset}
      onOk={() => deleteVideoAction()}
      onCancel={() => setOpenVideoReset(false)}
      cancelText={i18n.t('profile__cancel', {ns: 'candidateProfile'})}
      okText={
        !sendEmailNotification
          ? i18n.t('profile__reset_ve', {ns: 'candidateProfile'})
          : i18n.t('profile__reset_and_sent_message', {ns: 'candidateProfile'})
      }
      className='reset-interviews-modal'
    >
      <Text strong>{i18n.t('profile__reason_for_resetting', {ns: 'candidateProfile'})}</Text>
      <Radio.Group
        onChange={onChange}
        value={resetVideoOption?.value}
        className='reset-interviews-modal__radio-container'
      >
        <Radio value={'SAVE'}>
          {i18n.t('profile__answer_was_not_saved', {ns: 'candidateProfile'})}
        </Radio>
        <Radio value={'ANSW'}>
          {i18n.t('profile__candidate_no_respond', {ns: 'candidateProfile'})}
        </Radio>
        <Radio value={'LANG'}>
          {i18n.t('profile__other_language', {ns: 'candidateProfile'})}
        </Radio>
      </Radio.Group>
      <Row>
        <Checkbox onChange={checkMailNotification}>
          <Text>{i18n.t('profile__notify', {ns: 'candidateProfile'}).replace('{{name}}', name)}</Text>
          <Text disabled>({i18n.t('profile__optional',  {ns: 'candidateProfile'})})</Text>
        </Checkbox>
      </Row>
      {sendEmailNotification && (
        <TextArea
          rows={4}
          style={{ marginTop: '10px' }}
          onChange={(e) => onChangeEmail(e)}
        />
      )}
    </Modal>
  );
};

export default ResetInterviewModal;
