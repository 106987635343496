import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Typography,
  Input,
  Button,
  Select,
  Form,
  Icon
} from 'antd';
import propTypes from 'prop-types';
import i18n from '../../../i18n';

const { Title } = Typography;
const { Option } = Select;

/**
 * 
 * @param {String} searchText
 * @param {String} searchLocation
 * @param {Function} fetchJobApplications
 * @param {Function} setPage 
 * @returns 
 */

const Filters = ({ searchText, searchLocation, fetchJobApplications, setPage, countries }) => {
  const [localSearchText, setLocalSearchText] = useState(null)
  const [localSearchLocation, setLocalSearchLocation] = useState(null)

  const handleSubmit = (e) => {
    e.preventDefault()
    setPage(1)
    fetchJobApplications(localSearchText, localSearchLocation, 1)
  }

  const selectChange = (e) => {
    setLocalSearchLocation(e)
  }

  useEffect(() => {
    setLocalSearchLocation(searchLocation)
    setLocalSearchText(searchText)
  }, [searchLocation, searchText])

  return (
    <Row className="jobs-search" type="flex" gutter={20}>

      <Form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <Col xs={24} sm={12} md={8} lg={10} className='input-filter'>
          <Row type='flex' justify='end'>
            <Title level={3} style={{ color: '#454545', fontSize: '18px' }}>
              {i18n.t('commons__position')}
            </Title>
            <Input.Search
              onSearch={() => fetchJobApplications(localSearchText, localSearchLocation)}
              value={localSearchText}
              placeholder={i18n.t('job_portal__charge__placeholder')}
              onChange={(e) => setLocalSearchText(e.target.value)}
              suffix={ localSearchText !== null ? ( <Icon type='close-circle' style={{ margin: '0 5px 0 0' }} onClick={() => setLocalSearchText(null)}/> ) : null }
            />
          </Row>
        </Col>
        <Col xs={24} sm={12} md={8} lg={10} className='input-filter'>
          <Row type='flex' justify='end'>
            <Title level={3} style={{ color: '#454545', fontSize: '18px' }}>
              {i18n.t('process__table__ubication')}
            </Title>
            <Select placeholder="País" onChange={selectChange} value={localSearchLocation}>
              {Object.keys(countries).map((country, index) => (
                <Option key={index} value={countries[country].iso_2}>
                  {countries[country].translation[i18n.language.split('-')[0]]}
                </Option>
              ))}
            </Select>
          </Row>
        </Col>
        <Col xs={24} sm={12} md={8} lg={4} className='button-filter'>
          <Row type='flex' align='bottom'>
            <Button
              type="primary"
              htmlType='submit'
            >
              {i18n.t('job_portal__search')}
            </Button>
          </Row>
        </Col>
      </Form>

    </Row>
  );
}

Filters.propTypes = {
  searchText: propTypes.string,
  searchLocation: propTypes.string,
  fetchJobApplications: propTypes.func,
  setPage: propTypes.func
}

export default Filters;