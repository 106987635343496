import React from 'react';
import PropTypes from 'prop-types';

import TourModal from './TourModal';

import './styles.scss';

export default function TourComponent(props) {

  return (
    <TourModal
      title={props.title}
      content={props.content}
      contentTitle={props.contentTitle}
      tourConfig={props.tourConfig}

      // Set the modal visible
      isVisible={props.isVisible}
      setVisible={props.setVisible}
      businessUserName={props.businessUserName}
      width={props.width}
      showNavigation={props.showNavigation}
      redirectTo={props.redirectTo}
    />
  )
}

TourComponent.propTypes = {
  title: PropTypes.node,
  content: PropTypes.node,
  contentTitle: PropTypes.node,
  tourConfig: PropTypes.arrayOf(PropTypes.object),
  isVisible: PropTypes.bool,
  setVisible: PropTypes.func,
  businessUserName: PropTypes.string,
  width: PropTypes.number,
  showNavigation: PropTypes.bool,
  redirectTo: PropTypes.string,
};
