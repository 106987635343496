import React, { useState, useEffect } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Buffer } from 'buffer';

import * as actionCreators from '../../../actions/data';

import {
  Col,
  Row,
  message
} from 'antd';

import './customTests.scss';

import Form from "./components/Form";
import StartPage from "./components/StartPage";

function FormView(props) {
  const [state, setState] = useState({ color: '#f175a5', formData: undefined, globalTime: undefined, sumPagesTime: undefined });
  const [testStatus, setTestStatus] = useState({ inProgress: false });

  useEffect(() => {
    setTestStatus(oldData => ({ ...oldData, inProgress: true }));
  }, [state.formData]);

  const startTest = () => {

    // get custom test
    const endpoint = `/api/v1/gamesandtests/assignedactivitypersonalprogress/${props.assActPP}/customtests/${props.cuteId}/`
    props.actions.fetchAndReturn(props.token, endpoint)
      .then(response => {
        if (response.status < 400) {
          response.body['questions'] = JSON.parse(Buffer.from(response.body['questions'], 'base64').toString('utf-8'));
          setState(oldData => ({ ...oldData, formData: response.body.questions.data, globalTime: response.body['questions'].global_time }));
        }
        else {
          message.error('Error al cargar los datos por favor inténtalo nuevamente', 5);
          props.backToPath();
        }
        setTestStatus(oldData => ({ ...oldData, inProgress: true }));
      })

    // Move candidate to PENDING status, this means the test has started and the candidate only have this instance to answer
    const progressEndpoint = `/api/v1/gamesandtests/personalprogress/${props.assActPP}/`
    props.actions.fetchAndReturn(props.token, progressEndpoint, 'PATCH', { status: 'PENDING', time_remaining: 0 })
      .then(response => {
        if (response.status >= 400) {

          message.error('Error al cargar los datos por favor inténtalo nuevamente', 5);
          props.backToPath();
        }
      })
  };

  return (
    <Row type='flex' align='middle' justify='center'>
      <Col xs={24} sm={24} md={24} lg={24} >
        {
          testStatus.inProgress && state.formData !== undefined ?
            <Form formData={state.formData} globalTime={state.globalTime} state={state} backToPath={props.backToPath} jobappId={props.jobappId} cuteId={props.cuteId} assActPP={props.assActPP} />
            :
            <StartPage buttonColor={state.color} startTest={startTest} jobappId={props.jobappId} backToPath={props.backToPath} />
        }
      </Col>
    </Row>

  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(FormView);

