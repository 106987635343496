import React from 'react';
import DetailDisc from './disc/DetailDisc';
import DetailDat from './dat/DetailDat';
import DetailAlternatives from './alternatives/DetailAlternatives'
import DetailLikert from './likert/DetailLikert';
import DetailSJT from './sjt/DetailSJT';
import PropTypes from 'prop-types';

export const CustomTestsDetail = ({ data, personalityScores }) => {
  switch (data.scores.type) {
    case 'DISC':
      return (
        <DetailDisc scores={data.scores} />
      );
    case 'DAT':
      return (
        <DetailDat scores={data.scores} />
      );
    case 'RAVEN':
    case 'ALTERNATIVES':
      return (
        <DetailAlternatives
          answers={data.answers}
          questions={data.questions}
          sortedQuestions={data.sorted_questions}
          scores={data.scores}
          scoresConfig={data.scores_config}
          description={data.description}
          dateCreated={data.date_created}
        />
      );
    case 'SJT':
      return (
        <DetailAlternatives
          type="SJT"
          answers={data.answers}
          questions={data.questions}
          sortedQuestions={data.sorted_questions}
          scores={data.scores}
          scoresConfig={data.scores_config}
          description={data.description}
          dateCreated={data.date_created}
          personalityScores={personalityScores}
        />
      )

    case 'LIKERT':
      return (
        <DetailLikert 
        answers={data.answers}
        questions={data.questions}
        sortedQuestions={data.sorted_questions}
        scores={data.scores}
        scoresConfig={data.scores_config}
        description={data.description}
        dateCreated={data.date_created} 
        />
      )
    case 'SJT_GW':
      return (
        <DetailSJT
          answers={data.answers}
          questions={data.questions}
          sortedQuestions={data.sorted_questions}
          scores={data.scores}
          scoresConfig={data.scores_config}
          description={data.description}
          dateCreated={data.date_created} 
        />
      )
    default:
      return null;
  }
};

CustomTestsDetail.propTypes = {
  scores: PropTypes.object
}
export default CustomTestsDetail;
