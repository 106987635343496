import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Button, Col, DatePicker, Icon, Row, Select } from 'antd';
import i18n from '../../../../i18n';

const { Option, OptGroup } = Select;

const actionOptions = [
  { 
    value: 'user_job_application', 
    label: 'profile__job_application_process' 
  },
  { 
    value: 'assigned_activity_personal_progress', 
    label: 'profile__activities' 
  },
  { 
    value: 'interview', 
    label: 'profile__interview' 
  },
  { 
    value: 'mail_history', 
    label: 'profile__mails' 
  },
  { 
    value: 'reference', 
    label: 'profile__references' 
  },
  { 
    value: 'message', 
    label: 'profile__messages' 
  },
  { 
    value: 'interview_evaluation', 
    label: 'profile__interview_evaluation' 
  },
]

const ActivityFilter = ({ actions, auth, candidate, setFilters, translationFile }) => {

  const externalActors = [
    { 
      value: candidate.id, 
      label: candidate.user_name 
    },
    {
      value: 'trigger',
      label: i18n.t('profile__trigger', translationFile)
    }
  ]

  const [collaborators, setCollaborators] = useState([]);

  const changeFilters = (key, value) => {
    setFilters((currentFilters) => ({
      ...currentFilters,
      [key]: value,
    }));
  };

  const fetchBusinessUsers = async () => {
    const url = `/api/v1/permissions/business/${auth.currentBusiness.id}/`;

    const response = await actions.fetchAndReturn(auth.token, url)
    if (response.body.results) {
      const mappedCollabs = response.body.results.map(({business_user}) => {
        return {
          value: business_user.id,
          label: business_user.name ?? business_user.email
        }
      })
      setCollaborators(mappedCollabs);
    }
  };

  useEffect(() => {
    fetchBusinessUsers();
  }, [auth.currentBusiness.id, auth.token]);

  return (
    <Row style={{ marginBottom: 20 }} justify="space-around">
      <Col xs={24} sm={3}>
        <Button><Icon type="filter" />{i18n.t('profile__filter', translationFile)}</Button>
      </Col>
      <Col xs={24} sm={7}>
        <Select
          mode='multiple' 
          style={{ width: "95%" }} 
          placeholder={i18n.t('profile__select_users', translationFile)}
          onChange={(value) => changeFilters('actor', value)}
        >
          <OptGroup 
            label={i18n.t('profile__external_to_business', { ...translationFile, business: auth.business.name })}
          >
            {externalActors.map(({ value, label }) => 
              <Option key={value} value={value}>{label}</Option>
            )}
          </OptGroup>
          <OptGroup
            label={i18n.t('profile__from_business', { ...translationFile, business: auth.business.name })}
          >
            {collaborators.map(({ value, label }) =>
              <Option key={value} value={value}>{label}</Option>
            )}
          </OptGroup>
        </Select>
      </Col>
      <Col xs={24} sm={7}>
        <Select 
          onChange={(value) => changeFilters('type', value)}
          style={{ width: "95%" }} 
          mode='multiple'
          placeholder={i18n.t('profile__select_action_type', translationFile)}
        >
          {actionOptions.map(({ value, label }) => 
            <Option key={value} value={value}>
                {i18n.t(label, translationFile)}
            </Option>
            )
          }
        </Select>
      </Col>
      <Col xs={24} sm={7}>
        <DatePicker 
          style={{ width: "95%" }} 
          disabledDate={(current) => current && current > moment().endOf('day')}
          onChange={(date) => changeFilters('date_created', date && date.format('YYYY-MM-DD ZZ'))}
        />
      </Col>
    </Row>
  );
};

export default ActivityFilter;
