import {
  Card,
  Checkbox,
  DatePicker,
  Icon,
  Input,
  Radio,
  Select,
  Tooltip,
  Typography
} from 'antd';
import moment from 'moment';

import i18n from '../../i18n';
import { disabilityTypeOptions, genderOptions } from '../../constants/personalUser';
import { educationLevelOptions } from '../../constants/careers';

const { Option } = Select;
const { Paragraph, Text } = Typography;

export const careerAreaOptions = [
  // agropecuaria
  {
    groupName: i18n.t('form__career_options__1'),
    groupLabels: [
      {label: i18n.t('form__career_options__1__1'), value: 'Recursos Marinos'},
      {label: i18n.t('form__career_options__1__2'), value: 'Ciencias Agrarias'},
      {label: i18n.t('form__career_options__1__3'), value: 'Forestal'},
      {label: i18n.t('form__career_options__1__4'), value: 'Alimentos'},
      {label: i18n.t('form__career_options__1__5'), value: 'Medicina Veterinaria'},
    ],
  },
  // art
  {
    groupName: i18n.t('form__career_options__2'),
    groupLabels: [
      {label: i18n.t('form__career_options__2__1'), value: 'Arquitectura'},
      {label: i18n.t('form__career_options__2__2'), value: 'Diseño'},
      {label: i18n.t('form__career_options__2__3'), value: 'Música'},
      {label: i18n.t('form__career_options__2__4'), value: 'Artes'},
      {label: i18n.t('form__career_options__2__5'), value: 'Artes Escénicas'},
      {label: i18n.t('form__career_options__2__6'), value: 'Artes de comunicación'},
    ],
  },
  {
    // science
    groupName: i18n.t('form__career_options__3'),
    groupLabels: [
      {label: i18n.t('form__college'), value: 'Bachillerato / College'},
      {label: i18n.t('form__career_options__3__1'), value: 'Biología'},
      {label: i18n.t('form__career_options__3__2'), value: 'Biotecnología'},
      {label: i18n.t('form__career_options__3__3'), value: 'Bioquímica'},
      {label: i18n.t('form__career_options__3__4'), value: 'Química'},
      {label: i18n.t('form__career_options__3__5'), value: 'Medio Ambiente'},
      {label: i18n.t('form__career_options__3__6'), value: 'Geología'},
      {label: i18n.t('form__career_options__3__7'), value: 'Geografía'},
      {label: i18n.t('form__career_options__3__8'), value: 'Física'},
      {label: i18n.t('form__career_options__3__9'), value: 'Matemática'},
      {label: i18n.t('form__career_options__3__10'), value: 'Estadística'},
    ],
  },
  // social science
  {
    groupName: i18n.t('form__career_options__4'),
    groupLabels: [
      {label: i18n.t('form__career_options__4__1'), value: 'Derecho'},
      {label: i18n.t('form__career_options__4__2'), value: 'Ciencias Políticas'},
      {label: i18n.t('form__career_options__4__3'), value: 'Trabajo Social'},
      {label: i18n.t('form__career_options__4__4'), value: 'Sociología'},
      {label: i18n.t('form__career_options__4__5'), value: 'Psicología'},
      {label: i18n.t('form__career_options__4__6'), value: 'Periodismo'},
      {label: i18n.t('form__career_options__4__7'), value: 'Comunicaciones'},
      {label: i18n.t('form__career_options__4__8'), value: 'Orientación'},
    ],
  },
  // humanidades
  {
    groupName: i18n.t('form__career_options__5'),
    groupLabels: [
      {label: i18n.t('form__college'), value: 'Bachillerato / College'},
      {label: i18n.t('form__career_options__5__1'), value: 'Historia'},
      {label: i18n.t('form__career_options__5__2'), value: 'Letras'},
      {label: i18n.t('form__career_options__5__3'), value: 'Bibliotecnología'},
      {label: i18n.t('form__career_options__5__4'), value: 'Filosofía'},
      {label: i18n.t('form__career_options__5__5'), value: 'Estética'},
      {label: i18n.t('form__career_options__5__6'), value: 'Ciencias Religiosas'},
    ],
  },
  // education
  {
    groupName: i18n.t('form__career_options__6'),
    groupLabels: [
      {label: i18n.t('form__career_options__6__1'), value: 'Educación Parvularia'},
      {label: i18n.t('form__career_options__6__2'), value: 'Educación Básica'},
      {label: i18n.t('form__career_options__6__3'), value: 'Educación Media'},
      {label: i18n.t('form__career_options__6__4'), value: 'Educación Especial'},
    ],
  },
  //salud
  {
    groupName: i18n.t('form__career_options__7'),
    groupLabels: [
      {label: i18n.t('form__college'), value: 'Bachillerato / College'},
      {label: i18n.t('form__career_options__7__1'), value: 'Medicina'},
      {label: i18n.t('form__career_options__7__2'), value: 'Odontología'},
      {label: i18n.t('form__career_options__7__3'), value: 'Enfermería'},
      {label: i18n.t('form__career_options__7__4'), value: 'Obstetricia'},
      {label: i18n.t('form__career_options__7__5'), value: 'Nutrición y Dietética'},
      {label: i18n.t('form__career_options__7__6'), value: 'Tecnología Médica'},
      {label: i18n.t('form__career_options__7__7'), value: 'Kinesiología'},
      {label: i18n.t('form__career_options__7__8'), value: 'Terapia Ocupacional'},
      {label: i18n.t('form__career_options__7__9'), value: 'Fonoaudiología'},
      {label: i18n.t('form__career_options__7__10'), value: 'Química y Farmacia'},
    ],
  },
  //admin
  {
    groupName: i18n.t('form__career_options__8'),
    groupLabels: [
      {label: i18n.t('form__career_options__8__1'), value: 'Administración'},
      {label: i18n.t('form__career_options__8__2'), value: 'Contabilidad'},
      {label: i18n.t('form__career_options__8__3'), value: 'Comercio'},
      {label: i18n.t('form__career_options__8__4'), value: 'Economía'},
      {label: i18n.t('form__career_options__8__5'), value: 'Marketing'},
      {label: i18n.t('form__career_options__8__6'), value: 'Finanzas'},
      {label: i18n.t('form__career_options__8__7'), value: 'Turismo y Hotelería'},
      {label: i18n.t('form__career_options__8__8'), value: 'Secretariado'},
    ],
  },
  //ing
  {
    groupName: i18n.t('form__career_options__9'),
    groupLabels: [
      {label: i18n.t('form__career_options__9__1'), value: 'Ingeniería Civil'},
      {label: i18n.t('form__career_options__9__2'), value: 'Obras Civiles'},
      {label: i18n.t('form__career_options__9__3'), value: 'Industrial'},
      {label: i18n.t('form__career_options__9__4'), value: 'Eléctrica / Electrónica'},
      {label: i18n.t('form__career_options__9__5'), value: 'Computación e Informática'},
      {label: i18n.t('form__career_options__9__6'), value: 'Química y Metalurgia'},
      {label: i18n.t('form__career_options__9__7'), value: 'Transporte y Telecomunicación'},
      {label: i18n.t('form__career_options__9__8'), value: 'Minas'},
      {label: i18n.t('form__career_options__9__9'), value: 'Mecánica'},
      {label: i18n.t('form__career_options__9__10'), value: 'Sonido y Acústica'},
      {label: i18n.t('form__career_options__9__11'), value: 'Biotecnología'},
      {label: i18n.t('form__college'), value: 'Bachillerato / College'}
    ],
  },
];

export const careerStateOptions = [
  {
    label: i18n.t('form__status__1'),
    value: 'TIT',
  },
  {
    label: i18n.t('form__status__2'),
    value: 'EGR',
  },
  {
    label: i18n.t('form__status__3'),
    value: 'CUR',
  },
  {
    label: i18n.t('form__other'),
    value: 'OTHER',
  },
];

export const errorMessageHelper = {
  method: {
    POST: i18n.t('commons__creating'),
    PATCH: i18n.t('commons__updating'),
    DELETE: i18n.t('commons__deleting'),
  },
  entity: {
    careers: i18n.t('commons__one_of_the_careers'),
    certifications: i18n.t('commons__one_of_the_certifications'),
    // skills
    experiences: i18n.t('commons__one_of_the_experiences'),
    candidate: i18n.t('form__the_personal_info'),
    cv: i18n.t('form__the_cv'),
    // cover_letter: i18n.t('form__the_cover_letter'),
  },
};

export const personalFormLabels = {
  // Step 1
  personalInformation: {
    name: i18n.t('form__names'),
    surname: i18n.t('form__lastname'),
    birth_date: i18n.t('form__birth_date'),
    phone: i18n.t('form__phone'),
    nationality: i18n.t('form__nationality'),
    country: i18n.t('form__residence_country'),
    commune: i18n.t('form__commune'),
    marital_status: i18n.t('profile__marital_status'),
    gender: i18n.t('form__gender'),
    education_level: i18n.t('form__academic_level_modal'),
    disability: i18n.t('form__disability'),
    disability_type: i18n.t('form__disability_type'),
    // disability_visible: i18n.t('form__disability__visible'),
  },
  // Step 2
  careersInformation: {
    career_name: i18n.t('profile__career'),
    career_institution: i18n.t('profile__institution'),
    career_area: i18n.t('form__area'),
    career_state: i18n.t('commons__status'),
    career_entry_year: i18n.t('form__start_year'),
    career_last_year: i18n.t('form__end_year'),
    career_type: i18n.t('form__type_of_career'),
  },
  certificationsInformation: {
    certification_name: i18n.t('form__certification_name'),
    certification_institution: i18n.t('profile__institution'),
    certification_area: i18n.t('form__area'),
    certification_length: i18n.t('commons__duration'),
    certification_length_unit: i18n.t('commons__duration_unit'),
    certification_start_date: i18n.t('form__start__day'),
    certification_end_date: i18n.t('form__end__day'),
    certification_url: i18n.t('form__certification_link'),
  },
  // Step 3
  experiencesInformation: {
    experience_company: i18n.t('commons__company'),
    experience_position: i18n.t('commons__position'),
    experience_month_start: `${i18n.t('form__start__day')} - ${i18n.t('commons__month')}`,
    experience_year_start: `${i18n.t('form__start__day')} - ${i18n.t('commons__year')}`,
    experience_month_end: `${i18n.t('form__end__day')} - ${i18n.t('commons__month')}`,
    experience_year_end: `${i18n.t('form__end__day')} - ${i18n.t('commons__year')}`,
    experience_country: i18n.t('interview__address')
  },
  // Step 4
  filesInformation: {
    cv: i18n.t('form__cv'),
    // cover_letter: i18n.t('form__cover_letter'),
  },
}

export const monthsOptions = [
  i18n.t('form__january'),
  i18n.t('form__february'),
  i18n.t('form__march'),
  i18n.t('form__april'),
  i18n.t('form__may'),
  i18n.t('form__june'),
  i18n.t('form__july'),
  i18n.t('form__august'),
  i18n.t('form__september'),
  i18n.t('form__october'),
  i18n.t('form__november'),
  i18n.t('form__december'),
];

export const personalDataFields = {
  birth_date: {
    handleChange: (updateCandidate, newBirthDate) => updateCandidate('birth_date', newBirthDate ?? null),
    input: () => (
      <DatePicker
        disabledDate={(current) => current && current >= moment()}
        style={{ width: '100%' }}
      />
    ),
    label: personalFormLabels.personalInformation.birth_date,
    span: 12,
  },
  phone_prefix: {
    handleChange: (updateCandidate, option) => updateCandidate('phone_prefix', option.split('--')[1] || ''),
    input: (options) => (
      <Select
        allowClear
        showSearch
        filterOption={(inputValue, option) => option.props.label.includes(inputValue.toLowerCase())}
        initialValue=''
      >
        {options.map(({ country, phone_code, iso_2 }) => (
          // Add text metada to allow searching by country name
          <Option
            key={`${iso_2}--${phone_code}`}
            value={`${iso_2}--${phone_code}`}
            label={country.toLowerCase()}
          >
            <div>
              <img src={`https://genoma-assets.s3.us-east-2.amazonaws.com/flags/${iso_2}.svg`} /> {phone_code}
            </div>
          </Option>
          )
        )}
      </Select>
    ),
    label: personalFormLabels.personalInformation.phone,
    span: 4,
  },
  phone: {
    handleChange: (updateCandidate, event) => updateCandidate('phone', event.target.value ?? ''),
    input: () => (<Input />),
    label: ' ',
    span: 8,
    validators: [
      {
        errorMsg: i18n.t("commons__numbers_only"),
        validator: (rule, value, callback) => {
          const re = /^[0-9]+$/;
          if (value && !re.test(value)) {
            callback(true); // mal
          }
          callback(); // correcto
        },
      },
    ],
  },
  nationality: {
    handleChange: (updateCandidate, option) => updateCandidate('nationality', option?.toLowerCase()),
    input: (options) => (
      <Select
        allowClear
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input?.toLowerCase()) >= 0
        }
        showSearch
      >
        {options.map(({ country, translation }) => (
          <Option key={country} value={country}>
            {translation[i18n.language.split('-')[0]]}
          </Option>
        ))}
      </Select>
    ),
    label: personalFormLabels.personalInformation.nationality,
    span: 12,
  },
  country: {
    handleChange: (updateCandidate, option) => updateCandidate('country', option ? option.toLowerCase() : option),
    input: (options) => (
      <Select
        allowClear
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        showSearch
      >
        {options.map(({ country, translation }) => (
          <Option key={country} value={country}>
            {translation[i18n.language.split('-')[0]]}
          </Option>
        ))}
      </Select>
    ),
    label: personalFormLabels.personalInformation.country,
    span: 12,
  },
  commune: {
    handleChange: (updateCandidate, event) => updateCandidate('commune', event.target.value),
    input: () => (<Input />),
    label: personalFormLabels.personalInformation.commune,
    span: 12,
  },
  education_level: {
    handleChange: (updateCandidate, option) => updateCandidate('education_level', option || ''),
    input: () => (
      <Select allowClear initialValue={''}>
        {educationLevelOptions.map((level, index) => (
          <Option key={index} value={level.value}>{level.label}</Option>
        ))}
      </Select>
    ),
    label: personalFormLabels.personalInformation.education_level,
    span: 12,
  },
  // marital_status: {
  //   handleChange: (updateCandidate, option) => updateCandidate('marital_status', option),
  //   input: () => (
  //     <Select allowClear>
  //       <Option value='Casado'>{i18n.t('profile__married')}</Option>
  //       <Option value='Soltero'>{i18n.t('profile__single')}</Option>
  //       <Option value='Divorciado'>{i18n.t('profile__divorce')}</Option>
  //       <Option value='Viudo'>{i18n.t('form_widow')}</Option>
  //     </Select>
  //   ),
  //   label: personalFormLabels.personalInformation.marital_status,
  //   span: 12,
  // },
  gender: {
    clear: (updateCandidate, setFieldsValue) => (
      <Tooltip title={i18n.t('commons__clear_field')}>
        <Icon
          onClick={() => {
            updateCandidate('gender', 'E');
            setFieldsValue({ gender: 'E' });
          }}
          type="close-circle"
        />
      </Tooltip>
    ),
    handleChange: (updateCandidate, event) => updateCandidate('gender', event.target.value),
    input: () => (
      <Radio.Group>
        {Object.entries(genderOptions).filter((entry) => entry[0] !== 'E').map(([key, value]) => (
          <Radio key={key} value={key}>
            {key === 'W' ? i18n.t('profile__disability__would_rather_not_say') : value}
          </Radio>
        ))}
      </Radio.Group>
    ),
    label: personalFormLabels.personalInformation.gender,
    span: 24,
  },
  disability: {
    clear: (updateCandidate, setFieldsValue) => (
      <Tooltip title={i18n.t('commons__clear_field')}>
        <Icon
          onClick={() => {
            updateCandidate('disability', 'EMPTY');
            updateCandidate('disability_type', undefined);
            setFieldsValue({ disability: 'EMPTY', disability_type: undefined });
          }}
          type="close-circle"
        />
      </Tooltip>
    ),
    extra: (
      <Card
        bordered={false}
        className="info-card"
        style={{ backgroundColor: '#D9D9D9' }}
      >
        <Card.Meta
          description={(
            <Paragraph style={{ color: 'black', marginBottom: 0 }}>
              {i18n.t('form__disability_info_text').split('**')[0]} <Text style={{ color: '#F175A5' }}>{i18n.t('form__disability_info_text').split('**')[1]}</Text> {i18n.t('form__disability_info_text').split('**')[2]}
            </Paragraph>
          )}
        />
      </Card>
    ),
    handleChange: (updateCandidate, event, setFieldsValue) => {
      updateCandidate('disability', event.target.value);
      if (event.target.value !== "YES") {
        updateCandidate('disability_type', undefined);
        setFieldsValue({ disability_type: undefined });
      };
    },
    input: () => (
      <Radio.Group>
        <Radio value="NO">{i18n.t("profile__disability__no")}</Radio>
        <Radio value="YES">{i18n.t("profile__disability__yes")}</Radio>
        <Radio value="UNKNOWN">{i18n.t('profile__disability__would_rather_not_say')}</Radio>
      </Radio.Group>
    ),
    label: personalFormLabels.personalInformation.disability,
    span: 24,
  },
  disability_type: {
    condition: (candidate) => candidate.disability === 'YES',
    handleChange: (updateCandidate, event) => updateCandidate('disability_type', event.target.value),
    input: () => (
      <Radio.Group>
        {Object.entries(disabilityTypeOptions).map(([key, value]) => (
          <Radio key={key} value={key}>
            {key === 'UNKNOWN' ? i18n.t('profile__disability__would_rather_not_say') : value}
          </Radio>
        ))}
      </Radio.Group>
    ),
    label: personalFormLabels.personalInformation.disability_type,
    span: 24,
  },
  disability_visible: {
    handleChange: (updateCandidate, event) => updateCandidate('disability_visible', event.target.checked),
    input: () => (
      <Checkbox>
        {i18n.t('form__disability__availability_text')}
      </Checkbox>
    ),
    label: ' ',
    span: 24,
  }
}

export const stepTitles = [
  i18n.t('form__step_one'),
  i18n.t('form__academic_info'),
  i18n.t('form__experience'),
  i18n.t('form__additional_files'),
]

export const yearsOptions = [...Array(((new Date().getFullYear()) + 10) - 1920 + 1).keys()].map(x => x + 1920);
