import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Tag,
  Typography,
  Select,
  Form,
  Spin,
} from 'antd';
import '../CandidateList.scss';

/**
 * Drawer component with form input 
 * @param {props} {visible, onClose, loading} 
 */
const RankingDrawer = (props) => {

  const { Title, Text } = Typography;

  // Skills Selected
  const [skills, setSkills] = useState({
    excSkills: [],
    desSkills: []
  });

  const [searchSkillsOptions, setSearchSkillsOptions] = useState([]);

  useEffect(() => {
    if (props.filterState) {
      setSkills({
        excSkills: props.filterState.excSkills,
        desSkills: props.filterState.desSkills
      });
    }
  }, []);

  const onSearch = (input) => {
    if (input.length > 2) {
      const currentSearch = props.skillsOptions.filter(elem => elem.toLowerCase().includes(input.toLowerCase()))
      setSearchSkillsOptions(currentSearch)
    }
  };

  const handleClose = (removedTag, name) => {
    const tags = skills[name].filter(tag => tag !== removedTag);
    setSkills(prevState => {
      return { ...prevState, [name]: tags }
    });
  };

  const buildRequest = () => {
    // Search for similar skills by default
    const reqBody = {
      exc_skills: skills.excSkills,
      check_similar_exc: skills.excSkills,
      des_skills: skills.desSkills,
      check_similar_des: skills.desSkills
    };

    return reqBody
  };

  const handleSelectConfirm = (value, name) => {
    if (value && skills[name].indexOf(value) === -1) {
      var newState = [...skills[name], value];
      setSkills(prevState => {
        return { ...prevState, [name]: newState }
      });
      setSearchSkillsOptions([])
    }
  };

  const forMap = (tag, name, color) => {
    const tagElem = (
      <Tag
        closable
        color={color}
        onClose={e => {
          e.preventDefault();
          handleClose(tag, name);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span key={tag} style={{ display: "inline-block" }}>
        {tagElem}
      </span>
    );
  };

  const tagsExcSkills = skills.excSkills.map((tag) => forMap(tag, "excSkills", '#597EF7'));
  const tagsDesSkills = skills.desSkills.map((tag) => forMap(tag, "desSkills", '#FFA940'));

  return (
    <Modal
      title={
        <div>
          <Text strong style={{ fontSize: 16 }} >Filtros</Text>
        </div>
      }
      closable={true}
      visible={props.visible}
      onCancel={props.onClose}
      onOk={() => props.filterCv(buildRequest())}
      footer={[
        <Button key="back" onClick={props.onClose}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" onClick={() => props.filterCv(buildRequest())}>
          Filtrar
        </Button>
      ]}
      //width={400}
      className='filter_cv'
    >
      <div style={{ marginBottom: 8 }}>
        <Text strong style={{ fontSize: 18 }} >Filtra y selecciona</Text>
      </div>
      <div style={{ marginBottom: 20 }}>
        <Text style={{ fontSize: 14 }} >
          Para poder filtrar a tus candidatos por curriculum, primero debes seleccionar sus habilidades 🔎
        </Text>
      </div>

      <Spin spinning={props.loading} delay={500}>
        <Text strong style={{ fontSize: 14 }} >¿Cuales son las habilidades excluyentes para este cargo?</Text>
        <Row>
          <div className='tag_container'>
            {tagsExcSkills}
          </div>
        </Row>

        <Row style={{ marginBottom: 40 }} type="flex" align="middle" justify="center">
          <Col span={24}>
            <Select
              name={"excSkills"}
              value={undefined}
              onSelect={(value) => handleSelectConfirm(value, "excSkills")}
              placeholder="Buscar habilidades excluyentes"
              showSearch
              onSearch={onSearch}
              dropdownRender={menu => (
                searchSkillsOptions.length > 0 ? menu : <div />
              )}
            >
              {
                searchSkillsOptions.map((elem) => {
                  return <Select.Option key={elem} value={elem}>{elem}</Select.Option>
                })
              }
            </Select>
          </Col>
        </Row>

        <Text strong style={{ fontSize: 14 }} >¿Cuales son las habilidades deseables para este cargo?</Text>
        <Row>
          <div className='tag_container'>
            {tagsDesSkills}
          </div>
        </Row>

        <Row style={{ marginBottom: '20px' }} type="flex" align="middle" justify="center" className='select-row'>
          <Col span={24}>
            <Select
              name={"desSkills"}
              value={undefined}
              onSelect={(value) => handleSelectConfirm(value, "desSkills")}
              placeholder="Buscar habilidades deseables"
              showSearch
              onSearch={onSearch}
              dropdownRender={menu => (
                searchSkillsOptions.length > 0 ? menu : <div />
              )}
            >
              {
                searchSkillsOptions.map((elem) => {
                  return <Select.Option key={elem} value={elem}>{elem}</Select.Option>
                })
              }
            </Select>
          </Col>
        </Row>
      </Spin>
    </Modal >
  )
}

export default Form.create()(RankingDrawer)
