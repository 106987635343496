export const processAssignedAdditionalQuestions = (responseData) => {
    const questionsData = JSON.parse(responseData.configuration).questions;
    const questions = Object.keys(questionsData).map(key => {
      return (
        {
          id: key,
          type: questionsData[key].type,
          question: questionsData[key].question,
          mandatory: questionsData[key].mandatory,
          additionals: questionsData[key].additionals,
          alternatives: questionsData[key].alternatives,
          position: questionsData[key].position,
          number_from: questionsData[key].number_from ? questionsData[key].number_from : questionsData[key].from,
          number_to: questionsData[key].number_to ? questionsData[key].number_to : questionsData[key].to,
          configuration: questionsData[key].configuration,
        }
      )
    });
  
    const newData = {
      id: responseData.stage,
      stage: responseData.stage,
      questions: questions,
    };
  
    return newData;
  };