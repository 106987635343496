import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Typography, Icon, Tabs, message, Button, Tooltip } from 'antd';

import { CandidateContext } from '../config/CandidateContext';

import Card from './Card';
import Change from './Change';
import i18n from '../../../i18n'

const { Text, Title } = Typography;

const VideoInterviews = ({ handleVideosFlag }) => {
  const {
    application,
    candidate: { name, email },
    jobApp,
    token,
    videoActions,
    translationFile
  } = useContext(CandidateContext);

  const [videos, setVideos] = useState(null);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const getVideos = async () => {
      try {
        const videos = await videoActions.fetchVideo(
          token,
          jobApp.interview_id,
          email
        );

        if (videos.some((video) => video.answer.length !== 0)) {
          setVideos(videos);
          handleVideosFlag();
        }
      } catch (e) {
        if (application.video_interview_ready) {
          message.error(
            i18n.t('profile__ve_get_error', translationFile)
        );
        }
      }
    };
    if (jobApp.interview_id) {
      getVideos();
    }
  }, [email, jobApp]);

  const download = (url) => {
    const videoName = url.split('/').pop();
    //  download the video file by url
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // create a new url for the video file
        const url = window.URL.createObjectURL(blob);
        // create a link element
        const a = document.createElement('a');
        // set the href of the link to the url
        a.href = url;
        // set the download attribute of the link to the video name
        a.download = videoName;
        // append the link to the body
        document.body.appendChild(a);
        // set the content type of the link as the video extension
        a.type = blob.type;
        // click the link to start the download
        a.click();
        // remove the link from the body
        document.body.removeChild(a);
      });
  };

  const handleFullDownload = () => {
    //code to download all videos
    const filteredVideos = videos?.filter(video => video.answer[0] !== undefined);
    filteredVideos.forEach(video => {
      const { answer } = video;
      const tmpAnswer = answer[0];
      if (tmpAnswer?.video?.url) {
        download(tmpAnswer.video.url);
      }
    });
  };

  const printVideo = ({ answer, question }) => {
    const tmpAnswer = answer[0] ?? { video: { url: ' ' } };
    const withoutEval = i18n.t('profile__without_evaluation', translationFile).split(" ").map(function (word) {
      return word.charAt(0);
    }).join('')

    return (
      <div className="new-modal-videoint">
        <Text strong>{question.text}</Text>
        <video width="100%" src={tmpAnswer.video.url} controls />
        <div className="new-modal-videoint__rating-container">
          <Text>{i18n.t('profile__score', translationFile)}</Text>
          <div className="new-modal-videoint__rating-container__spaces">
            {new Array(6).fill(0).map((n, i) => (
              <span
                className={`${n + i && i <= tmpAnswer.rating ? 'orange-style' : ''
                  }`}
              >
                {n + i ? n + i : withoutEval.toUpperCase()}
              </span>
            ))}
          </div>
        </div>
        <Row type='flex' justify='space-between'>
          <Col span={12}>
            <Change
              buttons={[
                {
                  onClick: () => setIndex((i) => i - 1),
                  disabled: index === 0,
                },
                {
                  onClick: () => setIndex((i) => i + 1),
                  disabled: index + 1 === videos.length,
                },
              ]}
              value={index + 1}
              next={`${i18n.t('profile__of', translationFile)} ${videos?.length}`}
            />
          </Col>
          <Col span={4}>
            <Tooltip title={i18n.t('profile__download_ve', translationFile)}>
              <Button type="primary" className="new-modal-videoint__button" onClick={handleFullDownload}>
                <Icon type="download" />
              </Button>
            </Tooltip>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    videos && (
      <Card
        title={
          <Text strong className="text-16px">
            {i18n.t('candidate_path__interview')}
          </Text>
        }
      >
        {printVideo(videos[index])}
      </Card>
    )
  );
};

export default VideoInterviews;
