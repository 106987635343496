import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';
import * as authActionCreators from '../actions/auth';
import { bindActionCreators } from 'redux';

export default function requireAuthenticationInsideActiveProcess(Component) {
    class AuthenticatedComponent extends React.Component {
        static propTypes = {
            isAuthenticated: PropTypes.bool.isRequired,
            location: PropTypes.shape({
                pathname: PropTypes.string.isRequired
            }).isRequired,
            dispatch: PropTypes.func.isRequired
        };

        componentWillMount() {
            this.checkAuth();
        }

        checkAuth() {
            //const redirectAfterLogin = this.props.location.pathname;
            const jobappid = this.props.match.params['jobappid'];
            const storageToken = localStorage.getItem('token');
            const user = JSON.parse(localStorage.getItem('user'));
            let redirectAfterLogin = ''
            if (jobappid) {
                [ , ...redirectAfterLogin] = this.props.location.pathname.split(jobappid);
            } else {
                redirectAfterLogin = this.props.location.pathname;
            }
                this.props.authActions.authCheckAuthentication(storageToken);
            if (!this.props.isAuthenticated && storageToken!=null){
                this.props.authActions.authLoginUserSuccess(storageToken,user);
                this.props.authActions.authRedirect(redirectAfterLogin);
            }
            else if (!this.props.isAuthenticated && jobappid){
                this.props.authActions.authLogout(this.props.token,`/startprocess/${jobappid}?next=${redirectAfterLogin}`);
            } else if (!this.props.isAuthenticated){
                this.props.authActions.authLogout(this.props.token,`/login?next=${redirectAfterLogin}`);
            }
            else if (localStorage.getItem('token') == null) {
                this.props.authActions.authLogout(this.props.token,`/startprocess/${jobappid}`,{"generic": "Se ha cerrado la sesión remotamente, por favor inicie sesión nuevamente"});
                //this.props.dispatch(push(`/login?next=${redirectAfterLogin}`));
            }
                // } else {
            //     const homePath = `/jobapplication/${jobappid}/`
            //     this.props.authActions.authLoginUserSuccess(storageToken,JSON.parse(localStorage.getItem('user')), homePath);
            // }
            //else if(localStorage.getItem('token') == null){
            //    this.props.authActions.authLogout(this.props.token,`/login?next=${redirectAfterLogin}`);
            //}
        }

        render() {
            return (
                <div>
                    {this.props.isAuthenticated === true
                        ? <Component {...this.props} />
                        : null
                    }
                </div>
            );
        }
    }

    const mapStateToProps = (state) => {
        return {
            isAuthenticated: state.auth.isAuthenticated,
            token: state.auth.token
        };
    };

    const mapDispatchToProps = (dispatch) => {
        return {
            authActions: bindActionCreators(authActionCreators, dispatch)
        };
    };

    return connect(mapStateToProps,mapDispatchToProps)(AuthenticatedComponent);
}
