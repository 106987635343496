import React from 'react';
import { Row, Col, Typography, Avatar, Affix } from 'antd';
import propTypes from 'prop-types';

const backgroundPalette = [
  '#D3ADF7',
  '#91D5FF',
  '#87E8DE',
  '#FFD591',
  '#ADC6FF',
];
const colorPallete = ['#531DAB', '#096DD9', '#08979C', '#D46B08', '#1D39C4'];

const { Paragraph } = Typography;

const Profiles = ({ candidates, scrollRefs }) => {
  const names = candidates.map((item) => {
    if (item.name.indexOf(' ') !== -1) {
      if (item.surname.indexOf(' ') !== -1) {
        return {
          name: item.name.slice(0, item.name.indexOf(' ')),
          surname: item.surname.slice(0, item.surname.indexOf(' ')),
          email: item.email,
        };
      } else {
        return {
          name: item.name.slice(0, item.name.indexOf(' ')),
          surname: item.surname,
          email: item.email,
        };
      }
    } else {
      if (item.surname.indexOf(' ') !== -1) {
        return {
          name: item.name,
          surname: item.surname.slice(0, item.surname.indexOf(' ')),
          email: item.email,
        };
      } else {
        return { name: item.name, surname: item.surname, email: item.email };
      }
    }
  });

  return (
    <Affix offsetTop={55}>
      <div className='profiles-container' ref={scrollRefs}>
        <Row
          type='flex'
          align='middle'
          justify='center'
          className='profiles-container__profiles'
        >
          <Col span={2}>
            <Row
              type='flex'
              justify='start'
              className='profiles-container__title'
            >
              <Paragraph strong>Perfiles</Paragraph>
            </Row>
          </Col>
          <Col span={19}>
            <Row
              type='flex'
              justify='space-around'
              className='profiles-container__avatars'
            >
              {names.map((profile, index) => (
                <Col key={index}>
                  <Avatar
                    style={{
                      background: backgroundPalette[index],
                      color: colorPallete[index],
                    }}
                    size={92}
                  >
                    {(profile.name && profile.surname && profile.name !== '') ? (
                      `${profile.name[0].toUpperCase()}${profile.surname[0].toUpperCase()}`
                    ) : (
                      `${profile.email[0].toUpperCase()}${profile.email[1].toUpperCase()}`
                    )}
                  </Avatar>
                  <Paragraph strong>
                    {profile.name ? (
                      <>
                        {profile.name}
                        <br />
                        {profile.surname}
                      </>
                    ) : profile.email
                    }
                  </Paragraph>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </div>
    </Affix>
  );
};

Profiles.propTypes = {
  candidates: propTypes.array.isRequired,
};

export default Profiles;
