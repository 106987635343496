import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { push } from "react-router-redux";
import { bindActionCreators } from "redux";

import * as actionCreators from "../../../actions/data";
import i18n from "../../../i18n";

import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Icon,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Typography,
} from "antd";
import BulkActions from "../../References/Plugins/BulkActions";

import FilesTemplatesTable from "./FilesTemplatesTable";

const { Title, Paragraph } = Typography;
const { Search } = Input;

const assetsUrl = "https://genoma-assets.s3.us-east-2.amazonaws.com/";

function FilesTemplateDashboard(props) {
  const [searchInput, setSearchInput] = useState();
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [templatesData, setTemplatesData] = useState([]);

  useEffect(() => {
    const business = props.business;
    if (business) {
      const url = `/api/v1/gamesandtests/business/${business.id}/filesrequesttemplates/`;
      props.actions.fetchAndReturn(props.token, url)
        .then((response) => (
          setTemplatesData(response.body.results.map((template) => ({ ...template, key: template.id })))
        ));
    }
  }, [props.business]);

  function deleteTemplate(templateObj) {
    const { id: templateId, request, business} = templateObj;
    
    Modal.confirm({
      title: `Eliminar plantilla de solicitud de documentos "${request.name}"`,
      content: '¿Estás seguro/a de querer eliminar la plantilla? Una vez eliminada, no podrás recuperarla.',
      closable: true,
      cancelText: i18n.t('commons__cancel'),
      onOk: () => {
        const url = `/api/v1/gamesandtests/business/${business.id}/filesrequesttemplates/${templateId}/`;
        props.actions.fetchAndReturn(props.token, url, 'DELETE')
          .then(response => {
            if (response.status >= 400) {
              message.error('No fue posible eliminar la plantilla.', 5);
            } else {
              message.success('¡Plantilla eliminada con éxito!', 5);
            }
            setTemplatesData(templatesData.filter((t) => t.id != templateId));
          })
          .catch(() => message.error('No fue posible eliminar la plantilla.', 5));
      },
      onCancel: () => {}
    });
  }

  function bulkDeleteTemplates() {
    let loadingMsg;
    let loadingMsgConfig = {
      duration: 0,
      key: "loadingMsg-bulkDelete",
    };
    let resultMsgConfig = { duration: 5 };

    loadingMsgConfig.content= `Eliminando ${selectedRowsData.length} plantillas.`;

    Modal.confirm({
      title: `Eliminar ${selectedRowsData.length} plantillas`,
      content:
        "¿Estás seguro/a de querer eliminar las plantillas seleccionadas? Una vez eliminadas, no podrás recuperarlas.",
      closable: true,
      cancelText: i18n.t('commons__cancel'),
      onOk: () => {
        loadingMsg = message.loading(loadingMsgConfig);
        let url;
        for (let i = 0; i < selectedRowsData.length; i++) {
          const { id: templateId, status, name } = selectedRowsData[i];
          url = `/api/v1/gamesandtests/business/${props.business.id}/filesrequesttemplates/${templateId}/`;

          let totalTemplates = 0;

          props.actions
            .deleteAndReturn(props.token, url, "DELETE")
            .then((response) => {
              if (response.status >= 400) {
                message.error(
                  `No fue posible eliminar la plantilla "${name}".`,
                  5
                );
              } else {
                totalTemplates++;
                setTemplatesData((prevData) => {
                  if (status == "ACTIV") {
                    return {
                      ...prevData,
                      activ: prevData.activ.filter(
                        ({ id }) => id != templateId
                      ),
                    };
                  } else {
                    return {
                      ...prevData,
                      archi: prevData.archi.filter(
                        ({ id }) => id != templateId
                      ),
                    };
                  }
                });
              }
            })
            .catch(() => {
              message.destroy();
              message.error(
                `No fue posible eliminar la plantilla "${name}".`,
                5
              );
            });
        }
        message.destroy("loadingMsg-bulkDelete");
        resultMsgConfig[
          "content"
        ] = `${selectedRowsData.length} plantillas eliminadas con éxito.`;
        message.success(resultMsgConfig);
        setSelectedRowsData([]);
      },
      onCancel: () => {},
    });
  }

  const handleRowsSelected = (obj) => {
    const prevSelected = selectedRowsData;
    if (prevSelected.includes(obj))
      setSelectedRowsData(
        prevSelected.filter((element) => element.id !== obj.id)
      );
    else
      setSelectedRowsData([...prevSelected, obj]);
  };

  const handleAllRowsSelected = (allRowsSelected, selectedRows, changeRows) => {
    if (allRowsSelected)
      setSelectedRowsData(selectedRows);
    else
      setSelectedRowsData([]);
  };

  return (
    <Layout style={{ margin: 40 }}>
      <Row style={{ margin: "10px 0px 20px 0px" }}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to="/home">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/tools">{i18n.t('management__my_tools', {ns: 'businessUser'})}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/tools">{i18n.t('management__tools_title', {ns: 'businessUser'})}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{i18n.t('docr__title')}</Breadcrumb.Item>
        </Breadcrumb>
      </Row>

      <Row type="flex" style={{ color: "#2B2B2B", alignItems: "baseline" }}>
        <Col>
          <Icon
            type="arrow-left"
            style={{ display: "flex", marginRight: 10 }}
            onClick={() => props.dispatch(push("/tools"))}
          />
        </Col>
        <Col>
          <Title>{i18n.t('docr__title')}</Title>
        </Col>
      </Row>
      <Row>
        <Paragraph style={{ fontSize: "16px", fontWeight: 400 }}>
          Gestiona los documentos administrativos que necesites solicitar de los candidatos.
        </Paragraph>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        {templatesData.length > 0 ? (
          <div style={{ width: "100%" }}>
            <Row style={{ width: "100%", height: "100%", marginBottom: "1em" }}>
              <Col span={4}>
                <Search
                  placeholder="Buscar documento"
                  onSearch={(input) => setSearchInput(input)}
                  onChange={(event) => setSearchInput(event.target.value)}
                />
              </Col>
              <Col span={20} align="end">
                <Button
                  type="primary"
                  onClick={() =>
                    props.dispatch(push("/filestemplates/new"))
                  }
                >
                  Crear solicitud
                </Button>
              </Col>
            </Row>
            <FilesTemplatesTable
              handleRowsSelected={handleRowsSelected}
              handleAllRowsSelected={handleAllRowsSelected}
              selectedRowsData={selectedRowsData}
              templatesData={templatesData}
              searchInput={searchInput}
              deleteTemplate={deleteTemplate}
            />
          </div>
        ) : (
          <Card
            style={{
              border: "1.5px solid #D9D9D9",
              width: "130em",
              height: "50em",
            }}
            bodyStyle={{ padding: "0px", height: "100%" }}
          >
            <Row
              style={{
                background: `url("${assetsUrl}Group+1499custom_fields_background.svg") no-repeat center bottom`,
                backgroundSize: "contain",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                padding: "0px",
              }}
            >
              <Col
                span={8}
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  height: "40em",
                  justifyContent: "center",
                }}
              >
                <Row type="flex" justify="center" style={{ width: "30em" }}>
                  <img
                    style={{ width: "50%" }}
                    src={`${assetsUrl}genomines/4.png`}
                  />
                </Row>
                <Row type="flex" justify="center" style={{ width: "30em" }}>
                  <Title
                    level={2}
                    style={{ color: "#454545", textAlign: "center" }}
                  >
                    ¡Crea plantillas para solicitar documentos!
                  </Title>
                </Row>
                <Row type="flex" justify="center" style={{ width: "30em" }}>
                  <Paragraph style={{ color: "#5E5E5E", textAlign: "center" }}>
                    Hemos preparado para ti esta herramienta que te permitirá
                    crear las solicitudes de documentos de tus procesos de
                    selección.
                  </Paragraph>
                </Row>
                <Row type="flex" justify="center" style={{ width: "30em" }}>
                  <Button
                    type="primary"
                    onClick={() =>
                      props.dispatch(push("/filestemplates/new"))
                    }
                  >
                    Crear plantilla de documentos
                  </Button>
                </Row>
              </Col>
            </Row>
          </Card>
        )}
      </Row>

      <BulkActions
        bulkDeleteTemplates={bulkDeleteTemplates}
        handleAllRowsSelected={handleAllRowsSelected}
        selectedRows={selectedRowsData}
      />
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    business: state.auth.currentBusiness,
    businessPermissions: state.auth.businessPermissions,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilesTemplateDashboard);
