import React, { useState } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { Card } from 'antd'


const ReasoningBars = (props) => {

  const [candidateData, setCandidateData] = useState([])

  const data = [
    {
      "Razonamiento": 'Numérico',
      'Tu puntaje': props.numericalReasoning,
      'Tu puntajeColor': 'hsl(326, 100%, 83%)',
      'Puntaje promedio': props.numericalAverage,
      'Puntaje promedioColor': 'hsl(180, 74%, 72%)',
    },
    {
      "Razonamiento": 'Lógico',
      'Tu puntaje': props.logicReasoning,
      'Tu puntajeColor': 'hsl(326, 100%, 83%)',
      'Puntaje promedio': props.logicAverage,
      'Puntaje promedioColor': 'hsl(180, 74%, 72%)',
    },
    {
      "Razonamiento": 'Verbal',
      'Tu puntaje': props.verbalReasoning,
      'Tu puntajeColor': 'hsl(326, 100%, 83%)',
      'Puntaje promedio': props.verbalAverage,
      'Puntaje promedioColor': 'hsl(180, 74%, 72%)',
    },
  ]

  if (props.numericalReasoning) {
    candidateData.push({
      name: 'Numérico',
      score: props.numericalReasoning,
      avg: props.numericalAverage
    })
  }

  if (props.logicReasoning) {
    candidateData.push({
      name: 'Lógico',
      score: props.logicReasoning,
      avg: props.logicAverage
    })
  }

  if (props.verbalReasoning) {
    candidateData.push({
      name: 'Verbal',
      score: props.verbalReasoning,
      avg: props.verbalAverage
    })
  }

  return (
    <Card style={{ width: '100%', height: '600px' }}>
      <div style={{ height: 400 }}>
        <ResponsiveBar
          data={data}
          keys={[ 'Tu puntaje', 'Puntaje promedio' ]}
          indexBy="Razonamiento"
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          padding={0.3}
          innerPadding={1}
          maxValue={100}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={[ '#ffa7d9', '#81ecec' ]}
          groupMode={'grouped'}
          defs={[
            {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: '#38bcb2',
              size: 4,
              padding: 1,
              stagger: true
            },
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: '#eed312',
              rotation: -45,
              lineWidth: 6,
              spacing: 10
            }
          ]}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Razonamiento',
            legendPosition: 'middle',
            legendOffset: 32
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: -40
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
          theme={{ 'fontSize': 12, 'textColor': '#828282' }}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
        />
      </div>
    </Card>
  )
}

export default ReasoningBars
