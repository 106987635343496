import React from 'react';
import { Row, Col, Button, Typography, Radio, Affix } from 'antd';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
import '../AccountInfoStyles.scss';

const { Title, Text } = Typography;

function Header({
  buttonScreen,
  // TODO when feedback 2.0 is done
  handleScreenChange,
  personalData,
  onSubmit,
  formError,
}) {
  return (
    <>
      <Affix>
        <Row
          type='flex'
          align='middle'
          justify='center'
          style={{
            backgroundColor: '#FFFFFF',
            borderBottom: '1px solid #d9d9d9',
          }}
        >
          <Col xs={10} sm={10} md={8}>
            <Title level={3}>{i18n.t('navbar__my_profile')[0]+i18n.t('navbar__my_profile').slice(1).toLowerCase()}</Title>
          </Col>
          <Col xs={8} sm={8} md={5} className='save-changes'>
            <Row type='flex' justify='end'>
              <Button htmlType='submit' type={'primary'} disabled={formError}>
                {i18n.t('profile__save__changes')}
              </Button>
            </Row>
          </Col>
        </Row>
      </Affix>

      <Row
        type='flex'
        align='middle'
        justify='center'
        className='candidate-profile-header'
      >
        <Col span={20}>
          <Row type='flex' justify='center'>
            <div className='circle-candidate-avatar'>
              {personalData.name ?
                <Text className='text-candidate-avatar'>
                  {`${personalData.name[0]? personalData.name[0].toUpperCase(): ''} ${personalData.surname[0] ? personalData.surname[0].toUpperCase() : ''}`}
                </Text>
                :
                <img
                  style={{ heigth: '100%' }}
                  src='https://genoma-assets.s3.us-east-2.amazonaws.com/genomin-profile.svg'
                />
              }
            </div>

          </Row>
          {!personalData.name ? null :
            <Row type='flex' justify='center'>
              <Title level={3} style={{ margin: '0.5em 0 1em 0' }}>
                {`${personalData.name} ${personalData.surname}`}
              </Title>
            </Row>
          }
        </Col>

        <Col span={20}>
          <Row type='flex' justify='space-around'>
          {
            /*
            <Col xs={24} sm={24} md={16} style={{ margin: '20px 0' }}>
              
                // TODO cuando este el feedback V2
                <Radio.Group
                  value={buttonScreen}
                  onChange={handleScreenChange}
                  className='button-group'
                >
                  <Radio.Button value='1'>Mis datos</Radio.Button>
                  <Radio.Button value='2'>Mis resultados</Radio.Button>
                </Radio.Group>
            </Col>
            */
          }
            {buttonScreen === '1' ? (
              <>
                <Col xs={24} sm={24} md={16}>
                  <div className='privacy-div'>
                    <Text>
                      📝 {i18n.t('profile__text')}
                    </Text>
                  </div>
                </Col>
              </>
            ) : (
              <Row gutter={[8, 32]} type='flex' justify='center'>
                <Col xs={24} sm={24} md={16}>
                  <Title level={3}>{i18n.t('feedback__genomawork__report')}</Title>
                  <Text>
                    {i18n.t('header__feedback__explanation')}
                  </Text>
                </Col>
                <Col xs={24} sm={24} md={16}>
                  <div className='privacy-div'>
                    <Text>
                      📝 {i18n.t('header__test__duration')}
                    </Text>
                  </div>
                </Col>
              </Row>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
}

Header.propTypes = {
  personalData: PropTypes.shape({
    name: PropTypes.string,
    surname: PropTypes.string
  }), 
}

export default Header;
