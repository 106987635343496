import React, { useState } from 'react';
import { Checkbox, Radio, Input, Button } from 'antd';

export const ColumnFilterKO = ({ questionType, ko, value, setFilter, koKey }) => {
  const [localFilter, setLocalFilter] = useState(value);

  const save = () => {
    setFilter((filters) => ({ ...filters, [koKey]: localFilter }));
  };

  const renderQuestionComponent = () => {
    switch (questionType) {
      case 'multiselection':
        return (
          <Checkbox.Group
            onChange={(values) => setLocalFilter(values.length ? values : null)}
            value={localFilter}
          >
            {ko.alternatives.map((alternative, index) => (
              <Checkbox value={index}>{alternative.text}</Checkbox>
            ))}
          </Checkbox.Group>
        );
      case 'singleselection':
        return (
          <Radio.Group
            options={ko.alternatives.map((alternative, index) => ({
              label: alternative.text,
              value: index,
            }))}
            onChange={(e) => setLocalFilter(e.target.value)}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
            }}
            value={localFilter}
          />
        );
      case 'int':
        return (
          <div className="column-filter-container__numbers">
            <Input
              type="number"
              placeholder="Mínimo"
              onChange={(e) => {
                const value = Number(e.target.value);
                setLocalFilter((values) => [value, values?.[1]]);
              }}
              value={localFilter?.[0]}
            />
            <Input
              type="number"
              placeholder="Máximo"
              onChange={(e) => {
                const value = Number(e.target.value);
                setLocalFilter((values) => [values?.[0], value]);
              }}
              value={localFilter?.[1]}
            />
          </div>
        );
      case 'multiline':
      case 'singleline':
        return (
          <Input
            type="text"
            placeholder="Buscar respuesta"
            onChange={(e) => setLocalFilter(e.target.value)}
            value={localFilter}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="column-filter-container">
      {renderQuestionComponent()}
      <div className="column-filter-container__buttons">
        <Button
          style={{ width: '100%' }}
          onClick={() => {
            setFilter((filters) => ({ ...filters, [koKey]: null }));
            setLocalFilter(null);
          }}
        >
          Reiniciar
        </Button>
        <Button type="primary" onClick={save}>Guardar</Button>
      </div>
    </div>
  );
};


export const ColumnFilterVInt = ({ setFilter }) => {
  const [localFilter, setLocalFilter] = useState(null);

  const save = () => {
    setFilter((filters) => ({ ...filters, video_interview_ready: localFilter }));
  };

  const renderFilterComponent = () => {
    return (
      <Radio.Group
        options={[{ label: 'Completada', value: true }, { label: 'Pendiente', value: false }].map((alternative, index) => ({
          label: alternative.label,
          value: alternative.value,
        }))}
        onChange={(e) => setLocalFilter(e.target.value)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
        }}
        value={localFilter}
      />
    );
  }

  return (
    <div className="column-filter-container">
      {renderFilterComponent()}
      <div className="column-filter-container__buttons">
        <Button
          style={{ width: '100%' }}
          onClick={() => {
            setFilter((filters) => ({ ...filters, video_interview_ready: null }));
            setLocalFilter(null);
          }}
        >
          Reiniciar
        </Button>
        <Button type="primary" onClick={save}>Guardar</Button>

      </div>
    </div>
  );
};

export const ColumnFilterActivity = ({ activityCode, activitiesId, queryFilters, setQueryFilters }) => {
  const [localFilter, setLocalFilter] = useState({});
  const [radioState, setRadioState] = useState(null);

  const save = () => {
    
    if (activityCode === 'GENOME') {
      setQueryFilters((queryFilters) => ({
        ...queryFilters,
        genome_completed: localFilter.genome_completed,
        genome_incompleted: localFilter.genome_incompleted
      }));
    }
    else {
      setQueryFilters((queryFilters) => ({
        ...queryFilters,
        activity_completed: localFilter.activity_completed,
        activity_incompleted: localFilter.activity_incompleted
      }));
    }
  };

  const setActivityFilter = (value) => {
    setRadioState(value)
    let completedActivities = [];
    let incompletedActivities = [];

    const completedKey = activityCode === 'GENOME' ? 'genome_completed' : 'activity_completed';
    const incompletedKey = activityCode === 'GENOME' ? 'genome_incompleted' : 'activity_incompleted';

    completedActivities = queryFilters[completedKey] ?? [];
    incompletedActivities = queryFilters[incompletedKey] ?? [];

    if (value) {
      completedActivities = [...new Set([...completedActivities, ...activitiesId])];
      // Remove from activity_incompleted if neccesary
      incompletedActivities = incompletedActivities.filter(elem => !activitiesId.includes(elem));
    }
    else {
      incompletedActivities = [...new Set([...incompletedActivities, ...activitiesId])];
      // Remove from activity_completed if neccesary
      completedActivities = completedActivities.filter(elem => !activitiesId.includes(elem));
    }

    setLocalFilter({ [completedKey]: [...completedActivities], [incompletedKey]: [...incompletedActivities] });
  };

  const renderFilterComponent = () => {
    return (
      <Radio.Group
        options={[{ label: 'Completada', value: true }, { label: 'Pendiente', value: false }].map((alternative, index) => ({
          label: alternative.label,
          value: alternative.value,
        }))}
        onChange={(e) => setActivityFilter(e.target.value)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
        }}
        value={radioState}
      />
    );
  }

  const resetCurrentFilter = () => {
    if (activityCode === 'GENOME') {
      setQueryFilters((queryFilters) => (
          {
            ...queryFilters,
            genome_incompleted: queryFilters.genome_incompleted ? queryFilters.genome_incompleted.filter(elem => !activitiesId.includes(elem)) : [],
            genome_completed: queryFilters.genome_completed ? queryFilters.genome_completed.filter(elem => !activitiesId.includes(elem)) : [],
          }
        ));
      setRadioState(null);
    }
    else {
      setQueryFilters((queryFilters) => (
        {
          ...queryFilters,
          activity_incompleted: queryFilters.activity_incompleted ? queryFilters.activity_incompleted.filter(elem => !activitiesId.includes(elem)) : [],
          activity_completed: queryFilters.activity_completed ? queryFilters.activity_completed.filter(elem => !activitiesId.includes(elem)) : [],
        }
      ));
    };
    setRadioState(null);
  }

  return (
    <div className="column-filter-container">
      {renderFilterComponent()}
      <div className="column-filter-container__buttons">
        <Button
          style={{ width: '100%' }}
          onClick={resetCurrentFilter}
        >
          Reiniciar
        </Button>
        <Button type="primary" onClick={save}>Guardar</Button>

      </div>
    </div>
  );
};
