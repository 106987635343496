import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import UploadImage from './uploadImage';

const BackgroundBusiness = props => {
  const [uploadedImg, setUploadedImg] = useState(null);
  useEffect(() => {
    if (props.imgProcess) {
      setUploadedImg(props.imgProcess);
    }
  }, [props.imgProcess])

  const getImg = (img, info) => {
    setUploadedImg(img);
    props.background(info);
  };

  const exampleImg = (
    <div className="uploaded-bg">
      {uploadedImg && <img src={uploadedImg} />}
    </div>
  );

  return (
    <Row className='gw-as__bu-bg' type="flex">
      <UploadImage
        type='bg'
        title='Imagen para procesos'
        description={
          <span>
            Imagen de portada que ven los postulantes al ingresar al proceso. Archivos soportados JPG y PNG, con un peso máximo de 1MB. Tamaño mínimo: 1200x500 píxeles. Proporción 16:9.
          </span>
        }

        getImage={getImg}
      />

      <Col md={12} className="gw-as__bu-bg__preview">
        <Row>
          <Col span={12}>
            <div style={{ width: '250px', height: '180px', border: '1px solid #D9D9D9', margin: '0 0 10px 0' }}>
              <div style={{ height: '10%', width: '100%', border: '1px solid #D9D9D9' }}>
                <div style={{ background: 'white', width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
                  <div style={{ height: '10px', width: '10px', backgroundColor: '#D9D9D9', marginLeft: 2 }} />
                </div>
              </div>

              <div style={{ height: '90%', width: '100%', display: 'flex' }}>
                <div style={{ background: '#D9D9D9', width: '100%', height: '100%' }} />
              </div>
            </div>
            <sup>Previsualización - Computadora</sup>
          </Col>
          <Col span={12}>
            <div style={{ width: '100px', height: '180px', border: '1px solid #D9D9D9', margin: '0 0 10px 0' }}>
              <div style={{ height: '10%', width: '100%', border: '1px solid #D9D9D9' }}>
                <div style={{ background: 'white', width: '100%', height: '100%' }}>
                  <div style={{ background: 'white', width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
                    <div style={{ height: '10px', width: '10px', backgroundColor: '#D9D9D9', marginLeft: 2 }} />
                  </div>
                </div>
              </div>
              <div style={{ height: '50%', width: '100%', display: 'flex' }}>
                <div style={{ background: 'white', width: '100%', height: '100%' }} />
              </div>

              <div style={{ height: '40%', width: '100%', display: 'flex' }}>
                <div style={{ background: '#D9D9D9', width: '100%', height: '100%' }} />
              </div>
            </div>
            <sup>Previsualización - Celular</sup>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BackgroundBusiness;

