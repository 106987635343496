import { Button, Divider, Switch, Typography } from 'antd';
import React, { useContext, useMemo } from 'react';

import { CandidateContext } from '../config/CandidateContext';
import CulturalReport from './CulturalFitReport';
import GameBadge from './GameBadge';
import LeadershipTrait from './LeadershipComponents/LeadershipTrait';
import Trait from './Trait';
import i18n from '../../../i18n';
import useBoolean from '../../../hooks/useBoolean';

const { Text } = Typography;

const SummaryEvaluations = () => {
  const {
    application: { activities },
    getPersonalityTraits,
    getCognitiveTraits,
    getConductualTraits,
    getCulturalTraits,
    getLeadershipTraits,
    translationFile,
    changeKey,
    jobApp
  } = useContext(CandidateContext);

  const [withDetails, handleDetails] = useBoolean(true);
  const [withDistribution, handleDistribution] = useBoolean(true);

  const gamesActivities = activities.filter(
    (a) => a.category !== 'OTHER' && a.category !== 'INITIAL'
  );

  const refs = useMemo(() => {
    const titles = [
      'commons__cognitive',
      'commons__personality',
      'commons__behavioral',
    ];

    return [
      getCognitiveTraits,
      getPersonalityTraits,
      () => [...getConductualTraits(), ...getCulturalTraits(), ...getLeadershipTraits()],
    ].reduce((acc, fn, i) => {
      acc[i18n.t(titles[i], translationFile)] = {
        ref: React.createRef(null),
        traits: fn(),
      };
      return acc;
    }, {});
  }, [jobApp.measuredTraits]);

  return (
    <div className="new-modal-summary-evals">
      <div className="new-modal-summary-evals__header">
        <Text className="text-16px" strong style={{ flexGrow: 1 }}>
          {i18n.t('commons__traits', translationFile)}
        </Text>
        <div>
          {gamesActivities.map((act, idx) => (
            <GameBadge key={`gamebadge-${idx}`} game={act} />
          ))}
        </div>
      </div>
      <div className="new-modal-summary-evals__refs">
        {Object.entries(refs).map(([title, { ref }], idx) => (
          <Button
            id={`eval-refs-${idx}`}
            onClick={() =>
              ref.current.scrollIntoView({
                behavior: 'smooth',
              })
            }
          >
            {i18n.t(title, translationFile)}
          </Button>
        ))}
      </div>
      <div className="new-modal-summary-evals__switchs">
        <Switch
          checked={withDistribution}
          onChange={handleDistribution.toggle}
          checkedChildren={
            <Text style={{ color: '#FFFFFF' }}>
              {i18n.t('profile__with_distribution', translationFile)}
            </Text>
          }
          unCheckedChildren={
            <Text>{i18n.t('profile__no_distribution', translationFile)}</Text>
          }
        />
        <Switch
          checked={withDetails}
          onChange={handleDetails.toggle}
          checkedChildren={
            <Text>{i18n.t('profile__with_details', translationFile)}</Text>
          }
          unCheckedChildren={
            <Text>{i18n.t('profile__no_details', translationFile)}</Text>
          }
        />
      </div>
      {Object.entries(refs).map(([title, category]) =>
        category.traits.length ? (
          <div className="new-modal-summary-evals__category" ref={category.ref}>
            <div className="new-modal-summary-evals__category__title">
              <Divider />
              <Text>{title}</Text>
              <Divider />
            </div>
            {category.traits.length ? (
              category.traits.map((trait, idx) => {
                if (trait.type === 'cultural') {
                  return (
                    <CulturalReport
                      id={`cultural-${idx}`}
                      traits={trait.traits}
                    />
                  );
                } else if (trait.type === 'leadership') {
                  return (
                    <LeadershipTrait
                      inSummary={true}
                      data={trait.traits?.map((item) => {
                        return {
                          code: item.trait_id.code,
                          score: item.score,
                        };
                      })}
                    />
                  );
                }
                return (
                  <Trait
                    id={`behavioral-${idx}`}
                    trait={trait}
                    withDetails={withDetails}
                    withDistribution={withDistribution}
                  />
                );
              })
            ) : (
              <Text>{i18n.t('profile__does_not_have', translationFile)}</Text>
            )}
          </div>
        ) : null
      )}
    </div>
  );
};

export default SummaryEvaluations;
