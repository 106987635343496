import React, { Fragment } from 'react';
import gamesDescription from './gameDescriptions';
import AddOrRemove from '../../../../AddOrRemove';
import { 
  Col, 
  Card, 
  Tooltip, 
  Tag, 
  Typography, 
  Button } from 'antd';

const { Text } = Typography;

const SingleGame = ({ activity, openModal, item, index }) => (
  <Col key={index} xs={12} sm={6}>
    <Card
      cover={
        <img
          src={`https://genoma-assets.s3.us-east-2.amazonaws.com/${activity.url}.png`}
          alt='bag'
        />
      }
    >
      <Card.Meta
        title={
          <Fragment>
            <Text strong>{activity.activity.replace('Mobile', '')}</Text> <br />
            <Tag>{activity.time_estimated} minutos</Tag>
          </Fragment>
        }
        description={
          <Fragment>
             <Tooltip placement="top" title="Ver detalle">
              <Button
                className='btn-detail'
                icon='zoom-in'
                onClick={() => openModal(gamesDescription[activity.code], { ...activity, index: index, allGames: item })} />
            </Tooltip>
            <AddOrRemove activity={activity} /> <br />
          </Fragment>
        }
      />
    </Card>
  </Col>
)

export default SingleGame;