import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../../../../actions/jobapp';
import * as actionsDataCreators from '../../../../../../actions/data';

import { AppContext } from '../../../NewProcessForm';
import { typeToSpanishText } from '../../constants';

import Templates from './components/Templates';
import AddedQuestions from './components/questions/AddedQuestions';
import OptionalFields from './components/optionalFields/OptionalFields';
import AddNewQuestion from './components/addNew/AddNewQuestion';

import {
  Row,
  Col,
  Button,
  Form,
  message
} from 'antd';
import './ApplicationForm.scss';

class ApplicationForm extends Component {

  constructor() {
    super();
    this.state = {
      edit: false,
      title: 'Nueva pregunta',
      showQuestionForm: false,
      loadingTemplates: false,
      questions: [],
      initialOptions: [0, 1],
      newQuestionData: {
        type: '',
        question: '',
        // mandatory: 'required',
        mandatory: true,
        additionals: {},
        alternatives: [
          { text: '', correct: false },
          { text: '', correct: false },
          { text: '', correct: false },
          { text: '', correct: false },
        ],
        from: 0,
        to: 0,
      },
      questionToDelete: { id: null, text: '' },
      table: {
        headers: [
          { name: 'question', display: 'Pregunta' },
          { name: 'type', display: 'Tipo' }
        ],
        rows: [
          {
            question: { name: '¿Cuáles son tus pretensiones de renta?', display: 'Pretensiones de sueldo' },
            configuration: {},
            type: { name: 'int', display: 'Numérico' },
            id: '',
            alternatives: [],
            from: 0,
            to: 0,
          }
        ],
        actions: { check: true }
      },
    }
  }

  componentDidMount() {
    this.loadQuestionTemplates();
  }
  addAnswer = async () => {
    this.setState({ showQuestionForm: true, title: 'Nueva pregunta' }, () => {
      const el = document.getElementById('question-form');

      window.scrollTo({
        top: el.offsetTop,
        behavior: 'smooth'
      })
    });
  }

  addQuestionFromTemplate = async (selectedTemplate) => {
    await this.addAnswer();
    const { form } = this.props;
    let { question, type, from , to, alternatives } = selectedTemplate;
    let additionals = null;
    if (selectedTemplate.configuration){
      additionals = selectedTemplate.configuration;
    }
    else{
      additionals = selectedTemplate.additionals;
    }

    this.setState({ title: 'Editar pregunta' });
    
    form.setFieldsValue({
      type: type.name,
      mandatory: true,
    });

    if (type.name !== 'file') {
      form.setFieldsValue({
        question: question.name,
      });
    } else {
      form.setFieldsValue({
        fileName: question.name,
        fileDescription: additionals ? additionals.instructions : null,
        fileType: additionals ? additionals.accepted_extensions : ['PDF'],
      });
    }

    if (type.name === 'int') {
      form.setFieldsValue({
        intFrom: from,
        intTo: to
      });
    }

    if (type.name === 'singleselection' || type.name === 'multiselection') {
      const totalAlternatives = alternatives.length;

      this.setState({ initialOptions: [...Array(totalAlternatives).keys()] }, () => {
        form.setFieldsValue({
          keys: [...Array(totalAlternatives).keys()]
        }, () => {
          for (const x in this.state.initialOptions) {
            form.setFieldsValue({
              [`alternative[${x}]`]: alternatives[x].text,
              [`correct[${x}]`]: alternatives[x].correct
            });
          }
        })
      });
    }
  }

  handleCloseForm = () => {
    this.setState({ showQuestionForm: false, title: 'Nueva pregunta' })
  }

  editQuestion = async (question, index) => {
    this.props.actions.startEditQuestion(index);
    this.setState({ edit: true});
    this.addQuestionFromTemplate(question);
  }

  formatAlternatives = (alternatives, correct) => {
    const altKeys = [...Array(alternatives.length).keys()];
    let formattedAlt = [];

    for (const x in altKeys) {
      if (alternatives[x] !== undefined && correct[x] !== undefined) {
        formattedAlt.push({ text: alternatives[x], correct: correct[x] })
      }
    }

    return formattedAlt;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const type = values.type;
        const alt = ['singleselection', 'multiselection'].includes(type) ?
          this.formatAlternatives(values.alternative, values.correct) : '';
        const additionals = ['file'].includes(type) ?
          { instructions: values.fileDescription, accepted_extensions: values.fileType } : {};

        let newQuestionData = { ...this.state.newQuestionData };

        newQuestionData.type = type;
        newQuestionData.question = values.type === 'file' ? values.fileName : values.question;
        newQuestionData.alternatives = alt;
        newQuestionData.additionals = additionals;
        newQuestionData.from = values.intFrom ? values.intFrom : 0;
        newQuestionData.to = values.intTo ? values.intTo : 0;
        newQuestionData.mandatory = true;
        if (e.type === 'submit') { // guardar pregunta
          this.setState({ newQuestionData: newQuestionData }, () => {
            this.saveQuestion(this.state.newQuestionData);
          });

        } else { // guardar plantilla
          this.saveAsTemplate(newQuestionData);
        }

        this.handleCloseForm();
      }
    });
  }

  saveQuestion = (newQuestion) => {
    const { koActivity, koAssigned, jobAppId } = this.props;
    const { edit } = this.state;
    if (edit) {
      this.props.actions.updateQuestionKo(newQuestion);
    } else if (!koAssigned) {
      const configuration = {
        questions: {
          0: {
            ...newQuestion,
            position : 0
          }
        }
      };

      const data = {
        job_application_id: jobAppId,
        activity_id: koActivity.id,
        height: 1,
        order: 1,
        stage: 1,
        configuration: configuration,
        time_estimated: koActivity.time_estimated,
        icon: koActivity.icon
      };

      this.props.actions.addAssignedActivity(koActivity.code, data);

    } else {
      this.props.actions.addQuestionKo(newQuestion);
    }

    this.setState({ edit: false, title: 'Nueva pregunta' })
  }

  saveAsTemplate = (newTemplate) => {
    const url = `gamesandtests/business/${this.props.business.id}/questiontemplates/`;
    const token = this.props.token;

    let data = {
      question: newTemplate.question,
      question_type: newTemplate.type,
      alternatives: newTemplate.alternatives,
      number_from: newTemplate.from,
      number_to: newTemplate.to,
      configuration: {
        alternatives: newTemplate.alternatives,
        additionals: newTemplate.additionals
      }
    };

    this.props.actionsData.sendData(token, url, JSON.stringify(data), 'POST', '').then(() => {
      message.success("Pregunta guardada correctamente");
      data.id = this.props.data.id

      let alternatives = newTemplate.alternatives;

      this.setState((prevState) => ({
        table: {
          ...prevState.table,
          rows: [
            ...prevState.table.rows,
            {
              id: data.id,
              question: { name: data.question, display: data.question },
              type: { name: data.question_type, display: typeToSpanishText[data.question_type] },
              alternatives,
              from: data.number_from,
              to: data.number_to,
              configuration: {
                alternatives: data.alternatives,
                additionals: data.additionals
              }
            }
          ]
        }
      }));

      data.alternatives = newTemplate.alternatives
    })
  }

  loadQuestionTemplates = () => {
    this.setState({ loadingTemplates: true });
    const url = `/api/v1/gamesandtests/business/${this.props.business.id}/questiontemplates/`;
    const token = this.props.token;
    this.props.actionsData.fetchAndReturn(token, url, 'GET').then((dataTable) => {
      // TECHDEBT: usar return del fetchData
      let table = { ...this.state.table }
      dataTable.body.results.forEach(data => {
        let alternatives;
        if (data.alternatives && (!data.configuration || data.configuration.alternatives.length == 0)){
          let altArray = data.alternatives.split(",");
          let correctArray = data.correct.split(",");
          alternatives = altArray.map((alt, i) => { return { text: alt, correct: correctArray[i] === 'true' ? true : false } })
        }
        else{
          alternatives = (typeof data.configuration.alternatives === 'object') ?
            data.configuration.alternatives :
            data.configuration.alternatives.split(',');
        }
        table.rows.push({
          id: data.id,
          question: { name: data.question, display: data.question },
          type: { name: data.question_type, display: typeToSpanishText[data.question_type] },
          configuration: data.configuration,
          alternatives,
          from: data.number_from,
          to: data.number_to
        })
      });
      this.setState({ table, loadingTemplates: false });
    })
  }

  deleteTemplate = (id) => {
    let questionToDelete = { ...this.state.questionToDelete };
    questionToDelete.id = id;
    let rowQuestion = this.state.table.rows.find(row => row.id === id);
    questionToDelete.text = rowQuestion.question.display;

    this.setState({ questionToDelete }, () => {
      this.deleteQuestionTemplate();
    });
  }

  deleteQuestionTemplate = () => {
    const url = `/api/v1/gamesandtests/business/${this.props.business.id}/questiontemplates/${this.state.questionToDelete.id}/`;
    const token = this.props.token;
    this.props.actionsData.fetchAndReturn(token, url, 'DELETE').then(() => {
      this.setState((prevState) => ({
        table: {
          ...prevState.table,
          rows: this.state.table.rows.filter((row) => row.id !== this.state.questionToDelete.id)
        }
      }), () => {
        message.success('Plantilla eliminada')
      });
    });
  }
  
  render() {
    const {
      changeOptionalFields,
      optionalFields,
      jobApplication,
      changeAdditionalQuestions
    } = this.props;
    const { showQuestionForm, table, loadingTemplates } = this.state;
    return (
      <AppContext.Consumer>
        {({ active }) => (
          <Row className='af'>
            <OptionalFields
              onChange={changeOptionalFields}
              values={optionalFields}
              jobAppStatus={jobApplication.status}
            />

            <AddedQuestions
              edit={this.editQuestion}
              active={active}
              jobAppStatus={jobApplication.status}
              onChange={changeAdditionalQuestions}
            /> 
  
            {(showQuestionForm) ? (
              <AddNewQuestion
              title={this.state.title}
                form={this.props.form}
                submit={this.handleSubmit}
                permission={this.props.businessPermissions}
                closeForm={this.handleCloseForm}
              />
            )
              :
              (!active) ? (
                <Col sm={24}>
                  <Button
                    type='link'
                    icon='plus-circle'
                    onClick={this.addAnswer}
                  >
                    Agregar pregunta
                  </Button>
                </Col>
              ) : null
            }

            {!active &&
              <Templates
                templates={table.rows}
                deleteTemplate={this.deleteTemplate}
                questionToDelete={this.state.questionToDelete}
                addQuestion={this.addQuestionFromTemplate}
                permission={this.props.businessPermissions}
                loading={loadingTemplates}
              />
            }
          </Row>
        )}
      </AppContext.Consumer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    koAssigned: state.jobapp.assignedActivities['KO'],
    koActivity: state.jobapp.activities.results.find(a => a.code === 'KO'),
    businessPermissions: state.auth.businessPermissions,
    jobApplication: state.jobapp.jobApplication,
    data: state.data.data,
    jobAppId: state.jobapp.jobAppId,
    knockOutActivity: state.jobapp.activities.results.find((act) => { return act.code === 'KO'; }),
    business: state.auth.currentBusiness
    
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: bindActionCreators(actionCreators, dispatch),
    actionsData: bindActionCreators(actionsDataCreators, dispatch),
  };
};

const ApplicationFormView = Form.create()(ApplicationForm);
export default connect(mapStateToProps, mapDispatchToProps)(ApplicationFormView);