import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import * as actionCreators from '../../actions/data';

import './impersonate-styles.scss';
import homeBackground from '../Home/images/genoma_fondo2.png'
import {
  Form,
  Button,
  Card,
  Typography,
  Row,
  Col,
  Spin
} from 'antd';

const NextGame = (props) => {

  const [gameResponseState, setGameResponseState] = useState(["ALST", "ALSP"])
  const [loading, setLoading] = useState(true)

  // Check if user has previous game responses
  useEffect(() => {
    setLoading(true)
    let games = ["ALST", "ALSP"]
    const url = `gamesandtests/gameresponselist/${props.user}/?format=json`;
    props.actions.fetchData(props.token, url, '', 'get').then((data) => {
      // Check if the user has previous game responses
      let filteredGames = data.filter(obj => games.includes(obj.activity_code))
      setGameResponseState(filteredGames.map((obj) => obj.activity_code))
    }).then(() => setLoading(false));
  }, [])

  const handleClick = (gameCode) => {
    let absoluteUrl = `/jobapplication/${props.match.params.jobappid}/${gameCode}`
    props.dispatch(push(absoluteUrl));
  }

  return (
    <div style={{ backgroundImage: `url(${homeBackground})`, width: "100vw", height: "calc(100vh - 64px)", backgroundSize: "cover" }}>
      <div className="impersonate">
        {
          loading ?
            <div className="impersonate">
              <Row type="flex" justify="center" align="middle" style={{ height: "85vh", }}>
                <Col>
                  <Card style={{ width: 400, textAlign: "center" }}>
                    <Spin />
                  </Card>
                </Col>
              </Row>
            </div>
            :
            <Row type="flex" justify="center" align="middle" style={{ height: "85vh", }}>
              <Col>
                <Card style={{ width: 400 }}
                  title={
                    'Prueba Alster'
                  }>
                  <Form>
                    {
                      gameResponseState.includes('ALST') && gameResponseState.includes('ALSP') ?
                        <Row type="flex" justify="center" align="middle" style={{ marginBottom: 10, alignItems: "center" }}>
                          <Typography.Text>
                            Detectamos que ya completaste ambos tests 😉
                          </Typography.Text>
                        </Row>
                        :
                        <>
                          <Row type="flex" justify="center" align="middle" style={{ marginBottom: 10, alignItems: "center" }}>
                            <Typography.Text>
                              A continuación tendrás la oportunidad de responder una evaluacion personalizada para este proceso.
                        </Typography.Text>
                          </Row>
                          <Row type="flex" justify="center" align="middle" style={{ marginBottom: 10 }}>
                            <Typography.Text strong>
                              Recuerda que solo tendrás una oportunidad para responder!
                        </Typography.Text>
                          </Row>

                          <Form.Item>
                            <Button
                              type="primary"
                              htmlType="button"
                              block
                              size="large"
                              disabled={gameResponseState.includes('ALST')}
                              onClick={() => handleClick('ALST')}
                            >
                              Prueba Chile
                        </Button>
                          </Form.Item>
                          <Form.Item>
                            <Button
                              type="primary"
                              htmlType="button"
                              block
                              size="large"
                              disabled={gameResponseState.includes('ALSP')}
                              onClick={() => handleClick('ALSP')}
                            >
                              Prueba Perú
                        </Button>
                          </Form.Item>
                        </>
                    }
                  </Form>

                </Card>
              </Col>
            </Row>
        }
      </div>
    </div >
  )
}



const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isAuthenticating: state.auth.isAuthenticating,
    authErrors: state.auth.errors,
    token: state.auth.token,
    user: state.auth.userID
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: bindActionCreators(actionCreators, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NextGame);