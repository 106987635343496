import React from 'react';

import CoverPage from '../../pdfCommons/CoverPage';
import Summary from './components/Summary';
import Detail from './components/Detail';
import FooterPage from '../../pdfCommons/FooterPage';

import './alternatives-pdf.scss';

const AlternativesPdf = ({ data, refPrint, candidateInfo, jobName, downloadSummary, hideGenomaLogo }) => {
  const categoriesCount = Object.values(data.answer.questions).reduce((obj, v) => { obj[v.category] = (obj[v.category] || 0) + 1; return obj; }, {});
  const alternativesSkillObject = () => {
    const tmpQuestions = data.answer.questions;
    const rawResult = data.answer.scores.raw_result;
    const correctAnswer = data.answer.scores_config.correct_answers;
    let objToReturn = {};
    data.answer.sorted_questions.forEach(key => {
      if (!tmpQuestions[key]) {
        return;
      }
      if (!objToReturn[tmpQuestions[key].category])
        objToReturn[tmpQuestions[key].category] = { correct: [], wrong: [] };

      if (rawResult[key]?.correct) {
        objToReturn[tmpQuestions[key].category].correct.push({
          id: key,
          ...correctAnswer[key],
        });
      } else {
        objToReturn[tmpQuestions[key].category].wrong.push({ id: key, ...correctAnswer[key] });
      }
    });
    return objToReturn;
  };

  const categoriesFiltered = alternativesSkillObject();
  return (
    <div ref={refPrint} className='alternatives-pdf-modal'>
      <section className='alternatives-pdf-modal__coverpage print'>
        <CoverPage name={data.answer.custom_test?.title} hideGenomaLogo={hideGenomaLogo}/>
      </section>
      <section className='alternatives-pdf-modal__mainsection print'>
        <Summary
          candidateInfo={candidateInfo}
          jobName={jobName}
          scores={data.answer.scores}
          scoresConfig={data.answer.scores_config}
          description={data.answer.description}
          questions={data.answer.questions}
          categoriesCount={categoriesCount}
          testName={data.answer.custom_test?.title}
          categoriesFiltered={categoriesFiltered}
          dateCreated={data.answer.date_created}
          hideGenomaLogo={hideGenomaLogo}
        />
      </section>
      <FooterPage />

      {
        !downloadSummary &&
        <section className='alternatives-pdf-modal__mainsection print'>
          <Detail
            answers={data.answer.answers}
            questions={data.answer.questions}
            sortedQuestions={data.answer.sorted_questions}
            scores={data.answer.scores}
            testName={data.answer.custom_test?.title}
            scoresConfig={data.answer.scores_config}
          />
        </section>
      }

    </div>
  );
}

export default AlternativesPdf;
