import React, { Component, createContext, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { 
  ContactInfo, 
  Description, 
  Requisites, 
  Location,
  Error,
  Done,
  Questions } from './sections';
import { 
  Typography, 
  Modal, 
  Steps } from 'antd';
import * as Sentry from '@sentry/browser';

import * as actionCreators from '../../../../../../../../../actions/data';
export const AppContext = createContext();

const { Title } = Typography;
const { Step } = Steps;
const lastStep = 4;

class LaborumJobForm extends Component {
  state = {
    currentStep: 0,
    loading: false,
    completedSteps: [],
    data: {
      "client": null,
      "token": null,
      "country": "CL",
      "confidential": 0,
      "domainId": null,
      "companyId": null,
      "jobId": 0,
      "description": {
          "jobAreaId": null,
          "jobDescription": "",
          "availabilityId": null,
          "workDayId": null,
          "jobValidity": null,
          "vacancies": 1,
          "contractTime": "1 mes",
          "jobTypeId": null,
          "salary": 100000,
          "showSalary": 0,
          "companyActivityId": null,
          "paymentMethod": 1,
          "jobTitle": "",
          "jobLink": null
      },
      "requisites": {
          "minimumRequirements": "Requisitos",
          "computerSkillId": null,
          "applicantProfile": null,
          "educationalLevelId": null,
          "academicSituationId": null
      },
      "location": {
          "regionId": 1,
          "countryId": null,
          "city": {
            "id": 0,
          },
          "workplace": null,
      },
      "contactInfo": {
          "companyName": "",
          "email": "",
          "contact": "",
          "contactPhone": ""
      },
      "questions": { 
      }
    }
  }

  countryToTrabajandoCountry = (country) => {
    switch (country) {
      case 'CL':
        return 1;
      case 'CO':
        return 4;
      case 'PE':
        return 3;
      case 'AR':
        return 2;
      case 'BO':
        return 100;
      case 'BR':
        return 103;
      case 'EC':
        return 126;
      case 'PA':
        return 239;
      case 'UY':
        return 294;
      case 'VN':
        return 6;

    }
  }

  componentDidMount() {
    const { jobApp, shareProcessUrl, jobPortalIntegrations } = this.props;
    const { client, token, companyId, domainId } = jobPortalIntegrations['trabajando']
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        client,
        token,
        companyId,
        domainId,
        contactInfo: {
          ...prevState.data.contactInfo,
          companyName: jobApp.business_name,
        },
        location: {
          ...prevState.data.location,
          countryId: this.countryToTrabajandoCountry(jobApp.location_country)
        },
        description: {
          ...prevState.data.description,
          jobTitle: jobApp.job_application,
          jobDescription: jobApp.description ? jobApp.description.replace(/(<([^>]+)>)/ig, '') : '',
          paymentMethod: jobApp.location_country === 'CL' ? 1 : 2, // pesos chilenos : dólares
          jobLink: shareProcessUrl + '?utm_source=trabajando-gw',
          companyName: jobApp.business_name,
        }
      }
    }));
    if (jobApp.job_portals.trabajando) {
      this.setState({ data: jobApp.job_portals.trabajando });
    }
  }

  onChangeShowSalary = (e) => {
    let data = {...this.state.data}
    let description = {...data.description}
    description.showSalary = e.target.checked ? 1:0
    data.description = description
    this.setState({ data });
  }

  onChange = async currentStep => {
    this.setState({ currentStep });
  };

  prevStep = () => {
    this.setState({ currentStep: this.state.currentStep - 1 })
  }

  currentContent = () => {
    const { currentStep } = this.state;
    const { location_country } = this.props.jobApp;

    switch (currentStep) {
      case 0:
        return <ContactInfo />
      case 1:
        return <Requisites />
      case 2: 
        return <Description onChangeShowSalary={this.onChangeShowSalary} isChile={location_country === 'CL'} />
      case 3:
        return <Questions savedQuestions={this.state.data.questions}/>
      case 4: 
        return <Location />
      case 5:
        return <Done />
      case -1:
        return <Error />
    }
  }

  updateJobPortals = () => {
      let job_portals = this.props.jobApp.job_portals;
      job_portals['trabajando'] = this.state.data;
      this.props.handleChange('job_portals')(job_portals);
      const url = `gamesandtests/jobapplications/${this.props.jobApp.id}/`;
      const data = { 'job_portals': job_portals };
      this.props.actions.updateData(this.props.token, url, JSON.stringify(data), 'PATCH', 'Actualizado portales de trabajo');
  }

  saveData = (e, form, section) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        const { currentStep } = this.state;
        this.setState({ loading: true });

        if (values.confidential !== undefined) {
          this.setState((prevState) => ({
            data: {
              ...prevState.data,
              confidential: values.confidential ? 1 : 0
            }
          }))
        }
        this.setState((prevState) => {
          delete values.confidential;
          return ({
            data: {
              ...prevState.data,
              [section]: {
                ...prevState.data[section],
                ...values
              }
          }
        })})
      }
    })
  };

  submitData = (e, form, section) => {
    e.preventDefault();
    let errorSending = false;
    const dataToSend = {...this.state.data}
    form.validateFields(async (err, values) => {
      if (!err) {
        const { currentStep } = this.state;
        this.setState({ loading: true });

        if (values.confidential !== undefined) {
          dataToSend.confidential = values.confidential ? 1 : 0;
        }
        dataToSend[section] = {
          ...dataToSend[section],
          ...values,
        }
        const newData = JSON.parse(JSON.stringify(dataToSend));
        this.setState({ data: newData });
        if (currentStep === lastStep) {
          dataToSend.requisites.minimumRequirements = dataToSend.requisites.minimumRequirements.replaceAll('\n', '<br>');
          dataToSend.description.jobDescription = dataToSend.description.jobDescription.replaceAll('\n', '<br>');
          dataToSend.description.companyName = dataToSend.description.companyName || dataToSend.contactInfo.companyName;
          delete dataToSend.contactInfo.confidential;
          let resp = await fetch(`${this.props.jobPortalIntegrations['trabajando'].url}/jobs-v1.4/rest/json/corporate`, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataToSend),
          }).catch(e => {
            console.error(e);
            console.error(this.state.data);
            console.error(dataToSend);
            Sentry.captureException('Trabajando server error');
            errorSending = true;
          });
          resp = await resp.json();
          
          if (resp.status === 'OK') {
            this.updateJobPortals();
            const shouldRedirect = false;
            this.props.publish('', shouldRedirect);
          } else {
            console.error(resp);
            console.error(this.state.data);
            Sentry.captureException('Trabajando integration');
            errorSending = true
          }
        }

        if (errorSending) {
          this.setState({ currentStep: -1 })
        } else {
          this.onChange(currentStep + 1);
        }

        this.setState((prevState) => ({ 
          loading: false, 
          completedSteps: [...prevState.completedSteps, currentStep] 
        }));
      }
    });
  }

  render() {
    const published = this.props.jobApp.job_portals ? this.props.jobApp.job_portals['trabajando'] : false
    const { currentStep, data, loading, completedSteps } = this.state;
    const isLastStep = currentStep <= lastStep;
    const lastStepCompleted = Math.max(...completedSteps);
    return (
      <Modal 
        visible={this.props.visible}
        onCancel={this.props.closeForm} 
        className={`pj__job-form ${!isLastStep ? 'finish' : ''}`}
        footer={false}
        centered={true}
        title={
          !isLastStep ? ( 
            false
          ) : (
            <Fragment>
              <div className='pj__job-form__headline'>
                <Title level={4}>{published ? 'Volver a ' : ''}Publicar empleo en Trabajando.com</Title>
              </div>

              <Steps
                type="navigation"
                size="small"
                current={currentStep} 
                onChange={this.onChange}
              >
                {['Contacto', 'Requerimientos', 'Descripción', 'Preguntas adicionales', 'Lugar'].map((title, index) => (
                  <Step 
                    key={index}
                    title={title} 
                    disabled={lastStepCompleted < index} 
                    status={completedSteps.includes(index) ? 'finish' : currentStep === index ? 'process' : 'wait'}
                  />
                ))}
              </Steps>
            </Fragment>
          )
        }
      >
        <AppContext.Provider
          value={{
            currentStep: currentStep,
            back: this.prevStep,
            data: data,
            submit: this.submitData,
            loading: loading,
            save: this.saveData,
          }}
        >
          {/* {published ? <Done />: this.currentContent()} */}
          {this.currentContent()}
        </AppContext.Provider>
      </Modal>
    )
  }
};


const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    jobPortalIntegrations: state.auth.jobPortalIntegrations
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LaborumJobForm);