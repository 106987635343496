import React from "react";

const ImageField = ({ src }) => {
  return (
    <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', marginTop: 40, marginBottom: 40 }}>
      <img alt={'img-header'} style={{ maxWidth: '100%', height: 'auto' }} src={src} />
    </div>
  );
};

export default ImageField;