import React from 'react';

import { Button, Typography } from 'antd';

const { Text } = Typography;

const Change = ({ buttons, before, value, next }) => {
  return (
    <div className="change-buttons">
      <Button icon="left" {...buttons[0]} />
      {before ? <Text>{before}</Text> : null}
      <span className="change-buttons__value">{value}</span>
      <Text>{next}</Text>
      <Button icon="right" {...buttons[1]} />
    </div>
  );
};

export default Change;
