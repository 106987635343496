import React, { useState } from 'react';
import {
  Button,
  Col,
  Row,
} from "antd";

import { Buffer } from 'buffer';

export default function LeverModalContent({ leverIntegrationTemplate, getIntegration }) {
  const envVariables = {
    client_id: leverIntegrationTemplate.integration_fields.client_id,
    client_secret: leverIntegrationTemplate.integration_fields.client_secret,
    redirect_callback: leverIntegrationTemplate.integration_fields.redirect_callback,
    scope: leverIntegrationTemplate.integration_fields.scope,
    state: leverIntegrationTemplate.integration_fields.state,
    lever_base_url: leverIntegrationTemplate.integration_fields.lever_base_url,
    lever_auth_url: leverIntegrationTemplate.integration_fields.lever_auth_url,
    auth_token_url: leverIntegrationTemplate.integration_fields.auth_token_url,
  }

  const buildRedirectUri = () => {
    const baseUri = envVariables.redirect_callback;
    const redirectParams = ['integration_id='+leverIntegrationTemplate.id,'client_id='+envVariables.client_id , 'client_secret='+envVariables.client_secret, 'auth_token_url='+envVariables.auth_token_url, 'redirect_callback='+envVariables.redirect_callback ];
    const redirect = Buffer.from(redirectParams.join('&')).toString('base64')
    return baseUri + '?redirect_params=' + redirect;
  };  

  const handleIntegrate = () => {
    const authURI = `${envVariables.lever_auth_url}/?client_id=${envVariables.client_id}&redirect_uri=${buildRedirectUri()}&state=${envVariables.state}&response_type=code&scope=${envVariables.scope}&prompt=consent&audience=${envVariables.lever_base_url}`;
    const encodedAuthURI = encodeURI(authURI);
    window.location.replace(encodedAuthURI);
  }
  return (
    <Row>
      <Col sm={24}>
        Al presionar "Autorizar", se le redirigirá a Lever, donde deberá
        iniciar sesión y autorizar a Genomawork para poder completar la integración.
        Una vez integrado correctamente.
      </Col>
      <Col>
        <Button style={{marginTop:20}} type={'primary'} onClick={handleIntegrate} disabled={getIntegration()?.status==='ACTIV'}>Autorizar</Button>
      </Col>
    </Row>
  )
}
