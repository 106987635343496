import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actionCreators from '../../../../actions/data'

import {
    Modal,
    Typography,
    Button,
    Form,
    Select,
    Badge,
    message,
} from 'antd'
import '../ProcessList.scss'

const ProcessState = (props) => {

    const { Title, Text } = Typography
    const { Option } = Select;

    const [loading, setLoading] = useState(false)
    const [processState, setprocessState] = useState(props.status)
    const [jobApplication, setJobApplication] = useState({
        content: '',
        nextState: null,
        processStatus: '',
    })

    const onChangeSelect = (value) => {
        setJobApplication({
            processStatus: value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const { token } = props;
        const url = `/api/v1/gamesandtests/jobapplications/${props.process.id}/`
        const status = jobApplication.processStatus
        const submitData = { status };

        props.actions.fetchAndReturn(token, url, 'PATCH', submitData)
            .then((resp) => {
                if (resp.status > 400){
                    message.error('Error al actualizar el proceso')
                }
                else {
                    props.updateProcesses(status)
                    setJobApplication({ ...jobApplication, processStatus: status })
                    message.success('El proceso ha sido actualizado')
                }
            });
        props.closeModal()
    }

    return (
        <Modal
            centered={true}
            visible={props.visible}
            className='gw-share-with-collaborators-modal'
            footer={[
                <Button
                    type='default'
                    onClick={props.closeModal}
                    disabled={loading}
                    key={"cancel"}
                >
                    Cancelar
                </Button>,
                <Button
                    type='primary'
                    onClick={handleSubmit}
                    loading={loading}
                    key="ok"
                >
                    Aplicar
                </Button>
            ]}
        >
            <div className='content'>
                <div className='headline'>
                    <Title level={1}>Estado del Proceso</Title>
                    <Text type='secondary'>{props.name}</Text>
                </div>

                <div className='modal-body'>
                    <label>Estado</label>
                    <Select
                        defaultValue={processState}
                        className='status'
                        onChange={onChangeSelect}
                    >
                        <Option value="ACTIV"><Badge color='#87d068' text='Abierto' className='stat' /></Option>
                        <Option value="INACT"><Badge color='#ff4543' text='Cerrado' className='stat' /></Option>
                        <Option value="OTHER"><Badge color='#848484' text='Borrador' className='stat' /></Option>
                    </Select>
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        isSaving: state.jobapp.isSaving,
        jobApplication: state.jobapp.jobApplication,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actionCreators, dispatch),
        dispatch
    }
}

const JobState2 = Form.create()(ProcessState)
export default connect(mapStateToProps, mapDispatchToProps)(JobState2)