import React from 'react';
import {
  Layout,
  Row,
  PageHeader,
  Typography
} from 'antd';

import i18n from '../../../../i18n';

const { Text } = Typography;

const InvalidAccount = ({ goBack }) => {
  return (
    <Layout className='main-layout-cards__cols__component'>
      <Row>
        <PageHeader style={{  padding: '16px 0' }}
          onBack={() => goBack()}
          title='Cuenta no válida'
        />
      </Row>
      <Row>
        <Text style={{ fontSize: '16px', lineHeight: '26px', color: '#786D73' }}>
          {i18n.t('start_process__business__account__error')}
        </Text>
      </Row>
    </Layout>
  );
}

export default InvalidAccount;