import React from "react";
import { Row, Col } from 'antd';
import AppSummary from "./appsummary/AppSummary";
import LevelsCompleted from "./levelscompleted/LevelsCompleted";
import CandidateInstructions  from './instructions/CandidateInstructions';

const CandidatePathPerform = () => {
	return (
		<Row>
			<Col xs={24}>
				<LevelsCompleted />
			</Col>
			<Col xs={24}>
				<AppSummary />
			</Col>
			<Col xs={24}>
				<CandidateInstructions />
			</Col>
		</Row>
	);
};

export default CandidatePathPerform;
