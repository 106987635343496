import React, { useState } from "react";
import { Button, Col, Form, Icon, Input, Typography, Row } from "antd";

const NewToken = ({ createTokenFn, loading }) => {
  const [name, setName] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    createTokenFn({ name: name });
  };
  return (
    <Row gutter={[0, 24]} style={{ marginTop: "1em" }}>
      <Row className="new-token-info">
        <Icon
          type="info-circle"
          theme="filled"
          style={{ marginLeft: "1em", color: "#1A8FFF", fontSize: 15 }}
        />
        <Typography.Text
          align="middle"
          style={{ marginLeft: "1em", color: "#2B2B2B", fontSize: 12 }}
        >
          Esta clave expirará 1 año después de su creación.
        </Typography.Text>
      </Row>
      <Form>
        <Form.Item style={{ marginTop: "1em" }}>
          Nombre
          <Input
            type="text"
            name="tokenName"
            placeholder="Clave 1"
            onChange={({ target }) => {
              setName(target.value);
            }}
          ></Input>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            onClick={handleSubmit}
            loading={loading}
            size="default"
            style={{ width: "100%" }}
          >
            Guardar clave
          </Button>
        </Form.Item>
      </Form>
    </Row>
  );
};

export default NewToken;
