import React from 'react';

import { Col, Progress, Row, Typography } from 'antd';
import PropTypes from 'prop-types';

import { stepTitles } from './constants';
import i18n from '../../i18n';

const { Text } = Typography;

function ProgressBar(props) {
  const { currentStep, optionalFields, totalSteps } = props;

  const s = 0 + (optionalFields.careers === 2) + (optionalFields.experiences === 2);

  return (
    <>
      <Row className='form-card-row' gutter={8} type="flex">
        <Col
          className={`progress-segment-left${totalSteps === 1 ? ' progress-segment-right' : ''}`}
          span={24 / totalSteps}
        >
          <Progress
            percent={100}
            showInfo={false}
            strokeColor={currentStep === 0 ? "#FFADD2" : "#F175A5"}
            strokeWidth={20}
            type="line"
          />
        </Col>
        {totalSteps > 1
          ? (
            <Col
              className={`progress-segment-${totalSteps > 2 ? 'middle' : 'right'}`}
              span={24 / totalSteps}
            >
              <Progress
                percent={currentStep + s >= 1 ? 100 : 0}
                showInfo={false}
                strokeColor={currentStep === 1 ? "#FFADD2" : "#F175A5"}
                strokeWidth={20}
                type="line"
              />
            </Col>
          ) : null}
        {totalSteps > 2 ? (
          <Col
            className={`progress-segment-${totalSteps > 3 ? 'middle' : 'right'}`}
            span={24 / totalSteps}
          >
            <Progress
              percent={currentStep + s >= 2 ? 100 : 0}
              showInfo={false}
              strokeColor={currentStep === 2 ? "#FFADD2" : "#F175A5"}
              strokeWidth={20}
              type="line"
            />
          </Col>
        ) : null}
        {totalSteps > 3 ? (
          <Col className='progress-segment-right' span={24 / totalSteps}>
            <Progress
              percent={currentStep + s <= 2 ? 0 : 100}
              strokeColor="#FFADD2"
              showInfo={false}
              strokeWidth={20}
              type="line"
            />
          </Col>
        ) : null}
      </Row>
      <Row className='form-card-row'>
        <Text style={{ marginTop: '0.5em' }}>
          {i18n.t('form__step')} {currentStep + 1}: {stepTitles[currentStep]}
        </Text>
      </Row>
    </>
  );
}

ProgressBar.propTypes = {
  currentStep: PropTypes.number.isRequired,
  optionalFields: PropTypes.object.isRequired,
  totalSteps: PropTypes.number.isRequired,
};

export default ProgressBar;
