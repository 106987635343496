import React, { useContext } from 'react';
import {
  Col,
  Row,
  Typography,
  Button,
} from 'antd'

import TemplateCard from './components/TemplateCard';

import { AnalyticsContext } from '../context/AnalyticsContext';
import { processNivoData, parseDates, fillTimeline, formatUTMSrcName, keysToUpperCase } from '../assets/utils';
import i18n from '../../../../i18n';

const { Text } = Typography

const Candidates = () => {
  const { sectionsData: { candidates }, jobAppId } = useContext(AnalyticsContext)

  const {
    ages,
    applications_total,
    average_time_per_status,
    candidates_per_process,
    candidates_total,
    candidate_timeline,
    completion_days_avg,
    completion_rate_avg,
    creation_date,
    days_to_first_application_avg,
    diabilities,
    discard_time_avg,
    disability_rate,
    education_levels,
    first_hired_date,
    first_application_date,
    fit_distribution,
    genders,
    nationalities,
    nps,
    utm_sources,
    visitor_to_candidate_rate,
  } = candidates;

  const candidateTimeline = fillTimeline(candidate_timeline, 10);
  const parsedDates = parseDates(
    {
      creation_date,
      first_application_date,
      first_hired_date,
    }
  )

  const nationality_higher = nationalities.reduce((acc, v ) => {
    if (acc[1] < v.count && v.nationality !== '') {
      return [v.nationality, v.count];
    }
    return acc;
  }, ['', 0])[0];

  const nationality_higher_code = nationality_higher ? {
    'chile': 'CL',
    'perú': 'PE',
    'peru': 'PE',
    'colombia': 'CO',
    'argentina': 'AR',
    'venezuela': 'VE',
    'bolivia': 'BO',
    'ecuador': 'EC',
    'uruguay': 'UY',
    'paraguay': 'PY',
    'brasil': 'BR',
    'méxico': 'MX',
    'guatemala': 'GT',
    'honduras': 'HN',
    'el Salvador': 'SV',
    'nicaragua': 'NI',
    'costa rica': 'CR',
    'panamá': 'PA',
    'puerto rico': 'PR',
    'república dominicana': 'DO',
    'españa': 'ES',
    'estados unidos': 'US',
    'canadá': 'CA',
  }[nationality_higher.toLowerCase()] : '';

  const nationality_avg = { code: nationality_higher_code, label: nationality_higher }

  const getBestSource = () => {
    const src = utm_sources.filter(x => x.source)[0]?.source
    return src ? src.charAt(0).toUpperCase() + src.slice(1).split('_').join(' ') : null;
  }

  return (
    <Col xs={24} className="new-analytics__content">
      <Row style={{ margin: '0 0 15px 0' }}>
        <Text>Vision general</Text>
      </Row>

      <Row type='flex' gutter={[20, 20]}>
        <Col xs={24} lg={12}>
          <TemplateCard
            templateText
            data={applications_total}
            height={392}
            // capitalize 1st letter
            title={i18n.t('navbar__my_process').charAt(0).toUpperCase() + i18n.t('navbar__my_process').slice(1)}
            text={jobAppId ? i18n.t('analytics__total_candidates_text_process') : i18n.t('analytics__total_candidates_text_general')}
          />
        </Col>
        <Col xs={24} lg={12}>
          <TemplateCard
            templateGraphic
            height={250}
            graphicType='timeline'
            title='Línea del tiempo de Candidatos'
            data={processNivoData(candidateTimeline, ['month', 'year'], '-')}
          />
        </Col>
      </Row>

      {/* {
        jobAppId && (
          <Row>
            <TemplateCard
              templateGraphic
              graphicType='funnel'
              title='Candidatos por etapa'
            />
          </Row>
        )
      } */}
      {
        jobAppId && (
          <Row type='flex' gutter={[20, 20]}>
            <Col xs={24} lg={12}>
              <TemplateCard
                templateGraphic
                height={300}
                topic='FIT'
                graphicType='pieChart'
                title={i18n.t('analytics__fit_distribution')}
                extra={i18n.t('analytics__fit_distribution_extra')}
                data={processNivoData(fit_distribution, ['fit'])}
                customMargin={{ top: 40, right: 20, bottom: 20, left: 40 }}
              />
            </Col>
            <Col xs={24} lg={12}>
              <TemplateCard
                templateText
                height={340}
                data={parsedDates.first_application_date}
                title='Fecha de primera postulación'
                text={parsedDates.first_application_date ? 
                  'Fue la fecha de la primera postulación a este proceso.' : null
                }
              />
            </Col>
          </Row>
        )
      }
      <Row style={{ margin: '30px 0 15px 0' }}>
        <Text>{i18n.t('analytics__utm_source')}</Text>
      </Row>
      <Row type='flex' gutter={[20, 20]}>
        <Col xs={24} lg={12}>
          <TemplateCard
            templateGraphic
            topic='channel'
            graphicType='bar'
            customDirection='horizontal'
            title={i18n.t('analytics__utm_sources')}
            extra={i18n.t('analytics__utm_sources_info')}
            data={processNivoData(keysToUpperCase(utm_sources), ['source'])}
            height={300}
            customMargin={{ top: 10, right: 5, bottom: 30, left: 90 }}
          />
        </Col>
        <Col xs={24} lg={12}>
          <TemplateCard
            templateText
            height={342}
            data={formatUTMSrcName(getBestSource())}
            title='Canal de reclutamiento de mayor impacto'
            text='Es tu fuente de reclutamiento más importante de todos los procesos'
            flexDirection='column'
            fontSize='40px'
          />
        </Col>
      </Row>
      <Row type='flex' gutter={[20, 20]}>
        <Col xs={24} lg={12}>
          <TemplateCard
            templateText
            height={342}
            data={nps ? `${nps.toFixed(1)}/10` : null}
            fontSize='35px'
            iconType={
              nps > 8 ?
                'smile'
                : nps > 5 ?
                  'meh'
                  : 'frown'
            }
            title='Satisfacción del Candidato'
            text={
              nps ? nps > 8 ?
                '¡Felicitaciones! Esta es una buena nota por parte de tus postulantes.'
                : 'Es la nota promedio con la que tus candidatos han evaluado los procesos seleccionados.' : null
            }
          />
        </Col>
      </Row>

      {/* PENDING KOs
      {
        jobAppId &&
        // TODO:
        (
          <Row type='flex' gutter={[20, 20]}>
            {
              [1, 2].map((elem, idx) => (
                <Col key={idx} xs={24} lg={12}>
                  <TemplateCard
                    templateGraphic
                    data={[{ id: 1, name: '1', value: 10 }, { id: 2, name: '2', value: 8 }, { id: 3, name: '3', value: 35 }]}
                    title='Preguntas adicionales'
                    question='Del 1 al 5, ¿cómo definirías tu nivel de figma? donde 1 es novato y 5 es experto'
                    graphicType='bar'
                    topic='question'
                    height={350}
                    customMargin={{ top: 20, right: 5, bottom: 90, left: 40 }}
                  />
                </Col>
              ))
            }
          </Row>
        )
      } */}

      {
        jobAppId &&
        (
          <React.Fragment>
            <Row style={{ margin: '30px 0 15px 0' }}>
              <Text>Fechas del proceso</Text>
            </Row>
            <Row type='flex' gutter={[20, 20]}>
              <Col xs={24} lg={12}>
                <TemplateCard
                  templateText
                  height={250}
                  data={parsedDates.creation_date}
                  title='Fecha de apertura del proceso'
                  text={parsedDates.creation_date ? 
                    'Es la fecha de apertura a postulaciones del proceso.' : null
                  }
                />
              </Col>
              <Col xs={24} lg={12}>
                {<TemplateCard
                  templateText
                  height={250}
                  data={parsedDates.first_application_date}
                  title='Fecha de primera postulación'
                  text={parsedDates.first_application_date ? 
                    'Fue la fecha de la primera postulación a este proceso.' : null
                  }
                />}
              </Col>
            </Row>
          </React.Fragment>
        )

      }



      <Row style={{ margin: '30px 0 15px 0' }}>
        <Text>Eficiencia del proceso</Text>
      </Row>

      <Row type='flex' gutter={[20, 20]}>
        <Col xs={24} lg={12}>
          {
            !jobAppId ?
              <TemplateCard
                templateText
                height={250}
                data={candidates_per_process ? candidates_per_process.toFixed(2) : null }
                title='Media de candidatos por proceso'
                text='candidatos promedio tienen tus procesos de selección.'
              /> :
              <TemplateCard
                templateText
                height={250}
                title='Tiempo de evaluación de Entrevista'
                text='Es el tiempo promedio que un candidato espera por la evaluación de su entrevista en este proceso.'
              />
          }
        </Col>
        {/* <Col xs={24} lg={12}>
          <TemplateCard
            templateText
            height={250}
            data={days_to_discarded_avg ? `${days_to_discarded_avg} Días` : null}
            title='Tiempo de descarte medio'
            text='Es el tiempo promedio que transcurre para dar respuesta a un candidato rechazado en los procesos seleccionados.'
          />
        </Col> */}
        <Col xs={24} lg={12}>
          <TemplateCard
            templateText
            height={250}
            data={completion_days_avg ? `${completion_days_avg} Día${completion_days_avg == 1 ? '' : 's'}` : null}
            title='Tiempo medio del proceso'
            text='Tardan en promedio tus candidatos en realizar los juegos.'
          />
        </Col>
        <Col xs={24} lg={12}>
          <TemplateCard
            templateText
            height={250}
            data={completion_rate_avg ? `${completion_rate_avg.toFixed(2)*100}%` : null}
            title={i18n.t('analytics__completion_rate_average')}
            text={i18n.t('analytics__completion_rate_average_text')}
            extra={i18n.t('analytics__completion_rate_average_info')}
          />
        </Col>
        <Col xs={24} lg={12}>
          <TemplateCard
            templateText
            height={250}
            // data={`${days_to_first_application_avg} Días`}
            data={days_to_first_application_avg ? `${days_to_first_application_avg} Días`: null}
            title='Tiempo de convocatoria media'
            text='Es el tiempo promedio transcurrido, desde la apertura del proceso hasta la primera postulación. '
          />
        </Col>
      </Row>
      <Row type='flex' gutter={[20, 20]}>
        <Col xs={24} lg={12}>
        <TemplateCard
            templateText
            data={visitor_to_candidate_rate ? `${visitor_to_candidate_rate}%` : null}
            title={i18n.t('analytics__visitor_to_candidate_rate')}
            text={i18n.t('analytics__visitor_to_candidate_rate_text')}
            height={250}
            extra={i18n.t('analytics__visitor_to_candidate_rate_info')}
          />
        </Col>
      </Row>
      {
        jobAppId && 
         <>
          <Row>
            <Col>
            <TemplateCard
                templateGraphic
                graphicType='stackedBar'
                topic='average_time_per_status'
                customDirection='horizontal'
                data={[average_time_per_status.reduce(
                  (obj, item) => Object.assign(obj, { [item.status]: item.count }), { id: "" })]}
                title={i18n.t('analytics__time_per_status')}
                extra={i18n.t('analytics__time_per_status_info')}
                height={300}
              />  
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <TemplateCard 
                templateText
                data={discard_time_avg ? `${discard_time_avg.toFixed(0)} Días` : null}
                title={i18n.t('analytics__discard_time_average')}
                text={i18n.t('analytics__discard_time_average_text')}
                extra={i18n.t('analytics__discard_time_average_info')}
                height={250}    
              />
            </Col>
          </Row>
         </>
      }

      <Row style={{ margin: '30px 0 15px 0' }}>
        <Text>¿Qué tan diversos están siendo tus procesos?</Text>
      </Row>

      <Row gutter={[20, 20]}>
        <Col xs={24} lg={18} >
          <TemplateCard
            templateGraphic
            graphicType='geoMap'
            data={processNivoData(nationalities, ['nationality'])}
            height={250}
            title='Nacionalidad - Candidatos'
            text={nationality_avg ? nationality_avg : null}
          />

          <TemplateCard
            templateGraphic
            height={350}
            graphicType='bar'
            topic='age'
            data={processNivoData(ages, ['age_range'])}
            customMargin={{ top: 40, right: 20, bottom: 40, left: 55 }}
            title='Edad - Candidatos'
            customDirection='vertical'
            text='años'
          />

        </Col>
        <Col xs={24} lg={6}>
          <TemplateCard
            templateGraphic
            topic='gender'
            graphicType='pieChart'
            title='Género - Candidatos'
            text={genders.length ? 
              genders.reduce(function (prev, current) {
                return (prev && prev.count > current.count) ? prev : current
              })
              : null}
            data={processNivoData(genders, ['gender'])}
            height={320}
            customMargin={{ top: 50, right: 5, bottom: 110, left: 0 }}
            translateX={10}
            translateY={170}
          />
        </Col>
      </Row>

      {
        jobAppId &&
        (
          <Row>
            <TemplateCard
              templateGraphic
              height={250}
              graphicType='bar'
              data={processNivoData(education_levels, ['education_level'], '', true)}
              topic='education'
              title='Formación academica - Candidatos'
              customDirection={'vertical'}
              customMargin={{ top: 20, right: 20, bottom: 50, left: 50 }}
            />
          </Row>
        )
      }

      <Row type='flex' justify='center' style={{ margin: '30px 0 42px 0' }}>
        <Button icon='to-top' onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          Volver arriba
        </Button>
      </Row>
    </Col>
  );
}

export default Candidates;