import React, { useEffect, useRef, useState } from "react";

import OptionButton from "../commons/OptionButton";

import {
  Typography
} from 'antd';
import ParseHtml from "../../../../../components/parseHtml";

const alternativesDict = (idx) => String.fromCharCode(97 + idx);

const SingleAnswer = ({ field, fieldChanged, value, disableNextPage }) => {
  const [state, setState] = useState('');
  // Be sure that this props never changes
  const disableNextPageStable = useRef(disableNextPage);

  useEffect(() => {
    setState(value)
  }, [value])

  useEffect(() => {
    if (state != '') {
      disableNextPageStable.current(field._uid, false);
    }
    else {
      disableNextPageStable.current(field._uid, true);
    }
  }, [state, field._uid])

  const onClick = (id, value) => {
    fieldChanged(id, value);
    setState(value);
  }

  return (
    <div key={value}>
      <div style={{ marginTop: 20 }}>
        <Typography.Text style={{ fontSize: 14, color: '#A4A4A4' }}>SELECCIÓN ÚNICA</Typography.Text>
      </div>
      <div style={{ marginBottom: 10 }}>
        <Typography.Text style={{ fontSize: 16, color: '#5E5E5E', userSelect: 'none' }}>{ParseHtml(field.label)}</Typography.Text>
      </div>
      {field.options.map((option, index) => {
        return (
          <div key={option.value}>
            <OptionButton
              checked={state === option.value}
              id={field._uid}
              label={option.label}
              value={option.value}
              index={index}
              prefix={alternativesDict(index)}
              type={'single'}
              onClick={onClick}
            />
          </div>
        );
      })}
    </div>
  );
};

export default SingleAnswer;
