import React, { useState, useContext } from 'react';

import { CandidateContext } from '../config/CandidateContext';

import {
  Button,
  Col,
  Icon,
  Modal,
  Row,
  Select,
  Tag,
  Tooltip,
  Typography,
  message,
} from 'antd';

import FitProgress from '../../../components/FitProgress';
import InviteCandidateModal from '../../../components/InviteCandidate';
import Tags from '../../../components/Tags';
import Notes from '../components/Notes';
import Change from '../components/Change';
import i18n from '../../../i18n';
import useBoolean from '../../../hooks/useBoolean';
import MoveModal from '../../BusinessDashboard/CandidateList/components/MoveModal';
import DiscardReasonTag from '../../BusinessDashboard/CandidateList/components/DiscardReasonTag';
import { sources } from '../../../constants/passiveCandidates';

const { Text } = Typography;

const Information = ({ handleShowPassiveModal, handleWaitingResponse, waitingResponse }) => {
  const {
    application,
    candidate,
    discardReasons,
    indexCandidate,
    laneData,
    onChangeCandidate,
    jobApp,
    updateStatus,
    sharing,
    handleEmailView,
    changeKey,
    handleFixLockedGames,
    discardCandidate,
    editCandidateDiscardReason,
    reclassifyCandidate,
    translationFile,
    mailTemplates,
    permissions,
  } = useContext(CandidateContext);
  const [unlockedGames, setUnlockedGames] = useState(true);
  const [showDiscardModal, handleDiscardModal] = useBoolean(false);
  const [invitePassiveCandidateModalVisible, handleInvitePassiveCandidateModal] = useBoolean(false);


  const { stages: { stages } } = jobApp;

  const checkBlockedCutes = () => {
    return application.activities?.filter(
      (item) => item.code == 'CUTE'
    ).some(
      (cute) => cute.status !== 'FINISHD' && cute.status !== 'INITIAL'
    );
  };

  const getJobOrCareer = (jobs, careers) => {
    const lastJob = jobs.sort(
      (a, b) =>
        parseInt(a.year_end) - parseInt(b.year_end) ||
        parseInt(a.month_end) - parseInt(b.month_end)
    )[jobs.length - 1];
    const lastCareer = careers.sort(
      (a, b) => parseInt(a.career_last_year) - parseInt(b.career_last_year)
    )[careers.length - 1];

    return lastJob
      ? `${lastJob.position} ${i18n.t('profile__in', translationFile)} ${lastJob.company}`
      : lastCareer
      ? `${lastCareer.career_name}  ${i18n.t('profile__in', translationFile)} ${lastCareer.career_institution}`
      : i18n.t('profile__not_declared', translationFile);
  };

  const confirmUnlock = () => {
    Modal.confirm({
      title: i18n.t('profile__block_game', translationFile).replace('{candidato}', candidate.user_name),
      content:
        i18n.t('profile__unlock_game_information', translationFile),
      centered: true,
      onOk: () => {
        if (permissions.business_admin || permissions.role?.can_manage_userjobapplications){
          handleFixLockedGames(candidate.id);
          setUnlockedGames(false);
        }
        else {
          message.error(
            i18n.t('commons__permissions_error', state.translationFile)
          );
        }
      },
      onCancel() {},
    });
  };

  const changeState = async () => {
    handleWaitingResponse.on();
    if (application.state === 'ACTIV') {
      await discardCandidate();
    } else {
      await reclassifyCandidate();
    }
    handleWaitingResponse.off();
  };

  const handleDiscardCandidate = async (closeModal, discardReasonId, comment = null) => {
    await discardCandidate(discardReasonId, comment);      
      closeModal();
  }

  const formatCandidateData = () => {
    return {
      'ACTIV': {
        [candidate.personal_user_id]: {
          id: candidate.personal_user_id,
          email: candidate.email,
          name: candidate.name,
        }
      }
    };
  };

  return (
    <Col xs={24} lg={8} className="new-modal-information">
      <div className="new-modal-information__head">
        <MoveModal
          visible={showDiscardModal}
          handleVisible={handleDiscardModal}
          candidatesLength={1}
          callback={handleDiscardCandidate}
          mode="discard"
          selectedCandidates={formatCandidateData()}
          jobappid={jobApp.id}
          discardReasons={discardReasons}
        />
        <Row type="flex" justify="space-between" align="middle">
          <Text strong className="text-20px">
            {candidate.user_name}
            {' '}
            {application.candidate_type === 'passive'
              ? (
                <Tooltip
                  placement="right"
                  title={i18n.t('passive_candidates__update_passive_candidate')}
                >
                  <Icon onClick={handleShowPassiveModal.on} type="edit" />
                </Tooltip>
              )
              : null}
          </Text>
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            {(application.incompleted_games && unlockedGames)
              ? (
                <Tooltip title={i18n.t('profile__blockade_in_process', translationFile )}>
                  <Button
                    style={{
                      borderRadius: 2,
                      background: '#FAAD14',
                      borderColor: '#FAAD14',
                    }}
                    type="danger"
                    size="small"
                    onClick={confirmUnlock}
                    icon={'exclamation-circle'}
                  />
                </Tooltip>
              ) : null}
            {(application.candidate_type !== 'passive' && checkBlockedCutes())
              ? (
                <Tooltip
                  title={i18n.t('profile__block_cute', translationFile)}
                  placement="bottom"
                >
                  <Button
                    style={{
                      borderRadius: 2,
                      background: '#FAAD14',
                      borderColor: '#FAAD14',
                    }}
                    type="danger"
                    size="small"
                    onClick={() => changeKey(2)}
                    icon={'exclamation-circle'}
                  />
                </Tooltip>
              ) : null}
          </div>
        </Row>
        {(candidate.experiences || candidate.careers)
          && <Text>{getJobOrCareer(candidate.experiences, candidate.careers)}</Text>}
      </div>
      <div className="new-modal-information__postulation">
        <FitProgress
          width={100}
          type="circle"
          percent={application.final_score}
          strokeWidth={6}
          showLegend={true}
        />
        <div className="new-modal-information__postulation__right">
          <Text strong>{i18n.t('commons__postulation_status', translationFile)}</Text>
          <Select
            disabled={sharing}
            onChange={(code) => updateStatus(code)}
            style={{ width: '180px' }}
            value={application.status}
          >
            {stages?.map((stage) => (
              <Select.Option key={stage.code} value={stage.code}>
                {stage.name}
              </Select.Option>
            ))}
          </Select>
          {// Old logic Descartado Stage
            application.status !== 'OTHER' &&
              (application.state === 'DISCAR' ? (
                <Button icon="undo" type="link" onClick={reclassifyCandidate}>
                  {i18n.t('profile__reclassify', translationFile)}
                </Button>
              ) : (
                <Button icon="stop" type="link" onClick={handleDiscardModal.toggle} disabled={sharing}>
                  {i18n.t('profile__discard_postulation', translationFile)}
                </Button>
              ))
          }
        </div>
      </div>
      {application.state === 'DISCAR' && (
        <div className="new-modal-information__contact">
          <Text strong className="text-16px">
            Razón de descarte
          </Text>
          <br />
          <DiscardReasonTag
            editCandidateDiscardReason={editCandidateDiscardReason}
            application={application}
            candidate={candidate} 
            discardReasons={discardReasons} 
          />
        </div>      
        )}
      {application.candidate_type === 'passive' ? (
        <>
          <Row align="top" justify="space-between" type="flex">
            <Tag color="blue" style={{ marginTop: '0.5em' }}>
              <Icon type="user" />
              {` ${i18n.t('commons__passive_candidate')}`}
            </Tag>
            {candidate?.email && 
              <Button onClick={handleInvitePassiveCandidateModal.on} type="link">
                <Icon type="user-add" />
                {i18n.t('profile__invite_passive_candidate', translationFile)}
              </Button>
            }
          </Row>
          <div>
            <Text className="text-16px" strong>
              {i18n.t('passive_candidates__source')}
            </Text>
            <br />
          <Tag style={{ marginTop: '0.5em' }}>
            {sources[candidate.source] ?? i18n.t('profile__unfilled', translationFile)}
          </Tag>
          </div>
        </>
      ) : (
        <Row align="top" justify="space-between" type="flex">
          <Tag color="green" style={{ marginTop: '0.5em' }}>
            <Icon type="user" />
            {` ${
              i18n.t('profile__candidate', translationFile).slice(0, -1)
            } ${
              i18n.t('commons__activ', translationFile).toLocaleLowerCase()
            }`}
          </Tag>
        </Row>
      )}
      <div className="new-modal-information__contact">
        <Text strong className="text-16px">
          {i18n.t('commons__contact_information', translationFile)}
        </Text>
        <Button
          disabled={sharing || !candidate.email || !permissions?.role?.can_manage_userjobapplications}
          onClick={handleEmailView.toggle}
          style={{ userSelect:'auto' }}
          type="link"
        >
          <Icon type="mail" />
          <Text style={{ color: '#5E5E5E' }}>
            {candidate.email || i18n.t('profile__not_declared', translationFile)}
          </Text>
        </Button>

        <Button
          disabled={!candidate.full_phone || candidate.full_phone === ''}
          onClick={() =>
            window.open(
              `https://api.whatsapp.com/send?phone=${candidate.full_phone.slice(1)}`
            )
          }
          style={{ userSelect:'auto' }}
          type="link"
        >
          <Icon type="phone" />
          <Text style={{ color: '#5E5E5E' }}>
            {candidate.full_phone || i18n.t('profile__not_declared', translationFile)}
          </Text>
        </Button>
      </div>
      {application.candidate_type !== 'passive' && (
        <div className="new-modal-information__tags">
          <Text strong className="text-16px">
            {i18n.t('commons__tags', translationFile)}
          </Text>
          <Tags
            personalUserId={candidate.id}
            tags={candidate.tags}
            onlyView={sharing}
          />
        </div>
      )}
      <Notes />
      <div className="new-modal-information__swap">
        <Change
          buttons={[
            {
              onClick: () => onChangeCandidate('prev'),
              disabled: !indexCandidate,
            },
            {
              onClick: () => onChangeCandidate('next'),
              disabled:
                !laneData.length || indexCandidate + 1 === laneData.length,
            },
          ]}
          before={i18n.t('profile__switch_profile', translationFile).split('{')[0]}
          value={indexCandidate + 1}
          next={`${i18n.t('profile__of', translationFile)} ${laneData.length ? laneData.length : 1}`}
        />
      </div>

      <InviteCandidateModal
        closeModal={handleInvitePassiveCandidateModal.off}
        jobApplication={jobApp}
        selectedPersonalUsers={[
          {
            email: candidate.email,
            name: candidate.name,
            surname: candidate.surname,
          }
        ]}
        selectProcessesVisible={false}
        source="passive-gw"
        templates={mailTemplates}
        visible={invitePassiveCandidateModalVisible}
      />
    </Col>
  );
};

export default Information;
