import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Spin, Typography } from 'antd'

const LoadingModal = (props) => {
  return (
    <Modal 
      visible={props.loading}
      centered={true}
      footer={false}
      closable={false}
      className='gw-loading-modal__export-excel'
    >
      <Spin size="middle" style={{marginRight: 20}}/>
      <Typography.Text>Estamos descargando tus datos. Esto podría tomar un momento.</Typography.Text>
    </Modal>
  )    
}

LoadingModal.propTypes = {
  loading: PropTypes.bool.isRequired
};

export default LoadingModal