import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';

import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Divider, Popover, Typography } from 'antd'
import i18n from "../../../../../../../../i18n";

const { Text } = Typography

const styles = theme => ({
	title: {
		borderRadius: 4,
		textAlign: "center",
		paddingTop: 13,
		paddingBottom: 13,
    maxWidth: 540,
    margin: "0 auto 27px"
  },
  first: {
		margin: "0 auto"
  },
  nonfirst: {
  },
  unfinished: {
    //opacity: 0.5
  },
  finished: {  
  },
	titleBody1: {
    margin: 0,
    color: 'black',
	},
});

const StageTitle = props => {
  const { classes, name, percentage, items } = props;
  const levelOneIsCompleted = items.filter(
    e => e.code === 'FORM' || e.code === 'KO'
  ).map(({ status }) => status).includes('PLAYABLE');
  const levelTwoIsCompleted = items.filter(
    e => e.code !== 'FORM' && e.code !== 'KO'
  ).map(({ status }) => status).includes('PLAYABLE');
  const isActivities = name === 'Actividades';
  const currentLevel = isActivities ? levelTwoIsCompleted : levelOneIsCompleted;
  const tower = 'https://genoma-assets.s3.us-east-2.amazonaws.com/Castle.svg';
  const towerWthoutFlag = 'https://genoma-assets.s3.us-east-2.amazonaws.com/Castle.svg';
  const towerLevel = !currentLevel ? tower : towerWthoutFlag;
	return (
    <div
      className={classNames(classes.title, percentage ? classes.finished : classes.unfinished)}
    >
      <Divider>
        <Popover
          placement="bottom"
          content={
            <Fragment>
              <Text strong>
                {(isActivities && levelOneIsCompleted)
                  ? 'Completa el nivel 1 para desbloquear los juegos'
                  : (
                    !currentLevel
                      ? i18n.t('candidate_path__level_completed')
                      : i18n.t('candidate_path__level_incomplete')
                  )}
              </Text>
              <br />
              <Text>
                {isActivities ? (
                  '¡Esta etapa es fundamental para concluir el proceso!'
                ) : (
                  i18n.t('candidate_path__level_1__explanation')
                )}
              </Text>
            </Fragment>
          }
          trigger="click"
          className='stage-popover'
          overlayClassName='stage-popover'
        >
          <img className='castle-img' src={towerLevel} />
        </Popover>
      </Divider>
      <Text className='stage-title' strong>
        {name}
      </Text>
    </div>
	);
};

StageTitle.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    items: state.data.data.items,
    jobappId: state.data.data.jobapp_id
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default withStyles(styles)(connect(mapStateToProps,mapActionsToProps)(StageTitle));
