import React from 'react';
import { Row, Col } from 'antd';
import ProfilesPDF from './ProfilesPDF';
import QuestionsPDF from './QuestionsPDF';

import "../../pdf-css.scss";

function ThirdPagePdf({ candidates }) {
  return (
    <Row>
      <ProfilesPDF candidates={candidates} />
      <Row className='dividers'>
        <p>Preguntas adicionales</p>
      </Row>
      <QuestionsPDF candidates={candidates} />
      <Row style={{ minHeight: 45 }} />
    </Row>
  );
}

export default ThirdPagePdf;
