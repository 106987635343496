import React, { useContext, useMemo } from 'react';
import { Col, Row, Typography, Switch, Checkbox, Button } from 'antd';
import { AnalyticsContext } from '../context/AnalyticsContext';
import GenomaScoreStream from '../../../../components/charts/Stream';

const { Text } = Typography;

function TraitCategoryComponent({ title, traitCategoryData }) {
  return (
    <Row align='middle' style={{ border: '1px solid #D9D9D9', background: 'white', marginBottom: 20 }}>
      <Row type='flex' align='middle' style={{ borderBottom: '1px solid #D9D9D9', height: '42px' }}>
        <Text style={{ padding: '10px' }}>{title}</Text>
      </Row>
      {
        traitCategoryData.map((trait, idx) => (
          <Row key={idx} style={{ height: '300px' }}>
            <Row type='flex' justify='start' align='middle' style={{ padding: '10px 20px' }}>
              <Text>{trait.trait_name}</Text>
            </Row>
            <Col xs={24} lg={12} style={{ height: '100%' }}>
              {
                trait.distribution && trait.distribution.length ?
                  <GenomaScoreStream
                    data={trait.distribution}
                    keys={['Tus contrataciones', 'Tus candidatos']}
                    labels={['score']}
                  /> :
                  (
                    <Row type='flex' justify='center' align='middle' style={{ height: '350px' }}>
                      <Text strong>Sin información</Text>
                    </Row>
                  )
              }
            </Col>
            <Col xs={24} lg={12} style={{ height: '100%', padding: '80px 20px 20px 20px' }}>
              <Row type='flex' justify='end' align='middle' style={{ width: '100%' }}>
                <Col span={8} style={{ border: '1px solid #D9D9D9', borderRight: 'none', borderBottom: 'none', height: '100%', padding: '10px', background: '#0050B3'}}>
                
                  <Row type='flex' justify='center' align='middle'>
                    <Text style={{ color: '#FFFFFF', textAlign: 'center' }}>Tus candidatos</Text>
                  </Row>
                </Col>
                <Col span={8} style={{ border: '1px solid #D9D9D9', borderBottom: 'none', height: '100%', padding: '10px', background: '#096DD9' }}> 
                  <Row type='flex' justify='center' align='middle'>
                    <Text style={{ color: '#FFFFFF', textAlign: 'center' }}>
                      Tus contrataciones
                    </Text>
                  </Row>
                </Col>
              </Row>
              {
                [
                  { title: 'Promedio', candidate: trait.mean_not_hired.toFixed(2), hired: trait.mean_hired.toFixed(2) },
                  { title: 'Mediana', candidate: trait.median_not_hired, hired: trait.median_hired },
                ].map((row, idx) => (
                  <Row key={idx} type='flex' align='middle' style={{ width: '100%' }}>
                    <Col span={8} style={{ border: '1px solid #D9D9D9', borderRight: 'none', height: '100%', padding: '10px' }}>
                      <Row type='flex' justify='center' align='middle'>
                        <Text strong>{row.title}</Text>
                      </Row>
                    </Col>
                    <Col span={8} style={{ border: '1px solid #D9D9D9', borderRight: 'none', height: '100%', padding: '10px' }}>
                      <Row type='flex' justify='center' align='middle'>
                        <Text>{row.candidate}</Text>
                      </Row>
                    </Col>
                    <Col span={8} style={{ border: '1px solid #D9D9D9', height: '100%', padding: '10px' }}>
                      <Row type='flex' justify='center' align='middle'>
                        <Text>{row.hired}</Text>
                      </Row>
                    </Col>
                  </Row>
                ))
              }
            </Col>
          </Row>
        ))
      }
    </Row>
  )
}


const Evaluations = () => {
  const { sectionsData: { evaluations }, jobAppId } = useContext(AnalyticsContext);

  const {
    EMOTIO,
    COGNIT,
    RAZONA,
    PERSON,
    BEHAV,
    total,
  } = evaluations;

  const parseStreamData = (data) => {
    if (!data) return [];
    return Object.keys(data).map((trait_id) => {
      const trait = {
        trait_id,
        mean: data[trait_id].mean,
        medianIndex: data[trait_id].median_index,
        trait_name: data[trait_id].trait_name,
        total: data[trait_id].total,
        total_hired: data[trait_id].total_hired,
        total_not_hired: data[trait_id].total - data[trait_id].total_hired,
        mean_hired: data[trait_id].mean_hired || 0,
        mean_not_hired: data[trait_id].mean_not_hired || 0 ,
        median_hired: data[trait_id].median_hired || 0 ,
        median_not_hired: data[trait_id].median_not_hired || 0,
      };
      const distribution = Array(101).fill(null);
      // Fill scores to use them as labels in chart.
      distribution.forEach((item, idx) => {
        distribution[idx] = {
          score: idx,
          'Tus candidatos': 0,
          'Tus contrataciones': 0,
        }
      })

      let hired_idx = 0;
      let not_hired_idx = 0;
      data[trait_id].distribution.forEach((item) => {
        if (item.score < 0 || item.score > (data.intervals || 100)) {
          return;
        }
        if (item.is_hired) {
          // Delete
          if (hired_idx <= Math.floor(trait.total_hired/2) ) {
            trait.median_hired = item.score;
          }
          hired_idx += item.count;
          // Delete
          trait.mean_hired += (item.score*item.count) / trait.total_hired;
          distribution[item.score]['Tus contrataciones'] += item.count
          // /trait.total_hired
        } else {
          // Delete
          if (not_hired_idx <= Math.floor(trait.total_not_hired/2) ) {
            trait.median_not_hired = item.score;
          }
          not_hired_idx += item.count;
          // Delete
          trait.mean_not_hired += (item.score*item.count) / (trait.total - trait.total_hired);
          distribution[item.score]['Tus candidatos'] += item.count
          // /(trait.total - trait.total_hired)
        }
      });

      trait.distribution = distribution;
      return trait;
    });
  }

  const parsedEmotion = useMemo(() => parseStreamData(EMOTIO), [EMOTIO]);
  const parsedCognitive = useMemo(() => parseStreamData(COGNIT), [COGNIT]);
  const parsedReasoning = useMemo(() => parseStreamData(RAZONA), [RAZONA]);
  const parsedPersonality = useMemo(() => parseStreamData(PERSON), [PERSON]);

  return (
    <Col xs={24} className="new-analytics__content">
      <Row style={{ margin: '0 0 15px 0' }}>
        <Text>Visión general</Text>
      </Row>

      {/* <Row align='middle' style={{ border: '1px solid #D9D9D9', background: 'white' }}>
        <Row type='flex' align='middle' style={{ borderBottom: '1px solid #D9D9D9', height: '42px' }}>
          <Text style={{ padding: '10px' }}>Distribuciones consideradas</Text>
        </Row>
        <Row type='flex' gutter={[20, 20]} style={{ padding: '10px', height: '300px' }}>
          <Col xs={24} lg={12}>
            <Row type='flex' justify='space-between' align='middle'>
              <Text>Distribución de candidatos</Text>
              <Switch style={{ margin: '0 30px 0 0' }} />
            </Row>
            <Row type='flex' justify='start' style={{ flexDirection: 'column', margin: '15px 0 0 0' }}>
              <Checkbox style={{ margin: 0 }}>FIT recomendado</Checkbox>
              <Checkbox style={{ margin: '10px 0' }}>FIT quizás</Checkbox>
              <Checkbox style={{ margin: 0 }}>FIT no recomendado</Checkbox>
            </Row>
          </Col>
          <Col xs={24} lg={12}>
            <Row type='flex' justify='space-between' align='middle'>
              <Text>Distribución de contratados</Text>
              <Switch style={{ margin: '0 30px 0 0' }} />
            </Row>
            <Row type='flex' style={{ flexDirection: 'column', margin: '15px 0 0 0' }}>
              <Checkbox style={{ margin: 0 }}>FIT recomendado</Checkbox>
              <Checkbox style={{ margin: '10px 0' }}>FIT quizás</Checkbox>
              <Checkbox style={{ margin: 0 }}>FIT no recomendado</Checkbox>
            </Row>
          </Col>
        </Row>
        <Row type='flex' justify='end' align='middle' style={{ padding: '10px' }}>
          <Button>Aplicar configuración</Button>
        </Row>
      </Row> */}

      <Row style={{ border: '1px solid #D9D9D9', background: 'white', margin: '20px 0' }}>
        <Row type='flex' align='middle' style={{ borderBottom: '1px solid #D9D9D9', height: '42px' }}>
          <Text style={{ padding: '10px' }}>Totales considerados</Text>
        </Row>
        <Row type='flex'>
          <Col span={12} style={{ padding: '10px 0 10px 10px' }}>
            <Row type='flex' justify='center' align='middle' style={{ height: '42px', width: '100%', background: '#096DD9' }}>
              <Text style={{ color: 'white' }}>Tus candidatos</Text>
            </Row>
            <Row type='flex' justify='center' align='middle' style={{ height: '42px', width: '100%', border: '1px solid #D9D9D9', borderTop: 'none' }}>
              <Text>{total.total}</Text>
            </Row>
          </Col>
          <Col span={12} style={{ padding: '10px 10px 10px 0' }}>
            <Row type='flex' justify='center' align='middle' style={{ height: '42px', width: '100%', background: '#003A8C' }}>
              <Text style={{ color: 'white' }}>Tus contrataciones</Text>
            </Row>
            <Row type='flex' justify='center' align='middle' style={{ height: '42px', width: '100%', border: '1px solid #D9D9D9', borderTop: 'none' }}>
              <Text>{total.total_hired}</Text>
            </Row>
          </Col>
        </Row>
      </Row>

      <TraitCategoryComponent title='Evaluaciones de Razonamiento' traitCategoryData={parsedReasoning} />
      <TraitCategoryComponent title='Cognitivos' traitCategoryData={parsedCognitive} />
      <TraitCategoryComponent title='Conductuales' traitCategoryData={parsedEmotion} />
      <TraitCategoryComponent title='Personalidad' traitCategoryData={parsedPersonality} />

      <Row type='flex' justify='center' style={{ margin: '30px 0 42px 0' }}>
        <Button icon='to-top' onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          Volver arriba
        </Button>
      </Row>
    </Col>
  );
}

export default Evaluations;