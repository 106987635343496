import React, { useState, useEffect } from 'react';
import {
  Button,
  Col,
  Divider,
  Row,
  TimePicker,
} from 'antd';
import i18n from '../../../../i18n';
import moment from 'moment';
import daysOfWeek from '../../../../constants/daysOfWeek';

const hourOfDayToOneHot = (hour, minutes, end = false) => {
  // Blocks of time are 15 minutes
  return hour * 4 + minutes / 15 - end * 1;
}

const oneHotToBase16 = (oneHotString) => {
  const iterations = 96 / 4;
  let result = '';
  for (let i = 0; i < iterations; i++) {
      const partition = oneHotString.slice(i * 4, (i + 1) * 4);
      result += `${parseInt(partition, 2).toString(16)}`
  }
  return result;
}

const DaysAndTimesComponent = ({
  onChange,
  schedule,
  index,
  handleDeleteBlock,
}) => {

  const handleDayClick = (day) => {
    const newSchedule = structuredClone(schedule);
    newSchedule.daysSelected[day] = !newSchedule.daysSelected[day];
    onChange(newSchedule, index);
  }

  const handleHourChange = (timeString, position) => {
    const newSchedule = structuredClone(schedule);
    newSchedule[position] = timeString;
    onChange(newSchedule, index);
  }

  return (
    <Row key={index}>
      <Row type='flex' gutter={5}>
        {daysOfWeek.map((day) => {
          return (
            <Col span={3}>
              <Button
                shape='circle'
                type={schedule.daysSelected[day] ? 'primary' : ''}
                onClick={() => handleDayClick(day)}
              >
                {i18n.t(`commons__dayofweek__${day}`, 'translation').slice(0, 2)}
              </Button>
            </Col>
          )
        })}
      </Row>
      <Row type='flex' style={{ marginTop: 20 }}>
        <TimePicker
          value={moment(schedule.start, 'HH:mm')}
          onChange={(_, timeString) => handleHourChange(timeString, 'start')}
          minuteStep={15} format='HH:mm'
          inputReadOnly
          allowClear={false} 
        />
        -
        <TimePicker
          value={moment(schedule.end, 'HH:mm')}
          onChange={(_, timeString) => handleHourChange(timeString, 'end')}
          minuteStep={15} format='HH:mm'
          inputReadOnly
          allowClear={false} 
        />
        <Button
            type='link'
            icon='delete'
            onClick={() => handleDeleteBlock(index)}
            style={{ color: '#ec5f2f', padding: 0 }}
          />
      </Row>
      <Divider />
    </Row>
  )
}

export default function ScheduleFilterComponent({ onChange }) {
  const [filter, setFilter] = useState(',,,,,,');
  const [schedules, setSchedules] = useState([
    {
      daysSelected: {
        'monday': true,
        'tuesday': false,
        'wednesday': false,
        'thursday': false,
        'friday': false,
        'saturday': false,
        'sunday': false,
      },
      start: '08:00',
      end: '17:00'
    },
  ])

  useEffect(() => {
    const newFilter = {
      'monday': Array(96).fill(0),
      'tuesday': Array(96).fill(0),
      'wednesday': Array(96).fill(0),
      'thursday': Array(96).fill(0),
      'friday': Array(96).fill(0),
      'saturday': Array(96).fill(0),
      'sunday': Array(96).fill(0),
    };
    schedules.forEach((schedule) => {
      daysOfWeek.filter(d => schedule.daysSelected[d]).forEach((day) => {
        const start = schedule.start.split(':');
        const end = schedule.end.split(':');
        const startOneHot = hourOfDayToOneHot(parseInt(start[0]), parseInt(start[1]));
        const endOneHot = hourOfDayToOneHot(parseInt(end[0]), parseInt(end[1]), true);
        for (let i = startOneHot; i <= endOneHot; i++) {
          newFilter[day][i] = 1;
        }
      })
    })
    const newFilterString = daysOfWeek.map((day) => {
      return oneHotToBase16(newFilter[day].join(''));
    }).join(',');
    setFilter(newFilterString);
  }, [schedules])

  const handleChangeSchedule = (newSchedule, index) => {
    const newSchedules = structuredClone(schedules);
    newSchedules[index] = newSchedule;
    setSchedules(newSchedules);
  }

  const handleAddblock = () => {
    const newSchedules = structuredClone(schedules);
    newSchedules.push({
      daysSelected: {
        'monday': false,
        'tuesday': false,
        'wednesday': false,
        'thursday': false,
        'friday': false,
        'saturday': false,
        'sunday': false,
      },
      start: '08:00',
      end: '17:00'
    })
    setSchedules(newSchedules);
  }

  const handleDeleteBlock = (pos) => {
    const newSchedules = structuredClone(schedules);
    newSchedules.splice(pos, 1);
    setSchedules(newSchedules);
  }

  const handleApplyFilter = () => {
    onChange('ko_schedule', filter);
  }

  return (
    <Row>
      {schedules.map((schedule, index) => {
        return (
          <DaysAndTimesComponent
            key={index}
            schedule={schedule}
            index={index}
            onChange={handleChangeSchedule}
            handleDeleteBlock={handleDeleteBlock}
          />
        )
      })}
      <Button
        type='link'
        icon='plus'
        style={{ color: '#7583f7', padding: 0 }}        
        onClick={handleAddblock}
      >
        Agregar otro bloque horario
      </Button>
      <Button
        type='primary'
        onClick={handleApplyFilter}
      >
        Aplicar horario
      </Button>
    </Row>

  )
}