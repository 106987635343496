import { Col, Icon, Row, Tabs, Typography } from 'antd';
import { traitsColors, traitsNames } from './utils';

import React from 'react';
import { ResponsiveBar } from '@nivo/bar';

const { Text, Title } = Typography;

const splitAndUpper = (str) => {
  const secondWord = str.split(' ')[1];

  return secondWord.charAt(0).toUpperCase() + secondWord.slice(1).toLowerCase();
};

const BarChart = ({ barData, descriptionData }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <div className="chart-colors">
        {descriptionData?.map((category) => (
          <div className="chart-colors__item">
            <div
              style={{
                height: 12,
                width: 12,
                background: category.color,
                borderRadius: 2,
              }}
            />
            <Text>{splitAndUpper(category.title)}</Text>
          </div>
        ))}
      </div>
      <div style={{ height: 350 }}>
        <ResponsiveBar
          data={barData}
          keys={['score']}
          indexBy="code"
          margin={{ top: 10, right: 20, bottom: 40, left: 40 }}
          padding={0.3}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={(bar) => traitsColors[bar.indexValue]}
          axisTop={null}
          axisRight={null}
          label={null}
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          maxValue={12}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: 'middle',
            tickValues: barData.map((trait) => trait.code),
            renderTick: ({ x, y, rotate, textBaseline, value }) => {
              const words = traitsNames[value].split(' ');
              const firstWord = words[0];
              const remainingWords = words.slice(1).join(' ');
              return (
                <g transform={`translate(${x},${y + 6})`}>
                  <text
                    textAnchor="middle"
                    dominantBaseline={textBaseline}
                    style={{
                      fontSize: 12,
                      fill: '#5E5E5E',
                    }}
                  >
                    {firstWord}
                  </text>
                  {remainingWords && (
                    <text
                      textAnchor="middle"
                      dominantBaseline={textBaseline}
                      style={{
                        fontSize: 12,
                        fill: '#5E5E5E',
                      }}
                      dy="1.5em"
                    >
                      {remainingWords}
                    </text>
                  )}
                </g>
              );
            },
          }}
          tooltip={(r) => (
            <div
              style={{
                padding: '0.25rem',
                border: '1px solid #5E5E5E',
                borderRadius: 2,
                background: '#ffffff',
              }}
            >
              <Text>
                <strong style={{ color: traitsColors[r.indexValue] }}>
                  {traitsNames[r.indexValue]}
                </strong>
                : {r.value} punto
                {r.value > 1 ? 's' : ''}
              </Text>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default BarChart;
