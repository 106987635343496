import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from 'react-router-redux';
import { message } from "antd";
import * as actionCreators from "../../actions/data";
import GenominSpin from "../../components/GenominSpin/GenominSpin"
import { Buffer } from 'buffer';

import { queryToObject } from '../../utils/url';

function LeverIntegration({ location, token, actions, business, dispatch }) {

  const [leverState, setLeverState] = useState(undefined);

  // TODO si la integracion muere llamar al delete asi no nos hacemos problemas
  const patchIntegration = (integrationPk, BaseUrl) => {
    const url = `/api/v2/business/${business}/integrations/${integrationPk}/`;
    actions
      .fetchAndReturn(token, url, "PATCH", { base_url: BaseUrl })
      .then((response) => {
        if (response.status >= 400) {
          throw new Error(response.status_code)
        } else {
          return response.body;
        }
      })
      .then((integration) => {
        message.success("Integración actualizada correctamente");
        dispatch(push("/integrations"))
      })
      .catch((e) => {
        message.error("Error al actualizar la integración");
      });
  };


  const postIntegrations = (body) => {
    const url = `/api/v2/business/${business}/integrations/`;
    actions
      .fetchAndReturn(token, url, "POST", body)
      .then((response) => {
        if (response.status >= 400) {
          message.error("Error al crear la integración");
        } else {
          setLeverState(response.body);
          return response.body;
        }
      })
      .then((integration) => {
        patchIntegration(integration.id, integration.template.base_url);
        message.success("Integración creada exitosamente");
      })
      .catch((e) => {
        message.error("Error al crear la integración");
      });
  };

  useEffect(() => {
    const qs = queryToObject(location.search);
    const error = qs.error && qs.error == 'access_denied'
    if (error) {
      dispatch(push("/integrations"))
      return;
    }
    let decodedData = Buffer.from(qs.redirect_params, 'base64').toString('utf-8');
    decodedData = queryToObject(decodedData);
    fetch(
      decodedData.auth_token_url,
      {
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          client_id: decodedData.client_id,
          client_secret: decodedData.client_secret,
          grant_type: 'authorization_code',
          code: qs.code,
          redirect_uri: decodedData.redirect_callback,
        })
      }
    ).then(response => {
      if (response.status >= 400) {
        throw new Error(response.status_code)
      } else {
        return response.json();
      }
    }
    ).then(json => {
      const accessToken = json.access_token;
      delete json.access_token;

      const body = {
        template: decodedData.integration_id,
        status: 'ACTIV',
        auth_token: accessToken,
        fields: json
      };
      postIntegrations(body);
    }

    ).catch(e => {
      /*
          setTimeout(() => {
            window.location.assign('/integrations')
          }, 2000)
      */
      message.error('Hubo un error en la integración. Redirigiendo...')
    })

  }, [])

  return (
    <div>
      <GenominSpin />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    token: state.auth.token,
    business: state.auth.business.id,
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(LeverIntegration);
