import React, { useContext }  from 'react';

import { Button, Divider, Icon, Row, Typography } from 'antd';
import PropTypes from 'prop-types';

import CareerInformation from './CareerInformation';
import { PersonalFormContext } from '../config/Context';
import { stepTitles } from '../constants';
import i18n from '../../../i18n';

const { Text, Title } = Typography;

function AcademicInformation(props) {
  const { form } = props;
  const { getFieldDecorator, getFieldValue, setFieldsValue, validateFields } = form;
  const {
    addCareer,
    candidate: { careers },
    jobApplication,
  } = useContext(PersonalFormContext);

  const optional = jobApplication.optional_fields.careers || jobApplication.optional_fields.careers === undefined;

  return (
    <>
      <Row>
        <Title level={3}>
          {!optional ? <Text style={{ color: 'red' }}>*</Text> : ''}{stepTitles[1]}
        </Title>
      </Row>
      {careers.map((careerData, idx) => (
        <div key={`career-information-${idx}-${Object.values(careerData).join('-')}`}>
          <CareerInformation
            idx={idx}
            careerData={careerData}
            getFieldDecorator={getFieldDecorator}
            getFieldValue={getFieldValue}
            setFieldsValue={setFieldsValue}
            validateFields={validateFields}
          />
        </div>
      ))}
      {careers.length === 0 ? <Divider /> : null}
      <Button className="add-button" onClick={addCareer}>
        <Icon type="plus" /> {i18n.t("commons__add_career")}
      </Button>
    </>
  );
}

AcademicInformation.propTypes = {
  form: PropTypes.object.isRequired,
};

export default AcademicInformation;
