import { Typography } from 'antd';

const { Text } = Typography;

export const countryCode = {
  uruguay: 'UY',
  chile: 'CL',
  brasil: 'BR',
  argentina: 'AR',
  paraguay: 'PY',
  mexico: 'MX',
  bolivia: 'BO',
  perú: 'PE',
  colombia: 'CO',
  panamá: 'PA',
  // ecuador: 'EC',
  // guatemala: 'GT',
};

export const countryCodeGeoMap = {
  uruguay: 'URY',
  chile: 'CHL',
  brasil: 'BRA',
  argentina: 'ARG',
  paraguay: 'PRY',
  venezuela: 'VEN',
  ecuador: 'ECU',
  bolivia: 'BOL',
  perú: 'PER',
  colombia: 'COL',
  panamá: 'PAN',
  guatemala: 'GTM',
  mexico: 'MEX',
  españa: 'ESP',
  corea: 'KOR',
  'república dominicana': 'DOM',
  cameroon: 'CM',
  albania: 'ALB',
  afghanistan: 'AFG',
  algeria: 'DZA',
  austria: 'AUT',
  americansamoa: 'ASM',
  'antigua and barbuda': 'ATG',
  méxico: 'MEX',
  andorra: 'AND',
  angola: 'AGO',
  'puerto rico': 'PRI',
  'costa rica': 'CRI',
};

export const processNivoData = (
  data = [],
  labels,
  separator = '',
  percentage = false
) => {
  const total = percentage
    ? data.reduce((acc, v) => {
        return acc + v.count;
      }, 0)
    : 1;
  const result = data
    .map((d) => {
      return {
        id: labels.reduce((acc, l) => {
          return acc + (acc && separator) + d[l];
        }, ''),
        label: labels.reduce((acc, l) => {
          return acc + (acc && separator) + d[l];
        }, ''),
        value: (d.count / total).toFixed(2) * (percentage ? 100 : 1),
      };
    })
    .filter((d) => d.label !== 'null' && d.label);

    const formattedResult = result.map( obj => ({...obj, label: obj.label?.split("_").join(" ")}))

  return formattedResult;
};

export const parseDates = (data) => {
  Object.keys(data).forEach((k) => {
    data[k] = data[k]
      ? new Date(data[k]).toLocaleDateString()
      : 'Sin información';
  });
  return data;
};

export const fillTimeline = (data, lastN = null) => {
  // Fills timelines for missing dates with 0s as values
  // data is expected to have month and year, and a count value.
  // Data is already sorted by year and month.
  const timeline = [];
  if (!data.length) return [];
  const minMonth = data[0].month;
  const minYear = data[0].year;
  const maxMonth = data[data.length - 1].month;
  const maxYear = data[data.length - 1].year;

  for (let year = minYear; year <= maxYear; year++) {
    for (
      let month = year === minYear ? minMonth : 1;
      month <= 12 && (year !== maxYear || month <= maxMonth);
      month++
    ) {
      timeline.push({ month, year, count: 0 });
    }
  }
  data.forEach((d) => {
    timeline[(d.year - minYear) * 12 + d.month - minMonth].count = d.count;
  });

  if (lastN) {
    return timeline.slice(-lastN);
  }
  return timeline;
};

export const fakeLineCurveData = [
  {
    id: 'Candidatos',
    data: new Array(60).fill('').map((_, i) => ({
      x: i + 18,
      y: Math.floor(Math.random() * 100),
    })),
  },
  {
    id: 'Contrataciones',
    data: new Array(60).fill('').map((_, i) => ({
      x: i + 18,
      y: Math.floor(Math.random() * 100),
    })),
  },
];

export const getLineCurveData = (data = {}, key, customTitles = null) => {
  return Object.entries(data).map(([k, value]) => ({
    id: customTitles ? customTitles[k] : k,
    data: value.data?.length
      ? value.data.map((v) => ({
          x: v[key],
          y: v.count,
        }))
      : [],
  }));
};

export const getStatisticsColumns = (data = [], config = {}) => {
  const columns = [
    {
      title: '',
      dataIndex: 'statistic',
      key: 'statistic',
      render: (text) => <Text strong>{text}</Text>,
    },
  ];

  data.forEach((c, i) => {
    columns.push({
      title: (
        <Text
          style={{
            padding: '1rem',
            display: 'block',
            background: config.colors?.[i],
            color: '#ffffff',
            minHeight: '100%',
          }}
        >
          {c.title}
        </Text>
      ),
      dataIndex: c.key,
      key: c.key,
      render: (value) => (
        <Text style={{ textAlign: 'center', display: 'block' }}>
          {value ? Number(value).toFixed(1) : '-'}
        </Text>
      ),
    });
  });

  return columns;
};

// data formato { candidates: {avg: x, median: x, mode: x}, hired: {avg: x, median: x, mode: x} }
export const prepareStatisticsData = (data, customTitles = null) => {
  const results = {
    avg: { key: '1', statistic: 'Promedio' },
    median: { key: '2', statistic: 'Mediana' },
    mode: { key: '3', statistic: 'Moda' },
  };

  Object.entries(data).forEach((entry) => {
    const [key, value] = entry;
    const newKey = customTitles ? customTitles[key] : key;

    Object.entries(value)
      .filter((entry) => entry[0] !== 'data')
      .forEach((innerEntry) => {
        const [innerKey, innerValue] = innerEntry;
        results[innerKey][newKey] = innerValue;
      });
  });

  return Object.values(results);
};

export const getMaxCount = (dataArray, key) => {
  return Math.max(
    ...dataArray
      .filter(
        (item) =>
          item[key] !== null && item[key] !== undefined && item[key] !== ''
      )
      .map((item) => item.count)
  );
};

export const renderTooltip = ({ point, xLabel, yLabel }) => {
  const formatterString = point.serieId.charAt(0).toUpperCase() + point.serieId.slice(1)
  return (
    <div
      style={{
        background: 'white',
        padding: '10px',
        border: '1px solid #ccc',
      }}
    >
      <strong>{formatterString}</strong>
      <br />
      {xLabel}: {point.data.xFormatted}
      <br />
      {yLabel}: {point.data.yFormatted}
    </div>
  );
};

export const getMaxCountObject = (arr = []) => {
  let maxObj = arr[0] ?? { max: {}, total: 0 };
  let totalCount = 0;

  for (let obj of arr) {
    if (obj.count > maxObj.count) {
      maxObj = obj;
    }
    totalCount += obj.count;
  }

  return { max: maxObj, total: totalCount };
};

export const genders = {
  Male: 'Masculino',
  Female: 'Femenino',
  'Non binary': 'No binario',
  Other: 'Otro',
  'Would rather not say': 'Prefiero no decirlo',
  Empty: 'Sin información',
  Undefined: 'Sin información',
};

export const disabilities = {
  NONE: 'No aplica',
  PHYSICAL: 'Física',
  SENSORY: 'Sensorial',
  INTELECTUAL: 'Intelectual',
  PSYCHIC: 'Psíquica',
  VISCERAL: 'Visceral',
  MULTIPLE: 'Múltiple',
  UNKNOWN: 'Prefiere no señalar',
};

export const getDataOrDefaultValue = (
  data,
  callback = (data) => data,
  defaultValue = null
) => {
  return data !== null && data !== undefined ? callback(data) : defaultValue;
};

export const renderComponentOrDefault = (
  data,
  component,
  defaultValue = (
    <Text
      style={{ display: 'block', textAlign: 'center', width: '100%' }}
    ></Text>
  )
) => {
  return data !== null &&
    data !== undefined &&
    Object.keys(data).some((key) => Object.keys(data[key]).length)
    ? component
    : '';
};

export const formatUTMSrcName = (srcName) => {
  if (!srcName) return '';
  const name = srcName
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  
    switch (name) {
      case 'Genomawork':
        return 'Genomawork';
      case 'Businessportal':
        return 'Portal de empleos';
      default:
        return name;
    }
}

export const keysToUpperCase = (objArray) => {
  const ret = objArray.map(obj => (
    ({source: obj.source && obj.source.charAt(0).toUpperCase() + obj.source.slice(1), count: obj.count})
    ));

  return ret;
}
