/* eslint-disable max-len */

const gamesDescription = {
    IOWA: { traits: ['Aprendizaje del feedback', 'Consistencia para tomar decisiones'],
        traitDesc: ['Capacidad de utilizar la información proveniente del resultado de una decisión en un ambiente incierto de manera de organizar mentalmente este input en una representación coherente e integrarla con el conocimiento existente para mejorar las decisiones subsiguientes.', 'Habilidad para ajustar las decisiones y adherirse al mejor camino disponible después de entender la naturaleza del problema que se está enfrentando.', 
            'Habilidad para ajustar las decisiones y adherirse al mejor camino disponible después de entender la naturaleza del problema que se está enfrentando.'],
    },
    IOW2: { traits: ['Aprendizaje del feedback', 'Consistencia para tomar decisiones'],
        traitDesc: ['Capacidad de utilizar la información proveniente del resultado de una decisión en un ambiente incierto de manera de organizar mentalmente este input en una representación coherente e integrarla con el conocimiento existente para mejorar las decisiones subsiguientes.', 'Habilidad para ajustar las decisiones y adherirse al mejor camino disponible después de entender la naturaleza del problema que se está enfrentando.', 
            'Habilidad para ajustar las decisiones y adherirse al mejor camino disponible después de entender la naturaleza del problema que se está enfrentando.'],
    },
    STRP: { traits: ['Capacidad para evitar distracciones', 'Velocidad para evitar distracciones'],
        traitDesc: ['Capacidad para inhibir la interferencia cognitiva que ocurre cuando el procesamiento de un atributo específico de un estímulo impide el procesamiento simultáneo de un segundo atributo del mismo estímulo.',
            'Tiempo de reacción para inhibir la interferencia cognitiva causada por la presentación de un segundo atributo asociado a un mismo estímulo.']
    },
    STR2: { traits: ['Capacidad para evitar distracciones', 'Velocidad para evitar distracciones'],
        traitDesc: ['Capacidad para inhibir la interferencia cognitiva que ocurre cuando el procesamiento de un atributo específico de un estímulo impide el procesamiento simultáneo de un segundo atributo del mismo estímulo.',
            'Tiempo de reacción para inhibir la interferencia cognitiva causada por la presentación de un segundo atributo asociado a un mismo estímulo.']
    },
    DICE: { traits: ['Capacidad para realizar varias tareas a la vez', 'Rapidez para realizar varias tareas a la vez', 'Facilidad para realizar varias tareas a la vez'],
        traitDesc: ['Habilidad para lidiar efectivamente con múltiples demandas de tareas. También conocida como capacidad de multitasking.',
            'Tiempo de reacción asociado a la realización efectiva de varias tareas a la vez.',
            'Facilidad para realizar efectivamente una sola tarea en comparación con la facilidad para realizar efectivamente varias tareas.']
    },
    DCE2: { traits: ['Capacidad para realizar varias tareas a la vez', 'Rapidez para realizar varias tareas a la vez'],
    traitDesc: ['Habilidad para lidiar efectivamente con múltiples demandas de tareas. También conocida como capacidad de multitasking.',
        'Tiempo de reacción asociado a la realización efectiva de varias tareas a la vez.']
    },
    DICT: { traits: ['Altruismo:'],
        traitDesc: ['Grado en el que una persona tiende a procurar el bien de otras personas de manera desinteresada, incluso a costa de su propio interés.'] 
    },
    DCT2: { traits: ['Altruismo:'],
        traitDesc: ['Grado en el que una persona tiende a procurar el bien de otras personas de manera desinteresada, incluso a costa de su propio interés.']
    },
    TEMO: { traits: ['Habilidad para reconocer emociones'],
        traitDesc: ['Habilidad para identificar e interpretar correctamente la emoción que está sintiendo una persona a partir de su expresión facial.'] 
    },
    TEM2: { traits: ['Habilidad para reconocer emociones'],
        traitDesc: ['Habilidad para identificar e interpretar correctamente la emoción que está sintiendo una persona a partir de su expresión facial.'] 
    },
    BART: { traits: ['Disposición al riesgo'],
        traitDesc: ['Tendencia a elegir o evitar opciones que conllevan un riesgo de perder algo. Los comportamientos de riesgo tienen asociado un cierto peligro o daño, mientras que al mismo tiempo también brindan la oportunidad de obtener algún tipo de recompensa.']
    },
    BAR2: { traits: ['Disposición al riesgo'],
        traitDesc: ['Tendencia a elegir o evitar opciones que conllevan un riesgo de perder algo. Los comportamientos de riesgo tienen asociado un cierto peligro o daño, mientras que al mismo tiempo también brindan la oportunidad de obtener algún tipo de recompensa.']
    },
    TLET: { traits: ['Razonamiento verbal'],
        traitDesc: ['Se refiere a la capacidad para entender la comunicación verbal y escrita. Comprende la capacidad de entender las palabras y las frases, así como la identificación de relaciones entre palabras. No solo requiere poder entender el significado de las palabras en general, sino también cuáles son sus significados dentro de un contexto.']
    },
    TLE2: { traits: ['Razonamiento verbal'],
        traitDesc: ['Se refiere a la capacidad para entender la comunicación verbal y escrita. Comprende la capacidad de entender las palabras y las frases, así como la identificación de relaciones entre palabras. No solo requiere poder entender el significado de las palabras en general, sino también cuáles son sus significados dentro de un contexto.']
    },
    TLOG: { traits: ['Razonamiento lógico'],
        traitDesc: ['Se refiere al entendimiento y análisis de información visual y la resolución de problemas que contienen símbolos o figuras. Comprende la capacidad para razonar lógicamente e identificar patrones y similitudes entre elementos.']
    },
    TLO2: { traits: ['Razonamiento lógico'],
        traitDesc: ['Se refiere al entendimiento y análisis de información visual y la resolución de problemas que contienen símbolos o figuras. Comprende la capacidad para razonar lógicamente e identificar patrones y similitudes entre elementos.']
    },
    DGTS: { traits: ['Memoria de corto plazo'],
        traitDesc: ['Capacidad para mantener la atención en una tarea durante un período de tiempo determinado.'] 
    },
    MEM2: { traits: ['Memoria de corto plazo'],
    traitDesc: ['Capacidad para mantener la atención en una tarea durante un período de tiempo determinado.'] 
    },
    TNUM: { traits: ['Razonamiento numérico'],
        traitDesc: ['Se refiere a la velocidad y precisión para resolver problemas aritméticos simples que involucran el uso de operaciones matemáticas. Asimismo, comprende la capacidad para realizar inferencias y tomar decisiones a partir de datos numéricos o estadísticos.'] 
    },
    TNU2: { traits: ['Razonamiento numérico'],
        traitDesc: ['Se refiere a la velocidad y precisión para resolver problemas aritméticos simples que involucran el uso de operaciones matemáticas. Asimismo, comprende la capacidad para realizar inferencias y tomar decisiones a partir de datos numéricos o estadísticos.'] 
    },
    THEX: { traits: ['Apertura a la experiencia', 'Escrupulosidad - Meticulosidad', 'Cordialidad', 'Extraversión', 'Honestidad - Humildad', 'Emocionalidad'],
        traitDesc: ['Las personas con puntajes muy altos en esta escala quedan absortos con la belleza del arte y la naturaleza, tienen curiosidad sobre diversas áreas del conocimiento, usan su imaginación libremente en el día a día y se interesan por ideas o personas inusuales. Por el contrario, las personas con puntajes muy bajos en esta escala no se impresionan con la mayoría de las obras de arte, sienten poca curiosidad intelectual, evitan las actividades creativas y sienten poca atracción por las ideas que puedan parecer radicales o poco convencionales.',
            'Las personas con puntajes muy altos en esta escala organizan su tiempo y su entorno físico, trabajan de manera disciplinada para alcanzar sus metas, se esfuerzan por lograr la precisión y perfección de las tareas que realizan y reflexionan cuidadosamente al tomar decisiones. Por el contrario, las personas con puntajes muy bajos en esta escala tienden a no estar preocupadas por los entornos ordenados y los horarios, evitan tareas difíciles o metas desafiantes, están satisfechas con trabajos que contienen algunos errores y toman decisiones impulsivas o con poca reflexión.',
            'Las personas con puntajes muy altos en esta escala perdonan los males que sufrieron, son indulgentes al juzgar a los demás, están dispuestas a comprometerse y cooperar con otros, y pueden controlar fácilmente su genio. Por el contrario, las personas con puntajes muy bajos en esta escala guardan rencor contra quienes las han dañado, son bastante críticas con los defectos de los demás, son tercas en la defensa de su punto de vista y se enojan rápidamente en respuesta al maltrato.',
            'Las personas con puntajes muy altos en la escala de Extraversión tienen una visión positiva sobre sí mismas, se sienten seguras cuando lideran o se dirigen a grupos de personas, disfrutan las reuniones e interacciones sociales y experimentan sentimientos positivos de entusiasmo y energía. Por el contrario, las personas con puntajes muy bajos en esta escala se consideran a sí mismas impopulares, se sienten incómodas cuando son el centro de la atención social, son indiferentes a las actividades sociales y se sienten menos animadas y optimistas que otras personas.',
            'Las personas con puntajes muy altos en la escala de Honestidad-Humildad evitan manipular a otros para obtener un beneficio personal, sienten una tentación leve por romper las reglas, no se interesan en tener una gran riqueza y lujos, y no sienten que tengan un derecho especial para elevar su estatus social. Por el contrario, las personas con puntajes muy bajos en esta escala adularán a los demás para obtener lo que desean, están inclinadas a romper las reglas para su beneficio personal, están motivadas por obtener ganancias materiales y exageran sobre su propia importancia.',
            'Las personas con puntajes muy altos en la escala de Emocionalidad experimentan miedo frente a los peligros físicos, experimentan ansiedad como respuesta al estrés de la vida, sienten la necesidad de apoyo emocional de los demás y sienten empatía y vínculos sentimentales con los demás. Por el contrario, las personas con puntuaciones muy bajas en esta escala no se ven desalentadas por la posibilidad de sufrir un daño físico, sienten poca preocupación inclusive en situaciones estresantes, tienen poca necesidad de compartir sus preocupaciones con otros y se sienten emocionalmente distantes de los demás.']
    },
    THE2: { traits: ['Apertura a la experiencia', 'Escrupulosidad - Meticulosidad', 'Cordialidad', 'Extraversión', 'Honestidad - Humildad', 'Emocionalidad'],
        traitDesc: ['Las personas con puntajes muy altos en esta escala quedan absortos con la belleza del arte y la naturaleza, tienen curiosidad sobre diversas áreas del conocimiento, usan su imaginación libremente en el día a día y se interesan por ideas o personas inusuales. Por el contrario, las personas con puntajes muy bajos en esta escala no se impresionan con la mayoría de las obras de arte, sienten poca curiosidad intelectual, evitan las actividades creativas y sienten poca atracción por las ideas que puedan parecer radicales o poco convencionales.',
            'Las personas con puntajes muy altos en esta escala organizan su tiempo y su entorno físico, trabajan de manera disciplinada para alcanzar sus metas, se esfuerzan por lograr la precisión y perfección de las tareas que realizan y reflexionan cuidadosamente al tomar decisiones. Por el contrario, las personas con puntajes muy bajos en esta escala tienden a no estar preocupadas por los entornos ordenados y los horarios, evitan tareas difíciles o metas desafiantes, están satisfechas con trabajos que contienen algunos errores y toman decisiones impulsivas o con poca reflexión.',
            'Las personas con puntajes muy altos en esta escala perdonan los males que sufrieron, son indulgentes al juzgar a los demás, están dispuestas a comprometerse y cooperar con otros, y pueden controlar fácilmente su genio. Por el contrario, las personas con puntajes muy bajos en esta escala guardan rencor contra quienes las han dañado, son bastante críticas con los defectos de los demás, son tercas en la defensa de su punto de vista y se enojan rápidamente en respuesta al maltrato.',
            'Las personas con puntajes muy altos en la escala de Extraversión tienen una visión positiva sobre sí mismas, se sienten seguras cuando lideran o se dirigen a grupos de personas, disfrutan las reuniones e interacciones sociales y experimentan sentimientos positivos de entusiasmo y energía. Por el contrario, las personas con puntajes muy bajos en esta escala se consideran a sí mismas impopulares, se sienten incómodas cuando son el centro de la atención social, son indiferentes a las actividades sociales y se sienten menos animadas y optimistas que otras personas.',
            'Las personas con puntajes muy altos en la escala de Honestidad-Humildad evitan manipular a otros para obtener un beneficio personal, sienten una tentación leve por romper las reglas, no se interesan en tener una gran riqueza y lujos, y no sienten que tengan un derecho especial para elevar su estatus social. Por el contrario, las personas con puntajes muy bajos en esta escala adularán a los demás para obtener lo que desean, están inclinadas a romper las reglas para su beneficio personal, están motivadas por obtener ganancias materiales y exageran sobre su propia importancia.',
            'Las personas con puntajes muy altos en la escala de Emocionalidad experimentan miedo frente a los peligros físicos, experimentan ansiedad como respuesta al estrés de la vida, sienten la necesidad de apoyo emocional de los demás y sienten empatía y vínculos sentimentales con los demás. Por el contrario, las personas con puntuaciones muy bajas en esta escala no se ven desalentadas por la posibilidad de sufrir un daño físico, sienten poca preocupación inclusive en situaciones estresantes, tienen poca necesidad de compartir sus preocupaciones con otros y se sienten emocionalmente distantes de los demás.']
    },
    EFRT: { traits: ['Motivación por las recompensas', 'Disposición para realizar esfuerzo'],
        traitDesc: ['Preferencia por opciones en donde se pueda alcanzar una alta recompensa aun cuando ésta tenga asociada la necesidad de invertir un gran esfuerzo.',
            'Disposición basal frente al esfuerzo independientemente de las recompensas asociadas a la consecución de una tarea.'] 
    },
    EFR2: { traits: ['Motivación por las recompensas', 'Disposición para realizar esfuerzo'],
        traitDesc: ['Preferencia por opciones en donde se pueda alcanzar una alta recompensa aun cuando ésta tenga asociada la necesidad de invertir un gran esfuerzo.',
            'Disposición basal frente al esfuerzo independientemente de las recompensas asociadas a la consecución de una tarea.'] 
    },
    GNG: { traits: ['Impulsividad', 'Duración de la atención'],
        traitDesc: ['Tendencia a reaccionar de forma rápida e inmediata ante estímulos o situaciones sin una reflexión previa.',
            'Capacidad para mantener la atención en una tarea durante un período de tiempo específico.'] 
    },
    GNG2: { traits: ['Impulsividad', 'Duración de la atención'],
        traitDesc: ['Tendencia a reaccionar de forma rápida e inmediata ante estímulos o situaciones sin una reflexión previa.',
            'Capacidad para mantener la atención en una tarea durante un período de tiempo específico.'] 
    },
    TOWR: { traits: ['Resolución de problemas', 'Capacidad de planificación', 'Enfoque para enfrentar un problema'],
        traitDesc: ['Proceso mediante el cual se abordan las dificultades, los obstáculos o los eventos estresantes para lograr un objetivo. La persona debe elegir una estrategia de solución para enfrentar un problema que muchas veces es nuevo o desconocido.',
            'Capacidad de pensar en el futuro o anticipar mentalmente la forma correcta de llevar a cabo una tarea o alcanzar un objetivo específico. Esta habilidad permite elegir las acciones necesarias para alcanzar una meta, decidir el orden correcto, asignar a cada tarea a los recursos cognitivos adecuados y establecer un plan de acción.',
            'Preferencia por un enfoque a la hora de enfrentar un problema. Hay personas que se enfocan principalmente en la ejecución de la solución, mientras que hay otras que se enfocan principalmente en la planificación de la solución.'] 
    },
    TOW2: { traits: ['Resolución de problemas', 'Capacidad de planificación'],
        traitDesc: ['Proceso mediante el cual se abordan las dificultades, los obstáculos o los eventos estresantes para lograr un objetivo. La persona debe elegir una estrategia de solución para enfrentar un problema que muchas veces es nuevo o desconocido.',
            'Capacidad de pensar en el futuro o anticipar mentalmente la forma correcta de llevar a cabo una tarea o alcanzar un objetivo específico. Esta habilidad permite elegir las acciones necesarias para alcanzar una meta, decidir el orden correcto, asignar a cada tarea a los recursos cognitivos adecuados y establecer un plan de acción.',] 
    },
    SJIN: { traits: ['Situaciones de emprendedor'],
        traitDesc: ['']
    },
    TPCG: {
        traits: ['Neuroticismo', 'Extraversión', 'Apertura a la experiencia', 'Amabilidad', 'Responsabilidad',],
        traitDesc: [
            'Las personas con puntajes altos en la dimensión de neuroticismo pueden experimentar principalmente un sentimiento negativo específico como ansiedad, ira o depresión. Se caracterizan por ser personas emocionalmente reactivas. Por el contrario, las personas con puntajes bajos en esta escala se alteran en menor medida y son menos reactivas emocionalmente. Tienden a estar tranquilas, emocionalmente estables y libres de sentimientos negativos persistentes.', 
            'Las personas con puntajes altos en la dimensión de extraversión disfrutan estar con gente, están llenos de energía y frecuentemente experimentan emociones positivas. Suelen ser personas entusiastas, orientadas a la acción, tendientes a seguir oportunidades emocionantes. Por el contrario, las personas con puntajes bajos en esta dimensión carecen de la exuberancia, la energía y un alto nivel de actividad. Tienden a ser tranquilos, discretos y desconectados del mundo social. Disfrutan la soledad y las actividades solitarias y su socialización tiende a estar reducida a unos pocos amigos cercanos.', 
            'Las personas con puntajes altos en esta dimensión son intelectualmente curiosas, aprecian el arte, son sensibles a la belleza y tienden a ser más conscientes de sus sentimientos. Asimismo, son más propensas a pensar y actuar de una manera individualista e inconformista. Por el contrario, las personas con puntajes bajos en esta dimensión tienden a tener intereses comunes y acotados. Prefieren lo sencillo, directo y obvio por sobre lo complejo, ambiguo y sutil. Son propensas a mirar el arte y la ciencia con sospecha considerando estas actividades como difíciles de entender o sin un uso práctico. Del mismo modo, prefieren la familiaridad a la novedad, son conservadoras y resistentes al cambio.', 
            'Las personas con puntajes altos en esta dimensión valoran llevarse bien con los demás. Por lo tanto, son amables, consideradas, generosas, serviciales y están dispuestas a llegar a un equilibrio entre sus intereses y los de los demás. Estas personas tienen una visión optimista de la naturaleza humana. Por el contrario, las personas con puntajes bajos en esta dimensión colocan su propio interés por sobre el llevarse bien con los demás. Por lo general, no les preocupa el bienestar de los demás y, por lo tanto, es poco probable que se entreguen por otras personas.', 
            'Las personas con puntajes altos en esta dimensión tienden a trabajar duro para llevar a cabo sus planes, evitan problemas y alcanzan altos niveles de éxito a través de una planificación decidida y gracias a un alto grado de perseverancia. Suelen ser ordenadas, organizadas y son consideradas por los demás como confiables y trabajadoras. Por el contrario, las personas con puntajes bajos en esta dimensión tienden a estar menos interesadas en las metas de largo plazo y más interesadas en vivir al momento. Suelen cambiar de rumbo y se distraen con facilidad. En general son desorganizadas y realizan tareas de manera descuidada.',
        ],
    },
    CFIT: {
        traits: ['Individual / Grupal', 'Estructurado / Flexible', 'Jerárquico / Horizontal', 'Formal / Casual'],
        traitDesc: [
            'Grado en que el evaluado(a) prefiere esforzarse para alcanzar metas individuales o, por el contrario, trabajar de forma colaborativa con otras personas.',
            'Grado de preferencia por trabajar en un ambiente altamente estructurado o, por el contrario, en uno donde se puede innovar y  tomar riesgos.',
            'Grado de preferencia por trabajar en una cultura donde los roles definen la capacidad para tomar de decisiones o, por el contrario, donde todos los integrantes son llamados a participar en ello.',
            'Grado de preferencia por trabajar en una cultura donde los roles definen la capacidad para tomar de decisiones o, por el contrario, donde todos los integrantes son llamados a participar en ello.'
        ]
    }
};

export default gamesDescription;
