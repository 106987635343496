import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import * as actionCreators from '../../../../actions/data';

import {
  Typography,
  Button,
  Icon,
  Tooltip,
  Form,
  Input,
  Modal,
  message
} from 'antd';
import MceEditor from '../../NewProcessFormV2/components/mceeditor/MceEditor';


const EditTemplate = (props) => {

  const { Title, Text } = Typography;

  const [error, setError] = useState(false);
  const [preview, setPreview] = useState(false);
  const [editTemplate, setEditTemplate] = useState({
    business_user_id: '',
    id: null,
    name: '',
    subject: '',
    template: '',
    readOnlyBody: ''
  })

  const getTemplateToEdit = async () => {
    try {
      const url = `accounts/business/${props.business.id}/mailtemplates/${props.match.params.templateid}/`
      const token = props.token;

      props.actions.fetchData(token, url, '', 'get').then((data) => {
        setEditTemplate(data)
      })
    } catch (error) {
      setError(true)
    }
  };

  useEffect(() => {
    getTemplateToEdit()
  }, [])

  const handleChange = (name) => (event) => {
    setEditTemplate({
      ...editTemplate,
      [name]: event.target.value
    })
  };

  const navigateToEmailTemplates = () => {
    props.dispatch(push('/emailtemplates'))
  };

  const showConfirm = () => {
    Modal.confirm({
      centered: true,
      title: 'Tienes cambios sin guardar. ¿Estás seguro de continuar?',
      okText: 'Confirmar',
      cancelText: 'Cancelar',
      onOk: () => navigateToEmailTemplates(),
    })
  };

  const handleSubmitEdit = (e) => {
    e.preventDefault()

    const url = `accounts/business/${props.business.id}/mailtemplates/${props.match.params.templateid}/`
    const token = props.token;

    const data = {
      template: editTemplate.template,
      name: editTemplate.name,
      subject: editTemplate.subject,
    }

    props.actions.sendData(token, url, JSON.stringify(data), 'PATCH').then(() => {
      message.success('La plantilla se editó correctamente', 3)
      navigateToEmailTemplates()
    })
  };

  const insertKey = (type) => {
    switch (type) {
      case 'name':
        window.tinymce.activeEditor.execCommand('mceInsertContent', false, " --name-- ");
        return;
      case 'processName':
        window.tinymce.activeEditor.execCommand('mceInsertContent', false, " --processName-- ");
        return;
    }
  }

  const previewTemplate = () => {
    setPreview(true);
    const readOnlyBody = editTemplate.template.replace(/(--name--)+/g, 'NombrePersona')
      .replace(/(--processName--)+/g, 'NombreProceso');
    setEditTemplate({
      ...editTemplate,
      readOnlyBody
    })
  };

  const handleCancel = () => {
    setPreview(false)
  };

  const { getFieldDecorator } = props.form;

  return (
    <div className='template-crud'>
      <div className='template-crud__header'>
        <Tooltip title='Volver' >
          <Button
            type='primary'
            size='small'
            onClick={showConfirm}
          ><Icon type="left" />
          </Button>
        </Tooltip>
        <Title level={3}>Editar plantilla</Title>
      </div>

      <div className='template-crud__body'>
        <Form onSubmit={handleSubmitEdit}>
          <Form.Item label='Nombre de la plantilla'>
            {getFieldDecorator('name', {
              initialValue: editTemplate.name,
              onChange: handleChange('name'),
            })(
              <Input
                placeholder="Nombre plantilla"
              />,
            )}
          </Form.Item>

          <Form.Item label='Asunto' >
            {getFieldDecorator('subject', {
              initialValue: editTemplate.subject,
              onChange: handleChange('subject'),
              rules: [
                {
                  max: 199,
                  message: 'Máximo 200 caracteres'
                },
                {
                  required: true,
                  message: 'Ingresa un asunto para la plantilla'
                }],
            })(
              <Input
                maxLength={200}
                placeholder="Asunto"
              />,
            )}
          </Form.Item>

          {
            editTemplate.template ? (<MceEditor name='template' readOnlyBody={editTemplate.readOnlyBody} handleChange={handleChange} body={editTemplate.template} />) : null
          }

          <div className='template-crud__buttons'>
            <Form.Item  >
              <Button onClick={() => insertKey('name')} >
                Insertar nombre
             </Button>
              <Button onClick={() => insertKey('processName')} >
                Insertar nombre proceso
             </Button>
            </Form.Item>
            <Form.Item>
              <Button type='secondary' onClick={previewTemplate} >Vista previa</Button>
            </Form.Item>
            <Form.Item  >
              <Button type='primary' htmlType='submit' >
                Guardar cambios
             </Button>
            </Form.Item>
          </div>
        </Form>
        <Modal
          title={<Text strong>Visualización de la plantilla</Text>}
          visible={preview}
          className='modal-preview'
          onCancel={handleCancel}
          footer={[
            <Button key='1' onClick={handleCancel} type='primary' >
              Volver
            </Button>
          ]}
        >
          {editTemplate.template !== undefined ? <MceEditor name='body' body={editTemplate.template} handleChange={handleChange}  disabled={preview} readOnlyBody={editTemplate.readOnlyBody} /> : null}
        </Modal>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    token: state.auth.token,
    business: state.auth.currentBusiness,
  }
}

const EditTemplateView = Form.create()(EditTemplate);
export default connect(mapStateToProps, mapDispatchToProps)(EditTemplateView);