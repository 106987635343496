import React, { useState, useEffect, useMemo } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";
import PropTypes from "prop-types";

import * as authActionCreators from "../../actions/auth";
import * as dataActionCreators from "../../actions/data";

import CandidateLoginLayout from "./components/CandidateLoginLayout";
import LoginForm from "./components/LoginForm";
import SocialLogin from "./components/SocialLogin";

import { Row, Col, Typography, Icon, Button, Divider } from 'antd';
import './Login.scss';
import GenominLoader from '../../utils/GenominLoader';
import i18n from '../../i18n';

function CandidateLoginView(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [customStyles, setCustomStyles] = useState({
    background: null,
    colors: {
      background: "#F175A5",
      color: "#FFFFFF",
    },
  });
  const qs = props.location.search;

  const handleLoading = () => setIsLoading((bool) => !bool);

  const handleGoBack = () => {
    props.dispatch(push("/"));
  };

  useEffect(() => {
    if (props.isAuthenticated) {
      const url = props.userType === "personal" ? "/me" : "/processes";
      props.dispatch(push(url));
    }

    const slug = props.match.params["businessuserslug"];

    const getStyles = async (slug, callback) => {
      try {
        const data = JSON.stringify({ business_user: slug });
        const url = `accounts/business/${slug}/`;
        const styles = await props.dataActions.fetchData("", url, data, "get");

        if (styles) {
          props.authActions.updateLogo(styles.logo);
          setCustomStyles({
            background: (
              <img
                src={styles.jobs_site_background_image}
                alt='branded background'
                className='branded-img'
              />
            ),
            colors: styles.color_theme,
          });
        }
        callback();
      } catch (e) {
        console.error(e);
      }
    };
    slug ? getStyles(slug, handleLoading) : handleLoading();
  }, []);

  const extractRedirect = (string) => {
    const match = string.match(/next=(.*)/);
    return match ? `?next=${match[1]}` : '';
  };

  const redirect = extractRedirect(props.location.search);
  return (
    <>
      <GenominLoader isLoading={isLoading} customClass='login-form-genomin' />
      {!isLoading && (
        <CandidateLoginLayout rightSide={customStyles.background}>
          <Col xs={24} sm={12} className='candidate-login__left-side'>
            <Row
              className='candidate-login__left-side__header'
              style={{ height: "12em" }}
            >
              <Button onClick={handleGoBack} type='link'>
                <Icon type='left'></Icon>
              </Button>
              <Typography.Title level={4}>{i18n.t('commons__candidates').toUpperCase()}</Typography.Title>
              <Row>
                <Typography.Title level={1}>{i18n.t('commons__sign_in')}</Typography.Title>
              </Row>
            </Row>
            <Row className='candidate-login__left-side__social-login'>
              <Col xs={24}>
                <SocialLogin location={props.location} />
                <Divider style={{ color: '#786D73', fontWeight: '300' }}>
                  {i18n.t('login__email__login')}
                </Divider>
              </Col>
            </Row>
            <Row className='candidate-login__left-side__login-form'>
              <Col xs={24}>
                <LoginForm redirect={`/tos${qs}`} colors={customStyles.colors} />
              </Col>
            </Row>
          </Col>
        </CandidateLoginLayout>
      )}
    </>
  );
}

CandidateLoginView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isAuthenticating: PropTypes.bool.isRequired,
  statusText: PropTypes.string,
  authActions: PropTypes.shape({
    authLoginUser: PropTypes.func.isRequired,
  }).isRequired,
  dataActions: PropTypes.any,
  alerts: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isAuthenticating: state.auth.isAuthenticating,
    statusText: state.auth.statusText,
    alerts: state.alerts,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    authActions: bindActionCreators(authActionCreators, dispatch),
    dataActions: bindActionCreators(dataActionCreators, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CandidateLoginView);
export { CandidateLoginView as CandidateLoginViewNotConnected };
