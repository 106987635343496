import { Icon, Tooltip } from 'antd';

import React from 'react';
import i18n from '../../../i18n';

const GameBadge = ({ game }) => {
  const isFinished = game.status === 'FINISHD';

  const icon = {
    type: isFinished ? 'check-circle' : 'close-circle',
    style: {
      color: isFinished ? '#52C41A' : '#FF4D4F',
    },
  };

  const getGameImg = () => {
    const assetUrl = 'https://genoma-assets.s3.us-east-2.amazonaws.com/';

    switch (game.code) {
      case 'LEAD':
        return `${assetUrl}${game.code.trim()}.svg`;
      case 'CUTE':
      case 'TPCG':
      case 'THEX':
      case 'THE2':
        return `${assetUrl}Personalidad.png`;
      default:
        return `${assetUrl}${game.code.trim()}.png`;
    }
  };

  return (
    <Tooltip placement="topLeft" title={game.translation[i18n.language]}>
      <div className="game-badge">
        <img
          src={getGameImg()}
          alt="game icon"
          style={{
            filter: isFinished ? 'none' : 'grayscale(1)',
          }}
        />
        <Icon {...icon} theme="filled" />
      </div>
    </Tooltip>
  );
};

export default GameBadge;
