import {
  Badge,
  Button,
  Layout,
  Modal,
  Row,
  Spin,
  Tabs,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import {
  browserVersion,
  isChrome
} from 'react-device-detect'

import i18n from '../../../i18n';
import * as authActionCreators from '../../../actions/auth';
import * as dataActionCreators from '../../../actions/data';
import CandidatePathContent from './CandidatePathContent/CandidatePathContent';
import MessagesView from '../../../components/messaging/MessagesView';

import '../candidatepath/CandidatePath.scss';

const { Text } = Typography;

function CandidatePath(props) {
  const defaultUJAState = {
    backgroundImage: '',
    isLoading: true,
    jobappid: null,
    mounted: false,
    hasGamesResponses: false,
    recommendationsModal: false,
    videoDialog: false,
    userJobApp: null,
    chat: null,
    notifications: [],
    activities: [],
    logo: '',
    currentTab: '0',
    willMount: false,
  };

  const defaultJobAppState = {
    backgroundImage: '',
    businessSlug: '',
    colorTheme: null,
    isLoading: true,
    logo: '',
    jobApplication: { name: '' },
    isSeed: true,
  };
  const [ujaState, setUjaState] = useState(defaultUJAState);
  const [jobAppState, setJobAppState] = useState(defaultJobAppState);

  useEffect(() => {
    if (isChrome && parseInt(browserVersion) < 80) {
      Modal.error({
        centered: true,
        title: i18n.t('candidate_path__old__browser__title'),
        content: i18n.t('candidate_path__old__browser__content'),
        onOk: () => {
          window.location = 'https://www.google.com/intl/es-419/chrome/';
        },
      });
    }
    const token = props.token;
    setUjaState({ ...ujaState, jobappid: props.match.params.jobappid });
    let url = `/api/v1/gamesandtests/applications/${props.match.params.jobappid}/?format=json`;
    Object.keys(props.utm_params).map((field) => {
      url = url + `&utm_${field}=${props.utm_params[field]}`;
    });

    const url2 = `/api/v1/gamesandtests/jobapplications/${props.match.params.jobappid}/public/`;
    props.actions.fetchAndReturn(token, url, 'GET').then((response) => {
      const { body, status } = response;
      if (status >= 400) {
        props.dispatch(push(`/startprocess/${props.match.params.jobappid}`));
      }
      setUjaState((ujaState) => ({
        ...ujaState,
        isLoading: false,
        userJobApp: body.id,
        chat: body.chat,
        activities: body.activities,
        hasGamesResponses: body.can_see_feedback,
        willMount: true,
      }));

      // PU notifications of type MSG, not read
      const urlNotifications = `/api/v1/notifications/count/?notification_type=MSG&status=CREATED&job_app=${props.match.params.jobappid}`;
      props.actions.fetchAndReturn(token, urlNotifications, 'GET').then(response => {
        if (response.status < 400) {
          const notificationCountResponse = response.body ? response.body : [];
          setUjaState(ujaState => ({ ...ujaState, notifications: notificationCountResponse }))
        }
      });
      body.branches.forEach(branch => {
        props.actions.fetchAndReturn(token, `/api/v1/gamesandtests/applications/${branch}/?format=json`)
      });
      return true;
    });

    if (props.data.intro_video != null && props.data.intro_video.length > 0) {
      setUjaState({ ...ujaState, videoDialog: true });
    }
    props.actions.fetchAndReturn(token, url2, 'get').then((response) => {
      const { body } = response;
      // body.branches.forEach(branch => {
      //   props.actions.fetchAndReturn(token, `/api/v1/gamesandtests/applications/${branch}/?format=json`)
      // })
      setJobAppState(
        {
          ...jobAppState,
          jobApplication: { 'name': body.job_application },
          logo: body.business.logo,
          businessSlug: body.business.slug,
          colorTheme: body.business.color_theme,
          isSeed: body.is_seed,
          isLoading: false
        });
    });
  }, []);

  useEffect(() => {
    if (window.location.hash === '#messages' && ujaState.willMount === true) {
      setUjaState({ ...ujaState, currentTab: '1', willMount: false });
    }
  }, [ujaState.isLoading]);

  // Update on every on chage tab
  const updateChat = (tabKey) => {
    setUjaState({ ...ujaState, isLoading: true });
    const url = `gamesandtests/applications/${props.match.params.jobappid}/?format=json`;
    props.actions.fetchData(props.token, url, '', 'get').then((response) => {
      setUjaState({
        ...ujaState,
        isLoading: false,
        chat: response.chat,
        currentTab: tabKey,
      });
    });
  };

  const getNotificationCount = (notificationType) => {
    if (notificationType === 'MSG') {
      const notificationCount = ujaState.notifications.find(
        (obj) => obj.notification_type === 'MSG'
      )?.count;
      return notificationCount ? notificationCount : 0;
    }
    return 0;
  };

  const updateNotificationCount = () => {
    setUjaState({
      ...ujaState,
      notifications: [{ notification_type: 'MSG', count: 0 }],
    });
  };

  const findCode = () => {
    const { data } = props;
    const find = (code) => ujaState.activities.find((act) => act.code === code);
    const allGamesState = ujaState.activities
      .filter(
        (e) =>
          e.code !== 'KO' &&
          e.code !== 'FORM' &&
          e.code !== 'VINT' &&
          e.code !== 'CUTE' &&
          e.code !== 'INTR'
      )
      .map(({ status }) => status);

    return {
      ko: find('KO'),
      form: find('FORM'),
      vint: find('VINT'),
      allGames: allGamesState,
    };
  };

  // TODO: ver con que actualizarlo
  useEffect(() => {
    const { jobappId } = props;
    const { allGames } = findCode();

    if (
      ujaState.backgroundImage === '' &&
      props.data.background_image !== undefined &&
      props.data.background_image != ujaState.backgroundImage
    ) {
      setUjaState({
        ...ujaState,
        backgroundImage: props.data.background_image,
      });
    }
    if (props.data.logo !== null && props.logoURL !== props.data.logo) {
      props.authActions.updateLogo(props.data.logo);
    }
    if (allGames.every((x) => x === 'DONE')) {
      localStorage.removeItem(`gr_${jobappId}`);
    }
    if (!jobAppState.isSeed) {
      if (
        !ujaState.recommendationsModal &&
        allGames.length &&
        !allGames.includes('DONE')
      ) {
        openRecommendationsModal();
      }
    }
  }, [props]);

  const openRecommendationsModal = () => {
    const { jobappId } = props;
    const { ko, form } = findCode();

    if (localStorage.getItem(`gr_${jobappId}`) !== 'closed') {
      if ((!ko || ko.status === 'DONE') && (!form || form.status === 'DONE')) {
        setUjaState({ ...ujaState, recommendationsModal: true });
        localStorage.setItem(`gr_${jobappId}`, 'open');
      }
    }
  };

  const closeModal = () => {
    const { jobappId } = props;
    setUjaState({ ...ujaState, recommendationsModal: false });
    localStorage.setItem(`gr_${jobappId}`, 'closed');
  };

  const handleVideoDialog = () => {
    let v = !ujaState.videoDialog;
    setUjaState({ ...ujaState, videoDialog: v });
  };

  const embededTraduction = (youtube_link) => {
    if (youtube_link) {
      return youtube_link.replace('watch?v=', 'embed/');
    } else {
      return '';
    }
  };

  const getFeedbackSeed = () => {
    return jobAppState.isSeed && ujaState.activities.filter(obj=>obj.progress.status === 'FINISHD').length==ujaState.activities.length
  }

  return (
    <Layout className="candidate-path-layout">
      <Tabs
        activeKey={ujaState.currentTab}
        centered
        tabBarStyle={{ display: 'flex', justifyContent: 'center' }}
        style={{ width: '90%' }}
        destroyInactiveTabPane={true}
        onChange={(key) => {
          key && updateChat(key);
        }}
      >
        <Tabs.TabPane tab={i18n.t('candidate_path__activities')} key="0">
          <Row type="flex" align="top" justify="center">
            {ujaState.isLoading || jobAppState.isLoading ? (
              <Spin />
            ) : (
              <CandidatePathContent
                businessSlug={jobAppState.businessSlug}
                colorTheme={jobAppState.colorTheme}
                items={ujaState.activities}
                jobApplication={jobAppState.jobApplication}
                jobAppId={props.match.params.jobappid}
                src={jobAppState.logo}
                hasGamesResponses={ujaState.hasGamesResponses || getFeedbackSeed()}
              />
            )}
            <Modal
              width={780}
              visible={ujaState.videoDialog}
              onCancel={handleVideoDialog}
              footer={[
                <Button key="back" onClick={handleVideoDialog}>
                  {i18n.t('commons__close')}
                </Button>,
              ]}
            >
              <iframe
                width="720"
                height="480"
                src={embededTraduction(props.data.intro_video)}
                allowFullScreen
              ></iframe>
            </Modal>
            <Modal
              centered
              width={650}
              //footer={false}
              onCancel={closeModal}
              visible={ujaState.recommendationsModal}
              className="recommendations-modal"
              footer={[
                <Button
                  onClick={closeModal}
                  type="primary"
                  style={{
                    margin: 'auto',
                    fontSize: '16px',
                    padding: '7px 50px',
                    height: 'auto',
                  }}
                >
                  {i18n.t('commons__continue')}
                </Button>,
              ]}
            >
              <div style={{ fontSize: 15 }}>
                <div
                  style={{
                    margin: '10px auto 10px',
                    textAlign: 'center',
                  }}
                >
                  <img
                    width="110"
                    src={
                      'https://genoma-assets.s3.us-east-2.amazonaws.com/happy-genomin.png'
                    }
                    style={{
                      display: 'flex',
                      margin: 'auto',
                    }}
                  />
                  <Text
                    strong
                    style={{
                      fontSize: 18,
                    }}
                  >
                    {i18n.t('final__modal__congratulations')}
                  </Text>
                </div>
                <Text>{i18n.t('final__modal__paragraph__1')}</Text> <br />{' '}
                <br />
                <Text>{i18n.t('final__modal__paragraph__2')}</Text> <br />{' '}
                <br />
                <Text strong>
                  {i18n.t('final__modal__recommendations')}
                </Text>{' '}
                <br />
                <ul
                  style={{
                    listStyle: 'outside',
                    paddingLeft: '1em',
                  }}
                >
                  <li>
                    <Text>{i18n.t('final__modal__recommendations__1')}</Text>
                  </li>
                  <li>
                    <Text>{i18n.t('final__modal__recommendations__2')}</Text>
                  </li>
                  <li>
                    <Text>{i18n.t('final__modal__recommendations__3')}</Text>
                  </li>
                </ul>{' '}
                <br />
                <Text strong>{i18n.t('final__modal__Important')}</Text> <br />
                <Text>{i18n.t('final__modal__important__1')}</Text>
                <br />
                <br />
                <Text
                  type="secondary"
                  style={{
                    fontSize: 14,
                    lineHeight: 1,
                  }}
                >
                  {i18n.t('final__modal__footer')}
                </Text>
              </div>
            </Modal>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <Badge dot={true} count={getNotificationCount('MSG')}>
              <div style={{ fontSize: 16 }}>{i18n.t('commons__messages')}</div>
            </Badge>
          }
          key="1"
        >
          <MessagesView
            userType="personal"
            logoBusiness={props.logoURL}
            userJobApp={ujaState.userJobApp}
            chat={ujaState.chat}
            callback={updateNotificationCount}
          />
        </Tabs.TabPane>
      </Tabs>
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userId: state.auth.userID,
    data: state.data.data,
    utm_params: state.data.utm_params,
    jobappId: state.data.data.jobapp_id,
    logoURL: state.auth.logoURL,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(dataActionCreators, dispatch),
    authActions: bindActionCreators(authActionCreators, dispatch),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidatePath);
