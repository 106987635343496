import React, { createContext, useEffect, useMemo, useReducer, useState } from 'react';
import * as Sentry from '@sentry/browser';

import CandidatePoolReducer from './CandidatePoolReducer';
import { buildCustomFieldsColumns, columns } from '../columns';

export const CandidatePoolContext = createContext();

const initialState = {
  // Candidates
  candidates: [],
  customFieldFilePreviewFlag: false,
  previewCv: null,
  selectedCandidates: {},
  selectedFileId: null,
  totalCandidates: 0,
  // Single candidate
  openCandidate: null,
  candidateData: {},
  // Columns
  columns: [],
  columnsShown: [
    'name',
    'email',
    'cv',
    'candidate_type',
    'tags',
    'experience',
    'education_level',
  ],
  customFieldsColumns: {},
  tableColumns: [],
  // Filters
  filterQuery: '',
  filtersConfig: {
    tags: {
      icon: 'tags',
      selectLabel: 'Etiquetas',
      dropdownVisible: true,
      type: '',
      value: null,
    },
    email: {
      icon: 'mail',
      selectLabel: 'Correo electrónico',
      dropdownVisible: true,
      type: '',
      value: null,
    },
    education_level: {
      icon: 'read',
      selectLabel: 'Nivel educacional',
      dropdownVisible: true,
      type: '',
      value: null,
    },
    career: {
      icon: 'bank',
      selectLabel: 'Carrera',
      dropdownVisible: true,
      type: '',
      value: null,
    },
    experience: {
      icon: 'file-search',
      selectLabel: 'Experiencia laboral',
      dropdownVisible: true,
      type: '_position_contains',
      value: null,
    },
    years_of_experience: {
      icon: 'number',
      selectLabel: 'Años de experiencia',
      dropdownVisible: true,
      type: '',
      value: null,
    },
    candidate_type: {
      icon: 'team',
      selectLabel: 'Tipo de candidato',
      dropdownVisible: true,
      type: '',
      value: null,
    }
  },
  selectedFilters: [],
  // PassiveCandidates Modals
  addPassiveCandidateToJobApplications: false,
  addPassiveCandidateVisible: false,
  addPassiveCandidateVisibleMethod: 'POST',
  // Pagination
  page: 1,
  pageSize: 50,
  // Extra data
  businessCustomFields: undefined,
  countries: undefined,
  mailTemplates: [],
};

export const CandidatePoolProvider = ({ actions, business, token, children }) => {
  const [state, dispatch] = useReducer(CandidatePoolReducer, initialState);
  const [loading, setLoading] = useState(false);

  const shortDispatch = (type, payload) => dispatch({ type, payload });

  useEffect(() => {
    try {
      const url = `/api/v1/accounts/business/${business.id}/mailtemplates/`;
      actions
        .fetchAndReturn(token, url)
        .then((response) => shortDispatch('SET_MAIL_TEMPLATES', response.body.results));

      if (state.businessCustomFields === undefined) {
        const candidateCustomFieldsUrl = `/api/v1/gamesandtests/business/${business.id}/customfields/?field_type=CANDID&active=True`;
        actions
          .fetchAndReturn(token, candidateCustomFieldsUrl)
          .then((candidateCustomFieldsResponse) => {
            shortDispatch('SET_BUSINESS_CUSTOM_FIELDS', candidateCustomFieldsResponse.body.results);
          })
          .catch((e) => Sentry.captureException(e));
      }

      if (state.countries === undefined) {
        const countriesUrl = '/api/v1/constants/countries/';
        actions.fetchAndReturn(token, countriesUrl)
          .then((countriesResponse) => {
            shortDispatch('SET_COUNTRIES', countriesResponse.body);
          })
          .catch((e) => Sentry.captureException(e));
      }

      shortDispatch('SET_TABLE_COLUMNS', columns(setOpenCandidate, setPreviewCv));
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [business]);

  useEffect(() => {
    const { columnsShown, customFieldsColumns, tableColumns } = state;
    const fixedColumnsArray = tableColumns.filter(({ key }) => columnsShown.includes(key));
    const customFieldsColumnsArray = Object.entries(customFieldsColumns).map(
      (entry) => {
        const [customFieldKey, customFieldData] = entry;
        return buildCustomFieldsColumns(
          customFieldKey,
          customFieldData, 
          setSelectedFileId,
          setCustomFieldFilePreviewFlag,
        );
      }
    );
    shortDispatch('SET_COLUMNS', fixedColumnsArray.concat(customFieldsColumnsArray));
  }, [state.tableColumns, state.columnsShown, state.customFieldsColumns]);

  useEffect(() => {
    getCandidatesPage();
  }, [state.page, state.pageSize, state.filterQuery]);

  useEffect(() => {
    buildFilterQuery();
  }, [state.selectedFilters]);

  const buildFilterQuery = () => {
    const { filtersConfig, selectedFilters } = state;
    const query = selectedFilters
      .map((filterKey) => {
        const { type, value } = filtersConfig[filterKey];
        if (value === null) return '';
        if (!type.includes('.')) {
          return `${filterKey}${type}=${Array.isArray(value) ? `[${value.join(',')}]` : value}&`;
        }
        return `${type
          .split('.')
          .map((subtype, index) => `${filterKey}${subtype}=${value[index]}`)
          .join('&')}&`;
      })
      .join('');
    shortDispatch('SET_FILTER_QUERY', query);
  };

  const getCandidatesPage = async () => {
    setLoading(true);
    const { filterQuery, page, pageSize } = state;
    try {
      const endpoint = `/api/v2/business/candidates/?${filterQuery}page=${page}&page_size=${pageSize}`;
      const response = await actions.fetchAndReturn(token, endpoint);
      shortDispatch('SET_CANDIDATES', {
        candidates: response.body.results.map((x) => ({ ...x, key: x.id })),
        totalCandidates: Math.ceil(response.body.count),
      });
    } catch (err) {
      Sentry.captureException(err);
      shortDispatch('SET_CANDIDATES', { candidates: [], totalCandidates: 0 });
    }
    setLoading(false);
  };

  const loadCandidateData = async () => {
    if (!state.openCandidate) return;

    try {
      setCandidateData({});
      const endpoint = `/api/v2/business/candidates/${state.openCandidate.id}/`;
      const response = await actions.fetchAndReturn(token, endpoint, 'GET');
      setCandidateData(response.body);
      const { phone_prefix, phone, country, other_contact_info } = response.body.passive_candidate ?? response.body.personal_user;
      setOpenCandidate({
        ...state.openCandidate,
        phone_prefix,
        phone,
        country,
        other_contact_info: { ...other_contact_info },
        applications: response.body.applications,
      });
      return;
    } catch (err) {
      Sentry.captureException(err);
      return;
    }
  };

  const setCandidateData = (candidate) => shortDispatch('SET_CANDIDATE_DATA', candidate);

  const setColumnsShown = (columnsShown) => shortDispatch('SET_COLUMNS_SHOWN', columnsShown)

  const handleColumnsSelection = (key) => (
    state.columnsShown.includes(key)
      ? setColumnsShown(state.columnsShown.filter((columnKey) => columnKey !== key))
      : setColumnsShown([...state.columnsShown, key])
  );

  const setCustomFieldFilePreviewFlag = (customFieldFilePreviewFlag) => shortDispatch(
    'SET_CUSTOM_FIELD_FILE_PREVIEW_FLAG', customFieldFilePreviewFlag
  );

  const setOpenCandidate = (candidate) => shortDispatch('SET_OPEN_CANDIDATE', candidate);

  const setPreviewCv = (cvUrl) => shortDispatch('SET_PREVIEW_CV', cvUrl);

  const setSelectedFileId = (fileId) => shortDispatch('SET_SELECTED_FILE_ID', fileId);

  const memoValues = useMemo(
    () => ({
      ...state,
      buildFilterQuery,
      getCandidatesPage,
      handleAddPassiveCandidateToJobApplications: {
        on: () => shortDispatch('SET_ADD_PASSIVE_CANDIDATE_TO_JOB_APPLICATIONS', true),
        off: () => shortDispatch('SET_ADD_PASSIVE_CANDIDATE_TO_JOB_APPLICATIONS', false),
        toggle: () => shortDispatch(
          'SET_ADD_PASSIVE_CANDIDATE_TO_JOB_APPLICATIONS',
          !state.addPassiveCandidateToJobApplications
        ),
      },
      handleAddPassiveCandidateVisible: {
        on: () => shortDispatch('SET_ADD_PASSIVE_CANDIDATE_VISIBLE', true),
        off: () => shortDispatch('SET_ADD_PASSIVE_CANDIDATE_VISIBLE', false),
        toggle: () => shortDispatch(
          'SET_ADD_PASSIVE_CANDIDATE_VISIBLE', !state.addPassiveCandidateVisible
        ),
      },
      handleColumnsSelection,
      loadCandidateData,
      loading,
      setAddPassiveCandidateVisibleMethod: (method) => shortDispatch(
        'SET_ADD_PASSIVE_CANDIDATE_VISIBLE_METHOD', method
      ),
      setCandidateData,
      setColumnsShown,
      setCustomFieldsColumns: (customFieldsColumns) => shortDispatch(
        'SET_CUSTOM_FIELDS_COLUMNS', customFieldsColumns
      ),
      setCustomFieldFilePreviewFlag,
      setFiltersConfig: (func) => shortDispatch('SET_FILTERS_CONFIG', func(state.filtersConfig)),
      setMailTemplates: (func) => shortDispatch('SET_MAIL_TEMPLATES', func(state.mailTemplates)),
      setOpenCandidate,
      setPage: (page) => shortDispatch('SET_PAGE', page),
      setPageSize: (pageSize) => shortDispatch('SET_PAGE_SIZE', pageSize),
      setPreviewCv,
      setSelectedCandidates: (selectedCandidatesObj) => shortDispatch(
        'SET_SELECTED_CANDIDATES', selectedCandidatesObj
      ),
      setSelectedFileId,
      setSelectedFilters: (selectedFilters) => shortDispatch(
        'SET_SELECTED_FILTERS', selectedFilters
      ),
    }), [loading, state],
  );

  return (
    <CandidatePoolContext.Provider value={memoValues}>
      {children}
    </CandidatePoolContext.Provider>
  );
};
