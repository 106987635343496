import React, { useContext, useEffect, useState } from 'react';
import {
  Dropdown,
  Row,
  Button,
  Menu,
  Tooltip,
  Icon,
  Modal,
  Col,
} from 'antd';

import { CandidateContext } from '../../config/CandidateContext';

import Collapse from '../Collapse';

import CustomTestsDetail from './CustomTestsDetail';
import ModalPdf from './ModalPdf';

import useBoolean from '../../../../hooks/useBoolean';
import i18n from '../../../../i18n'

const CustomTestView = () => {
  const { candidate, customTests, application, jobApp, permissions, resetCuteAnswer, translationFile } =
    useContext(CandidateContext);

  const [showCutePdf, setShowCutePdf] = useState({});
  const [openPanelKey, setOpenPanelKey] = useState([0]);
  const [downloadSummary, handleDownloadSummary] = useBoolean(false);

  const [showResetCuteAnswerModal, handleResetModal] = useBoolean(false);

  useEffect(() => {
    let tmpModals = {};
    customTests.forEach((cute) => (tmpModals[cute.name] = false));
    setShowCutePdf(tmpModals);
  }, []);

  const DownloadReportButtons = ({ type, cute, idx }) => {
    const menu = () => (
      <Menu>
        <Menu.Item key="0">
          <div
            onClick={(e) => {
              handleDownloadSummary.toggle();
              setShowCutePdf((oldState) => ({
                ...oldState,
                [cute.name]: true,
              }));
            }}
          >
            Descargar reporte
          </div>
        </Menu.Item>
        <Menu.Item key="1">
          <div
            onClick={() => {
              handleDownloadSummary.on();
              setShowCutePdf((oldState) => ({
                ...oldState,
                [cute.name]: true,
              }));
            }}
          >
            Descargar resumen
          </div>
        </Menu.Item>   
      </Menu>
    );
    return (
      <>
        {type === 'ALTERNATIVES' ? (
          <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
            <Button type="primary">Descargar reporte</Button>
          </Dropdown>
        ) : (
          <Button
            onClick={() =>
              setShowCutePdf((oldState) => ({ ...oldState, [cute.name]: true }))
            }
          >
            Descargar reporte
          </Button>
        )}
      </>
    );
  };

  const ResetCuteAnswerButton = ({ cute }) => {
    const hasPermissions = permissions.business_admin || permissions.role?.can_manage_userjobapplications;
    if (!hasPermissions) {
      return <></>;
    }
    const text =
      'El reinicio ocurre solamente si la prueba personalizada pertenece o fue compartida con tu organización';
    const disable =
      cute.answer.custom_test.share_answers
        ? true
        : false;
    if (disable == false) {
      return (
        <Row type="flex" align="middle">
          <Tooltip title={i18n.t('profile__delete_test', translationFile)} trigger="hover" placement="topRight">
            <Button onClick={() => handleResetModal.on()}>
              {i18n.t('profile__restart_test', translationFile)}
            </Button>
          </Tooltip>

          <Modal
            visible={showResetCuteAnswerModal}
            onCancel={() => handleResetModal.off()}
            title="¿Estás seguro(a)?"
            footer={[
              <Button key="back" onClick={() => handleResetModal.off()}>
                {i18n.t('commons__no', translationFile)}
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={() => resetCuteAnswer(cute.answer.custom_test.id, handleResetModal.off)}
              >
                {i18n.t('commons__yes', translationFile)}
              </Button>,
            ]}
          >
            <Row type="flex" style={{ flexDirection: 'row' }} gutter={16}>
              <Col>
                <Icon
                  type="info-circle"
                  style={{ color: '#ef8624', fontSize: '20px' }}
                />
              </Col>
              <Col>
                <p>
                  Se eliminarán las respuestas del candidato y reiniciará la
                  prueba.
                </p>
              </Col>
            </Row>
          </Modal>
        </Row>
      );
    }
    return <></>;
  };

  const getCuteStatus = (tmpCute) => {
    console.log(tmpCute);
    const cuteStatus = application.activities.find(
      (obj) => obj.assigned_activity_id === tmpCute.assignedActivityId
    )?.status;
    return cuteStatus;
  };

  if (!customTests || customTests.length == 0) {
    return null;
  } else {
    return customTests.map((customTest, index) => (
      <Collapse icon="experiment" title={customTest.answer.custom_test.title}>
        {!customTest.answer.error ? (
          <>
            <Row type="flex" justify="end">
              {Object.keys(customTest.answer.scores).length > 0 ? (
                ['DAT', 'ALTERNATIVES', 'DISC', 'SJT_GW', 'RAVEN'].includes(customTest.answer.scores.type)
                 ? (
                  <Row
                    justify={'space-between'}
                    style={{ width: '100%' }}
                    type={'flex'}
                  >
                    <DownloadReportButtons
                      type={customTest.answer.scores.type}
                      cute={customTest}
                      idx={index}
                    />
                    <ResetCuteAnswerButton cute={customTest}>
                      Reiniciar prueba
                    </ResetCuteAnswerButton>
                  </Row>
                ) : (
                  <ResetCuteAnswerButton cute={customTest}>
                    Reiniciar prueba
                  </ResetCuteAnswerButton>
                )
              ) : null}
            </Row>
            <CustomTestsDetail data={customTest.answer} personalityScores={application.personality_scores.map(obj=>({code:obj.trait.code, score:obj.score}))}/>
            <ModalPdf
              showCutePdf={showCutePdf[customTest.name]}
              customTest={customTest}
              candidate={{ ...application, ...candidate}}
              jobApp={jobApp}
              setShowCutePdf={(value) =>
                setShowCutePdf((oldState) => ({
                  ...oldState,
                  [customTest.name]: value,
                }))
              }
              type={customTest.answer.scores.type}
              downloadSummary={downloadSummary}
            />
          </>
        ) : (
          <>
            <Row
              type="flex"
              align="middle"
              justify={'space-between'}
              style={{ width: '100%' }}
            >
              <Col>La prueba no ha sido respondida.</Col>
              {/* {getCuteStatus(customTest) !== 'INITIAL' && ( */}
              {true && (
                <Col>
                  <ResetCuteAnswerButton cute={customTest}>
                    Reiniciar prueba
                  </ResetCuteAnswerButton>
                </Col>
              )}
            </Row>
          </>
        )}
      </Collapse>
    ));
  }
};

export default CustomTestView;
