import React from 'react';
import {
  Row,
  Col,
  Typography,
  Divider
} from 'antd';

const { Title, Text } = Typography;

const InitialDescription = () => {
  return (
    <Row type='flex' gutter={[20,20]}>
      <Col span={24}>
        <Title level={4} style={{ color: '#5E5E5E' }}>
          Descripción
        </Title>
      </Col>
      <Col span={24}>
        <Text>
          Este test evalúa la creatividad verbal y el pensamiento divergente. El evaluado debe escribir palabras que sean distantes a nivel semántico entre sí (es decir, en uso y contexto). Esta “distancia” es transformada de forma automática y objetiva en un puntaje, clasificando al evaluado en una de tres posibles categorías en base a su nivel obtenido: <Text style={{ color: '#FF4D4F' }}>Bajo</Text>, <Text style={{ color: '#FAAD14' }}>Promedio</Text> y <Text style={{ color: '#52C41A' }}>Alto</Text>.
        </Text>
      </Col>
      <Col span={24}>
        <Title level={4} style={{ color: '#5E5E5E' }}>
          Resultados del evaluado
        </Title>
      </Col>
      <Divider style={{ margin: '8px 8px 20px 8px' }}/>
    </Row>
  );
}

export default InitialDescription;