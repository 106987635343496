import React from 'react';
import {
  List,
  Skeleton,
  Icon,
  Col,
  Typography,
} from 'antd';
import i18n from '../../../i18n';
const { Title } = Typography;


function AdditionalDocuments({additionalDocuments}) {
  return (
    <Col xs={24}>
      <List
        itemLayout="horizontal"
        dataSource={additionalDocuments}
        renderItem={(item, index) => (
          <List.Item
              actions={[<a href={item.document} key="documents-download-button">{i18n.t('commons__download', { ns: 'candidateProfile'})}</a>,]}
            >
              <Skeleton avatar title={false} loading={item.loading} active>
                <List.Item.Meta
                  avatar={
                    <Icon type='file' />
                  }
                  title={<a href={item.document}>{item.document_type}</a>}
                  description={item.document_extension}
                />
              </Skeleton>
            </List.Item>
      )} />
    </Col>
  )
}

export default AdditionalDocuments;
