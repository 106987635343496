import React, { useState, useEffect, useMemo } from 'react';
import { Button, Card, Col, Icon, Row, Select, Typography, message, Modal } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import TriggerAction from './components/TriggerAction';
import ResultCondition from './components/ResultCondition';

const { Text, Title } = Typography;
const { Option } = Select;

export const configurations = {
  'MOVSTG': {
    'label': 'Cuando los candidatos sean movidos a esta etapa',
    'scheduleOptional': true,
    'scheduleLabel': 'Días de delay',
    'conditions': [],
    'secondary': false
  },
  'FINSTG': {
    'label': 'Cuando los candidatos hayan completado esta etapa',
    'scheduleOptional': false,
    'conditions': ['FIT', 'KT'],
    'secondary': true,
  }
}

const defaultActions = {
  0: 'MSG',
  1: 'MOVE',
  2: 'ADDTAG',
  3: 'DISCARD'
}

const TriggerForm = ({goBack, stages, tags, finish, setTrigger, selectedStage, evaluations=[], isSecondary=false, secondaryOptions={}, trigger }) => {
  const [conditionEnabled, setConditionEnabled] = useState(!isSecondary ? false : true)
  const [actions, setActions] = useState([]);
  const [when, setWhen] = useState(!isSecondary ? {value: Object.keys(configurations)[0], label: Object.values(configurations)[0].label}: secondaryOptions.when);
  const [conditionOperator, setConditionOperator] = useState(!isSecondary ? 'NONE' : secondaryOptions.conditionOperator)
  const [conditions, setConditions] = useState(!isSecondary ? [] : secondaryOptions.conditions)
  const [cutesAvailable, setCutesAvailable] = useState(evaluations)

  const availableActions = useMemo(() => {
    const actionsUsed = actions.map((action) => action.type);
    return Object.values(defaultActions).filter(x => (when.value !== 'MOVSTG' || !(['DISCARD', 'MOVE'].includes(x))) && !actionsUsed.includes(x));
  }, [actions, when])

  const AddButton = ({text, fn}) => {
    return (
      <Row type='flex' justify='center' align='middle' style={{ marginTop: '1em'}}>
        <Col span={4}>
          <Button type='secondary' icon='plus-circle' onClick={() => fn()}>{text}</Button>
        </Col>
      </Row>
    )
  }

  const addActions = () => {
    const typeSelected = availableActions[0];
    if (!typeSelected) {
      message.error('No hay más acciones disponibles');
      return;
    };
    setActions((oldState) => {
      const newState = [...oldState, { 'type' :  typeSelected} ]
      if (typeSelected === 'MSG') newState['schedule_type'] = 'now';
      return newState;
    })
  }

  const removeAction = (index) => {
    setActions((oldState) => oldState.filter((action, i) => i !== index))
  }

  const handleFinish = () => {
    const actionsFilled = actions.map((action) => {
      if (action.type === 'MSG') {
        return Boolean(action.config && action.config.subject && action.config.content);
      } else if (action.type === 'MOVE') {
        return Boolean(action.config && action.config.next_stage);
      } else if (action.type === 'ADDTAG') {
        return Boolean(action.config && action.config.labels && action.config.labels.length > 0);
      }
      return true;
    });
    if (actionsFilled.includes(false)) {
      message.error('Por favor completa todas las acciones');
      return;
    };
    if (!isSecondary){
      setTrigger((oldState) => ({
        ...oldState,
        id: uuidv4(),
        when,
        conditionOperator,
        conditions: structuredClone(conditions),
        primaryActions: structuredClone(actions),
        secondaryActions: []
      }))
    }
    if (isSecondary) {
      setTrigger((oldState) => ({
        ...oldState,
        secondaryActions: structuredClone(actions),
      }))
    }
    finish()
  }

  useEffect(() => {
    if (trigger && trigger.when) {
      const { when, conditionOperator, conditions, primaryActions } = trigger;
      setWhen(when)
      setConditionOperator(conditionOperator)
      setConditions(structuredClone(conditions))
      setActions(structuredClone(primaryActions))
      setConditionEnabled(conditions.length > 0)
    }
  }, [])

  const changeWhen = (value) => {
    if (value === when.value) return
    setWhen({value, label: configurations[value].label})
    // we remove the actions
    setActions([])
    // we remove the conditions if the new when doesn't have conditions
    if (value === 'MOVSTG'){
      // we remove the conditions
      setConditions([])
      setConditionOperator('NONE')
      setConditionEnabled(false)
    }
  }

  return (
  <>
    <Title level={4}>
      {isSecondary ? 'Acciones secundarias' : 'Nueva automatización'}
    </Title>
    <Text>
      {
        isSecondary ? 
          'Selecciona las acciones que se ejecutarán cuando el candidato no cumpla las condiciones configuradas previamente.'
        :
          'Selecciona las condiciones que el candidato debe cumplir y las acciones que se activarán en consecuencia para formar una automatización.'
      }
    </Text>
    <WhenSelector onChange={changeWhen} defaultValue={isSecondary ? when.value : 'MOVSTG'} disabled={isSecondary}/>
    {!conditionEnabled && configurations[when.value].conditions.length > 0 ?
      <AddButton 
        text='Agregar condición' 
        fn={() => { setConditionOperator('AND'); setConditionEnabled(true);}}
      /> : null
    }
    {conditionEnabled ?
      <ResultCondition 
        remove={() => setConditionEnabled(false)} 
        conditions={conditions}
        setConditions={setConditions}
        conditionOperator={conditionOperator}
        setConditionOperator={setConditionOperator}
        conditionsAvailable={configurations[when.value].conditions}
        cutesAvailable={cutesAvailable}
        setCutesAvailable={setCutesAvailable}
        isSecondary={isSecondary}
        evaluations={evaluations}
      />
      : null
    }
    {actions.map((action,idx) => 
      <TriggerAction key={`action_${idx}`}
        selectedStage={selectedStage}
        action={action} 
        actions={actions}
        availableActions={availableActions}
        removeAction={() => removeAction(idx)} 
        stages={stages} 
        setActions={setActions} 
        actionIndex={idx}
        jobAppTags={tags}
      /> 
    )}
    {availableActions.length > 0 ? <AddButton text='Agregar acción' fn={addActions}/> : null}
    <Row type='flex' justify='end' align='middle' style={{ marginTop: '1em'}} gutter={[10, 10]}>
      <Col>
        <Button type='secondary' onClick={
          () =>
          Modal.confirm({
            title: '¿Estás seguro/a que deseas salir de la creación de la automatización?',
            icon: <Icon type="info-circle" style={{ color: '#FAAD14' }} />,
            content: 'Se perderá toda la información guardada.',
            okText: 'Eliminar automatización',
            cancelText: 'Volver',
            onOk: goBack,
          })
        }>Cancelar</Button>
      </Col>
      <Col>
        {actions.length > 0 && <Button type='primary' onClick={handleFinish}> Crear automatización </Button>}
      </Col>
    </Row>
  </>
  )
}

export default TriggerForm;


function WhenSelector({onChange, defaultValue='MOVSTG', disabled=false}) {
    
  return (
    <Card className='trigger-card'>
      <Row type='flex' justify='center' align='middle'>
        <Col span={4}>
          <Icon type='user' style={{ fontSize: 60, color: '#A4A4A4' }} />
        </Col>
        <Col span={16}>
          <Text>Cuándo ejecutar la automatización</Text><br />
          {disabled ? 
          <Select style={{ width: '100%' }} defaultValue={defaultValue} disabled>
            {Object.entries(configurations).map(([key,value], index) => <Option key={`opt-when_${index}`} value={key}>{value.label}</Option> )}
          </Select>
          : <Select style={{ width: '100%' }} defaultValue={defaultValue} onChange={onChange}>
            {Object.entries(configurations).map(([key,value], index) => <Option key={`opt-when_${index}`} value={key}>{value.label}</Option> )}
          </Select>
          }  
        </Col>
      </Row>
    </Card>
  )
}