import React from 'react';
import { Typography } from 'antd';
import daysOfWeek from '../../constants/daysOfWeek';
import i18n from '../../i18n';

const { Text } = Typography;

const oneHotToHourOfDay = (oneHot, end = false) => {
  const adjustedOneHot = end ? oneHot + 1 : oneHot;
  const hour = Math.floor(adjustedOneHot / 4);
  const minutes = (adjustedOneHot % 4) * 15;
  return [hour, minutes];
};

const DayList = ({ schedule }) => {
  const formattedSchedule = {};

  Object.keys(schedule).forEach((day) => {
    let inABlock = false;
    let newBlock;
    formattedSchedule[day] = [];

    schedule[day].forEach((oneHot, index) => {
      if (oneHot) {
        if (!inABlock) {
          inABlock = true;
          newBlock = { start: [], end: [] };
          newBlock.start = oneHotToHourOfDay(index);
          newBlock.end = oneHotToHourOfDay(index, true);
        } else {
          newBlock.end = oneHotToHourOfDay(index, true);
        }
      } else if (inABlock) {
        inABlock = false;
        formattedSchedule[day].push(newBlock);
      }
    });
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      {daysOfWeek.map((day) => (
        <div key={day} style={{ display: 'flex', gap: '1rem' }}>
          <Text strong style={{ width: 80 }}>
            {i18n.t(`commons__dayofweek__${day}`)}
          </Text>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
            }}
          >
            {formattedSchedule[day].length > 0 ? (
              formattedSchedule[day].map((block, index) => (
                <Text key={index}>
                  {`${block.start[0]}:${block.start[1]
                    .toString()
                    .padStart(2, '0')}hs - ${block.end[0]}:${block.end[1]
                    .toString()
                    .padStart(2, '0')}hs`}
                </Text>
              ))
            ) : (
              <Text>Día de descanso</Text>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DayList;
