import React from 'react';
import { Link } from 'react-router-dom';

import {
  Icon,
  Table,
  Tooltip,
  Typography,
} from 'antd';

const { Text } = Typography;

function ReferenceTemplatesTable(props) {
  const columns = (status) => {
    return [
      {
        title: 'NOMBRE DE LA PLANTILLA',
        dataIndex: 'name',
        key: 'name',
        render: (name) => (<Text>{name}</Text>),
      },
      {
        title: 'NÚMERO DE PREGUNTAS',
        dataIndex: 'questions_count',
        key: 'questions_count',
        render: (questions_count) => (<Text>{questions_count}</Text>),
      },
      {
        title: 'DESCRIPCIÓN',
        dataIndex: 'description',
        key: 'description',
        render: (description) => (<Text>{description}</Text>),
        ellipsis: true,
      },
      {
        title: 'CREACIÓN',
        dataIndex: 'date_created',
        key: 'date_created',
        sorter: (a, b) => {
          const firstDate = new Date(a.date_created.slice(6), a.date_created.slice(3,5), a.date_created.slice(0,2))
          const secondDate = new Date(b.date_created.slice(6), b.date_created.slice(3,5), b.date_created.slice(0,2));
          if (firstDate < secondDate)
            return -1
          else if (firstDate > secondDate)
            return 1
          else
            return 0
        },
        render: (date_created) => (<Text>{date_created}</Text>),
      },
      {
        title: 'ACCIONES',
        dataIndex: 'id',
        key: 'id',
        render: (id, templateObj) => (
          <div
            className='reference-templates-actions'
            style={{ display: 'flex' }}
          >
            {!templateObj.public ? (
              <Link to={`/references/edit/${id}`}>
                <Tooltip placement='top' title='Editar plantilla'>
                  <Icon
                    type='edit'
                    style={{ fontSize: '19px', color: '#A4A4A4', marginRight: '10px' }}
                  />
                </Tooltip>
              </Link>
            ) : (
              <Link to={`/references/edit/${id}`}>
                <Tooltip placement='top' title='Vista previa'>
                  <Icon
                    type='file-text'
                    style={{ fontSize: '19px', color: '#A4A4A4', marginRight: '10px' }}
                  />
                </Tooltip>
              </Link>
            )}
            <Tooltip placement='top' title='Duplicar plantilla'>
              <Icon
                style={{ fontSize: '19px', color: '#A4A4A4', marginRight: '10px' }}
                type='copy'
                onClick={() => props.handleDuplicate(id)}
              />
            </Tooltip>
            {!templateObj.public && (
              <Tooltip
                placement='top'
                title={`${status == 'ACTIV' ? 'A' : 'Desa'}rchivar plantilla`}
              >
                <Icon
                  style={{ fontSize: '19px', color: '#A4A4A4', marginRight: '10px' }}
                  type={status == 'ACTIV' ? 'eye-invisible' : 'eye'}
                  onClick={() => props.toggleArchivedTemplate(id, status)}
                />
              </Tooltip>
            )}
            {!templateObj.public && (
              <Tooltip placement='top' title='Eliminar plantilla'>
                <Icon
                  type='delete'
                  style={{ fontSize: '19px', color: '#A4A4A4', marginRight: '10px' }}
                  onClick={() => props.deleteTemplate(templateObj)}
                />
              </Tooltip>
            )}
          </div>
        ),
      },
    ]
  };

  return (
    <>
      <Table
        pagination={{ hideOnSinglePage: true }}
        rowSelection={{
          onSelect: props.handleRowsSelected, 
          onSelectAll: props.handleAllRowsSelected,
          selectedRowKeys: props.selectedRowsData.map((obj) => obj.id),
        }}
        className='references-table'
        dataSource={props.templatesData.filter(
          (rowData) => {
            if (!props.searchInput)
              return true;
            return (
              rowData.name.includes(props.searchInput) || (rowData.description && rowData.description.includes(props.searchInput))
            )
          }
        )}
        columns={columns(props.status)}
        scroll={{ x: 1200 }}
      />
      <Text style={{ marginTop: 15, position: "absolute" }}>
        <strong>{props.selectedRowsData.length}</strong> plantillas seleccionadas
      </Text>
    </>
  )
};

export default ReferenceTemplatesTable;
