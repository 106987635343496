import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actionCreators from '../../actions/data';
import * as actionsJobApp from '../../actions/jobapp';
import * as actionVideos from '../../actions/videos';

import { CandidateProvider } from './config/CandidateContext';

import FakeModal from './Layout/FakeModal';

import './styles.scss';

const CandidateModal = ({ ...props }) => {
  // in CandidateList/List.js we have a lot of classes that modifies Row, Col and Tabs through the children
  // is much simpler to use a portal and ignore that (at least for now)
  // todo: clean List.js classes 
  return ReactDOM.createPortal(
    <CandidateProvider {...props}>
      <FakeModal
        actions={props.actions}
        businessId={props.business.id}
        getStageCandidates={props.getStageCandidates}
        setCandidates={props.setCandidates}
      />
    </CandidateProvider>,
    document.getElementById('portal')
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    jobAppActions: bindActionCreators(actionsJobApp, dispatch),
    videoActions: bindActionCreators(actionVideos, dispatch),
    dispatch,
  };
};

const mapStateToProps = (state) => {
  return {
    logoURL: state.auth.logoURL,
    userName: state.auth.userName,
    token: state.auth.token,
    addOns: state.auth.addOns,
    business: state.auth.currentBusiness,
    // lanes: state.data.lanes,
    videoInterview: state.jobapp.jobApplication.interview_id
      ? state.videos.data
      : [],
    measuredTraits: state.trait.measuredTraits,
    permissions: state.auth.businessPermissions,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateModal);
