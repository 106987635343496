import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import FilterModal from './filterModal';

import '../CandidateList.scss';

const CV_API = process.env.REACT_APP_CV_API;

/**
 * Replaces CandidateList Table with a filterCv table using the CV data
 * @param {props} {candidates, onCloseDrawer, oncloseDrawer}
 */
const CvFilter = (props) => {
  const [skillsOptions, setSkillsOptions] = useState([]);

  useEffect(() => {
    fetchAndReturn('/skills').then((response) => setSkillsOptions(response));
  }, []);

  const splitCv = (cv) => {
    if (cv) {
      // Hide if contains .doc extension
      if (cv.includes('.doc')) {
        return null;
      } else {
        const cleanCv = cv.split('.com/')[1].split('?X')[0];
        return cleanCv;
      }
    }
    return null;
  };

  function fetchAndReturn(endpoint, method = 'get', bodyData = null) {
    props.setLoading(true);
    let url = `${CV_API}${endpoint}`;
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Token ${props.token}`,
    };

    let config = bodyData
      ? {
          method: `${method}`,
          headers,
          body: JSON.stringify(bodyData),
        }
      : {
          method: `${method}`,
          headers,
        };

    return fetch(url, config).then((response) => {
      props.setLoading(false);
      if (response.status < 400) {
        return response.json();
      } else {
        return [];
      }
    });
  }

  const getValidCvs = () =>
    props.candidates.filter(
      (candidate) => candidate.cv && !candidate.cv.includes('.doc')
    );

  const parse = () => {
    const parseCV = getValidCvs();
    if (parseCV.length == 0) {
      return;
    }
    parseCV.map((tmpCandidate) => {
      const cv = splitCv(tmpCandidate.cv);
      if (cv) {
        const endpoint = `/parse?user_id=${tmpCandidate.key}&file_name=${cv}`;
        fetchAndReturn(endpoint, 'POST');
      }
    });
  };

  const filterCv = (reqBody) => {
    const filterCvs = getValidCvs();

    if (filterCvs.length == 0) {
      props.setCvFilterState({
        excSkills: reqBody.exc_skills,
        desSkills: reqBody.des_skills,
      });
      props.setLoading(false);
      props.onCloseModal();
      return;
    }
    props.setLoading(true);
    reqBody['user_id'] = filterCvs.map(
      (candidate) => candidate.personal_user_id
    );

    const endpoint = `/ranking`;
    fetchAndReturn(endpoint, 'POST', reqBody).then((response) => {
      const candidates = getCandidates(response, reqBody);
      props.setCvFilterState(
        {
          excSkills: reqBody.exc_skills,
          desSkills: reqBody.des_skills,
        },
        candidates
      );
      props.setLoading(false);
      props.onCloseModal();
    });
  };

  const getCandidates = (responseData, reqBody) => {
    // This should not be done (edit the props), we should set the state with setState
    const tmpCandidates = props.candidates.map((candidate) => {
      let tmpCandidate = structuredClone(candidate);
      const rankedCandidate = responseData.find(
        (ranked) => tmpCandidate.personal_user_id === ranked.user_id
      );

      tmpCandidate.cvFilter = {};

      if (rankedCandidate) {
        tmpCandidate.cvFilter.excSkills =
          rankedCandidate.excluding_skills.found_skills;
        //TODO Remove
        //tmpCandidate.cvFilter.excSkills = []
        tmpCandidate.cvFilter.excSkillsTotal =
          tmpCandidate.cvFilter.excSkills.length;

        tmpCandidate.cvFilter.desSkills =
          rankedCandidate.desired_skills.found_skills;
        tmpCandidate.cvFilter.desSkillsTotal =
          tmpCandidate.cvFilter.desSkills.length;

        tmpCandidate.cvFilter.similarExcSkills =
          rankedCandidate.excluding_skills.similar_skills;
        tmpCandidate.cvFilter.similarExcSkillsTotal =
          tmpCandidate.cvFilter.similarExcSkills.length;

        tmpCandidate.cvFilter.similarDesSkills =
          rankedCandidate.desired_skills.similar_skills;
        tmpCandidate.cvFilter.similarDesSkillsTotal =
          tmpCandidate.cvFilter.similarDesSkills.length;

        tmpCandidate.cvFilter.score = rankedCandidate.score;
        tmpCandidate.cvFilter.skillScore =
          tmpCandidate.cvFilter.excSkills.length +
          tmpCandidate.cvFilter.desSkills.length;
      }

      return tmpCandidate;
    });

    return tmpCandidates;
  };

  return (
    <FilterModal
      visible={props.modalVisible}
      onClose={props.onCloseModal}
      parse={parse}
      filterCv={filterCv}
      loading={props.loading}
      skillsOptions={skillsOptions}
      //TODO Remove when every cvs is parsed
      checkedCandidates={props.checkedCandidates}
      filterState={props.filterState}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(CvFilter);
