export default (fn, time) => {
    let timerId = null;
  
    return (...args) => {
      if (timerId) {
        clearTimeout(timerId);
      }
  
      timerId = setTimeout(_ => fn(...args), time);
    };
  };
  