import './Evaluations.scss';

import * as actionCreators from '../../actions/data';

import {
  Button,
  Card,
  Col,
  Icon,
  Input,
  Modal,
  Row,
  Tabs,
  Typography,
  message,
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

import Complementary from './components/Complementary';
import Game from './components/Game';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getGameImg } from './helpers';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import useDebouncedSearch from '../../hooks/useDebouncedSearch';

const { Text, Title } = Typography;

const { TabPane } = Tabs;

const notGames = ['FORM', 'KO', 'CUTE', 'VINT'];

const complementaryEvaluations = (addOns) => {
  const hasCuTes = addOns.find((obj) => obj.add_on === 'CUTE');
  const hasIKits = addOns.find((obj) => obj.add_on === 'IKIT');

  const cards = [
    {
      icon: 'form',
      name: 'Pruebas de conocimientos',
      description:
        'Evalúa conocimientos especializados o habilidades específicas asociadas a una búsqueda.',
      action: (history) => history.push('/genomatests'),
      disabled: !hasCuTes,
    },
    {
      icon: 'file-add',
      name: 'Evaluaciones personalizadas',
      description:
        'Crea nuevas evaluaciones desde cero o digitaliza las pruebas que usas en tus procesos.',
      action: (history) => history.push('/customtest'),
      disabled: !hasCuTes,
    },
    {
      icon: 'form',
      name: 'Entrevista',
      description:
        'Evalúa a los candidatos a través de distintas preguntas para analizar su ajuste al cargo.',
      action: (history) => history.push('/account/interviewkit'),
      disabled: !hasIKits,
    },
    {
      icon: 'form',
      name: 'Preguntas adicionales',
      description:
        'Plantea preguntas en la postulación de los candidatos y obtén información clave para tu proceso.',
      action: (history) => history.push('/applicationform'),
      disabled: false,
    },
    {
      icon: 'file-text',
      action: (history) => history.push('/interviews'),
      name: 'Entrevistas por competencias',
      description:
        'Accede a herramientas para transcribir de forma automática una entrevista, analizar competencias y generar informes de forma ágil.',
      disabled: !addOns.find((x) => x.add_on === 'TERA'),
    },
    {
      icon: 'like',
      action: (history) => history.push('/situationals'),
      name: 'Pruebas de juicio situacional',
      description:
        'Mide una habilidad a través de las decisiones de los candidatos al enfrentar situaciones realistas.',
      disabled: false,
    },
  ];

  return cards;
};

const Evaluations = ({ history, actions, auth, ...props }) => {
  const [games, setGames] = useState([]);

  const [modalGame, setGame] = useState(null);

  const [filterValue, setFilterValue] = useState('');

  const DebouncedInput = useDebouncedSearch(
    (value) => {
      setFilterValue(value);
    },
    500,
    'Buscar evaluaciones...',
    {}
  );

  const Breadcrumb = useBreadcrumb(
    [
      {
        path: '/',
        text: 'Home',
      },
      {
        path: 'evaluations',
        text: 'Evaluaciones',
      },
    ],
    '>'
  );

  const gamesPerCategory = useMemo(() => {
    // we can make this more efficient by using a reduce and 4 if statements
    // but this is more readable and it's not a big array so it's fine
    const cognitives = games.filter(
      (game) => game.category === 'COGNITIVE' || game.category === 'REASONING'
    );
    const personals = games.filter((game) => game.category === 'PERSONALITY');
    const conductuals = games.filter((game) => game.category === 'BEHAVIORAL');
    const emotionals = games.filter((game) => game.category === 'EMOTIONAL');

    return {
      Cognitivas: cognitives,
      Personalidad: personals,
      Conductuales: [...conductuals, ...emotionals],
    };
  }, [games]);

  useEffect(() => {
    const {
      token,
      business: { id },
    } = auth;

    actions
      .fetchAndReturn(
        token,
        `/api/v1/gamesandtests/business/${id}/activities/`,
        'GET'
      )
      .then(({ status, body }) => {
        if (status > 299) {
          throw new Error();
        } else {
          setGames(
            body.results.filter((game) => !notGames.includes(game.code))
          );
        }
      })
      .catch((err) => message.error('Error al cargar las evaluaciones'));
  }, []);

  const filteredComplementaryEvaluations = complementaryEvaluations(
    auth.addOns
  ).filter((comp) =>
    comp.name.toLowerCase().includes(filterValue.toLowerCase())
  );

  const filteredGames = games.filter((game) =>
    game.activity
      .replace(/Mobile/g, '')
      .trim()
      .toLowerCase()
      .includes(filterValue.toLowerCase())
  );

  return (
    <div className="evaluations-container">
      {Breadcrumb}
      <Title level={2}>Evaluaciones</Title>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Troncales" key="1">
          <div style={{ marginBottom: '1rem' }}>{DebouncedInput}</div>
          <div className="games-categories">
            {!filterValue ? (
              Object.entries(gamesPerCategory).map(([category, games]) => (
                <div className="games-categories__category">
                  <Text strong>{category}</Text>
                  <div className="games-categories__category__games">
                    {games.map((game) => (
                      <Game game={game} setGame={setGame} />
                    ))}
                  </div>
                </div>
              ))
            ) : filteredGames.length ? (
              <div className="games-categories__category__games">
                {filteredGames.map((game) => (
                  <Game game={game} setGame={setGame} />
                ))}
              </div>
            ) : (
              'No hay evaluaciones que coincidan con tu búsqueda.'
            )}
          </div>
        </TabPane>
        {props.businessPermissions.role?.can_manage_additional_evaluations && (
          <TabPane tab="Complementarias" key="2">
            <div style={{ marginBottom: '1rem' }}>{DebouncedInput}</div>
            <div className="complementary-evaluations">
              {filteredComplementaryEvaluations.length
                ? filteredComplementaryEvaluations.map((evaluation) => (
                    <Complementary
                      disabled={evaluation.disabled}
                      evaluation={evaluation}
                      history={history}
                    />
                  ))
                : 'No hay evaluaciones que coincidan con tu búsqueda.'}
            </div>
          </TabPane>
        )}
      </Tabs>
      <Modal
        visible={!!modalGame}
        onCancel={() => setGame(null)}
        footer={null}
        width="min(600px, 90%)"
      >
        {modalGame ? (
          <div className="modal-game">
            <div className="modal-game__info">
              <img src={getGameImg(modalGame)} />
              <Text strong style={{ fontSize: 16 }}>
                {modalGame.activity.replace(/Mobile/g, '').trim()}
              </Text>
              <Text>{modalGame.description}</Text>
            </div>
            <div className="modal-game__traits">
              <Text>Rasgos medidos en esta evaluación:</Text>
              {modalGame.traits.map((trait) => (
                <span className="modal-game__traits__text">
                  •<strong>{trait.trait}</strong>:{' '}
                  {trait.candidate_description_translation.es}
                </span>
              ))}
            </div>
            <div className="modal-game__footer">
              {[
                {
                  icon: 'clock-circle',
                  text: `${modalGame.time_estimated} minutos`,
                },
                {
                  icon: 'font-size',
                  text: Object.keys(modalGame.translation)
                    .map((text) => text.toUpperCase())
                    .join(', '),
                },
              ].map(({ icon, text }) => (
                <div>
                  <Icon type={icon} />
                  <Text>{text}</Text>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    businessPermissions: state.auth.businessPermissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Evaluations);
