import React from 'react';
import { Button } from 'antd';
import { AppContext } from './JobForm';

const Actions = () => (
  <AppContext.Consumer>
    {(value)  => {
      const lastStep = value.currentStep === 4;
      return (
        <footer>
          {value.currentStep > 0 && <Button icon='left' onClick={value.back}>Atrás</Button>}
          <Button 
            type={lastStep ? 'primary' : 'default'}
            htmlType='submit'
            loading={lastStep && value.loading}
          >
            {lastStep ? 'Publicar' : 'Siguiente'}
          </Button>
        </footer>
      )
    }}
  </AppContext.Consumer>
)

export default Actions;