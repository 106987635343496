import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import i18n from '../../i18n';

import * as actionCreators from '../../actions/data';
import * as actions from '../../actions/auth';

import {
  Checkbox, Icon, Col, Breadcrumb, Row, Typography
} from 'antd';

const { Title, Paragraph } = Typography;

function AccountPermissionsView(props) {
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const currentRole = props.authState?.role;
    const filteredRoles = currentRole ? Object.keys(currentRole).filter(key => key.includes('can_')) : [];
    setRoles(filteredRoles);
  }
    , [props.authState])

  return (
    <div className='account-config-container'>
      <Breadcrumb separator=">" style={{ marginBottom: 20 }}>
        <Breadcrumb.Item href="/home">Inicio</Breadcrumb.Item>
        <Breadcrumb.Item href="/account/settings">Cuenta</Breadcrumb.Item>
        <Breadcrumb.Item>Permisos</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Icon type="arrow-left" style={{ display: 'flex', marginBottom: '0.5em', marginRight: 10 }} onClick={() => props.dispatch(push('/account/settings'))} />
        <Title level={2}>Permisos</Title>
      </div>

      <div>
        <Paragraph style={{ fontSize: "16px", marginBottom: "2em" }}>
          Permisos de acceso para tu cuenta.
        </Paragraph>
      </div>

      {/* TODO: Crumbs */}
      <div className="account-base-card">
        <Row>
          <Col span={24}>
            <div>
              <Title level={3}>{`Mi Rol:`}</Title>
            </div>
            <Row type='flex' align='middle' justify='center' style={{ minHeight: 32, backgroundColor: '#F5F5F5', width: 240 }}>
              {props.authState?.role.name}
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col span={24}>
            <Title level={3}>Permisos independientes</Title>
          </Col>
        </Row>
        <Row>
          {
            <div style={{ marginBottom: 5 }} key={'can_view_all_jobapps'}>
              <Checkbox
                checked={props.authState?.['can_view_all_jobapps']}
                disabled={true}
              >
                {i18n.t('can_view_all_jobapps', {ns: 'businessUser'})}
              </Checkbox>
            </div>
          }
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col span={24}>
            <Title level={3}>Mis Permisos</Title>
          </Col>
        </Row>
        <Row>
          {
            roles.map(elemKey => {
              return (
                <div style={{ marginBottom: 5 }} key={elemKey}>

                  <Checkbox
                    checked={props.authState?.role[elemKey]}
                    disabled={true}
                  >
                    {i18n.t(elemKey, {ns: 'businessUser'})}
                  </Checkbox>
                </div>
              )
            })
          }
        </Row>
      </div>
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    authState: state.auth.businessPermissions
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    actionsAuth: bindActionCreators(actions, dispatch),
    dispatch,

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPermissionsView);