import React, { Fragment, useEffect, useState } from "react";
import { Row, Col } from "antd";
import CandidatePath from "../../../../components/CandidatePath/CandidatePath";
import CandidateInstructions from "./CandidatePathPerform/CandidateInstructions";
import AppSummary from "./CandidatePathPerform/AppSummary";
import Progress from "../Progress/Progress";
import { Typography } from "antd";
import EndProcess from "./CandidatePathPerform/EndProcess";
import i18n from "../../../../i18n";
import "./CandidatePathContent.scss";

const badgeLockedIMG = 'https://genoma-assets.s3.us-east-2.amazonaws.com/badgeLocked.png'

const { Title, Text } = Typography;

const CandidatePathContent = (props) => {
  const { colorTheme, src } = props;
  const [itemss, setItems] = useState([]);
  const [groupItems, setGroupItems] = useState([]);

  useEffect(() => {
    const tmpItems = structuredClone(props.items);
    tmpItems.forEach((item) => {
      // FORM shloud be the 1st item
      if (item.code == "KO" && item.path_position == 0) {
        item.path_position = -1;
      }
      if (item.code == "FORM") {
        item.path_position = -1;
      }
    });
    setItems(tmpItems);

    const groupItems = groupBy(Object.values(tmpItems), "path_position");
    setGroupItems(groupItems);

  }, [props.items]);

  const groupBy = (xs, key) => {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  /**
   * 
   * @param {*} level path_position
   * @returns 
   */
  const percentStageCompleted = (level) => {    
    if (groupItems.length == 0) {
      return 0;
    }

    let count = 0;
    groupItems[level].forEach((item) => {
      if (item.activity.code === 'INTR') {
        if (item.progress.status !== "PENDING") {
          count += 1;
        }
      }
      else {
        if (item.progress.status === "FINISHD" || item.progress.status === "DONE") {
          count += 1;
        }
      }
    });
    return (count * 100) / groupItems[level].length
  };

  return (
    <Row justify="center">
      <Row justify="center">
        <Col span={24}>
          <div className="cp-game-over">
            <Fragment>
              <div className="text">
                <img src={props.hasGamesResponses ? "https://genoma-assets.s3.us-east-2.amazonaws.com/happy-genomin.png" : badgeLockedIMG} />
                <div>
                  <Title level={4}>{i18n.t("candidate_path__results")}</Title>
                  <Text>{i18n.t("candidate_path__results__text")}</Text>
                </div>
              </div>
            </Fragment>

            <EndProcess
              businessSlug={props.businessSlug}
              colorTheme={colorTheme}
              hasGamesResponses={props.hasGamesResponses}
            />
          </div>
        </Col>

        <Col xs={24} sm={24} md={16} lg={16} xl={18}>
          <CandidatePath
            pathItems={itemss.map((obj) => {
              const tmpObj = {
                ...obj.activity,
                path_position: obj["path_position"],
                status: obj["status"],
                jobAppId: props.jobAppId,
                configuration: obj["configuration"],
                progress: obj["progress"],
                video_interview_url: obj['video_interview_url']
              }
              if (obj.activity.code == 'VINT') {
                tmpObj['video_interview_url'] = obj['video_interview_url']
              }
              else if (obj.activity.code == 'INTR') {
                tmpObj['personal_interview_id'] = obj['id']
              }

              return tmpObj;
            })}
            items={groupItems}
            src={src}
            businessSlug={props.businessSlug}
            jobApplication={props.jobApplication}
            preview={false}
            colorTheme={props.colorTheme}
            percentStageCompleted={percentStageCompleted}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={6}>
          <Progress items={props.items} />
          <Row>
            <Col xs={24}>
              <AppSummary
                items={groupItems}
                percentStageCompleted={percentStageCompleted}
              />
            </Col>
            <Col xs={24}>
              <CandidateInstructions />
            </Col>
          </Row>
        </Col>
      </Row>
    </Row>
  );
};

export default CandidatePathContent;
