import React from 'react';
import { Row, Col, Typography } from 'antd';
import i18n from '../../../i18n';

const { Title } = Typography;

function CandidateLoginLayout({ children, rightSide }) {
  const right = () => {
    return !rightSide ? (
      <Row className="candidate-right-def">
        <Row type='flex' justify='center' align='middle' style={{ width: '100%', height: '20%' }}>
          <div
            style={{
              height: '61px',
              width: '266px',
              // Set background image
              backgroundImage: `url(https://genoma-assets.s3.us-east-2.amazonaws.com/Logo+Genoma.png)`,
              // Set background size
              backgroundSize: 'cover',
              // Make it a background image so it will repeat
              backgroundRepeat: 'no-repeat',
            }}
          />
        </Row>
        <Row style={{ width: '100%', height: '80%' }} type='flex' justify='center'>
          <div style={{
            borderRadius: '69px 69px 0px 0px',
            backgroundColor: '#f175a5',
            height: '100%',
            width: '90%',
            maxWidth: '789px',
            bottom: 0,
          }}>
            <Row
              type='flex'
              justify='center'
              align='middle'
              style={{ width: '100%', height: '30%', padding: 30 }}
            >
              <Title level={1} style={{ color: 'white', textAlign: 'center' }}>
                {i18n.t('login__our_dream')}
              </Title>
            </Row>
            <Row type='flex' justify='center' align='bottom' style={{ width: '100%', height: '70%' }}>
              <div
                style={{
                  height: '100%',
                  width: 'inherit',
                  // Set background image
                  backgroundImage: `url(https://genoma-assets.s3.us-east-2.amazonaws.com/login-candidate-image.png)`,
                  // Set background size
                  backgroundSize: 'contain',
                  // Make it a background image so it will repeat
                  backgroundRepeat: 'no-repeat',
                  // Make the background stick to the bottom
                  backgroundPosition: 'bottom',
                }}
              />
            </Row>
          </div>
        </Row>
      </Row>
    ) : (
      rightSide
    );
  };

  return (
    <>
      <Row className="candidate-login" type="flex" justify="center" align="middle">
        {children}
        <Col sm={12} className="candidate-login__right-side">
          {right()}
        </Col>
      </Row>
    </>
  );
}

export default CandidateLoginLayout;
