import React, { useState } from 'react';
import { Typography, Button, Icon, Card, Avatar, Tooltip } from 'antd';
import LaborumJobForm from './form/JobForm';
import './Trabajando.scss';

const { Text } = Typography;
const { Meta } = Card;

function PostJobOnLaborum(props) {

  const [state, setState] = useState({
    visibleModal: false
  })

  const showForm = () => {
    setState({ visibleModal: !state.visibleModal })
  }

  const { visibleModal } = state;
  const { jobapp, shareProcessUrl, jobPortalIntegrations, publish } = props;
  const published = Boolean(jobapp.job_portals['trabajando']);
  return (
    <>
      <Card
        style={{ width: '100%', border: '1px solid #d9d9d9' }}
        actions={[
          published ? 
          <Button type="link"  onClick={showForm}>
            <Icon style={{ color: "#47E197" }} type="check-circle" /> Publicado
          </Button> : jobPortalIntegrations.trabajando.active ? 
          <Button type="link" onClick={showForm} >
            <Icon style={{ color: "#5e5e5e" }} type="notification" /> Publicar
          </Button> : <Tooltip title="Comunícate con nosotros para integrar a Trabajando.com"><span>No disponible</span></Tooltip>
        ]}
      >
        <Meta
          avatar={<Avatar src="https://genoma-assets.s3.us-east-2.amazonaws.com/jobportals/trabajando.jpeg" size="large" />}
          title="Trabajando.com"
          description="Pagado"
        />
      </Card>
      <LaborumJobForm shareProcessUrl={shareProcessUrl} publish={publish} jobApp={jobapp} visible={visibleModal} closeForm={showForm} handleChange={props.handleChange} />
    </>
  )
};

export default PostJobOnLaborum;