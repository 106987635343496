import React, { useState } from 'react';
import { Modal, Col, Row, Typography, Button } from 'antd';

const JobPortal = ({jobApp, publish, handleChange, provider, providerName, modalContent, countries, jobPortalIntegrations}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const sendJobData = () => {
    // Update jobapp
    const { job_portals } = jobApp;
    job_portals[provider] = true;
    handleChange('job_portals')(job_portals);
    publish(false);
  };
  const published = Boolean(jobApp.job_portals[provider]);
  const countryAvailable = Boolean(!countries || countries.includes(jobApp.location_country));
  
  return (
  <>
    <Col span={6}>
      <Row
        type='flex'
        justify='end'
        align='middle'
        style={{ flexDirection: 'column', rowGap: '8px', padding: '20px 0px', border: '1px solid #D9D9D9', minHeight: '180px' }}
      >
        <img src={`https://genoma-assets.s3.us-east-2.amazonaws.com/jobportals/${provider}.png`} style={{width: '50px'}}/>
        <Typography.Title level={3}>{providerName}</Typography.Title>
        {published
          ? (
            <Button className='share-card-button__done'>Publicado</Button>
          ) : (
            <Button
              className='share-card-button'
              disabled={!countryAvailable}
              onClick={() => countryAvailable ? setModalVisible(true): null }
            >
              {countryAvailable ? `Publicar en ${providerName}` : 'No disponible en el país'}
            </Button>
          )}
      </Row>
    </Col>
    <Modal
      visible={modalVisible}
      okText="Publicar"
      cancelText="Cancelar"
      onOk={() => {
        sendJobData();
        setModalVisible(false);
      }}
      onCancel={() => setModalVisible(false)}
    >
      {modalContent}
    </Modal>
  </>
  );
}

export default JobPortal;