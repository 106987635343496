export const paises = [
    { "name": "Afghanistan" },
    { "name": "Albania" },
    { "name": "Algeria" },
    { "name": "AmericanSamoa" },
    {"name": "Andorra" },
    { "name": "Angola" },
    { "name": "Anguilla" },
    { "name": "Antigua and Barbuda" },
    { "name": "Argentina" },
    { "name": "Armenia" },
    { "name": "Aruba" },
    { "name": "Australia" },
    { "name": "Austria" },
    { "name": "Azerbaijan" },
    { "name": "Bahamas" },
    { "name": "Bahrain" },
    { "name": "Bangladesh" },
    { "name": "Barbados" },
    { "name": "Belarus" },
    { "name": "Belgium" },
    { "name": "Belize" },
    { "name": "Benin" },
    { "name": "Bermuda" },
    { "name": "Bhutan" },
    { "name": "Bolivia" },
    { "name": "Bosnia and Herzegovina" },
    { "name": "Botswana" },
    { "name": "Brasil" },
    { "name": "British Indian Ocean Territory" },
    { "name": "Bulgaria" },
    { "name": "Burkina Faso" },
    { "name": "Burundi" },
    { "name": "Cambodia" },
    { "name": "Cameroon" },
    { "name": "Canada" },
    { "name": "Cape Verde" },
    { "name": "Cayman Islands" },
    { "name": "Central African Republic" },
    { "name": "Chad" },
    { "name": "Chile" },
    { "name": "China" },
    { "name": "Christmas Island" },
    { "name": "Colombia" },
    { "name": "Comoros" },
    { "name": "Congo" },
    { "name": "Cook Islands" },
    { "name": "Costa Rica" },
    { "name": "Croatia" },
    { "name": "Cuba" },
    { "name": "Cyprus" },
    { "name": "Czech Republic" },
    { "name": "Denmark" },
    { "name": "Djibouti" },
    { "name": "Dominica" },
    { "name": "Dominican Republic" },
    { "name": "Ecuador" },
    { "name": "Egypt" },
    { "name": "El Salvador" },
    { "name": "Equatorial Guinea" },
    { "name": "Eritrea" },
    { "name": "Estonia" },
    { "name": "Ethiopia" },
    { "name": "Faroe Islands" },
    { "name": "Fiji" },
    { "name": "Finland" },
    { "name": "France" },
    { "name": "French Guiana" },
    { "name": "French Polynesia" },
    { "name": "Gabon" },
    { "name": "Gambia" },
    { "name": "Georgia" },
    { "name": "Germany" },
    { "name": "Ghana" },
    { "name": "Gibraltar" },
    { "name": "Greece" },
    { "name": "Greenland" },
    { "name": "Grenada" },
    { "name": "Guadeloupe" },
    { "name": "Guam" },
    { "name": "Guatemala" },
    { "name": "Guinea" },
    { "name": "Guinea-Bissau" },
    { "name": "Guyana" },
    { "name": "Haiti" },
    { "name": "Honduras" },
    { "name": "Hungary" },
    { "name": "Iceland" },
    { "name": "India" },
    { "name": "Indonesia" },
    { "name": "Iraq" },
    { "name": "Ireland" },
    { "name": "Israel" },
    { "name": "Italy" },
    { "name": "Jamaica" },
    { "name": "Japan" },
    { "name": "Jordan" },
    { "name": "Kazakhstan" },
    { "name": "Kenya" },
    { "name": "Kiribati" },
    { "name": "Kuwait" },
    { "name": "Kyrgyzstan" },
    { "name": "Latvia" },
    { "name": "Lebanon" },
    { "name": "Lesotho" },
    { "name": "Liberia" },
    { "name": "Liechtenstein" },
    { "name": "Lithuania" },
    { "name": "Luxembourg" },
    { "name": "Madagascar" },
    { "name": "Malawi" },
    { "name": "Malaysia" },
    { "name": "Maldives" },
    { "name": "Mali" },
    { "name": "Malta" },
    { "name": "Marshall Islands" },
    { "name": "Martinique" },
    { "name": "Mauritania" },
    { "name": "Mauritius" },
    { "name": "Mayotte" },
    { "name": "Mexico" },
    { "name": "Monaco" },
    { "name": "Mongolia" },
    { "name": "Montenegro" },
    { "name": "Montserrat" },
    { "name": "Morocco" },
    { "name": "Myanmar", },
    { "name": "Namibia"},
    { "name": "Nauru", },
    { "name": "Nepal" },
    { "name": "Netherlands" },
    { "name": "Netherlands Antilles" },
    { "name": "New Caledonia" },
    { "name": "New Zealand" },
    { "name": "Nicaragua" },
    { "name": "Niger" },
    { "name": "Nigeria" },
    { "name": "Niue" },
    { "name": "Norfolk Island" },
    { "name": "Northern Mariana Islands"  },
    { "name": "Norway" },
    { "name": "Oman" },
    { "name": "Pakistan" },
    { "name": "Palau" },
    { "name": "Panama" },
    { "name": "Papua New Guinea" },
    { "name": "Paraguay" },
    { "name": "Perú" },
    { "name": "Philippines" },
    { "name": "Poland" },
    { "name": "Portugal" },
    { "name": "Puerto Rico" },
    { "name": "Qatar" },
    { "name": "Romania" },
    { "name": "Rwanda" },
    { "name": "Samoa" },
    { "name": "San Marino" },
    { "name": "Saudi Arabia" },
    { "name": "Senegal" },
    { "name": "Serbia" },
    { "name": "Seychelles" },
    { "name": "Sierra Leone" },
    { "name": "Singapore" },
    { "name": "Slovakia" },
    { "name": "Slovenia" },
    { "name": "Solomon Islands" },
    { "name": "South Africa" },
    { "name": "South Georgia and the South Sandwich Islands" },
    { "name": "Spain" },
    { "name": "Sri Lanka" },
    { "name": "Sudan" },
    { "name": "Suriname" },
    { "name": "Swaziland" },
    { "name": "Sweden" },
    { "name": "Switzerland" },
    { "name": "Tajikistan" },
    { "name": "Thailand" },
    { "name": "Togo" },
    { "name": "Tokelau" },
    { "name": "Tonga" },
    { "name": "Trinidad and Tobago" },
    { "name": "Tunisia" },
    { "name": "Turkey" },
    { "name": "Turkmenistan", },
    { "name": "Turks and Caicos Islands", },
    { "name": "Tuvalu" },
    { "name": "Uganda" },
    { "name": "Ukraine" },
    { "name": "United Arab Emirates" },
    { "name": "United Kingdom" },
    { "name": "United States" },
    { "name": "Uruguay" },
    { "name": "Uzbekistan" },
    { "name": "Vanuatu" },
    { "name": "Wallis and Futuna" },
    { "name": "Yemen" },
    { "name": "Zambia" },
    { "name": "Zimbabwe" },
    { "name": "Brunei Darussalam" },
    { "name": "Cocos (Keeling) Islands" },
    { "name": "Congo, The Democratic Republic of the" },
    { "name": "Cote d'Ivoire" },
    { "name": "Falkland Islands (Malvinas)" },
    { "name": "Guernsey" },
    { "name": "Holy See (Vatican City State)" },
    { "name": "Hong Kong" },
    { "name": "Iran, Islamic Republic of" },
    { "name": "Isle of Man" },
    { "name": "Jersey" },
    { "name": "Korea, Democratic People's Republic of" },
    { "name": "Korea, Republic of" },
    { "name": "Lao People's Democratic Republic" },
    { "name": "Libyan Arab Jamahiriya" },
    { "name": "Macao" },
    { "name": "Macedonia, The Former Yugoslav Republic of" },
    { "name": "Micronesia, Federated States of" },
    { "name": "Moldova, Republic of" },
    { "name": "Mozambique" },
    { "name": "Palestinian Territory, Occupied" },
    { "name": "Pitcairn" },
    { "name": "Réunion" },
    { "name": "Russia" },
    { "name": "Saint Barthélemy" },
    { "name": "Saint Helena, Ascension and Tristan Da Cunha" },
    { "name": "Saint Kitts and Nevis" },
    { "name": "Saint Lucia" },
    { "name": "Saint Martin" },
    { "name": "Saint Pierre and Miquelon" },
    { "name": "Saint Vincent and the Grenadines" },
    { "name": "Sao Tome and Principe" },
    { "name": "Somalia" },
    { "name": "Svalbard and Jan Mayen" },
    { "name": "Syrian Arab Republic" },
    { "name": "Taiwan, Province of China" },
    { "name": "Tanzania, United Republic of" },
    { "name": "Timor-Leste" },
    { "name": "Venezuela" },
    { "name": "Viet Nam" },
    { "name": "Virgin Islands, British" },
    { "name": "Virgin Islands, U.S." }
]

export const comunas = [
    "Aisén",
    "Algarrobo",
    "Alhué",
    "Alto Biobío",
    "Alto Hospicio",
    "Alto del Carmen",
    "Ancud",
    "Andacollo",
    "Angol",
    "Antofagasta",
    "Antuco",
    "Antártica",
    "Arauco",
    "Arica",
    "Buin",
    "Bulnes",
    "Cabo de Hornos",
    "Cabrero",
    "Calama",
    "Calbuco",
    "Caldera",
    "Calera de Tango",
    "Calle Larga",
    "Camarones",
    "Camiña",
    "Canela",
    "Carahue",
    "Cartagena",
    "Casablanca",
    "Castro",
    "Catemu",
    "Cauquenes",
    "Cañete",
    "Cerrillos",
    "Cerro Navia",
    "Chaitén",
    "Chanco",
    "Chañaral",
    "Chiguayante",
    "Chile Chico",
    "Chillán",
    "Chillán Viejo",
    "Chimbarongo",
    "Cholchol",
    "Chonchi",
    "Chépica",
    "Cisnes",
    "Cobquecura",
    "Cochamó",
    "Cochrane",
    "Codegua",
    "Coelemu",
    "Coihaique",
    "Coihueco",
    "Colbún",
    "Colchane",
    "Colina",
    "Collipulli",
    "Coltauco",
    "Combarbalá",
    "Concepción",
    "Conchalí",
    "Concón",
    "Constitución",
    "Contulmo",
    "Copiapó",
    "Coquimbo",
    "Coronel",
    "Corral",
    "Coínco",
    "Cunco",
    "Curacautín",
    "Curacaví",
    "Curaco de Vélez",
    "Curanilahue",
    "Curarrehue",
    "Curepto",
    "Curicó",
    "Dalcahue",
    "Diego de Almagro",
    "Doñihue",
    "El Bosque",
    "El Carmen",
    "El Monte",
    "El Quisco",
    "El Tabo",
    "Empedrado",
    "Ercilla",
    "Estación Central",
    "Florida",
    "Freire",
    "Freirina",
    "Fresia",
    "Frutillar",
    "Futaleufú",
    "Futrono",
    "Galvarino",
    "General Lagos",
    "Gorbea",
    "Graneros",
    "Guaitecas",
    "Hijuelas",
    "Hualaihué",
    "Hualañé",
    "Hualpén",
    "Hualqui",
    "Huara",
    "Huasco",
    "Huechuraba",
    "Illapel",
    "Independencia",
    "Iquique",
    "Isla de Maipo",
    "Isla de Pascua",
    "Juan Fernández",
    "La Calera",
    "La Cisterna",
    "La Cruz",
    "La Estrella",
    "La Florida",
    "La Granja",
    "La Higuera",
    "La Ligua",
    "La Pintana",
    "La Reina",
    "La Serena",
    "La Unión",
    "Lago Ranco",
    "Lago Verde",
    "Laguna Blanca",
    "Laja",
    "Lampa",
    "Lanco",
    "Las Cabras",
    "Las Condes",
    "Lautaro",
    "Lebu",
    "Licantén",
    "Limache",
    "Linares",
    "Litueche",
    "Llaillay",
    "Llanquihue",
    "Lo Barnechea",
    "Lo Espejo",
    "Lo Prado",
    "Lolol",
    "Loncoche",
    "Longaví",
    "Lonquimay",
    "Los Andes",
    "Los Lagos",
    "Los Muermos",
    "Los Sauces",
    "Los Vilos",
    "Los Álamos",
    "Los Ángeles",
    "Lota",
    "Lumaco",
    "Machalí",
    "Macul",
    "Maipú",
    "Malloa",
    "Marchihue",
    "Mariquina",
    "María Elena",
    "María Pinto",
    "Maule",
    "Maullín",
    "Mejillones",
    "Melipeuco",
    "Melipilla",
    "Molina",
    "Monte Patria",
    "Mostazal",
    "Mulchén",
    "Máfil",
    "Nacimiento",
    "Nancagua",
    "Natales",
    "Navidad",
    "Negrete",
    "Ninhue",
    "Nogales",
    "Nueva Imperial",
    "O'higgins",
    "Olivar",
    "Ollague",
    "Olmué",
    "Osorno",
    "Ovalle",
    "Padre Hurtado",
    "Padre Las Casas",
    "Paihuaco",
    "Paillaco",
    "Paine",
    "Palena",
    "Palmilla",
    "Panguipulli",
    "Panquehue",
    "Parral",
    "Pedro Aguirre Cerda",
    "Pelarco",
    "Pelluhue",
    "Pemuco",
    "Pencahue",
    "Penco",
    "Peralillo",
    "Peredones",
    "Perquenco",
    "Petorca",
    "Peumo",
    "Peñaflor",
    "Peñalolén",
    "Pica",
    "Pichidegua",
    "Pichilemu",
    "Pinto",
    "Pirque",
    "Pitrufquén",
    "Placilla",
    "Portezuelo",
    "Porvenir",
    "Pozo Almonte",
    "Primavera",
    "Providencia",
    "Puchuncaví",
    "Pucón",
    "Pudahuel",
    "Puente Alto",
    "Puero Octay",
    "Puerto Montt",
    "Puerto Varas",
    "Pumanque",
    "Punitaqui",
    "Punta Arenas",
    "Puqueldón",
    "Purranque",
    "Purén",
    "Putaendo",
    "Putre",
    "Puyehue",
    "Queilén",
    "Quellón",
    "Quemchi",
    "Quilaco",
    "Quilicura",
    "Quilleco",
    "Quillota",
    "Quillón",
    "Quilpué",
    "Quinchao",
    "Quinta Normal",
    "Quinta de Tilcoco",
    "Quintero",
    "Quirihue",
    "Rancagua",
    "Rauco",
    "Recoleta",
    "Renaico",
    "Renca",
    "Rengo",
    "Requínoa",
    "Retiro",
    "Rinconada",
    "Romeral",
    "Ránquil",
    "Río Bueno",
    "Río Claro",
    "Río Hurtado",
    "Río Ibáñez",
    "Río Negro",
    "Río Verde",
    "Saavedra",
    "Sagrada Familia",
    "Salamanca",
    "San Antonio",
    "San Bernardo",
    "San Carlos",
    "San Clemente",
    "San Esteban",
    "San Fabián",
    "San Felipe",
    "San Fernando",
    "San Gregorio",
    "San Ignacio",
    "San Javier",
    "San Joaquín",
    "San José de Maipo",
    "San Juan de la Costa",
    "San Miguel",
    "San Nicolás",
    "San Pablo",
    "San Pedro",
    "San Pedro de Atacama",
    "San Pedro de La Paz",
    "San Rafael",
    "San Ramón",
    "San Rosendo",
    "San Vicente de Tagua Tagua",
    "Santa Bárbara",
    "Santa Cruz",
    "Santa Juana",
    "Santa María",
    "Santiago",
    "Santo Domingo",
    "Sierra Gorda",
    "Talagante",
    "Talca",
    "Talcahuano",
    "Taltal",
    "Temuco",
    "Teno",
    "Teodoro Schmidt",
    "Tierra Amarilla",
    "Tiltil",
    "Timaukel",
    "Tirúa",
    "Tocopilla",
    "Toltén",
    "Tomé",
    "Torres del Paine",
    "Tortel",
    "Traiguén",
    "Treguaco",
    "Tucapel",
    "Valdivia",
    "Vallenar",
    "Valparaíso",
    "Vichuquén",
    "Victoria",
    "Vicuña",
    "Vilcún",
    "Villa Alegre",
    "Villa Alemana",
    "Villarrica",
    "Vitacura",
    "Viña del Mar",
    "Yerbas Buenas",
    "Yumbel",
    "Yungay",
    "Zapallar",
    "Ñiquen",
    "Ñuñoa"
]