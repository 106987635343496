import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../../actions/data';

import NewProcessModal from '../modals/NewProcess/NewProcessModal';

import {
  Button,
} from 'antd'

const NewProcess = (props) => {

  const [newProcessModalVisible, setNewProcessModalVisible] = useState(false)

  useEffect(() => {
    if (window.location.hash === '#new') {
      setNewProcessModalVisible(true);
    }
  }, [])

  useEffect(() => {
    // Show modal from the parent
    props.showModal && showModal()
  }, [props.showModal])


  const showModal = () => {
    setVisible(true)
  }

  return (
    <>
      <Button type='primary' icon='plus' onClick={() => setNewProcessModalVisible(true)} style={{width: 150}}>
        <span className='value'>Nuevo proceso</span>
      </Button>

      <NewProcessModal
        visible={newProcessModalVisible}
        closeModal={() => setNewProcessModalVisible(false)}
        //canCreate={props.clonationAvailable}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  const isClonationAvailable = () => {
    const addOns = state.auth.addOns.map(elem => elem.add_on)
    // return addOns.includes('SEED')
    // TODO: descomentar
    return true
  }

  return {
    token: state.auth.token,
    data: state.data.data,
    clonationAvailable: isClonationAvailable(),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewProcess)
