import React, { useEffect } from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import { Modal, message, Button } from 'antd';

import * as actions from '../../actions/auth';
import { SERVER_URL } from '../../utils/config';
import { queryToObject } from '../../utils/url';
import i18n from '../../i18n';


function Tos(props) {
  const qs = queryToObject(props.location.search);

  useEffect(() => {   
    if (props.tos == true){
      props.dispatch(push(qs.next || '/me'))
    } 
  }
  ,[props.tos]);


  const errorMsg = () => {
    message.error('Error al actualizar los datos');
  };

  // If user accepts tos update candidate profile in DB
  const handleOk = () => {
    // Send request to update tos then push url
    fetch(`${SERVER_URL}/api/v1/accounts/tos/`, {
      method: 'put',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Token ${props.token}`,
      },
      body: JSON.stringify({ accepted_tos: true })
    })
      .then(response => {
        return response.json()
      })
      .then((data) => {
        props.actionsAuth.updateAcceptedTos(data.accepted_tos)

        if (data.accepted_tos) {
          props.dispatch(push(qs.next || '/me'))
        }
        else {
          errorMsg()
        }

      });
    ;
  };

  // If user doesn't accept tos return to landing page
  const handleCancel = () => {
    props.dispatch(push('/'));
  };

  return (
    <>
      <Modal title={i18n.t('tos__terms')}
        visible={true} onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        footer={[
          <Button key="back" onClick={handleCancel}>
           {i18n.t('commons__cancel')}
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Aceptar Términos
          </Button>,
        ]}>
        <iframe credentialless='' width="100%" height="500" src="https://docs.google.com/document/d/e/2PACX-1vT4odFJk2URzMvEF3GADBndjXdLqVhPHaGNEdhEvZmieRH00HQiamys_mQECH-17aXTbiUaW2iqSiek/pub?embedded=true"></iframe>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    tos: state.auth.acceptedTos,
    user: state.auth.userID,
    token: state.auth.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actionsAuth: bindActionCreators(actions, dispatch),
    dispatch,

  };
};


export default connect(mapStateToProps, mapDispatchToProps)(Tos);
