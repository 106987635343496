import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { push } from 'react-router-redux';
import { Row, Col, Button, Card, Icon, Layout, Typography, Tabs } from 'antd';

const { Header, Footer, Sider, Content } = Layout;
const { Title, Paragraph, Text } = Typography;

export default function ToolCard(props) {
  return (
    <div className="tool-item">
      <div className="tool-item__header">
        <Icon type={props.icon} />
        <Text strong>{props.title}</Text>
      </div>
      <div className="tool-item__content">
        <Paragraph>{props.description}</Paragraph>
        <Button
          onClick={() => {
            props.dispatch(push(props.href));
          }}
          type="primary"
        >
          Ingresar
        </Button>
      </div>
    </div>
  );
}

ToolCard.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  href: PropTypes.string,
};
