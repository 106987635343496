import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';

import SurveyFormView from './SurveyFormView'
import BlockedView from './BlockedView'

import * as actionCreators from '../../../actions/data';
import * as gameActions from '../../../actions/games';

import {
  Spin,
} from 'antd';


function CustomTestView(props) {

  const [loading, setLoading] = useState(true);
  const [finished, setFinished] = useState(false);
  const [jobapp, setJobapp] = useState();

  const queryString = new URLSearchParams(window.location.search);
  const cuteId = queryString.get("cuteId");
  const assActPP = queryString.get("assActPP");
  const surveyId = props.match.params['surveyid'];

  useEffect(() => {
  }, []
  );

  useEffect(() => {
  }, []
  );

  const backToPath = () => {
    const url = `/jobapplication/${jobappId}/`
    props.dispatch(push(url))
  };

  const View = () => (
    true ?
      <SurveyFormView
        cuteId={cuteId}
        surveyId={surveyId}
        backToPath={backToPath}
      />
      :
      <BlockedView backToPath={backToPath} />
  )

  return (
    <View />
  )
}

const mapStateToProps = (state) => {
  return {
    jobapp: state.data.data,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    gameActions: bindActionCreators(gameActions, dispatch),
    dispatch
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(CustomTestView);
