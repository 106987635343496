import React, { useState, useEffect } from 'react';
import {
  Row,
  Button,
  Card,
  Col,
  Modal,
  Table,
  Tooltip,
  Typography,
  Breadcrumb,
  Input,
  message,
} from 'antd';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import * as actionCreators from '../../actions/data';

import debounce from 'lodash.debounce';

import SituationalPreview from './SituationalPreview';

const { Text, Title } = Typography;

const GenomaTests = (props) => {
  const { history, actions, auth } = props;

  const [genomaTests, setGenomaTests] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [filteredTests, setFilteredTests] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [cute, setCute] = useState(null);

  const columns = [
    {
      title: "NOMBRE DE LA PRUEBA",
      key: 'title',
      dataIndex: 'title',
      width: '30%',
      ellipsis: true,
      sorter: (a, b) => {
        if (a.title.toLowerCase() < b.title.toLowerCase())
          return -1
        else if (a.title.toLowerCase() > b.title.toLowerCase())
          return 1
        else
          return 0
      }
    },
    {
      title: 'CREADO POR',
      dataIndex: 'created_by_detail.business_user_name',
      key: 'created_by_detail',
      width: '25%',
      ellipsis: true
    },
    {
      title: 'CREACIÓN',
      dataIndex: 'date_created',
      key: 'date_created',
      width: '15%',
      ellipsis: true,
      render: (item) => (
        <Text>{item.slice(0, 10)}</Text>
      )
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: '15%',
      ellipsis: true,
      render: (id, item) => (
        <Button.Group className='gw-pl__table__actions'>
          {
            auth.addOns.find((obj) => obj.add_on === 'SJT') &&
            <Tooltip placement='top' title="Editar">
              <Button type='link' icon='edit' onClick={() => props.dispatch(push(`/situationals/edit/${id}`))} />
            </Tooltip>
          }
          <Tooltip placement='top' title="Ver más">
            <Button type='link' icon='info-circle' onClick={() => { setShowModal(true); setCute(item) }} />
          </Tooltip>
        </Button.Group>
      ),
    },
  ];

  const debouncedSearch = debounce((value) => {
    setSearchValue(value);
  }, 500);

  useEffect(() => {
    const { token } = auth;

    actions
      .fetchAndReturn(token, '/api/v1/gamesandtests/customtests/')
      .then((response) => {
        if (response.status === 200) {
          const tests = response.body.filter(
            (cute) =>
              cute.cute_type === 'KNOWLEDGE' &&
              Object.keys(cute.questions).includes('cute_type') &&
              cute.questions.cute_type === 'SITUATIONAL'
          );

          setGenomaTests(tests);
          setFilteredTests(tests);
        } else {
          throw new Error();
        }
      })
      .catch((error) => {
        message.error('Error al cargar las pruebas.');
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setFilteredTests(
      genomaTests.filter((test) =>
        test.title.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }, [searchValue]);

  return (
    <div className="genoma-tests-container">
      <Breadcrumb separator=">">
        <Breadcrumb.Item onClick={() => history.push('/')}>
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => history.push('/evaluations')}>
          Evaluaciones
        </Breadcrumb.Item>
        <Breadcrumb.Item>Pruebas de juicio situacional</Breadcrumb.Item>
      </Breadcrumb>
      <Title level={2}>Pruebas de juicio situacional</Title>
      <Text>Mide una habilidad a través de las decisiones de los candidatos al enfrentar situaciones realistas.</Text>
      <Row>
        <Col span={16}>
          <Input.Search
            placeholder="Buscar prueba"
            style={{ width: 'min(360px, 100%)' }}
            onChange={(e) => debouncedSearch(e.target.value)}
          />
        </Col>
        <Col span={8}>
          {
            auth.addOns.find((obj) => obj.add_on === 'SJT') &&
            < Row type='flex' justify='end' className='custom-test-table__filters__colbutton__row'>
              <Button
                className='custom-test-table__filters__colbutton__row__button'
                type='primary'
                onClick={() => props.dispatch(push('/situationals/new'))}
              >
                Crear prueba
              </Button>
            </Row>
          }
        </Col>
      </Row >
      <Row>
        <Modal
          width={950}
          visible={showModal}
          onCancel={() => setShowModal(false)}
          title="Detalles"
          footer={[
            <Button key='exit-button' type='primary' onClick={() => setShowModal(false)}>
              Salir
            </Button>]}
        >
          <SituationalPreview cute={cute} modeModal />
        </Modal>
        <Card bodyStyle={{ padding: 0 }} style={{ border: '1px solid #D9D9D9' }}>
          {!genomaTests ? (
            <Row
              style={{
                background: `url("${assetsUrl}Group+1499custom_fields_background.svg") no-repeat center bottom`,
                backgroundSize: 'contain',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Col
                span={8}
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  height: '40em',
                  justifyContent: 'center',
                }}
              >
                <Row type='flex' justify='center' style={{ width: '30em' }}>
                  <img
                    style={{ width: '50%' }}
                    src={`${assetsUrl}genomines/4.png`}
                  />
                </Row>
                <Row type='flex' justify='center' style={{ width: '30em' }}>
                  <Title level={2} style={{ color: '#454545' }}>
                    No existen pruebas de juicio situacional
                  </Title>
                </Row>
              </Col>
            </Row>
          ) : (
            <Table
              columns={columns}
              dataSource={filteredTests}
              pagination={false}
              style={{ height: '35em', maxHeight: '35em' }}
            />
          )}
        </Card>
      </Row>
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GenomaTests);
