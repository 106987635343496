import React from 'react';

import { Row, Typography } from 'antd';
import PropTypes from 'prop-types';

const { Text } = Typography;

export default function EmptySection(props) {
  return (
    <>
      <Row justify="center" type="flex">
        <img src="https://genoma-assets.s3.us-east-2.amazonaws.com/genomin-empty.png" />
      </Row>
      <Row justify="center" type="flex">
        <Text strong>{props.title}</Text>
      </Row>
      {props.subtitle && (
        <Row justify="center" type="flex">
          <Text type="secondary">{props.subtitle}</Text>
        </Row>
      )}
    </>
  );
}

EmptySection.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string || undefined,
};
