
const awsmobile =
{
    "aws_appsync_graphqlEndpoint": process.env.REACT_APP_APPSYNC,
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": process.env.REACT_APP_APPSYNC_KEY,
}


export default awsmobile;