import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'react-router-redux';

import * as actionCreators from '../../actions/data';

// import referenceQuestions from './utils/defaultReferences';

import {
  Breadcrumb,
  Button,
  Col,
  Icon,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Tabs,
  Typography,
} from 'antd';
import './references.scss';
import BulkActions from './Plugins/BulkActions';
import ReferenceTemplatesTable from './ReferenceTemplatesTable';
import useBreadcrumb from '../../hooks/useBreadcrumb';

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;
const { Search } = Input;

function ReferencesDashboard(props) {
  const [searchInput, setSearchInput] = useState();
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [templatesData, setTemplatesData] = useState({
    activ: [],
    archi: [],
  });

  const Breadcrumb = useBreadcrumb(
    [
      {
        path: '/',
        text: 'Home',
      },
      {
        path: 'tools',
        text: 'Gestión',
      },
      {
        path: 'references',
        text: 'Referencias laborales',
      },
    ],
    '>'
  );

  useEffect(() => {
    const business = props.business;
    if (business) {
      const url = `/api/v1/gamesandtests/business/${business.id}/referencetemplates/`;
      props.actions.fetchAndReturn(props.token, url).then((response) => {
        const results = {
          activ: [],
          archi: [],
        };
        results.activ = response.body.results
          .filter(({ status }) => status === 'ACTIV')
          .map((rowData) => ({ ...rowData, key: rowData.id }));
        results.archi = response.body.results
          .filter(({ status }) => status === 'ARCHI')
          .map((rowData) => ({ ...rowData, key: rowData.id }));
        setTemplatesData(results);
      });
    }
  }, [props.business]);

  function handleDuplicate(templateId) {
    message.loading('Duplicando plantilla');
    const url = `/api/v1/gamesandtests/business/${props.business.id}/referencetemplates/`;
    const body = { duplicate: templateId };
    props.actions
      .fetchAndReturn(props.token, url, 'POST', body)
      .then((response) => {
        message.destroy();
        if (response.status >= 400) {
          message.error('No fue posible duplicar la plantilla.', 5);
        } else {
          message.success('¡Plantilla duplicada con éxito!', 5);
        }
        setTemplatesData((prevData) => {
          const newTemplate = { ...response.body, key: response.body.id };
          if (newTemplate.status == 'ACTIV') {
            return {
              ...prevData,
              activ: [...prevData.activ, newTemplate],
            };
          } else {
            return {
              ...prevData,
              archi: [...prevData.archi, newTemplate],
            };
          }
        });
      })
      .catch(() => {
        message.destroy();
        message.error('No fue posible duplicar la plantilla.', 5);
      });
  }

  function toggleArchivedTemplate(templateId, currentStatus) {
    message.loading(
      `${currentStatus == 'ACTIV' ? 'A' : 'Desa'}rchivando plantilla`
    );
    const url = `/api/v1/gamesandtests/business/${props.business.id}/referencetemplates/${templateId}/`;
    const body = { status: currentStatus == 'ACTIV' ? 'ARCHI' : 'ACTIV' };
    props.actions
      .fetchAndReturn(props.token, url, 'PATCH', body)
      .then((response) => {
        message.destroy();
        if (response.status >= 400) {
          message.error(
            `No fue posible ${
              currentStatus == 'ARCHI' ? 'des' : ''
            }archivar la plantilla.`,
            5
          );
        } else {
          message.success(
            `¡Plantilla ${
              currentStatus == 'ARCHI' ? 'des' : ''
            }archivada con éxito!`,
            5
          );
        }
        setTemplatesData((prevData) => {
          const newTemplate = response.body;
          if (newTemplate.status == 'ACTIV') {
            return {
              activ: [...prevData.activ, newTemplate],
              archi: prevData.archi.filter(({ id }) => id != templateId),
            };
          } else {
            return {
              activ: prevData.activ.filter(({ id }) => id != templateId),
              archi: [...prevData.archi, newTemplate],
            };
          }
        });
      })
      .catch(() => {
        message.destroy();
        message.error(
          `No fue posible ${
            currentStatus == 'ARCHI' ? 'des' : ''
          }archivar la plantilla.`,
          5
        );
      });
  }

  function bulkToggleArchivedTemplate() {
    let loadingMsg;
    let loadingMsgConfig = {
      duration: 0,
      key: 'loadingMsg-bulkToggleArchived',
    };
    let resultMsgConfig = {
      duration: 5,
    };

    const currentStatus = selectedRowsData.length && selectedRowsData[0].status;

    loadingMsgConfig['content'] = `${true ? 'A' : 'Desa'}rchivando ${
      selectedRowsData.length
    } plantillas.`;
    loadingMsg = message.loading(loadingMsgConfig);

    const body = { status: currentStatus == 'ACTIV' ? 'ARCHI' : 'ACTIV' };
    let url;

    for (let i = 0; i < selectedRowsData.length; i++) {
      const { id: templateId, name } = selectedRowsData[i];
      url = `/api/v1/gamesandtests/business/${props.business.id}/referencetemplates/${templateId}/`;

      let totalTemplates = 0;

      props.actions
        .fetchAndReturn(props.token, url, 'PATCH', body)
        .then((response) => {
          if (response.status >= 400) {
            message.error(
              `No fue posible ${
                currentStatus == 'ARCHI' ? 'des' : ''
              }archivar la plantilla "${name}".`,
              5
            );
          } else {
            totalTemplates++;
            setTemplatesData((prevData) => {
              const newTemplate = { ...response.body, key: response.body.id };
              if (newTemplate.status == 'ACTIV') {
                return {
                  activ: [...prevData.activ, newTemplate],
                  archi: prevData.archi.filter(({ id }) => id != templateId),
                };
              } else {
                return {
                  activ: prevData.activ.filter(({ id }) => id != templateId),
                  archi: [...prevData.archi, newTemplate],
                };
              }
            });
          }
        })
        .catch(() => {
          message.destroy();
          message.error(
            `No fue posible ${
              currentStatus == 'ARCHI' ? 'des' : ''
            }archivar la plantilla "${name}".`,
            5
          );
        });
    }
    message.destroy('loadingMsg-bulkToggleArchived');
    resultMsgConfig['content'] = `${selectedRowsData.length} plantillas ${
      currentStatus == 'ARCHI' ? 'des' : ''
    }archivadas con éxito.`;
    message.success(resultMsgConfig);
    setSelectedRowsData([]);
  }

  function deleteTemplate(templateObj) {
    const { id: templateId, status, name } = templateObj;
    Modal.confirm({
      title: `Eliminar plantilla de referencias "${name}"`,
      content:
        '¿Estás seguro/a de querer eliminar la plantilla? Una vez eliminada, no podrás recuperarla.',
      closable: true,
      cancelText: 'Cancelar',
      onOk: () => {
        const url = `/api/v1/gamesandtests/business/${props.business.id}/referencetemplates/${templateId}/`;
        props.actions
          .fetchAndReturn(props.token, url, 'DELETE')
          .then((response) => {
            if (response.status >= 400) {
              message.error(`No fue posible eliminar la plantilla.`, 5);
            } else {
              message.success(`¡Plantilla eliminada con éxito!`, 5);
            }
            setTemplatesData((prevData) => {
              if (status == 'ACTIV') {
                return {
                  ...prevData,
                  activ: prevData.activ.filter(({ id }) => id != templateId),
                };
              } else {
                return {
                  ...prevData,
                  archi: prevData.archi.filter(({ id }) => id != templateId),
                };
              }
            });
          })
          .catch(() => {
            message.error(`No fue posible eliminar la plantilla.`, 5);
          });
      },
      onCancel: () => {},
    });
  }

  function bulkDeleteTemplates() {
    let loadingMsg;
    let loadingMsgConfig = {
      duration: 0,
      key: 'loadingMsg-bulkDelete',
    };
    let resultMsgConfig = {
      duration: 5,
    };

    loadingMsgConfig[
      'content'
    ] = `Eliminando ${selectedRowsData.length} plantillas.`;

    Modal.confirm({
      title: `Eliminar ${selectedRowsData.length} plantillas`,
      content:
        '¿Estás seguro/a de querer eliminar las plantillas seleccionadas? Una vez eliminadas, no podrás recuperarlas.',
      closable: true,
      cancelText: 'Cancelar',
      onOk: () => {
        loadingMsg = message.loading(loadingMsgConfig);
        let url;
        for (let i = 0; i < selectedRowsData.length; i++) {
          const { id: templateId, status, name } = selectedRowsData[i];
          url = `/api/v1/gamesandtests/business/${props.business.id}/referencetemplates/${templateId}/`;

          let totalTemplates = 0;

          props.actions
            .fetchAndReturn(props.token, url, 'DELETE')
            .then((response) => {
              if (response.status >= 400) {
                message.error(
                  `No fue posible eliminar la plantilla "${name}".`,
                  5
                );
              } else {
                totalTemplates++;
                setTemplatesData((prevData) => {
                  if (status == 'ACTIV') {
                    return {
                      ...prevData,
                      activ: prevData.activ.filter(
                        ({ id }) => id != templateId
                      ),
                    };
                  } else {
                    return {
                      ...prevData,
                      archi: prevData.archi.filter(
                        ({ id }) => id != templateId
                      ),
                    };
                  }
                });
              }
            })
            .catch(() => {
              message.destroy();
              message.error(
                `No fue posible eliminar la plantilla "${name}".`,
                5
              );
            });
        }
        message.destroy('loadingMsg-bulkDelete');
        resultMsgConfig[
          'content'
        ] = `${selectedRowsData.length} plantillas eliminadas con éxito.`;
        message.success(resultMsgConfig);
        setSelectedRowsData([]);
      },
      onCancel: () => {},
    });
  }

  const handleRowsSelected = (obj) => {
    const prevSelected = selectedRowsData;
    if (prevSelected.includes(obj))
      setSelectedRowsData(
        prevSelected.filter((element) => element.id !== obj.id)
      );
    else setSelectedRowsData([...prevSelected, obj]);
  };

  const handleAllRowsSelected = (allRowsSelected, selectedRows, changeRows) => {
    if (allRowsSelected) setSelectedRowsData(selectedRows);
    else setSelectedRowsData([]);
  };

  return (
    <div className="references-container">
      {Breadcrumb}
      <Row type="flex" style={{ color: '#2B2B2B', alignItems: 'baseline' }}>
        <Col>
          <Icon
            type="arrow-left"
            style={{ display: 'flex', marginRight: 10 }}
            onClick={() => props.dispatch(push('/tools'))}
          />
        </Col>
        <Col>
          <Title>Referencias laborales</Title>
        </Col>
      </Row>

      <Paragraph style={{ fontSize: '16px', fontWeight: 400 }}>
        Desde esta sección podrás visualizar las{' '}
        <strong>plantillas de cuestionarios </strong>
        para enviar a los referenciadores al momento de solicitar referencias a
        los candidatos. Al solicitar referencias a los candidatos se les
        solicitan el nombre y apellido, correo electrónico, nombre de la
        organización donde trabajaron juntos y la relación laboral con el
        referenciador.
      </Paragraph>

      <Row type="flex" justify="space-between" align="middle">
        <Col span={7}>
          <Search
            placeholder="Buscar plantillas"
            onSearch={(input) => setSearchInput(input)}
            onChange={(event) => setSearchInput(event.target.value)}
          />
        </Col>
        <Col>
          <Link to="/references/create">
            <Button type="primary">Crear Plantilla</Button>
          </Link>
        </Col>
      </Row>

      <Row>
        <Tabs
          className="references-tabs"
          defaultActiveKey="1"
          onChange={() => setSelectedRowsData([])}
        >
          <TabPane tab="Activas" key="1">
            <ReferenceTemplatesTable
              status="ACTIV"
              handleRowsSelected={handleRowsSelected}
              handleAllRowsSelected={handleAllRowsSelected}
              selectedRowsData={selectedRowsData}
              templatesData={templatesData['activ']}
              searchInput={searchInput}
              handleDuplicate={handleDuplicate}
              toggleArchivedTemplate={toggleArchivedTemplate}
              deleteTemplate={deleteTemplate}
            />
          </TabPane>
          <TabPane tab="Archivadas" key="2">
            <ReferenceTemplatesTable
              status="ARCHI"
              handleRowsSelected={handleRowsSelected}
              handleAllRowsSelected={handleAllRowsSelected}
              selectedRowsData={selectedRowsData}
              templatesData={templatesData['archi']}
              searchInput={searchInput}
              handleDuplicate={handleDuplicate}
              toggleArchivedTemplate={toggleArchivedTemplate}
              deleteTemplate={deleteTemplate}
            />
          </TabPane>
        </Tabs>
        <BulkActions
          bulkDeleteTemplates={bulkDeleteTemplates}
          bulkToggleArchivedTemplate={bulkToggleArchivedTemplate}
          handleAllRowsSelected={handleAllRowsSelected}
          selectedRows={selectedRowsData}
        />
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    business: state.auth.currentBusiness,
    businessPermissions: state.auth.businessPermissions,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferencesDashboard);
