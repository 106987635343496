import React from 'react';
import { Link } from 'react-router-dom';

import { Col, Icon, Row, Typography } from 'antd';
import i18n from '../../../i18n';
import ParseHtml from '../../../components/parseHtml';

const { Text } = Typography;

export function MsgPersonalUser({ notification, callback, date }) {
  return (
    <Row type='flex' justify='space-between'>
      <Col span={date ? 14 : 24}>
        <Link to={`/jobapplication/${notification.job_application.id}#messages`} onClick={callback}>
          <div className='custom-notification-item'>
          { notification.status === 'CREATED' && <Icon type="exclamation-circle" style={{color: '#52c41a', fontSize: 18, marginRight: 15}}/> }
            <Text> 
              {ParseHtml(
                i18n.t(
                  'notifications__new_message',
                  {
                    business: `<strong>${notification.business_user?.business_name}</strong>`,
                    jobapp: `<strong>${notification.job_application.job_application}</strong>`,
                    ns: 'notifications',
                  }
                )
              )}
              </Text>
          </div>
        </Link>
      </Col>
      <Col span={date ? 10 : 0}>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          {date}
        </div>
      </Col>
    </Row>
  )
}
