import React, { useEffect, useState, useRef } from 'react';
import {
  Row,
  Col,
  Typography,
  Icon,
  Tabs,
  Modal,
  Checkbox,
  Button,
} from 'antd';

import Tour from 'reactour';
import useBoolean from '../../../../hooks/useBoolean';

import tourConfigTable from '../config/tourConfig';
import '../config/tourStyles.scss';

const { Text, Title } = Typography;

const ModalTour = ({ visible, handleModal, actions }) => {
  const [notShowAgain, setNotShowAgain] = useState(() =>
    JSON.parse(localStorage.getItem('showModalTour'))
  );

  const [isTourOpen, handleTourOpen] = useBoolean(false);

  const tourMethodsRef = useRef(null);

  const handleStepAction = (stepIndex) => {
    if (stepIndex === 2 || stepIndex === 4) {
      actions.handleDrawer.off();
    }
  };

  return (
    <>
      <Modal
        title={
          <div
            style={{
              display: 'flex',
              gap: '1rem',
              height: 60,
              alignItems: 'center',
            }}
          >
            <img
              src="https://genoma-assets.s3.us-east-2.amazonaws.com/inspiration.svg"
              style={{ height: '100%' }}
            />
            <Text strong style={{ color: '#5E5E5E' }}>
              ¡Optimizamos el tablero para incluir nuevas funcionalidades de
              gestión!
            </Text>
          </div>
        }
        visible={visible}
        width="min(700px, 90%"
        footer={
          <Row
            type="flex"
            justify="space-between"
            style={{ width: '100%' }}
            align="middle"
          >
            <Checkbox
              defaultChecked={
                notShowAgain !== null ? !notShowAgain : false
              }
              onChange={(e) => {
                localStorage.setItem('showModalTour', !e.target.checked);
                setNotShowAgain(e.target.checked);
              }}
            >
              No volver a mostrar.
            </Checkbox>
            <Button
              type="primary"
              onClick={() => {
                handleModal.off();
                handleTourOpen.on();
              }}
            >
              ¡Explorar!
            </Button>
          </Row>
        }
        onCancel={handleModal.off}
      >
        <div className="table-tour-modal-content">
          <div className="table-tour-modal-content__texts">
            <p>¿Qué podrás hacer ahora?</p>
            <div className="table-tour-modal-content__news">
              {[
                'Selector de columnas: Agregar o quitar propiedades del tablero, según lo que necesites.',
                'Descartados: La información de la persona quedará almacenada en la etapa donde se descarte.',
                'Filtros: Los filtros ahora pueden gestionarse de manera acumulativa, agregando los que tu desees.',
                'Acciones Masivas: En la pestaña Candidatos podrás ver y gestionar a todos los candidatos que participan de tu proceso, sin importar la etapa en la que se encuentren.',
                'Documentos: Acceder rápidamente a información sobre documentos de tus candidatos',
              ].map((phrase) => {
                const [title, text] = phrase.split(':');
                return (
                  <div className="table-tour-modal-content__news__item">
                    <Icon type="check" />
                    <Text>
                      <strong>{title}:</strong> {text}
                    </Text>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="table-tour-modal-content__texts">
            <p>¡Valoramos tu opinión!</p>
            <span>
              Tu opinión es importante para nosotros.{' '}
              <strong>
                Tus comentarios y recomendaciones nos ayudan para seguir
                mejorando la plataforma.
              </strong>
            </span>
          </div>
          <span>
            Puedes dejarnos todos tus comentarios y opiniones sobre esta nueva
            navegación en{' '}
            <a>
              <u>este link</u>
            </a>
            .
          </span>
        </div>
      </Modal>
      <Tour
        steps={tourConfigTable(actions)}
        isOpen={isTourOpen}
        onRequestClose={() => handleTourOpen.off()}
        badgeContent={(curr, tot) => `${curr} de ${tot}`}
        showNavigation={false}
        showButtons={true}
        startAt={0}
        nextButton={<Button>Siguiente</Button>}
        prevButton={<Button>Anterior</Button>}
        lastStepNextButton={
          <Button onClick={handleTourOpen.off}>Finalizar</Button>
        }
        onAfterOpen={({ close }) => {
          tourMethodsRef.current = { close };
        }}
        getCurrentStep={handleStepAction}
      />
    </>
  );
};

export default ModalTour;
