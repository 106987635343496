export const filterByTagFn = (value, arr) => {
    return arr.filter(({ tags }) =>
      tags.includes(value)
    );
};

export const filterByOwnerFn = (composeObj) => {
  if (composeObj.filterByNameOrSubject === undefined) {
    return composeObj.mailTemplates;
  } else {
    return composeObj.mailTemplates.filter(({ business }) =>
      business.name.includes(composeObj.filterByOwner)
    );
  }
};

export const filterByNameOrSubjectFn = (value, arr) => {
  const valueNormalize = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  return arr.filter(
    (template) =>
      template.name
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(valueNormalize.toLowerCase()) ||
      template.subject
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(valueNormalize.toLowerCase())
  );
};

export const filterCompose =
  (...fns) =>
  (filterObjInFunc) =>
    fns.reduceRight((y, f) => f(y), filterObjInFunc);
