import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Icon,
  Input,
  message,
  Row,
  Spin
} from 'antd';
import i18n from '../../i18n';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../actions/data';
import * as authActionCreators from '../../actions/auth';

import PropTypes from 'prop-types';

import Message from './Message';
import MceEditor from '../../containers/BusinessDashboard/NewProcessFormV2/components/mceeditor/MceEditor'
import './styles.scss'

const content = "Mensaje mensaje mensaje mensaje mensaje mensaje mensaje mensaje mensaje mensaje mensaje mensaje mensaje mensaje mensaje mensaje mensaje mensaje mensaje"
const candidateLogo = "https://genoma-assets.s3.us-east-2.amazonaws.com/genomin-profile.svg"

const tmpMsgArray = [
  { signature: 'bu name', to_user: 'personal_user', msg_content: content + ' 1', date: 'dd/mm/yyyy hh:mm', sender: 'business' },
  { signature: 'bu name', to_user: 'personal_user', msg_content: content + ' 2', date: 'dd/mm/yyyy hh:mm', sender: 'business' },
  { signature: 'personal name', to_user: 'bu', msg_content: content + ' 3', date: 'dd/mm/yyyy hh:mm', sender: 'personal' },
  { signature: 'bu name', to_user: 'personal_user', msg_content: content + ' 4', date: 'dd/mm/yyyy hh:mm', sender: 'business' },
]

function MessagesView({ callback, userJobApp, userType, chat, toUser, toUserName, ...rest }) {

  const [msgs, setMsgs] = useState([]);
  const [currentMsg, setCurrentMsg] = useState('');
  const [subject, setSubject] = useState(null);
  const [chatDisabled, setChatDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const msgPosition = {
    'business': userType === 'business' ? 'start' : 'end',
    'personal': userType === 'business' ? 'end' : 'start'
  };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth", bottom: 0 });
  }

  useEffect(() => {
    fetchMessages();
    updateMessages()
  }, []);

  useEffect(() => {
    setChatDisabled(chat ? chat.is_disabled : true);
  }, [chat]);

  useEffect(() => {
    scrollToBottom();
  }, [msgs]);

  const getAvatar = (sender, obj) => {
    switch (sender) {
      case 'business':
        return <img src={rest.logoBusiness} className="msg-logo" />;
      case 'personal':
        let initials = obj.signature.split(' ').map((n)=>n[0]).join('');
        initials = initials.length >= 2 ? initials.substring(0, 2) : initials;
        return (
          <div className="msg-candidate-avatar">
            {obj.signature ? initials : 'G'}
          </div>
        )
      default:
        return
    }
  }

  const fetchMessages = async () => {
    // candidate.ui is the userjobapp_id, personaluser_id is the candidate uuid
    const url = `/api/v2/messaging/userjobapplications/${userJobApp}/messages/`;
    const response = await rest.actions.fetchAndReturn(rest.token, url);
    const updatedMsgs = response.body ? response.body : []
    setMsgs(updatedMsgs);
    //  setMsgs(tmpMsgArray);
  }

  const updateMessages = async () => {
    if (chat && chat.id) {
      const url = `/api/v2/messaging/bulkchats/${chat.id}/`;
      const response = await rest.actions.fetchAndReturn(rest.token, url, 'PUT');
      const updatedNotifications = response.body ? response.body.notifications_updated_count : 0
      rest.authActions.updateNotificationCount(oldState => oldState - updatedNotifications);
      if (callback) {
        callback();
      }
    }
  }

  const sendMessage = async () => {
    if (currentMsg && currentMsg.trim() !== '') {
      const newMessage = {
        msg_content: currentMsg,
        to_user: toUser,
        subject: subject,
      };
      const url = `/api/v2/messaging/userjobapplications/${userJobApp}/messages/`;
      setLoading(true);
      const response = await rest.actions.fetchAndReturn(rest.token, url, 'POST', newMessage);
      if (response.status < 400) {
        setSubject(null);
        setMsgs([...msgs, response.body]);
        setLoading(false);
      }
      else {
        message.error(i18n.t('messages__error'));
        setLoading(false);
      }
    }
    setCurrentMsg('');
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      sendMessage();
    }
  };

  const insertName = () => {
    const body = currentMsg.indexOf('</p>') > 0 ? (
      `${currentMsg.slice(0, currentMsg.length - 4)} ${toUserName} </p>`
    ) : (
      `<p>${toUserName} </p>`
    );
    setCurrentMsg(body);
  };

  return (
    <>
      <Row type='flex' align='middle' justify='center' style={{ height: '77vh', overflow: 'scroll' }}>
        <Col xs={22} sm={22} md={22} lg={20} style={{ overflow: 'auto' }}>
          {
            msgs.map((obj, idx) =>
              <Row type='flex' align='middle' justify={msgPosition[obj.sender]} key={idx}>
                <Col xs={20} sm={20} md={20} lg={18}>
                  <Message msgData={obj} avatar={getAvatar(obj.sender, obj)} position={msgPosition[obj.sender]} />
                </Col>
              </Row >
            )
          }

          {
            msgs.length > 0 ?
              null
              :
              <>
                <Row type="flex" justify="center">
                  <Col xs={22} sm={22} md={22} lg={22}>
                    <div style={{ textAlign: 'center', color: '#D9D9D9', marginTop: 20, marginBottom: 20 }}>
                      <Icon type='message' theme={userType == 'business' ? 'twoTone' : 'outlined'} twoToneColor={'#F175A5'} style={{ fontSize: 80 }} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div style={{ textAlign: 'center', fontSize: 14, marginBottom: 100 }}>
                      {i18n.t('profile__without_mesaages', {ns: 'candidateProfile'})}
                    </div>
                  </Col>
                </Row>
              </>
          }
          <Row type="flex" align="bottom">
            {chatDisabled || chatDisabled===null ?
              <>
                <Col xs={2} sm={2} md={1} lg={1}>
                  <div style={{ textAlign: 'left', color: '#D9D9D9', fontSize: 16 }}>
                    <Icon type='message' style={{ fontSize: 24 }} />
                  </div>
                </Col>
                <Col xs={22} sm={22} md={23} lg={23}>
                  <div style={{ fontSize: 16 }}>
                    {
                      userType === 'personal' ? i18n.t('messages__disabled') :
                        i18n.t('profile__message_note', {ns: 'candidateProfile'})
                    }
                  </div>
                </Col>
              </>
              :
              <>
                <Col xs={24} sm={24} md={24} lg={24}>
                  {
                    /**
                    userType == 'business' &&
                    <Input style={{ marginBottom: 20 }} onChange={(e) => setSubject(e.target.value)} value={subject} placeholder="Asunto..." />
                    */
                  }
                  <MceEditor
                    name="body"
                    readOnlyBody={''}
                    handleChange={() => (e) => setCurrentMsg(e.target.value)}
                    body={currentMsg}
                    disabled={false}
                    handleAttachFile={null}
                    plugins={"lists,anchor,link,visualchars,paste"}
                    onKeyDown={handleKeyDown}
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Row type="flex" align="bottom" justify="space-between">
                    {
                      userType == 'business' &&
                      <Col xs={10} sm={10} md={6} lg={6}>
                        <Button type="secondary" icon="folder" onClick={insertName} className="msg-footer">
                        {i18n.t('profile__insert_name', {ns: 'candidateProfile'})}
                        </Button>
                      </Col>
                    }
                    <Col xs={10} sm={10} md={6} lg={6}>
                      <Spin spinning={loading}>
                        <Button type="primary" icon="right" onClick={sendMessage} className="msg-footer" style={{ maxWidth: 200 }}>
                          {i18n.t('commons__send')}
                        </Button>
                      </Spin>
                    </Col>
                  </Row>
                </Col>
              </>
            }
          </Row>
          <Row>
            <div id="scroll-ref" ref={messagesEndRef} />
          </Row>
        </Col>
      </Row>
    </>
  );
};

MessagesView.propTypes = {
  userType: PropTypes.string.isRequired,
  logoBusiness: PropTypes.string.isRequired,
  msgArray: PropTypes.arrayOf(PropTypes.shape({
    msg_content: PropTypes.string,
    signature: PropTypes.string,
    date: PropTypes.string,
    sender: PropTypes.string
  }))
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    authActions: bindActionCreators(authActionCreators, dispatch),
  };
};

const mapStateToProps = state => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagesView);