import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Typography,
  Icon,
  Modal,
  Checkbox,
  Button,
} from 'antd';

import Tour from 'reactour';
import useBoolean from '../../../../hooks/useBoolean';

import tourConfig from './tourConfig/tourConfig';
import './tourConfig/tourStyles.scss';

const { Text } = Typography;

const ModalTour = ({ visible, handleModal, actions }) => {
  const [notShowAgain, setNotShowAgain] = useState(() =>
    JSON.parse(localStorage.getItem('showCreateProcessTour'))
  );

  const [isTourOpen, handleTourOpen] = useBoolean(false);

  const tourMethodsRef = useRef(null);

  const handleStepAction = (stepIndex) => {
    // Scroll is not working, so we use this workaround
    if (stepIndex === 50) {
      const element = document.querySelector('#jobappinfo-company')
      const topPos = element.getBoundingClientRect().top

      window.scrollTo({
        top: topPos, // scroll so that the element is at the top of the view
        behavior: 'smooth' // smooth scroll
      })
    }
    if (stepIndex === 30) {
      actions.setActiveStep(1)
    }
  };


  return (
    <>
      <Modal
        title={
          <div
            style={{
              display: 'flex',
              gap: '1rem',
              height: 60,
              alignItems: 'center',
            }}
          >
            <img
              src="https://genoma-assets.s3.us-east-2.amazonaws.com/inspiration.svg"
              style={{ height: '100%' }}
            />
            <Text strong style={{ color: '#5E5E5E' }}>
              ¡Optimizamos la creación de procesos para incluir nuevas funcionalidades!
            </Text>
          </div>
        }
        visible={visible}
        width="min(700px, 90%"
        footer={
          <Row
            type="flex"
            justify="space-between"
            style={{ width: '100%' }}
            align="middle"
          >
            <Checkbox
              defaultChecked={
                notShowAgain !== null ? !notShowAgain : false
              }
              onChange={(e) => {
                localStorage.setItem('showCreateProcessTour', !e.target.checked);
                setNotShowAgain(e.target.checked);
              }}
            >
              No volver a mostrar.
            </Checkbox>
            <Button
              type="primary"
              onClick={() => {
                handleModal.off();
                handleTourOpen.on();
              }}
            >
              ¡Explorar!
            </Button>
          </Row>
        }
        onCancel={handleModal.off}
      >
        <div className="table-tour-modal-content">
          <div className="table-tour-modal-content__texts">
            <p>¿Que podrás hacer ahora?</p>
            <div className="table-tour-modal-content__news">
              {[
                'Utiliza Genomas predeterminados: Haz clic en un Genoma predeterminado para crear un proceso de selección completo de forma rápida.',
                'Configura etapas: Al agregar o quitar las etapas dentro de tu proceso.',
                'Añade evaluaciones: Desde la biblioteca de Genomawork incluye evaluaciones en una etapa.',
                'Personaliza la apariencia: Ajusta la vista inicial de tu proceso para potenciar tu marca empleadora.',
                'Difunde a tu proceso: Comparte el puesto con el talento que estás buscando a través de distintas herramientas.',
              ].map((phrase) => {
                const [title, text] = phrase.split(':');
                return (
                  <div className="table-tour-modal-content__news__item">
                    <Icon type="check" />
                    <Text>
                      <strong>{title}:</strong> {text}
                    </Text>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="table-tour-modal-content__texts">
            <p>¡Valoramos tu opinión!</p>
            <span>
              Tu opinión es importante para nosotros.{' '}
              <strong>
                Tus comentarios y recomendaciones nos ayudan para seguir
                mejorando la plataforma.
              </strong>
            </span>
          </div>
          <span>
            Contáctanos para obtener más información y hacernos llegar tus comentarios sobre esta nueva navegación.
          </span>
        </div>
      </Modal>
      <Tour
        steps={tourConfig(actions)}
        isOpen={isTourOpen}
        onRequestClose={() => handleTourOpen.off()}
        badgeContent={(curr, tot) => `${curr} de ${tot}`}
        showNavigation={false}
        showButtons={true}
        startAt={0}
        onBeforeClose={() => actions.setShowShareProcessModal(false)}

        //nex and prev button receives a react node and we cannot nest a button inside a button
        nextButton={<div className='ant-btn' style={{ paddingTop: 3 }}>Siguiente</div>}
        prevButton={<div className='ant-btn' style={{ paddingTop: 3 }}>Anterior</div>}
        lastStepNextButton={
          <div
            className='ant-btn'
            style={{ paddingTop: 3 }}
            onClick={handleTourOpen.off}
          >
            Finalizar
          </div>
        }
        onAfterOpen={({ close }) => {
          tourMethodsRef.current = { close };
        }}
        getCurrentStep={handleStepAction}
      />
    </>
  );
};

export default ModalTour;
