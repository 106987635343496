import i18n from '../../i18n'

export const countryData = [
  {
    name: 'Latinoamérica',
    code: 'LATAM'
  },
  {
    name: 'Argentina',
    code: 'AR'
  },
  {
    name: 'Bolivia',
    code: 'BO'
  },
  {
    name: 'Chile',
    code: 'CL'
  },
  {
    name: 'Colombia',
    code: 'CO'
  },
  {
    name: 'Ecuador',
    code: 'EC'
  },
  {
    name: 'México',
    code: 'MX'
  },
  {
    name: 'Perú',
    code: 'PE'
  },
  {
    name: 'Panamá',
    code: 'PA'
  },
  {
    name: 'Paraguay',
    code: 'PY'
  },
  {
    name: 'Uruguay',
    code: 'UY'
  }
]

export const jobsForCategory = [
  {
    name: i18n.t('job_portal__opt__1'),
    img: 'https://genoma-assets.s3.us-east-2.amazonaws.com/abogados.png',
    words: i18n.t('job_portal__opt__1')
  },
  {
    name: i18n.t('job_portal__opt__2'),
    img: 'https://genoma-assets.s3.us-east-2.amazonaws.com/comunicaciones.png',
    words: i18n.t('job_portal__opt__2')
  },
  {
    name: i18n.t('job_portal__opt__3'),
    img: 'https://genoma-assets.s3.us-east-2.amazonaws.com/disen__o.png',
    words: i18n.t('job_portal__opt__3')
  },
  {
    name: i18n.t('job_portal__opt__6'),
    img: 'https://genoma-assets.s3.us-east-2.amazonaws.com/operativos.png',
    words: i18n.t('job_portal__opt__6')
  },
  {
    name: i18n.t('job_portal__opt__5'),
    img: 'https://genoma-assets.s3.us-east-2.amazonaws.com/recursos_humanos.png',
    words: i18n.t('job_portal__opt__5')
  },
  {
    name: i18n.t('job_portal__opt__4'),
    img: 'https://genoma-assets.s3.us-east-2.amazonaws.com/tech.png',
    words: 'developer programador full stack'
  },
]