import React from 'react'
import ReactDOM from 'react-dom'
import './RichTextEditor.scss'
import { Button, Icon, Tooltip } from 'antd'


export const Menu = React.forwardRef(
  (
    { className, ...props },
    ref
  ) => (
    <div
      {...props}
      data-test-id="menu"
      ref={ref}
      className='rteditor__menu'
    />
  )
)

export const Portal = ({ children }) => {
  return typeof document === 'object'
    ? ReactDOM.createPortal(children, document.body)
    : null
}

export const Toolbar = React.forwardRef(
  (
    { className, ...props },
    ref
  ) => (
    <Menu
      {...props}
      ref={ref}
      className='rteditor__toolbar'
    />
  )
)

export const TemplateButton = ({ 
  character,
  tooltipTitle, 
  onMouseDown, 
  iconType,
  iconStyle={ color: '#828282' } 
}) => (
  <Tooltip title={tooltipTitle}>
    <Button
      type='link'
      style={{ color: '#828282' }}
      onMouseDown={onMouseDown ? (e) => onMouseDown(e) : null}
    >
      {character ? character : <Icon type={iconType} style={iconStyle} />}
    </Button>
  </Tooltip>
);
