import React from 'react';
import {
  Row,
  Col,
  Typography
} from 'antd';

const { Text } = Typography;

const ColorSwatch = ({ margin }) => {
  return (
    <Row type='flex' style={{ margin: `${margin}` }}>
      <Col style={{ padding: '10px', border: '1px solid #D9D9D9' }}>
        <Row>
          <Col style={{ width: '15px', height: '15px', background: '#A50026' }} />
          <Col style={{ width: '15px', height: '15px', background: '#D73027' }} />
          <Col style={{ width: '15px', height: '15px', background: '#F46D43' }} />
        </Row>
        <Row>
          <Text strong>
            Distancia baja entre palabras
          </Text>
        </Row>
        <Row>
          <Text>
            0 al 72
          </Text>
        </Row>
      </Col>

      <Col style={{ padding: '10px', border: '1px solid #D9D9D9', margin: '0px 20px' }}>
        <Row>
          <Col style={{ width: '15px', height: '15px', background: '#FDAE61' }} />
          <Col style={{ width: '15px', height: '15px', background: '#FEE08B' }} />
          <Col style={{ width: '15px', height: '15px', background: '#FFFFBF' }} />
          <Col style={{ width: '15px', height: '15px', background: '#D9EF8B' }} />
        </Row>
        <Row>
          <Text strong>
            Distancia promedio entre palabras
          </Text>
        </Row>
        <Row>
          <Text>
            73 al 83
          </Text>
        </Row>
      </Col>

      <Col style={{ padding: '10px', border: '1px solid #D9D9D9' }}>
        <Row>
          <Col style={{ width: '15px', height: '15px', background: '#A6D96A' }} />
          <Col style={{ width: '15px', height: '15px', background: '#66BD63' }} />
          <Col style={{ width: '15px', height: '15px', background: '#1A9850' }} />
          <Col style={{ width: '15px', height: '15px', background: '#1A9850' }} />
          <Col style={{ width: '15px', height: '15px', background: '#006837' }} />
        </Row>
        <Row>
          <Text strong>
            Distancia alta entre palabras
          </Text>
        </Row>
        <Row>
          <Text>
            84 al 100+
          </Text>
        </Row>
      </Col>
    </Row>
  );
}

export default ColorSwatch;