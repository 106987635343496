import React, { useState, useMemo, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { isMobile } from 'react-device-detect';
import {
  Button,
  Modal,
  List,
  Skeleton,
  Avatar,
  Typography,
  Row,
  Col,
  Icon,
  Input,
  Tabs,
} from 'antd';

const { TabPane } = Tabs;

import i18n from '../../../i18n';

const {
  Title
} = Typography;

const MobileTabsWrapper = ({ children }) => {
  return (
    <Row gutter={[20, 20]} style={{ height: '100%' }}>
      {isMobile ? 
        <Tabs style={{ height: '100%' }}>
          {children.map((child, index) => {
            return (
              <TabPane tab={child.props.title} key={index} style={{ height: '100%' }}>
                <Row>
                  <Col xs={24} style={{ height: '100%' }}>
                    {child}
                  </Col>
                </Row>
              </TabPane>
            )})}
        </Tabs> : 
        children.map((child, index) => {
        return (
          <Col xs={24} sm={12} style={{ height: '100%' }}>
            {child}
          </Col>
        ) 
        })}
    </Row>
  )
}


const Map = ({
  onClick,
  onIdle,
  children,
  style,
  ...options
}) => {
  const ref = React.useRef(null);
  const [map, setMap] = React.useState();

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, options));
    }
  }, [ref, map]);

  React.useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );
      if (onClick) {
        map.addListener("click", onClick);
      }
    }
  })
  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useEffect(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map });
        }
      })}
    </>
  )
}

const normalIcon = 'https://genoma-assets.s3.us-east-2.amazonaws.com/disabled_map_pin.svg';
const selectedIcon = 'https://genoma-assets.s3.us-east-2.amazonaws.com/enabled_map_pin.svg';

const Marker = ({ addBranch, centerMarker, root, iconImg, ...options }) => {
  const [marker, setMarker] = React.useState(null);
  const baseIcon = {
    scaledSize: new google.maps.Size(50, 50),
    url: iconImg
  }

  React.useEffect(() => {
    if (!marker) {
      const newMarker = new google.maps.Marker()
      setMarker(newMarker);
    }
    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    //options = { ...options, icon: { ...baseIcon } }

    if (marker) {
      if (options.infoContent) {
        // Feisimo esto, pero solo recibe elementos como string
        const infowindow = new google.maps.InfoWindow();
        // Cargar el listener para mostrar la ventana de info para postular
        // no funciona con componentes react, ver otra forma
        // PD. se setea dos veces este listener, ver como evitarlo

        const newIcon = {
          scaledSize: new google.maps.Size(50, 50),
          url: iconImg
        };

        marker.setOptions({ ...options, icon: newIcon });

        if (google.maps.event.hasListeners(marker, 'click') === false) {
          marker.setOptions({ ...options, icon: baseIcon });
          marker.addListener("click", () => {
            infowindow.setContent(ReactDOMServer.renderToString(options.infoContent));
            addBranch()
            centerMarker()
          })
        }
      }
      else {
        marker.setOptions({ ...options, icon: baseIcon });
      }
    }

  }, [marker, options]);
  return null;
};

export default function MultipleJobs({
  selectedJobApplication,
  setSelectedJobApplication,
  jobApplications,
  reset,
  handleApplyAll,
  theme
}) {
  const [selectedBranches, setSelectedBranches] = useState({});
  const [initialMarker, setInitialMarker] = useState({ lat: 0, lng: 0 });
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const [currentJobApps, setCurrentJobApps] = useState([]);
  const [filteredJobApps, setFilteredJobApps] = useState([]);

  useEffect(() => {
    const sortedJobApps = jobApplications.filter(
      x => selectedJobApplication.branches.includes(x.id))
      .sort((a, b) => a.job_application.localeCompare(b.job_application));
    setCurrentJobApps(sortedJobApps);
    setFilteredJobApps(sortedJobApps);
    navigator.geolocation.getCurrentPosition((position) => {
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;
      setMapCenter({ lat, lng });
      setInitialMarker({ lat, lng });
    }, (e) => {
      console.log(e);
    })

  }, []);

  const sortJobAppList = (list) => {
    list.sort((a,b) => (selectedBranches[b.id]!==undefined)-(selectedBranches[a.id]!==undefined) || (a.job_application.localeCompare(b.job_application)))
    return list
  };

  const addBranch = (jobApplicationId) => {
    setSelectedBranches((currentJobApplications) => {
      if (currentJobApplications[jobApplicationId]) {
        delete currentJobApplications[jobApplicationId];
        return { ...currentJobApplications };
      }
      return { ...currentJobApplications, [jobApplicationId]: true }
    })
  }

  const selectedCount = useMemo(() => {
    return Object.keys(selectedBranches).length
  }, [selectedBranches])

  const handleApply = () => {
    handleApplyAll(selectedJobApplication.id, selectedBranches)
  }

  const populateMap = () => {
    const jobMarkers = [];
    if (selectedJobApplication && jobApplications) {
      jobApplications.filter(
        x =>
          selectedJobApplication.branches.includes(x.id)
      ).forEach((jobapp) => {
        if (jobapp.geoloc) {
          const [lat, lng] = jobapp.geoloc;
          const position = { lat, lng }
          const tmpMarker = (
            <Marker
              addBranch={() => addBranch(jobapp.id)}
              centerMarker={() => setMapCenter(position)}
              key={`mk-${jobapp.id}`}
              position={position}
              iconImg={selectedBranches[jobapp.id] ? selectedIcon : normalIcon}
              
              infoContent={
                <></>
                /*
                <Row>
                  {jobapp.job_application}
                  <Button id="markerButton" style={{ ...theme }}>{i18n.t('job_portal__apply')}</Button>
                </Row>  
                */   
              }
              
            />
          )
          jobMarkers.push(tmpMarker)
        }
      })
    }
    return jobMarkers;

  }

  const filterList = (e) => {
    const value = e.target.value;
    if (value && value === '') {
      setFilteredJobApps(currentJobApps);
    }
    else {
      const filteredList = currentJobApps.filter(obj =>
        obj.job_application.toLowerCase().includes(value.toLowerCase()) || obj.address.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredJobApps(filteredList);
    }
  }

  return (
    <Modal
      visible
      width={isMobile ? '100%' : '80%'}
      style={{ top: isMobile ? 10 : '', height:  isMobile ? '100vh' : '100%' }}
      bodyStyle={{ height: isMobile ? '90vh' : '100%' }}
      footer={[
        <Button
          key='mj-cancel'
          onClick={reset}
        >{i18n.t('commons__cancel')}</Button>,
        <Button
          key='mj-submit'
          style={{ ...theme }}
          onClick={() => handleApply()}
          disabled={Object.keys(selectedBranches).length == 0}
        >{i18n.t('commons__continue')}</Button>
      ]}
      onCancel={() => setSelectedJobApplication(null)}
    >
      <Row style={{ height: isMobile ? '70vh' : '70vh' }}>
        <Title level={3}>
          {i18n.t('job_portal__select_branches')} {selectedCount ? `(${selectedCount})` : null}
        </Title>
        <Row style={{ marginBottom: 15, paddingRight: 60 }}>
          <Col xs={24} sm={12}>
            <Input.Search allowClear onChange={filterList} placeholder={i18n.t('job_portal__search')} />
          </Col>
        </Row>
        <MobileTabsWrapper>
          <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY} title='Mapa'>
            <Map center={mapCenter} zoom={12} style={{ height: '60vh' }}>
              <Marker infoContent={null} key={`mk-self`} position={initialMarker} root={true} iconImg={selectedIcon} />
              {populateMap()}
            </Map>
          </Wrapper>
          <List
            title='Lista'
            style={{ overflow: 'auto', height: '60vh' }}
            itemLayout="horizontal"
            // loadMore={loadMore}
            dataSource={sortJobAppList(filteredJobApps)}
            renderItem={(item, index) => (
              <List.Item
                key={item.id}
              >
                <Skeleton title={false} loading={item.loading} active>
                  <List.Item.Meta
                    title={<span>{item.job_application}</span>}
                    description={item.address ? `${item.address}` : 'Sin dirección'}
                  />
                  <div>{item.short_description}</div>
                  <Button
                    onClick={() => addBranch(item.id)}
                    style={selectedBranches[item.id] ? { ...theme } : {}}
                  >{selectedBranches[item.id] ? <Icon type='check' /> : <Icon type='plus' />}</Button>
                </Skeleton>
              </List.Item>
            )}
          />
          
        </MobileTabsWrapper>
      </Row>
    </Modal>
  )
}