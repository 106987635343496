import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { textLevels } from "../../containers/CustomTest/CustomTestCreate/utils";
import "./MultiRangeSlider.scss";

const CustomMultiRangeSlider = ({ cuteType, levels, height, onChange, multiple, ranges, segs, thumbs, setRanges, setSegs, setThumbs, questionNumber }) => {
  const refs = useRef(Array.from({ length: 4 }, () => useRef(0)));
 
  const suffix = cuteType === 'situationals' ? '%' : '';

  const referenceChange = (thumbNumber) => {
    let thumb1 = parseInt(refs.current[0].current.value)
    let thumb2 = parseInt(refs.current[1].current?.value)
    let thumb3 = parseInt(refs.current[2].current?.value)
    let thumb4 = parseInt(refs.current[3].current?.value)

    const orderedThumbs = [thumb1, thumb2, thumb3, thumb4].filter(x => x !== undefined).sort((a, b) => a - b);
    const newSegs = Array.from({ length: levels }).fill(0).map((_, index) => {
      if (index === 0) {
        return { min: 0, max: orderedThumbs[index] + 1, refRange: orderedThumbs[index] }
      } else if (index === levels - 1) {
        return { min: orderedThumbs[index - 1] + 1, max: questionNumber + 1 }
      } else {
        return { min: orderedThumbs[index - 1] + 1, max: orderedThumbs[index] + 1, refRange: orderedThumbs[index] }
      }
    });
    const newRanges = Array.from({ length: levels }).fill(0).map((_, index) => {
      if (index === 0) {
        return orderedThumbs[index]
      } else if (index === levels - 1) {
        return questionNumber - orderedThumbs[index - 1]
      } else {
        return orderedThumbs[index] - orderedThumbs[index - 1]
      }
    });
    if (newRanges.some(x => x < 1)) {
      return;
    }
    const newThumbs = [thumb1, thumb2, thumb3, thumb4].sort((a, b) => a - b);
    setRanges(newRanges);
    setSegs(newSegs);
    setThumbs(newThumbs);
    // TODO: usar onChange
    onChange(newSegs);
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        {/* Ranges description */}
        {segs.slice(0, levels).map((_, level) => {
          return (
          <div key={`${level}-range`} style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '10px', height: '10px', background: textLevels[levels][level].border, margin: '0 5px 0 0' }} />
            <p>{textLevels[levels][level].title}: {segs[level].min}{suffix} a {segs[level].max-1}{suffix}</p>
          </div>
        )})}
      </div>
      <div className="container">
        {/* Sliders */}
        {segs.slice(0, levels-1).map((_, level) => (
          <input
            key={`thumb-${level}`}
            ref={refs.current[level]}
            type='range'
            className={`thumb thumb--zindex-${level + 3}`}
            min={0}
            max={questionNumber}
            value={thumbs[level]}
            onChange={() => referenceChange(`t${level + 1}`)}
          />
        ))}
        <div className="custom-multirange-slider">
          {/* Ranges bar */}
          {segs.slice(0, levels).map((_, level) => (
            <div key={`range-bar-${level}`} className="custom-slider__track" 
              style={{ 
                width: `${ranges[level]/questionNumber*100}%`,
                height: height, 
                background: `${textLevels[levels][level].border}` 
              }} 
            />
          ))}
        </div>
      </div>
    </div>
  );
};

CustomMultiRangeSlider.propTypes = {
  onChange: PropTypes.func.isRequired,
  ranges: PropTypes.array.isRequired,
  segs: PropTypes.array.isRequired,
  thumbs: PropTypes.array.isRequired
};

export default CustomMultiRangeSlider;
