// `useDebouncedSearch` is a custom hook that provides a search input field with built-in debounce capability.
// It accepts a function `fn`, a delay `time`, a `placeholder` text, and a `style` object as parameters.

import { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';

import { Input } from 'antd';

const useDebouncedSearch = (
  fn,
  time = 500, // delay in ms for the debounce. Default is 500ms.
  placeholder = '',
  style = { width: 'min(360px, 100%)' },
) => {
  const [searchValue, setSearchValue] = useState('');

  const debouncedSearch = debounce((value) => {
    setSearchValue(value);
  }, time);

  // useEffect hook to call the provided function `fn` whenever the search value changes
  useEffect(() => {
    fn(searchValue);
  }, [searchValue]);

  return (
    <Input.Search
      placeholder={placeholder}
      style={style}
      onChange={(e) => debouncedSearch(e.target.value)}
    />
  );
};

export default useDebouncedSearch;
