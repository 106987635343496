import React, { useContext, useState } from 'react';
import { Badge, Col, Tabs } from 'antd';

import { CandidateContext } from '../config/CandidateContext';

import Background from '../tabs/Background';
import Activity from '../tabs/Activity';
import Summary from '../tabs/Summary';
import Evaluations from '../tabs/Evaluations';
import MailHistory from '../tabs/MailHistory';
import Messages from '../tabs/Messages';
import Comments from '../tabs/Comments';
import EmailView from '../components/EmailView';
import Header from '../components/Header';
import i18n from '../../../i18n'

const { TabPane } = Tabs;

const Content = ({ handleScroll, mode }) => {
  const {
    application: { candidate_type },
    changeKey,
    emailView,
    permissions,
    notifications,
    tabKey,
    translationFile,
  } = useContext(CandidateContext);

  const [openedMail, setOpenedMail] = useState()

  const getNotificationCount = (notificationType) => {
    if (notificationType === 'MSG') {
      const notificationCount = notifications.find(
        (obj) => obj.notification_type === 'MSG'
      )?.count;
      return notificationCount ? notificationCount : 0;
    }
    return 0;
  };

  const TABS = [
    {
      title: i18n.t('commons__summary', translationFile),
      component: () => <Summary />,
      showOnShare: true,
      candidate_type: 'active',
      display: true,
    },
    {
      title: i18n.t('profile__background', translationFile),
      component: () => <Background />,
      showOnShare: true,
      display: true,
    },
    {
      title: i18n.t('commons__evaluations', translationFile),
      component: () => <Evaluations />,
      showOnShare: true,
      candidate_type: 'active',
      display: true,
    },
    {
      title: i18n.t('commons__activity', translationFile),
      component: () => <Activity setOpenedMail={setOpenedMail} />,
      showOnShare: true,
      candidate_type: 'active',
      display: true,
    },
    {
      title: i18n.t('commons__comments', translationFile),
      component: () => <Comments />,
      showOnShare: true,
      candidate_type: 'active',
      display: true,
    },
    {
      title: i18n.t('commons__mail_history', translationFile),
      component: () => <MailHistory openedMail={openedMail}/>,
      showOnShare: true,
      display: true,
    },
    {
      title: i18n.t('commons__messages', translationFile),
      component: () => <Messages />,
      notificationType: 'MSG',
      showOnShare: false,
      candidate_type: 'active',
      display: permissions.business_admin || permissions.role?.can_manage_userjobapplications ? true : false,
    },
  ];

  return (

    <Col xs={24} lg={16} className="new-modal-content" onScroll={handleScroll}>
      {/* we should put the email view here? i'm not sure but there is no other place*/}
      {emailView ? (
        <EmailView />
      ) : (
        <>
          <Header mode={mode}/>
          <Tabs
            activeKey={tabKey}
            onChange={(i) => changeKey(i)}
          >
            {TABS.filter(obj=>obj.display).map((tab, i) => (
              ((mode !== 'isolated' || tab.showOnShare)
                && (tab.candidate_type === undefined || candidate_type === tab.candidate_type))
                  ? (
                    <TabPane
                      tab={
                        <Badge
                          dot={true}
                          count={getNotificationCount(tab.notificationType)}
                        >
                          {tab.title}
                        </Badge>
                      }
                      key={i}
                    >
                      {tab.component()}
                    </TabPane>
                  ) : null
            ))}
          </Tabs>
        </>
      )}
    </Col>
  );
};

export default Content;
