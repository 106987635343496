import React, {useState} from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import {
  Button,
  Col,
  Row,
  Modal,
  Typography
} from 'antd';
import Tour from 'reactour'
import PropTypes from 'prop-types';

import './styles.scss';

function TourModal(props) {

  const [isTourOpen, setIsTourOpen] = useState(false);

  const startTour = () => {
    props.setVisible(false);
    if (props.tourConfig) {
      setIsTourOpen(true);
    } else if (props.redirectTo) {
      props.dispatch(push(props.redirectTo));
    }
  }

  const closeTour = () => {
    setIsTourOpen(false);
  }

  const handleCancel = () => {
    props.setVisible(false);
  };

  return (
    <>
      <Modal
        width={props.width}
        title={
          <Row type='flex' align='middle'>
            <Col span={4}>
              <img style={{ width: '100%' }} src="https://genoma-assets.s3.us-east-2.amazonaws.com/inspiration.svg" />
            </Col>
            <Col span={20}>
              <Typography.Title level={4}>
                {props.businessUserName}, ¡que hay de nuevo!
              </Typography.Title>
              {props.title}
            </Col>
          </Row>
        }
        footer={[
          <Button key="ok" type="primary" onClick={startTour}>
            ¡Explorar!
          </Button>,
        ]}
        visible={props.isVisible}
        onCancel={handleCancel}
      >
        <div>
          {props.contentTitle || (
            <Typography.Text strong>
              ¿Qué podrás hacer ahora?
            </Typography.Text>
          )}
        </div>
        {props.content}
      </Modal>

      <Tour
        steps={props.tourConfig}
        isOpen={isTourOpen}
        onRequestClose={closeTour}
        showNavigation={props.showNavigation}
        showButtons={true}
        nextButton={
          <div className='tour-button'>Siguiente</div>
        }
        prevButton={
          <div className='tour-button'>Anterior</div>
        }
        lastStepNextButton={
          <div className='tour-button__close' onClick={closeTour}>Finalizar</div>
        }
      />
    </>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
  };
};

export default connect(mapDispatchToProps)(TourModal);

TourModal.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.node,
  content: PropTypes.node,
  contentTitle: PropTypes.node,
  isVisible: PropTypes.bool,
  setVisible: PropTypes.func,
  businessUserName: PropTypes.string,
  width: PropTypes.number,
  showNavigation: PropTypes.bool,
  redirectTo: PropTypes.string,
};