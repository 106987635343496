import React from 'react';
import {
  Badge,
  Button,
  Icon,
  Popover,
  Row,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import {
  ColumnFilterActivity,
  ColumnFilterKO,
  ColumnFilterVInt,
} from './components/ColumnFilter';

import { CvIcon } from './SvgIndex';
import DiscardReasonTag from './components/DiscardReasonTag';
import FitProgress from '../../../components/FitProgress';
import i18n from '../../../i18n';
import { isInvalidValue } from './helpers';

const { Text, Paragraph } = Typography;

const skills = (props) => {
  const excSkills = props.headerSkills.excSkills.map((elem) => {
    const col = {
      title: <div style={{ color: '#000' }}>{elem}</div>,
      className: 'exc-skill',
      key: elem,
      width: 250,
      filters: [
        {
          text: 'Con habilidades',
          value: 'skills',
        },
        {
          text: 'Con habilidades similares',
          value: 'similarSkills',
        },
        {
          text: 'Sin habilidades',
          value: 'none',
        },
      ],
      onFilter: (value, record) => {
        if (value === 'skills') {
          const foundSkill = record.cvFilter?.excSkills?.find(
            (value) => value == elem
          );
          return foundSkill ? true : false;
        }
        if (value === 'similarSkills') {
          const similarExc = record.cvFilter?.similarExcSkills?.find(
            (obj) => obj[elem] != null && obj[elem] != undefined
          );
          return similarExc ? true : false;
        }
        if (value === 'none') {
          const similarExc = record.cvFilter?.similarExcSkills?.find(
            (obj) => obj[elem] != null && obj[elem] != undefined
          );
          const foundSkill = record.cvFilter.excSkills.find(
            (value) => value == elem
          );
          return !similarExc && !foundSkill ? true : false;
        }
      },
      render: (_, record) => {
        const foundSkill = record.cvFilter?.excSkills?.find(
          (value) => value == elem
        );
        if (foundSkill) {
          return (
            <div>
              <Icon
                style={{ fontSize: 24, color: '#597EF7' }}
                type="check-circle"
                theme="filled"
              />
            </div>
          );
        }
        // if not found check if there are similar skills
        else {
          const similarExc = record.cvFilter?.similarExcSkills?.find(
            (obj) => obj[elem] != null && obj[elem] != undefined
          );
          if (similarExc) {
            return (
              <Popover
                placement="top"
                title={'Habilidades similares'}
                content={similarExc[elem].join(', ')}
                trigger="hover"
              >
                <div>
                  <Icon
                    style={{ fontSize: 24 }}
                    type="info-circle"
                    theme="filled"
                  />
                </div>
              </Popover>
            );
          } else {
            return <div>No posee.</div>;
          }
        }
      },
    };
    return col;
  });

  const desSkills = props.headerSkills.desSkills.map((elem) => {
    const col = {
      title: <div style={{ color: '#000' }}>{elem}</div>,
      className: 'des-skill',
      key: elem,
      width: 250,
      filters: [
        {
          text: 'Con habilidades',
          value: 'skills',
        },
        {
          text: 'Con habilidades similares',
          value: 'similarSkills',
        },
        {
          text: 'Sin habilidades',
          value: 'none',
        },
      ],
      onFilter: (value, record) => {
        if (value === 'skills') {
          const foundSkill = record.cvFilter?.desSkills?.find(
            (value) => value == elem
          );
          return foundSkill ? true : false;
        }
        if (value === 'similarSkills') {
          const similarExc = record.cvFilter?.similarDesSkills?.find(
            (obj) => obj[elem] != null && obj[elem] != undefined
          );
          return similarExc ? true : false;
        }
        if (value === 'none') {
          const similarExc = record.cvFilter?.similarDesSkills?.find(
            (obj) => obj[elem] != null && obj[elem] != undefined
          );
          const foundSkill = record.cvFilter.desSkills.find(
            (value) => value == elem
          );
          return !similarExc && !foundSkill ? true : false;
        }
      },
      render: (_, record) => {
        const foundSkill = record.cvFilter?.desSkills?.find(
          (value) => value == elem
        );
        if (foundSkill) {
          return (
            <div>
              <Icon
                style={{ fontSize: 24, color: '#FFA940' }}
                type="check-circle"
                theme="filled"
              />
            </div>
          );
        }
        // if not found check if there are similar skills
        else {
          const similarExc = record.cvFilter?.similarDesSkills?.find(
            (obj) => obj[elem] != null && obj[elem] != undefined
          );
          if (similarExc) {
            return (
              <Popover
                placement="top"
                title={'Habilidades similares'}
                content={similarExc[elem].join(', ')}
                trigger="hover"
              >
                <div>
                  <Icon
                    style={{ fontSize: 24 }}
                    type="info-circle"
                    theme="filled"
                  />
                </div>
              </Popover>
            );
          } else {
            return <div>No posee.</div>;
          }
        }
      },
    };
    return col;
  });

  return excSkills.concat(desSkills);
};

// TODO: import from main schedule component
const oneHotToHourOfDay = (oneHot, end = false) => {
  const adjustedOneHot = end ? oneHot + 1 : oneHot;
  const hour = Math.floor(adjustedOneHot / 4);
  const minutes = (adjustedOneHot % 4) * 15;
  return [hour, minutes];
};

const daysOfWeek = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];
const dayBlocks = (schedule) => {
  const blocks = {};
  let inABlock = false;
  let newBlock = {
    start: [],
    end: [],
  };
  daysOfWeek.forEach((day) => {
    blocks[day] = [];
    schedule[day].forEach((oneHot, index) => {
      if (oneHot) {
        if (!inABlock) {
          inABlock = true;
          newBlock = {
            start: oneHotToHourOfDay(index),
            end: oneHotToHourOfDay(index, true),
          };
        } else {
          newBlock.end = oneHotToHourOfDay(index, true);
        }
      } else if (!oneHot && inABlock) {
        inABlock = false;
        blocks[day].push(newBlock);
      }
    });
  });
  return blocks;
};

const createAnswer = (ko) => {
  if (!ko) return 'Sin información.';

  switch (ko.type) {
    case 'singleselection':
      return ko.alternatives[ko.answer].text;
    case 'multiselection':
      const answers = Object.keys(ko.answer);

      return ko.alternatives[answers[0]].text;
    case 'int':
    case 'multiline':
    case 'singleline':
      return ko.answer;
  }
};

const defaultColumnsGenerator = (
  methods,
  order = {},
  config,
  discardReasons = [],
  jobAppId,
  token
) => {
  const kos = config.selectedKos.length
    ? JSON.parse(
        config.activities.find((act) => act.code === 'KO').configuration
      ).questions
    : {};

  const questions = config.selectedKos
    .filter((n) => n !== 'schedule' && kos[n].type !== 'availability')
    .map((n) => {
      const actualKo = kos[n];

      return {
        title: actualKo.question.toUpperCase(),
        dataIndex: `${n}`,
        key: `${n}`,
        filterIcon: () => {
          const customFiltered = !isInvalidValue(config.koFilters[n]); // your custom logic to determine the filtered state
          return (
            <Icon
              type="filter"
              style={{ color: customFiltered ? '#f175a5' : '' }}
              theme="filled"
            />
          );
        },
        render: (_, row) => {
          return (
            <Text>
              {createAnswer(row.ko_answers ? row.ko_answers[n] : null)}
            </Text>
          );
        },
        width: 200,
        filterDropdown: () => (
          <ColumnFilterKO
            ko={actualKo}
            questionType={actualKo.type}
            koKey={n}
            value={config.koFilters[n]}
            setFilter={config.setKoFilters}
          />
        ),
      };
    });
  const dayScheduleQuestionsN = config.selectedKos.find(
    (n) => kos[n].type === 'schedule'
  );
  if (dayScheduleQuestionsN) {
    const scheduleKo = kos[dayScheduleQuestionsN];
    daysOfWeek.forEach((day) => {
      questions.push({
        title: i18n.t(`commons__dayofweek__${day}`),
        dataIndex: `${day}`,
        key: `${day}`,
        render: (_, row) => {
          if (!row.ko_answers || !row.ko_answers[dayScheduleQuestionsN]) {
            return <div>Sin información</div>;
          }
          const blocks = dayBlocks(
            row.ko_answers[dayScheduleQuestionsN].answer
          );
          return (
            <div>
              {blocks[day].length > 0
                ? blocks[day].map((block) => {
                    return (
                      <Paragraph>
                        {block.start[0].toString().padStart(2, '0')}:
                        {block.start[1].toString().padStart(2, '0')} -{' '}
                        {block.end[0].toString().padStart(2, '0')}:
                        {block.end[1].toString().padStart(2, '0')}
                      </Paragraph>
                    );
                  })
                : 'Sin disponibilidad'}
            </div>
          );
        },
        width: 200,
      });
    });
  }

  const availabilityQuestionN = config.selectedKos.find(
    (n) => kos[n].type === 'availability'
  );

  if (availabilityQuestionN) {
    daysOfWeek.forEach((day) => {
      questions.push({
        title: i18n.t(`commons__dayofweek__${day}`),
        dataIndex: `${day}`,
        key: `${day}`,
        render: (_, row) => {
          if (!row.ko_answers || !row.ko_answers[availabilityQuestionN]) {
            return <div>Sin información.</div>;
          }
          const blocks = dayBlocks(
            row.ko_answers[availabilityQuestionN].answer
          );
          return (
            <div>
              {blocks[day].length > 0
                ? blocks[day].map((block) => {
                    return (
                      <Paragraph>
                        {block.start[0].toString().padStart(2, '0')}:
                        {block.start[1].toString().padStart(2, '0')} -{' '}
                        {block.end[0].toString().padStart(2, '0')}:
                        {block.end[1].toString().padStart(2, '0')}
                      </Paragraph>
                    );
                  })
                : 'Día no disponible.'}
            </div>
          );
        },
        width: 200,
      });
    });
  }

  const tmpColumns = [
    {
      title: 'FIT',
      dataIndex: 'final_score',
      key: 'final_score',
      render: (final_score) => {
        return <FitProgress type="circle" percent={final_score} width={45} />;
      },
      width: 80,
      fixed: 'left',
      ...order.FIT,
    },
    {
      title: 'NOMBRE/CORREO',
      dataIndex: 'full_name',
      key: 'full_name',
      width: 250,
      fixed: 'left',
      ...order.full_name,
      render: (full_name, { email, candidate, passive_candidate, personal_user_id }) => {
        const checkTooltip = (text, render) => {
          if (text?.length > 30) {
            return <Tooltip title={text}>{render}...</Tooltip>;
          } else {
            return render;
          }
        };

        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {checkTooltip(
              full_name,
              <Text>
                <a
                  onClick={() => methods.handleOpenProfileModal(candidate, personal_user_id || passive_candidate)}
                  style={{ color: '#454545', fontWeight: 700 }}
                >
                  {full_name.substring(0, 27)}
                </a>
              </Text>
            )}
            {checkTooltip(
              email,
              <Text
                style={{
                  color: '#A4A4A4',
                }}
              >
                {email?.substring(0, 27)}
              </Text>
            )}
          </div>
        );
      },
    },
    {
      title: 'TIPO DE CANDIDATO',
      dataIndex: 'candidate_type',
      key: 'candidate_type',
      width: 200,
      render: (candidate_type) => (
        <Tag color={candidate_type === 'passive' ? 'blue' : 'green'}>
          {candidate_type === 'passive'
            ? i18n.t('passive_candidates__passive')
            : i18n.t('passive_candidates__active')}
        </Tag>
      ),
    },
    {
      title: 'ETAPA',
      dataIndex: 'status',
      key: 'status',
      width: 200,
      render: (status) => (
        <Text className="bordered-text">
          {config.jobStages.find((stage) => stage.code === status)?.name}
        </Text>
      ),
    },
    {
      title: 'CV',
      dataIndex: 'cv',
      key: 'cv',
      width: 100,
      render: (cv) => (
        <Row type='flex' align='middle' justify='start'>
          <Tooltip title={!cv ? 'No posee.' : 'Previsualizar'}>
            <div
              onClick={() => (!cv ? null : methods.handleCV(cv))}
              style={{ cursor: !cv ? null : 'pointer', width: 'fit-content' }}
            >
              <CvIcon color={!cv ? 'gray' : '#F175A5'} />
            </div>
          </Tooltip>
          {cv ? <Tooltip title='Descargar'>
            <Button size='large' type='link' style={{ color: '#f175a5' }} icon='download' onClick={() => methods.downloadCV(cv)} />
          </Tooltip> : null}
        </Row>
      ),
    },
    /*
    {
      title: 'DATOS PERSONALES',
      dataIndex: 'personal_data',
      key: 'personal_data',
      width: 170,
      render: (_, row) => {
        const personalForm = row.activities?.find((act) => act.code === 'FORM');
        // if personalForm find the activitie do this
        const personalFormCompleted = personalForm
          ? personalForm.status === 'FINISHD'
            ? true
            : false
          : false;
        return (
          <Badge
            status={personalFormCompleted ? 'success' : 'error'}
            text={personalFormCompleted ? 'Completo' : 'Incompleto'}
          />
        );
      },
    },
    */
    {
      title: 'PREGUNTAS',
      dataIndex: 'ko',
      key: 'ko',
      width: 150,
      render: (_, row) => {
        const personalKO = row.activities?.find((act) => act.code === 'KO');
        // if personalKO find the activitie do this
        const personalKoCompleted = personalKO
          ? personalKO.status === 'FINISHD'
            ? true
            : false
          : false;

        return (
          <Badge
            status={personalKoCompleted ? 'success' : 'error'}
            text={personalKoCompleted ? 'Completo' : 'Incompleto'}
          />
        );
      },
    },
    ...questions,
    {
      title: (
        <Text style={{ fontSize: 13, color: '#000000' }}>
          ESTADO VIDEOENTREVISTA
        </Text>
      ),
      dataIndex: 'video_interview_ready',
      key: 'video_interview_ready',
      width: 170,
      filterDropdown: () => (
        <ColumnFilterVInt setFilter={config.setQueryFilters} />
      ),
      render: (_, row) =>
        row.video_interview_ready ? (
          <Badge status="success" text="Completa" />
        ) : (
          <Badge status="error" text="Pendiente" />
        ),
    },
    {
      title: (
        <Text style={{ fontSize: 13, color: '#000000' }}>
          EVALUACIÓN VIDEOENTREVISTA
        </Text>
      ),
      dataIndex: 'vint_eval',
      key: 'vint_eval',
      width: 170,
      render: (_, row) =>
        row.videointerview_evaluations?.evaluations?.average ? (
          <Text>
            <Icon
              type="star"
              theme="filled"
              style={{ color: 'rgb(250, 219, 20)', marginRight: '0.25rem' }}
            />
            {row.videointerview_evaluations.evaluations.average}
          </Text>
        ) : (
          <Text>No posee.</Text>
        ),
    },
    {
      title: 'JUEGOS',
      dataIndex: 'completed_games',
      key: 'completed_games',
      width: 100,
      render: (_, row) => {
        const games = row.activities.filter(
          (act) =>
            act.code !== 'CUTE' &&
            act.code !== 'FORM' &&
            act.code !== 'KO' &&
            act.code !== 'VINT'
        ).length;

        const completed = row.activities.filter(
          (act) =>
            act.code !== 'CUTE' &&
            act.code !== 'FORM' &&
            act.code !== 'KO' &&
            act.code !== 'VINT' &&
            act.status === 'FINISHD'
        ).length;

        return games > 0 ? (
          <Badge
            status={row.activities && completed === games ? 'success' : 'error'}
            text={
              !row.activities ? `${games}/${games}` : `${completed}/${games}`
            }
          />
        ) : (
          'No posee.'
        );
      },
    },
    {
      title: 'ETIQUETAS',
      dataIndex: 'tags',
      key: 'tags',
      width: 300,
      render: (tags = []) =>
        tags.length ? (
          tags.map((tag, index) => (
            <Tooltip title={tag} key={tag}>
              <Tag key={index}>
                {tag.length > 20 ? `${tag.slice(0, 20)}...` : tag}
              </Tag>
            </Tooltip>
          ))
        ) : (
          <Text>No posee.</Text>
        ),
    },

    {
      title: 'RAZÓN DE DESCARTE',
      dataIndex: 'discard_reason_representation',
      key: 'discard_reason_representation',
      width: 250,
      render: (_, row) => (
        <DiscardReasonTag
          editCandidateDiscardReason={methods.handleEditDiscardReason}
          application={row}
          candidate={row}
          discardReasons={discardReasons}
        />
      ),
      ...order.discard_reason,
    },
    {
      title: 'POSTULACIÓN',
      dataIndex: 'date_started',
      key: 'date_started',
      width: 150,
      ...order.date_started,
    },
  ];

  if (config.totalSkills) {
    const index = tmpColumns.findIndex((col) => col.key === 'cv');
    const nextIndex = index < 0 ? 2 : index + 1;

    tmpColumns.splice(
      nextIndex,
      0,
      {
        title: 'REQUISITOS',
        dataIndex: 'cvFilter.skillScore',
        key: 'skillScore',
        width: 170,
        sorter: (a, b) => {
          if (a.cvFilter.skillScore == null) {
            return -1;
          }
          if (b.cvFilter.skillScore == null) {
            return 1;
          }
          return a.cvFilter.skillScore - b.cvFilter.skillScore;
        },
        render: (score = 0, row) => (
          <Text>
            {!config.totalSkills
              ? 'No posee.'
              : !row.cv
              ? 'No posee CV.'
              : `${score} de ${config.totalSkills}`}
          </Text>
        ),
      },
      ...skills({ headerSkills: config.cvFilters })
    );
  }

  return tmpColumns;
};

const documentsColumnsGenerator = (methods, order = {}, config) => {
  const filesRequestsColumns = config.filesRequests?.map((request, index) => {
    const requestConfig = JSON.parse(request.configuration);
    return {
      title: requestConfig?.name?.toUpperCase(),
      dataIndex: 'files_requests',
      key: `files_requests_${index}`,
      render: (files_requests, record) => {
        const requestQuestions = files_requests.find(
          (obj) => obj.files_request.name === requestConfig?.name
        );
        return requestQuestions?.files_request?.questions?.map((req) => (
          <Row
            type="flex"
            align="middle"
            key={`${req.id}-${req.name}-${record.id}`}
          >
            <Typography.Text>{req.name}</Typography.Text>
            {req.answers.files?.length > 0 ? (
              <>
                <Icon
                  type="check-circle"
                  theme="filled"
                  style={{ color: '#52c41a', marginLeft: 5 }}
                />
                <Typography.Text style={{ marginLeft: 5, color: '#52c41a' }}>
                  Enviado
                </Typography.Text>
              </>
            ) : (
              <>
                <Icon
                  type="clock-circle"
                  theme="filled"
                  style={{ color: '#CCCCCC', marginLeft: 5 }}
                />
                <Typography.Text style={{ marginLeft: 5, color: '#CCCCCC' }}>
                  No enviado
                </Typography.Text>
              </>
            )}
          </Row>
        ));
      },
    };
  });

  return [
    {
      title: 'NOMBRE/CORREO',
      dataIndex: 'full_name',
      key: 'full_name',
      ...order.full_name,
      render: (full_name, { email, candidate, personal_user_id, passive_candidate }) => {
        const checkTooltip = (text, render) => {
          if (text.length > 30) {
            return <Tooltip title={text}>{render}...</Tooltip>;
          } else {
            return render;
          }
        };

        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {checkTooltip(
              full_name,
              <Text>
                <a
                  onClick={() =>
                    methods.handleOpenProfileModal(candidate, personal_user_id || passive_candidate)
                  }
                  style={{ color: '#454545', fontWeight: 700 }}
                >
                  {full_name.substring(0, 27)}
                </a>
              </Text>
            )}
            {checkTooltip(
              email,
              <Text
                style={{
                  color: '#A4A4A4',
                }}
              >
                {email?.substring(0, 27)}
              </Text>
            )}
          </div>
        );
      },
    },
    ...filesRequestsColumns,
    {
      title: 'ACCIONES',
      dataIndex: 'files_requests',
      key: 'actions',
      width: 110,
      align: 'right',
      render: (files_requests = [], candidate) => {
        const atLeastOneFile =
          files_requests.filter((obj) => obj.status === 'FINISHD').length > 0;
        return (
          <>
            {/* <Button
              type="link"
              icon="eye"
              disabled={files_requests.filter((obj) => obj.status == 'FINISHD').length == 0}
              onClick={() => config.selectIndex(index)}
            /> */}
            <Tooltip placement="bottom" title="Reiniciar">
              <Button
                disabled={!atLeastOneFile}
                icon="reload"
                onClick={() =>
                  config.openDocsResetModal({ files_requests, candidate })
                }
                type="link"
              />
            </Tooltip>
          </>
        );
      },
    },
  ];
};

const koColumnsGenerator = (fn) => {
  return [
    {
      title: 'PREGUNTAS',
      dataIndex: 'activities',
      key: 'ko',
      render: (activities) => {
        const personalKO = activities?.find((act) => act.code === 'KO');
        // if personalKO find the activitie do this
        const personalKoCompleted = personalKO
          ? personalKO.status === 'FINISHD'
            ? true
            : false
          : false;
        return (
          <Badge
            status={personalKoCompleted ? 'success' : 'error'}
            text={personalKoCompleted ? 'Completo' : 'Incompleto'}
          />
        );
      },
    },
  ];
};

const checkActivityFiltered = (activitiesId, queryFilters, code) => {
  let isActive = false;
  if (code === 'GENOME') {
    isActive =
      queryFilters?.genome_completed?.length > 0 ||
      queryFilters?.genome_incompleted?.length > 0;
  } else {
    activitiesId.forEach((id) => {
      if (
        queryFilters?.activity_completed?.includes(id) ||
        queryFilters?.activity_incompleted?.includes(id)
      ) {
        isActive = true;
      }
    });
  }
  return isActive;
};

const activityProgressColumnsGenerator = (methods, order = {}, config) => (
  config.activities?.map((obj) => (
    {
      title: (<Text style={{ fontSize: 13, color: '#000000' }}>{obj.name?.toUpperCase()}</Text>),
      dataIndex: obj.id,
      key: obj.id,
      width: 170,
      filtered: checkActivityFiltered(obj.activitiesId, config.queryFilters),
      filterDropdown: () => (
        <ColumnFilterActivity
          setQueryFilters={config.setQueryFilters}
          queryFilters={config.queryFilters}
          activityCode={obj.code}
          activitiesId={obj.activitiesId}
        />
      ),
      render: (_, row) => {
        const activitiesId = obj.activitiesId;

        if (obj.code === 'TERA') {
          return (row.candidateinterviewprocess_id ? 
          <Button onClick={() => window.open(`/interviews/candidateinterviews/${row.candidateinterviewprocess_id}`)}>Ver entrevista</Button> : 
          "Sin entrevista")
        }

        if (obj.code === 'GENOME') {
          const completedGames = row.activities.filter(
            (obj) =>
              activitiesId.includes(obj.assigned_activity_id) &&
              obj.status === 'FINISHD'
          );
          return (
            <Badge
              status={
                activitiesId.length == completedGames.length ? 'success' : 'error'
              }
              text={`${completedGames.length}/${activitiesId.length}`}
            />
          );
        }
        // If no GENOME activitiesId should have only one element
        const activity = row.activities.find(
          (obj) => activitiesId[0] == obj.assigned_activity_id
        );
        return (
          <Badge
            status={activity?.status === 'FINISHD' ? 'success' : 'error'}
            text={activity?.status === 'FINISHD' ? 'Completa' : 'Pendiente'}
          />
        );
      },
  })));

export {
  defaultColumnsGenerator,
  documentsColumnsGenerator,
  koColumnsGenerator,
  activityProgressColumnsGenerator,
};
