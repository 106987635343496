import '../EvaluationsStyles.scss';

import {
  Button,
  Col,
  Divider,
  Icon,
  Input,
  Modal,
  Row,
  Tooltip,
  Typography,
} from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';

import EvaluationCard from '../EvaluationCard';
import JobAppContext from '../../../context/JobAppContext';
import NewQuestion from './NewQuestion';
import { questionIcon } from './constants';
import { v4 as uuidv4 } from 'uuid';

const LOGO_URL =
  'https://genoma-assets.s3.us-east-2.amazonaws.com/question-icon-activity.svg';

function AdditionalQuestions() {
  const {
    additionalQuestions,
    activities,
    currentStage,
    removeQuestionTemplate,
    updateAdditionalQuestions,
  } = useContext(JobAppContext);

  const [isVisible, setIsVisible] = useState(false);
  const [searchInput, setSearchInput] = useState(undefined);
  const [availableQuestions, setAvailableQuestions] = useState([]);
  // KOs are stored as objects
  const [currentQuestions, setCurrentQuestions] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);

  const [configNewQuestion, setConfigNewQuestion] = useState(false);
  const [questionToEdit, setQuestionToEdit] = useState(null);

  const editComponent = useRef(null);

  useEffect(() => {
    if (additionalQuestions && isVisible) {
      let updatedQuestions;
      let newCurrentEvaluations;

      updatedQuestions = additionalQuestions?.allEvaluations;
      newCurrentEvaluations =
        additionalQuestions.currentEvaluations.find(
          (obj) => obj.stage === currentStage
        )?.questions ?? [];
      updatedQuestions.sort((a, b) => a.question.localeCompare(b.question));

      // Remove previously added cutes, we check every question already used
      let currentEvaluationsIds = [];
      additionalQuestions.currentEvaluations.forEach((questionsObj) => {
        currentEvaluationsIds = [
          ...currentEvaluationsIds,
          ...questionsObj.questions.map((obj) => obj.id),
        ];
      });
      updatedQuestions = updatedQuestions.filter(
        (obj) => !currentEvaluationsIds.includes(obj.id)
      );
      setAvailableQuestions(updatedQuestions);

      // Add currentEvaluations only when the modal is redered for the first time
      if (currentQuestions.length == 0) {
        setCurrentQuestions(newCurrentEvaluations);
      }
    }
  }, [additionalQuestions, currentStage, isVisible]);

  // Clean state after modal is closed, this is really important to avoid bugs
  // we always want to start with a clean state so the previous state doesn't affect the next one and the previous use effect triggers correctly
  useEffect(() => {
    if (isVisible == false) {
      setSearchInput(undefined);
      setAvailableQuestions([]);
      setCurrentQuestions([]);
      setFilteredQuestions([]);

      setConfigNewQuestion(false);
      setQuestionToEdit(null);
    }
  }, [isVisible]);

  useEffect(() => {
    if (configNewQuestion == false && questionToEdit != null) {
      setQuestionToEdit(null);
    }
  }, [configNewQuestion]);

  useEffect(() => {
    if (searchInput == undefined) return;
    const filtered = availableQuestions.filter((obj) =>
      obj.question.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilteredQuestions(filtered);
  }, [searchInput]);

  const addOrEditQuestion = (question, idx) => {
    // Los templates vienen con la llave type, pero en realidad necesitamos una llave con el nombre type
    if (!question.type && question.question_type) {
      question.type = question.question_type;
      delete question.question_type;
    }

    // Algunos templates vienen sin mandatory, por lo que se agrega
    if (question.mandatory == undefined) {
      question.mandatory = false;
    }

    const everyIndex = Object.keys(currentQuestions);
    const currentIds = currentQuestions.map((obj) => obj.id);
    // Si tiene id es que es pregunta a editar
    if (question.id && currentIds.includes(question.id)) {
      const questionIdx = currentQuestions.findIndex(
        (obj) => obj.id == question.id
      );

      currentQuestions[questionIdx] = question;
      setCurrentQuestions([...currentQuestions]);
    }
    // Add new
    else {
      // inventamos un id
      question.id = question.id ? question.id : ['schedule', 'availability'].includes(question.type) ? 'schedule' : question.type === 'salary' ? 'salary' : uuidv4();
      const toAddQuestion = { ...question, position: everyIndex.length };
      setCurrentQuestions((oldState) => [...oldState, toAddQuestion]);
      if (idx != undefined && idx != null) {
        availableQuestions.splice(idx, 1);
        setAvailableQuestions(availableQuestions);
      }
    }
    setConfigNewQuestion(false);
  };

  const removeQuestion = (question, idx) => {
    const toRemoveQuestion = { ...question };
    // Revisamos si es un template, osea si esta en el arreglo que contiene todo
    if (
      additionalQuestions.allEvaluations.some(
        (obj) => obj.id === toRemoveQuestion.id
      )
    ) {
      delete toRemoveQuestion.stage;
      delete toRemoveQuestion.position;
      const newAvailableQuestions = [
        ...availableQuestions,
        toRemoveQuestion,
      ].sort((a, b) => a.question.localeCompare(b.question));
      setAvailableQuestions(newAvailableQuestions);
    } else {
      delete toRemoveQuestion.id;
    }

    const tmpCurrentQuestions = JSON.parse(JSON.stringify(currentQuestions));
    tmpCurrentQuestions.splice(idx, 1);
    setCurrentQuestions(tmpCurrentQuestions);

    setConfigNewQuestion(false);
    setQuestionToEdit(null);
  };

  const executeScroll = () =>
    editComponent.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });

  const editQuestion = (question, idx) => {
    setQuestionToEdit(question);
  };

  useEffect(() => {
    if (questionToEdit) {
      executeScroll();
    }
    if (configNewQuestion == true) {
      executeScroll();
    }
  }, [configNewQuestion, questionToEdit]);

  const onSave = () => {
    // Siempre actualizar currentEvaluations
    // En este caso las ko son 1 por etapa, por el momento 1 por proceso pero la logica es
    // buscar si existe ko en esa etapa -> ejecutar accion
    const toAddIdx = additionalQuestions.toAdd.findIndex(
      (obj) => obj.stage === currentStage
    );
    const toUpdateIdx = additionalQuestions.toUpdate.findIndex(
      (obj) => obj.stage === currentStage
    );
    const currentEvaluationsIdx =
      additionalQuestions.currentEvaluations.findIndex(
        (obj) => obj.stage === currentStage
      );
    let currentEvaluations;

    // Tambien usamos la etapa como id, asi son mas genericas todas nuestras funciones, por ahora solo
    // se acepta 1 activity ko por proceso, peor podria extenderse a 1 activity ko por etapa
    const activityKO = activities.find((obj) => obj.code === 'KO');
    const tmpQuestions = {
      questions: currentQuestions,
      stage: currentStage,
      id: currentStage,
      code: activityKO.code,
      icon: activityKO.icon,
      translation: activityKO.translation,
    };

    // Si no está en toAdd pero está en current editamos
    if (toAddIdx == -1 && currentEvaluationsIdx != -1) {
      let toUpdate = [...additionalQuestions.toUpdate];
      currentEvaluations = [...additionalQuestions.currentEvaluations];
      if (toUpdateIdx == -1) {
        toUpdate.push({ ...tmpQuestions });
      } else {
        toUpdate[toUpdateIdx] = { ...tmpQuestions };
      }
      updateAdditionalQuestions('toUpdate', toUpdate);

      // Como exitia en current actualizamos
      currentEvaluations[currentEvaluationsIdx] = { ...tmpQuestions };
      updateAdditionalQuestions('currentEvaluations', currentEvaluations);
    }

    // Si esta en toAdd pero no en current
    else if (toAddIdx != -1 && currentEvaluationsIdx == -1) {
      // este caso es imposible, no deberia pasar nunca
    }

    // Si no esta en toAdd y no esta en current (es 100% nueva) agregamos a ambas
    else if (toAddIdx == -1 && currentEvaluationsIdx == -1) {
      currentEvaluations = [
        ...additionalQuestions.currentEvaluations,
        { ...tmpQuestions },
      ];
      const toAdd = [...additionalQuestions.toAdd, { ...tmpQuestions }];
      updateAdditionalQuestions('toAdd', toAdd);
      updateAdditionalQuestions('currentEvaluations', currentEvaluations);
    }

    // Si esta en toAdd y esta en current, estamos editando una pregunta 100% nueva (luego no es necesario el toUpdate)
    else if (toAddIdx != -1 && currentEvaluationsIdx != -1) {
      currentEvaluations = [...additionalQuestions.currentEvaluations];
      const toAdd = [...additionalQuestions.toAdd];
      currentEvaluations[currentEvaluationsIdx] = { ...tmpQuestions };
      toAdd[toAddIdx] = { ...tmpQuestions };
      updateAdditionalQuestions('toAdd', toAdd);
      updateAdditionalQuestions('currentEvaluations', currentEvaluations);
    }
    // Finalmente si el largo  de currentEvaluations.question es 0 sacar el objeto entero de la etapa
    if (tmpQuestions.questions.length == 0) {
      onRemoveEvaluation();
    }
    // Close modal
    setIsVisible(false);
  };

  const onRemoveEvaluation = () => {
    // Remover de toAdd y toUpdate si es que esta
    // Si no esta en toAdd agregar a toRemove
    const toAddIdx = additionalQuestions.toAdd.findIndex(
      (obj) => obj.stage === currentStage
    );
    const toUpdateIdx = additionalQuestions.toUpdate.findIndex(
      (obj) => obj.stage === currentStage
    );
    const currentEvaluationsIdx =
      additionalQuestions.currentEvaluations.findIndex(
        (obj) => obj.stage === currentStage
      );

    const currentEvaluations = additionalQuestions.currentEvaluations;

    // Siempre remover de update
    if (toUpdateIdx != -1) {
      const toUpdate = [...additionalQuestions.toUpdate];
      toUpdate.splice(toUpdateIdx, 1);
      updateAdditionalQuestions('toUpdate', toUpdate);
    }
    // Remover de toAdd si existe, si no agregar a toRemove (si no esta en to add significa que haremos un DELETE)
    if (toAddIdx != -1) {
      const toAdd = [...additionalQuestions.toAdd];

      toAdd.splice(toAddIdx, 1);
      updateAdditionalQuestions('toAdd', toAdd);
    } else {
      const toRemove = [...additionalQuestions.toRemove];
      // Solo agregar un toRemove si es que el objeto existe
      if (currentEvaluationsIdx != -1) {
        toRemove.push({ ...currentEvaluations[currentEvaluationsIdx] });
        updateAdditionalQuestions('toRemove', toRemove);
      }
    }

    // Siempre remover de currentEvaluations
    currentEvaluations.splice(currentEvaluationsIdx, 1);
    updateAdditionalQuestions('currentEvaluations', currentEvaluations);
  };

  const getCurrentQuestions = () => {
    const tmpCurrentQuestions =
      additionalQuestions.currentEvaluations[0]?.questions ?? [];
    const questionsComponent = tmpCurrentQuestions
      .sort((a, b) => a.position - b.position)
      .map((obj, idx) => <div key={idx}>{`• ${obj.question}`}</div>);
    return (
      <>
        <div style={{ marginBottom: 5, marginTop: 10 }}>
          <strong>Preguntas agregadas:</strong>
        </div>
        {questionsComponent}
      </>
    );
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: '',
      content: 'Confirme para continuar',
      okText: 'Confirmar',
      onOk: () => removeQuestionTemplate(id),
      cancelText: 'Cerrar',
    });
  };

  const swapElements = (arr, idx1, idx2) => {
    [arr[idx1], arr[idx2]] = [arr[idx2], arr[idx1]];
    arr.forEach((obj, idx) => {
      obj.position = idx;
    });

    setCurrentQuestions([...arr])

  };

  const moveUp = (index) => {
    if (index == 0) return;
    const newQuestions = [...currentQuestions];
    swapElements(newQuestions, index, index - 1);
    setCurrentQuestions(newQuestions);
  };

  const moveDown = (index) => {
    if (index == currentQuestions.length - 1) return;
    const newQuestions = [...currentQuestions];
    swapElements(newQuestions, index, index + 1);
    setCurrentQuestions(newQuestions);
  };

  const QuestionRow = ({
    questionObj,
    index,
    questionsGroupType,
    hasAvailabilityQuestion,
  }) => {
    const question = questionObj.question;
    const type = questionObj.type
      ? questionObj.type
      : questionObj.question_type;
    return (
      <Row type="flex" justify="space-between" style={{ marginBottom: 8 }}>
        <Col sm={20} md={22}>
          <Row type="flex">
            {questionsGroupType === 'currentQuestion' && (
              <Col sm={1}>
                <div style={{ width: 20, marginTop: -8 }}>
                  <div>
                    <Icon type="caret-up" onClick={() => moveUp(index)} />
                  </div>
                  <div>
                    <Icon type="caret-down" onClick={() => moveDown(index)} />
                  </div>
                </div>
              </Col>
            )}
            <Col sm={23}>
              <span className="additional-question-icon">
                <Icon type={questionIcon[type]} />
              </span>
              <Typography.Text style={{ fontSize: 16 }}>
                {question}
              </Typography.Text>
            </Col>
          </Row>
        </Col>

        <Col sm={4} md={2}>
          <Row type="flex" justify="space-between">
            {questionsGroupType === 'template' ? (
              <>
                <Button
                  icon="delete"
                  onClick={() => confirmDelete(questionObj.id)}
                />
                {type === 'availability' && hasAvailabilityQuestion ? (
                  <Tooltip title="Solo puedes añadir una pregunta de disponibilidad horaria">
                    <Button
                      type="primary"
                      icon="plus"
                      onClick={() => {}}
                      disabled
                    />
                  </Tooltip>
                ) : (
                  <Button
                    type="primary"
                    icon="plus"
                    onClick={() => addOrEditQuestion(questionObj, index)}
                  />
                  // <span
                  //   className="additional-question-button"
                  //   onClick={() => addOrEditQuestion(questionObj, index)}
                  // >
                  //   <Icon type="plus" />
                  // </span>
                )}
              </>
            ) : (
              <>
                <Button
                  icon="edit"
                  onClick={() => editQuestion(questionObj, index)}
                />
                <Button
                  icon="minus"
                  onClick={() => removeQuestion(questionObj, index)}
                  style={{ border: 'solid 1px #f175a5', color: '#f175a5' }}
                />
              </>
            )}
          </Row>
        </Col>
      </Row>
    );
  };

  const getButtons = () => {
    const stageQuestions = additionalQuestions.currentEvaluations.find(
      (obj) => obj.stage === currentStage
    );
    if (stageQuestions || additionalQuestions.currentEvaluations.length == 0) {
      return [
        <Button
          key={'add'}
          className="evaluation-add-button"
          onClick={() => setIsVisible(true)}
        >
          {stageQuestions?.questions.length > 0 ? (
            <>
              <Icon type="check" />
              <span>Editar</span>
            </>
          ) : (
            <>
              <Icon type="plus" />
              <span>Configurar y agregar</span>
            </>
          )}
        </Button>,

        stageQuestions?.questions.length ? (
          <Button
            key={'delete'}
            className="evaluation-delete-button"
            onClick={() => onRemoveEvaluation()}
          >
            Eliminar
          </Button>
        ) : null,
      ];
    }
    // Si hay kos en otra etapa no dejamos agregar mas
    else {
      return [
        <Button key={'add-other'} className="evaluation-added-button">
          Evaluación agregada en otra etapa
        </Button>,
      ];
    }
  };

  const hasAvailabilityQuestion = currentQuestions.some(
    (question) => question.type === 'availability' || question.type === 'schedule'
  );

  return (
    <>
      <EvaluationCard
        logo={LOGO_URL}
        evaluationTypeImg={[
          'https://genoma-assets.s3.us-east-2.amazonaws.com/puzzle.svg',
        ]}
        title="Preguntas adicionales"
        description="Plantea preguntas para los candidatos y obtén información clave para tu proceso."
        viewMoreContent={getCurrentQuestions}
        additionalInformation={[
          { value: 'Todo tipo de cargos', icon: 'user' },
          { value: 'ES, EN, PT', icon: 'flag' },
        ]}
        buttons={getButtons}
      />

      <Modal
        title={
          <div style={{ fontSize: 18, fontWeight: 700 }}>
            Configurar preguntas adicionales
          </div>
        }
        bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 280px)' }}
        visible={isVisible}
        maskClosable={false}
        onOk={() => setIsVisible(false)}
        onCancel={() => setIsVisible(false)}
        cancelText={'Cancelar'}
        width={1000}
        forceRender={true}
        destroyOnClose={true}
        footer={[
          <Button key="cancel" onClick={() => setIsVisible(false)}>
            Cancelar
          </Button>,
          <Button key="ok" type="primary" onClick={onSave}>
            Guardar
          </Button>,
        ]}
        className="evaluation-modal"
      >
        <div className="evaluation-modal-title">Preguntas adicionales</div>
        <div className="evaluation-modal-section">
          Plantea preguntas para los candidatos y obtén información clave para
          tu proceso.
        </div>

        <div className="evaluation-modal-sub-title">
          Preguntas seleccionadas
        </div>

        {currentQuestions.length == 0 ? (
          <div className="evaluations-flex-div">
            <Row type="flex" justify="center">
              <img src={LOGO_URL} alt="cute-logo" style={{ height: 40 }} />
            </Row>
            <Row type="flex" justify="center">
              <Typography.Text strong style={{ alignText: 'center' }}>
                Aún no has seleccionado ninguna pregunta.
              </Typography.Text>
            </Row>
            <Row type="flex" justify="center">
              <Typography.Text>
                Busca o crea nuevas preguntas debajo de esta sección.
              </Typography.Text>
            </Row>
          </div>
        ) : (
          <Row type="flex" justify="start" gutter={[20, 20]}>
            {currentQuestions
              .sort((a, b) => a.position - b.position)
              .map((question, idx) => (
                <Col xs={24} sm={24} lg={24} key={idx}>
                  <QuestionRow
                    questionObj={question}
                    index={idx}
                    questionsGroupType={'currentQuestion'}
                  />
                </Col>
              ))}
          </Row>
        )}

        <Row type="flex" justify="start" style={{ marginBottom: 20 }}>
          {configNewQuestion || questionToEdit != null ? (
            <NewQuestion
              onClose={() => {
                setConfigNewQuestion(false);
                setQuestionToEdit(null);
              }}
              addOrEditQuestion={addOrEditQuestion}
              questionToEdit={questionToEdit}
              hasAvailabilityQuestion={hasAvailabilityQuestion}
            />
          ) : (
            <Button
              className="evaluation-card-button-add"
              onClick={() => {
                setConfigNewQuestion(true);
              }}
            >
              Crear pregunta
            </Button>
          )}
          <div id="dummy-ref" ref={editComponent} />
        </Row>

        <div className="evaluation-modal-sub-title" style={{ marginTop: 10 }}>
          Preguntas disponibles
        </div>

        <Row type="flex" justify="start" gutter={[20, 20]}>
          <Col xs={24} sm={12}>
            <Input
              className="evaluation-modal-section"
              placeholder="Buscar pregunta"
              suffix={<Icon type="search" />}
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </Col>
        </Row>

        <Row type="flex" justify="start" gutter={[10, 10]}>
          {(searchInput !== undefined
            ? filteredQuestions
            : availableQuestions
          ).map((question, idx) => (
            <Col xs={24} sm={24} lg={24} key={idx}>
              <QuestionRow
                questionObj={question}
                index={idx}
                questionsGroupType={'template'}
                hasAvailabilityQuestion={hasAvailabilityQuestion}
              />
              <Divider style={{ marginTop: 20, marginBottom: 10 }} />
            </Col>
          ))}
        </Row>
      </Modal>
    </>
  );
}

export default AdditionalQuestions;
