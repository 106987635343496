import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import * as actionCreators from '../../../../actions/data';

import {
  Typography,
  Button,
  Icon,
  Tooltip,
  Form,
  Input,
  Modal,
  message,
} from 'antd';
import MceEditor from '../../NewProcessFormV2/components/mceeditor/MceEditor.js'
import i18n from '../../../../i18n';

const NewTemplate = (props) => {

  const { Title, Text } = Typography;

  const [preview, setPreview] = useState(false)
  const [state, setState] = useState({
    name: '',
    subject: '',
    subjectEmpty: false,
    body: '',
    readOnlyBody: ''
  })

  const navigateToEmailTemplates = () => {
    props.dispatch(push('/emailtemplates'))
  };

  const showConfirm = () => {
    Modal.confirm({
      centered: true,
      title: 'Tienes cambios sin guardar. ¿Estás seguro de continuar?',
      okText: i18n.t('commons__confirm'),
      cancelText: 'Cancelar',
      onOk: () => navigateToEmailTemplates(),
    })
  };

  const handleChange = (name) => (event) => {
    setState({
      ...state,
      [name]: event.target.value
    })
  };

  const saveTemplate = (e) => {
    e.preventDefault()
    if (state.subject.length !== 0) {
      const url = `accounts/business/${props.business.id}/mailtemplates/`
      const token = props.token;

      const data = { template: state.body, name: state.name, subject: state.subject };
      props.actions.sendData(token, url, JSON.stringify(data), 'post', '').then(() => {
        message.success('La plantilla se creó exitosamente', 3)
        navigateToEmailTemplates()
      })
    }
  };

  const insertKey = (type) => {
    switch (type) {
      case 'name':
        window.tinymce.activeEditor.execCommand('mceInsertContent', false, " --name-- ");
        return;
      case 'processName':
        window.tinymce.activeEditor.execCommand('mceInsertContent', false, " --processName-- ");
        return;
    }
  }

  const previewTemplate = () => {
    setPreview(true);
    const readOnlyBody = state.body.replace(/(--name--)+/g, 'NombrePersona')
      .replace(/(--processName--)+/g, 'NombreProceso');
    setState({
      ...state,
      readOnlyBody
    })
  };

  const handleCancel = () => {
    setPreview(false)
  };

  const { getFieldDecorator } = props.form;

  return (
    <div className='template-crud'>
      <div className='template-crud__header'>
        <Tooltip title='Volver' >
          <Button
            type='primary'
            size='small'
            onClick={showConfirm}
          ><Icon type="left" />
          </Button>
        </Tooltip>

        <Title level={3}>Crear nueva plantilla</Title>
      </div>

      <div className='template-crud__body'>
        <Form onSubmit={saveTemplate}>
          <Form.Item label={<Tooltip title='Este es el nombre con el que puedes identificar la plantilla que vas a crear.'>Nombre de la plantilla</Tooltip>}>
            {getFieldDecorator('name', {
              initialValue: state.name,
              onChange: handleChange('name'),
              rules: [{
                required: true,
                message: 'Debes crearle un nombre para identificar a la plantilla'
              }],
            })(
              <Input
                placeholder="Nombre plantilla"
              />,
            )}
          </Form.Item>

          <Form.Item label='Asunto' >
            {getFieldDecorator('subject', {
              initialValue: state.subject,
              onChange: handleChange('subject'),
              rules: [
                {
                  max: 199,
                  message: 'Máximo 200 caracteres'
                },
                {
                  required: true,
                  message: 'Ingresa un asunto para la plantilla'
                }],
            })(
              <Input
                maxLength={200}
                placeholder="Asunto"
              />,
            )}
          </Form.Item>

          {state.body !== undefined ? <MceEditor name='body' body={state.body} handleChange={handleChange}  disabled={preview} readOnlyBody={state.readOnlyBody} /> : null}

          <div className='template-crud__buttons'>
            <Form.Item>
              <Button type='secondary' onClick={() => insertKey('name')} >Insertar nombre</Button>
            </Form.Item>
            <Form.Item>
              <Button type='secondary' onClick={() => insertKey('processName')} >Insertar nombre proceso</Button>
            </Form.Item>
            <Form.Item>
              <Button type='secondary' onClick={previewTemplate} >Vista previa</Button>
            </Form.Item>
            <Form.Item>
              <Button type='primary' htmlType='submit' >
                Crear plantilla
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>

      <Modal
        title={<Text strong>Visualización de la plantilla</Text>}
        visible={preview}
        className='modal-preview'
        onCancel={handleCancel}
        footer={[
          <Button key='1' onClick={handleCancel} type='primary' >
            Volver
          </Button>
        ]}
      >
        {state.body !== undefined ? <MceEditor name='body' body={state.body} handleChange={handleChange}  disabled={preview} readOnlyBody={state.readOnlyBody} /> : null}
      </Modal>
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    token: state.auth.token,
    business: state.auth.currentBusiness,
  }
}

const NewTemplateView = Form.create()(NewTemplate);
export default connect(mapStateToProps, mapDispatchToProps)(NewTemplateView);