import React, { useState } from 'react'

import {
  Button, Icon, Col, Modal, Row, Tag, Typography
} from 'antd';

import ParseHtml from '../../../../../../components/parseHtml'

import '../EvaluationsStyles.scss'
import SituationalPreview from '../../../../../SituationalTests/SituationalPreview';

function CustomTestCard({ addTest, removeTest, cute, cuteType, type }) {
  const [detailVisible, setDetailVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const { Text } = Typography;

  const ExpandedRowCutes = (cute) => {
    return (
      <Row>
        <Col sm={12}>
          <Row type='flex'>
            <Col style={{ minWidth: '160px', minHeight: '96px' }}>
              <div>
                <Text strong level={4} style={{ color: '#454545' }}>Dimensiones</Text>
              </div>
              <div>
                {getDimensions(cute)}
              </div>
            </Col>
          </Row>
        </Col>
        <Col sm={12}>
          <Row type='flex'>
            <Col style={{ minWidth: '160px', minHeight: '96px', border: '1px solid #D9D9D9', background: '#FAFAFA', padding: '10px' }}>
              <div>
                <Icon type='question-circle' style={{ color: '#F175A5' }} />
              </div>
              <div>
                <Text strong level={4} style={{ color: '#454545' }}>Número de preguntas</Text>
              </div>
              <div>
                {countQuestions(cute)}
              </div>

            </Col>
          </Row>
        </Col>

      </Row>
    )
  };

  const cuteTime = () => cute?.questions?.global_time ? `${cute?.questions.global_time / 60} minutos` : 'Sin tiempo';

  const countQuestions = (cute) => {
    const dataArray = cute.questions.data;
    const config = cute.scores_config;

    if (config?.questions_to_evaluate) return config.questions_to_evaluate;

    let total = 0;
    dataArray.forEach(obj => {
      // Don't count conditional pages or read only fields
      if (obj.conditional === undefined) {
        let fieldsLength = obj.fields.filter(field => field.component !== 'read_only').length
        total = fieldsLength + total;
      }
    })
    return total;
  };

  const getDimensions = (cute) => {
    const dataArray = cute.questions.data;
    const categories = new Set();
    dataArray.forEach(obj => {
      obj.fields?.forEach(question => {
        if (question.category && question.category !== '') {
          categories.add(question.category);
        }
      })
    })
    const componentArray = Array.from(categories).map((elem, idx) =>
      <div key={idx}>
        <Icon type='check' style={{ marginRight: 5 }} />
        {elem}
      </div>
    );
    return componentArray.length > 0 ? componentArray : <div>Sin información</div>;
  };


  return (
    <div className='evaluation-card'>
      <div className='evaluation-modal-sub-title'>{cute?.title}</div>
      <Tag>{cuteTime()}</Tag>
      <div className='evaluation-modal-section'>
        {ParseHtml(cute?.description)}
      </div>
      <div>
        {detailVisible && ExpandedRowCutes(cute)}
      </div>
      <div></div>
      <Row type='flex' justify='space-between' gutter={[20, 20]} style={{ marginTop: 10 }}>
        <Col>
          {cuteType === 'situational' ? <Button icon='plus-circle' onClick={() => setModalVisible(true)} /> : (
            detailVisible ?
              <Button icon='minus-circle' onClick={() => setDetailVisible(false)} />
              :
              <Button icon='plus-circle' onClick={() => setDetailVisible(true)} />
          )}
        </Col>
        <Col>
          {
            type === 'add' ?
              <Button
                className='evaluation-card-button-add'
                onClick={addTest}>
                Agregar
              </Button> :
              <Button
                className='evaluation-card-button-remove'
                onClick={removeTest}>
                Quitar
              </Button>
          }
        </Col>
      </Row>
      <Modal
        width={950}
        visible={modalVisible} 
        onCancel={() => setModalVisible(false)}
        title="Detalles"
        footer={[
          <Button key='exit-button' type='primary' onClick={() => setModalVisible(false)}>
            Salir
          </Button>]}
      >
        <SituationalPreview cute={cute} modeModal />
      </Modal>
    </div>
  );
};

export default CustomTestCard;