import moment from 'moment';

export const dateDifferenteInDays = (date) => {
  const now = moment();
  const procDate = moment(date, "DD/MM/YYYY");

  const diff = moment.duration(now.diff(procDate));

  return Math.floor(diff.asDays());
};
