import React, { useContext, useEffect, useState } from 'react';

import { CandidateContext } from '../config/CandidateContext';

import { connect } from 'react-redux';
import { Alert, Avatar, Button, Card, Col, message, Modal, Result, Row, Typography } from 'antd';
import Comment from './Comment';
import i18n from '../../../i18n';
import ParseHtml from '../../../components/parseHtml';
import RichTextEditor from './Comment/RichTextEditor';
import { SERVER_URL } from '../../../utils/config';
import '../styles.scss'

const { Title, Text } = Typography;
const assetsUrl = 'https://genoma-assets.s3.us-east-2.amazonaws.com/';

const Comments = ({ auth }) => {
  const {
    actions,
    candidate,
    jobApp,
    token,
    translationFile,
  } = useContext(CandidateContext);

  const [attachedFiles, setAttachedFiles] = useState([]);
  const [comments, setComments] = useState([]);
  const [currentComment, setCurrentComment] = useState('');
  const [collaborators, setCollaborators] = useState([]);
  const [commentCreated, setCommentCreated] = useState(false);
  const [isPublic, setIsPublic] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [father, setFather] = useState(null);
  const [commentToDelete, setCommentToDelete] = useState(null);
  const [visible, setVisible] = useState(false);
  const [externalLink, setExternalLink] = useState(null);

  useEffect(() => {
    const getComments = async () => {
      // TODO: update candidate id for real candidate id instead of (personal_user_id) disguised as candidate id 
      const url = `/api/v1/gamesandtests/jobapplications/${jobApp.id}/candidates/${candidate.id}/comments/`;
      const response = await actions.fetchAndReturn(token, url);
      if (response.body.results) {
        setComments(response.body.results);
      }
    };

    getComments();
  }, [jobApp, candidate]);


  useEffect(() => {
    const fetchBusinessUsers = async () => {
      const url = `/api/v1/accounts/jobapplications/${jobApp.id}/businessusers/`;
  
      const response = await actions.fetchAndReturn(token, url)
      if (response.body.results) {
        const mappedCollabs = response.body.results.map((business_user) => {
          return {
            value: business_user.id,
            label: business_user.name ?? business_user.email,
            initials: business_user.initials
          }
        })
        setCollaborators(mappedCollabs);
      }
    };
    fetchBusinessUsers();
  }, [auth.currentBusiness.id, token])

  useEffect(() => {
    if (commentToDelete) {
      deleteComment();
      setCommentToDelete(null);
    }
  }, [commentToDelete])

  useEffect(() => {
    const handleGlobalClick = event => {
      event.preventDefault();

      let element = event.target;

      while (element && element.nodeName !== 'A') {
        element = element.parentElement;
      }
      
      if (element && element.href) {
        let href = element.href;
        if (element.href.startsWith(window.location.origin)) {
          href = element.href.replace(`${window.location.origin}/processes/`, 'http://');
        }
        setVisible(true);
        setExternalLink(href);
      }
    };
    window.addEventListener('click', handleGlobalClick);
  
    return () => {
      window.removeEventListener('click', handleGlobalClick);
    };
  }, []);
  
  const createComment = async () => {
    const url = `/api/v1/gamesandtests/jobapplications/${jobApp.id}/candidates/${candidate.id}/comments/`;
    const formData = new FormData();
    formData.append('text', currentComment);
    formData.append('public', isPublic);
    formData.append('shared_with', selectedUsers);

    if (father) {
      formData.append('father', father.id);
    }
    if (attachedFiles.length > 0) {
      formData.append('attachments', attachedFiles.pop().originFileObj);
    }
    await fetch(`${SERVER_URL}${url}`, {
      method: 'POST',
      headers: {
        Authorization: `Token ${token}`,
      },
      body: formData,
    })
    .then((response) => response.json())
    .then((data) => {
      if (data) {
        if (father) {
          setComments(comments.map((comment) => {
            if (father.id === comment.id) {
              return { ...comment, children: [...comment.children, data] }
            }
            return comment
          }))
        } else {
          setComments([...comments, data]);
        }
        resetStates();
      }
      else {
        message.error(i18n.t('profile__error_creating_comment', translationFile));
      }
    })   
  };

  const deleteComment = async () => {
    const url = `/api/v1/gamesandtests/jobapplications/${jobApp.id}/candidates/${candidate.id}/comments/${commentToDelete.id}/`;
    
    const response = await actions.fetchAndReturn(token, url, 'DELETE')
    if (response.status < 400) {
      setComments(comments.map((comment) => {
        if (comment.id === commentToDelete.id) {
          return response.body
        }
        return comment
      }))
    }
    else {
      message.error(i18n.t('profile__error_deleting_comment', translationFile));
    }
  };

  const usersWithAccess = (comment) => {
    const { shared_with, tagged_users } = comment;
    const businessUsers = collaborators.filter(({ value }) => shared_with.includes(value) || tagged_users.includes(value))
    return businessUsers.map(({ label }) => label).join(', ')
  }

  const resetStates = () => {
    setCurrentComment('');
    setCommentCreated(true);
    setIsPublic(true);
    setSelectedUsers([]);
    setAttachedFiles([]);
    setFather(null);
  }

  return (
    <div style={{ display: 'grid' }}>
      {comments.length > 0 ? comments.map((comment) => 
        <Row type='flex' justify='center' key={comment.id} gutter={[0, 30]}>
          <Col xl={22} xs={24} style={{ marginBottom: 20 }}>
            {comment.deleted ? 
              <Comment
                comment={comment}
              /> : 
              <>
                <Comment
                  name={auth.name}
                  comment={comment}
                  usersWithAccess={comment.public ? [] : usersWithAccess(comment)}
                  setFather={setFather}
                  setCommentToDelete={setCommentToDelete}
                />
                {comment.children?.map((child) => 
                  <Comment
                    comment={child}
                    isChild={true}
                    key={child.id}
                    usersWithAccess={child.public ? [] : usersWithAccess(child)}
                  />
                )}
              </>
            }
          </Col>
        </Row>
      ) :
        <Row
          style={{
            backgroundSize: 'contain',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Col
            span={8}
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              height: '35em',
              justifyContent: 'center',
            }}
          >
            <Row type='flex' justify='center' style={{ width: '30em' }}>
              <img
                style={{ width: '50%' }}
                src={`${assetsUrl}genomines/8.png`}
              />
            </Row>
            <Row type='flex' justify='center' style={{ width: '30em' }}>
              <Title level={2} style={{ color: '#454545' }}>
                {i18n.t('profile__empty_comments', translationFile)}
              </Title>
            </Row>
          </Col>
        </Row>
      }
      <Row type='flex' justify='center'>
        <Col xl={22} xs={24}>
          {father && (
            <Card className='response-card' style={{ border: '1px solid #D9D9D9' }}>
              <div className='response-header'>
                <Text className='answer' type="secondary">{i18n.t('profile__answer_to', { ns: 'candidateProfile' })}</Text>
              </div>
              <Row type='flex' justify='start'>
                <Col span={2}>
                  <Avatar 
                    className='avatar'
                    size='large' 
                    style={{ 
                      backgroundColor: '#F5F5F5', 
                      color: '#454545', 
                      border: '1px solid #D9D9D9'
                    }}
                    >
                      {father.initials}
                    </Avatar>
                </Col>
                <Col span={22}>
                  <Text strong>{father.author}</Text>
                  <Text>{ParseHtml(father.parsed_text)}</Text>
                </Col>
              </Row>
            </Card>
          )}
          <RichTextEditor 
            mentionOptions={collaborators}
            handleEditorChange={(body) => setCurrentComment(body)}
            resetValueState={commentCreated}
            setResetValueState={setCommentCreated}
            isPublic={isPublic}
            setIsPublic={setIsPublic}
            selectedItems={selectedUsers}
            setSelectedItems={setSelectedUsers}
            attachedFiles={attachedFiles}
            setAttachedFiles={setAttachedFiles}
          />
        </Col>
      </Row>
      <Row type='flex' justify='end' gutter={[20, 0]} style={{ marginTop: 20 }}>
        <Col sm={{ span: 4, pull: 1 }} xs={{ span: 24, pull: 0 }}>
          <Button 
            type="primary" 
            onClick={createComment} 
            style={{ width: '100%' }}
            disabled={currentComment.length === 0 && attachedFiles.length === 0}
          >
            {i18n.t('commons__save')}
          </Button>
        </Col>
        <Col sm={{ span: 4, pull: 1 }} xs={{ span: 24, pull: 0 }}>
            <Button 
              onClick={resetStates}
              style={{ width: '100%' }}
              disabled={currentComment.length === 0}
            >
              {i18n.t('commons__cancel')}
            </Button>
        </Col>
      </Row>
      <Modal 
        title={i18n.t('profile__external_link', { ns: 'candidateProfile' })} 
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => { setVisible(false); window.open(externalLink) }}
      >
        <Result 
          title={i18n.t('profile__exiting_genomawork', { ns: 'candidateProfile' })}
          subTitle={i18n.t('profile__go_to_external_link', { ns: 'candidateProfile' })}
          extra={[<Alert key="alert" message={i18n.t('profile__not_responsible_for_content', translationFile)} type="info"/>]}
        >
          <p>{i18n.t('profile__redirected_to', { ns: 'candidateProfile' })}</p>
          <p>{externalLink}</p>
        </Result>
      </Modal>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Comments);
