import React from 'react';
// styles are stored in src/components/tour/styles.scss

const tourConfigHome = [
  {
    selector: '.home-left__metrics',
    content: () =>
      <div className="tour-popover-div">
        En las <strong>métricas generales</strong> podrás ver tus procesos abiertos, tus candidatos contratados y tus candidatos descartados de todos los procesos. También tendrás un accesso directo para ir a las estadísticas de tus contrataciones.
      </div>,
  },
  {
    selector: '.home-left__processes',
    content: () =>
      <div className="tour-popover-div">
        En esta sección podrás ver los últimos procesos creados en el mes.
      </div>,
  },
  {
    selector: '[data-tour="right-div-1"]',
    content: () =>
      <div className="tour-popover-div">
        Desde Genomawork te queremos mantener informado de los cambios que te iremos ofreciendo para mejorar tus procesos de reclutamiento y puedas sacarle el mayor provecho a la plataforma y a tus procesos de selección.
      </div>,
  },
  {
    selector: '[data-tour="right-div-2"]',
    content: () =>
      <div className="tour-popover-div">
        Utiliza todas las herramientas que tienes a disposición para potenciar tu marca empleadora y brindarle la mejor experiencia a tus candidatos.
      </div>,
  },
  {
    selector: '[data-tour="right-div-3"]',
    content: () =>
      <div className="tour-popover-div">
        Por último, podrás tener acceso directo a todos nuestros blogs relacionados con recursos humanos y otros temas que podrían ser útiles para ti y tu equipo de trabajo.
      </div>,
  },

];

export default tourConfigHome;