import React from 'react'
import InviteCollaborators from './components/InviteCollaborators';
import CollaboratorsList from './components/CollaboratorsList';
import { Row } from 'antd';

const Collaborator = () => {

  return (
    <Row title='Usuarios'>
      <InviteCollaborators />
      <CollaboratorsList />
    </Row>
  )
}

export default Collaborator
