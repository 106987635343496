
import React from 'react';
import GenomaSteps from '../../../../../components/GenomaSteps/GenomaSteps';

const reavenLabels = {
  'level': 'IQ',
  'grade': 'Grado',
  'percentile': 'Percentil',
  'range': 'Rango',
  'interpretation': 'Interpretación',
}

const RavenSegment = ({ activeStep }) => {
  const stepsData = [
    {
      title: 'Deficiente',
      content: '',
      label: '5'
    },
    {
      title: 'Inferior al promedio',
      content: '',
      label: '25'
    },
    {
      title: 'Promedio',
      content: '',
      label: '50'
    },
    {
      title: 'Superior al promedio',
      content: '',
      label: '75'
    },
    {
      title: 'Superior',
      content: '',
      label: '95'
    }
  ];

  return (
    <>
      <GenomaSteps data={stepsData} activeStep={activeStep} md={24} />
    </>
  )
};

const RavenDictionaryComponent = ({ dictionary, scoresConfig, labels = reavenLabels }) => {
  if (!dictionary) return <div style={{ fontSize: 24, fontWeight: 700 }}>{'Resultado no válido'}</div>;
  const mappedObject = Object.entries(dictionary).map(([key, value]) => {
    if (['grade', 'percentile', 'range'].includes(key)) return null;
    return (
      <div key={key} style={{ minHeight: 30, border: '1px solid #45454' }}>
        {`${labels[key]}: ${value}`}
      </div>
    )
  })
  const extra = scoresConfig.segments.find(obj => obj.max == dictionary['percentile']);
  return (
    <div style={{ marginBottom: 12 }}>
      {mappedObject}
      {`${extra.description}`}
      {dictionary['percentile']}
      <div style={{ marginTop: 20 }}>
        <RavenSegment activeStep={[5, 25, 50, 75, 95].findIndex(elem => elem == dictionary.percentile)} />
      </div>
    </div>
  )
};

export default RavenDictionaryComponent;