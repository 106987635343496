import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { 
  Input, 
  Icon, 
  AutoComplete, 
  Row,
  Spin } from 'antd'

import * as actionCreators from '../../../actions/data';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

class SearchField extends React.Component {

  state = {
    resultsCandidate: [],
    resultsProcess: [],
    value: '',
    options: []
  };

  searchAPIDebounced = AwesomeDebouncePromise((val) => this.searchAPI(val), 500);

  componentWillReceiveProps(nextProps) {
    if (this.props.type === 'candidates' && JSON.stringify(nextProps.resultsCandidate) !== JSON.stringify(this.state.resultsCandidate)) {
      this.setState({ resultsCandidate: nextProps.resultsCandidate })
    }
    if (this.props.type === 'process' && JSON.stringify(nextProps.resultsProcess) !== JSON.stringify(this.state.resultsProcess)) {
      this.setState({ resultsProcess: nextProps.resultsProcess })
    }
  }

  searchAPI = (newValue) => {
    const { type } = this.props;
    const url = `gamesandtests/searchuserjobapp/?type=${type}&text=${newValue}`
   
    this.setState({ loading: true })

    this.props.actions.search(this.props.token, url, newValue).then(() => {
      this.setState({ loading: false })
    })
  }

  handleClick = (item) => {
    const { type } = this.props;
    
    if (type === 'candidates') {
      window.location.assign(`/processes/${item.job_application_id}?id=${item.candidate_id}`)
    } else {
      this.props.dispatch(push(`/processes/${item.job_application_id}`));
    }

    this.clearResults()
  }

  onSearch = newValue => {
    if (newValue) {
      this.searchAPIDebounced(newValue)
    } else {
      this.clearResults()
    }
    
    this.setState({ value: newValue })
  };

  onBlur = () => {
    this.clearResults()
  }

  onFocus = async () => {
    await this.clearResults()
    this.state.value && this.searchAPIDebounced(this.state.value)
  }

  clearResults = () => {
    this.setState({ 
      resultsCandidate: [],
      resultsProcess: [],
    })
  }

  formatDataSource = () => {
    const { type } = this.props;

    if (type === 'candidates') {
      // al buscar se repetían algunos candidatos del mismo proceso
      return this.state.resultsCandidate.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t.email === thing.email && t.process === thing.process
        ))
      )
    } else {
      return this.state.resultsProcess
    }
  }

  render() {
    const { loading, value } = this.state
    const { type } = this.props

    return (
      <Row type='flex' align='middle' className="gw-search-field" style={{ height: 55 }}>
        <AutoComplete
          style={{ width: 200 }}
          allowClear
          onBlur={this.onBlur}
          onSearch={this.onSearch}
          onFocus={this.onFocus}
          optionLabelProp="value"
          placeholder={
            type === 'candidates' ? 'Buscar candidato' : 'Buscar proceso'
          }
          notFoundContent={
            value ? (
              loading ? (
                <Spin size="small" />
              ) : (
                'No se encontraron resultados'
              )
            ) : null
          }
          dropdownClassName="certain-category-search-dropdown"
          dataSource={
            value
              ? this.formatDataSource().map((item) => (
                  <AutoComplete.Option
                    key={
                      type === 'process'
                        ? item.job_application_id
                        : item.email + item.process
                    }
                    value={
                      type === 'process'
                        ? item.job_application + item.job_application_id
                        : item.name + item.process + item.email
                    }
                    onClick={() => this.handleClick(item)}
                  >
                    {type === 'process' ? (
                      <Fragment>
                        <div className="dropdown-icon">
                          <Icon type="reload" />
                        </div>
                        <div className="dropdown-item">
                          <strong>{item.job_application}</strong>
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <div className="dropdown-icon">
                          <Icon type="user" />
                        </div>
                        <div className="dropdown-item">
                          <span>{item.name}</span>
                          <span>{item.email}</span>
                          <strong>{item.process}</strong>
                        </div>
                      </Fragment>
                    )}
                  </AutoComplete.Option>
                ))
              : []
          }
        >
          <Input
            className="new-input-navbar-search"
            prefix={<Icon type="search" className="icon-search"/>}
            placeholder="Buscar candidatos..."
          />
        </AutoComplete>
      </Row>
    );
  }
}

SearchField.propTypes = {
  type: PropTypes.string
};

const mapStateToProps = (state) => {
  const newData = state.data.searchResults;
  return {
    resultsCandidate: newData,
    resultsProcess: newData,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: bindActionCreators(actionCreators, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchField);
