import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  Input,
  Select,
  Checkbox,
  Row,
  Col,
  Button,
} from 'antd'

const { Search } = Input

const Filters = (props) => {
  const [data, setData] = useState([])
  const [usedFilters, setUsedFilters] = useState([])
  const [keyword, setKeyword] = useState(undefined)
  const [place, setPlace] = useState(undefined)
  const [type, setType] = useState(undefined)
  const [department, setDepartment] = useState(undefined)
  const [tag, setTag] = useState(undefined)
  const [dates, setDates] = useState(undefined)

  const [locations, setLocations] = useState([])
  const [departments, setDepartments] = useState([])


  useEffect(() => {
    if (props.data) {
      const allProcesses =  [...props.data.ACTIV, ...props.data.INACT, ...props.data.OTHER]
      setData(allProcesses);
      const newLocations = groupByValue('location', allProcesses);
      const newDepartments = groupByValue('department', allProcesses);
      setLocations(newLocations);
      setDepartments(newDepartments);
    }
  }, [props.data.ACTIV, props.data.INACT, props.data.OTHER])

  const setQueryString = () => {
    let q = '';
    if (place) {
      q += `location=${place.join(';')}&`
    }
    if (department) {
      q += `department=${department}&`
    }
    if (keyword) {
      q += `q=${keyword}&`
    }
    props.setSearchQueryStrings(q);
  }

  useEffect(() => {
    setQueryString();
  }, [place, department])

  const onChange = (key) => (newValue) => {
    switch (key) {
      case 1: // buscar
        const keyword = newValue.target.value;
        setKeyword(keyword || undefined)
        !keyword && clearFilter(1)
        break;

      case 2: // lugar
        setPlace(newValue)
        !newValue.length && clearFilter(2)
        break;

      case 3: // tipo
        setType(newValue)
        break;

      case 4: // departamento
        setDepartment(newValue)
        !newValue.length && clearFilter(4)
        break;

      case 5: // buscar
        const tags = newValue.target.value;
        setTag(tags)
        !tags && clearFilter(5)
        break;
      case 6:
        break;
    }

    if (!usedFilters.includes(key)) {
      setUsedFilters([...usedFilters, key])
    }
  }


  const clearFilter = async (key) => {
    switch (key) {
      case 1: // buscar nombre proceso
        setKeyword(undefined);
        break

      case 2: // lugar
        setPlace(undefined)
        break;
      case 3: // tipo
        setType(undefined)
        break;
      case 4: // departamento
        setDepartment(undefined)
        break;
      case 5: // tags
        setTag(undefined)
        break;
      case 6:
        setDates(undefined)
        break;
    }
    // Caso especial keyword no tiene asociado un useffect porque se gatilla con el boton buscar
    if (key==1){
      let q= '';
      if (place) {
        q += `location=${place.join(';')}&`
      }
      if (department) {
        q += `department=${department}&`
      }
      props.setSearchQueryStrings(q);
    }
    setUsedFilters(usedFilters.filter((filter) => filter !== key));
  }

  // lleva la cuenta de los filtros que se estan usando para mostrarlo 
  // en el btn que abre los filtros 
  useEffect(() => {
    props.count(usedFilters.length)
  }, [usedFilters])

  /** 
   * @param { String } value location || department
   * @return { Array } lista de lugares o departamentos
   */
  const groupByValue = (criteria, processes) => {
    const currentGroups = (criteria === 'location' ? locations : departments).map(x => x[0]);
    // Agrupa los procesos por cada lugar o departamento    
    const processesGroups = processes && processes.map((obj) => obj[criteria]);
    const groups = [...currentGroups,...processesGroups].reduce(
      (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
      [],
    );
    const resources = []

    for (const x in groups) {
      // cantidad de procesos por cada lugar o departamento
      const total = processes.filter((process) => process[criteria] === groups[x]).length
      resources.push([groups[x], total])
      // lista Checkbox.Group
    }

    return resources;
  }

  

  return (
      <Row className='gw-pl__filters' type='flex'>
        <Form style={{ width: '100%' }}>
          <Row gutter={10} type='flex'>
            <Col xs={24} sm={12} md={12} style={{ display: 'block' }}>
              <Form.Item
                extra={usedFilters.includes(1) && <a onClick={() => clearFilter(1)}>Limpiar</a>}
              >
                <Search
                  placeholder='Buscar por nombre o etiquetas'
                  onChange={onChange(1)}
                  enterButton={
                    <Button style={{ fontSize: 14 }} icon='search'>
                      Buscar
                    </Button>
                  }
                  onSearch={setQueryString}
                  // onSearch={props.reloadProcesses}
                  value={keyword}
                  size='large'
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={6}>
              <Form.Item extra={usedFilters.includes(2) && <a onClick={() => clearFilter(2)}>Limpiar</a>}>
                <Select
                  placeholder='Ubicación'
                  value={place && place.map((x) => x.replace(/,/g, '')).join(', ')}
                  size='large'
                  dropdownClassName='gw-pl-dwn-filters'
                  dropdownRender={() => (
                    <div onMouseDown={(e) => e.preventDefault()}>
                      <Checkbox.Group
                        options={locations.map(x => (
                          {
                            value: x[0],
                            label: (
                              <div className='chk-label'>
                                <div>{x[0]}</div>
                                <div>{x[1]}</div>
                              </div>
                            )
                          }
                        ))}
                        onMouseDown={(e) => e.preventDefault()}
                        onChange={onChange(2)}
                        value={place}
                      />
                    </div>
                  )}
                >
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={6}>
              <Form.Item extra={usedFilters.includes(4) && <a onClick={() => clearFilter(4)}>Limpiar</a>}>
                <Select
                  placeholder='Departamento'
                  value={department && department.join(', ')}
                  size='large'
                  dropdownClassName='gw-pl-dwn-filters'
                  dropdownRender={() => (
                    <div onMouseDown={(e) => e.preventDefault()}>
                      <Checkbox.Group
                        options={departments.map(x => (
                          {
                            value: x[0],
                            label: (
                              <div className='chk-label'>
                                <div>{x[0]}</div>
                                <div>{x[1]}</div>
                              </div>
                            )
                          }
                        ))}
                        onMouseDown={(e) => e.preventDefault()}
                        onChange={onChange(4)}
                        value={department}
                      />
                    </div>
                  )}
                >
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Row>
  )
}

Filters.propTypes = {
  visible: PropTypes.bool.isRequired,
  count: PropTypes.func,
  data: PropTypes.array,
  updateData: PropTypes.func,
  close: PropTypes.func,
  setSearchQueryStrings: PropTypes.func,
  reloadProcesses: PropTypes.func,
};

export default Filters