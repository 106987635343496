import React, { useContext, useEffect, useState } from 'react';
import { CandidateContext } from '../config/CandidateContext';

import {
  Button,
  Icon,
  Row,
  Tooltip,
  Typography,
  message,
} from 'antd';

import NewCustomFieldModal from '../../../components/NewCustomFieldModal';
import i18n from '../../../i18n';

import { v4 as uuidv4 } from 'uuid';

import useBoolean from '../../../hooks/useBoolean';
import PreviewDocument from '../../CandidateProfilev2/common/PreviewDocument';
import CustomFieldEdition from './CustomFieldEdition';
import CustomFieldAnswer from './CustomFieldAnswer';

const { Text } = Typography;

const fieldTypes = {
  USERJA: ['postulación', 'esta postulación'],
  CANDID: ['candidato', 'este candidato'],
};

function CustomFields(props) {
  const {
    businessCustomFields,
    application: { custom_fields },
    updateCustomField,
  } = useContext(CandidateContext);

  const [customFieldKeyInEditionMode, setCustomFieldKeyInEditionMode] =
    useState();
  const [newCustomFieldValue, setNewCustomFieldValue] = useState();
  const [newCustomFieldModalVisible, setNewCustomFieldModalVisible] =
    useState(false);

  const [fileList, setFileList] = useState([]);

  const [previewFlag, handlePreview] = useBoolean(false);
  const [selectedFileId, setSelectedFileId] = useState(null);

  const beforeUpload = (file) => {
    const isDocOrPDF =
      file.type === 'application/msword' ||
      file.type ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      file.type === 'application/pdf';
    if (!isDocOrPDF) {
      message.error(
        i18n.t('profile__file_type_restriction', {
          ns: 'candidateProfile',
        })
      );
      return false;
    }

    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error(
        i18n.t('profile__file_mb_restriction', {
          ns: 'candidateProfile',
        })
      );
      return false;
    }

    file.uid = uuidv4();
    setFileList([{ uid: '-1', name: file.name, status: 'done', url: '' }]);
    setNewCustomFieldValue(file);

    return false;
  };

  const model = props.fieldType === 'USERJA' ? 'application' : 'candidate';
  const businessCustomFieldsObj = {};
  const tmpCustomFields = [...businessCustomFields[model]];
  const customFields = { ...custom_fields[model] };
  for (let i = 0; i < tmpCustomFields.length; i++) {
    const element = tmpCustomFields[i];
    if (!Object.keys(customFields).includes(element.name_key)) {
      customFields[element.name_key] = null;
    }
    businessCustomFieldsObj[element.name_key] = { ...element };
    delete businessCustomFieldsObj[element.name_key].name_key;
  }

  return (
    <div className="new-modal-content__background__section">
      <Text strong className="text-16px">
        {i18n.t(
          `profile__custom_attributes_${
            fieldTypes[props.fieldType][0] == 'candidato'
              ? 'candidate'
              : 'postulation'
          }`,
          { ns: 'candidateProfile' }
        )}
      </Text>
      {Object.entries(customFields).map((entry, idx) => {
        const [customFieldKey, customFieldValue] = entry;
        const customFieldObj = businessCustomFieldsObj[customFieldKey] || null;

        return (
          <Row key={`custom-field-${customFieldKey}-${idx}`}>
            <Row type="flex" align="middle">
              <Text strong>
                {customFieldObj
                  ? customFieldObj.name
                  : customFieldKey.replaceAll('_', ' ')}
                :
                {customFieldObj?.description && (
                  <Tooltip
                    placement="right"
                    title={
                      ![null, undefined, ''].includes(
                        customFieldObj.description
                      )
                        ? customFieldObj.description
                        : i18n.t('profile__attribute_description', {
                            ns: 'candidateProfile',
                          })
                    }
                  >
                    <Icon style={{ marginLeft: '5px' }} type="info-circle" />
                  </Tooltip>
                )}
              </Text>
            </Row>
            {customFieldKeyInEditionMode === customFieldKey ? (
              <CustomFieldEdition
                customFieldObj={customFieldObj}
                setNewCustomFieldValue={setNewCustomFieldValue}
                newCustomFieldValue={newCustomFieldValue}
                fileList={fileList}
                beforeUpload={beforeUpload}
                updateCustomField={updateCustomField}
                fieldType={props.fieldType}
                setCustomFieldKeyInEditionMode={setCustomFieldKeyInEditionMode}
                customFieldKey={customFieldKey}
              />
            ) : (
              <CustomFieldAnswer
                customFieldValue={customFieldValue}
                customFieldObj={customFieldObj}
                setSelectedFileId={setSelectedFileId}
                handlePreview={handlePreview}
                customFieldKeyInEditionMode={customFieldKeyInEditionMode}
                setNewCustomFieldValue={setNewCustomFieldValue}
                setCustomFieldKeyInEditionMode={setCustomFieldKeyInEditionMode}
                customFields={customFields}
                customFieldKey={customFieldKey}
                fieldTypes={fieldTypes}
                fieldType={props.fieldType}
                updateCustomField={updateCustomField}
              />
            )}
          </Row>
        );
      })}
      <Button
        disabled={customFieldKeyInEditionMode}
        onClick={() => setNewCustomFieldModalVisible(true)}
        style={{ paddingLeft: 0, width: 'fit-content', marginTop: 'auto' }}
        type="link"
      >
        {i18n.t('profile__add_attribute', { ns: 'candidateProfile' })}
      </Button>

      <PreviewDocument
        file={null}
        visiblePreviewModal={previewFlag}
        handleOk={() => {
          handlePreview.toggle();
          setSelectedFileId(null);
        }}
        fetchUrl={`/api/v1/gamesandtests/filerecords/${selectedFileId}/`}
        withFetch={true}
      />

      <NewCustomFieldModal
        existingFieldsKeys={[
          ...Object.keys(customFields),
          ...Object.keys(businessCustomFieldsObj),
        ]}
        closeModal={() => setNewCustomFieldModalVisible(false)}
        handleCustomFields={updateCustomField}
        type={props.fieldType}
        visible={newCustomFieldModalVisible}
      />
    </div>
  );
}

export default CustomFields;
