import React from 'react';
import NewCustomTest from './components/NewCustomTest';
import TestProvider from './context/CustomTestContext';

const AppNewCustomTest = () => {
  return (
    <TestProvider>
      <NewCustomTest />
    </TestProvider>
  );
}
 
export default AppNewCustomTest;