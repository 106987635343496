import React, { useState, useEffect, useContext } from 'react'

import {
  Button, Icon, Modal, message
} from 'antd';

import EvaluationCard from '../EvaluationCard';
import JobAppContext from '../../../context/JobAppContext';
import VideoInterviewConfig from './VideoInterviewConfig';
import { videoInterviewMessage } from '../../../context/Utils';

import '../EvaluationsStyles.scss'

function VideoInterview() {
  const { videoInterviews, currentStage, updateVideoInterviews, jobApplication, business, hasCandidates, stages } = useContext(JobAppContext);

  const [isVisible, setIsVisible] = useState(false);
  // [{text: "pregunta", time:30}, ...]
  const [questions, setQuestions] = useState([]);
  // We are no using the subject yet
  const [emailContent, setEmailContent] = useState({ content: '', subject: '' });
  const [isNewVideoInterview, setIsNewVideoInterview] = useState(true);

  // Clean state after modal is closed
  useEffect(() => {
    if (isVisible == false) {

    }
  }, []);

  useEffect(() => {
    const toAddInterviewIdx = videoInterviews.toAdd.findIndex(obj => obj.stage === currentStage);
    if (toAddInterviewIdx == -1) {
      setIsNewVideoInterview(false)
    }
    else {
      setIsNewVideoInterview(true)
    }
  }, [videoInterviews.toAdd]);

  useEffect(() => {
    const currentVideoInterview = videoInterviews.currentEvaluations.find(obj => obj.stage === currentStage);
    currentVideoInterview ? setQuestions([...currentVideoInterview.questions]) : setQuestions([]);
    if (currentVideoInterview == null || currentVideoInterview == undefined) {
      const msg = videoInterviewMessage(jobApplication.name, business.name);
      setEmailContent(oldState => ({ ...oldState, content: msg }));
    }
    else {
      // Existe al menos 1 VE
      setEmailContent(oldState => ({ ...oldState, content: videoInterviews.currentEvaluations[0].emailContent.content }));
    }
  }, [isVisible, videoInterviews, currentStage]);

  const vintQuestions = () => {
    const questionsComponent = questions.map((obj, idx) => <div key={idx}>{`• ${obj.text}`}</div>);
    return (
      <>
        <div style={{ marginBottom: 5, marginTop: 10 }}><strong>Preguntas agregadas:</strong></div>
        {questionsComponent}
      </>
    )
  };

  const onSave = () => {
    if (questions.length == 0) {
      message.warning('La video entrevista debe tener preguntas');
      return;
    }
    if (questions.find(obj => obj.text === '' || !obj.text)) {
      message.warning('Las preguntas no pueden estar vacías');
      return;
    }

    // Estamos editando
    if (videoInterviews.currentEvaluations.length > 0 && videoInterviews.toAdd.length == 0) {
      const currentVideoInterviewIdx = videoInterviews.currentEvaluations.findIndex(obj => obj.stage === currentStage);
      videoInterviews.currentEvaluations[currentVideoInterviewIdx].questions = questions;

      const toUpdateInterviewIdx = videoInterviews.toUpdate.findIndex(obj => obj.stage === currentStage);
      // Si ya estaba el elmento en update sobre escribe
      if (toUpdateInterviewIdx != -1) {
        videoInterviews.toUpdate[toUpdateInterviewIdx].questions = questions;
        videoInterviews.toUpdate[toUpdateInterviewIdx].emailContent = emailContent;
        updateVideoInterviews('toUpdate', [...videoInterviews.toUpdate]);
      }
      // si no agregalo
      else {
        const tmpUpdatedVideoInterview = { ...videoInterviews.currentEvaluations[currentVideoInterviewIdx] };
        tmpUpdatedVideoInterview.questions = questions;
        tmpUpdatedVideoInterview.emailContent = emailContent;
        updateVideoInterviews('toUpdate', [...videoInterviews.toUpdate, tmpUpdatedVideoInterview]);
      }
      updateVideoInterviews('currentEvaluations', [...videoInterviews.currentEvaluations])

    }
    // Creando
    else {
      // Reemplzamos todo, de nuevo se asume que solo hay 1 ve por proceso
      const tmpVideoInt = { stage: currentStage, emailContent: emailContent, questions: [...questions] };
      const newCurrentEvaluations = [tmpVideoInt]
      updateVideoInterviews('currentEvaluations', newCurrentEvaluations)
      // Si no hay evaluaciones previas en to add significa que se agrega por 1ra vez,
      // si se encuentra on obj en to add simplemente lo reemplazamos
      updateVideoInterviews('toAdd', newCurrentEvaluations)
    }
    setIsVisible(false);
  };

  const onDelete = () => {
    const currentVideoInterviewIdx = videoInterviews.currentEvaluations.findIndex(obj => obj.stage === currentStage);
    const toAddInterviewIdx = videoInterviews.toAdd.findIndex(obj => obj.stage === currentStage);
    const toUpdateInterviewIdx = videoInterviews.toUpdate.findIndex(obj => obj.stage === currentStage);

    // Agregar al toRemove solo siel proceso ya tenia una vint creada, si existe el objeto en toAdd significa que es una nueva vint
    if (toAddInterviewIdx == -1) {
      updateVideoInterviews('toRemove', [{ ...videoInterviews.currentEvaluations[currentVideoInterviewIdx] }]);
    };

    videoInterviews.currentEvaluations.splice(currentVideoInterviewIdx, 1);
    updateVideoInterviews('currentEvaluations', [...videoInterviews.currentEvaluations])

    // Intentamos remover de toAdd y toUpdate, si no las encuentra da lo mismo
    toAddInterviewIdx != -1 && videoInterviews.toAdd.splice(toAddInterviewIdx, 1);
    updateVideoInterviews('toAdd', [...videoInterviews.toAdd])
    toUpdateInterviewIdx != -1 && videoInterviews.toUpdate.splice(toUpdateInterviewIdx, 1);
    updateVideoInterviews('toAdd', [...videoInterviews.toUpdate])
  };

  const getButtons = () => {
    if (videoInterviews.currentEvaluations.findIndex(obj => obj.stage !== currentStage) !== -1) {
      return ([
        <Button key={'add'} className='evaluation-added-button'>
          Evaluación agregada en otra etapa
        </Button>
      ])
    }
    // Si hay VE en otra etapa no dejamos agregar mas VEs
    else {
      return ([
        <Button key={'add'} className='evaluation-add-button' onClick={() => setIsVisible(true)}>
          {
            questions.length > 0 ?
              <>
                <Icon type='check' />
                <span>Editar</span>
              </>
              :
              <>
                <Icon type='plus' />
                <span>Configurar y agregar</span>
              </>
          }
        </Button>,

        questions.length && !hasCandidates ?
          <Button key={'delete'} className='evaluation-delete-button' onClick={onDelete}>
            Eliminar
          </Button> : null
      ])
    }
  };

  return (
    <>
      <EvaluationCard
        logo='https://genoma-assets.s3.us-east-2.amazonaws.com/vint-icon-activity.svg'
        evaluationTypeImg={['https://genoma-assets.s3.us-east-2.amazonaws.com/puzzle.svg']}
        title='Video entrevista'
        description='Evalúa las respuestas de los candidatos en relación a preguntas específicas a través de un formato de video.'
        viewMoreContent={vintQuestions}
        additionalInformation={[
          { value: 'Todo tipo de cargos', icon: 'user' },
          { value: 'ES, EN, PT', icon: 'flag' },
        ]}
        buttons={getButtons}
      />
      <Modal
        title={<div style={{ fontSize: 18, fontWeight: 700 }}>Configurar video entrevista</div>}
        visible={isVisible}
        maskClosable={false}
        onOk={() => setIsVisible(false)}
        onCancel={() => setIsVisible(false)}
        cancelText={'Cancelar'}
        width={1000}
        forceRender={true}
        destroyOnClose={true}
        footer={[
          <Button key='cancel' onClick={() => setIsVisible(false)}>Cancelar</Button>,
          <Button 
            key='ok' 
            type='primary' 
            onClick={onSave}
            // Desabilitar si quiero crear entrevista nueva pero proceso tiene candidatos y la etapa no es nueva
            //disabled={videoInterviews.currentEvaluations.length == 0 && (hasCandidates && !stages.find(obj => obj.id === currentStage)?.new)}
          >
            {videoInterviews.currentEvaluations.length > 0 ? 'Editar Video Entrevista' : 'Crear Video Entrevista'}
          </Button>,
        ]}
        className='evaluation-modal'
      >
        <VideoInterviewConfig
          questions={questions}
          setQuestions={setQuestions}
          emailContent={emailContent}
          setEmailContent={setEmailContent}
          edit={videoInterviews.currentEvaluations.length > 0}
          videoInterviewId={videoInterviews.currentEvaluations.length ? videoInterviews.currentEvaluations[0].id : undefined}
          newStage={stages.find(obj => obj.id === currentStage)?.new}
        />
      </Modal>
    </>
  );
};

export default VideoInterview;