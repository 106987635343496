import React from 'react';
import PropTypes from "prop-types";

import {
  Avatar, Card, Col, Icon, Row,
} from 'antd';

import { businessPermissionName } from '../../Business/Plugins/utils';

const { Meta } = Card;

const Options = (props) => {
  return (
    <Row className="options" gutter={[24, 24]}>
      <Col xs={24} md={12} lg={8} xl={6}>
        <Card>
          <Row type="flex" align="middle">
            <Col span={22}>
              <Meta
                avatar={<Avatar size={68} icon="mail" />}
                title="Correo electrónico"
                description={props.email}
              />
            </Col>
          </Row>
        </Card>
      </Col>
      <Col xs={24} md={12} lg={8} xl={6}>
        <Card>
          <Row type="flex" align="middle">
            <Col span={22}>
              <Meta
                avatar={<Avatar size={68} icon="user" />}
                title="Nombre"
                description={props.name}
              />
            </Col>
            <Col style={{ height: "100%" }} span={2}>
              <Icon 
                onClick={props.selectChangeName}
                style={{ cursor: "pointer" }} 
                type="right" 
              />
            </Col>
          </Row>
        </Card>
      </Col>
      <Col xs={24} md={12} lg={8} xl={6}>
        <Card>
          <Row type="flex" align="middle">
            <Col span={22}>
              <Meta
                avatar={<Avatar size={68} icon="key" />}
                title="Contraseña"
                description={"••••••••"}
              />
            </Col>
            <Col style={{ height: "100%" }} span={2}>
              <Icon 
                onClick={props.selectChangePassword}
                style={{ cursor: "pointer" }} 
                type="right" 
              />
            </Col>
          </Row>
        </Card>
      </Col>
      <Col xs={24} md={12} lg={8} xl={6}>
        <Card>
          <Row type="flex" align="middle">
            <Col span={24}>
              <Meta
                avatar={<Avatar size={68} icon="unlock" />}
                title="Permisos"
                description={businessPermissionName(props.businessPermissions)}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  )
};

Options.propTypes = {
  businessPermissions: PropTypes.shape({
    business_admin: PropTypes.bool.isRequired,
    can_manage: PropTypes.bool.isRequired,
    can_invite: PropTypes.bool,
    can_create_cell: PropTypes.bool,
    can_view_all_jobapps: PropTypes.bool,
  }),
  email: PropTypes.string,
  name: PropTypes.string,
  selectChangeName: PropTypes.func.isRequired,
  selectChangePassword: PropTypes.func.isRequired,
};

export default Options;
