import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import queryString from 'query-string';
import createHistory from 'history/createBrowserHistory';

import * as actionCreators from '../../../actions/data';
import * as actionVideos from '../../../actions/videos';
import * as actionsJobApp from '../../../actions/jobapp';

import useBoolean from '../../../hooks/useBoolean';
import NewCandidateModal from '../../NewCandidateModal';
import MailExportInput from '../Plugins/ExportMailModal';
// import AddCollabModal from './components/AddCollabModal';
// import CollabsAndButtons from './components/CollabsAndButtons';
import CustomExportModal from './components/CustomExportModal';
import DetailsModal from './components/DetailsModal';
import ModalTour from './components/ModalTour';
import StagesComponents from './components/StagesComponents';
import Table from './components/Table';
import { clearEmptyKeys } from './helpers';

import i18n from '../../../i18n';

import TriggerComponentModal from '../Triggers';
import {
  Badge,
  Breadcrumb,
  Button,
  Col,
  ConfigProvider,
  Dropdown,
  Icon,
  Menu,
  message,
  Modal,
  Row,
  Select,
  Tabs,
  Tooltip,
  Typography,
} from 'antd';
import esES from 'antd/lib/locale/es_ES'; // import the Spanish locale
import './CandidateListV3.scss';

const { Item } = Breadcrumb;
const { Paragraph } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const SHARE_URL = process.env.REACT_APP_SHARE_URL;

const CandidateListV3 = (props) => {
  // states
  const [allCandidates, setAllCandidates] = useState({});
  // Triggers
  const [triggers, setTriggers] = useState([]);
  const [showTriggerModal, handleTriggerModal] = useBoolean(false);
  const [bulkPerStageConfig, setBulkPerStageConfig] = useState({
    allSelected: false,
    stage: null,
  });

  const [stageActivitiesHeaders, setStageActivitiesHeaders] = useState();
  const [businessCustomFields, setBusinessCustomFields] = useState({});
  const [candidates, setCandidates] = useState({});
  const [candidateId, setCandidateId] = useState(null);
  const [candidatesState, setCandidatesState] = useState('ACTIV');
  const [candidatesToTable, setCandidatesToTable] = useState([]);
  // const [collabModal, handleCollabModal] = useBoolean(false);
  // fetch countries here to do it only once
  const [countries, setCountries] = useState(); 
  const [cvFilters, setCvFilters] = useState({
    excSkills: [],
    desSkills: [],
  });
  const [dataOk, setDataOk] = useState(false);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [discardReasons, setDiscardReasons] = useState([])
  const [docs, setDocs] = useState(null);
  const [jobAppActive, setJobAppActive] = useState([]);
  const [jobApplication, setJobApplication] = useState({});
  const [jobAppStatus, setJobAppStatus] = useState({});
  const [jobStages, setJobStages] = useState(null);
  const [koFilters, setKoFilters] = useState({});
  const [orderBy, setOrderBy] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState('25');
  const [queryColumns, setQueryColumns] = useState([]);
  const [queryFilters, setQueryFilters] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // this state rerenders the table when not needed but is the only way to open things in tour tutorial
  const [showDrawer, handleDrawer] = useBoolean(false);
  const [showModalTour, handleModalTour] = useBoolean(
    () => JSON.parse(localStorage.getItem('showModalTour')) ?? true
  );
  const [stageActiveCandidatesCount, setStageActiveCandidatesCount] = useState(null);
  const [stageDiscardedCandidatesCount, setStageDiscardedCandidatesCount] = useState(null);
  const [stagesOnFocus, setStagesOnFocus] = useState(null);
  const [tabKey, setTabKey] = useState('1');
  const [totalCandidatesToTable, setTotalCandidatesToTable] = useState(null);
  const [userId, setUserId] = useState(null);
  const [showCustomExportModal, setShowCustomExportModal] = useState(false);

  const isDocView = tabKey == '4';

  ///////////////////////////////////////////////

  // functions
  const changeFilters = (key, value) => {
    setQueryFilters((filters) => ({
      ...filters,
      [key]: value,
    }));
  };

  const fetchCountries = () => {
    props.actions.fetchAndReturn(props.token, '/api/v1/constants/countries/', 'GET')
      .then(({ body: countries }) => setCountries(countries))
      .catch((error) => Sentry.captureException(error));
  };

  const onSelectRowChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const getSingleCandidates = async () => {
    const token = props.token;
    const newCandidates = {};
    const promiseArray = [];
    jobApplication.stages &&
      jobApplication.stages.stages.forEach((stage) => {
        const qsWith = 'with=activities,incompleted_games,vint_eval,ko';
        const url = `/api/v1/gamesandtests/candidatelist/${jobAppId}/?format=json&serializer=table&pagination=true&status=${stage.code}&page_size=1&${qsWith}`;

        const candidatesDataPromise = props.actions
          .fetchAndReturn(token, url, 'GET')
          .then((response) => {
            newCandidates[stage.code] = response.body;
          });
        promiseArray.push(candidatesDataPromise);
      });

    jobApplication.stages &&
      Promise.all(promiseArray).then(() => {
        setCandidates(newCandidates);
      });
  };

  const getStageCandidates = async (nextStage) => {
    // We don´t want extra requests
    if (stageActivitiesHeaders === undefined) return;
    setDataOk(false);

    let qsWith = `with=incompleted_games,activities,${queryColumns.join(
      ','
    )}`;

    const currentActivities = stageActivitiesHeaders.filter(obj => obj.stage === nextStage);
    if (currentActivities.length > 0) {
      qsWith = qsWith.concat(`,progresses`);
    }
    const token = props.token;

    let url = `/api/v1/gamesandtests/candidatelist/${props.match.params['jobappid']}/?format=json&serializer=table&pagination=true&page=${page}&page_size=${pageSize}&${qsWith}`;
    let activeUrl = `/api/v1/gamesandtests/candidatelist/${jobAppId}/?format=json&serializer=table&pagination=true&status=${nextStage}&state=ACTIV&page_size=1&${qsWith}`;
    let discardUrl = `/api/v1/gamesandtests/candidatelist/${jobAppId}/?format=json&serializer=table&pagination=true&status=${nextStage}&state=DISCAR&page_size=1&${qsWith}`;
    // filter out null values from queryFilters
    const filters = Object.entries(clearEmptyKeys(queryFilters));

    // add filters to url
    if (filters.length > 0) {
      url += `&${filters.map(([key, value]) => `${key}=${value}`).join('&')}`;
    }

    switch (tabKey) {
      //stages tab needs status and state query params
      case '1':
        // Old logic stage "Descartado", shows all candidates ignoring the status ("Activo", "Descartado")
        if (nextStage === 'OTHER') {
          url += `&status=${nextStage}`;
        } else {
          url += `&status=${nextStage}&state=${candidatesState}`;
        }
        break;
      // candidates tab needs state query param
      case '2':
        url += `&state=${candidatesState}`;
        break;
      // documents tab needs no query params
      case '4':
        break;
    }

    if (tabKey !== '4' && orderBy) {
      url += `&ordering=${orderBy.order}${orderBy.key}`;
    }

    if (queryColumns.includes('ko')) {
      const filters = Object.entries(clearEmptyKeys(koFilters));
      url += `&${filters
        .map(([key, value]) => `ko_${key}=${value}`)
        .join('&')}`;
    }

    const { body } = await props.actions.fetchAndReturn(token, url, 'get');
    const { body: activeBody } = await props.actions.fetchAndReturn(
      token,
      activeUrl,
      'get'
    );
    const { body: discardBody } = await props.actions.fetchAndReturn(
      token,
      discardUrl,
      'get'
    );
    setTotalCandidatesToTable(body.count);
    setAllCandidates(body.results);
    setDataOk(true);
    setStageActiveCandidatesCount(activeBody.count);
    setStageDiscardedCandidatesCount(discardBody.count);
  };

  const setStages = async () => {
    const token = props.token;
    const url =
      '/api/v1/gamesandtests/jobapplications/' +
      props.match.params['jobappid'] +
      '/?format=json';
    const response = await props.actions.fetchAndReturn(token, url, 'get');
    setJobApplication({...response.body});
    setTriggers(response.body.triggers.map((trigger) => ({
      ...trigger,
      when: trigger.when_is_triggered,
      primaryActions: trigger.actions_config.filter((action) => action.priority === 1),
      secondaryActions: trigger.actions_config.filter((action) => action.priority === 2),
    }
    )));
    setJobAppStatus({ processStatus: response.body.status });
    setJobStages(response.body.stages.stages);
  };

  const buildActivitiesHeaders = (data) => {
    const activitiesHeaders = [];
    const baseColumn = {
      name: '',
      activitiesId: [],
      stage: '',
    };
    const genomeCategory = jobApplication.genome_detail.category;
    const gamesActivities = data.filter(obj => !['CUTE', 'FORM', 'KO', 'VINT', 'TERA'].includes(obj.code));

    if (['BASAL', 'COGNI', 'EXPERT'].includes(genomeCategory)) {
      const genomeCol = structuredClone(baseColumn);
      genomeCol.name = 'Genoma';
      genomeCol.code = 'GENOME';
      // TODO JSONFIELD: reemplazar "include required" si se corre la migracion JSONFIELD
      const findGenomeGames = gamesActivities.filter(obj => obj.configuration.includes('required'));
      // Nueva creacion
      if (findGenomeGames.length > 0) {
        genomeCol.activitiesId = findGenomeGames.map(obj => obj.id);
        genomeCol.stage = findGenomeGames[0].stage;

        const otherGames = gamesActivities.filter(obj => !genomeCol.activitiesId.includes(obj.id));
        otherGames.forEach(obj => {
          const tmpCol = structuredClone(baseColumn);
          tmpCol.stage = obj.stage;
          tmpCol.name = obj.activity;
          tmpCol.activitiesId = [obj.id];
          tmpCol.code = obj.code
          activitiesHeaders.push(tmpCol);
        });
      }
      // Creacion vieja, se asume que todos los juegos son parte del basal
      else {
        genomeCol.activitiesId = gamesActivities.map(obj => obj.id);
        genomeCol.stage = 'ACTIV';
      }
      activitiesHeaders.push(genomeCol);
    }
    // No es basal todos los juegos vienen sueltos
    else {
      gamesActivities.forEach(obj => {
        const tmpCol = structuredClone(baseColumn);
        tmpCol.stage = obj?.stage &&  obj?.stage !== '2' ? obj.stage : 'ACTIV';
        tmpCol.name = obj.activity;
        tmpCol.activitiesId = [obj.id];
        activitiesHeaders.push(tmpCol);
      });
    }
    // Otras evaluaciones, cutes vint, etc

    const otherActivities = data.filter(obj => ['CUTE', 'FORM', 'KO', 'VINT', 'TERA'].includes(obj.code));
    otherActivities.forEach(obj => {
      const tmpCol = structuredClone(baseColumn);
      tmpCol.stage = obj.stage;
      tmpCol.name = obj.translation.es || obj.activity;
      tmpCol.activitiesId = [obj.id];
      tmpCol.code = obj.code
      activitiesHeaders.push(tmpCol);
    });

    return activitiesHeaders;
  };

  const fetchJobAppActivities = async () => {
    const token = props.token;
    const assActUrl = `/api/v1/gamesandtests/assignedactivity/${jobApplication.id}/`;
    const { body } = await props.actions.fetchAndReturn(token, assActUrl);
    const stageActivities = buildActivitiesHeaders(body);
    setStageActivitiesHeaders(stageActivities)
    setJobAppActive(body);

    setDocs(
      body.filter((assAct) => assAct.code === 'DOCR'))
  };

  const fetchBusinessCustomFields = async () => {
    const url = `/api/v1/gamesandtests/business/${props.business.id}/customfields/?field_type=JOBAPP&active=True`;
    const { body } = await props.actions.fetchAndReturn(props.token, url);
    const customFields = {};
    body.results.forEach((customField) => {
      customFields[customField.name_key] = customField;
      delete customFields[customField.name_key].name_key;
    });
    setBusinessCustomFields(customFields);
  };

  const onChangeSelect = (value) => {
    const { token } = props;
    const url = `gamesandtests/jobapplications/${props.match.params['jobappid']}/`;
    const status = value;
    const submitData = JSON.stringify({
      status,
    });
    props.actions.updateData(token, url, submitData, 'PATCH');
    setJobAppStatus({ processStatus: status });
  };

  const handleEdit = () => {
    props.history.push(`/processes/${props.match.params['jobappid']}/edit`);
  };
  const handleEditTriggers = () => {
    props.history.push(`/processes/${props.match.params['jobappid']}/edit#step-1__triggers`);
  }

  const showLink = () => {
    let url = '';
    const SHARE_URL = process.env.REACT_APP_SHARE_URL.includes(
      'app.genoma.work'
    )
      ? 'https://jobs.genoma.work'
      : process.env.REACT_APP_SHARE_URL;
    if (props.business.name) {
      url = `${SHARE_URL}/${props.business.slug}/${props.match.params['jobappid']}`;
    } else {
      url = `${SHARE_URL}/startprocess/${props.match.params['jobappid']}`;
    }
    return encodeURI(url);
  };

  const handleShare = (event) => {
    props.dispatch(push(`/share/${jobAppId}`));
  };

  const parseId = () => {
    const parsed = queryString.parse(window.location.search);

    setCandidateId(parsed.id);
  };

  const openCandidateModal = (candidateId, userId) => {
    createHistory().push(`${jobAppId}?id=${candidateId}`);
    setCandidateId(candidateId);
    setUserId(userId);
  };

  const closeCandidateModal = () => {
    setCandidateId(null);
    createHistory().push(`${jobAppId}`);
  };

  const handleCandidatesExport = (type) => (email) => {
    const token = props.token;
    let url = '';
    switch (type) {
      case 'candidateData':
        url = `gamesandtests/candidatelist/export/${jobAppId}/?email=${email}`;
        break;
      case 'candidatePostulationRegistry':
        url = `stats/postulationregistry/export/${jobAppId}/`;
        break;
    }
    const today = new Date();
    const parsedDate = `${today.getDate()}-${today.getMonth() + 1
      }-${today.getFullYear()}`;
    const filename = `${jobApplication.job_application} ${parsedDate}.xls`;
    props.actions.fetchFile(token, url, '', 'get', filename);
  };

  const showMailExportModal = (type) => {
    const modal = {};
    modal.modal = Modal.info({
      title:
        type === 'candidatePostulationRegistry'
          ? 'Exportar Historial de Candidatos'
          : 'Exportar Proceso',
      content: (
        <div>
          {type === 'candidatePostulationRegistry' ? (
            <Paragraph>
              Al ingresar el correo electrónico y confirmar esta acción se
              descargaran los datos de los candidatos que han participado en
              este proceso, en formato excel.<br></br>
              Este informe entrega un resumen de la fecha de los hitos que va
              cumpliendo un candidato dentro del proceso. Es decir en que fecha
              comenzó este y fue avanzando de etapas.
            </Paragraph>
          ) : (
            <Paragraph>
              Esta funcionalidad se encuentra sin mantenimiento y dejará de existir en 2024.<br></br>
              Puede haber errores en la generación de este reporte.
              En reemplazo se puede utilizar el botón "Exportar proceso", el cual se encuentra
              optimizado para una descarga más veloz, con más información y en formato XLSX.
              <br></br>
              Al ingresar el correo electrónico y confirmar esta acción se
              descargaran todos datos del proceso asociado, en formato excel.{' '}
              <br></br>
              Mediante este informe podrás obtener los datos personales de los
              candidatos que han postulado al proceso y en el estado actual que
              se encuentran dentro de este (ya sea el puntaje que han obtenido
              en las diversas actividades, la etapa o estando en el que se
              encuentran dentro del proceso y las notas que le han dejado al
              candidato)
            </Paragraph>
          )}
          <p>Ingrese correo para enviar el reporte.</p>
          <MailExportInput
            handleCandidatesExport={handleCandidatesExport(type)}
            modal={modal}
          />
        </div>
      ),
      cancelButtonProps: { style: { display: 'none' } },
      okButtonProps: { style: { display: 'none' } },
    });
  };

  const onTabChange = (key) => {
    setTabKey(key);
  };

  const filterCvCandidates = (nextSkills, candidates = []) => {
    setCvFilters(nextSkills);
    setCandidatesToTable(candidates);
  };

  const changeName = async (name) => {
    try {
      const url = `/api/v1/gamesandtests/jobapplications/${jobAppId}/`;

      const response = await props.actions.fetchAndReturn(
        props.token,
        url,
        'PATCH',
        {
          job_application: name,
        }
      );

      if (response.status === 200) {
        setJobApplication(response.body);
        message.success('Nombre del proceso cambiado con éxito.');
      }
      else{
        message.error('Hubo un error al cambiar el nombre del proceso.');
        return;
      }

      return;
    } catch (e) {
      message.error('Hubo un error al cambiar el nombre del proceso.');
    }
  };
  ////////////////////////////////////////////

  // hooks
  const jobAppId = useMemo(() => {
    return props.match.params['jobappid'];
  }, [props.match]);

  useEffect(() => {
    setStages();
    parseId();
    if (countries === undefined) fetchCountries();
  }, []);

  useEffect(() => {
    if (jobApplication.stages) {
      getSingleCandidates();
      fetchJobAppActivities();
    }
  }, [jobApplication.stages]);

  useEffect(() => {
    if (jobApplication.id) {
      fetchJobAppActivities();
    }
    fetchBusinessCustomFields();
  }, [jobApplication.id]);

  useEffect(() => {
    if (bulkPerStageConfig.allSelected) {
      const getCandidates = async () => {
        try {
          let url = `/api/v1/gamesandtests/candidatelist/${props.match.params['jobappid']
            }/?format=json&serializer=simple&pagination=true&page_size=${totalCandidatesToTable ?? 50000
            }&status=${stagesOnFocus}&state=${candidatesState}`;

          // filter out null values from queryFilters
          const filters = Object.entries(clearEmptyKeys(queryFilters));

          // add filters to url
          if (filters.length > 0) {
            url += `&${filters
              .map(([key, value]) => `${key}=${value}`)
              .join('&')}`;
          }

          const response = await props.actions.fetchAndReturn(
            props.token,
            url,
            'GET'
          );

          setTotalCandidatesToTable(response.body.count);
          setBulkPerStageConfig((config) => ({
            ...config,
            data: response.body.results,
          }));
        } catch (e) {
          console.error(e);
        }
      };

      getCandidates();
    }
  }, [bulkPerStageConfig.allSelected, stagesOnFocus, candidatesState, queryFilters, koFilters]);

  useEffect(() => {
    getStageCandidates(stagesOnFocus);
    setCandidatesToTable(allCandidates);
  }, [
    stagesOnFocus,
    page,
    candidatesState,
    candidates,
    queryColumns,
    queryFilters,
    tabKey,
    pageSize,
    orderBy,
    koFilters,
  ]);

  // when change tabs reset cv filters
  useEffect(() => {
    setCvFilters({
      excSkills: [],
      desSkills: [],
    });
  }, [tabKey, stagesOnFocus]);

  useEffect(() => {
    setCandidatesToTable(allCandidates);
  }, [allCandidates]);

  useEffect(() => {
    // every time the candidatesToTable changes, the selected rows are reset
    // cleaneast approach to reset rows
    setSelectedRowKeys([]);
  }, [candidatesToTable]);

  useEffect(() => {
    const { token, actions } = props;

    actions
      .fetchData(
        token,
        `gamesandtests/jobapplication/${props.match.params['jobappid']}/triggers/`,
        '',
        'GET'
      )
      .then((res) => {
        setDocs(
          res.triggers.find((trigger) => trigger.trigger_code === 'DOCS')
        );
      });

    actions.fetchAndReturn(
      token,
      `/api/v1/gamesandtests/business/${props.business.id}/discardreasons/`,
      'GET',
      '',
    )
    .then((res) => {
      setDiscardReasons(res.body.results)
    });
  }, []);
  //////////////////////////////////////////////
  const handleAddFiles = () => {
    props.history.push(`/processes/${props.match.params['jobappid']}/edit/#step-1__filesRequests__${stagesOnFocus}`);
  }

  // more options dropdown menu
  const menu = (
    <Menu>
      {
        (props.businessPermissions.business_admin || props.businessPermissions.role?.can_manage_jobapplications) &&
        <Menu.Item key="1" onClick={handleEdit}>
          <Icon type="edit" />
          Editar proceso
        </Menu.Item>
      }
      {
        (props.businessPermissions.business_admin || props.businessPermissions.role?.can_share_jobapplications_with_candidates) &&
        <Menu.Item key="3" onClick={handleShare}>
          <Icon type="share-alt" />
          Compartir proceso para postular
        </Menu.Item>
      }
      {/* <Menu.Item key="4">
        <Icon type="eye" />
        Ver proceso
      </Menu.Item> */}
      <Menu.Item key={5} onClick={() => setDetailsVisible(true)}>
        <Icon type="info-circle" />
        Ver detalles
      </Menu.Item>
      <Menu.SubMenu
        title={
          <>
            <Icon type="download" />
            Exportar
          </>
        }
      >
        <Menu.Item
          onClick={() => setShowCustomExportModal(true)}
        >
          <Icon type='file-excel' style={{ color: 'green' }} /> Exportar proceso
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            showMailExportModal('candidatePostulationRegistry');
          }}
        >
          <Icon type="clock-circle" style={{ color: 'green' }} />
          Exportar historial de candidatos
        </Menu.Item>
        <Menu.Item onClick={() => showMailExportModal('candidateData')}>
          <Icon type="exclamation-circle" style={{ color: 'red' }} /> Exportar proceso (versión antigua)
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );

  const updateTable = (userId, currentStage, nextStage, candidateData=null) => {
    setCandidates((prevCandidates) => {
      const updatedCandidates = { ...prevCandidates };
      const idx = updatedCandidates[currentStage].results?.findIndex(
        (candidate) => candidate.id === userId
      );
      if (!idx) return ({ ...updatedCandidates });

      let updatedCandidate = { ...updatedCandidates[currentStage].results[idx] };
      // Update candidate data
      if (candidateData) {
        updatedCandidate = { ...updatedCandidate, ...candidateData };
      }
      if (currentStage !== nextStage) {
        updatedCandidate.status = nextStage;
        // Remove from old stage
        updatedCandidates[currentStage].results.splice(idx, 1);
        updatedCandidates[currentStage].count -= 1;
        // Add to new stage, not sure if necesary because stage change triggers a refetch (count is still necesary)
        updatedCandidates[nextStage].results.push(updatedCandidate);
        updatedCandidates[nextStage].count += 1;
      }
      return ({ ...updatedCandidates });
    });
  };

  return (
    <ConfigProvider locale={esES}>
      {/* 
          USING CONFIG PROVIDER WAS THE ONLY WAY I FOUND TO CHANGE THE TEXT OF THE EDITABLE PARAGRAPH
          I DIDNT KNOW THIS FEATURE EXISTED UNTIL NOW 
          I tried to use the locale prop in Paragraph but it didn't work
      */}
      <div className="candidate-list-container">
        <Row type="flex" justify="space-between">
          <Breadcrumb>
            <Item
              style={{ cursor: 'pointer' }}
              onClick={() => props.history.push('/processes/')}
            >
              Procesos
            </Item>
            <Item>Tablero de candidatos</Item>
          </Breadcrumb>
          <Row type="flex" align="middle" style={{ gap: '0.5rem' }}>
            {jobApplication.is_confidential ? (
              <Tooltip title="Proceso confidencial">
                <div className="process-is-confidential">
                  <Icon type="lock" />
                  <span>Confidencial</span>
                </div>
              </Tooltip>
            ) : null}
            <Select
              value={jobAppStatus?.processStatus}
              className="candidate-list-container__select-options"
              onChange={onChangeSelect}
            >
              <Option value="ACTIV">
                <Badge status="success" text="Proceso abierto" />
              </Option>
              <Option value="INACT">
                <Badge status="error" text="Proceso cerrado" />
              </Option>
              <Option value="OTHER">
                <Badge status="default" text="Proceso en borrador" />
              </Option>
            </Select>
            <Button onClick={handleAddFiles}>
              <Icon type="paper-clip" />
              Solicitar documentos
            </Button>
            <Dropdown overlay={menu}>
              <Button icon="ellipsis"></Button>
            </Dropdown>
            <Button type='secondary' onClick={handleTriggerModal.on}>
              <Icon type="thunderbolt"/>
            </Button>
          </Row>
        </Row>
        <Row
          className="candidate-list-container__process-info"
          type="flex"
          justify="space-between"
        >
          <Col span={14}>
            <Paragraph
              editable={{ onChange: changeName }}
              strong
              style={{ margin: 0 }}
              locale={{ edit: 'Editar' }}
            >
              {jobApplication.job_application}
            </Paragraph>
          </Col>
          <Button type="primary" onClick={handleModalTour.on}>
            Tutorial
          </Button>
          {/* 
          TO DO
          <CollabsAndButtons
            handleCollabModal={handleCollabModal}
            docs={docs}
            jobAppId={jobAppId}
          /> */}
        </Row>
        <Tabs defaultActiveKey="1" onChange={onTabChange}>
          <TabPane tab="Etapas" key="1">
            <Row type="flex" style={{ flexDirection: 'column', gap: '1rem' }}>
              <StagesComponents
                setPage={setPage}
                jobStages={jobStages}
                candidates={candidates}
                setStagesOnFocus={setStagesOnFocus}
                setSelectedRowKeys={setSelectedRowKeys}
              />
            </Row>
          </TabPane>
          <TabPane tab="Candidatos" key="2"></TabPane>
          {/* <TabPane tab='Actividad' key='3'>
          Actividad queda para cuando esten los logs de actividad.
        </TabPane> */}
          <TabPane tab="Documentos" key="4"></TabPane>
        </Tabs>
        <Table
          candidates={candidates}
          countries={countries}
          stagesOnFocus={stagesOnFocus}
          candidatesToTable={candidatesToTable}
          jobAppActive={jobAppActive}
          openCandidateModal={openCandidateModal}
          candidatesState={candidatesState}
          setCandidatesState={setCandidatesState}
          setCandidatesToTable={setCandidatesToTable}
          allCandidates={allCandidates}
          dataOk={dataOk}
          page={page}
          columnsType={isDocView ? 'documentsView' : 'default'}
          setPage={setPage}
          jobAppId={jobAppId}
          jobStages={jobStages}
          setCandidates={setCandidates}
          selectedRowKeys={selectedRowKeys}
          onSelectRowChange={onSelectRowChange}
          jobApplication={jobApplication}
          documentsView={isDocView}
          docs={docs}
          discardReasons={discardReasons}
          addOns={props.addOns}
          queryColumns={queryColumns}
          setQueryColumns={setQueryColumns}
          queryFilters={queryFilters}
          changeFilters={changeFilters}
          setQueryFilters={setQueryFilters}
          cvFilters={cvFilters}
          filterCvCandidates={filterCvCandidates}
          setCvFilters={setCvFilters}
          extraColumns={tabKey == '2' ? ['status'] : []}
          totalCandidatesToTable={totalCandidatesToTable}
          pageSize={pageSize}
          setPageSize={setPageSize}
          showDrawer={showDrawer}
          handleDrawer={handleDrawer}
          setOrderBy={setOrderBy}
          koFilters={koFilters}
          setKoFilters={setKoFilters}
          getStageCandidates={getStageCandidates}
          bulkPerStageConfig={bulkPerStageConfig}
          setBulkPerStageConfig={setBulkPerStageConfig}
          stageActiveCandidatesCount={stageActiveCandidatesCount}
          stageDiscardedCandidatesCount={stageDiscardedCandidatesCount}
          stageActivitiesHeaders={stageActivitiesHeaders ?? [] }
        />

        {candidateId ? (
          <NewCandidateModal
            discardReasons={discardReasons}
            closeModal={() => closeCandidateModal()}
            candidateId={candidateId} // Candidate
            userId={userId} // PassiveCandidate || PersonalUser
            jobAppId={jobAppId}
            onChangePhase={(userId, currentStage, nextStage, candidateData=null) => {
              updateTable(userId, currentStage, nextStage, candidateData)
            }}
            lanes={{ [stagesOnFocus]: candidatesToTable }}
            updateCandidateTable={updateTable}
            mode="not-isolated"
            getStageCandidates={getStageCandidates}
            setCandidates={setCandidates}
            redirectAfterMerge={(candidateId, userId) => {
              openCandidateModal(candidateId, userId);
              getStageCandidates(stagesOnFocus);
            }}
          />
        ) : null}
        {/* <AddCollabModal
          visible={collabModal}
          closeModal={handleCollabModal.off}
        /> */}
        <ModalTour
          visible={showModalTour}
          handleModal={handleModalTour}
          actions={{
            handleDrawer,
            onTabChange,
          }}
        />

        <DetailsModal
          businessCustomFields={businessCustomFields}
          closureDate={jobApplication.closure_date}
          customFields={jobApplication.custom_fields}
          department={jobApplication.department}
          language={jobApplication.language}
          setVisible={setDetailsVisible}
          tags={jobApplication.tags}
          titleJobapp={jobApplication.job_application}
          visible={detailsVisible}
        />

        <CustomExportModal
          setVisible={setShowCustomExportModal}
          visible={showCustomExportModal}
          jobApplication={jobApplication}
          token={props.token}
          actions={props.actions}
        />
      </div>
      <TriggerComponentModal
        visible={showTriggerModal}
        setVisible={handleTriggerModal.off}
        currentTriggers={triggers}
        stages={jobStages}
        currentEvaluations={jobAppActive?.filter((a) => a.code === 'CUTE').map((cute) => JSON.parse(cute.configuration))}
        tags={jobApplication.tags_used}
        updateTriggers={null}
        triggersToRemove={null}
        mode='view'
        goToEdit={handleEditTriggers}
        />
    </ConfigProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    addOns: state.auth.addOns,
    data: state.data.data,
    lanes: state.data.lanes,
    simpleLanes: state.data.simpleLanes,
    isFetching: state.data.isFetching,
    businessOwner: state.auth.businessOwner,
    error: state.data.error,
    video: state.videos.data,
    jobApplication: state.jobapp.jobApplication,
    assignedActivities: state.jobapp.assignedActivities,
    activities: state.jobapp.activities.results,
    business: state.auth.currentBusiness,
    businessPermissions: state.auth.businessPermissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    videoActions: bindActionCreators(actionVideos, dispatch),
    jobAppActions: bindActionCreators(actionsJobApp, dispatch),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateListV3);
