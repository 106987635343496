import React from 'react';
// styles are stored in src/components/tour/styles.scss

import {
  Icon,
  Typography
} from 'antd';

export const tourModalTitle = (
  <p>
    Hemos renovado tu experiencia en Genomawork para acceder rápido a tus secciones de interés. <strong>Para conocerlas, puedes ingresar a “Mis Procesos”, “Mis Herramientas”, “Mis Analíticas” y “Mi Cuenta”</strong>
  </p>
)

export const tourModalContent = (
  <>
    <div className="tour-modal-check-list">
      <Icon type="check" className="check-icon" />
      <strong>Mis Procesos:</strong> Acceder de manera ágil a tus procesos de selección con información detallada.
    </div>
    <div className="tour-modal-check-list">
      <Icon type="check" className="check-icon" />
      <strong> Mis Herramientas:</strong> Ingresar rápidamente a todas las herramientas que dispones en Genomawork.
    </div>
    <div className="tour-modal-check-list">
      <Icon type="check" className="check-icon" />
      <strong> Mis Analíticas:</strong> Observar las analíticas generales y de tus procesos específicos.
    </div>
    <div className="tour-modal-check-list">
      <Icon type="check" className="check-icon" />
      <strong>Mi Cuenta:</strong> Configurar tu cuenta y marca empleadora.
    </div>

    <div style={{ marginTop: 20 }}>
      <Typography.Text strong>
        ¿Y eso es todo?
      </Typography.Text>
    </div>

    <div className="tour-modal-check-list">
      ¡No! Como tu opinión es lo más importante, <strong>estaremos muy atento a tus recomendaciones y feedback para seguir mejorando nuestra plataforma.</strong>
    </div>
    <div className="tour-modal-check-list">
      Puedes enviarnos todos tus comentarios y opiniones sobre esta nueva navegación a hola@genomawork.com
    </div>
  </>
)