import React from 'react';

import { Button } from 'antd';
import i18n from '../../../i18n'

const Footer = () => {
  const handleDialog = () => {
    // setPrivacyPolicyDialog(!privacyPolicyDialog)
    window.open('/privacy');
  };

  return (
    <div className="footer-login">
      <span>2023 Genomawork ®</span>
      <Button type="link" onClick={handleDialog}>
        • {i18n.t('landing__page__conditions')}
      </Button>
      <Button type="link" onClick={handleDialog}>
        • {i18n.t('landing__page__policy')}
      </Button>
    </div>
  );
};

export default Footer;
