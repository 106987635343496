import React from 'react'

import { Col, Divider, Row, Typography } from 'antd';

import ParseHtml from '../../components/parseHtml';

const SituationalPreview = ({ cute, modeModal }) => {
  const alts = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j']
  const { Paragraph, Text } = Typography;
  const { questions, scores_config } = cute;

  const getAlternativeValue = (fieldUid, optionUid) => {
    const answers = scores_config?.correct_answers;
    const value = answers[fieldUid]?.value[optionUid];
    if (value) return value;
    return 0;
  }

  return (
    <>
      {modeModal ? (
        <div>
          <Text strong>Descripción</Text>
          <Paragraph style={{ marginTop: 10 }}>{ParseHtml(cute?.description)}</Paragraph>
        </div>) : 
        <Row style={{ margin: '20px 0' }}>
          <Col>
            <Text strong>Instrucciones</Text>
            <Paragraph>{ParseHtml(questions?.data[0].fields[0].paragraphs[0])}</Paragraph>
          </Col>
        </Row>
        }
      
      <Row style={{ marginBottom: 20 }}>
        <Col>
          <Text strong>Situaciones</Text>
        </Col>
      </Row>
      {questions?.data.map((page) => {
        if (page.label === 'Instrucciones') return;
        return (
          <Row key={page._uid} gutter={[20, 20]}>
            <Col lg={8}>
              <Paragraph strong>Imagen</Paragraph>
              <img style={{ width: '100%' }} src={page.image.url} />
            </Col>
            <Col lg={16}>
              <Paragraph style={{ margin: 0 }} strong>{page.label}</Paragraph>
              <Paragraph style={{ margin: 0 }}>{page.description}</Paragraph>
              <Paragraph style={{ margin: 0 }} strong>¿Cuál crees que es la mejor y peor forma de abordar esta situación?</Paragraph>
              {page.fields[0].options.map((field, idx) => 
                <Row key={field._uid} style={{ margin: '10px 0px' }} gutter={[10, 10]}>
                  <Col span={1}>
                    {alts[idx]}.
                  </Col>
                  <Col md={23} lg={modeModal ? 23 : 19}>
                    {field.label}
                  </Col>
                  {!modeModal && 
                    <Col key="scores" md={24} lg={4}>
                      <p>{getAlternativeValue(page.fields[0]._uid, field._uid)} puntos</p>
                    </Col>
                  }               
                </Row>
              )}
            </Col>
            <Divider />
          </Row>
        )
      })}
    </>
  )
}

export default SituationalPreview;