import React, { useMemo } from 'react';

import "../../pdf-css.scss";
import { Col, Row, Typography } from 'antd';

const { Text } = Typography;

const QuestionsPDF = ({ candidates }) => {
  const questions = [];

  candidates.map((candidate) => {
    if (Object.values(candidate['ko_answers']).length > 0) {
      Object.values(candidate['ko_answers']).map((q) => {
        const questionNumber = Object.keys(candidate['ko_answers']).length;
        if (questions.length < questionNumber && typeof q === 'object') {
          questions.push(q.question);
        }
      });
    }
  });

  return (
    <Row className='question-section-pdf'>
      {candidates[0] ? (
        questions.length > 0 ? (
          questions.map((question, questionIndex) => (
            <Col span={24} className='question-section-pdf__question'>
              <Row type='flex' align='middle'>
                <Col span={6}>
                  <Col key={questionIndex}>
                    <Text strong>{question}</Text>
                  </Col>
                </Col>
                <Col span={18}>
                  <Row type='flex' justify='space-around'>
                    {candidates[0]
                      ? candidates.map((candidate, index) => (
                        Object.values(candidate['ko_answers']).length >
                          0 ? (
                          typeof Object.values(candidate['ko_answers'])[
                            questionIndex
                          ]?.answer !== 'object' ? (
                            <Col key={index} span={Math.floor(24 / (candidates.length))} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 5 }}>
                                <Text style={{fontSize: 12, wordBreak: 'break-all'}}>
                                  {
                                    Object.values(candidate['ko_answers'])[
                                      questionIndex
                                    ]?.answer
                                  }
                                </Text>
                            </Col>
                          ) : (
                            Object.keys(
                              Object.values(candidate['ko_answers'])[
                                questionIndex
                              ]?.answer
                            )
                              .filter(
                                (key) =>
                                  Object.values(candidate['ko_answers'])[
                                    questionIndex
                                  ]?.answer[key]
                              )
                              .map((key, index) => (
                                <Col key={index} span={Math.floor(24 / (candidates.length))} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 5 }}>
                                    <Text style={{fontSize: 12, wordBreak: 'break-all'}}>
                                      {
                                        Object.values(
                                          candidate['ko_answers']
                                        )[questionIndex].alternatives[key]
                                          .text
                                      }
                                    </Text>
                                </Col>
                              ))
                          )
                        ) : (
                          <Text style={{ marginTop: '20px' }}>
                            Sin respuestas
                          </Text>
                        )
                      ))
                      : null}
                  </Row>
                </Col>
              </Row>
            </Col>
          ))
        ) : (
          <Text strong disabled>Sin preguntas</Text>
        )
      ) : null}
    </Row>
  );
};

export default QuestionsPDF;
