
import React, { useState, useContext } from 'react'

import {
  Button, Form, Icon, Col, message, Row, Input, Select, Tag, Typography
} from 'antd';

import ConnectIntegrationModal from './IntegrationModal/ConnectIntegrationModal';
import DisconnectIntegrationModal from './IntegrationModal/DisconnectIntegrationModal';

import JobAppContext from '../../context/JobAppContext';

function Integrations({form}) {
  const { jobApplication, integrations, updateCustomFields, updateIntegrations, stages } = useContext(JobAppContext);

  const [state, setState] = useState({
    connectModalVisible: false,
    disconnectModalVisible: false,
    integrationToConnect: null,
    connectTitle: 'Conectar integración',
    customFieldsToEdit: {},
  });

  const setConnectModalVisible = (value, integration = null, title) => {
    setState(oldState => ({...oldState, integrationToConnect: integration }));
    setState(oldState => ({...oldState, connectModalVisible: value }));
    setState(oldState => ({...oldState, connectTitle: title }));
  }

  const setDisconnectModalVisible = (value, integration = null) => {
    setState(oldState => ({...oldState, integrationToConnect: integration }));
    setState(oldState => ({...oldState, disconnectModalVisible: value }));
  }

  const handleIntegrationsCustomFields = (name, value) => { 
    const customFields = state.customFieldsToEdit;
    customFields[name] = value;
    setState(oldState => ({...oldState, customFieldsToEdit : {...customFields} }));
  }

  const updateIntegrationData = () => { 
    let customFields = jobApplication.custom_fields
    customFields = {...customFields, ...state.customFieldsToEdit}
    updateCustomFields(customFields)
  };

  const connectIntegration = (integration) => {
    const newIntegrations = [...integrations.jobApplicationIntegrations];
    const data = {
      integration: integration,
      fields: integration.template.jobapplication_fields,
      stage: integration.template.default_stage ? integration.template.default_stage: null
    }
    newIntegrations.push(data)
    updateIntegrations('jobApplicationIntegrations', newIntegrations)
    message.success(`¡Integración con ${integration.template.name} realizada exitosamente!`);
    updateIntegrationData()
    updateIntegrations('update', true);
  };

  const disableConnection = (integration) => {
    const newIntegrations = [...integrations.jobApplicationIntegrations];
    var index = newIntegrations.map(obj => obj.integration.id).indexOf(integration.id);
    if (index !== -1) {
      newIntegrations.splice(index, 1);
      updateIntegrations('jobApplicationIntegrations', newIntegrations)
    }
    //this.setState({jobAppIntegrations : newIntegrations})
    updateIntegrations('update', true);
  };

  const findStage = (code) => {
    const idx = stages.findIndex(obj => obj.id === code);
    return stages[idx] ? stages[idx].name : ''
  }

  const checkToken = (token, integration) => {
    return integration.auth_token === token
  }

  const questionType = (q, error, index) => {
    const component = {
        'text': <Input key={index} index={index} placeholder={jobApplication.custom_fields[index]} onChange={(e) => handleIntegrationsCustomFields(index, e.target.value)}/>,
        // 'int': <Integer {...q} key={index} index={index} error={error} handleChangeV2={console.log('wena')} disabled={false} />,
        'singleselection': <Select key={index} {...q} index={index} error={error} defaultValue={findStage(jobApplication.custom_fields[index])} options={stages} onChange={(e) => handleIntegrationsCustomFields(index, e)}>
          {
            stages.map((elem) => {
              return <Select.Option key={elem.id} value={elem.id}>{elem.name}</Select.Option>
            })
            }
        </Select> ,
    
    };
    return <div key={index} id={index}>{component[q.type]}</div> || null
  }

  const { getFieldDecorator } = form;

  return (
    <Row type="flex" gutter={[24, 24]} style={{ flexDirection: 'row', alignItems: 'stretch', position: 'relative' }}>
      {integrations.businessIntegrations.map((integration, integrationKey) => (
        <Col key={integrationKey} lg={window.innerWidth <= 1280 ? 12 : 8}>
          <Row
            type='flex'
            justify='space-between'
            style={{
              overflow: 'auto',
              height: '100%',
              width: '15em',
              border: '1px solid #D9D9D9',
              position: 'relative',
              display: 'flex'
            }}
          >
            <Row type='flex' style={{ marginLeft: '1em', alignItems: 'baseline' }}>
              <Col span={12} align="left" style={{ bottom: '0.5em', marginTop: '1.5em' }}>
                <Row type='flex' justify='start' style={{ height: '100%' }}>
                  <img className="integration-image" src={integration.template.image} />
                </Row>
                <Row>
                  <Typography.Text style={{ fontSize: 13, marginTop: '0.25em' }} ellipsis strong>
                    {integration.template.name}
                  </Typography.Text>
                </Row>
              </Col>
              <Col span={10} justify='end'>
                {!integrations.jobApplicationIntegrations.map(obj => obj.integration.id).includes(integration.id) ? (
                  <Tag
                    className='disconnected-integration-tag'
                    style={{ bottom: '2em', right: '1em' }}
                  >
                    <Icon type='close-circle' />{' '}
                    No conectado
                  </Tag>
                ) : (
                  <Tag
                    className='connected-integration-tag'
                    style={{ bottom: '2em', left: '1em' }}
                  >
                    <Icon type='check-circle' />{' '}
                    Conectado
                  </Tag>
                )}
              </Col>
            </Row>
            <Row
              style={{
                minHeight: "5em",
                maxHeight: "6em",
                paddingLeft: "1em",
                paddingRight: "1em",
                marginTop: "0.5em",
              }}
              type="flex"
            >
              <Typography.Text className="integration-description">
                {integration.template.description}
              </Typography.Text>
            </Row>
            <Row>
              <Col span={24} style={{ marginLeft: "1em", marginRight: "1em" }}>
                <Button
                  style={{
                    border: '1px solid #F175A5',
                    color: '#F175A5',
                    backgroundColor: 'white',
                    fontSize: '13px',
                    borderRadius: '0.2em',
                    height: '1.8em',
                    width: '100%',
                    maxWidth: '14em',
                    marginBottom: '0.25em',
                  }}
                  onClick={() => (
                    !integrations.jobApplicationIntegrations.map(obj => obj.integration.id).includes(integration.id)
                      ? setConnectModalVisible(true, integration, 'Conectar integración')
                      : setDisconnectModalVisible(true, integration)
                  )}
                >
                  {!integrations.jobApplicationIntegrations.map(obj => obj.integration.id).includes(integration.id)
                    ? 'Conectar integración'
                    : 'Desactivar integración'}
                </Button>
                {integrations.jobApplicationIntegrations.map(obj => obj.integration.id).includes(integration.id) ? (
                  <Button
                    style={{
                      border: '1px solid #B7EB8F !important',
                      color: '#52C41A',
                      backgroundColor: 'white',
                      fontSize: '13px',
                      borderRadius: '0.2em',
                      height: '1.8em',
                      width: '100%',
                      maxWidth: '14em',
                      marginBottom: '0.5em',
                    }}
                    onClick={() => setConnectModalVisible(true, integration, 'Gestionar integración')}
                  >
                    Gestionar integración
                  </Button>
                ) : null}
              </Col>
            </Row>
            {state.connectModalVisible && (
              <ConnectIntegrationModal
                submitIntegration={state.connectTitle !== 'Gestionar integración' ? (
                  connectIntegration
                ) : (
                  updateIntegrationData
                )}
                integration={state.integrationToConnect}
                visible={state.connectModalVisible}
                setVisible={setConnectModalVisible}
                checkToken={checkToken}
                questionType={questionType}
                getFieldDecorator={getFieldDecorator}
                connectTitle={state.connectTitle}
              />
            )}
            {state.disconnectModalVisible && (
              <DisconnectIntegrationModal
                disconnectIntegration={disableConnection}
                integration={state.integrationToConnect}
                visible={state.disconnectModalVisible}
                setVisible={setDisconnectModalVisible}
              />
            )}
          </Row>
        </Col>
      )
      )}
    </Row>
  )
}

const IntegrationsForm = Form.create({ name: 'integrations_form' })(Integrations);
export default IntegrationsForm;