import React from 'react';
import { Col, Row, Input, Modal, Typography } from 'antd';

import ColModalEdit from './ColModalEdit';
import ColModalCreate from './ColModalCreate';

const { Text } = Typography;
const { TextArea } = Input;

const ModalEditAndCreate = ({
  isModalEditVisible,
  showModalFunction,
  setIsModalEditVisible,
  setIsModalAddAlternativeVisible,
  isModalAddAlternativeVisible,
  questionToEdit,
  setQuestionToEdit,
  handleEditQuestion,
  isModalCreateVisible,
  setSelected,
  setIsModalCreateVisible,
  handleSubmit,
  selected,
  questionToSend,
  setQuestionToSend,
}) => {
  const [counter, setCounter] = React.useState(questionToEdit?.configuration?.alternatives?.length || 2);

  return (
    <Modal
      title={isModalEditVisible ? 'Editar Pregunta' : 'Crear Pregunta'}
      visible={isModalEditVisible ? isModalEditVisible : isModalCreateVisible}
      onOk={() => {
        if (isModalEditVisible) {
          showModalFunction(setIsModalEditVisible, false);
          handleEditQuestion(questionToEdit);
        } else {
          handleSubmit();
        }
      }}
      onCancel={() => {
        showModalFunction(
          isModalEditVisible ? setIsModalEditVisible : setIsModalCreateVisible,
          false
        );
        if (isModalEditVisible) {
          setQuestionToEdit({
            additionals: {},
            configuration: {
              alternatives: [
                { text: undefined, correct: undefined },
                { text: undefined, correct: undefined },
              ],
            },
            question_type: selected || 'singleline',
            number_from: 0,
            number_to: 0,
            question: '',
          });
        } else {
          setQuestionToSend({
            additionals: {},
            configuration: {
              alternatives: [
                { text: undefined, correct: undefined },
                { text: undefined, correct: undefined },
              ],
            },
            question_type: selected || 'singleline',
            number_from: 0,
            number_to: 0,
            question: '',
          });
        }
      }}
      centered
      okText={isModalEditVisible ? 'Editar' : 'Crear'}
      cancelText={'Cancelar'}
      okButtonProps={{
        disabled:
          questionToSend.question !== '' || questionToEdit.question.length > 0
            ? false
            : true,
      }}
      destroyOnClose={true}
    >
      <Modal
        title='Copiar listado de alternativas'
        visible={isModalAddAlternativeVisible}
        onOk={() => showModalFunction(setIsModalAddAlternativeVisible, false)}
        onCancel={() =>
          showModalFunction(setIsModalAddAlternativeVisible, false)
        }
        centered
        okText='Confirmar'
        cancelText='Cancelar'
      >
        <Row gutter={[16, 20]}>
          <Col>
            <Text disabled>
              Copia y pega las alternativas en el campo inferior. Tus
              alternativas serán separadas por un salto de línea.
            </Text>
          </Col>
          <Col>
            <Text>Lista de alternativas </Text>
          </Col>
          <Col>
            <TextArea
              maxLength={100}
              style={{ height: '8.875rem' }}
              showCount={true}
            />
          </Col>
        </Row>
      </Modal>
      {isModalEditVisible ? (
        <ColModalEdit
          questionToEdit={questionToEdit}
          setQuestionToEdit={setQuestionToEdit}
          setCounter={setCounter}
          counter={counter}
          showModalFunction={showModalFunction}
          setIsModalAddAlternativeVisible={setIsModalAddAlternativeVisible}
        />
      ) : (
        <ColModalCreate
          setCounter={setCounter}
          setIsModalAddAlternativeVisible={setIsModalAddAlternativeVisible}
          showModalFunction={showModalFunction}
          counter={counter}
          selected={selected}
          setSelected={setSelected}
          questionToSend={questionToSend}
          setQuestionToSend={setQuestionToSend}
        />
      )}
    </Modal>
  );
};

export default ModalEditAndCreate;
