import React, { useContext }  from 'react';

import {
  Button,
  Divider,
  Icon,
  Row,
  Typography,
} from 'antd';
import PropTypes from 'prop-types';

import CertificationInformation from './CertificationInformation';
import { PersonalFormContext } from '../config/Context';
import i18n from '../../../i18n';

const { Text, Title } = Typography;

function Certifications(props) {
  const { form } = props;
  const { getFieldDecorator, getFieldValue, setFieldsValue, validateFields } = form;
  const {
    addCertification,
    candidate: { certifications },
    jobApplication,
  } = useContext(PersonalFormContext);

  const optional = jobApplication.optional_fields.certifications || jobApplication.optional_fields.certifications === undefined;

  return (
    <>
      <Row>
        <Title level={3}>
          {!optional ? <Text style={{ color: 'red' }}>*</Text> : ''}{i18n.t("form__certifications")}
        </Title>
      </Row>
      {certifications.map((certificationData, idx) => (
        <div key={`certification-information-${idx}`}>
          <CertificationInformation
            idx={idx}
            certificationData={certificationData}
            getFieldDecorator={getFieldDecorator}
            getFieldValue={getFieldValue}
            setFieldsValue={setFieldsValue}
            validateFields={validateFields}
          />
        </div>
      ))}
      {certifications.length === 0 ? <Divider /> : null}
      <Button className="add-button" onClick={addCertification}>
        <Icon type="plus" /> {i18n.t("form__add_certification")}
      </Button>
    </>
  );
}

Certifications.propTypes = {
  form: PropTypes.object.isRequired,
};

export default Certifications;
