import React from 'react';

import { Layout, Row, Col, Typography, Input, Tag, Button, Divider } from 'antd';
const { Title, Text } = Typography;

const EditInterviewList = ({ list, fnEdit, fnCopy, fnDelete, setSpecificId }) => {
  return (
    <Layout style={{background: 'white'}} className='layoutCustomInterview'>
      <Title level={4} style={{ margin: '0 0 20px 20px', color: '#5E5E5E' }}>Preguntas creadas</Title>
      {list.map((item, index) => (
        <Row key={index} className='formNewQuestions'>
          <Col style={{ marginRight: '40px' }}>
            <Title level={4} style={{ color: '#5E5E5E' }} >Pregunta {index + 1}</Title>
            {/* <Input disabled value={item.text} size="large" /> */}
            <Row style={{ border: '1px solid #D9D9D9', padding: '5px', cursor: 'not-allowed' }}>
              <Text>{item.text}</Text>
            </Row>
          </Col>
          <Col style={{ marginRight: '40px' }}>
            <Title level={4} style={{ color: '#5E5E5E' }} >Etiqueta</Title>
            {item.skill.map((tag, index) => (
              <Tag key={index}>{tag}</Tag>
            ))}
          </Col>
          <Col>
            <Title level={4} style={{ color: '#5E5E5E' }} >Acciones</Title>
            <Button size='large' icon='edit' onClick={() => { fnEdit(index, item.id); setSpecificId(index) }} style={{ border: 'none', color: '#A4A4A4', boxShadow: 'none' }} />
            <Button size='large' icon='copy' onClick={() => fnCopy(index, item.id)} style={{ border: 'none', color: '#A4A4A4', boxShadow: 'none' }} />
            <Button size='large' icon='delete' onClick={() => fnDelete({ state: true, idQuestion: item.id })} style={{ border: 'none', color: '#A4A4A4', boxShadow: 'none' }} />
          </Col>
        </Row>
      ))}
    </Layout>
  );
}

export default EditInterviewList;