import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from "connected-react-router";


import { withStyles } from "@material-ui/core/styles";
import { Grid, Tooltip, ClickAwayListener } from "@material-ui/core";

import * as dataActionCreators from '../../../../../../../actions/data';

import { Button, Popover, Typography } from 'antd'
import i18n from "../../../../../../../i18n";

const styles = theme => ({
    buttonCont: {
        textAlign: "center",
    },
    disabledB: {
        filter: 'grayscale(100%)',
        opacity: 0.7
    },
    tooltip: {
        fontSize: 14,
    },
});

class EndProcess extends React.Component {

    state = {
        open: false,
    };

    endProcess = () => {
        if (!this.props.completed){
            this.setState({ open: true });
        }
        else {
            this.props.dispatch(push('/me/scores'))
        }
    }

    handleClose = () => {
        this.setState({ open: false })
    }

	render() {
        const { businessSlug, classes, colorTheme, completed, items } = this.props;
        const { open } = this.state;
        const levelTwo = items.filter(e => e.code !== 'FORM' && e.code !== 'KO')
		return (
            <Fragment>
                {levelTwo !== 0 &&
                    <Grid item xs={12} className={classes.buttonCont}>
                        <ClickAwayListener onClickAway={this.handleClose}>
                            <Popover
                                content={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img width='50' src='https://static.wixstatic.com/media/6f7af9_9068a8e7bf0c4460b96f1a46e24a2710~mv2.png/v1/fill/w_90,h_90,al_c,q_85,usm_0.66_1.00_0.01/1.webp' />
                                    <div><Typography.Text strong>Para ver los resultados debes completar el proceso.</Typography.Text></div>
                                </div>}
                                trigger="click"
                                placement="bottom"
                                visible={open}
                                overlayClassName='results-popover'
                            >
                                <Button
                                    style={
                                        ['banco-internacional'].includes(businessSlug) ? (
                                            { maxWidth: '400px', ...colorTheme }
                                        ) : (
                                            { maxWidth: '400px' }
                                        )
                                    }
                                    size="large"
                                    onClick={this.endProcess}
                                    className={completed ? null : classes.disabledB}
                                    block
                                >
                                    {i18n.t('candidate_path__see_results')}
                                </Button>
                            </Popover>
                        </ClickAwayListener>
                    </Grid>
                }
            </Fragment>
		);
	}
}

EndProcess.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        jobappId: state.data.data.jobapp_id,
        status: state.data.data.status,
        items: state.data.data.items,
        token: state.auth.token,
        completed: state.data.data.items.every(item => (item.code === 'ALST' || item.code === 'ALSP') ? true : item.status === 'DONE'),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(dataActionCreators, dispatch),
        dispatch
    };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(EndProcess));
