import React, { useContext, useState, useMemo } from 'react';

import { CandidatePoolContext } from './config/CandidatePoolContext';

import {
  Button,
  Checkbox,
  Col,
  // Drawer,
  Dropdown,
  Icon,
  Input,
  InputNumber,
  Layout,
  Menu,
  Pagination,
  Row,
  Select,
  Tabs,
  Tag,
  Typography,
} from 'antd';

import './candidatePool.scss';
import CandidatePoolTable from './CandidatesTable';
import CandidateDetail from './CandidateDetail';
import ActionsComponent from './components/actions';
import CandidateProfileModal from '../NewCandidateModal';
import InviteCandidateComponent from '../../components/InviteCandidate';
import SendEmail from '../../components/SendEmail';
import AddTagsModal from '../../components/AddTagsModal';
import AddToProcess from './components/AddToProcess';
import { careerTypeOptions } from '../../constants/careers';
import PreviewDocument from '../CandidateProfilev2/common/PreviewDocument';
import AddPassiveForm from '../BusinessDashboard/CandidateList/components/PassiveCandidateFormModal';

import useBoolean from '../../hooks/useBoolean';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import { educationLevelOptions } from '../../constants/careers';

const { Text, Title } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const candidateTypeOptions = {
  active: 'Activo',
  passive: 'Pasivo',
  seed: 'Clonación',
}

function CandidatePool({ actions, business, token, ...props }) {
  const {
    addPassiveCandidateToJobApplications,
    addPassiveCandidateVisible,
    addPassiveCandidateVisibleMethod,
    buildFilterQuery,
    businessCustomFields,
    candidates,
    columnsShown,
    countries,
    customFieldsColumns,
    customFieldFilePreviewFlag,
    filtersConfig,
    getCandidatesPage,
    handleAddPassiveCandidateToJobApplications,
    handleAddPassiveCandidateVisible,
    handleColumnsSelection,
    loadCandidateData,
    loading,
    mailTemplates,
    openCandidate,
    page,
    pageSize,
    previewCv,
    selectedCandidates,
    selectedFileId,
    selectedFilters,
    setAddPassiveCandidateVisibleMethod,
    setColumnsShown,
    setCustomFieldsColumns,
    setCustomFieldFilePreviewFlag,
    setFiltersConfig,
    setMailTemplates,
    setOpenCandidate,
    setPage,
    setPageSize,
    setPreviewCv,
    setSelectedCandidates,
    setSelectedFileId,
    setSelectedFilters,
    tableColumns,
    totalCandidates,
  } = useContext(CandidatePoolContext);

  // TABLE
  const [actionSelected, setActionSelected] = useState(null);
  const [columnsShownDropdownVisible, handleColumnsShownDropdownVisible] =
    useBoolean(false);
  const [paneKey, setPaneKey] = useState('1');
  // MODALS
  const [openProfile, setOpenProfile] = useState(null);
  // FILTERS
  // const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  // const [filterLabelSearch, setFilterLabelSearch] = useState('');

  const Breadcrumb = useBreadcrumb(
    [
      {
        path: '/',
        text: 'Home',
      },
      {
        path: 'candidates',
        text: 'Candidatos',
      },
    ],
    '>'
  );

  const buildFilterTag = {
    tags: (value) => `Etiquetas: ${value.join(', ')}`,
    email: (value) => `Correo electrónico: ${value}`,
    education_level: (value) => {
      const aux = [];
      careerTypeOptions.forEach(
        (careerType) => value.includes(careerType.value) && aux.push(careerType.label)
      );
      return `Nivel educacional: ${aux.join(', ')}`;
    },
    career: (value) => `Carrera: ${value}`,
    experience: (value) => `Experiencia laboral: ${value}`,
    years_of_experience: (value) => {
      const type = filtersConfig.years_of_experience.type.split('.');
      if (type.length == 2) {
        const min_years_index = type.indexOf('_min');
        const max_years_index = type.indexOf('_max');
        return `Años de experiencia: ${value[min_years_index] !== value[max_years_index]
          ? `${value[min_years_index]} a`
          : ''
          } ${value[max_years_index]} año${value[max_years_index] > 1 ? 's' : ''
          }`;
      }
      if (type.includes('_min')) {
        return `Años de experiencia último cargo: Mínimo ${value[0]} año${value[0] > 1 ? 's' : ''
          }`;
      }
      if (type.includes('_max')) {
        return `Años de experiencia: Máximo ${value[0]} año${value[0] > 1 ? 's' : ''
          }`;
      }
    },
    candidate_type: (value) => `Tipo de candidato: ${candidateTypeOptions[value]}`,
  };

  const businessHasCustomFields =
    businessCustomFields && businessCustomFields.length > 0;

  const closeFilterDropdown = (key) =>
    setFiltersConfig((prevState) => {
      const newState = { ...prevState };
      newState[key] = {
        ...prevState[key],
        dropdownVisible: !prevState[key].dropdownVisible,
      };
      return { ...newState };
    });

  // const handleShowFilters = () => setFilterDrawerOpen((prev) => !prev);

  const handleShowProfile = (application) => {
    const personalUserId = application.personaluser ? application.personaluser : application.passivecandidate;
    const candidateId = application.candidate_id;
    const jobApplicationId = application.job_application_id_id;
    setOpenCandidate(null);
    setOpenProfile({ personalUserId, candidateId, jobApplicationId });
  };

  const openCandidateVisibleModal = useMemo(() => {
    return openCandidate ? true : false;
  }, [openCandidate]);

  const selectUnselectAll = () => {
    if (Object.keys(selectedCandidates).length >= pageSize) {
      setSelectedCandidates({});
    } else {
      const tmp = {};
      candidates.forEach((x) => {
        tmp[x.id] = {
          email: x.email,
          name: x.full_name,
          tags: x.tags,
        };
      });
      setSelectedCandidates({ ...tmp });
    }
  };

  const toggleSelectFilter = ({ key }) => {
    if (selectedFilters.includes(key)) {
      setFiltersConfig((prevState) => {
        const newState = { ...prevState };
        newState[key] = {
          ...prevState[key],
          dropdownVisible: true,
          type: '',
          value: null,
        };
        return { ...newState };
      });
      setSelectedFilters([...selectedFilters].filter((selectedKey) => selectedKey !== key));
    }
    setSelectedFilters([...selectedFilters, key]);
  };

  // MENUS
  const columnsShownMenu = (
    <Menu
      className="select-columns-menu"
      openKeys={['custom_fields']}
      inlineCollapsed={false}
      selectable={false}
      style={{ width: '20em' }}
    >
      <Menu.Item key="title">
        <Row align="middle" justify="space-between" type="flex">
          <Text>Propiedades de la tabla</Text>
          {/* <Icon
            type="close-circle"
            onClick={() => handleColumnsShownDropdownVisible.off()}
          /> */}
        </Row>
      </Menu.Item>
      <Menu.Item key="selectAll">
        <Row align="middle" justify="space-between" type="flex">
          <Col>Seleccionar todo</Col>
          <Col span={2}>
            <Checkbox
              checked={
                columnsShown?.length === tableColumns?.length &&
                Object.keys(customFieldsColumns)?.length ===
                businessCustomFields?.length
              }
              onChange={() => {
                if (
                  columnsShown?.length === tableColumns?.length &&
                  Object.keys(customFieldsColumns).length ===
                  businessCustomFields.length
                ) {
                  setColumnsShown(['name', 'email']);
                  setCustomFieldsColumns({});
                } else {
                  setColumnsShown([
                    'name',
                    'email',
                    'cv',
                    'candidate_type',
                    'tags',
                    'experience',
                    'education_level',
                  ]);
                  const prevCustomFieldsColumns = { ...customFieldsColumns };
                  businessCustomFields.forEach((customField) => {
                    if (
                      !Object.keys(prevCustomFieldsColumns).includes(
                        customField.name_key
                      )
                    ) {
                      prevCustomFieldsColumns[customField.name_key] = {
                        ...customField,
                      };
                    }
                  });
                  setCustomFieldsColumns(prevCustomFieldsColumns);
                }
              }}
            />
          </Col>
        </Row>
      </Menu.Item>
      <Menu.Item key="cv">
        <Row align="middle" justify="space-between" type="flex">
          <Col>
            <Icon type="solution" /> CV
          </Col>
          <Col span={2}>
            <Checkbox
              checked={columnsShown?.includes('cv')}
              onChange={() => {
                handleColumnsSelection('cv');
              }}
            />
          </Col>
        </Row>
      </Menu.Item>
      <Menu.Item key="candidate_type">
        <Row align="middle" justify="space-between" type="flex">
          <Col>
            <Icon type="team" /> Tipo de candidato
          </Col>
          <Col span={2}>
            <Checkbox
              checked={columnsShown?.includes('candidate_type')}
              onChange={() => {
                handleColumnsSelection('candidate_type');
              }}
            />
          </Col>
        </Row>
      </Menu.Item>
      <Menu.Item key="tags">
        <Row align="middle" justify="space-between" type="flex">
          <Col>
            <Icon type="tag" /> Etiquetas
          </Col>
          <Col span={2}>
            <Checkbox
              checked={columnsShown?.includes('tags')}
              onChange={() => {
                handleColumnsSelection('tags');
              }}
            />
          </Col>
        </Row>
      </Menu.Item>
      <Menu.Item key="experience">
        <Row align="middle" justify="space-between" type="flex">
          <Col>
            <Icon type="file-search" /> Experiencia laboral
          </Col>
          <Col span={2}>
            <Checkbox
              checked={columnsShown?.includes('experience')}
              onChange={() => {
                handleColumnsSelection('experience');
              }}
            />
          </Col>
        </Row>
      </Menu.Item>
      <Menu.Item key="education_level">
        <Row align="middle" justify="space-between" type="flex">
          <Col>
            <Icon type="read" /> Formación
          </Col>
          <Col span={2}>
            <Checkbox
              checked={columnsShown?.includes('education_level')}
              onChange={() => {
                handleColumnsSelection('education_level');
              }}
            />
          </Col>
        </Row>
      </Menu.Item>
      {businessHasCustomFields ? (
        <Menu.SubMenu
          className="select-columns-submenu"
          key="custom_fields"
          mode="inline"
          title={
            <Row align="middle" justify="space-between" type="flex">
              <Col>
                <Icon type="tags" /> Atributos personalizados
              </Col>
              <Col span={2}>
                <Checkbox
                  checked={
                    Object.keys(customFieldsColumns).length ===
                    businessCustomFields?.length
                  }
                  onChange={() => {
                    if (
                      Object.keys(customFieldsColumns).length ===
                      businessCustomFields?.length
                    ) {
                      setCustomFieldsColumns({});
                    } else {
                      const prevCustomFieldsColumns = { ...customFieldsColumns };
                      businessCustomFields.forEach((customField) => {
                        if (
                          !Object.keys(prevCustomFieldsColumns).includes(
                            customField.name_key
                          )
                        ) {
                          prevCustomFieldsColumns[customField.name_key] = {
                            ...customField,
                          };
                        }
                      });
                      setCustomFieldsColumns(prevCustomFieldsColumns);
                    }
                  }}
                />
              </Col>
            </Row>
          }
        >
          {businessCustomFields?.length > 0
            ? businessCustomFields.map((customField) => (
              <Menu.Item
                className="select-columns-submenu-item"
                key={`candidate-${customField.name_key}`}
              >
                <Row align="middle" justify="space-between" type="flex">
                  <Col span={3} />
                  <Col span={19} style={{ whiteSpace: 'pre-wrap' }}>
                    {customField.name}
                  </Col>
                  <Col span={2}>
                    <Checkbox
                      checked={Object.keys(customFieldsColumns).includes(
                        customField.name_key
                      )}
                      onChange={() =>  {
                        if (
                          Object.keys(customFieldsColumns).includes(
                            customField.name_key
                          )
                        ) {
                          const newState = { ...customFieldsColumns };
                          delete newState[customField.name_key];
                          setCustomFieldsColumns(newState);
                        } else {
                          setCustomFieldsColumns({
                            ...customFieldsColumns,
                            [customField.name_key]: customField,
                          });
                        }
                      }}
                    />
                  </Col>
                </Row>
              </Menu.Item>
            ))
            : null}
        </Menu.SubMenu>
      ) : null}
    </Menu>
  );

  const filterDropdowns = {
    tags: (
      <Menu>
        <Menu.Item>
          <Input
            allowClear
            key={filtersConfig.tags.value?.join(',')}
            onPressEnter={(event) => {
              setFiltersConfig((prevState) => {
                const newState = { ...prevState };
                if (newState.tags.value) {
                  newState.tags.value.push(
                    event.target.value.toLocaleLowerCase()
                  );
                } else {
                  newState.tags.value = [
                    event.target.value.toLocaleLowerCase(),
                  ];
                }
                return { ...newState };
              });
            }}
          />
          <div>
            <Text style={{ marginTop: 15, fontSize: 12 }}>
              {filtersConfig.tags.value && filtersConfig.tags.value?.length > 0
                ? null
                : 'Sin etiquetas, ingrese un valor y presione enter.'}
            </Text>
          </div>
        </Menu.Item>
        <Menu.Item>
          {filtersConfig.tags.value?.map((tag) => (
            <Tag
              className="dropdown-filter-tag"
              closable
              key={tag}
              onClose={() =>
                setFiltersConfig((prevState) => {
                  const newState = { ...prevState };
                  newState.tags.value = prevState.tags.value?.filter(
                    (val) => val !== tag
                  );
                  return { ...newState };
                })
              }
            >
              {tag}
            </Tag>
          ))}
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item style={{ textAlign: 'end' }}>
          <Button
            onClick={() => {
              buildFilterQuery();
              closeFilterDropdown('tags');
            }}
            type="primary"
          >
            Filtrar
          </Button>
        </Menu.Item>
      </Menu>
    ),
    email: (
      <Menu>
        <Menu.Item>
          <Input
            onChange={(event) => {
              setFiltersConfig((prevState) => {
                const newState = { ...prevState };
                newState.email.value =
                  event.target.value.toLocaleLowerCase();
                return { ...newState };
              });
            }}
            onPressEnter={() => {
              buildFilterQuery();
              closeFilterDropdown('email');
            }}
          />
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item style={{ textAlign: 'end' }}>
          <Button
            onClick={() => {
              buildFilterQuery();
              closeFilterDropdown('email');
            }}
            type="primary"
          >
            Filtrar
          </Button>
        </Menu.Item>
      </Menu>
    ),
    education_level: (
      <Menu>
        <Menu.Item>
          <Select
            mode="tags"
            onDeselect={(value) =>
              setFiltersConfig((prevState) => {
                const newState = { ...prevState };
                if (
                  prevState.education_level.value &&
                  prevState.education_level.value.includes(value)
                ) {
                  newState.education_level.value =
                    prevState.education_level.value.filter(
                      (level) => level !== value
                    );
                }
                return { ...newState };
              })
            }
            onSelect={(value) =>
              setFiltersConfig((prevState) => {
                const newState = { ...prevState };
                if (prevState.education_level.value) {
                  newState.education_level.value.push(value);
                } else {
                  newState.education_level.value = [value];
                }
                return { ...newState };
              })
            }
            showArrow
            style={{ borderRadius: '2px', width: '20em' }}
            value={filtersConfig.education_level.value || []}
          >
            {educationLevelOptions.map((level, index) => (
              <Option key={index} value={level.value}>
                {level.label}
              </Option>
            ))}
          </Select>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item style={{ textAlign: 'end' }}>
          <Button
            onClick={() => {
              buildFilterQuery();
              closeFilterDropdown('education_level');
            }}
            type="primary"
          >
            Filtrar
          </Button>
        </Menu.Item>
      </Menu>
    ),
    career: (
      <Menu>
        <Menu.Item>
          <Input
            onChange={(event) => {
              setFiltersConfig((prevState) => {
                const newState = { ...prevState };
                newState.career.value = event.target.value;
                return { ...newState };
              });
            }}
            onPressEnter={() => {
              buildFilterQuery();
              closeFilterDropdown('career');
            }}
          />
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item style={{ textAlign: 'end' }}>
          <Button
            onClick={() => {
              buildFilterQuery();
              closeFilterDropdown('career');
            }}
            type="primary"
          >
            Filtrar
          </Button>
        </Menu.Item>
      </Menu>
    ),
    experience: (
      <Menu>
        <Menu.Item>
          <Input
            placeholder="Cargo previo"
            onChange={(event) => {
              setFiltersConfig((prevState) => {
                const newState = { ...prevState };
                newState.experience.value = event.target.value;
                newState.experience.type = '_position_contains';
                return { ...newState };
              });
            }}
            onPressEnter={() => {
              buildFilterQuery();
              closeFilterDropdown('experience');
            }}
          />
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item style={{ textAlign: 'end' }}>
          <Button
            onClick={() => {
              buildFilterQuery();
              closeFilterDropdown('experience');
            }}
            type="primary"
          >
            Filtrar
          </Button>
        </Menu.Item>
      </Menu>
    ),
    years_of_experience: (
      <Menu>
        <Menu.Item>
          <InputNumber
            parser={(value) => `${value}`.replace(/[^\d]/g, '')}
            placeholder="Mínimo"
            onChange={(value) => {
              setFiltersConfig((prevState) => {
                const newState = { ...prevState };
                if (typeof value !== 'number') {
                  // Si borraron el input
                  if (prevState.years_of_experience.type.includes('_min')) {
                    if (prevState.years_of_experience.type.includes('_max')) {
                      const max_index = prevState.years_of_experience.type
                        .split('.')
                        .indexOf('_max');
                      newState.years_of_experience.value = [
                        prevState.years_of_experience.value[max_index],
                      ];
                    } else {
                      newState.years_of_experience.value = null;
                    }
                    newState.years_of_experience.type =
                      prevState.years_of_experience.type
                        .replace('_min', '')
                        .replace('.', '');
                  }
                } else if (
                  !prevState.years_of_experience.type.includes('_max')
                ) {
                  newState.years_of_experience.type = '_min';
                  newState.years_of_experience.value = [value];
                } else if (
                  prevState.years_of_experience.type.includes('_min')
                ) {
                  const index = prevState.years_of_experience.type
                    .split('.')
                    .indexOf('_min');
                  newState.years_of_experience.value[index] = value;
                } else {
                  newState.years_of_experience.type += '._min';
                  newState.years_of_experience.value.push(value);
                }
                return { ...newState };
              });
            }}
          />
          <Text
            style={{
              fontSize: '18px',
              marginLeft: '0.5em',
              marginRight: '0.5em',
            }}
          >
            -
          </Text>
          <InputNumber
            parser={(value) => `${value}`.replace(/[^\d]/g, '')}
            placeholder="Máximo"
            onChange={(value) => {
              setFiltersConfig((prevState) => {
                const newState = { ...prevState };
                if (typeof value !== 'number') {
                  // Si borraron el input
                  if (prevState.years_of_experience.type.includes('_max')) {
                    if (prevState.years_of_experience.type.includes('_min')) {
                      const min_index = prevState.years_of_experience.type
                        .split('.')
                        .indexOf('_min');
                      newState.years_of_experience.value = [
                        prevState.years_of_experience.value[min_index],
                      ];
                    } else {
                      newState.years_of_experience.value = null;
                    }
                    newState.years_of_experience.type =
                      prevState.years_of_experience.type
                        .replace('_max', '')
                        .replace('.', '');
                  }
                } else if (
                  !prevState.years_of_experience.type.includes('_min')
                ) {
                  newState.years_of_experience.type = '_max';
                  newState.years_of_experience.value = [value];
                } else if (
                  prevState.years_of_experience.type.includes('_max')
                ) {
                  const index = prevState.years_of_experience.type
                    .split('.')
                    .indexOf('_max');
                  newState.years_of_experience.value[index] = value;
                } else {
                  newState.years_of_experience.type += '._max';
                  newState.years_of_experience.value.push(value);
                }
                return { ...newState };
              });
            }}
          />
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item style={{ textAlign: 'end' }}>
          <Button
            onClick={() => {
              buildFilterQuery();
              closeFilterDropdown('years_of_experience');
            }}
            type="primary"
          >
            Filtrar
          </Button>
        </Menu.Item>
      </Menu>
    ),
    candidate_type: (
      <Menu>
        <Menu.Item>
          <Select
            onSelect={(value) =>
              setFiltersConfig((prevState) => {
                const newState = { ...prevState };
                newState.candidate_type.value = value;
                return { ...newState };
              })
            }
            showArrow
            style={{ borderRadius: '2px', width: '20em' }}
            value={filtersConfig.candidate_type.value}
          >
            {Object.keys(candidateTypeOptions).map((code) => (
              <Option key={candidateTypeOptions[code]} value={code}>
                {candidateTypeOptions[code]}
              </Option>
            ))}
          </Select>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item style={{ textAlign: 'end' }}>
          <Button
            onClick={() => {
              buildFilterQuery();
              closeFilterDropdown('candidate_type');
            }}
            type="primary"
          >
            Filtrar
          </Button>
        </Menu.Item>
      </Menu>
    ),
  };

  const filtersMenu = (
    <Menu
      multiple
      onClick={toggleSelectFilter}
      selectedKeys={selectedFilters.map((entry) => entry[0])}
    >
      {Object.entries(filtersConfig).map((entry) => {
        const [key, { icon, selectLabel }] = entry;
        return (
          <Menu.Item key={key}>
            <Icon type={icon} />
            {selectLabel}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const selectedCandidatesArray = useMemo(
    () => Object.values(selectedCandidates),
    [selectedCandidates]
  );

  const selectedCandidatesWithMail = useMemo(
    () => selectedCandidatesArray.filter((candidate) => candidate.email),
    [selectedCandidatesArray]
  );

  const activeCandidatesOnly = useMemo(
    () => !selectedCandidatesArray.some((candidate) => candidate.candidate_type === 'passive'),
    [selectedCandidatesArray]
  );

  const passiveCandidatesOnly = useMemo(
    () => !selectedCandidatesArray.some((candidate) => candidate.candidate_type !== 'passive'),
    [selectedCandidatesArray]
  );

  return (
    <Layout style={{ padding: '2rem', height: '100%' }}>
      <div style={{ marginBottom: '1.25rem' }}>{Breadcrumb}</div>
      <Row>
        <Title>Candidatos</Title>
      </Row>
      <Row>
        <Tabs
          activeKey={paneKey}
          defaultActiveKey="1"
          onChange={(e) => setPaneKey(e)}
        >
          <TabPane key="1" tab="Candidatos">
            <Row type="flex">
              <Dropdown overlay={filtersMenu} trigger={['click']}>
                <Button
                  icon="filter"
                  style={{ marginBottom: '10px', marginRight: '10px' }}
                >
                  Añadir filtro
                </Button>
              </Dropdown>
              {selectedFilters.map((key) => {
                const { dropdownVisible, icon, selectLabel, value } =
                  filtersConfig[key];
                return (
                  <Dropdown
                    key={`filter-${key}`}
                    overlay={filterDropdowns[key]}
                    onClick={() =>
                      setFiltersConfig((prevState) => {
                        const newState = { ...prevState };
                        newState[key] = {
                          ...prevState[key],
                          dropdownVisible: !prevState[key].dropdownVisible,
                        };
                        return { ...newState };
                      })
                    }
                    trigger={['click']}
                    visible={dropdownVisible}
                  >
                    <Tag
                      className="filter-tag"
                      closable
                      color="magenta"
                      key={
                        value === null
                          ? selectLabel
                          : buildFilterTag[key](value)
                      }
                      onClose={() => toggleSelectFilter({ key })}
                      style={{ marginBottom: '10px' }}
                    >
                      <Icon style={{ marginRight: '0.5em' }} type={icon} />{' '}
                      {value === null
                        ? selectLabel
                        : buildFilterTag[key](value)}
                    </Tag>
                  </Dropdown>
                );
              })}
              {selectedFilters.length > 0 && (
                <Button
                  onClick={() => {
                    setFiltersConfig((prevState) => {
                      const newFiltersConfig = { ...prevState };
                      for (let i = 0; i < selectedFilters.length; i++) {
                        const filterName = selectedFilters[i];
                        newFiltersConfig[filterName].dropdownVisible = true;
                        newFiltersConfig[filterName].type = '';
                        newFiltersConfig[filterName].value = null;
                      }
                      return { ...newFiltersConfig };
                    });
                    setSelectedFilters([]);
                  }}
                  style={{ marginBottom: '10px' }}
                >
                  Limpiar
                </Button>
              )}
            </Row>
            <Row style={{ marginBottom: 20, marginTop: 20 }} type="flex">
              <Col sm={12}>
                <Row type="flex" justify="start">
                  <Button>
                    <Checkbox
                      indeterminate={
                        selectedCandidatesArray.length > 0 &&
                        selectedCandidatesArray.length < pageSize
                      }
                      checked={selectedCandidatesArray.length >= pageSize}
                      onClick={selectUnselectAll}
                      style={{ marginRight: '5px' }}
                    />
                    <Text>
                      {selectedCandidatesArray.length > 0
                        ? selectedCandidatesArray.length
                        : ''}
                    </Text>
                    <Icon type="down" />
                  </Button>
                  <ActionsComponent
                    active={selectedCandidatesArray.length > 0}
                    activeCandidatesOnly={activeCandidatesOnly}
                    allCandidatesWithEmail={selectedCandidatesWithMail.length === selectedCandidatesArray.length}
                    handleAction={setActionSelected}
                    businessPermissions={props.businessPermissions}
                    passiveCandidatesOnly={passiveCandidatesOnly}
                  />
                </Row>
              </Col>
              <Col sm={12}>
                <Row gutter={[12, 0]} justify="end" type="flex">
                  {/* TODO: */}
                  {/* <Button icon="search" /> */}
                  {/* <Button icon="pie">Filtros personalizados</Button> */}
                  <Col>
                    <Dropdown
                      overlay={columnsShownMenu}
                      trigger={[]}
                      visible={columnsShownDropdownVisible}
                    >
                      <Button
                        icon="table"
                        onClick={() => handleColumnsShownDropdownVisible.toggle()}
                        style={{ marginRight: '5px' }}
                      >
                        Mostrar en la tabla <Icon type="down" />
                      </Button>
                    </Dropdown>
                  </Col>
                  <Col>
                    {(props.businessPermissions.business_admin || props.businessPermissions.role?.can_create_and_manage_passive_candidates)
                      ? (
                        <Dropdown
                          overlay={(
                            <Menu>
                              <Menu.Item 
                                key="1"
                                onClick={() => window.open('https://chrome.google.com/webstore/detail/genomawork/pdfkfkaahodnfjcnonokegcafcjgapgb?brand=CHBD&gclid=Cj0KCQjw3JanBhCPARIsAJpXTx4p6iSNolA7dEckQyWReqq9hXXIQ936jABvgNZSTirshD767SxWDwQaAl9BEALw_wcB&gclsrc=aw.ds', '_blank')}
                              >
                                <Icon type="to-top" />
                                Cargar candidato pasivo desde extensión
                              </Menu.Item>
                              <Menu.Item key="2" onClick={handleAddPassiveCandidateVisible.on}>
                                <Icon type="edit" />
                                Cargar candidato pasivo manualmente
                              </Menu.Item>
                            </Menu>
                          )}
                          placement="bottomLeft"
                        >
                          <Button
                            className="candidate-list-container--button"
                            icon="plus"
                            type="primary"
                          >
                            Cargar candidatos
                          </Button>
                        </Dropdown>
                      ) : null}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ marginBottom: 20 }}>
              <CandidatePoolTable
                setSelectedCandidatesKeys={(selectedRowKeys) => {
                  const tmp = {};
                  candidates
                    .filter((candidate) =>
                      selectedRowKeys.includes(candidate.key)
                    )
                    .forEach((x) => {
                      tmp[x.id] = {
                        email: x.email,
                        name: x.full_name,
                        tags: x.tags,
                        candidate_type: x.candidate_type,
                        personal_user_id: x.personal_user,
                      };
                    });
                  setSelectedCandidates(tmp);
                }}
              />
            </Row>
            <Row type="flex" justify="end" gutter={20}>
              <Col>
                <Select value={pageSize} onChange={(e) => setPageSize(e)}>
                  <Option value={25}>25</Option>
                  <Option value={50}>50</Option>
                  <Option value={100}>100</Option>
                </Select>
              </Col>
              <Col>
                <Pagination
                  current={page}
                  disabled={loading}
                  onChange={(p) => {
                    setPage(p);
                  }}
                  pageSize={pageSize}
                  total={totalCandidates}
                />
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Row>

      {/* Modals */}

      <CandidateDetail
        visible={openCandidateVisibleModal && !addPassiveCandidateVisible}
        handleShowProfile={handleShowProfile}
      />

      {/* TODO: Verificar que los cv se ven bien en staging y producción */}
      {previewCv && (
        <PreviewDocument
          handleOk={() => setPreviewCv(null)}
          file={previewCv}
          visiblePreviewModal={true}
        />
      )}

      {openProfile && (
        <CandidateProfileModal
          candidateId={openProfile.candidateId}
          userId={openProfile.personalUserId}
          jobAppId={openProfile.jobApplicationId}
          closeModal={() => setOpenProfile(null)}
          onChangePhase={() => { }}
          lanes={{}}
          mode="isolated"
          discardReasons={[]}
        />
      )}

      <InviteCandidateComponent
        closeModal={() => setActionSelected(null)}
        selectedPersonalUsers={selectedCandidatesArray}
        source="candidate-pool-gw"
        templates={mailTemplates}
        visible={actionSelected === 'invite'}
      />

      <SendEmail
        closeModal={() => setActionSelected(null)}
        selectedPersonalUsers={selectedCandidates}
        setTemplates={setMailTemplates}
        templates={mailTemplates}
        visible={actionSelected === 'sendMail'}
      />

      <AddTagsModal
        closeModal={() => setActionSelected(null)}
        refreshTable={getCandidatesPage}
        selectedPersonalUsers={selectedCandidates}
        visible={actionSelected === 'addTags'}
      />

      <AddToProcess
        businessId={business.id}
        candidates={Object.keys(selectedCandidates)}
        closeModal={() => setActionSelected(null)}
        fetchAndReturn={actions.fetchAndReturn}
        token={token}
        visible={actionSelected === 'addToProcess'}
      />

      <AddPassiveForm
        actions={actions}
        businessId={business.id}
        closeOnDelete={() => {
          setOpenCandidate(null);
          getCandidatesPage();
          setAddPassiveCandidateVisibleMethod('POST');
          handleAddPassiveCandidateToJobApplications.off();
        }}
        countries={countries}
        forceStep={addPassiveCandidateToJobApplications ? 2 : undefined}
        handleVisible={{
          ...handleAddPassiveCandidateVisible,
          off: () => {
            handleAddPassiveCandidateVisible.off();
            handleAddPassiveCandidateToJobApplications.off();
          },
        }}
        method={addPassiveCandidateVisibleMethod}
        passiveCandidate={openCandidate && ({
          id: openCandidate.passivecandidate,
          candidateId: openCandidate.id,
          personaluser_fields: {
            cv: openCandidate.cv,
            name: openCandidate.name,
            surname: openCandidate.surname,
            email: openCandidate.email,
            phone_prefix: openCandidate.phone_prefix,
            phone: openCandidate.phone,
            country: openCandidate.country,
            source: openCandidate.source,
          },
          other_contact_info: { ...openCandidate.other_contact_info },
          applications: openCandidate.applications?.map(
            ({ job_application_id_id, job_application_name, job_application_status }) => ({
              id: job_application_id_id,
              name: job_application_name,
              status: job_application_status,
            })
          ),
        })}
        setCandidates={getCandidatesPage}
        token={token}
        updatePassiveCandidateData={(updatedData) => {
          if (updatedData?.personaluser_fields) {
            const { source, email } = updatedData;
            const { name, surname, phone_prefix, phone, country } = updatedData.personaluser_fields;
            let id;
            if (updatedData.candidate_id) {
              id = updatedData.candidate_id;
            } else {
              id = openCandidate.id;
            }
            setOpenCandidate({
              ...openCandidate,
              id,
              name,
              surname,
              email,
              phone_prefix,
              phone,
              country,
              source,
              other_contact_info: { ...updatedData.other_contact_info },
              candidate_type: 'passive',
            })
          } else if (updatedData?.newApplication) {
            loadCandidateData();
          }
          setAddPassiveCandidateVisibleMethod('POST');
        }}
        visible={addPassiveCandidateVisible}
      />
      {/* For file custom fields */}
      <PreviewDocument
        file={null}
        visiblePreviewModal={customFieldFilePreviewFlag}
        handleOk={() => {
          setCustomFieldFilePreviewFlag(false);
          setSelectedFileId(null);
        }}
        fetchUrl={`/api/v1/gamesandtests/filerecords/${selectedFileId}/`}
        withFetch={true}
      />

      {/* <Drawer onClose={handleShowFilters} visible={filterDrawerOpen}>Aca van los filtros</Drawer> */}
    </Layout>
  );
}

export default CandidatePool;
