import React from 'react';
import {
  Icon
} from 'antd';

export default function CircleWithIcon(props) {
  const { icon, disabled, size=70, backgroundColor='FFF0F6', iconColor='#F175A5' } = props;

  return (
    <div
      className="account-circle"
      style={{ 
        backgroundColor: disabled ? '#F0F0F0' : backgroundColor ? backgroundColor : '#FFF0F6', 
        height: size, 
        width: size 
      }}
    >
      <div>
        <Icon
          type={icon}
          style={{
            fontSize: 30,
            color: disabled ? '#CCCCCC' : iconColor ? iconColor : '#F175A5',
          }}
        />
      </div>
    </div>
  )
}