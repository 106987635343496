import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import { Button, Popover, Typography } from "antd";
import i18n from "../../../../../i18n";

const EndProcess = (props) => {
  const [state, setState] = useState({
    open: false,
  });
  const { businessSlug, colorTheme, hasGamesResponses } = props;

  const endProcess = () => {
    if (!props.hasGamesResponses) {
      setState({ open: true });
    } else {
      props.dispatch(push("/me/scores"));
    }
  };

  return (
    <Fragment>
      <div sm={12} style={{ textAlign: "center" }}>
        <Popover
          content={
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                width="50"
                src="https://static.wixstatic.com/media/6f7af9_9068a8e7bf0c4460b96f1a46e24a2710~mv2.png/v1/fill/w_90,h_90,al_c,q_85,usm_0.66_1.00_0.01/1.webp"
              />
              <div>
                <Typography.Text strong>
                  {i18n.t('candidate_path__see__results__tooltip')}
                </Typography.Text>
              </div>
            </div>
          }
          trigger="click"
          placement="bottom"
          open={state.open && !hasGamesResponses}
          overlayClassName="cp-results-popover"
        >
          <Button
            style={
              ["banco-internacional"].includes(businessSlug)
                ? { maxWidth: "400px", ...colorTheme }
                : { maxWidth: "20rem", minWidth: "12rem" }
            }
            size="large"
            onClick={endProcess}
            className={hasGamesResponses ? '' : 'disabledB'}
            block
          >
            {i18n.t("candidate_path__see_results")}
          </Button>
        </Popover>
      </div>
    </Fragment>
  );
};

const mapDispatchToProps = dispatch => {
    return {
      dispatch,
    };
  };
export default connect(mapDispatchToProps)(EndProcess);
