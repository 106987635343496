import React from 'react';
import { 
  Icon } from 'antd';

export const icon = {
  'singleline': 'minus',
  'multiline': 'align-left',
  'int': 'calculator',
  'singleselection': 'check',
  'multiselection': <i className="anticon anticon-check"><Icon type='unordered-list' /></i>,
  'file': 'paper-clip'
}

export const typeToSpanishText = {
  'singleline': 'Texto corto (200 caracteres)',
  'multiline': 'Texto largo (1500 caracteres)',
  'int':'Numérico',
  'singleselection': 'Selección única',
  'multiselection': 'Selección múltiple',
  'file': 'Documento adjunto'
}

export const colors = {
  'REASONING': '#4e4cac',
  'COGNITIVE': '#f07f8b',
  'PERSONALITY': '#9698d5',
  'EMOTIONAL': '#f07f8b'
}

export const texts = {
  'REASONING': 'Razonamiento',
  'COGNITIVE': 'Cognitivo',
  'PERSONALITY': 'Personalidad',
  'EMOTIONAL': 'Emocional'
}

export const initialWelcomeText = '<p style="color: #ee689b">¡Bienvenido(a)!</p><p></p><p>Agradecemos tu interés por ser parte de nuestro equipo.</p><p></p><p>Te invitamos, como parte de nuestro proceso de selección, a que completes algunos juegos y, a cambio, te entregaremos un reporte que te ayudará a conocerte mejor.</p><p></p><p>Esta etapa es muy importante, ya que el resultado determinará tu continuidad en el proceso de selección y potencial incorporación a nuestra empresa.</p><p></p><p>La actividad cuenta con juegos que duran entre 2 y 4 minutos y un test de 10 minutos. No es necesario que completes todos los juegos de una sola vez. Puedes completar algunos, y en otro momento los que te falten.</p><p></p><p>Esta actividad debe ser realizada utilizando el navegador Google Chrome y con una buena conexión a Internet.</p><p></p><p>Te sugerimos jugar en un lugar tranquilo y sin distracciones.</p><p></p><p>¡Gracias por tu postulación!</p><p></p><p style="color: #ee689b; font-size: 14px;">Cualquier duda o problema por favor escribe directamente al chat de soporte en el costado inferior derecho de la pantalla.</p>'