import fetch from 'isomorphic-fetch';
import { push } from 'react-router-redux';
import { alertActions } from './alerts';
import { checkHttpStatus, parseJSON } from '../utils';
import { 
    VIDEO_FETCH_BEGIN,
    VIDEO_FETCH_FAILURE,
    VIDEO_FETCH_SUCCESS,
    VIDEO_RATE_BEGIN,
    VIDEO_RATE_FAILURE,
    VIDEO_RATE_SUCCESS,
    RESET_VIDEO_STATE,
 } from '../constants';

const VIDEO_URL = process.env.REACT_APP_VIDEO_URL;

export const resetVideoState = () => ({
    type: RESET_VIDEO_STATE,
})

export function resetVideoInterviewState(email) {
    return async (dispatch) => {
        dispatch(resetVideoState())
    }
}

export const fetchVideoBegin = (interviewId, userEmail) => ({
        type: VIDEO_FETCH_BEGIN,
        payload: { interivewId: interviewId, userEmail: userEmail }
});

export const fetchVideoSuccess = data => ({
    type: VIDEO_FETCH_SUCCESS,
    payload: { data: data }
});
    
export const fetchVideoFailure = error => ({
    type: VIDEO_FETCH_FAILURE,
    payload: { error }
});

export function fetchVideo(token, interviewId,userEmail,method = 'GET') {
    return dispatch => {
        dispatch(fetchVideoBegin({interviewId: interviewId, userEmail: userEmail}));
        return fetch(`${VIDEO_URL}/api/interviews/${interviewId}/answers?candidate=${userEmail}`, {
            method: `${method}`,
            headers: {
                Accept: 'application/json',
                "Content-Type": `application/json`,
                'Authorization': `Token ${token}`
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then(json => {
            dispatch(fetchVideoSuccess(json));
            return json
        })
        .catch(error => {
            dispatch(fetchVideoFailure(error));
            return Promise.resolve();
        })
    };
}

export const rateVideoBegin = (rating, answerId) => ({
    type: VIDEO_RATE_BEGIN,
    payload: { rating: rating, answerId: answerId }
});

export const rateVideoSuccess = (data, answerId, rating) => ({
    type: VIDEO_RATE_SUCCESS,
    payload: { data: data, rating: rating, answerId: answerId }
});

export const rateVideoFailure = error => ({
    type: VIDEO_RATE_FAILURE,
    payload: { error }
});

export function rateVideo(token, answerId,rating,method = 'PATCH') {
    return dispatch => {
        dispatch(rateVideoBegin({rating: rating, answerId: answerId}));
        return fetch(`${VIDEO_URL}/api/answers/${answerId}`, {
            method: `${method}`,
            headers: {
                Accept: 'application/json',
                "Content-Type": `application/json`,
                'Authorization': `Token ${token}`
            },
            body: `${JSON.stringify({answer: {rating: rating } })}`
        })
        .then(handleErrors)
        .then(res => res.json())
        .then(json => {
            dispatch(rateVideoSuccess(json, answerId, rating));
        })
        .catch(error => {
            dispatch(rateVideoFailure(error));
            return Promise.resolve();
        })
    };
}
// Handle HTTP errors since fetch won't.
function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}