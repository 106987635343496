import React from 'react';

import "../../pdf-css.scss";
import { Row, Col } from 'antd';
import { genderOptions } from '../../../../../constants/personalUser';

const Traits = ({ candidates }) => {
  return (
    <Row className='trait-container'>
      {['Edad', 'Género', 'Nivel educacional', 'Discapacidad'].map(
        (rasgos, index) => (
          <Row gutter={[0, 12]}>
            <Col key={index} span={24}>
              <Col span={6} className='trait-container trait-container--title'>
                <p>{rasgos}</p>
              </Col>
              <Col
                span={18}
                className='trait-container trait-container--description'
              >
                <Row type='flex' justify='space-around'>
                  {rasgos === 'Edad' &&
                    candidates.map((item) => (
                      <Row type='flex' justify='center' key={item.id}>
                        <p>{item.age}</p>
                      </Row>
                    ))}
                  {rasgos === 'Género' &&
                    candidates.map((item) => (
                      <Row key={item.id} type='flex' justify='center'>
                        <p>
                          {genderOptions[item.gender]}
                        </p>
                      </Row>
                    ))}
                  {rasgos === 'Nivel educacional' &&
                    candidates.map((item) => (
                      <Row key={item.id} type='flex' justify='center'>
                        {item.education_level.length > 12 ? (
                          <p>
                            {item.education_level.slice(0, 8)}
                            <br />
                            {item.education_level.slice(8, 20)}
                            <br />
                            {item.education_level.slice(20)}
                          </p>
                        ) : item.education_level.length === 0 ? (
                          <p>Sin datos</p>
                        ) : (
                          <p>{item.education_level}</p>
                        )}
                      </Row>
                    ))}
                  {rasgos === 'Discapacidad' &&
                    candidates.map((item) => (
                      <Row key={item.id} type='flex' justify='center'>
                        <p>{item.disability_type}</p>
                      </Row>
                    ))}
                </Row>
              </Col>
            </Col>
          </Row>
        )
      )}
    </Row>
  );
};

export default Traits;
