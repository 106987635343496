import React from "react";
import { connect } from "react-redux";
import JobStage from "../../../../../../../containers/JobApplicationProcess/candidatepath/candidatepathcontent/candidatestage/JobStage/JobStage";
import { Card, Col, Row, Typography } from 'antd';

const { Title } = Typography;

class RightSide extends React.Component {

  groupBy = (xs, key) => {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  render() {
    const tmpAssignedActivities = { ...this.props.assignedActivities };
    // use current cut not this.props.assignedActivities['CUTE'] 
    if (this.props.currentCustomTests !== undefined) {
      this.props.currentCustomTests.forEach((obj, idx) => {
        tmpAssignedActivities[`CUTE${idx}`] = {
          activity: obj.activity,
          code: obj.code,
          configuration: JSON.stringify(obj.configuration),
          stage: obj.stage,
          icon: 'cute.svg',
          // TODO, replace with a true estimated time, global time if existe, sum of question time if not global time, 1 by default
          time_estimated: 1

        };
      });
    }
    else if (this.props.currentCustomTests === undefined && tmpAssignedActivities['CUTE'] !== undefined) {
      tmpAssignedActivities['CUTE'].forEach((obj, idx) => {
        tmpAssignedActivities[`CUTE${idx}`] = {
          activity: obj.activity,
          code: obj.code,
          configuration: JSON.stringify(obj.configuration),
          stage: obj.stage,
          icon: 'cute.svg',
          // TODO, replace with a true estimated time, global time if existe, sum of question time if not global time, 1 by default
          time_estimated: 1
        };
      });
    }

    // delete the cute key, the data from the assigned activities endpoint, contains an array of cutes not necesary up to date if the user
    // added o removed a cute
    delete tmpAssignedActivities['CUTE'];

    Object.keys(tmpAssignedActivities).forEach(key => tmpAssignedActivities[key]['preview'] = true)
    Object.keys(tmpAssignedActivities).forEach(key => tmpAssignedActivities[key]['progress'] = { status: "INITIAL" })

    Object.keys(tmpAssignedActivities).forEach((elem) => {
        if (tmpAssignedActivities[elem]['stage'] === 1){
          tmpAssignedActivities[elem]['stage'] = 'ACTIV';
        }
    });
    const stages = this.groupBy(Object.values(tmpAssignedActivities), 'stage')

    return (
      <Card className='cp-view cp-view-old'>
        <Row
          type='flex'
          justify='center'
          className='cp-view__headline'
        >
          <Col xs={24} md={18} lg={15}>
            <Title level={3}>
              Resumen de la ruta del candidato
            </Title>

            {Object.keys(stages).sort((a, b) => {
              // ACTIV always should be the first value
              if (a === "ACTIV") {
                return -1
              }
              if (b === "ACTIV") {
                return 1
              }
              else {
                return a.localeCompare(b)
              }
            }).map((stage, i) => {

              return (
                <JobStage
                  key={i}
                  stage={stage}
                  activities={stages[stage]}
                  percentage={0}
                />
              )
            })}
          </Col>
        </Row>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    assignedActivities: state.jobapp.assignedActivities
  }
}

export default connect(mapStateToProps)(RightSide);
