import React, { useContext } from 'react';
import {
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  Modal,
  Row,
  Select,
  Typography,
} from 'antd';
import MailTemplatesContext from '../context/mailTemplates';
import NoOptionsSelect from './NoOptionsSelect';

const { Title } = Typography;
const { Search } = Input;
const { Option } = Select;
const { Item } = Menu;

const FilterBar = () => {
  const { filterBar, isActive } = useContext(MailTemplatesContext);

  const {
    onChangeSearch,
    inputSearch,
    filterTemplatesByTagsFn,
    filteredTags,
    tagsValue,
    //filterTemplatesByOwnerFn,
    //filteredCreator,
    createVisible,
    setCreateVisible,
    categories,
    //onChangeSwitchAllCategories,
    selectCreateCategoryFn,
    categoryValue,
    setCategoryValue,
    createCategory,
    onSearch,
  } = filterBar;

  return (
    <>
      <Modal
        title='Nueva categoría de plantillas'
        visible={createVisible}
        footer={null}
        centered={true}
        onCancel={() => setCreateVisible(false)}
      >
        <Row style={{ padding: '0.5rem' }}>
          <Input
            placeholder='Por favor ingrese un nombre para su nueva categoría'
            value={categoryValue}
            onChange={(e) => setCategoryValue(e.target.value)}
          />
          <Button
            type='primary'
            style={{ marginTop: '0.8rem' }}
            onClick={createCategory}
          >
            Crear nueva categoría
          </Button>
        </Row>
      </Modal>
      <Row
        type='flex'
        justify='space-between'
        align='middle'
        className='template-container__create-button'
      >
        <Col span={12}>
          <Row type='flex'>
            <Search
              value={inputSearch}
              placeholder='Buscar por nombre o asunto'
              onPressEnter={onSearch}
              onChange={onChangeSearch}
              onSearch={onSearch}
              style={{ width: 300, marginRight: '20px' }}
              disabled={!isActive?.id}
            />
            <Select
              style={{ width: 210 }}
              placeholder='Etiquetas'
              onChange={filterTemplatesByTagsFn}
              allowClear={true}
              value={tagsValue.tags}
              dropdownRender={(menu) =>
                filteredTags.filterTags[0] !== '' ? menu : <NoOptionsSelect />
              }
              disabled={!isActive?.id}
            >
              {filteredTags.filterTags.map((tag, index) => (
                <Option value={tag} key={index}>{tag}</Option>
              ))}
            </Select>

            {/* TODO: ? */}
            {/* <Select
              style={{ width: 130 }}
              placeholder='Creado por'
              allowClear={true}
              onChange={filterTemplatesByOwnerFn}
              value={tagsValue.owner}
            >
              {filteredCreator.filterCreator.map((creator, index) => (
                <Option value={creator} key={index}>
                  {creator}
                </Option>
              ))}
            </Select> */}
          </Row>
        </Col>
        <Col span={12}>
          <Row type='flex' justify='end'>
            <Button
              onClick={() => setCreateVisible(true)}
              style={{ marginRight: '20px' }}
            >
              Crear categoría
            </Button>
            <Dropdown
              placement='bottomRight'
              overlay={
                <Menu>
                  {categories.data?.map(({ name, id }) => (
                    <Item onClick={() => selectCreateCategoryFn(id)} key={id}>
                      En {name}
                    </Item>
                  ))}
                </Menu>
              }
              trigger={['click']}
              overlayClassName='template-container__dropdown-menu'
            >
              <div onClick={(e) => e.preventDefault()}>
                <Button type='primary'>Crear plantilla</Button>
              </div>
            </Dropdown>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default FilterBar;
