import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { withStyles } from "@material-ui/core/styles";
import { ClickAwayListener } from "@material-ui/core";
import classNames from "classnames";

import { Tag, Typography, Tooltip, Popover, Modal, Button } from "antd";
import i18n from "../../../../../../../../i18n";

const badgeLockedIMG = 'https://genoma-assets.s3.us-east-2.amazonaws.com/badgeLocked.png'
const coinIMG = 'https://genoma-assets.s3.us-east-2.amazonaws.com/coinWithoutSign.png';

const { Title, Text } = Typography

const styles = theme => ({
    outerCircle: {
        position: "relative",
        width: 110,
        height: 110,
        borderRadius: "100%",
        margin: "0 auto",
        cursor: "pointer",
        filter: 'drop-shadow(2px 4px 6px #6c717b73)',
    },
    outerCirclrPlayable: {
        '&:hover': {
            filter: 'drop-shadow(2px 4px 6px #6c717b73) brightness(0.9)'
        },
    },
    smallHeart: {
        zIndex: 999,
        position: "absolute",
        width: 42,
        height: 42,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        textAlign: "center",
        boxSizing: "border-box",
        right: 0,
        bottom: 8,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    levels: {
        color: '#995B3D',
        fontWeight: 900,
        fontSize: '13px',
    },
    icon: {
        height: 95,
        padding: '3px',
        borderRadius: '100%',

        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        width: '92%',
        margin: 'auto',
        display: 'flex',
        objectFit: 'contain',
    },
    aborted: {
        filter: 'grayscale(100%)',
    },
    tooltip: {
        fontSize: 14,
    },
});

const jsPsyched = ['TOWR', 'STRP', 'DGTS', 'GNG']
const failSafe = ['TNUM', 'TLET', 'STRP', 'DGTS']

class Activity extends React.Component {

    state = {
        colorIcon: null,
        grayIcon: null,
        open: false,
        content: '',
        visibleLockedModal: false
    }

    handleClick = event => {
        const personalForm = this.props.items.find(act => act.code === 'FORM');
        const knockoutQuestion = this.props.items.find(act => act.code === 'KO');

        // Esto esta muy feo con tantos ifs, me da un poco de cringe
        const jobappId = this.props.jobappId
        const code = this.props.code
        let url = `/jobapplication/${jobappId}/${code}`
        
        // lastest IF for interviews
        if (code === 'INTR') {
            this.props.dispatch(push(`/jobapplication/${jobappId}/interview/${this.props.personal_interview_id}`))
            return;
        }

        if (code === 'VINT') {
            window.open(`${process.env.REACT_APP_VIDEO_FRONT_URL}/processes/${this.props.video_interview_url}/`)
            return;
        }

        if (code === 'CUTE') {
            const assActId = this.props.id;
            const cuteId = JSON.parse(this.props.configuration).id;
            const assActPP = this.props.progress.id;
            url = `${url}?cuteId=${cuteId}&assActId${assActId}&assActPP=${assActPP}`
        }
        if (this.props.category === 'INITIAL') {
            if (jsPsyched.includes(code) || failSafe.includes(code)) {
                window.location.assign(url)
                return
            } else {
                this.props.dispatch(push(url))
            }
        }
        if (knockoutQuestion) {
            if (knockoutQuestion.status !== 'DONE' && this.props.category === 'OTHER') {
                const jobappId = this.props.jobappId
                const code = this.props.code
                // const url = `/jobapplication/${jobappId}/${code}`
                if (jsPsyched.includes(code) || failSafe.includes(code)) {
                    window.location.assign(url)
                } else {
                    this.props.dispatch(push(url))
                }
                return
            }

            if ((personalForm && personalForm.status !== 'DONE') || knockoutQuestion.status !== 'DONE') {
                this.setState({
                    open: true,
                    content: i18n.t('candidate_path__activity__questions__filled')
                });
                return
            }
        }

        if (personalForm && personalForm.status !== 'DONE') {
            this.setState({
                open: true,
                content: i18n.t('candidate_path__activity__data__filled')
            });
        }
        else if (this.props.status === 'DONE') {
            this.setState({
                open: true,
                content: <div style={{ display: 'flex' }}><img style={{ marginRight: '15px' }} width='35' src='https://image.flaticon.com/icons/svg/190/190411.svg' /> <div>{i18n.t('candidate_path__game__completed')}</div></div>
            });
        } else if (this.props.status === 'ABORTED' || this.props.status === 'PENDING') {
            this.setState({
                visibleLockedModal: true,
                content: i18n.t('candidate__path__new__locked__text')
            });
        } 
        else if (this.props.preview) {
            return null
        } else {
            const jobappId = this.props.jobappId
            const code = this.props.code
            const url = `/jobapplication/${jobappId}/${code}`
            if (jsPsyched.includes(code) || failSafe.includes(code)) {
                window.location.assign(url)
            }
            else {
                const assActId = this.props.id;
                const cuteId = JSON.parse(this.props.configuration).id;
                const assActPP = this.props.progress.id;

                if (this.props.code === 'CUTE' && assActId !== undefined && cuteId !== undefined) {
                    this.props.dispatch(push(`${url}?cuteId=${cuteId}&assActId=${assActId}&assActPP=${assActPP}`))
                }
                else {
                    this.props.dispatch(push(url))
                }
            }
        }
    };

    handleClose = () => {
        this.setState({
            open: false,
        });
    };

    handleCancelModal = () => {
        this.setState({ visibleLockedModal: false })
    }

    getIconStatus = (levelOneIsCompleted, status, icon, code) => {
        if (code === 'CUTE'){
            return 'icon'
        }
        return status == "ABORTED" || levelOneIsCompleted && icon !== 'FORM' && icon !== 'KO' ? 'aborted icon' : 'icon'
    }

    render() {
        const { businessSlug, colorTheme, classes, status, icon, time_estimated, preview, items, prvw, code } = this.props;
        const { open, content } = this.state;
        const onClickProps = { PopperProps: { disablePortal: true }, onClose: this.handleClose, visible: open, disableFocusListener: true, disableHoverListener: true, disableTouchListener: true, content: <Text strong>{content}</Text> }
        const onHoverProps = { disableFocusListener: true, disableTouchListener: true, title: `Duración aprox: ${time_estimated} min` }
        const tooltipProps = (status === 'PLAYABLE' || preview) ? onClickProps : onClickProps
        const levelOneIsCompleted = items.filter(e => e.code === 'FORM' || e.code === 'KO').map(({ status }) => status).includes('PLAYABLE')
        return (
            ((code !== "ALST") && (code !== "ALSP")) ?
                <React.Fragment>
                    <ClickAwayListener onClickAway={this.handleClose}>
                        <Popover
                            classes={{ tooltip: classes.tooltip }}
                            {...tooltipProps}
                            placement='bottom'
                            overlayClassName='activity-popover'
                        >
                            
                            <div className={classNames(status === 'PLAYABLE' || preview ? 'outerCirclrPlayable' : 'outerCircle', prvw && 'prvw')} onClick={this.handleClick}>
                                <div className='hexagono' style={{ position: 'absolute', zIndex: 99 }}>
                                    <img style={{ width: '100%' }} src='https://i.imgur.com/tQCIULu.png' />
                                </div>
                                
                                <img
                                    src={icon === 'FORM' ? 'https://i.imgur.com/IcMX6cs.png' : icon === 'KO' ? 'https://i.imgur.com/EUXTknN.png' : icon.includes('.svg') || icon.includes('.png') ? `https://genoma-assets.s3.us-east-2.amazonaws.com/${icon}` : `https://genoma-assets.s3.us-east-2.amazonaws.com/${icon}.png`}
                                    className={this.getIconStatus(levelOneIsCompleted, status, icon, code)}
                                />
                                

                                <div
                                    className={
                                        code !== 'CUTE' ? (
                                            status !== "DONE" ? 'aborted smallHeart' : 'smallHeart'
                                        ) : (
                                            ['PLAYABLE', 'INITIAL', 'ABORTED', 'PENDING'].includes(status) ? 'aborted smallHeart' : 'smallHeart'
                                        )
                                    }
                                    style={{ backgroundImage: `url(${coinIMG})` }}
                                >
                                    {time_estimated ? <span className='coins'>x{time_estimated}</span> : null}
                                </div>

                            </div>
                        </Popover>
                    </ClickAwayListener>
                    <div>
                        
                        <Text strong className='labelIcon'>       
                            {/* {
                                this.props.activity === undefined && (code !== 'CUTE' || code !== 'INTR') ? (<span>{typeof this.props.translation !== 'undefined' ? this.props.translation[i18n.language.split('-')[0]] : this.props.activity}</span>) : null
                            } */}
                            {
                                
                            (typeof this.props.translation !== 'undefined' ? this.props.translation[i18n.language.split('-')[0]]?.includes('Mobile') : (this.props.activity && this.props.activity.includes('Mobile'))) ? (
                              <span>
                                <div>{typeof this.props.translation !== 'undefined' ? this.props.translation[i18n.language.split('-')[0]]?.replace('Mobile', '') : this.props.activity &&  this.props.activity.replace('Mobile', '')}</div>
                                {this.props.activity.toLowerCase().includes('letras') ? (
                                  <div>
                                    <Tooltip
                                      color="#ea4c896b"
                                      className='mobile-tag'
                                    >
                                      *{i18n.t('commons__process__language')}
                                    </Tooltip>
                                  </div>
                                ) : ''}
                                <div>
                                  <Tag
                                    color={['banco-internacional'].includes(businessSlug) ? colorTheme.background : "#ea4c896b"}
                                    className='mobile-tag'
                                  >
                                    Mobile
                                  </Tag>
                                </div>
                              </span>
                            ) : code === 'CUTE' ? JSON.parse(this.props.configuration).name : (typeof this.props.translation === 'undefined' ? this.props.activity : this.props.translation[i18n.language.split('-')[0]])}
                        </Text>
                    </div>

                    <Modal
                        centered
                        className='lockedGameModal'
                        visible={this.state.visibleLockedModal}
                        onOk={this.handleCancelModal}
                        onCancel={this.handleCancelModal}
                        footer={[
                            <Button key="submit" type="primary" onClick={this.handleCancelModal}>
                               {i18n.t('candidate__path__understand')}
                            </Button>
                        ]}
                    >
                        <div className='content'>
                            <Title level={3}>{i18n.t('candidate__path__locked')}</Title>
                            <img src={badgeLockedIMG} /> <br />
                            <Text>{this.state.content}</Text>
                        </div>
                    </Modal>
                </React.Fragment>
                :
                <></>

        );
    }
};

Activity.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        jobappId: state.data.data.jobapp_id,
        items: state.data.data.items
    }
}

const mapActionsToProps = (dispatch) => {
    return {
        dispatch,
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(Activity));
