import fetch from 'isomorphic-fetch';
import * as Sentry from '@sentry/browser';
import { push } from 'react-router-redux';
import { SERVER_URL } from '../utils/config';
import { checkHttpStatus, parseJSON } from '../utils';
import { alertActions } from './alerts';
import {
    AUTH_LOGIN_USER_REQUEST,
    AUTH_LOGIN_USER_FAILURE,
    AUTH_LOGIN_USER_SUCCESS,
    AUTH_FIRST_LOGIN_BUSINESSUSER_SUCCESS,
    AUTH_SOCIAL_LOGIN_USER_REQUEST,
    AUTH_SOCIAL_LOGIN_USER_FAILURE,
    AUTH_SOCIAL_LOGIN_USER_SUCCESS,
    AUTH_LOGOUT_USER,
    AUTH_SIGNUP_USER_REQUEST,
    AUTH_SIGNUP_USER_FAILURE,
    AUTH_SIGNUP_USER_SUCCESS,
    AUTH_SIGNUP_USER_ALREADY_EXISTS,
    AUTH_GO_BACK,
    LOGO_UPDATE,
    LOGO_RESET,
    UPDATE_EMAIL,
    UPDATE_TOS,
    UPDATE_AUTH_DATA,
    UPDATE_NOTIFICATION_COUNT,
} from '../constants';
import { getProviders } from '../constants/socialAuth';
import i18n from '../i18n';

export function authSocialLoginUserSuccess(token, user, socialAuth) {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    return {
        type: AUTH_SOCIAL_LOGIN_USER_SUCCESS,
        payload: {
            token,
            user,
            socialAuth,
        }
    };
}

export function authLoginUserSuccess(token, user) {
    // TODO: Separar logins de PersonalUser y BusinessUser,
    // tanto en frontend como backend

    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));

    // Si usuario que inicia sesión es de tipo PersonalUser,
    // o si es BusinessUser pero sin contrato activo, se agrega el
    // atributo active_contract a 'user', para evitar problemas
    // más adelante en el reducer
    if (user.user_type === 'business') {
        if (!user.active_contract) {
            user.active_contract = {
                addons: [],
                contract_type: null,
                max_applications: null,
                max_candidates_per_jobapp: null,
                max_subordinates: null,
                service_detail: '',
                service_expiration_date: null,
                service_start_date: null,
                status: null,
            }
        } else if (!user.active_contract.addons) {
            user.active_contract.addons = [];
        }
    } else {
        user.active_contract = { addons: [] }
    }
    return {
        type: AUTH_LOGIN_USER_SUCCESS,
        payload: {
            token,
            user,
        }
    };
}

export function authBusinessUserFirstLoginSuccess(name) {
    return {
        type: AUTH_FIRST_LOGIN_BUSINESSUSER_SUCCESS,
        payload: { name }
    }
}

export function authLoginUserFailure(error, message) {
    localStorage.removeItem('token');
    return {
        type: AUTH_LOGIN_USER_FAILURE,
        payload: {
            status: error,
            errors: message
        }
    };
}

export function authSocialLoginUserFailure(error, message) {
    localStorage.removeItem('token');
    return {
        type: AUTH_SOCIAL_LOGIN_USER_FAILURE,
        payload: {
            status: error,
            errors: message,
        }
    };
}

export function authLoginUserRequest() {
    return {
        type: AUTH_LOGIN_USER_REQUEST
    };
}

export function authSocialLoginUserRequest(provider='', email='', jobappid='', token='', newCandidate=true) {
    return {
        type: AUTH_SOCIAL_LOGIN_USER_REQUEST,
        payload: {
            provider: provider,
            email: email,
            jobappid: jobappid,
            token: token,
            newCandidate: newCandidate,
        }
    };
}

export function authSignupUserRequest() {
    return {
        type: AUTH_SIGNUP_USER_REQUEST
    };
}

export function authSignupUserFailure(error, message) {
    return {
        type: AUTH_SIGNUP_USER_FAILURE,
        payload: {
            status: error,
            errors: message
        }
    };
}

export function authSignupUserAlreadyExists(censoredmail, userid, errors) {
    return {
        type: AUTH_SIGNUP_USER_ALREADY_EXISTS,
        payload: {
            censoredmail: censoredmail,
            userid: userid,
            errors: errors
        }
    };
}

export function authSignupUserSuccess() {
    return {
        type: AUTH_SIGNUP_USER_SUCCESS,
    };
}

export function authLogoutSuccess(message) {
    return {
        type: AUTH_LOGOUT_USER,
        payload: {
            errors: message
        }
    };
}

export function authRedirect(url) {
    return (dispatch) => {
        dispatch(push(url));
        return Promise.resolve();
    };
}

export function authClean() {
    return {
        type: AUTH_GO_BACK
    };
}

export function authGoBack() {
    return (dispatch) => {
        dispatch(alertActions.clean());
        dispatch(authClean());
        return Promise.resolve();
    };
}

export const updateLogo = logo_url => ({
    type: LOGO_UPDATE,
    payload: { logo_url: logo_url }
});

export const resetLogo = () => ({
    type: LOGO_RESET,
});
/**
 * Función para hacer logout de la plataforma. Lo que hace es borrar el token del localstorage
 * y al usuario. En caso que haya existido un usuario logueado lo que hace es hacer POST a
 * la API para borrar el token del backend.
 *
 * En caso que salga todo bien con la API o que no haya existido un usuario, se despacha
 * la acción que todo salió bien y se muestra el mensaje y se redirecciona al usuario.
 *
 * @param {string} token: Token del usuario que está guardado en el localstorage
 * @param {string} url: Path a la que después se redirecciona al usuario
 * @param {Object} message: Mensaje que se muestra al usuario
 */
export function authLogout(token, url = '/', message = {}) {
    return async (dispatch) => {
        const tkn = localStorage.getItem('token') != null;
        localStorage.removeItem('token');
        localStorage.removeItem('user');

        if (tkn) {
            try {
                await fetch(`${SERVER_URL}/api/v1/accounts/logout/`, {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Token ${token}`,
                    }
                });
                dispatch(authLogoutSuccess(message));
                dispatch(resetLogo());
                dispatch(push(url));
            } catch (err) {
                console.error('Ocurrió un error', err);
                dispatch(authLogoutSuccess(message));
                dispatch(push(url));
            }

        } else {
            dispatch(authLogoutSuccess(message));
            dispatch(push(url));
        }
    };
}


/**
 * This function replace btoa(), it works with latin-1 enconde and ISO encode eg: supports ñ
 * @param {*} str - str to encode
 */
function b64EncodeUnicode(str) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
    }));
}


/**
 * Función para hacer login dentro de la aplicación. Lo que hace primero es hacer dispatch
 * que el usuario está intentando hacer login (se emite una acción). Después se setea el
 * token y se envía en los headers para intentar hacer login haciendo POST a la API.
 *
 * Una vez retornada la respuesta de la API lo que se hace es parsearla (pasandola a
 * JSON) y si no falla se emite la acción que salió todo bien y se guarda al usuario y el token
 * de este y se redirecciona a la página recibida en los argumentos.
 *
 * @param {string} email: Email del usuario que está intentando hacer login
 * @param {string} password: Contraseña del usuario que está intentando loguearse
 * @param {string} redirect: Path de la ruta que después a la que se rediccionará
 * @param {*} id
 */
export function authLoginUser(email, password, redirect = '/', body = {}, id) {
    return async (dispatch) => {
        try {
            dispatch(authLoginUserRequest());
            const auth = b64EncodeUnicode(`${email}:${id}:${password}`);
            let response;
            // MFA request
            if (body && body.otp){
                response = await fetch(`${SERVER_URL}/api/v1/accounts/otpauthentication/`, {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Basic ${auth}`
                    },
                    body: JSON.stringify(body)
                });
            }
            // Normal login
            else{
                response = await fetch(`${SERVER_URL}/api/v1/accounts/login/`, {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Basic ${auth}`
                    },
                });
            }
            checkHttpStatus(response);
            response = await parseJSON(response);
            if (response.token === null){
                return response
            }
            if (response.social_accounts) {
                const providers = getProviders(response.social_accounts)
                let aux = '';
                if (response.social_accounts.length >= 1) {
                    aux = ` ${i18n.t('login__auth__error_reminder_social_accounts', {providers: providers})}`
                }
                let msg = '';
                if (!response.default_password) {
                    msg += i18n.t('login__auth__error')
                } 
                msg += aux
                dispatch(authLoginUserFailure(401, { password: msg }));
                dispatch(alertActions.error(msg));
                throw '401'
            } else {
                dispatch(
                    authLoginUserSuccess(
                        response.token,
                        response.user,
                    )
                );
                if (response.user.user_type === 'business') {
                    dispatch(updateLogo(response.user.img_user));
                }
                redirect && dispatch(push(redirect));
            return response;
            }
        } catch (error) {
            handleGenericErrors(dispatch, error);
            return Promise.reject();
        }
    };
}

export function authLinkedInErrorReceived() {
    return (dispatch) => {
        const msg = {
            socialCodeError: 'No fue posible iniciar sesión con LinkedIn',
        }
        dispatch(authSocialLoginUserFailure(500, msg))
    };
}

export function authGoogleErrorReceived() {
    return (dispatch) => {
        const msg = {
            socialCodeError: 'No fue posible iniciar sesión con Gmail',
        }
        dispatch(authSocialLoginUserFailure(500, msg))
    };
}

/**
 * Función para login con LinkedIn. Lo que hace primero es hacer dispatch
 * que el usuario está intentando hacer login con LinkedIn (se emite una acción).
 * Después, hace una request al endpoint del back encargado del login con LinkedIn,
 * enviando el "code" obtenido desde la API de LinkedIn, para que el endpoint
 * pueda usarlo al consultar la API. Si el back responde que se requiere DNI
 * puede ser por dos motivos: no existe un PersonalUser asociado al correo
 * recibido al consultar la API de LinkedIn desde el back, o bien existe el PersonalUser,
 * pero jamás ha iniciado sesión con LinkedIn.
 * 
 * En el primer caso, se pide al usuario que ingrese sus datos para registrarse.
 * En el segundo caso, se pide solo su DNI, para corroborar su identidad y asociar
 * el PersonalUser a una instancia de SocialAccount, lo que le permitirá ingresar
 * facilmente con LinkedIn en el futuro.
 *
 * @param {string} redirect: Path de la ruta que después a la que se rediccionará
 * @param {string} code: código para acceder a la API de LinkedIn
 * @param {string} jobappid: id del proceso al que se quiere ingresar, en caso de estar 
 * iniciando sesión para ingresar a un proceso
 */
export function authLinkedInLoginUser(redirect='/', code='', jobappid='') {
    return async (dispatch) => {
        try {
            dispatch(authSocialLoginUserRequest('LI', null, jobappid, code));
            // OJO que code != token de autenticación que se obtendrá (para el caso
            // específico de LinkedIn) después desde el back, 
            // TODO: hay que cambiarlo en el reducer después, una vez que el back responda con éxito al login y entregue accessToken
            let response = await fetch(`${SERVER_URL}/api/v1/accounts/login/linkedin/`, {
                method: 'post',
                body: JSON.stringify({
                    code: code,
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            checkHttpStatus(response);
            response = await parseJSON(response);
            if (jobappid !== '') {
                if (response.dni_required) {
                    // SocialAccount does not exist

                    // Si en response la llave user viene con valor asociado null, significa que no existe
                    // un PersonalUser registrado con el mail asociado a la red social elegida, por lo tanto
                    // el candidato es nuevo y debe registrarse. Si no es nuevo pero es primera vez que
                    // ingresa con la red social elegida, debe ingresar su rut solo por esta vez, para 
                    // corroborar su identidad
                    const newCandidate = response.user ? false : true
                    dispatch(authSocialLoginUserRequest('LI', response.social_email, jobappid, code, newCandidate)) //dispatch this again to save email in redux
                    dispatch(push(`/signup/${jobappid}`))
                    return false;
                } else {
                    // Candidate had already logged in with LinkedIn succesfully in the past
                    const socialAuth = {
                        email: response.social_email, //email asociado a cuenta de LinkedIn,
                        provider: 'LI',
                        jobAppId: jobappid,
                        tokenObj: code, // code used to consult LinkedIn's API and get access tokens
                        newCandidate: false,
                    }
                    dispatch(authSocialLoginUserSuccess(response.token, response.user, socialAuth));
                    if (redirect){
                        dispatch(push(redirect));
                    }
                }
            } else {
                if (response.dni_required && !response.user) {
                    // Si no está ingresando a través de un proceso y no existe SocialAccount ni 
                    // PersonalUser, no puede crearse
                    const msg = {
                        emailNotFound: i18n.t('login__mail__error__gmail'),
                    }
                    dispatch(authSocialLoginUserFailure(401, msg))
                } else if (response.dni_required && response.user) {
                    // Si no está ingresando a través de un proceso y existe PersonalUser pero no
                    // SocialAccount, se le solicita el rut solo por esta vez, para 
                    // corroborar su identidad
                    dispatch(authSocialLoginUserRequest('LI', response.social_email, jobappid, code, false)) //dispatch this again to save email in redux
                    dispatch(push(`/confirmDNI?next=/me`))
                } else {
                    // Ya había iniciado sesión con LinkedIn en Genoma en el pasado
                    const socialAuth = {
                        email: response.social_email, //email asociado a cuenta de LinkedIn,
                        provider: 'LI',
                        jobAppId: jobappid,
                        tokenObj: code, // code used to consult LinkedIn's API and get access tokens
                        newCandidate: false,
                    }
                    dispatch(authSocialLoginUserSuccess(response.token, response.user, socialAuth));
                    if (redirect){
                        dispatch(push(redirect));
                    }
                }
            }
        } catch (error) {
            if (error.response.status === 401)
            {
                // Este error significa que el rut no coincide con el correo de la cuenta ingresada
                const msg = {
                    dniNotFound: i18n.t('start__process__DNI__error__unmatch')
                }
                dispatch(authSocialLoginUserFailure(error, msg))
            }
        }
        return true;
    };
}

/**
 * Función para login con Gmail. Lo que hace primero es hacer dispatch
 * que el usuario está intentando hacer login con Gmail (se emite una acción).
 *
 * @param {string} redirect: Path de la ruta que después a la que se rediccionará
 * @param {string} email: Correo Gmail
 * @param {string} tokenObj: Objeto con access_token para acceder a la API de Google
 * @param {string} jobappid: Id del proceso al que se quiere ingresar, en caso de estar 
 * iniciando sesión para ingresar a un proceso
 */
export function authGoogleLoginUser(redirect='/', email='', tokenObj={}, jobappid='') {
    return async (dispatch) => {
        try {
            dispatch(authSocialLoginUserRequest('GM', null, jobappid, tokenObj));

            let response = await fetch(`${SERVER_URL}/api/v1/accounts/login/gmail/`, {
                method: 'post',
                body: JSON.stringify({
                    access_token: tokenObj,
                    email: email,
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            checkHttpStatus(response);
            response = await parseJSON(response);
            if (jobappid !== '') {
                // TODO:
                // console.log("AUTH GOOGLE LOGIN USER ACTION - WITH JOBAPPID")
                // console.log(jobappid)
                // console.log(response)
                if (response.dni_required) {
                    // SocialAccount does not exist

                    // Si en response la llave user viene con valor asociado null, significa que no existe
                    // un PersonalUser registrado con el mail asociado a la red social elegida, por lo tanto
                    // el candidato es nuevo y debe registrarse. Si no es nuevo pero es primera vez que
                    // ingresa con la red social elegida, debe ingresar su rut solo por esta vez, para 
                    // corroborar su identidad
                    const newCandidate = response.user ? false : true;
                    dispatch(authSocialLoginUserRequest('GM', response.social_email, jobappid, tokenObj, newCandidate)) //dispatch this again to save email in redux
                    dispatch(push(`/signup/${jobappid}`))
                    return false;
                } else {
                    // Candidate had already logged in with Gmail succesfully in the past
                    const socialAuth = {
                        email: response.social_email, //email asociado a cuenta de Gmail,
                        provider: 'GM',
                        jobAppId: jobappid,
                        tokenObj: tokenObj, // code used to consult LinkedIn's API and get access tokens
                        newCandidate: false,
                    }
                    dispatch(authSocialLoginUserSuccess(response.token, response.user, socialAuth));
                    if (redirect) {
                        dispatch(push(redirect));
                    }
                }
            } else {
                if (response.dni_required && !response.user) {
                    // Si no está ingresando a través de un proceso y no existe
                    // SocialAccount ni PersonalUser, no puede crearse
                    const msg = {
                        emailNotFound: i18n.t('login__mail__error__gmail'),
                    }
                    dispatch(authSocialLoginUserFailure(401, msg))
                } else if (response.dni_required && response.user) {
                    // Si no está ingresando a través de un proceso y existe 
                    // PersonalUser pero no SocialAccount, se le solicita el rut
                    // solo por esta vez, para corroborar su identidad
                    dispatch(authSocialLoginUserRequest('GM', response.social_email, jobappid, tokenObj, false)) //dispatch this again to save email in redux
                    dispatch(push(`/confirmDNI?next=/me`))
                } else {
                    // Ya había iniciado sesión con Gmail en Genoma en el pasado
                    const socialAuth = {
                        email: response.social_email, //email asociado a cuenta de LinkedIn,
                        provider: 'GM',
                        jobAppId: jobappid,
                        tokenObj: tokenObj, // code used to consult LinkedIn's API and get access tokens
                        newCandidate: false,
                    }
                    dispatch(authSocialLoginUserSuccess(response.token, response.user, socialAuth));
                    if (redirect){
                        dispatch(push(redirect));
                    }
                }
            }
            
        } catch (error) {
            if (error.response.status === 401)
            {
                // Este error significa que el rut no coincide con el correo de la cuenta ingresada
                const msg = {
                    dniNotFound: i18n.t('start__process__DNI__error__unmatch')
                }
                dispatch(authSocialLoginUserFailure(error, msg))
            }
        }
        return true;
    }
}

export const updateEmail = email => ({
    type: UPDATE_EMAIL,
    payload: { updatedEmail: email }
});

export function updateEmailAcc(email) {
    return async (dispatch) => {
        dispatch(updateEmail(email));
    };
}

export const updateTos = tos => ({
    type: UPDATE_TOS,
    payload: { acceptedTos: tos }
});

export function updateAcceptedTos(acceptedTos) {
    return async (dispatch) => {
        dispatch(updateTos(acceptedTos));
    };
}

export const updateAuthData = data => ({
    type: UPDATE_AUTH_DATA,
    // data = {keyName: "", value: ""}
    payload: data
});

export const updateNotificationCount = data => ({
    type: UPDATE_NOTIFICATION_COUNT,
    payload: data
});

export function updateAuthDataAction(data) {
    return async (dispatch) => {
        dispatch(updateAuthData(data));
    };
}

export function authCheckFirstPassword(token, redirect = '/') {
    return async (dispatch) => {
        try {
            let response = await fetch(`${SERVER_URL}/api/v1/accounts/checkpassword/`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                }
            });
            response = await parseJSON(response);
            // if (response.home_logo != null) {
            //     dispatch(updateLogo(response.home_logo));
            // }
            if (response.default_password) {
                const url = `/setpassword?next=${redirect}`;
                dispatch(push(url));
            }
        } catch (error) {
            handleGenericErrors(dispatch, error);
        }
    };
}


export function authCheckAuthentication(token, user) {
    return async (dispatch) => {
        try {
            let res = await fetch(`${SERVER_URL}/api/v1/accounts/isauthenticated/`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                }
            });
            handleErrors(res);
            res = await res.json();
            dispatch(authLoginUserSuccess(token, res));
        } catch (error) {
            handleGenericErrors(dispatch, error);
        }
    };
}

export function authChangePassword(token, password, passwordConfirm, redirect = '/') {
    return async (dispatch) => {
        if (password !== passwordConfirm) {
            // TODO: Se hizo esto porque no se alcanzaba a limpiar los errores anteriores, así que
            // después de un timeout se llama a la acción que las contraseñas ingresadas no coinciden
            setTimeout(() => { 
                dispatch(authSignupUserFailure('NonMatchingPasswords', {
                    'passwordConfirm': 'Las contrseñas ingresadas no coinciden'
                })); 
            }, 50);
            return;
        }
        try {
            let response = await fetch(`${SERVER_URL}/api/v1/accounts/changepassword/`, {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                    'Data': `{ "password": "${password}"}`
                }
            });
            if (response) { dispatch(push(redirect)); }

        } catch (error) {
            handleGenericErrors(dispatch, error);
        }
    };
}

export function authBusinessUserFirstLogin(token, businessUserId, name, password, passwordConfirm, redirect = '/home') {
    return async (dispatch) => {
        if (password !== passwordConfirm) {
            // TODO: Se hizo esto porque no se alcanzaba a limpiar los errores anteriores, así que
            // después de un timeout se llama a la acción que las contraseñas ingresadas no coinciden
            setTimeout(() => { 
                dispatch(authSignupUserFailure('NonMatchingPasswords', {
                    'passwordConfirm': 'Las contrseñas ingresadas no coinciden'
                })); 
            }, 50);
            return;
        }
        try {
            let response = await fetch(
                `${SERVER_URL}/api/v1/accounts/changepassword/`,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${token}`,
                        'Data': `{ "password": "${password}"}`
                    }
            });
            if (response) {
                response = await fetch(
                    `${SERVER_URL}/api/v1/accounts/businessusers/${businessUserId}/`,
                    {
                        method: 'PATCH',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Token ${token}`
                        },
                        body: JSON.stringify({
                            'name': name,
                            'old_password': password,
                        })
                    }
                );
                if (response) {
                    dispatch(authBusinessUserFirstLoginSuccess(name));
                    dispatch(push(redirect));
                };
            }
        } catch (error) {
            handleGenericErrors(dispatch, error);
        }
    };
}


export function authChangePasswordFromRecovery(recovery_token, password, passwordConfirm, redirect = '/') {
    return async (dispatch) => {
        if (password !== passwordConfirm) {
            // TODO: Se hizo esto porque no se alcanzaba a limpiar los errores anteriores, así que
            // después de un timeout se llama a la acción que las contraseñas ingresadas no coinciden
            setTimeout(() => { 
                dispatch(authSignupUserFailure('NonMatchingPasswords', {
                    'passwordConfirm': 'Las contrseñas ingresadas no coinciden'
                })); 
            }, 50);
            return;
        }
        try {
            let response = await fetch(`${SERVER_URL}/api/v1/accounts/changepasswordrecovery/`, {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: `{ "recovery_token": "${recovery_token}", "password": "${password}"}`
            });
            if (response && response.status < 400) { dispatch(push(redirect)); }
            return response;

        } catch (error) {
            handleGenericErrors(dispatch, error);
            return {error:true, status:500}
        }
    };
}

export function authConfirmMail(confirmation_token){
    return dispatch => {
        //const outdata = ${rawdata};
        return fetch(`${SERVER_URL}/api/v1/accounts/confirm/email/${confirmation_token}/`, {
            method: 'get',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
        .then(handleErrors)
        .then(res => dispatch(alertActions.success('¡Tu correo fue confirmado exitosamente! Ahora puedes continuar con tu(s) proceso(s).')))
        .catch(error => {
            if (error.message == 'Precondition Failed') {
                dispatch(alertActions.error('Tu correo ya está confirmado o el plazo para confirmarlo venció.'));
            } else if (error.message == 'Not Found') {
                dispatch(alertActions.error('Link de activación inválido. Ingresa con tus credenciales para que te reenvien un nuevo link.'));
            }
            return Promise.resolve();
        });
    };
}

export function authSendConfirmMail(token, rawdata){
    return dispatch => {
        return fetch(`${SERVER_URL}/api/v1/accounts/sendconfirmemail/`, {
            method: 'get',
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${token}`,
                'Content-Type': 'application/json',
                'Raw-Data': `${rawdata}`,
            },
        })
        .then(handleErrors)
        .then(res => Promise.resolve())
        .catch(error => {
            return Promise.resolve();
        });
    };
}

export function authPasswordRecovery(email, redirect = '/', recaptcha) {
    return async (dispatch) => {
        try {
            dispatch(authLoginUserRequest());
            await fetch(`${SERVER_URL}/api/v1/accounts/passwordrecovery/`, {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Captcha': recaptcha,
                },
                body: `{ "email": "${email}", "redirect": "${redirect}", "language": "${i18n.language.split('-')[0]}"}`
            });
            const msg = i18n.t('forgot__password__if__mail__exists');
            dispatch(authLoginUserFailure(200, {
                dialog: msg
            }));
        } catch (error) {
            handleGenericErrors(dispatch, error);
        }
    };
}

export function authSocialSignupUser(socialAuth, password, captchaToken, dni='', country_document='', redirect='/') {
    return dispatch => {
        dispatch(authSignupUserRequest());
        const { email } = socialAuth;
        // const code = socialAuth.tokenObj
        const jobappid = socialAuth.jobAppId;
        return fetch(
            `${SERVER_URL}/api/v1/accounts/dniexists/?format=json&country=${country_document}&dni=${dni}&email=${email}`,
            {
                headers: {
                    Accept: 'application/json',
                }
            }
        )
        .then(handleErrors)
        .then(parseJSON)
        .then((response) => {
            if (response.new_dni === "true") {
                fetch(
                    `${SERVER_URL}/api/v1/accounts/register/socialaccount/?format=json`,
                    {
                        method: 'post',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            'email': email,
                            'password': password,
                            'dni': dni,
                            'country_document':country_document,
                            'job_application_id': jobappid,
                            'provider': socialAuth.provider,
                            'token': captchaToken,
                        })
                    }
                )
                .then(handleErrors)
                .then(parseJSON)
                .then(res => {
                    dispatch(authSignupUserSuccess());
                    dispatch(authSocialLoginUserSuccess(res.token, res.user, socialAuth));
                    dispatch(push(redirect));
                    dispatch(alertActions.success('Cuenta creada Exitosamente'));
                })
                .catch(error => {
                    dispatch(authSignupUserFailure('SignupError', { 'generic': error }));
                    if (error && typeof error.response !== 'undefined' && error.response.status >= 500) {
                        // Server side error
                        dispatch(authLoginUserFailure(500, { 'dialog': 'Ocurrió un error en el servidor!' }));
                        dispatch(alertActions.error('Ocurrió un error en el servidor!'));
                    } else {
                        // Most likely connection issues
                        dispatch(authLoginUserFailure('ConnectionError', { 'dialog': i18n.t('start_process__gmail__connection__error') }));
                        dispatch(alertActions.error(i18n.t('start_process__gmail__connection__error')));
                    }
                    return Promise.resolve();
                });
            } else {
                let dialog;
                if (response.social_accounts) {
                    // Si response viene con atributo "social_accounts", se
                    // sugiere también iniciar sesión nuevamente con las "redes
                    // sociales" registradas
                    dialog = 'El DNI ingresado ya está asociado a una cuenta. Inicie sesión con las credenciales asociadas a este DNI'
                } else {
                    dialog = 'El DNI ingresado ya está asociado a una cuenta. Inicie sesión con las credenciales asociadas a este DNI'
                }
                dispatch(
                    authSignupUserAlreadyExists(
                        response.user_mail,
                        response.user_id, 
                        { dialog }
                    )
                );
                dispatch(
                    alertActions.warning(dialog)
                );
            }
        })
    }
}

export function authVinculateUser(socialAuth, dni, redirect) {
    return async (dispatch) => {
        try {
            let response = await fetch(`${SERVER_URL}/api/v1/accounts/vinculate/`, {
                method: 'post',
                body: JSON.stringify({
                    provider: socialAuth.provider,
                    email: socialAuth.email,
                    dni: dni,
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            checkHttpStatus(response);
            response = await parseJSON(response);
            // console.log(response)
            dispatch(authSocialLoginUserSuccess(response.token, response.user, socialAuth));
            dispatch(push(redirect));
        } catch (error) {
            if (error.response.status === 401)
            {
                // Este error significa que el rut no coincide con el correo de la cuenta ingresada
                const msg = {
                    dniNotFound: i18n.t('start__process__DNI__error__unmatch')
                }
                dispatch(authSocialLoginUserFailure(error, msg))
            }
        }
    }
}

export function authSignupUser(email, password, token, jobapp, redirect = '/', dni = '', country_document='') {
    return dispatch => {
        dispatch(authSignupUserRequest());
        // if (password!=passwordConfirm) {
        //     // TODO ULTRA FLAITE, PERO EL DISPATCH SE HACIA TAN RAPIDO QUE NO ALCANZABA A LIMPIAR LOS ERRORES ANTERIORES
        //     setTimeout(() => { dispatch(authSignupUserFailure('NonMatchingPasswords', {"passwordConfirm": "Las contrseñas ingresadas no coinciden"})) }, 50)
        //     //dispatch(authSignupUserFailure('NonMatchingPasswords', {"passwordConfirm": "Las contrseñas ingresadas no coinciden"}));
        //     return Promise.resolve();
        // }
        return fetch(
            `${SERVER_URL}/api/v1/accounts/dniexists/?format=json&country=${country_document}&dni=${dni}&email=${email}`,
            {
                // credentials: 'include',
                headers: {
                    Accept: 'application/json',
                }
            })
        .then(handleErrors)
        .then(parseJSON)
        .then((response) => {
            if(response.new_dni === 'true'){
                return fetch(`${SERVER_URL}/api/v1/accounts/register/?format=json`, {
                    method: 'post',
                    // credentials: 'include',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: `${JSON.stringify({ 'email': email,'password': password,'dni': dni, 'country_document':country_document, 'job_application_id': jobapp, 'token': token })}`
                })
                .then(handleErrors)
                .then(res => {
                    dispatch(authSignupUserSuccess());
                    dispatch(authLoginUserRequest());
                    const auth = btoa(`${email}:0:${password}`);
                    return fetch(`${SERVER_URL}/api/v1/accounts/login/?format=json`, {
                        method: 'post',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Basic ${auth}`
                        }
                    })
                    .then(checkHttpStatus)
                    .then(parseJSON)
                    .then((response) => {
                        dispatch(authLoginUserSuccess(response.token, response.user));
                        redirect && dispatch(push(redirect));
                        dispatch(alertActions.success('Cuenta creada Exitosamente'));
                        return Promise.resolve();
                    })
                    .catch((error) => {
                        if (error && typeof error.response !== 'undefined' && error.response.status === 401) {
                            // Invalid authentication credentials
                            dispatch(authLoginUserFailure(401, { 'password':'Error de Autentificación: contraseña no coincide con el usuario ingresado' }));
                            dispatch(alertActions.error(i18n.t('login__auth__error')));
                        } else if (error && typeof error.response !== 'undefined' && error.response.status >= 500) {
                            // Server side error
                            dispatch(authLoginUserFailure(500, { 'dialog': 'Ocurrió un error en el servidor!' }));
                            dispatch(alertActions.error('Ocurrió un error en el servidor!'));
                        } else {
                            // Most likely connection issues
                            dispatch(authLoginUserFailure('ConnectionError', { 'dialog': i18n.t('start_process__gmail__connection__error') }));
                            dispatch(alertActions.error(i18n.t('start_process__gmail__connection__error')));
                        }

                        return Promise.reject(); // reject para poder tener funcionalidades basadas en una promesa mala
                    });
                })
                .catch(error => {
                    dispatch(authSignupUserFailure('SignupError', { 'generic': error }));
                    return Promise.reject();
                });
            }
            else{
                dispatch(authSignupUserAlreadyExists(response.user_mail, response.user_id, { 'dialog': 'El DNI ingresado ya está asociado a una cuenta. Inicie sesión con las credenciales asociadas a este DNI' }));
                // dispatch(alertActions.warning('El DNI ingresado ya está asociado a una cuenta. Inicie sesión con las credenciales asociadas a este DNI'));
                return Promise.reject('El DNI ingresado ya está asociado a una cuenta. Inicie sesión con las credenciales asociadas a este DNI');
            }
        });
    };
}

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}


/**
 * Función para manejar de forma genérica los errores. Esta pedazo de código se
 * repetía en varias partes porque se manejaban los mismos errores siempre, por lo que se
 * pasí a una función por separado para llamarla en los catch de las llamadas a la API.
 *
 * @param {*} dispatch: el objeto para poder hacer dispatch a las acciones
 * @param {*} error: un objeto del tipo error con estado y toda la información del error
 */
const handleGenericErrors = (dispatch, error) => {
    // Si ocurre algún error de autentificación (se revisa si el estado del error es 401)
    if (error && typeof error.response !== 'undefined' && error.response.status === 401) {
        dispatch(authLoginUserFailure(401, {
            password:i18n.t('login__auth__error')
        }));
        dispatch(alertActions.error(
            i18n.t('login__auth__error')
        ));
    // En caso de que haya un error de parte del servidor (se revisa si el estado tiene código 
    // sobre 500 y no existe una respuesta)
    } else if (error && typeof error.response !== 'undefined' && error.response.status >= 500) {
        // dispatch(authLoginUserFailure(500, { generic: 'Ocurrió un error en el servidor!' }));
    // En cualquier otro caso (error genérico). Esto normalmente es en caso de fallo con la
    // conexión a internet
        Sentry.captureException('Auth: server error');
    } else {
        // console.log("GENERIC ERROR")
        // console.log(error)
        dispatch(authLoginUserFailure('ConnectionError', {
            generic: i18n.t('start_process__gmail__connection__error')
        }));
        Sentry.captureException('Auth: connection error');
    }

};
