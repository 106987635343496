import React, { useContext, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Icon,
  message,
  Modal,
  Row,
  Typography,
  Upload,
} from 'antd';

import PreviewDocument from '../../CandidateProfilev2/common/PreviewDocument';
import { PersonalFormContext } from '../config/Context';
import i18n from '../../../i18n';

const { Panel } = Collapse;
const serverUrl = process.env.REACT_APP_SERVER_URL;

const CvForm = ({ token, userId, setDisplayForm, setParserReady, goBackToPath, setParserOutcome, setChoseToNotAutocompleteForm, setTotalSteps }) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    updateCandidate,
    candidate: {
      id,
      name,
      surname,
      skills,
      phone,
      phone_prefix,
      careers,
      has_cv,
      cv,
      newCv,
      cvType,
      education_level,
      experiences,
      commune,
    },
    updateCvParsedInfo,
    updateCvType,
    countries,
    updateCvStatus,
  } = useContext(PersonalFormContext);
  const [normalFileList, setNormalFileList] = useState(newCv && cvType === 'normal' ? [newCv] : []);
  const [linkedinFileList, setLinkedinFileList] = useState(newCv && cvType === 'linkedin' ? [newCv] : []);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [disableNormalDragger, setDisableNormalDragger] = useState(false);
  const [disableLinkedinDragger, setDisableLinkedinDragger] = useState(false);

  function fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = () => {
        const base64String = reader.result.split(',')[1]; // Extract base64 data from the result
        resolve(base64String);
      };
  
      reader.onerror = (error) => {
        reject(error);
      };
  
      reader.readAsDataURL(file); // Read the file as data URL
    });
  }

  const handleContinue = async () => {
    if (newCv === null || (has_cv && cv && !newCv)) {
      setDisplayForm(false);
    }
    if (newCv && cvType === 'normal') {
      setIsLoading(true);
      setChoseToNotAutocompleteForm(false);
      setTotalSteps((prevState) => prevState - 1);
      let formData = {
        "filename": newCv.name,
        "user_id": userId,
      }
      formData['file'] = await fileToBase64(newCv.originFileObj);
      const url = `${process.env.REACT_APP_CV_API}/v2/parse`;
      fetch(url, {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        }
      })
        .then(res => {
          if (res.statusCode >= 400) throw Error('Bad response');
          return res.json();
        })
        .then(data => parseData(data))
        .catch(() => {
          setParserReady(true);
          setParserOutcome('error');
          timeoutClose();
          // Test data for localhost
          // parseData({
          //   contact_info: {
          //     mobile_phone: 'test123'
          //   },
          //   skills: ['ola', 'chao'],
          //   education: {
          //     university: []
          //   },
          // })
        });
    } else if (newCv && cvType === 'linkedin') {
      setIsLoading(true);
      setChoseToNotAutocompleteForm(false);
      setTotalSteps((prevState) => prevState - 1);
      let formData = {
        "filename": newCv.name,
        "user_id": userId,
      }
      formData['file'] = await fileToBase64(newCv.originFileObj);
      const url = `${process.env.REACT_APP_CV_API}/v2/parselinkedin`;
      fetch(url, {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Token ${token}`,
        },
      }).then((res) => {
        if (res.statusCode >=400) throw Error('Bad response')
        return res.json()
      })
      .then((data) => {
        parseLinkedinData(data);
      })
      .catch((err) => {
        setParserOutcome('error');
        timeoutClose();
      });
    }
  };

  const timeoutClose = () => {
    setTimeout(() => {
      setIsLoading(false);
      setDisplayForm(false);
      disableNormalDragger && setDisableNormalDragger(false);
      disableLinkedinDragger && setDisableLinkedinDragger(false);
      setParserReady(true);
    }, 1000);
  };

  const parseData = (data) => {
    const educationLevel = {
      "ENSEÑANZA MEDIA": i18n.t("form__academic_level_1"),
      "TÉCNICO": i18n.t("form__academic_level_2"),
      "UNIVERSITARIA": i18n.t("form__academic_level_3"),
      "PREGRADO": i18n.t("form__academic_level_7"),
      "POSTGRADO": i18n.t("form__academic_level_4"),
      "DOCTORADO": i18n.t("form__academic_level_6"),
      "MAGISTER": i18n.t("form__academic_level_5"),
    }
    //const nameSplit = data.contact_info.name?.indexOf(' ');
    const firstName = data.contact_info?.name;
    const lastName = data.contact_info?.surname;
    const universities = data.education.university.map((item) => ({
      career_institution: item,
      career_name: undefined,
      career_entry_year: undefined,
      career_last_year: undefined,
      career_state: undefined,
      career_type: undefined,
    }));
    const parsedPhone = data.contact_info.mobile_phone.length > 8 ? data.contact_info.mobile_phone.slice(-8) : data.contact_info.mobile_phone;
    const parsedPhonePrefix = data.contact_info.mobile_phone.length > 8 ? data.contact_info.mobile_phone.slice(0, -8) : '';
    const parsedSkills = data.skills.concat(data.languages).filter((item) => item && item !== 'NAN');
    if (!name) updateCandidate('name', firstName);
    if (!surname) updateCandidate('surname', lastName);
    const skillSet = new Set(skills.concat(parsedSkills));
    const skillArray = [...skillSet]
    if (skillArray.length > 0) updateCandidate('skills', skillArray);
    if (careers.length === 0) updateCandidate('careers', universities);
    if (!phone) updateCandidate('phone', parsedPhone);
    const countryPhoneCode = countries.find(c => parsedPhonePrefix.includes(c.phone_code));
    if (!phone_prefix) updateCandidate('phone_prefix', countryPhoneCode ? countryPhoneCode.phone_code : null);
    if (!education_level) updateCandidate('education_level', educationLevel[data.education.highest_level]);
    // Just for storing the parsed data in the context, later we will use the parsed data to fill the form
    updateCvParsedInfo(data);
    setParserOutcome('success');
    timeoutClose();
  };

  const parseLinkedinData = (data) => {
    const skillsSet = new Set(skills.concat(data.main_skills));
    const skillArray = [...skillsSet];
    if (skillArray.length > 0) updateCandidate('skills', skillArray);
    const nameSplit = data.name.indexOf(' ');
    const firstName = data.name.slice(0, nameSplit);
    const lastName = data.name.slice(nameSplit + 1);
    if (!name) updateCandidate('name', firstName);
    if (!surname) updateCandidate('surname', lastName);
    if (experiences.length === 0) {
      updateCandidate('experiences', [{ company: data.last_company, position: data.last_experience }]);
    }
    const secondCommaIndex = data.region.indexOf(',', data.region.indexOf(',') + 1);
    const parsedCommune = data.region.slice(0, secondCommaIndex);
    let parsedCountry = data.region.slice(secondCommaIndex + 1);
    if (!commune) updateCandidate('commune', parsedCommune.length > 0 ? commune : null);
    parsedCountry = countries.find(
      (c) => c.country === country || Object.values(c.translation).includes(country)
    );
    if (!country) updateCandidate('country', parsedCountry ? parsedCountry.country : null);
    setParserOutcome('success');
    timeoutClose();
  };

  return (
    <React.Fragment>
      <Row type='flex' justify='center' align='middle' style={{ height: '100%' }}>
        {isLoading === false
          ? (
            <>
              <Row type='flex' style={{ width: '100%', padding: '20px 20px' }}>
                <Col
                  onClick={() => Modal.confirm({
                    cancelText: i18n.t('commons__cancel'),
                    content: i18n.t('form__go_back__content'),
                    okText: i18n.t('commons__go_back'),
                    onOk: goBackToPath,
                    title: i18n.t('form__go_back__title'),
                  })}
                  span={6}
                  style={{ cursor: 'pointer', marginTop: '5px' }}
                >
                  <Icon style={{ marginRight: '5px' }} type="left" /> {i18n.t('commons__go_back')}
                </Col>
              </Row>
              <Col md={20} lg={12}>
                <Card className='form-card' style={{ marginTop: '20px', padding: '10px 0' }}>
                  <Row type='flex' justify='center' align='middle' style={{ rowGap: '16px' }}>
                    <Col span={20} style={{ textAlign: 'center' }}>
                      <img src='https://genoma-assets.s3.us-east-2.amazonaws.com/upload-cv.svg' alt='upload-cv' />
                    </Col>
                    <Col span={20} style={{ textAlign: 'center' }}>
                      <Typography.Title level={2}>
                        {i18n.t("form__profile__step_0__title")}
                      </Typography.Title>
                      <Typography.Text>
                        {i18n.t("form__profile__step_0__description")}
                      </Typography.Text>
                    </Col>
                    <Col span={20}>
                      {has_cv
                        ? (
                          <Collapse defaultActiveKey={['1']}>
                            <Panel
                              header={i18n.t("form__profile__step_0__option_0__title")}
                              key="1"
                            >
                              <Typography.Paragraph style={{ fontSize: '14px' }}>
                              {i18n.t("form__profile__step_0__option_0__description")}
                              <br />
                              </Typography.Paragraph>
                              <Icon type="paper-clip" style={{ marginRight: '5px' }} />
                              <Typography.Text>
                                <a href={cv} target='_blank'>CV</a>
                              </Typography.Text>
                            </Panel>
                          </Collapse>
                        ) : null}
                    </Col>
                    <Col span={20}>
                      <Collapse>
                        <Panel
                          header={i18n.t("form__profile__step_0__option_1__title")}
                          key="1"
                        >
                          <Upload.Dragger 
                            name='file' 
                            accept=".pdf,application/pdf"
                            multiple={false}
                            method='PUT'
                            disabled={disableNormalDragger}
                            fileList={normalFileList}
                            action={`${serverUrl}/api/v1/accounts/personalusers/${id}/files/`}
                            data={{ filetype: 'cv' }}
                            headers={{ Authorization: `Token ${token}`, Accept: 'application/json, text/plain, */*' }}
                            showUploadList={{ showPreviewIcon: true }}
                            onChange={({ file, fileList }) => {
                              updateCvType('normal');
                              const { status } = file;
                              if (status === 'done') {
                                message.success(i18n.t("form__file__upload_success__message"));
                                updateCandidate('newCv', file);
                                updateCandidate('has_cv', true);
                                setDisableLinkedinDragger(true);
                                updateCvStatus(true);
                              }
                              if (status === 'error') {
                                message.error(i18n.t("form__file__upload_error__message"));
                              }
                              setNormalFileList(fileList.length > 0 ? [fileList[fileList.length - 1]] : []);
                            }}
                            onRemove={() => {
                              updateCvType(null);
                              updateCandidate('newCv', null);
                              setDisableLinkedinDragger(false);
                              updateCvStatus(false);
                            }}
                            beforeUpload={(file) => {
                              const isLt5M = file.size / 1024 / 1024 < 5;
                              if (!isLt5M) {
                                message.error(i18n.t("form__file_upload__error__message__max_size"));
                              }
                              return isLt5M;
                            }}
                          >
                            <p className="ant-upload-drag-icon">
                              <Icon type="inbox" style={{color: '#F175A5'}}/>
                            </p>
                            <p className="ant-upload-text">{i18n.t("form__file__upload__instructions")}</p>
                            <p className="ant-upload-hint">
                              {i18n.t("form__file__upload__instructions__details")}<br />
                              ({i18n.t("form__file__upload__instructions__max_size")})
                            </p>
                          </Upload.Dragger>
                        </Panel>
                      </Collapse>
                    </Col>
                    <Col span={20}>
                      <Divider>
                        <Typography.Title level={4}>
                          {i18n.t("form__profile__step_0__alternative__title")}
                        </Typography.Title>
                      </Divider>
                      <Collapse>
                        <Panel header={i18n.t("form__profile__step_0__option_2__title")} key="1">
                          <Row type='flex' style={{ width: '100%', flexDirection: 'row' }}>
                            <Icon type='linkedin' style={{ fontSize: '20px', color: '#597EF7' }} />
                            <Typography.Title level={4} style={{ marginLeft: '8px' }}>
                              {i18n.t("form__profile__step_0__option_2__title")}
                            </Typography.Title>
                            <br/>
                            <Typography.Paragraph style={{ fontSize: '14px' }}>
                              {i18n.t("form__profile__step_0__option_2__description")}
                            </Typography.Paragraph>
                            <Typography.Paragraph style={{ fontSize: '14px' }}>
                              {i18n.t("form__profile__step_0__option_2__instruction_1")}<br />
                              {i18n.t("form__profile__step_0__option_2__instruction_2")}<br />
                              {i18n.t("form__profile__step_0__option_2__instruction_3")}
                            </Typography.Paragraph>
                          </Row>
                          <Upload.Dragger 
                            name='file' 
                            accept=".pdf,application/pdf"
                            multiple={false}
                            method='PUT'
                            fileList={linkedinFileList}
                            disabled={disableLinkedinDragger}
                            action={`${serverUrl}/api/v1/accounts/personalusers/${id}/files/`}
                            data={{ filetype: 'cv' }}
                            headers={{ Authorization: `Token ${token}`, Accept: 'application/json, text/plain, */*' }}
                            showUploadList={{ showPreviewIcon: true }}
                            onChange={({file, fileList}) => {
                              updateCvType('linkedin');
                              const { status } = file;
                              if (status === 'done') {
                                message.success('Archivo subido correctamente');
                                updateCandidate('newCv', file);
                                updateCandidate('has_cv', true);
                                setDisableNormalDragger(true);
                                updateCvStatus(true);
                              }
                              if (status === 'error') {
                                message.error('Error al subir el archivo');
                              }
                              setLinkedinFileList(fileList.length > 0 ? [fileList[fileList.length - 1]] : []);
                            }}
                            onRemove={() => {
                              updateCvType(null);
                              updateCandidate('newCv', null);
                              setDisableNormalDragger(false);
                              updateCvStatus(false);
                            }}
                            beforeUpload={(file) => {
                              const isLt5M = file.size / 1024 / 1024 < 5;
                              if (!isLt5M) {
                                message.error('form__file_upload__error__message__max_size');
                              }
                              return isLt5M;
                            }}
                          >
                            <p className="ant-upload-drag-icon">
                              <Icon type="inbox" style={{color: '#F175A5'}}/>
                            </p>
                            <p className="ant-upload-text">{i18n.t("form__file__upload__instructions")}</p>
                            <p className="ant-upload-hint">
                              {i18n.t("form__file__upload__instructions__details")}<br />
                              ({i18n.t("form__file__upload__instructions__max_size")})
                            </p>
                          </Upload.Dragger>
                        </Panel>
                      </Collapse>
                    </Col>
                    <Col span={20}>
                      <Row type='flex' justify='center' align='middle' style={{ marginTop: '20px' }}>
                        <Typography.Text
                          onClick={() => {
                            setDisplayForm(false);
                            setChoseToNotAutocompleteForm(true);
                          }}
                          style={{ color: '#F175A5', cursor: 'pointer' }}
                        >
                          {i18n.t("form__profile__step_0__option_3__title")}
                        </Typography.Text>
                      </Row>
                    </Col>
                    <Col span={20}>
                      <Row type='flex' justify='end' style={{ width: '100%', columnGap: '8px' }}>
                        {newCv && 
                          <Button 
                            type='secondary' 
                            onClick={() => setShowPreviewModal(true)} 
                            style={{ display: 'flex', padding: '10px', justifyContent: 'center', alignItems: 'center' }}
                          >
                            <Icon type='eye' />
                            {i18n.t("commons__preview")}
                          </Button>
                        }
                        <Button
                          disabled={!newCv && !has_cv}
                          type='primary' 
                          onClick={handleContinue} 
                          style={{ display: 'flex', padding: '10px', justifyContent: 'center', alignItems: 'center'}}
                        >
                          {i18n.t("commons__next")} <Icon type='arrow-right' />
                        </Button>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </>
          ) : (
            <Card 
              className='form-card' 
              style={{ marginTop: '20%', padding: '10px 0', alignSelf: 'center' }}
              bodyStyle={{ minHeight: 'auto' }}
            >
              <Row type='flex' justify='center' align='middle' style={{ rowGap: '16px' }}>
                <Col span={24} style={{ textAlign: 'center'}}>
                  <Icon
                    spin={true}
                    rotate={1}
                    type="loading-3-quarters"
                    style={{ fontSize: '50px', color: '#F175A5' }}
                  />
                </Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                  <Typography.Text>
                    {i18n.t("form__profile__step_0__loading__message_1")}
                    <br />
                    {i18n.t("form__profile__step_0__loading__message_2")}
                  </Typography.Text>
                </Col>
              </Row>
            </Card>
          )}
      </Row>
      {newCv?.response?.file && (
        <PreviewDocument
          file={newCv.response.file}
          visiblePreviewModal={showPreviewModal}
          isPdf={true}
          handleOk={() => setShowPreviewModal(false)}
        />
      )}
    </React.Fragment>
  );
}

export default CvForm;
