export const getGameImg = (game) => {
  const assetUrl = 'https://genoma-assets.s3.us-east-2.amazonaws.com/';

  if (game.icon.includes('.')){
    return `${assetUrl}${game.icon}`;
  }

  switch (game.code) {
    case 'CUTE':
    case 'TPCG':
    case 'THEX':
    case 'THE2':
      return `${assetUrl}Personalidad.png`;
    default:
      return `${assetUrl}${game.code.trim()}.png`;
  }
};
