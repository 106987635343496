import React, { useState } from 'react';
import { Form, Upload, message, Spin } from 'antd';

/**
 * Drag and upload component only accepts xls and xlsx
 * @param {*} props - handleFile, setXlsState, setState
 */
export default function DataInput(props) {

  // antd shows a list of uploaded files
  const [ fileListState, setFileListState ] = useState([])
  const [ loadingFile, setLoadingFile ] = useState(false)
  const [ hideFileInput, setHideFileInput ] = useState(false)

  function handleChange(target) {
    const file = target;
    if (file) props.handleFile(file);
    setLoadingFile(true)
  };

  return (
    <Form.Item>
      <Spin tip="Subiendo Excel" spinning={loadingFile}>
        <Upload.Dragger
          name='file'
          multiple={false}
          fileList={fileListState}
          onRemove={() => {
            props.setXlsState({ cols: [], data: [] })
            props.setState({ modalVisible: false, validateData: false, importData: null, })
            props.handleParentRemove() ?? null
          }}
          beforeUpload={file => {
            const reader = new FileReader();
            handleChange(file);
            // Prevent upload
            return false;
          }}
          onChange={(info) => {
            let fileList = [...info.fileList];
            let file = fileList.slice(-1);
            setFileListState(file);
            setLoadingFile(false)
            if (fileList.length) {
              message.success('El documento ha sido subido exitosamente', 3)
              setHideFileInput(true)
            } else {
              message.success('El documento ha sido eliminado exitosamente', 3)
              setHideFileInput(false)
            }
          }}
          accept=".xls,.xlsx"
        >
          {!hideFileInput ? (
            <div className='pf__form-wrapper__cloud'>
              <div className='cloud-container'>
                <svg width="101" height="61" viewBox="0 0 161 121" role="presentation" focusable="false" tabIndex="-1" className="upload-shot-cloud fill-current">
                  <path d="M127.452 47.224C127.458 21.067 106.434 0 80.5 0 54.566 0 33.542 21.067 33.542 47.056 14.745 48.918 0 64.742 0 84.028 0 104.45 16.516 121 36.896 121h87.208C144.484 121 161 104.45 161 84.028c0-19.286-14.745-35.11-33.548-36.804z"></path>
                </svg>
                <div className="cloud-arrow-container">
                  <svg height="18" viewBox="0 0 75 48" width="75" role="presentation" focusable="false" tabIndex="-1" className="upload-shot-arrow fill-current">
                    <path fill='white' d="M24 47.111H6.736c-5.978 0-8.97-7.24-4.743-11.475L37.5.056l35.507 35.58c4.226 4.235 1.235 11.475-4.743 11.475H50.995z"></path>
                  </svg>
                </div>
                <div className="cloud-arrow-stripes">
                  <div className="cloud-arrow-stripe red"></div>
                  <div className="cloud-arrow-stripe purple"></div>
                  <div className="cloud-arrow-stripe blue"></div>
                </div>
              </div>

              <div className="ant-upload-text">
                <strong> Haz clic o arrastra tu documento aquí para subirlo. </strong>
              </div>
              <div className="ant-upload-text"> Solo en formato Excel </div>
            </div>
          ) : (
            <div className="ant-upload-text">
              <strong> Archivo subido exitosamente </strong>
            </div>
          )}
        </Upload.Dragger>
      </Spin>
    </Form.Item>
  )
}