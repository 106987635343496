import { 
    VIDEO_FETCH_BEGIN,
    VIDEO_FETCH_FAILURE,
    VIDEO_FETCH_SUCCESS,
    VIDEO_RATE_BEGIN,
    VIDEO_RATE_FAILURE,
    VIDEO_RATE_SUCCESS,
    RESET_VIDEO_STATE,
 } from '../constants';


 const initialState = {
    interviewId: '',
    data: [],
    isFetchingVideo: false,
    error: '',
    isRatingVideo: false,
}

export default function traitReducer(state = initialState, action) {
    switch (action.type) {
        case VIDEO_FETCH_BEGIN:
            return {
                ...state,
                interviewId: action.payload.interviewId,
                isFetchingVideo: true
            }
        case VIDEO_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingVideo: false,
                data: action.payload.data
            }
        case VIDEO_FETCH_FAILURE:
            return {
                ...state,
                isFetchingVideo: false,
                data: [],
                error: action.payload.error
            }
        case VIDEO_RATE_BEGIN:
            return {
                ...state,
                isRatingVideo: true,
            }
        case VIDEO_RATE_SUCCESS:
            let answer = state.data.find(ans => ans.answer[0].id === action.payload.answerId)
            answer.answer[0].rating = action.payload.rating
            return {
                ...state,
                isRatingVideo: false,
                data: state.data
            }
        case VIDEO_RATE_FAILURE:
            return {
                ...state,
                error: action.payload.error
            }
        case RESET_VIDEO_STATE:
            return {
                ...initialState
            }
        default:
                return state;
    }
}