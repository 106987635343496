import React, { useState, useEffect, useRef } from "react";
import ReCAPTCHA from 'react-google-recaptcha';
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import CandidateLoginLayout from "../Login/components/CandidateLoginLayout";
import { isMobile } from "react-device-detect";

import * as actionCreators from "../../actions/auth";

import { Icon, Form, Row, Col, Typography, Input, Button, Modal } from "antd";
import i18n from "../../i18n";

const { Title, Text } = Typography;

const PasswordRecoveryView = (props) => {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({ dialog: false, email: false });
  const [redirectTo, setRedirectTo] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("Correo enviado");

  const captchaRef = useRef(null);

  const handleChange = (name, { target }) => {
    let tempErrors = errors;
    setErrors(tempErrors);
    setEmail(target.value);
  };

  const extractRedirect = (string) => {
    const match = string.match(/next=(.*)/);
    return match ? match[1] : "/";
  };

  const submitRequest = async (e) => {
    const qs = new URLSearchParams(props.location.search);
    const redirectTo = qs.get("next") || "/";
    e.preventDefault();
    const captchaToken = await captchaRef.current.executeAsync();
    console.log(captchaToken,"ccc")
    props.form.validateFieldsAndScroll((err, values) => {
      if (!errors.email) {
        props.actions
          .authPasswordRecovery(email.toLowerCase(), redirectTo, captchaToken)
          .then(() => {
            setModal(true);
          });
      }
    });
  };

  const handleDialogClose = () => {
    setModal(false);
    setModalMessage("Correo enviado");
  };

  const handleGoBack = () => {
    props.dispatch(goBack());
  };

  useEffect(() => {
    setRedirectTo(
      props.location ? extractRedirect(props.location.search) || "/" : "/"
    );
  }, []);

  useEffect(() => {
    if (props.errors && props.errors["dialog"]) {
      setModalMessage(props.errors["dialog"]);
    }
  }, [props]);

  useEffect(() => {
    if(!getFieldError("email") && email.length > 0){
      setErrors({ ...errors, email: false });
    }else{
      setErrors({ ...errors, email: true });
    }
  }, [email]);

  const { getFieldDecorator, getFieldError } = props.form;

  return (
    <CandidateLoginLayout>
      <Modal
        centered
        closable={false}
        visible={modal}
        onOk={handleDialogClose}
        onCancel={handleDialogClose}
        footer={[
          <Button key="submit" type="primary" onClick={handleDialogClose}>
            {i18n.t('commons__close')}
          </Button>,
        ]}
      >
        <div style={{ textAlign: "center" }}>
          <br />
          <Text strong style={{ fontSize: "16px", marginTop: "1em" }}>
            {modalMessage}
          </Text>
        </div>
      </Modal>

      <Col xs={24} sm={12} className="candidate-login__left-side">
        <Row type="flex" justify="center" align="middle">
          <Col xs={24}>
            <Row
              className="candidate-login__left-side__header"
              style={{ height: "12em" }}
            >
              <Button onClick={handleGoBack} type="link">
                <Icon type="left"></Icon>
              </Button>
              <Typography.Title level={4}>{i18n.t('commons__candidate')}</Typography.Title>
              <Row>
                <Typography.Title level={1}>
                  {i18n.t('login__password__recovery')}
                </Typography.Title>
                <Typography.Paragraph>
                  {i18n.t('login__mail__link')} 😊
                </Typography.Paragraph>
              </Row>
            </Row>

            <Form onSubmit={(e) => submitRequest(e)}>
              <Form.Item label={i18n.t('commons__email')}>
                {getFieldDecorator("email", {
                  rules: [
                    {
                      required: true,
                      message: "Por favor escribe tu email",
                    },
                    { type: "email", message: i18n.t('login__valid__email') },
                  ],
                })(
                  <Input
                    size="large"
                    type="email"
                    placeholder={i18n.t('commons__email')}
                    value={email}
                    onChange={(e) => handleChange("email", e)}
                  />
                )}
              </Form.Item>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                block
                style={{ textAlign: "center", marginTop: "0.2em" }}
                loading={props.isAuthenticating}
                disabled={errors.email}
              >
                {isMobile ? i18n.t('login__new__password__request').split()[0] : i18n.t('login__new__password__request')}
              </Button>
            </Form>
          </Col>
        </Row>
      </Col>
      <ReCAPTCHA
          onChange={() => {}}
          ref={captchaRef}
          sitekey={process.env.REACT_APP_CAPTCHA_API_KEY}
          size='invisible'
      />
    </CandidateLoginLayout>
  );
};

PasswordRecoveryView.defaultProps = {
  statusText: "",
  location: null,
  errors: {},
};

PasswordRecoveryView.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isAuthenticating: PropTypes.bool.isRequired,
  statusText: PropTypes.string,
  token: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    authPasswordRecovery: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }),
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isAuthenticating: state.auth.isAuthenticating,
    statusText: state.auth.statusText,
    token: state.auth.token,
    errors: state.auth.errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: bindActionCreators(actionCreators, dispatch),
  };
};

const PasswordForm = Form.create({ name: "password-recovery" })(
  PasswordRecoveryView
);
export default connect(mapStateToProps, mapDispatchToProps)(PasswordForm);
export { PasswordRecoveryView as PasswordRecoveryViewNotConnected };
