import React, { useState, useEffect } from 'react';

import { Modal, Typography, Button, Popconfirm, message, Popover } from 'antd';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../../actions/data';

import moment from 'moment';
import AssistantBadge from './AssistantBadge';
import useBoolean from '../../../hooks/useBoolean';

const { Text } = Typography;

const deleteStyle = {
  border: '1px solid #FF4D4F',
  color: '#FF4D4F',
  width: 'fit-content',
};

const InterviewModal = ({
  interviewId,
  jobAppId,
  actions,
  auth,
  refetch,
  ...props
}) => {
  const [interview, setInterview] = useState({});
  const [deleting, handleFlag] = useBoolean(false);

  const deleteInterview = () => {
    handleFlag.toggle();

    actions
      .fetchAndReturn(
        auth.token,
        `/api/v1/evaluations/interviews/${interviewId}/`,
        'DELETE'
      )
      .then(({ status }) => {
        if (status === 204) {
          message.success('Entrevista borrada con éxito');
          refetch();
          props.onCancel();
        } else {
          message.error('Error al borrar la entrevista.');
        }
      })
      .finally(() => {
        handleFlag.toggle();
      });
  };

  useEffect(() => {
    if (!interviewId) {
      return;
    }

    actions
      .fetchAndReturn(
        auth.token,
        `/api/v1/evaluations/interviews/${interviewId}/`,
        'GET'
      )
      .then(({ body: interview }) => setInterview(interview));
  }, [interviewId]);

  return (
    <Modal
      {...props}
      footer={null}
      width="min(90%, 800px)"
      destroyOnClose={true}
    >
      <div className="calendar-interview-modal">
        <header>
          <Text strong style={{ fontSize: 18 }}>
            {interview.title}
          </Text>
          <Text style={{ color: '#597EF7' }}>
            {interview.job_application?.job_application}
          </Text>
          <Text>
            {moment(interview.interview_date, 'DD-MM-YYYY HH:mm Zz').format(
              'DD-MM-YYYY HH:mm (UTC Zz)'
            )}
          </Text>
        </header>
        <Popconfirm
          placement="right"
          title="¿Estás seguro que quieres borrar la entrevista?"
          onConfirm={deleteInterview}
          okText="Sí"
          cancelText="No"
        >
          <Button style={deleteStyle} loading={deleting}>
            Borrar entrevista
          </Button>
        </Popconfirm>
        <Text>{interview.description}</Text>
        <div className="calendar-interview-modal__people">
          <Text strong>Candidatos</Text>
          {interview.personal_interviews?.map((personalInt, idx) => (
            <div className="calendar-interview-modal__pint" key={idx}>
              <AssistantBadge assistant={personalInt} type="candidate" />
              <div className="calendar-interview-modal__people__text">
                <Text strong>{personalInt.name}</Text>
                <Text>{personalInt.email}</Text>
              </div>
              {personalInt.comment ? (
                <Popover
                  content={<Text>{personalInt.comment}</Text>}
                  title={null}
                  trigger="hover"
                >
                  <Button icon="read">Comentario</Button>
                </Popover>
              ) : null}
              <div className="calendar-interview-modal__people__text"></div>
              <Button
                onClick={e =>
                  window.open(
                    `/processes/${interview.job_application.id}?id=${personalInt.personal_user}`,
                    '_blank'
                  )
                }
                icon="user">
                Ver perfil
              </Button>
            </div>
          ))}
        </div>
        <div className="calendar-interview-modal__people">
          <Text strong>Asistentes</Text>
          <div className="calendar-interview-modal__collab">
            <AssistantBadge assistant={interview.creator} type="creator" />
            <div className="calendar-interview-modal__people__text">
              <Text strong>{interview.creator?.name}</Text>
              <Text>{interview.creator?.email}</Text>
            </div>
            <Button icon="user-add" disabled>
              Creador
            </Button>
          </div>
          {interview.business_users?.map((bUser) => (
            <div className="calendar-interview-modal__collab">
              <AssistantBadge assistant={bUser} type="collab" />
              <div className="calendar-interview-modal__people__text">
                <Text strong>{bUser.name}</Text>
                <Text>{bUser.email}</Text>
              </div>
              <Button icon="eye" disabled>
                Colaborador
              </Button>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InterviewModal);
