import React, { Fragment, useState } from 'react';
import {
  Col,
  Card,
  Typography,
  Button,
  Tag
} from 'antd';

import CuteConfiguration from './CuteConfiguration';

const { Text } = Typography;

const CustomTest = ({ activity, index, onChangeCustomTest, currentCustomTests }) => {
  const [visible, setVisible] = useState(false);
  const [added, setAdded] = useState(false);

  const handleModal = (value) => {
    setVisible(value);
  }

  const setAddedValue = (value) => {
    setAdded(value);
  };

  const addOrRemoveCute = () => {
    setVisible(true);
  };

  return (
    <>
      <CuteConfiguration
        visible={visible}
        setVisible={handleModal}
        setAddedValue={setAddedValue}
        onChangeCustomTest={onChangeCustomTest}
        currentCustomTests={currentCustomTests}
      />
      <Col key={index} xs={12} sm={6}>
        <Card
          cover={
            <img
              src={`https://genoma-assets.s3.us-east-2.amazonaws.com/${activity.icon}`}
              alt='bag'
            />
          }
        >
          <Card.Meta
            title={
              <Fragment>
                <Text strong>{activity.activity.replace('Mobile', '')}</Text> <br />
                <Tag>Sin tiempo estimado</Tag>
              </Fragment>
            }
            description={
              <>
                {(window.innerWidth <= 500) ?
                  <Button style={{ background: added ? '#c54c25' : '#4caf50' }} icon={added ? 'minus' : 'plus'} onClick={addOrRemoveCute} />
                  :
                  <Button
                    style={{ background: added ? '#c54c25' : '#4caf50' }}
                    onClick={addOrRemoveCute}
                  >
                    {added ? 'Editar' : 'Agregar'}
                  </Button>
                }
              </>
            }
          />
        </Card>
      </Col>
    </>
  )
}

export default CustomTest;