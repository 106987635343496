import { GenericQuizAlsterPeru as GameComponent } from '@genomawork/genoma-games-old';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Tour from 'reactour';
import * as dataActionCreators from '../../../actions/data';
import * as actionCreators from '../../../actions/games';

const GAME_CODE = 'ALSP'

class ALSPGameView extends Component {
    static propTypes = {
        statusText: PropTypes.string,
        isSendingGameOutcome: PropTypes.bool.isRequired,
        gameOutcomeRecieved: PropTypes.bool.isRequired,
        gameOutcomeResponse: PropTypes.object,
        gameOutcomeError: PropTypes.string,
        token: PropTypes.string.isRequired,
        actions: PropTypes.shape({
            sendGameData: PropTypes.func.isRequired
        }).isRequired
    };

    state = {
        token: '',
        jobappid: ''
    }

    componentDidMount() {
        this.props.dataActions.fetchAndReturn(
            this.props.token, 
            `/api/v1/gamesandtests/gamecompleteness/?format=json&game_code=${GAME_CODE}&job_application_id=${this.props.match.params.jobappid}`,
            'get'
        ).then((response) => {
            if (response.body.incompleted_game) {
                this.props.history.goBack();
            }
        });
        // let x = document.getElementById("view-container").firstChild;
        // x.style.height = "100vh";
        this.setState({ 
            jobappid: this.props.match.params.jobappid,
            token: this.props.token,
        });
      }

    initGame = () => {
        const data = { game_code: GAME_CODE };
        this.props.dataActions.sendData(this.props.token, 'gamesandtests/gamecompleteness/?format=json', JSON.stringify(data), 'post');
    }

    postData = (allData) => {
        const submit = { "game_code": "ALSP", "job_application_id": this.state.jobappid, "game_outcome": allData };
        const url = 'gamesandtests/gameresponse/';

        this.props.actions.sendGameData(this.props.token, url, JSON.stringify(submit), 'post');
        this.setState((state) => { return { gameSubmit: submit }; });
    };

    render() {
        return (
            <div className="protected full-height">
                <GameComponent
                    tour={Tour}
                    callbackStart={this.initGame}
                    callback={this.postData}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        gameOutcomeResponse: state.games.gameOutcomeResponse,
        isSendingGameOutcome: state.games.isSendingGameOutcome,
        gameOutcomeRecieved: state.games.gameOutcomeRecieved,
        gameOutcomeError: state.games.gameOutcomeError,
        data: state.data.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actionCreators, dispatch),
        dataActions: bindActionCreators(dataActionCreators, dispatch),
        dispatch
    };
};

export default (connect(mapStateToProps, mapDispatchToProps)(ALSPGameView));
