import React from 'react';
import { Col, Icon, Row, Typography, Tooltip } from 'antd';

const { Text } = Typography;

export const columnFn = (fn1, fn2, fn3) => {
  const columns = [
    {
      title: 'PREGUNTA',
      dataIndex: 'question',
      key: 'question',
      width: '40%',
      render: (question) => (
        <div className="custom-cell">
          <Text>{question}</Text>
        </div>
      ),
    },
    {
      title: 'TIPO',
      dataIndex: 'question_type',
      key: 'question_type',
      width: '35%',
      render: (question_type) =>
        (question_type === 'int' && <Text>Numérico</Text>) ||
        (question_type === 'singleline' && <Text>Texto</Text>) ||
        (question_type === 'multiline' && <Text>Texto múltiple</Text>) ||
        (question_type === 'singleselection' && <Text>Selección única</Text>) ||
        (question_type === 'multiselection' && (
          <Text>Selección múltiple</Text>
        )) ||
        (question_type === 'file' && <Text>Documento adjunto</Text>),
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: '15%',
      render: (id) => {
        return (
          <Row type="flex" style={{ gap: '0.5rem' }} justify='end'>
            <Tooltip placement="top" title="Editar pregunta">
              <Icon
                type="edit"
                style={{ fontSize: '19px', color: '#A4A4A4' }}
                onClick={() => fn1(id)}
              />
            </Tooltip>
            <Tooltip placement="top" title="Duplicar pregunta">
              <Icon
                style={{ fontSize: '19px', color: '#A4A4A4' }}
                type="copy"
                onClick={() => fn2(id)}
              />
            </Tooltip>
            <Tooltip placement="top" title="Eliminar pregunta">
              <Icon
                type="delete"
                onClick={() => fn3(id)}
                style={{ fontSize: '19px', color: '#A4A4A4' }}
              />
            </Tooltip>
          </Row>
        );
      },
    },
  ];
  return columns;
};
