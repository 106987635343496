import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import moment from 'moment';

import {
  Alert,
  Button,
  Col,
  Dropdown,
  Form,
  Icon,
  Input,
  Menu,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Tooltip,
  Typography,
  Upload,
} from 'antd';

import i18n from '../../../../i18n';
import useBoolean from '../../../../hooks/useBoolean';
import { sources } from '../../../../constants/passiveCandidates';
import SelectProcesses from '../../../../components/SelectProcesses';

const { Option } = Select;
const { Text } = Typography;

const acceptedCvFileTypes = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

function PassiveCandidateFormModal(props) {
  const {
    actions: { deleteAndReturn, fetchAndReturn, sendFile },
    businessId,
    closeFakeModal,
    closeOnDelete,
    countries,
    forceStep,
    form: { getFieldDecorator, getFieldError, getFieldValue, resetFields, setFieldsValue, validateFields },
    getStageCandidates,
    handleVisible,
    jobApplicationData,
    method,
    passiveApplication,
    passiveCandidate,
    setCandidates,
    stages,
    stageOnFocus,
    token,
    updatePassiveCandidateData,
    visible,
  } = props;

  const [showMoreFields, handleShowMoreFields] = useBoolean(false);
  const [submittingData, handleSubmittingData] = useBoolean(false);

  const [candidateId, setCandidateId] = useState();
  const [existingCandidate, setExistingCandidate] = useState();
  const [jobApplicationChoices, setJobApplicationChoices] = useState();
  const [nextJobApplicationChoicesPage, setNextJobApplicationChoicesPage] = useState();
  const [selectedJobApplications, setSelectedJobApplications] = useState([]);
  const [step, setStep] = useState(0);
  const [verifyEmailError, setVerifyEmailError] = useState();

  useEffect(() => {
    if (forceStep !== undefined && method === 'PATCH') {
      setStep(forceStep);
      setCandidateId(passiveCandidate?.candidateId);
    } else {
      setStep(0);
    }
  }, [forceStep]);

  // TODO: revisar pq forceStep funciona una sola vez

  useEffect(() => {
    let values = {}
    if (method === 'PATCH' && passiveCandidate) {
      // Edición
      const {
        personaluser_fields: {
          // TODO: cv,
          name, surname, email, phone_prefix, phone, country,
        },
        source,
        other_contact_info: { linkedin },
      } = passiveCandidate;
      values = {
        // cv: [cv],
        name,
        surname,
        email,
        phone_prefix,
        phone,
        country,
        source,
        linkedin,
      }
      if (jobApplicationData) {
        const { note, status } = passiveApplication;
        values.job_application = jobApplicationData.job_application;
        values.stage = status;
        values.note = note;
      }
      setSelectedJobApplications(passiveCandidate.applications);
    } else if (method === 'POST' && existingCandidate) {
      // Creación pero ya existe candidato pasivo con ese correo
      const {
        email,
        files: { cv },
        personaluser_fields: {
          name, surname, phone_prefix, phone, country, source,
        },
        other_contact_info: { linkedin },
      } = existingCandidate;
      values = {
        name,
        surname,
        email,
        phone_prefix,
        phone,
        country,
        source,
        linkedin,
      }
      if (cv) {
        values.cv = [{
          uid: '1',
          name: 'cv',
          status: 'done',
          url: cv,
        }];
      }
      if (jobApplicationData) {
        values.job_application = jobApplicationData.job_application;
        values.note = jobApplicationData.note;
        values.stage = jobApplicationData.status;
      }
    }
    setFieldsValue({ ...values });
  }, [method, passiveApplication, existingCandidate]);

  const fetchJobApplications = (searchQuery='', loadingMore=false) => {
    const defaultUrl = `/api/v1/gamesandtests/business/${businessId}/jobapplications/${searchQuery}`;
    return fetchAndReturn(token, defaultUrl)
      .then((data) => {
        const aux = {};
        data.body?.results?.forEach(({ id, job_application, status, department, creation_date }) => {
          aux[id] = {
            id,
            job_application,
            status,
            department,
            creation_date: moment(creation_date).format('YYYY-MM-DD'),
          };
        });
        if (!loadingMore) setJobApplicationChoices(aux);
        else setJobApplicationChoices((prevChoices) => ({ ...prevChoices, ...aux }));
  
        if (data.body?.next) {
          const url = new URL(data.body.next);
          const params = new URLSearchParams(url.search);
          const page = params.get('page');
          setNextJobApplicationChoicesPage(page);
        }
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  };

  useEffect(() => {
    if (!jobApplicationData && jobApplicationChoices === undefined) {
      const url = `/api/v1/gamesandtests/business/${businessId}/jobapplications/`;
      fetchJobApplications();
    }
  }, []);

  const closeModal = () => {
    handleShowMoreFields.off();
    handleSubmittingData.off();
    handleVisible.off();
    resetFields();
    setExistingCandidate();
    handleShowMoreFields.off();
    setStep(0);
    setVerifyEmailError();
  }

  const handleDeleteApplication = () => {
    handleSubmittingData.on();
    deleteAndReturn(
      token,
      `/api/v1/gamesandtests/jobapplications/${jobApplicationData?.id}/candidates/${passiveCandidate.id}/`,
    )
      .then(async (response) => {
        if (response.status === 204) {
          try {
            if (passiveApplication?.status) {
              await getStageCandidates(passiveApplication.status);
              await setCandidates((prevCandidates) => ({
                ...prevCandidates,
                [passiveApplication.status]: {
                  ...prevCandidates[passiveApplication.status],
                  count: prevCandidates[passiveApplication.status].count - 1,
                },
              }));
            }
          } catch (error) {
            Sentry.captureException(error);
          }
          message.success(i18n.t('passive_candidates__userjobapplication_deleted_successfully'));
          closeModal();
          closeFakeModal && closeFakeModal();
        } else {
          message.error(i18n.t('passive_candidates__error_deleting_userjobapplication'));
        }
      })
      .catch(() => {
        message.error(i18n.t('passive_candidates__error_deleting_userjobapplication'));
      })
      .finally(handleSubmittingData.off);
  };

  const handleDeleteCandidate = () => {
    handleSubmittingData.on();
    deleteAndReturn(
      token,
      `/api/v1/accounts/business/${businessId}/passivecandidates/${passiveCandidate.id}/`,
    )
      .then((response) => {
        if (response.status === 204) {
          message.success(i18n.t('passive_candidates__candidate_deleted_successfully'));
          try {
            if (passiveApplication?.status && jobApplicationData) {
              getStageCandidates(passiveApplication.status);
              setCandidates((prevCandidates) => ({
                ...prevCandidates,
                [passiveApplication.status]: {
                  ...prevCandidates[passiveApplication.status],
                  count: prevCandidates[passiveApplication.status].count - 1,
                },
              }));
            } else if (closeOnDelete) {
              closeOnDelete();
            }
          } catch (error) {
            Sentry.captureException(error);
          }
          closeModal();
          closeFakeModal && closeFakeModal();
        } else {
          message.error(i18n.t('passive_candidates__error_deleting_candidate'));
        }
      })
      .catch(() => {
        message.error(i18n.t('passive_candidates__error_deleting_candidate'));
      })
      .finally(handleSubmittingData.off);
  };

  const deleteMenu = (
    <Menu>
      <Menu.Item
        onClick={() => Modal.confirm({
          cancelText: i18n.t('commons__cancel'),
          content: i18n.t('passive_candidates__delete_userjobapplication__confirm'),
          okText: i18n.t('commons__confirm'),
          onOk: handleDeleteApplication,
          title: i18n.t('commons__confirm_question'),
        })}
      >
        <Tooltip
          placement="right"
          title={i18n.t('passive_candidates__delete_userjobapplication__tooltip')}
        >
          {i18n.t('passive_candidates__delete_userjobapplication')}
        </Tooltip>
      </Menu.Item>
      <Menu.Item
        onClick={() => Modal.confirm({
          cancelText: i18n.t('commons__cancel'),
          content: i18n.t('passive_candidates__delete_candidate__confirm'),
          okText: i18n.t('commons__confirm'),
          onOk: handleDeleteCandidate,
          title: i18n.t('commons__confirm_question'),
        })}
      >
        <Tooltip
          placement="right"
          title={i18n.t('passive_candidates__delete_candidate__tooltip')}
        >
          {i18n.t('passive_candidates__delete_candidate')}
        </Tooltip>
      </Menu.Item>
    </Menu>
  );

  const handleSubmit = () => {
    validateFields(async (err, values) => {
      if (!err) {
        handleSubmittingData.on();
        const passiveCandidateBody = {
          email: values.email,
          other_contact_info: {
            linkedin: values.linkedin ?? null,
          },
          personaluser_fields: {
            name: values.name.trim(),
            surname: values.surname.trim(),
            phone_prefix: values.phone_prefix && values.phone ? values.phone_prefix : '',
            phone: values.phone ?? '',
            country: values.country ?? '',
          },
          source: values.source ?? null,
        };

        const passiveCandidateId = (
          (method === 'PATCH' && passiveCandidate?.id)
            ? `${passiveCandidate.id}/`
            : (
              (method === 'POST' && existingCandidate?.id)
                ? `${existingCandidate.id}/`
                : ''
            )
        );

        // Passive candidate
        try {
          const { status: candidateStatus, body } = await fetchAndReturn(
            token,
            `/api/v1/accounts/business/${businessId}/passivecandidates/${passiveCandidateId}`,
            (method === 'POST' && existingCandidate) ? 'PATCH' : method,
            passiveCandidateBody,
          );
          const newCv = candidateStatus === 201 || !(body.files?.cv);
          if (candidateStatus < 400) {
            setCandidateId(body.candidate_id);
            if (values.cv?.length && values.cv[0].name !== 'cv') {
              // Upload CV
              const method = newCv ? 'POST' : 'PATCH';
              try {
                await sendFile(
                  token,
                  `accounts/passivecandidates/${body.id}/files/${newCv ? '' : `${body.files.cv}/`}`,
                  values.cv[0],
                  'cv',
                  '',
                  'cv',
                  method
                )
              } catch {
                message.error(i18n.t('passive_candidates__error_uploading_cv'));
              }
            }

            if (jobApplicationData) {
              // Passive application
              const jobApplicationId = jobApplicationData?.id ?? values.job_application;
              const passiveApplicationBody = {
                status: values.stage,
                note: values.note ?? null,
              };
              if (method === 'POST') {
                passiveApplicationBody.candidate_id = body.candidate_id;
              }
              try {
                const { status: applicationStatus } = await fetchAndReturn(
                  token,
                  `/api/v1/gamesandtests/jobapplications/${jobApplicationId}/candidates/${method === 'POST' ? '' : `${body.candidate_id}/`}`,
                  method,
                  passiveApplicationBody,
                );
                if (applicationStatus < 400) {
                  if (applicationStatus === 201) {
                    if (values.stage && jobApplicationData) {
                      // Tablero de candidatos de un proceso
                      await getStageCandidates(values.stage);
                      await setCandidates((prevCandidates) => ({
                        ...prevCandidates,
                        [values.stage]: {
                          ...prevCandidates[values.stage],
                          count: prevCandidates[values.stage].count + 1,
                        },
                      }));
                    } else {
                      // Talent Pool
                      if (method === 'POST') {
                        setCandidates();
                      } else {
                        await updatePassiveCandidateData({ newApplication: true });
                      }
                    }
                  } else {
                    await updatePassiveCandidateData();
                  };
                  message.success(
                    applicationStatus === 201
                      ? i18n.t('passive_candidates__userjobapplication_created_successfully')
                      : i18n.t('passive_candidates__userjobapplication_updated_successfully')
                  );
                } else {
                  message.error(
                    method === 'POST'
                      ? i18n.t('passive_candidates__error_adding_candidate_to_process')
                      : i18n.t('passive_candidates__error_updating_candidate_in_process')
                  );
                }
              } catch (error) {
                Sentry.captureException(error);
                message.error(
                  method === 'POST'
                    ? i18n.t('passive_candidates__error_adding_candidate_to_process')
                    : i18n.t('passive_candidates__error_updating_candidate_in_process')
                );
              }
            } else {
              updatePassiveCandidateData(body);
              message.success(
                existingCandidate
                  ? i18n.t('passive_candidates__candidate_updated_successfully')
                  : i18n.t('passive_candidates__candidate_created_successfully')
              )
            }
          } else if (candidateStatus === 409) {
            message.error(i18n.t('passive_candidates__candidate_already_exists__error_message'));
          } else {
            message.error(
              method === 'POST'
                ? i18n.t('passive_candidates__error_creating_candidate')
                : i18n.t('passive_candidates__error_updating_candidate')
            );
          }
        } catch (error) {
          Sentry.captureException(error);
          message.error(
            method === 'POST'
              ? i18n.t('passive_candidates__error_creating_candidate')
              : i18n.t('passive_candidates__error_updating_candidate')
          );
        }

        handleSubmittingData.off();
      }
    });
  };

  const verifyEmail = () => {
    if (!getFieldValue('email')) {
      setStep(1);
      return;
    }

    handleSubmittingData.on();

    // Revisar si ya existe un candidato pasivo con ese correo
    fetchAndReturn(
      token,
      `/api/v1/accounts/business/${businessId}/passivecandidates/?email__iexact=${getFieldValue('email')}`,
      'GET',
    )
      .then(async ({ status, body }) => {
        if (status >= 400) {
          setVerifyEmailError(i18n.t('commons__database_error__try_later'));
          return;
        }
        if (body?.count > 0) {
          const candidateData = body?.results[0];
          setExistingCandidate({ ...candidateData });
          setCandidateId(candidateData.candidate_id);

          // Si existe, y además tiene cv registrado, traemos el cv
          if (candidateData.files?.cv) {
            const { status: statusCv, body: bodyCv } = await fetchAndReturn(
              token,
              `/api/v1/accounts/passivecandidates/${candidateData.id}/files/${candidateData.files.cv}/`,
              'GET',
            )
            if (statusCv < 400 && bodyCv?.file) setExistingCandidate(
              (prevCandidateData) => ({ ...prevCandidateData, files: { cv: bodyCv.file } })
            )
          }

          if (jobApplicationData) {
            const application = candidateData.applications.find(
              ({ job_application_id }) => job_application_id === jobApplicationData.id
            );
            if (application) setExistingCandidate(
              (prevCandidateData) => ({ ...prevCandidateData, isAlreadyInThisProcess: true })
            );
          }
        }
        setStep(1);
      })
      .catch(() => {})
      .finally(() => handleSubmittingData.off());
  };

  const handleAddToJobApplications = () => {
    let succesfullyAdded = 0;
    handleSubmittingData.on();
    const promiseArray = [];
    const auxSelectedJobApplications = selectedJobApplications?.filter(
      // To avoid sending requests to job applications that already have the candidate
      ({ id }) => !passiveCandidate.applications.includes(id)
    );
    auxSelectedJobApplications.forEach(({ id: jobAppId }) => {
      const passiveApplicationBody = { candidate_id: candidateId };
      promiseArray.push(
        fetchAndReturn(
          token,
          `/api/v1/gamesandtests/jobapplications/${jobAppId}/candidates/`,
          'POST',
          passiveApplicationBody,
        )
          .then(({ status: applicationStatus }) => {
            if (applicationStatus < 400) succesfullyAdded++;
          })
          .catch((error) => Sentry.captureException(error))
      );
    });

    Promise.all(promiseArray)
      .then(() => {
        if (succesfullyAdded === auxSelectedJobApplications.length) {
          message.success(i18n.t('passive_candidates__candidates_added_successfully'));
        } else {
          message.error(i18n.t('passive_candidates__error_adding_candidate_to_process'));
        }
        handleSubmittingData.off();
      });
    if (updatePassiveCandidateData) updatePassiveCandidateData();
  };

  return (
    <Modal
      className={method === 'PATCH' ? "passive-candidate-form-modal" : ""}
      footer={(
        <>
          {(method === 'PATCH' && step !== 2) ? (
            <Col span={8}>
              <Row type="flex" justify="start">
                {jobApplicationData
                  ? (
                    <Dropdown overlay={deleteMenu}>
                      <Button type="danger">
                        {i18n.t('commons__delete')}
                      </Button>
                    </Dropdown>
                  ) : (
                    <Tooltip
                      placement="top"
                      title={i18n.t('passive_candidates__delete_candidate__tooltip')}
                    >
                      <Button
                        onClick={() => Modal.confirm({
                          cancelText: i18n.t('commons__cancel'),
                          content: i18n.t('passive_candidates__delete_candidate__confirm'),
                          okText: i18n.t('commons__confirm'),
                          onOk: handleDeleteCandidate,
                          title: i18n.t('commons__confirm_question'),
                        })}
                        type="danger"
                      >
                        {i18n.t('commons__delete')}
                      </Button>
                    </Tooltip>
                  )}
              </Row>
            </Col>
          ) : <Col span={8} />}
          <Col span={16}>
            <Row type="flex" justify="end">
              <Col>
                <Button
                  disabled={submittingData}
                  onClick={closeModal}
                  style={{ marginRight: '1em' }}
                >
                  {i18n.t('commons__cancel')}
                </Button>
              </Col>
              <Col>
                {(method === 'POST' && step === 0)
                  ? (
                    <Button
                      disabled={getFieldError('email') || submittingData}
                      onClick={() => verifyEmail()}
                      type="primary"
                    >
                      {getFieldValue('email')
                        ? i18n.t('commons__continue')
                        : i18n.t('passive_candidates__continue_without_email')}
                    </Button>
                  )
                  : (
                    <Button
                      disabled={
                        submittingData
                        || (
                          step !== 2
                            && ((existingCandidate?.isAlreadyInThisProcess) || (!getFieldValue('name') && !getFieldValue('surname')))
                        )
                      }
                      onClick={() => {
                        if (step === 2) {
                          handleAddToJobApplications();
                          setExistingCandidate();
                          setStep(0);
                          handleShowMoreFields.off();
                          handleVisible.off();
                        } else {
                          handleSubmit();
                          resetFields();
                          setVerifyEmailError();
                          if (jobApplicationData || method === 'PATCH') {
                            setExistingCandidate();
                            setStep(0);
                            handleShowMoreFields.off();
                            handleVisible.off();
                          } else {
                            setStep(2);
                          }
                        }
                      }}
                      type="primary"
                    >
                      {method === 'PATCH'
                        ? i18n.t('profile__save__changes')
                        : (
                          jobApplicationData || step === 2
                            ? (
                              selectedJobApplications.length
                                ? i18n.t('passive_candidates__add_to_process')
                                : i18n.t('commons__finish')
                            )
                            : i18n.t('commons__continue')
                        )}
                    </Button>
                  )}
              </Col>
            </Row>
          </Col>
        </>
      )}
      onCancel={closeModal}
      title={
        <Text strong>
          {step === 2
            ? 'Agregar a procesos'
            : (
              method === 'PATCH'
                ? i18n.t('passive_candidates__update_passive_candidate')
                : i18n.t('passive_candidates__add_passive_candidate'))}
        </Text>
      }
      visible={visible}
      width={600}
    >
      <Spin spinning={submittingData}>
        {step !== 2 ? (
          <Alert
            closable
            description={
              <Text>
                {i18n.t('passive_candidates__modal_explanation').split('**')[0]}
                <Text strong>{i18n.t('passive_candidates__modal_explanation').split('**')[1]}</Text>
                {i18n.t('passive_candidates__modal_explanation').split('**')[2]}
              </Text>
            }
            message={i18n.t('passive_candidates__what_is_a_passive_candidate')}
            showIcon
            style={{ marginBottom: '1em' }}
            type="info"
          />
        ) : null}
        {(method === 'POST' && step === 1 && existingCandidate)
          ? (
            <Alert
              closable={false}
              description={(
                existingCandidate.isAlreadyInThisProcess && jobApplicationData
                  ? (
                    <a href={`/processes/${jobApplicationData.id}?id=${existingCandidate.id}`}>
                      {i18n.t('passive_candidates__go_to_profile')}
                    </a>)
                  : i18n.t('passive_candidates__existing_candidate_message')
              )}
              message={existingCandidate.isAlreadyInThisProcess
                ? i18n.t('passive_candidates__candidate_already_exists_in_process').replace('{{candidateEmail}}', existingCandidate.email)
                : i18n.t('passive_candidates__candidate_already_exists').replace('{{candidateEmail}}', existingCandidate.email)
              }
              showIcon={false}
              style={{ marginBottom: '1em' }}
              type="warning"
            />
          ) : null}
        {(method === 'POST' && step === 1)
          ? (
            <Button
              onClick={() => {
                handleShowMoreFields.off();
                setExistingCandidate();
                const email = getFieldValue('email');
                resetFields();
                setFieldsValue({ email });
                setStep(0);
              }}
              style={{ paddingLeft: 0 }}
              type="link"
            >
              <Icon type="left" />
              {i18n.t('form__go_back__title')}
            </Button>
          )
          : null}
        {step < 2 ? (
          <Form>
            <Row
              gutter={16}
              style={existingCandidate?.isAlreadyInThisProcess ? { display: 'none'} : {}}
              type="flex"
            >
              <Col
                span={24}
                style={(method === 'POST' && step === 0) ? { display: 'none' } : {}}
              >
                <Form.Item colon={false} label={null}>
                  {getFieldDecorator('cv', {
                    getValueFromEvent: uploadEvent => {
                      if (Array.isArray(uploadEvent)) {
                        return uploadEvent;
                      }
                      if (uploadEvent?.file?.status === 'removed') return;
                      return uploadEvent && [uploadEvent.file];
                    },
                    rules: [
                      { required: false },
                      {
                        validator: (rule, value, callback) => {
                          // Si tiene url, es el que estaba cargado de antes
                          if (value && !value[0].url && !acceptedCvFileTypes.includes(value[0].type)) {
                            callback(i18n.t('form__error__cv__3'));
                          } else if (value && value[0].size > 5242880) {
                            callback(i18n.t('form__error__cv__2'));
                          }
                          callback();
                        }
                      }
                    ],
                    valuePropName: 'fileList',
                  })(
                    <Upload.Dragger
                      accept=".doc,.docx,.pdf"
                      beforeUpload={() => false}
                      showUploadList={{
                        showPreviewIcon: false,
                        showDownloadIcon: false,
                        showRemoveIcon: true,
                      }}
                      style={{ width: '100%' }}
                    >
                      <p className="ant-upload-drag-icon">
                        <Icon style={{ color: '#F175A5' }} type="inbox" />
                      </p>
                      <p className="ant-upload-text">{i18n.t('passive_candidates__add_cv_here')}</p>
                      <p className="ant-upload-hint">{i18n.t('form__cv__extension__file__2')}</p>
                    </Upload.Dragger>
                  )}
                </Form.Item>
              </Col>
              <Col
                span={24}
                style={(method === 'POST' && step === 0) ? { display: 'none' } : {}}
              >
                <Form.Item
                  label={
                    <>
                      <Text style={{ color: 'red' }}>*</Text>
                      {i18n.t('form__names')}
                    </>
                  }
                >
                  {getFieldDecorator('name', {
                    rules: [{ required: true, message: i18n.t('passive_candidates__name_error') }],
                  })(
                    <Input
                      allowClear
                      placeholder={i18n.t('commons__write_here_placeholder')}
                      style={{ width: '100%' }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col
                span={24}
                style={(method === 'POST' && step === 0) ? { display: 'none' } : {}}
              >
                <Form.Item label={<><Text style={{ color: 'red' }}>*</Text>{i18n.t('form__lastname')}</>}>
                  {getFieldDecorator('surname', {
                    rules: [{ required: true, message: i18n.t('passive_candidates__surname_error') }],
                  })(
                    <Input
                      allowClear
                      placeholder={i18n.t('commons__write_here_placeholder')}
                      style={{ width: '100%' }}
                    />
                  )}
                </Form.Item>
              </Col>
              {(method !== 'PATCH' && jobApplicationData) ? (
                <>
                  <Col span={12} style={step === 0 ? { display: 'none' } : {}}>
                    <Form.Item
                      label={
                        <>
                          <Text style={{ color: 'red' }}>*</Text>
                          {i18n.t('commons__process')}
                        </>
                      }
                    >
                      {getFieldDecorator('job_application', {
                        initialValue: jobApplicationData?.job_application,
                        rules: [{ required: false }],
                      })(<Input disabled style={{ width: '100%' }} />)}
                    </Form.Item>
                  </Col>
                  <Col span={12} style={step === 0 ? { display: 'none' } : {}}>
                    <Form.Item
                      label={
                        <>
                          {getFieldValue('job_application')
                            ? <Text style={{ color: 'red' }}>*</Text>
                            : null}
                          {i18n.t('commons__stage')}
                        </>
                      }
                    >
                      {getFieldDecorator('stage', {
                        initialValue: stageOnFocus,
                        rules: getFieldValue['job_application']
                          ? [{ required: true, message: i18n.t('passive_candidates__stage_error') }]
                          : [{ required: false }],
                      })(
                        <Select>
                          {stages?.map(
                            ({ code, name }) => (<Option key={code} value={code}>{name}</Option>)
                          )}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </>
              ) : null}
              <Col
                span={24}
                style={
                  (method === 'POST' && step === 1 && (existingCandidate || !getFieldValue('email'))) 
                    ? { display: 'none' }
                    : {}
                }
              >
                <Form.Item
                  label={
                    <>
                      {i18n.t('commons__email')} <Text type="secondary"> ({i18n.t('commons__optional')})</Text>
                    </>
                  }
                >
                  {getFieldDecorator('email', {
                    rules: [
                      { required: false },
                      {
                        type: 'email',
                        message: i18n.t('commons__invalid_email'),
                      },
                    ],
                  })(
                    <Input
                      allowClear
                      disabled={method === 'POST' && step === 1}
                      placeholder={i18n.t('commons__write_here_placeholder')}
                      style={{ width: '100%' }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col
                span={24}
                style={(method === 'POST' && step === 0) ? { display: 'none' } : {}}
              >
                <Row gutter={16} type="flex">
                  <Col span={6}>
                    <Form.Item
                      colon={false}
                      label={
                        <>
                          {i18n.t('form__phone')} <Text type="secondary"> ({i18n.t('commons__optional')})</Text>
                        </>
                      }
                    >
                      {getFieldDecorator('phone_prefix', {
                        rules: [{ required: false }],
                      })(
                        <Select allowClear filterOption optionFilterProp="value" showSearch>
                          {countries?.map(({ country, phone_code }) => (
                            <Option key={`${country}-${phone_code}`} value={phone_code}>
                              {phone_code}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={18}>
                    <Form.Item label=" ">
                      {getFieldDecorator('phone', {
                        rules: [{ required: false }],
                      })(
                        <Input
                          allowClear
                          placeholder={i18n.t('commons__write_here_placeholder')}
                          style={{ width: '100%' }}
                          type='number'
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col
                span={24}
                style={showMoreFields ? {} : { display: 'none' }}
              >
                <Form.Item label={<>{i18n.t('form__residence_country')} <Text type="secondary"> ({i18n.t('commons__optional')})</Text></>}>
                  {getFieldDecorator('country', {
                    onChange: (option) => {
                      if (!getFieldValue('phone_prefix')) setFieldsValue(
                        { 
                          phone_prefix: countries?.find(
                            ({ country }) => country.toLowerCase() === option
                          ).phone_code,
                        }
                      );
                    },
                    rules: [{ required: false }],
                  })(
                    <Select allowClear filterOption optionFilterProp="value" showSearch>
                      {countries?.map(
                        ({ country }) => (
                          <Option key={country} value={country.toLowerCase()}>{country}</Option>
                        )
                      )}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col
                span={24}
                style={showMoreFields ? {} : { display: 'none' }}
              >
                <Form.Item
                  label={
                    <>
                      {i18n.t('passive_candidates__source')} <Text type="secondary"> ({i18n.t('commons__optional')})</Text>
                    </>
                  }
                >
                  {getFieldDecorator('source', {
                    rules: [{ required: false }],
                  })(
                    <Select
                      allowClear
                      placeholder={i18n.t('passive_candidates__lookup_source')}
                    >
                      {Object.keys(sources).map(
                        (code) => (<Option key={code} value={code}>{sources[code]}</Option>)
                      )}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col
                span={24}
                style={showMoreFields ? {} : { display: 'none' }}
              >
                <Form.Item
                  label={<>LinkedIn <Text type="secondary"> ({i18n.t('commons__optional')})</Text></>}
                >
                  {getFieldDecorator('linkedin', {
                    rules: [{ required: false }],
                  })(
                    <Input
                      allowClear
                      placeholder={i18n.t('passive_candidates__linkedin_profile_link')}
                      style={{ width: '100%' }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col
                span={24}
                style={(method === 'POST' && step === 0) ? { display: 'none' } : {}}
              >
                <Button
                  icon={showMoreFields ? 'up' : 'down'}
                  onClick={handleShowMoreFields.toggle}
                  style={{ padding: 0 }}
                  type="link"
                >
                  {showMoreFields
                    ? i18n.t('commons__show_less')
                    : i18n.t('commons__show_more')}
                </Button>
              </Col>
              {jobApplicationData ? (
                <Col
                  span={24}
                  style={(method === 'POST' && step === 0) ? { display: 'none' } : {}}
                >
                  <Form.Item
                    label={
                      <>
                        {i18n.t('commons__note')} <Text type="secondary"> ({i18n.t('commons__optional')})</Text>
                      </>
                    }
                  >
                    {getFieldDecorator('note', {
                      rules: [{ required: false }],
                    })(
                      <Input.TextArea
                        allowClear
                        placeholder={i18n.t('commons__write_here_placeholder')}
                        rows={4}
                      />
                    )}
                  </Form.Item>
                </Col>
              ) : null}
              {verifyEmailError ? <Alert message={verifyEmailError} type="error" /> : null}
            </Row>
          </Form>
        ) : (
          <SelectProcesses
            disabledJobApplications={passiveCandidate.applications.map(({ id }) => id)}
            fetchJobApplications={fetchJobApplications}
            jobApplicationChoices={jobApplicationChoices}
            nextPage={nextJobApplicationChoicesPage}
            selectedJobApplications={selectedJobApplications}
            setSelectedJobApplications={setSelectedJobApplications}
          />
        )}
      </Spin>
    </Modal>
  );
}

PassiveCandidateFormModal.propTypes = {
  actions: PropTypes.shape({
    deleteAndReturn: PropTypes.func,
    fetchAndReturn: PropTypes.func.isRequired,
    sendFile: PropTypes.func.isRequired,
  }).isRequired,
  businessId: PropTypes.string.isRequired,
  closeFakeModal: PropTypes.func,
  closeOnDelete: PropTypes.func,
  countries: PropTypes.arrayOf(PropTypes.shape({
    country: PropTypes.string.isRequired,
    phone_code: PropTypes.string.isRequired,
  })),
  forceStep: PropTypes.number,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    getFieldError: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
  getStageCandidates: PropTypes.func,
  handleVisible: PropTypes.shape({
    off: PropTypes.func.isRequired,
    on: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
  }).isRequired,
  jobApplicationData: PropTypes.shape({
    id: PropTypes.string,
    job_application: PropTypes.string,
  }) || undefined,
  method: PropTypes.string.isRequired,
  passiveApplication: PropTypes.shape({
    note: PropTypes.string,
    status: PropTypes.string.isRequired,
  }),
  passiveCandidate: PropTypes.shape({
    id: PropTypes.string.isRequired,
    personaluser_fields: PropTypes.shape({
      cv: PropTypes.string,
      name: PropTypes.string.isRequired,
      surname: PropTypes.string.isRequired,
      email: PropTypes.string,
      phone_prefix: PropTypes.string,
      phone: PropTypes.string,
      country: PropTypes.string,
      source: PropTypes.string,
    }),
    other_contact_info: PropTypes.shape({
      linkedin: PropTypes.string,
    }),
  }),
  setCandidates: PropTypes.func.isRequired,
  stages: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
  stageOnFocus: PropTypes.string,
  token: PropTypes.string.isRequired,
  updatePassiveCandidateData: PropTypes.func,
  visible: PropTypes.bool.isRequired,
};

const AddPassiveForm = Form.create({ name: 'add_passive_candidate_form' })(PassiveCandidateFormModal);

export default AddPassiveForm;
