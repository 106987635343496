import React from 'react';
import { Row, Col, Form, Input, Typography, Select } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../../actions/data';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';

const { Title } = Typography;
const { Option } = Select;

function PersonalDataDisabled({ form, personalData, onChangePersonalData }) {
  const { getFieldDecorator, getFieldValue } = form;

  return (
    <Form layout='horizontal' className='candidate-form-section' style={{ marginTop: 20 }}>
      <Row>
        <Title level={4}>{i18n.t('form__data__disabled__title')}</Title>
      </Row>
      <Row type='flex' gutter={20}>
        <Col span={24}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label={
                <>
                  <span className='label-required'>*</span>
                  <span>Email</span>
                </>
              }
            >
              {getFieldDecorator('Email', {
                initialValue: personalData?.email,
                rules: [
                  { required: true, message: i18n.t('form__complete__data') },
                ],
                onChange: onChangePersonalData('name'),
              })(<Input placeholder='Email' disabled/>)}
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label={
                <>
                  <span className='label-required'>*</span>
                  <span>RUT/DNI</span>
                </>
              }
            >
              {getFieldDecorator('DNI', {
                initialValue: personalData?.dni,
                rules: [
                  { required: true, message: i18n.t('form__complete__data') },
                ],
                onChange: onChangePersonalData('DNI'),
              })(<Input placeholder='DNI' disabled/>)}
            </Form.Item>
          </Col>
        </Col>
      </Row>
    </Form>
  );
}

PersonalDataDisabled.propTypes = {
  personalData: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  };
};

const WrappedPersonalData = Form.create()(PersonalDataDisabled);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedPersonalData);
