import * as actionCreators from '../../../../../../../../actions/data';
import * as actions from '../../../../../../../../actions/auth';

import {
  Button,
  Col,
  Divider,
  Input,
  Row,
  Select,
  Slider,
  Tooltip,
  Typography,
  message,
} from 'antd';
import React, { useEffect, useRef, useState } from "react";

import MceEditor from "../../../../mceeditor/MceEditor";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import debounce from '../../../../../../../../utils/debounce';

const { Title, Text } = Typography;
const { Option } = Select;

function GetOnBoardJobForm({ jobapp, visible, token, jobPortalIntegrations, actions, sendJobData }) {
  const [body, setBody] = useState({
    title: jobapp.job_application,
    projects: '',
    description: jobapp.description,
    description_headline: 'Requerimientos del cargo',
    functions: '',
    min_salary: 3000,
    max_salary: 4000,
    remote_modality: 'fully_remote',
    tenant_city_id: 0,
    modality_id: null,
    seniority_id: null,
    perks: [''],
    category_id: null
  })
  const [errors, setErrors] = useState({
    description_plain_text: [],
    projects_plain_text: [],
    functions_plain_text: [],
  });
  const [options, setOptions] = useState({
    cities: [],
    perks: [],
    seniorities: [],
    modalities: [],
    remote_modalities: [
      { id: 'fully_remote', name: 'Completamente remoto' },
      { id: 'remote_local', name: 'Remoto localmente' },
      { id: 'temporarily_remote', name: 'Temporalmente remoto' },
      { id: 'no_remote', name: 'Presencial' },
    ],
    categories: [],
  })
  const [id, setId] = useState(jobapp.job_portals['getonbrd']?.id);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef(null);

  const handleConnectionId = async (jobId) => {
    setLoading(true);
    const path = `api/v0/jobs/${jobId}`;
    const resp = await fetch(`${jobPortalIntegrations['getonbrd'].url}/${path}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Token ${jobPortalIntegrations['getonbrd'].token}`
      },
    })
    if (resp.status == 200) {
      // TODO: REVISAR SI HAY UNA JOBAPP CON ESE ID
      const url = `/api/v1/integrations/jobapplications/?name=getonbrd&id=${jobId}&api_key=${jobPortalIntegrations['getonbrd'].token}`
      actions.fetchAndReturn(token, url, 'GET').then((response) => {
        if (response.status != 404) {
          message.error('El id del proceso ya se encuentra asociado a otro proceso de nuestra plataforma.');
        } else {
          sendJobData({ id: jobId });
          setId(jobId);
          loadCurrentProcess(jobId);
        };
      })
    } else {
      if (jobId.length > 30) {
        message.error('Ocurrió un error, revisa el formulario y vuelve a intentar.');
      }
    }
    setLoading(false);
  }
  const debouncedHandleConnectionId = debounce(handleConnectionId, 2000);
  const loadCurrentProcess = async (idx) => {
    if (idx) {
      const path = `api/v0/jobs/${idx}`;
      const resp = await fetch(`${jobPortalIntegrations['getonbrd'].url}/${path}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Token ${jobPortalIntegrations['getonbrd'].token}`
        },
      })
      const { data } = await resp.json();
      data.attributes.seniority_id = data.attributes.seniority?.data?.id;
      const newBody = { ...body };
      Object.keys(body).forEach((key) => {
        newBody[key] = data.attributes[key] ? data.attributes[key] : newBody[key];
      })
      setBody(newBody);
    }
  }

  const loadCities = async () => {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Token ${jobPortalIntegrations['getonbrd'].token}`
    };

    let totalPages = undefined;
    let currentPage = 1;
    let cities = [];

    while (totalPages != currentPage) {
      await fetch(`${jobPortalIntegrations['getonbrd'].url}/api/v0/cities/?page=${currentPage}`, {
        method: 'GET',
        headers: headers,
      }).then((resp) => resp.json())
        .then(json => {
          currentPage = json.meta.page + 1;
          totalPages = json.meta.total_pages;

          cities = [...cities, ...json.data.map((e) => ({
            id: e.id,
            name: e.attributes.name,
            description: e.attributes.description ? e.attributes.description : '',
          }))]
        });
    };
    return cities;
  }

  useEffect(() => {
    loadCurrentProcess(id);
  }, [id])

  useEffect(() => {
    // Load all options
    const tempOptions = {
      cities: [],
      perks: [],
      seniorities: [],
      modalities: [],
      categories: [],
      remote_modalities: options.remote_modalities
    };
    const paths = ['perks', 'seniorities', 'modalities', 'categories']
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Token ${jobPortalIntegrations['getonbrd'].token}`
    };

    const cities = loadCities().then(result => tempOptions['cities'] = result.sort((a, b) => a.name.localeCompare(b.name)));

    const promises = paths.map(async (path) => {
      const resp = await fetch(`${jobPortalIntegrations['getonbrd'].url}/api/v0/${path}`, {
        method: 'GET',
        headers: headers,
      })
      const body = await resp.json();
      return body;
    });

    // Cities can have multiple pages, we request every page then continue with the rest
    cities.then(() => Promise.all(promises).then((results) => {
      paths.forEach((path, index) => {
        tempOptions[path] = results[index].data.map((e) => ({
          // id: e.attributes.locale_key ? e.attributes.locale_key : e.id,
          id: e.id,
          name: e.attributes.name,
          description: e.attributes.description ? e.attributes.description : '',
        }))
      })
      setOptions(tempOptions);
    }).catch((e) => {
      console.log(e);
    })
    )
  }, [])

  const handleInputChange = (attribute) => (e) => {
    const { value } = e.target;
    setBody({ ...body, [attribute]: value })
  }

  const handleSelectChange = (attribute) => (value) => {
    setBody({ ...body, [attribute]: value })
  }

  const handleSlideChange = ([minValue, maxValue]) => {
    setBody({ ...body, min_salary: minValue, max_salary: maxValue })
  }

  useEffect(() => {
    setBody((currentBody) => {
      return {
        ...currentBody,
        tenant_city_id: currentBody.tenant_city_id ? currentBody.tenant_city_id : options.cities[0] ? options.cities[0].id : '0',
        seniority_id: currentBody.seniority_id ? currentBody.seniority_id : options.seniorities[0] ? options.seniorities[0].id : '0',
        modality_id: currentBody.modality_id ? currentBody.modality_id : options.modalities[0] ? options.modalities[0].id : '0',
      }
    })
  }, [options])

  const postJobGOB = () => {
    const { url } = jobPortalIntegrations['getonbrd'];
    if (body.remote_modality === 'remote_local') {
      body.remote_zone = options.cities.find(x => x.id === body.tenant_city_id).name;
    }
    const formData = new FormData();
    Object.keys(body).forEach(key => {
      formData.append(key, body[key]);
    })
    const path = '/jobs'
    fetch(`${url}/api/v0${path}` + (id ? `/${id}` : ''), {
      method: id ? 'PATCH' : 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Token ${jobPortalIntegrations['getonbrd'].token}`
      },
      body: formData
    }).then(async (result) => {
      const response = await result.json()
      if (response.data) {
        window.open(`${jobPortalIntegrations['getonbrd'].url}/jobs/${response.data.id}/edit`);
        const msge = id ? 'Proceso vinculado correctamente' : 'Proceso creado correctamente'
        const messageContent = (
          <>
            <span>{msge}</span>
            <Button
              type='link'
              href={`${jobPortalIntegrations['getonbrd'].url}/jobs/${response.data.id}/edit`}
            >
              Ver
            </Button>
          </>
        )
        setId(response.data.id);
        message.success(messageContent);
        sendJobData({ id: response.data.id })
      } else if (response.message) {
        message.error('Ocurrió un error, revisa el formulario y vuelve a intentar.')
        // Cargar errores
        const newErrors = { ...errors };
        Object.keys(errors).forEach((key) => {
          newErrors[key] = response.message[key] ? response.message[key] : newErrors[key];
        })
        setErrors(newErrors);
        containerRef.current.scrollIntoView();
      }
    }).catch(e => {
      message.error('Ocurrió un error, por favor vuelve a intentarlo.');
    })

  }


  return (
    <>
      <Row>
        <Title level={2}>{id ? "Editar" : "Crear"} empleo en GetOnBoard</Title>
        <Text>
          Al finalizar y guardar el empleo, se deberá completar la {id ? "edición" : "creación"} en el portal de GetOnBoard.
        </Text>
        <Tooltip placement='topLeft' title='Si tu proceso ya está creado en GetOnBoard, puedes ingresar su ID aquí.'>
          <Title level={4}>ID del proceso</Title>
        </Tooltip>
        <Input type="text" disabled={id ? true : false} placeholder={id} onChange={(e) => e.target.value && debouncedHandleConnectionId(e.target.value)} />
      </Row>
      <Divider />
      <Row style={{ maxHeight: '60vh', overflow: 'auto' }}>
        <div id='top' ref={containerRef}></div>

        <Title level={4}>Titulo</Title>
        <Input type="text" value={body.title} onChange={handleInputChange('title')} />

        <Title level={4}>Titulo de la descripción</Title>
        <Input type="text" value={body.description_headline} onChange={handleInputChange('description_headline')} />

        <Title level={4}>Descripción del cargo<Text type="danger">*</Text></Title>
        {errors.description_plain_text.length ? <Text type="danger">{errors.description_plain_text[0]}</Text> : null}
        <MceEditor
          name="description"
          handleChange={handleInputChange}
          body={body.description}
        />

        <Title level={4}>Presenta al equipo de trabajo<Text type="danger">*</Text></Title>
        <Text>
          ¿Qué tipo de proyectos están llevando a cabo? ¿Cuál es el estilo de trabajo? ¿Cuál es la cultura del equipo?
        </Text>
        {errors.projects_plain_text.length ? <Text type="danger">{errors.projects_plain_text[0]}</Text> : null}
        <MceEditor
          name="projects"
          handleChange={handleInputChange}
          body={body.projects}
        />

        <Title level={4}>Funciones del cargo<Text type="danger">*</Text></Title>
        <Text>
          ¿En qué área o equipo trabajará este puesto? ¿Qué funciones tendrá? ¿De qué será responsable? ¿Cómo se medirá su éxito?
        </Text>
        {errors.functions_plain_text.length ? <Text type="danger">{errors.functions_plain_text[0]}</Text> : null}
        <MceEditor
          name="functions"
          handleChange={handleInputChange}
          body={body.functions}
        />

        <Title level={4}>Sueldo líquido</Title>
        <Text>Entre <strong>{body.min_salary}</strong> y <strong>{body.max_salary}</strong> USD/mes.</Text>
        <Row type='flex' justify='start'>
          <Col sm={18}>
            <Slider style={{ widht: '60%' }} max={20000} step={100} range value={[body.min_salary, body.max_salary]} onChange={handleSlideChange} />
          </Col>
        </Row>

        {/* Selects */}
        <Row type="flex" gutter={[10, 10]} justify='space-between'>
          <Col sm={12}>
            <Title level={4}>Ciudad</Title>
            <Select value={body.tenant_city_id} onChange={handleSelectChange('tenant_city_id')} style={{ width: 200 }}>
              {options.cities.map(x => {
                return (
                  <Option key={x.id} value={x.id} label={x.name}>{x.name}</Option>
                )
              })}
            </Select>
          </Col>
          <Col sm={12}>
            <Title level={4}>Nivel de experiencia</Title>
            <Select value={body.seniority_id?.toString()} onChange={handleSelectChange('seniority_id')} style={{ width: 200 }}>
              {options.seniorities.map(x => {
                return (
                  <Option key={x.id} value={x.id} label={x.name}>{x.name}</Option>
                )
              })}
            </Select>
          </Col>
          <Col sm={12}>
            <Title level={4}>Modalidad</Title>
            <Select value={body.modality_id} onChange={handleSelectChange('modality_id')} style={{ width: 200 }}>
              {options.modalities.map(x => {
                return (
                  <Option key={x.id} value={x.id} label={x.name}>{x.name}</Option>
                )
              })}
            </Select>

          </Col>
          <Col sm={12}>
            <Title level={4}>Modalidad remota</Title>
            <Select value={body.remote_modality} onChange={handleSelectChange('remote_modality')} style={{ width: 200 }}>
              {options.remote_modalities.map(x => {
                return (
                  <Option key={x.id} value={x.id} label={x.name}>{x.name}</Option>
                )
              })}
            </Select>
          </Col>
          <Col sm={12}>
            <Title level={4}>Categoría</Title>
            <Select value={body.category_id} onChange={handleSelectChange('category_id')} style={{ width: 200, marginBottom: '1em' }}>
              {options.categories.map(x => {
                return (
                  <Option key={x.id} value={x.id} label={x.name}>{x.name}{x.id}</Option>
                )
              })}
            </Select>
          </Col>
        </Row>

      </Row>
      <Row type="flex" justify="end" align="middle">
        <Button onClick={postJobGOB} type="primary">{id ? 'Guardar' : 'Crear'} proceso</Button>
      </Row>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    jobPortalIntegrations: state.auth.jobPortalIntegrations
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    actionsAuth: bindActionCreators(actions, dispatch),
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GetOnBoardJobForm);
