import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Sentry from '@sentry/browser';

import {
  Button,
  Card,
  Col,
  Icon,
  List,
  Modal,
  Row,
  Tag,
  Typography,
  message,
} from 'antd';
import PropTypes from 'prop-types';

import EmptySection from './Empty';
import { careerFields, certificationFields, experienceFields, skillsFields } from '../constants';
import * as actionCreators from '../../../../actions/data';
import i18n from '../../../../i18n';

const { Text, Title } = Typography;

// NOTE: Quizá esto debería ir en index.jsx?
const formFields = {
  careers: { ...careerFields },
  certifications: { ...certificationFields },
  experiences: { ...experienceFields },
  skills: { ...skillsFields },
}

function AdditionalInformationSection(props) {
  const {
    addButtonSubmitMethod,
    addText,
    candidateFieldName,
    data,
    emptySubtitle,
    emptyTitle,
    handleDelete,
    handleResponse,
    rawData,
    sectionTitle,
    showForm,
    submitUrl,
    type,
  } = props;

  return (
    <Card bodyStyle={{ paddingTop: 0 }} className='profile-section-card'>
      <Row
        align="middle"
        justify="space-between"
        style={{ paddingBottom: '1em', paddingTop: '1em', width: '100%' }}
        type="flex"
      >
        <Col xs={24} sm={12}>
          <Title level={4} style={{ marginBottom: 0 }}>{sectionTitle}</Title>
        </Col>
        <Col>
          <Button
            className='profile-section-card__update'
            onClick={() => showForm(() => ({
              config: {
                data: type === 'list' ? undefined : { [candidateFieldName]: data },
                fields: formFields[candidateFieldName],
                handleResponse,
                method: addButtonSubmitMethod,
                submitUrl,
              },
              title: sectionTitle,
            }))}
          >
            <Icon type="plus" />
            {addText}
          </Button>
        </Col>
      </Row>

      {data?.length ? (
        type === 'list' ? (
          <List bordered={false}>
            {data.map((item, index) => (
              <List.Item key={item.id} style={{ minWidth: '100%' }}>
                <Row align="middle" justify="space-between" style={{ width: '100%' }} type="flex">
                  <Col xs={24} sm={21}>
                    <Row align="middle" gutter={0} justify="space-between" style={{ width: '100%' }} type="flex">
                      <Col xs={24} sm={12}>
                        <Text strong style={{ color: '#597EF7'}}>{item.title}</Text>
                        <br />
                        <Text type="secondary">{item.subtitle}</Text>
                      </Col>
                      <Col className='profile-section-card__extra-info' xs={24} sm={12}>
                        {item.startDate} - {item.endDate}
                        <br />
                        {item.extra}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={2}>
                    <Row align="middle" gutter={0} justify="space-around" type="flex">
                      <Col>
                        <Icon
                          onClick={() => showForm(() => ({
                            config: {
                              data: rawData[index],
                              fields: formFields[candidateFieldName],
                              handleResponse,
                              submitUrl,
                            },
                            title: sectionTitle,
                          }))}
                          style={{ color: '#F175A5', cursor: 'pointer' }}
                          type="edit"
                        />
                      </Col>
                      <Col>
                        <Icon
                          onClick={() => Modal.confirm({
                            cancelText: i18n.t('commons__cancel'),
                            content: i18n.t('commons__confirm_question'),
                            okText: i18n.t('commons__confirm'),
                            onOk: () => handleDelete(item, index),
                            title: `${i18n.t('commons__delete')} ${i18n.t('commons__career')} ${item.title}`,
                          })}
                          style={{ cursor: 'pointer' }}
                          type="delete" 
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </List.Item>
            ))}
          </List>
        ) : (
          <Row type="flex">
            {data.map((item, index) => (
              <Tag
                closable
                key={index}
                onClose={() => handleDelete(item)}
              >
                {item}
              </Tag>
            ))}
          </Row>
        )
      ) : (
        <EmptySection subtitle={emptySubtitle} title={emptyTitle} />
      )}
    </Card>
  );
}

AdditionalInformationSection.propTypes = {
  addButtonSubmitMethod: PropTypes.string.isRequired,
  addText: PropTypes.string.isRequired,
  candidateFieldName: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      endDate: PropTypes.string,
      extra: PropTypes.string,
      startDate: PropTypes.string,
      subtitle: PropTypes.string,
      title: PropTypes.string,
    })),
  ]).isRequired,
  emptySubtitle: PropTypes.string,
  emptyTitle: PropTypes.string,
  handleDelete: PropTypes.func.isRequired,
  handleResponse: PropTypes.func.isRequired,
  rawData: PropTypes.array.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  showForm: PropTypes.func.isRequired,
  submitUrl: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

AdditionalInformationSection.defaultProps = {
  emptySubtitle: '',
  emptyTitle: '',
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actionCreators, dispatch),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalInformationSection);