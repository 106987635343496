import React, { useState } from 'react'

export default function PrivacyPolicyComponent() {

    return (
        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', width: '100%', height: '100vh' }}>
            <iframe credentialless='' style={{ width: '50vw', height: '100%' }} src="https://docs.google.com/document/u/1/d/e/2PACX-1vT4odFJk2URzMvEF3GADBndjXdLqVhPHaGNEdhEvZmieRH00HQiamys_mQECH-17aXTbiUaW2iqSiek/pub?embedded=true">
            </iframe>
        </div>
    )
}
