import React from 'react';
import { Typography, Button } from 'antd';
import '../JobListing.scss';

const CoverImage = (props) => {

  const { Title, Text } = Typography;

  const getQuerystring = (key) => {
    const queryString = new URLSearchParams(window.location.search);
    return queryString.get(key) || '';
  }

  return (
    <div>
      <div className='jl__img-container'>
        <img alt='Portada empresa' src={props.backgroundImage} className='jl__background-img' />
      </div>

      <div className='jl__overlay'>
        <div className='jl__centered-text'>
          <Title level={1}>
            {
              !getQuerystring('hide_title').includes('true') &&
              props.business_user
            }
          </Title>

          <div className='jl__centered-text__btns'>
            <Button
              size='large'
              onClick={props.handleNavigateToDescription}
              style={{
                background: props.theme.background,
                borderColor: props.theme.background,
                color: props.theme.color,
              }}
            >Sobre la empresa
            </Button>

            {props.results.length !== 0 &&
              <Button
                size='large'
                onClick={props.handleNavigateToJobs}
                style={{
                  background: props.theme.background,
                  borderColor: props.theme.background,
                  color: props.theme.color,
                }}
              >Ver empleos
              </Button>}
          </div>

          <section className='jl__rrss'>
            {props.facebook ||
              props.linkedin ||
              props.instagram ||
              props.twitter ?
              (<Text strong>Redes Sociales:</Text>) : null
            }
            <div className='jl__rrss-box'>
              {props.facebook &&
                <a target='_blank' href={props.facebook}>
                  <img src='https://genoma-assets.s3.us-east-2.amazonaws.com/jobsite-assets/facebook-icon.png' />
                </a>}
              {props.linkedin &&
                <a target='_blank' href={props.linkedin}>
                  <img src='https://genoma-assets.s3.us-east-2.amazonaws.com/jobsite-assets/linkedin-icon.png' />
                </a>}
              {props.instagram &&
                <a target='_blank' href={props.instagram}>
                  <img src='https://genoma-assets.s3.us-east-2.amazonaws.com/jobsite-assets/instagram-icon.png' />
                </a>}
              {props.twitter &&
                <a target='_blank' href={props.twitter}>
                  <img src='https://genoma-assets.s3.us-east-2.amazonaws.com/jobsite-assets/twitter-icon.png' />
                </a>}
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default CoverImage;
