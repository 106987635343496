import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Typography, Select } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../../actions/data';
import { comunas } from './countryAndComunne';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';

const { Title } = Typography;
const { Option } = Select;


const dias = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
];

function PersonalData({
  form,
  personalData,
  onChangePersonalData,
  setFormError,
  checkErrors,
  birthDay,
  setBirthDay,
  meses,
  countries,
}) {
  const { getFieldDecorator, getFieldsError, setFieldsValue } = form;
  const [years, setYears] = useState([]);

  const daysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };

  const updateDays = (daysCount) => {
    let days = [];
    for (var i = 1; i <= daysCount; i++) {
      days.push(i);
    }
    setBirthDay({ ...birthDay, dias: days });
  };

  const handleChangeDate = (dateElem) => (event) => {
    let daysCount = 0;
    let mesIndex = 0;
    switch (dateElem) {
      case 'day':
        setBirthDay({ ...birthDay, day: event });
        break;
      case 'month':
        mesIndex = meses.indexOf(event) + 1;
        daysCount = daysInMonth(mesIndex, birthDay.year);
        updateDays(daysCount);
        setBirthDay({ ...birthDay, month: event });
        break;
      case 'year':
        mesIndex = meses.indexOf(birthDay.month) + 1;
        daysCount = daysInMonth(mesIndex, event);
        updateDays(daysCount);
        setBirthDay({ ...birthDay, year: event });
        break;
    }
  };

  useEffect(() => {
    let fieldsError = getFieldsError();
    setFormError(checkErrors(fieldsError));
  }, [personalData]);

  useEffect(() => {
    /* seteo lo años */
    let nowDate = new Date();
    let fullYear = [];
    for (let i = nowDate.getFullYear(); i >= 1920; i--) {
      fullYear.push(i);
    }
    setYears(fullYear);

    /*seteo el state local para las fechas de nacimiento */
    if (personalData.birth_date !== undefined) {
      let birthArray =
        personalData.birth_date === null
          ? [undefined, undefined, undefined]
          : personalData?.birth_date?.split('-');
      const year = birthArray[0] ? Number.parseInt(birthArray[0]) : birthArray[0];
      const month = birthArray[1] ? meses[Number.parseInt(birthArray[1]) - 1] : birthArray[1];
      const day = birthArray[2] ? Number.parseInt(birthArray[2]) : birthArray[2];
      setBirthDay({ year, month, day });
    }
  }, [personalData.birth_date]);

  const capitalize = (words) => {
    const result = words.split(" ").map((word) => { 
      return word[0].toUpperCase() + word.substring(1); 
    }).join(" ");
    return result;
  }

  return (
    <div className='candidate-form-section'>
      <Row>
        <Title level={4}>{i18n.t('commons__personal__data')}</Title>
      </Row>
      <Row type='flex' gutter={20} >
        <Col span={24}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label={
                <>
                  <span className='label-required'>*</span>
                  <span>{i18n.t('form__names').split('(')[0]}</span>
                </>
              }
            >
              {getFieldDecorator('Nombre', {
                initialValue: personalData?.name,
                rules: [
                  { required: true, message: i18n.t('form__complete__data') },
                ],
                onChange: onChangePersonalData('name'),
              })(<Input placeholder={i18n.t('form__names').split('(')[0]} />)}
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label={
                <>
                  <span className='label-required'>*</span>
                  <span>{i18n.t('form__lastname').split('(')[0]}</span>
                </>
              }
            >
              {getFieldDecorator('Apellido', {
                initialValue: personalData?.surname,
                rules: [
                  { required: true, message: i18n.t('form__complete__data') },
                ],
                onChange: onChangePersonalData('surname'),
              })(<Input placeholder={i18n.t('form__lastname').split('(')[0]} />)}
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label={
                <>
                  <span className='label-required'>*</span>
                  <span>{i18n.t('form__birth_date')}</span>
                </>
              }
            >
              <Row gutter={5}>
                
                {(i18n.language.split('-')[0] == 'en' ? [
                  { label: i18n.t('commons__month'), value: 'month', list: meses },
                  { label: i18n.t('commons__day'), value: 'day', list: dias },
                  { label: i18n.t('commons__year'), value: 'year', list: years },
                ] : [
                  { label: i18n.t('commons__day'), value: 'day', list: dias },
                  { label: i18n.t('commons__month'), value: 'month', list: meses },
                  { label: i18n.t('commons__year'), value: 'year', list: years },
                ]).map(({ label, value, list }) => (
                  <Col
                    xs={value === 'month' ? 9 : value === 'year' ? 8 : 7}
                    sm={24}
                    md={8}
                    key={value}
                  >
                    <Form.Item key={label}>
                      {getFieldDecorator(value, {
                        initialValue: birthDay[value],
                        onChange: handleChangeDate(value),
                        rules: [{ required: true, message: `${label}*` }],
                      })(
                        <Select
                          key={label}
                          placeholder={label}
                          showSearch
                          optionFilterProp='children'
                          notFoundContent={i18n.t('commons__no__results')}
                        >
                          {list.map((item, index) => (
                            <Option key={index} value={item}>
                              {item}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                ))}
              </Row>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label={
                <>
                  <span>{i18n.t('form__nationality')}</span>
                </>
              }
            >
              {getFieldDecorator('Nacionalidad', {
                initialValue: personalData?.nationality,
                rules: [
                  { required: false, message: i18n.t('form__complete__data') },
                ],
                onChange: onChangePersonalData('nationality'),
              })(
                <Select
                  showSearch
                  optionFilterProp='children'
                  notFoundContent={i18n.t('commons__no__results')}
                >
                  {countries.map((elem) => (
                    <Select.Option key={elem} value={elem.country.toLowerCase()}
                    >
                      {capitalize(elem.translation ? elem.translation[i18n.language.split('-')[0]]: elem)}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          {/* <Col xs={24} sm={24} md={12}>
            <Form.Item
              label={
                <>
                  <span>{i18n.t('profile__marital_status')}</span>
                </>
              }
            >
              {getFieldDecorator('Estado civil', {
                initialValue: personalData?.marital_status,
                rules: [
                  { required: false, message: i18n.t('form__complete__data') },
                ],
                onChange: onChangePersonalData('marital_status'),
              })(
                <Select
                  placeholder={i18n.t('profile__marital_status')}
                  showSearch
                  optionFilterProp='children'
                  notFoundContent={i18n.t('commons__no__results')}
                >
                  <Option value='Casado'>{i18n.t('profile__married')}</Option>
                  <Option value='Soltero'>{i18n.t('profile__single')}</Option>
                  <Option value='Divorciado'>{i18n.t('profile__divorce')}</Option>
                </Select>
              )}
            </Form.Item>
          </Col> */}
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label={
                <>
                  <span className='label-required'>*</span>
                  <span>{i18n.t('form__residence_country')}</span>
                </>
              }
            >
              {getFieldDecorator('País de residencia', {
                initialValue: personalData?.country,
                rules: [
                  { required: true, message: i18n.t('form__complete__data') },
                ],
                onChange: (value) => {
                  onChangePersonalData('country')(value);
                  const filteredCountries = countries.filter(
                    (elem) => elem.country.toLowerCase() === value.toLowerCase()
                  );
                  if (filteredCountries.length === 1) {
                    setFieldsValue({ 'Prefijo Teléfono': filteredCountries[0].phone_code });
                    onChangePersonalData('phone_prefix')(filteredCountries[0].phone_code);
                  }
                },
              })(
                <Select
                  placeholder={i18n.t('commons__select')}
                  showSearch
                  optionFilterProp='children'
                  notFoundContent={i18n.t('commons__no__results')}
                >
                  {countries.map((country, index) => (
                    <Option key={index} value={country.country.toLowerCase()} >
                      {country.translation[i18n.language.split('-')[0]]}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          {personalData?.country === 'Chile' && (
            <Col xs={24}>
              <Form.Item
                label={
                  <>
                    <span>Comuna</span>
                  </>
                }
              >
                {getFieldDecorator('commune', {
                  initialValue:
                    personalData.commune === ''
                      ? undefined
                      : personalData.commune,
                  onChange: onChangePersonalData('commune'),
                  rules: [
                    {
                      required: false,
                      message: i18n.t('form__country__error'),
                    },
                  ],
                })(
                  <Select showSearch placeholder={i18n.t('commons__select')}>
                    {comunas.map((comuna, index) => (
                      <Option key={index} >
                        {comuna}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label={
                <>
                  <span className='label-required'>*</span>
                  <span>{i18n.t('form__phone')}</span>
                </>
              }
            >
              <Row type='flex' justify='space-between' gutter={20}>
                <Col xs={12} sm={24} md={12}>
                  <Form.Item>
                    {getFieldDecorator('Prefijo Teléfono', {
                      initialValue: personalData?.phone_prefix,
                      rules: [
                        {
                          required: true,
                          message: i18n.t('form__complete__data'),
                        },
                      ],
                      onChange: onChangePersonalData('phone_prefix'),
                    })(
                      <Select
                        label='Código de Área'
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                      {countries.map((option, index) => (
                        <Select.Option key={index} value={option.phone_code}>
                          {`${option.translation[i18n.language.split('-')[0]]} (${option.phone_code})`}
                        </Select.Option>
                      ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={12} sm={24} md={12}>
                  <Form.Item>
                    {getFieldDecorator('Teléfono', {
                      initialValue: personalData?.phone,
                      rules: [
                        {
                          required: true,
                          message: i18n.t('form__phone__error'),
                        },
                      ],
                      onChange: (e) =>
                        onChangePersonalData('phone')({
                          target: { value: e.target.value.replace(/\D/g, '') },
                        }),
                    })(<Input placeholder={i18n.t('form__phone')} type='number' />)}
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Col>
      </Row>
    </div>
  );
}

PersonalData.propTypes = {
  personalData: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  };
};

const WrappedPersonalData = Form.create()(PersonalData);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedPersonalData);
