import React, { useState, useEffect, useContext } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  Row,
  Col,
  Typography,
  Icon,
  Modal,
  Tooltip,
  Button,
  Radio,
  message,
} from 'antd';
import moment from 'moment';

import * as actionCreators from '../../../actions/data';

import { CandidateContext } from '../config/CandidateContext';

import Collapse from './Collapse';

import useBoolean from '../../../hooks/useBoolean';
import i18n from '../../../i18n';

const { Paragraph, Text } = Typography;

// config
const statusHandler = {
  PEND: {
    icon: 'exclamation-circle',
    color: '#FC8728',
    tooltipText: 'Cuestionario no enviado aún',
    answersTitle: '',
    sendQuestionnaire: true,
  },
  MAIL: {
    icon: 'clock-circle',
    color: '#1890FF',
    tooltipText: i18n.t('profile__send_waiting_for_answer', { ns: 'candidateProfile' }),
    answersTitle: i18n.t('profile__waiting_for_answer', { ns: 'candidateProfile' }),
    sendQuestionnaire: true,
  },
  D_ML: {
    icon: 'check-circle',
    color: '#52C41A',
    tooltipText: i18n.t('profile__reference_answered', { ns: 'candidateProfile' }),
    answersTitle: i18n.t('profile__answers', { ns: 'candidateProfile' }),
    sendQuestionnaire: false,
  },
  D_PH: {
    icon: 'check-circle',
    color: '#52C41A',
    tooltipText: i18n.t('profile__reference_answered', { ns: 'candidateProfile' }),
    answersTitle: 'Respondida por teléfono',
    sendQuestionnaire: false,
  },
  DECL: {
    icon: 'close-circle',
    color: '#F5222D',
    tooltipText:  i18n.t('profile__reference_rejected', { ns: 'candidateProfile' }),
    answersTitle: i18n.t('profile__request_rejected', { ns: 'candidateProfile' }),
    sendQuestionnaire: false,
  },
};

// single ref
const Reference = ({
  reference,
  sendQuestionnaire,
  completeQuestionnaire,
  referenceTemplates,
}) => {
  const [showBody, handleBody] = useBoolean(false);
  const { name, organization, relation, status, template_id, date_completed } = reference;

  const [questionnaireSent, setQuestionnaireSent] = useState(status === 'MAIL');
  const [questionnaireComplete, setQuestionnaireComplete] = useState(
    ['D_ML, D_PH'].includes(status)
  );
  const [newStatus, setNewStatus] = useState();
  const [showReferenceTemplatesModal, setShowReferenceTemplatesModal] =
    useState(false);
  const [selectedReferenceTemplate, setSelectedReferenceTemplate] =
    useState(template_id);

  const createReference = (ref) => {
    let questions = [];

    if (ref.answer) {
      questions = Object.values(ref.answer);
    }

    return (
      <>
        <div className="new-modal-references__ref__body__information">
          <Text strong>{i18n.t('profile__contact_information', { ns: 'candidateProfile' })}</Text>
          <Text>
            <strong>{i18n.t('commons__email', { ns: 'candidateProfile' })}:</strong> {ref.email}
          </Text>
          <Text>
            <strong>{i18n.t('commons__phone', { ns: 'candidateProfile' })}:</strong> {ref.phone}
          </Text>
        </div>
        {date_completed
          ? (
            <div className="new-modal-references__ref__body__information">
              <Text>
                {i18n.t('profile__reference_answered_date', { ns: 'candidateProfile' }).replace('{{date}}', date_completed)}
              </Text>
            </div>
          )
          : null}
        <div className="new-modal-references__ref__body__questions">
          <Text strong>
            {!questionnaireComplete
              ? statusHandler[ref.status].answersTitle
              : i18n.t('profile__reference_answered', {ns: 'candidateProfile'})}
          </Text>
          {Object.keys(questions).map((questionId, idx) => (
            <div>
              <Text key={`${questionId}`} strong>
                {questions[questionId].position
                  ? questions[questionId].position + 1
                  : idx + 1}
                . {questions[questionId].question}
              </Text>
              {'singleselection'.includes(questions[questionId].type) ? (
                parseInt(questions[questionId].answer) <
                questions[questionId].configuration?.alternatives.length ? (
                  <Text style={{ marginLeft: '1em' }}>
                    {
                      questions[questionId].configuration?.alternatives[
                        questions[questionId].answer
                      ]
                    }
                  </Text>
                ) : (
                  <Text style={{ marginLeft: '1em' }}>
                    Respuesta no disponible
                  </Text>
                )
              ) : 'multiselection'.includes(questions[questionId].type) ? (
                questions[questionId].answer.map(
                  (alternativeIndex) =>
                    parseInt(alternativeIndex) <
                      questions[questionId].configuration?.alternatives
                        .length && (
                      <Text style={{ marginLeft: '1em' }}>
                        {
                          questions[questionId].configuration?.alternatives[
                            alternativeIndex
                          ]
                        }
                      </Text>
                    )
                )
              ) : (
                questions[questionId].answer
              )}
            </div>
          ))}
        </div>

        {!window.location.toString().includes('/sharedprofile/') &&
          (((newStatus && statusHandler[newStatus].sendQuestionnaire) ||
            statusHandler[ref.status].sendQuestionnaire) &&
          !questionnaireComplete ? (
            <div className="new-modal-references__ref__body__buttons">
              <Button
                className="send-questionnaire"
                onClick={() => setShowReferenceTemplatesModal(true)}
                style={{ color: '#F175A5', borderColor: '#F175A5' }}
              >
                {questionnaireSent
                  ? i18n.t('profile__resend_questionnaire', { ns: 'candidateProfile' })
                  : i18n.t('profile__send_questionnaire',  { ns: 'candidateProfile' })}
              </Button>
              <Button
                onClick={() =>
                  completeQuestionnaire(
                    ref.id,
                    message,
                    setQuestionnaireComplete,
                    setNewStatus
                  )
                }
              >
                {i18n.t('profile__mark_as_completed', { ns: 'candidateProfile' })}
              </Button>
            </div>
          ) : ref.status === 'D_PH' || newStatus === 'D_PH' ? (
            <Button
              onClick={() =>
                completeQuestionnaire(
                  ref.id,
                  message,
                  setQuestionnaireComplete,
                  setNewStatus,
                  false
                )
              }
              style={{ width: 'fit-content' }}
            >
              {i18n.t('profile__unmark_completed', { ns: 'candidateProfile' })}
            </Button>
          ) : null)}
      </>
    );
  };

  return (
    <div className="new-modal-references__ref">
      <div
        className="new-modal-references__ref__header"
        onClick={handleBody.toggle}
      >
        <Text>{`${name} - ${relation} ${i18n.t('profile__in', { ns: 'candidateProfile' })} ${organization}`}</Text>
        <Tooltip
          placement="top"
          title={
            newStatus
              ? statusHandler[newStatus].tooltipText
              : statusHandler[status].tooltipText
          }
        >
          <Icon
            type={
              newStatus
                ? statusHandler[newStatus].icon
                : statusHandler[status].icon
            }
            style={{
              color: newStatus
                ? statusHandler[newStatus].color
                : statusHandler[status].color,
            }}
          />
        </Tooltip>
        <Icon
          type={!showBody ? 'right' : 'up'}
          style={{ marginLeft: 'auto' }}
        />
      </div>
      {showBody ? (
        <div className="new-modal-references__ref__body">
          {createReference(reference)}
        </div>
      ) : null}
      <Modal
        visible={showReferenceTemplatesModal}
        title={i18n.t('profile__reference_templates', { ns: 'candidateProfile' })}
        cancelText={i18n.t('profile__cancel', { ns: 'candidateProfile' })}
        onCancel={() => setShowReferenceTemplatesModal(false)}
        okText={i18n.t('profile__sent_to', { ns: 'candidateProfile' })}
        onOk={() => {
          sendQuestionnaire(reference.id, selectedReferenceTemplate, message, setQuestionnaireSent, setNewStatus);
          setShowReferenceTemplatesModal(false);
        }}
        okButtonProps={{ disabled: selectedReferenceTemplate === null }}
        style={{ fontSize: '14px', fontWeight: 400 }}
      >
        <Paragraph>
          {i18n.t('profile__select_referral_questionnaire', { ns: 'candidateProfile' })}
        </Paragraph>
        {questionnaireSent && (
          <Paragraph>
            <strong>{i18n.t('profile__important_in_questionnaire', { ns: 'candidateProfile' })}</strong>
          </Paragraph>
        )}
        <Radio.Group
          onChange={(e) => setSelectedReferenceTemplate(e.target.value)}
          value={selectedReferenceTemplate}
        >
          {referenceTemplates.length && referenceTemplates.map(({id, name}) => (
            <Radio style={{ display: 'block', marginBottom: '10px' }} key={id} value={id}>{name}</Radio>
          ))}
        </Radio.Group>
      </Modal>
    </div>
  );
};

// main component
const References = ({ business, actions, token }) => {
  const {
    application: { references, last_reference_date },
    sendReferencesQuestionnaire,
    completeReferenceQuestionnaire,
    translationFile
  } = useContext(CandidateContext);

  const [referenceTemplates, setReferenceTemplates] = useState([]);
  useEffect(() => {
    if (business?.id) {
      const url = `/api/v1/gamesandtests/business/${business.id}/referencetemplates/`;
      actions.fetchAndReturn(token, url).then((response) => {
        setReferenceTemplates(
          response.body.results
            .filter(({ status }) => status === 'ACTIV')
            .map(({ id, name }) => ({ id, name }))
        );
      });
    }
  }, [business]);

  return references.length ? (
    <Collapse icon="usergroup-add" title={i18n.t('profile__references', translationFile)}>
      {references.length ? (
        <div className="new-modal-references">
          <Text strong>{i18n.t('profile__references', translationFile)}</Text>
          <Text style={{ color: '#A4A4A4' }}>
            {i18n.t('profile__last_references_sent', translationFile).split(':')[0]}: {moment(last_reference_date).format('DD/MM/YYYY')}
          </Text>
          {references.map((reference) => (
            <Reference
              reference={reference}
              sendQuestionnaire={sendReferencesQuestionnaire}
              completeQuestionnaire={completeReferenceQuestionnaire}
              referenceTemplates={referenceTemplates}
            />
          ))}
        </div>
      ) : (
        `${i18n.t('profile__does_not_have', { ns: 'candidateProfile' })}.`
      )}
    </Collapse>
  ) : null;
};

const mapStateToProps = (state) => {
  return {
    business: state.auth.currentBusiness,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(References);
