import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Card, Col, Form, Icon, Input, InputNumber, Row, Select, Tag, Typography } from 'antd';
import MceEditor from '../../../BusinessDashboard/NewProcessFormV2/components/mceeditor/MceEditor.js';
import TagInput from '../../../../components/TagInput';

const { Option } = Select;

export const actionsOptions = {
  'MSG': {icon: 'mail', label: 'Enviar mensaje'},
  'ADDTAG': {icon: 'tag', label: 'Etiquetar'},
  'MOVE': {icon: 'arrow-right', label: 'Mover de etapa'},
  'DISCARD': {icon: 'delete', label: 'Descartar'}
}

const Action = ({ action, removeAction, stages, actions, setActions, availableActions, actionIndex, jobAppTags, selectedStage, businessUserEmail, form }) => {
  const [tags, setTags] = useState(jobAppTags);
  const { getFieldDecorator } = form;

  useEffect(() => {
    const actionType = actions[actionIndex]?.type;
    if (actionType && actions[actionIndex].config === undefined) {
      changeAction(actionType);
    };
  }, [])

  const changeAction = (value) => {
    setActions((oldState) => {
      const newState = [...oldState]
      newState[actionIndex] = {
        'type': value,
        'config': {}
      }
      if (value === 'ADDTAG') {
        newState[actionIndex]['config'] = {
          'labels': []
        }
      } else if (value === 'MOVE') {
        newState[actionIndex]['config'] = {
          'next_stage': stages[0].id
        }
      } else if (value === 'MSG') {
        newState[actionIndex]['config'] = {
          'subject': '',
          'content': '',
          'schedule_type': 'now',
          'days_offset': 4
        }
      }
      return newState;
    })
  }

  const handleMcEditor = (name) => (e) => {
    setActions((oldState) => {
      const newState = [...oldState]
      newState[actionIndex]['config'] = {
        ...newState[actionIndex]['config'],
        'content': e.target.value
      }
      return newState;
    })
  };
  
  const handleSubjectChange = (e) => {
    e.persist();
    setActions(
      (oldState) => {
        const newState = [...oldState];
        newState[actionIndex]['config'] = {
          ...newState[actionIndex]['config'],
          'subject': e.target.value,
          'from_email': businessUserEmail
        }
        return newState;
      })
  }

  const handleScheduleMailChange = (value) => {
    setActions(
      (oldState) => {
        const newState = [...oldState];
        newState[actionIndex]['config'] = {
          ...newState[actionIndex]['config'],
          'schedule_type': value
        }
        return newState;
      })
  }

  const handleDaysOffsetChange = (value) => {
    setActions(
      (oldState) => {
        const newState = [...oldState];
        newState[actionIndex]['config'] = {
          ...newState[actionIndex]['config'],
          'days_offset': value
        }
        return newState;
      })
  }

  const handleAddTag = (newTagValue) => {
    if (newTagValue && !tags.includes(newTagValue)) {
      setActions(
        (oldState) => {
          const newState = [...oldState];
          newState[actionIndex]['config'] = {
            ...newState[actionIndex]['config'],
            'labels': [...newState[actionIndex]['config']['labels'], newTagValue]
          }
          return newState;
        }
      );
    }
  };

  const handleRemoveTag = (tag) => {
    setActions(
      (oldState) => {
        const newState = [...oldState];
        newState[actionIndex]['config'] = {
          ...newState[actionIndex]['config'],
          'labels': newState[actionIndex]['config']['labels'].filter((t) => t !== tag)
        }
        return newState;
      }
    );
  };

  const actionBody = useMemo(() => {
    return ({
    'MOVE': (
      <Row type='flex' justify='center' align='middle' style={{ marginTop: '1em'}}>
        <Col span={20}>
          <Typography.Text>¿A dónde quieres mover a los evaluados?</Typography.Text>
        </Col>
        <Col span={20}>
          <Select style={{ width: '40%' }} defaultValue={actions[actionIndex].config?.next_stage} placeholder='Selecciona etapa' onChange={(value) => setActions(
            (oldState) => {
              const newState = [...oldState]
                newState[actionIndex] = {
                'type': 'MOVE',
                'config': {
                  'next_stage': value
                }
              }
              return newState
              }
          )}>
            {stages.filter((stage)=> stage.id !== selectedStage.id ).map((stage) => (
              <Option key={stage.id} value={stage.id}>{stage.name}</Option>
            ))}
          </Select>
        </Col>
      </Row>
    ),
    'MSG': (
      <Row type='flex' justify='center' align='middle' style={{ marginTop: '1em'}}>
        <Col span={20}>
          <Form.Item>
            {
              getFieldDecorator('subject', {
                rules: [
                  {
                    required: true,
                    message: 'Por favor ingresa un asunto'
                  }
                ],
                initialValue: actions[actionIndex].config?.subject
              })
              
            (<Input
              name='subject'
              placeholder='Escribir el asunto que enviarás a los evaluados'
              onChange={handleSubjectChange}
          />)}
          </Form.Item>
          <Typography.Text>¿Qué mensaje le quieres enviar a los evaluados?</Typography.Text>
          <Form.Item hasFeedback>
            {getFieldDecorator('mailText', {
              rules: [
                {
                  required: true,
                  message: 'Por favor ingresa un mensaje'
                }
              ],
              validateTrigger: 'onBlur' // add this line
            })(<MceEditor
              name='message'
              body={actions[actionIndex].config?.content}
              placeholder='Escribir el mensaje que enviarás a los evaluados'
              height={200}
              handleChange={handleMcEditor}
            />)}
          </Form.Item>
        </Col>
        <Col span={20}>
          <Row type='flex' justify='start' align='middle'>
            <Select defaultValue={actions[actionIndex].config?.schedule_type || 'now'} onChange={handleScheduleMailChange} style={{width: '200px'}}>
              <Select.Option value={'now'}>Inmediatamente</Select.Option>
              <Select.Option value={'custom'}>Personalizado</Select.Option>
            </Select>
            {action.config?.schedule_type === 'custom' &&
              <>
                <InputNumber min={1} max={3} onChange={handleDaysOffsetChange} defaultValue={1} style={{ marginLeft: '1em', width: '50px'}}/>
                <Select defaultValue={'days'} style={{ marginLeft: '1em' }} disabled>
                  <Select.Option value={'days'}>Días después</Select.Option>
                </Select>
              </>
            }
          </Row>
        </Col>
        <Col span={20} style={{ marginTop: '1em' }}>
          <Typography.Text style={{ color: '#A4A4A4' }}>Si la programación ocurre un fin de semana, el correo se enviará el siguiente lunes solamente a los candidatos añadidos.</Typography.Text>
        </Col>
      </Row>
    ),
    'ADDTAG': (
      <Row type='flex' justify='center' align='middle' style={{ marginTop: '1em'}}>
        <Col span={20}>
          <Typography.Text>¿Qué etiqueta quieres agregar en los perfiles de los evaluados?</Typography.Text>
        </Col>
        <Col span={20} style={{ marginTop: '1em' }}>
          <TagInput
            tags={actions[actionIndex].config?.labels || []}
            addTag={tag => handleAddTag(tag)}
            removeTag={tag => handleRemoveTag(tag)}
          />
        </Col>
      </Row>
    )
  })}, [actions, actionIndex, selectedStage, stages, tags]);

  return (
    <Card
    className='trigger-card'
    >
      <Row type='flex' justify='center' align='middle'>
        <Col span={4}>
          { action.type ? <Icon type={actionsOptions[action.type].icon} style={{ fontSize: 60, color: '#A4A4A4' }} /> : null }
        </Col>
        <Col span={16}>
          <Row type='flex' justify='space-between' align='middle'>
            <Typography.Text>Acciones</Typography.Text>
            <Icon type='delete' onClick={removeAction} style={{ fontSize: '16px', color: 'red', padding: 10 }}/>
          </Row>
          <Select style={{ width: '100%' }} onChange={(value) => changeAction(value)} value={actionsOptions[action.type].label}>
          {
            Object.keys(actionsOptions)
              .filter(option => availableActions.includes(option))
              .map((AvailableOption) => (
                <Option
                  key={`action__${AvailableOption}`}
                  value={AvailableOption}
                  style={AvailableOption === 'DISCARD' ? { color: 'red' } : {}}
                >
                  {actionsOptions[AvailableOption].label}
                </Option>
              ))
          }
          </Select>
        </Col>
      </Row>
      {actionBody[action?.type]}
    </Card>
  )
};

const mapStateToProps = (state) => {
  return {
    businessUserEmail: state.auth.userName
  };
};

const ActionForm = Form.create({ name: 'action_form'})(Action)

export default connect(
  mapStateToProps,
)(ActionForm)