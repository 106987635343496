import React, { useEffect, useState, useRef, useCallback } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../../actions/data';

import {
  Button,
  Dropdown,
  Modal,
  Typography,
  Popover,
  Form,
  Menu,
  Input,
  Select,
  Icon,
  message,
  Alert,
} from 'antd';
import PaperIMG from '../assets/paper.png';

import MceEditor from '../../NewProcessFormV2/components/mceeditor/MceEditor';

const { Title, Text } = Typography;
const { Option } = Select;

function MailModal(props) {
  const [state, setState] = useState({
    openConfirmationDialog: false,
    name: '',
    subject: '',
    body: '',
    anchorEl: null,
    templateToDelete: null,
    replacedBody: '',
    from: 'no-reply@genoma.work',
    cc: '',
    bcc: [],
    preview: false,
    index: 0,
    delay: 0,
    single: true,
    candidates: [],
    myTemplates: [],
    templateCategories: [],
    subjecEmpty: false,
    showConfidentialAlert: false,
    modalText: '',
    modalTitle: '',
    visible: false,
    readOnlyBody: '',
    editorEnabled: true,
    confirmSaveTemplateModal: false,
    fileList: [],
  });
  const [batches, setBatches] = useState(null);
  const captchaRef = useRef(null);

  useEffect(() => {
    setState(oldState => ({ ...oldState, from: 'no-reply@genoma.work' }));
    getTemplates();
  }, []);

  const handleChange = (name) => (event) => {
    const pokeProps = ['from'];
    let value = null;

    if (pokeProps.includes(name)) {
      value = event;
    } else {
      value = event.target.value;
    }

    if (name === 'bcc') {
      value = [value];
    }

    setState(oldState =>
    ({
      ...oldState,
      [name]: value,
    })
    );
  }

  const goToPreview = (candidates) => {
    let differentToPreview = !state.preview;
    setState((oldState) => {
      return { ...oldState, preview: !oldState.preview };
    });

    candidates = getCandidates(candidates);
    let firstName = candidates[state.index].name.split(' ');
    if (firstName[0].includes('...')) {
      firstName[0] = candidates[state.index].email;
    }

    if (differentToPreview) {
      setState(oldState => ({ ...oldState, editorEnabled: false }));
      const content = state.body
        .replace(/--name--/g, firstName[0])
        .replace(/--processName--/g, props.jobAppName ?? props.jobApplicationName);
      setState(oldState => ({ ...oldState, readOnlyBody: content }));
    } else {
      setState(oldState => ({ ...oldState, editorEnabled: true }));
      const content = state.body;
    }
  };

  const handleChip = (value, candidates) => {
    candidates = getCandidates(candidates);
    let firstName = candidates[state.index + value].name.split(' ');
    if (firstName[0].includes('...')) {
      firstName[0] = candidates[state.index + value].email;
    }
    const content = state.body
      .replace(/--name--/g, firstName[0])
      .replace(/--processName--/g, props.jobAppName ?? props.jobApplicationName);
    setState(oldState => ({ ...oldState, readOnlyBody: content }));
    setState((oldState) => {
      return ({ ...oldState, index: oldState.index + value });
    });
  };

  const getInfo = (user) => {
    return `${user.name} <${user.email}>`;
  };

  const getCandidates = (candidates) => {
    let c = {};
    for (let lane in candidates) {
      c = { ...c, ...candidates[lane] };
    }
    return Object.values(c);
  };

  const getIds = (candidates) => {
    let c = {};
    for (let lane in candidates) {
      c = { ...c, ...candidates[lane] };
    }
    return Object.keys(c);
  };

  const insertNameKey = () => {
    window.tinymce.activeEditor.execCommand(
      'mceInsertContent',
      false,
      ' --name-- '
    );
  };

  const insertKey = (type) => {
    switch (type) {
      case 'name':
        window.tinymce.activeEditor.execCommand(
          'mceInsertContent',
          false,
          ' --name-- '
        );
        return;
      case 'processName':
        window.tinymce.activeEditor.execCommand(
          'mceInsertContent',
          false,
          ' --processName-- '
        );
        return;
      case 'link':
        window.tinymce.activeEditor.execCommand(
          'mceInsertContent',
          false,
          `${process.env.REACT_APP_SHARE_URL}/jobapplication/${props.jobappid}/`
        );
        return;
    }
  };

  const sendMail = async (data, selectedCandidates) => {

    const ids = getIds(selectedCandidates);
    const users = getCandidates(selectedCandidates);
    for (const x in users) {
      users[x].id = ids[x];
    }
    // const mailPromises = [];
    const floorDivision = (number, divisor) =>
      (number - (number % divisor)) / divisor;
    // Send mail in batches of 50
    const userBatches = [
      ...Array(
        floorDivision(users.length, 50) + (users.length % 50 > 0)
      ).keys(),
    ].map(() => ({ ...data, users: [] }));
    // Add users to each corresponding userBatch
    users.forEach((user, index) => {
      userBatches[floorDivision(index, 50)].users.push(user);
    });
    // Send one request for each userBatch and add to mailPromises
    userBatches.forEach((userBatch) => {
      // Filter empty strings from bcc array
      userBatch.bcc = userBatch.bcc.filter((item) => item);
    });
    setBatches(userBatches);
    // Wait for all request promises to end before telling the user
    /*
    Promise.all(mailPromises).then(() => {
      handleClose();
      setState({ editorEnabled: true }, () =>
        message.success('Los correos han sido enviados')
      );
    });
    */
  };

  const sendBatch = useCallback((newValue) => {
    console.log("waaaaa")
    // Handle newToken creation
    if (!batches) return;
    const url = '/api/v1/gamesandtests/sendmail/';
    const token = props.token;
    
    const currentBatches = structuredClone(batches);

    const userBatch = currentBatches.shift();

    props.actions.fetchAndReturn(
      token,
      url,
      'POST',
      userBatch,
      { Captcha: newValue }
    ).then((resp) => {
      if (resp.status < 400){
        setBatches(currentBatches);
        message.success('Los correos han sido enviados');
      }
      else{
        message.success('Error al enviar correos');
      }
    });
  }, [batches]);

  useEffect(() => {
    // Only start sending batches if there are any
    if (batches && batches.length > 0) {
      captchaRef.current.execute();
    } else if (batches) {
      handleClose();
      setState(oldState => ({ ...oldState, editorEnabled: true }))
      setBatches(null)
    }
  }, [batches]);

  const handleClose = () => {
    setState(oldState => ({
      ...oldState,
      subject: '',
      body: '',
      from: 'no-reply@genoma.work',
      cc: '',
      preview: false,
      index: 0,
      delay: 0,
      single: true,
    }));
    props.handleClose('visibleMailModal');
  };

  const useTemplate = (template) => {
    setState(oldState => ({
      ...oldState,
      subjecEmpty: false,
      anchorEl: null,
      subject: template.subject,
      body: template.template,
      showConfidentialAlert: true,
    }));
  };

  const deleteTemplate = (template) => {
    const url = `accounts/business/${props.business.id}/mailtemplates/${template.id}/`;
    const token = props.token;

    const data = { id: template.id };
    props.actions
      .sendData(token, url, JSON.stringify(data), 'delete', '')
      .then(() => {
        setState(oldState => ({
          ...oldState,
          openConfirmationDialog: false,
          subject: '',
          subjecEmpty: false,
        }));
        setState(oldState => ({ ...oldState, body: '' }));
      });
  };

  const openModalDeleteConfirmation = (template) => {
    Modal.confirm({
      title: 'Borrar plantilla',
      content: `¿Confirmas que deseas eliminar la plantilla ${template.name}?`,
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => deleteTemplate(template),
      onCancel: () => { },
    });
    setState(oldState => ({
      ...oldState,
      templateToDelete: { ...template },
    }));
  };

  const closeModalDeleteConfirmation = () => {
    setState(oldState => ({ oldState, openConfirmationDialog: false }));
  };

  const saveTemplate = (openSnack) => {
    if (!state.subject) {
      setState(oldState => ({ ...oldState, subjecEmpty: true }));
      return;
    }
    setState(oldState => ({ ...oldState, subjecEmpty: false }));
    const url = `accounts/business/${props.business.id}/mailtemplates/`;
    const token = props.token;

    const data = {
      template: state.body,
      name: state.name,
      subject: state.subject,
    };
    props.actions
      .sendData(token, url, JSON.stringify(data), 'post', '')
      .then(() => {
        openSnack('Se guardó la plantilla exitosamente');
      });
    getTemplates();
  };

  const getTemplates = () => {
    const token = props.token;
    const categoriesUrl = `/api/v1/accounts/mailtemplatecategories/`;
    props.actions.fetchAndReturn(token, categoriesUrl, 'get').then((response) => {
      setState(oldState => ({...oldState, templateCategories: response.body.results }));
      const url = `/api/v1/accounts/business/${props.business.id}/mailtemplates/`;
      props.actions.fetchAndReturn(token, url, 'get').then((response) => {
        setState(oldState => ({...oldState, myTemplates: response.body.results }));
      });
    });

  };

  const handleClick = async (event) => {
    setState(oldState => ({ ...oldState, anchorEl: event.currentTarget }));
    getTemplates();
  };

  const handleCloseMenu = () => {
    setState(oldState => ({ ...oldState, anchorEl: null }));
  };

  const isValidEmail = () => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(state.cc.toLowerCase());
  };

  const handleVisibleChange = (visible) => {
    setState(oldState => ({ ...oldState, visible }));
  };

  const handleAttachFile = (info) => {
    const { file } = info;
    let fileList;
    if (info.file.status === 'removed') {
      const uid = file.uid;
      fileList = state.fileList.filter((x) => x.uid !== uid);
    } else {
      const fileData = {
        uid: file.uid,
        name: file.name,
        size: file.size,
        type: file.type,
        ...file.response,
      };
      fileList = [...state.fileList, fileData];
    }
    setState(oldState => ({ ...oldState, fileList }));
  };

  


  const { isFetching, selectedCandidates } = props;
  const {
    subject,
    body,
    from,
    cc,
    bcc,
    preview,
    index,
    single,
    myTemplates,
    fileList,
  } = state;
  const completed = Boolean(subject && body && from);
  const { getFieldDecorator } = props.form;
  const emailOptions = [
    {
      label: 'no-reply@genoma.work',
      value: 'no-reply@genoma.work',
    },
  ];


  return (
    <Modal
      visible={props.open}
      className="modal-email-container"
      width={850}
      onCancel={() => handleClose('visibleModal')}
      footer={[
        <div key="9">
          {preview ? (
            <div>
              <Button
                onClick={() => goToPreview(selectedCandidates)}
                type="secondary"
              >
                Atras
              </Button>

              <Button
                type="primary"
                disabled={!completed || isFetching}
                onClick={() =>
                  sendMail(
                    {
                      subject: subject,
                      body: body,
                      from: from,
                      cc: cc,
                      bcc: bcc,
                      single: single,
                      job_application_id: props.jobappid,
                      attachments: state.fileList,
                    },
                    selectedCandidates
                  )
                }
              >
                {isFetching ? 'Enviando...' : 'Enviar'}
              </Button>
              <ReCAPTCHA
                ref={captchaRef}
                size="invisible"
                sitekey={process.env.REACT_APP_CAPTCHA_API_KEY}
                onChange={sendBatch}
              />
            </div>
          ) : (
            <div>
              <Button
                key="1"
                type="secondary"
                onClick={() =>
                  setState(oldState=> ({...oldState, confirmSaveTemplateModal: true }))
                }
              >
                Guardar plantilla
              </Button>

              <Popover
                width={350}
                content={
                  <Menu
                    style={{ width: 256, maxHeight: '40vh', overflowY: 'auto' }}
                  >
                    {state.templateCategories.map((category) => (
                      <Menu.SubMenu
                        key={category.id}
                        title={category.name}
                        popupClassName='mail-template-submenu'
                      >
                        {state.myTemplates
                          .filter(
                            (template) =>
                              template.category === category.id
                          )
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((template, i) => (
                            <Menu.Item
                              key={i}
                              onClick={() => useTemplate(template)}
                              style={{ cursor: 'pointer' }}
                            >
                              {template.name}
                            </Menu.Item>
                          ))}
                      </Menu.SubMenu>
                    ))}
                    <Menu.SubMenu
                      key={'nocat'}
                      title={'Sin categoría'}
                      popupClassName='mail-template-submenu'
                    >
                      {state.myTemplates
                        .filter((template) => !template.category)
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((template, i) => (
                          <Menu.Item
                            key={i}
                            onClick={() => useTemplate(template)}
                            style={{ cursor: 'pointer' }}
                          >
                            {template.name}
                          </Menu.Item>
                        ))}
                    </Menu.SubMenu>
                    <Menu.SubMenu
                      key={'other'}
                      title={'Otras plantillas'}
                      popupClassName='mail-template-submenu'
                    >
                      {state.myTemplates.filter(
                        (template) => template.category !== null &&
                          !state.templateCategories
                            .map((category) => category.id)
                            .includes(template.category)
                      ).map((template, i) => (
                        <Menu.Item
                          key={i}
                          onClick={() => useTemplate(template)}
                          style={{ cursor: 'pointer' }}
                        >
                          {template.name}
                        </Menu.Item>
                      ))}
                    </Menu.SubMenu>
                  </Menu>
                }
                title={<Text strong>Mis plantillas</Text>}
                trigger="hover"
                onCancel={handleVisibleChange}
              >
                <Button
                  key="2"
                  type="secondary"
                  onClick={handleClick}
                >
                  Mis plantillas
                </Button>
              </Popover>
              <Dropdown
                overlay={
                  <div style={{ display: 'grid' }}>
                    <Button
                      key="3"
                      type="secondary"
                      onClick={() => insertKey('name')}
                    >
                      Insertar Nombre
                    </Button>

                    <Button
                      key="4"
                      type="secondary"
                      onClick={() => insertKey('processName')}
                    >
                      Insertar Nombre del Proceso
                    </Button>
                    <Button
                      key="5"
                      type="secondary"
                      onClick={() => insertKey('link')}
                    >
                      Link del proceso
                    </Button>
                  </div>
                }
              >
                <Button icon="down">Insertar</Button>
              </Dropdown>

              <Button
                key="5"
                type="primary"
                onClick={() => goToPreview(selectedCandidates)}
              >
                Vista Previa
              </Button>
            </div>
          )}
        </div>,
      ]}
    >
      <Title level={4} style={{ display: 'flex' }}>
        <img
          width="25"
          src={PaperIMG}
          style={{
            marginRight: '7px',
          }}
        />
        Enviar email ({getCandidates(selectedCandidates).length})
      </Title>

      <Form>
        <Form.Item label="De" className="email-from">
          {getFieldDecorator('from', {
            onChange: handleChange('from'),
            initialValue: 'no-reply@genoma.work',
          })(
            <Select disabled={preview}>
              {emailOptions.map(({ label, value }) => (
                <Option key={value} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          )}
          <span className="email-from__helper-text">
            El email se enviará desde esta dirección
          </span>
        </Form.Item>

        {preview ? (
          <div className="preview-to">
            <Button
              type="secondary"
              onClick={
                index == 0
                  ? null
                  : () => handleChip(-1, selectedCandidates)
              }
            >
              <Icon type="left" />
              Anterior
            </Button>

            <Input
              label="Para"
              disabled={preview}
              value={getInfo(
                getCandidates(selectedCandidates)[index]
              )}
            />

            <Button
              type="secondary"
              onClick={
                index == getCandidates(selectedCandidates).length - 1
                  ? null
                  : () => handleChip(1, selectedCandidates)
              }
            >
              Siguiente
              <Icon type="right" />
            </Button>
          </div>
        ) : (
          <div>
            <Form.Item label="CC">
              {getFieldDecorator('cc', {
                onChange: handleChange('cc'),
                rules: [
                  {
                    type: 'email',
                    message: 'El email ingresado no es válido',
                  },
                ],
              })(<Input placeholder="Agregar destinatarios CC " />)}
            </Form.Item>
            <Form.Item label="CCO">
              {getFieldDecorator('bcc', {
                onChange: handleChange('bcc'),
                rules: [
                  {
                    type: 'email',
                    message: 'El email ingresado no es válido',
                  },
                ],
              })(<Input placeholder="Agregar destinatarios CCO " />)}
            </Form.Item>
          </div>
        )}

        <Form.Item label="Asunto">
          <Input
            placeholder="Ingresa aquí el asunto"
            value={state.subject}
            onChange={handleChange('subject')}
          />
        </Form.Item>
        {state.subjecEmpty && (
          <p style={{ color: 'red', fontSize: '0.8rem' }}>
            Debes ingresar el asunto para identificar el template
          </p>
        )}

        {props.isConfidential && state.showConfidentialAlert ? (
          <Alert
            message={
              <span>
                Revisa el contenido del mensaje. Recuerda que es un{' '}
                <strong>proceso confidencial.</strong>
              </span>
            }
            type="info"
            showIcon
          />
        ) : null}

        <div style={{ marginTop: '1em' }}>
          <MceEditor
            name="body"
            handleChange={handleChange}
            body={state.body}
            disabled={!state.editorEnabled}
            readOnlyBody={state.readOnlyBody}
            handleAttachFile={
              getCandidates(selectedCandidates).length <= 20
                ? handleAttachFile
                : null
            }
          />
        </div>
      </Form>
      <Modal
        title="Ingrese el nombre de la plantilla para continuar "
        visible={state.confirmSaveTemplateModal}
        onOk={() => {
          saveTemplate(props.openSnackHandle);
          setState(oldState => ({...oldState, confirmSaveTemplateModal: false }));
        }}
        onCancel={() =>
          setState(oldState => ({...oldState, confirmSaveTemplateModal: false }))
        }
      >
        <Form>
          <Form.Item>
            Nombre de plantilla:
            <Input
              type="text"
              label="Nombre de plantilla"
              value={state.name}
              onChange={(e) => {
                const value = e.target?.value
                setState(oldState => ({...oldState, name: value}));
              }}
            ></Input>
          </Form.Item>
        </Form>
      </Modal>
    </Modal>
  )

};

const mapStateToProps = (state) => {
  return {
    userName: state.auth.userName,
    data: state.data.data,
    jobAppName: state.jobapp.jobApplication.job_application,
    token: state.auth.token,
    isFetching: state.data.isFetching,
    business: state.auth.currentBusiness,
  };
};

const mapDispathToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

const MailModalForm = Form.create()(MailModal);
export default connect(mapStateToProps, mapDispathToProps)(MailModalForm);
