import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import resources from './locales/i18nFiles'
// the translations

if (i18n.isInitialized){
  Object.keys(resources).map((language, idx) => {
    Object.keys(resources[language]).map((file, idx) => {
      i18n.addResourceBundle(language, file, resource[language])
    })
  })  }
 
else {
  i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      load: 'languageOnly',
      resources,
      debug: false,
      fallbackLng: 'es',
      ns: Object.keys(Object.keys(resources['es'])),
      defaultNS: 'translation',
      locales: ['es', 'en', 'pt'],
      nsSeparator: ':',
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      }
    });
}
  
export default i18n;