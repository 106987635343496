import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';

import {
  Row, 
  Col,
  Dropdown,
  Menu,
  Button,
  Typography,
  Modal,
  Input,
  List,
} from 'antd';
import ProcessStatusBadge from '../../../../components/ProcessStatusBadge';

const { Text, Title } = Typography;
const { TextArea } = Input;

const stateToText = {
  ACTIV: 'Abierto',
  INACT: 'Cerrado',
  OTHER: 'Borrador'
}
/**
 * 
 * @param {Boolean} visible Defines if Modal is visible
 * @param {Function} setVisible sets Modal visibility
 * @param {Function} handleBulkAction function for handling action over selected jobapps.
 * @param {Array} jobApplications List of jobapplications.
 * @returns {React.Node}
 */
const TagsModal =  ({ visible, setVisible, handleBulkAction, jobApplications}) => {
  const [tag, setTag] = useState(undefined);
  return (
    <Modal 
      title='Agregar etiquetas a los procesos'
      width={'50vw'}
      visible={visible} 
      onOk={() => {
        tag && handleBulkAction('tags')({ tag });
        setTag(undefined);
        setVisible(false);
      }}
      okText="Agregar"
      cancelText="Cancelar"
      onCancel={() => setVisible(false)}
    >
      <Row type='flex' gutter={[12, 12]}> 
        <Col sm={12}>
          <div style={{ maxHeight: '350px', overflowY: 'auto' }}>
            <List
              header={<Text strong level={4}>{jobApplications.length} {jobApplications.length === 1 ? "proceso seleccionado" : "procesos seleccionados"}.</Text>}
              itemLayout="horizontal"
              dataSource={jobApplications}

              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    title={item.job_application}
                  />
                </List.Item>
              )}
            />
          </div>
        </Col>
        <Col sm={12}>
          <TextArea
            maxLength={20}
            rows={2} 
            value={tag} 
            placeholder="etiqueta" 
            onChange={({ target: { value } }) => setTag(value)} 
          />
        </Col>
      </Row>
    </Modal>
  )
}

/**
 * 
 * @param {String} currentStatus Status of the table column to define what to show 
 * @param {Function} handleBulkAction Status of the table column to define what to show 
 * @param {Array} jobApplications List of jobApplication objects
 * @returns {React.Node}
 */
function FooterActions({ currentStatus, handleBulkAction, jobApplications, ...props  }) {
  const [showTagsModal, setShowTagsModal] = useState(false)
  const canDelete = useMemo(() => (
    jobApplications.every(jobapp => jobapp.count_total === 0)
    && (props.businessPermissions.business_admin || props.businessPermissions.role?.can_create_jobapplications)
  ), [jobApplications]);

  return (
    <>
      <Row>
        <Text style={{ color: '#f175a5' }}>
          {jobApplications.length} procesos seleccionados
        </Text>
      </Row>
      <Row className="actions-footer__buttons" type="flex" justify="center">
        <Dropdown overlay={<>
          <Menu>
            <Menu.Item onClick={() => handleBulkAction('status')({ status: 'ACTIV' })}>
              <ProcessStatusBadge status={'ACTIV'} text={'Abrir'} />
            </Menu.Item>
            <Menu.Item onClick={() => handleBulkAction('status')({ status: 'INACT' })}>
              <ProcessStatusBadge status={'INACT'} text={'Cerrar'} />
            </Menu.Item>
          </Menu>
        </>} trigger={['click']} placement="topLeft">
          <Button><ProcessStatusBadge status={currentStatus} text={stateToText[currentStatus]} /></Button>
        </Dropdown>
        <Button icon="pushpin" onClick={handleBulkAction('favorite')}>Fijar como {jobApplications.length === 1 ? "favorito" : "favoritos"}</Button>
        <Button icon="tag" onClick={() => setShowTagsModal(true)}>Agregar etiqueta</Button>
        {currentStatus === 'OTHER' && canDelete ? 
          <Button 
            type="danger" 
            icon="delete" 
            onClick={handleBulkAction('delete')}
          ><Text>Eliminar</Text>
          </Button> : null}
      </Row>
      <TagsModal 
        visible={showTagsModal} 
        setVisible={() => setShowTagsModal(false)}
        handleBulkAction={handleBulkAction}
        jobApplications={jobApplications}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    businessPermissions: state.auth.businessPermissions,
  };
};


export default connect(mapStateToProps, null)(FooterActions);


