import React, { useContext } from 'react';
import { Row, Col, Typography, Button, Progress } from 'antd';

import { CandidateContext } from '../config/CandidateContext';

const { Text } = Typography;

const colors = ['#FF7066', '#FFC34D', '#92DF53', '#66C7FF'];

const CuTe = ({ cute, changeKey }) => {
  // just the existence of the error property is enough to know if answers exist
  const hasError = cute?.answer == null ? true : !!cute?.answer?.error;

  const getSegment = (scoresConfig, scores) => {
    try {
      return scoresConfig.segments.find((seg) => seg.max >= scores.result && seg.min <= scores.result);
    }
    catch {
      return null
    }
  }

  const createCuTeImage = () => {
    const segment = getSegment(cute.answer?.scores_config, cute.answer?.scores);

    switch (cute.answer.scores.type) {
      case 'RAVEN':
        return (
          <Row type='flex' justify='center' style={{ minHeight: 100 }}>
            {
              cute?.answer?.scores?.custom_scores?.level &&
              <Col span={24} style={{ display: 'grid', marginTop: 10 }}>
                <Text
                  strong
                  style={{
                    fontSize: 22,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {"NIVEL"}
                </Text>
              </Col>
            }
            <Col span={24} style={{ display: 'grid', marginTop: 10 }}>
              <Text
                strong
                style={{
                  fontSize: 22,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {cute?.answer?.scores?.custom_scores?.level ?? 'Resultado no válido'}
              </Text>
            </Col>

          </Row>
        )
      case 'ALTERNATIVES':
        return (
          <Row type='flex' justify='center'>
            <Col style={{ display: 'grid', marginTop: 10 }}>
              <Progress
                type='dashboard'
                percent={Math.round(cute.answer.scores.result / Object.keys(cute.answer.scores.raw_result).length * 100)}
                strokeWidth={20}
                strokeLinecap="square"
                gapDegree={126}
                style={{ height: 90 }}
              />
              <Text
                strong
                style={{
                  fontSize: 22,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {segment.label}
              </Text>
            </Col>
          </Row>
        );
      case 'SJT':
        return (
          <Row type='flex'>
            <Col span={24}>
              <Row type='flex' align='middle' justify='center'>
                <Text strong style={{ fontSize: 32 }}>
                  {cute.answer.scores.result} puntos.
                </Text>
              </Row>
            </Col>
          </Row>
        );
      case 'DISC':
        return cute.answer.scores.result.map((item, i) => (
          <Text
            style={{
              color: colors[i],
              border: `1px solid ${colors[i]}`,
              padding: 6,
              background: `${colors[i]}30`,
              fontSize: 16,
            }}
            strong
          >
            {item}
          </Text>
        ));
      case 'DAT':
        const [color, text] =
          cute.answer.scores.score > 83
            ? ['rgb(82, 196, 26)', 'Alto']
            : cute.answer.scores.score > 72
              ? ['rgb(250, 173, 20)', 'Promedio']
              : ['rgb(255, 79, 79)', 'Bajo'];

        return (
          <div className="new-modal-summary-cutes__dat">
            <Text
              style={{
                background: color,
                color: '#FFFFFF',
                flexGrow: 1,
                display: 'grid',
                placeContent: 'center',
                fontSize: 24,
              }}
              strong
            >
              {Math.round(cute.answer.scores.score)} puntos
            </Text>
            <Text style={{ color: color, textAlign: 'center' }}>{text}</Text>
          </div>
        );
      case 'LIKERT':
        return (
          <Text strong style={{ fontSize: 32 }}>
            {cute.answer.scores.result}
          </Text>
        );
      case 'SJT_GW':
        const colorForSegment = {
          'MUY BAJO': 'rgb(255, 77, 79)',
          'BAJO': 'rgb(255, 122, 69)',
          'MEDIO': 'rgb(250, 173, 20)',
          'ALTO': 'rgb(186, 230, 55)',
          'MUY ALTO': 'rgb(82, 196, 26)'
        };

        return (
          <Row type='flex' justify='center'>
            <Col style={{ display: 'grid', marginTop: 10 }}>
              <Progress
                type='dashboard'
                percent={cute.answer.scores.result}
                strokeWidth={20}
                strokeLinecap="square"
                gapDegree={126}
                strokeColor={colorForSegment[segment.label]}
                style={{ height: 90 }}
              />
              <Text
                strong
                style={{
                  fontSize: 22,
                  display: 'flex',
                  justifyContent: 'center',
                  color: colorForSegment[segment.label]
                }}
              >
                {segment.label}
              </Text>
            </Col>
          </Row>
        );
    }
  };

  return (
    <div className="new-modal-summary-cutes__item">
      {!hasError ? (
        <Row style={{ height: '100%' }} gutter={[20, 10]}>
          <Col style={{ display: 'flex', flexDirection: 'column', height: '100%' }} span={18}>
            <Text className="text-16px" strong style={{ marginBottom: 10 }}>
              {cute.answer.custom_test.title}
            </Text>
            <Text>
              {cute.answer.scores.type === 'SJT_GW' ?
                `La persona evaluada obtuvo un ${cute.answer.scores.result}% en la prueba. 
                Este resultado corresponde a un nivel ${getSegment(cute.answer.scores_config, cute.answer.scores).label.toLowerCase()} 
                de ${cute.answer.custom_test.title}` :
                'Si la persona evaluada ya respondió la prueba. A continuación, podrás ver sus resultados.'
              }
            </Text>
            <Button
              icon="arrow-right"
              style={{ width: 'fit-content', marginTop: 'auto' }}
              onClick={() => changeKey(2)}
            >
              Ver más información
            </Button>
          </Col>
          <Col span={6} className="new-modal-summary-cutes__item__right">
            {createCuTeImage(cute)}
          </Col>
        </Row>
      ) : (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: 'fit-content'
        }}
        >
          <Text className="text-16px" strong style={{ marginBottom: 10 }}>
            {cute.answer?.custom_test.title}
          </Text>
          <Text style={{ fontSize: 15 }}>
            El candidato aún no responde la prueba
          </Text>
        </div>
      )}
    </div>
  );
};

const SummaryCuTes = () => {
  const { customTests, changeKey } = useContext(CandidateContext);

  return (
    <div className="new-modal-summary-cutes">
      {customTests.map((cute, idx) => (
        <CuTe changeKey={changeKey} key={idx} cute={cute} />
      ))}
    </div>
  );
};

export default SummaryCuTes;
