import React, { Component } from 'react';
import { Steps, Typography, Affix, Tooltip, Badge } from 'antd';
import './Tabs.scss';

const { Step } = Steps;
const { Text } = Typography;

class NavigationTabs extends Component {
  render() {
    const { assignedActivities, totalPoints } = this.props;
    const { reasoningTraits, personalityTraits, cognitiveTraits } = this.props.traits;
    const calculatePercentage = (traits) => totalPoints !== 0 ? Math.round(traits.reduce((init, next) => init + parseInt(next.points), 0)*100/totalPoints) : 0;
    const data = [
      {
        "label": "Razonamiento",
        "value": calculatePercentage(reasoningTraits),
        "color": "#4e4cac"
      },
      {
        "label": "Personalidad",
        "value": calculatePercentage(personalityTraits),
        "color": "#9698d5"
      },
      {
        "label": "Cognitivos y emocionales",
        "value": calculatePercentage(cognitiveTraits),
        "color": "#f07f8b"
      },
    ]

    let acts = [];
    for (const x in assignedActivities) { 
      acts.push(assignedActivities[x]);
    }
    const levelTwo = acts.filter(e => e.icon !== 'FORM' && e.icon !== 'KO');

    return (
      <Affix offsetTop={75}>
        <div className='ig-header'>
          <Steps
            type="navigation"
            size="small"
            current={this.props.current}
            onChange={this.props.onChange}
          >
            <Step title='Recorrido del candidato' />
            <Step title='Configuración genoma ideal' disabled={levelTwo.length === 0} />
          </Steps>

          {data.map(({ value }) => value).reduce((a, b) => a + b, 0) >= 1 && 
            <div className='bar'>
              {data.map((bar, i) => (
                <Tooltip
                  key={i}
                  placement='top' 
                  overlayClassName='category-relevance'
                  title={
                    <Badge
                      color={bar.color}
                      text={
                        <Text type='secondary'>
                          <strong>{bar.label}:</strong> {Number(totalPoints) === 0 ? 0 : bar.value}%
                        </Text>
                      }
                    />
                  }
                >
                <div
                  style={{
                    width: `${bar.value}%`,
                    background: bar.color
                  }}
                />
              </Tooltip>
              ))}
            </div>
          }
        </div>
      </Affix>
    )
  }
}

export default NavigationTabs;