import React, { useState } from 'react';
import {
  Button,
  Divider,
  Col,
  Row,
  Typography,
} from 'antd';

import Results from './components/Results';
import '../styles.scss';
import ParseHtml from '../../../../../components/parseHtml';
import i18n from '../../../../../i18n';
import AlternativeDetail from './components/AlternativeDetail';

const { Text, Title } = Typography;

export const DetailSJT = ({
  answers,
  questions,
  sortedQuestions,
  scores,
  scoresConfig,
  description,
  dateCreated,
}) => {

  const [hideAnswers, setHideAnswers] = useState(true);

  return (
    <>
      {dateCreated && (
        <Row style={{ margin: '15px 0 15px 0' }}>
          <Text>
            Fecha de finalizacion: <strong>{dateCreated}</strong>
          </Text>
        </Row>
      )}

      {description && (
        <>
          <Title level={4}>Descripción</Title>
          <Divider />
          <Row style={{ marginBottom: 24 }}>
            <Col span={24}>{ParseHtml(description)}</Col>
          </Row>
        </>
      )}
			<Results scores={scores} scoresConfig={scoresConfig} pdfReport={false} />
      <Row type="flex" justify="space-between" style={{ marginTop: '15px' }}>
        <Col>
          <Title level={4}>{i18n.t('profile__answers', { ns: 'candidateProfile' })}</Title>
        </Col>
        <Col>
          <Button onClick={() => setHideAnswers((oldState) => !oldState)}>
            {hideAnswers ? 'Mostrar respuestas' : 'Ocultar Respuestas'}
          </Button>
        </Col>
      </Row>

      {!hideAnswers && (
        <AlternativeDetail 
					answers={answers} 
					questions={questions} 
					scores={scores} 
					scoresConfig={scoresConfig}
					sortedQuestions={sortedQuestions}
				/>
      )}
    </>
  );
};

export default DetailSJT;
