import React, { useState } from 'react';
import {
  Layout,
  Row,
  Icon,
  Form,
  Input,
  Button,
  Typography,
  Select,
  Tooltip,
  Checkbox
} from 'antd';

import i18n from '../../../../i18n';
import { useEffect } from 'react';

const { Title, Text } = Typography;

const Registration = (
  {
    props, state, goBack,
    handleChange, submitSignupData, handleConfirmEmailChange,
    validateToNextPassword, handleChangeCountry,
    countriesIdentification, handleDNIChange,
    handleTosChange, handleTosDialog, checkFields, 
  }
) => {
  const { getFieldDecorator } = props.form;
  const [askForEmail, setAskForEmail] = useState(true);

  const checkEmailNotNull = () => !state.email;

  useEffect(() => {
    setAskForEmail(checkEmailNotNull())
  }, [])
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return (
    <Layout className='main-layout-cards__cols__component'>
      <Row style={{ margin: '0 0 10px 0' }}>
        <Icon type='arrow-left' onClick={() => goBack()} style={{ fontSize: '27px' }} />
      </Row>
      <Row style={{ margin: '0 0 10px 0' }}>
        <Text style={{ fontSize: '20px', color: '#8C8C8C' }}>
          {i18n.t('commons__candidate').toLocaleUpperCase()}
        </Text>
      </Row>
      <Row style={{ margin: '0 0 12px 0' }}>
        <Title level={1} style={{ padding: '0px' }}>
          {i18n.t('start_process__register')}
        </Title>

        <Text style={{ fontSize: '18px', color: '786D73' }}>
          {state.email}
        </Text>
      
      </Row>
      <Row style={{ margin: '0 0 20px 0' }}>
        {Object.keys(state.fields).length > 0 && (<Text type='secondary' style={{ fontSize: '18px', color: '786D73' }}>
          {i18n.t('start_process__invitation_message',{ name: state.fields.name, integration: state.utm['source']})}
        </Text>)}
      </Row>
      <Form onSubmit={submitSignupData}>

        <Form.Item label={i18n.t('commons__email')} hasFeedback>
          {getFieldDecorator('email', {
            onChange: handleChange('email'),
            initialValue: state.email,
            rules: [
              {
                required: true,
                message: i18n.t('start__process__please_enter_your_email'),
                pattern: emailRegex,
              },
            ],
          })(
            <Input
              disabled={!askForEmail}
              size="large"
              placeholder={''}
              type="text"
              onPaste={(e) => {
                e.preventDefault()
                return false;
              }}
              onCopy={(e) => {
                e.preventDefault()
                return false;
              }}
              autoComplete="off"
            />
          )}
        </Form.Item>
        <Form.Item label={i18n.t('start_process__confirm__email')} hasFeedback>
          {getFieldDecorator('emailConfirm', {
            onChange: handleChange('emailConfirm'),
            rules: [
              {
                required: true,
                message: i18n.t('start__process__please_enter_your_email')
              },
              { validator: handleConfirmEmailChange }
            ],
          })(
            <Input
              size="large"
              placeholder={i18n.t('start_process__constrain__mail')}
              type="text"
              onPaste={(e) => {
                e.preventDefault()
                return false;
              }}
              onCopy={(e) => {
                e.preventDefault()
                return false;
              }}
              autoComplete="off"
            />
          )}
        </Form.Item>

        <Form.Item label={i18n.t('commons__password')}>
          {getFieldDecorator('password', {
            rules: [
              {
                required: true,
                message: i18n.t('start_process__missed__password'),
              },
              {
                validator: validateToNextPassword,
                message: i18n.t('start_process__password__constrains'),
              },
            ],
            onChange: handleChange('password')
          })(
            <Input.Password style={{ height: '40px' }} placeholder={i18n.t('commons__input__password')} />
          )}
        </Form.Item>

        <Form.Item label={
        <span>{i18n.t('commons__country')}
              <Tooltip title={i18n.t('start_process__DNI')} placement='topRight'>
                <Icon type="question-circle-o" style={{ margin: 5 }} />
              </Tooltip>
            </span>
            
            }>
       
          {getFieldDecorator('country_document', {
            initialValue: state.fields['country'] ? state.fields['country'].toLowerCase() : state.country_document,
            rules: [
              {
                required: true,
                message:
                  i18n.t('start_process__country__empty'),
              },
            ],
          })(
            <Select
              size="large"
              onChange={handleChangeCountry('country_document')}
              
              placeholder={i18n.t('start_process__constrain__country')}
              showSearch
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {state.countries.sort((a, b) =>
                  a.translation[i18n.language.split('-')[0]].localeCompare(b.translation[i18n.language.split('-')[0]])
                ).map((option) => (
                <Select.Option
                  key={option.country}
                  value={option.country.toLowerCase()}
                >
                  {option.translation[i18n.language.split('-')[0]]}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label={
            <span>
              {countriesIdentification[state.country_document] || 'D.N.I'}
              <Tooltip title={i18n.t('start_process__DNI')} placement='topRight'>
                <Icon type="question-circle-o" style={{ margin: 5 }} />
              </Tooltip>
            </span>
          }
          hasFeedback
        >
          {getFieldDecorator('dni', {
            onChange: handleChange('dni'),
            initialValue:state.dni,
            rules: [
              {
                required: true,
                message: `${i18n.t('start_process__dni__empty').split('{')[0]} ${countriesIdentification[state.country_document]}`,
              },
              { validator: handleDNIChange, message: `${i18n.t('start_process__dni__not_valid').split('{')[0]} ${countriesIdentification[state.country_document]} ${i18n.t('start_process__dni__not_valid').split('}')[1]}` }
            ],
          })(
            <Input
              size="large"
            
              placeholder={
                countriesIdentification[state.country_document]
              }
              type="text"
            />
          )}
        </Form.Item>

        <Form.Item>
          {getFieldDecorator('terms', {
            rules: [{ required: true, message: 'Es requerido' }],
          })(
            <Checkbox onChange={handleTosChange('tos')}>
              {i18n.t('start_process__terms_of_use').split('**')[0]}{' '}
              <a
                onClick={handleTosDialog}
                style={
                  ['banco-internacional'].includes(state.business_user_slug) ? (
                    { color: state.color_theme.background, fontSize: '14px' }
                  ) : (
                    { fontSize: '14px' }
                  )
                }
              >
                {i18n.t('start_process__terms_of_use').split('**')[1]}
              </a>{' '}
              {i18n.t('start_process__terms_of_use').split('**')[2]}
            </Checkbox>
          )}
        </Form.Item>

        <Button
          block
          size="large"
          style={checkFields() ? { borderRadius: '4px' } : { borderRadius: '4px', ...state.color_theme }}
          htmlType="submit"
          disabled={checkFields()}
          loading={state.isLoading}
        >
          {i18n.t('start_process__create__account')}
        </Button>
      </Form>
      <Row type='flex' justify='center' align='middle' style={{ }}>
        <Text>{i18n.t('start__process__have_an__account')}</Text>
        <Button type='link'  style={{ fontSize: '16px', color: state.color_theme.background }} href={'/login'}>
          {i18n.t('start__process__log_in')}
        </Button>
      </Row>
    </Layout>
  );
}

export default Registration;