import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { message } from "antd";

import { queryToObject } from '../../utils/url';
import { SERVER_URL } from '../../utils/config';


function HiringRoomIntegration({ location, token }) {
    const [valid, setValid] = useState(true);

    useEffect(() => {
        const qs = queryToObject(location.search);
        fetch(
            `${SERVER_URL}/api/v1/integrations/hiringroom/`,
            {
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify({
                    auth_code: qs.authCode,
                    state: qs.state
                })
            }
        ).then(response => {
            if (response.status_code >= 400) {
                message.error('Hubo un error en la integración. Redirigiendo...')
            } else {
                message.success('Integración realizada satisfactoriamente. Redirigiendo...')
            }
            setTimeout(() => {
                window.location.assign('/integrations')
            }, 2000)
        }).catch(e => {
            message.error('Hubo un error en la integración. Redirigiendo...')
            setTimeout(() => {
                window.location.assign('/integrations')
            }, 2000)
        })
    }, [])

    return (
        <>{valid ? 'Ok' : 'Integración falló'}</>
    )
}

const mapDispatchToProps = dispatch => {
    return {
      dispatch,
    };
  };
  
  const mapStateToProps = (state) => {
    return {
      isAuthenticated: state.auth.isAuthenticated,
      token: state.auth.token,
    };
  }


export default connect(mapStateToProps, mapDispatchToProps)(HiringRoomIntegration);
