import React from 'react';
import { Link } from 'react-router-dom';

import {
  Icon,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import moment from 'moment';

const { Text } = Typography;

function FilesTemplatesTable(props) {
  const columns = () => {
    return [
      {
        title: 'NOMBRE DE LA PLANTILLA',
        dataIndex: 'request',
        key: 'name',
        sorter: (a, b) => {
            if (a[0] < b[0])
              return -1
            else if (a[0] > b[0])
              return 1
            else
              return 0
          },
        render: (request) => (<Text>{request.name}</Text>),
      },
      {
        title: 'DOCUMENTOS ADICIONALES',
        dataIndex: 'request',
        key: 'questions',
        render: (request) => request.questions.map((q) => (<Tag key={q.id}>{q.name}</Tag>)),
      },
      {
        title: 'FECHA DE CREACIÓN',
        dataIndex: 'date_created',
        key: 'date_created',
        sorter: (a, b) => {
          const firstDate = new Date(
            a.date_created.slice(6),
            a.date_created.slice(3,5),
            a.date_created.slice(0,2),
          )
          const secondDate = new Date(
            b.date_created.slice(6),
            b.date_created.slice(3,5),
            b.date_created.slice(0,2),
          );
          if (firstDate < secondDate) return -1;
          else if (firstDate > secondDate) return 1;
          return 0;
        },
        render: (date_created) => (
          <Text>
            {moment(date_created, 'DD-MM-YYYY HH:mm:ss Zz').local().format('DD/MM/YYYY')}
          </Text>
        ),
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        render: (id, templateObj) => (
          <div
            className='reference-templates-actions'
            style={{ display: 'flex' }}
          >
            {!templateObj ? (
              <Link to={`/filestemplates/edit/${id}`}>
                <Tooltip placement='top' title='Editar plantilla'>
                  <Icon
                    type='edit'
                    style={{ fontSize: '19px', color: '#A4A4A4', marginRight: '10px' }}
                  />
                </Tooltip>
              </Link>
            ) : (
              <Link to={`/filestemplates/edit/${id}`}>
                <Tooltip placement='top' title='Editar plantilla'>
                  <Icon
                    type='edit'
                    style={{ fontSize: '19px', color: '#A4A4A4', marginRight: '10px' }}
                  />
                </Tooltip>
              </Link>
            )}
              <Tooltip placement='top' title='Eliminar plantilla'>
                <Icon
                  type='delete'
                  style={{ fontSize: '19px', color: '#A4A4A4', marginRight: '10px' }}
                  onClick={() => props.deleteTemplate(templateObj)}
                />
              </Tooltip>
          </div>
        ),
      },
    ]
  };

  return (
    <>
      <Table
        pagination={{ hideOnSinglePage: true }}
        rowSelection={{
          onSelect: props.handleRowsSelected, 
          onSelectAll: props.handleAllRowsSelected,
          selectedRowKeys: props.selectedRowsData.map((obj) => obj.id),
        }}
        className='references-table'
        dataSource={props.templatesData.filter(
          (rowData) => {
            if (!props.searchInput)
              return true;
            return (
              rowData.request?.name?.toLowerCase().includes(props.searchInput.toLowerCase())
            )
          }
        )}
        columns={columns(props.status)}
        scroll={{ x: 1200 }}
      />
    </>
  )
};

export default FilesTemplatesTable;
