import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../actions/data';

import { ListProvider } from './context/ListContext';

import List from './components/List';

const CandidatePath = props => {
  return (
    <ListProvider {...props}>
      <List />
    </ListProvider>
  );
};

const mapStateToProps = state => {
  return {
    userType: state.auth.userType,
    userName: state.auth.userName,
    token: state.auth.token,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidatePath);
