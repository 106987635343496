import React from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';

import { withStyles } from "@material-ui/core/styles";
import JobStage from "./JobStage/JobStage";
import { Typography, PageHeader } from 'antd';
import i18n from "../../../../../i18n";

const { Title } = Typography

const styles = theme => ({
	card: {
		paddingBottom: 36,
		paddingLeft: 20,
		paddingRight: 20
	},
	jobStageTitle: {
		fontWeight: "300"
	},
	topStagesMinutes: {
		textAlign: "center",
		paddingBottom: 19
	},
	roseNumbers: {
		fontSize: 62,
		fontWeight: "700",
		color: "#ff008a",
		lineHeight: 1.0
	},
	btn: {
		color: "#ffffff",
		fontWeight: "300",
		fontSize: 14
	},
	topBtn: {
		marginTop: 20
	},
	noPadding: {
		padding: 0
	},
	btnBottom: {
		marginTop: 18,
		textAlign: "center"
	},
	sizeLarge: {
		padding: "8px 16px"
	}
});

class CandidateStage extends React.Component {

	groupBy = (xs, key) => {
		return xs.reduce((rv, x) => {
			(rv[x[key]] = rv[x[key]] || []).push(x);
			return rv;
		}, {});
	};

	render() {
		const { businessSlug, classes, colorTheme, items, jobappName, jobappLogo } = this.props;
		// Fix bug introduced with flex, the keys in stages are number from 1 to 2, and represent the stage in the path
		// with flex the key are the stages, so we need to replace the key ACTIV with 1

		const cleanItems = items.filter(obj => obj.code !== 'CUTE').map(obj => { return { ...obj, stage: (obj.stage === 'ACTIV' || obj.stage === 'INITIAL' ? '1' : obj.stage) } })
		const cleanCuTes = items.filter(obj => obj.code === 'CUTE').map(obj => { return { ...obj, stage: (obj.stage === 'ACTIV' ? '1' : obj.stage) } })

		let stages = cleanItems ? this.groupBy([...cleanItems, ...cleanCuTes], 'stage') : {}

		return (
			<div className='candidate-stage'>
				{jobappName &&
					<PageHeader
						title={
							<Title level={3}>
								<strong
									style={['banco-internacional'].includes(businessSlug) ? { color: colorTheme.background } : {}}
								>
									{' '}{jobappName}
								</strong>
							</Title>
						}
						avatar={{ src: jobappLogo, shape: 'square', size: 40 }}
					/>
				}
				{Object.keys(stages).map((stage, i) => {
					return (
						<JobStage
							key={i}
							businessSlug={businessSlug}
							colorTheme={colorTheme}
							stage={stage}
							activities={stages[stage]}
							percentage={0}
						/>
					)
				})}
			</div>
		);
	}
}

CandidateStage.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
	return {
		items: state.data.data.items,
		jobappName: state.data.data.name,
		jobappLogo: state.data.data.logo,
	};
};


export default withStyles(styles)(connect(mapStateToProps)(CandidateStage));
