import React, { useState, useEffect } from "react";
import { Modal, message } from "antd";
import MailWriter, { SendingEmails } from "./MailComponents";
import * as actionsCreators from '../../../../actions/data';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import i18n from "../../../../i18n";

const EmailModal = (props) => {
  const [step, setStep ] = useState(2);
  const [text, setText] = useState('Bienvenido(a),<br/>¡Agradecemos tu interés por postular a nuestro proceso de selección!<br/><br/>Te invitamos a completar juegos y evaluaciones para seguir avanzando con tu postulación. Una vez termines, te entregaremos un reporte que te ayudará a conocerte mejor.<br/><br/>Para ello, deberás completar juegos que duran entre 2 y 4 minutos, y un test de 10 minutos. No es necesario que completes todos los juegos de una sola vez. Puedes completar algunos, y posteriormente aquellos que continúen pendientes.<br/><br/>Te recomendamos jugar en un lugar tranquilo y sin distracciones. Debes realizar los juegos desde un computador o celular, utilizando el navegador Google Chrome y con una buena conexión a Internet.<br/><br/>¡Gracias por tu postulación!<br/>Cualquier duda o problema por favor escribe directamente al chat de soporte en el costado inferior derecho de la pantalla ("icono del sobre").');
  const [mail, setMail] = useState('');
  const [status, setStatus] = useState(null);
  const handleOk = () => {
    setStep((oldStep) => oldStep + 1);
  }

  const getMails = () => {
    return [mail];
  }

  const validate = () => {
    let pattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/
    if (!pattern.test(mail)){
      Modal.error({
        title: 'Error',
        content: 'Debe ingresar un correo válido.',
        onOk: () => setStep(2)
      });
    } else {
      handleOk();
      sendMail();
    }
  }

  useEffect(() => {
    if (step === 5) {
      props.hideModal();
    }
  }, [step]);

  const sendMail = () => {
    const url = '/api/v1/accounts/jobapplicationinvitations/';
    const token = props.token;
    props.actions.fetchAndReturn(token, url, 'POST', {
      subject: 'Invitación a proceso de selección',
      body: text,
      jobapp_id: props.jobAppId,
      utm: { source: 'genoma', medium: 'email', campaign: '' },
      email: mail,
      fields: { name: '' , surname: '' , email: mail }
    }).then((response) => {
      setStatus(response.status);
      if (response.status === 403) {
        message.error(
          i18n.t('commons__permissions_error', translationFile)
        );
      }
      handleOk();
    }).catch((error) => {
      setStatus(500);
      console.log(error);
    })
  }

  return (
    <Modal
      visible
      onCancel={props.hideModal}
      title='Invitar candidatos desde individualmente'
      width='898px'
      onOk={step === 2 ? validate : handleOk}
    >
      {step === 2
        ? (
          <MailWriter
            name='body'
            setMailText={setText}
            mailText={text}
            height={500}
            getMails={getMails}
            setMails={setMail}
            business={props.business}
          />
        )
        : null}
      {step >= 3 ? (<SendingEmails step={step} status={status}/>) : null}
    </Modal>
  )
}


const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    jobAppId: state.jobapp.jobAppId,
    business: state.auth.currentBusiness
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionsCreators , dispatch),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(EmailModal);