import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import './style.scss';

import PropTypes from 'prop-types';

import ParseHtml from '../../../../components/parseHtml';
import {
  Col,
  Row,
  Typography,
  Button,
  Icon,
  Layout,
  Select,
  Alert,
} from 'antd';
import CustomTabs from './CustomTabs/CustomTabs';

import i18n from '../../../../i18n';

import { SERVER_URL } from '../../../../utils/config';
const defaultBackground = 'https://genoma-assets.s3.us-east-2.amazonaws.com/background-genomines.png'

const API_URL = process.env.REACT_APP_SERVER_URL;

const { Title, Text } = Typography;

const WelcomeView = (props) => {
  const { theme, data, start, handleKnowMore, banner, isAuthenticated } = props;

  const [tabs, setTabs] = useState({});
  const [currentTab, setCurrentTab] = useState('job');
  const [headerShrinked, setHeaderShrinked] = useState(false);
  const [hideCompanyDescription, setHideCompanyDescription] = useState(false);
  const [selectLanguage, setSelectLanguage] = useState(
    i18n.language.split('-')[0].includes('es')
      ? 'ES'
      : i18n.language.split('-')[0].includes('en')
        ? 'GB'
        : i18n.language.split('-')[0].includes('pt')
          ? 'BR'
          : 'ES'
  );
  const FLAG_URL = 'https://genoma-assets.s3.us-east-2.amazonaws.com/flags/';
  const languages = {
    ES: {
      flag: 'ES',
      language: i18n.t('commons__ES'),
    },
    EN: {
      flag: 'US',
      language: i18n.t('commons__EN'),
    },
    PT: {
      flag: 'BR',
      language: i18n.t('commons__PT'),
    },
  };

  useEffect(() => {
    const resizeHeaderOnScroll = () => {
      const distanceY =
        window.pageYOffset || document.documentElement.scrollTop;
      const shrinkOn = 55;

      if (distanceY > shrinkOn) {
        setHeaderShrinked(true);
      } else {
        setHeaderShrinked(false);
      }
    };
    if (!i18n.language in ['es', 'en', 'pt']) {
      i18n.changeLanguage('es');
    }

    window.addEventListener('scroll', resizeHeaderOnScroll);

    // Start processs can start in the #info view or the #login view
    if (window.location.hash.includes('#login')) {
      start();
    }

    return () => {
      window.removeEventListener('scroll', resizeHeaderOnScroll);
    };
  }, []);

  useEffect(() => {
    const prepareContent = async () => {
      try {
        const sections = data?.business?.business_info?.sections ? data.business.business_info.sections : data?.business?.business_info ? data.business.business_info : [];

        if (
          sections.length &&
          sections.length === 1 &&
          sections[0].title === '' &&
          sections[0].content === ''
        ) {
          const rrss = [
            'rrss_facebook',
            'rrss_instagram',
            'rrss_linkedin',
            'rrss_twitter',
          ].some(
            (elem) => data.business[elem] !== null && data.business[elem] !== ''
          );
          if (!rrss) {
            setHideCompanyDescription(true);
          }
        }

        const tmp = {
          job: (
            <Row type="flex" style={{ flexDirection: 'column', gap: '2rem' }}>
              {data.is_confidential ? (
                <Alert
                  message={
                    <span>
                      Este es un <strong>proceso confidencial.</strong>
                    </span>
                  }
                  type="info"
                  showIcon
                />
              ) : null}
              {data.description && (
                <div>
                  <Title level={2}>
                    {i18n.t('start__process__about__job')}
                  </Title>
                  <div className={'welcome-text-start-process'}>
                    {ParseHtml(data.description)}
                  </div>
                </div>
              )}
              {data.welcome_text && (
                <div>
                  <Title level={2}>
                    {i18n.t('start__process__about__info')}
                  </Title>
                  <div>{ParseHtml(data.welcome_text)}</div>
                </div>
              )}
            </Row>
          ),
          company: (
            <>
              {sections.length > 0 ? (
                sections.map((item) => (
                  <>
                    <div>{item.title && item.title !== '' ? <Title level={2}>{item.title}</Title> : null}</div>
                    <div style={{ marginTop: 15 }}>{item.content && item.content !== '' ? <Text level={2}>{item.content}</Text> : null}</div>
                  </>
                ))
              ) : (
                <>{ParseHtml(data.business?.jobs_site_welcome_text || '')}</>
              )}
              {/*
                results ? (
                  <Col xs={24} className="welcome-other-processes">
                    <Text strong>{i18n.t('start__process__last_jobs_offers')}</Text>
                    {results.map((proc, idx) => (
                      <div key={idx}>
                        <Text style={{ fontSize: 16 }}>{proc.job_application}</Text>
                        <Button
                          style={{ ...theme }}
                          href={`/Genomawork/${proc.id}`}
                          target="_blank"
                        >
                          {i18n.t('start__process__new__see__job')}
                        </Button>
                      </div>
                    ))}
                  </Col>
                ) : null
                */}
              <Col xs={24} className="welcome-social-networks">
                <Text strong>{i18n.t('start__process__social__networks')}</Text>
                {['rrss_facebook', 'rrss_instagram', 'rrss_linkedin', 'rrss_twitter'].map(rs => (
                  (data.business && data.business[rs]) ?
                  <a href={data.business[rs]} target="_blank" key={rs}>
                    <Icon
                      type={rs.replace('rrss_', '')}
                    />
                    <span>{rs.replace('rrss_', '')[0].toUpperCase() + rs.replace('rrss_', '').slice(1, rs.replace('rrss_', '').length)}</span>
                  </a> : null
                ))}
              </Col>
            </>
          ),
        };

        setTabs(tmp);
      } catch (e) {
        console.error(e);
      }
    };

    prepareContent();
  }, [data, theme]);

  const getQuerystring = (key) => {
    const queryString = new URLSearchParams(window.location.search);
    return queryString.get(key) || '';
  };

  const onSelectLanguage = (code) => {
    setSelectLanguage(code);
    changeLanguage(code);
  };

  const changeLanguage = (value) => {
    if (value.toLowerCase() == 'gb') {
      value = 'EN';
    } else if (value.toLowerCase() == 'br') {
      value = 'PT';
    }
    i18n.changeLanguage(value.toLowerCase());
    window.location.reload();
  };

  const name = data.business?.name ?? '';

    const background = banner ? banner : defaultBackground;
    const formattedBackground = background[0] === '/' ? `${SERVER_URL}${background}` : background;

  return (
    <>
      {!isAuthenticated && (
        <Layout.Header style={{ textAlign: 'end' }}>
          <Select
            onSelect={onSelectLanguage}
            defaultValue={i18n.language.split('-')[0].toUpperCase()}
            style={{ fontSize: '0.8em' }}
            maxTagCount={'responsive'}
            getPopupContainer={trigger => trigger}
          >
            {Object.keys(languages).map((key, index) => (
              <Select.Option key={index} value={key} >
                <span className='location-with-flag' style={{ alignItems: 'center' }}>
                  <span className='jl-flag'>
                    <div className="menu-select-image">
                      <img src={`${FLAG_URL}${languages[key].flag}.svg`} />
                    </div>
                  </span> {' '}
                  <span className="menu-select-text">
                    {window.innerWidth >= 600 ? languages[key].language : key}
                  </span>
                </span>
              </Select.Option>
            ))}

          </Select>

        </Layout.Header>)}

      <Row className={`welcome-container`}>
        <Col className={`welcome-container__header ${headerShrinked ? "shrinked" : ""}`}
          xs={24}
        >
          <div>
            <img alt='Portada empresa' src={background} className='welcome-background-image' />
            {
              isMobile &&
              <>
                <Row className='welcome-header-mobile' type='flex' justify='space-around'>
                  <Col span={24}>
                    {
                      !getQuerystring('hide_title').includes('true') &&
                      <Title level={2} style={{ textAlign: "center", color: '#f0f0f0' }}>{data.job_application?.toUpperCase()}</Title>
                    }
                    <div style={{ textAlign: "center" }}>
                      <Text style={{ color: '#f0f0f0' }}>{data.business?.name.toUpperCase()}</Text>
                    </div>
                  </Col>
                  <Col xs={20} sm={20} md={12} lg={6} className="icons-header">
                    {
                      /* Hide department
                      <div>
                        <Icon type="idcard" style={{ color: '#f0f0f0' }} />
                        <span>{data.department}</span>
                      </div>
                      <div className='icons-header__separator' />
                      */
                    }
                    <div>
                      <Icon type="environment" style={{ color: '#f0f0f0' }} />
                      <span>
                        {data.location_city}, {data.location_country}
                      </span>
                    </div>
                  </Col>
                </Row>
              </>
            }
          </div>
          {
            !isMobile &&
            <>
              <Col span={24}>
                {
                  !getQuerystring('hide_title').includes('true') &&
                  <Title level={3} style={{ textAlign: "center", color: '#f0f0f0' }}>{data.job_application?.toUpperCase()}</Title>
                }
                <div style={{ textAlign: "center" }}>
                  <Text style={{ color: '#f0f0f0' }}>{data.business?.name.toUpperCase()}</Text>
                </div>
              </Col>
              <Col xs={20} sm={12} md={10} lg={6} className="icons-header">
                {
                  /* Hide department
                  <div>
                    <Icon type="idcard" style={{ color: '#f0f0f0' }} />
                    <span>{data.department}</span>
                  </div>
                  <div className='icons-header__separator' />
                  */
                }
                <div>
                  <Icon type="environment" style={{ color: '#f0f0f0' }} />
                  <span>
                    {data.location_city}, {data.location_country}
                  </span>
                </div>
              </Col>
              <Col xs={20} sm={12} md={10} lg={8} className="icons-header">
                {
                  data.business?.name && !data.business?.name.toLowerCase().includes('minera centinela') &&
                  <Button style={{ ...theme, borderColor: theme.background }} size='large' className="start-process-button" href="#anchor-tabs" onClick={handleKnowMore}>
                    {i18n.t('start_process__know_more')}
                  </Button>
                }
                <Button style={{ ...theme, borderColor: theme.background }} onClick={start} size='large' className="start-process-button">
                  {
                    data.business?.name && data.business?.name.toLowerCase().includes('minera centinela') ||
                      getQuerystring('utm_medium').includes('integration') ||
                      getQuerystring('apply').includes('continue')
                      ?
                      i18n.t('commons__continue') :
                      i18n.t('job_portal__apply')
                  }
                </Button>
              </Col>
            </>
          }
        </Col>
        {
          isMobile &&

          <Col xs={24} sm={24} md={12} lg={8} className="icons-header">
            {
              data.business?.name && !data.business?.name.toLowerCase().includes('minera centinela') &&
              <Button style={{ ...theme, borderColor: theme.background }} size='large' className="start-process-button">
                {i18n.t('start_process__know_more')}
              </Button>
            }
            <Button style={{ ...theme, borderColor: theme.background }} onClick={start} size='large' className="start-process-button">
              {
                data.business?.name && data.business?.name.toLowerCase().includes('minera centinela') ||
                  getQuerystring('apply').includes('continue')
                  ?
                  i18n.t('commons__continue') :
                  i18n.t('job_portal__apply')
              }
            </Button>
          </Col>
        }


        <Col xs={24} id='anchor-tabs'>
          <CustomTabs
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            tabs={hideCompanyDescription || data.is_confidential ? ['job'] : ['job', 'company']}
            color={theme.background}
          />
          <Col xs={22} md={12} style={{ padding: '1.5rem 0', lineHeight: 1.3 }}>
            {tabs[currentTab]}
          </Col>
          <Col xs={22} md={12} className="support-card">
            <Icon type="info-circle" theme="filled" />
            <span>
              {i18n.t('start__process__new__start__process')}
            </span>
          </Col>

          <Col xs={24} md={12} className="start-process-div-button">
            <Row type='flex' justify='center'>
              <Col xs={24} md={12}>
                <Button style={{ ...theme }} onClick={start} size='large' className="start-process-button">
                  {
                    data.business?.name && data.business?.name.toLowerCase().includes('minera centinela') ||
                      getQuerystring('apply').includes('continue')
                      ?
                      i18n.t('commons__continue') :
                      i18n.t('job_portal__apply')
                  }
                </Button>
              </Col>
            </Row>
          </Col>
        </Col>
      </Row >

    </>
  );
};

WelcomeView.propTypes = {
  theme: PropTypes.object,
  data: PropTypes.object,
  start: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(WelcomeView);
