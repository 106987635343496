import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../../../../../actions/jobapp';
import * as actionsDataCreators from '../../../../../../../actions/data';
import { 
  Col, 
  List, 
  Icon, 
  Button,
  Typography,
  Tag,
  Divider } from 'antd';
import { icon } from '../../../constants';
import { isMobile } from 'react-device-detect';
import MobileActions from './MobileActions';

const { Text } = Typography;

class Templates extends Component {

  state = {
    loadMore: false
  }

  onLoadMore = () => {
    // desplegar todas las plantillas
    this.setState({ loadMore: true });
  }

  render() {
    const { loadMore } = this.state;
    const { questionToDelete } = this.props;
    const removed = (id) => id === questionToDelete.id;
    return (
      <Col xs={24} id='templates' className='af__templates'>
        <div className='headline'>
          <Text strong>Plantillas de preguntas</Text>
        </div>

        <Divider />

        <List
          size="small"
          dataSource={loadMore && this.props.templates.length > 5 ? this.props.templates : this.props.templates.slice(0, 5)}
          renderItem={(item, index) => 
            <List.Item 
              key={index}
              actions={
                isMobile ? ([
                  <MobileActions 
                    main={() => this.props.addQuestion(item)}
                    delete={() => this.props.deleteTemplate(item.id)}
                    permission={this.props.permission}
                    type='plus'
                  />
                ]) : ([
                  <Button 
                    icon='plus' 
                    className='main-btn add' 
                    disabled={removed(item.id)} 
                    onClick={() => this.props.addQuestion(item)} 
                  />,
            
                  index !== 0 && ( 
                    <Button 
                      icon='minus' 
                      className='main-btn'
                      disabled={removed(item.id) || (!this.props.permission.can_manage && !this.props.permission.business_admin)} 
                      onClick={() => this.props.deleteTemplate(item.id)}
                    />
                  )
                ])
              }
              className={removed(item.id) && 'qn-removed'}
            >
              <List.Item.Meta 
                title={
                  <div>
                    <Tag>
                      {item.type.name === 'multiselection' ? 
                          icon[item.type.name]
                        :
                          <Icon type={icon[item.type.name]} />
                      }
                    </Tag>
                    <Text strong>{item.question.name}</Text>
                  </div>
                } 
              />
            </List.Item>
          }
        />

        {!loadMore && this.props.templates.length >= 6 &&
          <div className='bottom'>
            <Button onClick={this.onLoadMore} icon='down'>Ver todos ({this.props.templates.length})</Button>
          </div>
        }
      </Col>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    koActivity: state.jobapp.assignedActivities.KO,
    jobApplication: state.jobapp.jobApplication,
    data: state.data.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: bindActionCreators(actionCreators, dispatch),
    actionsData: bindActionCreators(actionsDataCreators, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Templates);