import React, { Component } from 'react';
import { 
  Col,  
  Icon, 
  Button, 
  Card, 
  Form, 
  Typography,
  Select,
  Switch } from 'antd';
import TypeOfAnswer from './types/index';
import { icon, typeToSpanishText } from '../../../../constants';

const { Option } = Select;
const { Text } = Typography;

class AddNewQuestion extends Component {
  onChange = () => {
    const { setFieldsValue } = this.props.form;

    setFieldsValue({
      intFrom: '',
      intTo: '',
      fileName: '',
      fileDescription: '',
      fileType: ['PDF'],
      keys: [0, 1],
      alternative: [undefined, undefined],
      correct: [false, false],
      mandatory: true,
    })
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    return (
      <Col xs={24} sm={24} md={20} lg={18} id='question-form'>
        <Card className='af__new-question'>
          <div className='af__new-question__header'>
            <Text strong>{this.props.title}</Text>
            <Button icon='close' onClick={this.props.closeForm} />
          </div>

          <Form onSubmit={this.props.submit}>
            <Form.Item label='Tipo de respuesta'>
              {getFieldDecorator('type', {
                initialValue: 'singleline',
                onChange: this.onChange,
                rules: [{ required: true, message: 'Por favor selecciona el tipo de respuesta' }],
              })(
                <Select>
                  {Object.keys(typeToSpanishText).map((key) => (
                    <Option value={key} key={key}>
                      {key === 'multiselection' ? <div className='new-qn-select-icon-db-check'>{icon[key]}</div> : <div className='new-qn-select-icon'> <Icon type={icon[key]} /> </div>}
                      {' '}
                      {typeToSpanishText[key]}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item hidden={true}>
              {getFieldDecorator('mandatory', {
                defaultChecked: true,
                initialValue: true,
              })(<Switch checked={getFieldValue('mandatory') ? true : false} />)
              }
            </Form.Item>

            <TypeOfAnswer type={getFieldValue('type')} {...this.props.form} />

            <Form.Item className='bottom'>
              <Button className='main-btn' onClick={this.props.closeForm}>Cancelar</Button>
              <div className='main-actions'>
                {(this.props.permission.can_manage || this.props.permission.business_admin) ? (
                  <Button
                    className='main-btn'
                    disabled={!this.props.permission.can_manage && !this.props.permission.business_admin}
                    onClick={this.props.submit}
                  >
                    Guardar como plantilla
                  </Button>
                ) : null}
                <Button type='primary' htmlType="submit">Guardar</Button>
              </div>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    )
  }
}

export default AddNewQuestion;