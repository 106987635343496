import React from 'react';
import debounce from 'lodash.debounce';

export const useIsOverflow = (ref) => {
  const [isOverflow, setIsOverflow] = React.useState(null);

  const debouncedCheckOverflow = debounce((ref) => {
    if (ref.current.scrollWidth > ref.current.clientWidth) {
      setIsOverflow(true);
    } else {
      setIsOverflow(false);
    }
  }, 300);

  React.useEffect(() => {
    const { current } = ref;

    const checkOverflow = () => {
      debouncedCheckOverflow(ref);
    };

    if (ref && current) {
      window.addEventListener('resize', checkOverflow);

      return () => {
        window.removeEventListener('resize', checkOverflow);
      };
    }
  }, [ref]);

  return isOverflow;
};
