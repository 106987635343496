import React, { useState } from 'react';
import {
    Icon,
    Input,
    Tag,
} from 'antd';


/**
 *  
 * @returns 
 */
export default function Tags({ tags, addTag, removeTag }) {
    const [newTag, setNewTag] = useState(null);

    const handleCreateTag = () => {
        if (newTag !== null && newTag !== '') {
          addTag(newTag);
        }
        setNewTag(null);
    }


    return (
        <div>
        {tags.map(tag => {
            return (
            <Tag key={tag} closable onClose={() => removeTag(tag)}>
                {tag}
            </Tag>
            );
        })}
        {newTag !== null ? <Input
            type="text"
            size="small"
            style={{ width: 100, paddingRight: '22px', marginRight: 10 }}
            value={newTag}
            onChange={e => setNewTag(e.target.value)}
            onBlur={handleCreateTag}
            onPressEnter={handleCreateTag}
          /> : null}
        <Tag
            onClick={() => setNewTag('')}
            style={{
              background: '#fff',
              borderStyle: 'dashed',
            }}
          >
            <Icon type="plus" /> Agregar
          </Tag>
        </div>
    );
}


