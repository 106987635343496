import React from 'react';
import { Card, Col, Icon, Row, Typography } from 'antd';
import i18n from '../../../../../i18n';

const { Text } = Typography;

const StageCards = ({ data }) => {
  const keyToName = {
    ACTIV: 'En proceso',
    DONE: 'Completado',
    V_INT: 'Videoentrevista',
    V_INTR: 'Videoentrevista completada',
    V_INTE: 'Videoentrevista\n evaluada',
    INTRV: 'Entrevista\n presencial',
    HIRED: 'Contratado',
  };

  const cardHeight = Math.max(...Object.keys(data).map((key) => data[key].reasons.length)) * 35 + 100;

  const stagePercentage = (reasonCount, stageCount) => {
    if (stageCount === 0) {
      return 0;
    }
    return Math.round((reasonCount / stageCount) * 100);
  };


  return (
    <Row>
      {Object.keys(data).map((key, idx) => (
        <Col span={6}>
          <Card
            bodyStyle={{ border: '1px solid #1890FF', height: cardHeight, overflow: 'auto' }}
            headStyle={{ backgroundColor: '#1890FF' }}
            key={key}
            title={
              <>
                <Text style={{ color: 'white' }} strong>{keyToName[key]}</Text>
                <br />

                <Text style={{ color: 'white' }} type='secondary'><Icon type="team" />
                  {data[key].count > 0 && i18n.t('analytics__total_discarded_candidates', { count: data[key].count })}
                </Text>
              </>
            }
            bordered
          >
            {data[key].reasons.length !== 0 ? (
              data[key].reasons.map((reason, idx) => (
                <Row style={{ marginBottom: 10 }}>
                  <Text>{reason.discard_reason_title}</Text>
                  <br />
                  <Text style={{ color: '#A4A4A4' }}>{stagePercentage(reason.count, data[key].count)}%</Text>
                  <br />
                </Row>
              ))
            ) : (
              <Row style={{ textAlign: 'center' }}>
                <Text>{i18n.t('commons__no_information')}</Text>
              </Row>
            )}
          </Card>
        </Col>
      ))
      }
    </Row>
  );
}

export default StageCards;