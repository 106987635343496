import React, { useState }  from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Sentry from '@sentry/browser';

import { Button, Col, Input, message, Modal, Row, Tag, Typography } from 'antd';

import * as actionCreators from '../actions/data';
import useBoolean from '../hooks/useBoolean';

const { Paragraph, Text } = Typography;

function AddTagsComponent({actions, closeModal, refreshTable, selectedPersonalUsers, token, visible}) {
  const [newTag, setNewTag] = useState('');
  const [tags, setTags] = useState([]);
  const [submittingTags, handleSubmittingTags] = useBoolean(false);

  const handleSubmit = async () => {
    handleSubmittingTags.on();
    const candidateIds = Object.keys(selectedPersonalUsers);
    const notSent = [];
    for (let i = 0; i < candidateIds.length; i++) {
      try {
        const candidateId = candidateIds[i];
        const url = `gamesandtests/tags/personalusers/${selectedPersonalUsers[candidateId].personal_user_id}/`;
        const data = {
          tags: selectedPersonalUsers[candidateId].tags ? (
            [...selectedPersonalUsers[candidateId].tags, ...tags]
          ) : [...tags] };
        const response = await actions.sendData(token, url, JSON.stringify(data), 'PUT');
        if (response.status >= 400) {
          notSent.push(candidateId);
        }
      } catch (err) {
        Sentry.captureException(err);
      }
    }
    refreshTable();
    handleSubmittingTags.off();
    setTags([]);
    setNewTag('');
    closeModal();
    if (notSent.length === 0) {
      message.success('Etiquetas añadidas correctamente.')
    } else {
      notSent.forEach((personalUserId) => {
        return (
          message.error(
            `Etiquetas no se pudieron añadir a ${selectedPersonalUsers[personalUserId].name || selectedPersonalUsers[personalUserId].email}`
          )
        )
      });
    }
  };

  return (
    <Modal
      footer={[
        <Button onClick={closeModal} type="secondary">
          Cancelar
        </Button>,
        <Button loading={submittingTags} onClick={handleSubmit} type="primary">
          Enviar
        </Button>
      ]}
      onCancel={closeModal}
      title={(
        <>
          <Text strong>Añadir etiquetas</Text>
          <Paragraph style={{ marginBottom: 0 }}>
            {Object.keys(selectedPersonalUsers).length} candidato{Object.keys(selectedPersonalUsers).length === 1 ? '' : 's'}
          </Paragraph>
        </>
      )}
      visible={visible}
      width="60%"
    >
      <Text strong style={{ fontSize: '16px' }}>Etiquetas</Text>
      <Paragraph style={{ marginBottom: 0 }}>
        Crea etiquetas que se agregarán al perfil del candidato.
      </Paragraph>

      <Row style={{ marginTop: '1em' }} type="flex">
        {tags.map((tagText, index) => (
          <Tag
            closable
            onClose={() => setTags((prevTags) => {
              const updatedTags = [...prevTags];
              updatedTags.splice(index, 0);
              return updatedTags;
            })}
            style={{
              fontSize: '14px',
              marginBottom: '1em',
              marginRight: '1em',
              paddingBottom: '0.25em',
              paddingLeft: '0.5em',
              paddingRight: '0.5em',
              paddingTop: '0.25em',
            }}
          >
            {tagText}
          </Tag>
        ))}
      </Row>
      <Row gutter={10} type="flex">
        <Col span={6}>
          <Input
            onChange={(event) => setNewTag(event.target.value)}
            value={newTag}
          />
        </Col>
        <Col>
          <Button
            onClick={() => {
              setTags((prevTags) => [...prevTags, newTag]);
              setNewTag('');
            }}
          >
            Agregar etiqueta
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

const mapStateToProps = state => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTagsComponent);