import React, { useState, useEffect } from 'react';
import {
  Button,
  Divider,
  Col,
  Row,
  Icon,
  Progress,
  Tag,
  Typography,
} from 'antd';
import '../styles.scss';
import Segments from '../Segments';
import RavenDictionaryComponent from './RavenComponent';
import ParseHtml from '../../../../../components/parseHtml';
import i18n from '../../../../../i18n';

const { Text, Title } = Typography;

export const DetailAlternatives = ({
  answers,
  questions,
  sortedQuestions,
  scores,
  scoresConfig,
  description,
  dateCreated,
  type,
  // Classic game response, some cutes may need this
  personalityScores,
}) => {
  const [hideAnswers, setHideAnswers] = useState(true);
  const [categoriesCount, setCategoriesCount] = useState({});
  const [CategoriesFiltereded, setCategoriesFiltereded] = useState({});

  const multipleAnswerComponent = (answerId, idx) => {
    const question = questions[answerId];
    const answerValue = answers[answerId];
    const answerIsCorrect = scores.raw_result[answerId]?.correct;
    const optionsSelected = question.options.filter((opt) => answers[answerId].includes(opt.value))
    const {
      label: questionLabel,
      category: questionCategory,
      type: answerType,
    } = question;

    return (
      <Col
        span={24}
        style={{
          marginBottom: '2rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.25rem',
        }}
      >
        <div>
          <div style={{ display: 'flex', gap: '0.25rem' }}>
            {idx + 1}. {ParseHtml(questionLabel)}
          </div>
        </div>
        {answerValue && answerType === 'radio' ?
          (
            <div
              className={
                answerIsCorrect
                  ? 'cute-answer cute-answer__correct'
                  : 'cute-answer cute-answer__wrong'
              }
              style={{ display: 'flex', flexDirection: 'row', gap: '0.25rem' }}
            >
              <Row type='flex' align='middle'>
                {optionsSelected.map((opt, idx) => (
                  <Tag key={idx} type='flex' style={{ padding: '5px', width: '100%' }}>{ParseHtml(opt.label)}</Tag>
                ))}
              </Row>
              <Icon
                type={answerIsCorrect ? 'check-circle' : 'close-circle'}
                theme="twoTone"
                twoToneColor={answerIsCorrect ? '#389E0D' : '#FF4D4F'}
              />
            </div>
          ) : (
            <div className={'cute-answer cute-answer__wrong'}>
              <Icon
                type="close-circle"
                theme="twoTone"
                twoToneColor={'#FF4D4F'}
              />
              <Text type="danger">Sin respuesta</Text>
            </div>
          )}
      </Col>
    )
  }

  const alternativesSkillObject = (tmpQuestions, rawResult, correctAnswer) => {
    let objToReturn = {};
    sortedQuestions.forEach((key) => {
      if (!tmpQuestions[key]) {
        return;
      }
      if (!objToReturn[tmpQuestions[key].category])
        objToReturn[tmpQuestions[key].category] = { correct: [], wrong: [] };

      if (rawResult[key]?.correct) {
        objToReturn[tmpQuestions[key].category].correct.push({
          id: key,
          ...correctAnswer[key],
        });
      } else {
        objToReturn[tmpQuestions[key].category].wrong.push({
          id: key,
          ...correctAnswer[key],
        });
      }
    });

    return setCategoriesFiltereded(objToReturn);
  };

  useEffect(() => {
    const categoriesCount = Object.values(questions).reduce((obj, v) => {
      obj[v.category] = (obj[v.category] || 0) + 1;
      return obj;
    }, {});
    setCategoriesCount(categoriesCount);
    alternativesSkillObject(
      questions,
      scores.raw_result,
      scoresConfig.correct_answers
    );
  }, []);

  const answerComponent = (answerId, idx) => {
    const answerValue = answers[answerId];
    const question = questions[answerId];

    const answerOptionIndex = question['options']?.findIndex(
      (obj) => obj.value === answerValue
    );

    const answerValueLabel = question['options']?.find(
      (obj) => obj['value'] === answerValue
    )?.label;
    const answerIsCorrect = scores['raw_result']?.[answerId]?.correct || type === 'SJT';


    const {
      label: questionLabel,
      category: questionCategory,
      type: answerType,
    } = question;

    return (
      <Col
        span={24}
        style={{
          marginBottom: '2rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.25rem',
        }}
      >
        {questionCategory ? (
          <Tag color="geekblue" style={{ width: 'fit-content' }}>
            {questionCategory}
          </Tag>
        ) : null}
        <div>
          <div style={{ display: 'flex', gap: '0.25rem' }}>
            {idx + 1}. {ParseHtml(questionLabel)}
          </div>
        </div>
        {answerValue && answerType === 'text' ? (
          <div className="cute-answer">{answerValue}</div>
        ) : answerValueLabel ? (
          <div
            className={
              answerIsCorrect
                ? 'cute-answer cute-answer__correct'
                : 'cute-answer cute-answer__wrong'
            }
          >
            {/* get correct answer from scores config and show, tmp_value = scores_config['correct_answers']['value'] -> questions['answerId']['options'].find(obj => obj['value'] === tmp_value)?.label
                !answerIsCorrect && 
                  <div>
                    { }
                  </div>
                  */}
            <span>{String.fromCharCode(97 + answerOptionIndex)}</span>
            <div
              className="cute-divider"
              style={{ background: answerIsCorrect ? '#389E0D' : '#FF4D4F' }}
            />
            <div style={{ flexGrow: 1 }}>{ParseHtml(answerValueLabel)}</div>
            <Icon
              type={answerIsCorrect ? 'check-circle' : 'close-circle'}
              theme="twoTone"
              twoToneColor={answerIsCorrect ? '#389E0D' : '#FF4D4F'}
            />
          </div>
        ) : (
          <div className={'cute-answer cute-answer__wrong'}>
            <Icon
              type="close-circle"
              theme="twoTone"
              twoToneColor={'#FF4D4F'}
            />

            <Text type="danger">Sin respuesta</Text>
          </div>
        )}
      </Col>
    );
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const realQuestionNumber = Object.values(questions).filter(
    (q) => q.type !== 'text'
  ).length;



  return (
    <>
      {dateCreated && (
        <Row style={{ margin: '15px 0 15px 0' }}>
          <Text>
            Fecha de finalizacion: <strong>{dateCreated}</strong>
          </Text>
        </Row>
      )}

      {description && (
        <>
          <Title level={4}>Descripción</Title>
          <Divider />
          <Row style={{ marginBottom: 24 }}>
            <Col span={24}>{ParseHtml(description)}</Col>
          </Row>
        </>
      )}

      {scores.weighted_score &&
        <>
          <Title level={4}>Puntaje Ponderado</Title>
          <Divider />
          <Row type="flex">
            <Text strong style={{ margin: '0 0 20px 0' }}>
              {`${scores.weighted_score.toFixed(1)}%`}
            </Text>
          </Row>
        </>
      }

      <Title level={4}>Resultados del evaluado</Title>
      <Divider />
      {
        scoresConfig.type !== 'RAVEN' ?
          <Row type="flex" justify="center" align="middle">
            <Col span={8}>
              <Row type="flex" align="middle" style={{ flexDirection: 'column' }}>
                {type === 'SJT' ? <Text>{scores.result} puntos</Text> :
                  <Text strong style={{ margin: '0 0 20px 0' }}>
                    {`${scores.result} respuestas correctas de ${realQuestionNumber}`}
                  </Text>
                }
                <Progress
                  percent={Math.round(
                    (scores.result / scoresConfig.questions_to_evaluate) * 100
                  )}
                  strokeColor="95DE64"
                  strokeWidth={20}
                  strokeLinecap="square"
                  type="dashboard"
                  gapDegree={126}
                />
              </Row>
            </Col>
            <Col span={16}>
              <Segments scoresConfig={scoresConfig} scores={scores} />
            </Col>
          </Row> :
          <RavenDictionaryComponent dictionary={scores?.custom_scores} scoresConfig={scoresConfig} />
      }

      {scoresConfig?.trait_config &&
        <Row>
          <div style={{ marginBottom: 15, marginTop: 20 }}>
            {`El nivel de riesgo se ve influido por los resultados provenientes del perfil de personalidad del evaluado de la siguiente manera:`}
          </div>

          <div style={{ marginBottom: 15 }}>
            <div><strong>{`Responsabilidad: ${personalityScores.find(obj => obj.code === 'RESPO')?.score} puntos.`}</strong></div>
            {`A medida que las personas muestran mayores puntajes de responsabilidad, suelen tener una menor tendencia a presentar conductas contraproductivas al mostrarse más prudentes y con orientación al deber.`}
          </div>

          <div style={{ marginBottom: 15 }}>
            <div><strong>{`Amabilidad: ${personalityScores.find(obj => obj.code === 'AMABI')?.score} puntos.`}</strong></div>
            {`A medida que las personas muestran mayores puntajes de amabilidad, suelen tener una menor tendencia a presentar conductas contraproductivas al priorizar las necesidades de otros por sobre sus intereses y buscar comportarse acorde a normas sociales.`}
          </div>

          <div style={{ marginBottom: 15 }}>
            <div><strong>{`Neuroticismo: ${personalityScores.find(obj => obj.code === 'NEURO')?.score} puntos.`}</strong></div>
            {`A medida que las personas muestran menores puntajes en neuroticismo, suelen tener una menor tendencia a presentar conductas contraproductivas al mostrar una mayor calma y estabilidad emocional.`}
          </div>
        </Row>
      }

      <Title level={4}>Dimensiones</Title>
      <Divider />
      <Row type="flex">
        {scores.category_result &&
          Object.keys(scores.category_result).map((elem) => {
            if (categoriesCount[elem]) {
              return (
                <Col span={24} key={elem} style={{ marginBottom: 15 }}>
                  <Text>{capitalizeFirstLetter(elem)}</Text>
                  <Text strong>
                    {` ${scores.category_result[elem]} de ${categoriesCount[elem]}`}
                  </Text>
                  <Progress
                    percent={Math.floor(
                      (scores.category_result[elem] / categoriesCount[elem]) *
                      100
                    )}
                    size="default"
                    strokeWidth={10}
                    strokeColor="#ADC6FF"
                  />
                </Col>
              );
            }
          })}
      </Row>

      {
        // This is for soprole
        Object.values(scoresConfig.correct_answers).find(
          (obj) => obj.alternatives_skill
        ) && (
          <Row type="flex" justify="space-between">
            <Col>
              <Title level={4}>Componentes evaluados</Title>
            </Col>
            {Object.keys(CategoriesFiltereded).map((name, index) => (
              <Col
                key={index}
                span={24}
                style={{ padding: '5px', margin: '5px 0' }}
              >
                <Col style={{ marginBottom: '5px ' }}>
                  <Text strong>{name}</Text>
                </Col>
                <Col span={24} style={{ marginBottom: '3px' }}>
                  <Text underline>
                    Los ámbitos logrados en esta competencia son:
                  </Text>
                </Col>
                <Col span={24}>
                  {CategoriesFiltereded[name].correct.map(
                    ({ alternatives_skill, id }) => (
                      <Col key={id} span={24} style={{ padding: '0 5px' }}>
                        <Text> - {alternatives_skill}</Text>
                      </Col>
                    )
                  )}
                </Col>
                <Col span={24} style={{ margin: '5px 0 3px 0' }}>
                  <Text underline>
                    Los ámbitos <Text strong> NO </Text> logrados en esta
                    competencia son:
                  </Text>
                </Col>
                <Col span={24}>
                  {CategoriesFiltereded[name].wrong.map(
                    ({ alternatives_skill, id }) => (
                      <Col key={id} span={24} style={{ padding: '0 5px' }}>
                        <Text> - {alternatives_skill}</Text>
                      </Col>
                    )
                  )}
                </Col>
              </Col>
            ))}
          </Row>
        )
      }

      <Row type="flex" justify="space-between" style={{ marginTop: '15px' }}>
        <Col>
          <Title level={4}>{i18n.t('profile__answers', { ns: 'candidateProfile' })}</Title>
        </Col>
        <Col>
          <Button onClick={() => setHideAnswers((oldState) => !oldState)}>
            {hideAnswers ? 'Mostrar respuestas' : 'Ocultar Respuestas'}
          </Button>
        </Col>
      </Row>

      {!hideAnswers && (
        <Row type="flex">
          {sortedQuestions.map((elem, idx) => {
            return (
              <Col span={24} key={idx}>
                {scoresConfig.correct_answers[elem]?.type === 'multiple_answers' ? multipleAnswerComponent(elem, idx) : answerComponent(elem, idx)}
              </Col>
            );
          })}
        </Row>
      )}
    </>
  );
};

DetailAlternatives.propTypes = {};
export default DetailAlternatives;
