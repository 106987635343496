import React from 'react';
import { Typography, Icon, Tabs, Input, Button } from 'antd';
import CustomPreview from './CustomPreview';

import debounce from 'lodash.debounce';

const { Text, Title } = Typography;

const Colors = ({ colors = {}, handleFormChange, defaultColors }) => {
  const debouncedSetColors = debounce((color, key) => {
    handleFormChange('confidential_color_theme')({ ...colors, [key]: color });
  }, 250);

  return (
    <div className="confidential-colors">
      <div className="confidential-colors__left">
        <div style={{ display: 'flex', gap: '1.5rem' }}>
          <div className="confidential-colors__picker">
            <span>Color principal de la empresa</span>
            <div className="confidential-colors__picker__inputs">
              <input
                value={colors?.background}
                type="color"
                onChange={(e) =>
                  debouncedSetColors(e.target.value, 'background')
                }
              />
              <Input value={colors?.background} />
            </div>
          </div>
          <div className="confidential-colors__picker">
            <span>Color del texto del botón</span>
            <div className="confidential-colors__picker__inputs">
              <input
                value={colors?.color}
                type="color"
                onChange={(e) => debouncedSetColors(e.target.value, 'color')}
              />
              <Input value={colors?.color} />
            </div>
          </div>
        </div>
        <Button
          onClick={() =>
            handleFormChange('confidential_color_theme')(defaultColors)
          }
        >
          Deshacer cambios
        </Button>
      </div>
      <CustomPreview
        colors={colors}
        texts={['Previsualización computadora', 'Previsualización teléfono']}
      />
    </div>
  );
};

export default Colors;
