import React, { useState, Fragment, Component, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../../actions/data';
import { bindActionCreators } from 'redux';
import { Modal, Icon, Row, Col, Button, message, Typography } from 'antd';
import i18n from '../../../i18n';

const SHARE_URL = process.env.REACT_APP_SHARE_URL;

const ShareProfile = ({
  candidateId,
  jobappId,
  visibleShareProfile,
  handleCancel,
  actions,
  authToken,
  candidateType,
}) => {
  const [loading, setLoading] = useState(false);
  const [shareToken, setShareToken] = useState(null);

  const handleOk = () => {
    setLoading(true);
    getToken();
  };

  const getToken = async () => {
    try {
      const url = '/api/v1/gamesandtests/sharecandidateprofile/';
      let data = candidateType === 'active'
        ? {
          candidate_id: candidateId,
          jobapp_id: jobappId,
        }
        : {
          passive_candidate_id: candidateId,
          jobapp_id: jobappId,
        };
      const { body } = await actions.fetchAndReturn(
        authToken,
        url,
        'POST',
        data,
        
      );

      if (body) {
        setShareToken(body.token);
        return message.success(i18n.t('profile__link_generated', {ns: 'candidateProfile'}));
      }
    } catch (err) {
      message.error(i18n.t('profile__share_link_error', {ns: 'candidateProfile'}));
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(
      `${SHARE_URL}/sharedprofile/${shareToken}/${jobappId}/${candidateId}`
    );
    message.success(i18n.t('profile__link_copy_ok', {ns: 'candidateProfile'}));
  };

  return (
    <Modal
      className="sendMail"
      centered
      width={800}
      visible={visibleShareProfile}
      closable={false}
      footer={[
        !shareToken ? (
          <Fragment>
            {' '}
            <Button key="back" onClick={handleCancel}>
              {i18n.t('profile__cancel', {ns:'candidateProfile'})}
            </Button>
            <Button
              key="submit"
              type="primary"
              loading={loading && !shareToken ? true : false}
              onClick={handleOk}
              htmlType="submit"
            >
              {i18n.t('profile__accept', {ns:'candidateProfile'})}
            </Button>{' '}
          </Fragment>
        ) : (
          <Button onClick={handleCancel} type="primary">
            Ok
          </Button>
        ),
      ]}
    >
      {!shareToken ? (
        <Fragment>
          <Row>
            <Col xs={1}>
              <Icon
                type="exclamation-circle"
                style={{
                  marginTop: '7px',
                  fontSize: '17px',
                  color: 'orange',
                }}
              />
            </Col>
            <Col xs={23}>
              <div>
                <p>{i18n.t('profile__warning',{ns:'candidateProfile'})}</p>
                {i18n.t('profile__share_profile_text', {ns:'candidateProfile'}).split('\\n')[0]}
                <br />
                {i18n.t('profile__share_profile_text', {ns:'candidateProfile'}).split('\\n')[1]}
                <br />
                {i18n.t('profile__share_profile_text', {ns:'candidateProfile'}).split('\\n')[2]}

              </div>

              <br />
              <small>
                {i18n.t('profile__tos', {ns:'candidateProfile'}).split('{{')[0]}{' '}
                <a
                  href="https://docs.wixstatic.com/ugd/1837b1_015a59889ae64150a63a822ab2fbfc50.pdf"
                  target="_blank"
                >
                  {i18n.t('profile__tos', {ns:'candidateProfile'}).split('{{')[1].split('}}')[0]}
                </a>
                .
              </small>
            </Col>
          </Row>
        </Fragment>
      ) : (
        <Button
          onClick={copyToClipboard}
          style={{
            margin: 'auto',
            display: 'flex',
            lineHeight: '14px',
            fontSize: '18px',
            height: 'auto',
            padding: '16px 22px 0',
            border: 'none',
            textDecoration: 'underline',
          }}
        >
          {' '}
          <Typography.Paragraph copyable>Copiar link</Typography.Paragraph>{' '}
        </Button>
      )}
    </Modal>
  );
};

const mapDispathToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

export default connect(null, mapDispathToProps)(ShareProfile);
