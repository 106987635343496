import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';

import FormView from './FormView'
import BlockedView from './BlockedView'

import * as actionCreators from '../../../actions/data';
import * as gameActions from '../../../actions/games';

import {
  Spin,
} from 'antd';


function CustomTestView(props) {

  const [loading, setLoading] = useState(true);
  const [finished, setFinished] = useState(false);

  const queryString = new URLSearchParams(window.location.search);
  const cuteId = queryString.get("cuteId");
  const assActPP = queryString.get("assActPP");
  const jobappId = props.match.params['jobappid'];

  /**
   * TODO fetch job app here, also get banner
   */
  useEffect(() => {
    // Check if candidate completed the test
    const endpoint = `/api/v1/gamesandtests/personalprogress/${assActPP}/`
    props.actions.fetchAndReturn(props.token, endpoint)
      .then(response => {
        if (response.status < 400 && response.body.status !== 'INITIAL') {
          setFinished(true);
        }
        else if (response.status >= 400) {
          setFinished(true);
        }
        setLoading(false);
      })
  }, []
  );

  useEffect(() => {
    if (props.match.params['jobappid'] === undefined) {
      const url = `/me/`
      props.dispatch(push(url));
    }
  }, [props.match.params['jobappid']]
  );

  const backToPath = () => {
    const url = `/jobapplication/${jobappId}/`
    props.dispatch(push(url))
  };

  const View = () => (
    assActPP && !finished ?
      <FormView
        jobappId={jobappId}
        cuteId={cuteId}
        assActPP={assActPP}
        backToPath={backToPath}
      />
      :
      <BlockedView backToPath={backToPath} />
  )

  return (
    <Spin spinning={loading}>
      <View />
    </Spin>
  )
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    gameActions: bindActionCreators(gameActions, dispatch),
    dispatch
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(CustomTestView);
