import React, { useState } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from 'react-router-redux';
import { v4 as randomID } from 'uuid';

import * as actionCreators from "../../../../actions/data";
import '../InterviewKit.scss';
import ModalDelete from '../components/ModalDelete';
import CardList from '../components/CardList';

import { Row, Col, Breadcrumb, Input, Button, Tabs, Form, Typography, Icon, Popover, Modal, Layout } from "antd";

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs

const NewScorecard = (props) => {

  const [showEdit, setShowEdit] = useState(false)
  const [specificId, setSpecificId] = useState(null)

  const [cardkit, setCardkit] = useState({
    name: '',
    questions: [],
    type: 'CARD',
    // creation: Date.now()
  })
  const [inputQuestion, setInputQuestion] = useState({
    text: '',
    skill: [],
    question_number: ''
  })
  const [showDelete, setShowDelete] = useState({
    state: false,
    idQuestion: ''
  });

  const handleCardName = (e) => {
    setCardkit({
      ...cardkit,
      name: e.target.value
    })
  }

  const handleInput = (e) => {
    setInputQuestion({
      ...inputQuestion,
      [e.target.name]: e.target.value
    })
  }
  const addQuestion = () => {
    if (inputQuestion.text === '') {
      Modal.error({
        title: '¡Error!',
        content: 'Debes llenar el campo "Habilidades"',
        centered: true
      })
      return;
    }
    setCardkit({
      ...cardkit,
      questions: [...cardkit.questions, { ...inputQuestion, question_number: randomID() }]
    })
    setInputQuestion({ text: '', skill: [], question_number: '' })
  }
  const confirmDelete = (id) => {
    const questionFilter = cardkit.questions.filter(item => item.question_number !== id)
    setCardkit({
      ...cardkit,
      questions: questionFilter
    })
    setShowDelete({ state: false, idQuestion: '' })
  }
  const cancelDelete = () => {
    setShowDelete(false)
  }
  const copyQuestion = (index) => {
    const copyInput = cardkit.questions[index].text
    setInputQuestion({
      ...inputQuestion,
      text: copyInput
    })
  }
  const cleanInput = () => {
    setInputQuestion({
      text: '',
    })
  }
  const cancelButton = () => {
    props.dispatch(push('/account/interviewkit'))
  }

  const clickEdit = (index, idSkill) => {
    const { text, skill, question_number } = cardkit.questions[index]
    setShowEdit(true)
    setInputQuestion({ ...inputQuestion, text: text, skill: skill, question_number: question_number })
    setSpecificId(index)
  }
  const editConfirm = () => {
    cardkit.questions.splice(specificId, 1, inputQuestion)
    setInputQuestion({ ...inputQuestion, question_number: '', skill: [], text: '' })
    setSpecificId(null)
    setShowEdit(false)
  }
  const cancelEdit = () => {
    setInputQuestion({ ...inputQuestion, text: '', skill: [], question_number: '' })
    setSpecificId(null)
    setShowEdit(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, type, questions } = cardkit
    let endpoint = '/api/v1/evaluations/interviewkit/'
    let token = props.token
    let questionData = questions.map((value, index) => {
      return { ...value, question_number: index, question_type: type, feedback: true }
    })
    let body = { name: name, kit_type: type, questions: questionData }
    if (name === '') {
      Modal.error({
        title: '¡Error!',
        content: 'El nuevo Scorecard debe tener un nombre',
        centered: true
      })
      return;
    }
    try {
      props.actions.fetchAndReturn(token, endpoint, 'post', body)
        .then(res => {
          if (res.status < 400) {
            Modal.success({
              title: '¡Plantilla guardada!',
              content: 'Se guardó la plantilla configurada.',
              centered: true
            })
            props.dispatch(push('/account/interviewkit'))
          }
        })
    } catch (error) {
      console.log('Error al enviar el formulario -->', error)
    }
  }

  return (
    <Layout className='layoutCustomInterview'>
      <Row className='beadcrumNav'>
        <Col>
          <Breadcrumb separator=">">
            <Breadcrumb.Item href='/processes'>Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/tools">Mis herramientas</Breadcrumb.Item>
            <Breadcrumb.Item href="/account/interviewkit">Entrevistas Personalizadas</Breadcrumb.Item>
            <Breadcrumb.Item href="/account/interviewkit/new/scorecard">Plantilla Predeterminada</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row className="navTitle" gutter={20}>
        <Col>
          <Icon onClick={() => cancelButton()} type='arrow-left' style={{ fontSize: '25px' }} />
        </Col>
        <Col>
          <Title level={1}>Plantilla Predeterminada</Title>
        </Col>
      </Row>
      <Row className="infoSection">
        <Col>
          <Paragraph>Desde esta sección podrás configurar las habilidades que solicites para tus candidatos.</Paragraph>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit}>
        <Row>
          <div className="newInterviewTable">
            <Tabs>
              <TabPane key='1' tab='Vista de Edición'>
                <Row style={{ margin: '0px 20px' }}>
                  <Col>
                    <Title style={{ color: '#5E5E5E' }} level={4}>Tipo de Entrevista Personalizada</Title>
                  </Col>
                  <Col>
                    <Paragraph>Scorecard</Paragraph>
                  </Col>
                </Row>
                <Row style={{ margin: '20px 20px' }}>
                  <Col>
                    <Title level={4} style={{ color: '#5E5E5E' }} >Nombre de la Plantilla</Title>
                  </Col>
                  <Col>
                    <Input onChange={handleCardName} placeholder='Ingresa el nombre' size='large' />
                  </Col>
                </Row>
                <Row className="formNewQuestions">
                  <Col style={{ marginRight: '40px' }}>
                    <Title level={4} style={{ color: '#5E5E5E' }} >
                      {showEdit ? 'Editar habilidad' : 'Habilidad'}
                      <Popover placement="topRight" content="Podrás evaluar las habilidades de tus candidatos mediante 5 estrellas">
                        <Icon style={{ marginLeft: '5px' }} type='info-circle' />
                      </Popover>
                    </Title>
                    <Input name='text' value={inputQuestion.text} onChange={handleInput} size='large' placeholder='Ingresa la habilidad a evaluar en tus candidatos' />
                  </Col>
                  <Col>
                    <Title level={4} style={{ color: '#5E5E5E' }} >Acciones</Title>
                    <Button icon='delete' onClick={() => cleanInput()} style={{ border: 'none', color: '#A4A4A4', boxShadow: 'none' }} />
                  </Col>
                </Row>
                <Row type="flex" align="middle" style={{ margin: '20px 20px' }}>
                  <Col>
                    {
                      showEdit ?
                        (
                          <Button onClick={() => editConfirm()} icon='plus-circle' style={{ color: '#F175A5', border: 'none', boxShadow: 'none' }} >
                            Editar Habilidad
                          </Button>
                        ) :
                        (
                          <Button onClick={() => addQuestion()} icon='plus-circle' style={{ color: '#F175A5', border: 'none', boxShadow: 'none' }} >
                            Agregar Habilidad
                          </Button>
                        )
                    }
                  </Col>
                </Row>
                <CardList list={cardkit.questions} fnDelete={setShowDelete} fncopy={copyQuestion} fnEdit={clickEdit} setSpecificId={setSpecificId} />
                {/* {cardkit.questions.length > 0 && (
                  cardkit.questions.map((item, index) => (
                    <Row key={index} type="flex" justify="space-between" style={{ margin: '10px 20px' }}>
                      <Col style={{}}>
                        <Title level={4} style={{ color: '#5E5E5E' }} >Habilidad</Title>
                        <Input value={item.text} disabled size='large' />
                      </Col>
                      <Col>
                        <Title level={4}>Acciónes</Title>
                        <Button icon='delete' onClick={() => setShowDelete({ state: true, idQuestion: item.question_number })} size='large' />
                        <Button icon='copy' onClick={() => copyQuestion(index)} size='large' />
                      </Col>
                    </Row>
                  ))
                )} */}
              </TabPane>
            </Tabs>
          </div>
        </Row>

        <Row className="actionsNewInterview">
          {
            showEdit ?
              (
                <Col style={{ marginRight: '20px' }}> <Button onClick={() => cancelEdit()}>Cancelar edicion</Button> </Col>
              ) :
              (
                <Col style={{ marginRight: '20px' }}> <Button onClick={() => cancelButton()}>Cancelar</Button> </Col>
              )
          }
          {!showEdit && (<Col> <Button htmlType="submit" style={{ background: '#F175A5', color: 'white' }}>Crear Plantilla</Button> </Col>)}
        </Row>
      </Form>
      <ModalDelete showDelete={showDelete} cancelDelete={cancelDelete} confirmDelete={confirmDelete} type={'habilidad'} />
    </Layout>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch
  };
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    token: state.auth.token,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewScorecard);