import React, { Fragment, useState, useEffect, useContext } from "react";
import { Col, Row, Typography, Layout } from "antd";
import './CandidatePath.scss'
import ActivityPath from "./components/ActivityPath";
import i18n from "../../i18n";

function CandidatePath({ src, pathItems, items, businessSlug, jobApplication, stages, preview, colorTheme, percentStageCompleted }) {
  const [stagesInPath, setStagesInPath] = useState({})

  const groupBy = (xs, key) => {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  useEffect(() => {
    // Preview view in create/edit job app
    // Here we don't have the path_position but the stage
    let newItems = pathItems;
    if (preview == true) {
      newItems = structuredClone(pathItems);
      newItems.forEach((item) => {
        if (item.path_position == undefined && preview == true) {
          item.path_position = stages.map((stage) => stage.id).indexOf(item.stage)
        }
        if (item.code == "KO" && item.path_position == 0) {
          item.path_position = -1;
        }
        if (item.code == "FORM") {
          item.path_position = -1;
        }
      });
    }
    const goupedItems = groupBy(Object.values(newItems), "path_position")
    Object.keys(goupedItems).forEach(key => {
      goupedItems[key] = goupedItems[key].sort((a, b) => {
        // CUTEs always should be the last value
        if (a.code === 'CUTE') {
          return 1;
        }
        // FORM 1st
        else if (a.code === 'FORM') {
          return -1;
        }
        else {
          -1
        }
      });
    });
    setStagesInPath(goupedItems)
  }, [pathItems]);

  const getBlockedLevel = (currentLevel) => {
    const levels = Object.keys(stagesInPath);
    // Find at least one level that is not completed and is after the current level
    const blockedLevel = levels.find(tempLevel => {
      if (percentStageCompleted) {
        return percentStageCompleted(tempLevel) != 100 && tempLevel < currentLevel
      }
      return false;
    }
    );
    return blockedLevel ? true : false;
  };


  return (
    <Layout className='candidate-path-layout'>
      <Row
        type="flex"
        justify="center"
        className="cp-headline"
        style={{ paddingTop: preview ? 24 : 0 }}
      >
        <Col sm={24}>
          <img
            id="businessLogo"
            src={src}
            alt="Business Logo"
            style={{ height: "45px" }}
          />
        </Col>
        <Col sm={24}>
          <Typography.Title level={4}>
            {i18n.t('candidate_path__process_for')}
            {
              <strong
                style={
                  ["banco-internacional"].includes(businessSlug)
                    ? { color: "blue" }
                    : { color: "#FF148C" }
                }
              >
                {" "}
                {jobApplication.name}
              </strong>
            }
          </Typography.Title>
        </Col>

        {Object.keys(stagesInPath)
          .sort((a, b) => {
            // ACTIV always should be the first value
            if (a === "ACTIV") {
              return -1;
            }
            if (b === "ACTIV") {
              return 1;
            } else {
              return a.localeCompare(b);
            }
          })
          .map((level, i) => {
            return (
              <ActivityPath
                key={i}
                percentage={0}
                businessSlug={businessSlug}
                colorTheme={colorTheme}
                level={level}
                activities={stagesInPath[level]}
                items={pathItems}
                prvw={preview}
                blocked={getBlockedLevel(level)}
              />
            );
          })}
      </Row>
    </Layout>
  );
}

export default CandidatePath;
