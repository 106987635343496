import React, { useContext } from 'react';
import { Divider, Modal, Row, Col, Typography, Tag, Checkbox } from 'antd';
import MailTemplatesContext from '../context/mailTemplates';

import '../template-mails.scss';

const { Text, Title } = Typography;

const ShareModal = () => {
  const { shareModal } = useContext(MailTemplatesContext);

  const {
    openShareModal,
    setOpenShareModal,
    templateToPreview,
    colaboratorsToShare,
    setArrayToShare,
    arrayToShare,
    shareTemplatesFn,
    businessName,
  } = shareModal;

  const onChange = (checkedValues) => {
    setArrayToShare(checkedValues);
  };

  const onCheckAllChange = (e) => {
    const allIdsToShare = colaboratorsToShare
      .filter(
        ({ business_user, status }) =>
          business_user.name !== businessName && status === 'ACT'
      )
      .map(({ business_user }) => business_user.id);

    e.target.checked ? setArrayToShare(allIdsToShare) : setArrayToShare([]);
  };

  return (
    <Modal
      title='Compartir plantilla'
      visible={openShareModal}
      width='56.188rem'
      okText='Compartir'
      cancelText='Cancelar'
      centered={true}
      onOk={() => shareTemplatesFn(templateToPreview.id)}
      onCancel={() => setOpenShareModal(false)}
    >
      <Row>
        <Row className='template-container__modal-preview-row'>
          <Col>
            <Text strong>Nombre de la plantilla</Text>
          </Col>
          <Col>
            <Title level={4}>{templateToPreview.name}</Title>
          </Col>
        </Row>
        <Row className='template-container__modal-preview-row'>
          <Checkbox onChange={onCheckAllChange}>Seleccionar todos</Checkbox>
          <Divider />
          <Checkbox.Group
            style={{ width: '100%' }}
            onChange={onChange}
            value={arrayToShare}
          >
            <Row>
              {colaboratorsToShare
                .filter(
                  ({ business_user, status }) =>
                    business_user.name !== businessName && status === 'ACT'
                )
                .map(({ business_user }) => (
                  <Row
                    key={business_user.id}
                    type='flex'
                    align='middle'
                    style={{ marginBottom: '10px' }}
                  >
                    <Col span={1}>
                      <Checkbox value={business_user.id}></Checkbox>
                    </Col>
                    <Col span={14}>
                      <Row>
                        <Text>{business_user.name}</Text>
                      </Row>
                      <Row>
                        <Text type='secondary'>{business_user.email}</Text>
                      </Row>
                    </Col>
                  </Row>
                ))}
            </Row>
          </Checkbox.Group>
        </Row>
      </Row>
    </Modal>
  );
};

export default ShareModal;
