export const questionIcon = {
  'singleline': 'minus',
  'multiline': 'align-left',
  'int': 'calculator',
  'singleselection': 'check',
  'multiselection': 'unordered-list' ,
  'file': 'paper-clip',
  'availability': 'clock-circle'
};

export const questionDescription = {
  'singleline': 'Texto corto (200 caracteres)',
  'multiline': 'Texto largo (1500 caracteres)',
  'int':'Numérico',
  'singleselection': 'Selección única',
  'multiselection': 'Selección múltiple',
  'file': 'Documento adjunto',
  'availability': 'Disponibilidad horaria'
}