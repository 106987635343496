import React, { useContext } from 'react';
import MailTemplatesContext from './context/mailTemplates';
import {
  Icon,
  Row
} from 'antd';


const tagsToMap = [
  { color: '#FA8C16' },
  { color: '#EB2F96' },
  { color: '#13C2C2' },
  { color: '#1890FF' },
  { color: '#5E5E5E' },
];

const DragAndDropTab = () => {
  const { filterByCategory, categoriesTabs, extraCategory, setExtraCategory, tableTemplates } =
    useContext(MailTemplatesContext);

  const { activeCol, selectedStyle, isActive, categories } = categoriesTabs;

  const getItemStyle = (color, isActive, item) => ({
    userSelect: 'none',
    background: isActive === item && `${color}20`,
    width: '35%',
    borderRight: '1px solid #d9d9d9',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color,
  });

  const categoriesToMap = [...categories].slice(0, 4);

  return (
    <>
      <div
        style={{
          display: 'flex',
          height: '4.063rem',
          borderBottom: '1px solid #d9d9d9',
          overflow: 'auto',
          cursor: 'pointer',
        }}
      >
        <div
          id='all-categories'
          style={getItemStyle(tagsToMap[4]?.color, isActive.id, 'all-categories')}
          onClick={(e) => {
            activeCol(e);
            filterByCategory('all-categories');
            setExtraCategory({ id: undefined, name: undefined });
          }}
        >
          <div style={{ ...selectedStyle(tagsToMap[4]?.color, isActive.id === 'all-categories'), height: '0px' }} />
          <span style={{ textAlign: 'center' }}>Todas las categorías</span>
          <div style={selectedStyle(tagsToMap[4]?.color, isActive.id === 'all-categories')} />
        </div>
        {extraCategory.name ? (
          <div
            id={extraCategory.id}
            style={getItemStyle(tagsToMap[4]?.color, isActive.id, extraCategory.id)}
            onClick={(e) => {
              activeCol(e);
              filterByCategory(null);
            }}
          >
            <div style={{ ...selectedStyle(tagsToMap[4]?.color, isActive.id === extraCategory.id), height: '0px' }} />
            <Row type='flex' justify='space-around' align='middle'>
              <span style={{ textAlign: 'center' }}>{extraCategory.name}</span>
              {
                (isActive.id === extraCategory.id) &&
                <Icon type='delete'
                  onClick={(e) => {
                    e.stopPropagation()
                    tableTemplates.deleteAndUpdateCategories(extraCategory)
                    activeCol({ currentTarget: { id: 'all-categories' } });
                    filterByCategory('all-categories');
                    setExtraCategory({ id: undefined, name: undefined });
                  }}
                />
              }
            </Row>
            <div style={selectedStyle(tagsToMap[4]?.color, isActive.id === extraCategory.id)} />
          </div>
        ) : (
          <div
            id={'null-categories'}
            style={getItemStyle(tagsToMap[4]?.color, isActive.id, 'null-categories')}
            onClick={(e) => {
              activeCol(e);
              filterByCategory(null);
              setExtraCategory({ id: undefined, name: undefined });
            }}
          >
            <div style={{ ...selectedStyle(tagsToMap[4]?.color, isActive.id === 'null-categories'), height: '0px' }} />
            <span style={{ textAlign: 'center' }}>Sin categoría</span>
            <div style={selectedStyle(tagsToMap[4]?.color, isActive.id === 'null-categories')} />
          </div>
        )}

        {categoriesToMap.map((item, index) => (
          <div
            key={item.id}
            id={item.id}
            style={getItemStyle(tagsToMap[index]?.color, isActive.id, item.id)}
            onClick={(e) => {
              activeCol(e);
              filterByCategory(item.id);
              setExtraCategory({ id: undefined, name: undefined });
            }}
          >
            <div style={{ ...selectedStyle(tagsToMap[index]?.color, isActive.id === item.id), height: '0px' }} />
            <Row type='flex' justify='space-around' align='middle'>
              <span style={{ textAlign: 'center' }}>{item.name}</span>
            </Row>
            <div style={selectedStyle(tagsToMap[index]?.color, isActive.id === item.id)} />
          </div>
        ))}

        <div
          id='shared-categories'
          style={getItemStyle(tagsToMap[4]?.color, isActive.id, 'shared-categories')}
          onClick={(e) => {
            activeCol(e);
            filterByCategory('shared-categories');
            setExtraCategory({ id: undefined, name: undefined });
          }}
        >
          <div style={{ ...selectedStyle(tagsToMap[4]?.color, isActive.id === 'shared-categories'), height: '0px' }} />
          <span style={{ textAlign: 'center' }}>Plantillas compartidas</span>
          <div style={selectedStyle(tagsToMap[4]?.color, isActive.id === 'shared-categories')} />
        </div>
      </div>
    </>
  );
};

export default DragAndDropTab;
