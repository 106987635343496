import React, { useState, useMemo, useEffect } from 'react';
import './styles.scss';
import {
  Row,
  Col,
  Button,
  Select,
  Calendar,
  Badge,
  Breadcrumb,
  Typography,
  Card,
  Divider,
  Icon,
  Tooltip,
  Modal,
} from 'antd';

import moment from 'moment';
import queryString from 'query-string';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../actions/data';

import InterviewModal from './components/InterviewModal';
import AssistantBadge from './components/AssistantBadge';

const { Option } = Select;
const { Text, Paragraph } = Typography;

const types = {
  ONSITE: 'En persona',
};

const MonthDisplay = ({ actions, auth, history }) => {
  const [interviews, setInterviews] = useState({});
  const [selectedDate, setSelectedDate] = useState(
    moment().format('DD-MM-YYYY')
  );
  const [modalInformation, setModalInformation] = useState(null);

  function getListData(value) {
    const dayInterviews = interviews[value.format('DD-MM-YYYY')] ?? [];

    return dayInterviews.map((interview) => ({
      type: 'success',
      content: interview.title,
    }));
  }

  function dateCellRender(value) {
    const listData = getListData(value);
    return (
      <ul>
        {listData.map((item, idx) => (
          <li key={idx}>
            <Badge status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    );
  }

  const getInterviews = (date) => {
    const execute = (key) => date.clone()[key]('month').format('DD-MM-YYYY');

    const [start, end] = [execute('startOf'), execute('endOf')];

    actions
      .fetchAndReturn(
        auth.token,
        `/api/v1/evaluations/interviews/?date_range=${start},${end}`,
        'GET'
      )
      .then(({ body: interviews }) => {
        // ORDERING INTERVIEWS HERE WE SKIP FILTERS IN getListData FUNCTION
        const interviewsObject = interviews.reduce((acc, interview) => {
          // getting local dates
          const [localStart, localEnd] = [
            interview.interview_date,
            interview.interview_end_date,
          ].map((date) => moment(date, 'DD-MM-YYYY HH:mm Zz').local());

          const newInterview = {
            ...interview,
            localStart,
            localEnd,
          };

          const key = localStart.format('DD-MM-YYYY');

          if (acc[key]) {
            acc[key].push(newInterview);
          } else {
            acc[key] = [newInterview];
          }

          return acc;
        }, {});

        setInterviews(interviewsObject);
      });
  };

  const openInterview = (interview) => {
    history.push(
      `?jobapp_id=${interview.job_application.id}&interview_id=${interview.id}`
    );
    setModalInformation({
      jobapp_id: interview.job_application.id,
      interview_id: interview.id,
    });
  };

  useEffect(() => {
    getInterviews(moment());
    const query = queryString.parse(window.location.search);
    const [first, second] = Object.keys(query);

    if (first === 'interview_id' && second === 'jobapp_id') {
      setModalInformation(query);
    }
  }, []);

  return (
    <Row className="client-calendar" gutter={[16, 16]}>
      <Col xs={24}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Calendario de reuniones</Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      <Col xs={24}>
        <Button
          icon="arrow-left"
          type="link"
          onClick={history.goBack}
          style={{ color: ' #5E5E5E', fontSize: 18, fontWeight: 700 }}
        >
          Agenda de entrevistas
        </Button>
      </Col>
      <Col sm={24} lg={16}>
        <Calendar
          mode="month"
          validRange={[moment().startOf('month'), moment().add(6, 'years')]}
          dateCellRender={dateCellRender}
          onPanelChange={getInterviews}
          onSelect={(date) => setSelectedDate(date.format('DD-MM-YYYY'))}
        />
      </Col>
      <Col sm={10} lg={8} className="client-calendar__right">
        <Card
          title={
            <Text strong>
              Entrevistas de
              {selectedDate === moment().format('DD-MM-YYYY')
                ? ' hoy'
                : `l ${selectedDate}`}
            </Text>
          }
          style={{ width: '100%', border: '1px solid #D9D9D9' }}
        >
          {interviews[selectedDate] ? (
            interviews[selectedDate]
              .sort((a, b) => a.localStart - b.localStart)
              .map((interview, i, array) => (
                <span key={i}>
                  <Row
                    type="flex"
                    gutter={[16, 0]}
                    className="calendar-interview"
                    onClick={() => openInterview(interview)}
                  >
                    <Col xs={12}>
                      <Text strong>{interview.title}</Text>
                    </Col>
                    <Col xs={12} className="calendar-interview__left">
                      <Text style={{ alignText: 'right' }}>
                        {interview.localStart.format('HH:mm')} -{' '}
                        {interview.localEnd.format('HH:mm')}
                      </Text>
                    </Col>
                    <Col xs={12}>
                      <Text strong style={{ color: '#597EF7' }}>
                        {interview.job_application.job_application}
                      </Text>
                    </Col>
                    <Col xs={12} className="calendar-interview__left">
                      <Text style={{ color: '#454545' }}>
                        {types[interview.interview_type]}
                      </Text>
                    </Col>
                    <Col xs={24}>
                      <div className="calendar-interview__assistants">
                        {interview.personal_interviews.map(
                          (personalInt, idx) => (
                            <AssistantBadge
                              assistant={personalInt}
                              key={idx}
                              type="candidate"
                            />
                          )
                        )}
                        <AssistantBadge
                          assistant={interview.creator}
                          type="creator"
                        />
                        <div className="assistant-badge assistant-many-left">
                          <Text>+{interview.business_users.length}</Text>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {i < array.length - 1 ? (
                    <Divider style={{ margin: '10px 0' }} />
                  ) : null}
                </span>
              ))
          ) : (
            <Text>No hay entrevistas para mostrar.</Text>
          )}
        </Card>
      </Col>
      <InterviewModal
        onCancel={() => setModalInformation(null)}
        visible={!!modalInformation}
        interviewId={modalInformation?.interview_id}
        refetch={() => getInterviews(moment())}
      />
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MonthDisplay);
