import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import JobUrl from './components/JobUrl';
import ShareJob from './components/ShareJob';
import PostJobOnLaborum from './components/trabajando/Trabajando';
import PostJobOnFreePortal from './components/freeJobPortal';
import PostJobOnPaidPortal from './components/paidJobPortal';
import RightSide from './rightside/RightSide';
import { 
  Row,
  Col,
  Divider,
  Typography
} from 'antd';
import './PublishJob.scss';
import GetOnBoardJobForm from './components/getonbrd/Getonbrd';

const { Title, Paragraph } = Typography;

class PublishJob extends Component {
  render() {
    const { business, businessOwner, jobappid, processType, jobapp, jobPortalIntegrations, publish } = this.props;
    const SHARE_URL = process.env.REACT_APP_SHARE_URL.includes('app.genoma.work') ? 
            'https://jobs.genoma.work' : process.env.REACT_APP_SHARE_URL;
    const businessSlug = business ? business.slug : businessOwner;
    const shareProcessUrl = `${SHARE_URL}/${businessSlug}/${jobappid}/`

    return (
      <Row className='pj'>
        <Col xs={24} lg={24}>
          <JobUrl url={shareProcessUrl} />
          {processType !== 'Interno' && (
            <Fragment>
              <Row type='flex' gutter={[12, 30]} style={{ marginTop: '24px' }}>
                <Col sm={24}>
                  <Title level={2}>
                    Portales gratuitos
                  </Title>
                  <Row type='flex' gutter={[12,12]}>
                    <Col sm={12} md={8}>
                      <PostJobOnFreePortal 
                        provider='googleforjobs'
                        providerName='Google for jobs'
                        modalContent={
                          <>
                            <Title level={3}>Publicar empleo en Google for Jobs</Title>
                            <Paragraph>
                              Desde ahora, tus ofertas laborales podrán ser visibles desde Google 🎉.
                            </Paragraph>
                            <Paragraph>
                              Al aceptar, la información del proceso será publicada en el motor
                              de búsqueda de Google.
                              Por favor verificar la <strong>fecha de cierre</strong> del proceso, 
                              ya que esta indicará la fecha de expiración de la publicación en Google.
                            </Paragraph>
                          </>
                        }
                        jobapp={jobapp}
                        publish={publish}
                        handleChange={this.props.handleChange}
                        jobPortalIntegrations={jobPortalIntegrations}
                      />
                    </Col>
                    <Col sm={12} md={8}>
                      <PostJobOnFreePortal
                        provider='trovit'
                        providerName='Trovit'
                        modalContent={
                          <>
                            <Title level={3}>Publicar empleo en Trovit/Mitula</Title>
                            <Paragraph>
                              Desde ahora, tus ofertas laborales podrán ser visibles desde Trovit y Mitula 🎉.
                            </Paragraph>
                            <Paragraph>
                              Al aceptar, la información del proceso será publicada en el motor
                              de búsqueda de Trovit y Mitula.
                              Por favor verificar la <strong>fecha de cierre</strong> del proceso, 
                              ya que esta indicará la fecha de expiración de la publicación en Trovit y Mitula.
                            </Paragraph>
                          </>
                        }
                        jobapp={jobapp}
                        publish={publish}
                        handleChange={this.props.handleChange}
                        jobPortalIntegrations={jobPortalIntegrations}
                        countries={['CL', 'CO', 'PE', 'MX', 'AR', 'UY']}
                      />   
                    </Col>
                    <Col sm={12} md={8}>
                      <PostJobOnFreePortal 
                        provider='opcionempleo'
                        providerName='Opción Empleo'
                        modalContent={
                          <>
                            <Title level={3}>Publicar empleo en Opción Empleo</Title>
                            <Paragraph>
                              Desde ahora, tus ofertas laborales podrán ser visibles desde Opción Empleo 🎉.
                            </Paragraph>
                            <Paragraph>
                              Al aceptar, la información del proceso será publicada en el motor
                              de búsqueda de Opción Empleo.
                              Por favor verificar la <strong>fecha de cierre</strong> del proceso, 
                              ya que esta indicará la fecha de expiración de la publicación en Indeed.
                            </Paragraph>
                          </>
                        }
                        jobapp={jobapp}
                        publish={publish}
                        handleChange={this.props.handleChange}
                        jobPortalIntegrations={jobPortalIntegrations}
                        countries={['CL', 'CO', 'PE', 'BO', 'AR', 'EC', 'MX', 'PA', 'PY', 'UY']}
                      />
                    </Col>
                    <Col sm={12} md={8}>
                      <PostJobOnFreePortal 
                        provider='jooble'
                        providerName='Jooble'
                        modalContent={
                          <>
                            <Title level={3}>Publicar empleo en Jooble</Title>
                            <Paragraph>
                              Desde ahora, tus ofertas laborales podrán ser visibles desde Jooble 🎉.
                            </Paragraph>
                            <Paragraph>
                              Al aceptar, la información del proceso será publicada en el motor
                              de búsqueda de Jooble.
                              Por favor verificar la <strong>fecha de cierre</strong> del proceso, 
                              ya que esta indicará la fecha de expiración de la publicación en Jooble.
                            </Paragraph>
                          </>
                        }
                        jobapp={jobapp}
                        publish={publish}
                        handleChange={this.props.handleChange}
                        jobPortalIntegrations={jobPortalIntegrations}
                        countries={['BR', 'CL', 'CO', 'PE', 'MX', 'AR', 'EC']}
                      />
                    </Col>
                    <Col sm={12} md={8}>
                      <PostJobOnFreePortal 
                        provider='buscojobs'
                        providerName='Buscojobs'
                        modalContent={
                          <>
                          <Title level={3}>Publicar empleo en BuscoJobs</Title>
                          <Paragraph>
                            Desde ahora, tus ofertas laborales podrán ser visibles desde BuscoJobs 🎉.
                          </Paragraph>
                          <Paragraph>
                            Al aceptar, la información del proceso será publicada en la búsqueda de empleos de BuscoJobs
                            Por favor verificar la <strong>fecha de cierre</strong> del proceso, 
                            ya que esta indicará la fecha de expiración de la publicación en BuscoJobs.
                          </Paragraph>
                        </>
                        }
                        jobapp={jobapp}
                        publish={publish}
                        handleChange={this.props.handleChange}
                        jobPortalIntegrations={jobPortalIntegrations}
                        countries={['AR', 'BO', 'BR', 'CL', 'CO', 'PA', 'PE', 'MX', 'EC', 'VE']}
                      />
                    </Col>
                    <Col sm={12} md={8}>
                      <PostJobOnFreePortal 
                        provider='talentcom'
                        providerName='Talent.com'
                        modalContent={
                          <>
                          <Title level={3}>Publicar empleo en Talent.com</Title>
                          <Paragraph>
                            Desde ahora, tus ofertas laborales podrán ser visibles desde Talent.com 🎉.
                          </Paragraph>
                          <Paragraph>
                            Al aceptar, la información del proceso será publicada en el portal Talent.com. 
                            Por favor verificar la <strong>fecha de cierre</strong> del proceso, 
                            ya que esta indicará la fecha de expiración de la publicación en Talent.
                          </Paragraph>
                        </>
                        }
                        jobapp={jobapp}
                        publish={publish}
                        handleChange={this.props.handleChange}
                        jobPortalIntegrations={jobPortalIntegrations}
                        countries={['BR', 'CL', 'CO', 'PE', 'MX', 'AR', 'EC']}
                      />
                    </Col>
                    {/* LinkedIn not available YET */}
                    { (jobPortalIntegrations.linkedin && jobPortalIntegrations.linkedin.companyId)  ? <Col sm={12} md={8}>
                      <PostJobOnFreePortal 
                        provider='linkedin'
                        providerName='LinkedIn'
                        modalContent={
                          <>
                            <Title level={3}>Publicar empleo en LinkedIn</Title>
                            <Paragraph>
                              Desde ahora, tus ofertas laborales podrán ser visibles desde LinkedIn 🎉.
                            </Paragraph>
                            <Paragraph>
                              Al aceptar, la información del proceso será publicada en la búsqueda de empleos de LinkedIn
                              (Job Listings). El empleo será asociado al perfil de LinkedIn de la empresa. <br/>
                              Por favor verificar la <strong>fecha de cierre</strong> del proceso, 
                              ya que esta indicará la fecha de expiración de la publicación en LinkedIn.
                            </Paragraph>
                          </>
                        }
                        jobapp={jobapp}
                        publish={publish}
                        handleChange={this.props.handleChange}
                        jobPortalIntegrations={jobPortalIntegrations}
                      />
                    </Col> : null}
                  </Row>
                </Col>

                <Col sm={24} md={24} lg={24}>
                  <Title level={2}>
                    Portales Pagados
                  </Title>
                  <Row type='flex' gutter={[12,30]}>
                    <Col sm={12} md={8}>
                      {jobPortalIntegrations.trabajando && <PostJobOnLaborum shareProcessUrl={shareProcessUrl}
                        jobapp={jobapp}
                        publish={publish}
                        handleChange={this.props.handleChange}
                        jobPortalIntegrations={jobPortalIntegrations}
                      />}
                    </Col>
                    <Col sm={12} md={8}>
                      <PostJobOnPaidPortal 
                        provider='getonbrd'
                        providerName='GetOnBoard'
                        ModalContent={
                          GetOnBoardJobForm
                        }
                        logo="https://d2dgum4gsvdsrq.cloudfront.net/assets/icon-new-c18debbaa69bac8df6158426f4a00752b32a7fba603cba4eeb3e4572466344a6.png"
                        jobapp={jobapp}
                        publish={publish}
                        handleChange={this.props.handleChange}
                        jobPortalIntegrations={jobPortalIntegrations}
                        countries={['BR', 'CL', 'CO', 'PE', 'MX', 'AR', 'EC']}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <ShareJob shareProcessUrl={shareProcessUrl} />
            </Fragment>
          )}
          <Divider />
          <RightSide
            currentCustomTests={this.props.currentCustomTests}
          />
        </Col>
      </Row>
    )
  }
};

const mapStateToProps = (state) => {
  return {
    business: state.auth.business,
    businessOwner: state.auth.businessOwner,
    jobPortalIntegrations: state.auth.jobPortalIntegrations
  };
};

export default connect(mapStateToProps)(PublishJob);