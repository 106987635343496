import {
  Card,
  Checkbox,
  DatePicker,
  Icon,
  Input,
  InputNumber,
  Radio,
  Select,
  Tooltip,
  Typography,
} from "antd";
import moment from "moment";

import { careerAreaOptions, careerStateOptions, careerTypeOptions } from "../../../constants/careers";
import i18n from "../../../i18n";
import DebouncedSelect from "../../../components/input/DebouncedSelect";

const { OptGroup, Option } = Select;
const { Paragraph, Text } = Typography;

const today = new Date();

export const careerFields = {
  career_name: {
    input: (setFieldsValue, value) => (
      <DebouncedSelect
        fieldName="career_name"
        placeholder={i18n.t('form__select__career')}
        searchApiUrl={`/api/v1/accounts/careers/?language=${i18n.language.split('-')[0]}`}
        setValue={setFieldsValue}
        value={value}
      />
    ),
    label: i18n.t('profile__career'),
    required: true,
    span: 12,
  },
  career_institution: {
    input: (setFieldsValue, value) => (
      <DebouncedSelect
        fieldName="career_institution"
        placeholder={i18n.t('form__select__university')}
        searchApiUrl={`/api/v1/accounts/universities/?language=${i18n.language.split('-')[0]}`}
        setValue={setFieldsValue}
        value={value}
      />
    ),
    label: i18n.t('profile__institution'),
    required: true,
    span: 12,
  },
  career_area: {
    input: () => (
      <Select
        allowClear
        notFoundContent={i18n.t('commons__no__results')}
        optionFilterProp='children'
        placeholder={i18n.t('form__area')}
        showSearch
        style={{ width: '100%' }}
      >
        {careerAreaOptions.map(({ groupName, groupLabels }) => (
          <OptGroup key={groupName} label={groupName}>
            {groupLabels.map((item, index) => (
              <Option key={index + item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </OptGroup>
        ))}
      </Select>
    ),
    label: i18n.t('form__area'),
    required: true,
    span: 12,
  },
  career_state: {
    input: () => (
      <Select
        allowClear
        placeholder={i18n.t('commons__status')}
        style={{ width: '100%' }}
      >
        {careerStateOptions.map((opt) => (
          <Option key={opt.value}>
            {opt.label}
          </Option>
        ))}
      </Select>
    ),
    label: i18n.t('commons__status'),
    required: true,
    span: 12,
  },
  career_entry_year: {
    label: i18n.t('form__start__day'),
    input: () => (
      <Select
        allowClear
        placeholder={i18n.t('commons__year')}
        style={{ width: '100%' }}
      >
        {yearsOptions.slice(0, (new Date().getFullYear()) - 1920 + 1).reverse().map(
          (opt, index) => <Option value={opt} key={index + opt}>{opt}</Option>)}
      </Select>
    ),
    required: true,
    rules: (getFieldValue, validateFields) => ([
      {
        validator: (rule, value, callback) => {
          validateFields(['career_last_year'], { force: true });
          callback();
        },
      }
    ]),
    span: 12,
  },
  career_last_year: {
    input: () => (
      <Select
        allowClear
        placeholder={i18n.t('commons__year')}
        style={{ width: '100%' }}
      >
        {yearsOptions.slice(0, (new Date().getFullYear()) - 1910 + 1).reverse().map(
          (opt, index) => <Option value={opt} key={index + opt}>{opt}</Option>)}
      </Select>
    ),
    label: i18n.t('form__end__day'),
    required: false,
    rules: (getFieldValue) => ([
      {
        validator: (rule, value, callback) => {
          const entryYear = parseInt(getFieldValue('career_entry_year'));
          if (value && entryYear && parseInt(value) < entryYear) {
            callback(i18n.t('form__career_last_year_error'));
          } else {
            callback();
          }
        },
        type: 'number',
      }
    ]),
    span: 12,
  },
  career_type: {
    input: () => (
      <Radio.Group>
        {careerTypeOptions.map((opt) => (
          <Radio value={opt.value} key={opt.value}>{opt.label}</Radio>
        ))}
      </Radio.Group>
    ),
    label: i18n.t('form__type_of_career'),
    required: true,
    span: 24,
  },
}

export const certificationFields = {
  name: {
    input: (setFieldsValue, value) => (
      <Input allowClear placeholder={i18n.t('form__enter_certification')} />
    ),
    label: i18n.t('form__certification_name'),
    required: true,
    span: 12,
  },
  institution: {
    input: (setFieldsValue, value) => (
      <Input allowClear placeholder={i18n.t('form__enter_institution')} />
    ),
    label: i18n.t('profile__institution'),
    required: true,
    span: 12,
  },
  area: {
    input: () => (
      <Select
        allowClear
        notFoundContent={i18n.t('commons__no__results')}
        optionFilterProp='children'
        placeholder={i18n.t('form__area')}
        showSearch
        style={{ width: '100%' }}
      >
        {careerAreaOptions.map(({ groupName, groupLabels }) => (
          <OptGroup key={groupName} label={groupName}>
            {groupLabels.map((item, index) => (
              <Option key={index + item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </OptGroup>
        ))}
      </Select>
    ),
    label: i18n.t('form__area'),
    required: false,
    span: 12,
  },
  length: {
    input: () => (
      <InputNumber
        allowClear
        placeholder={i18n.t('commons__duration')}
        style={{ borderRadius: '2px', marginTop: '4px', width: '100%' }}
      />
    ),
    label: i18n.t('commons__duration'),
    required: false,
    rules: (getFieldValue, validateFields) => ([
      {
        message: i18n.t('form__certification_duration__invalid_value'),
        pattern: /^0*?[1-9]\d*$/,
      },
      {
        validator: (rule, value, callback) => {
          if (value !== 0 && !value && getFieldValue('length_unit')) {
            callback(i18n.t('form__certification_duration__error'));
          } else if (value && !getFieldValue('length_unit')) {
            validateFields(['length_unit'], { force: true });
          } else {
            validateFields(['length_unit'], { force: true });
            callback();
          }
        },
        type: 'number',
      }
    ]),
    span: 6,
  },
  length_unit: {
    input: () => (
      <Select
        allowClear
        placeholder={i18n.t('commons__unit')}
        style={{ width: '100%' }}
      >
        {lengthUnitOptions.map((opt) => (
          <Option key={opt.value} value={opt.value}>
            {opt.label}
          </Option>
        ))}
      </Select>
    ),
    required: false,
    rules: (getFieldValue, validateFields) => ([
      {
        validator: (rule, value, callback) => {
          if (!value && getFieldValue('length')) {
            callback(i18n.t('form__certification_duration_unit__error'));
          } else if (value && !getFieldValue('length')) {
            validateFields(['length'], { force: true });
          } else {
            validateFields(['length'], { force: true });
            callback();
          }
        }
      }
    ]),
    span: 6,
  },
  start_date: {
    input: () => (
      <DatePicker
        disabledDate={(current) => current && current > moment()}
        placeholder={i18n.t('form__start__day')}
        style={{ width: '100%' }}
      />
    ),
    label: i18n.t('form__start__day'),
    parseBeforeSubmit: (value) => value && value.format('YYYY-MM-DD'),
    required: true,
    rules: (getFieldValue, validateFields) => ([
      {
        validator: (rule, value, callback) => {
          validateFields(['end_date'], { force: true });
          callback();
        },
      }
    ]),
    span: 12,
  },
  end_date: {
    extra: (extraInfo, setFieldsValue) => (
      <Checkbox
        checked={extraInfo.state === 'CUR' || (!extraInfo.end_date || extraInfo.end_date >= today)}
        onChange={(e) => {
          if (e.target.checked) {
            setFieldsValue({ state: 'CUR' })
            if (extraInfo.end_date < today) {
              setFieldsValue({ end_date: null });
            }
          } else {
            setFieldsValue(
              { state: extraInfo.end_date && extraInfo.end_date < today ? 'FIN' : 'OTHER' }
            );
          }
        }}
      >
        {i18n.t('form__status__3')}
      </Checkbox>
    ),
    input: () => (
      <DatePicker
        placeholder={i18n.t('commons__end__date')}
        style={{ width: '100%' }}
      />
    ),
    label: i18n.t('commons__end__date'),
    parseBeforeSubmit: (value) => value && value.format('YYYY-MM-DD'),
    required: false,
    rules: (getFieldValue) => ([
      {
        validator: (rule, value, callback) => {
          const startDate = getFieldValue('start_date');
          if (value && startDate && value < startDate) {
            callback(i18n.t('form__certification_end_date__error'));
          } else {
            callback();
          }
        },
        type: 'number',
      }
    ]),
    span: 12,
  },
  certificate_url: {
    input: () => (
      <Input allowClear placeholder={i18n.t('form__certification_link_placeholder')} />
    ),
    label: i18n.t('form__certification_link'),
    required: false,
    span: 24,
  },
}

export const experienceFields = {
  company: {
    input: () => (
      <Input allowClear placeholder={i18n.t('commons__company')} />
    ),
    label: i18n.t('commons__company'),
    required: true,
    span: 12,
  },
  position: {
    input: () => (
      <Input allowClear placeholder={i18n.t('form__position')} />
    ),
    label: i18n.t('form__position'),
    required: true,
    span: 12,
  },
  month_start: {
    input: () => (
      <Select allowClear placeholder={i18n.t('commons__month')}>
        {monthsOptions.map((month, index) => (
          <Option key={`month-start-${month}`} value={index}>{month}</Option>
        ))}
      </Select>
    ),
    label: i18n.t('form__start__day'),
    parseBeforeSubmit: (value) => {
      const newValue = monthsOptions.indexOf(value);
      return newValue !== -1 ? newValue : value;
    },
    required: true,
    rules: (getFieldValue, validateFields) => ([
      {
        validator: (rule, value, callback) => {
          validateFields(['month_end'], { force: true });
          callback();
        },
      }
    ]),
    span: 6,
  },
  year_start: {
    input: () => (
      <Select allowClear placeholder={i18n.t('commons__year')}>
        {yearsOptions.slice(0, (new Date().getFullYear()) - 1920 + 1).reverse().map(
          (opt, index) => <Option value={opt} key={index + opt}>{opt}</Option>)}
      </Select>
    ),
    required: true,
    rules: (getFieldValue, validateFields) => ([
      {
        validator: (rule, value, callback) => {
          validateFields(['month_end'], { force: true });
          callback();
        },
      }
    ]),
    span: 6,
  },
  month_end: {
    extra: (extraInfo, setFieldsValue) => (
      <Checkbox
        checked={(!extraInfo.month_end && !extraInfo.year_end)}
        className='full-width'
        disabled
      >
        {i18n.t('form__option')}
      </Checkbox>
    ),
    input: () => (
      <Select allowClear placeholder={i18n.t('commons__month')}>
        {monthsOptions.map((month, index) => (
          <Option key={`month-end-${month}`} value={index}>{month}</Option>
        ))}
      </Select>
    ),
    label: i18n.t('form__end__day'),
    parseBeforeSubmit: (value) => {
      const newValue = monthsOptions.indexOf(value);
      return newValue !== -1 ? newValue : value;
    },
    rules: (getFieldValue) => ([
      {
        validator: (rule, value, callback) => {
          const yearStart = getFieldValue('year_start');
          const monthStart = monthsOptions.includes(getFieldValue('month_start'))
            ? monthsOptions.findIndex((elem) => getFieldValue('month_start') === elem)
            : getFieldValue('month_start');
          const startDate = yearStart && moment(`${yearStart}-${parseInt(monthStart)+1}`, 'YYYY-MM');

          const yearEnd = getFieldValue('year_end');
          const monthEnd = monthsOptions.includes(value)
            ? monthsOptions.findIndex((elem) => value === elem)
            : value;
          const endDate = yearEnd && moment(`${yearEnd}-${parseInt(monthEnd)+1}`, 'YYYY-MM');

          if (endDate && startDate && endDate < startDate) {
            callback(i18n.t('form__experience_end_date__error'));
          } else {
            callback();
          }
        },
        type: 'number',
      }
    ]),
    span: 6,
  },
  year_end: {
    input: () => (
      <Select allowClear placeholder={i18n.t('commons__year')}>
        {yearsOptions.slice(0, (new Date().getFullYear()) - 1920 + 1).reverse().map(
          (opt, index) => <Option value={opt} key={index + opt}>{opt}</Option>)}
      </Select>
    ),
    rules: (getFieldValue, validateFields) => ([
      {
        validator: (rule, value, callback) => {
          validateFields(['month_end'], { force: true });
          callback();
        },
      }
    ]),
    span: 6,
  },
  country: {
    input: (setFieldsValue, value, countriesOptions) => (
      <Select
        allowClear
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input?.toLowerCase()) >= 0
        }
        showSearch
      >
        {countriesOptions.map(({ country, translation }) => (
          <Option key={country} value={country}>
            {translation[i18n.language.split('-')[0]]}
          </Option>
        ))}
      </Select>
    ),
    label: i18n.t('commons__country'),
    required: true,
    span: 12,
  },
};

export const disabilityOptions = {
  'NO': i18n.t("profile__disability__no"),
  'YES': i18n.t("profile__disability__yes"),
  'UNKNOWN': i18n.t('profile__disability__would_rather_not_say'),
  'EMPTY': i18n.t('commons__no_data_registered'),
}

export const disabilityTypeOptions = {
  'NONE': i18n.t('commons__no_data_registered'),
  'PHYSICAL': i18n.t('form__physical'),
  'SENSORY': i18n.t('form__sensorial'),
  'INTELECTUAL': i18n.t('form__intellectual'),
  'PSYCHIC': i18n.t('form__psychic'),
  'VISCERAL': i18n.t('form__visceral'),
  'MULTIPLE': i18n.t('form__multiple'),
  'UNKNOWN': i18n.t('profile__disability__would_rather_not_say'),
};

export const educationLevelOptions = {
  'Media/Secundaria': i18n.t('form__academic_level_1'),
  'Técnico/Profesional': i18n.t('form__academic_level_2'),
  'Universitario': i18n.t('form__academic_level_3'),
  'Postgrado': i18n.t('form__academic_level_4'),
  'Magister': i18n.t('form__academic_level_5'),
  'Doctorado': i18n.t('form__academic_level_6'),
}

export const genderOptions = {
  'M': i18n.t('form__male'),
  'F': i18n.t('form__female'),
  'O': i18n.t('form__other'),
  'E': '-',
};

const lengthUnitOptions = [
  { label: i18n.t('commons__hours'), value: 'HRS' },
  { label: i18n.t('commons__weeks'), value: 'WKS' },
  { label: i18n.t('commons__months'), value: 'MTH' },
  { label: i18n.t('commons__semesters'), value: 'SMS'},
  { label: i18n.t('commons__years'), value: 'YRS' },
];

export const maritalStatusOptions = {
  'Casado': i18n.t('profile__married'),
  'Soltero': i18n.t('profile__single'),
  'Divorciado': i18n.t('profile__divorce'),
  'Viudo': i18n.t('form_widow'),
};

export const monthsOptions = [
  i18n.t('form__january'),
  i18n.t('form__february'),
  i18n.t('form__march'),
  i18n.t('form__april'),
  i18n.t('form__may'),
  i18n.t('form__june'),
  i18n.t('form__july'),
  i18n.t('form__august'),
  i18n.t('form__september'),
  i18n.t('form__october'),
  i18n.t('form__november'),
  i18n.t('form__december'),
];

export const personalDataFields = {
  birth_date: {
    label: i18n.t('form__birth_date'),
    input: () => (
      <DatePicker
        disabledDate={(current) => current && current >= moment()}
        style={{ width: '100%' }}
      />
    ),
    span: 12,
  },
  phone_prefix: {
    label: i18n.t('form__phone'),
    input: (countriesOptions) => (
      <Select
        allowClear
        filterOption={(input, option) =>
          option?.props?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
        }
        showSearch
      >
        {countriesOptions?.map(({ phone_code }) => (
          <Option key={phone_code} value={phone_code}>{phone_code}</Option>
        ))}
      </Select>
    ),
    span: 4,
  },
  phone: {
    input: () => <Input allowClear />,
    span: 8,
  },
  nationality: {
    label: i18n.t('form__nationality'),
    input: (countriesOptions) => (
      <Select
        allowClear
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input?.toLowerCase()) >= 0
        }
        showSearch
      >
        {countriesOptions.map(({ country, translation }) => (
          <Option key={country} value={country}>
            {translation[i18n.language.split('-')[0]]}
          </Option>
        ))}
      </Select>
    ),
    span: 12,
  },
  country: {
    label: i18n.t('form__residence_country'),
    input: (countriesOptions) => (
      <Select
        allowClear
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input?.toLowerCase()) >= 0
        }
        showSearch
      >
        {countriesOptions?.map(({ country, translation }) => (
          <Option key={country} value={country}>
            {translation[i18n.language.split('-')[0]]}
          </Option>
        ))}
      </Select>
    ),
    span: 12,
  },
  commune: {
    label: i18n.t('form__commune'),
    input: () => <Input allowClear />,
    span: 12,
  },
  // marital_status: {
  //   label: i18n.t('profile__marital_status'),
  //   input: () => (
  //     <Select allowClear>
  //       {Object.entries(maritalStatusOptions).map((entry) => (
  //         <Option key={entry[0]} value={entry[0]}>
  //           {entry[1]}
  //         </Option>
  //       ))}
  //     </Select>
  //   ),
  //   span: 12,
  // },
  gender: {
    label: i18n.t('form__gender'),
    input: () => (
      <Radio.Group className='gender-btns'>
        {Object.entries(genderOptions).filter((entry) => entry[0] !== 'E').map(
          (entry) => (
            <Radio.Button key={entry[0]} value={entry[0]}>{entry[1]}</Radio.Button>
          ))}
      </Radio.Group>
    ),
    span: 12,
  },
  education_level: {
    label: i18n.t('form__academic_level_modal'),
    input: () => (
      <Select allowClear>
        {Object.entries(educationLevelOptions).map((entry) => (
          <Option key={entry[0]} value={entry[0]}>
            {entry[1]}
          </Option>
        ))}
      </Select>
    ),
    span: 12,
  },
  disability: {
    clear: (setFieldsValue) => (
      <Tooltip title={i18n.t('commons__clear_field')}>
        <Icon
          onClick={() => {
            setFieldsValue({ disability: 'EMPTY', disability_type: 'NONE' });
          }}
          type="close-circle"
        />
      </Tooltip>
    ),
    extra: (
      <Card
        bordered={false}
        className="info-card"
        style={{ backgroundColor: '#D9D9D9' }}
      >
        <Card.Meta
          description={(
            <Paragraph style={{ color: 'black', marginBottom: 0 }}>
              {i18n.t('form__disability_info_text').split('**')[0]} <Text style={{ color: '#F175A5' }}>{i18n.t('form__disability_info_text').split('**')[1]}</Text> {i18n.t('form__disability_info_text').split('**')[2]}
            </Paragraph>
          )}
        />
      </Card>
    ),
    label: i18n.t('form__disability'),
    input: () => (
      <Radio.Group>
        {Object.entries(disabilityOptions).filter((entry) => entry[0] !== 'EMPTY').map((entry) => (
          <Radio key={entry[0]} value={entry[0]}>{entry[1]}</Radio>
        ))}
      </Radio.Group>
    ),
    onChange: (event, setFieldsValue) => {
      if (event.target.value !== 'YES') {
        setFieldsValue({ disability_type: 'NONE' })
      }
    },
    span: 24,
  },
  disability_type: {
    clear: (setFieldsValue) => (
      <Tooltip title={i18n.t('commons__clear_field')}>
        <Icon
          onClick={() => {
            setFieldsValue({ disability_type: 'NONE' });
          }}
          type="close-circle"
        />
      </Tooltip>
    ),
    condition: (candidate) => candidate.disability === 'YES',
    label: i18n.t('form__disability_type'),
    input: () => (
      <Radio.Group>
        {Object.entries(disabilityTypeOptions).filter((entry) => entry[0] !== 'NONE').map((entry) => (
          <Radio key={entry[0]} value={entry[0]}>{entry[1]}</Radio>
        ))}
      </Radio.Group>
    ),
    span: 24,
  },
  disability_visible: {
    input: () => (<Checkbox>{i18n.t('form__disability__availability_text')}</Checkbox>),
    span: 24,
    valuePropName: 'checked',
  }
}

export const skillsFields = {
  skills: {
    input: (setFieldsValue, value) => (
      <DebouncedSelect
        fieldName="skills"
        placeholder={i18n.t('form__skills_and_languages__placeholder')}
        searchApiUrl={`/api/v1/accounts/skills/?language=${i18n.language.split('-')[0]}`}
        setValue={setFieldsValue}
        type="multiple"
        value={value}
      />
    ),
    required: false,
    span: 24,
  },
}

const yearsOptions = [
  ...Array(((new Date().getFullYear()) + 10) - 1920 + 1).keys()
].map(x => x + 1920);
