import React, { useState, useEffect } from 'react'

import {
  Col,
  Row,
} from 'antd'

import './GenomaSteps.scss';

function GenomaSteps({ data, onChange, activeStep, md = 14 }) {

  const [currentStepIdx, setCurrentStepIdx] = useState(0);
  const [totalSteps, setTotalSteps] = useState(0);

  useEffect(() => {
    if (data) {
      setTotalSteps(data.length);
    }
  }, [data]);

  useEffect(() => {
    if (activeStep !== undefined || activeStep !== null) {
      changeStep(activeStep);
    }
  }, [activeStep])

  const buildStep = (stepObject, idx) => {
    const totalSteps = data ? data.length : 0;
    if (totalSteps == 0) {
      return;
    }

    const stepClass = idx == currentStepIdx ? 'genoma-step-active' : 'genoma-step';

    const stepWidth = Math.floor(100 / totalSteps);
    // If labels are not provided, use the index as the label
    const stepContent = stepObject.label ? stepObject.label : idx + 1;
    switch (idx) {
      case 0:
        return (
          <Col key={idx} style={{ width: `${stepWidth}%` }} className='centered-step'>
            <span className='empty-link' />
            <div className={stepClass} onClick={() => changeStep(idx)}>{stepContent}</div>
            <hr className='step-link' />
          </Col>
        );
      case (totalSteps - 1):
        return (
          <Col key={idx} style={{ width: `${stepWidth}%` }} className='centered-step'>
            <hr className='step-link' />
            <div className={stepClass} onClick={() => changeStep(idx)}>{stepContent}</div>
            <span className='empty-link' />
          </Col>
        );
      default:
        return (
          <Col key={idx} style={{ width: `${stepWidth}%` }} className='centered-step'>
            <hr className='step-link' />
            <div className={stepClass} onClick={() => changeStep(idx)}>{stepContent}</div>
            <hr className='step-link' />
          </Col >
        );
    }
  }

  const buildTitleRow = (stepObject, idx) => {

    const totalSteps = data ? data.length : 0;
    if (totalSteps == 0) {
      return
    }
    const stepWidth = Math.floor(100 / totalSteps)
    const stepTitleClass = idx == currentStepIdx ? 'genoma-step-title-active' : 'genoma-step-title';

    return (
      <Col key={idx} style={{ width: `${stepWidth}%` }} className={stepTitleClass}>
        {stepObject.title}
      </Col>
    )
  };

  const changeStep = (newStepidx) => {
    setCurrentStepIdx(newStepidx);
    onChange && onChange(newStepidx);
  };

  return (
    <Row type='flex' justify='center'>
      <Col xs={24} md={md} data-tour="genoma-steps">
        <Row type='flex' justify='center' className='genoma-steps'>
          {
            data.map((item, idx) => buildStep(item, idx))
          }
        </Row >

        <Row type='flex' justify='center' className='genoma-steps'>
          {
            data.map((item, idx) => buildTitleRow(item, idx))
          }
        </Row >
      </Col>

      <Col xs={24}>
        <div className='genoma-step-content'>
          {data[currentStepIdx]?.content}
        </div>
      </Col>
    </Row>
  );
};

export default GenomaSteps;