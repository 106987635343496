//EXTERNAL COMPONENTS
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Breadcrumb,
  Button,
  Col,
  Icon,
  Modal,
  Row,
  Table,
  Tabs,
  Typography,
} from 'antd';
import { Link } from 'react-router-dom';

//INTERNAL COMPONENTS
import * as actionCreators from '../../../actions/data';
import './ApplicationForm.scss';
import ActionBar from './ActionBar';
import ModalEditAndCreate from './ModalEditAndCreate';
import PersonalData from './PersonalData';
import { columnFn } from './Columns';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

function ApplicationForm(props) {
  const [questionKits, setQuestionKits] = useState([]);
  const [allQuestionKits, setAllQuestionKits] = useState([]);
  const [filterQuestion, setFilterQuestion] = useState(false);
  const [isModalCreateVisible, setIsModalCreateVisible] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [isModalAddAlternativeVisible, setIsModalAddAlternativeVisible] =
    useState(false);
  const [keyTab, setKeyTab] = useState('1');
  const [allChecked, setAllChecked] = useState(false);
  const [checked, setChecked] = useState({});
  const [selected, setSelected] = useState(undefined);
  const [tableSelectedRows, setTableSelectedRows] = useState([]);
  const [questionToSend, setQuestionToSend] = useState({
    additionals: {},
    configuration: {
      alternatives: [
        { text: undefined, correct: undefined },
        { text: undefined, correct: undefined },
      ],
    },
    question_type: 'singleline',
    number_from: 0,
    number_to: 0,
    question: '',
  });
  const [questionToEdit, setQuestionToEdit] = useState({
    additionals: {},
    configuration: { alternatives: [] },
    question_type: 'singleline',
    number_from: 0,
    number_to: 0,
    question: '',
  });
  const [businessPersonalData, setBusinessPersonalData] = useState({});
  const [optionalFields, setOptionalFields] = useState([]);
  const [hiddenFields, setHiddenFields] = useState([]);
  const [questionToOptions, setQuestionToOptions] = useState([]);
  const [inputSearchValue, setInputSearchValue] = useState('');
  const [selectSearchValue, setSelectSearchValue] = useState('all');

  const handeChangeCheckBox = (target) => {
    const { name } = target;
    setChecked({ ...checked, [name]: target.checked });
  };

  const errorFunction = (message) => {
    const messageToSend =
      message || 'Ocurrió un error, por favor intente nuevamente.';
    Modal.error({
      content: `${messageToSend}`,
      centered: true,
      maskClosable: true,
      okText: 'Continuar',
    });
  };

  const checkOptionsFunction = (array, type) => {
    array?.forEach(({ text }) => {
      if (
        (type === 'multiselection' && !text) ||
        (type === 'singleselection' && !text)
      )
        throw new Error('Por favor complete todas las opciones.');
    });
  };

  const handleSubmit = async () => {
    try {
      checkOptionsFunction(
        questionToSend.configuration.alternatives,
        questionToSend.question_type
      );
      const url = `/api/v1/gamesandtests/business/${props.business.id}/questiontemplates/`;
      const token = props.token;
      const response = await props.actions.fetchAndReturn(
        token, url, 'POST', { ...questionToSend, alternatives: questionToSend.configuration?.alternatives },
      );
      if (response.status === 201) {
        Modal.success({
          content: 'Pregunta creada exitosamente',
          centered: true,
          maskClosable: true,
          okText: 'Continuar',
        });
        handleGetQuestions();
      } else {
        errorFunction();
      }
      setQuestionToSend({
        additionals: {},
        configuration: {
          alternatives: [
            { text: undefined, correct: undefined },
            { text: undefined, correct: undefined },
          ],
        },
        question_type: selected || 'singleline',
        number_from: 0,
        number_to: 0,
        question: '',
      });
      setIsModalCreateVisible(false);
    } catch (e) {
      errorFunction(e);
    }
  };

  const handleDeteleQuestion = async (id) => {
    try {
      const url = `/api/v1/gamesandtests/business/${props.business.id}/questiontemplates/${id}/`;
      const token = props.token;
      const { status } = await props.actions.deleteAndReturn(token, url);
      if (status === 204) {
        Modal.success({
          content: 'Pregunta eliminada exitosamente',
          centered: true,
          maskClosable: true,
          okText: 'Continuar',
        });
        handleGetQuestions();
      } else {
        errorFunction();
      }
    } catch (e) {
      errorFunction(e);
    }
  };

  const handleOpenEditModal = (selectedQuestion) => {
    const filteredQuestion = allQuestionKits.filter(
      ({ id }) => id === selectedQuestion
    );
    setQuestionToEdit(filteredQuestion[0]);
    showModalFunction(setIsModalEditVisible, true);
  };

  const handleDuplicateQuestion = async (selectedQuestion) => {
    try {
      const url = `gamesandtests/business/${props.business.id}/questiontemplates/`;
      const token = props.token;
      const filteredQuestionToDuplicate = allQuestionKits.filter(
        ({ id }) => id === selectedQuestion
      );

      await props.actions.sendData(
        token,
        url,
        JSON.stringify(filteredQuestionToDuplicate[0]),
        'POST',
        ''
      );
      Modal.success({
        content: 'Pregunta duplicada exitosamente',
        centered: true,
        maskClosable: true,
        okText: 'Continuar',
      });
      handleGetQuestions();
    } catch (e) {
      errorFunction();
    }
  };

  const handleEditQuestion = async (questionToEdit) => {
    try {
      delete questionToEdit['correct'];
      delete questionToEdit['alternatives'];
      checkOptionsFunction(questionToEdit.configuration.alternatives, questionToEdit.question_type);

      const url = `/api/v1/gamesandtests/business/${props.business.id}/questiontemplates/${questionToEdit.id}/`;
      const token = props.token;
      const { status } = await props.actions.fetchAndReturn(token, url, 'PATCH', questionToEdit);
      setQuestionToEdit({
        additionals: {},
        configuration: {
          alternatives: [
            { text: undefined, correct: undefined },
            { text: undefined, correct: undefined },
          ],
        },
        question_type: selected || 'singleline',
        number_from: 0,
        number_to: 0,
        question: '',
      });
      if (status === 200){
        Modal.success({
          content: 'Pregunta editada exitosamente',
          centered: true,
          maskClosable: true,
          okText: 'Continuar',
        });
      } else {
        errorFunction();
      }
      handleGetQuestions();
    } catch (e) {
      errorFunction(e);
    }
  };

  const handleGetQuestions = async () => {
    try {
      const url = `gamesandtests/business/${props.business.id}/questiontemplates/`;
      const token = props.token;
      const { results } = await props.actions.fetchData(
        token,
        url,
        '',
        'GET',
        ''
      );
      setAllQuestionKits(results.reverse());
    } catch (e) {
      errorFunction(e);
    }
  };

  const handleGetPersonalData = async () => {
    try {
      const pk = props.business.id;
      const url = `accounts/business/${pk}/formconfigurations/`;
      const token = props.token;
      const { formconfiguration } = await props.actions.fetchData(
        token,
        url,
        '',
        'GET',
        ''
      );
      setBusinessPersonalData(formconfiguration);
      setOptionalFields(formconfiguration.optional_fields);
      setHiddenFields(formconfiguration.hidden_fields);
    } catch (e) {
      errorFunction(e);
    }
  };

  const columns = columnFn(
    handleOpenEditModal,
    handleDuplicateQuestion,
    warning
  );

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setTableSelectedRows(selectedRows);
    },
  };

  const showModalFunction = (fn, state) => {
    fn(state);
  };

  const confirmChanges = async () => {
    const body = {
      labels: {
        dni: 'Rut',
        email: 'Correo',
        name: 'Nombre',
        surname: 'Apellido',
        phone: 'Teléfono',
        birth_date: 'Fecha de nacimiento',
        gender: 'Género',
        nationality: 'Nacionalidad',
        country: 'País de residencia',
        commune: 'Comuna',
        // marital_status: 'Estado civil',
        education_level: 'Nivel de educación',
        carrers: 'Antecedentes académicos',
        experiences: 'Experiencia laboral',
        cv: 'Curriculum Vitae',
      },
      optional_fields: allChecked ? [] : optionalFields,
      hidden_fields: allChecked ? [] : hiddenFields,
    };
    try {
      const pk = props.business.id;
      const url = `accounts/business/${pk}/formconfigurations/`;
      const token = props.token;
      await props.actions.sendData(
        token,
        url,
        JSON.stringify(body),
        'PATCH',
        ''
      );
      Modal.confirm({
        title: '¡Tus cambios se guardaron exitosamente!',
        icon: <Icon type="check-circle" style={{ color: '#52C41A' }} />,
        content: (
          <Row>
            <Col>
              <Text>Se guardó la plantilla configurada</Text>
            </Col>
          </Row>
        ),
        okText: 'Continuar',
        onOk: function () {
          return handleGetPersonalData();
        },
      });
    } catch (e) {
      errorFunction();
    }
  };

  function warning(id) {
    Modal.confirm({
      title: '¿Estás seguro/a que deseas eliminar la plantilla?',
      icon: <Icon type="info-circle" style={{ color: '#FAAD14' }} />,
      content: 'Se eliminará de manera definitiva.',
      okText: 'Eliminar plantilla',
      cancelText: 'Cancelar',
      onOk: () => handleDeteleQuestion(id),
    });
  }

  const handleFilterQuestions = (typeOfQuestion) => {
    setInputSearchValue('');

    if (typeOfQuestion === 'all' || typeOfQuestion === undefined) {
      setFilterQuestion(false);
      setSelectSearchValue('all');
    } else {
      setFilterQuestion(true);
      const filteredQuestion = allQuestionKits.filter(
        ({ question_type }) => question_type === typeOfQuestion
      );
      setQuestionKits(filteredQuestion);
      setSelectSearchValue(typeOfQuestion);
    }
  };

  const handleFilterQuestionsSearch = (questionString) => {
    setFilterQuestion(true);
    const filteredQuestion = allQuestionKits.filter(({ question }) =>
      question.toLowerCase().includes(questionString.toLowerCase())
    );
    setInputSearchValue(questionString);
    setQuestionKits(filteredQuestion);
    setQuestionToOptions([
      ...new Set(filteredQuestion.map(({ question_type }) => question_type)),
    ]);
  };

  const inputSearchBarFn = (e) => {
    setInputSearchValue(e.target.value);
  };

  useEffect(() => {
    handleGetQuestions();
    handleGetPersonalData();
  }, []);

  return (
    <div className="tools-dashboard-container">
      <Breadcrumb separator={'>'}>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/evaluations">Evaluaciones</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Formulario de postulación</Breadcrumb.Item>
      </Breadcrumb>
      <Title level={1}></Title>
      <p style={{ fontSize: 14 }}>
        En esta sección podrás configurar las principales preguntas y datos para
        tus candidatos.
      </p>

      {keyTab != '2' && (
        <ActionBar
          dataSource={filterQuestion ? questionKits : allQuestionKits}
          inputSearchBarFn={inputSearchBarFn}
          inputSearchValue={inputSearchValue}
          questionToOptions={!questionToOptions.length ? [] : questionToOptions}
          showModalFunction={showModalFunction}
          setIsModalCreateVisible={setIsModalCreateVisible}
          handleFilterQuestions={handleFilterQuestions}
          handleFilterQuestionsSearch={handleFilterQuestionsSearch}
          setFilterQuestion={setFilterQuestion}
          selectSearchValue={selectSearchValue}
          setSelectSearchValue={setSelectSearchValue}
        />
      )}

      <ModalEditAndCreate
        isModalEditVisible={isModalEditVisible}
        setIsModalEditVisible={setIsModalEditVisible}
        showModalFunction={showModalFunction}
        setSelected={setSelected}
        selected={selected}
        isModalAddAlternativeVisible={isModalAddAlternativeVisible}
        setIsModalAddAlternativeVisible={setIsModalAddAlternativeVisible}
        questionToEdit={questionToEdit}
        setQuestionToEdit={setQuestionToEdit}
        handleEditQuestion={handleEditQuestion}
        isModalCreateVisible={isModalCreateVisible}
        setIsModalCreateVisible={setIsModalCreateVisible}
        handleSubmit={handleSubmit}
        questionToSend={questionToSend}
        setQuestionToSend={setQuestionToSend}
      />

      <div className="tools-dashboard-container__card-table">
        <Tabs
          activeKey={keyTab}
          defaultActiveKey="1"
          onChange={(key) => setKeyTab(key)}
          className="tools-dashboard-container__tabs-container"
        >
          <TabPane tab="Preguntas adicionales" key="1">
            <Table
              // rowSelection={{
              //   type: 'Checkbox',
              //   ...rowSelection,
              // }}
              className="tools-dashboard-container__rubrics-table"
              dataSource={filterQuestion ? questionKits : allQuestionKits}
              columns={columns}
              rowKey="id"
              pagination={{ onChange: () => window.scrollTo(0, 0) }}
            />
          </TabPane>
          {/* <TabPane tab="Datos personales" key="2">
            <PersonalData
              optionalFields={optionalFields}
              hiddenFields={hiddenFields}
              setOptionalFields={setOptionalFields}
              setHiddenFields={setHiddenFields}
              businessPersonalData={businessPersonalData}
              setAllChecked={setAllChecked}
              allChecked={allChecked}
            />
          </TabPane> */}
        </Tabs>
      </div>
      {keyTab === '2' && (
        <Row type="flex" style={{ gap: '0.5rem' }}>
          <Button onClick={() => setKeyTab('1')}>Cancelar</Button>
          <Button type="primary" onClick={confirmChanges}>
            Guardar Cambios
          </Button>
        </Row>
      )}
      {tableSelectedRows.length > 1 && keyTab == 1 && (
        <Row className="tools-dashboard-container__bulk-actions">
          <Row style={{ padding: '10px' }} gutter={[0, 16]}>
            <Col>
              <Row>
                <Col span={23}>
                  <Text style={{ color: 'white' }}>
                    <Icon type="check" style={{ marginRight: '5px' }} />
                    {tableSelectedRows.length} Preguntas seleccionadas
                  </Text>
                </Col>
                <Col span={1}>
                  <Icon
                    type="close"
                    style={{ color: 'white', cursor: 'pointer' }}
                    onClick={() => setTableSelectedRows([])}
                  />
                </Col>
              </Row>
            </Col>
            <Col>
              <Row style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Col span={8}>
                  <Button
                    onClick={() => console.log('back')}
                    icon="star"
                    style={{ color: '#5E5E5E' }}
                  >
                    Fijar como favoritos
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    onClick={() => console.log('back')}
                    icon="check"
                    style={{ color: '#5E5E5E' }}
                  >
                    Requeridas para todos mis procesos
                  </Button>
                </Col>
                <Col span={4}>
                  <Button
                    icon="delete"
                    onClick={() => console.log('back')}
                    style={{ color: '#FF4D4F' }}
                  >
                    Eliminar
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    token: state.auth.token,
    business: state.auth.currentBusiness,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationForm);
