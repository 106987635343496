import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ParseHtml from '../../components/parseHtml';
import * as actionCreators from '../../actions/data';
import * as authActionCreators from '../../actions/auth';
import ThemeProvider from './context/ThemeProvider';

import {
  Layout,
  Typography,
  Select,
  Input,
  Button,
  Modal,
} from 'antd';
import './JobListing.scss';
import CompanyDescription from './components/CompanyDescription';
import CoverImage from './components/CoverImage';
import AvailableJobs from './components/AvailableJobs';
import YoutubeVideo from './components/YoutubeVideo';
import MultipleJobs from './components/MultipleJobs';

import chileIMG from './assets/flags/chile.png';
import argentinaIMG from './assets/flags/argentina.png';
import boliviaIMG from './assets/flags/bolivia.png';
import brazilIMG from './assets/flags/brazil.png';
import colombiaIMG from './assets/flags/colombia.png';
import ecuadorIMG from './assets/flags/ecuador.png';
import paraguayIMG from './assets/flags/paraguay.png';
import peruIMG from './assets/flags/peru.png';
import uruguayIMG from './assets/flags/uruguay.png';
import venezuelaIMG from './assets/flags/venezuela.png';


const { Text, Title } = Typography;
const defaultImage = 'https://genoma-assets.s3.us-east-2.amazonaws.com/jobsite-assets/background-geometric.png';

function JobListingView(props) {
  const propTypes = {
    statusText: PropTypes.string,
    isFetching: PropTypes.bool.isRequired,
    data: PropTypes.object,
    actions: PropTypes.shape({
      fetchData: PropTypes.func.isRequired
    }).isRequired,
    authActions: PropTypes.shape({
      authLoginUser: PropTypes.func.isRequired,
      authSignupUser: PropTypes.func.isRequired
    }).isRequired
  };

  const defaultProps = {
    data: null,
    errors: {},
    status: ''
  };
  const [allResults, setAllResults] = useState([]);
  const [selectedJobApplication, setSelectedJobApplication] = useState(null);
  const [state, setState] = useState({
    backgroundImage: "",
    business_user: "",
    jobappid: undefined,
    results: [],
    cities: [],
    business_info: { sections: [] },
    intro_video: {
      source: "",
      video_id: "",
    },
    welcome_text: "",
    allCities: [],
    cityGroups: [],
    theme: {
      background: "",
      color: "",
    },
    rrss_facebook: "",
    rrss_twitter: "",
    rrss_instagram: "",
    rrss_linkedin: "",
  });


  const handleApply = (jobApplication) => {
    const hasBranches = jobApplication.branches.length > 0;
    if (hasBranches) {
      setSelectedJobApplication(jobApplication);
    } else {
      const queryParams = window.location.search || '?utm_source=businessportal&utm_medium=genomawork';
      const url = `/startprocess/${jobApplication.id}/${queryParams}`;
      props.dispatch(push(url));
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0 });
    const token = props.token;
    const data = JSON.stringify({ business_user: props.match.params['businessuserslug'] });

    const slug = props.match.params["businessuserslug"];
    const businessCellSlug = props.match.params["businesscellslug"];


    const url = businessCellSlug ? `/api/v1/gamesandtests/${slug}/jobslisting/?businesscell=${businessCellSlug}` : `/api/v1/gamesandtests/${slug}/jobslisting/`;

    props.actions.fetchAndReturn('', url).then((r) => {
      const response = r.body;
      setState((s) => {
        return { ...s, results: response.jobapplications }
      });
      setAllResults([...response.jobapplications]);

      props.authActions.updateLogo(response.logo);
      setState((s) => {
        return {
          ...s,
          business_user: response.name,
          welcome_text: ParseHtml(response.jobs_site_welcome_text),
          business_info: response.business_info,
          intro_video: { source: response?.intro_video?.source, video_id: response?.intro_video?.video_id },
          backgroundImage: response.jobs_site_background_image ? response.jobs_site_background_image : defaultImage,
          theme: { background: response.color_theme.background, color: response.color_theme.color },
          rrss_facebook: response.rrss_facebook,
          rrss_instagram: response.rrss_instagram,
          rrss_linkedin: response.rrss_linkedin,
          rrss_twitter: response.rrss_twitter
        }
      });
    });
  }, [])

  useEffect(() => {
    groupByCity();
  }, [state.results])


  const handleNavigateToJobs = () => {
    const el = document.getElementById('jl-section');
    const bodyRect = document.body.getBoundingClientRect();
    const elemRect = el.getBoundingClientRect();
    const distance = elemRect.top - bodyRect.top;
    window.scrollTo({
      left: 0,
      top: distance
    });
  };

  const handleNavigateToDescription = () => {
    const el = document.getElementById('jl-description');
    const bodyRect = document.body.getBoundingClientRect();
    const elemRect = el.getBoundingClientRect();
    const distance = elemRect.top - bodyRect.top;
    window.scrollTo({
      left: 0,
      top: distance
    });
  };

  const getCities = () => {
    const cities = allResults.map(({ location_city, location_country }) => {
      return {
        country: location_country,
        city: location_city
      }
    });
    return cities.filter(
      (obj, index, arr) => arr.findIndex(o => (o.country === obj.country && o.city === obj.city)) === index
    ).sort(
      (a, b) => a.city.localeCompare(b.city)
    );
  }

  const groupByCity = () => {
    const { results } = state;
    let groups = [];
    // Prioridad a ciertos nombres de ciudades
    const priorityCityNames = ['Nacional']
    const allCities = results.map(item => item.location_city).sort((a, b) => {
      if (priorityCityNames.includes(a)) { return -1 }
      else if (priorityCityNames.includes(b)) { return 1 }
      if (a < b) { return -1 }
      else if (a > b) { return 1 }
    }).reduce((acc, item) => {
      if (item !== acc[acc.length - 1]) {
        return [...acc, item]
      }
      return acc
    }, [])
    setState((s) => {
      return { ...s, allCities }
    })

    groups = results.reduce((groups, item) => {
      if (item.branches && item.branches.length === 0) {
        const group = (groups[item.location_city.toLowerCase()] || []);
        group.push(item);
        groups[item.location_city.toLowerCase()] = group;
      }
      else {
        const group = groups['main'] || [];
        group.push(item);
        groups['main'] = group;
      }
      return groups;
    }, {});
    let cityGroups = []; 
    allCities.forEach(cityName => {
      if (groups[cityName.toLowerCase()]){
        cityGroups.push(groups[cityName.toLowerCase()]);
      }
      else {
        // main job app with branches always 1st
        cityGroups = [groups['main'], ...cityGroups];
      }
    });
    setState((s) => {
      return { ...s, cityGroups }
    })
  };

  const flag = (country) => {
    return <img src={`https://genoma-assets.s3.us-east-2.amazonaws.com/flags/${country}.svg`} />
  };

  const showCompanyPresentation = () => {
    setState({ ...state, showJobs: false })
  };

  const onChangeJobPosition = (e) => {
    let value = e.target.value
    setState((s) => {
      return {
        ...s,
        results: allResults.filter(job => job.job_application.toLowerCase().includes(value.toLowerCase()))
      }
    })
  };

  const onChangeCity = (value) => {
    setState((s) => {
      return {
        ...s,
        results: value ? allResults.filter(job => job.location_city.toLowerCase() === value.toLowerCase()) : allResults
      }
    })
  };

  const handleApplyAll = (jobApplicationId, selectedBranches) => {
    // Guardar en redux las branches para una jobappid
    props.actions.saveSelectedBranches(jobApplicationId, selectedBranches)
    // Llevar a la página de postulación.
    const queryParams = window.location.search || '?utm_source=businessportal&utm_medium=genomawork';
    const url = `/startprocess/${jobApplicationId}/${queryParams}`
    props.dispatch(push(url));
  }

  return (
    <ThemeProvider>
      <Layout className='jl'>
        <Layout.Content>
          <div className='jl__cover-page-container'>
            <CoverImage
              backgroundImage={state.backgroundImage}
              business_user={state.business_user}
              results={state.results}
              handleNavigateToDescription={handleNavigateToDescription}
              handleNavigateToJobs={handleNavigateToJobs}
              facebook={state.rrss_facebook}
              linkedin={state.rrss_linkedin}
              instagram={state.rrss_instagram}
              twitter={state.rrss_twitter}
              theme={state.theme}
            />
          </div>

          <section className='jl-description' id='jl-description' >
            <CompanyDescription
              business_info={state.business_info}
            />
            {
              state.welcome_text.length !== 0 && (
                <div className='jl-description__textEditor' style={{ paddingTop: '3em' }}>{state.welcome_text}</div>
              )
            }
          </section>

          {
            state.intro_video.video_id && state.intro_video.video_id.length !== 0 ? (
              <section className='jl-video'>
                <YoutubeVideo linkId={state.intro_video.video_id.split('watch?v=')[1]} />
              </section>
            ) : null
          }

          {selectedJobApplication ?
            <MultipleJobs
              selectedJobApplication={selectedJobApplication}
              jobApplications={state.results}
              handleApplyAll={handleApplyAll}
              reset={() => setSelectedJobApplication(null)}
              setSelectedJobApplication={setSelectedJobApplication}
              theme={{
                borderColor: state.theme.background,
                background: state.theme.background,
                color: state.theme.color
              }}
            />
            : null}

          <section className='jl__jobs' id='jl-section'>
            <div className='jl__jobs__filters'>
              <Text strong>Filtros: </Text>
              <Input
                style={{ width: 270 }}
                placeholder='Busca por empleo'
                onChange={onChangeJobPosition}
              />

              <Select placeholder='Ciudad' showSearch style={{ width: 270 }} onChange={onChangeCity} allowClear={true}>
                {getCities().map((e, index) => (
                  <Select.Option key={index} value={e.city}>
                    <span className='location-with-flag'>
                      <span className='jl-flag'>
                        {flag(e.country)}
                      </span> {' '}
                      {e.city}
                    </span>
                  </Select.Option>
                ))}
              </Select>

              <Button
                style={{
                  height: '45px',
                  borderColor: state.theme.background,
                  background: state.theme.background,
                  color: state.theme.color,
                }}
                onClick={groupByCity}
              > Buscar
              </Button>
            </div>
            <AvailableJobs
              groupByCity={groupByCity}
              cityGroups={state.cityGroups}
              flag={flag}
              theme={state.theme}
              handleApply={handleApply}
            />

          </section>
        </Layout.Content>
      </Layout>
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => {
  return {
    data: state.data.data,
    isFetching: state.data.isFetching,
    error: state.data.error,
    logoURL: state.auth.logoURL
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    authActions: bindActionCreators(authActionCreators, dispatch),
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobListingView);
export { JobListingView as JobListingViewNotConnected };
