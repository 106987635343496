import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as actionCreators from "../../../../../../../actions/data";
import i18n from "../../../../../../../i18n";

import {
  Button,
  Card,
  Col,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Row,
  Select,
  Switch,
  Typography,
  Upload,
} from "antd";


const { Option } = Select;
const { Text } = Typography;

const FilesTemplateForm = (props) => {
  const { getFieldDecorator, getFieldValue } = props.form;

  const [files, setFiles] = useState([]);
  const [id, setId] = useState(null);
  const [templateName, setTemplateName] = useState();
  const [instructions, setInstructions] = useState();
  const [saveAsTemplate, setSaveAsTemplate] = useState(false);
  const [questions, setQuestions] = useState(
    props.location?.state?.questions || [
      {
        name: undefined,
        extensions: [],
        description: "",
      },
      {
        name: undefined,
        extensions: [],
        description: "",
      },
      {
        name: undefined,
        extensions: [],
        description: "",
      },
    ]
  );
  const [selectValues, setSelectValues] = useState([[], [], []]);

  const options = ["pdf", "docx", "xlsx", "pptx", "zip"];

  const setQuestion = (e, field, index) => {
    const fieldToChange = field == "name" ? e.target.value : e;
    const newQuestions = questions;
    newQuestions[index][field] = fieldToChange;
    setQuestions(newQuestions);
    setSelectValues(newQuestions.map((q) => q.extensions));
  };

  const deleteQuestion = (index) => {
    const newQuestions = questions.filter((_, i) => i !== index);
    setQuestions(newQuestions);
    setSelectValues(newQuestions.map((q) => q.extensions));
  };

  const submitData = (e) => {
    e.preventDefault();
    if (questions.filter((q) => q.name !== undefined).length > 0 && templateName.length > 0) {
      props.form.validateFields((err, fieldsValues) => {
        let formData = new FormData();
        const newQuestions = JSON.stringify(
          questions.filter((q) => q.name !== undefined)
        );
        const existingFiles = [];
        files.map((file) => {
          if (!file.business) {
            formData.append(file.name, file.originFileObj);
          } else {
            existingFiles.push(file.id);
          }
        });
        formData.append("old_files", existingFiles);
        formData.append("name", templateName);
        if (instructions) formData.append("instruction", instructions);
        formData.append("questions", newQuestions);
        if (!err && saveAsTemplate) {
          const method = id ? "PATCH" : "POST";
          props.onSaveRequest(formData, method, id, 'template');
        } else {
          const method = id ? "PATCH" : "POST";
          props.onSaveRequest(formData, method, id, 'request');
        };
      })
    } else {
      message.error('Debes ingresar todos los campos obligatorios')
    }
  };

  const dummyRequest = ({ onSuccess }) => setTimeout(() => onSuccess("ok"), 0);

  const handleAttachFile = ({ fileList }) => {
    const filteredFiles = fileList.filter((file) => file.status !== "removed");
    // Update the state with the new file list
    setFiles(filteredFiles);
    return files;
  };

  const getBlobObj = async (file) => {
    const blob = file.business
      ? await fetch(file.file).then((r) => r.blob())
      : new Blob([file.originFileObj]);
    const url = window.URL.createObjectURL(blob);
    let aux = document.createElement("a");
    document.body.appendChild(aux);
    aux.style = "display: none";
    aux.href = url;
    aux.download = file.name;
    aux.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <Row>
      <Form onSubmit={submitData}>
        <Card
          style={{ width: "100%" }}
          bodyStyle={{ paddingBottom: 0, paddingTop: 0 }}
          title={
            <Typography.Text strong style={{ marginTop: 10, color: "#597EF7" }}>
              NUEVA SOLICITUD DE DOCUMENTOS
            </Typography.Text>
          }
        >
          <Form.Item
            style={{ marginBottom: "0px", marginTop: "0px" }}
            help={
              <Row justify="space-between" type="flex">
                <Col>
                  {templateName?.length == 0 || props.location?.state?.name?.length == 0
                    ? "Obligatorio"
                    : ""}
                </Col>
                <Col>
                  {`${
                    templateName?.length ??
                    props.location?.state?.name?.length ??
                    0
                  }/100`}
                </Col>
              </Row>
            }
            label={
              <Text>
                <span style={{ color: "red" }}>*</span> Nombre de solicitud
              </Text>
            }
          >
            <Input
              maxLength={100}
              placeholder="Escribe el nombre de la solicitud"
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            help={
              <Row justify="end" type="flex">
                {`${
                  instructions?.length ??
                  props.location?.state?.instruction.length ??
                  0
                }/2000`}
              </Row>
            }
            label={
              <>
                <Text strong>{i18n.t('commons__instructions')}</Text>
                <Text style={{ color: "#BFBFBF" }}> (opcional)</Text>
              </>
            }
            style={{ marginBottom: "0px", marginTop: "0px" }}
          >
            <Input.TextArea
              maxLength={2000}
              placeholder="Escribe los detalles de la solicitud"
              value={instructions}
              onChange={(e) => setInstructions(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Col style={{ marginRight: "15px" }}>
              <Upload
                key="unique-key"
                accept=".pdf,.doc,.docx,.ppt,.pptx,.xlx,.xlsx"
                multiple={true}
                onChange={(info) => {
                  if (info.file.status === "uploading") {
                    info.file.status = "done";
                    const currentFileListSize = info.fileList.reduce(
                      (x, y) => x + y.size,
                      0
                    );
                    if (currentFileListSize >= 10485760) {
                      message.error(i18n.t('profile__max_size_file', { ns: 'candidateProfile' }));
                    } else if (info.fileList.length > 3) {
                      message.error(i18n.t('profile__max_files_count', { ns: 'candidateProfile' }));
                    } else {
                      handleAttachFile(info);
                    }
                  } else if (info.file.status === "done") {
                    message.success(`${info.file.name} ${i18n.t('profile__uploaded_ok', { ns: 'candidateProfile' })}`);
                    handleAttachFile(info);
                  } else if (info.file.status === "error") {
                    message.error(`${info.file.name} ${i18n.t('profile__no_uploaded', { ns: 'candidateProfile' })}`);
                  } else if (info.file.status === "removed") {
                    handleAttachFile(info);
                  }
                }}
                customRequest={(response) => dummyRequest(response)}
                fileList={files.map((file, index) => ({
                  ...file,
                  uid: index,
                  key: index,
                }))}
                onPreview={(file) => {
                  getBlobObj(file);
                }}
                style={{ marginBottom: "0px" }}
              >
                <Button>
                  <Icon type="paper-clip" /> {i18n.t('profile__add_files', { ns: 'candidateProfile' })}
                </Button>
              </Upload>
            </Col>
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "0px", marginTop: "0px" }}
            label={
              <Text>
                <span style={{ color: "#FF4D4F" }}>*</span> Documentos en esta solicitud{" "}
                <Text style={{ color: "#BFBFBF" }}>
                  (Formatos de archivos permitidos .pdf, .docx, .xlsx, .pptx, .zip.)
                </Text>
              </Text>
            }
          >
            {questions.map((question, index) => (
              <Row
                align="middle"
                key={`question-${index}-${question}`}
                style={{ marginBottom: "10px" }}
                type="flex"
              >
                <Col span={16}>
                  {getFieldDecorator(`question-${index}`, {
                    initialValue: question["name"],
                  })(
                    <Row gutter={16} type="flex">
                      <Col span={16}>
                        <Row align="bottom" style={{ flexDirection: "column" }} type="flex">
                          <Col span={24}>
                            <Input
                              key={index}
                              maxLength={200}
                              onChange={(e) => setQuestion(e, "name", index)}
                              placeholder="Escribe el nombre del documento"
                              style={{ width: '100%' }}
                              value={questions[index]["name"]}
                            />
                          </Col>
                          <Col>{getFieldValue(`question-${index}`)?.length ?? 0}/200</Col>
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Select
                          mode="multiple"
                          allowClear
                          style={{ width: "100%" }}
                          placeholder="Extensión de archivo"
                          onChange={(e) =>
                            setQuestion(e, "extensions", index)
                          }
                          key={`extension-${index}-${question}`}
                          value={selectValues[index]}
                        >
                          {options.map((value, i) => (
                            <Option key={i} value={value}>
                              {value}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col span={1} style={{ textAlign: "center" }}>
                  <Icon
                    onClick={() => questions.length > 1 && deleteQuestion(index)}
                    type="delete"
                    style={{ fontSize: 20, color: "#FF4D4F" }}
                  />
                </Col>
              </Row>
            ))}
            <Button
              onClick={() =>
                setQuestions((prevQuestions) => [
                  ...prevQuestions,
                  { question: undefined, extensions: [], description: "" },
                ])
              }
              type="link"
              style={{ padding: 0 }}
            >
              <Icon
                type="plus-circle"
                style={{
                  fontSize: 30,
                  color: "#F175A5",
                  strokeWidth: 1,
                }}
              />
            </Button>
          </Form.Item>
          <Form.Item style={{ marginBottom: "0px", marginTop: "0px" }}>
            Guardar como plantilla {"     "}
            <Switch
              checked={saveAsTemplate}
              onChange={() => setSaveAsTemplate(!saveAsTemplate)}
            />
          </Form.Item>
        </Card>
        <Form.Item>
          <Row type="flex" justify="end" style={{ width: "100%" }}>
            <Col style={{ marginRight: "20px" }}>
              <Button
                onClick={() =>
                  Modal.confirm({
                    cancelText: i18n.t('commons__cancel'),
                    content: "Perderás todos los cambios no guardados.",
                    okText: i18n.t('commons__confirm'),
                    onOk: () => props.onCancel(),
                    title: "¿Estás seguro de querer volver atrás?",
                  })
                }
              >
                {i18n.t('commons__cancel')}
              </Button>
            </Col>
            <Col>
              <Button htmlType="submit" type="primary">
                Crear solicitud
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Row>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

const mapStateToProps = (state) => {
  return {
    business: state.auth.currentBusiness,
    businessPermissions: state.auth.businessPermissions,
    token: state.auth.token,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: "files-form" })(FilesTemplateForm));
