import { Col, Icon, Row, Tabs, Typography } from 'antd';

import AvailabilitySchedule from '../../../components/Availability/AvailabilitySchedule';
import AvailabilitySelect from '../../../components/Availability/AvailabilitySelect';
import React from 'react';

const { Text, Title } = Typography;

const Availability = ({
  index,
  question,
  handleChange,
  configuration = {},
  ...props
}) => {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
      className="availability"
    >
      <Text strong>{question}</Text>
      {configuration.availabilityType === 'free' ? (
        <AvailabilitySchedule
          index={index}
          question={question}
          handleChange={handleChange}
          isNew={true}
        />
      ) : (
        <AvailabilitySelect
          schedule={configuration.schedule}
          index={index}
          handleChange={handleChange}
        />
      )}
    </div>
  );
};

export default Availability;
