import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';

import CandidatePathContent from './candidatepathcontent/CandidatePathContent';
import MessagesView from '../../../components/messaging/MessagesView'

import * as dataActionCreators from '../../../actions/data';
import * as authActionCreators from '../../../actions/auth';

import { Badge, Button, Layout, Modal, Row, Spin, Tabs, Typography, message } from 'antd';
import './CandidatePath.scss'
import {
  isChrome,
  browserVersion
} from 'react-device-detect'
import i18n from '../../../i18n';

const { Text } = Typography;

class CandidatePath extends React.Component {

  state = {
    backgroundImage: '',
    businessSlug: '',
    colorTheme: null,
    isLoading: true,
    isSeed: true,
    jobappid: null,
    mounted: false,
    recommendationsModal: false,
    videoDialog: false,

    userJobApp: null,
    chat: null,
    notifications: [],
    currentTab: '0'
  };

  componentDidMount() {
    if (isChrome) {
      if (parseInt(browserVersion) < 80) {
        Modal.error({
          centered: true,
          title: i18n.t('candidate_path__old__browser__title'),
          content: i18n.t('candidate_path__old__browser__content'),
          onOk: () => { window.location = 'https://www.google.com/intl/es-419/chrome/' }
        })
      }
    }
    const token = this.props.token;
    this.setState({ jobappid: this.props.match.params.jobappid });
    let url = `gamesandtests/userjobapplication/${this.props.location.pathname.split('/')[2]}/?format=json`;
    Object.keys(this.props.utm_params).map(field => {
      url = url + `&utm_${field}=${this.props.utm_params[field]}`;
    });

    const url2 = `gamesandtests/jobapplications/${this.props.match.params.jobappid}/public/`;
    this.props.actions.fetchData(token, url, '', 'get').then((response) => {
      /*
      if (response == undefined){
        message.error(i18n.t('start_process__inactive__process'), 5);
        this.props.dispatch(push('/me'))
        return;
      }
      */
      this.setState({ isLoading: false, userJobApp: response.id, chat: response.chat },
        () => window.location.hash === '#messages' && this.setState({ currentTab: '1' }));
      if (this.props.data.intro_video != null && this.props.data.intro_video.length > 0) {
        this.setState({ videoDialog: true })
      }
      response.branches.forEach(branch => {
        this.props.actions.fetchAndReturn(token, `/api/v1/gamesandtests/userjobapplication/${branch}/?format=json`)
      })
    });

    this.props.actions.fetchData(token, url2, '', 'get').then((response) => {
      this.setState({
        businessSlug: response.business.slug,
        colorTheme: response.business.color_theme,
        isSeed: response.is_seed,
      })
    });

    // PU notifications of type MSG, not read
    const urlNotifications = `/api/v1/notifications/count/?notification_type=MSG&status=CREATED&job_app=${this.props.match.params.jobappid}`;
    this.props.actions.fetchAndReturn(token, urlNotifications, 'GET').then(response => {
      if (response.status < 400) {
        const notificationCountResponse = response.body ? response.body : [];
        this.setState({ notifications: notificationCountResponse })
      }
      // TODO else
    })
  };

  updateChat() {
    this.setState({ isLoading: true })
    const url = `gamesandtests/userjobapplication/${this.props.location.pathname.split('/')[2]}/?format=json`;
    this.props.actions.fetchData(this.props.token, url, '', 'get').then((response) => {
      this.setState({ isLoading: false, chat: response.chat })
    });
  }


  getNotificationCount = (notificationType) => {
    if (notificationType === 'MSG') {
      const notificationCount = this.state.notifications.find(obj => obj.notification_type === 'MSG')?.count;
      return notificationCount ? notificationCount : 0
    }
    return 0
  }

  updateNotificationCount = () => {
    this.setState({ notifications: [{ notification_type: 'MSG', count: 0 }] });
  }

  findCode = () => {
    const { data } = this.props;
    const find = (code) => data.items?.find(act => act.code === code);
    const allGamesState = data.items?.filter(e => e.code !== 'KO' && e.code !== 'FORM' && e.code !== 'VINT' && e.code !== 'CUTE' && e.code !== 'INTR').map(({ status }) => status)

    return {
      ko: find('KO'),
      form: find('FORM'),
      vint: find('VINT'),
      allGames: allGamesState,
    }
  }

  componentDidUpdate() {
    const { jobappId } = this.props;
    const { allGames } = this.findCode();

    if (this.state.backgroundImage === '' && this.props.data.background_image !== undefined && this.props.data.background_image != this.state.backgroundImage) {
      this.setState({ backgroundImage: this.props.data.background_image });
    }
    if (this.props.data.logo !== null && this.props.logoURL !== this.props.data.logo) {
      this.props.authActions.updateLogo(this.props.data.logo);
    }
    if (allGames?.every(x => x === 'DONE')) {
      localStorage.removeItem(`gr_${jobappId}`);
    }
    if (!this.state.isSeed) {
      if (!this.state.recommendationsModal && allGames.length && !allGames.includes('DONE')) {
        this.openRecommendationsModal();
      }
    }
  };

  openRecommendationsModal = () => {
    const { jobappId } = this.props;
    const { ko, form } = this.findCode();

    if (localStorage.getItem(`gr_${jobappId}`) !== 'closed') {
      if ((!ko || ko.status === 'DONE') && (!form || form.status === 'DONE')) {
        this.setState({ recommendationsModal: true });
        localStorage.setItem(`gr_${jobappId}`, 'open');
      }
    }
  }

  closeModal = () => {
    const { jobappId } = this.props;
    this.setState({ recommendationsModal: false });
    localStorage.setItem(`gr_${jobappId}`, 'closed');
  }

  handleVideoDialog = () => {
    let v = !this.state.videoDialog
    this.setState({ videoDialog: v })
  };

  embededTraduction = (youtube_link) => {
    if (youtube_link) {
      return youtube_link.replace('watch?v=', 'embed/');
    } else {
      return ''
    }
  }

  render() {
    return (
      <Layout className='cp-view'>
        <Tabs
          activeKey={this.state.currentTab}
          centered
          tabBarStyle={{ display: 'flex', justifyContent: 'center' }}
          style={{ width: '90%' }}
          destroyInactiveTabPane={true}
          onChange={key => {
            key && this.updateChat();
            this.setState({ currentTab: key });
          }}
        >
          <Tabs.TabPane tab={i18n.t('candidate_path__activities')} key="0">
            <Row type='flex' align='top' justify='center'>
              {this.state.isLoading ? (
                <Spin />
              ) : (
                <CandidatePathContent
                  businessSlug={this.state.businessSlug}
                  colorTheme={this.state.colorTheme}
                />
              )}
              <Modal
                width={780}
                visible={this.state.videoDialog}
                onCancel={this.handleVideoDialog}
                footer={[
                  <Button key="back" onClick={this.handleVideoDialog}>
                    {i18n.t('commons__close')}
                  </Button>,
                ]}
              >
                <iframe 
                  credentialless=''
                  width="720"
                  height="480"
                  src={this.embededTraduction(this.props.data.intro_video)}
                  allowFullScreen>
                </iframe>
              </Modal>
              <Modal
                centered
                width={650}
                //footer={false}
                onCancel={this.closeModal}
                visible={this.state.recommendationsModal}
                className='recommendations-modal'
                footer={[
                  <Button
                    onClick={this.closeModal}
                    type='primary'
                    style={{
                      margin: 'auto',
                      fontSize: '16px',
                      padding: '7px 50px',
                      height: 'auto',
                    }}
                  >
                    {i18n.t('commons__continue')}
                  </Button>
                ]}
              >
                <div style={{ fontSize: 15 }}>
                  <div
                    style={{
                      margin: '10px auto 10px',
                      textAlign: 'center'
                    }}
                  >
                    <img
                      width='110'
                      src={"https://genoma-assets.s3.us-east-2.amazonaws.com/happy-genomin.png"}
                      style={{
                        display: 'flex',
                        margin: 'auto'
                      }}
                    />
                    <Text
                      strong
                      style={{
                        fontSize: 18
                      }}
                    >
                      {i18n.t('final__modal__congratulations')}
                    </Text>
                  </div>

                  <Text>
                    {i18n.t('final__modal__paragraph__1')}
                  </Text> <br /> <br />
                  <Text>
                    {i18n.t('final__modal__paragraph__2')}
                  </Text> <br /> <br />
                  <Text strong>{i18n.t('final__modal__recommendations')}</Text> <br />
                  <ul
                    style={{
                      listStyle: 'outside',
                      paddingLeft: '1em'
                    }}
                  >
                    <li>
                      <Text>
                        {i18n.t('final__modal__recommendations__1')}
                      </Text>
                    </li>
                    <li>
                      <Text>
                        {i18n.t('final__modal__recommendations__2')}
                      </Text>
                    </li>
                    <li>
                      <Text>
                        {i18n.t('final__modal__recommendations__3')}
                      </Text>
                    </li>
                  </ul> <br />
                  <Text strong>{i18n.t('final__modal__Important')}</Text> <br />

                  <Text>
                    {i18n.t('final__modal__important__1')}
                  </Text>
                  <br />
                  <br />
                  <Text
                    type="secondary"
                    style={{
                      fontSize: 14,
                      lineHeight: 1,
                    }}
                  >
                    {i18n.t('final__modal__footer')}
                  </Text>
                </div>
              </Modal>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab={<Badge dot={true} count={this.getNotificationCount('MSG')}><div style={{ fontSize: 16 }}>{i18n.t('commons__messages')}</div></Badge>} key="1">
            <MessagesView
              userType="personal"
              logoBusiness={this.props.logoURL}
              userJobApp={this.state.userJobApp}
              chat={this.state.chat}
              callback={this.updateNotificationCount}
            />
          </Tabs.TabPane>
        </Tabs>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.data.data,
    utm_params: state.data.utm_params,
    jobappId: state.data.data.jobapp_id,
    logoURL: state.auth.logoURL,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(dataActionCreators, dispatch),
    authActions: bindActionCreators(authActionCreators, dispatch),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidatePath);
