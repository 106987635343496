import React, { createContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../actions/data';
import * as authActionCreators from '../../../actions/auth';

export const ThemeContext = createContext();

const ThemeProvider = (props) => {

  const [themeButton, setThemeButton] = useState({
    color: '#fff',
    background: '#f175a5'
  })

  return (
    <ThemeContext.Provider
      value={{ themeButton }}
    >
      {props.children}
    </ThemeContext.Provider>
  )
}

const mapStateToProps = (state) => {
  return {
    data: state.data.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    authActions: bindActionCreators(authActionCreators, dispatch),
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThemeProvider);
