export const SCORES_DATA = {
  final_score_distribution: [],
  traits: {
    reasoning: [],
    personality: [],
    cognitive: [],
  },
};

export const KO_DATA = {
  questions: {},
};

export const STATS = {
  gender: [],
  disability: [],
  // Recidency
  country: [],
  nationality: [],
  ages: [],
  education_level: [],
};

export const JOBAPPS = {
  results: [],
};

export const HEADER = {
  overview: {
    ACTIV: 0,
    INACT: 0,
    OTHER: 0,
  },
  stages: {},
  candidates_per_week: [],
};

export const OVERVIEW = {
  ages: [],
  area_distribution: [],
  average_time_per_status: [],
  candidate_timeline: [],
  candidates_total: 0,
  discard_reasons: [],
  hired_total: 0,
  hired_versus_vacancies_rate: 0,
  hiring_rate: 0,
  nps: 0,
  completion_rate_avg: 0,
  completion_time_avg: 0,
  hiring_rate_avg: 0,
  nationalities: [],
  genders: [],
  disability_types: [],
  education_levels: [],
  first_hired_date: null,
  first_application_date: null,
  fit_distribution: [],
  stages: {},
  utm_sources: [],
  visitor_to_candidate_rate: 0,
};
export const CANDIDATES = {
  ages: [],
  applications_total: 0,
  average_time_per_status: [],
  candidates_per_process: 0,
  candidates_total: 0,
  candidate_timeline: [],
  completion_days_avg: 0,
  completion_rate_avg: 0,
  creation_date: null,
  days_to_first_application_avg: 0,
  discard_time_avg: 0,
  disability_rate: 0,
  disability_type: [],
  education_levels: [],
  first_application_date: null,
  first_hired_date: null,
  genders: [],
  nationalities: [],
  nps: 0,
  utm_sources: [],
  visitor_to_candidate_rate: 0,
};
export const HIRING = {
  total_hired: 0,
  candidate_timeline: [],
  utm_sources: [],
  last_hiring: [],
  hired_versus_vacancies_rate: 0,
  hiring_per_jobapp: 0,
  hiring_rate: 0,
  hiring_time_avg: 0,
  nationalities: [],
  ages: [],
  age_avg: 0,
  genders: [],
  disabilities: [],
  disability_rate: 0,
  education_levels: [],
};
export const EVALUATIONS_RESULTS = {
  EMOTIO: {},
  COGNIT: {},
  RAZONA: {},
  PERSON: {},
  BEHAV: {},
  total: {
    total: 0,
    total_hired: 0,
  },
};
export const DIVERSITY = {
  candidates: {
    age: {},
  },
  hired: {
    age: {},
  },
};

const statisticsBase = { avg: 0, median: 0, mode: 0, data: [] };

export const SATISFACTION = {
  candidate_avg: 0,
  net_promoter_score: 0,
  candidates_calcs: statisticsBase,
  fit_calcs: statisticsBase,
  gender_calcs: statisticsBase,
  ages_calcs: statisticsBase,
  disability_calcs: statisticsBase,
  nationality_calcs: statisticsBase,
};
