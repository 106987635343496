import React, { Component } from 'react';
import { Form, Input, InputNumber, Slider, Select, Row, Col, Button, Checkbox } from 'antd';
import { areas, areasOrderedArray, availability, jobTypes, businessActivity, businessActOrderedArray, paymentMethods } from './options';
import Actions from '../../Actions';
import { AppContext } from '../../JobForm';

const { Option } = Select;
const { TextArea } = Input;

const searchProps = {
  showSearch: true,
  optionFilterProp: 'children',
  notFoundContent: 'No se encontraron resultados',
  filterOption: (input, option) => {
    if (option.props.value) {
      return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    } else {
      return false
    }
  }
}

class Description extends Component {
  state = {
    vacancies: 1,
    showSalary: false,
  }

  updateVacancies = async (sum) => {
    const { vacancies } = this.state;
    await this.setState({ 
      vacancies: sum < 0 && vacancies ? vacancies - 1 : vacancies + 1 
    })
    this.setVacancies();
  }

  onVacanciesChange = async (value) => {
    const newValue = value >= 1 || !value ? 1 : value;
    await this.setState({ vacancies: newValue });
    this.setVacancies();
  }

  setVacancies = () => {
    this.props.form.setFieldsValue({
      vacancies: this.state.vacancies
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { showSalary } = this.state

    return ( 
      <AppContext.Consumer>
        {(value)  => {
          const { description } = value.data;
          return (
            <Form onSubmit={(e) => value.submit(e, this.props.form, 'description')}>
              <div className='form-view' id='form-view'>
                <Form.Item label="Título o cargo de trabajo">
                  {getFieldDecorator('jobTitle', {
                    initialValue: description.jobTitle,
                    rules: [
                      {
                        required: true,
                        message: 'Por favor ingresa el título',
                      },
                    ],
                  })(
                    <Input />
                  )}
                </Form.Item>

                <Form.Item label="Área de desempeño">
                  {getFieldDecorator('jobAreaId', {
                    initialValue: description.jobAreaId,
                    rules: [
                      {
                        required: true,
                        message: 'Por favor selecciona el área de desempeño',
                      },
                    ],
                  })(
                    <Select { ...searchProps }>
                      {areasOrderedArray.map((id) => (
                        <Option key={id} value={parseInt(id)}>{areas[id]}</Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>

                <Form.Item label="Descripción de la oferta">
                  {getFieldDecorator('jobDescription', {
                    initialValue: description.jobDescription,
                    rules: [
                      {
                        required: true,
                        message: 'Por favor ingresa la descripción de la oferta',
                      },
                    ],
                  })(
                    <TextArea autoSize={{ minRows: 4, maxRows: 5 }} />
                  )}
                </Form.Item>
                <Form.Item label="Jornada de trabajo">
                  {/**falta este pareceeee */}
                  {getFieldDecorator('workDayId', {
                    initialValue: description.workDayId,
                    rules: [
                      {
                        required: true,
                        message: 'Por favor selecciona la jornada de trabajo',
                      },
                    ],
                  })(
                    <Select>
                      {Object.keys(availability).map((id) => (
                        <Option key={id} value={parseInt(id)}>{availability[id]}</Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>

                <Form.Item label="Duración de la oferta">
                  {getFieldDecorator('jobValidity', {
                    initialValue: description.jobValidity,
                    rules: [
                      {
                        required: true,
                        message: 'Por favor selecciona el aviso de vigencias',
                      },
                    ],
                  })(
                    <Select>
                      {[15, 30, 60, 90].map((days) => (
                        <Option key={days} value={days}>{days} días</Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>

                <Form.Item label="Cantidad de vacantes">
                  <div className='vacancies'>
                    <Button icon='minus' onClick={() => this.updateVacancies(-1)} />
                    {getFieldDecorator('vacancies', {
                      initialValue: description.vacancies,
                      onChange: this.onVacanciesChange,
                      rules: [
                        {
                          required: true,
                          message: 'Por favor selecciona el aviso de vigencias',
                        },
                      ],
                    })(
                      <InputNumber />  
                    )}
                    <Button icon='plus' onClick={() => this.updateVacancies(1)} />
                  </div>
                </Form.Item>

                <Form.Item label="Duración del contrato">
                  {getFieldDecorator('contractTime', {
                    initialValue: description.contractTime,
                    rules: [
                      {
                        required: true,
                        message: 'Por favor selecciona el aviso de vigencias',
                      },
                    ],
                  })(
                    <Input />
                  )}
                </Form.Item>

                <Form.Item label="Tipo de cargo">
                  {getFieldDecorator('jobTypeId', {
                    initialValue: description.jobTypeId,
                    rules: [
                      {
                        required: true,
                        message: 'Por favor selecciona el aviso de vigencias',
                      },
                    ],
                  })(
                    <Select { ...searchProps }>
                      {Object.keys(jobTypes).map((id) => (
                        <Option key={id} value={parseInt(id)}>{jobTypes[id]}</Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>

                <Row gutter={15}>
                  <Col xs={24} md={12}>
                    {this.props.isChile &&
                      <Form.Item label="Sueldo a pagar">
                        {/**IMPORTANTE ACA solo números */}
                        {getFieldDecorator('salary', {
                          initialValue: description.salary,
                          rules: [
                            {
                              required: true,
                              message: 'Por favor ingresa el sueldo a pagar',
                            },
                          ],
                        })(
                          <InputNumber />
                        )}
                      </Form.Item>
                    }
                    <Checkbox checked={description.showSalary} onChange={this.props.onChangeShowSalary} >Mostrar salario</Checkbox>
                    {!this.props.isChile &&
                      <Form.Item label="Rango salario">
                        {getFieldDecorator('salaryRange', {
                          initialValue: description.salaryRange,
                          rules: [
                            {
                              required: true,
                              message: 'Por favor ingresa el rango salarial',
                            },
                          ],
                        })(
                          <Slider />
                        )}
                      </Form.Item>
                    }
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item label="Forma de pago">
                      {getFieldDecorator('paymentMethod', {
                        initialValue: description.paymentMethod,
                        rules: [
                          {
                            required: true,
                            message: 'Por favor selecciona la forma de pago',
                          },
                        ],
                      })(
                        <Select>
                          {Object.keys(paymentMethods).map((id) => (
                            <Option key={id} value={parseInt(id)}>{paymentMethods[id]}</Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item label="Actividad de la empresa">
                  {getFieldDecorator('companyActivityId', {
                    initialValue: description.companyActivityId,
                    rules: [
                      {
                        required: true,
                        message: 'Por favor selecciona la actividad de la empresa',
                      },
                    ],
                  })(
                    <Select { ...searchProps }>
                      {businessActOrderedArray.map((id) => (
                        <Option key={id} value={parseInt(id)}>{businessActivity[id]}</Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </div>

              <Actions />
            </Form>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

const WrappedDescriptionForm = Form.create()(Description);
export default WrappedDescriptionForm;