import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Button, Col, Icon, message, Modal, Row, Upload } from 'antd';
import PropTypes from 'prop-types';

import * as actionCreators from '../../../../actions/data';
import i18n from '../../../../i18n';

function UploadCurriculumModal(props) {
  const { actions, candidateId, closeModal, setCv, token } = props;

  const [newCv, setNewCv] = useState();

  const handleSubmit = () => {
    actions.sendFile(
      token, `accounts/personalusers/${candidateId}/files/`, newCv, newCv.name, '', 'cv',
    ).then((response) => {
      if (response.uploaded) {
        message.success(i18n.t('commons__save_success'));
        setCv(response.file);
      } else {
        message.error(i18n.t('commons__save_failure'));
      }
      closeModal();
    })
  };

  return (
    <Modal
      footer={null}
      onCancel={closeModal}
      visible
      width={600}
    >
      <Upload.Dragger
        accept=".doc,.docx,.pdf,application/pdf,application/msword"
        beforeUpload={() => false}
        onChange={({ file }) => {
          file.status = 'done';
          setNewCv(file);
        }}
        showUploadList={{
          showPreviewIcon: false,
          showDownloadIcon: false,
          showRemoveIcon: false,
        }}
        style={{ marginTop: '2em', width: '100%' }}
        fileList={newCv ? [newCv] : []}
      >
        <p className="ant-upload-drag-icon">
          <Icon style={{ color: '#F175A5' }} type="inbox" />
        </p>
        <p className="ant-upload-text">{i18n.t('form__upload_here')}</p>
        <p className="ant-upload-hint">{i18n.t('form__cv__extension__file__2')}</p>
      </Upload.Dragger>
      <Row
        gutter={8}
        justify="end"
        style={{ marginTop: '2em' }}
        type="flex"
      >
        <Col>
          <Button onClick={closeModal}>{i18n.t('commons__cancel')}</Button>
        </Col>
        <Col>
          <Button onClick={handleSubmit} type="primary">
            {i18n.t('commons__save')}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}

UploadCurriculumModal.propTypes = {
  actions: PropTypes.shape({
    sendFile: PropTypes.func.isRequired,
  }).isRequired,
  candidateId: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  setCv: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actionCreators, dispatch),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadCurriculumModal);
