import React from 'react';
import {
  Tabs,
  Col,
  Row,
  Icon,
  Typography,
  Table,
  Tag
} from 'antd';
import ParseHtml from '../../../../../../../../../../../components/parseHtml';

const { Text } = Typography;
const { TabPane } = Tabs

const tabPaneQuestions = [
  {
    title: 'Tipo de pregunta',
    dataIndex: 'skill',
    key: 'skill',
    render: (item, index) => (
      <Tag key={index} style={{ border: '1px solid #597EF7' }}>{item}</Tag>
    )
  },
  {
    title: 'Descripción',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Respuesta',
    dataIndex: 'answer',
    key: 'answer'
  }
];
const FakeQuestionData = [
  {
    skill: 'Microsoft Excel',
    description: '¿Cuál es un lenguaje de estilos?',
    answer: 'Desarrollo Web'
  }
]

const ExpandedRowCutes = (cute) => {
  return (
    <Tabs style={{ margin: '0' }}>
      <TabPane tab='Detalles' key='1'>
        <Col sm={8} md={6}>
          <Row type='flex'>
            <Col style={{ width: '130px', height: '96px', border: '1px solid #D9D9D9', background: '#FAFAFA', margin: '0 20px 0 0' }}>
              <Row type='flex' align='top' justify='space-between' style={{ height: '100%', flexDirection: 'column', padding: '10px' }}>
                <Icon type='clock-circle' style={{ color: '#F175A5' }} />
                <Text strong level={4} style={{ color: '#454545' }}>Tiempo</Text>
                {
                  cute.questions.global_time ?
                    (<Text> {cute.questions.global_time / 60} minutos </Text>) :
                    (<Text>Sin tiempo</Text>)
                }
              </Row>
            </Col>
            {
            /*
            <Col style={{ width: '130px', height: '96px', border: '1px solid #D9D9D9', background: '#FAFAFA' }}>
              <Row type='flex' align='top' justify='space-between' style={{ height: '100%', flexDirection: 'column', padding: '10px' }}>
                <Icon type='check-circle' style={{ color: '#F175A5' }} />
                <Text strong level={4} style={{ color: '#454545' }}>Aprobación</Text>
                {
                  cute.scores_config?.approval_percentage ?
                    (<Text>{cute.scores_config.approval_percentage}%</Text>) :
                    (<Text>Sin porcentaje</Text>)
                }
              </Row>
            </Col>
            */
            }
          </Row>
        </Col>
        <Col sm={16} md={18} style={{ height: '200px' }}>
          <Row type='flex' style={{ flexDirection: 'column', height: '100%' }}>
            <Text strong style={{ color: '#454545', fontSize: '16px', margin: '0 0 5px 0' }}>Descripción</Text>
            {
              cute.description !== null ?
                ParseHtml(cute.description) :
                (
                  <Text>Sin descripción</Text>
                )
            }
          </Row>
        </Col>
      </TabPane>
      {/* <TabPane tab='Preguntas' key='2'>
        <Table
          pagination={false}
          dataSource={FakeQuestionData}
          columns={tabPaneQuestions}
          style={{ border: '1px solid #D9D9D9', borderBottom: 'none' }}
          rowKey={data => data.skill}
        />
      </TabPane> */}
    </Tabs>
  )
}

export default ExpandedRowCutes;