import React, { useEffect, useState } from 'react';
import { Button, Col, Collapse, Icon, Modal, Radio, Row, Typography } from 'antd';
import TriggerForm, { configurations } from './triggerForm';
import TriggerSummary from './components/TriggerSummary';
import { actionsOptions } from './components/TriggerAction';
import './triggers.scss';

const { Panel } = Collapse;
const { Text } = Typography;


const TriggerComponentModal = ({ currentTriggers, triggersToRemove, currentEvaluations, visible, setVisible, stages, tags, updateTriggers, mode='view', goToEdit=null }) => {
  const [triggers, setTriggers] = useState(currentTriggers.map((t) => ({...t, when: configurations[t.when] ? t.when : null})))
  const [formEnabled, setFormEnabled] = useState(false)
  const [stageSelected, setStageSelected] = useState(null)
  const [viewStages, setViewStages] = useState([])
  const [actionTypesWarning, setActionTypesWarning] = useState({})

  useEffect(() => {
    if (mode === 'view' && stages) {
      setViewStages(stages.map((stage) => ({...stage, id: stage.code})))
    }
  }, [stages])

  const handleClose = () => {
    setFormEnabled(false)
    setVisible(false)
  }

  function sortByType(a, b) {
    if (a.when === 'MOVSTG' && b.when !== 'MOVSTG') {
      return -1;
    }
    if (a.when !== 'MOVSTG' && b.when === 'MOVSTG') {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    if (mode === 'view' && currentTriggers) {
      setTriggers(currentTriggers.map((t) => {
        const whenFound = configurations[t.when] ? t.when : null;
        return {...t, when:  {label: configurations[whenFound]?.label, value: whenFound}}
      }))
    } 
  }, [currentTriggers])

  const checkTriggers = () => {
    if (triggers.length > currentTriggers.length) {
      const newTriggers = triggers.filter((trigger) => !currentTriggers.find((currentTrigger) => currentTrigger.id === trigger.id))
      updateTriggers('toAdd', newTriggers)
      updateTriggers('currentTriggers', triggers)
    }
  }
  
  useEffect(() => {
    let actionsDict = {}
    let repeatedActionsByStage = {}
    // create array of all actions of all triggers
    triggers.forEach((trigger) => {
      const primaryActions = trigger.primaryActions.map((action) => action.type)
      const secondaryActions = trigger.secondaryActions.map((action) => action.type)
      actionsDict[trigger.stage.id] ? actionsDict[trigger.stage.id] = actionsDict[trigger.stage.id].concat([...primaryActions, ...secondaryActions]) : actionsDict[trigger.stage.id] = [...primaryActions, ...secondaryActions]
    })
    // check which actions are repeated by stage and set them with setActionTypesWarning
    Object.keys(actionsDict).forEach((stage) => {
      const repeatedActions = actionsDict[stage].filter((action, index) => actionsDict[stage].indexOf(action) !== index)
      repeatedActionsByStage[stage] = repeatedActions
    })
    setActionTypesWarning(repeatedActionsByStage)
  }, [triggers])

  const deleteTrigger = (trigger) => {
    if (trigger.id) {
      const newTriggersToRemove = [...triggersToRemove, trigger].filter(t => !!t.id)
      updateTriggers('toRemove', newTriggersToRemove)
    }
    else {
      const newTriggersToAdd = triggers.filter((t) => t !== trigger)
      updateTriggers('toAdd', newTriggersToAdd)
    }
    setTriggers(triggers.filter((t) => t !== trigger))
    updateTriggers('currentTriggers', triggers.filter((t) => t !== trigger))
  }

  const emptyTrigger = (stage) => (
    <Row type='flex' style={{ rowGap: '1em' }}>
      <Row type='flex' justify='center' align='middle' style={{ width: '100%' }}>
        <img src="https://genoma-assets.s3.us-east-2.amazonaws.com/genomin_2.svg" alt="genomin" />
      </Row>
      <Row type='flex' justify='center' align='middle' style={{rowGap: '0.5em', width: '100%'}}>
        <Col span={24} style={{ textAlign: 'center' }}>
          <Text><strong>Aún no tienes ninguna automatización en esta etapa</strong></Text><br />
        </Col>
        <Col span={24} style={{ textAlign: 'center' }}>
          <Text>Crea automatizaciones en tu proceso para facilitar tus tareas.</Text>
        </Col>
      </Row>
      <Row type='flex' justify='center' align='middle' style={{ width: '100%' }}>
        <Button type='secondary' onClick={() => {
          setStageSelected(stage);
          setFormEnabled(true)}}
        >
          Crear automatización
        </Button>
      </Row>
    </Row>
  )
  
  if (mode === 'edit') 
  return (
    <Modal
      title="Automatizaciones"
      visible={visible}
      footer={formEnabled ? [] : [
        <Button key="back" onClick={handleClose}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" onClick={() => {
          checkTriggers();
          handleClose();
        }}>
          Confirmar
        </Button>,
      ]}
      bodyStyle={{ backgroundColor: '#f5f5f5' }}
      onCancel={handleClose}
      width={900}
    >
      {!formEnabled && stages?.map((stage, index) => 
      (
        <Collapse key={`stage_${index}`} defaultActiveKey={[`collapse__${index}`]} style={{ marginTop: '1em' }}>
          <Panel header={ triggers.filter(trigger => trigger.stage.id === stage.id).length > 0 ?
            <Row type='flex' justify='space-between' align='middle' style={{ width: '100%' }}>
                <Text>{stage.name}</Text>
                {triggers.filter(trigger => trigger.stage.id === stage.id).length < 4 &&
                <Button type='primary' onClick={() => {
                  setStageSelected(stage);
                  setFormEnabled(true)}}
                  icon='plus'
                  style={{ color: "white"}}
                /> }
            </Row> :
              stage.name}
          >
            {triggers?.filter(trigger => trigger.stage.id === stage.id).length === 0 ? 
              emptyTrigger(stage) : 
              triggers.filter(trigger => trigger.stage.id === stage.id).sort(sortByType).map((trigger, index) => {
                return (
                  <TriggerSummary 
                    trigger={trigger} 
                    key={`trigger_${index}`} 
                    stages={stages} number={index + 1} 
                    canRemove={true} 
                    remove={() => deleteTrigger(trigger)} 
                    cutes={currentEvaluations}
                  />
                )
              }
            )}
            {
              actionTypesWarning[stage.id]?.length > 0 &&
              <Row type='flex' justify='center'>
                <Col span={24} style={{ padding: '10px', margin: '10px', backgroundColor: '#FFFBE6', border: '1px solid #FFE58F' }}>
                  <Text>Existen automatizaciones que tienen acciones repetidas [{actionTypesWarning[stage.id].map((actionType) => actionsOptions[actionType]?.label).join(', ')}]. Asegúrate de que la acción no repita su contenido y no sea dirigida a los mismos candidatos.</Text>
                </Col>
              </Row>
            }
          </Panel>
        </Collapse>
      ))}
      {formEnabled ?
          <TriggerCreator
            goBack={() => setFormEnabled(false)}
            evaluations={currentEvaluations.filter((cute) => cute.stage === stageSelected.id)}
            stageSelected={stageSelected}
            stages={stages}
            tags={tags}
            setTriggers={setTriggers}
            finish={() => setFormEnabled(false)} 
          /> : null}
    </Modal>
  )
  else {
    return (
    <Modal
      title="Automatizaciones"
      visible={visible}
      footer={
        <Button type='primary' onClick={goToEdit}> Editar </Button>
      }
      onCancel={() => setVisible(false)}
      width={900}
      style={{
        backgroundColor: '#FAFAFA'
      }}
    >
    {triggers.length > 0 ? viewStages.map((stage, stageIndex) => (
      triggers?.find((trigger) => ( trigger.stage === stage.id)) &&
        <Collapse key={`stage_${stageIndex}`} defaultActiveKey={[`collapse__${stageIndex}`]} style={{ marginTop: '1em' }}>
          <Panel header={
            <Row>
              <Text>{stage.name}</Text>
            </Row>
          }
          >
            {triggers.filter(trigger => trigger.stage === stage.id).sort(
              sortByType
            ).map((trigger, index) => {
              return (
                <TriggerSummary 
                  trigger={trigger} 
                  key={`trigger_${index}`} 
                  stages={viewStages} 
                  number={index + 1} 
                  canRemove={false} 
                  remove={null} 
                  cutes={currentEvaluations} 
                />
              )
            }
            )}
          </Panel>
        </Collapse>
    )):
    <Typography.Text>El proceso no posee automatizaciones</Typography.Text>
    }
    </Modal>
  )
  }
}

export default TriggerComponentModal;


function TriggerCreator ({ goBack, stages, evaluations, tags, stageSelected, setTriggers, finish }) {
  const [trigger, setTrigger] = useState({
    stage: stageSelected,
  })
  const [triggerDone, setTriggerDone] = useState(false)
  const [addSecondaryTrigger, setAddSecondaryTrigger] = useState(false)
  const [secondaryTrigger, setSecondaryTrigger] = useState({})
  const [secondaryTriggerDone, setSecondaryTriggerDone] = useState(false)

  const handleRadioChange = (e) => {
    setAddSecondaryTrigger(e.target.value)
  }

  const askForSecondaryTrigger = (
    <>
    <Row type='flex' justify='start' align='middle' style={{ marginTop: '1em', rowGap: '8px' }}>
      <Text>¿Deseas agregar una automatización para los candidatos que no cumplen con las condiciones que configuraste anteriormente?</Text>
      <Row>
        <Radio.Group onChange={handleRadioChange} defaultValue={false}>
          <Radio.Button value={true}>Sí</Radio.Button>
          <Radio.Button value={false}>No</Radio.Button>
        </Radio.Group>
      </Row>
    </Row>
    </>
  )
  

  return(
    <>
      {!triggerDone ?
      <TriggerForm 
        goBack={goBack} 
        stages={stages}
        selectedStage={stageSelected}
        tags={tags} 
        finish={() => setTriggerDone(true)}
        trigger={trigger}
        setTrigger={setTrigger}
        evaluations={evaluations}
        /> : null
      }
      
      {triggerDone && addSecondaryTrigger && !secondaryTriggerDone ?
        <TriggerForm 
          goBack={goBack} 
          stages={stages} 
          selectedStage={stageSelected}
          tags={tags} 
          finish={() => setSecondaryTriggerDone(true)} 
          setTrigger={setTrigger} 
          isSecondary={addSecondaryTrigger} 
          secondaryOptions={{
            "when": trigger.when,
            "conditions": trigger.conditions,
            "conditionOperator": trigger.conditionOperator,
          }}
        />
        : null
      }
      {((triggerDone && secondaryTriggerDone && addSecondaryTrigger) || (triggerDone && !addSecondaryTrigger)) ?
        <TriggerSummary trigger={trigger} stages={stages} cutes={evaluations} repeatedActions={[]}/> : null
      }
      {triggerDone && trigger.conditions.length > 0 && configurations[trigger.when.value].secondary && !secondaryTriggerDone ? askForSecondaryTrigger : null}
      {((triggerDone && secondaryTriggerDone && addSecondaryTrigger) || (triggerDone && !addSecondaryTrigger)) ?
        <>
          <Row type='flex' justify='end' align='middle' style={{ marginTop: '1em'}} gutter={[10, 10]}>
            <Col>
              <Button type='secondary' onClick={() => setTriggerDone(false)}>Volver</Button>
            </Col>
            <Col>
              <Button type='primary' onClick={() =>  {
                  setTriggers((oldState) => [...oldState, trigger])
                  finish();
                }}>
                  Confirmar
                </Button>
            </Col>
          </Row>
        </> 
        : null
      }
      </>
  )
}

