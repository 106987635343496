import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { push } from 'react-router-redux';

import PropTypes from "prop-types";
import * as actionCreators from "../../actions/data"
import './BusinessPermissionManager.scss';

import {
  Breadcrumb,
  Button,
  Card,
  Checkbox,
  Col,
  Icon,
  Layout,
  Row,
  Typography,
} from 'antd';

const { Text, Title } = Typography;

const BusinessPermissionManager = (props) => {

  const [roles, setRoles] = useState([]);
  const [currentRole, setCurrentRole] = useState({});

  useEffect(() => {
    let endpointRoles = `/api/v1/permissions/roles/`;
    props.actions.fetchAndReturn(
      props.token, endpointRoles
    ).then(response => {
      if (response.status < 400) {
        setRoles(response.body.results)
        if (response.body.results.length > 0) {
          setCurrentRole(response.body.results[0])
        }
      }
    });

  }, []);

  return (
    <Layout>
      <Row className="gw-bm">
        <Breadcrumb separator=">" style={{ margin: 24 }}>
          <Breadcrumb.Item href="/home">Inicio</Breadcrumb.Item>
          <Breadcrumb.Item href="/business/users">Usuarios</Breadcrumb.Item>
          <Breadcrumb.Item>Roles</Breadcrumb.Item>
        </Breadcrumb>

        <Card
          title={
            <Row type="flex" justify="left" align='middle'>
              <Title>
                <Icon style={{ fontSize: 24 }} onClick={() => props.dispatch(push('/business/users'))} type="arrow-left" />
                Roles y permisos
              </Title>
            </Row>
          }
        >
          <div style={{ marginBottom: 20 }}>
            <Text>
              Establece roles y permisos de acceso para los usuarios de tu equipo.
            </Text>
          </div>

          <Row gutter={[20]}>
            <Col md={8}>
              <div className='business-role-section'>
                <div className='role-section-title'>
                  Roles
                </div>
                <Row type='flex' align='top'>
                  {
                    /** 
                    <Col md={24}>
                      <Button className='role-section-button' type='primary'>
                        Crear nuevo rol
                      </Button>
                    </Col>
                     */
                  }
                  {
                    roles.map(obj =>
                      <Col md={24}>
                        <Button className='role-section-button' onClick={() => setCurrentRole(obj)}>
                          {obj.name}
                        </Button>
                      </Col>
                    )
                  }

                </Row>
              </div>
            </Col>
            <Col md={16}>
              <div className='business-role-section'>
                <div className='role-section-title'>
                  {`Permisos: ${currentRole.name}`}
                </div>
                <div>
                  {Object.keys(currentRole).filter(elem => elem.includes('can_')).map((key, index) => (
                    <Checkbox checked={currentRole[key]} style={{ width: '100%', margin: 0 }}>{key}</Checkbox>
                  ))}
                </div>

              </div>
            </Col>
          </Row>
        </Card>

      </Row>
    </Layout>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch
  };
};

const mapStateToProps = (state) => {
  return {
    businessPermissions: state.auth.businessPermissions,
    business: state.auth.currentBusiness,
    contract: state.auth.contract,
    token: state.auth.token,
    userAvatar: state.auth.userAvatar,
    userID: state.auth.userID,
    userName: state.auth.userName,
  };
};

BusinessPermissionManager.propTypes = {
  userAvatar: PropTypes.string.isRequired,
  userID: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  businessPermissions: PropTypes.shape({
    business_admin: PropTypes.bool.isRequired,
    can_manage: PropTypes.bool.isRequired,
    can_invite: PropTypes.bool,
    can_create_cell: PropTypes.bool,
    can_view_all_jobapps: PropTypes.bool,
  }),
  business: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  token: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessPermissionManager);
