import React, { useState, useContext, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import { CandidateContext } from "../config/CandidateContext";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../actions/data";

import {
  Button,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Popover,
  Row,
  Typography,
} from "antd";

import MceEditor from "../../BusinessDashboard/NewProcessFormV2/components/mceeditor/MceEditor";

import i18n from "../../../i18n";

const { Text } = Typography;

const SendEmail = (props) => {
  const { getFieldDecorator } = props.form;
  const {
    application: { candidate_type },
    candidate: { name, email, user_name },
    fetchMailHistory,
    handleEmailView,
    jobApp: { id: job_application_id },
    mailTemplateCategories,
    mailTemplates,
    // Redux
    actions,
    token,
  } = useContext(CandidateContext);

  const [newEmail, setNewEmail] = useState({
    from: "no-reply@genoma.work",
    cc: "",
    bcc: [],
    subject: "",
    body: "",
    fileList: [],
    replacedBody: "",
    job_application_id,
  });
  const captchaRef = useRef(null);
  const mailRef = useRef(null);
  const [error, setError] = useState("");
  const [sending, setSending] = useState(false);
  const [templates, setTemplates] = useState({ show: false, list: [] });

  const [newTemplateModalVisible, setNewTemplateModalVisible] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState("");

  const handleShowTemplates = () =>
    setTemplates((state) => ({ ...state, show: !state.show }));

  const handleChange = (e) => {
    e.persist();
    if (e.target.name === "bcc") {
      setNewEmail((form) => ({ ...form, [e.target.name]: [e.target.value] }));
    } else {
      setNewEmail((form) => ({ ...form, [e.target.name]: e.target.value }));
    }
  };

  const handleAttachFile = (info) => {
    const { file } = info;
    let fileList;
    if (info.file.status === "removed") {
      const uid = file.uid;
      fileList = newEmail.fileList.filter((x) => x.uid !== uid);
    } else {
      const fileData = {
        uid: file.uid,
        name: file.name,
        size: file.size,
        type: file.type,
        ...file.response,
      };
      fileList = [...newEmail.fileList, fileData];
    }
    setNewEmail((email) => ({ ...email, fileList }));
  };

  const sendMail = async (mail) => {
    // Get captcha token first
    const captchaToken = await captchaRef.current.executeAsync();

    const emptyFields = !mail.body.trim() || !mail.subject.trim();

    if (emptyFields)
      return setError(i18n.t('profile__fill_mail_fields', {ns: 'candidateProfile'}));

    const url = "/api/v1/gamesandtests/sendmail/";
    const user = { name: user_name, email: email, candidate_type };
    mail.users = [user];
    mail.attachments = mail.fileList;
    // Filter empty strings from bcc array
    mail.bcc = mail.bcc.filter((item) => item);

    setSending(true);

    actions
      .fetchAndReturn(token, url, "post", (mail), {Captcha: captchaToken})
      .then((resp) => {
        if (resp.status < 400) {
          message.success(i18n.t('profile__message_sent', { ns: 'candidateProfile' }));
        } else {
          message.error(i18n.t('profile__could_not_send_message', { ns: 'candidateProfile' }));  
        }
        handleEmailView.toggle();
      })
      .catch((e) => {
        message.error(i18n.t('profile__could_not_send_message', { ns: 'candidateProfile' }));
      })
      .finally(() => {
        setSending(false);
        fetchMailHistory();
      });
  };

  const changeTemplate = (template) => {
    setNewEmail((email) => ({
      ...email,
      subject: template.subject,
      body: template.template,
    }));
  };

  const showDeleteConfirm = (template) => {
    Modal.confirm({
      title: `${i18n.t('profile__delete_template_confirmation', {ns:'candidateProfile'})} ${template.name}?`,
      okText:  i18n.t('commons__yes', {ns:'candidateProfile'}),
      okType: "danger",
      cancelText: i18n.t('commons__no', {ns:'candidateProfile'}),
      onOk: () => deleteTemplate(template.id),
      onCancel() {},
    });
  };

  const deleteTemplate = async (id) => {
    try {
      const url = `accounts/business/${props.business.id}/mailtemplates/${id}/`;
      await actions.sendData(token, url, JSON.stringify({ id }), "delete", "");
      setTemplates((templates) => ({
        ...templates,
        list: templates.list.filter((template) => template.id !== id),
      }));
      message.success(i18n.t('profile__templete_was_removed', {ns:'candidateProfile'}));
    } catch (err) {
      message.error(i18n.t('profile__deleted_template_error', {ns:'candidateProfile'}));
    }
  };

  const updateNewTemplateName = (value) => {
    setNewTemplateName(value);
  };

  const saveTemplate = () => {
    const emptyFields = !newEmail.body.trim() || !newEmail.subject.trim();
    if (emptyFields) {
      setError(i18n.t('profile__you_must_fill_fields', {ns:'candidateProfile'}));
    } else {
      const url = `/api/v1/accounts/business/${props.business.id}/mailtemplates/`;
      const data = {
        template: newEmail.body,
        subject: newEmail.subject,
        name: newTemplateName,
      };
      actions
        .fetchAndReturn(token, url, 'POST', data)
        .then((response) => {
          if (response.status < 400){
            const newtemplateList = {
              ...templates,
              list: [...templates.list, response.body],
            };
            setTemplates(newtemplateList);
            setNewTemplateName("");
            setNewTemplateModalVisible(false);
            message.success(i18n.t('profile__template_saved', {ns: 'candidateProfile'}));
          }
        })
        .catch((e) => {
          setNewTemplateName("");
          setNewTemplateModalVisible(false);
          message.error(i18n.t('profile__could_not_save_template', {ns: 'candidateProfile'}));
          setSending(false);
        });
    }
  };

  const insertNameKey = () => {
    const body =
      newEmail.body.indexOf("</p>") > 0
        ? `${newEmail.body.slice(0, newEmail.body.length - 4)} ${name} </p>`
        : `<p>${name} </p>`;
    setNewEmail((email) => ({ ...email, body: body }));
  };

  useEffect(() => {
    if (mailTemplates.length) {
      setTemplates((prevState) => ({
        ...prevState,
        list: [...mailTemplates],
      }));
    }
  }, [mailTemplates]);

  return (
    <div className="email-view" ref={mailRef}>
      <Text strong>{i18n.t('profile__send_email_to', {ns: 'candidateProfile'})} {name}</Text>
      <div>
        <label htmlFor="from">{i18n.t('profile__from', {ns: 'candidateProfile'})}</label>
        <Input
          name="from"
          id="from"
          value={newEmail.from}
          type="text"
          disabled={true}
        />
      </div>
      <div>
        <label htmlFor="cc">CC</label>
        <Form.Item>
          {getFieldDecorator("email", {
            value: newEmail.cc,
            rules: [
              {
                required: false,
                type: "email",
                message: i18n.t('profile__enter_valid_email', {ns: 'candidateProfile'}),
              },
            ],
          })(<Input name="cc" id="cc" onChange={handleChange} type="mail" />)}
        </Form.Item>
      </div>
      <div>
        <label htmlFor="bcc">CCO</label>
        <Form.Item>
          {getFieldDecorator("bcc", {
            value: newEmail.bcc,
            rules: [
              {
                required: false,
                type: "email",
                message:i18n.t('profile__enter_valid_email', {ns: 'candidateProfile'}),
              },
            ],
          })(<Input name="bcc" id="bcc" onChange={handleChange} type="mail" />)}
        </Form.Item>
      </div>
      <div>
        <label htmlFor="subject">
          <Text type="danger">*</Text>
          {i18n.t('profile__mail_subject', {ns: 'candidateProfile'})}
        </label>
        <Input
          name="subject"
          id="subject"
          onChange={handleChange}
          value={newEmail.subject}
          type="text"
        />
      </div>
      <div>
        <label htmlFor="body">
          <Text type="danger">*</Text>
          {` ${
            i18n
              .t("profile__content", { ns: "candidateProfile" })
              .charAt(0)
              .toUpperCase() +
            i18n.t("profile__content", { ns: "candidateProfile" }).slice(1)
          }`}
          :
        </label>
        <MceEditor
          handleAttachFile={handleAttachFile}
          name="body"
          body={newEmail.body}
          handleChange={() => (e) => {
            setNewEmail((lastEmail) => {
              return {
                ...lastEmail,
                body: e.target.value,
              };
            });
          }}
          token={token}
        />
      </div>
      <Text type="danger">{error}</Text>
      <div className="email-view__buttons">
        <Row type="flex" style={{ gap: "0.5rem" }}>
          <Popover
            width={350}
            content={
                <Menu
                  style={{ width: 256, height: '40vh', overflow: 'scroll' }}
                >
                  {mailTemplateCategories.map((category) => (
                    <Menu.SubMenu
                      key={category.id}
                      title={category.name}
                      popupClassName='mail-template-submenu'
                    >
                      {templates.list
                        .filter(
                          (template) =>
                            template.category === category.id
                        )
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((template, i) => (
                          <Menu.Item
                            key={i}
                            onClick={() => changeTemplate(template)}
                            style={{ cursor: 'pointer' }}
                          >
                            {template.name}
                          </Menu.Item>
                        ))}
                    </Menu.SubMenu>
                  ))}
                  <Menu.SubMenu
                    key={'nocat'}
                    title={'Sin categoría'}
                    popupClassName='mail-template-submenu'
                  >
                    {templates.list
                      .filter((template) => !template.category)
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((template, i) => (
                        <Menu.Item
                          key={i}
                          onClick={() => changeTemplate(template)}
                          style={{ cursor: 'pointer' }}
                        >
                          {template.name}
                        </Menu.Item>
                      ))}
                  </Menu.SubMenu>
                  <Menu.SubMenu
                    key={'other'}
                    title={'Otras plantillas'}
                    popupClassName='mail-template-submenu'
                  >
                    {templates.list.filter(
                      (template) => template.category !== null && 
                        !mailTemplateCategories
                          .map((category) => category.id)
                          .includes(template.category)
                    ).map((template, i) => (
                      <Menu.Item
                        key={i}
                        onClick={() => changeTemplate(template)}
                        style={{ cursor: 'pointer' }}
                      >
                        {template.name}
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                </Menu>
            }
            title={
              <Text strong>
                {i18n.t("profile__my_templates", { ns: "candidateProfile" })}
              </Text>
            }
            trigger="click"
            onCancel={(visible) =>
              setTemplates({ ...templates, show: visible })
            }
          >
            <Button
              icon="table"
              disabled={!templates.list.length}
              onClick={handleShowTemplates}
            >
              {i18n.t("profile__see_my_templates", { ns: "candidateProfile" })}
            </Button>
          </Popover>
          <Button icon="save" onClick={() => setNewTemplateModalVisible(true)}>
            {i18n.t("profile__save_template", { ns: "candidateProfile" })}
          </Button>
          <Button icon="user-add" onClick={insertNameKey}>
            {i18n.t("profile__insert_name", { ns: "candidateProfile" })}
          </Button>
        </Row>
        <Modal
          title={i18n.t("profile__save_template", { ns: "candidateProfile" })}
          okText={i18n.t("profile__save", { ns: "candidateProfile" })}
          okType={"primary"}
          cancelText={i18n.t("profile__cancel", { ns: "candidateProfile" })}
          visible={newTemplateModalVisible}
          onOk={() => saveTemplate()}
          onCancel={() => setNewTemplateModalVisible(false)}
        >
          <Form>
            <Form.Item
              label={i18n.t("profile__name_of_template", {
                ns: "candidateProfile",
              })}
            >
              <Input
                onChange={(event) => {
                  updateNewTemplateName(event.target.value);
                }}
                placeholder={i18n.t("profile__template_name", {
                  ns: "candidateProfile",
                })}
              />
            </Form.Item>
          </Form>
        </Modal>
        <ReCAPTCHA
          ref={captchaRef}
          size="invisible"
          sitekey={process.env.REACT_APP_CAPTCHA_API_KEY}
          onChange={() => {}}
        />
        <Row type="flex" style={{ gap: "0.5rem" }}>
          <Button type="link" onClick={handleEmailView.toggle}>
            {i18n.t("profile__cancel", { ns: "candidateProfile" })}
          </Button>
          <Button
            loading={sending}
            onClick={() => sendMail(newEmail)}
            style={{ borderColor: "#fca0d3" }}
          >
            <Text strong style={{ color: "#fca0d3" }}>
              {i18n.t("profile__sent_to", { ns: "candidateProfile" })}
            </Text>
          </Button>
        </Row>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

const mapStateToProps = (state) => {
  return {
    business: state.auth.currentBusiness,
  };
};

const SendEmailView = Form.create({ name: "mailForm" })(SendEmail);
export default connect(mapStateToProps, mapDispatchToProps)(SendEmailView);
