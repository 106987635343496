import { Button, Col, Row, TimePicker, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

import i18n from '../../i18n';
import moment from 'moment';

const { Title, Text } = Typography;

const daysOfWeek = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const oneHotToHourOfDay = (oneHot, end = false) => {
  const adjustedOneHot = end ? oneHot + 1 : oneHot;
  const hour = Math.floor(adjustedOneHot / 4);
  const minutes = (adjustedOneHot % 4) * 15;
  return [hour, minutes];
};

const hourOfDayToOneHot = (hour, minutes, end = false) => {
  // Blocks of time are 15 minutes
  return hour * 4 + minutes / 15 - end * 1;
};

const BlockComponent = ({
  day,
  handleScheduleChangeBlock,
  handleAddBlock,
  handleDeleteBlock,
  isLastBlock,
  block,
  key,
}) => {
  const [currentBlock, setCurrentBlock] = useState(block);

  const handleTemporaryBlockChange = (day, position) => (time, timeString) => {
    const newBlock = structuredClone(currentBlock);

    newBlock[position] = timeString.split(':').map((x) => Number.parseInt(x));

    setCurrentBlock(newBlock);

    const startOneHot = hourOfDayToOneHot(newBlock.start[0], newBlock.start[1]);
    const endOneHot = hourOfDayToOneHot(newBlock.end[0], newBlock.end[1], true);
    if (endOneHot < startOneHot) {
      newBlock.end = [...newBlock.start];
    }
    if (endOneHot >= startOneHot) {
      handleScheduleChangeBlock(day, block, newBlock);
    }
  };

  return (
    <div key={key} style={{ display: 'flex', gap: '0.5rem' }}>
      <TimePicker
        onChange={handleTemporaryBlockChange(day, 'start')}
        value={moment(
          `${currentBlock.start[0]}:${currentBlock.start[1]}`,
          'HH:mm'
        )}
        minuteStep={15}
        format="HH:mm"
        inputReadOnly
        allowClear={false}
        style={{ width: 180 }}
      />

      <TimePicker
        onChange={handleTemporaryBlockChange(day, 'end')}
        value={moment(`${currentBlock.end[0]}:${currentBlock.end[1]}`, 'HH:mm')}
        minuteStep={15}
        format="HH:mm"
        inputReadOnly
        allowClear={false}
        disabledHours={() =>
          Array(24)
            .fill(0)
            .map((_, i) => i)
            .filter((h) => h < currentBlock.start[0])
        }
        style={{ width: 180 }}
      />

      <Button
        style={{ borderRadius: '66%' }}
        icon="minus"
        onClick={() => handleDeleteBlock(day, block)}
      />
      {isLastBlock ? (
        <Button
          style={{ borderRadius: '66%' }}
          icon="plus"
          onClick={() => handleAddBlock(day, block)}
        />
      ) : null}
    </div>
  );
};

// basically the Schedule component but with a different style
const Availability = ({
  handleChange,
  index,
  question,
  initialSchedule = null,
  isNew = false,
  ...props
}) => {
  const [schedule, setSchedule] = useState(
    () =>
      initialSchedule ?? {
        monday: Array(96).fill(0),
        tuesday: Array(96).fill(0),
        wednesday: Array(96).fill(0),
        thursday: Array(96).fill(0),
        friday: Array(96).fill(0),
        saturday: Array(96).fill(0),
        sunday: Array(96).fill(0),
      }
  );

  useEffect(() => {
    // Initialize with at least one block each day.
    if (isNew === true) {
      const newSchedule = structuredClone(schedule);
      daysOfWeek.forEach((day) => {
        newSchedule[day][0] = 1;
      });
      setSchedule(newSchedule);
    }
  }, []);

  const replicateSchedule = (day) => () => {
    const newSchedule = structuredClone(schedule);
    daysOfWeek.map((d) => {
      if (d !== day) {
        newSchedule[d] = [...newSchedule[day]];
      }
    });
    setSchedule(newSchedule);
  };

  const handleAddBlock = (day, selectedBlock) => {
    const newSchedule = structuredClone(schedule);
    const endOneHot = hourOfDayToOneHot(
      selectedBlock.end[0],
      selectedBlock.end[1],
      true
    );
    let newIndex = endOneHot + 2;
    while (
      (newSchedule[day][newIndex] === 1 ||
        newSchedule[day][newIndex + 1] === 1) &&
      newIndex < 96
    ) {
      newIndex++;
    }
    newSchedule[day][newIndex] = 1;
    setSchedule(newSchedule);
  };

  const handleAddFirstBlock = (day) => {
    const newSchedule = structuredClone(schedule);
    newSchedule[day][0] = 1;
    setSchedule(newSchedule);
  };

  const handleScheduleChangeBlock = (day, oldBlock, newBlock) => {
    const newSchedule = structuredClone(schedule);
    const oldStartOneHot = hourOfDayToOneHot(
      oldBlock.start[0],
      oldBlock.start[1]
    );
    const oldEndOneHot = hourOfDayToOneHot(
      oldBlock.end[0],
      oldBlock.end[1],
      true
    );
    newSchedule[day].fill(0, oldStartOneHot, oldEndOneHot + 1);

    const startOneHot = hourOfDayToOneHot(newBlock.start[0], newBlock.start[1]);
    const endOneHot = hourOfDayToOneHot(newBlock.end[0], newBlock.end[1], true);
    newSchedule[day].fill(1, startOneHot, endOneHot + 1);

    setSchedule(newSchedule);
  };

  const handleDeleteBlock = (day, block) => {
    const newSchedule = structuredClone(schedule);
    const startOneHot = hourOfDayToOneHot(block.start[0], block.start[1]);
    const endOneHot = hourOfDayToOneHot(block.end[0], block.end[1], true);
    newSchedule[day].fill(0, startOneHot, endOneHot + 1);
    setSchedule(newSchedule);
  };

  const dayBlocks = useMemo(() => {
    const blocks = {};
    let inABlock = false;
    let newBlock;
    daysOfWeek.forEach((day) => {
      blocks[day] = [];
      schedule[day].forEach((oneHot, index) => {
        if (oneHot) {
          if (!inABlock) {
            inABlock = true;
            newBlock = {
              start: [],
              end: [],
            };
            newBlock.start = oneHotToHourOfDay(index);
            newBlock.end = oneHotToHourOfDay(index, true);
          } else {
            newBlock.end = oneHotToHourOfDay(index, true);
          }
        } else if (inABlock) {
          inABlock = false;
          blocks[day].push(newBlock);
        }
      });
    });
    return blocks;
  }, [schedule]);

  useEffect(() => {
    handleChange(index, schedule);
  }, [schedule]);

  return (
    <Row type="flex" style={{ flexDirection: 'column', gap: '1rem' }}>
      {daysOfWeek.map((day) => {
        const atLeastOne = dayBlocks[day].length > 0;
        return (
          <div style={{ width: 'fit-content' }}>
            <Row type="flex" align="middle" justify="space-between">
              <Text strong>{i18n.t(`commons__dayofweek__${day}`)}</Text>
              <Button
                onClick={replicateSchedule(day)}
                type="link"
                icon="copy"
                style={{ color: '#F175A5', padding: 0 }}
              >
                Copiar horario
              </Button>
            </Row>
            <div>
              {atLeastOne ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                  }}
                >
                  {dayBlocks[day].map((block, index) => {
                    const isLastBlock = index === dayBlocks[day].length - 1;
                    return (
                      <BlockComponent
                        key={crypto.randomUUID()}
                        day={day}
                        block={block}
                        handleScheduleChangeBlock={handleScheduleChangeBlock}
                        handleAddBlock={handleAddBlock}
                        handleDeleteBlock={handleDeleteBlock}
                        isLastBlock={isLastBlock}
                      />
                    );
                  })}
                </div>
              ) : (
                <Row type="flex" align="middle" style={{ gap: '0.5rem' }}>
                  <Text
                    style={{
                      padding: '0.25rem 0.5rem',
                      borderRadius: 2,
                      border: '1px solid #e5e5e5',
                    }}
                  >
                    Día no disponible
                  </Text>
                  <Button
                    style={{ borderRadius: '66%' }}
                    icon="plus"
                    onClick={() => handleAddFirstBlock(day)}
                  />
                </Row>
              )}
            </div>
          </div>
        );
      })}
    </Row>
  );
};

export default Availability;
