import React, { useContext, useRef, useEffect } from 'react';
import {
  Icon,
  Modal,
  Row,
  Col,
  Typography,
  Input,
  Button,
  Select,
  Tag,
  Tooltip,
} from 'antd';
import MceEditor from '../../NewProcessFormV2/components/mceeditor/MceEditor';
import MailTemplatesContext from '../context/mailTemplates';

import '../template-mails.scss';

const { Text } = Typography;

const EditModalTemplate = () => {
  const { editModal, categories } = useContext(MailTemplatesContext);

  const insertKey = (type) => {
    switch (type) {
      case 'name':
        window.tinymce.activeEditor.execCommand(
          'mceInsertContent',
          false,
          ' --name-- '
        );
        return;
      case 'processName':
        window.tinymce.activeEditor.execCommand(
          'mceInsertContent',
          false,
          ' --processName-- '
        );
        return;
    }
  };

  const {
    duplicateTemplate,
    handleEditTemplateChange,
    setDuplicateTemplate,
    setOpenEditModal,
    openEditModal,
    editMailTemplateFn,
    inputState,
    setInputState,
  } = editModal;
  const inputEl = useRef(null);

  const handleClose = (removedTag) => {
    const tags = inputState.tags.filter((tag) => tag !== removedTag);
    setInputState({ ...inputState, tags });
  };

  const showInput = () => {
    setInputState({ ...inputState, inputVisible: true });
  };

  const handleInputChange = (e) => {
    setInputState({ ...inputState, inputValue: e.target.value });
  };

  const handleInputConfirm = () => {
    const { inputValue } = inputState;
    let { tags } = inputState;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }
    setInputState({
      tags,
      inputVisible: false,
      inputValue: '',
    });
    setDuplicateTemplate((prevState) => ({ ...prevState, tags }));
  };

  useEffect(() => {
    inputEl.current && inputEl.current.focus();
  }, [inputState.inputVisible]);

  return (
    <Modal
      afterClose={() =>
        setInputState({
          tags: [],
          inputVisible: false,
          inputValue: '',
        })
      }
      title='Editar plantilla'
      visible={openEditModal}
      width='56.188rem'
      okText='Guardar plantilla'
      cancelText='Cancelar'
      centered={true}
      onOk={() => editMailTemplateFn(duplicateTemplate)}
      onCancel={() => {
        setDuplicateTemplate({});
        setOpenEditModal(false);
      }}
    >
      <Row>
        <Row className='template-container__modal-preview-row'>
          <Col>
            <Text strong>Nombre de la plantilla</Text>
          </Col>
          <Col>
            <Input
              placeholder='Nombre de la plantilla'
              value={duplicateTemplate?.name}
              onChange={handleEditTemplateChange('name')}
            />
          </Col>
        </Row>
        <Row className='template-container__modal-preview-row'>
          <Col>
            <Text strong>Asunto</Text>
          </Col>
          <Col>
            <Input
              placeholder='Asunto'
              value={duplicateTemplate?.subject}
              onChange={handleEditTemplateChange('subject')}
            />
          </Col>
        </Row>
        <Row className='template-container__modal-preview-row'>
          <Col>
            <Text strong>Texto</Text>
          </Col>
          <MceEditor
            name='template'
            handleChange={handleEditTemplateChange}
            body={duplicateTemplate?.template}
          />
        </Row>
        <Row className='template-container__modal-preview-row' type='flex'>
          <Button icon='user' onClick={() => insertKey('name')}>
            Insertar nombre del candidato
          </Button>
          <Button icon='form' onClick={() => insertKey('processName')}>
            Insertar nombre del proceso
          </Button>
        </Row>
        <Row>
          <Text strong>Categoría</Text><br/>
          <Select style={{ minWidth: 200 }} value={duplicateTemplate?.category || undefined} onChange={(value) => handleEditTemplateChange('category')({ target: { value } })} placeholder="Categoría">
            {categories.data.map((category) => (
                // id, name, slug
              <Select.Option key={category.id} value={category.id}>{category.name}</Select.Option>
            ))}
          </Select>
        </Row>
        <Row className='template-container__modal-preview-row' gutter={[0, 10]}>
          <Col>
            <Text strong>Etiquetas</Text>
          </Col>
          <Col>
            {inputState.inputVisible && (
              <Input
                ref={inputEl}
                type='text'
                size='small'
                style={{ width: 78, margin: '0 10px 0 0' }}
                value={inputState.inputValue}
                onChange={handleInputChange}
                onBlur={handleInputConfirm}
                onPressEnter={handleInputConfirm}
              />
            )}
            {!inputState.inputVisible && (
              <Tag
                onClick={inputState.tags.length < 5 && showInput}
                className='template-container__tags'
                style={{ color: '#F175A5' }}
              >
                <Icon type='plus' /> Nueva etiqueta
              </Tag>
            )}
            {inputState.tags.map((tag, index) => {
              const isLongTag = tag.length > 20;
              const tagElem = (
                <Tag
                  key={index}
                  closable={true}
                  onClose={() => handleClose(tag)}
                >
                  {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                </Tag>
              );
              return isLongTag ? (
                <Tooltip title={tag} key={index}>
                  {tagElem}
                </Tooltip>
              ) : (
                tagElem
              );
            })}
          </Col>
        </Row>
      </Row>
    </Modal>
  );
};

export default EditModalTemplate;
