import React, { useState } from 'react';
import '../JobListing.scss';

import { Typography, Modal, Button } from 'antd';

const WelcomeText = () => {
  const [showNewSection, setShowNewSection] = useState(false);

  const showModal = () => setShowNewSection(true);

  const handleOk = () => setShowNewSection(false);

  return (
    <div className='rps__job-info'>
      <div onClick={showModal} style={{ cursor: "pointer" }}>
        <img 
          alt='¿Qué es esta nueva sección?' 
          src='https://genoma-assets.s3.us-east-2.amazonaws.com/jobsite-assets/queesestanuevaseccion.png'
        />
      </div>

      <Modal
        visible={showNewSection}
        width={700}
        footer={[
          <Button
            key="back"
            type="primary"
            onClick={handleOk}
          >
            Cerrar
          </Button>
        ]}
      >
        <div className='rps__job-info-text'>
          <div className='instructions-text'>
            <Typography.Paragraph>
              Esta nueva sección está diseñada para que le entregues más información a los postulantes 
              acerca de tu empresa: puedes dar una breve presentación de la compañía, hablar sobre su misión, 
              y contar un poco más acerca de lo que hacen. Toda esta información no es obligatoria, pero si
              será de gran ayuda para que los postulantes puedan conocer un poco sobre la compañía.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Recuerda que en esta sección deberás incluir información muy importante, como respuestas a ciertas 
              interrogantes que seguramente se harán tus postulantes. Por ejemplo: ¿Quiénes son y a qué se dedican?, 
              ¿Qué te hace diferente, o qué te caracteriza?, ¿Cuál es la motivación de tu empresa?, entre otras.
            </Typography.Paragraph>
            <Typography.Paragraph strong> 
              Esta es la primera información que verán tus candidatos al ingresar al portal de empleos.
            </Typography.Paragraph>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default WelcomeText;
