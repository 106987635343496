import React, { useState, useEffect, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goBack, push } from 'react-router-redux';
import PropTypes from 'prop-types';

import * as authActionCreators from '../../actions/auth';
import * as dataActionCreators from '../../actions/data';

import CandidateLoginLayout from './components/CandidateLoginLayout';


import Footer from './components/Footer';
import LoginForm from './components/LoginForm';

import { Row, Col, Typography, Icon, Button } from 'antd';
import './Login.scss';
import i18n from '../../i18n';

const footerImg = 'https://genoma-assets.s3.us-east-2.amazonaws.com/footer_genomin.svg';
const whiteLogoImg = 'https://genoma-assets.s3.us-east-2.amazonaws.com/white_logo_genoma.svg';

const { Title } = Typography;
function BusinessLoginView(props) {
  const handleGoBack = () => {
    props.dispatch(goBack());
  };

  useEffect(() => {
    if (props.isAuthenticated) {
      props.dispatch(push('/home'));
    }
  }, []);

  const memoRender = useMemo(() => {
    return (
      <Row className="candidate-right-def">
        <Row
          type='flex'
          justify='center'
          align='middle'
          style={{ width: '100%', height: '20%' }}
        >
          <div
            style={{
              height: '61px',
              width: '266px',
              // Set background image
              backgroundImage: `url(https://genoma-assets.s3.us-east-2.amazonaws.com/Logo+Genoma.png)`,
              // Set background size
              backgroundSize: 'cover',
              // Make it a background image so it will repeat
              backgroundRepeat: 'no-repeat',
            }}
          />
        </Row>
        <Row style={{ width: '100%', height: '80%' }} type='flex' justify='center'>
          <div style={{
            borderRadius: '69px 69px 0px 0px',
            backgroundColor: '#f175a5',
            height: '100%',
            width: '90%',
            maxWidth: '789px',
            bottom: 0,
          }}>
            <Row
              type='flex'
              justify='center'
              align='middle'
              style={{ width: '100%', height: '30%', padding: 30 }}
            >
              <Title level={1} style={{ color: 'white', textAlign: 'center' }}>
                {i18n.t('login__business_welcome_text')}
              </Title>
            </Row>
            <Row
              type='flex'
              justify='center'
              align='middle'
              style={{ width: '100%', height: '70%' }}
            >
              <div
                style={{
                  height: '100%',
                  width: 'inherit',
                  // Set background image
                  backgroundImage: `url(https://genoma-assets.s3.us-east-2.amazonaws.com/login-business-image.png)`,
                  // Set background size
                  backgroundSize: 'contain',
                  // Make it a background image so it will repeat
                  backgroundRepeat: 'no-repeat',
                  // Make the background stick to the bottom
                  backgroundPosition: 'center'
                }}
              />
            </Row>
          </div>
        </Row>
      </Row>
    );
  }, []);

  const colors = {
    background: '#F175A5',
      color: '#FFFFFF'
  }

  return (
    <>
      <CandidateLoginLayout rightSide={memoRender}>
        <Col xs={24} sm={12} className="candidate-login__left-side">
          <Row
            className="candidate-login__left-side__header"
          >
            <Button onClick={handleGoBack} type="link">
              <Icon type="left"></Icon>
            </Button>
            <Typography.Title level={4}>{i18n.t('commons__company')}</Typography.Title>
            <Row>
              <Typography.Title level={1}>{i18n.t('commons__sign_in')}</Typography.Title>
            </Row>
          </Row>
          <Row className="candidate-login__left-side__login-form">
            <Col xs={24}>
              <LoginForm redirect="/home" colors={colors}/>
            </Col>
          </Row>
          <Footer />
        </Col>
      </CandidateLoginLayout>
    </>
  );
}

BusinessLoginView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isAuthenticating: PropTypes.bool.isRequired,
  statusText: PropTypes.string,
  authActions: PropTypes.shape({
    authLoginUser: PropTypes.func.isRequired,
  }).isRequired,
  dataActions: PropTypes.any,
  alerts: PropTypes.any,
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isAuthenticating: state.auth.isAuthenticating,
    statusText: state.auth.statusText,
    alerts: state.alerts,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    authActions: bindActionCreators(authActionCreators, dispatch),
    dataActions: bindActionCreators(dataActionCreators, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BusinessLoginView);
export { BusinessLoginView as BusinessLoginViewNotConnected };
