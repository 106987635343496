import React, { useState, useEffect, useContext } from "react";

import {
  Button,
  Col,
  Icon,
  Input,
  Modal,
  Row,
  Table,
  Tag,
  Typography,
} from "antd";

import EvaluationCard from "../EvaluationCard";
import JobAppContext from "../../../context/JobAppContext";
import FileRequestForm from "./components/FilesRequestForm";
import "../EvaluationsStyles.scss";
import moment from "moment";
import { Buffer } from 'buffer';
import { v4 as uuidv4 } from 'uuid';
import i18n from "../../../../../../i18n";

const LOGO_DOCR = 'https://genoma-assets.s3.us-east-2.amazonaws.com/cute-icon-3.svg';

function AdditionalFilesRequest() {
  const {
    filesRequests,
    updateFilesRequests,
    saveFileRequestTemplate,
    currentStage,
    activities,
    jobApplication
  } = useContext(JobAppContext);

  const [availableFilesRequests, setAvailableFilesRequests] = useState([]);
  const [currentFilesRequests, setCurrentFilesRequests] = useState([]);
  const [createFilesRequest, setCreateFilesRequest] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [searchInput, setSearchInput] = useState(undefined);
  const [filteredFilesRequests, setFilteredFilesRequests] = useState([]);
  const [fileRequestActivity, setFileRequestActivity] = useState(undefined);

  useEffect(() => {
    setFileRequestActivity(activities.find((obj) => obj.code === "DOCR"));
  }, [activities]);

  const expandedRowRender = (record) => (
    <Row gutter={24} type="flex">
      <Col span={12}>
        <Row><Typography.Text strong>{i18n.t('commons__instructions')}</Typography.Text></Row>
        {record.request?.instruction?.split('\n')?.map((line) => (
          <Row><Typography.Text>{line}</Typography.Text></Row>
        ))}
      </Col>
      <Col span={12}>
        <Row><Typography.Text strong>Documentos a solicitar</Typography.Text></Row>
        {record.request.questions.map((e) => (
          <Row><Typography.Text><Icon type="check"/>{e.name}</Typography.Text></Row>
        ))}
        {/* Add additional content inside the collapsed row if needed */}
      </Col>
    </Row>
  );

  const expandedCurrentRowRender = (record) => (
    <Row gutter={24} type="flex">
      <Col span={12}>
        <Row><Typography.Text strong>{i18n.t('commons__instructions')}</Typography.Text></Row>
        {record.instruction?.split('\n')?.map((line) => (
          <Row><Typography.Text>{line}</Typography.Text></Row>
        ))}
      </Col>
      <Col span={12}>
        <Row><Typography.Text strong>Documentos a solicitar</Typography.Text></Row>
        {record.questions.map((e) => (
          <Row><Typography.Text><Icon type="check"/> {e.name}</Typography.Text></Row>
        ))}
        {/* Add additional content inside the collapsed row if needed */}
      </Col>
    </Row>
  );

  useEffect(() => {
    if (filesRequests) {
      let currentStageEvaluations = filesRequests.currentEvaluations.filter(
        (obj) => obj.stage === currentStage
      );
      if (currentFilesRequests.length === 0) {
        setCurrentFilesRequests([...currentStageEvaluations]);
      }
      const currentTemplates = filesRequests.currentEvaluations.map((obj) => obj.template);
      const addFilesRequests = [...filesRequests.allEvaluations].filter(
        (obj) => !currentTemplates.includes(obj.id)
      );
      setAvailableFilesRequests(addFilesRequests);
      setFilteredFilesRequests(addFilesRequests);
    }
  }, [isVisible, filesRequests]);

  useEffect(() => {
    if (isVisible === false) {
      setAvailableFilesRequests([]);
      setCurrentFilesRequests([]);
      setSearchInput(undefined);
      setFilteredFilesRequests([]);
    }
  }, [isVisible]);

  useEffect(() => {
    if (searchInput === undefined) return;
    const filtered = availableFilesRequests.filter((obj) =>
      obj.request?.name?.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilteredFilesRequests(filtered);
  }, [searchInput]);

  const filesRequestsDetail = () => {
    return (
      <div style={{ margin: "5px 0px 10px 5px" }}>
        <div style={{ marginBottom: 4 }}>
          <strong>Solicitudes seleccionadas:</strong>
        </div>
        {filesRequests.currentEvaluations
          .filter((obj) => obj.stage === currentStage)
          .map((obj) => (
            <div>{`• ${obj.name}`}</div>
          ))}
      </div>
    );
  };

  const customExpandIcon = (props) => {
    if (!props.expanded) {
      return (
        <a onClick={() => props.onExpand(props.panelKey)}>
          <Icon type="right" style={{ color: '#5E5E5E' }} />
        </a>
      );
    }
    return (
      <a onClick={() => props.onExpand(props.panelKey)}>
        <Icon type="down" style={{ color: '#5E5E5E' }} />
      </a>
    );
  };

  const onSave = () => {
    // Por cada elemento del modal ver si existe en el contexto, si no esta entonces hay que agregarlo
    const toAdd = [...filesRequests.toAdd];
    const toRemove = [...filesRequests.toRemove];
    const toUpdate = [...filesRequests.toUpdate];

    currentFilesRequests.forEach((current) => {
      const foundIdx = filesRequests.currentEvaluations.some(
        (obj) => obj.id === current.id && current.stage === currentStage
      );
      !foundIdx && current.id.includes('no__') && toAdd.push({...current});
    });

    // SACAR UNO QUE ESTÁ EN EL ESTADO
    filesRequests.currentEvaluations.forEach((current) => {
      if (current.stage !== currentStage) return;
      const found = currentFilesRequests.findIndex((obj) => obj.id === current.id);
      if (found === -1) {
        // Si lo removemos tambien debemos sacarlo de las cutes a updatear
        toRemove.push(current);
      } else {
        const toUpdateIdx = toUpdate.findIndex(obj => obj.id === current.id)
        if (toUpdateIdx !== -1) toUpdate.splice(toUpdateIdx, 1);
      }
    });

    // Check if all elements in toAdd are added in localEvaluations
    toAdd.forEach((current, idx) => {
      if (current.stage !== currentStage) return;
      if (currentFilesRequests.findIndex(obj => current.id === obj.id) === -1) {
        toAdd.splice(idx, 1);
      }
    });

    filesRequests.currentEvaluations.forEach((fileRequest) => {
      if (!fileRequest.stage) {
        const found = toRemove.findIndex((obj) => obj.id === fileRequest.id);
        if (found === -1 && !fileRequest.id.includes('no__')) toRemove.push(fileRequest);
      }
    });

    // Check if we remove og file requests and add again, remove repeated elements in toAdd and to Remove
    toRemove.forEach((current, idx) => {
      const addIndex = toAdd.findIndex(obj => current.id === obj.id);
      const updateIndex = toUpdate.findIndex(obj => current.id === obj.id);
      if (addIndex !== -1 && current.stage === currentStage) {
        toRemove.splice(idx, 1);
        toAdd.splice(addIndex, 1);
      } else if (updateIndex === -1 && current.stage !== currentStage) {
        toRemove.splice(idx, 1);
        current.stage = currentStage;
        toUpdate.push(current);
      }
    });

    const ids = new Set(filesRequests.currentEvaluations.map(obj => obj.id));
    const removeIds = new Set(toRemove.map(obj => obj.id));
    const updateCurrentEvals = [
      ...filesRequests.currentEvaluations.filter((obj) => !removeIds.has(obj.id) && obj.stage),
      ...currentFilesRequests.filter(obj => !ids.has(obj.id) && obj.stage),
    ];
    updateFilesRequests("toAdd", toAdd);
    updateFilesRequests("toRemove", toRemove.filter((e) => !e.id.includes('no__')));
    updateFilesRequests("toUpdate", toUpdate);
    updateFilesRequests("currentEvaluations", updateCurrentEvals);
    setIsVisible(false);
  };

  const addFilesRequest = (filesRequest) => {
    let idx = availableFilesRequests.indexOf(filesRequest);
    let tmpFilesRequest = {
      ...availableFilesRequests[idx].request,
      assigned_activity: {
        stage: currentStage,
        activity_id: fileRequestActivity.id,
        job_application_id: jobApplication.id
      },
      template: filesRequest.id,
      stage: currentStage,
    };
    let newFilesRequests;

    const currentFilesRequestsIds = currentFilesRequests.map((obj) => obj.id);
    const toRemoveIds = filesRequests.toRemove.map((obj) => obj.template);
    if (filesRequest.id && toRemoveIds.includes(filesRequest.id)) {
      tmpFilesRequest = {
        ...tmpFilesRequest,
        ...filesRequests.toRemove[toRemoveIds.indexOf(filesRequest.id)],
      };
    } else if (
      filesRequest.id &&
      !currentFilesRequestsIds.includes(filesRequest.id) &&
      idx !== -1
    ) {
      tmpFilesRequest.id = 'no__' + uuidv4();
    }
    newFilesRequests = [...currentFilesRequests, tmpFilesRequest];
    setCurrentFilesRequests(newFilesRequests);
    availableFilesRequests.splice(idx, 1);
    setAvailableFilesRequests(availableFilesRequests);
  };

  const onCancel = () => setCreateFilesRequest(false);

  const onSaveRequest = (formData, method, id, type) => {
    if (type === "template") {
      saveFileRequestTemplate(formData, method, id);
      setCreateFilesRequest(false);
    } else {
      const object = Object.fromEntries(formData);
      object.questions = JSON.parse(object.questions);
      object.stage = currentStage;
      object.id = 'no__'+uuidv4()
      const current = [...currentFilesRequests];
      current.push(object);
      setCurrentFilesRequests(current);
      setCreateFilesRequest(false);
    }
  };

  const removeFilesRequest = (filesRequest) => {
    const idx = currentFilesRequests.indexOf(filesRequest);
    // Remove logic
    if (filesRequest.template && filesRequests.allEvaluations.some((obj) => obj.id === filesRequest.template)) {
      const template = filesRequests.allEvaluations.filter((obj) => obj.id === filesRequest.template)[0];
      const newAvailableRequests = [...availableFilesRequests, template].sort(
        (a, b) => a.id.toString().localeCompare(b.id.toString())
      );
      setAvailableFilesRequests(newAvailableRequests);
    } else if (filesRequest.id && filesRequests.allEvaluations.some((obj) =>  obj.id === filesRequest.template)) {
      delete filesRequest.stage;
      const newAvailableRequests = [
        ...availableFilesRequests,
        filesRequest,
      ].sort((a, b) => a.id.toString().localeCompare(b.id.toString()));
      setAvailableFilesRequests(newAvailableRequests);
    } else {
      delete filesRequest.stage;
    }
    currentFilesRequests.splice(idx, 1);
    setCurrentFilesRequests(currentFilesRequests);
  };

  const templateColumns = [
    {
      title: "NOMBRE DE SOLICITUD",
      dataIndex: "request",
      key: "name",
      render: (item) => (<span>{item.name}</span>),
    },
    {
      title: "DOCUMENTOS AGREGADOS",
      dataIndex: "request",
      key: "files",
      render: (item) => item.questions.map((q) => (
        <Tag
          key={q.id}
          style={{
            maxWidth: '15em',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {q.name}
        </Tag>
      )),
    },
    {
      title: "FECHA DE CREACIÓN",
      dataIndex: "date_created",
      key: "dateCreated",
      sorter: (a, b) => {
        const firstDate = new Date(
          a.date_created.slice(6),
          a.date_created.slice(3, 5),
          a.date_created.slice(0, 2)
        );
        const secondDate = new Date(
          b.date_created.slice(6),
          b.date_created.slice(3, 5),
          b.date_created.slice(0, 2)
        );
        if (firstDate < secondDate) return -1;
        else if (firstDate > secondDate) return 1;
        else return 0;
      },
      render: (dateCreated) => (
        <Typography.Text>
          {moment(dateCreated, "DD-MM-YYYY HH:mm:ss Zz").local().format("DD/MM/YYYY")}
        </Typography.Text>
      ),
    },
    {
      title: "ACCIONES",
      key: "action",
      render: (item) =>
        currentFilesRequests.map((obj) => obj.template).includes(item.id) ? (
          <span>
            <Button
              type="secondary"
              className="additional-files-button"
              onClick={() => removeFilesRequest(item)}
            >
              <Icon type="plus" />
            </Button>
          </span>
        ) : (
          <span>
            <Button
              type="primary"
              className="additional-files-button"
              onClick={() => addFilesRequest(item)}
            >
              <Icon type="plus" />
            </Button>
          </span>
        ),
    },
  ];

  const columns = [
    {
      title: "NOMBRE DE SOLICITUD",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "DOCUMENTOS AGREGADOS",
      dataIndex: "questions",
      key: "files",
      render: (item) => item.map((q) => (
        <Tag
          key={q.id}
          style={{
            maxWidth: '15em',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {q.name}
        </Tag>
      )),
    },
    {
      title: "ACCIONES",
      key: "action",
      render: (item) =>
        currentFilesRequests.map((obj) => obj.template).includes(item.template) ? (
          <span>
            <Button
              type="secondary"
              className="additional-files-button"
              onClick={() => removeFilesRequest(item)}
            >
              <Icon type="minus" />
            </Button>
          </span>
        ) : (
          <span>
            <Button
              type="primary"
              className="additional-files-button"
              onClick={() => addFilesRequest(item)}
            >
              <Icon type="plus" />
            </Button>
          </span>
        ),
    },
  ];

  return (
    <>
      <EvaluationCard
        logo={LOGO_DOCR}
        evaluationTypeImg={[]}
        title="Solicitud de documentos"
        description="Solicita y gestiona los documentos que necesites de los candidatos."
        additionalInformation={[
          { value: "Todo tipo de cargos", icon: "user" },
          { value: "ES, EN, PT", icon: "flag" },
        ]}
        viewMoreContent={filesRequestsDetail}
        buttons={() => [
          <Button
            key="add"
            className="evaluation-add-button"
            onClick={() => setIsVisible(true)}
          >
            {filesRequests.currentEvaluations.filter((obj) => obj.stage === currentStage).length === 0
              ? (
                <>
                  <Icon type="plus" />
                  <span>Configurar y agregar</span>
                </>
              ) : (
                <>
                  <Icon type="check" />
                  <span>Editar</span>
                </>
              )}
          </Button>,
        ]}
        game={false}
      />

      <Modal
        title={
          <div style={{ fontSize: 18, fontWeight: 700 }}>
            Solicitud de documentos
          </div>
        }
        visible={isVisible}
        maskClosable={false}
        onOk={() => setIsVisible(false)}
        onCancel={() => setIsVisible(false)}
        cancelText={i18n.t('commons__cancel')}
        width={1000}
        forceRender={true}
        destroyOnClose={true}
        footer={[
          <Button key="cancel" onClick={() => setIsVisible(false)}>
            {i18n.t('commons__cancel')}
          </Button>,
          <Button key="ok" onClick={onSave} type="primary">
            {filesRequests.currentEvaluations.filter((obj) => obj.stage === currentStage).length > 0
              ? "Editar solicitudes"
              : "Agregar solicitudes"}
          </Button>,
        ]}
        className="evaluation-modal"
      >
        <div className="evaluation-modal-sub-title">
          Solicitudes seleccionadas
        </div>
        {currentFilesRequests.length === 0 && !createFilesRequest
          ? (
            <div className="evaluations-flex-div">
              <Row type="flex" justify="center">
                <img alt="cute-logo" src={LOGO_DOCR} style={{ height: 40 }} />
              </Row>
              <Row type="flex" justify="center">
                <Typography.Text strong style={{ alignText: "center" }}>
                  Aún no has seleccionado solicitudes de documentos
                </Typography.Text>
              </Row>
              <Row type="flex" justify="center">
                <Typography.Text>
                  Busca los que necesites solicitar en las solicitudes disponibles
                  de esta sección o agrega una nueva en el siguiente botón.
                </Typography.Text>
              </Row>
              <Row type="flex" justify="center">
                <Button
                  type="secondary"
                  onClick={() => setCreateFilesRequest(!createFilesRequest)}
                >
                  Crear solicitud
                </Button>
              </Row>
            </div>
          ) : (
            !createFilesRequest
              ? (
                <Row type="flex" justify="start" gutter={[20, 20]}>
                  <Col xs={24}>
                    <Table
                      pagination={false}
                      dataSource={currentFilesRequests.filter((obj) => obj.stage === currentStage)}
                      columns={columns}
                      rowKey={(record) => Buffer.from(
                        `${record.id}-${record.template}-${record.name}`, 'base64'
                      ).toString('base64')}
                      expandedRowRender={(record) => expandedCurrentRowRender(record)}
                      expandIcon={(props) => customExpandIcon(props)}
                      rowClassName={() => 'expandable-row'}
                    />
                  </Col>
                  <Col xs={24}>
                    <Button
                      type="secondary"
                      onClick={() => setCreateFilesRequest(!createFilesRequest)}
                    >
                      Crear solicitud
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Row
                  type="flex"
                  style={{
                    height: "100%",
                    width: "100%",
                    border: "1px solid #597EF7",
                    padding: "1em",
                  }}
                >
                  <Col sm={24}>
                    <FileRequestForm
                      onCancel={onCancel}
                      onSaveRequest={onSaveRequest}
                    />
                  </Col>
                </Row>
              )
          )}

        <div className="evaluation-modal-sub-title" style={{ marginTop: 10 }}>
          Plantillas de solicitudes disponibles
        </div>
        <Row type="flex" justify="start" gutter={[20, 20]}>
          <Col xs={24} sm={12}>
            <Input
              className="evaluation-modal-section"
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Buscar solicitud de documentos"
              suffix={<Icon type="search" />}
              value={searchInput}
            />
          </Col>
        </Row>
        <Row type="flex" justify="start" gutter={[20, 20]}>
          <Col xs={24}>
            <Table
              pagination={{ pageSize: 5 }}
              dataSource={
                searchInput !== undefined
                  ? filteredFilesRequests
                  : availableFilesRequests
              }
              columns={templateColumns}
              rowKey="id"
              expandedRowRender={(record) => expandedRowRender(record)}
              expandIcon={(props) => customExpandIcon(props)}
              rowClassName={() => 'expandable-row'}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default AdditionalFilesRequest;
