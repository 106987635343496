import React, { useContext } from 'react';

import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Typography,
} from 'antd';
import PropTypes from 'prop-types';

import { PersonalFormContext } from '../config/Context';
import { monthsOptions, personalFormLabels, yearsOptions } from '../constants';
import i18n from '../../../i18n';

const { Option } = Select;
const { Text } = Typography;

const { experiencesInformation: experiencesLabels } = personalFormLabels;

function ExperienceInformation(props) {
  const {
    idx,
    experienceData,
    getFieldDecorator,
    getFieldValue,
    validateFields,
  } = props;
  const { company, country, month_end, month_start, position, year_end, year_start } = experienceData;

  const { removeExperience, updateExperience } = useContext(PersonalFormContext);

  return (
    <Row align="top" gutter={24} type="flex">
      <Col xs={24} md={12}>
        <Form.Item
          colon={false}
          label={
            <>
              <Text style={{ color: 'red' }}>*</Text>{experiencesLabels.experience_company}
            </>
          }
        >
          {getFieldDecorator(`experience_company-${idx}`, {
            initialValue: company,
            onChange: (event) => updateExperience(idx, 'company', event.target.value || null),
            rules: [
              { required: true, message: i18n.t('commons__required__field') },
            ],
          })(<Input allowClear />)}
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <Form.Item
          colon={false}
          label={
            <>
              <Text style={{ color: 'red' }}>*</Text>{experiencesLabels.experience_position}
            </>
          }
        >
          {getFieldDecorator(`experience_position-${idx}`, {
            initialValue: position,
            onChange: (event) => updateExperience(idx, 'position', event.target.value || null),
            rules: [
              { required: true, message: i18n.t('commons__required__field') },
            ],
          })(<Input allowClear />)}
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <Row gutter={8} type="flex">
          <Col span={12}>
            <Form.Item
              colon={false}
              label={
                <>
                  <Text style={{ color: 'red' }}>*</Text>{experiencesLabels.experience_month_start}
                </>
              }
            >
              {getFieldDecorator(`experience_month_start-${idx}`, {
                initialValue: monthsOptions[month_start],
                onChange: (option) => updateExperience(idx, 'month_start', option),
                rules: [
                  { required: true, message: i18n.t('commons__required__field') },
                ],
              })(
                <Select placeholder={i18n.t('commons__month')}>
                  {monthsOptions.map((month, index) => (
                    <Option key={`month-start-${idx}-${month}`} value={index}>{month}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item colon={false} label="-">
              {getFieldDecorator(`experience_year_start-${idx}`, {
                initialValue: year_start,
                onChange: (option) => updateExperience(idx, 'year_start', option),
                rules: [
                  { required: true, message: i18n.t('commons__required__field') },
                ],
              })(
                <Select placeholder={i18n.t('commons__year')}>
                {yearsOptions.slice(0, (new Date().getFullYear()) - 1920 + 1).reverse().map(
                  (opt, index) => <Option value={opt} key={index + opt}>{opt}</Option>)}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col xs={24} md={12}>
        <Row gutter={8} type="flex">
          <Col span={12}>
            <Form.Item colon={false} label={experiencesLabels.experience_month_end}>
              {getFieldDecorator(`experience_month_end-${idx}`, {
                initialValue: monthsOptions[month_end],
                onChange: (option) => updateExperience(idx, 'month_end', option || null),
                rules: [
                  {
                    validator: (rule, value, callback) => {
                      if (value === undefined && getFieldValue(`experience_year_end-${idx}`)) {
                        callback(i18n.t('form__experience_month_end__error'));
                      } else {
                        if (value === undefined && !getFieldValue(`experience_year_end-${idx}`)) {
                          validateFields([`experience_year_end-${idx}`], { force: true });
                        }
                        callback();
                      }
                    }
                  }
                ],
              })(
                <Select allowClear placeholder={i18n.t('commons__month')}>
                  {monthsOptions.map((month, index) => (
                    <Option key={`month-end-${idx}-${month}`} value={index}>{month}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item colon={false} label=" ">
              {getFieldDecorator(`experience_year_end-${idx}`, {
                initialValue: year_end,
                onChange: (option) => updateExperience(idx, 'year_end', option || null),
                rules: [
                  {
                    validator: (rule, value, callback) => {
                      if (!value && getFieldValue(`experience_month_end-${idx}`)) {
                        callback(i18n.t('form__experience_year_end__error'));
                      } else {
                        if (!value && !getFieldValue(`experience_month_end-${idx}`)) {
                          validateFields([`experience_month_end-${idx}`], { force: true });
                        }
                        callback();
                      }
                    }
                  }
                ],
              })(
                <Select allowClear placeholder={i18n.t('commons__year')}>
                  {yearsOptions.slice(0, (new Date().getFullYear()) - 1920 + 1).reverse().map(
                    (opt, index) => <Option value={opt} key={index + opt}>{opt}</Option>)}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Checkbox disabled checked={(!month_end && !year_end)}>
          {i18n.t('form__experience__current')}
        </Checkbox>
      </Col>
      <Col xs={24} md={12}>
        <Form.Item
          colon={false}
          label={
            <>
              <Text style={{ color: 'red' }}>*</Text>{experiencesLabels.experience_country}
            </>
          }
        >
          {getFieldDecorator(`experience_country-${idx}`, {
            initialValue: country,
            onChange: (event) => updateExperience(idx, 'country', event.target.value || null),
            rules: [{ required: true, message: i18n.t('commons__required__field') }],
          })(<Input />)}
        </Form.Item>
      </Col>
      <Col span={24} style={{ textAlign: 'end' }}>
        <Button
          onClick={() => Modal.confirm({
            cancelText: i18n.t('commons__cancel'),
            content: i18n.t('commons__confirm_question'),
            okText: i18n.t('commons__confirm'),
            onOk: () => removeExperience(idx),
            title: `${i18n.t('commons__delete')} ${i18n.t('commons__experience')} ${company ? `en ${company}` : ''}`,
          })}
          style={{ color: '#F175A5', padding: 0 }}
          type="link"
        >
          {i18n.t('commons__delete')}
        </Button>
      </Col>
      <Divider style={{ marginTop: 0 }} />
    </Row>
  );
}

ExperienceInformation.propTypes = {
  idx: PropTypes.number.isRequired,
  experienceData: PropTypes.shape({
    company: PropTypes.string,
    country: PropTypes.string,
    month_end: PropTypes.string | PropTypes.number,
    month_start: PropTypes.string | PropTypes.number,
    position: PropTypes.string,
    year_end: PropTypes.string | PropTypes.number,
    year_start: PropTypes.string | PropTypes.number,
  }).isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
};

export default ExperienceInformation;
