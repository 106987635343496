import { TRAIT_FETCH_BEGIN,
    TRAIT_UPDATE,
    TRAITS_RELEVANCE,
    TRAIT_RECEIVE_FAILURE,
    TRAIT_RECEIVE_SUCCESS,
    MEASURED_TRAIT_FETCH_BEGIN,
    MEASURED_TRAIT_RECEIVE_FAILURE,
    MEASURED_TRAIT_RECEIVE_SUCCESS,
    MEASURED_TRAIT_REMOVE,
    MEASURED_TRAIT_ORIGINAL_REMOVE,
    SAVE_TRAIT_SUCCESS,
    RESET_MT
     } from '../constants';

const initialState = {
    traits: {},
    measuredTraits: {},
    removedMeasuredTraits: [],
    totalPoints: 0,
    error: [],
    isFetchingTraits: false,
    isFetchingMeasuredTraits: false,
}

export default function traitReducer(state = initialState, action) {
    switch (action.type) {
        case TRAIT_RECEIVE_SUCCESS:
            // All done: set loading "false".
            // Also, replace the items with the ones from the server
            return {
                ...state,
                isFetchingTraits: false,
                traits: action.payload.traits,
            };
        case TRAIT_UPDATE:
            return {
                ...state,
                measuredTraits: { ...state.measuredTraits, [action.payload.measuredTrait.trait_id]: action.payload.measuredTrait },
                totalPoints: state.totalPoints + action.payload.differenceTotalPoints
            };
        
        case RESET_MT:
            return {
                ...initialState
            };

        case TRAIT_RECEIVE_FAILURE:
            // The request failed, but it did stop, so set loading to "false".
            // Save the error, and we can display it somewhere
            // Since it failed, we don't have items to display anymore, so set it empty.
            // This is up to you and your app though: maybe you want to keep the items
            // around! Do whatever seems right.
            return {
                ...initialState,
                isFetchingTraits: false,
                error: action.payload.error,
                data: []
            };
        
        case TRAIT_FETCH_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                totalPoints: 0,
                isFetchingTraits: true,
                error: null
            };
        case MEASURED_TRAIT_FETCH_BEGIN:
            return {
                ...state,
                isFetchingMeasuredTraits: true,
                error: null
            };
        case MEASURED_TRAIT_RECEIVE_FAILURE:
            return {
                ...state,
                isFetchingMeasuredTraits: false,
                error: action.payload.error
            }
        case MEASURED_TRAIT_RECEIVE_SUCCESS:
            return {
                ...state,
                isFetchingMeasuredTraits: false,
                measuredTraits: {...state.measuredTraits, ...action.payload.measuredTraits},
                totalPoints: Object.values(action.payload.measuredTraits).reduce((init, next) => init + next.points, 0) + (action.payload.isNew ? state.totalPoints : 0)
            }
        case MEASURED_TRAIT_REMOVE:
            let newMeasuredTraits = {...state.measuredTraits}
            let newPoints = state.totalPoints
            action.payload.ids.forEach(id => {
                newPoints -= parseInt(newMeasuredTraits[id].points)
                delete newMeasuredTraits[id]
            })
            return {
                ...state,
                measuredTraits: newMeasuredTraits,
                totalPoints: newPoints ? newPoints : 0,
            }
        case MEASURED_TRAIT_ORIGINAL_REMOVE:
            let removedMeasuredTraits = [...state.removedMeasuredTraits]
            removedMeasuredTraits.push(...action.payload.ids)
            return {
                ...state,
                removedMeasuredTraits: removedMeasuredTraits,
            }
        case SAVE_TRAIT_SUCCESS:
            return {
                ...initialState
            }
        case TRAITS_RELEVANCE:
            let measuredTraits = {...state.measuredTraits}
            for (const mt in measuredTraits) {
                measuredTraits[mt] = {...measuredTraits[mt], relevance: state.totalPoints != 0 ? Math.round(parseFloat((measuredTraits[mt].points)*100/state.totalPoints)*100)/100 : 0}
            }
            return {
                ...state,
                measuredTraits: measuredTraits,
            }
        default:
                return state;
    }
}