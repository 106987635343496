import '../EvaluationsStyles.scss'

import {
  Button,
  Col,
  Icon,
  Input,
  Modal,
  Row,
  Tooltip,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react'

import EvaluationCard from '../EvaluationCard';
import JobAppContext from '../../../context/JobAppContext';

function Games({ type, title }) {
  const {
    assignedActivities,
    jobApplication,
    games,
    currentStage,
    updateGames,
    addMeasuredTrait,
    removeMeasuredTrait,
  } = useContext(JobAppContext);

  const [searchInput, setSearchInput] = useState(undefined);
  const [availableGames, setAvailableGames] = useState([]);
  const [currentEvaluations, setCurrentEvaluations] = useState([]);
  const [allEvaluations, setAllEvaluations] = useState([]);
  const [filteredGames, setFilteredGames] = useState([]);

  useEffect(() => {
    setSearchInput(undefined);
    setAvailableGames([]);
    setCurrentEvaluations([]);
    setFilteredGames([]);
  }, []);

  useEffect(() => {
    if (searchInput == undefined) return;
    const filtered = allEvaluations.filter(obj => obj.activity.toLowerCase().includes(searchInput.toLowerCase())).sort((a, b) => a.activity.localeCompare(b.activity));
    setFilteredGames(filtered);
  }, [searchInput]);


  useEffect(() => {
    if (games) {
      // Remove previously added games
      const currentEvaluationsIds = games.currentEvaluations.map(obj => obj.id);
      let updatedGames = games.allEvaluations.filter(obj => !currentEvaluationsIds.includes(obj.id));
      setAvailableGames(updatedGames);
      // Add currentEvaluations
      let currentEvaluationsStage = games.currentEvaluations.filter(obj => obj.stage === currentStage)
      setCurrentEvaluations([...games.currentEvaluations]);
      setAllEvaluations([...currentEvaluationsStage, ...updatedGames].sort((a, b) => a.activity.localeCompare(b.activity)));
    };
  }, [games, currentStage]);


  const getTypes = (list) => {
    // Acá se muestran las actividades que vienen en el genoma ideal
    if (type == 'PERSONALITY') {
      return list.filter(obj => obj.category == type)
    } else if (type == 'COGNITIVE') {
      return list.filter(obj => [type, "REASONING"].includes(obj.category))
    } else {
      return list.filter(obj => obj.category == 'EMOTIONAL' || obj.category == 'BEHAVIORAL')
    }
  };

  const language = (game) => {
    return game.icon == "TLET" ? 'ES' : 'ES, EN, PT'
  };

  const gameTime = (game) => game?.time_estimated ? `${game?.time_estimated} minutos` : 'Sin tiempo';
  const gameTraits = (game) => game.traits?.length == 1 ? `${game.traits?.length} rasgo` : `${game.traits?.length} rasgos`;

  const checkBeforeAdd = (game) => {
    if (['COGNI', 'BASAL', 'EXPER'].includes(jobApplication.genome?.category)) {
      Modal.confirm({
        title: 'Agregar Evaluación adicional',
        content: "Recuerda que las evaluaciones que no están contenidas por defecto en el genoma predefinido no influirán en el FIT de los candidatos. De todas formas, podrás ver los resultados de la evaluación adicional en el tablero y el perfil.",
        onOk: () => addGame(game),
        okText: 'Agregar',
        cancelText: 'Cancelar',
      })
    }
    else {
      addGame(game);
    };
  }

  const addGame = (game) => {
    let idx = availableGames.findIndex(obj => obj.id === game.id);

    // Add game with stage
    let tmpGame = { ...availableGames[idx] };
    tmpGame.stage = currentStage;

    const newState = [...currentEvaluations, tmpGame];
    // Add tolocal current games
    setCurrentEvaluations(newState);
    // Update available GAMES
    availableGames.splice(idx, 1);
    setAvailableGames(availableGames);

    // Also updated the filtered data
    idx = filteredGames.findIndex(obj => obj.id === game.id);
    filteredGames.splice(idx, 1);
    setFilteredGames(filteredGames);

    // Add logic
    const toAdd = [...games.toAdd];
    const toRemove = [...games.toRemove];

    const toAddIdx = toAdd.findIndex(obj => obj.id === game.id);
    const toRemoveIdx = toRemove.findIndex(obj => obj.id === game.id);

    // Si no esta en toAdd ni en toRemove, agregar a toAdd
    if (toAddIdx == -1) {
      if (toRemoveIdx == -1) {
        toAdd.push({ ...tmpGame });
      }
      // Estaba en to remove, revisar si la stage es la misma si no mandar a update
      else {
        if (toRemove[toRemoveIdx].stage != tmpGame.stage) {
          const toUpdate = [...games.toUpdate];
          toUpdate.push(tmpGame)
          updateGames('toUpdate', toUpdate);
        }
      }
    };

    // Siempre remover de toRemove (si no esta no se remueve nada)
    toRemove.splice(toRemoveIdx, 1);

    updateGames('toAdd', toAdd);
    updateGames('toRemove', toRemove);
    updateGames('currentEvaluations', newState);

    // Agregar Measured Traits
    game.traits.forEach(trait => addMeasuredTrait(trait));
  };

  const removeGame = (game) => {
    const idx = currentEvaluations.findIndex(obj => obj.id === game.id)
    // Unused games should not have stage
    //delete currentEvaluations[idx].stage

    // Remove logic
    const toUpdate = [...games.toUpdate];
    const toAdd = [...games.toAdd];
    const toRemove = [...games.toUpdate];

    const toUpdateIdx = toUpdate.findIndex(obj => obj.id === game.id)
    const toAddIdx = toAdd.findIndex(obj => obj.id === game.id)

    // Siempre remover de update
    if (toUpdateIdx != -1) {
      toUpdate.splice(toUpdateIdx, 1);
    }
    // Remover de toAdd si existe, si no agregar a toRemove
    if (toAddIdx != -1) {
      toAdd.splice(toAddIdx, 1);
    }
    else {
      toRemove.push({ ...currentEvaluations[idx] });
    }

    let updateAvailableGames = [...availableGames, currentEvaluations[idx]];
    // Remove from current evals
    currentEvaluations.splice(idx, 1);
    setCurrentEvaluations(currentEvaluations);
    updateAvailableGames.sort((a, b) => a.activity.localeCompare(b.activity));
    setAvailableGames([updateAvailableGames]);

    updateGames('toUpdate', toUpdate);
    updateGames('toAdd', toAdd);
    updateGames('toRemove', toRemove);
    updateGames('currentEvaluations', currentEvaluations);

    // Remover Measured Traits
    game.traits.forEach(trait => removeMeasuredTrait(trait));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      // 👇 Get input value
      setSearchInput(e.target.value)
      console.log('searching')
    }
  };

  const isGameRequired = (game) => {
    const assignedGame = assignedActivities.find(obj => obj.activity.code === game.code);
    let config = assignedGame?.configuration;
    if (config) {
      config = JSON.parse(config);
      return config.required;
    };
    return false;
  };

  const RemoveButton = ({ game }) => {
    if (isGameRequired(game)) {
      return (
        <Tooltip title={'El juego es obligatorio en el Genoma seleccionado'}>
          <Button key={'close'} className='evaluation-added-button' style={{ cursor: 'not-allowed' }}>
            <Icon type='close' />Agregado por defecto
          </Button>
        </Tooltip>
      )
    }
    else {
      return (
        <Button key={'close'} className='evaluation-delete-button' onClick={() => removeGame(game)} disabled={false}>
          <Icon type='close' />Quitar
        </Button>
      );
    }
  };

  const getCurrentTraits = (game) => {
    const traitComponent = game.traits.map((obj, idx) =>
      <div key={idx} style={{ marginBottom: 5, fontSize: 14, lineHeight: '22px' }}>
        <strong>{`• ${obj.trait}: `}</strong> {obj.candidate_description_translation['es']}
      </div>
    );
    return (
      <>
        <div style={{ marginBottom: 15, marginTop: 10, fontSize: 14 }}>Rasgos medidos en esta evaluación:</div>
        {traitComponent}
      </>
    )
  };

  return (
    <>
      <Row type="flex" justify="space-between" style={{ margin: 10 }}>
        <Col>
          <div className="evaluations-title">{title}</div>
        </Col>
        <Col>
          <Input
            className="evaluations-search-input"
            placeholder={`Buscar evaluación ${title
              .split(':')[1]
              .trim()
              .toLowerCase()}`}
            suffix={<Icon type="search" />}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </Col>
      </Row>

      {getTypes(searchInput !== undefined ? filteredGames : allEvaluations).map(
        (game, idx) => (
          <EvaluationCard
            key={idx}
            logo={`https://genoma-assets.s3.us-east-2.amazonaws.com/${
              game.icon.includes('.svg') ? game.icon : game.icon + '.png'
            }`}
            evaluationTypeImg={
              type === 'GENOME'
                ? [
                    'https://genoma-assets.s3.us-east-2.amazonaws.com/adn.svg',
                    'https://genoma-assets.s3.us-east-2.amazonaws.com/chip.svg',
                  ]
                : ['https://genoma-assets.s3.us-east-2.amazonaws.com/chip.svg']
            }
            title={game.activity}
            description={game.description}
            viewMoreContent={() => getCurrentTraits(game)}
            additionalInformation={[
              { value: gameTime(game), icon: 'clock-circle', sm: 6 },
              { value: gameTraits(game), icon: 'appstore', sm: 5 },
              { value: game.code === 'LEAD' ? 'Jefaturas o líderes' : 'Todo tipo de cargos', icon: 'user', sm: 8 },
              { value: language(game), icon: 'flag', sm: 5 },
            ]}
            buttons={() => [
              availableGames.includes(game) ? (
                <Button
                  key={'add'}
                  className="evaluation-add-button"
                  onClick={() => checkBeforeAdd(game)}
                >
                  <Icon type="plus" />
                  Agregar
                </Button>
              ) : (
                <RemoveButton game={game} key={'remove'} />
              ),
            ]}
            game={true}
          />
        )
      )}
    </>
  );
};

export default Games;