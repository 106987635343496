import React, { useState, useEffect, useRef } from "react";

import {
  Typography,
} from 'antd';

const { Text } = Typography;

const Countdown = ({ callback, time, color, type }) => {
  const [clockSeconds, setClockSeconds] = useState(time);

  const stableCallback = useRef();

  useEffect(() => {
    stableCallback.current = callback;
  });

  useEffect(() => {
    setClockSeconds(time);
    
    let count = 0
    let timer = setInterval(() => {
      count++
      if (count === time) {
        clearInterval(timer);
      }
      setClockSeconds((oldValue) => oldValue - 1);
    }, 1000);

    return () => {
      clearInterval(timer);
    }
  }, [time])


  // TODO we are not using props.type
  useEffect(() => {
    if (clockSeconds === 0) {
      stableCallback.current(type);
    }
  }, [clockSeconds, stableCallback, type]);

  const formatCountdown = (seconds) => {
    const date = new Date(0);
    date.setSeconds(seconds);
    const timeString = date.toISOString().substring(19, 14);
    return timeString;
  }

  return (
    <div style={{ paddingTop: 2, display: 'flex', justifyContent: 'end' }}>
      {time && <Text strong style={{ color: color }}>{formatCountdown(clockSeconds)}</Text>}
    </div>
  );
};

export default Countdown;
