import React, { useContext } from 'react';

import { CandidateContext } from '../config/CandidateContext';

import { Button, Dropdown, Icon, Menu, Tooltip, Typography } from 'antd';

import ShareProfile from './ShareProfile';
import ReportPDF from '../../Report';

import PreviewDocument from '../../CandidateProfilev2/common/PreviewDocument';

import useBoolean from '../../../hooks/useBoolean';
import i18n from '../../../i18n'

import ScheduleModal from '../../BusinessDashboard/CandidateListV2/ScheduleModal';

const { Text } = Typography;

const Header = ({mode}) => {
  const {
    application: { candidate_type, status },
    addOns,
    permissions,
    candidate,
    jobApp,
    token,
    preparePDF,
    topRef,
    sharing,
    userjobapplicationreference,
    askForReference,
    translationFile
  } = useContext(CandidateContext);

  const [viewCv, handleCv] = useBoolean(false);
  const [shareView, handleShare] = useBoolean(false);
  const [asPDF, handlePDFView] = useBoolean(false);
  const [showScheduleModal, handleScheduleModal] = useBoolean(false);

  const actions = [
    {
      condition: candidate_type === 'active',
      icon: 'user-add',
      text: userjobapplicationreference ? i18n.t('profile__resend_request_references', translationFile) : i18n.t('commons__send_reference_request', translationFile),
      fn: askForReference,
    },
  ].filter((action) => action.condition);

  const getActions = () => {
    let tmpActions = [];
    tmpActions = [...actions];
    
    const hasPersonalUserInterviewAddOn = addOns.find((addon) => addon.add_on === 'SCIN')
    if (hasPersonalUserInterviewAddOn){
      return [...tmpActions, { icon: 'calendar', text: i18n.t('profile__schedule_meeting', translationFile), fn: handleScheduleModal.on }]
    }
    else{
      return tmpActions;
    }    
  };

  const menu = (
    <Menu>
      {
        getActions()
          .map((action, index) => (
            <Menu.Item key={`action-${index}`} onClick={action.fn}>
              <Icon type={action.icon} />
              <Text>{action.text}</Text>
            </Menu.Item>
          ))
        }
    </Menu>
  );

  return (
    <div className="new-modal-content__header" ref={topRef}>
      <Text strong className="text-20px" style={{ width: '100%' }}>
        {jobApp.job_application}
      </Text>
      <div div className="new-modal-content__header__sub">
        <Text>{i18n.t('commons__publication_date', translationFile).split('[')[0]} {jobApp.creation_date}.</Text>
        <div className="new-modal-content__header__btns">
          <Tooltip title={i18n.t('commons__see_cv', translationFile)}>
            <Button type="link" icon="file" onClick={handleCv.toggle} />
          </Tooltip>
          <Tooltip title={i18n.t('commons__share_profile', translationFile)}>
            <Button type="link" icon="share-alt" onClick={handleShare.toggle} />
          </Tooltip>
          {candidate_type !== 'passive' && (
            <Tooltip
              title={i18n.t('commons__profile_as_pdf', translationFile)}
              onClick={handlePDFView.toggle}
            >
              <Button type="link" icon="file-pdf" />
            </Tooltip>
          )}
          {(getActions().length) ? (
            <Dropdown overlay={menu} placement="bottomRight" disabled={sharing}>
              <Button>{i18n.t('commons__more_actions', translationFile)}</Button>
            </Dropdown>
          ) : null}
        </div>
      </div>
      <ShareProfile
        handleOk={handleShare.toggle}
        handleCancel={handleShare.toggle}
        visibleShareProfile={shareView}
        jobappId={jobApp.id}
        candidateId={candidate.candidateId}
        candidateType={candidate_type}
        authToken={token}
      />
      {candidate_type !== 'passive' && (
        <ReportPDF
          getPdfData={preparePDF}
          visible={asPDF}
          closeModal={handlePDFView.off}
        />
      )}
      <PreviewDocument
        file={candidate.cv}
        visiblePreviewModal={viewCv}
        handleOk={handleCv.toggle}
      />
      {
        mode !== 'isolated' &&
        <ScheduleModal
          visible={showScheduleModal}
          handleVisible={handleScheduleModal.toggle}
          selectedCandidates={[candidate]}
          stage={status}
          jobApplication={jobApp}
        />
      }
    </div>
  );
};

export default Header;
