import { SHOW_MODAL, HIDE_MODAL } from '../constants';

export const showModal = ({ modalProps, modalType }) => {
    return (dispatch) => {
        dispatch({
            type: SHOW_MODAL,
            modalProps,
            modalType
        });
    };
};

export const hideModal = () => {
    return (dispatch) => {
        dispatch({
            type: HIDE_MODAL
        });
    };
};