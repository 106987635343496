import React, { useState, useEffect, useMemo } from 'react';

import {
  Form,
  Input,
  Row,
  Col,
  Select,
  Typography,
  DatePicker,
  Button,
} from 'antd';

import moment from 'moment';

import { times } from '../assets/times';
import timezones from '../assets/timezones.json';

const { Title, Text } = Typography;

const timeDurations = new Array(13).fill(1).map((_, i) => 5 * i);

const DateForm = ({ form, interviewType, selectedCandidates }) => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const candidates_per_meeting = getFieldValue('candidates_per_meeting');

  const [initIndex, endIndex] = ['init_hour', 'end_hour']
    .map((x) => getFieldValue(x))
    .map((t) => times.findIndex((time) => time === t));

  const isSingle = interviewType === 'single';

  const maxCandidates =
    getFieldValue('candidates_per_meeting') ===
    getFieldValue('attendees')?.candidates?.length;

  useEffect(() => {
    if (maxCandidates) {
      setFieldsValue({ interval_duration: 0 });
    }
  }, [candidates_per_meeting]);

  return (
    <div className="schedule-details-form">
      <Title level={4}>Programación de horario</Title>
      <Text>
        Selecciona una fecha y una hora donde desees agendar la reunión con los
        candidatos.
      </Text>
      <Row type="flex" gutter={[32, 0]}>
        <Col xs={12}>
          <Form.Item label="Fecha">
            {getFieldDecorator('date', {
              rules: [
                {
                  required: true,
                  message: 'Por favor ingresa la fecha',
                },
              ],
            })(
              <DatePicker
                placeholder="Seleccionar fecha..."
                style={{ width: '100%' }}
              />
            )}
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item label="Hora de inicio">
            {getFieldDecorator('init_hour', {
              rules: [
                {
                  required: true,
                  message: 'Por favor ingresa la fecha',
                },
              ],
            })(
              <Select placeholder="Selecciona hora de inicio...">
                {times.slice(0, endIndex).map((time) => (
                  <Select.Option value={time} key={time}>
                    {time}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        {!isSingle ? (
          <>
            <Col xs={12}>
              <Form.Item label="Candidatos por reunión">
                {getFieldDecorator('candidates_per_meeting', {
                  rules: [
                    {
                      required: true,
                      message:
                        'Por favor ingrese la cantidad de candidatos por reunión',
                    },
                  ],
                })(
                  <Select placeholder="Selecciona la cantidad de candidatos...">
                    {new Array(
                      getFieldValue('attendees')?.candidates.length ??
                        selectedCandidates.length
                    )
                      .fill(0)
                      .map((_, i) => (
                        <Select.Option value={i + 1} key={`candidate-${i + 1}`}>
                          {i + 1} candidato{`${i + 1 > 1 ? 's' : ''}`}
                        </Select.Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item label="Duración de cada reunión">
                {getFieldDecorator('interview_duration', {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor ingresa la duración de cada reunión!',
                    },
                  ],
                })(
                  <Select
                    placeholder="Selecciona la duración de cada reunión..."
                    optionLabelProp="label"
                  >
                    {timeDurations.map((time) => (
                      <Select.Option
                        value={time}
                        key={`min-${time}`}
                        label={`${time} minutos`}
                      >
                        {time} minutos
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item label="Intervalo entre reuniones">
                {getFieldDecorator('interval_duration', {
                  rules: [
                    {
                      required: true,
                      message:
                        'Por favor ingresa la duración de cada intervalo!',
                    },
                  ],
                })(
                  <Select
                    placeholder="Selecciona la duración de cada intervalo..."
                    optionLabelProp="label"
                    disabled={maxCandidates}
                  >
                    {timeDurations.map((time) => (
                      <Select.Option
                        value={time}
                        key={`min-${time}`}
                        label={time > 0 ? `${time} minutos` : 'Sin intervalos'}
                      >
                        {time > 0 ? `${time} minutos` : 'Sin intervalos'}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </>
        ) : (
          <Col xs={12}>
            <Form.Item label="Hora de cierre">
              {getFieldDecorator('end_hour', {
                rules: [
                  {
                    required: true,
                    message: 'Por favor ingresa la fecha',
                  },
                ],
              })(
                <Select placeholder="Selecciona hora de cierre...">
                  {times.slice(initIndex + 1, times.length).map((time) => (
                    <Select.Option value={time} key={time}>
                      {time}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        )}
        <Col xs={12}>
          <Form.Item label="Zona horaria">
            {getFieldDecorator('gmt', {
              initialValue: `${moment().format('zZ')} - ${
                Intl.DateTimeFormat().resolvedOptions().timeZone
              }`,
              rules: [
                {
                  required: true,
                  message: 'Por favor ingresa la zona horaria',
                },
              ],
            })(<Input disabled />)}
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default DateForm;
