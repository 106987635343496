import React, { useState } from 'react';
import {
  Button,
  Col,
  Input,
  Row,
} from "antd";

export default function HiringRoomIntegrationModalContent ({ 
  businessIntegrationData, 
  editBusinessIntegrationData, 
  setBusinessIntegrationData 
}) {
  const [account, setAccount] = useState('');

  const handleIntegrate = () => {
    window.location.assign(businessIntegrationData.template.test_path.path + `&account=${account}&state=${account}`)
  }

  return (
    <Row>
      <Col sm={24}>
        Al presionar "Autorizar", se le redirigirá a Hiring Room, donde deberá
        iniciar sesión y autorizar a Genomawork para poder completar la integración.
        Una vez integrado correctamente, podrá continuar con sus procesos normalmente.
        A continuación, se debe ingresar la cuenta de Hiring Room (ej. si el dominio es empresa.hiringroom.com,
        la cuenta es <strong>empresa</strong>)
      </Col>
      <Col>
        <Input 
          value={account} 
          placeholder={"Ingresar cuenta. Ej: empresa.hiringroom.com -> empresa"} 
          onChange={e => setAccount(e.target.value)}
        />
      </Col>
      <Col>
        <Button type='link' onClick={handleIntegrate}>Autorizar</Button>    
      </Col>
    </Row>
  )
}
