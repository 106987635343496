import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import ReCAPTCHA from 'react-google-recaptcha';

import { Form, Button, Icon, Input, Alert, Row, Typography } from 'antd';

import * as authActionCreators from '../../../actions/auth';
import * as dataActionCreators from '../../../actions/data';
import ReactGA from 'react-ga';
import i18n from '../../../i18n';

const { Text } = Typography;

function LoginFormComponent(props) {
  const authError = i18n.t('login__wrong__data');
  const { getFieldDecorator } = props.form;
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loginInfo, setLoginInfo] = useState({
    email: '',
    password: '',
    otp: null,
    errors: {},
    redirectTo: props.redirect,
  });
  const [requireMfa, setRequireMfa] = useState(false);
  const captchaRef = useRef(null);
  const [captchaToken, setCaptchaToken] = useState(null);
  const path = window.location.pathname;

  useEffect(() => {
    if (!props.isAuthenticating && props.alerts.type === 'alert-danger') {
      setVisibleAlert(true);
    }
  }, [props.alerts.type, props.isAuthenticating]);

  const recoverPassword = () => {
    const url = '/passwordrecovery';
    props.dispatch(push(url));
  };

  const handleFormChange = prop => event => {
    setVisibleAlert(false);
    let tempErrors = { ...loginInfo.errors };
    tempErrors[prop] = false;
    setLoginInfo({
      ...loginInfo,
      errors: tempErrors,
      [prop]: event.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    let captchaTokenToSend = captchaToken;
    if (!captchaToken) {
      captchaTokenToSend = await captchaRef.current.executeAsync();
    }
    setVisibleAlert(false);
    props.form.validateFields((err, values) => {
      if (!err) {
        const body = { 'otp': loginInfo.otp, 'Captcha': captchaTokenToSend };
        props.authActions
          .authLoginUser(
            loginInfo.email.toLowerCase(),
            loginInfo.password,
            loginInfo.redirectTo,
            body
          )
          .then((response) => {
            if (response && response.mfa_hash !== null) {
              setRequireMfa(true);
            }
            else {
              ReactGA.set({ userId: loginInfo.email });
            }
          }).catch(() => {
            if (props.alerts.message === i18n.t('login__auth__error')) {
              setVisibleAlert(true);
            }
          })
      }
      // Always refresh captcha
      captchaRef.current.reset();
    });
  };

  const SimpleLogin = () => (
    <>
      <Form.Item label={i18n.t('commons__email')}>
        {getFieldDecorator('email', {
          initialValue: loginInfo.email,
          onChange: handleFormChange('email'),
          rules: [
            {
              type: 'email',
              message: i18n.t('login__email__error'),
            },
            {
              required: true,
              message: i18n.t('login__without__email'),
            },
          ],
        })(
          <Input
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder={i18n.t('commons__email')}
          />
        )}
      </Form.Item>

      <Form.Item label={i18n.t('commons__password')}>
        {getFieldDecorator('password', {
          initialValue: loginInfo.password,
          onChange: handleFormChange('password'),
          rules: [
            {
              required: true,
              message: i18n.t('change__password__please__enter__your__password'),
            },
          ],
        })(
          <Input.Password
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder={i18n.t('commons__password')}
          />
        )}
      </Form.Item>
    </>
  )

  const OtpInput = () => (
    <Form.Item label={i18n.t('login__mfa')}>
      {getFieldDecorator('otp', {
        initialValue: loginInfo.otp,
        onChange: handleFormChange('otp'),
        rules: [
          {
            required: true,
            message: i18n.t('login__mfa__error'),
          },
        ],
      })(
        <Input
          prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder={i18n.t('login__mfa__security__code')}
        />
      )}
      <ReCAPTCHA
        onChange={() => { }}
        ref={captchaRef}
        sitekey={process.env.REACT_APP_CAPTCHA_API_KEY}
        size='invisible'
      />
    </Form.Item>
  )


  return (
    <Form onSubmit={handleSubmit}>
      {
        requireMfa ? OtpInput() : SimpleLogin()
      }

      <div className="login-alert">
        {visibleAlert ? (
          <Alert message={requireMfa ? i18n.t('login__mfa__validation__error') : authError} type="error" showIcon />
        ) : null}
      </div>

      <Form.Item>
        <Button
          htmlType="submit"
          block
          size="large"
          style={props.colors}
        >
          {i18n.t('commons__sign_in')}
        </Button>

        {path !== '/businesslogin' ? (
          <Row type='flex' align='middle' justify='center'>
            <Text>{i18n.t('login__register_text')}</Text>
            <Button
              type="link"
              onClick={() => props.dispatch(push('/signup'))}
              style={{ color: props.colors.background || '#F175A5' }}
              strong
            >
              <strong>{i18n.t('login__register_button')}</strong>
            </Button>
          </Row>
        ) : null}
        <Row type='flex' align='middle' justify='center'>
          <Button
            type="link"
            onClick={recoverPassword}
            style={{ color: props.colors.background || '#F175A5' }}
            strong
          >
            <strong>{i18n.t('login__forgot__password')}</strong>
          </Button>
        </Row>
      </Form.Item>
      <ReCAPTCHA
        onChange={(token) => { setCaptchaToken(token) }}
        onExpired={() => { setCaptchaToken(null) && captchaRef.current.execute() }}
        ref={captchaRef}
        sitekey={process.env.REACT_APP_CAPTCHA_API_KEY}
        size='invisible'
      />
    </Form>
  );
}

LoginFormComponent.defaultProps = {
  statusText: '',
  location: null,
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isAuthenticating: state.auth.isAuthenticating,
    statusText: state.auth.statusText,
    alerts: state.alerts,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    authActions: bindActionCreators(authActionCreators, dispatch),
    dataActions: bindActionCreators(dataActionCreators, dispatch),
  };
};

const LoginForm = Form.create()(LoginFormComponent);

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
