import React, { useEffect, useMemo, useReducer, useState } from 'react';
import moment from 'moment'
import * as Sentry from '@sentry/browser';

import { message } from 'antd';

import { CandidateModel, JobApplicationModel } from './models';
import personalFormReducer from './personalFormReducer';
import { errorMessageHelper } from '../constants';
import i18n from '../../../i18n';

export const PersonalFormContext = React.createContext();

const initialState = {
  candidate: CandidateModel,
  countries: [],
  currentStep: 0, // Should be 0 by default
  deletedCareers: [],
  deletedCertifications: [],
  deletedExperiences: [],
  jobApplication: JobApplicationModel,
  cvAlreadySent: false,
};

export const PersonalFormProvider = ({
  actions,
  children,
  jobAppId,
  token,
}) => {
  const [state, dispatch] = useReducer(personalFormReducer, initialState);
  const [loading, setLoading] = useState(true);

  const shortDispatch = (type, payload) => dispatch({ type, payload });

  // get info about candidate and other information that could be useful
  useEffect(() => {
    if (jobAppId) {
      let requests = [
        actions.fetchAndReturn('', '/api/v1/constants/countries/?p=[country,translation,phone_code,iso_2]')
          .then((response) => shortDispatch('LOAD_COUNTRIES', response.body))
          .catch((error) => Sentry.captureException(error)),
        actions.fetchAndReturn(token, `/api/v1/gamesandtests/jobapplications/${jobAppId}/`)
          .then((response) => (
            response.status < 400 && shortDispatch('LOAD_JOBAPPLICATION', response.body)
          ))
          .catch((error) => Sentry.captureException(error)),
        actions.fetchAndReturn(token, '/api/v1/accounts/personal/')
          .then((response) => {
            if (response.status < 400) {
              const data = { ...response.body };
              data.birth_date = data.birth_date ? moment(data.birth_date, 'YYYY-MM-DD') : null;
              data.certifications = data.certifications.map((certification) => ({
                ...certification,
                start_date: certification.start_date ? moment(certification.start_date, 'YYYY-MM-DD') : null,
                end_date: certification.end_date ? moment(certification.end_date, 'YYYY-MM-DD') : null,
              }));
              shortDispatch('LOAD_CANDIDATE', data);
            }
          })
          .catch((error) => Sentry.captureException(error))
      ];
      Promise.all(requests).then(() => setLoading(false));
    }
  }, [jobAppId]);

  const updateCvStatus = (status) => shortDispatch('UPDATE_CV_STATUS', status);

  const addCareer = () => shortDispatch('ADD_CAREER');

  const addCertification = () => shortDispatch('ADD_CERTIFICATION');

  const addExperience = () => shortDispatch('ADD_EXPERIENCE');

  const handleCandidateData = () => {
    const { candidate, jobApplication: { id: jobApplicationId } } = state;
    const candidateData = {
      ...candidate,
      birth_date: candidate.birth_date?.format('YYYY-MM-DD') ?? null,
      phone_prefix: candidate.phone_prefix || '',
      education_level: candidate.education_level || '',
      jobappid: jobApplicationId,
    };
    const requests = [
      actions.fetchAndReturn(token, '/api/v1/accounts/personal/', 'PATCH', candidateData)
        .then((response) => ({ ...response, method: 'PATCH', entity: 'candidate' }))
    ];
    if (candidate.newCv && !state.cvAlreadySent) {
      requests.push(
        actions.sendFile(
          token,
          `accounts/personalusers/${candidate.id}/files/`,
          candidate.newCv,
          candidate.newCv.name,
          '',
          'cv',
        ).then((response) => ({ ...response, method: 'PATCH', entity: 'cv' }))
      );
    }
    // if (candidate.newCoverLetter) {
    //   requests.push(
    //     actions.sendFile(
    //       token,
    //       `accounts/personalusers/${candidate.id}/files/`,
    //       candidate.newCoverLetter,
    //       candidate.newCoverLetter.name,
    //       '',
    //       'cover_letter',
    //     ).then((response) => ({ ...response, method: 'PATCH', entity: 'cover_letter' }))
    //   );
    // }
    return requests;
  };

  const handleCareers = () => {
    const { candidate } = state;
    const url = '/api/v1/accounts/careerinformations/';
    const createCareersRequests = candidate.careers.filter(
      ({ id }) => id === undefined
    ).map((career) => (
      actions.fetchAndReturn(token, url, 'POST', career)
        .then((response) => ({ ...response, method: 'POST', entity: 'careers' }))
    ));
    const updateCareersRequests = candidate.careers.filter(
      ({ id }) => id !== undefined
    ).map((career) => (
      actions.fetchAndReturn(token, `${url}${career.id}/`, 'PATCH', career)
        .then((response) => ({ ...response, method: 'PATCH', entity: 'careers' }))
    ));
    const deleteCareersRequests = state.deletedCareers.map((careerId) => (
      actions.fetchAndReturn(token, `${url}${careerId}/`, 'DELETE')
        .then((response) => ({ ...response, method: 'DELETE', entity: 'careers' }))
    ));
    return [...createCareersRequests, ...updateCareersRequests, ...deleteCareersRequests];
  };

  const handleCertifications = () => {
    const { candidate } = state;
    const url = `/api/v1/accounts/personalusers/${candidate.id}/certifications/`;
    const createCertificationsRequests = candidate.certifications.filter(
      ({ id }) => id === undefined
    ).map((certification) => (
      actions.fetchAndReturn(
        token,
        url,
        'POST',
        {
          ...certification,
          start_date: certification.start_date?.format('YYYY-MM-DD'),
          end_date: certification.end_date?.format('YYYY-MM-DD'),
        },
      )
        .then((response) => ({ ...response, method: 'POST', entity: 'certifications' }))
    ));
    const updateCertificationsRequests = candidate.certifications.filter(
      ({ id }) => id !== undefined
    ).map((certification) => (
      actions.fetchAndReturn(
        token,
        `${url}${certification.id}/`,
        'PATCH',
        {
          ...certification,
          start_date: certification.start_date?.format('YYYY-MM-DD'),
          end_date: certification.end_date?.format('YYYY-MM-DD'),
        },
      )
        .then((response) => ({ ...response, method: 'PATCH', entity: 'certifications' }))
    ));
    const deleteCertificationsRequests = state.deletedCertifications.map((certificationId) => (
      actions.fetchAndReturn(token, `${url}${certificationId}/`, 'DELETE')
        .then((response) => ({ ...response, method: 'DELETE', entity: 'certifications' }))
    ));
    return [
      ...createCertificationsRequests,
      ...updateCertificationsRequests,
      ...deleteCertificationsRequests,
    ];
  }

  const handleExperiences = () => {
    const { candidate } = state;
    const url = '/api/v1/accounts/experienceinformations/';
    const createExperiencesRequests = candidate.experiences.filter(
      ({ id }) => id === undefined
    ).map((experience) => (
      actions.fetchAndReturn(token, url, 'POST', experience)
        .then((response) => ({ ...response, method: 'POST', entity: 'experiences' }))
    ));
    const updateExperiencesRequests = candidate.experiences.filter(
      ({ id }) => id !== undefined
    ).map((experience) => (
      actions.fetchAndReturn(
        token,
        `${url}${experience.id}/`,
        'PATCH',
        {
          ...experience,
          month_end: experience.month_end || null,
          year_end: experience.year_end || null,
        },
      )
        .then((response) => ({ ...response, method: 'PATCH', entity: 'experiences' }))
    ));
    const deleteExperiencesRequests = state.deletedExperiences.map((experienceId) => (
      actions.fetchAndReturn(token, `${url}${experienceId}/`, 'DELETE')
        .then((response) => ({ ...response, method: 'DELETE', entity: 'experiences' }))
    ));
    return [...createExperiencesRequests, ...updateExperiencesRequests, ...deleteExperiencesRequests];
  };

  const jumpToStep = (step) => shortDispatch('JUMP_STEP', step - state.currentStep);

  const removeCareer = (idx) => shortDispatch('REMOVE_CAREER', idx);

  const removeCertification = (idx) => shortDispatch('REMOVE_CERTIFICATION', idx);

  const removeExperience = (idx) => shortDispatch('REMOVE_EXPERIENCE', idx);

  const saveChanges = () => {
    setLoading(true);
    return Promise.all([
      ...handleCareers(),
      ...handleCertifications(),
      ...handleExperiences(),
      ...handleCandidateData(),
    ])
      .then(results => {
        const errorResponses = results.filter(({ status }) => status >= 400);
        if (errorResponses.length > 0) {
          errorResponses.forEach(({ entity, method }) => (
            message.error(
              i18n.t(
                'form__error_updating',
                {
                  method: errorMessageHelper.method[method],
                  entity: errorMessageHelper.entity[entity],
                },
              )
            )
          ));
          setLoading(false);
          return false;
        }
        setLoading(false);
        message.success(i18n.t('form__successfully_uploading'));
        return true;
      });
  };

  const stepBack = () => {
    if (state.currentStep === 2 && state.jobApplication.optional_fields.careers === 2) {
      return shortDispatch('JUMP_STEP', -1 )
    } else if (state.currentStep === 3 && state.jobApplication.optional_fields.experiences === 2) {
      return shortDispatch('JUMP_STEP', -1 - 1 * (state.jobApplication.optional_fields.careers === 2))
    }
    shortDispatch('JUMP_STEP', -1)
  };

  const stepForward = () => {
    if (state.currentStep === 0 && state.jobApplication.optional_fields.careers === 2) {
      return shortDispatch('JUMP_STEP', 1 + 1 * (state.jobApplication.optional_fields.experiences === 2))
    } else if (state.currentStep === 1 && state.jobApplication.optional_fields.experiences === 2) {
      return shortDispatch('JUMP_STEP', 1)
    }
    shortDispatch('JUMP_STEP', 1)
  };

  const updateCandidate = (fieldName, fieldValue) => shortDispatch(
    'UPDATE_CANDIDATE', { field: fieldName, value: fieldValue }
  );

  const updateCareer = (careerIdx, careerFieldName, value) => shortDispatch(
    'UPDATE_CAREER', { careerIdx, careerFieldName, value }
  );

  const updateCertification = (certificationIdx, certificationFieldName, value) => shortDispatch(
    'UPDATE_CERTIFICATION', { certificationIdx, certificationFieldName, value }
  );

  const updateExperience = (experienceIdx, experienceFieldName, value) => shortDispatch(
    'UPDATE_EXPERIENCE', { experienceIdx, experienceFieldName, value }
  );

  const updateCvType = (cvType) => shortDispatch('UPDATE_CV_TYPE', cvType);

  const updateCvParsedInfo = (data) => shortDispatch('UPDATE_CV_PARSED_INFO', data);

  const memoValues = useMemo(
    () => ({
      ...state,
      loading,
      addCareer,
      addCertification,
      addExperience,
      jumpToStep,
      removeCareer,
      removeCertification,
      removeExperience,
      saveChanges,
      stepBack,
      stepForward,
      updateCandidate,
      updateCareer,
      updateCertification,
      updateCvParsedInfo,
      updateCvStatus,
      updateCvType,
      updateExperience,
    }),
    [state.candidate, state.currentStep, jobAppId, loading]
  );

  return (
    <PersonalFormContext.Provider value={memoValues}>
      {children}
    </PersonalFormContext.Provider>
  );
};
