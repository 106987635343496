import React from 'react'

import { Card, Tooltip, Typography, Slider, Icon } from 'antd'


const CognitiveSlider = (props) => {

  const { Title, Text } = Typography

  return (
    <Card
      className='cognitive-slider'
      title={
        <Title level={4} >
          <Tooltip title={props.tooltip} placement="right">
            {props.title}
            {' '}
            <Icon type='info-circle' style={{fontSize: 15}} />
          </Tooltip>
        </Title>
      }
      extra={
        <Title level={4}>
          {`${props.sliderPercent}${'%'}`}
        </Title>} style={{ width: '100%' }}
    >
      <div className={props.value1 == props.value2 && 'custom-slider'}>
        <Slider
          range
          defaultValue={[props.value1, props.value2]}
          disabled={true}
        />
      </div>

      <div className='cognitive-slider-descriptions' >
        <Text>
          {props.subTitle1}
        </Text>
        <Text>
          {props.subTitle2}
        </Text>
      </div>

    </Card>
  )
}

export default CognitiveSlider