import React from 'react'
import { connect } from 'react-redux';
import { JobAppProvider } from './context/JobAppContext'
import JobappForm from './components/JobappForm'

import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../actions/data';

import './JobAppForm.scss'

function ProcessFlexForm(props) {

  return (
    <JobAppProvider {...props}>
      <div className='job-application-form'>
        <JobappForm />
      </div>
    </JobAppProvider>

  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    businessId: state.auth.business.id,
    businessPermissions: state.auth.businessPermissions,
    addOns: state.auth.addOns
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcessFlexForm);
