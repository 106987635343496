import React from 'react';
import { Link } from 'react-router-dom';

import { Badge, Col, Row, Typography } from 'antd';

const { Text } = Typography;

export function BusinessUserNotificationItem({ callback, date, redirectPath, text, read=false }) {
  return (
    <Link to={redirectPath} onClick={callback}>
      <Row
        type='flex'
        align='middle'
        justify='space-between'
        className='custom-notification-item'
      >
        <Col span={date ? 18 : 24}>
          <Row align="middle" type="flex">
            <Badge color={(date && !read) ? "blue" : "transparent"} />
            <Text style={{ maxWidth: '90% '}}>{text}</Text>
          </Row>
        </Col>
        <Col span={date ? 6 : 0}>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            {date}
          </div>
        </Col>
      </Row>
    </Link>
  )
};
