import {
  Button,
  Col,
  Icon,
  Row,
  Typography
} from 'antd';
import React, { useContext, useEffect, useState } from 'react'

import AdditionalFilesRequest from './FilesRequests/FilesRequests'
import AdditionalQuestions from './AdditionalQuestions/AdditionalQuestions';
import CustomTests from './CustomTest/CustomTests';
import EvaluationList from './StageEvaluation/EvaluationList';
import Games from './Games/Games';
import GenomeConfiguration from './GenomeConfiguration/Configuration';
import InterviewKits from './InterviewKits/InterviewKits';
import { InterviewsContextProvider } from '../../../Interviews/config/context';
import JobAppContext from '../../context/JobAppContext';
import JobApplicationInterviewProcess from './InterviewProcess';
import TriggerComponentModal from '../../../Triggers';
import VideoInterview from './VideoInterview/VideoInterview';
import useBoolean from '../../../../../hooks/useBoolean';

const evaluationsSections = [
  /*
  {
    key: 'basal',
    img: 'https://genoma-assets.s3.us-east-2.amazonaws.com/adn.svg',
    title: 'Genoma basal'
  },
  */
  {
    key: 'complementary',
    img: 'https://genoma-assets.s3.us-east-2.amazonaws.com/puzzle.svg',
    title: 'Evaluaciones complementarias'
  },
  {
    key: 'troncal',
    img: 'https://genoma-assets.s3.us-east-2.amazonaws.com/chip.svg',
    title: 'Evaluaciones troncales'
  },
  {
    key: 'interviews',
    img: 'https://genoma-assets.s3.us-east-2.amazonaws.com/puzzle.svg',
    title: 'Entrevistas'
  },
   {
     key: 'stageNull',
     img: 'https://genoma-assets.s3.us-east-2.amazonaws.com/puzzle.svg',
     title: 'Otras acciones'
   }
];

function JobAppEvaluations() {
  const [activeEvaluation, setActiveEvaluation] = useState('complementary__koQuestions');
  const [showTriggerModal, setShowTriggerModal] = useBoolean(false);

  const {
    business,
    currentStage,
    updateCurrentStage,
    stages,
    jobApplication,
    cuTes,
    games,
    videoInterviews,
    additionalQuestions,
    triggers,
    updateTriggers,
  } = useContext(JobAppContext);

  const evaluationsTypes = {
    //'basal': [],
    'troncal': [
      { name: 'Cognitiva', key: 'cognitive' },
      { name: 'Conductual', key: 'behavioral' },
      { name: 'Personalidad', key: 'personality' }
    ],
    'complementary': [
      { name: 'Preguntas adicionales', key: 'koQuestions' },
      { name: 'Conocimiento y habilidades específicas', key: 'cuteGenoma' },
      { name: 'Evaluaciones personalizadas', key: 'cuteBusiness' },
    ],
    'interviews': [
      { name: 'Entrevistas por competencias', key: 'teraInterview', style: { backgroundColor: '#F9F0FF', color: '#9254DE' } },
      { name: 'Video entrevistas', key: 'vint' },
      { name: 'Entrevistas personalizadas', key: 'interviewKits', available: !!business.addOns.find(add_on => add_on == 'IKIT') },
    ],
    'stageNull': [
      { name: 'Solicitud de documentos', key: 'additionalFiles' }
    ],
  }

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const params = hash.split('#')[1];
      const option = params.split('__')[1];
        if (option === 'triggers') {
          setShowTriggerModal.on();
        }
      }
    }, []);

  useEffect(() => {
    setActiveEvaluation('complementary__koQuestions');
    // Si se cambia de stage seteamos la nav bar dependiendo de la etapa
  }, [jobApplication.genome, currentStage]);

  const buildNavigationButtons = (evalTypeKey) => {
    let disableButton = false;
    let buttonExtraClass = {};
    const evalKeys = activeEvaluation.split('__');
    const isActiveType = evalKeys[0] === evalTypeKey;

    const hasCuTeAddon = business.addOns.find(obj => obj.add_on === 'CUTE');

    let objArray = evaluationsTypes[evalTypeKey].filter(obj => obj.available !== false);

    if (evalTypeKey === 'complementary' && !hasCuTeAddon) {
      objArray = objArray.filter(obj => obj.key !== 'cuteBusiness')
      // Las pruebas de conocimeinto (las que proporciona genoma) son libres para todos
      // objArray = objArray.filter(obj => obj.key !== 'cuteGenoma')
    }

    const components = objArray.map(obj => {
      const buttonClass = isActiveType && obj.key == evalKeys[1] ? 'evaluations-button__active' : 'evaluations-button';
      return (
        <div className={buttonClass} style={buttonExtraClass} key={obj.key} onClick={() => !disableButton && setActiveEvaluation(`${evalTypeKey}__${obj.key}`)}>
          {obj.name}
        </div>
      )
    });
    return components;
  };

  const getEvaluationTitle = () => {
    const currentKey = activeEvaluation.split('__');
    const evaluationGroup = evaluationsSections.find(obj => obj.key === currentKey[0]);
    const evaluationType = evaluationsTypes[evaluationGroup.key].find(obj => obj.key === currentKey[1])
    return `${evaluationGroup?.title}: ${evaluationType?.name}`
  };

  const buildNavigation = () => {
    // TODO deprecar ikits en algun momento

    const components = evaluationsSections.filter(obj => evaluationsTypes[obj.key].length > 0).map((obj, idx) => {
      return (
        <div 
          className='evaluations-section'
          key={obj.key}
          style={
            {
              marginTop: idx === 0 ? 0 : 20,
              ...evaluationsTypes[obj.key].style
            }
          }
        >
          <Row type='flex' justify='space-between' align='middle' style={{ width: '100%' }}>
            <Col className='evaluations-item'>
              <img className='evaluations-sub-title' src={obj.img} />
              <span className='evaluations-sub-title'>{obj.title}</span>
            </Col>
            <Col className='evaluations-item'>
              {obj.key === 'troncal' &&
                business.addOns.find(obj => obj.add_on == 'TEDT') &&
                <Icon type='setting' style={{ color: '#F175A5' }} onClick={() => setActiveEvaluation('troncal__genomeConfiguration')} />}
            </Col>
          </Row>
          {buildNavigationButtons(obj.key)}
        </div>
      );
    })

    return components;
  };

  const getEvaluationsCards = () => {
    const evalKeys = activeEvaluation.split('__');
    const title = getEvaluationTitle();
    switch (evalKeys[1]) {
      case 'cognitive':
        return <Games type={evalKeys[1].toUpperCase()} title={title} />
      case 'behavioral':
        return <Games type={evalKeys[1].toUpperCase()} title={title} />
      case 'personality':
        return <Games type={evalKeys[1].toUpperCase()} title={title} />
      case 'cuteGenoma':
        return <CustomTests type={'knowledge'} title={title} />;
      case 'koQuestions':
        return <AdditionalQuestions title={title} />;
      case 'vint':
        return <VideoInterview title={title} />;
      case 'cuteBusiness':
        return <CustomTests type={'custom'} title={title} />;
      // TODO deprecar ikits
      case 'interviewKits':
        return <InterviewKits />;
      case 'additionalFiles':
        return <AdditionalFilesRequest />;
      case 'genomeConfiguration':
        return <GenomeConfiguration />;
      case 'teraInterview':
        return <InterviewsContextProvider><JobApplicationInterviewProcess /></InterviewsContextProvider>;
    }
  };

  const hasActivities = () => {
    if (stages.findIndex(obj => obj.id === currentStage) == 0) {
      return true;
    }
    else if (cuTes.currentEvaluations.filter(obj => obj.stage === currentStage).length ||
      games.currentEvaluations.filter(obj => obj.stage === currentStage).length ||
      videoInterviews.currentEvaluations.filter(obj => obj.stage === currentStage).length ||
      additionalQuestions.currentEvaluations.filter(obj => obj.stage === currentStage).length) {
      return true;
    };
    return false;
  };

  return (
    <>
      <div className='evaluations-step-content-container'>
        <div className='evaluations-step-content'>
          <Row type='flex' justify='space-between'>
            <Col className='evaluations-title' style={{ fontSize: 24 }} span={24} >
              <Typography.Text>Evaluaciones agregadas</Typography.Text>
            </Col>
            <Col span={12} style={{ marginBottom: 20 }}>
              {
                hasActivities() ?
                  <Typography.Text>Valida las evaluaciones que has agregado.</Typography.Text> :
                  <Typography.Text>Agrega evaluaciones en esta etapa</Typography.Text>
              }
            </Col>
            <Col span={12}>
              <div style={{display: 'flex', justifyContent: 'end'}}>
                <Button type='primary' onClick={() => setShowTriggerModal.on()} icon='thunderbolt' style={{ color: "white" }}>
                  Automatizaciones
                </Button>
              </div>
            </Col>
          </Row>
          <EvaluationList />
        </div>
        <div className='evaluations-step-content'>
          <Row type='flex'>
            <Col className='evaluations-title' style={{ fontSize: 24 }} span={24} >
              Evaluaciones disponibles
            </Col>
            <Col span={24} style={{ marginBottom: 20 }}>
              <Typography.Text>Agrega las evaluaciones que deseas incluir en esta etapa del proceso de selección.</Typography.Text>
            </Col>
          </Row>
          <Row type='flex' gutter={[20, 20]} >
            <Col xs={12} sm={8} lg={6} data-tour="genoma-evaluations">
              {buildNavigation()}
            </Col>
            <Col xs={12} sm={16} lg={18}>
              {getEvaluationsCards()}
            </Col>
          </Row>
        </div>
      </div>
      <TriggerComponentModal
        visible={showTriggerModal}
        setVisible={setShowTriggerModal.off}
        currentTriggers={triggers.currentTriggers}
        stages={stages}
        currentEvaluations={cuTes.currentEvaluations}
        tags={jobApplication.tags?.map(tag => tag.name)}
        updateTriggers={updateTriggers}
        triggersToRemove={triggers.toRemove}
        mode='edit'
      />
    </>
  );
};

export default JobAppEvaluations;
