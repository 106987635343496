import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';
import * as authActionCreators from '../actions/auth';
import { bindActionCreators } from 'redux';

export default function requireAnyAuth(Component) {
    class AuthenticatedComponent extends React.Component {
        static propTypes = {
            isAuthenticated: PropTypes.bool.isRequired,
            location: PropTypes.shape({
                pathname: PropTypes.string.isRequired
            }).isRequired,
            dispatch: PropTypes.func.isRequired
        };

        componentWillMount() {
            this.checkAuth();
            if(this.props.token != null){
                this.checkFirstPassword();
            }
        }

        componentWillReceiveProps(nextProps) {
            this.checkAuth();
            if(this.props.token != null){
                this.checkFirstPassword();
            }
        }

        checkFirstPassword() {
            this.props.authActions.authCheckFirstPassword(this.props.token, this.props.location.pathname);
        }

        checkAuth() {
            const redirectAfterLogin = this.props.location.pathname;
            const storageToken = localStorage.getItem('token');
            if (!this.props.isAuthenticated && storageToken != null) {
                this.props.authActions.authRedirect(redirectAfterLogin);
            }
            else if (!this.props.isAuthenticated) {
                this.props.authActions.authLogout(this.props.token, `/login?next=${redirectAfterLogin}`);
            }
            else if (localStorage.getItem('token') == null) {
                this.props.authActions.authLogout(
                    this.props.token,
                    `/login?next=${redirectAfterLogin}`,
                    {
                        "generic": "Se ha cerrado la sesión remotamente, por favor inicie sesión nuevamente"
                    }
                );
                //this.props.dispatch(push(`/login?next=${redirectAfterLogin}`));
            }
        }

        render() {
            return (
                <div>
                    {this.props.isAuthenticated === true
                        ? <Component {...this.props} />
                        : null
                    }
                </div>
            );
        }
    }

    const mapStateToProps = (state) => {
        return {
            isAuthenticated: state.auth.isAuthenticated,
            userType: state.auth.userType,
            token: state.auth.token
        };
    };

    const mapDispatchToProps = (dispatch) => {
        return {
            authActions: bindActionCreators(authActionCreators, dispatch),
            dispatch
        };
    };

    return connect(mapStateToProps,mapDispatchToProps)(AuthenticatedComponent);
}
