import { Checkbox, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';

import i18n from '../../i18n';

const { Text } = Typography;

const AvailabilitySelect = ({ schedule = {}, handleChange, index }) => {
  const [selectedBlocks, setSelectedBlocks] = useState(() => {
    const initialBlocks = {};
    Object.keys(schedule).forEach((day) => {
      initialBlocks[day] = Array(96).fill(0);
    });
    return initialBlocks;
  });

  useEffect(() => {
    handleChange(index, selectedBlocks);
  }, [selectedBlocks, handleChange, index]);

  const handleCheckboxChange = (day, blockStartIndex, blockEndIndex) => {
    setSelectedBlocks((prev) => {
      const newDayBlocks = [...prev[day]];
      for (let i = blockStartIndex; i <= blockEndIndex; i++) {
        newDayBlocks[i] = newDayBlocks[i] === 1 ? 0 : 1;
      }
      return { ...prev, [day]: newDayBlocks };
    });
  };

  const convertToBlocks = (daySchedule) => {
    let blocks = [];
    let startBlock = null;
    daySchedule.forEach((value, index) => {
      if (value === 1 && startBlock === null) {
        startBlock = index;
      } else if (value === 0 && startBlock !== null) {
        blocks.push([startBlock, index - 1]);
        startBlock = null;
      }
    });
    if (startBlock !== null) {
      blocks.push([startBlock, daySchedule.length - 1]);
    }
    return blocks;
  };

  const formatBlockTime = (startIndex, endIndex) => {
    const startHour = Math.floor(startIndex / 4);
    const startMinutes = (startIndex % 4) * 15;
    const endHour = Math.floor((endIndex + 1) / 4); // +1 para incluir el final del bloque
    const endMinutes = ((endIndex + 1) % 4) * 15; // +1 para incluir el final del bloque
    return `${startHour.toString().padStart(2, '0')}:${startMinutes
      .toString()
      .padStart(2, '0')} - ${endHour.toString().padStart(2, '0')}:${endMinutes
      .toString()
      .padStart(2, '0')}`;
  };

  const renderDaySchedule = (day) => {
    const dayBlocks = convertToBlocks(schedule[day]);
    if (dayBlocks.length === 0) {
      return <Text>Día no disponible</Text>;
    }

    return dayBlocks.map(([start, end], index) => {
      const isChecked = selectedBlocks[day]
        .slice(start, end + 1)
        .some((val) => val === 1);
      return (
        <div
          key={index}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '0.5rem',
            border: `1px solid ${isChecked ? '#F175A5' : '#D9D9D9'}`,
            padding: '0.5rem 1rem',
            borderRadius: '2px',
            backgroundColor: isChecked ? '#FFF0F6' : '#ffffff',
          }}
        >
          <Checkbox
            checked={isChecked}
            onChange={() => handleCheckboxChange(day, start, end)}
          />
          <Text style={{ color: isChecked ? '#F175A5' : '' }}>
            {formatBlockTime(start, end)}
          </Text>
        </div>
      );
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      {Object.keys(schedule).map((day) => (
        <Row key={day} type="flex" align="middle" style={{ gap: '1rem' }}>
          <Text strong style={{ width: 100 }}>
            {i18n.t(`commons__dayofweek__${day}`)}
          </Text>
          <Row type="flex" style={{ gap: '1.5rem' }}>
            {renderDaySchedule(day)}
          </Row>
        </Row>
      ))}
    </div>
  );
};

export default AvailabilitySelect;
