import React from 'react';


const Card = ({ height, minHeight = 200, title, children, stretch=false}) => {
  const width = stretch ? '100%' : 'auto';
  return (
    <div style={{ height, minHeight, width }} className="new-modal-card">
      {title ? <div className="new-modal-card__header">{title}</div> : null}
      <div className="new-modal-card__content">{children}</div>
    </div>
  );
};

export default Card;
