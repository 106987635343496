import React, { useContext, useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import { Row, Typography, Select, Spin } from 'antd';
import { AnalyticsContext } from '../../context/AnalyticsContext';
import { trigramSimilarity, unaccent } from '../../../../../utils/search';

const { Title } = Typography;
const { Option } = Select;

const SearchFilter = () => {
  const {
    peopleAnalytics: { job_apps },
    jobAppId,
    handleJobApp,
    isLoading,
  } = useContext(AnalyticsContext);

  const [inputFilter, setInputFilter] = useState('');
  const [localJobapps, setLocalJobapps] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showJobApps, setShowJobApps] = useState(false);
  const toggleList = () => setShowJobApps((bool) => !bool);

  const handleChange = (e) => {
    if (!showJobApps) toggleList();
    setInputFilter(e.target.value);
    if (e.target.value) {
      handleJobApp(e.target.value);
    } else {
      handleJobApp(null);
    }
  };

  useEffect(() => {
    const sortedJobApps = sortJobApps(job_apps.results);
    setLocalJobapps(sortedJobApps);
  }, [job_apps.results]);

  const sortJobApps = (jobApplications) => {
    const sortedJobApps = [
      ...jobApplications.sort((a, b) => {
        return a.job_app.job_application === b.job_app.job_application
          ? 0
          : a.job_app.job_application > b.job_app.job_application
          ? 1
          : -1;
      }),
    ];
    return sortedJobApps;
  };

  const cleanFilter = (e) => {
    setInputFilter('');

    // if jobAppId exists in the context (we are looking one process) => call handleJobApp function without an argument (going to the cache to retrieve all processes)
    // swapping one process => all processes is fast because we have all processes in the state
    if (jobAppId) handleJobApp();
  };

  // using debounce to optimize the search filter, we dont want to re-render all the content everytime the input changes
  // with this approach, we only filter the jobapps 500ms after the last click in the keyboard
  const filterOption = (input, option) => {
    const searchInput = unaccent(input.toLowerCase());
    const jobAppName = unaccent(option.toLowerCase());

    const sim = trigramSimilarity(searchInput, jobAppName);
    return jobAppName.includes(searchInput) || sim > 0.2;
  };

  const onSearch = debounce((value) => {
    setLoading(true);
    const sortedJobApps = sortJobApps(job_apps.results);
    const filteredJobApps = value
      ? sortedJobApps.filter((x) =>
          filterOption(value, x.job_app.job_application)
        )
      : sortedJobApps;
    setLocalJobapps(filteredJobApps);
    setLoading(false);
  });

  return (
    <Select
      onChange={(v) => handleChange({ target: { value: v.key } })}
      notFoundContent={loading ? <Spin spinning /> : <span>No encontrado</span>}
      showSearch
      labelInValue
      // default value is null, so we can use the placeholder
      // defaultValue=''
      size="large"
      filterOption={false}
      onSearch={debounce(onSearch, 2000)}
      style={{ width: '100%' }}
      placeholder="Buscar proceso"
    >
      <Option key="defaultjobapp" value={null}>
        Todos los procesos
      </Option>
      {localJobapps.map((jobapp) => {
        return (
          <Option
            key={jobapp.job_app_id}
            id={jobapp.job_app_id}
            value={jobapp.job_app_id}
            title={jobapp.job_app.job_application}
          >
            {jobapp.job_app.job_application}
          </Option>
        );
      })}
    </Select>
  );
};

export default SearchFilter;
