import React from 'react';

import { Form, Input, Row, Col, Select, Typography, Radio } from 'antd';

const { Title } = Typography;

const types = ['Videoentrevista', 'Presencial'];
const videoTypes = ['Google Meet', 'Microsoft Teams', 'Zoom'];

const Details = ({ form, jobApp, selectedCandidates, interviewType }) => {
  const { getFieldDecorator, getFieldValue } = form;

  return (
    <div className="schedule-details-form">
      <Title level={4}>Detalles</Title>
      <Row type="flex" gutter={[32, 0]}>
        <Col xs={24}>
          <Form.Item label="Título">
            {getFieldDecorator('title', {
              initialValue: `Entrevista para ${jobApp.job_application}`,
              rules: [
                {
                  required: true,
                  message: 'Por favor ingresa el título!',
                },
              ],
            })(<Input placeholder="Escribir aquí título de entrevista..." />)}
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item label="Descripción">
            {getFieldDecorator('description', {
              initialValue:
                'En base a tu postulación y como parte del proceso de selección, te invitamos a una entrevista presencial',
            })(
              <Input.TextArea placeholder="Escribe aquí información relevante sobre la entrevista..." />
            )}
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item label="Tipo de entrevista">
            {getFieldDecorator('interview_type', {
              initialValue: 'Presencial',
              rules: [
                {
                  required: true,
                  message: 'Por favor ingresa el tipo de entrevista!',
                },
              ],
            })(
              <Select
                placeholder="Selecciona el tipo de entrevista..."
                disabled
              >
                {types.map((type) => (
                  <Select.Option value={type} key={type}>
                    {type}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item label="Ubicación">
            {getFieldDecorator('address', {
              initialValue: jobApp.address,
              rules: [
                {
                  required: true,
                  message: 'Por favor ingresa la ubicación!',
                },
              ],
            })(<Input placeholder="Escribe la dirección de la reunión..." />)}
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item label="Idioma">
            {getFieldDecorator('language', {
              initialValue: 'Español',
              rules: [
                {
                  required: true,
                  message: 'Por favor ingresa el idioma!',
                },
              ],
            })(<Select disabled />)}
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default Details;
