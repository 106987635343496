import { Button, Modal, Popover, Tag, Tooltip, Typography } from "antd";
import React, { useState } from "react";

import { connect } from "react-redux";
import i18n from "../../../i18n";
import { push } from "react-router-redux";
const { Text, Title } = Typography;

const coinEmpty = 'https://genoma-assets.s3.us-east-2.amazonaws.com/coinWithoutSign.png'
const badgeLockedIMG = 'https://genoma-assets.s3.us-east-2.amazonaws.com/badgeLocked.png';

const Activity = (props) => {
  const initialState = {
    colorIcon: null,
    grayIcon: null,
    open: false,
    content: "",
    visibleLockedModal: false,
  };
  const [state, setState] = useState(initialState);
  const {
    businessSlug,
    code,
    colorTheme,
    icon,
    status,
    jobAppId,
    time_estimated,
    prvw,
  } = props;

  const getUrl = () => {
    let url = `/jobapplication/${jobAppId}/${code}`;
    if (code === "CUTE") {
      const assActId = props.id;
      const cuteId = JSON.parse(props.configuration).id;
      const assActPP = props.progress.id;
      url = `${url}?cuteId=${cuteId}&assActId${assActId}&assActPP=${assActPP}`;
    } else if (code === "INTR") {
      url = `/jobapplication/${jobAppId}/interview/${props.personal_interview_id}`;
    } else if (code === "VINT") {
      url = `${process.env.REACT_APP_VIDEO_FRONT_URL}/processes/${props.video_interview_url}/`;
    } else if (code === "DOCR") {
      const request = JSON.parse(props.configuration).id;
      url = `${url}?requestId=${request}`;
    }
    return url;
  };

  const handleClick = () => {
    const personalForm = props.activityItems.find(act => act.code === 'FORM');

    const url = getUrl();
    if (prvw) {
      return;
    }

    if (status === "ABORTED" || status === "PENDING") {
      if (code !== "INTR") {
        setState({
          ...state,
          visibleLockedModal: true,
          content: i18n.t("candidate__path__new__locked__text"),
        });
        return;
      }
    }

    if (status === "DONE" && code !== "FORM" && code !== 'DOCR') {
      setState({
        ...state,
        open: true,
        content: (
          <div style={{ display: "flex" }}>
            <div>{i18n.t("candidate_path__game__completed")}</div>
          </div>
        ),
      });
      return;
    }

    if (personalForm && personalForm.status !== 'DONE' && code !== 'FORM') {
      setState({
        open: true,
        content: i18n.t('candidate_path__activity__data__filled')
      });
      return;
    }

    const assActId = props.id;

    if (code === "VINT") {
      window.open(url);
      return;
    }

    // TODO arreglar esta condicion
    if (code === "CUTE" && assActId !== undefined) {
      props.dispatch(push(url));
      return;
    } else {
      props.dispatch(push(url));
      return;
    }
  };

  const handleCancelModal = () => setState({ ...state, visibleLockedModal: false });

  const getCoinStatus = (code, status) => {
    if (code === "INTR") {
      return status !== 'PENDING'
        ? "small-heart"
        : "aborted small-heart";
    } else if (code === "CUTE" || code === "DOCR") {
      return !status || status !== "DONE"
        ? "aborted small-heart"
        : "small-heart"
    } else {
      return !status || ["PLAYABLE", "INITIAL", "ABORTED", "PENDING"].includes(status)
        ? "aborted small-heart"
        : "small-heart";
    }
  }

  return (
    <React.Fragment>
      <div
        className={prvw ? "outer-circle" : "outer-circle-playable"}
        onClick={handleClick}
      >
        <Popover
          trigger="click"
          overlayClassName="activity-popover"
          onOpenChange={() => handleClose}
          mouseLeaveDelay={1}
          onClick={() => setState(oldState => ({ ...oldState, open: !state.open }))}
          content={
            props.blocked
              ? <Text strong>{i18n.t("candidate_path__activity__pending")}</Text>
              : <Text strong>{state.content}</Text>
          }
        >
          <div
            className="hexagono"
            style={{ position: "absolute", zIndex: 99 }}
          >
            <img
              style={{ width: "100%" }}
              src="https://i.imgur.com/tQCIULu.png"
            />
          </div>

          <img
            src={
              icon === "FORM"
                ? "https://i.imgur.com/IcMX6cs.png"
                : icon === "KO"
                  ? "https://i.imgur.com/EUXTknN.png"
                  : icon?.includes(".svg") || icon?.includes(".png")
                    ? `https://genoma-assets.s3.us-east-2.amazonaws.com/${icon}`
                    : `https://genoma-assets.s3.us-east-2.amazonaws.com/${icon}.png`
            }
            className={
              (props.blocked && !['VINT', 'INTR', 'DOCR'].includes(code))
                ? "activity-icon blocked-activity"
                : "activity-icon"
            }
          />

          <div
            className={getCoinStatus(code, status)}
            style={{ backgroundImage: `url(${coinEmpty})` }}
          >
            {time_estimated ? (<span className="cp-coins">x{time_estimated}</span>) : null}
          </div>
        </Popover>
      </div>
      <div>
        <Text strong className="label-icon">
          {(
            typeof props.translation !== "undefined"
              ? props.translation[i18n.language.split("-")[0]]?.includes("Mobile")
              : props.activity && props.activity.includes("Mobile")
          ) ? (
            <span>
              <div>
                {typeof props.translation !== "undefined"
                  ? props.translation[i18n.language.split("-")[0]]?.replace(
                    "Mobile",
                    "")
                  : props.activity && props.activity.replace("Mobile", "")}
              </div>
              {props.activity.toLowerCase().includes("letras")
                ? (
                  <div>
                    <Tooltip color="#ea4c896b" className="mobile-tag">
                      *{i18n.t("commons__process__language")}
                    </Tooltip>
                  </div>
                ) : ("")}
              <div>
                <Tag
                  color={
                    ["banco-internacional"].includes(businessSlug)
                      ? colorTheme.background
                      : "#ea4c896b"
                  }
                  className="mobile-tag"
                >
                  Mobile
                </Tag>
              </div>
            </span>
          ) : ["CUTE", "DOCR"].includes(code) ? (
            (props.prvw ? props.candidate_path_title : JSON.parse(props.configuration).name)
          ) : typeof props.translation === "undefined" ? (
            props.activity
          ) : (
            props.translation[i18n.language.split("-")[0]]
          )}
        </Text>
      </div>
      <Modal
        centered
        className="lockedGameModal"
        visible={state.visibleLockedModal}
        onOk={handleCancelModal}
        onCancel={handleCancelModal}
        footer={[
          <Button key="submit" type="primary" onClick={handleCancelModal}>
            {i18n.t("candidate__path__understand")}
          </Button>,
        ]}
      >
        <div className="content">
          <Title level={3}>{i18n.t("candidate__path__locked")}</Title>
          <img src={badgeLockedIMG} /> <br />
          <Text>{state.content}</Text>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const mapActionsToProps = (dispatch) => {
  return {
    dispatch,
  };
};
export default connect(mapActionsToProps)(Activity);
