import React from 'react';

import { Layout, Row, Col, Typography, Input, Button, Popover, Icon } from 'antd';
const { Title, Text } = Typography;

const EditCardList = ({ list, fnEdit, fnCopy, fnDelete }) => {
  return (
    <Layout style={{ background: 'white' }} className='layoutCustomInterview'>
      {
        list.length > 0 &&
        (
          <Title level={4} style={{ margin: '0 0 20px 20px', color: '#5E5E5E' }}>
            Habilidades creadas
          </Title>
        )
      }
      {list.map((item, index) => (
        <Row key={item.id} className='formNewQuestions'>
          <Col style={{ marginRight: '40px' }}>
            <Title level={4} style={{ color: '#5E5E5E' }} >
              Habilidad
              <Popover placement="topRight" content="Podrás evaluar las habilidades de tus candidatos mediante 5 estrellas"><Icon style={{ marginLeft: '5px' }} type='info-circle' /></Popover>
            </Title>
            {/* <Input disabled value={item.text} size='large' /> */}
            <Row style={{ border: '1px solid #D9D9D9', padding: '5px', cursor: 'not-allowed' }}>
              <Text>{item.text}</Text>
            </Row>
          </Col>
          <Col>
            <Title level={4} style={{ color: '#5E5E5E' }} >Acciones</Title>
            <Button icon='edit' onClick={() => fnEdit(index, item.id)} style={{ border: 'none', color: '#A4A4A4', boxShadow: 'none' }} />
            <Button onClick={() => fnCopy(index)} icon='copy' style={{ border: 'none', color: '#A4A4A4', boxShadow: 'none' }} />
            <Button icon='delete' onClick={() => fnDelete({ state: true, idQuestion: item.id })} style={{ border: 'none', color: '#A4A4A4', boxShadow: 'none' }} />
          </Col>
        </Row>
      ))}
    </Layout>
  );
}

export default EditCardList;