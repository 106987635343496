import React, { Component, createContext } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { showModal, hideModal } from '../../../actions/modal';
import * as actionCreators from '../../../actions/data';
import * as jobappActions from '../../../actions/jobapp';
import * as traitActions from '../../../actions/trait';

import JobState from './components/JobState/JobState';
import Wizard from './components/Wizard/Wizard';
import {
  JobDetail,
  ApplicationForm,
  Stages,
  IdealGenome,
  VideoInterview,
  InterviewKit,
  PublishJob
} from './components/StepContent';

import {
  Alert,
  Input,
  Layout,
  Row,
  Col,
  Modal,
  Button,
  Typography,
  Select,
  message,
  List
} from 'antd';
import './NewProcessForm.scss';

export const AppContext = createContext();
const { Sider, Content } = Layout;
const { Title, Text } = Typography;
let nextStep = null;
const wizardTitles = [
  { name: 'Información', code: 'required' },
  { name: 'Etapas', code: 'required' },
  { name: 'Formulario', code: 'required' },
  { name: 'Evaluaciones', code: 'required' },
  { name: 'Video entrevista', code: 'required' },
  { name: 'Entrevistas personalizadas', code: 'IKIT' },
  { name: 'Resumen', code: 'required' }
];

const alternatives = [
  { "code": "ACTIV", "text": "En proceso" },
  { "code": "DONE", "text": "Completado" },
  { "code": "V_INT", "text": "Video entrevista" },
  { "code": "V_INTR", "text": "Video entrevista terminada" },
  { "code": "V_INTE", "text": "Video entrevista evaluada" },
  { "code": "HIRED", "text": "Contratado" },
  { "code": "OTHER", "text": "Descartado" }
]


class NewProcessFormView extends Component {
  state = {
    jobAppStatusModalClosed: false,
    current: { name: "Información", pos: 0 },
    hasSider: undefined,
    openSnackbar: false,
    snackbarText: '',
    tabIndex: '1',
    jobApplication: {
      tags: [],
      job_application: 'Seleccionar opción',
      department: '',
      location_country: '',
      location_city: '',
      date: '',
      closure_date: '',
      description: undefined,
      welcome_text: undefined,
      language: 'es',
      genome_id: '',
      status: '',
      stages: '',
      optional_cv: false,
      optional_fields: {
        name: false,
        surname: false,
        phone: false,
        careers: false,
        experiences: false,
        cv: false,
        gender: false,
        birth_date: false,
        //has_disability: false,
        //disability_type: false,
      },
      job_portals: {
        chiletrabajo: false,
        indeed: false,
        laborum: false,
        trabajando: false
      },
      addInterviewKit: [],
      deleteInterviewKit: [],

      addCustomTest: [],
      deleteCustomTest: [],
      currentCustomTests: undefined,

      integrations: [],
      youtube_intro_video: null,
      custom_fields: {},
      // TODO: file_custom_fields_updated: [],
    },
    editedVideo: false,
    createdVideo: false,
    videoInterviewModal: false,
    newIntegrations: [],
    errors: {
      job_application: true,
      department: true,
      location_city: true,
      closure_date: true,
      welcome_text: true,
    },
    dateIsPast: false,
    activities: [],
    collaboratorsList: [],
    permissions: [],
    error: false,
    integrations: [],
    jobAppIntegrations: [],
    inputVisible: false,
    inputValue: '',
    tagsP: [],
    canEdit: true,
    customFieldsEdit: {},
    wizardSteps: [],
    jobAppCurrentStatus: 'ACTIV',
  };

  handleCustomFields = (name, value) => {
    let customFields = this.state.customFieldsEdit;
    customFields[name] = value;
    this.setState({ customFieldsEdit: customFields });
  }

  handleSubmitIntegration = (integration) => {
    let jobapp = this.state.jobApplication;
    let customFields = this.state.customFieldsEdit;

    jobapp.custom_fields = customFields;
    this.setState({ jobAppplication: jobapp });
  }

  findStage = (code) => {
    const index = alternatives.map(option => option.code).indexOf(code)
    return alternatives[index] ? alternatives[index].text : ''
  }

  questionType = (q, error, index) => {
    const component = {
      'text': <Input key={index} index={index} placeholder={this.state.jobApplication.custom_fields[index]} onChange={(e) => this.handleCustomFields(index, e.target.value)} />,
      // 'int': <Integer {...q} key={index} index={index} error={error} handleChangeV2={console.log('wena')} disabled={false} />,
      'singleselection': <Select key={index} {...q} index={index} error={error} defaultValue={this.findStage(this.state.jobApplication.custom_fields[index])} options={alternatives} onChange={(e) => this.handleCustomFields(index, e)}>
        {
          alternatives.map((elem) => {
            return <Select.Option key={elem.code} value={elem.code}>{elem.text}</Select.Option>
          })
        }
      </Select>,

    };
    return <div key={index} id={index}>{component[q.type]}</div> || null
  }

  componentDidMount() {
    const token = this.props.token;
    this.setState({ jobappid: this.props.match.params.jobappid });

    this.props.jobappActions.fetchJobApplication(token, this.props.match.params.jobappid).then((res) => {
      const prevUrl = res.youtube_intro_video === "" ? null : res.youtube_intro_video;
      this.setState({
        jobApplication: {
          ...res,
          addInterviewKit: [],
          deleteInterviewKit: [],
          addCustomTest: [],
          deleteCustomTest: [],
          youtube_intro_video: prevUrl,
          // TODO: file_custom_fields_updated: [],
        }
      });
      this.setState({ customFieldsEdit: this.state.jobApplication.custom_fields })
      this.setState({ canEdit: res.status === 'ACTIV' ? false : true });
      this.setState({ jobAppCurrentStatus: res.status });
      this.loadIntegrations();
    });
    const data = { jobapp_id: this.props.match.params.jobappid };
    this.props.jobappActions.fetchActivity(token, this.props.match.params.jobappid, this.props.currentBusiness.id).then(() => {
      this.setState({ activities: this.props.data });
    });
    this.getColaborators();
    this.getSubordinates();

    // Show only the steps after checking the addons
    let wizardSteps = wizardTitles.filter((obj) => {
      let userAddOns = this.props.addOns.map(elem => elem.add_on)
      if (userAddOns.includes(obj.code) || obj.code === "required") {
        return true
      }
    })
    this.setState({ wizardSteps: wizardSteps });
  };

  checkToken = (token, integration) => {
    return integration.auth_token === token
  }

  onChangeIntegration = (integrationKey, integrationFieldKey) => {
    return (event) => {
      const integrations = [...this.state.integrations]
      integrations[integrationKey].fields[integrationFieldKey].value = event.target.value;
      this.setState({ integrations });
    }
  };

  loadIntegrations = () => {
    // Fecth integrations
    const token = this.props.token;
    this.props.jobappActions.fetchIntegrations(this.props.currentBusiness.id, token).then(() => {
      const integrations = [...this.props.integrations];
      this.state.jobApplication.integrations.forEach(jobAppIntegration => {
        // Technical debt O(mn)
        const name = jobAppIntegration.name;
        // Find if an integration (form) matches with current integrations.
        const integration = integrations.find((x) => x.name === name);
        // Autocomplete integrration fields with data from jobapp.
        if (integration) {
          integration.fields.forEach(integrationField => {
            integrationField.value = jobAppIntegration.fields[integrationField.name];
          });
        }
      })
      const activeIntegrations = integrations.filter(obj => obj.status !== 'INACT')
      this.setState({ integrations: activeIntegrations });
    })
    this.props.jobappActions.fetchJobApplicationIntegrations(this.props.match.params.jobappid, token).then(() => {
      const integrations = [...this.props.integrations];
      this.setState({ jobAppIntegrations: integrations })
    })
  }

  onChangeJobPosting = (field) => (event) => {
    const jobapp = { ...this.state.jobApplication };
    // Integrations trigger custom_fields
    if (['status', 'youtube_intro_video', 'location_country', 'closure_date', 'job_portals', 'jobs_site_availability', 'language', 'custom_fields'].includes(field)) {
      jobapp[field] = event;
      // custom_fields.<field_decorator>
    } else if (field.includes('custom_fields')) {
      const name_key = field.split('.')[1];
      // We are not using the FILE tipe
      if (event instanceof File && jobapp.custom_fields[name_key]) {
        // TODO: jobapp.file_custom_fields_updated.push({ id: jobapp.custom_fields[name_key], name_key });
        jobapp.custom_fields[name_key] = event;
      } else if (event.status === 'removed') {
        jobapp.custom_fields[name_key] = null;
        // TODO: jobapp.file_custom_fields_updated.push({ id: event.uid, name_key });
      } else {
        // We are only using event inputs
        jobapp.custom_fields[name_key] = event;
      }
    } else {
      jobapp[field] = event.target.value;
    }
    this.setState({ jobApplication: jobapp });
  }

  onChangeInterviewKit = (kit, type) => {
    if (type === 'add') {
      let kitExistIndex = this.state.jobApplication.addInterviewKit.findIndex(item => item.id === kit.id)
      let checkDeleteKitIndex = this.state.jobApplication.deleteInterviewKit.findIndex(item => item.id === kit.id)

      // Check if already exist if true, don't add
      if (kitExistIndex != -1) {
        return
      }
      // Check if exist in interview kits to delete, if exist remove from deleteInterviewKit and don't add in this state
      if (checkDeleteKitIndex != -1) {
        this.state.jobApplication.deleteInterviewKit.splice(checkDeleteKitIndex, 1)
        this.setState({ jobApplication: { ...this.state.jobApplication, deleteInterviewKit: this.state.jobApplication.deleteInterviewKit } });
      }
      else {
        this.setState({ jobApplication: { ...this.state.jobApplication, addInterviewKit: [...this.state.jobApplication.addInterviewKit, kit] } });
      }
    }

    if (type === 'delete') {
      let kitExistIndex = this.state.jobApplication.deleteInterviewKit.findIndex(item => item.id === kit.id)
      let checkAddKitIndex = this.state.jobApplication.addInterviewKit.findIndex(item => item.id === kit.id)
      // Check if already exist if true, don't delete
      if (kitExistIndex != -1) {
        return
      }
      // Check if exist in interview kits to add, if exist remove from addInterviewKit and don't add in this state
      if (checkAddKitIndex != -1) {
        this.state.jobApplication.addInterviewKit.splice(checkAddKitIndex, 1)
        this.setState({ jobApplication: { ...this.state.jobApplication, addInterviewKit: this.state.jobApplication.addInterviewKit } });
      }
      else {
        this.setState({ jobApplication: { ...this.state.jobApplication, deleteInterviewKit: [...this.state.jobApplication.deleteInterviewKit, kit] } });
      }
    }
  }

  /**
   * Add or remove custom tests, also set de global state with the current tests (array of test after addding and removing)
   * @param {*} test 
   * @param {*} currentTests 
   * @param {*} type 
   * @returns 
   */
  onChangeCustomTest = (test, currentTests, type) => {

    if (type === 'add') {
      const testExistIndex = this.state.jobApplication.addCustomTest.findIndex(item => item.id === test.id)
      const checkDeleteTestIndex = this.state.jobApplication.deleteCustomTest.findIndex(item => item.id === test.id)

      // Check if already exist if true, don't add
      if (testExistIndex != -1) {
        return
      }
      // Check if exist in tests to delete, if exist remove from deleteCustomTest and don't add in this state
      if (checkDeleteTestIndex != -1) {
        this.state.jobApplication.deleteCustomTest.splice(checkDeleteTestIndex, 1)
        this.setState({
          jobApplication: {
            ...this.state.jobApplication,
            deleteCustomTest: this.state.jobApplication.deleteCustomTest,
            currentCustomTests: currentTests
          }
        });
      }
      else {

        this.setState({
          jobApplication: {
            ...this.state.jobApplication,
            addCustomTest: [...this.state.jobApplication.addCustomTest, test],
            currentCustomTests: currentTests
          }
        });
      }
    }

    if (type === 'delete') {
      const testExistIndex = this.state.jobApplication.deleteCustomTest.findIndex(item => item.id === test.id);
      const checkAddTestIndex = this.state.jobApplication.addCustomTest.findIndex(item => item.id === test.id);
      // Check if already exist if true, don't delete
      if (testExistIndex != -1) {
        return
      }
      // Check if exist in tests to add, if exist remove from addCustomTest and don't add in this state
      if (checkAddTestIndex != -1) {
        this.state.jobApplication.addCustomTest.splice(checkAddTestIndex, 1)
        this.setState({
          jobApplication: {
            ...this.state.jobApplication,
            addCustomTest: this.state.jobApplication.addCustomTest,
            currentCustomTests: currentTests
          }
        });
      }
      else {
        this.setState({
          jobApplication: {
            ...this.state.jobApplication,
            deleteCustomTest: [...this.state.jobApplication.deleteCustomTest, test],
            currentCustomTests: currentTests
          }
        });
      }
    }
    // Set current tests
  }

  handleChange = async (event, tabIndex) => {
    await this.saveOnSwitchTab();
    this.setState({ tabIndex });
  };

  fechatoString = (fecha) => {
    return fecha.getFullYear() + '-' + (fecha.getMonth() + 1) + '-' + fecha.getDate()
  }

  handleNext = () => {
    let index = parseInt(this.state.tabIndex) + 1;
    if (this.state.tabIndex === '6') {
      index = 1
    }
    this.setState({ tabIndex: index.toString() });
  }

  handlePublishJob = (shouldRedirect = true) => {
    const jobapp = { ...this.state.jobApplication };
    const date = new Date();
    if (jobapp.status === 'INACT') {
      jobapp.closure_date = this.fechatoString(date);
    }
    this.setState({ jobApplication: jobapp });
    this.updateRelevance(shouldRedirect);
  }

  updateRelevance = shouldRedirect => {
    if (this.checkErrors()) {
      this.props.traitActions.traitsRelevance().then(() => {
        this.submitData(shouldRedirect)
      })
    } else {
      this.openAlertModal(
        "No se puede guardar",
        "Falta llenar algún campo, o algún campo posee un error que impide guardar el proceso. Por favor, vuelve al formulario para ver los campos que presentan problemas."
      );
    }
  }

  checkVideoUrl = () => {
    const { youtube_intro_video } = this.state.jobApplication;
    if (youtube_intro_video === '') {
      Modal.confirm({
        centered: true,
        okText: 'Continuar sin guardar video',
        cancelText: 'Volver a edición',
        className: 'modal-video-confirm',
        title: 'Url de video introductorio inválida',
        content: 'La url ingresada para el video del proceso no ha sido reconocida como válida. Recuerda que solo Youtube y Vimeo son aceptados como proveedores. Haz click en \'Volver a edición\' para modificarla, o en \'Continuar sin guardar video\' para guardar el proceso sin url de video introductorio.',
        onOk: this.handlePublishJob,
      });
    } else {
      this.handlePublishJob();
    }
  }

  checkVideoInterview = () => {
    if (this.state.editedVideo && !this.state.createdVideo) {
      this.openVideoModal()
    } else {
      this.checkVideoUrl();
    }
  }

  openVideoModal = () => {
    this.setState({ videoInterviewModal: true })
  }

  closeVideoModal = () => {
    this.setState({ videoInterviewModal: false })
  }

  closeVideoModalOnAgree = () => {
    this.setState({ videoInterviewModal: false }, this.handlePublishJob())
  }

  changeOptionalCV = (value) => {
    const jobApp = { ...this.state.jobApplication, optional_cv: value }
    this.setState({ jobApplication: jobApp })
  }

  handleAddCollaborator = event => {
    let users = event.target.value
    const user = users.pop()
    let to_add = []
    for (const u of users) {
      if (u.id == user) {
        to_add.push(u)
      }
    }
    const url = 'accounts/permission/'
    const token = this.props.token;
    let data = { users: to_add, job_app_id: this.props.match.params.jobappid }
    this.props.actions.sendData(token, url, JSON.stringify(data)).then(() => {
      this.getColaborators();
    });
  };

  handleDeletePermission = (permission_id) => {
    const url = 'accounts/permission/' + permission_id + '/'
    const token = this.props.token;
    this.props.actions.fetchData(token, url, '', 'DELETE').then(() => {
      this.getColaborators();
    });
  };

  getColaborators = () => {
    const url = 'accounts/permission/'
    const token = this.props.token;
    let data = { process_id: this.props.match.params.jobappid }
    this.props.actions.fetchData(token, url, JSON.stringify(data)).then(() => {
      const permissions = this.props.data ? this.props.data.results.filter(per => per.business_user_id != this.props.userID) : []
      this.setState({ permissions: permissions });
    });
  };

  getSubordinates = async () => {
    const url = `accounts/business/${this.props.currentBusiness.id}/businessusers/`;
    await this.props.actions.fetchData(this.props.token, url, '', 'GET').then((response) => {
      const subordinates = this.props.data.results.filter(sub => sub.id != this.props.userID)
      this.setState({ collaboratorsList: subordinates })
    });
  };

  changeOptionalFields = (type, value) => {
    this.setState((prevState) => ({
      jobApplication: {
        ...prevState.jobApplication,
        optional_fields: {
          ...prevState.jobApplication.optional_fields,
          [type]: value
        }
      }
    }))
  };

  changeAdditionalQuestions = (additional_question_id, value) => {
    this.props.assignedKoActivity.configuration.questions[additional_question_id].mandatory = value
  }

  integrateJobApp = async () => {
    const token = this.props.token;
    const { jobappid } = this.props.match.params;
    const { integrations } = this.state;
    integrations.forEach(integration => {
      const { id, name } = integration;
      const stage = 'HIRED';
      // Verifies that every field on integration is not null.
      if (integration.fields.every(integrationField => integrationField.value)) {
        const fields = {};
        integration.fields.forEach(integrationField => {
          fields[integrationField.name] = integrationField.value;
        });
        const body = { id, name, stage, fields };
        const resp = this.props.jobappActions.fetchAddIntegrations(token, jobappid, body);
      }
    })
  };
  connectIntegration = (integration) => {
    //TODO: CHANGE NAME newIntegrations
    const newIntegrations = this.state.jobAppIntegrations;
    const data = {
      integration: integration,
      fields: integration.template.jobapplication_fields,
      stage: integration.template.default_stage ? integration.template.default_stage : null
    }
    newIntegrations.push(data)
    this.setState({ jobAppIntegrations: newIntegrations })
    message.success(`¡Integración con ${integration.template.name} realizada exitosamente!`);
    this.handleSubmitIntegration(integration)
  };

  disableConnection = (integration) => {

    const newIntegrations = this.state.jobAppIntegrations;
    var index = newIntegrations.map(obj => obj.integration.id).indexOf(integration.id);
    if (index !== -1) {
      newIntegrations.splice(index, 1);
      this.setState({ jobAppIntegrations: newIntegrations })
    }
    this.setState({ jobAppIntegrations: newIntegrations })

  }

  async submitData(shouldRedirect) {
    if (this.checkErrors()) {
      this.setState({ error: false })
      const token = this.props.token;
      const businessName = this.props.currentBusiness ? this.props.currentBusiness.name : this.props.businessOwner;
      const jobAppData = this.state.jobApplication;
      jobAppData.youtube_intro_video = jobAppData.youtube_intro_video == "" ? null : jobAppData.youtube_intro_video;
      const newAssignedActivities = [...this.props.newAssignedActivities];
      const removedAssignedActivities = [...this.props.removedAssignedActivities];
      const updatedAssignedActivities = this.props.updatedAssignedActivities;

      const newMeasuredTraits = this.props.newMeasuredTraits;
      const updatedMeasuredTraits = this.props.updatedMeasuredTraits;
      const removedMeasuredTraits = this.props.removedMeasuredTraits;
      if (!jobAppData.genome_id) {
        const idealGenome = {
          genome: `${jobAppData.job_application} - ${businessName}`,
          description: jobAppData.description,
          category: 'ARTIF',
          active: true
        };
        await this.props.jobappActions.createGenomeId(idealGenome, token);
        jobAppData.genome_id = this.props.jobApplication.genome_id;
      }

      // Remove custom test using another logic
      // Add or delete evaluations
      if (this.state.jobApplication.deleteCustomTest.length) {
        const endpointAssAct = `/api/v1/gamesandtests/bulkassignedactivity/`
        const body = {
          assigned_activity_ids: this.state.jobApplication.deleteCustomTest.map(obj => obj.id),
          job_application_id: this.props.match.params.jobappid
        };
        this.props.actions.fetchAndReturn(this.props.token, endpointAssAct, 'delete', body);
      }
      if (this.state.jobAppIntegrations.length !== 0) {
        this.state.jobAppIntegrations.map((integration) => {

          const endpointIntegrations = `/api/v2/business/jobapplications/${this.props.match.params.jobappid}/integrations/`
          const body = integration
          body['integration'] = body.integration.id
          this.props.actions.fetchAndReturn(this.props.token, endpointIntegrations, 'post', body);
        })
      }

      this.props.jobappActions.fetchJobApplicationIntegrations(this.props.match.params.jobappid, this.props.token).then(() => {
        [...this.props.integrations].map((integration) => {
          if (!this.state.jobAppIntegrations.map(obj => obj.integration).includes(integration.integration.id)) {
            const deleteEndpointIntegrations = `/api/v2/business/jobapplications/${this.props.match.params.jobappid}/integrations/${integration.integration.id}/`
            this.props.actions.fetchAndReturn(this.props.token, deleteEndpointIntegrations, 'delete');
          }
        })
      })

      // add or remove activities, CuTes are stored in another key
      const updateAssAct = [...this.props.newAssignedActivities, ...this.state.jobApplication.addCustomTest];
      await this.props.jobappActions.savePositionAndRedirect(
        token,
        this.props.match.params.jobappid,
        jobAppData,
        updateAssAct,
        removedAssignedActivities,
        updatedAssignedActivities,
        newMeasuredTraits,
        removedMeasuredTraits,
        updatedMeasuredTraits,
        shouldRedirect,
        this.state.newIntegrations).then(async () => {
          await this.setState({ error: this.props.error })
          if (this.state.error) {
            message.error('Ocurrió un error guardando el proceso.', 3)
          }
        });
      //this.integrateJobApp();

      message.success('Datos guardados correctamente');
    }

    // Add or delete evaluations
    if (this.state.jobApplication.addInterviewKit.length) {
      let endpointJobapp = `/api/v1/evaluations/jobapplication/${this.props.match.params.jobappid}/interviewkit/`
      let body = { interview_kits: this.state.jobApplication.addInterviewKit }

      // Assign a list of templates 
      this.props.actions.fetchAndReturn(this.props.token, endpointJobapp, 'post', body)
    }
    if (this.state.jobApplication.deleteInterviewKit.length) {
      let endpointJobapp = `/api/v1/evaluations/jobapplication/${this.props.match.params.jobappid}/interviewkit/`
      let body = { interview_kits: this.state.jobApplication.deleteInterviewKit }

      // Assign a list of templates 
      this.props.actions.fetchAndReturn(this.props.token, endpointJobapp, 'delete', body)
    }
  };

  checkErrors = () => {
    let errors = { ...this.state.errors }
    errors.job_application = this.state.jobApplication.job_application ? false : true
    errors.department = this.state.jobApplication.department ? false : true
    errors.location_city = this.state.jobApplication.location_city ? false : true
    errors.closure_date = this.state.jobApplication.closure_date === 'Fecha invalida' ? true : false
    errors.closure_date = this.validateDate() ? true : false;
    errors.welcome_text = this.state.jobApplication.welcome_text ? false : true
    this.setState({ errors: errors, dateIsPast: this.validateDate() })
    return Object.values(errors).every(value => value === false)
  };

  validateDate = () => {
    const closure = new Date(`${this.state.jobApplication.closure_date} UTC`);
    const today = new Date();
    today.setUTCHours(0, 0, 0, 0);
    return today > closure;
  };

  updateInterviewId = (interviewId) => {
    const jobapp = { ...this.state.jobApplication };
    jobapp.interview_id = interviewId;
    this.setState({ jobApplication: { ...jobapp } });
  }

  toggleEditedVideoFlag = (value) => {
    this.setState({ editedVideo: value })
  }

  toggleCreatedVideoFlag = () => {
    this.setState({ createdVideo: true })
  }

  closeModal = (event) => {
    this.props.hideModal();
  }

  handleOpenSnackbar = (text) => {
    this.setState({ openSnackbar: true, snackbarText: text })
  }

  handleCloseSnack = () => {
    this.setState({ openSnackbar: false })
  }

  openAlertModal = (title, message) => {
    this.props.showModal({
      open: true,
      title,
      message,
      closeModal: this.closeModal
    }, 'alert');
  }

  handleTabChange = (activeKey) => {
    this.setState({ tabIndex: activeKey })
  }

  showConfirm = () => {
    Modal.confirm({
      centered: true,
      okText: 'Si',
      cancelText: 'No',
      className: 'modal-video-confirm',
      title: '¿Deseas continuar SIN crear la videoentrevista?',
      content: 'Has editado los campos de la videoentrevista, pero esta no ha sido creada. Para esto debes volver al formulario y apretar el botón "Crear Videoentrevista"',
      onOk: () => {
        this.setState({ current: nextStep, editedVideo: false });
      },
    })
  };

  comebackConfirm = () => {
    Modal.confirm({
      centered: true,
      okText: 'Si',
      cancelText: 'No',
      className: 'modal-video-confirm',
      title: '¿Confirmas que quieres salir de la edición del proceso?',
      content: 'Si abandonas la vista, los cambios que has hecho no se guardarán',
      onOk: () => {
        this.props.dispatch(push(`/processes/${this.props.match.params.jobappid}`));
      },
    })
  };

  onChangeStep = current => {
    let currentObj = this.state.wizardSteps[current];
    if (this.state.editedVideo && !this.state.jobApplication.interview_id) {
      this.showConfirm();
      nextStep = { name: currentObj.name, pos: current };
    } else {
      this.setState({ current: { name: currentObj.name, pos: current } });
      window.scrollTo(0, 0);
    }
    // if the modal is closed at least once, it wont open again
    if (!this.state.jobAppStatusModalClosed && this.state.jobAppCurrentStatus === 'ACTIV') {
      Modal.confirm({
        title: 'Proceso activo',
        content: (<>El proceso se encuentra activo. Para poder editar en detalle el proceso este debe estar en estado de Borrador.</>),
        okText: 'Pasar a borrador',
        cancelText: 'Continuar sin editar',
        onOk: async () => {
          this.setState({ jobApplication: { ...this.state.jobApplication, status: 'OTHER' } }, () => {
            this.submitData(false).then(() => {
              window.location.reload();
            })
          }
          )
        },
        onCancel: () => {
          this.setState({ jobAppStatusModalClosed: true })
        }
      })
    }
  }

  nextStep = () => {
    if (this.state.editedVideo && !this.state.jobApplication.interview_id) {
      this.showConfirm();
    } else {
      this.setState({ current: { name: this.state.current.name, pos: this.state.current.pos + 1 } });
    }
  }

  setTags = (tags) => {
    const jobapp = { ...this.state.jobApplication }
    jobapp.tags = tags

    this.setState({ jobApplication: jobapp })

    const token = this.props.token
    const url = `processes/${this.props.match.params.jobappid}`
    // this.props.actions.sendData(token, url, JSON.stringify(jobapp.tags), 'put')
  }

  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value })
  }

  handleClose = (removedTag) => {
    const tags = this.state.jobApplication.tags.filter(tag => tag !== removedTag)
    this.setState({ jobApplication: { ...this.state.jobApplication, tags: tags } });
    this.setTags(tags);
  }

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  }

  handleInputConfirm = () => {
    const { inputValue } = this.state;
    let tags = this.state.jobApplication.tags;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }

    this.setState({
      inputVisible: false,
      inputValue: '',
      jobApplication: { ...this.state.jobApplication, tags: tags }
    })

    this.setTags(tags);
  }

  saveInputRef = (input) => (this.input = input);

  addNewStage = (newStages) => {
    let jobapp = { ...this.state.jobApplication }
    let stages = newStages
    jobapp.stages['stages'] = stages
    this.setState({ jobApplication: jobapp })
  }

  currentStepContent = () => {
    const { jobApplication } = this.state;
    const { optional_fields } = jobApplication;
    switch (this.state.current.name) {
      case 'Información': return {
        title: 'Información del cargo',
        content: <JobDetail
          jobAppId={this.props.match.params.jobappid}
          jobapp={jobApplication}
          handleChange={this.onChangeJobPosting}
          integrations={this.state.integrations}
          dateIsPast={this.state.dateIsPast}
          inputVisible={this.state.inputVisible}
          inputValue={this.state.inputValue}
          handleInputChange={this.handleInputChange}
          handleChangeIntegration={this.onChangeIntegration}
          handleClose={this.handleClose}
          showInput={this.showInput}
          handleInputConfirm={this.handleInputConfirm}
          saveInputRef={this.saveInputRef}
          tagsA={this.state.jobApplication.tags}
          checkToken={this.checkToken}
          connectIntegration={this.connectIntegration}
          updateIntegrationData={this.handleSubmitIntegration}
          jobAppIntegrations={this.state.jobAppIntegrations}
          disableConnection={this.disableConnection}
          questionType={this.questionType}
          currentBusinessId={this.props.currentBusiness.id}
          handleCustomFields={this.handleCustomFields}
        />
      }
      case 'Etapas': return {
        title: 'Etapas',
        content: <Stages
          addStage={this.addNewStage}
          jobapp={jobApplication}
          canEdit={this.state.canEdit}
        />
      }
      case 'Formulario': return {
        title: 'Formulario de postulación',
        content: (
          <ApplicationForm
            optionalFields={optional_fields}
            changeOptionalFields={this.changeOptionalFields}
            changeAdditionalQuestions={this.changeAdditionalQuestions}
          />
        )
      }
      case 'Evaluaciones': return {
        title: 'Evaluaciones',
        content: (
          <IdealGenome
            onChangeCustomTest={this.onChangeCustomTest}
            currentCustomTests={this.state.jobApplication.currentCustomTests}
          />
        )
      }

      case 'Video entrevista': return {
        title: 'Video entrevista',
        content: (
          <VideoInterview
            editedVideo={this.toggleEditedVideoFlag}
            jobApplication={jobApplication}
            addStage={this.addNewStage}
            createdVideo={this.toggleCreatedVideoFlag}
            updateInterviewId={this.updateInterviewId}
            jobAppId={this.props.match.params.jobappid}
          />
        )
      }

      case 'Entrevistas personalizadas': return {
        title: 'Entrevistas personalizadas',
        content: (
          <InterviewKit
            jobApplication={jobApplication}
            jobAppId={this.props.match.params.jobappid}
            onchange={this.onChangeInterviewKit}
          />
        )
      }

      case 'Resumen': return {
        title: 'Resumen',
        content: (
          <PublishJob
            jobapp={jobApplication}
            currentCustomTests={this.state.jobApplication.currentCustomTests}
            processType={this.state.jobApplication.process_type}
            jobappid={this.props.match.params.jobappid}
            handleChange={this.onChangeJobPosting}
            publish={this.handlePublishJob}
          />
        )
      }
    }
  }

  displayErrors() {
    const labels = {
      closure_date: 'Fecha de Cierre',
      department: 'Departamento',
      job_application: 'Nombre del cargo',
      location_city: 'Ciudad',
      welcome_text: 'Texto de Bienvenida',
    }
    return (
      <>
        <List>
          {Object.keys(this.state.errors).map((field, i) => {
            if (this.state.errors[field]) return (<List.Item key={i}>{labels[field]}</List.Item>)
          })}
        </List>
      </>
    )
  }

  render() {
    const { jobApplication, assignedActivities } = this.props;
    const { current, hasSider } = this.state;
    const { title, content } = this.currentStepContent();
    let activities = [];
    for (const x in assignedActivities) {
      activities.push(assignedActivities[x]);
    }

    return (
      <>
        <Alert
          message={
            <>
              <Text>
                {"En Genomawork hemos estado trabajando para mejorar tu experiencia y a partir del día 20 de septiembre nuestra sección de creación de proceso cambiará. Te dejamos el material de lanzamiento para que conozcas desde ya estas mejoras: "}
                <a target='_blank' href='https://genoma-archives.s3.us-east-2.amazonaws.com/Material+Lanzamiento+Creacio%CC%81n+de+proceso.pdf'>descargar aquí</a>
              </Text>
            </>
          }
          type="warning"
          closable
        />
        <Layout className={`${hasSider && 'collapse-sider'} np`}>
          {this.props.modalState && this.props.modalState.modalProps && (
            <Modal
              title={<Text>{this.props.modalState.modalProps.title}</Text>}
              visible={this.props.modalState.modalProps.open}
              footer={null}
              onCancel={this.props.modalState.modalProps.closeModal}
            >
              <Text>{this.props.modalState.modalProps.message}</Text>
              {/* DEUDA TÉCNICA */}
              {/* Si el mensaje del modal es para informar que la sesión expiró, no se deben 
            mostrar los errores relacionados al formulario del proceso (por ejemplo que falta
            algún campo, que la fecha de cierre es inválida, etc.). Mega flaite el "if", pero
            ambos errores vienen del redux, y entrar a modificar eso es demasiado complejo,
            asique habrá que mejorarlo en un futuro haciendo un buen uso del redux */}
              {this.props.modalState.modalProps.title && !this.props.modalState.modalProps.title.includes('La sesión ha caducado') && <Text>{this.displayErrors()}</Text>}
            </Modal>
          )}
          <Sider
            breakpoint="lg"
            onBreakpoint={broken => {
              this.setState({ hasSider: broken });
            }}
          >
            <Wizard
              currentStep={current}
              onChangeStep={this.onChangeStep}
              hasSider={hasSider}
              ifGames={activities.filter(e => e.icon !== 'FORM' && e.icon !== 'KO').length}
              ifVideo={this.state.createdVideo || jobApplication.interview_id}
              ifQuestions={this.props.assignedKoActivity}
              addOns={this.props.addOns}
            />
          </Sider>
          <Layout className='main-content' id='main-content'>
            <Content>
              <Row>
                <Col xs={24} md={17}>
                  <div className='main-content__headline'>
                    <Button type='primary' onClick={this.comebackConfirm} icon='left' />
                    <Title level={3} >{title}</Title>
                  </div>
                  <AppContext.Provider
                    value={{
                      active: jobApplication.status === 'ACTIV',
                    }}
                  >
                    {content}
                  </AppContext.Provider>
                </Col>

                <Col xs={24} md={7} className='job-state'>
                  <JobState
                    name={this.state.jobApplication.job_application}
                    publish={this.checkVideoInterview.bind(this)}
                    save={this.handlePublishJob}
                    handleChangeJobPosting={this.onChangeJobPosting}
                    jobAppId={this.props.match.params.jobappid}
                    currentCustomTests={this.state.jobApplication.currentCustomTests}
                  />
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  // Sustom tests ahve its own state
  let filteredNewAssignedActivities = { ...state.jobapp.assignedActivities };
  delete filteredNewAssignedActivities['CUTE'];
  filteredNewAssignedActivities = Object.values(filteredNewAssignedActivities).filter(act => !act.original)

  return {
    addOns: state.auth.addOns,
    alerts: state.alerts,
    assignedActivities: state.jobapp.assignedActivities,
    assignedKoActivity: state.jobapp.assignedActivities.KO,
    businessOwner: state.auth.businessOwner,
    currentBusiness: state.auth.currentBusiness,
    data: state.data.data,
    error: state.data.error,
    integrations: state.jobapp.integrations,
    isFetching: state.data.isFetching,
    jobApplication: state.jobapp.jobApplication,
    knockOutActivity: state.jobapp.activities?.results?.find((act) => { return act.code === 'KO'; }),
    modalState: state.modal,
    newAssignedActivities: filteredNewAssignedActivities,
    newMeasuredTraits: Object.values(state.trait.measuredTraits).filter(mt => !mt.original),
    removedAssignedActivities: state.jobapp.removedAssignedActivities,
    removedMeasuredTraits: state.trait.removedMeasuredTraits,
    updatedAssignedActivities: Object.values(state.jobapp.assignedActivities).filter(act => act.original),
    updatedMeasuredTraits: Object.values(state.trait.measuredTraits).filter(mt => mt.original),
    userID: state.auth.userID,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    jobappActions: bindActionCreators(jobappActions, dispatch),
    traitActions: bindActionCreators(traitActions, dispatch),
    hideModal: () => { dispatch(hideModal()); },
    showModal: (modalProps, modalType) => { dispatch(showModal({ modalProps, modalType })); },
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewProcessFormView);
