export const skillsList = [
  'Adobe After Effects', 'Adobe Ilistrator', 'Adobe Premiere',
  'Adobe Photoshop', 'AJAX', 'Angular',
  'Ansible', 'Apache', 'API REST',
  'ASP.NET', 'AutoCat', 'AWS',
  'Bash', 'C', 'C#',
  'Confluence Cloud', 'CSS',
  'DevOps', 'Django', 'Docker',
  'Drupal', 'ExpressJS', 'Figma',
  'Flask', 'Flutter', 'GHS Safety',
  'Git', 'Github', 'Go',
  'Google Analytics', 'Google Cloud Platform', 'Google Data Studio',
  'GraphQL', 'Hadoop', 'Heroku',
  'HTML', 'HubSpot', 'Ionic',
  'Java', 'JavaScript', 'JIRA',
  'jQuery', 'Keras', 'Kubernetes',
  'Larabel', 'Magento 2', 'MariaDB',
  'MATLAB', 'Microsoft Access', 'Microsoft Azure',
  'Microsoft Dynamics', 'Microsoft Excel', 'Microsoft Outlook',
  'Microsoft Power Point', 'Microsoft Project', 'Microsoft SharePoint',
  'Microsoft Visio', 'Microsoft Word', 'MongDB',
  'NodeJS', 'Numpy', 'Nuxt',
  'Oracle', 'Pandas', 'Perl',
  'PHP', 'PostgreSQL', 'PowerBI',
  'Programación Orientada a Objetos', 'Python', 'PyTorch',
  'QuickBooks', 'R', 'React',
  'React Native', 'Rest', 'Ruby',
  'Ruby on Rails', 'Saleforce CRM', 'SAP Ariba',
  'Selenium', 'Shopify', 'SkeychupPro',
  'Sklearn', 'SolidWorks', 'Spark',
  'SQL', 'Swift', 'Tableau',
  'Tensorflow', 'Terraform', 'Typescript',
  'VMware', 'Vue.js', 'Woocommerce',
  'Wordpress', 'Zendesk',
  'Extreme Programing', 'Lean', 'Mejora Continua',
  'Metodologías Ágiles', 'Scrum',
  'Aleman', 'Árabe', 'Chino cantonés',
  'Chino mandarín', 'Coreano', 'Español',
  'Francés', 'Hindi', 'Indonesio',
  'Inglés', 'Italiano', 'Japonés',
  'Persa', 'Portugués', 'Ruso',
  'Tailandés', 'Tamol', 'Turco',
  'Vietnamita', 'Comunicación', 'Liderazgo', 'Motivación',
  'Negociación', 'Trabajo en equipo',
  'Ciberseguridad', 'Contabilidad', 'Facebook Ads',
  'Google Ads', 'QA', 'Redes sociales', 'SEO',
  'Analisís de datos', 'Atención al cliente', 'Ortografía', 'Redacción'
]

export const textLevels = {
  2: [
    {
      title: 'BAJO',
      text: 'El evaluado se encuentra en un nivel bajo en la habilidad.',
      background: '#FFF2E8',
      border: '#FF4D4F'
    },
    {
      title: 'ALTO',
      text: 'El evaluado se encuentra en un nivel alto en la habilidad.',
      background: '#FCFFE6',
      border: '#52C41A'
    }
  ],
  3: [
    {
      title: 'BAJO',
      text: 'El evaluado se encuentra en un nivel bajo en la habilidad.',
      background: '#FFF2E8',
      border: '#FF4D4F'
    },
    {
      title: 'MEDIO',
      text: 'El evaluado se encuentra en un nivel medio en la habilidad.',
      background: '#FFF7E6',
      border: '#FAAD14'
    },
    {
      title: 'ALTO',
      text: 'El evaluado se encuentra en un nivel alto en la habilidad.',
      background: '#FCFFE6',
      border: '#52C41A'
    },
  ],
  4: [
    {
      title: 'BAJO',
      text: 'El evaluado se encuentra en un nivel bajo en la habilidad.',
      background: '#FFF2E8',
      border: '#FF4D4F'
    },
    {
      title: 'MEDIO',
      text: 'El evaluado se encuentra en un nivel medio en la habilidad.',
      background: '#FFF7E6',
      border: '#FAAD14'
    },
    {
      title: 'ALTO',
      text: 'El evaluado se encuentra en un nivel alto en la habilidad.',
      background: '#FCFFE6',
      border: '#BAE637'
    },
    {
      title: 'DESTACADO',
      text: 'El evaluado se encuentra en un nivel destacado en la habilidad.',
      background: '#F6FFED',
      border: '#52C41A'
    },
  ],
  5: [
    {
      title: 'MUY BAJO',
      text: 'El evaluado se encuentra en un nivel muy bajo en la habilidad.',
      background: '#FFF1F0',
      border: '#FF4D4F'
    },
    {
      title: 'BAJO',
      text: 'El evaluado se encuentra en un nivel bajo en la habilidad.',
      background: '#FFF2E8',
      border: '#FF7A45'
    },
    {
      title: 'MEDIO',
      text: 'El evaluado se encuentra en un nivel medio en la habilidad.',
      background: '#FFF7E6',
      border: '#FAAD14'
    },
    {
      title: 'ALTO',
      text: 'El evaluado se encuentra en un nivel alto en la habilidad.',
      background: '#FCFFE6',
      border: '#BAE637'
    },
    {
      title: 'DESTACADO',
      text: 'El evaluado se encuentra en un nivel destacado en la habilidad.',
      background: '#F6FFED',
      border: '#52C41A'
    },
  ],
}