import React, { Fragment } from 'react';
import {
  Layout,
  Row,
  Icon,
  Typography,
  Form,
  Button,
  Input,
  Divider
} from 'antd';

import i18n from '../../../../i18n';
import SocialLoginButtons from '../../../Login/components/SocialLogin';

const { Title, Text } = Typography;

const NotFirstLogin = (
  {
    props, state, submitAfterLogin, submitLoginData,
    handleChange, alerts, recoverPassword,
    goBack, mydata
  }
) => {
  const { getFieldDecorator } = props.form;

  return (
    <Layout className='main-layout-cards__cols__component'>
      <Row style={{ margin: '0 0 60px 0' }}>
        <Icon style={{ fontSize: '27px' }} type='arrow-left' onClick={() => goBack()} />
      </Row>
      <Row style={{ margin: '0 0 30px 0' }}>
        <Text style={{ fontSize: '20px', color: '#8C8C8C' }}>
          {i18n.t('commons__candidate').toUpperCase()}
        </Text>
      </Row>
      <Row style={{ margin: ' 0 0 42px 0' }}>
        <Title>
          {i18n.t('start__process__welcome__again')}
        </Title>
        <Text style={{ fontSize: '18px', color: '#786D73' }}>
          {props.isDNIAlreadyUsed ? props.userMail : mydata['user_mail']}
        </Text>
      </Row>

      <Form onSubmit={submitLoginData}>
        {(mydata['social_accounts'] && mydata['default_password']) ?
          // Primera vez que ingresa con correo, antes solo usó rr.ss.
          <SocialLoginButtons
            location={props.location}
            jobappid={props.match.params['jobappid']}
            firstLogin={false}
            linkedin={mydata['social_accounts'].includes("LI")}
            gmail={mydata['social_accounts'].includes("GM")}
            submitAfterLogin={submitAfterLogin}
          />
          :
          // No es primera vez que ingresa con correo y clave (i.e. default_password == false)
          <Fragment>
            <Form.Item label='Contraseña'>
              {getFieldDecorator('password', {
                rules: [{ required: true, message: 'Por favor escribe tu contraseña' }],
              })(
                <Input.Password
                  size='large'
                  prefix={<Icon type="lock" style={{ color: '#786D73' }} />}
                  type="password"
                  placeholder="Contraseña"
                  onChange={handleChange('password')}
                />
              )}
              {
                (state.errors.passwordLogin || state.showPasswordError) &&
                <div style={{ lineHeight: 1.5, color: 'red' }}>
                  {alerts.type === "alert-danger" && alerts.message}
                </div>
              }
            </Form.Item>


            <Row type='flex' align='middle' style={{ flexDirection: 'column', margin: '32px 0 0 0' }}>
              <Button
                size="large"
                htmlType="submit"
                block
                style={{ borderRadius: '4px', ...state.color_theme }}
              >
                {i18n.t('commons__sign_in')}
              </Button>
              <Button
                type='link'
                onClick={() => recoverPassword()}
                style={{ color: state.color_theme.background, margin: '20px 0 0 0', fontSize: '16px', fontWeight: 700 }}
              >
                {i18n.t('start__process__forgot__password')}
              </Button>
            </Row>

            {mydata['social_accounts'] &&
              <Fragment>
                <Divider />
                <SocialLoginButtons
                  location={props.location}
                  jobappid={props.match.params['jobappid']}
                  firstLogin={false}
                  linkedin={mydata['social_accounts'].includes("LI")}
                  gmail={mydata['social_accounts'].includes("GM")}
                  submitAfterLogin={submitAfterLogin}
                />
              </Fragment>
            }
          </Fragment>
        }
      </Form>
    </Layout>
  );
}

export default NotFirstLogin;