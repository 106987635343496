import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import { Modal, Button } from 'antd'

const styles = theme => ({
    content: {
        justifyContent: 'center',
        alignItems: 'center',
    }
});

const widthBreakpointToPixel = {
    'xs': 500,
    'sm': 768,
    'md': 992,
    'lg': 1200,
}

const AlertDialogSlide = (props) =>  {

    let footerElements = []
    if (props.cancel){
        const buttonToAdd = <Button key="back" onClick={props.handleClose}>
        Cancelar
      </Button>
        footerElements.push(buttonToAdd)
    }

    if (props.accept) {
        const buttonToAdd = <Button key="submit" type="primary" onClick={props.handleAccept}>
                                Aceptar
                            </Button>
        footerElements.push(buttonToAdd)
    }

    return (
        <React.Fragment>
            <Modal
                title={props.title}
                visible={props.open}
                onOk={props.handleClose}
                onCancel={props.handleAccept}
                width={widthBreakpointToPixel[props.maxWidth]}
                footer={footerElements}
                >
                {props.content}
            </Modal>
        </React.Fragment>
    );
  
}

AlertDialogSlide.propTypes = {
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.string,
};
AlertDialogSlide.defaultProps = {
    fullWidth: false,
    maxWidth: 'sm',
};

export default withStyles(styles)(AlertDialogSlide);
