import React, { useState } from 'react';
import { Row, Col, Button, Typography, Form, Icon, Select, Modal } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../../actions/data';
import EditCareerModal from './Modal/EditCareerForm';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
import { careerStateOptions, educationLevelOptions } from '../../../constants/careers';

const { Title, Text } = Typography;
const { Option } = Select;

function Education({
  form,
  personalData,
  onChangePersonalData,
  token,
  actions,
  setUpdateData
}) {

  const [openAddEducationModal, setOpenAddEducationModal] = useState(false);
  const [openEditEducationModal, setOpenEditEducationModal] = useState(false);
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false);

  const [careerToEdit, setCareerToEdit] = useState({});
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [careerToDelete, setCareerToDelete] = useState(null);
  const { getFieldDecorator } = form;

  const translateState = (str) => {
    return careerStateOptions.filter((a) => a.value === str)[0].label;
  };

  const handleCareerAdition = (careerData) => {
    const careerInfoUrl = '/api/v1/accounts/careerinformations/';
    actions
      .fetchAndReturn(token, careerInfoUrl, 'POST', careerData)
      .then(() => {
        setOpenEditEducationModal(false);
        setUpdateData(true);
      })
      .catch((error) => {
        console.error('Ocurrió un error', error);
      });
  };

  const handleCareerEdit = (careerData, id) => {
    careerData['last_year'] = 0
    const careerInfoUrl = `/api/v1/accounts/careerinformations/${id}/`;
    actions
      .fetchAndReturn(token, careerInfoUrl, 'PATCH', careerData)
      .then(() => {
        setOpenEditEducationModal(false);
        setUpdateData(true);
      })
      .catch((error) => {
        console.error('Ocurrió un error', error);
      });
  };

  const handleCareerDelete = (careerToDelete) => {
    setConfirmLoading(true);
    const careerInfoUrl = `/api/v1/accounts/careerinformations/${careerToDelete}/`;
    actions
      .fetchAndReturn(token, careerInfoUrl, 'delete')
      .then(() => {
        setModalDeleteVisible(false);
        setConfirmLoading(false);
        setUpdateData(true);
      })
      .catch((error) => {
        console.error('Ocurrió un error', error);
      });
  };

  const deleteConfirm = ({ id }) => {
    setCareerToDelete(id);
    setModalDeleteVisible(true);
  };

  const onEditCareer = (data) => {
    setOpenEditEducationModal(true);
    setCareerToEdit(data);
  };

  return (
    <>
      <EditCareerModal
        openModal={openAddEducationModal}
        setOpenModal={setOpenAddEducationModal}
        onAddCareer={handleCareerAdition}
        token={token}
      />

      <EditCareerModal
        openModal={openEditEducationModal}
        setOpenModal={setOpenEditEducationModal}
        onEditCareer={handleCareerEdit}
        token={token}
        careerToEdit={careerToEdit}
      />
      <Modal
        visible={modalDeleteVisible}
        centered={true}
        onOk={() => handleCareerDelete(careerToDelete)}
        confirmLoading={confirmLoading}
        onCancel={() => setModalDeleteVisible(false)}
      >
        <p>¿Estás seguro de eliminar esta carrera?</p>
      </Modal>

      <Form layout='horizontal' className='candidate-form-section'>
        <Row className='candidate-form-section-inner'>
          <Row>
            <Title level={4}>{i18n.t('profile__academic__background')}</Title>
          </Row>
          <Row style={{ marginBottom: '10px' }}>
            <Form.Item
              label={
                <>
                  <span className='label-required'>*</span>
                  <span>{i18n.t('form__academic_level')}</span>
                </>
              }
            >
              {getFieldDecorator('education_level', {
                initialValue:
                  personalData?.education_level === ''
                    ? ''
                    : personalData.education_level,
                rules: [
                  {
                    required: true,
                    message: i18n.t('form__max__education__error'),
                  },
                ],
                onChange: onChangePersonalData('education_level'),
              })(
                <Select
                  placeholder={i18n.t('form__academic_level')}
                  showSearch
                  optionFilterProp='children'
                  notFoundContent={i18n.t('commons__no__results')}
                  style={{ width: '100%' }}
                >
                  {educationLevelOptions.map((level, index) =>
                    <Option key={index} value={level.value}>{level.label}</Option>
                  )}
                </Select>
              )}
            </Form.Item>
          </Row>
          <Col>
            <>
              <span className='label-required'>*</span>
              <span>{i18n.t('form__academic_historial')}</span>
            </>
          </Col>
          <Row type='flex' style={{ margin: '10px 0' }}>
            {personalData.careers?.map((values, index) => (
              <Col span={24} style={{ marginBottom: '14px' }} key={index}>
                <Col xs={17} sm={17} md={21}>
                  <Row>
                    <Col>
                      <Text>
                        {values.career_name}, {values.career_institution}
                      </Text>
                    </Col>
                    <Col>
                      <Text disabled>
                        {translateState(values.career_state)}
                      </Text>
                    </Col>
                    <Col>
                      <Text disabled>
                        {values.career_entry_year} - {values.career_last_year}
                      </Text>
                    </Col>
                  </Row>
                </Col>
                <Col xs={7} sm={7} md={3}>
                  <Row type='flex' gutter={10} justify="end">
                    <Col>
                      <Button
                        icon='edit'
                        type='primary'
                        onClick={() => onEditCareer(personalData.careers[index])}
                      />
                    </Col>
                    <Col>
                      <Button
                        icon='delete'
                        onClick={() => deleteConfirm(personalData.careers[index])}
                      />
                    </Col>
                  </Row>
                </Col>
              </Col>
            ))}
          </Row>
          <Row onClick={() => setOpenAddEducationModal(true)}>
            <Icon type='plus-circle' className='add-button' />
            <Text className='add-button'>{i18n.t('commons__add_career')}</Text>
          </Row>
        </Row>
      </Form>
    </>
  );
}

Education.propTypes = {
  personalData: PropTypes.shape({
    education_level: PropTypes.string,
    careers: PropTypes.arrayOf(PropTypes.shape({
      career_name: PropTypes.string,
      career_institution: PropTypes.string,
      career_entry_year: PropTypes.string,
      career_last_year: PropTypes.string,
    }),
  ),
  }) 
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  };
};

const WrappedEducation = Form.create()(Education);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedEducation);
