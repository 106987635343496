import React from 'react'
import {
  Row,
  Breadcrumb,
  Typography, Icon
} from 'antd';
import propTypes from 'prop-types';
import i18n from '../../../i18n';
const { Title } = Typography;

/**
 * 
 * @param {String} searchLocation
 * @param {String} locationFullName
 * @param {Function} setJobView
 * @param {Function} setShowPagination
 * @param {Function} setPage
 * @returns
 */

const CommonHeader = ({ searchLocation, setJobView, setShowPagination, setPage, country, countries }) => {
  return (
    <Row type='flex' className='common-header' id='header-section'>
      <Breadcrumb>
        <Breadcrumb.Item>{i18n.t('job_portal__title')}</Breadcrumb.Item>
        {
          searchLocation === null ?
          <Breadcrumb.Item>{countries['LATAM'].translation[i18n.language.split('-')[0]]}</Breadcrumb.Item> :
          <Breadcrumb.Item>{country[i18n.language.split('-')[0]]}</Breadcrumb.Item>
        }
      </Breadcrumb>
      <Row type='flex' align='middle' style={{ margin: '20px 0 0 0' }}>
        <Icon 
          type='arrow-left'
          onClick={
            () => {
              setJobView(false)
              setShowPagination(false)
              setPage(1)
            }
          } 
          style={{ fontSize: '21px', color: 'black' }}
        />
        <Title style={{ margin: '0 0 0 10px' }}>
          {i18n.t('job_portal__title')}
        </Title>
      </Row>
    </Row>
  );
}

CommonHeader.propTypes = {
  searchLocation: propTypes.string,
  locationFullName: propTypes.string,
  setJobView: propTypes.func,
  setShowPagination: propTypes.func
}

export default CommonHeader;