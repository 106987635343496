import React from 'react';

import { Button, Card, Col, Icon, Row, Typography } from 'antd';
import PropTypes from 'prop-types';

import { disabilityOptions, disabilityTypeOptions, genderOptions, maritalStatusOptions } from '../constants';
import i18n from '../../../../i18n';

const { Text, Title } = Typography;

export default function PersonalDataSection(props) {
  const { data, setEditPersonalDataVisible } = props;

  return (
    <Card bodyStyle={{ paddingTop: 0 }} className='profile-section-card'>
      <Row
        align="middle"
        justify="space-between"
        style={{ paddingBottom: '1em', paddingTop: '1em' }}
        type="flex"
      >
        <Col>
          <Title level={4} style={{ marginBottom: 0 }}>
            {i18n.t('commons__personal__data')} 
          </Title>
        </Col>
        <Col>
          <Button className="profile-section-card__update" onClick={setEditPersonalDataVisible.on}>
            <Icon type="edit" />
            {i18n.t('commons__edit')}
          </Button>
        </Col>
      </Row>
      
      <Row justify="center" style={{ marginBottom: '2em' }} type="flex">
        <Col>
          <Row
            align="middle"
            justify="center"
            style={{ flexDirection: 'column' }}
            type="flex"
          >
            <div className='profile-section-card__avatar-initials'>
              {data.avatar_initials}
            </div>
            <Text strong style={{ fontSize: '1.25em' }}>
              {`${data.name} ${data.surname}`}
            </Text>
          </Row>
        </Col>
      </Row>

      <Row className="profile-section-card__data-row" justify="space-between" type="flex">
        <Col>
          <Text strong>DNI</Text>
        </Col>
        <Col>{data.dni}</Col>
      </Row>
      <Row className="profile-section-card__data-row" justify="space-between" type="flex">
        <Col>
          <Text strong>{i18n.t('commons__mail')}</Text>
        </Col>
        <Col>{data.email}</Col>
      </Row>
      <Row className="profile-section-card__data-row" justify="space-between" type="flex">
        <Col>
          <Text strong>{i18n.t('form__birth_date')}</Text>
        </Col>
        <Col>
          {data.birth_date
            ? (
              `${typeof data.birth_date === 'string' ? data.birth_date : data.birth_date.local().format('DD-MMM-YYYY')}`
            ) : (
              <Text type="secondary">{i18n.t('commons__no_data_registered')}</Text>
            )}
        </Col>
      </Row>
      <Row className="profile-section-card__data-row" justify="space-between" type="flex">
        <Col>
          <Text strong>{i18n.t('form__phone')}</Text>
        </Col>
        <Col>
          {(data.phone_prefix && data.phone) ? `${data.phone_prefix} ${data.phone}` : (
            <Text type="secondary">{i18n.t('commons__no_data_registered')}</Text>
          )}
        </Col>
      </Row>
      <Row className="profile-section-card__data-row" justify="space-between" type="flex">
        <Col>
          <Text strong>{i18n.t('form__nationality')}</Text>
        </Col>
        <Col>
          {data.nationality ?? (
            <Text type="secondary">{i18n.t('commons__no_data_registered')}</Text>
          )}
        </Col>
      </Row>
      <Row className="profile-section-card__data-row" justify="space-between" type="flex">
        <Col>
          <Text strong>{i18n.t('form__residence_country')}</Text>
        </Col>
        <Col>
          {data.country
            ? (`${data.country.charAt(0).toUpperCase()}${data.country.slice(1)}`)
            : (<Text type="secondary">{i18n.t('commons__no_data_registered')}</Text>)}
        </Col>
      </Row>
      {/* <Row className="profile-section-card__data-row" justify="space-between" type="flex">
        <Col>
          <Text strong>{i18n.t('profile__marital_status')}</Text>
        </Col>
        <Col>
          {data.marital_status ? maritalStatusOptions[data.marital_status] : (
            <Text type="secondary">{i18n.t('commons__no_data_registered')}</Text>
          )}
        </Col>
      </Row> */}
      <Row className="profile-section-card__data-row" justify="space-between" type="flex">
        <Col>
          <Text strong>{i18n.t('form__gender')}</Text>
        </Col>
        <Col>{genderOptions[data.gender]}</Col>
      </Row>
      <Row className="profile-section-card__data-row" justify="space-between" type="flex">
        <Col>
          <Text strong>{i18n.t('form__disability')}</Text>
        </Col>
        <Col>
          {disabilityOptions[data.disability]}
        </Col>
      </Row>
      <Row className="profile-section-card__data-row" justify="space-between" type="flex">
        <Col>
          <Text strong>{i18n.t('form__disability_type')}</Text>
        </Col>
        <Col>
          {disabilityTypeOptions[data.disability_type]}
        </Col>
      </Row>
    </Card>
  )
}

PersonalDataSection.propTypes = {
  data: PropTypes.shape({
    avatar_initials: PropTypes.string || undefined || null,
    birth_date: PropTypes.object || PropTypes.string || undefined || null,
    country: PropTypes.string || undefined || null,
    disability_type: PropTypes.string || undefined || null,
    dni: PropTypes.string || undefined || null,
    email: PropTypes.string,
    gender: PropTypes.string,
    // marital_status: PropTypes.string || undefined || null,
    name: PropTypes.string || undefined || null,
    nationality: PropTypes.string || undefined || null,
    phone: PropTypes.string || undefined || null,
    phone_prefix: PropTypes.string || undefined || null,
    surname: PropTypes.string || undefined || null,
  }).isRequired,
  setEditPersonalDataVisible: PropTypes.shape({
    off: PropTypes.func.isRequired,
    on: PropTypes.func.isRequired,
  }).isRequired,
};
