import React from 'react';

const tourConfigTable = (actions) => [
  {
    selector: '.table-tutorial-show-columns',
    content: () => (
      <div className="table-tour-div">
        Desde el selector <strong>Mostrar en la tabla</strong> puedes agregar o
        quitar propiedades del tablero según lo que necesites.
      </div>
    ),
  },
  {
    selector: '.candidate-list-container__status-selector',
    content: () => (
      <div className="table-tour-div">
        A la hora de descartar un candidato, la información de la persona
        quedará almacenada en la etapa donde se encontraba antes del descarte,
        facilitando el llevar un mayor control de cada etapa por separado, y
        pudiendo reincorporar candidatos y candidatas fácilmente en caso de
        necesitarlo.
      </div>
    ),
    // action: () => {
    //   refTable.current.getWrappedInstance().openCandidateModalKey(candidateId);
    // },
  },
  {
    selector: '.table-filters-icon',
    content: () => (
      <div className="table-tour-div">
        Los <strong>filtros</strong> ahora pueden gestionarse de manera
        acumulativa agregando los que tu desees.
      </div>
    ),
    // action: () => {
    //   actions.handleDrawer.off();
    // },
  },
  {
    selector: '.table-filters',
    content: () => (
      <div className="table-tour-div">
        Mediante el botón <strong>Añadir filtro</strong> puedes seleccionar los
        filtros que desees agregar a la tabla.
      </div>
    ),
    action: () => {
      actions.handleDrawer.on();
    },
  },
  {
    selector: '.ant-tabs-nav',
    content: () => (
      <div className="table-tour-div">
        ¿Quieres realizar una acción masiva sobre tus postulantes? En la pestaña{' '}
        <strong>Candidatos</strong> podrás ver y gestionar a todos los
        candidatos que participan de tu proceso, sin importar la etapa en la que
        se encuentren.
      </div>
    ),
    // action: () => {
    //   actions.handleDrawer.off();
    // },
  },
  {
    selector: '.ant-tabs-nav',
    content: () => (
      <div className="table-tour-div">
        ¿Quieres acceder rápidamente a información sobre documentos de tus
        candidatos? ¡Desde la pestaña <strong>Documentos</strong> puedes
        hacerlo!
      </div>
    ),
  },
];

export default tourConfigTable;
