import React, { useEffect } from "react";

import { Button, Form, Input, message, Modal, Row, Col, Typography } from "antd";
import i18n from "../../../i18n";

const { Text } = Typography;

const FormDiscardReasonModal = ({ 
  actions, 
  business,
  discardReasonId,
  discardReasons,
  form, 
  handleVisible, 
  token, 
  updateDiscardReasons, 
  visible 
}) => {

  const { getFieldDecorator, getFieldValue } = form;
  const BASE_URL = `/api/v1/gamesandtests/business/${business.id}/discardreasons/`;

  const validateInput = (_, value) => {
    if (!value) return true;
    return !discardReasons.map(({ title }) => title.toUpperCase()).includes(value.toUpperCase());
  };

  const submitDiscardReasonForm = (e) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      const { title } = values;
      const url = discardReasonId ? `${BASE_URL}${discardReasonId}/` : BASE_URL;            
      if (discardReasonId) {
        const data = {
            title,
            public: false,
        };
        actions.fetchAndReturn(token, url, 'PATCH', data)
            .then((response) => {
                message.success(i18n.t('commons__saved_changes',{ns: 'businessUser'}));
                handleVisible.off();
                form.resetFields();
                updateDiscardReasons();
            }).catch((error) => {
                message.error(i18n.t('commons__unsaved_changes',{ns: 'businessUser'}));
            });  
      } else {
        const method = 'POST';
        const data = {
          title,
          public: false,
        };

        actions.fetchAndReturn(token, url, method, data)
          .then((response) => {
            message.success(i18n.t('commons__saved_changes',{ns: 'businessUser'}));
            handleVisible.off();
            form.resetFields();
            updateDiscardReasons();
          }).catch((error) => {
            message.error(i18n.t('commons__unsaved_changes',{ns: 'businessUser'}));
          });              
      }
    });
  };

  useEffect(() => {
    if (discardReasonId) {
      const discardReason = discardReasons.find((discardReason) => discardReason.id === discardReasonId);
      form.setFieldsValue({
        title: discardReason.title,
      });
    }
    else {
      form.setFieldsValue({
        title: undefined,
      });
    }
  }, [discardReasonId]);

  return (
      <Modal 
        title={discardReasonId ? i18n.t('management__edit_discard_reason_title',{ns: 'businessUser'}) : i18n.t('management__create_discard_reason',{ns: 'businessUser'})}
        onCancel={handleVisible.off}
        visible={visible}
        footer={[
          <Button onClick={() => { handleVisible.off() }}>
            {i18n.t('commons__cancel')}
          </Button>,
          <Button type="primary" onClick={submitDiscardReasonForm}>
            {i18n.t('commons__save')}
          </Button>
        ]}
      >
        <Form onSubmit={submitDiscardReasonForm} colon={false}>
          <Form.Item 
            name='title' 
            label={<><Text style={{ color: 'red' }}> * </Text>{i18n.t('management__singular_discard_reason', {ns: 'businessUser'})}</>}
            help={
              <Row justify="space-between" type="flex">
                <Col>
                  {getFieldValue('title')?.length == 0 ? i18n.t('commons__mandatory',{ns: 'businessUser'}) : ''}
                  {validateInput(null, getFieldValue('title')) ? '' : i18n.t('management__reason_exists', {ns: 'businessUser'})}
                </Col>
                <Col>
                  {`${getFieldValue('title')?.length ?? 0}/40`}
                </Col>
              </Row>
            }
          >
            {getFieldDecorator('title', {
              rules: [
                { required: true },
                { validator: validateInput },
              ], 
            })(<Input maxLength={40}  placeholder={i18n.t('management__create_discard_reason_placeholder',{ns: 'businessUser'})}/>)}
          </Form.Item> 
        </Form>   
      </Modal>
  );
};

export default Form.create()(FormDiscardReasonModal);