import React from 'react';
import {
  Row,
  Col,
  Typography
} from 'antd';
import propTypes from 'prop-types';
import i18n from '../../../i18n';
const { Text } = Typography;

/**
 * 
 * @param {Boolean} jobView
 * @param {String} searchText
 * @param {String} locationFullName 
 * @returns 
 */

const NotFound = ({ jobView, searchText, locationFullName }) => {
  return (
    <>
      {
        jobView ?
          (
            <Row gutter={[0, 15]}>
              <Col span={24}>
                <Text style={{ fontSize: '18px' }}>
                  {i18n.t('job_portal__search__not__found').split('**')[0]} <strong>{searchText ? searchText : i18n.t('job_portal__all_type')}  {i18n.t('job_portal__search__not__found').split('}')[1].split('{')[0]} {locationFullName}</strong>  {i18n.t('job_portal__search__not__found').split('**')[2]}
                </Text>
              </Col>
              <Col span={24}>
                <Text style={{ fontSize: '18px' }} strong>{i18n.t('job_portal__suggestions')}</Text>
              </Col>
              <Col span={24} style={{ marginLeft: '25px' }}>
                <ul style={{ listStyle: 'initial' }}>
                  <li style={{ margin: '10px 0', fontSize: '18px' }}>{i18n.t('job_portal__filter__recommendation__location')}</li>
                  <li style={{ margin: '10px 0', fontSize: '18px' }}>{i18n.t('job_portal__filter__recommendation__words')}</li>
                  <li style={{ margin: '10px 0', fontSize: '18px' }}>{i18n.t('job_portal__filter__recommendation__spelling')}</li>
                  <li style={{ margin: '10px 0', fontSize: '18px' }}>{i18n.t('job_portal__filter__recommendation__whole__words')}</li>
                </ul>
              </Col>
            </Row>
          ) :
          (
            <Row style={{ margin: '0 0 100px 0' }}>
              <Col span={24}>
                <Text style={{ fontSize: '18px' }}>{i18n.t('job_portal__filter__bad__request')}</Text>
              </Col>
              <Col span={24} style={{ marginLeft: '25px' }}>
                <ul style={{ listStyle: 'initial' }}>
                  <li style={{ margin: '10px 0', fontSize: '18px' }}>{i18n.t('job_portal__filter__recommendation__location')}</li>
                  <li style={{ margin: '10px 0', fontSize: '18px' }}>{i18n.t('job_portal__filter__recommendation__words')}</li>
                  <li style={{ margin: '10px 0', fontSize: '18px' }}>{i18n.t('job_portal__filter__recommendation__spelling')}</li>
                  <li style={{ margin: '10px 0', fontSize: '18px' }}>{i18n.t('job_portal__filter__recommendation__whole__words')}</li>
                </ul>
              </Col>
            </Row>
          )
      }
    </>
  );
}

NotFound.propTypes = {
  jobView: propTypes.bool,
  searchText: propTypes.string,
  locationFullName: propTypes.string
}

export default NotFound;