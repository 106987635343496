import React, { useContext, useEffect, useState } from 'react';
import TraitList from "./TraitList/TraitList";
import { Typography, Collapse, Icon, Switch, Row, Col } from 'antd';
import JobAppContext from '../../../context/JobAppContext';
import './TraitsConfiguration.scss';

const { Title, Text } = Typography;
const { Panel } = Collapse;

function Configuration() {
  const {
    measuredTraits,
  } = useContext(JobAppContext);

  const [cognitiveTraits, setCognitiveTraits] = useState(null);
  const [personalityTraits, setPersonalityTraits] = useState(null);
  const [behavioralTraits, setBehavioralTraits] = useState(null);
  const [state, setState] = useState({
    activePanels: [],
    isChecked: false
  });

  useEffect(() => {
    // Cognitivo = reasoning + cognitive
    const cognitive = measuredTraits.current.filter(obj=>obj.trait.category==='COGNIT' && obj.trait.status == 'ACTIV');
    const reasoning = measuredTraits.current.filter(obj=>obj.trait.category==='RAZONA' && obj.trait.status == 'ACTIV');
    const updatedCognitive = [...cognitive, ...reasoning];

    // Conductual
    const behavioral = measuredTraits.current.filter(obj=>obj.trait.category==='EMOTIO' && obj.trait.status == 'ACTIV');
    // Perso
    const personality = measuredTraits.current.filter(obj=>obj.trait.category==='PERSON' && obj.trait.status == 'ACTIV');

    setCognitiveTraits(updatedCognitive);
    setPersonalityTraits(personality);
    setBehavioralTraits(behavioral);
    setState((oldState) => {
      let panels = []
      updatedCognitive.length && panels.push('1')
      personality.length && panels.push('2')
      behavioral.length && panels.push('3')
      return (
        { ...oldState, activePanels: panels }
      )
    });
  }, [measuredTraits.current]);

  const onChangeCollapse = (activeKeys) => {
    setState(oldState => ({ ...oldState, activePanels: activeKeys }))
  };

  const onChangeSwitch = (value) => {
    setState(oldState => ({ ...oldState, isChecked: value }));
  };

  return (
    <div className='genome-config'>
      <div className='distribution-description'>
        <Row>
          <Col span={20}>
            <Text strong>Mostrar promedio y distribución de candidatos</Text> <br />
          </Col>
          <Col span={4}>
            <Row type='flex' justify='end'>
              <Switch size='small' onChange={onChangeSwitch} />
            </Row>
          </Col>
        </Row>
        <Row>
          <Text type='secondary'>Con este gráfico podrás visualizar una distribución representativa de cada rasgo para identificar como se comporta la población. Podrás ver los promedios y los percentiles para compararlo con el resultado de tus candidatos.</Text>
        </Row>
      </div>

      <Collapse
        activeKey={state.activePanels}
        onChange={onChangeCollapse}
        className='genome-config-section'
        expandIcon={({ isActive, panelKey }) => {
          switch (panelKey) {
            case '1':
              return <Icon style={{ color: '#40A9FF' }} type="caret-right" rotate={isActive ? 90 : 0} />
            case '2':
              return <Icon style={{ color: '#9254DE' }} type="caret-right" rotate={isActive ? 90 : 0} />
            case '3':
              return <Icon style={{ color: '#FF7A45' }} type="caret-right" rotate={isActive ? 90 : 0} />
          }
        }}
      >

        <Panel header={<Text style={{ fontSize: 16, color: '#40A9FF' }}>Rasgos cognitivos</Text>} key="1">
          <div>
            <TraitList traits={cognitiveTraits} showDistribution={state.isChecked} />
          </div>
        </Panel>

        <Panel header={<Text style={{ fontSize: 16, color: '#9254DE' }}>Rasgos de Personalidad</Text>} key="2">
          <div>
            <TraitList traits={personalityTraits} showDistribution={state.isChecked} />
          </div>
        </Panel>

        <Panel header={<Text style={{ fontSize: 16, color: '#FF7A45' }}>Rasgos conductuales</Text>} key="3">
          <div>
            <TraitList traits={behavioralTraits} showDistribution={state.isChecked} />
          </div>
        </Panel>
      </Collapse>
    </div>
  )
};

export default Configuration;
