import React, { useState, useContext, useMemo, useEffect } from 'react';
import { push } from 'react-router-redux';
import { isMobile } from 'react-device-detect';
import './styles.scss';
// TODO: use useBoolean from outer hooks folder
import useBoolean from '../../hooks/useBoolean';
import CircleWithIcon from '../../../../components/circleWithIcon';

import { ListContext } from '../../context/ListContext';

import { Link } from 'react-router-dom';

import {
  Typography,
  Icon,
  Card,
  Col,
  Button,
  Input,
  Row,
  Select,
  Spin,
  Tabs,
  Popover,
} from 'antd';
import CustomSelect from '../CustomSelect';
import Filters from '../Filters';

import { dateDifferenteInDays } from '../../utils/calcs';

import i18n from '../../../../i18n';
import { trigramDistance, unaccent } from '../../../../utils/search';
import SERVER_URL from '../../../../utils/config';

const { TabPane } = Tabs;

const { Title, Text, Paragraph } = Typography;
const { Search } = Input;

const getStatusConfig = (opt) => {
  const options = {
    P_E: {
      icon: 'arrow-right',
      text: 'Postulación enviada',
      colors: {
        border: '#91D5FF',
        background: '#E6F7FF',
        color: '#1890FF',
      },
    },
    S_AP: {
      icon: 'check-circle',
      text: 'Sin act. pendientes',
      colors: {
        border: '#B7EB8F',
        background: '#F6FFED',
        color: '#52C41A',
      },
    },
    A_P: {
      icon: 'alert',
      text: 'Actividad pendiente',
      colors: {
        border: '#FFD591',
        background: '#FFF7E6',
        color: '#FA8C16',
      },
    },
    P_F: {
      icon: '',
      text: 'Proceso finalizado',
      colors: {
        border: '#D9D9D9',
        background: '#FAFAFA',
        color: '#454545',
      },
    },
  };

  switch (opt) {
    // TODO Remove
    case 'INITIAL':
      return options.P_E;
    case 'ACTIV':
    case 'V_INT':
      return options.A_P;
    case 'OTHER':
    case 'HIRED':
      return options.P_F;
    // New values
    case 'DONE':
      return options.S_AP;
    case 'PENDING':
      return options.A_P;
    default:
      return options.S_AP;
  }
};

const StatusProcess = ({ colors, icon, text }) => {
  return (
    <div
      style={{
        borderColor: colors.border,
        background: colors.background,
        color: colors.color,
      }}
      className="status-process"
    >
      <Icon type={icon} />
      <span>{text}</span>
    </div>
  );
};

const Process = ({ process, pendient, selectProcess }) => {
  const config = getStatusConfig(process.user_job_application_status);

  const days = dateDifferenteInDays(process.date_started);

  const charLimit = 50;
  const isLargerThan = process.job_application_name.length >= charLimit;

  const formattedLogo = process.business_logo[0] === '/' ? `${SERVER_URL}${process.business_logo}` : process.business_logo;

  const name = (
    <Text strong style={{ fontSize: 14, width: '60%' }}>
      {isLargerThan
        ? `${process.job_application_name.substring(0, charLimit)}...`
        : process.job_application_name}
    </Text>
  );
  const pluralDaysChar = days > 1 ? 's' : '';
  return (
    <Link
      to={`/jobapplication/${process.job_application_id}/`}
      style={{ color: '#5E5E5E' }}
    >
      <Row className="process-list-card" onClick={selectProcess} type="flex">
        <Col xs={6}>
          <Row
            type="flex"
            align="middle"
            justify="center"
            style={{ height: '100%', width: '100%' }}
          >
            <img
              style={{ maxWidth: '80%', maxHeight: '80%' }}
              src={formattedLogo}
            />
          </Row>
        </Col>
        <Col className="process-list-card__right" xs={18}>
          {isLargerThan ? (
            <Popover content={process.job_application_name}>{name}</Popover>
          ) : (
            name
          )}
          <span style={{ color: '#A4A4A4' }}>
            {i18n
              .t('processes__applied_days_ago')
              .replace('{{days}}', days)
              .replace('{{pluralDaysChar}}', pluralDaysChar)}
          </span>
          <Text style={{ width: '65%' }}>
            <p>{process.job_application_department}</p>
            <strong>
              {process.business_name}
            </strong>{' '}
            - {process.job_application_location_city},{' '}
            {process.job_application_location_country}
          </Text>
          <StatusProcess {...config} />
        </Col>
      </Row>
    </Link>
  );
};

const TABS = [
  {
    name: 'Activos',
    values: ['ACTIV', 'OTHER'],
  },
  {
    name: 'Cerrados',
    values: ['INACT'],
  },
];

const List = (props) => {
  const listContext = useContext(ListContext);
  const [showFilter, toggleFilter] = useBoolean(false);
  const [countryFilter, setCountryFilter] = useState(null);
  const [dateFilter, setDateFilter] = useState(null);
  const [nameFilter, setNameFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState(['ACTIV', 'OTHER']);
  const [candidateData, setCandidateData] = useState({});

  const { processes, loading } = listContext;

  const changeStatus = (key) => {
    const { values } = TABS.find((tab) => tab.name === key);

    setStatusFilter(values);
  };

  const filteredProcesses = useMemo(() => {
    const checkStatus = (proc) =>
      proc.job_application_status === statusFilter[0] ||
      proc.job_application_status === statusFilter[1];

    const checkCountry = (proc) =>
      countryFilter
        ? proc.job_application_location_country === countryFilter
        : true;

    const checkDate = (proc) =>
      dateFilter ? dateFilter >= dateDifferenteInDays(proc.date_started) : true;

    const checkName = (proc) =>
      nameFilter
        ? trigramDistance(
            nameFilter.toLowerCase(),
            proc.job_application_name.toLowerCase()
          ) < 0.9 ||
          unaccent(proc.job_application_name.toLowerCase())
            .toLowerCase()
            .includes(unaccent(nameFilter.toLowerCase()))
        : true;

    return processes.filter(
      (proc) =>
        checkStatus(proc) &&
        checkCountry(proc) &&
        checkDate(proc) &&
        checkName(proc)
    );
  }, [processes, statusFilter, countryFilter, dateFilter, nameFilter]);

  const filters = {
    time: {
      options: [
        { text: 'Todos', value: null },
        { value: 1, text: 'Últimas 24 horas' },
        { value: 7, text: 'Esta semana' },
        { value: 30, text: 'Este mes' },
        { value: 365, text: 'Este año' },
      ],
      placeholder: 'Fecha de publicación',
      action: setDateFilter,
      value: dateFilter,
      key: 'time-filter',
    },
    country: {
      options: [
        { text: 'Todos', value: null },
        { text: 'Chile', value: 'CL' },
        { text: 'Colombia', value: 'CO' },
        { text: 'Argentina', value: 'AR' },
        { text: 'Perú', value: 'PE' },
        { text: 'Ecuador', value: 'EC' },
        { text: 'Uruguay', value: 'UY' },
      ],
      placeholder: 'País',
      action: setCountryFilter,
      value: countryFilter,
      key: 'date-filter',
    },
  };

  useEffect(() => {
    const token = listContext.token;
    const url = 'accounts/personal/';
    listContext.actions.fetchData(token, url, '', 'GET').then((data) => {
      setCandidateData({ ...data });
    });
  }, []);

  const candidateDataPercentage = useMemo(() => {
    const uselessData = [
      'age',
      'avatar_initials',
      'commune',
      'disability_type',
      'dni',
      'email',
      'game_scores',
      'gender',
      'has_cv',
      'marital_status',
      'personality_scores',
      'reasoning_avg',
      'reasoning_scores',
      'satisfaction_survey',
      'share_feedback',
    ];
    if (Object.keys(candidateData).length === 0) return '0';
    let totalCount = 0;
    let completedCount = 0;
    Object.keys(candidateData).forEach((key) => {
      // Ignore some fields
      if (uselessData.includes(key)) {
        return;
      }

      const value = candidateData[key];
      totalCount++;
      if (!value) {
        return;
      } else if (typeof value === 'string' && value.length === 0) {
        return;
      } else if (typeof value === 'object' && Object.keys(value).length === 0) {
        return;
      }

      completedCount += 1;
    });
    return Math.floor((completedCount / totalCount) * 100);
  }, [candidateData]);

  return (
    <Spin spinning={loading}>
      <Row
        className="candidate-process-list"
        type="flex"
        style={{ padding: '2rem' }}
      >
        <Title>{i18n.t('navbar__my_process')}</Title>
        {processes.length ? (
          <>
            <Col xs={24}>
              <div className="search-and-filter">
                <Search
                  placeholder={i18n.t('job_portal__search')}
                  onChange={(e) => setNameFilter(e.target.value)}
                  value={nameFilter}
                />
                <Button type="secondary" icon="filter" onClick={toggleFilter} />
                <div>
                  {Object.values(filters).map((filter) => (
                    <CustomSelect {...filter} />
                  ))}
                </div>
              </div>
            </Col>
            <Col xs={24}>
              <Tabs defaultActiveKey="Activos" onChange={changeStatus}>
                {TABS.map((tab) => (
                  <TabPane
                    key={tab.name}
                    tab={`${tab.name} (${processes.filter((proc) => tab.values.includes(proc.job_application_status)).length})`}
                  />
                ))}
              </Tabs>
            </Col>
            <Row
              type="flex"
              gutter={[10, 10]}
              justify={filteredProcesses.length ? null : 'center'}
              className="process-list"
              style={{ margin: 0 }}
            >
              {filteredProcesses.length ? (
                filteredProcesses.map((proc) => (
                  <Col xs={24} lg={12} key={proc.job_application_id}>
                    <Process
                      process={proc}
                      pendient={
                        proc.user_job_application_status === 'ACTIV' ||
                        proc.user_job_application_status === 'V_INT'
                      }
                    />
                  </Col>
                ))
              ) : (
                <span>{i18n.t('processes__no_processes_to_show')}</span>
              )}
            </Row>
          </>
        ) : (
          <Col sm={24}>
            <Text>{i18n.t('processes__still_havent_applied')}</Text>
            <Row
              style={{ marginTop: '10px' }}
              gutter={[20, 20]}
              type="flex"
              align="middle"
              justify="start"
            >
              <Col xs={24} md={12} xl={8}>
                <Card style={{ border: '1px solid #e9e9e9' }}>
                  <Row type="flex" style={{ width: '100%' }}>
                    <Col xs={18}>
                      <div style={{ height: isMobile ? '' : '10rem' }}>
                        <Paragraph>
                          {i18n
                            .t('processes__data_completion_progress')
                            .replace(
                              '{{candidateDataPercentage}}',
                              candidateDataPercentage
                            )}
                        </Paragraph>
                        <Paragraph>
                          {candidateDataPercentage !== 100
                            ? i18n.t(
                                'processes__data_completion_fill_your_data'
                              )
                            : i18n.t(
                                'processes__data_completion_apply_to_jobs'
                              )}
                        </Paragraph>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <Row
                        type="flex"
                        align="top"
                        justify="end"
                        style={{ height: '100%' }}
                      >
                        <CircleWithIcon
                          icon="bell"
                          size={30}
                          backgroundColor={'#F0F5FF'}
                          iconColor={'#85A5FF'}
                        />
                      </Row>
                    </Col>
                  </Row>
                  <Row
                    type="flex"
                    align="middle"
                    justify="start"
                    style={{ bottom: 10 }}
                  >
                    <Button
                      onClick={() => listContext.dispatch(push('/me/profile'))}
                      type="primary"
                    >
                      {candidateDataPercentage !== 100
                        ? i18n.t(
                            'processes__data_completion_fill_your_data__button'
                          )
                        : i18n.t(
                            'processes__data_completion_apply_to_jobs__button'
                          )}
                    </Button>
                  </Row>
                </Card>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Card style={{ border: '1px solid #e9e9e9' }}>
                  <Row type="flex" style={{ width: '100%' }}>
                    <Col xs={18}>
                      <div style={{ height: isMobile ? '' : '10rem' }}>
                        <Paragraph>
                          {i18n.t('processes__go_to_resources_section')}
                        </Paragraph>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <Row
                        type="flex"
                        align="top"
                        justify="end"
                        style={{ height: '100%' }}
                      >
                        <CircleWithIcon
                          icon="trophy"
                          size={30}
                          backgroundColor={'#F0F5FF'}
                          iconColor={'#85A5FF'}
                        />
                      </Row>
                    </Col>
                  </Row>
                  <Row type="flex" align="middle" justify="start">
                    <Button
                      onClick={() => listContext.dispatch(push('/resources'))}
                      type="primary"
                    >
                      {i18n.t('processes__go_to_resources_section__button')}
                    </Button>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        )}
        {showFilter ? (
          <Filters toggle={toggleFilter} filters={filters} />
        ) : null}
      </Row>
    </Spin>
  );
};

export default List;
