import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import {
    BusinessCollaborators,
    BusinessRoles,
    HomeView,
    ImpersonateView,
    BusinessUserFirstLogin,
    NextGameView,
    CandidateLoginView, // new
    BusinessLoginView, // new
    EditBusinessUserView,
    SocialLoginRedirectView,
    SocialSignUp,
    SignUpView,
    NotFoundView,
    MaintenanceView,
    ForbiddenUserView,
    CandidateUserLanding,
    CandidatePath,
    JobApplicationProcessStartView,
    AccountView,
    UpdatePasswordView,
    UpdateMfaView,
    AccountPermissionsView,
    UpdateNameView,
    AccountSetting, // new
    JobPortals,
    // TODO
    Calendars,
    PersonalFormActivityView,
    PasswordRecoveryView,
    ChangePasswordView,
    KnockOutQuestionView,
    CustomTestView,
    CustomTestSurveyView,
    //  ProcessListView, 
    ProcessListV2,
    NewAnalytics, // TEST!
    PeopleAnalyticsCandidatesView, // new
    ShareProcess,
    CandidateList, // new
    CandidateUpload,
    CandidatePoolView,
    RecruitmentPortalSettingsV2,
    Tos,
    ShareCandidateFeedbackV2, //new
    CandidateFeedbackV2,
    CandidateAccountInfoView,
    CustomInterview,
    NewInterview,
    NewScorecard,
    EditInterview,
    EditScorecard,
    NewCandidatePath,
    NewProcessFormViewV2, //new
    NewProcessFlex,
    EmailTemplatesView, // new
    EmailTemplatesView2, // new
    NewTemplateView, // new
    EditTemplateView, // new
    NotificationSettingsView,
    NotificationHistoryView,
    JobListingViewV2,
    ExpireShareToken,
    AddReferencesView, //new
    ReferenceFormView,
    JobAppGroupView,
    ToolsDashboardView,
    InterviewProcessesView, //new
    ApplicationForm,
    ResourcesView,
    // Games v2
    BART2GameView,
    BIG5GameView,
    DICT2GameView,
    IOWA2GameView,
    MEMO2GameView,
    STRP2GameView,
    TEMO2GameView,
    THEX2GameView,
    TLET2GameView,
    TLOG2GameView,
    TNUM2GameView,
    DICE2GameView,
    EFRT2GameView,
    GNG2GameView,
    TOWR2GameView,
    CFITGameView,
    DOCRView,
    CATNGameView,
    LEADGameView,

    ALSTGameView,
    ALSPGameView,
    PCLAGameView,

    addDocumentsForm,
    PrivacyPolicyComponent,
    ReferencesDashboard,
    ReferenceTemplateForm,
    ClientsHome,
    IsolatedModal,
    Jobs,
    HiringRoomIntegration,
    LeverIntegration,

    Calendar, // client calendar
    ClearStorageView,
    CandidateInterview,
    ManageIntegrationsView,
    CustomFields,
    NewCustomTest,
    CustomTestCreate,
    CandidateProfileSection,
    Evaluations,
    GenomaTests,
    FilesTemplateDashboard,
    FilesTemplateForm,
    CustomFieldForm,
    DiscardReasonsView,
} from './containers';
import requireAddons from './utils/requireAddons';
import requireAuthentication from './utils/requireAuthentication';
import requireAnyAuth from './utils/requireAnyAuth';
import CandidateRoute from './utils/CandidateRoute';
import requireAuthenticationInsideActiveProcess from './utils/requireAuthenticationInsideActiveProcess';
import SituationalTests from './containers/SituationalTests';

export default (
  <Suspense fallback={<div>Loading...</div>}>
    <Switch>
        {/* <Suspense fallback={<div>Loading...</div>}> */}
        {/*<Route path="*" component={MaintenanceView} />*/}
        <Route exact path="/" component={HomeView} />
        <Route path='/business/users' component={requireAuthentication(BusinessCollaborators, 'business')} />
        <Route path='/business/roles' component={requireAuthentication(BusinessRoles, 'business')} />
        <Route path="/login/socialredirect" component={SocialLoginRedirectView} />
        <Route path="/login/:businessuserslug" component={CandidateLoginView} />
        <Route path="/login" component={CandidateLoginView} />
        <Route path="/businesslogin" component={BusinessLoginView} />
        <Route exact path="/signup" component={SignUpView} />
        <Route path="/signup/:jobappid" component={SocialSignUp} />
        <Route path="/confirmDNI" component={SocialSignUp} />
        <Route path="/tokenauth" component={ImpersonateView} /> {/* Ruta temporal hasta moverlo afuera */}
        <Route path='/businessuserloginconfirm' component={BusinessUserFirstLogin} />
        <Route path="/otlogin" component={ImpersonateView} />
        <Route path="/nextgame/:jobappid/:game" component={requireAnyAuth(NextGameView)} />
        <Route path="/passwordrecovery/setpassword/:token" component={ChangePasswordView} />
        <Route path="/passwordrecovery" component={PasswordRecoveryView} />
        <Route path="/setpassword" component={ChangePasswordView} />
        <Route path="/account/businessuser" component={requireAnyAuth(EditBusinessUserView)} />
        <Route exact={true} path="/account/settings" component={requireAnyAuth(AccountView)} />
        <Route path="/account/settings/updatepassword" component={requireAnyAuth(UpdatePasswordView)} />
        <Route path="/account/settings/updatemfa" component={requireAnyAuth(UpdateMfaView)} />
        <Route path="/account/settings/permissions" component={requireAnyAuth(AccountPermissionsView)} />
        <Route path="/account/settings/updatename" component={requireAnyAuth(UpdateNameView)} />
        <Route exact={true} path="/account/settings/notifications" component={requireAnyAuth(NotificationSettingsView)} />
        <Route path="/notifications" component={requireAnyAuth(NotificationHistoryView)} />
        
        <Route path="/account/configuration" component={requireAuthentication(AccountSetting, 'business')} />
        <Route path="/account/recruitmentportal" component={requireAuthentication(RecruitmentPortalSettingsV2, 'business')} /> {/* Material */}
        <Route path="/account/interviewkit/new/scorecard" component={requireAuthentication(NewScorecard, 'business')} />
        <Route path="/account/interviewkit/new/interview" component={requireAuthentication(NewInterview, 'business')} />
        <Route path="/account/interviewkit/edit/scorecard/:id" component={requireAuthentication(EditScorecard, 'business')} />
        <Route path="/account/interviewkit/edit/interview/:id" component={requireAuthentication(EditInterview, 'business')} />
        <Route path="/account/interviewkit" component={requireAuthentication(CustomInterview, 'business')} />
        <Route path={'/interviews'} component={requireAddons(requireAuthentication(InterviewProcessesView, 'business'), 'TERA')} />
        <Route path="/tools" component={requireAuthentication(ToolsDashboardView, 'business')} />
        <Route path="/applicationform" component={requireAuthentication(ApplicationForm)} />
        <Route path='/calendar' exact component={requireAuthentication(Calendar, 'business')}  />

        <Route path="/candidates" component={requireAuthentication(CandidatePoolView, 'business')} /> {/*TODO remove deprecated component*/}
        <Route path="/peopleanalytics/candidates" component={requireAuthentication(PeopleAnalyticsCandidatesView, 'business')} /> {/* Comparador de candidatos */}
        <Route path="/peopleanalytics" component={requireAuthentication(NewAnalytics, 'business')} />
        <Route exact path="/processes/:jobappid/newedit" component={requireAuthentication(NewProcessFormViewV2, 'business')} />
       
        <Route path="/processes/new" component={requireAuthentication(NewProcessFlex, 'business')} />
        <Route exact path="/processes/:jobappid/edit" component={requireAuthentication(NewProcessFlex, 'business')} />

        <Route path="/integrations/hiringroom" component={requireAuthentication(HiringRoomIntegration)} />
        <Route path="/integrations/lever" component={requireAuthentication(LeverIntegration, 'business')} />
        <Route path="/integrations" component={requireAuthentication(ManageIntegrationsView, 'business')} />
        <Route path="/filestemplates" exact component={requireAuthentication(FilesTemplateDashboard, 'business')} />
        <Route path="/filestemplates/edit/:id" component={requireAuthentication(FilesTemplateForm, 'business')} />
        <Route path="/filestemplates/new" component={requireAuthentication(FilesTemplateForm, 'business')} />
        
        <Route path='/home' exact component={requireAuthentication(ClientsHome, 'business')} />
        <Route path="/customfields/edit/:id" component={requireAuthentication(CustomFieldForm, 'business')} />
        <Route path="/customfields/new" component={requireAuthentication(CustomFieldForm, 'business')} />
        <Route path="/customfields" component={requireAuthentication(CustomFields, 'business')} />
        <Route path="/filestemplates/edit/:id" component={requireAuthentication(FilesTemplateForm, 'business')} />
        <Route path="/filestemplates/new" component={requireAuthentication(FilesTemplateForm, 'business')} />
        <Route path="/filestemplates" exact component={requireAuthentication(FilesTemplateDashboard, 'business')} />

        <Route path='/home' exact component={requireAuthentication(ClientsHome, 'business')} />
        <Route path='/customtest/copy/:cutecopy' component={requireAddons(requireAuthentication(NewCustomTest, 'business'), 'CUTE')} />
        <Route path='/customtest/edit/:cuteid' component={requireAddons(requireAuthentication(NewCustomTest, 'business'), 'CUTE')} />
        <Route path='/customtest/new' component={requireAddons(requireAuthentication(NewCustomTest, 'business'), 'CUTE')} />
        <Route exact path='/customtest' component={requireAddons(requireAuthentication(CustomTestCreate, 'business'), 'CUTE')} />

        <Route path="/candidateprofile/:jobappid/:candidateid" component={requireAuthentication(IsolatedModal, 'business')} />

        <Route path="/candidatelist/:jobappid" component={requireAuthentication(CandidateList, 'business')} />
        <Route path="/newcandidatelist/:jobappid" component={requireAuthentication(CandidateList, 'business')} />
        <Route path="/processes/:jobappid" component={requireAuthentication(CandidateList, 'business')} /> {/* integraciones usan la url vieja */}
        {/* <Route path="/processes" component={requireAuthentication(ProcessListView)} /> */}
        <Route path="/share/:jobappid" component={requireAuthentication(ShareProcess, 'business')} />
        <Route path="/candidateupload/:jobappid" component={requireAuthentication(CandidateUpload, 'business')} />
        <Route path="/processes" component={requireAuthentication(ProcessListV2, 'business')} />
        <Route path="/emailtemplates/:templateid/edit" component={requireAuthentication(EditTemplateView, 'business')} />
        <Route path="/emailtemplates/new" component={requireAuthentication(NewTemplateView, 'business')} />
        {/* <Route path="/emailtemplates" component={requireAuthentication(EmailTemplatesView, 'business')} /> */}
        <Route path="/emailtemplates" component={requireAuthentication(EmailTemplatesView2, 'business')} />
        <Route exact={true} path="/evaluations" component={requireAuthentication(Evaluations, 'business')} />
        <Route exact={true} path="/genomatests" component={requireAuthentication(GenomaTests, 'business')} />
        
        <Route exact={true} path="/situationals" component={requireAuthentication(SituationalTests, 'business')} />
        <Route path='/situationals/edit/:cuteid' component={requireAddons(requireAuthentication(NewCustomTest, 'business'), 'SJT')} />
        <Route path='/situationals/new' component={requireAddons(requireAuthentication(NewCustomTest, 'business'), 'SJT')} />

        <Route path="/startprocess/:jobappid" component={JobApplicationProcessStartView} />
        <Route path="/discardreasons" component={requireAuthentication(DiscardReasonsView, 'business')} />
        {/* <Route exact={true} path="/jobs/:businessuserslug/:jobportalslug" component={JobAppGroupView} /> */}
        <Route exact={true} path="/jobs/:businessuserslug" component={JobListingViewV2} />
        <Route exact={true} path="/jobs/:businessuserslug/:businesscellslug" component={JobListingViewV2} />
        <Route path="/jobs" component={requireAuthentication(Jobs, 'candidate')} />
        <Route path='/jobportals' component={requireAuthentication(JobPortals, 'business')} />
        <Route path="/jobappgroup/:groupid" component={JobAppGroupView} />
        {/* Juegos Antiguos */}
        
        {/* Games V2: Refactor to Hooks */}
        <Route path="/jobapplication/:jobappid/BAR2" component={requireAuthenticationInsideActiveProcess(BART2GameView)} />
        <Route path="/jobapplication/:jobappid/TNU2" component={requireAuthenticationInsideActiveProcess(TNUM2GameView)} />
        <Route path="/jobapplication/:jobappid/TLE2" component={requireAuthenticationInsideActiveProcess(TLET2GameView)} />
        <Route path="/jobapplication/:jobappid/TLO2" component={requireAuthenticationInsideActiveProcess(TLOG2GameView)} />
        <Route path="/jobapplication/:jobappid/TEM2" component={requireAuthenticationInsideActiveProcess(TEMO2GameView)} />
        <Route path="/jobapplication/:jobappid/DCT2" component={requireAuthenticationInsideActiveProcess(DICT2GameView)} />
        <Route path="/jobapplication/:jobappid/STR2" component={requireAuthenticationInsideActiveProcess(STRP2GameView)} />
        <Route path="/jobapplication/:jobappid/THE2" component={requireAuthenticationInsideActiveProcess(THEX2GameView)} />
        <Route path="/jobapplication/:jobappid/TPCG" component={requireAuthenticationInsideActiveProcess(BIG5GameView)} />
        <Route path="/jobapplication/:jobappid/IOW2" component={requireAuthenticationInsideActiveProcess(IOWA2GameView)} />
        <Route path="/jobapplication/:jobappid/MEM2" component={requireAuthenticationInsideActiveProcess(MEMO2GameView)} />
        <Route path="/jobapplication/:jobappid/DCE2" component={requireAuthenticationInsideActiveProcess(DICE2GameView)} />
        <Route path="/jobapplication/:jobappid/EFR2" component={requireAuthenticationInsideActiveProcess(EFRT2GameView)} />
        <Route path="/jobapplication/:jobappid/GNG2" component={requireAuthenticationInsideActiveProcess(GNG2GameView)} />
        <Route path="/jobapplication/:jobappid/TOW2" component={requireAuthenticationInsideActiveProcess(TOWR2GameView)} />
        <Route path="/jobapplication/:jobappid/CFIT" component={requireAuthenticationInsideActiveProcess(CFITGameView)} />
        <Route path="/jobapplication/:jobappid/DOCR" component={requireAuthenticationInsideActiveProcess(DOCRView)} />
        <Route path="/jobapplication/:jobappid/CATN" component={requireAuthenticationInsideActiveProcess(CATNGameView)} />
        <Route path="/jobapplication/:jobappid/LEAD" component={requireAuthenticationInsideActiveProcess(LEADGameView)} />
        {/* Juego Alster Chile/Peru**/}
        <Route path="/jobapplication/:jobappid/ALST" component={requireAuthenticationInsideActiveProcess(ALSTGameView)} />
        <Route path="/jobapplication/:jobappid/ALSP" component={requireAuthenticationInsideActiveProcess(ALSPGameView)} />
        <Route path="/jobapplication/:jobappid/PCLA" component={requireAuthenticationInsideActiveProcess(PCLAGameView)} />

        {/* <Route path="/jobapplication/:jobappid/EFRT" component={requireAuthenticationInsideActiveProcess(EFFRTGameView)} /> */}
        <Route path="/jobapplication/:jobappid/FORM" component={requireAuthenticationInsideActiveProcess(PersonalFormActivityView)} />
        <Route path="/jobapplication/:jobappid/KO" component={requireAuthenticationInsideActiveProcess(KnockOutQuestionView)} /> {/* Material */}
        <Route exact={true} path="/jobapplication/:jobappid/CUTE" component={requireAuthenticationInsideActiveProcess(CustomTestView)} />
        <Route exact={true} path="/surveys/:surveyid" component={(CustomTestSurveyView)} />
        <Route exact={true} path="/jobapplication/:jobappid/interview/:personalInterview" component={requireAuthentication(CandidateInterview, 'candidate')} />
        <Route path="/jobapplication/:jobappid/documents/:triggerid" component={requireAnyAuth(addDocumentsForm)} />
        <Route path="/old/jobapplication/:jobappid" component={requireAuthenticationInsideActiveProcess(CandidatePath)} />
        <Route  path="/jobapplication/:jobappid" component={requireAuthenticationInsideActiveProcess(NewCandidatePath)} />
        <Route path="/scores/:id" component={ShareCandidateFeedbackV2} />

        <Route path="/tos" component={requireAnyAuth(Tos)} />
        <Route path="/resources" component={requireAuthentication(ResourcesView, 'candidate')} />
        <Route path="/me/scores" component={requireAuthentication(CandidateFeedbackV2, 'candidate')} />
        <Route path="/me/profile" component={requireAuthentication(CandidateProfileSection, 'candidate')} />
        {/* TODO: Deprecar */}
        <Route path="/me/profile/old" component={requireAuthenticationInsideActiveProcess(CandidateAccountInfoView)} />
        <CandidateRoute path="/me" component={requireAuthenticationInsideActiveProcess(CandidateUserLanding)} />

        <Route path="/sharedprofile/:tokenid/:jobappid/:candidateid" component={IsolatedModal}/>
        <Route path="/addrefs/:reference_token" component={requireAnyAuth(AddReferencesView)} />
        <Route path="/references/edit/:templateId" component={ReferenceTemplateForm} />
        <Route path="/references/create" component={ReferenceTemplateForm} />
        <Route path="/references/:referenceId" component={ReferenceFormView} />
        <Route path="/references" component={ReferencesDashboard} />
        <Route path="/:bu/:jobappid" component={JobApplicationProcessStartView} />
        <Route path="/forbiddenAccess" component={ForbiddenUserView} />
        <Route path="/expired" component={ExpireShareToken} />
        <Route path="/notFound" component={NotFoundView} />
        <Route path="/privacy" component={PrivacyPolicyComponent} />
        <Route exact={true} path="/clearstorage" component={ClearStorageView} />
       
        <Route path="/:wildcard" >
            <Redirect to="/notFound" />
        </Route>
        <Route path="*" component={NotFoundView} />
        {/* </Suspense> */}
    </Switch>
  </Suspense>
);
{/* <Route path='/customtest/copy/:cutecopy' component={requireAddons(requireAuthentication(NewCustomTest), 'CUTE')} />
<Route path='/customtest/edit/:cuteid' component={requireAddons(requireAuthentication(NewCustomTest), 'CUTE')} />
<Route path='/customtest/new' component={requireAddons(requireAuthentication(NewCustomTest), 'CUTE')} />
<Route exact path='/customtest' component={requireAddons(requireAuthentication(CustomTestCreate), 'CUTE')} /> */}
