import React, { useMemo } from "react";

import CustomSlider from "../../../../../components/CustomSlider";

import "../../pdf-css.scss";
import { Row, Col, Typography } from "antd";

const Personality = ({ candidates }) => {
  const withoutData = [];
  const personalityTraits = [[], [], [], [], [], []];

  candidates.forEach((candidate, indexCandidates) => {
    if (candidate.personality_scores.length === 0) {
      withoutData.push(indexCandidates);
    } else {
      let name;
      if (candidate.name) {
        name = candidate.name[0];
        if (candidate.surname) {
          name = name + candidate.surname[0];
        }
      } else {
        name = candidate.email[0] + candidate.email[1];
      }
      candidate.personality_scores.forEach((trait, indexSliders) => {
        personalityTraits[indexSliders].push([
          name,
          trait.score,
          [trait.trait, trait.lower_bound, trait.upper_bound],
        ]);
      });
    }
  });

  return (
    <Row >
      {candidates[0] ? (
        candidates[0].personality_scores.length !== 0 ||
          candidates[1].personality_scores.length !== 0 ? (
          personalityTraits.map((slider, index) => (
            slider.length > 0 ? (
              <Row key={index} className='personality-container'>
                <p className='personality-container__trait personality-container__trait--title'>{slider[0][2][0]}</p>
                <CustomSlider
                  span={24}
                  height={"13px"}
                  background={"#FFD6E7"}
                  margin={"15px 0"}
                  avatars={slider}
                  withoutData={withoutData}
                />
                <Col
                  span={24}
                  style={{ display: "flex", justifyContent: "space-between" }}
                  className="personality-container__trait personality-container__trait--description"
                >
                  <Col>
                    <p>{slider[0][2][1]}</p>
                  </Col>
                  <Col>
                    <p>{slider[0][2][2]}</p>
                  </Col>
                </Col>
              </Row>
            ) : null
          ))
        ) : (
          <Typography.Paragraph>Sin datos de personalidad</Typography.Paragraph>
        )
      ) : null}
    </Row>
  );
};

export default Personality;
