import React, { useContext } from 'react';
import PreviewModal from './components/PreviewModal';
import ShareModal from './components/ShareModal';
import CopyAndCreateModal from './components/CopyAndCreateModal';
import EditModalTemplate from './components/EditModalTemplate';
import FilterBar from './components/FilterBar';
import TableContainer from './components/TableContainer';
import MailTemplatesContext from './context/mailTemplates';

import useBreadcrumb from '../../../hooks/useBreadcrumb';

import { Typography, Row, Icon, Col } from 'antd';
import './template-mails.scss';

const { Title, Text } = Typography;

const TemplateMails = ({ history }) => {
  const { isActive } = useContext(MailTemplatesContext);

  const Breadcrumb = useBreadcrumb(
    [
      {
        path: '/',
        text: 'Home',
      },
      {
        path: 'tools',
        text: 'Gestión',
      },
      {
        path: 'emailtemplates',
        text: 'Plantillas de mensajería',
      },
    ],
    '>'
  );

  return (
    <Row className="template-container">
      <PreviewModal />
      <ShareModal />
      <CopyAndCreateModal />
      <EditModalTemplate />
      {Breadcrumb}
      <Row type="flex" align="middle">
        <Col style={{ marginRight: '10px' }}>
          <Icon
            type="arrow-left"
            style={{ cursor: 'pointer' }}
            onClick={history.goBack}
          />
        </Col>
        <Col xs={8} sm={12} className="template-container__title">
          <Title level={3}>Plantillas de mensajería</Title>
        </Col>
      </Row>
      <Row className="template-container__info">
        <Text>
          Agiliza la comunicación con tus candidatos utilizando o creando
          plantillas pre-guardadas que luego podrás reutilizar en tus procesos.
          También puedes crear categorías de plantillas según requieras.
        </Text>
      </Row>
      <FilterBar />
      <TableContainer />
    </Row>
  );
};

export default TemplateMails;
