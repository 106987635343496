import React, { useMemo, useContext } from 'react'
import { Row, Typography, Button, Col, Badge } from 'antd'
import { ResponsiveFunnel } from '@nivo/funnel'
import { AnalyticsContext } from '../../context/AnalyticsContext'

import { generateColorArray } from '../../../../../components/charts/utils';

const { Text } = Typography

const Funnel = () => {
  const { currentSection, sectionsData } = useContext(AnalyticsContext)
  const stages = sectionsData[currentSection].stages ?? {};
  const stages_names = sectionsData[currentSection].stages_names ?? {};

  // Stage_names are sorted!
  const data = useMemo(() => {
    const keyToName = stages_names ? stages_names : {};
    return Object.keys(stages_names).reduce((acc, ele) => {
      if (keyToName[ele]) {
        acc = [
          ...acc,
          {
            value: stages[ele],
            label: keyToName[ele],
            id: ele,
          },
        ];
      }

      return acc;
    }, []);
  }, [stages, sectionsData]);

  const borderColors = ['#0050B3', '#BAE7FF']; 
  const colors = generateColorArray(borderColors, Object.keys(stages_names).length)
  const assingColors = () => {
    const ret = data.map((item, idx) => (
      { ...item, color: colors[idx] }
    ))
    return ret;
  }

  return (
    data.length ?
      (
        <>
          <Col xs={24} md={18}>
            <Row type='flex' justify='space-around' style={{padding: '0px 16px'}}>
              {
                data.map(obj => <div>{obj.value}</div>)
              }
            </Row>
          </Col>
          <Row style={{ height: '350px' }}>
            <Col xs={24} md={18} style={{ height: '100%' }}>
              <ResponsiveFunnel
                data={data}
                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                interpolation="smooth"
                shapeBlending={.5}
                colors={colors}
                borderWidth={20}
                borderColor={{ theme: 'background' }}
                enableLabel={false}
                currentPartSizeExtension={5}
                currentBorderWidth={40}
                motionConfig="wobbly"
                direction='horizontal'
              />
            </Col>
            <Col xs={24} md={6} style={{ height: '100%' }}>
              <Row type='flex' justify='center' align='top' style={{ flexDirection: 'column', height: '100%', width: '100%', margin: '0 0 0 10px' }}>
                {
                  assingColors().map((item, idx) => (
                    <Badge key={idx} color={item.color} text={item.label} />
                  ))
                }
              </Row>
            </Col>
          </Row>
        </>
      ) :
      (
        <Row type='flex' justify='center' align='middle' style={{ height: '350px' }}>
          <Text strong>Sin información</Text>
        </Row>
      )
  );
}

export default Funnel;