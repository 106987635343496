import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Input,
  message,
  Modal,
  Row,
  Spin,
  Typography,
} from 'antd';

import i18n from '../../../../i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../../actions/data';

const { Text } = Typography;
const translationFile = { ns: 'candidateProfile' };

// TODO: Add PropTypes
const DocsResetModal = (props) => {
  const { candidateDocsReset, jobAppId, token, actions, source } = props;
  const [sendEmail, setSendEmail] = useState(false);
  const [mailText, setMailText] = useState('');
  const [resetFiles, setResetFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setResetFiles([]);
    setMailText('');
    setSendEmail(false);
    props.handleCancel();
  };

  const handleOk = async () => {
    setLoading(true);
    const data = {
      send_email: sendEmail,
      content: mailText ?? null,
    };
    if (source === 'candidateprofile') {
      const candidate = candidateDocsReset[0].candidate;
      resetFiles.forEach((id) => {
        actions.fetchAndReturn(
          token,
          `/api/v1/gamesandtests/jobapplications/${jobAppId}/personalusers/${candidate.personal_user_id}/filesrequests/${id}/`,
          'PATCH',
          data,
        )
          .then((res) => {
            if (res.status >= 400) {
              message.error(i18n.t('profile__reset_error', translationFile));
              setLoading(false);
              handleClose();
            } else {
              message.success(i18n.t('profile__reset_successfully', translationFile));
              setLoading(false);
              handleClose();
            }
          })
          .catch(() => {
            message.error(i18n.t('profile__reset_error', translationFile));
            setLoading(false);
            handleClose();
          });
      });
    } else {
      const floorDivision = (number, divisor) => (number - (number % divisor)) / divisor;
      // send requests in batches of 50
      const numBatches = Math.ceil(candidateDocsReset.length / 50);
      const batches = Array.from({ length: numBatches }, () => []);
      // Add users to each corresponding batch
      candidateDocsReset.forEach((user, index) => batches[floorDivision(index, 50)].push(user));
      // Send one request for each userBatch and add to mailPromises
      let successfulResets = 0;
      let failedResets = 0;
      let totalResetAttempts = 0;
      for (const batch of batches) {
        const resetRequests = [];
        for (const user of batch) {
          for (const id of resetFiles) {
            const request = actions.fetchAndReturn(
              token,
              `/api/v1/gamesandtests/jobapplications/${jobAppId}/personalusers/${user.personal_user_id}/filesrequests/${id}/`,
              'PATCH',
              data,
            ).then(({ status }) => status < 400 ? successfulResets++ : failedResets++);
            resetRequests.push(request);
            totalResetAttempts++;
          }
        }
        await Promise.all(resetRequests);
      }
      setLoading(false);
      if (failedResets === 0 && successfulResets === totalResetAttempts) {
        message.success(i18n.t('profile__reset_successfully', translationFile));
      } else {
        message.error(i18n.t('profile__reset_error', translationFile));
      }
      handleClose();
    }
  };

  const candidateName = candidateDocsReset[0]?.candidate?.user_name ?? '';
  let filesRequests = candidateDocsReset[0]?.files_requests;
  if (source === 'candidateprofile') {
    filesRequests = filesRequests?.filter(({ status }) => status === 'FINISHD');
  }

  return (
    <Modal
      footer={[
        <Button disable={loading} key="back" onClick={handleClose}>
          {i18n.t('commons__cancel')}
        </Button>,
        <Button key="submit" loading={loading} type="primary" onClick={handleOk}>
          {i18n.t('commons__reset')}
        </Button>,
      ]}
      onCancel={handleClose}
      bodyStyle={{ paddingTop: '1em' }}
      title={
        source === 'candidateprofile'
          ? i18n.t('profile__reset_request_title', translationFile).replace('{{name}}', candidateName)
          : i18n.t('profile__reset_requests_title', translationFile)
        }
      visible={props.visible}
      width="30em"
    >
      <Spin spinning={loading}>
        <Row style={{ flexDirection: 'column' }} type="flex">
          <Col>
            <Text strong>
              {i18n.t('profile__reset_docs', translationFile)}
            </Text>
          </Col>
          <Col>
            <Checkbox.Group
              onChange={(values) => setResetFiles(values.length > 0 ? values : [])}
              value={resetFiles}
            >
              {filesRequests?.map(
                ({ files_request }, idx) => (
                  <Row
                    key={`${files_request.id}-${idx}`}
                    style={{ flexDirection: 'column', padding: '0.5em' }}
                    type="flex"
                  >
                    <Checkbox
                      key={`${files_request.id}-${files_request.name}`}
                      value={files_request.id}
                    >
                      {files_request.name}
                    </Checkbox>
                  </Row>
                )
              )}
            </Checkbox.Group>
          </Col>
          <Col style={{ marginTop: '1em' }}>
            <Checkbox checked={sendEmail} onChange={({target}) => setSendEmail(target.checked)}>
              {source === 'candidateprofile'
                ? (
                  <Text>
                    {i18n.t('profile__notify', translationFile).replace('{{name}}', candidateName)} ({i18n.t('commons__optional')})
                  </Text>
                ) : (
                  <Text>
                    {i18n.t('profile__notify_candidates', translationFile)} ({i18n.t('commons__optional')})
                  </Text>
                )}
            </Checkbox>
          </Col>
          <Col>
            {sendEmail
              ? (
                <Input.TextArea
                  onChange={({ target }) => setMailText(target.value)}
                  rows={4}
                  style={{ marginTop: '10px' }}
                />
              ) : null}
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

export default connect(null, mapDispatchToProps)(DocsResetModal);
