import React, { Fragment, useState } from 'react';
import { Table, Typography, Tag } from 'antd';

/**
 * Footer table showing the xls preview
 * @param {*} props - data
 */
export default function OutTable(props) {
  const [state, setState] = useState({ page: 1, })
  const pageSize = 50

  const defaultCols = [
    {
      title: <Typography.Text strong> Fila </Typography.Text>,
      dataIndex: (_value, _row, index) => index,
      //state.page start at 1
      render: (_value, _row, index) => (index + 1) + (50 * (state.page - 1))
  
    },
    {
      title: <Typography.Text strong> Nombre </Typography.Text>,
      dataIndex: 'name',
    },
    {
      title: <Typography.Text strong> Apellido </Typography.Text>,
      dataIndex: 'surname',
    },
    {
      title: <Typography.Text strong> Email </Typography.Text>,
      dataIndex: 'email',
    },
    {
      title: <Typography.Text strong> DNI </Typography.Text>,
      dataIndex: 'dni',
    },
    {
      title: <Typography.Text strong> País documento </Typography.Text>,
      dataIndex: 'country_document',
    },
  ]

  const statusCol = [
    {
      title: <Typography.Text strong> Status </Typography.Text>,
      dataIndex: 'status',
      render: (status) => {
        let tags = {};
        if (status) {
          if (props.step === 'validation') {
            tags = status.validation
          } else if (props.step === 'import') {
            tags = status.import
          }
        }
        return (
          <Fragment>
            {status ? (
              [
                tags.error && tags.error.map(
                  tag => {
                    const color = 'volcano';
                    return (
                      <Tag color={color} key={tag}>
                        {tag.toUpperCase()}
                      </Tag>
                    );
                  }
                ),
                tags.success && tags.success.map(
                  tag => {
                    const color = 'green';
                    return (
                      <Tag color={color} key={tag}>
                        {tag.toUpperCase()}
                      </Tag>
                    );
                  }
                ),
                tags.warning && tags.warning.map(
                  tag => {
                    const color = 'orange';
                    return (
                      <Tag color={color} key={tag}>
                        {tag.toUpperCase()}
                      </Tag>
                    );
                  }
                ),
              ]
            ) : null
            }
          </Fragment>
        )
      }
    }
  ]

  /**
   * 
   * @param {*} fields 
   * @returns 
   */
  const getTableCols = (fields) => {
    return fields.map(obj => {
      const ko = {
                title: <Typography.Text strong> {obj.name} </Typography.Text>,
                dataIndex: obj.key,
                }
      return ko      
    })
  }  

  /**
   * Build the columns, use the base structure and add optional and ko fields when requested
   * @returns 
   */
  const getColumns = () =>{
    let cols = [...defaultCols]
    const optionalFields = props.optionalFields()
    const koFields = props.koFields()

    if (optionalFields.length > 0){
      const optionalCols = getTableCols(optionalFields);
      cols = [...cols, ...optionalCols];
    }
    if (koFields.length > 0){
      const koCols = getTableCols(koFields);
      cols = [...cols, ...koCols];
    }
    return [...cols, ...statusCol]
  }

  return (
    <Table
      locale={{
        emptyText: (
          <span>
            No se encontró archivo XLS
          </span>
        )
      }}
      rowKey={(row, index) => `${row.email}${index}`}
      pagination={{
        position: "top",
        defaultCurrent: 1,
        pageSize: pageSize,
        onChange(current) {
          setState({
            ...state,
            page: current
          });
        }
      }}
      dataSource={props.data}
      columns={getColumns()}
      scroll={{ x: true }}
    />
  );
};