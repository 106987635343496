import { findScore, findText, texts } from './utils';

import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

const LeadershipType = ({ category, traits }) => {
  return (
    <div className="leadership-description__category">
      <div
        className="leadership-description__category__header"
        style={{ background: category.color }}
      >
        {category.title}
      </div>
      {category.items ? (
        category.items.map((item) => {
          const score = findScore(traits, item.code);
          const text = findText(texts, item.code, score);
          return (
            <div className="leadership-description__item">
              <div className="leadership-description__item__left">
                <Text strong style={{ color: category.color }}>
                  {item.title}
                </Text>
                <Text>{text}</Text>
              </div>
              <div className="leadership-description__item__right">
                <Text
                  strong
                  style={{ fontSize: '2rem', color: category.color }}
                >
                  {score}
                </Text>
                <Text strong style={{ color: category.color }}>
                  Puntos
                </Text>
              </div>
            </div>
          );
        })
      ) : (
        <div className="leadership-description__item">
          <div className="leadership-description__item__left">
            <Text>
              {findText(texts, category.code, findScore(traits, category.code))}
            </Text>
          </div>
          <div className="leadership-description__item__right">
            <Text strong style={{ fontSize: '2rem', color: category.color }}>
              {findScore(traits, category.code)}
            </Text>
            <Text strong style={{ color: category.color }}>
              Puntos
            </Text>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeadershipType;
