import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { CandidateContext } from '../config/CandidateContext';
import { clearEmptyKeys } from '../../BusinessDashboard/CandidateList/helpers';
import { connect } from 'react-redux';

import { Avatar, Badge, Button, Icon, Tag, Typography } from 'antd';
import i18n from '../../../i18n';
import ActivityFilter from './ActivityFilter';

const { Text } = Typography;

const Activity = ({ auth, setOpenedMail }) => {
  const { 
    actions,
    candidate,
    changeKey,
    jobApp,
    tabKey,
    token,
    translationFile
  } = useContext(CandidateContext);

  const [candidateHistory, setCandidateHistory] = useState([]);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    const fetchCandidateHistory = async () => {
      let url = `/api/v1/gamesandtests/jobapplications/${jobApp.id}/candidates/${candidate.id}/userjobapplicationlogs/?format=json`
      
      const currentFilters = Object.entries(clearEmptyKeys(filters));

      if (currentFilters.length > 0) {
        url += `&${currentFilters
          .map(([key, value]) => `${key}=${value}`)
          .join('&')}`;
      }
      const response = await actions.fetchAndReturn(token, url);
      const historyLocalDateTimes = response.body.results.map((log) => { 
        const date = moment.utc(log.date_created, 'DD-MM-YYYY HH:mm').local();
        return {
          ...log, 
          localDate: date.format('DD MMMM YYYY'),
          localTime: date.format('h:mm a') 
      }});
      setCandidateHistory(historyLocalDateTimes);
    };
    fetchCandidateHistory();
  }, [filters, jobApp, candidate]);

  let currentDate = null

  const getIcon = (iconCode) => {
    const baseUrl = 'https://genoma-assets.s3.us-east-2.amazonaws.com/';

    switch (iconCode) {
      case 'FORM':
        return "https://i.imgur.com/IcMX6cs.png";
      case 'KO':
        return 'https://i.imgur.com/EUXTknN.png';
      case 'CUTE':
        return `${baseUrl}cute.svg`;
      case 'VINT':
        return `${baseUrl}path-icon-video.svg`;
      default:
        return `${baseUrl}${iconCode.replace('Mobile', '').trim()}.png`;
    }
  };

  const shouldRenderDateSection = (date) => {
    if (date !== currentDate) {
      currentDate = date;
      return true;
    }
    return false;
  };

  const handleSeeMailDetails = (objectId) => {
    setOpenedMail(parseInt(objectId));
    changeKey('4');
  }

  const handleSeeDetailsLink = (type, link, objectId) => {
    switch (type) {
      case 'message':
        return (
          <Button onClick={() => changeKey('5')} type='link'>
            {i18n.t('profile__view_detail', translationFile)}
          </Button>);
      case 'interview':
        return (
          <Button href={link} type='link'>
            {i18n.t('profile__view_detail', translationFile)}
          </Button>);
      case 'mail_history':
        return (
          <Button onClick={() => handleSeeMailDetails(objectId)} type='link'>
            {i18n.t('profile__view_detail', translationFile)}
          </Button>);
      case 'user_job_application':
        return (
          <Button onClick={() => changeKey('1')} type='link'>
            {i18n.t('profile__view_background', translationFile)}
          </Button>);
    };   
  };

  const getBadge = (agent, agentInitials) => {
    switch (agent) {
      case 'trigger':
        return <Avatar 
                  size="small" 
                  style={{ color: '#FFC53D', backgroundColor: '#FFFBE6' }} 
                  icon={<Icon type="thunderbolt" theme="filled"/>} 
                />;         
      case 'personal_user':
        return <Avatar size="small" style={{ backgroundColor: '#FFF0F6', color: '#F175A5', padding: 1 }}>{agentInitials}</Avatar>;
      case 'business_user':
        return <Avatar size="small" style={{ backgroundColor: '#D6E4FF', color: '#597EF7', padding: 1 }}>{agentInitials}</Avatar>
    }
  }

  return (
    <>
      <ActivityFilter 
        actions={actions}
        auth={auth}
        candidate={candidate}
        setFilters={setFilters}
        translationFile={translationFile}
      />
      <div className="new-modal-content__activities">
        {candidateHistory
          .map(({ agent, agent_initials, changes, localDate, localTime, log_data , object_id, type }) => {
            const { icon, link, text, variables } = log_data ?? {};
          return (
            <>
              {shouldRenderDateSection(localDate) && 
                <Text className="new-modal-content__activities__header">
                  {localDate}
                </Text>}
              <div className="new-modal-content__activities__item">
                <Badge count={getBadge(agent, agent_initials)} offset={[0, 35]}>
                  {type === 'assigned_activity_personal_progress' ? (
                    <img src={getIcon(icon)}/>
                  ) : 
                    <Avatar size="large" style={{ color: '#FFFFFF', backgroundColor: '#597EF7' }} icon={<Icon type={icon} />} />   
                  }
                </Badge>
                <Text style={{ flexGrow: 3 }}>
                  {i18n.t(text, {...translationFile, ...variables})}
                  {link && handleSeeDetailsLink(type, link, object_id)}
                  {icon === 'tag' && 
                    <Tag color='gold' style={{ marginLeft: 10 }}>
                      <Icon type="user" />
                      {changes.tags}
                    </Tag>
                  }
                </Text>
                <Text style={{ flex: 'None' }}>{localTime}</Text>
              </div>
            </>  
          )}
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Activity);
