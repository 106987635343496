import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';


export default class ExpireShareToken extends React.Component {
    render() {
        return (
            <Grid container>
                <Grid sm={12} spacing={8}>
                    <div className="permission_denied">
                        <div className="denied__wrapper">
                            <h1>Oops!</h1>
                            <h3>El perfil de este candidato no está disponible, si quieres acceder, solicita nuevamente el link a quien te lo envió.</h3>
                            <h3>Genomawork tiene este protocolo para proteger los datos de los candidatos</h3>
                            <img id="astronaut" src="https://static.wixstatic.com/media/6f7af9_9068a8e7bf0c4460b96f1a46e24a2710~mv2.png/v1/fill/w_178,h_178,al_c,q_80,usm_0.66_1.00_0.01/1.webp" alt=""/>
                            
                            <svg id="planet" style={{ width: '320px', marginLeft: '2rem'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <circle className="saturn" cx="256" cy="256" r="149.536" fill="#FF4F54"/>
                            <g className="saturn-2" fill="#EA4753">
                                <path d="M109.388 285.56c42.515 1.428 157.943-2.613 289.462-73.807-5.11-16.448-13.02-31.882-23.322-45.604-42.716 29.386-140.403 83.922-268.457 76.27-1.354 14.666-.508 29.175 2.318 43.14zM400.734 293.587c3.976-15.31 5.422-30.68 4.614-45.672-33.75 25.31-137.237 92.367-277.65 84.876 6.507 10.874 14.383 20.93 23.472 29.88 44.354.286 137.696-6.443 245.93-57.163 1.362-3.89 2.58-7.86 3.634-11.92zM245.488 405.184c35.427 2.537 69.784-7.742 97.543-27.59-27.972 11.533-60.787 21.76-97.542 27.59zM348.02 138.097c-15.645-12.225-33.99-21.522-54.434-26.832-71.883-18.667-145.126 18.253-174.25 84.01 49.02-1.676 133.073-12.256 228.685-57.178z"/>
                            </g>
                            <circle className="hover" cx="319.166" cy="208.081" r="28.389" fill="#D83A4E"/>
                            <path d="M331.25 189.492c6.04 1.568 11.114 4.97 14.792 9.452-2.98-8.73-10.143-15.848-19.74-18.34-15.175-3.94-30.672 5.167-34.613 20.342-2.373 9.136-.012 18.384 5.55 25.162-1.73-5.075-2.05-10.695-.602-16.273 3.94-15.177 19.438-24.284 34.613-20.343z" opacity=".1"/>
                            <circle className="hover" cx="234.463" cy="273.978" r="19.358" fill="#D83A4E"/>
                            <path d="M242.703 261.303c4.118 1.07 7.578 3.39 10.085 6.444-2.03-5.953-6.916-10.806-13.46-12.505-10.347-2.687-20.914 3.523-23.6 13.87-1.62 6.23-.008 12.537 3.785 17.158-1.18-3.46-1.398-7.293-.41-11.097 2.686-10.348 13.252-16.558 23.6-13.87z" opacity=".1"/>
                            <circle className="hover" cx="307.493" cy="144.207" r="12.584" fill="#D83A4E"/>
                            <path d="M312.85 135.967c2.678.695 4.927 2.204 6.557 4.19-1.32-3.872-4.496-7.026-8.75-8.13-6.727-1.747-13.596 2.29-15.343 9.017-1.052 4.05-.005 8.15 2.46 11.153-.767-2.25-.908-4.74-.267-7.213 1.747-6.727 8.616-10.764 15.343-9.017z" opacity=".1"/>
                            <circle className="hover" cx="163.289" cy="255.495" r="19.358" fill="#D83A4E"/>
                            <path d="M171.53 242.82c4.118 1.068 7.577 3.388 10.084 6.443-2.03-5.954-6.916-10.806-13.46-12.505-10.348-2.687-20.915 3.523-23.602 13.87-1.618 6.23-.008 12.536 3.785 17.158-1.18-3.46-1.398-7.293-.41-11.097 2.687-10.348 13.255-16.558 23.602-13.87z" opacity=".1"/>
                            <circle className="hover" cx="230.586" cy="365.92" r="19.358" fill="#D83A4E"/>
                            <path d="M238.826 353.245c4.118 1.07 7.578 3.39 10.085 6.444-2.03-5.954-6.915-10.807-13.46-12.506-10.347-2.688-20.914 3.522-23.6 13.87-1.62 6.23-.01 12.536 3.784 17.157-1.18-3.46-1.398-7.292-.41-11.096 2.688-10.346 13.255-16.556 23.602-13.87z" opacity=".1"/>
                            <circle className="hover" cx="302.221" cy="353.781" r="19.357" fill="#D83A4E"/>
                            <path d="M310.462 341.105c4.118 1.07 7.577 3.39 10.085 6.445-2.03-5.954-6.916-10.807-13.46-12.506-10.348-2.688-20.914 3.523-23.602 13.87-1.618 6.23-.008 12.536 3.785 17.157-1.18-3.46-1.398-7.29-.41-11.095 2.687-10.346 13.254-16.556 23.602-13.87z" opacity=".1"/>
                            <circle className="hover" cx="358.827" cy="284.847" r="11.079" fill="#D83A4E"/>
                            <path d="M363.542 277.593c2.357.612 4.337 1.94 5.772 3.688-1.162-3.406-3.958-6.184-7.703-7.156-5.922-1.537-11.97 2.017-13.507 7.938-.926 3.565-.005 7.175 2.166 9.82-.676-1.98-.8-4.175-.235-6.352 1.537-5.92 7.585-9.475 13.507-7.937z" opacity=".1"/>
                            <circle className="hover" cx="220.465" cy="156.416" r="11.079" fill="#D83A4E"/>
                            <path d="M225.18 149.162c2.358.612 4.338 1.94 5.773 3.688-1.162-3.408-3.958-6.185-7.703-7.157-5.922-1.538-11.97 2.016-13.508 7.938-.926 3.566-.004 7.175 2.167 9.82-.677-1.98-.8-4.174-.236-6.35 1.537-5.922 7.585-9.476 13.507-7.938z" opacity=".1"/>
                            <circle className="hover" cx="154.027" cy="171.743" r="5.819" fill="#D83A4E"/>
                            <path d="M156.504 167.933c1.238.322 2.278 1.02 3.03 1.938-.61-1.79-2.078-3.248-4.045-3.758-3.11-.808-6.288 1.06-7.095 4.17-.486 1.872-.002 3.767 1.138 5.156-.354-1.04-.42-2.192-.124-3.335.807-3.11 3.984-4.978 7.094-4.17z" opacity=".1"/>
                            <circle className="hover" cx="391.387" cy="251.305" r="5.819" fill="#D83A4E"/>
                            <path d="M393.864 247.495c1.237.32 2.277 1.02 3.03 1.937-.61-1.79-2.078-3.248-4.045-3.76-3.11-.807-6.288 1.06-7.096 4.17-.486 1.873-.002 3.768 1.138 5.158-.354-1.04-.42-2.192-.123-3.336.807-3.11 3.983-4.977 7.094-4.17z" opacity=".1"/>
                            <circle className="hover" cx="145.077" cy="302.473" r="5.819" fill="#D83A4E"/>
                            <path d="M147.554 298.662c1.238.322 2.277 1.02 3.03 1.938-.61-1.79-2.078-3.248-4.045-3.76-3.11-.807-6.288 1.06-7.096 4.17-.486 1.873-.002 3.77 1.138 5.157-.355-1.04-.42-2.19-.124-3.335.81-3.11 3.985-4.978 7.096-4.17z" opacity=".1"/>
                            <circle className="hover" cx="187.342" cy="355.265" r="5.819" fill="#D83A4E"/>
                            <path d="M189.818 351.455c1.238.32 2.278 1.02 3.032 1.938-.61-1.79-2.08-3.25-4.046-3.76-3.11-.808-6.287 1.06-7.095 4.17-.487 1.872-.003 3.768 1.137 5.157-.354-1.04-.42-2.192-.123-3.336.808-3.11 3.984-4.977 7.094-4.17z" opacity=".1"/>
                            <path d="M351.36 140.785c10.244 27.673 12.43 58.646 4.45 89.372-20.76 79.935-102.387 127.907-182.32 107.15-21.917-5.693-41.423-15.968-57.776-29.522 16.405 44.32 53.49 80.17 102.7 92.95 79.934 20.758 161.562-27.214 182.32-107.148 15.068-58.02-6.082-116.922-49.373-152.802z" opacity=".1"/>
                            <g>
                                <path className="stars" fill="#FFF" d="M112.456 363.093c-.056 7.866-6.478 14.197-14.344 14.142 7.866.056 14.198 6.48 14.142 14.345.056-7.866 6.48-14.198 14.345-14.142-7.868-.057-14.2-6.48-14.144-14.345zM432.436 274.908c-.056 7.866-6.478 14.198-14.344 14.142 7.866.057 14.197 6.48 14.142 14.345.056-7.866 6.48-14.197 14.345-14.142-7.868-.056-14.2-6.48-14.144-14.345zM159.75 58.352c-.12 16.537-13.62 29.848-30.157 29.73 16.537.118 29.848 13.62 29.73 30.156.118-16.537 13.62-29.848 30.156-29.73-16.54-.117-29.85-13.62-29.73-30.156z"/>
                            </g>
                            </svg>
                            <a href="/"><button className="denied__link">Volver</button></a>
                        </div>
                    </div>
                </Grid>
            </Grid>
        );
    }
}