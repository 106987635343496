import React, { Fragment, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Checkbox,
  Modal
} from 'antd';

function SelectionForm({ questionToEdit, ...props }) {
  const [state, setState] = useState({
    initialOptions: [0, 1],
    visible: false,
    waitInput: false,
    acceptInput: false,
    tmpAlternatives: null,
    alternativesSeparator: null,
  });

  const remove = k => {
    const { getFieldValue, setFieldsValue } = props;
    const keys = getFieldValue('keys');
    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }
    setFieldsValue({
      keys: keys.filter(key => key !== k),
    });
  };

  const add = () => {
    const { getFieldValue, setFieldsValue } = props;
    const keys = getFieldValue('keys');
    const nextKeys = keys.concat(keys.length);
    setFieldsValue({
      keys: nextKeys,
    });
  };

  const handleCheckbox = (index) => {
    const { type, getFieldValue, setFieldsValue } = props;
    const keys = getFieldValue('keys');
  };

  const showModal = () => {
    setState(oldState => ({
      ...oldState,
      visible: true,
    }))
  };

  const hideModal = () => {
    setState(oldState => ({
      ...oldState,
      visible: false,
      alternativesSeparator: null,
    }))
  };

  const confirmAlternatives = () => {
    const { setFieldsValue } = props;
    setFieldsValue({
      alternative: state.tmpAlternatives,
    })
    hideModal()
  }

  const multipleAlternativesAtOnce = (alternatives) => {
    setState(oldState => ({
      ...oldState,
      tmpAlternatives: alternatives.filter(alt => alt.length),
    }));

    const { getFieldValue, setFieldsValue } = props;
    // Se agregan posiciones adicionales a keys, alternatives y correct si es necesario
    const n_keys = getFieldValue('keys').length
    const n_alternatives = alternatives.length
    let previousAlternatives = getFieldValue('alternative')
    let previousCorrect = getFieldValue('correct')
    if (n_alternatives > n_keys) {
      for (let i = n_keys + 1; i <= n_alternatives; i++) {
        add()
        previousAlternatives = previousAlternatives.concat(undefined)
        previousCorrect = previousCorrect.concat(undefined)
      }
      setFieldsValue({
        alternative: previousAlternatives,
        correct: previousCorrect
      })
    } else if (n_alternatives < n_keys) {
      for (let i = n_alternatives; i < n_keys; i++) {
        remove(i)
      }
    }

    showModal()
  }

  const onChange = (event) => {
    // Parse input to detect multiple alternatives at once
    const input = event.currentTarget.value
    const sep = state.alternativesSeparator
    let alternatives;
    if (sep == null) {
      alternatives = input.match(/^.*((\r\n|\n|\r)|$)/gm)
    } else {
      alternatives = input.split(sep)
    }

    multipleAlternativesAtOnce(alternatives)
    setState(oldState => ({
      ...oldState,
      waitInput: false,
      acceptInput: true,
    }));
  }


  const { getFieldDecorator, getFieldValue } = props;
  const { initialOptions } = state;

  // Templates vienen con alternatives en configuration, preguntas ya cargadas vienen con alternatives en el mismo nivel
  // el campo alternatives de tempaltes esta deprecado por ser un char field
  const getAlternativesLength = () => {
    if (questionToEdit?.configuration?.alternatives ){
      return questionToEdit?.configuration?.alternatives .length
    }
    else if (questionToEdit?.alternatives){
      return questionToEdit?.alternatives.length 
    }
    else {
      return 0
    };
  }

  getFieldDecorator('keys', { initialValue: getAlternativesLength() > 0 ? [...Array(getAlternativesLength()).keys()] : initialOptions });
  const keys = getFieldValue('keys');

  return (
    <Fragment>
      {state.waitInput || state.acceptInput ?
        <Modal
          title='Copiar listado de alternativas'
          visible={state.visible}
          onOk={confirmAlternatives}
          onCancel={hideModal}
          okText='Confirmar'
          cancelText='Cancelar'
        >
          {state.waitInput ?
            <Fragment>
              <p>Copia y pega un listado de alternativas en la casilla</p>
              <br />
              <Form>
                <Form.Item label='Separador'>
                  <Input
                    value={state.alternativesSeparator}
                    onChange={(e) => {
                      const sep = e.target.value ? e.target.value : null;
                      setState(oldState => ({
                        ...oldState,
                        alternativesSeparator: sep,
                      }));
                    }}
                    placeholder='Ingresa el caracter que actuará como separador de las alternativas'
                  />
                  <span>Por defecto: Salto de línea. Sugerencias: , ; - / | </span>
                </Form.Item>
                <Form.Item label='Lista de alternativas'>
                  <Input.TextArea
                    onChange={onChange}
                    placeholder='Pega acá la lista de alternativas'
                  />
                </Form.Item>
              </Form>
            </Fragment>
            : state.acceptInput ?
              <Fragment>
                <p>Confirma que las alternativas estén correctas</p>
                <br />
                <h5>Alternativas</h5>
                <ul>
                  {state.tmpAlternatives.map((alt, i) => (<li key={i}>{`${i + 1} - ${alt}`}</li>))}
                </ul>
                <br />
                <p>IMPORTANTE: Al confirmar, todas las alternativas previas serán reemplazadas por las mostradas.</p>
              </Fragment>
              : null}
        </Modal>
        : null}

      <Row type='flex'>
        <Col span={24}>
          <div className='ant-col ant-form-item-label'>
            <label className='ant-form-item-required'>
              Alternativas
            </label>
          </div>
        </Col>
      </Row>

      <Row type='flex' gutter={[10,10]}> 
        {keys.map((k, index) => (
          <div key={index} style={{ width: '100%' }}>
            <Col sm={15}>
              <Form.Item>
                {getFieldDecorator(`alternative[${k}]`, {
                  validateTrigger: ['onChange', 'onBlur'],
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: 'Por favor escribe una alternativa',
                    },
                  ],
                })(<Input placeholder='Alternativa' />)}
              </Form.Item>
            </Col>

            {(
              <Col sm={6}>
                <Form.Item>
                  {getFieldDecorator(`correct[${k}]`, {
                    initialValue: false,
                    valuePropName: 'checked',
                    onChange: () => handleCheckbox(index)
                  })(
                    <Checkbox>¿Es correcta?</Checkbox>
                  )}
                </Form.Item>
              </Col>
            )}

            <Col sm={1}>
              <Form.Item>
                {keys.length >= 2 ? (
                  <Button icon='delete' onClick={() => remove(k)} />
                ) : null}
              </Form.Item>
            </Col>
          </div>
        ))}

        <Col span={24}>
          <Button
            type='link'
            onClick={add}
            icon='plus-circle'
            style={{ paddingLeft: 0 }}
          >
            Agregar alternativa
          </Button>
        </Col>
        <Col span={24}>
          <Button
            type='link'
            style={{ paddingLeft: 0 }}
            onClick={() => {
              setState(oldState => ({
                ...oldState,
                waitInput: true,
                visible: true,
              }))
            }}
            icon='plus-circle'
          >
            Copiar lista de alternativas
          </Button>
        </Col>
      </Row>
    </Fragment>
  )
};

export default SelectionForm;