import React, { useState, useEffect, useCallback } from 'react'

import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';

import * as actionCreators from '../../../../../actions/data';
import * as authActionCreators from '../../../../../actions/auth';
import * as traitActionsCreators from '../../../../../actions/trait';

import {
  Button,
  Col,
  Icon,
  Input,
  Row,
  Modal,
  Popconfirm,
  Popover,
  Skeleton,
  Typography,
  message
} from 'antd'

import GenomeInfo from './GenomeInfo'
import GenomeCard from './GenomeCard'
import './NewProcessModal.scss'

function NewProcessModal({ closeModal, visible, canCreate, ...props }) {
  const [activeItem, setActiveItem] = useState(0);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState(undefined);
  const [smartSearchInput, setSmartSearchInput] = useState(undefined);
  const [smartSearchPopoverOpen, setSmartSearchPopoverOpen] = useState(false)
  const [smartSearchActive, setSmartSearchActive] = useState(false)
  // el orden importa ->  GenomeInfo -> activeTabInfo
  const categories = ['BASAL', 'COGNI', 'EXPER', 'CLONE'];
  // Todavia no se usa
  const [allFamilyLabels, setAllFamilyLabels] = useState([]);

  useEffect(() => {
    if (searchInput !== undefined) {
      searchGenome();
    };
    // Set smart search just in case
    setSmartSearchInput(searchInput);
  }, [searchInput]);

  useEffect(() => {
    stableLoadData();
  }, []);

  const stableLoadData = useCallback(() => {
    loadData();
  }, []);

  useEffect(() => {
    setLoading(true);
    setFilteredData(data.filter(
      item => item.genome_category === categories[activeItem]
    ));
    setSearchInput(undefined);
    setSmartSearchInput(undefined);
    setLoading(false);
  }, [activeItem]);

  const loadData = async () => {
    const url = `gamesandtests/business/${props.business.id}/activitypacks/`;
    await props.actions
      .fetchData(props.token, url, '', 'get')
      .then((response) => {
        const data = [];
        const labels = new Set();
        response.results.forEach((pag) => {
          pag.templates.forEach((template) => {
            data.push(template);
            labels.add(...template.keywords.map((keyword) => keyword.family));
          })
        });
        data.sort((a, b) => a.job_application.localeCompare(b.job_application));
        setData(data);
        const mylabels = Array.from(labels);
        setAllFamilyLabels(mylabels);
        setFilteredData(data.filter(
          item => item.genome_category === categories[activeItem]
        ));
        setLoading(false);
      })
      .catch((e) => console.log(e));
  };

  const isItemActive = (itemIdx) => {
    return activeItem === itemIdx ? 'active' : ''
  };

  const searchGenome = () => {
    setSmartSearchActive(false);
    if (searchInput === '' || searchInput == undefined) {
      setFilteredData(data.filter(
        item => item.genome_category === categories[activeItem]
      ));
    }
    const newData = data.filter(item => item.job_application.toLowerCase().includes(searchInput.toLowerCase()))
    setFilteredData(newData.filter(
      item => item.genome_category === categories[activeItem]
    ));
  }

  const handleTagChange = (value) => {
    if (value.length === 0) {
      setSelectedFamilyLabels([]);
    }
    setSelectedFamilyLabels(value);
  }

  const handleDuplicate = (jobappId) => {
    setLoading(true)
    const url = `/api/v1/gamesandtests/jobapplications/${jobappId}/duplicate/`
    props.actions.fetchAndReturn(props.token, url, 'POST', { is_genome_template: true })
      .then((response) => {
        return response
      }).then((response) => {
        setLoading(false)
        if (response.status && response.status < 400) {
          props.dispatch(push(`/processes/${response.body.id}/edit`))
        }
        else {
          message.error("No se pudo crear el proceso")
        }
      })
  };

  const getTotalGenomes = () => {
    return data.filter(obj => obj.genome_category === categories[activeItem]).length
  };

  const smartSearchGenome = async (customValue = undefined) => {
    if (customValue == undefined && (smartSearchInput === '' || smartSearchInput == undefined)) {
      setFilteredData([...data.filter(
        item => item.genome_category === categories[activeItem]
      )]);
      setSmartSearchActive(false);
    }
    else {
      setLoading(true)
      const encodedInput = encodeURIComponent(smartSearchInput)
      const url = `${process.env.REACT_APP_DS_URL}search/genomes/?search=${encodedInput}`
      const response = await props.actions.fetchAnyUrl(props.token, url, 'GET');
      setSmartSearchActive(true);
      const foundGenomes = [];
      response.results.forEach(obj => data.find(job => {
        if (job.id === obj.job_application_id) {
          foundGenomes.push(job)
        }
      }))
      const result = foundGenomes.filter(
        item => item.genome_category === categories[activeItem]
      );

      setFilteredData(result.slice(0, 5));
    };
    //setSearchInput(undefined);
    setLoading(false);
    setSmartSearchPopoverOpen(false);
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      smartSearchGenome()
    }
  };

  const smartGenomeSearchContent = () => {
    return (
      <div style={{ width: 350, }}>
        <Typography.Text>
          Te presentamos opciones de cargos que
          se alinean con tu búsqueda.
          Evaluamos y clasificamos los genomas
          más sobresalientes para el cargo que buscas.
        </Typography.Text>
        <Input
          placeholder='Buscar genoma'
          suffix={<Icon type='search' onClick={smartSearchGenome} />}
          value={smartSearchInput}
          onChange={(e) => setSmartSearchInput(e.target.value)}
          onKeyDown={handleEnter}
          style={{ marginTop: 15 }}
        />
        <Row type='flex' justify='end'>
          <Button type='primary' onClick={smartSearchGenome} style={{ marginTop: 15 }}>Buscar</Button>
        </Row>
      </div>

    )

  };

  const NotFoundGenome = () => {
    return (
      <Row type='flex' justify='center' align='middle' style={{ height: '30vh' }}>
        <Row type='flex' justify='center'>
          <Col span={20}>
            <Row type='flex' justify='center'>
              <Icon type="search" style={{ fontSize: 48 }} />
            </Row>
          </Col>
          <Col span={20}>
            <Row type='flex' justify='center'>
              <div style={{ color: '#454545', fontWeight: 700, fontSize: 18 }}>
                No se encontraron resultados
              </div>
            </Row>
          </Col>
          <Col span={16}>
            <Row type='flex' justify='center'>
              <Typography.Text>
                No encontramos nada que coincida con un genoma. Te recomendamos utilizar nuestra
              </Typography.Text>

              <img style={{ height: 12, margin: '0px 5px 0px 5px' }} src='https://genoma-assets.s3.us-east-2.amazonaws.com/magic-wand.svg' alt="genomin-6" />
              <Typography.Text strong style={{ color: '#9254DE' }}>
                recomendación inteligente
              </Typography.Text>
            </Row>
            <Row type='flex' justify='center'>
              <Button
                style={{ marginTop: 20 }}
                type='primary'
                onClick={
                  () => {
                    //setSearchInput(undefined);
                    smartSearchGenome(searchInput)
                  }
                }
              >
                Buscador Inteligente
              </Button>
            </Row>
          </Col>
        </Row >
      </Row >
    )
  }

  return (
    <Modal
      visible={visible}
      title={'Nuevo proceso'}
      width={1160}
      footer={[]}
      onCancel={() => { closeModal(); setSmartSearchPopoverOpen(false) }}
      bodyStyle={{ padding: 0, height: '75vh' }}
    >
      <Row type='flex' style={{ height: '100%' }}>
        <Col className='new-process-left-menu' sm={6}>
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
              <div className='menu-title'>
                Genomas predeterminados
              </div>
              <div className={`menu-item ${isItemActive(0)}`} onClick={() => setActiveItem(0)}>
                <img src="https://genoma-assets.s3.us-east-2.amazonaws.com/genomin-2.svg" alt="genomin-2" />
                <Typography.Text>
                  Genomas basales
                </Typography.Text>
              </div>
              <div className={`menu-item ${isItemActive(1)}`} onClick={() => setActiveItem(1)}>
                <img src="https://genoma-assets.s3.us-east-2.amazonaws.com/genomin-7.svg" alt="genomin-2" />
                <Typography.Text>
                  Genomas cognitivos
                </Typography.Text>
              </div>
              <div>
                <div className='menu-title'>
                  Genomas personalizados
                </div>
                <div className={`menu-item ${isItemActive(2)}`} onClick={() => setActiveItem(2)}>
                  <img src="https://genoma-assets.s3.us-east-2.amazonaws.com/genomin-3.svg" alt="genomin-2" />
                  <Typography.Text>
                    Genomas expertos
                  </Typography.Text>
                </div>
                <div className={`menu-item ${isItemActive(3)}`} onClick={() => setActiveItem(3)}>
                  <img src="https://genoma-assets.s3.us-east-2.amazonaws.com/genomin-8.svg" alt="genomin-2" />
                  <Typography.Text>
                    Genomas por clonación
                  </Typography.Text>
                </div>
              </div>
            </div>
            <Popconfirm
              title='Al crear un proceso desde cero, los juegos que agregues no tendrán exigencias ni pesos, por los que no se les calculará un puntaje de FIT a los candidatos al momento de completar sus actividades. ¿Estás seguro que quieres continuar?'
              cancelText="Cancelar"
              okText="Confirmar"
              onConfirm={() => props.dispatch(push('/processes/new'))}
              overlayStyle={{ width: 450 }}
            >
              <div className='menu-item'>
                <Icon type='edit' style={{ marginRight: 5 }} />
                <Typography.Text>
                  Crear un proceso desde cero
                </Typography.Text>
              </div>
            </Popconfirm>
          </div>
        </Col>

        <Col className='new-process-right-menu' sm={18}>
          <div style={{ padding: 20 }}>
            <GenomeInfo infoIndex={activeItem} />
          </div>
          <div style={{ padding: '0px 20px' }}>
            <Row type='flex' gutter={20}>
              <Col>
                <Input
                  placeholder='Buscar genoma'
                  suffix={<Icon type='search' onClick={searchGenome} />}
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </Col>
              {
                activeItem == 0 &&
                <Col>
                  <Popover
                    content={smartGenomeSearchContent()}
                    visible={smartSearchPopoverOpen}
                    placement="bottomLeft"
                    title='Recomendación inteligente'
                    trigger='click'
                    style={{ width: 350 }}>
                    <div
                      onClick={() => setSmartSearchPopoverOpen(!smartSearchPopoverOpen)}
                      style={{ backgroundColor: smartSearchActive ? '#F9F0FF' : '#FFFFFF', cursor: 'pointer', border: '1px solid #9254DE', borderRadius: 2, width: 32, height: 32, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      {
                        <img style={{ height: 16 }} src='https://genoma-assets.s3.us-east-2.amazonaws.com/magic-wand.svg' alt="genomin-6" />
                      }
                    </div>
                  </Popover>
                </Col>
              }
            </Row>
          </div>
          <div style={{ padding: 20 }}>
            <Skeleton loading={loading} active={true} />
            <Row gutter={20}>
              {
                loading == false && getTotalGenomes() == 0 ?
                  <Row type='flex' justify='center' align='middle' style={{ marginTop: 50 }}>
                    <img src='https://genoma-assets.s3.us-east-2.amazonaws.com/lock-bg.svg' alt="lock-bg" style={{ width: '50%' }} />
                    <div>
                      <Typography.Text strong>
                        Este Genoma tiene un costo adicional
                      </Typography.Text>
                      <Typography.Text>
                        , si quieres utilizarlo contacta a tu encargado/a de cuenta.
                      </Typography.Text>
                    </div>
                  </Row>
                  :

                  loading == false &&
                  (
                    filteredData.length != 0 ?
                      filteredData.map((item, idx) =>
                        <Col key={`gen-card__col_${idx}`} span={12} style={{ marginBottom: 20, minHeight: 200 }}>
                          <GenomeCard data={item} duplicateGenome={handleDuplicate} smartSearchActive={smartSearchActive} />
                        </Col>
                      ) :
                      <NotFoundGenome />
                  )
              }
            </Row>

          </div>
        </Col>
      </Row>

    </Modal>
  )
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    data: state.data.data,
    userName: state.auth.userName,
    isFetching: state.data.isFetching,
    error: state.data.error,
    alerts: state.alerts,
    logoURL: state.auth.logoURL,
    business: state.auth.currentBusiness
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    authActions: bindActionCreators(authActionCreators, dispatch),
    traitActions: bindActionCreators(traitActionsCreators, dispatch),
    dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewProcessModal);