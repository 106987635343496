import fetch from 'isomorphic-fetch';
import { push } from 'react-router-redux';
import { alertActions } from './alerts';
import * as traitActions from './trait';
import { SERVER_URL } from '../utils/config';
import {
    JOBAPP_FETCH_BEGIN,
    JOBAPP_FETCH_SUCCESS,
    JOBAPP_UPDATE,
    JOBAPP_FETCH_FAILURE,
    ACTIVITY_FETCH_BEGIN,
    ACTIVITY_FETCH_SUCCESS,
    ACTIVITY_FETCH_FAILURE,
    ACTIVITY_ADD,
    ACTIVITY_REMOVE,
    ACTIVITY_ORIGINAL_REMOVE,
    ACTIVITY_ADD_QUESTION,
    ACTIVITY_REMOVE_QUESTION,
    ACTIVITY_SWITCH_QUESTION,
    ACTIVITY_UPDATE_QUESTION,
    ACTIVITY_START_EDIT_QUESTION,
    SAVE_STARTING,
    SAVE_IDEAL_GENOME,
    SAVE_IDEAL_GENOME_SUCCESS,
    UPDATE_JOB_APPLICATION,
    SAVE_NEW_MEASURED_TRAITS,
    UPDATE_MEASURED_TRAITS,
    DELETE_MEASURED_TRAITS,
    SAVE_NEW_ASSIGNED_ACTIVITIES,
    UPDATE_ASSIGNED_ACTIVITIES,
    DELETE_ASSIGNED_ACTIVITIES,
    POSITION_SEND_SUCCESS,
    POSITION_SEND_FAILURE,
    ASSIGNED_ACTIVITY_FETCH_BEGIN,
    ASSIGNED_ACTIVITY_RECEIVE_SUCCESS,
    ASSIGNED_ACTIVITY_RECEIVE_FAILURE,
} from '../constants';

export const fetchJobApplicationBegin = (jobappid) => {
    return {
        type: JOBAPP_FETCH_BEGIN,
        payload: { jobAppId: jobappid }
    };
};

export const fetchJobApplicationSuccess = (data) => {
    return {
        type: JOBAPP_FETCH_SUCCESS,
        payload: { jobApplication: data }
    };
};

export const updateJobApplication = (field, data) => {
    return {
        type: JOBAPP_UPDATE,
        payload: { [field]: data }
    };
};

export const fetchJobApplicationFailure = (error) => {
    return {
        type: JOBAPP_FETCH_FAILURE,
        payload: { error }
    };
};

export const fetchAssignedActivityBegin = (jobappid) => {
    return {
        type: ASSIGNED_ACTIVITY_FETCH_BEGIN,
        payload: { jobappid }
    };
};

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

export const fetchAssignedActivitySuccess = (data) => {
    const assignedActivities = {};
    data.results.forEach((aa) => {
        if (aa.code !== 'CUTE'){
            assignedActivities[aa.code] = { ...aa, configuration: JSON.parse(aa.configuration), original: true };
        }
        else if (aa.code === 'CUTE'){
            const prevCuTes = assignedActivities['CUTE'] ? assignedActivities['CUTE'] : []
            assignedActivities[aa.code] = [ ...prevCuTes, { ...aa, configuration: JSON.parse(aa.configuration), original: true }];
        }

    });
    let index = 0;
    if (assignedActivities.KO) {
        index = Math.max(...Object.keys(assignedActivities.KO.configuration.questions));
    }
    return {
        type: ASSIGNED_ACTIVITY_RECEIVE_SUCCESS,
        payload: { assignedActivities, index }
    };
};

export const fetchAssignedActivityFailure = (error) => {
    return {
        type: ASSIGNED_ACTIVITY_RECEIVE_FAILURE,
        payload: { error }
    };
};

export const fetchAssignedActivity = (token, jobappid) => {
    return (dispatch) => {
        dispatch(fetchAssignedActivityBegin(jobappid));
        const rawData = JSON.stringify({ jobappid });
        return fetch(`${SERVER_URL}/api/v1/gamesandtests/assignedactivity/`, {
            method: 'GET',
            headers: {
                'Raw-Data': `${rawData}`,
                'Accept': 'application/json',
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json',
            }
        })
            .then(handleErrors)
            .then((res) => { return res.json(); })
            .then((json) => {
                dispatch(fetchAssignedActivitySuccess(json));
            })
            .catch((error) => {
                dispatch(fetchAssignedActivityFailure(error));
                return Promise.resolve();
            });
    };
};

export function fetchIntegrationsSuccess(json) {
    const  integrations  = json;
    return {
        type: 'INTEGRATIONS_RECEIVE_SUCCESS',
        payload:  integrations 
    };
}

export function fetchIntegrations(pk, token) {
     return (dispatch) => {
        return fetch(`${SERVER_URL}/api/v2/business/${pk}/integrations/`, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json',
            }
        })
        .then(handleErrors)
        .then((res) => { return res.json(); })
        .then((json) => {
            dispatch(fetchIntegrationsSuccess(json.results));
            return true;
        })
        .catch(() => {
            return Promise.resolve();
        });
     }
}

export function fetchJobApplicationIntegrationsSuccess(json) {
    const  integrations  = json;
    return {
        type: 'JOB_APP_INTEGRATIONS_RECEIVE_SUCCESS',
        payload:  integrations 
    };
}

export function fetchJobApplicationIntegrations(pk, token) {
    return (dispatch) => {
       return fetch(`${SERVER_URL}/api/v2/business/jobapplications/${pk}/integrations/`, {
           method: 'get',
           headers: {
               'Accept': 'application/json',
               'Authorization': `Token ${token}`,
               'Content-Type': 'application/json',
           }
       })
       .then(handleErrors)
       .then((res) => { return res.json(); })
       .then((json) => {
           dispatch(fetchJobApplicationIntegrationsSuccess(json.results));
           return true;
       })
       .catch((error) => {
           dispatch(fetchJobApplicationFailure(error));
           return Promise.resolve();
       });
    }
}

export function fetchAddIntegrationsSuccess(json) {
    const { integrations } = json;
    return {
        type: 'INTEGRATIONS_ADD_SUCCESS',
        payload: { integrations }
    };
}

export function fetchAddIntegrations(token, jobAppId, integration) {
    return (dispatch) => {
       return fetch(`${SERVER_URL}/api/v1/gamesandtests/jobapplication/${jobAppId}/integrations/`, {
           method: 'post',
           headers: {
               'Accept': 'application/json',
               'Authorization': `Token ${token}`,
               'Content-Type': 'application/json',
           },
           body: `${JSON.stringify(integration)}`
       })
       .then(handleErrors)
       .then((res) => { return res.json(); })
       .then((json) => {
           dispatch(fetchAddIntegrationsSuccess(json));
            return json;
       })
       .catch((error) => {
           return Promise.resolve();
       });
    }
}

export function fetchJobApplication(token, jobappid, onlyjobdata=false) {
    return (dispatch) => {
        dispatch(fetchJobApplicationBegin(jobappid));
        return fetch(`${SERVER_URL}/api/v1/gamesandtests/jobapplications/${jobappid}/`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json',
            }
        })
            .then(handleErrors)
            .then((res) => { return res.json(); })
            .then((json) => {
                dispatch(fetchJobApplicationSuccess(json));
                if (json.genome_id && !onlyjobdata) {
                    dispatch(fetchAssignedActivity(token, json.id));
                    dispatch(traitActions.fetchMeasuredTrait(token, json.genome_id));
                    return json;
                } else {
                    return json;
                }
                return false;
            })
            .catch((error) => {
                dispatch(fetchJobApplicationFailure(error));
                return Promise.resolve();
            });
    };
}

export function fetchJobApplicationFromToken(token, share_token, onlyjobdata=false) {
    return (dispatch) => {
        dispatch(fetchJobApplicationBegin(share_token));
        return fetch(`${SERVER_URL}/api/v1/gamesandtests/shared/job/${share_token}/`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json',
            }
        })
            .then(handleErrors)
            .then((res) => { return res.json(); })
            .then((json) => {
                dispatch(fetchJobApplicationSuccess(json));
                if (json.genome_id && !onlyjobdata) {
                    dispatch(fetchAssignedActivity(token, json.id));
                    dispatch(traitActions.fetchMeasuredTrait(token, json.genome_id));
                } else {
                    return Promise.resolve();
                }
                return false;
            })
            .catch((error) => {
                dispatch(fetchJobApplicationFailure(error));
                return Promise.resolve();
            });
    };
}


export const fetchActivityBegin = () => {
    return {
        type: ACTIVITY_FETCH_BEGIN,
    };
};

export const fetchActivitySuccess = (activities) => {
    return {
        type: ACTIVITY_FETCH_SUCCESS,
        payload: { activities }
    };
};

export const addAssignedActivityAction = (code, data) => {
    return {
        type: ACTIVITY_ADD,
        payload: { data, code }
    };
};

export const addAssignedActivity = (code, data, traits = []) => {
    // TODO hacer fetch de los trait de la actividad
    const measuredTraits = {};
    const nonReasoningStep = [
        '76A100',
        'APRFDB',
        'ACRTTL',
        'ACRSWT',
        'ADBLSP',
        'TMPRES',
        'TMRESW',
        'EMOTOT',
        'EMOCOR',
        'MMTRCE',
        'MULTIV',
        'DRCATC',
        'DCSINC',
        'CAPPLN',
        'CAPATC',
        'CTRIMP',
        'EXTPLN',
    ];
    traits.forEach((t) => {        
        measuredTraits[t.id] = {
            genome_id: null,
            trait_id: t.id,
            score: t.average_score,
            relevance: 0,
            points: 0,
            distribution: t.category === 'RAZONA' || nonReasoningStep.includes(t.code) ? 'STEP' : t.category === 'PERSON' ? 'RANG' : 'NORM',
            category: t.category,
            trait: t
        };
    });
    return (dispatch) => {
        dispatch(addAssignedActivityAction(code, data));
        dispatch(traitActions.fetchMeasuredTraitSuccess(measuredTraits, true));
    };
};

export const removeAssignedActivityAction = (code) => {
    return {
        type: ACTIVITY_REMOVE,
        payload: { code },
    };
};

export const removeOriginalAssignedActivity = (code) => {
    return {
        type: ACTIVITY_ORIGINAL_REMOVE,
        payload: { code },
    };
};

export const removeAssignedActivity = (code, original, traits = [], mIds = []) => {
    // eliminar los traits de la actividad
    return (dispatch) => {
        dispatch(removeAssignedActivityAction(code));
        dispatch(traitActions.removeMeasuredTrait(traits.map((t) => { return t.id; })));
        if (original) {
            dispatch(removeOriginalAssignedActivity(code));
            dispatch(traitActions.removeOriginalMeasuredTrait(mIds));
        }
    };
};

export const addQuestionKo = (question) => {
    return {
        type: ACTIVITY_ADD_QUESTION,
        payload: { question }
    };
};

export const removeQuestionKo = (index) => {
    return {
        type: ACTIVITY_REMOVE_QUESTION,
        payload: { index }
    };
};

export const switchQuestionKo = (index, direction) => {
    return {
        type: ACTIVITY_SWITCH_QUESTION,
        payload: { index, direction }
    };
};

export const updateQuestionKo = (data) => {
    // Hay que llamar a la action startEditQuestion con el indice de la pregunta
    // que se va a editar antes de llamar a esta action
    return {
        type: ACTIVITY_UPDATE_QUESTION,
        payload: { data }
    };
};

export const startEditQuestion = (index) => {
    return {
        type: ACTIVITY_START_EDIT_QUESTION,
        payload: { index }
    };
};

export const fetchActivityFailure = (error) => {
    return {
        type: ACTIVITY_FETCH_FAILURE,
        payload: { error }
    };
};

export function fetchActivity(token, id, currentBusiness) {
    return (dispatch) => {
        dispatch(fetchActivityBegin());
        return fetch(`${SERVER_URL}/api/v1/gamesandtests/business/${currentBusiness}/activities/`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json',
            }
        })
            .then(handleErrors)
            .then((res) => { return res.json(); })
            .then((json) => {
                // agregar la personal form
                const dp = json.results.find((act) => { return act.code === 'FORM'; });
                dispatch(
                    addAssignedActivity(
                        'FORM',
                        {
                            activity: dp.activities,
                            job_application_id: id,
                            activity_id: dp.id,
                            order: 1,
                            stage: 1,
                            time_estimated: dp.time_estimated,
                            icon: 'FORM',
                            height: 1,
                            configuration: {}
                        }
                    )
                );
                dispatch(fetchActivitySuccess(json));
                const codeList = JSON.stringify({ activity_codes: json.results.map((act) => { return act.code; }) });
                dispatch(traitActions.fetchTrait(token, codeList));
                return json;
            })
            .catch((error) => {
                dispatch(fetchActivityFailure(error));
                return Promise.resolve();
            });
    };
}

export const startSaving = () => {
    return {
        type: SAVE_STARTING,
    };
};

export const saveIdealGenome = (data) => {
    return {
        type: SAVE_IDEAL_GENOME,
        payload: { data },
    };
};

export const saveIdealGenomeSuccess = (genome) => {
    return {
        type: SAVE_IDEAL_GENOME_SUCCESS,
        payload: { genome }
    };
};

export const updateJobApplicationBegin = (data) => {
    return {
        type: UPDATE_JOB_APPLICATION,
        payload: { data },
    };
};

export const saveNewMeasuredTraits = (data) => {
    return {
        type: SAVE_NEW_MEASURED_TRAITS,
        payload: { data }
    };
};

export const updateMeasuredTraits = (data) => {
    return {
        type: UPDATE_MEASURED_TRAITS,
        payload: { data },
    };
};

export const deleteMeasuredTraits = (data) => {
    return {
        type: DELETE_MEASURED_TRAITS,
        payload: { data },
    };
};

export const saveNewAssignedActivities = (data) => {
    return {
        type: SAVE_NEW_ASSIGNED_ACTIVITIES,
        payload: { data },
    };
};

export const updateAssignedActivities = (data) => {
    return {
        type: UPDATE_ASSIGNED_ACTIVITIES,
        payload: { data },
    };
};

export const deleteAssignedActivities = (data) => {
    return {
        type: DELETE_ASSIGNED_ACTIVITIES,
        payload: { data },
    };
};

export const savePositionSuccess = (data) => {
    return {
        type: POSITION_SEND_SUCCESS,
        payload: { data }
    };
};

export const savePositionFailure = (error) => {
    return {
        type: POSITION_SEND_FAILURE,
        payload: { error }
    };
};

export function createGenomeId(idealGenome, token) {
    return (dispatch) => {
        dispatch(saveIdealGenome(idealGenome));
        return fetch(`${SERVER_URL}/api/v1/genomes/genome/`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json',
            },
            body: `${JSON.stringify(idealGenome)}`
        })
            .then(handleErrors)
            .then((res) => { return res.json(); })
            .then((genome) => {
                dispatch(saveIdealGenomeSuccess(genome));
                return Promise.resolve();
            });
    };
}

export function savePositionAndRedirect(
    token,
    jobappid,
    step1Description,
    newAssignedActivities,
    removedAssignedActivities,
    updatedAssignedActivities,
    newMeasuredTraits,
    removedMeasuredTraits,
    updatedMeasuredTraits,
    shouldRedirect=true,
    newIntegrations
) {
    return (dispatch) => {
        const jobAppData = { ...step1Description };

        dispatch(updateJobApplicationBegin(jobAppData));

        return fetch(`${SERVER_URL}/api/v1/gamesandtests/jobapplications/${jobappid}/`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json',
            },
            body: `${JSON.stringify(jobAppData)}`
        })
        .then(handleErrors)
        .then((res) => { return res.json(); })
        .then((json1) => {
            const measuredTraits = newMeasuredTraits.map((mt) => {
                return { ...mt, genome_id: json1.genome_id };
            });
            // TODO generar este arreglo a partir de: newAssignedActivities, removedAssignedActivities,
            // updatedAssignedActivities, newMeasuredTraits, removedMeasuredTraits, updatedMeasuredTraits
            // si uno de estos arreglo viene vacio no meterlos al arreglo!
            if (measuredTraits && measuredTraits.length) {
                dispatch(saveNewMeasuredTraits(measuredTraits));
            }
            if (removedMeasuredTraits && removedMeasuredTraits.length) {
                dispatch(deleteMeasuredTraits(removedMeasuredTraits));
            }
            if (updatedMeasuredTraits && updatedMeasuredTraits.length) {
                dispatch(updateMeasuredTraits(updatedMeasuredTraits));
            }
            if (newAssignedActivities && newAssignedActivities.length) {
                dispatch(saveNewAssignedActivities(newAssignedActivities));
            }
            if (updatedAssignedActivities && updatedAssignedActivities.length) {
                dispatch(updateAssignedActivities(updatedAssignedActivities));
            }
            if (removedAssignedActivities && removedAssignedActivities.length) {
                dispatch(deleteAssignedActivities({
                    activity_ids: removedAssignedActivities,
                    job_application_id: jobappid
                }));
            }
            const promiseMeasuredTraits = (measuredTraits && measuredTraits.length) ?
                fetch(`${SERVER_URL}/api/v1/genomes/measuredtraits/`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Token ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: `${JSON.stringify(measuredTraits)}`
                }).then(handleErrors) : Promise.resolve();
            const promiseRemovedMeasuredTraits = (removedMeasuredTraits && removedMeasuredTraits.length) ?
                fetch(`${SERVER_URL}/api/v1/genomes/bulkmeasuredtraits/`, {
                    method: 'delete', 
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Token ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: `${JSON.stringify(removedMeasuredTraits)}`
                }).then(handleErrors) : Promise.resolve();
            const promiseUpdatedAssignedActivities =
                (updatedAssignedActivities && updatedAssignedActivities.length) ?
                    fetch(`${SERVER_URL}/api/v1/gamesandtests/bulkassignedactivity/`, {
                        method: 'PUT',
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': `Token ${token}`,
                            'Content-Type': 'application/json',
                        },
                        body: `${JSON.stringify(updatedAssignedActivities)}`
                    }).then(handleErrors) : Promise.resolve();
            const promiseUpdatedMeasuredTraits =
                (updatedMeasuredTraits && updatedMeasuredTraits.length) ?
                    fetch(`${SERVER_URL}/api/v1/genomes/bulkmeasuredtraits/`, {
                        method: 'PUT',
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': `Token ${token}`,
                            'Content-Type': 'application/json',
                        },
                        body: `${JSON.stringify(updatedMeasuredTraits)}`
                    }).then(handleErrors) : Promise.resolve();
            const promiseNewAssignedActivities = (newAssignedActivities && newAssignedActivities.length) ?
                fetch(`${SERVER_URL}/api/v1/gamesandtests/assignedactivity/`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Token ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: `${JSON.stringify(newAssignedActivities)}`
                }).then(handleErrors) : Promise.resolve();
            const promiseRemovedAssignedActivities =
                (removedAssignedActivities && removedAssignedActivities.length) ?
                    fetch(`${SERVER_URL}/api/v1/gamesandtests/bulkassignedactivity/`, {
                        method: 'DELETE',
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': `Token ${token}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(
                            {
                                activity_ids: removedAssignedActivities,
                                job_application_id: jobappid
                            }
                        )
                    }).then(handleErrors) : Promise.resolve();
            return Promise.all([
                promiseMeasuredTraits,
                promiseRemovedMeasuredTraits,
                promiseUpdatedMeasuredTraits,
                promiseNewAssignedActivities,
                promiseUpdatedAssignedActivities,
                promiseRemovedAssignedActivities,
            ]);
        })
        .then(() => {
            if (newAssignedActivities && newAssignedActivities.length) {
                dispatch(fetchAssignedActivity(token, jobappid));
            }
            // TODO esto eventualmente debería sacarse porque los genomas, en un mundo ideal, no se deberían editar
            if (
                (updatedMeasuredTraits && updatedMeasuredTraits.length) ||
                (newMeasuredTraits && newMeasuredTraits.length) ||
                (removedMeasuredTraits && removedMeasuredTraits.length)
            ) {
                fetch(`${SERVER_URL}/api/v1/gamesandtests/updatefinalscore/`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Token ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: `${JSON.stringify({ job_application_id: jobappid })}`
                }).then(handleErrors);
            }
            dispatch(savePositionSuccess({ shouldRedirect }));
            dispatch(traitActions.savePositionSuccess());
            if (shouldRedirect){
                dispatch(push(`/processes/${jobappid}`));
            }
            dispatch(alertActions.success('Exito'));
            return Promise.resolve();
        })
        .catch((error) => {
            dispatch(savePositionFailure(error));
            dispatch(alertActions.error('Hubo un error enviando los datos'));
            return Promise.resolve();
        });
    };
}
