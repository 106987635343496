import React from 'react';

const desktopSvg = (color = '#F175A5') => (
  <svg
    width="210"
    height="150"
    viewBox="0 0 210 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="209"
      height="149"
      rx="1.5"
      fill="white"
      stroke="#D9D9D9"
    />
    <rect x="11" y="6" width="7" height="7" fill="#D9D9D9" />
    <path
      d="M0.5 19.5H209.5V148C209.5 148.828 208.828 149.5 208 149.5H2C1.17157 149.5 0.5 148.828 0.5 148V19.5Z"
      fill="#FAFAFA"
      stroke="#D9D9D9"
    />
    <path
      d="M157 20H209V148C209 148.552 208.552 149 208 149H157V20Z"
      fill={color}
    />
    <rect x="105" y="50" width="104" height="68" fill="#BFBFBF" />
    <rect x="10" y="55" width="5" height="5" fill="#D9D9D9" />
    <rect x="10" y="70" width="43" height="5" fill="#D9D9D9" />
    <rect x="10" y="85" width="74" height="5" fill="#D9D9D9" />
    <rect x="10" y="94" width="25" height="5" fill="#D9D9D9" />
    <rect x="10" y="109" width="50" height="5" fill={color} />
  </svg>
);

const mobileSvg = (color = '#F175A5') => (
  <svg
    width="75"
    height="150"
    viewBox="0 0 75 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="74"
      height="149"
      rx="1.5"
      fill="white"
      stroke="#D9D9D9"
    />
    <rect
      x="0.5"
      y="0.5"
      width="74"
      height="149"
      rx="1.5"
      fill="white"
      stroke="#D9D9D9"
    />
    <path
      d="M1 19H74V148C74 148.552 73.5523 149 73 149H2C1.44772 149 1 148.552 1 148V19Z"
      fill="#FAFAFA"
    />
    <rect x="6" y="6" width="7" height="7" fill="#D9D9D9" />
    <rect x="6" y="55" width="13" height="5" fill="#D9D9D9" />
    <rect x="6" y="70" width="25" height="5" fill="#D9D9D9" />
    <rect x="6" y="77" width="63" height="5" fill="#D9D9D9" />
    <rect x="6" y="92" width="25" height="5" fill="#D9D9D9" />
    <rect x="6" y="99" width="63" height="5" fill="#D9D9D9" />
    <rect x="6" y="109" width="63" height="5" fill={color} />
  </svg>
);

const CustomPreview = ({ colors, texts }) => {
  return [desktopSvg, mobileSvg].map((fn, i) => (
    <div className="preview-item">
      {fn(colors?.background)}
      <span style={{ display: 'block', fontSize: 12, color: '#A4A4A4' }}>
        {texts[i]}
      </span>
    </div>
  ));
};

export default CustomPreview;
