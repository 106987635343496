import React from 'react';

const GenominLoader = ({ isLoading, customClass }) => {
  return (
    <>
      {isLoading ? (
        <div className={`vertical-centered-box ${customClass}`} id="loadergif">
          <div className="content">
            <div className="loader-circle"></div>
            <div className="loader-line-mask">
              <div className="loader-line"></div>
            </div>
            <img
              width="70"
              src="https://genoma-assets.s3.us-east-2.amazonaws.com/minigenomin.png"
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default GenominLoader;
