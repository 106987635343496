import './styles/header.scss';
import 'antd/dist/antd.css';
import './styles/main.scss';

import {
  Avatar,
  Col,
  Dropdown,
  Icon,
  Layout,
  Menu,
  Modal,
  Row,
  Select,
} from 'antd';
import React, { useEffect, useState } from 'react';

import GENOMA from './assets/logos/Genoma.png';
import NotificationBell from './containers/Notifications/NotificationNavBar';
import ReactGA from 'react-ga';
import SearchField from './containers/Common/Appbar/SearchField';
import { authLogout } from './actions/auth';
import { browserName } from 'react-device-detect';
import { connect } from 'react-redux';
import i18n from './i18n';
import { isMobile } from 'react-device-detect';
import { push } from 'react-router-redux';
// Importar todo lo necesario para el chat de soporte dentro de toda la app
// Google Analytics


require('./styles/main.scss');
const { SubMenu } = Menu;
const FLAG_URL = 'https://genoma-assets.s3.us-east-2.amazonaws.com/flags/';
const languages = {
  ES: {
    flag: 'ES',
    language: i18n.t('commons__ES'),
  },
  EN: {
    flag: 'US',
    language: i18n.t('commons__EN'),
  },
  PT: {
    flag: 'BR',
    language: i18n.t('commons__PT'),
  },
};

function App(props) {
  const [select, setSelect] = useState(
    i18n.language.split('-')[0].includes('es')
      ? 'ES'
      : i18n.language.split('-')[0].includes('en')
      ? 'GB'
      : i18n.language.split('-')[0].includes('pt')
      ? 'BR'
      : 'ES'
  );
  const onSelect = (code) => {
    setSelect(code);
    changeLanguage(code);
  };
  const [state, setState] = useState({
    logo: GENOMA,
    openDrawer: false,
    TCModalOpen: false,
  });

  const [analyticHash, setAnalyticHash] = useState(null);

  const [userType, setUserType] = useState('');
  const [collapse, setCollapse] = useState(true);
  const drawerItems = {
    personal: [
      {
        title: i18n.t('navbar__my_process'),
        path: '/me',
        icon: 'project',
        code: 'REQ',
      },
      {
        title: i18n.t('navbar__my_jobs'),
        path: '/jobs',
        icon: 'block',
        code: 'REQ',
      },
      {
        title: i18n.t('navbar__my_resources'),
        path: '/resources',
        icon: 'trophy',
        code: 'REQ',
      },
      {
        title: i18n.t('navbar__my_profile'),
        path: '/account/settings',
        icon: 'user',
        code: 'REQ',
      },
    ],
    business: [
      {
        title: 'Procesos',
        path: '/processes',
        icon: 'table',
        code: 'REQ',
      },
      {
        title: 'Evaluaciones',
        path: '/evaluations',
        icon: 'file-done',
        code: 'EVALS',
      },
      {
        title: 'Analíticas',
        path: '/peopleanalytics',
        icon: 'line-chart',
        code: 'REQ',
        submenu: [
          {
            title: 'Resumen',
            path: '/peopleanalytics#overview',
            icon: 'home',
          },
          {
            title: 'Candidatos',
            path: '/peopleanalytics#candidates',
            icon: 'idcard',
          },
          {
            title: 'Contrataciones',
            path: '/peopleanalytics#hiring',
            icon: 'line-chart',
          },
          {
            title: 'Evaluaciones',
            path: '/peopleanalytics#evaluations',
            icon: 'like',
          },
          {
            title: `Diversidad e inclusión`,
            path: '/peopleanalytics#diversity',
            icon: 'team',
          },
          {
            title: `Satisfacción del candidato`,
            path: '/peopleanalytics#satisfaction',
            icon: 'user',
          },
        ],
      },
      {
        title: 'Candidatos',
        path: '/candidates',
        icon: 'team',
        code: 'REQ',
      },
      {
        title: 'Gestión',
        path: '/tools',
        icon: 'tool',
      },
    ],
  };

  useEffect(() => {
    props.dispatch({
      type: 'APP_SET_LEFT_MARGIN',
      payload: collapse ? '65px' : '208px',
    });
  }, [collapse]);

  let customizeTawkInterval;

  function customizeTawkWidget(visible) {
    // Custom tawk CSS
    const updatedTawkBottom = isMobile
      ? '65px'
      : '40px'; /*This is their default style that I want to change*/
    const updatedTawkRight =
      '5px'; /*This is their default style that I want to change*/
    if (document.querySelector("iframe[title='chat widget']") !== null) {
      /*Check if the default style exists then remove it and add my custom style*/
      document.querySelector("iframe[title='chat widget']").style.right =
        updatedTawkRight;
      document.querySelector("iframe[title='chat widget']").style.bottom =
        updatedTawkBottom;

      if (visible) {
        document.querySelector("iframe[title='chat widget']").style.display =
          '';
      } else {
        document.querySelector("iframe[title='chat widget']").style.display =
          'none';
      }

      clearInterval(customizeTawkInterval);
    }
  }

  useEffect(() => {
    const isOnGameView = onGameView(props.location);

    if (props.userType === 'business') {
      customizeTawkInterval = setInterval(
        () => customizeTawkWidget(false),
        200
      );
    } else {
      if (isOnGameView) {
        customizeTawkInterval = setInterval(
          () => customizeTawkWidget(false),
          200
        );
      } else {
        customizeTawkInterval = setInterval(
          () => customizeTawkWidget(true),
          200
        );
      }
    }
  }, [props.location, props.userType]);

  useEffect(() => {
    if (!['es', 'en', 'pt'].includes(i18n.language.split('-')[0])) {
      i18n.changeLanguage('es');
    } else {
      i18n.changeLanguage(i18n.language.split('-')[0]);
    }
  }, []);

  useEffect(() => {
    setUserType(props.userType);
  }, [props.userType]);

  const logout = () => {
    let url = '/';
    if (state.jobappid !== undefined) {
      url = state.jobappid;
    }
    ReactGA.set({ userId: false });
    props.dispatch(authLogout(props.token, url));
  };

  const goToHome = () => {
    if (props.isAuthenticated) {
      const url = props.userType === 'business' ? '/home' : '/';
      props.dispatch(push(url));
    }
  };
  const changeLanguage = (value) => {
    if (value.toLowerCase() == 'gb') {
      value = 'EN';
    } else if (value.toLowerCase() == 'br') {
      value = 'PT';
    }
    i18n.changeLanguage(value.toLowerCase());
    window.location.reload();
  };

  const goToUrl = (url) => {
    props.dispatch(push(url));
  };

  const handleAccount = () => {
    props.dispatch(push('/account/settings'));
  };

  const handleMyAccount = () => {
    props.dispatch(push('/me/profile'));
  };

  const handleConfig = () => {
    props.dispatch(push('/account/configuration'));
  };

  const handlePortal = () => {
    props.dispatch(push('/account/recruitmentportal'));
  };

  const handleTCModalOpen = () => {
    setState({ TCModalOpen: !state.TCModalOpen });
  };
  const handleCollaborators = () =>
    props.dispatch(push('/business/users'));

  const isBusinessUser = props.userType && props.userType === 'business';

  const isPersonalUser = props.userType && props.userType === 'personal';

  const canManageCollaborators =
    isBusinessUser &&
    (props.businessPermissions.business_admin ||
      // Remove after refactor roles
      props.businessPermissions.can_invite);

  const canManageBrand =
    isBusinessUser &&
    (props.businessPermissions.role?.is_super_admin ||
      props.businessPermissions.role?.can_manage_brand);

  const onGameView = (location) => {
    if (!location) {
      return false;
    }
    const pathnameList = location.pathname.split('/');
    return (
      (pathnameList.length >= 4 &&
        pathnameList[3].length === 4 &&
        (pathnameList[3][3] === '2' ||
          ['TPCG', 'ALST', 'ASLP', 'CFIT', 'LEAD'].includes(pathnameList[3]))) ||
      pathnameList[3] === 'KO' ||
      (pathnameList[1] === 'resources' && pathnameList[2] === 'INTT')
    );
  };

  // Iniciar Google Analytics y ver en que página está el usuario
  ReactGA.initialize('UA-135391418-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
  const { isAuthenticated, location } = props;
  const isOnGameView = onGameView(location);
  const isOnRootView = location && location.pathname === '/';
  const root = document.getElementById('tawkchat-container');
  if (isOnGameView) {
    if (root) {
      root.style.display = 'none';
    }
  } else {
    if (root) {
      root.style.display = '';
    }
  }

  const onCollapse = (collapse) => {
    setCollapse(!collapse);
  };

  const { openDrawer } = state;
  const showFooter =
    isAuthenticated &&
    isMobile &&
    // && props.userType === 'personal'
    !isOnGameView &&
    !isOnRootView;

  const showSider =
    isAuthenticated &&
    !isMobile &&
    // props.userType === 'personal' &&
    !isOnGameView &&
    !isOnRootView;

  const showHeader =
    window.location.href.indexOf('sharedprofile') == -1 &&
    isAuthenticated &&
    !isOnGameView &&
    !isOnRootView; // last condition was (!isOnGameView && !isOnRootView)
  const showHeaderOnMobile =
    isAuthenticated && isMobile && !isOnGameView && !isOnRootView;

  // saving url hash to decorate analytics drawer
  useEffect(() => {
    setAnalyticHash(window.location.hash.substring(1));
  }, [window.location.hash]);

  return (
    <>
      <Layout className="app" hasSider style={{ minHeight: '100%' }}>
        {showHeader || showHeaderOnMobile ? (
          <Layout.Header
            className="main-header"
            id="main-header"
            style={{
              // marginLeft: showSider ? collapse ? '80px' : '208px' : '0px',
              transition: 'all 0.2s',
            }}
          >
            <Row
              type="flex"
              align="middle"
              justify="space-between"
              style={{ width: '100%', height: '100%' }}
            >
              <Col
                // Left side
                xs={8}
                md={4}
                style={{ height: '100%' }}
              >
                <Row
                  type="flex"
                  justify="start"
                  align="middle"
                  style={{ padding: '5px 5px', height: '100%' }}
                >
                  <Col
                    onClick={goToHome}
                    style={{ height: '100%', width: '100%' }}
                  >
                    <Row type="flex" align="top" justify="center">
                      <div
                        id="businessLogo"
                        alt="Business Logo"
                        style={{
                          height: 44,
                          width: '100%',
                          backgroundImage: `url(${props.logoURL})`,
                          backgroundSize: 'contain',
                          backgroundRepeat: 'no-repeat',
                          backgroundPositionY: 'center',
                          cursor: 'pointer',
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col
                // Right side
                xs={16}
                md={20}
                style={{ height: '100%' }}
              >
                <Row
                  className="right"
                  type="flex"
                  align="middle"
                  justify="end"
                  style={{ width: '100%', height: '100%' }}
                >
                  {props.userType !== 'business' && showHeaderOnMobile ? (
                    <div style={{ paddingRight: '0.5em' }}>
                      <Select
                        onSelect={onSelect}
                        defaultValue={i18n.language.split('-')[0].toUpperCase()}
                        style={{ fontSize: '0.8em', display: 'flex' }}
                        maxTagCount={'responsive'}
                        getPopupContainer={(trigger) => trigger}
                      >
                        {Object.keys(languages).map((key, index) => (
                          <Select.Option key={index} value={key}>
                            <span
                              className="location-with-flag"
                              style={{ alignItems: 'center' }}
                            >
                              <span className="jl-flag">
                                <div className="menu-select-image">
                                  <img
                                    src={`${FLAG_URL}${languages[key].flag}.svg`}
                                  />
                                </div>
                              </span>{' '}
                              <span className="menu-select-text">
                                {window.innerWidth >= 600
                                  ? languages[key].language
                                  : key}
                              </span>
                            </span>
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  ) : props.userType === 'business' ? (
                    <SearchField type="candidates" />
                  ) : null}

                  {isAuthenticated && (
                    <div style={{ display: 'inline-flex' }}>
                      <NotificationBell />
                      <Dropdown
                        className="avatar-dropdown"
                        placement="bottomRight"
                        getPopupContainer={
                          // Hack to anchor the content to the parent
                          (p) => p
                        }
                        overlay={
                          <Menu className="gw-avatar-dropdown-menu">
                            <Menu.Item>
                              <Row type="flex" justify="center">
                                <span>{props.userName}</span>
                              </Row>
                            </Menu.Item>
                            {props.userType &&
                              props.userType === 'personal' && (
                                <Menu.Item onClick={handleMyAccount}>
                                  <Icon type="user" />
                                  {i18n.t(
                                    'account__drop_down__my__personal_data'
                                  )}
                                </Menu.Item>
                              )}
                            <Menu.Item onClick={handleAccount}>
                              <Icon type="lock" />
                              {props.userType === 'business'
                                ? 'Mi cuenta'
                                : i18n.t('account__drop_down__my__account')}
                            </Menu.Item>
                            {canManageCollaborators && (
                              <Menu.Item
                                id="collaborators-manager"
                                onClick={handleCollaborators}
                              >
                                <Icon type="user" />
                                Usuarios
                              </Menu.Item>
                            )}
                            {canManageBrand && (
                              <Menu.Item onClick={handlePortal}>
                                <Icon type="setting" />
                                Marca empleadora
                              </Menu.Item>
                            )}
                            <Menu.Item onClick={handleTCModalOpen}>
                              <Icon type="file-search" />
                              {i18n.t('tos__terms')}
                            </Menu.Item>
                            <Menu.Item onClick={logout}>
                              <img src="https://i.imgur.com/N46Abie.png" />
                              {props.userType === 'business'
                                ? 'Cerrar sesión'
                                : i18n.t('account__drop_down__log_out')}
                            </Menu.Item>
                          </Menu>
                        }
                        trigger={['click']}
                        overlayClassName="gw-avatar-dropdown"
                      >
                        <div
                          style={{
                            alignItems: 'center',
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'space-between',
                            paddingTop: 2,
                          }}
                          className="ant-dropdown-link"
                          onClick={(e) => e.preventDefault()}
                        >
                          <Avatar
                            style={{ background: '#FFF0F6', color: '#F759AB' }}
                          >
                            {props.userAvatar}
                          </Avatar>
                          <Icon type="caret-down" />
                        </div>
                      </Dropdown>
                    </div>
                  )}
                </Row>
              </Col>
            </Row>
          </Layout.Header>
        ) : null}
        {showSider ? (
          <Layout.Sider
            collapsible
            collapsed={collapse}
            onCollapse={() => onCollapse(collapse)}
            theme="light"
            className="sider-container"
            width={208}
            style={{ position: 'fixed', paddingTop: 54 }}
          >
            <div className="sider-container__links">
              <Menu
                defaultSelectedKeys={[]}
                selectedKeys={[]}
                mode="inline"
                style={{ border: 'none' }}
              >
                {drawerItems[userType]
                  ? drawerItems[userType].map((item, key) =>
                      item.submenu ? (
                        <SubMenu
                          key={`menu-${key}`}
                          title={
                            <span>
                              <Icon type={item.icon} />
                              <span className="menu-button-text">
                                {item.title}
                              </span>
                            </span>
                          }
                        >
                          {item.submenu.map((submenu, subkey) => (
                            <Menu.Item
                              key={`menu-${key}-sub-${subkey}`}
                              onClick={() => props.dispatch(push(submenu.path))}
                              style={
                                analyticHash === submenu.path.split('#')[1]
                                  ? {
                                      color: '#f175a5',
                                      fontWeight: 'bold',
                                    }
                                  : {}
                              }
                            >
                              <Icon type={submenu.icon} />
                              <span className="menu-button-text">
                                {submenu.title}
                              </span>
                            </Menu.Item>
                          ))}
                        </SubMenu>
                      ) : (
                        <Menu.Item
                          key={`menu-${key}`}
                          onClick={() => props.dispatch(push(item.path))}
                        >
                          <Icon type={item.icon} />
                          <span className="menu-button-text">{item.title}</span>
                        </Menu.Item>
                      )
                    )
                  : null}
              </Menu>
            </div>

            <Select
              onSelect={onSelect}
              defaultValue={i18n.language.split('-')[0].toUpperCase()}
              style={{ fontSize: '0.8em', padding: '1rem' }}
              maxTagCount={'responsive'}
              showArrow={false}
              getPopupContainer={(trigger) => trigger}
            >
              {Object.keys(languages).map((key, index) => (
                <Select.Option key={index} value={key}>
                  <img src={`${FLAG_URL}${languages[key].flag}.svg`} />
                  {!collapse ? (
                    <span style={{ padding: '0 6px', flexGrow: 1 }}>
                      {languages[key].language}
                    </span>
                  ) : null}
                </Select.Option>
              ))}
            </Select>
          </Layout.Sider>
        ) : null}
        <Layout.Content
          style={{
            marginTop: showHeader || showHeaderOnMobile ? '55px' : '0px',
            marginBottom: showHeaderOnMobile ? '59px' : '0px',
            marginLeft: showSider ? (collapse ? '80px' : '208px') : '0px',
            transition: 'all 0.2s',
          }}
        >
          {props.children}
        </Layout.Content>
        {showFooter ? (
          <Layout.Footer
            className="footer-main-container"
            style={{ position: 'fixed', bottom: 0, width: '100%' }}
          >
            <Row>
              <Menu
                style={{
                  border: 'none',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: '#FFFFFF',
                  boxShadow: ' 0px -1px 8px rgba(94, 94, 94, 0.1)',
                }}
                mode="horizontal"
                subMenuOpenDelay={0.1}
                className="footer-main-container-menu"
                overflowedIndicator={<Icon type="menu" />}
                getPopupContainer={(p) => {
                  // Hack for showing submenu as a menu item child.
                  return p;
                }}
              >
                {drawerItems[userType]
                  ? drawerItems[userType].map((item, key) => (
                      <Menu.Item
                        key={item.icon}
                        onClick={() => props.dispatch(push(item.path))}
                        style={{ height: '100%', padding: '9px' }}
                      >
                        <Row
                          style={{ height: '2.5rem', padding: '6px' }}
                          align="middle"
                          justify="center"
                        >
                          <Row
                            type="flex"
                            style={{ height: '1rem' }}
                            align="middle"
                            justify="center"
                          >
                            <Icon
                              style={{ fontSize: '16px' }}
                              type={item.icon}
                            />
                          </Row>
                          <Row
                            type="flex"
                            style={{ height: '1rem' }}
                            align="middle"
                            justify="center"
                          >
                            <span className="footer-button-text">
                              {item.title}
                            </span>
                          </Row>
                        </Row>
                      </Menu.Item>
                    ))
                  : null}
              </Menu>
            </Row>
          </Layout.Footer>
        ) : null}
      </Layout>
      <Modal
        title={i18n.t('tos__terms')}
        visible={state.TCModalOpen}
        onOk={handleTCModalOpen}
        onCancel={handleTCModalOpen}
        centered={true}
        className="terms-and-conditions-modal"
        footer={[]}
      >
        <iframe
          credentialless=''
          width="500"
          height="500"
          src="https://docs.google.com/document/d/e/2PACX-1vT4odFJk2URzMvEF3GADBndjXdLqVhPHaGNEdhEvZmieRH00HQiamys_mQECH-17aXTbiUaW2iqSiek/pub?embedded=true"
        ></iframe>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    businessPermissions: state.auth.businessPermissions,
    isAuthenticated: state.auth.isAuthenticated,
    userType: state.auth.userType,
    location: state.routing.location,
    token: state.auth.token,
    userName: state.auth.userName,
    userAvatar: state.auth.userAvatar,
    businessLogo: state.data.data.business_logo,
    logoURL: state.auth.imgUser,
    // TODO: Eliminar si se elimina función showAddOn
    // addOns: state.auth.addOns
  };
};

export default connect(mapStateToProps)(App);
export { App as AppNotConnected };
