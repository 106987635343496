import React from 'react';
import {
  Row,
  Col,
  Typography
} from 'antd';

const { Text } = Typography;

const DescriptionResults = () => {
  return (
    <Row type='flex' style={{ flexDirection: 'column', border: '1px solid #D9D9D9' }}>
      <Row type='flex' style={{ background: '#F6FFED' }} >
        <Col span={6} style={{ borderRight: '1px solid #D9D9D9', padding: '10px' }}>
          <Row type='flex' justify='center' align='middle' style={{ height: '100%' }}>
            <Text style={{ color: '#52C41A' }}>Alto: 84 al 100+</Text>
          </Row>
        </Col>
        <Col span={18} style={{ padding: '10px' }}>
          <Text>
            Un nivel alto significa que la persona tiene una creatividad verbal desarrollada y que es capaz de pensar en una variedad de soluciones originales a la hora de enfrentar un problema abierto, pudiendo generar ideas poco relacionadas entre sí.
          </Text>
        </Col>
      </Row>

      <Row type='flex' style={{ borderTop: '1px solid #D9D9D9', borderBottom: '1px solid #D9D9D9' }}>
        <Col span={6} style={{ borderRight: '1px solid #D9D9D9', padding: '10px' }}>
          <Row type='flex' justify='center' align='middle' style={{ height: '100%' }}>
            <Text style={{ color: '#FAAD14' }}>Promedio: 73 al 83</Text>
          </Row>
        </Col>
        <Col span={18} style={{ padding: '10px' }}>
          <Text>
            Un nivel promedio significa que la persona tiene una creatividad verbal adecuada y que es capaz de pensar en soluciones satisfactorias a la hora de enfrentar un problema abierto, pudiendo generar ideas que presentan algún grado de relación entre sí.
          </Text>
        </Col>
      </Row>

      <Row type='flex' style={{}}>
        <Col span={6} style={{ borderRight: '1px solid #D9D9D9', padding: '10px' }}>
          <Row type='flex' justify='center' align='middle' style={{ height: '100%' }}>
            <Text style={{ color: '#FF4D4F' }}>Bajo: 0 al 72</Text>
          </Row>
        </Col>
        <Col span={18} style={{ padding: '10px' }}>
          <Text>
            Un nivel bajo significa que la persona tiene una creatividad verbal limitada y que es capaz de pensar en soluciones acotadas a la hora de enfrentar un problema abierto, pudiendo generar ideas que presentan un alto grado de relación entre sí.
          </Text>
        </Col>
      </Row>
    </Row>
  );
}

export default DescriptionResults;