import React, { useState } from "react";
import {
  Button,
  Row,
  Col,
  Modal,
  Typography,
} from 'antd';

import ImageField from "./Fields/ImageField";

function StartPage({ buttonColor, startTest, jobappId, backToPath }) {

  const testConfirmModal = () => {
    const infoText = [
      'Una vez presiones el botón continuar comenzarás la actividad.',
      'Ten en cuenta que tendrás solo una oportunidad para responder.'
    ];

    Modal.confirm({
      title: 'Importante',
      content: (
        <>
        {
        infoText.map((elem, idx) =>
            <div key={idx} style={{ margin: 8 }}>
              {`• ${elem}`}
            </div>
          )
        }
        </>
      ),
      maskClosable: true,
      onOk: () => startTest(),
      onCancel: () => { },
      okType: 'primary',
      okText: 'Continuar',
      cancelText: 'Cerrar'
    });
  }


  const Instructions = () => {
    const dataSource = [
      `Tienes 1 oportunidad para continuar con la prueba en caso que tengas problemas de conexión.`,
      `Puedes realizarla desde una computadora o un dispositivo móvil.`,
    ];
    return (
      <>
        <div style={{ textAlign: 'center', margin: 30 }}>
          <Typography.Title level={4}>
            Instrucciones
          </Typography.Title>
        </div>

        {
          dataSource.map((elem, idx) =>
            <div key={idx} style={{ margin: 8 }}>
              {`• ${elem}`}
            </div>
          )
        }

        <div style={{ marginTop: 30 }}>
          <Button className='cute-button-primary' style={{ borderColor: buttonColor, backgroundColor: buttonColor }} onClick={testConfirmModal}>
            Comenzar desafío
          </Button>

          <Button className='cute-button-secondary' onClick={backToPath}>
            Cancelar
          </Button>
        </div>
      </>
    )
  }

  return (
    <Row type='flex' align='middle' justify='center' style={{ minHeight: '90vh' }}>
      <Col xs={22} sm={22} md={14} lg={14}>
        {
          //jobapp.banner_picture ?
          //  <ImageField src={jobapp.banner_picture} /> :
          //  null
        }
        <Instructions />

      </Col>
    </Row >
  );
};

export default StartPage;