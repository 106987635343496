import React from 'react';

import { Modal, Row, Col, Typography, Tag } from 'antd';

const { Text, Title } = Typography;

const MergeCandidatesModal = ({ activeCandidate, passiveCandidate, mergeCandidates, confirmMerge }) => (
  <Modal
    onCancel={() => mergeCandidates()}
    onOk={() => confirmMerge()}
    title="Combinar candidatos"
    visible
  >
    <div
      style={{
        backgroundColor: '#F5F5F5',
        borderRadius: '5px',
        padding: '20px 10px 20px 20px',
      }}
    >
      <Row align="middle" justify="space-around" type="flex">
        <Col>
          <img
            src="https://genoma-assets.s3.us-east-2.amazonaws.com/genomin.svg"
            style={{ height: '50px', transform: 'scaleX(-1)', width: '50px' }}
          />
        </Col>
        <Col span={20}>
          <Text>
            Si se encuentran diferencias entre los datos personales del candidato/a activo y 
            pasivo, los datos del <strong>candidato activo</strong> tendrán prioridad.
          </Text>
        </Col>
      </Row>
    </div>
    <Row gutter={[12, 0]} style={{ marginTop: '1em' }} type="flex">
      <Col span={12}>
        <Row
          gutter={[0, 8]}
          style={{
            border: '1px solid #D9D9D9',
            borderRadius: '2px',
            flexDirection: 'column',
            height: '30vh',
            padding: '1em',
            wordBreak: 'break-word',
          }}
          type="flex"
        >
          <Col>
            <Tag color="green">Candidato activo</Tag>
          </Col>
          <Col>
            <Title level={4} style={{ marginBottom: 0 }}>{activeCandidate?.name}</Title>
          </Col>
          <Col>
            <Text>{activeCandidate.email}</Text>
          </Col>
          <Col>
            <Text><strong>DNI:</strong> {activeCandidate.dni}</Text>
          </Col>
        </Row>
      </Col>
      <Col span={12}>
        <Row
          gutter={[0, 8]}
          style={{
            border: '1px solid #D9D9D9',
            borderRadius: '2px',
            flexDirection: 'column',
            height: '30vh',
            padding: '1em',
            wordBreak: 'break-word',
          }}
          type="flex"
        >
          <Col>
            <Tag color="blue">Candidato pasivo</Tag>
          </Col>
          <Col>
            <Title level={4} style={{ marginBottom: 0 }}>{passiveCandidate.name}</Title>
          </Col>
          <Col>
            <Text>{passiveCandidate.email}</Text>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
)

export default MergeCandidatesModal;
