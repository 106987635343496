import React, { useContext, useState, useEffect } from 'react';
import { Layout, Row, Typography, Button, Col, Tag } from 'antd';

import { customTestContext } from '../../context/CustomTestContext';

import CustomModal from './CustomModal';
import ParseHtml from '../../../../../components/parseHtml';
import SituationalPreview from '../../../../SituationalTests/SituationalPreview';

const { Text } = Typography;

const Step4 = ({ cuteType }) => {
  const {
    configStep1,
    configStep2,
    globalTimeContext,
    questionsContext,
    scoreConfig,
    radioTimeButton,
    correct_answers,
    setCorrectAnswer,
  } = useContext(customTestContext);

  const { questions } = configStep2;
  const cute = {
    ...configStep1,
    questions: questions,
    scores_config: scoreConfig,
  }
  const [showModal, setShowModal] = useState(false);
  const [modalProp, setModalProp] = useState({ affair: '', content: '' });

  const getCorrectAnswers = () => {
    const correctAlternative = [];
    questionsContext.map((page) => {
      page.fields.map((question) => {
        const id = Object.keys(scoreConfig.correct_answers).find(
          (id) => id === question._uid
        );
        question.options?.map((alternative) => {
          if (scoreConfig.correct_answers[id]?.value === alternative.value) {
            correctAlternative.push({
              answer: alternative.label,
              id: question._uid,
            });
          }
        });
        if (
          scoreConfig.correct_answers[id]?.value === '' &&
          id === question._uid
        ) {
          correctAlternative.push({
            answer:
              question.type === 'radio'
                ? 'Alternativa no seleccionada'
                : question.type ==='text' || question.component === 'input' ? '' : 'No hay respuesta correcta',
            id: question._uid,
          });
        }
      });
    });
    setCorrectAnswer(correctAlternative);
  };

  useEffect(() => {
    getCorrectAnswers();
  }, []);

  return (
    <Layout style={{ margin: '0 0 200px 0' }}>
      <Row style={{ border: '1px solid #D9D9D9' }}>
        <Row
          style={{
            padding: '10px 20px',
            width: '100%',
            background: '#F5F5F5',
            borderBottom: '1px solid #D9D9D9',
          }}
        >
          <Text strong style={{ margin: '0' }}>
            Configuración
          </Text>
        </Row>
        <Row>
          <Row type="flex" style={{ padding: '20px' }} gutter={[10, 20]}>
            <Col span={7}>
              <Row type="flex" style={{ flexDirection: 'column' }}>
                <Text strong>Nombre de la prueba para colaboradores</Text>
                <Text>
                  {configStep1.title !== ''
                    ? configStep1.title
                    : 'Campo sin rellenar'}
                </Text>
              </Row>
            </Col>
            <Col span={7}>
              <Row type="flex" style={{ flexDirection: 'column' }}>
                <Text strong>Nombre de la prueba para evaluados</Text>
                <Text>
                  {configStep2.candidateTitle !== ''
                    ? configStep2.candidateTitle
                    : 'Campo sin rellenar'}
                </Text>
              </Row>
            </Col>
            <Col span={5}>
              <Row type="flex" align="top" style={{ flexDirection: 'column' }}>
                <Text strong>Descripción</Text>
                {configStep1.description.length > 0 ? (
                  <Button
                    onClick={() => {
                      setModalProp({
                        affair: 'Descripción',
                        content: configStep1.description,
                      });
                      setShowModal(true);
                    }}
                    style={{ padding: '0' }}
                    type="link"
                  >
                    Ver texto
                  </Button>
                ) : (
                  <Text>Campo sin rellenar</Text>
                )}
              </Row>
            </Col>
            <Col span={5}>
              <Row type="flex" align="top" style={{ flexDirection: 'column' }}>
                <Text strong>Límite de tiempo</Text>
                <Text>
                  {globalTimeContext !== null
                    ? `${globalTimeContext} minutos`
                    : globalTimeContext === null && radioTimeButton
                    ? 'Debes asignar tiempo'
                    : 'Indefinido'}
                </Text>
              </Row>
            </Col>
            <Col span={6}>
              <Row type="flex" align="top" style={{ flexDirection: 'column' }}>
                <Text strong>Dimensiones a evaluar</Text>
                {configStep1.skills.length > 0 ? (
                  <Button
                    onClick={() => {
                      setModalProp({
                        affair: 'Habilidades',
                        content: configStep1.skills,
                      });
                      setShowModal(true);
                    }}
                    style={{ padding: '0' }}
                    type="link"
                  >
                    Ver habilidades
                  </Button>
                ) : (
                  <Text>Campo sin rellenar</Text>
                )}
              </Row>
            </Col>
          </Row>
        </Row>
      </Row>

      <Row style={{ margin: '30px 0 0 0', border: '1px solid #D9D9D9' }}>
        <Row
          style={{
            padding: '10px 20px',
            background: '#F5F5F5',
            borderBottom: '1px solid #D9D9D9',
          }}
        >
          <Text strong>Prueba</Text>
        </Row>
        {cuteType === 'situationals' ? 
          (<Row style={{ padding: '0 30px' }}>
            <SituationalPreview cute={cute} modeModal={false} />
          </Row>) : 
          (<>
            <Row type="flex" style={{ padding: '10px 20px' }}>
              <Col span={8}>
                <Text strong>Dimensión</Text>
              </Col>
              <Col span={8}>
                <Text strong>Pregunta</Text>
              </Col>
              <Col span={8}>
                <Text strong>Respuesta correcta</Text>
              </Col>
            </Row>
            <Row style={{ padding: '10px 20px' }}>
              {questions.data.slice(1).map((page, i) =>
                page.fields.map((question, i) => (
                  <Row type="flex" key={i}>
                    <Col span={8}>
                      {question.category !== '' ? (
                        <Tag
                          style={{
                            padding: '1px 10px',
                            border: '1px solid #597EF7',
                            margin: '0 0 15px 0',
                          }}
                        >
                          {question.category}
                        </Tag>
                      ) : (
                        <Tag
                          style={{
                            padding: '1px 10px',
                            border: '1px solid #597EF7',
                            margin: '0 0 15px 0',
                          }}
                        >
                          Sin dimensión
                        </Tag>
                      )}
                    </Col>
                    <Col span={8}>
                      {question.label !== '' ? (
                        ParseHtml(question.label)
                      ) : (
                        <Text>Sin descripción</Text>
                      )}
                    </Col>
                    <Col span={8}>
                      {correct_answers.map((answer, id) =>
                        answer.id === question._uid ? (
                          <Text key={id}>
                            {answer.answer
                              ? answer.answer
                              : 'Alternativa no selecciona'}
                          </Text>
                        ) : null
                      )}
                    </Col>
                  </Row>
                ))
              )}
            </Row>
          </>) }
       
      </Row>

      <CustomModal
        affair={modalProp.affair}
        content={modalProp.content}
        showModal={showModal}
        setShowModal={setShowModal}
        description={configStep1.description}
      />
    </Layout>
  );
};

export default Step4;
