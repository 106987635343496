import React from "react";
import TraitSlider from "./TraitSlider/TraitSlider";
import TraitRelevance from './TraitRelevance/TraitRelevance';

import { Row, Col } from 'antd';

const TraitWeight = props => {
    const { trait_id, trait } = props;
    return (
        <Row type="flex">
            <Col xs={20} sm={20}>
                <TraitSlider showDistribution={props.showDistribution} trait={trait} trait_id={trait_id} />
            </Col>
            <Col xs={4} sm={4} style={{ display: 'flex', justifyContent: 'end' }}>
                <TraitRelevance trait_id={trait_id} />
            </Col>
        </Row>
    );
}

export default TraitWeight;
