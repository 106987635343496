import * as actionCreators from '../../../../actions/data';

import React, { createContext, useContext, useEffect, useState } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { message } from 'antd';
import { push } from 'react-router-redux';
import { v4 as uuidv4 } from 'uuid';

const API_URL = process.env.REACT_APP_SERVER_URL;

export const InterviewsContext = createContext({
  interviews: [],
  isLoading: false,
  getInterviewProcesses: () => {},
  getInterviewProcess: () => {},
  createInterviewProcess: () => {},
  updateInterviewProcess: () => {},
  deleteInterviewProcess: () => {},
  getCandidateInterviewProcess: () => {},
  createCandidateInterviewProcess: () => {},
  updateCandidateInterviewProcess: () => {},
  deleteCandidateInterviewProcess: () => {},
  goTo: () => {},
  goBack: () => {},
  goToInterview: () => {},
  goToInterviews: () => {},
  goToCreateInterview: () => {},
  goToCandidateInterview: () => {},
  uploadToS3: async () => {},
});

function InterviewsContextProviderNotConnected({
  children,
  actions,
  dispatch,
  auth,
}) {
  const [interviews, setInterviews] = useState([]);
  const [skills, setSkills] = useState([]);
  const [reports, setReports] = useState([]);
  const [questionnaires, setQuestionnaires] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [evaluations, setEvaluations] = useState([]);

  useEffect(() => {
    getInterviewProcesses();
    getSkills();
    getReports();
    getUsers();
    getQuestionnaires();
  }, []);

  const getQuestionnaires = async () => {
    const path = '/api/v1/evaluations/interviewquestionnaires/';
    const response = await actions.fetchAndReturn(auth.token, path);
    setQuestionnaires(response.body.results);
  };

  const getSkills = async () => {
    const path = '/api/v1/evaluations/skills/';
    const response = await actions.fetchAndReturn(auth.token, path);
    setSkills(response.body.results);
    return response.body.results;
  };

  const getUsers = async () => {
    const url = `/api/v1/accounts/business/${auth.business.id}/businessusers/`;
    const response = await actions.fetchAndReturn(auth.token, url);
    setUsers(response.body.results);
    return response.body.results;
  };

  const getReports = async () => {
    const path = '/api/v1/evaluations/reports/';
    const response = await actions.fetchAndReturn(auth.token, path);
    setReports(response.body.results);
    return response.body.results;
  };

  const getReport = async (id) => {
    const path = `/api/v1/evaluations/reports/${id}/`;
    const response = await actions.fetchAndReturn(auth.token, path);
    return response.body;
  };

  const getInterviewProcesses = () => {
    const path = '/api/v1/evaluations/interviewprocesses/';
    actions
      .fetchAndReturn(auth.token, path)
      .then((res) => {
        // set the total candidates
        res.body.results.forEach((interviewProcess) => {
          interviewProcess.totalCandidates =
            interviewProcess.candidateinterviews.length;
        });
        setInterviews(res.body.results);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getInterviewProcess = async (id) => {
    const path = `/api/v1/evaluations/interviewprocesses/${id}/`;
    return await actions.fetchAndReturn(auth.token, path).then((res) => {
      res.body.totalCandidates = res.body.candidateinterviews.length;
      return res.body;
    });
  };

  const createInterviewProcess = async (data) => {
    const path = `/api/v1/evaluations/interviewprocesses/`;
    const body = structuredClone(data);
    delete body.CandidateInterviews;
    delete body.candidateinterviews;
    delete body.business_user;
    delete body.business;
    return await actions
      .fetchAndReturn(auth.token, path, 'POST', body)
      .then((res) => {
        setInterviews((interviews) => [...interviews, res.body]);
        return res.body;
      });
  };

  const deleteInterviewProcess = async (id) => {
    const path = `/api/v1/evaluations/interviewprocesses/${id}/`;
    return await actions
      .fetchAndReturn(auth.token, path, 'DELETE')
      .then((res) => {
        return res.body;
      });
  };

  const updateInterviewProcess = async (id, data) => {
    const path = `/api/v1/evaluations/interviewprocesses/${id}/`;
    const body = structuredClone(data);
    delete body.CandidateInterviews;
    delete body.candidateinterviews;
    delete body.business_user;
    delete body.business;
    return await actions
      .fetchAndReturn(auth.token, path, 'PATCH', body)
      .then((res) => {
        // updating interview in state
        setInterviews((interviews) =>
          interviews.map((i) => (i.id === res.body.id ? res.body : i))
        );
        return res.body;
      });
  };

  const getCandidateInterviewProcess = async (cid) => {
    const path = `/api/v1/evaluations/candidateinterviewprocesses/${cid}/`;
    return await actions.fetchAndReturn(auth.token, path).then((res) => {
      return res.body;
    });
  };

  const createCandidateInterviewProcess = async (data) => {
    const path = `/api/v1/evaluations/candidateinterviewprocesses/`;
    return await actions
      .fetchAndReturn(auth.token, path, 'POST', data)
      .then((res) => {
        return res.body;
      });
  };

  const updateCandidateInterviewProcess = async (cid, data) => {
    const path = `/api/v1/evaluations/candidateinterviewprocesses/${cid}/`;
    return await actions
      .fetchAndReturn(auth.token, path, 'PATCH', data)
      .then((res) => {
        return res.body;
      });
  };

  const deleteCandidateInterviewProcess = async (cid) => {
    const path = `/api/v1/evaluations/candidateinterviewprocesses/${cid}/`;
    return await actions
      .fetchAndReturn(auth.token, path, 'DELETE')
      .then((res) => {
        return res.body;
      });
  };

  const uploadToS3 = async (cid, file) => {
    const url = `${API_URL}/api/v1/evaluations/candidateinterviewprocesses/${cid}/audio/?type=audio/mp3`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token ' + auth.token,
      },
    });
    const body = await response.json();
    const s3Url = body.url;
    const response2 = await fetch(s3Url, {
      method: 'PUT',
      headers: {
        // 'Content-Type': 'audio/mp3',
      },
      body: file,
    });
    return s3Url.split('?')[0];
  };

  // evals fns
  const getCandidateInterviewEvaluations = async (candidateInterviewId) => {
    const url = `/api/v1/evaluations/candidateinterviewprocesses/${candidateInterviewId}/evaluations/`;

    const response = await actions.fetchAndReturn(auth.token, url);
    setEvaluations(response.body);
  };

  const evaluateSkill = async (
    skill,
    evaluation,
    candidateInterviewId,
    score
  ) => {
    try {
      const url = `/api/v1/evaluations/candidateinterviewprocesses/${candidateInterviewId}/evaluations/`;
      let body = {
        scores: {},
      };

      if (evaluation) {
        body = { scores: { ...evaluation.scores } };
      }

      if (skill.parentSkill) {
        if (!body.scores[skill.parentSkill.id]) {
          body.scores[skill.parentSkill.id] = {};
        }

        body.scores[skill.parentSkill.id][skill.key] = score;
      } else {
        body.scores[skill.key] = score;
      }

      const response = await actions.fetchAndReturn(auth.token, url, 'PUT', {
        ...body,
        candidate_interview_process: candidateInterviewId,
      });

      setEvaluations((evaluations) => {
        if (evaluation.id) {
          return evaluations.map((e) => {
            if (e.business_user.id === response.body.business_user.id) {
              return response.body;
            }
            return e;
          });
        } else {
          return [...evaluations, response.body];
        }
      });

      message.success('Competencia evaluada con éxito.');
    } catch (e) {
      message.error('Error al evaluar la competencia.');
    }
  };

  const evaluateRecommendationScore = async (
    evaluation,
    candidateInterviewId,
    value
  ) => {
    try {
      const url = `/api/v1/evaluations/candidateinterviewprocesses/${candidateInterviewId}/evaluations/`;

      const response = await actions.fetchAndReturn(auth.token, url, 'PUT', {
        recommendation_score: value,
        candidate_interview_process: candidateInterviewId,
      });

      setEvaluations((evaluations) => {
        if (evaluation) {
          return evaluations.map((e) => {
            if (e.business_user.id === response.body.business_user.id) {
              return response.body;
            }
            return e;
          });
        } else {
          return [...evaluations, response.body];
        }
      });

      message.success('Recomendación general evaluada con éxito.');
    } catch (e) {
      message.error('Error al cambiar la recomendación.');
    }
  };

  const saveAnnotations = async (candidateInterviewId, annotations) => {
    try {
      const url = `/api/v1/evaluations/candidateinterviewprocesses/${candidateInterviewId}/evaluations/`;

      // we could reflect this in the context state but it's not necessary now
      // in others fns we update the state because we use it as dependency in some components
      await actions.fetchAndReturn(auth.token, url, 'PUT', {
        ...annotations,
        candidate_interview_process: candidateInterviewId,
      });

      message.success('Anotaciones guardadas con éxito.');
    } catch (e) {
      message.error('Error al guardar las anotaciones.');
    }
  };

  // skills fns

  const createSkill = async (skill) => {
    const path = `/api/v1/evaluations/skills/`;
    const response = await actions.fetchAndReturn(auth.token, path, 'POST', {
      ...skill,
      sub_skills: skill.sub_skills ?? [],
    });

    setSkills((skills) => [
      ...skills,
      {
        ...response.body,
        sub_skills: response.body.sub_skills ?? [],
      },
    ]);
  };

  const updateSkill = async (skill) => {
    const path = `/api/v1/evaluations/skills/${skill.id}/`;
    const response = await actions.fetchAndReturn(auth.token, path, 'PATCH', {
      ...skill,
      sub_skills: skill.sub_skills ?? [],
    });

    setSkills((skills) =>
      skills.map((s) => {
        if (s.id === skill.id) {
          return response.body;
        }
        return s;
      })
    );
  };

  const deleteSkill = async (id) => {
    try {
      const path = `/api/v1/evaluations/skills/${id}/`;
      await actions.fetchAndReturn(auth.token, path, 'DELETE');
      setSkills((skills) => skills.filter((s) => s.id !== id));
      message.success('Competencia eliminada correctamente.');
    } catch (e) {
      message.error('Error al eliminar la competencia.');
    }
  };

  const deleteSkillAsFavorite = async (id) => {
    try {
      const path = `/api/v1/evaluations/favorites/skills/${id}/`;
      const response = await actions.fetchAndReturn(auth.token, path, 'DELETE');

      if (response.status === 204) {
        message.success('Competencia eliminada de favoritos.');
        getSkills();
      }
    } catch (e) {
      message.error('Error al eliminar la competencia de favoritos.');
    }
  };

  const saveSkillAsFavorite = async (id) => {
    try {
      const path = `/api/v1/evaluations/favorites/skills/${id}/`;
      const response = await actions.fetchAndReturn(auth.token, path, 'POST');

      if (response.status === 201) {
        message.success('Competencia guardada como favorita.');
        getSkills();
      }
    } catch (e) {
      message.error('Error al guardar la competencia como favorita.');
    }
  };

  // reports fns

  const createReport = async (report) => {
    const path = `/api/v1/evaluations/reports/`;
    const response = await actions.fetchAndReturn(
      auth.token,
      path,
      'POST',
      report
    );

    setReports((reports) => [...reports, response.body]);

    return response.body;
  };

  const updateReport = async (report) => {
    const path = `/api/v1/evaluations/reports/${report.id}/`;
    const response = await actions.fetchAndReturn(
      auth.token,
      path,
      'PATCH',
      report
    );

    setReports((reports) =>
      reports.map((r) => {
        if (r.id === report.id) {
          return response.body;
        }
        return r;
      })
    );
  };

  const deleteReport = async (id) => {
    try {
      const path = `/api/v1/evaluations/reports/${id}/`;
      await actions.fetchAndReturn(auth.token, path, 'DELETE');
      setReports((reports) => reports.filter((r) => r.id !== id));
      message.success('Formato de informe eliminado correctamente.');
    } catch (e) {
      message.error('Error al eliminar el formato de informe.');
    }
  };

  const copyReport = async (id, callback) => {
    try {
      const report = reports.find((r) => r.id === id);

      const newReport = {
        ...report,
        id: null,
        name: `${report.name} (Copia)`,
      };

      delete newReport.creator;
      delete newReport.business;

      const createdReport = await createReport(newReport);
      message.success('Formato de informe copiado correctamente.');
      callback(createdReport);
    } catch (e) {
      message.error('Error al copiar el formato de informe.');
    }
  };

  const deleteReportAsFavorite = async (id) => {
    try {
      const path = `/api/v1/evaluations/favorites/reports/${id}/`;
      const response = await actions.fetchAndReturn(auth.token, path, 'DELETE');

      if (response.status === 204) {
        setReports((reports) =>
          reports.map((report) => ({
            ...report,
            favorite: report.id === id ? false : report.favorite,
          }))
        );

        message.success('Reporte eliminado de favoritos.');
      }
    } catch (e) {
      message.success('Error al eliminar el formato de informe de favoritos.');
    }
  };

  const saveReportAsFavorite = async (id) => {
    try {
      const path = `/api/v1/evaluations/favorites/reports/${id}/`;
      const response = await actions.fetchAndReturn(auth.token, path, 'POST');

      if (response.status === 201) {
        setReports((reports) =>
          reports.map((report) => ({
            ...report,
            favorite: report.id === id ? true : report.favorite,
          }))
        );

        message.success('Reporte guardado como favorito.');
      }
    } catch (e) {
      message.success('Error al guardar el formato de informe como favorito.');
    }
  };

  // questions fns
  const createQuestionnaire = async (questionnaire) => {
    const path = `/api/v1/evaluations/interviewquestionnaires/`;
    const response = await actions.fetchAndReturn(
      auth.token,
      path,
      'POST',
      questionnaire
    );

    setQuestionnaires((questionnaires) => [...questionnaires, response.body]);
    return response.body;
  };

  const deleteQuestionnaire = async (id) => {
    try {
      const path = `/api/v1/evaluations/interviewquestionnaires/${id}/`;
      await actions.fetchAndReturn(auth.token, path, 'DELETE');
      setQuestionnaires((questionnaires) =>
        questionnaires.filter((q) => q.id !== id)
      );
      message.success('Cuestionario eliminado con éxito.');
    } catch (e) {
      message.success('Error al eliminar el cuestionario.');
    }
  };

  const updateQuestionnaire = async (questionnaire) => {
    const path = `/api/v1/evaluations/interviewquestionnaires/${questionnaire.id}/`;
    const response = await actions.fetchAndReturn(
      auth.token,
      path,
      'PATCH',
      questionnaire
    );

    setQuestionnaires((questionnaires) =>
      questionnaires.map((q) => {
        if (q.id === questionnaire.id) {
          return response.body;
        }
        return q;
      })
    );
  };

  const copyQuestionnaire = async (id, callback) => {
    try {
      const questionnaire = questionnaires.find((q) => q.id === id);

      const newQuestionnaire = {
        ...questionnaire,
        id: null,
        name: `${questionnaire.name} (Copia)`,
      };

      delete newQuestionnaire.creator;
      delete newQuestionnaire.business;

      const createdQuestionnaire = await createQuestionnaire(newQuestionnaire);
      message.success('Cuestionario copiado correctamente.');
      callback(createdQuestionnaire);
    } catch (e) {
      message.error('Error al copiar el cuestionario.');
    }
  };

  const deleteQuestionnaireAsFavorite = async (id) => {
    try {
      const path = `/api/v1/evaluations/favorites/questionnaires/${id}/`;
      const response = await actions.fetchAndReturn(auth.token, path, 'DELETE');

      if (response.status === 204) {
        setQuestionnaires((questionnaires) =>
          questionnaires.map((q) => ({
            ...q,
            favorite: q.id === id ? false : q.favorite,
          }))
        );

        message.success('Cuestionario eliminado de favoritos.');
      }
    } catch (e) {
      message.error('Error al eliminar el cuestionario de favoritos.');
    }
  };

  const saveQuestionnaireAsFavorite = async (id) => {
    try {
      const path = `/api/v1/evaluations/favorites/questionnaires/${id}/`;
      const response = await actions.fetchAndReturn(auth.token, path, 'POST');

      if (response.status === 201) {
        setQuestionnaires((questionnaires) =>
          questionnaires.map((q) => ({
            ...q,
            favorite: q.id === id ? true : q.favorite,
          }))
        );

        message.success('Cuestionario guardado como favorito.');
      }
    } catch (e) {
      message.error('Error al guardar el cuestionario como favorito.');
    }
  };

  // update reports and skills when changing an interview
  useEffect(() => {
    getReports();
    getSkills();
  }, [interviews]);

  const contextValue = {
    // auth stuff
    userId: auth.userID,
    // Objects
    users,
    interviews,
    skills,
    reports,
    questionnaires,
    // Flags
    isLoading,
    // InterviewProcesses
    getInterviewProcesses,
    getInterviewProcess,
    createInterviewProcess,
    deleteInterviewProcess,
    updateInterviewProcess,
    // CandidateInterviewProcesses
    getCandidateInterviewProcess,
    createCandidateInterviewProcess,
    updateCandidateInterviewProcess,
    deleteCandidateInterviewProcess,
    // Skills
    createSkill,
    updateSkill,
    deleteSkill,
    saveSkillAsFavorite,
    deleteSkillAsFavorite,
    // Reports
    getReport,
    createReport,
    updateReport,
    deleteReport,
    copyReport,
    saveReportAsFavorite,
    deleteReportAsFavorite,
    //Questionnnaires
    createQuestionnaire,
    updateQuestionnaire,
    deleteQuestionnaire,
    saveQuestionnaireAsFavorite,
    deleteQuestionnaireAsFavorite,
    copyQuestionnaire,
    // Evaluations
    evaluations,
    getCandidateInterviewEvaluations,
    evaluateSkill,
    evaluateRecommendationScore,
    saveAnnotations,
    // Navigation
    goToInterview: (idx) => {
      dispatch(push(`/interviews/${idx}`));
    },
    goToInterviews: () => {
      dispatch(push('/interviews'));
    },
    goToCandidateInterview: (cid) => {
      dispatch(push(`/interviews/candidateinterviews/${cid}`));
    },
    goToCreateInterview: () => {
      dispatch(push('/interviews/create'));
    },
    goTo: (path) => {
      dispatch(push(path));
    },
    goBack: () => {
      window.history.back();
    },
    uploadToS3,
  };

  return (
    <InterviewsContext.Provider value={contextValue}>
      {children}
    </InterviewsContext.Provider>
  );
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actionCreators, dispatch),
  dispatch,
});
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export const InterviewsContextProvider = connect(
  mapStateToProps,
  mapDispatchToProps
)(InterviewsContextProviderNotConnected);
