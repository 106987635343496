import React, { useState } from 'react';

import {
  Divider,
  Checkbox,
  Row,
  Typography,
  Modal,
  message,
  Spin,
} from 'antd';

const CheckboxGroup = Checkbox.Group;

const { Text } = Typography;

const defaultCheckedList = ['Rasgos'];
const optionDict = {
  'Preguntas adicionales': 'ko', 
  'Rasgos': 'traits', 
  'Video Entrevista': 'vint',
  'Carrera y experiencia previa': 'careers_or_experiences',
}
const plainOptions = Object.keys(optionDict)


const CustomExportModal = ({ setVisible, visible, jobApplication, token, actions }) => {

  const [state, setState] = useState({
    checkedList: defaultCheckedList,
    indeterminate: true,
    checkAll: false,
  });
  const [loading, setLoading] = useState(false);

  const onChange = checkedList => {
    setState({
      checkedList,
      indeterminate: !!checkedList.length && checkedList.length < plainOptions.length,
      checkAll: checkedList.length === plainOptions.length,
    });
  };

  const onCheckAllChange = e => {
    setState({
      checkedList: e.target.checked ? plainOptions : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  };

  const requestXLS = () => {
    setLoading(true);

    const qs = `?with=${state.checkedList.map(elem => optionDict[elem]).join(',')}`
    const url = `gamesandtests/candidatelist/customexport/${jobApplication.id}/` + qs

    const today = new Date();
    const parsedDate = `${today.getDate()}-${today.getMonth() + 1
      }-${today.getFullYear()}`;
    const filename = `${jobApplication.job_application} ${parsedDate}.xls`;
    actions.fetchFile(token, url, '', 'get', filename)
      .then(() => {
        message.success('Solicitud enviada con exito')
        setLoading(false);
        setVisible(false);
      })
      .catch(() => {
        message.error('Problema al generar archivo')
        setLoading(false);
        setVisible(false);
      })
  }

  return (
    <Modal
      visible={visible}
      title={<Text strong>Exportar proceso</Text>}
      okText="Solicitar XLS"
      cancelText="Cancelar"
      onCancel={() => setVisible(false)}
      onOk={requestXLS}
      width="min(700px, 90%)"
    >
      <Row>
      </Row>
      {loading ?
        <Row type='flex' align='middle' justify='center' style={{ minHeight: 120 }}>
          <Spin spinning={loading} />
        </Row>

        :
        <div>
          <div>
            <Text>
              Si el proceso cuenta con mas de 2500 candidatos, el archivo sera enviado directamente al email.
            </Text>
          </div>
          <Divider/>
          <div>
            <Text strong level={3}>
              Selecciona las columnas a descargar
            </Text>
          </div>
          <div style={{ borderBottom: '1px solid #E9E9E9', marginTop: 20 }}>
            <Checkbox
              indeterminate={state.indeterminate}
              onChange={onCheckAllChange}
              checked={state.checkAll}
            >
              Seleccionar todo
            </Checkbox>
          </div>
          <br />
          <Checkbox
            checked={true}
            disabled={true}
          >
            Datos Personales
          </Checkbox>
          <CheckboxGroup
            options={plainOptions}
            value={state.checkedList}
            onChange={onChange}
          />
        </div>
      }
    </Modal>
  );
};

export default (CustomExportModal);
