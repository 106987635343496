import React from 'react';
import { Button, Typography, Modal, Tooltip, Row, Col, Icon } from 'antd';

import i18n from '../../../i18n';

const { Text } = Typography;

const CustomFieldAnswer = ({
  customFieldValue,
  customFieldObj,
  setSelectedFileId,
  handlePreview,
  customFieldKeyInEditionMode,
  setNewCustomFieldValue,
  setCustomFieldKeyInEditionMode,
  customFields,
  customFieldKey,
  fieldTypes,
  fieldType,
  updateCustomField,
}) => {
  return (
    <Row align="middle" type="flex" justify="space-between">
      <Col span={20}>
        {customFieldValue === true ? (
          i18n.t('commons__yes', { ns: 'candidateProfile' })
        ) : customFieldValue === false ? (
          i18n.t('commons__no', { ns: 'candidateProfile' })
        ) : Array.isArray(customFieldValue) ? (
          customFieldValue.join(', ')
        ) : customFieldValue && customFieldObj?.value_type === 'file' ? (
          <Button
            style={{ padding: 0 }}
            onClick={() => {
              setSelectedFileId(customFieldValue);
              handlePreview.toggle();
            }}
            type="link"
          >
            {i18n.t('profile__view_document', {
              ns: 'candidateProfile',
            })}
          </Button>
        ) : !customFieldValue ? (
          <Text>
            {i18n.t('profile__unanswered', {
              ns: 'candidateProfile',
            })}
          </Text>
        ) : (
          customFieldValue
        )}
      </Col>
      <Col span={3}>
        <Tooltip title={i18n.t('commons__edit')}>
          <Icon
            onClick={() => {
              if (customFieldKeyInEditionMode === undefined) {
                setNewCustomFieldValue(customFields[customFieldKey]);
                setCustomFieldKeyInEditionMode(customFieldKey);
              }
            }}
            type="edit"
            style={{
              cursor: customFieldKeyInEditionMode ? 'not-allowed' : 'pointer',
              marginRight: '5px',
            }}
          />
        </Tooltip>
        {customFieldObj === null ? (
          <Tooltip
            title={i18n.t('profile__delete', {
              ns: 'candidateProfile',
            })}
          >
            <Icon
              onClick={() =>
                Modal.confirm({
                  cancelText: i18n.t('profile__cancel', {
                    ns: 'candidateProfile',
                  }),
                  closable: false,
                  content: (
                    <>
                      ¿Estás seguro que quieres eliminar el atributo
                      <strong>{` ${customFieldKey.replaceAll(
                        '_',
                        ' '
                      )} `}</strong>
                      de {fieldTypes[fieldType][1]}?
                    </>
                  ),
                  okText: i18n.t('profile__delete', {
                    ns: 'candidateProfile',
                  }),
                  onOk: () =>
                    updateCustomField(customFieldKey, null, fieldType, true),
                  title: i18n.t('profile_delete_attribute', {
                    ns: 'candidateProfile',
                  }),
                })
              }
              type="delete"
              style={{ cursor: 'pointer' }}
            />
          </Tooltip>
        ) : null}
      </Col>
    </Row>
  );
};

export default CustomFieldAnswer;
