import React from 'react'
import { push } from 'react-router-redux';
import {
  Modal,
  Row,
  Typography,
  Checkbox
} from 'antd';

const { Text } = Typography;

const AgreementModal = ({ props, setShowModal, setAgreement, agreement, showModal }) => {
  return (
    <Modal
      title='Acuerdo de propiedad intelectual'
      okText='Aceptar'
      cancelText='Cancelar'
      visible={showModal}
      centered
      onCancel={() => setShowModal(false)}
      onOk={() => { agreement ? props.dispatch(push('/customtest/new')) : null }}
    >
      <Row type='flex'>
        <Text style={{ margin: '0 0 30px 0' }}>
          Genomawork cuenta con un módulo de pruebas personalizadas que le permite a los clientes configurar sus propios tests y evaluaciones. Genomawork facilita este servicio en su plataforma, donde cada cliente diseña su propia evaluación.
          El contenido de una prueba de este módulo es administrado por cada cliente, por lo que es de su exclusiva responsabilidad. Cualquier denuncia, multa, demanda, sanción o acción legal interpuesta por los contenidos de una evaluación será de exclusiva responsabilidad del cliente.
          Genomawork declara que la puesta a disposición de la plataforma no infringe ninguna patente, derecho de autor, derecho de imagen, marca comercial, propiedad intelectual o protección de datos personales de otras personas o entidades. La plataforma no constituye un uso no autorizado de un secreto comercial o vulneración de derechos legalmente protegidos de una tercera persona o entidad.
        </Text>
        <Checkbox onChange={() => setAgreement(!agreement)}>
          He leído y acepto los Términos y condiciones
        </Checkbox>
      </Row>
    </Modal>
  );
}

export default AgreementModal;