// TODO: Implement this component as the SignUp form everywhere in the app
import React, { Fragment, useState, useEffect, useRef  } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import * as authActionCreators from '../../actions/auth';
import * as actionCreators from '../../actions/data';

import ReCAPTCHA from 'react-google-recaptcha';

import { validateDNI, countries} from '../../utils/dniValidate';

import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  PageHeader,
  Select,
  Spin,
  Typography,
} from 'antd'
import i18n from '../../i18n';

const { Text } = Typography
const { Content } = Layout
const { Option } = Select


const SignUp = (props) => {
  const [state, setState] = useState({
    email: '',
    // password: '',
    // passwordConfirm: '',
    errors: {
      // email: false,
      // password: false,
      // passwordConfirm: false,
      // passwordLogin: false,
      dni: false,
    },
    // jobappid: '',
    tosDialog: false,
    tokenCaptcha: null,
    backgroundImage: null,
    countries: [{
      country: "Angola",
      translation: {
        en: "Angola",
        es: "Angola",
        pt: "Angola",
      }
    }]
    // title: '',
    // content: '',
    // openModal: false,
  })
  const captchaRef = useRef(null);  
  const { alerts, authErrors, isCreatingAccount, isFetching, socialAuth, socialAuthErrors } = props

  const { getFieldDecorator, getFieldValue } = props.form

  // TODO: análogo a componentDidUpdate()?
  // TODO: análogo a componentWillMount()?

//   const handleModalClose = () => {
//     window.location.href = SHARE_URL
//     setState({
//       ...state,
//       openModal: false,
//     });
//   }

  useEffect (() => {
    getCountries()
  }, [])

  const getCountries = () => {
    const url = '/api/v1/constants/countries/?p=[country,translation]'
    props.actions.fetchAndReturn('', url, 'GET').then((response) => { 
      setState({
        ...state,
        countries: response.body
      })
    })};

  const handleTosDialog = () => {
    let newTosDialogState = !state.tosDialog
    setState({
      ...state,
      tosDialog: newTosDialogState,
    })
  };

  const handleDNIChange = (rule, value, callback) => {
    const country_document = getFieldValue('country_document')
    if (value && !validateDNI(country_document, value)) {
      let errors = { ...state.errors };
      errors.dni = true;
      setState({
        ...state,
        errors: errors,
      });
      callback(i18n.t('start__process__dni__error__gmail'));
    }
    callback();
  };

  const handleChangeCountry = () => {
    let errors = state.errors;
    errors.dni = false;
    setState((prevState) => ({
      ...prevState,
      errors: errors
    }));
    props.form.setFieldsValue({
      dni: ''
    });
  }

  const verifyCallback = (response) => {
    setState({
      ...state,
      tokenCaptcha: response
    })
  };

  const checkFields = () => {
    const { provider, newCandidate } = socialAuth;
    const tos = getFieldValue('terms');
    const country_document = getFieldValue('country_document');
    const dni = getFieldValue('dni');
    const { tokenCaptcha } = state;
    if (!newCandidate) {
      if ( dni !== '' ) {
        return false
      }
      return true
    }
    if (provider) {
      if (
        tokenCaptcha !== null && tos && (dni !== '' || !countries.includes(country_document))
      ) {
        return false
      }
      return true
    }

    // TODO: DESCOMENTAR ESTE BLOQUE CUANDO ESTE COMPONENTE SE HABILITE
    // PARA REGISTRO DE USUARIOS SIN OAUTH
    // else if (
    //   password !== '' && 
    //   tokenCaptcha !== null && 
    //   tos && 
    //   (dni !== '' || !countries.includes(country_document))
    // ) {
    //   return false
    // }
    // return true
  }

  const goBack = () => {
    let redirect;
    if (socialAuth.provider && socialAuth.jobAppId) {
      redirect = `/startprocess/${socialAuth.jobAppId}`
    } else {
      // TODO: Esto debería cambiar cuando este componente de SignUp se habilite en casos distintos a iniciar sesión a través de un proceso
      redirect = "/login"
    }
    setState({
      email: '',
      errors: {
        dni: false,
      },
      tosDialog: false,
      tokenCaptcha: null,
      backgroundImage: null,
    })
    props.authActions.authGoBack();
    props.dispatch(push(redirect))
  };

  const extractRedirect = (string) => {
    const match = string.match(/next=(.*)/);
    return match ? match[1] : '/';
  };
  
  const submitSignupData = e => {
    e.preventDefault();

    props.form.validateFields((err, values) => {
      let myDNI = ''
      myDNI = getFieldValue('dni').toLowerCase().replace(/[^\w\s]/gi, '');
      if (!state.errors.dni || !err) {
        let redirect;
        // const email = socialAuth.provider ? socialAuth.email : state.email.toLowerCase()
        // TODO: Get password from getFieldValue eventually
        const password = socialAuth.provider ? '' : state.password
        const country_document = socialAuth.provider ? '' : getFieldValue('country_document')
        if (socialAuth.provider) {
          redirect = socialAuth.jobAppId ? '/jobapplication/' + socialAuth.jobAppId : ''
          redirect = redirect + extractRedirect(props.location.search)
          if (socialAuth.newCandidate) {
            // Primer login ever en GW
            if (socialAuth.provider) {
              props.authActions.authSocialSignupUser(
                socialAuth,
                password,
                state.tokenCaptcha,
                myDNI,
                country_document,
                redirect,
              )
            }
          } else {
            // Primer login con red social específica, pero PersonalUser ya existía
            props.authActions.authVinculateUser(socialAuth, myDNI, redirect)
          }
        } else {
          // TODO: Cuando esta vista incluya tambien signup "normal", ver bien desde donde se obtendra jobAppId cuando no es socialAuth
          redirect = '/jobapplication/' + socialAuth.jobAppId + extractRedirect(props.location.search)
        }
      } else {
        let errors = { ...state.errors };
        // NOTE: Porque se settea error de clave si o si?
        // errors.password = true;
        // errors.passwordConfirm = true;
        setState({
          ...state,
          errors: errors
        });
      }
    });
  };

  return (
    <Fragment>
      {(props.location.pathname === "/confirmDNI" && props.socialAuth.email === null) ? 
        <Redirect to="/notFound" />
      : 
        <div className='start-view' style={{ backgroundColor: '#FFFFFF' }}>
          <div>
            <div className="protected" id="mainDiv">
              <Modal
                title="Términos de uso de Genomawork"
                visible={state.tosDialog}
                onOk={handleTosDialog}
                onCancel={handleTosDialog}
                centered={true}
                className='terms-and-conditions-modal'
                footer={[
                  <Button key="back" type='primary' onClick={handleTosDialog}>
                    Cerrar
                  </Button>
                ]}
              >
                <iframe
                  credentialless=''
                  width="500"
                  height="500"
                  src="https://docs.google.com/document/d/e/2PACX-1vRSmBXJpnQXJV88b5l1lXMra9IXWo3SXlLWAb27aAKYM5jLDW79uzxlug3lfbKFBajA38JhlOOQRUeg/pub?embedded=true"
                >
                </iframe>
              </Modal>
                
              <Col>
                <Layout className='jd'>
                  <div className='content'>
                    <Content>
                      <section className='jd__cover'>
                        <div className='wrapper'>
                          <div className='content'>
                            {
                              isFetching === true
                              ?
                                <div className='pf__is-loading'>
                                  <Spin />
                                </div>
                              :
                                <Col>
                                  <Card
                                    style={{ padding: 10, marginTop: '3em', marginBottom: '4em' }}
                                  >
                                    <PageHeader
                                      onBack={goBack}
                                      title={socialAuth.newCandidate ? i18n.t('start__process__gmail__sign_up_1') : i18n.t('start__process__gmail__sign_up_2')}
                                      style={{ paddingLeft: 0 }}
                                    />
                      
                                    <Form onSubmit={submitSignupData}>
                                      {socialAuth.newCandidate ?
                                        <div style={{ marginBottom: 20, marginTop: 10, display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                          <Text strong>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                              {socialAuth.email ? socialAuth.email : state.email}
                                            </div>
                                          </Text>
                                        </div>
                                        :
                                        <div style={{
                                          display: 'flex', 
                                          justifyContent: 'center', 
                                          textAlign: 'center' 
                                        }}>
                                          <Text>
                                            {i18n.t('start__process__gmail__found__account').split('{')[0]} {socialAuth.email} {i18n.t('start__process__gmail__found__account').split('}')[1]}
                                          </Text>
                                        </div>
                                      }

                                      {socialAuth.newCandidate &&
                                       
                                        <Form.Item label={i18n.t('commons__country')}>
                                          {getFieldDecorator('country_document', {
                                            rules: [{ 
                                              required: true, 
                                              message: i18n.t('start_process__country__empty') 
                                            }],
                                          })(
                                            <Select
                                            size="large"
                                            onChange={handleChangeCountry}
                                            placeholder={i18n.t('start_process__constrain__country')}
                                          > 
                                            {state.countries.map(option => (
                                              <Option
                                                key={option.country}
                                                value={option.country.toLowerCase()}
                                              >
                                                {option.translation[i18n.language.split('-')[0]]}
                                              </Option>
                                            ))}
                                        </Select>
                                          )}
                                        </Form.Item>
                                      }

                                      <Form.Item label={i18n.t('start_process__DNI')} hasFeedback>
                                        {getFieldDecorator('dni', {
                                          rules: [
                                            { required: true, message: i18n.t('start__process__please_enter_your_dni') },
                                            { validator: handleDNIChange }
                                          ],
                                        })(
                                          <Input
                                            size='large'
                                            placeholder="RUT / CURP / CI / CIP / RG / CC"
                                            type="text"
                                          />
                                        )}
                                      </Form.Item>
                          
                                      {socialAuth.newCandidate &&
                                        <Fragment>
                                          {/* NOTE: Debería pedir captcha cuando PersonalUser ya existe? */}
                                          <ReCAPTCHA
                                            ref={captchaRef}
                                            sitekey={process.env.REACT_APP_CAPTCHA_API_KEY}
                                            onChange={verifyCallback}
                                            hl={i18n.language.split('-')[0]}
                                            size='invisible'
                                          />
                                          <Form.Item>
                                            {getFieldDecorator(
                                              'terms',
                                              { 
                                                rules: [{ required: true, message:  i18n.t('commons__required__field') }],
                                                onChange: () => captchaRef.current.executeAsync()
                                              },
                                            )(
                                              <Checkbox>
                                              {i18n.t('start_process__terms_of_use').split('**')[0]} <a onClick={handleTosDialog} style={{ fontSize: '14px' }}>{i18n.t('start_process__terms_of_use').split('**')[1]}</a> {i18n.t('start_process__terms_of_use').split('**')[2]}
                                              </Checkbox>
                                            )}
                                          </Form.Item>  
                                        </Fragment>
                                      }

                                      <div className="login__alerts" >
                                        {alerts.message && <Alert message={alerts.message} type="error" showIcon />}
                                        {(socialAuthErrors && socialAuthErrors.dniNotFound) && <Alert message={socialAuthErrors.dniNotFound} type="error" showIcon />}
                                      </div>

                                      <Button
                                        block
                                        size="large"
                                        type="primary"
                                        htmlType="submit"
                                        disabled={checkFields()}
                                        loading={isCreatingAccount}
                                      >
                                        {socialAuth.newCandidate ? i18n.t('start_process__create__account') : i18n.t('commons__confirm')}
                                      </Button>
                                    </Form>
                                  </Card>
                                </Col>
                            }
                          </div>
                          
                          {/* // FIXME:? */}
                          <div className='bg-image'>
                            <div className='main-image'>
                              <div className='bg' style={{ backgroundImage: `url(${state.backgroundImage})` }} />
                            </div>
                          </div>

                        </div>
                      </section>
                    </Content>
                  </div>
                </Layout>
              </Col>

            </div>        
          </div>
        </div>
      }
    </Fragment>
  )
}

SignUp.defaultProps = {
  authErrors: null,
  isFetching: false,
  socialAuth: {
    provider: null,
    tokenObj: null,
    email: null,
    jobAppId: null,
    newCandidate: null,
  },
  socialAuthErrors: null,
}

SignUp.propTypes = {
  authActions: PropTypes.shape({
    authLoginUser: PropTypes.func.isRequired,
    authSignupUser: PropTypes.func.isRequired
  }).isRequired,
  authErrors: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  isCreatingAccount: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  socialAuth: PropTypes.shape({
    provider: PropTypes.string,
    tokenObj: PropTypes.string,
    email: PropTypes.string,
    jobAppId: PropTypes.string,
    newCandidate: PropTypes.bool,
  }),
  socialAuthErrors: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    alerts: state.alerts,
    authErrors: state.auth.errors,
    isFetching: state.data.isFetching,
    isCreatingAccount: state.auth.isCreatingAccount,
    socialAuth: state.auth.socialAuth,
    socialAuthErrors: state.auth.socialErrors,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    authActions: bindActionCreators(authActionCreators, dispatch),
    dispatch,
  };
};

const Forms = Form.create({ name: 'horizontal_signup' })(SignUp);
export default connect(mapStateToProps, mapDispatchToProps)(Forms);
export { Forms as SignUpView };