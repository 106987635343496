import React from 'react';

const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
const simulateMouseClick = (element) => {
  mouseClickEvents.forEach(mouseEventType =>
    element.dispatchEvent(
      new MouseEvent(mouseEventType, {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1
      })
    )
  );
};

const tourConfig = (actions) => [
  {
    selector: '[data-tour="genoma-steps"]',
    content: () => (
      <div >
        Ahora puedes configurar tu proceso en tan sólo tres simples pasos.
      </div>
    ),
    action: () => {
      actions.setActiveStep(0)
    }
  },
  {
    selector: '[data-tour="jobappinfo-jobdetail"]',
    content: () => (
      <div >
        Agrega toda la información del cargo que quieres que los candidatos vean al postular al proceso
      </div>
    ),
  },
  {
    selector: '',
    highlightedSelectors: ['.jobappinfo-company'],
    mutationObservables: ['jobappinfo-company'],
    content: () => (
      <div >
        Personaliza la apariencia de la vista inicial del proceso para potenciar tu marca empleadora.
      </div>
    ),
    action: () => {
      actions.setActiveStep(0)
      const element = document.querySelector('#jobappinfo-company')
      const topPos = element?.getBoundingClientRect().top

      window.scrollTo({
        top: topPos, // scroll so that the element is at the top of the view
        behavior: 'smooth' // smooth scroll
      })
    }
  },
  {
    selector: '.jobapp-stages',
    highlightedSelectors: ['.jobapp-stages'],
    //mutationObservables selectors passed to this array will trigger a rerender of the highlighted area after changes to selected DOM nodes
    mutationObservables: ['.jobapp-stages'],
    content: () => (
      <div >
        Crea tu proceso de selección donde podrás añadir y / o eliminar etapas.
      </div>
    ),
    action: () => {
      window.scrollTo({
        top: 0, // scroll so that the element is at the top of the view
      })
      actions.setActiveStep(1)
    }
  },
  {
    selector: '[data-tour="genoma-evaluations"]',
    content: () => (
      <div >
        Añade evaluaciones troncales y / o complementarias desde la biblioteca de Genomawork.
      </div>
    ),
    action: () => {
      actions.setActiveStep(1)
    }
  },
  {
    selector: '.candidate-path-layout',
    highlightedSelectors: ['.candidate-path-layout'],
    mutationObservables: ['.candidate-path-layout'],
    content: () => (
      <div >
        Obtén una mirada integral de las evaluaciones que deberán completar los postulantes.
      </div>
    ),
    action: () => {
      actions.setActiveStep(2)
    }
  },
  {
    selector: '.welcome-container',
    highlightedSelectors: ['.welcome-container'],
    mutationObservables: ['.welcome-container'],
    content: () => (
      <div >
        Previsualiza la página inicial de tu proceso para asegurar una buena experiencia en los candidatos.
      </div>
    ),
    action: () => {
      actions.setShowShareProcessModal(false);
      const tab = document.querySelectorAll('.ant-tabs-tab')[1];
      simulateMouseClick(tab);
    }
  },
  {
    selector: '.share-process-modal',
    highlightedSelectors: ['.share-process-modal'],
    mutationObservables: ['.share-process-modal'],
    content: () => (
      <div >
        ¡Y no olvides la difusión de tu proceso! Aquí encontrarás distintas herramientas para compartir tu vacante con el talento que estás buscando.
      </div>
    ),
    action: () => {
      actions.setShowShareProcessModal(true);
      actions.setActiveStep(0);
    }
  }

];

export default tourConfig;
