import React from 'react';

import Summary from '../../pdfCommons/Summary';
import Detail from '../DetailDisc';
import FooterPage from '../../pdfCommons/FooterPage';

import './disc-pdf.scss';

const DiscPdf = ({ data, refPrint, candidateInfo, jobName }) => {

  return (
    <div ref={refPrint} className='disc-pdf-modal'>
      <section className='disc-pdf-modal__mainsection print'>
        <Summary
          candidateInfo={candidateInfo}
          jobName={jobName}
          description={data.answer.description}
          testName={data.name}
          dateCreated={data.answer.date_created}
        />
      </section>
      <FooterPage />
      <section className='disc-pdf-modal__mainsection print'>
        <Detail scores={data.answer.scores} />
      </section>
    </div>
  );
}

export default DiscPdf;
