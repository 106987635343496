import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Button, Row } from 'antd';

//---> despues de la call con joaco
import FirstPagePdf from './FirstPagePdf';
import SecondPagePdf from './SecondPagePdf';
import ThirdPagePdf from './ThirdPagePdf';
import FourthPagePdf from './FourthPagePdf';
import FifthPagePdf from './FifthPagePdf';

import '../../pdf-css.scss';

/**
 * Root component
 * @param {*} props
 * @returns
 */
function CompareCandidatePdf({ candidates, jobName }) {
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <Row type='flex' justify='center'>
        <Button
          type='primary'
          onClick={handlePrint}
          style={{ height: 40, whiteSpace: 'normal' }}
        >
          {'Descargar PDF'}
        </Button>
      </Row>
      <div ref={componentRef} className='root-print-div firefox-bg-color'>
        <title>Comparador de candidatos</title>
        <table>
          <thead>
            <tr>
              <td>&nbsp;</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td>
                <div className='container break-first-page'>
                  <FirstPagePdf jobName={jobName} />
                </div>

                <div className='container break-page'>
                  <SecondPagePdf candidates={candidates} jobName={jobName} />
                </div>

                <div className='container break-page'>
                  <ThirdPagePdf candidates={candidates} />
                </div>

                <div className='container break-page'>
                  <FourthPagePdf candidates={candidates} />
                </div>

                <div className='container break-page'>
                  <FifthPagePdf candidates={candidates} />
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>&nbsp;</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  );
}

export default CompareCandidatePdf;
