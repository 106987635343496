import React, { Fragment, useEffect, useState } from 'react';
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../../actions/data";
import { validateChileanDni } from "./utils"
import { SERVER_URL } from '../../../../utils/config';
import i18n from '../../../../i18n';

import XLSX from 'xlsx';
import {
  Alert,
  Button,
  Collapse,
  Icon,
  List,
  Modal,
  Col,
  Row,
  Tooltip,
  Typography,
  message,
  Steps,
  Switch,
  Layout
} from 'antd';

import './CandidateUpload.scss'
import OutTable from './ImportTable'
import DataInput from './DataInput'

const { Footer } = Layout;
const { Step } = Steps;
const { Text, Title } = Typography;

/**
 * Wrapper
 * @param {*} props 
 */
function CandidateUpload(props) {
  return (
    <Row
      justify="center"
      type="flex"
    >
      <Col xs={24} sm={12} md={16}>
        <XlsUpload {...props} />
      </Col>
    </Row>
  )
}

const optionalFieldsObj = [
  {
    name: 'Código télefono',
    key: 'phone_prefix',
    value: '+56'
  },
  {
    name: 'Teléfono',
    key: 'phone',
    value: '999999999'
  },
  {
    name: 'Ciudad/Comuna',
    key: 'commune',
    value: 'Santiago'
  },
  {
    name: 'Género',
    key: 'gender',
    value: '<M,F,N,O,W,E>'
  },
  {
    name: 'Fecha nacimiento',
    key: 'birth_date',
    value: 'DD-MM-YYYY'
  },
  {
    name: 'Nacionalidad',
    key: 'nationality',
    value: 'Chile'
  },
]

/**
 * Main conponent 
 * Children components: DataInput, Outable
 * @param {*} props 
 */
function XlsUpload(props) {

  const [xlsState, setXlsState] = useState({
    data: [], /* Array of Objects e.g. [{"a":1,"b":2}, ...] */
    cols: []  /* Array of column strings e.g. [ 'A', 'B', ... ] */
  });

  const [optionalFieldsState, setOptionalFieldsState] = useState({ optionalFields: false, koFields: false });

  const [errorsState, setErrorsState] = useState({
    dni: [],
    email: []
  });

  const [state, setState] = useState({
    modalVisible: false,
    validateData: false, // Indica si datos han sido validados
    importData: null,
    helpActivated: false, // Indica si se presionó el botón para ver modal de ayuda
    optionalFieldsWarning: false // Open modal with warning for optional fields
  });

  useEffect(
    () => {
      validateData()
    },
    [xlsState]
  );

  const handleFile = (file) => {
    try {
      /* Boilerplate to set up FileReader */
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = (e) => {
        // Parse data 
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', cellDates: true,  dateNF: 'dd-mm-yyyy' });
        // Get first worksheet 
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        // Convert to json, it's also posible to convert into an array of arrays
        const data = XLSX.utils.sheet_to_json(ws, { raw: false });
        // Update state 
        setXlsState({
          data: data,
          cols: extractHeader(ws)
        });
      };
      if (rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
    }
    catch (error) {
      // if error rest state
      setXlsState({ cols: [], data: [] });
    }
  };

  /**
   * Get first row from xls
   * @param {*} ws - worksheet object
   */
  function extractHeader(ws) {
    const header = [];
    const columnCount = XLSX.utils.decode_range(ws['!ref']).e.c + 1;
    for (let i = 0; i < columnCount; ++i) {
      header[i] = ws[`${XLSX.utils.encode_col(i)}1`].v;
    }
    return header
  }

  /**
   * Export json to xls
   */
  const exportFile = () => {
    // convert state to workbook 
    const ws = XLSX.utils.json_to_sheet(xlsState.data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "exported");
    // generate XLSX file and send to client 
    XLSX.writeFile(wb, "exported_table.xlsx");
  };

  /**
   * Builds a template with the xls structure
   */
  const baseCols = ['name', 'surname', 'email', 'dni', 'country_document'];
  const getTemplate = () => {
    let template = [[...baseCols],
    ['juan', 'perez', 'a@b.c', '11111111-1', 'chile']];

    // Add the optional fields to the template when requested
    if (optionalFieldsState.optionalFields) {
      const optionalFieldsObjCols = optionalFieldsObj.map(obj => obj.key)
      const optionalFieldsObjVals = optionalFieldsObj.map(obj => obj.value)
      template = [[...template[0], ...optionalFieldsObjCols], [...template[1], ...optionalFieldsObjVals]]
    }
    // Add the ko fields to template the when requested
    if (optionalFieldsState.koFields) {
      const koFieldsObj = getKoFields()
      const koFieldsObjCols = koFieldsObj.map(obj => obj.key)
      const koFieldsObjVals = koFieldsObj.map(() => 'answer')
      template = [[...template[0], ...koFieldsObjCols], [...template[1], ...koFieldsObjVals]]
    }
    /* convert state to workbook */
    const ws = XLSX.utils.aoa_to_sheet(template);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "template.xlsx");
    message.success('El template ha sido descargado', 3);
  };

  /**
   * Builds a list component with the errors/warnings logs using the backend response
   * @param {String} title - title of the logs
   * @param {Array} errorsArray - list of string with errors/warnings
   */
  const makeErrorList = (title, idx, errorsArray) => {
    let errorList = errorsArray.map((element, idx) => {
      return (
        <List.Item key={idx}>{`${idx + 1}.- ${element}`}</List.Item>
      )
    })
    let header = `Se encontraron ${errorsArray.length} ${title}:`
    return (
      <Collapse.Panel header={header} key={idx}>
        {errorList}
      </Collapse.Panel>
    )
  }

  const HelpModalContent = () => {
    const warnings = [
      "Candidato ya existe dentro de nuestro sistema. En este caso no se crea un nuevo usuario, y si los datos del excel no coincidan con los almacenados, se mantienen los datos del sistema.",
      "Candidato no existe dentro de nuestro sistema. En este caso se crea un nuevo usuario para el candidato y se asigna al proceso.",
      "Usuario ya se encuentra ingresado en el proceso. En este caso, no se vuelve a asignar al proceso.",
    ]
    return (
      <Fragment>
        <p>
          Antes de cargar candidatos al proceso, asegúrate de que el archivo esté listo para importarse. A
          continuación, te explicamos como Genomawork determina cuando tus registros pueden presentar
          advertencias y/o errores:
        </p>
        <br />

        <Collapse>
          <Collapse.Panel header='Candidatos nuevos o existentes' key={0}>
            <div>
              <p>
                Genomawork sabrá si un candidato ya existe en el sistema. Esto se hace mediante la dirección
                de correo electrónico del candidato y/o el DNI asociado a su usuario.
              </p>
              <br />
              <p>
                Si importas un candidato que ya existe en Genomawork, este se asignará a tu proceso; sin embargo,
                las propiedades coincidentes se actualizarán con los datos previamente ingresados a la plataforma
                por el candidato. Si importas un candidato que no existe en Genomawork, este será creado con los
                datos importados en tu archivo.
              </p>
              <br />
              <p>
                Una vez importado el archivo, el candidato recibirá en su correo un link de invitación para participar
                en tu proceso; luego, con su usuario y clave deberá ingresar a la plataforma y aceptar nuestra politica
                de términos y condiciones, para poder avanzar a las actividades asignadas al proceso.
              </p>
            </div>
          </Collapse.Panel>
          <Collapse.Panel header='Advertencias al importar' key={1}>
            <div>
              <p>
                Te avisaremos si:
              </p>
              <List
                bordered={false}
                dataSource={warnings}
                renderItem={item =>
                  <List.Item>
                    {item}
                  </List.Item>
                }
              />
              <p>
                En los casos que el candidato no se encuentre ya asignado al proceso, se procede a asignar el usuario
                a este proceso y enviarle una invitación para ingresar con su respectiva clave.
              </p>
            </div>
          </Collapse.Panel>
          <Collapse.Panel header="Errores de validación" key={2}>
            <p>
              Antes de cargar los candidatos al proceso, la plataforma hace una validación de los datos del archivo excel
              subido. Específicamente, revisa email y DNI, y las condiciones que se deben cumplir es que estos campos no
              se encuentren vacíos y que su formato sea válido.
            </p>
          </Collapse.Panel>
        </Collapse>

      </Fragment>
    )
  }

  /**
   * Another modal inner content, with warnings when importing candidates with ko
   */
  const WarningOptionalFields = () => {
    return (
      <>
        <p style={{ marginBottom: 16 }}>
          <Typography.Text>
            Recuerda que solo puedes importar preguntas adicionales con respuestas del tipo texto plano.
          </Typography.Text>
        </p>
        <p>
          <Typography.Text strong>
            Además los candidatos con respuestas previamente cargadas no podran editar o volver a contestar dichas preguntas!
          </Typography.Text>
        </p>
      </>
    )
  }

  /**
   * Modal component that swap the content between the frontend validations and backend validation, after import
   */
  const InnerModal = () => {
    try {
      // show after import
      if (state.importData) {
        let warningErrorList = makeErrorList('advertencia/s', 1, state.importData.data.errors.warning)
        let createErrorList = makeErrorList('error/es al crear candidato', 2, state.importData.data.errors.create)
        let assignErrorList = makeErrorList('error/es al asignar candidato', 3, state.importData.data.errors.assign)

        const importData = state.importData.data
        return (
          <div>
            <div className='modal-title-text'>
              {
                (importData.errors.create.length > 0 || importData.errors.assign.length > 0) ? (
                  // Caso 1: Errores que impiden importación
                  <Typography.Text type="danger" >
                    La mayor parte de los candidatos han sido importados, a excepción de los que aparecen
                    en la sección de errores, revísalos a continuación:
                  </Typography.Text>
                ) : (
                  (state.importData.data.errors.warning.length > 0) ? (
                    // Caso 2: Advertencias que no impiden importación
                    <Typography.Text style={{ color: '#fa541c' }}>
                      Todos los candidatos han sido importados y asignados a tu proceso, pero se detectaron
                      algunas advertencias:
                    </Typography.Text>
                  ) : (
                    // Caso 3: "Happy path"
                    <Typography.Text style={{ color: '#237804' }}>
                      Todos los candidatos han sido importados exitosamente.
                    </Typography.Text>
                  )
                )
              }
            </div>
            <Collapse
            // defaultActiveKey={['1']}
            >
              {warningErrorList}
              {createErrorList}
              {assignErrorList}
            </Collapse>
            <div
              className='modal-btn-container'
            >
              <Button
                type='primary'
                onClick={() => props.dispatch(push(`/processes/${props.match.params["jobappid"]}/`))}
              >
                Ir a la tabla de candidatos
              </Button>
            </div>
          </div>
        )
      }
      // shows after validate data
      else {
        let dniErrorList = makeErrorList('error/es en los DNI', 1, errorsState.dni)
        let emailErrorList = makeErrorList('error/es en los email', 2, errorsState.email)

        return (
          //"errorsState.errorsFront"
          <div>
            {
              errorsState.email.length !== 0 || errorsState.dni.length !== 0 ? (
                <Typography.Text
                  style={{ marginLeft: '5px' }}
                  type="danger"
                >
                  No se puede proceder a importar candidatos, revisa el archivo excel y vuelvelo a subir.
                </Typography.Text>
              ) : (null)
            }
            <Collapse defaultActiveKey={['1']}>
              {dniErrorList}
              {emailErrorList}
            </Collapse>
          </div>
        )
      }
    }
    catch {
      let res = <div><h5>Problema en el servidor</h5></div>
      return res
    }
  }

  /*
    Código de errores:
      0: Sin errores de validación
      1: DNI vacío
      2: DNI inválido
      3: email vacío
      4: email inválido
  */

  /**
   * Check if mail exist and has a correct format, return a string with the error or false if mail is valid
   * @param {Object} obj - obj with keys: email, name, surname, dni and country_document
          */
  const getEmailErrors = (obj) => {
    let error = ""
    if (!obj.email) {
      let error = {
        modalMessage: "No se encuentra email en la fila:",
        tableMessage: "Email vacío",
        errorCode: 3,
      }
      return error
    }
    else {
      let error = {
        modalMessage: `Se encontro el email ${obj.email} con formato inválido en la fila:`,
        tableMessage: `Formato email inválido`,
        errorCode: 4,
      }
      let pattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/
      let ret = pattern.test(obj.email) ? false : error
      return ret
    }
  }

  /**
   * Check if DNI exist then check if country_document = chile and validate the DNI if country_document !=chile only chek if DNI not empty
   * return a string with the error or false if dni is valid
   * @param {Object} obj - obj with keys: email, name, surname, dni and country_document
            */
  const getDniErrors = (obj) => {
    if (!obj.dni) {
      let error = {
        modalMessage: "No se encuentra DNI en la fila:",
        tableMessage: "DNI vacío",
        errorCode: 1,
      }
      return error
    }
    else {
      let error = {
        modalMessage: `DNI ${obj.dni} inválido en la fila:`,
        tableMessage: `DNI inválido`,
        errorCode: 2,
      }
      let ret = false
      if (obj.country_document) {
        if (obj.country_document.toLowerCase() === 'chile') {
          let dni = obj.dni.toString().toLowerCase()
          ret = validateChileanDni(dni) ? false : error
        }
      }
      return ret
    }
  }

  /**
   * Frontend validation, validate mail and DNI
   */
  const validateData = () => {
    let errors = { email: [], dni: [] }

    xlsState.data.map((obj, idx) => {
      let errorEmail = getEmailErrors(obj)
      let errorDni = getDniErrors(obj)
      obj.status = {
        validation: {
          error: [],
          warning: [],
          success: []
        },
        import: {
          error: [],
          warning: [],
          success: []
        },
      }

      if (errorEmail) {
        errors.email.push(`${errorEmail.modalMessage} ${idx + 1}`)
        obj.status.validation.error.push(errorEmail.tableMessage)
      }
      if (errorDni) {
        errors.dni.push(`${errorDni.modalMessage} ${idx + 1}`)
        obj.status.validation.error.push(errorDni.tableMessage)
      }

      if (!errorDni && !errorEmail) {
        obj.status.validation.success.push("Datos válidos")
      }
    })
    setErrorsState({
      dni: errors.dni,
      email: errors.email
    })

    // Only enable import if data is valid
    if (errors.email.length == 0 && errors.dni.length == 0 && xlsState.data.length != 0) {
      setState({
        ...state,
        validateData: true,
        importData: null,
      })
    }
    else {
      setState({
        ...state,
        validateData: false,
        importData: null,
      })
    }
  };

  /**
   * Post with body
   * @param {*} url - endpoint url
            * @param {*} bodyData - object with the body data
            */

  const postData = async (url = '', bodyData = {}) => {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Token ${props.token}`,
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(bodyData)
    });
    return response;
  }

  /**
   * Import object with candidates
   */
  const importFile = () => {
    // TODO before sending data, make a new key <ko> for every candidate with the configuration.questions plus the answer
    // 0) get the candidate answers searching in xlsState.data for each key that contains the substring '<int>:', split :, get first element (answer key in the DB) /^\d+:/.test(string)
    // 1) for each candidate add ko:{question:"", type:"", answer:""}, get this info from the assigned activity ko and add the answer key
    // 2) remove unnecesary keys from the state
    // 3 import

    // Build the request body
    let keys = [...baseCols]
    let koKeys = []
    if (optionalFieldsState.optionalFields) {
      const optionalFieldsObjCols = optionalFieldsObj.map(obj => obj.key)
      keys = [...keys, ...optionalFieldsObjCols]
    }
    if (optionalFieldsState.koFields) {
      const koFieldsObj = getKoFields()
      const koFieldsObjCols = koFieldsObj.map(obj => obj.key)
      koKeys = [...koFieldsObjCols]
    }

    const bodyData = xlsState.data.map(obj => {
      // Add the personal data
      let tmpCandidate = {}
      keys.forEach(key => {
        if (key === 'has_disability') {
          tmpCandidate[key] = obj[key].toUpperCase() === 'S' ? true : false;
        }
        else {
          tmpCandidate[key] = obj[key];
        }
      })
      // build the ko key
      if (optionalFieldsState.koFields) {
        let koAnswer = { ...props.assignedActivities.KO.configuration.questions };
        koKeys.forEach(key => {
          const idx = key.split(':')[0]
          koAnswer[idx].answer = obj[key];
        })
        // add the ko key {name:"name", ..., ko:{...koAnswer}}
        tmpCandidate.ko = koAnswer;
      }
      return tmpCandidate ;
    }
    );

    let status
    postData(`${SERVER_URL}/api/v1/accounts/importcandidates/${props.match.params["jobappid"]}/`, { data: bodyData})
      .then(response => {
        status = response.status
        return response.json()
      })
      .then(data => {
        setState({
          ...state,
          modalVisible: true,
          validateData: true,
          importData: { status: status, data: data },
        })

        const { assigned_users } = data
        const warnings = data.errors.warning
        const creationErrors = data.errors.create
        // FIXME: Warnings y errores se deberían poder revisar más eficientemente,
        // para lo cual habria que modificar un poco como el back envía el resultado
        // de la importación de candidatos
        xlsState.data.map((obj, idx) => {
          // Chequear si usuario ya estaba asignado
          // TODO: Si postulacion ya existia, no mostrar warning de que usuario ya existia?
          warnings.forEach(warning => {
            if (warning.includes('asignado') && warning.includes(obj.dni.split('-').join(''))) {
              obj.status.import.warning.push("Postulación ya existía")
            }
            else if (warning.includes('existe') && warning.includes(obj.dni.split('-').join(''))) {
              if (warning.includes(obj.email)) {
                obj.status.import.warning.push("Usuario ya existía")
              } else {
                obj.status.import.warning.push("Usuario ya existía con otros datos")
                obj.status.import.success.push("Candidato agregado")
              }
            }
          });

          // Chequear si hubo error al crear usuario
          creationErrors.forEach(error => {
            if (error.includes(obj.dni)) {
              obj.status.import.error.push("Error al crear usuario")
            }
          })

          // TODO: TAGS DE ERROR DE ASIGNACIÓN

          if (assigned_users.includes(obj.email)) {
            obj.status.import.success.push("Candidato agregado")
          }
        })
      })
  };

  const openValidateModal = () => {
    setState({
      ...state,
      modalVisible: true,
    })
  };

  const openHelpModal = () => {
    setState({
      ...state,
      modalVisible: true,
      helpActivated: true,
    })
  };

  const handleCancel = () => {
    setState({
      ...state,
      modalVisible: false,
      helpActivated: false,
      optionalFieldsWarning: false,
    })
  }

  /**
  * Check if jobapp has kos then return the list of questions
  * @returns
  */
  const getKoFields = () => {
    if (props.assignedActivities.KO) {
      const questions = props.assignedActivities.KO.configuration.questions
      // return array ob duplicated name and key, also concat the question number, eg:[{name:"0:pregunta 1", key:"0:pregunta 1" }] 
      return Object.keys(questions).map(elem => ({ name: `${questions[elem].question}`, key: `${elem}:${questions[elem].question}` }))
    }
    return []
  }

  const getOptionalFields = () => {
    // if optional field switch it's true
    if (optionalFieldsState.optionalFields) {
      return optionalFieldsObj
    }
    return []
  }

  const getModalContent = () => {
    if (state.helpActivated)
      return <HelpModalContent />
    else if (state.optionalFieldsWarning) {
      return <WarningOptionalFields />
    }
    else {
      return <InnerModal />
    }
  }

  return (
    <div
      className="import-main"
    >
      <Modal
        title={
          <Typography.Text strong> Carga de candidatos </Typography.Text>
        }
        visible={state.modalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        {<div>
          {getModalContent()}
        </div>}
      </Modal>

      <Row>
        <Col className='cl_content__header_content'>
          <Tooltip title='Volver a mis procesos'>
            <Button
              type="primary"
              onClick={
                () => props.dispatch(
                  push(`/processes/${props.match.params["jobappid"]}/`)
                )
              }
              icon='left'
            />
          </Tooltip>
          <Title level={3}> Carga de Candidatos </Title>
          <span></span>
        </Col>

        <Alert
          closable
          message={
            <Text>
              {i18n.t('add_candidates__active_candidate_explanation').split('**')[0]}
              <Text strong>
                {i18n.t('add_candidates__active_candidate_explanation').split('**')[1]}
              </Text>
              {i18n.t('add_candidates__active_candidate_explanation').split('**')[2]}
              <Text strong>
                {i18n.t('add_candidates__active_candidate_explanation').split('**')[3]}
              </Text>
              {i18n.t('add_candidates__active_candidate_explanation').split('**')[4]}
            </Text>
          }
          showIcon
          style={{ marginBottom: '1em' }}
          type="info"
        />
      </Row>

      <Steps>
        <Step
          status={xlsState.data.length != 0 ? "finish" : "wait"}
          title="Subir excel"
          icon={<Icon type="file-excel" />}
        />
        <Step
          status={
            !state.validateData ? (
              // Datos no validados por error o porque aun no se reciben
              (errorsState.dni.length || errorsState.email.length) ? "error" : "wait"
            ) : "finish" // Datos validados con éxito
          }
          title="Validación de datos"
          description={errorsState.dni.length || errorsState.email.length ? "No se puede importar. Revisa el archivo excel y súbelo nuevamente" : null}
          onClick={openValidateModal}
          className={xlsState.data.length ? "clickable" : ""}
          icon={
            <Icon
              type={errorsState.dni.length || errorsState.email.length ? "close-circle" : "check-circle"}
            />
          }

        />
        <Step
          status={state.importData ? "finish" : "wait"}
          title="¡Candidatos Importados!"
          icon={<Icon type="usergroup-add" />}
          description={state.importData ? "Candidatos importados con éxito" : null}
        />
      </Steps>

      <Row style={{ margin: 20, marginBottom: 0 }} type='flex' justify='start' gutter={16}>
        <Col>
          <Switch
            checkedChildren="Con datos opcionales"
            unCheckedChildren="Sin datos opcionales"
            onChange={(val) => setOptionalFieldsState({ ...optionalFieldsState, optionalFields: val })}
            style={{}}
          />
        </Col>
        <Col>
          <Switch
            disabled={props.assignedActivities.KO ? false : true}
            checkedChildren="Con preguntas adicionales"
            unCheckedChildren="Sin preguntas adicionales"
            onChange={(val) => {
              setOptionalFieldsState({ ...optionalFieldsState, koFields: val })
              setState({
                ...state,
                modalVisible: true,
                optionalFieldsWarning: true,
              })
            }
            }
          />
        </Col>
      </Row>

      <Row style={{ margin: 20, marginBottom: 0 }}>
        <Col>
          <DataInput handleFile={handleFile} setXlsState={setXlsState} setState={setState} />
        </Col>
      </Row>

      {xlsState.data.length ?
        <Row style={{ margin: 20, marginTop: 0 }}>
          <Col>
            <OutTable
              data={xlsState.data}
              cols={xlsState.cols}
              optionalFields={getOptionalFields}
              koFields={getKoFields}
              step={
                !state.importData ? (
                  (state.validateData || (errorsState.dni.length || errorsState.email.length)) ? 'validation' : 'upload'
                ) : 'import'
              }
            />
          </Col>
        </Row>
        : null
      }

      {/* TOOLBAR */}
      <Footer
        className="import__footer"
      >
        <span className="actions">
          <div className="left">
            <Button onClick={getTemplate}>
              Descargar template
            </Button>
            <Button onClick={openHelpModal}>
              Ayuda
            </Button>
          </div>

          <span className="right">
            {!state.importData ? (
              <Button
                disabled={!state.validateData}
                onClick={importFile}
                className={state.validateData ? "import-button" : ""}
              >
                Importar
              </Button>
            ) : [
              <Button
                onClick={() => props.dispatch(push(`/processes/${props.match.params["jobappid"]}/`))}
              >
                Volver a tabla de candidatos
              </Button>,
              <Button
                onClick={openValidateModal}
              >
                Advertencias
              </Button>
            ]}
          </span>
        </span>
      </Footer>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    jobApplication: state.jobapp.jobApplication,
    // TODO replace this with endpoint
    assignedActivities: state.jobapp.assignedActivities,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateUpload);