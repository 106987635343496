import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import ActivityRow from "./ActivityRow/ActivityRow";
import StageTitle from "./stagetitle/StageTitle";
import i18n from "../../../../../../../i18n";


const styles = theme => ({
	candidateStage2: {
		paddingTop: 14,
		paddingBottom: 18,
		textAlign: "center"
	},
	title: {
		border: "1px solid #999999",
		borderRadius: 4,
		backgroundColor: "#898989",
		textAlign: "center",
		paddingTop: 13,
		paddingBottom: 13,
		width: 240,
		margin: "0 auto 27px"
	},

	titleBody1: {
		color: "#fff"
	},
	padTopBot: {
		paddingTop: 8,
		paddingBottom: 8,
		"&:first-child": {
			paddingTop: 0
		},
		"&:last-child": {
			paddingBottom: 0
		}
	},
});

class ActivityPath extends React.Component {
    
    columnsPerRow = (row) => {
        if (row===0){
            return 1
        }else if ((row+3)%7 === 0 || (row+1)%7 === 0) {
            return 2
        } else if ((row+6)%7 === 0){
            return 3
        } else {
            return false
        }
    }
    
    activitiesSliced = (activities) => {
        let clenActivities = activities.filter(elem => !['ALSP','ALST'].includes(elem.code))
        let sliced = []
        for (let i=0; i<clenActivities.length; i +=1 ){
            let size =  this.columnsPerRow(i)
            if (size){
                sliced.push(clenActivities.slice(i,Math.min(i+size, clenActivities.length)))
            }
        }
        return sliced
    }
    
    render() {
        const { businessSlug, colorTheme, classes, activities, stage, items, prvw } = this.props;
        const percentage = activities.every(act => act.progress.status === 'FINISHD')
        const slicedActivities = this.activitiesSliced(activities)
        const title = {
            1: i18n.t('candidate_path__personal__info'),
            2: i18n.t('candidate_path__activities'),
            3: i18n.t('candidate__path__KO__questions'),
            4: i18n.t('candidate_path__interview'),
        }
        const levelOneIsCompleted = items.filter(e => e.code === 'FORM' || e.code === 'KO').map(({ status }) => status).includes('PLAYABLE')
        return (
            <div className={classes.candidateStage2}>
                <StageTitle percentage={percentage} name={title[stage]}/>
                <Grid container className={title[stage] === title[2] && levelOneIsCompleted ? 'level-locked' : ''}>
                    {slicedActivities.map((row, index) => (
                        <ActivityRow
                            businessSlug={businessSlug}
                            colorTheme={colorTheme}
                            prvw={prvw}
                            row={row}
                            key={index}
                            stage={stage}
                        />
                    ))}
                </Grid>
            </div>
        )
    }
};

ActivityPath.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        items: state.data.data.items
    }
}

export default withStyles(styles)(connect(mapStateToProps)(ActivityPath));
