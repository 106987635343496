import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

const BluePercentage = ({ number }) => {
  return (
    <Text style={{ color: '#0050B3', fontSize: 18 }} strong>
      {number}%
    </Text>
  );
};

export default BluePercentage;
