import React, { useState, useEffect } from "react"
import { connect } from "react-redux";
import { push } from 'react-router-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from "redux";

import * as actionCreators from "../../../actions/data";
import './InterviewKit.scss';

import {
  Row, Col, Breadcrumb, Input, Button,
  Tabs, Table, Typography, Icon, Modal,
  Layout, Menu, Dropdown, message, DatePicker
} from "antd"

const { Search } = Input;
const { Title, Paragraph, Text } = Typography;
const { TabPane } = Tabs;

function CustomInterview(props) {

  const [rowsData, setRowsData] = useState([{}]);
  const [interviewList, setInterviewList] = useState([]);
  const [scorecardList, setScorecardList] = useState([]);
  const [searchFilter, setSearchFilter] = useState({ text: '' });

  let getAllEndpoint = '/api/v1/evaluations/interviewkit_list/';
  let token = props.token;

  const getData = () => {
    props.actions.fetchAndReturn(token, getAllEndpoint)
      .then(response => {
        const interviewFilter = response.body.filter(item => item.kit_type === 'INTERVIEW')
        const scorecardFilter = response.body.filter(item => item.kit_type === 'CARD')
        setInterviewList(interviewFilter)
        setScorecardList(scorecardFilter)
      })
  }
  useEffect(() => {
    getData()
  }, [])

  const editInterview = (id) => {
    props.dispatch(push(`/account/interviewkit/edit/interview/${id}`))
  }
  const editScorecard = (id) => {
    props.dispatch(push(`/account/interviewkit/edit/scorecard/${id}`))
  }
  const modalDelete = (id) => {
    Modal.confirm({
      title: '¿Estás seguro/a que deseas eliminar la plantilla?',
      content: 'Se eliminará de manera definitiva.',
      okText: 'Eliminar plantilla',
      centered: true,
      cancelText: 'Cancelar',
      onOk() {
        const delete_endpoint = `/api/v1/evaluations/interviewkit/${id}/`
        props.actions.fetchAndReturn(props.token, delete_endpoint, 'delete')
          .then(response => {
            if (response.status === 200) {
              message.success('Plantilla eliminada correctamente')
              getData()
            } else {
              message.error('Error al eliminar la plantilla')
            }
          }).catch(error => {
            message.error('Error al eliminar la plantilla')
          })
      }
    })
  }
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowsData(selectedRows);
    }
  };
  const bulkDelete = (row) => {
    for (let i = 0; i < row.length; i++) {
      const delete_endpoint = `/api/v1/evaluations/interviewkit/${row[i].id}/`
      props.actions.fetchAndReturn(props.token, delete_endpoint, 'delete')
        .then(response => {
          if (response.status === 200) {
            getData()
            setRowsData({})
            message.success('Eliminación exitosa')
          }
        })
    }
  }
  const handleChangeSearchFilter = (e) => {
    setSearchFilter({ ...searchFilter, [e.target.name]: e.target.value })
  }
  console.log(interviewList)
  const filterSearch = (e) => {
    if (searchFilter.text !== '') {
      const filtroInterview = interviewList.filter(item => {
        const resultante = item.group.toUpperCase()
        return resultante.indexOf(searchFilter.text.toUpperCase()) > -1
      })
      const filtroScoreCard = scorecardList.filter(item => {
        const resultante = item.group.toUpperCase()
        return resultante.indexOf(searchFilter.text.toUpperCase()) > -1
      })
      if (filtroInterview.length > 0) {
        setInterviewList(filtroInterview)
        message.success('Scorecard encontrada')
        setSearchFilter({ ...searchFilter, text: '' })
      }
      if (filtroScoreCard.length > 0) {
        setScorecardList(filtroScoreCard)
        message.success('Scorecard encontrada')
        setSearchFilter({ ...searchFilter, text: '' })
      }
      if (filtroInterview.length > 0 && filtroScoreCard.length > 0) {
        message.error('Sin resultados')
        setSearchFilter({ ...searchFilter, text: '' })
        getData()
      }
    }
    if (searchFilter.text === '') {
      getData()
    }
  }

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Link to={'/account/interviewkit/new/interview'} >Nueva Entrevista</Link>
      </Menu.Item>
      <Menu.Item key="1">
        <Link to={'/account/interviewkit/new/scorecard'}>Nueva Scorecard</Link>
      </Menu.Item>
    </Menu>
  )

  const columns = [
    {
      title: 'NOMBRE DE LA PLANTILLA',
      dataIndex: 'group',
      key: 'group',
      render: (name) => (
        <>
          {/* <Icon type="star" style={{padding: '0 10px 0 5px', color: '#A4A4A4', fontSize:'15px'}} /> */}
          <Text>{name}</Text>
        </>
      )
    },
    {
      title: 'NÚMERO DE PREGUNTAS',
      dataIndex: 'questions_total',
      key: 'questions_total',
      sorter: (a, b) => b.questions_total - a.questions_total
    },
    {
      title: 'TIPO DE PLANTILLA',
      dataIndex: 'kit_type',
      key: 'kit_type',
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (id) => <>
        {interviewList.find(item => item.id === id) ? (
          <Button onClick={() => editInterview(id)} style={{ border: 'none', color: '#A4A4A4', boxShadow: 'none' }} icon="edit" />
        ) : (
          <Button onClick={() => editScorecard(id)} style={{ border: 'none', color: '#A4A4A4', boxShadow: 'none' }} icon="edit" />
        )}
        {/* <Button style={{ border: 'none', color: '#A4A4A4', boxShadow: 'none'}} icon="copy" />
          <Button style={{ border: 'none', color: '#A4A4A4', boxShadow: 'none'}} icon="eye" /> */}
        <Button style={{ border: 'none', color: '#A4A4A4', boxShadow: 'none' }} onClick={() => modalDelete(id)} icon="delete" />
      </>
    }
  ]

  return (
    <Layout className="layoutCustomInterview">
      <Row className="beadcrumNav">
        <Col>
          <Breadcrumb separator=">">
            <Breadcrumb.Item href="/processes">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/tools">Mis herramientas</Breadcrumb.Item>
            <Breadcrumb.Item href="/account/interviewkit">Entrevistas Personalizadas</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row className="infoSection">
        <Col>
          <Title>Entrevistas personalizadas</Title>
        </Col>
        <Col>
          <Paragraph>
            En esta sección podrás <Text strong>gestionar y configurar las entrevistas personalizadas para los candidatos.</Text> Además podrás visualizar las Scorecard para evaluar la puntuación de las entrevistas. Puedes iniciar configurando la <Text strong>plantilla predeterminada según tus requerimientos</Text> y luego crear más plantillas según lo que necesites.
          </Paragraph>
        </Col>
      </Row>
      <Row className="filterSearch" type="flex" justify='space-between' style={{ margin: '20px 40px' }}>
        <Col>
          <Search value={searchFilter.text} onSearch={filterSearch} onChange={handleChangeSearchFilter} name="text" placeholder="Buscar Plantilla" style={{ width: '400px' }} />
        </Col>
        <Row className="filterSearchAction" type="flex" align="middle" gutter={16}>
          {/* <Col>
              <DatePicker size="large" placeholder="Buscar por fecha"/>
            </Col> */}
          <Col>
            <Dropdown overlay={menu} trigger={['click']}>
              <Button type='primary' onClick={e => e.preventDefault()}>
                Crear Plantilla
              </Button>
            </Dropdown>
          </Col>
        </Row>
      </Row>
      <Row type="flex" justify="center" >
        <div className="customInterviewTable" style={{ width: '100%' }}>
          <Tabs>
            <TabPane tab='Entrevistas' key='1'>
              <Table rowKey={interviewList => interviewList.id} rowSelection={rowSelection} columns={columns} dataSource={interviewList} pagination={{ defaultPageSize: 10 }} />
            </TabPane>
            <TabPane tab='Scorecard' key='2'>
              <Table rowKey={scorecardList => scorecardList.id} rowSelection={rowSelection} columns={columns} dataSource={scorecardList} pagination={{ defaultPageSize: 10 }} />
            </TabPane>
          </Tabs>
        </div>
      </Row>
      {rowsData.length > 1 && (
        <Row style={{
          background: '#454545', width: '320px', height: '90px', display: "flex",
          flexDirection: 'column', justifyContent: 'center', borderRadius: '5px', margin: 'auto auto 100px auto', position: 'absolute', bottom: '0', left: '0', right: '0'
        }}>
          <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginBottom: '10px' }}>
            <Paragraph style={{ margin: '0', color: 'white' }}>
              <Icon type="check" style={{ marginRight: '5px' }} />
              {rowsData.length} Plantillas seleccionadas
            </Paragraph>
            <Icon type="close" style={{ color: 'white', fontSize: '21px' }} onClick={() => setRowsData({})} />
          </Col>
          <Col style={{ display: 'flex', justifyContent: 'center' }}>
            {/* <Button style={{ margin: '0px 5px 0px 0' }} icon="star" > Fijar como favoritos </Button> */}
            <Button onClick={() => bulkDelete(rowsData)} style={{ margin: '0px 0 0px 5px', color: '#FF4D4F' }} icon="delete"> Eliminar </Button>
          </Col>
        </Row>
      )}
    </Layout>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch
  };
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    token: state.auth.token,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomInterview);