import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from 'react-router-redux';
import { v4 as randomID } from 'uuid';

import * as actionCreators from "../../../../actions/data";
import '../InterviewKit.scss';
import ModalDelete from '../components/ModalDelete';
import EditCardList from '../components/EditCardList';

import { Row, Col, Breadcrumb, Input, Button, Tabs, Divider, Typography, Icon, Popover, Form, message, Layout } from "antd";

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs

const EditScorecard = (props) => {

  const getData = () => {
    let endpoint = `/api/v1/evaluations/interviewkit/${props.match.params.id}/`
    let token = props.token
    props.actions.fetchAndReturn(token, endpoint)
      .then(response => {
        if (response.status === 200) {
          setCardData({
            id: response.body.id,
            business_user: response.body.business_user,
            group: response.body.group,
            kit_type: response.body.kit_type,
            detail: response.body.detail
          })
        }
      })
  }
  useEffect(() => {
    getData()
  }, [])

  const [cardData, setCardData] = useState({
    id: '',
    business_user: '',
    group: '',
    kit_type: '',
    detail: []
  })
  const [newSkill, setNewSkill] = useState({
    text: '',
    skill: '',
    question_number: '',
    id: '',
    question_type: 'CARD',
    feedback: false
  })
  const [skillRemoved, setSkillRemoved] = useState({
    interview_kit_detail_id: ''
  })
  const [activatePost, setActivatePost] = useState(false)
  const [activateEdit, setActivateEdit] = useState(false)
  const [activateDelete, setActivateDelete] = useState(false)
  const [showDelete, setShowDelete] = useState({
    state: false,
    idQuestion: ''
  })
  const [showEdit, setShowEdit] = useState(false)

  const handleInput = (e) => {
    if (newSkill.id === '') {
      setNewSkill({
        ...newSkill,
        text: e.target.value,
        id: randomID()
      })
    } else {
      setNewSkill({
        ...newSkill,
        text: e.target.value
      })
    }
  }
  const addNewSkill = () => {
    if(newSkill.text !== '') {
      setCardData({
        ...cardData,
        detail: [...cardData.detail, newSkill]
      })
    }
    return;
  }
  const cancelPost = () => {
    setActivatePost(false)
    setNewSkill({
      ...newSkill,
      text: '',
      id: ''
    })
  }
  const cancelNewSkill = () => {
    cleanNewSkillInput()
    setActivatePost(false)
  }
  const editSkill = (index, idSkill) => {
    const { id, text } = cardData.detail[index]
    const filterEditCard = cardData.detail.filter(item => item.id !== idSkill)
    setShowEdit(true)
    setActivateEdit(true)
    setNewSkill({
      ...newSkill,
      text: text,
      id: id,
    })
    setCardData({
      ...cardData,
      detail: filterEditCard
    })
  }
  const editSkillConfirm = () => {
    setCardData({
      ...cardData,
      detail: [...cardData.detail, newSkill]
    })
  }
  const cancelEditSkill = () => {
    setNewSkill({
      ...newSkill,
      text: '',
      question_number: '',
      id: '',
    })
    setActivateEdit(false)
    setShowEdit(false)
  }
  const duplicateSkill = (index) => {
    setNewSkill({
      ...newSkill,
      text: cardData.detail[index].text,
      id: randomID()
    })
    setActivatePost(true)
  }
  const cleanNewSkillInput = () => {
    if (activateEdit) {
      setCardData({
        ...cardData,
        detail: [...cardData.detail, newSkill]
      })
      setShowEdit(false)
      setActivateEdit(false)
    }
    setNewSkill({
      ...newSkill,
      text: '',
      id: ''
    })
  }
  const confirmDelete = (idQuestion) => {
    const filterSkill = cardData.detail.filter(item => item.id === idQuestion)
    setSkillRemoved({
      interview_kit_detail_id: filterSkill[0].id
    })
    setActivateDelete(true)
  }
  const cancelDelete = () => {
    setShowDelete(false)
    setActivateDelete(false)
    setSkillRemoved({ interview_kit_detail_id: '' })
  }
  const redirect = () => {
    props.dispatch(push('/account/interviewkit'))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const { id, group, kit_type, detail } = cardData
    const endpoint = `/api/v1/evaluations/interviewkit_questions/${id}/`
    let token = props.token

    if (activatePost) {
      let questionData = detail.map((value, index) => {
        return { ...value, question_number: index, question_type: 'CARD', feedback: false }
      })
      let body = { questions: [questionData[questionData.length - 1]] }
      try {
        if(newSkill.text !== '') {
          props.actions.fetchAndReturn(token, endpoint, 'post', body)
          .then(response => {
            if (response.status === 200) {
              cancelPost()
              message.success('Habilidad agregada correctamente')
            }
          })
        } else {
          message.error('El campo "Agregar nueva habilidad" no puede estar vacio')
        }
      } catch (error) {
        console.log(error)
      }
    }
    if (activateDelete) {
      let body = { questions: [skillRemoved] }
      const questionFilter = cardData.detail.filter(item => item.id !== skillRemoved.interview_kit_detail_id)
      try {
        props.actions.fetchAndReturn(token, endpoint, 'delete', body)
          .then(response => {
            if (response.status === 200) {
              message.success('Habilidad eliminada correctamente')
              setCardData({
                ...cardData,
                detail: questionFilter
              })
              setShowDelete(false)
              setActivateDelete(false)
            }
          })
      } catch (error) {
        console.log(error)
      }
    }
    if (activateEdit) {
      let detailData = detail.map((value, index) => {
        return { ...value, question_number: index, question_type: 'CARD', feedback: false, interview_kit_detail_id: value.id }
      })
      let body = { name: group, kit_type: kit_type, questions: detailData }
      try {
        if(newSkill.text !== '') {
          props.actions.fetchAndReturn(token, endpoint, 'put', body)
          .then(response => {
            if (response.status === 200) {
              message.success('Habilidad editada correctamente')
              cancelEditSkill()
            }
          })
        } else {
          message.error('El campo "Editar habilidad" no puede estar vacio')
        }
      } catch (error) {
        console.log('Error -->', error)
      }
    }
  }

  return (
    <Layout className='layoutCustomInterview'>
      <Row className='beadcrumNav'>
        <Col>
          <Breadcrumb separator=">">
            <Breadcrumb.Item href="/processes">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/tools">Mis herramientas</Breadcrumb.Item>
            <Breadcrumb.Item href="/account/interviewkit">Entrevistas Personalizadas</Breadcrumb.Item>
            <Breadcrumb.Item href={`/account/interviewkit/edit/scorecard/${props.match.params.id}`}>Plantilla Predeterminada</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row className='navTitle' gutter={20}>
        <Col>
          <Icon onClick={() => redirect()} type='arrow-left' style={{ fontSize: '25px' }} />
        </Col>
        <Col>
          <Title level={1}>Plantilla Predeterminada</Title>
        </Col>
      </Row>
      <Row className='infoSection'>
        <Col>
          <Paragraph>Desde esta sección podrás configurar las habilidades que solicites para tus candidatos.</Paragraph>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit}>
        <Row>
          <div className='newInterviewTable'>
            <Tabs>
              <TabPane key='1' tab='Vista de Edición'>
                <Row style={{ margin: '0px 20px' }}>
                  <Col>
                    <Title style={{ color: '#5E5E5E' }} level={4}>Tipo de Entrevista Personalizada</Title>
                  </Col>
                  <Col>
                    <Paragraph>Scorecard</Paragraph>
                  </Col>
                </Row>
                <Row style={{ margin: '20px 20px' }}>
                  <Col>
                    <Title level={4} style={{ color: '#5E5E5E' }} >Nombre de la Plantilla</Title>
                  </Col>
                  <Col>
                    <Input value={cardData.group} disabled size='large' />
                  </Col>
                </Row>
                {activatePost || showEdit ? (
                  <Row className='formNewQuestions' style={{ margin: '20px 20px' }}>
                    <Col style={{ marginRight: '40px' }}>
                      {showEdit ? (
                        <Title level={4} style={{ color: '#5E5E5E' }} >
                          Editar habilidad
                        </Title>
                      ) : (
                        <Title level={4} style={{ color: '#5E5E5E' }} >
                          Agregar nueva habilidad
                        </Title>
                      )}
                      <Input name='text' value={newSkill.text} onChange={handleInput} size='large' />
                    </Col>
                    <Col>
                      <Title level={4} style={{ color: '#5E5E5E' }} >Acciones</Title>
                      <Button icon='delete' onClick={() => cleanNewSkillInput()} style={{ border: 'none', color: '#A4A4A4', boxShadow: 'none' }} />
                    </Col>
                  </Row>
                ) : null}
                <Divider />
                <EditCardList list={cardData.detail} fnEdit={editSkill} fnCopy={duplicateSkill} fnDelete={setShowDelete}/>
                <Row type="flex" align="middle" style={{ margin: '20px 20px' }}>
                  <Col>
                    {!showEdit && !activatePost ? (
                      <Button onClick={() => setActivatePost(true)} icon='plus-circle' style={{ color: '#F175A5', border: 'none', boxShadow: 'none' }} >Agregar Habilidad</Button>
                    ) : null}
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
          </div>
        </Row>
        {showEdit && (
          <Row className='actionsNewInterview'>
            <Col style={{ marginRight: '20px' }}> <Button onClick={() => cleanNewSkillInput()}>Cancelar</Button> </Col>
            <Col> <Button htmlType='submit' onClick={() => editSkillConfirm()} style={{ background: '#F175A5', color: 'white' }}>Editar habilidad</Button> </Col>
        </Row>
        )}
        {activatePost && (
          <Row className='actionsNewInterview'>
            <Col style={{ marginRight: '20px' }}> <Button onClick={() => cancelNewSkill()}>Cancelar</Button> </Col>
            <Col> <Button htmlType='submit' onClick={() => addNewSkill()} style={{ background: '#F175A5', color: 'white' }}>Agregar habilidad</Button> </Col>
          </Row>
        )}
        {!showEdit && !activatePost && !showDelete.state && (
          <Row className='actionsNewInterview'>
            <Col> <Button onClick={() => redirect()} style={{ background: '#F175A5', color: 'white' }}>Volver</Button> </Col>
          </Row>
        )}
        <ModalDelete showDelete={showDelete} cancelDelete={cancelDelete} confirmDelete={confirmDelete} type={'habilidad'} />
      </Form>
    </Layout>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch
  };
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    token: state.auth.token,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditScorecard);