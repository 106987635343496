import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../../../../actions/data';
import MceEditor from '../../../../../BusinessDashboard/NewProcessFormV2/components/mceeditor/MceEditor';
import DragAndDrop from '../../../../../Common/DragAndDrop';
import VideoInterviewExample from './components/Example/Example';
import SingleQuestion from './components/SingleQuestion/SingleQuestion';

import {
  Form,
  Button,
  Tabs,
  Typography,
  Icon,
  message,
  Row,
  Col
} from 'antd';
import './VideoInterview.scss';

let id = 3;
const { TabPane } = Tabs;
const { Text } = Typography;
const successMessage = '<p style="color: #ea4c89;">¡Felicitaciones!</p> <p>Has avanzado a la siguiente fase del proceso. Ahora deberás responder, a través de una grabación en video, unas breves preguntas que [companyName] ha preparado para conocerte mejor.</p><p style="color: #ea4c89;">Ten en cuenta que:</p><ul><li>Solo tienes una oportunidad para responder la video entrevista.</li><li>Debes contar con una buena y estable conexión a Internet.</li><li>Recomendamos utilizar el navegador Google Chrome actualizado.</li><li>Si estas usando un dispositivo iPhone/iPad, usar Safari.</li>';
const getItems = count => Array.from({ length: count }, (v, k) => k).map((k) => k);
const newQn = { text: '', time: 30 }

class VideoInterview extends React.Component {

  state = {
    keys: [0, 1, 2],
    loading: false,
    currentTab: '1',
    editable: true,
    message: successMessage,
    formData: {
      interview: {
        businessName: '',
        processName: '',
        businessId: '',
        logo: '',
        interviewTxt: successMessage,
      },
      questions: Array(3).fill(newQn)
    }
  }

  remove = k => {
    const { form } = this.props
    const keys = form.getFieldValue('keys')
    const questions = form.getFieldValue('questions')
    
    if (keys.length === 1) {
      return;
    }

    form.setFieldsValue({
      keys: keys.filter(key => key !== k),
    });
  };

  add = () => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(id++);

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        questions: [...prevState.formData.questions, newQn]
      }
    }))

    form.setFieldsValue({
      keys: nextKeys,
    }, () => window.scrollTo(0, document.body.scrollHeight));
  };

  handleQuestionChange = () => {
    const { form, editedVideo } = this.props
    const keys = form.getFieldValue('questions')

    if (!keys.every(key => key === undefined) || !keys.every(key => key === "")) {
      editedVideo(true);
    }
  }

  // mensaje para el candidato!
  handleEditorChange = (fieldName) => (event) => {
    this.setState({ [fieldName]: event.target.value });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const { keys, questions, times } = values;
        this.setState({ loading: true });

        let formData = {
          interview: {
            businessName: this.props.businessName,
            processName: this.props.jobApplication.job_application,
            businessId: this.props.jobAppId,
            logo: this.props.logo,
            interviewTxt: this.state.message,
          },
          questions: []
        }
        keys.forEach((key) => {
          formData.questions.push({
            text: questions[key],
            time: parseInt(times[key])
          })
        })

        this.setState({ formData });
        const videoStages = [
          {'code': 'V_INT', 'name': 'Video entrevista'},
          {'code': 'V_INTR','name': 'Video entrevista terminada'},
          {'code': 'V_INTE', 'name': 'Video entrevista evaluada'},
        ];
        let stages = this.props.jobApplication.stages;
        const doneStageIndex = stages.stages.findIndex(x => (x.code === 'DONE'));
        stages.stages.splice(doneStageIndex + 1, 0, videoStages[0])
        stages.stages.splice(doneStageIndex + 2, 0, videoStages[1])
        stages.stages.splice(doneStageIndex + 3, 0, videoStages[2])

        try {
          let resp = await fetch(`${process.env.REACT_APP_VIDEO_URL}/api/interviews`, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Token ${this.props.token}`
            },
            body: JSON.stringify(formData),
          });
          resp = await resp.json();
          const url = `gamesandtests/jobapplications/${this.props.jobAppId}/`;
          const submitData = JSON.stringify({
            interview_id: resp.id,
            stages
          });
          this.props.actions.sendData(this.props.token, url, submitData, 'PATCH');
          this.props.addStage(stages['stages'])
          this.props.updateInterviewId(resp.id);
          this.setState({ loading: false, success: true, editable: false });
          this.props.createdVideo();
          message.success('Video entrevista creada correctamente')
        } catch (err) {
          this.setState({ loading: false });
          console.error('Ocurrió un error', err);
          message.error('Ocurrió un error creando la video entrevista. Por favor inténtalo nuevamente.', 4);
        }
      } else {
        if (this.state.currentTab === '2') {
          message.error('Debes completar el formulario de preguntas para crear la video entrevista.', 4);
        }
      }
    });
  };

  async componentDidUpdate() {
    if (!this.state.message && this.props.jobApplicationName && this.props.businessName) {
      let interviewText = `<p style="color: #ff008a;">¡Felicitaciones!</p>
            <p>Has avanzado a la siguiente fase del proceso: ${this.props.jobApplication.job_application} de ${this.props.businessName}. <br/>Ahora deberás responder, a través de una grabación en video, unas breves preguntas que ${this.props.businessName} ha preparado para conocerte mejor.</p>
            <p style="color: #ff008a;">Ten en cuenta que:</p>
            <ul>
            <li>Solo tienes una oportunidad para responder la video entrevista.</li>
            <li>Debes contar con una buena y estable conexión a Internet.</li>
            <li>Recomendamos utilizar el navegador Google Chrome actualizado.</li>
            <li>Si estas usando un dispositivo iPhone/iPad, usar Safari.</li>
            </ul>`
      this.setState({ message: interviewText })
    }
  }

   async componentDidMount() {
    const message = `<p style="color: #ea4c89;">¡Felicitaciones!</p> <p>Has avanzado a la siguiente fase del proceso: ${this.props.jobApplication.job_application}. Ahora deberás responder, a través de una grabación en video, unas breves preguntas que ${this.props.businessName} ha preparado para conocerte mejor.</p><p style="color: #ea4c89;">Ten en cuenta que:</p><ul><li>Solo tienes una oportunidad para responder la video entrevista.</li><li>Debes contar con una buena y estable conexión a Internet.</li><li>Recomendamos utilizar el navegador Google Chrome actualizado.</li><li>Si estas usando un dispositivo iPhone/iPad, usar Safari.</li>`
    this.setState({ message })
    if(this.props.jobApplication.duplicate_video_interview && !this.props.interviewId) {
      try {
        let resp = await fetch(`${process.env.REACT_APP_VIDEO_URL}/api/interviews/${this.props.jobApplication.duplicate_video_interview}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Token ${this.props.token}`
          },
        });
        resp = await resp.json();
        let data = { ...this.state.formData };
        data.interview.processName = resp.processName;
        data.questions = resp.questions;
        this.setState({ formData: data, editable: true, keys: getItems(data.questions.length) });
      } catch (err) {
        console.error('Ocurrió un error', err);
      }
    }
    if (this.props.interviewId && this.state.editable) {
      try {
        let resp = await fetch(`${process.env.REACT_APP_VIDEO_URL}/api/interviews/${this.props.interviewId}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Token ${this.props.token}`
          },
        });
        resp = await resp.json();
        let data = { ...this.state.formData };
        data.interview.processName = resp.processName;
        data.questions = resp.questions.map(q => {
          return {
            text: q.text,
            time: q.time
          }
        });
        this.setState({ formData: data, editable: false, keys: getItems(data.questions.length), message: resp.interviewTxt });
      } catch (err) {
        console.error('Ocurrió un error', err);
      }
    }
  }

  onChangeTab = (newTab) => {
    this.setState({ currentTab: newTab })
  }

  render() {
    const { questions } = this.state.formData;
    const { getFieldDecorator, getFieldValue } = this.props.form;

    getFieldDecorator('keys', { initialValue: this.state.keys });
    const keys = getFieldValue('keys');
    
    return (
      <div className='vi'>
        <VideoInterviewExample />

        <Form onSubmit={this.handleSubmit} layout='vertical'>
          <Tabs animated={false} onChange={this.onChangeTab}>
            <TabPane tab="Preguntas" key="1">
              <DragAndDrop
                ko
                items={keys}
                render={(key, index) => (
                  <SingleQuestion
                    key={index}
                    index={index}
                    keys={keys}
                    item={key}
                    qn={questions[index]}
                    remove={this.remove}
                    editable={this.state.editable}
                    onChange={this.handleQuestionChange}
                    getFieldDecorator={getFieldDecorator}
                  />
                )}
              />

              {this.state.editable &&
                <Form.Item style={{ margin: '0 0 80px 0' }}>
                  <Button
                    className='add-btn'
                    icon='plus-circle'
                    type="link"
                    onClick={this.add}
                  >
                    Agregar pregunta
                  </Button>
                </Form.Item>
              }
            </TabPane>

            <TabPane tab="Correo al candidato" key="2">
              <Row>
                <Col xs={24} lg={22}>
                  <Form.Item label="Este mensaje se enviará al correo del candidato para avisar que ha sido seleccionado a video entrevista">
                    <MceEditor 
                      handleChange={this.handleEditorChange}
                      disabled={!this.state.editable}
                      body={this.state.message}
                      readOnlyBody={this.state.message}
                      name='message'
                    />
                  </Form.Item>
                </Col>
              </Row>
            </TabPane>
          </Tabs>

          {this.state.editable &&
            <footer>
              <div className='wrap'>
                <Text>
                  <Icon type="info-circle"/> Recuerda que una vez creada la video entrevista no se podrá editar
                </Text>
                <Button
                  type='default'
                  htmlType='submit'
                  loading={this.state.loading}
                >
                  Crear video entrevista
                </Button>
              </div>
            </footer>
          }
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    interviewId: state.jobapp.jobApplication.interview_id,
    jobApplicationName: state.jobapp.jobApplication.job_application,
    businessName: state.auth.currentBusiness.name,
    logo: state.auth.logoURL,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch
  };
};

const VideoInterviewForm = Form.create()(VideoInterview);
export default connect(mapStateToProps, mapDispatchToProps)(VideoInterviewForm);