import React, { useState, useEffect } from "react";
import {
  Button,
  Icon,
  Row,
  Col,
  message,
  Modal,
  Progress,
  Typography,
} from 'antd';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as actionCreators from '../../../../actions/data';

import InputField from "./Fields/InputField";
import SingleAnswer from "./Fields/SingleAnswer";
import MultiAnswer from "./Fields/MultiAnswer";
import Likert from "./Fields/Likert";
import Disc from "./Fields/Disc";
import ImageField from "./Fields/ImageField";
import ReadOnlyField from "./Fields/ReadOnly";
import Situational from "./Fields/Situational";
import ParseHtml from "../../../../components/parseHtml";

import LastPage from "./LastPage";
import Countdown from "./commons/Countdown";

const { confirm } = Modal;

const fieldMeetsCondition = (values) => (field) => {
  if (field.conditional && field.conditional.field) {
    const segments = field.conditional.field.split("_");
    const fieldId = segments[segments.length - 1];
    return values[fieldId] === field.conditional.value;
  }
  return true;
};

const Form = ({ formData, globalTime, state, cuteId, assActPP, token, actions, backToPath, jobappId, submitEndpoint, name }) => {
  // state to track the current page ID of the form
  const [page, setPage] = useState(0);
  const [progressPercent, setProgressPercent] = useState(0);
  // state to track the current form data that will be displayed
  const [currentPageData, setCurrentPageData] = useState(formData[page]);
  const [currentFields, setCurrentFields] = useState([]);

  // track the values of the form fields
  const [values, setValues] = useState({});

  // obj with required fields {_uid: value}, if at least one value is true disable next page
  const [disableNextPage, setDisableNextPage] = useState({});
  const [disableNextButton, setDisableNextButton] = useState(true);

  // Alert the user before closing tab, react-router-dom Prompt manage the back button behaviour
  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);
  /**
   * Fill the answers array
   */
  useEffect(() => {
    let currentValues = {};
    // reduce all values in the current page

    formData.forEach(pageData => {
      const newValues = pageData.fields.reduce((obj, field) => {
        // If values[id] undefined, fill with blank
        if (field.component === "multiple_answers") {
          obj[field._uid] = new Array(field.options.length).fill('');
        }
        else if (field.component === "disc") {
          obj[field._uid] = ['d', 'i', 's', 'c'].map(elem => ({ type: elem, value: 0 }));
        }
        else if (field.component === "read_only") {
          // Do nothing
        }
        else if (field.component === "SJT_GW") { 
          obj[field._uid] = { best: '', worst: '' };
        } else {
          obj[field._uid] = "";
        }
        return obj;
      }, {});
      // Set the update values
      currentValues = { ...currentValues, ...newValues };
    })
    setValues(currentValues);
  }, [formData]);

  // Page change, update form
  useEffect(() => {
    const fields = currentPageData.fields
    setCurrentFields(fields);
  }, [currentPageData]);

  // everytime a value changes check if a conditional page/field it's showed
  useEffect(() => {
    const fields = currentPageData.fields.filter(fieldMeetsCondition(values));
    // Don't trigger aditional renders if no fields where added or removed
    if (fields.length !== currentFields.length) {
      setCurrentFields(fields);
    }
  }, [currentPageData, currentFields.length, values]);

  // everytime we change the page, the current fields are updated, update the required fields, and the next button state
  useEffect(() => {
    const requiredFields = {};
    formData[page]?.fields?.forEach(obj => {
      requiredFields[obj._uid] = obj.required !== undefined ? obj.required : false;
    });
    setDisableNextPage(requiredFields);
  }, [page]);

  // this effect will run when the `page` changes
  useEffect(() => {
    if (page >= 0) {
      const upcomingPageData = formData[page];
      setCurrentPageData(upcomingPageData);
    }
    // Last page has idx -1
    else {
      const lastPage = {
        component: "last_page",
        label: "",
        fields: []
      }
      setCurrentPageData(lastPage);
      // Go to last page and send the data
      submitAnswers()
    }
  }, [page, formData]);

  /**
   * Count all pages without conditionals
   */
  const countPages = () => {
    let total = 0;
    formData.forEach(obj => {
      if (obj.conditional === undefined) {
        total = total + 1;
      }
    })
    return total;
  }


  // callback provided to components to update the main list of form values
  const fieldChanged = (fieldId, value) => {
    // use a callback to find the field in the value list and update it
    setValues((currentValues) => {
      currentValues[fieldId] = value;
      return currentValues;
    });

    // TODO remove or replace with a use effect. 
    // This just fakes that we've updated the `currentPageData` to force a re-render in React and shows the conditional field
    setCurrentPageData((currentPageData) => {
      return Object.assign({}, currentPageData);
    });
  };

  // callback provided to components to update the main list of form values
  const multiAnswersFieldChanged = (fieldId, index, value) => {
    // use a callback to find the field in the value list and update it)
    setValues((currentValues) => {
      currentValues[fieldId][index] = value;
      return currentValues;
    });

    // TODO update this. This just fakes that we've updated the `currentPageData` to force a re-render in React
    //setCurrentPageData((currentPageData) => {
    //  return Object.assign({}, currentPageData);
    //});
  };

  const discFieldChanged = (fieldId, values) => {
    // use a callback to find the field in the value list and update it
    setValues((currentValues) => {
      currentValues[fieldId] = values;
      return currentValues;
    });
  };


  const findNextPage = (page, direction) => {
    if (formData.length === page) {
      return -1;
    }
    const upcomingPageData = formData[page];
    if (upcomingPageData.conditional && upcomingPageData.conditional.field) {
      // we're going to a conditional page, make sure it's the right one
      const segments = upcomingPageData.conditional.field.split("_");
      const fieldId = segments[segments.length - 1];

      const fieldToMatchValue = values[fieldId];

      if (fieldToMatchValue !== upcomingPageData.conditional.value) {
        // if we didn't find a match, try the next page
        return findNextPage(direction === "next" ? page + 1 : page - 1, direction);
      }
    }
    return page;
  };

  const navigatePages = (direction) => () => {
    setDisableNextButton(true);

    if (direction === 'last') {
      setPage(-1);
      //setProgressPercent(countPages());
      return;
    }
    const tmpPage = findNextPage(direction === "next" ? page + 1 : page - 1, direction);

    if (tmpPage === -1) {
      finishCute();
    }
    else {
      setDisableNextButton(true);
      setPage(tmpPage);
      setProgressPercent(direction === "next" ? progressPercent + 1 : progressPercent - 1);
    }
  };

  const nextPage = navigatePages("next");
  const prevPage = navigatePages("prev");
  const toLastPage = navigatePages('last');

  const onSubmit = (e) => {
    e.preventDefault();
    // data is send on close and before showing the last page
  };

  const mapFields = (field) => ({
    "read_only": (
      <ReadOnlyField
        key={field._uid}
        paragraphs={field.paragraphs}
      />
    ),
    "single_answer": (
      <SingleAnswer
        key={field._uid}
        field={field}
        fieldChanged={fieldChanged}
        value={values[field._uid]}
        disableNextPage={disableNextPageHandler}
      />
    ),
    "multiple_answers": (
      <MultiAnswer
        key={field._uid}
        field={field}
        fieldChanged={multiAnswersFieldChanged}
        values={values[field._uid]}
        disableNextPage={disableNextPageHandler}
      />
    ),
    "input": (
      <InputField
        key={field._uid}
        field={field}
        fieldChanged={fieldChanged}
        value={values[field._uid]}
        disableNextPage={disableNextPageHandler}
      />
    ),
    "likert": (
      <Likert
        key={field._uid}
        field={field}
        fieldChanged={fieldChanged}
        value={values[field._uid]}
        disableNextPage={disableNextPageHandler}
      />
    )
    ,
    "disc": (
      <Disc
        key={field._uid}
        field={field}
        fieldChanged={discFieldChanged}
        values={values[field._uid]}
        disableNextPage={disableNextPageHandler}
      />
    ),
    "SJT_GW": (
      <Situational 
        key={field._uid}
        field={field}
        fieldChanged={fieldChanged}
        values={values[field._uid]}
        disableNextPage={disableNextPageHandler}
      />
    )

  });

  const checkPrevButton = () => {
    // If page > 0, previous page has no timer and current time has no timer, or last page. Show button, otherwise hide it
    return (page > 0 && formData[findNextPage(page - 1, 'prev')].time === undefined && formData[page].time === undefined)
  }

  const selectTimer = () => {
    // If page > 0, previous page has no timer and current time has no timer, or last page. Show button, otherwise hide it
    if (globalTime) {
      return <Countdown callback={() => toLastPage()} time={globalTime} color={state.color} type={'global'} />
    }
    else {
      return (currentPageData.time ?
        <Countdown callback={() => nextPage()} time={currentPageData.time} color={state.color} type={'page'} />
        :
        <></>)
    }
  }

  /**
   * if at least one of the required fields dont has an answer (disableNextPage[key] == true) block next button
   * @returns 
   */
  useEffect(() => {
    if (Object.keys(disableNextPage) === 0) {
      setDisableNextButton(true);
    }
    else {
      const disable = Object.keys(disableNextPage).some(key => disableNextPage[key] === true);
      setDisableNextButton(disable);
    }
  }, [disableNextPage])

  const disableNextPageHandler = (fieldId, val) => {
    setDisableNextPage(oldState => ({ ...oldState, [fieldId]: val }));
  }

  const formatQuestions = () => {
    let questionsDict = {};
    // reduce all values in the current page
    formData.forEach(pageData => {
      if (pageData.fields !== undefined) {
        const newValues = {}
        pageData.fields.forEach(obj => {
          if (obj.component !== 'read_only') {
            if (obj.type === 'SJT_GW') {
              newValues[obj._uid] = { 
                ...obj, 
                title: pageData.label,
                description: pageData.description,
              }
            } else {
              newValues[obj._uid] = obj
            }
          }
        })

        questionsDict = { ...questionsDict, ...newValues };
      }
    })
    return questionsDict;
  }

  const formatAnswers = (questions) => {
    let answersDict = {...values};
    Object.keys(questions).forEach(question => {
      if (questions[question].component === 'multiple_answers'){
        // remove all '' values
        answersDict[question] = answersDict[question].filter(val => val !== "")
      }
    })
    return answersDict;
  }

  const submitAnswers = (customStatus = null) => {
    const endpoint = submitEndpoint ? submitEndpoint : `/api/v1/gamesandtests/customtests/${cuteId}/answers/`;
    const questions = formatQuestions();
    const formattedAnswers = formatAnswers(questions);
    actions.fetchAndReturn(token, endpoint, 'POST', { answers: formattedAnswers, questions: questions, jobapp_id: jobappId })
      .then(response => {
        if (assActPP) {
          const progessEndpoint = `/api/v1/gamesandtests/personalprogress/${assActPP}/`
          if (response.status >= 400 || response.status === null) {
            message.error('Error al enviar los datos. Por favor, inténtalo nuevamente', 5);
            // If error, try to set the personal progress status
            actions.fetchAndReturn(token, progessEndpoint, 'PATCH', { status: 'BLOCKED' })
          }
          else {
            const patchStatus = customStatus === null ? 'FINISHD' : customStatus;
            actions.fetchAndReturn(token, progessEndpoint, 'PATCH', { status: patchStatus })
          }
        }
      })
  };

  const closeCute = () => {
    // If submitEndpoint not null we are using cute survey and there is no need to confirm
    if (submitEndpoint){
      backToPath();
    }
    else {
      confirm({
        title: 'Confirma para abandonar esta prueba',
        content: 'Parte de tu progreso se perderá',
        onOk() {
          submitAnswers('BLOCKED');
          backToPath();
        },
      });
    };
  }

  const finishCute = () => {
    confirm({
      title: 'Confirma para finalizar esta prueba',
      content: <><p>A continuación se guardarán tus resultados.</p><p>Haz clic en "OK" para finalizar tu prueba</p></>,
      onOk() {
        setPage(-1);
        setProgressPercent(progressPercent + 1);
      },
      cancelText: 'Cancelar'
    });
  }

  return (
    <div>
      <Row type='flex' align='middle' justify='center' style={{ minHeight: '10vh' }}>
        <Col xs={4} sm={4} md={2} lg={2} >
          <Button type="link" icon='close' onClick={closeCute} />
        </Col>
        <Col xs={16} sm={16} md={14} lg={14} >
          <Progress
            strokeColor={state.color}
            percent={progressPercent >= 0 ? 100 / countPages() * progressPercent : 100}
            format={(percent) => `${Math.round(percent / (100 / countPages()))}/${countPages()}`}
          />
        </Col>
        <Col xs={4} sm={4} md={2} lg={2} >
          {
            selectTimer()
          }
        </Col>
      </Row>
      {
        page === -1
          ?
          <Row 
            type='flex' 
            align='middle' 
            justify='center' 
            style={{ 
              minHeight: '90vh', 
              backgroundImage: "url(https://genoma-assets.s3.us-east-2.amazonaws.com/confetti.png)" 
            }}
          >
            <LastPage buttonColor={state.color} backToPath={backToPath} name={name}/>
          </Row>
          :
          <Row type='flex' align='middle' justify='center' style={{ minHeight: '90vh' }}>
            <Col xs={22} sm={22} md={14} lg={14}>
              <form onSubmit={onSubmit}>
                <Row type='flex'>
                  <Col span={24}>
                    {currentPageData.fields[0].component === 'SJT_GW' ? 
                      <Row style={{ marginBottom: 20 }}>
                        {currentPageData.image && 
                        <>
                          <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', marginBottom: 20 }}>
                            <img alt={'img-header'} style={{ maxWidth: '100%', height: 'auto' }} src={currentPageData.image.url} />
                          </div>
                        </>}
                        <Typography.Title level={4} style={{ marginBottom: 20 }}>
                          {ParseHtml(currentPageData.label)}
                        </Typography.Title>
                        <Typography.Text>
                          {currentPageData.description}
                        </Typography.Text>
                      </Row>
                      : 
                      <>
                        <Typography.Title level={4} style={{ marginBottom: 20 }}>
                          {ParseHtml(currentPageData.label)}
                        </Typography.Title>
                        {currentPageData.img && <ImageField src={currentPageData.img} />}
                      </>
                    }
                    {
                      currentFields.map((field) => mapFields(field)[field.component])
                    }
                  </Col>
                </Row>
                <div style={{ display: 'flex', marginTop: 20 }}>
                  <div>
                    {
                      checkPrevButton() ?
                        <Button className={'cute-button-prev'} style={{ borderColor: state.color }} onClick={prevPage}>
                          <Icon type="left" style={{ color: state.color }} />
                        </Button>
                        :
                        null
                    }
                  </div>
                  <div style={{ width: '100%' }}>
                    {
                      page !== - 1
                      &&
                      <Button
                        className={disableNextButton ? 'cute-button-disabled' : 'cute-button-primary'}
                        style={{ borderColor: disableNextButton ? '#d9d9d9' : state.color, backgroundColor: disableNextButton ? '#FFFFFF' : state.color }}
                        onClick={nextPage}
                        disabled={disableNextButton}
                      >
                        Siguiente
                      </Button>
                    }
                  </div>
                </div>
              </form >
            </Col>
          </Row>
      }
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
    name: state.auth.name || state.auth.userName,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(Form);

