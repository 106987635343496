import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Modal, Button } from 'antd';

import FullReport from './pdfReports/FullReport';

const ModalPdf = ({ showModal, setShowModal }) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })

  return (
    <Modal
      title={
        <Button
          type='primary'
          onClick={() => { handlePrint() }}
        >
          Descargar reporte
        </Button>
      }
      visible={showModal}
      footer={null}
      onCancel={() => { setShowModal(false) }}
      centered
      width={850}
    >
      <FullReport refPrint={componentRef} />
    </Modal>
  );
}
 
export default ModalPdf;