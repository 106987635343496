import React, { useState, useEffect } from 'react';
import {
  Typography,
  Icon,
  Breadcrumb,
  Input,
  message,
} from 'antd';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../actions/data';

import ParseHtml from '../../components/parseHtml';
import GenominSpin from '../../components/GenominSpin/GenominSpin';

import debounce from 'lodash.debounce';
import QuestionsModal from './QuestionsModal';
import './GenomaTests.scss';

const { Text, Title } = Typography;

const GenomaTests = ({ history, actions, auth }) => {
  const [genomaTests, setGenomaTests] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [filteredTests, setFilteredTests] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTest, setSelectedTest] = useState(null);

  const debouncedSearch = debounce((value) => {
    setSearchValue(value);
  }, 500);

  useEffect(() => {
    const { token } = auth;
    setIsLoading(true);

    actions
      .fetchAndReturn(token, '/api/v1/gamesandtests/customtests/')
      .then((response) => {
        if (response.status === 200) {
          const tests = response.body.filter(
            (cute) =>
              cute.cute_type === 'KNOWLEDGE' &&
              !Object.keys(cute.questions).includes('cute_type')
          );

          setGenomaTests(tests);
          setFilteredTests(tests);
          setIsLoading(false);
        } else {
          throw new Error();
        }
      })
      .catch((error) => {
        message.error('Error al cargar las pruebas.');
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setFilteredTests(
      genomaTests.filter((test) =>
        test.title.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }, [searchValue]);

  return (
    <div className="genoma-tests-container">
      <Breadcrumb separator=">">
        <Breadcrumb.Item onClick={() => history.push('/')}>
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => history.push('/evaluations')}>
          Evaluaciones
        </Breadcrumb.Item>
        <Breadcrumb.Item>Pruebas de conocimientos</Breadcrumb.Item>
      </Breadcrumb>
      <Title level={2}>Pruebas de conocimientos</Title>
      <Text>Revisa las pruebas que Genomawork dispone para ti.</Text>
      <Input.Search
        placeholder="Buscar prueba"
        style={{ width: 'min(360px, 100%)' }}
        onChange={(e) => debouncedSearch(e.target.value)}
      />
      <div className="genoma-tests-body">
        <QuestionsModal test={selectedTest} setSelectedTest={setSelectedTest} />
        {isLoading ? (
          <GenominSpin />
        ) : (
        <>
          {filteredTests.length > 0 ? (
            filteredTests.map((test) => (
              <div className="genoma-tests-body__test" onClick={() => setSelectedTest(test)}>
                <div className="genoma-tests-body__test__header">
                  <Text strong>{test.title}</Text>
                </div>
                <div className="genoma-tests-body__test__content">
                  {test.description ? (
                    ParseHtml(test.description)
                  ) : (
                    <Text>Este test no posee descripción.</Text>
                  )}
                  {test.scores_config.questions_to_evaluate && 
                    <div className="div-with-icon">
                      <Icon type="bars" />
                      <Text>
                        {test.scores_config.questions_to_evaluate} preguntas
                      </Text>
                    </div>
                  }
                  <div className="div-with-icon">
                    <Icon type="clock-circle" />
                    <Text>
                      {test?.questions?.global_time ? test?.questions?.global_time/60 + ' minutos' : 'No define'}
                    </Text>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <Text style={{ color: '#a4a4a4' }}>
              No hay pruebas de conocimientos disponibles.
            </Text>
          )}
        </>)
      }
    
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GenomaTests);
