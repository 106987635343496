import React, { useRef, useState, useEffect } from "react";
import {
  Input,
} from 'antd';
import ParseHtml from "../../../../../components/parseHtml";

const InputField = ({ field, fieldChanged, type, value, disableNextPage }) => {
  const [state, setState] = useState('');
  const disableNextPageStable = useRef(disableNextPage);

  useEffect(() => {
    setState(value);
  }, [value]);

  useEffect(() => {
    if (state != '') {
      disableNextPageStable.current(field._uid, false);
    }
    else {
      disableNextPageStable.current(field._uid, true);
    }
  }, [state, field._uid]);

  return (
    <div key={field._uid} style={{marginBottom: 10}}>
      <div style={{marginBottom: 5, userSelect: 'none'}}>{ParseHtml(field.label)}</div>
      <Input.TextArea
        onPaste={(e)=>{
          e.preventDefault()
          return false;
        }} 
        onCopy={(e)=>{
          e.preventDefault()
          return false;
        }}
        autoSize={true}
        allowClear={true}
        type={type || field.component}
        id={field._uid}
        defaultValue={value || ''}
        name={field._uid}
        // when mount for the 1st time value is undefined 
        onChange={(e) => {
          // Notify the main state list of the new value
          fieldChanged(field._uid, e.target.value);
        }}
      />
    </div>
  );
};

export default InputField;