import React, { useEffect, useState, useRef } from 'react';

import PropTypes from 'prop-types';

const CustomProgress = ({
  size,
  progress,
  strokeWidth,
  circleOneStroke,
  circleTwoStroke,
}) => {
  const [offset, setOffset] = useState(0);
  const circleRef = useRef(null);
  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;

  useEffect(() => {
    const progressOffset = ((100 - progress) / 100) * circumference;
    setOffset(progressOffset);
  }, [setOffset, circumference, progress, offset]);

  return (
    <>
      <svg className="svg-progress" width={size} height={size}>
        <circle
          className="svg-progress-circle-bg"
          stroke={circleOneStroke}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
        />
        <circle
          className="svg-progress-circle"
          ref={circleRef}
          stroke={circleTwoStroke}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
        />
        <text
          x={`${center}`}
          y={`${center - 15}`}
          className="svg-progress-circle-text"
          style={{ fontWeight: 'normal', fontSize: '12px' }}
        >
          FIT
        </text>
        <text
          x={`${center}`}
          y={`${center + 15}`}
          className="svg-progress-circle-text"
        >
          {progress !== null ? `${progress}%` : 'ND'}
        </text>
      </svg>
    </>
  );
};

CustomProgress.propTypes = {
  size: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  strokeWidth: PropTypes.number.isRequired,
  circleOneStroke: PropTypes.string.isRequired,
  circleTwoStroke: PropTypes.string.isRequired,
};

export default CustomProgress;
