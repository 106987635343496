import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../../../../actions/data';

import moment from 'moment';
import 'moment/locale/es';
import ConnectIntegrationModal from './ConnectIntegrationModal';
import DisconnectIntegrationModal from './DisconnectIntegrationModal';
import NewCustomFieldModal from '../../../../../../components/NewCustomFieldModal';
import localeES from 'antd/es/date-picker/locale/es_ES';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Icon,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Tag,
  Tooltip,
  Typography,
  Radio,
  Modal,
  Upload,
} from 'antd';

import './JobDetail.scss';
import MceEditor from "../../mceeditor/MceEditor";

moment.locale('es');

const { Option } = Select;
const { Paragraph, Title } = Typography;
const dateFormat = 'DD/MM/YYYY';
const websites = ['youtube', 'vimeo'];

class JobDetail extends Component {

  constructor() {
    super()
    this.state = {
      processId: 0,
      visibleCalendar: false,
      videoUrl: '',
      connectModalVisible: false,
      disconnectModalVisible: false,
      connectIntegration: null,
      connectTitle: 'Conectar integración',
      newCustomFieldModalVisible: false,
      existingJobApplicationAdditionalFiles: undefined,
      customFields: undefined,
      businessCustomFieldsObj: undefined,
    };
  }

  componentDidMount() {
    const url = `/api/v1/gamesandtests/business/${this.props.currentBusinessId}/customfields/?field_type=JOBAPP`;
    this.props.actions.fetchAndReturn(this.props.token, url)
      .then((response) => {
        if (response.status < 400) {
          const businessCustomFields = response.body.results;
          const customFields = { ...this.props.jobapp.custom_fields };
          const businessCustomFieldsObj = {};

          for (let i = 0; i < businessCustomFields.length; i++) {
            const element = businessCustomFields[i];
            if (!Object.keys(customFields).includes(element.name_key)) {
              customFields[element.name_key] = null;
            } else if (element.value_type === 'file' && Object.keys(existingJobApplicationAdditionalFiles).includes(element.name_key)) {
              customFields[element.name_key] = existingJobApplicationAdditionalFiles[element.name_key];
            }
            businessCustomFieldsObj[element.name_key] = { ...element };
            delete businessCustomFieldsObj[element.name_key].name_key;
          }

          this.setState({ businessCustomFieldsObj, customFields });
        }
      });
  }

  componentWillReceiveProps(nextProps) {
    const { youtube_intro_video } = this.props.jobapp;

    if (nextProps.jobapp.youtube_intro_video !== youtube_intro_video && !this.state.videoUrl) {
      this.setState({ videoUrl: nextProps.jobapp.youtube_intro_video })
    }
  }

  validateVideoUrl = (url) => {
    if (url.includes('https://www.') && url.includes('.com')) {
      return true
    } else return false
  }

  onChangeVideo = (event) => {
    // TODO: REVISAR QUE URLS SE GUARDEN CORRECTAMENTE EN TODOS LOS CASOS
    // (YT, VIMEO, DROPBOX)
    const value = event.target.value;
    let embedlink = value;

    if (this.validateVideoUrl(value)) {
      if (value.includes('youtube')) {
        // Si url ingresada tiene "embed", significa (muy probablemente)
        // que ya está "parseada", por lo que se envía al back tal cual
        if (!value.includes('embed')) {
          // Si no tiene embed, se parsea
          let queryStrings;
          try {
            queryStrings = value.split('?')[1].split('&');
          } catch {
            queryStrings = []
          }
          const queryStringObj = {}
          queryStrings.forEach(qs => {
            const [key, value] = qs.split('=')
            queryStringObj[key] = value
          });
          embedlink = queryStringObj.v ? `https://www.youtube.com/embed/${queryStringObj.v}` : '';
        }
      }

      if (value.includes('vimeo')) {
        const id = value.split('vimeo.com/')[1]
        embedlink = `https://player.vimeo.com/video/${id}`
      }

      this.setState({ videoUrl: embedlink })

      this.props.handleChange("youtube_intro_video")(embedlink)
    } else {
      this.setState({ videoUrl: '' })
      this.props.handleChange("youtube_intro_video")('')
    }
  }

  formatDateFromDatabase = (date) => {
    if (typeof date !== 'undefined'){
      const [year, month, day] = date.split('-')
      return `${day}-${month}-${year}`
    }
  }

  setConnectModalVisible = (value, integration=null, title) => {
    this.setState({ connectIntegration: integration })
    this.setState({ connectModalVisible: value })
    this.setState({ connectTitle : title })
  }

  setDisconnectModalVisible = (value, integration=null) => {
    this.setState({ connectIntegration: integration })
    this.setState({ disconnectModalVisible: value })
  }

  normFile = (e) => {
    if (Array.isArray(e)) return e.slice(-1);
    return e && e.fileList.slice(-1);
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      jobapp,
      jobAppIntegrations,
      handleChange,
      integrations,
      dateIsPast,
      inputValue,
      inputVisible,
      handleClose,
      showInput,
      handleInputChange,
      handleInputConfirm,
      saveInputRef,
      tagsA,
      handleCustomFields,
    } = this.props;
    const { businessCustomFieldsObj, customFields, videoUrl } = this.state;
    let checked = false;
    let showJobSiteChecked = (!jobapp.is_seed && jobapp.jobs_site_global_availability);
    if (jobapp.jobs_site_availability != undefined) { checked = jobapp.jobs_site_availability };

    return (
      <Row className="npjd">
        <Col xs={24} lg={23}>
          <Form layout="horizontal">
            {showJobSiteChecked && (
              <Form.Item
                label="Cargo visible en el Portal de empleo"
                style={{ display: 'flex' }}
              >
                <Switch
                  size="small"
                  checked={checked}
                  onClick={handleChange('jobs_site_availability')}
                />
              </Form.Item>
            )}

            <Form.Item label="Nombre del cargo">
              {getFieldDecorator('name', {
                onChange: handleChange('job_application'),
                initialValue: jobapp.job_application,
                rules: [
                  {
                    required: true,
                    message: 'Por favor ingresa el nombre del cargo',
                  },
                ],
              })(<Input />)}
            </Form.Item>

            <Row gutter={20}>
              <Col xs={12}>
                <Form.Item label="País">
                  {getFieldDecorator('country', {
                    onChange: handleChange('location_country'),
                    initialValue: jobapp.location_country,
                    rules: [
                      {
                        required: true,
                        message: 'Por favor selecciona el país',
                      },
                    ],
                  })(
                    <Select initialValue={'CL'}>
                      <Option value={'AR'}>Argentina</Option>
                      <Option value={'BO'}>Bolivia</Option>
                      <Option value={'BR'}>Brasil</Option>
                      <Option value={'CL'}>Chile</Option>
                      <Option value={'CR'}>Costa Rica</Option>
                      <Option value={'CO'}>Colombia</Option>
                      <Option value={'EC'}>Ecuador</Option>
                      <Option value={'SV'}>El Salvandor</Option>
                      <Option value={'GT'}>Guatemala</Option>
                      <Option value={'HN'}>Honduras</Option>
                      <Option value={'ME'}>México</Option>
                      <Option value={'PAN'}>Panamá</Option>
                      <Option value={'PA'}>Paraguay</Option>
                      <Option value={'PE'}>Perú</Option>
                      <Option value={'DO'}>República Dominicana</Option>
                      <Option value={'UY'}>Uruguay</Option>
                      <Option value={'VE'}>Venezuela</Option>
                      <Option value={'INT'}>Internacional</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item label="Ciudad">
                  {getFieldDecorator('city', {
                    onChange: handleChange('location_city'),
                    initialValue: jobapp.location_city,
                    rules: [
                      {
                        required: true,
                        message: 'Por favor ingresa la ciudad',
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label="Departamento">
              {getFieldDecorator('departamento', {
                onChange: handleChange('department'),
                initialValue: jobapp.department,
                rules: [
                  {
                    required: true,
                    message: 'Por favor ingresa el departamento',
                  },
                  {
                    max: 49,
                    message: 'Máximo 50 caracteres',
                  },
                ],
              })(<Input maxLength={50} />)}
            </Form.Item>

            <Form.Item label="Idioma del proceso">
              {getFieldDecorator('idioma', {
                onChange: handleChange('language'),
                initialValue: jobapp.language,
                rules: [
                  {
                    required: true,
                    message: 'Por favor selecciona el idioma del proceso',
                  },
                ],
              })(
                <Select initialValue={'es'}>
                  <Option value={'es'}>Español</Option>
                  <Option value={'en'}>Inglés</Option>
                  <Option value={'pt'}>Portugués</Option>
                </Select>
              )}
            </Form.Item>

            <Form.Item label="Etiquetas">
              {typeof tagsA !== 'undefined' &&
                tagsA.map((tag, index) => {
                  const isLongTag = tag.length > 20;
                  const tagElem = (
                    <Tag
                      key={tag}
                      closable
                      onClose={() => handleClose(tag)}
                      style={{ marginBottom: '10px' }}
                    >
                      {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                    </Tag>
                  );
                  return isLongTag ? (
                    <Tooltip title={tag} key={tag}>
                      {tagElem}
                    </Tooltip>
                  ) : (
                    tagElem
                  );
                })}

              {!inputVisible && (
                <Tag
                  onClick={showInput}
                  style={{ background: '#fff', borderStyle: 'dashed' }}
                >
                  <Icon type="plus" /> Nuevo tag
                </Tag>
              )}

              {getFieldDecorator('tags', {
                onChange: handleChange('tags'),
                initialValue: jobapp.tags,
              })(
                <>
                  {inputVisible && (
                    <Input
                      ref={saveInputRef}
                      type="text"
                      size="small"
                      style={{ width: 78 }}
                      value={inputValue}
                      onChange={handleInputChange}
                      onBlur={handleInputConfirm}
                      onPressEnter={handleInputConfirm}
                    />
                  )}
                </>
              )}
            </Form.Item>

            {integrations?.length > 0 && (
              <Form.Item label='Integraciones'>
                <Row type="flex" gutter={[24, 24]} style={{ flexDirection:'row', alignItems: 'stretch', position: 'relative' }}>
                  {integrations.map((integration, integrationKey) => (
                      <Col lg={window.innerWidth <= 1280 ? 12 : 8}>
                        <Row
                          type='flex'
                          justify='space-between'
                          style={{
                            overflow: 'auto',
                            height:'100%',
                            width: '15em',
                            border: '1px solid #D9D9D9',
                            position: 'relative',
                            display: 'flex'
                          }}
                        >
                          <Row type='flex' style={{ marginLeft: '1em', alignItems:'baseline' }}>
                            <Col span={12} align="left" style={{ bottom:'0.5em', marginTop: '1.5em' }}>
                              <Row type='flex' justify='start' style={{ height: '100%' }}>
                                <img className="integration-image" src={integration.template.image} />
                              </Row>
                              <Row>
                                <Typography.Text style={{ fontSize: 13, marginTop:'0.25em' }} ellipsis strong>
                                  {integration.template.name}
                                </Typography.Text>
                              </Row>
                            </Col>
                            <Col span={10} justify='end'>
                              {!this.props.jobAppIntegrations.map(obj => obj.integration.id).includes(integration.id) ? (
                                <Tag
                                  className='disconnected-integration-tag'
                                  style={{ bottom: '2em', right: '1em' }}
                                >
                                  <Icon type='close-circle'/>{' '}
                                  No conectado
                                </Tag>
                              ) : (
                                <Tag
                                  className='connected-integration-tag'
                                  style={{ bottom: '2em', left: '1em' }}
                                >
                                  <Icon type='check-circle' />{' '}
                                  Conectado
                                </Tag>
                              )}
                            </Col>
                          </Row>
                          <Row
                            style={{
                              minHeight: "5em",
                              maxHeight: "6em",
                              paddingLeft: "1em",
                              paddingRight: "1em",
                              marginTop: "0.5em",
                            }}
                            type="flex"
                          >
                            <Typography.Text className="integration-description">
                              {integration.template.description}
                            </Typography.Text>
                          </Row>
                          <Row>
                            <Col span={24} style={{ marginLeft: "1em", marginRight: "1em" }}>
                              <Button
                                style={{
                                  border: '1px solid #F175A5',
                                  color: '#F175A5',
                                  backgroundColor: 'white',
                                  fontSize: '13px',
                                  borderRadius: '0.2em',
                                  height: '1.8em',
                                  width: '100%', 
                                  maxWidth: '14em',
                                  marginBottom: '0.25em',
                                }}
                                onClick={() => (
                                  !this.props.jobAppIntegrations.map(obj => obj.integration.id).includes(integration.id)
                                    ? this.setConnectModalVisible(true, integration, 'Conectar integración')
                                    : this.setDisconnectModalVisible(true, integration)
                                )}
                              >
                                  {!this.props.jobAppIntegrations.map(obj => obj.integration.id).includes(integration.id)
                                    ? 'Conectar integración'
                                    : 'Desactivar integración'}
                              </Button> 
                              {this.props.jobAppIntegrations.map(obj => obj.integration.id).includes(integration.id) ? (
                                <Button
                                  style={{
                                    border: '1px solid #B7EB8F !important',
                                    color: '#52C41A',
                                    backgroundColor: 'white',
                                    fontSize: '13px',
                                    borderRadius: '0.2em',
                                    height: '1.8em',
                                    width: '100%', 
                                    maxWidth: '14em',
                                    marginBottom: '0.5em',
                                  }}
                                  onClick={() => this.setConnectModalVisible(true, integration, 'Gestionar integración')}
                                >
                                  Gestionar integración
                                </Button>
                              ) : null}
                            </Col>
                          </Row>
                          {this.state.connectModalVisible && (
                            <ConnectIntegrationModal 
                              submitIntegration={this.state.connectTitle !== 'Gestionar integración' ? (
                                this.props.connectIntegration
                              ) : (
                                this.props.updateIntegrationData
                              )}
                              integration={this.state.connectIntegration}
                              visible={this.state.connectModalVisible}
                              setVisible={this.setConnectModalVisible}
                              checkToken={this.props.checkToken}
                              questionType={this.props.questionType}
                              getFieldDecorator={getFieldDecorator}
                              connectTitle={this.state.connectTitle}
                            />
                          )}
                          {this.state.disconnectModalVisible && (
                            <DisconnectIntegrationModal
                              disconnectIntegration={
                                this.props.disableConnection
                              }
                              integration={this.state.connectIntegration}
                              visible={this.state.disconnectModalVisible}
                              setVisible={this.setDisconnectModalVisible}
                            />
                          )}
                        </Row>
                      </Col>
                    )
                  )}
                </Row>
              </Form.Item>
            )}
            
            <Row
              style={{
                color: 'rgba(0, 0, 0, 0.85)',
                fontWeight: 'bold',
                marginBottom: '0.75em',
                marginTop: '3em',
              }}>
              Atributos personalizados proceso
              <Tooltip
                placement="right"
                title="Agrega campos adicionales de información para personalizar la gestión de tus PROCESOS. Esta información no será visible para los candidatos."
              >
                <Icon style={{ marginLeft: '5px', marginRight: '5px' }} type="info-circle" />
              </Tooltip>
              :
            </Row>
            {customFields && (
              <>
                <NewCustomFieldModal
                  existingFieldsKeys={[...Object.keys(customFields), ...Object.keys(businessCustomFieldsObj)]}
                  handleCustomFields={(customFieldKey, value, customFieldType, deleteField) => {
                    handleCustomFields(customFieldKey, value, customFieldType, deleteField);
                    this.setState({ customFields: { ...customFields, [customFieldKey]: value, }});
                  }}
                  visible={this.state.newCustomFieldModalVisible}
                  closeModal={() => this.setState({ newCustomFieldModalVisible: false })}
                  type="JOBAPP"
                />
                {Object.keys(customFields).length === 0 ? (
                  <Typography.Paragraph>
                    Aún no tienes atributos personalizados en este proceso.
                  </Typography.Paragraph>
                ) : (
                  <>
                    {Object.keys(customFields).map((customFieldKey) => (
                      <Row align="middle" key={customFieldKey} type="flex">
                        {businessCustomFieldsObj[customFieldKey] ? (
                          <Col span={22}>
                            <Form.Item
                              colon={false}
                              label={
                                <span>
                                  {businessCustomFieldsObj[customFieldKey].name}:
                                  <Tooltip
                                    placement="right"
                                    title={
                                      ![null, undefined, ''].includes(businessCustomFieldsObj[customFieldKey].description)
                                        ? businessCustomFieldsObj[customFieldKey].description
                                        : 'Este atributo no tiene descripción'
                                    }
                                  >
                                    <Icon style={{ marginLeft: '5px' }} type="info-circle" />
                                  </Tooltip>
                                </span>
                              }
                            >
                              {businessCustomFieldsObj[customFieldKey].value_type === 'text' && (
                                getFieldDecorator(`custom_fields.${customFieldKey}`, {
                                  initialValue: customFields[customFieldKey],
                                  onChange: (event) => (
                                    handleChange(`custom_fields.${customFieldKey}`)(event.target.value)
                                  ),
                                  rules: [{ max: 250, message: 'Máximo 250 caracteres'}]
                                })(<Input />)
                              )}
                              {businessCustomFieldsObj[customFieldKey].value_type === 'number' && (
                                getFieldDecorator(`custom_fields.${customFieldKey}`, {
                                  initialValue: customFields[customFieldKey],
                                  onChange: handleChange(`custom_fields.${customFieldKey}`),
                                  rules: [{ type: 'number', message: 'Debe ser un número' }],
                                })(<InputNumber  />)
                              )}
                              {businessCustomFieldsObj[customFieldKey].value_type === 'boolean' && (
                                getFieldDecorator(`custom_fields.${customFieldKey}`, {
                                  initialValue: customFields[customFieldKey],
                                  onChange: handleChange(`custom_fields.${customFieldKey}`),
                                  valuePropName: 'checked'
                                })(<Switch  />)
                              )}
                              {businessCustomFieldsObj[customFieldKey].value_type === 'date' && (
                                getFieldDecorator(`custom_fields.${customFieldKey}`, {
                                  initialValue: customFields[customFieldKey] ? (
                                    moment(customFields[customFieldKey], 'DD-MM-YYYY')
                                  ) : null,
                                  onChange: (value) => (
                                    handleChange(`custom_fields.${customFieldKey}`)(
                                      moment(value).format('DD-MM-YYYY')
                                    )
                                  ),
                                })(<DatePicker allowClear format="DD-MM-YYYY" locale={localeES} />)
                              )}
                              {businessCustomFieldsObj[customFieldKey].value_type.includes('selection') && (
                                getFieldDecorator(`custom_fields.${customFieldKey}`, {
                                  initialValue: customFields[customFieldKey] || undefined,
                                  onChange: (value) => (handleChange('custom_fields')(
                                    { ...this.props.jobapp.custom_fields, [customFieldKey]: value }
                                  )),
                                })(
                                  <Select
                                    allowClear
                                    mode={businessCustomFieldsObj[customFieldKey].value_type.includes('multi') ? 'multiple' : 'default'}
                                  >
                                    {businessCustomFieldsObj[customFieldKey].options.map((value) => (
                                      <Option key={value} value={value}>{value}</Option>
                                    ))}
                                  </Select>
                                )
                              )}
                              {businessCustomFieldsObj[customFieldKey].value_type === 'file' && (
                                getFieldDecorator(`custom_fields.${customFieldKey}`, {
                                  getValueFromEvent: this.normFile,
                                  initialValue: customFields[customFieldKey],
                                  onChange: ({ file }) => handleChange(`custom_fields.${customFieldKey}`)(file),
                                  rules: [
                                    {
                                      validator: (rule, value, callback) => {
                                        if (value && value[0].size > 5242880) {
                                          callback(true);
                                        } else {
                                          callback();
                                        }
                                      },
                                      message: 'Archivo no puede pesar más de 5MB',
                                    },
                                  ],
                                  valuePropName: 'fileList'
                                })(
                                  <Upload.Dragger
                                    accept=".pdf,.doc"
                                    // Prevent upload
                                    beforeUpload={(file) => {
                                      if (file.size <= 5242880) return false;
                                    }}
                                    name={customFieldKey}
                                    multiple={false}
                                  >
                                    <Icon style={{ fontSize: '30px '}} type="inbox" />
                                    <Title level={3} style={{ marginBottom: 0 }}>Sube tu archivo acá</Title>
                                    <Paragraph>Máximo 5MB. Formatos permitidos: .pdf y .doc</Paragraph>
                                  </Upload.Dragger>
                                )
                              )}
                            </Form.Item>
                          </Col>
                        ) : (
                          <>
                            <Col span={22}>
                              <Form.Item label={customFieldKey.replaceAll('_', ' ')}>
                                {getFieldDecorator(`custom_fields.${customFieldKey}`, {
                                  initialValue: customFields[customFieldKey],
                                  onChange: (event) => (
                                    handleChange(`custom_fields.${customFieldKey}`)(event.target.value)
                                  ),
                                  rules: [{ max: 250, message: 'Máximo 250 caracteres'}]
                                })(<Input />)}
                              </Form.Item>
                            </Col>
                            <Col span={2} align="center">
                              <Icon
                                onClick={() => Modal.confirm({
                                  cancelText: 'Cancelar',
                                  closable: false,
                                  content: (
                                    <>
                                      ¿Estás seguro que quieres eliminar el atributo
                                      <strong>{` ${customFieldKey.replaceAll('_', ' ')} `}</strong>
                                      de este proceso?
                                    </>
                                  ),
                                  okText: 'Eliminar',
                                  onOk: () => {
                                    const updatedCustomFields = { ...this.props.jobapp.custom_fields };
                                    delete updatedCustomFields[customFieldKey];
                                    this.props.handleChange('custom_fields')({ ...updatedCustomFields });
                                  },
                                  title: 'Eliminar atributo personalizado del proceso',
                                })}
                                style={{ paddingTop: '1em' }}
                                type="delete"
                              />
                            </Col>
                          </>
                        )}
                      </Row>
                    ))}
                  </>
                )}
              </>
            )}
            <Row type="flex" style={{ marginBottom: '25px' }}>
              <Col span={12}>
                <Button
                  onClick={() => this.setState({ newCustomFieldModalVisible: true })}
                  type="secondary"
                >
                  Agregar atributo
                </Button>
              </Col>
            </Row>

            <Form.Item 
              label='Video introductorio (opcional)' 
              extra='Ingresa el link de tu video introductorio. ¡Ten en cuenta que el video debe estar alojado en alguno de los proveedores referidos arriba!'
            >
              <div className="btns-container">
                {websites.map((web) => (
                  <div
                    key={web}
                    className={`${
                      videoUrl && videoUrl.includes(web) && 'active'
                    } website-btn`}
                  >
                    <img alt={web} />
                  </div>
                ))}
              </div>

              {getFieldDecorator('youtube_intro_video', {
                onChange: this.onChangeVideo,
                initialValue: jobapp.youtube_intro_video,
              })(<Input />)}
            </Form.Item>

            <Form.Item label="Texto de bienvenida (opcional)">
              <MceEditor
                handleChange={handleChange}
                body={jobapp.welcome_text}
                name="welcome_text"
                height={400}
              />
            </Form.Item>

            <Form.Item label="Sobre el cargo (opcional)">
              <MceEditor
                handleChange={handleChange}
                body={jobapp.description}
                name="description"
                height={400}
              />
            </Form.Item>

            <Form.Item label="Fecha de cierre">
              {getFieldDecorator('endDate', {
                onChange: (newValue) =>
                  handleChange('closure_date')(
                    moment(newValue).format('YYYY-MM-DD')
                  ),
                rules: [
                  {
                    required: true,
                    message: 'Por favor ingresa la fecha de cierre',
                  },
                ],
                initialValue: moment(
                  this.formatDateFromDatabase(jobapp.closure_date),
                  dateFormat
                ),
              })(
                <DatePicker
                  showToday={false}
                  format={dateFormat}
                  locale={localeES}
                  allowClear={false}
                  disabledDate={(current) => {
                    return current && current < moment();
                  }}
                />
              )}
              {dateIsPast && <p style={{ color: 'red' }}>La fecha ya pasó</p>}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    initialClosureDate: state.jobapp.jobApplication.closure_date,
    token: state.auth.token
  };
};

const mapDispathToProps = dispatch => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  }
}

const JobDetailForm = Form.create()(JobDetail);
export default connect(mapStateToProps, mapDispathToProps)(JobDetailForm);