import './KnockoutForm.scss';

import { Alert, Button, Card, Divider, Row, Typography } from 'antd';

import Availability from './QuestionTypes/Availability';
import FileUpload from './QuestionTypes/FileUpload';
import Integer from './QuestionTypes/Integer';
import MultiLine from './QuestionTypes/MultiLine';
import MultiSelection from './QuestionTypes/MultiSelection';
import React from 'react';
import Schedule from './QuestionTypes/Schedule';
import SingleLine from './QuestionTypes/SingleLine';
import SingleSelection from './QuestionTypes/SingleSelection';
import i18n from '../../i18n';

const KnockOutForm = (props) => {
  const { questions, errors, disabled } = props;
  const question = (q, error, index) => {
    const component = {
      singleline: (
        <SingleLine
          {...q}
          key={index}
          index={index}
          error={error}
          handleChangeV2={props.handleChangeV2}
          classes={props.classes}
          disabled={disabled}
        />
      ),
      multiline: (
        <MultiLine
          {...q}
          key={index}
          index={index}
          error={error}
          handleChangeV2={props.handleChangeV2}
          classes={props.classes}
          disabled={disabled}
        />
      ),
      int: (
        <Integer
          {...q}
          key={index}
          index={index}
          error={error}
          handleChangeV2={props.handleChangeV2}
          classes={props.classes}
          disabled={disabled}
        />
      ),
      singleselection: (
        <SingleSelection
          {...q}
          key={index}
          index={index}
          error={error}
          handleChangeV2={props.handleChangeV2}
          classes={props.classes}
          disabled={disabled}
        />
      ),
      multiselection: (
        <MultiSelection
          {...q}
          key={index}
          index={index}
          error={error}
          handleMultiChangeV2={props.handleMultiChangeV2}
          classes={props.classes}
          disabled={disabled}
        />
      ),
      file: (
        <FileUpload
          {...q}
          key={index}
          index={index}
          error={error}
          handleFileChange={props.handleFileChange}
          classes={props.classes}
          disabled={disabled}
          jobappid={props.jobappid}
        />
      ),
      schedule: (
        <Schedule
          {...q}
          key={index}
          index={index}
          error={error}
          handleChange={props.handleChangeV2}
          classes={props.classes}
          disabled={disabled}
          jobappid={props.jobappid}
        />
      ),
      availability: (
        <Availability
          {...q}
          key={index}
          index={index}
          error={error}
          handleChange={props.handleChangeV2}
          classes={props.classes}
          disabled={disabled}
          jobappid={props.jobappid}
        />
      ),
    };
    if (disabled) {
      let component =
        q.type === 'file' ? <div /> || null : component[q.type] || null;
      return (
        <div key={index} id={index}>
          {component}
        </div>
      );
    }
    return (
      (
        <>
          <div key={index} id={index}>
            {component[q.type]}
          </div>
          <Divider style={{ margin: 0 }} />
        </>
      ) || null
    );
  };

  const getSortedQuestions = () => {
    const sorted = Object.keys(questions)
      .map((key) => ({ ...questions[key], key: key }))
      .sort((a, b) => a.position - b.position);
    const questionArray = sorted.map((obj) =>
      question(questions[obj.key], errors[obj.key], obj.key)
    );
    return questionArray;
  };

  return (
    <Card
      title={
        !disabled && (
          <Typography.Title level={2}>
            {i18n.t('candidate__path__KO__questions')}
          </Typography.Title>
        )
      }
      bordered={false}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
        <Alert
          message={
            <>
              {i18n.t('ko__blue_text').split('{{')[0]}(
              <Typography.Text style={{ color: 'red' }}>*</Typography.Text>)
              {i18n.t('ko__blue_text').split('}}')[1].split('{{')[0]}
              <Typography.Text strong>
                {' '}
                {i18n.t('ko__blue_text').split('{{')[2].split('}}')[0]}
              </Typography.Text>
            </>
          }
          type="info"
          showIcon
        />
        {getSortedQuestions()}
        {!disabled && (
          <div style={{ display: 'flex', justifyContent: 'end', gap: '1rem' }}>
            <Button type="secondary" onClick={props.handleCancel}>
              {i18n.t('commons__cancel')}
            </Button>
            <Button type="primary" onClick={props.onSubmit}>
              {i18n.t('candidate__path__save__answers')}
            </Button>
          </div>
        )}
      </div>
    </Card>
  );
};

export default KnockOutForm;
