import React from 'react';
import PropTypes from 'prop-types';

/**
 * Custom svg component for rating
 * @param {*} props 
 * @returns 
 */
function GenomaStars(props) {
  const maxValue = props.maxValue ? props.maxValue : 5

  const Star = ({ color }) => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <polygon fill={color} points="512,197.816 325.961,185.585 255.898,9.569 185.835,185.585 0,197.816 142.534,318.842 95.762,502.431 255.898,401.21 416.035,502.431 369.263,318.842" />
      </svg>
    )
  }
  return (
    <>
      {
        [...Array(maxValue).keys()].map((_, idx) => {
          return props.value - 1 >= idx ? <Star color={props.fillColor} key={idx} /> : <Star color={'#B0B0B0'} key={idx} />
        }
        )
      }
    </>
  )
}

function ScorecardsGroup ({ group }) {
  return (
    <div>
      <h6
        style={{
          fontWeight: 'bold',
          fontSize: '14px',
          lineHeight: '10px',
          color: '#8c8c8c',
          marginTop: '1.5em',
          marginBottom: '1.5em',
        }}
      >
        {group.group}
      </h6>
      <div className="scores-container">
        {group.answer?.map((ans) => (
          <Scorecard title={ans.text} value={ans.evaluation} maxValue={5} key={ans.id}/>
        ))}
      </div>
    </div>
  );
};

const Scorecard = ({ title, value, maxValue }) => {
  return (
    <div className="scorecard">
      <p>{title}</p>
      <div style={{ display: 'flex', alignItems: 'center', width: 100 }}>
        <GenomaStars fillColor={'#FF9CD2'} maxValue={maxValue} value={value} />
      </div>
    </div>
  );
};

Scorecard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
};

ScorecardsGroup.propTypes = {
  group: PropTypes.object.isRequired,
};

export default ScorecardsGroup;
