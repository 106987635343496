import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Checkbox,
  DatePicker,
  Dropdown,
  Icon,
  Input,
  Menu,
  Radio,
  Select,
  Slider,
  Tooltip,
  Typography,
} from 'antd';

import moment from 'moment';
import debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';
import ScheduleFilterComponent from './ScheduleFilterComponent';


import { filtersBase, clearEmptyKeys } from '../helpers';
import i18n from '../../../../i18n';

const { Text } = Typography;

const countries = [
  {
    name: 'Argentina',
    code: 'AR',
  },
  {
    name: 'Bolivia',
    code: 'BO',
  },
  {
    name: 'Chile',
    code: 'CL',
  },
  {
    name: 'Colombia',
    code: 'CO',
  },
  {
    name: 'Ecuador',
    code: 'EC',
  },
  {
    name: 'México',
    code: 'MX',
  },
  {
    name: 'Perú',
    code: 'PE',
  },
  {
    name: 'Panamá',
    code: 'PA',
  },
  {
    name: 'Paraguay',
    code: 'PY',
  },
  {
    name: 'Uruguay',
    code: 'UY',
  },
];

const careerTypeOptions = [
  {
    label: 'Educación Técnico Profesional',
    value: 'TECPRO',
  },
  {
    label: 'Pregrado',
    value: 'PREG',
  },
  {
    label: 'Postgrado',
    value: 'POSTG',
  },
  {
    label: 'Otro',
    value: 'OTHER',
  },
];

const filters = [
  // {
  //   name: 'Estado de la etapa',
  //   icon: 'sync',
  // },
  {
    name: 'Juegos',
    icon: 'file-done',
    key: 'fit_in_range',
  },
  {
    name: 'CV u hoja de vida',
    icon: 'solution',
    key: 'cv_null',
  },
  // {
  //   name: 'Renta',
  //   icon: 'dollar',
  // },
  {
    name: 'Ubicación',
    icon: 'environment',
    key: 'country',
  },
  {
    name: 'Experiencia laboral',
    icon: 'solution',
    key: 'experience_position_contains',
  },
  {
    name: 'Formación académica',
    icon: 'read',
    key: 'education_level',
  },
  {
    name: 'Etiquetas',
    icon: 'tags',
    key: 'tags',
  },
  {
    name: 'Postulación',
    icon: 'clock-circle',
    key: 'date_in_range',
  },
  {
    name: i18n.t('management__singular_discard_reason',{ns: 'businessUser'}),
    icon: 'stop',
    key: 'discard_reasons',
  },
  {
    name: 'Horario de disponibilidad',
    icon: 'clock-circle',
    key: 'ko_schedule',
  },
  {
    name: 'Declara discapacidad',
    icon: 'user',
    key: 'has_disability',
  }
];

const radioStyle = {
  display: 'block',
  lineHeight: '30px',
};

const Filter = ({ 
  queryFilters,
  setQueryFilters,
  visible,
  onClose,
  candidatesState,
  discardReasons,
  queryColumns,
  setQueryColumns
}) => {
  const [localFilters, setLocalFilters] = useState({});

  const changeFilters = (key, value) => {
    setLocalFilters((filters) => ({
      ...filters,
      [key]: value,
    }));
  };

  const debouncedCommuneSearch = debounce((value) => {
    changeFilters('commune', value);
  }, 500);

  const debouncedJobSearch = debounce((value) => {
    changeFilters('experience_position_contains', value);
  }, 500);

  const debouncedFitSearch = debounce((value) => {
    changeFilters('fit_in_range', value);
  }, 250);

  const debouncedYears = debounce((values) => {
    ['experience_min_years', 'experience_max_years'].forEach((key, index) => {
      changeFilters(key, values[index]);
    });
  }, 250);

  const debouncedCareer = debounce((value) => {
    changeFilters('career', value);
  }, 500);

  const experienceRef = useRef(null);

  const createFilter = (key) => {
    const filterConfig = filters.find((filter) => filter.key === key);

    const content = (item) => (
      <div className="table-filters__container__item" key={key}>
        <div className="table-filters__container__item__header">
          <Icon type={filterConfig.icon} />
          <span>{filterConfig.name}</span>
          <Tooltip title="Borrar filtro" placement="rightBottom">
            <Icon
              type="close-circle"
              theme="filled"
              style={{
                cursor: 'pointer',
                marginLeft: 'auto',
                color: '#CCCCCC',
              }}
              onClick={() => deleteFilter(filterConfig.key)}
            />
          </Tooltip>
        </div>
        <div className="table-filters__container__item__content">{item}</div>
      </div>
    );

    switch (key) {
      // case 'Estado de la etapa':
      //   return (
      //     <Checkbox.Group
      //       options={[
      //         { label: 'En proceso', value: 'En proceso' },
      //         { label: 'Finalizados', value: 'Finalizados' },
      //         { label: 'Descartados', value: 'Descartados' },
      //       ]}
      //       defaultValue={[]}
      //       onChange={() => {}}
      //     />
      //   );
      case 'fit_in_range':
        return content(
          <Slider
            range
            value={localFilters['fit_in_range']}
            onChange={(e) => debouncedFitSearch(e)}
          />
        );
      case 'cv_null':
        return content(
          <Radio.Group
            onChange={(e) => changeFilters('cv_null', e.target.value)}
            value={localFilters['cv_null']}
          >
            {[
              { label: 'Tiene CV', value: false },
              { label: 'No tiene CV', value: true },
            ].map((item) => (
              <Radio style={radioStyle} value={item.value}>
                {item.label}
              </Radio>
            ))}
          </Radio.Group>
        );
      // case 'Renta':
      //   return (
      //     <Checkbox.Group
      //       options={[
      //         { label: 'USD', value: 'USD' },
      //         { label: 'CLP', value: 'CLP' },
      //       ]}
      //       defaultValue={[]}
      //       onChange={() => {}}
      //     />
      //   );
      case 'country':
        return content(
          <>
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="País"
              onChange={(value) => changeFilters('country', value)}
              value={localFilters['country'] || undefined}
            >
              {countries.map((country) => (
                <Select.Option value={country.code}>
                  {country.name}
                </Select.Option>
              ))}
            </Select>
            {localFilters['country'] === 'CL' ? (
              <Input
                placeholder="Comuna"
                style={{ marginTop: '0.5rem' }}
                onChange={(e) => debouncedCommuneSearch(e.target.value)}
              />
            ) : null}
          </>
        );
      case 'experience_position_contains':
        return content(
          <>
            <Input
              placeholder="Cargo previo"
              onChange={(e) => debouncedJobSearch(e.target.value)}
              style={{ marginBottom: '0.5rem' }}
              ref={experienceRef}
            />
            {localFilters['experience_position_contains'] ? (
              <Slider
                range
                onChange={(values) => debouncedYears(values)}
                tipFormatter={(value) => `${value} año${value > 1 ? 's' : ''}`}
                value={[
                  localFilters['experience_min_years'],
                  localFilters['experience_max_years'],
                ]}
              />
            ) : null}
          </>
        );
      case 'education_level':
        return content(
          <>
            <Select
              style={{ width: '100%' }}
              placeholder="Nivel educativo"
              onChange={(value) => changeFilters('education_level', value)}
              value={localFilters['education_level'] || undefined}
            >
              {careerTypeOptions.map((type) => (
                <Select.Option value={type.value}>{type.label}</Select.Option>
              ))}
            </Select>
            {localFilters['education_level'] ? (
              <Input
                placeholder="Carrera"
                style={{ marginTop: '0.5rem' }}
                onChange={(e) => debouncedCareer(e.target.value)}
              />
            ) : null}
          </>
        );
      case 'tags':
        return content(
          <Select
            mode="tags"
            style={{ width: '100%' }}
            placeholder="Buscar etiquetas"
            value={localFilters['tags']}
            onChange={(e) => changeFilters('tags', e)}
          />
        );
      case 'date_in_range':
        return content(
          <DatePicker.RangePicker
            placeholder={['Comienzo', 'Final']}
            separator="-"
            onChange={(dates) =>
              changeFilters(
                'date_in_range',
                dates.map((date) => date.format('DD-MM-YYYY'))
              )
            }
            value={localFilters['date_in_range'].map((date) =>
              moment(date, 'DD-MM-YYYY')
            )}
            format="DD-MM-YYYY"
          />
        );
      case 'discard_reasons':
        return content(
          <Select
            mode="multiple"
            style={{ width: '98%' }}
            onChange={(value) => changeFilters('discard_reasons', value)}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {discardReasons.map((discardReason) => (
              <Select.Option value={discardReason.id} key={discardReason.id}>
                {discardReason.title}
              </Select.Option>
            ))}
          </Select>
        );
      case 'ko_schedule':
        if (!queryColumns.find((column) => column === 'ko')) {
          setQueryColumns([...queryColumns, 'ko']);
        }
        return content(
          <ScheduleFilterComponent
            onChange={changeFilters}
          />
        )
        case 'has_disability':
          return content(
            <Checkbox.Group
              onChange={(value) => changeFilters('has_disability', value)}
              options={[
                { label: 'Sí', value: 'YES' },
                { label: 'No', value: 'NO' },
                { label: 'Prefiere no decir', value: 'UNKNOWN' },
                { label: 'No declarado', value: 'EMPTY' },
              ]}
              value={localFilters['has_disability']}
            />
          );
    }
  };

  const addFilter = (key) => {
    setLocalFilters((filters) => ({ ...filters, [key]: filtersBase[key] }));
  };

  const deleteFilter = (keyToDelete) => {
    // i dont like this way but i dont know how to do it better
    const relatedKeys = {
      education_level: ['career', 'education_level'],
      country: ['commune', 'country'],
      experience_position_contains: [
        'experience_max_years',
        'experience_min_years',
        'experience_position_contains',
      ],
    };

    const keys = [...(relatedKeys[keyToDelete] ?? [keyToDelete])];
    setLocalFilters((filters) =>
      Object.entries(filters)
        .filter(([key, value]) => !keys.includes(key))
        .reduce((acc, [key, value]) => {
          return { ...acc, [key]: value };
        }, {})
    );
  };

  const dropdownMenu = (
    <Menu>
      {filters
        .filter((filter) => !Object.keys(localFilters).includes(filter.key) 
        && !(candidatesState !== 'DISCAR' && filter.key === 'discard_reasons'))
        .map((filter) => (
          <Menu.Item key={filter.key} onClick={() => addFilter(filter.key)}>
            <Icon type={filter.icon} />
            {filter.name}
          </Menu.Item>
        ))}
    </Menu>
  );

  // we are using this effect to clear the input when state changes because localFilters.experience_position_contains is a debounced text value
  // and we cant control it with the state
  useEffect(() => {
    if (
      experienceRef.current &&
      !localFilters['experience_position_contains']
    ) {
      experienceRef.current.state.value = '';
    }
  }, [localFilters]);

  useEffect(() => {
    // optimization to avoid unnecessary re-renders
    if (!isEqual(clearEmptyKeys(queryFilters), clearEmptyKeys(localFilters))) {
      // when the local filters change we update the query filters
      setQueryFilters(localFilters);
    }
  }, [localFilters]);

  useEffect(() => {
    if (candidatesState === 'ACTIV' && Object.keys(localFilters).includes('discard_reasons')) {
      deleteFilter('discard_reasons')
    }
  }, [candidatesState])
  
  const list = Object.keys(localFilters);

  return visible ? (
    <div className="table-filters-background">
      <div className="table-filters">
        <div className="table-filters__drawer-header">
          <Text strong>Filtros</Text>
          <Button type="link" icon="close" onClick={onClose} />
        </div>
        <div className="table-filters__container">
          {list.length ? (
            list.map((filter) => createFilter(filter))
          ) : (
            <div className="table-filters__container__list-empty">
              <Text strong>¡No tienes filtros activos aún!</Text>
              <span>
                Para comenzar a filtrar presiona el botón{' '}
                <u style={{ color: '#f175a5' }}>
                  <strong>+ Añadir filtro</strong>
                </u>{' '}
                en la parte inferior del menú
              </span>
            </div>
          )}
        </div>
        <div className="table-filters__buttons">
          <Dropdown
            overlay={dropdownMenu}
            overlayStyle={{ top: 'auto', bottom: 0 }}
          >
            <Button type="primary" icon="plus">
              Añadir filtro
            </Button>
          </Dropdown>
          <Button onClick={() => setLocalFilters({})} disabled={!list.length}>
            Limpiar
          </Button>
        </div>
      </div>
    </div>
  ) : null;
};

export default Filter;
