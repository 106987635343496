import React, { useState, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actionCreators from '../../../../actions/data'
import { Button, Tooltip } from 'antd'
import LoadingModal from '../modals/loading'

const ExportCandidates = (props) => {

  const [loading, setLoading] = useState(false)

  const handleCandidatesExport = async () => {
    setLoading(true)
    const url = `gamesandtests/candidatelist/export/`;
    const today = new Date();
    const parsedDate = `${today.getDate()}-${today.getMonth()+1}-${today.getFullYear()}`;
    const filename = `Candidatos al ${parsedDate}.xls`;
    try {
      // technical debt: agregar mensaje de error cuando no se logre descargar.
      await props.actions.fetchFile(props.token, url, "", "get", filename);
    } 
    finally {
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <Tooltip placement="top" title='Al hacer clic en este botón se descargaran en formato excel los datos de todos los candidatos que han postulado a los diversos procesos que ha creado tu empresa.'>
        <Button type='default' icon='file-excel' onClick={handleCandidatesExport}/>
      </Tooltip>

      <LoadingModal loading={loading} />
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportCandidates)