import React from 'react'
import { Modal } from 'antd'

export const deleteProcess = (props) => {
  return Modal.confirm({
    title: <span>¿Deseas eliminar el proceso <strong style={{ color: '#424242' }}>{props.name}</strong>?</span>,
    content: 'El proceso se eliminará permanentemente',
    okText: 'Confirmar',
    cancelText: 'Cancelar',
    centered: true,
    onOk: () => props.onOk(),
  });
}