import React, { useState } from 'react';
import {
  Modal,
  Row,
  Col,
  Typography,
  Checkbox,
  InputNumber,
  Form,
  Select,
  Button
} from 'antd';
import { countryCode } from '../../assets/utils';
import { genderOptions } from '../../../../../constants/personalUser';

const { Text } = Typography;
const { Option } = Select;

const AdvancedFilters = ({ showModal, setShowModal, handleFilter }) => {
  const [filterParams, setFilterParams] = useState({
    date: [],
    hired: false,
    nationality: [],
    gender: [],
    has_disability: false,
    age: [],
    rent: [],
    education: undefined,
    fit: undefined,
  })

  return (
    <Modal
      title='Filtros avanzados'
      onOk={() => { setShowModal(!showModal); handleFilter('', filterParams) }}
      onCancel={() => setShowModal(!showModal)}
      okText='Aplicar filtros'
      cancelText='Cancelar'
      visible={showModal}
      centered
    >
      <Form layout='vertical'>
        {/* <Form.Item label='Estado'>
          <Checkbox checked={filterParams.hired} onClick={(e) => setFilterParams({ ...filterParams, hired: e.target.checked })}>
            Contratado
          </Checkbox>
        </Form.Item> */}
        <Form.Item label='Nacionalidad'>
          <Row type='flex' justify='space-between'>
            {
              Object.values(countryCode).map((nation, idx) => (
                <Col
                  key={idx}
                  span={4}
                  style={{
                    background: filterParams.nationality.some(elem => elem === nation) ? '#F0F5FF' : '#FAFAFA',
                    border: filterParams.nationality.some(elem => elem === nation) ? '1px solid #597EF7' : '1px solid #D9D9D9',
                    padding: '20px 10px',
                    margin: '0 10px 10px 0',
                    borderRadius: '2px',
                    cursor: 'pointer'
                  }}
                  onClick={
                    () => filterParams.nationality.some(elem => elem === nation) ?
                      setFilterParams({ ...filterParams, nationality: filterParams.nationality.filter(elem => elem !== nation) }) :
                      setFilterParams({ ...filterParams, nationality: [...filterParams.nationality, nation] })
                  }
                >
                  <Row type='flex' justify='center'>
                    <img src={`https://genoma-assets.s3.us-east-2.amazonaws.com/flags/${nation}.svg`} />
                  </Row>
                  <Row type='flex' justify='center'>
                    <Text>{nation}</Text>
                  </Row>
                </Col>
              ))
            }
          </Row>
        </Form.Item>
        <Form.Item label='Género'>
          <Checkbox.Group
            options={Object.entries(genderOptions).map(([key, value]) => ({ label: value, value: key }))}
            onChange={(e) => setFilterParams({ ...filterParams, gender: e })}
          />
        </Form.Item>
        <Form.Item label='Situación de discapacidad'>
          <Checkbox checked={filterParams.has_disability} onClick={(e) => setFilterParams({ ...filterParams, has_disability: e.target.checked })}>
            Declara alguna situacion de discapacidad
          </Checkbox>
        </Form.Item>
        <Form.Item label='Edad'>
          <Row type='flex' justify='space-between' align='middle'>
            <Row type='flex' gutter={20} style={{ width: filterParams.age.length > 0 ? '75%' : '100%' }}>
              <Col span={12}>
                <InputNumber placeholder='Mínimo' style={{ width: '100%' }} min={0} value={filterParams.age[0]} onChange={(e) => setFilterParams({ ...filterParams, age: [e, filterParams.age[1]] })} />
              </Col>
              <Col span={12}>
                <InputNumber placeholder='Máximo' style={{ width: '100%' }} min={0} value={filterParams.age[1]} onChange={(e) => setFilterParams({ ...filterParams, age: [filterParams.age[0], e] })} />
              </Col>
            </Row>
            {
              filterParams.age.length > 0 && (<Button size='small' shape='round' onClick={() => setFilterParams({ ...filterParams, age: [] })}>Borrar filtro</Button>)
            }
          </Row>
        </Form.Item>

        {/* <Form.Item label='Renta'>
          <Row type='flex' justify='space-between' align='middle'>
            <Row type='flex' gutter={20} style={{ width: filterParams.rent.length > 0 ? '75%' : '100%' }}>
              <Col span={12}>
                <InputNumber placeholder='Mínimo' style={{ width: '100%' }} min={0} value={filterParams.rent[0]} onChange={(e) => setFilterParams({ ...filterParams, rent: [e, filterParams.rent[1]] })} />
              </Col>
              <Col span={12}>
                <InputNumber placeholder='Máximo' style={{ width: '100%' }} min={0} value={filterParams.rent[1]} onChange={(e) => setFilterParams({ ...filterParams, rent: [filterParams.rent[0], e] })} />
              </Col>
            </Row>
            {
              filterParams.rent.length > 0 && (<Button size='small' shape='round' onClick={() => setFilterParams({ ...filterParams, rent: [] })}>Borrar filtro</Button>)
            }
          </Row>
        </Form.Item> */}

        <Form.Item label='Formación academica'>
          <Row type='flex' justify='space-between' align='middle'>
            <Select style={{ width: filterParams.education_level ? '75%' : '100%' }} value={filterParams.education_level} placeholder='Seleccionar formación académica' onChange={(e) => setFilterParams({ ...filterParams, education_level: e })}>
              <Option value={'UNI'}>Educación Universitario</Option>
              <Option value={'TEC'}>Educación Tecnica</Option>
              <Option value={'SEC'}>Educación secundaria</Option>
              <Option value={'MAG'}>Magister</Option>
              <Option value={'DOC'}>Doctorado</Option>
              <Option value={'POS'}>Postgrado</Option>
            </Select>
            {
              filterParams.education_level && (<Button size='small' shape='round' onClick={() => setFilterParams({ ...filterParams, education_level: null })}>Borrar filtro</Button>)
            }
          </Row>
        </Form.Item>

        <Form.Item label='Puntaje FIT'>
          <Row type='flex' justify='space-between' align='middle'>
            <Select style={{ width: filterParams.fit ? '75%' : '100%' }} placeholder='Seleccionar puntaje FIT' value={filterParams.fit} onChange={(e) => setFilterParams({ ...filterParams, fit: e })}>
              <Option value={'85,100'}>Recomendado</Option>
              <Option value={'60,84'}>Medianamente recomendado</Option>
              <Option value={'0,59'}>No recomendado</Option>
            </Select>
            {
              filterParams.fit && (<Button size='small' shape='round' onClick={() => setFilterParams({ ...filterParams, fit: null })}>Borrar filtro</Button>)
            }
          </Row>
        </Form.Item>
      </Form>
    </Modal>

  );
}

export default AdvancedFilters;