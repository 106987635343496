import React, { useEffect, useState, useRef } from 'react';
import { Modal, Button, Spin, Row, Col } from 'antd';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../actions/data';
import i18n from '../../../i18n';

const embedCV = (fileUrl) => {
  return fileUrl && fileUrl.includes('.pdf')
    ? fileUrl
    : `https://docs.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(
        fileUrl
      )}`;
};

const PreviewDocument = ({
  file,
  handleOk,
  visiblePreviewModal,
  fetchUrl,
  withFetch = false,
  actions,
  token,
}) => {
  const [iframeIntervalId, setIframeIntervalId] = useState(null);
  const [cvUrl, setCvUrl] = useState(file);
  const [loading, setLoading] = useState(true);
  const iframeRef = useRef();

  useEffect(() => {
    let intervalId;
    if (cvUrl) {
      intervalId = setInterval(() => {
        if (iframeRef.current) {
          iframeRef.current.src = embedCV(cvUrl);
        }
      }, 2000);
      setIframeIntervalId(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [cvUrl]);

  useEffect(() => {
    if (file && file !== '' && !withFetch) {
      setCvUrl(file);
    }
  }, [file]);

  useEffect(() => {
    if (visiblePreviewModal && withFetch && fetchUrl) {
      setLoading(true);
      actions
        .fetchAndReturn(token, fetchUrl)
        .then((res) => {
          setLoading(false);
          setCvUrl(res.body.file);
        });
    }
  }, [visiblePreviewModal, fetchUrl]);

  const downloadCV = () => {
    const link = document.createElement('a');
    link.href = cvUrl;
    link.setAttribute('download', 'cv');
    link.setAttribute('target', '_blank');
    // Download it witout opening a new tab
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <Modal
      width={100}
      style
      visible={visiblePreviewModal}
      closable
      centered
      onCancel={handleOk}
      onOk={handleOk}
      className="preview-document-modal"
      footer={[
        <Button key='download' type='secondary' onClick={downloadCV}>
          {i18n.t('commons__download')}
        </Button>,
        <Button key="ok" type="primary" onClick={handleOk}>
          Ok
        </Button>,
      ]}
    >
      <Row type="flex" justify="center" align="middle">
        <Col xs={24}>
          <Spin size="large" spinning={loading} style={{ width: '100%' }}>
            <iframe
              id="cv-iframe"
              credentialless=''
              frameBorder='0'
              height='500px'
              onLoad={() => {
                clearInterval(iframeIntervalId);
                setLoading(false);
              }}
              ref={iframeRef}
              src={embedCV(cvUrl)}
              width="100%"
            />
          </Spin>
        </Col>
      </Row>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewDocument);
