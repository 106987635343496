import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import i18n from "../../../i18n";

import { Button, Col, message, Modal, Row, Spin } from "antd";

import useBoolean from "../../../hooks/useBoolean";
import SelectProcessesComponent from "../../../components/SelectProcesses";

export default function AddToProcess({
  businessId, candidates, closeModal, fetchAndReturn, token, visible,
}) {
  const [jobApplicationChoices, setJobApplicationChoices] = useState();
  const [submittingData, handleSubmittingData] = useBoolean(false);
  const [selectedJobApplications, setSelectedJobApplications] = useState([]);

  useEffect(() => {
    if (jobApplicationChoices === undefined) {
      const url = `/api/v1/gamesandtests/business/${businessId}/jobapplications/`;
      fetchAndReturn(token, url)
        .then((data) => {
          const aux = {};
          data.body?.results?.forEach(({ id, job_application, status, department, creation_date }) => {
            aux[id] = {
              id,
              job_application,
              status,
              department,
              creation_date: moment(creation_date).format('YYYY-MM-DD'),
            };
          });
          setJobApplicationChoices(aux);
        })
        .catch((e) => {
          Sentry.captureException(e);
        });
    }
  }, []);

  const handleAddToJobApplications = () => {
    let succesfullyAdded = 0;
    handleSubmittingData.on();
    const promiseArray = [];
    selectedJobApplications?.forEach((jobAppId) => {
      candidates?.forEach((candidateId) => {
        const passiveApplicationBody = { candidate_id: candidateId };
        promiseArray.push(
          fetchAndReturn(
            token,
            `/api/v1/gamesandtests/jobapplications/${jobAppId}/candidates/`,
            'POST',
            passiveApplicationBody,
          )
            .then(({ status: applicationStatus }) => {
              if (applicationStatus < 400) succesfullyAdded++;
            })
            .catch((error) => Sentry.captureException(error))
        );
      });
    });

    Promise.all(promiseArray)
      .then(() => {
        if (succesfullyAdded === selectedJobApplications.length * candidates.length) {
          message.success(i18n.t('passive_candidates__candidates_added_successfully'));
        } else {
          message.error(i18n.t('passive_candidates__error_adding_candidates'));
        }
        handleSubmittingData.off();
        closeModal();
      })
      .catch(() => {
        message.error(i18n.t('passive_candidates__error_adding_candidates'));
        handleSubmittingData.off();
        closeModal();
      });
  };

  return (
    <Modal
      footer={(
        <Row type="flex" justify="end">
          <Col>
            <Button
              disabled={submittingData}
              onClick={closeModal}
              style={{ marginRight: '1em' }}
            >
              {i18n.t('commons__cancel')}
            </Button>
          </Col>
          <Col>
            <Button
              disabled={submittingData}
              onClick={handleAddToJobApplications}
              type="primary"
            >
              Agregar
            </Button>
          </Col>
        </Row>
      )}
      onCancel={closeModal}
      title="Agregar a proceso"
      visible={visible}
      width={600}
    >
      <Spin spinning={submittingData}>
        <SelectProcessesComponent
          disabledJobApplications={[]}
          jobApplicationChoices={jobApplicationChoices}
          selectedJobApplications={selectedJobApplications}
          setSelectedJobApplications={setSelectedJobApplications}
        />
      </Spin>
    </Modal>
  )
};

PropTypes.AddToProcess = {
  businessId: PropTypes.string.isRequired,
  candidates: PropTypes.array,
  closeModal: PropTypes.func.isRequired,
  fetchAndReturn: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired
};
