export const validateChileanDni = (rut) => {
  if (typeof rut === 'string' || typeof rut === 'number') {
    const rutSinFormato = cleanRut(rut);
    const rutSinDv = rutSinFormato.slice(0, -1);
    const rutDv = rutSinFormato.split('').pop().toLowerCase();

    return calcularDv(rutSinDv) === rutDv;
  }
  else {
    return false;
  }
};
  
  const calcularDv = rut => {
    let sum = 0;
    let rutReversa = cleanRut(rut).split('').reverse();
  
    for (let i = 0, j = 2; i < rutReversa.length; i++, j < 7 ? j++ : j = 2) {
      sum += rutReversa[i] * j;
    }
  
    let result = 11 - (sum % 11)
    if (result === 11) return '0';
    if (result === 10) return 'k';
    return String(result);
  };
  
  const cleanRut = rut => {
    return String(rut).replace(/[^0-9a-z]/gi, '');
  }