import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
/**
 * Show candidate notes
 * @param {*} notes 
 * @returns React.node
 */
function Notes({ notes }) {

  return (
    notes !== '' ?
      <div className="report-pdf-reference protected-div">
        <div className="report-pdf-reference__header">
          <h6 style={{ fontSize: 14 }}>
            <strong>
              {i18n.t('candidate_pdf__team_notes', {ns: 'candidatePdf'})}
            </strong>
          </h6>
        </div>
        <div className='section-text'>{notes}</div>
      </div>
      :
      null
  )
}

Notes.propTypes = {
  notes: PropTypes.string.isRequired,
};

export default Notes;
