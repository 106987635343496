import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Tag, Input, Tooltip, Icon, message, Button } from 'antd';

import * as actionCreators from '../actions/data';
import i18n from '../i18n';

const Tags = ({ actions, token, onlyView, ...props }) => {
  const [state, setState] = useState({
    personalUserId: 0,
    tags: [],
    inputVisible: false,
    inputValue: '',
  });

  const inputRef = useRef(null);

  const sendTags = (tags) => {
    const data = {
      tags: tags,
    };

    const url = `/api/v1/gamesandtests/tags/personalusers/${state.personalUserId}/`;
    return actions.fetchAndReturn(token, url, 'PUT', data);
  };

  const removeTag = (removedTag) => {
    const tags = state.tags.filter((tag) => tag !== removedTag);
    sendTags(tags).then((resp) => {
      if (resp.status == 403) {
        message.error(i18n.t('commons__permissions_error'));
      }
      else if (resp.status >= 400) {
        message.error(i18n.t('Error al eliminar tag'));
      }
      else {
        setState((state) => ({ ...state, tags: resp.body?.tags }));
        message.success('Tag eliminado');
      }
    });
  };

  const showInput = () => {
    setState((state) => ({ ...state, inputVisible: true }));
  };

  const handleInputChange = (e) => {
    e.persist(); // because react version

    setState((state) => ({ ...state, inputValue: e.target.value }));
  };

  const handleInputConfirm = (e) => {
    const { inputValue, tags } = state;
    const tagExists = tags.indexOf(inputValue) >= 0;

    if (tagExists) {
      message.error('Ese tag ya existe');
    } else if (inputValue && !tagExists) {
      sendTags([...tags, inputValue]).then((resp) => {
        if (resp.status == 403) {
          message.error(i18n.t('commons__permissions_error'));
        }
        else if (resp.status >= 400) {
          message.error(i18n.t('Error al crear tag'));
        }
        else {
          setState((state) => ({ ...state, tags: resp.body?.tags }));
          setState((state) => ({
            ...state,
            inputVisible: false,
            inputValue: '',
          }));
          message.success('Tags actualizados');
        }
      });
    }
  };

  useEffect(() => {
    if (
      props.personalUserId !== state.personalUserId &&
      Array.isArray(props.tags)
    ) {
      setState({
        personalUserId: props.personalUserId,
        tags: props.tags,
        inputVisible: false,
        inputValue: '',
      });
    }
  }, []);

  const { tags, inputVisible } = state;

  const renderTags = () =>
    tags.map((tag, index) => {
      const isLongTag = tag.length > 20;
      const tagElem = (
        <Tag
          key={tag}
          closable={!onlyView}
          onClose={() => removeTag(tag)}
          style={{ margin: 0 }}
        >
          {isLongTag ? `${tag.slice(0, 20)}...` : tag}
        </Tag>
      );
      return isLongTag ? (
        <Tooltip title={tag} key={tag}>
          {tagElem}
        </Tooltip>
      ) : (
        tagElem
      );
    });

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
      {renderTags()}
      {!onlyView ? (
        inputVisible ? (
          <Input
            type="text"
            size="small"
            style={{ width: 100, paddingRight: '22px' }}
            value={state.inputValue}
            onChange={handleInputChange}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
          />
        ) : (
          <Tag
            onClick={showInput}
            style={{
              background: '#fff',
              borderStyle: 'dashed',
            }}
          >
            <Icon type="plus" /> {i18n.t('commons__new_tag', { ns: 'candidateProfile' })}
          </Tag>
        )
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userName: state.auth.userName,
    token: state.auth.token,
  };
};

const mapDispathToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispathToProps)(Tags);
