import { LOCATION_CHANGE } from 'react-router-redux';
import { SUCCESS, ERROR, WARNING, CLEAN } from '../constants';


export default function alertReducer(state = {}, action) {
    switch (action.type) {
        case SUCCESS:
            return {
                type: 'alert-success',
                message: action.message
            };
        case ERROR:
            return {
                type: 'alert-danger',
                message: action.message
            };
        case WARNING:
            return {
                type: 'alert-warning',
                message: action.message
            }
        case LOCATION_CHANGE:
            return {};
        case CLEAN:
            return {};
        default:
            return state;
    }
}