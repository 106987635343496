import React, { Component, Fragment } from 'react';
import { 
  Row, 
  Col, 
  Button, 
  Form, 
  Input, 
  Checkbox,
  Modal } from 'antd';
class SelectionForm extends Component {
  state = {
    initialOptions: [0, 1],
    visible: false,
    wait_input: false,
    accept_input: false,
    tmp_alternatives: null,
    alternatives_separator: null,
  }

  remove = k => {
    const { getFieldValue, setFieldsValue } = this.props;
    // can use data-binding to get
    const keys = getFieldValue('keys');
    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }

    // can use data-binding to set
    setFieldsValue({
      keys: keys.filter(key => key !== k),
    });
  };

  add = () => {
    const { getFieldValue, setFieldsValue } = this.props;
    // can use data-binding to get
    const keys = getFieldValue('keys');
    const nextKeys = keys.concat(keys.length);
    // can use data-binding to set
    // important! notify form to detect changes
    setFieldsValue({
      keys: nextKeys,
    });
  };

  handleCheckbox = (index) => {
    const { type, getFieldValue, setFieldsValue } = this.props;
    const keys = getFieldValue('keys');
    // chequear solo una alternativa correcta
    // if (type === 'singleselection') {
    //   for (const x in keys) {
    //     if (index !== Number(x)) {
    //       setFieldsValue({ [`correct[${x}]`]: false });
    //     }
    //   }
    // }
  }

  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  hideModal = () => {
    this.setState({
      visible: false,
    })
  }

  confirmAlternatives = () => {
    const { setFieldsValue } = this.props;
    setFieldsValue({
      alternative: this.state.tmp_alternatives,
    })
    this.hideModal()
  }

  multipleAlternativesAtOnce = (alternatives) => {
    this.setState({
      tmp_alternatives: alternatives.filter(alt => alt.length),
    })

    const { getFieldValue, setFieldsValue } = this.props;
    // Se agregan posiciones adicionales a keys, alternatives y correct si es necesario
    const n_keys = getFieldValue('keys').length
    const n_alternatives = alternatives.length
    let previous_alternatives = getFieldValue('alternative')
    let previous_correct = getFieldValue('correct')
    if (n_alternatives > n_keys) {
      for (let i = n_keys + 1; i <= n_alternatives; i++) {
        this.add()
        previous_alternatives = previous_alternatives.concat(undefined)
        previous_correct = previous_correct.concat(undefined)
      }
      setFieldsValue({
        alternative: previous_alternatives,
        correct: previous_correct
      })
    } else if (n_alternatives < n_keys) {
      for (let i = n_alternatives; i < n_keys; i++) {
        this.remove(i)
      }
    }

    this.showModal()
  }

  onChange = (event) => {
    // Parse input to detect multiple alternatives at once
    const input = event.currentTarget.value
    const sep = this.state.alternatives_separator
    let alternatives;
    if (sep == null) {
      alternatives = input.match(/^.*((\r\n|\n|\r)|$)/gm)
    } else {
      alternatives = input.split(sep)
    }
    
    this.multipleAlternativesAtOnce(alternatives)
    this.setState({
      wait_input: false,
      accept_input: true,
    })
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props;
    const { initialOptions } = this.state;

    getFieldDecorator('keys', { initialValue: initialOptions });
    const keys = getFieldValue('keys');

    return (
      <Fragment>
        {this.state.wait_input || this.state.accept_input ?
          <Modal
            title='Copiar listado de alternativas'
            visible={this.state.visible}
            onOk={this.confirmAlternatives}
            onCancel={this.hideModal}
            okText='Confirmar'
            cancelText='Cancelar'
          >
            {this.state.wait_input ?
              <Fragment>
                <p>Copia y pega un listado de alternativas en la casilla</p>
                <br />
                <Form>
                  <Form.Item label="Separador">
                    <Input
                      onChange={(e)=>{
                        this.setState({
                          alternatives_separator: e.target.value,
                        })
                      }}
                      placeholder="Ingresa el caracter que actuará como separador de las alternativas"
                    />
                    <span>Por defecto: Salto de línea. Sugerencias: , ; - / | </span>
                  </Form.Item>
                  <Form.Item label="Lista de alternativas">
                    <Input.TextArea
                      onChange={this.onChange}
                      placeholder="Pega acá la lista de alternativas"
                    />
                  </Form.Item>
                </Form>
              </Fragment>
              : this.state.accept_input ?
                  <Fragment>
                    <p>Confirma que las alternativas estén correctas</p>
                    <br/>
                    <h5>Alternativas</h5>
                    <ul>
                      {this.state.tmp_alternatives.map((alt, i)=>(<li>{`${i+1} - ${alt}`}</li>))}
                    </ul>
                    <br/>
                    <p>IMPORTANTE: Al confirmar, todas las alternativas previas serán reemplazadas por las mostradas.</p>
                  </Fragment>
                  : null}
          </Modal>
        : null}

        <Row gutter={15} type='flex'>
          <Col span={24}>
            <div className='ant-col ant-form-item-label'>
              <label className='ant-form-item-required'>
                Alternativas
              </label>
            </div>
          </Col>

          {keys.map((k, index) => (
            <div key={index} style={{ width: '100%' }}>
              <Col sm={15}>
                <Form.Item>
                  {getFieldDecorator(`alternative[${k}]`, {
                    validateTrigger: ['onChange', 'onBlur'],
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: "Por favor escribe una alternativa",
                      },
                    ],
                  })(<Input placeholder='Alternativa' />)}
                </Form.Item>
              </Col>
              
              {getFieldValue('mandatory') && (
                <Col sm={6}>
                  <Form.Item>
                    {getFieldDecorator(`correct[${k}]`, {
                      initialValue: false,
                      valuePropName: 'checked', 
                      onChange: () => this.handleCheckbox(index)
                    })(
                      <Checkbox><div className='alt-q'>¿Es correcta?</div></Checkbox>
                    )}
                  </Form.Item>
                </Col>
              )}

              {/* <Col sm={1} className='mx-2'>
                <Form.Item>
                  <Button icon='plus' />
                </Form.Item>
              </Col> */}

              <Col sm={1}>
                <Form.Item>
                  {keys.length >= 2 ? (
                    <Button icon='delete' onClick={() => this.remove(k)} />
                  ) : null}
                </Form.Item>
              </Col>

              
              {/* <Row>
                <Button
                  type='ghost'
                  // onClick={this.add}
                  icon='plus-circle'
                  // className="mt-0 mb-5"
                  style={{ margin: '0px 0px 15px 25px' }}
                >
                  Agregar pregunta condicional
                </Button>
              </Row> */}
            </div> 
          ))}

          <Col span={24}>
            <Button
              type='ghost'
              onClick={this.add}
              icon='plus-circle'
            >
              Agregar alternativa
            </Button>
          </Col>
          <Col span={24}>
            <Button
              type='ghost'
              onClick={() => {
                this.setState({
                  wait_input: true,
                  visible: true,
                })
              }}
              icon='plus-circle'
            >
              Copiar lista de alternativas
            </Button>
          </Col>
        </Row>
      </Fragment>
    )
  }
}

export default SelectionForm;