import React, { useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { differenceInMinutes, differenceInSeconds, add } from 'date-fns'
import * as actionCreators from '../../../../actions/data';

import {
  Button,
  Col,
  DatePicker,
  Icon,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Typography,
} from 'antd';
import MceEditor from '../../NewProcessFormV2/components/mceeditor/MceEditor';
import i18n from '../../../../i18n';

const { Text } = Typography;
const { TextArea } = Input;

const MoveModal = ({
  actions,
  discardReasons,
  token,
  business,
  visible,
  handleVisible,
  candidatesLength,
  callback,
  jobStages,
  stagesOnFocus,
  mode,
  candidatesName = [],
  selectedCandidates,
  jobappid,
  isStageBulk = false,
}) => {
  const captchaRef = React.createRef();

  const [nextStage, setNextStage] = useState(null);
  const [selectedReasonId, setSelectedReasonId] = useState(null)
  const [comment, setComment] = useState(null)
  const [addEmail, setAddEmail] = useState(false)
  const [emailBody, setEmailBody] = useState(i18n.t('discard_reason__default_email'))
  const [myTemplates, setMyTemplates] = useState({})
  const [programmedEmailDate, setProgrammedEmailDate] = useState(null)
  const [personalized, setPersonalized] = useState(false)

  useEffect(() => {
    if (visible && mode == 'discard') {
      const [defaultReason] = discardReasons.filter(({title}) => title === 'Omitida')
      if (defaultReason) {
        setSelectedReasonId(defaultReason.id)
      } else {
        setSelectedReasonId(discardReasons[0])
      }
      getTemplates();
    }
  }, [visible])

  const getTemplates = async () => {
    const url = `/api/v1/accounts/business/${business.id}/mailtemplates/`;
    const response = await actions.fetchAndReturn(token, url, 'GET', '')
      setMyTemplates(response.body.results);
  };

  const getCandidates = (candidates) => {
    let c = {};
    for (let lane in candidates) {
      c = { ...c, ...candidates[lane] };
    }
    return Object.values(c);
  };

  const getIds = (candidates) => {
    let c = {};
    for (let lane in candidates) {
      c = { ...c, ...candidates[lane] };
    }
    return Object.keys(c);
  };

  const handlePersonalizedProgrammedEmailDate = (date) => {   
    if (!date) {
      setProgrammedEmailDate(date)
      setPersonalized(false)
    } else {
      const diff = differenceInMinutes(new Date(date), new Date())
      if (diff < 0) {
        message.error(i18n.t('discard_reason__passed_date_warning'))
      } else {
        setProgrammedEmailDate(date)
      }
    };   
  };

  const handleStandardProgrammedEmailDate = (timeframe) => {
    if (timeframe === 'personalized') { 
      setPersonalized(true)
    } else {
      if (timeframe === 0) {
        setProgrammedEmailDate(null)
      } else {
      setProgrammedEmailDate(add(new Date(), { hours: timeframe }))
      }
    }
  };
  const handleCancelPerzonalizedEmailDate = () => {
    setPersonalized(false)
    setProgrammedEmailDate(null)
  }

  const handleDiscardAndSendMail = async () => {
    const captchaToken = await captchaRef.current.executeAsync();

    const url = '/api/v1/gamesandtests/sendmail/';
    const candidates = selectedCandidates;

    const ids = getIds(candidates);
    const users = getCandidates(candidates);

    for (const x in users) {
      users[x].id = ids[x];
    }
    const floorDivision = (number, divisor) =>
      (number - (number % divisor)) / divisor;
    const userBatches = [
      ...Array(
        floorDivision(users.length, 50) + (users.length % 50 > 0)
      ).keys(),
    ].map(() => ({
      subject: 'Genomawork', 
      body: emailBody,
      from: 'no-reply@genoma.work',
      job_application_id: jobappid, 
      users: [] }));
    users.forEach((user, index) => {
      userBatches[floorDivision(index, 50)].users.push(user);
    }); 
    let sendEmailMessage;
    if (programmedEmailDate) {
      const diff = differenceInMinutes(new Date(programmedEmailDate), new Date())
      if (diff > 0) {
        userBatches.forEach((batch) => {
          batch.send_at = differenceInSeconds(new Date(programmedEmailDate), new Date())
        })
      } 
      sendEmailMessage = i18n.t('discard_reason__programmed_mails')
    } else {
      sendEmailMessage = i18n.t('discard_reason__sent_mails')
    }
    await callback(refreshModal, selectedReasonId, comment)  
    try {
      await Promise.all(
        userBatches.map((userBatch) => {
          actions.fetchAndReturn(
            token,
            url,
            'POST',
            userBatch,
            { Captcha: captchaToken }
          );
        })
      );
      refreshModal();
      message.success(sendEmailMessage);  
    } catch (e) {
        Sentry.captureException(e);
        message.error(i18n.t('messages__error_sending_discard_email'))
        refreshModal();
    }
    
  };

  const refreshModal = () => {
    handleVisible.off()
    const [defaultReason] = discardReasons.filter(({title}) => title === 'Omitida')
    if (defaultReason) {
      setSelectedReasonId(defaultReason.id)
    } else {
      setSelectedReasonId(discardReasons[0])
    }
    setAddEmail(false)
    setEmailBody(i18n.t('discard_reason__default_email'))
    setComment(null)
    setProgrammedEmailDate(null)
  };

  const config = () => { 
    switch (mode) {
      case 'move':   
        return {
          title: i18n.t('actions__move_stage'),
          button: (
            <Button
              type="primary"
              onClick={() => callback(nextStage, handleVisible.off)}
            >
              {i18n.t('commons__move')}
            </Button>
          ),
          content: (
            <>
              <Text strong>{i18n.t('commons__stages')}</Text>
              <Select
                style={{ width: '100%', marginBlock: '0.5rem 1rem' }}
                placeholder={i18n.t('move_modal__select_stage')}
                onChange={(value) => setNextStage(value)}
              >
                {jobStages
                  ?.filter(
                    (stage) =>
                      stage.code !== stagesOnFocus
                  )
                  .map((stage) => (
                    <Select.Option value={stage.code} key={stage.code}>
                      {stage.name}
                    </Select.Option>
                  ))}
              </Select>
            </>
          ),
        };
      case 'discard':
        return {
          title: i18n.t('discard_reason__title'),
          button: addEmail ?
          (
            <>
              <Button
                type="danger"
                icon="stop"
                onClick={() => handleDiscardAndSendMail()}
              >
                {programmedEmailDate ?  
                  i18n.t('discard_reason__discard_and_program_mail') : 
                  i18n.t('discard_reason__discard_and_send_mail')
                }
              </Button>
              <ReCAPTCHA
                ref={captchaRef}
                size="invisible"
                sitekey={process.env.REACT_APP_CAPTCHA_API_KEY}
              />
            </>
          ) : 
          (
            <Button
            icon="stop"
            type="danger"
            onClick={() => callback(refreshModal, selectedReasonId, comment)}
          >
            {i18n.t('discard_reason__discard')}
          </Button>
        ),
        content: (
          discardReasons.length > 0 && (
            <div className="move-modal-common-container">
              {!isStageBulk
              ? candidatesName.map((name, i) => (
                  <Text style={{ color: '#A4A4A4' }} key={name + i}>
                    {name}
                  </Text>
                ))
              : null}
              <Text strong><Text type='danger'>*</Text>{i18n.t('discard_reason__choose_discard_reason')}</Text>
              <Text>{i18n.t('discard_reason__privacy_of_reason')}</Text>
              <Select 
                showSearch
                onSelect={(value) => setSelectedReasonId(value)} 
                value={selectedReasonId}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {discardReasons.map((discardReason) => (
                  <Select.Option value={discardReason.id} key={discardReason.id}>
                    {discardReason.title}
                  </Select.Option>
                ))}
              </Select>
              <Text strong>{i18n.t('commons__comment')} <Text disabled>{`(${i18n.t('commons__optional')})`}</Text></Text>
              <TextArea 
                rows={3} 
                placeholder={i18n.t('discard_reason__comment_explanation')} 
                maxLength={100}
                onChange={(e) => setComment(e.target.value)}
                value={comment}
              />
              <Text strong>{i18n.t('discard_reason__send_mail')} <Text disabled>{`(${i18n.t('commons__optional')})`}</Text></Text>
              <Radio.Group
                value={addEmail}
                onChange={(e) => { setAddEmail(e.target.value) }}
              >
                <Radio.Button value={true}>{i18n.t('commons__capitalized_yes')}</Radio.Button>
                <Radio.Button value={false}>{i18n.t('commons__capitalized_no')}</Radio.Button>
              </Radio.Group>
              {addEmail && (
                <>
                  <MceEditor 
                    body={emailBody}
                    disabled={false}
                    token={token}
                    handleChange={
                      (fieldName) => (event) => setEmailBody(event.target.value)
                    }
                    name='body'
                  />
                  <Row gutter={8}>
                    <Col span={12}>
                      <Text strong>{i18n.t('commons__email_template')}</Text>
                    </Col>
                    <Col span={12}>
                      <Text strong>{i18n.t('commons__schedule_email')}</Text>
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={12}>
                      <Select style={{ width: '100%' }} onSelect={(value) => setEmailBody(value)}>
                        {myTemplates
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((template, i) => (
                            <Select.Option key={i} value={template.template}>{template.name}</Select.Option>
                          ))}
                      </Select>
                    </Col>
                    <Col span={12}>
                      {
                        personalized ? (
                          <div style={{ 'display': 'flex' }}>
                            <DatePicker
                              showTime={{
                                format: "HH:mm"
                              }}
                              onChange={handlePersonalizedProgrammedEmailDate}                   
                              disabledDate={(current) => current.valueOf() < add(new Date(), {days: -1})}
                              format='YYYY/MM/DD HH:mm'
                            /> 
                            <Button type='link' onClick={() => handleCancelPerzonalizedEmailDate()}>
                              <Icon type='close' />
                            </Button>
                          </div>                      
                        ) : (
                          <Select 
                            style={{ width: '100%' }} 
                            onSelect={(value) => handleStandardProgrammedEmailDate(value)}
                            defaultValue={0}
                          >
                            <Select.Option value={0}>{i18n.t('discard_reason__not_programmed')}</Select.Option>
                            <Select.Option value={3}>3 {i18n.t('commons__hours')}</Select.Option>
                            <Select.Option value={24}>1 {i18n.t('commons__day')}</Select.Option>
                            <Select.Option value={168}>1 {i18n.t('commons__week')}</Select.Option>
                            <Select.Option value='personalized'>{i18n.t('discard_reason__personalized')}</Select.Option>
                          </Select>
                        )
                      }               
                    </Col>
                  </Row>
                </>)}
          </div>
        ))
      };
      case 'reclassify':
        return {
            title: i18n.t('commons__reincorporate_candidates'),
            button: (
              <Button
                type="primary"
                icon="undo"
                onClick={() => callback('ACTIV', handleVisible.off, 'state')}
              >
                {i18n.t('commons__reincorporate')}
              </Button>
            ),
            content: (
              <div className="move-modal-common-container">
                <Text strong>
                  {isStageBulk ?
                      i18n.t('messages__warning_reincorporate_everyone_on_stage') :
                      i18n.t('messages__warning_reincorporate_x_candidates', { candidatesLength })
                  }
                </Text>
                {!isStageBulk
                  ? candidatesName.map((name, i) => (
                      <Text style={{ color: '#A4A4A4' }} key={name + i}>
                        {name}
                      </Text>
                    ))
                  : null}
              </div>
            ),
          };
      default:
        return {};
      }
  };

  return (

    <Modal
      title={
        <div
          style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
        >
          <span>
            <strong>{config().title}</strong>
          </span>
          <Text style={{ fontSize: 14 }}>
            {isStageBulk
              ? 'Toda la etapa seleccionada'
              : `${candidatesLength} candidato${
                  candidatesLength > 1 ? 's' : ''
                } seleccionado${candidatesLength > 1 ? 's' : ''}`}
          </Text>
        </div>
      }
      visible={visible}
      onCancel={refreshModal}
      footer={[
        <Button onClick={() => {
          refreshModal()
        }}
          >
          {i18n.t('commons__cancel')}
          </Button>,
        config().button,
      ]}
    >
      {config().content}
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    business: state.auth.business,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MoveModal);