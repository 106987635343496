import React, { useContext } from 'react';

import {
  Button,
  Col,
  Divider,
  Form,
  Modal,
  Radio,
  Row,
  Select,
  Typography,
} from 'antd';
import PropTypes from 'prop-types';

import { PersonalFormContext } from '../config/Context';
import { careerAreaOptions, careerStateOptions, personalFormLabels, yearsOptions } from '../constants';
import i18n from '../../../i18n';
import DebouncedSelect from '../../../components/input/DebouncedSelect';

const { OptGroup, Option } = Select;
const { Text } = Typography;

const { careersInformation: careersLabels } = personalFormLabels;

function CareerInformation(props) {
  const {
    idx,
    careerData,
    getFieldDecorator,
    getFieldValue,
    setFieldsValue,
    validateFields,
  } = props;

  const {
    career_area,
    career_entry_year,
    career_institution,
    career_last_year,
    career_name,
    career_state,
    career_type,
  } = careerData;

  const { removeCareer, updateCareer } = useContext(PersonalFormContext);

  const today = new Date();
  const currentYear = today.getFullYear();

  return (
    <Row align="top" gutter={24} key={`${idx}-${Object.values(careerData).join('-')}`} type="flex">
      <Col key={`col-name-${career_name}`} xs={24} md={12}>
        <Form.Item
          colon={false}
          key={`name-${career_name}`}
          label={<><Text style={{ color: 'red' }}>*</Text>{careersLabels.career_name}</>}
        >
          {getFieldDecorator(`career_name-${idx}`, {
            initialValue: career_name,
            rules: [
              { required: true, message: i18n.t('commons__required__field') },
            ],
          })(
            <DebouncedSelect
              fieldName={`career_name-${idx}`}
              placeholder={i18n.t('form__select__career')}
              searchApiUrl={`/api/v1/accounts/careers/?language=${i18n.language.split('-')[0]}`}
              setValue={(fieldObj) => {
                setFieldsValue(fieldObj);
                updateCareer(idx, 'career_name', fieldObj[`career_name-${idx}`]);
              }}
            />
          )}
        </Form.Item>
      </Col>
      <Col key={`col-institution-${career_institution}`} xs={24} md={12}>
        <Form.Item
          colon={false}
          key={`institution-${career_institution}`}
          label={<><Text style={{ color: 'red' }}>*</Text>{careersLabels.career_institution}</>}
        >
          {getFieldDecorator(`career_institution-${idx}`, {
            initialValue: career_institution,
            rules: [
              { required: true, message: i18n.t('commons__required__field') },
            ],
          })(
            <DebouncedSelect
            fieldName={`career_institution-${idx}`}
            placeholder={i18n.t('form__select__university')}
            searchApiUrl={`/api/v1/accounts/universities/?language=${i18n.language.split('-')[0]}`}
            setValue={(fieldObj) => {
              setFieldsValue(fieldObj);
              updateCareer(idx, 'career_institution', fieldObj[`career_institution-${idx}`]);
            }}
          />
          )}
        </Form.Item>
      </Col>
      <Col key={`col-area-${career_area}`} xs={24} md={12}>
        <Form.Item
          colon={false}
          key={`area-${career_area}`}
          label={<><Text style={{ color: 'red' }}>*</Text>{careersLabels.career_area}</>}
        >
          {getFieldDecorator(`career_area-${idx}`, {
            initialValue: career_area,
            onChange: (option) => updateCareer(idx, 'career_area', option),
            rules: [
              { required: true, message: i18n.t('commons__required__field') },
            ],
          })(
            <Select
              placeholder={i18n.t('form__area')}
              showSearch
              optionFilterProp='children'
              notFoundContent={i18n.t('commons__no__results')}
            >
              {careerAreaOptions.map(({ groupName, groupLabels }) => (
                <OptGroup key={groupName} label={groupName}>
                  {groupLabels.map((item, index) => (
                    <Option key={index + item.value} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </OptGroup>
              ))}
            </Select>
          )}
        </Form.Item>
      </Col>
      <Col key={`col-state-${career_state}`} xs={24} md={12}>
        <Form.Item
          colon={false}
          key={`state-${career_state}`}
          label={<><Text style={{ color: 'red' }}>*</Text>{careersLabels.career_state}</>}
        >
          {getFieldDecorator(`career_state-${idx}`, {
            initialValue: career_state,
            onChange: (option) => updateCareer(idx, 'career_state', option),
            rules: [
              { required: true, message: i18n.t('commons__required__field') },
            ],
          })(
            <Select placeholder={i18n.t('commons__status')}>
              {careerStateOptions.map((opt, index) => (
                <Option key={opt.value}>
                  {opt.label}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Col>
      <Col key={`col-entry_year-${career_entry_year}`} xs={24} md={12}>
        <Form.Item
          colon={false}
          key={`entry_year-${career_entry_year}`}
          label={<><Text style={{ color: 'red' }}>*</Text>{careersLabels.career_entry_year}</>}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator(`career_entry_year-${idx}`, {
            initialValue: career_entry_year,
            onChange: (option) => updateCareer(idx, 'career_entry_year', option),
            rules: [
              { required: true, message: i18n.t('commons__required__field') },
              {
                validator: (rule, value, callback) => {
                  validateFields([`career_last_year-${idx}`], { force: true });
                  callback();
                },
                type: 'number',
              }
            ],
          })(
            <Select placeholder={i18n.t('commons__year')}>
              {yearsOptions.slice(0, (new Date().getFullYear()) - 1920 + 1).reverse().map(
                (opt, index) => <Option value={opt} key={index + opt}>{opt}</Option>)}
            </Select>
          )}
        </Form.Item>
      </Col>
      <Col key={`col-last_year-${career_last_year}`} xs={24} md={12}>
        <Form.Item
          colon={false}
          key={`last_year-${career_last_year}`}
          label={careersLabels.career_last_year}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator(`career_last_year-${idx}`, {
            initialValue: career_last_year ?? undefined,
            onChange: (option) => {
              updateCareer(idx, 'career_last_year', option || null);
              if (option && option > currentYear) {
                setFieldsValue({ [`career_state-${idx}`]: 'CUR' });
                updateCareer(idx, 'career_state', 'CUR');
              }
            },
            rules: [
              {
                validator: (rule, value, callback) => {
                  const entry_year = parseInt(getFieldValue(`career_entry_year-${idx}`));
                  if (value && entry_year && value < entry_year) {
                    callback(i18n.t('form__career_last_year_error'));
                  } else {
                    callback();
                  }
                },
                type: 'number',
              }
            ],
          })(
            <Select allowClear placeholder={i18n.t('commons__year')}>
              {yearsOptions.slice(0, (new Date().getFullYear()) - 1910 + 1).reverse().map(
                (opt, index) => <Option value={opt} key={index + opt}>{opt}</Option>)}
            </Select>
          )}
        </Form.Item>
      </Col>
      <Col key={`col-type-${career_type}`} span={24}>
        <Form.Item
          colon={false}
          key={`type-${career_type}`}
          label={<><Text style={{ color: 'red' }}>*</Text>{careersLabels.career_type}</>}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator(`career_type-${idx}`, {
            initialValue: career_type,
            onChange: (event) => updateCareer(idx, 'career_type', event.target.value),
            rules: [
              { required: true, message: i18n.t('commons__required__field') },
            ],
          })(
            <Radio.Group>
              <Radio value={'TECPRO'}>{i18n.t('form__career_type_option')}</Radio>
              <Radio value={'PREG'}>{i18n.t('form__academic_level_7')}</Radio>
              <Radio value={'POSTG'}>{i18n.t('form__academic_level_4')}</Radio>
              <Radio value={'OTHER'}>{i18n.t('form__other')}</Radio>
            </Radio.Group>
          )}
        </Form.Item>
      </Col>
      <Col span={24} style={{ textAlign: 'end' }}>
        <Button
          onClick={() => Modal.confirm({
            cancelText: i18n.t('commons__cancel'),
            content: i18n.t('commons__confirm_question'),
            okText: i18n.t('commons__confirm'),
            onOk: () => removeCareer(idx),
            title: `${i18n.t('commons__delete')} ${i18n.t('commons__career')} ${career_name ?? ''}`,
          })}
          style={{ color: '#F175A5', padding: 0 }}
          type="link"
        >
          {i18n.t('commons__delete')}
        </Button>
      </Col>
      <Divider style={{ marginTop: 0 }} />
    </Row>
  );
}

CareerInformation.propTypes = {
  idx: PropTypes.number.isRequired,
  careerData: PropTypes.shape({
    career_area: PropTypes.string,
    career_entry_year: PropTypes.string | PropTypes.number,
    career_institution: PropTypes.string,
    career_last_year: PropTypes.string | PropTypes.number,
    career_name: PropTypes.string,
    career_state: PropTypes.string,
    career_type: PropTypes.string,
  }).isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
  getFieldValue: PropTypes.func.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
  validateFields: PropTypes.func.isRequired,
};

export default CareerInformation;
