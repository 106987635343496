import React, { useContext } from 'react';

import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

import { PersonalFormContext } from '../config/Context';
import { careerAreaOptions, personalFormLabels } from '../constants';
import i18n from '../../../i18n';

const { OptGroup, Option } = Select;
const { Text } = Typography;

const { certificationsInformation: certificationsLabels } = personalFormLabels;

function CertificationInformation(props) {
  const {
    idx,
    certificationData,
    getFieldDecorator,
    getFieldValue,
    setFieldsValue,
    validateFields,
  } = props;

  const { name, institution, area, certificate_url, length, length_unit, start_date, end_date, state } = certificationData;

  const { removeCertification, updateCertification } = useContext(PersonalFormContext);

  const today = new Date();

  return (
    <Row align="top" gutter={24} type="flex">
      <Col xs={24} md={12}>
        <Form.Item
          colon={false}
          label={<><Text style={{ color: 'red' }}>*</Text>{certificationsLabels.certification_name}</>}
        >
          {getFieldDecorator(`certification_name-${idx}`, {
            initialValue: name,
            onChange: (event) => updateCertification(idx, 'name', event.target.value),
            rules: [
              { required: true, message: i18n.t('commons__required__field') },
            ],
          })(
            <Input allowClear placeholder={i18n.t('form__enter_certification')} />
          )}
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <Form.Item
          colon={false}
          label={<><Text style={{ color: 'red' }}>*</Text>{certificationsLabels.certification_institution}</>}
        >
          {getFieldDecorator(`certification_institution-${idx}`, {
            initialValue: institution,
            onChange: (event) => updateCertification(idx, 'institution', event.target.value),
            rules: [
              { required: true, message: i18n.t('commons__required__field') },
            ],
          })(
            <Input allowClear placeholder={i18n.t('form__enter__institution')} />
          )}
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <Form.Item
          colon={false}
          label={certificationsLabels.certification_area}
        >
          {getFieldDecorator(`certification_area-${idx}`, {
            initialValue: area,
            onChange: (option) => updateCertification(idx, 'area', option || null),
          })(
            <Select
              allowClear
              placeholder={i18n.t('form__area')}
              showSearch
              optionFilterProp='children'
              notFoundContent={i18n.t('commons__no__results')}
            >
              {careerAreaOptions.map(({ groupName, groupLabels }) => (
                <OptGroup key={groupName} label={groupName}>
                  {groupLabels.map((item, index) => (
                    <Option key={index + item.value} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </OptGroup>
              ))}
            </Select>
          )}
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <Row gutter={8} type="flex">
          <Col span={12}>
            <Form.Item colon={false} label={certificationsLabels.certification_length}>
              {getFieldDecorator(`certification_length-${idx}`, {
                initialValue: length,
                onChange: (value) => updateCertification(idx, 'length', value || null),
                rules: [
                  {
                    message: i18n.t('form__certification_duration__invalid_value'),
                    pattern: /^0*?[1-9]\d*$/,
                  },
                  {
                    validator: (rule, value, callback) => {
                      if (value > 0 && !value && getFieldValue(`certification_length_unit-${idx}`)) {
                        callback(i18n.t('form__certification_duration__error'));
                      } else if (value > 0 && !getFieldValue(`certification_length_unit-${idx}`)) {
                        validateFields([`certification_length_unit-${idx}`], { force: true });
                      } else {
                        callback();
                      }
                    },
                    type: 'number',
                  }
                ],
              })(
                <InputNumber
                  placeholder={i18n.t('commons__duration')}
                  style={{ borderRadius: '2px', marginTop: '4px', width: '100%' }}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item colon={false} label="-">
              {getFieldDecorator(`certification_length_unit-${idx}`, {
                initialValue: length_unit,
                onChange: (option) => updateCertification(idx, 'length_unit', option),
                rules: [
                  {
                    validator: (rule, value, callback) => {
                      if (!value && getFieldValue(`certification_length-${idx}`)) {
                        callback(i18n.t('form__certification_duration_unit__error'));
                      } else if (value && !getFieldValue(`certification_length-${idx}`)) {
                        validateFields([`certification_length-${idx}`], { force: true });
                      } else {
                        callback();
                      }
                    }
                  }
                ],
              })(
                <Select
                  allowClear
                  placeholder={i18n.t('commons__unit')}
                  style={{ width: '100%' }}
                >
                  <Option value="HRS">{i18n.t('commons__hours')}</Option>
                  <Option value="WKS">{i18n.t('commons__weeks')}</Option>
                  <Option value="MTH">{i18n.t('commons__months')}</Option>
                  <Option value="SMS">{i18n.t('commons__semesters')}</Option>
                  <Option value="YRS">{i18n.t('commons__years')}</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col xs={24} md={12}>
        <Form.Item
          colon={false}
          label={<><Text style={{ color: 'red' }}>*</Text>{certificationsLabels.certification_start_date}</>}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator(`certification_start_date-${idx}`, {
            initialValue: start_date,
            onChange: (newStartDate) => updateCertification(idx, 'start_date', newStartDate ?? null),
            rules: [
              { required: true, message: i18n.t('commons__required__field') },
            ],
          })(
            <DatePicker
              disabledDate={(current) => current && current >= moment()}
              placeholder={i18n.t('form__start__day')}
              style={{ width: '100%' }}
            />
          )}
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <Form.Item
          colon={false}
          label={certificationsLabels.certification_end_date}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator(`certification_end_date-${idx}`, {
            initialValue: end_date ?? null,
            onChange: (newEndDate) => {
              updateCertification(idx, 'end_date', newEndDate ?? null);
              if (newEndDate && newEndDate < today) {
                updateCertification(idx, 'state', 'FIN');
                setFieldsValue({ [`certification_state-${idx}`]: 'FIN' });
              } else if (newEndDate && newEndDate >= today) {
                updateCertification(idx, 'state', 'CUR');
                setFieldsValue({ [`certification_state-${idx}`]: 'CUR' });
              } else {
                updateCertification(idx, 'state', 'OTHER');
                setFieldsValue({ [`certification_state-${idx}`]: 'OTHER' });
              }
            },
          })(
            <DatePicker placeholder={i18n.t('commons__end__date')} style={{ width: '100%' }} />
          )}
          <Checkbox
            checked={state === 'CUR' && (!end_date || end_date >= today)}
            onChange={(e) => {
              if (e.target.checked) {
                updateCertification(idx, 'state', 'CUR');
                if (getFieldValue(`certification_end_date-${idx}`) < today) {
                  updateCertification(idx, 'end_date', null);
                  setFieldsValue({ [`certification_end_date-${idx}`]: null });
                }
              } else {
                const end_date = getFieldValue(`certification_end_date-${idx}`);
                updateCertification(idx, 'state', (end_date && end_date < today) ? 'FIN' : 'OTHER');
              }
            }}
          >
            {i18n.t('form__status__3')}
          </Checkbox>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          colon={false}
          label={certificationsLabels.certification_url}
        >
          {getFieldDecorator(`certificate_url-${idx}`, {
            initialValue: certificate_url,
            onChange: (event) => updateCertification(idx, 'certificate_url', event.target.value),
            rules: [
              {
                message: i18n.t('form__certification_duration__invalid_value'),
                type: 'url',
              },
            ],
          })(
            <Input
              allowClear
              placeholder={i18n.t('form__certification_link_placeholder')}
            />
          )}
        </Form.Item>
      </Col>
      <Col span={24} style={{ textAlign: 'end' }}>
        <Button
          onClick={() => Modal.confirm({
            cancelText: i18n.t('commons__cancel'),
            content: i18n.t('commons__confirm_question'),
            okText: i18n.t('commons__confirm'),
            onOk: () => removeCertification(idx),
            title: `${i18n.t('commons__delete')} ${i18n.t('commons__certification')}`,
          })}
          style={{ color: '#F175A5', padding: 0 }}
          type="link"
        >
          {i18n.t('commons__delete')}
        </Button>
      </Col>
      <Divider style={{ marginTop: 0 }} />
    </Row>
  );
}

CertificationInformation.propTypes = {
  idx: PropTypes.number.isRequired,
  certificationData: PropTypes.shape({
    name: PropTypes.string,
    institution: PropTypes.string,
    area: PropTypes.string,
    certificate_url: PropTypes.string,
    length: PropTypes.number,
    length_unit: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    state: PropTypes.string,
  }).isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
  getFieldValue: PropTypes.func.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
  validateFields: PropTypes.func.isRequired,
};

export default CertificationInformation;
