import { Button, Col, Typography, Icon, Row, Tag, Tooltip } from 'antd';
import React, { useState, useEffect } from 'react';

const buttonStyle = {
  border: '1px solid #F175A5',
  color: '#F175A5',
  flexGrow: 1,
};

const IntegrationItem = ({
  id,
  description,
  image,
  name,
  i,
  country,
  getBusinessIntegration,
  onChangeBusinessIntegrationDataFn,
  postNewIntegrationFn,
  showConfirm,
  setGenomesVisible,
  setVisible,
}) => {
  const showGenomesButton =
    getBusinessIntegration(name, country)?.status === 'ACTIV' &&
    getBusinessIntegration(name, country).fields['importable_jobs'];

  return (
    <Col key={i}>
      <div className="integration-card">
        <div className="available-integrations">
          <Row type="flex" style={{ flexDirection: 'column', gap: '0.5rem' }}>
            <img className="integration-image" src={image} />
            <Typography.Text style={{ fontSize: 14 }} strong>
              {`${name}${country && ` - ${country}`}`}
            </Typography.Text>
          </Row>

          {!getBusinessIntegration(name, country) ||
          getBusinessIntegration(name, country)?.status === 'INACT' ? (
            <Tag className="disconnected-integration-tag">
              <Icon type="close-circle" /> No conectado
            </Tag>
          ) : (
            <Tag className="connected-integration-tag">
              <Icon type="check-circle" /> Conectado
            </Tag>
          )}
        </div>

        <Typography.Text
          className="integration-description"
          style={{ fontSize: 13, flexGrow: 1 }}
        >
          {description}
        </Typography.Text>

        <div className="integration-buttons-row">
          <Button
            onClick={() => {
              getBusinessIntegration(name, country)
                ? onChangeBusinessIntegrationDataFn(
                    getBusinessIntegration(name, country).auth_token,
                    getBusinessIntegration(name, country).base_url,
                    getBusinessIntegration(name, country).id,
                    getBusinessIntegration(name, country).fields,
                    getBusinessIntegration(name, country).genomes,
                    getBusinessIntegration(name, country).template
                  )
                : postNewIntegrationFn(id);
              setVisible(true);
            }}
            style={showGenomesButton ? { flexGrow: 1 } : buttonStyle}
          >
            Gestionar integración
          </Button>

          <Tooltip title="Borrar">
            <Button
              icon="delete"
              disabled={
                !getBusinessIntegration(name, country)?.status === 'ACTIV'
              }
              onClick={() => {
                showConfirm(getBusinessIntegration(name, country).id);
              }}
            />
          </Tooltip>

          {showGenomesButton && (
            <Button
              onClick={() => {
                onChangeBusinessIntegrationDataFn(
                  getBusinessIntegration(name, country).auth_token,
                  getBusinessIntegration(name, country).base_url,
                  getBusinessIntegration(name, country).id,
                  getBusinessIntegration(name, country).fields,
                  getBusinessIntegration(name, country).genomes
                );

                setGenomesVisible(true);
              }}
              style={buttonStyle}
            >
              Obtener genomas
            </Button>
          )}
        </div>
      </div>
    </Col>
  );
};

export default IntegrationItem;
