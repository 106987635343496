import React, { useState, useContext, useEffect } from 'react';
import { CandidateContext } from '../config/CandidateContext';

import { Button, Input, Popconfirm, Typography } from 'antd';

import useBoolean from '../../../hooks/useBoolean';
import i18n from '../../../i18n';

const { Text } = Typography;

const Notes = () => {
  const { application, saveNote, sharing, translationFile } = useContext(CandidateContext);

  const [newNote, setNewNote] = useState('');
  const [isWriting, handleIsWriting] = useBoolean(false);

  const handleChange = (e) => {
    if (!isWriting) {
      handleIsWriting.on();
    }
    setNewNote(e.target.value);
  };

  const handleSend = (note) => {
    saveNote(note);
    handleIsWriting.off();
  };

  const handleCancel = () => {
    setNewNote(application.note);
    handleIsWriting.off();
  };

  useEffect(() => {
    setNewNote(application.note);
  }, [application.note]);

  return (
    <div className="new-modal-information__note">
      <Text strong className="text-16px">
        {i18n.t('profile__team_notes', translationFile)}
      </Text>
      <Input.TextArea
        placeholder={i18n.t('profile__new_note', translationFile)}
        autoSize={{ minRows: 2, maxRows: 10 }}
        maxLength={1500}
        onChange={handleChange}
        value={newNote}
        disabled={sharing}
        style={{ color: '#0d0c22' }}
      />
      {newNote?.length && isWriting ? (
        <div className="new-modal-information__note__btns">
          <Popconfirm
            placement="bottomLeft"
            title={
              <span>
                <strong>{i18n.t('profile__action_restore_original_note', translationFile)}.</strong>{' '}
                {i18n.t('profile__are_you_sure', translationFile)}
              </span>
            }
            onConfirm={() => handleCancel()}
            okText={i18n.t('commons__yes', translationFile)}
            cancelText={i18n.t('commons__no', translationFile)}
          >
            <Button type="link">{i18n.t('profile__cancel', translationFile)}</Button>
          </Popconfirm>
          <Button type="link" onClick={() => handleSend(newNote)}>
            <span>{i18n.t('profile__post_note', translationFile)}</span>
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default Notes;
