import React, { useMemo, useCallback, useRef, useEffect, useState } from 'react'
import { Editor, Transforms, Range, createEditor } from 'slate'
import { withHistory } from 'slate-history'
import { Slate, Editable, ReactEditor, withReact } from 'slate-react'

import { Portal, Toolbar } from './components'
import { 
  BlockButton,
  Element,
  Leaf,
  LinkButton,
  MarkButton,
  MentionButton,
  ShareButton,
  UploadButton
} from './plugins'
import { Avatar } from 'antd'
import i18n from '../../../../../i18n'
import './RichTextEditor.scss'


const initialValue = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  }
]

const RichTextEditor = ({
  attachedFiles,
  isPublic,
  handleEditorChange, 
  mentionOptions, 
  resetValueState,
  selectedItems,
  setAttachedFiles,
  setSelectedItems,
  setIsPublic,
  setResetValueState,
}) => {
  const ref = useRef() // HTMLDivElement | null
  const [target, setTarget] = useState() // Range | undefined
  const [index, setIndex] = useState(0)
  const [search, setSearch] = useState('')
  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const editor = useMemo(() => withMentions(withReact(withHistory(createEditor()))), [])

  const filteredMentionOptions = mentionOptions.filter(({ label }) =>
    label.toLowerCase().includes(search.toLowerCase())
  ).slice(0, 10)

  const onKeyDown = useCallback(
    event => {
      if (target && filteredMentionOptions.length > 0) {
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault()
            const prevIndex = index >= filteredMentionOptions.length - 1 ? 0 : index + 1
            setIndex(prevIndex)
            break
          case 'ArrowUp':
            event.preventDefault()
            const nextIndex = index <= 0 ? filteredMentionOptions.length - 1 : index - 1
            setIndex(nextIndex)
            break
          case 'Tab':
          case 'Enter':
            event.preventDefault()
            Transforms.select(editor, target)
            insertMention(editor, filteredMentionOptions[index].label)
            setTarget(null)
            break
          case 'Escape':
            event.preventDefault()
            setTarget(null)
            break
          case 'ArrowLeft':
            event.preventDefault()
            Transforms.move(editor, { unit: 'offset', reverse: true })
            break
          case 'ArrowRight':
            event.preventDefault()
            Transforms.move(editor, { unit: 'offset' })
            break
        }
      }
    },
    [filteredMentionOptions, editor, index, target]
  )

  useEffect(() => {
    if (target && filteredMentionOptions.length > 0) {
      const el = ref.current
      const domRange = ReactEditor.toDOMRange(editor, target)
      const rect = domRange.getBoundingClientRect()
      el.style.top = `${rect.top + window.pageYOffset + 24}px`
      el.style.left = `${rect.left + window.pageXOffset}px`
    }
  }, [filteredMentionOptions.length, editor, index, search, target])

  useEffect(() => {
    if (resetValueState) {
      Transforms.select(editor, {
        anchor: Editor.start(editor, []),
        focus: Editor.end(editor, []),
      })
      const marks = ['bold', 'italic', 'underline', 'numbered-list', 'bulleted-list']
      marks.forEach(mark => Editor.removeMark(editor, mark))

      Transforms.delete(editor);
      setResetValueState(false)
    }
  }, [resetValueState])

  const typeHtmlTags = (element) => {
    if (Object.keys(element).includes('text')) {
      let html = element.text
      if (html === '') return '';
      if (element.bold) {
        html = `<strong>${html}</strong>`
      }
      if (element.italic) {
        html = `<em>${html}</em>`
      }
      if (element.underline) {
        html = `<u>${html}</u>`
      }
      return html
    }
    if (
      element.children.length > 0 && 
      element.children.every((child) => child.text === '') &&
      !Object.keys(element).includes('character')
    ) {
      return '';
    };
    
    switch (element.type) {
      case 'paragraph':
        return `<p>${element.children.map((child) => typeHtmlTags(child)).join('')}</p>`
      case 'numbered-list':
        return `<ol>${element.children.map((child) => typeHtmlTags(child)).join('')}</ol>`
      case 'bulleted-list':
        return `<ul>${element.children.map((child) => typeHtmlTags(child)).join('')}</ul>`
      case 'list-item':
        return `<li>${element.children.map((child) => typeHtmlTags(child)).join('')}</li>`
      case 'mention':
        const mentionValue = mentionOptions.find(({ label }) => label === element.character).value
        return `{{${mentionValue}}}`
      case 'link':
        if (element.children.length > 0) {
          return `<a href="${element.url}">${element.children.map((child) => typeHtmlTags(child)).join('')}</a>`
        }
        return `<a href="${element.url}">${element.url}</a>`
      default:
        return ''
    }
  }

  const convertToHtml = (bodyObject) => {
    const htmlBody = bodyObject.map((element) => typeHtmlTags(element)).join('')
    handleEditorChange(htmlBody)
  }

  return (
    <Slate
      editor={editor}
      initialValue={initialValue}
      onChange={(bodyObject) => {
        convertToHtml(bodyObject)
        const { selection } = editor
        if (selection && Range.isCollapsed(selection)) {
          const beforePoint = Editor.before(editor, selection.anchor);

        if (beforePoint) {
          const range = Editor.range(editor, beforePoint, selection.anchor);
          const text = Editor.string(editor, range);

          if (text === '@') {
            setTarget(range);
            setSearch('');
            setIndex(0);
            return;
          } else {
            const [start] = Range.edges(selection)
            const wordBefore = Editor.before(editor, start, { unit: 'word' })
            const before = wordBefore && Editor.before(editor, wordBefore)
            const beforeRange = before && Editor.range(editor, before, start)
            const beforeText = beforeRange && Editor.string(editor, beforeRange)
            const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/)
            const after = Editor.after(editor, start)
            const afterRange = Editor.range(editor, start, after)
            const afterText = Editor.string(editor, afterRange)
            const afterMatch = afterText.match(/^(\s|$)/)
  
            if (beforeMatch && afterMatch) {
              setTarget(beforeRange)
              setSearch(beforeMatch[1])
              setIndex(0)
              return
            }
          }
        }
      }
        setTarget(null)
      }}
    >
      <div className='rich-text-editor'>
        <Editable
          style={{ minHeight: 50, padding: '0 10px', margin: '10px 0' }}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onKeyDown={onKeyDown}
          placeholder={i18n.t('profile__write_a_comment', { ns: 'candidateProfile' })}
        />
        {target && filteredMentionOptions.length > 0 && (
          <Portal>
            <div
              ref={ref}
              style={{
                top: '-9999px',
                left: '-9999px',
                position: 'absolute',
                zIndex: 1000,
                padding: '3px',
                background: 'white',
                borderRadius: '4px',
                boxShadow: '0 1px 5px rgba(0,0,0,.2)',
              }}
              data-cy="mentions-portal"
            >
              {filteredMentionOptions.map(({ value, label, initials }, i) => (
                <div
                  key={value}
                  onClick={() => {
                    Transforms.select(editor, target)
                    insertMention(editor, label)
                    setTarget(null)
                  }}
                  style={{
                    padding: '1px 3px',
                    borderRadius: '3px',
                    background: i === index ? '#B4D5FF' : 'transparent',
                  }}
                >
                  <Avatar 
                    size='small' 
                    style={{ 
                      border: 'solid 0.5px #D9D9D9',
                      backgroundColor: '#F5F5F5',
                      color: '#454545',
                      padding: 1,
                      marginRight: 10
                    }}
                  >
                    {initials}
                  </Avatar>
                  {label}
                </div>
              ))}
            </div>
          </Portal>
        )}
        <Toolbar>
          <MentionButton />
          <UploadButton attachedFiles={attachedFiles} setAttachedFiles={setAttachedFiles}/>
          <LinkButton />
          <ShareButton 
            isPublic={isPublic}
            options={mentionOptions}
            selectedItems={selectedItems}
            setIsPublic={setIsPublic}
            setSelectedItems={setSelectedItems}
          />
          <MarkButton format="bold" icon="bold" />
          <MarkButton format="italic" icon="italic" />
          <MarkButton format="underline" icon="underline" />
          <BlockButton format="numbered-list" icon="ordered-list" />
          <BlockButton format="bulleted-list" icon="unordered-list" />
        </Toolbar>
      </div>
    </Slate>
  )
}

const withMentions = editor => {
  const { isInline, isVoid, markableVoid } = editor

  editor.isInline = element => {
    return ['mention', 'link'].includes(element.type) ? true : isInline(element)
  }

  editor.isVoid = element => {
    return element.type === 'mention' ? true : isVoid(element)
  }

  editor.markableVoid = element => {
    return element.type === 'mention' || markableVoid(element)
  }

  return editor
}

const insertMention = (editor, character) => {
  const mention = {
    type: 'mention',
    character,
    children: [{ text: '' }],
  }
  Transforms.insertNodes(editor, mention)
  Transforms.move(editor)
}

export default RichTextEditor