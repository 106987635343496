import React, { useEffect, useState, Fragment } from 'react'
import { Redirect } from 'react-router'
import fetch from 'isomorphic-fetch'
import { SERVER_URL } from '../../../utils/config'

import './Feedback.scss'

import CandidateIntro from './components/CandidateIntro'
import Chapter from './components/Chapter'
import SliderProfile from './components/SliderProfile'
import ReasoningChart from './components/ReasoningChart'
import ReasoningBars from './components/ReasoningBars'
import GameResults from './components/GameResults'

import TowerGameImage from '../../CandidateProfilev2/assets/old_assets/towergameLfeed.png'
import BalloonGameImage from '../../CandidateProfilev2/assets/old_assets/ballongameLfeed.png'
import DiceGameImage from '../../CandidateProfilev2/assets/old_assets/dicegameLfeed.png'
import EmotionGameImage from '../../CandidateProfilev2/assets/old_assets/smileygameLfeed.png'
import CasinoGameImage from '../../CandidateProfilev2/assets/old_assets/cardgameLfeed.png'

import GameResultImage from '../../CandidateProfilev2/assets/old_assets/gameresults.png'
import CognitiveTraitsImage from '../../CandidateProfilev2/assets/old_assets/cogtraitgamesfeed.png'
import PersonalityTraitsImage from '../../CandidateProfilev2/assets/old_assets/personalitygamesfeed.png'
import ReasoningTraitsImage from '../../CandidateProfilev2/assets/old_assets/reasoninggamesfeed.png'
import i18n from '../../../i18n'

const ASSETS_URL = 'https://genoma-assets.s3.us-east-2.amazonaws.com'

const ShareFeedback = (props) => {

  const [error, setError] = useState(false)
  const [data, setData] = useState({
    name: '',
    surname: '',
    avatar_initials: 'GW',
    reasoning_scores: [],
    personality_scores: [],
    game_scores: [],
    fun_scores: []
  })

  useEffect(() => {
    const url = SERVER_URL + `/api/v1/accounts/sharepersonal/${props.match.params.id}/`

    fetch(url)
      .then(response => {

        if (response.status >= 400) {
          setData(...data)
          setError(true)
        }
        return response.json();
      })
      .then(response => {


        let total_scores = []

        const bar2_game = response.game_scores.filter(score => score.trait_id.assessment_code === 'BAR2')
        const bart_game = response.game_scores.filter(score => score.trait_id.assessment_code === 'BART')
        const tow2_game = response.game_scores.filter(score => score.trait_id.assessment_code === 'TOW2')
        const towr_game = response.game_scores.filter(score => score.trait_id.assessment_code === 'TOWR')
        const gng2_game = response.game_scores.filter(score => score.trait_id.assessment_code === 'GNG2')
        const gng_game = response.game_scores.filter(score => score.trait_id.assessment_code === 'GNG')
        const tem2_game = response.game_scores.filter(score => score.trait_id.assessment_code === 'TEM2')
        const temo_game = response.game_scores.filter(score => score.trait_id.assessment_code === 'TEMO')
        const mem2_game = response.game_scores.filter(score => score.trait_id.assessment_code === 'MEM2')

        if (bar2_game.length > 0) {
          total_scores = [...(bar2_game.filter(score => score.trait_id.code === 'AVRRSG'))]
        } else if (bart_game.length > 0) {
          total_scores = [...(bart_game.filter(score => score.trait_id.code === 'AVRRSG'))]
        }

        if (tem2_game.length > 0) {
          total_scores = [...total_scores, ...(tem2_game.filter(score => score.trait_id.code === 'EMOTOT'))]
        } else if (temo_game.length > 0) {
          total_scores = [...total_scores, ...(temo_game.filter(score => score.trait_id.code === 'EMOCOR'))]
        }

        if (gng2_game.length > 0) {
          total_scores = [...total_scores, ...(gng2_game.filter(score => ['CAPATC', 'CTRIMP'].find((val) => val === score.trait_id.code)))]
        } else if (gng_game.length > 0) {
          total_scores = [...total_scores, ...(gng_game.filter(score => ['DRCATC', 'IMPERR'].find((val) => val === score.trait_id.code)))]
        }

        if (tow2_game.length > 0) {
          total_scores = [...total_scores, ...(tow2_game.filter(score => ['EXTPLN'].find((val) => val === score.trait_id.code)))]
        } else if (towr_game.length > 0) {
          total_scores = [...total_scores, ...(towr_game.filter(score => score.trait_id.code === 'CAPPLN'))]
        }
        if (mem2_game.length > 0) {
          total_scores = [...total_scores, ...(mem2_game.filter(score => ['ADBLSP'].find((val) => val === score.trait_id.code)))]
        }

        total_scores = total_scores.filter(score => score.trait_id.status === 'ACTIV')

        let treated_data = response
        treated_data = {
          ...treated_data,
          personality_scores: response.personality_scores.filter(trait => trait.trait_id.status === 'ACTIV' && trait.trait_id.code !== 'HONES'),
          game_scores: total_scores.filter(score => score.trait_id.status === 'ACTIV'),
          fun_scores: response.game_scores.filter(score => ['CAPPLN', 'DECRSG', 'MMTRCE', 'ACRTTL', 'EMOTOT', 'TTLRCL', 'CAPATC', 'DNRRCL', 'ACRTTL', 'BLOSPA', 'BRTMNY', 'EXTPLN'].find((val) => val === score.trait_id.code)),

        }
        setData(treated_data)

      })
  }, [])

  const TNUM = data.reasoning_scores ?
    data.reasoning_scores.find(rscore => {
      return rscore.trait_id.assessment_code === 'TNUM'
    }) : null
  const TLET = data.reasoning_scores ?
    data.reasoning_scores.find(rscore => {
      return rscore.trait_id.assessment_code === 'TLET'
    }) : null
  const TLOG = data.reasoning_scores ?
    data.reasoning_scores.find(rscore => {
      return rscore.trait_id.assessment_code === 'TLOG'
    }) : null

  const gamesFunScores = []

  //Tower game scores

  if (data.fun_scores.find(val => val.trait_id.code === 'EXTPLN' && val.trait_id.assessment_code === 'TOW2') ||
    data.fun_scores.find(val => val.trait_id.code === 'CAPPLN' && val.trait_id.assessment_code === 'TOWR')) {
    const trait = data.fun_scores.find(val => val.trait_id.code === 'EXTPLN' && val.trait_id.assessment_code === 'TOW2') ||
      data.fun_scores.find(val => val.trait_id.code === 'CAPPLN' && val.trait_id.assessment_code === 'TOWR')

    gamesFunScores.push({
      upperTitle: i18n.t('feedback__towers__complete'),
      upperScore: trait.score,
      upperAvgScore: trait.trait_id.average_score,
      upperInverted: false,
      upperMeasure: '%',
      upperSpace: '',
      image: TowerGameImage,
      alt: "tower game",
      titleImg: "tower game",
      titleCard: i18n.t('feedback__towers')
    });
  }

  //Memory game scores
  if (data.fun_scores.find(val => val.trait_id.code === 'BLOSPA') ||
    data.fun_scores.find(val => val.trait_id.code === 'MMTRCE')) {
    const trait = data.fun_scores.find(val => val.trait_id.code === 'BLOSPA') ||
      data.fun_scores.find(val => val.trait_id.code === 'MMTRCE')
    gamesFunScores.push({
      upperTitle: i18n.t('feedback__memory__level'),
      upperScore: trait.trait_id.code === 'BLOSPA' ? Math.round(trait.score * 9 / 100) : Math.round(trait.score * 15 / 100),
      upperAvgScore: trait.trait_id.code === 'BLOSPA' ? trait.trait_id.average_score : Math.round(trait.trait_id.average_score * 15 / 100),
      upperInverted: false,
      upperMeasure: trait.trait_id.code === 'BLOSPA' ? i18n.t('feedback__memory__squares') : i18n.t('commons__numbers'),
      upperSpace: '',
      image: `${ASSETS_URL}/${trait.trait_id.assessment_code}.png`,
      alt: "memory game",
      titleImg: "memory game",
      titleCard: i18n.t('feedback__memory')
    });
  }

  //Dice game scores
  if (data.fun_scores.find(val => val.trait_id.code === 'ACRTTL' && val.trait_id.assessment_code === 'DCE2') ||
    data.fun_scores.find(val => val.trait_id.code === 'ACRTTL' && val.trait_id.assessment_code === 'DICE')) {
    const trait = data.fun_scores.find(val => val.trait_id.code === 'ACRTTL' && val.trait_id.assessment_code === 'DCE2') ||
      data.fun_scores.find(val => val.trait_id.code === 'ACRTTL' && val.trait_id.assessment_code === 'DICE')
    gamesFunScores.push({
      upperTitle: i18n.t('feedback__hit__percentage'),
      upperScore: trait.score,
      upperAvgScore: trait.trait_id.average_score,
      upperInverted: false,
      upperMeasure: '%',
      upperSpace: '',
      image: DiceGameImage,
      alt: "dice game",
      titleImg: "dice game",
      titleCard: i18n.t('feedback__dices')
    });
  }

  //Emotions game scores
  if (data.fun_scores.find(val => val.trait_id.code === 'EMOTOT' && val.trait_id.assessment_code === 'TEM2') ||
    data.fun_scores.find(val => val.trait_id.code === 'EMOTOT' && val.trait_id.assessment_code === 'TEMO')) {
    const trait = data.fun_scores.find(val => val.trait_id.code === 'EMOTOT' && val.trait_id.assessment_code === 'TEM2') ||
      data.fun_scores.find(val => val.trait_id.code === 'EMOTOT' && val.trait_id.assessment_code === 'TEMO')
    gamesFunScores.push({
      upperTitle: i18n.t('feedback__hit__percentage'),
      upperScore: trait.score,
      upperAvgScore: trait.trait_id.average_score,
      upperInverted: false,
      upperMeasure: '%',
      upperSpace: '',
      image: EmotionGameImage,
      alt: "emotion game",
      titleImg: "emotion game",
      titleCard: i18n.t('feedback__emotions')
    });
  }

  //Iowa game scores
  if (data.fun_scores.find(val => val.trait_id.code === 'TTLRCL' && val.trait_id.assessment_code === 'IOW2') ||
    data.fun_scores.find(val => val.trait_id.code === 'TTLRCL' && val.trait_id.assessment_code === 'IOWA')) {
    const trait = data.fun_scores.find(val => val.trait_id.code === 'TTLRCL' && val.trait_id.assessment_code === 'IOW2') ||
      data.fun_scores.find(val => val.trait_id.code === 'TTLRCL' && val.trait_id.assessment_code === 'IOWA')
    gamesFunScores.push({
      upperTitle: i18n.t('feedback__casino__money'),
      upperScore: trait.score,
      upperAvgScore: trait.trait_id.average_score,
      upperInverted: true,
      upperMeasure: '$',
      upperSpace: '',
      image: CasinoGameImage,
      alt: "casino game",
      titleImg: "casino game",
      titleCard: i18n.t('feedback__casino')
    });
  }

  //Baloon game scores
  if (data.fun_scores.find(val => val.trait_id.code === 'BRTMNY') ||
    data.fun_scores.find(val => val.trait_id.code === 'DECRSG')) {
    const trait = data.fun_scores.find(val => val.trait_id.code === 'BRTMNY') ||
      data.fun_scores.find(val => val.trait_id.code === 'DECRSG')
    gamesFunScores.push({
      upperTitle: i18n.t('feedback__casino__money'),
      upperScore: Math.round(trait.score * 1680 / 100),
      upperAvgScore: Math.round(trait.trait_id.average_score * 1680 / 100),
      upperInverted: true,
      upperMeasure: '$',
      upperSpace: ' ',
      image: BalloonGameImage,
      alt: "balloon game",
      titleImg: "balloon game",
      titleCard: i18n.t('feedback__balloons')
    });
  }

  return (
    <div className='feedback-main-container'>
      {(error) ?
        <Redirect to="/notFound" />
        :
        <>
          <CandidateIntro
            fullName={data.name + ' ' + data.surname}
          />

          <Chapter
            title={i18n.t('feedback__results__title')}
            description={i18n.t('feedback__results__introductions')}
            bigImage={GameResultImage}
          />

          <div className='feedback-game-results'>
            {gamesFunScores.map((game, index) => (
              <div key={index} >
                <GameResults
                  style={{ height: '100%' }}
                  upperTitle={game.upperTitle}
                  upperScore={game.upperScore}
                  upperAvgScore={game.upperAvgScore}
                  upperInverted={game.upperInverted}
                  upperMeasure={game.upperMeasure}
                  upperSpace={game.upperSpace}
                  lowerScore={game.lowerScore}
                  image={game.image}
                  alt={game.alt}
                  titleImg={game.titleImg}
                  titleCard={game.titleCard}
                />
              </div>
            ))}
          </div>

          {data.game_scores && data.game_scores.length > 0 ?
            <div className='feedback-slider-container'>
              <Chapter
                title={i18n.t('feedback__emotions__and__cognitive__traits')}
                description={i18n.t('feedback__c_e_traits__text')}
                bigImage={CognitiveTraitsImage}
              />
              <SliderProfile title={i18n.t('feedback__personality__profile')} traits={data.game_scores || []} />
            </div> : null
          }

          {(TLET && TNUM && TLOG) ?
            <div className='reasoning-container'>
              <Chapter
                title={i18n.t('feedback__reasoning__skills')}
                description={i18n.t('feedback__reasoning__skills__introduction')}
                bigImage={ReasoningTraitsImage}
              />
              <ReasoningChart
                verbalReasoning={TLET ? TLET.score : null}
                verbalAverage={TLET ? TLET.trait_id.average_score : null}
                logicReasoning={TLOG ? TLOG.score : null}
                logicAverage={TLOG ? TLOG.trait_id.average_score : null}
                numericalReasoning={TNUM ? TNUM.score : null}
                numericalAverage={TNUM ? TNUM.trait_id.average_score : null}
              />
            </div>
            :
            (TLET || TNUM || TLOG) ?
              <div className='reasoning-container'>
                <Chapter
                  title={i18n.t('feedback__reasoning__skills')}
                  description={i18n.t('feedback__reasoning__skills__introduction')}
                  bigImage={ReasoningTraitsImage}
                />
                <ReasoningBars
                  verbalReasoning={TLET ? TLET.score : null}
                  verbalAverage={TLET ? TLET.trait_id.average_score : null}
                  logicReasoning={TLOG ? TLOG.score : null}
                  logicAverage={TLOG ? TLOG.trait_id.average_score : null}
                  numericalReasoning={TNUM ? TNUM.score : null}
                  numericalAverage={TNUM ? TNUM.trait_id.average_score : null}
                />
              </div>
              : null
          }

          {data.personality_scores && data.personality_scores.length > 0 ?
            <div className='personality-slider'>
              <Chapter
                title={i18n.t('feedback__personality__profile')}
                description={i18n.t('feedback__personality__profile__introduction')}
                bigImage={PersonalityTraitsImage}
              />
              <SliderProfile title={i18n.t('feedback__personality__profile')} traits={data.personality_scores || []} />

            </div>
            : null
          }
        </>
      }
    </div>
  )
}

export default ShareFeedback
