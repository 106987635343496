export default (state, action) => {
  switch (action.type) {
    case 'HYDRATE_CANDIDATE':
      return {
        ...state,
        ...action.payload,
      };
    case 'UPDATE_CUTE_SURVEY':
      return {
        ...state,
        customTestSurveys: action.payload,
      };
    case 'UPDATE_NOTE':
      return {
        ...state,
        application: {
          ...state.application,
          note: action.payload,
        },
      };
    case 'CHANGE_CANDIDATE':
      return {
        ...state,
        nextCandidateId: action.payload,
      };
    case 'COMPARE_CANDIDATES':
      return {
        ...state,
        nextCandidateId: action.payload,
        comparingCandidates: !state.comparingCandidates,
      };
    case 'MERGE_CANDIDATES':
      return {
        ...state,
        candidatesToMerge: action.payload,
        mergingCandidates: !state.mergingCandidates,
      };
    case 'UPDATE_STATUS':
      return {
        ...state,
        application: {
          ...state.application,
          status: action.payload,
        },
      };
    case 'UPDATE_FILES_REQUESTS':
        return {
          ...state,
          filesRequests: action.payload,
        };
    default:
      throw new Error(
        `${action.type} is not supported, check your action.type!`
      );
  }
};
