import { 
    JOBAPP_FETCH_BEGIN,
    JOBAPP_FETCH_SUCCESS,
    JOBAPP_FETCH_FAILURE,
    ACTIVITY_FETCH_BEGIN,
    ACTIVITY_FETCH_SUCCESS,
    ASSIGNED_ACTIVITY_FETCH_BEGIN,
    ASSIGNED_ACTIVITY_RECEIVE_SUCCESS,
    ASSIGNED_ACTIVITY_RECEIVE_FAILURE,
    ACTIVITY_FETCH_FAILURE,
    ACTIVITY_ADD,
    ACTIVITY_REMOVE,
    ACTIVITY_ORIGINAL_REMOVE,
    ACTIVITY_ADD_QUESTION,
    ACTIVITY_REMOVE_QUESTION,
    ACTIVITY_SWITCH_QUESTION,
    ACTIVITY_UPDATE_QUESTION,
    ACTIVITY_START_EDIT_QUESTION,
    SAVE_IDEAL_GENOME,
    SAVE_IDEAL_GENOME_SUCCESS,
    UPDATE_JOB_APPLICATION,
    SAVE_NEW_MEASURED_TRAITS,
    SAVE_NEW_ASSIGNED_ACTIVITIES,
    POSITION_SEND_SUCCESS,
    POSITION_SEND_FAILURE,
    SAVE_STARTING,
} from '../constants';

const initialState = {
    jobAppId: '',
    jobApplication: {},
    integrations: [],
    activities: {
        results: []
    },
    assignedActivities: {},
    removedAssignedActivities: [],
    isFetchingJobApp: false,
    isFetchingActivities: false,
    isFetchingAssignedActivities: false,
    isSavingJobApp: false,
    isSavingMeasuredTraits: false,
    isSavingAssignedActivities: false,
    isSaving: false,
    error: [],
    koQuestions: 0,
    indexEdit: null,
}

export default function jobappReducer(state = initialState, action) {
    switch (action.type) {
        case JOBAPP_FETCH_BEGIN:
            // Loading starts
            return {
                ...initialState,
                isFetchingJobApp: true,
                jobAppId: action.payload.jobAppId,
                error: [],
            }
        case JOBAPP_FETCH_SUCCESS:
            // All done: set loading "false".
            // Also, replace the items with the ones from the server
            return {
                ...state,
                isFetchingJobApp: false,
                jobApplication: action.payload.jobApplication,
            };
        case JOBAPP_FETCH_FAILURE:
            // The request failed, but it did stop, so set loading to "false".
            // Save the error, and we can display it somewhere
            // Since it failed, we don't have items to display anymore, so set it empty.
            // This is up to you and your app though: maybe you want to keep the items
            // around! Do whatever seems right.
            return {
                ...state,
                isFetchingJobApp: false,
                error: action.payload.error,
                jobApplication: {}
            };
        case ACTIVITY_FETCH_BEGIN:
            // Loading Starts
            return {
                ...state,
                isFetchingActivities: true,
                error: [],
            };
        case ACTIVITY_FETCH_SUCCESS:
            // All done: set loading "false".
            // Also, replace the items with the ones from the server
            return {
                ...state,
                isFetchingActivities: false,
                activities: action.payload.activities,
            };
        case ACTIVITY_FETCH_FAILURE:
            // The request failed, but it did stop, so set loading to "false".
            // Save the error, and we can display it somewhere
            // Since it failed, we don't have items to display anymore, so set it empty.
            // This is up to you and your app though: maybe you want to keep the items
            // around! Do whatever seems right.
            return {
                ...state,
                isFetchingActivities: false,
                error: action.payload.error,
                activities: {
                    results: []
                },
            }; 
        case ACTIVITY_ADD:
            // Add an activity to the process
            return {
                ...state,
                assignedActivities: {
                    ...state.assignedActivities, 
                    [action.payload.code]: action.payload.data
                },
            }
        case ACTIVITY_REMOVE:
            let newAssignedActivities = {...state.assignedActivities}
            delete newAssignedActivities[action.payload.code]
            return {
                ...state,
                assignedActivities: newAssignedActivities,
            }
        case ACTIVITY_ORIGINAL_REMOVE:
            let removedAssignedActivities = [...state.removedAssignedActivities]
            removedAssignedActivities.push(state.activities.results.find(act => act.code === action.payload.code).id)
            return {
                ...state,
                removedAssignedActivities: removedAssignedActivities
            }
        case ACTIVITY_ADD_QUESTION:
            let koQuestionsActivity = {...state.assignedActivities['KO']}
            const newIndex = state.koQuestions + 1;
            const newPosition = Object.keys(koQuestionsActivity.configuration.questions).length;

            koQuestionsActivity.configuration.questions[newIndex] = { 
                ...action.payload.question, 
                position: newPosition 
            }
            return {
                ...state,
                assignedActivities: {
                    ...state.assignedActivities,
                    'KO': koQuestionsActivity
                },
                koQuestions: newIndex,
            }
        case ACTIVITY_REMOVE_QUESTION:
            const idx = parseInt(action.payload.index);
            let KOQuestionsActivity = { ...state.assignedActivities['KO'] };
            const removedQuestion = KOQuestionsActivity.configuration.questions[idx];
            let updatedKOQuestions = {}
            Object.keys(KOQuestionsActivity.configuration.questions).map(key => {
                let question = KOQuestionsActivity.configuration.questions[key];
                if (removedQuestion.position < question.position) {
                    question.position--;
                    updatedKOQuestions[key-1] = question;
                } else if (removedQuestion.position > question.position) {
                    updatedKOQuestions[key] = question;
                }
            });

            KOQuestionsActivity.configuration.questions = updatedKOQuestions;

            return {
                ...state,
                assignedActivities: {
                    ...state.assignedActivities,
                    'KO': KOQuestionsActivity,
                }
            }
        case ACTIVITY_SWITCH_QUESTION:
            let index = action.payload.index
            let indexInt = parseInt(action.payload.index)
            let direction = action.payload.direction
            let editKoquestion = {...state.assignedActivities['KO']}
            let temp = editKoquestion.configuration.questions[action.payload.index]
            let keys = Object.keys(editKoquestion.configuration.questions)
            let indexPosition = keys.indexOf(index)
            
            if (direction === 'up' && indexPosition !== 0) {
                editKoquestion.configuration.questions[action.payload.index] = editKoquestion.configuration.questions[keys[indexPosition-1]]
                editKoquestion.configuration.questions[keys[indexPosition-1]] = temp
            } else if (direction === 'down' && indexPosition !== keys.length-1) {
                editKoquestion.configuration.questions[action.payload.index] = editKoquestion.configuration.questions[keys[indexPosition+1]]
                editKoquestion.configuration.questions[keys[indexPosition+1]] = temp
            }
            return {
                ...state,
                assignedActivities: {
                    ...state.assignedActivities,
                    'KO': editKoquestion,
                }
            }
        case ACTIVITY_UPDATE_QUESTION:
            let koQuestionUpdated = {...state.assignedActivities['KO']};
            // We only edit the ko content
            const oldPosition = koQuestionUpdated.configuration.questions[state.indexEdit].position;
            action.payload.data.position = oldPosition;
            koQuestionUpdated.configuration.questions[state.indexEdit] = action.payload.data;
            return {
                ...state,
                assignedActivities: {
                    ...state.assignedActivities,
                    'KO': koQuestionUpdated,
                }
            }
        case ACTIVITY_START_EDIT_QUESTION:
            return {
                ...state,
                indexEdit: action.payload.index
            }
        case ASSIGNED_ACTIVITY_FETCH_BEGIN:
            return {
                ...state,
                isFetchingAssignedActivities: true,
                error: [],
            }
        case ASSIGNED_ACTIVITY_RECEIVE_FAILURE:
            return {
                ...state,
                isFetchingAssignedActivities: false,
                error: action.payload.error
            }
        case ASSIGNED_ACTIVITY_RECEIVE_SUCCESS:
            /**
             * PARCHE TEMPORAL PARA MANEJAR KOS ROTAS
            */
            const updatedQuestions = {};
            const updatedConfiguration = {};
            const assignedActivityKO = action.payload.assignedActivities.KO || {};
            const configuration = assignedActivityKO && assignedActivityKO.configuration
            const originalQuestions =  configuration ? configuration.questions : [];
            const originalPositions = Object.values(originalQuestions).map(({ position }) => position).sort((a,b)=>a-b);
            let badPositions = false;
            for (let i = 0; i < originalPositions.length; i++) {
                // Este if funciona también si la pregunta viene sin position
                if (i != originalPositions[i]) {
                    badPositions = true;
                    break
                }
            }

            if (badPositions) {
                Object.keys(originalQuestions).map(key => {
                    const { position } = originalQuestions[key];
                    const positionIndex = originalPositions.indexOf(position);
                    updatedQuestions[key] = {
                        ...originalQuestions[key],
                        position: positionIndex
                    };
                });
                updatedConfiguration.questions = updatedQuestions;
            } else {
                updatedConfiguration.questions = originalQuestions;
            }
            const updatedKOAssignedActivity =  {
                ...assignedActivityKO,
                configuration: updatedConfiguration,
            };

            const assignedActivities = {
                ...state.assignedActivities,
                ...action.payload.assignedActivities,
            }
            if (updatedKOAssignedActivity.code) {
                assignedActivities.KO = updatedKOAssignedActivity;
            }

            return {
                ...state,
                isFetchingAssignedActivities: false,
                assignedActivities,
                koQuestions: action.payload.index,
            }
        case SAVE_STARTING:
            return {
                ...state,
                isSaving: true,
            }
        case SAVE_IDEAL_GENOME:
            return {
                ...state,
                isSavingIdealGenome: true
            }
        case SAVE_IDEAL_GENOME_SUCCESS:
            return {
                ...state,
                jobApplication: {...state.jobApplication, genome_id: action.payload.genome.id}
            }
        case UPDATE_JOB_APPLICATION:
            return {
                ...state,
                isSavingIdealGenome: false,
                isSavingJobApp: true
            }
        case SAVE_NEW_MEASURED_TRAITS:
            return {
                ...state,
                isSavingJobApp: false,
                isSavingMeasuredTraits: true,
            }
        case SAVE_NEW_ASSIGNED_ACTIVITIES:
            return {
                ...state,
                isSavingMeasuredTraits: false,
                isSavingAssignedActivities: true,
            }
        case POSITION_SEND_SUCCESS:
            const newState = action.payload.shouldRedirect ? {
                ...initialState,
            } : { 
                ...state,
                isFetchingActivities: false,
                isFetchingAssignedActivities: false,
                isFetchingJobApp: false,
                isSaving: false,
                isSavingAssignedActivities: false,
                isSavingIdealGenome: false,
                isSavingJobApp: false,
                isSavingMeasuredTraits: false,
            }
            newState.assignedActivities = state.assignedActivities;
            return newState;
        case POSITION_SEND_FAILURE:
            return {
                ...state,
                isSaving: false,
                isSavingIdealGenome: false,
                isSavingJobApp: false,
                isSavingMeasuredTraits: false,
                isSavingAssignedActivities: false,
                error: action.payload.error
            }
        case 'INTEGRATIONS_RECEIVE_SUCCESS':
            return {
                ...state,
                integrations: action.payload
            };
        case 'JOB_APP_INTEGRATIONS_RECEIVE_SUCCESS':
            return {
                ...state,
                integrations : action.payload,
            }
        case 'INTEGRATIONS_ADD_SUCCESS':
            return {
                ...state,
                jobApplication: { ...state.jobApplication, integrations: action.payload.integrations }
            };
        default:
            return state;
        }

    
}
