import React from 'react';
import { Col, Row, Typography, Divider } from 'antd';
import propTypes from 'prop-types';

const { Paragraph, Text } = Typography;

const Questions = ({ candidates, questions, scrollRefs }) => {
  const questionIds = Object.keys(questions || {});


  return (
    <div ref={scrollRefs} className='question-section-container'>
      <Row type='flex' justify='center'>
        <Row className='question-section-container__table'>
          {candidates[0] ? (
            questionIds.length > 0 ? (
              questionIds.map((questionId) => (
                <>
                  <Row
                    key={questionId}
                    type='flex'
                    align='middle'
                    justify='center'
                    className='question-section-container__row'
                  >
                    <Col span={2}>
                      <Paragraph strong>{questions[questionId].question}</Paragraph>
                    </Col>
                    <Col span={19}>
                      <Row type='flex' justify='space-around'>
                        {candidates[0]
                          ? candidates.map((candidate, index) => (
                            <Col span={Math.floor(24 / (candidates.length))} key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 5 }}>
                              <CandidateAnswer questionId={questionId} candidate={candidate} />
                            </Col>
                          ))
                          : null}
                      </Row>
                    </Col>
                  </Row>
                  <Divider />
                </>
              ))
            ) : (
              <Paragraph strong disabled>Sin preguntas</Paragraph>
            )
          ) : null}
        </Row>
      </Row>
    </div>
  );
};

function CandidateAnswer({ candidate, questionId, key }) {
  const answer = candidate.ko_answers[questionId];

  return (
    <Row key={key}>
      {(answer && answer.answer) ? (
        ['multiselection', 'singleselection'].includes(answer.type) ? (
          Object.keys(answer.answer)
            .map((key, index) => (
              answer.answer[key] ?
                <Col key={index}>
                  <Row type='flex' justify='center'>
                    <Text>
                      {answer.alternatives[key].text}
                    </Text>
                  </Row>
                </Col> : null
            ))
        ) : (
          <Col>
            <Row type='flex' justify='center'>
              <Text>
                {answer.answer}
              </Text>
            </Row>
          </Col>
        )
      ) : (
        <Text key={key} style={{ marginTop: '20px' }}>
          Sin respuestas
        </Text>
      )}
    </Row>
  )
}

Questions.propTypes = {
  candidates: propTypes.array.isRequired,
};

export default Questions;
