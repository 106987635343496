import React, { useMemo } from 'react';
import { Row, Col, Typography, Icon, Tabs, Divider } from 'antd';

import CustomProgress from '../components/Progress';

import { months } from '../assets/utils';
import i18n from '../../../i18n';

const { Text, Title } = Typography;

const FirstPage = ({ state }) => {
  const {
    candidate,
    jobApplication,
    cognitiveScores,
    personalityScores,
    behavioralScores,
    globalVInt,
    koAnswers,
    refsWithAnswers,
  } = state;

  const evaluations = useMemo(() => {
    const tmpsEvaluations = [];

    if (
      cognitiveScores.length ||
      personalityScores.length ||
      behavioralScores.length
    ) {
      tmpsEvaluations.push(i18n.t('candidate_pdf__games', {ns: 'candidatePdf'}));
    }

    if (personalityScores.length) {
      tmpsEvaluations.push(i18n.t('candidate_pdf__personality_test', {ns: 'candidatePdf'}));
    }

    if (globalVInt) {
      tmpsEvaluations.push(i18n.t('candidate_pdf__ve', {ns: 'candidatePdf'}));
    }

    if (koAnswers.length) {
      tmpsEvaluations.push(i18n.t('commons__ko', {ns: 'candidateProfile'}));
    }

    if (refsWithAnswers?.length) {
      tmpsEvaluations.push(i18n.t('candidate_pdf__references', {ns: 'candidatePdf'}));
    }

    return tmpsEvaluations;
  }, [state]);

  const lastJob = candidate.experiences.sort((a, b) => {
    // if one of the years is empty, we set it to 9999 so it goes to the end
    // we are checking the end year so if there is no end year, it means it's the current job
    const [firstEnd, lastEnd] = [
      parseInt(a.year_end) || 9999,
      parseInt(b.year_end) || 9999,
    ];

    return lastEnd - firstEnd;
  })[0];

  const lastCareer = candidate.careers.sort(
    (a, b) => parseInt(b.career_last_year) - parseInt(a.career_last_year)
  )[0];

  return (
    <div className="first-page break-page">
      <img
        src="https://app.staging.genoma.work/media/logos/genoma-logo.png"
        alt="genoma logo"
        className="first-page__img"
      />
      <div className="first-page__text">
        <p className="t18">{i18n.t('candidate_pdf__report', {ns: 'candidatePdf'})}</p>
        <p style={{ lineHeight: 1.5 }}>
          {i18n.t('candidate_pdf__first_page_text', {ns: 'candidatePdf'})}
        </p>
      </div>
      <div className="first-page__summary">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '2rem',
          }}
        >
          <div className="text-container">
            <h4>
              <strong>{candidate.user_name ?? candidate.name}</strong>
            </h4>
            <p className="t12">
              {candidate.date_completed
                ? `${i18n.t('candidate_pdf__evaluation_date', {ns: 'candidatePdf' })} : ${candidate.date_completed}`
                : `${i18n.t('candidate_pdf__start_date', {ns: 'candidatePdf' })} : ${candidate.date_started}`}
            </p>
            <p className="t12">
              {i18n.t('candidate_pdf__process', {ns: 'candidatePdf' })}: <strong>{jobApplication.name}</strong>
            </p>
          </div>
          <CustomProgress
            size={80}
            progress={candidate.finalScore ? candidate.finalScore : 0}
            strokeWidth={6}
            circleOneStroke="#FFFFFF"
            circleTwoStroke="#FF9CD2"
          />
        </div>
        <Divider />
        <div className="first-page__summary__data">
          {lastJob ? (
            <div className="text-container">
              <p>
                <strong>{i18n.t('candidate_pdf__work_experience', { ns: 'candidatePdf' })}</strong>
              </p>
              <p className="t12">
                {lastJob.position} en {lastJob.company}
              </p>
              <p className="t12" style={{ color: '#A4A4A4' }}>
                {months[lastJob.month_start - 1]} {lastJob.year_start} -{' '}
                {!lastJob.year_end
                  ? i18n.t('candidate_pdf__present', { ns: 'candidatePdf' })
                  : `${lastJob.month_start}/${lastJob.year_start}`}
              </p>
            </div>
          ) : null}
          {lastCareer ? (
            <div className="text-container">
              <p>
                <strong>{i18n.t('candidate_pdf__academic_background', { ns: 'candidatePdf' })}</strong>
              </p>
              <p className="t12">
                {lastCareer.career_name} {i18n.t('profile__in', {ns: 'candidateProfile'})} {lastCareer.career_institution}
              </p>
              <p className="t12" style={{ color: '#A4A4A4' }}>
                {lastCareer.career_entry_year} - {lastCareer.career_last_year}
              </p>
            </div>
          ) : null}
          <div className="text-container">
            <p>
              <strong>{i18n.t('candidate_pdf__contact_information', { ns: 'candidatePdf' })}</strong>
            </p>
            <p className="t12">
              <strong>{i18n.t('candidate_pdf__phone', { ns: 'candidatePdf' })}:</strong> {candidate.full_phone}
            </p>
            <p className="t12">
              <strong>{i18n.t('candidate_pdf__email', { ns: 'candidatePdf' })}:</strong> {candidate.email}
            </p>
          </div>
        </div>
        <Divider />
        <div className="first-page__summary__evaluations">
          <p>
            <strong>{i18n.t('candidate_pdf__evaluations', { ns: 'candidatePdf' })}</strong>
          </p>
          <ul>
            {evaluations.map((text, idx) => (
              <li key={idx}>{text}</li>
            ))}
          </ul>
        </div>
        <span className="first-page__summary__footer-text">
          Powered by <strong>Genomawork</strong>
        </span>
      </div>
    </div>
  );
};

export default FirstPage;
