import React, { useContext, useEffect, useState } from "react";
import { Slider, Typography, Icon, Popover, Col, Row } from 'antd';
import { ResponsiveLine } from '@nivo/line';
import FitRangeSelector from "../../../../../../../components/fitRangeSelector";

import JobAppContext from "../../../../context/JobAppContext";

import '../TraitsConfiguration.scss';

const { Text } = Typography;

const styles = {
  sliderTitle: {
    fontSize: 13,
    fontWeight: "700"
  },
  rangeWrapper: {
    width: "100%",
    position: "relative"
  },
  higher: {
    textAlign: 'right',
    fontSize: 12,
  },
  lower: {
    textAlign: 'left',
    fontSize: 12,
  },
};

const distribution = (distribution) => {
  return typeof distribution === 'string' ? distribution.includes('[') ? distribution : undefined : undefined
}
function TraitSlider(props) {
  const {
    business,
    updateMeasuredTrait
  } = useContext(JobAppContext);


  const [state, setState] = useState({
    genomeIdeal: 0,
    points: 0,
    ranges: [],
    upperScore: null,
    personalityTrait: false,
    toggleGraph: false,
  });

  useEffect(() => {
    let measured = { ...props.measuredTrait };
    setState(oldState => ({ ...oldState, genomeIdeal: measured['score'] }));
    const { trait } = measured;
    const { category } = trait;
    if (category === 'PERSON') {
      setState(oldState => ({ ...oldState, personalityTrait: true, upperScore: measured['upper_score'] }));
    };

    const ranges = [...personalityRanges];
    setState(oldState => ({ ...oldState, ranges }));
  
  }, [props.measuredTrait]);

  const handleSliderAfterChange = (value, upperScore) => {
    let measured = { ...props.measuredTrait }
    const { trait } = measured;
    const { category } = trait;
    measured['score'] = value;
    if (category === 'PERSON') {
      measured['upper_score'] = upperScore;
    }
    updateMeasuredTrait(measured);
  };

  const handleSliderChange = (value) => {
    setState(oldState => ({...oldState, genomeIdeal: value }));
  };

  const hiddeFormulas = ['COGNIT', 'EMOTIO'];

  const personalityRanges = [20, 40, 60, 80, 100];

  const measuredTrait = props.measuredTrait;
  const { trait } = measuredTrait;
  const data = (trait && trait.distribution) ? distribution(trait.distribution) : '[]';
  const graphData = data && JSON.parse(data).map(({ name, val }) => { return { x: name, y: val } });
  const totalY = graphData.reduce((total, { y }) => total + y, 0)
  const totalCandidates = [{
    id: 'candidates',
    color: 'rgb(236, 132, 162)',
    border: 'black',
    data: graphData
  }];

  return (
    <Row className='trait-slider'>
      <Row type="flex" align="middle" className='trait-name'>
        <Col xs={18} sm={18} style={{ display: 'flex', justifyContent: 'start' }}>
          <Text
            id="slider-image"
            strong
            className={styles.sliderTitle}
          >
            {trait && trait.trait}

            {trait && trait.trait !== trait.descripcion && !hiddeFormulas.includes(trait.category) &&
              <Popover
                overlayClassName='trait-name-popover'
                content={trait.descripcion}
                trigger="click"
              >
                <Icon type="question-circle" />
              </Popover>
            }
          </Text>
        </Col>
      </Row>
      <Row style={styles.rangeWrapper}>
        <Row type="flex" justify="center">
          <Col sm={20}>
            {props.showDistribution && data &&
              <Row style={{ width: '100%', height: '30px' }}>
                <ResponsiveLine
                  data={totalCandidates}
                  margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                  xScale={{ type: 'point' }}
                  yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                  curve='basis'
                  axisTop={null}
                  axisRight={null}
                  axisBottom={null}
                  axisLeft={null}
                  enableGridX={false}
                  enableGridY={false}
                  colors={(value) => value.color}
                  borderColor="#fff"
                  enablePoints={false}
                  pointSize={10}
                  lineWidth={0}
                  pointColor={{ theme: 'background' }}
                  pointBorderWidth={2}
                  pointBorderColor={{ from: 'serieColor' }}
                  pointLabel="y"
                  enableArea={true}
                  pointLabelYOffset={-12}
                  useMesh={true}
                  legends={[]}
                  tooltip={
                    (value, values) => (
                      <div className='chart-tooltip'>
                        <div>{Math.round(100 * parseInt(value.point.data.y) / totalY)}% obtiene como resultado {value.point.data.x}</div>
                      </div>
                    )
                  }
                />
                <div
                  style={{ marginTop: '-5px', zIndex: 999, position: 'inherit', pointerEvents: 'none' }}
                >
                  <img
                    style={{ marginLeft: (trait ? trait.average_score - 1 : null) + '%' }}
                    src='https://i.imgur.com/1WKikM2.png' />
                </div>
              </Row>
            }
            <Row style={{ position: 'relative' }} className='trait-slider__content'>
              {state.personalityTrait && props.measuredTrait.distribution === 'RANG' ?
                (
                  <Col xs={24} style={{ height: '36px' }}>
                    <FitRangeSelector
                      ranges={state.ranges}
                      lowerScore={state.genomeIdeal}
                      upperScore={state.upperScore}
                      onClickRange={(lower, upper) => {
                        setState(oldState => ({...oldState,  genomeIdeal: lower, upperScore: upper }));
                        handleSliderAfterChange(lower, upper)
                      }}
                      disabled={business.addOns.find(obj => obj.add_on == 'TEDT') ? false : true}
                    />
                  </Col>
                )
                : (<Col xs={24} className="trait-slider__action"><Slider
                  min={0}
                  max={100}
                  tipFormatter={(value) => `${value}%`}
                  onChange={handleSliderChange}
                  onAfterChange={handleSliderAfterChange}
                  value={state.genomeIdeal}
                  tooltipVisible={false}
                  style={{ width: "100%" }}
                  disabled={business.addOns.find(obj => obj.add_on == 'TEDT') ? false : true}
                /></Col>)}
            </Row>
            <Row type="flex" align="middle" justify="space-between" className='trait-name'>
              <Col xs={9} sm={9} style={{ display: 'flex', justifyContent: 'start' }}>
                <Typography className={styles.lower}>
                  {trait && trait.lower_bound}
                </Typography>
              </Col>
              <Col xs={9} sm={9} style={{ display: 'flex', justifyContent: 'end' }}>
                <Typography className={styles.higher}>
                  {trait && trait.upper_bound}
                </Typography>
              </Col>
            </Row>
          </Col>

          <Col sm={4}>
            <Row style={{ display: 'grid', justifyContent: 'end' }}>
              <Text strong>
                {state.genomeIdeal}% {props.measuredTrait.distribution === 'RANG' ? `- ${state.upperScore}%` : ''}
                </Text>
              {
              props.showDistribution && <Text strong type='secondary' style={{color:'#FCA0D3'}}>
                {trait ? trait.average_score : null}%
                </Text>
                }
            </Row>
          </Col>
        </Row>
      </Row>
    </Row>
  );

};

export default TraitSlider;
