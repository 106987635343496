import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  Typography,
  Icon,
  Card,
  Avatar,
  Row,
} from 'antd';

const { Meta } = Card;
const { Text, Title, Paragraph } = Typography;

/**
 * 
 * @param {object} jobapp Includes jobapplication information 
 * @param {function} publish sends jobapp info for update
 * @param {function} handleChange updates local jobapp info prev to publish
 * @param {array} countries array of strings containing country names
 * @returns {React.ReactNode}
 */
function PostJobOnPaidPortal({ jobapp, publish, handleChange, provider, providerName, ModalContent, countries, logo }) {
  const [modalVisible, setModalVisible] = useState(false);

  const sendJobData = (jobData) => {
    // Update jobapp
    const { job_portals } = jobapp;
    job_portals[provider] = jobData;
    handleChange('job_portals')(job_portals);
    publish(false);
  }

  const published = Boolean(jobapp.job_portals[provider]);
  const countryAvailable = Boolean(!countries || countries.includes(jobapp.location_country))
  return (
    <>
      <Card
        style={{ width: '100%', border: '1px solid #d9d9d9' }}
        actions={[
          published ?
            <Button type="link" onClick={() => setModalVisible(true)}>
              <Icon style={{ color: "#47E197" }} type="check-circle" /> Publicado
            </Button> :
            <Button type="link" onClick={() => countryAvailable && setModalVisible(true)} >
              <Icon style={{ color: "#5e5e5e" }} type="notification" />
              {countryAvailable ? "Publicar" : "No disponible en el país"}
            </Button>
        ]}
      >
        <Meta
          avatar={<Avatar src={logo} size="large" />}
          title={providerName}
          description="Pagado"
        />
      </Card>
      <Modal
        visible={modalVisible}
        footer={null}
        onCancel={() => setModalVisible(false)}
        width="70vw"
      >
        {ModalContent && <ModalContent jobapp={jobapp} sendJobData={sendJobData} />}
      </Modal>
    </>
  )
}

PostJobOnPaidPortal.propTypes = {
  jobapp: PropTypes.object.isRequired,
  publish: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  provider: PropTypes.string.isRequired,
  providerName: PropTypes.string.isRequired,
  modalContent: PropTypes.node.isRequired,
  image: PropTypes.string.isRequired,
  countries: PropTypes.arrayOf(PropTypes.string)
}

export default PostJobOnPaidPortal;
