import React, { Fragment } from 'react';
import DefaultForm from './default';
import FileForm from './file';
import IntForm from './int';
import SelectionForm from './selection';

const TypeOfAnswer = (props) => {
  const isSelection = props.type === 'singleselection' || props.type === 'multiselection';
  return (
    <Fragment>
      {props.type !== 'file' && (
        <DefaultForm {...props} />
      )}

      {props.type === 'int' && (
        <IntForm {...props} />
      )}

      {props.type === 'file' && (
        <FileForm {...props} />
      )}

      {isSelection && (
        <SelectionForm type={props.type} {...props} />
      )}
    </Fragment>
  )
}

export default TypeOfAnswer;