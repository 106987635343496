import React, { Fragment } from 'react';
import {
  Layout,
  Row,
  Typography,
  Form,
  Input,
  Button,
  Divider,
  Icon
} from 'antd';

import SocialLoginButtons from '../../../Login/components/SocialLogin';
import i18n from '../../../../i18n';

const { Title, Text } = Typography;

const FirstLogin = (
  {
    props, state, submitEmail,
    handleChange, extractRedirect, goBack, submitAfterLogin
  }
) => {
  const { getFieldDecorator } = props.form;

  return (
    <Layout className='main-layout-cards__cols__component'>
      <Row style={{ margin: '0 0 44px 0' }}>
        <span style={{ marginRight: 20 }}>
          <Icon type='arrow-left' onClick={() => goBack()} style={{ fontSize: '20px' }} />
        </span>
          <Text style={{ color: '#8C8C8C', fontSize: '20px' }}>
            {i18n.t('commons__candidate').toUpperCase()}
          </Text>
      </Row>
      <Row>
        <Title level={1}>
          {i18n.t('commons__sign_in')}
        </Title>
        <Text style={{ color: '#786D73', lineHeight: '26px', fontSize: '18px' }}>
          {i18n.t('start_process__candidate__without_account__text')}
        </Text>
      </Row>
      <Form onSubmit={submitEmail} style={{ marginTop: '1em' }}>
        <Form.Item label={i18n.t('commons__email')}>
          {getFieldDecorator('email', {
            rules: [
              { required: true, message: 'Por favor escribe tu email' },
              { type: 'email', message: 'Ingresa un email válido' }
            ],
          })(
            <Input
              size='large'
              type="email"
              placeholder={i18n.t('commons__email')}
              onChange={handleChange('email')}
              style={{ borderRadius: '4px' }}
            />
          )}
          {state.errors.email && <Text type='danger'>{i18n.t('login__valid__email')}</Text>}
        </Form.Item>

        <Button
          size="large"
          htmlType="submit"
          block
          style={{ marginTop: '1em', borderRadius: '4px', ...state.color_theme }}
        >
          {i18n.t('commons__next')}
        </Button>
      </Form>
      <Row>
        {extractRedirect(props.location.search) !== '/processes' &&
          <Fragment>
            <Divider />
            <SocialLoginButtons
              location={props.location}
              jobappid={props.match.params['jobappid']}
              submitAfterLogin={submitAfterLogin}
            />
          </Fragment>
        }
      </Row>

    </Layout>
  );
}

export default FirstLogin;
