export default (state, action) => {
  switch (action.type) {
    case 'SET_ADD_PASSIVE_CANDIDATE_TO_JOB_APPLICATIONS':
      return {
        ...state,
        addPassiveCandidateToJobApplications: action.payload,
      };
    case 'SET_ADD_PASSIVE_CANDIDATE_VISIBLE':
      return {
        ...state,
        addPassiveCandidateVisible: action.payload,
      };
    case 'SET_ADD_PASSIVE_CANDIDATE_VISIBLE_METHOD':
      return {
        ...state,
        addPassiveCandidateVisibleMethod: action.payload,
      };
    case 'SET_BUSINESS_CUSTOM_FIELDS':
      return {
        ...state,
        businessCustomFields: action.payload,
      };
    case 'SET_CANDIDATE_DATA':
      return {
        ...state,
        candidateData: action.payload,
      };
    case 'SET_CANDIDATES':
      return {
        ...state,
        candidates: action.payload.candidates,
        totalCandidates: action.payload.totalCandidates,
      };
    case 'SET_COLUMNS':
      return {
        ...state,
        columns: action.payload,
      };
    case 'SET_COLUMNS_SHOWN':
      return {
        ...state,
        columnsShown: action.payload,
      };
    case 'SET_COUNTRIES':
      return {
        ...state,
        countries: action.payload,
      };
    case 'SET_CUSTOM_FIELDS_COLUMNS':
      return {
        ...state,
        customFieldsColumns: action.payload,
      };
    case 'SET_CUSTOM_FIELD_FILE_PREVIEW_FLAG':
      return {
        ...state,
        customFieldFilePreviewFlag: action.payload,
      };
    case 'SET_FILTERS_CONFIG':
      return {
        ...state,
        filtersConfig: action.payload,
      };
    case 'SET_FILTER_QUERY':
      return {
        ...state,
        filterQuery: action.payload,
      };
    case 'SET_MAIL_TEMPLATES':
      return {
        ...state,
        mailTemplates: action.payload,
      };
    case 'SET_OPEN_CANDIDATE':
      return {
        ...state,
        openCandidate: action.payload,
      };
    case 'SET_PAGE':
      return {
        ...state,
        page: action.payload,
      };
    case 'SET_PAGE_SIZE':
      return {
        ...state,
        pageSize: action.payload,
      };
    case 'SET_PREVIEW_CV':
      return {
        ...state,
        previewCV: action.payload,
      };
    case 'SET_SELECTED_CANDIDATES':
      return {
        ...state,
        selectedCandidates: action.payload,
      };
    case 'SET_SELECTED_FILE_ID':
      return {
        ...state,
        selectedFileId: action.payload,
      };
    case 'SET_SELECTED_FILTERS':
      return {
        ...state,
        selectedFilters: action.payload,
      };
    case 'SET_TABLE_COLUMNS':
      return {
        ...state,
        tableColumns: action.payload,
      };
    default:
      throw new Error(
        `${action.type} is not supported, check your action.type!`
      );
  }
};
