import React from 'react';
import {
  Form,
  Input,
  Icon,
  Row,
  Col,
  Select,
  Card,
  Typography
} from 'antd';
import './SingleQuestion.scss';

const { Option } = Select;
const { Text } = Typography;

const SingleQuestion = ({ qn, index, item, remove, keys, getFieldDecorator, onChange, editable }) => (
  <Row gutter={20} className='added-questions'>
    <Card>
      <Row>
        <Col span={24} className='headline'>
          <Text strong>Pregunta {index + 1}</Text>
        </Col>

        <Col span={24}>
          <Form.Item>
            {getFieldDecorator(`questions[${item}]`, {
              initialValue: qn.text,
              validateTrigger: ['onChange', 'onBlur'],
              onChange: onChange,
              rules: [
                {
                  required: true,
                  message: 'Por favor ingresa la pregunta o elimina este campo',
                },
              ],
            })(<Input placeholder="¿Cuál es tu pregunta?" disabled={!editable} />)}
          </Form.Item>
        </Col>

        <Col span={9}>
          <Form.Item label='Tiempo límite:'>
            {getFieldDecorator(`times[${item}]`, {
              initialValue: qn.time,
              validateTrigger: ['onChange', 'onBlur'],
              rules: [
                {
                  required: true,
                  message: 'Por favor selecciona el tiempo límite',
                },
              ],
            })(
              <Select disabled={!editable} onMouseDown={e => e.stopPropagation()}>
                <Option value={30}>30s</Option>
                <Option value={40}>40s</Option>
                <Option value={50}>50s</Option>
                <Option value={60}>1m</Option>
                <Option value={90}>1m 30s</Option>
                <Option value={120}>2m</Option>
                <Option value={180}>3m</Option>
                <Option value={300}>5m</Option>
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>

      {keys.length > 1 && editable &&
        <div className='delete-btn'>
          <Icon type="close" onClick={() => remove(item)} />
        </div>
      }
    </Card>
  </Row>
)

export default SingleQuestion;