import React, { useState } from 'react';
import { Form, Input, Icon, Button, Typography } from 'antd';

const MailExportInput = (props) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const [email, setEmail] = useState('');
    const [error, setError] = useState('')
    return (
    <Form>
      <Form.Item >
        <Input
          prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="Correo eléctrónico"
          type='email'
          value={email}
          onChange={(e) => {
            setEmail(e.target.value); 
            setError(''); 
          }}
        />
        {error && 
          <Typography.Text type='danger'>{error}</Typography.Text>
        }
      </Form.Item>
      <Form.Item>
        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
          <Button style={{marginRight:30}} onClick={() => {
                props.modal.modal.destroy();
              }}>
            Cancelar            
          </Button>
          <Button type='primary' onClick={() => {
              if (re.test(email.toLowerCase())) {
                props.handleCandidatesExport(email);
                props.modal.modal.destroy();
              } else {
                setError('Email no válido');
              }
              }}>
              Confirmar            
          </Button>
        </div>
      </Form.Item>
    </Form>
    )
  }

export default MailExportInput;

