import React, { useState, useEffect, useContext } from "react";

import { Button, Col, Icon, Modal, Row, Table, Typography } from "antd";

import EvaluationCard from "../EvaluationCard";
import JobAppContext from "../../../context/JobAppContext";

import "../EvaluationsStyles.scss";

function InterviewKits() {
  const { interviewKits, updateInterviewKits } = useContext(JobAppContext);

  const [availableKits, setAvailableKits] = useState([]);
  const [currentKits, setCurrentKits] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    //TODO: agregar - cambiar a current InterviewKits
    if (interviewKits && isVisible) {
      let kits;
      kits = interviewKits?.allEvaluations;
      kits = kits.filter(
        (obj) => !interviewKits.currentEvaluations.includes(obj)
      );
      setAvailableKits(kits);
      interviewKits.currentEvaluations
        ? setCurrentKits([...interviewKits.currentEvaluations])
        : setCurrentKits([]);
    }
  }, [isVisible, interviewKits]);

  useEffect(() => {
    if (isVisible == false) {
      setAvailableKits([]);
      setCurrentKits([]);
    }
  }, [isVisible]);

  const onSave = () => {

    // Por cada elemento del modal ver si existe en el contexto, si no esta entonces hay que agregarlo
    const toAdd = [...interviewKits.toAdd];
    const toRemove = [...interviewKits.toRemove];

    currentKits.forEach(current => {
      const found = interviewKits.currentEvaluations.some(obj => obj.id === current.id);
      !found && toAdd.push(current);
    });

    // Por cada elemento del contexto ver si esta en el modal, si no esta hay que sacarlo
    interviewKits.currentEvaluations.forEach(current => {
      const found = currentKits.some(obj => obj.id === current.id);
      const toAddFoundIndex = toAdd.findIndex(obj => obj.id === current.id);
      if (!found && toAddFoundIndex != -1){
        toAdd.splice(toAddFoundIndex, 1);
      }
      else if (!found){
        toRemove.push(current);
      };
    });

    // Revisamos el arreglo toRemove actual, si hay elementos en toAdd y toRemove que coinciden eliminamos los 2
    toRemove.forEach((current, idx) => {
      const addIndex = toAdd.findIndex(obj => current.id === obj.id);
      if (addIndex != -1) {
        toRemove.splice(idx, 1);
        toAdd.splice(addIndex, 1);
      }
    })

    updateInterviewKits('toAdd', toAdd);
    updateInterviewKits('toRemove', toRemove);
    updateInterviewKits('currentEvaluations', currentKits);
    setIsVisible(false);
  };

  const addInterviewKit = (ikit) => {
    let newKits = [...currentKits, ikit];
    const idx = availableKits.indexOf(ikit);
    const currentIds = currentKits.map((obj) => obj.id);
    if (ikit.id && !currentIds.includes(ikit.id) && idx !== -1) {
      setCurrentKits(newKits);
      availableKits.splice(idx, 1);
      setAvailableKits(availableKits);
    }
  };

  const removeInterviewKit = (ikit) => {
    //   TODO: lógica del to remove o no
    const currentIds = currentKits.map((obj) => obj.id);
    const idx = currentIds.indexOf(ikit.id);
    if (ikit.id && currentIds.includes(ikit.id) && idx !== -1) {
      currentKits.splice(idx, 1);
      setCurrentKits(currentKits);
      let kits;
      kits = interviewKits?.allEvaluations;
      kits = kits.filter((obj) => !currentKits.includes(obj));
      setAvailableKits(kits);
    }
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "group",
      key: "group",
    },
    {
      title: "N° de preguntas",
      dataIndex: "questions_total",
      key: "questions",
    },
    {
      title: "Tipo Entrevista",
      dataIndex: "kit_type",
      key: "type",
      render: (type) =>
        (type === "INTERVIEW" && (
          <Typography.Text>Entrevista</Typography.Text>
        )) ||
        (type === "CARD" && <Typography.Text>Scorecard</Typography.Text>),
    },
    {
      title: "",
      key: "action",
      render: (item) =>
        currentKits.map((obj) => obj.id).includes(item.id) ? (
          <span>
            <Button
              className="action-btn"
              onClick={() => removeInterviewKit(item)}
            >
              Quitar
            </Button>
          </span>
        ) : (
          <span>
            <Button
              className="action-btn"
              onClick={() => addInterviewKit(item)}
            >
              Agregar
            </Button>
          </span>
        ),
    },
  ];

  return (
    <>
      <EvaluationCard
        logo="https://genoma-assets.s3.us-east-2.amazonaws.com/vint-icon-activity.svg"
        evaluationTypeImg={[]}
        title={"Entrevistas personalizadas"}
        description={"Esta evaluación podrás ser usada en todas las etapas"}
        additionalInformation={[
          { value: "Todo tipo de cargos", icon: "user" },
          { value: "ES, EN, PT", icon: "flag" },
        ]}
        viewMoreContent={() => {
          return null;
        }}
        buttons={() => [
          <Button
            key={"add"}
            className="evaluation-add-button"
            onClick={() => setIsVisible(true)}
          >
            {interviewKits.currentEvaluations.length == 0 ? (
              <>
                <Icon type="plus" />
                <span>Configurar y agregar</span>
              </>
            ) : (
              <>
                <Icon type="check" />
                <span>Editar</span>
              </>
            )}
          </Button>,
        ]}
        game={false}
      />
      <Modal
        title={
          <div style={{ fontSize: 18, fontWeight: 700 }}>
            Configurar entrevistas personalizadas
          </div>
        }
        visible={isVisible}
        maskClosable={false}
        onOk={() => setIsVisible(false)}
        onCancel={() => setIsVisible(false)}
        cancelText={"Cancelar"}
        width={1000}
        forceRender={true}
        destroyOnClose={true}
        footer={[
          <Button key="cancel" onClick={() => setIsVisible(false)}>
            Cancelar
          </Button>,
          <Button key="ok" type="primary" onClick={onSave}>
            {interviewKits.currentEvaluations.length > 0
              ? "Editar Entrevistas Personalizadas"
              : "Crear  Entrevistas Personalizadas"}
          </Button>,
        ]}
        className="evaluation-modal"
      >
        <div className="evaluation-modal-title">Selección de entrevistas</div>
        <div className="evaluation-modal-section">
          Agrega un paquete personalizado para crear la entrevista.
        </div>

        <div className="evaluation-modal-sub-title">
          Preguntas seleccionadas
        </div>
        {currentKits.length == 0 ? (
          <div className="evaluations-flex-div">
            <Row type="flex" justify="center">
              <img
                src={
                  "https://genoma-assets.s3.us-east-2.amazonaws.com/vint-icon-activity.svg"
                }
                alt="cute-logo"
                style={{ height: 40 }}
              />
            </Row>
            <Row type="flex" justify="center">
              <Typography.Text strong style={{ alignText: "center" }}>
                Aún no has seleccionado ningun paquete.
              </Typography.Text>
            </Row>
            <Row type="flex" justify="center">
              <Typography.Text>
                Busca paquetes debajo de esta sección.
              </Typography.Text>
            </Row>
          </div>
        ) : (
          <Row type="flex" justify="start" gutter={[20, 20]}>
            <Col xs={24}>
              <Table
                pagination={false}
                dataSource={currentKits}
                columns={columns}
                rowKey="id"
              />
            </Col>
          </Row>
        )}
        <div className="evaluation-modal-sub-title" style={{ marginTop: 10 }}>
          Paquetes disponibles
        </div>
        <Row type="flex" justify="start" gutter={[20, 20]}>
          <Col xs={24}>
            <Table
              pagination={false}
              dataSource={availableKits}
              columns={columns}
              rowKey="id"
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default InterviewKits;
