import React from 'react';
import { Row, Col, Card, Table, Typography } from 'antd';

import {
  getLineCurveData,
  prepareStatisticsData,
  getStatisticsColumns,
  renderTooltip,
} from '../../assets/utils';

import { getColors } from '../../../../../components/charts/utils';

import { ResponsiveLine } from '@nivo/line';

const { Text } = Typography;

const LineWithTable = ({
  cardTitle,
  responsiveConfig,
  data,
  lineKey,
  colors = null,
  tooltipLabels,
  columns = null,
  customTitles = null,
  customTableData = null,
}) => {
  const defaultColors = colors ?? getColors(Object.keys(data).length);

  const atLeastOneKey = Object.keys(data).some((key) => {
    return Object.keys(data[key]).length;
  });

  return (
    <Col xs={24}>
      <Card
        title={cardTitle}
        style={{ border: '1px solid rgb(229, 229, 229)' }}
      >
        {atLeastOneKey ? (
          <Row type="flex" gutter={16} align='middle'>
            <Col {...responsiveConfig} style={{ height: 300 }}>
              <ResponsiveLine
                data={getLineCurveData(data, lineKey, customTitles)}
                margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
                curve="monotoneX" // Configura el tipo de curva
                axisTop={null}
                axisRight={null}
                xScale={{ type: 'linear', min: 'auto', max: 'auto' }}
                colors={defaultColors}
                pointColor={{ theme: 'background' }}
                useMesh={true}
                areaBaselineValue={0}
                areaOpacity={1}
                enableArea={true}
                tooltip={(tooltipProps) =>
                  renderTooltip({
                    ...tooltipProps,
                    xLabel: tooltipLabels[0],
                    yLabel: tooltipLabels[1],
                  })
                }
              />
            </Col>
            <Col {...responsiveConfig}>
              <Table
                dataSource={prepareStatisticsData(
                  customTableData ?? data,
                  customTitles
                )}
                columns={getStatisticsColumns(
                  columns ??
                    Object.keys(data).sort().map((key) => ({
                      key: customTitles ? customTitles[key] : key,
                      title: customTitles ? customTitles[key] : (key ? key[0].toUpperCase() + key.slice(1) : key),
                    })),
                  { colors: defaultColors }
                )}
                pagination={false}
                style={{ border: '1px solid #D9D9D9' }}
              />
            </Col>
          </Row>
        ) : (
          <Text
            strong
            style={{ display: 'block', width: '100%', textAlign: 'center' }}
          >
            Sin información.
          </Text>
        )}
      </Card>
    </Col>
  );
};

export default LineWithTable;
