import React from 'react';
import { Row, Col, Typography, Icon, Tabs, Divider } from 'antd';
import i18n from '../../../i18n';

const { Text, Title } = Typography;

const Footer = () => {
  return (
    <div className="report-pdf-footer">
      <Divider />
      <span>
      {i18n.t('candidate_pdf__not_authorization_to_share_report', {ns: 'candidatePdf'})}
      </span>
    </div>
  );
};

export default Footer;
