import React, { useEffect, useMemo, useState } from 'react';

import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Icon,
  Input,
  List,
  Menu,
  Row,
  Typography,
} from 'antd';

import i18n from '../i18n';
import EmptySection from '../containers/CandidateDashboard/Profile/components/Empty';
import ProcessStatusBadge from './ProcessStatusBadge';
import useBoolean from '../hooks/useBoolean';

const { Text, Title } = Typography;

export default function SelectProcessesComponent({
  disabledJobApplications,
  fetchJobApplications,
  jobApplicationChoices,
  nextPage,
  selectedJobApplications,
  setSelectedJobApplications,
}) {
  const [searchJobApp, setSearchJobApp] = useState();
  const [selectedOrderCriteria, setSelectedOrderCriteria] = useState('creation-date');
  const [loadingMore, handleLoadingMore] = useBoolean(false);

  useEffect(() => {
    let searchQuery = searchJobApp ? `job_application=${searchJobApp.toLocaleLowerCase()}` : '';
    if (loadingMore) {
      searchQuery = `${searchQuery}${searchQuery ? '&' : '?'}page=${nextPage}`;
      handleLoadingMore.off();
    }
    fetchJobApplications(searchQuery, loadingMore);
  }, [searchJobApp, loadingMore]);

  const sortedJobApplicationChoices = useMemo(() => {
    const jobapps = Object.values(jobApplicationChoices)
    if (selectedOrderCriteria === 'creation-date') {
      return jobapps?.sort((a, b) => {
        if (a.creation_date < b.creation_date) return 1;
        if (a.creation_date > b.creation_date) return -1;
        return 0;
      });
    }

    if (selectedOrderCriteria === 'alphabetically') {
      return jobapps?.sort((a, b) => {
        if (a.job_application.toLowerCase() < b.job_application.toLowerCase()) return -1;
        if (a.job_application.toLowerCase() > b.job_application.toLowerCase()) return 1;
        return 0;
      });
    }

    return jobapps;
  }, [selectedOrderCriteria, jobApplicationChoices]);

  const orderMenu = (
    <Menu defaultSelectedKeys={["creation-date"]} selectable>
      <Menu.Item
        key="creation-date"
        onClick={() => setSelectedOrderCriteria('creation-date')}
      >
        Fecha de creación (más reciente)
      </Menu.Item>
      <Menu.Item
        key="alphabetically"
        onClick={() => setSelectedOrderCriteria('alphabetically')}
      >
        Títulos A-Z
      </Menu.Item>
    </Menu>
  );

  return (
    <Row style={{ flexDirection: 'column' }} type="flex">
      <Col style={{ marginBottom: '2em' }}>
        <Title level={4}>{i18n.t('commons__processes')}</Title>
        <Text>Adicionalmente, puedes elegir uno o más procesos para agregar al candidato pasivo</Text>
      </Col>
      <Col style={{ marginBottom: '2em' }}>
        {selectedJobApplications.length ? (
          <List style={{ height: '12em', overflow: 'auto' }}>
            {selectedJobApplications.map(({ id, name, status }) => (
              <List.Item key={id}>
                <Row align="middle" justify="space-between" key={id} style={{ width: '100%' }} type='flex'>
                  <Col>
                    <Row align="top" type="flex">
                      <Col style={{ marginLeft: '0.25em', marginRight: '0.25em' }}>
                        <ProcessStatusBadge status={status} />
                      </Col>
                      <Col>
                        <Text strong style={{ color: 'black' }}>
                          {name}
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    {!disabledJobApplications.includes(id)
                      ? (
                        <Icon
                          onClick={() => setSelectedJobApplications(
                            (prevSelectedJobApplications) => prevSelectedJobApplications.filter(({ id: jobAppId }) => jobAppId !== id)
                          )}
                          type="close"
                        />
                      ) : null}
                  </Col>
                </Row>
              </List.Item>
            ))}
          </List>
        ) : (
          <EmptySection
            title="Sin procesos"
            subtitle="No se asignará ningún proceso a menos que decidas seleccionarlo."
          />
        )}
      </Col>
      <Col>
        <Row align="middle" justify="space-between" type="flex">
          <Col span={17}>
            <Input.Search
              allowClear
              onSearch={(value) => setSearchJobApp(value.toLocaleLowerCase())}
              placeholder={i18n.t('invite_candidate__search_process__placeholder')}
              style={{ marginBottom: '0.75em', marginTop: '0.75em' }}
            />
          </Col>
          <Col>
            <Dropdown overlay={orderMenu}>
              <Button>
                <Icon type="align-left" /> Ordenar por <Icon type="down" />
              </Button>
            </Dropdown>
          </Col>
        </Row>
        <Row style={{ maxHeight: '15em', overflow: 'auto' }}>
          {sortedJobApplicationChoices.map(({ id, job_application, status, department }) => (
            <Row 
              key={id}
              style={{
                backgroundColor: '#fafafa',
                border: '1px solid #e9e9e9',
                height: 60,
                padding: 10,
                width: '100%',
              }}
              type='flex'
            >
              <Col span={1}>
                <Checkbox
                  checked={selectedJobApplications.map(({ id }) => id).includes(id)}
                  disabled={disabledJobApplications.includes(id)}
                  onChange={() => setSelectedJobApplications((prevSelectedJobApplications) => {
                    const auxSelectedJobApplicationsIds = prevSelectedJobApplications.map(({ id }) => id);
                    if (auxSelectedJobApplicationsIds.includes(id)) return prevSelectedJobApplications.filter(({ id: jobAppId }) => jobAppId !== id);
                    return [...prevSelectedJobApplications, { id, name: job_application, status, department }];
                  })}
                  value={id}
                />
              </Col>
              <Col span={1} style={{ marginLeft: '0.25em', marginRight: '0.25em' }}>
                <ProcessStatusBadge status={status} />
              </Col>
              <Col>
                <Text strong style={{ color: 'black' }}>{job_application}</Text>
                <br/>
                <Text>{department}</Text>
              </Col>
            </Row>
          ))}
          {/* FIXME: Debería haber una forma más "amigable" de cargar más procesos, en vez de tener
          que bajar hasta el final para recién ver el botón, lo dejo así pq recién en producción nos
          dimos cuenta que la request de todos los procesos disponibles viene paginada, por lo que
          no se muestran todos los procesos en la lista */}
          {nextPage
            ? (
              <Row
                style={{
                  backgroundColor: '#fafafa',
                  border: '1px solid #e9e9e9',
                  height: 60,
                  padding: 10,
                  width: '100%',
                }}
                type='flex'
              >
                <Col span={24}>
                  <Button
                    block
                    onClick={() => handleLoadingMore.on}
                    style={{ height: '100%' }}
                    type="link"
                  >
                    Cargar más procesos
                  </Button>
                </Col>
              </Row>
            )
            : null}
        </Row>
      </Col>
    </Row>
  );
}
