import i18n from "../i18n";

const careerAreas = {
  agropecuaria: [
    'Recursos Marinos',
    'Ciencias Agrarias',
    'Forestal',
    'Alimentos',
    'Medicina Veterinaria'
  ],
  art: [
    'Arquitectura',
    'Diseño',
    'Música',
    'Artes',
    'Artes Escénicas',
    'Artes de comunicación'
  ],
  science: [
    'Bachillerato / College',
    'Biología',
    'Biotecnología',
    'Bioquímica',
    'Química',
    'Medio Ambiente',
    'Geología',
    'Geografía',
    'Física',
    'Matemática',
    'Estadística',
  ],
  socialScience: [
    'Derecho',
    'Ciencias Políticas',
    'Trabajo Social',
    'Sociología',
    'Psicología',
    'Periodismo',
    'Comunicaciones',
    'Orientación',
  ],
  humanidades: [
    'Bachillerato / College',
    'Historia',
    'Letras',
    'Bibliotecnología',
    'Filosofía',
    'Estética',
    'Ciencias Religiosas',
  ],
  education: [
    'Educación Parvularia',
    'Educación Básica',
    'Educación Media',
    'Educación Especial',
  ],
  salud: [
    'Bachillerato / College',
    'Medicina',
    'Odontología',
    'Enfermería',
    'Obstetricia',
    'Nutrición y Dietética',
    'Tecnología Médica',
    'Kinesiología',
    'Terapia Ocupacional',
    'Fonoaudiología',
    'Química y Farmacia',
  ],
  admin: [
    'Administración',
    'Contabilidad',
    'Comercio',
    'Economía',
    'Marketing',
    'Finanzas',
    'Turismo y Hotelería',
    'Secretariado',
  ],
  ing: [
    'Bachillerato / College',
    'Ingeniería Civil',
    'Obras Civiles',
    'Industrial',
    'Eléctrica / Electrónica',
    'Computación e Informática',
    'Química y Metalurgia',
    'Transporte y Telecomunicación',
    'Minas',
    'Mecánica',
    'Sonido y Acústica',
    'Biotecnología',
  ]
};

export const careerAreaOptions = [
  {
    groupName: i18n.t('form__career_options__1'),
    groupLabels: careerAreas.agropecuaria.map(
      (value, idx) => ({ label: i18n.t(`form__career_options__1__${idx+1}`), value })
    ),
  },
  {
    groupName: i18n.t('form__career_options__2'),
    groupLabels: careerAreas.art.map(
      (value, idx) => ({ label: i18n.t(`form__career_options__2__${idx+1}`), value })
    ),
  },
  {
    groupName: i18n.t('form__career_options__3'),
    groupLabels: careerAreas.science.map(
      (value, idx) => ({ label: i18n.t(`form__career_options__3__${idx+1}`), value })
    ),
  },
  {
    groupName: i18n.t('form__career_options__4'),
    groupLabels: careerAreas.socialScience.map(
      (value, idx) => ({ label: i18n.t(`form__career_options__4__${idx+1}`), value })
    ),
  },
  {
    groupName: i18n.t('form__career_options__5'),
    groupLabels: careerAreas.humanidades.map(
      (value, idx) => ({ label: i18n.t(`form__career_options__5__${idx+1}`), value })
    ),
  },
  {
    groupName: i18n.t('form__career_options__6'),
    groupLabels: careerAreas.education.map(
      (value, idx) => ({ label: i18n.t(`form__career_options__6__${idx+1}`), value })
    ),
  },
  {
    groupName: i18n.t('form__career_options__7'),
    groupLabels: careerAreas.salud.map(
      (value, idx) => ({ label: i18n.t(`form__career_options__7__${idx+1}`), value })
    ),
  },
  {
    groupName: i18n.t('form__career_options__8'),
    groupLabels: careerAreas.admin.map(
      (value, idx) => ({ label: i18n.t(`form__career_options__8__${idx+1}`), value })
    ),
  },
  {
    groupName: i18n.t('form__career_options__9'),
    groupLabels: careerAreas.ing.map(
      (value, idx) => ({ label: i18n.t(`form__career_options__9__${idx+1}`), value })
    ),
  },
];

export const careerStateOptions = [
  {
    label: i18n.t('form__status__1'),
    value: 'TIT',
  },
  {
    label: i18n.t('form__status__2'),
    value: 'EGR',
  },
  {
    label: i18n.t('form__status__3'),
    value: 'CUR',
  },
  {
    label: i18n.t('form__other'),
    value: 'OTHER',
  },
];

export const careerTypeOptions = [
  {
    label: i18n.t('form__academic_level_1'),
    value: 'SECUN',
  },
  {
    label: i18n.t('form__career_type_option'),
    value: 'TECPRO',
  },
  {
    label: i18n.t('form__academic_level_7'),
    value: 'PREG',
  },
  {
    label: i18n.t('form__academic_level_4'),
    value: 'POSTG',
  },
  {
    label: i18n.t('form__other'),
    value: 'OTHER',
  }
];

export const educationLevelOptions = [
  {
    label: i18n.t('form__academic_level_1'), 
    value: 'Media/Secundaria',
  },
  {
    label: i18n.t('form__academic_level_2'), 
    value: 'Técnico profesional superior',
  },
  {
    label: i18n.t('form__academic_level_3'), 
    value: 'Universitaria',
  },
  {
    label: i18n.t('form__academic_level_4'), 
    value: 'Postgrado',
  },
  {
    label: i18n.t('form__academic_level_5'), 
    value: 'Magister',
  },
  {
    label: i18n.t('form__academic_level_6'), 
    value: 'Doctorado',
  },
];
