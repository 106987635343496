import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../actions/data'
import moment from 'moment'
import { Table, Switch } from 'antd'

const { Column } = Table;

const CollaboratorsList = (props) => {
  const [users, setUsers] = useState([])
  const [emailToDelete, setEmailToDelete] = useState(null)

  useEffect(() => {
    getUsers()
  }, [])

  const getUsers = () => {
    const url = 'gamesandtests/sendmailinvitation/'

    props.actions.fetchData(props.token, url, '', 'get').then((json) => setUsers(json.results))
  }
  //props.data.results && !users && getUsers()

  const deleteUser = (email) => {
    setEmailToDelete(email)
    const url = 'gamesandtests/sendmailinvitation/'
    const data = {
      email: email
    }

    props.actions.sendData(props.token, url, JSON.stringify(data), 'delete', '').then(() => {
      const filteredUsers = users.filter((user) => user.email !== email)
      setUsers(filteredUsers)
    })
  }

  return (
    <div className='gw-as__users__table'>
      {users ?
        <Table
          dataSource={users}
          pagination={false}
          rowClassName={(record) => record.email === emailToDelete ? 'email-to-delete' : null}
          style={{ backgroundColor: '#fff' }}
        >
          <Column title='Nombre' dataIndex='business_user' key='name' />
          <Column title='Email' dataIndex='email' key='email' />
          <Column title='Invitado por' dataIndex='father_name' key='invitedBy' />
          <Column
            title='Fecha invitación'
            key='date'
            render={(record) => moment(record.date_only_joined).format('DD/MM/YYYY')}
          />

          {/* TODO backend */}
          {/*<Column 
            title='Rol' 
            dataIndex='rol' 
            key='rol' 
            render={(record, idx) => (
              <span key={idx}>Admin</span>
            )}
            />*/}

          <Column
            title='Cuenta activada'
            key='accountActivated'
            render={(record, idx) => (
              <Switch
                key={idx}
                defaultChecked={!record.default_password}
                disabled
              />
            )}
          />

          {/* <Column
            title=''
            key='delete'
            render={(record, idx) => (
              <Button 
                key={idx}
                icon='delete' 
                onClick={() => deleteUser(record.email)} 
                disabled={record.email === emailToDelete}
              />
            )}
          /> */}
        </Table>
        :
        null
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    data: state.data.data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollaboratorsList);
