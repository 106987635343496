import React, { useState, useContext, useEffect } from 'react'

import {
  Col, Row, Icon, Typography, Tooltip, Button
} from 'antd';
import JobAppContext from '../../context/JobAppContext';

import StageConfigurationModal from './StageConfigurations';

function StagesBar() {
  const [stageActive, setStageActive] = useState(0);
  const [stageConfigVisible, setStageConfigVisible] = useState(false);
  const [newStageConfigVisible, setNewStageConfigVisible] = useState(false);
  const [newStageIndex, setNewStageIndex] = useState(undefined);

  const [evalsData, setEvalsData] = useState({ time: 0, total: 0 });

  const {
    addStage,
    stages,
    currentStage,
    updateCurrentStage,
    additionalQuestions,
    cuTes,
    games,
    updateStages,
    videoInterviews,
  } = useContext(JobAppContext);

  useEffect(() => { countEvaluations() }, [additionalQuestions, cuTes, games, videoInterviews,]);
  useEffect(() => {
    const idx = stages.findIndex(obj => obj.id === currentStage);
    setStageActive(idx);
  }, [currentStage]);

  const swapElements = (arr, idx1, idx2) => {
    [arr[idx1], arr[idx2]] = [arr[idx2], arr[idx1]];
  };

  const moveLeft = (id) => {
    const index = stages.findIndex(obj => obj.id === id)
    if (index == 0) return;
    const newStages = [...stages];
    swapElements(newStages, index, index - 1)
    setStageActive(oldState => oldState - 1);
    updateStages(newStages);
  };

  const moveRight = (id) => {
    const index = stages.findIndex(obj => obj.id === id)
    if (index == stages.length - 1) return;
    const newStages = [...stages];
    swapElements(newStages, index, index + 1)
    setStageActive(oldState => oldState + 1);
    updateStages(newStages);
  };

  const Stage = ({ id, stageIndex, active, name, required }) => {
    const getStageStatus = () => {
      if (disabledStages(id)) {
        return 'disabled';
      }
      else if (active) {
        return 'active';
      }
      else {
        return '';
      }
    };

    const updateActiveStage = () => {
      if (disabledStages(id)) return;
      setStageActive(stages.findIndex(obj => obj.id === id))
      updateCurrentStage(id);
    };

    const disabledStages = (id) => {
      if (id === 'HIRED' || id === 'OTHER') {
        return true;
      }
      return false
    };

    const showNavLeftButton = () => {
      if (stages[stageIndex - 1]?.required || stageIndex == 0 || stages[stageIndex]?.required) {
        return 'none';
      };
      return null;
    }

    const showNavRihtgButton = () => {
      if (stages[stageIndex + 1]?.required || stages[stageIndex]?.required) {
        return 'none';
      };
      return null;
    }

    return (
      <Row
        type='flex' align='middle'
        className={`jobapp-stage ${getStageStatus()}`}
        onClick={() => updateActiveStage()} style={{ cursor: 'pointer' }}
      >
        {
          getStageStatus() == 'active' &&
          <>
            <Button
              shape="circle"
              icon="arrow-left"
              size={'small'}
              className={'move-stage-button__left'}
              style={{ borderColor: '#597EF7', color: '#597EF7', backgroundColor: '#F0F5FF', display: showNavLeftButton() }}
              onClick={() => moveLeft(id)}
            />
            <Button
              shape="circle"
              icon="arrow-right"
              size={'small'}
              className={'move-stage-button__right'}
              style={{ borderColor: '#597EF7', color: '#597EF7', backgroundColor: '#F0F5FF', display: showNavRihtgButton() }}
              onClick={() => moveRight(id)}
            />

          </>
        }
        <Col xs={20} sm={21}>
          <Row>
            <div className='stage-name'>
              <Tooltip title={name} ><span>{name}</span></Tooltip>
            </div>
          </Row>

          <div style={{ color: '#A4A4A4' }}>
            {required ? <Icon type='lock' style={{ marginRight: 8 }} /> : <Icon type='unlock' style={{ marginRight: 8 }} />}
            <span>{required ? 'Etapa fija' : 'Etapa flexible'}</span>
          </div>
        </Col>

        <Col xs={4} sm={3} style={{ height: '100%' }}>
          {/** INITIAL es especial, no podemos meterle juego, pero es posible borrarla/cambiarle el nombre */}
          {(!disabledStages(id) || id === 'INITIAL') && <Icon style={{ marginLeft: 8, cursor: 'pointer' }} type='edit' onClick={() => openStageConfig(stageIndex, id)} />}
        </Col>
      </Row>
    )
  };

  const openStageConfig = (stageIndex, id) => {
    setStageActive(stageIndex);
    updateCurrentStage(id);
    setStageConfigVisible(true);
  };

  const addLocalStage = (newIdx) => {
    setNewStageConfigVisible(true);
    setNewStageIndex(newIdx);
  };

  const addInitialStage = () => {
    const newStage = {
      id: 'INITIAL',
      name: 'Etapa inicial',
      required: true,
    }
    addStage(newStage, 0)
  };

  const buildStages = () => {
    let newStages = [];
    stages?.forEach((obj, idx) => {
      newStages.push(
        <div key={idx} style={{ position: 'relative' }}>
          <Stage
            key={obj.id}
            name={obj.name}
            required={obj.required}
            active={obj.id === currentStage}
            id={obj.id}
            stageIndex={idx}
          />
          {
            (stages.length != idx + 1 && obj.id !== 'HIRED') &&
            <Button
              shape="circle"
              icon="plus"
              size={'small'}
              className={'add-stage-button'}
              style={{ borderColor: '#F175A5', color: '#F175A5' }}
              onClick={() => addLocalStage(idx + 1)}
            />
          }
        </div>
      )
    })
    const leftButton =
      <div className='add-stage-button-left' key='add-left-button'>
        <Button
          shape="circle"
          icon="plus"
          type="dashed"
          size={'small'}
          style={{ borderColor: '#F175A5', color: '#F175A5', paddingBottom: 10 }}
          onClick={addInitialStage}
        />
      </div>

    if (stages.find(obj => obj.id === 'INITIAL')) {
      return newStages;
    }
    else {
      return [leftButton].concat(newStages);
    }
  };

  const countEvaluations = () => {
    let time = 0;
    let totalEvals = 0;

    if (additionalQuestions.currentEvaluations.length > 0) {
      // Por ahora max 1 eval, cada pregunta suma 15 seg
      totalEvals = totalEvals + 1;
      time = time + 0.25 * additionalQuestions.currentEvaluations[0].questions.length;
    };
    if (cuTes?.currentEvaluations?.length > 0) {
      totalEvals = totalEvals + cuTes.currentEvaluations.length;
      cuTes.currentEvaluations.forEach(obj => {
        time = time + (obj.questions?.global_time ? obj.questions.global_time / 60 : 0);
      });
    };
    if (games.currentEvaluations?.length > 0) {
      totalEvals = totalEvals + games.currentEvaluations.length;
      games.currentEvaluations.forEach(obj => {
        time = time + obj.time_estimated;
      });
    };
    if (videoInterviews.currentEvaluations?.length > 0) {
      // Por ahora max 1 eval
      totalEvals = totalEvals + 1;
      videoInterviews.currentEvaluations[0].questions.forEach(obj => {
        time = time + obj.time / 60;
      });
    };

    setEvalsData({ time: time, total: totalEvals });
  }

  return (
    stages.length > 0 ?
      <Row type='flex' justify='end' align='middle'>
        <Row type='flex' justify='center' align='middle' className='jobapp-stage-summary' style={{ gap: 8 }}>
          <img style={{ height: 20 }} src='https://genoma-assets.s3.us-east-2.amazonaws.com/games-lib/assets/coin.png' />
          <Typography.Text>{`${evalsData.total} evaluaciones |`}</Typography.Text>
          <Icon type='clock-circle' />
          <Typography.Text>{`${Math.round(evalsData.time)} minutos`}</Typography.Text>
        </Row>
        <div className='jobapp-stages'>
          {buildStages()}
        </div>
        <StageConfigurationModal
          handleOk={() => setStageConfigVisible(true)}
          handleCancel={() => setStageConfigVisible(false)}
          visible={stageConfigVisible}
          newStage={false}
          stageIndex={stageActive}
        />
        <StageConfigurationModal
          handleOk={() => setNewStageConfigVisible(true)}
          handleCancel={() => setNewStageConfigVisible(false)}
          visible={newStageConfigVisible}
          newStage={true}
          stageIndex={newStageIndex}
        />
      </Row>
      :
      <>
      </>
  );
};

export default StagesBar;