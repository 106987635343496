import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showModal, hideModal } from '../../../actions/modal';
import * as actionCreators from '../../../actions/data';
import * as authActionCreators from '../../../actions/auth';
import * as traitActionsCreators from '../../../actions/trait';

import moment from 'moment';

import Filters from './actions/filters';
import HoverActions from './actions/hoverActions';
import NewProcess from './actions/newProcess';
import OldNewProcess from './actions/oldNewProcess';
import ExportCandidates from './actions/exportCandidates';
import TabBar from './tabs/tabs';
import ProcessStatusBadge from '../../../components/ProcessStatusBadge';
import BlackFooter from '../../../components/blackFooter.jsx';

import {
  Typography,
  Tag,
  Button,
  Col,
  Icon,
  Layout,
  message,
  Rate,
  Row,
  Statistic,
  Table,
  Tooltip,
} from 'antd';
import './ProcessList.scss';
import { Link } from 'react-router-dom';
import FooterActions from './components/footerActions';

import useBreadcrumb from '../../../hooks/useBreadcrumb';

const { Text, Title, Paragraph } = Typography;
const { Column } = Table;

const ProcessList = (props) => {
  const [loading, setLoading] = useState(false);
  const [visibleFilters, setVisibleFilters] = useState([]);
  const [usedFilters, setUsedFilters] = useState({
    ACTIV: 0,
    INACT: 0,
    OTHER: 0,
  });
  const [processes, setProcesses] = useState({
    ACTIV: [],
    INACT: [],
    OTHER: [],
  });
  // List of the processes, we need this state to "rescue" all the data after clearing the filters
  const [currentProcesses, setCurrentProcesses] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [currentStatus, setCurrentStatus] = useState('ACTIV');
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedJobApps, setSelectedJobApps] = useState([]);
  const [tableSelectedRowKeys, setTableSelectedRowKeys] = useState([]);
  const [favoriteJobApps, setFavoriteJobApps] = useState({});
  const [searchQueryStrings, setSearchQueryStrings] = useState('');
  const [showNewProcessModal, setShowNewProcessModal] = useState(false);

  const Breadcrumb = useBreadcrumb(
    [
      {
        path: '/',
        text: 'Home',
      },
      {
        path: 'processes',
        text: 'Procesos',
      },
    ],
    '>'
  );

  const openFooter = useMemo(() => {
    return selectedJobApps.length > 0;
  }, [selectedJobApps]);

  useEffect(() => {
    // TODO Falta optimizar aquí, 4 renders innecesarios
    async function fetchData() {
      props.traitActions.resetMeasuredTraits();
      getSubordinates();
    }
    fetchData();
  }, [props.traitActions]);

  useEffect(() => {
    reloadProcesses();
  }, []);

  useEffect(() => {
    reloadProcesses();
  }, [searchQueryStrings]);

  useEffect(() => {
    setSelectedJobApps([]);
  }, [currentStatus, visibleFilters]);

  useEffect(() => {
    props.subordinates &&
      setCollaborators(
        props.subordinates.filter((sub) => sub.email !== props.userName)
      );
  }, [props.subordinates]);

  const reorderProcesses = () => {
    const reorderedProcesses = orderedProcesses(processes[currentStatus]);
    setProcesses((prevState) => ({
      ...prevState,
      [currentStatus]: reorderedProcesses,
    }));
  };

  useEffect(() => {
    reorderProcesses();
  }, [favoriteJobApps, currentStatus]);

  const processesSorter = (criteria) => (a, b) => {
    const favoriteOrder =
      (favoriteJobApps[b.id] ? 1000 : 0) - (favoriteJobApps[a.id] ? 1000 : 0);
    if (favoriteOrder !== 0) {
      // favoriteOrder rules over date order
      return favoriteOrder;
    }
    const bValue = b[criteria].toLowerCase();
    const aValue = a[criteria].toLowerCase();
    return bValue === aValue.toLowerCase() ? 0 : bValue < aValue ? 1 : -1;
  };

  const orderedProcesses = (data, tmpFavoriteJobApps = null) => {
    const currentFavoriteJobApps = tmpFavoriteJobApps
      ? tmpFavoriteJobApps
      : favoriteJobApps;
    return data.sort((a, b) => {
      const favoriteOrder =
        (currentFavoriteJobApps[b.id] ? 1000 : 0) -
        (currentFavoriteJobApps[a.id] ? 1000 : 0);
      if (favoriteOrder !== 0) {
        // favoriteOrder rules over date order
        return favoriteOrder;
      }
      // return new Date(b.creation_date) - new Date(a.creation_date)
      return 0;
    });
  };

  const updateProcesses = (processToUpdate) => {
    setLoading(true);
    if (processToUpdate) {
      loadTableData(currentStatus);
    }
    setLoading(false);
  };

  /**
   *
   * @param { String } status
   */
  const loadTableData = async (status) => {
    setLoading(true);
    const tmpFavoriteJobApps = await getFavoriteJobApplications();
    const url = `/api/v1/gamesandtests/processlist/?format=json&page_size=10000&status=${status}&${searchQueryStrings || 'ordering=-date_created'
      }`;

    const data = props.actions
      .fetchAndReturn(props.token, url, 'GET')
      .then((response) => {
        const processData = response.body.results.map((obj) => obj.job_app);
        const orderedProcessesData = orderedProcesses(
          processData,
          tmpFavoriteJobApps
        );
        setProcesses((prevState) => ({
          ...prevState,
          [status]: orderedProcessesData,
        }));
        if (props.error) {
          openAlertModal(
            'La sesión ha caducado',
            'Su sesión ha expirado, por favor cerre sesión y vuelva a entrar'
          );
          return;
        }
        setLoading(false);
        return orderedProcessesData;
      });
    return data;
  };

  // modal para sesión caducada
  const closeModal = () => {
    props.hideModal();
  };

  const openAlertModal = (title, message) => {
    props.showModal(
      {
        open: true,
        title,
        message,
        closeModal: closeModal,
      },
      'alert'
    );
  };

  const getSubordinates = async () => {
    const url = `accounts/business/${props.business.id}/businessusers/`;
    await props.actions
      .fetchData(props.token, url, '', 'get')
      .then((response) => {
        setCollaborators(
          response.results.filter((sub) => sub.email !== props.userName)
        );
      });
  };

  const openFilters = () => {
    if (visibleFilters.includes('1')) {
      setVisibleFilters([]);
    } else {
      setVisibleFilters(['1']);
    }
  };

  // cantidad de filtros que se está usando
  const filtersCount = (count) => {
    setUsedFilters((prevState) => ({
      ...prevState,
      [currentStatus]: count,
    }));
  };

  // actualizar procesos al filtrar
  const filteredData = (newData) => {
    setProcesses((prevState) => ({
      ...prevState,
      [currentStatus]: orderedProcesses(newData),
    }));
  };

  const patchJobApplications = (jobApplications, dataFactory) => {
    const { token } = props;
    const statusChangePromises = [];
    jobApplications.forEach((jobApp) => {
      const submitData = JSON.stringify(dataFactory(jobApp));
      const url = `gamesandtests/jobapplications/${jobApp.id}/`;
      const patchPromise = props.actions.updateData(
        token,
        url,
        submitData,
        'PATCH'
      );
      statusChangePromises.push(patchPromise);
    });
    Promise.all(statusChangePromises)
      .then(() => {
        updateProcesses(currentStatus);
        message.success('Los procesos han sido actualizados');
      })
      .catch((e) => {
        message.error('Uno o más procesos han fallado, intenta nuevamente.');
      });
  };

  const getFavoriteJobApplications = async () => {
    const url = `/api/v1/gamesandtests/favoritejobapplications/`;
    const tmpFavoriteJobApps = await props.actions
      .fetchAndReturn(props.token, url, 'get')
      .then((favoriteJobAppsData) => {
        const favoriteJobAppsObj = {};
        favoriteJobAppsData.body.forEach(
          (x) => (favoriteJobAppsObj[x.job_application] = 1)
        );
        setFavoriteJobApps(favoriteJobAppsObj);
        return favoriteJobAppsObj;
      });
    return tmpFavoriteJobApps;
  };

  const addFavoriteJobApplications = (jobAppIds) => {
    const url = `/api/v1/gamesandtests/favoritejobapplications/`;
    props.actions
      .fetchAndReturn(props.token, url, 'post', {
        job_application_ids: jobAppIds,
      })
      .then(() => {
        message.success('Proceso agregado a favoritos');
        getFavoriteJobApplications();
      });
  };

  const removeFavoriteJobApplications = (jobAppIds) => {
    const jobAppId = jobAppIds[0];
    const url = `/api/v1/gamesandtests/favoritejobapplications/${jobAppId}/`;
    props.actions.fetchAndReturn(props.token, url, 'delete').then(() => {
      message.warning('Proceso desmarcado como favorito');
      getFavoriteJobApplications();
    });
  };

  const resetSelectedRows = () => {
    setSelectedJobApps([]);
    setTableSelectedRowKeys([]);
  };

  const handleBulkAction = (type) => (options) => {
    if (type === 'status') {
      const dataFactory = () => ({ status: options.status });
      patchJobApplications([...selectedJobApps], dataFactory);
      resetSelectedRows();
    } else if (type === 'favorite') {
      const jobAppIds = selectedJobApps.map((x) => x.id);
      addFavoriteJobApplications(jobAppIds);
    } else if (type === 'tags') {
      const { tag } = options;
      const dataFactory = (jobApp) => ({ tags: [...jobApp.tags, tag] });
      patchJobApplications(selectedJobApps, dataFactory);
      resetSelectedRows();
    } else if (type === 'delete') {
      const promisesArray = [];
      selectedJobApps.forEach((jobApp) => {
        const url = `/api/v1/gamesandtests/jobapplications/${jobApp.id}/`;
        const response = props.actions.fetchAndReturn(
          props.token,
          url,
          'delete'
        );
        promisesArray.push(response);
      });
      Promise.all(promisesArray)
        .then(() => {
          message.success('Procesos eliminados satisfactoriamente');
          const orderedProcessesPromise = loadTableData(currentStatus);
          orderedProcessesPromise.then((orderedProcessesData) => {
            setCurrentProcesses(orderedProcessesData);
          });
          resetSelectedRows();
        })
        .catch(() => {
          message.error('Ocurrió un error, intenta nuevamente');
        });
    }
  };

  const reloadProcesses = () => {
    ['ACTIV', 'INACT', 'OTHER'].forEach((status) => {
      const orderedProcessesPromise = loadTableData(status);
      if (status === currentStatus) {
        orderedProcessesPromise.then((orderedProcessesData) => {
          setCurrentProcesses(orderedProcessesData);
        });
      }
    });
  };

  const jobApplicationStats = useMemo(() => {
    let userJobApplicationsCount = 0;
    let hiredCount = 0;

    ['ACTIV', 'INACT', 'OTHER'].forEach((key) => {
      userJobApplicationsCount += processes[key].reduce(
        (processAcc, jobApp) => {
          return processAcc + jobApp.count_total;
        },
        0
      );
      hiredCount += processes[key].reduce((processAcc, jobApp) => {
        return processAcc + jobApp.count_hired;
      }, 0);
    }, 0);

    const stats = {
      createdCount:
        processes.ACTIV.length +
        processes.INACT.length +
        processes.OTHER.length,
      userJobApplicationsCount,
      hiredCount,
    };
    return stats;
  }, [processes]);

  return (
    <Layout className="gw-pl">
      <div style={{ marginBottom: '1.25rem' }}>{Breadcrumb}</div>
      <Row gutter={10} style={{ marginBottom: 20 }}>
        <Col xs={24} sm={8}>
          <Title level={1}>Procesos</Title>
        </Col>
        <Col xs={24} sm={16}>
          <Row className="gw-pl__stats" type="flex" justify="end">
            <Col xs={8} sm={4}>
              <Statistic
                title="Procesos creados"
                value={jobApplicationStats.createdCount}
              />
            </Col>
            <Col xs={8} sm={4}>
              <Statistic
                title="Postulantes recibidos"
                value={jobApplicationStats.userJobApplicationsCount}
              />
            </Col>
            <Col xs={8} sm={4}>
              <Statistic
                title="Candidatos contratados"
                value={jobApplicationStats.hiredCount}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row type="flex" justify="space-between" gutter={10}>
        <Col xs={24} sm={20}>
          <Filters
            visible={visibleFilters}
            count={filtersCount}
            data={processes}
            updateData={filteredData}
            close={openFilters}
            setSearchQueryStrings={setSearchQueryStrings}
            reloadProcesses={reloadProcesses}
          />
        </Col>
        <Col xs={24} sm={4}>
          <Row type="flex" justify="space-between" align="top">

            <Col span={4}>
              {(props.businessPermissions.business_admin ||
                props.businessPermissions.can_view_all_jobapps) && (
                  <ExportCandidates />
                )}
            </Col>

            <Col span={20}>
              {
                (props.businessPermissions.business_admin || props.businessPermissions.role?.can_create_jobapplications) &&
                <Row type="flex" justify="end" align="top">
                  <div style={{marginBottom: '.5em'}}>
                    <NewProcess showModal={showNewProcessModal} />
                  </div>
                </Row>
              }
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="gw-pl__table">
        <Row className="tabs-row" style={{ width: '100%' }}>
          <TabBar
            onChange={(currentStatus) => {
              setCurrentStatus(currentStatus);
              const orderedProcessesPromise = loadTableData(currentStatus);
              orderedProcessesPromise.then((orderedProcessesData) => {
                setCurrentProcesses(orderedProcessesData);
              });
              setCurrentPage(0);
            }}
            loading={loading}
            processes={processes}
          />
        </Row>
        <Table
          className="gw-pl__processlist"
          loading={loading}
          dataSource={processes[currentStatus]}
          rowKey={(record, idx) => record.id}
          rowSelection={{
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectedJobApps(selectedRows);
              setTableSelectedRowKeys(selectedRowKeys);
            },
            onSelectAll: (selected, selectedRows) => {
              if (
                tableSelectedRowKeys.length === processes[currentStatus].length
              ) {
                setSelectedJobApps([]);
                setTableSelectedRowKeys([]);
              } else {
                setSelectedJobApps(processes[currentStatus]);
                setTableSelectedRowKeys(
                  processes[currentStatus].map((x) => x.id)
                );
              }
            },
            selectedRowKeys: tableSelectedRowKeys,
          }}
          pagination={{
            onChange: (page) => {
              window.scrollTo(0, 0);
              setCurrentPage(page);
            },
            pageSize: 6,
            current: currentPage,
          }}
          locale={{
            emptyText:
              currentStatus === 'ACTIV' ? (
                <div style={{ display: 'grid' }}>
                  <span role="img" style={{ fontSize: 100, color: 'black' }}>
                    <img
                      src="https://genoma-assets.s3.us-east-2.amazonaws.com/genomin_1.svg"
                      alt="genomin"
                    />
                  </span>
                  <Title level={4}>
                    Aún no has iniciado procesos. Vayamos a crear uno...
                  </Title>
                  <Button
                    onClick={() => {
                      setShowNewProcessModal(true);
                    }}
                    type="link"
                  >
                    Crear proceso <Icon type="right" />
                  </Button>
                </div>
              ) : (
                <div style={{ display: 'grid' }}>
                  <span role="img" style={{ fontSize: 100, color: 'black' }}>
                    <img
                      src="https://genoma-assets.s3.us-east-2.amazonaws.com/genomin_2.svg"
                      alt="genomin"
                    />
                  </span>
                  <Text>No se encontraron procesos</Text>
                </div>
              ),
          }}
        >
          <Column
            title="Proceso"
            sorter={processesSorter('job_application')}
            dataIndex="job_application"
            render={(item, record) => (
              <Row className="column-proceso" type="flex" align="middle">
                <Col className="job_app-favorite" sm={12} md={3}>
                  <Rate
                    onChange={(value) => {
                      if (value) {
                        addFavoriteJobApplications([record.id]);
                      } else {
                        removeFavoriteJobApplications([record.id]);
                      }
                    }}
                    value={favoriteJobApps[record.id] ? 1 : 0}
                    count={1}
                    character={
                      <Tooltip
                        title="Marcar como favorito"
                        mouseEnterDelay={0}
                        mouseLeaveDelay={0}
                      >
                        <Icon type="pushpin" theme="filled" />
                      </Tooltip>
                    }
                  />
                </Col>
                <Col className="job_app-status" sm={12} md={3}>
                  <ProcessStatusBadge status={record.status} />
                </Col>
                <Col className="job_app-name" sm={24} md={18}>
                  <Link to={`/processes/${record.id}`}>
                    <Row>
                      <Text
                        className="job_app-name-text"
                        ellipsis
                        strong
                        key={record.id}
                      >
                        {item}
                      </Text>
                      {record.is_confidential ? (
                        <Tooltip title="Proceso confidencial">
                          <div className="confidential-process-icon">
                            <Icon type="lock" />
                          </div>
                        </Tooltip>
                      ) : null}
                    </Row>
                    <Row>
                      <Text
                        type="secondary"
                        ellipsis={{
                          rows: 1,
                        }}
                      >
                        {record.department}
                      </Text>
                    </Row>
                  </Link>
                </Col>
              </Row>
            )}
          />

          <Column
            title="Candidatos"
            dataIndex="count_total"
            render={(item, record) => (
              <>
                <Row>
                  <Text>
                    <Icon type="team" /> Totales: {record.count_total}
                  </Text>
                </Row>
                <Row>
                  <Text>
                    <Icon type="user" /> Perfilados: {record.count_profiled}
                  </Text>
                </Row>
              </>
            )}
            sorter={(a, b) => a.count_total < b.count_total ? 1 : -1}
          />

          <Column
            title="Etiquetas"
            dataIndex="tags"
            render={(tags) => (
              <span>
                {tags.slice(0, 2).map((tag, index) => (
                  <Tag
                    key={index}
                    style={{
                      border: '1px solid #2F54EB',
                      color: '#2F54EB',
                      backgroundColor: '#F0F5FF',
                      margin: '2px',
                    }}
                  >
                    {tag}
                  </Tag>
                ))}
                {tags.length > 2 ? (
                  <Tooltip
                    placement={'bottom'}
                    title={tags.slice(2).join(', ')}
                  >
                    <Tag>...</Tag>
                  </Tooltip>
                ) : null}
              </span>
            )}
          />

          <Column title="Ubicación" dataIndex="location" />

          <Column
            title="Fecha de creación"
            dataIndex="creation_date"
            render={(dueDate) =>
              moment(dueDate).format('DD MMM, YYYY').replace('.', '')
            }
          />

          <Column
            title="Acciones"
            dataIndex="id"
            render={(id, record) => (
              <HoverActions
                status={currentStatus}
                jobAppName={record.job_application}
                subordinates={collaborators}
                updateProcesses={updateProcesses}
                process={record}
                countCandidates={record.count_total}
                loadTableData={loadTableData}
                id={id}
              />
            )}
          />
        </Table>
      </Row>
      <BlackFooter visible={openFooter}>
        <FooterActions
          currentStatus={currentStatus}
          handleBulkAction={handleBulkAction}
          jobApplications={selectedJobApps}
        />
      </BlackFooter>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    data:
      state.data.data &&
      state.data.data.results &&
      state.data.data.results.length &&
      state.data.data.results[0]['job_app'] &&
      state.data.data.results.map(({ job_app }) => job_app),
    userName: state.auth.userName,
    isFetching: state.data.isFetching,
    error: state.data.error,
    alerts: state.alerts,
    logoURL: state.auth.logoURL,
    dataState: state.data.data,
    business: state.auth.currentBusiness,
    businessPermissions: state.auth.businessPermissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    authActions: bindActionCreators(authActionCreators, dispatch),
    traitActions: bindActionCreators(traitActionsCreators, dispatch),
    hideModal: () => {
      dispatch(hideModal());
    },
    showModal: (modalProps, modalType) => {
      dispatch(showModal({ modalProps, modalType }));
    },
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProcessList);
