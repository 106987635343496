import React, { useState, useEffect, useContext } from 'react'

import {
  Button, Icon, Col, Row, Input, Typography, Select
} from 'antd';

import JobAppContext from '../../../context/JobAppContext';
import MceEditor from '../../../../NewProcessFormV2/components/mceeditor/MceEditor';

import '../EvaluationsStyles.scss'

function VideoInterviewConfig({ questions, setQuestions, emailContent, setEmailContent, videoInterviewId, newStage }) {
  const { formType, videoInterviews, hasCandidates } = useContext(JobAppContext);

  const [detailVisible, setDetailVisible] = useState(false);

  useEffect(() => {
    // TODO update current VINTs
  }, [videoInterviews.videoInterviews])

  const onChangeMailContent = (content) => {
    setEmailContent(oldState => ({ ...oldState, content: content }));
  };

  const VideoInterviewFormLabel = ({ label, optional }) => {
    return (
      <span className='jobapp-section-label'>
        {!optional && <span style={{ color: 'red', fontWeight: 400 }}>*</span>} {label} {optional && <span style={optionalLabelStyles}>(opcional)</span>}
      </span>
    )
  }

  const addQuestion = () => {
    setQuestions(oldState => [...oldState, {
      text: '',
      time: 30,
      // preguntas neuvas tienen id undef
      id: undefined,
    }])
  };

  const deleteQuestion = (index) => {
    if (questions[index]) {
      const newQuestions = [...questions];
      newQuestions.splice(index, 1);
      setQuestions(newQuestions);
    };
  };

  const timeDict = [
    { value: 30, label: '00:30' },
    { value: 60, label: '01:00' },
    { value: 90, label: '01:30' },
    { value: 120, label: '02:00' },
    { value: 150, label: '02:30' },
    { value: 180, label: '03:00' },
  ];

  const swapElements = (arr, idx1, idx2) => {
    [arr[idx1], arr[idx2]] = [arr[idx2], arr[idx1]];
  };

  const moveUp = (index) => {
    if (index == 0) return;
    const newQuestions = [...questions];
    swapElements(newQuestions, index, index - 1)
    setQuestions(newQuestions);
  };

  const moveDown = (index) => {
    if (index == questions.length - 1) return;
    const newQuestions = [...questions];
    swapElements(newQuestions, index, index + 1)
    setQuestions(newQuestions);
  };

  const onChangeQuestion = (value, index) => {
    questions[index].text = value;
    setQuestions([...questions]);

  };

  const onChangeTime = (value, index) => {
    questions[index].time = value;
    setQuestions([...questions]);
  };

  const interviewQuestion = (index, question) => {
    return (
      <div key={index}>
        <Row type='flex' justify='space-between' align='middle' style={{ marginBottom: 10, marginTop: 20 }}>
          <Col xs={22} sm={22} md={23} lg={23} style={{ display: 'flex' }}>
            {
              !videoInterviewId &&
              <div style={{ width: 20 }}>
                <div><Icon type='caret-up' onClick={() => moveUp(index)} /></div>
                <div><Icon type='caret-down' onClick={() => moveDown(index)} /></div>
              </div>
            }
            <Input.TextArea rows={1} value={question.text} onChange={event => onChangeQuestion(event.target.value, index)} />
          </Col>

          {!videoInterviewId && <Icon type='delete' style={{ fontSize: 20 }} onClick={() => deleteQuestion(index)} />}
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            <div style={{ margin: !videoInterviewId ? '0px 0px 5px 20px' : '0px 0px 5px 0px' }}>
              <Typography.Text>
                Tiempo límite de respuesta
              </Typography.Text>
            </div>
          </Col>
          <Col xs={12} sm={12} md={8} lg={6}>
            <Select
              suffixIcon={<Icon type='clock-circle' />}
              style={{ width: '100%', marginLeft: !videoInterviewId ? 20 : 0 }}
              value={question.time}
              onChange={value => onChangeTime(value, index)}
            >
              {timeDict.map(obj => (<Select.Option value={obj.value} key={obj.value}>{obj.label}</Select.Option>))}
            </Select>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Col sm={24}>
      <div className='evaluation-card' style={{ marginBottom: 20 }}>
        <div className='evaluation-modal-sub-title' style={{ margin: 0 }}>Preguntas</div>
        <div style={{ margin: '5px 0px 0px 0px' }}>
          <Typography.Text>
            Agrega las preguntas que quieres que los candidatos respondan en la video entrevista.
          </Typography.Text>
        </div>
        {
          questions.map((obj, idx) => interviewQuestion(idx, obj))
        }
        {
          (!hasCandidates || newStage) &&
          <Button
            type='link'
            style={{ color: '#E52E8F', display: 'flex', alignItems: 'center', padding: 0, marginTop: 5 }}
            onClick={addQuestion}
          >
            <Icon type="plus-circle" style={{ lineHeigth: 0, marginLeft: !videoInterviewId ? 20 : 0 }} />
            Agregar pregunta
          </Button>
        }
      </div>
      <div className='evaluation-card' style={{ marginBottom: 10 }}>
        <div className='evaluation-modal-sub-title' style={{ margin: 0 }}>Enviar mensaje</div>
        <div className='evaluation-modal-section '>
          <Typography.Text>
            Escribe el mensaje para notificar a tus candidatos sobre la nueva solicitud de documentos.
          </Typography.Text>

          {
            /**
            <div style={{ marginBottom: 10, marginTop: 10 }}>
              <VideoInterviewFormLabel label={'Asunto'} optional={false} />
            </div>
   
            <Input
                value={subject}
            />
            */
          }
          <div style={{ marginBottom: 10, marginTop: 10 }}>
            <VideoInterviewFormLabel label={'Mensaje'} optional={false} />
          </div>
          <div>
            <MceEditor
              body={emailContent.content}
              handleChange={() => (e) => onChangeMailContent(e.target.value)}
              name='welcome_text'
            />
          </div>
        </div>

      </div>
    </Col>
  );
};

export default VideoInterviewConfig;