import React, { useEffect, useRef, useState } from "react";

import OptionButton from "../commons/OptionButton";

import {
  Row,
  Col,
  Typography,
  Icon
} from 'antd';

const Disc = ({ field, fieldChanged, values, disableNextPage }) => {
  const [state, setState] = useState([]);
  // Be sure that this props never changes
  const disableNextPageStable = useRef(disableNextPage);

  useEffect(() => {
    // Keys are not in the same order, update first
    const updatedState = field.options.map(obj => ({type: obj.type, value: values.find(valueObj => valueObj.type===obj.type).value}) )
    setState(updatedState)
  }, [values])

  useEffect(() => {
    const minusOne = state.findIndex(obj => obj.value === -1);
    const plusOne = state.findIndex(obj => obj.value === 1);

    if (plusOne === -1 || minusOne === -1) {
      disableNextPageStable.current(field._uid, true);
    }
    // did not find a -1 or a +1 in the array
    else {
      disableNextPageStable.current(field._uid, false);
    }
  }, [state, field._uid])

  const onClick = (id, index, valueObj) => {
    let newState = [...state];
    const minusOne = state.findIndex(obj => obj.value === -1);
    const plusOne = state.findIndex(obj => obj.value === 1);

    // Deselect option
    if (minusOne === index) {
      newState[index] = { type: newState[index].type, value: 0 };
      fieldChanged(id, newState);
      setState(newState);
      return
    }
    else if (plusOne === index) {
      newState[index] = { type: newState[index].type, value: 0 };
      fieldChanged(id, newState);
      setState(newState);
      return
    }

    // Select option with another option already selected disc logic
    // First deselect
    if (minusOne !== -1 && valueObj.value === -1) {
      newState[minusOne] = { type: newState[minusOne].type, value: 0 };
      fieldChanged(id, newState);
    }
    else if (plusOne !== -1 && valueObj.value === 1) {
      newState[plusOne] = { type: newState[plusOne].type, value: 0 };
      fieldChanged(id, newState);
    }

    newState[index] = valueObj;
    fieldChanged(id, newState);
    setState(newState);
  }

  // This kind of DISC has 3 options, -1,1 or null (0)
  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        <Typography.Text strong style={{ fontSize: 16, color: '#5E5E5E', userSelect: 'none' }}>
          {field.label}
        </Typography.Text>
      </div>
      <Row gutter={20} type='flex' style={{ marginBottom: 20 }}>
        <Col span={8}>
        </Col>
        <Col span={8}>
          <div style={{ textAlign: 'center' }}>Lo que más me representa</div>
        </Col>
        <Col span={8}>
          <div style={{ textAlign: 'center' }}>Lo que menos me representa</div>
        </Col>
      </Row>
      {
        field.options.map((option, index) => {
          return (
            <Row gutter={20} type='flex' key={option.label}>
              <Col key={option.label} span={8}>
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                  {option.label}
                </div>
              </Col>
              <Col key={`${option.label}-1`} span={8}>
                <OptionButton
                  checked={state[index] ? state[index].value === 1 : false}
                  id={field._uid}
                  label={<Icon type="like" />}
                  value={-1}
                  index={index}
                  type={'likert'}
                  onClick={(id) => onClick(id, index, { type: option.type, value: 1 })}
                />
              </Col>
              <Col key={`${option.label}+1`} span={8}>
                <OptionButton
                  checked={state[index] ? state[index].value === -1 : false}
                  id={field._uid}
                  label={<Icon type="dislike" />}
                  value={1}
                  index={index}
                  type={'likert'}
                  onClick={(id) => onClick(id, index, { type: option.type, value: -1 })}
                />
              </Col>
            </Row>
          );
        })
      }
    </div >
  );
};

export default Disc;
