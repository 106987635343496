import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";

import {
  Avatar,
  Badge,
  Checkbox,
  Col,
  Icon,
  message,
  Modal,
  Row,
  Select,
  Switch,
  Typography,
} from 'antd';

import i18n from '../../../i18n';

const { Text } = Typography;
const { Option } = Select;

const Collaborator = ({ roles, ...props }) => {
  const { avatar, handlePermissionsChange, handleRoleChange, handleStatusChange, email, userPermissions } = props;
  const currentRole = userPermissions?.role;
  const currentKey = currentRole ? Object.keys(currentRole).filter(key => key.includes('can_')) : [];

  const statusFilterList = [
    {
      'text': 'Activo',
      'value': 'ACT',
    },
    {
      'text': 'Pendiente',
      'value': 'PND',
    },
    {
      'text': 'Revocado',
      'value': 'REV',
    }
  ];

  const statusColors = {
    'ACT': 'green',
    'PND': 'yellow',
    'REV': 'red',
  };

  const sortFixedRoles = (roles) => {
    const fixedOrder = ['super admin', 'admin', 'gestor', 'evaluador', 'observador'];
    roles.sort((a, b) => fixedOrder.indexOf(a.name.toLowerCase()) - fixedOrder.indexOf(b.name.toLowerCase()));
    return roles;
  };

  const DetailRole = () => {
    return (
      <div style={{ marginBottom: 15 }}>
        <div style={{ marginBottom: 10, marginTop: 10 }}>
          <Text style={{ color: '#454545', fontSize: 16, weigth: 700 }}>Permisos Independientes</Text>
        </div>

        <div>
          <Checkbox
            checked={userPermissions && userPermissions['can_view_all_jobapps']}
            onChange={(e) => handlePermissionsChange('can_view_all_jobapps')}
          >
            Acceso a todos los procesos
          </Checkbox>
        </div>
        <div style={{ marginBottom: 10, marginTop: 15 }}>
          <Text style={{ color: '#454545', fontSize: 16, weigth: 700 }}>Permisos del Rol</Text>
        </div>
        <div>
          {
            currentKey.map(elemKey => {
              return (
                <div style={{ marginBottom: 5 }} key={elemKey}>

                  <Checkbox
                    checked={currentRole[elemKey]}
                    disabled={true}
                  >
                    {i18n.t(elemKey, {ns: 'businessUser'})}
                  </Checkbox>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
  return (
    <>
      <Row
        type="flex"
        justify="space-between"
        gutter={[16, 48]}
      >

        <Col span={8}>
          <Row type='flex' align='middle' justify='start'>
            <span style={{ marginRight: 10 }}>{avatar}</span><Text>{email}</Text>
          </Row>
        </Col>
        <Col span={12} style={{ borderRadius: "2px" }}>
          <Row type='flex' align='middle' justify='start'>
            <Select
              allowClear
              style={{ width: "90%" }}
              placeholder="Seleccionar permisos"
              onChange={
                (roleId) => handleRoleChange(roleId)
              }
              value={currentRole?.id}
              notFoundContent="Permiso no existe"
            >
              {
                sortFixedRoles(roles).map(obj => (
                  <Option key={obj.id} value={obj.id}>{obj.name}</Option>
                ))
              }
            </Select>
            {
              userPermissions?.status !== 'PND' &&
              <Row type='flex' align='middle' justify='start' style={{ marginTop: 10 }}>
                <Switch checked={userPermissions?.status != 'REV'} onChange={handleStatusChange} style={{marginRight:10}}/>
                {userPermissions?.status === 'REV' ? 'Activar permisos' : 'Desactivar permisos'}
              </Row>
            }
          </Row>
        </Col>
        <Col span={4}>
          <Row type='flex' align='middle' justify='center' style={{ height: 40 }}>
            <div>
              <Badge color={statusColors[userPermissions?.status]} />
              {statusFilterList.find(obj => obj.value === userPermissions?.status)?.text}
            </div>
          </Row>
        </Col>
      </Row>
      <DetailRole />
    </>
  )
}

const EditCollaboratorPermissionsModal = ({ roles, ...props }) => {
  const [editCollaborator, setEditCollaborator] = useState({});

  const { currentCollaborator, showEditPermissionsModal, setShowEditPermissionsModal, updateBusinessPermissionData, sendData, token } = props;

  useEffect(() => {
    if (currentCollaborator) {
      setEditCollaborator({ ...currentCollaborator.businessPermissions, status: currentCollaborator.status.code })
    }
  }, [showEditPermissionsModal, currentCollaborator])

  const handlePermissionsChange = (permission) => {
    editCollaborator[permission] = !editCollaborator[permission];
    setEditCollaborator(() => ({ ...editCollaborator }));
  }

  const handleRoleChange = (roleId) => {
    const updatedRole = roles.find(obj => obj.id === roleId);
    editCollaborator.role = updatedRole;
    setEditCollaborator(() => ({ ...editCollaborator }));
  }

  const handleStatusChange = () => {
    if (editCollaborator.status === 'REV') {
      editCollaborator.status = 'ACT'
    }
    else {
      editCollaborator.status = 'REV'
    }
    setEditCollaborator(() => ({ ...editCollaborator }));
  }

  const submitData = () => {
    const businessUserId = currentCollaborator.businessUser.id;
    const url = `/api/v1/permissions/business/${props.businessId}/users/${businessUserId}/`;

    const data = {
      can_view_all_jobapps: editCollaborator.status === 'REV' ? false: editCollaborator.can_view_all_jobapps,
      // This serializer uses the wole dict
      role: editCollaborator.role.id,
      status: editCollaborator.status
    }

    return sendData(token, url, "PATCH", data).then((response) => {
      if (response.status < 400) {
        const { body } = response;
        const newData = {
          business_admin: body.business_admin,
          can_manage: body.can_manage,
          can_invite: body.can_invite,
          can_create_cell: body.can_create_cell,
          can_view_all_jobapps: body.can_view_all_jobapps,
          role: body.role,
          status: body.status,
        }
        updateBusinessPermissionData(businessUserId, newData);
        message.success("Permisos actualizados");
        handleCancelModal();
      }
      else if (response.status == 403) {
        message.error("No tienes permisos para realizar esta acción");
      }
      else {
        message.error("Error al actualizar datos");
      }
    });
  }

  const handleCancelModal = () => {
    setShowEditPermissionsModal(false);
  }

  const getInitials = (name) => {
    if (name) {
      const initials = name.split(' ').map((word) => word[0]);
      return <Avatar size={40}>{initials.join('').toUpperCase()}</Avatar>
    }
    else {
      return <Avatar size={40}><Icon type="user" /></Avatar>
    }
  }

  return (
    <Modal
      bodyStyle={{ overflowY: 'scroll' }}
      className="invitation-modal"
      style={{ fontFamily: 'Lato' }}
      title={
        <Text style={{ fontWeight: 700 }}>Editar usuario</Text>
      }
      visible={showEditPermissionsModal}
      onCancel={handleCancelModal}
      onOk={submitData}
    >
      <Collaborator
        key={`collaborator`}
        handlePermissionsChange={handlePermissionsChange}
        handleRoleChange={handleRoleChange}
        handleStatusChange={handleStatusChange}
        email={currentCollaborator?.businessUser.email}
        avatar={getInitials(currentCollaborator?.businessUser.name)}
        userPermissions={editCollaborator}
        isBusinessAdmin={props.isBusinessAdmin}
        roles={roles}
      />

    </Modal>
  )
}

EditCollaboratorPermissionsModal.propTypes = {
  showEditPermissionsModal: PropTypes.bool.isRequired,
  setShowEditPermissionsModal: PropTypes.func.isRequired,
  sendData: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  businessId: PropTypes.string.isRequired,
  addBusinessPermissionData: PropTypes.func.isRequired,
  isBusinessAdmin: PropTypes.bool.isRequired,
};

export default EditCollaboratorPermissionsModal;
