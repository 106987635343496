import React, { useState } from 'react';
import {
  Row,
  Col,
  Icon,
  List,
  Button,
  Typography
} from 'antd';
import '../JobListing.scss';
import i18n from '../../../i18n';

const { Title, Text, Paragraph } = Typography;

const AvailableJobs = (props) => {
  const {
    handleApply
  } = props;

  const sortJobs = (a, b) => {
    if (a.branches.length > 0 && b.branches.length > 0) {
      return a.job_application.localeCompare(b.job_application);
    }
    else if (a.branches.length > 0) {
      return -1;
    }
    else if (b.branches.length > 0) {
      return 1;
    }
    else {
      return a.job_application.localeCompare(b.job_application);
    }
  };

  const getGroupTitle = (group) => {
    let value = group.find(obj => obj.branches?.length > 1)
    value = value ? value : group[0]
    if (value.branches.length > 0) {
      return (
        <>
          <Title level={2}>{`${i18n.t('job_portal__jobs_in')} ${i18n.t('job_portal__branches')}`}</Title>
          <Row>
            {i18n.t('job_portal__job_portal__select_branches_info')}
          </Row>
        </>
      )
    }
    else {
      return (
        <>
          <Title level={2}>{value.location_city.charAt(0).toUpperCase() + value.location_city.slice(1)}</Title>
        </>
      )
    }
  };

  return (
    <div>
      {props.cityGroups.map((group, index) =>
        <List
          key={index}
          className='jl__jobs__list'
          header={getGroupTitle(group)}
          dataSource={group.sort((a, b) => sortJobs(a, b))}
          renderItem={(item, index) => (
            <List.Item key={index} className='jl__jobs__list-item'>
              <div style={{ width: '100%' }}>
                <Row type='flex'>
                  <Col xs={20} md={14}>
                    <Text strong>{item.job_application}</Text>
                    {item.address && <Paragraph style={{fontSize:15}}><Icon type='environment' style={{marginRight:10}}/>{item.address}</Paragraph>}
                  </Col>
                  <Col xs={4} md={4} className='location'>
                    <span className='location-with-flag'>
                      <span className='jl-flag'>
                        {props.flag(item.location_country)}
                      </span> {' '}
                      {item.location_city}
                    </span>
                  </Col>

                  <Col xs={4} md={6}>
                    <Button
                      style={{
                        borderColor: props.theme.background,
                        background: props.theme.background,
                        color: props.theme.color,
                        whiteSpace: 'normal',
                        minHeight: 32,
                        height: 'auto',
                      }}
                      onClick={() => handleApply(item)}
                    > {item.branches.length ? i18n.t('job_portal__select_branches') : i18n.t('commons__see_more')}
                    </Button>
                  </Col>
                </Row>
              </div>
            </List.Item>
          )}
        />
      )}
    </div >
  )
}

export default AvailableJobs;
