import React from "react";

import {
  Button,
  Col,
  Row
} from 'antd';
import ParseHtml from "../../../../../components/parseHtml";

const buttonStyle = (checked, type, value=null) => {
  const style = {
    height: 'auto',
    whiteSpace: 'normal',
    marginBottom: '10px',

    marginBottom: 10,
    borderRadius: 1,
    padding: '0 15px 0 0',
    borderColor: checked ? '#1890ff' : '#d9d9d9',
    background: type === 'likert' && checked ? '#E6F7FF' : '#ffffff',

    width: '100%'
  };
  if (type === 'likert') {
    //style['minHeight'] = 30 + Math.abs(value) * 5;
    style['minHeight'] = 40;
  };
  if (type === 'SJT_GW') {
    style['minHeight'] = 40;
    style['border'] = checked ? 'None' : '1px solid #d9d9d9';
    style['background'] = checked ? value === 'best' ? '#95DE64' : '#FF7875' : '#ffffff';
  }
  return style;
};

const divStyle = (type) => {
  const baseStyle = {
    paddingLeft: 15,
    minHeight: 'auto',
    color: 'rgba(0, 0, 0, 0.65)',
    display: 'flex',
    textAlign: 'start',
    justifyContent: ['likert', 'SJT_GW'].includes(type) ? 'center' : 'start',
    alignItems: 'center',
  };

  if (['likert', 'SJT_GW'].includes(type)) {
    baseStyle['width'] = '100%';
  }

  return baseStyle;
}


const getPrefixStyles = (checked) => {
  const style = {
    minHeight: 30,
    background: checked ? '#1890ff' : '#FAFAFA',
    borderRight: checked ? '1px solid #1890ff' : '1px solid #d9d9d9',
    color: checked ? '#fff' : 'rgba(0, 0, 0, 0.65)',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

  return style;
}

const OptionButton = ({ checked, id, label, value, index, prefix, onClick, type, disabled = false }) => {

  const updateButton = () => {
    if (type === 'multi') {
      checked ? onClick(id, index, '') : onClick(id, index, value);
    }
    if (type === 'single') {
      checked ? onClick(id, '') : onClick(id, value);
    }
    if (type === 'likert') {
      checked ? onClick(id, '') : onClick(id, value);
    }
    if (type === 'SJT_GW') {
      onClick(id, value)
    }
  }
  return (
    <div>
      <Button
        style={buttonStyle(checked, type, value)}
        onClick={() => updateButton()}
        disabled={disabled}
      >
        <Row type="flex">
          { prefix &&  
            <Col span={2} style={getPrefixStyles(checked)}>
              {prefix}
            </Col>
          } 
          <Col span={prefix ? 22 : 24} style={divStyle(type)}>
            {['likert', 'SJT_GW'].includes(type) ? label : ParseHtml(label)}
          </Col>
        </Row>
      </Button>
    </div >
  );
};

export default OptionButton;
