import React, { useState } from 'react';
import { Dropdown, Icon, Menu, message, Popconfirm, Tag, Tooltip, Typography } from 'antd';
import i18n from '../../../../i18n';

const { Text } = Typography;

const DiscardReasonTag = ({ application, candidate, discardReasons, editCandidateDiscardReason }) => {

  const [selectedOption, setSelectedOption] = useState(null);

  const handleEditDiscardReason = async () => {
    editCandidateDiscardReason(application, candidate, selectedOption)
    setSelectedOption(null);
  };

  const menu = (
    <Menu onClick={({ key }) => setSelectedOption(key)}>
      <Menu.ItemGroup title={i18n.t('discard_reason__change_reason')}>
        <Menu.Divider />
        {
          discardReasons.map((discardReason) => (
            <Menu.Item key={discardReason.id} disabled={discardReason.title === application.discard_reason_representation}>
              <Text disabled={discardReason.title === application.discard_reason_representation} >{discardReason.title}</Text>
            </Menu.Item>
          ))
        }
      </Menu.ItemGroup>
    </Menu>
  );

  return (
    <Popconfirm
      title={selectedOption && i18n.t('discard_reason__change_reason_to', { reason: discardReasons.find(({id}) => id === selectedOption).title })}
      visible={selectedOption !== null}
      placement="right"
      onCancel={() => setSelectedOption(null)}
      onConfirm={() => handleEditDiscardReason()}
    >
      <Dropdown overlay={menu} trigger={['click']}>
        {application.discard_reason_comment ? 
          (
            <Tooltip 
              title={
                <>
                  <Text style={{ 'color': 'white' }} strong>{i18n.t('commons__comment')}</Text>
                  <br/>
                  {application.discard_reason_comment}
                </>
              }>
                <Tag color='red' style={{ width: 'fit-content', cursor: 'pointer' }}>
                  {application.discard_reason_representation}{' '}
                  <Icon type="exclamation-circle" theme='filled' />
                    {'  '} 
                  <Icon type="ellipsis" />           
                </Tag>
            </Tooltip>
          ) : 
          (
            <Tag color="red" style={{ width: 'fit-content', cursor: 'pointer' }}>
              {application.discard_reason_representation}
              {'  '} 
              <Icon type="ellipsis" />           
            </Tag>
          )
      }        
      </Dropdown>
    </Popconfirm>
  ) 
};

export default DiscardReasonTag;