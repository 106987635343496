import React from 'react'
import CognitiveSlider from './CognitiveSlider'

import { Card, List, Tooltip, Typography, Badge } from 'antd'
import i18n from '../../../../i18n'

const SliderProfile = (props) => {

  const { Text } = Typography
  const { Meta } = Card

  const data = [
    { name: 'feedback__your__score', color: '#ea4c89' },
    { name: 'feedback__average__score', color: '#81ecec', title: 'Promedio de todas las personas que han realizado los juegos de Genomawork.' }
  ];

  return (
    <Card
      style={{ width: '75%' }}
      className='slider-profile-card'
    >
      <Meta
        className='slider-profile-list'
        description={
          data.map((item, index) => (
            <div className='slider-profile-container' key={index} >
              <Badge color={item.color} />
              <Tooltip title={item.title} >
                <Text >{i18n.t(item.name)}</Text>
              </Tooltip>
            </div>
          ))
        }
      />

      {props.traits.map((trait, index) => (
        <CognitiveSlider
          title={trait.trait_id.trait_translation[i18n.language.split('-')[0]]}
          tooltip={trait.trait_id.candidate_description_translation[i18n.language.split('-')[0]]}
          subTitle1={trait.trait_id.lower_bound_translation[i18n.language.split('-')[0]]}
          subTitle2={trait.trait_id.upper_bound_translation[i18n.language.split('-')[0]]}
          value1={trait.score}
          value2={trait.trait_id.average_score}
          sliderPercent={trait.score}
          key={index}
        />
      ))
      }
    </Card>
  )
}

export default SliderProfile
