import React, { useState, useContext, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Button, Col, DatePicker, Row, Spin, Typography, Tooltip } from 'antd';

import { AnalyticsContext } from './context/AnalyticsContext';
import useScreen from '../../../hooks/useScreen';

import Header from './components/Content/sections/Header';
import Overview from './Sections/Overview';
import Candidates from './Sections/Candidates';
import Hiring from './Sections/Hiring';
import Evaluations from './Sections/Evaluations';
import Diversity from './Sections/Diversity';
import Efficiency from './Sections/Efficiency';
import BottomLinks from './Sections/components/BottomLinks';
import AdvancedFilters from './Sections/components/AdvancedFilters';
import ModalPdf from './Sections/components/ModalPdf';
import SearchFilter from './Sections/components/SearchFilter';

const titles = {
  overview: 'Resumen',
  candidates: 'Candidatos',
  hiring: 'Contrataciones',
  evaluations: 'Evaluaciones',
  diversity: 'Diversidad e inclusión',
  satisfaction: 'Satisfacción',
};

const AnalyticsApp = () => {
  const [showModalFilters, setShowModalFilters] = useState(false);
  const [showModalPdf, setShowModalPdf] = useState(false);

  const width = useScreen(300);
  const isBelow1200px = width <= 1200;

  const {
    currentSection,
    setCurrentSection,
    peopleAnalytics: handleDate,
    handleFilter,
    jobAppId,
    isLoading,
  } = useContext(AnalyticsContext);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { Text } = Typography;
  const { RangePicker } = DatePicker;

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
      }}
    >
      <Header />
      <div className="analytics-filters-and-buttons">
        <Text strong>{titles[currentSection]}</Text>
        <div style={{ width: '25%' }}>
          <SearchFilter />
        </div>
        <div className="rangepicker-div">
          <RangePicker
            onChange={(d, s) => handleFilter('date', s)}
            placeholder={['Fecha inicial', 'Fecha final']}
            style={{ width: 'fit-content' }}
            separator=""
          />
          <span
            style={{ position: 'absolute', left: 0, bottom: -20, fontSize: 12 }}
          >
            Este filtro se aplicará automáticamente a todas las métricas.
          </span>
        </div>
        <Button
          icon="filter"
          onClick={() => setShowModalFilters(!showModalFilters)}
          style={{ width: 'fit-content' }}
        >
          Filtros avanzados
        </Button>
        <Tooltip title="Generar reporte PDF" placement="left">
          <Button
            type="primary"
            icon="file-pdf"
            style={{ paddingInline: 6, marginLeft: 'auto' }}
            onClick={() => setShowModalPdf(!showModalPdf)}
          />
        </Tooltip>
      </div>
      {isBelow1200px ? (
        <BottomLinks
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
        />
      ) : null}
      <Spin spinning={isLoading}>
        {currentSection === 'overview' && <Overview />}
        {currentSection === 'candidates' && <Candidates />}
        {currentSection === 'hiring' && <Hiring />}
        {currentSection === 'evaluations' && <Evaluations />}
        {currentSection === 'diversity' && <Diversity />}
        {currentSection === 'satisfaction' && <Efficiency />}
      </Spin>

      <AdvancedFilters
        showModal={showModalFilters}
        setShowModal={setShowModalFilters}
        handleFilter={handleFilter}
      />
      <ModalPdf showModal={showModalPdf} setShowModal={setShowModalPdf} />
    </div>
  );
};

export default AnalyticsApp;
