import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Alert, Button, Col, Icon, Row, Spin, Typography } from 'antd';

import { CandidateContext } from '../config/CandidateContext';

import Information from './Information';
import Content from './Content';
import MergeCandidatesModal from './MergeCandidatesModal';

import GenominSpin from '../../../components/GenominSpin/GenominSpin';
import PassiveCandidateFormModal from '../../BusinessDashboard/CandidateList/components/PassiveCandidateFormModal';

import useBoolean from '../../../hooks/useBoolean';

import debounce from 'lodash.debounce';

const { Text } = Typography;

const FakeModal = (props) => {
  const {
    application,
    candidate,
    candidatesToMerge,
    changeKey,
    closeModal,
    compareCandidates,
    comparingCandidates,
    confirmMerge,
    jobApp,
    loading,
    mergeCandidates,
    mergingCandidates,
    mode,
    possible_duplicates,
    token,
    topRef,
    updatePassiveCandidateData,
  } = useContext(CandidateContext);

  const { actions, businessId, getStageCandidates, setCandidates } = props;

  // logic to handle GO TO TOP button (this is the cleanest approach i had found)
  const [showButton, handleButton] = useBoolean(false);
  const [waitingResponse, handleWaitingResponse] = useBoolean(false);
  const [showPassiveModal, handleShowPassiveModal] = useBoolean(false);
  const [countries, setCountries] = useState(props.countries);

  useEffect(() => {
    // Si recibe countries en las props, no hace el fetch
    if (countries === undefined) {
      actions.fetchAndReturn(token, '/api/v1/constants/countries/', 'GET')
        .then(({ body: countries }) => setCountries(countries))
        .catch((error) => Sentry.captureException(error));
    }
  }, [countries]);

  useEffect(() => {
    if (window.location.hash === '#messages') changeKey(5);
    else if (window.location.hash === '#filesrequest') changeKey(1);
    else if (window.location.hash === '#references') changeKey(2);
    else if (window.location.hash === '#comments') changeKey(4);
  }, [businessId]);

  const debouncedFn = useCallback(
    debounce(() => setTimeout(handleButton.off, 5000), 1000),
    []
  );

  const handleScroll = () => {
    if (!showButton) {
      handleButton.on();
    }

    debouncedFn();
  };

  const activeCandidate = candidatesToMerge.find((candidate) => candidate.candidate_type === 'active');
  const passiveCandidate = candidatesToMerge.find((candidate) => candidate.candidate_type === 'passive');

  return (
    <Spin spinning={waitingResponse}>
      {mergingCandidates
        ? (
          <MergeCandidatesModal
            activeCandidate={activeCandidate}
            passiveCandidate={passiveCandidate}
            mergeCandidates={mergeCandidates}
            confirmMerge={confirmMerge}
          />
        ) : (
          <div className={`new-modal-candidate-bg ${mode}`}>
            <div className="new-candidate-modal">
              {loading ? (
                <GenominSpin />
              ) : (
                <>
                  {(!waitingResponse && possible_duplicates?.length)
                    ? (
                      <Alert
                        banner={true}
                        className="duplicates-alert"
                        closable={false}
                        message={
                          <Row align="middle" justify="space-between" type="flex">
                            <Col span={12}>
                              <Row align="middle" gutter={12} style={{ width: '100%' }} type="flex">
                                <Col>
                                  <Icon
                                    style={{ color: '#1890FF' }}
                                    type="info-circle"
                                  />
                                </Col>
                                <Col>
                                  <Text> Se han encontrado 2 perfiles similares</Text>
                                </Col>
                                <Col>
                                  <Button.Group>
                                    <Button
                                      className={comparingCandidates ? 'other-profile-btn' : 'primary-profile-btn'}
                                      onClick={() => comparingCandidates && compareCandidates(possible_duplicates[0].id)}
                                    >
                                      Perfil 1
                                    </Button>
                                    <Button
                                      className={comparingCandidates ? 'primary-profile-btn' : 'other-profile-btn'}
                                      onClick={() => !comparingCandidates && compareCandidates(possible_duplicates[0].id)}
                                    >
                                      Perfil 2
                                    </Button>
                                  </Button.Group>
                                </Col>
                              </Row>
                            </Col>
                            <Col>
                              <Row align="middle" type="flex">
                                <Col>
                                  <Button
                                    className='primary-profile-btn'
                                    onClick={() => mergeCandidates()}
                                  >
                                    Unir
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        }
                        showIcon={false}
                        type="info"
                      />
                    )
                    : null}
                  {showButton ? (
                    <Button
                      icon="up"
                      style={{
                        position: 'absolute',
                        right: 30,
                        bottom: 20,
                        zIndex: 130,
                        borderRadius: '66%',
                      }}
                      onClick={() =>
                        topRef.current.scrollIntoView({ behavior: 'smooth' })
                      }
                      className="go-to-top-btn"
                    />
                  ) : null}
                  <Button
                    icon="close"
                    style={{
                      position: 'absolute',
                      right: '1rem',
                      zIndex: 1,
                      color: '#5E5E5E',
                    }}
                    onClick={closeModal}
                    type="link"
                  />
                  <Information
                    handleShowPassiveModal={handleShowPassiveModal}
                    handleWaitingResponse={handleWaitingResponse}
                    waitingResponse={waitingResponse}
                  />
                  <Content handleScroll={handleScroll} mode={mode} />

                  {(application && candidate && showPassiveModal) && (
                    <PassiveCandidateFormModal
                      actions={actions}
                      businessId={businessId}
                      closeFakeModal={closeModal}
                      countries={countries}
                      getStageCandidates={getStageCandidates}
                      handleVisible={handleShowPassiveModal}
                      jobApplicationData={jobApp}
                      method="PATCH"
                      passiveApplication={application}
                      passiveCandidate={{
                        id: candidate.id,
                        personaluser_fields: { ...candidate },
                        other_contact_info: { linkedin: '' }, // TODO:
                      }}
                      setCandidates={setCandidates}
                      stages={jobApp.stages.stages}
                      stageOnFocus={application.status}
                      token={token}
                      updatePassiveCandidateData={updatePassiveCandidateData}
                      visible={showPassiveModal}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        )}
    </Spin>
  );
};

export default FakeModal;
