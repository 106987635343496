import React, { Fragment } from 'react';

import {
  Button,
  Col,
  Icon,
  Layout,
  Row,
  Typography
} from 'antd';


const { Text } = Typography;


const BulkActions = (props) => {
  const { bulkDeleteTemplates, bulkToggleArchivedTemplate, handleAllRowsSelected, selectedRows } = props;

  const currentStatus = selectedRows.length && selectedRows[0].status;

  return selectedRows.length ? (
    <Fragment>
      <Layout.Footer
        className='table__footer'
      >
        <Row className='table__actions' type='flex' align='middle' justify='space-between'>
          <Col className='left'>
            <Text strong>
              <Button
                className='close-footer'
                icon='close'
                onClick={() => handleAllRowsSelected(false)}
              />
              {selectedRows.length} seleccionados
            </Text>
          </Col>
          <Col className='right'>
            {bulkToggleArchivedTemplate
              ? (
                <Button onClick={bulkToggleArchivedTemplate} style={{ marginRight: '1em' }}>
                  <Icon type={currentStatus == 'ACTIV' ? 'eye-invisible' : 'eye'} /> {`${currentStatus == 'ACTIV' ? 'A' : 'Desa'}`}rchivar
                </Button>
              ) : null}
            <Button onClick={bulkDeleteTemplates}>
              <Icon type="delete" /> Eliminar
            </Button>
          </Col>
        </Row>
      </Layout.Footer>
    </Fragment>
  ) : null;
};

export default BulkActions;
