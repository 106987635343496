export const ApplicationModel = {
  id: null,
  activities: [],
  additional_documents: [],
  candidate_type: null,
  chat: null,
  custom_fields: {},
  custom_genome_score: null,
  customTests: [],
  date_completed: null,
  date_started: null,
  discard_reason_representation: null,
  discard_reason_comment: null,
  email_history: [],
  final_score: null,
  game_scores: [],
  incompleted_games: null,
  ko_answers: {},
  last_reference_date: null,
  note: null,
  personality_scores: [],
  reasoning_scores: {},
  reference_status: null,
  references: [],
  scores: null,
  state: null,
  status: null,
  subjective_evaluation: null,
  userjobapplicationreference: null,
  videointerview_evaluations: null,
};

export const CandidateModel = {
  id: null, // PersonalUser or PassiveCandidate ID
  candidateId: null, // Candidate ID
  age: null,
  avatar_initials: '',
  birth_date: null,
  careers: [],
  commune: null,
  country: null,
  customTests: [],
  koQuestions: null,
  cv: null,
  disability: null,
  disability_type: null,
  dni: null,
  education_level: null,
  email: null,
  experiences: [],
  full_phone: null,
  gender: null,
  // TODO: Deprecate has_disability
  has_disability: null,
  // marital_status: null,
  name: null,
  nationality: null,
  otherJobApps: [],
  surname: null,
  tags: [],
  user_name: null,
};

export const JobAppModel = {
  id: null,
  job_application: null,
  activities_count: null,
  duration: null,
  duplicate_video_interview: null,
  genome_id: null,
  count_total: null,
  count_profiled: null,
  creation_date: null,
  description: null,
  welcome_text: null,
  status: null,
  banner_picture: null,
  is_seed: null,
  optional_cv: null,
  closure_date: null,
  status_choices: [],
  location: null,
  location_city: null,
  location_country: null,
  department: null,
  business_logo: null,
  business_name: null,
  interview_id: null,
  youtube_intro_video: null,
  process_type: null,
  jobs_site_availability: null,
  jobs_site_global_availability: null,
  tags_used: [],
  integrations: [],
  stages: {
    stages: [],
  },
  optional_fields: {},
  // FIXME:
  triggers: [],
  old_triggers: [],
  job_portals: {},
  tags: [],
  measuredTraits: {},
};
