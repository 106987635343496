import React, { useContext, useState } from 'react';
import {
  Input,
  Radio,
  Row,
  Typography,
  Icon,
  Col,
  Tooltip,
  AutoComplete,
  Button,
  Layout,
  Alert
} from 'antd';

import { customTestContext } from '../../context/CustomTestContext';
import { skillsList } from '../../utils';

import MceEditor from '../../../../BusinessDashboard/TemplatesMails/components/MceEditor';

const { Text } = Typography;

const Step1 = () => {
  const [dataSkills, setDataSkills] = useState(skillsList);
  const [valueInput, setValueInput] = useState({ value: '' });
  const [showAlert, setShowAlert] = useState(false);

  const {
    configStep1, setConfigStep1, globalTimeContext,
    setGlobalTimeContext, radioTimeButton, setRadioTimeButton
  } = useContext(customTestContext);

  const handleChange = (e) => {
    setConfigStep1({
      ...configStep1,
      [e.target.name]: e.target.value
    })
  }
  const handleMcEditor = (name) => (e) => {
    setConfigStep1({
      ...configStep1,
      [name]: e.target.value
    })
  }
  const handleChangeRadio = (e) => {
    setRadioTimeButton(e.target.value)
    if (e.target.value === false) {
      setGlobalTimeContext(null)
    }
  }
  const onSearchSelect = (searchText) => {
    if (searchText !== '') {
      const filtroInterview = skillsList.filter(skill => {
        const resultante = skill.toUpperCase()
        return resultante.indexOf(searchText.toUpperCase()) > -1
      })
      setDataSkills(filtroInterview)
    }
  }
  const addTag = (value) => {
    if (configStep1.skills.includes(value)) {
      return setShowAlert(true)
    }
    setConfigStep1({
      ...configStep1,
      skills: [...configStep1.skills, value]
    })
    setValueInput({ value: '' })
  }
  const removeTag = (skill) => {
    const tags = configStep1.skills.filter(tag => tag !== skill)
    setConfigStep1({ ...configStep1, skills: tags })
  }
  const selectValue = (value) => {
    setValueInput({ ...valueInput, value: value })
  }

  const [_, cuteType, _action, _cuteId] = window.location.pathname.split('/');

  return (
    <Layout className='new-custom-test__frame__step-by-step__step1'>
      <Row type='flex' align='middle' className='new-custom-test__frame__step-by-step__step1__fields'>
        <span>*</span>
        <Text >Nombre de la prueba para colaboradores</Text>
        <Tooltip placement='topLeft' title='Así es como tú y tus compañeros reconocerán la prueba para añadirla a sus procesos. Esto no se mostrará a los candidatos.'>
          <Icon type='question-circle' />
        </Tooltip>
      </Row>
      <Input
        name='title'
        value={configStep1.title}
        onChange={handleChange}
        className='new-custom-test__frame__step-by-step__step1__input'
        placeholder='Nombre de la prueba'
      />
      <Row type='flex' align='middle' className='new-custom-test__frame__step-by-step__step1__fields'>
        <span>*</span>
        <Text>Descripción de la Prueba</Text>
        <Tooltip title='Añade un breve resumen opcional del contenido de la prueba para tí y tus compañeros. Esto no se mostrará a los candidatos.'>
          <Icon type='question-circle' />
        </Tooltip>
      </Row>

      <Row className='new-custom-test__frame__step-by-step__step1__input'>
        <MceEditor
          placeholder='Descripción de la prueba'
          name='description'
          text={configStep1.description}
          handleChange={handleMcEditor}
        />
      </Row>

      <Row className='new-custom-test__frame__step-by-step__step1__configuration'>
        <Col span={18}>
          <Row type='flex' align='middle' className='new-custom-test__frame__step-by-step__step1__fields'>
            <span>*</span>
            <Text>Límite de tiempo</Text>
          </Row>

          <Radio.Group value={radioTimeButton} onChange={handleChangeRadio} className='new-custom-test__frame__step-by-step__step1__configuration__radioGroup'>
            <Row type='flex' justify='space-between' align='middle'>
              <Radio value={false} className='new-custom-test__frame__step-by-step__step1__configuration__radio-group__radio'>
                Indefinido
              </Radio>
              <Radio value={true} className='new-custom-test__frame__step-by-step__step1__configuration__radio-group__radio'>
                <Input
                  disabled={!radioTimeButton ? true : false}
                  type='number'
                  value={globalTimeContext}
                  onChange={(e) => e.target.value > 0 && setGlobalTimeContext(e.target.value)}
                  placeholder='30'
                  className='new-custom-test__frame__step-by-step__step1__configuration__radio-group__radio__input'
                />
                minutos
              </Radio>
            </Row>
          </Radio.Group>
        </Col>
      </Row>

      {
        cuteType !== 'situationals' &&
        <Row className='new-custom-test__frame__step-by-step__step1__dimensions'>
          <Row type='flex' align='middle' className='new-custom-test__frame__step-by-step__step1__fields'>
            <span>*</span>
            <Text>Dimensiones a evaluar</Text>
            <Tooltip title='Habilidades que necesitas medir en tu prueba. Puedes seleccionar las que necesites o agregar alguna nueva a la lista. Ten en cuenta que en el siguiente paso podrás vincular estas habilidades seleccionadas a las preguntas que definas para tu prueba.'>
              <Icon type='question-circle' />
            </Tooltip>
          </Row>
        
          <AutoComplete
            dataSource={dataSkills}
            placeholder='Buscar habilidades'
            value={valueInput.value}
            onChange={selectValue}
            onSearch={onSearchSelect}
            onSelect={addTag}
            on
            style={{ width: '100%', margin: '5px 0 0 0' }}
          >
            <Input.Search
              onSearch={() => addTag(valueInput.value)}
            />
          </AutoComplete>
        </Row>
      }
      {
        showAlert && (
          <Row style={{ margin: '10px 0 10px 0' }}>
            <Alert
              type='error'
              message='Dimensión duplicada'
              description='No puedes agregar dos dimensiones iguales'
              closable
              onClose={() => setShowAlert(!showAlert)}
            />
          </Row>
        )
      }
      <Row type='flex' justify='start' gutter={10} className='new-custom-test__frame__step-by-step__step1__dimensions-list'>
        {
          configStep1.skills.map((skill, index) => (
            <Col key={index}>
              <Button onClick={() => removeTag(skill)} size='small'>
                {skill}
                <Icon type='close' />
              </Button>
            </Col>
          ))
        }
      </Row>
    </Layout>
  );
}

export default Step1;
