import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Pagination, Button, Typography } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../actions/data';
import * as authActionCreators from '../../actions/auth';

import HeaderBackground from './components/HeaderBackground';
import CommonHeader from './components/CommonHeader';
import Filters from './components/Filters';
import LocationCountry from './components/LocationCountry';
import JobList from './components/JobsList';
import JobInfo from './components/JobInfo';
import NotFound from './components/NotFound';
import JobsCategory from './components/JobsCategory';

import { countryData } from './utils';
import i18n from '../../i18n';

import './Jobs.scss'

const { Content } = Layout;
const { Title, Text } = Typography;

const latam = {
  'en': 'Latin America', 
  'es': 'Latinoamérica', 
  'pt': 'Latinoamérica'}

const countriesCodes = ['CL', 'AR', 'MX', 'BO', 'PE', 'CO', 'PY', 'UY', 'PA', 'EC']

//TODO: delete locationFullName
function JobsView({ actions, token }) {
  const [jobView, setJobView] = useState(false);
  const [jobData, setJobData] = useState({});
  const [jobID, setJobID] = useState('');
  const [jobApplications, setJobApplications] = useState([]);
  const [jobApplicationsCount, setJobApplicationsCount] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [searchLocation, setSearchLocation] = useState(null);
  const [locationCountry, setLocationCountry] = useState(null);
  const [locationFullName, setLocationFullName] = useState(null);
  const [showPagination, setShowPagination] = useState(false)
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false)
  const [countries, setCountries] = useState({})
  const [country, setCountry] = useState(latam)

  const fetchJobApplications = (search, location, reload) => {
    let url = `/api/v1/jobportal/jobapplications/?page=${reload ? reload : page}&page_size=12`
    if (search && location) {
      if (location === 'LATAM') {
        url += `&q=${search}`;
      } else {
        url += `&q=${search}&location_country=${location}`;
      }
    } else if (search) {
      url += `&q=${search}`;
    } else if (location) {
      if (location === 'LATAM') {
        url = `/api/v1/jobportal/jobapplications/?page=${reload ? reload : page}&page_size=12`
      } else {
        url += `&location_country=${location}`;
      }
    }
    if (location) {
      const fullName = countryData.filter(countryObj => countryObj.code === location);
      setLocationFullName(fullName[0].name)
    }
    setSearchLocation(location);
    setSearchText(search)
    setLocationCountry(location);
    setCountry(countries[location]? countries[location].translation : latam)
    setLoading(true);
    setJobApplications([])
    
    actions.fetchAndReturn(token, url).then(response => {
      if (response.status === 200) {
        const data = response.body;
        setJobApplications(data.results);
        setJobApplicationsCount(Math.ceil(data.count / 12) * 10);
        setLoading(false);
        setError(false)
        getDataSpecificJob(data.results[0].id)
      } else {
        setError(true)
        setLoading(false)
      }
    })
  }
  const getDataSpecificJob = (id) => {
    let url = `/api/v1/gamesandtests/personaljobapplication/${id}/`;
    actions.fetchAndReturn(token, url).then((response) => {
      if (response.status === 200) {
        setJobID(id);
        setJobData(response.body);
      }
    });
  }
  const handleChange = (page) => {
    setPage(page)
    fetchJobApplications(searchText, searchLocation, page)
  }

  const fetchCountries = () => {
    const url = `/api/v1/constants/countries/?p=[translation,iso_2]`;
    actions.fetchAndReturn('', url).then((response) => {
      if (response.status === 200) {
        const countriesDict = {}
        countriesDict['LATAM'] = {'iso_2': 'LATAM', 'translation': latam}
        response.body.map((country) => {
          
          if (countriesCodes.includes(country.iso_2)){
            countriesDict[country.iso_2] = country

          }
          
        }
        )
        setCountries(countriesDict)
      }
    });
  }

  const diffDay = (jobDate) => {
    let date_1 = new Date(jobDate);
    let date_2 = Date.now();

    let day_as_milliseconds = 86400000;
    let diff_in_millisenconds = date_2 - date_1;
    let diff_in_days = Math.ceil(diff_in_millisenconds / day_as_milliseconds);
    return diff_in_days
  }
  const scrollToTop = (idName) => {
    const el = document.getElementById(idName)
    window.scrollTo({
      top: el,
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    fetchCountries();
    fetchJobApplications(searchText, searchLocation, null);
  }, [])

  return (
    <Layout className='layout-job-portal'>
      {
        jobView ?
          <CommonHeader
            searchLocation={searchLocation}
            locationFullName={country[i18n.language.split('-')[0]]}
            setJobView={setJobView}
            setShowPagination={setShowPagination}
            setPage={setPage}
            country={country}
            countries={countries}
          /> :
          <HeaderBackground />
      }
      <Content className='content-job-portal' id='content-job-portal-id'>

        <Filters
          searchText={searchText}
          searchLocation={searchLocation}
          fetchJobApplications={fetchJobApplications}
          setPage={setPage}
          countries={countries}
        />
        {
          !jobView ?
            <LocationCountry
              searchLocation={searchLocation}
              searchText={searchText}
              locationCountry={locationCountry}
              setSearchLocation={setSearchLocation}
              setLocationFullName={setLocationFullName}
              fetchJobApplications={fetchJobApplications}
              setPage={setPage}
              setLocationCountry={setLocationCountry}
              country={country}
              countries={countries}
            /> :
            null}
        <Row type='flex' justify='start' id='jobs-section' style={{ margin: '0 0 20px 0' }}>
          {
            !jobView ?
              (
                <Col span={24}>
                  <Title level={3} style={{ color: '#454545', fontSize: '18px' }}>
                    {i18n.t('job_portal__last__jobs')}
                  </Title>
                </Col>
              ) : error ? null : jobApplicationsCount === 0 ? null :
                (
                  <Row type='flex' gutter={[0, 10]}>
                    <Col span={24} style={{ margin: '5px 0' }}>
                      <Text>
                        {i18n.t('job_portal__jobs__types').split('{')[0]} <strong>{searchText ? searchText : i18n.t('job_portal__all_type')}</strong> {i18n.t('job_portal__jobs__types').split('}')[1].split('{')} <strong>{country[i18n.language.split('-')[0]]}</strong>
                      </Text>
                    </Col>
                    <Col span={24} style={{ margin: '5px 0' }}>
                      <Text>{`${i18n.t('job_portal__pages').split('{')[0]} ${page} ${i18n.t('job_portal__pages').split('}')[1].split(' {')[0]} ${Math.ceil(jobApplicationsCount / 10)}`}</Text>
                    </Col>
                  </Row>
                )
          }
        </Row>
        <Row gutter={20}>
          <Col span={error ? 24 : jobApplicationsCount === 0 ? 24 : jobView ? 8 : 24}>
            {
              error ?
                <NotFound jobView={jobView} searchText={searchText} locationFullName={country[i18n.language.split('-')[0]]} />
                : jobApplicationsCount === 0 ?
                  <NotFound jobView={jobView} searchText={searchText} locationFullName={country[i18n.language.split('-')[0]]} />
                  :
                  <>
                    <JobList
                      jobApplications={jobApplications}
                      jobView={jobView}
                      loading={loading}
                      jobData={jobData}
                      jobID={jobID}
                      page={page}
                      setPage={setPage}
                      setJobView={setJobView}
                      getDataSpecificJob={getDataSpecificJob}
                      diffDay={diffDay}
                      setShowPagination={setShowPagination}
                    />
                    {
                      !showPagination ?
                        <Row type='flex' justify='start' gutter={20} className='more-jobs-buttons'>
                          <Col xs={24} sm={jobView ? 24 : 8} md={jobView ? 24 : 12} lg={jobView ? 24 : 8} >
                            {window.innerWidth < 992 ? (
                              <Button className='button-jobs' block onClick={() => setShowPagination(true)}>
                                {i18n.t('job_portal__see__all')}
                              </Button>
                            ) : (
                              <Button className='button-jobs' block onClick={() => {
                                setShowPagination(true)
                                setJobView(true)
                                scrollToTop('content-job-portal-id')
                              }}>
                                {i18n.t('job_portal__see__all')}
                              </Button>
                            )}
                          </Col>
                        </Row> :
                        (
                          <Row type='flex' justify='center' style={{ margin: '20px 0 0 0' }}>
                            <Pagination
                              simple
                              current={page}
                              onChange={handleChange}
                              total={jobApplicationsCount}
                              style={{ margin: '20px 0 30px 0' }}
                            />
                          </Row>
                        )
                    }
                  </>
            }
          </Col>
          {
            error ? null : jobApplicationsCount === 0 ? null :
              (
                <Col span={error ? 0 : jobApplicationsCount === 0 ? 0 : jobView ? 16 : 0}>
                  <JobInfo
                    jobData={jobData}
                    jobID={jobID}
                    diffDay={diffDay}
                  />
                </Col>
              )
          }
        </Row>

        {
          !jobView ? (
            <>
              <Row type='flex' justify='start'>
                <Title level={3}>
                  {i18n.t('job_portal__jobs__category')}
                </Title>
              </Row>
              <JobsCategory
                jobView={jobView}
                searchLocation={searchLocation}
                setJobView={setJobView}
                fetchJobApplications={fetchJobApplications}
                scrollToTop={scrollToTop}
              />
            </>
          ) : null
        }

      </Content>
    </Layout>
  )
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    authActions: bindActionCreators(authActionCreators, dispatch),
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobsView);
export { JobsView as JobsViewNotConnected };
