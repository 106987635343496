import React, { useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';

import { CandidateContext } from '../../config/CandidateContext';

import ResetInterviewModal from './ResetInterviewModal';
import useBoolean from '../../../../hooks/useBoolean';
import GlobalEvaluation from './GlobalEvaluation';
import IndividualEvaluation from './IndividualEvaluation';

import { isMobile } from 'react-device-detect';

import { recommendedVEvaluation, rateValues } from '../../helpers';

import {
  Col,
  Button,
  Icon,
  message,
  Row,
  Radio,
  Switch,
  Tooltip,
  Typography,
  Divider,
} from 'antd';

import Collapse from '../Collapse';
import i18n from '../../../../i18n';
import './styles.scss';

const { Text } = Typography;

const VideoInterview = ({
  interview,
  isEditing,
  setVideoToReset,
  videosResetted,
  interviewAnswer,
  setBusinessRate,
}) => {
  const { onChangeVideoInterviewEval, translationFile } = useContext(CandidateContext);
  // const [openCollaboratosView, setOpenCollaboratosView] = useState(false);
  const isInTheArray = videosResetted.filter(
    (video) => video.question_id === interview.question.id
  );
  const [rates, setRates] = useState(0);
  const [ratesValues, setRatesValues] = useState(rateValues);

  useEffect(() => {
    const id = interview.answer.length ? interview.answer[0].id : null;
    setRates(
      (id && interviewAnswer && interviewAnswer.evaluations && interviewAnswer.evaluations[id])
        ? interviewAnswer.evaluations[id]['rating']
        : 0
    );
  }, [interview, interviewAnswer]);

  return (
    <Row className="">
      <Col /* span={12} */ style={{ marginBottom: '0.5rem' }}>
        <Text strong>{interview.question.text}</Text>
        <Tooltip
          title={
            isEditing
              ? i18n.t('profile__reset_ve', translationFile)
              : i18n.t('profile__reset_ve_mode', translationFile)
          }
        >
          {!!interview.answer.length && (
            <Icon
              type="reload"
              style={{
                marginLeft: '10px',
                color: isEditing ? '#5E5E5E' : '#CCCCCC',
                cursor: isEditing ? 'pointer' : 'not-allowed',
              }}
              onClick={() =>
                isEditing
                  ? setVideoToReset(
                    interview.question.id,
                    interview.answer[0].id
                  )
                  : null
              }
            />
          )}
        </Tooltip>
      </Col>
      {interview.answer.length ? (
        interview.answer.map((ans, idx) => (
          <Row key={idx}>
            <Row gutter={10} type="flex" justify="space-between" align="top">
              <Col span={24}>
                <video width="100%" height="280" src={ans.video.url} controls />

                <Radio.Group
                  id="rating"
                  onChange={(newRating) => {
                    let rates_ = rates;
                    rates_ = newRating.target.value;
                    onChangeVideoInterviewEval(
                      ans.id,
                      newRating.target.value,
                      interview.question.text
                    ).then((res) => {
                      setRates(rates_);

                      const a = ratesValues.map((item) => {
                        if (item['value'] != 0) {
                          item['key'] =
                            item['value'] <= rates_
                              ? `${item['value']}-true`
                              : `${item['value']}-false`;
                        }
                        return item;
                      });
                      setRatesValues(a);
                      setBusinessRate(true);
                    });
                  }}
                  style={{
                    marginTop: '1em',
                    minWidth: '100%',
                  }}
                  value={rates}
                  defaultValue={rates}
                >
                  <Radio.Button
                    style={{ width: '20%', borderRadius: 2 }}
                    value={0}
                    disabled={true}
                  >
                    <Text style={{ color: '#D9D9D9' }}>{i18n.t('profile__without_evaluation', translationFile)}</Text>
                  </Radio.Button>
                  {ratesValues.slice(1).map((item, idx) => (
                    <Radio.Button
                      key={idx}
                      style={{
                        textAlign: 'center',
                        borderRadius: 2,
                        width: '16%',
                        border:
                          rates >= item['value']
                            ? `1px solid ${ratesValues[rates]['color']}`
                            : `1px solid ${ratesValues[0]['color']}`,
                        backgroundColor:
                          rates >= item['value']
                            ? ratesValues[rates]['background']
                            : ratesValues[0]['background'],
                      }}
                      value={item['value']}
                      disabled={!isEditing}
                    >
                      <Tooltip
                        placement="bottom"
                        title={item['text']}
                        trigger="hover"
                      >
                        <Text
                          style={{
                            color:
                              rates >= item['value']
                                ? ratesValues[rates]['color']
                                : ratesValues[0]['color'],
                          }}
                        >
                          {item['value']}
                        </Text>
                      </Tooltip>
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Col>
            </Row>
          </Row>
        ))
      ) : !videosResetted.length ? (
        <Text>{i18n.t('profile__without_answer', translationFile)}. </Text>
      ) : (
        isInTheArray.length > 0 && (
          <Col>
            <Icon
              type="clock-circle"
              style={{
                marginRight: '10px',
                color: '#CCCCCC',
              }}
            />
            <Text disabled>
              {i18n.t('profile__ve_restarted', translationFile)}
            </Text>
          </Col>
        )
      )}
    </Row>
  );
};

const VideoInterviews = (props) => {
  const {
    application: { status },
    candidate: { name, id, email },
    jobApp,
    actions,
    token,
    videoActions,
    onChangeRecommendation,
    permissions,
    sharing,
    translationFile
  } = useContext(CandidateContext);

  const [sending, handleFlag] = useBoolean(false);
  const [isLoad, setIsLoad] = useState(false);
  const [openVideoReset, setOpenVideoReset] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [bodyToDelete, setBodyToDelete] = useState({});
  const [sendEmailNotification, setSendEmailNotification] = useState(false);
  const [videosResetted, setVideosResetted] = useState([]);
  const [videoInterview, setVideoInterview] = useState([]);
  const [videoInterviewEvaluation, setVideoInterviewEvaluation] = useState({});
  const [emailToSend, setEmailToSend] = useState('');
  const [resetVideoOption, setResetVideoOption] = useState({
    value: 'SAVE',
  });
  const [mode, setMode] = useState('EDIT');
  const [businessUserEvaluation, setBusinessUserEvaluation] = useState({});
  const [recommendationValue, setRecommendationValue] = useState('');
  const [recommendationsOptions, setRecommendationsOptions] = useState(
    recommendedVEvaluation
  );
  const [businessRate, setBusinessRate] = useState(true);

  const reSend = async () => {
    try {
      const url = `gamesandtests/jobapplications/${jobApp.id}/candidates/${id}/`;
      const data = JSON.stringify({ status: 'V_INT' });

      handleFlag();
      await actions.sendData(token, url, data, 'PATCH');
      message.success(`${i18n.t('profile__ve_forwarded', translationFile)}.`);
      handleFlag();
    } catch (e) {
      Sentry.captureException(e);
      message.error(e);
    }
  };

  const deleteVideoAction = async () => {
    const hasPerm = (permissions.business_admin || permissions.role?.can_manage_userjobapplications);
    if (!hasPerm) {
      message.error(
        i18n.t('commons__permissions_error', translationFile)
      );
    }
    try {
      let url = 'videointerview/answerresets/';

      const body = JSON.stringify({
        ...bodyToDelete,
        reason: resetVideoOption.value,
        notify: sendEmailNotification,
        email: emailToSend,
      });

      await actions.sendData(token, url, body);
      setOpenVideoReset(false);
    } catch (e) {
      message.error(
        i18n.t('profile__ve_deleted_error', translationFile)
      );
    }
  };

  const setVideoToReset = (question_id, answer_id) => {
    setOpenVideoReset(true);
    setBodyToDelete({
      ...bodyToDelete,
      personal_user: id,
      job_application: jobApp.id,
      interview_id: jobApp.interview_id,
      question_id,
      answer_id,
    });
  };

  const onChangeEdition = (checked) => {
    setIsEditing(checked);
  };

  const onChange = (e) => {
    setResetVideoOption({
      value: e.target.value,
    });
  };

  const onChangeEmail = ({ target }) => {
    const { value } = target;
    setEmailToSend(value);
  };

  const checkMailNotification = ({ target }) => {
    setSendEmailNotification(target.checked);
  };

  const getResettedVideos = async () => {
    if (jobApp.interview_id) {
      try {
        const url = `/api/v1/videointerview/answerresets/?personal_user_id=${id}&interview_id=${jobApp.interview_id}`;
        const { body } = await actions.fetchAndReturn(token, url);
        setVideosResetted(body?.results ? body.results : []);
      } catch (e) {
        message.error(
          i18n.t('profile__ve_get_error', translationFile)
        );
      }
    };
  };

  const getVideos = async (getVideo = false) => {
    try {
      if (getVideo) {
        const resp = await videoActions.fetchVideo(
          token,
          jobApp.interview_id,
          email
        );
        setVideoInterview(resp);
      }
      if (jobApp.interview_id) {
        const url = `/api/v1/videointerview/interviewevaluations/?user_pk=${id}&interview_id=${jobApp.interview_id}`;
        const { body } = await actions.fetchAndReturn(token, url);
        if (!body?.id) return;
        setVideoInterviewEvaluation(body);

        const businessEvaluation = body
          ? body.business_evaluations.find((element) => {
            return element.business_user.id == props.userID;
          })
          : null;
        setBusinessUserEvaluation(businessEvaluation);
        businessEvaluation
          ? setRecommendationValue(businessEvaluation.recommended)
          : setBusinessRate(false);
      }
      setIsLoad(true);
    } catch (e) {
      message.error(
        i18n.t('profile__ve_get_error', translationFile)
      );
    }
  };

  const getInterviewEvaluations = () => {
    const allEvaluations = videoInterviewEvaluation;
    const videosResettedAId = videosResetted.map((video) => {
      return video.answer_id;
    });
    videosResettedAId.map((answerId) => {
      delete allEvaluations.evaluations[answerId];
      allEvaluations.business_evaluations.map((BU) => {
        BU['evaluations'][answerId] ? delete BU.evaluations[answerId] : null;
      });
    });
    let sum = 0;
    Object.keys(allEvaluations.evaluations).map((evaluation) => {
      if (evaluation !== 'average') {
        sum += allEvaluations.evaluations[evaluation]['rating'];
      }
    });

    // SET AVERAGE
    allEvaluations.evaluations['average'] =
      sum / (Object.keys(allEvaluations.evaluations).length - 1);

    return allEvaluations;
  };

  useEffect(() => {
    if (!openVideoReset) {
      getResettedVideos();
      getVideos(true);
    }
  }, [openVideoReset]);

  useEffect(() => {
    getResettedVideos();
    getVideos(true);
  }, []);

  return videoInterview?.length || !!videoInterview ? (
    <Collapse icon="video-camera" title={i18n.t('candidate_path__interview')}>
      <div className="new-modal-video-interviews">
        <ResetInterviewModal
          checkMailNotification={checkMailNotification}
          name={name}
          openVideoReset={openVideoReset}
          setOpenVideoReset={setOpenVideoReset}
          sendEmailNotification={sendEmailNotification}
          onChange={onChange}
          resetVideoOption={resetVideoOption}
          deleteVideoAction={deleteVideoAction}
          onChangeEmail={onChangeEmail}
        />

        <div className="video-interviews-navigation">
          <div className="video-interviews-navigation__left">
            {mode == 'EDIT' ? (
              <>
                <Text> {i18n.t('profile__modify_evaluation', translationFile)}:</Text>
                <Switch
                  size="small"
                  style={{ marginLeft: '5px' }}
                  onChange={onChangeEdition}
                  disabled={sharing}
                />
              </>
            ) : (
              <Button
                icon="arrow-left"
                onClick={() => setMode('EDIT')}
                type="link"
                style={{ color: 'rgba(0, 0, 0, 0.75)', fontWeight: 700 }}
              >
                {mode == 'GLOB' ? i18n.t('profile__overall_score', translationFile) : i18n.t('profile__individual_score', translationFile)}
              </Button>
            )}
          </div>
          <div>
            <Radio.Group
              style={{ borderStyle: 'none', borderBoxColor: '#CCCCCC' }}
              onChange={async (mode) => {
                await getVideos();
                await getResettedVideos();
                setMode(mode.target.value);
              }}
              value={mode}
            >
              <Radio.Button
                key={`GLOB-${businessRate}`}
                value="GLOB"
                disabled={!businessRate}
                style={{ borderRadius: 2 }}
              >
                {' '}
                <Text>{i18n.t('profile__overall_score', translationFile)}</Text>{' '}
              </Radio.Button>
              <Radio.Button
                key={`INDIV-${businessRate}`}
                value="INDIV"
                disabled={!businessRate}
                style={{ borderRadius: 2 }}
              >
                {' '}
                <Text>{i18n.t('profile__individual_score', translationFile)}</Text>{' '}
              </Radio.Button>
            </Radio.Group>
          </div>
        </div>
        {mode == 'GLOB' && (
          <GlobalEvaluation globalevaluation={getInterviewEvaluations()} />
        )}
        {mode == 'INDIV' && (
          <IndividualEvaluation globalevaluation={getInterviewEvaluations()} />
        )}
        {mode == 'EDIT' && (
          <div className="video-interviews-recommendation-container">
            <Text strong>{i18n.t('profile__recommendation_question', translationFile)}</Text>
            <div>
              <Radio.Group
                style={{ borderStyle: 'none', borderBoxColor: '#CCCCCC' }}
                defaultValue={recommendationValue}
                onChange={(newRating) => {
                  setBusinessRate(true);
                  setRecommendationValue(newRating.target.value);
                  const a = recommendedVEvaluation.map((item) => {
                    item['key'] =
                      item['value'] == newRating.target.value
                        ? `${item['value']}-true`
                        : `${item['value']}-false`;
                    return item;
                  });
                  setRecommendationsOptions(a);
                  onChangeRecommendation(newRating.target.value);
                }}
              >
                {recommendationsOptions.map((recommendation, i, arr) => (
                  <>
                    <Radio.Button
                      disabled={!isEditing}
                      key={recommendation['key']}
                      style={{
                        width: isMobile ? 15 : 150,
                      }}
                      className="recommendations-options"
                      value={recommendation['value']}
                    >
                      <Row type="flex" justify="space-around" align="middle">
                        <Icon
                          type={recommendation['icon']}
                          theme={
                            recommendationValue == recommendation['value']
                              ? 'filled'
                              : 'outlined'
                          }
                          style={{
                            fontSize: 18,
                            color:
                              recommendationValue == recommendation['value']
                                ? recommendation['color']
                                : '#CCCCCC',
                          }}
                        />
                      </Row>
                      {isMobile ? null : (
                        <Row type="flex" justify="space-around" align="middle">
                          <Text
                            style={{
                              color:
                                recommendationValue == recommendation['value']
                                  ? '#454545'
                                  : '#CCCCCC',
                            }}
                          >
                            {recommendation['text']}
                          </Text>
                        </Row>
                      )}
                    </Radio.Button>
                    {i < arr.length - 1 ? (
                      <Divider type="vertical" style={{ height: 25 }} />
                    ) : null}
                  </>
                ))}
              </Radio.Group>
            </div>
          </div>
        )}
        {mode == 'EDIT' &&
          videoInterview.map((interview, idx) => (
            <VideoInterview
              key={idx}
              interview={interview}
              isEditing={isEditing}
              setVideoToReset={setVideoToReset}
              videosResetted={videosResetted}
              interviewAnswer={businessUserEvaluation}
              setBusinessRate={setBusinessRate}
            />
          ))}
        {status !== 'ACTIV' &&
          !['INDIV', 'GLOB'].includes(mode) &&
          status !== 'DONE' &&
          videoInterview.some((i) => !!!i.answer.length) && (
            <Button
              type="secondary"
              loading={sending}
              icon="sync"
              onClick={reSend}
            >
              {i18n.t('profile__resend_ve_mail', translationFile)}
            </Button>
          )}
        {status !== 'ACTIV' &&
          status !== 'DONE' &&
          !['INDIV', 'GLOB'].includes(mode) &&
          !!videosResetted.length && (
            <Col className="candidate-modal-videos__resetted-history">
              <Col
                span={24}
                className="candidate-modal-videos__resetted-history--title"
              >
                <Text strong>Historial de reinicios</Text>
              </Col>
              {videoInterview.map((interview) =>
                videosResetted.map((video, idx) => {
                  if (video.question_id === interview.question.id) {
                    return (
                      <Col key={idx}>
                        <Col span={24}>
                          <Text>{interview.question.text}</Text>
                          <Text
                            disabled
                            className="candidate-modal-videos__resetted-history--date"
                          >
                            {video.date_created}
                          </Text>
                        </Col>
                        <Col
                          span={24}
                          className="candidate-modal-videos__resetted-history--business-user"
                        >
                          <Text>
                            {i18n.t('profile__restarted_by', translationFile)} {video.business_user?.business_user}
                          </Text>
                        </Col>
                      </Col>
                    );
                  }
                })
              )}
            </Col>
          )}
      </div>
    </Collapse>
  ) : null;
};
const mapStateToProps = (state) => {
  return {
    userID: state.auth.userID,
  };
};

export default connect(mapStateToProps, null)(VideoInterviews);
