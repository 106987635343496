import React from 'react';

import { Button, Card, Col, Icon, Row, Typography, Tooltip } from 'antd';
import PropTypes from 'prop-types';

import i18n from '../../../../i18n';
import EmptySection from './Empty';

const { Text, Title } = Typography;

export default function CurriculumSection({ data, setEditCurriculumVisible, ...props }) {

  return (
    <Card bodyStyle={{ paddingTop: 0 }} className='profile-section-card'>
      <Row
        align="middle"
        justify="space-between"
        style={{ paddingBottom: '1em', paddingTop: '1em' }}
        type="flex"
      >
        <Col>
          <Title level={4} style={{ marginBottom: 0 }}>{i18n.t('form__cv')}</Title>
        </Col>
        <Col>
          <Button
            onClick={setEditCurriculumVisible.on}
            className="profile-section-card__update"
          >
            <Icon type="upload" />
            {i18n.t('form__cv__upload')}
          </Button>
        </Col>
      </Row>

      {(data.has_cv)
        ? (
          <Row
            align="middle"
            className="profile-section-card__cv"
            justify="space-between"
            type="flex"
          >
            <Col span={22}>
              <Row align="middle" type="flex">
                <Col style={{ marginRight: '0.75em' }}>
                  <Icon style={{ fontSize: '2.5em' }} type="file-pdf" />
                </Col>
                <Col className="cv-file-name">
                  <Text strong>
                    {`${data.cv.split('/').pop()}`}
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col>
              <Tooltip title={i18n.t('commons__download')}>
                <Icon
                  className="profile-section-card__cv-actions"
                  onClick={() => window.open(data.cv, '_blank')}
                  type="download"
                />
              </Tooltip>
            </Col>
          </Row>
        )
        : (<EmptySection title={i18n.t('profile__no_cv_yet')} />)}
    </Card>
  );
}

CurriculumSection.propTypes = {
  data: PropTypes.shape({
    cv: PropTypes.string,
    has_cv: PropTypes.bool,
  }).isRequired,
  setEditCurriculumVisible: PropTypes.shape({
    off: PropTypes.func,
    on: PropTypes.func,
  }).isRequired,
};
