import React, { useState, useEffect } from 'react';
import { Row, Col, Icon, Form, Button, Typography, Modal } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../../actions/data';
import EditExperienceModal from './Modal/EditExperienceForm';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';

const { Title, Text } = Typography;

function JobExperience({
  personalData,
  token,
  actions,
  setUpdateData,
}) {

  const [openAddExperienceModal, setOpenAddExperienceModal] = useState(false);
  const [openEditExperienceModal, setOpenEditExperienceModal] = useState(false);
  const [modalDeleteExperienceVisible, setModalDeleteExperienceVisible] = useState(false);

  const [experienceToEdit, setExperienceToEdit] = useState({});
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [experienceToDelete, setExperienceToDelete] = useState(null);

  const handleExperienceAdition = (experienceData, token) => {
    const experienceInfoUrl = 'accounts/experienceinformation/';
    const experiences = {
      experiences: [...personalData.experiences, experienceData],
    };
    const experiencesData = JSON.stringify(experiences);
    actions
      .sendData(token, experienceInfoUrl, experiencesData, 'POST')
      .then(() => {
        setOpenEditExperienceModal(false);
        setUpdateData(true);
      })
      .catch((error) => {
        console.error('Ocurrió un error', error);
      });
  };

  const handleExperienceEdit = (experienceData, id) => {
    experienceData['month_end'] = null
    experienceData['year_end'] = null
    const experienceInfoUrl = `/api/v1/accounts/experienceinformations/${id}/`;
    experienceData['month_end'] = null;
    experienceData['year_end'] = null;
    actions
      .fetchAndReturn(token, experienceInfoUrl, 'PATCH', experienceData)
      .then(() => {
        setOpenEditExperienceModal(false);
        setUpdateData(true);
      })
      .catch((error) => {
        console.error('Ocurrió un error', error);
      });
  };

  const editExperience = (data) => {
    setOpenEditExperienceModal(true);
    setExperienceToEdit(data);
  };

  const handleExperienceDelete = (experienceToDelete) => {
    setConfirmLoading(true);
    const experienceInfoUrl = `/api/v1/accounts/experienceinformations/${experienceToDelete}/`;
    actions
      .fetchAndReturn(token, experienceInfoUrl, 'delete').then(() => {
        setModalDeleteExperienceVisible(false);
        setConfirmLoading(false);
        setUpdateData(true);
      })
      .catch((error) => {
        console.error('Ocurrió un error', error);
      });
  };

  const deleteConfirm = ({ id }) => {
    setExperienceToDelete(id);
    setModalDeleteExperienceVisible(true);
  };

  return (
    <>
      <EditExperienceModal
        openModal={openAddExperienceModal}
        setOpenModal={setOpenAddExperienceModal}
        onAddExperience={handleExperienceAdition}
        token={token}
      />
      <EditExperienceModal
        openModal={openEditExperienceModal}
        setOpenModal={setOpenEditExperienceModal}
        token={token}
        experienceToEdit={experienceToEdit}
        onEditExperience={handleExperienceEdit}
      />
      <Modal
        visible={modalDeleteExperienceVisible}
        centered={true}
        onOk={() => handleExperienceDelete(experienceToDelete)}
        confirmLoading={confirmLoading}
        onCancel={() => setModalDeleteExperienceVisible(false)}
      >
        <p>{i18n.t('form__delete__experience')}</p>
      </Modal>

      <Form layout='horizontal' className='candidate-form-section'>
        <Row className='candidate-form-section-inner'>
          <Col style={{ marginBottom: '15px' }}>
            <Title level={4}>{i18n.t('form__experience')}</Title>
          </Col>
          {personalData.experiences?.map((experiences, index) => (
            <Row type='flex' style={{ marginBottom: '20px' }} key={index}>
              <Col  xs={17} sm={17} md={21}>
                <Row>
                  <Col>
                    <Text>{experiences.position}</Text>
                  </Col>
                  <Col>
                    <Text disabled>{experiences.company}</Text>
                  </Col>
                  <Col>
                    <Text disabled>
                      {experiences.year_start} -{' '}
                      {!experiences.month_end && !experiences.year_end
                        ? i18n.t('form__present')
                        : experiences.year_end}
                    </Text>
                  </Col>
                </Row>
              </Col>
              <Col xs={7} sm={7} md={3}>
                <Row type='flex' gutter={10} justify="end">
                  <Col>
                    <Button
                      icon='edit'
                      type='primary'
                      onClick={() =>
                        editExperience(personalData.experiences[index])
                      }
                    />
                  </Col>
                  <Col>
                    <Button
                      icon='delete'
                      onClick={() =>
                        deleteConfirm(personalData.experiences[index])
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          ))}
          <Row onClick={() => setOpenAddExperienceModal(true)}>
            <Icon type='plus-circle' className='add-button' />
            <Text className='add-button'> {i18n.t('profile__add__experience')}</Text>
          </Row>
        </Row>
      </Form>
    </>
  );
}

JobExperience.propTypes = {
  personalData: PropTypes.shape({
    experiences: PropTypes.arrayOf(PropTypes.shape({
      position: PropTypes.string,
      company: PropTypes.string,
      year_start: PropTypes.string,
      year_end: PropTypes.string,
      month_start: PropTypes.string,
      month_end: PropTypes.string,
    }),
  ),
  }) 
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  };
};

const WrappedJobExperience = Form.create()(JobExperience);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedJobExperience);
