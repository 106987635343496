import React, { Component, Fragment } from 'react';
import { Modal, Button } from 'antd';

class MobileActions extends Component {
  state = {
    visible: false
  }

  handleModal = () => {
    this.setState({ visible: !this.state.visible })
  }

  delete = () => {
    this.handleModal();
    this.props.delete();
  }

  main = () => {
    this.handleModal();
    this.props.main();
  }

  render() {
    const { type } = this.props;
    return (
      <Fragment>
        <Button className='mobile-actions-btn' icon='more' onClick={this.handleModal} />
        <Modal
          visible={this.state.visible}
          footer={false}
          closable={false}
          centered
          className='mobile-action-modal'
        >
          
          <Button block icon={this.props.type} onClick={this.main}>{type === 'edit' ? 'Editar pregunta' : 'Agregar plantilla'}</Button>
          {(!this.props.permission || this.props.permission.can_manage)? (
            <Button block icon='minus' type='danger' onClick={this.delete}>
              Eliminar {type=== 'edit' ? 'pregunta' : 'plantilla'}
            </Button>
          ): null}
          <Button block onClick={this.handleModal}>Cancelar</Button>
        </Modal>
      </Fragment>
    )
  }
} 

export default MobileActions;