import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';

import i18n from '../../../i18n';
import * as actionCreators from '../../../actions/data';

import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Icon,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Table,
  Typography,
} from 'antd';
import PropTypes from 'prop-types';
import './CustomFields.scss';

import useBreadcrumb from '../../../hooks/useBreadcrumb';

const { Item } = Breadcrumb;
const { Paragraph, Title } = Typography;

const assetsUrl = 'https://genoma-assets.s3.us-east-2.amazonaws.com/';

const fieldTypeLabels = {
  JOBAPP: 'Proceso',
  USERJA: 'Postulación',
  CANDID: 'Candidato',
};

const fieldTypePluralLabels = {
  JOBAPP: 'Procesos',
  USERJA: 'Postulaciones',
  CANDID: 'Candidatos',
};

const valueTypeLabels = {
  text: 'Texto',
  number: 'Numérico',
  boolean: 'Si/No',
  date: 'Fecha',
  singleselection: 'Selección única',
  multiselection: 'Selección múltiple',
  file: 'Archivo',
};

const CustomFields = (props) => {
  const [customFieldsData, setCustomFieldsData] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const Breadcrumb = useBreadcrumb(
    [
      {
        path: '/',
        text: 'Home',
      },
      {
        path: 'tools',
        text: 'Gestión',
      },
      {
        path: 'customfields',
        text: 'Atributos personalizados',
      },
    ],
    '>'
  );

  const columns = [
    {
      title: 'NOMBRE DEL ATRIBUTO',
      key: 'name',
      dataIndex: 'name',
      sorter: (a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        else if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        else return 0;
      },
    },
    {
      title: 'TIPO DE ATRIBUTO',
      key: 'valueType',
      dataIndex: 'value_type',
      filters: Object.entries(valueTypeLabels).map((entry) => ({
        text: entry[1],
        value: entry[0],
      })),
      onFilter: (value, record) => value === record.value_type,
      render: (text) => valueTypeLabels[text],
    },
    {
      title: 'HABILITADO EN',
      key: 'fieldType',
      dataIndex: 'field_type',
      filters: Object.entries(fieldTypeLabels).map((entry) => ({
        text: entry[1],
        value: entry[0],
      })),
      onFilter: (value, record) => value === record.field_type,
      render: (text) => fieldTypeLabels[text],
    },
    {
      title: 'ACCIONES',
      key: 'actions',
      dataIndex: 'id',
      render: (id, record) => (
        <Row type="flex" align="top">
          <Col style={{ marginRight: '10px' }}>
            <Icon
              onClick={() =>
                props.dispatch(
                  push({
                    pathname: `/customfields/edit/${id}`,
                    state: record,
                  })
                )
              }
              type="edit"
            />
          </Col>
          <Col style={{ marginRight: '10px' }}>
            <Icon onClick={() => duplicateCustomField(record)} type="copy" />
          </Col>
          <Col>
            <Icon
              onClick={() =>
                Modal.confirm({
                  cancelText: 'Cancelar',
                  content: `${fieldTypePluralLabels[record.field_type]
                    } con este atributo ya asociado continuarán teniendolo, al menos hasta que se les elimine manualmente.`,
                  okText: 'Eliminar',
                  onOk: () => deleteCustomField(id),
                  title: `¿Estás seguro de querer eliminar el atributo "${record.name}"?`,
                })
              }
              type="delete"
            />
          </Col>
        </Row>
      ),
    },
  ];

  const deleteCustomField = (id) => {
    const url = `/api/v1/gamesandtests/business/${props.business.id}/customfields/${id}/`;
    props.actions
      .fetchAndReturn(props.token, url, 'DELETE')
      .then((response) => {
        if (response.status >= 400) {
          if (response.status == 403) {
            message.error(i18n.t('commons__permissions_error'));
          }
          else {
            message.error('Atributo no pudo ser eliminado');
          }
        } else {
          message.success('Atributo eliminado con éxito');
          fetchCustomFields();
        }
      })
      .catch(() => message.error('Atributo no pudo ser eliminado'));
  };

  const duplicateCustomField = (originalCustomFieldData) => {
    const url = `/api/v1/gamesandtests/business/${props.business.id}/customfields/`;
    const data = { ...originalCustomFieldData };
    delete data.id;
    delete data.business;
    data.name = `Copia de ${originalCustomFieldData.name}`;
    props.actions
      .fetchAndReturn(props.token, url, 'POST', data)
      .then((response) => {
        if (response.status >= 400) {
          message.error('Atributo no pudo ser duplicado');
        } else {
          message.success('Atributo duplicado con éxito');
          fetchCustomFields();
        }
      })
      .catch(() => message.error('Atributo no pudo ser duplicado'));
  };

  const fetchCustomFields = () => {
    const url = `/api/v1/gamesandtests/business/${props.business.id}/customfields/`;
    props.actions
      .fetchAndReturn(props.token, url)
      .then((response) => {
        if (response.status < 400) {
          setCustomFieldsData(
            response.body.results.map((customField) => ({
              ...customField,
              key: customField.id,
            }))
          );
        }
      })
      .catch(() => message.error('Hubo un error obteniendo los datos'));
  };

  useEffect(fetchCustomFields, [props]);

  return (
    <Layout
      className="custom-fields-table-view"
      style={{ margin: '30px 40px 30px 40px' }}
    >
      <Row style={{ marginBottom: '1.5em' }}>
        {Breadcrumb}
      </Row>
      <Row type="flex" align="middle" style={{ marginBottom: '1em' }}>
        <Col style={{ marginRight: '0.75em' }}>
          <Icon
            onClick={() => props.dispatch(push('/tools'))}
            type="arrow-left"
          />
        </Col>
        <Col>
          <Title level={2} style={{ color: '#2B2B2B', margin: 0 }}>
            Atributos personalizados
          </Title>
        </Col>
      </Row>
      <Row style={{ marginBottom: '2em' }}>
        <Paragraph
          style={{
            color: '#5E5E5E',
            fontSize: '16px',
            fontWeight: 'normal',
            marginBottom: '0px',
          }}
        >
          Agrega campos con información adicional para personalizar la gestión
          de tus procesos, candidatos, y sus postulaciones. Esta información no
          será visible para los candidatos.
        </Paragraph>
      </Row>
      <Row type="flex" justify="space-around" style={{ marginBottom: '2em' }}>
        <Col span={6}>
          <Input.Search
            disabled={customFieldsData.length === 0}
            onSearch={(value) => setSearchValue(value)}
            placeholder="Buscar atributo por nombre"
          />
        </Col>
        <Col span={6} />
        <Col span={12} style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            type="primary"
            onClick={() => props.dispatch(push('/customfields/new'))}
          >
            Crear un atributo
          </Button>
        </Col>
      </Row>
      <Row>
        <Card
          bodyStyle={{ padding: 0 }}
          style={{ border: '1px solid #D9D9D9' }}
        >
          {customFieldsData.length === 0 ? (
            <Row
              style={{
                background: `url("${assetsUrl}Group+1499custom_fields_background.svg") no-repeat center bottom`,
                backgroundSize: 'contain',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Col
                span={8}
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  height: '40em',
                  justifyContent: 'center',
                }}
              >
                <Row type="flex" justify="center" style={{ width: '30em' }}>
                  <img
                    style={{ width: '50%' }}
                    src={`${assetsUrl}genomines/4.png`}
                  />
                </Row>
                <Row type="flex" justify="center" style={{ width: '30em' }}>
                  <Title level={2} style={{ color: '#454545' }}>
                    ¡Crea nuevos atributos personalizados!
                  </Title>
                </Row>
                <Row type="flex" justify="center" style={{ width: '30em' }}>
                  <Paragraph style={{ color: '#5E5E5E', textAlign: 'center' }}>
                    Hemos preparado para ti esta herramienta que te permitirá
                    almacenar los atributos que necesitas en los procesos de
                    selección, los candidatos y sus postulaciones.
                  </Paragraph>
                </Row>
                <Row type="flex" justify="center" style={{ width: '30em' }}>
                  <Button
                    type="primary"
                    onClick={() => props.dispatch(push('/customfields/new'))}
                  >
                    Crear un atributo
                  </Button>
                </Row>
              </Col>
            </Row>
          ) : (
            <Table
              columns={columns}
              dataSource={customFieldsData.filter((customFieldData) =>
                customFieldData.name
                  ?.toLowerCase()
                  .includes(searchValue.toLowerCase())
              )}
              style={{ height: '35em', maxHeight: '35em' }}
            />
          )}
        </Card>
      </Row>
    </Layout>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

const mapStateToProps = (state) => {
  return {
    business: state.auth.currentBusiness,
    token: state.auth.token,
  };
};

CustomFields.propTypes = {
  actions: PropTypes.shape({
    fetchAndReturn: PropTypes.func.isRequired,
  }),
  business: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  dispatch: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomFields);
