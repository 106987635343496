import React from 'react';
import { Form, Select, Input } from 'antd';
import { candidateProfile, academicSituation, computerSkills, educationLevel } from './options';
import Actions from '../../Actions';
import { AppContext } from '../../JobForm';

const { Option } = Select;
const { TextArea } = Input;

const Requeriments = (props) => {
  const { getFieldDecorator } = props.form;
  return (
    <AppContext.Consumer>
      {(value)  => {
        const { requisites } = value.data;
        return (
          <Form onSubmit={(e) => value.submit(e, props.form, 'requisites')}>
            <div className='form-view' id='form-view'>
              <Form.Item label="Requisitos minimos necesarios para postular">
                {getFieldDecorator('minimumRequirements', {
                  initialValue: requisites.minimumRequirements,
                  rules: [
                    {
                      required: true,
                      message: 'Por favor ingresa los requisitos mínimos',
                    },
                  ],
                })(
                  <TextArea autoSize={{ minRows: 4, maxRows: 5 }} />
                )}
              </Form.Item>

              <Form.Item label="Nivel de conocimientos informáticos">
                {getFieldDecorator('computerSkillId', {
                  initialValue: requisites.computerSkillId,
                  rules: [
                    {
                      required: true,
                      message: 'Por favor selecciona nivel de conocimiento',
                    },
                  ],
                })(
                  <Select>
                    {Object.keys(computerSkills).map((id) => (
                      <Option key={id} value={parseInt(id)}>{computerSkills[id]}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>

              <Form.Item label="Perfil del candidato">
                {getFieldDecorator('applicantProfile', {
                  initialValue: requisites.applicantProfile,
                  rules: [
                    {
                      required: true,
                      message: 'Por favor selecciona el perfil',
                    },
                  ],
                })(
                  <Select>
                    {Object.keys(candidateProfile).map((id) => (
                      <Option key={id} value={parseInt(id)}>{candidateProfile[id]}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>

              <Form.Item label="Situación académica">
                {getFieldDecorator('academicSituationId', {
                  initialValue: requisites.academicSituationId,
                  rules: [
                    {
                      required: true,
                      message: 'Por favor selecciona la situación académica',
                    },
                  ],
                })(
                  <Select>
                    {Object.keys(academicSituation).map((id) => (
                      <Option key={id} value={parseInt(id)}>{academicSituation[id]}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>

              <Form.Item label="Grado de estudios">
                {getFieldDecorator('educationalLevelId', {
                  initialValue: requisites.educationalLevelId,
                  rules: [
                    {
                      required: true,
                      message: 'Por favor selecciona el grado de estudios',
                    },
                  ],
                })(
                  <Select>
                    {Object.keys(educationLevel).map((id) => (
                      <Option key={id} value={parseInt(id)}>{educationLevel[id]}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </div>

            <Actions />
          </Form>
        )
      }}
    </AppContext.Consumer>
  )
}

const WrappedRequerimentsForm = Form.create()(Requeriments);
export default WrappedRequerimentsForm;