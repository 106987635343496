import React from 'react';
import { Modal, Typography, Row } from 'antd';

const { Text } = Typography;

const InformationModal = ({ process, showModal, setShowModal }) => {
  return (
    <Modal
      title='Procesos asignados'
      footer={null}
      onCancel={() => { setShowModal(false) }}
      visible={showModal}
      centered
    >
      {
        process.length > 0 ?
        (
          process.map(p => (
            <Row style={{ margin: '5px 0 0 0' }}>
              <Text strong>
                {p.job_app_name}
              </Text>
            </Row>
          ))
        ) : null
      }
    </Modal>
  );
}


export default InformationModal;