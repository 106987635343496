import './Leadership.scss';

import { Button, Col, Icon, Row, Tabs, Typography } from 'antd';
import React, { useContext } from 'react';

import BarChart from './BarChart';
import { CandidateContext } from '../../config/CandidateContext';
import LeadershipReport from './LeadershipReport';
import LeadershipType from './LeadershipType';
import { getEvaluationText } from './utils';
import useBoolean from '../../../../hooks/useBoolean';

const { Text, Title } = Typography;

const dummyData = [
  { code: 'INFIDE', score: 2 },
  { code: 'MOTINS', score: 5 },
  { code: 'ESTINT', score: 5 },
  { code: 'CONIND', score: 8 },
  { code: 'RECCON', score: 5 },
  { code: 'MANEXC', score: 10 },
  { code: 'LAIFAI', score: 7 },
];

const descriptionData = [
  {
    title: 'Liderazgo transformacional',
    color: '#FF85C0',
    items: [
      {
        title: 'Estimulación intelectual',
        code: 'ESTINT',
      },
      {
        title: 'Motivación inspiradora',
        code: 'MOTINS',
      },
      {
        title: 'Influencia idealizada',
        code: 'INFIDE',
      },
      {
        title: 'Consideración individual',
        code: 'CONIND',
      },
    ],
  },
  {
    title: 'Liderazgo transaccional',
    color: '#EB2F96',
    items: [
      {
        title: 'Recompensa contingente',
        code: 'RECCON',
      },
      {
        title: 'Manejo por excepción',
        code: 'MANEXC',
      },
    ],
  },
  {
    title: 'Liderazgo laissez-faire',
    color: '#780650',
    code: 'LAIFAI',
  },
];

const LeadershipTrait = ({ inSummary = false, data }) => {
  const [visibleReport, handleVisibleReport] = useBoolean(false);

  const { jobApp, candidate, changeKey } = useContext(CandidateContext);

  return (
    <div className="new-modal-trait">
      <Row type="flex" justify="space-between">
        <Text className="text-16px" strong>
          Liderazgo
        </Text>

        <Button
          icon={!inSummary ? 'file-pdf' : 'plus'}
          onClick={!inSummary ? handleVisibleReport.on : () => changeKey(2)}
        >
          {!inSummary ? 'Descargar' : 'Ver más información'}
        </Button>
      </Row>
      <Text>
        Mide el estilo de liderazgo que la persona evaluada tiende a preferir al
        enfrentar situaciones donde deben dirigir a miembros de su equipo.
      </Text>
      <BarChart barData={data} descriptionData={descriptionData} />
      {getEvaluationText(data)}
      {inSummary ? null : (
        <div className="leadership-description">
          {descriptionData.map((category) => (
            <LeadershipType category={category} traits={data} />
          ))}
        </div>
      )}
      <LeadershipReport
        visible={visibleReport}
        closeModal={handleVisibleReport.off}
        descriptionData={descriptionData}
        traits={data}
        jobApp={jobApp}
        candidate={candidate}
      />
    </div>
  );
};

export default LeadershipTrait;
