import React, { useState } from 'react';
import { Typography } from 'antd';
import { ResponsiveHeatMap } from '@nivo/heatmap';

import PropTypes, { arrayOf } from 'prop-types';

import InitialDescription from './InitialDescription';
import RawResults from './RawResults';
import DescriptionResults from './DescriptionResults';
import HeatmapExplanation from './HeatmapExplanation';
import DetailResult from './DetailResult';
import ColorSwatch from './ColorSwatch';

import { pickRGB } from '../../../../../components/charts/utils';

const { Text } = Typography;

export const DetailDat = ({ scores }) => {
  const [showExplanation, setShowExplanation] = useState(false);

  const DatHeatMap = ({ scores }) => {
    const data = [];

    scores.subset.forEach((rowElem, rowIdx) => {
      const tmpRow = {};
      tmpRow['id'] = rowElem;
      scores.subset.forEach((elem, colIdx) => {
        tmpRow[elem] = scores.distance_matrix.map((score) =>
          score.map((value) => Math.round(value * 100))
        )[rowIdx][colIdx];
      });
      data.push(tmpRow);
    });

    const getColorHeatMap = (value) => {
      if (value === 0) {
        return '#F0F0F0';
      }
      if (value > 0 && value <= 73) {
        const percent = (value - 0) / (73 - 0);

        const rgbArray = pickRGB([165, 0, 38], [244, 109, 67], percent);
        return `RGB(${rgbArray.join(',')})`;
      } else if (value >= 74 && value <= 83) {
        const percent = (value - 74) / (83 - 74);

        const rgbArray = pickRGB([253, 174, 97], [217, 239, 139], percent);
        return `RGB(${rgbArray.join(',')})`;
      } else if (value > 83) {
        const percent = (value - 84) / (110 - 84);

        const rgbArray = pickRGB([166, 217, 106], [0, 104, 55], percent);
        return `RGB(${rgbArray.join(',')})`;
      }
    };

    const dataKeysValues = data.length
      ? Object.keys(data[0]).filter((key) => key !== 'id')
      : [];
    const colors = data
      .map((item) =>
        dataKeysValues.map((key) => getColorHeatMap(item[`${key}`]))
      )
      .flat();

    function scale() {}

    scale.domain = () => {
      const _colors = colors.slice(0);
      return () => {
        return _colors.shift();
      };
    };

    return (
      <ResponsiveHeatMap
        forceSquare
        data={data}
        maxValue={101}
        minValue={0}
        keys={scores.subset}
        indexBy={'id'}
        margin={{ top: 20, right: 100, bottom: 60, left: 100 }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 30,
          legend: '',
          legendOffset: 46,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '',
          legendPosition: 'middle',
          legendOffset: 70,
        }}
        axisRight={false}
        axisTop={false}
        colors={scale}
        cellOpacity={1}
        emptyColor="#ffffff"
        animate={true}
        motionConfig="wobbly"
        motionStiffness={80}
        motionDamping={9}
        hoverTarget="cell"
        sizeVariation={0}
        cellBorderWidth={5}
        cellBorderColor={'#FFFFFF'}
        labelTextColor={(obj) => (obj.label === 0 ? '#F0F0F0' : '#454545')}
        theme={{
          fontSize: 14,
          labels: {
            text: {
              fontSize: 14,
              fill: '#FFFFFF',
            },
          },
        }}
      />
    );
  };

  return (
    <>
      <InitialDescription />
      <RawResults margin={'0 0 30px 0'} score={scores.score} />
      <DescriptionResults />
      {showExplanation ? <HeatmapExplanation /> : null}
      <DetailResult
        showExplanation={showExplanation}
        setShowExplanation={setShowExplanation}
      />
      <ColorSwatch margin={'20px 0'} />

      <div style={{ height: 400 }}>
        <DatHeatMap scores={scores} />
      </div>
    </>
  );
};

DetailDat.propTypes = {
  scores: PropTypes.shape({
    score: PropTypes.number,
    subset: PropTypes.arrayOf(String),
    distance_matrix: PropTypes.arrayOf(arrayOf(Number)),
  }),
};
export default DetailDat;
