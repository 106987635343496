import React, { useState, useEffect } from 'react';
import { Button, Col, Icon, Row, Tag, Typography, Input } from 'antd';

import { configurations } from '../triggerForm';
import { actionsOptions } from './TriggerAction';

const { TextArea } = Input; 
const { Text } = Typography;

const triggerSummary = ({ trigger, stages, number, canRemove=false, remove, cutes }) => {
  const [whenLabel, setWhenLabel] = useState('');
  const conditionLabel = (condition) => {
    if (condition.type === 'FIT') {
      const filter = condition.filter === 'geq' ? 'mayor o igual a' :  'menor a'
      return `Fit ${filter} ${condition.value}%`
    }
    if (condition.type === 'KT'){
      const cute = cutes.find((c) => c.id === condition.cute_id)
      return `${cute.candidate_path_title ? cute.candidate_path_title.toLowerCase() : cute.name.toLowerCase()} ${condition.scores_labels.length > 0 ? `con resultado: ${condition.scores_labels.join(', ')}` : ''}`
    }
  }

  const cleanMailText = (text) => {
    const cleanHtml = text.replace(/<[^>]*>/g, "");
    return cleanHtml
  }

  useEffect(() => {
    if (trigger.when?.label) {
      setWhenLabel(trigger.when.label + ': ')
      return
    }
    setWhenLabel(configurations[trigger.when]?.label + ': ')
  }, [trigger.when])

  return(
    <div style={{marginBottom: '1em'}}>
      <Row type='flex' justify='start' align='middle' style={{width: '100%'}}>
        <Row style={{width: '100%'}}>
          <Col span={23}>
            <Typography.Text style={{
              fontSize: '14px',
              lineHeight: '22px',
              fontWeight: 700}}
            >
            Automatización {number}
            </Typography.Text>
          </Col>
          {canRemove &&
            <Col span={1}>
              <Button icon='delete' style={{color: '#F175A5'}} onClick={remove}/>
            </Col>
          }
        </Row>
        <Row style={{width: '100%', marginTop: '5px'}} key={`summary__primary-trigger`}>
          {whenLabel}<br />
          <Text>
          {trigger.conditions?.length > 0 && <>Condiciones:<br /></>}
            <ul style={{marginBottom: '5px'}}>
              {trigger.conditions?.map((condition, conditionIndex) => (
              <li key={`summary__trigger__condition_${conditionIndex}`}>
                {trigger.conditionsOperator === 'AND' && conditionIndex > 0 ? ' y ' : trigger.conditionsOperator === 'OR' && conditionIndex > 0 ? ' o ' : null}
                {conditionLabel(condition)}<br />
              </li>
            ))}
            </ul>
          </Text>
            <Typography.Text style={{color: '#F175A5'}}>Acciones principales</Typography.Text>
            {trigger.primaryActions?.map((action, actionIndex) => {
              return (
                <div key={`summary__trigger__action_${actionIndex}`}>
                  <Row style={{ marginLeft: '1em'}}>
                    {
                      action.config.schedule_type === 'custom' && 
                        <>
                          <Text>Enviar {action.config.days_offset} días después:</Text>
                          <br/>
                        </>
                    }
                    <Icon type={actionsOptions[action.type].icon} style={{ fontSize: 14, marginRight: '5px'}} />
                    {action.type === 'MSG' && 
                    <>
                      <Text>Enviar mensaje</Text>
                      <Row gutter={[10, 10]} type='flex'>
                        <Col xs={24}>
                          <Input value={action.config.subject}/> 
                        </Col>
                        <Col xs={24}>
                          <TextArea value={cleanMailText(action.config.content)} />
                        </Col>

                      </Row>
                    </>
                    }
                    {
                    action.type === 'MOVE' && 
                    <Text>Mover a la etapa: {stages.find((stage) => stage.id === action.config.next_stage)?.name}</Text>
                    }
                    {
                    action.type === 'ADDTAG' && 
                    <Text>Etiquetar con: {action.config.labels?.map((label, idx) => <Tag key={`tag_label__${idx}`}>{label}</Tag>)}</Text>
                    }
                    {
                    action.type === 'DISCARD' && 
                    <Text>Descartar candidatos</Text>
                    }
                  </Row>
                </div>
              )})}
            {trigger.secondaryActions?.length > 0 &&
              <>
              <Typography.Text style={{color: '#F175A5'}}>Acciones secundarias</Typography.Text>
                <br />
                {trigger.secondaryActions?.map((action, actionIndex) => {
                  return (
                    <div key={`summary__trigger__action_${actionIndex}`}>
                      <Row style={{marginLeft: '1em'}}>
                        <Icon type={actionsOptions[action.type].icon} style={{ fontSize: 14, marginRight: '5px'}} />
                        {action.type === 'MSG' && 
                        <>
                          <Text>{action.config.subject}</Text>
                          <br />
                          <Text>{cleanMailText(action.config.content)}</Text>
                        </>
                        }
                        {
                        action.type === 'MOVE' && 
                        <Text>Mover a la etapa: {stages.find((stage) => stage.id === action.config.next_stage).name}</Text>
                        }
                        {
                        action.type === 'ADDTAG' && 
                        <Text>Etiquetar con: {action.config.labels?.map((label, idx) => <Tag key={`tag_label__${idx}`}>{label}</Tag>)}</Text>
                        }
                        {
                        action.type === 'DISCARD' && 
                        <Text>Descartar candidatos</Text>
                        }
                      </Row>
                    </div>
                  )})
                }
              </>
            }
        </Row>    
      </Row>
    </div>
  )
}

export default triggerSummary;