import React, { Component } from 'react';
import gamesDescription from './GameList/SingleGame/gameDescriptions';
import { Row, Col, Typography } from 'antd';
import GameDescription from './GameList/GameDescription/GameDescription';
import GameList from './GameList/GameList';
import './CandidatePath.scss';

const { Text } = Typography;

class CandidatePath extends Component {

  state = {
    visibleDescription: false,
    activity: {},
    description: ''
  }

  openModal = (description, activity) => {
    this.setState({ 
      visibleDescription: !this.state.visibleDescription, 
      activity, 
      description: description, activity
    })
  }

  closeModal = () => {
    this.setState({ 
      visibleDescription: !this.state.visibleDescription
    })
  }

  prevOrNext = (prev) => {
    const { allGames, index } = this.state.activity;
    const prevNum =  index - 1;
    const nextNum = index + 1;
    const num = prev ? prevNum : nextNum;

    if (allGames.activities.length >= num+1 && num > -1) {
      const newActivity = this.state.activity.allGames.activities[Number(num)];
      this.setState({
        activity: { allGames: {activities: this.state.activity.allGames.activities}, ...this.state.activity.allGames.activities[Number(num)], title: this.state.activity.title, index: num },
        description: gamesDescription[newActivity.code]
      })
    }
  }

  render() {
    return (
      <Row gutter={30} className='ig__candidate-path'>
        <Col sm={24} className='game-bags'>
          <div className='ig__headline'>
            <Text type='secondary'>
              En esta sección debes elegir qué evaluaciones tendrá tu proceso de selección.
            </Text>
          </div>
          
          <GameList 
            openModal={this.openModal} 
            onChangeCustomTest={this.props.onChangeCustomTest}   
            currentCustomTests={this.props.currentCustomTests}
            {...this.props.activities} assignedActivities={this.props.assignedActivities} 
          />
        </Col>

        <GameDescription 
          selectedGame={this.state.activity}
          visible={this.state.visibleDescription}
          description={this.state.description}
          openModal={this.closeModal}
          nextGame={this.prevOrNext}
        />
      </Row>
    )
  }
}

export default CandidatePath;