import React, { useContext } from 'react';
import JobAppContext from '../../context/JobAppContext';
import { Button, Col, Row, Icon, Typography, Alert } from 'antd';
import StartProcessPreview from './StartProcessPreview';
import { isMobile } from 'react-device-detect';
import UploadImage from '../../../../AccountV2/profile/components/uploadImage';

import { Link } from 'react-router-dom';

function Company() {
  const { updateJobApplication, jobApplication, business } =
    useContext(JobAppContext);

  const checkBackgorundImageType = () => {
    if (typeof jobApplication.backgroundImage === 'string') {
      return jobApplication.backgroundImage;
    }
    return jobApplication.backgroundImage.b64Img;
  };

  return (
    <>
      <Row type="flex" gutter={[16, 0]}>
        {jobApplication.is_confidential ? (
          <Col sm={8}>
            <div>
              <Alert
                showIcon
                type="info"
                message={
                  <span>
                    Si quieres cambiar la configuración de tus procesos
                    confidenciales, dirígete a{' '}
                    <strong>Mi marca (confidencial)</strong>, selecciona lo que
                    necesites y se aplicará en tus{' '}
                    <strong>procesos confidenciales.</strong>
                  </span>
                }
              />
              <Link
                to="/account/recruitmentportal/confidential"
                target="_blank"
              >
                <Button icon="arrow-right" style={{ marginTop: '1rem' }}>
                  Ir a Mi marca (confidencial)
                </Button>
              </Link>
            </div>
          </Col>
        ) : (
          <Col sm={8}>
            <Row>
              <Typography.Text
                strong
                style={{
                  color: '#2B2B2B',
                  fontSize: 18,
                }}
              >
                Apariencia
              </Typography.Text>
            </Row>
            <Row>
              <Typography.Text
                style={{
                  color: '#2B2B2B',
                  fontSize: 14,
                }}
              >
                Agrega una imagen para personalizar la apariencia de este
                proceso.
              </Typography.Text>
            </Row>

            <Row style={{ paddingTop: 16 }}>
              <Typography.Text
                strong
                style={{
                  color: '#2B2B2B',
                  fontSize: 16,
                }}
              >
                Imágenes
              </Typography.Text>
            </Row>
            <Row style={{ paddingTop: 8 }}>
              <UploadImage
                type="bg"
                getImage={(b64Img, fileObj) =>
                  updateJobApplication('backgroundImage', {
                    b64Img: b64Img,
                    fileObj: fileObj,
                  })
                }
                newProcess={true}
              />
            </Row>
            <Row>
              <Col sm={20}>
                <Typography.Text
                  style={{
                    fontSize: 14,
                    color: '#2B2B2B',
                  }}
                >
                  Archivos soportados JPG y PNG, con un peso máximo de 1MB.
                  Mínimo 200x200 píxeles
                </Typography.Text>
              </Col>
            </Row>
            <Row style={{ paddingTop: 10 }}>
              <Button
                onClick={() =>
                  updateJobApplication('backgroundImage', undefined)
                }
              >
                <Icon type="rollback" />
                <Typography.Text
                  style={{
                    fontSize: 14,
                  }}
                >
                  Deshacer cambios
                </Typography.Text>
              </Button>
            </Row>
          </Col>
        )}

        <Col sm={16} style={{ paddingBottom: '7rem' }}>
          <Row>
            <Col sm={20}>
              <div className="laptop" style={{ zIndex: 0 }}>
                <div className="laptop-screen">
                  <div className="laptop-content">
                    <StartProcessPreview
                      isMobile={false}
                      banner={
                        jobApplication.backgroundImage
                          ? checkBackgorundImageType()
                          : business.backgroundImage
                      }
                      data={jobApplication}
                      businessTheme={business}
                      business={business}
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={4}>
              <div
                className="laptop"
                style={{
                  top: isMobile ? '' : '12.5rem',
                  right: isMobile ? '' : '6rem',
                }}
              >
                <StartProcessPreview
                  isMobile={true}
                  banner={
                    jobApplication.backgroundImage
                      ? checkBackgorundImageType()
                      : business.backgroundImage
                  }
                  data={jobApplication}
                  business={business}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Company;
