import React, { useState, useMemo, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../../actions/data';
import i18n from '../../../../i18n';

import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Empty,
  Icon,
  Input,
  Menu,
  message,
  Modal,
  Popover,
  Radio,
  Row,
  Table,
  Tooltip,
  Typography,
} from 'antd';

import {
  defaultColumnsGenerator,
  documentsColumnsGenerator,
  koColumnsGenerator,
  activityProgressColumnsGenerator,
} from '../TableColumns';

import debounce from 'lodash.debounce';

import useBoolean from '../../../../hooks/useBoolean';
import Filter from './Filter';

// import AddInviteModal from './AddInviteModal';
import MoveModal from './MoveModal';
import ScheduleModal from '../../CandidateListV2/ScheduleModal';

import DocsModal from './DocsModal';

import CvFilter from '../../CandidateListV2/CvModule/CvFilter';
import MailModalV2 from '../../CandidateListV2/CandidateList/MailModalV2';
import PreviewDocument from '../../../CandidateProfilev2/common/PreviewDocument';
import ShareProfiles from './ShareProfiles';
import DocsResetModal from './FileRequestsModal';
import PassiveCandidateFormModal from './PassiveCandidateFormModal';
import InviteCandidateModal from '../../../../components/InviteCandidate';

const PAGE_SIZE_OPTIONS = [5, 10, 25, 50, 100];

const { Paragraph, Text } = Typography;

const tablePropsOptions = [
  {
    label: 'FIT',
    value: 'final_score',
    disabled: true,
  },
  {
    label: 'NOMBRE/CORREO',
    value: 'full_name',
    disabled: true,
  },
  {
    label: 'TIPO DE CANDIDATO',
    value: 'candidate_type',
  },
  {
    label: 'CV',
    value: 'cv',
  },
  {
    label: 'DATOS PERSONALES',
    value: 'personal_data',
  },
  {
    label: 'JUEGOS',
    value: 'completed_games',
  },
  // {
  //   label: 'ESTADO DE ETAPA',
  //   value: 'stage',
  // },
  {
    label: 'ETIQUETAS',
    value: 'tags',
  },
  {
    label: 'POSTULACIÓN',
    value: 'date_started',
  },
];

const SHARE_URL = process.env.REACT_APP_SHARE_URL;

const translationFile = { ns: 'candidateProfile' };

// TODO: PropTypes
// TODO: Translate
const CandidateTable = ({
  candidates,
  countries,
  stagesOnFocus,
  candidatesToTable = [],
  jobAppActive,
  openCandidateModal,
  candidatesState,
  setCandidatesState,
  dataOk,
  page,
  setPage,
  columnsType,
  jobAppId,
  jobStages,
  business,
  token,
  actions,
  setCandidates,
  selectedRowKeys,
  onSelectRowChange,
  jobApplication,
  documentsView,
  docs,
  discardReasons,
  addOns = [],
  queryColumns,
  setQueryColumns,
  queryFilters,
  changeFilters,
  setQueryFilters,
  cvFilters,
  filterCvCandidates,
  setCvFilters,
  extraColumns,
  totalCandidatesToTable,
  pageSize,
  setPageSize,
  showDrawer,
  handleDrawer,
  setOrderBy,
  koFilters,
  setKoFilters,
  getStageCandidates,
  setBulkPerStageConfig,
  bulkPerStageConfig,
  stageActiveCandidatesCount,
  stageDiscardedCandidatesCount,
  stageActivitiesHeaders,
  businessPermissions
}) => {
  // states
  // const [showAddModal, handleAddModal] = useBoolean(false);

  const [selectedColumns, setSelectedColumns] = useState(tablePropsOptions.map(({ value }) => value));
  const [questionsColumns, setQuestionsColumns] = useState([]);
  const [cv, setCv] = useState(null);
  const [cvLoading, setCvLoading] = useState(false);
  const [candidateIndexDocs, setCandidateIndexDocs] = useState(null);
  const [discardedCandidates, setDiscardedCandidates] = useState([]);
  const [mailTemplates, setMailTemplates] = useState();

  const [cvModalFlag, handleCvModal] = useBoolean(false);
  const [showMoveModal, handleMoveModal] = useBoolean(false);
  const [showDiscardModal, handleDiscardModal] = useBoolean(false);
  const [showPassiveModal, handlePassiveModal] = useBoolean(false);
  const [showReclassifyModal, handleReclassifyModal] = useBoolean(false);
  const [showScheduleModal, handleScheduleModal] = useBoolean(false);
  const [showCvFiltersModal, handleFiltersCvModal] = useBoolean(false);

  const [candidateDocsReset, setCandidateDocsReset] = useState(null);

  const [showMailModal, handleMailModal] = useBoolean(false);
  const [showInviteModal, handleInviteModal] = useBoolean(false);

  const queryPropsOptions = [
    {
      label: 'VIDEOENTREVISTA',
      value: 'vint_eval',
      display: jobApplication.interview_id !== null ? true : false,
    },
    {
      label: 'PREGUNTAS',
      value: 'ko',
      disabled: jobAppActive?.find((act) => act.code === 'KO') ? false : true,
      display: true,
    },
  ];

  const questionsPropsOptions = queryColumns.includes('ko')
    ? Object.entries(
      JSON.parse(jobAppActive.find((act) => act.code === 'KO').configuration)
        .questions
    ).map((q) => ({
      label: q[1].question,
      value: q[0],
    }))
    : [];

  ////////////////////////////////

  // functions
  const debouncedSearch = debounce((value) => {
    changeFilters('name', value);
  }, 500);

  const handleCV = (cv) => {
    setCv(cv);
    handleCvModal.toggle();
  };

  const downloadCV = (cv) => {
    const link = document.createElement('a');
    link.href = cv;
    link.setAttribute('download', 'cv');
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleOpenProfileModal = (cId, userId) => {
    openCandidateModal(cId, userId);
  };

  const prepareBulkResetDocs = () => {
    const candidates = candidatesToTable.filter(
      (candidate, idx) => candidate.files_requests.length > 0 && selectedRowKeys.includes(idx)
    );
    setCandidateDocsReset(candidates);
  }

  const compareCandidates = () => {
    const usersIds = selectedRowKeys
      .map((index) => candidatesToTable[index].personal_user_id)
      .join(',');
    const url = `/peopleanalytics/candidates?userids=${usersIds}&jobappid=${jobAppId}`;
    window.open(url);
  };

  const askForReferences = () => {
    const candidates = selectedRowKeys.map((i) => candidatesToTable[i].personal_user_id);
    const data = { candidates };
    const url = `gamesandtests/jobapplications/${jobAppId}/userjobapplicationreferences/?lang=es`;
    Modal.confirm({
      cancelText: i18n.t('profile__cancel', translationFile),
      closable: true,
      content: (
        <Paragraph>
          {i18n.t('profile__sent_mail_explanation_in_references', translationFile)}
        </Paragraph>
      ),
      okText: i18n.t('profile__send', translationFile),
      onOk: () => {
        actions
          .sendData(token, url, JSON.stringify(data), 'POST')
          .then((_) => message.success(i18n.t('profile__requests_for_references_sent_ok', translationFile)))
          .catch((_) => message.error(i18n.t('profile__requests_for_references_sent_error', translationFile)));
      },
    });
  };

  const moveCandidates = async (nextStage, closeModal, key = 'status') => {
    const url = `/api/v1/gamesandtests/jobapplications/${jobAppId}/candidates`;
    const isStateKey = key === 'state';

    // we have the selected rows in the table, but we need to get the personal_user_id
    let personal_ids;

    if (bulkPerStageConfig.allSelected) {
      // TODO: Probar que esto funcione!
      personal_ids = bulkPerStageConfig.data.map(
        (user) => user.personal_user_id || candidatesToTable[i].passive_candidate
      );
    } else {
      personal_ids = selectedRowKeys.map(
        (i) => candidatesToTable[i].personal_user_id || candidatesToTable[i].passive_candidate
      );
    }

    let promises = [];

    try {
      if (isStateKey) {
        // DISCARD
        promises = personal_ids.map((id) => {
          return actions.fetchAndReturn(
            token,
            `${url}/${id}/`,
            'PATCH',
            { state: nextStage },
          );
        });
      } else {
        // NORMAL MOVE
        promises = personal_ids.map((id) => {
          return actions.fetchAndReturn(
            token,
            `${url}/${id}/`,
            'PATCH',
            { status: nextStage },
          );
        });
      }

      const results = await Promise.all(promises);

      if (isStateKey && nextStage === 'DISCAR') {
        const tmpCandidates = formatMailCandidates();
        setDiscardedCandidates(tmpCandidates);
      }

      if (results.some(obj => obj < 400)) {
        message.success(isStateKey && nextStage === 'ACTIV' ?
          i18n.t('messages__success_reincorporating_candidates') :
          i18n.t('messages__success_moving_candidates')
        );
      }

      if (key === 'status') {
        // updating headers
        // CHANGING THIS STATE WE GET A REFETCH, SO NO NEED TO CHANGE OTHERS STATES MANUALLY :)

        setCandidates((candidates) => ({
          ...candidates,
          [stagesOnFocus]: {
            ...candidates[stagesOnFocus],
            count: candidates[stagesOnFocus].count - personal_ids.length,
          },
          [nextStage]: {
            ...candidates[nextStage],
            count: candidates[nextStage].count + personal_ids.length,
          },
        }));
      } else {
        setCandidates((candidates) => ({ ...candidates }));
      }

      closeModal();
    } catch (e) {
      message.error(
        isStateKey && nextStage === 'ACTIV' ?
          i18n.t('messages__error_reincorporating_candidates') :
          i18n.t('messages__error_moving_candidates')
      );
    }
  };

  const discardCandidates = async (closeModal, discardReasonId, comment = null) => {
    const url = `/api/v1/gamesandtests/jobapplications/${jobAppId}/candidates/`;
    const personal_ids = selectedRowKeys.map(
      (i) => candidatesToTable[i].personal_user_id
    );

    try {
      await Promise.all(
        personal_ids.map((id) => {
          return actions.fetchAndReturn(
            token,
            `${url}${id}/`,
            'PATCH',
            {
              state: 'DISCAR',
              discard_reason: discardReasonId,
              discard_reason_comment: comment,
            },
          );
        })
      );
      message.success(i18n.t('messages__success_discarding_candidates'));
      setCandidates((candidates) => ({ ...candidates }));
      closeModal();

    } catch (e) {
      message.error(i18n.t('messages__error_discarding_candidates'));
    }
  }

  const handleEditDiscardReason = async (application, candidate, discardReasonId) => {
    const url = `/api/v1/gamesandtests/jobapplications/${jobAppId}/candidates`
    const data = { discard_reason: discardReasonId };
    try {
      await actions.fetchAndReturn(
        token,
        candidate.personal_user_id ? `${url}/${candidate.personal_user_id}/` : `${url}/${candidate.id}/`,
        'PATCH',
        data,
      )
      message.success(i18n.t('commons__saved_changes', { ns: 'businessUser' }));
      const newTitle = discardReasons.find(({ id }) => id === discardReasonId).title;
      application.discard_reason_representation = newTitle;

    } catch (error) {
      message.error(i18n.t('commons__unsaved_changes', { ns: 'businessUser' }));
    }
  };

  // TODO: Probar que esto funcione!
  const formatMailCandidates = () => {
    let rows;

    if (bulkPerStageConfig.allSelected) {
      rows = bulkPerStageConfig.data.map((user) => ({
        id: user.personal_user_id,
        email: user.email,
        name: user.user_name,
      }));
    } else {
      if (selectedRowKeys.length > 0) {
        rows = selectedRowKeys.filter(
          (index) => candidatesToTable[index]?.email
        ).map((key) => ({
          id: candidatesToTable[key]?.personal_user_id,
          email: candidatesToTable[key]?.email,
          name: candidatesToTable[key]?.full_name,
        }));
      } else {
        return discardedCandidates;
      }
    }

    return rows.reduce((acc, curr) => {
      return {
        [stagesOnFocus]: {
          ...acc[stagesOnFocus],
          [curr.id]: curr,
        },
      };
    }, {});
  };
  ////////////////////////////////

  // table config
  const handleTableChange = (pagination, filters, sorter) => {
    const { current } = pagination;
    const { columnKey, order } = sorter;
    const keysDict = {
      final_score: 'fit',
      full_name: 'name',
    };
    if (columnKey && order) {
      setOrderBy({
        key: keysDict[columnKey] || columnKey,
        order: order === 'ascend' ? '' : '-',
      });
    } else {
      setOrderBy(null);
    }

    setPage(current);
  };

  const columnGenerators = {
    default: defaultColumnsGenerator,
    documentsView: documentsColumnsGenerator,
    cv: () => [],
    ko: koColumnsGenerator,
    activities: activityProgressColumnsGenerator,
  };

  const order = !documentsView
    ? {// we need to do this because if sorter attribute is not present, the table doesnt render the sorter button
      FIT: {
        sorter: (a, b) => { },
      },
      date_started: {
        sorter: (a, b) => { },
      },
      discard_reason: {
        sorter: (a, b) => { },
      },
      full_name: {
        sorter: (a, b) => { },
      },
    }
    : {};

  const { desSkills = [], excSkills = [] } = cvFilters ?? {};

  const totalSkills = desSkills.length + excSkills.length;

  const config = documentsView
    ? {
      candidates,
      filesRequests: jobAppActive.filter((obj) => obj.code === 'DOCR'),
      jobStages,
      selectIndex: (index) => setCandidateIndexDocs(index),
      openDocsResetModal: (obj) => setCandidateDocsReset([obj]),
    } : {
      selectedKos: questionsColumns,
      activities: jobAppActive,
      cvFilters,
      totalSkills,
      jobStages,
      koFilters,
      setKoFilters,
      queryFilters,
      setQueryFilters,
      bulkPerStageConfig,
    };

  const columns = useMemo(() => {
    const tmpColumns = [
      ...columnGenerators[columnsType](
        {
          handleEditDiscardReason,
          handleCV,
          handleOpenProfileModal,
          downloadCV,
        },
        order,
        config,
        discardReasons,
        jobAppId,
      ),
    ];

    const tmpQueryColumns = [...queryColumns];
    // If query for vint_eval also shows vint_ready
    if (tmpQueryColumns.find(columnKey => columnKey === 'vint_eval')) {
      tmpQueryColumns.push('video_interview_ready')
    };

    const activitiesColumns = [
      ...columnGenerators['activities'](
        {},
        {},
        {
          activities: stageActivitiesHeaders.filter(obj => obj.stage === stagesOnFocus),
          queryFilters,
          setQueryFilters,
        }),
    ];

    if (!documentsView) {
      const baseColumns = tmpColumns.filter((column) =>
        [
          ...selectedColumns,
          ...activitiesColumns,
          ...tmpQueryColumns,
          ...questionsColumns,
          ...cvFilters.desSkills,
          ...cvFilters.excSkills,
          ...extraColumns,
          ...['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
        ].includes(column.key)
      );

      return [...baseColumns, ...activitiesColumns];
    }


    return tmpColumns;
  }, [
    columnsType,
    selectedColumns,
    queryColumns,
    questionsColumns,
    candidatesToTable,
    cvFilters,
    koFilters,
  ]);

  // there is so many variables that can change the table, so we need to memoize it
  // to avoid unnecessary renders
  // im not confortable with this approach but the problem doesnt start here
  // i dont want to change the whole table component
  const rows = useMemo(() => {
    return !candidatesToTable || !Array.isArray(candidatesToTable)
      ? []
      : candidatesToTable.map((candidate, index) => ({ ...candidate, key: index }));
  }, [candidatesToTable]);

  const selectedCandidates = useMemo(formatMailCandidates, [selectedRowKeys])

  useEffect(() => {
    // cleaning unnecesary columns
    if (!queryColumns.includes('ko')) {
      setQuestionsColumns([]);
    }
  }, [queryColumns]);

  useEffect(() => {
    // This should not happen, just in case
    if (selectedRowKeys.length > 0 && discardedCandidates.length > 0) {
      setDiscardedCandidates([]);
    }
  }, [selectedRowKeys]);

  useEffect(() => {
    if (candidatesState === 'DISCAR') {
      setSelectedColumns([...selectedColumns, 'discard_reason_representation']);
    } else {
      setSelectedColumns(selectedColumns.filter((column) => column !== 'discard_reason_representation'));
    }
  }, [candidatesState])

  useEffect(() => {
    if (mailTemplates === undefined) {
      const url = `/api/v1/accounts/business/${business.id}/mailtemplates/`;
      actions
        .fetchAndReturn(token, url)
        .then(({ body, status }) => status < 400 && setMailTemplates(body.results))
        .catch((error) => Sentry.captureException(error));
    }
  }, [business]);

  const permissionError = () => {
    message.error(i18n.t('commons__permissions_error'));
  }

  /////////////////////////////////

  // components config
  const addCandidatesDropdownMenu = (
    <Menu className="more-buttons-list-container">
      <Menu.Item key="0" onClick={handlePassiveModal.on}>
        <Tooltip
          placement="left"
          title={
            <Text style={{ color: 'white' }}>
              {i18n.t('add_candidates__passive_candidate_explanation_tooltip').split('**')[0]}
              <Text strong style={{ color: 'white' }}>
                {i18n.t('add_candidates__passive_candidate_explanation_tooltip').split('**')[1]}
              </Text>
              {i18n.t('add_candidates__passive_candidate_explanation_tooltip').split('**')[2]}
              <Text strong style={{ color: 'white' }}>
                {i18n.t('add_candidates__passive_candidate_explanation_tooltip').split('**')[3]}
              </Text>
              {i18n.t('add_candidates__passive_candidate_explanation_tooltip').split('**')[4]}
            </Text>
          }
        >
          <Icon type="user-add" />
          <Text>{i18n.t('commons__passive_candidates')}</Text>
        </Tooltip>
      </Menu.Item>
      {addOns.find((addon) => addon.add_on === 'IMPC') ? (
        <Menu.Item key="1" onClick={() => window.open(`${SHARE_URL}/candidateupload/${jobAppId}`, '_blank')}>
          <Tooltip
            placement="left"
            title={
              <Text style={{ color: 'white' }}>
                {i18n.t('add_candidates__active_candidate_explanation_tooltip').split('**')[0]}
                <Text strong style={{ color: 'white' }}>
                  {i18n.t('add_candidates__active_candidate_explanation_tooltip').split('**')[1]}
                </Text>
                {i18n.t('add_candidates__active_candidate_explanation_tooltip').split('**')[2]}
                <Text strong style={{ color: 'white' }}>
                  {i18n.t('add_candidates__active_candidate_explanation_tooltip').split('**')[3]}
                </Text>
                {i18n.t('add_candidates__active_candidate_explanation_tooltip').split('**')[4]}
              </Text>
            }
          >
            <Icon type="upload" />
            <Text>{i18n.t('add_candidates__upload_excel')}</Text>
          </Tooltip>
        </Menu.Item>
      ) : null}
    </Menu>
  );

  const checkboxStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  };

  const propsTableContent = (
    <div className="table-props-popover-content">
      <Checkbox.Group
        style={checkboxStyle}
        options={candidatesState === 'DISCAR' ?
          [...tablePropsOptions,
          {
            label: i18n.t('management__singular_discard_reason', { ns: 'businessUser' }).toUpperCase(),
            value: 'discard_reason_representation'
          }] :
          tablePropsOptions}
        value={selectedColumns}
        onChange={(checkedValues) => setSelectedColumns(checkedValues)}
      />
      <Divider style={{ margin: 0 }} />
      <Checkbox.Group
        style={checkboxStyle}
        options={queryPropsOptions.filter((item) => item.display)}
        value={queryColumns}
        onChange={(checkedValues) => setQueryColumns(checkedValues)}
      />

      {queryColumns.includes('ko') ? (
        <Checkbox.Group
          style={checkboxStyle}
          options={questionsPropsOptions}
          value={questionsColumns}
          onChange={(checkedValues) => setQuestionsColumns(checkedValues)}
        />
      ) : null}
    </div>
  );

  const DropdownMenu = (
    <Menu className="more-buttons-list-container">
      {businessPermissions.role?.can_manage_userjobapplications &&
        <Menu.Item
          disabled={selectedRowKeys?.filter((index) => candidatesToTable[index]?.candidate_type === 'passive').length > 0}
          onClick={handleMailModal.toggle}
        >
          <Icon type="mail" />
          <Tooltip placement="topLeft" title="Por el momento, esta acción solo está disponible para candidatos activos">
            <Text>{i18n.t('actions__send_email')}</Text>
          </Tooltip>
        </Menu.Item>
      }
      {businessPermissions.role?.can_create_and_manage_passive_candidates &&
        <Menu.Item
          disabled={
            selectedRowKeys?.filter(
              (index) => {
                const candidate = candidatesToTable[index];
                return candidate && (candidate.candidate_type !== 'passive' || !candidate.email);
              }
            ).length > 0
          }
          onClick={handleInviteModal.toggle}
        >
          <Icon type="user-add" />
          <Tooltip placement="topLeft" title="Esta acción solo está disponible para candidatos pasivos con correo">
            <Text>{i18n.t('profile__invite_passive_candidate', translationFile)}</Text>
          </Tooltip>
        </Menu.Item>
      }
      <Menu.Item disabled={bulkPerStageConfig.allSelected}>
        <Icon type="share-alt" />
        <ShareProfiles
          candidates={selectedRowKeys.map((i) => candidatesToTable[i])}
          jobAppId={jobAppId}
          disabled={bulkPerStageConfig.allSelected}
        />
      </Menu.Item>
      <Menu.Item
        disabled={selectedRowKeys?.filter((index) => candidatesToTable[index]?.candidate_type === 'passive').length > 0}
        onClick={askForReferences}
      >
        <Icon type="user-add" />
        <Tooltip placement="topLeft" title="Esta acción solo está disponible para candidatos activos">
          <Text>{i18n.t('commons__send_reference_request', translationFile)}</Text>
        </Tooltip>
      </Menu.Item>
      {addOns.find((addon) => addon.add_on === 'SCIN')
        ? (
          <Menu.Item onClick={handleScheduleModal.toggle}>
            <Icon type="calendar" />
            <Text>Agendar una reunión</Text>
          </Menu.Item>
        )
        : null}

      {stagesOnFocus !== 'OTHER' &&
        (candidatesState === 'DISCAR' ? (
          <Menu.Item onClick={businessPermissions.role?.can_manage_userjobapplications ? handleReclassifyModal.toggle : permissionError }>
            <Icon type="undo" />
            <Text>{i18n.t('commons__reincorporate')}</Text>
          </Menu.Item>
        ) : (
          <Menu.Item onClick={businessPermissions.role?.can_manage_userjobapplications ? handleDiscardModal.toggle : permissionError }>
            <Icon type="stop" />
            <Text>{i18n.t('commons__discard')}</Text>
          </Menu.Item>
        ))}
    </Menu>
  );
  //////////////////////////////

  const selectedPersonalUsers = useMemo(() => {
    const aux = [];
    selectedRowKeys?.forEach((index) => {
      const { name, email } = candidatesToTable[index];
      if (email) aux.push({ email, name });
    });
    return aux;
  }, [selectedRowKeys]);

  return (
    <div className="candidate-listv3-table">
      {!documentsView ? (
        <Row className="candidate-list-container__status-selector" type="flex">
          <Radio.Group
            value={candidatesState}
            onChange={(e) => {
              setCandidatesState(e.target.value);
              setPage(1);
            }}
          >
            <Radio.Button value="ACTIV">
              Activos ({stageActiveCandidatesCount})
            </Radio.Button>
            <Radio.Button value="DISCAR">
              Descartados ({stageDiscardedCandidatesCount})
            </Radio.Button>
          </Radio.Group>
        </Row>
      ) : null}
      <Row type="flex" justify="space-between" style={{ gap: '1rem' }}>
        {selectedRowKeys.length > 0 || bulkPerStageConfig.allSelected ? (
          <Row
            type="flex"
            className="candidate-list-actions"
          >
            <Row
              type="flex"
              align="middle"
              style={{
                height: '100%',
                gap: '0.5rem',
                padding: '0 0.5rem',
                background: '#ffffff',
              }}
            >
              <Checkbox
                checked={
                  selectedRowKeys.length > 0 || bulkPerStageConfig.allSelected
                }
                // some kind of hack to imitate a button that cleans the selection
                onChange={() => onSelectRowChange([])}
                disabled={bulkPerStageConfig.allSelected}
              />
              <Text>
                {bulkPerStageConfig.allSelected
                  ? totalCandidatesToTable
                  : selectedRowKeys.length}
              </Text>
            </Row>
            {columnsType !== 'documentsView'
              ? (
                <>
                  <Button
                    icon="arrow-right"
                    type="primary"
                    onClick={handleMoveModal.on}
                    disabled={!businessPermissions.role?.can_manage_userjobapplications}
                  >
                    Mover de etapa
                  </Button>
                  <Tooltip title="Máximo 5 candidatos.">
                    <Button
                      icon="team"
                      onClick={compareCandidates}
                      disabled={selectedRowKeys.length > 5}
                    >
                      Comparar candidatos
                    </Button>
                  </Tooltip>
                  <Dropdown overlay={DropdownMenu} placement="bottomRight">
                    <Button icon="down">Más...</Button>
                  </Dropdown>
                </>
              ) : (
                <>
                  <Button
                    icon='mail'
                    type='primary'
                    onClick={handleMailModal.toggle}
                  >
                    Enviar recordatorio
                  </Button>
                  <Button
                    icon='reload'
                    type='secondary'
                    onClick={prepareBulkResetDocs}
                  >
                    Resetear solicitud
                  </Button>
                </>
              )
            }
          </Row>
        ) : null}
        <Row type="flex" style={{ gap: '0.25rem', marginLeft: 'auto' }}>
          <Button
            onClick={() => {
              setBulkPerStageConfig((config) => ({
                ...config,
                allSelected: !config.allSelected,
              }));
            }}
          >
            {bulkPerStageConfig.allSelected ? 'Des' : 'S'}eleccionar por etapa
          </Button>
          <Input.Search
            placeholder="Buscar (nombre/email/dni)"
            style={{ width: 200 }}
            onChange={(e) => debouncedSearch(e.target.value)}
          />
          <Popover content="Filtros">
            <Button
              icon="filter"
              onClick={handleDrawer.toggle}
              className="table-filters-icon"
            />
          </Popover>
          {/* TODO: Refactor: dejar en lista de opciones y render con map */}
          {!documentsView ? (
            <>
              <div className="cv-skills-div-button">
                <Button icon="file-search" onClick={handleFiltersCvModal.on}>
                  Habilidades CV {totalSkills ? `(${totalSkills})` : null}
                </Button>
                {totalSkills ? (
                  <Popover content="Limpiar Habilidades CV">
                    <div
                      className="cv-skills-div-button__delete"
                      onClick={() =>
                        setCvFilters({
                          excSkills: [],
                          desSkills: [],
                        })
                      }
                    >
                      <Icon type="close" style={{ fontSize: '0.75rem' }} />
                    </div>
                  </Popover>
                ) : null}
              </div>
              <Popover
                placement="bottomRight"
                content={propsTableContent}
                title={
                  <Row
                    type="flex"
                    style={{ width: 300, padding: '0.5rem' }}
                    align="middle"
                  >
                    <Text strong>PROPIEDADES</Text>
                  </Row>
                }
              >
                <Button icon="table" className="table-tutorial-show-columns">
                  Mostrar en la tabla
                </Button>
              </Popover>
              {/* <Button
                icon="plus"
                type="primary"
                className="candidate-list-container--button"
                onClick={handleAddModal.on}
              >
                Añadir candidato
              </Button> */}
              {
                (businessPermissions.business_admin ||
                  businessPermissions.role?.can_create_and_manage_passive_candidates) &&
                <Dropdown overlay={addCandidatesDropdownMenu} placement="bottomRight">
                  <Button className="candidate-list-container--button" icon="plus" type="primary">
                    {i18n.t('actions__upload_candidates')}
                  </Button>
                </Dropdown>
              }
            </>
          ) : null}
        </Row>
      </Row>
      <Table
        className="candidates-table"
        columns={columns}
        dataSource={rows}
        loading={!dataOk}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: PAGE_SIZE_OPTIONS.map((option) => String(option)),
          pageSize: Number(pageSize),
          onShowSizeChange: (current, size) => {
            setPageSize(size);
          },
          hideOnSinglePage: false,
          current: Number(page),
          total: totalCandidatesToTable,
          locale: {
            items_per_page: 'por página',
          },
        }}
        onChange={handleTableChange}
        scroll={{ x: 1500, y: 380, scrollToFirstRowOnChange: true }}
        rowSelection={{
          // trick to avoid changing logic in the table
          selectedRowKeys: bulkPerStageConfig.allSelected
            ? rows.map((row, i) => i)
            : selectedRowKeys,
          onChange: onSelectRowChange,
          getCheckboxProps: (record) => ({
            disabled: bulkPerStageConfig.allSelected, //
          }),
        }}
        locale={{
          emptyText: <div style={{ display: 'none' }} />,
        }}
      />
      {rows.length === 0 && <Empty description="Sin información." />}

      <Filter
        onClose={handleDrawer.off}
        visible={showDrawer}
        queryFilters={queryFilters}
        changeFilters={changeFilters}
        setQueryFilters={setQueryFilters}
        candidatesState={candidatesState}
        discardReasons={discardReasons}
        queryColumns={queryColumns}
        setQueryColumns={setQueryColumns}
      />

      <MoveModal
        visible={showReclassifyModal}
        handleVisible={handleReclassifyModal}
        candidatesLength={selectedRowKeys.length}
        callback={moveCandidates}
        candidatesName={selectedRowKeys.map(
          (i) => candidatesToTable[i]?.full_name
        )}
        mode="reclassify"
        isStageBulk={bulkPerStageConfig.allSelected}
      />
      <MoveModal
        visible={showDiscardModal}
        handleVisible={handleDiscardModal}
        candidatesLength={selectedRowKeys.length}
        callback={discardCandidates}
        candidatesName={selectedRowKeys.map(
          (i) => candidatesToTable[i]?.full_name
        )}
        mode="discard"
        selectedCandidates={selectedCandidates}
        jobappid={jobAppId}
        discardReasons={discardReasons}
        isStageBulk={bulkPerStageConfig.allSelected}
      />
      <MoveModal
        visible={showMoveModal}
        handleVisible={handleMoveModal}
        candidatesLength={selectedRowKeys.length}
        callback={moveCandidates}
        jobStages={jobStages}
        stagesOnFocus={stagesOnFocus}
        mode="move"
        isStageBulk={bulkPerStageConfig.allSelected}
      />
      <PassiveCandidateFormModal
        actions={actions}
        businessId={business.id}
        countries={countries}
        getStageCandidates={getStageCandidates}
        handleVisible={handlePassiveModal}
        jobApplicationData={jobApplication}
        method="POST"
        setCandidates={setCandidates}
        stages={jobStages}
        stageOnFocus={stagesOnFocus}
        token={token}
        visible={showPassiveModal}
      />
      <InviteCandidateModal
        closeModal={handleInviteModal.off}
        jobApplication={{ id: jobAppId }}
        selectedPersonalUsers={selectedPersonalUsers}
        selectProcessesVisible={false}
        source="passive-gw"
        templates={mailTemplates ?? []}
        visible={showInviteModal}
      />
      {/* <AddInviteModal
       visible={showAddModal}
        handleModal={handleAddModal}
        mode="add"
        actions={actions}
        jobAppId={jobAppId}
        token={token}
      /> */}
      <ScheduleModal
        visible={showScheduleModal}
        handleVisible={handleScheduleModal.toggle}
        selectedCandidates={
          bulkPerStageConfig.allSelected
            ? bulkPerStageConfig.data
            : selectedRowKeys.map((index) => candidatesToTable[index])
        }
        stage={stagesOnFocus}
        jobApplication={jobApplication}
        interviewType="multi"
      />
      <DocsModal
        docTitle="foc"
        filesRequests={candidatesToTable[candidateIndexDocs]?.files_requests}
        stage={jobStages?.find(
          (stage) =>
            stage.code === candidatesToTable[candidateIndexDocs]?.status
        )}
        totalDocs={'foc-question'}
        candidate={candidatesToTable[candidateIndexDocs]}
        visible={candidateIndexDocs !== null}
        onCancel={() => setCandidateIndexDocs(null)}
      />
      {candidateDocsReset ? (
        <DocsResetModal
          visible={candidateDocsReset !== null}
          handleCancel={() => {
            setCandidateDocsReset(null);
            getStageCandidates(stagesOnFocus);
            onSelectRowChange([]);
          }}
          candidateDocsReset={candidateDocsReset}
          jobAppId={jobAppId}
          token={token}
          source="processtable"
        />
      ) : null}
      {showCvFiltersModal ? (
        <CvFilter
          jobAppId={jobAppId}
          candidates={candidatesToTable}
          checkedCandidates={selectedRowKeys.map((i) => candidatesToTable[i])}
          modalVisible={showCvFiltersModal}
          onCloseModal={handleFiltersCvModal.off}
          setCvFilterState={filterCvCandidates}
          filterState={cvFilters}
          loading={cvLoading}
          setLoading={setCvLoading}
        />
      ) : null}
      <MailModalV2
        selectedCandidates={showMailModal ? formatMailCandidates() : []}
        jobApplicationName={jobApplication.job_application}
        open={showMailModal}
        jobappid={jobAppId}
        openSnackHandle={(msg) => message.success(msg)}
        handleClose={handleMailModal.off}
        isConfidential={jobApplication?.is_confidential}
      />
      <PreviewDocument
        file={cv}
        visiblePreviewModal={cvModalFlag}
        handleOk={handleCvModal.off}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    business: state.auth.business,
    businessPermissions: state.auth.businessPermissions,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateTable);
