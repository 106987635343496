import React, { useEffect, useState, Fragment } from 'react'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actionCreators from '../../../actions/data'

import { message } from 'antd'
import './Feedback.scss'

import CandidateIntro from './components/CandidateIntro'
import Chapter from './components/Chapter'
import SliderProfile from './components/SliderProfile'
import ReasoningChart from './components/ReasoningChart'
import GameResults from './components/GameResults'
import SurveyElement from './components/SurveyElement'

import GameResultImage from '../../CandidateProfilev2/assets/old_assets/gameresults.png'
import CognitiveTraitsImage from '../../CandidateProfilev2/assets/old_assets/cogtraitgamesfeed.png'
import PersonalityTraitsImage from '../../CandidateProfilev2/assets/old_assets/personalitygamesfeed.png'
import ReasoningTraitsImage from '../../CandidateProfilev2/assets/old_assets/reasoninggamesfeed.png'
import i18n from '../../../i18n'

const ASSETS_URL = 'https://genoma-assets.s3.us-east-2.amazonaws.com'
const SHARE_URL = process.env.REACT_APP_SHARE_URL

const Feedback = (props) => {
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [satisfactionClicked, setSatisfactionClicked] = useState(false)
  // TODO: Deberíamos borrar este state? no se usa
  const [candidateData, setCandidateData] = useState({
    name: null,
    age: null,
    email: null,
    final_score: null,
    gender: null,
    personal_user_id: null,
    status: null,
    test_scores_feedback: {
      TLET: null,
      TNUM: null,
      TLOG: null,
      TEMO: null,
      THEX: null,
    },
    avatar_initials: 'GW'
  })

  useEffect(() => {
    const token = props.token;
    setCandidateData({ jobappid: props.match?.params['jobappid'] });
    const url = 'accounts/personal/';
    props.actions.fetchData(token, url, '', 'get').then(() => {
      setCandidateData({ data: props.data })
    })
  }, [])

  const openSnackHandle = () => {
    setOpenSnackbar(true)
  }

  const copyToClipboard = (id) => {
    navigator.clipboard.writeText(`${SHARE_URL}/scores/${id}`)
    openSnackHandle()
  }

  const shareFeedbackHandler = () => {
    const token = props.token;
    const url = 'accounts/personal/';
    props.actions.sendData(token, url, JSON.stringify({ share_feedback: true }), 'PATCH')
    copyToClipboard(props.data.id)
    message.success(i18n.t('commons__copy'))
  }

  const scoreSelectionHandler = (number) => {
    setSatisfactionClicked(true)
    const token = props.token;
    const url = 'accounts/personal/';
    props.actions.sendData(token, url, JSON.stringify({ satisfaction_survey: number }), 'PATCH')
  }

  const TNUM = props.reasoning_scores.find(rscore => {
    return rscore.trait_id.assessment_code === 'TNUM'
  })
  const TLET = props.reasoning_scores.find(rscore => {
    return rscore.trait_id.assessment_code === 'TLET'
  })
  const TLOG = props.reasoning_scores.find(rscore => {
    return rscore.trait_id.assessment_code === 'TLOG'
  })
  const TNU2 = props.reasoning_scores.find(rscore => {
    return rscore.trait_id.assessment_code === 'TNU2'
  })
  const TLE2 = props.reasoning_scores.find(rscore => {
    return rscore.trait_id.assessment_code === 'TLE2'
  })
  const TLO2 = props.reasoning_scores.find(rscore => {
    return rscore.trait_id.assessment_code === 'TLO2'
  })

  const gamesFunScores = []

  // Tower game scores
  if (props.fun_data.find(val => val.trait_id.code === 'EXTPLN' && val.trait_id.assessment_code === 'TOW2') ||
    props.fun_data.find(val => val.trait_id.code === 'CAPPLN' && val.trait_id.assessment_code === 'TOWR')) {
    const trait = props.fun_data.find(val => val.trait_id.code === 'EXTPLN' && val.trait_id.assessment_code === 'TOW2') ||
      props.fun_data.find(val => val.trait_id.code === 'CAPPLN' && val.trait_id.assessment_code === 'TOWR')
    gamesFunScores.push({
      upperTitle: i18n.t('feedback__towers__complete'),
      upperScore: trait.score,
      upperAvgScore: trait.trait_id.average_score,
      upperInverted: false,
      upperMeasure: '%',
      upperSpace: '',
      image: `${ASSETS_URL}/${trait.trait_id.assessment_code}.png`,
      alt: "tower game",
      titleImg: "tower game",
      titleCard: i18n.t('feedback__towers')
    });
  }

  // Memory game scores
  if (props.fun_data.find(val => val.trait_id.code === 'BLOSPA') ||
    props.fun_data.find(val => val.trait_id.code === 'MMTRCE')) {
    const trait = props.fun_data.find(val => val.trait_id.code === 'BLOSPA') ||
      props.fun_data.find(val => val.trait_id.code === 'MMTRCE')
    gamesFunScores.push({
      upperTitle: i18n.t('feedback__memory__level'),
      upperScore: trait.trait_id.code === 'BLOSPA' ? Math.round(trait.score * 9 / 100) : Math.round(trait.score * 15 / 100),
      upperAvgScore: trait.trait_id.code === 'BLOSPA' ? trait.trait_id.average_score : Math.round(trait.trait_id.average_score * 15 / 100),
      upperInverted: false,
      upperMeasure: trait.trait_id.code === 'BLOSPA' ? i18n.t('feedback__memory__squares') : i18n.t('commons__numbers'),
      upperSpace: '',
      image: `${ASSETS_URL}/${trait.trait_id.assessment_code}.png`,
      alt: "memory game",
      titleImg: "memory game",
      titleCard: i18n.t('feedback__memory')
    });
  }

  // Dice game scores
  if (props.fun_data.find(val => val.trait_id.code === 'ACRTTL' && val.trait_id.assessment_code === 'DCE2') ||
    props.fun_data.find(val => val.trait_id.code === 'ACRTTL' && val.trait_id.assessment_code === 'DICE')) {
    const trait = props.fun_data.find(val => val.trait_id.code === 'ACRTTL' && val.trait_id.assessment_code === 'DCE2') ||
      props.fun_data.find(val => val.trait_id.code === 'ACRTTL' && val.trait_id.assessment_code === 'DICE')
    gamesFunScores.push({
      upperTitle: i18n.t('feedback__hit__percentage'),
      upperScore: trait.score,
      upperAvgScore: trait.trait_id.average_score,
      upperInverted: false,
      upperMeasure: '%',
      upperSpace: '',
      image: `${ASSETS_URL}/${trait.trait_id.assessment_code}.png`,
      alt: "dice game",
      titleImg: "dice game",
      titleCard: i18n.t('feedback__dices')
    })
  }

  // Emotions game scores
  if (props.fun_data.find(val => val.trait_id.code === 'EMOTOT' && val.trait_id.assessment_code === 'TEM2') ||
    props.fun_data.find(val => val.trait_id.code === 'EMOTOT' && val.trait_id.assessment_code === 'TEMO')) {
    const trait = props.fun_data.find(val => val.trait_id.code === 'EMOTOT' && val.trait_id.assessment_code === 'TEM2') ||
      props.fun_data.find(val => val.trait_id.code === 'EMOTOT' && val.trait_id.assessment_code === 'TEMO')
    gamesFunScores.push({
      upperTitle: i18n.t('feedback__hit__percentage'),
      upperScore: trait.score,
      upperAvgScore: trait.trait_id.average_score,
      upperInverted: false,
      upperMeasure: '%',
      upperSpace: '',
      image: `${ASSETS_URL}/${trait.trait_id.assessment_code}.png`,
      alt: "emotion game",
      titleImg: "emotion game",
      titleCard: i18n.t('feedback__emotions')
    })
  }

  // Iowa game scores
  if (props.fun_data.find(val => val.trait_id.code === 'TTLRCL' && val.trait_id.assessment_code === 'IOW2') ||
    props.fun_data.find(val => val.trait_id.code === 'TTLRCL' && val.trait_id.assessment_code === 'IOWA')) {
    const trait = props.fun_data.find(val => val.trait_id.code === 'TTLRCL' && val.trait_id.assessment_code === 'IOW2') ||
      props.fun_data.find(val => val.trait_id.code === 'TTLRCL' && val.trait_id.assessment_code === 'IOWA')
    gamesFunScores.push({
      upperTitle: i18n.t('feedback__casino__money'),
      upperScore: trait.score,
      upperAvgScore: trait.trait_id.average_score,
      upperInverted: true,
      upperMeasure: '$',
      upperSpace: '',
      image: `${ASSETS_URL}/${trait.trait_id.assessment_code}.png`,
      alt: "casino game",
      titleImg: "casino game",
      titleCard: i18n.t('feedback__casino')
    })
  }

  // Baloon game scores
  if (props.fun_data.find(val => val.trait_id.code === 'BRTMNY') ||
    props.fun_data.find(val => val.trait_id.code === 'DECRSG')) {
    const trait = props.fun_data.find(val => val.trait_id.code === 'BRTMNY') ||
      props.fun_data.find(val => val.trait_id.code === 'DECRSG')
    gamesFunScores.push({
      upperTitle: i18n.t('feedback__casino__money'),
      upperScore: Math.round(trait.score * 1680 / 100),
      upperAvgScore: Math.round(trait.trait_id.average_score * 1680 / 100),
      upperInverted: true,
      upperMeasure: '$',
      upperSpace: '',
      image: `${ASSETS_URL}/${trait.trait_id.assessment_code}.png`,
      alt: "balloon game",
      titleImg: "balloon game",
      titleCard: i18n.t('feedback__balloons')
    })
  }

  // Traffic game scores
  if (props.fun_data.find(val => val.trait_id.code === 'CAPATC')) {
    const trait = props.fun_data.find(val => val.trait_id.code === 'CAPATC')
    gamesFunScores.push({
      upperTitle: i18n.t('feedback__correct__percentage'),
      upperScore: trait.score,
      upperAvgScore: trait.trait_id.average_score,
      upperInverted: false,
      upperMeasure: '%',
      upperSpace: '',
      image: `${ASSETS_URL}/${trait.trait_id.assessment_code}.png`,
      alt: "traffic game",
      titleImg: "traffic game",
      titleCard: i18n.t('feedback__traffic__light')
    })
  }

  // Recharge game scores
  if (props.fun_data.find(val => val.trait_id.code === 'DNRRCL')) {
    const trait = props.fun_data.find(val => val.trait_id.code === 'DNRRCL')
    gamesFunScores.push({
      upperTitle: i18n.t('feedback__casino__money'),
      upperScore: trait.score,
      upperAvgScore: trait.trait_id.average_score,
      upperInverted: true,
      upperMeasure: '$',
      upperSpace: '',
      image: `${ASSETS_URL}/${trait.trait_id.assessment_code}.png`,
      alt: "recharge game",
      titleImg: "recharge game",
      titleCard: i18n.t('feedback__recharge')
    })
  }

  // Stroop game scores
  if (props.fun_data.find(val => val.trait_id.code === 'ACRTTL' && val.trait_id.assessment_code === 'STR2')) {
    const trait = props.fun_data.find(val => val.trait_id.code === 'ACRTTL' && val.trait_id.assessment_code === 'STR2')
    gamesFunScores.push({
      upperTitle: i18n.t('feedback__correct__percentage'),
      upperScore: trait.score,
      upperAvgScore: trait.trait_id.average_score,
      upperInverted: false,
      upperMeasure: '%',
      upperSpace: '',
      image: `${ASSETS_URL}/${trait.trait_id.assessment_code}.png`,
      alt: "stroop game",
      titleImg: "stroop game",
      titleCard: i18n.t('feedback__colors')
    })
  }

  let personality_scores = []
  if (props.personality_scores_v3 && props.personality_scores_v3.length > 0) {
    personality_scores = props.personality_scores_v3;
  } else if (props.personality_scores_v2 && props.personality_scores_v2.length > 0) {
    personality_scores = props.personality_scores_v2;
  } else if (props.personality_scores_v1 && props.personality_scores_v1.length > 0) {
    personality_scores = props.personality_scores_v1;
  }

  return (
    <div className='feedback-main-container'>
      {props.userType === 'business' && <Redirect to="/processes" />}

      <CandidateIntro
        fullName={props.name}
        shareFeedback={shareFeedbackHandler}
      />

      <Chapter
        title={i18n.t('feedback__results__title')}
        description={i18n.t('feedback__results__introductions')}
        bigImage={GameResultImage}
      />

      <div className='feedback-game-results'>
        {gamesFunScores.map((game) => (
          <div
            key={game.titleCard}
          >
            <GameResults
              style={{ height: '100%' }}
              upperTitle={game.upperTitle}
              upperScore={game.upperScore}
              upperAvgScore={game.upperAvgScore}
              upperInverted={game.upperInverted}
              upperMeasure={game.upperMeasure}
              upperSpace={game.upperSpace}
              lowerScore={game.lowerScore}
              image={game.image}
              alt={game.alt}
              titleImg={game.titleImg}
              titleCard={game.titleCard}
            />
          </div>
        ))}

      </div>

      {props.game_scores && props.game_scores.length > 0 ?
        <div className='feedback-slider-container'>
          <Chapter
            title={i18n.t('feedback__emotions__and__cognitive__traits')}
            description={i18n.t('feedback__c_e_traits__text')}
            bigImage={CognitiveTraitsImage}
          />
          <SliderProfile title={i18n.t('feedback__personality__profile')} traits={props.game_scores || []} />
        </div> : null
      }

      {(TLET || TNUM || TLOG || TLE2 || TNU2 || TLO2) ?
        <div className='reasoning-container'>
          <Chapter
            title={i18n.t('feedback__reasoning__skills')}
            description={i18n.t('feedback__reasoning__skills__introduction')}
            bigImage={ReasoningTraitsImage}
          />
          <ReasoningChart
            verbalReasoning={TLE2 ? TLE2.score : (TLET ? TLET.score : null)}
            verbalAverage={TLE2 ? TLE2.trait_id.average_score : (TLET ? TLET.trait_id.average_score : null)}
            logicReasoning={TLO2 ? TLO2.score : (TLOG ? TLOG.score : null)}
            logicAverage={TLO2 ? TLO2.trait_id.average_score : (TLOG ? TLOG.trait_id.average_score : null)}
            numericalReasoning={TNU2 ? TNU2.score : (TNUM ? TNUM.score : null)}
            numericalAverage={TNU2 ? TNU2.trait_id.average_score : (TNUM ? TNUM.trait_id.average_score : null)}
          />
        </div>
        : null
      }

      {personality_scores.length > 0 && (
        <div className='personality-slider'>
          <Chapter
            title={i18n.t('feedback__personality__profile')}
            description={i18n.t('feedback__personality__profile__introduction')}
            bigImage={PersonalityTraitsImage}
          />
          <SliderProfile title={i18n.t('feedback__personality__profile')} traits={personality_scores} />
        </div>
      )}

      {props.satisfaction_survey === 0 ?
        <SurveyElement
          scoreSelectionHandler={scoreSelectionHandler}
        />
        : null
      }
    </div>
  )
}

const mapStateToProps = (state) => {

  let total_scores = []

  const bar2_game = state.data.data.game_scores.filter(score => score.trait_id.assessment_code === 'BAR2')
  const bart_game = state.data.data.game_scores.filter(score => score.trait_id.assessment_code === 'BART')
  const tow2_game = state.data.data.game_scores.filter(score => score.trait_id.assessment_code === 'TOW2')
  const towr_game = state.data.data.game_scores.filter(score => score.trait_id.assessment_code === 'TOWR')
  const gng2_game = state.data.data.game_scores.filter(score => score.trait_id.assessment_code === 'GNG2')
  const gng_game = state.data.data.game_scores.filter(score => score.trait_id.assessment_code === 'GNG')
  const tem2_game = state.data.data.game_scores.filter(score => score.trait_id.assessment_code === 'TEM2')
  const temo_game = state.data.data.game_scores.filter(score => score.trait_id.assessment_code === 'TEMO')
  const mem2_game = state.data.data.game_scores.filter(score => score.trait_id.assessment_code === 'MEM2')
  const intt_game = state.data.data.game_scores.filter(score => score.trait_id.assessment_code === 'INTT')

  if (bar2_game.length > 0) {
    total_scores = [...(bar2_game.filter(score => score.trait_id.code === 'AVRRSG'))]
  } else if (bart_game.length > 0) {
    total_scores = [...(bart_game.filter(score => score.trait_id.code === 'AVRRSG'))]
  }

  if (tem2_game.length > 0) {
    total_scores = [...total_scores, ...(tem2_game.filter(score => score.trait_id.code === 'EMOTOT'))]
  } else if (temo_game.length > 0) {
    total_scores = [...total_scores, ...(temo_game.filter(score => score.trait_id.code === 'EMOCOR'))]
  }

  if (gng2_game.length > 0) {
    total_scores = [...total_scores, ...(gng2_game.filter(score => ['CAPATC', 'CTRIMP'].find((val) => val === score.trait_id.code)))]
  } else if (gng_game.length > 0) {
    total_scores = [...total_scores, ...(gng_game.filter(score => ['DRCATC', 'IMPERR'].find((val) => val === score.trait_id.code)))]
  }

  if (tow2_game.length > 0) {
    total_scores = [...total_scores, ...(tow2_game.filter(score => ['EXTPLN'].find((val) => val === score.trait_id.code)))]
  } else if (towr_game.length > 0) {
    total_scores = [...total_scores, ...(towr_game.filter(score => score.trait_id.code === 'CAPPLN'))]
  }
  if (mem2_game.length > 0) {
    total_scores = [...total_scores, ...(mem2_game.filter(score => ['ADBLSP'].find((val) => val === score.trait_id.code)))]
  }
  if (intt_game.length > 0) {
    total_scores = [...total_scores, ...intt_game];
  }

  total_scores = total_scores.filter(score => score.trait_id.status === 'ACTIV')

  return {
    token: state.auth.token,
    userType: state.auth.userType,
    data: state.data.data,
    isFetching: state.data.isFetching,
    error: state.data.error,
    satisfaction_survey: state.data.data.satisfaction_survey,
    name: state.data.data.name + " " + state.data.data.surname,
    reasoning_scores: state.data.data.game_scores.filter(score => score.trait_id.category == "RAZONA"),
    personality_scores_v1: state.data.data.personality_scores && state.data.data.personality_scores.filter(trait => trait.trait_id.status === 'ACTIV' && trait.trait_id.assessment_code === 'THEX' && trait.trait_id.code !== 'HONES'),
    personality_scores_v2: state.data.data.personality_scores && state.data.data.personality_scores.filter(trait => trait.trait_id.status === 'ACTIV' && trait.trait_id.assessment_code === 'THE2' && trait.trait_id.code !== 'HONES'),
    personality_scores_v3: state.data.data.personality_scores && state.data.data.personality_scores.filter(trait => trait.trait_id.status === 'ACTIV' && trait.trait_id.assessment_code === 'TPCG'),
    cognitive_traits: state.data.data,
    game_scores: total_scores,
    fun_data: state.data.data.game_scores.filter(score => ['CAPPLN', 'DECRSG', 'MMTRCE', 'ACRTTL', 'EMOTOT', 'TTLRCL', 'CAPATC', 'DNRRCL', 'ACRTTL', 'BLOSPA', 'BRTMNY', 'EXTPLN'].find((val) => val === score.trait_id.code)),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Feedback)
