import React, { Fragment } from 'react';

import {
  Button,
  Col,
  Icon,
  Layout,
  Row,
  Typography
} from 'antd';


const { Text } = Typography;


const BulkActions = (props) => {
  const { bulkResendInvitation, bulkRevoke, handleAllRowsSelected, selectedRows } = props;

  return selectedRows.length ? (
    <Fragment>
      <Layout.Footer
        className='table__footer'
      >
        <Row className='table__actions' type='flex' align='middle' justify='space-between'>
          <Col className='left'>
            <Text strong>
              <Button
                className='close-footer'
                icon='close'
                onClick={() => handleAllRowsSelected(false)}
              />
              {selectedRows.length} seleccionados
            </Text>
          </Col>
          <Col className='right'>
            <Button onClick={bulkRevoke} style={{ marginRight: '1em' }}>
              <Icon type="delete" /> Desactivar permisos 
            </Button>
            <Button onClick={bulkResendInvitation}>
              <Icon type="mail" /> Reenviar correo de invitación
            </Button>
          </Col>
        </Row>
      </Layout.Footer>
    </Fragment>
  ) : null;
};

export default BulkActions;
