import React, { useContext } from 'react';
import {
  Layout,
  Row,
  Typography,
  Radio,
  Col,
  Button,
  Switch,
  Input,
} from 'antd';

import { customTestContext } from '../../context/CustomTestContext';
import { textLevels } from '../../utils';
import CustomMultiRangeSlider from '../../../../../components/MutiRangeSlider/CustomMultiRangeSlider';

const { Text } = Typography;

const Step3 = ({ cuteType }) => {
  const {
    multiple,
    ranges,
    setRanges,
    segments,
    setSegments,
    thumbs,
    setThumbs,
    scoreConfig,
    setScoreConfig,
    levels,
    setLevels,
    textDescriptions,
    setTextDescription,
    colorDescriptions,
    showDescriptions,
    setShowDescriptions,
    changeSegments,
  } = useContext(customTestContext);
  const changeLevels = (e) => {
    setLevels(e.target.value);
  };
  const viewDescription = (e) => {
    setShowDescriptions(e);
    if (e === false) {
      scoreConfig.segments.map((segment) => {
        delete segment['description'];
      });
    } else {
      scoreConfig.segments.map((segment, i) => {
        segment.description = textLevels[levels][i].text;
      });
    }
  };

  const changeTitleText = (e, i) => {
    if (scoreConfig.segments[i].label.length < 20) {
      scoreConfig.segments[i].label = e.target.value;
    }
    setScoreConfig({ ...scoreConfig });
  };
  const changeDescriptionText = (e, i) => {
    scoreConfig.segments[i].description = e.target.value;
    setScoreConfig({ ...scoreConfig });
  };

  const path = window.location.pathname.slice(17);

  const questions_to_evaluate = cuteType === 'situationals' ? 100 : Object.values(scoreConfig.correct_answers).filter(
    (q) => q.type !== 'text'
  ).length;

  const isLevelDisabled = (level) => {
    if (cuteType === 'situationals') {
      return scoreConfig.total_max_score < level;
    }
    return questions_to_evaluate <= (level * 2) - 2 ;
  }

  return (
    <Layout style={{ margin: '0 0 200px 0' }}>
      <Row style={{ border: '1px solid #D9D9D9' }}>
        <Row
          style={{
            padding: '10px 20px',
            background: '#F5F5F5',
            borderBottom: '1px solid #D9D9D9',
          }}
        >
          <Text strong style={{ margin: '0' }}>
            Niveles de evaluación
          </Text>
        </Row>
        <Row style={{ background: 'white', padding: '20px' }}>
          <Row type="flex" style={{ flexDirection: 'column' }}>
            {path !== 'new' && (
              <Text strong style={{ margin: '0px 0 15px 0' }}>
                Recuerda volver a configurar tus segmentos cada vez que editas o
                copias una prueba personalizada
              </Text>
            )}
            <Text>
              Los niveles de evaluación ubica a los evaluados según su desempeño
              en la prueba. Selecciona los niveles que necesites para evaluar a
              los candidatos:
            </Text>
            <Radio.Group
              value={levels}
              style={{ margin: '10px 0 0 0' }}
              onChange={changeLevels}
            >
              {[2, 3, 4, 5].map((level) => 
                <Radio
                  key={level}
                  value={level}
                  disabled={isLevelDisabled(level)}
                >
                {level} niveles
                </Radio>
              )}
            </Radio.Group>
            <Text style={{ margin: '30px 0 16px 0' }}>
              Según el total de preguntas que configuraste, desliza los niveles
              que necesites:
            </Text>
          </Row>
          <Row>
            {questions_to_evaluate > 2 ? (
              <CustomMultiRangeSlider
                levels={levels}
                cuteType={cuteType}
                height={20}
                ranges={ranges}
                setRanges={setRanges}
                segs={segments}
                setSegs={setSegments}
                thumbs={thumbs}
                setThumbs={setThumbs}
                multiple={multiple}
                onChange={changeSegments}
                questionNumber={questions_to_evaluate}
              />
            ) : (
              <Text strong>
                Debes agregar al menos <u>3 preguntas de selección</u> para
                configurar tus segmentos
              </Text>
            )}
          </Row>
          {questions_to_evaluate > 2 ? (
            <React.Fragment>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                style={{ margin: '60px 0 0 0' }}
              >
                <Button
                  type="link"
                  icon="edit"
                  style={{ padding: '0', margin: '0 0 10px 0' }}
                  onClick={() => setTextDescription(!textDescriptions)}
                >
                  {textDescriptions
                    ? 'Guardar textos descriptivos'
                    : 'Editar textos descriptivos'}
                </Button>
                <Switch
                  style={{ margin: '0 0 10px 0' }}
                  checkedChildren="Mostrar descripciones"
                  unCheckedChildren="Mostrar descripciones"
                  defaultChecked={true}
                  onChange={viewDescription}
                />
              </Row>

              <Row>
                {scoreConfig.segments.map((level, i) => (
                  <Row type="flex" key={i}>
                    <Col
                      span={showDescriptions ? 6 : 24}
                      style={{
                        border: `1px solid ${colorDescriptions[i].border}`,
                        background: `${colorDescriptions[i].background}`,
                        height: '62px',
                      }}
                    >
                      <Row
                        type="flex"
                        justify="center"
                        align="middle"
                        style={{ height: '100%' }}
                      >
                        {textDescriptions ? (
                          <Input
                            value={level.label}
                            onChange={(e) => changeTitleText(e, i)}
                            style={{ textAlign: 'center' }}
                          />
                        ) : (
                          <Text strong style={{ fontSize: '14px' }}>
                            {level.label}
                          </Text>
                        )}
                      </Row>
                    </Col>
                    {showDescriptions ? (
                      <Col span={18}>
                        <Row
                          type="flex"
                          justify="center"
                          align="middle"
                          style={{
                            height: '100%',
                            height: '62px',
                            padding: '5px 12px',
                            border: '1px solid #D9D9D9',
                            borderLeft: 'none',
                          }}
                        >
                          <Col span={24}>
                            {textDescriptions ? (
                              <Input
                                value={level.description}
                                onChange={(e) => changeDescriptionText(e, i)}
                              />
                            ) : (
                              <Text style={{ fontSize: '14px' }}>
                                {level.description}
                              </Text>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    ) : null}
                  </Row>
                ))}
              </Row>
            </React.Fragment>
          ) : null}
        </Row>
      </Row>
    </Layout>
  );
};

export default Step3;
