import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { push } from 'react-router-redux';

import PropTypes from "prop-types";
import * as actionCreators from "../../actions/data"
import './BusinessPermissionManager.scss';

import {
  Avatar,
  Breadcrumb,
  Badge,
  Button,
  Card,
  Col,
  Icon,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Table,
  Tooltip,
  Typography,
} from 'antd';

import BulkActions from './Plugins/BulkActions';
import InviteToBusinessModal from './Plugins/InviteToBusinessModal';
import EditCollaboratorPermissionsModal from './Plugins/EditCollaboratorPermissionsModal';

import {
  businessPermissionName,
  businessPermissionStatusName,
} from './Plugins/utils';

const { Column } = Table;
const { Text, Title } = Typography;
const { Search } = Input;

const permissionsFilterList = [
  {
    'text': 'Administrar',
    'value': 'business_admin'
  },
  {
    'text': 'Gestionar marca empleadora',
    'value': 'can_manage'
  },
  {
    'text': 'Invitar',
    'value': 'can_invite'
  },
  {
    'text': 'Acceso a todos los procesos',
    'value': 'can_view_all_jobapps'
  },
  {
    'text': 'Sin permisos',
    'value': 'None'
  },
];

const statusFilterList = [
  {
    'text': 'Activo',
    'value': 'ACT',
  },
  {
    'text': 'Pendiente',
    'value': 'PND',
  },
  {
    'text': 'Revocado',
    'value': 'REV',
  }
];

const BusinessPermissionManager = (props) => {
  const [showInvitationModal, setShowInvitationModal] = useState(false);
  const [showEditPermissionsModal, setShowEditPermissionsModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filterInvitedBy, setFilterInvitedBy] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [searchInput, setSearchInput] = useState();
  const [roles, setRoles] = useState([]);

  const [currentCollaborator, setCurrentCollaborator] = useState(null);

  const statusColors = {
    'Activo': 'green',
    'Pendiente': 'yellow',
    'Revocado': 'red',
  };

  useEffect(() => {
    let endpointRoles = `/api/v1/permissions/roles/`;
    props.actions.fetchAndReturn(
      props.token, endpointRoles
    ).then(response => {
      if (response.status < 400) {
        setRoles(response.body.results)
      }
    });

    let endpoint = `/api/v1/permissions/business/${props.business.id}/`;
    props.actions.fetchAndReturn(
      props.token, endpoint
    ).then(response => {
      if (response.status < 400) {
        let data = response.body.results.map(obj => {
          const businessUserData = obj.business_user ? {
            ...obj.business_user,
            name: obj.business_user.name ? obj.business_user.name : obj.business_user.email,
          } : {};
          const statusData = obj.status ? {
            code: obj.status,
            text: businessPermissionStatusName[obj.status],
          } : {};
          const invitedByData = obj.created_by ? ({
            id: obj.created_by.id,
            name: obj.created_by.length > 0 ? obj.created_by.name : obj.created_by.email,
          }) : {
            id: 'GW',
            name: 'Genomawork',
          };
          return {
            key: businessUserData.id,
            businessUser: businessUserData,
            businessPermissions: {
              business_admin: obj.business_admin,
              can_manage: obj.can_manage,
              can_invite: obj.can_invite,
              can_create_cell: obj.can_create_cell,
              can_view_all_jobapps: obj.can_view_all_jobapps,
              text: businessPermissionName(obj),
              role: obj.role,
            },
            status: statusData,
            invitedBy: invitedByData,
            invitationDate: obj.date_created,
          };
        });
        setTableData(data);
        let inviters = {};
        for (let i = 0; i < data.length; i++) {
          const obj = data[i];
          if (Object.keys(inviters).includes(obj.invitedBy.id))
            continue;
          inviters[obj.invitedBy.id] = {
            text: obj.invitedBy.name,
            value: obj.invitedBy.id,
          }
        }
        setFilterInvitedBy(Object.values(inviters));
      }
    });
  }, [props.business]);

  const addBusinessPermissionData = (data) => {
    const businessPermissionData = [...tableData, ...data];
    setTableData([...businessPermissionData]);
  }

  const updateBusinessPermissionData = (businessUserId, permissions) => {
    setTableData((prevTableData) => {
      const businessPermissionData = [...prevTableData];
      for (let i = 0; i < businessPermissionData.length; i++) {
        const rowData = businessPermissionData[i];
        if (rowData.businessUser.id == businessUserId) {
          businessPermissionData[i] = {
            ...rowData,
            status: {
              code: permissions.status,
              text: businessPermissionStatusName[permissions.status],
            },
            businessPermissions: {
              business_admin: permissions.business_admin,
              can_manage: permissions.can_manage,
              can_invite: permissions.can_invite,
              can_create_cell: permissions.can_create_cell,
              can_view_all_jobapps: permissions.can_view_all_jobapps,
              text: businessPermissionName(permissions),
              role: permissions.role,
            }
          };
        }
      }
      return businessPermissionData;
    });
  };

  const handleRowsSelected = obj => {
    const prevSelected = selectedRowsData;
    if (prevSelected.includes(obj))
      setSelectedRowsData(
        prevSelected.filter(
          (element) => element.businessUser.id !== obj.businessUser.id
        )
      );
    else
      setSelectedRowsData([...prevSelected, obj]);
  }

  const handleAllRowsSelected = (allRowsSelected, selectedRows, changeRows) => {
    if (allRowsSelected)
      setSelectedRowsData(selectedRows);
    else
      setSelectedRowsData([]);
  }

  const loggedInUserPermissions = props.businessPermissions;
  const canCreateBusinessPermissions = loggedInUserPermissions.role?.can_invite_business_users;
  const canManageBusinessPermission = (perm) => (
    loggedInUserPermissions.business_admin ||
    (loggedInUserPermissions.can_invite && !perm.businessPermissions.business_admin && perm.invitedBy.id == props.userID)
  );

  const bulkRevoke = () => {
    // TODO: MEJORAR ESTILO DE MENSAJES
    // TODO: ARREGLAR TOOLTIPS FEOS
    let loadingMsg;
    let loadingMsgConfig = {
      duration: 0,
      key: "loadingMsg-bulkRevoke",
    };
    let resultMsgConfig = {
      duration: 5,
    }

    const data = {
      business_admin: false,
      can_manage: false,
      can_invite: false,
      can_create_cell: false,
      can_view_all_jobapps: false,
      status: 'REV',
    };
    const updatableRows = loggedInUserPermissions.business_admin ? (
      selectedRowsData.filter(row => row.businessUser.id !== props.userID)
    ) : (
      selectedRowsData.filter(row => (
        row.businessUser.id !== props.userID && row.invitedBy.id === props.userID
      ))
    );
    if (selectedRowsData.filter(row => row.businessUser.id === props.userID).length > 0) {
      message.warning({
        duration: 5,
        content: "No puedes desactivar tu propio permiso.",
      });
    }
    if (updatableRows.length > 0) {
      loadingMsgConfig['content'] = `Deshabilitando acceso a Genomawork para ${updatableRows.length} colaboradores.`;
      loadingMsg = message.loading(loadingMsgConfig);
    } else {
      resultMsgConfig['content'] = "No es posible deshabilitar los permisos de los colaboradores seleccionados."
      message.error(resultMsgConfig);
      return;
    }

    for (let i = 0; i < updatableRows.length; i++) {
      const { businessUser } = selectedRowsData[i];
      const url = `/api/v1/permissions/business/${props.business.id}/users/${businessUser.id}/`;
      // No desactivar permiso propio
      if (businessUser.id !== props.userID) {
        const businessUserName = businessUser.name || businessUser.email;
        props.actions.fetchAndReturn(props.token, url, 'PATCH', data).then((response) => {
          if (response.status >= 400) {
            resultMsgConfig['content'] = `No fue posible deshabilitar el acceso para ${businessUserName}.`;
            message.error(resultMsgConfig);
          } else {
            const { body } = response;
            const newData = {
              business_admin: body.business_admin,
              can_manage: body.can_manage,
              can_invite: body.can_invite,
              can_create_cell: body.can_create_cell,
              can_view_all_jobapps: body.can_view_all_jobapps,
              status: body.status,
            }
            updateBusinessPermissionData(businessUser.id, newData);
          }
        })
      }
    }
    message.destroy("loadingMsg-bulkRevoke");
    resultMsgConfig['content'] = `¡Acceso a Genomawork deshabilitado!`;
    message.success(resultMsgConfig);
    setSelectedRowsData([]);
  };

  const bulkResendInvitation = () => {
    let loadingMsgConfig = {
      duration: 0,
      key: "loadingMsg-bulkResendInvitation",
    };
    let resultMsgConfig = {
      duration: 5,
    }

    loadingMsgConfig['content'] = `Reenviando correo de invitación a Genomawork a ${selectedRowsData.length} colaboradores.`;
    loadingMsg = message.loading(loadingMsgConfig);

    const url = `/api/v1/accounts/resendinvitationemail/?template=d-a32a73c0301540389d612d72c9e07442&emails=(${selectedRowsData.map(({ businessUser }) => businessUser.email).join(',')
      })`;
    props.actions.fetchAndReturn(props.token, url, 'GET').then((response) => {
      message.destroy("loadingMsg-bulkResendInvitation");
      if (response.status >= 400) {
        resultMsgConfig['content'] = `No fue posible enviar los correos.`;
        message.error(resultMsgConfig);
      } else {
        const { body } = response;
        resultMsgConfig['content'] = `¡${body.emails_sent} correos enviados exitósamente!`;
        message.success(resultMsgConfig);
      }
    });
    setSelectedRowsData([]);
  };

  const inviteDisabled = (
    props.contract.max_subordinates !== null &&
    tableData.filter(obj => obj.status.code != 'REV').length >= props.contract.max_subordinates
  )

  return (
    <Layout>
      <Row className="gw-bm">
        <InviteToBusinessModal
          roles={roles}
          showInvitationModal={showInvitationModal}
          setShowInvitationModal={setShowInvitationModal}
          sendData={props.actions.fetchAndReturn}
          token={props.token}
          businessId={props.business.id}
          addBusinessPermissionData={addBusinessPermissionData}
          isBusinessAdmin={props.businessPermissions.business_admin}
        />
        <EditCollaboratorPermissionsModal
          roles={roles}
          showEditPermissionsModal={showEditPermissionsModal}
          setShowEditPermissionsModal={setShowEditPermissionsModal}
          currentCollaborator={currentCollaborator}
          sendData={props.actions.fetchAndReturn}
          token={props.token}
          businessId={props.business.id}
          updateBusinessPermissionData={updateBusinessPermissionData}
          isBusinessAdmin={props.businessPermissions.business_admin}
        />

        <Breadcrumb separator=">" style={{ margin: 24 }}>
          <Breadcrumb.Item href="/home">Inicio</Breadcrumb.Item>
          <Breadcrumb.Item>Usuarios</Breadcrumb.Item>
        </Breadcrumb>

        <Card
          title={
            <Row type="flex" justify="space-between">
              <Title>Usuarios</Title>
            </Row>
          }
        >
          <Row className='collaborators-avatar' type="flex" align="middle" style={{ marginBottom: "2em" }}>
            <Col
              style={{ marginRight: "0.1em" }}
            >
              {/* TODO: Averigüar de que depende el color del avatar */}
              <Avatar size={60}>{props.userAvatar}</Avatar>
            </Col>
            <Col
              style={{ paddingLeft: 15 }}
            >
              <Row>
                <Text
                  style={{ fontSize: "1.25em" }}
                  strong
                >
                  {props.business.name}
                </Text>
              </Row>
              <Row>
                <Text
                  style={{ fontWeight: 400, color: "#A4A4A4" }}
                >
                  {tableData.filter(obj => obj.status.code != 'REV').length} colaboradores de un máximo de {props.contract.max_subordinates ?? 10}
                  <Tooltip
                    mouseEnterDelay={0.01}
                    placement="right"
                    title={
                      <span>
                        Considerando invitaciones <strong>pendientes y aceptadas</strong>
                      </span>
                    }
                  >
                    <Icon style={{ marginLeft: "5px" }} type="question-circle" />
                  </Tooltip>
                </Text>
              </Row>
            </Col>
          </Row>

          <Row type="flex" justify="space-between" style={{ marginBottom: "2em" }}>
            <Row type="flex" justify="left" style={{ width: '100%' }}>
              <Col sm={10} md={8} style={{ paddingRight: 10, paddingTop: 10 }}>
                <Search
                  placeholder='Buscar usuario'
                  style={{ minWidth: "100%" }}
                  onSearch={(input) => setSearchInput(input)}
                  onChange={(event) => setSearchInput(event.target.value)}
                />
              </Col>
              <Col sm={8} md={4} style={{ paddingTop: 10 }}>
                {
                  /**
                                   <Button
                  onClick={() => props.dispatch(push('/business/roles'))}
                >
                  Roles y permisos
                </Button>
                   */
                }
              </Col>
              {canCreateBusinessPermissions &&
                <Col sm={6} md={12} style={{ paddingTop: 10 }}>
                  <Row type="flex" justify="end" style={{ width: '100%' }}>
                    <Button
                      onClick={() => !inviteDisabled ? setShowInvitationModal(true) : (
                        Modal.warning({
                          "title": "Máximo de colaboradores alcanzado",
                          "content": "Contáctate con tu ejecutivo/a de cuenta a cargo para revisar la opción de añadir más colaboradores."
                        })
                      )}
                      type='primary'
                    >
                      <Icon type="plus" style={{ color: 'FFFFFF' }} />
                      Agregar usuario
                    </Button>
                  </Row>
                </Col>
              }
            </Row>
            {/* TODO: Genomín */}
          </Row>

          <Table
            key="BusinessPermissionTable"
            dataSource={tableData.filter(
              (rowData) => {
                if (!searchInput)
                  return true;
                return (
                  rowData.businessUser.name.includes(searchInput) ||
                  rowData.businessUser.email.includes(searchInput)
                )
              }
            )}
            pagination={{ position: 'bottom' }}
            rowSelection={{
              onSelect: handleRowsSelected,
              onSelectAll: handleAllRowsSelected,
              selectedRowKeys: selectedRowsData.map((obj) => obj.businessUser.id),
            }}
            locale={{
              emptyText: (
                <div style={{ display: 'grid' }}>
                  <span role="img" style={{ fontSize: 100, color: 'black' }}><img src="https://genoma-assets.s3.us-east-2.amazonaws.com/genomin_2.svg" alt="genomin" /></span>
                  <Text>No se encontraron colaboradores</Text>
                </div>
              )
            }}
          >
            <Column
              width={300}
              className="custom-table-title"
              key="collaborator-column"
              title="USUARIO"
              dataIndex="businessUser"
              sorter={(a, b) => {
                if (a.businessUser.name.toLowerCase() < b.businessUser.name.toLowerCase())
                  return -1
                else if (a.businessUser.name.toLowerCase() > b.businessUser.name.toLowerCase())
                  return 1
                else
                  return 0
              }}
              render={(obj) => ([
                <Row key="collaborator-name">
                  <Text strong>{obj['name']}</Text>
                </Row>,
                <Row key="collaborator-email">
                  {/* TODO: CUADRITO CON FLECHITA (PERO A DONDE LLEVA?) */}
                  <Text type='secondary'>{obj['email']}</Text>
                </Row>
              ])}
            />
            <Column
              width={250}
              className="custom-table-title"
              key="permission-column"
              title={[<Text key="title" style={{ marginRight: '0.5em' }}>ROL</Text>,]}
              dataIndex='businessPermissions.role.name'
              sorter={(a, b) => {
                const firstPerm = a.businessPermissions.text.toLowerCase();
                const secondPerm = b.businessPermissions.text.toLowerCase();
                if (firstPerm < secondPerm)
                  return -1
                else if (firstPerm > secondPerm)
                  return 1
                else
                  return 0
              }}
              filters={permissionsFilterList}
              onFilter={(value, record) => value != 'None' ? record.businessPermissions[value] : !Object.values(record.businessPermissions).includes(true)}
              render={(obj, record) => {
                return (
                  (canManageBusinessPermission(record) && record.businessUser.id !== props.userID) ? (
                    <>
                      {
                        record.businessPermissions?.role?.name}
                      <Icon
                        style={{ marginLeft: 15 }}
                        type="edit"
                        onClick={() => {
                          setCurrentCollaborator(record)
                          setShowEditPermissionsModal(true)
                        }
                        }
                      />
                    </>
                  ) : (
                    <Row
                      type="flex"
                      align="middle"
                      style={{
                        color: "#5E5E5E",
                      }}
                    >
                      <Col>{obj}</Col>
                    </Row>
                  )
                )
              }}
            />
            <Column
              className="custom-table-title"
              key="status-column"
              title="ESTADO"
              dataIndex="status.text"
              sorter={(a, b) => {
                const firstStatus = a.status.text.toLowerCase();
                const secondStatus = b.status.text.toLowerCase();
                if (firstStatus < secondStatus)
                  return -1
                else if (firstStatus > secondStatus)
                  return 1
                else
                  return 0
              }}
              filters={statusFilterList}
              onFilter={(value, record) => record.status.code === value}
              defaultFilteredValue={['ACT', 'PND']}
              render={(obj, record) =>
                <Row type="flex" align="middle">
                  <Badge color={statusColors[obj]} />
                  <Text>{obj}</Text>
                </Row>
              }
            />
            <Column
              width={300}
              className="custom-table-title"
              key="invited-by-column"
              title="INVITADO POR"
              dataIndex="invitedBy.name"
              sorter={(a, b) => {
                if (a.invitedBy.name.toLowerCase() < b.invitedBy.name.toLowerCase())
                  return -1
                else if (a.invitedBy.name.toLowerCase() > b.invitedBy.name.toLowerCase())
                  return 1
                else
                  return 0
              }}
              filters={filterInvitedBy}
              onFilter={(value, record) => record.invitedBy.id === value}
            />
            <Column
              className="custom-table-title"
              key="invitation-date-column"
              title="FECHA DE INVITACIÓN"
              dataIndex="invitationDate"
              sorter={(a, b) => {
                const firstDate = new Date(a.invitationDate.slice(6), a.invitationDate.slice(3, 5), a.invitationDate.slice(0, 2))
                const secondDate = new Date(b.invitationDate.slice(6), b.invitationDate.slice(3, 5), b.invitationDate.slice(0, 2));
                if (firstDate < secondDate)
                  return -1
                else if (firstDate > secondDate)
                  return 1
                else
                  return 0
              }}
              render={(obj) => <Text>{`${obj}`}</Text>}
            />
          </Table>
          <Text
            style={{ marginTop: -43, position: "absolute" }}
          >
            <strong>{selectedRowsData.length}</strong> colaboradores seleccionados
          </Text>
        </Card>

        <BulkActions
          bulkResendInvitation={bulkResendInvitation}
          bulkRevoke={bulkRevoke}
          handleAllRowsSelected={handleAllRowsSelected}
          selectedRows={selectedRowsData}
        />
      </Row>
    </Layout>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch
  };
};

const mapStateToProps = (state) => {
  return {
    businessPermissions: state.auth.businessPermissions,
    business: state.auth.currentBusiness,
    contract: state.auth.contract,
    token: state.auth.token,
    userAvatar: state.auth.userAvatar,
    userID: state.auth.userID,
    userName: state.auth.userName,
  };
};

BusinessPermissionManager.propTypes = {
  userAvatar: PropTypes.string.isRequired,
  userID: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  businessPermissions: PropTypes.shape({
    business_admin: PropTypes.bool.isRequired,
    can_manage: PropTypes.bool.isRequired,
    can_invite: PropTypes.bool,
    can_create_cell: PropTypes.bool,
    can_view_all_jobapps: PropTypes.bool,
  }),
  business: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  token: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessPermissionManager);
