import React, { useContext } from 'react'

import {
  DatePicker, Form, Col, Row, Input, Select, Typography, InputNumber
} from 'antd';

import moment from 'moment'

import PostulationFormCheckbox from './JobAppInfoComponents/PostulationForm';

import JobAppContext from '../context/JobAppContext'
import GenomaTags from '../../../../components/GenomaTags';
import MceEditor from '../../../BusinessDashboard/NewProcessFormV2/components/mceeditor/MceEditor'
import Company from './JobAppInfoComponents/Company';
import CustomFields from './JobAppInfoComponents/CustomFields';
import Integrations from './JobAppInfoComponents/Integrations';
import PostulationType from './JobAppInfoComponents/PostulationType';

const { Text } = Typography;

const dateFormat = 'DD-MM-YYYY';


const optionalLabelStyles = { fontSize: 14, color: '#A4A4A4', fontWeight: 'normal' };

function JobAppInfo({ form }) {
  const { getFieldDecorator } = form;

  const {
    countries, updateJobApplication, jobApplication,
  } = useContext(JobAppContext);
  const JobAppFormLabel = ({ label, optional }) => {
    return (
      <span className='jobapp-section-label'>
        {!optional && <span style={{ color: 'red', fontWeight: 400 }}>*</span>} {label} {optional && <span style={optionalLabelStyles}>(opcional)</span>}
      </span>
    )
  }

  const sortCountries = (a, b) => {
    const latamCountries = ['AR', 'BO', 'BR', 'CL', 'CO', 'CR', 'DO', 'EC', 'GT', 'HN', 'PR', 'PY', 'PE', 'UY', 'VE' ]
    if (latamCountries.includes(a.iso_2) && !latamCountries.includes(b.iso_2)) return -1;
    if (!latamCountries.includes(a.iso_2) && latamCountries.includes(b.iso_2)) return 1;
    
    return 0;
  }

  return (
    <div className='jobapp-info-container' >
      <div className='jobapp-section' data-tour="jobappinfo-jobdetail">
        <div className='jobapp-section-title'>
          Información básica
        </div>
        <Form>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label={<JobAppFormLabel label={'Nombre del cargo'} />}>
                {getFieldDecorator('name', {
                  rules: [
                    {
                      required: true,
                      message: 'Este campo es obligatorio',

                    },

                  ],
                  onChange: (obj) => updateJobApplication('name', obj.target.value),
                  initialValue: jobApplication.name,
                })(<Input placeholder="Nombre externo del cargo" maxLength={100} suffix={`${jobApplication.name?.length}/100`}/>)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={<JobAppFormLabel label={'Área'} />}>
                {getFieldDecorator('department', {
                  rules: [
                    {
                      required: true,
                      message: 'Este campo es obligatorio',
                    },
                  ],
                  onChange: (obj) => updateJobApplication('department', obj.target.value),
                  initialValue: jobApplication.department,
                })(<Input placeholder="Selecciona el área" maxLength={50} suffix={`${jobApplication.department?.length}/50`}/>)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={<JobAppFormLabel label={'Vacantes'} optional={true} />}>
                {getFieldDecorator('totalVacancies', {
                  rules: [
                    {
                      required: false,
                      message: 'Este campo es obligatorio',
                    },
                  ],
                  onChange: (value) => updateJobApplication('totalVacancies', value),
                  initialValue: jobApplication.totalVacancies,
                })(<InputNumber min={0} style={{ borderRadius: 2, width: '100%', paddingTop: 4 }} placeholder="Selecciona número de vacantes" />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={<JobAppFormLabel label={'Idioma'} />}>
                {getFieldDecorator('language', {
                  initialValue: 'es',
                  rules: [
                    {
                      required: true,
                      message: 'Este campo es obligatorio',
                    },
                  ],
                  onChange: (obj) => updateJobApplication('language', obj),
                  initialValue: jobApplication.language,
                })(
                  <Select style={{ width: '100%' }}>
                    <Select.Option key={'en'} value={'en'}>{'English'}</Select.Option>
                    <Select.Option key={'es'} value={'es'}>{'Español'}</Select.Option>
                    <Select.Option key={'pt'} value={'pt'}>{'Português'}</Select.Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={<JobAppFormLabel label={'País'} />}>
                {getFieldDecorator('country', {
                  rules: [
                    {
                      required: true,
                      message: 'Este campo es obligatorio',
                    },
                  ], onChange: (value) => updateJobApplication('country', value),
                  initialValue: jobApplication.country,
                })(
                  <Select
                    placeholder="País"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {countries.sort((a,b)=> sortCountries(a,b)).map(obj => <Select.Option key={obj.iso_2} value={obj.iso_2}>{obj.translation.es}</Select.Option>)}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={<JobAppFormLabel label={'Ciudad'} optional={false} />}>
                {getFieldDecorator('city', {
                  rules: [
                    {
                      required: true,
                      message: 'Este campo es obligatorio',
                    },
                  ],
                  onChange: (obj) => updateJobApplication('city', obj.target.value),
                  initialValue: jobApplication.city,
                })(<Input placeholder="Selecciona la ciudad" />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={<JobAppFormLabel label={'Dirección'} optional={true} />}>
                {getFieldDecorator('address', {
                  rules: [
                    {
                      required: false,
                    },
                  ],
                  onChange: (obj) => updateJobApplication('address', obj.target.value),
                })(<Input placeholder="Dirección" />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={<JobAppFormLabel label={'Fecha de finalización estimada'} optional={false} />}>
                {getFieldDecorator('closeDate', {
                  rules: [
                    {
                      required: false,
                      //message: 'Este campo es obligatorio',
                    },
                  ],
                  onChange: (date, dateString) => updateJobApplication('closureDate', dateString),
                  initialValue: jobApplication.closureDate ? moment(jobApplication.closureDate, dateFormat) : undefined,
                })(<DatePicker placeholder="Selecciona una fecha" format={dateFormat} />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={<JobAppFormLabel label={'Etiquetas'} optional={true} />}>
                <div style={{ marginTop: 10 }}>
                  <GenomaTags
                    initialValue={jobApplication.tags}
                    callback={(tagArray) => { updateJobApplication('tags', tagArray) }}
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div className='jobapp-section'>
        <div className='jobapp-section-title'>
          {'Detalles del cargo'}
        </div>
        <div style={{ marginBottom: 10 }}>
          <JobAppFormLabel label={'Texto de bienvenida'} />
        </div>
        <div>
          <MceEditor
            handleChange={() => (e) => updateJobApplication('welcomeText', e.target.value)}
            disabled={false}
            body={jobApplication.welcomeText}
            //readOnlyBody={this.state.message}
            name='welcomeText'
            height={350}
          />
        </div>

        <div style={{ marginBottom: 10, marginTop: 30 }}>
          <JobAppFormLabel label={'Sobre el cargo'} optional={true} />
        </div>
        <div>
          <MceEditor
            handleChange={() => (e) => updateJobApplication('description', e.target.value)}
            disabled={false}
            body={jobApplication.description}
            //readOnlyBody={this.state.message}
            name='description'
          />
        </div>

        <div className='jobapp-grid-gap' style={{ marginBottom: 10, marginTop: 30 }}>
          <JobAppFormLabel label={'Video'} optional={true} />
          <Input placeholder="Ingresa el link de tu video introductorio" onChange={(e) => updateJobApplication('introVideo', e.target.value)} />
          <Text style={{ fontSize: 14 }}>
            Ten en cuenta que el video debe estar alojado en alguno de los siguientes proveedores:
          </Text>
          <div className='jobapp-video-providers'>
            <div />
            <div />
          </div>
        </div>
      </div>

      {
        /** 
        <div className='jobapp-section'>
          <div className='jobapp-section-title'>
            {'Condiciones del cargo '}
            TODO
          </div>
        </div>
        */
      }
      <div className='jobapp-section'>
        <div className='jobapp-section-title'>
          {'Formulario de postulación'}
        </div>
        <PostulationFormCheckbox />
      </div>

      <div className='jobapp-section'>
        <div className='jobapp-section-title'>
          {'Tipo de postulación'}
        </div>
        <PostulationType />
      </div>
      
      <div className='jobapp-section'>
        <div className='jobapp-section-title'>
          {'Atributos personalizados'}
        </div>
        <CustomFields />
      </div>

      <div className='jobapp-section jobappinfo-company' id="jobappinfo-company">
        <div className='jobapp-section-title'>
          {'Sobre la empresa '}
        </div>
        <Company />
      </div>

      <div className='jobapp-section'>
        <div className='jobapp-section-title'>
          {'Integraciones'}
        </div>
        <Integrations />
      </div>
    </div>
  )
}



const WrappedJobAppInfo = Form.create({ name: 'jobappinfo_form' })(JobAppInfo);

export default WrappedJobAppInfo;