import React, { useContext } from 'react';

import { Button, Divider, Icon, Row, Typography } from 'antd';
import PropTypes from 'prop-types';

import ExperienceInformation from './ExperienceInformation';
import { PersonalFormContext } from '../config/Context';
import { stepTitles } from '../constants';
import i18n from '../../../i18n';

const { Text, Title } = Typography;

function Experience(props) {
  const { form } = props;
  const { getFieldDecorator, getFieldValue, setFieldsValue, validateFields } = form;
  const {
    addExperience,
    candidate: { experiences },
    jobApplication,
  } = useContext(PersonalFormContext);

  const optional = jobApplication.optional_fields.experiences || jobApplication.optional_fields.experiences === undefined;

  return (
    <>
      <Row>
        <Title level={3}>{!optional ? <Text style={{ color: 'red' }}>*</Text> : ''}{stepTitles[2]}</Title>
      </Row>
      {experiences.map((experienceData, idx) => (
        <div key={`experience-information-${idx}`}>
          <ExperienceInformation
            idx={idx}
            experienceData={experienceData}
            getFieldDecorator={getFieldDecorator}
            getFieldValue={getFieldValue}
            setFieldsValue={setFieldsValue}
            validateFields={validateFields}
          />
        </div>
      ))}
      {experiences.length === 0 ? <Divider /> : null}
      <Button className="add-button" onClick={addExperience}>
        <Icon type="plus" /> {i18n.t("form__add_experience")}
      </Button>
    </>
  );
}

Experience.propTypes = {
  form: PropTypes.object.isRequired,
};

export default Experience;
