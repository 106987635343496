import React from 'react';
import { Col } from 'antd';
import i18n from '../../../../../i18n';

const CustomTabs = ({ setCurrentTab, currentTab, tabs, color, preview=false }) => {

  const style = { "--bu-theme-color": color };

  const tmpTabs = tabs.map((t, i) => (
    <div
      className={`${currentTab === t ? 'selected-tab' : ''}`}
      style={{...style, fontSize: preview ? 9 : 14}}
      onClick={() => setCurrentTab(t)}
      key={t}
    >
     {t === 'job' ? <span>{i18n.t('start__process__about__job')}</span> : <span>{i18n.t('start__process__about__company')}</span>}
    </div>
  ));

  return (
    <Col xs={24} className="welcome-custom-tabs">
      <div>{tmpTabs}</div>
    </Col>
  );
};

export default CustomTabs;
