import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SERVER_URL } from '../../../../utils/config';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../../actions/data';
import {
  Modal,
  Typography,
  Button,
  Checkbox,
  message,
  Input,
} from 'antd';
import '../ProcessList.scss';

const CheckboxGroup = Checkbox.Group;
const { Title, Text } = Typography;

const ShareWithCollaborators = (props) => {
  const [checkedList, setCheckedList] = useState([])
  const [indeterminate, setIndeterminate] = useState(true)
  const [checkAll, setCheckAll] = useState(false)
  const [loading, setLoading] = useState(false)
  const [filterQuery, setFilterQuery] = useState('');
  const [alreadyShared, setAlreadyShared] = useState({});

  const formatPlainOptions = useMemo(() => {
    const filteredCollaborators = filterQuery ? props.collaborators.filter((collaborator) => {
      const name = collaborator.name ? 
        collaborator.name : 
        (collaborator.business_user ? collaborator.business_user : collaborator.email);
      return collaborator.email.toLowerCase().includes(filterQuery.toLowerCase()) || name.toLowerCase().includes(filterQuery.toLowerCase());
    }) : props.collaborators 


    const options = filteredCollaborators.map((collaborator, index) => {
      const name = collaborator.name ?
        collaborator.name :
        (collaborator.business_user ? collaborator.business_user : collaborator.email);
      return {
        disabled: !!alreadyShared[collaborator.id],
        value: collaborator.email,
        label: (
          <div className='chk-label' key={index} style={{ width: "100%" }}>
            <div className='chk-label-avatar'>
              <div className='chk-label__color'>
                <Text>{name.substr(0, 1).toUpperCase()}</Text>
              </div>
            </div>
            <div className='chk-label-name'>
              <Text strong>{collaborator.email}</Text>
              <Text type='secondary'>{name}</Text>
              {!!alreadyShared[collaborator.id] ?
              <>
                <Text type='danger'>Compartido</Text>
                <Button icon='user-delete' onClick={() => removePermission(alreadyShared[collaborator.id])} >Dejar de compartir</Button>
              </>
              : null}
            </div>
          </div>
        )
      }
    })

    return options
  }, [props.collaborators, filterQuery, alreadyShared])

  useEffect(() => {
    if (props.visible) {
      getAlreadyShared();
    }
  }, [props.visible])
  
  const getAlreadyShared = () => {
    const url = 'accounts/permission/';
    props.actions.fetchData(props.token, url, JSON.stringify({ process_id: props.id }), 'get').then((data) => {
      const permissions = data.results;
      const shared = {};
      permissions.forEach(p => {
        shared[p.business_user_id] = p.id;
      })
      setAlreadyShared(shared);
    })
  }

  const removePermission = (permissionId) => {
    const url = `accounts/permission/${permissionId}/`;
    fetch(`${SERVER_URL}/api/v1/${url}`, {
      method: `DELETE`,
      headers: {
          Accept: 'application/json',
          Authorization: `Token ${props.token}`,
          "Content-Type": `application/json`,
      },
   }).then(() => {
     getAlreadyShared();
     const resetCheckedList = [];
     setCheckedList(resetCheckedList);
   })
  }

  const onChange = checkedList => {
    setCheckedList(checkedList)
    setIndeterminate(!!checkedList.length && checkedList.length < formatPlainOptions.length)
    setCheckAll(checkedList.length === formatPlainOptions.length)
  };
  
  const onCheckAllChange = e => {
    const plainOptions = formatPlainOptions.map(({ value }) => value)
    setCheckedList(e.target.checked ? plainOptions : [])
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  };

  const handleShareProcess = async () => {
    setLoading(true)
    const url = 'accounts/permission/'
    let data = { users: checkedList, job_app_id: props.id }
    await props.actions.sendData(props.token, url, JSON.stringify(data), 'post')
    setLoading(false)
    message.success('El proceso ha sido compartido')
    props.closeModal()
  };

  return (
    <Modal
      centered={true}
      visible={props.visible}
      className='gw-share-with-collaborators-modal'
      footer={[
        <Button
          key={1}
          type='default'
          onClick={props.closeModal}
          disabled={loading}
        >
          Cancelar
        </Button>,
        <Button
          key={2}
          type='primary'
          disabled={!checkedList.length}
          onClick={handleShareProcess}
          loading={loading}
        >
          Compartir
        </Button>
      ]}
    >
      <div className='content' onClick={(e) => e.stopPropagation()}>
        <div className='headline'>
          <Title level={1}>Compartir proceso</Title>
          <Text type='secondary'>{props.name}</Text>
        </div>
        <div>
          <Input type="search" value={filterQuery} onChange={(e) => setFilterQuery(e.target.value)} placeholder="Buscar colaborador" />
        </div>
        <div className='filters'>
          <Checkbox
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
          >
            Seleccionar todos
          </Checkbox>
        </div>
      
        <CheckboxGroup
          options={formatPlainOptions}
          value={checkedList}
          onChange={onChange}
        />
      </div>
    </Modal>
  )
}

ShareWithCollaborators.propTypes = {
  visible: PropTypes.bool.isRequired,
  name: PropTypes.string,
  collaborators: PropTypes.array,
  closeModal: PropTypes.func,
  id: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareWithCollaborators);