import React, { useState, useEffect, useMemo } from 'react';
import {
  Row,
  Col,
  Typography,
  Button,
  Icon,
  Input,
  Switch,
  Steps,
  message,
} from 'antd';
import { Link } from 'react-router-dom';
import JobPortal from './components/JobPortal';
import QRCode from 'qrcode.react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionsCreators from '../../../actions/data';
import * as actionsJobApp from '../../../actions/jobapp';

import './ShareProcess.scss';

import EmailModal from './components/EmailModal';
import PostJobOnLaborum from './components/PostJobOnLaborum';

const { Title, Paragraph } = Typography;

const SHARE_URL = process.env.REACT_APP_SHARE_URL;

const ShareProcess = (props) => {
  const jobappid = props.match.params['jobappid'];
  const businessSlug = props.business ? props.business.slug : props.businessOwner;
  const shareProcessUrl = useMemo(() => (SHARE_URL.includes('app.genoma.work') ? `https://jobs.genoma.work/${businessSlug}/${jobappid}/` : `${SHARE_URL}/${businessSlug}/${jobappid}/`), [businessSlug, jobappid])
  const [visibleEmailModal, setVisibleEmailModal] = useState(false);
  const [state, setState] = useState({
    job_portals: {
      chiletrabajo: false,
      //indeed: false,
      laborum: false,
      trabajando: false,
      googleforjobs: false,
      trovit: false,
      jooble: false,
      buscojobs: false,
      talentcom: false,
      opcionempleo: false,
    },
    jobs_site_availability: false,
  });

  useEffect(() => {
    async function fetchData() {
      const res = await props.jobAppActions.fetchJobApplication(
        props.token,
        jobappid
      );
      setState(res);
    }
    fetchData();
  }, []);

  const getShareProcessUrl = () => {
    const SHARE_URL = process.env.REACT_APP_SHARE_URL.includes(
      'app.genoma.work'
    )
      ? 'https://jobs.genoma.work'
      : process.env.REACT_APP_SHARE_URL;
    const shareProcessUrl = `${SHARE_URL}/${businessSlug}/${jobappid}/`
    return shareProcessUrl;
  };

  const onChangeJobPosting = (field) => (event) => {
    const jobapp = { ...state };
    if (
      [
        'status',
        'youtube_intro_video',
        'location_country',
        'closure_date',
        'job_portals',
        'jobs_site_availability',
        'language',
      ].includes(field)
    ) {
      jobapp[field] = event;
    } else {
      jobapp[field] = event.target.value;
    }
    setState(jobapp);
  };

  const dateToString = (date) => {
    return (
      date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    );
  };

  const openTalentPool = () => {
    const currentRoute = props.location.pathname;
    const index = currentRoute.indexOf('share');
    let url = currentRoute.slice(0, index);
    url += 'candidates';
    window.open(url, '_blank');
  };

  const handlePublishJob = (shouldRedirect = true) => {
    const jobapp = { ...state };
    const date = new Date();
    if (jobapp.status === 'INACT') {
      jobapp.closure_date = dateToString(date);
    }
    setState(jobapp);
    updateJobApp({ job_portals: jobapp.job_portals }).then((res) => {
      if (res.status === 200) {
        message.success('La vacante se ha publicado exitosamente.');
      } else {
        message.error('Hubo un error al publicar la vacante.');
      }
    });
  };


  const downloadQR = () => {
    const oldCanvas = document.getElementById('qr-canvas');

    //create a new canvas
    const newCanvas = document.createElement('canvas');
    const context = newCanvas.getContext('2d');
    //set dimensions
    newCanvas.width = 1000;
    newCanvas.height = 1000;
    //apply the old canvas to the new one
    context.drawImage(oldCanvas, 0, 0, 1000, 1000);

    const url = newCanvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.download = 'qr-code.png';
    link.href = url;
    link.click();
  };

  /*
  Test Download svg
  Funciona pero no descarga el genomin al medio
  function triggerDownload(imgURI) {
    const a = document.createElement('a');
    a.download = 'MY_COOL_IMAGE.png'; // filename
    a.target = '_blank';
    a.href = imgURI;

    // trigger download button
    // (set `bubbles` to false here.
    // or just `a.click()` if you don't care about bubbling)
    a.dispatchEvent(new MouseEvent('click', {
      view: window,
      bubbles: false,
      cancelable: true
    }));
  }

  const downloadQR2 = () => {

      const svgNode = document.getElementById('qr-svg');;
      const svgString = (new XMLSerializer()).serializeToString(svgNode);
      const svgBlob = new Blob([svgString], {
        type: 'image/svg+xml;charset=utf-8'
      });

      const DOMURL = window.URL || window.webkitURL || window;
      const url = DOMURL.createObjectURL(svgBlob);

      const image = new Image();
      image.width = svgNode.width.baseVal.value;
      image.height = svgNode.height.baseVal.value;
      image.src = url;
      image.onload = function () {
        const canvas = document.getElementById('canvas');
        canvas.width = image.width;
        canvas.height = image.height;

        const ctx = canvas.getContext('2d');
        canvas.width = 1000;
        canvas.height = 1000;
        ctx.drawImage(image, 0, 0, 1000, 1000);
        DOMURL.revokeObjectURL(url);

        const imgURI = canvas
          .toDataURL('image/png')
          .replace('image/png', 'image/octet-stream');
        triggerDownload(imgURI);
      
    };
  }
  */

  const updateJobApp = async (bodyData) => {
    const url = `/api/v1/gamesandtests/jobapplications/${jobappid}/`;
    return await props.actions.fetchAndReturn(
      props.token,
      url,
      'PATCH',
      bodyData
    );
  };

  const handleSwitch = async (checked) => {
    // make fetch with patch method to update the jobapp
    const res = await updateJobApp({ jobs_site_availability: checked });
    // if sucesss update state with the checked value
    if (res.status === 200) {
      const keyword = checked ? 'visible' : 'privada';
      message.success(`La vacante es ahora ${keyword}.`);
      setState({ ...state, jobs_site_availability: checked });
    } else {
      message.error('Hubo un error al actualizar la vacante.');
    }
  };

  return (
    <Row type="flex" justify="center" align="middle" style={{ rowGap: '24px' }}>
      <Col xs={24} sm={18}>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{
            flexDirection: 'column',
            rowGap: '16px',
            width: '100%',
            padding: '50px 0px',
            backgroundColor: 'gray',
          }}
        >
          <Row style={{ textAlign: 'center' }}>
            <Typography.Title level={2} style={{ color: 'white' }}>
              ¡Difunde tu proceso!
            </Typography.Title>
            <Typography.Text style={{ color: 'white' }}>
              Es tiempo de encontrar a tu candidato ideal.
            </Typography.Text>
          </Row>
          <Row type="flex" style={{ width: '100%' }}>
            <Col
              span={6}
              push={6}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                rowGap: '16px',
              }}
            >
              <div className="qr-background">
                {/* TODO: add invitation link */}
                <QRCode
                  renderAs='canvas'
                  value={shareProcessUrl}
                  size={120}
                  //fgColor={'#f175a5'}
                  includeMargin
                />
                {
                  //For download purposes
                }
                <QRCode
                  style={{display:'none'}}
                  id='qr-canvas'
                  renderAs='canvas'
                  value={shareProcessUrl}
                  size={1000}
                  //fgColor={'#f175a5'}
                  includeMargin
                />
                <canvas id="canvas" style={{display:'none'}}></canvas>
                <Button onClick={downloadQR}><Icon type="download" />Descargar QR</Button>
              </div>
            </Col>
            <Col span={6} push={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Input value={getShareProcessUrl()} style={{ width: '250px', height: '32px', overflow: 'hidden', background: 'white' }} />
              <Button style={{ backgroundColor: '#597EF7', color: 'white' }} onClick={() => navigator.clipboard.writeText(getShareProcessUrl())} ><Icon type="link" />Copiar</Button>
            </Col>
          </Row>
        </Row>
      </Col>

      <Col xs={24} sm={18}>
        <Row type='flex' justify='center' style={{ backgroundColor: '#FFFBE6', padding: 15 }}>
          <Col span={22}>
            <Typography.Text>Cargo visible en el portal de empleo</Typography.Text>
          </Col>
          <Col span={2}>
            <Switch checked={state.jobs_site_availability} onChange={(check) => handleSwitch(check)} />
          </Col>
        </Row>
      </Col>

      <Col xs={24} sm={18}>
        <Row type="flex" justify="center" align="middle">
          <Steps direction="vertical">
            <Steps.Step
              status="process"
              title="Invitar candidatos"
              description={
                <Row
                  gutter={16}
                  type="flex"
                  align="middle"
                  justify="start"
                  style={{ width: '100%', columnGap: '12px' }}
                >
                  <Card
                    title="Base de talentos"
                    description="Usa tu base de candidatos"
                    icon="user"
                    buttonText="Invitar desde Talent Pool"
                    action={() => openTalentPool()}
                  />
                  {props.addOns.find((addon) => addon.add_on === 'IMPC') && (
                    <Card
                      title="Múltiples candidatos"
                      description={'Importa mediante Excel'}
                      icon="usergroup-add"
                      buttonText="invitar candidatos"
                      action={() =>
                        window.open(
                          `${process.env.REACT_APP_SHARE_URL}/candidateupload/${jobappid}`,
                          '_blank'
                        )
                      }
                    />
                  )}
                  <Card
                    title="Individualmente"
                    description={'Mediante correo electrónico'}
                    icon="user-add"
                    buttonText="invitar individualmente"
                    action={() => setVisibleEmailModal(true)}
                  />
                </Row>
              }
            />
            <Steps.Step
              status={'process'}
              title="Difundir en redes sociales"
              description={
                <Row
                  gutter={16}
                  type="flex"
                  align="middle"
                  justify="start"
                  style={{ width: '100%', columnGap: '12px' }}
                >
                  <Card
                    title="Twitter"
                    buttonText={'Publicar en Twitter'}
                    image={
                      'https://cdn-icons-png.flaticon.com/512/3256/3256013.png'
                    }
                    social="twitter"
                    shareProcessUrl={shareProcessUrl}
                  />
                  <Card
                    title="Facebook"
                    buttonText={'Publicar en Facebook'}
                    image={
                      'https://cdn-icons-png.flaticon.com/512/5968/5968764.png'
                    }
                    social="facebook"
                    shareProcessUrl={shareProcessUrl}
                  />
                  <Card
                    title="LinkedIn"
                    buttonText={'Compartir en LinkedIn'}
                    image={
                      'https://cdn-icons-png.flaticon.com/512/3536/3536505.png'
                    }
                    social="linkedin"
                    shareProcessUrl={shareProcessUrl}
                  />
                </Row>
              }
            />
            <Steps.Step
              status={'process'}
              title="Difundir en portales gratuitos"
              description={
                <Row
                  gutter={[16, 16]}
                  type="flex"
                  align="middle"
                  justify="start"
                  style={{ width: '100%', columnGap: '12px' }}
                >
                  {state.is_confidential ? (
                    <JobPortal
                      provider="talentcom"
                      providerName="Talent.com"
                      publish={handlePublishJob}
                      handleChange={onChangeJobPosting}
                      jobApp={state}
                      modalContent={
                        <>
                          <Title level={3}>Publicar empleo en Talent.com</Title>
                          <Paragraph>
                            Desde ahora, tus ofertas laborales podrán ser
                            visibles desde Talent.com 🎉.
                          </Paragraph>
                          <Paragraph>
                            Al aceptar, la información del proceso será
                            publicada en el portal Talent.com. Por favor
                            verificar la <strong>fecha de cierre</strong> del
                            proceso, ya que esta indicará la fecha de expiración
                            de la publicación en Talent.
                          </Paragraph>
                        </>
                      }
                      countries={['BR', 'CL', 'CO', 'PE', 'MX', 'AR', 'EC']}
                      jobportalIntegrations={props.jobportalIntegrations}
                    />
                  ) : (
                    <>
                      <JobPortal
                        provider="googleforjobs"
                        providerName="Google for Jobs"
                        publish={handlePublishJob}
                        handleChange={onChangeJobPosting}
                        jobApp={state}
                        modalContent={
                          <>
                            <Typography.Title level={3}>
                              Publicar empleo en Google for Jobs
                            </Typography.Title>
                            <Typography.Paragraph>
                              Desde ahora, tus ofertas laborales podrán ser
                              visibles desde Google 🎉.
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                              Al aceptar, la información del proceso será
                              publicada en el motor de búsqueda de Google. Por
                              favor verificar la{' '}
                              <strong>fecha de cierre</strong> del proceso, ya
                              que esta indicará la fecha de expiración de la
                              publicación en Google.
                            </Typography.Paragraph>
                          </>
                        }
                        jobportalIntegrations={props.jobportalIntegrations}
                      />
                      <JobPortal
                        provider="trovit"
                        providerName="Trovit"
                        publish={handlePublishJob}
                        handleChange={onChangeJobPosting}
                        jobApp={state}
                        modalContent={
                          <>
                            <Title level={3}>
                              Publicar empleo en Trovit/Mitula
                            </Title>
                            <Paragraph>
                              Desde ahora, tus ofertas laborales podrán ser
                              visibles desde Trovit y Mitula 🎉.
                            </Paragraph>
                            <Paragraph>
                              Al aceptar, la información del proceso será
                              publicada en el motor de búsqueda de Trovit y
                              Mitula. Por favor verificar la{' '}
                              <strong>fecha de cierre</strong> del proceso, ya
                              que esta indicará la fecha de expiración de la
                              publicación en Trovit y Mitula.
                            </Paragraph>
                          </>
                        }
                        countries={['CL', 'CO', 'PE', 'MX', 'AR', 'UY']}
                        jobPortalIntegrations={props.jobPortalIntegrations}
                      />
                      <JobPortal
                        provider="opcionempleo"
                        providerName="Opción Empleo"
                        publish={handlePublishJob}
                        handleChange={onChangeJobPosting}
                        jobApp={state}
                        modalContent={
                          <>
                            <Title level={3}>
                              Publicar empleo en Opción Empleo
                            </Title>
                            <Paragraph>
                              Desde ahora, tus ofertas laborales podrán ser
                              visibles desde Opción Empleo 🎉.
                            </Paragraph>
                            <Paragraph>
                              Al aceptar, la información del proceso será
                              publicada en el motor de búsqueda de Opción
                              Empleo. Por favor verificar la{' '}
                              <strong>fecha de cierre</strong> del proceso, ya
                              que esta indicará la fecha de expiración de la
                              publicación en Indeed.
                            </Paragraph>
                          </>
                        }
                        countries={[
                          'CL',
                          'CO',
                          'PE',
                          'BO',
                          'AR',
                          'EC',
                          'MX',
                          'PA',
                          'PY',
                          'UY',
                        ]}
                        jobportalIntegrations={props.jobportalIntegrations}
                      />
                      <JobPortal
                        provider="jooble"
                        providerName="Jooble"
                        publish={handlePublishJob}
                        handleChange={onChangeJobPosting}
                        jobApp={state}
                        modalContent={
                          <>
                            <Title level={3}>Publicar empleo en Jooble</Title>
                            <Paragraph>
                              Desde ahora, tus ofertas laborales podrán ser
                              visibles desde Jooble 🎉.
                            </Paragraph>
                            <Paragraph>
                              Al aceptar, la información del proceso será
                              publicada en el motor de búsqueda de Jooble. Por
                              favor verificar la{' '}
                              <strong>fecha de cierre</strong> del proceso, ya
                              que esta indicará la fecha de expiración de la
                              publicación en Jooble.
                            </Paragraph>
                          </>
                        }
                        countries={['BR', 'CL', 'CO', 'PE', 'MX', 'AR', 'EC']}
                        jobportalIntegrations={props.jobportalIntegrations}
                      />
                      <JobPortal
                        provider="buscojobs"
                        providerName="Buscojobs"
                        publish={handlePublishJob}
                        handleChange={onChangeJobPosting}
                        jobApp={state}
                        modalContent={
                          <>
                            <Title level={3}>
                              Publicar empleo en BuscoJobs
                            </Title>
                            <Paragraph>
                              Desde ahora, tus ofertas laborales podrán ser
                              visibles desde BuscoJobs 🎉.
                            </Paragraph>
                            <Paragraph>
                              Al aceptar, la información del proceso será
                              publicada en la búsqueda de empleos de BuscoJobs
                              Por favor verificar la{' '}
                              <strong>fecha de cierre</strong> del proceso, ya
                              que esta indicará la fecha de expiración de la
                              publicación en BuscoJobs.
                            </Paragraph>
                          </>
                        }
                        countries={[
                          'AR',
                          'BO',
                          'BR',
                          'CL',
                          'CO',
                          'PA',
                          'PE',
                          'MX',
                          'EC',
                          'VE',
                        ]}
                      />
                      <JobPortal
                        provider="talentcom"
                        providerName="Talent.com"
                        publish={handlePublishJob}
                        handleChange={onChangeJobPosting}
                        jobApp={state}
                        modalContent={
                          <>
                            <Title level={3}>
                              Publicar empleo en Talent.com
                            </Title>
                            <Paragraph>
                              Desde ahora, tus ofertas laborales podrán ser
                              visibles desde Talent.com 🎉.
                            </Paragraph>
                            <Paragraph>
                              Al aceptar, la información del proceso será
                              publicada en el portal Talent.com. Por favor
                              verificar la <strong>fecha de cierre</strong> del
                              proceso, ya que esta indicará la fecha de
                              expiración de la publicación en Talent.
                            </Paragraph>
                          </>
                        }
                        countries={['BR', 'CL', 'CO', 'PE', 'MX', 'AR', 'EC']}
                        jobportalIntegrations={props.jobportalIntegrations}
                      />
                      {/* LinkedIn not available YET */}
                      {props.jobPortalIntegrations.linkedin &&
                      props.jobPortalIntegrations.linkedin.companyId ? (
                        <JobPortal
                          provider="linkedin"
                          providerName="LinkedIn"
                          publish={handlePublishJob}
                          handleChange={onChangeJobPosting}
                          jobApp={state}
                          jobPortalIntegrations={props.jobPortalIntegrations}
                          modalContent={
                            <>
                              <Title level={3}>
                                Publicar empleo en LinkedIn
                              </Title>
                              <Paragraph>
                                Desde ahora, tus ofertas laborales podrán ser
                                visibles desde LinkedIn 🎉.
                              </Paragraph>
                              <Paragraph>
                                Al aceptar, la información del proceso será
                                publicada en la búsqueda de empleos de LinkedIn
                                (Job Listings). El empleo será asociado al
                                perfil de LinkedIn de la empresa. <br />
                                Por favor verificar la{' '}
                                <strong>fecha de cierre</strong> del proceso, ya
                                que esta indicará la fecha de expiración de la
                                publicación en LinkedIn.
                              </Paragraph>
                            </>
                          }
                        />
                      ) : null}
                    </>
                  )}
                </Row>
              }
            />
            <Steps.Step
              status={'process'}
              title="Difundir en portales de pago"
              description={
                <Row
                  gutter={16}
                  type="flex"
                  align="middle"
                  justify="start"
                  style={{ width: '100%' }}
                >
                  <PostJobOnLaborum
                    jobapp={state}
                    shareProcessUrl={shareProcessUrl}
                    jobPortalIntegrations={props.jobPortalIntegrations}
                    publish={handlePublishJob}
                    handleChange={onChangeJobPosting}
                  />
                </Row>
              }
            />
          </Steps>
        </Row>
      </Col>

      <Col xs={24} sm={18}>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ padding: 20 }}
        >
          <Col span={20}>
            <Typography.Title level={3}>
              ¿Todo listo? ¡Ya es hora de encontrar al mejor talento! 🚀
            </Typography.Title>
          </Col>
          <Col span={4}>
            <Button type='primary'>
              <Link to={`/processes/${jobappid}`}>
                <Icon type="arrow-right" />
                Ir al proceso
              </Link>
            </Button>
          </Col>
        </Row>
      </Col>
      {visibleEmailModal && (
        <EmailModal hideModal={() => setVisibleEmailModal(false)} />
      )}
    </Row>
  );
};

export function Card({
  title,
  description,
  icon,
  buttonText,
  image,
  action,
  social = false,
  ...props
}) {
  const socialType = {
    linkedin: 0,
    twitter: 0,
    facebook: 1,
  };

  const getUrl = (type) => {
    if (socialType[type] === 0) {
      const encodedUrl = encodeURI(props.shareProcessUrl.replace(/\s/g, ''));
      if (type === 'linkedin') {
        return `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
      } else if (type === 'twitter') {
        return (
          'https://twitter.com/intent/tweet?original_referer=https://www.google.cl&text=&url=' +
          encodedUrl
        );
      }
    } else if (socialType[type] === 1) {
      return 'https://www.facebook.com/sharer.php?u=' + props.shareProcessUrl;
    }
  };

  const popup = (url) => {
    const width = 500;
    const height = 700;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 4;
    return window.open(
      url,
      '_blank',
      'width=' +
        width +
        ', height=' +
        height +
        ', top=' +
        top +
        ', left=' +
        left
    );
  };

  return (
    <Col span={6}>
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          flexDirection: 'column',
          rowGap: '8px',
          padding: '20px 0px',
          border: '1px solid #D9D9D9',
          minWidth: '200px',
        }}
      >
        {image ? (
          <img src={image} style={{ width: '50px' }} />
        ) : (
          <Icon className="share-card-icon" type={icon} />
        )}
        <Typography.Title level={3}>{title}</Typography.Title>
        <Typography.Text>{description}</Typography.Text>
        <Button
          className="share-card-button"
          onClick={!social ? action : () => popup(getUrl(social))}
        >
          {buttonText}
        </Button>
      </Row>
    </Col>
  );
}

const mapStateToProps = (state) => {
  return {
    addOns: state.auth.addOns,
    jobApp: state.jobapp,
    token: state.auth.token,
    jobPortalIntegrations: state.auth.jobPortalIntegrations,
    business: state.auth.business,
    businessOwner: state.auth.businessOwner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionsCreators, dispatch),
    jobAppActions: bindActionCreators(actionsJobApp, dispatch),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareProcess);
