import React, { useContext } from 'react';

import { Col, Form, Icon, Row, Typography, Upload } from 'antd';
import PropTypes from 'prop-types';

import { PersonalFormContext } from '../config/Context';
import i18n from '../../../i18n';
import { personalFormLabels } from '../constants';

const { Dragger } = Upload;
const { Text, Title } = Typography;

function Curriculum(props) {
  const { form } = props;
  const { getFieldDecorator } = form;
  const {
    candidate: { cv, has_cv, newCv },
    jobApplication: { optional_fields: { cv: optional } },
    updateCandidate,
  } = useContext(PersonalFormContext);

  return (
    <Col sm={24} style={{ display: optional === 2 ? 'none' : 'auto', width: '100%' }}>
      <Row>
        <Title level={3}>
          {(optional !== undefined && !optional) ? <Text style={{ color: 'red' }}>*</Text> : ''}{personalFormLabels.filesInformation.cv}
        </Title>
      </Row>
      <Row align="top" type="flex">
        <Col span={24}>
          <Form.Item>
            {getFieldDecorator("cv", {
              getValueFromEvent: uploadEvent => {
                if (Array.isArray(uploadEvent)) {
                  return uploadEvent;
                }
                return uploadEvent && [uploadEvent.file];
              },
              initialValue: has_cv ? [
                {
                  uid: '1',
                  name: newCv ? newCv.name : cv.split('/').pop(),
                  status: 'done',
                  url: newCv ? newCv.response?.file : cv,
                },
              ] : null,
              onChange: ({ file }) => updateCandidate('newCv', file),
              rules: [
                {
                  validator: (rule, value, callback) => {
                    if (value && value[0].size > 5242880) {
                      callback(i18n.t('form__error__cv__2'));
                    }
                    callback();
                  }
                }
              ],
              valuePropName: 'fileList',
            })(
              <Dragger
                accept=".doc,.docx,.pdf,application/pdf,application/msword"
                beforeUpload={() => false}
                showUploadList={{
                  showPreviewIcon: false,
                  showDownloadIcon: false,
                  showRemoveIcon: false,
                }}
                style={{ width: '100%' }}
              >
                <p className="ant-upload-drag-icon">
                  <Icon style={{ color: '#F175A5' }} type="inbox" />
                </p>
                <p className="ant-upload-text">{i18n.t('form__upload_here')}</p>
                <p className="ant-upload-hint">{i18n.t('form__cv__extension__file__2')}</p>
              </Dragger>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Col>
  );
}

Curriculum.propTypes = {
  form: PropTypes.object.isRequired,
};

export default Curriculum;