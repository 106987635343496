import {
  Form,
  Row,
  Col,
  Typography,
  Button,
  Icon,
  Select,
  Menu,
  message,
} from 'antd';
import shuffle from 'lodash.shuffle';

import moment from 'moment';

const { Text, Title } = Typography;

const attendees = ({
  form,
  selectedCandidates,
  collabs,
  owner,
  interviewType,
}) => {
  const { getFieldDecorator, getFieldsValue, getFieldValue, setFieldsValue } =
    form;

  const addCollab = (newCollab) => {
    const attendees = getFieldValue('attendees');
    const collab = attendees.collabs.find(
      (collab) => collab.business_user.email === newCollab.business_user.email
    );
    if (!collab) {
      setFieldsValue({
        attendees: {
          ...attendees,
          collabs: [...attendees.collabs, newCollab],
        },
      });
    }
  };

  const deleteCandidate = (personalId) => {
    const attendees = getFieldValue('attendees');
    const isSingle = getFieldValue('single_or_multi') === 'single';

    if (attendees.candidates.length === 2 && !isSingle) {
      message.error(
        'Debes tener al menos dos candidatos en la entrevista múltiple.'
      );
    } else {
      if (attendees.candidates.length === 1 && isSingle) {
        message.error('Debes tener al menos un candidato en la entrevista.');
      } else {
        setFieldsValue({
          attendees: {
            ...attendees,
            candidates: attendees.candidates.filter(
              (candidate) => candidate.personal_user_id !== personalId
            ),
          },
        });
      }
    }
  };

  const deleteCollab = (email) => {
    const attendees = getFieldValue('attendees');
    setFieldsValue({
      attendees: {
        ...attendees,
        collabs: attendees.collabs.filter(
          (collab) => collab.business_user.email !== email
        ),
      },
    });
  };

  const refreshCandidates = () => {
    const attendees = getFieldValue('attendees');

    setFieldsValue({
      attendees: { ...attendees, candidates: [...selectedCandidates] },
    });
  };

  const shuffleCandidates = () => {
    const attendees = getFieldValue('attendees');

    setFieldsValue({
      attendees: { ...attendees, candidates: shuffle(attendees.candidates) },
    });
  };

  const printattendee = (attendee, type, numberInList) => {
    const [first, second] = attendee.name.split(' ');
    const isSingle = interviewType === 'single';
    const attendees = getFieldValue('attendees');
    const candidates = attendees?.candidates ?? [];

    return (
      <Row
        key={attendee.email}
        type="flex"
        className="schedule-attendees-form__attendee"
      >
        <div className="schedule-attendees-form__attendee__avatar">
          {first[0].toUpperCase()}
          {second && second[0].toUpperCase()}
        </div>
        <div className="schedule-attendees-form__attendee__content">
          <div className="schedule-attendees-form__attendee__texts">
            <Text strong>{attendee.name}</Text>
            <span>{attendee.email}</span>
          </div>
        </div>
        <div className="schedule-attendees-form__attendee__type">
          <Icon type={type === 'Candidato' ? 'user' : 'user-add'} />
          <span>{type}</span>
        </div>
        {type !== 'Organizador' ? (
          <Button
            type="link"
            icon="delete"
            style={{ color: '#FF4D4F' }}
            disabled={interviewType === 'single'}
            onClick={
              type === 'Colaborador'
                ? () => deleteCollab(attendee.email)
                : () => deleteCandidate(attendee.id)
            }
          />
        ) : (
          <Button
            type="link"
            icon="check"
            disabled={true}
            style={{ color: '#52C41A' }}
          />
        )}
      </Row>
    );
  };

  const buildMenu = (props) => {
    let filteredArray = collabs.filter(
      (obj) =>
        obj.business_user.email.includes(props.inputValue) ||
        obj.business_user.name?.includes(props.inputValue)
    );
    filteredArray = filteredArray.sort((a, b) =>
      a.business_user.email.localeCompare(b.business_user.email)
    );
    return (
      <Menu>
        {filteredArray
          .filter((obj) => obj.business_user.email !== owner.email)
          .map((collab, idx) => (
            // The is a bug, on click trigger close the menu before the function call whitout executing addCollab
            // reference https://github.com/ant-design/ant-design/issues/13504
            <Menu.Item key={idx} onMouseDown={() => addCollab(collab)}>
              {collab.business_user.email}
              {` (${collab.business_user.name ?? 'Sin nombre'})`}
            </Menu.Item>
          ))}
      </Menu>
    );
  };

  const sum = getFieldValue('attendees')
    ? Object.values(getFieldValue('attendees')).reduce(
        (acc, current) => (acc += current.length),
        0
      )
    : selectedCandidates.length;

  return (
    <div className="attendees-content">
      <Title level={4}>Asistentes ({sum})</Title>
      {interviewType === 'multi' ? (
        <Row type="flex" justify="space-between">
          <Button
            onClick={refreshCandidates}
            type="link"
            style={{ padding: 0 }}
          >
            Refrescar candidatos
          </Button>
          <Button
            onClick={shuffleCandidates}
            type="link"
            style={{ padding: 0 }}
          >
            Orden aleatorio
          </Button>
        </Row>
      ) : null}
      <Select
        mode="multiple"
        placeholder="Seleccione colaboradores para agregar a la entrevista"
        defaultValue={[]}
        style={{ width: '100%' }}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        dropdownRender={(_menu, props) => buildMenu(props)}
      ></Select>
      <Form.Item style={{ display: 'none' }}>
        {getFieldDecorator('attendees', {
          initialValue: { candidates: selectedCandidates, collabs: [] },
        })(<div />)}
      </Form.Item>
      <Col xs={24}>
        {printattendee(
          {
            name: owner.name ?? 'Sin nombre',
            email: owner.email,
          },
          'Organizador'
        )}
        {getFieldValue('attendees').collabs?.map((collab) =>
          printattendee(
            {
              name: collab.business_user.name ?? 'Sin nombre',
              email: collab.business_user.email,
            },
            'Colaborador'
          )
        )}
        {getFieldValue('attendees').candidates?.map((candidate, index) =>
          printattendee(
            {
              name: candidate.full_name || candidate.name || candidate.user_name,
              email: candidate.email,
              id: candidate.personal_user_id,
            },
            'Candidato',
            index
          )
        )}
      </Col>
    </div>
  );
};

export default attendees;
