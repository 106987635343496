export default (state, action) => {
  switch (action.type) {
    case 'ADD_CAREER':
      return {
        ...state,
        candidate: {
          ...state.candidate,
          careers: [
            ...state.candidate.careers,
            {
              career_area: undefined,
              career_entry_year: undefined,
              career_institution: undefined,
              career_last_year: undefined,
              career_name: undefined,
              career_state: undefined,
              career_type: undefined,
            },
          ],
        },
      }
    case 'ADD_CERTIFICATION':
      return {
        ...state,
        candidate: {
          ...state.candidate,
          certifications: [
            ...state.candidate.certifications,
            {
              name: undefined,
              institution: undefined,
              area: undefined,
              start_date: undefined,
              end_date: undefined,
              length: undefined,
              length_unit: undefined,
              state: undefined,
            },
          ],
        },
      };
    case 'ADD_EXPERIENCE':
      return {
        ...state,
        candidate: {
          ...state.candidate,
          experiences: [
            ...state.candidate.experiences,
            {
              company: undefined,
              country: undefined,
              month_end: undefined,
              month_start: undefined,
              position: undefined,
              year_end: undefined,
              year_start: undefined,
            }
          ],
        },
      };
    case 'JUMP_STEP':
      return {
        ...state,
        currentStep: state.currentStep + action.payload,
      };
    case 'LOAD_CANDIDATE':
      return {
        ...state,
        candidate: action.payload,
      };
    case 'LOAD_COUNTRIES':
      return {
        ...state,
        countries: action.payload,
      };
    case 'LOAD_JOBAPPLICATION':
      return {
        ...state,
        jobApplication: action.payload,
      };
    case 'REMOVE_CAREER': {
      const careers = [...state.candidate.careers];
      const deletedCareer = careers.splice(action.payload, 1)[0];
      const deletedCareers = [...state.deletedCareers];
      if (deletedCareer?.id) deletedCareers.push(deletedCareer.id);
      return {
        ...state,
        candidate: { ...state.candidate, careers },
        deletedCareers,
      };
    }
    case 'REMOVE_CERTIFICATION': {
      const certifications = [...state.candidate.certifications];
      const deletedCertification = certifications.splice(action.payload, 1)[0];
      const deletedCertifications = [...state.deletedCertifications];
      if (deletedCertification?.id) deletedCertifications.push(deletedCertification.id);
      return {
        ...state,
        candidate: { ...state.candidate, certifications },
        deletedCertifications,
      };
    }
    case 'REMOVE_EXPERIENCE': {
      const experiences = [...state.candidate.experiences];
      const deletedExperience = experiences.splice(action.payload, 1)[0];
      const deletedExperiences = [...state.deletedExperiences];
      if (deletedExperience?.id) deletedExperiences.push(deletedExperience.id);
      return {
        ...state,
        candidate: { ...state.candidate, experiences },
        deletedExperiences,
      };
    }
    case 'UPDATE_CANDIDATE':
      return {
        ...state,
        candidate: {
          ...state.candidate,
          [action.payload.field]: action.payload.value,
        },
      };
    case 'UPDATE_CAREER': {
      const careers = [...state.candidate.careers];
      const { careerIdx, careerFieldName, value } = action.payload;
      careers[careerIdx] = { ...careers[careerIdx], [careerFieldName]: value };
      return {
        ...state,
        candidate: { ...state.candidate, careers },
      };
    }
    case 'UPDATE_CERTIFICATION': {
      const certifications = [...state.candidate.certifications];
      const { certificationIdx, certificationFieldName, value } = action.payload;
      certifications[certificationIdx] = { ...certifications[certificationIdx], [certificationFieldName]: value };
      return {
        ...state,
        candidate: { ...state.candidate, certifications },
      };
    }
    case 'UPDATE_EXPERIENCE': {
      const experiences = [...state.candidate.experiences];
      const { experienceIdx, experienceFieldName, value } = action.payload;
      experiences[experienceIdx] = { ...experiences[experienceIdx], [experienceFieldName]: value };
      return {
        ...state,
        candidate: { ...state.candidate, experiences },
      };
    }
    case 'UPDATE_CV_PARSED_INFO':{
      return {
        ...state,
        candidate: {
          ...state.candidate,
          cvParsedInfo: {
            ...state.candidate.cvParsedInfo,
            ...action.payload,
          },
        },
      };
    }
    case 'UPDATE_CV_TYPE':{
      return {
        ...state,
        candidate: {
          ...state.candidate,
          cvType: action.payload,
        }
      }
    }
    case 'UPDATE_CV_STATUS': {
      return {
        ...state,
        cvAlreadySent: action.payload,
      }
    }
    default:
      throw new Error(
        `${action.type} is not supported, check your action.type!`
      );
  }
};
