import React from 'react';
import { Steps, Button, Typography } from 'antd';
import './Wizard.scss';

const { Step } = Steps;
const { Text } = Typography;
const titles = [
  {name:'Información', code:'required'}, 
  {name:'Etapas', code:'required' },
  {name:'Formulario', code:'required'}, 
  {name: 'Evaluaciones', code:'required'}, 
  {name: 'Video entrevista', code:'required'}, 
  {name: 'Entrevistas personalizadas', code:'IKIT'}, 
  {name: 'Resumen', code:'required'}
];

const Wizard = ({ 
  currentStep, 
  onChangeStep, 
  hasSider, 
  ifVideo, 
  ifQuestions, 
  ifGames, 
  addOns}) => {

  const icon = (index) => {
    if (index === 0 || ifQuestions && index === 1 || ifVideo && index === 3 || ifGames && index === 2) {
      return 'check'
    }
  
    return index
  }

  const isCompleted = (index) => {
    return typeof icon(index) !== 'number'
  }

  let userAddOns = addOns.map(elem => elem.add_on);

  return (
  <div className='np__wizard'>
    {/*<Button icon='left' className='main-btn'>Atrás</Button>*/}
    
    <Steps
      size="small" 
      current={currentStep.pos} 
      onChange={onChangeStep} 
      direction={hasSider ? "horizontal" : "vertical"}
    >
      {titles.filter((obj) =>{
        // TODO: PARCHE, SACAR DESPUÉS
        if (obj.name === 'Video entrevista' && userAddOns.includes('NVIN')) {
          return false;
        }
        // FIN PARCHE

        if (userAddOns.includes(obj.code) || obj.code === "required"){
          return true;
        }
      }).map((obj, index) => (
        <Step 
          key={index}
          // className={isCompleted(index) ? 'step-completed' : ''}
          // icon={isCompleted(index) && <img width='10' src='https://i.imgur.com/CYRaFeD.png' />}
          // status={currentStep === index ? 'process' : 'wait'} 
          title={<Text strong>{obj.name}</Text>}
        />
      ))}
      
      {/*<Step status={currentStep === 1 ? 'finish' : 'wait'}  icon={<Icon type="ordered-list" />} title="Etapas" />*/}
    </Steps>
  </div>
)}

export default Wizard;