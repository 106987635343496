import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

import CandidateLoginLayout from "../Login/components/CandidateLoginLayout";

import * as actionCreators from "../../actions/auth";
import i18n from '../../i18n'

import { message, Button, Form, Input, Typography, Row, Col } from "antd";

const { Title, Text, Paragraph } = Typography;

class ChangePasswordView extends React.Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    isAuthenticating: PropTypes.bool.isRequired,
    statusText: PropTypes.string,
    token: PropTypes.string.isRequired,
    actions: PropTypes.shape({
      authChangePassword: PropTypes.func.isRequired,
      authChangePasswordFromRecovery: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }),
  };

  static defaultProps = {
    statusText: "",
    location: null,
  };

  state = {
    showPassword: false,
    password: "",
    passwordConfirm: "",
    errors: {},
    redirectTo: null,
    userType: 'bu',
  };

  handleChange = (prop) => (event) => {
    let tempErrors = this.state.errors;
    tempErrors[prop] = false;
    if (prop === "password") {
      const valid = this.isValidPassword(event.target.value);
      const same = this.isSamePassword(event.target.value, this.state.passwordConfirm);
      this.setState({ confirmDirty: valid && same ? true : false });
    }
    if (prop === "passwordConfirm") {
      const valid = this.isValidPassword(this.state.password);
      const same = this.isSamePassword(this.state.password, event.target.value);
      this.setState({ confirmDirty: valid && same ? true : false });
    }
    this.setState({ errors: tempErrors, [prop]: event.target.value });
    //this.setState({ formValues: value });
  };

  changePassword = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      let token = null;
      if (
        this.isValidPassword(this.state.password) &&
        this.isSamePassword(this.state.password, this.state.passwordConfirm)
      ) {
        if (this.props.match.params["token"] !== undefined) {
          token = this.props.match.params["token"];
          this.props.actions.authChangePasswordFromRecovery(
            token,
            this.state.password,
            this.state.passwordConfirm,
            this.state.redirectTo
          ).then(response => {
            if (response.status>=400) {
              message.error("El enlace ha caducado")
            }
          }
          );
        } else {
          token = this.props.token;
          this.props.actions.authChangePassword(
            token,
            this.state.password,
            this.state.passwordConfirm,
            this.state.redirectTo
          );
        }
      }
    });
  };

  isValidPassword = (password) => {
    const re = this.userType === 'bu' ? /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$%&\*\+\-_:;.=?@~])/ : /^(?=.*\d)(?=.*[A-Z])|(?=.*[a-z]).{8,}$/;
    if (!password.match(re) || password.length < 8) {
      let tempErrors = this.state.errors;
      tempErrors["password"] =
      this.state.userType === 'bu' ? "La contraseña no puede haber sido usada en el último año. Debe tener mínimo 8 caracteres, al menos una letra minúscula, una letra mayúscula, un número y uno de los siguientes símbolos: !#$%&*+-/:;=?@_.~"
      : "Debe tener mínimo 8 caracteres, al menos una letra y un número."
      this.setState({ errors: tempErrors });
      return false;
    }
    return true;
  };

  isSamePassword = (password, passwordConfirm) => {
    if (password !== passwordConfirm) {
      let tempErrors = this.state.errors;
      tempErrors["passwordConfirm"] =
        "La contraseña debe coincidir en ambos campos";
      this.setState({ errors: tempErrors });
      return false;
    }
    return true;
  };

  // Note: have to use componentWillMount, if I add this in constructor will get error:
  // Warning: setState(...): Cannot update during an existing state transition (such as within `render`).
  // Render methods should be a pure function of props and state.
  componentWillMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const next = urlParams.get('next');
    let user = urlParams.get('user');
    user = user ? user : (this.props.userTypeProp === 'business' ? 'bu' : 'pu');
    const baseRedirect = user === 'pu' ? '/login' : '/businesslogin'

    this.setState({
      redirectTo: next && next !== '/' ? next : baseRedirect,
      userType: user
    });
  }

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Las contraseñas son diferentes");
    } else {
      callback();
    }

    const re = this.state.userType === 'bu' ? /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$%&\*\+\-_:;.=?@~])/ : /^(?=.*\d)(?=.*[A-Z])|(?=.*[a-z]).{8,}$/;
    if (!value.match(re)) {
      return callback(true);
    } else {
      return callback();
    }
  };

  validatePassword = (rule, value, callback) => {
    const re = this.state.userType === 'bu' ? /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$%&\*\+\-_:;.=?@~])/ : /^(?=.*\d)(?=.*[A-Z])|(?=.*[a-z]).{8,}$/;
    if (!value.length) {
      return callback();
    }
    if (!value.match(re) || value.length < 8) {
      return callback(true);
    } else {
      return callback();
    }
  };

  render() {
    const { getFieldDecorator, getFieldError } = this.props.form;
    return (
      <CandidateLoginLayout>
        <Col xs={24} sm={12} className='candidate-login__left-side'>
          <Row xs={16}>
            <Row
              className='candidate-login__left-side__header'
            >
              <Row>
                <Typography.Title level={1}>
                  {i18n.t('change__password__change__password')}
                </Typography.Title>
                <Paragraph style={{ marginBottom: 10 }}>
                  <Text>
                    Para continuar, primero debes cambiar tu contraseña.
                  </Text>
                </Paragraph>
                <div>
                  {this.state.userType === 'bu' ?
                    <Text>
                      La contraseña no puede haber sido usada en el último año. Debe tener mínimo 8 caracteres, al menos una letra minúscula, una letra mayúscula, un número y uno de los siguientes símbolos: !#$%&*+-/:;=?@_.~
                    </Text>
                    :
                    <Text>
                      Debe tener mínimo 8 caracteres, al menos una letra y un número.
                    </Text>
                  }
                </div>
              </Row>
            </Row>

            <Form onSubmit={this.changePassword} style={{ marginTop: '1.5em' }}>
              <Form.Item hasFeedback label={i18n.t('commons__new__password')}>
                {getFieldDecorator('password', {
                  onChange: this.handleChange('password'),
                  rules: [
                    {
                      required: true,
                      message: i18n.t('change__password__please__enter__your__password'),
                    //       CHANGE  message:
                    // this.state.userType === 'bu' ? "La contraseña no puede haber sido usada en el último año. Debe tener mínimo 8 caracteres, al menos una letra minúscula, una letra mayúscula, un número y uno de los siguientes símbolos: !#$%&*+-/:;=?@_.~"
                    // : "Debe tener mínimo 8 caracteres, al menos una letra y un número."
                    },
                    {
                    validator: this.validatePassword,
                    },
                    { min: 8 },
                  ],
                })(<Input.Password size="large" placeholder={i18n.t('commons__password')} />)}
              </Form.Item>

              <Form.Item
                hasFeedback
                style={{ marginBottom: '2em' }}
                label={i18n.t('change__password__repeat_password')}
              >
                {getFieldDecorator("confirm", {
                  onChange: this.handleChange("passwordConfirm"),
                  rules: [
                    {
                      required: true,
                      message: i18n.t('start_process__please__confirm__your__password'),
                    },
                    {
                      validator: this.compareToFirstPassword,
                    },
                    { min: 8 },
                  ],
                })(
                  <Input.Password
                    size="large"
                    placeholder={i18n.t('commons__password_confirmation')}
                    onBlur={this.handleConfirmBlur}
                  />
                )}
              </Form.Item>

              <Button
                loading={this.props.isAuthenticating}
                htmlType='submit'
                type='primary'
                size='large'
                disabled={!this.state.confirmDirty}
                block
              >
                {i18n.t('commons__sign_in')}
              </Button>
            </Form>
          </Row>
        </Col>
      </CandidateLoginLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isAuthenticating: state.auth.isAuthenticating,
    userTypeProp: state.auth.userType,
    statusText: state.auth.statusText,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  };
};

const ChangePasswordForm = Form.create({ name: "horizontal_login" })(
  ChangePasswordView
);
export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);
export { ChangePasswordView as ChangePasswordViewNotConnected };
