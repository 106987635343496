import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import appReducer from './app';
import authReducer from './auth';
import dataReducer from './data';
import gamesReducer from './games';
import alertReducer from './alerts';
import traitReducer from './trait';
import jobappReducer from './jobapp';
import videosReducer from './videos';
import modalReducer from './modal';

export default combineReducers({
    app: appReducer,
    auth: authReducer,
    data: dataReducer,
    games: gamesReducer,
    routing: routerReducer,
    alerts: alertReducer,
    trait: traitReducer,
    jobapp: jobappReducer,
    videos: videosReducer,
    modal: modalReducer,
});
