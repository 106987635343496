import {
  Typography
} from "antd";
import React from "react";
import ParseHtml from "../../../../../components/parseHtml";



const ReadOnlyField = ({ paragraphs }) => {
  return (
    paragraphs.map((elem, idx) => {
      return (
        <div key={idx} style={{ width: '100%', marginBottom: 10 }}>
          <Typography.Text style={{ lineHeight: 1.5, userSelect: 'none' }}>
            {ParseHtml(elem)}
          </Typography.Text>
        </div>
      )
    })
  );
};

export default ReadOnlyField;