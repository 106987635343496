import React, { useState } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../actions/data";
import * as authActionCreators from '../../actions/auth';

import './EditBusinessUser.scss';

import {
  Card, Layout, message, Row, Typography
} from "antd";

import Options from "./Plugins/Options";
import ChangeName from "./Plugins/ChangeName";
import ChangePassword from "./Plugins/ChangePassword";

const { Paragraph } = Typography;

const EditBusinessUserView = (props) => {
  const [showOptions, setShowOptions] = useState(true);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showChangeName, setShowChangeName] = useState(false);

  return (
    <Layout>
      <Row className="edit-business-user-profile">
        {/* TODO: Crumbs */}
        <Card bordered={false} className="main-card" title="Mi cuenta">
          <Paragraph style={{ fontSize: "16px", marginBottom: "2em" }}>
            Desde esta sección podrás configurar tu cuenta en Genomawork: cambiar tu contraseña y nombre, activar y desactivar notificaciones y configurar la autenticación multifactor.
          </Paragraph>
          {showOptions && (
            <Options 
              email={props.email} 
              name={props.name}
              selectChangePassword={() => {
                setShowOptions(false);
                setShowChangePassword(true);
              }}
              selectChangeName={() => {
                setShowOptions(false);
                setShowChangeName(true);
              }}
              businessPermissions={props.businessPermissions}
            />
          )}
          {showChangePassword && (
            <ChangePassword 
              goBackToOptions={({responseReceived, content}) => {
                setShowOptions(true);
                setShowChangePassword(false);
                if (responseReceived && responseReceived === 200) {
                  message.success({
                    content,
                    duration: 5,
                  });
                }
              }}
              token={props.token}
              fetchAndReturn={props.actions.fetchAndReturn}
              userId={props.userId}
              login={props.authActions.authLoginUser}
              email={props.email}
            />
          )}
          {showChangeName && (
            <ChangeName
              goBackToOptions={({responseReceived, content}) => {
                setShowOptions(true);
                setShowChangeName(false);
                if (responseReceived && responseReceived === 200) {
                  message.success({
                    content,
                    duration: 5,
                  });
                }
              }}
              token={props.token}
              fetchAndReturn={props.actions.fetchAndReturn}
              userId={props.userId}
              login={props.authActions.authLoginUser}
              email={props.email}
            />
          )}
        </Card>
      </Row>
    </Layout>
  )
};

EditBusinessUserView.propTypes = {
  businessPermissions: PropTypes.shape({
    business_admin: PropTypes.bool.isRequired,
    can_manage: PropTypes.bool.isRequired,
    can_invite: PropTypes.bool,
    can_create_cell: PropTypes.bool,
    can_view_all_jobapps: PropTypes.bool,
  }),
  email: PropTypes.string,
  name: PropTypes.string,
  token: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    businessPermissions: state.auth.businessPermissions,
    email: state.auth.userName,
    name: state.auth.name,
    token: state.auth.token,
    userId: state.auth.userID,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    authActions: bindActionCreators(authActionCreators, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditBusinessUserView);
