import React, { useContext } from 'react'
import { Row, Button } from 'antd'

import { AnalyticsContext } from '../../context/AnalyticsContext';

const BottomLinks = () => {

const { setCurrentSection } = useContext(AnalyticsContext)

  return (
    <Row type='flex' justify='start' className='bottom-refs-buttons'>
      <Button
        icon='home'
        type='link'
        onClick={() => setCurrentSection('overview')}
      >
        Resumen
      </Button>
      <Button
        icon='idcard'
        type='link'
        onClick={() => setCurrentSection('candidates')}
      >
        Candidatos
      </Button>
      <Button
        icon='line-chart'
        type='link'
        onClick={() => setCurrentSection('hiring')}
      >
        Contrataciones
      </Button>
      <Button
        icon='like'
        type='link'
        onClick={() => setCurrentSection('evaluations')}
      >
        Evaluaciones
      </Button>
    </Row>
  );
};

export default BottomLinks;
