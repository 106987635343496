import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import * as Sentry from '@sentry/browser';

import * as actionCreators from '../../../actions/data';

import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  Icon,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Select,
  Switch,
  Tooltip,
  Typography,
} from 'antd';

const { Item } = Breadcrumb;
const { Option } = Select;
const { Paragraph, Text, Title } = Typography;

const CustomFieldForm = (props) => {
  const { getFieldDecorator, getFieldValue } = props.form;

  const [options, setOptions] = useState(
    props.location?.state?.options || [undefined, undefined, undefined]
  );

  const submitData = (e) => {
    e.preventDefault();
    props.form.validateFields((err, fieldsValues) => {
      if (!err) {
        const url = `/api/v1/gamesandtests/business/${
          props.business.id
        }/customfields/${
          props.match.params['id'] ? `${props.match.params['id']}/` : ''
        }`;
        const method = props.match.params['id'] ? 'PATCH' : 'POST';
        const {
          name,
          description,
          active,
          fieldType: field_type,
          valueType: value_type,
        } = fieldsValues;
        const data = {
          name,
          description,
          active,
          field_type,
          value_type,
        };
        if (value_type.includes('selection')) {
          data.options = [];
          const options_count = Object.keys(fieldsValues).filter((key) =>
            key.includes('option')
          ).length;
          for (let i = 0; i < options_count; i++) {
            const option = fieldsValues[`option-${i}`];
            if (option && option.length > 0) {
              data.options.push(option);
            }
          }
        }
        props.actions
          .fetchAndReturn(props.token, url, method, data)
          .then((response) => {
            if (response.status >= 400) {
              if (response.status == 403) {
                message.error(i18n.t('commons__permissions_error'));
              }
              else {
                message.error('Cambios no pudieron ser guardados');
              }
            } else {
              message.success('Cambios guardados');
              props.dispatch(push('/customfields'));
            }
          })
          .catch((error) => {
            Sentry.captureException(error);
            message.error('Cambios no pudieron ser guardados');
          });
      }
    });
  };

  return (
    <Layout style={{ margin: '30px 40px 30px 40px' }}>
      <Row style={{ marginBottom: '1.5em' }}>
        <Breadcrumb separator=">">
          <Item>
            <Link to="/tools">Mis Herramientas</Link>
          </Item>
          <Item>
            <Link to="/tools">Herramientas de Gestión</Link>
          </Item>
          <Item>
            <Link to="/customfields">Atributos personalizados</Link>
          </Item>
          <Item>
            {props.match.params['id'] ? 'Editar ' : 'Crear '}atributo
            personalizado
          </Item>
        </Breadcrumb>
      </Row>
      <Row type="flex" align="middle" style={{ marginBottom: '1em' }}>
        <Col style={{ marginRight: '0.75em' }}>
          <Icon
            onClick={() =>
              Modal.confirm({
                cancelText: 'Cancelar',
                content: 'Perderás todos los cambios no guardados.',
                okText: 'Confirmar',
                onOk: () => props.dispatch(push('/customfields')),
                title: '¿Estás seguro de querer volver atrás?',
              })
            }
            type="arrow-left"
          />
        </Col>
        <Col>
          <Title level={2} style={{ color: '#2B2B2B', margin: 0 }}>
            {props.match.params['id'] ? 'Editar ' : 'Crear '}atributo
            personalizado
          </Title>
        </Col>
      </Row>
      {!props.match.params['id'] && (
        <Row style={{ marginBottom: '2em' }}>
          <Paragraph
            style={{
              color: '#5E5E5E',
              fontSize: '16px',
              fontWeight: 'normal',
              marginBottom: '0px',
            }}
          >
            Configura el nuevo atributo personalizado que usarás en tus
            proyectos
          </Paragraph>
        </Row>
      )}
      <Row>
        <Card style={{ border: '1px solid #D9D9D9' }}>
          <Form onSubmit={submitData}>
            <Form.Item
              help={
                <Row justify="space-between" type="flex">
                  <Col>
                    {getFieldValue('name')?.length == 0 ||
                    props.location?.state?.name?.length == 0
                      ? 'Obligatorio'
                      : ''}
                  </Col>
                  <Col>
                    {`${
                      getFieldValue('name')?.length ??
                      props.location?.state?.name?.length ??
                      0
                    }/200`}
                  </Col>
                </Row>
              }
              label={
                <Text>
                  <span style={{ color: 'red' }}>*</span> Nombre del atributo
                </Text>
              }
            >
              {getFieldDecorator('name', {
                initialValue: props.location?.state?.name,
                rules: [{ required: true, message: 'Obligatorio' }],
              })(<Input maxLength={200} />)}
            </Form.Item>
            <Form.Item
              help={
                <Row justify="end" type="flex">
                  {`${
                    getFieldValue('description')?.length ??
                    props.location?.state?.description?.length ??
                    0
                  }/1000`}
                </Row>
              }
              label="Descripción"
            >
              {getFieldDecorator('description', {
                initialValue: props.location?.state?.description,
              })(<Input.TextArea maxLength={1000} />)}
            </Form.Item>
            <Form.Item
              colon={false}
              label={
                <>
                  <Text>
                    <span style={{ color: 'red' }}>*</span>
                    {' Entidad: '}
                    <Tooltip
                      placement="right"
                      title="Click aquí para más información"
                    >
                      <Icon
                        type="info-circle"
                        onClick={() =>
                          Modal.info({
                            content: (
                              <Paragraph>
                                Hace referencia a <strong>dónde</strong> se
                                habilitará el atributo. Las entidades
                                disponibles son:
                                <ul>
                                  <li style={{ listStyleType: 'initial' }}>
                                    <strong>Procesos de selección</strong>.
                                    Visibles desde la opción "Ver detalles" de
                                    cada proceso, y gestionables desde la
                                    edición de cada proceso.
                                  </li>
                                  <li style={{ listStyleType: 'initial' }}>
                                    <strong>Postulaciones</strong> de candidatos
                                    a procesos específicos. Visibles y
                                    gestionables desde el perfil del candidato
                                    en el proceso respectivo. Importante tener
                                    en cuenta que para un mismo candidato, los
                                    atributos personalizados de sus
                                    postulaciones variarán de un proceso a otro.
                                  </li>
                                  <li style={{ listStyleType: 'initial' }}>
                                    <strong>Candidatos</strong>. Visibles y
                                    gestionables desde{' '}
                                    <strong>cualquier</strong> perfil del
                                    candidato, y <strong>transversales</strong>{' '}
                                    entre sus distintas postulaciones.
                                  </li>
                                </ul>
                              </Paragraph>
                            ),
                            okText: 'Ok',
                            title: 'Entidad',
                            width: 500,
                          })
                        }
                      />
                    </Tooltip>
                  </Text>
                </>
              }
            >
              {getFieldDecorator('fieldType', {
                initialValue: props.location?.state?.field_type,
                rules: [{ required: true, message: 'Obligatorio' }],
              })(
                <Select>
                  {getFieldValue('valueType') !== 'file' ? (
                    <Option value="JOBAPP">Proceso</Option>
                  ) : null}
                  <Option value="USERJA">
                    Postulación (de un candidato a un proceso específico)
                  </Option>
                  <Option value="CANDID">Candidato</Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item
              colon={false}
              label={
                <Text>
                  <span style={{ color: 'red' }}>*</span>
                  {' Tipo de campo: '}
                  <Tooltip
                    placement="right"
                    title="Tipo de dato que se almacenará en este atributo"
                  >
                    <Icon type="info-circle" />
                  </Tooltip>
                </Text>
              }
            >
              {getFieldDecorator('valueType', {
                initialValue: props.location?.state?.value_type,
                rules: [{ required: true, message: 'Obligatorio' }],
              })(
                <Select>
                  <Option value="text">Texto</Option>
                  <Option value="number">Numérico</Option>
                  <Option value="boolean">Sí/No</Option>
                  <Option value="date">Fecha</Option>
                  <Option value="singleselection">Selección única</Option>
                  <Option value="multiselection">Selección múltiple</Option>
                  {getFieldValue('fieldType') !== 'JOBAPP' ? (
                    <Option value="file">Archivo</Option>
                  ) : null}
                </Select>
              )}
            </Form.Item>
            {getFieldValue('valueType') &&
              getFieldValue('valueType').includes('selection') && (
                <Form.Item
                  label={
                    <Text>
                      <span style={{ color: 'red' }}>*</span> Opciones
                    </Text>
                  }
                >
                  {options.map((option, index) => (
                    <Row
                      align="middle"
                      key={`option-${index}-${option}`}
                      style={{ marginBottom: '10px' }}
                      type="flex"
                    >
                      <Col span={23}>
                        {getFieldDecorator(`option-${index}`, {
                          initialValue: option,
                        })(
                          <Input
                            key={index}
                            placeholder={`Opción ${index + 1}`}
                          />
                        )}
                      </Col>
                      <Col span={1} style={{ textAlign: 'center' }}>
                        <Icon
                          disabled={options.length < 3}
                          onClick={() =>
                            setOptions((prevOptions) =>
                              prevOptions.filter((_, i) => i !== index)
                            )
                          }
                          type="delete"
                        />
                      </Col>
                    </Row>
                  ))}
                  <Button
                    onClick={() =>
                      setOptions((prevOptions) => [...prevOptions, undefined])
                    }
                    type="link"
                  >
                    <Icon type="plus-circle" /> Agregar opción
                  </Button>
                </Form.Item>
              )}
            <Form.Item
              colon={false}
              label={
                <Text
                  onClick={(e) => {
                    // the switch was changing when clicking on the text
                    // this prevents that
                    e.preventDefault();
                  }}
                >
                  {'Activo: '}
                  <Tooltip
                    placement="right"
                    title="Solo los atributos activos se mostrarán en las respectivas entidades."
                  >
                    <Icon type="info-circle" />
                  </Tooltip>
                </Text>
              }
            >
              {getFieldDecorator('active', {
                initialValue: props.location?.state?.active,
                valuePropName: 'checked',
              })(
                <Switch
                  checkedChildren={<Icon type="check" />}
                  unCheckedChildren={<Icon type="close" />}
                />
              )}
            </Form.Item>
            <Form.Item>
              <Row type="flex" justify="end" style={{ width: '100%' }}>
                <Col style={{ marginRight: '20px' }}>
                  <Button
                    onClick={() =>
                      Modal.confirm({
                        cancelText: 'Cancelar',
                        content: 'Perderás todos los cambios no guardados.',
                        okText: 'Confirmar',
                        onOk: () => props.dispatch(push('/customfields')),
                        title: '¿Estás seguro de querer volver atrás?',
                      })
                    }
                  >
                    Cancelar
                  </Button>
                </Col>
                <Col>
                  <Button htmlType="submit" type="primary">
                    Guardar cambios
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      </Row>
    </Layout>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

const mapStateToProps = (state) => {
  return {
    business: state.auth.currentBusiness,
    token: state.auth.token,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'custom-field-form' })(CustomFieldForm));
