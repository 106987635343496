import React, { useState } from 'react';
import PropTypes from "prop-types";

import { 
  Button, Card, Col, Icon, Input, Form, message, Row, Typography 
} from 'antd';

const { Password } = Input;
const { Title } = Typography;

const ChangeName = (props) => {
  const [loading, setLoading] = useState();
  const { getFieldDecorator, validateFields } = props.form;

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields((errors, values) => {
      if (!errors) {
        setLoading(true);
        const { email, fetchAndReturn, login, token, userId } = props;
        let url = `/api/v1/accounts/businessusers/${userId}/`;
        const body = {
          'name': values.newName,
          'old_password': values.password,
        };
        fetchAndReturn(token, url, 'patch', body).then((response) => {
          if (response.status >= 400) {
            if (response.message) {
              if (response.message == 'Invalid credentials.') {
                message.error({
                  duration: 5,
                  content: 'Contraseña actual inválida',
                });
              }
            } else {
              message.error({
                duration: 5,
                content: 'No fue posible actualizar el nombre',
              });
            }
            setLoading(false);
          } else {
            url = 'api/v1/accounts/logout/';
            fetchAndReturn(token, url, 'post').then(() => {
              login(email, values.password, '/account/businessuser', '').then(() => {
                props.goBackToOptions({
                  responseReceived: response.status, 
                  content: "Nombre modificado exitosamente",
                });
              });
            });
          }
        });
      }
    })
  }

  return (
    <>
      <Row 
        type="flex" 
        align="middle"
        style={{
          marginBottom: "2em",
        }}
      >
        <Col>
          <Icon 
            onClick={props.goBackToOptions}
            type="left"
            style={{ 
              color: "#A4A4A4",
              marginRight: "10px",
              paddingTop: "5px",
            }} 
          />
        </Col>
        <Col>
          <Title
            style={{
              color: "#454545",
              fontWeight: 700,
              fontSize: "20px",
              margin: 0,
              padding: 0,
            }}
          >
            Cambiar nombre
          </Title>
        </Col>
      </Row>
      <Row>
        <Card className="change-password">
          <Row>
            <Col 
              span={10} 
              style={{ height: "100%", paddingRight: "1em" }}
            >
              <Form onSubmit={handleSubmit}>
                <Form.Item required>
                  <Title>
                    Nuevo nombre
                  </Title>
                  {getFieldDecorator('newName', {
                    rules: [
                      {
                        required: true,
                        message: "Debes ingresar el nuevo nombre.",
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
                <Form.Item required>
                  <Title>
                    Contraseña actual
                  </Title>
                  {getFieldDecorator('password', {
                    rules: [
                      {
                        required: true,
                        message: "Debes ingresar tu contraseña actual.",
                      }
                    ],
                  })(<Password />)}
                </Form.Item>
                <Row>
                  <Form.Item>
                    <Button 
                      onClick={props.goBackToOptions}
                      style={{ marginRight: "1em" }}
                    >
                      Cancelar
                    </Button>
                    <Button type="primary" htmlType="submit" loading={loading}>
                      Cambiar nombre
                    </Button>
                  </Form.Item>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card>
      </Row>
    </>
  );
};

ChangeName.propTypes = {
  email: PropTypes.string,
  goBackToOptions: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  fetchAndReturn: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
};

const ChangeNameForm = Form.create()(ChangeName);

export default ChangeNameForm;
