import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card, Progress } from "antd";

const AppSummary = props => {
  const { items } = props;

  const levelOne = items.filter(e => e.code === 'FORM' || e.code === 'KO');
  const levelTwo = items.filter(
    e => e.code !== 'FORM' && e.code !== 'KO' && !['ALSP','ALST'].includes(e.code)
  );

  const completed = (level) => level.filter(
    item => item.progress.status === 'FINISHD' || item.progress.status === 'DONE'
  ).length

  const tower = 'https://genoma-assets.s3.us-east-2.amazonaws.com/Castle.svg';
  const towerWthoutFlag = 'https://genoma-assets.s3.us-east-2.amazonaws.com/Castle.svg';

  return (
    <Fragment>
      <Card className='app-summary'>
        <div className='levels'>
          {levelOne.length !== 0 && (
            <div className='level-one'>
              <div className='wrap'>
                <Progress
                  type='circle'
                  strokeWidth={11}
                  percent={completed(levelOne) * 100 / levelOne.length}
                  format={() => '0'}
                />
                <img
                  src={completed(levelOne) === levelOne.length ? tower : towerWthoutFlag}
                />
              </div>
            </div>)}

          {levelTwo.length !== 0 && (
            <div className='level-two'>
              <div className='wrap'>
                <Progress
                  type='circle'
                  strokeWidth={11}
                  percent={completed(levelTwo) * 100 / levelTwo.length}
                  format={() => '0'}
                  />
                <img
                  src={completed(levelTwo) === levelTwo.length ? tower : towerWthoutFlag}
                />
              </div>
            </div>)}
        </div>
      </Card>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    items: state.data.data.items,
  };
};

export default connect(mapStateToProps)(AppSummary);
