
export const onCreateNotification = `
    subscription OnCreateNotification($userId: String!){
        onCreateNotification(userId: $userId) {
            extraData
            id
            type
            userId
        }
    }
`;
