import React from "react";
import moment from 'moment'

import {
  Row,
  Col,
  Icon,
  Typography,
} from 'antd';

import ParseHtml from "../parseHtml";
import PropTypes from 'prop-types';

/**
 * 
 * @param {Object} msgData
 * @param {React.Node} avatar
 * @param {string} position <'start','end'> This is used to make the chat like structure
 * @returns React.Node
 */
function Message({ msgData, avatar, position }) {

  /**
   * This fn takes a Date in +0000 timezone, and shows the formatted date using the local timezone of the client eg: in AR 13pm is 12pm in Chile
   * @param {*} msgDate 
   * @returns 
   */
  const dateToCurrentLocale = (msgDate) => {
    const newDate = moment(msgDate, 'DD-MM-YYYY HH:mm:ss Zz').local().format('DD-MM-YYYY HH:mm');
    return newDate;
  }

  const MsgIcon = () => (
    <Col xs={5} sm={5} md={5} lg={3}>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
        {avatar}
      </div>
    </Col>
  )

  return (
    <Row type='flex' align='top' justify={position}>
      {
        position === 'start' &&
        <MsgIcon />
      }
      <Col xs={19} sm={19} md={19} lg={21}>
        <div className={`message ${position}`}>
          {
            msgData.subject &&
            <div className={'message-content__subject'}>
              {msgData.subject}
            </div>
          }
          <div className={'message-content'}>
            {ParseHtml(msgData.msg_content)}
          </div>
          <div className="msg-signature">
            <Typography.Text>
              {msgData.signature}
            </Typography.Text>
            <Icon style={{ fontSize: 10, margin: '0px 5px 0px 5px' }} type="minus" />
            <Typography.Text>
              {dateToCurrentLocale(msgData.date)}
            </Typography.Text>
          </div>
        </div>
      </Col>
      {
        position === 'end' &&
        <MsgIcon />
      }
    </Row >
  );
};

Message.propTypes = {
  msgData: PropTypes.shape({
    msg_content: PropTypes.string,
    signature: PropTypes.string,
    date: PropTypes.string,
    sender: PropTypes.string
  }),
  avatar: PropTypes.object,
  position: PropTypes.string,
};

export default Message;