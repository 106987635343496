import React, { useContext } from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { Col, Row, Typography, Table, Badge, Button } from 'antd';

import TemplateCard from './components/TemplateCard';

import { AnalyticsContext } from '../context/AnalyticsContext';
import { processNivoData, formatUTMSrcName, keysToUpperCase } from '../assets/utils';
import i18n from '../../../../i18n';

const { Text } = Typography

const Hiring = ({ dispatch }) => {
  const { sectionsData: { hiring }, jobAppId } = useContext(AnalyticsContext)

  const {
    ages,
    age_avg,
    candidate_timeline,
    disabilities,
    disability_rate,
    education_levels,
    fit_distribution,
    genders,
    hired_versus_vacancies_rate,
    hiring_per_jobapp,
    hiring_rate,
    hiring_time_avg,
    last_hiring,
    nationalities,
    total_hired,
    utm_sources,
  } = hiring;

  const nationality_higher = nationalities.reduce((acc, v) => {
    if (acc[1] < v.count && v.nationality !== '') {
      return [v.nationality, v.count];
    }
    return acc;
  }, ['', 0])[0];

  const nationality_higher_code = {
    'chile': 'CL',
    'perú': 'PE',
    'peru': 'PE',
    'colombia': 'CO',
    'argentina': 'AR',
    'venezuela': 'VE',
    'bolivia': 'BO',
    'ecuador': 'EC',
    'uruguay': 'UY',
    'paraguay': 'PY',
    'brasil': 'BR',
    'méxico': 'MX',
    'guatemala': 'GT',
    'honduras': 'HN',
    'el Salvador': 'SV',
    'nicaragua': 'NI',
    'costa rica': 'CR',
    'panamá': 'PA',
    'puerto rico': 'PR',
    'paraguay': 'PY',
    'república dominicana': 'DO',
    'españa': 'ES',
    'estados unidos': 'US',
    'canadá': 'CA',
  }[nationality_higher?.toLowerCase()]

  const nationality_avg = { code: nationality_higher_code, label: nationality_higher }
  const getBestSource = () => {
    const src = utm_sources.filter(x => x.source)[0]?.source
    return src ? src.charAt(0).toUpperCase() + src.slice(1).split('_').join(' ') : null;
  }

  const columns = [
    {
      title: 'NOMBRE',
      dataIndex: 'personal_user_name',
      key: 'personal_user_name',
      render: (e, jobapp) => {
        return (
          <Row type='flex' align='middle' style={{ height: '100%' }}>
            <Text>{e}</Text>
          </Row>
        )
      }
    },
    {
      title: 'PROCESO',
      dataIndex: 'job_application_name',
      key: 'job_application_name',
      render: (e, jobapp) => {
        return (
          <Row type='flex' align='middle' style={{ height: '100%' }}>
            <Col span={22}>
              <Row type='flex' justify='start' align='middle'>
                <Badge status={jobapp.status ? 'success' : 'error'} />
                <Text strong>{e}</Text>
              </Row>
            </Col>
            <Col span={2}>
              <Row type='flex' justify='end' align='middle'>
                <Button icon='eye' onClick={() => dispatch(push(`/processes/${jobapp.user_job_application__job_application_id}`))} />
              </Row>
            </Col>
          </Row>
        )
      }
    },
    {
      title: 'FECHA DE CONTRATACIÓN',
      dataIndex: 'date_added',
      key: 'date_added',
      render: (e) => (
        <Row type='flex' align='middle' style={{ height: '100%' }}>
          <Text>{(new Date(e)).toLocaleDateString()}</Text>
        </Row>
      )
    },
  ];

  return (
    <Col xs={24} className="new-analytics__content">
      <Row style={{ margin: '0 0 15px 0' }}>
        <Text>Visión general</Text>
      </Row>
      <Row type='flex' gutter={[20, 20]}>
        <Col xs={24} lg={12}>
          <TemplateCard
            templateText
            data={total_hired}
            height={392}
            title='Contrataciones totales'
            text='contrataciones realizadas en los procesos seleccionados.'
          />
        </Col>
        <Col xs={24} lg={12}>
          <TemplateCard
            templateGraphic
            height={250}
            graphicType='timeline'
            title='Timeline de contrataciones'
            data={processNivoData(candidate_timeline, ['month', 'year'], '-')}
          />
        </Col>
      </Row>
      {!jobAppId && (
        <Row style={{ border: '1px solid #D9D9D9', background: 'white', marginBottom: 24 }}>
          <Row type='flex' align='middle' style={{ borderBottom: '1px solid #D9D9D9', height: '42px' }}>
            <Text style={{ padding: '10px' }}>Últimos procesos con contrataciones</Text>
          </Row>
          <Row>
            <Table rowKey={'name'} columns={columns} style={{ margin: '20px' }} dataSource={last_hiring} bordered pagination={false} />
          </Row>
        </Row>
      )}
      <Row type='flex' gutter={[20, 20]} className='card-print'>
        {jobAppId && (
          <Col xs={24} lg={12}>
            <TemplateCard
              templateGraphic
              height={300}
              topic='FIT'
              graphicType='pieChart'
              title={i18n.t('analytics__fit_distribution')}
              extra={i18n.t('analytics__fit_distribution_extra')}
              data={processNivoData(fit_distribution, ['fit'])}
              customMargin={{ top: 40, right: 20, bottom: 20, left: 40 }}
            />
          </Col>
        )}
        <Col xs={24} lg={12}>
          <TemplateCard
            templateText
            data={hired_versus_vacancies_rate ? `${(hired_versus_vacancies_rate * 100).toFixed(2)}%` : null}
            title={i18n.t('analytics__occupation_rate')}
            text={i18n.t('analytics__occupation_rate_text')}
            extra={i18n.t('analytics__occupation_rate_info')}
            height={340}
          />
        </Col>
      </Row>

      <Row style={{ margin: '30px 0 15px 0' }}>
        <Text>{i18n.t('analytics__utm_source')}</Text>
      </Row>

      <Row type='flex' gutter={[20, 20]}>
        <Col xs={24} lg={12}>
          <TemplateCard
            templateGraphic
            topic='channel'
            graphicType='bar'
            title={i18n.t('analytics__utm_sources')}
            extra={i18n.t('analytics__utm_sources_info')}
            customDirection='horizontal'
            data={processNivoData(keysToUpperCase(utm_sources), ['source'])}
            height={300}
            customMargin={{ top: 0, right: 20, bottom: 30, left: 85 }}
          />
        </Col>
        <Col xs={24} lg={12}>
          <TemplateCard
            templateText
            height={342}
            data={formatUTMSrcName(getBestSource())}
            title='Canal de reclutamiento de mayor impacto'
            text='Es la fuente de reclutamiento a la que se le atribuyen más contrataciones en base a 
            los procesos seleccionados.'
            flexDirection='column'
            fontSize='40px'
          />
        </Col>
      </Row>

      <Row style={{ margin: '30px 0 15px 0' }}>
        <Text>Eficiencia del proceso</Text>
      </Row>

      <Row type='flex' gutter={[20, 20]}>
        <Col xs={24} lg={12}>
          <TemplateCard
            title='Tiempo de contratación media'
            templateText
            data={hiring_time_avg ? `${hiring_time_avg.toFixed(1)} Días` : null}
            text='Es el tiempo promedio que transcurre desde la apertura de un proceso de selección hasta la contratación del primer candidato, 
            considerando los procesos seleccionados.'
            height={250}
          />
        </Col>
        <Col xs={24} lg={12}>
          <TemplateCard
            title='Media de contrataciones'
            templateText
            data={hiring_per_jobapp ? `${hiring_per_jobapp.toFixed(2)} contrataciones` : null}
            text='Son realizadas por proceso, en promedio, en base a 
            los procesos seleccionados.'
            height={250}
          />
        </Col>
        <Col xs={24} lg={24}>
          <TemplateCard
            title='Tasa de contratación media'
            templateText
            data={hiring_rate ? `${hiring_rate.toFixed(0)}%` : null}
            text='En promedio, del total de candidatos a un proceso se traducen en una contratación. Considerando los procesos seleccionados.'
            height={250}
          />
        </Col>
      </Row>

      <Row style={{ margin: '30px 0 15px 0' }}>
        <Text>¿Qué tan diversas están siendo tus contrataciones?</Text>
      </Row>

      <Row gutter={[20, 20]}>
        <Col xs={24} lg={18} >
          <TemplateCard
            templateGraphic
            graphicType='geoMap'
            data={processNivoData(nationalities, ['nationality'])}
            height={250}
            title='Nacionalidad - Contrataciones'
            text={nationality_avg ? nationality_avg : null}
          />

          <TemplateCard
            templateGraphic
            height={350}
            graphicType='bar'
            topic='age'
            data={processNivoData(ages, ['age_range'])}
            customMargin={{ top: 40, right: 20, bottom: 40, left: 50 }}
            title='Edad - Contrataciones'
            customDirection='vertical'
            text='años'
          />

        </Col>
        <Col xs={24} lg={6}>
          <TemplateCard
            templateGraphic
            topic='gender-hired'
            graphicType='pieChart'
            title='Género - Contrataciones'
            text={genders.length ?
              genders.reduce(function (prev, current) {
                return (prev && prev.count > current.count) ? prev : current
              }) :
              null}
            data={processNivoData(genders, ['gender'])}
            height={320}
            customMargin={{ top: 70, right: 5, bottom: 100, left: 0 }}
            translateX={10}
            translateY={150}
          />
        </Col>
      </Row>
      <Row type='flex' justify='center' style={{ margin: '30px 0 42px 0' }}>
        <Button icon='to-top' onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          Volver arriba
        </Button>
      </Row>
    </Col>
  );
}

const mapStateToProps = () => ({

})
const mapDispatchToProps = (dispatch) => ({
  dispatch
})
export default connect(mapStateToProps, mapDispatchToProps)(Hiring);

