import React, { Fragment } from "react";
import { Typography, Row, Col } from 'antd';
import TraitWeight from './TraitWeight'
import { connect } from 'react-redux';

const styles = {
    heading: {
        fontWeight: "300",
        lineHeight: 1.0
    },
}

const { Text } = Typography;

const TraitList = props => {

    const { traits, totalPoints } = props;
    return (
        <div className='trait-list-item'>
            <Row type="flex" justify="end">
                <Col xs={20} sm={20} style={{ display: 'flex', justifyContent: 'end'}}>
                    {traits.length !== 0 &&
                        <Row style={{ display: 'grid' }}>
                            <Text strong className={styles.heading}>
                                Genoma ideal
                            </Text>
                            {props.showDistribution &&
                                <Text strong type='secondary'>
                                    <img style={{ height: '20px' }} src='https://i.imgur.com/Sh7gktO.png' /> Promedio
                                </Text>
                            }
                        </Row>
                    }
                </Col>

                <Col xs={4} sm={4} style={{ display: 'flex', justifyContent: 'end' }}>
                    <Text strong className={styles.heading}>
                        Relevancia {totalPoints !== 0 ? Math.round(traits.reduce((init, next) => init + (next.points), 0) * 100 / totalPoints) : 0}%
                        </Text>
                </Col>
            </Row>
            {traits.length ? (
                <div className={props.showDistribution && 'with-distribution'}>
                    {traits.map((trait, i) => {
                        return <TraitWeight key={i} {...trait} showDistribution={props.showDistribution} />
                    })}
                </div>
            ) : (
                    <div className='no-data'>
                        <Text>No hay juegos seleccionados para esta categoría</Text>
                    </div>
                )}
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        totalPoints: state.trait.totalPoints
    }
}
export default connect(mapStateToProps)(TraitList);
