import { Button, Row, Tag, Tooltip, Typography } from 'antd';
import i18n from '../../i18n';

const { Text } = Typography;

const months = {
  0: 'ene.',
  1: 'feb.',
  2: 'mar.',
  3: 'abr.',
  4: 'may.',
  5: 'jun.',
  6: 'jul.',
  7: 'ago.',
  8: 'sep.',
  9: 'oct.',
  10: 'nov.',
  11: 'dic.',
};

export function columns(setOpenCandidate, setPreviewCv) {
  return [
    {
      title: 'Nombre',
      dataIndex: 'full_name',
      fixed: 'left',
      key: 'name',
      render: (name, record) => (
        <Button
          onClick={() => setOpenCandidate(record)}
          style={{ textAlign: 'left', width: '100%' }}
          type='link'
        >
          <Text strong style={{ textAlign: 'left', whiteSpace: 'break-spaces' }}>
            {(name && name.replaceAll(' ', '') !== '') ? name : 'No registra nombre'}
          </Text>
        </Button>
      ),
      width: 250,
    },
    {
      title: 'Correo',
      dataIndex: 'email',
      fixed: 'left',
      key: 'email',
      render: (email) => (<Text style={{ color: '#A4A4A4' }}>{email}</Text>),
      width: 150,
    },
    {
      title: 'CV',
      dataIndex: 'cv',
      key: 'cv',
      render: (cvUrl) => {
        return (
          <Button
            disabled={!cvUrl}
            icon='file-pdf'
            onClick={() => cvUrl && setPreviewCv(cvUrl)}
            style={{ color: cvUrl ? '#F175A5' : '#D9D9D9', fontSize: '20px' }}
            type='link'
          />
        )
      },
      width: 80,
    },
    {
      title: 'Tipo de candidato',
      dataIndex: 'candidate_type',
      key: 'candidate_type',
      render: (candidateType) => {
        switch(candidateType) {
          case 'active':
            return <Tag color='green'>Activo</Tag>
          case 'passive':
            return <Tag color='blue'>Pasivo</Tag>
          case 'seed':
            return <Tag color='pink'>Clonación</Tag>
          default:
            return <Tag color='gray'>-</Tag>
        }
      },
      width: 150,
    },
    // TODO: Ubicación
    {
      title: 'Etiquetas',
      dataIndex: 'tags',
      key: 'tags',
      render: (tags) => (
        <>
          {tags?.slice(0,2).map((t, key) => (<Tag key={key}>{t}</Tag>))}
          {tags?.length > 2 && (
            <Tooltip
              placement='bottom'
              title={(
                tags.slice(2).map((tag) => (
                  <Row style={{ marginBottom: '5px' }} type="flex">
                    <Tag key={tag}>{tag}</Tag>
                  </Row>
                ))
              )}
            >
              <Tag key="extra">...</Tag>
            </Tooltip>
          )}
        </>
      )
    },
    {
      title: 'Experiencia laboral',
      dataIndex: 'experience',
      key: 'experience',
      render: (experience) => {
        return (experience ? (
          <>
            <Text style={{ color: '#A4A4A4' }}>{experience.position}</Text>
            <br />
            <Text style={{ color: '#A4A4A4' }}>
              {months[experience.month_start]} {experience.year_start} - {experience.year_end ? `${months[experience.month_end]} ${experience.year_end}` : 'actualidad'}
            </Text>
            <br />
            <Text strong>{experience.company}</Text>
          </>
        ) : <Text style={{ color: '#A4A4A4' }}>Sin experiencia</Text>)
      }
    },
    {
      title: 'Formación',
      dataIndex: 'education_level',
      key: 'education_level',
      render: (education_level, { career }) => {
        return (
          career ? (
            <>
              <Text style={{ color: '#A4A4A4' }}>{education_level}</Text>
              <br />
              <Text strong>{career.career_name}</Text>
            </>
          ) : <>{education_level || 'Sin formación'}</>
        )
      }
    },
    // TODO: última actividad?
    // TODO: idioma
  ];
};

export function buildCustomFieldsColumns(customFieldKey, customFieldData, setSelectedFileId, handlePreview) {
  return ({
    title: customFieldData.name,
    dataIndex: customFieldKey,
    key: `custom_field_${customFieldKey}`,
    render: (name, record) => {
      if (record.custom_fields === null) return;

      if (['text', 'number', 'date', 'singleselection'].includes(customFieldData.value_type)) return record.custom_fields[customFieldKey];
      if (customFieldData.value_type === 'boolean') {
        if (record.custom_fields[customFieldKey] === true) return 'Si';
        if (record.custom_fields[customFieldKey] === false) return 'No';
      }
      if (customFieldData.value_type === 'multiselection' && Array.isArray(record.custom_fields[customFieldKey])) return record.custom_fields[customFieldKey].join(', ');
      if (customFieldData.value_type === 'file' && record.custom_fields[customFieldKey]) {
        return (
          <Button
            style={{ padding: 0 }}
            onClick={() => {
              setSelectedFileId(record.custom_fields[customFieldKey]);
              handlePreview(true);
            }}
            type="link"
          >
            {i18n.t('profile__view_document', { ns: 'candidateProfile' })}
          </Button>
        );
      }
      return;
    },
  });
};