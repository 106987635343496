import React, { useState, useRef, useEffect } from 'react'

import {
  Icon, Input, Tag, Tooltip
} from 'antd';

function GenomaTags({ initialValue, callback }) {
  const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState([]);

  const inputEl = useRef(null);

  useEffect(() => {
    if (initialValue) {
      setTags(initialValue);
    }
  }, [initialValue])

  useEffect(() => {
    if (inputVisible) {
      inputEl.current.focus();
    }
  }, [inputVisible])

  const handleClose = removedTag => {
    const newTags = tags.filter(tag => tag !== removedTag);
    setTags(newTags);
    callback && callback(newTags);
  };

  const showInput = () => {
    setInputVisible(true)
  };

  const handleInputChange = e => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      const newTags = [...tags, inputValue];
      setTags(newTags);
      callback && callback(newTags);
    }
    setInputVisible(false);
    setInputValue('')
  };

  return (
    <div>
      {tags.map((tag) => {
        const isLongTag = tag.length > 20;
        const tagElem = (
          <Tag key={tag} closable={true} onClose={() => handleClose(tag)}>
            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
          </Tag>
        );
        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}
      {inputVisible && (
        <Input
          ref={inputEl}
          type="text"
          size="small"
          style={{ width: 78 }}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      )}
      {!inputVisible && (
        <Tag onClick={showInput} style={{ background: '#fff', borderStyle: 'dashed' }}>
          <Icon type="plus" /> Agregar nueva etiqueta
        </Tag>
      )}
    </div>
  );
}

export default GenomaTags;