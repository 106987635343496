import { getURLWithQueryParams } from "../utils/url"

export const SOCIAL_PROVIDERS = {
  'LI': 'LinkedIn',
  'GM': 'Gmail',
}

export function getProviders(list=[]) {
  const names = [];
  list.forEach(provider => {
    names.push(SOCIAL_PROVIDERS[provider])
  });
  let strNames = '';
  if (names.length) {
    strNames += names[0]
    if (names.length > 2) {
      for (let i = 1; i < names.length - 1; i++) {
        strNames += `, ${names[i]}`
      }
    }
    if (names.length > 1) {
      strNames += ` y ${names[names.length - 1]}`
    }
  }
  return strNames;
}

export const LINKEDIN_STATE = 'ry32gr7IH3HU4TTf3ruh3ui3447TGBi93jr'
const LINKEDIN_SCOPE = 'r_liteprofile r_emailaddress'
const LINKEDIN_REDIRECT = process.env.REACT_APP_LINKEDIN_REDIRECT_URI
const LINKEDIN_CLIENT_ID = process.env.REACT_APP_LINKEDIN_CLIENT_ID
export const LINKEDIN_URL = getURLWithQueryParams(
  'https://www.linkedin.com/oauth/v2/authorization',
  {
    response_type: "code",
    client_id: LINKEDIN_CLIENT_ID,
    redirect_uri: LINKEDIN_REDIRECT,
    state: LINKEDIN_STATE,
    scope: LINKEDIN_SCOPE
  },
)
