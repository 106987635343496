import React, { useState } from 'react';
import {
  Button,
  Row,
  Col,
  Spin,
  Card,
  Typography,
  Icon,
  Modal,
} from 'antd';
import propTypes from 'prop-types';

import JobInfoModal from './JobInfoModal';

import '../Jobs.scss';
import i18n from '../../../i18n';
const FLAG_URL = 'https://genoma-assets.s3.us-east-2.amazonaws.com/flags';

const { Title, Text } = Typography;

/**
 * 
 * @param {Array} jobApplications
 * @param {Boolean} loading
 * @param {Object} jobData
 * @param {String} jobID
 * @param {Boolean} jobView
 * @param {Function} diffDay
 * @param {Function} setJobView
 * @param {Function} setShowPagination
 * @param {Function} getDataSpecificJob 
 * @returns 
 */

const JobList = ({ jobApplications, loading, jobData, jobID, jobView, diffDay, setJobView, setShowPagination, getDataSpecificJob }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <Spin spinning={loading} size='large' >
      <Row id='job-cards-container' type="flex" justify='center' gutter={[20, 20]} style={{ overflowY: 'auto', height: jobView ? '50vh' : '100%' }}>
        {jobApplications.map((jobapp, key) => (
          <Col key={key} xs={24} sm={jobView ? 24 : 12} md={jobView ? 24 : 12} lg={jobView ? 24 : 8} style={{ maxHeight: '140px', paddingBottom: (jobApplications.length-1===key ? 0 : 20)}}>
            <Card style={{ border: '1px solid #e9e9e9' }} onClick={() => {
              getDataSpecificJob(jobapp.id)
              if (jobView || window.innerWidth >= 992) {
                setJobView(true)
                setShowPagination(true)
              } else {
                setShowModal(true)
              }
            }}>

              <Col span={6} style={{ padding: '0 10px' }}>
                <Row type='flex' align='middle' justify='center' style={{ height: '100%' }}>

                  <div style={{ backgroundImage: `url(${jobapp.business_user.logo})`, height: 80, width: 80, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: '50%' }} />

                </Row>
              </Col>

              <Col span={18} style={{ padding: '0 10px', height: '100%' }}>
                <Row style={{ height: '100%', width: '100%' }}>

                  <Row type='flex' justify='start' align='middle'>
                    <Text style={{ marginRight: '5px', color: '#454545' }} strong>{jobapp.business_user.name}</Text>
                    <Text style={{ fontSize: '12px', color: '#A4A4A4' }}>{jobapp.location_city} {jobapp.location_country}
                    </Text>
                    <Row type='flex' align='middle'><img src={`${FLAG_URL}/${jobapp.location_country}.svg`} style={{ height: '15px', width: '15px', marginLeft: '5px' }} /></Row>
                  </Row>

                  <Button style={{ padding: 0 }} type='link'>
                    <Title style={{ color: '#1890ff' }} ellipsis level={4}>
                      {jobapp.job_application.length > 25 ? `${jobapp.job_application.slice(0, 25)}...` : jobapp.job_application}
                    </Title>
                  </Button>
                  <Row type='flex'>
                    <Text style={{ color: '#A4A4A4' }}>
                      <Icon type="clock-circle" style={{ margin: '0 5px 0 0' }}/>
                      {`${i18n.t('job_portal__published').split('{')[0]} ${diffDay(jobapp.creation_date)} ${i18n.t('job_portal__published').split('}')[1]}`} 
                    </Text>
                  </Row>
                </Row>
              </Col>

            </Card>
          </Col>
        ))}
      </Row>
      <Modal
        visible={showModal}
        centered
        onCancel={() => setShowModal(false)}
        footer={null}
        bodyStyle={{ padding: '24px 0px' }}
      >
        <JobInfoModal
          jobData={jobData}
          jobID={jobID}
          diffDay={diffDay}
        />
      </Modal>
    </Spin>
  );
}

JobList.propTypes = {
  jobApplications: propTypes.array,
  loading: propTypes.bool,
  jobData: propTypes.object,
  jobID: propTypes.string,
  jobView: propTypes.bool,
  diffDay: propTypes.func,
  setJobView: propTypes.func,
  setShowPagination: propTypes.func,
  getDataSpecificJob: propTypes.func
}

export default JobList;