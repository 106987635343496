import React from 'react';
import { Modal, Typography } from 'antd';

const { Text } = Typography;

const DeleteConfirmModal = ({ deleteCustomTest, showModal, setShowModal, idCute }) => {
  return (
    <Modal
      title='¿Estás seguro/a que deseas eliminar la prueba?'
      content='Se eliminará de manera definitiva para ti y tu organización.'
      okText='Sí, estoy seguro/a'
      cancelText='No, cancelar'
      onOk={() => { deleteCustomTest(idCute), setShowModal(false) }}
      onCancel={() => setShowModal(false)}
      visible={showModal}
      centered
    >
      <Text>Se eliminará de manera definitiva para ti y tu organización.</Text>
    </Modal>
  );
}
 
export default DeleteConfirmModal;