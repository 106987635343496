import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ActivityPath from "./ActivityPath/ActivityPath";

const styles = theme => ({
	jobStage1: {
		paddingTop: 19,
		paddingBottom: 14,
		textAlign: "center"
	},
	title: {
		border: "1px solid #999999",
		borderRadius: 4,
		backgroundColor: "#f6f6f6",
		textAlign: "center",
		paddingTop: 11,
		paddingBottom: 11
	},
	root: {
		position: "relative",
		height: 77,
		width: 77,
		border: "1px solid #999999",
		borderRadius: "100%",
		margin: "28px auto 10px"
	},
	wrapper: {
		height: 69,
		width: 69,
		margin: 3,
		backgroundColor: "#ffffff",
		borderRadius: "100%",
		border: "1px solid #999999"
	},

	fabProgress: {
		top: 0,
		left: 0,
		position: "absolute",
		borderRadius: "100%"
	},
	delete: {
		visibility: "inherit",
		position: "absolute",
		content: "",
		width: 22,
		height: 22,
		right: -3,
		borderRadius: "100%",
		backgroundColor: "#898989",
		textAlign: "center",
		paddingTop: 2
	},
	iconFontSize: {
		fontSize: 38
	},
	labelIcon: {
		fontWeight: 300
	}
});

const JobStage = props => {
  const { businessSlug, colorTheme, stage, activities, percentage, prvw } = props;
	return (
		<ActivityPath
			businessSlug={businessSlug}
			colorTheme={colorTheme}
			prvw={prvw}
			stage={stage}
			percentage={percentage}
			activities={activities}
		/>
	);
};

JobStage.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(JobStage);
