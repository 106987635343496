import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../../actions/data";
import { Button, Upload, Icon, message, Row, Col, Typography } from "antd";

const { Text, Paragraph } = Typography;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

const UploadImage = (props) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const beforeUpload = (file) => {
    const isLt1MB = file.size < 1000000;
    if (!isLt1MB) {
      message.error("Imagen debe pesar menos de 1MB");
    }
    return isLt1MB;
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        setImageUrl(imageUrl);
        setLoading(false);
        props.getImage(imageUrl, info.file.originFileObj);
      });
    }
  };

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const uploadButton = (
    <div className="upload-btn">
      <span>
        <Icon type={loading ? "loading" : "plus"} />
        <div className="ant-upload-text">Subir imagen</div>
      </span>
    </div>
  );

  const uploadButtonProcess = (
    <div>
      <Button style={{width: '9.5rem', height: '2rem'}}>
        <Icon
          type={loading ? "loading" : "picture"}
          theme={loading ? "" : "filled"}
        />
       Agregar imagen
      </Button>
    </div>
  );

  return (
    <Row type="flex">
      <Col sm={24} className="img-description">
        <Text strong className="subtitle">
          {props.title}
        </Text>
        <Paragraph>{props.description}</Paragraph>
      </Col>

      <Col sm={24} className={props?.newProcess ? "" : "img-uploader"}>
        <Upload
          name="avatar"
          listType={props?.newProcess ? "text" : "picture-card"}
          className={props?.newProcess ? "" : "avatar-uploader"}
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          accept=".jpg,.jpeg,.png"
          customRequest={dummyRequest}
        >
          {!props?.newProcess && imageUrl && (
            <img src={imageUrl} alt="preview" style={{ width: "100%" }} />
          )}
          {props?.newProcess ? uploadButtonProcess : uploadButton}
        </Upload>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.data.data,
    isFetching: state.data.isFetching,
    error: state.data.error,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadImage);
