import React from 'react';
import { Row, Col, Typography } from 'antd';
import CustomSlider from '../../../../components/CustomSlider.jsx';
import propTypes from 'prop-types';
const { Title, Text } = Typography;

const Personality = ({ candidates }) => {
  const withoutData = [];
  const personalityTraits = [[], [], [], [], [], []];

  candidates.forEach((candidate, indexCandidates) => {
    if (candidate.personality_scores.length === 0  ) {
      withoutData.push(indexCandidates);
    } else {
      candidate.personality_scores.forEach((trait, indexSliders) => {
        let name;
        if (candidate.name) {
          name = candidate.name[0];
          if (candidate.surname) {
            name = name + candidate.surname[0];
          }
        } else {
          name = candidate.email[0] + candidate.email[1];
        }
        personalityTraits[indexSliders].push([
          name,
          trait.score,
          [trait.trait, trait.lower_bound, trait.upper_bound],
        ]);
      });
    }
  });

  return (
    <Row className='personality-section-container' type='flex' justify='center'>
      <Col span={21}>
        {candidates[0] ? (
          candidates[0].personality_scores.length !== 0 ||
          candidates[1].personality_scores.length !== 0 ? (
            personalityTraits.map((slider, index) =>
              slider.length > 0 ? (
                <Row key={index} className='personality-section-container__slider'>
                  <Title level={4}>{slider[0][2][0]}</Title>
                  <CustomSlider
                    span={24}
                    height={'13px'}
                    background={'#FFD6E7'}
                    margin={'15px 0'}
                    avatars={slider}
                    withoutData={withoutData}
                  />
                  <Col
                    span={24}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Col>
                      <Text>{slider[0][2][1]}</Text>
                    </Col>
                    <Col>
                      <Text>{slider[0][2][2]}</Text>
                    </Col>
                  </Col>
                </Row>
              ) : null
            )
          ) : (
            <Typography.Paragraph strong style={{ fontSize: '16px' }}>
              Sin datos de personalidad
            </Typography.Paragraph>
          )
        ) : null}
      </Col>
    </Row>
  );
};

Personality.propTypes = {
  candidates: propTypes.array.isRequired,
};

export default Personality;
