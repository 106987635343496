import React, { Fragment } from 'react';
import { Form, Button, Input, Upload, Icon, Spin, Layout, Typography } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './addDocuments.scss';
import { SERVER_URL } from '../../utils/config';

import * as actionCreators from '../../actions/data';
import * as authActionsCreator from '../../actions/auth';
import * as jobappActionCreators from '../../actions/jobapp';
import parseHtml from '../../components/parseHtml';
const { Title, Paragraph } = Typography;


class addDocumentsForm extends React.Component {
  state = {
    form: {
      questions: [
        {
          'label': 'Cargando...',
          'type': 'text'
        },
        {
          'label': 'Cargando...',
          'type': 'file'
        },
        {
          'label': 'Cargando...',
          'type': 'file'
        }
      ],
      title: 'Cargando...',
      description: 'Cargando...',
      attachments: []
    },
    loadingFile: false,
    fileList: [],
    message: '',
  };
  form = Form.create({ name: 'validate_other' });

  componentWillMount() {
    // Se cargan las preguntas del trigger.
    const token = this.props.token;
    this.props.jobappActions.fetchJobApplication(token, this.props.match.params.jobappid).then(jobapp => {
      this.props.authActions.updateLogo(jobapp.business_logo)
    })
    const jobappId = this.props.match.params['jobappid'];
    const triggerId =  this.props.match.params['triggerid'];
    fetch(`${SERVER_URL}/api/v1/gamesandtests/jobapplication/${jobappId}/triggers/${triggerId}/`)
      .then((res) => {
        res.json()
          .then(body => {
            const form = body.trigger.data;
            form.questions.forEach(question => {
              question.loadingFile = false;
            })
            this.setState({ form });
          })
      })
      .catch(() => {
        const form = { questions: [] }
        this.setState({ form });
      })
  };

  handleSubmitForm = () => {
    const { form } = this.state;
    const answers = form.questions.map(q => q.value);
    if (!answers.every(q => q)) {
      this.setState({ message: 'Faltan documentos/respuestas por completar. Por favor revisa el formulario.' })
      return;
    }
    this.setState({ message: 'Enviando datos...' })
    this.sendFormData(answers)
    };

  sendFormData = async (answers) => {
    const jobappId = this.props.match.params['jobappid'];
    const triggerId =  this.props.match.params['triggerid'];
    const data = { answers };
    const url = `gamesandtests/jobapplication/${jobappId}/triggers/${triggerId}/`
    await fetch(`${SERVER_URL}/api/v1/${url}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        Authorization: `Token ${this.props.token}`,
      },
      body: JSON.stringify(data)
    })
      .then((response) => {
        if (response.status >= 300) {
          const error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
        this.setState({ message: 'Datos correctamente enviados.' });
        setTimeout(() => this.props.history.push(`/me`), 2000);
      })
      .catch(() => {
        this.setState({ message: 'Hubo un error, intenta nuevamente.' });
      })
  }

  handleChange = (key) => {
    return (e) => {
      const { form } = this.state;
      const message = '';
      form.questions[key].value = e.target.value;
      this.setState({ form, message });
    }
  };

  handleUploadFile = (key) => {
    return async (file) => {
      let fileList = [...file.fileList]
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = ''
        }
        return file
      });
      
      const { form } = this.state
      form.questions[key].fileList = fileList;
      form.questions[key].loadingFile = true;
      this.setState({ form });

      if (file.file.status === 'uploading') {
        this.setState({ message: '' });
      }

      if (file.file.status === 'error') {
        const { form } = this.state;
        const message = 'Hubo un error al subir el archivo, favor intenta nuevamente.'
        form.questions[key].value = null;
        form.questions[key].fileList = [];
        form.questions[key].loadingFile = false;
        this.setState({ form, message })
      }
      // Upload Document
      if (file.file.status === 'done') {
        if (file.file.size > 5242880) {
          const { form } = this.state
          form.questions[key].fileList = [];
          form.questions[key].loadingFile = false;
          this.setState({ form  });
          alert('El tamaño del CV excede los 4MB. Por favor intentarlo nuevamente con un archivo más liviano.')
        } else {
          // UPLOAD CV
          const fileObject = file.file.originFileObj;
          const jobappId = this.props.match.params['jobappid'];
          const triggerId =  this.props.match.params['triggerid'];
          let url = `gamesandtests/additionalfileupload/${jobappId}/${fileObject.name}`;
          const fileType = `${triggerId}-${key}`;
          const questionTitle = this.state.form.questions[key].label;
          await this.props.actions.sendFile(this.props.token, url, fileObject, fileObject.name, '', questionTitle)
            .then((j) => {
              const { form } = this.state;
              const url = j.file_url;
              form.questions[key].value = url;
              form.questions[key].loadingFile = false;
              this.setState({ form });
            })
            .catch((e) => {
              const { form } = this.state;
              const message = 'Hubo un error al subir el archivo, intenta nuevamente.'
              form.questions[key].value = null;
              form.questions[key].fileList = [];
              form.questions[key].loadingFile = false;
              this.setState({ form, message })
            })
        }
      }
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { form } = this.state;
    return (
      <>
        <Layout className='pf'>
          <div className='content'>
            <Title level={1}>{form.title}</Title>
            <Title level={3}>{this.props.jobapp.job_application}</Title>
            <Paragraph>{parseHtml(form.description)}</Paragraph>
            <div className="attachments">
              {form.attachments && form.attachments.map(attachment => {
                return (
                  <Button type="link" icon="download" onClick={() => window.open(attachment.src)}>{attachment.name}</Button>
                )
              })}
            </div>
            <p style={{ color: 'red' }}>{this.state.message}</p>
            <Form onSubmit={this.handleSubmitForm}>
              {
                form.questions.map((question, index) => {
                  switch (question.type) {
                    case 'text':
                      return (
                        <Form.Item label={question.label} key={index}>
                          <Input placeholder="input placeholder" value={question.value} onChange={this.handleChange(index)}/>
                        </Form.Item>
                      );
                    case 'file':
                      return (
                        <Form.Item label={question.label} key={index}>
                          <Upload.Dragger
                            name='file'
                            action={SERVER_URL + '/api/v1/gamesandtests/mockupload/'}
                            headers={{
                              authorization: 'authorization-text',
                            }}
                            onChange={this.handleUploadFile(index)}
                            accept=".doc,.docx,.pdf,.jpg,.jpeg,.xls,.xlsx,.png,.zip"
                            fileList={question.fileList}
                            disabled={question.loadingFile}
                          >
                            <Spin tip="Subiendo archivo..." spinning={question.loadingFile}>
                              <div className='pf__form-wrapper__cloud'>
                                <div className='cloud-container'>
                                  <svg width="101" height="61" viewBox="0 0 161 121" role="presentation" focusable="false" tabindex="-1" className="upload-shot-cloud fill-current"><path d="M127.452 47.224C127.458 21.067 106.434 0 80.5 0 54.566 0 33.542 21.067 33.542 47.056 14.745 48.918 0 64.742 0 84.028 0 104.45 16.516 121 36.896 121h87.208C144.484 121 161 104.45 161 84.028c0-19.286-14.745-35.11-33.548-36.804z"></path></svg>
                                  <div className="cloud-arrow-container"><svg height="18" viewBox="0 0 75 48" width="75" role="presentation" focusable="false" tabindex="-1" className="upload-shot-arrow fill-current"><path fill='white' d="M24 47.111H6.736c-5.978 0-8.97-7.24-4.743-11.475L37.5.056l35.507 35.58c4.226 4.235 1.235 11.475-4.743 11.475H50.995z"></path></svg></div>
                                  <div className="cloud-arrow-stripes"><div className="cloud-arrow-stripe red"></div> <div className="cloud-arrow-stripe purple"></div> <div className="cloud-arrow-stripe blue"></div></div>
                                </div>

                                <div className="ant-upload-text"><strong>Haz clic o arrastra tu documento aquí.</strong></div>
                                <div className="ant-upload-text">Solo en formato Word, Excel, PDF, JPG, PNG, ZIP. Máximo 4MB.</div>
                              </div>
                            </Spin>
                          </Upload.Dragger>
                        </Form.Item>
                      );
                    default:
                      return null;
                  }
                })
              }
              <p style={{ color: 'red' }}>{this.state.message}</p>
              <Form.Item wrapperCol={{ span: 24, offset: 0 }}>
                <Button type="primary" onClick={this.handleSubmitForm} style={{ width: '100%' }}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Layout>
      </>
     );
  }
}

const mapStateToProps = (state) => {
  return {
      data: state.data.data,
      jobapp: state.jobapp.jobApplication,
      isFetching: state.data.isFetching,
      error: state.data.error,
      alerts: state.alerts
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      actions: bindActionCreators(actionCreators, dispatch),
      jobappActions: bindActionCreators(jobappActionCreators, dispatch),
      authActions: bindActionCreators(authActionsCreator, dispatch),
      dispatch
  };
};
 
export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'documents-form' })(addDocumentsForm));
