import { Col, Form, Icon, Row, Tabs, Typography } from 'antd';

import Availability from '../../../../../../../components/Availability/AvailabilitySchedule';
import DefaultForm from './Default';
import React from 'react';

const { Text, Title } = Typography;

const { TabPane } = Tabs;

const AvailabilityQuestion = ({
  getFieldDecorator,
  getFieldValue,
  setFieldsValue,
  questionToEdit,
}) => {
  const name = '¿Cuál es tu disponibilidad horaria?';

  return (
    <div>
      <DefaultForm
        getFieldDecorator={getFieldDecorator}
        getFieldValue={getFieldValue}
        maxLength={100}
        initialValue={name}
      />
      <Form.Item style={{ display: 'hidden' }}>
        {getFieldDecorator('availabilityType', {
          initialValue:
            questionToEdit?.configuration?.availabilityType ?? 'specific',
        })(
          <Tabs
            activeKey={getFieldValue('availabilityType')}
            onChange={(key) => setFieldsValue({ availabilityType: key })}
          >
            <TabPane tab="Bloques específicos" key="specific">
              <Form.Item style={{ display: 'hidden' }}>
                {getFieldDecorator('schedule', {
                  initialValue: questionToEdit?.configuration?.schedule ?? {},
                })}
                <Availability
                  initialSchedule={questionToEdit?.configuration?.schedule}
                  index="schedule"
                  question={questionToEdit?.question}
                  handleChange={(key, value) => {
                    setFieldsValue({ [key]: value });
                  }}
                  isNew={questionToEdit == null}
                />
              </Form.Item>
            </TabPane>
            <TabPane tab="Bloques libres" key="free">
              <Text>
                Permite que los candidatos coloquen su disponibilidad de horario
                para cada día.
              </Text>
            </TabPane>
          </Tabs>
        )}
      </Form.Item>
    </div>
  );
};

export default AvailabilityQuestion;
