import * as actionCreators from '../../actions/data';
import * as gameActions from '../../actions/games';

import { Modal, message } from 'antd'

import CircularProgress from "@material-ui/core/CircularProgress"
import KnockoutForm from './KnockoutForm.js'
import PropTypes from 'prop-types';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  container: {
    display: 'flex',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
  loader: {

  }
});

class KnockOutQuestionView extends React.Component {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    data: PropTypes.object,
    token: PropTypes.string.isRequired,
    actions: PropTypes.shape({
      fetchData: PropTypes.func.isRequired
    }).isRequired,
    match: PropTypes.object.isRequired,
  };

  static defaultProps = {
    data: null
  };

  state = {
    questions: [],
    errors: {},
    isDisabled: false,
    error: false,
    processName: "KnockOut Questions",
  };

  handleChangeV2 = (idx, value) => {
    let questions = { ...this.state.questions }
    let errors = { ...this.state.errors }
    if (questions[idx].type === 'singleline' && value.length > 200) {
      return
    }
    if (questions[idx].type === 'multiline' && value.length > 1500) {
      return
    }

    questions[idx].answer = value
    errors[idx] = false
    this.setState({ questions: questions, errors: errors });

  };

  sendData = () => {
    const token = this.props.token;
    const url = "gamesandtests/answer/" + this.state.jobappid + "/";
    const submitData = JSON.stringify({ game_code: "KO", answer: this.state.questions });
    const redirect = `/jobapplication/${this.state.jobappid}/`
    this.props.gameActions.sendGameDataAndRedirect(token, url, submitData, redirect, "Datos enviados con éxito");
    message.success('Tus respuestas fueron enviadas con éxito', 3)
  }

  showConfirm = () => {
    Modal.confirm({
      centered: true,
      okText: 'Si',
      cancelText: 'No',
      title: '¿Deseas envíar tus respuestas?',
      content: 'No podrás modificar estos datos una vez que sean enviados',
      onOk: () => this.sendData(),
    })
  }

  handleFileChange = (key, ready) => {
    let questions = { ...this.state.questions };
    let errors = { ...this.state.errors };
    questions[key].answer = ready;
    errors[key] = false;
    this.setState({ questions: questions, errors: errors });
  };

  handleMultiChangeV2 = (key, alt, event) => {
    let questions = { ...this.state.questions }
    let errors = { ...this.state.errors }
    if (!questions[key].answer) {
      questions[key].answer = {}
    }
    questions[key].answer[alt] = event.target.checked
    if (event.target.value) {
      errors[key] = false
    }

    this.setState({ questions: questions, errors: errors });
  }

  onSubmit = (e) => {

    e.preventDefault();
    if (!this.checkErrors()) {
      this.showConfirm()
      /*
      const token = this.props.token;
      const url = "gamesandtests/answer/" + this.state.jobappid + "/";
      const submitData = JSON.stringify({ game_code: "KO", answer: this.state.questions });
      const redirect = `/jobapplication/${this.state.jobappid}/`
      this.props.gameActions.sendGameDataAndRedirect(token, url, submitData, redirect, "Datos enviados con éxito");
      */
    }
    else {

    }

  }

  checkErrors = () => {
    let errors = { ...this.state.errors }
    let error = false
    let answer;
    for (const key in this.state.errors) {
      answer = this.state.questions[key].answer
      if (answer === undefined || answer === '' || answer === false || answer === null) {
        errors[key] = true;
        error = true
      }
    }
    //If errors found, move to the error div
    if (error) {
      const firstError = Object.keys(errors).filter(elem => errors[elem] === true)[0]
      window.location.href = `#${firstError}`;
    }

    this.setState({ errors })
    return error
  }


  handleCancel = (e) => {
    e.preventDefault();
    const redirect = `/jobapplication/${this.state.jobappid}/`
    this.props.dispatch(push(redirect))
  }

  componentDidMount() {
    if (this.props.history.action === 'POP') {
      const redirect = `/jobapplication/${this.props.match.params['jobappid']}/`
      this.props.dispatch(push(redirect))
    }
    const token = this.props.token;
    this.setState({ jobappid: this.props.match.params['jobappid'] })
    const url = "gamesandtests/bulkassignedactivity/";
    let errors = {}
    // this.setState({questions: questions, errors: errors})
    const rawData = { code: "KO", jobappid: this.props.match.params['jobappid'] }
    this.props.actions.fetchData(token, url, JSON.stringify(rawData), 'get').then((response) => {

      Object.keys(this.props.configuration.questions).forEach(i => {
        if (this.props.configuration.questions[i].mandatory === 'required' || this.props.configuration.questions[i].mandatory == true) {
          errors[i] = false
        }
      })
      this.setState({ questions: this.props.configuration.questions, errors: errors })
    });
  }

  render() {
    const { alerts, isFetching } = this.props;
    const { isDisabled, questions, errors } = this.state

    return (
      <div>
        <div className="container margin-top-small">
          {isDisabled &&
            <div className="margin-top-small">
              <div className="alert alert-danger">
                <b>No puedes modificar estos datos una vez que fueron enviados</b>
              </div>
            </div>}
          {alerts.message &&
            <div className="margin-top-error">
              <div className={"alert " + alerts.type}>
                <b>{alerts.message}</b>
              </div>
            </div>
          }
          <div className={this.props.root}>
            {isFetching ?
              <div className={styles.loader}><CircularProgress /></div> :
              <KnockoutForm
                disabled={false}
                questions={questions}
                errors={errors}
                handleChange={this.handleChange}
                handleChangeV2={this.handleChangeV2}
                handleMultiChange={this.handleMultiChange}
                handleMultiChangeV2={this.handleMultiChangeV2}
                handleFileChange={this.handleFileChange}
                onSubmit={this.onSubmit.bind(this)}
                handleCancel={this.handleCancel.bind(this)}
                title={'Preguntas Adicionales'}
                description={''}
                jobappid={this.state.jobappid}
              />}
            <br />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    configuration: JSON.parse(state.data.data.configuration),
    isFetching: state.data.isFetching,
    error: state.data.error,
    alerts: state.alerts,
    isFetching: state.data.data.isFetching,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    gameActions: bindActionCreators(gameActions, dispatch),
    dispatch
  };
};


export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(KnockOutQuestionView));
