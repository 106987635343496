import React, { Fragment } from "react";
import { Card, Col, Row, Progress } from "antd";

const AppSummary = (props) => {
  
  const { items, percentStageCompleted } = props;

  const tower = "https://genoma-assets.s3.us-east-2.amazonaws.com/Castle.svg";
  const towerWthoutFlag =
    "https://genoma-assets.s3.us-east-2.amazonaws.com/Castle.svg";

  return (
    <Fragment>
      <Card className="cp-app-summary">
        <Row className="levels" type="flex" gutter={[20,20]}>
          {Object.keys(items).sort().map(function (key) {
            return (
              <Col className="level-one" key={key} span={12}>
                <div className="wrap">
                  <Progress
                    type="circle"
                    strokeWidth={11}
                    percent={percentStageCompleted(key)}
                    format={() => "0"}
                  />
                  <img
                    src={
                      percentStageCompleted(key) === 100
                        ? tower
                        : towerWthoutFlag
                    }
                  />
                </div>
              </Col>
            );
          })}
        </Row>
      </Card>
    </Fragment>
  );
};

export default AppSummary;
