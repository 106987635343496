import { Button, Divider, Modal, Row, Typography } from 'antd';
import React, { useRef } from 'react';

import BarChart from './BarChart';
import LeadershipType from './LeadershipType';
import { getEvaluationText } from './utils';
import { useReactToPrint } from 'react-to-print';

const frontImg = 'https://genoma-assets.s3.us-east-2.amazonaws.com/leadership-front.svg';
const reportLogo = 'https://genoma-assets.s3.us-east-2.amazonaws.com/logo.svg'
const { Text, Title } = Typography;

const LeadershipReport = ({
  visible,
  closeModal,
  descriptionData,
  traits,
  jobApp,
  candidate,
}) => {
  const pdfRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
  });

  return (
    <Modal
      visible={visible}
      onCancel={closeModal}
      footer={null}
      width="min(800px, 90%)"
      title={
        <Button type="primary" onClick={handlePrint}>
          Descargar PDF
        </Button>
      }
      className="leadership-report-modal"
    >
      <div className="leadership-pdf" ref={pdfRef}>
        <div className="break-page first-page">
          <img src={frontImg} style={{ width: '100%' }} />
        </div>
        <>
          <div className="break-before" />
          <Divider className="no-print" />
          <div className="break-page page">
            <div className="page__header">
              <div className="page__header__left">
                <Title level={3}>{candidate.user_name}</Title>
                <Text>
                  Proceso: {jobApp.job_application} para{' '}
                  <strong>{jobApp.business_name}</strong>
                </Text>
                <Text>
                  DNI o RUT: <strong>{candidate.dni}</strong>
                </Text>
              </div>
              <img src={reportLogo} />
            </div>
            <Divider />
            <div className="page__content">
              <Title level={4}>Resultados liderazgo</Title>
              <Text>
                La prueba mide el estilo de liderazgo que la persona evaluada
                tiende a preferir al enfrentar situaciones donde deben dirigir a
                miembros de su equipo.
              </Text>
              <BarChart barData={traits} descriptionData={descriptionData} />
              {getEvaluationText(traits)}
              <Text>
                Conocer estos resultado de la persona evaluada te permitirá
                comprender si se ajusta o no a las necesidades de liderazgo de
                la búsqueda.
              </Text>
            </div>
          </div>
        </>
        <>
          <div className="break-before" />
          <Divider className="no-print" />
          <div className="break-page page">
            <div className="page__header">
              <Title level={4}>Decisiones</Title>
              <img src={reportLogo} />
            </div>
            <Divider />
            <div className="page__content">
              <Title level={4}>Resultados por dimensión</Title>
              <LeadershipType category={descriptionData[0]} traits={traits} />
            </div>
          </div>
        </>
        <>
          <div className="break-before" />
          <Divider className="no-print" />
          <div className="break-page page">
            <div className="page__header">
              <Title level={4}>Decisiones</Title>
              <img src={reportLogo} />
            </div>
            <Divider />
            <div className="page__content">
              <Title level={4}>Resultados por dimensión</Title>
              <LeadershipType category={descriptionData[1]} traits={traits} />
              <LeadershipType category={descriptionData[2]} traits={traits} />
            </div>
          </div>
        </>
      </div>
    </Modal>
  );
};

export default LeadershipReport;
