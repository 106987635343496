export const candidateProfile = {
  2: 'Estudiante',
  1: 'Estudios superiores (Profesional, técnico-profesional, técnico)',
  4: 'Indiferente',
  3: 'Oficio (no requiere estudios superiores)'
}

export const academicSituation = {
  2: 'Egresado',
  1: 'En curso',
  3: 'Graduado',
  5: 'Indiferente',
  4: 'Próximo a graduarse'
}

export const computerSkills = {
  0: 'Ninguno',
  1: 'Nivel bajo',
  6: 'Nivel experto',
  5: 'Nivel profesional',
  4: 'Nivel técnico',
  2: 'Nivel usuario',
  3: 'Nivel usuario avanzado'
}

export const educationLevel = {
  1: 'Básica',
  2: 'Media',
  3: 'Técnico medio/ colegio técnico',
  4: 'Técnico profesional superior',
  5: 'Universitaria',
  6: 'Postgrado',
  147: 'Magíster',
  148: 'Doctorado'
}