import {
  Button,
  Card,
  Col,
  Icon,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import EvaluationCard from "../EvaluationCard";
import { InterviewsContext }   from '../../../../Interviews/config/context';
import JobAppContext from "../../../context/JobAppContext";
import Tags from '../../../../../../components/TagInput';

const { Title, Text, Paragraph } = Typography;

const defaultInterviewProcess = {
  id: null,
  // editable fields
  skills: [],
  soft_skills: [],
  tech_skills: [],
  name: "",
  report_configuration: null,
}

export default function JobApplicationInterviewProcess() {
  const { business: { addOns }, activities, currentStage, jobApplication, interviewProcesses, updateInterviewProcesses } = useContext(JobAppContext);
  const interviewsContext = useContext(InterviewsContext);
  const [interviewProcess, setInterviewProcess] = useState(defaultInterviewProcess);
  const [isVisible, setIsVisible] = useState(false);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [interviewProcessExists, setInterviewProcessExists] = useState(false);
  const [assignedActivity, setAssignedActivity] = useState(null);

  const canUseTera = useMemo(() => {
    return activities.some(activity => activity.code === 'TERA') && addOns.some(addOn => addOn.add_on === 'TERA');
  }, [activities, addOns]);

  const handleAssignedActivity = () => {
    if (!interviewProcess.id) {
      return;
    };
    if (assignedActivity && assignedActivity.stage !== currentStage) {
      const editedAssignedActivity = { ...assignedActivity };
      editedAssignedActivity.stage = currentStage;
      updateInterviewProcesses('toUpdate', [editedAssignedActivity]);
      updateInterviewProcesses('currentEvaluations', [editedAssignedActivity]);
    } else if (!assignedActivity) {
      const newCurrentEvaluations = {
        activity: 'TERA',
        stage: currentStage,
        job_application: jobApplication.id,
        configuration: { id: interviewProcess.id }
      }
      updateInterviewProcesses('currentEvaluations', [...interviewProcesses.currentEvaluations, newCurrentEvaluations])
      updateInterviewProcesses('toAdd', [...interviewProcesses.toAdd, newCurrentEvaluations]);
    }
  }
  

  const handleCreateInterviewProcess = () => {
    const interviewProcessToCreate = { ...interviewProcess };
    interviewProcessToCreate.skills = interviewProcess.skills.map(skill => ({ id: skill }));

    interviewsContext.createInterviewProcess(interviewProcessToCreate).then(x => {
      setInterviewProcess(x);
      interviewsContext.getInterviewProcesses();
      setIsCreateModalVisible(false);
      setIsVisible(true);
      handleAssignedActivity();
    })
  }

  useEffect(() => {
    // Find assignedactivity corresponding to TERA
    const assignedActivity = interviewProcesses.currentEvaluations[0];
    setAssignedActivity(assignedActivity);
  }, [interviewProcesses.currentEvaluations])

  
  useEffect(() => {
    // Encontrar el interview process que corresponde a job application
    const currentInterviewProcess = interviewsContext.interviews.find(
      (interviewProcess) =>
        interviewProcess.job_application === jobApplication.id
    );
    if (currentInterviewProcess) {
      setInterviewProcessExists(true);
      setInterviewProcess(currentInterviewProcess);
    }
  }, [interviewsContext.interviews, interviewsContext])

  const interviewsToList = useMemo(() => {
    return interviewsContext.interviews.filter(interview => interview.id !== interviewProcess.id)
  }, [interviewsContext.interviews, interviewProcess, jobApplication, isVisible])

  const columns = [
    {
      title: 'Entrevista',
      dataIndex: `name`,
      key: `name`,
      render: (fieldValue, obj) => (
        <Button type='link' onClick={() => interviewsContext.goToInterview(obj.id)}><span strong>{fieldValue}</span></Button>
      )
    },
    {
      title: 'Entrevistados',
      dataIndex: `totalCandidates`,
      key: `totalCandidates`,
      render: (fieldValue, obj) => (
        <div><Icon type='team' /> {fieldValue} candidatos.</div>
      )
    },
    {
      title: 'Fecha de creación',
      dataIndex: `date_created`,
      key: `date_created`,
      render: (fieldValue, obj) => (
        <div>{fieldValue}</div>
      )
    },
    {
      title: 'Acciones',
      dataIndex: `id`,
      key: `id`,
      render: (fieldValue, obj) => (
        <Row type='flex' gutter={[10, 10]}>
          <Col>
            <Tooltip title={interviewProcessExists ? 'Ya existe una entrevista asignada a este proceso' : obj.job_application ? 'Esta entrevista ya fue asignada a otro proceso' : ''} >
            <Button disabled={interviewProcessExists || obj.job_application} onClick={() => setInterviewProcess(obj)} type='primary' icon='plus' />
            </Tooltip>
          </Col>
          <Col><Button disabled={interviewProcessExists} onClick={() => {
            setInterviewProcess({...obj, id: undefined });
            setIsCreateModalVisible(true);
            setIsVisible(false);
            }} type='primary' icon='copy' /></Col>
          {obj.candidateinterviews.length === 0 ? 
            <Col><Button disabled={interviewProcessExists} onClick={() => interviewsContext.deleteInterviewProcess(obj.id).then(interviewsContext.getInterviewProcesses)} type='primary' icon='delete' /> </Col>
          : null}
        </Row>
      )
    },
  ]
  
const handleAssignInterviewProcess = () => {
  handleAssignedActivity()
  setIsVisible(false);
  if (!interviewProcess.id) {
    return;
  }
  interviewProcess.job_application = jobApplication.id;
  interviewsContext.updateInterviewProcess(interviewProcess.id, interviewProcess).then(x => {
    setInterviewProcess(x);
    interviewsContext.getInterviewProcesses();
  })
}

  

  return (
    <>
      <EvaluationCard
        logo="https://genoma-assets.s3.us-east-2.amazonaws.com/vint-icon-activity.svg"
        evaluationTypeImg={[]}
        title={"Transcripción y análisis de entrevistas"}
        description={"Graba audio, transcribe y obtén un reporte de las entrevistas realizadas, con la ayuda de la inteligencia artificial."}
        additionalInformation={[
          { value: "Todo tipo de cargos", icon: "user" },
          { value: "ES", icon: "flag" },
        ]}
        viewMoreContent={() => {
          return null;
        }}
        buttons={() => [
          <Button
            key={"add"}
            onClick={() => setIsVisible(true)}
            disabled={!canUseTera}
            type={'primary'}
          >
            {canUseTera ? (
              <>
                <Icon type="plus" />
                <span>Configurar</span>
              </>
            ) : (
              <>
                <Icon type="calendar" />
                <span>Contactar ventas</span>
              </>
            )}
          </Button>,
        ]}
        game={false}
      />
      <Modal
        visible={isVisible} 
        onCancel={() => setIsVisible(false)}
        onOk={handleAssignInterviewProcess}
        width={'80vw'}
        okText='Guardar'
        cancelText='Cancelar'
      >
        <Title level={2}>Entrevistas</Title>
        <Row>
          <Col span={24}>
            <Title level={4}>Entrevista seleccionada</Title>
            <Card>
              {interviewProcess.id? 
              <Row type='flex' align='middle' justify='center' style={{ width: '100%' }}>
                <Col span={20}>
                  <Title level={4}>{interviewProcess.name}</Title>
                  <p>{interviewProcess.description}</p>
                  <p>Entrevistados: {interviewProcess.totalCandidates}</p>
                </Col>
                <Col span={4}>
                  <Button type='link' icon='eye' onClick={() => interviewsContext.goToInterview(interviewProcess.id)} />
                  <Button disabled={interviewProcessExists} type='link' icon='delete' onClick={() => setInterviewProcess(structuredClone(defaultInterviewProcess))} />
                </Col>
              </Row>
                : <Col><p>No hay entrevista seleccionada</p></Col>
              }
            </Card>

          </Col>
          <Col span={24}>

            <Title level={4}>Entrevistas disponibles</Title>
            <Table
              dataSource={interviewsToList}
              columns={columns}
              pagination={10}
            />
          </Col>
        </Row>
        <Button
          disabled={interviewProcessExists}
          icon='plus'
          onClick={() => {
            setIsVisible(false);
            setIsCreateModalVisible(true)}
          }
        >Crear nuevo proceso de entrevista.</Button>
      </Modal>
      <Modal
        visible={isCreateModalVisible}
        onCancel={() => setIsCreateModalVisible(false)}
        onOk={handleCreateInterviewProcess}
        okText='Crear'
        cancelText='Cancelar'
      >
        <Title level={2}>Crear nuevo proceso de entrevista</Title>
        <Row type='flex' gutter={[24, 24]}>
          <Col span={24}>
            <Text>Nombre del cargo para la entrevista</Text>
            <Input
              placeholder='Nombre del cargo'
              value={interviewProcess.name}
              onChange={e => setInterviewProcess({ ...interviewProcess, name: e.target.value })}
            />
          </Col>
          <Col span={24}>
            <Paragraph>Formato de reporte</Paragraph>
            <Select
              style={{ width: 200 }}
              value={interviewProcess.report_configuration}
              onChange={value => setInterviewProcess({ ...interviewProcess, report_configuration: value })} 
            >
              {interviewsContext.reports.map((report, index) => {
                return (
                  <Select.Option 
                    onChange={value => setInterviewProcess({ ...interviewProcess, report_configuration: value })} 
                    value={report.id}
                  >
                    {report.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Col>
          <Col span={24}>
            <Paragraph>Competencias</Paragraph>
            <Select 
              mode="multiple"
              value={interviewProcess.skills?.map((skill) => skill.id || skill )}
              style={{ width: 200 }}
              onChange={value => setInterviewProcess({ ...interviewProcess, skills: value })} 
            >
              {interviewsContext.skills.map((skill, index) => {
                return (
                  <Select.Option value={skill.id}>
                    {skill.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Col>
          <Col span={24}>
            <Text>Habilidades técnicas</Text>
            <Tags
              tags={interviewProcess.tech_skills}
              addTag={(tag) => {
                setInterviewProcess({ ...interviewProcess, tech_skills: [...interviewProcess.tech_skills, tag] })
              }}
              removeTag={(tag) => {
                setInterviewProcess({ ...interviewProcess, tech_skills: interviewProcess.tech_skills.filter(t => t !== tag) })
              }}
            />
          </Col>
        </Row>
      </Modal>
    </>
  )
}



