export const CandidateModel = {
  id: null,
  birth_date: null,
  careers: [],
  certifications: [],
  commune: '',
  country: null,
  cv: null,
  disability: null,
  disability_type: null,
  dni: null,
  education_level: null,
  email: null,
  experiences: [],
  gender: null,
  has_cv: null,
  // marital_status: null,
  name: null,
  nationality: null,
  // newCoverLetter: null,
  newCv: null,
  cvType: null,
  phone: null,
  skills: [],
  surname: null,
  cvParsedInfo: null,
};

export const JobApplicationModel = {
  optional_fields: {},
};
