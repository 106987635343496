import React, { useState, useEffect, useRef } from "react";

import OptionButton from "../commons/OptionButton";

import {
  Row,
  Col,
  Typography
} from 'antd';

const Likert = ({ field, fieldChanged, value, disableNextPage }) => {

  const [state, setState] = useState('');
  // Be sure that this props never changes
  const disableNextPageStable = useRef(disableNextPage);

  useEffect(() => {
    setState(value)
  }, [value])

  const onClick = (id, value) => {
    fieldChanged(id, value);
    setState(value);
  }

  useEffect(() => {
    if (state !== '') {
      disableNextPageStable.current(field._uid, false);
    }
    else {
      disableNextPageStable.current(field._uid, true);
    }
  }, [state, field._uid])

  const getWidthCol = () => {
    return Math.floor(24 / field.options.length)
  }

  return (
    <div>
      <div style={{ marginBottom: 30 }}>
        <Typography.Text style={{ fontSize: 16, color: '#5E5E5E', userSelect: 'none' }}>{field.label}</Typography.Text>
      </div>
      <Row gutter={20} type='flex' align='middle' justify='center'>
        {field.options.map((option, index) => {
          return (
            <Col key={option.value} span={getWidthCol()}>
              <OptionButton
                checked={state === option.value}
                id={field._uid}
                label={index +1}
                value={option.value}
                index={index}
                type={'likert'}
                onClick={onClick}
              />
            </Col>
          );
        })}
      </Row>
      <Row gutter={20} type='flex' align='middle' justify='center'>
        {field.options.map((option, index) => {
          return (
            <Col key={option.value} span={getWidthCol()}>
              <div style={{
                'textAlign': 'center'
              }}>
                {option.label}
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default Likert;
