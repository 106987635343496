import React from 'react';
import { Typography, Input, Button, Row, Col, Tooltip } from 'antd';

const { Text, Paragraph } = Typography;
const InputGroup = Input.Group;

class JobUrl extends React.Component {

  state = {
    visibleTooltip: false
  }

  copyLink = () => {
    this.setState({ visibleTooltip: true });
    setTimeout(() => { this.setState({ visibleTooltip: false }) }, 600);
    navigator.clipboard.writeText(this.props.url)
  }

  render() {
    const { visibleTooltip } = this.state;
    return (  
      <div className='pj__job-url'>
        <Paragraph>
          Este es el enlace directo para el proceso que has creado, lo que significa 
          que si navegas a esta URL, verás la página introductoria para comenzar el proceso.
        </Paragraph>

        <Row>
          <Col md={20}>
            <Input size='large' value={this.props.url} disabled />
          </Col>

          <Col md={4}>
            <Tooltip 
              placement='top' 
              overlayClassName='copy'
              visible={visibleTooltip} 
              title='Copiado!'
            >
              <Button type='danger' onClick={this.copyLink}>Copiar link</Button>
            </Tooltip>
          </Col>
        </Row>
      </div>
    )
  }
}

export default JobUrl;