import React, { useState, useEffect, useMemo } from 'react';
import {
  Card,
  Col,
  Row,
  Button,
  Icon,
  Modal,
  Typography,
  Divider,
  Radio,
  Tooltip,
} from 'antd';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../actions/data';

import { Link } from 'react-router-dom';
import moment from 'moment';

import TourComponent from '../../components/tour/TourComponent';
import GenominSpin from '../../components/GenominSpin/GenominSpin';
import tourConfigHome from './Tour/tourConfigHome';
import { tourModalTitle, tourModalContent } from './Tour/modalContent';
import {
  tutorialModalContent,
  tutorialModalContentTitle,
} from '../Business/Tour/modalContent';
// import tutorialConfig from '../Business/Tour/tutorialConfig';

import MONTHS from './assets/months';
import './ClientsHome3.scss';

import NewProcess from '../BusinessDashboard/ProcessListV2/actions/newProcess';
import useBoolean from '../../hooks/useBoolean';

const assetsUrl = 'https://genoma-assets.s3.us-east-2.amazonaws.com/';

const { Title, Text } = Typography;

const interviewTypes = {
  ONSITE: 'Presencial',
};

const HomeClients = ({ auth, actions, ...props }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [information, setInformation] = useState({
    headers: {
      candidates_per_week: [],
      stages: {},
    },
    jobapps: {
      results: [],
    },
  });

  const [jobApps, setJobApps] = useState([]);
  const [jobsCount, setJobsCount] = useState([]);
  const [interviews, setInterviews] = useState([]);

  const [lastPost, setLastPost] = useState(null);
  const [tourModalOpen, setTourModalOpen] = useState(false);
  const [
    firstReleaseBusinessTourModalOpen,
    setFirstReleaseBusinessTourModalOpen,
  ] = useState(false);

  const [dateSubstractor, setDateSubstractor] = useState(30);

  const [newProcessFlag, handleProcessFlag] = useBoolean(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const from = moment().format('YYYY-MM-DD');
      const to = moment()
        .subtract(dateSubstractor, 'days')
        .format('YYYY-MM-DD');

      const today = moment().format('DD-MM-YYYY');

      // PATCH: Se reemplazan paths (strings) por objetos con 2 atributos: path y rawdata,
      // para poder enviar "status = ACTIV" en la rawdata de la request a processlist.
      // Sería ideal que el endpoint leyera este filtro como querystring en lugar de recibirlo como rawdata.
      // Arreglar endpoint y luego volver a dejar paths como estaba.
      const paths = [
        {
          path: `stats/hiringstatssummary/?&from=${to}`,
          rawdata: null,
        },
        {
          path: `gamesandtests/processlist/?page_size=6&from_date=${to}&to_date=${from}`,
          rawdata: { status: 'ACTIV' },
        },
        {
          path: 'gamesandtests/joblistingcount/',
          rawdata: null,
        },
        {
          path: 'stats/wix/posts/',
          rawdata: null,
        },
        {
          path: `evaluations/interviews/?date_range=${today},${today}`,
          rawdata: null,
        },
      ];

      const promisesMap = paths.map(({ path, rawdata }) =>
        actions.fetchData(auth.token, path, JSON.stringify(rawdata))
      );

      const [headerData, jobapps, jobsCountResult, posts, interviews] =
        await Promise.all(promisesMap);

      setJobApps(jobapps.results);
      setJobsCount(jobsCountResult);
      setInformation({
        headers: { ...headerData },
        jobapps: { ...jobapps },
      });
      setLastPost(posts[0]);
      setInterviews(
        interviews.filter(
          (interview) =>
            moment(interview.interview_date, 'DD-MM-YYYY HH:mm:ss Zz').format(
              'DD-MM-YYYY'
            ) === today
        )
      );
      setIsLoading(false);
    };
    getData();
  }, [dateSubstractor]);

  const [month, year] = useMemo((_) => {
    const tmpDate = new Date();

    return [MONTHS[tmpDate.getMonth()], tmpDate.getFullYear()];
  }, []);

  const setTourModalVisible = (value) => setTourModalOpen(value);

  const canManageCollaborators =
    props.businessPermissions.business_admin ||
    props.businessPermissions.role?.can_invite_business_users;

  const hasScheduleInterviewAddon = auth.addOns.find(
    (addon) => addon.add_on === 'SCIN'
  );

  return (
    <div className="home-container">
      <Col xs={24}>
        <Row type="flex" justify="space-between">
          <Title level={2}>¡Hola, {auth.name}! 👋</Title>
        </Row>
        <Row type="flex" justify="space-between" style={{ gap: '1rem' }}>
          <Row type="flex" align="middle" style={{ gap: '1rem' }}>
            <Radio.Group
              value={dateSubstractor}
              onChange={(e) => setDateSubstractor(e.target.value)}
            >
              <Radio.Button value={30}>Mes</Radio.Button>
              <Radio.Button value={365}>Año</Radio.Button>
            </Radio.Group>
            <Text>
              {month}, {year}
            </Text>
          </Row>
          {
            props.businessPermissions.role?.can_create_jobapplications &&           
            <NewProcess showModal={newProcessFlag} />
          }
        </Row>
      </Col>
      {isLoading ? (
        <GenominSpin />
      ) : (
        <Row type="flex" gutter={[10, 10]} style={{ flexGrow: 1 }}>
          <Col md={24} lg={16} className="home-left">
            <div className="home-left__metrics">
              <Row type="flex" justify="space-between">
                <Title level={4} style={{ color: '#FFFFFF' }}>
                  Métricas generales
                </Title>
                <Button>
                  <Link to="/peopleanalytics#hiring" className="header-button">
                    <Text strong>Ver contrataciones</Text>
                    <Icon type="arrow-right" />
                  </Link>
                </Button>
              </Row>
              <Row type="flex">
                <Text>
                  En el{' '}
                  <strong>
                    presente {dateSubstractor > 30 ? 'año' : 'mes'}
                  </strong>{' '}
                  mejorando tus procesos de selección con Genomawork,
                  conseguiste:
                </Text>
              </Row>
              <Row type="flex" style={{ gap: '4rem' }}>
                {[
                  [jobApps ? jobsCount.jobs_open : '-', 'procesos abiertos'],
                  [
                    information.headers.total_hired ?? '-',
                    'candidatos contratados',
                  ],
                  [
                    information.headers.total_discarded ?? '-',
                    'candidatos descartados',
                  ],
                ].map(([value, text], index) => (
                  <div className="home-left__metrics__metric">
                    <Text strong>{value}</Text>
                    <p>{text}</p>
                  </div>
                ))}
              </Row>
            </div>
            <div className="new-processes-home-list">
              <div className="new-processes-home-list__header">
                <Title level={4}>
                  Últimos procesos abiertos del{' '}
                  {dateSubstractor > 30 ? 'año' : 'mes'}
                </Title>
                <Button>
                  <Link to="/processes" className="header-button">
                    <Text strong>Ver todos</Text>
                    <Icon type="arrow-right" />
                  </Link>
                </Button>
              </div>
              <div className="new-processes-home-list__content">
                {information.jobapps.results.length ? (
                  information.jobapps.results.map((process = {}) => (
                    <div
                      className="new-processes-home-list__content__process"
                      key={process.job_app_id}
                      onClick={() =>
                        props.history.push(
                          `/processes/${process.job_app_id}`
                        )
                      }
                    >
                      <div>
                        <Text strong>{process.job_app.job_application}</Text>
                        <p>{process.job_app.count_total} candidatos totales</p>
                      </div>
                      {process?.job_app.is_confidential ? (
                        <Tooltip title="Proceso confidencial">
                          <div className="confidential-icon">
                            <Icon type="lock"/>
                          </div>
                        </Tooltip>
                      ) : null}
                    </div>
                  ))
                ) : (
                  <div className="new-processes-home-list__no-data">
                    <Text>👀</Text>
                    <p>
                      Aún no has iniciado procesos...
                      <br />
                      ¡Crear el primero ahora!
                    </p>
                    <Link to="/processes#new">Crear proceso</Link>
                  </div>
                )}
              </div>
            </div>
            {hasScheduleInterviewAddon && (
              <div className="home-left__interviews">
                <div className="home-left__interviews__header">
                  <Title level={4}>Entrevistas</Title>
                  <Button>
                    <Link to="/calendar" className="header-button">
                      <Text strong>Ver agenda</Text>
                      <Icon type="arrow-right" />
                    </Link>
                  </Button>
                </div>
                {interviews.length ? (
                  <div className="home-left__interviews__content">
                    {interviews.slice(0, 4).map((interview, i, arr) => (
                      <div className="home-left__interviews__interview" key={i}>
                        <Text strong>{interview.title}</Text>
                        <Text style={{ color: '#597EF7', flexGrow: 1 }}>
                          {interview.job_application.job_application}
                        </Text>
                        <Text>
                          {moment(
                            interview.interview_date,
                            'DD-MM-YYYY HH:mm:ss Zz'
                          ).format('HH:mm')}
                        </Text>
                        <Text style={{ fontSize: 14, color: '#a4a4a4' }}>
                          {interviewTypes[interview.interview_type]}
                        </Text>
                        <Button
                          type="link"
                          style={{
                            padding: 0,
                            color: '#A4A4A4',
                          }}
                          icon="eye"
                          onClick={() =>
                            props.history.push(
                              `/calendar?jobapp_id=${interview.job_application.id}&interview_id=${interview.id}`
                            )
                          }
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="empty-ints">
                    <Text>No tienes entrevistas agendadas para hoy.</Text>
                  </div>
                )}
              </div>
            )}
          </Col>

          <Col md={24} lg={8} className="home-right">
            <Row data-tour="right-div-1">
              <Card
                size="small"
                title={
                  <Row type="flex" justify="space-between" align="middle">
                    <Title level={4} style={{ paddingTop: 10 }}>
                      📢 ¡Comunícate con tus candidatos!
                    </Title>
                    <Button
                      type="link"
                      href="https://genoma-assets.s3.us-east-2.amazonaws.com/mensajeri%CC%81a-CS.pdf"
                      target="_blank"
                    >
                      Descarga tu instructivo aquí
                    </Button>
                  </Row>
                }
              >
                <div>
                  <img
                    style={{ maxHeight: '180px', width: '100%' }}
                    src={`${assetsUrl}messages.png`}
                  />
                </div>
                <Text>
                  Desde ahora podrás enviar y recibir mensajes de tus candidatos
                  a través de la plataforma.
                </Text>
              </Card>
            </Row>

            <div data-tour="right-div-2">
              {/* TODO: BLOQUEAR TUTORIAL PARA BUSINESSUSERS ANDRÉS */}
              {/* FIXME: ese includes siempre da false :/ */}
              {canManageCollaborators &&
              ![
                'burgerking.ar@genoma.work',
                'burgerking@genoma.work',
                'starbucks@genoma.work',
                'starbucks.ar@genoma.work',
                'chilis.pfchangs@genoma.work',
                'pfchangs@genoma.work',
              ].includes('Genomaworks') ? (
                <Card
                  size="small"
                  title={
                    <Row type="flex" justify="space-between" align="middle">
                      <Title level={4} style={{ paddingTop: 10 }}>
                        🚀 Nuestras actualizaciones
                      </Title>
                      {/* <a onClick={() => setFirstReleaseBusinessTourModalOpen(true)}>Saber más</a> */}
                    </Row>
                  }
                >
                  <div>
                    <a></a>
                    <div>
                      Si quieres potenciar tu proceso de selección con{' '}
                      <strong>entrevistas personalizadas</strong>, escribenos a{' '}
                      <a href="mailto:mariajose@genomawork.com">
                        mariajose@genomawork.com
                      </a>
                      .
                    </div>
                  </div>
                </Card>
              ) : (
                <Card
                  size="small"
                  title={
                    <Row type="flex" justify="space-between" align="middle">
                      <Title level={4} style={{ paddingTop: 10 }}>
                        🕹 Ayúdanos a mejorar
                      </Title>
                      <a href="https://t.maze.co/86615936?guerilla=true">
                        Ir al prototipo
                      </a>
                    </Row>
                  }
                >
                  <div>
                    <a href="https://t.maze.co/86615936?guerilla=true">
                      <div className="bg-img">
                        <img
                          style={{ height: '100%' }}
                          src={`${assetsUrl}test-de-usabilidad.png`}
                        />
                      </div>
                    </a>
                    <div className="post-body">
                      Hemos diseñado una nueva funcionalidad que te permitirá
                      tomar anotaciones durante una entrevista. ¿Quieres probar
                      el prototipo?
                    </div>
                  </div>
                </Card>
              )}
            </div>

            <div data-tour="right-div-3">
              <Card
                size="small"
                title={
                  <Row type="flex" justify="space-between" align="middle">
                    <Title level={4} style={{ paddingTop: 10 }}>
                      ☄️️📝 Blog Genomawork
                    </Title>
                    <a href={lastPost ? lastPost.url : ''} target="_blank">
                      Leer publicación
                    </a>
                  </Row>
                }
              >
                <a href={lastPost ? lastPost.url : ''}>
                  <div className="bg-img">
                    <img
                      style={{ height: '100%' }}
                      src={`${lastPost?.image_url}`}
                    />
                  </div>
                </a>
                <div className="post-body">{lastPost?.title}</div>
              </Card>
            </div>
          </Col>
        </Row>
      )}

      <TourComponent
        title={tourModalTitle}
        content={tourModalContent}
        tourConfig={tourConfigHome}
        isVisible={tourModalOpen}
        setVisible={setTourModalVisible}
        businessUserName={auth.name}
        width={660}
        showNavigation={true}
      />

      {/* TUTORIAL 1ER RELEASE BUSINESS */}
      <TourComponent
        content={tutorialModalContent}
        contentTitle={tutorialModalContentTitle}
        isVisible={firstReleaseBusinessTourModalOpen}
        setVisible={setFirstReleaseBusinessTourModalOpen}
        businessUserName={auth.name}
        width={660}
        showNavigation={true}
        redirectTo="/business/users"
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const isClonationAvailable = () => {
    const addOns = state.auth.addOns.map((elem) => elem.add_on);
    return addOns.includes('SEED');
  };

  return {
    auth: state.auth,
    businessPermissions: state.auth.businessPermissions,
    businessEmail: state.auth.businessOwnerEmail,
    data: state.data.data,
    clonationAvailable: isClonationAvailable(),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: bindActionCreators(actionCreators, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeClients);
