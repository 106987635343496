import React, { useEffect, useState } from 'react';
import './ConfidentialBrand.scss';
import {
  Row,
  Col,
  Typography,
  Icon,
  Alert,
  Input,
  Divider,
  Upload,
  message,
  Button,
} from 'antd';

import createDesktopPreview from './assets/createDesktopPreview';
import createMobilePreview from './assets/createMobilePreview';

import Colors from './components/Colors';

import useBoolean from '../../../../hooks/useBoolean';

import isEqual from 'lodash/isEqual';
import { SERVER_URL } from '../../../../utils/config';

const { Text, Title } = Typography;

//
const beforeUpload = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        // if (img.width >= 1200 && img.height >= 500) {
        resolve(file);
        // } else {
        //   message.error('La imagen debe tener al menos 1200x500 píxeles');
        //   reject();
        // }
      };
      img.onerror = () => {
        message.error('Error al cargar la imagen.');
        reject();
      };
    };
    reader.onerror = () => {
      message.error('Error al leer el archivo.');
      reject();
    };
  });
};

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

const ConfidentialBrand = ({
  confidentialInfo,
  actions,
  token,
  businessId,
  defaultColors
}) => {
  const [form, setForm] = useState(() => ({ ...confidentialInfo }));
  const [formIsEqualToInfo, handleEqual] = useBoolean(true);

  // variable to show logo checking if it is a string (from backend) or a file (new file from frontend)
  const [selectedLogo, setSelectedLogo] = useState(null);

  const [selectedImage, setSelectedImg] = useState(null);

  const handleFileChange = (info, key) => {
    if (info.file.status === 'done') {
      handleFormChange(key)(info.file.originFileObj);
    }
  };

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const handleFormChange = (key) => (value) => {
    setForm({ ...form, [key]: value });
  };

  const cancelAction = () => {
    setForm({ ...confidentialInfo });
    handleEqual.on();
  };

  const onSave = async () => {
    try {
      if (form.confidential_logo instanceof File) {
        const logoUrl = `accounts/business/${businessId}/logo/confidential/${form.confidential_logo.name}/`;
        const logoResponse = await actions.sendFile(
          token,
          logoUrl,
          form.confidential_logo,
          form.confidential_logo.name
        );

        if (!logoResponse.uploaded) {
          throw new Error({ customMessage: 'Error al subir el logo.' });
        }
      }

      if (form.confidential_background_image instanceof File) {
        const backgroundUrl = `accounts/business/${businessId}/background/confidential/${form.confidential_background_image.name}/`;
        const backgroundResponse = await actions.sendFile(
          token,
          backgroundUrl,
          form.confidential_background_image,
          form.confidential_background_image.name
        );

        if (!backgroundResponse.uploaded) {
          throw new Error({ customMessage: 'Error al subir la imagen.' });
        }
      }

      // this update the redux state -> confidentialInfo is updated -> form is updated
      const newForm = {
        confidential_name: form.confidential_name,
        confidential_color_theme: form.confidential_color_theme,
      };

      if (form.confidential_logo === null) {
        newForm.confidential_logo = null;
      }

      if (form.confidential_background_image === null) {
        newForm.confidential_background_image = null;
      }

      await actions.fetchData(
        token,
        `accounts/business/${businessId}/`,
        JSON.stringify(newForm),
        'PATCH'
      );

      message.success('Información guardada con éxito.');
    } catch (e) {
      message.error(e?.customMessage ?? 'Error al guardar la información.');
    }
  };

  useEffect(() => {
    setForm({ ...confidentialInfo });
  }, [confidentialInfo]);

  useEffect(() => {
    if (isEqual(form, confidentialInfo)) {
      handleEqual.on();
    } else {
      handleEqual.off();
    }
  }, [form, confidentialInfo]);

  useEffect(() => {
    if (!form.confidential_logo) {
      setSelectedLogo(null);
    } else {
      if (typeof form.confidential_logo === 'string') {
        const newUrl =
          form.confidential_logo[0] === '/'
            ? `${SERVER_URL}${form.confidential_logo}`
            : form.confidential_logo;

        setSelectedLogo(newUrl);
      } else {
        getBase64(form.confidential_logo, (result) => {
          setSelectedLogo(result);
        });
      }
    }
  }, [form.confidential_logo]);

  useEffect(() => {
    if (!form.confidential_background_image) {
      setSelectedImg(null);
    } else {
      if (typeof form.confidential_background_image === 'string') {
        const newUrl =
          form.confidential_background_image[0] === '/'
            ? `${SERVER_URL}${form.confidential_background_image}`
            : form.confidential_background_image;

        setSelectedImg(newUrl);
      } else {
        getBase64(form.confidential_background_image, (result) => {
          setSelectedImg(result);
        });
      }
    }
  }, [form.confidential_background_image]);

  return (
    <div className="confidential-brand">
      <Alert
        message={
          <Text>
            La configuración que realices en esta sección aplica solamente para
            todos tus <strong>procesos confidenciales.</strong>
          </Text>
        }
        type="info"
        showIcon
      />
      <div className="confidential-brand__section">
        <Title level={3}>Descripción</Title>
        <Text strong>Nombre de empresa confidencial</Text>
        <span className="italic-comment">
          Si no estableces un nombre de empresa confidencial para tus
          procesos confidenciales, se usará el nombre “Empresa confidencial”.
        </span>
        <Input
          placeholder="Nombre de empresa confidencial"
          value={form.confidential_name}
          onChange={(e) =>
            handleFormChange('confidential_name')(e.target.value)
          }
        />
      </div>
      <Divider />
      <div className="confidential-brand__section">
        <Title level={3}>Imágenes</Title>
        <Text strong>Logo de la empresa</Text>
        <span className="italic-comment">
          Si no estableces un logo para tus procesos confidenciales no se
          utilizará un logo.
        </span>
        <Upload
          //   fileList={selectedLogo}
          listType="picture-card"
          beforeUpload={beforeUpload}
          onChange={(info) => handleFileChange(info, 'confidential_logo')}
          // onRemove={() => setSelectedLogo(null)}
          customRequest={dummyRequest}
          showUploadList={false}
        >
          {selectedLogo ? (
            <img
              src={selectedLogo}
              alt="logo image"
              style={{ width: '100%' }}
            />
          ) : (
            <>
              <Icon type="plus" />
              <Text style={{ display: 'block' }}>Subir logo</Text>
            </>
          )}
        </Upload>
      </div>
      <Divider />
      <div className="confidential-brand__section">
        <Row type="flex" justify="space-between">
          <Text strong>Imagen predeterminada para procesos</Text>
          <span>
            🚨 Si no estableces alguna imagen, se usará la imagen
            predeterminada.
          </span>
        </Row>
        <span>
          Imagen de portada que ven los postulantes al ingresar al proceso.
          Archivos soportados JPG y PNG, con un peso máximo de 1MB. Tamaño
          mínimo: 500x1200 píxeles. Proporción 16:9.
        </span>
        <span className="italic-comment">
          No colocar información relevante en la imagen, dado que esta puede
          verse cortada en función del tamaño de la pantalla y dispositivo que
          el postulante ocupe.
        </span>
        <div className="processes-image">
          <Upload
            listType="picture-card"
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleFileChange(info, 'confidential_background_image')
            }
            customRequest={dummyRequest}
            showUploadList={false}
          >
            <div style={{ width: 200 }}>
              <Icon type="plus" />
              <Text style={{ display: 'block' }}>
                {selectedImage ? 'Cambiar' : 'Subir'} imagen de portada
              </Text>
            </div>
          </Upload>
          {[
            {
              img: createDesktopPreview(selectedImage),
              text: 'Previsualización computadora',
            },
            {
              img: createMobilePreview(selectedImage),
              text: 'Previsualización teléfono',
            },
          ].map((item, index) => (
            <div className="preview-item">
              {item.img}
              <span
                style={{ display: 'block', fontSize: 12, color: '#A4A4A4' }}
              >
                {item.text}
              </span>
            </div>
          ))}
        </div>
        <Divider />
        <div className="confidential-brand__section">
          <Title level={3}>Colores</Title>
          <Text strong>Colores de la empresa</Text>
          <span>
            Los colores que elijas serán agregados a la vista de un empleo en
            particular. Los elementos que cambiarán de color serán los{' '}
            <strong>
              botones, el fondo de la imagen y el pie de la página.
            </strong>
          </span>
          <Colors
            colors={form.confidential_color_theme}
            handleFormChange={handleFormChange}
            defaultColors={defaultColors}
          />
        </div>
        <Divider />
        <Row type="flex" style={{ gap: '0.5rem', marginLeft: 'auto' }}>
          <Button disabled={formIsEqualToInfo} onClick={cancelAction}>
            Cancelar
          </Button>
          <Button type="primary" disabled={formIsEqualToInfo} onClick={onSave}>
            Guardar
          </Button>
        </Row>
      </div>
    </div>
  );
};

export default ConfidentialBrand;
