import { useState } from 'react';

const useBoolean = (init) => {
  const [bool, setBool] = useState(init);

  const handleBool = () => setBool(b => !b);

  return [bool, handleBool];
};

export default useBoolean;
