import React from 'react';
import { Typography, Button, Row } from 'antd';
import QRCode from 'qrcode.react';
import './ShareJob.scss';

const { Title, Text } = Typography;

const linkedinIMG = 'https://image.flaticon.com/icons/svg/2111/2111499.svg';
const twitterIMG = 'https://image.flaticon.com/icons/svg/1384/1384065.svg';
const facebookIMG = 'https://image.flaticon.com/icons/svg/2111/2111393.svg';
const shareIMG = 'https://i.imgur.com/B87G8v1.png';

const ShareJob = ({ shareProcessUrl }) => {
  const encodedURL = encodeURI(shareProcessUrl.replace(/\s/g, ''));

  const popup = (url) => {
    const width = 500;
    const height = 700;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 4;

    return window.open(
      url,
      '_blank',
      'width=' +
        width +
        ', height=' +
        height +
        ', top=' +
        top +
        ', left=' +
        left
    );
  };

  return (
    <div style={{ margin: '1rem 0' }}>
      <Title level={4}>Compartir link del proceso en Redes Sociales</Title>
      <div
        className="share-buttons-container"
        style={{ display: 'flex', width: '50%' }}
      >
        <Button
          className="social-button"
          size="large"
          icon="linkedin"
          onClick={() =>
            popup(
              'https://www.linkedin.com/sharing/share-offsite/?url=' +
                encodedURL
            )
          }
        >
          <Text strong>LinkedIn</Text>
        </Button>

        <Button
          className="social-button"
          size="large"
          icon="twitter"
          onClick={() =>
            popup(
              'https://twitter.com/intent/tweet?original_referer=https://www.google.cl&text=&url=' +
                encodedURL
            )
          }
        >
          <Text strong>Twitter</Text>
        </Button>

        <Button
          className="social-button"
          size="large"
          icon="facebook"
          onClick={() =>
            popup('https://www.facebook.com/sharer.php?u=' + shareProcessUrl)
          }
        >
          <Text strong>Facebook</Text>
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          alignContent: 'flex-start',
          justifyContent: 'flex-start',
          paddingTop: 20,
        }}
      >
        <QRCode
          value={shareProcessUrl}
          size={256}
          imageSettings={{
            src: 'https://genoma-assets.s3.us-east-2.amazonaws.com/genomin.png',
            width: 60,
            height: 60,
          }}
        />
      </div>
    </div>
  );
};

export default ShareJob;
