import React from 'react';
import dompurify from 'dompurify';

function ParseHtml(content) {
  return (
    <div dangerouslySetInnerHTML={{  __html: dompurify.sanitize(content, {FORCE_BODY: true}) }}></div>
  )
}

export default ParseHtml;
