import React from 'react';

import FitProgress from '../../../../../components/FitProgress.jsx';
import { Row, Typography, Col } from 'antd';

const PercentagesPDF = ({ candidates }) => {
  return (
    <Row type='flex' align='middle'>
      <Col span={6}>
        <Typography.Paragraph strong>FIT</Typography.Paragraph>
      </Col>
      <Col span={18}>
        <Row type='flex' justify='space-around'>
          {candidates.map((percent) => (
            <Row key={percent.id} type='flex' justify='center'>
              {percent.final_score ? (
                <FitProgress
                  percent={percent.final_score}
                  type='circle'
                  width={62}
                />
              ) : (
                <Typography.Paragraph strong>Sin FIT</Typography.Paragraph>
              )}
            </Row>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

export default PercentagesPDF;
