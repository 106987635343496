import React, { useState } from 'react'

import {
  Icon,
  Row,
} from 'antd'

import './NewProcessModal.scss'

function GenomeInfo({ infoIndex = 0 }) {
  const [isOpen, setIsOpen] = useState(true);

  const activeTabInfo = {
    0: { 
      name: 'Genomas basales', 
      description: 'Crea tu proceso de selección a partir de un genoma basal con evaluaciones predefinidas y sugerencias complementarias según el cargo. Este se basa en literatura científica y datos agregados para evaluar cargos específicos de forma ágil.' 
    },
    1: { 
      name: 'Genomas cognitivos', 
      description: 'Crea tu proceso de selección a partir de un genoma cognitivo con una evaluación que entrega un puntaje general de la habilidad cognitiva de tus candidatos. Existen 4 niveles cognitivos, ya que cada uno está enfocado en las exigencias de una familia de cargos específica.' 
    },
    2: { 
        name: 'Genomas expertos', 
        description: 
              'Genomas personalizados creados exclusivamente para ti. Constan de una calibración de los rasgos de Genomawork según las competencias del descriptor de cargo asociado a tu proceso de selección.'
      },
    3: { 
        name: 'Genomas por clonación', 
        description: 
              'Genomas personalizados creados exclusivamente para ti. Clonamos a tus mejores talentos identificando patrones y características claves a través de inteligencia artificial.'
      }
  };

  return (
    isOpen &&
    <div className='genoma-new-process-info'>
      <Row type='flex' justify='space-between'>
        <div>
          {activeTabInfo[infoIndex].name}
        </div>
        <Icon type='close' style={{ margin: 5 }} onClick={() => setIsOpen(false)} />
      </Row>
      <div>
        {activeTabInfo[infoIndex].description}
      </div>

    </div>

  )
}

export default GenomeInfo;