import React, { useContext } from "react";
import { Input, Typography, Col, Row } from 'antd';
import JobAppContext from "../../../../context/JobAppContext";

const { Text } = Typography;
const TraitRelevance = ({ measuredTrait, totalPoints, ...props }) => {

  const {
    business,
    measuredTraits,
    getRelevanceMeasuredTrait,
    updateMeasuredTraits,
  } = useContext(JobAppContext);

  const handleTraitRelevanceChange = event => {
    let newValue = event.target.value && event.target.value > 0 ? parseInt(event.target.value) : 0
    const prevPoints = measuredTrait['points']
    const diff = newValue - prevPoints;
    newValue = newValue > 32767 ? prevPoints : newValue;

    const currentMTraits = [...measuredTraits.current];
    const toUpdateTrait = currentMTraits.find(currentMTrait => currentMTrait.trait.id === measuredTrait.trait.id);

    toUpdateTrait['points'] = newValue;

    // Actualizamos la relevancia en cadena
    currentMTraits.forEach(obj => {
      obj['relevance'] = getRelevanceMeasuredTrait(obj, totalPoints + diff);
    });
    updateMeasuredTraits(currentMTraits, 'current');
  };

  return (
    <>
      <Row type="flex" justify="end" align="top" style={{ marginTop: props.showDistribution ? 30 : 20 }}>
        <Col xs={16} sm={16} style={{ display: 'flex', justifyContent: 'end', alignContent: 'middle' }}>
          <Input
            value={Math.round(Number(measuredTrait['points']))}
            onChange={handleTraitRelevanceChange}
            name="personweight"
            id="personweight"
            suffix={'%'}
            disabled={business.addOns.find(obj => obj.add_on == 'TEDT') ? false : true}
          />
        </Col>
        <Col xs={8} sm={8} style={{ display: 'flex', justifyContent: 'end', marginTop: 8 }}>
          <Text strong >{measuredTrait['relevance']}%</Text>
        </Col>
      </Row>
    </>
  );
};

export default (TraitRelevance);
