import i18n from "../i18n";

export const formatDate = string => {
    return string ? string.split('-').reverse().join('-') : null;
}

export const evaluations = {
    0: i18n.t('candidate_pdf__no_evaluated', {ns: 'candidatePdf'}),
    1: i18n.t('candidate_pdf__not_acceptable', {ns: 'candidatePdf'}),
    2: i18n.t('candidate_pdf__deficient', {ns: 'candidatePdf'}),
    3: i18n.t('candidate_pdf__moderate', {ns: 'candidatePdf'}),
    4: i18n.t('candidate_pdf__good', {ns: 'candidatePdf'}),
    5: i18n.t('candidate_pdf__excelent', {ns: 'candidatePdf'}),
}

export const evaluationDictionary = number => {
    return evaluations[number];
}