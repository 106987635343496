import React from 'react';
import { ResponsiveHeatMap } from '@nivo/heatmap';
import { pickRGB } from '../../../../../../../components/charts/utils';

import BreadcrumPage from '../../../pdfCommons/Breadcrum';
import CommonHeader from '../../../pdfCommons/CommonHeader';
import FooterPage from '../../../pdfCommons/FooterPage';

const Page2 = ({ nivoData, candidateInfo, jobName }) => {
  const data = [];

  nivoData.subset.forEach((rowElem, rowIdx) => {
    const tmpRow = {};
    tmpRow['id'] = rowElem;
    nivoData.subset.forEach((elem, colIdx) => {
      tmpRow[elem] = nivoData.distance_matrix.map((score) =>
        score.map((value) => value * 100)
      )[rowIdx][colIdx];
    });
    data.push(tmpRow);
  });

  const getColorHeatMap = (value) => {
    if (value === 0) {
      return '#F0F0F0';
    }
    if (value > 0 && value <= 73) {
      const percent = (value - 0) / (73 - 0);

      const rgbArray = pickRGB([165, 0, 38], [244, 109, 67], percent);
      return `RGB(${rgbArray.join(',')})`;
    } else if (value >= 74 && value <= 83) {
      const percent = (value - 74) / (83 - 74);

      const rgbArray = pickRGB([253, 174, 97], [217, 239, 139], percent);
      return `RGB(${rgbArray.join(',')})`;
    } else if (value > 83) {
      const percent = (value - 84) / (110 - 84);

      const rgbArray = pickRGB([166, 217, 106], [0, 104, 55], percent);
      return `RGB(${rgbArray.join(',')})`;
    }
  };

  const dataKeysValues = data.length
    ? Object.keys(data[0]).filter((key) => key !== 'id')
    : [];
  const colors = data
    .map((item) => dataKeysValues.map((key) => getColorHeatMap(item[`${key}`])))
    .flat();

  function scale() {}

  scale.domain = () => {
    const _colors = colors.slice(0);
    return () => {
      return _colors.shift();
    };
  };

  return (
    <main className="dat-pdf-modal__mainsection__page2">
      <section className="dat-pdf-modal__mainsection__page2__header">
        <BreadcrumPage candidateInfo={candidateInfo} jobName={jobName} />
        <CommonHeader reportName={'Diccionario'} />
      </section>

      <hr style={{ margin: '14px 0 20px 0', color: '#D9D9D9' }} />

      <section className="dat-pdf-modal__mainsection__page2__question">
        <h6>¿Cómo se calcula el puntaje?</h6>
        <p>
          La puntuación se calcula mediante técnicas de procesamiento de
          lenguaje natural utilizando Machine Learning. Se asigna un puntaje a
          cada combinación de palabras en base a cuán seguido se usan éstas en
          contextos similares. Se considera la posibilidad de que las personas
          que toman el test puedan cometer equivocaciones ortográficas o
          introducir palabras demasiado específicas, por lo que se admite una
          cierta holgura en la cantidad de palabras escritas, considerando
          únicamente las primeras 7 correctas o “válidas” para efectos del
          cálculo.
        </p>
      </section>

      <section className="dat-pdf-modal__mainsection__page2__explanation">
        <h6>Detalle del resultado</h6>
        <p>
          Por cada par de palabras{' '}
          <strong>(1 palabra del eje vertical y 1 del eje horizontal)</strong>{' '}
          escritas por el evaluado, se muestra el puntaje asociado a la
          distancia entre ellas. Palabras más distantes entre sí se traducen en
          un puntaje mayor, mientras que palabras más cercanas entre sí se
          traducen en un puntaje menor.
        </p>
      </section>

      <section className="dat-pdf-modal__mainsection__page2__segments">
        <div className="dat-pdf-modal__mainsection__page2__segments__box">
          <div className="dat-pdf-modal__mainsection__page2__segments__box__colorbar">
            {['#A50026', '#D73027', '#F46D43'].map((color) => (
              <div
                key={color}
                style={{ width: '15px', height: '15px', background: color }}
              />
            ))}
          </div>
          <p>
            <strong>Distancia baja</strong>
          </p>
          <p>
            <strong>entre palabras</strong>
          </p>
          <p>0 al 72</p>
        </div>
        <div className="dat-pdf-modal__mainsection__page2__segments__box">
          <div className="dat-pdf-modal__mainsection__page2__segments__box__colorbar">
            {['#FDAE61', '#FEE08B', '#FFFFBF', '#D9EF8B'].map((color) => (
              <div
                key={color}
                style={{ width: '15px', height: '15px', background: color }}
              />
            ))}
          </div>
          <p>
            <strong>Distancia promedio entre</strong>
          </p>
          <p>
            <strong>palabras</strong>
          </p>
          <p>73 al 83</p>
        </div>
        <div className="dat-pdf-modal__mainsection__page2__segments__box">
          <div className="dat-pdf-modal__mainsection__page2__segments__box__colorbar">
            {['#A6D96A', '#66BD63', '#1A9850', '#1A9851', '#006837'].map(
              (color) => (
                <div
                  key={color}
                  style={{ width: '15px', height: '15px', background: color }}
                />
              )
            )}
          </div>
          <p>
            <strong>Distancia alta entre</strong>
          </p>
          <p>
            <strong>palabras</strong>
          </p>
          <p>84 al 100+</p>
        </div>
      </section>

      <section className="dat-pdf-modal__mainsection__page2__heatmap">
        <ResponsiveHeatMap
          data={data}
          maxValue={120}
          minValue={0}
          keys={nivoData.subset}
          indexBy={'id'}
          margin={{ top: 20, right: 100, bottom: 60, left: 150 }}
          axisBottom={{
            tickSize: 1,
            tickPadding: 5,
            tickRotation: 30,
            legend: '',
            legendOffset: 46,
          }}
          axisLeft={{
            tickSize: 1,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 70,
          }}
          axisRight={false}
          axisTop={false}
          colors={scale}
          isInteractive={false}
          cellBorderWidth={5}
          cellBorderColor={'#FFFFFF'}
          labelTextColor={(obj) => (obj.label === 0 ? '#F0F0F0' : '#454545')}
          theme={{
            fontSize: 14,
            labels: {
              text: {
                fontSize: 14,
                fill: '#FFFFFF',
              },
            },
          }}
        />
      </section>

      <FooterPage />
    </main>
  );
};

export default Page2;
