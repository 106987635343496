import React, { useState } from "react";
import NewCandidateModal from "../NewCandidateModal";
import { Layout, Select } from "antd";
import i18n from "../../i18n";

const { Header } = Layout;

const FLAG_URL = 'https://genoma-assets.s3.us-east-2.amazonaws.com/flags/';
const languages = {
  ES: {
    flag: 'ES',
    language: i18n.t('commons__ES'),
  },
  EN: {
    flag: 'US',
    language: i18n.t('commons__EN'),
  },
  PT: {
    flag: 'BR',
    language: i18n.t('commons__PT'),
  },
};

const IsolatedModal = ({
  match: {
    params: { candidateid, jobappid, tokenid },
  },
}) => {

  const [select, setSelect] = useState(
    i18n.language.split('-')[0].includes('es')
      ? 'ES'
      : i18n.language.split('-')[0].includes('en')
        ? 'GB'
        : i18n.language.split('-')[0].includes('pt')
          ? 'BR'
          : 'ES'
  );

  const onSelect = (code) => {
    setSelect(code);
    changeLanguage(code);
  };

  const changeLanguage = (value) => {
    if (value.toLowerCase() == 'gb') {
      value = 'EN';
    } else if (value.toLowerCase() == 'br') {
      value = 'PT';
    }
    i18n.changeLanguage(value.toLowerCase());
    window.location.reload();
  };

  return (
    <>
      <Header style={{
        position: 'sticky',
        display: 'flex',
        top: 0,
        zIndex: 2000,
        alignItems: 'start', justifyContent: 'end',
        paddingRight: 16
      }}>
        <Select
          onSelect={onSelect}
          defaultValue={i18n.language.split("-")[0].toUpperCase()}
          style={{ fontSize: "0.8em", display: "flex", marginTop: '0.25em' }}
          maxTagCount={"responsive"}
          getPopupContainer={(trigger) => trigger}
        >
          {Object.keys(languages).map((key, index) => (
            <Select.Option key={index} value={key}>
              <span
                className="location-with-flag"
                style={{ alignItems: "center" }}
              >
                <span className="jl-flag">
                  <div className="menu-select-image">
                    <img src={`${FLAG_URL}${languages[key].flag}.svg`} />
                  </div>
                </span>{" "}
                <span className="menu-select-text">
                  {window.innerWidth >= 600 ? languages[key].language : key}
                </span>
              </span>
            </Select.Option>
          ))}
        </Select>
      </Header>
      <NewCandidateModal
        candidateId={candidateid}
        jobAppId={jobappid}
        closeModal={() => { }}
        onChangePhase={() => { }}
        lanes={{}}
        mode="isolated"
        shareTokenId={tokenid}
        discardReasons={[]}
      />
    </>
  );
};

export default IsolatedModal;
