import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd'
import CandidateStage from "./candidatestage/CandidateStage";
import CandidatePathPerform from "./candidatepathperform/CandidatePathPerform";
import Progress from '../progress/Progress'
import { Typography } from 'antd'
import EndProcess from "./candidatepathperform/levelscompleted/EndProcess/EndProcess";
import i18n from "../../../../i18n";

const badgeLockedIMG = 'https://genoma-assets.s3.us-east-2.amazonaws.com/badgeLocked.png'

const { Title, Text } = Typography;

const CandidatePathContent = props => {
	const { items } = props;
	const minutes =  items.reduce((init, next) => init + next.progress.time_remaining, 0);
    const total = items.reduce((init, next) => init + next.time_estimated, 0);
	const progress = (total-minutes)*100/(total)
	const levelTwo = items.filter(e => e.code !== 'FORM' && e.code !== 'KO')

	return (
		<Row justify="center">
			<Row justify="center">
				{ !props.isSeed ?
					<Col span={24}>
						<div className='game-over'>
							{minutes === 0 && progress === 100 ? 
								<div className='text'>
									<img src={"https://genoma-assets.s3.us-east-2.amazonaws.com/happy-genomin.png"} />
									<div>
										<Title level={4}>{i18n.t('candidate_path__process__finish')}</Title>
										<Text>
										{i18n.t('candidate_path__process__finish__text')}
										</Text>
									</div>
								</div>
								: 
								<Fragment>
									{levelTwo.length !== 0 &&
										<div className='text'>
											<img src={badgeLockedIMG} />
											<div>
												<Title level={4}>{i18n.t('candidate_path__results')}</Title>
												<Text>
													{i18n.t('candidate_path__results__text')}
												</Text>
											</div>
										</div>
									}
								</Fragment>
							}
							{levelTwo.length !== 0 && (
								<EndProcess
									businessSlug={props.businessSlug}
									colorTheme={props.colorTheme}
								/>
							)}
						</div>
					</Col>
					: null
				}
				<Col
					xs={24}
					sm={24}
					md={16}
					lg={16}
					xl={18}
				>
					<CandidateStage
						businessSlug={props.businessSlug}
						colorTheme={props.colorTheme}
					/>
				</Col>
				<Col
					xs={24}
					sm={24}
					md={8}
					lg={8}
					xl={6}
				>
					<Progress />
					<CandidatePathPerform />
				</Col>
			</Row>
		</Row>
	);
};

const mapStateToProps = (state) => {
  return {
    items: state.data.data.items,
	isSeed: state.data.data?.is_seed,
  };
};

export default connect(mapStateToProps)(CandidatePathContent);
