import React from "react";
import { Input, Form, Typography } from "antd";

const classNames = {
  limit: {
    fontSize: "0.9rem",
  },
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end"
  },
};

const MultiLine = (props) => {
  const { question, error, index, answer, disabled, mandatory } = props;
  return (
    <React.Fragment>
      <Form.Item
        name={"multiline"}
        label={
          <Typography.Text style={{ whiteSpace: 'normal' }}>
            {mandatory && <Typography.Text style={{ color: 'red' }}>* </Typography.Text>}
            {question}
          </Typography.Text>
        }
        labelAlign="left"
      >
        <Input.TextArea
          disabled={disabled}
          value={answer}
          maxLength={1500}
          placeholder='Escribe tu respuesta aquí.'
          onChange={(event) => props.handleChangeV2(index, event.target.value)}
        />
        {error && (
          <Typography style={{ color: "red", marginTop: 3, fontSize: 12 }}>
            Tienes que responder esta pregunta para continuar.
          </Typography>
        )}
      </Form.Item>
      <div style={classNames.container}>
        {answer && <p style={classNames.limit}>{answer.length}/1500 </p>}
      </div>
    </React.Fragment>
  );
};

export default MultiLine;
