import React, { useState } from 'react';
import './styles.scss';

import useBoolean from '../../../../hooks/useBoolean';

import { Col, Button, Radio, Input } from 'antd';

const Filter = ({ filter }) => {
  const [show, { toggle }] = useBoolean(false); // TODO: usa nuevo hook global con toggle

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  return (
    <Col xs={24} className="candidate-filter">
      <Col xs={24} className="candidate-filter__header">
        <span>{filter.placeholder}</span>
        <Button
          icon={show ? 'caret-up' : 'caret-down'}
          type="link"
          style={{ color: '#454545' }}
          onClick={toggle}
        />
      </Col>
      {show ? (
        <Radio.Group
          onChange={e => filter.action(e.target.value)}
          value={filter.value}
        >
          {filter.options.map(opt => (
            <Radio style={radioStyle} value={opt.value}>
              {opt.text}
            </Radio>
          ))}
        </Radio.Group>
      ) : null}
    </Col>
  );
};

const Filters = ({ toggle, filters }) => {
  return (
    <div className="candidate-filters">
      <Col xs={24} className="candidate-filters__header">
        <span>Filtros</span>
        <Button icon="close" onClick={toggle} />
      </Col>
      <Col xs={24} className="candidate-filters__content">
        {Object.values(filters).map(filter => (
          <Filter filter={filter} />
        ))}
      </Col>
      <Col xs={24}>
        <Button type="primary" onClick={toggle}>
          Aplicar filtros
        </Button>
      </Col>
    </div>
  );
};

export default Filters;
