import React, { useState } from 'react'
import {
  Row,
  Col,
  Layout,
  Icon,
} from 'antd';
import PropTypes from 'prop-types';

function BlackFooter(props) {
  return (
    <Layout.Footer style={{ display: props.visible ? '' : 'none', padding: '10px' }} className='actions-footer'>
      <Row type="flex" justify="space-around" align="middle">
        <Col sm={22}>
          {props.children}
        </Col>
      </Row>
    </Layout.Footer>
  )
}

BlackFooter.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.node
}
export default BlackFooter;
