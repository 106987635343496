import React, { useMemo } from 'react';
import { Typography } from 'antd';
import ParseHtml from '../../../components/parseHtml';

// import { wrapText } from '../helpers';

import { ResponsiveLine } from '@nivo/line';
import i18n from '../../../i18n';

const { Text, Title } = Typography;

const Trait = ({ trait }) => {
  const calculatePercentage = (value) => {
    return 25 + (value * 950) / 100;
  };

  const buildSegments = () => {
    if (trait.type === 'personality' && trait.relevance == 0) {
      return (
        [
          {
            color: '#FFD6E7',
            percentage: [0, trait.quantiles[0]],
          },
          {
            color: '#FF85C0',
            percentage: [trait.quantiles[0], trait.quantiles[1]],
          },
          {
            color: '#EB2F96',
            percentage: [trait.quantiles[1], trait.quantiles[2]],
          },
          {
            color: '#9E1068',
            percentage: [trait.quantiles[2], trait.quantiles[3]],
          },
          {
            color: '#520339',
            percentage: [trait.quantiles[3], 100],
          },
        ]
      )
    }
    else if (trait.type === 'personality' && trait.relevance >= 0) {
      let lineColors = ['#FFA39E', '#FFE58F', '#71D83E', '#FFE58F', '#FFA39E'];
      let newQuantiles = [trait.measuredTraitScore - 20, trait.measuredTraitScore, trait.measuredTraitUpperScore, trait.measuredTraitUpperScore + 20];
      while (newQuantiles[0] <= 0 || newQuantiles[newQuantiles.length - 1] >= 100) {
        if (newQuantiles[0] <= 0) {
          // Left shift
          // array = [1,2,3] -> [2,3,3]
          newQuantiles.push(newQuantiles[newQuantiles.length - 1])
          newQuantiles.shift();
          lineColors.push(lineColors[lineColors.length - 1])
          lineColors.shift();
        }
        else if (newQuantiles[newQuantiles.length - 1] >= 100) {
          // Rigth shift
          // array = [1,2,3] -> [1,1,2]
          newQuantiles.pop();
          newQuantiles.unshift(newQuantiles[0]);
          lineColors.pop()
          lineColors.unshift(lineColors[0])
        }
      };
      
      return (
        [
          {
            color: lineColors[0],
            percentage: [0, newQuantiles[0]],
          },
          {
            color: lineColors[1],
            percentage: [newQuantiles[0], newQuantiles[1]],
          },
          {
            color: lineColors[2],
            percentage: [newQuantiles[1], newQuantiles[2]],
          },
          {
            color: lineColors[3],
            percentage: [newQuantiles[2], newQuantiles[3]],
          },
          {
            color: lineColors[4],
            percentage: [newQuantiles[3], 100],
          },
        ]
      );
    }
    else {
      return (
        [
          {
            color: '#FFA39E',
            percentage: [0, trait.idealScore * 0.6],
          },
          {
            color: '#FFE58F',
            percentage: [trait.idealScore * 0.6, trait.idealScore],
          },
          {
            color: '#71D83E',
            percentage: [trait.idealScore, 100],
          },
        ]
      );
    }
  };

  const drawLines = () => {
    const config = buildSegments(trait);

    return config.map((line, idx) => (
      <line
        key={idx}
        x1={calculatePercentage(line.percentage[0])}
        x2={calculatePercentage(line.percentage[1])}
        y1="0"
        y2="0"
        stroke={line.color}
        strokeWidth="20"
      />
    ));
  };

  const [graphData, totalY, totalCandidates] = useMemo(
    (_) => {
      const tmpGraph = trait.distribution
        ? JSON.parse(trait.distribution).map(({ name, val }) => ({
          x: name,
          y: val,
        }))
        : [];

      return [
        tmpGraph,
        tmpGraph.reduce((total, { y }) => total + y, 0),
        [
          {
            id: 'candidates',
            color: 'rgb(236, 132, 162)',
            border: 'black',
            data: tmpGraph,
          },
        ],
      ];
    },
    [trait.distribution]
  );

  return (
    <div className="report-pdf-trait">
      <p className="t12">
        <strong>{trait.traitTranslation[i18n.language]}</strong>
      </p>
      <p
        className="t12"
        style={{ lineHeight: 1.5, textAlign: 'justify', flexGrow: 1 }}
      >
        {trait.description}
      </p>
      <div>
        <div id={`${trait.type}-${trait.code}`} className="config-preview">
          <ResponsiveLine
            data={totalCandidates}
            height={50}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 'auto',
              max: 'auto',
              stacked: true,
              reverse: false,
            }}
            curve="basis"
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            enableGridX={false}
            enableGridY={false}
            enableArea={true}
            colors="#597EF7"
            borderColor="#597EF7"
            enablePoints={false}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[]}
            isInteractive={false}
          />
        </div>
        <div style={{ position: 'relative', zIndex: 200 }}>
          <svg
            width="100%"
            height="50"
            viewBox="0 0 1000 65"
            xmlns="http://www.w3.org/2000/svg"
          // there is 1000px of witdh available to paint (viexBox autoscale vectors)
          >
            {drawLines()}
            {new Array(11).fill(10).map((n, i) => (
              <span key={i}>
                <text
                  x={25 + (950 / 10) * i}
                  y="80"
                  fontSize="30"
                  textAnchor="middle"
                  fill="#5E5E5E"
                >
                  {n * i}
                </text>
                <line
                  x1={25 + (950 / 10) * i}
                  x2={25 + (950 / 10) * i}
                  y1="22"
                  y2="38"
                  stroke="#D9D9D9"
                  strokeWidth="6"
                  strokeLinecap="square"
                  name="marker"
                />
              </span>
            ))}
            <circle
              cx={25 + (950 * trait.candidateScore) / 100}
              cy="0"
              r="30"
              fill="#000000"
            />
            <text
              x={25 + (950 * trait.candidateScore) / 100}
              y="10"
              fontSize="30"
              fill="#FFFFFF"
              textAnchor="middle"
            >
              {trait.candidateScore}
            </text>
            {/* {wrapText(trait.lowerBound || 'Bajo', 10, 80)}
          {wrapText(trait.upperBound || 'Alto', 990, 80, 'end')} */}
          </svg>
        </div>
      </div>
      <div
        className="t12"
        style={{ lineHeight: 1.5, textAlign: 'justify', flexGrow: 1, whiteSpace: 'pre-wrap' }}
      >
        {trait.intervalDescription && ParseHtml(trait.intervalDescription.replaceAll('\\n', '<br>'))}
      </div>
    </div>
  );
};

export default Trait;
