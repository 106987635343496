import React, { useState } from 'react';
import PropTypes from "prop-types";

import {
  Button, Card, Col, Form, Icon, Input, message, Row, Typography
} from 'antd';

const { Paragraph, Title } = Typography;
const { Password } = Input;

const ChangePassword = (props) => {
  const [confirmDirty, setConfirmDirty] = useState(false);
  const [loading, setLoading] = useState();
  const { getFieldDecorator, validateFields } = props.form;

  const validateNewPassword = (rule, value, callback) => {
    const re = /^(?=.*[0-9])(?=.*[a-z])/;

    if (value) {
      if (confirmDirty)
        validateFields(['confirmNewPassword'], { force: true });
      if (!value.match(re))
        callback(true); // No cumple
    }
    callback(); // Cumple
  };

  const comparePasswords = (rule, value, callback) => {
    // Confirmar que las contraseñas sean iguales
    if (value && value !== props.form.getFieldValue('newPassword'))
      callback('Las contraseñas no coinciden.');
    callback();
  };

  const handleConfirmBlur = (e) => {
    const { value } = e.target;
    setConfirmDirty(confirmDirty || !!value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields((errors, values) => {
      if (!errors) {
        setLoading(true);
        const { email, fetchAndReturn, login, token, userId } = props;
        let url = `/api/v1/accounts/businessusers/${userId}/`;
        const body = {
          'old_password': values.oldPassword,
          'password': values.newPassword,
        }
        fetchAndReturn(token, url, 'patch', body).then((response) => {
          if (response.status >= 400) {
            if (response.message) {
              if (response.message == 'Invalid credentials.') {
                message.error({
                  duration: 5,
                  content: 'Contraseña actual inválida',
                });
              }
            } else {
              message.error({
                duration: 5,
                content: 'No fue posible actualizar la contraseña',
              });
            }
            setLoading(false);
          } else {
            url = 'api/v1/accounts/logout/';
            fetchAndReturn(token, url, 'post').then(() => {
              login(email, values.newPassword, '/account/businessuser', '').then(() => {
                props.goBackToOptions({
                  responseReceived: response.status, 
                  content: "Contraseña modificada exitosamente",
                });
              });
            });
          }
        })
      } else {
        const error_keys = Object.keys(errors);
        for (let i = 0; i < error_keys.length; i++) {
          const field = error_keys[i];
          errors[field].errors.map(error => {
            message.error({
              duration: 5,
              content: error.message,
            });
          })
        }
      }
    });
  };

  return (
    <>
      <Row 
        type="flex" 
        align="middle"
        style={{
          marginBottom: "2em",
        }}
      >
        <Col>
          <Icon 
            onClick={props.goBackToOptions}
            type="left"
            style={{ 
              color: "#A4A4A4",
              marginRight: "10px",
              paddingTop: "5px",
            }} 
          />
        </Col>
        <Col>
          <Title
            style={{
              color: "#454545",
              fontWeight: 700,
              fontSize: "20px",
              margin: 0,
              padding: 0,
            }}
          >
            Cambiar contraseña
          </Title>
        </Col>
      </Row>
      <Row>
        <Card className="change-password">
          <Row>
            <Col 
              span={10} 
              style={{ height: "100%", paddingRight: "1em" }}
            >
              <Form onSubmit={handleSubmit}>
                <Form.Item required>
                  <Title>
                    Nueva contraseña
                  </Title>
                  {getFieldDecorator('newPassword', {
                    rules: [
                      {
                        min: 6,
                        message: "La contraseña debe tener al menos 6 caracteres.",
                      },
                      {
                        required: true,
                        message: "Debes ingresar la nueva contraseña.",
                      },
                      {
                        validator: validateNewPassword,
                        message: "La contraseña debe tener letras Y números."
                      }
                    ],
                  })(<Password />)}
                </Form.Item>
                <Form.Item required>
                  <Title>
                    Confirmar nueva contraseña
                  </Title>
                  {getFieldDecorator('confirmNewPassword', {
                    rules: [
                      {
                        required: true,
                        message: "Debes ingresar la nueva contraseña nuevamente.",
                      },
                      {
                        validator: comparePasswords,
                      },
                    ],
                  })(<Password onBlur={handleConfirmBlur} />)}
                </Form.Item>
                <Form.Item required>
                  <Title>
                    Contraseña actual
                  </Title>
                  {getFieldDecorator('oldPassword', {
                    rules: [
                      {
                        required: true,
                        message: "Debes ingresar tu contraseña actual.",
                      }
                    ],
                  })(<Password />)}
                </Form.Item>
                <Row>
                  <Form.Item>
                    <Button 
                      onClick={props.goBackToOptions}
                      style={{ marginRight: "1em" }}
                    >
                      Cancelar
                    </Button>
                    <Button type="primary" htmlType="submit" loading={loading}>
                      Cambiar contraseña
                    </Button>
                  </Form.Item>
                </Row>
              </Form>
            </Col>
            <Col 
              span={14}
              style={{ height: "100%", paddingLeft: "1em" }}
            >
              <Title>
                Cómo debe ser tu contraseña
              </Title>
              <Paragraph>Debe tener mínimo 6 caracteres, una letra minúscula y un número.</Paragraph>
              <Paragraph>No incluyas tu nombre, apellido o e-mail, ni caracteres idénticos consecutivos.</Paragraph>
              {/* <Title>Seguridad</Title> */}
              {/* Quiero cerrar mis sesiones activas al cambiar de clave. */}
            </Col>
          </Row>
        </Card>
      </Row>
    </>
  );
};

ChangePassword.propTypes = {
  goBackToOptions: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  fetchAndReturn: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  login: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
};

const ChangePasswordForm = Form.create()(ChangePassword)

export default ChangePasswordForm;