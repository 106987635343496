import React, { useState } from 'react'

import {
  Button,
  Col,
  Icon,
  Row,
  Typography,
  Tag
} from 'antd'

import './NewProcessModal.scss'

function GenomeCard({ data, duplicateGenome, smartSearchActive }) {
  const [viewMore, setViewMore] = useState(false);
  const family = [...new Set(data.keywords.map(item => item.family))]

  const getCurrentTraits = () => {
    const traitComponent = data.traits.map((obj, idx) => <Col span={12} key={idx} style={{ marginBottom: 3 }}>{`• ${obj.trait}`}</Col>);
    return (
      <>
        <div style={{ marginBottom: 5, marginTop: 15 }}><strong>Rasgos medidos:</strong></div>
        <Row style={{ marginBottom: 25 }}>
          {traitComponent}
        </Row>
      </>
    )
  };
  return (
    <div className={smartSearchActive ? 'smart-genome-card': 'genome-card'}>
      <div className='genome-card-content'>
        <div className='genome-card-title'>
          {data.job_application}
          {
            smartSearchActive &&
            <img style={{ height: 16 }} src='https://genoma-assets.s3.us-east-2.amazonaws.com/magic-wand.svg' alt="genomin-6" />
          }
        </div>
        <div className='genome-card-tags'>
          {family.map((tagValue, index) => <Tag key={`${data.job_application}-tag-${index}`} color='blue'>{tagValue}</Tag>)}
        </div>
        <Row type='flex' justify='space-between'>
          <Col>
            <span style={{ marginRight: 15 }}>
              <Icon type='clock-circle' style={{ color: '#597EF7', fontSize: 14, paddingRight: 5 }} />
              {`${data.duration} min`}
            </span>
            <span>
              <Icon type='rocket' style={{ color: '#597EF7', fontSize: 14, paddingRight: 5 }} />
              {`${data.activities_count - 1} evaluaciones`}
            </span>

          </Col>
        </Row>
        <div>
          <Typography.Text>
            {data.short_description ?? 'Sin Información'}
          </Typography.Text>
        </div>

        {viewMore &&
          <div>
            {getCurrentTraits()}
          </div>
        }
        {
          /*
          viewMore &&
          <>
            <div>
              <Typography.Text strong>
                {'Rasgos medidos:'}
              </Typography.Text>
              <ul style={{ columnCount: '2' }}>
                {traits.map((trait, index) => <li key={`${data.job_application}-trait-${index}`}>{trait.fields.trait}</li>)}
              </ul>
            </div>
            {
            //Nota: solamente algunos genomas cognitivos tienen ejemplos de cargos 
            }
            <div>
              <Typography.Text strong>
                Ejemplos de cargos asociados:
              </Typography.Text>
              <ul style={{ columnCount: '2' }}>
                {data.keywords.map((keyword, index) => <li key={`${data.job_application}-keyword-${index}`}>{keyword.name}</li>)}
              </ul>
            </div>
          </>
          */
        }
      </div>
      <div>


        <Row type='flex' justify='space-between'>
          <Col onClick={() => setViewMore(!viewMore)} style={{ cursor: 'pointer' }}>
            <Icon type={!viewMore ? 'plus' : 'minus'} style={{ fontSize: 14, paddingRight: 5 }} />
            {!viewMore ? 'Ver más' : 'Ver menos'}
          </Col>

          <Button type='primary' onClick={() => duplicateGenome(data.id)}>
            Seleccionar genoma
          </Button>

        </Row>
      </div>
    </div>

  )
}

export default GenomeCard;