import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

//crear array de números basado en la cantidad de preguntas
//para luego mostrar la lista de estas y puedan ser draggables
const getItems = count =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: k,
    content: k
  }));

  /**
   * order the ko question using the position property
   * @param {*} count 
   * @returns 
   */
const formatItems = count =>
  count.map((k, idx) => ({
    id: k,
    content: k,
    position: k.position ? k.position : idx,
  })).sort(
    (a,b) => a.position - b.position
  );

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  borderRadius: 7,
  margin: `0 0 15px 0`,
  outline: "none",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  padding: grid,
  width: '100%'
});

/**
 * Nuevo dnd.
 * No se pega, es más accesible, es más bonito y es de Atlassian
 * 
 * @DragAndDrop 
 */

class DragAndDrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: props.ko ? formatItems(props.items) : getItems(props.items.length)
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentWillReceiveProps(nextProps) {
      this.setState({ 
        items: this.props.ko ? formatItems(nextProps.items) : getItems(nextProps.items.length)
      });
  }

  onDragEnd(result) {
    const { onChange } = this.props;
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({ items });
    onChange && onChange(items);
  }

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              className='dnd-row'
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {this.state.items.map((item, index) => (
                <Draggable 
                  key={item.id}
                  isDragDisabled={this.props.isDragDisabled}
                  draggableId={item.id.toString()} index={index}>
                  {(provided, snapshot) => (
                    <div 
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                      className={this.state.items.length === index+1 ? 'animate-last-question' : ''}
                    >
                      <div className='dnd-wrap'>
                        <img src='https://dashboard.myinterview.com/static/img/icons/drag-reorder-icon.svg' />
                        <div className='' 
                          style={{ 
                            borderRadius: '7px', 
                            background: this.props.items ? 
                              'transparent' : snapshot.isDragging ? 
                                "#4c99ea" : "#fff", 
                                cursor: 'default', 
                                width: '100%' 
                          }}>

                          {this.props.render(item.id, index)}
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

// Put the thing into the DOM!
export default DragAndDrop
