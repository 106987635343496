import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actionCreators from '../../../../actions/data';

import { Tabs, Badge, Typography } from 'antd'

const { Text } = Typography
const { TabPane } = Tabs

const status = ['ACTIV', 'INACT', 'OTHER']
const titles = {
  'ACTIV': 'Abiertos',
  'INACT': 'Cerrados',
  'OTHER': 'Borradores'
}

const TabBar = (props) => {
  const { processes } = props;
  const [jobsByStatus, setJobsByStatus] = useState({ 'ACTIV': 0, 'INACT': 0, 'OTHER': 0 })


  useEffect(() => {
    if (processes) {
      setJobsByStatus({ 
        'ACTIV': processes.ACTIV.length, 
        'INACT': processes.INACT.length,
        'OTHER': processes.OTHER.length,
      })
      return;
    }

  }, [processes])


  const handleTabChange = (key) => {
    switch(key) {
      case '0':
        props.onChange('ACTIV')
        break
      case '1':
        props.onChange('INACT')
        break
      case '2':
        props.onChange('OTHER')
        break
      default:
        props.onChange('ACTIV')
    }
  }

  return (
    <Tabs defaultActiveKey="0" onChange={handleTabChange} style={{ width: 'auto' }}>
      {status.map((stat, idx) => (
        <TabPane
          key={idx}
          tab={
            <Text>
              {titles[stat]} <Badge count={jobsByStatus[stat]}  overflowCount={9999}/>
            </Text>
          }
        />
      ))}
    </Tabs>
  )
}

const mapStateToProps = (state) => {
  return {
    data: state.data.data,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabBar)