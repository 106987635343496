import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Col, Card, Empty, Tooltip, Divider, Typography, Icon, Row, Table, List } from 'antd';

import * as actionCreators from "../../../../../../actions/data";
import './InterviewKit.scss';

const { Text } = Typography;

function InterviewKit(props) {

  const [interviewKits, setInterviewKits] = useState([]);
  const [addedInterviewKits, setAddedInterviewKits] = useState([]);

  let endpointBusiness = '/api/v1/evaluations/interviewkit_list/'
  let endpointJobapp = `/api/v1/evaluations/jobapplication/${props.jobAppId}/interviewkit/`
  useEffect(() => {
    // Check if process have added kits
    props.actions.fetchAndReturn(props.token, endpointJobapp)
      .then(response => {
        if (response.status < 400) {
          setAddedInterviewKits(response.body)

          // Check if bussines  have kits templates 
          props.actions.fetchAndReturn(props.token, endpointBusiness)
            .then(responseBussines => {
              if (responseBussines.status < 400) {
                // Remove remove previously added kits
                response.body.forEach((addedKit, index, array) => {
                  let itemIndex = responseBussines.body.findIndex(tmp => tmp.id == addedKit.interview_kit);
                  responseBussines.body.splice(itemIndex, 1)
                })
                setInterviewKits(responseBussines.body)
              }
            })
        }
      })
  }, []);

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'group',
      key: 'group',
    },
    {
      title: 'N° de preguntas',
      dataIndex: 'questions_total',
      key: 'questions',
    },
    {
      title: 'Tipo Entrevista',
      dataIndex: 'kit_type',
      key: 'type',
      render: (type) => type === 'INTERVIEW' && <Text>Entrevista</Text> || type === 'CARD' && <Text>Scorecard</Text>
    },
    {
      title: '',
      key: 'action',
      render: (item) => (
        <span>
          <Button className='action-btn' onClick={() => addInterviewKit(item)}>Agregar</Button>
        </span>
      ),
    }
  ];

  const addInterviewKit = (kit) => {
    let newKits = [...addedInterviewKits, kit]
    setAddedInterviewKits(newKits);
    const indexToDelete = interviewKits.findIndex(item => item.id === kit.id)
    let ikits = [...interviewKits]
    ikits.splice(indexToDelete, 1)
    setInterviewKits(ikits)

    props.onchange(kit, 'add')
  }

  const removeKit = (kit) => {
    setInterviewKits([...interviewKits, kit])
    const indexToDelete = addedInterviewKits.findIndex(item => item.id === kit.id)
    let ikits = [...addedInterviewKits]
    ikits.splice(indexToDelete, 1)
    setAddedInterviewKits(ikits)

    props.onchange(kit, 'delete')
  }

  return (
    <Card style={{ minHeight: '90vh', borderTop: '1px solid #efefef', paddingTop: '2em' }}>
      <Row>
        <Col xs={24} sm={24} className='rubricas' style={{ marginBottom: '25px' }} >
          <Text strong>Evaluaciones asignadas:</Text>
          <List
            bordered
            locale={{
              emptyText: "No hay evaluaciones asignadas al proceso"
            }}
            dataSource={addedInterviewKits}
            renderItem={(item) => (
              <List.Item key={item.key} actions={[<Button icon='delete' className='delete-btn' onClick={() => removeKit(item)} />]}>
                <Typography.Text >{item.group}</Typography.Text>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Table pagination={false} dataSource={interviewKits} columns={columns} rowKey='id' />
        </Col>
      </Row>
    </Card>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch
  };
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    token: state.auth.token,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(InterviewKit);