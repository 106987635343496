import React, { useRef, useState, useContext } from 'react';
import { Row, Col, Typography, Icon, Button, Modal, Divider } from 'antd';
import { useReactToPrint } from 'react-to-print';
import { CandidateContext } from '../config/CandidateContext';

import Card from './Card';

const { Text, Paragraph, Title } = Typography;

const CulturalReport = ({ traits, mode='summary' }) => {
  const [showModal, setShowModal] = useState(false);
  const { changeKey } = useContext(CandidateContext);
  const handlePrint = () => setShowModal(true);

  if (mode === 'summary') {
    return (
      <Card minHeight='356px'>
        <Row type='flex' justify='start' align='middle'>
          <Title level={4} style={{ color: '#454545' }}>
            Cultura Organizacional
          </Title>
        </Row>
        <Row type='flex' justify='space-between' align='middle'>
          <Text>
            El evaluado(a) muestra sus preferencias frente a una cultura organizacional
          </Text>
          <Button onClick={() => changeKey('2')} style={{ marginRight:'10px' }}>Ver detalles</Button>
        </Row>
        <Row type='flex' justify='center' align='middle' style={{ marginTop: '20px', rowGap: '20px' }}>
          {traits.map((trait, i) => (
            <CulturalTraitEntry key={`cultural-trait-entry__${i}`} idx={i} trait={trait} />
          ))}
        </Row>
      </Card>
    );
  }
  return (
    <>
      <Row type='flex' justify='start' align='middle' style={{ marginTop: '20px' }}>
        <Row type='flex' justify='space-between' align='middle' style={{ width: '100%' }}>
          <Col span={6}>
            <Title level={4} style={{ color: '#454545' }}>Cultural organizacional</Title>
          </Col>
          <Col span={6}>
            <Button onClick={handlePrint}>Descargar Reporte PDF</Button>
          </Col>
        </Row>
        <Row type='flex' justify='start' align='middle' style={{ marginTop: '20px', rowGap: '20px' }}>
          {traits[0]?.dateCreated ? <Text>Completado el {traits[0].dateCreated}</Text> : null}
          <Col span={24}>
            <Text>Esta evaluación mide las preferencias del evaluado(a) frente a la cultura organizacional y las ordena en 4 dimensiones:</Text>
          </Col>
          <Col span={6}>
            {traits.map((trait, idx) => (
              <li key={`cultural-list-items-category_${idx}`}>
                {`${idx + 1}. ${trait.lowerBound} / ${trait.upperBound}`}
              </li>
            ))}
          </Col>
          <Paragraph style={{ marginBottom: '30px' }}>
            Conocer el resultado del evaluado(a) en cada una de estas dimensiones te permitirá comprender si se ajusta o no a la cultura organizacional asociada 
            a tu búsqueda.
          </Paragraph>
        </Row>
        <Title level={4} style={{ color: '#454545' }}>Resultados del evaluado(a)</Title>
        <Row type='flex' style={{ rowGap: '20px' }}>
          {traits.map((trait, i) => (
            <CulturalTraitEntry key={`cultural-trait-entry__${i}`} idx={i+1} trait={trait} mode='detailed' />
          ))}
        </Row>
      </Row>
      <ModalPdf visible={showModal} onClose={() => setShowModal(false)} traits={traits} />
    </>
  );
}

function CulturalTraitEntry({ trait, idx, mode='summary' }) {
  const getTraitDescription = () => {
    const keyword = idx === 1 ? 'trabajo' : 'ambiente';
    const lowerBound = trait.lowerBound.toLowerCase();
    const upperBound = trait.upperBound.toLowerCase();
    return {
      "-2": `Fuerte preferencia por el ${keyword} ${lowerBound}`,
      "-1": `Ligera preferencia por el ${keyword} ${lowerBound}`,
      "0": `Equilibrio entre ${keyword} ${lowerBound} y ${upperBound}`,
      "1": `Ligera preferencia por el ${keyword} ${upperBound}`,
      "2": `Fuerte preferencia por el ${keyword} ${upperBound}`,
    };
  };

  const base = (
    <Row
      type='flex'
      justify='center'
      className="new-modal-summary-evals__cultural-trait"
      align='middle'
      style={{ width: '100%', columnGap: '8px' }}
    >
      <Col span={6}>
        <div className='cultural-subtrait-label-container'>
          <Text className="text-10px">{getTraitDescription()[trait.candidateScore]}</Text>
        </div>
      </Col>
      <Col span={3}>
        <div className={`cultural-node-selected__${idx%2} cultural-trait-pole cultural-trait-pole__selected`}>
          {trait.lowerBound}
        </div>
      </Col>
      <Col span={10}>
        <Row type='flex' justify='space-around'>
          {Array.apply(null, Array(5)).map((_, i) => {
            if (i - 2 === Math.round(trait.candidateScore)) {
              return (
                <div key={i} id={`node_${i}`} className={`cultural-node-selected__${idx%2} cultural-scale-node`}>
                  <div className='cultural-scale-inside-node__selected'/>
                </div>
              );
            }
            return (
              <div key={i} className='cultural-scale-node'/>
            );
          })}
        </Row>
      </Col>
      <Col span={3}>
        <div className={`cultural-node-selected__${idx%2} cultural-trait-pole cultural-trait-pole__selected`}>
          {trait.upperBound}
        </div>
      </Col>
    </Row>
  );

  if (mode === 'summary') {
    return (base);
  } else if (mode === 'detailed') {
    return (
      <Card minHeight='256px'>
        <Row type='flex' align='middle' style={{ rowGap: '16px' }}>
          <Row style={{ width: '100%' }}>
            <Title level={4} style={{ color: '#454545' }}>
              {`${trait.lowerBound} / ${trait.upperBound}`}
            </Title>
          </Row>
          <Row style={{ width: '100%', marginRight: '10px' }}>
            <Text style={{ textAlign: 'justify' }}>{trait.description}</Text>
          </Row>
          {base}
          <Paragraph style={{ textAlign: 'justify', marginRight: '10px' }}>{trait.intervalDescription[trait.candidateScore]}</Paragraph>
        </Row>
      </Card>
    );
  } else if (mode === 'pdf') {
    return (
      <Row
        type='flex'
        className='cultural-fit-report-entry-row'
        align='middle'
      >
        <Col span={6} className={`cultural-trait-pole cultural-node-selected__${idx%2}`}>
          <Text style={{ color: 'white' }}>{trait.lowerBound}/{trait.upperBound}</Text>
        </Col>
        <Row style={{ width: '100%', marginRight: '20px' }}>
          <Text style={{ textAlign: 'justify' }}>{trait.description}</Text>
        </Row>
        <Title level={4}>Resultados del evaluado(a)</Title>
        <Row
          type='flex'
          justify='center'
          align='middle'
          style={{ width: '100%' }}
        >
          <Col span={22}>
            <Card minHeight='auto' stretch={true}>
              {base}
            </Card>
          </Col>
        </Row>
        <Paragraph style={{ textAlign: 'justify', marginRight: '20px' }}>{trait.intervalDescription[trait.candidateScore]}</Paragraph>
      </Row>
    );
  }
}

const ModalPdf = ({ visible, onClose, traits }) => {
  const today = new Date();
  const ref = useRef(null);
  const { candidate, jobApp } = useContext(CandidateContext);
  const handleDownload = useReactToPrint({
    content: () => ref.current,
    pageStyle: '@page { size: A4 portrait; margin: 0; } @media print { html, body { -webkit-print-color-adjust: exact;} }',
    documentTitle: `Reporte Cultural de ${candidate.user_name} - ${jobApp.job_application}`,
    onBeforeGetContent: () => {
      const cover = document.getElementById('cfrpc');
      cover.style.margin = '20px 20px 0px 20px';
    },
    onAfterPrint: () => {
      const cover = document.getElementById('cfrpc');
      cover.style.margin = '10px 10px 0px 10px';
    },
  });

  const header = (
    <Row
      type='flex'
      justify='center'
      align='middle'
      style={{ width: '100%', marginBottom: '20px' }}
    >
      <Text style={{ fontSize: '12px' }}>
        Candidato: {candidate.user_name} / Proceso: {jobApp.job_application} / Fecha de descarga: {today.toDateString()}
      </Text>
    </Row>
  );
  const header2 = (
    <Row type='flex' align='middle'>
      <Row type='flex' justify='center' style={{ width: '100%', marginBottom: '20px' }}>
        <Text style={{ fontSize: '12px' }}>
          Candidato: {candidate.user_name} / Proceso: {jobApp.job_application}/ Fecha de descarga: {today.toDateString()}
        </Text>
      </Row>
      <Row
        type='flex'
        justify='start'
        align='middle'
        style={{ width: '100%' }}
      >
        <Col span={2}>
          <img
            src={'https://genoma-assets.s3.us-east-2.amazonaws.com/report-cfit-icon.svg'}
            style={{ width: '34px', height: '34px' }}
          />
        </Col>
        <Col span={8}>
          <Title level={4} style={{ marginBottom: '0px' }}>
            Evaluación Cultura Organizacional
          </Title>
        </Col>
        <Col span={12} style={{ display: 'flex', justifyContent: 'end' }}>
          <img
            src='https://app.staging.genoma.work/media/logos/genoma-logo.png'
            alt='logo'
            style={{ width: '100px' }}
          />
        </Col>
      </Row>
    </Row>
  );
  const topContent = (
    <Row
      type='flex'
      justify='space-between'
      align='middle'
      style={{ width: '100%' }}
    >
      <Col span={10}>
        <Title level={4} style={{ color: '#454545' }}>{candidate.user_name}</Title>
        <Text>
          Proceso: {jobApp.job_application}
          <br/>
          DNI o RUT: {candidate.dni}
          <br/>
          Fecha de realización: {traits[0].dateCreated}
        </Text>
      </Col>
      <Col span={4}>
        <img
          src='https://app.staging.genoma.work/media/logos/genoma-logo.png'
          alt='logo'
          style={{ width: '100px' }}
        />
      </Col>
    </Row>
  );
  const intro = (
    <>
      <Row type='flex' justify='start' align='middle'>
        <img
          src={'https://genoma-assets.s3.us-east-2.amazonaws.com/report-cfit-icon.svg'}
          style={{ width: '34px', height: '34px', marginRight: '10px' }}
        />
        <Title level={4} style={{ marginBottom: '0px' }}>Evaluación Cultura Organizacional</Title>
      </Row>
      <Row
        type='flex'
        justify='start'
        align='middle'
        style={{ marginTop: '20px', rowGap: '20px' }}
      >
        <Text>Completado el {traits[0].dateCreated ?? null}</Text>
        <Col span={24}>
          <Text>Esta evaluación mide las preferencias del evaluado(a) frente a la cultura organizacional y las ordena en 4 dimensiones:</Text>
        </Col>
        <Col span={8}>
          {traits.map((trait, idx) => (
            <li key={`cultural-list-items-category_${idx}`}>
              {`${idx + 1}. ${trait.lowerBound} / ${trait.upperBound}`}
            </li>
          ))}
        </Col>
        <Paragraph style={{ marginBottom: '10px' }}>
          Conocer el resultado del evaluado(a) en cada una de estas dimensiones te permitirá comprender si se ajusta o no a la cultura organizacional asociada 
          a tu búsqueda.
        </Paragraph>
      </Row>
    </>
  );
  const footer = (
    <Row
      type='flex'
      justify='center'
      align='middle'
      style={{ width: '100%', marginTop: '30px' }}
    >
      <Text style={{ fontSize: '12px' }}>
        Nota: No existe autorización profesional para dar a conocer este informe al postulante ni a terceros, se considera información confidencial.
      </Text>
    </Row>
  );
  const references = (
    <Row
      type='flex'
      justify='center'
      align='middle'
      style={{ width: '100%', marginTop: '20px' }}
    >
      <Title level={4}>Referencias</Title>
      <Paragraph style={{ marginBottom: '700px', textAlign: 'justify', marginRight: '20px' }}>
        1. Cameron, K. S., & Quinn, R. E. (2011). Diagnosing and Changing Organizational Culture: Based on the Competing Values Framework (3rd ed.). San Francisco: Jossey-Bass.
        <br/>
        2. Jung, Tobias & Scott, Tim & Davies, Huw & Bower, Peter & Whalley, Diane & Mcnally, Rosalind & Mannion, Russell. (2016). Instruments for the Exploration of Organisational Culture - Compendium of Instruments. 10.13140/RG.2.1.4337.4484.
        <br/>
        3. Srivastava, S. B., Goldberg, A., Manian, V. G., & Potts, C. (2018). Enculturation Trajectories: Language, Cultural Adaptation, and Individual Outcomes in Organizations. Management Science, 64(3), 1348-1364. doi:10.1287/mnsc.2016.2671.
        <br/>
      </Paragraph>
    </Row>
  );

  return (
    <Modal
      title={
        <Button
          type='primary'
          onClick={handleDownload}
        >
          Descargar reporte
        </Button>
      }
      visible={visible}
      onCancel={onClose}
      footer={null}
      bodyStyle={{ padding: '12px' }}
      width={800}
      centered
    >
      <div ref={ref}>
        {/* cover */}
        <div
          className='cfit-report__page'
          id='cfrpc'
          style={{ 'margin': '10px 10px 0px 10px', justifyContent: 'flex-start' }}
        >
          <Row
            type='flex'
            justify='flex-start'
            style={{ backgroundColor: '#F759AB', paddingBlock: '35vh', flexDirection: 'column' }}
          >
            <Text 
            style={{ color: 'white', marginLeft: '20px', fontSize: '48px' }}
            >
              Evaluación
              <br/>
            </Text>
            <Title style={{color: 'white', fontSize: '48px', marginLeft: '20px', marginTop: '0px'}}>
              Cultura
              <br/>
              Organizacional
            </Title>
          </Row>
          <Row style={{backgroundColor: '#FFADD2', height: '2vh'}}/>
          <Row 
          type='flex' 
          justify='end' 
          align={'middle'} 
          style={{width: '100%'}}
          >
            <img 
            src='https://app.staging.genoma.work/media/logos/genoma-logo.png' 
            alt='logo' 
            style={{width: '200px'}}
            />
          </Row>
        </div>
        {/* Page 1 */}
        <div className='cfit-report__page'>
          {header}
          {topContent}
          <Divider />
          {intro}
          <Divider />
          <Title level={4} style={{color: '#454545'}}>
            Resultados del evaluado(a)
          </Title>
          <Row 
          type='flex' 
          justify='center'
          align='middle' 
          style={{marginTop: '20px', rowGap: '20px', marginBottom: '90px'}}
          >
            {traits.map((trait, i) => (
              <CulturalTraitEntry key={`cultural-trait-entry__${i}`} idx={i} trait={trait}/>
              ))}
          </Row>
          {footer}
        </div>
        {/* Page 2 */}
        <div className='cfit-report__page'>
          {header2}
          <Divider />
          {traits.slice(0,2).map((trait, i) => (
            <CulturalTraitEntry key={`cultural-trait-entry__${i}`} idx={i} trait={trait} mode='pdf'/>
            ))}
          {footer}
        </div>
        {/* Page 3 */}
        <div className='cfit-report__page'>
          {header2}
          <Divider />
          {traits.slice(2,4).map((trait, i) => (
            <CulturalTraitEntry key={`cultural-trait-entry__${i}`} idx={i+2} trait={trait} mode='pdf'/>
            ))}
          {footer}
        </div>
        {/* page 4 */}
        <div className='cfit-report__page' style={{ marginTop: '20px' }}>
          {header2}
          <Divider />
          {references}
          {footer}
        </div>
      </div>
    </Modal>
  );
}

export default CulturalReport;