import React, { useContext } from 'react';
import Overview from '../../Overview';
import './pa-pdf-report.scss';
import TemplateCard from '../TemplateCard';
import { AnalyticsContext } from '../../../context/AnalyticsContext';

import { fillTimeline, processNivoData } from '../../../assets/utils'


const FullReport = ({ refPrint }) => {

  const { sectionsData: { overview } } = useContext(AnalyticsContext);

  const {
    candidate_timeline,
  } = overview;
  const candidateTimeline = fillTimeline(candidate_timeline, 10);

  return (
    <div ref={refPrint} className='pa-pdf'>
      <section className='pa-pdf__coverpage print'>
        <div className='pa-pdf__coverpage__title'>
          <h1>Reporte resumennnn</h1>
          <h1>People Analitycs</h1>
        </div>
        <div className='pa-pdf__coverpage__logos'>
          <img src='https://genoma-assets.s3.us-east-2.amazonaws.com/logo.svg' />
          <img src='https://genoma-assets.s3.us-east-2.amazonaws.com/genomin.png' />
        </div>
      </section>
      <section className='pd-pdf__overview print break-page'>
        <div style={{ border: '1px solid #D9D9D9' }} className='card-print'>
          <TemplateCard
            templateGraphic
            graphicType='timeline'
            topic='candidate_timeline'
            title='Línea del tiempo de Candidatos'
            data={processNivoData(candidateTimeline, ['month', 'year'], '-')}
          />
        </div>

        <div className='card-print'>
          <Overview pdfReport />
        </div>
        <h1>.</h1>
      </section>
    </div>
  );
}

export default FullReport;