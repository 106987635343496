const ContextReducer = (state, action) => {
  switch (action.type) {
    case 'HYDRATE_STATE':
      return {
        ...state,
        ...action.payload,
      };
    case 'UPDATE_STAGES':
      return {
        ...state,
        stages: action.payload,
      };
    case 'UPDATE_ACTIVITIES':
      return {
        ...state,
        activities: action.payload,
      };
    case 'UPDATE_CURRENT_STAGE':
      return {
        ...state,
        currentStage: action.payload,
      };
    case 'UPDATE_CUTES':
      return {
        ...state,
        cuTes: {
          ...state.cuTes,
          [action.payload.key]: action.payload.value
        },
      };
    case 'UPDATE_FORM':
      return {
        ...state,
        candidateForm: {
          ...state.candidateForm,
          [action.payload.key]: action.payload.value
        },
      };
    case 'UPDATE_JOB_APPLICATION':
      return {
        ...state,
        jobApplication: {
          ...state.jobApplication,
          [action.payload.key]: action.payload.value
        },
      };
    case 'UPDATE_BUSINESS':
      return {
        ...state,
        business: {
          ...state.business,
          [action.payload.key]: action.payload.value
        },
      };
    case 'UPDATE_GAMES':
      return {
        ...state,
        games: {
          ...state.games,
          [action.payload.key]: action.payload.value
        },
      };
    case 'UPDATE_VIDEO_INTERVIEWS':
      return {
        ...state,
        videoInterviews: {
          ...state.videoInterviews,
          [action.payload.key]: action.payload.value
        },
      };
      case 'UPDATE_INTERVIEW_PROCESSES':
        return {
          ...state,
          interviewProcesses: {
            ...state.interviewProcesses,
            [action.payload.key]: action.payload.value
          },
        };
    case 'UPDATE_ADDITIONAL_QUESTIONS':
      return {
        ...state,
        additionalQuestions: {
          ...state.additionalQuestions,
          [action.payload.key]: action.payload.value
        },
      };
    case 'UPDATE_MEASURED_TRAITS':
      return {
        ...state,
        measuredTraits: {
          ...state.measuredTraits,
          [action.payload.key]: action.payload.value
        },
      };
    case 'UPDATE_INTEGRATIONS':
      return {
        ...state,
        integrations: {
          ...state.integrations,
          [action.payload.key]: action.payload.value
        },
      };
    // TODO deprecar ikits
    case 'UPDATE_IKITS':
      return {
        ...state,
        interviewKits: {
          ...state.interviewKits,
          [action.payload.key]: action.payload.value
        },
      };
    case 'UPDATE_FILES_REQUESTS':
      return {
        ...state,
        filesRequests: {
          ...state.filesRequests,
          [action.payload.key]: action.payload.value
        },
      };
    case 'UPDATE_TRIGGERS':
      return {
        ...state,
        triggers: {
          ...state.triggers,
          [action.payload.key]: action.payload.value
        },
      };
  }
};

export default ContextReducer;
