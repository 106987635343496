import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import QRCode from 'qrcode.react';
import * as actionCreators from '../../../../actions/data';

import { deleteProcess } from '../modals/delete';
import LoadingModal from '../modals/loading';
import ShareWithCollaborators from '../modals/shareWithCollaborators';
import ProcessState from '../modals/processState';
import MailExportInput from '../../../BusinessDashboard/Plugins/ExportMailModal';
import LaborumJobForm from '../../NewProcessFormV2/components/StepContent/6-publishJob/components/trabajando/form/JobForm'
import ShareJob from '../../NewProcessFormV2/components/StepContent/6-publishJob/components/ShareJob';

import {
  Tooltip,
  Button,
  Dropdown,
  Menu,
  Icon,
  message,
  Typography,
  Modal
} from 'antd'

const HoverActions = (props) => {
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [duplicate, setDuplicate] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [trabajandoModal, setTrabajandoModal] = useState(false);
  const [shareJobModal, setShareJobModal] = useState(false);
  const [shareUrl, setShareUrl] = useState('');
  const [encodedShareUrl, setEncodedShareUrl] = useState('');
  const [showQR, setShowQR] = useState(false);

  useEffect(() => {
    showLink();
  }, [props.process, props.businessOwner, props.business]);

  const copyLink = () => {
    navigator.clipboard.writeText(showLink())
    message.success('El link fue copiado a tu portapapeles')
  }

  const showLink = () => {
    let url = ''
    const SHARE_URL = process.env.REACT_APP_SHARE_URL.includes('app.genoma.work') ?
      'https://jobs.genoma.work' : process.env.REACT_APP_SHARE_URL;
    const businessSlug = props.business ? props.business.slug : props.businessOwner;
    if (businessSlug) {
      url = `${SHARE_URL}/${businessSlug}/${props.process.id}`;
    } else {
      url = `${SHARE_URL}/startprocess/${props.process.id}`;
    }
    setShareUrl(url);
    const encodedURI = encodeURI(url)
    setEncodedShareUrl(encodedURI);
    return encodeURI(url);
  };

  const handleAnalytics = () => {
    props.dispatch(push(`/peopleanalytics?jobappid=${props.process.id}`))
  }

  const handleEdit = (e) => {
    props.dispatch(push(`/processes/${props.process.id}/edit`))
  }

  const handleExport = (type) => (email) => {
    setLoadingExport(true)
    const { token } = props;
    let url = '';
    switch (type) {
      case 'candidateData':
        url = `gamesandtests/candidatelist/export/${props.process.id}/?email=${email}`;
        break;
      case 'candidatePostulationRegistry':
        url = `stats/postulationregistry/export/${props.process.id}/`;
        break;
    }
    const today = new Date()
    const parsedDate = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`
    const filename = `${props.jobAppName} ${parsedDate}.xls`
    props.actions.fetchFile(props.token, url, "", "get", filename)
      .then(() => {
        setLoadingExport(false)
      });
  };

  const showMailExportModal = (type) => {
    const modal = {};
    modal.modal = Modal.info({
      title: 'Ingrese correo para enviar el reporte.',
      content: <MailExportInput handleCandidatesExport={handleExport(type)} modal={modal} />,
      cancelButtonProps: { style: { display: 'none' } },
      okButtonProps: { style: { display: 'none' } }
    })
  }

  const handleDuplicate = () => {
    setLoading(true)
    const url = `/api/v1/gamesandtests/jobapplications/${props.process.id}/duplicate/`
    props.actions.fetchAndReturn(props.token, url, 'POST', ({ 'is_genome': false })).then((response) => {
      if (response.status < 400) {
        setDuplicate(true)
        return response
      }
      else {
        message.error('Error al duplicar este proceso')
      }
    }).then((response) => {
      setLoading(false)
      props.dispatch(push(`/processes/${response.body.id}/edit`))
    })
  }


  const handleShareWithSubordinates = () => {
    //modal
    setShareModal(!shareModal)
  }

  const handleStatusChange = () => {
    //modal
    setStatusModal(!statusModal)
  }

  const handleDelete = () => {
    deleteProcess({
      onOk: deleteJobApp,
      name: props.jobAppName
    })
  }

  const deleteJobApp = () => {
    const url = 'gamesandtests/jobapplications/' + props.id + '/';
    props.actions.fetchData(props.token, url, '', 'delete').then(async () => {
      await props.loadTableData(props.status)
    });
  };

  const updateJobApp = () => (job_portals) => {
    const url = `/api/v1/gamesandtests/jobapplications/${props.id}/`;
    const body = { job_portals };
    props.actions.fetchAndReturn(props.token, url, 'PATCH', body);
  }

  const handleMenuClick = (e) => {
    switch (e.key) {
      case '1':
        handleEdit()
        break
      case '2':
        copyLink()
        break
      case '3':
        handleShareWithSubordinates()
        break
      case '4':
        showMailExportModal('candidateData')
        break
      case '5':
        showMailExportModal('candidatePostulationRegistry')
        break
      case '6':
        handleDuplicate()
        break
      case '7':
        handleStatusChange('status')
        break
      case '8':
        handleDelete()
        break
      case '9':
        setTrabajandoModal(true);
        break
      case '10':
        setShareJobModal(true);
        break
      case '11':
        setShowQR(true);
        break
      case '12':
        handleAnalytics()
        break
    }
  }

  const isMobile = window.innerWidth <= 990

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Button.Group className='gw-pl__table__actions'>

        <Tooltip placement="top" title='Analytics'>
          <Button type="link" icon='line-chart' onClick={handleAnalytics} />
        </Tooltip>

        {
          (props.businessPermissions.business_admin || props.businessPermissions.role?.can_manage_jobapplications) &&
          <Tooltip placement="top" title='Editar proceso'>
            <Button type="link" icon='edit' onClick={handleEdit} />
          </Tooltip>
        }

        {
          (props.businessPermissions.business_admin || props.businessPermissions.role?.can_share_jobapplications_with_candidates) &&
          <Tooltip placement="top" title='Copiar link para invitar a candidatos a postular'>
            <Button type="link" icon='link' onClick={copyLink} />
          </Tooltip>
        }

        {props.subordinates.length !== 0 && props.businessPermissions.role?.can_share_jobapplications_with_users &&
          <Tooltip placement="top" title='Compartir con colaboradores'>
            <Button type="link" icon='usergroup-add' onClick={handleShareWithSubordinates} />
          </Tooltip>
        }

        {
          (props.businessPermissions.business_admin || props.businessPermissions.role?.can_create_jobapplications) &&
          <Tooltip placement="top" title='Duplicar proceso'>
            <Button type="link" icon='copy' onClick={handleDuplicate} />
          </Tooltip>
        }

        <Dropdown
          trigger={['click']}
          overlayClassName='gw-hover-actions'
          overlay={
            <Menu onClick={handleMenuClick}>
              {isMobile &&
                <Menu.Item key="2">
                  <Icon type="link" />
                  Copiar enlace del proceso
                </Menu.Item>
              }

              {isMobile && props.subordinates.length !== 0 && props.businessPermissions.role?.can_share_jobapplications_with_users &&
                <Menu.Item key="3">
                  <Icon type="usergroup-add" />
                  Compartir con colaboradores
                </Menu.Item>
              }

              <Menu.Item key="4">
                <Icon type="database" />
                Descargar datos de candidatos
              </Menu.Item>

              <Menu.Item key="5">
                <Icon type="history" />
                Descargar historial cambios
              </Menu.Item>

              <Menu.Item key="7">
                <Icon type="bars" />
                Estado
              </Menu.Item>
              {
                props.jobPortalIntegrations &&
                  props.jobPortalIntegrations.trabajando &&
                  props.jobPortalIntegrations.trabajando.token ?
                  (<Menu.Item key="9">
                    <Icon type="check" />
                    Publicar en Trabajando.com
                  </Menu.Item>)
                  : null
              }
              {(props.businessPermissions.business_admin || props.businessPermissions.role?.can_share_jobapplications_with_candidates) &&
                <Menu.Item key="10">
                  <Icon type="global" />
                  Publicar en Redes Sociales
                </Menu.Item>
              }

              {(props.businessPermissions.business_admin || props.businessPermissions.role?.can_share_jobapplications_with_candidates) &&
                <Menu.Item key="11">
                  <Icon type="qrcode" />
                  Generar código QR
                </Menu.Item>
              }
              {
                !props.countCandidates && props.businessPermissions.business_admin || props.businessPermissions.role?.can_create_jobapplications ?
                  <Menu.Item key="8">
                    <Typography.Text type='danger'>
                      <Icon type="delete" />
                      Eliminar
                    </Typography.Text>
                  </Menu.Item> :
                  null
              }
            </Menu>
          }
        >
          <Tooltip placement="top" title='Más acciones'>
            <Button>
              <EllipsisIcon />
            </Button>
          </Tooltip>
        </Dropdown>
      </Button.Group>

      <LoadingModal
        loading={loadingExport || loading}
      />

      <ShareWithCollaborators
        visible={shareModal}
        name={props.jobAppName}
        collaborators={props.subordinates}
        closeModal={handleShareWithSubordinates}
        id={props.process.id}
      />

      <ProcessState
        visible={statusModal}
        name={props.jobAppName}
        status={props.status}
        closeModal={handleStatusChange}
        process={props.process}
        updateProcesses={props.updateProcesses}
      />

      <Modal
        visible={shareJobModal}
        onCancel={() => setShareJobModal(false)}
        cancelText="Cancelar"
        onOk={() => { setShareJobModal(false) }}
      >
        <ShareJob shareProcessUrl={shareUrl} />
      </Modal>

      <Modal
        visible={showQR}
        onCancel={() => setShowQR(false)}
        cancelText="Cancelar"
        onOk={() => setShowQR(false)}
      >
        <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'space-around' }}>
          <QRCode
            value={encodedShareUrl}
            size={256}
            imageSettings={{
              src: 'https://genoma-assets.s3.us-east-2.amazonaws.com/genomin.png',
              width: 60,
              height: 60,
            }}
          />
        </div>

      </Modal>

      {props.process.job_portals ?
        <LaborumJobForm
          shareProcessUrl={encodedShareUrl}
          publish={() => { }}
          jobApp={props.process}
          visible={trabajandoModal}
          closeForm={() => setTrabajandoModal(!trabajandoModal)}
          handleChange={updateJobApp}
        /> : null}
    </div>
  )
}

HoverActions.propTypes = {
  status: PropTypes.string,
  jobAppName: PropTypes.string,
  subordinates: PropTypes.array,
  loadTableData: PropTypes.func,
  id: PropTypes.string,
};

const EllipsisSvg = () => (
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="41.915px" height="41.916px" viewBox="0 0 41.915 41.916"
    xmlSpace="preserve">
    <g>
      <g>
        <path fill="#8b8b8b" d="M11.214,20.956c0,3.091-2.509,5.589-5.607,5.589C2.51,26.544,0,24.046,0,20.956c0-3.082,2.511-5.585,5.607-5.585
			C8.705,15.371,11.214,17.874,11.214,20.956z"/>
        <path fill="#8b8b8b" d="M26.564,20.956c0,3.091-2.509,5.589-5.606,5.589c-3.097,0-5.607-2.498-5.607-5.589c0-3.082,2.511-5.585,5.607-5.585
			C24.056,15.371,26.564,17.874,26.564,20.956z"/>
        <path fill="#8b8b8b" d="M41.915,20.956c0,3.091-2.509,5.589-5.607,5.589c-3.097,0-5.606-2.498-5.606-5.589c0-3.082,2.511-5.585,5.606-5.585
			C39.406,15.371,41.915,17.874,41.915,20.956z"/>
      </g>
    </g>
    <g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
  </svg>
);
const EllipsisIcon = props => <Icon component={EllipsisSvg} {...props} />;

const mapStateToProps = (state) => {
  return {
    business: state.auth.business,
    businessOwner: state.auth.businessOwner,
    businessPermissions: state.auth.businessPermissions,
    data: state.data.data,
    jobPortalIntegrations: state.auth.jobPortalIntegrations,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HoverActions);
