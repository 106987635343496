import React from 'react';
import { Typography } from 'antd';

const { Title } = Typography;

const Error = () => (
  <div className='pj__job-form__done'>
    <span>
      <Title level={4}>Algo salió mal</Title>
      <Typography>El empleo no se ha publicado en Trabajando.com</Typography>
      <Typography>contacta a la administración de la plataforma para orientación</Typography>
    </span>
  </div>
)

export default Error;