import {
    GAME_DATA_SEND_BEGIN,
    GAME_DATA_SEND_FAILURE,
    GAME_DATA_SEND_SUCCESS
} from '../constants';
import { LOCATION_CHANGE } from 'react-router-redux';

const initialState = {
    gameOutcomeResponse: null,
    isSendingGameOutcome: false,
    gameOutcomeRecieved: false,
    gameOutcomeError: null
};

export default function gamesReducer(state = initialState, action) {
    switch (action.type) {
        case GAME_DATA_SEND_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return Object.assign({}, state, {
                isSendingGameOutcome: true,
                gameOutcomeRecieved: false,
                gameOutcomeError: null
            });

        case GAME_DATA_SEND_SUCCESS:
            // All done: set loading "false".
            // Also, replace the items with the ones from the server
            return Object.assign({}, state, {
                isSendingGameOutcome: false,
                gameOutcomeRecieved: true,
                gameOutcomeResponse: action.payload.gameOutcomeResponse
            });

        case GAME_DATA_SEND_FAILURE:
            // The request failed, but it did stop, so set loading to "false".
            // Save the error, and we can display it somewhere
            // Since it failed, we don't have items to display anymore, so set it empty.
            // This is up to you and your app though: maybe you want to keep the items
            // around! Do whatever seems right.
            return Object.assign({}, state, {
                isSendingGameOutcome: false,
                gameOutcomeRecieved: false,
                gameOutcomeError: action.payload.gameOutcomeError,
                gameOutcomeResponse: []
            });
        case LOCATION_CHANGE:
            return Object.assign({}, state, {
                isSendingGameOutcome: false,
                gameOutcomeRecieved: false,
                gameOutcomeError: null,
                gameOutcomeResponse: null
            });

        default:
            return state;
    }
}