import React, { Component } from 'react';
import TraitList from "./TraitWeight/TraitList";
import { Typography, Collapse, Button, Switch } from 'antd';
import './Configuration.scss';

const { Title, Text } = Typography;
const { Panel } = Collapse;

class Configuration extends Component {

  state = {
    activePanels: [],
    isChecked: false
  }

  componentDidMount() {
    const { personalityTraits, reasoningTraits, cognitiveTraits } = this.props.traits;

    this.setState(() => {
      let panels = []
      reasoningTraits.length && panels.push('1')
      personalityTraits.length && panels.push('2')
      cognitiveTraits.length && panels.push('3')
      
      return { 
        activePanels: panels 
      }
   });
  }

  onChangeCollapse = (activeKeys) => {
    this.setState({ activePanels: activeKeys })
  }

  onChangeSwitch = (value) => {
    this.setState({ isChecked: value })
  }
  render() {
    const { personalityTraits, reasoningTraits, cognitiveTraits } = this.props.traits;
    const { activePanels, isChecked } = this.state;
    return (
      <div className='ig-config'>
        <div className='ig-config__switch'>
          <Switch size='small' onChange={this.onChangeSwitch} />
          <Text strong>Mostrar promedio y distribución de candidatos</Text> <br />
          <Text type='secondary'>Con este gráfico podrás visualizar una distribución representativa de cada rasgo para identificar como se comporta la población. Podrás ver los promedios y los percentiles para compararlo con el resultado de tus candidatos.</Text>
        </div>

        <Collapse activeKey={activePanels} onChange={this.onChangeCollapse}>
          <Panel header={<Title level={4}>Rasgos de Razonamiento</Title>} key="1">
            <div className='panel-content'>
              <TraitList traits={reasoningTraits} showDistribution={isChecked} />
            </div>
            <Button className='close-panel' icon='up' block onClick={() => this.onChangeCollapse(activePanels.filter((e) => e !== '1'))} />
          </Panel>

          <Panel header={<Title level={4}>Rasgos de Personalidad</Title>} key="2">
            <div className='panel-content'>
              <TraitList traits={personalityTraits} showDistribution={isChecked} />
            </div>
            <Button className='close-panel' icon='up' block onClick={() => this.onChangeCollapse(activePanels.filter((e) => e !== '2'))} />
          </Panel>

          <Panel header={<Title level={4}>Rasgos Cognitivos y Emocionales</Title>} key="3">
            <div className='panel-content'>
              <TraitList traits={cognitiveTraits} showDistribution={isChecked} />
            </div>
            <Button className='close-panel' icon='up' block onClick={() => this.onChangeCollapse(activePanels.filter((e) => e !== '3'))} />
          </Panel>
        </Collapse>
      </div>
    )
  }
}

export default Configuration;
