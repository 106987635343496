import React, { useEffect, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import * as Sentry from '@sentry/browser';

import * as actionCreators from '../../../actions/data';
import * as actions from '../../../actions/auth';

import { BusinessUserNotificationItem } from '../NotificationItems/BusinessUser';
import { getNotificationBusinessUser} from '../NotificationItems/utils';
import { MsgPersonalUser } from '../NotificationItems/PersonalUser';
import i18n from '../../../i18n';

import moment from 'moment'

import {
  Card,
  Col,
  Collapse,
  DatePicker,
  Row,
  Button,
  Icon,
  List,
  Pagination,
  Typography,
  Tabs,
  Select,
  Skeleton,
  Switch,
  Empty,
} from 'antd';
const { TabPane } = Tabs;

import '../notification-style.scss';

const { Title } = Typography;
const { Option } = Select;

const pageSize = 20;

function NotificationHistoryView(props) {
  const [loading, setLoading] = useState(false);
  const [jobapps, setJobapps] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [filterJobapp, setFilterJobApp] = useState(undefined);
  const [filterDate, setFilterDate] = useState(null);
  const [notificationStatus, setNotificationStatus] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNotifications, setTotalNotifications] = useState(0);


  const fetchNotifications = async (queryString = '') => {
    //queryString ='date_created=17-08-2022'
    setLoading(true);
    const url = `/api/v1/notifications/?${queryString}`;
    const response = await props.actions.fetchAndReturn(props.token, url);
    const notifications = response.body ? response.body.results : [];
    setNotifications(notifications);
    const total = response.body ? response.body.count : 0;
    setTotalNotifications(total);
    setLoading(false);
  };

  const fetchJobApplications = async () => {
    let url;
    let mappedJobapps;
    if (props.userType === 'personal') {
      url = `/api/v1/gamesandtests/personalapplications/`;
      const response = await props.actions.fetchAndReturn(props.token, url);
      const jobs = response.body ? response.body.results : [];

      mappedJobapps = jobs.map(obj => ({ name: obj.job_application_name, id: obj.job_application_id }));
    }
    else {
      url = `/api/v1/gamesandtests/processlist/?format=json&page_size=10000`;
      const response = await props.actions.fetchAndReturn(props.token, url);
      const jobs = response.body ? response.body.results : [];

      mappedJobapps = jobs.map(obj => ({ name: obj.job_app.job_application, id: obj.job_app.id }));
    }
    mappedJobapps = [{ name: "Todos los procesos", id: undefined }, ...mappedJobapps]
    setJobapps(mappedJobapps);
  };

  useEffect(() => {
    fetchJobApplications();
    const qs = ['page=1', `page_size=${pageSize}`].join('&');
    fetchNotifications(qs);
  }, []);

  useEffect(() => {
    let qs = [`page=${currentPage}`, `page_size=${pageSize}`];
    if (filterJobapp) {
      const jobappQs = `jobapp=${filterJobapp}`
      qs.push(jobappQs)
    }
    if (filterDate && filterDate.length > 0) {
      const dateQs = `date_range=${filterDate[0].format('DD-MM-YYYY Z')},${filterDate[1].format('DD-MM-YYYY Z')}`
      qs.push(dateQs);
    }
    if (notificationStatus) {
      const statusQs = `status=${notificationStatus}`
      qs.push(statusQs)
    }
    qs = qs.join('&');
    fetchNotifications(qs);

  }, [filterDate, filterJobapp, notificationStatus, currentPage]);

  /**
   * This fn takes a Date in +0000 timezone, and shows the formatted date using the local timezone of the client eg: in AR 13pm is 12pm in Chile
   * @param {*} msgDate 
   * @returns 
   */
  const dateToCurrentLocale = (msgDate) => {
    const newDate = moment(msgDate, 'DD-MM-YYYY HH:mm:ss Zz').local().format('DD/MM/YYYY - hh:mm A');
    return newDate;
  }

  const getNotificationPersonalUser = (notification) => {
    switch (notification.notification_type) {
      case 'MSG':
        return (
          <MsgPersonalUser
            key={notification.id}
            notification={notification}
            date={dateToCurrentLocale(notification.date_created)}
            callback={() => updateNotification(notification.id)}
          />
        );
    }
  }

  const getBusinessUserNotifications = () => {
    const notificationCounter = {};
    notifications.forEach((notification, idx) => {
      const { id, job_application, notification_type, extra, status } = notification;
      let code;
      if (status === 'READ') {
        // NOTE: Queremos agrupar solo las no leídas
        code = id;
      } else if (notification_type === 'STGCMPLTD') {
        // En el caso de notificaciones por etapa, es necesario identificar también la etapa para la
        // agrupación
        code = `${job_application.id}-STGCMPLTD-${extra?.stage_name ?? ''}`;
      } else if (notification_type === 'INTSTATUS') {
        // En el caso de notificaciones por estado de entrevista, es necesario identificar también el
        // estado para la agrupación
        code = `${job_application.id}-INTSTATUS-${extra?.new_status ?? ''}`;
      } else if (notification_type === 'NEWJOBAPP') {
        // En el caso de notificaciones por nuevo proceso, no es necesario identificar el proceso para
        // la agrupación
        code = 'NEWJOBAPP';
      } else {
        code = `${job_application.id}-${notification_type}`;
      }
      notifications[idx] = { ...notification, code };
      if (notificationCounter[code]) {
        notificationCounter[code] += 1;
      } else {
        notificationCounter[code] = 1;
      }
    });

    const notificationItems = []; // Components to be rendered
    const codesRendered = []; // Codes already rendered
    notifications.forEach(notification => {
      const { id, code, date_created, status } = notification;
      if (codesRendered.includes(code)) {
        return;
      }
      const { notificationText, redirectPath } = getNotificationBusinessUser(notification, notificationCounter[code]);
      const mainNotification = (
        <BusinessUserNotificationItem
          key={id}
          date={dateToCurrentLocale(date_created)}
          callback={() => (
            notifications
              .filter(({ code: notification_code }) => notification_code === code)
              .map(({ id: notification_id }) => updateNotification(notification_id))
          )}
          redirectPath={redirectPath}
          text={notificationText}
          read={status === 'READ'} // Podemos asumir esto porque solo se agruparán las no leídas
        />
      );
      notificationItems.push(
        notificationCounter[code] > 1
          ? (
            <Collapse className='notifications-collapse' bordered={false}>
              <Collapse.Panel header={mainNotification}>
                <List
                  itemLayout="horizontal"
                  dataSource={notifications.filter(n => n.code === code)}
                  renderItem={item => {
                    const { notificationText: singleNotificationText, redirectPath: singleRedirectPath } = getNotificationBusinessUser(item);
                    return (
                      <List.Item>
                        <List.Item.Meta
                          title={(
                            <BusinessUserNotificationItem
                              key={item.id}
                              date={dateToCurrentLocale(item.date_created)}
                              callback={() => updateNotification(item.id)}
                              redirectPath={singleRedirectPath}
                              text={singleNotificationText}
                              read={item.status === 'READ'}
                            />
                          )}
                        />
                      </List.Item>
                    )
                  }}
                />
              </Collapse.Panel>
            </Collapse>
          ) : (mainNotification)
      );
      codesRendered.push(code);
    });

    return notificationItems;
  };

  const updateNotification = async (notificationId) => {
    const url = `/api/v1/notifications/${notificationId}/`;
    const body = { status: 'READ' };
    const response = await props.actions.fetchAndReturn(props.token, url, 'PATCH', body);
    if (response.status < 400) {
      closePopover();
      props.authActions.updateNotificationCount(oldState => oldState - 1);
    };
  }

  const markAllAsRead = () => {
    const url = `/api/v2/messaging/notificationsupdate/`;
    props.actions.fetchAndReturn(props.token, url, 'PATCH')
      .then((response) => {
        if (response.status < 400) {
          props.authActions.updateNotificationCount(0);
          setNotifications(
            (prevState) => prevState.map((notification) => ({ ...notification, status: 'READ' }))
          );
        }
      })
      .catch((error) => Sentry.captureException(error));
  };

  const notificationsDataSource = useMemo(() => (
    props.userType === 'personal' ? notifications : getBusinessUserNotifications()
  ), [props.userType, notifications]);

  return (
    <div className='account-config-container'>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Icon
          type="arrow-left"
          style={{ display: 'flex', marginBottom: '0.5em', marginRight: 10 }}
          onClick={() => props.dispatch(push('/'))}
        />
        <Title level={2}>{i18n.t('commons__notifications')}</Title>
      </div>
      <Row type='flex' justify='end' align="middle">
        <Switch
          checked={notificationStatus === 'CREATED' ? false : true}
          onChange={() => notificationStatus === 'CREATED' ? setNotificationStatus(null) : setNotificationStatus('CREATED')}
          checkedChildren={i18n.t('notifications__view_all', { ns: 'notifications' })}
          unCheckedChildren={i18n.t('notifications__view_new', { ns: 'notifications' })}
          style={{ marginLeft: 15, marginBottom: 5 }}
        />
        <Button
          disabled={notifications.filter(({ status }) => status !== 'READ').length === 0}
          style={{ marginLeft: 15, marginBottom: 5 }}
          onClick={(event) => {
            markAllAsRead();
            event.currentTarget.blur();
          }}
        >
          {i18n.t('notifications__all_read', { ns: 'notifications' })}
        </Button>
        <Button
          style={{ marginLeft: 15, marginBottom: 5 }}
          icon="setting"
          onClick={() => props.dispatch(push('/account/settings/notifications'))}
        >
          {i18n.t('commons__configuration')}
        </Button>
      </Row>
      <Tabs
        defaultActiveKey="0"
      >
        <TabPane tab="Procesos" key="0">
          <Card style={{ border: 'solid 1px #D9D9D9' }}>
            <Row type='flex' justify='start' gutter={[10,10]} style={{ marginBottom: 25 }}>
              <Col>
                <Select
                  showSearch
                  allowClear
                  style={{ minWidth: 200, width: '20vw' }}
                  placeholder={i18n.t('commons__select_process')}
                  optionFilterProp="children"
                  onChange={val => setFilterJobApp(val)}
                  value={filterJobapp}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {[...jobapps]
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((obj, idx) => (
                      <Option key={idx} value={obj.id}>
                        {obj.name}
                      </Option>
                    ))}
                </Select>
              </Col>
              <Col>
                <DatePicker.RangePicker
                  placeholder={[i18n.t('commons__date'), i18n.t('commons__date')]}
                  onChange={val => setFilterDate(val)}
                  value={filterDate}
                  style={{ minWidth: 200 }}
                  format={'DD-MM-YYYY'}
                />
              </Col>
            </Row>
            {loading
              ? <Skeleton />
              : (
                notificationsDataSource.length === 0
                  ? (
                    <Empty
                      description={i18n.t('notifications__no_notifications_yet', { ns: 'notifications' })}
                      imageStyle={{ marginTop: 25 }}
                    />
                  ) : (
                    <List
                      itemLayout="horizontal"
                      dataSource={notificationsDataSource}
                      renderItem={item => (
                        <List.Item>
                          <List.Item.Meta
                            title={props.userType === 'personal'
                              ? getNotificationPersonalUser(item)
                              : item}
                          />
                        </List.Item>
                      )}
                    />
                  )
              )}
            <Row type='flex' justify='end'>
              <Pagination
                current={currentPage}
                total={totalNotifications}
                pageSize={pageSize}
                onChange={(page, pageSize) => setCurrentPage(page)}
              />
            </Row>
          </Card>
        </TabPane>
      </Tabs>
    </div>
  )
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userId: state.auth.userID,
    userType: state.auth.userType,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    actionsAuth: bindActionCreators(actions, dispatch),
    dispatch,

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationHistoryView);