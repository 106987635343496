import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  Input,
  Checkbox,
  DatePicker,
  Typography,
} from 'antd';
import moment from 'moment';
import i18n from '../../../../i18n';
const InputGroup = Input.Group;

const initialState = {
  actualJob: '',
  position: '',
  company: '',
  country: '',
  month_start: '',
  year_start: '',
  month_end: '',
  year_end: '',
  experience_index: -1,
};

function EditExperienceForm({
  openModal,
  setOpenModal,
  form,
  onAddExperience,
  token,
  onEditExperience,
  experienceToEdit,
}) {
  const { getFieldDecorator } = form;
  const [state, setState] = useState(initialState);
  const [dateError, setDateError] = useState(null);

  const handleChangeActualJob = (event) => {
    if (event.target.checked) {
      setState({
        ...state,
        actualJob: event.target.checked,
        month_end: null,
        year_end: null,
      });
    } else {
      setState({ ...state, actualJob: event.target.checked });
    }
  };

  const disabledStartDate = (endValue) => {
    const startValue = new Date(state.year_start, state.month_start);
    if (!startValue || !endValue) {
      return false;
    }
    return startValue > endValue;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form
      .validateFields()
      .then((value) => {
        if (state.month_start === '' || state.year_start === ''){
          setDateError(i18n.t('form__date__error'))
          throw "Invalid date";
        }
        if (onAddExperience && state.experience_index < 0) {
          if (state.actualJob) {
            state.month_end = '';
            state.year_end = '';
          }
          onAddExperience(state, token);
        }
        else if (onEditExperience){
          onEditExperience(state, experienceToEdit.id);
        }
      })
      .then(() => {
        setOpenModal(false);
      })
      .catch((error) => {
        //console.error('Ocurrió un error', error);
      });
  };

  useEffect(() => {
    if (!!experienceToEdit && onEditExperience) {
      const {
        position,
        company,
        country,
        month_start,
        month_end,
        year_start,
        year_end,
      } = experienceToEdit;
      setState({
        ...state,
        actualJob: month_end === null || month_end === '' ? true : false,
        position,
        company,
        country,
        month_start,
        month_end: month_end === null ? '' : month_end,
        year_start,
        year_end: year_end === null ? '' : year_end,
      });
      form.setFieldsValue({
        position,
        company,
        country,
      });
    }
    else if (onAddExperience) {
      setState(initialState);
    }
  }, [experienceToEdit, onEditExperience, onAddExperience]);



  return (
    <Modal
      visible={openModal}
      className='experienceModal'
      footer={false}
      centered
      destroyOnClose={true}
      closable
      onCancel={() => setOpenModal(false)}
    >
      <Form onSubmit={handleSubmit}>
        <Row gutter={10}>
          <Col xs={24} className='header'>
            <Typography.Title level={2}>{i18n.t('form__experience')}</Typography.Title>
          </Col>

          <Col xs={24}>
            <Form.Item label={i18n.t('form__position')}>
              {getFieldDecorator('position', {
                rules: [
                  { required: true, message: i18n.t('form__experience__modal__error__position') },
                ],
                onChange: (e) =>
                  setState({ ...state, position: e.target.value }),
              })(<Input placeholder={i18n.t('form__experience__modal__example__position')} />)}
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item label={i18n.t('commons__company')}>
              {getFieldDecorator('company', {
                rules: [
                  { required: true, message: i18n.t('form__experience__modal__error__company') },
                ],
                onChange: (e) =>
                  setState({ ...state, company: e.target.value }),
              })(<Input placeholder={i18n.t('form__experience__modal__example__company')} />)}
            </Form.Item>
          </Col>

          <Col xs={24}>
            {/* NOTE: No debería ser un select? */}
            <Form.Item label={i18n.t('commons__country')}>
              {getFieldDecorator('country', {
                rules: [
                  { required: true, message: i18n.t('form__experience__modal__error__country') },
                ],
                onChange: (e) =>
                  setState({ ...state, country: e.target.value }),
              })(<Input placeholder={i18n.t('form__experience__modal__example__country')} />)}
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item>
              {getFieldDecorator('actualJob', {
                rules: [
                  { required: false, message: i18n.t('form__exp__career__error')},
                ],
                onChange: handleChangeActualJob,
                valuePropName: "checked",
                initialValue: state.actualJob ? true : false,
              })(
                <Checkbox checked={state.actualJob}>
                  {i18n.t('form__option')}
                </Checkbox>
              )}
            </Form.Item>
          </Col>

          <Col xs={24} md={12} className='date-fields'>
            <div className='ant-col ant-form-item-label'>
              <label className='ant-form-item-required'>{i18n.t('form__start__day')}</label>
            </div>
            <InputGroup compact>
              <DatePicker.MonthPicker
                locale={moment.locale(i18n.language.split('-')[0])}
                value={
                  state.month_start !== ''
                    ? moment(
                        `${parseInt(state.month_start) + 1}-${
                          state.year_start
                        }`,
                        'MM-YYYY'
                      )
                    : null
                }
                placeholder={i18n.t('form__start__day')}
                format='MM-YYYY'
                onChange={(date) => {
                  setDateError(null);
                  setState({
                    ...state,
                    year_start: date.year(),
                    month_start: date.month(),
                  });
                }
                }
                picker='month'
              />
            </InputGroup>
            {dateError && <Typography.Text type="danger">{dateError}</Typography.Text>}
          </Col>

          {!state.actualJob && (
            <React.Fragment>
              <Col xs={24} md={12} className='date-fields'>
                <div className='ant-col ant-form-item-label'>
                  <label className='ant-form-item-required'>
                    {i18n.t('form__end__day')}
                  </label>
                </div>
                <InputGroup compact>
                  <DatePicker.MonthPicker
                    locale={moment.locale(i18n.language.split('-')[0])}
                    disabled={ state.month_start === '' || state.year_start === '' ? true : false}
                    value={
                      state.month_end && state.month_end !== '' 
                        ? moment(
                            `${parseInt(state.month_end) + 1}-${
                              state.year_end
                            }`,
                            'MM-YYYY'
                          )
                        : null
                    }
                    placeholder={i18n.t('form__end__day')}
                    format='MM-YYYY'
                    disabledDate={(endValue) => disabledStartDate(endValue)}
                    onChange={(date) => {
                      setDateError(null);
                      setState({
                        ...state,
                        year_end: date.year(),
                        month_end: date.month(),
                      });
                    }
                    }
                    picker='month'
                  />
                </InputGroup>
              </Col>
            </React.Fragment>
          )}

          <Col xs={24} className='footer'>
            <Form.Item>
              <Button
                onClick={() => setOpenModal(false)}
                type='default'
              >
                {i18n.t('commons__cancel')}
              </Button>
              <Button
                htmlType='submit'
                type='primary'
                style={{ marginLeft: '10px' }}
              >
                {i18n.t('commons__save')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

const EditExperienceModal = Form.create({ name: 'career-modal' })(
  EditExperienceForm
);
export default EditExperienceModal;
