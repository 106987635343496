import React from 'react';

import {
  Checkbox,
  Form,
  Row,
  Typography,
} from 'antd';

const hasCorret = (alternatives) => {
  let hasCorrect = false;
  alternatives.forEach(alt => {
    if (alt.correct) {
      hasCorrect = true;
    }
  })
  return hasCorrect;
}

const MultiSelection = props => {
  const { question, error, index, answer, alternatives, disabled, mandatory } = props;
  const hasCorrectAnswer = hasCorret(alternatives);
  let color = '#757892';

  if (hasCorrectAnswer && disabled) {
    alternatives.forEach((alternative, index) => {
      if (answer[index.toString()]) {
        color = alternative.correct ? '#757892' : 'red';
      }
    })
  }

  return (
    <div>
      <Form.Item
        name={"multiselection"}
        label={
          <Typography.Text style={{ whiteSpace: 'normal' }}>
            {mandatory && <Typography.Text style={{ color: 'red' }}>* </Typography.Text>}
            {question}
          </Typography.Text>
        }
        labelAlign="left"
      >
        <Checkbox.Group disabled={disabled}>
          {alternatives.map((alt, i) => {
            return (
              <Row key={i}>
                <Checkbox
                  value={i.toString()}
                  checked={answer && answer[i]}
                  onChange={event => props.handleMultiChangeV2(index, i, event)}
                >
                  {alt.text}
                </Checkbox>
              </Row>
            );
          })}
        </Checkbox.Group>
        {error && (
          <Typography style={{ color: 'red', marginTop: 3, fontSize: 12 }}>
            Tienes que responder esta pregunta para continuar.
          </Typography>)}
      </Form.Item>
    </div>
  );
}

export default MultiSelection;
