import React from 'react';
import { Tag, Typography } from 'antd';
import CustomDiscPlot from './PlotDisc';
import PropTypes from 'prop-types';

const { Text } = Typography;

export const DetailDisc = ({ scores }) => {
  const rawResults = scores.raw_result;
  const intensity = scores.intensity;
  if (scores.result.length !== 4 || rawResults === undefined)
    return;

  return (
    <>
      <div style={{ fontSize: '15px', marginBottom: 5 }}>
        <Text strong>Resultados por perfil</Text>
      </div>
      <div>
        <Tag
          style={{
            textAlign: 'center',
            lineHeight: '30px',
            width: 50,
            fontSize: 14,
          }}
          color='red'
        >
          D: {rawResults['d']}
        </Tag>
        <Tag
          style={{
            textAlign: 'center',
            lineHeight: '30px',
            width: 50,
            fontSize: 14,
          }}
          color='orange'
        >
          i: {rawResults['i']}
        </Tag>
        <Tag
          style={{
            textAlign: 'center',
            lineHeight: '30px',
            width: 50,
            fontSize: 14,
          }}
          color='green'
        >
          S: {rawResults['s']}
        </Tag>
        <Tag
          style={{
            textAlign: 'center',
            lineHeight: '30px',
            width: 50,
            fontSize: 14,
          }}
          color='blue'
        >
          C: {rawResults['c']}
        </Tag>
      </div>

      <div style={{ fontSize: '15px', marginBottom: 5, marginTop: 15 }}>
        <Text strong style={{ fontSize: '15px' }}>
          Patrón
        </Text>
      </div>
      <div>
        <Tag
          style={{
            textAlign: 'center',
            lineHeight: '30px',
            width: 50,
            fontSize: 14,
            marginRight: 0,
            borderRadius: 1,
          }}
          color='red'
        >
          {scores.result[0]}
        </Tag>
        <Tag
          style={{
            textAlign: 'center',
            lineHeight: '30px',
            width: 50,
            fontSize: 14,
            marginRight: 0,
            borderRadius: 1,
          }}
          color='orange'
        >
          {scores.result[1]}
        </Tag>
        <Tag
          style={{
            textAlign: 'center',
            lineHeight: '30px',
            width: 50,
            fontSize: 14,
            marginRight: 0,
            borderRadius: 1,
          }}
          color='green'
        >
          {scores.result[2]}
        </Tag>
        <Tag
          style={{
            textAlign: 'center',
            lineHeight: '30px',
            width: 50,
            fontSize: 14,
            marginRight: 0,
            borderRadius: 1,
          }}
          color='blue'
        >
          {scores.result[3]}
        </Tag>
      </div>
      {
        intensity
          ?
          <div style={{ fontSize: '15px', marginBottom: 5, marginTop: 15 }}>
            <Text strong style={{ fontSize: '15px' }}>
              Gráfico intensidad
            </Text>
            <CustomDiscPlot intensity={intensity} />
          </div>
          :
          null
      }
    </>
  )
};

DetailDisc.propTypes = {
  scores : PropTypes.shape({
    raw_result: PropTypes.shape({
      d: PropTypes.number,
      i: PropTypes.number,
      s: PropTypes.number,
      c: PropTypes.number
    }),
    result: PropTypes.arrayOf(Number),
    intensity: PropTypes.arrayOf(Number)
})
}
export default DetailDisc;
