import React from 'react';
// styles are stored in src/components/tour/styles.scss

import {
  Col,
  Icon,
  Row,
  Typography
} from 'antd';


const assetsUrl = 'https://genoma-assets.s3.us-east-2.amazonaws.com/'

export const tutorialModalContentTitle = (
  <Typography.Title level={4}>
    Nueva sección de permisos para colaboradores.
  </Typography.Title>
);

export const tutorialModalContent = (
  <>
    <div className="tour-modal-check-list">
      <Row type="flex" justify='center'>
        <img
          style={{ width: '50%' }}
          src={`${assetsUrl}dropdown-ss.png`}
        />
      </Row>
      <Row
        type="flex"
        justify="space-between"
        align="middle"
      >
        <Col span={1} style={{ marginRight: "5px" }}>
          <Icon
            type="check"
            className="check-icon"
            style={{ fontSize: "18px" }}
          />
        </Col>
        <Col>
          Desde esta sección podrás administrar los permisos de los colaboradores 
          de tu empresa. Dependiendo de sus permisos, los colaboradores podrán 
          gestionar la marca empleadora y/o invitar a otros colaboradores.
        </Col>
      </Row>
    </div>

    <div style={{ marginTop: 20 }}>
      <Typography.Text strong>
        ¡Tu opinión es importante!
      </Typography.Text>
    </div>

    <div className="tour-modal-check-list">
      Estaremos muy atento a tus recomendaciones y feedback para seguir mejorando nuestra plataforma y ofrecerte un servicio óptimo.
    </div>
    <div className="tour-modal-check-list">
      Puedes dejarnos tus comentarios y opiniones al correo <a>customer@genomawork.com</a>
    </div>
  </>
)