import React from 'react';

import { Layout, Row, Col, Typography, Input, Button } from 'antd';
const { Title, Text } = Typography;

const CardList = ({ list, fnDelete, fncopy, fnEdit, specificId }) => {
  return (
    <Layout style={{ background: 'white' }}>
      {list.length > 0 && (
        list.map((item, index) => (
          <Row key={index} className='formNewQuestions' >
            <Col style={{ margin: '0 40px 0 0' }}>
              <Title level={4} style={{ color: '#5E5E5E' }} >Habilidad {index + 1}</Title>
              {/* <Input value={item.text} disabled size='large' style={{ width: '558px' }} /> */}
              <Row style={{ border: '1px solid #D9D9D9', padding: '5px', cursor: 'not-allowed' }}>
                <Text>{item.text}</Text>
              </Row>
            </Col>
            <Col>
              <Title level={4} style={{ color: '#5E5E5E' }}>Acciónes</Title>
              <Button icon='edit' style={{ border: 'none', color: '#A4A4A4', boxShadow: 'none' }} onClick={() => fnEdit(index, item.question_number)} />
              <Button icon='delete' style={{ border: 'none', color: '#A4A4A4', boxShadow: 'none' }} onClick={() => fnDelete({ state: true, idQuestion: item.question_number })} />
              <Button icon='copy' style={{ border: 'none', color: '#A4A4A4', boxShadow: 'none' }} onClick={() => fncopy(index)} />
            </Col>
          </Row>
        ))
      )}
    </Layout>
  );
}

export default CardList;