import React, { useMemo, useState } from 'react';
import { Row, Col, Typography, Avatar } from 'antd';
import propTypes from 'prop-types';

const backgroundPalette = [
  '#D3ADF7',
  '#91D5FF',
  '#87E8DE',
  '#FFD591',
  '#ADC6FF',
];
const colorPalleteAvatars = [
  '#531DAB',
  '#096DD9',
  '#08979C',
  '#D46B08',
  '#1D39C4',
];

/**
 * @param {Array<Object>} candidates
 * @param {string} type
 */

const HeatMapMobile = ({ candidates, type }) => {
  const [validData, setValidData] = useState(false);
  
  const allTraits = useMemo(() => {
    const tmpTraits = []
    candidates.forEach((candidate) => {
      if (candidate[`${type}_scores`].length > 0) {
        setValidData(true);
      }
      candidate[`${type}_scores`].forEach((t) => {
        // Hacer un set de las combinaciones de traits 
        // y activities para luego mapear segun esto
        if (!tmpTraits.find(x => x.code === t.code && x.game_code === t.game_code)) {
          tmpTraits.push({ game_code: t.game_code, trait: t.trait, code: t.code })
        }
      });
    })
    return tmpTraits
  }
  , [candidates]) 

  return (
    <Row className='heatmap-mobile-container'>
      {type === 'cognitive' && validData ? (
        <Row type='flex' className='heatmap-mobile-container__heatmap-mobile-colors'>
          <Col className='heatmap-mobile-container__color-info'>
            <div
              style={{ width: '48px', height: '25px', background: '#FFADD2' }}
            />
            <Typography.Text>Bajo</Typography.Text>
          </Col>
          <Col className='color-info'>
            <div
              style={{ width: '48px', height: '25px', background: '#F759AB' }}
            />
            <Typography.Text>Medio</Typography.Text>
          </Col>
          <Col className='color-info'>
            <div
              style={{ width: '48px', height: '25px', background: '#C41D7F' }}
            />
            <Typography.Text>Alto</Typography.Text>
          </Col>
        </Row>
      ) : type === 'reasoning' && validData ? (
        <Row type='flex' className='heatmap-mobile-container__heatmap-mobile-colors'>
          <Col className='heatmap-mobile-container__color-info'>
            <div
              style={{ width: '48px', height: '25px', background: '#FFADD2' }}
            />
            <Typography.Text>Bajo</Typography.Text>
          </Col>
          <Col className='color-info'>
            <div
              style={{ width: '48px', height: '25px', background: '#F759AB' }}
            />
            <Typography.Text>Medio</Typography.Text>
          </Col>
          <Col className='color-info'>
            <div
              style={{ width: '48px', height: '25px', background: '#C41D7F' }}
            />
            <Typography.Text>Alto</Typography.Text>
          </Col>
        </Row>
      ) : null}
      {validData ? (
        <Row
          type='flex'
          className='heatmap-mobile-container__heatmap-mobile-questions'
          style={
            allTraits.length >= 7
              ? { minWidth: '600px' }
              : allTraits.length >= 10
              ? { minWidth: '700px' }
              : allTraits.length >= 12
              ? { minWidth: '800px' }
              : { minWidth: '600px' }
          }
        >
          <Col className='heatmap-mobile-container__percent'>
            <table>
              <thead>
                <tr>
                  {candidates.map(
                    (candidate, index) =>
                      candidate[`${type}_scores`].length > 0 && (
                        <th key={index}>
                          <Avatar
                            size={32}
                            style={{
                              background: backgroundPalette[index],
                              color: colorPalleteAvatars[index],
                              fontSize: '14px',
                            }}
                          >
                            {candidate.name[0].toUpperCase()}
                            {candidate.surname[0].toUpperCase()}
                          </Avatar>
                        </th>
                      )
                  )}
                </tr>
              </thead>
              <tbody>
                {candidates.map((candidate, index) => (
                  <tr
                    style={{ display: 'flex', flexDirection: 'column' }}
                    key={index}
                  >
                    {candidate[`${type}_scores`]
                      ? allTraits.map((trait, index) => {
                        const candidateScore = candidate[`${type}_scores`].find(cScore =>cScore.code === trait.code && cScore.game_code === trait.game_code)?.score
                        return candidateScore >= 90 ? (
                          <td key={index} style={{ background: '#C41D7F' }}>
                            {candidateScore}
                          </td>
                        ) : candidateScore >= 60 ? (
                          <td key={index} style={{ background: '#F759AB' }}>
                            {candidateScore}
                          </td>
                        ) : candidateScore ? (
                          <td key={index} style={{ background: '#FFADD2' }}>
                            {candidateScore}
                          </td>
                        ) : 
                          <td key={index} style={{ background: '#000' }}>

                          </td>
                      }
                        )
                      : null}
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
          <Col className='heatmap-mobile-container__questions'>
            <table>
              <tbody>
                {candidates[0]
                  ? allTraits.map((question, index) => (
                      <tr key={index}>
                        <td>
                          <Typography.Text>{question.trait}</Typography.Text>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </Col>
        </Row>
      ) : (
        <Row
          type='flex'
          justify='center'
          align='middle'
          style={{ height: '100%' }}
        >
          <Typography.Title style={{ padding: '0', margin: '0' }}>
            Sin datos
          </Typography.Title>
        </Row>
      )}
    </Row>
  );
};

HeatMapMobile.propTypes = {
  candidates: propTypes.array.isRequired,
  type: propTypes.string.isRequired,
};

export default HeatMapMobile;
