import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import i18n from '../../i18n';

import * as actionCreators from '../../actions/data';
import * as actions from '../../actions/auth';

import { 
  Button, Col, Icon, Input, Form, message, Row, Typography 
} from 'antd';

const { Password } = Input;
const { Title, Paragraph } = Typography;

function NameChangeView(props) {
  const [loading, setLoading] = useState();
  const { getFieldDecorator, validateFields } = props.form;

  const handleSubmit = (e) => {
    e.preventDefault();

    validateFields((errors, values) => {
      if (!errors) {
        setLoading(true);
        const token = props.token;
        const  userId  = props.userID;
        let url = `/api/v1/accounts/businessusers/${userId}/`;
        const body = {
          'name': values.newName,
          'old_password': values.password
        };
        props.actions.fetchAndReturn(token, url, 'PATCH', body).then((response) => {
          if (response.status >= 400) {
            if (response.message) {
              if (response.message == 'Invalid credentials.') {
                message.error({
                  duration: 5,
                  content: i18n.t('change__name__invalid__password'),
                });
              }
            } else {
              message.error({
                duration: 5,
                content: i18n.t('change__name__errors'),
              });
            }
            setLoading(false);
          } else {
              props.authActions.updateAuthDataAction({ keyName: 'name', value: values.newName })
              props.dispatch(push('/account/settings'));
            };
          }
        );
      }
    })
  }
  return (
    <> 
      <div className='account-config-container'>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Icon type="arrow-left" style={{ display: 'flex', marginBottom: '0.5em', marginRight: 10 }} onClick={() => props.dispatch(push('/account/settings'))}/>
          <Title level={2}>{i18n.t('change__name__title')}</Title>
        </div>
        <Paragraph style={{fontSize:16}}>
          {i18n.t('my__account__description')}
        </Paragraph>

        <div className="account-base-card ">
          <Row type='flex' justify='start' align='top' gutter={[24,24]}>
            <Col xs={24} sm={20} md={12}>
              <Form onSubmit={handleSubmit}>
                <Form.Item label={i18n.t('change__name__new__name')}>
                  {getFieldDecorator('newName', {
                    rules: [
                      {
                        required: true,
                        message: i18n.t('change__name__name__error'),
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
                <Form.Item label={i18n.t('commons__password')} hasFeedback>
                  {getFieldDecorator('password', {
                    rules: [
                      {
                        required: true,
                        message: i18n.t('change__name__password__error'),
                      }
                    ],
                  })(<Password />)}
                </Form.Item>
                <Row>
                  <Form.Item>
                    <Button 
                      onClick={() => props.dispatch(push('/account/settings'))}
                      style={{ marginRight: "1em" }}
                    >
                      {i18n.t('commons__cancel')}
                    </Button>
                    <Button type="primary" htmlType="submit" loading={loading}>
                      {i18n.t('change__name__title')}
                    </Button>
                  </Form.Item>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};


const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isAuthenticating: state.auth.isAuthenticating,
    token: state.auth.token,
    email: state.auth.userName,
    error: state.data.error,
    alerts: state.alerts,
    userID: state.auth.userID
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    actionsAuth: bindActionCreators(actions, dispatch),
    dispatch,

  };
};
const  NameForm = Form.create({ name: 'account-form' })(NameChangeView);

export default connect(mapStateToProps, mapDispatchToProps)(NameForm);