import React from 'react';
import { Row, Col, Typography, Icon, Tabs, Tooltip, Divider } from 'antd';

import chunk from 'lodash.chunk';

import moment from 'moment';

const { Text, Title } = Typography;

const Meetings = ({ form, interviewType, selectedCandidates }) => {
  const { getFieldValue } = form;
  const candidatesPerMeeting = getFieldValue('candidates_per_meeting');
  const isSingle = interviewType === 'single';
  const attendees = getFieldValue('attendees');
  const candidates = attendees?.candidates ?? [];
  const meetings = chunk(candidates, candidatesPerMeeting);

  const getHourInfo = (candidates, meetingNumber) => {
    const multiConfig = {};

    if (!isSingle) {
      const intervals = meetingNumber * getFieldValue('interval_duration');
      const interviewsTime =
        meetingNumber * getFieldValue('interview_duration');

      multiConfig.start = moment(getFieldValue('init_hour'), 'HH:mm')
        .add(intervals, 'minutes')
        .add(interviewsTime, 'minutes')
        .format('HH:mm');

      multiConfig.end = moment(multiConfig.start, 'HH:mm')
        .add(getFieldValue('interview_duration'), 'minutes')
        .format('HH:mm');
    }

    return (
      <Row type="flex" style={{ gap: '0.5rem' }}>
        <Text>{multiConfig.start}</Text>-<Text>{multiConfig.end}</Text>-
        <Text>{getFieldValue('date')?.format('D [de] MMMM, YYYY')}</Text>
      </Row>
    );
  };

  return (
    <div className="meetings-container">
      <Title level={4}>
        Reuniones {meetings.length ? `(${meetings.length})` : null}
      </Title>
      {candidates.length && candidatesPerMeeting ? (
        <div className="meetings-list">
          {meetings.map((candidates, i) => {
            return (
              <>
                <div className="meetings-list__meeting">
                  <div>{getHourInfo(candidates, i)}</div>
                  <div className="meetings-list__meeting__candidates">
                    {candidates.map((candidate) => {
                      const [first, second] = (
                        candidate.full_name ?? candidate.name
                      ).split(' ');

                      return (
                        <Tooltip
                          title={
                            <Row
                              type="flex"
                              style={{ flexDirection: 'column' }}
                            >
                              <span>
                                <strong>
                                  {candidate.full_name ?? candidate.name}
                                </strong>
                              </span>
                              <span>{candidate.email}</span>
                            </Row>
                          }
                        >
                          <div className="schedule-attendees-form__attendee__avatar">
                            {first[0].toUpperCase()}
                            {second && second[0].toUpperCase()}
                          </div>
                        </Tooltip>
                      );
                    })}
                  </div>
                </div>
                {i < meetings.length - 1 &&
                getFieldValue('interval_duration') ? (
                  <div className="meeting-interval">
                    <Divider />
                    <div style={{ color: '#A4A4A4' }}>
                      intervalo de {getFieldValue('interval_duration')} minutos
                    </div>
                    <Divider />
                  </div>
                ) : null}
              </>
            );
          })}
        </div>
      ) : (
        <Text>Debe introducir la cantidad de candidatos por reunión.</Text>
      )}
    </div>
  );
};

export default Meetings;
