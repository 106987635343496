import { Icon } from 'antd';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useIsOverflow } from '../../../../hooks/useOverflow';

const StageButtonComponent = ({
  name,
  code,
  candidates,
  type,
  clickActiveFn,
}) => {
  return (
    <button
      key={`stage-${code}`}
      className={`button ${type}`}
      onClick={clickActiveFn}
    >
      <div className="candidate-list-container__stages-container--tab-title">
        {name}
        <div
          className={`candidate-list-container__stages-container--total-${type}`}
        >
          {candidates[code]?.count || 0}
        </div>
      </div>
    </button>
  );
};

const StagesComponents = ({
  candidates,
  jobStages,
  setStagesOnFocus,
  setPage,
  setSelectedRowKeys,
}) => {
  const [classes, setClasses] = useState({ active: 0 });

  const containerRef = useRef();
  const isOverflow = useIsOverflow(containerRef);

  const clickActiveFn = (index, code) => {
    setClasses({ active: index });
    setStagesOnFocus(code);
    setSelectedRowKeys([]);
    setPage(1);
  };

  useEffect(() => {
    if (jobStages) {
      const doneIndex = jobStages.findIndex((stage) => stage.code === 'DONE');
      const nextIndex = doneIndex > 0 ? doneIndex : 0;

      clickActiveFn(nextIndex, jobStages[nextIndex].code);
    }
  }, [jobStages]);

  const scroll = (scrollOffset) => {
    containerRef.current.scrollLeft += scrollOffset;
  };

  // const [leftStages, selectedStage, rightStages] = useMemo(() => {
  //   if (!jobStages) {
  //     return [[], null, []];
  //   }
  //   const activeIndex = classes.active;

  //   const endIndex = jobStages.length;

  //   return [
  //     jobStages.slice(0, activeIndex),
  //     jobStages[classes.active],
  //     jobStages.slice(activeIndex + 1, endIndex),
  //   ];
  // }, [classes.active, jobStages]);

  // return (
  //   <div className="candidate-list-container__stages-container">
  //     <div className="candidate-list-container__stages-container-scroll-left">
  //       {leftStages?.map(({ name, code }, i) => (
  //         <StageButtonComponent
  //           key={`${code}-${i}`}
  //           name={name}
  //           code={code}
  //           type="inactive"
  //           candidates={candidates}
  //           clickActiveFn={() => clickActiveFn(i, code)}
  //         />
  //       ))}
  //     </div>
  //     <div className="candidate-list-container__stages-container-center">
  //       {selectedStage ? (
  //         <StageButtonComponent
  //           name={selectedStage.name}
  //           code={selectedStage.code}
  //           type="active"
  //           candidates={candidates}
  //           clickActiveFn={() =>
  //             clickActiveFn(classes.active, selectedStage.code)
  //           }
  //         />
  //       ) : null}
  //     </div>
  //     <div className="candidate-list-container__stages-container-scroll-right">
  //       {rightStages?.map(({ name, code }, i) => (
  //         <StageButtonComponent
  //           name={name}
  //           code={code}
  //           type="inactive"
  //           candidates={candidates}
  //           clickActiveFn={() => clickActiveFn(i + classes.active + 1, code)}
  //         />
  //       ))}
  //     </div>
  //   </div>
  // );
  return (
    <div className="new-stage-selector-container" ref={containerRef}>
      {isOverflow ? (
        <div
          className="new-stage-selector-container__nav-btn nav-btn-left"
          onClick={() => {
            scroll(-100);
          }}
        >
          <Icon type="left" />
        </div>
      ) : null}
      {jobStages?.map(({ name, code }, i) => (
        <div
          key={`stage-${code}`}
          onClick={() => clickActiveFn(i, code)}
          className={`new-stage-selector-container__selector ${
            i === classes.active ? 'category-selected' : ''
          }`}
        >
          <p>{name}</p>
          {/* <p>completado</p> */}
          <div className="new-stage-selector-container__selector__count">
            {candidates[code]?.count || 0}
          </div>
        </div>
      ))}
      {isOverflow ? (
        <div
          className="new-stage-selector-container__nav-btn nav-btn-right"
          onClick={() => {
            scroll(100);
          }}
        >
          <Icon type="right" />
        </div>
      ) : null}
    </div>
  );
};

export default StagesComponents;
