import React, { useState, useEffect } from 'react';
import { Button, Card, Checkbox, Col, Dropdown, Icon, InputNumber, Menu, Row, Select, Typography } from 'antd';

const { Option } = Select;

export const likerOptions = [
  { label: 'Bajo', value: 'Bajo' },
  { label: 'Medio', value: 'Medio' },
  { label: 'Alto', value: 'Alto' },
]

const resultCondition = ({ remove, conditions, setConditions, conditionOperator, setConditionOperator, conditionsAvailable, setConditionsAvailable, cutesAvailable, setCutesAvailable, isSecondary=false }) => {

  const handleCuteAdded = (cute) => {
    setConditions((oldState) => ([...oldState, {type: 'KT', cute_id: cute.id, cute, scores_labels: []}]))
    setCutesAvailable((oldState) => (oldState.filter((c) => c.id !== cute.id)))
  }
  const handleRemoveCuteCondition = (condition) => {
    setCutesAvailable((oldState) => ([...oldState, condition.cute]))
    setConditions((oldState) => (oldState.filter((c) => condition !== c)))
  }

  const handleConditionChange = (type, value) => {
    // Check value is numeric only
    if (isNaN(value)) {
      return;
    };
    setConditions((oldState) => ([
      ...oldState.filter((condition) => condition.type !== type),
      {...oldState.find((condition) => condition.type === type), value}
    ]))
  }

  const handleChangeFitType = (value) => {
    setConditions((oldState) => ([
      ...oldState.filter((condition) => condition.type !== 'FIT'), 
      {...oldState.find((condition) => condition.type === 'FIT'), filter: value}
    ]))
  }

  const cuteMenu = (
    <Menu>
      {conditions.filter(c =>  c.type === 'FIT').length === 0 ? <Menu.Item onClick={() => setConditions((oldState) => (
            [...oldState, 
            {
              type: 'FIT',
              filter: 'geq',
              value: 50
            }]
          ))}>
        <Typography.Text>Puntaje de Fit</Typography.Text>
      </Menu.Item> : null }
      {cutesAvailable?.map((evaluation,idx) => (
        <Menu.Item key={`cute_condition_option__${idx}`} onClick={() => handleCuteAdded(evaluation)}>
          <Typography.Text>{evaluation.candidate_path_title}</Typography.Text>
        </Menu.Item>
      ))}
    </Menu>
  )

  const handleOptionsChange = (checkedValues, cuteCondition) => {
    setConditions((oldState) => {
      const newState = structuredClone(oldState);
      const editedCuteCondition = newState.find((condition) => condition.cute_id ===  cuteCondition.cute_id)
      editedCuteCondition.scores_labels = checkedValues;
      return newState
    })
  }

  return (
    <Card
    className='trigger-card'
    >
      <Row type='flex' justify='center' align='middle'>
        <Col span={4}>
          <Icon type='schedule' style={{ fontSize: 60, color: '#A4A4A4' }} />
        </Col>
        <Col span={16}>
          <Row type='flex' justify='space-between' align='middle'>
            <Typography.Text>Condiciones</Typography.Text><br />
            {!isSecondary && 
              <Icon
                type='delete'
                onClick={() => {
                  setConditionOperator('NONE');
                  remove();
                }} 
                style={{ fontSize: '16px', color: 'red', padding: 10 }}
              />
            }
          </Row>
          {!isSecondary ? 
          <Select style={{ width: '100%' }} onChange={(value) => setConditionOperator(value)} value={conditionOperator}>
            <Option value='AND'>y cumplan con los siguientes resultados</Option>
            <Option value='OR'>y cumplan con al menos uno de los siguientes resultados</Option>
          </Select> : 
          <Select style={{ width: '100%' }} value={conditionOperator} onChange={(value) => setConditionOperator(value)} disabled>
            <Option value='OR'>y cumplan con los siguientes resultados</Option>
            <Option value='AND'>y cumplan con al menos uno de los siguientes resultados</Option>
          </Select>
          }
        </Col>
      </Row>
      <Row type='flex' style={{ marginTop: '1em' }}>
      {conditions.find(c => c.type === 'FIT') ?
        <Row style={{ width: '100%' }}>
          <Row type='flex' gutter={20} style={{ width: '100%' }}>
            <Col span={22} push={2}>
              <Typography.Text>
                Puntaje de Fit
              </Typography.Text>
            </Col>
            <Col span={2} pull={1}>
              {!isSecondary ? <Button icon='close-circle' onClick={() => setConditions((oldState) => oldState.filter((condition) => condition.type !== 'FIT'))} /> : null}
            </Col>
          </Row>
          <Row type='flex' gutter={20} style={{ width: '100%' }}>
            <Col span={8} push={2}>
            {!isSecondary ? 
              <Select style={{ width: '100%' }} value={conditions.find((condition) => condition.type === 'FIT')?.filter} onChange={handleChangeFitType}>
                <Option value='geq'>mayor o igual a </Option>
                <Option value='lt'>menor a</Option>
              </Select> : 
              <Select style={{ width: '100%' }} disabled value={conditions.find((condition) => condition.type = 'FIT').filter === 'geq' ? 'lt' : 'geq'}>
                <Option value='geq'>mayor o igual a</Option>
                <Option value='lt'>menor a</Option>
              </Select>
            }
            </Col>
            <Col span={8} push={2}>
            {!isSecondary ?
              <InputNumber 
              min={0} 
              max={100} 
              defaultValue={50} 
              onChange={(value) => handleConditionChange('FIT', value)} 
              value={conditions.find((c) => c.type === 'FIT').value}
              formatter={value => `${value}%`}
              parser={value => Number(value.replace('%', ''))}
              />
              : 
              <InputNumber 
              disabled 
              value={conditions.find((c) => c.type === 'FIT').value} 
              formatter={value => `${value}%`}
              parser={value => Number(value.replace('%', ''))}
              />
            }
            </Col>
          </Row>
        </Row> : null
      }
      </Row>
      {conditions.filter(c => c.type === 'KT').map((cuteCondition, index) => 
        <Row type='flex' key={`cute_liker__${index}`} style={{marginTop: '1em'}}>
          <Col span={22} push={2}>
            <Typography.Text>
              {cuteCondition.cute.candidate_path_title}
            </Typography.Text>
          </Col>
          <Col span={2} pull={1}>
            {!isSecondary && <Button icon='close-circle' onClick={() => handleRemoveCuteCondition(cuteCondition)} />}
          </Col>
          <Col span={24} push={2} style={{ marginTop: '1em'}}>
          {
            !isSecondary ?
              <Checkbox.Group 
              options={cuteCondition.cute.scores_config.segments?.map((segment) => ({label: segment.label, value: segment.label})) || likerOptions} 
              value={cuteCondition.scores_labels} 
              onChange={(checkedValues) => handleOptionsChange(checkedValues, cuteCondition)}
              /> :
              <Checkbox.Group 
                options={cuteCondition.cute.scores_config.segments?.map((segment) => ({label: segment.label, value: segment.label})) || likerOptions} 
                defaultValue={(cuteCondition.cute.scores_config.segments || likerOptions).filter((option) => !cuteCondition.scores_labels.includes(option.label)).map((option) => option.label)} 
                onChange={(checkedValues) => handleOptionsChange(checkedValues, cuteCondition)} disabled={true}
              />
          }
          </Col>
        </Row>
      )}
      {
        conditionOperator !== 'NONE' && !isSecondary && (cutesAvailable.length > 0 || !conditions.find(x => x.type === 'FIT') ) ?
        <Col span={22} push={2} style={{ marginTop: '1em'}}>
          <Dropdown overlay={cuteMenu}>
            <Button type='secondary' icon='plus'/>
          </Dropdown>
        </Col> : null
      }
      <Col span={20} push={2} style={{ backgroundColor: '#FFFBE6', border: '1px solid #FFE58F', padding: '9px 20px', marginTop: '2em'}}>
        <Typography.Text style={{ fontSize: '14px' }}>
          Los resultados de Preguntas Adicionales y Video Entrevista serán excluidos de las acciones automatizadas.
        </Typography.Text>
      </Col>
    </Card>
  )
}

export default resultCondition;