import React from 'react';

import { Row, Col, Typography, Icon, Tabs } from 'antd';

import { getGameImg } from '../helpers';

const { Text, Title } = Typography;

const Game = ({ game, setGame }) => {
  return (
    <div className="games-categories__game" onClick={() => setGame(game)}>
      <div className="games-categories__game__header">
        <img src={getGameImg(game)} alt="game icon" />
        <Text strong style={{ flexGrow: 1, fontSize: 16 }}>
          {game.activity.replace(/Mobile/g, '').trim()}
        </Text>
        <Text style={{ color: '#A4A4A4', fontSize: 12 }}>
          <Icon type="clock-circle" style={{ marginRight: '0.125rem' }} />
          {game.time_estimated}
          {game.time_estimated > 1 ? ' minutos' : ' minuto'}
        </Text>
      </div>
      <div>
        <Text>{game.description}</Text>
      </div>
    </div>
  );
};

export default Game;
