import i18n from '../../../i18n'


export const recommendedEvaluations = [
  {
    value: 'NOREC',
    text: i18n.t('profile__not_recommended', {ns: 'candidateProfile'}),
    icon: 'close-circle',
    color: '#FF4D4F',
    key: 'NOREC-false',
  },
  {
    value: 'OBJEC',
    text: i18n.t('profile__with_observations', {ns: 'candidateProfile'}),
    icon: 'info-circle',
    color: '#FAAD14',
    key: 'OBSER-false',
  },
  {
    value: 'RECOM',
    text: i18n.t('profile__recommended', {ns: 'candidateProfile'}),
    icon: 'check-circle',
    color: '#52C41A',
    key: 'RECOM-false',
  },
];

export const rateValues = [
  {
    background: '#FFFFFF',
    color: '#D9D9D9',
    value: 0,
    evaluated: false,
    key: '0-false',
    text: '',
  },
  {
    background: '#ff8f90',
    color: '#FF4D4F',
    value: 1,
    evaluated: true,
    key: '1-false',
    text:  i18n.t('profile__insufficient', {ns: 'candidateProfile'}),
    marginLeft: 110,
  },
  {
    background: '#fabfac',
    color: '#fc6d42',
    value: 2,
    evaluated: true,
    ' key': '2-false',
    text: i18n.t('profile__low', {ns: 'candidateProfile'}),
    marginLeft: 200,
  },
  {
    background: '#FFF1B8',
    color: '#FAAD14',
    value: 3,
    evaluated: true,
    key: '3-false',
    text: i18n.t('profile__middle', {ns: 'candidateProfile'}),
    marginLeft: 252,
  },
  {
    background: '#F4FFB8',
    color: '#BAE637',
    value: 4,
    evaluated: true,
    key: '4-false',
    text: i18n.t('profile__high', {ns: 'candidateProfile'}),
    marginLeft: 335,
  },
  {
    background: '#D9F7BE',
    color: '#52C41A',
    value: 5,
    evaluated: true,
    key: '5-false',
    text:  i18n.t('profile__outstanding', {ns: 'candidateProfile'}),
    marginLeft: 375,
  },
];


export const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];
