import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Divider } from 'antd';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as actionCreators from '../../actions/data';

import { useReactToPrint } from 'react-to-print';

import './styles.scss';

import FirstPage from './pages/FirstPage';
import Page from './pages/Page';
import TraitPage from './pages/TraitsPage';
import VideoInterview from './pages/VideoInterview';
import KOs from './pages/KOs';
import Reference from './pages/Reference';
import InterviewKitPDF from './pages/InterviewKit/InterviewKitPDF';
import Notes from './pages/Notes';

import chunk from 'lodash/chunk';
import i18n from '../../i18n';

const Report = ({ getPdfData, visible, closeModal }) => {
  const [state, setState] = useState({
    personalityScores: [],
    cognitiveScores: [],
    behavioralScores: [],
    koAnswers: [],
    candidate: {
      status: null,
      dni: null,
      age: null,
      nationality: null,
      // Pais residencia
      country: null,
      commune: null,
      email: null,
      gender: null,
      careers: [],
      experiences: [],
      final_score: 0,
      references: [],
      birth_date: '',
      date_completed: '',
    },
    jobApplication: {
      stages: [],
    },
    interviewsKit: [],
    notes: '',
    globalVInt: null,
    refsWithAnswers: [],
  });

  useEffect(() => {
    if (state.candidate.references?.length) {
      const refs = state.candidate.references.map((ref) =>
        ref.answer ? { ...ref, answer: Object.values(ref.answer) } : ref
      );
      setState((state) => ({
        ...state,
        refsWithAnswers: refs.filter((ref) => ref.answer.length),
      }));
    }
  }, [state.candidate]);

  const componentRef = useRef(null);

  useEffect(() => {
    if (visible) {
      getPdfData()
        .then((data) => {
          document.title = `informe-candidato ${
            data.candidate.user_name ?? data.candidate.name
          }`;
          setState(data);
        })
        .catch(() => closeModal());
    }

    // Default pdf name
    return () => (document.title = 'genomawork');
  }, [visible]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      [
        ...state.cognitiveScores,
        ...state.personalityScores,
        ...state.behavioralScores.filter((trait) => trait.type !== 'leadership'),
      ].forEach((trait) => {
        const div = document.getElementById(`${trait.type}-${trait.code}`);
        div.classList.replace('config-preview', 'config-printing');
      });
    },
    onAfterPrint: () => {
      [
        ...state.cognitiveScores,
        ...state.personalityScores,
        ...state.behavioralScores.filter((trait) => trait.type !== 'leadership'),
      ].forEach((trait) => {
        const div = document.getElementById(`${trait.type}-${trait.code}`);
        div.classList.replace('config-printing', 'config-preview');
      });
    },
  });

  const printTraits = (type, traits) => {
    // if personality 2 elements per page else 4 (using chunk from lodash library)
    // https://lodash.com/docs/4.17.15#chunk
    const chunkedTraits = chunk(traits, type === 'personality' ? 2 : 4);

    return chunkedTraits.map((traits, index) => (
      <>
        <div className="break-before" />
        <Divider className="no-print" />
        <Page headerText={headerPageText}>
          <TraitPage
            type={type}
            traits={traits}
            showHeader={index > 0 ? true : false}
          />
        </Page>
      </>
    ));
  };

  const headerPageText = `${state.candidate.name} - ${
    state.jobApplication.name
  } - ${state.candidate.date_completed ?? state.candidate.date_started}`;

  return (
    <Modal
      visible={visible}
      onCancel={closeModal}
      footer={null}
      width={600}
      className="report-pdf-modal"
      title={
        <Button type="primary" onClick={handlePrint}>
          {i18n.t('candidate_pdf__download_pdf', {ns: 'candidatePdf'})}
        </Button>
      }
    >
      <div ref={componentRef} className="report-pdf">
        <FirstPage state={state} />
        {printTraits('cognitive', state.cognitiveScores)}
        {printTraits('personality', state.personalityScores)}
        {printTraits('behavioral', state.behavioralScores)}

        {state.globalVInt && state.videoInterviewReady ? (
          <>
            <div className="break-before" />
            <Divider className="no-print" />
            <Page headerText={headerPageText}>
              <VideoInterview globalEvaluation={state.globalVInt} />
            </Page>
          </>
        ) : null}
        {state.koAnswers.length > 0 ? (
          <>
            <div className="break-before" />
            <Divider className="no-print" />
            <Page headerText={headerPageText}>
              <KOs KOs={state.koAnswers.sort((a,b)=> a.position - b.position)} />
            </Page>
          </>
        ) : null}
        {state.refsWithAnswers?.map((ref, index) => (
          <>
            <div className="break-before" />
            <Divider className="no-print" />
            <Page headerText={headerPageText}>
              <Reference reference={ref} i={index} />
            </Page>
          </>
        ))}
        {state.notes && state.notes.replace(/\s/g, '') !== '' ? (
          <>
            <div className="break-before" />
            <Divider className="no-print" />
            <Page headerText={headerPageText}>
              <Notes notes={state.notes} />
            </Page>
          </>
        ) : null}
        {state.interviewsKit && state.interviewsKit.length > 0 ? (
          <>
            <div className="break-before" />
            <Divider className="no-print" />
            <Page headerText={headerPageText}>
              <InterviewKitPDF interviewsKit={state.interviewsKit} />
            </Page>
          </>
        ) : null}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Report);
