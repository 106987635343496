import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import queryString from 'query-string'

import * as authActionCreators from '../../actions/auth';
import * as dataActionCreators from '../../actions/data';

import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Typography,
} from 'antd';
import CandidateLoginLayout from '../Login/components/CandidateLoginLayout';

import GenominSpin from '../../components/GenominSpin/GenominSpin';

import '../Login/Login.scss';

const { Paragraph, Title, Text } = Typography;

// TODO: PropTypes

const BusinessUserFirstLogin = (props) => {
  const [impersonateInfo, setImpersonateInfo] = useState({
    token: '',
    email: '',
    businessUserId: '',
  });
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [confirmDirty, setConfirmDirty] = useState();
  const [errors, setErrors] = useState({});
  const [modalErrorShown, setModalErrorShown] = useState(false);

  useEffect(() => {
    const values = queryString.parse(props.location.search);
    setImpersonateInfo({
      token: values.token,
      email: values.email,
      businessUserId: values.id,
    });
    props.authActions.authCheckAuthentication(values.token, true);
  }, []);

  useEffect(() => {
    if (impersonateInfo.token.length > 0) {
      props.actions.fetchAndReturn(
        impersonateInfo.token, '/api/v1/accounts/checkpassword/'
      ).then((response) => {
        if (!response.body.default_password) {
          props.dispatch(push("/home"))
        };
      })
    }
  }, [impersonateInfo]);

  useEffect(() => {
    if (props.authErrors && Object.keys(props.authErrors).length > 0) {
      Modal.error({
        title: 'Error de autentificación',
        content: 'El token de autenticación no es válido o no coincide con el usuario. Será redirigido a la página de inicio.',
        onOk: handleCloseModal,
        onCancel: handleCloseModal
      })
    }
  }, [props.authErrors])


  const handleCloseModal = (close) => {
    props.dispatch(push('/'));
    close();
  }

  const isValidPassword = (password) => {
    const re = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$%&\*\+\-_:;.=?@~])/;
    if (!re.test(password) || password.length < 8) {
      setErrors(
        (prevErrors) => (
          {
            ...prevErrors,
            password: "Debe tener mínimo 8 caracteres, al menos una letra minúscula, una letra mayúscula, un número y uno de los siguientes símbolos: !#$%&*+-/:;=?@_.~"
          }
        )
      );
      return false;
    }
    return true;
  };
  
  const isSamePassword = (password, passwordConfirm) => {
    if (password !== passwordConfirm) {
      setErrors(
        (prevErrors) => (
          {
            ...prevErrors,
            passwordConfirm: "La contraseña debe coincidir en ambos campos"
          }
        )
      );
      return false;
    }
    return true;
  };

  const handleInputChange = (prop) => (event) => {
    let tempErrors = errors;
    tempErrors[prop] = false;
    if (prop === "name") {
      setName(event.target.value);
    } else if (prop === "password") {
      if (isValidPassword(event.target.value)) {
        setPassword(event.target.value);
      }
    } else if (prop === "passwordConfirm") {
      if (isValidPassword(password) && isSamePassword(password, event.target.value)) {
        setPasswordConfirm(event.target.value);
      }
    }
  };

  const { getFieldDecorator } = props.form;

  const handleConfirmBlur = (e) => {
    const { value } = e.target;
    setConfirmDirty(confirmDirty || !!value);
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== props.form.getFieldValue("password")) {
      callback("Las contraseñas son diferentes");
    } else {
      callback();
    }

    const re = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$%&\*\+\-_:;.=?@~])/;

    if (!value.match(re)) {
      return callback(true);
    } else {
      return callback();
    }
  };

  const validateToNextPassword = (rule, value, callback) => {
    if (value && confirmDirty) {
      props.form.validateFields(["confirm"], { force: true });
    }
    callback();

    const re = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$%&\*\+\-_:;.=?@~])/;
    if (!value.length) {
      return callback();
    }
    if (!value.match(re) || value.length < 8) {
      return callback(true);
    } else {
      return callback();
    }
  };

  const submit = (e) => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      if (name.length > 0 && isValidPassword(password) && isSamePassword(password, passwordConfirm)) {
        const { businessUserId } = impersonateInfo;
        props.authActions.authBusinessUserFirstLogin(
          props.token, businessUserId, name, password, passwordConfirm, "/home"
        );
      } else {
        setModalErrorShown(true);
      }
    });
  };

  return (
    props.isAuthenticating ? (
      <div className="content-loading">
        <GenominSpin />
      </div>
    ) : (props.isAuthenticated && (
      <CandidateLoginLayout>
        <Col xs={24} sm={12} className='candidate-login__left-side'>
          <Row xs={16}>
            <Row
              className='candidate-login__left-side__header'
              style={{ height: "7em" }}
            >
              <Row>
                <Title level={1}> ¡Bienvenido a Genoma! </Title>
                <Paragraph>
                  Para continuar, primero debes ingresar tu nombre y cambiar tu contraseña.
                </Paragraph>
              </Row>
            </Row>

            <Form
              className="candidate-login__left-side__login-form"
              onSubmit={submit}
              style={{ marginTop: "1.5em" }}
            >
              <Form.Item label="Correo">
                <Input disabled value={impersonateInfo.email}/>
              </Form.Item>
              
              <Form.Item label="Nombre">
                {getFieldDecorator("name", {
                  onChange: handleInputChange("name"),
                  rules: [
                    {
                      required: true,
                      message: "Por favor ingresa tu nombre",
                    },
                  ]
                })(<Input />)}
              </Form.Item>

              <Form.Item label='Contraseña'>
                {getFieldDecorator("password", {
                  onChange: handleInputChange("password"),
                  rules: [
                    {
                      required: true,
                      message: "Por favor ingresa tu contraseña",
                    },
                    {
                      validator: validateToNextPassword,
                      message:
                        "Debe contener al menos 8 caracteres, y al menos 1 mayúscula, 1 minúscula, 1 número, y uno de los siguientes símbolos: !#$%&*+-_:;.=?@~ ",
                    },
                    { min: 8 },
                  ],
                })(<Input.Password placeholder='Contraseña'/>)}
              </Form.Item>

              <Form.Item
                style={{ marginBottom: "2em" }}
                label='Repetir contraseña'
              >
                {getFieldDecorator("confirm", {
                  onChange: handleInputChange("passwordConfirm"),
                  rules: [
                    {
                      required: true,
                      message: "Por favor confirma tu contraseña",
                    },
                    {
                      validator: compareToFirstPassword,
                    },
                    { min: 8 },
                  ],
                })(
                  <Input.Password
                    placeholder='Confirmar contraseña'
                    onBlur={handleConfirmBlur}
                  />
                )}
              </Form.Item>

              <Button
                loading={props.isAuthenticating}
                htmlType='submit'
                type='primary'
                size='large'
                disabled={!confirmDirty}
                block
              >
                Iniciar sesión
              </Button>
            </Form>
            <Modal visible={modalErrorShown} footer={null} closable={true} onCancel={() => setModalErrorShown(false)}>
              <Row type='flex' justify='center'>
                <Text type='danger'>Por favor revise los campos en rojo</Text>
              </Row>
            </Modal>
          </Row>
        </Col>
      </CandidateLoginLayout>
    ))
  )
}


const mapStateToProps = (state) => {
  return {
    authErrors: state.auth.errors,
    isAuthenticated: state.auth.isAuthenticated,
    isAuthenticating: state.auth.isAuthenticating,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: bindActionCreators(dataActionCreators, dispatch),
    authActions: bindActionCreators(authActionCreators, dispatch)
  };
};

const BusinessUserFirstLoginView = Form.create()(BusinessUserFirstLogin)
export default connect(mapStateToProps, mapDispatchToProps)(BusinessUserFirstLoginView);