import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import {
  Button,
  Checkbox,
  Col,
  Input,
  List,
  message,
  Modal,
  Popover,
  Row,
  Typography,
} from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import i18n from '../i18n';

import * as actionCreators from '../actions/data';

import ProcessStatusBadge from './ProcessStatusBadge';
import MceEditor from '../containers/BusinessDashboard/NewProcessFormV2/components/mceeditor/MceEditor';

const { Paragraph, Text } = Typography;

const readOnlyBody = (business, emailBody='') => (
  `<html>
    <body style="margin: 0 auto; background-color: #E5E5E5; font-family: color: #454545; font-size: 16px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" bgcolor="#ffffff" style="width: 100%; max-width: 680px; border-style: none;">
        <tr style="border-style: none;">
          <td align="center" style="padding: 30px; border-style: none;">
            <table width="100%" align="center" border="0" cellpadding="0" cellspacing="0" style="border-style: none;">
              <tr style="border-style: none;">
                <td align="center" style="border-style: none;">
                  <h3 style="margin: 0; padding: 0; line-height: 160%;">
                    ¡${business} te invita a postular a {nombre_del_proceso}!
                  </h3>
                </td>
              </tr>
              <tr style="border-style: none;">
                <td style="padding-top: 5px; border-style: none;">
                  ${emailBody}
                </td>
              </tr>
              <tr style="border-style: none;">
                <td style="padding-top: 15px; border-style: none;">
                  <strong>¿Que debes tener en cuenta para continuar el proceso?</strong>
                  <br />
                  <ul>
                    <li>Se te realizarán pruebas gamificadas  de aproximadamente 3 minutos cada una. Para continuar con el proceso debes hacer click en el siguiente link.</li>
                    <li>Puedes responder desde un computador o un celular / tablet.</li>
                    <li>Recuerda contar con una buena y estable conexión a Internet.</li>
                    <li>Te recomendamos usar el navegador Google Chrome actualizado.</li>
                  </ul>
                </td>
              </tr>
              <tr style="border-style: none;">
                <td style="padding-top: 5px; border-style: none;">
                  Teniendo esto en cuenta, y cuando estés preparado/a, haz click en el siguiente botón y presiona el botón "Postular" que se encontrará en la plataforma. Si ya tenías una cuenta con nosotros, podrás utilizar los resultados anteriores de nuestras evaluaciones.
                </td>
              </tr>
              <tr style="border-style: none;">
                <td align="center" style="padding-top: 30px; border-style: none;">
                  <a
                    href=""
                    style="text-decoration: none; font-weight: bold; padding: 8px 20px; font-size: 14px; background: #EF3C96; border: none; border-radius: 2px; color: #FFF; font-family: Lato, Arial, sans-serif; cursor: pointer; pointer-events: none;"
                  >
                    IR A POSTULAR
                  </a>
                </td>
              </tr>
              <tr style="border-style: none;">
                <td style="padding-top: 30px; border-style: none;">
                  ¡Te deseamos mucha suerte!
                </td>
              </tr>
              <tr style="border-style: none;">
                <td style="padding-top: 15px; border-style: none;">
                  Si tienes alguna duda o necesitas más información, por favor ponte en contacto con nosotros a través del icono rosa en la esquina inferior derecha de nuestra web.
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </body>
  </html>`
);

function InviteCandidateComponent({
  closeModal,
  jobApplication,
  selectedPersonalUsers,
  selectProcessesVisible=true,
  source,
  templates,
  visible,
  // Redux props
  actions,
  currentBusiness,
  token
}) {
  const [jobApplicationChoices, setJobApplicationChoices] = useState([]);
  const [selectedJobApplications, setSelectedJobApplications] = useState(
    jobApplication
      ? { [jobApplication.id]: { ...jobApplication } }
      : {}
  );
  const [emailBody, setEmailBody] = useState('');
  const [sendingEmails, setSendingEmails] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    if (jobApplication && jobApplication.id) {
      setSelectedJobApplications({ [jobApplication.id]: { ...jobApplication } });
    }
  }, [jobApplication]);

  useEffect(() => {
    if (selectProcessesVisible && jobApplicationChoices.length === 0) {
      const path = '/api/v1/gamesandtests/processlist/?format=json&page_size=10000&status=ACTIV';
      actions.fetchAndReturn(token, path)
        .then((data) => {
          setJobApplicationChoices(data.body.results);
        })
        .catch((e) => {
          Sentry.captureException(e);
        })
    }
  }, [])

  const onChange = (e) => setSelectedJobApplications((prev) => {
    const newSelected = { ...prev };
    newSelected[e.target.value] = !newSelected[e.target.value];
    if (!newSelected[e.target.value]) {
      delete newSelected[e.target.value];
    }
    return newSelected;
  });

  const handleSubmit = async () => {
    setSendingEmails(true);
    const url = '/api/v1/accounts/jobapplicationinvitations/';
    const notSent = [];
    const selectedJobApplicationsIds = Object.keys(selectedJobApplications);
    const promises = selectedJobApplicationsIds.map((jobapp_id) => {
      const data = {
        jobapp_id,
        mailBody: emailBody || ' ', // Espacio en blanco si no se envía mensaje opcional para que el back no envie el template de integraciones
        utm: {
          source,
          medium: 'email',
        },
      };
      return Promise.all(selectedPersonalUsers.map(({ email, name }) => (
        actions.fetchAndReturn(token, url, 'POST', { ...data, email, fields: { name }, })
          .then((response) => {
            if (response.status >= 400) {
              notSent.push([jobapp_id, personalUserInfo]);
            }
          })
          .catch((err) => Sentry.captureException(err))
      )));
    });
    await Promise.resolve(promises);
    setSendingEmails(false);
    closeModal();
    setEmailBody('');
    setSearchInput('');
    setSelectedJobApplications(
      jobApplication
        ? { [jobApplication.id]: { ...jobApplication } }
        : {}
    );
    if (notSent.length === 0) {
      message.success(
        (selectedPersonalUsers.length === 1 && selectedJobApplicationsIds.length === 1)
          ? i18n.t('invite_candidate__invitation_sent_successfully')
          : i18n.t('invite_candidate__invitations_sent_successfully')
      )
    } else {
      notSent.forEach(({jobapp_id, personalUserInfo}) => {
        const jobappName = jobApplicationChoices.filter(
          (jobapp) => jobapp.id === jobapp_id
        )[0].job_application;
        return (
          message.error(
            i18n.t('invite_candidate__error_sending_invitation')
              .replace('{{candidate}}', personalUserInfo.name || personalUserInfo.email)
              .replace('{{jobapp}}', jobappName)
          )
        )
      });
    }
  };

  const sortedJobApplicationChoices = useMemo(
    () => jobApplicationChoices.sort((a,b) => {
      const aSelected = selectedJobApplications[a.job_app_id] ? 1 : 0;
      const bSelected = selectedJobApplications[b.job_app_id] ? 1 : 0;
      return -(aSelected - bSelected);
    }),
    [jobApplicationChoices, selectedJobApplications]
  );

  return (
    <Modal
      bodyStyle={{ paddingTop: '1em' }}
      footer={(
        <>
          <Button
            onClick={() => {
              closeModal();
              setEmailBody('');
              setSearchInput('');
              setSelectedJobApplications(
                jobApplication
                  ? { [jobApplication.id]: { ...jobApplication } }
                  : {}
              );
            }}
            type="secondary"
          >
            {i18n.t('commons__cancel')}
          </Button>
          <Button loading={sendingEmails} onClick={handleSubmit} type="primary">
          {i18n.t('commons__send')}
          </Button>
        </>
      )}
      onCancel={closeModal}
      title={(
        <>
          <Text strong>{i18n.t('invite_candidate__invite')}</Text>
          <Paragraph style={{ marginBottom: 0 }}>
            {selectedPersonalUsers.length} {selectedPersonalUsers.length === 1 ? i18n.t('commons__candidate') : i18n.t('commons__candidates')}
          </Paragraph>
        </>
      )}
      visible={visible}
      width="60%"
    >
      {selectProcessesVisible ? (
        <>
          <Text strong style={{ fontSize: '16px' }}>
            {i18n.t('general_indicators__open_processes')}
          </Text>
          <Paragraph style={{ marginBottom: 0 }}>
            {selectedPersonalUsers.length === 1
              ? i18n.t('invite_candidate__select_processes_to_invite_candidate_to')
              : i18n.t('invite_candidate__select_processes_to_invite_candidates_to')}
          </Paragraph>
          
          <Input.Search
            allowClear
            onSearch={(value) => setSearchInput(value.toLocaleLowerCase())}
            placeholder={i18n.t('invite_candidate__search_process__placeholder')}
            style={{ marginBottom: '0.75em', marginTop: '0.75em' }}
          />
          <div style={{ maxHeight: 180, overflowY: 'auto' }}>
            {sortedJobApplicationChoices.filter(
              ({ job_app }) => (
                job_app.job_application.toLocaleLowerCase().includes(searchInput)
                || job_app.department.toLocaleLowerCase().includes(searchInput)
              )
            ).map((jobApplication) => (
              <Row 
                key={`${jobApplication.job_app_id}`}
                style={{
                  backgroundColor: '#fafafa',
                  border: '1px solid #e9e9e9',
                  height: 60,
                  padding: 10,
                  width: '100%',
                }}
                type='flex'
              >
                <Col sm={1}>
                  <Checkbox 
                    checked={selectedJobApplications[jobApplication.job_app_id]}
                    onChange={onChange}
                    value={jobApplication.job_app_id}
                  />
                </Col>
                <Col sm={1} style={{ marginLeft: '0.25em', marginRight: '0.25em' }}>
                  <ProcessStatusBadge status={jobApplication.job_app.status} />
                </Col>
                <Col>
                  <Text strong style={{ color: 'black' }}>{jobApplication.job_app.job_application}</Text>
                  <br/>
                  <Text>{jobApplication.job_app.department}</Text>
                </Col>
              </Row>
            ))}
          </div>
        </>
      ) : null}
      
      <Row style={{ fontSize: '16px', marginBottom: '1em', marginTop: '1em' }}>
        <Text strong>{i18n.t('invite_candidate__send_message')}</Text>
        <Text style={{ color: '#BFBFBF' }}> ({i18n.t('commons__optional')})</Text>
        <Paragraph style={{ marginBottom: 0 }}>
          {i18n.t('invite_candidate__send_message__description')}
        </Paragraph>
      </Row>
      <Row style={{ marginBottom: '1em' }}>
        {/* Edición mensaje opcional */}
        <MceEditor
          body={emailBody}
          handleChange={() => (e) => setEmailBody(e.target.value)}
          readOnlyBody={emailBody}
        />
      </Row>
      <Row>
        {/* Vista previa */}
        {i18n.t('commons__preview')}
        <Editor
          disabled={true}
          init={{
            entity_encoding: 'raw',
            height: 700,
            branding: false,
            menubar: false,
            elementpath: false,
            language: 'es',
            plugins: '',
            toolbar: '',
            selector: 'textarea',
          }}
          value={readOnlyBody(currentBusiness.name, emailBody)}
        />
      </Row>
      <Popover
        content={
          <List style={{ height: '40vh', overflowY: "scroll" }}>
            {templates?.sort(
              (a, b) => a.name.localeCompare(b.name)
            ).map((template, i) => (
              <List.Item
                key={i}
                onClick={() => setEmailBody(template.template)}
                style={{ cursor: 'pointer' }}
              >
                <List.Item.Meta title={<a>{template.name}</a>} />
              </List.Item>
            ))}
          </List>
        }
        title={<Text strong>{i18n.t('profile__my_templates', { ns: 'candidateProfile' })}</Text>}
        trigger="click"
        width={350}
      >
        <Button
          disabled={!templates.length}
          type="link"
          style={{ marginTop: '0.5em', paddingLeft: 0 }}
        >
          {i18n.t('profile__my_templates', { ns: 'candidateProfile' })}
        </Button>
      </Popover>
    </Modal>
  );
}

InviteCandidateComponent.propTypes = {
  closeModal: PropTypes.func.isRequired,
  jobApplication: PropTypes.object || null,
  selectedPersonalUsers: PropTypes.array.isRequired,
  selectProcessesVisible: PropTypes.bool,
  source: PropTypes.string.isRequired,
  templates: PropTypes.array.isRequired,
  visible: PropTypes.bool.isRequired,
  // Redux props
  actions: PropTypes.object.isRequired,
  currentBusiness: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

const mapStateToProps = state => {
  return {
    currentBusiness: state.auth.currentBusiness,
    token: state.auth.token,
    userId: state.auth.userID,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteCandidateComponent);
