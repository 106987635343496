import React, { useState } from 'react'
import { Form, Input, Button, Typography } from 'antd';
import Actions from '../Actions';
import { AppContext } from '../JobForm';


export function Questions({ form, savedQuestions }) {
  const savedQuestionsArray = Object.keys(savedQuestions || {}).map(key => savedQuestions[key])
  const [questions, setQuestions] = useState(savedQuestionsArray);

  const deleteQuestion = (e) => {
    e.preventDefault();
    const newQuestions = [...questions]
    newQuestions.pop();
    setQuestions(newQuestions);
  }

  const addQuestion = (e) => {
    e.preventDefault();
    setQuestions((q) => ([...q, `Pregunta ${q.length + 1}`]));
  }

  const { getFieldDecorator } = form;

  return (
    <AppContext.Consumer>
      {({ submit, save }) => {
        return (
          <>
            <Form onSubmit={(e) => submit(e, form, 'questions')} onChange={(e) => save(e, form, 'questions')}>
            <div className='form-view' id='form-view'>
              <Typography.Title>Preguntas adicionales</Typography.Title>
              {questions.map((q, i) => {
                return(
                  <Form.Item key={`fi-${i+1}`} label={`Pregunta ${i+1}`} style={{ display: 'flex' }}>
                    {getFieldDecorator(`question${i+1}`, {
                      initialValue: questions[i],
                      rules: [
                        {
                          required: true,
                          message: 'Por favor ingresa la pregunta.',
                        },
                      ],
                    })(
                        <Input type='text' key={i} />
                    )}
                    {questions.length > 0 && (i+1) === questions.length ? 
                      <Button icon='trash' onClick={deleteQuestion}>Eliminar pregunta</Button>
                    : null}
                  </Form.Item>
                )
              })}
                <Button onClick={addQuestion}>Agregar pregunta</Button>
              </div>
              <Actions />
            </Form>
          </>
        )
      }}
    </AppContext.Consumer>

  )
}

const QuestionsForm = Form.create()(Questions);
export default QuestionsForm;
