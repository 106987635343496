import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print'
import {
  Modal,
  Button
} from 'antd';

import DatPdf from './dat/datPdf/DatPdf';
import AlternativesPdf from './alternatives/alternativesPdf/AlternativesPdf'
import DiscPdf from './disc/discPdf/DiscPdf';
import PdfSJT from './sjt/PdfSJT';

import i18n from '../../../../i18n';

const ModalPdf = ({ showCutePdf, customTest, candidate, jobApp, setShowCutePdf, type, downloadSummary }) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const ModalPdf = () => {
    switch (type) {
      case 'DAT':
        return (
          <DatPdf
            refPrint={componentRef}
            nivoData={customTest.answer.scores}
            candidateInfo={candidate}
            jobName={jobApp.job_application}
            score={customTest.answer.scores.score}
          />
        );
      case 'RAVEN':
        return (
          <AlternativesPdf
            refPrint={componentRef}
            data={customTest}
            candidateInfo={candidate}
            jobName={jobApp.job_application}
            downloadSummary={true}
            hideGenomaLogo={true}
          />
        );

      case 'ALTERNATIVES':
        return (
          <AlternativesPdf
            refPrint={componentRef}
            data={customTest}
            candidateInfo={candidate}
            jobName={jobApp.job_application}
            downloadSummary={downloadSummary}
          />
        );
      case 'DISC':
        return (
          <DiscPdf
            refPrint={componentRef}
            data={customTest}
            candidateInfo={candidate}
            jobName={jobApp.job_application}
          />
        );
      case 'SJT_GW':
        return (
          <PdfSJT
            refPrint={componentRef}
            data={customTest}
            candidateInfo={candidate}
            jobName={jobApp.job_application}
          />
        )

      default:
        return null;
    }
  }

  return (
    <Modal
      title={
        <Button
          type='primary'
          onClick={handlePrint}>
          {i18n.t('commons__download')}
        </Button>
      }
      onCancel={() => setShowCutePdf(false)}
      visible={showCutePdf}
      footer={null}
      bodyStyle={{ padding: '12px' }}
      width={850}
      centered
    >
      <ModalPdf />
    </Modal>
  );
}

export default ModalPdf;