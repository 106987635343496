/**
 *
 * @param {*} minColor RGB array
 * @param {*} maxColor RGB array
 * @param {*} weightPercent Decimal value from 0 to 1
 * @returns RGB array
 */
export const pickRGB = (minColor, maxColor, weightPercent) => {
  const w1 = weightPercent;
  const w2 = 1 - w1;
  const rgb = [
    Math.round(maxColor[0] * w1 + minColor[0] * w2),
    Math.round(maxColor[1] * w1 + minColor[1] * w2),
    Math.round(maxColor[2] * w1 + minColor[2] * w2),
  ];
  return rgb;
};

/**
 * DEPRECATED
 * The max value is 100% the min value is 0%
 * @param {*} min
 * @param {*} max
 * @param {*} value
 * @returns
 */
const getPercent = (min, max, value) => (value - min) / (max - min);

export const getColor = (min, max, value) => {
  if (min == max) {
    return `RGB(${[0, 80, 179].join(',')})`;
  }
  const percent = getPercent(min, max, value);
  const rgbArray = pickRGB([105, 192, 255], [0, 80, 179], percent);
  return `RGB(${rgbArray.join(',')})`;
};

export const geTotalPercentage = (valuesArray, value) =>
  (value / valuesArray.reduce((a, b) => a + b, 0)) * 100;

export const getColors = (n, colorRGB = [0, 80, 179]) => {
  const colors = [];

  for (let i = 0; i < n; i++) {
    const factor = i / n;

    colors.push(
      `rgb(${colorRGB
        .map((color) => Math.round(color * (1 - factor) + 255 * factor))
        .join(', ')})`
    );
  }

  return colors;
};

/**
 * 
 * @param {*} borderColors -> array of min and max value
 * @param {*} value -> length of returned ccolors array 
 * @returns 
 */
export const generateColorArray = (borderColors, value) => {
  const minColor = borderColors[0];
  const maxColor = borderColors[1];
  const colorArray = [];

  for (let i = 0; i < value; i++) {
    const r = Math.floor((i / (value - 1)) * (parseInt(maxColor.slice(1, 3), 16) - parseInt(minColor.slice(1, 3), 16)) + parseInt(minColor.slice(1, 3), 16));
    const g = Math.floor((i / (value - 1)) * (parseInt(maxColor.slice(3, 5), 16) - parseInt(minColor.slice(3, 5), 16)) + parseInt(minColor.slice(3, 5), 16));
    const b = Math.floor((i / (value - 1)) * (parseInt(maxColor.slice(5, 7), 16) - parseInt(minColor.slice(5, 7), 16)) + parseInt(minColor.slice(5, 7), 16));
    const color = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
    colorArray.push(color);
  }

  return colorArray;
};
