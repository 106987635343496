import React, { useState } from 'react';
import { Row, Col, Typography, Tooltip, Icon, Button, Input } from 'antd';


const ColorsTheme = ({ setColors, theme }) => {

  const initialTheme = {
    background: '#f175a5',
    color: '#ffffff',
  }
  const [themes, setThemes] = useState(initialTheme)
  const { Text, Paragraph } = Typography;

  const handleChangeColor = (name) => (event) => {
    const newTheme = { ...themes, [name]: event.target.value }
    setThemes(newTheme)
    setColors(newTheme)
  };

  const handleOnBlur = (name) => (event) => {
    const value = event.target.value;
    const newTheme = initialTheme

    if (!/^#[0-9A-F]{6}$/i.test(value)) {
      setThemes(newTheme)
      setColors(newTheme)
    }
  };

  const backToPredeterminatedColors = () => {
    setThemes(initialTheme)
    setColors(themes)
  };

  return (
    <Row className='sb__bg'>
      <Row>
        <Col sm={24}>
          <div className='img-description'>
            <Text strong className='subtitle'>Configuración del color principal</Text>
            <Paragraph>
              Los colores que elijas serán agregados al portal de empleos y a la vista de un empleo en particular. Los elementos que cambiarán de color serán los botones, el fondo de la imagen y el pie de la página.
            </Paragraph>
            <Button size='small' className='sb__bg__link' onClick={backToPredeterminatedColors} >Volver a los colores predeterminados</Button>
          </div>
        </Col>
      </Row>
      <Row type="flex">
        <div className='sb__bg__color' >
          <div className='sb__bg__color__input'>
            <Text>Color de fondo del boton principal</Text>
            <input
              type="color"
              value={theme.background ?? themes.background}
              onChange={handleChangeColor('background')}
            />
            <input
              style={{ height: 30, width: 70, textAlign: 'center', marginTop: 10 }}
              value={theme.background ?? (themes.background)}
              onChange={handleChangeColor('background')}
              onBlur={handleOnBlur('background')}
            />
          </div>
          <div className='sb__bg__color__input'>
            <Tooltip title='Si tu color principal es muy claro, te aconsejamos configurar un color oscuro en el texto del boton para una mejor lectura debido a que el color predeterminado es blanco.' >
              <Text>Color del texto del boton <Icon type="info-circle" /> </Text>
            </Tooltip>
            <input
              type="color"
              value={theme.color ?? themes.color}
              onChange={handleChangeColor('color')}
            />
            <input
              style={{ height: 30, width: 70, textAlign: 'center', marginTop: 10 }}
              value={theme.color ?? themes.color}
              onChange={handleChangeColor('color')}
            />
          </div>
        </div>
        {/* <div className='sb__bg__preview'>
          <div className='sb__bg__preview__contain'>
            <div
              className='sb__bg__preview__block-back'
              style={{
                backgroundColor: theme.background ?? themes.background,
                color: theme.color ?? themes.color
              }}>
              <p>color</p>
            </div>
            <div className='sb__bg__preview__block-front'><p>imagen</p></div>
            <div
              className='sb__bg__preview__block-bottom'
              style={{ backgroundColor: theme.background ?? themes.background }}>
              <p style={{ color: theme.color ?? themes.color }}>color del texto</p>
            </div>
            <div className='sb__bg__preview__block-button' style={{ backgroundColor: theme.background ?? themes.background }}><p>b</p></div>
            <div className='sb__bg__preview__block-title' style={{ backgroundColor: theme.color ?? themes.color }}><p>texto</p></div>
            <div className='sb__bg__preview__block-text' style={{ backgroundColor: theme.color ?? themes.color }}><p>texto</p></div>
            <div className='sb__bg__preview__block-circleLeft'><p>texto</p></div>
            <div className='sb__bg__preview__block-circleMiddle'><p>texto</p></div>
            <div className='sb__bg__preview__block-circleRight'><p>texto</p></div>
            <img className='sb__bg__preview__img' src='https://genoma-assets.s3.us-east-2.amazonaws.com/jobsite-assets/theme-configuration.png' />
          </div>
          <div className='sb__bg__preview__span' >
            <span>Previsualización paleta de colores</span>
          </div>
        </div> */}

        <div style={{ width: '250px', height: '150px', border: '1px solid #D9D9D9', margin: '0 0 0 20px' }}>
          <div style={{ height: '75%', width: '100%', display: 'flex' }}>
            <div style={{ height: '100%', width: '50%', padding: '30px 0 0 15px' }}>
              <div style={{ width: '60px', height: '8px', background: '#D9D9D9', margin: '0 0 8px 0' }} />
              <div style={{ width: '40px', height: '6px', background: '#D9D9D9', margin: '0 0 8px 0' }} />
              <div style={{ width: '90px', height: '6px', background: '#D9D9D9', margin: '0 0 8px 0' }} />
              <div style={{ width: '70px', height: '6px', background: '#D9D9D9', margin: '0 0 8px 0' }} />
              <div style={{ display: 'flex' }}>
                <div style={{ width: '20px', height: '7px', background: themes.background, color: themes.color }} />
                <div style={{ width: '20px', height: '7px', background: '#D9D9D9', margin: '0 0 0 10px' }} />
              </div>
            </div>
            <div style={{ height: '100%', width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              <div style={{ height: '100%', width: '100%', background: '#D9D9D9' }}/>
            </div>
          </div>
          <div style={{ height: '25%', width: '100%', background: theme.background, color: theme.color, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', padding: '5px 0' }}>
            <div style={{ width: '180px', height: '6px', background: themes.color }}/>
            <div style={{ width: '130px', height: '4px', background: themes.color }}/>
            <div style={{ height: '10px', width: '130px', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
              <div style={{ height: '10px', width: '10px', borderRadius: '100%', background: themes.color }}/>
              <div style={{ height: '10px', width: '10px', borderRadius: '100%', background: themes.color }}/>
              <div style={{ height: '10px', width: '10px', borderRadius: '100%', background: themes.color }}/>
            </div>
          </div>
          <div className='sb__bg__preview__span' >
            <span>Previsualización paleta de colores</span>
          </div>
        </div>
      </Row>
    </Row>
  )
}

export default ColorsTheme;
