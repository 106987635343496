import React from 'react';
import { Button, Select, Input, Col, Row } from 'antd';

const { Option } = Select;
const { Search } = Input;

let objOptions = {
  singleline: 'Texto',
  multiline: 'Texto múltiple',
  int: 'Numérico',
  singleselection: 'Selección única',
  multiselection: 'Selección múltiple',
  file: 'Documento adjunto',
};

const ActionBar = ({
  setSelectSearchValue,
  inputSearchValue,
  showModalFunction,
  setIsModalCreateVisible,
  handleFilterQuestions,
  handleFilterQuestionsSearch,
  setFilterQuestion,
  inputSearchBarFn,
  dataSource,
  selectSearchValue,
}) => {
  const filterOptionsToSelect = [
    ...new Set(dataSource.map(({ question_type }) => question_type)),
  ];

  return (
    <Row>
      <Row type="flex" style={{ gap: '0.75rem' }}>
        <Col>
          <Search
            allowClear
            placeholder="Buscar preguntas"
            onSearch={(stringToSearch) =>
              handleFilterQuestionsSearch(stringToSearch)
            }
            value={inputSearchValue}
            style={{ width: '25rem' }}
            onChange={(e) => {
              inputSearchBarFn(e);
              setSelectSearchValue('all');
              if (!e.target.value.length) {
                setFilterQuestion(false);
              }
            }}
          />
        </Col>
        <Col>
          <Select
            allowClear={selectSearchValue !== 'all' ? true : false}
            style={{ width: '11.25rem' }}
            onChange={(option) => {
              handleFilterQuestions(option);
            }}
            value={selectSearchValue}
          >
            {Object.keys(objOptions).map((question_type, idx) => (
              <Option value={question_type} key={idx}>
                {objOptions[question_type]}
              </Option>
            ))}
            <Option value="all">Todas las categorias</Option>
          </Select>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => showModalFunction(setIsModalCreateVisible, true)}
          >
            Crear pregunta
          </Button>
        </Col>
      </Row>
    </Row>
  );
};

export default ActionBar;
