import React from 'react'

import StagesBar from './EvaluationsComponents/StagesBar';
import JobAppEvaluations from './EvaluationsComponents/JobAppEvaluations';

function Evaluations() {
  return (
    <div className='jobapp-evaluations '>
      <StagesBar />
      <JobAppEvaluations />
    </div>
  )
};

export default Evaluations;