import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../actions/data';
import { Modal, message } from 'antd';

//Internal components
import MailTemplatesContext from './context/mailTemplates';
import TemplateMails from './TemplateMails2';
//Internal functions
import {
  getTemplates,
  getCategories,
  deleteCategory,
  deleteTemplate,
  filterByNameOrSubjectFn,
  filterByTagFn,
  filterCompose,
} from './services';

const MainMailTemplates = (props) => {
  const [loading, setLoading] = useState(false);
  const [emailTemplates, setEmailTemplates] = useState([
    {
      business_user_id: '',
      id: null,
      name: '',
      template: '',
    },
  ]);
  const [isActive, setIsActive] = useState({
    id: null,
  });
  const [templateToPreview, setTemplateToPreview] = useState({});
  const [colaboratorsToShare, setColaboratorsToShare] = useState([]);
  const [arrayToShare, setArrayToShare] = useState([]);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [createVisible, setCreateVisible] = useState(false);
  const [categoryValue, setCategoryValue] = useState('');
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [tagsValue, setTagsValue] = useState({
    tags: undefined,
    owner: undefined,
  });
  const [categories, setCategories] = useState({ loading: false, data: [] });
  const [duplicateTemplate, setDuplicateTemplate] = useState({});
  const [inputSearch, setInputSearch] = useState('');
  const [arrayInCategory, setArrayInCategory] = useState([]);
  const [inputState, setInputState] = useState({
    tags: [],
    inputVisible: false,
    inputValue: '',
  });
  const [createTemplate, setCreateTemplate] = useState({
    name: '',
    template: '',
    subject: '',
    category: '',
    tags: [],
  });
  const [filteredTemplates, setFilteredTemplates] = useState({
    data: false,
    filterTemplates: [],
  });
  const [filteredTags, setFilteredTags] = useState({
    data: false,
    filterTags: [],
  });
  const [filteredCreator, setFilteredCreator] = useState({
    data: false,
    filterCreator: [],
  });
  const [filterObj, setFilterObj] = useState({
    mailTemplates: undefined,
    filterByNameOrSubject: undefined,
  });

  const [extraCategory, setExtraCategory] = useState({
    id: undefined,
    name: undefined,
  });

  const openModalDeleteConfirmation = (template) => {
    Modal.confirm({
      centered: true,
      title: `¿Estás seguro/a que deseas eliminar la plantilla?`,
      content: `Se eliminará de manera definitiva para todas las personas que tengan acceso a esta plantilla.`,
      okText: 'Eliminar plantilla',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: () => {
        deleteTemplate(
          template,
          props,
          message,
          setIsActive,
          getTemplates,
          setEmailTemplates,
          setLoading
        );
      },
      onCancel: () => {},
    });
  };

  const getSingleTemplate = async ({ id }, obj, category) => {
    try {
      let responseToDuplicate;
      const url = `accounts/business/${props.business.id}/mailtemplates/${id}/`;
      const token = props.token;
      let response = await props.actions.fetchData(token, url, '', 'get');
      if (!obj.edit) {
        delete response.category;
      }
      delete response.creator;
      if (!obj.edit) {
        delete response.id;
      }
      delete response.public;
      delete response.shared_with;
      delete response.business;
      if (!obj.edit) {
        responseToDuplicate = { ...response, category };
      } else {
        responseToDuplicate = { ...response };
      }

      if (obj.preview) {
        setTemplateToPreview(response);
        setOpenPreviewModal(true);
      } else if (obj.duplicate) {
        setDuplicateTemplate(responseToDuplicate);
        setInputState({ ...inputState, tags: response.tags });
        setOpenCopyModal(true);
      } else if (obj.edit) {
        setDuplicateTemplate(responseToDuplicate);
        setInputState({ ...inputState, tags: response.tags });
        setOpenEditModal(true);
      }
    } catch (error) {
      message.error('No se pudo obtener el mail template');
    }
  };

  const getSharedTemplate = async ({ id }) => {
    try {
      const url = `accounts/mailtemplates/${id}/`;
      const token = props.token;

      let endpoint = `/api/v1/permissions/business/${props.business.id}/`;

      const { body } = await props.actions.fetchAndReturn(token, endpoint);

      const response = await props.actions.fetchData(token, url, '', 'get');

      setColaboratorsToShare(body.results);
      setTemplateToPreview(response);
      setOpenShareModal(true);
    } catch (error) {
      message.error('No se pudo compartir el mail template');
    }
  };

  const shareTemplatesFn = async (pk) => {
    try {
      const url = `accounts/mailtemplates/${pk}/share/`;
      const token = props.token;
      const data = {
        business_users: arrayToShare,
      };

      await props.actions.sendData(token, url, JSON.stringify(data), 'PUT');
      message.success('Plantillas compartidas exitosamente');
      setOpenShareModal(false);
      setArrayToShare([]);
    } catch {
      message.error('Ocurrió un error al enviar el cuestionario');
    }
  };

  const createCategory = async () => {
    try {
      const url = `/api/v1/accounts/mailtemplatecategories/`;
      const token = props.token;
      const data = {
        name: categoryValue,
      };
      await props.actions.fetchAndReturn(token, url, 'POST', data);
      message.success('Categoria exitosamente creada');
      setCreateVisible(false);
      setCategoryValue('');
    } catch {
      message.error('Ocurrió un error al crear categoria');
    }
  };

  const createMailTemplateFn = async (copy, category) => {
    const templateToCopy = !duplicateTemplate.name
      ? templateToPreview
      : duplicateTemplate;

    category ? (templateToCopy['category'] = category) : null;

    try {
      const url = `accounts/mailtemplates/`;
      const token = props.token;
      const isCreateOrCopyTemplate = copy
        ? JSON.stringify(templateToCopy)
        : JSON.stringify(createTemplate);
      console.log('🚀 ----->', templateToCopy);
      await props.actions.sendData(token, url, isCreateOrCopyTemplate);
      if (copy) {
        setOpenCopyModal(false);
        setOpenPreviewModal(false);
      } else {
        setOpenCreateModal(false);
      }
      setCreateTemplate({
        name: '',
        template: '',
        subject: '',
        category: '',
        tags: [],
      });
      setIsActive({ id: null });
    } catch (error) {
      message.error(`No se pudo ${copy ? 'copiar' : 'crear'} el mail template`);
    }
  };

  const editMailTemplateFn = async ({ id }) => {
    const templateToCopy = duplicateTemplate;
    try {
      const url = `/api/v1/accounts/mailtemplates/${id}/`;
      const token = props.token;

      await props.actions.fetchAndReturn(token, url, 'PATCH', templateToCopy);
      setIsActive({ id: null });

      setOpenEditModal(false);
    } catch (error) {
      message.error(`No se pudo editar el mail template`);
      setOpenEditModal(false);
    }
  };

  const activeCol = (e) => {
    setIsActive({
      id: isActive.id === e.currentTarget.id ? null : e.currentTarget.id,
    });
  };

  const categoryExtra = (e) => {
    let arrCat = categories.data.filter(({ id }) => id === e.currentTarget.id);
    setExtraCategory({
      id: e.currentTarget.id,
      name: arrCat[0].name,
    });
  };

  const handleEditTemplateChange = (name) => (event) => {
    setDuplicateTemplate({
      ...duplicateTemplate,
      [name]: event.target.value,
    });
  };

  const handleCreateTemplateChange = (name) => (event) => {
    setCreateTemplate({
      ...createTemplate,
      [name]: event.target.value,
    });
  };

  const selectCreateCategoryFn = (option) => {
    setOpenCreateModal(true);
    setCreateTemplate({
      ...createTemplate,
      category: option,
    });
  };

  const filterTemplatesByTagsFn = (value) => {
    let filteredTemplatesArray;
    setTagsValue({ ...tagsValue, tags: value });

    if (!value) {
      setFilteredTemplates({
        ...filteredTemplates,
        filterTemplates: arrayInCategory,
      });
      setInputSearch('');
      return setTagsValue({ tags: undefined, owner: undefined });
    }

    if (inputSearch === '') {
      filteredTemplatesArray = filterByTagFn(value, arrayInCategory);
    } else {
      const valueNormalize = inputSearch
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

      filteredTemplatesArray = arrayInCategory.filter(
        ({ tags, name, subject }) =>
          tags.includes(value) &&
          name
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(valueNormalize.toLowerCase())
      );
    }

    setFilteredTemplates({
      ...filteredTemplates,
      filterTemplates: filteredTemplatesArray,
    });
  };

  // const filterTemplatesByOwnerFn = (value) => {
  //   let filteredTemplatesArray;

  //   setTagsValue({ ...tagsValue, owner: value });
  //   setFilterObj({
  //     ...filterObj,
  //     mailTemplates: arrayInCategory.filter(({ business }) =>
  //       business.name.includes(value)
  //     ),
  //   });

  //   if (!value) {
  //     setFilteredTemplates({
  //       ...filteredTemplates,
  //       filterTemplates: arrayInCategory,
  //     });
  //     setFilterObj({
  //       ...filterObj,
  //       mailTemplates: arrayInCategory,
  //     });
  //     setInputSearch('');
  //     return setTagsValue({ tags: undefined, owner: undefined });
  //   }

  //   if (filterObj.filterByNameOrSubject !== undefined) {
  //     let result = filterCompose(filterByNameOrSubjectFn)(filterObj);
  //     filteredTemplatesArray = result.filter(
  //       ({ business }) => business.name === value
  //     );
  //   } else {
  //     filteredTemplatesArray = filterObj.mailTemplates.filter(
  //       ({ business }) => business.name === value
  //     );
  //   }

  //   setFilteredTemplates({
  //     ...filteredTemplates,
  //     filterTemplates: filteredTemplatesArray,
  //   });
  // };

  const onSearch = () => {
    if (inputSearch === '') {
      let result = filterByNameOrSubjectFn(inputSearch, arrayInCategory);
      setFilteredTemplates({ ...filteredTemplates, filterTemplates: result });
      filterByTags(result);
      filterByCreator(result);
    }
  };

  const onChangeSearch = ({ target }) => {
    setInputSearch(target.value);
    if (target.value === '') {
      setFilteredTemplates({
        ...filteredTemplates,
        filterTemplates: arrayInCategory,
      });
      filterByTags(arrayInCategory);
      setTagsValue({ tags: undefined, owner: undefined });
      filterByCreator(arrayInCategory);
    } else if (!!tagsValue.tags) {
      const valueNormalize = target.value
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

      let result = arrayInCategory.filter(
        ({ tags, name }) =>
          tags.includes(tagsValue.tags) &&
          name
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(valueNormalize.toLowerCase())
      );
      setFilteredTemplates({
        ...filteredTemplates,
        filterTemplates: result,
      });
      filterByTags(result);
      filterByCreator(result);
    } else {
      let result = filterByNameOrSubjectFn(target.value, arrayInCategory);
      setFilteredTemplates({
        ...filteredTemplates,
        filterTemplates: result,
      });
      filterByTags(result);
      filterByCreator(result);
    }
  };

  const filterByTags = (array) => {
    const tagsToSelect = [];
    array.map(({ tags }) => {
      tags && tags.length > 0 && tagsToSelect.push(tags.join(', '));
    });
    setFilteredTags({
      ...filteredTags,
      filterTags: [...new Set(tagsToSelect.join(', ').split(', '))],
    });
  };

  const filterByCreator = (array) => {
    const creatorToSelect = [];
    array.map(({ business }) => {
      business && creatorToSelect.push(business.name);
    });
    setFilteredCreator({
      ...filteredCreator,
      filterCreator: [...new Set(creatorToSelect)],
    });
  };

  // const onChangeSwitchAllCategories = (value) => {
  //   setFilteredTemplates({
  //     ...filteredTemplates,
  //     data: !value,
  //   });
  // };

  const filterByCategory = (category) => {
    let allCategories = false;
    if (category === 'all-categories') allCategories = true;
    const filteredTemplatesArray = emailTemplates.filter(
      (template) => template.category === category
    );

    setFilteredTemplates({
      data: true,
      filterTemplates: allCategories ? emailTemplates : filteredTemplatesArray,
    });
    setFilterObj({
      ...filterObj,
      mailTemplates: allCategories ? emailTemplates : filteredTemplatesArray,
    });
    filterByTags(allCategories ? emailTemplates : filteredTemplatesArray);
    filterByCreator(allCategories ? emailTemplates : filteredTemplatesArray);
    setArrayInCategory(allCategories ? emailTemplates : filteredTemplatesArray);
  };

  const selectedStyle = (background, show) => {
    return {
      background,
      width: '100%',
      height: show ? '2px' : '0px',
    };
  };

  const deleteAndUpdateCategories = async (item) => {
    await deleteCategory(item, props, message);
    await getCategories(setCategories, props, message);
  }

  useEffect(() => {
    if (
      !openCopyModal ||
      !openCreateModal ||
      !openPreviewModal ||
      !openEditModal ||
      !createVisible
    ) {
      getTemplates(setLoading, setEmailTemplates, props, message);
      getCategories(setCategories, props, message);
    }
  }, [
    openCopyModal,
    openCreateModal,
    openPreviewModal,
    openEditModal,
    createVisible,
  ]);

  const previewModal = {
    openPreviewModal,
    setOpenPreviewModal,
    templateToPreview,
    categories,
    createMailTemplateFn,
  };

  const shareModal = {
    openShareModal,
    setOpenShareModal,
    templateToPreview,
    colaboratorsToShare,
    setArrayToShare,
    arrayToShare,
    shareTemplatesFn,
    businessName: props.business.name,
    businessUserId: props.businessUserId,
  };

  const editModal = {
    duplicateTemplate,
    handleEditTemplateChange,
    setDuplicateTemplate,
    setOpenEditModal,
    openEditModal,
    editMailTemplateFn,
    inputState,
    setInputState,
  };

  const tableTemplates = {
    emailTemplates: filteredTemplates.data
      ? filteredTemplates.filterTemplates
      : [],
    categories: categories.data,
    loading,
    openModalDeleteConfirmation,
    deleteAndUpdateCategories,
    businessName: props.business.name,
    businessUserId: props.businessUserId,
    getSharedTemplate,
  };

  const categoriesTabs = {
    categories: categories.data,
    activeCol,
    selectedStyle,
    isActive,
  };

  const copyAndCreateModal = {
    handleChange: openCreateModal
      ? handleCreateTemplateChange
      : handleEditTemplateChange,
    onCleanFn: openCreateModal ? setCreateTemplate : setDuplicateTemplate,
    argsInCleanFn: openCreateModal
      ? {
          name: '',
          template: '',
          subject: '',
          category: '',
          tags: [],
        }
      : {},
    template: openCreateModal ? createTemplate : duplicateTemplate,
    title: openCreateModal ? 'Crear plantilla' : 'Duplicar plantilla',
    onCancelFn: openCreateModal ? setOpenCreateModal : setOpenCopyModal,
    onOpenModal: openCreateModal ? openCreateModal : openCopyModal,
    onOkFn: createMailTemplateFn,
    openCreateModal,
    setInputState,
    inputState,
  };

  const filterBar = {
    onChangeSearch,
    onSearch,
    createVisible,
    setCreateVisible,
    setCategoryValue,
    categoryValue,
    createCategory,
    inputSearch,
    filterTemplatesByTagsFn,
    tagsValue,
    filteredTags,
    //filterTemplatesByOwnerFn:filterTemplatesByOwnerFn,
    filteredCreator,
    categories,
    //onChangeSwitchAllCategories:onChangeSwitchAllCategories,
    selectCreateCategoryFn,
  };

  return (
    <MailTemplatesContext.Provider
      value={{
        previewModal,
        shareModal,
        editModal,
        getSingleTemplate,
        tableTemplates,
        filterByCategory,
        categoriesTabs,
        activeCol,
        isActive,
        categories,
        categoryExtra,
        extraCategory,
        setExtraCategory,
        filterBar,
        copyAndCreateModal,
      }}
    >
      <TemplateMails history={props.history} />
    </MailTemplatesContext.Provider>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    business: state.auth.business,
    token: state.auth.token,
    businessUserId: state.auth.userID,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainMailTemplates);
