import React, { useState, useEffect, useContext } from 'react'

import {
  Button, Divider, Col, Input, Row, Select, Modal
} from 'antd';
import { v4 as uuidv4 } from 'uuid';

import JobAppContext from '../../context/JobAppContext';

function StageConfigurationModal({ handleOk, handleCancel, newStage, stageIndex, visible }) {

  const {
    assignedActivities,
    cuTes,
    games,
    filesRequests,
    videoInterviews,
    additionalQuestions,
    currentStage,
    stages,
    updateCurrentStage,
    updateCuTes,
    updateGames,
    updateVideoInterviews,
    updateAdditionalQuestions,
    updateFilesRequests,
    addStage,
    removeStage,
    updateStage,
  } = useContext(JobAppContext);

  /**
   * Lo lógica debiese ser la misma para cada eval. 
   * Se clona el objecto
   * Se modidica el estado clonado current, toAdd, toRemove...
   * Al guardar los cambios se actualiza el contexto y se limpian los datos del modal 
   * para que se actualicen con los nuevos cambios del contexto
   */

  const [stageDetail, setStageDetail] = useState(
    {
      id: null,
      name: undefined,
      required: false,
    }
  );

  const [stageCuTes, setStageCuTes] = useState({});
  const [stageGames, setStageGames] = useState({});
  const [stageVideoInterviews, setStageVideoInterviews] = useState({});
  const [stageAdditionalQuestions, setStageAdditionalQuestions] = useState({});
  const [stageFilesRequests, setStageFilesRequests] = useState({});

  useEffect(() => {
    // Truco flaite deep clone
    const cloneAdditionalQuestions = JSON.parse(JSON.stringify(additionalQuestions));
    setStageAdditionalQuestions(cloneAdditionalQuestions);

    const cloneStateGames = JSON.parse(JSON.stringify(games));
    setStageGames(cloneStateGames);

    const cloneStateCuTes = JSON.parse(JSON.stringify(cuTes));
    setStageCuTes(cloneStateCuTes);

    const cloneStateVideoInterview = JSON.parse(JSON.stringify(videoInterviews));
    setStageVideoInterviews(cloneStateVideoInterview);

    const cloneStateFilesRequests = JSON.parse(JSON.stringify(filesRequests));
    setStageFilesRequests(cloneStateFilesRequests);
  }, [cuTes, games, videoInterviews, additionalQuestions, visible, filesRequests]);

  useEffect(() => {
    if (visible == false) {
      setStageDetail(
        {
          id: null,
          name: undefined,
          required: true,
        }
      );
    }
  }, [visible])

  useEffect(() => {
    if (!newStage && stageIndex != -1) {
      setStageDetail(stages[stageIndex])
    }
  }, [newStage, stageIndex, visible])

  const onChangeStageName = (value) => {
    setStageDetail(oldState => ({ ...oldState, name: value }))
  };

  const saveUpdatedGames = () => {
    // Update stage evaluations
    updateGames('currentEvaluations', stageGames.currentEvaluations);
    updateGames('toUpdate', stageGames.toUpdate);
    updateGames('toAdd', stageGames.toAdd);
    updateGames('toRemove', stageGames.toRemove);
  }

  const saveUpdatedCutes = () => {
    // Update stage evaluations
    updateCuTes('currentEvaluations', stageCuTes.currentEvaluations);
    updateCuTes('toUpdate', stageCuTes.toUpdate);
    updateCuTes('toAdd', stageCuTes.toAdd);
    updateCuTes('toRemove', stageCuTes.toRemove);
  }

  const saveUpdatedVideoInterviews = () => {
    // Update stage evaluations
    updateVideoInterviews('currentEvaluations', stageVideoInterviews.currentEvaluations);
    updateVideoInterviews('toUpdate', stageVideoInterviews.toUpdate);
    updateVideoInterviews('toAdd', stageVideoInterviews.toAdd);
    updateVideoInterviews('toRemove', stageVideoInterviews.toRemove);
  }

  const saveUpdatedAdditionalQuestions = () => {
    // Update stage evaluations
    updateAdditionalQuestions('currentEvaluations', stageAdditionalQuestions.currentEvaluations);
    updateAdditionalQuestions('toUpdate', stageAdditionalQuestions.toUpdate);
    updateAdditionalQuestions('toAdd', stageAdditionalQuestions.toAdd);
    updateAdditionalQuestions('toRemove', stageAdditionalQuestions.toRemove);
  }

  const saveUpdatedFilesRequests = () => {
    // Update stage evaluations
    updateFilesRequests('currentEvaluations', stageFilesRequests.currentEvaluations);
    updateFilesRequests('toUpdate', stageFilesRequests.toUpdate);
    updateFilesRequests('toAdd', stageFilesRequests.toAdd);
    updateFilesRequests('toRemove', stageFilesRequests.toRemove);
  }

  const saveStage = () => {
    // Update stage
    if (newStage) {
      let newStage = { ...stageDetail };
      newStage.required = false;
      newStage.id = uuidv4();
      newStage.new = true;
      addStage(newStage, stageIndex)
    }
    else {
      updateStage(stageDetail, stageIndex);
      /*
      saveUpdatedGames();
      saveUpdatedCutes();
      saveUpdatedVideoInterviews();
      saveUpdatedAdditionalQuestions();
      */
    }
    handleCancel();
  };

  const deleteStage = () => {
    Modal.confirm({
      title: 'Cofirma para eliminar la etapa',
      content: 'Esta accion es irreversible',
      cancelText: 'Cancelar',
      okText: 'Confirmar',
      onOk: () => {
        removeStage(stageIndex);
        if (stageIndex == stages.findIndex(obj => obj.id === currentStage)) {
          updateCurrentStage('ACTIV')
        }
        handleCancel();
      }
    });
  };

  const evaluationDict = {
    'cute': stageCuTes,
    'game': stageGames,
    // Solo es 1 por etapa a pesar de que tengamos un array
    'videoInterview': stageVideoInterviews,
    'additionalQuestion': stageAdditionalQuestions,
    'filesRequest': stageFilesRequests,
  };

  const onDeleteEvaluation = (id, type) => {
    const stageEvaluations = evaluationDict[type];

    const currentEvaluations = [...stageEvaluations.currentEvaluations];
    const toUpdate = [...stageEvaluations.toUpdate];
    const toAdd = [...stageEvaluations.toAdd];
    const toRemove = [...stageEvaluations.toRemove];

    const currentIdx = currentEvaluations.findIndex(obj => obj.id === id);
    const toUpdateIdx = toUpdate.findIndex(obj => obj.id === id)
    const toAddIdx = toAdd.findIndex(obj => obj.id === id)

    // Siempre remover de update
    if (toUpdateIdx != -1) {
      toUpdate.splice(toUpdateIdx, 1);
      stageEvaluations.toUpdate = toUpdate
    }
    // Remover de toAdd si existe, si no agregar a toRemove
    if (toAddIdx != -1) {
      toAdd.splice(toAddIdx, 1);
      stageEvaluations.toAdd = toAdd;
    }
    else {
      toRemove.push({ ...currentEvaluations[currentIdx] });
      stageEvaluations.toRemove = toRemove;
    }
    // Remover de currentEvaluations
    if (currentIdx != -1) {
      currentEvaluations.splice(currentIdx, 1);
      stageEvaluations.currentEvaluations = currentEvaluations;
    }

    switch (type) {
      case 'game':
        setStageGames({ ...stageEvaluations });
        break
      case 'cute':
        setStageCuTes({ ...stageEvaluations });
        break
      case 'videoInterview':
        setStageVideoInterviews({ ...stageEvaluations });
        break
      case 'additionalQuestion':
        setStageAdditionalQuestions({ ...stageEvaluations });
        break
      
      case 'filesRequest':
        setStageFilesRequests({ ...stageEvaluations });
        break
    }
  };

  const onChangeEvaluation = (id, newStage, type) => {
    const stageEvaluations = evaluationDict[type];

    const currentEvaluations = [...stageEvaluations.currentEvaluations];
    const toAdd = [...stageEvaluations.toAdd];
    const toUpdate = [...stageEvaluations.toUpdate];

    const tmpCuTe = currentEvaluations.find(obj => obj.id === id);
    tmpCuTe.stage = newStage;

    const toAddIdx = toAdd.findIndex(obj => obj.id === id)
    const toUpdateIdx = toUpdate.findIndex(obj => obj.id === id)

    // No es posible estar en toAdd y toUpdate al mismo tiempo, si esta en toAdd, actualizar ese obj
    if (toAddIdx != -1) {
      toAdd[toAddIdx].stage = newStage;
    }
    // Si no esta en add hay que updatear
    if (toAddIdx == -1) {
      // Si no esta en update agregarlo caso contrario editar lo que ya existe
      toUpdateIdx == -1 ? toUpdate.push(tmpCuTe) : toUpdate[toUpdateIdx] = tmpCuTe;
    }

    stageEvaluations.currentEvaluations = currentEvaluations;
    stageEvaluations.toUpdate = toUpdate

    switch (type) {
      case 'game':
        setStageGames({ ...stageEvaluations });
        break
      case 'cute':
        setStageCuTes({ ...stageEvaluations });
        break
      case 'videoInterview':
        setStageVideoInterviews({ ...stageEvaluations });
        break
      case 'additionalQuestion':
        setStageAdditionalQuestions({ ...stageEvaluations });
        break
      case 'filesRequest':
        setStageFilesRequests({ ...stageEvaluations });
        break
    }

  }

  const EvaluationRow = ({ name, onChangeEvaluation, onDeleteEvaluation, groups, type, evaluation }) => {
    const icons = {
      'basal': 'https://genoma-assets.s3.us-east-2.amazonaws.com/adn.svg',
      'troncal': 'https://genoma-assets.s3.us-east-2.amazonaws.com/chip.svg',
      'complementary': 'https://genoma-assets.s3.us-east-2.amazonaws.com/puzzle.svg',
    }
    // Solo mostrar si en el contexto existe la evaluacion y ademas esta en la etapa actual
    const objDict = {
      'cute': cuTes,
      'game': games,
      'videoInterview': videoInterviews,
      'additionalQuestion': additionalQuestions,
      'filesRequest': filesRequests
    }

    const showEval = objDict[type].currentEvaluations.findIndex(obj => obj.id === evaluation.id && obj.stage === stageDetail.id);
    if (showEval == -1) {
      return null;
    };


    return (
      <Row type='flex' justify='space-around' style={{ margin: '10px 0px' }}>
        <Col sm={12} style={{ display: 'flex', alignItems: 'center' }}>
          {groups.map((elem, idx) => <img key={idx} style={{ width: 20, height: 20, marginRight: 5 }} src={icons[elem]} />)}
          {name}
        </Col>
        <Col sm={12}>
          <Row type='flex' justify='end'>
            <Select
              disabled={disableEvalEdit(evaluation)}
              value={evaluation.stage}
              placeholder='Mover de etapa'
              style={{ width: 200, paddingRight: 10 }}
              onChange={(val) => onChangeEvaluation(evaluation.id, val, type)} >
              {
                stages.map(obj =>
                (obj.id !== 'HIRED' && obj.id !== 'OTHER' &&
                  <Select.Option value={obj.id} key={obj.id}>
                    <span style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                      {obj.name}
                    </span>
                  </Select.Option>
                )
                )
              }
            </Select>
            <Button icon='delete' disabled={disableEvalEdit(evaluation)} style={!disableEvalEdit(evaluation) ? { color: '#FF4D4F', borderColor: '#FF4D4F' } : {}} onClick={() => onDeleteEvaluation(evaluation.id, type)} />
          </Row>
        </Col>
      </Row>
    );
  }

  const hasActivities = () => {
    return (
      stageCuTes?.currentEvaluations?.filter(obj=>obj.stage===stageDetail.id).length > 0 || 
      stageGames?.currentEvaluations?.filter(obj=>obj.stage===stageDetail.id).length > 0 ||
      stageVideoInterviews?.currentEvaluations?.filter(obj=>obj.stage===stageDetail.id).length > 0 ||
      stageAdditionalQuestions?.currentEvaluations?.filter(obj=>obj.stage===stageDetail.id).length > 0 ||
      stageFilesRequests?.currentEvaluations?.filter(obj=>obj.stage===stageDetail.id).length > 0
    )
  };

  const isGameRequired = (game) => {
    const assignedGame = assignedActivities.find(obj => obj.activity.code === game.code);
    let config = assignedGame?.configuration;
    if (config) {
      config = JSON.parse(config);
      return config.required;
    };
    return false;
  };

  const disableEvalEdit = (evaluation) => {
    return isGameRequired(evaluation);
  };

  const disableDelete = () => {
    if (stageDetail.id === 'INITIAL')   {
      return false;
    }
    else if (newStage || stageDetail.required || hasActivities() || stageDetail.id === 'ACTIV') {
      return true
    }
    return false
  };

  return (
    <Modal
      title="Configurar etapa"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      <div>
        <div style={{ fontSize: 14, fontWeight: 700 }}>
          Nombre etapa
        </div>
        <Input
          value={stageDetail?.name}
          style={{ margin: '10px 0px', maxWidth: '100%' }}
          onChange={event => onChangeStageName(event.target.value)}
        />
      </div>
      <div>
        {
          /*
            {!newStage ??
              <div style={{ fontSize: 14, fontWeight: 700, marginTop: 10 }}>
                Evaluaciones agregadas
              </div>
            }
    
            {stageAdditionalQuestions?.currentEvaluations && stageAdditionalQuestions.currentEvaluations.map((obj, idx) =>
              <EvaluationRow
                key={idx}
                name={'Preguntas adicionales'}
                groups={['complementary']}
                type={'additionalQuestion'}
                onChangeEvaluation={onChangeEvaluation}
                onDeleteEvaluation={onDeleteEvaluation}
                evaluation={obj}
              />
            )}
    
            {stageGames?.currentEvaluations && stageGames.currentEvaluations.map((obj, idx) =>
              <EvaluationRow
                key={idx}
                name={obj.activity}
                groups={['troncal']}
                type={'game'}
                onChangeEvaluation={onChangeEvaluation}
                onDeleteEvaluation={onDeleteEvaluation}
                evaluation={obj}
              />
            )}
    
            {stageCuTes?.currentEvaluations && stageCuTes.currentEvaluations.map((obj, idx) =>
              <EvaluationRow
                key={idx}
                name={obj.title}
                groups={['complementary']}
                type={'cute'}
                onChangeEvaluation={onChangeEvaluation}
                onDeleteEvaluation={onDeleteEvaluation}
                evaluation={obj}
              />
            )}
    
            {stageVideoInterviews?.currentEvaluations && stageVideoInterviews.currentEvaluations.map((obj, idx) =>
              <EvaluationRow
                key={idx}
                name={'Video Entrevista'}
                groups={['complementary']}
                type={'videoInterview'}
                onChangeEvaluation={onChangeEvaluation}
                onDeleteEvaluation={onDeleteEvaluation}
                evaluation={obj}
              />
            )}
            {stageFilesRequests?.currentEvaluations && stageFilesRequests.currentEvaluations.map((obj, idx) =>
              <EvaluationRow
                key={idx}
                name={obj.name}
                groups={['complementary']}
                type={'filesRequest'}
                onChangeEvaluation={onChangeEvaluation}
                onDeleteEvaluation={onDeleteEvaluation}
                evaluation={obj}
              />
          )}
        */
        }

        <Divider />
        <Row type='flex' justify='space-around'>
          <Col sm={12}>
            <Button
              onClick={deleteStage}
              style={{ color: disableDelete() ? '#CCCCCC' : '#FF4D4F', borderColor: disableDelete() ? '#CCCCCC' : '#FF4D4F' }}
              disabled={disableDelete()}>
              Eliminar etapa
            </Button>
          </Col>
          <Col sm={12}>
            <Row type='flex' key='delete' justify='end'>
              <Button key="back" onClick={handleCancel} style={{ marginRight: 10 }}>
                Cancelar
              </Button>

              <Button key="submit" type="primary" onClick={saveStage}>
                {newStage ? 'Crear' : 'Guardar cambios'}
              </Button>
            </Row>
          </Col>
        </Row>

      </div>
    </Modal>
  );
};

export default StageConfigurationModal;