import React from 'react';

import { Layout, Row, Col, Typography, Input, Tag, Button } from 'antd';
const { Title, Text } = Typography;
const { TextArea } = Input;

const InterviewList = ({ list, fnDelete, fnCopy, fnEdit, setShowEdit, setSpecificId }) => {
  return (
    <Layout className="layoutCustomInterview" style={{ background: 'white' }}>
      {
        list.length > 0 && (
          <Title level={4} style={{ margin: '0 0 20px 20px', color: '#5E5E5E' }}>
            Preguntas creadas
          </Title>
        )
      }
      {list.length > 0 && (
        list.map((item, index) => (
          <Row key={index} className='formNewQuestions'>
            <Col style={{ marginRight: '40px' }}>
              <Title level={4} style={{ color: '#5E5E5E' }} >Pregunta {index + 1}</Title>
              {/* {item.text.length > 50 ? (
                  <TextArea rows={4} value={item.text} disabled  />
                ) : (<Input value={item.text} disabled size='large' />)} */}
              <Row style={{ border: '1px solid #D9D9D9', padding: '5px', cursor: 'not-allowed' }}>
                <Text>{item.text}</Text>
              </Row>
            </Col>
            <Col style={{ marginRight: '40px' }}>
              <Title level={4} >Etiquetas</Title>
              {item.skill.map(tagItem => (
                <Tag key={tagItem} >{tagItem}</Tag>
              ))}
            </Col>
            <Col>
              <Title level={4}>Acciónes</Title>
              <Button icon='edit' style={{ border: 'none', color: '#A4A4A4' }} onClick={() => { setShowEdit(true); fnEdit(index, item.question_number); setSpecificId(index) }}></Button>
              <Button icon='delete' style={{ border: 'none', color: '#A4A4A4' }} onClick={() => fnDelete({ state: true, idQuestion: item.question_number })} />
              <Button icon='copy' style={{ border: 'none', color: '#A4A4A4' }} onClick={() => fnCopy(index)} />
            </Col>
          </Row>
        ))
      )}
    </Layout>
  );
}

export default InterviewList;