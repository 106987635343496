import React, { useContext } from 'react';

import { CandidateContext } from '../config/CandidateContext';
import Collapse from '../components/Collapse';
import CulturalReport from '../components/CulturalFitReport';
import CustomTests from '../components/CustomTests';
import InterviewKits from '../components/InterviewKits';
import LeadershipTrait from '../components/LeadershipComponents/LeadershipTrait';
import References from '../components/References';
import Trait from '../components/Trait';
import VideoEvaluation from '../components/VideoEvaluation';
import i18n from '../../../i18n';

const Evaluations = () => {
  const {
    getPersonalityTraits,
    getCognitiveTraits,
    getConductualTraits,
    getCulturalTraits,
    getLeadershipTraits,
    translationFile,
  } = useContext(CandidateContext);

  // Simple fix, this view needs a whole refactor
  const traits = [
    [getCognitiveTraits(), 'games__coginitives'],
    [getConductualTraits(), 'games__behavioral'],
    [getPersonalityTraits(), 'games__personality'],
    [getCulturalTraits(), 'Cultura Organizacional'],
    [getLeadershipTraits(), 'Liderazgo'],
  ].map(([traits, title]) =>
    traits.length ? (
      title.includes('games') ? (
        <Collapse icon="rocket" title={`${i18n.t(title, translationFile)}`}>
          <div className="traits-container">
            {traits.map((trait, i) =>
              <Trait
                trait={trait}
                withDetails={true}
                withDistribution={true}
              />
            )}
          </div>
        </Collapse>
      ) : (
        <Collapse icon="experiment" title={`${title}`}>
          {title.includes('Cultura') && traits[0].traits.length > 0 ? (
            <CulturalReport traits={traits[0].traits} mode="detailed" />
          ) :
            traits.map((trait, i) =>
              trait.type == 'leadership' ? (
                <LeadershipTrait
                  data={trait.traits?.map((item) => {
                    return {
                      code: item.trait_id.code,
                      score: item.score,
                    };
                  })}
                />
              ) : (
                null
              )
            )}
        </Collapse>
      )
    ) : null
  );

  return (
    <div className="new-modal-content__evaluations">
      {traits}
      <VideoEvaluation />
      <CustomTests />
      <References />
      <InterviewKits />
    </div>
  );
};

export default Evaluations;
