import React from 'react'
import { Card, Button, Tooltip, Typography } from 'antd'
import '../Feedback.scss'
import i18n from '../../../../i18n'

const CandidateIntro = (props) => {

  const { Meta } = Card
  const { Title } = Typography

  return (
    <Card
      className='candidate-intro'
      title={<Title level={4} >{props.fullName}</Title> }
      extra={props.shareFeedback ?
        <Tooltip title={i18n.t('feedback__share')}>
          <Button icon='share-alt' onClick={props.shareFeedback} />
        </Tooltip>
        : null
      }
      style={{ width: '70%', marginBottom: '10px' }}
    >
      <Meta title={i18n.t('feedback__genomawork__report')} />
      <p>{i18n.t('feedback__goal__paragraph')}</p>
    </Card>
  )
}

export default CandidateIntro
