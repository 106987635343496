import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Icon,
  Row,
  Select,
  Switch,
  Typography,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { questionDescription, questionIcon } from './constants';

import AvailabilityQuestion from './QuestionsTypes/AvailabilityQuestion';
import DefaultForm from './QuestionsTypes/Default';
import FileForm from './QuestionsTypes/File';
import IntForm from './QuestionsTypes/Int';
import JobAppContext from '../../../context/JobAppContext';
import SelectionForm from './QuestionsTypes/Selection';

const emptyQuestion = {
  type: '',
  question: '',
  mandatory: true,
  /*
  configuration: {
    additionals: {},
    alternatives: [
      { text: '', correct: false },
      { text: '', correct: false },
      { text: '', correct: false },
      { text: '', correct: false },
    ]
  },
  */
  number_from: 0,
  number_to: 0,
  alternatives: null,
  additionals: {},
  // alternatives y additionals deberian quedar como llaves independientes en las versiones nuevas
};

function NewQuestion({
  questionToEdit,
  addOrEditQuestion,
  onClose,
  hasAvailabilityQuestion,
  ...props
}) {
  const { saveQuestionTemplate, businessPermissions } =
    useContext(JobAppContext);
  const [currentType, setCurrentType] = useState('singleline');

  const { Option } = Select;
  const { Text } = Typography;

  useEffect(() => {
    if (questionToEdit != null) {
      setCurrentType(questionToEdit['type']);
    }
  }, [questionToEdit]);

  useEffect(() => {
    updateFields(questionToEdit);
  }, [questionToEdit, currentType]);

  const updateFields = (questionToEdit) => {
    const { setFieldsValue } = props.form;
    if (questionToEdit != null) {
      setCurrentType(questionToEdit['type']);
      // Esto setea field que no siempre existe y tira warning D:
      setFieldsValue({
        intFrom: questionToEdit.number_from,
        intTo: questionToEdit.number_to,
        question: questionToEdit.question,
        mandatory:
          questionToEdit.mandatory == undefined
            ? false
            : questionToEdit.mandatory,
        // Este campo no se usa pero el back lo requiere
        questionType: questionToEdit.type,
        configuration: questionToEdit.configuration,
      });

      if (
        questionToEdit['type'] === 'singleselection' ||
        questionToEdit['type'] === 'multiselection'
      ) {
        let alternatives;
        // Si vienen alternativas en el config entonces es un question template
        if (
          questionToEdit.configuration?.alternatives &&
          questionToEdit.configuration.alternatives.length > 0
        ) {
          alternatives = questionToEdit.configuration.alternatives;
        } else {
          alternatives = questionToEdit.alternatives;
        }
        alternatives.forEach((obj, idx) => {
          setFieldsValue({
            [`alternative[${idx}]`]: obj.text,
            [`correct[${idx}]`]: obj.correct,
          });
        });
      }
      if (questionToEdit['type'] === 'file') {
        setFieldsValue({
          fileDescription: questionToEdit.additionals.instructions,
          fileType: questionToEdit.additionals.accepted_extensions,
        });
      }
    }
  };

  const onChange = (value) => {
    setCurrentType(value);
  };

  const { getFieldsValue, getFieldDecorator, getFieldValue, setFieldsValue } =
    props.form;

  const getQuestionType = (type) => {
    const isSelection = type === 'singleselection' || type === 'multiselection';
    const maxLength = type === 'singleline' || 'int' ? 200 : 1500;

    if (isSelection) {
      return (
        <>
          <DefaultForm
            getFieldDecorator={getFieldDecorator}
            getFieldValue={getFieldValue}
            maxLength={200}
          />
          <SelectionForm
            type={type}
            getFieldDecorator={getFieldDecorator}
            getFieldValue={getFieldValue}
            setFieldsValue={setFieldsValue}
            questionToEdit={questionToEdit}
          />
        </>
      );
    } else if (type === 'int') {
      return (
        <>
          <DefaultForm
            getFieldDecorator={getFieldDecorator}
            getFieldValue={getFieldValue}
            maxLength={200}
          />
          <IntForm
            getFieldDecorator={getFieldDecorator}
            getFieldValue={getFieldValue}
          />
        </>
      );
    } else if (type === 'file') {
      return (
        <FileForm
          getFieldDecorator={getFieldDecorator}
          getFieldValue={getFieldValue}
        />
      );
    } else if (type === 'availability') {
      return (
        <AvailabilityQuestion
          getFieldDecorator={getFieldDecorator}
          getFieldValue={getFieldValue}
          setFieldsValue={setFieldsValue}
          questionToEdit={questionToEdit}
        />
      );
    } else if (type !== 'file') {
      return (
        <DefaultForm
          getFieldDecorator={getFieldDecorator}
          getFieldValue={getFieldValue}
          maxLength={maxLength}
        />
      );
    }
  };

  const formatAlternatives = (alternatives, correct) => {
    const altKeys = [...Array(alternatives.length).keys()];
    let formattedAlt = [];
    for (const x in altKeys) {
      if (alternatives[x] !== undefined && correct[x] !== undefined) {
        formattedAlt.push({ text: alternatives[x], correct: correct[x] });
      }
    }
    return formattedAlt;
  };
  const handleSubmit = (submitType) => {
    props.form.validateFields((err, values) => {
      if (!err) {
        const type = values.questionType;
        const alternatives = ['singleselection', 'multiselection'].includes(
          type
        )
          ? formatAlternatives(values.alternative, values.correct)
          : null;
        const additionals = ['file'].includes(type)
          ? {
              instructions: values.fileDescription,
              accepted_extensions: values.fileType,
            }
          : {};

        const newQuestionData = { ...emptyQuestion };

        newQuestionData.type = type;
        newQuestionData.question =
          values.type === 'file' ? values.fileName : values.question;
        //newQuestionData.configuration = { additionals: additionals, alternatives: alternatives };
        // Al parecer no se usa, peeeero el post requiere que exista
        newQuestionData.alternatives = alternatives;
        newQuestionData.additionals = additionals;
        newQuestionData.number_from = values.intFrom ? values.intFrom : 0;
        newQuestionData.number_to = values.intTo ? values.intTo : 0;
        newQuestionData.mandatory = values.mandatory ? values.mandatory : false;

        if (values.questionType === 'availability') {
          newQuestionData.configuration = {
            ...newQuestionData.configuration,
            availabilityType: values.availabilityType,
            schedule: values.schedule,
          };
        }

        // Preguntas editadas ya traen id y etapa
        if (questionToEdit) {
          newQuestionData.id = questionToEdit.id;
          newQuestionData.stage = questionToEdit.stage;
        }

        if (submitType === 'newQuestion') {
          // guardar pregunta
          // Add or edit question
          addOrEditQuestion(newQuestionData);
        } else if ('template') {
          // guardar plantilla
          // QuestionTemplate guarda additionals y alternatives en configuration
          const tempQuestionData = { ...newQuestionData };
          tempQuestionData.configuration = {
            additionals: additionals,
            alternatives: alternatives,
            availabilityType: values.availabilityType,
            schedule: values.schedule,
          };
          tempQuestionData.question_type = tempQuestionData.type;

          saveQuestionTemplate(tempQuestionData);
        }
        window.scrollTo(0, 0);
        onClose();
      }
    });
  };

  return (
    <Col xs={24}>
      <Card className="new-question-card">
        <div className="new-question-card__header">
          <Text strong>
            {questionToEdit ? 'Editar pregunta ' : 'Nueva pregunta'}
          </Text>
        </div>
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        <Form>
          <Form.Item label="Tipo de respuesta">
            {getFieldDecorator('questionType', {
              initialValue: 'singleline',
              onChange: onChange,
              rules: [
                {
                  required: true,
                  message: 'Por favor selecciona el tipo de respuesta',
                },
              ],
            })(
              <Select>
                {Object.keys(questionDescription).map((key) => (
                  <Option
                    value={key}
                    key={key}
                    disabled={
                      key === 'availability' &&
                      hasAvailabilityQuestion &&
                      !questionToEdit
                    }
                  >
                    <Icon
                      type={questionIcon[key]}
                      style={{ marginRight: 10 }}
                    />
                    {questionDescription[key]}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item>
            <Text type="secondary" style={{ marginRight: 10 }}>
              {getFieldValue('mandatory') ||
              getFieldValue('mandatory') == undefined
                ? 'Requerido'
                : 'Opcional'}
            </Text>
            {getFieldDecorator('mandatory', {
              defaultChecked: true,
              initialValue: true,
            })(<Switch checked={getFieldValue('mandatory') ? true : false} />)}
          </Form.Item>
          {getQuestionType(currentType)}
          <Form.Item>
            <Row type="flex" justify="space-between" style={{ marginTop: 10 }}>
              <Button onClick={onClose}>Cancelar</Button>
              <Col>
                {businessPermissions.can_manage ||
                businessPermissions.business_admin ? (
                  <Button
                    disabled={
                      !businessPermissions.can_manage &&
                      !businessPermissions.business_admin
                    }
                    onClick={() => handleSubmit('template')}
                  >
                    Guardar como plantilla
                  </Button>
                ) : null}
                <Button
                  type="primary"
                  onClick={() => handleSubmit('newQuestion')}
                  style={{ marginLeft: 10 }}
                >
                  Guardar
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Card>
    </Col>
  );
}

const NewQuestionForm = Form.create({ name: 'new-question-form' })(NewQuestion);

export default NewQuestionForm;
