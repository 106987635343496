import React, { useState, useEffect, createContext } from 'react';

import {
  SCORES_DATA,
  KO_DATA,
  STATS,
  HEADER,
  JOBAPPS,
  CANDIDATES,
  HIRING,
  EVALUATIONS_RESULTS,
  OVERVIEW,
  DIVERSITY,
  SATISFACTION,
} from '../assets/models';

import { countryCodeGeoMap, countryCode } from '../assets/utils';

export const AnalyticsContext = createContext();

export const AnalyticsProvider = ({ children, auth, actions, ...props }) => {
  const [user, setUser] = useState(auth);
  const [jobAppId, setJobAppId] = useState((_) => {
    const queryString = new URLSearchParams(window.location.search);

    return queryString.get('jobappid') || null;
  });

  // this state is going to save the first fetch because
  // we dont want to request the backend everytime that user clicks "Todos los procesos"
  const [initialCache, setInitialCache] = useState({});
  const [isCached, setIsCached] = useState(false);

  const [peopleAnalytics, setPeopleAnalytics] = useState({
    header: HEADER,
    candidates_stats: STATS,
    averages: '',
    hiring: '',
    scores_data: SCORES_DATA,
    job_apps: JOBAPPS,
    ko_data: KO_DATA,
  });

  const [sectionsData, setSectionsData] = useState({
    overview: OVERVIEW,
    candidates: CANDIDATES,
    hiring: HIRING,
    evaluations: EVALUATIONS_RESULTS,
    diversity: DIVERSITY,
    satisfaction: SATISFACTION,
  });

  const [filterParams, setFilterParams] = useState({
    date: [],
    hired: false,
    country: null,
    gender: null,
    has_disability: false,
    age: [],
    education_level: null,
    fit: null,
    rent: [],
  });
  /**
   * Other options are
   * overview, candidates, hiring, evaluations, diversity, efficiency
   */
  const [currentSection, setCurrentSection] = useState('overview');

  const [isLoading, setIsLoading] = useState((_) => true);

  const getProcessesCount = () => {
    const path = 'gamesandtests/joblistingcount/';
    actions.fetchData(auth.token, path).then((res) => {
      const { jobs_open, jobs_closed, jobs_draft } = res;
      setPeopleAnalytics((s) => ({
        ...s,
        header: {
          ...s.header,
          overview: {
            ...s.header.overview,
            ACTIV: jobs_open,
            INACT: jobs_closed,
            OTHER: jobs_draft,
          },
        },
      }));
    });
  };

  useEffect(() => {
    getProcessesCount();
    const hash = window.location.hash?.slice(1);
    if (
      [
        'overview',
        'candidates',
        'hiring',
        'evaluations',
        'diversity',
        'satisfaction',
      ].includes(hash)
    ) {
      setCurrentSection(hash);
    }
  }, [window.location.hash]);

  const handleFilter = (name, value) => {
    if (name === 'date' && value !== '') {
      setFilterParams((prevFilterParams) => ({
        ...prevFilterParams,
        date: value,
      }));
    } else {
      setFilterParams(value);
    }
    if (name === 'date' && value[0] === '') {
      setFilterParams({
        ...filterParams,
        date: [],
      });
    }
  };

  const handleJobApp = (id) => {
    if (id && id !== jobAppId) {
      setIsLoading(true);
    }
    props.history.push(
      id ? `/peopleanalytics?jobappid=${id}` : '/peopleanalytics'
    );
    setJobAppId(id);
  };

  const recoverFirstFetch = () => {
    setJobAppId(null);
  };

  const getData = async () => {
    setIsLoading(true);
    const tmp_data = {};
    const sectionNames = Object.keys(sectionsData);

    let base_path = 'stats/jobapplications/';
    if (jobAppId) {
      base_path = `stats/jobapplications/${jobAppId}/`;
    }

    const queryParams = [];
    Object.keys(filterParams).forEach((param) => {
      const paramValue = filterParams[param];
      if (paramValue) {
        queryParams.push(`${param}=${paramValue}`);
      }
    });
    const queryString = queryParams.join('&');
    const sectionResponse = await actions.fetchData(
      auth.token,
      `${base_path}?data=${currentSection}&${queryString}`,
      null,
      'GET'
    );
    const newSectionsData = { ...sectionsData };
    newSectionsData[currentSection] = sectionResponse;
    setSectionsData(oldSectionData => ({...oldSectionData , [currentSection]: sectionResponse}));
    setIsLoading(false);
    const jobapps_path =
      'gamesandtests/processlist/?format=json&page_size=10000';
    if (!isCached) {
      const job_apps = await actions.fetchData(auth.token, jobapps_path);

      tmp_data.job_apps = { ...job_apps };
      setInitialCache(tmp_data);
      setIsCached(true);
    }
    setPeopleAnalytics((old) => ({ ...old, ...tmp_data }));
  };

  useEffect(() => {
    if (
      !jobAppId &&
      isCached &&
      !Object.values(filterParams).some(
        (elem) => elem === undefined || elem === null
      )
    ) {
      setPeopleAnalytics(initialCache);
      return;
    }

    getData();
  }, [jobAppId, filterParams, currentSection]);

  const values = {
    user,
    jobAppId,
    peopleAnalytics,
    currentSection,
    isLoading,
    sectionsData,
    setCurrentSection,
    handleJobApp,
    recoverFirstFetch,
    handleFilter,
  };
  return (
    <AnalyticsContext.Provider value={values}>
      {children}
    </AnalyticsContext.Provider>
  );
};
