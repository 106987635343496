import React from 'react';
import '../JobListing.scss';

const YoutubeVideo = ({ linkId }) => {
  return (
    <div className='jl-video__frame'>
      <iframe credentialless='' width="720" height="405" src={`https://www.youtube-nocookie.com/embed/${linkId}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  )
}

export default YoutubeVideo;
