import React, { useState, useEffect } from 'react';
import i18n from '../../../i18n';

const Reference = ({ reference, i }) => {
  const { name, organization, email, phone, relation, answer } = reference;

  return (
    <div className="report-pdf-reference protected-div">
      <div className="report-pdf-reference__header">
        <h6 style={{ fontSize: 14 }}>
          <strong>{i18n.t('candidate_pdf__work_reference', {ns: 'candidatePdf'})} N-{i + 1}</strong>
        </h6>
        <p>
          <span>
            {name} - {organization}
          </span>
        </p>
        <p>
          <strong>{i18n.t('candidate_pdf__laboral_relationship', {ns: 'candidatePdf'})}</strong>: <span>{relation}</span>
        </p>
        <p>
          <strong>{i18n.t('candidate_pdf__email', {ns: 'candidatePdf'})}</strong>: <span>{email}</span>
        </p>
        <p>
          <strong>{i18n.t('candidate_pdf__phone', {ns: 'candidatePdf'})}</strong>: <span>{phone}</span>
        </p>
        <h6>
          <strong>Respuestas</strong>:
        </h6>
      </div>
      <div className="report-pdf-reference__answers">
        <ul>
          {answer?.length > 0 ? (
            answer.map((question, i) => (
              <li key={i}>
                <p>
                  <strong>{i + 1}.</strong> {question.question}
                </p>
                <p style={{ marginTop: '0.5rem' }}>
                  -{['singleselection', 'multiselection'].includes(question.type)
                    ? parseInt(question.answer) < question?.alternatives?.length
                      ? question.alternatives[question.answer].text
                      : i18n.t('candidate_pdf__answer_not_available', {ns: 'candidatePdf'})
                    : question.answer}
                </p>
              </li>
            ))
          ) : (
            <li>{i18n.t('candidate_pdf__not_info', {ns: 'candidatePdf'})}</li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Reference;
