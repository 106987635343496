import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Sentry from '@sentry/browser';

import * as actionCreators from '../actions/data';
import { SERVER_URL } from '../utils/config';

import {
  Button,
  Col,
  Dropdown,
  Icon,
  Input,
  Form,
  List,
  message,
  Modal,
  Popover,
  Row,
  Typography,
  Upload,
} from 'antd';

import MceEditor from '../containers/BusinessDashboard/NewProcessFormV2/components/mceeditor/MceEditor';
import i18n from '../i18n';

const { Paragraph, Text } = Typography;

function SendEmailForm({actions, business, closeModal, form, selectedPersonalUsers, setTemplates, templates, token, visible}) {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;

  const captchaRef = useRef(null);

  const [newTemplateModalVisible, setNewTemplateModalVisible]= useState(false);
  const [newTemplateName, setNewTemplateName] = useState('');
  const [sending, setSending] = useState(false);
  const [attachedFiles, setAttachedFiles] = useState([]);

  const handleAttachFile = (info) => {
    const { file } = info;
    setAttachedFiles((prevData) => {
      const uid = file.uid;
      let fileList = prevData.filter(x => x.uid !== uid);
      if (info.file.status !== 'removed') {
        const fileData = {
          uid: file.uid,
          name: file.name,
          size: file.size,
          type: file.type,
          ...file.response,
        };
        fileList.push(fileData);
      }
      return fileList;
    })
  };

  const requiredFieldsFilled = getFieldValue('subject') && getFieldValue('body');

  const handleSendMail = async () => {
    const url = '/api/v1/gamesandtests/sendmail/';
    const selectedPersonalUsersInfo = Object.values(selectedPersonalUsers);
    const s = selectedPersonalUsersInfo.length === 1 ? '' : 's';

    setSending(true);
    try {
      // Get captcha token first
      const captchaToken = await captchaRef.current.executeAsync();

      const data = {
        users: selectedPersonalUsersInfo,
        cc: getFieldValue('cc')?.filter(item => item), // Filter empty strings
        bcc: getFieldValue('bcc')?.filter(item => item), // Filter empty strings
        subject: getFieldValue('subject'),
        body: getFieldValue('body'),
        attachments: attachedFiles,
      };
      const response = await actions.fetchAndReturn(
        token, url, 'POST', (data), { Captcha: captchaToken }
      );
      if (response.status < 400) message.success(`Mensaje${s} enviado${s}`);
    } catch (err) {
      Sentry.captureException(err);
      message.error(`No se pudo enviar ${s ? 'los' : 'el'} mensaje${s}`);
    }
    setSending(false);
    closeModal();
  };

  const changeTemplate = template => setFieldsValue(
    { subject: template.subject, body: template.template }
  );

  const saveTemplate = () => {
    const url = `/api/v1/accounts/business/${business.id}/mailtemplates/`;
    const data = {
      name: newTemplateName,
      subject: getFieldValue('subject'),
      template: getFieldValue('body'),
    };
    actions
      .fetchAndReturn(token, url, 'POST', data )
      .then(response => {
        if (response.status < 400){
          setTemplates((prevTemplates) => [...prevTemplates, response.body]);
          setNewTemplateName('');
          setNewTemplateModalVisible(false);
          message.success('Plantilla guardada');
        }
      })
      .catch(e => {
        Sentry.captureException(e);
        setNewTemplateName('');
        setNewTemplateModalVisible(false);
        message.error('No se pudo guardar plantilla');
      });
  };

  const insertNameKey = () => {
    const body = getFieldValue('body');
    const newBody = body.indexOf('</p>') > 0 ? `${body.slice(0, body.length - 4)} --name-- </p>` : '<p>--name-- </p>';
    setFieldsValue({ body: newBody });
  }

  return (
    <Modal
      footer={[
        <Button
          onClick={() => {
            closeModal();
            setSending(false);
          }}
          type="secondary"
        >
          Cancelar
        </Button>,
        <Button
          loading={sending}
          onClick={handleSendMail}
          disabled={!requiredFieldsFilled}
          type="primary"
        >
          Enviar
        </Button>
      ]}
      onCancel={closeModal}
      title={(
        <>
          <Text strong>Enviar correo</Text>
          <Paragraph style={{ marginBottom: 0 }}>
            {Object.keys(selectedPersonalUsers).length} candidato{Object.keys(selectedPersonalUsers).length === 1 ? '' : 's'} seleccionados
          </Paragraph>
        </>
      )}
      visible={visible}
      width="60%"
    >
      <Row>
        <Form.Item
          label={(<Text style={{ fontSize: '16px' }}>De</Text>)}
        >
          {getFieldDecorator("from", { initialValue: 'no-reply@genoma.work' })(
            <Input disabled />
          )}
        </Form.Item>
      </Row>
      <Row>
        <Form.Item label="CC">
          {getFieldDecorator("cc", {
            rules: [
              {
                required: false,
                type: "email",
                message: "Ingresa un email válido."
              }
            ],
          })(<Input />)}
        </Form.Item>
      </Row>
      <Row>
        <Form.Item label="CCO">
          {getFieldDecorator("bcc", {
            rules: [
              {
                required: false,
                type: "email",
                message: "Ingresa un email válido."
              }
            ],
          })(<Input />)}
        </Form.Item>
      </Row>
      <Row>
        <Form.Item
          label={(<><Text type='danger'>*</Text> Asunto</>)}
          required
        >
          {getFieldDecorator("subject", {
            rules: [
              {
                required: true,
                message: 'Campo obligatorio',
              }
            ],
          })(<Input />)}
        </Form.Item>
      </Row>
      <Row>
        <Form.Item
          label={(<><Text type='danger'>*</Text> Contenido</>)}
          required
        >
          {getFieldDecorator("body", {
            initialValue: '',
            rules: [
              {
                required: true,
                message: 'Campo obligatorio',
              }
            ],
            valuePropName: 'body',
          })(
            <MceEditor
              disabled={false}
              handleChange={
                (fieldName) => (event) => setFieldsValue({ [fieldName]: event.target.value })
              }
              name="body"
              readOnlyBody=""
              token={token}
            />
          )}
        </Form.Item>
      </Row>
      <Row type="flex">
        <Col style={{ marginRight: '15px' }}>
          <Upload
            accept=".pdf,.doc,.docx,.ppt,.pptx,.xlx,.xlsx"
            name="attachments"
            action={`${SERVER_URL}/api/v1/gamesandtests/fileupload/`}
            headers={{ Authorization: `Token ${token}` }}
            onChange={(info) => {
              if (info.file.status === 'uploading') {
                const currentFileListSize = info.fileList.reduce(
                  (x, y) => x + y.size,
                  0
                );
                if (currentFileListSize >= 10485760) {
                  message.error(
                    'Tamaño máximo del total de archivos de 10 MB excedido.'
                  );
                } else if (info.fileList.length > 3) {
                  message.error('Solo puedes adjuntar un máximo de 3 documentos.');
                } else {
                  handleAttachFile(info);
                }
              } else if (info.file.status === 'done') {
                message.success(`${info.file.name} subido exitosamente`);
                handleAttachFile(info);
              } else if (info.file.status === 'error') {
                message.error(`${info.file.name} no se pudo subir`);
              } else if (info.file.status === 'removed') {
                handleAttachFile(info);
              }
            }}
            fileList={attachedFiles}
          >
            <Button><Icon type="paper-clip" /> {i18n.t('profile__add_files', {ns: 'candidateProfile'})}</Button>
          </Upload>
        </Col>
        <Col style={{ marginRight: '15px' }}>
          <Button
            disabled={!requiredFieldsFilled}
            onClick={() => setNewTemplateModalVisible(true)}
          >
            Guardar como plantilla
          </Button>
          <Modal
            cancelText="No"
            okText="Si"
            okType="primary"
            onCancel={() => {
              setNewTemplateModalVisible(false);
              setNewTemplateName('');
            }}
            onOk={saveTemplate}
            title="Guardar plantilla"
            visible={newTemplateModalVisible}
          >
            <Input
              onChange={(event) => setNewTemplateName(event.target.value)}
              placeholder="Nombre de la plantilla"
            />
          </Modal>
        </Col>
        <Col style={{ marginRight: '15px' }}>
          <Popover
            content={
              <div style={{ height: '40vh', overflowY: "scroll" }}>
                {templates?.sort(
                  (a, b) => a.name.localeCompare(b.name)
                ).map((template, i) => (
                  <List.Item
                    key={i}
                    onClick={() => changeTemplate(template)}
                    style={{ cursor: 'pointer' }}
                  >
                    <List.Item.Meta title={template.name} />
                  </List.Item>
                ))}
              </div>
            }
            title={<Text strong>Mis plantillas</Text>}
            trigger="click"
            width={350}
          >
            <Button disabled={!templates.length}>
              <Icon type="container" /> Plantillas de mensajes
            </Button>
          </Popover>
        </Col>
        <Col>
          <Dropdown
            overlay={
              <div style={{ display: 'grid' }}>
                <Button
                  key='0'
                  type="secondary"
                  onClick={insertNameKey}
                >
                  Insertar Nombre
                </Button>
              </div>
            }
          >
            <Button icon="down">Insertar</Button>
          </Dropdown>
        </Col>
        <ReCAPTCHA
          onChange={() => {}}
          ref={captchaRef}
          sitekey={process.env.REACT_APP_CAPTCHA_API_KEY}
          size='invisible'
        />
      </Row>
    </Modal>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch
  };
};

const mapStateToProps = state => {
  return {
    business: state.auth.currentBusiness,
    token: state.auth.token,
  };
};

const SendEmailComponent = Form.create({ name: 'mailForm' })(SendEmailForm);
export default connect(mapStateToProps, mapDispatchToProps)(SendEmailComponent);
