import React from 'react';
import { Row, Col} from 'antd';
import Trait from '../components/Trait';

import i18n from '../../../i18n';

const config = {
  personality: {
    title: i18n.t('candidate_pdf__personality_trait', { ns:'candidatePdf' }),
    description:
      i18n.t('candidate_pdf__personality_traits_text', { ns:'candidatePdf' }),
    img: 'https://genoma-assets.s3.us-east-2.amazonaws.com/personality.svg',
  },
  cognitive: {
    title: i18n.t('candidate_pdf__cognitive_trait', { ns:'candidatePdf' }),
    description:
      i18n.t('candidate_pdf__cognitive_traits_text', { ns:'candidatePdf' }),
    img: 'https://genoma-assets.s3.us-east-2.amazonaws.com/cognitive.svg',
  },
  behavioral: {
    title: i18n.t('candidate_pdf__behavioral_trait', { ns:'candidatePdf' }),
    description:
      i18n.t('candidate_pdf__behavioral_traits_text', { ns:'candidatePdf' }),
    img: 'https://genoma-assets.s3.us-east-2.amazonaws.com/behavioral.svg',
  },
};

const TraitsPage = ({ type, traits = [], showHeader }) => {
  // showHeader prop is used to show the header in the PDF and hide it in the preview

  return (
    <div className="report-pdf-traits-page">
      <header
        className={`report-pdf-traits-page__header ${
          showHeader ? 'show-header' : ''
        }`}
      >
        <div className="report-pdf-traits-page__header__texts">
          <p>
            <strong>{config[type].title}</strong>
          </p>
          <p className="t12" style={{ lineHeight: 1.2 }}>
            {config[type].description}
          </p>
        </div>
        <img src={config[type].img} alt="" />
      </header>
      <Row
        type="flex"
        className="report-pdf-traits-page__content"
        gutter={[16, 32]}
      >
        {traits.filter(({ type }) => type !== 'leadership').map((trait, idx) => (
          <Col xs={12} key={idx}>
            <Trait trait={trait} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default TraitsPage;
