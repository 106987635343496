import React from "react";
import PropTypes from "prop-types";
import { Input, Typography, Col, Row } from 'antd';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../../../../../../../../../actions/trait';
import { connect } from 'react-redux';

const { Text } = Typography;
class TraitRelevance extends React.Component {

    handleTraitRelevanceChange = event => {
        let measured = { ...this.props.measuredTraits[this.props.trait_id] }
        const prevPoints = measured.points
        let newValue = event.target.value && event.target.value > 0 ? parseInt(event.target.value) : 0
        newValue = newValue > 32767 ? prevPoints : newValue
        measured['points'] = newValue
        const diff = newValue - prevPoints
        this.props.actions.updateMeasured(measured, diff)
    };

    getPercent = () => {
        if (this.props.measuredTraits && this.props.totalPoints !== 0) {
            return Math.round((this.props.measuredTraits[this.props.trait_id]['points'] * 100 / this.props.totalPoints) * 100) / 100
        } else {
            return 0
        }
    }

    render() {
        const { classes, trait_id, measuredTraits } = this.props;

        return (
            <React.Fragment>
                <Row type="flex" justify="end" align="middle">
                    <Col xs={16} sm={16} style={{ display: 'flex', justifyContent: 'end', alignContent: 'middle' }}>
                            <Input
                                value={measuredTraits && Math.round(Number(measuredTraits[trait_id]['points'])).toString()}
                                onChange={this.handleTraitRelevanceChange}
                                name="personweight"
                                id="personweight"
                                type="number"
                                suffix={'%'}
                                disableUnderline
                            />
                    </Col>
                    <Col xs={8} sm={8} style={{ display: 'flex', justifyContent: 'end' }}>
                        <Text strong>{this.getPercent()}%</Text>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

TraitRelevance.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        measuredTraits: state.trait.measuredTraits,
        totalPoints: state.trait.totalPoints
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        actions: bindActionCreators(actionCreators, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TraitRelevance);
