import React, { useContext } from "react";
import JobAppContext from "../../context/JobAppContext";
import { Typography } from "antd";
import CandidatePath from "../../../../../components/CandidatePath/CandidatePath";

function CandidatePathContainer() {
  const { activities, additionalQuestions, business, cuTes, candidateForm, filesRequests, games, videoInterviews, jobApplication, stages, colorTheme } = useContext(JobAppContext);
  
  const logo = jobApplication.is_confidential && business.confidential_logo ? business.confidential_logo : business.logo;
  const theme = jobApplication.is_confidential && business.confidential_color_theme ? business.confidential_color_theme : colorTheme;

  return (
    <>
      <div style={{ padding: 20, background: '#FFFFFF' }}>
        <Typography.Text>
          Este es el recorrido que verán tus candidatos para completar tu proceso
          de selección.
        </Typography.Text>
      </div >
      <CandidatePath
        pathItems={[
          // FORM
          {
            code: 'FORM',
            icon: "FORM",
            stage: stages[0].id,
            time_estimated: 1,
            translation: candidateForm.translation
          },
          ...additionalQuestions.currentEvaluations.map((obj) => {
            return { ...obj, code: "KO", icon: 'KO', translation: activities.find(obj => obj.code === 'KO')?.translation };
          }),
          ,
          ...cuTes.currentEvaluations.map((obj) => {
            if (
              obj.cute_type === "KNOWLEDGE" && 
              Object.keys(obj.questions).includes('cute_type') && 
              obj.questions.cute_type === 'SITUATIONAL'
            ) {
              return { ...obj, code: "CUTE", icon: 'thumbs-sjt.png' };
            }
            return { ...obj, code: "CUTE", icon: 'cute.svg' };
          }),
          ...filesRequests.currentEvaluations.map((obj) => {
            return { ...obj, code: "DOCR",  time_estimated: 2, icon: 'candidate-docs.svg'};
          }),
          ...games.currentEvaluations,
          ...videoInterviews.currentEvaluations.map((obj) => {
            return {
              ...obj,
              code: "VINT",
              icon: 'path-icon-video.svg',
              translation: activities.find(obj => obj.code === 'VINT')?.translation,
              stage: obj.stage,
            };
          }),
        ]}
        src={logo}
        businessSlug={business.slug}
        jobApplication={jobApplication}
        stages={stages}
        preview={true}
        colorTheme={theme}
      />
    </>
  );
}

export default CandidatePathContainer;
