import React, { useContext, useEffect, useState } from 'react';
import {
  Input,
  Button,
  Divider,
  Typography,
  Tooltip,
  Row,
  Layout,
  Icon,
  Collapse,
  Col,
  Switch,
  Upload,
} from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { SERVER_URL } from '../../utils/config';

import { customTestContext } from '../CustomTest/CustomTestCreate/context/CustomTestContext';

import MceEditor from '../BusinessDashboard/TemplatesMails/components/MceEditor';

const { Text } = Typography;
const { Panel } = Collapse;
const { TextArea } = Input;

const Step2 = () => {
  const {
    configStep2,
    setConfigStep2,
    questionsContext,
    setQuestionsContext,
    scoreConfig,
    setScoreConfig,
    questionNumber,
    setQuestionNumber,
  } = useContext(customTestContext);

  const [fileLists, setFileLists] = useState(questionsContext.map((page) => page.image ? [page.image] : []))

  const addPage = () => {
    const newPage = {
      component: 'page',
      label: `Situación ${questionsContext.length + 1}`,
      description: '',
      image: '',
      _uid: uuidv4(),
      fields: [
        {
          component: 'SJT_GW',
          label: '¿Cuál crees que es la mejor y la peor forma de abordar esta situación?',
          image: '',
          type: 'SJT_GW',
          _uid: uuidv4(),
          required: true,
          options: [
            { component: "option", label: "", _uid: uuidv4() },
            { component: "option", label: "", _uid: uuidv4() },
            { component: "option", label: "", _uid: uuidv4() },
            { component: "option", label: "", _uid: uuidv4() },  
          ],
        },
      ],
    };
    setQuestionsContext([
      ...questionsContext,
      newPage,
    ]);
    setScoreConfig({
      ...scoreConfig,
      correct_answers: {
        ...scoreConfig.correct_answers,
        [newPage.fields[0]._uid]: {
          type: 'SJT_GW',
          value: newPage.fields[0].options.reduce((acc, option) => {
            acc[option._uid] = 0;
            return acc;
          }, {}),
        }
      }
    })
  };
  const deletePage = (id, indexPage) => {
    setQuestionNumber(
      questionNumber - questionsContext[indexPage].fields.length
    );
    const newPages = questionsContext.filter((page) => page._uid !== id);
    setQuestionsContext(newPages);
  };
  const changeAlternativeValue = (indexPage, indexOption, e) => {
    const optionUuid = questionsContext[indexPage].fields[0].options[indexOption]._uid;
    const questionUuid = questionsContext[indexPage].fields[0]._uid;

    setScoreConfig({
      ...scoreConfig,
      correct_answers: {
        ...scoreConfig.correct_answers,
        [questionUuid]: {
          ...scoreConfig.correct_answers[questionUuid],
          value: {
            ...scoreConfig.correct_answers[questionUuid].value,
            [optionUuid]: e.target.value,
          }
        }
      },
    });
  };
  const addAlternative = (indexPage) => {
    let uuid = uuidv4();
    const questionUuid = questionsContext[indexPage].fields[0]._uid;
    questionsContext[indexPage].fields[0].options = [
      ...questionsContext[indexPage].fields[0].options,
      {
        component: 'option',
        label: '',
        _uid: uuid,
      },
    ];
    setQuestionsContext([...questionsContext]);
    setScoreConfig({
      ...scoreConfig,
      correct_answers: {
        ...scoreConfig.correct_answers,
        [questionUuid]: {
          ...scoreConfig.correct_answers[questionUuid],
          value: {
            ...scoreConfig.correct_answers[questionUuid].value,
            [uuid]: 0,
          }
        }
      },
    });
  };
  const deleteAlternative = (page, option) => {
    const optionUuid = questionsContext[page].fields[0].options[option]._uid;
    questionsContext[page].fields[0].options.splice(option, 1);
    setQuestionsContext([...questionsContext]);

    const questionUuid = questionsContext[page].fields[0]._uid;

    delete scoreConfig.correct_answers[questionUuid].value[optionUuid];
    setScoreConfig({ ...scoreConfig });
  };
  const setRequired = (page, checked) => {
    questionsContext[page].fields[0].required = checked;
    setQuestionsContext([...questionsContext]);
  };
  const handleChangeCandidateTitle = (e) => {
    setConfigStep2({
      ...configStep2,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeAlternative = (page, option, e) => {
    questionsContext[page].fields[0].options[option].label =
      e.target.value;
    setQuestionsContext([...questionsContext]);
  };
  const handleChangeInput = (indexPage, e) => {
    questionsContext[indexPage][e.target.name] = e.target.value;
    setQuestionsContext([...questionsContext]);
  };
  const handleMcEditorInstructions = (name) => (e) => {
    configStep2.questions.data[0].fields[0].paragraphs = [e.target.value];
    setConfigStep2({ ...configStep2 });
  };

  const handleUploadFile = (info, indexPage) => {
    if (info.file.status === "uploading") {
      setFileLists((fileLists) => {
        const newFileLists = [...fileLists];
        newFileLists[indexPage] = [info.file];
        return newFileLists;
      });
    }
    if (info.file.status === "done") {
      questionsContext[indexPage].image = { 
        name: info.file.name, 
        url: info.file.response.location, 
        uid: info.file.uid 
      };
      setQuestionsContext([...questionsContext]);
    }
    if (info.file.status === "removed") {
      fileLists[indexPage] = []
      setFileLists(fileLists)
      questionsContext[indexPage].image = '';
      setQuestionsContext([...questionsContext]);
    }
  };

  useEffect(() => {
    questionsContext.length === 0 && addPage();
  }, []);

  return (
    <Layout className="new-custom-test__frame__step-by-step__step2">
       <Row className="new-custom-test__frame__step-by-step__step2__fields">
        <span>*</span>
        <Text>Nombre de la prueba para evaluados</Text>
        <Tooltip
          placement="topLeft"
          title="Nombre del test mostrado a los evaluados. Modificaciones en este campo no se reflejarán en el nombre del test mostrado a los colaboradores."
        >
          <Icon type="question-circle" />
        </Tooltip>
      </Row>
      <Input
        className="new-custom-test__frame__step-by-step__step2__input"
        name="candidateTitle"
        value={configStep2.candidateTitle}
        onChange={handleChangeCandidateTitle}
        placeholder="Conocimientos de Microsoft Excel"
      />
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        className="new-custom-test__frame__step-by-step__step2__instruction-field"
      >
        <Text>
          <span>*</span>
          Instrucciones
        </Text>
      </Row>
      <MceEditor
        placeholder="Instrucciones para la prueba"
        name="instruction"
        text={configStep2.questions.data[0].fields[0].paragraphs[0]}
        handleChange={handleMcEditorInstructions}
      />
      {questionsContext.map((page, indexPage) => (
        <Collapse
          key={page._uid}
          className="new-custom-test__frame__step-by-step__step2__collapse-page"
        >
          <Panel header={`Situación ${indexPage + 1}`}>
            <Row
              type="flex"
              className="new-custom-test__frame__step-by-step__step2__collapse-page__question__category"
              gutter={[0, 10]}
              justify="end"
            >
              <Col xs={24} sm={12}>
                <Row type="flex" align="middle" justify="end">
                  <Switch
                    unCheckedChildren="Obligatoria"
                    checkedChildren="Obligatoria"
                    checked={page.fields[0].required ?? false}
                    onChange={(e) =>
                      setRequired(indexPage, e)
                    }
                  />
                  {indexPage > 2 && 
                    <Icon
                    style={{
                      fontSize: '18px',
                      color: '#A4A4A4',
                      margin: '0 0 0 10px',
                    }}
                    type="delete"
                    onClick={() => deletePage(page._uid, indexPage)}
                  />}
                </Row>
              </Col>
            </Row>
            <Row>
              <Upload
                name='file'
                action={SERVER_URL + '/api/v1/gamesandtests/fileupload/'}
                headers={{
                  authorization: 'authorization-text',
                }}
                onChange={(info) => handleUploadFile(info, indexPage)}
                accept=".jpeg,.png,.jpg"
                listType='picture'
                fileList={fileLists[indexPage]}
              >
                <Button>
                  <Icon type="upload" /> Agregar imagen
                </Button>
              </Upload>
            </Row>
            <Row style={{ margin: '20px 0' }}>
              <span>*</span>
              <Text>Título de la situación</Text>
              <Input 
                placeholder='Escribe el título de la situación'
                value={page.label}
                name='label'
                onChange={(e) => handleChangeInput(indexPage, e)}
              />
            </Row>
            <Row style={{ margin: '20px 0' }}>
              <span>*</span>
              <Text>Descripción de la situación</Text>
              <TextArea 
                placeholder='Escribe la descripción de la situación'
                value={page.description}
                name='description'
                onChange={(e) => handleChangeInput(indexPage, e)}
              />
            </Row>
            <Row style={{ margin: '10px 0' }}>
              <span>*</span>
              <Text>Alternativas</Text>
            </Row>
            {page.fields[0].options.map((option, indexOption) => (
              <React.Fragment key={indexOption}>
                <Row
                  type="flex"
                  key={indexOption}
                  justify='space-around'
                  align='middle'
                  className="new-custom-test__frame__step-by-step__step2__collapse-page__question__alternative"
                >
                  <Col xs={12} sm={19}>
                    <Input
                      className="new-custom-test__frame__step-by-step__step2__collapse-page__question__alternative__input"
                      placeholder="Escribe la alternativa"
                      value={option.label}
                      onChange={(e) => handleChangeAlternative(indexPage, indexOption, e)}
                    />
                  </Col>
                  <Col xs={10} sm={4}>
                    <Input 
                      addonAfter="Puntos" 
                      onChange={(e) => changeAlternativeValue(indexPage, indexOption, e)}
                      value={scoreConfig.correct_answers[page.fields[0]._uid].value[option._uid]}
                    />
                  </Col>
                  <Col xs={2} sm={1}>
                    {indexOption > 3 && 
                        <Icon
                          type="delete"
                          style={{
                            fontSize: '18px',
                            color: '#A4A4A4',
                            marginLeft: 10
                          }}
                          onClick={() => deleteAlternative(indexPage, indexOption)}
                        />
                    }
                  </Col>
                </Row>
              </React.Fragment>
              ))}
              <Button
                type="link"
                icon="plus-circle"
                style={{ margin: '0 0 0 0' }}
                onClick={() => addAlternative(indexPage)}
              >
                Agregar alternativa
              </Button>
          </Panel>
        </Collapse>
        ))
      }
      <Row type="flex" justify="center">
        <Divider style={{ margin: '50px 0 200px 0' }}>
          <Button
            type="link"
            icon="plus-circle"
            style={{ color: '#F175A5' }}
            onClick={() => addPage()}
          >
            Agregar situación
          </Button>
        </Divider>
      </Row>
    </Layout>
  );
};

export default Step2;
