import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../../../../../../actions/jobapp';
import * as actionsDataCreators from '../../../../../../../../actions/data';
import DragAndDrop from '../../../../../../../Common/DragAndDrop';
import { Col, Typography, Divider, Checkbox, Icon, Button } from 'antd';
import SingleQuestion from './SingleQuestion';

const { Text } = Typography;

class AddedQuestions extends Component {

  state = {
    allRequired: true,
  }

  removeQuestion = (index) => {
    if (Object.keys(this.props.assignedKoActivity.configuration.questions).length > 1) {
      this.props.actions.removeQuestionKo(index)
    } else {
      this.props.actions.removeAssignedActivity('KO', this.props.ko.original)
    }
  }

  scrollToTemplates = () => {
    const el = document.getElementById('templates');
    return window.scrollTo({ top: el.offsetTop + 70 });
  }

  onCheckAllChange = (event) => {
    const { onChange } = this.props;
    const { questions } = this.props.assignedKoActivity.configuration

    this.setState({
      allRequired: event.target.checked
    })

    for (const question_id in questions) {
      onChange(question_id, event.target.checked)
    }
  }

  checkIfAllIsChecked = () => {
    if (this.props.assignedKoActivity) {
      const questions = Object.values(this.props.assignedKoActivity.configuration.questions)
      if (questions) {
        const allValues = questions.map(q => q.mandatory)
        this.setState({ allRequired: allValues.includes(false) ? false : true })
      }
    }
  }

  onSwitchChange = async (question_id, value) => {
    await this.props.onChange(question_id, value);
    this.checkIfAllIsChecked();
  }

  componentDidMount() {
    this.checkIfAllIsChecked()
  }

  reorderQuestions = (items) => {
    const { questions } = this.props.assignedKoActivity.configuration

    // we know the position of each question using the position in the array
    // rewrite/add the position property with the new index 
    const buffer = items.map((obj, index) => {
      questions[obj.id].position = index
      return { ...questions[obj.id] }
    })

    // Update question position if reorder
    items.forEach((obj, idx) => {
      this.props.actions.startEditQuestion(obj.id);
      this.props.actions.updateQuestionKo(buffer[idx]);
    })
  }

  render() {
    const { assignedKoActivity, active, jobAppStatus } = this.props;
    /**
     * Question structure -> {<id>: config}
     * In old questions the id is an integer and it is used as a position
     * in the dnd component. this functions should work with the 2 versions.
     */
    // transform object to array, move the key inside the objects with "id" as key
    const questionsArray = assignedKoActivity ? Object.keys(assignedKoActivity.configuration.questions)
      .map(key => ({ ...assignedKoActivity.configuration.questions[key], id: key })) : [];
    const questionsHavePosition = questionsArray.every(x => x.position !== undefined);

    // this var returns an array with the ids in the correct order (the idx in the array represents the position in the dnd component)
    const questions = assignedKoActivity && Object.keys(assignedKoActivity.configuration.questions).map(
      (key, index) => {
        const tmpQuestion = questionsArray.find(q => q.position == index);
        return questionsHavePosition && tmpQuestion ? tmpQuestion.id : key
      });

    const { allRequired } = this.state;
    return (
      <Col sm={24} className='af__added-questions af__switch'>
        <div className='headline'>
          <div>
            <Text strong>Preguntas</Text>
            <Text>{assignedKoActivity ? questions.length : 0}</Text>
          </div>
          {!active && <Button onClick={this.scrollToTemplates}><Icon type="down" /> Ver plantillas</Button>}
          <Checkbox
            onChange={this.onCheckAllChange}
            checked={allRequired}
            disabled={jobAppStatus === 'ACTIV'}
          >
            Todos requeridos
          </Checkbox>
        </div>
        <Divider />

        {active && !assignedKoActivity && <Text type='secondary'>No hay preguntas agregadas para este proceso</Text>}
        <div className='af__dnd'>
          {assignedKoActivity && (
            <DragAndDrop
              ko
              items={questions}
              onChange={this.reorderQuestions}
              render={(index) =>
                <SingleQuestion
                  edit={this.props.edit}
                  active={active}
                  index={index}
                  delete={() => this.removeQuestion(index)}
                  jobAppStatus={jobAppStatus}
                  onSwitchChange={this.onSwitchChange}
                  {...assignedKoActivity.configuration.questions[index]}
                />
              }
            />
          )}
        </div>
      </Col>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    assignedKoActivity: state.jobapp.assignedActivities.KO,
    ko: state.jobapp.assignedActivities['KO'],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: bindActionCreators(actionCreators, dispatch),
    actionsData: bindActionCreators(actionsDataCreators, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddedQuestions);