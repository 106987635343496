import React, { Fragment } from 'react';
import {
  Icon,
  Button,
  Typography,
  Tag,
  Switch,
  Tooltip,
  Popover,
  Menu,
  Dropdown
} from 'antd';
import { icon } from '../../../../constants';
import MobileActions from '../MobileActions';
import { isMobile } from 'react-device-detect';

const { Paragraph, Text } = Typography;

const SingleQuestion = (props) => {
  const { type, question, alternatives,  edit, active, index, jobAppStatus, mandatory, onSwitchChange } = props;
  const editQn = () => edit({ ...props, question: { name: question }, type: { name: type } }, index);

  const handleMenuClick = (e) => {
    switch (e.key) {
      case '1':
        editQn()
        break
      case '2':
        props.delete()
        break
    }
  }
  let showAlternatives;
  try {
    showAlternatives =  alternatives.map(e => {return (<><span>{e.text}</span><br></br></>)})
  } catch {
    showAlternatives =  alternatives.split(",").map(e => {return (<><span>{e.text}</span><br></br></>)})
  }
  return (
    <Fragment>
      <div className='dnd-item'>
        <div className='question'>
        {alternatives.length !== 0  ? 
          <Popover content={showAlternatives} title="Alternativas" trigger="hover">
           {<Tag>{type === 'multiselection' ? icon[type] : <Icon type={icon[type]} />}</Tag>}
          </Popover>
          : <Tag> <Icon type={icon[type]} /> </Tag>
          }
          <Paragraph ellipsis={{ rows: 1 }} style={{ wordBreak: 'break-all', whiteSpace: 'normal' }}>
            {question}
          </Paragraph>
        </div>

        {!active ?
          <span>
            {isMobile ?
              <MobileActions
                main={editQn}
                delete={props.delete}
                permission={props.permission}
                type='edit'
              />
              : (
                <div className='dnd-item__more'>
                  <Text type='secondary' className={mandatory ? 'required' : 'optional'}>
                    {mandatory ? 'Requerido' : 'Opcional'}
                  </Text>
                  <Switch
                    disabled={jobAppStatus === 'ACTIV'}
                    checked={mandatory}
                    onChange={(e) => onSwitchChange(parseInt(index), e)}
                  />

                  <Dropdown
                    trigger={['click']}
                    overlayClassName='dnd-item'
                    overlay={
                      <Menu onClick={handleMenuClick} >
                        <Menu.Item key="1" >
                          <Icon type="edit" />
                          Editar
                        </Menu.Item>

                        <Menu.Item key="2">
                          {/* <Button icon='delete' onClick={props.delete} /> */}
                          <Icon type="delete" />
                          Eliminar
                        </Menu.Item>
                      </Menu>}
                  >
                    <Tooltip placement="top" title='Más acciones'>
                      <Button size='small' >
                        <EllipsisSvg />
                      </Button>
                    </Tooltip>
                  </Dropdown>
                </div>
              )}
          </span>
          : <span>
            {!isMobile &&
              <div className='actions'>
                <Text type='secondary' className={mandatory ? 'required' : 'optional'}>
                  {mandatory ? 'Requerido' : 'Opcional'}
                </Text>
                <Switch
                  disabled={jobAppStatus === 'ACTIV'}
                  checked={mandatory}
                // onChange={(e) => onSwitchChange(parseInt(index), e)}
                />
              </div>
            }
          </span>
        }
      </div>
    </Fragment>
  )
}

const EllipsisSvg = () => (
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="18.915px" height="18.916px" viewBox="0 0 41.915 41.916"
    xmlSpace="preserve">
    <g>
      <g>
        <path fill="#8b8b8b" d="M11.214,20.956c0,3.091-2.509,5.589-5.607,5.589C2.51,26.544,0,24.046,0,20.956c0-3.082,2.511-5.585,5.607-5.585
			C8.705,15.371,11.214,17.874,11.214,20.956z"/>
        <path fill="#8b8b8b" d="M26.564,20.956c0,3.091-2.509,5.589-5.606,5.589c-3.097,0-5.607-2.498-5.607-5.589c0-3.082,2.511-5.585,5.607-5.585
			C24.056,15.371,26.564,17.874,26.564,20.956z"/>
        <path fill="#8b8b8b" d="M41.915,20.956c0,3.091-2.509,5.589-5.607,5.589c-3.097,0-5.606-2.498-5.606-5.589c0-3.082,2.511-5.585,5.606-5.585
			C39.406,15.371,41.915,17.874,41.915,20.956z"/>
      </g>
    </g>
    <g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
  </svg>
);
const EllipsisIcon = props => <Icon component={EllipsisSvg} {...props} />;

export default SingleQuestion;