import React from 'react'
import {
  Col,
  Form,
  Icon,
  Typography,
  Tooltip,
  Row,
  Modal
} from 'antd';

const ConnectIntegrationModal = ({
  submitIntegration,
  integration,
  visible,
  setVisible,
  questionType,
  getFieldDecorator,
  connectTitle
}) => {
  const handleSubmit = () => {
    submitIntegration(integration)
    setVisible(false, null)
  }
  return (
    <Modal
      width={572}
      centered={true}
      destroyOnClose={true}
      title={connectTitle}
      visible={visible}
      onOk={() => handleSubmit()}
      onCancel={() => setVisible(false)}
      okText="Conectar"
      cancelText="Cancelar"
    >
      <Row gutter={[0, 32]}>
        <Row type={"flex"} align='middle' gutter={[0, 24]}>
          <Col span={4}
            justify='start'>
            <img style={{ width: 80, height: 80 }} src='https://genoma-assets.s3.us-east-2.amazonaws.com/modal.svg' />
          </Col>
          <Col span={19} style={{ marginLeft: '0.5em', marginTop: '0.5em' }}>
            <Typography.Text justify="space-around" strong style={{ fontSize: 14, marginRight: '1em' }}>
              Conectarse con {integration.template.name}
            </Typography.Text>
            <Typography.Paragraph style={{ fontSize: 12 }}>
              Para conectar tu cuenta de Genomawork con otros servicios debes completar la siguiente información.
            </Typography.Paragraph>
          </Col>
        </Row>
        <Form >
          <Row gutter={24}>
            <Col xs={24}>
              {integration.template.jobapplication_fields.map((integrationField, integrationFieldKey) => {
                return (
                  <Form.Item key={integrationField}>
                    <Typography.Text justify="space-around" strong style={{ fontSize: 14, color: 'black', marginRight: '0.5em' }}>
                      {integrationField.label}
                    </Typography.Text>
                    {integrationField.tooltip &&
                      (
                        <Tooltip title={integrationField.tooltip} style={{ fontFamily: "Lato", width: "2em" }}>
                          <Icon type="info-circle" />
                        </Tooltip>)}
                    {getFieldDecorator(integrationField.name, {
                      rules: [
                        {
                          required: true,
                          message: `Debes ingresar el nuevo ${integrationField.label}`,
                        },
                      ],
                    })(questionType(integrationField, 'debes completar este campo para poder realizar correctamente la integración', integrationField.name))}
                  </Form.Item>
                )
              })
              }
            </Col>
          </Row>
          <Row key={integration} gutter={24}>
            <Col xs={24}>
              {!integration.template.default_stage && (
                <Form.Item key={integration.id} label={`Etapa ${integration.template.name}`}>
                  {questionType({ type: 'singleselection' }, 'debes completar este campo para poder realizar correctamente la integración', `${integration.template.name.toLowerCase().replace(' ', '_')}_stage`)}
                </Form.Item>
              )
              }
            </Col>
          </Row>
        </Form>
      </Row>
    </Modal>
  )
}


export default ConnectIntegrationModal