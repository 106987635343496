import React from 'react';
import { Row, Col, Typography } from 'antd'

import { jobsForCategory } from '../utils';
import propTypes from 'prop-types';

const { Title } = Typography;

/**
 * 
 * @param {Boolean} jobView
 * @param {String} searchLocation
 * @param {Function} setJobView
 * @param {Function} fetchJobApplications
 * @param {Function} scrollToTop  
 * @returns 
 */

const JobsCategory = ({ jobView, searchLocation, setJobView, fetchJobApplications, scrollToTop }) => {
  return (
    <Row type='flex' justify='space-between' gutter={[20, 20]}>
      {
        jobsForCategory.map((category, index) => (
          <Col xs={24} sm={12} md={12} lg={8} key={index}>
            <Row type='flex' justify='center' align='middle'
              style={{
                background: `url(${category.img})`,
                backgroundSize: 'cover',
                height: '100px',
                borderRadius: '5px',
                backgroundPosition: '50%',
                cursor: 'pointer',
              }}
              onClick={
                jobView || window.innerWidth > 992 ?
                  () => {
                    fetchJobApplications(category.words, searchLocation)
                    setJobView(true)
                    scrollToTop('content-job-portal-id')
                  } :
                  () => {
                    fetchJobApplications(category.words, searchLocation)
                    scrollToTop('jobs-section-title')
                  }
              }
            >
              <Title level={3} style={{ color: 'white', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.5)' }}>
                {category.name}
              </Title>
            </Row>
          </Col>
        ))
      }
    </Row>
  );
}

JobsCategory.prototype = {
  jobView: propTypes.bool,
  searchLocation: propTypes.string,
  setJobView: propTypes.func,
  fetchJobApplications: propTypes.func,
  scrollToTop: propTypes.func
}

export default JobsCategory;