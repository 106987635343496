import * as actions from '../../../actions/data';

import {
  Button,
  Card,
  Col,
  Input,
  Layout,
  Row,
  Switch,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const { Title, Paragraph, Text } = Typography;

function JobPortalsView({ jobPortals, token, actions, businessId }) {
  const [jobPortalsConfig, setJobPortalsConfig] = useState({})

  useEffect(() => {
    setJobPortalsConfig(structuredClone(jobPortals));
  }, [jobPortals])

  const onChangeActive = (jobPortalKey) => (value) => {
    const newJobPortalsConfig = structuredClone(jobPortalsConfig);
    newJobPortalsConfig[jobPortalKey].active = value;
    setJobPortalsConfig(newJobPortalsConfig);
  }

  const onChangeSubConfig = (jobPortalKey, subConfigKey) => (value) => {
    const newJobPortalsConfig = structuredClone(jobPortalsConfig);
    newJobPortalsConfig[jobPortalKey][subConfigKey] = value;
    setJobPortalsConfig(newJobPortalsConfig);
  }

  const onSubmit = () => {
    actions.fetchAndReturn(token, `/api/v1/accounts/business/${businessId}/`, 'PATCH', { job_portal_integrations: jobPortalsConfig }).then((res) => {
    }).catch((err) => {
      console.log(err);
    })
  }

  return (
    <Layout style={{ padding: 24 }}>
      <Row>
        <Title>Publicación en Portales de Empleo</Title>
      </Row>
      <Row>
        <Paragraph>Configura aquí los portales que deseas que estén activos para tu empresa. 
          Además podrás activar los portales que necesiten alguna configuración adicional.</Paragraph>
      </Row>
      <Row>
        <Button type='primary' onClick={onSubmit}>Guardar</Button>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col span={24}>
          <Row type='flex' gutter={[24, 24]}>
            {Object.keys(jobPortalsConfig).filter(jp => jp === 'indeed').map(jp => {
              return (
                <Col span={8}>
                  <Card title={jp} style={{ border: '1px solid #e9e9e9' }}>
                    <Switch
                      checkedChildren="Activado"
                      unCheckedChildren="Desactivado"
                      defaultChecked
                      checked={jobPortalsConfig[jp].active}
                      onChange={onChangeActive(jp)}
                    />
                    {jobPortalsConfig[jp].active ?
                      <>
                        {Object.keys(jobPortalsConfig[jp]).filter(x => x !== 'active').map(subConfig => {
                          return (
                            <Row>
                              {
                                typeof(jobPortalsConfig[jp][subConfig]) === 'boolean' ?
                                <>
                                  <Text>{subConfig}:{" "}</Text>
                                  <Switch
                                    title={subConfig}
                                    onChange={onChangeSubConfig(jp, subConfig)}
                                    checkedChildren="Activado"
                                    unCheckedChildren="Desactivado"
                                    defaultChecked
                                    checked={jobPortalsConfig[jp][subConfig]}
                                  /> 
                                </> :
                                typeof (jobPortalsConfig[jp][subConfig]) === 'string' ?
                                  // <><Input title={subConfig} value={jobPortalsConfig[jp][subConfig]} /></> :
                                  // TODO: configuracion de trabajando.com
                                  null :
                                  null
                              }
                            </Row>
                          )
                        })}
                      </>
                      : null
                    }
                  </Card>
                </Col>
              )
            })}
          </Row>
        </Col>
      </Row>
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    jobPortals: state.auth.jobPortalIntegrations,
    token: state.auth.token,
    businessId: state.auth.business?.id
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(JobPortalsView);

