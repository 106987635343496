import React, { Fragment, useEffect } from 'react';

import { queryToObject } from '../../../utils/url';
import { LINKEDIN_STATE } from '../../../constants/socialAuth';


import {
  Col,
  Layout,
  Spin,
} from 'antd';
const { Content } = Layout

const SocialLoginRedirect = (props) => {

  useEffect(() => {
    if (window.location.search) {
      const params = queryToObject(window.location.search)
      if (params.state === LINKEDIN_STATE) {
        localStorage.setItem('linkedin', window.location.search)
      }
    } else if (window.location.hash) {
      localStorage.setItem('googleoauthresponse', window.location.hash.replace('#', '?' ))
    }
    setTimeout(() => {
      window.close()
    }, 2000)
  }, [])

  return (
    <Fragment>
      <Col>
        <Layout className='jd'>
          <div className='content'>
            <Content>
              <div className='pf__is-loading'>
                <Spin />
              </div>
            </Content>
          </div>
        </Layout>
      </Col>
    </Fragment>
  )
}

export default SocialLoginRedirect;