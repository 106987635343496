import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import InterviewGroupQuestion from './InterviewGroupQuestion';
import ScorecardsGroup from './ScorecardsGroup';
import i18n from '../../../../i18n';

const InterviewKitPDF = ({ interviewsKit }) => {
  const { questions, scorecards } = useMemo(() => {
    return interviewsKit.reduce((acc, interview) => {
      if (interview.kit_type === 'INTERVIEW') {
        if (acc.questions) {
          acc.questions = [...acc.questions, interview];
        } else {
          acc.questions = [interview];
        }
      } else {
        if (acc.scorecards) {
          acc.scorecards = [...acc.scorecards, interview];
        } else {
          acc.scorecards = [interview];
        }
      }
      return acc;
    }, {});
  }, [interviewsKit]);

  return (
    <div className="report-pdf-interview-kits protected-div">
      <div className="report-pdf-interview-kits__header">
        <h6 style={{ fontSize: 14 }}><strong>{i18n.t('candidate_pdf__ikit', {ns: 'candidatePdf'})}</strong></h6>
      </div>
      {
        questions &&
        <div className="protected-div">
          <h6 style={{ fontSize: 14 }}>{i18n.t('candidate_pdf__interview', {ns: 'candidatePdf'})}</h6>
          {questions?.map((group) => (
            <InterviewGroupQuestion group={group} key={group.id} />
          ))}
        </div>
      }
      {
        scorecards &&
        <div className="protected-div">
          <h6 style={{ fontSize: 14 }}>{i18n.t('candidate_pdf__scorecards', {ns: 'candidatePdf'})}</h6>
          {scorecards.map((group) => (
            <ScorecardsGroup group={group} key={group.id} />
          ))}
        </div>
      }
    </div>
  );
};

InterviewKitPDF.propTypes = {
  interviewsKit: PropTypes.array.isRequired
}

export default InterviewKitPDF;
