import React, { useEffect, useRef, useState } from "react";

import OptionButton from "../commons/OptionButton";

import {
  Row,
  Col,
  Typography,
  Icon
} from 'antd';

const Situational = ({ field, fieldChanged, values, disableNextPage }) => {
  const [state, setState] = useState(values);
  const alternatives = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j'];

  const disableNextPageStable = useRef(disableNextPage);

  useEffect(() => {
    fieldChanged(field._uid, state);
    if (state.best === '' || state.worst === '') {
      disableNextPageStable.current(field._uid, true);
    }
    else {
      disableNextPageStable.current(field._uid, false);
    }
  }, [state, field._uid])

  const onClick = (id, value) => {
    if (value === "best") {
      if (state.best === id) {
        setState({ ...state, best: '' });
      } else {
        setState({ ...state, best: id });
      }
    }
    if (value === "worst") {
      if (state.worst === id) {
        setState({ ...state, worst: '' });
      } else {
        setState({ ...state, worst: id });
      }
    }
  }

  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        <Typography.Text strong style={{ fontSize: 16, color: '#5E5E5E', userSelect: 'none' }}>
          {field.label}
        </Typography.Text>
      </div>
      {
        field.options.map((option, index) => {
          return (
            <Row gutter={[20, 40]} type='flex' key={option._uid} align="middle" justify="space-around">
              <Col key={`${option.label}-2`} sm={1} xs={2}>
                {alternatives[index]}.
              </Col>
              <Col key={option.label} sm={15} xs={22}>
                {option.label}
              </Col>
              <Col key={`${option.label}-1`} sm={4} xs={12}>
                <OptionButton
                  checked={state.best === option._uid}
                  id={option._uid}
                  label={<Icon 
                    type="like" 
                    theme={state.best === option._uid ? 'filled' :'outlined'} 
                    twoToneColor='#D9D9D9'
                    style={{ color: state.best === option._uid ? '#ffffff' : '#D9D9D9' }}
                  />}
                  value={'best'}
                  index={index}
                  type={'SJT_GW'}
                  disabled={state.worst === option._uid}
                  onClick={onClick}
                />
              </Col>
              <Col key={`${option.label}+1`} sm={4} xs={12}>
                <OptionButton
                  checked={state.worst === option._uid}
                  id={option._uid}
                  label={<Icon
                     type="dislike" 
                     theme={state.worst === option._uid ? 'filled' :'outlined'} 
                     twoToneColor='#D9D9D9'
                     style={{ color: state.worst === option._uid ? '#ffffff' : '#D9D9D9' }}
                  />}
                  value={'worst'}
                  index={index}
                  type={'SJT_GW'}
                  disabled={state.best === option._uid}
                  onClick={onClick}
                />
              </Col>
            </Row>
          );
        })
      }
    </div >
  );
};

export default Situational;
