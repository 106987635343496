import React from 'react';
import {
  Table,
  Row,
  Typography,
  Button,
  Icon,
  Spin
} from 'antd';
import '../../CustomTestCreate.scss';

const { Text } = Typography;

const IfExistCute = ({ customTest, tableColumns, setShowModal, loading }) => {
  return (
    <React.Fragment>
      {
        loading ?
          (
            <Spin size='large'/>
          ) : customTest ? customTest.length > 0 ?
            (
              <Table
                rowKey='id'
                columns={tableColumns}
                dataSource={customTest}
                pagination={{ defaultPageSize: 10, style: { margin: '20px' } }}
                className='custom-test-table__table__yescute'
              />
            ) :
            (
              <Row type='flex' justify='center' align='middle' className='custom-test-table__table__notcute'>
                <img src='https://genoma-assets.s3.us-east-2.amazonaws.com/genomin.png' />
                <Text>Aún no tienes pruebas creadas.</Text>
                <Button
                  onClick={() => setShowModal(true)}
                  type='link'
                >
                  Crear prueba
                  <Icon type='arrow-right' />
                </Button>
              </Row>
            ) : null
      }
    </React.Fragment>
  );
}

export default IfExistCute;