import * as Sentry from '@sentry/browser';

import { ApplicationModel, CandidateModel, JobAppModel } from './models';
import { Modal, Typography, message } from 'antd';
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';

import { processAssignedAdditionalQuestions } from './utils'
import SERVER_URL from '../../../utils/config';
import candidateReducer from './candidateReducer';
import i18n from '../../../i18n';
import useBoolean from '../../../hooks/useBoolean';

const { Paragraph } = Typography;

export const CandidateContext = React.createContext();

const initialState = {
  application: ApplicationModel,
  businessCustomFields: {
    application: [],
    candidate: [],
  },
  candidatesToMerge: [],
  candidate: CandidateModel,
  comparingCandidates: false,
  mergingCandidates: false,
  customTests: [],
  customTestSurveys: [],
  indexCandidate: null,
  interviewKits: null,
  jobApp: JobAppModel,
  laneData: {},
  mailTemplateCategories: [],
  mailTemplates: [],
  nextCandidateId: null,
  notifications: [],
  possibleDuplicate: null,
  possible_duplicates: [],
  translationFile: { ns: 'candidateProfile' },
  translationFilePdf: { ns: 'candidatePdf' },
  filesRequests: [],
};

export const CandidateProvider = ({
  addOns,
  business,
  candidateId,
  permissions,
  userId,
  token,
  closeModal,
  jobAppId,
  actions,
  discardReasons,
  lanes,
  userName,
  videoActions,
  videoInterview,
  measuredTraits,
  children,
  onChangePhase,
  updateCandidateTable,
  mode,
  shareTokenId = '',
  logoURL,
  redirectAfterMerge,
}) => {
  const [state, dispatch] = useReducer(candidateReducer, initialState);
  const [emailView, handleEmailView] = useBoolean(false);
  const [loading, setLoading] = useState(true);

  // logic to manage the tabs in the modal
  const [tabKey, setKey] = useState('0');

  const changeKey = (key) => {
    // we must use string key (because of antd tab system)
    const string = key.toString();
    setKey(string);
  };

  const topRef = useRef(null);

  const shortDispatch = (type, payload) =>{
    const pl = structuredClone(payload);
    dispatch({ type, payload });
  };

  const onChangeCandidate = (direction) => {
    const nextCandidateIndex =
      direction === 'next'
        ? state.indexCandidate + 1
        : state.indexCandidate - 1;

    const { passive_candidate, personal_user_id } = state.laneData[nextCandidateIndex];

    setLoading(true);
    shortDispatch('CHANGE_CANDIDATE', passive_candidate || personal_user_id);
  };

  const compareCandidates = (otherCandidateId) => {
    setLoading(true);
    shortDispatch('COMPARE_CANDIDATES', otherCandidateId);
  };

  const mergeCandidates = () => {
    if (!state.mergingCandidates) {
      const { candidate_id: id, dni, email, name, surname } = state.candidate;
      const { candidate_type } = state.application;
      const candidateInfo = {
        id, 
        dni,
        email,
        name: `${name} ${surname}`,
        candidate_type,
      }
      shortDispatch('MERGE_CANDIDATES', [candidateInfo, state.possible_duplicates[0]]);
    } else {
      shortDispatch('MERGE_CANDIDATES', []);
    }
  };

  const confirmMerge = () => {
    if (state.candidatesToMerge.length != 2) return;

    const url = `/api/v1/gamesandtests/jobapplications/${jobAppId}/candidates/${state.candidatesToMerge[0].id}/merge/`;
    actions
      .fetchAndReturn(token, url, 'PATCH', { 'candidate_id': state.candidatesToMerge[1].id })
      .then((response) => {
        if (response.status < 400) {
          message.success(i18n.t('profile__candidates_merged', state.translationFile));
          shortDispatch('MERGE_CANDIDATES', []);
          if (state.application.candidate_type === 'active') {
            // Empty possible_duplicates array directly, instead of making the request again
            shortDispatch(
              'HYDRATE_CANDIDATE',
              // { application: { ...state.application, possible_duplicates: [] } },
              { possible_duplicates: [] },
            );
          }
          redirectAfterMerge(response.body.candidate, response.body.personal_user_id);
        } else {
          message.error(i18n.t('profile__an_error_ocurred', state.translationFile));
        }
      })
      .catch((e) => {
        Sentry.captureException(e);
        message.error(i18n.t('profile__an_error_ocurred', state.translationFile));
      });
  };

  // save UserJobApplication's note (in the future, should support more than 1 note and specify the
  // sender)
  const saveNote = (note) => {
    const url = `/api/v1/gamesandtests/jobapplications/${jobAppId}/candidates/${state.candidate.id}/`;
    const submitData = { note };
    actions
      .fetchAndReturn(token, url, 'PATCH', submitData,)
      .then((response) => {
        if (response.status < 400) {
          shortDispatch('UPDATE_NOTE', note)
          message.success(i18n.t('profile__update_note', state.translationFile))
        }
      })
      .catch((e) => {
        Sentry.captureException(e);
        message.success(i18n.t('profile__update_error', translationFile));
      });
  };

  // Reset blocked games
  const handleFixLockedGames = async (id) => {
    try {
      const url = `gamesandtests/lockedgames/${id}/`;
      await actions.updateData(token, url, '', 'POST');
      message.success(i18n.t('profile__unlock_game', state.translationFile));
    } catch (e) {
      message.error(
        i18n.t('profile__unlock_games_error', state.translationFile)
      );
    }
  };

  const fetchCandidateAndApplicationData = () => {
    const newToken = shareTokenId ? null : token;
    const share_token = shareTokenId ? `&share_token=${shareTokenId}` : '';

    let tmpCandidateId = state.nextCandidateId ?? candidateId;

    const url = `/api/v1/gamesandtests/candidateprofile/${jobAppId}/${tmpCandidateId}/?format=json${share_token}`;
    return actions
      .fetchAndReturn(newToken, url)
      .then(({ status, body }) => {
        if (status < 400) {
          const result = {};
          result.application = {
            ...body,
            activities: [...body.activities_progress],
            additional_documents: [...body.additional_documents],
            custom_fields: {
              application: { ...body.custom_fields.application },
              candidate: { ...body.custom_fields.candidate },
            },
            game_scores: [...body.game_scores],
            ko_answers: body.ko_answers ? { ...body.ko_answers } : {},
            personality_scores: [...body.personality_scores],
            reasoning_scores: { ...body.reasoningScores },
            references: [...body.references],
            scores: { ...body.scores },
            userjobapplicationreference: {
              ...body.userjobapplicationreference,
            },
            // videointerview_evaluations
          };
          delete result.application.candidate;
          result.candidate = {
            ...body.candidate,
            candidateId,
            careers: body.candidate.careers
              ? [...body.candidate.careers]
              : null,
            experiences: body.candidate.experiences
              ? [...body.candidate.experiences]
              : null,
            tags: body.candidate.tags ? [...body.candidate.tags] : null,
          };
          return result;
        }
        return {};
      })
      .catch((e) => {
        Sentry.captureException(e);
        return {};
      });
  };

  const fetchPossibleDuplicates = () => {
    let tmpCandidateId = state.nextCandidateId ?? candidateId;
    try {
      const url = `/api/v1/gamesandtests/jobapplications/${jobAppId}/candidates/${tmpCandidateId}/duplicates/`;
      actions
        .fetchAndReturn(token, url)
        .then(({ status, body }) => {
          if (status < 400) {
            shortDispatch('HYDRATE_CANDIDATE', { possible_duplicates: body });
          }
        })
        .catch((e) => {
          Sentry.captureException(e);
        });
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const fetchMailHistory = async (returnOnly=false) => {
    try {
      const url = `/api/v1/gamesandtests/jobapplications/${jobAppId}/candidates/${state.nextCandidateId ?? candidateId}/mailhistories/`;
      const response = await actions.fetchAndReturn(token, url)
      if (response.status < 400) {
        if (!returnOnly) {
          shortDispatch(
            'HYDRATE_CANDIDATE',
            { application: { ...state.application, email_history: response.body.results } }
          );
        }
        return response.body.results;
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const fetchMailTemplates = () => {
    try {
      const url = `/api/v1/accounts/business/${business.id}/mailtemplates/`;
      actions
        .fetchAndReturn(token, url)
        .then(({ body, status }) => {
          if (status < 400) {
            shortDispatch('HYDRATE_CANDIDATE', { mailTemplates: body.results });
            // TODO: TEST THIS
            const categoriesUrl = '/api/v1/accounts/mailtemplatecategories/';
            actions
              .fetchAndReturn(token, categoriesUrl)
              .then(({ status: categoriesStatus, body: categoriesBody }) => {
                if (categoriesStatus < 400) {
                  shortDispatch('HYDRATE_CANDIDATE', {
                    mailTemplateCategories: categoriesBody?.results,
                  });
                }
              })
              .catch((error) => Sentry.captureException(error));
          }
        })
        .catch((error) => Sentry.captureException(error));
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  // get info about candidate and other information that could be useful
  
  const fetchData = async () => {
    try {
      const nextState = {};

      const newToken = shareTokenId ? null : token;
      const share_token = shareTokenId ? `share_token=${shareTokenId}` : '';
      let businessId = business.id;
      if (shareTokenId) {
        const jobAppInfoUrl = `/api/v1/gamesandtests/jobapplications/${jobAppId}/public/`;
        const jobAppInfo = await actions.fetchAndReturn(null, jobAppInfoUrl);
        businessId = jobAppInfo.body.business.id;
      }

      const applicationAndCandidateData = await fetchCandidateAndApplicationData();
      nextState.application = { ...applicationAndCandidateData.application };
      nextState.candidate = { ...applicationAndCandidateData.candidate };

      setLoading(false);
      shortDispatch('HYDRATE_CANDIDATE', nextState);

      // Get mail history
      const email_history = await fetchMailHistory(true);
      nextState.application.email_history = email_history;
      shortDispatch('HYDRATE_CANDIDATE', nextState);

      // Get notifications count
      if (!shareTokenId && nextState.application.candidate_type !== 'passive') {
        const notifications =  await getMsgNotificationCount(nextState);
        console.log('NOTIFICATIONS:' , notifications);
        nextState.notifications = notifications;
        shortDispatch('HYDRATE_CANDIDATE', nextState);
      }

      if (nextState.application.candidate_type === 'passive') setKey('1');

      const assActUrl = `/api/v1/gamesandtests/assignedactivity/${jobAppId}/?${share_token}`;
      const jobAppUrl = `/api/v1/gamesandtests/jobapplications/${jobAppId}/?${share_token}`;
      const userjobappCustomFieldsUrl = `/api/v1/gamesandtests/business/${businessId}/customfields/?field_type=USERJA&active=True&${share_token}`;
      const candidateCustomFieldsUrl = `/api/v1/gamesandtests/business/${businessId}/customfields/?field_type=CANDID&active=True&${share_token}`;

      const promisesUrl = [
        assActUrl,
        jobAppUrl,
        userjobappCustomFieldsUrl,
        candidateCustomFieldsUrl,
      ].map((url) => actions.fetchAndReturn(newToken, url));

      const [
        assActResponse,
        jobAppResponse,
        userjobappCustomFieldsResponse,
        candidateCustomFieldsResponse,
      ] = await Promise.all(promisesUrl).catch((e) => console.log(e));

      nextState.jobApp = { ...jobAppResponse.body, measuredTraits: {} };
      nextState.businessCustomFields = {
        application: [...userjobappCustomFieldsResponse.body.results],
        candidate: [...candidateCustomFieldsResponse.body.results],
      };
      shortDispatch('HYDRATE_CANDIDATE', nextState);

      if (!shareTokenId) {
        const otherJobAppsUrl = `/api/v1/gamesandtests/${
          nextState.application.candidate_type === 'passive'
            ? 'passivecandidates'
            : 'personalusers'
        }/${state.nextCandidateId ?? candidateId}/userjobapplications/?business=${
          business.id
        }&${share_token}`;

        const otherJobAppsResponse = await actions.fetchAndReturn(newToken, otherJobAppsUrl);

        if (otherJobAppsResponse.status < 400) {
          nextState.candidate = {
            ...nextState.candidate,
            otherJobApps: [...otherJobAppsResponse.body.results],
          };
        }
        nextState.jobApp.measuredTraits = { ...measuredTraits };
        shortDispatch('HYDRATE_CANDIDATE', nextState);
      }
      if (
        (!measuredTraits ||
          Object.keys(measuredTraits).length == 0 ||
          shareTokenId) &&
        nextState.jobApp.genome_id
      ) {
        const rawData = JSON.stringify({
          genome_id: nextState.jobApp.genome_id,
        });
        const response = await fetch(
          `${SERVER_URL}/api/v1/genomes/measuredtraits/?share_token=${shareTokenId}`,
          {
            method: 'GET',
            headers: {
              'Raw-Data': `${rawData}`,
              Accept: 'application/json',
              Authorization: `Token ${newToken}`,
              'Content-Type': `application/json`,
            },
          }
        ).then((response) => response.json());
        try {
          const tmpMeasuredTraits = {};
          response.results.forEach(
            (mt) =>
              (tmpMeasuredTraits[mt.trait_id] = { ...mt, original: true })
          );
          nextState.jobApp = { ...nextState.jobApp, measuredTraits: {...tmpMeasuredTraits} };
          shortDispatch('HYDRATE_CANDIDATE', nextState);
        } catch (error) {
          console.error(error);
          // Sentry.captureException(error);
        }
      }

      const cuTes = assActResponse.body.filter((obj) => obj.code === 'CUTE');
      // This is the assigned activity, if the ko answers have some position undefined we can use the infer from the assigned activity
      const assignedKO = assActResponse.body.find(obj=>obj.code==='KO');
      const koQuestions = assignedKO ? processAssignedAdditionalQuestions(assignedKO) : null;

      nextState.customTests = cuTes;
      nextState.koQuestions = koQuestions;

      nextState.laneData = lanes[nextState.application.status]
        ? [...lanes[nextState.application.status]]
        : [];

      if (
        cuTes.length > 0 &&
        nextState.application.candidate_type !== 'passive'
      ) {
        let promiseArray = [];
        let cuTesNameArray = [];
        cuTes.forEach((cute, index) => {
          const config = JSON.parse(cute.configuration);
          const cuteAnswersUrl = `/api/v1/gamesandtests/customtests/${
            config.id
          }/personaluser/${
            applicationAndCandidateData.candidate.id
          }/answers/?${share_token}`;
          promiseArray.push(actions.fetchAndReturn(newToken, cuteAnswersUrl));
          cuTesNameArray.push(config.name);
        });
        if (promiseArray.length > 0) {
          await Promise.all(promiseArray).then(async (res) => {
            const responseArray = res.map((obj, idx) => ({
              answer: obj.body,
              name: cuTesNameArray[idx],
            }));
            nextState.customTests = responseArray;
            const hasSurvey = responseArray.find((obj) => {
              return (
                obj.answer?.scores_config?.survey !== undefined ||
                obj.answer?.scores_config?.survey !== null
              );
            });

            if (hasSurvey) {
              await getCustomTestSurveys(
                applicationAndCandidateData.application.id
              );
            }
          });
        }
      }

      if (
        nextState.jobApp.interview_id &&
        nextState.application.candidate_type !== 'passive'
      ) {
        await videoActions.fetchVideo(
          newToken,
          nextState.jobApp.interview_id,
          nextState.candidate.email
        );
      }

      if (nextState.laneData.length) {
        const indexCandidate = nextState.laneData.findIndex((application) => {
          // Some applications can be undefined if the page is not already loaded
          if (!application) {
            return;
          }
          return application.id === nextState.application.id;
        });

        nextState.indexCandidate = indexCandidate;
      }

      const endpointJobapp = `/api/v1/evaluations/jobapplication/${jobAppId}/interviewkit/?${share_token}`;

      const kitResponse = await actions.fetchAndReturn(newToken, endpointJobapp);

      // candidate.id is the personal_user_id or the passive candidate id, the real candidate id is candidateId
      if (nextState.application.candidate_type !== 'passive') await getFileRequestAnswers(nextState.candidate.id);

      if (
        !kitResponse.body.length ||
        nextState.application.candidate_type === 'passive'
      ) {
        setLoading(false);
        return shortDispatch('HYDRATE_CANDIDATE', nextState);
      }

      const kitPromises = kitResponse.body.map((kit, i) =>
        actions.fetchAndReturn(
          newToken,
          `/api/v1/evaluations/userjobapplication/${nextState.application.id}/interviewkit_eval/${kit.interview_kit}/answers/?${share_token}`,
          'GET'
        )
      );

      const kits = await Promise.all(kitPromises);

      const evaluationState = {
        userjobapp: nextState.application.id,
        kits: kitResponse.body.map((kit) => kit),
        evals: kitResponse.body.map((kit) => {
          return {
            interview_kit_id: kit.interview_kit,
            answers:
              kits.find(
                (k) => k.body[0]?.interview_kit_id == kit.interview_kit
              )?.body || [],
          };
        }),
      };

      nextState.interviewKits = { ...evaluationState };
      setLoading(false);
      return shortDispatch('HYDRATE_CANDIDATE', nextState);
    } catch (e) {
      Sentry.captureException(e);
      message.error('Ocurrio un error, por favor intenta nuevamente');
      closeModal();
    }
  };

  useEffect(() => {
    fetchData();
    if (!state.mailTemplates.length) fetchMailTemplates();
    fetchPossibleDuplicates();
  }, [state.nextCandidateId, userId]);

  const getFileRequestAnswers = async (userId) => {
    const url = `/api/v1/gamesandtests/jobapplications/${jobAppId}/personalusers/${userId}/filesrequests/`;
    const answers = await actions.fetchAndReturn(token, url, 'GET');
    if (answers.status < 400)
      shortDispatch('UPDATE_FILES_REQUESTS', answers?.body.results);
  };

  const updateStatus = (status) => {
    const url = `/api/v1/gamesandtests/jobapplications/${jobAppId}/candidates/${state.candidate.id}/`;
    actions
      .fetchAndReturn(token, url, 'PATCH', { status })
      .then((response) => {
        if (response.status < 400){
          shortDispatch('UPDATE_STATUS', status);
          onChangePhase(state.candidate.id, state.application.status, status);
          message.success(
            i18n.t('profile__updated_status', state.translationFile)
          );
        } else{
          message.error(
            i18n.t('profile__an_error_ocurred', state.translationFile)
          )
        }
      })
      .catch((_) =>
        message.error(
          i18n.t('profile__an_error_ocurred', state.translationFile)
        )
      );
  };

  const reclassifyCandidate = async () => {
    try {
      const resp = await actions.fetchAndReturn(
        token,
        `/api/v1/gamesandtests/jobapplications/${jobAppId}/candidates/${state.candidate.id}/`,
        'PATCH',
        { state: 'ACTIV' },
      );

      shortDispatch('HYDRATE_CANDIDATE', {
        application: { ...state.application, state: 'ACTIV' },
      });
      // Same stage we only update the new state
      updateCandidateTable(
        state.candidate.id,
        // Current stage
        state.application.status,
        // Next stage
        state.application.status
      );

      if (resp.status < 400) {
        message.success(
          i18n.t('profile__candidate_succesfully_reincorporated', {
            ns: 'candidateProfile',
          })
        )
      } else {
        message.error(
          i18n.t(
            'profile__an_error_ocurred_reincorporating',
            state.translationFile
          )
        );
      };
    
    } catch (e) {
      message.error(
        i18n.t(
          'profile__an_error_ocurred_reincorporating',
          state.translationFile
        )
      );
    }
  };

  const discardCandidate = async (discardReasonId, comment = null) => {
    try {
      const updatedCandidate = await actions.sendData(
        token,
        `gamesandtests/jobapplications/${jobAppId}/candidates/${state.candidate.id}/`,
        JSON.stringify({
          state: 'DISCAR',
          discard_reason: discardReasonId,
          discard_reason_comment: comment,
        }),
        'PATCH'
      );
      const updatedDiscardReason = discardReasons.find(
        ({ id }) => id === discardReasonId
      );
      shortDispatch('HYDRATE_CANDIDATE', {
        candidate: {
          ...state.candidate,
        },
        application: {
          ...state.application,
          state: 'DISCAR',
          discard_reason_representation: updatedDiscardReason
            ? updatedDiscardReason.title
            : '',
          discard_reason_comment: updatedCandidate.discard_reason_comment,
        },
      });
      // Same stage we only update the new state
      updateCandidateTable(
        state.candidate.id,
        // Current stage
        state.application.status,
        // Next stage
        state.application.status,
        // candidateData
        { state: 'DISCAR' },
      );
      message.success(i18n.t('profile__candidate_succesfully_discarded', state.translationFile));
    } catch (e) {
      Sentry.captureException(e);
      message.error(
        i18n.t('profile__an_error_ocurred_discarding', state.translationFile)
      );
    }
  };

  const editCandidateDiscardReason = async (
    application,
    candidate,
    discardReasonId
  ) => {
    const url = `/api/v1/gamesandtests/jobapplications/${jobAppId}/candidates`;
    const data = { discard_reason: discardReasonId };
    try {
      await actions.fetchAndReturn(
        token,
        candidate.personal_user_id
          ? `${url}/${candidate.personal_user_id}/`
          : `${url}/${candidate.id}/`,
        'PATCH',
        data
      );
      message.success(i18n.t('commons__saved_changes',{ns: 'businessUser'}));
      const newTitle = discardReasons.find(
        ({ id }) => id === discardReasonId
      ).title;

      shortDispatch('HYDRATE_CANDIDATE', {
        application: {
          ...state.application,
          discard_reason_representation: newTitle,
        },
      });
    } catch (error) {
      message.error(i18n.t('commons__unsaved_changes'));
    }
  };

  const askForReference = () => {
    if (state.application.candidate_type === 'passive') return;
    const data = { candidates: [state.candidate.id] };
    const url = `/api/v1/gamesandtests/jobapplications/${jobAppId}/userjobapplicationreferences/?lang=es`;
    Modal.confirm({
      cancelText: i18n.t('profile__cancel', state.translationFile),
      closable: true,
      content: (
        <Paragraph>
          {i18n.t(
            'profile__sent_mail_explanation_in_references',
            state.translationFile
          )}
        </Paragraph>
      ),
      okText: 'Enviar',
      onOk: () => {
        actions
          .fetchAndReturn(token, url, 'POST', data)
          .then((resp) =>{
            if (resp.status < 400){
              message.success(
                i18n.t(
                  'profile__requests_for_references_sent_ok',
                  state.translationFile
                )
              )
              }
            }
          )
          .catch((_) =>
            message.error(
              i18n.t(
                'profile__requests_for_references_sent_error',
                state.translationFile
              )
            )
          );
      },
    });
  };

  const sendReferencesQuestionnaire = (
    referenceId,
    referenceTemplateId,
    message,
    setQuestionnaireSent,
    setNewStatus
  ) => {
    if (state.application.candidate_type === 'passive') return;

    const { userjobapplicationreference } = state.application;
    const url = `gamesandtests/userjobapplicationreferences/${userjobapplicationreference.token}/references/${referenceId}/?lang=es`;
    const data = { template: referenceTemplateId };
    actions
      .sendData(token, url, JSON.stringify(data), 'PATCH')
      .then((_) => {
        setQuestionnaireSent(true);
        setNewStatus('MAIL');
        message.success(
          i18n.t('profile__questionnaire_sent_ok', state.translationFile)
        );
      })
      // TODO: TEST
      .catch((_) =>
        message.error(
          i18n.t('profile__questionnaire_sent_error', state.translationFile)
        )
      );
  };

  const completeReferenceQuestionnaire = (
    referenceId,
    message,
    setQuestionnaireCompleted,
    setNewStatus,
    markCompleted = true
  ) => {
    if (state.application.candidate_type === 'passive') return;

    const { userjobapplicationreference } = state.application;
    const url = `gamesandtests/userjobapplicationreferences/${userjobapplicationreference.token}/references/${referenceId}/`;
    const data = { status: markCompleted ? 'D_PH' : 'PEND' };
    actions
      .sendData(token, url, JSON.stringify(data), 'PATCH')
      .then((_) => {
        setQuestionnaireCompleted(markCompleted);
        setNewStatus(data.status);
        message.success(
          i18n.t(
            `profile__questionnaire_${
              markCompleted ? 'completed' : 'unmarked'
            }`,
            state.translationFile
          )
        );
      })
      // TODO: TEST
      .catch((_) =>
        message.error(i18n.t('profile__error_ocurred', state.translationFile))
      );
  };

  const getCustomTestSurveys = async (userJobId) => {
    const endpoint = `/api/v1/gamesandtests/applications/${userJobId}/customtestsurveys/`;
    await actions.fetchAndReturn(token, endpoint).then((response) => {
      if (response.status < 400) {
        const tmpSurveys = response.body;
        shortDispatch('UPDATE_CUTE_SURVEY', tmpSurveys);
        return response.body;
      }
    });
  };

  const getPersonalityQuantiles = (distribution, sd = null, mean = null) => {
    if (state.application.candidate_type === 'passive') return;

    distribution = JSON.parse(distribution);
    if (sd !== null && mean !== null) {
      return [
        mean - 1.5 * sd,
        mean - 0.5 * sd,
        mean + 0.5 * sd,
        mean + 1.5 * sd,
      ];
    }
    // Else build the distribution
    let valueList = [];
    let extendedArray = [];
    let prevIntervalName = 0;
    let prevIntervalValue = 0;
    distribution.forEach((interval) => {
      /*
      Name corresponde al mayor valor del intervalo.
      Value la cantidad de puntajes en dicho intervalo.
      La idea es crear un "intervalo intermedio" entre cada uno, creando
      un array extendido que vaya de 5 en 5 en vez de 10 en 10, para evitar
      problemas porque un quintil incluya a mas del 20%.
      */
      let name = interval['name'].split('-');
      // Last element
      name = parseInt(name[1]);
      let value = parseInt(interval['val']) / 2;
      extendedArray.push({
        name: Math.floor((name - prevIntervalName) / 2 + prevIntervalName),
        val: Math.floor(
          ((value - prevIntervalValue) / 2 + prevIntervalValue) / 10
        ),
      });
      extendedArray.push({ name: name, val: value / 10 });
      prevIntervalName = name;
      prevIntervalValue = value;
    });
    let range;
    extendedArray.forEach((interval) => {
      //Creamos una lista/vector extendiendo todos los "name" en un "value" cantidad de veces
      range = [...Array(Math.round(interval['val'])).keys()];
      const new_array = range.map((_item) => interval['name']);
      valueList = valueList.concat(new_array);
    });

    range = [...Array(4).keys()];
    const quantiles = range.map((val) => {
      return valueList[parseInt((valueList.length / 5) * (val + 1))];
    });

    return quantiles;
  };

  const getTraitIntervalPersonalityPosition = (quantiles, score) => {
    if (state.application.candidate_type === 'passive') return;

    if (score < quantiles[0]) {
      return 0;
    } else if (score < quantiles[1]) {
      return 1;
    } else if (score < quantiles[2]) {
      return 2;
    } else if (score < quantiles[3]) {
      return 3;
    } else if (score >= quantiles[3]) {
      return 4;
    }
  };

  const getTraitIntervalPosition = (percent) => {
    return Math.floor(percent / 33.4);
  };

  const getPersonalityTraits = () => {
    if (state.application.candidate_type === 'passive') return;

    const personality = state.application.game_scores.filter(
      (trait) =>
        trait.trait_id.category === 'PERSON' &&
        trait.trait_id.status === 'ACTIV'
    );

    return personality.map((obj) => {
      const quantiles = getPersonalityQuantiles(
        obj.trait_id.distribution,
        obj.trait_id.standard_deviation,
        obj.trait_id.average_score
      );

      return {
        code: obj.trait_id.code,
        candidateScore: obj.score,
        idealScore: state.jobApp.measuredTraits[obj.trait_id.id]?.score,
        relevance: state.jobApp.measuredTraits[obj.trait_id.id]?.relevance,
        measuredTraitScore: state.jobApp.measuredTraits[obj.trait_id.id]?.score,
        measuredTraitUpperScore:
          state.jobApp.measuredTraits[obj.trait_id.id]?.upper_score,
        lowerBound: obj.trait_id.lower_bound_translation[i18n.language],
        upperBound: obj.trait_id.upper_bound_translation[i18n.language],
        distribution: obj.trait_id.distribution,
        type: 'personality',
        trait: obj.trait_id.trait,
        descripcion: obj.trait_id.descripcion,
        description: obj.trait_id.description[i18n.language],
        traitTranslation: obj.trait_id.trait_translation,
        quantiles: quantiles,
        intervalDescription:
          obj.trait_id.interval_description[i18n.language] &&
          obj.trait_id.interval_description[i18n.language][
            getTraitIntervalPersonalityPosition(quantiles, obj.score)
          ],
      };
    });
  };

  const getCognitiveTraits = () => {
    if (state.application.candidate_type === 'passive') return;

    const {
      application: { game_scores },
    } = state;

    const TNUM = game_scores.find((rscore) =>
      ['TNU2', 'TNUM'].includes(rscore.trait_id.assessment_code)
    );
    const TLET = game_scores.find((rscore) =>
      ['TLE2', 'TLET'].includes(rscore.trait_id.assessment_code)
    );
    const TLOG = game_scores.find((rscore) =>
      ['TLO2', 'TLOG'].includes(rscore.trait_id.assessment_code)
    );
    const adaptive = game_scores.find((rscore) =>
      ['CATN', 'CATL'].includes(rscore.trait_id.assessment_code)
    );

    const reasoning = [TNUM, TLET, TLOG, adaptive].filter(
      (obj) => obj != undefined || obj != null
    );

    const reasoningScores = reasoning.map((obj) => ({
      code: obj.trait_id.assessment_code,
      candidateScore: obj.score,
      idealScore: state.jobApp.measuredTraits[obj.trait_id.id]?.score,
      relevance: state.jobApp.measuredTraits[obj.trait_id.id]?.relevance,
      lowerBound: obj.trait_id.lower_bound_translation[i18n.language],
      upperBound: obj.trait_id.upper_bound_translation[i18n.language],
      distribution: obj.trait_id.distribution,
      type: 'cognitive',
      trait: obj.trait_id.trait,
      description:
        obj.trait_id.candidate_description_translation[i18n.language],
      traitTranslation: obj.trait_id.trait_translation,
      intervalDescription:
        obj.trait_id.interval_description[i18n.language] &&
        obj.trait_id.interval_description[i18n.language][
          getTraitIntervalPosition(obj.score)
        ],
    }));

    const cognitive = game_scores.filter(
      (score) => score.trait_id.category == 'COGNIT'
    );

    const cognitiveScores = cognitive.map((obj) => ({
      code: obj.trait_id.code,
      candidateScore: obj.score,
      idealScore: state.jobApp.measuredTraits[obj.trait_id.id]?.score,
      relevance: state.jobApp.measuredTraits[obj.trait_id.id]?.relevance,
      lowerBound: obj.trait_id.lower_bound_translation[i18n.language],
      upperBound: obj.trait_id.upper_bound_translation[i18n.language],
      type: 'cognitive',
      distribution: obj.trait_id.distribution,
      trait: obj.trait_id.trait,
      description:
        obj.trait_id.candidate_description_translation[i18n.language],
      traitTranslation: obj.trait_id.trait_translation,
      intervalDescription:
        obj.trait_id.interval_description[i18n.language] &&
        obj.trait_id.interval_description[i18n.language][
          getTraitIntervalPosition(obj.score)
        ],
    }));

    return [...cognitiveScores, ...reasoningScores];
  };

  const getConductualTraits = () => {
    if (state.application.candidate_type === 'passive') return;

    // Conductual, cultural fit and leadership are showed in diferent places
    const behavioral = state.application.game_scores.filter(
      (score) => score.trait_id.category == 'EMOTIO' && !['LEAD', 'CFIT'].includes(score.trait.assessment_code)
    );
    const traits = behavioral.map((obj) => ({
      code: obj.trait_id.code,
      candidateScore: obj.score,
      idealScore: state.jobApp.measuredTraits[obj.trait_id.id]?.score,
      relevance: state.jobApp.measuredTraits[obj.trait_id.id]?.relevance,
      lowerBound: obj.trait_id.lower_bound_translation[i18n.language],
      upperBound: obj.trait_id.upper_bound_translation[i18n.language],
      type: 'behavioral',
      distribution: obj.trait_id.distribution,
      trait: obj.trait_id.trait,
      description:
        obj.trait_id.candidate_description_translation[i18n.language],
      traitTranslation: obj.trait_id.trait_translation,
      intervalDescription:
        obj.trait_id.interval_description[i18n.language] &&
        obj.trait_id.interval_description[i18n.language][
          getTraitIntervalPosition(obj.score)
        ],
    }));
    return traits;
  };

  const getLeadershipTraits = () => {
    if (state.application.candidate_type === 'passive') return;

    // get leadership traits
    const leadershipTraits = state.application.game_scores.filter(
      (gs) => gs.trait.assessment_code === 'LEAD'
    );
    
    const traits = [];
    if (leadershipTraits.length > 0) {
      traits.push({
        type: 'leadership',
        traits: leadershipTraits,
      });
    };
    return traits;
  };

  const getCulturalTraits = () => {
    if (state.application.candidate_type === 'passive') return;

    // traits of cultural fit
    const cultural = state.application.game_scores.filter(
      (score) => score.trait_id.category == 'BEHAV' && score.trait_id.assessment_code == 'CFIT'
    );
    if (cultural.length === 0) return [];
    const bh = cultural.map((obj) => ({
      dateCreated: obj.date_created,
      code: obj.trait_id.code,
      candidateScore: obj.score,
      idealScoreL: measuredTraits[obj.trait_id.id]?.score,
      relevance: measuredTraits[obj.trait_id.id]?.relevance,
      lowerBound: obj.trait_id.lower_bound,
      upperBound: obj.trait_id.upper_bound,
      type: 'cultural',
      distribution: obj.trait_id.distribution,
      trait: obj.trait_id.trait,
      description: obj.trait_id.descripcion,
      descripcion: obj.trait_id.description[i18n.language],
      traitTranslation: obj.trait_id.trait_translation,
      intervalDescription: obj.trait_id.interval_description,
    }));
    const culturalTraitsGroup = { type: 'cultural', traits: bh };
    return [culturalTraitsGroup];
  };

  const getKits = () => {
    if (state.application.candidate_type === 'passive') return;

    const { interviewKits } = state;

    return interviewKits
      ? interviewKits.kits.map((kit) => {
          let evalKit = interviewKits.evals.find(
            (ev) => ev.interview_kit_id == kit.interview_kit
          );
          evalKit = { ...kit, answer: evalKit.answers };
          return evalKit;
        })
      : [];
  };

  const saveKits = async (interview_kit, editGroup, createGroup, fn) => {
    if (state.application.candidate_type === 'passive') return;

    const createUrl = `/api/v1/evaluations/userjobapplication/${state.application.id}/interviewkit_eval/${interview_kit}/`;
    const editUrl = `/api/v1/evaluations/userjobapplication/${state.application.id}/interviewkit_eval/${interview_kit}/answers/`;
    let err = false;

    if (editGroup.length) {
      const answers = editGroup.map((ans) => ({
        ...ans,
        interview_kit_evaluation_id: ans.id,
      }));

      const editResponse = await actions.fetchAndReturn(token, editUrl, 'put', {
        answers: answers,
      });

      if (editResponse.status >= 400) {
        err = true;
      }
    } else {
      const createResponse = await actions.fetchAndReturn(
        token,
        createUrl,
        'POST',
        {
          answers: createGroup,
        }
      );

      if (createResponse.status >= 400) {
        err = true;
      }
    }

    if (!err) {
      message.success(
        i18n.t('profile__interview_evaluated', state.translationFile)
      );
      fn();
      return true;
    } else {
      message.error(i18n.t('profile__oops_error', state.translationFile));
      return false;
    }
  };

  const downloadCV = () => {
    return window.open(state.candidate.cv, '_blank');
  };

  const rateVideoLocally = (answerId, rating, questionText) => {
    if (state.application.candidate_type === 'passive') return;

    const url = 'videointerview/businessinterviewevaluations/';
    const data = {
      personal_user: state.candidate.id,
      interview_id: state.jobApp.interview_id,
      evaluations: {
        [answerId]: { rating: rating, question: questionText },
      },
    };
    return actions.sendData(token, url, JSON.stringify(data));
  };

  const rateCandidateLocally = (recommended) => {
    if (state.application.candidate_type === 'passive') return;

    const url = 'videointerview/businessinterviewevaluations/';
    const data = {
      personal_user: state.candidate.id,
      interview_id: state.jobApp.interview_id,
      recommended: recommended,
    };
    return actions.sendData(token, url, JSON.stringify(data));
  };

  const getMsgNotificationCount = async (nextState) => {
    const currentState = nextState ?? state;
    if (currentState.application.candidate_type === 'passive') return;

    if (!shareTokenId) {
      const url = `/api/v1/notifications/count/?personal_user=${
        currentState.nextCandidateId ?? userId
      }&notification_type=MSG&status=CREATED&job_app=${jobAppId}`;

      const response = await actions.fetchAndReturn(token, url, 'GET');
      if (response.status < 400) {
        const notificationCountResponse = response.body ? response.body : [];
        return notificationCountResponse;
      } else {
        message.error(i18n.t('profile__updated_error'), currentState.translationFile);
      }
    }
  };

  const updateNotificationsCount = () => {
    if (state.application.candidate_type === 'passive') return;

    const nextState = {
      notifications: [{ notification_type: 'MSG', count: 0 }],
    };
    return shortDispatch('HYDRATE_CANDIDATE', nextState);
  };

  // TODO update chat status
  // If chat does not exist post and enable, if exist patch is_disable
  const updateChat = async (body) => {
    if (state.application.candidate_type === 'passive') return;

    let url;
    let method;
    const chatId = state.application.chat?.id;
    if (chatId) {
      url = `/api/v2/messaging/chats/${chatId}/`;
      method = 'PATCH';
    } else {
      url = `/api/v2/messaging/chats/`;
      method = 'POST';
    }

    const response = await actions.fetchAndReturn(token, url, method, body);
    if (response.status < 400) {
      const nextState = {
        ...state,
        application: { ...state.application, chat: response.body },
      };
      return shortDispatch('HYDRATE_CANDIDATE', nextState);
    } else {
      message.error(i18n.t('profile__updated_error', state.translationFile));
    }
  };

  const updateCustomField = async (
    customFieldKey,
    customFieldNewValue,
    fieldType,
    deleteField,
    valueType
  ) => {
    const key = fieldType === 'USERJA' ? 'application' : 'candidate';
    console.log(state,"sssssss")
    if (valueType === 'file') {
      const url =
        fieldType === 'USERJA'
          ? `/api/v1/gamesandtests/userjobapplications/${state.application.id}/filecustomfields/`
          : `/api/v1/accounts/personalusers/${state.candidate.candidateId}/filecustomfields/`;
      try {
        const formData = new FormData();
        formData.append('file', customFieldNewValue);
        formData.append('name', customFieldNewValue.name);
        formData.append('name_key', customFieldKey);

        const response = await fetch(`${SERVER_URL}${url}`, {
          method: 'PATCH',
          headers: {
            Authorization: `Token ${token}`,
          },
          body: formData,
        }).then((res) => res.json());

        const nextState = {
          application: {
            ...state.application,
            custom_fields: {
              ...state.application.custom_fields,
              [key]: {
                ...state.application.custom_fields[key],
                [customFieldKey]: String(response.id),
              },
            },
          },
        };

        return shortDispatch('HYDRATE_CANDIDATE', nextState);
      } catch (e) {
        message.error('No fue posible guardar los cambios');
      }
    }
    else {
      const url =
        fieldType === 'USERJA'
          ? `/api/v1/gamesandtests/jobapplications/${jobAppId}/candidates/${
              state.application.personal_user_id ?? state.candidate.id
            }/`
          : `/api/v1/accounts/userjobapplications/${
              state.application.id
            }/candidates/${
              state.candidate.id
            }/`;
      const body = {
        custom_fields: { ...state.application.custom_fields[key] },
      };

      if (deleteField) {
        delete body.custom_fields[customFieldKey];
      } else {
        body.custom_fields[customFieldKey] = customFieldNewValue;
      }

      const response = await actions.fetchAndReturn(token, url, 'PATCH', body);
      if (response.status < 400) {
        const nextState = {
          application: {
            ...state.application,
            custom_fields: {
              ...state.application.custom_fields,
              [key]: { ...response.body.custom_fields },
            },
          },
        };
        return shortDispatch('HYDRATE_CANDIDATE', nextState);
      } 
      else {
        message.error('No fue posible guardar los cambios');
      }
    }
  };

  const onChangeVideoInterviewEval = async (
    answerId,
    newRating,
    questionText
  ) => {
    if (state.application.candidate_type === 'passive') return;

    await rateVideoLocally(answerId, newRating, questionText);
    const url = `/api/v1/videointerview/interviewevaluations/?user_pk=${state.candidate.id}&interview_id=${state.jobApp.interview_id}`;
    const { body } = await actions.fetchAndReturn(token, url);
    await Promise.all([
      videoActions.rateVideo(
        token,
        answerId,
        Math.round(body.evaluations[answerId]['rating'])
      ),
    ])
      .then(() => {
        // Personal user id, key in board state, stage(optional)
        if (updateCandidateTable && state.application.status === 'V_INTR') {
          const updateStateUrl = `gamesandtests/candidateupdate/${state.jobApp.id}/`;
          actions.sendData(
            token,
            updateStateUrl,
            JSON.stringify({
              status: 'V_INTE',
              personal_user_id: state.candidate.id,
            }),
            'PATCH'
          );
          updateCandidateTable(
            state.candidate.id,
            // Current stage
            'V_INTR',
            // Next stage
            'V_INTE'
          );
          shortDispatch('UPDATE_STATUS', 'V_INTE');
        }
        message.success(i18n.t('profile__ve_evaluated', state.translationFile));
      })
      .catch((e) => {
        message.error(
          i18n.t('profile__ve_evaluated_error', state.translationFile)
        );
      });
  };

  const onChangeRecommendation = async (recommendation) => {
    await Promise.all([rateCandidateLocally(recommendation)])
      .then(() => {
        message.success(
          i18n.t('profile__recommendation_updated', state.translationFile)
        );
      })
      .catch(() => {
        message.error(
          i18n.t('profile__recommendation_updated_error', state.translationFile)
        );
      });
  };

  const preparePDF = async () => {
    const {
      candidate_type,
      date_completed,
      date_started,
      final_score,
      ko_answers,
      note,
      references,
      status,
      video_interview_ready,
    } = state.application;
    const {
      age,
      birth_date,
      careers,
      commune,
      country,
      disability,
      disability_type,
      dni,
      education_level,
      email,
      experiences,
      full_phone,
      gender,
      // TODO: Deprecar
      has_disability,
      // marital_status,
      nationality,
      user_name,
    } = state.candidate;

    let url = `/api/v1/videointerview/interviewevaluations/?user_pk=${state.candidate.id}&interview_id=${state.jobApp.interview_id}`;
    const information = {
      cognitiveScores: getCognitiveTraits(),
      personalityScores: getPersonalityTraits(),
      behavioralScores: getConductualTraits(),
      videoInterview: [],
      koAnswers:
        ko_answers && ko_answers.answer
          ? Object.keys(ko_answers.answer).map((key) => ko_answers.answer[key])
          : [],
      candidate: {
        status,
        name: user_name,
        email,
        dni,
        age,
        sex: gender,
        // maritalStatus: marital_status,
        nationality: getCountryTranslation(nationality),
        country,
        commune,
        finalScore: final_score,
        gender,
        // TODO: Deprecar
        hasDisability: has_disability,
        disability: disability,
        disabilityType: disability_type,
        careers,
        educationLevel: education_level,
        experiences,
        phone: full_phone,
        full_phone,
        date_started,
        // finalScore: final_score,
        references,
        birth_date: birth_date,
        date_completed: date_completed?.replace(/\//g, '-'),
      },
      jobApplication: {
        stages: state.jobApp.stages,
        name: state.jobApp.job_application,
        logo: state.jobApp.business_logo,
        ...state.jobApp,
      },
      interviewsKit: getKits(),
      notes: note,
      globalVInt: null,
      videoInterviewReady: video_interview_ready,
    };

    if (state.jobApp.interview_id) {
      information.globalVInt = await actions
        .fetchAndReturn(token, url)
        .then(({ body }) => {
          return body;
        });
    }

    if (candidate_type !== 'passive' && state.jobApp.interview_id) {
      information.videoInterview = await actions
        .fetchAndReturn(token, url)
        .then(({ body }) => {
          const values = videoInterview.map((obj) => ({
            question: obj.question,
            rating:
              obj.answer.length > 0 && body.evaluations[obj.answer[0].id]
                ? body.evaluations[obj.answer[0].id].rating
                : 0,
            videoUrl: obj.answer.length != 0 ? obj.answer[0].video.url : '',
          }));
          return values;
        });
    }

    return information;
  };

  const resetCuteAnswer = async (cuteId, callback) => {
    if (state.application.candidate_type === 'passive') return;
    const url = `/api/v1/gamesandtests/resetcute/`;
    const data = {
      cute_id: cuteId,
      personal_user_id: state.candidate.id,
      job_app_id: state.jobApp.id,
    };
    const res = await actions.fetchAndReturn(token, url, 'DELETE', data);
    if (res.status < 400) {
      message.success(
        i18n.t('profile__reset_successfully', state.translationFile),
        7
      );
      const nextState = {
        customTests: state.customTests.map((test) =>
          test.answer.custom_test.id == cuteId
            ? { ...test, answer: { ...test.answer, error: {} } }
            : test
        ),
      };
      callback();
      return shortDispatch('HYDRATE_CANDIDATE', nextState);
    } else {
      message.error(i18n.t('profile__reset_error', state.translationFile), 9);
      return;
    }
  };

  const requestCuTeSurvey = (userEmail, userName, customTest) => {
    const url = `/api/v1/gamesandtests/customtestsurveys/`;
    const data = {
      user_email: userEmail,
      user_name: userName,
      custom_test: customTest,
      user_job_application: state.application.id,
    };
    actions.fetchAndReturn(token, url, 'POST', data).then((response) => {
      if (response.status < 400) {
        getCustomTestSurveys(state.application.id);
        message.success(
          i18n.t('profile__questionnaire_sent_ok', state.translationFile)
        );
      } else if (response.status == 409) {
        message.warning(
          i18n.t('Encuesta ya fue enviada a este correo', state.translationFile)
        );
      } else {
        message.error(
          i18n.t('profile__questionnaire_sent_error', state.translationFile)
        );
      }
    });
  };

  const getCountryTranslation = async (country) => {
    const url = '/api/v1/constants/countries/?p=[country,translation]';
    const countryTranslation = await actions
      .fetchAndReturn('', url, 'GET')
      .then((response) => {
        const countryWithTranslation = response.body.filter(
          (c) => c.country?.toLowerCase() === country?.toLowerCase()
        );
        return countryWithTranslation.length > 0
          ? countryWithTranslation[0].translation[i18n.language]
          : country;
      });
    return countryTranslation;
  };

  const updatePassiveCandidateData = async () => {
    const data = await fetchCandidateAndApplicationData();
    shortDispatch('HYDRATE_CANDIDATE', { ...data });
  };

  const memoValues = useMemo(
    () => ({
      ...state,
      saveNote,
      handleFixLockedGames,
      onChangeCandidate,
      loading,
      updateStatus,
      askForReference,
      sendReferencesQuestionnaire,
      completeReferenceQuestionnaire,
      getPersonalityTraits,
      getCognitiveTraits,
      getConductualTraits,
      getCulturalTraits,
      getLeadershipTraits,
      videoInterview,
      getKits,
      measuredTraits,
      actions,
      discardReasons,
      token,
      userName,
      preparePDF,
      downloadCV,
      emailView,
      handleEmailView,
      closeModal,
      saveKits,
      videoActions,
      onChangeVideoInterviewEval,
      onChangeRecommendation,
      topRef,
      mode,
      sharing: shareTokenId ? true : false,
      updateNotificationsCount,
      updateChat,
      updateCustomField,
      logoURL,
      resetCuteAnswer,
      tabKey,
      changeKey,
      reclassifyCandidate,
      getCountryTranslation,
      discardCandidate,
      compareCandidates,
      mergeCandidates,
      confirmMerge,
      // resetFilesRequests,
      editCandidateDiscardReason,
      updatePassiveCandidateData,
      requestCuTeSurvey,
      addOns,
      permissions,
      fetchMailHistory,
    }),
    [
      emailView,
      state.application,
      state.candidate,
      state.customTests,
      state.filesRequests,
      state.customTestSurveys,
      state.mergingCandidates,
      state.nextCandidateId,
      state.notifications,
      state.possible_duplicates,
      tabKey,
    ]
  );

  return (
    <CandidateContext.Provider value={memoValues}>
      {children}
    </CandidateContext.Provider>
  );
};
