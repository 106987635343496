import React, { Fragment, useContext } from 'react'

import {
  Row, Modal
} from 'antd';

import Evaluation from './Evaluation';

import JobAppContext from '../../../context/JobAppContext';

function EvaluationList() {

  const {
    jobApplication,
    assignedActivities,
    cuTes,
    filesRequests,
    games,
    videoInterviews,
    interviewProcesses,
    additionalQuestions,
    currentStage,
    stages,
    updateCuTes,
    updateFilesRequests,
    updateGames,
    updateVideoInterviews,
    updateInterviewProcesses,
    updateAdditionalQuestions,
  } = useContext(JobAppContext);

  const saveUpdatedGames = (stageGames) => {
    // Update stage evaluations
    updateGames('currentEvaluations', stageGames.currentEvaluations);
    updateGames('toUpdate', stageGames.toUpdate);
    updateGames('toAdd', stageGames.toAdd);
    updateGames('toRemove', stageGames.toRemove);
  };

  const saveUpdatedCutes = (stageCuTes) => {
    // Update stage evaluations
    updateCuTes('currentEvaluations', stageCuTes.currentEvaluations);
    updateCuTes('toUpdate', stageCuTes.toUpdate);
    updateCuTes('toAdd', stageCuTes.toAdd);
    updateCuTes('toRemove', stageCuTes.toRemove);
  };

  const saveUpdatedVideoInterviews = (stageVideoInterviews) => {
    // Update stage evaluations
    updateVideoInterviews('currentEvaluations', stageVideoInterviews.currentEvaluations);
    updateVideoInterviews('toUpdate', stageVideoInterviews.toUpdate);
    updateVideoInterviews('toAdd', stageVideoInterviews.toAdd);
    updateVideoInterviews('toRemove', stageVideoInterviews.toRemove);
  };

  const saveUpdatedInterviewProcesses = (stageInterviewProcesses) => {
    // Update stage evaluations
    updateInterviewProcesses('currentEvaluations', stageInterviewProcesses.currentEvaluations);
    updateInterviewProcesses('toUpdate', stageInterviewProcesses.toUpdate);
    updateInterviewProcesses('toAdd', stageInterviewProcesses.toAdd);
    updateInterviewProcesses('toRemove', stageInterviewProcesses.toRemove);
  };

  const saveUpdatedAdditionalQuestions = (stageAdditionalQuestions) => {
    // Update stage evaluations
    updateAdditionalQuestions('currentEvaluations', stageAdditionalQuestions.currentEvaluations);
    updateAdditionalQuestions('toUpdate', stageAdditionalQuestions.toUpdate);
    updateAdditionalQuestions('toAdd', stageAdditionalQuestions.toAdd);
    updateAdditionalQuestions('toRemove', stageAdditionalQuestions.toRemove);
  };

  const saveUpdatedFilesRequests = (stageFilesRequests) => {
    // Update stage evaluations
    updateFilesRequests('currentEvaluations', stageFilesRequests.currentEvaluations);
    updateFilesRequests('toUpdate', stageFilesRequests.toUpdate);
    updateFilesRequests('toAdd', stageFilesRequests.toAdd);
    updateFilesRequests('toRemove', stageFilesRequests.toRemove);
  };

  const evaluationDict = {
    'cute': structuredClone(cuTes),
    'game': structuredClone(games),
    // Solo es 1 por etapa a pesar de que tengamos un array
    'videoInterview': structuredClone(videoInterviews),
    'additionalQuestion': structuredClone(additionalQuestions),
    'filesRequest': structuredClone(filesRequests),
    'interviewProcesses': structuredClone(interviewProcesses),
  };

  const onDeleteEvaluation = (id, type) => {
    const stageEvaluations = evaluationDict[type];

    const currentEvaluations = [...stageEvaluations.currentEvaluations];
    const toUpdate = [...stageEvaluations.toUpdate];
    const toAdd = [...stageEvaluations.toAdd];
    const toRemove = [...stageEvaluations.toRemove];

    const currentIdx = currentEvaluations.findIndex(obj => obj.id === id);
    const toUpdateIdx = toUpdate.findIndex(obj => obj.id === id)
    const toAddIdx = toAdd.findIndex(obj => obj.id === id)

    // Siempre remover de update
    if (toUpdateIdx != -1) {
      toUpdate.splice(toUpdateIdx, 1);
      stageEvaluations.toUpdate = toUpdate
    }
    // Remover de toAdd si existe, si no agregar a toRemove
    if (toAddIdx != -1) {
      toAdd.splice(toAddIdx, 1);
      stageEvaluations.toAdd = toAdd;
    }
    else {
      toRemove.push({ ...currentEvaluations[currentIdx] });
      stageEvaluations.toRemove = toRemove;
    }
    // Remover de currentEvaluations
    if (currentIdx != -1) {
      currentEvaluations.splice(currentIdx, 1);
      stageEvaluations.currentEvaluations = currentEvaluations;
    }

    switch (type) {
      case 'game':
        saveUpdatedGames({ ...stageEvaluations })
        break
      case 'cute':
        saveUpdatedCutes({ ...stageEvaluations })
        break
      case 'videoInterview':
        saveUpdatedVideoInterviews({ ...stageEvaluations })
        break
      case 'additionalQuestion':
        saveUpdatedAdditionalQuestions({ ...stageEvaluations })
        break
      case 'filesRequest':
        saveUpdatedFilesRequests({ ...stageEvaluations })
        break
      case 'interviewProcesses':
        saveUpdatedInterviewProcesses({ ...stageEvaluations })
        break
    }
  };

  const deleteEvaluationConfirm = (id, type) => {
    Modal.confirm({
      title: 'Cofirma para eliminar esta evaluación',
      content: 'Esta accion es irreversible',
      cancelText: 'Cancelar',
      okText: 'Confirmar',
      onOk: () => {
        onDeleteEvaluation(id, type)
      }
    });
  };

  const onChangeEvaluation = (id, newStage, type) => {
    const stageEvaluations = evaluationDict[type];

    const currentEvaluations = [...stageEvaluations.currentEvaluations];
    const toAdd = [...stageEvaluations.toAdd];
    const toUpdate = [...stageEvaluations.toUpdate];

    const tmpCuTe = currentEvaluations.find(obj => obj.id === id);
    tmpCuTe.stage = newStage;

    const toAddIdx = toAdd.findIndex(obj => obj.id === id)
    const toUpdateIdx = toUpdate.findIndex(obj => obj.id === id)

    // No es posible estar en toAdd y toUpdate al mismo tiempo, si esta en toAdd, actualizar ese obj
    if (toAddIdx != -1) {
      toAdd[toAddIdx].stage = newStage;
    }
    // Si no esta en add hay que updatear
    if (toAddIdx == -1) {
      // Si no esta en update agregarlo caso contrario editar lo que ya existe
      toUpdateIdx == -1 ? toUpdate.push(tmpCuTe) : toUpdate[toUpdateIdx] = tmpCuTe;
    }

    stageEvaluations.currentEvaluations = currentEvaluations;
    stageEvaluations.toUpdate = toUpdate

    switch (type) {
      case 'game':
        saveUpdatedGames({ ...stageEvaluations })
        break
      case 'cute':
        saveUpdatedCutes({ ...stageEvaluations })
        break
      case 'videoInterview':
        saveUpdatedVideoInterviews({ ...stageEvaluations })
        break
      case 'additionalQuestion':
        saveUpdatedAdditionalQuestions({ ...stageEvaluations })
        break
      case 'filesRequest':
        saveUpdatedFilesRequests({ ...stageEvaluations })
        break
      case 'interviewProcesses':
        saveUpdatedInterviewProcesses({ ...stageEvaluations })
        break
    }
  }

  const getEvaluationsList = () => {

    // Formulario postulación
    const form = (stages.findIndex(obj => obj.id === currentStage) != 0) ?
      [] :
      [<Evaluation key={'form-eval'} evaluationType='form' name={'Formulario de postulación'} required={true} data={{ stage: currentStage }} />];

    // Juegos y basal
    // Filtrar todos los juegos que tengan assigned activity con config required
    let genome = [];
    let gamesEvals = [];
    if (['COGNI', 'BASAL', 'EXPER'].includes(jobApplication.genome?.category)) {
      games?.currentEvaluations &&
        games.currentEvaluations
          .filter(obj => obj.stage === currentStage)
          .forEach(obj => {
            const isRequired = assignedActivities.find(assAct => JSON.parse(assAct.configuration).required == true && obj.id == assAct.activity_id)
            const gameData = {
              id: obj.id,
              name: obj.translation.es,
              stage: obj.stage,
            };
            if (isRequired) {
              genome.push(gameData);
            }
            else {
              gamesEvals.push(gameData);
            }
          });
    }
    // No es basal, todos los juegos vienen sueltos
    else {
    games?.currentEvaluations &&
      games.currentEvaluations
        .filter(obj => obj.stage === currentStage)
        .forEach(obj => {
          let required = assignedActivities.find(assAct => assAct.activity.code == obj.code)
          required = required ? JSON.parse(required.configuration)?.required : false;
          const gameData = {
            id: obj.id,
            name: obj.activity,
            required: required,
            stage: obj.stage,
          };
          gamesEvals.push(gameData);
        });
  };

  genome = genome.length > 0 ?
    [<Evaluation key={'genome-evals'} evaluationType='genome' name={jobApplication.genome.genome} data={genome} required={true} />] :
    [];

  gamesEvals = gamesEvals.map((obj, idx) =>
    <Evaluation
      key={`game-${idx}`}
      evaluationType='game'
      name={obj.name}
      data={obj}
      required={obj.required}
      onChangeEvaluation={onChangeEvaluation}
      onDeleteEvaluation={deleteEvaluationConfirm}
    />
  )

  // TODO: por cada cute/ko revisar si es required y luego agregar bloque con dropdown correspondiente
  // Agregar formulario de postulacion
  const additionalQuestionsEvals = additionalQuestions?.currentEvaluations
    &&
    additionalQuestions.currentEvaluations
      .filter(obj => obj.stage === currentStage)
      .map((obj, idx) =>
        <Evaluation
          key={`question-${idx}`}
          evaluationType='additionalQuestion'
          name={'Preguntas adicionales'}
          data={obj}
          required={false}
          onChangeEvaluation={onChangeEvaluation}
          onDeleteEvaluation={deleteEvaluationConfirm}
        />
      )

  const cuTesEvals = cuTes?.currentEvaluations
    &&
    cuTes.currentEvaluations
      .filter(obj => obj.stage === currentStage)
      .map((obj, idx) =>
        <Evaluation
          key={`cute-${idx}`}
          evaluationType='cute'
          name={obj.title}
          data={obj}
          required={false}
          onChangeEvaluation={onChangeEvaluation}
          onDeleteEvaluation={deleteEvaluationConfirm}
        />
      )

  const videoInterviewsEvals = videoInterviews?.currentEvaluations
    &&
    videoInterviews.currentEvaluations
      .filter(obj => obj.stage === currentStage)
      .map((obj, idx) =>
        <Evaluation
          key={`video-${idx}`}
          evaluationType='videoInterview'
          name={'Video entrevista'}
          data={obj}
          required={false}
          onChangeEvaluation={onChangeEvaluation}
          onDeleteEvaluation={deleteEvaluationConfirm}
        />
      )

  const interviewProcessEvals = interviewProcesses?.currentEvaluations
    &&
    interviewProcesses.currentEvaluations
      .filter(obj => obj.stage === currentStage)
      .map((obj, idx) =>
        <Evaluation
          key={`tera-${idx}`}
          evaluationType='interviewProcesses'
          name={'Entrevista por competencias'}
          data={obj}
          required={false}
          onChangeEvaluation={onChangeEvaluation}
          onDeleteEvaluation={deleteEvaluationConfirm}
        />
      )
  
  const filesRequestsEvals = filesRequests?.currentEvaluations
  &&
  filesRequests.currentEvaluations
    .filter(obj => obj.stage === currentStage)
    .map((obj, idx) =>
      <Fragment key={`${idx}-${obj.name}`}>
        <Evaluation
          evaluationType='filesRequest'
          name={obj.name}
          data={obj}
          required={false}
          onChangeEvaluation={onChangeEvaluation}
          onDeleteEvaluation={deleteEvaluationConfirm}
        />
      </Fragment>
    )
  return [].concat.apply([], [form, genome, gamesEvals, additionalQuestionsEvals, cuTesEvals, videoInterviewsEvals, filesRequestsEvals, interviewProcessEvals])
};

return (
  <Row gutter={[20, 20]}>
    {
      getEvaluationsList()
    }
  </Row>
);
};

export default EvaluationList;