import React from 'react';
import { Row, Col, Typography } from 'antd';
import HeatmapPDF from './HeatmapPDF';

import '../../pdf-css.scss';

function FifthPagePdf({ candidates }) {
  return (
    <Row>
      <Row className='dividers'>
        <p>Mapa de calor de Rasgos</p>
      </Row>
      <Row type='flex' justify='space-between' style={{ margin: '20px 0' }}>
        <Col span={5}>
          <p className='heatmap-title'>Rasgos Cognitivos</p>
        </Col>
        <Col span={15}>
          <Row type='flex' justify='space-around'>
            <Col span={7}>
              <Row type='flex' justify='center'>
                <Row
                  style={{
                    width: '48px',
                    height: '25px',
                    background: '#FFADD2',
                  }}
                />
                <Typography.Text style={{ margin: '0 0 0 5px' }}>
                  Bajo
                </Typography.Text>
              </Row>
            </Col>
            <Col span={7}>
              <Row type='flex' justify='center'>
                <Row
                  style={{
                    width: '48px',
                    height: '25px',
                    background: '#F759AB',
                  }}
                />
                <Typography.Text style={{ margin: '0 0 0 5px' }}>
                  Medio
                </Typography.Text>
              </Row>
            </Col>
            <Col span={7}>
              <Row type='flex' justify='center'>
                <Row
                  style={{
                    width: '48px',
                    height: '25px',
                    background: '#C41D7F',
                  }}
                />
                <Typography.Text style={{ margin: '0 0 0 10px' }}>
                  Alto
                </Typography.Text>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <HeatmapPDF candidates={candidates} type='cognitive' />
      <Row style={{ margin: '20px 0' }}>
        <Col span={24}>
          <p className='heatmap-title'>Rasgos de Razonamiento</p>
        </Col>
      </Row>
      <HeatmapPDF candidates={candidates} type='reasoning' />
      <Row style={{ minHeight: 45 }} />
    </Row>
  );
}

export default FifthPagePdf;
