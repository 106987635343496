import React from 'react';
import { Row, Col, Typography } from 'antd';
import { genderOptions } from '../../../../constants/personalUser';

const { Text } = Typography;

const Traits = ({ candidates, scrollRefs }) => {
  return (
    <div className='traits-section-container' ref={scrollRefs}>
      <Row className='traits-section-container__table'>
        {['Edad', 'Género', 'Nivel educacional', 'Discapacidad'].map(
          (rasgos, index) => (
            <Row
              type='flex'
              align='middle'
              justify='center'
              className='traits-section-container__rows'
            >
              <Col span={2}>
                <Row type='flex' align='middle' justify='start'>
                  <Text strong>{rasgos}</Text>
                </Row>
              </Col>
              <Col span={19}>
                <Row type='flex' justify='space-around'>
                  {rasgos === 'Edad' &&
                    candidates.map((item) => (
                      <Col key={item.id}>
                        <Text>{item.age}</Text>
                      </Col>
                    ))}
                  {rasgos === 'Género' &&
                    candidates.map((item) => (
                      <Col key={item.id}>
                        <Text>
                          {genderOptions[item.gender]}
                        </Text>
                      </Col>
                    ))}
                  {rasgos === 'Nivel educacional' &&
                    candidates.map((item) => (
                      <Col key={item.id}>
                        {item.education_level.length > 15 ? (
                          <>
                            {item.education_level.slice(0, 8)}
                            <br />
                            {item.education_level.slice(8, 20)}
                            <br />
                            {item.education_level.slice(20)}
                          </>
                        ) : item.education_level.length === 0 ? (
                          <Text>Sin datos</Text>
                        ) : (
                          <Text>{item.education_level}</Text>
                        )}
                      </Col>
                    ))}
                  {rasgos === 'Discapacidad' &&
                    candidates.map((item) => (
                      <Col key={item.id}>
                        <Text>{item.disability_type}</Text>
                      </Col>
                    ))}
                </Row>
              </Col>
            </Row>
          )
        )}
      </Row>
    </div>
  );
};

export default Traits;
