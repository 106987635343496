import React from 'react';

import { Row, Typography, Col, Icon } from 'antd';

const { Text } = Typography;

const VideoInterviewPDF = ({ candidates }) => {
  const questions = [];

  candidates.map((candidate) => {
    if (Object.values(candidate['video_interview_scores']).length > 0) {
      Object.values(candidate['video_interview_scores']).map((q) => {
        const questionNumber = Object.keys(
          candidate['video_interview_scores']
        ).filter((q) => q !== 'average').length;
        if (questions.length < questionNumber && typeof q === 'object') {
          questions.push(q.question);
        }
      });
    }
  });

  return (
    <Row className='interview-section-container'>
      {candidates[0] ? (
        questions.length > 0 ? (
          questions.map((question, index) => (
            <Row type='flex'>
              <Col key={index} span={6}>
                <Text strong style={{ fontSize: 12 }}>{question}</Text>
              </Col>
              <Col key={index} span={18}>
                <Row type='flex' justify='space-around'>
                  {
                    // Esta logica esta buena
                    candidates[0]
                      ? candidates.map((candidate, candidateIndex) => {
                        const evals = Object.values(candidate['video_interview_scores']).filter(item => typeof item !== 'number')
                        return (
                          Object.values(candidate['video_interview_scores']).length >
                            0 ? (

                            <Col key={index}>
                              <Row type='flex' justify='center' align='middle'>
                                <Icon
                                  type='star'
                                  theme='filled'
                                  className='interview-section-container__icon'
                                />
                                <Text className='interview-section-container__text interview-section-container__text--icon'>
                                  {evals[index]?.rating}
                                </Text>
                              </Row>
                            </Col>


                          ) : questions.length > 0 ? (
                            questions.map((q) => (
                              <Col key={q}>
                                <Text className='interview-section-container__text interview-section-container__text--no-rate'>
                                  Sin puntaje
                                </Text>
                              </Col>
                            ))
                          ) : (
                            <Col>
                              <Text>Sin puntaje</Text>
                            </Col>
                          )
                        )
                      }
                      )
                      : null
                  }
                </Row>
              </Col>
            </Row>

          ))
        ) : (
          <Text strong>
            Sin video entrevistas evaluadas
          </Text>
        )
      ) : null}
    </Row>
  );
};

export default VideoInterviewPDF;
