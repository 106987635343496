import React, { useState } from 'react';
import { AnalyticsProvider } from './context/AnalyticsContext';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../../actions/data';

import './NewAnalytics.scss';

import useScreen from '../../../hooks/useScreen';

import AnalyticsApp from './Analytics';

function Analytics(props) {
  const [overview, setOverview] = useState(true)
  const [candidates, setCandidates] = useState(false)

  const width = useScreen(300);

  const isBelow1200px = width <= 1200;

  return (
    <AnalyticsProvider {...props}>
      <div className="new-analytics">
        <AnalyticsApp
          overview={overview}
          setOverview={setOverview}
          candidates={candidates}
          setCandidates={setCandidates}
        />
      </div>
    </AnalyticsProvider>
  );
}


const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
