import React from 'react'
import { Card, Typography, Button } from 'antd'
import '../Feedback.scss'
import i18n from '../../../../i18n'

const SurveyElement = (props) => {

  const { Title, Text } = Typography
  const { Meta } = Card

  const buttonVars = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

  return (
    <Card
      className='survey-elements'
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
      <Title level={4} >{i18n.t('feedback__evaluation__title')}</Title>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: '20px' }}>
        <Text style={{ marginRight: '10px' }} >{i18n.t('feedback__bad')}</Text>
        <Meta
          style={{ display: 'flex', justifyContent: 'space-around' }}
          description={buttonVars.map((item) => (
            <Button
              style={{ minWidth: 35, margin: '2px' }}
              size='small'
              disabled={props.satisfactionClicked}
              onClick={() => props.scoreSelectionHandler(item)}>
              {item}
            </Button>
          )
          )}
        />
        <Text style={{ marginLeft: '10px' }} >{i18n.t('feedback__excellent')}</Text>
      </div>
    </Card>
  )
}

export default SurveyElement
