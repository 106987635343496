import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Sentry from '@sentry/browser';

import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Typography,
} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

import * as actionCreators from '../../../../actions/data';
import i18n from '../../../../i18n';
import { monthsOptions, personalDataFields } from '../constants';
import useBoolean from '../../../../hooks/useBoolean';

const { Text, Title } = Typography;

function EditPersonalDataModal(props) {
  const { closeModal, countriesOptions, data, form, setData, token } = props;

  const { getFieldDecorator, getFieldsValue, setFieldsValue } = form;

  const [loading, handleLoading] = useBoolean(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        Object.keys(values).forEach((fieldName) => {
          if (values[fieldName] === undefined) {
            // TODO: Allow null True for marital_status in the backend
            values[fieldName] = fieldName === 'marital_status' ? '' : null;
          }
        });
        const data = {
          ...values,
          birth_date: values.birth_date?.format('YYYY-MM-DD') ?? null,
        }
        handleLoading.on();
        props.actions.fetchAndReturn(token, '/api/v1/accounts/personal/', 'PATCH', data)
          .then(({ body, status }) => {
            if (status >= 400) {
              message.error(i18n.t('profile__error_updating'));
            } else if (body) {
              setData({
                ...body,
                birth_date: body.birth_date ? moment(body.birth_date) : null,
                certifications: body.certifications?.map((certification) => ({
                  ...certification,
                  start_date: certification.start_date ? moment(certification.start_date) : null,
                  end_date: certification.end_date ? moment(certification.end_date) : null,
                })),
                country: body.country
                  ? `${body.country.charAt(0).toUpperCase()}${body.country.slice(1)}`
                  : null,
                experiences: body.experiences?.map((experience) => ({
                  ...experience,
                  month_start: experience.month_start ? monthsOptions[parseInt(experience.month_start)] : null,
                  month_end: experience.month_end ? monthsOptions[parseInt(experience.month_end)] : null,
                })),
                nationality: body.nationality
                  ? `${body.nationality.charAt(0).toUpperCase()}${body.nationality.slice(1)}`
                  : null,
              });
              message.success(i18n.t('form__successfully_uploading'));
            }
          })
          .catch((error) => {
            Sentry.captureException(error);
            message.error(i18n.t('profile__error_updating'));
          })
          .finally(() => {
            handleLoading.off();
            closeModal();
          });
      }
    });
  };

  return (
    <Modal
      wrapClassName='edit-personal-data-modal'
      footer={null}
      onCancel={closeModal}
      visible
      width={800}
    >
      <Row gutter={[0, 45]} type="flex">
        <Col>
          <Title level={4}>
            {i18n.t('commons__personal__data')} 
          </Title>
        </Col>
      </Row>

      <Form>
        <Row gutter={[15, 15]} type="flex">
          <Col span={12}>
            <Form.Item
              className="profile-label"
              colon={false}
              label={<><Text style={{ color: 'red' }}>*</Text>DNI</>}
            >
              {getFieldDecorator('dni', {
                initialValue: data.dni,
              })(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="profile-label"
              colon={false}
              label={<><Text style={{ color: 'red' }}>*</Text>{i18n.t('commons__email')}</>}
            >
              {getFieldDecorator('email', {
                initialValue: data.email,
              })(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="profile-label"
              colon={false}
              label={<><Text style={{ color: 'red' }}>*</Text>{i18n.t('form__names')}</>}
            >
              {getFieldDecorator("name", {
                initialValue: data.name,
                rules: [{ required: true, message: i18n.t('commons__required__field') }],
              })(<Input allowClear />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="profile-label"
              colon={false}
              label={<><Text style={{ color: 'red' }}>*</Text>{i18n.t('form__lastname')}</>}
            >
              {getFieldDecorator("surname", {
                initialValue: data.surname,
                rules: [{ required: true, message: i18n.t('commons__required__field') }],
              })(<Input allowClear />)}
            </Form.Item>
          </Col>
          {countriesOptions
            ? Object.entries(personalDataFields).map(
              ([key, { clear, condition, extra, label, input, onChange, span, valuePropName }]) => (
                <Col
                  key={`col-${key}`}
                  xs={span <= 12 ? (span * 2) : span}
                  sm={span}
                  style={{
                    display: (condition === undefined || condition(getFieldsValue())) ? 'block' : 'none'
                  }}
                >
                  <Form.Item
                    className="profile-label"
                    colon={false}
                    label={
                      label ? (
                        <>
                          {label}
                          {!['phone', 'disability_visible'].includes(key)
                            ? (
                              <Text style={{ color: '#A4A4A4', fontWeight: 'normal' }}>
                                {` (${i18n.t('commons__optional')})`}
                              </Text>
                            ) : null}
                        </>
                      ) : (<Text style={{ color: 'transparent' }}>-</Text>)
                    }
                  >
                    <Row align="middle" style={{ width: '100%' }} type="flex">
                      <Col span={clear ? 23 : 24}>
                        {getFieldDecorator(key, {
                          initialValue: (data[key] && ['country', 'nationality'].includes(key))
                            ? `${data[key].charAt(0).toUpperCase()}${data[key].slice(1)}`
                            : data[key],
                          onChange: onChange ? (event) => onChange(event, setFieldsValue) : undefined,
                          valuePropName: valuePropName ?? 'value',
                        })(input(countriesOptions))}
                      </Col>
                      {clear
                        ? (
                          <Col span={1}>
                            {clear(setFieldsValue)}
                          </Col>
                        ) : null}
                    </Row>
                    {extra ?? null}
                  </Form.Item>
                </Col>
              )
            ) : null}
        </Row>
        <Row gutter={8} justify="end" type="flex">
          <Col>
            <Form.Item className="profile-label">
              <Button disabled={loading} onClick={closeModal}>{i18n.t('commons__cancel')}</Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item className="profile-label">
              <Button loading={loading} onClick={handleSubmit} type="primary">{i18n.t('commons__save')}</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

const personalDataForm = Form.create({ name: 'edit-personal-data' })(EditPersonalDataModal);

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actionCreators, dispatch),
  dispatch,
});

personalDataForm.propTypes = {
  actions: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(personalDataForm);
