import React from 'react';
import { Form, Input, Row, Col, Typography } from 'antd';

const { Text } = Typography;

const DefaultForm = ({ getFieldDecorator, maxLength, initialValue }) => {
  const [value, setValue] = React.useState('');

  return (
    <Form.Item label='¿Cuál es tu pregunta?'>
      {getFieldDecorator('question', {
        rules: [{ required: true, message: 'Por favor escribe tu pregunta' }],
        initialValue
      })(
        <Input
          onChange={({ target }) => setValue(target.value)}
          maxLength={maxLength}
        />
      )}
      <Row type='flex' justify='end'>
        <Col span={2.5}>
          <Text disabled>{`${value.length}/${maxLength}`}</Text>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default DefaultForm;
