import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

const useBreadcrumb = (items, separator) => {
  return (
    <Breadcrumb separator={separator}>
      {items.map((item, index) => (
        <Breadcrumb.Item key={item.text}>
          <Link to={item.path} disabled={index === items.length - 1}>
            {item.text}
          </Link>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default useBreadcrumb;
