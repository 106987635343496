import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import * as actionCreators from '../../actions/data';
import i18n from '../../i18n';

import {
  Row,
  Col,
  Button,
  Typography,
  Popconfirm,
  Icon,
  Modal,
  Input,
  Alert,
  message
} from 'antd';

import './styles.scss';

import GenominSpin from '../../components/GenominSpin/GenominSpin';

import useBoolean from '../../hooks/useBoolean';

const { Text, Title, Paragraph } = Typography;

const deleteStyle = { border: '1px solid #FF4D4F', color: '#FF4D4F' };

const CandidateInterview = ({ actions, match, auth, dispatch }) => {
  const [jobApp, setJobApp] = useState({ business: { color_theme: {} } });
  const [candidateInterview, setInterview] = useState({ interview: {} });
  const [comment, setComment] = useState(null);

  const [showModalDelete, handleDeleteModal] = useBoolean(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const base = '/api/v1/';
        const urls = [
          `${base}gamesandtests/jobapplications/${match.params.jobappid}/public/`,
          `${base}evaluations/personalinterviews/${match.params.personalInterview}/`,
        ];

        const [{ body: jobapp }, { body: interview }] = await Promise.all(
          urls.map((url) => actions.fetchAndReturn(auth.token, url, 'GET'))
        );

        setJobApp(jobapp);
        if (interview === null){
          dispatch(push("/jobapplication/" + match.params.jobappid + "/"))
        }
        else{
          setInterview(interview);
        }
      } catch (e) {
        console.error(e);
        message.error("Error")
        dispatch(push("/jobapplication/" + match.params.jobappid + "/"))
      }
    };
    getData();
  }, []);

  const updatePersonalInterview = (status) => {
    const url = `/api/v1/evaluations/personalinterviews/${match.params.personalInterview}/`;
    actions.fetchAndReturn(auth.token, url, 'PATCH', { status: status, comment: comment }).then(response => {
      if (response.status < 400) {
        setInterview(response.body);
        message.success(i18n.t('commons__save_success'));
      }
      else {
        message.error(i18n.t('commons__save_failure'));
      }
    })
  };

  const getDeltaTime = () => {
    const start = moment(candidateInterview.interview.interview_date, 'DD-MM-YYYY HH:mm:ss Zz');
    const end = moment(candidateInterview.interview.interview_end_date, 'DD-MM-YYYY HH:mm:ss Zz')
    return moment.duration(end.diff(start)).asMinutes();
  };

  const interviewTypes = {
    'ONSITE': i18n.t('interview__onsite'),
    'GOOGLE': 'Google Meet',
    'TEAMS': 'Microsoft Teams',
    'ZOOM': 'Zoom',
  }

  const styleStatus = {
    'PENDING': { fontWight: 'bolder', color: '#FAAD14' },
    'ACCEPTED': { fontWight: 'bolder', color: '#52C41A' },
    'DECLINED': { fontWight: 'bolder', color: '#FF4D4F' },
  };

  return candidateInterview?.interview?.title ? (
    <>
      <div className="candidate-interview">
        <Button icon="arrow-left" type="link" onClick={() => dispatch(push("/jobapplication/" + match.params.jobappid + "/"))}>
          {i18n.t('form__back__to__progress')}
        </Button>
        <header>
          <img
            src={jobApp.business.logo}
            alt="business logo"
          />
          <div>
            <span style={{ color: jobApp.business.color_theme.background }}>
              {jobApp.business.name}
            </span>
            <Paragraph>{jobApp.job_application}</Paragraph>
          </div>
        </header>
        <Row type='flex' justify='start' align="middle">
          <Icon type="clock-circle" style={{ marginRight: 10 }} />
          <span>{`${getDeltaTime()} ${i18n.t('candidate_path__minutes')}`}</span>
        </Row>
        <Title level={3}>{candidateInterview.interview.title}</Title>
        <Text>
          {candidateInterview.interview.description}
        </Text>
        <Col xs={24}>
          <div className="candidate-interview__text-row">
            <Text strong>{i18n.t('interview__datetime')}</Text>
            <Text>{moment(candidateInterview.interview.interview_date, 'DD-MM-YYYY HH:mm Zz').local().format('DD-MM-YYYY HH:mm ([UTC]ZZ)')}</Text>
          </div>
          {
          /* Ocultar por ahora
          <div className="candidate-interview__text-row">
            <Text strong>{i18n.t('interview__type')}</Text>
            <Text>{interviewTypes[candidateInterview.interview.interview_type]}</Text>
          </div>
          */
          }
          <div className="candidate-interview__text-row">
            <Text strong>{candidateInterview.interview.link ? i18n.t('interview__link') : i18n.t('interview__address')}</Text>
            {
              candidateInterview.interview.link ?
                <Text>{candidateInterview.interview.link}</Text> :
                <Text>{candidateInterview.interview.address}</Text>
            }
          </div>
          <div className="candidate-interview__text-row">
            <Text strong>{i18n.t('commons__status')}</Text>
            <Text style={styleStatus[candidateInterview.status]}>{i18n.t(`interview__${candidateInterview.status}`)}</Text>
          </div>
          <div className="candidate-interview__text-row">
            <Text strong>{(i18n.t('commons__instructions'))}</Text>
          </div>
          <div style={{ marginTop: 5, marginBottom: 5 }}>{`• ${i18n.t('interview__instructions_1', { business_name: jobApp.business.name })}`}</div>
          <div>{`• ${i18n.t('interview__instructions_2')}`}</div>
        </Col>
        <Row type="flex" style={{ gap: '1rem' }}>
          <Popconfirm
            title={i18n.t('interview__confirm_modal')}
            onConfirm={() => updatePersonalInterview('ACCEPTED')}
            okText={i18n.t('commons__confirm')}
            cancelText={i18n.t('commons__cancel')}
            placement="right"
            icon={
              <Icon type="check-circle" style={{ color: ' #73D13D' }} />
            }
          >
            <Button style={jobApp.business.color_theme}>
              {i18n.t('interview__confirm_button')}
            </Button>
          </Popconfirm>
          <Button style={deleteStyle} onClick={handleDeleteModal.toggle}>
            {i18n.t('interview__decline_button')}
          </Button>
        </Row>
        <Alert
          message={i18n.t('start__process__new__start__process')}
          type="info"
          showIcon
          style={{ width: 'fit-content' }}
        />
      </div>
      <Modal
        title={<Text strong>{i18n.t('interview__decline_modal')}</Text>}
        visible={showModalDelete}
        onCancel={() => {
          handleDeleteModal.off();
          setComment(null);
        }
        }
        footer={[
          <Button key="submit" style={deleteStyle}
            onClick={() => {
              updatePersonalInterview('DECLINED');
              handleDeleteModal.off();
            }}>
            {i18n.t('commons__decline')}
          </Button>,
        ]}
        width="min(90%, 800px)"
      >
        <Text>{i18n.t('interview__decline_comment')}</Text>
        <Input.TextArea style={{ marginTop: '0.5rem' }} onChange={(e) => setComment(e.target.value)} value={comment} />
      </Modal>
    </>
  ) : (
    <GenominSpin />
  );
};

const mapStateToProps = (state) => {
  return {
    jobApp: state.jobapp.jobApplication,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateInterview);
