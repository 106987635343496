import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authActionCreators from '../actions/auth';

export default function requireAddons(Component, key) {
  class requireAddons extends React.Component {
    state = {
      permission: false,
      addons: []
    }
    componentWillMount() {
      this.listAddOn()
      if (!this.state.addons.includes(key)) {
        this.props.authActions.authRedirect('/home')
      }
    }
    listAddOn() {
      this.props.addOns.map((addon) => {
        this.state.addons.push(addon.add_on)
      })
    }

    render() {
      return (
        <div>
          {
            this.props.addOns.map((addon, index) => (
              addon.add_on === key ? (
                <Component {...this.props} key={index} />
              ) : (null)
            ))
          }
        </div>
      );
    }
  }

  const mapStateToProps = (state) => {
    return {
      addOns: state.auth.addOns
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      authActions: bindActionCreators(authActionCreators, dispatch),
      dispatch
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(requireAddons);
}
