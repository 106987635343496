import React from "react";
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../../../../../../../../../actions/trait';
import { connect } from 'react-redux';
import { Slider, Typography, Icon, Popover, Col, Row } from 'antd';
import '../../Configuration.scss';
import { ResponsiveLine } from '@nivo/line';
import FitRangeSelector from "../../../../../../../../../../../components/fitRangeSelector";

const { Text } = Typography;

const styles = {
    sliderTitle: {
        fontSize: 13,
        fontWeight: "700"
    },
    rangeWrapper: {
        width: "100%",
        position: "relative"
    },
    higher: {
        textAlign: 'right',
        fontSize: 12,
    },
    lower: {
        textAlign: 'left',
        fontSize: 12,
    },
};

const distribution = (distribution) => {
    return typeof distribution === 'string' ? distribution.includes('[') ? distribution : undefined : undefined
}
class TraitSlider extends React.Component {

    state = {
        genomeIdeal: 0,
        points: 0,
        ranges: [],
        upperScore: null,
        personalityTrait: false
    }

    componentDidMount() {
        let measured = { ...this.props.measuredTraits[this.props.trait_id] }
        this.setState({ genomeIdeal: measured['score'] })
        const { trait } = this.props;
        const { assessment_code, code, category } = trait;
        if (category === 'PERSON') {
            this.setState({ personalityTrait: true, upperScore: measured['upper_score'] });
        }
        if (this.personalityRanges[assessment_code]) {
            const ranges = [...this.personalityRanges[assessment_code][code]];
            this.setState({ ranges });
        }
    }

    handleSliderAfterChange = (value, upperScore) => {
        const { trait } = this.props;
        const { category } = trait;
        let measured = { ...this.props.measuredTraits[this.props.trait_id] }
        measured['score'] = value;
        if (category === 'PERSON') {
            measured['upper_score'] = upperScore;
        }
        this.props.actions.updateMeasured(measured)
    }

    handleSliderChange = (value) => {
        this.setState({ genomeIdeal: value });
    }

    toggleGraph = () => {
        this.setState({ toggleGraph: !this.state.toggleGraph });
    };

    state = {
        toggleGraph: false,
    };

    hiddeFormulas = ['COGNIT', 'EMOTIO']

    personalityRanges = {
        'THE2': {
            'APERT': [20, 40, 60, 80, 100],
            'CORDI': [20, 40, 60, 80, 100],
            'EMOCI': [20, 40, 60, 80, 100],
            'ESCRU': [20, 40, 60, 80, 100],
            'EXTRA': [20, 40, 60, 80, 100],
            'HONES': [20, 40, 60, 80, 100],
        },
        'TPCG': {
            'NEURO': [20, 40, 60, 80, 100],
            'EXTRA': [20, 40, 60, 80, 100],
            'APERT': [20, 40, 60, 80, 100],
            'AMABI': [20, 40, 60, 80, 100],
            'RESPO': [20, 40, 60, 80, 100],
        }
    }

    render() {
        const measuredTrait = this.props.measuredTraits[this.props.trait_id];
        const { trait } = this.props;
        const data = (trait && trait.distribution) ? distribution(trait.distribution) : '[]';
        const graphData = data && JSON.parse(data).map(({ name, val }) => { return { x: name, y: val } });
        const totalY = graphData.reduce((total, {y}) => total + y, 0)
        const totalCandidates = [{
            id: 'candidates',
            color: 'rgb(236, 132, 162)',
            border: 'black',
            data: graphData
        }];

        return (
            <Row className='trait-slider'>
                <Row type="flex" align="middle" className='trait-name'>
                    <Col xs={18} sm={18} style={{ display: 'flex', justifyContent: 'start' }}>
                        <Text
                            id="slider-image"
                            strong
                            className={styles.sliderTitle}
                        >
                            {trait && trait.trait}

                            {trait && trait.trait !== trait.descripcion && !this.hiddeFormulas.includes(trait.category) &&
                                <Popover
                                    overlayClassName='trait-name-popover'
                                    content={trait.descripcion}
                                    trigger="click"
                                >
                                    <Icon type="question-circle" />
                                </Popover>
                            }
                        </Text>
                    </Col>
                </Row>
                <Row style={styles.rangeWrapper}>
                    <Row type="flex" justify="center">
                        <Col sm={20}>
                            {this.props.showDistribution && data &&
                            <Row style={{ width: '100%', height: '30px' }}>
                                <ResponsiveLine
                                    data={totalCandidates}
                                    margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                                    xScale={{ type: 'point' }}
                                    yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                                    curve='basis'
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={null}
                                    axisLeft={null}
                                    enableGridX={false}
                                    enableGridY={false}
                                    colors={(value) => value.color}
                                    borderColor="#fff"
                                    enablePoints={false}
                                    pointSize={10}
                                    lineWidth={0}
                                    pointColor={{ theme: 'background' }}
                                    pointBorderWidth={2}
                                    pointBorderColor={{ from: 'serieColor' }}
                                    pointLabel="y"
                                    enableArea={true}
                                    pointLabelYOffset={-12}
                                    useMesh={true}
                                    legends={[]}
                                    tooltip={
                                        (value, values) => (
                                            <div className='chart-tooltip'>
                                                <div>{Math.round(100*parseInt(value.point.data.y)/totalY)}% obtiene como resultado {value.point.data.x}</div>
                                            </div>
                                        )
                                    }
                                />
                                <div
                                    style={{ marginTop: '-5px', zIndex: 999, position: 'inherit', pointerEvents: 'none' }}
                                >
                                    <img 
                                        style={{ marginLeft: (trait ? trait.average_score - 1 : null) + '%' }}
                                        src='https://i.imgur.com/1WKikM2.png' />
                                </div>
                            </Row>
                            }
                            <Row style={{ position: 'relative' }} className='trait-slider__content'>
                                {this.state.personalityTrait && measuredTrait.distribution === 'RANG' ? 
                                (
                                    <Col xs={24} style={{ height: '36px' }}>
                                        <FitRangeSelector 
                                            ranges={this.state.ranges}
                                            lowerScore={this.state.genomeIdeal}
                                            upperScore={this.state.upperScore}
                                            onClickRange={(lower, upper) => {
                                                this.setState({...this.state, genomeIdeal: lower, upperScore: upper })
                                                this.handleSliderAfterChange(lower, upper)
                                            }}
                                        />
                                    </Col>
                                )
                                : (<Col xs={24} className="trait-slider__action"><Slider
                                    min={0}
                                    max={100}
                                    tipFormatter={(value) => `${value}%`}
                                    onChange={this.handleSliderChange}
                                    onAfterChange={this.handleSliderAfterChange}
                                    value={this.state.genomeIdeal}
                                    tooltipVisible={false}
                                    style={{ width: "100%" }}
                                /></Col>)}
                            </Row>
                            <Row type="flex" align="middle" justify="space-between" className='trait-name'>
                                <Col xs={9} sm={9} style={{ display: 'flex', justifyContent: 'start' }}>
                                    <Typography className={styles.lower}>
                                        {trait && trait.lower_bound}
                                    </Typography>
                                </Col>
                                <Col xs={9} sm={9} style={{ display: 'flex', justifyContent: 'end' }}>
                                    <Typography className={styles.higher}>
                                        {trait && trait.upper_bound}
                                    </Typography>
                                </Col>
                            </Row>
                        </Col>

                        <Col sm={4}>
                            <Row style={{ display: 'grid', justifyContent: 'center' }}>
                                <Text strong>{this.state.genomeIdeal}% {measuredTrait.distribution === 'RANG' ? `- ${this.state.upperScore}%`: ''}</Text>
                                {this.props.showDistribution && <Text strong type='secondary'>{trait ? trait.average_score : null}%</Text>}
                            </Row>
                        </Col>
                    </Row>
                </Row>
            </Row>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        measuredTraits: state.trait.measuredTraits,
        traits: state.trait.traits
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        actions: bindActionCreators(actionCreators, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TraitSlider);
