import React, { useState } from 'react';
import { Form, Input, Select } from 'antd';
import Actions from '../Actions';
import { countries, regions } from './Description/options';
import { AppContext } from '../JobForm';

const { Option } = Select;


const searchProps = {
  showSearch: true,
  optionFilterProp: 'children',
  notFoundContent: 'No se encontraron resultados',
  filterOption: (input, option) => {
    if (option.props.value) {
      return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    } else {
      return false
    }
  }
}

const Address = (props) => {
  const { getFieldDecorator } = props.form;

  const [country, setCountry] = useState(null);

  const handleChange = (value) => setCountry(parseInt(value));

  return (
    <AppContext.Consumer>
      {(value) => {
        const { location } = value.data;
        if (country === null) {
          setCountry(location.countryId)
        }
        return (
          <Form onSubmit={(e) => value.submit(e, props.form, 'location')}>
            <div className='form-view' id='form-view'>
              <Form.Item label="País">
                {getFieldDecorator('countryId', {
                  initialValue: location.countryId,
                  rules: [
                    {
                      required: true,
                      message: 'Por favor selecciona país',
                    },
                  ],
                })(
                  <Select { ...searchProps } onChange={handleChange}>
                    {Object.keys(countries).map((id) => (
                      <Option key={id} value={parseInt(id)}>{countries[id]}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>

              {(country === 1) &&
                <Form.Item label="Región">
                  {getFieldDecorator('regionId', {
                    initialValue: location.regionId,
                    rules: [
                      {
                        required: true,
                        message: 'Por favor selecciona región',
                      },
                    ],
                  })(
                    <Select { ...searchProps }>
                      {Object.keys(regions).map((id) => (
                        <Option key={id} value={parseInt(id)}>{regions[id]}</Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              }

              <Form.Item label="Dirección">
                {getFieldDecorator('workplace', {
                  initialValue: location.workplace,
                  rules: [
                    {
                      required: true,
                      message: 'Por favor ingresa una dirección',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </div>

            <Actions />
          </Form>
        )
      }}
    </AppContext.Consumer>
  )
}

const WrappedAddressForm = Form.create()(Address);
export default WrappedAddressForm;