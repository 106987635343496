import React, { useState, useEffect } from "react";
import MceEditor from "../../NewProcessFormV2/components/mceeditor/MceEditor";
import ReCAPTCHA from "react-google-recaptcha";
import { Row, Col, Typography, Input, Spin, Icon, Button, Modal, Form, Popover, List, message } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import i18n from "../../../../i18n";

import * as actions from "../../../../actions/data";
import * as Sentry from '@sentry/browser';

const MailWriter = (props) => {
  const emailsBlocked = props.emailsInputDisabled ? props.emailsInputDisabled : false;
  const [templates, setTemplates] = useState({ show: false, list: [] });
  const [newTemplateModalVisible, setNewTemplateModalVisible] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState('');
  const [fileList, setFileList] = useState([]);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const mails = props.getMails()
  const handleChange = (name) => (text) => {
    if (name === 'body') {
      props.setMailText(text.target.value)
    }
  }

  useEffect(() => {
    const getTemplates = async () => {
      try {
        const url = `accounts/business/${props.business.id}/mailtemplates/`
        const headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Token ${props.token}`,
        };

        const templatesResponse = await fetch(`${SERVER_URL}/api/v1/${url}`, {
          method: `GET`,
          headers,
          // // // credentials: 'include',
        }).then(r => r.json());

        setTemplates(state => ({ ...state, list: templatesResponse.results }));
      } catch (e) {
        Sentry.captureException('Fetch');
      }
    };
    getTemplates();
  }, []);

  const handleShowTemplates = () =>
    setTemplates(state => ({ ...state, show: !state.show }));

  const saveTemplate = () => {
    const emptyFields = !props.mailText.trim() || !'Invitación a nuevo proceso'.trim();
    if (emptyFields) {
      setError('Debes llenar los campos para guardar una plantilla.');
    } else {
      const url = `/api/v1/accounts/business/${props.business.id}/mailtemplates/`;
      const data = { template: props.mailText, subject: 'Invitación a nuevo proceso', name: newTemplateName };
      props.actions
        .fetchAndReturn(props.token, url, 'POST', data)
        .then(response => {
          if (response.status < 400){
            const newtemplateList = { ...templates, list: [...templates.list, response.body] };
            setTemplates(newtemplateList);
            setNewTemplateName('');
            setNewTemplateModalVisible(false);
            message.success('Plantilla guardada');
          }
          else if (response.status == 403) {
            message.error(i18n.t('commons__permissions_error'))
          }
        })
        .catch(e => {
          setNewTemplateName('')
          setNewTemplateModalVisible(false);
          message.error('No se pudo guardar plantilla');
          setSending(false);
        });
    }
  }

  const showDeleteConfirm = template => {
    Modal.confirm({
      title: `¿Confirmas que deseas eliminar la plantilla ${template.name}?`,
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => deleteTemplate(template.id),
      onCancel() { },
    });
  };

  const changeTemplate = template => {
    props.setMailText(template.template);
  };

  const updateNewTemplateName = (value) => {
    setNewTemplateName(value);
  }

  const deleteTemplate = async id => {
    try {
      const url = `/api/v1/accounts/business/${props.business.id}/mailtemplates/${id}/`;
      await props.actions.fetchAndReturn(props.token, url, 'delete', { id });
      setTemplates(templates => ({
        ...templates,
        list: templates.list.filter(template => template.id !== id),
      }));
      message.success('Se eliminó la plantilla.');
    } catch (err) {
      message.error('Ocurrió un error eliminando la plantilla.');
    }
  };

  const handleAttachFile = info => {
    const { file } = info;
    let newFileList = fileList;
    if (info.file.status === 'removed') {
      const uid = file.uid;
      newFileList = fileList.filter(x => x.uid !== uid);
    } else {
      const fileData = {
        uid: file.uid,
        name: file.name,
        size: file.size,
        type: file.type,
        ...file.response,
      };
      newFileList.push(fileData);
    }
    setFileList(email => ({ ...email, fileList }));
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <Typography.Text>* Correo</Typography.Text>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Input value={mails.join(', ')} disabled={emailsBlocked} onChange={(e) => { props.setMails ? props.setMails(e.target.value) : null }} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Typography.Text>* Mensaje</Typography.Text>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <MceEditor name='body' body={props.mailText} handleChange={handleChange} height={500} handleAttachFile={handleAttachFile} />
        </Col>
      </Row>
      <Row type='flex' align='middle' style={{ marginTop: '10px' }}>
        <Col span={6}>
          <Button icon='save' style={{ width: '162px' }} onClick={() => setNewTemplateModalVisible(true)}>Guardar plantilla</Button>
          <Modal
            title={`Guardar plantilla`}
            okText={'Si'}
            okType={'primary'}
            cancelText={'No'}
            visible={newTemplateModalVisible}
            onOk={() => saveTemplate()}
            onCancel={() => setNewTemplateModalVisible(false)}>
            <Form>
              <Form.Item label='Nombre de la plantilla'>
                <Input
                  onChange={(event) => { updateNewTemplateName(event.target.value) }}
                  placeholder="Nombre plantilla"
                />
              </Form.Item>
            </Form>
          </Modal>
        </Col>
        <Col span={6}>
          <Popover
            width={350}
            content={
              <div style={{ height: '40vh', overflow: "scroll" }}>
                {
                  templates.list.sort((a, b) => a.name.localeCompare(b.name)).map((template, i) => (
                    <List.Item
                      key={i}
                      onClick={() => changeTemplate(template)}
                      actions={[<Icon type='delete' onClick={() => showDeleteConfirm(template)} style={{ marginRight: 15 }} />]}
                      style={{ cursor: 'pointer' }}
                    >
                      <List.Item.Meta title={template.name} />
                    </List.Item>
                  ))
                }
              </div>
            }
            title={<Typography.Text strong>Mis plantillas</Typography.Text>}
            trigger="click"
            onCancel={(visible) => setTemplates({ ...templates, show: visible })}
          >
            <Button
              icon="table"
              disabled={!templates.list.length}
              onClick={handleShowTemplates}
              style={{ width: '162px' }}
            >
              Ver mis plantillas
            </Button>
          </Popover>
        </Col>
      </Row>
      <ReCAPTCHA ref={props.ref} size="invisible" sitekey={process.env.REACT_APP_CAPTCHA_API_KEY} />
    </div>
  )
}

export const SendingEmails = (props) => {
  const state = props.step === 3 ? 'waiting' : 'done';
  const getStatusComponet = () => {
    switch (props.status) {
      case 200:
        return <Typography.Text strong>¡Correo(s) enviados!</Typography.Text>;
      case 500:
        return <Typography.Text strong>Error al enviar datos</Typography.Text>;
      case 403:
        return <Typography.Text strong>{i18n.t('commons__permissions_error', { ns: 'candidateProfile' })}</Typography.Text>;
    }
  };

  return (
    <Row type='flex' style={{ rowGap: '24px', flexDirection: 'column' }}>
      <Row type="flex" justify="center" align="middle">
        {state === 'waiting' ? <Spin size="large" /> : 
        <Icon type={props.status == 200 ? "check-circle" : 'exclamation-circle'} theme="twoTone" twoToneColor={props.status == 200 ? '#52c41a' : 'red'} style={{ fontSize: '64px' }} />}
      </Row>
      <Row type="flex" justify="center" align="middle">
        {
          state === 'waiting' ?
            <Typography.Text strong>Enviando correo(s)...</Typography.Text> :
            getStatusComponet()
        }
      </Row>
    </Row>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch),
    dispatch,
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    business: state.auth.currentBusiness,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MailWriter);