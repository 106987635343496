import React, { useState, useEffect, useRef } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from 'react-router-redux';
import { v4 as randomID } from 'uuid';

import * as actionCreators from "../../../../actions/data";

import '../InterviewKit.scss';
import ModalDelete from '../components/ModalDelete';
import EditInterviewList from '../components/EditInterviewList';

import { Row, Col, Breadcrumb, Input, Button, Tabs, Divider, Typography, Icon, Tag, Tooltip, Form, message, Layout } from "antd";

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

const EditInterview = (props) => {
    
  const textInput = useRef(null)
  const [specificId, setSpecificId] = useState(null);

  const [interviewData, setInterviewData] = useState({ detail: [{ skill: [] }] })
  const [allTags, setAllTags] = useState({
    tags: [],
    inputVisible: false,
    inputValue: ''
  })
  const [inputQuestion, setInputQuestion] = useState({
    id: '',
    skill: [],
    text:'',
    feedback: false,
    question_number: null
  })
  const [interviewRemoved, setInterviewRemoved] = useState({
    interview_kit_detail_id: ''
  })
  const [activeEdit, setActiveEdit] = useState(false)
  const [activeDelete, setActiveDelete] = useState(false)
  const [activePost, setActivePost] = useState(false)
  const [showDelete, setShowDelete] = useState({
    state: false,
    idQuestion: ''
  })
  const [showEdit, setShowEdit] = useState(false)

  const tagsToArr = (arr) => {
    let newDetail = []
    for(let i = 0; i < arr.length; i++) {
      let toArr = arr[i].skill.split('')
      let tagsArr = toArr.filter(item => item !== '[' && item !== ']' && item !== "'").join('').split(',')
      const newQuestion = {
        feedback: false,
        id: arr[i].id,
        question_number: arr[i].question_number,
        skill: tagsArr,
        text: arr[i].text
      }
      newDetail.push(newQuestion)
    }
    return newDetail
  }

  const getData = () => {
    let endpoint = `/api/v1/evaluations/interviewkit/${props.match.params.id}/`
    let token = props.token
    props.actions.fetchAndReturn(token, endpoint)
    .then(response => {
      if(response.status === 200) {
        setInterviewData({
          ...interviewData,
          business_user: response.body.business_user,
          detail: tagsToArr(response.body.detail),
          group: response.body.group,
          id: response.body.id,
          kit_type: 'INTERVIEW'
        })
      }
    })
  }

  const handleInputQuestion = (e) => {
    if(inputQuestion.id === '') {
      setInputQuestion({
        ...inputQuestion,
        [e.target.name]: e.target.value,
        id: randomID()
      })
    } else {
        setInputQuestion({
          ...inputQuestion,
          [e.target.name]: e.target.value
        })
    }
  }
  const cleanInput = () => {
    if(showEdit) {
      cancelEdit()
      return;
    }
    setInputQuestion({ ...inputQuestion, text: '', skill: '', id: '' })
  }

  const newTag = () => {
    setAllTags({ ...allTags, inputVisible: true })
  }
  const newTagChange = (e) => {
    setAllTags({ ...allTags, inputValue: e.target.value })
  }
  const newTagConfirm = () => {
    if(allTags.inputValue && allTags.tags.indexOf(allTags.inputValue) === -1) {
      allTags.tags = [...allTags.tags, allTags.inputValue]
    }
    setAllTags({ tags: allTags.tags, inputVisible: false, inputValue: '' })
    setInputQuestion({ ...inputQuestion, skill: allTags.tags })
  }
  const deleteTag = (removed) => {
    const newTags = allTags.tags.filter(tag => tag !== removed)
    setAllTags({...allTags, tags: newTags})
    setInputQuestion({ ...inputQuestion, skill: newTags })
  }
  console.log(inputQuestion.skill)
  const clickEdit = (index, idQuestion) => {
    const { text, id, skill } = interviewData.detail[index]
    // const filterQuestion = interviewData.detail.filter(item => item.id !== idQuestion)
    setShowEdit(true)
    setActiveEdit(true)
    setInputQuestion({ ...inputQuestion, text: text, id: id, skill: skill })
    setAllTags({ ...allTags, tags: skill})
    // setInterviewData({ ...interviewData, detail: filterQuestion })
  }
  const editConfirm = () => {
    interviewData.detail.splice(specificId, 1, inputQuestion)
    // setInterviewData({ ...interviewData, detail: [...interviewData.detail, inputQuestion] })
    setInputQuestion({ ...inputQuestion, id: '', skill: [], text: [] })
    setAllTags({ ...allTags, tags: [] })
    setSpecificId(null)
  }
  const cancelEdit = () => {
    setInterviewData({ ...interviewData, detail: [...interviewData.detail, inputQuestion] })
    setInputQuestion({ ...inputQuestion, id: '', skill: [], text: [] })
    setAllTags({ ...allTags, tags: [] })
    setShowEdit(false)
    setActiveEdit(false)
  }

  const confirmDelete = (idQuestion) => {
    const filterRemove = interviewData.detail.filter(item => item.id === idQuestion)
    setInterviewRemoved({ interview_kit_detail_id: filterRemove[0].id })
    setActiveDelete(true)
  }
  const cancelDelete = () => {
    setShowDelete(false)
    setInterviewRemoved({ interview_kit_detail_id: '' })
  }

  const clickPost = () => {
    setActivePost(true)
  }
  const confirmPost = () => {
    if(inputQuestion.text !== '') {
      setInterviewData({
        ...interviewData, detail: [...interviewData.detail, inputQuestion]
      })
      return;
    }
  }
  const cancelPost = () => {
    setActivePost(false)
    setInputQuestion({
      ...inputQuestion,
      text: '',
      id: '',
      skill: []
    })
    setAllTags({
      ...allTags,
      tags: []
    })
  }
  const clickCopy = (index, idQuestion) => {
    setActivePost(true)
    const { text, id, skill } = interviewData.detail[index]
    setInputQuestion({ ...inputQuestion, text: text, id: id, skill: skill })
    setAllTags({ ...allTags, tags: skill})
  }

  const redirect = () => {
    props.dispatch(push('/account/interviewkit'))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const { id, group, kit_type, detail } = interviewData
    const endpoint = `/api/v1/evaluations/interviewkit_questions/${id}/`
    let token = props.token
    if(activeEdit) {
      let detailData = detail.map((value, index) => {
        return { ...value, 
          question_number: index, 
          interview_kit_detail_id: value.id, 
          skill: interviewData.detail[index].skill, 
          question_type: 'INTERVIEW' 
        }
      })
      let body = { name: group, kit_type: kit_type, questions: detailData  }
      try {
        props.actions.fetchAndReturn(token, endpoint, 'put', body)
        .then(response => {
          if(response.status === 200) {
            message.success('Pregunta editada exitosamente')
            setShowEdit(false)
            setActiveEdit(false)
          }
        })
      } catch (error) {
        console.log('Error -->', error)
      }
    }
    if(activeDelete) {
      let body = { questions: [interviewRemoved] }
      const questionFilter = interviewData.detail.filter(item => item.id !== interviewRemoved.interview_kit_detail_id)
      try {
        props.actions.fetchAndReturn(token, endpoint, 'delete', body)
        .then(response => {
          if(response.status === 200) {
            message.success('Pregunta eliminada correctamente')
            setInterviewData({ ...interviewData, detail: questionFilter })
            setShowDelete({ state: false, idInterview: '' })
            setActiveDelete(false)
          }
        })
      } catch (error) {
        console.log(error)
      }
    }
    if(activePost) {
      let questionData = detail.map((value, index) => {
        return { ...value, question_number: index, question_type: 'INTERVIEW', feedback: false }
      })
      let body = { questions: [questionData[questionData.length - 1]] }
      try {
        if(inputQuestion.text !== '') {
          props.actions.fetchAndReturn(token, endpoint, 'post', body)
          .then(response => {
            if(response.status === 200) {
              cancelPost()
              message.success('Pregunta creada correctamente')
            }
          })
        } else {
          message.error('Debes llenar el campo "Nueva pregunta"')
        }
      } catch (error) {
        console.log(error)
      }
    } 
  }

  useEffect(() => {
    getData()
  }, [])

    

  return (
    <Layout className='layoutCustomInterview'>
      <Row className='beadcrumNav'>
        <Col>
          <Breadcrumb separator=">">
          <Breadcrumb.Item href="/processes">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/tools">Mis herramientas</Breadcrumb.Item>
          <Breadcrumb.Item href="/account/interviewkit">Entrevistas Personalizadas</Breadcrumb.Item>
          <Breadcrumb.Item href={`/account/interviewkit/edit/interview/${props.match.params.id}`}>Plantilla Predeterminada</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row className='navTitle' gutter={20}>
        <Col>
          <Icon onClick={() => redirect()} type='arrow-left' style={{fontSize: '25px'}}/>
        </Col>
        <Col>
          <Title style={{margin: '0', padding: '0'}} level={1}>Plantilla Predeterminada</Title>
        </Col>
      </Row>
      <Row className='infoSection'>
        <Col>
          <Paragraph>Desde esta sección podrás configurar las preguntas que solicites para tus candidatos.</Paragraph>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit}> 
        <Row>
          <div className='newInterviewTable'>
            <Tabs>
              <TabPane key='1' tab='Vista de Edición'>
                <Row style={{margin: '0px 20px'}}>
                  <Col>
                    <Title style={{color: '#5E5E5E'}} level={4}>Tipo de Entrevista Personalizada</Title>
                  </Col>
                  <Col>
                    <Paragraph>Entrevista</Paragraph>
                  </Col>
                </Row>
                <Row style={{margin: '20px 20px'}}>
                  <Col>
                    <Title level={4} style={{color: '#5E5E5E'}} >Nombre de la Plantilla</Title>
                  </Col>
                  <Col>
                    <Input disabled value={interviewData.group} placeholder='Ingresa el nombre' size='large' />
                  </Col>
                </Row>
                {activeEdit || activePost ? (
                  <Row className='formNewQuestions'>
                    <Col style={{marginRight: '40px'}}>
                      {activeEdit && <Title level={4} style={{color: '#5E5E5E'}} >Pregunta a editar</Title>}
                      {activePost && <Title level={4} style={{color: '#5E5E5E'}} >Nueva pregunta</Title>}
                      <Input value={inputQuestion.text} onChange={handleInputQuestion} name='text' size="large" />  
                    </Col>
                    <Col style={{marginRight: '40px'}}>
                      <Title level={4} style={{color: '#5E5E5E'}} >Etiquetas</Title>
                        {allTags.tags.map((tag, index) => {
                          const longTag = tag.length > 20
                          const tagElem = (
                            <Tag key={tag} closable onClose={() => deleteTag(tag)}>
                              {longTag ? `${tag.slice(0, 20)}...`: tag}
                            </Tag>
                          )
                          return longTag ? (
                            <Tooltip title={tag} key={tag}>
                              {tagElem}
                            </Tooltip>
                          ) : (
                            tagElem
                          )
                        })}
                        {allTags.inputVisible && (
                          <Input
                            ref={textInput}
                            type="text"
                            size="small"
                            style={{width: 78}}
                            value={allTags.inputValue}
                            onChange={newTagChange}
                            onBlur={() => newTagConfirm()}
                            onPressEnter={newTagConfirm}
                          />
                        )}
                        {!allTags.inputVisible && (
                          <Tag onClick={() => newTag()} style={{ background: '#fff', borderStyle:'dashed' }}>
                              <Icon type="plus" /> New tag
                          </Tag>
                        )}
                    </Col>
                    <Col>
                      <Title level={4} style={{color: '#5E5E5E'}} >Acciones</Title>
                      <Button icon='delete' onClick={() => cleanInput()} style={{border: 'none', color: '#A4A4A4', boxShadow: 'none'}} />
                    </Col>
                  </Row> 
                ) : null}

                <Divider />

                <EditInterviewList list={interviewData.detail} setSpecificId={setSpecificId} fnEdit={clickEdit} fnCopy={clickCopy} fnDelete={setShowDelete} />
                {!activePost && (
                  <Row type="flex" align="middle" style={{margin: '20px 20px'}}>
                    <Col>
                      <Button onClick={() => clickPost()} icon='plus-circle' style={{color: '#F175A5', border: 'none', boxShadow: 'none'}} >
                        Agregar pregunta
                      </Button>
                    </Col>
                  </Row>
                )}
              </TabPane>
            </Tabs>
          </div>
        </Row>
        <ModalDelete showDelete={showDelete} cancelDelete={cancelDelete} confirmDelete={confirmDelete} type={'pregunta'} />
        {showEdit && (
          <Row type="flex" style={{margin: '20px 40px'}} >
            <Col style={{marginRight: '20px'}}>
              <Button onClick={() => cancelEdit()}>Cancelar</Button>
            </Col>
            <Col>
              <Button htmlType="submit" onClick={() => editConfirm()} style={{background: '#F175A5', color: 'white'}}>Editar Pregunta</Button>
            </Col>
          </Row>
        )}
        {activePost && (
          <Row type="flex" style={{margin: '20px 40px'}} >
            <Col style={{marginRight: '20px'}}>
              <Button onClick={() => cancelPost()}>Cancelar</Button> 
            </Col>
            <Col>
              <Button htmlType="submit" onClick={() => confirmPost()} style={{background: '#F175A5', color: 'white'}}>Agregar pregunta</Button>
            </Col>
          </Row>
        )}
        {!activePost && !showEdit && !showDelete.state && (
          <Row className='actionsNewInterview'>
            <Col>
              <Button onClick={() => redirect()} style={{background: '#F175A5', color: 'white'}}>Volver</Button>
            </Col>
          </Row>
        )}
      </Form>
    </Layout>
  );
}

const EditFromInterview = Form.create({ name: 'new-interviewkit-form' })(EditInterview)

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch
  };
};
  
const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    token: state.auth.token,
  };
}
 
export default connect(mapStateToProps, mapDispatchToProps)(EditFromInterview);