import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Card,
  Col,
  Typography,
  Button,
  Icon,
  message,
  Divider,
  Tooltip
} from 'antd';
import propTypes from 'prop-types';

import '../Jobs.scss';
import i18n from '../../../i18n';
import ParseHtml from '../../../components/parseHtml';

const { Title, Text } = Typography;

/**
 * 
 * @param {Object} jobData
 * @param {String} jobID
 * @param {Function} diffDay
 * @returns 
 */

const JobInfo = ({ jobData, jobID, diffDay }) => {

  return (
    <Row style={{ maxHeight: '540px', minHeight: '540px', overflowY: 'auto', border: '1px solid #D9D9D9', background: 'white' }}>
      <Card style={{ maxHeight: '660px' }}>
        <Row type='flex' style={{ margin: '0 0 20px 0' }}>
          <Col span={6}>
            <Row type='flex' justify='center'>
              <img src={jobData.logo} style={{ height: '70%', width: '70%' }} />
            </Row>
          </Col>
          <Col span={18}>
            <Row type='flex' justify='space-between' style={{ flexDirection: 'column' }}>

              <Row type='flex' justify='space-between'>
                <Col>
                  <Title level={3} style={{ margin: '0' }}>
                    {jobData.job_application}
                  </Title>
                </Col>
                <Tooltip placement='top' title='Copiar link del empleo'>
                  <Col>
                    <Button icon='link' size='small' onClick={() => {
                      navigator.clipboard.writeText(`https://app.genoma.work/startprocess/${jobID}?utm_source=genomawork&utm_medium=jobs-site&utm_campaign=share`)
                      message.success(i18n.t('job_portal__copy_url'))
                    }} />
                  </Col>
                </Tooltip>
              </Row>

              <Row style={{ margin: '5px 0' }}>
                <Text style={{ fontSize: '16px' }}>
                  {jobData.business_user}
                </Text>
              </Row>

              <Row>
                <Text style={{ fontSize: '16px' }}>
                  <Icon type="clock-circle" style={{ margin: '0 5px 0 0' }} />
                  {`${i18n.t('job_portal__published').split('{')[0]} ${diffDay(jobData.creation_date)} ${i18n.t('job_portal__published').split('}')[1]}`} 
                </Text>
              </Row>
              <Row style={{ margin: '5px 0 0 0' }}>
                <Link to={`/startprocess/${jobID}?utm_source=genomawork&utm_medium=jobs-site`} target='_blank'>
                  <Button type="primary" size="small" style={{ width: '120px' }}>
                    {i18n.t('job_portal__apply')}
                  </Button>
                </Link>
              </Row>

            </Row>
          </Col>
        </Row>

        <Divider />

        <Row>
          {ParseHtml(jobData.welcome_text)}
          <br />
          {ParseHtml(jobData.description)}
        </Row>
      </Card>
    </Row>
  );
}

JobInfo.propTypes = {
  jobData: propTypes.object,
  jobID: propTypes.string,
  diffDay: propTypes.func
}

export default JobInfo;