import React from 'react';
import { Row, Col, Form, Typography, Checkbox, Button } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../../actions/data';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';

const { Title, Text } = Typography;

// TODO: Deprecar este componente cuando salga la nueva sección mi perfil
function GenderDisability({ form, personalData, setPersonalData }) {
  const { getFieldDecorator, getFieldValue } = form;

  function onChangeDisability(e) {
    if (e.target.checked && e.target.name === 'true') {
      setPersonalData({ ...personalData, has_disability: true });
    } else if (e.target.checked && e.target.name === 'false') {
      setPersonalData({ ...personalData, has_disability: false });
    }
  }

  return (
    <Form layout='horizontal' className='candidate-form-section'>
      <Row>
        <Title level={4}>{i18n.t('profile__gender_and_disability')} </Title>
      </Row>
      <Row type='flex' gutter={20}>
        <Col span={24}>
          <Col xs={24} sm={24} md={10}>
            <Form.Item
              label={
                <>
                  <span>{i18n.t('form__gender_selection')}</span>
                </>
              }
            >
              {getFieldDecorator('Genero', {
                rules: [
                  { required: false, message: i18n.t('form__complete__data') },
                ],
              })(
                <Row type='flex'>
                  <Col sm={i18n.language.split('-')[0] == 'en' ? 4 : 6} md={6} lg={i18n.language.split('-')[0] == 'en' ? 7 : 9} >
                    <Button
                      type={personalData.gender === 'M' ? 'primary' : 'default'}
                      name='M'
                      onClick={() =>
                        setPersonalData({ ...personalData, gender: 'M' })
                      }
                    >
                      {i18n.t('form__male')}
                    </Button>
                  </Col>
                  <Col  sm={6} md={6} lg={8}>
                    <Button
                      type={personalData.gender === 'F' ? 'primary' : 'default'}
                      name='F'
                      onClick={() =>
                        setPersonalData({ ...personalData, gender: 'F' })
                      }
                    >
                      {i18n.t('form__female')}
                    </Button>
                  </Col>
                  <Col  sm={6} md={6} lg={i18n.language.split('-')[0] == 'en' ? 8 : 6} >
                    <Button
                      type={personalData.gender === 'O' ? 'primary' : 'default'}
                      name='O'
                      onClick={() =>
                        setPersonalData({ ...personalData, gender: 'O' })
                      }
                    >
                      {i18n.t('form__other')}
                    </Button>
                  </Col>
                </Row>
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label={
                <>
                  <span className='label-required'>*</span>
                  <span>{i18n.t('commons__disability__question')}</span>
                </>
              }
            >
              {getFieldDecorator(i18n.t('form__disability'), {
                rules: [
                  { required: false, message: i18n.t('form__complete__data') },
                ],
              })(
                <Row>
                  <Col md={24}>
                    <Checkbox
                      onChange={onChangeDisability}
                      name='true'
                      checked={personalData.has_disability ? true : false}
                    >
                      <>
                        <span className='bold-option'>{i18n.t('profile__disability__yes').split(',')[0]}</span>, {i18n.t('profile__disability__yes').split(',')[1]}
                      </>
                    </Checkbox>
                  </Col>
                  <Col md={24}>
                    <Checkbox
                      onChange={onChangeDisability}
                      name='false'
                      checked={!personalData.has_disability ? true : false}
                    >
                      <>
                        <span className='bold-option'>{i18n.t('profile__disability__no').split(',')[0]}</span>, {i18n.t('profile__disability__no').split(',')[1]}
                      </>
                    </Checkbox>
                  </Col>
                </Row>
              )}
            </Form.Item>
          </Col>
        </Col>
      </Row>
    </Form>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  };
};

GenderDisability.propTypes = {
  personalData: PropTypes.shape({
    has_disability: PropTypes.bool,
    gender: PropTypes.string
  }), 
  setPersonalData: PropTypes.func 
}

const WrappedGenderDisability = Form.create()(GenderDisability);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedGenderDisability);
