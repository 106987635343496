import { useState, useEffect } from 'react';
import debounce from '../utils/debounce';

const useScreen = (time) => {
  const [width, setWidth] = useState(_ => window.innerWidth);

  useEffect(() => {
    const debouncedResize = debounce(_  => setWidth(window.innerWidth), time);

    window.addEventListener('resize', debouncedResize);

    return _ => window.removeEventListener('resize', debouncedResize);
  }, []);

  return width;
};

export default useScreen;
