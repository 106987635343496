import React, { Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../../../../../actions/trait';
import * as actionCreators from '../../../../../../../actions/jobapp';
import { AppContext } from '../../../../NewProcessForm';
import { Button } from 'antd';

const AddOrRemove = (props) => {

  const addOrRemoveActivity = async () => {
    const { code, id, time_estimated, activity, category } = props.activity;
    const { jobappid, measuredTraits, traits } = props;
  
    const assignedActivity = {
      job_application_id: jobappid, 
      activity_id: id, 
      stage: 2, 
      height: 1, 
      order: 1, 
      configuration: {}, 
      time_estimated, 
      icon: code,
      activity,
      category,
    };
    const traitsCode = traits.filter(t => t.assessment_code == code);
    if (props.assignedActivities[code]) {
      const mIds = traitsCode.map(t => measuredTraits[t.id].id);
      props.actions.removeAssignedActivity(code, props.assignedActivities[code].original, traitsCode, mIds);
    } else {
      await props.actions.addAssignedActivity(code, assignedActivity, traitsCode);
    }
  }

  const added = props.assignedActivities[props.activity.code];

  return (
    <AppContext.Consumer>
      {({ active })  => ( 
        !active ? (
          <Fragment>
            {props.sm || (window.innerWidth <= 500) ?
              <Button style={{ background: added ? '#c54c25' : '#4caf50' }} icon={added ? 'minus' : 'plus'} onClick={addOrRemoveActivity} />
            :
              <Button 
                style={{ background: added ? '#c54c25' : '#4caf50' }}  
                onClick={addOrRemoveActivity}
              >
                {added ? 'Quitar' : 'Agregar'}
              </Button>
            }
          </Fragment> 
        ) : added && <Button disabled className='added-btn' icon='check-circle'>Agregado</Button>
      )}
    </AppContext.Consumer>
  )
}

const mapStateToProps = (state) => {
  return {
    jobappid: state.jobapp.jobAppId,
    traits: Object.values(state.trait.traits),
    measuredTraits: state.trait.measuredTraits,
    assignedActivities: state.jobapp.assignedActivities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: bindActionCreators(actionCreators, dispatch),
    actionsTraits: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOrRemove);