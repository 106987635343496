import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import Activity from "./Activity";


const styles = theme => ({
	candidateStage2: {
		paddingTop: 14,
		paddingBottom: 18,
		textAlign: "center"
	},
	title: {
		border: "1px solid #999999",
		borderRadius: 4,
		backgroundColor: "#898989",
		textAlign: "center",
		paddingTop: 13,
		paddingBottom: 13,
		width: 240,
		margin: "0 auto 27px"
	},

	titleBody1: {
		color: "#fff"
	},
	padTopBot: {
		paddingTop: 8,
		paddingBottom: 8,
		"&:first-child": {
			paddingTop: 0
		},
		"&:last-child": {
			paddingBottom: 0
		}
	},
	smallHeart: {
		display: "none"
	}
});


const ActivityRow = props => {
    return (
        <Grid container justify="center" spacing={24} className={props.classes.padTopBot}>
            {props.row.map((activity, index) => {
                return (
                    <Grid key={index} item xs={4} sm={4} md={4} lg={4} xl={4} style={{ marginBottom: '15px' }}>
                        <Activity
                          businessSlug={props.businessSlug}
                          colorTheme={props.colorTheme}
                          prvw={props.prvw}
                          {...activity}
                        />
                    </Grid>
                )
            })}
        </Grid>
    )
};

export default withStyles(styles)(ActivityRow);

