import React from 'react';

import Footer from '../components/Footer';
import Header from '../components/Header';

const Page = ({ children, headerText }) => {
  return (
    <div className="break-page page">
      <Header text={headerText} />
      {children}
      <Footer />
    </div>
  );
};

export default Page;
