import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as dataActionCreators from '../../../../actions/data'
import { Progress, Typography, Card, Divider } from "antd";
import i18n from "../../../../i18n";
import "./Progress.scss"

const coinIMG = 'https://genoma-assets.s3.us-east-2.amazonaws.com/coin.png';
const clockIMG = 'https://genoma-assets.s3.us-east-2.amazonaws.com/wall-clock.svg';


const { Text } = Typography;

let hasSendedStatus = false;

const LinearBuffer = ({ items }) => {
  const minutes = items.reduce(
    (init, next) => init + (next.progress ? next.progress.time_remaining : 0),
    0
  );
  const total = items.reduce((init, next) => init + (next.activity.time_estimated === undefined ? 0 : next.activity.time_estimated), 0);
  const progress = ((total - minutes) * 100) / total;
  if (progress === 100 && !hasSendedStatus) {
    hasSendedStatus = true;
  }
  const totalReward = items.reduce(
    (init, next) => init + (next.activity.time_estimated == undefined ? 0 : next.activity.time_estimated),
    0
  );

  return (
    <div className='cp-candidate-progress'>
      <Card className='container'>
        <div className='cp-name-and-progess'>
          <Text className='progress-title' strong>{i18n.t('candidate_path__your_progress')}</Text>
          <Divider />
          <Progress percent={total != 0 ? progress : 0} strokeWidth={26} showInfo={false} />
        </div>

        <div className='cp-rewards'>
          <div className='cp-reward'>
            <img src={clockIMG} />
            <Text type='secondary'>{i18n.t('candidate_path__duration')} <strong>{totalReward} {i18n.t('candidate_path__minutes')}</strong></Text>
          </div>
        </div>
      </Card>

      <Card className='container'>
        <div className='cp-total-reward'>
          <div>
            <Text type='secondary' strong>{i18n.t('candidate_path__earned_coins')}</Text> <br />
            <Text className='counter'><strong>{totalReward - minutes} / {totalReward}</strong></Text>
          </div>
          <div>
            <img src={coinIMG} />
          </div>
        </div>
      </Card>
    </div>

  );
}


const mapStateToProps = (state) => {
  return {
    jobappId: state.data.data.jobapp_id,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(dataActionCreators, dispatch),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LinearBuffer);
