import React from 'react';
import { Layout, Row, Col, Button, Typography } from 'antd';
import '../InterviewKit.scss';

const { Paragraph } = Typography;

const ModalDelete = ({ showDelete, cancelDelete, confirmDelete, type }) => {

  return (
    <Layout className='layoutCustomInterview'>
      {showDelete.state && (
        <Row className='deleteModal'>
          <Col>
            <Paragraph style={{ margin: '0 0 0 10px', color: 'white' }}>
            {`¿Deseas eliminar la ${type}? Recuerda que una vez borrada no podrás recuperarla.`}
            </Paragraph>
          </Col>
          <Col>
            <Button style={{ marginRight: '20px' }} onClick={() => cancelDelete()}>Cancelar</Button>
            <Button htmlType="submit"
              style={{ background: '#F175A5', color: 'white', border: 'none' }} onClick={() => confirmDelete(showDelete.idQuestion)}>Borrar</Button>
          </Col>
        </Row>
      )}
    </Layout>
  );
}
 
export default ModalDelete;