
const businessPermissionStatusName = {
  'ACT': 'Activo',
  'PND': 'Pendiente',
  'REV': 'Revocado',
}

function businessPermissionName(
  { business_admin, can_manage, can_invite, can_create_cell, can_view_all_jobapps }
) {
  if (business_admin)
    return 'Administrar';

  let permissions = []
  if (can_manage)
    permissions = ['Gestionar marca empleadora'];
  if (can_invite)
    permissions = [...permissions, 'Invitar colaboradores'];
  // if (can_create_cell)
  //   permissions = [...permissions, 'Crear células'];
  if (can_view_all_jobapps)
    permissions = [...permissions, 'Acceso a todos los procesos'];

  if (permissions.length > 0)
    return permissions.join(', ')
  return 'Sin permisos habilitados.'
}

const processStatusName = {
  'ACTIV': 'Abierto',
  'INACT': 'Cerrado',
  'OTHER': 'Borrador',
}

const validateEmailFormat = (email) => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
}

export { 
  businessPermissionName, 
  businessPermissionStatusName, 
  processStatusName, 
  validateEmailFormat, 
};
