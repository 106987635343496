import i18n from '../i18n';

export const countriesIdentification = {
  argentina: 'DNI',
  bolivia: 'CI',
  brasil: 'RG',
  chile: 'RUT',
  colombia: 'CC',
  ecuador: 'CI',
  guatemala: 'DPI',
  mexico: 'CURP',
  méxico: 'CURP',
  panama: 'CIP',
  paraguay: 'CI',
  peru: 'DNI',
  uruguay: 'Documento',
  venezuela: 'CI',
};

export const disabilityTypeOptions = {
  PHYSICAL: i18n.t('form__physical'),
  SENSORY: i18n.t('form__sensorial'),
  INTELECTUAL: i18n.t('form__intellectual'),
  PSYCHIC: i18n.t('form__psychic'),
  VISCERAL: i18n.t('form__visceral'),
  MULTIPLE: i18n.t('form__multiple'),
  UNKNOWN: i18n.t('commons__uncomplete'),
};

export const genderOptions = {
  M: i18n.t('form__male'),
  F: i18n.t('form__female'),
  N: i18n.t('form__non_binary'),
  O: i18n.t('form__other'),
  W: i18n.t('commons__would_rather_not_say'),
  E: i18n.t('commons__uncomplete'),
};
