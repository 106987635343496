import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Sentry from '@sentry/browser';

import { Button, Col, Form, message, Modal, Row, Typography } from 'antd';
import PropTypes from 'prop-types';

import * as actionCreators from '../../../../actions/data';
import i18n from '../../../../i18n';
import useBoolean from '../../../../hooks/useBoolean';

const { Text, Title } = Typography;

function EditAdditionalInformationModal(props) {
  const {
    actions,
    closeModal,
    config: { data, fields, handleResponse, method, submitUrl },
    countriesOptions,
    form: { getFieldDecorator, getFieldsValue, getFieldValue, setFieldsValue, validateFields },
    title,
    token,
  } = props;

  const [loading, handleLoading] = useBoolean(false);

  const handleSubmit = () => {
    validateFields((err, values) => {
      const url = `${submitUrl}${data?.id ? `${data.id}/` : ''}`;
      if (!err) {
        Object.keys(fields).forEach((fieldName) => {
          if (values[fieldName] === undefined) {
            values[fieldName] = null;
          }
          if (fields[fieldName].parseBeforeSubmit) {
            values[fieldName] = fields[fieldName].parseBeforeSubmit(values[fieldName]);
          }
        });
        handleLoading.on();
        actions.fetchAndReturn(token, url, data?.id ? 'PATCH' : method, values)
          .then((response) => handleResponse(response))
          .catch((error) => {
            Sentry.captureException(error);
            message.error(i18n.t('profile__error_updating'));
          })
          .finally(() => {
            handleLoading.off();
            closeModal();
          });
      }
    });
  };

  return (
    <Modal
      wrapClassName='edit-personal-data-modal'
      footer={null}
      maskClosable={false}
      onCancel={closeModal}
      visible
      width={650}
      bodyStyle={{ paddingBottom: '1em' }}
    >
      <Row
        align="middle"
        style={{ paddingBottom: '1em' }}
        type="flex"
      >
        <Title level={4} style={{ marginBottom: 0 }}>{title}</Title>
      </Row>
      <Form>
        <Row align="top" gutter={24} type="flex">
          {Object.entries(fields).map(([fieldName, fieldConfig]) => {
            const rules = fieldConfig.rules ? fieldConfig.rules(getFieldValue, validateFields) : [];
            if (fieldConfig.required) {
              rules.push({
                required: true,
                message: i18n.t('commons__required__field'),
              });
            }
            return (
              <Col
                key={`col-${fieldName}`}
                xs={fieldConfig.span <= 12 ? (fieldConfig.span * 2) : fieldConfig.span}
                sm={fieldConfig.span}
              >
                <Form.Item
                  colon={false}
                  key={fieldName}
                  label={
                    fieldConfig.label
                      ? (
                        <>
                          {fieldConfig.required ? (<Text style={{ color: 'red' }}>*</Text>) : ''}
                          {fieldConfig.label}
                        </>
                      )
                      : (<Text style={{ color: 'transparent' }}>-</Text>)
                  }
                  style={{ marginBottom: 0 }}
                >
                  {getFieldDecorator(fieldName, {
                    initialValue: data ? data[fieldName] : undefined,
                    rules,
                  })(fieldConfig.input(setFieldsValue, data && data[fieldName], countriesOptions))}
                  {fieldConfig.extra && fieldConfig.extra(getFieldsValue(), setFieldsValue)}
                </Form.Item>
              </Col>
            )
          })}
        </Row>
        <Row gutter={8} justify="end" type="flex">
          <Col>
            <Form.Item className="profile-label">
              <Button disabled={loading} onClick={closeModal}>{i18n.t('commons__cancel')}</Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item className="profile-label">
              <Button loading={loading} onClick={handleSubmit} type="primary">{i18n.t('commons__save')}</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

const AdditionalInformationForm = Form.create({ name: 'additional_information_form' })(EditAdditionalInformationModal);

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actionCreators, dispatch),
  dispatch,
});

AdditionalInformationForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  config: PropTypes.shape({
    data: PropTypes.object,
    fields: PropTypes.object.isRequired,
    handleResponse: PropTypes.func.isRequired,
    method: PropTypes.string,
    submitUrl: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.object.isRequired,
  title: PropTypes.string,
  // Redux
  actions: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
};

AdditionalInformationForm.defaultProps = {
  config: {
    data: undefined,
    method: 'POST',
  },
  title: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalInformationForm);
