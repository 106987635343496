import moment from 'moment'

export const defaultWelcomeText = `<p><strong><span style="color: #ff0092;">¡Bienvenido(a)!</span></strong></p>
  <p></p>
  <p>Agradecemos tu interés por ser parte de nuestro equipo.</p>
  <p></p>
  <p>Te invitamos, como parte de nuestro proceso de selección, a que completes algunos juegos y, a cambio, te entregaremos un reporte que te ayudará a conocerte mejor.</p>
  <p></p>
  <p>Esta etapa es muy importante, ya que el resultado determinará tu continuidad en el proceso de selección y potencial incorporación a nuestra empresa.</p>
  <p></p>
  <p>La actividad cuenta con juegos que duran entre 2 y 4 minutos y un test de 10 minutos. No es necesario que completes todos los juegos de una sola vez. Puedes completar algunos, y en otro momento los que te falten.</p>
  <p></p>
  <p>Esta actividad debe ser realizada en un computador o celular, utilizando el navegador Google Chrome y con una buena conexión a Internet.</p>
  <p></p>
  <p>Te sugerimos jugar en un lugar tranquilo y sin distracciones.</p>
  <p></p>
  <p>¡Gracias por tu postulación!</p>
  <p></p>
  <p><span style="font-size: 10pt; color: #ff0092;">Cualquier duda o problema por favor escribe directamente al chat de soporte en el costado inferior derecho de la pantalla ("círculo rosado con centro blanco").</span></p>
`;

export const videoInterviewMessage = (jobAppName, businessName) => {
  const message = `<p style="color: #ea4c89;">¡Felicitaciones!</p> <p>Has avanzado a la siguiente fase del proceso: ${jobAppName}. Ahora deberás responder, a través de una grabación en video, unas breves preguntas que ${businessName} ha preparado para conocerte mejor.</p><p style="color: #ea4c89;">Ten en cuenta que:</p><ul><li>Solo tienes una oportunidad para responder la video entrevista.</li><li>Debes contar con una buena y estable conexión a Internet.</li><li>Recomendamos utilizar el navegador Google Chrome actualizado.</li><li>Si estas usando un dispositivo iPhone/iPad, usar Safari.</li>`
  return message;
};

export const validateData = (stateData) => {
  const keyToText = {
    name: 'Nombre',
    department: 'Área',
    totalVacancies: 'Número de vacantes',
    language: 'Lenguaje',
    country: 'País',
    welcomeText: 'Texto de bienvenida',
    city: 'Ciudad',
    closureDate: 'Fecha de cierre',
  }
  const requiredData = ['closureDate', 'city', 'name', 'department', 'language', 'country', 'welcomeText'];
  let errors = [];
  requiredData.forEach(key => {
    if (stateData[key] == '' || stateData[key] == null || stateData[key] == undefined) {
      errors.push(keyToText[key]);
      // Break
      return false;
    }
    return true;
  })
  const validClosureDate = moment(stateData['closureDate'], 'DD-MM-YYYY').diff(moment()) >= 0;
  if (!validClosureDate) {
    errors.push(`${keyToText['closureDate']} inválida`);
  }

  return errors;
};

// TODO revisar todos los casos
export const findAssignedActivity = (assignedActivities, evaluation, type) => {
  let assignedId = null;
  switch (type) {
    case 'cute':
      assignedId = assignedActivities.find(obj => JSON.parse(obj.configuration).id == JSON.parse(evaluation.configuration).id)?.id
      return assignedId
    case 'game':
      assignedId = assignedActivities.find(obj => obj.activity_id == evaluation.activity_id)?.id
      return assignedId
    case 'form':
      return evaluation.id
    
    case 'filesRequests':
      assignedId = assignedActivities.find(obj=>obj.activity_id == evaluation.activityId)?.id
      return assignedId
    case 'question':
      assignedId = assignedActivities.find(obj => obj.activity.code === 'KO')?.id
      return assignedId
    case 'interviewProcesses':
      assignedId = assignedActivities.find(obj => obj.activity.code === 'TERA')?.id
      return assignedId
  }
  return null;
};

const processGames = (requestData) => {
  let gamesData = []
  requestData.gamesData.forEach(obj => {
    let tmpGame = {
      configuration: JSON.stringify({}),
      stage: obj.stage,
      job_application_id: requestData.jobappId,
      activity_id: obj.id,
    };
    gamesData.push(tmpGame);
  });

  return gamesData;
};

const processQuestions = (requestData) => {

  let questionsData = [];
  requestData.questionsData.forEach(questionsObj => {

    const tmpQuestions = {};
    let data = {
      configuration: JSON.stringify({}),
      stage: questionsObj.stage,
      job_application_id: requestData.jobappId,
      activity_id: requestData.activityId,
    };
    questionsObj.questions.forEach(obj => {
      const isSelectionType = obj.type === 'multiselection' || obj.type === 'singleselection';
      // templates stores number_to and number_from keys, but answers uses from and to
      obj.from = obj.number_from;
      obj.to = obj.number_to;
      if (isSelectionType){
        obj.alternatives = typeof obj.alternatives === 'string' ? obj.configuration.alternatives : obj.alternatives;
      };

      delete obj.number_from;
      delete obj.number_to;
      if (obj.type === 'availability') {
        tmpQuestions['schedule'] = obj;
      } else {
        tmpQuestions[obj.id] = obj;
      }
    });
    data.configuration = JSON.stringify({ questions: tmpQuestions });
    questionsData.push(data);
  });
  return questionsData;
};

const processCuTes = (requestData) => {
  let cuTesData = []
  requestData.cuTes.forEach(obj => {
    let tmpCuTe = {
      configuration: JSON.stringify({ id: obj.id, name: obj.candidate_path_title }),
      stage: obj.stage,
      job_application_id: requestData.jobappId,
      activity_id: requestData.activityId,
    };
    cuTesData.push(tmpCuTe);
  });

  return cuTesData;
};

const processFilesRequests = (requestData) => {
  let filesRequestsData = []
  requestData.filesRequests.forEach(obj => {
    let tmpData = {
      stage: obj.stage,
      job_application_id: requestData.jobappId,
      activityId: requestData.activityId,
    };
    filesRequestsData.push(tmpData );
  });

  return filesRequestsData;
};

const processVideoInterview = (business, jobApplication, interview) => {
  let videoInterviewData = {
    interview: {
      businessName: business.name,
      processName: jobApplication.name,
      businessId: interview.jobApplicationId,
      logo: business.logo,
      interviewTxt: interview.emailContent.content,
    },
    questions: []
  };

  interview.questions.forEach((obj) => {
    videoInterviewData.questions.push({
      text: obj.text,
      time: obj.time,
    })
  });
  return videoInterviewData;
};

const processTriggers = (requestData) => {
  let triggersData = []
  requestData.triggers.forEach(obj => {
    let tmpTrigger = {
      id: obj.id,
      stage: obj.stage.id,
      job_application_id: requestData.jobappId,
      conditions: obj.conditions.map((condition) => {
        if (condition.type === 'KT'){
          delete condition.cute;
        }
        return condition;
      }),
      condition_operator: obj.conditionOperator,
      when_is_triggered: obj.when?.value,
      config: obj.config,
      // TODO: remove description
      description: 'Soy un trigger',
      primaryActions: obj.primaryActions.map((action) => {
        return {
          priority: 1,
          type: action.type,
          config: action.config,
        }
      }),
      secondaryActions: obj.secondaryActions ? obj.secondaryActions.map((action) => {
        return {
          priority: 2,
          type: action.type,
          config: action.config,
        }
      }) : []
    };
    triggersData.push(tmpTrigger);
  });
  return triggersData;
};

/**
 * Format context state before doing the request
 * @param {*} requestData 
 */
export const processRequest = (business, jobApplication, requestData, type) => {
  switch (type) {
    case 'jobapplication':
      const tmpStages = requestData.stages.map(obj => ({ code: obj.id, name: obj.name, required: obj.required }));
      const jobAppObj = {
        'genome': {},
        'department': requestData.jobApplication.department,
        'job_application': requestData.jobApplication.name,
        'total_vacancies': requestData.jobApplication.totalVacancies ?? null,
        'language': requestData.jobApplication.language.toLowerCase(),
        'location_country': requestData.jobApplication.country ?? null,
        'location_city': requestData.jobApplication.city ?? null,
        'closure_date': moment(requestData.jobApplication.closureDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        'tags': requestData.jobApplication.tags,
        'welcome_text': requestData.jobApplication.welcomeText,
        'description': requestData.jobApplication.description,
        'youtube_intro_video': requestData.jobApplication.introVideo ?? null,
        'optional_fields': requestData.candidateForm.candidateFormData,
        'stages': {
          stages: tmpStages,
        },
        'status': requestData.jobApplication.status,
        'custom_fields': requestData.jobApplication.custom_fields,
        'is_confidential': requestData.jobApplication.is_confidential,
        'backgroundImage': requestData.jobApplication.is_confidential ? null : requestData.jobApplication.backgroundImage,
      };
      return jobAppObj;

    case 'questions':
      const questionsData = processQuestions(requestData);
      return questionsData;

    case 'games':
      const gamesData = processGames(requestData);
      return gamesData;

    case 'cutes':
      const cuTesData = processCuTes(requestData);
      return cuTesData;

    case 'videointerview':
      const videoInterview = processVideoInterview(business, jobApplication, requestData);
      return videoInterview;

    case 'filesRequests':
      const filesRequests = processFilesRequests(requestData);
      return filesRequests;
    
    case 'triggers':
      const triggersData = processTriggers(requestData);
      return triggersData;
  }
};

const oldRequiredStage = (code) => {
  return ['INITIAL', 'OTHER', 'HIRED', 'V_INT', 'V_INTE', 'V_INTR'].includes(code);
}


const assignedAdditionalQuestions = (responseData) => {
  const questionsData = JSON.parse(responseData.configuration).questions;
  const questions = Object.keys(questionsData).map(key => {
    return (
      {
        id: key,
        type: questionsData[key].type,
        question: questionsData[key].question,
        mandatory: questionsData[key].mandatory,
        additionals: questionsData[key].additionals,
        alternatives: questionsData[key].alternatives,
        position: questionsData[key].position,
        number_from: questionsData[key].number_from ? questionsData[key].number_from : questionsData[key].from,
        number_to: questionsData[key].number_to ? questionsData[key].number_to : questionsData[key].to,
        configuration: questionsData[key].configuration,
      }
    )
  });

  const newData = {
    id: responseData.stage,
    stage: responseData.stage,
    questions: questions,
  };
  return newData;
};

const assignedGames = (responseData) => {
  const game = { ...responseData.game.activity };
  const deprecatedStages = ['1', '2'];
  if (deprecatedStages.includes(responseData.game.stage)) {
    // Creacion vieja, los juegos llegan con stage 2 de repente
    game.stage = 'ACTIV';
  }
  else {
    game.stage = responseData.game.stage;
  }
  return game;
};

const assignedCuTes = (responseData) => {
  // Para cuando a un business se le quita una prueba pero tiene
  // la assignedActivity con esa prueba asignada. 
  const defaultFoundCute = {
      "id": "",
      "candidate_path_title": "Prueba personalizada",
      "created_by": null,
      "created_by_business_user": null,
      "created_by_detail": {
          "business_name": "Genomawork",
          "business_user_name": "Genomawork"
      },
      "cute_type": "ALTERNATIVES",
      "date_created": null,
      "date_updated": null,
      "description": "",
      "public": false,
      "questions": {
          "data": []
      },
      "scores_config": {},
      "shared_with": [],
      "styles": {},
      "title": ""
  };
  const assignedCuTe = JSON.parse(responseData.cute.configuration);
  const assignedCuTeId = assignedCuTe.id
  const cuTeName = assignedCuTe.name;
  const foundCuTe = responseData.allCuTes.find(obj => obj.id === assignedCuTeId) || structuredClone(defaultFoundCute);
  foundCuTe.stage = responseData.cute.stage;
  foundCuTe.id = assignedCuTeId
  foundCuTe.title = foundCuTe.title ? foundCuTe.title : cuTeName;
  return foundCuTe;
};

/**
 * Process body before storing the data in the context
 * @param {*} responseBody 
 */
export const processResponses = (responseBody, type) => {
  switch (type) {
    case 'jobapplication':
      const jobAppObj = {
        id: responseBody.id,
        name: responseBody.job_application,
        description: responseBody.description,
        backgroundImage: responseBody.banner_picture,
        country: responseBody.location_country,
        city: responseBody.location_city,
        department: responseBody.department,
        totalVacancies: responseBody.total_vacancies,
        language: responseBody.language,
        closureDate: moment(responseBody.closure_date, 'YYYY-MM-DD').format('DD-MM-YYYY'),
        welcomeText: responseBody.welcome_text,
        status: responseBody.status,
        tags: responseBody.tags,
        introVideo: responseBody.youtube_intro_video,
        genome: responseBody.genome_detail,
        custom_fields: responseBody.custom_fields,
        is_confidential: responseBody.is_confidential,
      };
      return jobAppObj;

    case 'stages':
      const stages = responseBody.stages.map(obj => ({ id: obj.code, name: obj.name, required: obj.required ? obj.required : oldRequiredStage(obj.code) }))
      return stages;

    case 'form':
      const candidateForm = {
        'nationality': responseBody.nationality ?? true,
        'careers': responseBody.careers ?? true,
        'phone': responseBody.phone ?? true,
        'country': responseBody.country ?? true,
        'experiences': responseBody.experiences ?? true,
        'birth_date': responseBody.birth_date ?? true,
        'commune': responseBody.commune ?? true,
        'cv': responseBody.cv ?? true,
        'gender': responseBody.gender ?? true,
        // 'marital_status': responseBody.marital_status ?? true,
        //'has_disability': responseBody.has_disability ?? true,
        //'disability_type': responseBody.disability_type ?? true,,
        'education_level': responseBody.education_level ?? true
      }
      return candidateForm;

    case 'questions':
      const additionalQuestions = assignedAdditionalQuestions(responseBody);
      return additionalQuestions;

    case 'games':
      const game = assignedGames(responseBody);
      return game;

    case 'cutes':
      const cuTe = assignedCuTes(responseBody);
      return cuTe;

    case 'videointerview':
      return null;
    
      case 'triggers':
        const triggers = responseBody.map(obj => ({ 
          id: obj.id,
          when: obj.when_is_triggered,
          stage: { id: obj.stage }, 
          config: obj.config,
          primaryActions: obj.actions_config.filter((action) => action.priority === 1),
          secondaryActions: obj.actions_config.filter((action) => action.priority === 2),
          conditions: obj.conditions,
          conditionsOperator: obj.condition_operator,
        }))
        return triggers;
  }
};