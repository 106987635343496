import {
    DATA_RECEIVE_PROTECTED_DATA,
    DATA_LANE_FETCH,
    DATA_FETCH_PROTECTED_DATA_REQUEST,
    FILE_FETCH,
    DATA_FETCH,
    DATA_RECEIVE_FAILURE,
    DATA_RECEIVE_SUCCESS,
    DATA_SEND,
    DATA_UPDATE,
    DATA_UPDATE_SUCCESS,
    DATA_SEND_FAILURE,
    DATA_SEND_SUCCESS,
    DATA_LANE_RECEIVE_SUCCESS,
    DATA_SIMPLE_LANE_RECEIVE_SUCCESS,
    DATA_SIMPLE_LANE_FETCH,
    SEARCH_SUCCESS,
    SEARCH_BEGIN,
    SEARCH_FAILURE,
    FETCH_SUCCESS_V2,
    FETCH_BEGIN_V2,
    FETCH_FAILURE_V2,
    SET_UTM_PARAMS,
    SET_SELECTED_BRANCHES,
} from '../constants';

const initialState = {
    data: {
        items: [],
        configuration: '{"questions":[]}',
        game_scores: []
    },
    lanes: {},
    simpleLanes: {},
    isFetching: false,
    isSearching: false,
    searchResults: [],
    searchError: null,
    error: null,
    utm_params: {},
    selectedBranches: {},
};

export default function dataReducer(state = initialState, action) {
    switch (action.type) {
        case DATA_RECEIVE_PROTECTED_DATA:
            return Object.assign({}, state, {
                data: action.payload.data,
                isFetching: false
            });

        case DATA_FETCH_PROTECTED_DATA_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });

        case DATA_FETCH:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...initialState,
                utm_params: state.utm_params,
                searchResults: [...state.searchResults],
                isFetching: true,
                error: null
            };

        case FILE_FETCH:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
            };

        case DATA_RECEIVE_SUCCESS:
            // All done: set loading "false".
            // Also, replace the items with the ones from the server
            return {
                ...state,
                isFetching: false,
                data: { ...state.data, ...action.payload.data }
            };

        case DATA_LANE_FETCH:
            return {
                ...state,
                isFetching: true,
                lanes: { ...state.lanes }
            }

        case DATA_LANE_RECEIVE_SUCCESS:
            // All done: set loading "false".
            // Also, replace the items with the ones from the server
            return {
                ...state,
                isFetching: false,
                lanes: { ...state.lanes, ...action.payload }
            };
        case DATA_SIMPLE_LANE_FETCH:
            return {
                ...state,
                isFetching: true,

            }
        case DATA_SIMPLE_LANE_RECEIVE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                simpleLanes: action.payload.selected
            }

        case DATA_UPDATE:
            // All done: set loading "false".
            // Also, replace the items with the ones from the server
            return {
                ...state,
                isFetching: true,
                error: null
            };

        case DATA_UPDATE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: { ...state.data, ...action.payload.data }
            };

        case DATA_RECEIVE_FAILURE:
            // The request failed, but it did stop, so set loading to "false".
            // Save the error, and we can display it somewhere
            // Since it failed, we don't have items to display anymore, so set it empty.
            // This is up to you and your app though: maybe you want to keep the items
            // around! Do whatever seems right.
            return {
                ...state,
                isFetching: false,
                error: action.payload.error,
                data: []
            };

        case DATA_SEND:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                isFetching: true,
                error: null
            };

        case DATA_SEND_SUCCESS:
            // All done: set loading "false".
            // Also, replace the items with the ones from the server
            return {
                ...state,
                isFetching: false,
                data: { ...state.data, ...action.payload.data }
            };

        case DATA_SEND_FAILURE:
            // The request failed, but it did stop, so set loading to "false".
            // Save the error, and we can display it somewhere
            // Since it failed, we don't have items to display anymore, so set it empty.
            // This is up to you and your app though: maybe you want to keep the items
            // around! Do whatever seems right.
            return {
                ...initialState,
                isFetching: false,
                error: action.payload.error,
            };
        case SEARCH_BEGIN:
            // SEARCH BEGINS AND MARK THE STATE AS LOADING AND ERRORS
            return {
                ...state,
                isFetching: true,
                searchError: null
            };
        case SEARCH_SUCCESS:
            // MARK THE STATE AS NOT LOADING, CLEAN ERRORS AND ADD RESULTS
            return {
                ...state,
                isFetching: false,
                searchResults: action.payload.searchResults || state.searchResults,
                searchError: null
            };
        case SEARCH_FAILURE:
            // MARK THE STATE AS NOT LOADING, CLEAN RESULTS AND ADD ERRORS
            return {
                ...state,
                isFetching: false,
                searchResults: [],
                searchError: action.payload.error
            };

        // Only update fetch and error state
        case FETCH_BEGIN_V2:
            return {
                ...state,
                isFetching: true,
                error: null
            };
        case FETCH_SUCCESS_V2:
            return {
                ...state,
                isFetching: false,
                error: null
            };
        case FETCH_FAILURE_V2:
            return {
                ...state,
                isFetching: false,
                error: true
            };
        case SET_UTM_PARAMS:
            return {
                ...state,
                utm_params: action.payload.utm_params,
            }
        case SET_SELECTED_BRANCHES:
            const { jobApplicationId, selectedBranches } = action.payload;
            state.selectedBranches[jobApplicationId] = selectedBranches;
            return {
                ...state,
            }

        default:
            return state;
    }
}