import React from 'react';
import {
	Alert,
  Col,
  Row,
  Icon,
  Typography,
} from 'antd';

const { Title, Text } = Typography;

const AlternativeDetail = ({ answers, questions, scores, scoresConfig, sortedQuestions }) => {

  const answerComponent = (answerId, type) => {
    const candidateAnswer = type === 'best' ? answers[answerId].best : answers[answerId].worst;
    const answerData = questions[answerId].options.find(elem => elem._uid === candidateAnswer);
		const hasCorrectAnswer = type === 'best' ? scores.raw_result[answerId].best : scores.raw_result[answerId].worst;
    
    const IncorrectAnswer = () => {
      const values = scoresConfig.correct_answers[answerId].value;
			const bestKey = Object.keys(values).reduce((a, b) => values[a] > values[b] ? a : b);
			const correctBestAnswer = questions[answerId].options.find((elem) => elem._uid === bestKey)
      const worstKey = Object.keys(values).reduce((a, b) => values[a] < values[b] ? a : b);
			const correctWorstAnswer = questions[answerId].options.find((elem) => elem._uid === worstKey)
      return (
        <>
          {type === 'best' ? 
            <>
              <Alert message={answerData.label} type={answerData._uid === worstKey ? 'error' : 'warning'} showIcon />
							<Text type="secondary">Respuesta deseable: {correctBestAnswer.label}</Text>
            </> : 
            <>
              <Alert message={answerData.label} type={answerData._uid === bestKey ? 'error' : 'warning'} showIcon />
							<Text type="secondary">Respuesta deseable: {correctWorstAnswer.label}</Text>
            </>}
        </>
      )
    };

    return (
			<div style={{ margin: '10px 0' }}>
				{ 
					hasCorrectAnswer ? 
						<Alert message={answerData.label} type="success" showIcon /> :
            <IncorrectAnswer />
				}
			</div>
    );
  };

  return (
    <Row type="flex">
      {sortedQuestions.map((elem, idx) => {
        return (
          <Col span={24} key={idx}>
            <Row style={{ margin: '20px 0' }}>
              <Title level={4}>
                {questions[elem].title}
              </Title>
              <Text>
                {questions[elem].description}
              </Text>
            </Row>
            <Row>
              <Col span={1} style={{ paddingLeft: 7 }}>
                <Icon type="like"/>
              </Col>
              <Col span={23}>
                Alternativa seleccionada como la mejor:
              </Col>
            </Row>
            {answerComponent(elem, 'best')}
            <Row>
              <Col span={1} style={{ paddingLeft: 7 }}>
                <Icon type="dislike"/>
              </Col>
              <Col span={23}>
                Alternativa seleccionada como la peor:
              </Col>
            </Row>
            {answerComponent(elem, 'worst')}
          </Col>
        );
      })}
    </Row>
  )
};
export default AlternativeDetail;