import React from 'react';
import {
  Col,
  Row,
  Input,
  Select,
  Switch,
  Icon,
  Checkbox,
  Typography,
} from 'antd';

const { Text } = Typography;

const ColModalCreate = ({
  selected,
  questionToSend,
  setQuestionToSend,
  // showModalFunction,
  // setIsModalAddAlternativeVisible,
  setCounter,
  setSelected,
  counter,
}) => {

  return (
    <Row gutter={[16, 20]}>
      <Col>
        <Row gutter={[16, 20]}>
          <Col>
            <Text>
              {selected === 'file'
                ? 'Nombre del documento'
                : '¿Cuál es tu pregunta?'}
            </Text>
          </Col>
          <Col>
            <Input
              placeholder={selected === 'file' ? 'Nombre' : 'Pregunta'}
              value={questionToSend.question}
              onChange={({ target }) =>
                setQuestionToSend((questionToSend) => {
                  return { ...questionToSend, question: target.value };
                })
              }
            />
          </Col>
          {selected === 'file' && (
            <>
              <Col>
                <Text>Descripción del documento</Text>
              </Col>
              <Col>
                <Input
                  placeholder='Descripción'
                  value={questionToSend.additionals?.instructions}
                  onChange={({ target }) =>
                    setQuestionToSend((questionToSend) => {
                      return {
                        ...questionToSend,
                        additionals: {
                          ...questionToSend.additionals,
                          instructions: target.value,
                        },
                      };
                    })
                  }
                />
              </Col>
            </>
          )}
          <Col>
            <Text>Tipo de respuesta</Text>
          </Col>
          <Col>
            <Select
              defaultValue={selected || 'singleline'}
              style={{ width: '100%' }}
              onChange={(option) => {
                setSelected(option);
                setQuestionToSend((questionToSend) => {
                  return {
                    ...questionToSend,
                    question_type: option,
                  };
                });
              }}
            >
              <Option value='singleline'>
                <Icon
                  type='align-left'
                  style={{ marginRight: '0.688rem' }}
                ></Icon>
                Texto
              </Option>
              <Option value='multiline'>
                <Icon
                  type='align-left'
                  style={{ marginRight: '0.688rem' }}
                ></Icon>
                Texto múltiple
              </Option>
              <Option value='int'>
                <Icon
                  type='calculator'
                  style={{ marginRight: '0.688rem' }}
                ></Icon>
                Numérico
              </Option>
              <Option value='singleselection'>
                <Icon type='check' style={{ marginRight: '0.688rem' }}></Icon>
                Selección única
              </Option>
              <Option value='multiselection'>
                <Icon
                  type='unordered-list'
                  style={{ marginRight: '0.688rem' }}
                ></Icon>
                Selección múltiple
              </Option>
              <Option value='file'>
                <Icon
                  type='paper-clip'
                  style={{ marginRight: '0.688rem' }}
                ></Icon>
                Documento adjunto
              </Option>
            </Select>
          </Col>
          {selected === 'file' && (
            <>
              <Col>
                <Text>Tipos de archivos admitidos</Text>
              </Col>
              <Col>
                <Select
                  mode='multiple'
                  allowClear
                  style={{ width: '100%' }}
                  placeholder='Please select'
                  defaultValue={['PDF (Documento)']}
                  onChange={(option) => {
                    setQuestionToSend({
                      ...questionToSend,
                      additionals: {
                        ...questionToSend.additionals,
                        accepted_extensions: option,
                      },
                    });
                  }}
                >
                  <Option value='JPG'>JPG (Imagen)</Option>
                  <Option value='JPEG'>JPEG (Imagen)</Option>
                  <Option value='PNG'>PNG (Imagen)</Option>
                  <Option value='TIFF'>TIFF (Imagen)</Option>
                  <Option value='BMP'>BMP (Imagen)</Option>
                  <Option value='PDF (Documento)'>PDF (Documento)</Option>
                  <Option value='DOC'>DOC (Documento)</Option>
                  <Option value='DOCX'>DOCX (Documento)</Option>
                  <Option value='XPS'>XPS (Documento)</Option>
                </Select>
              </Col>
            </>
          )}
        </Row>
      </Col>
      {(selected === 'singleselection' || selected === 'multiselection') && (
        <Col>
          <Row gutter={[8, 0]}>
            <Col span={13}>
              <Row gutter={[0, 16]}>
                <Col>
                  <Text>Alternativas</Text>
                </Col>
                {questionToSend.configuration.alternatives.map(
                  (number, index) => (
                    <Col key={index}>
                      <Input
                        name={'text'}
                        value={
                          questionToSend.configuration.alternatives[index]?.text
                        }
                        placeholder={`Alternativa ${index + 1}`}
                        onChange={({ target }) =>
                          setQuestionToSend({
                            ...questionToSend,
                            ...(questionToSend.configuration.alternatives[
                              index
                            ] = {
                              [target.name]: target.value,
                              correct: false,
                            }),
                          })
                        }
                      />
                    </Col>
                  )
                )}
              </Row>
            </Col>
            <Col span={7}>
              <Row gutter={[0, 24]}>
                <Col>
                  <Row>
                    <Col>
                      <Text disabled>Alternativa Correcta</Text>
                    </Col>
                  </Row>
                </Col>
                {questionToSend.configuration.alternatives.map(
                  (number, index) => (
                    <Col key={index}>
                      <Row
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Col span={3}>
                          <Checkbox
                            onChange={({ target }) =>
                              setQuestionToSend((questionToSend) => {
                                questionToSend.configuration.alternatives[
                                  index
                                ] = {
                                  ...questionToSend.configuration.alternatives[
                                    index
                                  ],
                                  correct: target.checked,
                                };
                                return questionToSend;
                              })
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  )
                )}
              </Row>
            </Col>
            <Col span={4}>
              <Row gutter={[0, 24]}>
                <Col>
                  <Row style={{ display: 'flex', justifyContent: 'center' }}>
                    <Col>
                      <Text disabled>Eliminar</Text>
                    </Col>
                  </Row>
                </Col>
                {questionToSend.configuration.alternatives.map(
                  (number, index) => (
                    <Col key={index}>
                      <Row
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Col
                          span={3}
                          onClick={() => {
                            if (counter > 2) {
                              setQuestionToSend((questionToSend) => {
                                let arr =
                                  questionToSend.configuration.alternatives.filter(
                                    (alternative, i) => i != index
                                  );
                                questionToSend.configuration.alternatives = arr;
                                return questionToSend;
                              });
                              setCounter((counter) => counter - 1);
                            }
                          }}
                          style={{
                            cursor: counter > 2 ? 'pointer' : 'not-allowed',
                          }}
                        >
                          <Icon
                            type='delete'
                            style={{ color: '#A4A4A4', fontSize: '18px' }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  )
                )}
              </Row>
            </Col>
          </Row>
          <Col>
            <Row gutter={[24, 0]}>
              <Col
                span={1}
                onClick={() => {
                  setQuestionToSend((questionToSend) => {
                    questionToSend.configuration.alternatives[counter] = {
                      text: undefined,
                      correct: undefined,
                    };
                    return questionToSend;
                  });
                  setCounter((counter) => counter + 1);
                }}
                style={{ cursor: 'pointer' }}
              >
                <Icon
                  type='plus-circle'
                  style={{ color: '#F175A5', fontSize: '18px' }}
                />
              </Col>
              <Col
                span={8}
                onClick={() => {
                  console.log(
                    questionToSend.configuration.alternatives[counter]?.text,
                    'ASDASDASD'
                  );
                  if (
                    !questionToSend.configuration.alternatives[counter]?.text
                  ) {
                    setQuestionToSend((questionToSend) => {
                      questionToSend.configuration.alternatives[counter] = {
                        text: undefined,
                        correct: undefined,
                      };
                      return questionToSend;
                    });
                  }
                  setCounter((counter) => counter + 1);
                }}
                style={{ cursor: 'pointer' }}
              >
                <Text style={{ color: '#F175A5' }}>Agregar alternativa</Text>
              </Col>
            </Row>
          </Col>
          {/* <Col>
            <Row gutter={[24, 0]}>
              <Col
                span={1}
                onClick={() =>
                  showModalFunction(setIsModalAddAlternativeVisible, true)
                }
                style={{ cursor: 'pointer' }}
              >
                <Icon
                  type='copy'
                  style={{ color: '#8C8C8C', fontSize: '18px' }}
                />
              </Col>
              <Col
                span={12}
                onClick={() =>
                  showModalFunction(setIsModalAddAlternativeVisible, true)
                }
                style={{ cursor: 'pointer' }}
              >
                <Text style={{ color: '#8C8C8C' }}>
                  Copiar lista de alternativas
                </Text>
              </Col>
            </Row>
          </Col> */}
        </Col>
      )}
      {selected === 'int' && (
        <Col>
          <Row gutter={[32, 0]}>
            <Col span={12}>
              <Row gutter={[0, 8]}>
                <Col>
                  <Text>Desde</Text>
                </Col>
                <Col>
                  <Input
                    placeholder='0'
                    value={questionToSend.number_from}
                    onChange={({ target }) =>
                      setQuestionToSend({
                        ...questionToSend,
                        number_from: target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={[0, 8]}>
                <Col>
                  <Text>Hasta</Text>
                </Col>
                <Col>
                  <Input
                    placeholder='0'
                    value={questionToSend.number_to}
                    onChange={({ target }) =>
                      setQuestionToSend({
                        ...questionToSend,
                        number_to: target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      )}
      {/* <Col>
        <Row>
          <Col span={11}>
            <Text disabled>Requerida para todos mis procesos</Text>
          </Col>
          <Col span={2}>
            <Switch defaultChecked />
          </Col>
        </Row>
      </Col> */}
    </Row>
  );
};

export default ColModalCreate;
