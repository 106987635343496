import React, { Fragment } from 'react'
import { Card, Avatar, Typography } from 'antd'
import '../Feedback.scss'
import i18n from '../../../../i18n';

const GameResults = (props) => {

  const { Meta } = Card
  const { Text, Title } = Typography

  const computeScoreText = (inverted, score, measure, space = ' ') => {
    if (inverted) {
      return measure + space + score
    } else {
      return score + space + measure
    }
  }

  const gridStyle = {
    width: '150px',
    textAlign: 'center',
  };

  return (
    <Card
      style={{ width: 500 }}
      className='game-results'
      bordered={true}
    >
      <Meta
        avatar={<Avatar size={65} src={props.image} alt={props.alt} />}
        title={props.titleCard}
        style={{'width': 100}}
      />
      <div className='game-results__container'>
        <Title className='title' >{props.upperTitle}</Title>
        <div className='game-results-boxes' >
          <Card.Grid style={gridStyle} className='game-results-you'>
            <Text>{computeScoreText(props.upperInverted, props.upperScore, props.upperMeasure, props.upperSpace)}</Text>
            <Text>{i18n.t('feedback__you')}</Text>
          </Card.Grid>
          <Card.Grid style={gridStyle} className='game-results-average'>
            <Text>{computeScoreText(props.upperInverted, props.upperAvgScore, props.upperMeasure, props.upperSpace)}</Text>
            <Text>{i18n.t('feedback__average')}</Text>
          </Card.Grid>
        </div>
      </div>

      {props.lowerScore ?
        <Fragment>
          <div className='game-results__container'>
            <Title className='title' >{props.lowerScore.title}</Title>
            <div>
              <Card.Grid style={gridStyle} className='game-results-you'>
                <Text>{computeScoreText(props.lowerScore.inverted, props.lowerScore.score, props.lowerScore.measure)}</Text>
                <Text>Tú</Text>
              </Card.Grid>
              <Card.Grid style={gridStyle} className='game-results-average'>
                <Text>{computeScoreText(props.lowerScore.inverted, props.lowerScore.avgScore, props.lowerScore.measure)}</Text>
                <Text>Promedio</Text>
              </Card.Grid>
            </div>
          </div>
        </Fragment>
        : null
      }
    </Card >
  )
}

export default GameResults
