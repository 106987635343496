import React, { useState, useEffect } from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authActionCreators from '../../actions/auth';
import * as dataActionCreators from '../../actions/data';
import PropTypes from 'prop-types';

import { Typography, Button, Modal, Select, Col } from 'antd';
import genomaLogo from '../../media/logos/genoma-logo.png';
import i18n from '../../i18n';
const FLAG_URL = 'https://genoma-assets.s3.us-east-2.amazonaws.com/flags/'
const languages = {
  'ES': {
    'flag': 'ES',
    'language': i18n.t('commons__ES')
  },
  'EN': {
    'flag': 'US',
    'language': i18n.t('commons__EN')
  },
  'PT': {
    'flag': 'BR',
    'language': i18n.t('commons__PT')
  }
}

const homeBackgroundImg = 'https://genoma-assets.s3.us-east-2.amazonaws.com/login_bg.svg';

const { Title } = Typography;
require('./style.scss');

function HomeView(props) {
  const propTypes = {
    statusText: PropTypes.string,
    userName: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
  };

  const defaultProps = {
    statusText: '',
    userName: '',
  };
  const onSelect = (code) => {
    changeLanguage(code.toLowerCase())
  }; 

  useEffect(() => {
    if (props.userType === 'personal') {
      props.dispatch(push('/me'))
    }
    else if (props.userType === 'business') {
      props.dispatch(push('/home'))
    }
    if (!i18n.laguage in ['es', 'en', 'pt']){
      i18n.changeLanguage('es');
    }
  }, [])

  const [privacyPolicyDialog, setPrivacyPolicyDialog] = useState(false);

  const goToProtected = () => {
    props.dispatch(push('/protected'));
  };

  const goToUserHome = () => {
    const url = '/login';
    props.dispatch(push(url));
  };

  const gotoBusinessHome = () => {
    const url = '/businesslogin';
    props.dispatch(push(url));
  };

  const handleDialog = () => {
    // setPrivacyPolicyDialog(!privacyPolicyDialog)
    window.open('/privacy');
  };
  const changeLanguage = (value) => {
    if (value.toLowerCase() == 'gb'){
      value = 'EN'
    } else if (value.toLowerCase() == 'br'){
      value = 'PT'
    }
    i18n.changeLanguage(value.toLowerCase());
    window.location.reload();
  }

  return (
    <div
      style={{ backgroundImage: `url(${homeBackgroundImg})` }}
      className="home__container"
    >
      <Modal
        title="Política de Privacidad de Genomawork"
        visible={privacyPolicyDialog}
        onOk={handleDialog}
        onCancel={handleDialog}
        centered={true}
        className="terms-and-conditions-modal"
        footer={[
          <Button key="back" type="primary" onClick={handleDialog}>
            Cerrar
          </Button>,
        ]}
      >
        <iframe
          credentialless=''
          width="500"
          height="500"
          src="https://docs.google.com/document/u/1/d/e/2PACX-1vT4odFJk2URzMvEF3GADBndjXdLqVhPHaGNEdhEvZmieRH00HQiamys_mQECH-17aXTbiUaW2iqSiek/pub?embedded=true"
        ></iframe>
      </Modal>
      <Col xs={20} lg={13}>
        <img src={genomaLogo} alt="genoma logo" style={{ width: '321px' }} />
        <div className="home__center">
          <Title level={2}>{i18n.t('landing__page__welcome')}</Title>
          <Button
            onClick={gotoBusinessHome}
            type="primary"
            size="large"
            className="button-antd"
          >
            {i18n.t('landing__page__company_access')}
          </Button>
          <Button
            onClick={goToUserHome}
            type="primary"
            size="large"
            className="button-antd"
          >
            {i18n.t('landing__page__candidate_access')}
          </Button>
        </div>
        <footer>
          <span style={{ color: '#786d73' }}>Copyright © 2021 Genomawork.</span>
          <Button type="link" onClick={handleDialog}>
            • {i18n.t('landing__page__conditions')}
          </Button>
          <Button type="link" onClick={handleDialog}>
            • {i18n.t('landing__page__policy')}
          </Button>
          <div style={{paddingTop:'0.1em'}}>
          <Select
            onSelect={onSelect}
            defaultValue={i18n.language.split('-')[0].toLowerCase().includes('pt') ? 'PT': i18n.language.split('-')[0].toLowerCase().includes('en') ? 'EN' : 'ES'}
            style={{ paddingLeft: '0.5em', display: 'flex'}}
            maxTagCount={'responsive'}
            > 
            {Object.keys(languages).map((key, index) => (
              <Select.Option key={index} value={key}>
                <span className='location-with-flag' style={{alignItems:'center'}}>
                  <span className='jl-flag'>
                  <div className="menu-select-image">
                    <img src={`${FLAG_URL}${languages[key].flag}.svg`}/>
                  </div>
                  </span> {' '}
                </span>
              </Select.Option>
            ))}
          </Select>
          </div>
        </footer>
      </Col>
      {/* <div className="text-center" style={{ paddingTop: '20%' }}>
				<Title level={3}>Hola, {props.userName || 'bienvenido!'}</Title>
				<Button onClick={gotoBusinessHome}
					type="primary" size='large' shape='round' className='button-antd'
				>
					Acceso Empresas
				</Button>
				<Button onClick={goToUserHome}
					type="primary" size='large' shape='round' className='button-antd' style={{ marginLeft: "10px", marginTop: "10px" }}
				>
					Acceso Candidatos
				</Button>
				<br />
				<Button className='privacy-link' type='link' onClick={handleDialog}>Política de Privacidad</Button>
			</div> */}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    userName: state.auth.userName,
    errors: state.auth.errors,
    isAuthenticated: state.auth.isAuthenticated,
    userType: state.auth.userType,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    authActions: bindActionCreators(authActionCreators, dispatch),
    dataActions: bindActionCreators(dataActionCreators, dispatch),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeView);
export { HomeView as HomeViewNotConnected };
