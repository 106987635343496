import React, { useState, useEffect } from "react";
import fetch from "isomorphic-fetch";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { push } from "react-router-redux";
import { bindActionCreators } from "redux";
import * as Sentry from "@sentry/browser";

import * as actionCreators from "../../../actions/data";
import i18n from "../../../i18n";

import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  Icon,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Select,
  Typography,
  Upload,
} from "antd";

import { SERVER_URL } from "../../../utils/config";

const { Item } = Breadcrumb;
const { Option } = Select;
const { Paragraph, Text, Title } = Typography;

const FilesTemplateForm = (props) => {
  const { getFieldDecorator, getFieldValue } = props.form;

  const [files, setFiles] = useState([]);
  const [templateName, setTemplateName] = useState("");
  const [instructions, setInstructions] = useState("");
  const [questions, setQuestions] = useState(
    props.location?.state?.questions || [
      {
        name: undefined,
        extensions: [],
        description: "",
      },
      {
        name: undefined,
        extensions: [],
        description: "",
      },
      {
        name: undefined,
        extensions: [],
        description: "",
      },
    ]
  );
  const [selectValues, setSelectValues] = useState([[], [], []]);

  const options = [".pdf", ".docx", ".xlsx", ".pptx", ".zip"];

  useEffect(() => {
    const business = props.business;
    if (props.match.params["id"]) {
      const url = `/api/v1/gamesandtests/business/${business.id}/filesrequesttemplates/${props.match.params["id"]}/`;
      props.actions.fetchAndReturn(props.token, url, "GET").then((response) => {
        if (response.status >= 400) {
          message.error("Error al obtener plantilla");
          props.dispatch(push("/filestemplates"));
        } else {
          setQuestions(response.body.request.questions);
          setSelectValues(
            response.body.request.questions.map((q) => q.extensions)
          );
          setInstructions(response.body.request.instruction);
          setTemplateName(response.body.request.name);
          setFiles(response.body.request.instruction_files);
        }
      });
    }
  }, []);

  const setQuestion = (e, field, index) => {
    const fieldToChange = field == "name" ? e.target.value : e;
    const newQuestions = questions;
    newQuestions[index][field] = fieldToChange;
    setQuestions(newQuestions);
    setSelectValues(newQuestions.map((q) => q.extensions));
  };

  const deleteQuestion = (index) => {
    const newQuestions = questions.filter((_, i) => i !== index);
    setQuestions(newQuestions);
    setSelectValues(newQuestions.map((q) => q.extensions));
  };

  const submitData = (e) => {
    e.preventDefault();
    const business = props.business;
    props.form.validateFields((err, fieldsValues) => {
      if (!err) {
        const url = `/api/v1/gamesandtests/business/${
          business.id
        }/filesrequesttemplates/${
          props.match.params["id"] ? `${props.match.params["id"]}/` : ""
        }`;
        const method = props.match.params["id"] ? "PATCH" : "POST";
        const newQuestions = JSON.stringify(
          questions.filter((q) => q.name !== undefined)
        );
        let formData = new FormData();
        const existingFiles = [];
        files.map((file) => {
          if (!file.business) {
            formData.append(file.name, file.originFileObj);
          } else {
            existingFiles.push(file.id);
          }
        });
        formData.append("old_files", existingFiles);
        formData.append("name", templateName);
        formData.append("instruction", instructions);
        formData.append("questions", newQuestions);

        fetch(`${SERVER_URL}${url}`, {
          method: method,
          headers: {
            Accept: "application/json",
            Authorization: `Token ${props.token}`,
          },
          body: formData,
        })
          .then((response) => {
            if (response.status >= 400) {
              message.error(i18n.t('commons__unsaved_changes',{ns: 'businessUser'}));
            } else {
              message.success(i18n.t('commons__saved_changes',{ns: 'businessUser'}));
              props.dispatch(push("/filestemplates"));
            }
          })
          .catch((error) => {
            Sentry.captureException(error);
            message.error(i18n.t('commons__unsaved_changes',{ns: 'businessUser'}));
          });
      }
    });
  };

  const dummyRequest = ({ onSuccess }) => setTimeout(() => onSuccess("ok"), 0);

  const handleAttachFile = ({ fileList }) => {
    const filteredFiles = fileList.filter((file) => file.status !== "removed");
    // Update the state with the new file list
    setFiles(filteredFiles);

    return files;
  };

  const getBlobObj = async (file) => {
    const blob = file.business
      ? await fetch(file.file).then((r) => r.blob())
      : new Blob([file.originFileObj]);
    const url = window.URL.createObjectURL(blob);
    let aux = document.createElement("a");
    document.body.appendChild(aux);
    aux.style = "display: none";
    aux.href = url;
    aux.download = file.name;
    aux.click();
    window.URL.revokeObjectURL(url);
  };

  const onChangeUpload = (info) => {
    if (info.file.status === "uploading") {
      info.file.status = "done";
      const currentFileListSize = info.fileList.reduce((x, y) => x + y.size, 0);
      if (currentFileListSize >= 10485760) {
        message.error(i18n.t('profile__max_size_file', { ns: 'candidateProfile' }));
      } else if (info.fileList.length > 3) {
        message.error(i18n.t('profile__max_files_count', { ns: 'candidateProfile' }));
      } else {
        handleAttachFile(info);
      }
    } else if (info.file.status === "done") {
      message.success(`${info.file.name} ${i18n.t('profile__uploaded_ok', { ns: 'candidateProfile' })}`);
      handleAttachFile(info);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} ${i18n.t('profile__no_uploaded', { ns: 'candidateProfile' })}`);
    } else if (info.file.status === "removed") {
      handleAttachFile(info);
    }
  };

  return (
    <Layout style={{ margin: "30px 40px 30px 40px" }}>
      <Row style={{ marginBottom: "1.5em" }}>
        <Breadcrumb separator=">">
          <Item>
            <Link to="/tools">{i18n.t('management__my_tools',{ns: 'businessUser'})}</Link>
          </Item>
          <Item>
            <Link to="/tools">{i18n.t('management__tools_title',{ns: 'businessUser'})}</Link>
          </Item>
          <Item>
            <Link to="/filestemplates">{i18n.t('docr__title')}</Link>
          </Item>
          <Item>
            {props.match.params["id"] ? "Editar " : "Crear "}plantilla de documentos
          </Item>
        </Breadcrumb>
      </Row>
      <Row type="flex" align="middle" style={{ marginBottom: "1em" }}>
        <Col style={{ marginRight: "0.75em" }}>
          <Icon
            onClick={() =>
              Modal.confirm({
                cancelText: i18n.t('commons__cancel'),
                content: "Perderás todos los cambios no guardados.",
                okText: i18n.t('commons__confirm'),
                onOk: () => props.dispatch(push("/filestemplates")),
                title: "¿Estás seguro de querer volver atrás?",
              })
            }
            type="arrow-left"
          />
        </Col>
        <Col>
          <Title level={2} style={{ color: "#2B2B2B", margin: 0 }}>
            {props.match.params["id"] ? "Editar " : "Crear "}plantilla de
            documentos
          </Title>
        </Col>
      </Row>
      {!props.match.params["id"] && (
        <Row style={{ marginBottom: "2em" }}>
          <Paragraph
            style={{
              color: "#5E5E5E",
              fontSize: "16px",
              fontWeight: "normal",
              marginBottom: "0px",
            }}
          >
            En esta sección, podrás configuar los documentos que quieras solicitar a los candidatos.
          </Paragraph>
        </Row>
      )}
      <Row>
        <Form onSubmit={submitData}>
          <Card
            style={{ border: "1px solid #D9D9D9", paddingBottom: 0 }}
            bodyStyle={{ paddingBottom: 0 }}
          >
            <Form.Item
              initi
              help={
                <Row justify="space-between" type="flex">
                  <Col>
                    {getFieldValue("name")?.length == 0 ||
                    props.location?.state?.name?.length == 0
                      ? "Obligatorio"
                      : ""}
                  </Col>
                  <Col>
                    {`${
                      getFieldValue("name")?.length ??
                      props.location?.state?.name?.length ??
                      0
                    }/50`}
                  </Col>
                </Row>
              }
              label={
                <Text>
                  <span style={{ color: "red" }}>*</span> Nombre de plantilla de
                  documentos
                </Text>
              }
            >
              <Input
                maxLength={50}
                placeholder="Escribe el nombre de plantilla"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              help={
                <Row justify="end" type="flex">
                  {`${
                    getFieldValue("instruction", {
                      rules: [{ required: false, message: "opcional" }],
                    })?.length ??
                    props.location?.state?.instruction.length ??
                    0
                  }/1000`}
                </Row>
              }
              label={
                <>
                  <Text strong>{i18n.t('commons__instructions')}</Text>
                  <Text style={{ color: "#BFBFBF" }}> ({i18n.t('commons__optional')})</Text>
                </>
              }
            >
              <Input.TextArea
                maxLength={1000}
                placeholder="Escribe los detalles de la plantilla"
                value={instructions}
                onChange={(e) => setInstructions(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Col style={{ marginRight: "15px" }}>
                <Upload
                  key="unique-key"
                  accept=".pdf,.doc,.docx,.ppt,.pptx,.xlx,.xlsx"
                  multiple={true}
                  onChange={onChangeUpload}
                  customRequest={(response) => dummyRequest(response)}
                  fileList={files.map((file, index) => ({ ...file, uid: index, key: index, }))}
                  onPreview={(file) => getBlobObj(file)}
                >
                  <Button>
                    <Icon type="paper-clip" /> {i18n.t('profile__add_files', { ns: 'candidateProfile' })}
                  </Button>
                </Upload>
              </Col>
            </Form.Item>
            <Form.Item
              label={
                <Text>
                  <span style={{ color: "red" }}>*</span> Documentos a solicitar{" "}
                  <Text style={{ color: "#BFBFBF" }}>
                    (Formatos de archivos permitidos .pdf, .docx, .xlsx, .pptx,
                    .zip.)
                  </Text>
                </Text>
              }
            >
              <>
                {questions.map((question, index) => (
                  <Row
                    align="middle"
                    key={`question-${index}-${question}`}
                    style={{ marginBottom: "10px" }}
                    type="flex"
                  >
                    <Col sm={12}>
                      {getFieldDecorator(`question-${index}`, {
                        initialValue: question["name"],
                      })(
                        <Row>
                          <Col sm={16}>
                            <Input
                              key={index}
                              placeholder="Escribe el nombre del documento"
                              onChange={(e) => setQuestion(e, "name", index)}
                              value={questions[index]["name"]}
                            />
                          </Col>
                          <Col sm={1} />
                          <Col sm={7}>
                            <Select
                              mode="multiple"
                              allowClear
                              style={{ width: "100%" }}
                              placeholder="Extensión de archivo"
                              onChange={(e) => setQuestion(e, "extensions", index)}
                              key={`extension-${index}-${question}`}
                              value={selectValues[index]}
                            >
                              {options.map((value, i) => (
                                <Option key={i} value={value}>
                                  {value}
                                </Option>
                              ))}
                            </Select>
                          </Col>
                        </Row>
                      )}
                    </Col>
                    <Col sm={1} style={{ textAlign: "center" }}>
                      <Icon
                        onClick={() =>
                          questions.length > 1 && deleteQuestion(index)
                        }
                        type="delete"
                        style={{ fontSize: 20, color: "#FF4D4F" }}
                      />
                    </Col>
                  </Row>
                ))}
                <Button
                  onClick={() =>
                    setQuestions((prevQuestions) => [
                      ...prevQuestions,
                      { question: undefined, extensions: [], description: "" },
                    ])
                  }
                  type="link"
                  style={{ padding: 0 }}
                >
                  <Icon
                    type="plus-circle"
                    style={{
                      fontSize: 30,
                      color: "#F175A5",
                      strokeWidth: 1,
                      paddingTop: "0.5em",
                    }}
                  />
                </Button>
              </>
            </Form.Item>
          </Card>
          <Form.Item>
            <Row
              type="flex"
              justify="start"
              style={{ width: "100%", paddingTop: "0.5em" }}
            >
              <Col style={{ marginRight: "20px" }}>
                <Button
                  onClick={() =>
                    Modal.confirm({
                      cancelText: i18n.t('commons__cancel'),
                      content: "Perderás todos los cambios no guardados.",
                      okText: i18n.t('commons__confirm'),
                      onOk: () => props.dispatch(push("/filestemplates")),
                      title: "¿Estás seguro de querer volver atrás?",
                    })
                  }
                >
                  {i18n.t('commons__cancel')}
                </Button>
              </Col>
              <Col>
                <Button htmlType="submit" type="primary">
                  {i18n.t('profile__save__changes')}
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Row>
    </Layout>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

const mapStateToProps = (state) => {
  return {
    business: state.auth.currentBusiness,
    businessPermissions: state.auth.businessPermissions,
    token: state.auth.token,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: "-files-form" })(FilesTemplateForm));
