import React, { useState, useEffect, useRef, createRef } from 'react';
import queryString from 'query-string';
import { isMobile } from 'react-device-detect';

import { SERVER_URL } from '../../../utils/config';
import './PeopleAnalytics.scss';

import SectionTitle from './components/SectionTitle';
import Profiles from './components/Profiles';
import Percentages from './components/Percentages';
import Traits from './components/Traits';
import VideoInterview from './components/VideoInterview';
import Questions from './components/Questions';
import Personality from './components/Personality';
import Heatmap from './components/Heatmap';
import HeatMapMobile from './components/HeatmapMobile';
import CompareCandidatePdf from './components/CompareCandidatePdf/index';

import { Layout, Row, Button, Typography, Modal, Col } from 'antd';
const { Title } = Typography;

const PeopleAnalyticsCandidatesV2 = (props) => {
  const [candidates, setCandidates] = useState([]);
  const [koQuestions, setKoQuestions] = useState({});
  const [jobApp, setJobApp] = useState("b36ac451-17ec-4f13-8ec0-62ab765f7cd5");
  const [showComparePDF, setShowComparePDF] = useState(false);

  const scrollRefs = useRef(new Array(5).fill('').map((x) => createRef()));

  const handleScroll = (e) => {
    for (let rf of scrollRefs.current) {
      rf.current.scrollLeft = e.target.scrollLeft;
    }
  };

  const addOrRemove = (refs, action) => {
    for (let rf of refs) {
      if (rf.current) {
        rf.current[action]('scroll', handleScroll);
      }
    }
  };

  useEffect(() => {
    if (scrollRefs && scrollRefs.current) {
      addOrRemove(scrollRefs.current, 'addEventListener');

      return () => {
        addOrRemove(scrollRefs.current, 'removeEventListener');
      };
    }
  }, []);

  useEffect(() => {
    const qs = queryString.parse(props.location.search);
    const userIdsQs = qs.userids;
    const jobAppIdQs = qs.jobappid;
    fetchCandidatesData(jobAppIdQs, userIdsQs);
    fetchJobAppData(jobAppIdQs);
  }, []);
  const fetchCandidatesData = async (jobAppIdQs, userIdsQs) => {
    const headers = {
      Authorization: `Token ${props.token}`,
    };
    const path = `/api/v1/stats/jobapplications/${jobAppIdQs}/candidates/?userids=${userIdsQs}`;
    fetch(`${SERVER_URL}${path}`, { headers })
      .then(async (response) => {
        const resp = await response.json();
        return { candidatesData: resp, status: response.status };
      })
      .then((resp) => {
        const { candidatesData } = resp;
        if (resp.status === 200) {
          setCandidates(candidatesData.candidates);
          setKoQuestions(candidatesData.ko_questions.questions)
        }
      });
  };

  const fetchJobAppData = async (jobAppIdQs) => {
    const headers = {
      Authorization: `Token ${props.token}`,
    };
    const path = `/api/v1/gamesandtests/jobapplication/${jobAppIdQs}/`;
    return await fetch(`${SERVER_URL}${path}`, { headers })
      .then(async (response) => {
        const resp = await response.json();
        return { jobAppData: resp, status: response.status };
      })
      .then((resp) => {
        const { jobAppData } = resp;
        if (resp.status === 200) {
          setJobApp(jobAppData);
        }
      });
  };

  const handleBoolean = (setter) => setter((bool) => !bool);

  return (
    <Layout>
      <Row className='job-title-container' type='flex' align='middle'>
        <Col xs={12} md={15} className='job-title-container__job-name'>
          <Title level={2}>{jobApp.job_application}</Title>
        </Col>
        <Col xs={6} md={5}>
          <Row type='flex' justify='end'>
            <Button
              icon='download'
              type='primary'
              onClick={() => handleBoolean(setShowComparePDF)}
            />
          </Row>
        </Col>
        <Modal
          className='job-title-container__compare-modal'
          closable={true}
          centered={true}
          width={650}
          footer={false}
          onCancel={() => handleBoolean(setShowComparePDF)}
          visible={showComparePDF}
        >
          <CompareCandidatePdf
            visible={showComparePDF}
            candidates={candidates}
            jobName={jobApp.job_application}
          />
        </Modal>
      </Row>
      <Profiles candidates={candidates} scrollRefs={scrollRefs.current[0]} />

      <SectionTitle text={'Puntaje global'} background={'white'} />
      <Percentages candidates={candidates} scrollRefs={scrollRefs.current[1]} />

      <SectionTitle text={'Rasgos generales'} background={'white'} />
      <Traits candidates={candidates} scrollRefs={scrollRefs.current[2]} />

      <SectionTitle text={'Video entrevistas'} background={'white'} />
      <VideoInterview
        candidates={candidates}
        scrollRefs={scrollRefs.current[3]}
      />

      <SectionTitle text={'Preguntas adicionales'} background={'white'} />
      <Questions candidates={candidates} questions={koQuestions} scrollRefs={scrollRefs.current[4]} />

      <SectionTitle text={'Personalidad'} background={'white'} />
      <Personality candidates={candidates} />

      <SectionTitle text={'Mapa de calor de rasgos'} background={'white'} />
      <SectionTitle text={'Rasgos Cognitivos'} background={'trasparent'} />
      {
        isMobile ?
          <HeatMapMobile candidates={candidates} type='cognitive' />
          :
          <Heatmap candidates={candidates} type='cognitive' />
      }

      <SectionTitle text={'Rasgos de Razonamiento'} background={'trasparent'} />
      {
        isMobile ?
          <HeatMapMobile candidates={candidates} type='reasoning' />
          :
          <Heatmap candidates={candidates} type='reasoning' />

      }
    </Layout>
  );
};

export default PeopleAnalyticsCandidatesV2;
