import React, { useContext } from 'react';
import { Row, Col, Typography, Icon, Tabs, Card, Table } from 'antd';

import { AnalyticsContext } from '../context/AnalyticsContext';

import TemplateCard from './components/TemplateCard';

import { genders, disabilities, getDataOrDefaultValue } from '../assets/utils';
import { getColors } from '../../../../components/charts/utils';
import LineWithTable from './components/LineWithTable';

const { Text } = Typography;

const Efficiency = () => {
  const {
    sectionsData: { satisfaction },
  } = useContext(AnalyticsContext);

  return (
    <div>
      <Row type="flex" gutter={[16, 16]}>
        <Col xs={24}>
          <Text>Visión general</Text>
        </Col>
        <Col xs={12}>
          <TemplateCard
            templateText
            data={getDataOrDefaultValue(
              satisfaction?.candidates_calcs?.avg,
              (n) => `${n.toFixed(1)}/10`
            )}
            height={250}
            title="Nota promedio del candidato"
            iconType="smile"
            text="Es la nota promedio con la que tus candidatos han evaluado los procesos seleccionados."
            noMargin
          />
        </Col>
        <Col xs={12}>
          <TemplateCard
            templateText
            data={getDataOrDefaultValue(satisfaction?.net_promoter_score, (n) =>
              n.toFixed(1)
            )}
            subdata="escala de -100 a 100"
            height={250}
            title="Net Promoter Score (NPS)"
            text={
              <span>
                Corresponde al{' '}
                <strong>
                  <u>NPS</u>
                </strong>{' '}
                obtenido a partir de tus candidatos para{' '}
                <strong>los procesos seleccionados.</strong>
              </span>
            }
            noMargin
          />
        </Col>
        <LineWithTable
          cardTitle="Distribución de notas - Satisfacción del candidato"
          responsiveConfig={{ xs: 24, md: 12 }}
          data={{
            'Tus candidatos': satisfaction?.candidates_calcs.data
              ? {
                  data: satisfaction?.candidates_calcs.data,
                }
              : {},
          }}
          lineKey="nps"
          colors={getColors(1)}
          tooltipLabels={['NPS', 'Cantidad']}
          columns={[{ title: 'Tus candidatos', key: 'candidates' }]}
          customTableData={{
            candidates: satisfaction?.candidates_calcs,
          }}
        />
        <LineWithTable
          cardTitle="Notas de satisfacción por FIT"
          responsiveConfig={{ xs: 24, md: 12 }}
          data={satisfaction ? satisfaction.fit_calcs : {}}
          lineKey="nps"
          colors={['#FFA39E', '#FFE58F', '#B7EB8F']}
          tooltipLabels={['NPS', 'Cantidad']}
        />
        <LineWithTable
          cardTitle="Género"
          responsiveConfig={{ xs: 24, xl: 12 }}
          data={satisfaction ? satisfaction.gender_calcs : {}}
          lineKey="nps"
          tooltipLabels={['NPS', 'Cantidad']}
          customTitles={genders}
        />
        <LineWithTable
          cardTitle="Nacionalidad"
          responsiveConfig={{ xs: 24, lg: 12 }}
          data={satisfaction ? satisfaction.nationality_calcs : {}}
          lineKey="nps"
          tooltipLabels={['NPS', 'Cantidad']}
        />
        <LineWithTable
          cardTitle="Discapacidad"
          responsiveConfig={{ xs: 24, lg: 12 }}
          data={satisfaction ? satisfaction.disability_calcs : {}}
          lineKey="nps"
          tooltipLabels={['NPS', 'Cantidad']}
          customTitles={disabilities}
        />
        <LineWithTable
          cardTitle="Grupo etario"
          responsiveConfig={{ xs: 24, xl: 12 }}
          data={satisfaction ? satisfaction.ages_calcs : {}}
          lineKey="nps"
          colors={['#BAE7FF', '#69C0FF', '#1890FF', '#096DD9', '#0050B3']}
          tooltipLabels={['NPS', 'Cantidad']}
        />
      </Row>
    </div>
  );
};

export default Efficiency;
