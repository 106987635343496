import React, { Component } from 'react';
import { connect } from 'react-redux';
import SingleGame from './SingleGame/SingleGame';
import CustomTest from './CustomTest/CustomTest';
import {
  Row,
  Typography,
  Button,
  Collapse
} from 'antd';

const { Title, Text } = Typography;
const { Panel } = Collapse;

class CandidatePath extends Component {

  state = {
    activePanels: []
  }

  onChangeCollapse = (activeKeys) => {
    this.setState({ activePanels: activeKeys });
  }

  checkForMobileActivities = activities => {
    let hasMobileActivities = false
    activities.forEach((actCategory) => {
      actCategory.activities.forEach((act) => {
        if (act.activity.includes("Mobile")) {
          hasMobileActivities = true
        }
      })
    })
    return hasMobileActivities
  }

  render() {
    const { activePanels } = this.state;
    const { reasoningActivities, personalityActivities, cognitiveActivities, otherActivities } = this.props;

    const otherActivitiesClean = otherActivities.filter(obj => obj.code !== 'KO')
    const activities = [
      {
        title: 'Evaluaciones de Razonamiento',
        description: 'Estas habilidades tienen que ver con tu capacidad general para aprender, resolver problemas, entender instrucciones y trabajar con información. Debido a que todos somos distintos, en general cada persona tiene una de estas tres áreas más desarrollada que las otras.',
        activities: [...reasoningActivities], classname: 'logic'
      },
      {
        title: 'Evaluaciones de Personalidad',
        description: 'La personalidad es el conjunto de características específicas que te diferencian de los demás en términos de tus pensamientos, sentimientos y comportamiento. Tu personalidad está definida por tu genética y por las experiencias que has tenido.',
        activities: [...personalityActivities], classname: 'perso'
      },
      {
        title: 'Evaluaciones Cognitivas y Emocionales',
        description: 'Los rasgos cognitivos y emocionales son características que describen la forma en que las personas procesan información para tener una comprensión del mundo que las rodea, así como la forma en que reconocen sus propias emociones y las de los demás para adaptarse a los entornos, respectivamente.',
        activities: [...cognitiveActivities], classname: 'cogni'
      },
      {
        title: 'Evaluaciones adicionales',
        description: 'Incluye evaluaciones que midan otro tipo de habilidades en tu proceso de selección.',
        activities: [...otherActivitiesClean], classname: 'other'
      }
    ]

    if (this.checkForMobileActivities(activities)) {
      activities.forEach(activityCategory => {
        activityCategory.activities = activityCategory.activities.filter((act) => act.activity.includes("Mobile") || (act.code === 'CUTE'))
      })
    }

    return (
      <div className='game-list'>
        <Collapse activeKey={activePanels} onChange={this.onChangeCollapse}>
          {activities.map((item, index) => (
            item.activities.length &&
              <Panel
                key={index + 1}
                id={String(index + 1)}
                header={
                  <div>
                    <Title level={4}>{item.title}</Title>
                    <div className='description'>
                      <Text>{item.description}</Text>
                    </div>
                  </div>
                }
              >
                <div className='panel-content'>
                  <Row gutter={20} type='flex' className='game-row'>
                    {item.activities.map((activity, i) => {
                      if (activity.code === 'CUTE') {
                        return (
                          <CustomTest
                            index={i}
                            activity={activity}
                            onChangeCustomTest={this.props.onChangeCustomTest}
                            currentCustomTests={this.props.currentCustomTests}
                          />
                        )
                      }
                      else {
                        return (
                          <SingleGame
                            key={i}
                            item={item}
                            index={i}
                            activity={activity}
                            openModal={this.props.openModal}
                          />
                        )
                      }
                    })
                    }
                  </Row>
                </div>

                <Button
                  className='close-panel'
                  icon='up'
                  block
                  onClick={() => this.onChangeCollapse(activePanels.filter((e) => Number(e) !== index + 1))}
                />
              </Panel>
          ))}
        </Collapse>
      </div>
    )
  }
}

const getCognitiveActivities = (activities) => {
  return activities.filter((act) => { return act.category === 'COGNITIVE' || 
    act.category === 'EMOTIONAL' || 
    act.category === 'BEHAVIORAL'; 
  });
};

const mapStateToProps = (state) => {
  return {
    reasoningActivities: state.jobapp.activities.results.filter((act) => { return act.category === 'REASONING'; }),
    cognitiveActivities: getCognitiveActivities(state.jobapp.activities.results),
    personalityActivities: state.jobapp.activities.results.filter((act) => { return act.category === 'PERSONALITY'; }),
    otherActivities: state.jobapp.activities.results.filter((act) => { return act.category === 'OTHER'; }),
  };
};

export default connect(mapStateToProps)(CandidatePath);