import React from "react";
import { Row, Col } from "antd";

import Activity from "./Activity";


const ActivityRow = (props) => {
  return (
    <Row type="flex" justify="space-around" style={{'width': '100%'}} >
      {props.row.map((activity, index) => {
        return (
          <Col
            key={index}
            sm={8}
            style={{ marginBottom: "10px", marginTop: "10px"}}
            
          >
            <Activity
              businessSlug={props.businessSlug}
              colorTheme={props.colorTheme}
              prvw={props.prvw}
              activityItems={props.items}
              blocked={props.blocked}
              {...activity}
            />
          </Col>
        );
      })}
    </Row>
  );
};

export default ActivityRow;
