import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as actionCreators from '../../actions/data';

import {
  Alert,
  Button,
  Checkbox,
  Col,
  Input,
  InputNumber,
  Layout,
  message,
  Modal,
  Radio,
  Row,
  Spin,
  Typography
} from 'antd';
import './references.scss';


const { Title, Text, Paragraph } = Typography;


function ReferencesForm(props) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [answers, setAnswers] = useState({});
  const [answersSent, setAnswersSent] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');

  useEffect(() => {
    const { referenceId } = props.match.params;
    if (referenceId) {
      const url = `/api/v1/gamesandtests/references/${referenceId}/`;
      props.actions.fetchAndReturn(props.token, url)
        .then(({ body }) => {
          setTitle(body.template.name);
          setDescription(body.template.description);
          if (body.answer.length) {
            setAnswersSent(true);
            setAnswers(body.answer);
            setAlertMsg('Cuestionario ya fue respondido.');
          } else {
            setAnswers((prevAnswers) => {
              const { questions } = body.template;
              questions.map((question) => {
                prevAnswers[`${question.id}`] = {
                  type: question.type,
                  question: question.text,
                  configuration: question.configuration,
                  mandatory: question.mandatory,
                  position: question.position,
                  answer: question.type === 'multiselection' ? [] : '',
                }
              });
              return {...prevAnswers};
            });
          }
        });
    }
  }, [props.match.params.referenceId])

  const handleAnswerChange = (questionId, answer) => {
    setAnswers((prevAnswers) => {
      prevAnswers[questionId].answer = answer;
      return { ...prevAnswers };
    })
  };

  const handleSendAnswers = () => {
    // Check all questions have been answered
    const notAnswered = Object.values(answers).filter(
      ({ answer }) => answer.length === 0
    );
    if (notAnswered.length) {
      Modal.confirm({
        cancelText: 'Volver al formulario',
        okText: 'Enviar respuestas',
        onOk: submitAnswers,
        content: (
          <Paragraph>
            No haz respondido todas las preguntas, ¿estás seguro de querer
            enviar tus respuestas?
          </Paragraph>
        ),
      })
    } else {
      submitAnswers();
    }
  };

  const submitAnswers = () => {
    message.loading('Enviando respuestas...');
    const url = `/api/v1/gamesandtests/references/${props.match.params.referenceId}/`;
    props.actions.fetchAndReturn('', url, 'PATCH', { answer: answers })
      .then(response => {
        message.destroy();
        if (response.status >= 400) {
          message.error('No fue posible registrar las respuestas.', 5);
        } else {
          // message.success('¡Respuestas registradas correctamente!', 5);
          setAnswersSent(true);
          setAlertMsg('¡Respuestas registradas correctamente!');
        }
      })
      .catch(response => {
        message.destroy();
        // TODO: TEST
          message.error('No fue posible registrar las respuestas.', 5);
      });
  };

  return (
    <Layout className='references-form'>
    {!(title || Object.keys(answers).length) ? (
      <div className='references-form__is-loading'>
        <Spin />
      </div>
    ) : (
      <>
        {answersSent && (
          <Alert
            message={alertMsg}
            type='success'
            style={{ marginBottom: '20px'}}
          />
        )}
        <Row>
          <Title>{title}</Title>
          <Paragraph>{description}</Paragraph>
        </Row>
        <Row>
          {Object.keys(answers).map((questionId) => (
            <Row
              key={`${questionId}`}
              style={{ marginBottom: '20px' }}
            >
              <Row type='flex' align='middle'>
                <Col style={{ marginRight: '5px' }}>
                  <Text strong>{answers[questionId].position+1}.</Text>
                </Col>
                <Col>
                  <Text strong>{answers[questionId].question}</Text>
                </Col>
              </Row>
              <Row
                style={{ marginTop: '5px' }}
              >
                {answers[questionId].type === 'singleline' && (
                  <Input
                    disabled={answersSent}
                    value={answers[questionId].answer}
                    onChange={(e) => handleAnswerChange(questionId, e.target.value)}
                  />
                )}
                {answers[questionId].type === 'multiline' && (
                  <Input.TextArea
                    disabled={answersSent}
                    value={answers[questionId].answer}
                    onChange={(e) => handleAnswerChange(questionId, e.target.value)}
                  />
                )}
                {answers[questionId].type === 'integer' && (
                  <InputNumber
                    disabled={answersSent}
                    parser={(value) => `${value}`.replace(/[^\d]/g, '')}
                    value={answers[questionId].answer}
                    onChange={(value) => handleAnswerChange(questionId, value)}
                  />
                )}
                {answers[questionId].type.includes('selection') && (
                  answers[questionId].type.includes('single') ? (
                    <Radio.Group
                      value={answers[questionId].configuration.alternatives[answers[questionId].answer]}
                    >
                      {answers[questionId].configuration.alternatives.map((alternativeText, alternativeIndex) => (
                        <Radio
                          disabled={answersSent}
                          key={`${questionId}-${alternativeText}`}
                          value={alternativeText}
                          onChange={() => handleAnswerChange(questionId, alternativeIndex)}
                        >
                          {alternativeText}
                        </Radio>
                      ))}
                    </Radio.Group>
                  ) : (
                    <Checkbox.Group
                      value={answers[questionId].answer.map(
                        (alternativeIndex) => answers[questionId].configuration.alternatives[alternativeIndex]
                      )}
                    >
                      {answers[questionId].configuration.alternatives.map(
                        (alternativeText, alternativeIndex) => (
                          <Checkbox
                            checked={answers[questionId].answer.includes(alternativeIndex)}
                            disabled={answersSent}
                            key={`${questionId}-${alternativeText}`}
                            onChange={() => {
                              if (answers[questionId].answer.includes(alternativeIndex)) {
                                handleAnswerChange(
                                  questionId,
                                  answers[questionId].answer.filter((answer) => answer !== alternativeIndex)
                                )
                              } else {
                                handleAnswerChange(
                                  questionId,
                                  [...answers[questionId].answer, alternativeIndex].sort()
                                )
                              }
                            }}
                            value={alternativeText}
                          >
                            {alternativeText}
                          </Checkbox>
                        )
                      )}
                    </Checkbox.Group>
                  )
                )}
              </Row>
            </Row>
          ))}
        </Row>
        <Row type='flex' justify='end'>
          <Col>
            <Button
              disabled={answersSent}
              className={!answersSent && 'send-questionnaire'}
              onClick={handleSendAnswers}
            >
              Enviar respuestas
            </Button>
          </Col>
        </Row>
      </>
    )}
    </Layout>
  )
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferencesForm);
