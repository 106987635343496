import translationEN from './en/translation.json';
import translationPT from './pt/translation.json';
import translationES from './es/translation.json';
import candidateProfileEN from './en/candidate_profile.json';
import candidateProfilePT from './pt/candidate_profile.json';
import candidateProfileES from './es/candidate_profile.json';
import candidatePDFEN from './en/candidate_pdf.json';
import candidatePDFPT from './pt/candidate_pdf.json';
import candidatePDFES from './es/candidate_pdf.json';

import businessUserEN from './en/business_user_management.json';
import businessUserPT from './pt/business_user_management.json';
import businessUserES from './es/business_user_management.json';

import notificationsEN from './en/notifications.json';
import notificationsPT from './pt/notifications.json';
import notificationsES from './es/notifications.json';

// the translations
export default {
    en: { 
      translation: translationEN,
      candidateProfile: candidateProfileEN,
      candidatePdf: candidatePDFEN,
      businessUser: businessUserEN,
      notifications: notificationsEN
    },
    pt: {
      translation: translationPT,
      candidateProfile: candidateProfilePT,
      candidatePdf: candidatePDFPT,
      businessUser: businessUserPT,
      notifications: notificationsPT
    },
    es: {
      translation: translationES,
      candidateProfile: candidateProfileES,
      candidatePdf: candidatePDFES,
      businessUser: businessUserES,
      notifications: notificationsES
    }
  };