import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import PropTypes from 'prop-types';

import { geTotalPercentage } from './utils';

import { Row, Col } from 'antd';

/**
 *
 * @param {Array<String>} colors
 * @param {Array<Object>} dataSource {"id": <>, "label": <>, "value": <>}
 * @param {Number} height
 * @returns
 */
const PieChart = ({ colors, dataSource, height, customMargin, customDirection, translateX, translateY }) => {
  return (
    <Row>
      <Col sm={24}>
        <div style={{ height: height, minWidth: '100%' }}>
          <ResponsivePie
            data={dataSource}
            max
            margin={
              customMargin ?? { top: 30, right: 30, bottom: 30, left: 30 }
            }
            colors={colors}
            innerRadius={0}
            padAngle={0.7}
            cornerRadius={1}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
            arcLinkLabel={'value'}
            arcLinkLabelsDiagonalLength={12}
            arcLinkLabelsStraightLength={12}
            arcLabel={obj => (
              <tspan style={{ fontSize: '14px' }}>
                {`${Math.round(
                  geTotalPercentage(
                    dataSource.map(gender => gender.value),
                    obj.value
                  )
                )}%`}
              </tspan>
            )}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor="white"
            legends={[
              {
                direction: customDirection ?? 'column',
                justify: false,
                translateX: translateX ?? -30,
                translateY: translateY ?? -40,
                itemWidth: 80,
                itemHeight: 20,
                itemsSpacing: 0,
                symbolSize: 20,
                itemDirection: 'left-to-right',
              },
            ]}
            tooltip={({ datum }) => {
              return (
                <div
                  style={{
                    padding: 6,
                    background: '#FFFFFF',
                    boxShadow: '0px 1px 2px gray',
                    borderRadius: 2,
                  }}
                >
                  <div style={{ fontSize: 14 }}>
                    {`${datum.data.label}: ${datum.data.value}`}
                  </div>
                </div>
              );
            }}
          />
        </div>
      </Col>
    </Row>
  );
};

PieChart.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
  dataSource: PropTypes.arrayOf(PropTypes.object),
  height: PropTypes.number,
};

export default PieChart;
