import fetch from 'isomorphic-fetch';
import { alertActions } from './alerts';
import { SERVER_URL } from '../utils/config';
import { checkHttpStatus, parseJSON } from '../utils';
import { TRAIT_FETCH_BEGIN,
    TRAIT_RECEIVE_FAILURE,
    TRAIT_RECEIVE_SUCCESS,
    TRAIT_UPDATE,
    TRAITS_RELEVANCE,
    MEASURED_TRAIT_FETCH_BEGIN,
    MEASURED_TRAIT_RECEIVE_SUCCESS,
    MEASURED_TRAIT_RECEIVE_FAILURE,
    MEASURED_TRAIT_REMOVE,
    MEASURED_TRAIT_ORIGINAL_REMOVE,
    SAVE_TRAIT_SUCCESS,
    RESET_MT, } from '../constants';
import { authLoginUserFailure } from './auth';
import * as jobappActions from './jobapp';

export const fetchTraitBegin = data => ({
    type: TRAIT_FETCH_BEGIN,
    payload: { data }
});

export const fetchTraitSuccess = data => {
    const traits = {}
    data.results.forEach(t => traits[t.id] = {...t})
    return {
        type: TRAIT_RECEIVE_SUCCESS,
        payload: { traits: traits }
    }
    
};
  
export const fetchTraitFailure = error => ({
    type: TRAIT_RECEIVE_FAILURE,
    payload: { error }
});

export const resetMT = () => ({
    type: RESET_MT,
});

export function resetMeasuredTraits() {
    return (dispatch) => {
        dispatch(resetMT());
    };
}

export function fetchTrait(token, rawdata, method = 'get') {
    return (dispatch) => {
        dispatch(fetchTraitBegin(rawdata));
        return fetch(`${SERVER_URL}/api/v1/genomes/traits/`, {
            method: `${method}`,
            // credentials: 'include',
            headers: {
                "Raw-Data": `${rawdata}`,
                Accept: 'application/json',
                Authorization: `Token ${token}`,
                "Content-Type": `application/json`,
            }
        })
            .then(handleErrors)
            .then((res) => { return res.json(); })
            .then((json) => {
                dispatch(fetchTraitSuccess(json));
            })
            .catch((error) => {
                dispatch(fetchTraitFailure(error));
                return Promise.resolve();
            });
    };
}

export const fetchMeasuredTraitBegin = genome_id => ({
    type: MEASURED_TRAIT_FETCH_BEGIN,
    payload: { genome_id }
})

export const fetchMeasuredTrait = (token, genome_id) => {
    return (dispatch) => {
        const rawData = JSON.stringify({ genome_id: genome_id })
        dispatch(fetchMeasuredTraitBegin(genome_id));
        //const outdata = ${rawdata};
        return fetch(`${SERVER_URL}/api/v1/genomes/measuredtraits/`, {
            method: 'get',
            // credentials: 'include',
            headers: {
                "Raw-Data": `${rawData}`,
                Accept: 'application/json',
                Authorization: `Token ${token}`,
                "Content-Type": `application/json`,
            }
        })
            .then(handleErrors)
            .then((res) => { return res.json(); })
            .then((json) => {
                const measuredTraits = {}
                json.results.forEach(t => measuredTraits[t.trait_id] = {...t, original: true})
                dispatch(fetchMeasuredTraitSuccess(measuredTraits))
            })
            .catch(error => {
                dispatch(fetchMeasuredTraitFailure(error));
                dispatch(alertActions.error("Hubo un error solicitando los datos de los MeasuredTraits"));
                return Promise.resolve();
            })
    };
}

export const fetchMeasuredTraitFailure = (error) => ({
    type: MEASURED_TRAIT_RECEIVE_FAILURE,
    payload: { error }
})

export const fetchMeasuredTraitSuccess = (measuredTraits, isNew) => ({
    type: MEASURED_TRAIT_RECEIVE_SUCCESS,
    payload: { measuredTraits: measuredTraits, isNew }
})

export const removeMeasuredTrait = (ids) => ({
    type: MEASURED_TRAIT_REMOVE,
    payload: { ids: ids }
})

export const removeOriginalMeasuredTrait = (ids) => ({
    type: MEASURED_TRAIT_ORIGINAL_REMOVE,
    payload: { ids: ids }
})

export function updateMeasured(measured, difference = 0) {
    return {
        type: TRAIT_UPDATE,
        payload: { measuredTrait: measured,
            differenceTotalPoints: difference }
    }
}

export const savePositionSuccess = () => ({
    type: SAVE_TRAIT_SUCCESS,
})

export function traitsRelevance(){
    return async dispatch => {
        dispatch(jobappActions.startSaving())
        await dispatch(traitsRelevanceAction())
    }
}

export const traitsRelevanceAction = () => ({
    type: TRAITS_RELEVANCE
})

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}
