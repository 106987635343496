import i18n from '../../i18n'

export const months = {
  0: 'form__january',
  1: 'form__february',
  2: 'form__march',
  3: 'form__april',
  4: 'form__may',
  5: 'form__june',
  6: 'form__july',
  7: 'form__august',
  8: 'form__september',
  9: 'form__october',
  10: 'form__november',
  11: 'form__december',
};

export const wrapText = (text, x, y, anchor) => {
  if (text.length > 50) {
    const strArray = text.split(' ');
    const half = Math.ceil(strArray.length / 2);
    const firstHalf = strArray.slice(0, half);
    const secondHalf = strArray.slice(half, strArray.length);
    return (
      <>
        <text
          y={y.toString()}
          x={x.toString()}
          fill="#5E5E5E"
          fontSize={16}
          textAnchor={anchor ?? ''}
        >
          {firstHalf.join(' ')}
        </text>
        <text
          y={(y + 20).toString()}
          x={x.toString()}
          fill="#5E5E5E"
          fontSize={16}
          textAnchor={anchor ?? ''}
        >
          {secondHalf.join(' ')}
        </text>
      </>
    );
  } else {
    return (
      <text
        y={y.toString()}
        x={x.toString()}
        fill="#5E5E5E"
        fontSize={16}
        textAnchor={anchor ?? ''}
      >
        {text}
      </text>
    );
  }
};

export const recommendedVEvaluation = [
  {
    value: 'NOREC',
    text: i18n.t('profile__not_recommended', {ns: 'candidateProfile'}),
    icon: 'close-circle',
    color: '#FF4D4F',
    key: 'NOREC-false',
  },
  {
    value: 'OBJEC',
    text: i18n.t('profile__with_observations', {ns: 'candidateProfile'}),
    icon: 'info-circle',
    color: '#FAAD14',
    key: 'OBSER-false',
  },
  {
    value: 'RECOM',
    text: i18n.t('profile__recommended', {ns: 'candidateProfile'}),
    icon: 'check-circle',
    color: '#52C41A',
    key: 'RECOM-false',
  },
];

export const recommended = {
  RECOM: {
    value: 'RECOM',
    text: i18n.t('profile__recommended', {ns: 'candidateProfile'}),
    icon: 'check-circle',
    color: '#52C41A',
    key: 'RECOM-false',
  },
  OBJEC: {
    value: 'OBJEC',
    text: i18n.t('profile__with_observations', {ns: 'candidateProfile'}),
    icon: 'info-circle',
    color: '#FAAD14',
    key: 'OBSER-false',
  },
  NOREC: {
    value: 'NOREC',
    text: i18n.t('profile__not_recommended', {ns: 'candidateProfile'}),
    icon: 'close-circle',
    color: '#FF4D4F',
    key: 'NOREC-false',
  },
};

export const rateValues = [
  {
    background: '#FFFFFF',
    color: '#D9D9D9',
    value: 0,
    evaluated: false,
    key: '0-false',
    text: '',
  },
  {
    background: '#ff8f90',
    color: '#FF4D4F',
    value: 1,
    evaluated: true,
    key: '1-false',
    text:  i18n.t('profile__insufficient', {ns: 'candidateProfile'}),
    marginLeft: 110,
  },
  {
    background: '#fabfac',
    color: '#fc6d42',
    value: 2,
    evaluated: true,
    ' key': '2-false',
    text: i18n.t('profile__low', {ns: 'candidateProfile'}),
    marginLeft: 200,
  },
  {
    background: '#FFF1B8',
    color: '#FAAD14',
    value: 3,
    evaluated: true,
    key: '3-false',
    text: i18n.t('commons__average', {ns: 'candidateProfile'}),
    marginLeft: 252,
  },
  {
    background: '#F4FFB8',
    color: '#BAE637',
    value: 4,
    evaluated: true,
    key: '4-false',
    text: i18n.t('profile__high', {ns: 'candidateProfile'}),
    marginLeft: 335,
  },
  {
    background: '#D9F7BE',
    color: '#52C41A',
    value: 5,
    evaluated: true,
    key: '5-false',
    text:  i18n.t('profile__outstanding', {ns: 'candidateProfile'}),
    marginLeft: 375,
  },
];

export const iKitColors = {
  1: {
    background: '#FFD8BF',
    color: '#FF7A45',
    border: '1px solid #FF7A45',
  },
  2: {
    background: '#FFD8BF',
    color: '#FF7A45',
    border: '1px solid #FF7A45',
  },
  3: {
    background: '#F4FFB8',
    color: '#A0D911',
    border: '1px solid #A0D911',
  },
  4: {
    background: '#F4FFB8',
    color: '#A0D911',
    border: '1px solid #A0D911',
  },
  5: {
    background: '#D9F7BE',
    color: '#52C41A',
    border: '1px solid #52C41A',
  },
};

export const isValidEmail = (mail) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(mail.toLowerCase());
};
