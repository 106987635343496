import React from 'react';

import {
  Button,
  Card,
  Col,
  Divider,
  Icon,
  Modal,
  Row,
  Typography,
} from 'antd';

import i18n from '../../../../i18n';

const { Text } = Typography;

const getFileObj = async (file) => {
  // NOTE: process.env.REACT_APP_FILE_RECORD_URL es undefined
  // const url = process.env.REACT_APP_FILE_RECORD_URL + file.file
  const url = file.file;
  let aux = document.createElement("a");
  document.body.appendChild(aux);
  aux.style = "display: none";
  aux.href = url;
  aux.target = '_blank';
  aux.click();
  window.URL.revokeObjectURL(url);
};

const DocsModal = ({
  docTitle,
  stage,
  filesRequests,
  totalDocs,
  candidate,
  visible,
  onCancel,
}) => {
  return (
    <Modal
      visible={visible}
      footer={[
        <Button type="primary" onClick={onCancel}>
          {i18n.t('commons__close')}
        </Button>,
      ]}
      onCancel={onCancel}
      title={<Text strong>Visualización de solicitudes</Text>}
      closable={true}
    >
      <div
        className="docs-modal-body"
        style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
      >
        <Text>
          Solicitudes de documentos enviadas a{' '}
          <strong>{candidate?.full_name}</strong>
        </Text>
        {filesRequests?.map((req , idx) => (
          <Card type="inner" title={req.name}>
            <div className="docs-card">
              <div className="docs-card__header">
                <div className="docs-card__header__texts">
                  <Text strong>{i18n.t('form__step')}</Text>
                  <Text>{req.stage}</Text>
                </div>
                <Divider type="vertical" style={{ height: 24 }} />
                <div className="docs-card__header__texts">
                  <Text strong>Documentos solicitados</Text>
                  <Text>
                    {req.questions.map((question) => (<Row><Text>• {question.name}</Text></Row>))}
                  </Text>
                </div>
              </div>
              <Divider />
              <div className="docs-card__body">
                <Text strong>Documentos enviados</Text>
                {req.questions.map((question) => (
                  <Col>
                    <Row>
                      <Text>• {question.name}</Text>
                    </Row>
                    {question.answer.files.map((obj) => (
                      <Col sm={6} style={{ padding: '0.25em' }}> 
                        <a onClick={() => getFileObj(obj)}>
                          <Text style={{ fontSize: 12, color:'#1890FF' }}>
                            <Icon type='paper-clip'/> {obj.name}
                          </Text>
                        </a>
                      </Col>
                    ))}
                  </Col>
                ))}
              </div>
            </div>
          </Card>
        ))}
      </div>
    </Modal>
  );
};

export default DocsModal;
