import React from 'react';

import {
  InputNumber,
  Form,
  Typography,
} from 'antd';
import '../KnockoutForm.scss'

const Integer = props => {
  const { question, error, index, answer, disabled, from, to, mandatory } = props
  let color = '#757892'
  if (disabled && (parseInt(from) !== parseInt(to)) && (parseInt(answer) < parseInt(from) || parseInt(answer) > parseInt(to))) {
    color = 'red'
  }
  return (
    <React.Fragment>
      <Form.Item
        name={"integerField"}
        label={
          <Typography.Text style={{ whiteSpace: 'normal' }}>
            {mandatory && <Typography.Text style={{ color: 'red' }}>* </Typography.Text>}
            {question}
          </Typography.Text>
        }
        labelAlign="left"
      >
        <InputNumber
          placeholder='0'
          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
          parser={value => value.replace(/\D/g,'').replace(/\$\s?|(\.*)/g, '')}
          onChange={value => props.handleChangeV2(index, value)}
        />
        {error &&
          <Typography style={{ color: 'red', marginTop: 3, fontSize: 12 }}>
            Tienes que responder esta pregunta para continuar.
          </Typography>
        }
      </Form.Item>

    </React.Fragment>
  )
}

export default Integer;