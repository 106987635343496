import React, { useState, useEffect } from 'react';
import { Row, Typography, Breadcrumb } from 'antd';
import { connect } from 'react-redux';
import './index.scss';
import ToolCard from './components/toolCard';
import { Link } from 'react-router-dom';
import useDebouncedSearch from '../../hooks/useDebouncedSearch';
import i18n from '../../i18n';

const { Title, Paragraph } = Typography;

function ToolsDashboardView(props) {
  const [addOns, setAddOns] = useState(props.addOns.map((elem) => elem.add_on));

  useEffect(() => {
    const AddOnsArray = props.addOns.map((elem) => elem.add_on);
    setAddOns(AddOnsArray);
  }, [props.addOns]);

  const tools = [
    /*
    {
      icon: 'form',
      href: '/applicationform/',
      title: 'Formulario de Postulación',
      description: 'Gestiona la información que solicitarás a tus candidatos para tus diferentes procesos.'
    },
    */
    // {
    //   icon: 'edit',
    //   href: '/account/interviewkit',
    //   title: 'Entrevistas Personalizadas',
    //   description: 'Evalúa mediante preguntas personalizadas, para analizar y conocer mejor a tus candidatos.',
    //   addOn: addOns.findIndex((x) => x === 'IKIT')
    // },
    {
      icon: 'file-done',
      href: '/references',
      title: 'Referencias laborales',
      description:
        'Solicita referencias a tus candidatos, para conocerlos mejor y tomar decisiones informadas sobre sus experiencias.',
      hasPerm: props.businessPermissions.role?.can_manage_references
    },
    {
      icon: 'mail',
      href: '/emailtemplates',
      title: 'Plantillas de correos',
      description:
        'Crea, guarda y edita plantillas de correos electrónicos para tus procesos de contratación.',
        hasPerm: props.businessPermissions.role?.can_manage_mail_templates
    },
    {
      icon: 'api',
      href: '/integrations',
      title: 'Integraciones',
      description:
        'Gestiona integraciones con plataformas externas y agiliza tus procesos de selección en Genomawork.',
      hasPerm: props.businessPermissions.role?.can_manage_integrations
    },
    {
      'icon': 'paper-clip',
      'href': '/filestemplates',
      'title': 'Solicitud de documentos',
      'description': 'Solicita y gestiona los documentos que necesites de los candidatos.',
      hasPerm: props.businessPermissions.role?.can_manage_additional_evaluations
    },
    {
      icon: 'appstore',
      href: '/jobportals',
      title: 'Portales de empleo (Beta)',
      description: 'Gestiona los portales de empleo donde se publicarán tus vacantes.',
      addOn: addOns.findIndex((x) => x === 'JOBP'),
      hasPerm: props.businessPermissions.role?.can_manage_brand
    },
    {
      icon: 'form',
      href: '/customfields',
      title: 'Atributos personalizados',
      description: 'Agrega campos con información adicional para personalizar la gestión de tus procesos, candidatos, y sus postulaciones.',
      hasPerm: props.businessPermissions.role?.can_manage_custom_fields
    },
    {
      icon: 'stop',
      href: '/discardreasons',
      title: i18n.t('management__discard_reason_title', {ns: 'businessUser'}),
      description: i18n.t('management__discard_reason_description', {ns: 'businessUser'}),
      hasPerm: props.businessPermissions.role?.can_manage_additional_evaluations
    }
  ];

  const [filteredTools, setFilteredTools] = useState(tools);

  const DebouncedInput = useDebouncedSearch(
    (value) => {
      setFilteredTools(
        tools.filter((tool) =>
          tool.title.toLowerCase().includes(value.toLowerCase())
        )
      );
    },
    500,
    'Buscar herramientas'
  );

  return (
    <div className="tools-dashboard">
      <Breadcrumb separator={'>'}>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Gestión</Breadcrumb.Item>
      </Breadcrumb>
      <Title>Gestión</Title>
      <Paragraph>
        ¡Bienvenido/a a tus herramientas para gestionar y evaluar tus procesos
        de manera óptima! Desde esta sección podrás crear plantillas e ingresar
        a tus herramientas para aprovechar todos los beneficios que Genomawork
        te ofrece.
      </Paragraph>
      {DebouncedInput}
      {filteredTools.length ? (
        <Row type="flex" style={{ gap: '1.5rem' }}>
          {filteredTools.filter(obj=>obj.hasPerm===true).map((item, key) => {
            if (item.addOn === -1) {
              return;
            }
            return <ToolCard {...item} dispatch={props.dispatch} key={key} />;
          })}
        </Row>
      ) : (
        <Paragraph>No se encontraron resultados.</Paragraph>
      )}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    addOns: state.auth.addOns,
    businessPermissions: state.auth.businessPermissions,
  };
};

export default connect(mapStateToProps)(ToolsDashboardView);
export { ToolsDashboardView as ToolsDashboardViewNotConnected };
