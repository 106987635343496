import React, { useContext, useEffect, useState } from 'react';

import { CandidateContext } from '../config/CandidateContext';

import { Col, Icon, Row, Typography } from 'antd';
import VideoInterviews from '../components/SummaryVideoInterviews';
import Questions from '../components/Questions';
import SummaryEvaluations from '../components/SummaryEvaluations';
import SummaryCuTes from '../components/SummaryCustomTests';

import useBoolean from '../../../hooks/useBoolean';
import i18n from '../../../i18n';

const { Text } = Typography;

const selectedStyle = {
  background: '#597EF7',
  color: '#FFFFFF',
};

const Summary = () => {
  const {
    application: { activities, candidate_type, date_started },
    translationFile,
  } = useContext(CandidateContext);

  const otherEvaluations = i18n.t('commons__other_evaluations', translationFile)
  const traits = i18n.t('commons__traits', translationFile)

  const [hasVideos, handleVideosFlag] = useBoolean(false);
  const [selectedTab, setSelectedTab] = useState(traits);

  useEffect(() => {
    if (candidate_type !== 'passive') {
      const gamesCategories = ['COGNITIVE', 'EMOTIONAL', 'REASONING', 'PERSONALITY', 'BEHAVIORAL'];
      const hasCutes = activities.find(obj => obj.code === 'CUTE');

      if (activities.find(obj => gamesCategories.includes(obj.category)) && !hasCutes) {
        setSelectedTab(traits);
      }
      else {
        setSelectedTab(otherEvaluations);
      };
    }
  }, []);

  const evalTabs = candidate_type !== 'passive'
    ? {
      [traits]: <SummaryEvaluations />,
      [otherEvaluations]: <SummaryCuTes />,
    }
    : {};

  return (
    <Row type="flex" gutter={[16, 32]}>
      <Col xs={24}>
        <Icon type="clock-circle" style={{ color: '#A4A4A4' }} />
        <Text style={{ color: '#A4A4A4', marginLeft: '0.25rem' }}>
          {i18n.t('profile__postulation_date', { ns:'candidateProfile' }).split('[')[0]} {date_started}
        </Text>
      </Col>
      {(candidate_type !== 'passive') ? (
        <>
          <Col xs={hasVideos ? 12 : 0}>
            <VideoInterviews handleVideosFlag={handleVideosFlag.on} />
          </Col>
          {activities?.find(obj => obj.code === 'KO') && (
            <Col xs={hasVideos ? 12 : 24}>
              <Questions />
            </Col>
          )}
          <Col
            xs={24}
            style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
          >
            <div className="custom-tabs-modal">
              {Object.keys(evalTabs).map((tabName) => (
                <div
                  key={tabName}
                  style={tabName === selectedTab ? selectedStyle : {}}
                  onClick={() => setSelectedTab(tabName)}
                >
                  {tabName}
                </div>
              ))}
            </div>
            {evalTabs[selectedTab]}
          </Col>
        </>
      ) : null}
    </Row>
  );
};

export default Summary;
