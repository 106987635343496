import React, { useState, useContext, useMemo, useRef } from 'react';
import { CandidateContext } from '../config/CandidateContext';

import { evaluations, evaluationDictionary } from '../../../utils/helpers';

import {
  Col,
  Row,
  Typography,
  Button,
  Input,
  Select,
  Tooltip,
  message,
  Divider,
} from 'antd';

import Collapse from './Collapse';

import { iKitColors } from '../helpers';
import i18n from '../../../i18n';

const { Title, Text } = Typography;

const fillAndMap = (q) => {
  const arr = new Array(q).fill('');

  return (fn) => arr.map(fn);
};

// Buttons

const EditButtons = ({ onCancel, onSave }) => {
  return (
    <div>
      <Button onClick={onCancel} type="secondary">
        {i18n.t('profile__cancel', {ns: 'candidateProfile'})}
      </Button>
      <Button onClick={onSave} type="primary" style={{ color: '#FFFFFF' }}>
        {i18n.t('profile__save', {ns: 'candidateProfile'})}
      </Button>
    </div>
  );
};

//////////////
const SingleKit = ({ propGroup }) => {
  const { saveKits, sharing } = useContext(CandidateContext);
  // local state to make a lot easier editing mode
  const [group, setGroup] = useState(() => ({
    ...propGroup,
    detail: propGroup.detail.map((q) => ({
      ...q,
      evaluation: 0,
      feedback_text: '',
    })),
  }));
  const [isEditing, setIsEditing] = useState(false);

  // we use this logic to restore the state when cancel edit behaviour
  const cache = useRef(null);

  const saveLocalKit = () => {
    const savedKit = saveKits(
      group.interview_kit,
      group.answer,
      group.detail,
      () => setIsEditing(false)
    );
    if (savedKit && group.answer.length == 0) {
      setGroup((group) => ({
        ...group,
        answer: group.detail,
      }));
    }
  };

  const switchEdit = () => {
    if (isEditing) {
      cancelEdit();
    } else {
      cache.current = () => {
        setGroup(group);
      };

      setIsEditing(true);
    }
  };

  const cancelEdit = () => {
    setIsEditing(false);
    cache.current();
    return;
  };

  const handleChange = (question, parKV) => {
    if (group.answer.length) {
      setGroup((group) => ({
        ...group,
        answer: group.answer.map((ans) => {
          if (ans.question_number === question.question_number) {
            ans = { ...ans, ...parKV };
          }

          return ans;
        }),
      }));
    } else {
      setGroup((group) => ({
        ...group,
        detail: group.detail.map((q) => {
          if (q.question_number === question.question_number) {
            q = { ...q, ...parKV };
          }

          return q;
        }),
      }));
    }
  };

  const prepareQuestion = (q, number) => {
    return (
      <div key={q.id} className="candidate-modal-interviews__questions__item">
        <Text strong>
          {number}. {q.text}
        </Text>
        <Text className="skill">{q.skill}</Text>
        {isEditing ? (
          <>
            <Input.TextArea
              value={q.feedback_text}
              placeholder= {i18n.t('profile__candidate_response', {ns: 'candidateProfile'})}
              autoSize={{ minRows: 2, maxRows: 50 }}
              name="feedback_text"
              onChange={(e) =>
                handleChange(q, { feedback_text: e.target.value })
              }
            />
            <Select
              value={evaluationDictionary(q.evaluation || 0)}
              onSelect={(value) =>
                handleChange(q, { evaluation: parseInt(value) })
              }
            >
              {Object.entries(evaluations).map((eva) => (
                <Select.Option key={`eva-${eva[0]}`} value={eva[0]}>
                  {eva[1]}
                </Select.Option>
              ))}
            </Select>
          </>
        ) : (
          <>
            <Text
              className={
                q.feedback_text &&
                'candidate-modal-interviews__questions__item__answer'
              }
            >
              {q.feedback_text || i18n.t('profile__no_registred_response', {ns: 'candidateProfile'})}
            </Text>
            <Text className="ans-evaluation">
              {evaluationDictionary(q.evaluation || 0)}
            </Text>
          </>
        )}
      </div>
    );
  };

  const answers = group.detail.map(
    (q) =>
      group.answer.find((ans) => ans.question_number === q.question_number) || q
  );

  return (
    <div className="candidate-modal-interviews__questions">
      <Text strong style={{ fontSize: 16 }}>
        {group.group}
      </Text>
      <Button
        onClick={switchEdit}
        icon="edit"
        type="secondary"
        style={{ position: 'absolute', right: '1rem' }}
        disabled={sharing}
      />
      {answers.map((item, i, arr) => (
        <>
          {prepareQuestion(item, i + 1)}
          {i < arr.length - 1 ? <Divider /> : null}
        </>
      ))}
      {isEditing ? (
        <EditButtons onCancel={cancelEdit} onSave={saveLocalKit} />
      ) : null}
    </div>
  );
};

////////////////
const SingleScorecard = ({ propGroup }) => {
  const [group, setGroup] = useState(() => ({
    ...propGroup,
    detail: propGroup.detail.map((q) => ({
      ...q,
      evaluation: 0,
      feedback_text: '',
    })),
  }));
  const [isEditing, setIsEditing] = useState(false);
  const { saveKits, sharing } = useContext(CandidateContext);

  // we use this logic to restore the state when cancel edit behaviour
  const cache = useRef(null);

  const switchEdit = () => {
    if (isEditing) {
      cancelEdit();
    } else {
      cache.current = () => {
        setGroup(group);
      };

      setIsEditing(true);
    }
  };

  const cancelEdit = () => {
    setIsEditing(false);
    cache.current();
    return;
  };

  const changeValoration = (value, questionNumber) => {
    if (group.answer.length) {
      setGroup((group) => ({
        ...group,
        answer: group.answer.map((ans) => {
          if (ans.question_number === questionNumber) {
            ans = { ...ans, evaluation: value };
          }

          return ans;
        }),
      }));
    } else {
      setGroup((group) => ({
        ...group,
        detail: group.detail.map((q) => {
          if (q.question_number === questionNumber) {
            q = { ...q, evaluation: value };
          }

          return q;
        }),
      }));
    }
  };

  const answers = group.detail.map(
    (q) =>
      group.answer.find((ans) => ans.question_number === q.question_number) || q
  );

  return (
    <div className="candidate-modal-interviews__scorecard">
      <Text strong style={{ fontSize: 16 }}>
        {group.group}
      </Text>
      <Button
        onClick={switchEdit}
        icon="edit"
        type="secondary"
        style={{ position: 'absolute', right: '1rem' }}
        disabled={sharing}
      />
      {answers.map((ans, i, arr) => (
        <>
          <div
            className="candidate-modal-interviews__scorecard__item"
            key={ans.id}
          >
            <Text style={{ color: '#454545' }}>{ans.text}</Text>
            <div className="new-modal-ikit-evaluation">
              <div
                style={
                  !ans.evaluation
                    ? { color: '#454545', border: '1px solid #454545' }
                    : { background: 'rgba(0, 0, 0, 0.06)' }
                }
              >
                Sin evaluar
              </div>
              {fillAndMap(5)((x, i) => (
                <Tooltip title={evaluationDictionary(i + 1)}>
                  <div
                    onClick={
                      isEditing
                        ? () => changeValoration(i + 1, ans.question_number)
                        : null
                    }
                    style={
                      !ans.evaluation || ans.evaluation - 1 < i
                        ? { background: 'rgba(0, 0, 0, 0.06)' }
                        : iKitColors[ans.evaluation]
                    }
                  >
                    {i + 1}
                  </div>
                </Tooltip>
              ))}
            </div>
          </div>
          {i < arr.length - 1 ? <Divider /> : null}
        </>
      ))}
      {isEditing ? (
        <EditButtons
          onCancel={cancelEdit}
          onSave={() =>
            saveKits(group.interview_kit, group.answer, group.detail, () =>
              setIsEditing(false)
            )
          }
        />
      ) : null}
    </div>
  );
};

/////////////
const InterviewKit = () => {
  const { getKits, translationFile } = useContext(CandidateContext);

  const { questions, scorecards } = useMemo(() => {
    return getKits().reduce((acc, interview) => {
      if (interview.kit_type === 'INTERVIEW') {
        if (acc.questions) {
          acc.questions = [...acc.questions, interview];
        } else {
          acc.questions = [interview];
        }
      } else {
        if (acc.scorecards) {
          acc.scorecards = [...acc.scorecards, interview];
        } else {
          acc.scorecards = [interview];
        }
      }
      return acc;
    }, {});
  }, [getKits]);

  return questions || scorecards ? (
    <Collapse icon="message" title={i18n.t('profile__personalized_interview', translationFile)}>
      <div className="candidate-modal-interviews">
        {questions?.map((group) => (
          <SingleKit propGroup={group} key={group.id} />
        ))}
        {scorecards?.map((group) => (
          <SingleScorecard propGroup={group} key={group.id} />
        ))}
      </div>
    </Collapse>
  ) : null;
};

export default InterviewKit;
