import React from 'react';
import { Button, Typography } from 'antd';

const { Title, Text } = Typography;

const Done = () => (
  <div className='pj__job-form__done'>
    <span>
    <img src={happyGenomin} alt='done' />
      <Title level={4}>Tu empleo ha sido publicado</Title>
      <a href='https://www.trabajando.cl/' target='_blank'>www.trabajando.cl</a>
    </span>
  </div>
)

export default Done;