import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';

import React from 'react';
import { businessPermissionName } from '../Business/Plugins/utils';

import { Col, Row, Icon, Typography } from 'antd';

import './style.scss';
import i18n from '../../i18n';

const { Title, Text } = Typography;

function AccountView(props) {
  const pushUrl = (url) => {
    props.dispatch(push(url));
  };

  const AccountCard = ({ icon, title, content, disabled, url }) => {
    return (
      <a
        onClick={() => pushUrl(url)}
        style={{ color: 'inherit', pointerEvents: disabled ? 'none' : 'auto' }}
      >
        <div className="account-base-card">
          <Row
            type="flex"
            justify="start"
            align="middle"
            style={{ width: '100%' }}
          >
            <Col xs={6} sm={4}>
              <div
                className="account-circle"
                style={{ backgroundColor: disabled ? '#F0F0F0' : '#FFF0F6' }}
              >
                <div>
                  <Icon
                    type={icon}
                    style={{
                      fontSize: '35px',
                      color: disabled ? '#CCCCCC' : '#F175A5',
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col xs={12} sm={16}>
              <div style={{ marginLeft: 15, fontColor: '#CCCCCC' }}>
                <div>
                  <Text
                    strong
                    style={{ color: disabled ? '#A4A4A4' : 'inherit' }}
                  >
                    {title}
                  </Text>
                </div>
                <div style={{ marginTop: 10 }}>
                  <Text style={{ color: '#A4A4A4' }}>{content}</Text>
                </div>
              </div>
            </Col>
            <Col xs={6} sm={4}>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                {
                  <Icon
                    type={disabled ? 'lock' : 'right'}
                    style={{ color: '#A4A4A4' }}
                  />
                }
              </div>
            </Col>
          </Row>
        </div>
      </a>
    );
  };

  return (
    <div className="account-config-container">
      <div>
        <Title level={1}>{i18n.t('account__drop_down__my__account')}</Title>
        <Typography>
          {i18n.t('my__account__description')}
        </Typography>
      </div>

      <Row gutter={[24, 24]} style={{ marginTop: 30 }}>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AccountCard
            icon={'mail'}
            title={i18n.t('commons__email')}
            content={props.email}
            disabled={true}
          />
        </Col>

        <Col xs={24} sm={24} md={12} lg={8}>
          <AccountCard
            icon={'key'}
            title={i18n.t('commons__password')}
            content={'*******'}
            disabled={false}
            url={'/account/settings/updatepassword'}
          />
        </Col>

        {props.userType === 'business' && (
          <Col xs={24} sm={24} md={12} lg={8}>
            <AccountCard
              icon={'security-scan'}
              title={'Autenticación Multifactor'}
              content={''}
              disabled={false}
              url={'/account/settings/updatemfa'}
            />
          </Col>
        )}
        {props.userType === 'business' && (
          <Col xs={24} sm={24} md={12} lg={8}>
            <AccountCard
              icon={'unlock'}
              title={'Permisos'}
              content={businessPermissionName(props.businessPermissions)}
              disabled={false}
              url={'/account/settings/permissions'}
            />
          </Col>
        )}
        {props.userType === 'business' && ( 
          <Col xs={24} sm={24} md={12} lg={8}>
            <AccountCard
              icon='user'
              title={i18n.t('commons__name')}
              content={props.name}
              url={'/account/settings/updatename'}
            />
          </Col>
        )}
        {props.userType === 'personal' && ( 
          <Col xs={24} sm={24} md={12} lg={8}>
            <AccountCard
              icon='user'
              title={i18n.t('commons__personal__data')}
              url={'/me/profile'}
            />
          </Col>
        )}
        <Col xs={24} sm={24} md={12} lg={8}>
          <AccountCard
            icon='bell'
            title={i18n.t('commons__notifications')}
            content=''
            url='/account/settings/notifications'
          />
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    businessPermissions: state.auth.businessPermissions,
    email: state.auth.userName,
    name: state.auth.name,
    userType: state.auth.userType,
  };
};

AccountView.propTypes = {
  businessPermissions: PropTypes.shape({
    business_admin: PropTypes.bool.isRequired,
    can_manage: PropTypes.bool.isRequired,
    can_invite: PropTypes.bool,
    can_create_cell: PropTypes.bool,
    can_view_all_jobapps: PropTypes.bool,
  }),
  email: PropTypes.string,
  name: PropTypes.string,
};

export default connect(mapStateToProps, null)(AccountView);
