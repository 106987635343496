import React from 'react';
import { Row, Col, Divider } from 'antd';

import '../../pdf-css.scss';
import moment from 'moment';
import i18n from '../../../../../i18n';

function FirstPagePdf({ jobName }) {
  return (
    <div className='first-page-container'>
      <Row type='flex'>
        <Col lg={12} sm={12} xs={12}>
          <img
            alt='logo'
            id='logo'
            style={{ height: '30px' }}
            src={`https://genoma-assets.s3.us-east-2.amazonaws.com/logo.svg`}
          />
        </Col>
        <Col lg={12} sm={12} xs={12}>
          <Row className='second-page-container__date'>
            <p>{`${moment().format('DD/MM/YYYY')}`}</p>
          </Row>
        </Col>
      </Row>

      <Divider />

      <Row>
        <Col lg={24} sm={24} xs={24}>
          <Row className='first-page-container__text-pdf first-page-container__text-pdf--title'>
            <p>Reporte Comparador de Candidatos</p>
          </Row>
        </Col>
        <Col lg={18} sm={18} xs={18}>
          <Row className='first-page-container__text-pdf first-page-container__text-pdf--subtitle'>
            <p>{jobName}</p>
          </Row>
        </Col>
      </Row>
      <Row className='first-page-container__text-pdf first-page-container__text-pdf--note'>
        <p>
          {i18n.t('candidate_pdf__not_authorization_to_share_report', {ns: 'candidatePdf'})}
        </p>
      </Row>
      <Row type='flex' justify='center'>
        <p>Powered by Genomawork</p>
      </Row>
      <Divider />
      <Row style={{ minHeight: 45 }} />
    </div>
  );
}

export default FirstPagePdf;
