import React, { useState, useEffect } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Buffer } from 'buffer';

import * as actionCreators from '../../../actions/data';

import {
  Card,
  Col,
  Button,
  Row,
  Typography,
  message
} from 'antd';

import Form from "../CustomTestForm/components/Form";

function FormView(props) {
  const [state, setState] = useState({ color: '#f175a5', formData: undefined, globalTime: undefined, sumPagesTime: undefined });
  const [testStatus, setTestStatus] = useState({ inProgress: false });

  useEffect(() => {
    setTestStatus(oldData => ({ ...oldData, inProgress: true }));
  }, [state.formData]);

  const startTest = () => {
    // get custom test
    // For testing purposes in the candidate path we can use this endpoint
    const endpoint = `/api/v1/gamesandtests/customtestsurveys/${props.surveyId}/`
    props.actions.fetchAndReturn(props.token, endpoint)
      .then(response => {
        if (response.status < 400) {
          response.body['questions'] = JSON.parse(Buffer.from(response.body['questions'], 'base64').toString('utf-8'));
          setState(oldData => ({ ...oldData, formData: response.body.questions.data, globalTime: response.body['questions'].global_time }));
        }
        else if (response.status == 409) {
          message.warning('Encuesta ya fue completada', 5);
        }
        else {
          message.error('Error al cargar los datos por favor inténtalo nuevamente', 5);
        }
      })

  };

  const UserFormData = () => {
    return (
      <div>
        <Row type="flex" justify='center' align='middle' style={{ marginTop: 150 }}>
          <Col sm={20} md={12} align='middle' justify='center'>
            <Card title="Responder encuesta" bordered={false}>
              <Row type="flex" justify='center' align='middle'>
                <img style={{ maxWidth: '90%', margin: 20 }} src="https://genoma-assets.s3.us-east-2.amazonaws.com/invite-collaborators.png" />
                <Col span={24}>
                  <Button type='primary' onClick={startTest}>Comenzar</Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row >
      </div>
    )
  };

  return (
    <Row type='flex' align='middle' justify='center'>
      <Col xs={24} sm={24} md={24} lg={24} >
        {
          testStatus.inProgress && state.formData !== undefined ?
            <Form
              formData={state.formData}
              globalTime={state.globalTime}
              state={state}
              backToPath={()=>setTestStatus(oldData => ({ ...oldData, inProgress: false }))}
              submitEndpoint={`/api/v1/gamesandtests/customtestsurveys/${props.surveyId}/answers/`}
            />
            :
            <UserFormData />
        }
      </Col>
    </Row>

  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch
  };
};


export default connect(null, mapDispatchToProps)(FormView);

