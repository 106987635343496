import { defaultWelcomeText } from './Utils';

const initialStages = [
  { id: 'ACTIV', name: 'Postulantes', required: false },
  { id: 'HIRED', name: 'Contratado', required: true }
];

export const initialBusiness = {
  colorTheme: {
    color: "#ffffff",
    background: "#395f9d"
  },
  logo: '',
  backgroundImage: '',
  name: '',
  businessCells: [],
  addOns: [],
}

export const initialJobApplication = {
  id: undefined,
  address: '',
  name: '',
  description: '',
  backgroundImage: undefined,
  country: undefined,
  city: '',
  department: '',
  totalVacancies: undefined,
  language: 'es',
  closureDate: undefined,
  welcomeText: defaultWelcomeText,
  status: 'OTHER',
  tags: [],
  introVideo: undefined,
  // Otros campos

  // Fields + values
  custom_fields: {},
  is_confidential: false,
};

// Esto es optional_fields, true->opcional, false->obligatorio
export const initialCandidateFormData = {
  'nationality': false,
  'careers': false,
  'phone': true,
  'country': true,
  'experiences': true,
  'birth_date': true,
  'commune': true,
  'cv': false,
  'gender': true,
  // 'marital_status': true,
  'has_disability': true,
  'education_level': false
}

export const initialState = {
  //jobappType: undefined,
  // undefined on mount, NEW or EDIT after load
  formType: undefined,
  stages: initialStages,
  currentStage: 'ACTIV',
  jobApplication: initialJobApplication,
  candidateForm: {
    candidateFormData: initialCandidateFormData,
    activityId: undefined,
  },
  // Actividades disponibles para agregar
  activities: [],
  // Actividades asignadas al proceso
  assignedActivities: [],
  additionalQuestions: {
    // In this case this are the questions
    allEvaluations: [],
    toAdd: [],
    toRemove: [],
    toUpdate: [],
    currentEvaluations: [],
    activityId: undefined,
  },
  cuTes: {
    allEvaluations: [],
    toAdd: [],
    toRemove: [],
    toUpdate: [],
    currentEvaluations: [],
    activityId: undefined,
  },
  games: {
    allEvaluations: [],
    toAdd: [],
    toRemove: [],
    toUpdate: [],
    currentEvaluations: [],
  },
  videoInterviews: {
    // This field is not used, we still have the key for consistency
    allEvaluations: [],
    toAdd: [],
    // It's not possible to remove video interviews??
    toRemove: [],
    toUpdate: [],
    currentEvaluations: [],
  },
  interviewProcesses: {
    // This field is not used, we still have the key for consistency
    allEvaluations: [],
    toAdd: [],
    // It's not possible to remove video interviews??
    toRemove: [],
    toUpdate: [],
    currentEvaluations: [],
  },
  triggers: {
    toAdd: [],
    toRemove: [],
    currentTriggers: [],
  },
  // TODO deprecar ikits
  interviewKits: {
    allEvaluations: [],
    toAdd: [],
    toRemove: [],
    currentEvaluations: [],
  },

  filesRequests: {
    allEvaluations: [],
    toAdd: [],
    toRemove: [],
    toUpdate: [],
    currentEvaluations: [],
    activityId: undefined,
  },
  business: initialBusiness,
  measuredTraits: {
    originalMTraits: [],
    current: [],
  },

  // Estos custom_fields vienen del business y son configurados para todos los procesos
  // custom_fields es un conjunto que contiene los inputs de default_custom_fields + custom_fields locales
  // si name_key no existe como llave en custom_fields -> ese field es de tipo texto y fue creado solo para el proceso
  defaultCustomFields: [],

  // jobApplication tambien tiene un campo inegrations, pero desacoplamos la logica para que sea mas facil controlar el contexto
  integrations: {
    // Activas en el proceso
    jobApplicationIntegrations: [],
    // Integraciones posibles
    businessIntegrations: [],
    // Si agregamos o quitamos una integracion llamamos a la api
    update: false
  }
};