import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Avatar, Col, Icon, Row, Radio, Typography } from 'antd';

const { Text } = Typography;
import { recommended, rateValues } from '../../helpers';
import CustomRadioButton from '../../../../components/CustomRadioButton';
import i18n from '../../../../i18n';

const IndividualEvaluation = (globalEvaluation) => {
  const BUevaluations = globalEvaluation.globalevaluation.business_evaluations;
  const [idx, setIdx] = useState(0);
  const [bu, setBu] = useState(
    BUevaluations[idx].business_user.business_user
      ? BUevaluations[idx].business_user.business_user
      : BUevaluations[idx].business_user.email
  );
  return (
    <Col>
      <Row style={{ alignItems: 'center', marginTop: '1em' }}>
        <Col lg={8} xs={4}></Col>
        <Col lg={1}>
          <Icon
            size={40}
            type="left"
            onClick={() => {
              const oldIdx = idx;
              setIdx(idx == 0 ? 0 : idx - 1);
              setBu(
                oldIdx == 0
                  ? BUevaluations[0].business_user.business_user
                    ? BUevaluations[0].business_user.business_user
                    : BUevaluations[0].business_user.email
                  : BUevaluations[oldIdx - 1].business_user.business_user
                  ? BUevaluations[oldIdx - 1].business_user.business_user
                  : BUevaluations[oldIdx - 1].business_user.email
              );
            }}
            style={{ color: idx == 0 ? '#CCCCCC' : '#597EF7' }}
          />
        </Col>
        <Col lg={1}>
          <Avatar size={35} className="avatar-VE">
            <Text>
              {bu[0]}
              {bu.split(' ').length > 1 ? bu.split(' ')[1][0] : bu[1]}
            </Text>
          </Avatar>
        </Col>
        <Col lg={5}>
          <Text strong style={{ marginLeft: '1em' }}>
            {bu}
          </Text>
        </Col>
        <Col lg={1}>
          <Icon
            size={40}
            type="right"
            onClick={() => {
              const oldIdx = idx;

              setIdx(
                idx == BUevaluations.length - 1
                  ? BUevaluations.length - 1
                  : idx + 1
              );

              setBu(
                oldIdx == BUevaluations.length - 1
                  ? BUevaluations[BUevaluations.length - 1].business_user
                      .business_user
                    ? BUevaluations[BUevaluations.length - 1].business_user
                        .business_user
                    : BUevaluations[BUevaluations.length - 1].business_user
                        .email
                  : BUevaluations[oldIdx + 1].business_user.business_user
                  ? BUevaluations[oldIdx + 1].business_user.business_user
                  : BUevaluations[oldIdx + 1].business_user.email
              );
            }}
            style={{
              color: idx == BUevaluations.length - 1 ? '#CCCCCC' : '#597EF7',
            }}
          />
        </Col>
        <Col lg={9}></Col>
      </Row>
      <Row type="flex" style={{ alignItems: 'center' }}>
        <Col lg={24} md={24} xs={24}>
          <Col lg={9} md={9} xs={6}></Col>
          <Col
            lg={1}
            md={1}
            xs={2}
            style={{ marginLeft: !isMobile ? '0.5em' : null }}
          >
            {BUevaluations[idx].recommended ? (
              <Icon
                type={recommended[BUevaluations[idx].recommended]['icon']}
                theme="filled"
                style={{
                  fontSize: 14,
                  color: recommended[BUevaluations[idx].recommended]['color'],
                }}
              />
            ) : (
              <Icon
                type="question-circle"
                theme="filled"
                style={{ fontSize: 14, color: '#CCCCCC' }}
              />
            )}
          </Col>
          <Col lg={6} md={6} xs={10}>
            {BUevaluations[idx].recommended ? (
              <Text
                type="secondary"
                style={{
                  color: recommended[BUevaluations[idx].recommended]['color'],
                }}
              >
                {recommended[BUevaluations[idx].recommended]['text']}
              </Text>
            ) : (
              <Text type="secondary" style={{ color: '#CCCCCC' }}>
                 {i18n.t('profile__unanswered', {ns: 'candidateProfile'})}
              </Text>
            )}
          </Col>
        </Col>
      </Row>
      <Col className="questions-and-answers-container">
        <Row style={{ marginLeft: '1em', marginTop: '1em' }}>
          <Text strong>{i18n.t('profile__questions', {ns:'candidateProfile'})}</Text>
        </Row>
        {Object.keys(BUevaluations[idx].evaluations).map((evaluation, idx2) => {
          return evaluation !== 'average' ? (
            <Row type="flex" className="evaluations-container" style={{marginLeft: '1em'}}>
              <Col span={24}>
                <Text>
                  {idx2}.{' '}
                  {BUevaluations[idx].evaluations[evaluation]['question']}
                </Text>
              </Col>
              <Col
                type="flex"
                justify="space-around"
                style={{ alignItems: 'center', marginBottom: '0.5em' }}
              >
                <CustomRadioButton
                  value={BUevaluations[idx].evaluations[evaluation]['rating']}
                  rateValues={rateValues.slice(1)}
                />
              </Col>
              <Col span={24}>
                {isMobile ? (
                  <Text
                    style={{
                      marginLeft: 100,
                      color:
                        rateValues[
                          Math.round(
                            BUevaluations[idx].evaluations[evaluation]['rating']
                          )
                        ]['color'],
                    }}
                  >
                    {
                      rateValues[
                        Math.round(
                          BUevaluations[idx].evaluations[evaluation]['rating']
                        )
                      ]['text']
                    }
                  </Text>
                ) : (
                  <Text
                    style={{
                      marginLeft:
                        rateValues[
                          Math.round(
                            BUevaluations[idx].evaluations[evaluation]['rating']
                          )
                        ]['marginLeft'],

                      color:
                        rateValues[
                          Math.round(
                            BUevaluations[idx].evaluations[evaluation]['rating']
                          )
                        ]['color'],
                    }}
                  >
                    {
                      rateValues[
                        Math.round(
                          BUevaluations[idx].evaluations[evaluation]['rating']
                        )
                      ]['text']
                    }
                  </Text>
                )}
              </Col>
            </Row>
          ) : (
            ''
          );
        })}
      </Col>
    </Col>
  );
};

export default IndividualEvaluation;
