import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ParseHtml from '../../components/parseHtml';
import * as actionCreators from '../../actions/data';
import * as authActionCreators from '../../actions/auth';
import { Layout, Typography, Button, List, Tabs, Row, Col } from 'antd';
import CompanyDescription from '../JobListing/components/CompanyDescription';

import chileIMG from './assets/flags/chile.png';
import argentinaIMG from './assets/flags/argentina.png';
import boliviaIMG from './assets/flags/bolivia.png';
import brazilIMG from './assets/flags/brazil.png';
import colombiaIMG from './assets/flags/colombia.png';
import ecuadorIMG from './assets/flags/ecuador.png';
import paraguayIMG from './assets/flags/paraguay.png';
import peruIMG from './assets/flags/peru.png';
import uruguayIMG from './assets/flags/uruguay.png';
import venezuelaIMG from './assets/flags/venezuela.png';
import './JobAppGroup.scss';

const { Title, Text } = Typography;
const defaultImage = 'https://images.unsplash.com/photo-1523240795612-9a054b0db644';

class JobAppGroup extends React.Component {
    static propTypes = {
        statusText: PropTypes.string,
        isFetching: PropTypes.bool.isRequired,
        data: PropTypes.object,
        // token: PropTypes.string.isRequired,
        actions: PropTypes.shape({
            fetchData: PropTypes.func.isRequired
        }).isRequired,
        authActions: PropTypes.shape({
            authLoginUser: PropTypes.func.isRequired,
            authSignupUser: PropTypes.func.isRequired
        }).isRequired
    };

    static defaultProps = {
        data: null,
        errors: {},
        status: ''
    };

    results = []

    state = {
        backgroundImage: defaultImage,
        businessUser: "Test",
        jobappid: undefined,
        results: [],
        cities: [],
        welcome_text: ''
    };

    componentWillMount() {
        window.scrollTo({ top: 0 });
        const token = this.props.token;
        
        const { groupid, jobportalslug, businessuserslug } = this.props.match.params;
        let url;
        if (groupid) {
            url = `gamesandtests/jobappgroup/${groupid}/`;
        } else if (jobportalslug && businessuserslug) {
            url = `gamesandtests/jobportal/?job_portal_slug=${jobportalslug}&business_slug=${businessuserslug}`;
        }
        
        this.props.actions.fetchData(token, url, '', 'get')
            .then((response) => {
                this.setState({
                    jobAppGroup: response.job_application_group,
                    business_user: response.job_application_group.business_user,
                    welcome_text: response.job_application_group.description,
                    backgroundImage: response.job_application_group.background_image !== '' ? response.job_application_group.background_image :
                        response.job_application_group.business_user.jobs_site_background_image || defaultImage,
                });
                this.props.authActions.updateLogo(response.job_application_group.business_user.business?.logo)
            })
            .catch(() => {
                // window.location.href = '/NotFound';
            })
    };

    handleNavigateToJobs = () => {
        const el = document.getElementById('jag-section');
        const bodyRect = document.body.getBoundingClientRect();
        const elemRect = el.getBoundingClientRect();
        const distance = elemRect.top - bodyRect.top;
        window.scrollTo({
            left: 0,
            top: distance
        });
    };

    getCities = () => {
        const cities = this.results.map(({ location_city, location_country }) => {
            return {
                country: location_country,
                city: location_city
            }
        });

        return cities.filter((v, i, a) => a.findIndex(t => (t.country === v.country && t.city === v.city)) === i);
    }

    flag = (country) => {
        switch (country) {
            case 'CL': //chile
                return <img src={chileIMG} />
            case 'PE': //peru
                return <img src={peruIMG} />
            case 'BO': //bolivia
                return <img src={boliviaIMG} />
            case 'CO': //colombia
                return <img src={colombiaIMG} />
            case 'AR': //argentina
                return <img src={argentinaIMG} />
            case 'BR': //brasil
                return <img src={brazilIMG} />
            case 'EC': //ecuador
                return <img src={ecuadorIMG} />
            case 'PA': //paraguay
                return <img src={paraguayIMG} />
            case 'UY': //uruaguay
                return <img src={uruguayIMG} />
            case 'VN': //venezuela
                return <img src={venezuelaIMG} />
        }
    }

    render() {
        const { classes, error, loading, products, alerts, ...rest } = this.props;

        return (
            <Layout className='jag'>
                <Layout.Content>
                    <section className='jag__cover'>
                        <img className='jag__cover__img' src={this.state.backgroundImage}></img>
                    </section>
                    <section className='jag__rrss'>
                        {this.props.data.rrss_facebook && <a target='_blank' href={this.props.data.rrss_facebook}><img src='https://i.imgur.com/VWl2y2E.png' /></a>}
                        {this.props.data.rrss_linkedin && <a target='_blank' href={this.props.data.rrss_linkedin}><img src='https://i.imgur.com/3arbjqA.png' /></a>}
                        {this.props.data.rrss_instagram && <a target='_blank' href={this.props.data.rrss_instagram}><img src='https://i.imgur.com/nVfb8Pd.png' /></a>}
                        {this.props.data.rrss_twitter && <a target='_blank' href={this.props.data.rrss_twitter}><img src='https://i.imgur.com/ZTbJxOe.png' /></a>}
                    </section>
                    <section className='jag_welcome' style={{ textAlign: 'center', maxWidth: '80%', margin: 'auto' }} dangerouslySetInnerHTML={{ __html: this.state.jobAppGroup ? this.state.jobAppGroup.html_description : this.state.welcome_text }}></section>
                    <section className='jag__jobs' id='jag-section'>
                        {this.state.jobAppGroup ?
                            <List
                                className='jag__jobs__list'
                                header={<Title level={2}>{this.state.jobAppGroup ? this.state.jobAppGroup.name : ''}</Title>}
                                dataSource={this.state.jobAppGroup.job_applications}
                                renderItem={item => (
                                    <List.Item className='jag__jobs__list-item'>
                                        <Row type='flex'>
                                            <Col xs={20} md={14}>
                                                <Row type='flex'><Text strong>{item.job_application}</Text></Row>
                                                <Row type='flex'><div>{ParseHtml(item.short_description)}</div></Row>
                                            </Col>
                                            <Col xs={4} className='location'>
                                                <span className='location-with-flag'>
                                                    <span className='jag-flag'>
                                                        {this.flag(item.location_country)}
                                                    </span>
                                                    <span style={{ textAlign: 'left' }}>
                                                        {item.location_city}
                                                    </span>
                                                </span>
                                            </Col>
                                            <Col xs={4} md={6}><Button onClick={() => this.props.dispatch(push('/startprocess/' + item.id + '/'))}>Postular</Button></Col>
                                        </Row>
                                    </List.Item>
                                )}
                            />
                            : null}

                    </section>
                </Layout.Content>
                <Layout.Footer>
                    {this.props.data.rrss_website &&
                        <a href={this.props.data.rrss_website} target='_blank'>
                            {this.props.data.rrss_website}
                        </a>
                    }
                </Layout.Footer>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.data.data,
        isFetching: state.data.isFetching,
        error: state.data.error,
        logoURL: state.auth.logoURL
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actionCreators, dispatch),
        authActions: bindActionCreators(authActionCreators, dispatch),
        dispatch
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobAppGroup);
export { JobAppGroup as JobAppGroupNotConnected };
