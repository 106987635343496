import React, { Component } from 'react';
import { connect } from 'react-redux';

import NavigationTabs from './components/Customizable/NavigationTabs/Tabs';
import CandidatePath from './components/Customizable/CandidatePath/CandidatePath';
import IdealGenomeConfig from './components/Customizable/IdealGenomeConfig/Configuration';

import { Row, Col, Button, Icon } from 'antd';
import './IdealGenome.scss';

class IdealGenome extends Component {
  state = {
    current: 0,
  }

  onChange = current => {
    this.setState({ current });
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  render() {
    const { current } = this.state;
    const { 
      assignedActivities, 
      totalPoints, 
      personalityTraits, 
      reasoningTraits, 
      cognitiveTraits,
      otherTraits} = this.props;
   
    const traits = { 
      personalityTraits, 
      reasoningTraits, 
      cognitiveTraits,
      otherTraits
    };

    let acts = [];
    for (const x in assignedActivities) { 
      acts.push(assignedActivities[x]);
    }

    const levelTwo = acts.filter(e => e.icon !== 'FORM' && e.icon !== 'KO');

    return (
      <Row className='ig-content' gutter={20}>
        <Col xs={24} className='ig-content__steps'>
          
          <NavigationTabs 
            totalPoints={totalPoints} 
            onChange={this.onChange}
            assignedActivities={assignedActivities}
            traits={traits} 
            current={current}
          />

          <div className='ig'>
            {current === 0 ? (
              <CandidatePath 
                config={this.onChange} 
                onChangeCustomTest={this.props.onChangeCustomTest}
                currentCustomTests={this.props.currentCustomTests}
                current={current} 
              />
            ) : (
              <IdealGenomeConfig traits={traits} />
            )}

            <footer>
              {current === 0 ? (
                <Button 
                  type='ghost' 
                  onClick={() => this.onChange(1)} 
                  disabled={levelTwo.length === 0}
                  className='next'
                >
                  Configurar genoma ideal <Icon type='right' /> 
                </Button>
              )
                : 
              (
                <Button 
                  type='ghost'
                  icon='left' 
                  onClick={() => this.onChange(0)} 
                  className='prev'
                >
                  Atrás
                </Button>
              )}
            </footer>
          </div>
        </Col>
      </Row>
    )
  }
}

const getCognitiveTraits = (traits) => {
  return Object.values(traits).filter(trait => trait.category==='COGNIT' || trait.category==='EMOTIO' || trait.category==='BEHAV');
}

const mapStateToProps = (state) => {
  return {
    assignedActivities: state.jobapp.assignedActivities,
    cognitiveTraits: getCognitiveTraits(state.trait.measuredTraits),
    personalityTraits: Object.values(state.trait.measuredTraits).filter(trait => trait.category==='PERSON'),
    reasoningTraits: Object.values(state.trait.measuredTraits).filter(trait => trait.category==='RAZONA'),
    otherTraits: Object.values(state.trait.measuredTraits).filter(trait => trait.category==='OTHER'),
    totalPoints: state.trait.totalPoints
  };
};

export default connect(mapStateToProps)(IdealGenome);