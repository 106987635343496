import React, { useState } from 'react'
import {
  Breadcrumb,
  Button,
  Col,
  Icon,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Select,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import InputsIntegrationModal from '../InputsIntegrationModal';


export default function GenericIntegrationModalContent({
  businessIntegrationData,
  editBusinessIntegrationData,
  questionType,
  setEditBusinessIntegrationData,
  visible,
}) {
  return (
    <>
      <Typography.Paragraph>
        En esta sección deberás ingresar la clave de acceso
        (token) y el url entregado por{" "}
        <strong>
          {businessIntegrationData.template
            ? businessIntegrationData.template.name
            : "la otra aplicación"}
        </strong>{" "}
        para poder gestionar correctamente la integración.
      </Typography.Paragraph>
      <InputsIntegrationModal
        editBusinessIntegrationData={editBusinessIntegrationData}
        setEditBusinessIntegrationData={setEditBusinessIntegrationData}
        businessIntegrationData={businessIntegrationData}
        questionType={questionType}
        visible={visible}
      />
    </>
  )
}

