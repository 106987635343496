const unaccent = (word) => word.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

const getTrigrams = (word) => {
  const trigrams = new Set();
  for (let i = 0; i <= word.length - 3; i++) {
    const trigram = word.slice(i, i+3);
    trigrams.add(trigram);
  }
  return trigrams;
}

const trigramSimilarity = (x, y) => {
  const words = [unaccent(x), unaccent(y)];
  let count = 0;
  const trigramsX = getTrigrams(`  ${words[0].toLowerCase()} `);
  const trigramsY = getTrigrams(`  ${words[1].toLowerCase()} `);
  const uniqueTrigrams = new Set(trigramsX);
  // Union of trigrams
  trigramsY.forEach(t => {
    uniqueTrigrams.add(t);
  });
  uniqueTrigrams.forEach(t => {
    if (trigramsX.has(t) && trigramsY.has(t)) {
      count += 1;
    }
  })
  return count / uniqueTrigrams.size
}

const trigramDistance = (x, y) => 1 - trigramSimilarity(x,y);

export { trigramSimilarity, trigramDistance, unaccent };
