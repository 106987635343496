import React from "react";

import {
  Button,
  Typography,
  Col,
  Row
} from 'antd';

import './customTests.scss';

const BlockedView = ({ backToPath }) => {
  return (
    <Row type='flex' align='middle' justify='center' style={{ minHeight: '90vh' }}>
      <Col span={20}>
        <div style={{ textAlign: 'center', margin: 30 }}>
          <Typography.Text>
            Prueba completada 🚀
          </Typography.Text>
        </div>

        <div style={{ textAlign: 'center', marginBottom: 50 }}>
          <Typography.Text>
            Ya puedes tomar un merecido descanso
          </Typography.Text>
        </div>

        <div>
          <Button style={{width:'100%'}} onClick={backToPath}>
            Volver
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default BlockedView;