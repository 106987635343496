
import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment'

import {
  Button, DatePicker, Form, Icon, InputNumber, Modal, Col, Row, Input, Select, Tooltip, Typography, Upload, Switch
} from 'antd';
import localeES from 'antd/es/date-picker/locale/es_ES';

import NewCustomFieldModal from '../../../../../components/NewCustomFieldModal';
import JobAppContext from '../../context/JobAppContext';

function CustomFields(props) {
  const [newCustomFieldModalVisible, setNewCustomFieldModalVisible] = useState(false);
  const [customFields, setCustomFields] = useState({});

  const { defaultCustomFields, jobApplication, updateJobApplication, updateCustomFields } = useContext(JobAppContext);

  const { getFieldDecorator } = props.form;

  const { Paragraph, Title } = Typography;

  useEffect(() => {
    const tmpFields = {};
    defaultCustomFields?.forEach(obj => {
      tmpFields[obj.name_key] = undefined;
    });
    Object.keys(jobApplication.custom_fields).forEach(elem => {
      tmpFields[elem] = jobApplication.custom_fields[elem];
    });
    setCustomFields(tmpFields);
  }, [jobApplication.custom_fields, defaultCustomFields]);

  const handleCustomFields = (name, value) => {
    const updatedCustomFields = { ...jobApplication.custom_fields };
    updatedCustomFields[name] = value;
    updateJobApplication('custom_fields', updatedCustomFields);
  };

  const handleChange = (field) => (event) => {
    const jobapp = { ...jobApplication };
    if (field.includes('custom_fields')) {
      const name_key = field.split('.')[1];
      if (event instanceof File && jobapp.custom_fields[name_key]) {
        handleCustomFields(name_key, event)
      } else {
        // We are only using event inputs
        handleCustomFields(name_key, event)
      }
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) return e.slice(-1);
    return e && e.fileList.slice(-1);
  };

  const getCustomFieldCondig = (key) => {
    return defaultCustomFields?.find(obj => obj.name_key === key);
  };

  const confirmDelete = (customFieldKey) => {
    Modal.confirm({
      cancelText: 'Cancelar',
      closable: false,
      content: (
        <>
          ¿Estás seguro que quieres eliminar el atributo
          <strong>{` ${customFieldKey.replaceAll('_', ' ')} `}</strong>
          de este proceso?
        </>
      ),
      okText: 'Eliminar',
      onOk: () => {
        const updatedCustomFields = { ...jobApplication.custom_fields };
        delete updatedCustomFields[customFieldKey];
        updateCustomFields({ ...updatedCustomFields });
      },
      title: 'Eliminar atributo personalizado del proceso',
    })
  };

  return <>
    <NewCustomFieldModal
      existingFieldsKeys={[
        ...Object.keys(jobApplication.custom_fields), 
        ...Object.keys(defaultCustomFields ? defaultCustomFields : {})
      ]}
      handleCustomFields={(customFieldKey, value) => {
        handleCustomFields(customFieldKey, value);
      }}
      visible={newCustomFieldModalVisible}
      closeModal={() => setNewCustomFieldModalVisible(false)}
      type="JOBAPP"
    />
    {Object.keys(customFields).length === 0 ? (
      <Typography.Paragraph>
        Aún no tienes atributos personalizados en este proceso.
      </Typography.Paragraph>
    ) : (
      <>
        {Object.keys(customFields).map((customFieldKey) => {

          const customFieldConfig = getCustomFieldCondig(customFieldKey);
          return (
            <Row align="middle" key={customFieldKey} type="flex">
              {customFieldConfig ? (
                <Col span={22}>
                  <Form.Item
                    colon={false}
                    label={
                      <span>
                        {customFieldConfig.name}:
                        <Tooltip
                          placement="right"
                          title={
                            ![null, undefined, ''].includes(customFieldConfig.description)
                              ? customFieldConfig.description
                              : 'Este atributo no tiene descripción'
                          }
                        >
                          <Icon style={{ marginLeft: '5px' }} type="info-circle" />
                        </Tooltip>
                      </span>
                    }
                  >
                    {customFieldConfig.value_type === 'text' && (
                      getFieldDecorator(`custom_fields.${customFieldKey}`, {
                        initialValue: customFields[customFieldKey],
                        onChange: (event) => (
                          handleChange(`custom_fields.${customFieldKey}`)(event.target.value)
                        ),
                        rules: [{ max: 500, message: 'Máximo 500 caracteres' }]
                      })(<Input.TextArea rows={3}/>)
                    )}
                    {customFieldConfig.value_type === 'number' && (
                      getFieldDecorator(`custom_fields.${customFieldKey}`, {
                        initialValue: customFields[customFieldKey],
                        onChange: handleChange(`custom_fields.${customFieldKey}`),
                        rules: [{ type: 'number', message: 'Debe ser un número' }],
                      })(<InputNumber />)
                    )}
                    {customFieldConfig.value_type === 'boolean' && (
                      getFieldDecorator(`custom_fields.${customFieldKey}`, {
                        initialValue: customFields[customFieldKey],
                        onChange: handleChange(`custom_fields.${customFieldKey}`),
                        valuePropName: 'checked'
                      })(<Switch />)
                    )}
                    {customFieldConfig.value_type === 'date' && (
                      getFieldDecorator(`custom_fields.${customFieldKey}`, {
                        initialValue: customFields[customFieldKey] ? (
                          moment(customFields[customFieldKey], 'DD-MM-YYYY')
                        ) : null,
                        onChange: (value) => (
                          handleChange(`custom_fields.${customFieldKey}`)(
                            moment(value).format('DD-MM-YYYY')
                          )
                        ),
                      })(<DatePicker allowClear format="DD-MM-YYYY" locale={localeES} />)
                    )}
                    {customFieldConfig.value_type.includes('selection') && (
                      getFieldDecorator(`custom_fields.${customFieldKey}`, {
                        initialValue: customFields[customFieldKey] || undefined,
                        onChange: handleChange(`custom_fields.${customFieldKey}`),
                      })(
                        <Select
                          allowClear
                          mode={customFieldConfig.value_type.includes('multi') ? 'multiple' : 'default'}
                          style={{ minWidth: 200 }}
                        >
                          {customFieldConfig.options.map((value) => (
                            <Select.Option key={value} value={value}>{value}</Select.Option>
                          ))}
                        </Select>
                      )
                    )}
                    {customFieldConfig.value_type === 'file' && (
                      getFieldDecorator(`custom_fields.${customFieldKey}`, {
                        getValueFromEvent: normFile,
                        initialValue: customFields[customFieldKey],
                        onChange: ({ file }) => handleChange(`custom_fields.${customFieldKey}`)(file),
                        rules: [
                          {
                            validator: (rule, value, callback) => {
                              if (value && value[0].size > 5242880) {
                                callback(true);
                              } else {
                                callback();
                              }
                            },
                            message: 'Archivo no puede pesar más de 5MB',
                          },
                        ],
                        valuePropName: 'fileList'
                      })(
                        <Upload.Dragger
                          accept=".pdf,.doc"
                          // Prevent upload
                          beforeUpload={(file) => {
                            if (file.size <= 5242880) return false;
                          }}
                          name={customFieldKey}
                          multiple={false}
                        >
                          <Icon style={{ fontSize: '30px ' }} type="inbox" />
                          <Title level={3} style={{ marginBottom: 0 }}>Sube tu archivo acá</Title>
                          <Paragraph>Máximo 5MB. Formatos permitidos: .pdf y .doc</Paragraph>
                        </Upload.Dragger>
                      )
                    )}
                  </Form.Item>
                </Col>
              ) : (
                <>
                  <Col span={22}>
                    <Form.Item label={customFieldKey.replaceAll('_', ' ')}>
                      {getFieldDecorator(`custom_fields.${customFieldKey}`, {
                        initialValue: customFields[customFieldKey],
                        onChange: (event) => (
                          handleChange(`custom_fields.${customFieldKey}`)(event.target.value)
                        ),
                        rules: [{ max: 250, message: 'Máximo 250 caracteres' }]
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                  <Col span={2} align="center">
                    <Icon
                      onClick={() => confirmDelete(customFieldKey)}
                      style={{ paddingTop: '1em' }}
                      type="delete"
                    />
                  </Col>
                </>
              )}
            </Row>
          )
        })
        }
      </>
    )}
    <Row type="flex" style={{ marginBottom: '25px' }}>
      <Col span={12}>
        <Button
          onClick={() => setNewCustomFieldModalVisible(true)}
          type="secondary"
        >
          Agregar atributo
        </Button>
      </Col>
    </Row>
  </>
}
const CustomFieldsForm = Form.create({ name: 'custom-fields-form' })(CustomFields);
export default CustomFieldsForm;