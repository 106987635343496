import { Modal, message } from 'antd';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { findAssignedActivity, validateData } from './Utils';
import { getMTraitsToDelete, getMTraitsToUpdate } from './MeasuredTraitUtils';
import { processRequest, processResponses } from './Utils';

import ContextReducer from './ContextReducer'
import { SERVER_URL } from "../../../../utils/config";
import { buildMeasuredTrait } from './MeasuredTraitUtils';
import fetch from "isomorphic-fetch";
import { initialState } from './Models';
import { push } from 'react-router-redux';

const videoInterviewUrl = `${process.env.REACT_APP_VIDEO_URL}/api/interviews/`;

export const JobAppProvider = ({
  children,
  actions,
  businessId,
  businessPermissions,
  token,
  ...props
}) => {
  const [state, dispatchState] = useReducer(ContextReducer, structuredClone(initialState));
  const [countries, setCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [sendingData, setSendingData] = useState(false);
  const [showShareProcessModal, setShowShareProcessModal] = useState(false);
  const [hasCandidates, setHasCandidates] = useState(false);

  const shortDispatch = (type, payload) => dispatchState({ type, payload });

  // Cargamos datos en caso que el proceso ya exista
  const getCurrentAssignedActivities = async () => {
    setIsLoading(true);
    const tmpJobappId = props.match.params['jobappid'];

    if (tmpJobappId) {
      const jobappUrl = `/api/v1/gamesandtests/jobapplications/${tmpJobappId}/`;
      // Actualizamos los datos de la job app si existen
      let oldVideoInterviewId = null;
      await actions.fetchAndReturn(token, jobappUrl).then(response => {
        if (response.status >= 400) {
          message.error('Error al cargar la información de la postulación', 5000);
        }
        else {
          const results = response.body;
          oldVideoInterviewId = results.interview_id;
          const jobAppData = {
            jobApplication: processResponses(results, 'jobapplication'),
          };
          //shortDispatch('UPDATE_CURRENT_STAGE', results.stages.stages[0].code);
          // La 1ra etapa activa es en proceso -> ACTIV
          shortDispatch('UPDATE_CURRENT_STAGE', 'ACTIV');
          shortDispatch('UPDATE_STAGES', processResponses(results.stages, 'stages'));
          shortDispatch('UPDATE_TRIGGERS', { key: 'currentTriggers', value: processResponses(results.triggers, 'triggers')})
          shortDispatch('UPDATE_FORM', { key: 'candidateFormData', value: processResponses(results.optional_fields, 'form') });
          shortDispatch('HYDRATE_STATE', jobAppData);
        }
      });

      const assignedActivitiesUrl = `/api/v1/gamesandtests/jobapplications/${tmpJobappId}/activities/`;
      actions.fetchAndReturn(token, assignedActivitiesUrl).then(response => {
        if (response.status === 200) {
          const results = response.body.results
          results.forEach(obj => {
            // TODO remove en un futuro lejano o pasar script reemplzando todos los stage = 2 por ACTIV
            // Logica vieja derepente nos llegan cosas con stage = 1 o 2
            if (obj.stage === '2' || obj.stage === '1') {
              obj.stage = 'ACTIV';
            }
          });
          // Video entrevistas viejas sin assigned activity, creamos una fake
          const foundVideoInterview = results.find(obj => obj.activity.code === 'VINT');
          if (!foundVideoInterview && oldVideoInterviewId) {
            results.push({ activity: { code: 'VINT' }, stage: 'V_INT', configuration: JSON.stringify({ id: oldVideoInterviewId }) });
          }
          const updateState = { assignedActivities: results };
          shortDispatch('HYDRATE_STATE', updateState);
          // Este update gatilla el siguiente useEffect
        }
      })
    }
    setIsLoading(false);
  }

  useEffect(() => {
    // Actualizamos las current evaluations si solo si no existe una evaluacion actual
    // KO
    if (state.additionalQuestions.currentEvaluations.length == 0) {
      const assignedAdditionalQuestions = state.assignedActivities.filter(obj => obj.activity.code === 'KO');
      const currentQuestions = assignedAdditionalQuestions.map(obj => processResponses(obj, 'questions'));
      shortDispatch('UPDATE_ADDITIONAL_QUESTIONS', { key: 'currentEvaluations', value: currentQuestions });
    }
    // CUTES
    if (state.cuTes.currentEvaluations.length == 0) {
      const assignedCuTes = state.assignedActivities.filter(obj => obj.activity.code === 'CUTE');
      if (assignedCuTes.length > 0 && state.cuTes.allEvaluations.length > 0) {
        const currentCuTes = assignedCuTes.map(obj => processResponses({ allCuTes: state.cuTes.allEvaluations, cute: obj }, 'cutes'));
        shortDispatch('UPDATE_CUTES', { key: 'currentEvaluations', value: currentCuTes });
      };
    };
    // GAMES
    // No sea ko, cute, video entrevista etc
    if (state.games.currentEvaluations.length == 0) {
      const assignedGames = state.assignedActivities.filter(obj => !['FORM', 'KO', 'CUTE', 'INTRV', 'VINT', 'DOCR', 'TERA'].includes(obj.activity.code));
      const currentGames = assignedGames.map(obj => processResponses({ game: obj, stages: state.stages }, 'games'));
      shortDispatch('UPDATE_GAMES', { key: 'currentEvaluations', value: currentGames });
    }
    // Video interview
    if (state.videoInterviews.currentEvaluations.length == 0) {
      const assignedVideoInterview = state.assignedActivities.find(obj => obj.activity.code === 'VINT');
      if (assignedVideoInterview) {
        const videoInterviewId = JSON.parse(assignedVideoInterview.configuration).id;
        actions.fetchAnyUrl(token, videoInterviewUrl + videoInterviewId, 'GET').then(resp => {
          const content = {
            emailContent: { content: resp.interviewTxt, subject: '' },
            questions: resp.questions,
            stage: assignedVideoInterview.stage,
            id: videoInterviewId
          };
          shortDispatch('UPDATE_VIDEO_INTERVIEWS', { key: 'currentEvaluations', value: [content] });
        });
      };
    };
    if (state.interviewProcesses.currentEvaluations.length === 0) {
      const assignedInterviewProcessActivity = state.assignedActivities.find(obj => obj.activity.code === 'TERA');
      if (assignedInterviewProcessActivity) {
        shortDispatch('UPDATE_INTERVIEW_PROCESSES', { key: 'currentEvaluations', value: [assignedInterviewProcessActivity] });
      };
    }
  }, [state.assignedActivities, state.cuTes.allEvaluations])

  const getCandidates = () => {
    if (props.match.params['jobappid']) {
      const endpoint = `/api/v1/gamesandtests/candidatelist/${props.match.params['jobappid']}/?format=json&serializer=table&pagination=true&page_size=1`
      actions.fetchAndReturn(token, endpoint).then(response => {
        if (response.status === 200 && response.body.count > 0) {
          setHasCandidates(true);
        }
      })
    };
  };

  const getCustomTests = () => {
    let endpoint = '/api/v1/gamesandtests/customtests/'
    actions.fetchAndReturn(token, endpoint).then(response => {
      if (response.status === 200) {
        shortDispatch('UPDATE_CUTES', { key: 'allEvaluations', value: response.body });
        /**
        esto era para testear un proceso con cutes asignadas
        const temp = { ...response.body[0] }
        temp.stage = 'INITIAL'
        shortDispatch('UPDATE_CUTES', { key: 'currentEvaluations', value: [temp] });
         */
      }
    });
  };

  // TODO deprecar ikits
  const getInterviewKits = () => {
    let endpoint = '/api/v1/evaluations/interviewkit_list/'
    actions.fetchAndReturn(token, endpoint).then(response => {
      if (response.status === 200) {
        shortDispatch('UPDATE_IKITS', { key: 'allEvaluations', value: response.body });
      };
    });
  };

  // TODO 
  const getAllFilesRequests = () => {
    let endpoint = `/api/v1/gamesandtests/business/${businessId}/filesrequesttemplates/`
    actions.fetchAndReturn(token, endpoint).then(response => {
      if (response.status === 200) {
        shortDispatch('UPDATE_FILES_REQUESTS', { key: 'allEvaluations', value: response.body.results});
      };
    });
  };

  const getCurrentFileRequests = (jobAppId) => {
    let endpoint = `/api/v1/gamesandtests/jobapplications/${jobAppId}/filesrequests/`
    actions.fetchAndReturn(token, endpoint).then(response => {
      if (response.status === 200) {
        shortDispatch(
          'UPDATE_FILES_REQUESTS',
          {
            key: 'currentEvaluations',
            value: response.body.results.map((res) => ({...res, stage: res.assigned_activity.stage })),
          }
        );
      };
    });
  };

  // TODO deprecar ikits
  const fetchInterviewKits = (jobAppId) => {
    const endpointJobapp = `/api/v1/evaluations/jobapplication/${jobAppId}/interviewkit/`;
    if (state.interviewKits.toAdd.length) {
      let body = { interview_kits: state.interviewKits.toAdd };
      actions.fetchAndReturn(token, endpointJobapp, 'POST', body)
    }
    if (state.interviewKits.toRemove.length) {
      let body = { interview_kits: state.interviewKits.toRemove };
      actions.fetchAndReturn(token, endpointJobapp, 'DELETE', body);
    }
  };

  const updateCurrentStage = (value) => shortDispatch('UPDATE_CURRENT_STAGE', value);

  const getBusinessConfiguration = () => {
    let endpoint = `/api/v1/accounts/business/${businessId}/`;
    actions.fetchAndReturn(token, endpoint).then(response => {

      // Si esto sigue creciendo es mejor usar el HYDRATE y actualizar todo de una
      shortDispatch('UPDATE_BUSINESS', { key: 'colorTheme', value: response.body.color_theme })
      shortDispatch('UPDATE_BUSINESS', { key: 'logo', value: response.body.logo })
      shortDispatch('UPDATE_BUSINESS', { key: 'name', value: response.body.name })
      shortDispatch('UPDATE_BUSINESS', { key: 'businessInfo', value: response.body.business_info })
      shortDispatch('UPDATE_BUSINESS', { key: 'confidential_logo', value: response.body.confidential_logo })
      shortDispatch('UPDATE_BUSINESS', { key: 'confidential_name', value: response.body.confidential_name })
      shortDispatch('UPDATE_BUSINESS', { key: 'confidential_color_theme', value: response.body.confidential_color_theme })
      shortDispatch('UPDATE_BUSINESS', { key: 'confidential_background_image', value: response.body.confidential_background_image })
      shortDispatch('UPDATE_BUSINESS', { key: 'backgroundImage', value: response.body.background_image })
      shortDispatch('UPDATE_BUSINESS', { key: 'businessCells', value: response.body.business_cells })
    })
  }

  // Here we update the whole object
  const updateCandidateFormData = (newObj) => {
    const updateState = { candidateForm: { ...state.candidateForm, candidateFormData: { ...newObj } } };
    shortDispatch('HYDRATE_STATE', updateState);
  };

  const updateCuTes = (key, newObj) => shortDispatch('UPDATE_CUTES', { key: key, value: newObj });

  const updateGames = (key, newObj) => shortDispatch('UPDATE_GAMES', { key: key, value: newObj });

  const updateVideoInterviews = (key, newObj) => shortDispatch(
    'UPDATE_VIDEO_INTERVIEWS', { key: key, value: newObj },
  );

  const updateInterviewProcesses = (key, newObj) => shortDispatch(
    'UPDATE_INTERVIEW_PROCESSES', { key: key, value: newObj },
  );

  // TODO deprecar ikits
  const updateInterviewKits = (key, newObj) => shortDispatch(
    'UPDATE_IKITS', { key: key, value: newObj },
  );

  const updateAdditionalQuestions = (key, newObj) => shortDispatch(
    'UPDATE_ADDITIONAL_QUESTIONS', { key: key, value: newObj },
  );

  const updateFilesRequests = (key, newObj) => shortDispatch(
    'UPDATE_FILES_REQUESTS', { key: key, value: newObj },
  );
  
  const updateTriggers = (key, newObj) => {
    shortDispatch('UPDATE_TRIGGERS', { key: key, value: newObj });
  }

  const getCountries = () => {
    const url = '/api/v1/constants/countries/?p=[country,translation,iso_2]';
    const international = {
      "country": "International",
      "translation": {
        "en": "International",
        "es": "Internacional",
        "pt": ""
      },
      "iso_2": "INT"
    };
    actions.fetchAndReturn('', url, 'GET').then((response) => setCountries([...response.body, international]));
  };

  useEffect(() => {
    getCandidates();
    getCountries();
    getCurrentAssignedActivities();
    getAdditionalQuestionsTemplates();
    getCustomTests();
    getBusinessConfiguration();
    getActivities();
    getDefaultCustomFields();
    getIntegrations();
    // TODO deprecar ikits
    getInterviewKits();
    getAllFilesRequests();

    if (props.match.params['jobappid']) {
      const updateState = { formType: 'EDIT' };
      shortDispatch('HYDRATE_STATE', updateState);
      getCurrentInterviewKits()
      shortDispatch('UPDATE_JOB_APPLICATION', { key: 'id', value: props.match.params['jobappid'] })
      getMeasuredTraits(props.match.params['jobappid']);
      getCurrentFileRequests(props.match.params['jobappid'])
    } else {
      const updateState = { formType: 'NEW' };
      shortDispatch('HYDRATE_STATE', updateState);
    }
    if (props.addOns) shortDispatch('UPDATE_BUSINESS', { key: 'addOns', value: props.addOns });

    // Revisar esto, celulas vienen vacias en permissions desde el redux
    // shortDispatch('UPDATE_BUSINESS', { key: 'businessCells', value: businessPermissions.business_cells })
    shortDispatch('UPDATE_BUSINESS', { key: 'id', value: businessId })
    // TODO checkear dependencias
    return () => {};
  }, []);

  const updateStages = (updatedStages) => {
    shortDispatch('UPDATE_STAGES', updatedStages);
  };

  const updateStage = (updatedStage, idx) => {
    const updatedStages = [...state.stages];
    updatedStages[idx] = updatedStage;
    shortDispatch('UPDATE_STAGES', updatedStages);
  };

  const addStage = (newStage, idx) => {
    const updatedStages = [...state.stages];
    updatedStages.splice(idx, 0, newStage);
    shortDispatch('UPDATE_STAGES', updatedStages);
  };

  const getActivities = () => {
    let endpoint = `/api/v1/gamesandtests/business/${businessId}/activities/`;
    actions.fetchAndReturn(token, endpoint).then(response => {
      if (response.status === 200) {
        const results = response.body.results
        shortDispatch('UPDATE_GAMES',
          { key: 'allEvaluations', value: results.filter(obj => obj.code !== 'CUTE' && obj.code !== 'KO' && obj.code !== 'FORM') }
        );
        shortDispatch('UPDATE_ACTIVITIES', results);
        shortDispatch('UPDATE_ADDITIONAL_QUESTIONS', { key: 'activityId', value: results.find(obj => obj.code === 'KO')?.id });
        shortDispatch('UPDATE_CUTES', { key: 'activityId', value: results.find(obj => obj.code === 'CUTE')?.id });

        shortDispatch('UPDATE_FORM', { key: 'activityId', value: results.find(obj => obj.code === 'FORM')?.id });
        shortDispatch('UPDATE_FILES_REQUESTS', { key: 'activityId', value: results.find(obj => obj.code === 'DOCR')?.id });
        shortDispatch('UPDATE_FORM', { key: 'translation', value: results.find(obj => obj.code === 'FORM')?.translation });
      }
    })
  };

  const getIntegrations = () => {
    let jobAppIntegrations = [];

    if (props.match.params['jobappid']) {
      const jobAppIntegrationsEndpoint = `/api/v2/business/jobapplications/${props.match.params['jobappid']}/integrations/`;
      actions.fetchAndReturn(token, jobAppIntegrationsEndpoint).then(response => {
        if (response.status === 200) {
          jobAppIntegrations = response.body.results
          shortDispatch('UPDATE_INTEGRATIONS', { key: 'jobApplicationIntegrations', value: jobAppIntegrations });
        }

        const businnessIntegrationsEndpoint = `/api/v2/business/${businessId}/integrations/`;
        actions.fetchAndReturn(token, businnessIntegrationsEndpoint).then(response => {
          if (response.status === 200) {
            const results = response.body.results
            jobAppIntegrations.forEach(jobAppIntegration => {
              const name = jobAppIntegration.name;
              // Find if an integration (form) matches with current integrations.
              const tmpIntegration = results.find((x) => x.name === name);
              // Autocomplete integration fields with data from jobapp.
              if (tmpIntegration && tmpIntegration.fields.forEach) {
                tmpIntegration.fields.forEach(integrationField => {
                  integrationField.value = jobAppIntegration.fields[integrationField.name];
                });
              }
            })
            const activeIntegrations = results.filter(obj => obj.status !== 'INACT')
            shortDispatch('UPDATE_INTEGRATIONS', { key: 'businessIntegrations', value: activeIntegrations });
          }
        });

      });
    }

  };

  const updateIntegrations = (integrationKey, value) => {
    shortDispatch('UPDATE_INTEGRATIONS', { key: integrationKey, value: value });
  };

  const getMeasuredTraits = (jobAppId) => {
    const endpoint = `/api/v1/genomes/jobapplications/${jobAppId}/measuredtraits/`
    actions.fetchAndReturn(token, endpoint).then(response => {
      if (response.status === 200) {
        const results = response.body.results
        shortDispatch('UPDATE_MEASURED_TRAITS', { key: 'current', value: structuredClone(results) });
        shortDispatch('UPDATE_MEASURED_TRAITS', { key: 'originalMTraits', value: structuredClone(results) });
      }
    })
  };

  // 1 M trait
  const updateMeasuredTrait = (measuredTrait) => {
    const updatedMeasuredTraits = [...state.measuredTraits.current];
    const idx = updatedMeasuredTraits.findIndex(obj => obj.trait.id === measuredTrait.trait.id);
    updatedMeasuredTraits[idx] = measuredTrait;
    shortDispatch('UPDATE_MEASURED_TRAITS', { key: 'current', value: updatedMeasuredTraits });
  };

  // Bulk
  const updateMeasuredTraits = (updatedMeasuredTraits, key) => {
    shortDispatch('UPDATE_MEASURED_TRAITS', { key: key, value: updatedMeasuredTraits });
  };


  const getRelevanceMeasuredTrait = (currentMeasuredTrait, newTotal) => {
    if (currentMeasuredTrait && newTotal !== 0) {
      const newRelevance = Math.round((currentMeasuredTrait['points'] * 100 / newTotal) * 100) / 100;
      return newRelevance;
    } else {
      return 0;
    }
  };

  const addMeasuredTrait = (trait) => {
    const newMTrait = buildMeasuredTrait(trait, state.jobApplication.genome?.id);
    state.measuredTraits.current.push(newMTrait);
    shortDispatch('UPDATE_MEASURED_TRAITS', { key: 'current', value: state.measuredTraits.current });
  };

  const removeMeasuredTrait = (trait) => {
    const idx = state.measuredTraits.current.findIndex(obj => obj.trait.id === trait.id);
    const diff = -state.measuredTraits.current[idx].points;

    state.measuredTraits.current.splice(idx, 1);

    const newTotalPoints = state.measuredTraits.current.reduce((acc, obj) => acc + obj.points, 0);
    // Actualizamos la relevancia en cadena
    state.measuredTraits.current.forEach(obj => {
      obj['relevance'] = getRelevanceMeasuredTrait(obj, newTotalPoints + diff);
    });
    shortDispatch('UPDATE_MEASURED_TRAITS', { key: 'current', value: state.measuredTraits.current });
  };

  const getDefaultCustomFields = () => {
    const url = `/api/v1/gamesandtests/business/${businessId}/customfields/?field_type=JOBAPP`;
    actions.fetchAndReturn(token, url)
      .then((response) => {
        if (response.status < 400) {
          shortDispatch('HYDRATE_STATE', { defaultCustomFields: response.body.results });
        }
      });
  };

  const updateCustomFields = (customFields) => {
    shortDispatch('UPDATE_JOB_APPLICATION', { key: 'custom_fields', value: customFields });
  };

  const getCurrentInterviewKits = () => {
    const endpoint = `/api/v1/evaluations/jobapplication/${props.match.params['jobappid']}/interviewkit/`
    actions.fetchAndReturn(token, endpoint)
      .then(response => {
        if (response.status < 400) {
          shortDispatch('UPDATE_IKITS', { key: 'currentEvaluations', value: response.body });
        }
      })
  }

  const getAdditionalQuestionsTemplates = () => {
    let endpoint = `/api/v1/gamesandtests/business/${businessId}/questiontemplates/`;
    // Response come paginated
    // TODO if next != null  store the next page
    actions.fetchAndReturn(token, endpoint).then(response => {
      if (response.status === 200) {
        shortDispatch('UPDATE_ADDITIONAL_QUESTIONS', { key: 'allEvaluations', value: response.body.results });
      }
    })
  };

  const saveQuestionTemplate = (newTemplate) => {
    console.log(newTemplate, 'new')
    const url = `/api/v1/gamesandtests/business/${businessId}/questiontemplates/`;

    actions.fetchAndReturn(token, url, 'POST', newTemplate).then((response) => {
      if (response.status >= 400) {
        message.error('Error al guardar plantilla');
        return;
      };
      message.success('Plantilla guardada correctamente');
      shortDispatch('UPDATE_ADDITIONAL_QUESTIONS', { key: 'allEvaluations', value: [...state.additionalQuestions.allEvaluations, response.body] });
    });
  };

  const removeQuestionTemplate = (templateId) => {
    const url = `/api/v1/gamesandtests/business/${businessId}/questiontemplates/${templateId}/`;
    actions.fetchAndReturn(token, url, 'DELETE').then((response) => {
      if (response.status >= 400) {
        message.error('Error al eliminar plantilla');
        return;
      };
      message.success('Plantilla eliminada correctamente');

      const updatedQuestions = [...state.additionalQuestions.allEvaluations];
      const idx = updatedQuestions.findIndex(obj => obj.id == templateId);
      updatedQuestions.splice(idx, 1);
      shortDispatch('UPDATE_ADDITIONAL_QUESTIONS', { key: 'allEvaluations', value: [...updatedQuestions] });
    });
  };

  const removeStage = (idx) => {
    const updatedStages = [...state.stages];
    updatedStages.splice(idx, 1);
    const stageId = updatedStages[idx].id;
    // we remove triggers if the stage is removed
    const updatedCurrentTriggers = state.triggers.currentTriggers.filter(obj => obj.stage.id !== stageId);
    const updatedToAddTrigger = state.triggers.toAdd.filter(obj => obj.stage.id !== stageId);
    const updatedToRemoveTrigger = state.triggers.toRemove.filter(obj => obj.stage.id !== stageId);
    shortDispatch('UPDATE_TRIGGERS', { key: 'currentTriggers', value: updatedCurrentTriggers });
    shortDispatch('UPDATE_TRIGGERS', { key: 'toAdd', value: updatedToAddTrigger });
    shortDispatch('UPDATE_TRIGGERS', { key: 'toRemove', value: updatedToRemoveTrigger });
    shortDispatch('UPDATE_STAGES', updatedStages);
  };

  const showErrors = (type, errors) => {
    Modal.error({
      title: 'Error al guardar los datos',
      content:
        <>
          {type === 'validation' ?
            <>
              <div style={{ marginBottom: 10 }}>Datos requeridos:</div>
              {errors.map((elem, idx) =>
                <div key={idx}>
                  {`• ${elem}`}
                </div>
              )}
            </> :
            <>
              <div style={{ marginBottom: 10 }}>{`Ocurrió un error al momento de guardar los datos`}</div>
            </>
          }
        </>,
      okText: 'Ok',
    });
  };

  const addFormActivityRequest = async (jobappId) => {
    const assignedActUrl = `/api/v1/gamesandtests/jobapplications/${jobappId}/activities/`;
    const body = {
      activity_id: state.candidateForm.activityId,
      // Siempre se agrega a la 1ra etapa
      stage: state.stages[0].id,
      job_application_id: jobappId,
      configuration: JSON.stringify({}),
    }
    const continueRequest = await actions.fetchAndReturn(token, assignedActUrl, 'POST', body).then((response) => {
      if (response.status >= 400) {
        message.error('Error al configurar datos del formulario');
        setSendingData(false);
        return false;
      }
      else {
        return true;
      };
    });
    return continueRequest;
  };

  const jobappBannerRequest = async (jobappId) => {
    // Si esta indefinida no se ha seteado iamgen por proceso aun, si es string se ha seteado imagen pero ya esta en s3
    if (state.jobApplication.backgroundImage == undefined || typeof (state.jobApplication.backgroundImage) === 'string') {
      return true;
    };

    // Usamos otra accion
    const jobAppBannerUrl = `gamesandtests/jobapplication/${jobappId}/banner/`;
    const fileObj = state.jobApplication.backgroundImage.fileObj;
    const continueRequest = await actions.sendFile(token, jobAppBannerUrl, fileObj, fileObj.name).then((response) => {
      if (response.status >= 400) {
        message.error('Error al actualizar imagen del proceso');
        setSendingData(false);
        continueRequest = false;
        return false;
      }
      return true
    });
    return continueRequest;
  };

  const assignedActivityRequest = async (jobappId, method, data, type) => {
    const assignedActUrl = `/api/v1/gamesandtests/jobapplications/${jobappId}/activities/`;
    const promiseArray = [];
    data.forEach(obj => {
      let assignedActivityId = findAssignedActivity(state.assignedActivities, obj, type);
      assignedActivityId = assignedActivityId == null ? '' : assignedActivityId + '/';
      const tmpPromise = actions.fetchAndReturn(token, assignedActUrl + assignedActivityId, method, obj);
      promiseArray.push(tmpPromise);
    });
    return await Promise.all(promiseArray).then((_responses) => {
      return true
    }).catch((_error) => {
      message.error('Error al guardar datos de actividades');
      return false;
    });

  };

  const videoInterviewsRequest = async (method, interview, jobappId, stage) => {
    // Creamos patcheamos o borramos una VE
    const videoId = interview.id ? interview.id : '';
    const continueRequest = await actions.fetchAnyUrl(token, videoInterviewUrl + videoId, method, interview).then((response) => {
      // Deuda tecnica, con el delete response es undefined a pesar de que se borra correctamente
      if (method !== 'DELETE' && response.status >= 400 ) {
        message.error('Error al guardar video entrevista');
        setSendingData(false);
        return false;
      }
      const vintActivityId = state.activities.find(obj => obj.code === 'VINT')?.id;
      const vintAssignedActivityId = state.assignedActivities.find(obj => obj.activity.code === 'VINT')?.id;
      // Patchear la job app con el campo interview_id (solo si es nuevo) y crear assigned activity para la VE
      const jobappUrl = `/api/v1/gamesandtests/jobapplications/${jobappId}/`;
      let assignedActUrl = `/api/v1/gamesandtests/jobapplications/${jobappId}/activities/`;
      assignedActUrl = method === 'POST' ? assignedActUrl : assignedActUrl + vintAssignedActivityId + '/'

      if (method === 'POST') {
        const vintId = response.id;
        actions.fetchAndReturn(token, jobappUrl, 'PATCH', { 'interview_id':  vintId});
        const assignedInterview = {
          configuration: JSON.stringify({ 'id': vintId }),
          stage: stage,
          job_application_id: jobappId,
          activity_id: vintActivityId,
        };
        actions.fetchAndReturn(token, assignedActUrl, method, assignedInterview);
      }
      else if (method === 'PATCH') {
        const assignedInterview = {
          stage: stage,
        };
        actions.fetchAndReturn(token, assignedActUrl, method, assignedInterview);
      }
      else if (method === 'DELETE') {
        actions.fetchAndReturn(token, jobappUrl, 'PATCH', { 'interview_id': null });
        actions.fetchAndReturn(token, assignedActUrl, method);
      }
      // En el patch no se cambia el id de la VE, ergo no es necesario actualizar la assigned activity

      return true;
    });
    return continueRequest;
  };

  const interviewProcessRequest = async (interviewProcessesActivities, jobappId) => {
    const teraActivityId = state.activities.find(obj => obj.code === 'TERA')?.id;
    // Creamos patcheamos o borramos una VE
    interviewProcessesActivities.toAdd.map(interviewProcessAct => {
      const method = 'POST';
      const assignedActUrl = `/api/v1/gamesandtests/jobapplications/${jobappId}/activities/`;
      const assignedInterview = {
        configuration: JSON.stringify({ 'id': interviewProcessAct.configuration.id }),
        stage: interviewProcessAct.stage,
        job_application_id: jobappId,
        activity_id: teraActivityId,
      };
      actions.fetchAndReturn(token, assignedActUrl, method, assignedInterview);
    })

    interviewProcessesActivities.toUpdate.map(interviewProcessAct => {
      const method = 'PATCH';
      const assignedActUrl = `/api/v1/gamesandtests/jobapplications/${jobappId}/activities/${interviewProcessAct.id}/`;
      const assignedInterview = {
        stage: interviewProcessAct.stage,
      };
      actions.fetchAndReturn(token, assignedActUrl, method, assignedInterview);
    })

    interviewProcessesActivities.toRemove.map(interviewProcessAct => {
      const method = 'DELETE';
      const assignedActUrl = `/api/v1/gamesandtests/jobapplications/${jobappId}/activities/${interviewProcessAct.id}/`;
      actions.fetchAndReturn(token, assignedActUrl, method);
    })

    return true;
  };

  const triggersRequest = async (jobappId, method, triggers) => {
    const triggersUrl = `/api/v1/triggers/${jobappId}/triggers/`
    try {
      triggers.forEach(async obj => {
        let url = method === 'POST' ? triggersUrl : triggersUrl + obj.id + '/';
        let triggerActionsData = [...obj.primaryActions, ...obj.secondaryActions];
        delete obj.primaryActions;
        delete obj.secondaryActions;
        delete obj.id;
        const response = await actions.fetchAndReturn(token, url, method, obj);
        if (response.status >= 400) {
          message.error('Error al guardar datos de triggers');
          setSendingData(false);
          return false;
        }
        if (method === 'POST') {
          const triggerId = response.body.id;
          const actionUrl = `/api/v1/triggers/${jobappId}/triggers/${triggerId}/actions/`;
          // after save trigger, save actions
          triggerActionsData.forEach(async action => {
            const actionResponse = await actions.fetchAndReturn(token, actionUrl, method, action);
            if (actionResponse.status >= 400) {
              message.error('Error al guardar acciones de triggers');
              setSendingData(false);
              return false;
            }})
          }
      });
    }
    catch (error) {
      message.error('Error al guardar datos de triggers');
      return false;
    }
  return true;
  };
  
  const updateMeasuredTraitsRequest = async (jobappId, jobappGenomaId = null) => {
    const url = `/api/v1/genomes/jobapplications/${jobappId}/bulkmeasuredtraits/`;

    let updateScore = false;
    const mTraitsToDelete = getMTraitsToDelete(state.measuredTraits.originalMTraits, state.measuredTraits.current, jobappGenomaId);
    if (mTraitsToDelete.length > 0) {
      const responseDelete = await actions.fetchAndReturn(token, url, 'DELETE', mTraitsToDelete);
      if (responseDelete.status >= 400) {
        message.error('Error al configurar los rasgos');
        return false;
      };
      updateScore = true;
    }

    // Nunca se agregan m trait, solo update porque los juegos se crean con los traits en 0, luego update considera los simple hace un update sobre 
    // points y las relevances de los agregados
    const mTraitsToUpdate = getMTraitsToUpdate(state.measuredTraits.originalMTraits, state.measuredTraits.current, jobappGenomaId);
    if (mTraitsToUpdate.length > 0) {
      const responseUpdate = await actions.fetchAndReturn(token, url, 'PATCH', mTraitsToUpdate);
      if (responseUpdate.status >= 400) {
        message.error('Error al configurar los rasgos');
        return false;
      };
      updateScore = true;
    }

    // If Measured traits changed, update scores
    
    if (updateScore){
      actions.fetchAndReturn(token, '/api/v1/gamesandtests/updatefinalscore/', 'POST', { 'job_application_id': jobappId });
    }
    return true;
  };

  const updateIntegrationsRequest = async (jobappId) => {
    if (state.integrations.update == false) {
      return true;
    };
    if (state.integrations.jobApplicationIntegrations.length !== 0) {
      state.integrations.jobApplicationIntegrations.map((integration) => {

        const endpointIntegrations = `/api/v2/business/jobapplications/${jobappId}/integrations/`;
        const body = integration
        body['integration'] = body.integration.id
        actions.fetchAndReturn(token, endpointIntegrations, 'post', body);
      })
    }

    const jobAppIntegrationsEndpoint = `/api/v2/business/jobapplications/${jobappId}/integrations/`;
    actions.fetchAndReturn(token, jobAppIntegrationsEndpoint).then(response => {
      if (response.status < 400) {
        const tmpJobAppIntegrations = response.body.results
        tmpJobAppIntegrations.map((integration) => {
          if (!state.integrations.jobApplicationIntegrations.map(obj => obj.integration).includes(integration.integration.id)) {
            const deleteEndpointIntegrations = `/api/v2/business/jobapplications/${jobappId}/integrations/${integration.integration.id}/`
            actions.fetchAndReturn(token, deleteEndpointIntegrations, 'delete');
          }
        })
      };
    });

    return true;
  };


  // EDIT or NEW
  const createUpdateJobapp = async () => {
    // If new process, this var is undefined
    let jobappId = state.jobApplication.id;
    let jobappGenomeId = state.jobApplication.genome?.id;

    const cell = state.business.businessCells.find(obj => obj.type === 'SLCCN' && obj.name.toLowerCase().includes('default'));
    // Format the data in the state, return an object the django serializer keys
    const jobAppData = processRequest(state.business, state.jobApplication, state, 'jobapplication');

    let continueRequest = true;

    let jobappUrl;
    if (state.formType === 'EDIT') {
      jobappUrl = `/api/v1/gamesandtests/jobapplications/${jobappId}/`;
    }
    else {
      jobappUrl = `/api/v1/gamesandtests/businesscells/${cell.id}/jobapplications/`;

      // Si el proceso es nuevo creamos el genome 1ro, luego usamos el id para crear el job app
      // Esto es el nombre jobAppData.job_application en la db :/
      const genomeBody = { 'genome': jobAppData.job_application, 'description': '', 'category': 'ARTIF', 'active': true };
      continueRequest = await actions.fetchAndReturn(token, '/api/v1/genomes/genome/', 'POST', genomeBody).then((response) => {
        if (response.status >= 400) {
          message.error('Error al guardar los datos del proceso');
          setSendingData(false);
          return false;
        }
        else {
          // Este es el body que mandaremos en caso de crear la jobapp
          jobAppData.genome_id = response.body.id;
          // Este id se usa para patchear los measured traits
          jobappGenomeId = response.body.id;
          return true;
        };
      });
    }
    if (!continueRequest) return;

    const jobappRequestMethod = state.formType === 'EDIT' ? 'PATCH' : 'POST';

    // Save update jobapp
    continueRequest = await actions.fetchAndReturn(token, jobappUrl, jobappRequestMethod, jobAppData).then((response) => {
      if (response.status >= 400) {
        message.error('Error al guardar los datos del proceso');
        setSendingData(false);
        return false;
      }
      else {
        // Si el proceso es nuevo, actualizamos el id
        if (state.formType === 'NEW') {
          jobappId = response.body.id;
          shortDispatch('UPDATE_JOB_APPLICATION', { key: 'id', value: jobappId });
        }
        return true;
      };
    });
    if (!continueRequest) return;

    // Check if job app bg image changed
    continueRequest = await jobappBannerRequest(jobappId);
    if (!continueRequest) return;

    // Save update FORM data
    if (state.formType === 'NEW') {
      continueRequest = await addFormActivityRequest(jobappId);
      if (!continueRequest) return;
    }
    // TODO revisar
    else if (state.formType === 'EDIT') {
      // Si Assigned activity FORM no esta en la 1ra etapa (eg: se agrego la etapa initial, luego se form se corrio una etapa)
      const assignedForm = state.assignedActivities.find(obj => obj.activity.code === 'FORM');
      const stageFound = state.stages[0].id === assignedForm?.stage ? true : false;
      if (!stageFound && assignedForm) {
        const updatedAssignedForm = [{ stage: state.stages[0].id, id: assignedForm.id }];
        continueRequest = await assignedActivityRequest(jobappId, 'PATCH', updatedAssignedForm, 'form');
        if (!continueRequest) return;
      }
    };

    /**
    * Save, update or delete, games
    */
    let gamesData;
    if (state.games.toAdd.length > 0) {
      gamesData = processRequest(state.business, state.jobApplication, { gamesData: state.games.toAdd, jobappId: jobappId }, 'games');
      continueRequest = await assignedActivityRequest(jobappId, 'POST', gamesData, 'game');
      if (!continueRequest) return;
    }
    if (state.games.toUpdate.length > 0) {
      gamesData = processRequest(state.business, state.jobApplication, { gamesData: state.games.toUpdate, jobappId: jobappId }, 'games');
      continueRequest = await assignedActivityRequest(jobappId, 'PATCH', gamesData, 'game');
      if (!continueRequest) return;
    }
    if (state.games.toRemove.length > 0) {
      gamesData = processRequest(state.business, state.jobApplication, { gamesData: state.games.toRemove, jobappId: jobappId }, 'games');
      continueRequest = await assignedActivityRequest(jobappId, 'DELETE', gamesData, 'game');
      if (!continueRequest) return;
    }

    /**
     * En este punto los juegos/assigned activities crearon y borraron sus respectivos traits, luego basta hacer un bulk update
     */
    continueRequest = await updateMeasuredTraitsRequest(jobappId, jobappGenomeId);
    if (!continueRequest) return;

    // Save update additional questions, now each stage can have independent additional questions
    let questionsData;
    // Primero removemos ya que luego podriamos sobre escribir el mismo campo configuration
    if (state.additionalQuestions.toRemove.length > 0) {
      questionsData = processRequest(state.business, state.jobApplication, { activityId: state.additionalQuestions.activityId, questionsData: state.additionalQuestions.toRemove, jobappId: jobappId }, 'questions');
      continueRequest = await assignedActivityRequest(jobappId, 'DELETE', questionsData, 'question');
      if (!continueRequest) return;
    }
    if (state.additionalQuestions.toAdd.length > 0) {
      questionsData = processRequest(state.business, state.jobApplication, { activityId: state.additionalQuestions.activityId, questionsData: state.additionalQuestions.toAdd, jobappId: jobappId }, 'questions');
      continueRequest = await assignedActivityRequest(jobappId, 'POST', questionsData, 'question');
      if (!continueRequest) return;
    }
    if (state.additionalQuestions.toUpdate.length > 0) {
      questionsData = processRequest(state.business, state.jobApplication, { activityId: state.additionalQuestions.activityId, questionsData: state.additionalQuestions.toUpdate, jobappId: jobappId }, 'questions');
      continueRequest = await assignedActivityRequest(jobappId, 'PATCH', questionsData, 'question');
      if (!continueRequest) return;
    }

    /**
    * Save, update or delete, custom tests
    */
    // Save update custom tests, assigned activities without traits + configuration
    let cutesData;
    if (state.cuTes.toAdd.length > 0) {
      cutesData = processRequest(state.business, state.jobApplication, { activityId: state.cuTes.activityId, cuTes: state.cuTes.toAdd, jobappId: jobappId }, 'cutes');
      continueRequest = await assignedActivityRequest(jobappId, 'POST', cutesData, 'cute');
      if (!continueRequest) return;
    }
    if (state.cuTes.toUpdate.length > 0) {
      cutesData = processRequest(state.business, state.jobApplication, { activityId: state.cuTes.activityId, cuTes: state.cuTes.toUpdate, jobappId: jobappId }, 'cutes');
      continueRequest = await assignedActivityRequest(jobappId, 'PATCH', cutesData, 'cute');
      if (!continueRequest) return;
    }
    if (state.cuTes.toRemove.length > 0) {
      cutesData = processRequest(state.business, state.jobApplication, { activityId: state.cuTes.activityId, cuTes: state.cuTes.toRemove, jobappId: jobappId }, 'cutes');
      continueRequest = await assignedActivityRequest(jobappId, 'DELETE', cutesData, 'cute');
      if (!continueRequest) return;
    }
    /**
     * Save, update or delete, interview processes assigned activities
     */
    continueRequest = await interviewProcessRequest(state.interviewProcesses, jobappId);

    /**
     * Save update video interview
     */
    // We can only have 1 video interview for now
    if (state.videoInterviews.toAdd.length > 0) {
      const interviewToAdd = state.videoInterviews.toAdd[0]
      interviewToAdd['jobApplicationId'] = jobappId;
      const videoInterviewData = processRequest(state.business, state.jobApplication, interviewToAdd, 'videointerview');
      continueRequest = await videoInterviewsRequest('POST', videoInterviewData, jobappId, interviewToAdd.stage);
    }
    if (state.videoInterviews.toUpdate.length > 0) {
      const interviewToUpdate = state.videoInterviews.toUpdate[0]

      // TODO revisar si funciona el patch
      await videoInterviewsRequest('PATCH',
        {
          interview: {
            interviewTxt: interviewToUpdate.emailContent.content,
          },
          id: interviewToUpdate.id,
        },
        jobappId
        ,
        interviewToUpdate.stage
      );
      await interviewToUpdate.questions.forEach(async obj => {
        const interviewQuestionsUrl = `${process.env.REACT_APP_VIDEO_URL}/api/questions/`;
        if (obj.id){
          await actions.fetchAnyUrl(token, interviewQuestionsUrl + obj.id, 'PATCH', { text: obj.text, time: obj.time });
        }
        else{
          await actions.fetchAnyUrl(token, interviewQuestionsUrl, 'POST', { text: obj.text, time: obj.time, interviewId: interviewToUpdate.id});
        };
      });
    }
    /**
     * Save, delete triggers
     */
    let triggersData;
    if(state.triggers.toAdd.length > 0) {
      triggersData = processRequest(state.business, state.jobApplication, { triggers: state.triggers.toAdd, jobappId: jobappId }, 'triggers');
      continueRequest = await triggersRequest(jobappId, 'POST', triggersData);
      if (!continueRequest) return;
    }
    if(state.triggers.toRemove.length > 0) {
      triggersData = processRequest(state.business, state.jobApplication, { triggers: state.triggers.toRemove, jobappId: jobappId }, 'triggers');
      continueRequest = await triggersRequest(jobappId, 'DELETE', triggersData);
      if (!continueRequest) return;
    }

    if (state.videoInterviews.toRemove.length > 0) {
      const interviewToRemove = state.videoInterviews.toRemove[0]
      // No vale la pena borrar la instancia en la DB de la api de VE, simplemente la borramos de la jobapp y eliminamos la assigned act si existe
      continueRequest = await videoInterviewsRequest('DELETE', { id: interviewToRemove.id }, jobappId, interviewToRemove.stage);
    }

    if (state.filesRequests.toAdd.length > 0) {
      await filesRequestsRequest(jobappId, 'POST', state.filesRequests.toAdd);
    }

    if (state.filesRequests.toUpdate.length > 0) {
      const filesRequestsData = processRequest(state.business, state.jobApplication, { activityId: state.filesRequests.activityId, filesRequests: state.filesRequests.toUpdate, jobappId: jobappId }, 'filesRequests');
      continueRequest = await assignedActivityRequest(jobappId, 'PATCH', filesRequestsData, 'filesRequests');
      if (!continueRequest) return;
    }
    if (state.filesRequests.toRemove.length > 0) {
      await filesRequestsRequest(jobappId, 'DELETE', state.filesRequests.toRemove);
    }
    continueRequest = await updateIntegrationsRequest(jobappId)

    // TODO deprecar ikits
    fetchInterviewKits(jobappId);

    // Usamos el time out solo para que el se vea que el modal esta cargando a veces la creación es casi instantanea y 
    // queremos dar un feed back de que realmente se esta procesando, en realidad esto no es necesario
    setTimeout(() => {
      setSendingData(false);
      setShowShareProcessModal(true);
    }, '500');
  };

  const submitData = async () => {
    const errors = validateData(state.jobApplication);
    if (errors.length > 0) {
      showErrors('validation', errors);
    }
    else {
      setSendingData(true);
      createUpdateJobapp();
    };
  };

  const goTo = (path) => {
    props.dispatch(push(path));
  };

  const saveFileRequestTemplate = (formData, method, id) => {
    const url = `/api/v1/gamesandtests/business/${
      businessId
    }/filesrequesttemplates/${
      id ? `${id}/` : ""
    }`;
    fetch(`${SERVER_URL}${url}`, {
      method: method,
      headers: {
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
      body: formData,
    }).then((response) => {
        if (response.status >= 400) {
          message.error("Cambios no pudieron ser guardados");
        } else {
          message.success("Plantilla guardada con éxito");
          getAllFilesRequests();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        message.error("Cambios no pudieron ser guardados");
      });
    }

  const filesRequestsRequest = (jobAppId, method, data) => {
    try {
      if (['POST', 'PATCH'].includes(method)){
        data.map((obj) => {
          let formData = new FormData();
          const newQuestions = JSON.stringify(
            obj.questions.filter((q) => q.name !== undefined)
          );

          const fileNames = Object.keys(obj).filter((object) => ![
            'id',
            'assigned_activity',
            'old_files', 
            'name', 
            'questions', 
            'stage', 
            'instruction', 
            'instruction_files',
            'template', 
            'job_application', 
            'dateCreated'
          ].includes(object));
          fileNames.map((fileName) => {
            if (!obj[fileName].business) {
              formData.append(fileName, obj[fileName]);
            } 
          });
          if (obj.template) {
            formData.append("template", obj.template);
          }
          formData.append("old_files", obj.old_files);
          formData.append("name", obj.name);
          formData.append("instruction", obj.instruction);
          formData.append("questions", newQuestions);
          formData.append("stage", obj.stage);
          const url = `/api/v1/gamesandtests/jobapplications/${
            jobAppId
          }/filesrequests/${
            method =='PATCH' ? `${obj.id}/` : ""
          }`;
          fetch(`${SERVER_URL}${url}`, {
            method: method,
            headers: {
              Accept: "application/json",
              Authorization: `Token ${token}`,
            },
            body: formData,
          })
            .then(() => getAllFilesRequests())
            .catch((error) => Sentry.captureException(error));
        })
      } else {
        data.map((obj) => {
          const url = `/api/v1/gamesandtests/jobapplications/${jobAppId}/filesrequests/${obj.id}/`;
          fetch(`${SERVER_URL}${url}`, {
            method: method,
            headers: {
              Accept: "application/json",
              Authorization: `Token ${token}`,
            },
          })
            .then(() => getAllFilesRequests())
            .catch((error) => Sentry.captureException(error));
        });
      }
    } catch (e) {
      message.error("Error al guardar las actividades");
    }
  }

  const updateJobApplication = (key, newObj) => {
    if (key == 'backgroundImage') {
      if (newObj == '') {
        shortDispatch('UPDATE_JOB_APPLICATION', { key: key, value: state.business.backgroundImage });
      }
      else {
        shortDispatch('UPDATE_JOB_APPLICATION', { key: key, value: newObj });
      }
    }
    else {
      shortDispatch('UPDATE_JOB_APPLICATION', { key: key, value: newObj });
    }
  };

  const memoValues = useMemo(
    () => ({
      ...state,
      countries,
      isLoading,
      hasCandidates,
      addStage,
      removeStage,
      updateStage,
      addMeasuredTrait,
      removeMeasuredTrait,
      getRelevanceMeasuredTrait,
      updateMeasuredTrait,
      updateMeasuredTraits,
      updateCurrentStage,
      updateCandidateFormData,
      updateCuTes,
      updateGames,
      updateVideoInterviews,
      updateAdditionalQuestions,
      updateInterviewKits,
      updateInterviewProcesses,
      updateFilesRequests,
      saveFileRequestTemplate,
      updateCustomFields,
      saveQuestionTemplate,
      removeQuestionTemplate,
      goTo,
      submitData,

      token,
      businessPermissions,
      getBusinessConfiguration,
      updateJobApplication,
      updateStages,
      updateTriggers,
      updateIntegrations,
      sendingData,
      showShareProcessModal,
      setShowShareProcessModal
    }),
    // Dependencies observer
    [state, sendingData, showShareProcessModal]
  );

  return (
    <JobAppContext.Provider value={memoValues}>
      {children}
    </JobAppContext.Provider>
  );
};

const JobAppContext = React.createContext();

export default (JobAppContext);
