import React from 'react';
import FitProgress from '../../../../components/FitProgress.jsx';
import { Row, Typography, Col } from 'antd';
import propTypes from 'prop-types';

const { Paragraph } = Typography;

const Percentages = ({ candidates, scrollRefs }) => {
  return (
    <div className='fit-section-container' ref={scrollRefs}>
      <Row
        type='flex'
        align='middle'
        justify='center'
        className='fit-section-container__fit-section'
      >
        <Col span={2}>
          <Row
            type='flex'
            justify='start'
            className='fit-section-container__title'
          >
            <Paragraph strong>FIT</Paragraph>
          </Row>
        </Col>
        <Col span={19}>
          <Row type='flex' justify='space-around' style={{ margin: '40px 0' }}>
            {candidates.map((candidate) => (
              <Col key={candidate.id}>
                {candidate.final_score ? (
                  <FitProgress
                    percent={candidate.final_score}
                    type='circle'
                    width={92}
                  />
                ) : (
                  <Paragraph strong>Sin FIT</Paragraph>
                )}
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

Percentages.propTypes = {
  candidates: propTypes.array.isRequired,
};

export default Percentages;
