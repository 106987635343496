import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../actions/data';
import i18n from '../../i18n';

import { Col, Row, Form, Spin, message, Modal } from 'antd';
import './AccountInfoStyles.scss';

import Header from './components/Header';
import PersonalData from './components/PersonalData';
import PersonalDataDisabled from './components/PersonalDataDisabled';
import GenderDisability from './components/GenderDisability';
import Education from './components/Education';
import JobExperience from './components/JobExperience';
import UploadCv from './components/UploadCv';

const CV_API = process.env.REACT_APP_CV_API;

const meses = [
  i18n.t('form__january'),
  i18n.t('form__february'),
  i18n.t('form__march'),
  i18n.t('form__april'),
  i18n.t('form__may'),
  i18n.t('form__june'),
  i18n.t('form__july'),
  i18n.t('form__august'),
  i18n.t('form__september'),
  i18n.t('form__october'),
  i18n.t('form__november'),
  i18n.t('form__december'),
];

// TODO: Deprecar este componente cuando salga la nueva sección mi perfil
function AccountInfo(props) {
  const [buttonScreen, setButtonScreen] = useState('1');
  const [personalData, setPersonalData] = useState({
    loadingFile: false,
    fileList: [],
  });
  const [formError, setFormError] = useState(false);
  const [isData, setIsdata] = useState(false);

  const [birthDay, setBirthDay] = useState({
    year: undefined,
    month: undefined,
    day: undefined,
  });
  const [updateData, setUpdateData] = useState(true);
  const [countries, setCountries] =  useState([]);

  useEffect(() => {
    getCountries()
  }, []);

  const getCountries = () => {
    const url = '/api/v1/constants/countries/?p=[country,translation,phone_code,iso_2]';
    props.actions.fetchAndReturn('', url, 'GET').then((response) => setCountries(response.body));
  }

  const handleScreenChange = (e) => {
    setButtonScreen(e.target.value);
  };

  const onChangePersonalData = (name) => (event) => {
    if (name === 'phone' || name === 'name' || name === 'surname') {
      setPersonalData(
        (prevPersonalData) => ({ ...prevPersonalData, [name]: event.target.value })
      );
    } else {
      setPersonalData((prevPersonalData) => ({ ...prevPersonalData, [name]: event }));
    }
  };

  const checkErrors = (data) => {
    for (let key in data) {
      if (data[key] !== undefined) {
        return true;
      }
    }
    return false;
  };

  const getMonthNumber = (month) => {
    return meses.indexOf(month) + 1;
  };

  const getFormatedDate = () => {
    if (!birthDay.year || !birthDay.month || !birthDay.day) {
      return null;
    }
    return (
      birthDay.year + '-' + getMonthNumber(birthDay.month) + '-' + birthDay.day
    );
  };

  const uploadCV = (e) => {
    setPersonalData({ ...personalData, logoPic: e });
    let url = 'accounts/cvupload/';

    props.actions
      .sendFile(props.token, url, e, e.name)
      .then((response) => {
        setPersonalData({
          ...personalData,
          cvReady: true,
          loadingFile: false,
          cv: true,
        });

        const endpoint = `/parse?user_id=${props.userId}&file_name=${response.filename}`;
        const urlCV = `${CV_API}${endpoint}`;
        props.actions.fetchAnyUrl(props.token, urlCV, 'POST');
        message.success(i18n.t('form__cv__uploaded'));
      })
      .catch(() => {
        setPersonalData({
          ...personalData,
          loadingFile: false,
          cv: null,
          fileList: [],
        });
        Modal.error({
          centered: true,
          title: i18n.t('form__error__cv__1'),
          content:
            i18n.t(i18n.t('form__error__cv__1__content')),
        });
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setPersonalData({ ...personalData, loadingFile: true });
    const url = 'accounts/personal/';
    const token = props.token;

    let aux = {
      email: personalData.email,
      dni: personalData.dni.toString(),
      name: personalData.name,
      surname: personalData.surname,
      birth_date: getFormatedDate(),
      nationality: personalData.nationality,
      // marital_status: personalData.marital_status,
      country: personalData.country,
      phone_prefix: personalData.phone_prefix || '',
      phone: personalData.phone,
      gender: personalData.gender,
      has_disability: personalData.has_disability,
      disability_type: personalData.disabilityType,
      commune: personalData.commune,
      education_level: personalData.education_level || '',
    };
    const data = JSON.stringify(aux);
    const redirect = '/me';

    // Upload CV
    if (personalData.fileList && personalData.fileList.length > 0) {
      const file = personalData.fileList[0];
      const fileTypes = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ];

      if (file.originFileObj.size > 7340032) {
        setPersonalData({ ...personalData, fileList: [], loadingFile: false });
        Modal.error({
          centered: true,
          title: i18n.t('form__erorr__cv__2'),
          content:
            i18n.t('form__erorr__cv__2__content'),
        });
      } else if (!fileTypes.includes(file.originFileObj.type)) {
        setPersonalData({ ...personalData, fileList: [], loadingFile: false });
        Modal.error({
          centered: true,
          title: i18n.t('form__erorr__cv__3'),
          content:i18n.t('form__erorr__cv__3__content'),
        });
      } else {
        uploadCV(file.originFileObj);
      }
    }

    props.actions.sendDataAndRedirect(
      token,
      url,
      data,
      'PATCH',
      redirect,
      i18n.t('form__successfully_uploading')
    );
  };

  useEffect(() => {
    if (updateData) {
      const token = props.token;
      const url = 'accounts/personal/';
      props.actions
        .fetchData(token, url, '', 'get')
        .then((reponse) => {
          setIsdata(true);
          setPersonalData({ ...personalData, ...reponse });
          setUpdateData(false);
        })
        .catch((err) => {
          console.log('Error', err);
        });
    }
  }, [updateData]);

  return (
    <Form layout='horizontal' className='candidate-form-section' onSubmit={onSubmit}>
      <Spin spinning={!isData || updateData == true }>

        <Header
          buttonScreen={buttonScreen}
          handleScreenChange={handleScreenChange}
          personalData={personalData}
          formError={formError}
        />

        {buttonScreen === '1' ? (
          <>
            <Row type='flex' justify='center' gutter={20}>
              <Col xs={20} sm={20} md={14}>
                <PersonalDataDisabled
                  personalData={personalData}
                  onChangePersonalData={onChangePersonalData}
                />
                <PersonalData
                  personalData={personalData}
                  onChangePersonalData={onChangePersonalData}
                  setFormError={setFormError}
                  checkErrors={checkErrors}
                  setBirthDay={setBirthDay}
                  meses={meses}
                  birthDay={birthDay}
                  countries={countries}
                />
                <GenderDisability
                  personalData={personalData}
                  setPersonalData={setPersonalData}
                />
                <Education
                  personalData={personalData}
                  onChangePersonalData={onChangePersonalData}
                  setUpdateData={setUpdateData}
                />
                <JobExperience
                  personalData={personalData}
                  onChangePersonalData={onChangePersonalData}
                  setUpdateData={setUpdateData}
                />
                <UploadCv
                  personalData={personalData}
                  setPersonalData={setPersonalData}
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row type='flex' justify='center' gutter={20}>
              <Col xs={24} sm={24} md={24}>
                {
                  // TODO: Feedback v2
                }
              </Col>
            </Row>
          </>
        )}
      </Spin>
    </Form>
  );
}

const mapStateToProps = (state) => {
  return {
    data: state.data.data,
    userId: state.auth.userID,
    userType: state.auth.userType,
    isFetching: state.data.isFetching,
    error: state.data.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountInfo);
