import React, { useState, useMemo, useEffect } from 'react'
import {
  Row,
  Col,
} from 'antd';
import PropTypes from 'prop-types';

const colorPalette = [
  '#FFD6E7',
  '#FF85C0',
  '#EB2F96',
  '#9E1068',
  '#520339'
];

function FitRangeSelector(props) {
  const [selectedRanges, setSelectedRanges] = useState(Array(props.ranges.length).fill(0));
  const [ranges, setRanges] = useState([]);

  useEffect(() => {
    setSelectedRanges((lastState) => {
      // By default, select the middle
      let index = Math.floor(lastState.length/2);
      const { lowerScore } = props;
      const upperScore = props.upperScore || props.lowerScore; 
      let upperIndex;
      const newState = [...lastState];
      // If lowerScore exists, look for the selected interval
      if (props.lowerScore !== undefined) {
        index = 0;
        upperIndex = 0;
        props.ranges.forEach((score, i) => {
          if (score < 100 && score <= props.lowerScore) {
            index = i + 1;
          }
          if (score < 100 && score < upperScore) {
            upperIndex = i + 1;
          }
        });
        const onesArray = new Array(upperIndex - index + 1).fill(1);
        newState.splice(index, upperIndex-index+1, ...onesArray)
      } else {
        newState[index] = 1;
      }
      // Send scores
      props.onClickRange && props.onClickRange(lowerScore, upperScore);
      return newState;
    })
  }, [])

  useEffect(() => {
    let lowerBound = 0;
    const newRanges = [];
    props.ranges.forEach((upperBound, index) => {
      newRanges.push([lowerBound, upperBound])
      lowerBound = upperBound;
    })
    setRanges(newRanges);
  }, [props.ranges])

  const clickRange = (index) => {
    if (props.disabled !== undefined && props.disabled == true){
      return
    }
    // Left
    const rightIndex = index + 1
    const leftIndex = index - 1
    const newSelectedRanges = [...selectedRanges];
    const leftValue = newSelectedRanges[leftIndex] || 0
    const rightValue = newSelectedRanges[rightIndex] || 0
    if (leftValue === rightValue) {
      newSelectedRanges.fill(0);
    }
    if (newSelectedRanges[index] === 1){
      newSelectedRanges[index] = 0;
    } else {
      newSelectedRanges[index] = 1;
    }
    setSelectedRanges(newSelectedRanges);
    // look for lower and upper scores (bounds) and call callback
    let lowerBound;
    let upperBound;
    newSelectedRanges.forEach((value, index) => {
      if (value === 1) {
        if (lowerBound === undefined) {
          lowerBound = ranges[index][0];
        }
        upperBound = ranges[index][1]
      }
    })
    props.onClickRange && props.onClickRange(lowerBound, upperBound);
  }

  return (
    <>
      <Row type="flex" style={{ width: "100%", height: "50%" }}>
        {ranges.map((value, index) => {
          const [lowerBound, upperBound] = value;
          return (
            <Col
              key={index} 
              style={{ 
                width:`${upperBound-lowerBound}%`, 
                backgroundColor: colorPalette[index],
                border: selectedRanges[index] === 1 ? '3px solid #40A9FF' : ''
              }}
              onClick={() => !props.readOnly && clickRange(index)}
            />
          )
        })}
      </Row>
    </>
  )
}

FitRangeSelector.propTypes = {
  ranges: PropTypes.arrayOf(PropTypes.number).isRequired,
  onClickRange: PropTypes.func,
  lowerScore: PropTypes.number,
  upperScore: PropTypes.number,
  readOnly: PropTypes.bool
}
export default FitRangeSelector;
