import React, { useState } from 'react'
import {
  Typography,
  Row,
  Modal
} from 'antd';


const DisconnectIntegrationModal = ({
  disconnectIntegration,
  integration,
  visible,
  setVisible,
}) => {

  const handleSubmit = () => {
    disconnectIntegration(integration)
    setVisible(false, null)
  }
  return (
    <Modal
      width={400}
      centered={true}
      destroyOnClose={true}
      title={`Eliminar integración con ${integration.template.name}`}
      visible={visible}
      onOk={() => handleSubmit()}
      onCancel={() => setVisible(false)}
      okText="Confirmar"
      cancelText="Cancelar"
    >
      <Row gutter={[0, 20]}>
        <Typography.Text justify="space-around" style={{ fontSize: 12, marginRight: '1em' }}>
          Si confirmas esta acción este proceso no se encontrará vinculado a {integration.template.name}. ¿Estás seguro de esta acción?
        </Typography.Text>

      </Row>
    </Modal>
  )
}


export default DisconnectIntegrationModal