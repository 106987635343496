import React from "react";
import TraitSlider from "./TraitSlider";
import TraitRelevance from './TraitRelevance';

import { Row, Col } from 'antd';

const TraitWeight = ({ trait_id, measuredTrait, totalPoints, showDistribution }) => {
  return (
    <Row type="flex" style={{ marginBottom: 15 }}>
      <Col xs={20} sm={20}>
        <TraitSlider showDistribution={showDistribution} measuredTrait={measuredTrait} trait_id={trait_id} />
      </Col>
      <Col xs={4} sm={4} style={{ display: 'flex', justifyContent: 'end' }}>
        <TraitRelevance showDistribution={showDistribution} trait_id={trait_id} measuredTrait={measuredTrait} totalPoints={totalPoints} />
      </Col>
    </Row>
  );
}

export default TraitWeight;
