import { SUCCESS, ERROR, WARNING, CLEAN } from '../constants';

export const alertActions = {
    success,
    error,
    warning,
    clean,
};

function success(message) {
    return { type: SUCCESS, message };
}

function error(message) {
    return { type: ERROR, message };
}

function warning(message) {
    return { type: WARNING, message };
}
function clean() {
    return { type: CLEAN };
}

