import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Form } from 'antd';
import PropTypes from 'prop-types';

import './PersonalFormActivity.scss';

import * as actionCreators from '../../actions/data';
import { PersonalFormProvider } from './config/Context';
import PersonalFormLayout from './Layout';

function PersonalFormActivityView(props) {
  return (
    <PersonalFormProvider
      actions={props.actions}
      jobAppId={props.match?.params['jobappid']}
      token={props.token}
    >
      <PersonalFormLayout {...props} />
    </PersonalFormProvider>
  );
}

PersonalFormActivityView.propTypes = {
  actions: PropTypes.object,
  match: PropTypes.object,
  token: PropTypes.string,
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    userId: state.auth.userID,
  };
};

const PersonalInformationForm = Form.create()(PersonalFormActivityView)
export default connect(mapStateToProps, mapDispatchToProps)(PersonalInformationForm);
