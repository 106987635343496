import React from 'react'
import { Row, Typography, Layout } from 'antd';

import Collaborator from './collaborators/Collaborator';
import Profiles from './profile/profile';
import './AccountSetting.scss';

const { Title } = Typography;

const AccountSetting = () => {
  return (
    <Layout className='gw-as'>
      <Row>
        <Title>
          Mi Empresa
        </Title>
      </Row>
      <Collaborator />
    </Layout>
  )
}

export default AccountSetting
