import React, { useContext } from 'react';
import { Row, Col, Typography } from 'antd';

import TemplateCard from './components/TemplateCard';

import { AnalyticsContext } from '../context/AnalyticsContext';

import BluePercentage from './components/BluePercentage';

import {
  getMaxCountObject,
  disabilities,
  genders,
  processNivoData,
  getDataOrDefaultValue,
  renderComponentOrDefault
} from '../assets/utils';

import LineWithTable from './components/LineWithTable';

const { Text } = Typography;

const Diversity = () => {
  const {
    sectionsData: {
      diversity: { candidates, hired },
    },
  } = useContext(AnalyticsContext);

  const maxCandidateGender = getMaxCountObject(candidates.genders);
  const maxHiredGender = getMaxCountObject(hired.genders);

  const maxCandidateNationality = getMaxCountObject(candidates.nationality);
  const maxHiredNationality = getMaxCountObject(hired.nationality);

  const maxCandidateDisability = getMaxCountObject(candidates.disabilities);
  const maxHiredDisability = getMaxCountObject(hired.disabilities);

  return (
    <div>
      <Row type="flex" gutter={[16, 16]}>
        <Col xs={24}>
          <Text>Visión general</Text>
        </Col>
        <Col xs={24} lg={12}>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
          >
            <Text strong style={{ textAlign: 'center' }}>
              Candidatos
            </Text>
            <TemplateCard
              templateText
              data={candidates.total}
              height={151}
              title="Candidatos totales"
              text="Candidatos"
              noMargin
            />
            <TemplateCard
              templateGraphic
              graphicType="pieChart"
              title="Género"
              data={candidates.genders?.map(obj=>({id:obj.gender, label:obj.gender, value:obj.count}))}
              height={300}
              translateX={10}
              translateY={140}
              noMargin
              alert={
                <span className="span-justified">
                  El segmento mayoritario de tus candidatos se identifican con
                  el género{' '}
                  <strong>{genders[maxCandidateGender.max.gender]}</strong>,
                  representando el:{' '}
                  {
                    <BluePercentage
                      number={Math.round(
                        (maxCandidateGender.max.count * 100) /
                          maxCandidateGender.total
                      )}
                    />
                  }{' '}
                  del total de candidatos para los procesos seleccionados.
                </span>
              }
            />
            <TemplateCard
              templateGraphic
              graphicType="geoMap"
              data={processNivoData(candidates.nationality, ['nationality'])}
              height={250}
              title="Nacionalidad"
              noMargin
              alert={
                <span className="span-justified">
                  La mayoría de tus candidatos declaran nacionalidad{' '}
                  <strong>{maxCandidateNationality.max.nationality}</strong>,
                  representando el:{' '}
                  {
                    <BluePercentage
                      number={Math.round(
                        (maxCandidateNationality.max.count * 100) /
                          maxCandidateNationality.total
                      )}
                    />
                  }{' '}
                  del total de candidatos para los procesos seleccionados.
                </span>
              }
            />
            <TemplateCard
              templateText
              data={getDataOrDefaultValue(
                candidates.disability_rate,
                (n) => `${n.toFixed(2)}%`
              )}
              height={200}
              title="Tasa de discapacidad"
              text={
                <span>
                  De tus candidatos declaran algún tipo de discapacidad
                  considerando <strong>los procesos seleccionados.</strong>
                </span>
              }
              noMargin
            />
            <TemplateCard
              templateGraphic
              graphicType="bar"
              topic="channel"
              data={processNivoData(
                candidates.disabilities?.map((obj) => ({
                  ...obj,
                  disability_type: disabilities[obj.disability_type],
                })),
                ['disability_type']
              )}
              height={230}
              title="Discapacidad"
              customMargin={{ top: 30, right: 20, bottom: 30, left: 40 }}
              noMargin
              alert={
                <span className="span-justified">
                  La discapacidad declarada más frecuente en tus candidatos
                  corresponde a la de tipo{' '}
                  <strong>
                    {disabilities[maxCandidateDisability.max.disability_type]}
                  </strong>
                  , representando el:{' '}
                  {
                    <BluePercentage
                      number={Math.round(
                        (maxCandidateDisability.max.count * 100) /
                          maxCandidateDisability.total
                      )}
                    />
                  }{' '}
                  del total de candidatos con discapacidad declarada en los
                  procesos seleccionados.
                </span>
              }
            />
          </div>
        </Col>
        <Col xs={24} lg={12}>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
          >
            <Text strong style={{ textAlign: 'center' }}>
              Contratados
            </Text>
            <TemplateCard
              templateText
              data={hired.total}
              height={151}
              title="Contrataciones totales"
              text="Contratados"
              noMargin
            />
            <TemplateCard
              templateGraphic
              graphicType="pieChart"
              title="Género"
              data={processNivoData(
                hired.genders?.map((obj) => ({
                  ...obj,
                  gender: genders[obj.gender],
                })),
                ['gender']
              )}
              height={300}
              translateX={10}
              translateY={140}
              noMargin
              alert={
                <span className="span-justified">
                  El segmento mayoritario de tus contrataciones se identifican
                  con el género{' '}
                  <strong>{genders[maxHiredGender.max.gender]}</strong>,
                  representando el:{' '}
                  {
                    <BluePercentage
                      number={Math.round(
                        (maxHiredGender.max.count * 100) / maxHiredGender.total
                      )}
                    />
                  }{' '}
                  del total de contrataciones para los procesos seleccionados.
                </span>
              }
            />
            <TemplateCard
              templateGraphic
              graphicType="geoMap"
              data={processNivoData(hired.nationality, ['nationality'])}
              height={250}
              title="Nacionalidad"
              noMargin
              alert={
                <span className="span-justified">
                  La mayoría de tus contrataciones declaran nacionalidad{' '}
                  <strong>{maxHiredNationality.max.nationality}</strong> ,
                  representando el:{' '}
                  {
                    <BluePercentage
                      number={Math.round(
                        (maxHiredNationality.max.count * 100) /
                          maxHiredNationality.total
                      )}
                    />
                  }{' '}
                  del total de contrataciones para los procesos seleccionados.
                </span>
              }
            />
            <TemplateCard
              templateText
              data={getDataOrDefaultValue(
                hired.disability_rate,
                (n) => `${n.toFixed(2)}%`
              )}
              height={200}
              title="Tasa de discapacidad"
              text={
                <span>
                  De tus contratados declaran algún tipo de discapacidad
                  considerando <strong>los procesos seleccionados.</strong>
                </span>
              }
              noMargin
            />
            <TemplateCard
              templateGraphic
              topic="channel"
              graphicType="bar"
              data={processNivoData(
                hired.disabilities?.map((obj) => ({
                  ...obj,
                  disability_type: disabilities[obj.disability_type],
                })),
                ['disability_type']
              )}
              height={230}
              title="Discapacidad"
              customMargin={{ top: 30, right: 20, bottom: 30, left: 40 }}
              noMargin
              alert={
                <span className="span-justified">
                  La discapacidad declarada más frecuente en tus contratados
                  corresponde a la de tipo{' '}
                  <strong>
                    {disabilities[maxHiredDisability.max.disability_type]}
                  </strong>
                  , representando el:{' '}
                  {
                    <BluePercentage
                      number={Math.round(
                        (maxHiredDisability.max.count * 100) /
                          maxHiredDisability.total
                      )}
                    />
                  }{' '}
                  del total de contrataciones con discapacidad declarada en los
                  procesos seleccionados.
                </span>
              }
            />
          </div>
        </Col>
        <LineWithTable
          cardTitle="Grupo etario"
          responsiveConfig={{ xs: 24, xl: 12 }}
          data={{
            candidates: candidates.age,
            hired: hired.age,
          }}
          lineKey="age"
          colors={['#1890FF', '#0050B3']}
          tooltipLabels={['Edad', 'Cantidad']}
          columns={[
            { title: 'Tus candidatos', key: 'candidates' },
            { title: 'Tus contratados', key: 'hired' },
          ]}
        />
      </Row>
    </div>
  );
};

export default Diversity;
