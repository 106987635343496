import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import PropTypes from 'prop-types';

import { Row, Col } from 'antd';

/**
 *
 * @param {Array<Number>} intensity length 4
 * @returns
 */
const CustomDiscPlot = ({intensity}) => {
  const data = [
    {
      name: 'D',
      dominance: intensity[0],
      color: 'hsl(4, 100%, 70%)',
    },
    {
      name: 'i',
      influence: intensity[1],
      color: 'hsl(40, 100%, 65%)',
    },
    {
      name: 'S',
      stability: intensity[2],
      color: 'hsl(93, 69%, 60%)',
    },
    {
      name: 'C',
      compliance: intensity[3],
      color: 'hsl(202, 100%, 70%)',
    },
  ];
  return (
    <Row>
      <Col sm={24}>
        <div style={{ height: '25rem', minWidth: '100%' }}>
          <ResponsiveBar
            data={data}
            keys={['dominance', 'influence', 'stability', 'compliance']}
            colors={obj => obj.data.color}
            indexBy='name'
            enableLabel={true}
            margin={{ top: 20, right: 30, bottom: 50, left: 30 }}
            padding={0.15}
            layout={'vertical'}
            valueScale={{ type: 'linear' }}
            minValue={0}
            maxValue={28}
            isInteractive={true}
            // docs https://codesandbox.io/s/p3pv1n4n60?file=/src/index.js:590-937
            theme={{
              grid: {
                line: {
                  strokeWidth: 1,
                  strokeDasharray: '4 4',
                },
              },
            }}
            tooltip={({ value, indexValue }) => (
              <div
                style={{
                  padding: 6,
                  background: '#FFFFFF',
                  boxShadow: '0px 1px 2px gray',
                  borderRadius: 2,
                }}
              >
                <div style={{ fontSize: 14 }}>
                  {`${indexValue}: `}
                  <strong>{value}</strong>
                </div>
              </div>
            )}
          />
        </div>
      </Col>
    </Row>
  );
};

CustomDiscPlot.propTypes = {
  intensity: PropTypes.arrayOf(PropTypes.number),
};

export default CustomDiscPlot;
