import React from 'react';
import {
  Modal,
  Button,
  Typography,
  Tag,
  Icon,
  Divider
} from 'antd';
import AddOrRemove from '../../../../AddOrRemove';
import './GameDescription.scss';

const { Text, Title } = Typography;
 
const colors = {
  'REASONING': '#4e4cac',
  'COGNITIVE': '#f07f8b',
  'PERSONALITY': '#9698d5',
  'EMOTIONAL': '#f07f8b'
}
const GameDescription = ({ visible, description, selectedGame, openModal, nextGame }) => { 
  return (
    <Modal
      centered={true}
      visible={visible}
      onOk={openModal}
      onCancel={openModal}
      className='game-description'
      footer={
        <footer style={{ background: colors[selectedGame.category] }}>
          <span className='left-side'>
            <Button icon='left' onClick={() => nextGame(-1)} />
            <Button icon='right' onClick={() => nextGame()} />
            {selectedGame.allGames && <Text strong>{selectedGame.index+1}/{selectedGame.allGames.activities.length}</Text>}
          </span>
          
          <span className='right-side'>
            <AddOrRemove activity={selectedGame} />
          </span>
        </footer>
      }
    >
      <div className='headline'>
        <img className={selectedGame.classname} src={`https://genoma-assets.s3.us-east-2.amazonaws.com/${selectedGame.url}.png`} alt='bag' /> 
        <Title level={4}>{selectedGame.activity}</Title> 
        <Tag><Icon type="clock-circle" /> {selectedGame.time_estimated} minutos</Tag>
      </div>

      <Divider />

      <div className='description'>
        <div className='title'>
          <Text strong>Rasgos que se evaluarán en este juego:</Text> 
        </div>
       
        {description && description.traits.map((trait, index) => {
          return (
            <div key={trait} className='trait'>
              <Text>
                <b>{trait}</b>: {description.traitDesc[index]}
              </Text>
              <br />
              <br />
            </div>
          );
        })}
        
      </div>
    </Modal>
  )
};

export default GameDescription;