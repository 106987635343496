import React, { useState, useContext, useEffect } from 'react';
import {
  Input,
  Button,
  Divider,
  Typography,
  Row,
  Layout,
  Icon,
  Collapse,
  Col,
  Switch,
  Checkbox,
  Dropdown,
  Menu,
  Tag,
  Tooltip,
  Select,
} from 'antd';
import { v4 as uuidv4 } from 'uuid';

import { customTestContext } from '../../context/CustomTestContext';

import MceEditor from '../../../../BusinessDashboard/TemplatesMails/components/MceEditor';

const { Text } = Typography;
const { Panel } = Collapse;

const Step2 = () => {
  const {
    configStep1,
    configStep2,
    setConfigStep2,
    questionsContext,
    setQuestionsContext,
    scoreConfig,
    setScoreConfig,
    questionNumber,
    setQuestionNumber,
  } = useContext(customTestContext);

  const addPage = () => {
    setQuestionsContext([
      ...questionsContext,
      {
        component: 'page',
        label: `Pagina ${questionsContext.length + 1}`,
        _uid: uuidv4(),
        fields: [],
      },
    ]);
  };
  const deletePage = (id, indexPage) => {
    setQuestionNumber(
      questionNumber - questionsContext[indexPage].fields.length
    );
    const newPages = questionsContext.filter((page) => page._uid !== id);
    setQuestionsContext(newPages);
  };
  const addQuestion = (index) => {
    let uuid = uuidv4();
    questionsContext[index].fields = [
      ...questionsContext[index].fields,
      {
        component: 'single_answer',
        label: '',
        image: '',
        type: 'radio',
        _uid: uuid,
        options: [],
        category: '',
        required: false,
      },
    ];
    setQuestionsContext([...questionsContext]);
    setScoreConfig({
      ...scoreConfig,
      correct_answers: {
        ...scoreConfig.correct_answers,
        [uuid]: {
          type: 'single_answer',
          value: '',
          category: '',
        },
      },
    });
    setQuestionNumber(questionNumber + 1);
  };
  const deleteQuestion = (index, idQuestion) => {
    const newQuestions = questionsContext[index].fields.filter(
      (question) => question._uid !== idQuestion
    );
    questionsContext[index].fields = newQuestions;
    setQuestionsContext([...questionsContext]);
    setQuestionNumber(questionNumber - 1);
  };
  const addAlternative = (indexPage, indexQuestion) => {
    questionsContext[indexPage].fields[indexQuestion].options = [
      ...questionsContext[indexPage].fields[indexQuestion].options,
      {
        component: 'option',
        label: '',
        value: `id_${
          questionsContext[indexPage].fields[indexQuestion].options.length + 1
        }`,
      },
    ];
    setQuestionsContext([...questionsContext]);
  };
  const deleteAlternative = (page, question, option) => {
    questionsContext[page].fields[question].options.splice(option, 1);
    questionsContext[page].fields[question].options.forEach(
      (element, index) => (element.value = `id_${index + 1}`)
    );
    setQuestionsContext([...questionsContext]);
  };
  const selectCorrectAlternative = (page, question, option, e) => {
    const optionValue =
      questionsContext[page].fields[question].options[option].value;
    const id = questionsContext[page].fields[question]._uid;
    if (e.target.checked) {
      scoreConfig.correct_answers[id].value = optionValue;
    } else {
      scoreConfig.correct_answers[id].value = '';
    }
    setQuestionsContext([...questionsContext]);
  };

  const addSkill = (page, question, skill) => {
    questionsContext[page].fields[question].category = skill;
    const id = questionsContext[page].fields[question]._uid;
    scoreConfig.correct_answers[id].category = skill;
    setQuestionsContext([...questionsContext]);
  };
  const removeSkill = (page, question) => {
    questionsContext[page].fields[question].category = '';
    const id = questionsContext[page].fields[question]._uid;
    scoreConfig.correct_answers[id].category = '';
    setQuestionsContext([...questionsContext]);
  };
  const setRequired = (page, question, checked) => {
    questionsContext[page].fields[question].required = checked;
    setQuestionsContext([...questionsContext]);
  };

  const buttonDeletePage = (id, indexPage) => (
    <Icon
      type="delete"
      onClick={() => deletePage(id, indexPage)}
      style={{ color: '#A4A4A4', fontSize: '20px' }}
    />
  );
  const menuSkills = (page, question) =>
    configStep1.skills.length > 0 ? (
      <Menu>
        {configStep1.skills.map((skill, index) => (
          <Menu.Item key={index}>
            <Tag
              style={{ border: '1px solid #597EF7', background: 'white' }}
              onClick={() => addSkill(page, question, skill)}
            >
              {skill}
            </Tag>
          </Menu.Item>
        ))}
      </Menu>
    ) : (
      <Text>No hay dimensiones creadas</Text>
    );

  const handleChangeQuestion = (page, question) => (e) => {
    questionsContext[page].fields[question].label = e.target.value;
    setQuestionsContext([...questionsContext]);
  };
  const handleChangeAlternative = (page, question, option, e) => {
    questionsContext[page].fields[question].options[option].label =
      e.target.value;
    setQuestionsContext([...questionsContext]);
  };
  const handleChangeInput = (e) => {
    setConfigStep2({
      ...configStep2,
      [e.target.name]: e.target.value,
    });
  };
  const handleMcEditorInstructions = (name) => (e) => {
    configStep2.questions.data[0].fields[0].paragraphs = [e.target.value];
    setConfigStep2({ ...configStep2 });
  };

  const changeQuestionType = (type, pageIndex, questionIndex, id) => {
    // could be shorter but this way is more readable
    setQuestionsContext((pages) => {
      return pages.map((page, pIndex) => {
        if (pIndex === pageIndex) {
          return {
            ...page,
            fields: page.fields.map((question, qIndex) => {
              if (qIndex === questionIndex) {
                let tmpQuestion = {
                  component: 'input',
                  required: question.required,
                  label: question.label,
                  _uid: question._uid,
                  type,
                  category: '',
                };

                if (type === 'radio') {
                  tmpQuestion = {
                    ...tmpQuestion,
                    component: 'single_answer',
                    image: '',
                    options: [],
                  };
                }

                return tmpQuestion;
              } else {
                return question;
              }
            }),
          };
        } else {
          return page;
        }
      });
    });
    setScoreConfig((config) => ({
      ...config,
      correct_answers: {
        ...config.correct_answers,
        [id]: {
          type: type === 'radio' ? 'single_answer' : 'text',
          value: '',
          category: '',
        },
      },
    }));
  };

  useEffect(() => {
    questionsContext.length === 0 && addPage();
  }, []);

  return (
    <Layout className="new-custom-test__frame__step-by-step__step2">
      <Row
        type="flex"
        justify="end"
        className="new-custom-test__frame__step-by-step__step2__genomin"
      >
        <img src="https://genoma-assets.s3.us-east-2.amazonaws.com/genomin.svg" />
      </Row>
      <Row
        type="flex"
        className="new-custom-test__frame__step-by-step__step2__box-information"
      >
        <Col xs={24} sm={1}>
          <Icon
            type="info-circle"
            theme="twoTone"
            className="new-custom-test__frame__step-by-step__step2__box-information__icon"
          />
        </Col>
        <Col xs={24} sm={23}>
          <Text className="new-custom-test__frame__step-by-step__step2__box-information__text">
            Se considerará 1 punto por respuesta correcta. Te sugerimos
            configurar 1 pregunta por página para brindarles una mejor
            experiencia a los candidatos
          </Text>
        </Col>
      </Row>

      <Row className="new-custom-test__frame__step-by-step__step2__fields">
        <span>*</span>
        <Text>Nombre de la prueba para evaluados</Text>
        <Tooltip
          placement="topLeft"
          title="Nombre del test mostrado a los evaluados. Modificaciones en este campo no se reflejarán en el nombre del test mostrado a los colaboradores."
        >
          <Icon type="question-circle" />
        </Tooltip>
      </Row>
      <Input
        className="new-custom-test__frame__step-by-step__step2__input"
        name="candidateTitle"
        value={configStep2.candidateTitle}
        onChange={handleChangeInput}
        placeholder="Conocimientos de Microsoft Excel"
      />

      <Row
        type="flex"
        justify="space-between"
        align="middle"
        className="new-custom-test__frame__step-by-step__step2__instruction-field"
      >
        <Text>
          Instrucciones
          <span>(opcional)</span>
        </Text>
      </Row>
      <MceEditor
        placeholder="Instrucciones para la prueba"
        name="instruction"
        text={configStep2.questions.data[0].fields[0].paragraphs[0]}
        handleChange={handleMcEditorInstructions}
      />

      {questionsContext.length > 0
        ? questionsContext.map((page, indexPage) => (
            <Collapse
              key={page._uid}
              className="new-custom-test__frame__step-by-step__step2__collapse-page"
            >
              <Panel
                header={`Página ${indexPage + 1}`}
                extra={buttonDeletePage(page._uid, indexPage)}
              >
                {page.fields.length > 0
                  ? page.fields.map((question, indexQuestion) => (
                      <Collapse
                        key={question._uid}
                        className="new-custom-test__frame__step-by-step__step2__collapse-page__question"
                      >
                        <Panel
                          header={
                            <Row
                              type="flex"
                              justify="space-between"
                              align="middle"
                            >
                              <Text>Pregunta {indexQuestion + 1}</Text>
                              {/* div to prevent collapse opening when clicking select */}
                              <div onClick={(e) => e.stopPropagation()}>
                                <Select
                                  style={{ width: 240 }}
                                  value={question.type}
                                  onChange={(value) =>
                                    changeQuestionType(
                                      value,
                                      indexPage,
                                      indexQuestion,
                                      question._uid
                                    )
                                  }
                                >
                                  {[
                                    {
                                      value: 'radio',
                                      name: 'Selección única',
                                      icon: 'align-left',
                                    },
                                    {
                                      value: 'text',
                                      name: 'Texto abierto (1500 caracteres)',
                                      icon: 'check',
                                    },
                                  ].map((option, idx) => (
                                    <Select.Option key={`option_${idx}`} value={option.value}>
                                      <Icon
                                        type={option.icon}
                                        style={{ marginRight: '0.5rem' }}
                                      />
                                      {option.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </div>
                            </Row>
                          }
                        >
                          <Row
                            type="flex"
                            className="new-custom-test__frame__step-by-step__step2__collapse-page__question__category"
                            gutter={[0, 10]}
                          >
                            <Col xs={24} sm={12}>
                              {questionsContext[indexPage].fields[indexQuestion]
                                .category !== '' ? (
                                <Button
                                  onClick={() =>
                                    removeSkill(indexPage, indexQuestion)
                                  }
                                  className="new-custom-test__frame__step-by-step__step2__collapse-page__question__category__tag"
                                  size="small"
                                >
                                  {
                                    questionsContext[indexPage].fields[
                                      indexQuestion
                                    ].category
                                  }
                                  <Icon
                                    className="layout__step2__collapsePage__collapseQuestion__category__tag__icon"
                                    type="close"
                                  />
                                </Button>
                              ) : (
                                <Dropdown
                                  overlay={menuSkills(indexPage, indexQuestion)}
                                  trigger={['click']}
                                >
                                  <Button
                                    size="small"
                                    type="dashed"
                                    icon="plus"
                                    className="new-custom-test__frame__step-by-step__step2__collapse-page__question__category__select"
                                  >
                                    Seleccionar habilidad
                                  </Button>
                                </Dropdown>
                              )}
                            </Col>
                            <Col xs={24} sm={12}>
                              <Row type="flex" align="middle" justify="end">
                                <Switch
                                  unCheckedChildren="Obligatoria"
                                  checkedChildren="Obligatoria"
                                  checked={question.required ?? false}
                                  onChange={(e) =>
                                    setRequired(indexPage, indexQuestion, e)
                                  }
                                />
                                <Icon
                                  style={{
                                    fontSize: '18px',
                                    color: '#A4A4A4',
                                    margin: '0 0 0 10px',
                                  }}
                                  type="delete"
                                  onClick={() =>
                                    deleteQuestion(indexPage, question._uid)
                                  }
                                />
                              </Row>
                            </Col>
                          </Row>
                          <Row style={{ margin: '20px 0' }}>
                            <MceEditor
                              placeholder="Ingresa la pregunta"
                              name="instruction"
                              text={question.label}
                              handleChange={(e) =>
                                handleChangeQuestion(
                                  indexPage,
                                  indexQuestion,
                                  e
                                )
                              }
                              toolbar="forecolor | bold | italic | underline | alignleft | aligncenter | alignright | code | image | link"
                            />
                          </Row>
                          {question.type === 'radio' ? (
                            <>
                              {question.options.map((option, indexOption) => (
                                <React.Fragment key={indexOption}>
                                  <Row
                                    type="flex"
                                    key={indexOption}
                                    className="new-custom-test__frame__step-by-step__step2__collapse-page__question__alternative"
                                  >
                                    <Col xs={12} sm={18}>
                                      <Input
                                        className="new-custom-test__frame__step-by-step__step2__collapse-page__question__alternative__input"
                                        placeholder="Ingrese la alternativa"
                                        value={option.label}
                                        onChange={(e) =>
                                          handleChangeAlternative(
                                            indexPage,
                                            indexQuestion,
                                            indexOption,
                                            e
                                          )
                                        }
                                      />
                                    </Col>
                                    <Col xs={12} sm={6}>
                                      <Row
                                        type="flex"
                                        justify="end"
                                        align="middle"
                                        style={{ height: '100%' }}
                                      >
                                        {scoreConfig.correct_answers[
                                          question._uid
                                        ].value === '' ? (
                                          <Checkbox
                                            checked={
                                              scoreConfig.correct_answers[
                                                question._uid
                                              ].value === option.value
                                                ? true
                                                : false
                                            }
                                            onChange={(e) =>
                                              selectCorrectAlternative(
                                                indexPage,
                                                indexQuestion,
                                                indexOption,
                                                e
                                              )
                                            }
                                          >
                                            ¿Es correcta?
                                          </Checkbox>
                                        ) : (
                                          <Checkbox
                                            checked={
                                              scoreConfig.correct_answers[
                                                question._uid
                                              ].value === option.value
                                                ? true
                                                : false
                                            }
                                            disabled={
                                              scoreConfig.correct_answers[
                                                question._uid
                                              ].value === option.value
                                                ? false
                                                : true
                                            }
                                            onChange={(e) =>
                                              selectCorrectAlternative(
                                                indexPage,
                                                indexQuestion,
                                                indexOption,
                                                e
                                              )
                                            }
                                          >
                                            ¿Es correcta?
                                          </Checkbox>
                                        )}
                                        {scoreConfig.correct_answers[
                                          question._uid
                                        ].value === '' ? (
                                          <Icon
                                            type="delete"
                                            style={{
                                              fontSize: '18px',
                                              color: '#A4A4A4',
                                            }}
                                            onClick={() =>
                                              deleteAlternative(
                                                indexPage,
                                                indexQuestion,
                                                indexOption
                                              )
                                            }
                                          />
                                        ) : null}
                                      </Row>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              ))}
                              <Button
                                type="link"
                                icon="plus-circle"
                                style={{ margin: '0 0 0 0' }}
                                onClick={() =>
                                  addAlternative(indexPage, indexQuestion)
                                }
                              >
                                Agregar alternativa
                              </Button>
                            </>
                          ) : null}
                        </Panel>
                      </Collapse>
                    ))
                  : null}
                <Button
                  onClick={() => addQuestion(indexPage)}
                  icon="plus-circle"
                  type="link"
                  style={{ color: '#F175A5', margin: '20px 0 0 0' }}
                >
                  Agregar pregunta
                </Button>
              </Panel>
            </Collapse>
          ))
        : null}
      <Row type="flex" justify="center">
        <Divider style={{ margin: '50px 0 200px 0' }}>
          <Button
            type="link"
            icon="plus-circle"
            style={{ color: '#F175A5' }}
            onClick={() => addPage()}
          >
            Agregar página (dentro podrás agregar preguntas)
          </Button>
        </Divider>
      </Row>
    </Layout>
  );
};

export default Step2;
