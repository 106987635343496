import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { Typography } from "antd";
import i18n from "../../../../../i18n";

const { Title, Text } = Typography;

const CandidateInstructions = (props) => {
  return (
    <Fragment>
      <div
        style={{
          marginTop: 20,
          padding: "18px 20px 28px 20px",
          borderRadius: "8px",
          background:
            "linear-gradient(0deg, rgba(243,243,244,0.8), rgba(243,243,244,0.5)),#fff",
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <div className="cp-instruction-title">
              <Text strong>{i18n.t("candidate_path__instructions")}</Text>
            </div>
            {
              [
                'candidate_path__instructions__1',
                'candidate_path__instructions__2',
                'candidate_path__instructions__3',
                'candidate_path__instructions__4',
                'candidate_path__instructions__5',
                'candidate_path__instructions__6',
                'candidate_path__instructions__7',
              ].map((text) => (
                <div className="cp-item-list">
                  <Text>{i18n.t(text)}</Text>
                </div>
              ))
            }
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    items: state.data.data.items,
  };
};

export default connect(mapStateToProps)(CandidateInstructions);
