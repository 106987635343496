import React from 'react';
import './styles-pdf.scss';

const CoverPage = ({ name, hideGenomaLogo }) => {
  return (
    <main className='dat-pdf-modal__coverpage__container'>
      <div className='dat-pdf-modal__coverpage__container__firstcolor'>
        <div className='dat-pdf-modal__coverpage__container__secondcolor'>
          <h1 style={{ fontWeight: 400 }}>{name}</h1>
        </div>
      </div>
      {hideGenomaLogo ? null :
        <div className='dat-pdf-modal__coverpage__container__logos'>
          <img src='https://genoma-assets.s3.us-east-2.amazonaws.com/logo.svg' style={{ margin: '0 0 0 18px' }} />
          <img src='https://genoma-assets.s3.us-east-2.amazonaws.com/genomin.png' style={{ margin: '0 75px 0 0' }} />
        </div>
      }
    </main>
  );
}

export default CoverPage;