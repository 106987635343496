import React, { useContext }  from 'react';

import {
  Col,
  Form,
  Input,
  Row,
  Typography,
} from 'antd';
import PropTypes from 'prop-types';

import { PersonalFormContext } from '../config/Context';
import { personalDataFields, personalFormLabels, stepTitles } from '../constants';
import i18n from '../../../i18n';

const { Text, Title } = Typography;

const { personalInformation: personalInformationLabels } = personalFormLabels;

function PersonalInformationForm(props) {
  const { form } = props;
  const { getFieldDecorator, setFieldsValue } = form;
  const { candidate, countries, jobApplication, updateCandidate } = useContext(PersonalFormContext);

  const jobAppOptionalFieldsConfig = jobApplication.optional_fields;

  return (
    <>
      <Row>
        <Title level={3}>{stepTitles[0]}</Title>
      </Row>
      <Row align="middle" gutter={24} type="flex">
        <Col xs={24} md={12}>
          <Form.Item colon={false} label={<><Text style={{ color: 'red' }}>*</Text>DNI</>}>
            {getFieldDecorator("dni", {
              initialValue: candidate?.dni,
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item colon={false} label={<><Text style={{ color: 'red' }}>*</Text>Email</>}>
            {getFieldDecorator("email", {
              initialValue: candidate?.email,
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            colon={false}
            label={<><Text style={{ color: 'red' }}>*</Text>{personalInformationLabels.name}</>}
          >
            {getFieldDecorator("name", {
              initialValue: candidate?.name,
              onChange: (event) => updateCandidate('name', event.target.value),
              rules: [{ required: true, message: i18n.t('commons__required__field') }],
            })(<Input allowClear />)}
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item colon={false} label={<><Text style={{ color: 'red' }}>*</Text>{personalInformationLabels.surname}</>}>
            {getFieldDecorator("surname", {
              initialValue: candidate?.surname,
              onChange: (event) => updateCandidate('surname', event.target.value),
              rules: [{ required: true, message: i18n.t('commons__required__field') }],
            })(<Input allowClear />)}
          </Form.Item>
        </Col>

        {countries && candidate && jobAppOptionalFieldsConfig ? (
          Object.entries(personalDataFields).map(([key, data]) => {
            if (
              (key === 'phone_prefix' && jobAppOptionalFieldsConfig.phone === 2)
                || (Object.keys(jobAppOptionalFieldsConfig).includes(key) && jobAppOptionalFieldsConfig[key] === 2)
            )
              return null;
            
            const required = (
              !key.includes('disability')
                && Object.keys(jobAppOptionalFieldsConfig).includes(key)
                && !jobAppOptionalFieldsConfig[key]
            );
            const inputRules = [{ required, message: required && i18n.t('commons__required__field') }];
            data.validators?.forEach(
              ({ validator, errorMsg }) => inputRules.push({ validator, message: errorMsg })
            );

            let label = data.label;
            if (
              (key === 'phone_prefix'
                && ![null, undefined].includes(jobAppOptionalFieldsConfig.phone)
                && jobAppOptionalFieldsConfig.phone === false)
              || (key !== 'phone' && required)
            ) {
              label = (
                <>
                  <Text style={{ color: 'red' }}>*</Text>{data.label}
                </>
              );
            } else if (key !== 'phone' && key !== 'disability_visible') {
              label = (
                <>
                  {data.label}
                  <Text style={{ color: '#A4A4A4', fontWeight: 'normal' }}>
                    {` (${i18n.t('commons__optional')})`}
                  </Text>
                </>
              );
            }
            return ((data.condition === undefined || data.condition(candidate))
              ? (
                <Col
                  key={key}
                  xs={key === 'phone_prefix' ? 8 : key === 'phone' ? 16 : 24}
                  md={data.span}
                >
                  <Form.Item colon={false} label={label}>
                    <Row align="middle" style={{ width: '100%' }} type="flex">
                      <Col span={data.clear ? 23 : 24}>
                        {getFieldDecorator(key, {
                          initialValue: (candidate[key] && ['country', 'nationality'].includes(key))
                              ? `${candidate[key].charAt(0).toUpperCase()}${candidate[key].slice(1)}`
                              : candidate[key],
                          onChange: (prop) => data.handleChange(updateCandidate, prop, setFieldsValue),
                          rules: inputRules,
                        })(
                          ['country', 'nationality', 'phone_prefix'].includes(key)
                            ? data.input(countries)
                            : data.input()
                        )}
                      </Col>
                      {data.clear
                        ? (
                          <Col span={1}>
                            {data.clear(updateCandidate, setFieldsValue)}
                          </Col>
                        ) : null}
                    </Row>
                    {data.extra ?? null}
                  </Form.Item>
                </Col>
              ) : null
            )
          })
        ): null}
      </Row>
    </>
  );
}

PersonalInformationForm.propTypes = {
  form: PropTypes.object.isRequired,
};

export default PersonalInformationForm;
