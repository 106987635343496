import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Buffer } from 'buffer';

import AlertDialogSlide from "../Common/AlertDialogSlide";

import * as actionCreators from "../../actions/data";
import * as authActionCreators from "../../actions/auth";

import ReactPixel from 'react-facebook-pixel';

import { validateDNI } from '../../utils/dniValidate';

import {
  Row,
  Button,
  Form,
  Typography,
  Col,
  Icon,
  message,
  Checkbox,
  Layout,
  Modal,
  Drawer,
  Spin
} from 'antd';
import './start.scss';

import i18n from '../../i18n';

import FirstLogin from './components/Cards/FirstLogin';
import NotFirstLogin from './components/Cards/NotFirstLogin';
import InvalidAccount from './components/Cards/InvalidAccount';
import Registration from './components/Cards/Registration';
import Cover from './components/Cover';
import NewWelcomeView from './components/NewWelcomeView';
import { countriesIdentification } from '../../constants/personalUser';

const { Text } = Typography
const SHARE_URL = process.env.REACT_APP_SHARE_URL;

class StartJobApplicationView extends React.Component {
  static propTypes = {
    statusText: PropTypes.string,
    isFetching: PropTypes.bool.isRequired,
    data: PropTypes.object,
    // token: PropTypes.string.isRequired,
    actions: PropTypes.shape({
      fetchData: PropTypes.func.isRequired
    }).isRequired,
    authActions: PropTypes.shape({
      authLoginUser: PropTypes.func.isRequired,
      authSignupUser: PropTypes.func.isRequired
    }).isRequired
  };
  captchaRef = React.createRef();
  static defaultProps = {
    data: null,
    errors: {},
    status: '',
  };

  state = {
    showStart: false,
    showWelcome: true,
    email: '',
    emailConfirm: '',
    password: '',
    passwordConfirm: '',
    dni: '',
    country_document: '',
    showPassword: false,
    jobappid: '',
    cards: null,
    openDialog: false,
    tos: false,
    tosDialog: false,
    errors: {
      email: false,
      emailConfirm: false,
      password: false,
      passwordConfirm: false,
      passwordLogin: false,
      dni: false,
    },
    showPasswordError: false,
    welcomeMessage: '',
    validMailReplaced: true,
    tokenCaptcha: null,
    backgroundImage: null,
    backgroundImage_banner: null,
    isClosed: false,
    countries: [],
    openModal: false,
    title: "",
    content: "",
    isLoading: false,
    cookiesConsentDrawerOpen: false,
    business_user_slug: "",
    color_theme: {
      background: "",
      color: "",
    },
    cookies: [],
    fields: {},
    utm: {},
    queryParams: {},
  };

  componentDidUpdate() {
    //jobApplication.backgroundImage ? jobApplication.backgroundImage.b64Img : business.backgroundImage
    if ((this.props.status === 'INACT' || this.props.status === 'OTHER') && !this.state.openModal && !this.state.title) {
      this.handleModalOpen({
        title: "Advertencia",
        content:
          i18n.t('start_process__inactive__process')
      });
    }

    if (
      this.state.backgroundImage == null &&
      this.props.data.business && this.props.data.business.background_image != this.state.backgroundImage
    ) {
      if (this.props.data.banner_picture) {
        this.setState({ backgroundImage: this.props.data.banner_picture });
      }
      else {
        this.setState({ backgroundImage: this.props.data.business.background_image });
      }
    }
    if (
      this.props.data.business && this.props.data.business.logo != null &&
      this.props.logoURL != this.props.data.business.logo
    ) {
      this.props.authActions.updateLogo(this.props.data.business.logo);
    }
  }

  getCountries = () => {
    const url = '/api/v1/constants/countries/?p=[country,translation]'
    this.props.actions.fetchAndReturn('', url, 'GET').then((response) => {
      const data = response.body
      this.setState({ countries: data })
    })
  }

  getCookies = (businessId) => {
    const url = `/api/v1/accounts/business/${businessId}/cookies/`;
    this.props.actions
      .fetchAndReturn(this.props.token, url, "get")
      .then((data) => {
        if (!data.body) {
          return;
        }
        const cookies = data.body.cookies.filter(
          (cookie) => cookie.job_application_id === this.state.jobappid
        );
        this.setState({
          cookies,
          cookiesConsentDrawerOpen: cookies.length > 0,
        });
        const options = {
          autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
          debug: false, // enable logs
        };
        // Cookies
        // FBPixel
        const fbCookie = cookies.find((cookie) => cookie.provider === "FP");
        if (fbCookie) {
          ReactPixel.init(fbCookie.client_secret, {}, options);
          ReactPixel.track('PageView');
        }
      })
      .catch((e) => { console.log(e) });
  };

  handleDialogClose = () => {
    let tempErrors = this.state.errors;
    tempErrors.dialog = false;
    this.setState({ errors: tempErrors });
  };

  handleModalOpen = kwargs => {
    this.setState({ openModal: true, ...kwargs });
  };

  handleModalClose = () => {
    window.location.href = SHARE_URL;
    this.setState({ openModal: false });
  }

  handleChange = (prop) => (event) => {
    let hideAlert = this.state.showPasswordError;
    hideAlert = false;
    this.setState({ showPasswordError: hideAlert })

    let hideErrors = { ...this.state.errors };
    hideErrors[prop] = false;
    this.setState({ errors: hideErrors, [prop]: event.target.value });
  };

  handleChangeCountry = name => event => {
    let errors = this.state.errors;
    errors.dni = false;
    this.setState({ [name]: event, errors: errors });
    this.props.form.setFieldsValue({
      dni: '',
    });
  }

  handleTosChange = prop => event => {
    this.captchaRef.current.executeAsync();
    let v = !this.state.tos;
    this.setState({ tos: v });
  };

  handleTosDialog = () => {
    let v = !this.state.tosDialog;
    this.setState({ tosDialog: v });
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  verifyCallback = (response) => {
    this.setState({ tokenCaptcha: response });
  };

  handlePostback = () => {
    const postbackUrl64 = this.state.queryParams.postbackUrl;
    if (postbackUrl64) {
      const postbackUrl = Buffer.from(postbackUrl64, 'base64').toString('utf-8');
      const url = Object.keys(this.state.queryParams).filter(x => x !== 'postbackUrl').reduce((prev, key) => {
        const value = this.state.queryParams[key];
        return prev + `&${key}=${value}`;
      }, postbackUrl)
      try {
        // For origin problems
        fetch(url);
      } catch (e) {
        console.log(e);
      }
    }
  }

  checkFields = () => {
    if (
      this.state.password !== '' && this.state.tokenCaptcha !== null && this.state.tos &&
      (this.state.dni !== '' || !this.state.countries.includes(this.state.country_document.toLowerCase())) &&
      this.state.emailConfirm.toLowerCase().trim() === this.state.email.toLowerCase().trim()
    ) {
      return false
    } else {
      return true
    }
  }


  goBack = () => {
    const url = `gamesandtests/jobapplications/${this.state.jobappid}/public/`;
    this.setState({
      email: '',
      emailConfirm: '',
      password: '',
      passwordConfirm: '',
      dni: '',
      errors: {
        email: false,
        emailConfirm: false,
        password: false,
        passwordConfirm: false,
        dni: false,
      },
    });
    this.setState({ showStart: false, showWelcome: true })
    this.props.actions.fetchData(this.props.token, url, "", "get");
    this.props.authActions.authGoBack();
  };


  extractRedirect = (string) => {
    const match = string.match(/next=(.*)/);
    return match ? match[1] : '/';
  };

  submitSignupData = e => {
    e.preventDefault();
    this.handleButtonCookies('login-button');
    this.props.form.validateFields((err, values) => {
      //this.props.actions.sendData(this.props.token,'accounts/register/',JSON.stringify({"email": this.state.email,"password": this.state.password}),'post');
      let myDNI = ''
      myDNI = this.state.dni.toLowerCase().replace(/[^\d\w]/gi, '');

      if (!this.state.errors.dni && !err) {
        this.setState({ isLoading: true })
        const redirect = '/jobapplication/' + this.state.jobappid
        // TODO: deuda tecnica, single responsibility principle en authSignupUser
        // se quito el redirect para realizar redireccion en el callback, pero
        // aun sigue iniciando sesion en el signup. 
        this.props.authActions.authSignupUser(this.state.email.toLowerCase(), this.state.password, this.state.tokenCaptcha, this.state.jobappid, null, myDNI, this.state.country_document).then((e) => {
          this.setState({ isLoading: false })
          // Postular a los procesos sucursales y luego redirigir al proceso
          this.branchApply().then(() => {
            this.handlePostback();
            message.info('Redirigiendo a la postulación...')
            this.props.dispatch(push(redirect))
          })
        }).catch(e => {
          message.error('Error en el registro, por favor intentar nuevamente.')
        })
      } else {
        let errors = { ...this.state.errors };
        errors.password = true;
        errors.passwordConfirm = true;
        this.setState({ errors: errors });
      }
    });
  };

  branchApply = async () => {
    const jobAppSelectedBranches = this.props.selectedBranches[this.state.jobappid];
    const branches = jobAppSelectedBranches ? Object.keys(jobAppSelectedBranches) : [];
    // Always apply to base job app
    branches.push(this.state.jobappid);
    const promises = []
    if (branches.length === 0) {
      return Promise.resolve();
    } else if (branches.length > 1) {
      message.info('Creando postulaciones en sucursales.');
    }
    branches.forEach(branch => {
      //const url = `/api/v1/gamesandtests/userjobapplication/${branch}/?format=json${window.location.search.replace('?', '&')}`;
      // Esta media rara la estructura de este POST, ya que crea una uja pero como no pasamos uja.id utilizamos el par jobapp.id + puser.id
      // para crearla. En el caso del post como el candidato esta logueado podemos sacar el puser.id sin enviarlo en la ruta
      const url = `/api/v1/gamesandtests/applications/?format=json${window.location.search.replace('?', '&')}`;
      promises.push(
        this.props.actions.fetchAndReturn(this.props.token, url, 'POST', { 'job_application': branch })
      );
    })
    return Promise.all(promises)
  }

  submitAfterLogin = () => {
    const redirect =
        "/jobapplication/" +
        this.state.jobappid +
        this.extractRedirect(this.props.location.search);
    this.branchApply().then(() => {
      this.handlePostback();
      message.info('Redirigiendo a la postulación...')
      this.props.dispatch(push(redirect))
    })
  };

  submitLoginData = (e) => {
    e.preventDefault();
    this.handleButtonCookies('login-button');

    this.props.form.validateFieldsAndScroll((err, values) => {
      const redirect =
        "/jobapplication/" +
        this.state.jobappid +
        this.extractRedirect(this.props.location.search);

      this.props.authActions
        .authLoginUser(
          this.state.email.toLowerCase(),
          this.state.password,
          // Se remueve redirect para usar callback
          null,
          {},
          this.props.userID // ''
        )
        .then(() => {
          this.branchApply().then(() => {
            this.handlePostback();
            message.info('Redirigiendo a la postulación...')
            this.props.dispatch(push(redirect))
          })
        })
        .catch(() => {
          const errorMessage =
            this.props.alerts.type === "alert-danger"
              ? this.props.alerts.message
              : null;
          const error =
            this.props.alerts.type === "alert-danger" ? true : false;

          if (errorMessage || error) {
            let showAlert = { ...this.state.showPasswordError };
            showAlert = true;
            this.setState({ showPasswordError: showAlert });
          }
        });
      //this.props.actions.sendData(this.props.token,'accounts/register/',JSON.stringify({"email": this.state.email,"password": this.state.password}),'post');
    });
  };

  submitEmail = e => {
    e.preventDefault();
    const url2 = `/api/v1/accounts/jobapplicationinvitations/?jobapp_id=${this.state.jobappid}&source=startprocess&email=${this.state.email.toLowerCase()}`
    this.props.actions.fetchAndReturn('', url2, 'GET').then((response) => {
      const data = response.body
      if (data.count == 1) {
        this.setState({ fields: data.results[0].fields, utm: data.results[0].utm })
        if ('dni' in data.results[0].fields) {
          this.setState({ dni: data.results[0].fields['dni'] })
        }
      } else {
        this.setState({ fields: {}, utm: {} })
      }

    })
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!this.isValidEmail()) {
        let errors = { ...this.state.errors };
        errors.email = true;
        this.setState({ errors: errors })
      } else {
        const url = 'accounts/exist/?format=json';

        this.props.actions.fetchData(this.props.token, url, '{"mail": "' + this.state.email.toLowerCase() + '"}', 'get');

      }
    });
  };


  start = () => {
    const { userType, isAuthenticated } = this.props
    this.handleButtonCookies('start-button');

    if (userType === 'personal' && isAuthenticated) {
      const url = `/jobapplication/${this.state.jobappid}`;
      this.branchApply().then(() => {
        message.info('Redirigiendo a la postulación...')
        this.props.dispatch(push(url))
      })
      return;
    }
    if (userType === 'business' && isAuthenticated) {
      this.props.authActions.authLogout(this.props.token, '#');
    }
    if (userType === 'business' && isAuthenticated) {
      this.props.authActions.authLogout(this.props.token, '#');
    }
    this.setState({ showStart: true, showWelcome: false })
  };

  recoverPassword = () => {
    const url = '/passwordrecovery?next=' + this.props.location.pathname
    this.props.dispatch(push(url))
  };

  isValidEmail = () => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(this.state.email.toLowerCase());
  }

  validateToNextPassword = (rule, value, callback) => {
    const re = /^(?=.*[0-9])(?=.*[a-z])/;
    // validar si la contraseña nueva tiene un número y una letra minúscula
    if (value && !value.match(re) && value.length >= 6) {
      callback(true);
    }
    callback(); // correcto
  };

  handleDNIChange = (rule, value, callback) => {
    if (value && !validateDNI(this.state.country_document, value)) {
      let errors = { ...this.state.errors };
      errors.dni = true;
      this.setState({ errors: errors });
      callback(
        `El ${countriesIdentification[this.state.country_document]
        } ingresado no cumple las normas`
      );
    }
    callback();
  };

  handleConfirmEmailChange = (rule, value, callback) => {
    let errors = { ...this.state.errors };
    if (value && value.toLowerCase() != this.state.email.toLowerCase()) {
      errors.emailConfirm = true;
      this.setState({ errors: errors });
      callback(i18n.t('start_process__mails__doesnt__match'));
    } else {
      errors.emailConfirm = false;
      this.setState({ errors: errors });
    }
    callback();
  };

  handleCookiesConsentModalButton = (option) => () => {
    if (!option) {
      // Handle pixels revoke
      if (this.state.cookies.find(cookie => cookie.provider === 'FP')) {
        ReactPixel.revokeConsent();
      }
    }
    this.setState({ cookiesConsentDrawerOpen: false });
  }

  createCard = () => {
    let cards = [];
    let mydata = this.props.data;
    let alerts = this.props.alerts;
    if (mydata == null) {
      cards.push(<p>{i18n.t('commons_loading')}</p>)
    }
    else if (mydata['status'] == "ACTIV" || mydata['mail_validation'] == "false") {
      if (mydata['mail_validation'] == "false" && this.state.validMailReplaced) {
        this.state.email = mydata['user_mail'].toLowerCase()
        this.state.validMailReplaced = false;
      }
      if (mydata['status'] == "ACTIV") {
        this.state.welcomeMessage = i18n.t('commons__sign_in')
      }
      cards.push(
        <FirstLogin
          key={1}
          props={this.props}
          state={this.state}
          submitEmail={this.submitEmail}
          handleChange={this.handleChange}
          extractRedirect={this.extractRedirect}
          goBack={this.goBack}
          submitAfterLogin={this.submitAfterLogin}
        />
      );
    }
    else if ((mydata['user_exists'] === "true" && mydata['personal_user'] === "true") || this.props.isDNIAlreadyUsed === true) {
      cards.push(
        <NotFirstLogin
          key={2}
          props={this.props}
          state={this.state}
          alerts={alerts}
          mydata={mydata}
          submitAfterLogin={this.submitAfterLogin}
          submitLoginData={this.submitLoginData}
          handleChange={this.handleChange}
          recoverPassword={this.recoverPassword}
          goBack={this.goBack}
        />
      )

    }
    else if (mydata['user_exists'] === "true" && mydata['personal_user'] === "false") {
      cards.push(
        <InvalidAccount goBack={this.goBack} />
      )
    }
    else if (mydata['user_exists'] == "false") {
      cards.push(
        <Registration
          props={this.props}
          state={this.state}
          countriesIdentification={countriesIdentification}
          goBack={this.goBack}
          handleChange={this.handleChange}
          submitSignupData={this.submitSignupData}
          handleConfirmEmailChange={this.handleConfirmEmailChange}
          validateToNextPassword={this.validateToNextPassword}
          handleChangeCountry={this.handleChangeCountry}
          handleDNIChange={this.handleDNIChange}
          verifyCallback={this.verifyCallback}
          handleTosChange={this.handleTosChange}
          handleTosDialog={this.handleTosDialog}
          checkFields={this.checkFields}
          captchaRef={this.captchaRef}
        />
      );
    }
    return cards
  }

  // Note: have to use componentWillMount, if I add this in constructor will get error:
  // Warning: setState(...): Cannot update during an existing state transition (such as within `render`).
  // Render methods should be a pure function of props and state.
  componentDidMount() {
    window.scrollTo(0, 0);
    const token = this.props.token;
    const jobappid = this.props.match.params["jobappid"];
    this.state.jobappid = jobappid;
    this.setState({ jobappid });
    this.getCountries()
    const url = `gamesandtests/jobapplications/${this.state.jobappid}/public/`;
    this.props.actions.fetchData(token, url, "", "get").then((res) => {
      if (this.props.error) {
        let urlNotFound = "/NotFound404";
        this.props.dispatch(push(urlNotFound));
      }

      if (res.branches.length > 0 && Object.keys(this.props.selectedBranches).length == 0) {
        this.props.dispatch(push(`/jobs/${res.business?.slug}`))
      }
      const { business } = res;
      this.getCookies(business?.id);  // --> necesitamos un cambio con cookies
      // // // VA ESTA RESPONSE DE ABAJO
      // // this.setState({ business_user_slug: res?.business_user });
      this.setState({ business_user_slug: res?.business.slug });
      //this.setState({ backgroundImage: res.business.background_image })
      this.setState({ color_theme: res.business.color_theme, backgroundImage_banner: res.business.jobs_site_background_image });
    });


    // TEMPORAL: Guardar campos utm en redux
    const utm_params = {};
    if (window.location.search.includes('?')) {
      const queryDict = queryString.parse(window.location.search);
      Object.keys(queryDict).forEach(key => {
        const value = queryDict[key]
        if (key.includes('utm')) {
          const field = key.split('_')[1];
          utm_params[`${field}`] = value;
          delete queryDict[key];
        }
      });
      this.setState({ queryParams: queryDict });
    }
    this.props.actions.saveUtmParams(utm_params);
  };

  scrollToDescription = () => {
    this.handleButtonCookies('more-button');
  }

  hasDescription = (html) => {
    return html ? true : false
  }

  handleButtonCookies = (buttonId) => {
    this.state.cookies.forEach((cookie) => {
      // Facebook pixel
      if (cookie.category === 'OC' && cookie.provider === 'FP' && cookie.element_id === buttonId) {
        ReactPixel.track(cookie.client_id, cookie.data);
      }
    })
  }

  render() {
    const { data, classes, error, loading, products, alerts, ...rest } =
      this.props;

    return (
      <div className="start-view" style={{ backgroundColor: '#FFFFFF' }}>
        <AlertDialogSlide
          title={this.state.title}
          open={this.state.openModal}
          content={this.state.content}
          accept="true"
          handleAccept={this.handleModalClose}
        />
        <div>
          {this.state.showStart && (
            <div className="protected" id="mainDiv">
              <Modal
                title={i18n.t('form__terms_of_use__genomawork')}
                visible={this.state.tosDialog}
                onOk={this.handleTosDialog}
                onCancel={this.handleTosDialog}
                centered={true}
                className="terms-and-conditions-modal"
                footer={[
                  <Button
                    key="back"
                    type="primary"
                    onClick={this.handleTosDialog}
                  >
                    {i18n.t('commons__close')}
                  </Button>,
                ]}
              >
                <iframe
                  credentialless=""
                  width="500"
                  height="500"
                  src="https://docs.google.com/document/d/e/2PACX-1vT4odFJk2URzMvEF3GADBndjXdLqVhPHaGNEdhEvZmieRH00HQiamys_mQECH-17aXTbiUaW2iqSiek/pub?embedded=true"
                ></iframe>
              </Modal>
              <Layout className="main-layout-cards">
                <Row
                  type="flex"
                  align="middle"
                  style={{ height: '100%', background: 'white' }}
                >
                  <Col className="main-layout-card__cols" xs={24} md={12}>
                    {alerts.type === 'alert-warning' && (
                      <div
                        className="margin-top-error"
                        style={{ marginLeft: 10 }}
                      >
                        <div className={'alert ' + alerts.type}>
                          <b>{alerts.message}</b>
                        </div>
                      </div>
                    )}
                    <Row
                      type="flex"
                      justify="center"
                      align="middle"
                      style={{
                        flexDirection: 'column',
                        height: '100%',
                        width: '100%',
                      }}
                    >
                      {this.props.isFetching === true ? (
                        <Spin size="large" />
                      ) : (
                        this.createCard()
                      )}
                    </Row>
                  </Col>
                  <Col className="main-layout-cards__cols" xs={24} md={12}>
                    <div className="main-layout-cards__img">
                      <Cover
                        background_image={this.state.backgroundImage}
                        theme={this.state.color_theme}
                      />
                    </div>
                  </Col>
                </Row>
              </Layout>
            </div>
          )}

          {this.state.showWelcome && (
            <NewWelcomeView
              theme={this.state.color_theme}
              data={data}
              start={this.start}
              banner={this.state.backgroundImage}
              handleKnowMore={this.scrollToDescription}
            />
          )}
        </div>

        <ReCAPTCHA
          ref={this.captchaRef}
          sitekey={process.env.REACT_APP_CAPTCHA_API_KEY}
          onChange={this.verifyCallback}
          hl={i18n.language.split('-')[0]}
          size="invisible"
        />

        <Drawer
          // title="Consentimiento informado de uso de cookies"
          placement={'bottom'}
          visible={this.state.cookiesConsentDrawerOpen}
          mask={false}
          className="cookiesConsent"
          wrapClassName="cookiesConsent"
          height="auto"
          onClose={this.handleCookiesConsentModalButton(true)}
        >
          <Typography.Title level={4}>
            {i18n.t('start_process__cookies__title')}
          </Typography.Title>
          <Text className="paragraph">
            {`${i18n.t('start_process__cookies__1').split('{')[0]} ${
              data.business_user
            } ${i18n.t('start_process__cookies__1').split('}')[1]}`}
          </Text>
          <Text className="paragraph">
            {i18n.t('start_process__cookies__2')}
          </Text>
          <Text className="paragraph">
            {i18n.t('start_process__cookies__3')}
          </Text>
          <Row justify="center">
            <Col sm={24} md={4}>
              <Button
                className="cookies-button reject-cookies-button"
                onClick={this.handleCookiesConsentModalButton(false)}
              >
                {i18n.t('start__process__cookies__use_reject')}
              </Button>
            </Col>
            <Col sm={24} md={4}>
              <Button
                className="cookies-button accept-cookies-button"
                onClick={this.handleCookiesConsentModalButton(true)}
              >
                {i18n.t('start__process__cookies__use_accept')}
              </Button>
            </Col>
          </Row>
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedBranches: state.data.selectedBranches,
    token: state.auth.token,
    data: state.data.data,
    isFetching: state.data.isFetching,
    error: state.data.error,
    isDNIAlreadyUsed: state.auth.isDNIAlreadyUsed,
    userMail: state.auth.userName,
    userID: state.auth.userID,
    errors: state.auth.errors,
    isAuthenticated: state.auth.isAuthenticated,
    userType: state.auth.userType,
    isAuthenticating: state.auth.isAuthenticating,
    isCreatingAccount: state.auth.isCreatingAccount,
    alerts: state.alerts,
    status: state.data.data.status,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    authActions: bindActionCreators(authActionCreators, dispatch),
    dispatch
  };
};

const Forms = Form.create({ name: 'horizontal_login' })(StartJobApplicationView);
export default connect(mapStateToProps, mapDispatchToProps)(Forms);
export { Forms as StartJobApplicationViewNotConnected };
