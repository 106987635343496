import React, { useState } from 'react'

import {
  Input,
  Modal,
  Row,
  Typography,
} from 'antd';
import i18n from '../i18n';

const fieldTypes = {
  JOBAPP: ['este proceso', 'todos los procesos'],
  USERJA: ['esta postulación', 'todas las postulaciones'],
  CANDID: ['este candidato', 'todos los candidatos'],
};

const NewCustomFieldModal = (props) => {
  const [name, setName] = useState('');
  const [value, setValue] = useState(null);
  const [validName, setValidName] = useState(undefined);

  const nameRegEx = /^[A-Za-z\u00f1\u00d1]+[A-Za-z0-9\u00f1\u00d1]*[A-Za-z0-9\u00f1\u00d1 _]*$/;

  return (
    <Modal
      cancelText={i18n.t('profile__cancel', {ns: 'candidateProfile'})}
      closable={false}
      okButtonProps={{ disabled: !validName }}
      okText={i18n.t('profile__add_attribute', {ns: 'candidateProfile'}).split(' ')[0]}
      onCancel={() => {
        props.closeModal();
        setName('');
        setValue(null);
      }}
      onOk={() => {
        props.handleCustomFields(name.toLowerCase().replaceAll(' ', '_'), value, props.type, false);
        setName('');
        setValue(null);
        props.closeModal();
      }}
      title={i18n.t('profile__add_attribute', {ns: 'candidateProfile'})}
      visible={props.visible && props.type !== undefined}
      width={800}
    >
      <Row>
        <Typography.Paragraph>
          {fieldTypes[props.type][0] === 'este candidato' ?
          (<> {i18n.t('profile__add_an_attribute_candidate', {ns: 'candidateProfile'}).split('*')[0]}
          <strong> {i18n.t('profile__add_an_attribute_candidate', {ns: 'candidateProfile'}).split('*')[1]}</strong>{i18n.t('profile__add_an_attribute_candidate', {ns: 'candidateProfile'}).split('*')[2]} <a href="/customfields" target="_blank">{i18n.t('profile__add_an_attribute_candidate', {ns: 'candidateProfile'}).split('*')[3]}</a> </>)
          :  fieldTypes[props.type][0] === 'esta postulación' ? 
          (<> {i18n.t('profile__add_an_attribute_postulation', {ns: 'candidateProfile'}).split('*')[0]}
          <strong> {i18n.t('profile__add_an_attribute_postulation', {ns: 'candidateProfile'}).split('*')[1]}</strong>{i18n.t('profile__add_an_attribute_postulation', {ns: 'candidateProfile'}).split('*')[2]} <a href="/customfields" target="_blank">{i18n.t('profile__add_an_attribute_postulation', {ns: 'candidateProfile'}).split('*')[3]}</a> </>)
          :(<>
             Este atributo se agregará exclusivamente a {fieldTypes[props.type][0]}, y solo puede ser de tipo
             <strong> texto</strong>. Si quieres agregar un atributo que aplique a {fieldTypes[props.type][1]},
             o que sea de otro tipo, <a href="/customfields" target="_blank">crealo aquí</a>
             </>)
          }
        </Typography.Paragraph>
      </Row>
      <Row style={{ marginBottom: '10px' }}>
        <span style={{ color: 'red' }}>*</span>{i18n.t('profile__attribute_name', {ns: 'candidateProfile'})}
        <Input
          onChange={(event) => {
            setValidName(
              !!event.target.value.match(nameRegEx)
                && !props.existingFieldsKeys.includes(
                  event.target.value.toLocaleLowerCase().replaceAll(' ', '_')
                )
                && !props.existingFieldsKeys.includes(event.target.value)
            );
            setName(event.target.value);
          }}
          value={name}
        />
        {validName === false && (
          <span>
            {i18n.t('custom_attribute_name_constrain', {ns: 'candidateProfile'})}
          </span>
        )}
      </Row>
      <Row>
        {i18n.t('profile__value', {ns: 'candidateProfile'})}
        <Input
          onChange={(event) => setValue(event.target.value)}
          value={value}
        />
      </Row>
    </Modal>
  )
};

export default NewCustomFieldModal;
