import React from 'react';
import { Progress, Tooltip } from 'antd';
import i18n from '../i18n';

export default function FitProgress({ percent, showLegend = null, ...rest }) {
  const customProgressColor = () => {
    // Green
    if (percent >= 85) {
      return {
        '0%': '#71D83E',
        '100%': '#71D83E',
      };
    }
    // Red
    else if (percent < 60) {
      return {
        '0%': '#ff6961',
        '100%': '#ff6961',
      };
    }
    // Yellow
    else {
      return {
        '100%': '#FAAD14',
        '0%': '#FAAD14',
      };
    }
  };

  // return (
  //   <Progress
  //     format={percent => `${percent}%`}
  //     strokeColor={customProgressColor()}
  //     {...rest}
  //   />
  // );

  const getFormat = percent => {
    const value = percent !== null ? `${percent}%` : 'N/D';

    return () =>
      showLegend ? (
        <div>
          <p>FIT</p>
          <p>{value}</p>
        </div>
      ) : (
        <>{value}</>
      );
  };

  return percent !== null ? (
    <Progress
      format={getFormat(percent)}
      strokeColor={customProgressColor()}
      percent={percent}
      {...rest}
    />
  ) : (
    <Tooltip title={i18n.t('profile__without_fit_tooltip', {ns: 'candidateProfile'})}>
      <Progress {...rest} percent={0} format={getFormat(null)} />
    </Tooltip>
  );
}
