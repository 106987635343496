import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import * as authActionCreators from '../../actions/auth';
import * as actionCreators from '../../actions/data';

import {
  Col,
  Row,
  Icon,
  Input,
  Form,
  message,
  Modal,
  Typography,
  Switch
} from 'antd';
import QRCode from 'qrcode.react';

const { Paragraph, Text, Title } = Typography;

function MfaConfigView(props) {

  const [isActive, setIsActive] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [currentOtp, setCurrentOtp] = useState('');

  const [currentMfaHash, setCurrentMfaHash] = useState(null);
  const [currentQrOtp, setCurrentQrOtp] = useState(null);

  useEffect(() => {
    const activeMfa = props.mfaHash ? true : false;
    setIsActive(activeMfa);
    setCurrentMfaHash(props.mfaHash);
  }, [props.mfaHash])

  const updateMfa = () => {
    const endpoint = `/api/v1/accounts/users/${props.userId}/mfa/`;
    props.actions.fetchAndReturn(props.token, endpoint, 'PATCH', { "mfa_active": !isActive, "mfa_hash": currentMfaHash, "otp": currentOtp })
      .then(response => {
        if (response.status >= 400) {
          message.error('Error al enviar los datos. Por favor, inténtalo nuevamente', 3);
          setCurrentOtp('')
        }
        else {
          // Update redux and local storage
          props.authActions.updateAuthDataAction({ keyName: 'mfaHash', value: currentMfaHash })
          const user = {
            ...JSON.parse(localStorage.getItem('user')),
            mfa_hash: response.body.mfa_hash
          };
          localStorage.setItem('user', JSON.stringify(user));

          setIsVisible(false);
          setIsActive(!isActive);
          setCurrentOtp('');
          message.success('Configuración actualizada', 3);
        }
      })
  }

  const getMfaHash = () => {
    if (currentMfaHash && currentQrOtp) {
      setIsVisible(true);
    }
    const endpoint = `/api/v1/accounts/users/${props.userId}/activatemfa/`;
    props.actions.fetchAndReturn(props.token, endpoint, 'GET')
      .then(response => {
        if (response.status >= 400) {
          message.error('Error al enviar los datos. Por favor, inténtalo nuevamente', 3);
        }
        else {
          setCurrentMfaHash(response.body.mfa_hash);
          setCurrentQrOtp(response.body.qr_otp)
          setIsVisible(true);
        }
      })
  }

  const qrModal = () => (
    <>
      <div style={{ marginBottom: 20 }}>
        Utiliza una aplicación de autenticación multifactor (Google Authenticator, Authy, LastPass u otra), escanea este código QR y luego ingresa el código de seguridad.
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', border: '1px solid #d9d9d9', padding: 20, marginBottom: 20 }}>
        <QRCode
          value={currentQrOtp ? currentQrOtp : ''}
          size={150}
        />
      </div>
      <Input
        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
        placeholder="Código de seguridad"
        onChange={(e) => setCurrentOtp(e.target.value)}
      />
    </>
  )

  const confirmModal = () => (
    <>
      <div style={{ textAlign: 'center' }}>
        <Icon type="info-circle" style={{ fontSize: 64, color: '#1890FF', margin: 12 }} />
      </div>
      <div style={{ marginBottom: 20 }}>
        Ingresa el código de seguridad y selecciona <Text strong>Aceptar</Text> para actualizar tu configuración
      </div>
      <Input
        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
        placeholder="Código de seguridad"
        onChange={(e) => setCurrentOtp(e.target.value)}
      />
    </>
  )

  const onChange = () => {
    if (!isActive) {
      getMfaHash();
    }
    else {
      setIsVisible(true);
    }
  }

  return (
    <div className='account-config-container'>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Icon type="arrow-left" style={{ display: 'flex', marginBottom: '0.5em', marginRight: 10 }} onClick={() => props.dispatch(push('/account/settings'))} />
        <Title level={2}>Autenticación multifactor</Title>
      </div>
      <div className='account-mfa-card'>
        <Paragraph style={{ fontSize: 16 }}>
          Es una tecnología de seguridad que requiere métodos de autenticación de credenciales para verificar la identidad de un usuario en un inicio de sesión. Nuestra plataforma soporta las siguientes aplicaciones <Text strong>(Google Authenticator, Authy, LastPass u otra)</Text> que puedes descargar desde AppStore o PlayStore. Estas aplicaciones te permitirán escanear el código QR que habilitará la plataforma para ingresar el código MFA.
        </Paragraph>

        <Paragraph style={{ whiteSpace: 'pre-line', fontSize: 16 }}>
          <div>
            Ten en cuenta:
          </div>
          <div>
            {<Text strong>Activar MFA</Text>}
            : Implica habilitar un nivel adicional de seguridad (interfaz) al iniciar sesión en tu cuenta.
          </div>

          <div>
            {<Text strong>Desactivar MFA</Text>}
            : Implica deshabilitar el nivel adicional de seguridad (interfaz) al iniciar sesión en tu cuenta.
          </div>

        </Paragraph>
      </div>


      <Row type='flex'>
        <Col xs={24} sm={24} md={12} lg={8}>
          <div className='account-base-card' style={{ marginTop: 20 }}>
            <Text strong style={{ marginBottom: 30 }}> Autenticación Multifactor </Text>

            <Switch checkedChildren="Activa" unCheckedChildren="Inactiva" checked={isActive} onChange={onChange} />
          </div>
        </Col>
      </Row>

      <Modal
        maskClosable={true}
        onOk={() => updateMfa()}
        onCancel={() => setIsVisible(false)}
        title={"Autenticación multifactor"}
        okType={'primary'}
        okText={'Aceptar'}
        cancelText={'Cerrar'}
        visible={isVisible}
      >
        {isActive ?
          confirmModal() :
          qrModal()
        }
      </Modal>

    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    authActions: bindActionCreators(authActionCreators, dispatch),
    dispatch,
  };
};

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    userId: state.auth.userID,
    mfaHash: state.auth.mfaHash,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MfaConfigView);
