import React from 'react';

import Summary from '../pdfCommons/Summary';
import FooterPage from '../pdfCommons/FooterPage';
import CoverPage from '../pdfCommons/CoverPage';
import Results from './components/Results';
import AlternativeDetail from './components/AlternativeDetail';
import './pdf-sjt.scss';

const PdfSJT = ({ data, refPrint, candidateInfo, jobName }) => {
  return (
    <div ref={refPrint} className='sjt-pdf-modal'>
      <section className='sjt-pdf-modal__coverpage print'>
        <CoverPage name={data.answer.custom_test.title} />
      </section>
      <section className='sjt-pdf-modal__mainsection print'>
        <Summary
          candidateInfo={candidateInfo}
          jobName={jobName}
          description={data.answer.description}
          testName={data.name}
          dateCreated={data.answer.date_created}
        />
        <Results scores={data.answer.scores} scoresConfig={data.answer.scores_config} />
      </section>
      <FooterPage />
      <section className='sjt-pdf-modal__mainsection print'>
        <AlternativeDetail 
          answers={data.answer.answers} 
          questions={data.answer.questions} 
          scores={data.answer.scores} 
          scoresConfig={data.answer.scores_config}
          sortedQuestions={data.answer.sorted_questions}
        /> 
      </section>
      <FooterPage />
    </div>
  );
}

export default PdfSJT;
