import React from 'react';

import { Tooltip, Typography, Icon } from 'antd';

const { Text } = Typography;

const assistantsTypes = {
  creator: 'Creador',
  candidate: 'Candidato',
};

const AssistantBadge = ({ assistant = { email: '' }, type }) => {
  return (
    <Tooltip title={assistantsTypes[type]}>
      <div className={`assistant-badge assistant-${type}`}>
        <Text>
          {assistant.email[0]?.toUpperCase()}
          {assistant.email[1]?.toUpperCase()}
        </Text>
        {type === 'creator' ||
        type === 'collab' ||
        assistant.status === 'ACCEPTED' ? (
          <Icon
            type="check-circle"
            theme="filled"
            style={{ color: '#52C41A' }}
          />
        ) : assistant.status === 'PENDING' ? (
          <Icon
            type="question-circle"
            theme="filled"
            style={{ color: '#FFBA00' }}
          />
        ) : (
          <Icon
            type="close-circle"
            theme="filled"
            style={{ color: '#FF4D4F' }}
          />
        )}
      </div>
    </Tooltip>
  );
};

export default AssistantBadge;
