import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../actions/data';
import * as authActionCreators from '../../../actions/auth';

import {
  Form,
  Upload,
  Button,
  Icon,
  Typography,
  Spin,
} from 'antd';

class FileUpload extends React.Component {

  state = {
    file: null,
    fileReady: false,
    loadingFile: false,
  }

  handleChangeFile = async (file) => {
    if (file > 5242880) {
      alert('El tamaño del archivo excede los 5MB. Por favor intentarlo nuevamente con un archivo mas liviano');
    }
    else {
      this.sendFile(file)
    }
  };

  sendFile = async (file) => {
    this.setState({ loadingFile: true })
    await this.setState({ file: file });
    let url = 'gamesandtests/additionalfileupload/' + this.props.jobappid + '/' + file.name
    await this.props.actions.sendFile(this.props.token, url, file, file.name, '', this.props.question).then(() => {
      this.setState({ fileReady: true, loadingFile: false })
      this.props.handleFileChange(this.props.index, true);
    });
  };

  render() {
    const { question, additionals, error, mandatory } = this.props
    return (
      <React.Fragment>
        <Form.Item name={"integerField"}>
          <div style={{marginBottom:10}}>
            {mandatory && <Typography.Text style={{ color: 'red' }}>* </Typography.Text>}
            <Typography.Text strong>
              {"Documento: " + question}
            </Typography.Text>
          </div>
          <div style={{marginBottom: 5}}>
            {additionals?.instructions}
          </div>
          <Upload.Dragger
            name='file'
            accept=".doc,.docx,.pdf"
            disabled={this.state.loadingFile}
            onRemove={() => {
              this.setState({ file: null });
            }}
            beforeUpload={() => {
              // prevent antd upload
              return false;
            }}
            onChange={(info) => {
              this.handleChangeFile(info.file)
            }}
          >
            <Spin tip="Subiendo archivo..." spinning={this.state.loadingFile}>
              <div className='pf__form-wrapper__cloud'>
                <div className='cloud-container'>
                  <svg width="101" height="61" viewBox="0 0 161 121" role="presentation" focusable="false" tabIndex="-1" className="upload-shot-cloud fill-current"><path d="M127.452 47.224C127.458 21.067 106.434 0 80.5 0 54.566 0 33.542 21.067 33.542 47.056 14.745 48.918 0 64.742 0 84.028 0 104.45 16.516 121 36.896 121h87.208C144.484 121 161 104.45 161 84.028c0-19.286-14.745-35.11-33.548-36.804z"></path></svg>
                  <div className="cloud-arrow-container"><svg height="18" viewBox="0 0 75 48" width="75" role="presentation" focusable="false" tabIndex="-1" className="upload-shot-arrow fill-current"><path fill='white' d="M24 47.111H6.736c-5.978 0-8.97-7.24-4.743-11.475L37.5.056l35.507 35.58c4.226 4.235 1.235 11.475-4.743 11.475H50.995z"></path></svg></div>
                  <div className="cloud-arrow-stripes"><div className="cloud-arrow-stripe red"></div> <div className="cloud-arrow-stripe purple"></div> <div className="cloud-arrow-stripe blue"></div></div>
                </div>

                <div className="ant-upload-text"><strong>Haz clic en esta área para subir tu CV</strong></div>
                <div className="ant-upload-text">Solo en formato Word (.doc, .docx) o PDF (.pdf). Máximo 4MB</div>
              </div>
            </Spin>
          </Upload.Dragger>
          {this.state.fileReady ? (
            <Button style={{ backgroundColor: 'rgba(17, 255, 2, 0.2)' }} variant="contained" size="large" color="primary" disabled>
              <Icon type='check' /> {question} subido/a exitosamente
            </Button>) : (<div />)}
          {error &&
            <Typography style={{ color: 'red', marginTop: 3, fontSize: 12 }}>Tienes que subir este documento para continuar.</Typography>
          }
        </Form.Item>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.data.data,
    isFetching: state.data.isFetching,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    authActions: bindActionCreators(authActionCreators, dispatch),
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);
