export const getTemplates = async (
  setLoadingFn,
  setEmailTemplatesFn,
  props,
  { error }
) => {
  try {
    setLoadingFn(true);
    const url = 'accounts/mailtemplate/';

    const token = props.token;
    const { results } = await props.actions.fetchData(token, url, '', 'get');
    setEmailTemplatesFn(results.reverse());
    setLoadingFn(false);
  } catch (e) {
    setLoadingFn(false);
    error('No se pudieron obtener los mail templates');
  }
};

export const getCategories = async (setCategoriesFn, props, { error }) => {
  setCategoriesFn((prevState) => ({ ...prevState, loading: true }));
  try {
    const url = 'accounts/mailtemplatecategories/';
    const token = props.token;
    const { results } = await props.actions.fetchData(token, url, '', 'get');
    setCategoriesFn({ loading: false, data: results });
  } catch (e) {
    setCategoriesFn((prevState) => ({ ...prevState, loading: false }));
    error('No se pudieron obtener los mail templates');
  }
};

export const deleteCategory = async (
  { id },
  props,
  { success, error },
) => {
  try {
    const url = `/api/v1/accounts/mailtemplatecategories/${id}/`;
    const token = props.token;

    const response = await props.actions.fetchAndReturn(token, url, 'DELETE');
    if (response.status == 400) {
      error('No es posible eliminar categoria con templates', 3);
    }
    // Permission denied or business_user does not match -> 404
    else if (response.status == 403 || response.status == 404) {
      error('No tienes permisos para eliminar la categoría', 3);
    }
    else {
      success('La categoría ha sido eliminada', 3);
    }
  } catch (e) {
    error('No se pudo borrar la categoría', 3);
  }
};


export const deleteTemplate = async (
  { id },
  props,
  { success, error },
  setIsActiveFn,
  getTemplatesFn,
  setEmailTemplatesFn,
  setLoadingFn
) => {
  try {
    const url = `accounts/mailtemplates/${id}/`;

    const token = props.token;

    await props.actions.fetchData(token, url, '', 'DELETE');
    getTemplatesFn(setLoadingFn, setEmailTemplatesFn, props, error);
    success('La plantilla ha sido eliminada', 3);
    setIsActiveFn({ id: null });
  } catch (e) {
    error('No se pudo borrar el mail template');
  }
};

