export const availability = {
  4:	'Comisionista',
  8:	'Free Lance',
  1:	'Jornada Completa',
  2:	'Media Jornada',
  3:	'Part Time',
  7:	'Por Turnos',
  6:	'Práctica Profesional',
  5:	'Reemplazo',
  9:	'Teletrabajo'
}

export const paymentMethods = {
  2:	'Dólares',
  3:	'Euros',
  1:	'Pesos Chilenos'
}

export const jobTypes = {
  28: 'Abogado',
  29: 'Abogado Independiente',
  30: 'Administrador',
  17: 'Administrativo',
  26: 'Agente',
  31: 'Ama de casa',
  2: 'Analista',
  32: 'Asesor',
  3: 'Asistente',
  33: 'Asociado',
  34: 'Auditor',
  35: 'Auxiliar',
  36: 'Ayudante',
  37: 'Becario',
  62: 'Cajero',
  27: 'Cobrador',
  63: 'Comercial',
  4: 'Consultor/Asesor',
  38: 'Contador',
  39: 'Contralor',
  40: 'Coordinador',
  41: 'Desarrollador',
  42: 'Director',
  25: 'Diseñador',
  43: 'Dueño',
  67: 'Educador/Docente',
  44: 'Ejecutivo Cuenta',
  5: 'Ejecutivo Junior',
  6: 'Ejecutivo Senior',
  45: 'Encargado',
  46: 'Especialista',
  16: 'Estudiante',
  47: 'Estudiante de Posgrado',
  22: 'Estudiante para tesis',
  48: 'Gerente',
  10: 'Gerente/Director de Área',
  11: 'Gerente/Director de División',
  13: 'Gerente/Director General',
  49: 'Ingeniero',
  50: 'Inspector',
  8: 'Jefe Área/Sección/Depto./Local',
  51: 'Líder de Proyecto',
  60: 'Maestro de cocina',
  66: 'Médico',
  5: 'Miembro de Directorio',
  52: 'Notario',
  19: 'Operario',
  20: 'Operativo',
  1: 'Otro Profesional',
  54: 'Planeador',
  21: 'Practica profesional',
  14: 'Presidente/Director Ejecutivo',
  64: 'Profesional Independiente',
  55: 'Profesor / Investigador',
  56: 'Promotor',
  61: 'Reponedor / Repositor',
  57: 'Representante',
  58: 'Socio',
  9: 'Subgerente de Área',
  12: 'Subgerente/Subdirector General',
  7: 'Supervisor',
  18: 'Técnico',
  24: 'Técnico Superior Universitario',
  59: 'Tesorero',
  65: 'Vendedor'
}

//arry to order alphabetically
export const  businessActOrderedArray = [
  26,1,75,59,2,76,3,4,60,77,5,71,6,7,78,8,72,79,11,65, 64,58,12,13,14,15,16,17,80,18,81,
  82,57,84,85,20,21,22,23,24,25,73,63,97,27,28,67,29,30,31,32,33,86,34,35,87,36,37,38,39,
  41,42,55,88,69,43,40,89,90,91,66,44,98,61,68,92,70,46,93,47,74,48,49,94,95,50,51,52,53,
  54,56,96,
]

export const businessActivity = {
  26: 'Administración Pública',
  1: 'Aeronaves / Astilleros',
  75: 'Afore',
  59:'Agencia de Aduanas',
  2: 'Agrícola / Ganadera',
  76: 'Agroindustria',
  3: 'Agropecuaria',
  4: 'Agua / Obras Sanitarias',
  60: 'Alimentos',
  77: 'Ambiental',
  5: 'Arquitectura / Diseño / Decoración',
  71: 'Atelier de diseño',
  6: 'Automotriz',
  7: 'Banca / Financiera',
  78: 'Bebidas',
  8: 'Biotecnologia',
  72: 'Boutique9Carpintería / Muebles',
  79: 'Cemento y Materiales10Científica',
  11: 'Combustibles (Gas / Petróleo)',
  65: 'Comercial',
  64: 'Comercio Electrónico',
  58: 'Comercio Exterior',
  12: 'Comercio Mayorista',
  13: 'Comercio Minorista',
  14: 'Confecciones',
  15: 'Construcción',
  16: 'Consultoria / Asesoría',
  17: 'Consumo masivo',
  80: 'Cultura',
  18: 'Defensa',
  81: 'Departamentales',
  82: 'Despachos de Abogados',
  57: 'Distribuidora',
  84: 'Editorial e Imprenta19Educación / Capacitación',
  85: 'Electrónica de Consumo',
  20: 'Energía / Electricidad / Electrónica',
  21: 'Entretenimiento',
  22: 'Estudios Jurídicos',
  23: 'Exportación / Importación',
  24: 'Farmacéutica',
  25: 'Forestal / Papel / Celulosa',
  73: 'Grandes Almacenes',
  63: 'Grandes Tiendas',
  97: 'Hipermercados',
  27: 'Hotelería / Restaurantes',
  28: 'Imprenta / Editoriales',
  67: 'Industrial',
  29: 'Informática / Tecnología',
  30: 'Ingeniería',
  31: 'Inmobiliaria/Propiedades',
  32: 'Internet',
  33: 'Inversiones (Soc / Cías / Holding)',
  86: 'Investigación',
  34: 'Logística / Distribución',
  35: 'Manufacturas Varias',
  87: 'Maquinaria y Equipo',
  36: 'Medicina / Salud',
  37: 'Medios de Comunicación',
  38: 'Metalmecánica',
  39: 'Minería',
  41: 'Naviera',
  42: 'Organizaciones sin Fines de Lucro',
  55: 'Otra Actividad',
  88: 'Papel y Cartón',
  69: 'Pesquera',
  43: 'Pesquera / Cultivos Marinos',
  40: 'Petroleo / Gas / Combustibles',
  89: 'Poder ejecutivo y administración pública',
  90: 'Poder judicial',
  91: 'Poder legislativo',
  66: 'Productora de Cine y Tv',
  44: 'Publicidad / Marketing / RRPP45Química',
  98: 'Recursos Humanos',
  61: 'Retail',
  68: 'Salmonera',
  92: 'Sector energético',
  70: 'Seguridad',
  46: 'Seguros / Previsión',
  93: 'Servicios de Salud',
  47: 'Servicios Financieros Varios',
  74: 'Servicios funerarios',
  48: 'Servicios Varios',
  49: 'Siderurgia',
  94: 'Siderurgía y Metalurgía',
  95: 'Tabaco',
  50: 'Tecnologías de Información',
  51: 'Telecomunicaciones',
  52: 'Textil',
  53: 'Transporte',
  54: 'Turismo',
  56: 'Ventas',
  96: 'Vidrio y envases'
}

export const areasOrderedArray = [
  173,1,2,92,3,93,94,95,4,54,5,74,96,6,97,98,166,170,68,99,100,89,167,53,7,101,8,103,9,102,87,63,10,
  168,82,79,81,104,58,105,106,11,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,78,
  85,59,12,13,123,124,14,125,84,126,15,127,128,129,64,130,16,17,131,65,66,57,60,132,18,133,50,61,19,
  134,20,21,175,172,72,135,136,22,83,90,181,47,138,139,23,140,49,141,52,142,143,144,80,24,145,29,73,
  25,26,27,28,146,147,148,149,62,150,151,71,86,30,51,171,152,76,178,31,32,153,33,179,154,155,34,157,
  174,56,158,35,159,36,160,161,37,162,38,39,67,77,40,163,41,180,164,42,69,165,48,55,43,70,75,91,44,
  45,46
]

export const areas = {
  173:	'Abastecimiento',
  1:	'Administración',
  2:	'Administración Pública',
  92:	'Aduanas',
  3:	'Agronomía',
  93:	'Alimentos y Bebidas',
  94:	'Ambiental',
  95:	'Amparo',
  4:	'Arquitectura',
  54:	'Aseo',
  5:	'Asistente y Secretaria',
  74:	'Auditoría',
  96:	'Automatización / Instrumentación',
  6:	'Banca y Servicios Financieros',
  97:	'Bursátil',
  98:	'Cadena de Suministro',
  166:	'Calidad',
  170:	'Call Center',
  68:	'Capacitación',
  99:	'Category management',
  100:	'Clinica',
  89:	'Cobranza',
  167:	'Comedores Industriales',
  53:	'Comercial',
  7:	'Comercio Exterior',
  101:	'Compras',
  8:	'Computación e Informática',
  103:	'Comunicación / Medios',
  9:	'Comunicación Audiovisual',
  102:	'Comunicación Institucional',
  87:	'Confección',
  63:	'Construcción',
  10:	'Contabilidad',
  168:	'Contaduría',
  82:	'Contraloría',
  79:	'Control de Calidad',
  81:	'Control de Gestión',
  104:	'Corporativa',
  58:	'Costura',
  105:	'Crédito',
  106:	'Cuentas clave',
  11:	'Decoración',
  107:	'Derecho',
  108:	'Derecho Administrativo',
  109:	'Derecho Aduanero',
  110:	'Derecho Bancario y Bursátil',
  111:	'Derecho Civil',
  112:	'Derecho Constitucional',
  113:	'Derecho Electoral',
  114:	'Derecho Energético',
  115:	'Derecho Familiar',
  116:	'Derecho Financiero',
  117:	'Derecho Internacional',
  118:	'Derecho Laboral',
  119:	'Derecho Mercantil',
  120:	'Derecho Notarial',
  121:	'Derecho Penal',
  122:	'Derechos Humanos',
  78:	'Desarrollo',
  85:	'Desarrollo de Producto',
  59:	'Despacho',
  12:	'Dibujante',
  13:	'Digitadores',
  123:	'Dirección en servicios de salud',
  124:	'Dirección General',
  14:	'Diseño',
  125:	'Diseño Audiovisual',
  84:	'Diseño de Confecciones',
  126:	'Diseño Industrial',
  15:	'Distribución',
  127:	'Docencia',
  128:	'Economía',
  129:	'Edición/Redacción',
  64:	'Edificación',
  130:	'Editorialismo médico',
  16:	'Educación / Docencia',
  17:	'Educación Física',
  131: 'Educación médica',
  65:	'Electricidad',
  66:	'Electrónica',
  57:	'Empaque',
  60:	'Envasado',
  132:	'Epidemiología',
  18:	'Estética',
  133:	'Estimulación temprana',
  50:	'Estudio',
  61:	'Facturación',
  19:	'Finanzas',
  134:	'Fiscal',
  20:	'Fotografía',
  21:	'Gastronomía',
  175:	'Geotécnia',
  172:	'Gerencia',
  72:	'Gestión',
  135:	'Hospitalaria',
  136:	'Hoteles',
  22:	'Idiomas',
  83:	'Ilustración de Figurín',
  90:	'Impuestos',
  181:	'Industria',
  47:	'Informática',
  138:	'Informática â€“ hardware',
  139:	'Informática â€“ internet',
  23:	'Ingeniería',
  140:	'Inteligencia de Negocios',
  49:	'Internet',
  141:	'Inversión Extranjera',
  52:	'Inversiones',
  142:	'Investigación',
  143:	'Investigación clínica',
  144:	'Investigación de mercados',
  80:	'Laboratorio',
  24:	'Leyes',
  145:	'Litigio',
  29:	'Logística',
  73:	'Mantención',
  25:	'Marketing / Mercadeo',
  26:	'Mecánica',
  27:	'Medicina y Salud',
  28:	'Medio Ambiente',
  146:	'Medios de información',
  147:	'Mercadotecnia',
  148:	'Negocios Internacionales',
  149:	'Nóminas',
  62:	'Operaciones',
  150:	'Organización de eventos',
  151:	'Orientación',
  71:	'Otra Área',
  86:	'Patronaje',
  30:	'Periodismo',
  51:	'Planificación y Desarrollo',
  171:	'Post Venta',
  152:	'Precios de transferencia',
  76:	'Prevención de Riesgos',
  178:	'Producción en bosque',
  31:	'Producción y Manufactura',
  32:	'Promociones',
  153:	'Propiedad Industrial e Intelectual',
  33:	'Propiedades',
  179:	'Protección en bosque',
  154:	'Proyectos',
  155:	'Psicopedagógica',
  34:	'Publicidad',
  157:	'Química',
  174:	'Química y Farmacia',
  56:	'Recepción',
  158:	'Reclutamiento y Selección',
  35:	'Recursos Humanos',
  159:	'Redes y Telecomunicaciones',
  36:	'Relaciones Públicas',
  160:	'Salud',
  161:	'Salud Pública',
  37:	'Secretaria Bilingue',
  162:	'Seguridad e Higiene',
  38:	'Seguros',
  39:	'Servicio al Cliente',
  67:	'Servicio Técnico',
  77:	'Servicios',
  40:	'Servicios de Seguridad',
  163:	'Servicios Generales',
  41:	'Servicios Sociales',
  180:	'Silvicultura en bosque',
  164:	'Sociedades Mercantiles',
  42:	'Soporte Administrativo',
  69:	'Suministros',
  165:	'Tecnologías de Información',
  48:	'Telecomunicaciones',
  55:	'Teléfonos',
  43:	'Telemarketing',
  70:	'Todas las Areas',
  75:	'Trade Marketing',
  91:	'Tributarios',
  44:	'Turismo y Hotelería',
  45:	'Ventas',
  46:	'Veterinaria',
}

export const countries = {
  74:	'Afganistán',
  75:	'Albania',
  76:	'Alemania',
  77:	'Andorra',
  78:	'Angola',
  79:	'Anguila',
  80:	'Antártida',
  81:	'Antigua y Barbuda',
  82:	'Antillas Holandesas',
  83:	'Arabia Saudíta',
  2:	'Argentina',
  84:	'Armenia',
  85:	'Aruba',
  86:	'Australia',
  87:	'Austria',
  88:	'Azerbaiyán',
  89:	'Bahamas',
  90:	'Bahrein',
  91:	'Bangladesh',
  92:	'Barbados',
  93:	'Bélgica',
  94:	'Belice',
  95:	'Benín',
  96:	'Bermudas',
  97:	'Bhután',
  98:	'Bielorrusia',
  99:	'Birmania',
  100:	'Bolivia',
  101:	'Bosnia y Herzegovina',
  102:	'Botsuana',
  103:	'Brasil',
  104:	'Brunei',
  105:	'Bulgaria',
  106:	'Burkina Faso',
  107:	'Burundi',
  108:	'Cabo Verde',
  109:	'Camboya',
  110:	'Camerún',
  111:	'Canadá',
  112:	'Chad',
  1:	'Chile',
  113:	'China',
  114:	'Chipre',
  115:	'Ciudad del Vaticano',
  4:	'Colombia',
  116:	'Comores',
  117:	'Congo',
  118:	'Corea',
  119:	'Corea del Norte',
  120:	'Costa Rica',
  121:	'Croacia (Hrvatska)',
  122:	'Cuba',
  123:	'Dinamarca',
  124:	'Djibouri',
  125:	'Dominica',
  126:	'Ecuador',
  127:	'Egipto',
  128:	'El Salvador',
  129:	'Emiratos Árabes Unidos',
  130:	'Eritrea',
  131:	'Eslovaquia',
  132:	'Eslovenia',
  133:	'España',
  134:	'Estados Unidos',
  135:	'Estonia',
  136:	'Etiopía',
  137:	'Ex-República de Macedonia',
  138:	'Filipinas',
  139:	'Finlandia',
  140:	'Francia',
  141:	'Gabón',
  142:	'Gambia',
  144:	'Georgia del Surr',
  143:	'Georgia',
  146:	'Gibraltar',
  145:	'Ghana',
  148:	'Grecia',
  147:	'Granada',
  150:	'Guadalupe',
  149:	'Groenlandia',
  152:	'Guatemala',
  151:	'Guam',
  154:	'Guayana Francesa',
  153:	'Guayana',
  156:	'Guinea Ecuatorial',
  155:	'Guinea',
  158:	'Haití',
  157:	'Guinea-Bissau',
  160:	'Honduras',
  159:	'Holanda',
  162:	'Hungría',
  161:	'Hong Kong R. A. E',
  164:	'Indonesia',
  163:	'India',
  166:	'Irán',
  165:	'Irak',
  168:	'Isla Bouvet',
  167:	'Irlanda',
  170:	'Isla Heard e Islas McDonald',
  169:	'Isla Christmas',
  172:	'Islas Caimán',
  171:	'Islandia',
  174:	'Islas de Cocos o Keeling',
  173:	'Islas Cook',
  176:	'Islas Fiyi',
  175:	'Islas Faroe',
  178:	'Islas Marianas del Norte',
  177:	'Islas Malvinas',
  180:	'Islas menores de EE.UU',
  179:	'Islas Marshall',
  182:	'Islas Tokelau',
  181:	'Islas Palau',
  184:	'Islas Vírgenes (EE.UU.)',
  183:	'Islas Turks y Caicos',
  186:	'Israel',
  185:	'Islas Vírgenes (Reino Unido)',
  188:	'Jamaica',
  187:	'Italia',
  190:	'Jordania',
  189:	'Japón',
  192:	'Kenia',
  191:	'Kazajistán',
  194:	'Kiribati',
  193:	'Kirguizistán',
  196:	'Laos',
  195:	'Kuwait',
  198:	'Letonia',
  197:	'Lesoto',
  201:	'Liberia',
  200:	'Líbano',
  203:	'Liechtenstein',
  202:	'Libia',
  205:	'Luxemburgo',
  204:	'Lituania',
  207:	'Madagascar',
  206:	'Macao R. A. E',
  209:	'Malawi',
  208:	'Malasia',
  211:	'Malí',
  210:	'Maldivas',
  213:	'Marruecos',
  212:	'Malta',
  215:	'Mauricio',
  214:	'Martinica',
  217:	'Mayotte',
  216:	'Mauritania',
  5:	'México',
  218:	'Micronesia',
  219:	'Moldavia',
  220:	'Mónaco',
  221:	'Mongolia',
  222:	'Montserrat',
  223:	'Mozambique',
  224:	'Namibia',
  225:	'Nauru',
  226:	'Nepal',
  227:	'Nicaragua',
  228:	'Níger',
  229:	'Nigeria',
  230:	'Niue',
  231:	'Norfolk',
  232:	'Noruega',
  233:	'Nueva Caledonia',
  234:	'Nueva Zelanda',
  235:	'Omán',
  236:	'Panamá',
  237:	'Papua Nueva Guinea',
  238:	'Paquistán',
  239:	'Paraguay',
  3:	'Perú',
  240:	'Pitcairn',
  241:	'Polinesia Francesa',
  242:	'Polonia',
  243:	'Portugal',
  50:	'Puerto Rico',
  245:	'Qatar',
  246:	'Reino Unido',
  247:	'República Centroafricana',
  248:	'República Checa',
  249:	'República de Sudáfrica',
  250:	'República del Congo (Zaire)',
  251:	'República Dominicana',
  253:	'Ruanda',
  254:	'Rumania',
  255:	'Rusia',
  256:	'Samoa',
  257:	'Samoa occidental',
  258:	'San Kitts y Nevis',
  259:	'San Marino',
  260:	'San Pierre y Miquelon',
  261:	'San Vicente e Islas Granadinas',
  262:	'Santa Helena',
  263:	'Santa Lucía',
  264:	'Santo Tomé y Príncipe',
  265:	'Senegal',
  266:	'Serbia y Montenegro',
  267:	'Seychelles',
  268:	'Sierra Leona',
  269:	'Singapur',
  270:	'Siria',
  271:	'Somalia',
  272:	'Sri Lanka',
  273:	'Suazilandia',
  274:	'Sudán',
  275:	'Suecia',
  276:	'Suiza',
  277:	'Surinam',
  278:	'Svalbard',
  279:	'Tailandia',
  280:	'Taiwán',
  281:	'Tanzania',
  282:	'Tayikistán',
  283:	'Territorios Franceses del Sur',
  284:	'Timor Oriental',
  285:	'Togo',
  286:	'Tonga',
  287:	'Trinidad y Tobago',
  288:	'Túnez',
  289:	'Turkmenistán',
  290:	'Turquía',
  291:	'Tuvalu',
  292:	'Ucrania',
  293:	'Uganda',
  294:	'Uruguay',
  295:	'Uzbekistán',
  296:	'Vanuatu',
  6:	'Venezuela',
  297:	'Vietnam',
  298:	'Wallis y Futuna',
  299:	'Yemen',
  300:	'Zambia',
  301:	'Zimbabue',
}

export const regions = {
  473:	'Arica y Parinacota',
  2:	'Tarapacá',
  3:	'Antofagasta',
  4:	'Atacama',
  5:	'Coquimbo',
  6:	'Valparaíso',
  1:	'Metropolitana de Santiago',
  7:	"Lib. Gral. Bdo. O'Higgins",
  8:	'Maule',
  556:	'Ñuble',
  9:	'Biobío',
  10:	'La Araucanía',
  472:	'Los Ríos',
  11:	'Los Lagos',
  12:	'Aysén',
  13:	'Magallanes y Antártica Chilena',
  14:	'Extranjero',

}