import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actionCreators from '../../actions/data';

import { CandidatePoolProvider } from './config/CandidatePoolContext';
import CandidatePool from './CandidatePool';

import './candidatePool.scss';

function CandidatePoolView(props) {
  return (
    <CandidatePoolProvider {...props}>
      <CandidatePool
        actions={props.actions}
        business={props.business}
        businessPermissions={props.businessPermissions}
        token={props.token}
      />
    </CandidatePoolProvider>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    business: state.auth.currentBusiness,
    businessPermissions: state.auth.businessPermissions,
    token: state.auth.token,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidatePoolView);
