import React from 'react';
import { Row, Col, Typography, Divider } from 'antd';
import { ResponsiveHeatMap } from '@nivo/heatmap';

import { pickRGB } from '../../../../../components/charts/utils';
import ColorSwatch from './ColorSwatch';

const { Title, Text } = Typography;

const HeatmapExplanation = () => {
  const subsets = ['Oso', 'Alfiler', 'Cielo'];
  const data = [
    {
      id: 'Oso',
      Oso: 0,
      Alfiler: 77,
      Cielo: 74,
    },
    {
      id: 'Alfiler',
      Oso: 77,
      Alfiler: 0,
      Cielo: 70,
    },
    {
      id: 'Cielo',
      Oso: 74,
      Alfiler: 70,
      Cielo: 0,
    },
  ];

  const getColorHeatMap = (value) => {
    if (value === 0) {
      return '#F0F0F0';
    }
    if (value > 0 && value <= 73) {
      const percent = (value - 0) / (73 - 0);

      const rgbArray = pickRGB([165, 0, 38], [244, 109, 67], percent);
      return `RGB(${rgbArray.join(',')})`;
    } else if (value >= 74 && value <= 83) {
      const percent = (value - 74) / (83 - 74);

      const rgbArray = pickRGB([253, 174, 97], [217, 239, 139], percent);
      return `RGB(${rgbArray.join(',')})`;
    } else if (value > 83) {
      const percent = (value - 84) / (110 - 84);

      const rgbArray = pickRGB([166, 217, 106], [0, 104, 55], percent);
      return `RGB(${rgbArray.join(',')})`;
    }
  };

  const dataKeysValues = data.length
    ? Object.keys(data[0]).filter((key) => key !== 'id')
    : [];
  const colors = data
    .map((item) => dataKeysValues.map((key) => getColorHeatMap(item[`${key}`])))
    .flat();

  function scale() {}

  scale.domain = () => {
    const _colors = colors.slice(0);
    return () => {
      return _colors.shift();
    };
  };

  return (
    <>
      <Row style={{ margin: '40px 0 0 0' }}>
        <Title level={4} style={{ color: '#5E5E5E' }}>
          Explicación
        </Title>
      </Row>
      <Divider style={{ margin: '8px 0' }} />
      <Text>
        Esta explicación te muestra como entender el Detalle del resultado. Una
        palabra del eje vertical (alfiler) y otra palabra del eje horizontal
        (oso) se comparan entre si para asignar un puntaje a la distancia entre
        estas palabras, en base a cuán seguido se usan en contextos similares.
      </Text>
      <ColorSwatch margin={'20px 0'} />

      <Row
        type="flex"
        justify="space-between"
        align="middle"
        style={{ height: 180 }}
      >
        <Col span={8} style={{ height: '100%' }}>
          <ResponsiveHeatMap
            data={data}
            maxValue={101}
            minValue={0}
            keys={subsets}
            indexBy={'id'}
            margin={{ top: 20, right: 0, bottom: 60, left: 0 }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -92,
              legend: '',
              legendOffset: 46,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendPosition: 'middle',
              legendOffset: 70,
            }}
            axisRight={false}
            axisTop={false}
            colors={scale}
            cellOpacity={1}
            animate={true}
            motionConfig="wobbly"
            motionStiffness={80}
            motionDamping={9}
            hoverTarget="cell"
            forceSquare
            cellBorderWidth={5}
            cellBorderColor={'#FFFFFF'}
            labelTextColor={(obj) => (obj.label === 0 ? '#F0F0F0' : '#454545')}
            theme={{
              fontSize: 14,
              labels: {
                text: {
                  fontSize: 14,
                  fill: '#5E5E5E',
                },
              },
            }}
          />
        </Col>
        <Col span={16}>
          <Row type="flex" justify="start" align="middle" gutter={10}>
            <Col>
              <Text>Alfiler y Oso =</Text>
            </Col>
            <Col style={{ padding: '10px', background: '#FEE08B' }}>
              <Text>
                <strong>77</strong> distancia promedio entre palabras
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default HeatmapExplanation;
