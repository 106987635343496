const { validate } = require('rut.js');


// paises incluidos
export const countries = ['chile', 'colombia']

function validateChile(DNI) {
    return validate(DNI)
}

function validateColombia(DNI) {
    return /^\d+$/.test(DNI) && ([6, 7, 8, 10, 11].includes(DNI.length));
}

// Validar números de identidad
export function validateDNI(country, DNI) {
    switch (country){
        case 'chile':
            return validateChile(DNI)
        case 'colombia':
            return validateColombia(DNI.toString())
        default:
            return true
    }
}

