import './AtsIntegration.scss';

import * as actionCreators from '../../../actions/data';

import {
  Breadcrumb,
  Button,
  Col,
  Icon,
  Input,
  Layout,
  Modal,
  Row,
  Select,
  Switch,
  Tooltip,
  Typography,
  message,
} from 'antd';
import React, { useEffect, useState } from 'react';

import GenericIntegrationModalContent from './providers/Generic';
import GetToken from './GetToken';
import HiringRoomIntegrationModalContent from './providers/HiringRoom';
import InputsIntegrationModal from './InputsIntegrationModal';
import IntegrationItem from './IntegrationItem';
import LeverModalContent from './providers/Lever';
import { Link } from 'react-router-dom';
import NewToken from './NewToken';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { push } from 'react-router-redux';
import useBreadcrumb from '../../../hooks/useBreadcrumb';

const { Title, Paragraph } = Typography;
const { Search } = Input;

const Integrations = (props) => {
  const [keyword, setKeyword] = useState('');
  const [genomesVisible, setGenomesVisible] = useState(false);
  const [businessTemplatesFiltered, setBusinessTemplatesFiltered] = useState(
    []
  );
  const [businessIntegrations, setBusinessIntegrations] = useState([]);
  const [businessToken, setBusinessToken] = useState([]);
  const [businessTemplates, setBusinessTemplates] = useState([]);
  const [editBusinessIntegrationData, setEditBusinessIntegrationData] =
    useState({ auth_token: null, base_url: null, fields: {} });
  const [businessIntegrationData, setBusinessIntegrationData] = useState({
    auth_token: null,
    base_url: null,
    fields: {},
    genomes: '',
  });
  const [loading, setLoading] = useState(false);
  const [notInfo, setNotInfo] = useState(false);
  const [visible, setVisible] = useState(false);
  const [tokenVisible, setTokenVisible] = useState(false);
  const [createNewToken, setCreateNewToken] = useState(false);
  const [isGenericIntegration, setIsGenericIntegration] = useState(true);

  const Breadcrumb = useBreadcrumb(
    [
      {
        path: '/',
        text: 'Home',
      },
      {
        path: 'evaluations',
        text: 'Evaluaciones',
      },
      {
        path: 'skills',
        text: 'Entrevistas',
      },
    ],
    '>'
  );

  useEffect(() => {
    // TODO: Idealmente tener un campo que nos diga si la acción es distinta a la de guardar/cancelar
    const genericIntegration = !['Hiring Room', 'Lever'].includes(
      editBusinessIntegrationData?.template?.name
    );
    setIsGenericIntegration(genericIntegration);
  }, [editBusinessIntegrationData]);

  const getTokenFn = () => {
    setLoading(true);
    const pk = props.business.id;
    const url = `/api/v2/business/${pk}/accesstokens/`;
    const token = props.token;
    props.actions
      .fetchAndReturn(token, url)
      .then((data) => {
        setBusinessToken(data.body.results);
        setLoading(false);
        !data.body.results.length && setNotInfo(true);
      })
      .catch(() => {
        setLoading(false);
        message.error('No se pudieron obtener los tokens');
      });
  };

  const deleteTokenFn = (accessToken) => {
    const pk = props.business.id;
    const url = `/api/v2/business/${pk}/accesstokens/${accessToken}/`;
    const token = props.token;
    props.actions
      .fetchAndReturn(token, url, 'DELETE')
      .then((response) => {
        if (response >= 400) {
          message.error('No se pudo eliminar el access token');
        } else {
          message.success('Token eliminado con éxito');
          setBusinessToken([]);
          getTokenFn();
        }
      })
      .catch(() => {
        message.error('No se pudo eliminar el token');
      });
  };

  const getIntegrationsFn = async () => {
    const pk = props.business.id;
    const url = `/api/v2/business/${pk}/integrations/`;
    const token = props.token;
    await props.actions
      .fetchAndReturn(token, url)
      .then((data) => {
        setBusinessIntegrations(data.body.results);
      })
      .catch(() => {
        message.error('No se pudieron obtener los tokens');
      });
  };

  const postNewIntegrationFn = async (id) => {
    setLoading(true);
    const pk = props.business.id;
    const url = `/api/v2/business/${pk}/integrations/`;
    const token = props.token;
    const body = { template: id };
    !businessIntegrations
      .map((bu) => {
        return bu.template.id;
      })
      .includes(id)
      ? await props.actions
          .fetchAndReturn(token, url, 'POST', body)
          .then((response) => {
            if (response >= 400) {
              setLoading(false);
              message.error('No se pudieron obtener los tokens');
            } else {
              onChangeBusinessIntegrationDataFn(
                response.body.auth_token,
                response.body.base_url,
                response.body.id,
                response.body.fields,
                response.body.genomes,
                response.body.template
              );
              // message.success("Nueva integración realizada.");
              getIntegrationsFn();
              setLoading(false);
            }
          })
          .catch(() => {
            setLoading(false);
            message.error('No se pudieron obtener los tokens');
          })
      : null;
  };

  const createTokenFn = (name) => {
    setLoading(true);
    const pk = props.business.id;
    const url = `/api/v2/business/${pk}/accesstokens/`;
    const token = props.token;
    const body = name;

    props.actions
      .fetchAndReturn(token, url, 'POST', body)
      .then((response) => {
        if (response >= 400) {
          setLoading(false);
          message.error('No se pudo generar el token');
        } else {
          message.success('Nuevo token generado.');
          setNotInfo(false);

          setBusinessToken([...businessToken, response.body]);
          setLoading(false);
          setCreateNewToken(false);
        }
      })
      .catch(() => {
        setLoading(false);
        message.error('No se pudo generar el token');
      });
  };

  const getIntegrationsTemplatesFn = () => {
    setLoading(true);
    const url = `/api/v2/business/integrationtemplates/`;
    const token = props.token;
    props.actions
      .fetchAndReturn(token, url)
      .then((data) => {
        setBusinessTemplates(data.body.results);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        message.error(
          'Hubo un problema obteniendo las integraciones disponibles para habilitar.'
        );
      });
  };

  const onCopyTokenFn = (token) => {
    navigator.clipboard.writeText(token);
    message.success('Token copiado');
  };

  const onCopyGenomesFn = async (integration) => {
    await navigator.clipboard.writeText(integration.genomes);
    setGenomesVisible(false);
    message.success('Genomas copiados');
  };

  const onChangeBusinessIntegrationDataFn = (
    auth_token,
    base_url,
    integrationPk,
    fields,
    genomes,
    template
  ) => {
    setEditBusinessIntegrationData({
      auth_token,
      base_url,
      integrationPk,
      fields,
      genomes,
      template,
    });
    getSingleBusinessIntegrationFn(integrationPk);
  };

  const patchBusinessIntegrationFn = () => {
    setLoading(true);
    const pk = props.business.id;
    const { auth_token, base_url, integrationPk, fields, genomes } =
      editBusinessIntegrationData;
    const url = `/api/v2/business/${pk}/integrations/${integrationPk}/`;
    const token = props.token;
    const body = { auth_token, base_url, fields, genomes };

    props.actions
      .fetchAndReturn(token, url, 'PATCH', body)
      .then((response) => {
        if (response >= 400) {
          setLoading(false);
          message.error('No se pudo actualizar la integracion');
          setVisible(false);
        } else {
          response.body.status === 'INACT'
            ? message.error(
                'Se han actualizado los datos de la integración, sin embargo, estos no son útiles para conectar esta integración de forma correcta'
              )
            : message.success('Integración actualizada con éxito.');
          getIntegrationsFn();
          setLoading(false);
          setVisible(false);
        }
      })
      .catch(() => {
        setVisible(false);
        setLoading(false);
        message.error('No se pudo actualizar la integracion');
      });
  };

  const deleteSingleBusinessIntegrationFn = (integrationPk) => {
    const pk = props.business.id;
    const url = `/api/v2/business/${pk}/integrations/${integrationPk}/`;
    const token = props.token;

    props.actions
      .fetchAndReturn(token, url, 'DELETE')
      .then((response) => {
        if (response >= 400) {
          message.error('No se pudo eliminar la integracion');
        } else {
          message.success('Integracion borrada con exito');
          getIntegrationsFn();
        }
      })
      .catch(() => {
        message.error('No se pudo eliminar la integracion');
      });
  };

  const getSingleBusinessIntegrationFn = (integrationPk) => {
    const pk = props.business.id;
    const url = `/api/v2/business/${pk}/integrations/${integrationPk}/`;
    const token = props.token;

    props.actions
      .fetchAndReturn(token, url, 'GET')
      .then((response) => {
        if (response >= 400) {
          message.error('No se pudo obtener la integracion');
        } else {
          setBusinessIntegrationData(response.body);
        }
      })
      .catch(() => {
        message.error('No se pudo obtener la integracion');
      });
  };

  useEffect(() => {
    getIntegrationsFn();
    getTokenFn();
  }, []);

  const getBusinessIntegration = (name, country) => {
    return businessIntegrations.filter(
      (integration) =>
        integration.template.name == name &&
        integration.template.country == country
    )[0];
  };

  function onChangeIntegrationFields(target, index) {
    let integrationFields = editBusinessIntegrationData.fields;
    integrationFields[index] = target;
    setEditBusinessIntegrationData({
      ...editBusinessIntegrationData,
      fields: integrationFields,
    });
  }

  function getValue(alternatives, index) {
    editBusinessIntegrationData.fields[index] = editBusinessIntegrationData
      .fields[index]
      ? editBusinessIntegrationData.fields[index]
      : '';
    return alternatives[
      alternatives
        .map((obj) => obj.code)
        .indexOf(editBusinessIntegrationData?.fields[index])
    ]
      ? alternatives[
          alternatives
            .map((obj) => obj.code)
            .indexOf(editBusinessIntegrationData.fields[index])
        ].name
      : '';
  }

  function showConfirm(id) {
    Modal.confirm({
      title: '¿Estas seguro que quieres borrar esta integración?',
      centered: true,
      cancelText: 'Cancelar',
      okText: 'Borrar',
      onOk() {
        deleteSingleBusinessIntegrationFn(id);
      },
    });
  }

  function questionType(q, integration, integration_fields, index) {
    const alternatives =
      q.type === 'select' ? integration_fields['options'] : [];
    const component = {
      text: (
        <Input
          key={index}
          index={index}
          placeholder={integration.fields ? integration.fields[index] : ''}
          onChange={(e) => onChangeIntegrationFields(e.target.value, index)}
        />
      ),
      select: (
        <Select
          key={index}
          {...q}
          index={index}
          defaultValue={getValue(alternatives, index)}
          style={{ width: '100%' }}
          onChange={(e) => onChangeIntegrationFields(e, index)}
        >
          {alternatives.map((elem) => {
            return (
              <Select.Option key={elem.code} value={elem.code}>
                {elem.name}
              </Select.Option>
            );
          })}
        </Select>
      ),
      boolean: (
        <Switch
          key={index}
          checked={integration.fields[index]}
          onChange={(e) => onChangeIntegrationFields(e, index)}
        />
      ),
    };
    return (
      (
        <div key={index} id={index}>
          {component[q.type]}
        </div>
      ) || null
    );
  }
  useEffect(() => {
    setBusinessTemplatesFiltered(businessTemplates);
  }, [businessTemplates]);

  useEffect(() => {
    getIntegrationsTemplatesFn();
  }, []);

  const onChange = (newValue) => {
    setKeyword(newValue.target ? newValue.target.value.toLowerCase() : '');
    const value = newValue.target
      ? newValue.target.value.toLowerCase()
      : newValue.toLowerCase();
    setBusinessTemplatesFiltered(
      value === ''
        ? businessTemplates
        : businessTemplates.reduce((integrations, integration) => {
            if (integration.name.toLowerCase().includes(value)) {
              integrations.push(integration);
            }
            return integrations;
          }, [])
    );
  };

  return (
    <Layout className="tools-dashboard">
      {Breadcrumb}

      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <Icon
          type="arrow-left"
          style={{ display: 'flex', marginBottom: '0.5em', marginRight: 10 }}
          onClick={() => props.dispatch(push('/tools'))}
        />
        <Title level={1}>Integraciones</Title>
      </div>
      <Paragraph type="secondary">
        Agiliza tus procesos de selección y reclutamiento con las integraciones
        que Genomawork pone a tu disposición.
      </Paragraph>

      <Row type="flex" justify="space-between" style={{ gap: '1rem' }}>
        <Search
          placeholder="Buscar integración"
          onChange={(e) => onChange(e)}
          enterButton={<Button style={{ fontSize: 14 }} icon="search"></Button>}
          onSearch={onChange}
          type="flex"
          style={{
            width: 280,
          }}
          value={keyword}
        />

        <Button
          placeholder="Buscar token"
          onClick={() => setTokenVisible(true)}
          icon="search"
          className="open-access-token-modal-button"
          type="small"
        >
          Claves de acceso(token)
        </Button>
      </Row>

      <Row type={'flex'} gutter={[24, 24]}>
        {!!businessTemplatesFiltered.length &&
          businessTemplatesFiltered.map(
            ({ description, id, image, name, country }, i) => (
              <IntegrationItem
                id={id}
                description={description}
                image={image}
                name={name}
                country={country}
                i={i}
                key={i}
                getBusinessIntegration={getBusinessIntegration}
                onChangeBusinessIntegrationDataFn={
                  onChangeBusinessIntegrationDataFn
                }
                postNewIntegrationFn={postNewIntegrationFn}
                showConfirm={showConfirm}
                setGenomesVisible={setGenomesVisible}
                setVisible={setVisible}
              />
            )
          )}
      </Row>
      <Modal
        centered={true}
        destroyOnClose={true}
        title="Gestionar integración"
        visible={visible}
        onOk={patchBusinessIntegrationFn}
        onCancel={() => setVisible(false)}
        okText={'Guardar'}
        cancelText={'Cancelar'}
        forceRender={true}
        footer={isGenericIntegration ? undefined : null}
      >
        {(function () {
          switch (editBusinessIntegrationData?.template?.name) {
            case 'Lever':
              return (
                <LeverModalContent
                  leverIntegrationTemplate={
                    editBusinessIntegrationData.template
                  }
                  getIntegration={() =>
                    getBusinessIntegration(
                      editBusinessIntegrationData.template?.name,
                      editBusinessIntegrationData.template?.country
                    )
                  }
                />
              );
            case 'Hiring Room':
              return (
                <HiringRoomIntegrationModalContent
                  editBusinessIntegrationData={editBusinessIntegrationData}
                  businessIntegrationData={businessIntegrationData}
                  setEditBusinessIntegrationData={
                    setEditBusinessIntegrationData
                  }
                  onSubmit={patchBusinessIntegrationFn}
                />
              );
            default:
              return (
                <GenericIntegrationModalContent
                  editBusinessIntegrationData={editBusinessIntegrationData}
                  businessIntegrationData={businessIntegrationData}
                  setEditBusinessIntegrationData={
                    setEditBusinessIntegrationData
                  }
                  questionType={questionType}
                  visible={visible}
                />
              );
          }
        })()}
      </Modal>

      <Modal
        style={{ width: 590 }}
        centered={true}
        destroyOnClose={true}
        title="Claves de acceso (tokens)"
        visible={tokenVisible}
        onCancel={() => setTokenVisible(false)}
        okButtonProps={{ style: { display: 'none' } }}
        cancelText="Salir"
      >
        {!createNewToken && (
          <div>
            <Row type={'flex'} gutter={[24, 24]}>
              <Col sm={5} md={5} lg={4} justify="start">
                <img
                  style={
                    isMobile
                      ? { width: '4em', height: '4em' }
                      : { width: '5em', height: '5em' }
                  }
                  src="https://genoma-assets.s3.us-east-2.amazonaws.com/tool.svg"
                />
              </Col>
              <Col sm={18} md={18} lg={20}>
                <Typography.Text
                  type={'flex'}
                  justify="space-around"
                  strong
                  style={isMobile ? { fontSize: 12 } : { fontSize: 14 }}
                >
                  Genera claves de acceso para integrarte con otras plataformas
                </Typography.Text>
                <Typography.Paragraph
                  type={'flex'}
                  justify="space-around"
                  style={isMobile ? { fontSize: 10 } : { fontSize: 12 }}
                >
                  Para conectar tu cuenta de Genomawork con otros servicios
                  debes generar una clave.
                </Typography.Paragraph>
              </Col>
            </Row>
            <GetToken
              createTokenFn={createTokenFn}
              businessToken={businessToken}
              onCopyTokenFn={onCopyTokenFn}
              loading={loading}
              notInfo={notInfo}
              setCreateNewToken={setCreateNewToken}
              deleteTokenFn={deleteTokenFn}
            />{' '}
          </div>
        )}

        {createNewToken && (
          <div>
            <Row type={'flex'} gutter={[0, 24]} align="middle">
              <Icon
                type="arrow-left"
                style={{ display: 'flex', marginRight: 10 }}
                onClick={() => setCreateNewToken(false)}
              />
              <Typography.Text ellipsis strong style={{ fontSize: 15 }}>
                Generar nueva clave (token)
              </Typography.Text>
            </Row>
            <NewToken createTokenFn={createTokenFn} loading={loading} />{' '}
          </div>
        )}
      </Modal>

      <Modal
        centered={true}
        destroyOnClose={true}
        title="Genomas disponibles"
        visible={genomesVisible}
        onCancel={() => setGenomesVisible(false)}
        cancelText="Cancelar"
        okText={
          <>
            <Icon type="copy" /> Copiar al portapapeles
          </>
        }
        onOk={() => onCopyGenomesFn(businessIntegrationData)}
      >
        <Typography.Paragraph>
          Deberás copiar estos datos e ingresarlos en la plataforma de{' '}
          <strong>
            {businessIntegrationData.template
              ? businessIntegrationData.template.name
              : 'la otra aplicación'}
          </strong>{' '}
          para poder gestionar correctamente la integración.
        </Typography.Paragraph>
        <Row type="flex" align="middle">
          {businessIntegrationData.genomes.split('\n').map((template) => (
            <Typography.Text
              type="secondary"
              ellipsis={false}
              className="token-text"
              style={{
                fontSize: '14px',
                marginLeft: isMobile ? '1em' : '2em',
              }}
            >
              {template}
            </Typography.Text>
          ))}
        </Row>
      </Modal>
    </Layout>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    token: state.auth.token,
    business: state.auth.currentBusiness,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Integrations);
