import React from 'react';
import "../../pdf-css.scss";
import { Row, Col, Typography, Avatar } from 'antd';

const { Paragraph } = Typography;

const backgroundPalette = [
  '#D3ADF7',
  '#91D5FF',
  '#87E8DE',
  '#FFD591',
  '#ADC6FF',
];
const colorPallete = ['#531DAB', '#096DD9', '#08979C', '#D46B08', '#1D39C4'];

const Profiles = ({ candidates }) => {
  const names = candidates.map((item) => {
    if (item.name.indexOf(' ') !== -1) {
      if (item.surname.indexOf(' ') !== -1) {
        return {
          name: item.name.slice(0, item.name.indexOf(' ')),
          surname: item.surname.slice(0, item.surname.indexOf(' ')),
          email: item.email,
        };
      } else {
        return {
          name: item.name.slice(0, item.name.indexOf(' ')),
          surname: item.surname,
          email: item.email,
        };
      }
    } else {
      if (item.surname.indexOf(' ') !== -1) {
        return {
          name: item.name,
          surname: item.surname.slice(0, item.surname.indexOf(' ')),
          email: item.email,
        };
      } else {
        return { name: item.name, surname: item.surname, email: item.email, };
      }
    }
  });

  return (
    <Row type='flex' align='middle'>
      <Col span={6}>
        <Paragraph strong>Perfiles</Paragraph>
      </Col>
      <Col span={18}>
        <Row type='flex' justify='space-around' className='profiles-container'>
          {names.map((profile, index) => (
            <Col key={index} className='profiles-container__info' xs={4}>
              <Avatar
                style={{
                  background: backgroundPalette[index],
                  color: colorPallete[index],
                }}
                size={52}
              >
                {(profile.name && profile.surname && profile.name !== '') ? (
                  `${profile.name[0].toUpperCase()}${profile.surname[0].toUpperCase()}`
                ) : (
                  `${profile.email[0].toUpperCase()}${profile.email[1].toUpperCase()}`
                )}
              </Avatar>
              {profile.name ? (
                <div>
                  {profile.name}
                  <br />
                  {profile.surname}
                </div>
              ) : <div style={{wordBreak: 'break-all'}}>{profile.email}</div>
              }
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

export default Profiles;
