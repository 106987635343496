import { Icon, Typography } from 'antd';
import React, { useContext } from 'react';

import { CandidateContext } from '../config/CandidateContext';
import Card from './Card';
import DayList from '../../../components/Availability/DayList';
import ParseHtml from '../../../components/parseHtml';
import daysOfWeek from '../../../constants/daysOfWeek';
import i18n from '../../../i18n';

const { Text } = Typography;

const Questions = () => {
  const {
    application: { ko_answers, additional_documents },
    koQuestions,
    translationFile,
  } = useContext(CandidateContext);


  // There is a bug in the process creation sometimes the position attribute is not created whe infer the position parsing
  // the assigned activity configuration (the value is an object but Object.values retunr and stable sorted array)
  const hasPositionAttribute = ko_answers?.answer ? Object.values(ko_answers.answer).every(obj => obj.hasOwnProperty('position')) : false;

  const sortedQuestions = () => {
    if (ko_answers?.answer && koQuestions) {
      if (hasPositionAttribute) {
        return Object.values(ko_answers.answer).sort((a, b) => a.position - b.position)
      }
      else {
        const sortedIds = koQuestions.questions.map(obj=>obj.id);
        // FIXME: Temporal fix, should incorporate unanswered questions on profile instead of filtering them
        return sortedIds.map(id => ko_answers.answer[id]).filter(obj => obj !== undefined)
      }
    }
    else{
      return [];
    }
  }
  
  const questions = sortedQuestions();

  // If true discard
  const checkKOMultiSelection = (candidateAnswer) => {
    // structure of answers is : {0: true, 3: true, ... , <int>alternative_idx: <boolean>is_selected}
    // when the candidate deselect an alredy selected option the key/value is NOT removed from the object, instead, the value is set to false
    const selected = candidateAnswer?.answer
      ? Object.keys(candidateAnswer.answer).map(
        (element) => candidateAnswer.answer[element] == true ? candidateAnswer.alternatives[element] : null
      ).filter(obj => obj !== null)
      : [];
    const alternatives = candidateAnswer.alternatives.map(
      ({ correct }) => correct
    );
    return selected.map((selectedCandidateAnswer, index) => {
      if (alternatives.includes(true)) {
        return (
          <Text
            key={index}
            style={{ color: selectedCandidateAnswer.correct ? null : 'red' }}
          >
            {selectedCandidateAnswer.text}
          </Text>
        );
      } else {
        return <Text key={index}>{selectedCandidateAnswer.text}</Text>;
      }
    });
  };

  const checkKOSingleSelection = (candidateAnswer) => {
    const answer = candidateAnswer.answer;
    const selected = candidateAnswer.alternatives[answer];

    const alternatives = candidateAnswer.alternatives.map(
      ({ correct }) => correct
    );

    // ??
    if (!selected) return <>Sin respuesta</>;
    if (alternatives.includes(true)) {
      return (
        <Text style={{ color: selected.correct ? null : 'red' }}>
          {selected.text}
        </Text>
      );
    } else {
      return <Text>{selected.text}</Text>;
    }
  };

  const checkKOIntRange = (candidateAnswer) => {
    candidateAnswer.answer = parseInt(candidateAnswer.answer);
    candidateAnswer.from = parseInt(candidateAnswer.from);
    candidateAnswer.to = parseInt(candidateAnswer.to);

    if (candidateAnswer.from === candidateAnswer.to) {
      return candidateAnswer.answer;
    } else {
      return (
        <Text
          style={{
            color:
              candidateAnswer.from === candidateAnswer.to ||
                (candidateAnswer.answer >= candidateAnswer.from &&
                  candidateAnswer.answer <= candidateAnswer.to)
                ? null
                : 'red',
          }}
        >
          {candidateAnswer.answer}
        </Text>
      );
    }
  };

  const checkKOFile = (candidateAnswer) => {
    const doc = additional_documents.find(
      (item) => item.document_type === candidateAnswer.question
    );
    return (
      <>
        <Text>{candidateAnswer.additionals?.instructions}</Text>
        {doc && doc.document ? (
          <a href={doc.document}>
            {i18n.t('commons__download', { ns: 'candidateProfile' })}
          </a>
        ) : (
          <Text>Sin documento</Text>
        )}
      </>
    );
  };

  const checkKOAvaliability = (question) => {
    const availabilityType = question.configuration.availabilityType;
    const clientSchedule = question.configuration.schedule;
    const candidateSchedule = question.answer;

    const indexToTime = (index) => {
      const hours = Math.floor(index / 4);
      const minutes = (index % 4) * 15;
      return `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}`;
    };

    const getAvailableBlocks = (daySchedule) => {
      let blocks = [];
      let startBlock = null;
      daySchedule.forEach((value, index) => {
        if (value === 1 && startBlock === null) {
          startBlock = index;
        } else if (value === 0 && startBlock !== null) {
          blocks.push([startBlock, index - 1]);
          startBlock = null;
        }
      });
      if (startBlock !== null) {
        blocks.push([startBlock, daySchedule.length - 1]);
      }
      return blocks;
    };

    if (availabilityType === 'specific') {
      return (
        <div
          style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}
        >
          {daysOfWeek.map((day) => {
            const clientBlocks = getAvailableBlocks(clientSchedule[day]);

            return (
              <div
                key={day}
                style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}
              >
                <Text strong style={{ width: 80 }}>
                  {i18n.t(`commons__dayofweek__${day}`)}
                </Text>
                <div style={{ display: 'flex', gap: '0.25rem' }}>
                  {clientBlocks.length > 0
                    ? clientBlocks.map(([start, end], index) => {
                      const isSelected =
                        candidateSchedule[day] &&
                        candidateSchedule[day]
                          .slice(start, end + 1)
                          .some((val) => val === 1);
                      const startTime = indexToTime(start);
                      const endTime = indexToTime(end + 1); // +1 para incluir el final del bloque
                      return (
                        <div
                          key={index}
                          style={{
                            padding: '0.5rem 0.75rem',
                            border: '1px solid #D9D9D9',
                            borderRadius: '2px',
                            backgroundColor: isSelected
                              ? '#F175A5'
                              : '#ffffff',
                            display: 'flex',
                            color: isSelected ? '#ffffff' : '#000000A6',
                          }}
                        >
                          <span>
                            {startTime} - {endTime}
                          </span>
                        </div>
                      );
                    })
                    : 'Día no disponible'}
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return <DayList schedule={candidateSchedule} />;
    }
  };

  const getAnswers = (question) => {
    switch (question.type) {
      case 'multiselection':
        return checkKOMultiSelection(question);
      case 'singleselection':
        return checkKOSingleSelection(question);
      case 'int':
        return checkKOIntRange(question);
      case 'file':
        return checkKOFile(question);
      case 'availability':
        return checkKOAvaliability(question);
      default:
        return ParseHtml(question.answer);
    }
  };

  const printQuestion = (question, index) => {
    const { question: name } = question;

    return (
      <>
        <div className="new-modal-question">
          <div className="new-modal-question__texts">
            <Text strong style={{ fontSize: 15 }}>
              {name}{' '}
              <Icon type="alert" theme="filled" style={{ color: '#FAAD14' }} />
            </Text>
            {getAnswers(question)}
          </div>
        </div>
        {index !== questions.length - 1 && <hr />}
      </>
    );
  };

  return (
    <Card
      height="100%"
      title={
        <Text strong className="text-16px">
          {i18n.t('commons__ko', { ns: 'candidateProfile' })}
        </Text>
      }
    >
      <div className="summary-kos-list">
        {questions.length ? (
          questions.map((question, index) => printQuestion(question, index))
        ) : (
          <Text className="text-center">
            {i18n.t('profile__without_response', { ns: 'candidateProfile' })}.
          </Text>
        )}
      </div>
    </Card>
  );
};

export default Questions;
