import React from 'react';

import { Col, Divider, Icon, Modal, Row, Tooltip, Typography } from 'antd';

const { Text, Title } = Typography;

const languages = {
  'es': 'Español',
  'en': 'Inglés',
  'pt': 'Portugués',
};

const DetailsModal = ({
  businessCustomFields,
  closureDate,
  customFields={},
  department,
  language,
  setVisible,
  tags,
  titleJobapp,
  visible
}) => (
  <Modal
    closable
    footer={null}
    onCancel={() => setVisible(false)}
    title={<Text strong>Detalles</Text>}
    visible={visible}
    width={800}
  >
    <Row type="flex">
      <Divider
        className="details-modal-divider"
        orientation="left"
        style={{ marginTop: 0 }}
      >
        <Title level={4} style={{ color: '#CCCCCC', fontWeight: 'normal', margin: 0 }}>
          Información básica
        </Title>
      </Divider>
    </Row>
    <Row
      gutter={[20, 20]}
      style={{ marginBottom: '1em', marginLeft: '1em', marginRight: '1em' }}
      type="flex"
    >
      <Col span={12}>
        <Text className="detail-label">Nombre del cargo</Text>
        <Text>{titleJobapp}</Text>
      </Col>
      <Col span={12}>
        <Text className="detail-label">Departamento o área</Text>
        <Text>{department}</Text>
      </Col>
      {/* <Col span={12}>
        <Text className="detail-label">Número de vacantes</Text>
      </Col> */}
      <Col span={12}>
        <Text className="detail-label">Idioma del proceso</Text>
        <Text>{languages[language]}</Text>
      </Col>
      <Col span={12}>
        <Text className="detail-label">Fecha de cierre</Text>
        <Text>{closureDate}</Text>
      </Col>
      {tags?.length > 0 ? (
        <Col span={12}>
          <Text className="detail-label">Etiquetas</Text>
          <Text>{tags?.join(', ') || '-'}</Text>
        </Col>
      ) : null}
    </Row>
    <Row type="flex">
      <Divider
        className="details-modal-divider"
        orientation="left"
        style={{ marginTop: 0 }}
      >
        <Title level={4} style={{ color: '#CCCCCC', fontWeight: 'normal', margin: 0 }}>
          Atributos personalizados
        </Title>
      </Divider>
    </Row>
    <Row
      gutter={[20, 20]}
      style={{ marginBottom: '1em', marginLeft: '1em', marginRight: '1em' }}
      type="flex"
    >
      {Object.entries(customFields).map(([customFieldName, customFieldValue]) => (
        <Col key={customFieldName} span={12}>
          <Text className="detail-label">
            {businessCustomFields[customFieldName]?.name ?? customFieldName.replaceAll('_', ' ')}
            {businessCustomFields[customFieldName]?.description && (
              <Tooltip placement="right" title={businessCustomFields[customFieldName]?.description}>
                <Icon style={{ marginLeft: '5px' }} type="info-circle" />
              </Tooltip>
            )}
          </Text>
          <Text>
            {Array.isArray(customFieldValue)
              ? customFieldValue.join(', ')
              : (
                (typeof customFieldValue === 'boolean')
                  ? (customFieldValue ? 'Sí' : 'No')
                  : (
                    (null, undefined, '').includes(customFieldValue) ? '-' : customFieldValue
                  )
              )}
          </Text>
        </Col>
      ))}
    </Row>
  </Modal>
);

export default DetailsModal;
