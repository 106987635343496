import React, { Fragment, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import '../LoginOld.scss'
import { queryToObject } from '../../../utils/url';

import i18n from '../../../i18n';
import * as authActionCreators from '../../../actions/auth'
import { LINKEDIN_STATE, LINKEDIN_URL } from '../../../constants/socialAuth';
import { v4 } from 'uuid';

import {
  Button,
  Card,
  Modal,
  Typography,
} from 'antd';
const { Text } = Typography;

/**
 * This component contains the buttons to Login/SignIn 
 * with a "Social Provider" via Oauth
 * 
 * @param {*} props 
 */
function SocialLogin(props) {
  const [googleState, setGoogleState] = useState(v4())

  const googleUrlGenerator = () => {
    const baseUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
    const scope = `scope=https%3A//www.googleapis.com/auth/userinfo.email`;
    const nonce = `nonce=${googleState}`;
    const state = `state=${googleState}`;
    const responseType = `response_type=id_token`;
    const redirectUri = `redirect_uri=${encodeURIComponent(window.location.origin)}/login/socialredirect`;
    const clientId = `client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}`;
    const prompt = `prompt=consent`;

    return `${baseUrl}?${scope}&${responseType}&${redirectUri}&${clientId}&${prompt}&${state}&${nonce}`;
  }

  let popup;
  let redirect;

  useEffect(() => {
    return () => {
      window.removeEventListener('message', receiveLinkedInResponse)
      localStorage.removeItem('linkedin')
      localStorage.removeItem('googleoauthresponse')
      popup && popup.close();
    }
  }, []);

  const extractRedirect = (string) => {
    const match = string.match(/next=(.*)/);
    return match ? (
      match[1] == '/tos' ? '/me' : match[1]
    ) : '/';
  };

  // const receiveLinkedInResponse = ({ origin, data: { state, code, error, ...rest} }) => {
  const receiveLinkedInResponse = (event) => {
    if (event.key !== 'linkedin') return;

    const data = queryToObject(event.newValue);
    const { code, error, ...rest } = data;
    if (data.state !== LINKEDIN_STATE) return;

    if (code) {
      // this.props.receiveProviderToken({ provider: PROVIDER.LINKEDIN, token: code })
      if (props.jobappid != '') {
        // NOTE: extractRedirect necesario?
        redirect = location.pathname + extractRedirect(props.location.search)
        props.authActions.authLinkedInLoginUser(null, code, props.jobappid)
          .then((submitAfterLogin) => {
            submitAfterLogin && props.submitAfterLogin();
          });
      } else {
        redirect = props.location ? extractRedirect(props.location.search) || '/' : '/';
        props.authActions.authLinkedInLoginUser(redirect, code, props.jobappid)
      }
    }
    else {
      props.authActions.authLinkedInErrorReceived();
    }
    popup.close()
  }

  const handleLinkedInLogin = async () => {
    popup = window.open(LINKEDIN_URL, '_blank', 'width=600,height=600')
    window.addEventListener('storage', receiveLinkedInResponse)
  }

  const receiveGoogleResponse = (event) => {
    if (event.key !== 'googleoauthresponse') return;
    // Obtain response from localstorage
    const response = queryToObject(event.newValue);
    if (response.state !== googleState) return;
    const idToken = response.id_token;

    if (props.jobappid != '') {
      redirect = location.pathname + extractRedirect(props.location.search)
      props.authActions.authGoogleLoginUser(null, null, idToken, props.jobappid, true)
        .then((submitAfterLogin) => {
          submitAfterLogin && props.submitAfterLogin();
        }
        );
    } else {
      redirect = props.location ? extractRedirect(props.location.search) || '/' : '/';
      props.authActions.authGoogleLoginUser(redirect, null, idToken, props.jobappid, true)
    }
  }

  const skip = [
    'idpiframe_initialization_failed',
    'popup_closed_by_user'
  ]

  const receiveGoogleFailureResponse = (response) => {
    if (skip.includes(response.error)) return;
    props.authActions.authGoogleErrorReceived()
  }

  const handleDialogClose = () => {
    // TODO: Cambiar reload, solución flaite
    window.location.reload()
  };

  let errorModalMsg = '';
  if (props.socialAuthErrors) {
    // Se settea el mensaje de error del modal según orden de aparición

    // Primer error posible: error en respuesta de la API "provider"
    if (props.socialAuthErrors.socialCodeError) {
      errorModalMsg = props.socialAuthErrors.socialCodeError
    }

    // Segundo error posible: emailNotFound (i.e. email usado para Oauth no se encuentra registrado en genoma)
    if (props.socialAuthErrors.emailNotFound) {
      errorModalMsg = props.socialAuthErrors.emailNotFound
    }
  }

  const handleOpenGoogleLogin = () => {
    const url = googleUrlGenerator();
    window.open(url, '_blank', 'width=600,height=600');
    window.addEventListener('storage', receiveGoogleResponse);
  }

  return (
    <Fragment>
      <Modal
        centered
        closable={false}
        visible={(props.socialAuthErrors) ? true : false}
        footer={[
          <Button key="submit" type="primary" onClick={handleDialogClose}>
            {i18n.t('commons__close')}
          </Button>
        ]}
      >
        <div style={{ textAlign: 'center' }}>
          {(props.socialAuthErrors && props.socialAuthErrors.emailNotFound) && <> <br /> </>}
          <Text strong style={{ fontSize: '16px', marginTop: '1em' }}>
            {errorModalMsg}
          </Text>
        </div>
      </Modal>
      <Card
        xs={24}
        type="inner"
        style={{
          padding: 0,
          background: 'none'
        }}
        bodyStyle={{
          padding: 0,
        }}
      >
        <div className="social-login-buttons-group">
          {props.linkedin &&
            <Button
              block
              type="secondary"
              icon="linkedin"
              onClick={handleLinkedInLogin}
              size="large"
            >
              LinkedIn
            </Button>
          }

          {props.gmail &&
            <Button
              block
              icon="google"
              type="secondary"
              onClick={handleOpenGoogleLogin}
              size="large"
            >
              Gmail
            </Button>
          }
        </div>
      </Card>
    </Fragment>
  )
}

SocialLogin.defaultProps = {
  socialAuthErrors: null,
  location: null,
  jobappid: '',
  linkedin: true,
  gmail: true,
  firstLogin: true,
  socialAuth: {
    provider: null,
    tokenObj: null,
    email: null,
    jobAppId: null,
  },
};

SocialLogin.propTypes = {
  authActions: PropTypes.shape({
    authLinkedInLoginUser: PropTypes.func.isRequired,
  }).isRequired,
  socialAuthErrors: PropTypes.object,
  firstLogin: PropTypes.bool,
  linkedin: PropTypes.bool,
  gmail: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }),
  jobappid: PropTypes.string,
  socialAuth: PropTypes.shape({
    provider: PropTypes.string,
    tokenObj: PropTypes.string,
    email: PropTypes.string,
    jobAppId: PropTypes.string,
  }),
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    socialAuthErrors: state.auth.socialErrors,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    authActions: bindActionCreators(authActionCreators, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialLogin);
export { SocialLogin as SocialLoginButtons };
