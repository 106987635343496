import React, { useState, useEffect } from 'react';

import { Modal, Button, Form, message, Spin, Radio } from 'antd';

import DetailsForm from './components/DetailsForm';
import DateForm from './components/DateForm';
import AttendeesForm from './components/AttendeesForm';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../../../actions/data';
import i18n from '../../../../i18n';

import './styles.scss';

import moment from 'moment';
import Meetings from './components/Meetings';
import chunk from 'lodash.chunk';

const interviewTypes = {
  Presencial: 'ONSITE',
};

const languages = {
  Español: 'es',
};

const ScheduleModal = ({
  visible,
  handleVisible,
  form,
  actions,
  auth,
  reduxJobApp,
  selectedCandidates,
  stage,
  jobApplication,
  interviewType = 'single',
}) => {
  const [collabs, setCollabs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const url = `permissions/business/${auth.currentBusiness.id}/`;

    actions
      .fetchData(auth.token, url, '', 'get')
      .then(({ results: collabs }) => setCollabs(collabs));
  }, []);

  const handleSubmit = () => {
    const { getFieldValue } = form;

    setIsLoading(true);
    form.validateFields((err, form) => {
      if (err) {
        message.error('Por favor completa los campos requeridos');
        setIsLoading(false);
        return;
      } else {
        const dateToSend = form.date.format('DD-MM-YYYY');
        const stringTimezone = form.gmt.slice(0, 6).replace(':', '');

        if (interviewType === 'single') {
          const values = {
            address: form.address,
            stage,
            job_application: jobApp.id,
            title: form.title,
            description: form.description,
            interview_type: interviewTypes[form.interview_type],
            language: languages[form.language],
            interview_date: `${dateToSend} ${form.init_hour} UTC${stringTimezone}`,
            interview_end_date: `${dateToSend} ${form.end_hour} UTC${stringTimezone}`,
            interview_timezone: 'UTC' + stringTimezone,
            business_users: form.attendees.collabs.map(
              (collab) => collab.business_user.id
            ),
            personal_users: form.attendees.candidates.map(
              (candidate) => candidate.personal_user_id ?? candidate.id
            ),
          };

          actions
            .fetchAndReturn(
              auth.token,
              '/api/v1/evaluations/interviews/',
              'POST',
              values
            )
            .then((r) => {
              if (r.status < 400) {
                setIsLoading(false);
                message.success('Entrevista agendada con éxito');
                handleVisible();
              } 
              else {
                setIsLoading(false);
                message.error('Error al guardar los datos');
              }
            });
        } else {
          const promises = chunk(
            form.attendees.candidates,
            form.candidates_per_meeting
          ).map((candidates, i) => {
            const intervals = i * getFieldValue('interval_duration');
            const interviewsTime = i * getFieldValue('interview_duration');

            const start = moment(getFieldValue('init_hour'), 'HH:mm')
              .add(intervals, 'minutes')
              .add(interviewsTime, 'minutes')
              .format('HH:mm');

            const end = moment(start, 'HH:mm')
              .add(getFieldValue('interview_duration'), 'minutes')
              .format('HH:mm');

            const values = {
              address: form.address,
              stage,
              job_application: jobApp.id,
              title: form.title,
              description: form.description,
              interview_type: interviewTypes[form.interview_type],
              language: languages[form.language],
              interview_date: `${dateToSend} ${start} UTC${stringTimezone}`,
              interview_end_date: `${dateToSend} ${end} UTC${stringTimezone}`,
              interview_timezone: 'UTC' + stringTimezone,
              business_users: form.attendees.collabs.map(
                (collab) => collab.business_user.id
              ),
              personal_users: candidates.map((c) => c.personal_user_id),
            };

            return actions.fetchAndReturn(
              auth.token,
              '/api/v1/evaluations/interviews/',
              'POST',
              values
            );
          });

          Promise.all(promises).then((r) => {
            if (r.every((res) => res.status < 400)) {
              setIsLoading(false);
              message.success('Entrevistas agendadas con éxito');
              handleVisible();
            } else {
              setIsLoading(false);
              message.error('Error al guardar los datos');
            }
          });
        }
      }
    });
  };

  const jobApp = jobApplication ?? reduxJobApp;

  return (
    <Form onSubmit={handleSubmit}>
      <Modal
        title={
          <span style={{ fontWeight: 700, fontSize: 18 }}>
            Agendar entrevista
          </span>
        }
        visible={visible}
        onCancel={handleVisible}
        footer={[
          <Button onClick={handleVisible} key="cancel">
            Cancelar
          </Button>,
          <Spin key="submit-spin" spinning={isLoading}>
            <Button
              key="submit"
              type="primary"
              onClick={handleSubmit}
              style={{ marginLeft: 15 }}
            >
              Agendar
            </Button>
          </Spin>,
        ]}
        style={{ top: 20 }}
        width="min(90%, 800px)"
        className="schedule-modal"
        destroyOnClose={true}
      >
        <DetailsForm
          form={form}
          jobApp={jobApp}
          selectedCandidates={selectedCandidates}
          interviewType={interviewType}
        />
        <DateForm
          form={form}
          interviewType={interviewType}
          selectedCandidates={selectedCandidates}
        />
        {interviewType === 'multi' ? (
          <Meetings
            form={form}
            interviewType={interviewType}
            selectedCandidates={selectedCandidates}
          />
        ) : null}
        <AttendeesForm
          form={form}
          selectedCandidates={selectedCandidates}
          collabs={collabs}
          owner={{ email: auth?.businessPermissions?.business_user?.email, name: auth?.businessPermissions?.business_user?.name }}
          interviewType={interviewType}
        />
      </Modal>
    </Form>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxJobApp: state.jobapp.jobApplication,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'schedule-form' })(ScheduleModal));
