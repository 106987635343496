import React, { useEffect } from "react";
import { Col, Icon, Input, Tooltip, Typography, Row } from "antd";

const InputsIntegrationModal = ({
  setEditBusinessIntegrationData,
  editBusinessIntegrationData,
  businessIntegrationData,
  questionType,
  visible,
}) => {
  return (
    <Row gutter={[0, 32]}>
      <Row align="left" style={{ marginBottom: "1em", marginTop: "1em" }}>
        <Col>
          <Row>
            <Typography.Text>Clave de acceso (token)</Typography.Text>
          </Row>
          <Row>
            <Input
              name="auth_token"
              placeholder={
                businessIntegrationData.auth_token
                  ? businessIntegrationData.auth_token
                  : "Clave 1"
              }
              defaultValue={businessIntegrationData.auth_token}
              onChange={({ target }) =>
                setEditBusinessIntegrationData({
                  ...editBusinessIntegrationData,
                  [target.name]: target.value,
                })
              }
              value={editBusinessIntegrationData.auth_token}
            />
          </Row>
        </Col>
      </Row>
      <Row align="left" style={{ marginTop: "0.5em" }}>
        <Col>
          <Row>
            <Typography.Text>URL</Typography.Text>
          </Row>
          <Row>
            <Input
              name="base_url"
              placeholder={
                businessIntegrationData.base_url
                  ? businessIntegrationData.base_url
                  : "www.url.com/api"
              }
              onChange={({ target }) =>
                setEditBusinessIntegrationData({
                  ...editBusinessIntegrationData,
                  [target.name]: target.value,
                })
              }
              value={editBusinessIntegrationData.base_url}
            />
          </Row>
        </Col>
      </Row>
      <Row gutter={32} style={{ marginTop: "1em", marginBottom: "0.5em" }}>
        <Col xs={32}>
          {businessIntegrationData.template?.integration_fields.map(
            (integrationField, integrationFieldKey) => {
              return (
                <div key={integrationFieldKey}>
                  <Typography.Text>{integrationField.label}</Typography.Text>
                  {integrationField.tooltip && (
                    <Tooltip
                      title={integrationField.tooltip}
                      style={{ fontFamily: "Lato", width: "2em" }}
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  )}

                  {questionType(
                    { type: integrationField.type },
                    editBusinessIntegrationData,
                    integrationField,
                    integrationField.name
                  )}
                </div>
              );
            }
          )}
        </Col>
      </Row>
    </Row>
  );
};

export default InputsIntegrationModal;
