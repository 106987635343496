import fetch from 'isomorphic-fetch';
import { push } from 'react-router-redux';
import { Modal, message } from 'antd';
import { alertActions } from './alerts';
import { SERVER_URL } from '../utils/config';
import { checkHttpStatus, parseJSON } from '../utils';
import * as Sentry from '@sentry/browser';
import { DATA_FETCH_PROTECTED_DATA_REQUEST,
        DATA_RECEIVE_PROTECTED_DATA,
        DATA_FETCH,
        FILE_FETCH,
        DATA_RECEIVE_FAILURE,
        DATA_RECEIVE_SUCCESS,
        DATA_SEND,
        DATA_UPDATE,
        DATA_UPDATE_SUCCESS,
        DATA_SEND_FAILURE,
        DATA_SEND_SUCCESS,
        DATA_LANE_FETCH,
        DATA_LANE_RECEIVE_SUCCESS,
        DATA_SIMPLE_LANE_RECEIVE_SUCCESS,
        DATA_SIMPLE_LANE_FETCH,
        SEARCH_SUCCESS,
        SEARCH_BEGIN,
        SEARCH_FAILURE,
        FETCH_BEGIN_V2,
        FETCH_SUCCESS_V2,
        FETCH_FAILURE_V2,
        SET_UTM_PARAMS,
        SET_SELECTED_BRANCHES,
} from '../constants';
import { authLoginUserFailure } from './auth';

import i18n from '../i18n';

export function dataReceiveProtectedData(data) {
    return {
        type: DATA_RECEIVE_PROTECTED_DATA,
        payload: {
            data
        }
    };
}

export function dataFetchProtectedDataRequest() {
    return {
        type: DATA_FETCH_PROTECTED_DATA_REQUEST
    };
}

export function dataFetchProtectedData(token,url,rawdata) {
    return (dispatch, state) => {
        dispatch(dataFetchProtectedDataRequest());
        const data = btoa(`${rawdata}`);
        return fetch(`${SERVER_URL}/api/v1/${url}`, {
            // // credentials: 'include',
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${token}`,
                Data: `${data}`
            }
        })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then((response) => {
            dispatch(dataReceiveProtectedData(response.data));
        })
        .catch((error) => {
            if (error && typeof error.response !== 'undefined' && error.response.status === 401) {
                // Invalid authentication credentials
                return error.response.json().then((data) => {
                    dispatch(authLoginUserFailure(401, data.non_field_errors[0]));
                    dispatch(push('/login'));
                });
            } else if (error && typeof error.response !== 'undefined' && error.response.status >= 500) {
                // Server side error
                dispatch(authLoginUserFailure(500, 'A server error occurred while sending your data!'));
            } else {
                // Most likely connection issues
                dispatch(authLoginUserFailure('Connection Error', 'An error occurred while sending your data!'));
            }

            Sentry.captureException('Protected Data');
            dispatch(push('/login'));
            return Promise.resolve(); // TODO: we need a promise here because of the tests, find a better way
        });
    };
}

export const fetchBegin = url => ({
    type: DATA_FETCH,
    payload: { url }
});

export const fetchFileBegin = url => ({
    type: FILE_FETCH,
    payload: { url }
});

export const fetchLaneBegin = url => ({
    type: DATA_LANE_FETCH,
    payload: { url }
})

export const fetchSimpleLaneBegin = data => ({
    type: DATA_SIMPLE_LANE_FETCH,
    payload: {
        status: data.status
    }
})

export const fetchSuccess = data => ({
    type: DATA_RECEIVE_SUCCESS,
    payload: { data }
});

export const fetchLaneSuccess = (lane, data) => ({
    type: DATA_LANE_RECEIVE_SUCCESS,
    payload: { [lane]: data }
});

export const fetchSimpleLaneSuccess = (lane, data) => {
    let selected = {}
    data.forEach( user => {
        selected[user.personal_user_id] = {name: user.user_name, email: user.email, laneId: lane}
    })
    return {
        type: DATA_SIMPLE_LANE_RECEIVE_SUCCESS,
        payload: { selected: selected }
    }
}


export const fetchFailure = error => ({
    type: DATA_RECEIVE_FAILURE,
    payload: { error }
});


export function fetchData(token, url, rawdata, method='GET') {
    return dispatch => {
        dispatch(fetchBegin(url));
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }

        if (rawdata) headers['Raw-Data'] = rawdata
        const config = {
            method: `${method}`,
            headers,
        };
        if (method.toUpperCase() !== 'GET') config['body'] = rawdata
        return fetch(`${SERVER_URL}/api/v1/${url}`, config)
        .then(handleErrors)
        .then(res => res.json())
        .then(async (json) => {
            dispatch(fetchSuccess(json));
            return json;
        })
        .catch(error => {
            Sentry.captureException('Fetch');
            dispatch(fetchFailure(error));
            return Promise.resolve();
        });
    };
}

export function fetchFile(token, url, rawdata, method='get', filename) {
    return dispatch => {
        dispatch(fetchFileBegin(url));
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }

        if (rawdata) headers['Raw-Data'] = rawdata
        return fetch(`${SERVER_URL}/api/v1/${url}`, {
            method: `${method}`,
            headers,
        })
        .then(handleErrors)
        .then(response => {
            const resp = { 
                response,
                ct: response.headers.get('Content-Type')
            };
            return resp
        })
        .then(resp => {
            const { response, ct } = resp;
            if (ct === 'application/vnd.ms-excel' || ct === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                response.blob().then(blob => {
                    const url = URL.createObjectURL(blob)
                    var fileLink = document.createElement('a');
                    fileLink.href = url;
                    fileLink.download = filename;
                    fileLink.click();
                    // window.open(url, '_blank');
                    URL.revokeObjectURL(url);
                })
            } else {
                Modal.info({
                    title: 'Reporte en proceso',
                    content: 'El reporte se está generando en segundo plano. Será enviado por correo'
                })
            }
        })
    };
}

export function fetchDataLane(token,url,method = 'get') {
    const urlParams = new URLSearchParams(url);
    const status = urlParams.get('status');
    return dispatch => {
        dispatch(fetchLaneBegin(url));

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }

        return fetch(`${SERVER_URL}/api/v1/${url}`, {
            method: `${method}`,
            headers,
            // // // credentials: 'include',
        })
        .then(handleErrors)

        .then(res => res.json())
        .then(json => {
            if (url.includes('pagination=false')){
                dispatch(fetchLaneSuccess(status, {count:json.length, results:json}));
            }
            else{
                dispatch(fetchLaneSuccess(status, json));
            }
            
            return json;
        })
        .catch(error => {
            Sentry.captureException('Fetch');
            dispatch(fetchFailure(error));
            return Promise.resolve();
        })
    };
}

export function fetchSimpleDataLane(token,url,method = 'get') {
    const urlParams = new URLSearchParams(url);
    const status = urlParams.get('status');
    return dispatch => {
        dispatch(fetchLaneBegin(url));
        return fetch(`${SERVER_URL}/api/v1/${url}`, {
            method: `${method}`,
            // credentials: 'include',
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${token}`,
                "Content-Type": `application/json`,
            }
        })
        .then(handleErrors)

        .then(res => res.json())
        .then(json => {
            dispatch(fetchSimpleLaneSuccess(status, json));
            return json;
        })
        .catch(error => {
            Sentry.captureException('Fetch');
            dispatch(fetchFailure(error));
            return Promise.resolve();
        })
    };
}

export const sendBegin = url => ({
  type: DATA_SEND,
  payload: { url }
});

export const sendSuccess = data => ({
  type: DATA_SEND_SUCCESS,
  payload: { data }
});

export const sendFailure = error => ({
  type: DATA_SEND_FAILURE,
  payload: { error }
});

export function sendData(token, url, rawdata, method='POST', message='', customHeaders={}) {
    return dispatch => {
        dispatch(sendBegin(url));
        //const outdata = ${rawdata};
        return fetch(`${SERVER_URL}/api/v1/${url}`, {
            method: `${method}`,
            headers: {
                "Accept": 'application/json',
                "Authorization": `Token ${token}`,
                "Content-Type": `application/json`,
                ...customHeaders
            },
            body: `${rawdata}`
        })
        .then(handleErrors)
        .then(res => res.json())
        .then(json => {
            dispatch(sendSuccess(json));
            dispatch(alertActions.success(message));
            return json;
        })
        .catch(error => {
            Sentry.captureException('Send data');
            let data = {url};
            fetch(`${SERVER_URL}/api/v1/gamesandtests/errorshistory/`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(data), // data can be `string` or {object}!
                headers: {
                    Accept: 'application/json',
                    Authorization: `Token ${token}`,
                    "Content-Type": `application/json`,
                },
              }).then(res => res.json())
              .catch(error => console.error('Error:', error))
              .then(response => console.log('Success:', response));
            dispatch(sendFailure(error));
            dispatch(alertActions.error("Hubo un error enviando los datos"));
            return Promise.reject()
        })
    };
}

export function sendDataAndRedirect(token,url,rawdata,method,redirect,message='') {
  return dispatch => {
      dispatch(fetchBegin(url));
      //const outdata = ${rawdata};
      return fetch(`${SERVER_URL}/api/v1/${url}`, {
          method: `${method}`,
          // credentials: 'include',
          headers: {
              Accept: 'application/json',
              Authorization: `Token ${token}`,
              "Content-Type": `application/json`,
          },
          body: `${rawdata}`
      })
      .then(handleErrors)
/*        .then(res => {dispatch(fetchSuccess(res.json()));
          return res.json();})*/
      .then(res => res.json())
      .then(json => {
          dispatch(sendSuccess(json));
          dispatch(push(redirect));
          dispatch(alertActions.success(message))
      })
      .catch(error => {
          console.error(error);
          Sentry.captureException('Send Data and Redirect');
          dispatch(sendFailure(error));
          dispatch(alertActions.error("Hubo un error enviando los datos"));
          return Promise.resolve();
      })
  };
}

export const updateBegin = url => ({
    type: DATA_UPDATE,
    payload: { url }
})

export const updateSuccess = data => ({
    type: DATA_UPDATE_SUCCESS,
    payload: { data }
})

export function updateData(token,url,rawdata,method = 'PATCH', message='') {
    return dispatch => {
        dispatch(updateBegin(url));
        return fetch(`${SERVER_URL}/api/v1/${url}`, {
            method: `${method}`,
            // credentials: 'include',
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${token}`,
                "Content-Type": `application/json`,
            },
            body: `${rawdata}`
        })
        .then(handleErrors)
        .then(res => res.json())
        .then(json => {
            dispatch(updateSuccess(json));
            dispatch(alertActions.success(message));
        })
        .catch(error => {
            Sentry.captureException('Send data');
            dispatch(sendFailure(error));
            dispatch(alertActions.error("Hubo un error enviando los datos"));
            return Promise.resolve();
        })
    };
}


export function sendFile(token, url, file, name, message='', filetype='', method='PUT') {
    return dispatch => {
        dispatch(sendBegin(url));
        //const outdata = ${rawdata};
        let formData = new FormData();
        formData.append(name, file);
        formData.append('filetype', filetype);
        return fetch(`${SERVER_URL}/api/v1/${url}`, {
            method,
            // credentials: 'include',
            headers: {
                Accept: 'application/json, text/plain, */*',
                Authorization: `Token ${token}`,
            },
            body: formData,
        })
        .then(handleErrors)
        .then(res => res.json())
        .then(json => {
            dispatch(sendSuccess(json));
            dispatch(alertActions.success(message));
            return json;
        })
        .catch(error => {
            Sentry.captureException('Send file');
            dispatch(sendFailure(error));
            dispatch(alertActions.error("Hubo un error subiendo el archivo"));
            return Promise.reject();
        })
    };
}

export const searchBegin = text => ({
    type: SEARCH_BEGIN,
    payload: { searchText: text }
});

export const searchSuccess = data => ({
    type: SEARCH_SUCCESS,
    payload: { searchResults: data.results }
})

export const searchFailure = error => ({
    type: SEARCH_FAILURE,
    payload: { searchError: error }
})

export function search(token, url, text){
    return dispatch => {
        dispatch(searchBegin(text))
        return fetch(`${SERVER_URL}/api/v1/${url}`, {
            method: 'GET',
            // credentials: 'include',
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${token}`,
                "Content-Type": `application/json`
            },
        })
        .then(handleErrors)
        .then(res => res.json())
        .then(json => {
            dispatch(searchSuccess(json));
        })
        .catch(error => {
            Sentry.captureException('Search Field');
            dispatch(searchFailure(error));
            dispatch(alertActions.error("Hubo un error subiendo el archivo"));
            return Promise.resolve();
        })
    }
}


export const fetchBeginV2 = url => ({
    type: FETCH_BEGIN_V2,
    payload: { url }
});

export const fetchSuccessV2 = data => ({
    type: FETCH_SUCCESS_V2,
    payload: { data }
});

export const fetchFailureV2 = error => ({
    type: FETCH_FAILURE_V2,
    payload: { error }
});

/**
 * New fetch data only mutate isFetching and error state
 * @param {*} token 
 * @param {*} url 
 * @param {*} bodyData 
 * @param {*} method get, post, patch, put or delete
 */
export function fetchAndReturn(token, endpoint, method='GET', bodyData=null, extraHeaders={}) {
    let url = `${SERVER_URL}${endpoint}`;
    return dispatch => {
        dispatch(fetchBeginV2(url));
        
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...extraHeaders,
        };

        if (token && token !== '') {
            headers['Authorization'] = `Token ${token}`;
        }

        let config = bodyData ?
            {
                method: `${method}`,
                headers,
                body: JSON.stringify(bodyData)
            } :
            {
                method: `${method}`,
                headers
            }
        let tmpResp = { body: null, status: null }
        return fetch(url, config)
        .then(response => {
            tmpResp.status = response.status;
            return response.json().then(
                data => ({
                    body: data,
                    status: response.status
                })
            ).catch(() => ({ body: null, status: response.status }));
        })
        .then(json => {
            if (json.status < 400) {
                dispatch(fetchSuccessV2(json));
                return json;
            }
            else {
                if (json.status === 403 && method !== 'GET') {
                    message.error(
                        i18n.t('commons__permissions_error')
                    );
                }
                dispatch(fetchFailureV2(json.status));
                return {
                    status: json.status, 
                    body:  json.body ? json.body : null, 
                    message: json.body ? json.body.message : 'Error',
                };
            }
        })
        .catch(error => {
            console.log("🚀 ~ file: data.js ~ line 553 ~ fetchAndReturn ~ error", error)
                dispatch(searchFailure(error));
                return { status: 500 };
        });
    };
};

/**
 * For DELETE requests
 * @param {*} token
 * @param {*} endpoint
 */
export function deleteAndReturn(token, endpoint) {
    const url = `${SERVER_URL}${endpoint}`;
    return dispatch => {
        dispatch(fetchBeginV2(url));

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };

        if (token && token !== '') {
            headers['Authorization'] = `Token ${token}`;
        }

        let config = { headers, method: 'DELETE' };
        return fetch(url, config)
            .then(response => {
                if (response.status < 400) {
                    dispatch(fetchSuccessV2(response));
                } else {
                    dispatch(fetchFailureV2(response.status));
                }
                return response;
            })
            .catch(error => {
                Sentry.captureException(error);
                dispatch(fetchFailureV2(error));
                return {};
            });
    };
};

/**
 * Similar to fetch and return but receives any url
 * @param {*} token 
 * @param {*} url 
 * @param {*} method 
 * @param {*} bodyData 
 * @returns 
 */
export function fetchAnyUrl(token, url, method = 'get', bodyData = null){
    return dispatch => {
        dispatch(fetchBegin(url));
        const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
        }

        let config = bodyData ?
        {
            method: `${method}`,
            headers,
            body: JSON.stringify(bodyData)
        } :
        {
            method: `${method}`,
            headers
        }

        return fetch(url, config)
        .then(response => {
            return response.json()
        })
        .then(json =>{
            dispatch(fetchSuccess(json));
            return json
        })
        .catch(error => {
            Sentry.captureException('Fetch');
            dispatch(fetchFailure(error));
            return Promise.resolve();
        })
    }
}


// Handle HTTP errors since fetch won't.
function handleErrors(response) {
    if (!response.ok) {
        throw new Error(response.statusText);
    }
    return response;
}


export const saveUtmParams = utm_params => ({
    type: SET_UTM_PARAMS,
    payload: { utm_params }
});

/**
 * 
 * @param {String} jobApplicationId El ID de la JobApplication principal
 * @param {String[]} selectedBranches Arreglo de IDs de las sucursales seleccionadas
 * @returns 
 */
export const saveSelectedBranches = (jobApplicationId, selectedBranches) => ({
    type: SET_SELECTED_BRANCHES,
    payload: { jobApplicationId, selectedBranches }
});

