import React, { useContext, useState, useEffect } from 'react';

import { Row, Table } from 'antd';

import { CandidatePoolContext } from './config/CandidatePoolContext';

function CandidatePoolTable({
  setSelectedCandidatesKeys=()=>{},
}) {
  const { candidates, columns, loading, selectedCandidates } = useContext(CandidatePoolContext);

  const [currentCandidates, setCurrentCandidates] = useState([]);

  useEffect(() => {
    setCurrentCandidates(candidates);
  }, [candidates]);

  return (
    <Row>
      <Table
        loading={loading}
        className='candidate-pool-table'
        style={{ height: '100%' }}
        columns={columns}
        dataSource={candidates}
        pagination={false}
        scroll={{ x: (180 * columns.length), y: 600 }}
        rowSelection={{
          selectedRowKeys: Object.keys(selectedCandidates),
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedCandidatesKeys(selectedRowKeys);
          }
        }}
      />
    </Row>
  )
}

export default CandidatePoolTable;
