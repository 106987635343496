import React, { Component } from 'react';
import {
  Col,
  List,
  Icon,
  Typography,
  Collapse,
  Switch,
  Checkbox,
  Divider
} from 'antd';
import './OptionalFields.scss';

const { Text } = Typography;
const { Panel } = Collapse;

const requiredFields = [
  {
    fieldName: 'Email'
  },
  {
    fieldName: 'DNI'
  },
];

// nacionalidad, pais, comuna y estado civil
const labels = {
  name: 'Nombre',
  surname: 'Apellidos',
  phone: 'Teléfono',
  birth_date: 'Fecha de nacimiento',
  gender: 'Género',
  nationality: 'Nacionalidad',
  country: 'País de residencia',
  commune: 'Comuna',
  // marital_status: 'Estado civil',
  education_level: 'Nivel de educación',
  careers: 'Antecedentes académicos',
  experiences: 'Experiencia laboral',
  cv: 'Currículum vitae'
}

class OptionalFields extends Component {

  state = {
    allRequired: true,
  }

  onCheckAllChange = (event) => {
    const { onChange, values } = this.props;

    this.setState({
      allRequired: event.target.checked
    })

    for (const type in values) {
      onChange(type, !event.target.checked)
    }
  }

  checkIfAllIsChecked = () => {
    const { values } = this.props;
    const allValues = Object.values(values);

    this.setState({ allRequired: allValues.includes(true) ? false : true })
  }

  onSwitchChange = async (key, value) => {
    await this.props.onChange(key, value);
    this.checkIfAllIsChecked();
  }

  componentDidMount() {
    this.checkIfAllIsChecked()
  }

  render() {
    const { values, jobAppStatus } = this.props;
    const { allRequired } = this.state;
    return (
      <Col sm={24}>
        <div className='af__switch'>
          <div className='headline'>
            <div className='text'>
              <Text strong>Datos personales</Text>
              <Checkbox
                onChange={this.onCheckAllChange}
                checked={allRequired}
                disabled={jobAppStatus === 'ACTIV'}
              >
                Todos requeridos
              </Checkbox>
            </div>
            <Divider />
          </div>

          <List
            size="large"
            dataSource={requiredFields}
            renderItem={(item, index) =>
              <List.Item key={index}>
                <List.Item.Meta
                  title={item.fieldName}
                />
                <Text type='secondary'>Siempre requerido</Text>
              </List.Item>
            }
          />

          <Collapse
            bordered={false}
            expandIcon={({ isActive }) => <Icon type="down" rotate={isActive ? 180 : 0} />}
          >
            <Panel header="Datos Opcionales" key="1">
              <List
                size="large"
                dataSource={Object.keys(labels)}
                className='af__switch__values-list'
                renderItem={(item, index) => {
                  const classByValue = !values[item] ? 'required' : 'optional';
                  return (
                    <div>
                      {
                        values[item] === 2 || (
                          <>
                            <List.Item key={index}>
                              <List.Item.Meta
                                title={<Text className={classByValue}>{labels[item]}</Text>}
                              />
                              <Text type='secondary' className={classByValue}>
                                {!values[item] ? 'Requerido' : 'Opcional'}
                              </Text>
                              <Switch disabled={jobAppStatus === 'ACTIV'} checked={!values[item]} onChange={(e) => this.onSwitchChange(item, !e)} />
                            </List.Item>
                          </>
                        )
                      }
                    </div>
                  )
                }}
              />
            </Panel>
          </Collapse>
        </div>
      </Col>
    )
  }
}

export default OptionalFields;