import React, { useContext, useEffect, useRef, useState } from 'react'

import {
  Col, Row, Select, Modal, Dropdown, Icon
} from 'antd';

import JobAppContext from '../../../context/JobAppContext';


function Evaluation({ name, evaluationType, data, required, onChangeEvaluation, onDeleteEvaluation }) {
  const {
    stages,
  } = useContext(JobAppContext);

  const [openDropdown, setOpenDropdown] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [newStage, setNewStage] = useState(data.stage);

  const wrapperRef = useRef(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpenDropdown(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const getMenu = () => {
    switch (evaluationType) {
      case 'genome':
        return (
          <div className='added-evaluation-dropdown-menu'>
            <div style={{ margin: '0px 0px 10px 0px' }}>Juegos agregados:</div>
            {
              data.map((obj, idx) =>
                <div key={idx}>
                  {`• ${obj.name}`}
                </div>
              )
            }
          </div>
        )
      case 'additionalQuestion':
        return (
          <div className='added-evaluation-dropdown-menu'>
            <div style={{ margin: '0px 0px 10px 0px' }}>Preguntas agregadas:</div>
            {
              data.questions.sort((a,b) => a.position - b.position).map((obj, idx) =>
                <div key={idx}>
                  {`• ${obj.question}`}
                </div>
              )
            }
          </div>
        )
      case 'filesRequest':
        return (
          <div className='added-evaluation-dropdown-menu'>
            <div style={{ margin: '0px 0px 10px 0px' }}>Archivos solicitados:</div>
            {
              data.questions.map((question, idx) => 
              <div key={idx}>
                {`• ${question.name}`}
                </div>
                )
            }
          </div>
        )
      case 'videoInterview':
        return (
          <div className='added-evaluation-dropdown-menu'>
            <div style={{ margin: '0px 0px 10px 0px' }}>Preguntas agregadas:</div>
            {
              data.questions.map((obj, idx) =>
                <div key={idx}>
                  {`• ${obj.text}`}
                </div>
              )
            }
          </div>
        )
      default:
        return <></>;
    };
  };

  const getEvaluationComponent = () => {
    const hasDropdown = ['genome', 'additionalQuestion', 'filesRequest', 'videoInterview'].includes(evaluationType);
    switch (evaluationType) {
      default:
        return (
          <>
            <div
              ref={wrapperRef}
              onClick={() => {
                hasDropdown && setOpenDropdown(oldState => !oldState)
              }}
              style={{
                cursor: hasDropdown ? 'pointer' : 'auto',
                maxWidth: '80%',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {
                hasDropdown &&
                <Icon
                  type={openDropdown ? 'down' : 'right'}
                  style={{ marginRight: 5 }}
                />
              }
              {name}
            </div>
            {
              !required &&
              <Row type='flex' justify='end'>
                <div style={{ borderRadius: '50%', background: '#85A5FF', height: 22, width: 22, display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 10 }}>
                  <Icon
                    type='swap'
                    onClick={() => { setOpenEditModal(true) }}
                  />
                </div>
                <div style={{ borderRadius: '50%', background: '#85A5FF', height: 22, width: 22, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Icon
                    type='minus'
                    onClick={() => onDeleteEvaluation(data.id, evaluationType)}
                  />
                </div>
              </Row>
            }
          </>
        )
    }
  };
  

  return (
    <>
      <Col sm={24} md={12} lg={8}>
        <Dropdown overlay={getMenu()} visible={openDropdown}>
          <Row className='added-evaluation-current-stage' type='flex' align='middle' justify='space-between'>
            {getEvaluationComponent()}
          </Row>
        </Dropdown>
      </Col>


      <Modal
        title="Mover evaluación a etapa"
        visible={openEditModal}
        onOk={() => {
          onChangeEvaluation(data.id, newStage, evaluationType);
          setOpenEditModal(false);
        }}
        onCancel={() => { setOpenEditModal(false) }}
      >
        <Select
          value={newStage}
          placeholder='Mover de etapa'
          style={{ width: 200, paddingRight: 10 }}
          onChange={(newStage) => setNewStage(newStage)} >
          {
            stages.map(obj =>
            (obj.id !== 'HIRED' && obj.id !== 'OTHER' &&
              <Select.Option value={obj.id} key={obj.id}>
                <span style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  {obj.name}
                </span>
              </Select.Option>
            )
            )
          }
        </Select>
      </Modal>
    </>
  )

};

export default Evaluation;