import React from 'react';
import { Link } from 'react-router-dom';

import ParseHtml from '../../../components/parseHtml';
import {
  Row,
  Col,
  message,
  Typography,
  Button,
  Card,
  Icon
} from 'antd'
import propTypes from 'prop-types';
import i18n from '../../../i18n';

const { Title, Text } = Typography;

/**
 * 
 * @param {Object} jobData
 * @param {Function} diffDay
 * @param {String} jobID 
 * @returns 
 */

const JobInfoModal = ({ jobData, jobID, diffDay }) => {
  return (
    <Row>
      <Card style={{ height: '100%', padding: '0' }}>
        <Row type='flex' style={{ margin: '0 0 20px 0' }}>
          <Col span={4}>
            <Row type='flex' justify='center'>
              <img src={jobData.logo} style={{ height: '70%', width: '70%' }} />
            </Row>
          </Col>
          <Col span={20}>
            <Row type='flex' justify='space-between' style={{ flexDirection: 'column' }}>

              <Row type='flex' justify='space-between'>
                <Col>
                  <Title level={3} style={{ margin: '0', fontSize: '16px' }}>
                    {jobData.job_application}
                  </Title>
                </Col>
                <Col>
                  <Button icon='link' size='small' onClick={() => {
                    navigator.clipboard.writeText(`https://app.genoma.work/startprocess/${jobID}?utm_source=genomawork&utm_medium=jobs-site&utm_campaign=share`)
                    message.success(i18n.t('job_portal__copy_url'))
                  }} />
                </Col>
              </Row>

              <Row style={{ margin: '5px 0' }}>
                <Text>
                  {jobData.business_user}
                </Text>
              </Row>

              <Row>
                <Text>
                  <Icon type="clock-circle" style={{ margin: '0 5px 0 0' }} />
                  {`${i18n.t('job_portal__published').split('{')[0]} ${diffDay(jobData.creation_date)} ${i18n.t('job_portal__published').split('}')[1]}`} 
                </Text>
              </Row>
              <Row style={{ margin: '5px 0 0 0' }}>
                <Link to={`/startprocess/${jobID}?utm_source=genomawork&utm_medium=jobs-site`} target='_blank'>
                  <Button type="primary" size="small" style={{ width: '90px' }}>
                    {i18n.t('job_portal__apply')}
                  </Button>
                </Link>
              </Row>

            </Row>
          </Col>
        </Row>

        <Row>
          {ParseHtml(jobData.welcome_text)}
          <br />
          {ParseHtml(jobData.description)}
        </Row>
      </Card>
    </Row>
  );
}

JobInfoModal.propTypes = {
  jobData: propTypes.object,
  jobID: propTypes.string,
  diffDay: propTypes.func
}

export default JobInfoModal;