import React, { useContext, useEffect, useState } from 'react'

import { JobAppInfo, Evaluations, JobAppPreview } from '.'

import {
  Badge,
  Breadcrumb,
  Button,
  Col,
  Icon,
  Modal,
  Row,
  Select,
  Typography,
  Spin,
  Skeleton,
  Tooltip,
} from 'antd'
import { Prompt } from 'react-router';

import ModalTour from './ModalTour';
import GenomaSteps from '../../../../components/GenomaSteps/GenomaSteps';
import useBoolean from '../../../../hooks/useBoolean';

import JobAppContext from '../context/JobAppContext'

function JobappTabs() {
  const {
    goTo,
    formType,
    jobApplication: { id, status, name },
    updateJobApplication,
    sendingData,
    showShareProcessModal,
    setShowShareProcessModal,
    submitData,
    isLoading,
  } = useContext(JobAppContext);

  const [activeStep, setActiveStep] = useState(0);
  const [showModalTour, handleModalTour] = useBoolean(
    () => JSON.parse(localStorage.getItem('showCreateProcessTour')) ?? true
  );

  const stepsData = [
    {
      title: 'Información del cargo',
      content:
        <Skeleton loading={isLoading} active paragraph={{ rows: 10 }}>
          <JobAppInfo />
        </Skeleton>
    },
    {
      title: 'Evaluaciones',
      content:
        <Skeleton loading={isLoading} active paragraph={{ rows: 10 }}>
          <Evaluations />
        </Skeleton>
    },
    {
      title: 'Vista previa',
      content:
        <Skeleton loading={isLoading} active paragraph={{ rows: 10 }}>
          <JobAppPreview />
        </Skeleton>
    }
  ];

  useEffect(() => {
    // we check if the route has the # character
    // if it does, we get the number after it and set it as the active step
    const hash = window.location.hash;
    if (hash) {
      const params = hash.split('#')[1];
      const newStep = params.split('-')[1].split('__')[0];
      setActiveStep(parseInt(newStep));
    }
  }, []);
  
  const showTour = () => {
    handleModalTour.on()
  };

  return (
    <>
      <ModalTour
        visible={showModalTour}
        handleModal={handleModalTour}
        actions={{
          setActiveStep,
          setShowShareProcessModal,
        }}
      />
      <Modal
        visible={sendingData}
        closable={false}
        maskClosable={false}
        footer={[]}
      >
        <div style={{ margin: 20 }}>
          <div>
            <Row type='flex' justify='center'>
              <Spin size='large' />
            </Row>
          </div>
          <Row type='flex' justify='center'>
            <Typography.Text style={{ fontSize: 16 }}>Guardando información</Typography.Text>
          </Row>
        </div>
      </Modal>

      <Modal
        className='share-process-modal'
        visible={showShareProcessModal}
        onCancel={() => {
          setShowShareProcessModal(false);
          /*
          if (formType === 'EDIT') {
            window.location.reload()
          }
          else {
            goTo(`/processes/${id}/edit/`)
          }
          */
        }}
        closable={false}
        maskClosable={false}
        footer={[
          <Button key='1' onClick={() => goTo(`/processes/${id}`)}>
            Continuar sin difundir
          </Button>,
          <Button key='2' type='primary' onClick={() => goTo(`/share/${id}`)}>
            Difundir proceso
          </Button>
        ]}
      >
        <div className='share-process-modal-header'>
          <Row type='flex' justify='center' style={{ width: '100%' }}>
            <Icon type='check-circle' theme='filled' style={{ fontSize: 60, color: '#52C41A' }} />
          </Row>

          <Row type='flex' justify='center' style={{ width: '100%' }}>
            {formType === 'NEW' ? '¡Proceso creado exitosamente!' : '¡Proceso actualizado exitosamente!'}
          </Row>
        </div>
        <div className='share-process-modal-content'>
          <Typography.Paragraph style={{ fontSize: 16 }}>
            <strong>Los candidatos ya pueden participar de tu proceso de selección.</strong> Si quieres tener un mayor alcance de candidatos, te recomendamos difundirlo a través de redes sociales y portales.
          </Typography.Paragraph>
          <Typography.Paragraph style={{ fontSize: 16 }}>
            También puedes difundir posteriormente el proceso haciendo clic en el menú
            <Button style={{
              height: 30,
              width: 30,
              padding: '2px 0px 0px 0px',
              marginLeft: 15
            }}>
              <Icon type="ellipsis" style={{ fontSize: 24 }} />
            </Button>
          </Typography.Paragraph>
        </div>
      </Modal>

      <Row style={{ marginBottom: 20 }}>
        <Breadcrumb separator={<Icon type="right" />} style={{ fontSize: 17 }}>
          <Breadcrumb.Item>
            <Typography.Text onClick={() => goTo('/')} className='breadcrumbs-button'>
              Home
            </Typography.Text>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Typography.Text onClick={() => goTo('/processes')} className='breadcrumbs-button'>
              Mis Procesos
            </Typography.Text>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Typography.Text onClick={() => goTo(`/processes/${id}`)}>
              {formType === 'NEW' ? 'Nuevo Proceso' : 'Editar Proceso'} 
            </Typography.Text>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Row>

      <Row type='flex' justify='space-between' align='middle' style={{ marginBottom: 20 }}>
        <Col sm={12}>
          <Icon type="arrow-left" style={{ fontSize: 24 }} onClick={() => goTo(`/processes/${id}`)} />
          <Typography.Text className={'jobapp-title'}>
            {name}
          </Typography.Text>
        </Col>
        <Col sm={12}>
          <Row type='flex' justify='end' align='middle'>
            <Tooltip title='Tutorial de creación y edición de procesos'>
              <Icon type="question-circle" onClick={showTour} style={{ fontSize: 20, marginRight: 10 }} />
            </Tooltip>
            <Select value={status} onChange={(value) => updateJobApplication('status', value)}>
              <Select.Option value={"ACTIV"}><Badge color="#47E197" text="Proceso abierto" /></Select.Option>
              <Select.Option value={"INACT"}><Badge color="#ec5f2f" text="Proceso cerrado" /></Select.Option>
              <Select.Option value={"OTHER"}><Badge color="#D3D3D3	" text="Proceso en borrador" /></Select.Option>
            </Select>
            {activeStep === 2
              ? (
                <Button
                  type='primary'
                  style={{ marginLeft: 10 }}
                  onClick={submitData}
                >
                  {formType === 'NEW' ? 'Crear proceso' : 'Guardar proceso'}
                </Button>
              ) : (
                <Button
                  type='primary'
                  style={{ marginLeft: 10 }}
                  onClick={() => setActiveStep(oldState => oldState + 1)}
                >
                  Siguiente
                </Button>
              )}
          </Row>
        </Col>
      </Row>
      <GenomaSteps data={stepsData} activeStep={activeStep} onChange={setActiveStep} />

      <Row type='flex' justify='space-between' style={{ padding: '16px 16px' }}>
        <Col>
          <Button
            onClick={() => {
              setActiveStep(oldState => oldState - 1);
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
            }}>
            <span>
              <Icon type='arrow-left' style={{ marginRight: 5 }} />
            </span>
            Volver
          </Button>
        </Col>
        <Col>
          {activeStep === 2
            ? (
              <Button type='primary' onClick={submitData}>
                {formType === 'NEW' ? 'Crear proceso' : 'Guardar proceso'}
              </Button>
            ) : (
              <Button
                type='primary'
                onClick={() => {
                  setActiveStep(oldState => oldState + 1);
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  });
                }}
              >
                Siguiente
              </Button>
            )
          }
        </Col>
      </Row>
    <Prompt when={!showShareProcessModal} message={'¿Estás seguro/a que quieres salir? Perderás todos los cambios.'} />
    </>
  );
};

export default JobappTabs;
