import React from 'react';
import { Row, Col, Typography, Icon, Tabs, Progress } from 'antd';
import i18n from '../../../i18n';
import { recommendedEvaluations, rateValues } from '../assets/utils';

const { Text, Title } = Typography;

const VideoInterview = ({ globalEvaluation }) => {
  const totalRecommendations = Object.values(
    globalEvaluation.recommendations
  ).reduce((acc, rec) => acc + rec, 0);

  const evals = Object.entries(globalEvaluation.evaluations).filter(
    ([key, value]) => key !== 'average'
  );

  const totalRating = evals.reduce((acc, [key, { rating }]) => acc + rating, 0);

  const totalQuestions = Object.keys(globalEvaluation.evaluations).length - 1;

  const average = totalRating / totalQuestions;

  const averageRate = rateValues[Math.round(average)] ?? {};

  return (
    <div className="report-pdf-videointerview">
      <p>
        <strong>{i18n.t('candidate_pdf__ve', { ns: 'candidatePdf' }).toUpperCase()}</strong>
      </p>
      <div className="report-pdf-videointerview__global-eval">
        <p className="t12">
          <strong>{i18n.t('candidate_pdf__overall_evaluation', { ns: 'candidatePdf' })}</strong>
        </p>
        {recommendedEvaluations.map((recommendation, idx) => (
          <div className="report-pdf-videointerview__recommendation" key={idx}>
            <Icon
              type={recommendation.icon}
              theme="filled"
              style={{ color: recommendation.color }}
            />
            <p className="t12">{recommendation.text}</p>
            <Progress
              percent={
                (100 * globalEvaluation.recommendations[recommendation.value]) /
                totalRecommendations
              }
              strokeColor="#F175A5"
              strokeWidth={12}
              showInfo={false}
            />
            <p>{globalEvaluation.recommendations[recommendation.value]}</p>
          </div>
        ))}
      </div>
      <div className="report-pdf-videointerview__questions">
        <div className="report-pdf-videointerview__questions__final-score">
          <p className="t12">{i18n.t('candidate_pdf__final_score', { ns: 'candidatePdf' }).toUpperCase()}</p>
          <Progress
            type="circle"
            percent={(average * 100) / 5}
            format={() => <strong>{average.toFixed(1)}</strong>}
            width={60}
            strokeColor={averageRate.color}
          />
          <p className="t10" style={{ color: averageRate.color }}>
            <strong>{averageRate.text}</strong>
          </p>
          <p className="t10" style={{ color: '#5E5E5E' }}>
          {i18n.t('candidate_pdf__num_evaluations', { ns: 'candidatePdf' })}:{' '}
            {globalEvaluation.business_evaluations.length}
          </p>
        </div>
        <div className="report-pdf-videointerview__questions__list">
          <p className="t12">
            <strong>{i18n.t('candidate_pdf__average_ev_per_question', { ns : 'candidatePdf' })}</strong>
          </p>
          {evals.length ? (
            evals.map(([ansId, { question, rating }]) => (
              <div className="report-pdf-videointerview__questions__question" key={ansId}>
                <p className="t10">{question}</p>
                <p
                  className="t12"
                  style={{ color: rateValues[Math.round(rating)].color }}
                >
                  {rateValues[Math.round(rating)].text}
                </p>
                <Progress
                  type="circle"
                  percent={(rating * 100) / 5}
                  format={() => <strong>{rating.toFixed(1)}</strong>}
                  width={30}
                  strokeColor={rateValues[Math.round(rating)].color}
                />
              </div>
            ))
          ) : (
            <p className="t12">{i18n.t('candidate_pdf__no_available_evaluations', { ns : 'candidatePdf' })}.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoInterview;
