import { lazy } from 'react';

export { default as HomeView } from './Home/index';
export { default as ClientsHome } from './ClientsHome';
export { default as NotFoundView } from './Errors/NotFound';
export { default as MaintenanceView } from './Errors/Maintenance';
export { default as ExpireShareToken } from './Errors/ExpireShareToken';
export { default as ForbiddenUserView } from './Errors/ForbiddenUser';

export { default as BusinessCollaborators } from './Business/BusinessCollaborators';
export { default as BusinessRoles } from './Business/BusinessRoles';
export { default as EditBusinessUserView } from './BusinessUserAccount/EditBusinessUser';
export { default as BusinessUserFirstLogin } from './BusinessUserFirstLogin/BusinessUserFirstLogin';

export { default as UserHomeView } from './UserHome/index';
export { default as UpdatePasswordView } from './Account/PasswordChange';
export { default as UpdateMfaView } from './Account/MfaConfiguration';
export { default as AccountPermissionsView } from './Account/AccountPermissions';
export { default as UpdateNameView } from './Account/NameChange';
export { default as AccountView } from './Account/AccountView';
export { default as Calendars } from './Account/Calendars';

export { default as PasswordRecoveryView } from './PasswordRecovery/PasswordRecovery';
export { default as ChangePasswordView } from './ChangePassword/index';

export { default as JobListingView } from './JobListing/index';
export { default as JobListingViewV2 } from './JobListing/JobListing';

export { default as JobAppGroupView } from './JobApplicationGroup/index';

export { default as JobApplicationProcessStartView } from './JobApplicationProcess/start';
export { default as CandidatePath } from './JobApplicationProcess/candidatepath/CandidatePath';

export { default as KnockOutQuestionView } from './KnockOut/index';
export { default as CustomTestView } from './CustomTest/CustomTestForm/index';
export { default as CustomTestSurveyView } from './CustomTest/CustomTestSurveyForm/index';

export { default as CustomTestCreate } from './CustomTest/CustomTestCreate/CustomTest';
export { default as NewCustomTest } from './CustomTest/CustomTestCreate/AppNewCustomTest';
// export { default as BARTGameView } from './Game/bart';
// export { default as GoNoGoGameView } from './Game/gonogo';
// export { default as IOWAGameView } from './Game/iowa';
// export { default as TORRESGameView } from './Game/torres';
// export { default as DIGITGameView } from './Game/digit';
// export { default as STROOPGameView } from './Game/stroop';
// export { default as DADOSGameView } from './Game/dados';

export { default as BART2GameView } from './Game/v2/bart';
export { default as BIG5GameView } from './Game/v2/bigfive';
export { default as TNUM2GameView } from './Game/v2/tnum';
export { default as TLET2GameView } from './Game/v2/tlet';
export { default as TLOG2GameView } from './Game/v2/tlog';
export { default as TEMO2GameView } from './Game/v2/temo';
export { default as DICT2GameView } from './Game/v2/dict';
export { default as STRP2GameView } from './Game/v2/strp';
export { default as THEX2GameView } from './Game/v2/thex';
export { default as IOWA2GameView } from './Game/v2/iowa';
export { default as MEMO2GameView } from './Game/v2/memo';
export { default as DICE2GameView } from './Game/v2/dice';
export { default as EFRT2GameView } from './Game/v2/efrt';
export { default as GNG2GameView } from './Game/v2/gng';
export { default as TOWR2GameView } from './Game/v2/towr';
export { default as DOCRView } from './FilesRequest/FilesRequest';
export { default as PersonalFormActivityView } from './PersonalFormActivity/index';

const CFITGameView = lazy(() => import('./Game/v2/cfit'));
const CATNGameView = lazy(() => import('./Game/v2/catn'));
const LEADGameView = lazy(() => import('./Game/v2/lead'));

export { CFITGameView, CATNGameView, LEADGameView }

// Custom
export { default as ALSTGameView } from './Game/v2/custom_alst';
export { default as ALSPGameView } from './Game/v2/custom_alsp';
export { default as PCLAGameView } from './Game/v2/likertClaro';

export { default as CandidateFeedbackV2 } from './CandidateDashboard/Feedback/Feedback'; //new
export { default as ShareCandidateFeedbackV2 } from './CandidateDashboard/Feedback/ShareFeedback'; //new


// export { default as ProcessListView } from './BusinessDashboard/ProcessList';
export { default as ProcessListV2 } from './BusinessDashboard/ProcessListV2/ProcessList';
export { default as CandidatePoolView } from './CandidatePool';
export { default as ShareProcess } from './BusinessDashboard/ShareProcess/'; //new

export { default as NewProcessFormViewV2 } from './BusinessDashboard/NewProcessFormV2/NewProcessForm'; 
export { default as NewProcessFlex } from './BusinessDashboard/ProcessFlex'; // new

export { default as RecruitmentPortalSettingsV2 } from './JobListing/RecruitmentPortalSettings'; //new
export { default as PeopleAnalyticsCandidatesView } from './BusinessDashboard/CandidateComparator'; // new

export { default as AddReferencesView } from './ReferencesV2/AddReferences'; // new
export { default as ReferenceFormView } from './References/ReferenceForm';

// export { default as ReasoningTestView } from './Tests/ReasoningTest/ReasoningTest';
// export { default as NumericTestView } from './Tests/NumericTest/NumericTest';
// export { default as LogicTestView } from './Tests/LogicTest/LogicTest';
// export { default as EmotionTestView } from './Tests/EmotionTest/EmotionTest';
// export { default as HexacoTestView } from './Tests/HexacoTest/HexacoTest';

export { default as Tos } from './CandidateDashboard/Tos';
export { default as CandidateAccountInfoView } from './CandidateDashboard/AccountInfo';
export { default as CandidateProfileSection } from './CandidateDashboard/Profile';

export { default as addDocumentsForm } from './Triggers/addDocuments';
export { default as CandidateList } from './BusinessDashboard/CandidateList/CandidateListV3';
export { default as CandidateUpload } from './BusinessDashboard/CandidateListV2/CandidateUpload/CandidateUpload';

export { default as CustomInterview } from './BusinessDashboard/InterviewKit/CustomInterview';
export { default as NewInterview } from './BusinessDashboard/InterviewKit/New/NewInterview';
export { default as NewScorecard } from './BusinessDashboard/InterviewKit/New/NewScorecard';
export { default as EditInterview } from './BusinessDashboard/InterviewKit/Edit/EditInterview';
export { default as EditScorecard } from './BusinessDashboard/InterviewKit/Edit/EditScorecard';
export { default as AccountSetting } from './AccountV2/AccountSetting';
export { default as CandidateLoginView } from './Login/CandidateLogin';
export { default as BusinessLoginView } from './Login/BusinessLogin';

export { default as SocialLoginRedirectView } from './Login/components/SocialLoginRedirect';
export { default as SocialSignUp } from './SignUp/SocialSignUp';
export { default as SignUpView } from './SignUp/SignUp';

export { default as ImpersonateView } from './Impersonate/Impersonate';
export { default as NextGameView } from './Impersonate/NextGame';

export { default as ManageIntegrationsView } from './BusinessDashboard/AtsIntegration/Integrations'; // new
export { default as CustomFields } from './BusinessDashboard/CustomFields/CustomFieldsManagement'; // new
export { default as CustomFieldForm } from './BusinessDashboard/CustomFields/CustomFieldForm'; // new
export { default as EmailTemplatesView } from './BusinessDashboard/TemplatesMails/TemplateMails'; // new
export { default as EmailTemplatesView2 } from './BusinessDashboard/TemplatesMails/MainMailTemplates.jsx'; // new
export { default as NewTemplateView } from './BusinessDashboard/TemplatesMails/components/NewTemplate'; // new
export { default as EditTemplateView } from './BusinessDashboard/TemplatesMails/components/EditTemplate'; // new

export { default as DiscardReasonsView } from './BusinessDashboard/DiscardReasons';

export { default as NotificationSettingsView } from './Notifications/NotificationSettings'; 

export { default as ClearStorageView } from './ClearStorage/ClearStorageView';

export { default as NewAnalytics } from './BusinessDashboard/NewAnalytics';

// TESTING

export { default as Calendar } from './Calendar';

//
export { default as PrivacyPolicyComponent } from './Privacy';
export { default as ToolsDashboardView } from './Tools';
export { default as ReferencesDashboard } from './References/ReferencesDashboard';
export { default as ReferenceTemplateForm } from './References/ReferenceTemplateForm';

export { default as Jobs } from './Jobs/Jobs';
export { default as HiringRoomIntegration } from './Integrations/hiringroom';
export { default as LeverIntegration } from './Integrations/Lever';
export { default as IsolatedModal } from './IsolatedModal/';

export { default as NotificationHistoryView } from './Notifications/NotificationHistory/NotificationHistoryView';
export { default as ApplicationForm } from './BusinessDashboard/ApplicationForm/ApplicationForm.jsx';

export { default as CandidateInterview } from './CandidateInterview';
export { default as CandidateUserLanding } from './CandidateUserLanding';
export { default as NewCandidatePath} from './JobApplicationProcess/NewCandidatePath/CandidatePath';

export { default as FilesTemplateDashboard} from './BusinessDashboard/FilesRequests/FilesTemplatesDashboard';
export { default as FilesTemplateForm } from './BusinessDashboard/FilesRequests/FilesTemplatesForm';
// export { default as InterviewProcessesView } from './BusinessDashboard/Interviews';
const InterviewProcessesView = lazy(() => import('./BusinessDashboard/Interviews'));
export { InterviewProcessesView };
export { default as JobPortals } from './BusinessDashboard/JobPortals';

export { default as Evaluations } from './Evaluations';
export { default as GenomaTests } from './GenomaTests';

const ResourcesView = lazy(() => import('./CandidateDashboard/Resources'));
export { ResourcesView };
