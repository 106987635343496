import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { push } from 'react-router-redux';
import { bindActionCreators } from "redux";

import useBoolean from '../../../hooks/useBoolean';
import useBreadcrumb from '../../../hooks/useBreadcrumb';
import * as actionCreators from "../../../actions/data";
import FormDiscardReasonModal from "./FormModal";
import i18n from "../../../i18n";

import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Icon,
  Input,
  Layout,
  message,
  Popconfirm,
  Row,
  Table,
  Tooltip,
  Typography,
} from "antd";
import PropTypes from "prop-types";

const { Item } = Breadcrumb;
const { Paragraph, Title } = Typography;

const assetsUrl = 'https://genoma-assets.s3.us-east-2.amazonaws.com/';

const DiscardReasons = ({ actions, business, dispatch, token }) => {
  const Breadcrumb = useBreadcrumb(
    [
      {
        path: '/',
        text: 'Home',
      },
      {
        path: 'tools',
        text: 'Gestión',
      },
      {
        path: 'emailtemplates',
        text: 'Plantillas de mensajería',
      },
    ],
    '>'
  );

    const [discardReasonsData, setDiscardReasonsData] = useState();
    const [editDiscardReasonId, setEditDiscardReasonId] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [selectedDiscardReasonIds, setSelectedDiscardReasonIds] = useState([]);
    const [showDiscardModal, handleDiscardModal] = useBoolean(false);

    const columns = [
        {
          title: i18n.t('management__singular_discard_reason',{ns: 'businessUser'}).toUpperCase(),
          key: 'title',
          dataIndex: 'title',
          sorter: (a, b) => {
            if (a.title.toLowerCase() < b.title.toLowerCase())
              return -1
            else if (a.title.toLowerCase() > b.title.toLowerCase())
              return 1
            else
              return 0
          }
        },
        {
          title: i18n.t('management__created_by',{ns: 'businessUser'}),
          key: 'businessName',
          dataIndex: 'business_name',
          sorter: (a, b) => {
            if (a.title.toLowerCase() < b.title.toLowerCase())
              return -1
            else if (a.title.toLowerCase() > b.title.toLowerCase())
              return 1
            else
              return 0
          }
        },
        {
          key: 'operation',
          fixed: 'right',
          width: 100,
          render: (row) => row.business === business.id && (
            <Button.Group className='gw-pl__table__actions'>
              <Tooltip placement='top' title={i18n.t('management__edit_discard_reason_title',{ns: 'businessUser'})}>
                <Button type='link' icon='edit' onClick={() => handleEditDiscardReason(row.id) }/>
              </Tooltip>
              <Popconfirm 
                title={i18n.t('management__delete_discard_reason_popconfirm',{ns: 'businessUser'})}
                placement="bottomRight"
                onConfirm={() => handleDeleteDiscardReason(row.id)} >
                <Button type='link' icon='delete' />
              </Popconfirm>
            </Button.Group>
          ),
        },
      ];

    const rowSelection = { 
      onChange: (selectedRowKeys, selectedRows) => {
        setSelectedDiscardReasonIds(selectedRowKeys);
      },
      getCheckboxProps: record => ({
        disabled: record.business !== business.id,
      }),
    };

    const handleDeleteDiscardReason = async (discardReasonId) => {
      const url = `/api/v1/gamesandtests/business/${business.id}/discardreasons/${discardReasonId}/`;
      try {

        await actions.fetchAndReturn(token, url, 'DELETE', '')
        message.success(i18n.t('management__delete_reason_success',{ns: 'businessUser'}));
        fetchDiscardReasons();          
      } catch (error) {
        message.error(i18n.t('management__delete_reason_error',{ns: 'businessUser'})); 
      }
    };

    const handleDeleteMultipleDiscardReasons = async () => {
      try {
        await Promise.all(selectedDiscardReasonIds.map(async (discardReasonId) => {
          const url = `/api/v1/gamesandtests/business/${business.id}/discardreasons/${discardReasonId}/`;
          await actions.fetchAndReturn(token, url, 'DELETE', '')
          }));
          message.success(i18n.t('management__delete_multiple_reasons_success',{ns: 'businessUser'}));
          fetchDiscardReasons();          
      } catch (error) {
        message.error(i18n.t('management__delete_multiple_reasons_error',{ns: 'businessUser'}));
      }
    };
    
    const handleCreateDiscardReason = () => {
      setEditDiscardReasonId(null);
      handleDiscardModal.on();
    };

    const handleEditDiscardReason = (discardReasonId) => {
      setEditDiscardReasonId(discardReasonId);
      handleDiscardModal.on();
    };

    const fetchDiscardReasons = () => {
        const url = `/api/v1/gamesandtests/business/${business.id}/discardreasons/`;
        actions.fetchAndReturn(
            token,
            url,
            'GET',
            '',
          )
          .then((response) => {
            setDiscardReasonsData(
                response.body.results.map(
                    (discardReason) => ({
                      ...discardReason, key: discardReason.id
                    })
                  )
            )
          }).catch(() => message.error(i18n.t('management__get_discard_reasons_error',{ns: 'businessUser'})))
    };

  useEffect(() => {
    if (discardReasonsData === undefined) fetchDiscardReasons();
  }, []);

  return (
    <Layout className='custom-fields-table-view' style={{ margin: '30px 40px 30px 40px'}}>
      <Row style={{ marginBottom: '1.5em' }}>{Breadcrumb}</Row>
      <Row type='flex' align='middle' style={{ marginBottom: '1em' }}>
        <Col style={{ marginRight: '0.75em' }}>
          <Icon
            onClick={() => dispatch(push('/tools'))}
            type='arrow-left'
          />
        </Col>
        <Col>
          <Title level={2} style={{ color: '#2B2B2B', margin: 0 }}>{i18n.t('management__discard_reason_title', {ns: 'businessUser'})}</Title>
        </Col>
      </Row>
      <Row style={{ marginBottom: '2em' }}>
        <Paragraph
          style={{
            color: '#5E5E5E',
            fontSize: '16px',
            fontWeight: 'normal',
            marginBottom: '0px',
          }}
        >
          {i18n.t('management__discard_reason_description', {ns: 'businessUser'})}
        </Paragraph>
      </Row>
      <Row type='flex' justify='space-around' style={{ marginBottom: '2em' }}>
        <Col span={6}>
          <Input.Search
            disabled={discardReasonsData === undefined}
            onSearch={(value) => setSearchValue(value)}
            placeholder={i18n.t('management__search_discard_reason',{ns: 'businessUser'})}
          />
          {
            selectedDiscardReasonIds.length > 0 && (
              <Button style={{ 'marginTop': 10 }} onClick={handleDeleteMultipleDiscardReasons}>
                <Icon type='delete' />
                {i18n.t('management__delete_multiple_reasons',{ns: 'businessUser'})}
              </Button>
            )
          }
        </Col>
        <Col span={6} />
        <Col span={12} style={{ display: 'flex', justifyContent: 'end' }}>
          <Button type='primary' onClick={handleCreateDiscardReason}>{i18n.t('management__create_discard_reason', {ns: 'businessUser'})}</Button>
        </Col>
      </Row>
      <Row>
        <FormDiscardReasonModal 
          actions={actions} 
          business={business}
          handleVisible={handleDiscardModal} 
          token={token}
          updateDiscardReasons={fetchDiscardReasons} 
          visible={showDiscardModal}
          discardReasonId={editDiscardReasonId}
          discardReasons={discardReasonsData}
        />
        <Card bodyStyle={{ padding: 0 }} style={{ border: '1px solid #D9D9D9' }}>
          {!discardReasonsData ? (
            <Row
              style={{
                background: `url("${assetsUrl}Group+1499custom_fields_background.svg") no-repeat center bottom`,
                backgroundSize: 'contain',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Col
                span={8}
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  height: '40em',
                  justifyContent: 'center',
                }}
              >
                <Row type='flex' justify='center' style={{ width: '30em' }}>
                  <img
                    style={{ width: '50%' }}
                    src={`${assetsUrl}genomines/4.png`}
                  />
                </Row>
                <Row type='flex' justify='center' style={{ width: '30em' }}>
                  <Title level={2} style={{ color: '#454545' }}>
                    {i18n.t('management__empty_create_discard_reasons',{ns: 'businessUser'})}
                  </Title>
                </Row>
                <Row type='flex' justify='center' style={{ width: '30em' }}>
                  <Button
                    type='primary'
                    onClick={handleDiscardModal.toggle}
                  >    
                    {i18n.t('management__create_discard_reason', {ns: 'businessUser'})}
                  </Button>
                </Row>
              </Col>
            </Row>
            ) : (
              <Table
                columns={columns}
                dataSource={discardReasonsData && discardReasonsData.filter(
                  (discardReason) => discardReason.title.toLowerCase().includes(searchValue.toLowerCase())
                )}
                rowSelection={rowSelection}
                style={{ height: '35em', maxHeight: '35em' }}
              />
            )}
        </Card>
      </Row>
    </Layout>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    business: state.auth.business,
  };
};

DiscardReasons.propTypes = {
  actions: PropTypes.shape({
    fetchAndReturn: PropTypes.func.isRequired,
  }),
  dispatch: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscardReasons);
