import React from "react";
import PropTypes from "prop-types";
import ActivityRow from "./ActivityRow.jsx";
import { Col, Divider, Row } from "antd";

const ActivityPath = (props) => {
  const { blocked, businessSlug, colorTheme, activities, level, prvw } = props;
  const tower = "https://genoma-assets.s3.us-east-2.amazonaws.com/Castle.svg";

  const columnsPerRow = (row) => {
    if (row % 2 == 0) {
      return 2;
    } else {
      return 3;
    }
  };

  const activitiesSliced = (activities) => {
    let sliced = [];
    let num_activities = 0;
    if (level == '-1'){
      const row = activities.sort(function(a,b){return a.code - b.code})
      for (let i = 0; num_activities < activities.length; i += 1) {
        sliced.push(
          [row[num_activities]])
        num_activities += 1;
      }
      return sliced
    }
    for (let i = 0; num_activities < activities.length; i += 1) {
      let size = columnsPerRow(i);
      if (size) {
        sliced.push(
          activities.slice(
            num_activities,
            Math.min(num_activities + size, activities.length)
          )
        );
        num_activities += size;
      }
    }

    return sliced;
  };
  const slicedActivities = activitiesSliced(activities);

  return (
    <Row  style={{ width: "90%" }}>
      <Divider style={{paddingTop: 10, paddingBottom: 10}}>
        <Col sm={24}>
          <img className={prvw ? "castle-image-preview" : "castle-image"} src={tower} />
        </Col>
      </Divider>

      <Col sm={24}>
        {slicedActivities.map((row, index) => (
          <ActivityRow
            businessSlug={businessSlug}
            colorTheme={colorTheme}
            prvw={prvw}
            row={row}
            key={index}
            items={props.items}
            blocked={blocked}
          />
        ))}
      </Col>
    </Row>
  );
};

export default ActivityPath;
