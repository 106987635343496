import React from 'react';
import {
  Col,
  Row,
  Input,
  Select,
  Switch,
  Icon,
  Checkbox,
  Typography,
} from 'antd';

const { Text } = Typography;

const ColModalEdit = ({
  questionToEdit,
  setQuestionToEdit,
  setCounter,
  counter,
  showModalFunction,
  setIsModalAddAlternativeVisible,
}) => {
  return (
    <Row gutter={[16, 20]}>
      <Col>
        <Row gutter={[16, 20]}>
          {questionToEdit.question_type === 'file' ? (
            <>
              <Col>
                <Text>Nombre del documento</Text>
              </Col>
              <Col>
                <Input
                  placeholder='Nombre'
                  value={questionToEdit.question}
                  onChange={({ target }) =>
                    setQuestionToEdit({
                      ...questionToEdit,
                      question: target.value,
                    })
                  }
                />
              </Col>
              <Col>
                <Text>Descripción del documento</Text>
              </Col>
              <Col>
                <Input
                  placeholder='Descripción'
                  value={questionToEdit.additionals?.instructions}
                  onChange={({ target }) =>
                    setQuestionToEdit((questionToEdit) => {
                      return {
                        ...questionToEdit,
                        additionals: {
                          ...questionToEdit.additionals,
                          instructions: target.value,
                        },
                      };
                    })
                  }
                />
              </Col>
            </>
          ) : (
            <>
              <Col>
                <Text>¿Cuál es tu pregunta?</Text>
              </Col>
              <Col>
                <Input
                  placeholder='Pregunta'
                  value={questionToEdit.question}
                  onChange={({ target }) =>
                    setQuestionToEdit({
                      ...questionToEdit,
                      question: target.value,
                    })
                  }
                />
              </Col>
            </>
          )}
          <Col>
            <Text>Tipo de respuesta</Text>
          </Col>
          <Col>
            <Select
              value={questionToEdit.question_type}
              style={{ width: '100%' }}
              onChange={(option) => {
                if (option === 'multiselection' || option === 'singleselection')
                  setCounter(2);

                return setQuestionToEdit({
                  ...questionToEdit,
                  question_type: option,
                });
              }}
            >
              <Option value='singleline'>
                <Icon type='align-left' style={{ marginRight: '0.688rem' }} />
                Texto
              </Option>
              <Option value='multiline'>
                <Icon type='align-left' style={{ marginRight: '0.688rem' }} />
                Texto múltiple
              </Option>
              <Option value='int'>
                <Icon type='calculator' style={{ marginRight: '0.688rem' }} />
                Numérico
              </Option>
              <Option value='singleselection'>
                <Icon type='check' style={{ marginRight: '0.688rem' }} />
                Selección única
              </Option>
              <Option value='multiselection'>
                <Icon type='unordered-list' style={{ marginRight: '0.688rem' }} />
                Selección múltiple
              </Option>
              <Option value='file'>
                <Icon type='paper-clip' style={{ marginRight: '0.688rem' }} />
                Documento adjunto
              </Option>
            </Select>
          </Col>
          {questionToEdit.question_type === 'file' && (
            <>
              <Col>
                <Text>Tipos de archivos admitidos</Text>
              </Col>
              <Col>
                <Select
                  mode='multiple'
                  allowClear
                  style={{ width: '100%' }}
                  placeholder='Please select'
                  defaultValue={['PDF (Documento)']}
                  //onChange={(e) => console.log(e)}
                >
                  <Option value='JPG'>JPG (Imagen)</Option>
                  <Option value='JPEG'>JPEG (Imagen)</Option>
                  <Option value='PNG'>PNG (Imagen)</Option>
                  <Option value='TIFF'>TIFF (Imagen)</Option>
                  <Option value='BMP'>BMP (Imagen)</Option>
                  <Option value='PDF (Documento)'>PDF (Documento)</Option>
                  <Option value='DOC'>DOC (Documento)</Option>
                  <Option value='DOCX'>DOCX (Documento)</Option>
                  <Option value='XPS'>XPS (Documento)</Option>
                </Select>
              </Col>
            </>
          )}
        </Row>
      </Col>
      {(questionToEdit.question_type === 'singleselection' || questionToEdit.question_type === 'multiselection')
        ? (
          <Col>
            <Row gutter={[8, 0]}>
              <Col span={13}>
                <Row gutter={[0, 16]}>
                  <Col>
                    <Text>Alternativas</Text>
                  </Col>
                  {[...Array(counter)].map((number, index) => (
                    <Col key={index}>
                      <Input
                        name={'text'}
                        value={
                          questionToEdit.configuration.alternatives[index]?.text
                        }
                        placeholder={`Alternativa ${index + 1}`}
                        onChange={({ target }) =>
                          setQuestionToEdit({
                            ...questionToEdit,
                            ...(questionToEdit.configuration.alternatives[index] =
                              {
                                [target.name]: target.value,
                                correct: false,
                              }),
                          })
                        }
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col span={7}>
                <Row gutter={[0, 24]}>
                  <Col>
                    <Row>
                      <Col>
                        <Text disabled>Alternativa Correcta</Text>
                      </Col>
                    </Row>
                  </Col>
                  {[...Array(counter)].map((number, index) => (
                    <Col key={index}>
                      <Row style={{ display: 'flex', justifyContent: 'center' }}>
                        <Col span={3}>
                          <Checkbox
                            defaultChecked={
                              questionToEdit?.configuration?.alternatives[index]
                                ?.correct
                            }
                            onChange={({ target }) =>
                              setQuestionToEdit((questionToEdit) => {
                                questionToEdit.configuration.alternatives[index] =
                                  {
                                    ...questionToEdit.configuration.alternatives[
                                      index
                                    ],
                                    correct: target.checked,
                                  };
                                return questionToEdit;
                              })
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col span={4}>
                <Row gutter={[0, 24]}>
                  <Col>
                    <Row style={{ display: 'flex', justifyContent: 'center' }}>
                      <Col>
                        <Text disabled>Eliminar</Text>
                      </Col>
                    </Row>
                  </Col>
                  {[...Array(counter)].map((number, index) => (
                    <Col key={index}>
                      <Row style={{ display: 'flex', justifyContent: 'center' }}>
                        <Col
                          span={3}
                          onClick={() => {
                            if (counter > 2) {
                              setQuestionToEdit((questionToEdit) => {
                                let arr =
                                  questionToEdit.configuration.alternatives.filter(
                                    (alternative, i) => i != index
                                  );
                                questionToEdit.configuration.alternatives = arr;
                                return questionToEdit;
                              });
                              setCounter((counter) => counter - 1);
                            }
                          }}
                          style={{
                            cursor: counter > 2 ? 'pointer' : 'not-allowed',
                          }}
                        >
                          <Icon
                            type='delete'
                            style={{ color: '#A4A4A4', fontSize: '18px' }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
            <Col>
              <Row gutter={[24, 0]}>
                <Col
                  span={1}
                  onClick={() => {
                    setQuestionToEdit({
                      ...questionToEdit,
                      ...(questionToEdit.configuration.alternatives[counter + 1] =
                        {
                          text: undefined,
                          correct: false,
                        }),
                    });
                    return setCounter((counter) => counter + 1);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <Icon
                    type='plus-circle'
                    style={{ color: '#F175A5', fontSize: '18px' }}
                  />
                </Col>
                <Col
                  span={8}
                  onClick={() => {
                    setQuestionToEdit({
                      ...questionToEdit,
                      ...(questionToEdit.configuration.alternatives[counter] = {
                        text: undefined,
                        correct: false,
                      }),
                    });
                    return setCounter((counter) => counter + 1);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <Text style={{ color: '#F175A5' }}>Agregar alternativa</Text>
                </Col>
              </Row>
            </Col>
            {/* <Col>
              <Row gutter={[24, 0]}>
                <Col
                  span={1}
                  onClick={() =>
                    showModalFunction(setIsModalAddAlternativeVisible, true)
                  }
                >
                  <Icon
                    type='copy'
                    style={{ color: '#8C8C8C', fontSize: '18px' }}
                  />
                </Col>
                <Col
                  span={12}
                  onClick={() =>
                    showModalFunction(setIsModalAddAlternativeVisible, true)
                  }
                >
                  <Text style={{ color: '#8C8C8C' }}>
                    Copiar lista de alternativas
                  </Text>
                </Col>
              </Row>
            </Col> */}
          </Col>
        ) : null}
      {questionToEdit.question_type === 'int' && (
        <Col>
          <Row gutter={[32, 0]}>
            <Col span={12}>
              <Row gutter={[0, 8]}>
                <Col>
                  <Text>Desde</Text>
                </Col>
                <Col>
                  <Input
                    placeholder='0'
                    value={questionToEdit.number_from}
                    onChange={({ target }) =>
                      setQuestionToEdit({
                        ...questionToEdit,
                        number_from: target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={[0, 8]}>
                <Col>
                  <Text>Hasta</Text>
                </Col>
                <Col>
                  <Input
                    placeholder='0'
                    value={questionToEdit.number_to}
                    onChange={({ target }) =>
                      setQuestionToEdit({
                        ...questionToEdit,
                        number_to: target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      )}
      {/* <Col>
        <Row>
          <Col span={11}>
            <Text disabled>Requerida para todos mis procesos</Text>
          </Col>
          <Col span={2}>
            <Switch defaultChecked />
          </Col>
        </Row>
      </Col> */}
    </Row>
  );
};

export default ColModalEdit;
