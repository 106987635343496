import React from 'react';
import { Typography } from 'antd';
import '../JobListing.scss';

const CompanyDescription = (props) => {

  const { Title, Text } = Typography;
  const sections = props.business_info.sections ? props.business_info.sections : props.business_info

  const checkAllEmpty = () => {
    return sections.find(obj=> obj.content !== '' && obj.title !== '')
  }
  return (
    checkAllEmpty() ?
    <div className='description-company'>
      {
        sections.map((elem, index) => (
            <div className='description-company__about' key={index}>
              {
                elem.title !== '' ? <Title level={2}>{elem.title}</Title> : null
              }
              <div className='description-company__welcome' style={{ whiteSpace: 'pre-line', textAlign: 'justify' }}>
                {
                  elem.content !== '' ? <Text>{elem.content}</Text> : null
                }
              </div>
            </div>
        ))
      }
    </div> :
    null
  )
}

export default CompanyDescription;
