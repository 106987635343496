import React, { useMemo } from 'react';

import { Row, Typography } from 'antd';
import { ResponsiveLine } from '@nivo/line';

const { Text } = Typography;

const dateToMonth = {
  '1': 'ENE',
  '2': 'FEB',
  '3': 'MAR',
  '4': 'ABR',
  '5': 'MAY',
  '6': 'JUN',
  '7': 'JUL',
  '8': 'AGO',
  '9': 'SEP',
  '10': 'OCT',
  '11': 'NOV',
  '12': 'DEC',
}

const Timeline = ({ data, topic }) => {
  

  const timeline = useMemo(() => {
    if (data) {
      const processedData = data.map((item) => {
        const [month, year] = item.label.split('-')
        if (topic === 'candidate_timeline') {
          return {
            x: `${dateToMonth[month]} ${year}`,
            y: item.value,
          }
        }
        return {
          x: item.label,
          y: item.value,
        }
      })
      return [{ id: 'candidates', color: 'hsl(57, 70%, 50%)', data: processedData }]
    }
  }, [data])
  
  const axisLeft = () => {
    switch (topic) {
      case 'candidate_timeline':
        return {
          legend: 'Nuevos candidatos',
          legendOffset: -50,
        }
      default:
        return {}
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <Row className="new-analytics__content__stages" style={{ padding: 0 }}>
        <div style={{ margin: 0, border: 'none', height: '350px', widht: '100%' }}>
          {
            timeline && timeline[0].data.length ?
              (
                <ResponsiveLine
                  data={timeline[0].data.length ? timeline : [] }
                  lineWidth={2}
                  enableArea={true}
                  areaBaselineValue={0}
                  areaOpacity={0.4}
                  margin={{ top: 20, right: 55, bottom: 60, left: 55 }}
                  xScale={{ type: 'point' }}
                  yScale={{
                    type: 'linear',
                    min: 0,
                    max: 'auto',
                    stacked: false,
                    reverse: false,
                  }}
                  yFormat=" >-.2f"
                  curve="linear"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    orient: 'bottom',
                    tickSize: 10,
                    tickPadding: 10,
                    tickRotation: 35,
                  }}
                  axisLeft={{
                    orient: 'left',
                    tickSize: 11,
                    tickPadding: 10,
                    tickRotation: 0,
                    legendOffset: -45,
                    legendPosition: 'middle',
                    legend: topic === 'average_time_per_status' ? 'Días' : '',
                    // Without deceimals
                    format: e => Math.floor(e) === e && e,
                    ...axisLeft()
                  }}
                  colors={['#69C0FF', '#ffe3ee', '#E6F7FF']}
                  enableGridX={false}
                  enablePoints={true}
                  pointSize={10}
                  pointColor={{ theme: 'background' }}
                  pointBorderWidth={2}
                  pointBorderColor={{ from: 'serieColor', modifiers: [] }}
                  pointLabelYOffset={-12}
                  useMesh={true}
                  legends={[]}
                  tooltip={value => {
                    return (
                      <div className="gw-line-chart-tooltip">
                        <span style={{ color: '#40A9FF' }}>{value.point.data.y} {topic === 'average_time_per_status' && 'días'}</span>
                      </div>
                    );
                  }}
                  theme={{
                    fontSize: 10,
                    grid: {
                      line: {
                        stroke: '#f2f4f5',
                        strokeWidth: 1.5,
                      },
                    },
                  }}
                />
              ) :
              (
                <Row type='flex' justify='center' align='middle' style={{ height: '350px' }}>
                  <Text strong>Sin información</Text>
                </Row>
              )
          }
        </div>
      </Row>
    </div>
  );
};

export default Timeline;
