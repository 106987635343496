import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import * as actionCreators from '../../actions/auth';

import {
    Button,
    Card,
    Typography,
    Row,
    Col,
    Modal
} from 'antd';

function ClearStorageView(props) {
    const [isStorageEmpty, setIsStorageEmpty] = useState(false);

    const { Text, Paragraph } = Typography;

    useEffect(() =>{
        if (isStorageEmpty){
            props.dispatch(push(''));
        }
    },[isStorageEmpty]) 

    const clearStorage = () => {
        localStorage.clear();
        setIsStorageEmpty(true);
    }

    const confirmModal = () => {
        Modal.confirm({
            title: 'Limpiar sesión',
            content: `Confirma para limpiar tu sesión en Genomawork`,
            okText: 'Confirmar',
            okType: 'danger',
            cancelText: 'Cancelar',
            onOk: () => clearStorage(),
            onCancel: () => { }
        })
    }

    return (
        <Row type="flex" justify='center' align='middle' style={{marginTop:150}}>
            <Col sm={20} md={12}>
                    <Card title="Limpiar sesión" bordered={false}>
                        <Row type="flex" justify='center' align='middle'>
                            <img style={{maxWidth:'90%', margin:20}} src="https://genoma-assets.s3.us-east-2.amazonaws.com/invite-collaborators.png"/>
                            <Paragraph>
                                Presiona limpiar sesión para eliminar los datos de sesión que se encuentran en el almacenamiento local.
                                Esta acción cerrará la sesión activa solo dentro de este navegador.
                            </Paragraph>
                            <Col>
                                <Button type='primary'  onClick={confirmModal}>Limpiar sesión</Button>
                            </Col>
                        </Row>
                    </Card>
            </Col>
        </Row >
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        actions: bindActionCreators(actionCreators, dispatch)
    };
};

export default connect(null, mapDispatchToProps)(ClearStorageView);