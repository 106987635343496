import React, { useContext } from 'react';
import {
  Row,
  Spin,
  Col,
  Icon,
  Dropdown,
  Menu,
  Tooltip,
  Typography,
} from 'antd';

import DragAndDropTab from '../DragAndDropTab';
import {
  GenominHeadSvg,
  MountainSvg,
  NoMailTemplates,
} from '../components/svgContainer';
import TableTemplates from './TableTemplates';
import MailTemplatesContext from '../context/mailTemplates';

const { Item } = Menu;
const { Text } = Typography;

const TableContainer = () => {
  const {
    filterByCategory,
    isActive,
    activeCol,
    categories,
    tableTemplates,
    categoryExtra,
  } = useContext(MailTemplatesContext);

  return (
    <Row
      className='template-container__table-container'
      style={{
        '--myHeight':
          !isActive?.id || !tableTemplates.emailTemplates.length
            ? '39.5rem'
            : '4.063rem',
      }}
    >
      <Spin tip='Cargando categorias' spinning={categories.loading}>
        <Row type='flex'>
          <Col span={23} className='template-container__table-container--tags'>
            <DragAndDropTab />
          </Col>
          <Col
            span={1}
            id='plus'
            className='template-container__table-container--plus-container'
          >
            <Dropdown
              placement='bottomRight'
              overlay={
                <Menu>
                  {!categories.data.slice(4).length ? (
                    <Item>No hay categorías</Item>
                  ) : (
                    categories.data.slice(4)?.map((item) => (
                      <Item key={item.id}>
                        <div
                          id={item.id}
                          onClick={(e) => {
                            activeCol(e);
                            categoryExtra(e);
                            filterByCategory(item.id);
                          }}
                        >
                          {item.name}
                        </div>
                      </Item>
                    ))
                  )}
                </Menu>
              }
              trigger={['click']}
              overlayClassName='template-container__dropdown-menu'
            >
              <Tooltip placement='top' title='Todas las categorías'>
                <Row
                  type='flex'
                  justify='center'
                  align='middle'
                  className='template-container__table-container--all-cat'
                >
                  <div onClick={(e) => e.preventDefault()}>
                    <Icon
                      type='ellipsis'
                      style={{ fontSize: '20px', cursor: 'pointer' }}
                    />
                  </div>
                </Row>
              </Tooltip>
            </Dropdown>
          </Col>
        </Row>
        {!isActive?.id ? (
          <Row className='template-container__table-container--svg-container'>
            <Row type='flex' align='middle' justify='center'>
              <GenominHeadSvg />
            </Row>
            <Row>
              <MountainSvg />
            </Row>
          </Row>
        ) : (!tableTemplates.emailTemplates.length ? (
          <Row className='template-container__table-container--svg-container'>
            <Row type='flex' align='middle' justify='center'>
              <NoMailTemplates />
            </Row>
            <Row type='flex' align='middle' justify='center'>
              <Text
                strong
                style={{
                  color: '#F175A5',
                  marginTop: '18px',
                }}
              >
                Puede dirigirse al botón crear plantilla en la esquina superior
                derecha.
              </Text>
            </Row>
          </Row>
        ) : (
          // TODO: Arreglar que tabla sea 2 pixeles más chica de ancho :S
          <TableTemplates />
        ))}
      </Spin>
    </Row>
  );
};

export default TableContainer;
