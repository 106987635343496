import React, { useEffect, useContext } from 'react'

import {
  Checkbox, Divider, Col, Row, Typography
} from 'antd';
import JobAppContext from '../../context/JobAppContext';


const { Text } = Typography;

const requiredInfoObj = [
  { key: 'name', value: 'Nombres' },
  { key: 'surname', value: 'Apellidos' },
  { key: 'email', value: 'Email' },
  { key: 'dni', value: 'DNI' },
];

const optionalInfoObj = [
  { key: 'nationality', value: 'Nacionalidad' },
  { key: 'education_level', value: 'Nivel de educación' },
  { key: 'careers', value: 'Antecedentes académicos' },
  { key: 'phone', value: 'Teléfono' },
  { key: 'country', value: 'País de residencia' },
  { key: 'experiences', value: 'Experiencia laboral' },
  { key: 'birth_date', value: 'Fecha de nacimiento' },
  { key: 'commune', value: 'Comuna' },
  { key: 'cv', value: 'Curriculum vitae' },
  { key: 'gender', value: 'Género' },
  // { key: 'marital_status', value: 'Estado civil' },
  //{ key: 'has_disability', value: 'Discapacidad' },
];

function PostulationFormCheckbox() {
  const { updateCandidateFormData, candidateForm:{candidateFormData} } = useContext(JobAppContext);

  const allChecked = () => Object.values(candidateFormData).every(elem => elem == false);
  const indeterminate = () => Object.values(candidateFormData).some(elem => elem == false);

  const updateCheckAll = () => {
    const isAllChecked = allChecked();
    if (isAllChecked) {
      Object.keys(candidateFormData).forEach((key, index) => {
        candidateFormData[key] = true;
      });
      updateCandidateFormData({...candidateFormData});
    }
    else {
      Object.keys(candidateFormData).forEach((key, index) => {
        candidateFormData[key] = false;
      });
      updateCandidateFormData({...candidateFormData});
    }
  };

  const mapFields = (data, required) => {
    return (
      data.map((obj, idx) =>
        <Col xs={12} sm={8} key={idx}>
          <Row type='flex' align='space-between'>
            <Text>{obj.value}</Text>
            <Checkbox
              disabled={required}
              checked={required ? required : !candidateFormData[obj.key]}
              onChange={() => updateCandidateFormData({ ...candidateFormData, [obj.key]: !candidateFormData[obj.key] })}
              style={{ marginLeft: 15 }}
            />
          </Row>
        </Col>
      )
    );
  }

  return (
    <>
      <Row type='flex' justify='space-between'>
        <Text strong>Datos Obligatorios</Text>
        <Text>Datos: <strong>4</strong></Text>
      </Row>
      <Divider />
      <Row type='flex' gutter={[30, 10]}>
        {mapFields(requiredInfoObj, true)}
      </Row>

      <Row type='flex' align='space-between' style={{ marginTop: 30 }}>
        <Text strong>Datos Adicionales</Text>
        <div>
          <Text>Seleccionar todo</Text> <Checkbox indeterminate={indeterminate()} checked={allChecked()} onChange={()=>updateCheckAll()} />
          <Text style={{ marginLeft: 10 }}>Datos: <strong>12</strong></Text>
        </div>

      </Row>
      <Divider />
      <Row type='flex' gutter={[30, 10]}>
        {mapFields(optionalInfoObj, false)}
      </Row>
    </>
  );
};

export default PostulationFormCheckbox;