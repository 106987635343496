import React from 'react';
import { Icon } from 'antd';
const baseUrl = 'https://genoma-assets.s3.us-east-2.amazonaws.com'
const GenominHeadSvg = () => {
  return (
    <img
      src={`${baseUrl}/genomin-head.svg`}
    />
  );
};

const MountainSvg = () => {
  return (
    <img
      src={`${baseUrl}/mail-template-mountain.svg`}
    />
  );
};

const SvgIcon = () => {
  return (
    <img
      src={`${baseUrl}/mail-template-icon.svg`}
    />
  );
};

const AllCatIcon = () => {
  return (
    <img
      src={`${baseUrl}/all-categories.svg`}
    />
  );
};

const NoMailTemplates = () => {
  return (
    <img
      src={`${baseUrl}/no-mail-template.svg`}
    /> 
  );
};

const NoTags = () => {
  return (
    <img
      src={`${baseUrl}/mail-template-no-tags.svg`}
    />
  );
};

const PointsIcon = (props) => <Icon component={SvgIcon} {...props} />;

export { GenominHeadSvg, MountainSvg, PointsIcon, AllCatIcon, NoMailTemplates, NoTags };
