// Referencia: https://geekrodion.medium.com/react-and-linkedin-authorization-47b2395ba785

/**
 * getURLWithQueryParams receives the base part of an URL, and an object with parameters. 
 * It will return a new URL, made by merging base part and parameters converted to a query
 * string
 * 
 * @param {*} base 
 * @param {*} params 
 * @returns {string} Query URL
 */
export const getURLWithQueryParams = (base, params) => {
  const query = Object
    .entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&')
  return `${base}?${query}`
}

/**
 * queryToObject converts the query part of an URL into an object
 * 
 * @param {*} queryString 
 * @returns {Object}
 */
export const queryToObject = queryString => {
  const pairsString = queryString[0] === '?' ? queryString.slice(1) : queryString
  const pairs = pairsString
    .split('&')
    .map(str => str.split('=').map(decodeURIComponent))
  return pairs.reduce((acc, [key, value]) => key ? { ...acc, [key]: value } : acc, {})
}