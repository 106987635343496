import React from "react";
import {
  Button,
  Typography,
} from 'antd';

const LastPage = ({ buttonColor, backToPath, name }) => {
  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        <img src="https://genoma-assets.s3.us-east-2.amazonaws.com/finish-cute.png" alt="" />
        <Typography.Title level={3}>
          ¡{name}, has finalizado! 
        </Typography.Title>
      </div>

      <div style={{ textAlign: 'center', margin: 30 }}>
        <Typography.Text>
          Prueba completada 🚀
        </Typography.Text>
      </div>

      <div style={{ textAlign: 'center', marginBottom: 50 }}>
        <Typography.Text>
          Tus respuestas han sido enviadas.
        </Typography.Text>
      </div>

      <div>
        <Button className={'cute-button-primary'} style={{ borderColor: buttonColor, backgroundColor: buttonColor }} onClick={backToPath}>
          Volver al tablero de actividades
        </Button>
      </div>
    </div>
  );
};

export default LastPage;