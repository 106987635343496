import React, { useContext } from 'react';
import {
  Table,
  Tooltip,
  Icon,
  Typography,
  Tag,
  Row,
  Col,
  Dropdown,
  Menu,
} from 'antd';
import MailTemplatesContext from '../context/mailTemplates';

const { Column } = Table;
const { Text } = Typography;
const { Item } = Menu;

const TableTemplates = () => {
  const { getSingleTemplate, tableTemplates } = useContext(MailTemplatesContext);

  const {
    emailTemplates,
    loading,
    categories,
    openModalDeleteConfirmation,
    businessUserId,
    getSharedTemplate,
  } = tableTemplates;

  return (
    <Table
      dataSource={emailTemplates}
      loading={loading}
      rowKey={(data) => data.id}
      locale={{ emptyText: 'No se encontraron resultados' }}
    >
      <Column
        title={<Text strong>NOMBRE DE LA PLANTILLA</Text>}
        dataIndex='name'
        key='name'
        width='25%'
        render={(name) => (
          <Tooltip title={name}>
            <Text>{name.length > 40 ? `${name.slice(0, 40)}...` : name}</Text>
          </Tooltip>
        )}
      />

      <Column
        title={<Text strong>ASUNTO</Text>}
        dataIndex='subject'
        key='subject'
        width='15%'
      />

      <Column
        align='center'
        title={<Text strong>ETIQUETAS</Text>}
        dataIndex='tags'
        key='tags'
        width='15%'
        render={(tags) => {
          return tags?.map((tagName, index) => (
            <Tag key={index}> {tagName}</Tag>
          ));
        }}
      />
      <Column
        align='center'
        title={<Text strong>CREADO POR</Text>}
        dataIndex='creator'
        key='creator'
        width='15%'
        render={({ name }) => (
          <>
            <Text>{name}</Text>
          </>
        )}
      />
      <Column
        align='center'
        title=''
        key='id'
        width='10%'
        render={(template) => {
          const isCreator = template.creator.id === businessUserId;

          return (
            <Col /* span={20} */>
              <Row type='flex' justify='space-between' align='middle'>
                {isCreator ? (
                  <Tooltip
                    placement='top'
                    title='Editar'
                    onClick={() => getSingleTemplate(template, { edit: true })}
                  >
                    <Icon type='edit' style={{ fontSize: '18px' }} />
                  </Tooltip>
                ) : (
                  <Tooltip
                    placement='top'
                    title='Previsualizar'
                    onClick={() =>
                      getSingleTemplate(template, { preview: true })
                    }
                  >
                    <Icon type='eye' style={{ fontSize: '18px' }} />
                  </Tooltip>
                )}
                <Dropdown
                  placement='bottomRight'
                  overlay={
                    <Menu>
                      {categories.map(({ name, id }) => (
                        <Item
                          key={id}
                          onClick={() => getSingleTemplate(template, { duplicate: true }, id)}
                        >
                          En {name}
                        </Item>
                      ))}
                    </Menu>
                  }
                  trigger={['click']}
                  overlayClassName='template-container__dropdown-menu'
                >
                  <div onClick={(e) => e.preventDefault()}>
                    <Tooltip placement='top' title='Copiar plantilla'>
                      <Icon
                        type='copy'
                        style={{ fontSize: '18px', cursor: 'pointer' }}
                      />
                    </Tooltip>
                  </div>
                </Dropdown>
                <Tooltip
                  placement='top'
                  title='Compartir template'
                  onClick={() => getSharedTemplate(template)}
                >
                  <Icon type='usergroup-add' style={{ fontSize: '18px' }} />
                </Tooltip>
                <Tooltip
                  placement='top'
                  title='Eliminar template'
                  onClick={() => openModalDeleteConfirmation(template)}
                >
                  <Icon type='delete' style={{ fontSize: '18px' }} />
                </Tooltip>
              </Row>
            </Col>
          );
        }}
      />
    </Table>
  );
};

export default TableTemplates;
