import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import AddOrRemove from '../StepContent/4-idealGenome/components/AddOrRemove';
import { colors, texts } from '../constants';
import { 
  Typography, 
  Card, 
  Button, 
  List, 
  Avatar, 
  Affix,
  Icon,
  Modal,
  Tag,
  Badge,
  Select } from 'antd';
import './JobState.scss';

const { Text, Title } = Typography;
const { Option } = Select;

class JobState extends Component {

  updatedStatus = false;

  state = {
    status: '',
  }

  componentDidUpdate() {
    if (this.props.status && !this.updatedStatus){
      this.setState({ status: this.props.status})
      this.updatedStatus = true
    }
  }

  showModal = () => {
    this.setState({ visibleModal: !this.state.visibleModal })
  }

  handleStatusChange = status => event => {
    this.setState({ [status]: event })
    this.props.handleChangeJobPosting('status')(event);
  }

  render() {
    const { size, time, name } = this.props;
    return (
      <Affix offsetTop={90} className='js-affix'>
        <Card className='v2'>
          <Text strong>{name}</Text>
          
          <div className='summary'>
            <div className='summary__bag'>
              <Title level={4}>{size}</Title>
              <Text>Actividades</Text>
            </div>
            
            <div className='summary__bag'>
              <Title level={4}>{time}</Title>
              <Text>Minutos</Text>
            </div>
          </div>
          
          <label>Estado</label>
          <Select 
            defaultValue="OTHER" 
            className='status' 
            value={this.state.status} 
            onChange={this.handleStatusChange('status')}
          >
            <Option value="ACTIV"><Badge color='#87d068' text='Abierto' className='stat' /></Option>
            <Option value="INACT"><Badge color='#ff4543' text='Cerrado' className='stat' /></Option>
            <Option value="OTHER"><Badge color='#848484' text='Borrador' className='stat' /></Option>
          </Select>
      
          {/*<Button block onClick={this.showModal}>Actividades seleccionadas <Icon type="arrow-right" /></Button>*/}
          <Button style={{whiteSpace:'normal'}} block loading={this.props.isSaving} onClick={()=>this.props.save(false)}>Guardar y continuar editando</Button>
          <Button type='primary' block loading={this.props.isSaving} onClick={this.props.publish}>Guardar proceso</Button>
        </Card>
      </Affix>
    )
  }
}

const mapStateToProps = (state) => {
  const normalActivities = Object.keys(state.jobapp.assignedActivities).filter(key => key !== 'CUTE').map(elem => state.jobapp.assignedActivities[elem]);

  const normalTime = normalActivities.reduce((init, next) => { return init + next.time_estimated; }, 0);

  return {
    size: Object.keys(state.jobapp.assignedActivities).length,
    time: normalTime,
    isSaving: state.jobapp.isSaving,
    status: state.jobapp.jobApplication.status,
  };
};

export default connect(mapStateToProps)(JobState);