import React, { useContext } from 'react';
import { Switch, Typography } from 'antd';

import { CandidateContext } from '../config/CandidateContext';

import MessagesView from '../../../components/messaging/MessagesView';
import i18n from '../../../i18n'

const { Text } = Typography;

const MessageTab = (props) => {
  const {
    application: { id, chat },
    candidate,
    logoURL,
    translationFile,
    updateChat,
    updateNotificationsCount,
  } = useContext(CandidateContext);

  // TODOMSG Pasar chat id desde la req guardada en el context para poder bloquear el chat
  return (
    <div className="new-modal-content__messages">
      <div className="new-modal-content__messages__header">
        <Text>{i18n.t('profile__enable_messages', translationFile).split(' [')[0]} {candidate.user_name}</Text>
        <Switch
          checked={chat ? !chat.is_disabled : false}
          onChange={(value) =>
            updateChat({ is_disabled: !value, user_job_app: id })
          }
        />
      </div>
      <MessagesView
        userType={'business'}
        logoBusiness={logoURL}
        userJobApp={id}
        toUser={candidate.id}
        toUserName={candidate.name}
        chat={chat}
        callback={updateNotificationsCount}
      />
    </div>
  );
};

export default MessageTab;
