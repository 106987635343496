import React from 'react'
import {
  Row,
  Typography
} from 'antd';
import i18n from '../../../i18n';

const { Title } = Typography;

const HeaderBackground = () => {
  return (
    <Row type="flex" align="middle" justify="center" className='header-background'>
      <Title>
        {i18n.t('job_portal__title')}
      </Title>
    </Row>
  );
}

export default HeaderBackground;