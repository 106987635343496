import React from 'react'
import { Tabs, Typography } from 'antd'
import CandidatePathContainer from './PreviewComponents/CandidatePathContainer.jsx';
import StartProcessContainer from './PreviewComponents/StartProcessContainer'

const { TabPane } = Tabs;

function JobAppPreview() {
  return (
    <div className='jobapp-preview'>
      <div className='jobapp-preview-section'>
        <Tabs
          animated={{
            inkBar: true,
            tabPane: false
          }}
          type='card'
          tabBarGutter={0}
          tabBarStyle={{
            marginBottom: 0,
            borderColor: 'transparent'
          }}
        >
          <TabPane tab="Recorrido del candidato" key="1" >
            <div className='jobapp-info-container' style={{ borderRadius: '0px 16px 16px 16px' }}>
              <CandidatePathContainer />
            </div>
          </TabPane>
          <TabPane tab="Vista inicial del proceso" key="2">
            <div className='jobapp-info-container' style={{ borderRadius: '0px 16px 16px 16px' }}>
              <StartProcessContainer />
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}

export default JobAppPreview;