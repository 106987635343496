import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Sentry from '@sentry/browser';

import { Select, Spin } from 'antd';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';

import useBoolean from '../../hooks/useBoolean';
import i18n from '../../i18n';
import * as actionCreators from '../../actions/data';

const { Option } = Select;

function DebouncedSelect(props) {
  const [isLoadingOptions, setIsLoadingOptions] = useBoolean(false);
  const [options, setOptions] = useState();

  useEffect(() => {
    if (options === undefined) {
      setOptions(() => (
        props.type === 'default'
          ? (
            props.value
              ? [{ key: props.value, [i18n.language.split('-')[0]]: props.value, value: props.value}]
              : [])
          : (
            props.value
              ? props.value.map((value) => ({ key: value, [i18n.language.split('-')[0]]: value, value}))
              : [])
      ))
    }
  }, [props.type]);

  const newOption = (searchInput) => ({
    key: "new-instance",
    value: searchInput,
    [i18n.language.split('-')[0]]: `${i18n.t('commons__create')}: "${searchInput}" +`,
  });

  const searchAPI = (value) => {
    setIsLoadingOptions.on();
    props.actions.fetchAndReturn(props.token, `${props.searchApiUrl}&search_input=${value}`, 'GET')
      .then((response) => {
        if (response?.status < 400 && response?.body) setOptions(
          value?.length > 0 ? [newOption(value), ...response.body] : [...response.body]
        );
      })
      .catch((error) => Sentry.captureException(error))
      .finally(() => setIsLoadingOptions.off());
  };

  return (
    <Select
      allowClear={props.type !== 'multiple'}
      autoClearSearchValue={props.type === 'multiple'}
      filterOption={false}
      notFoundContent={isLoadingOptions ? <Spin size="small" /> : null}
      onSearch={debounce(filter => searchAPI(filter), 500)}
      onSelect={(value, option) => {
        if (option.key === 'new-instance') {
          setOptions((prevOptions) => (
            [
              ...prevOptions.filter(opt => opt.key !== 'new-instance'),
              {
                key: value,
                [i18n.language.split('-')[0]]: value,
                value,
              },
            ]
          ));
        }
        if (props.fieldName !== undefined) {
          props.setValue({
            [props.fieldName]: (props.type === 'multiple') ? (
              props.value?.includes(value)
                ? props.value.filter((tag) => tag !== value) : [...props.value, value]
            ) : value,
          });
        }
      }}
      onDeselect={
        props.type !== 'default'
          ? ((value) => {
            if (props.fieldName !== undefined) {
              props.setValue({
                [props.fieldName]: props.value.filter((tag) => tag !== value),
              });
              setOptions((prevOptions) => prevOptions.filter(opt => opt.value !== value));
            }
          })
          : (() => {})
      }
      placeholder={props.placeholder}
      showSearch
      style={{ width: '100%' }}
      mode={props.type}
      value={props.value}
    >
      {options?.filter(
        opt => props.type === 'default' || !props.value.includes(opt.value)
      ).map(option => (
        <Option
          key={option.key || option.name}
          value={
            option.value ||
            option[i18n.language.split('-')[0]] ||
            option.translation?.[i18n.language.split('-')[0]] ||
            option.name
          }
        >
          {
            option[i18n.language.split('-')[0]] ||
            option.translation?.[i18n.language.split('-')[0]] ||
            option.name
          }
        </Option>
      ))}
    </Select>
  );
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actionCreators, dispatch),
  dispatch,
});

DebouncedSelect.propTypes = {
  fieldName: PropTypes.string,
  placeholder: PropTypes.string,
  searchApiUrl: PropTypes.string.isRequired,
  setValue: PropTypes.func,
  type: PropTypes.oneOf(['default', 'multiple', 'tags']),
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
  // Redux
  actions: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

DebouncedSelect.defaultProps = {
  fieldName: undefined,
  placeholder: undefined,
  setValue: undefined,
  type: 'default',
  value: undefined,
};

export default connect(mapStateToProps, mapDispatchToProps)(DebouncedSelect);
