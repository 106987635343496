import React from 'react';
import {
  Row,
  Col,
  Input,
  InputNumber,
  Typography,
  Icon,
  Switch,
  DatePicker,
  Upload,
  Select,
  Tooltip,
  Button,
  Modal,
} from 'antd';

import i18n from '../../../i18n';

import localeES from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';

const { Text, Title } = Typography;

const CustomFieldEdition = ({
  customFieldObj,
  setNewCustomFieldValue,
  newCustomFieldValue,
  fileList,
  beforeUpload,
  updateCustomField,
  fieldType,
  setCustomFieldKeyInEditionMode,
  customFieldKey
}) => {
  return (
    <Row align="middle" type="flex" justify="space-between">
      <Col span={20}>
        {(customFieldObj === null || customFieldObj.value_type === 'text') && (
          <>
            <Input
              onChange={(event) => setNewCustomFieldValue(event.target.value)}
              value={newCustomFieldValue}
            />
            {newCustomFieldValue?.length > 250 && (
              <>
                <br />
                <span style={{ color: 'red' }}>
                  {i18n.t('profile__maximum_characteres', {
                    ns: 'candidateProfile',
                  })}
                </span>
              </>
            )}
          </>
        )}
        {customFieldObj && customFieldObj.value_type === 'number' && (
          <>
            <InputNumber
              onChange={(value) => setNewCustomFieldValue(value)}
              value={newCustomFieldValue}
            />
            {newCustomFieldValue &&
              typeof newCustomFieldValue !== 'number' &&
              newCustomFieldValue !== '-' &&
              newCustomFieldValue !== '' && (
                <>
                  <br />
                  <span style={{ color: 'red' }}>
                    {i18n.t('profile__must_be_number', {
                      ns: 'candidateProfile',
                    })}
                  </span>
                </>
              )}
          </>
        )}
        {customFieldObj && customFieldObj.value_type === 'boolean' && (
          <Switch
            checked={newCustomFieldValue}
            checkedChildren={<Icon type="check" />}
            onChange={(value) => setNewCustomFieldValue(value)}
            unCheckedChildren={<Icon type="close" />}
          />
        )}
        {customFieldObj && customFieldObj.value_type === 'date' && (
          <DatePicker
            allowClear
            format="DD-MM-YYYY"
            locale={localeES}
            onChange={(value) =>
              setNewCustomFieldValue(moment(value).format('DD-MM-YYYY'))
            }
            value={
              newCustomFieldValue
                ? moment(newCustomFieldValue, 'DD-MM-YYYY')
                : null
            }
          />
        )}
        {customFieldObj && customFieldObj.value_type.includes('selection') && (
          <Select
            allowClear
            mode={
              customFieldObj.value_type.includes('multi')
                ? 'multiple'
                : 'default'
            }
            onChange={(value) =>
              customFieldObj.value_type === 'multiselection' &&
              setNewCustomFieldValue(value)
            }
            onSelect={(value) =>
              customFieldObj.value_type === 'singleselection' &&
              setNewCustomFieldValue(value)
            }
            style={{ minWidth: '100%' }}
            value={newCustomFieldValue || undefined}
          >
            {customFieldObj.options.map((value) => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
          </Select>
        )}
        {customFieldObj && customFieldObj.value_type === 'file' && (
          <Upload
            accept=".doc,.docx,.pdf,application/pdf,application/msword"
            beforeUpload={beforeUpload}
            fileList={fileList}
            transitionName=""
          >
            <Tooltip
              title={`${i18n.t('profile__file_type_restriction', {
                ns: 'candidateProfile',
              })}. ${i18n.t('profile__file_mb_restriction', {
                ns: 'candidateProfile',
              })}.`}
            >
              <Button icon="upload" style={{ marginTop: '0.25rem' }}>
                {newCustomFieldValue
                  ? i18n.t('profile__change', {
                      ns: 'candidateProfile',
                    })
                  : i18n.t('profile__upload', {
                      ns: 'candidateProfile',
                    })}
              </Button>
            </Tooltip>
          </Upload>
        )}
      </Col>
      <Col span={3}>
        <Tooltip
          title={i18n.t('profile__save', {
            ns: 'candidateProfile',
          })}
        >
          <Icon
            onClick={() => {
              updateCustomField(
                customFieldKey,
                newCustomFieldValue,
                fieldType,
                false,
                customFieldObj?.value_type
              );
              setNewCustomFieldValue();
              setCustomFieldKeyInEditionMode();
            }}
            type="save"
            style={{ cursor: 'pointer', marginRight: '5px' }}
          />
        </Tooltip>
        <Tooltip
          title={i18n.t('profile__back', {
            ns: 'candidateProfile',
          })}
        >
          <Icon
            onClick={() =>
              Modal.confirm({
                cancelText: i18n.t('profile__cancel', {
                  ns: 'candidateProfile',
                }),
                closable: false,
                content: i18n.t('profile__changes_will_discarded', {
                  ns: 'candidateProfile',
                }),
                okText: i18n.t('profile__go_back', {
                  ns: 'candidateProfile',
                }),
                onOk: () => {
                  setNewCustomFieldValue();
                  setCustomFieldKeyInEditionMode();
                },
                title: i18n.t('profile__discard_changes', {
                  ns: 'candidateProfile',
                }),
              })
            }
            type="rollback"
            style={{ cursor: 'pointer' }}
          />
        </Tooltip>
      </Col>
    </Row>
  );
};

export default CustomFieldEdition;