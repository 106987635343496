import React from 'react';
import { Row, Col, Typography, Icon, Tabs } from 'antd';
import i18n from '../../../i18n';

const { Text, Title } = Typography;

const KOs = ({ KOs }) => {
  const getAnswer = (obj) => {
    if (obj.type === 'singleselection') {
      return obj.answer ? obj.alternatives[obj.answer]?.text : '';
    }
    if (obj.type === 'multiselection') {
      const selected = Object.keys(obj.answer ?? {})
        .map((idx) => obj.alternatives[parseInt(idx)].text)
        .join(', ');
      return selected;
    } else {
      return obj.answer;
    }
  };

  return (
    <div className="report-pdf-kos">
      <p>
        <strong>{i18n.t('candidate_pdf__additional_questions', { ns: 'candidatePdf' }).toUpperCase()}</strong>
      </p>
      {KOs.length ? (
        KOs.map((obj, idx) => (
          <div key={idx}>
            <p className="t12">
              <strong>
                {idx + 1}. {obj.question}
              </strong>
            </p>
            <p className="t12" style={{ marginTop: '0.5rem' }}>
              {getAnswer(obj)}
            </p>
          </div>
        ))
      ) : (
        <p className="t12">{i18n.t('candidate_pdf__additional_info', { ns: 'candidatePdf' })}.</p>
      )}
    </div>
  );
};

export default KOs;
