import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import queryString from 'query-string'

import * as authActionCreators from '../../actions/auth';
import * as dataActionCreators from "../../actions/data"

import './impersonate-styles.scss';
import homeBackground from '../Home/images/genoma_fondo2.png'
import {
  Form,
  Button,
  Card,
  Typography,
  Row,
  Col,
  Modal,
  Spin,
  message
} from 'antd';

const Impersonate = (props) => {

  const { Text } = Typography;
  const [impersonateInfo, setImpersonateInfo] = useState({
    token: '',
    email: ''
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const values = queryString.parse(props.location.search);
    const fetchData = async () => {
      const endpoint = `/api/v1/accounts/otlogin/?genomatoken=${values.genomatoken}`;
      props.dataActions.fetchAndReturn(props.token, endpoint, 'GET')
        .then(response => {
          if (response.status >= 400) {
            message.error('Credenciales incorrectas');
            setIsLoading(false);
            setError(true);
            return null;
          }
          else {
            return response.body;
          }
        }).then(data => {
          setImpersonateInfo({
            token: data.token,
            email: values.email
          })
          props.authActions.authCheckAuthentication(data.token);
          setIsLoading(false);
        })
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (
      props.match.path === '/tokenauth' &&
      props.token && props.userType === 'personal'
    ) {
      handleClick()
    }
  }, [props.token])

  useEffect(() => {
    if (props.authErrors && Object.keys(props.authErrors).length > 0) {
      Modal.error({
        title: 'Error de autentificación',
        content: 'El token utilizado no es válido o no coincide con el usuario. Será redirigido a la página de inicio.',
        onOk: handleCloseModal,
        onCancel: handleCloseModal
      })
    }
  }, [props.authErrors])

  const handleCloseModal = (close) => {
    props.dispatch(push('/'));
    close();
  }

  const handleClick = (e) => {
    const qs = queryString.parse(props.location.search)
    if (qs.next) {
      props.dispatch(push(qs.next))
    } else {
      props.dispatch(push('/'))
    }
  };

  const logout = () => {
    props.authActions.authLogout(impersonateInfo.token);
  }

  return (
    <Spin spinning={isLoading}>
      <div style={{ backgroundImage: `url(${homeBackground})`, width: "100vw", height: "calc(100vh - 64px)", backgroundSize: "cover" }}>
        <div className="impersonate">
          <Row type="flex" justify="center" align="middle" style={{ height: "85vh", }}>
            <Col>
              <Card style={{ width: 400 }}
                title={
                  <>
                    <Row type="flex" justify="center" align="middle">
                      {
                        !error ?
                          <Text strong>Estás entrando como:</Text>
                          :
                          <Text strong>Error al iniciar sesión</Text>
                      }

                    </Row>
                    <Row type="flex" justify="center" align="middle">
                      <Text strong>{impersonateInfo.email}</Text>
                    </Row>
                  </>
                }>

                <Form className="impersonate__form">
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="button"
                      className="impersonate-form-button"
                      block
                      size="large"
                      onClick={handleClick}
                    >
                      Continuar
                    </Button>
                  </Form.Item>
                </Form>
                {!error && <Typography.Text>¿No eres {impersonateInfo.email}? </Typography.Text>}
                <Button onClick={logout} type="link">Volver al inicio</Button>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </Spin>
  )
}



const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isAuthenticating: state.auth.isAuthenticating,
    authErrors: state.auth.errors,
    token: state.auth.token,
    userType: state.auth.userType
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    authActions: bindActionCreators(authActionCreators, dispatch),
    dataActions: bindActionCreators(dataActionCreators, dispatch)
  };
};

const ImpersonateView = Form.create()(Impersonate)
export default connect(mapStateToProps, mapDispatchToProps)(ImpersonateView);