const nonReasoningStep = [
  '76A100',
  'APRFDB',
  'ACRTTL',
  'ACRSWT',
  'ADBLSP',
  'TMPRES',
  'TMRESW',
  'EMOTOT',
  'EMOCOR',
  'MMTRCE',
  'MULTIV',
  'DRCATC',
  'DCSINC',
  'CAPPLN',
  'CAPATC',
  'CTRIMP',
  'EXTPLN',
];

export const baseMeasuredTrait = {
  genome_id: null,
  trait_id: null,
  // Intervalo para los traits distribution === RANG, [20,40,60,80,100], otras distribuciones 0
  upper_score: 0,
  // Target score para todas las distribusiones excepto RANG, en ese caso es el "lower score"
  score: 0,
  relevance: 0,
  points: 0,
  standard_deviation: null,
  distribution: 'NORM',
  trait: {}
};

const getMeasuredTraitDistribution = (trait) => {
  const dist = trait.category === 'RAZONA' || nonReasoningStep.includes(trait.code) ? 'STEP' : trait.category === 'PERSON' ? 'RANG' : 'NORM';
  return dist;
};

export const buildMeasuredTrait = (trait, genomeId) => {
  const newMeasuredTrait = { ...baseMeasuredTrait };
  newMeasuredTrait['genome_id'] = genomeId;
  newMeasuredTrait['distribution'] = getMeasuredTraitDistribution(trait);
  newMeasuredTrait['trait'] = { ...trait };
  // Desde el back viene este campo (trait_id), por el momento no es necesario, se mantiene por consistencia
  newMeasuredTrait['trait_id'] = trait.id;
  return newMeasuredTrait;
};

export const getMTraitsToUpdate = (oldMTraits, newMTraits, genomeId) => {
  // Measured traits a patchear
  // Los juegos crean sus measured traits  con valores default, es necesario patchear al menos la distribucion
  // para los traits antiguos. Si cambiaron los points o la relevancia tambien se patchea
  let toUpdate = newMTraits.filter(newMT => {
    const isNewIdx = oldMTraits.findIndex(oldMT => newMT.trait.id == oldMT.trait.id);
    if (isNewIdx == -1) {
      return true;
    }
    // Si ya existia
    else{
      const tmpOldMT = oldMTraits[isNewIdx];
      if (newMT.points != tmpOldMT.points || newMT.relevance != tmpOldMT.relevance || newMT.score != tmpOldMT.score || newMT.upper_score != tmpOldMT.upper_score){
        return true;
      }
      return false;
    }
  });

  toUpdate = toUpdate.map(measuredTrait => {
    return (
      {
        genome_id: measuredTrait.genome_id ? measuredTrait.genome_id : genomeId,
        // Este campo es para el bulk update
        trait_id: measuredTrait.trait.id,
        distribution: measuredTrait.distribution,
        upper_score: measuredTrait.upper_score,
        score: measuredTrait.score,
        relevance: measuredTrait.relevance,
        points: measuredTrait.points,
      }
    )
  });
  return toUpdate;
};


export const getMTraitsToDelete = (oldMTraits, newMTraits) => {
  // Measured traits a borrar
  let toDelete = oldMTraits.filter(oldMT => {
    const isNewIdx = newMTraits.findIndex(newMT => oldMT.trait.id == newMT.trait.id);
    // Si old trait id no esta en new trait, se debe borrar
    if (isNewIdx == -1) {
      return true;
    }
    else{
      return false;
    }
  });

  toDelete = toDelete.map(measuredTrait => {
    return (
      {
        // Este campo es para el bulk update
        trait_id: measuredTrait.trait.id,
      }
    )
  });
  return toDelete;
};
