import React, { useState, useEffect, useContext } from 'react'

import {
  Button, Icon, Modal, Col, Row, Input, Typography
} from 'antd';

import EvaluationCard from '../EvaluationCard';
import JobAppContext from '../../../context/JobAppContext';
import CustomTestCard from './CustomTestCard';

import '../EvaluationsStyles.scss'

const LOGO_URL_GENOMA = 'https://genoma-assets.s3.us-east-2.amazonaws.com/cute-icon-2.svg';
const LOGO_URL_CUTE = 'https://genoma-assets.s3.us-east-2.amazonaws.com/cute-icon-3.svg';

function CustomTests({ type }) {
  const { business, cuTes, currentStage, updateCuTes } = useContext(JobAppContext);
  const [isVisible, setIsVisible] = useState(false);
  const [searchInput, setSearchInput] = useState(undefined);
  const [availableCuTes, setAvailableCuTes] = useState([]);
  const [currentEvaluations, setcurrentEvaluations] = useState([]);
  const [filteredCuTes, setFilteredCuTes] = useState([]);
  const [modalType, setModalType] = useState(type);

  const modalData = {
    'knowledge': {
      title: 'Configurar prueba de conocimiento',
      description: 'Agrega una prueba para evaluar los conocimientos de los candidatos.',
      logo: LOGO_URL_GENOMA,

    },
    'custom': {
      title: 'Configurar prueba personalizada',
      description: 'Agrega una prueba para evaluar los conocimientos de los candidatos.',
      logo: LOGO_URL_CUTE,
    },
    'situational': {
      title: 'Configurar prueba de juicio situacional',
      description: 'Agrega una prueba para evaluar las decisiones de los candidatos.',
      logo: LOGO_URL_GENOMA,
    }
  }
  // Clean state after modal is closed
  useEffect(() => {
    if (isVisible == false) {
      setSearchInput(undefined);
      setAvailableCuTes([]);
      setcurrentEvaluations([]);
      setFilteredCuTes([]);
    }
  }, [isVisible]);

  useEffect(() => {
    if (isVisible == false) return;

    // Deberiamos tener un atributo para diferenciar cuales son pruebas personalizadas y cuales son test de conocimeinto
    let updatedCuTes;
    let newCurrentEvaluations;
    if (type === 'knowledge') {
      updatedCuTes = cuTes?.allEvaluations.filter(obj => obj.cute_type === 'KNOWLEDGE');
      newCurrentEvaluations = [...cuTes.currentEvaluations].filter(obj => obj.cute_type === 'KNOWLEDGE');
    } else {
      updatedCuTes = cuTes?.allEvaluations.filter(obj => obj.cute_type === 'CUSTOM');
      newCurrentEvaluations = [...cuTes.currentEvaluations].filter(obj => obj.created_by === business.id && obj.cute_type === 'CUSTOM');
    }
    updatedCuTes.sort((a, b) => a.title.localeCompare(b.title));

    // Remove previously added cutes
    const currentEvaluationsIds = cuTes.currentEvaluations.map(obj => obj.id);
    updatedCuTes = updatedCuTes.filter(obj => !currentEvaluationsIds.includes(obj.id));
    setAvailableCuTes(updatedCuTes);

    // Add currentEvaluations
    setcurrentEvaluations(newCurrentEvaluations.filter(obj => obj.stage === currentStage));
  }, [cuTes, type, currentStage, isVisible]);

  useEffect(() => {
    if (searchInput == undefined) return;
    const filtered = availableCuTes.filter(obj => obj.title.toLowerCase().includes(searchInput.toLowerCase()));
    setFilteredCuTes(filtered);
  }, [searchInput]);

  const addTest = (cute) => {
    let idx = availableCuTes.findIndex(obj => obj.id === cute.id);

    // Add cute with stage
    let tmpCute = { ...availableCuTes[idx] };
    tmpCute.stage = currentStage;
    setcurrentEvaluations(oldState => [...oldState, tmpCute]);

    // Update available cutes
    availableCuTes.splice(idx, 1);
    setAvailableCuTes(availableCuTes);

    // Also updated the filtered data
    idx = filteredCuTes.findIndex(obj => obj.id === cute.id);
    filteredCuTes.splice(idx, 1);
    setFilteredCuTes(filteredCuTes);

  };

  const removeTest = (cute) => {
    const idx = currentEvaluations.findIndex(obj => obj.id === cute.id)
    let updateAvailableCuTes = [...availableCuTes, currentEvaluations[idx]];
    currentEvaluations.splice(idx, 1);
    setcurrentEvaluations(currentEvaluations);

    updateAvailableCuTes.sort((a, b) => a.title.localeCompare(b.title));
    setAvailableCuTes([...updateAvailableCuTes]);
  };

  // Aca se comparan los current cutes locales con los del contexto y se agregan o remueven en los objestos correspondientes
  const onSave = () => {
    const toAdd = [...cuTes.toAdd];
    const toRemove = [...cuTes.toRemove];
    const toUpdate = [...cuTes.toUpdate];

    const localEvaluations = [...currentEvaluations]

    // Por cada elemento del modal ver si existe en el contexto, si no esta entonces hay que agregarlo
    localEvaluations.forEach(current => {
      const found = cuTes.currentEvaluations.some(obj => {
        return obj.id === current.id && current.stage === currentStage
      });
      !found && toAdd.push(current);
    });

    // Por cada elemento del contexto ver si esta en el modal, si no esta entonces removerlo si solo si es un elemento en la etapa actual
    cuTes.currentEvaluations.forEach(current => {
      if (current.stage !== currentStage) return;

      const addIndex = toAdd.findIndex(obj => current.id === obj.id);
      const found = localEvaluations.some(obj => {
        return obj.id === current.id
      });

      if (!found && addIndex == -1) {
        // No esta en toAdd tampoco en locales, hay que mandar una DELETE request
        // current is an object from cuTes.currentEvaluations it can be type knowledge or custom
        if (type.toLowerCase() === current.cute_type.toLowerCase()) {
          toRemove.push(current);
        };
      };
      // Si lo removemos tambien debemos sacarlo de las cutes a updatear
      if (found) {
        const toUpdateIdx = toUpdate.findIndex(obj => obj.id === current.id)
        toUpdateIdx != -1 && toUpdate.splice(toUpdateIdx, 1);
      }
      //}
    });

    // Check if all elements in toAdd are added in localEvaluations
    toAdd.forEach((current, idx) => {
      if (current.stage !== currentStage) return;
      if ((localEvaluations.findIndex(obj => current.id === obj.id) == -1) && (type.toLowerCase() === current.cute_type.toLowerCase())) {
        toAdd.splice(idx, 1);
      }
    });

    // Check if we remove og cute and add again, remove repeated elements in toAdd and to Remove
    toRemove.forEach((current, idx) => {
      const addIndex = toAdd.findIndex(obj => current.id === obj.id);
      if (addIndex != -1) {
        toRemove.splice(idx, 1);
        toAdd.splice(addIndex, 1);
      }
    });

    //const ids = new Set(cuTes.currentEvaluations.map(obj => obj.id));
    const updateCurrentEvals = [
      // Dont touch the currentEvaluations that are not in the current stage
      ...cuTes.currentEvaluations.filter(obj => obj.stage !== currentStage),
      /**
       * Same stage
       */
      // Other cutes same stage but different type eg: knowledge, custom or situational
      ...cuTes.currentEvaluations.filter(obj => (obj.cute_type.toLowerCase() !== type.toLowerCase()) && obj.stage === currentStage),
      // local evals by construction have the current stage cutes with the current type (cutes visible in this modal NOT state.cuTes.currentEvaluations)
      ...localEvaluations]

    updateCuTes('toAdd', toAdd);
    updateCuTes('toRemove', toRemove);
    updateCuTes('toUpdate', toUpdate);
    updateCuTes('currentEvaluations', updateCurrentEvals);

    setIsVisible(false);
  };

  const getCurrentCuTes = (cuteType) => {
    let newCurrentEvaluations = [];
    if (cuteType === 'knowledge') {
      newCurrentEvaluations = [...cuTes.currentEvaluations].filter(obj => obj.cute_type === 'KNOWLEDGE' && obj.questions?.cute_type !== 'SITUATIONAL');
    }
    if (cuteType === 'situational') {
      newCurrentEvaluations = [...cuTes.currentEvaluations].filter(obj => obj.cute_type === 'KNOWLEDGE' && obj.questions?.cute_type === 'SITUATIONAL');
    }
    if (cuteType === 'custom') {
      newCurrentEvaluations = [...cuTes.currentEvaluations].filter(obj => obj.created_by === business.id && obj.cute_type === 'CUSTOM');
    }
    newCurrentEvaluations = newCurrentEvaluations.filter(obj => obj.stage === currentStage)
    return newCurrentEvaluations;
  }

  const cuTesDetail = (cuteType) => {
    const newCurrentEvaluations = getCurrentCuTes(cuteType)
    return (
      <div style={{ margin: '5px 0px 10px 5px' }}>
        <div style={{ marginBottom: 4 }}>
          <strong>
            {'Pruebas seleccionadas:'}
          </strong>
        </div>
        {newCurrentEvaluations.map((obj, idx) => <div key={idx}>{`• ${obj.title}`}</div>)}
      </div>
    );
  };

  const displayCute = (cute) => {
    if (modalType === 'situational') {
      return cute.questions?.cute_type === 'SITUATIONAL'
    }
    if (modalType === 'knowledge') {
      return cute.questions?.cute_type !== 'SITUATIONAL' && cute.cute_type === modalType.toUpperCase();
    }
    return cute.cute_type === modalType.toUpperCase();
  }

  return (
    <>
      <EvaluationCard
        logo={type === 'knowledge' ? LOGO_URL_GENOMA : LOGO_URL_CUTE}
        evaluationTypeImg={['https://genoma-assets.s3.us-east-2.amazonaws.com/puzzle.svg']}
        title={type === 'knowledge' ? 'Pruebas de conocimiento' : 'Evaluaciones personalizadas'}
        description={
          type === 'knowledge' ?
            'Mide el nivel de conocimiento que tienen los candidatos sobre un tema específico.' :
            'Mide el nivel de conocimiento sobre un tema específico que hayas creado para evaluar a tus candidatos.'
        }
        viewMoreContent={() => cuTesDetail(type)}
        additionalInformation={[
          { value: 'Todo tipo de cargos', icon: 'user' },
          { value: 'ES, EN, PT', icon: 'flag' },
        ]}
        buttons={() => [
          <Button key={'add'} className='evaluation-add-button' onClick={() => { setIsVisible(true); setModalType(type) }}>
            {
              getCurrentCuTes(type).length > 0 ?
                <>
                  <Icon type='check' />
                  <span>Editar</span>
                </>
                :
                <>
                  <Icon type='plus' />
                  <span>Agregar</span>
                </>
            }
          </Button>
        ]}
      />
      {type === 'knowledge' &&  
        <EvaluationCard
          logo={LOGO_URL_GENOMA}
          evaluationTypeImg={['https://genoma-assets.s3.us-east-2.amazonaws.com/puzzle.svg']}
          title="Pruebas de juicio situacional"
          description="Mide una habilidad a través de las decisiones de los candidatos al enfrentar situaciones realistas."
          viewMoreContent={() => cuTesDetail('situational')}
          additionalInformation={[
            { value: 'Todo tipo de cargos', icon: 'user' },
            { value: 'ES, EN, PT', icon: 'flag' },
          ]}
          buttons={() => [
            <Button key={'add'} className='evaluation-add-button' onClick={() => { setIsVisible(true); setModalType('situational') }}>
              {
                getCurrentCuTes('situational').length > 0 ?
                  <>
                    <Icon type='check' />
                    <span>Editar</span>
                  </>
                  :
                  <>
                    <Icon type='plus' />
                    <span>Agregar</span>
                  </>
              }
            </Button>
          ]}
        />
      }
      <Modal
        title={<div style={{ fontSize: 18, fontWeight: 700 }}>{modalData[modalType].title}</div>}
        bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 300px)' }}
        visible={isVisible}
        maskClosable={false}
        onOk={() => setIsVisible(false)}
        onCancel={() => setIsVisible(false)}
        cancelText={'Cancelar'}
        width={1000}
        forceRender={true}
        destroyOnClose={true}
        footer={[
          <Button key='cancel' onClick={() => setIsVisible(false)}>Cancelar</Button>,
          <Button key='ok' type='primary' onClick={onSave}>Guardar</Button>,
        ]}
        className='evaluation-modal'
      >
        <div className='evaluation-modal-title'>
          Selección de pruebas
        </div>
        <div className='evaluation-modal-section'>
          {modalData[modalType].description}
        </div>

        <div className='evaluation-modal-sub-title'>
          Pruebas seleccionadas
        </div>

        {currentEvaluations.filter(obj => obj.stage === currentStage).length == 0 ?
          <div className='evaluations-flex-div'>
            <Row type='flex' justify='center'>
              <img
                src={modalData[modalType].logo}
                alt='cute-logo'
                style={{ height: 40 }}
              />
            </Row>
            <Row type='flex' justify='center'>
              <Typography.Text strong style={{ alignText: 'center' }}>
                Aún no has seleccionado ninguna prueba.
              </Typography.Text>
            </Row>
            {
              business.addOns.find(obj => obj.add_on === 'CUTE') && type !== 'knowledge' &&
              <>
                <Row type='flex' justify='center'>
                  <Typography.Text>
                    Busca las pruebas disponibles debajo de esta sección o crea una nueva en el siguiente botón.
                  </Typography.Text>
                </Row>
                <Row type='flex' justify='center'>
                  <Button className='evaluation-card-button-add' onClick={() => window.open('/customtest', '_blank', 'noreferrer')}>Crear prueba</Button>
                </Row>
              </>
            }
          </div>
          :
          <Row type='flex' justify='start' gutter={[20, 20]}>
            {
              currentEvaluations.filter(obj => obj.stage === currentStage).map((cute, idx) =>
                <Col xs={24} sm={24} lg={12} key={idx} style={{ display: displayCute(cute) ? 'block' : 'none' }}>
                  <CustomTestCard
                    key={idx}
                    cute={cute}
                    type='remove'
                    removeTest={() => removeTest(cute)}
                    cuteType={modalType}
                  />
                </Col>
              )
            }
          </Row>
        }

        <div className='evaluation-modal-sub-title'>
          Pruebas disponibles
        </div>
        <Row type='flex' justify='start' gutter={[20, 20]}>
          <Col xs={24} sm={12}>
            <Input
              className='evaluation-modal-section'
              placeholder='Buscar por nombre temática o área'
              suffix={<Icon type='search' />}
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }
              }
            />
          </Col>
        </Row>
        <Row type='flex' justify='start' gutter={[20, 20]}>
          {
            (searchInput !== undefined ? filteredCuTes : availableCuTes).map((cute, idx) =>
              <Col xs={24} sm={24} lg={12} key={idx} style={{ display: displayCute(cute) ? 'block' : 'none' }}>
                <CustomTestCard
                  key={idx}
                  cute={cute}
                  addTest={() => addTest(cute)}
                  type='add'
                  cuteType={modalType}
                />
              </Col>
            )}
        </Row>
      </Modal>
    </>
  );
};

export default CustomTests;