import React, { useContext } from 'react';
import { AnalyticsContext } from '../../../context/AnalyticsContext';

import { Col, Row, Typography } from 'antd';

const { Text } = Typography;

const Header = () => {
  const {
    headerRef,
    peopleAnalytics: { header },
    user,
    jobAppId,
    overview,
    candidates,
  } = useContext(AnalyticsContext);

  return (
    <div ref={headerRef}>
      <Row className="new-analytics__content__header">
        <Col xs={24} md={14}>
          <img src={user.imgUser} />
          <Row type="flex" justify="space-around">
            <Text strong>Buenos días, team {user.businessOwner} 🌤️</Text>
            <Text>Bienvenido/a a tu nuevo dashboard de People Analytics.</Text>
          </Row>
        </Col>
        {!jobAppId ? (
          <Col xs={24} md={10}>
            {[
              ['ACTIV', 'abiertos'],
              ['INACT', 'cerrados'],
              ['OTHER', 'en borrador'],
            ].map(([key, text]) => (
              <Col xs={8} key={key}>
                <Text>Procesos {text}</Text>
                <Text>{header.overview?.[key]}</Text>
              </Col>
            ))}
          </Col>
        ) : (
          <div></div>
        )}
      </Row>
      {overview || candidates ? (
        <Row style={{ marginTop: '35px' }}>
          <Text strong style={{ color: '#454545' }}>
            {overview && 'Resumen - Todos los procesos'}
            {candidates && 'Candidatos'}
          </Text>
        </Row>
      ) : null}
    </div>
  );
};

export default Header;
