import React, { useState, useEffect, useMemo } from 'react';

const initialFilters = {
  job_application_status: ['OTHER', 'ACTIV'],
};

export const ListContext = React.createContext();

export const ListProvider = ({ children, ...props }) => {
  const [processes, setProcesses] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getProcesses = async () => {
      setLoading(true);
      const processes = await props.actions.fetchData(
        props.token,
        'gamesandtests/personalapplications/',
        '',
        'get'
      );

      setProcesses(processes.results);
      setLoading(false);
    };
    getProcesses();
  }, []);

  return (
    <ListContext.Provider
      value={{ processes, loading, ...props }}
    >
      {children}
    </ListContext.Provider>
  );
};
