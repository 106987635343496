import React from 'react';
import { Modal, Typography, Row, Tag, Col } from 'antd';

import ParseHtml from '../../../../../components/parseHtml';

const { Text } = Typography;

const CustomModal = ({ content, affair, showModal, setShowModal, description }) => {
  return (
    <Modal
      title={affair}
      footer={null}
      onCancel={() => setShowModal(false)}
      visible={showModal}
      centered
    >
      {
        Array.isArray(content) && affair === 'Habilidades' ?
          (
            <Row type='flex' justify='start' gutter={10}>
              {content.map((c, i) => (
                <Col key={i}>
                  <Tag style={{ border: '1px solid #597EF7', background: 'white' }}>
                    {c}
                  </Tag>
                </Col>
              ))}
            </Row>
          ) : <Text>{ ParseHtml(description) }</Text>
      }
    </Modal>
  );
}

export default CustomModal;