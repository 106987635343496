import React, { useState } from 'react'
import { useSlate, useSelected, useFocused } from 'slate-react'
import { Editor, Range, Transforms, Element as SlateElement } from 'slate'
import { 
  Alert,
  Avatar,
  Badge,
  Button,
  Checkbox,
  Icon,
  Input,
  Modal,
  Popover,
  Tooltip,
  Typography,
  Upload
} from 'antd'
import { TemplateButton } from './components'
import i18n from '../../../../../i18n'
import './RichTextEditor.scss'

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const BlockButton = ({ format, icon }) => {

  const LIST_TYPES = ['numbered-list', 'bulleted-list']

  const editor = useSlate()

  const toggleBlock = (editor) => {
    const isActive = isBlockActive(editor)
    const isList = LIST_TYPES.includes(format)
    Transforms.unwrapNodes(editor, {
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        LIST_TYPES.includes(n.type),
      split: true,
    })
    
    const newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    }
    
    Transforms.setNodes(editor, newProperties)
  
    if (!isActive && isList) {
      const block = { type: format, children: [] }
      Transforms.wrapNodes(editor, block)
    }
  }

  const isBlockActive = (editor, blockType = 'type') => {
    const { selection } = editor
    if (!selection) return false
  
    const [match] = Array.from(
      Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: n =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n[blockType] === format,
      })
    )
  
    return !!match
  }

  const buttonTitle = {
    'numbered-list': i18n.t('games_numbers', { ns: 'candidateProfile' }),
    'bulleted-list': i18n.t('commons__bullet_points', { ns: 'candidateProfile' }),
  }

  return (
    <TemplateButton 
      tooltipTitle={buttonTitle[format]}
      iconType={icon}
      iconStyle={{ color: isBlockActive(editor) ? "#F175A5" : '#828282' }}
      onMouseDown={event => {
        event.preventDefault()
        toggleBlock(editor)
      }}
    />
  )
}
  
export const MarkButton = ({ format, icon }) => {
  const editor = useSlate()

  const toggleMark = (editor) => {
    const isActive = isMarkActive(editor)
  
    if (isActive) {
      Editor.removeMark(editor, format)
    } else {
      Editor.addMark(editor, format, true)
    }
  }

  const isMarkActive = (editor) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
  }

  const buttonTitle = {
    bold: 'Negrita',
    italic: 'Cursiva',
    underline: 'Subrayado'
  }

  return (
    <TemplateButton 
      tooltipTitle={buttonTitle[format]}
      iconType={icon}
      iconStyle={{ color: isMarkActive(editor) ? "#F175A5" : '#828282' }}
      onMouseDown={event => {
        event.preventDefault()
        toggleMark(editor)
      }}
    />
  )
}


export const ShareButton = ({ 
  isPublic, 
  options, 
  selectedItems, 
  setIsPublic, 
  setSelectedItems,
}) => {

  const PrivacyOptions = () => {
    const { Search } = Input
    const { Text } = Typography
    
   const [filteredOptions, setFilteredOptions] = useState(options)

    const handleSearch = (value) => {
      const filtered = options.filter(({ label }) => label.toLowerCase().includes(value.toLowerCase()))
      setFilteredOptions(filtered)
    }

    return (
      <div>
        <Search 
          style={{ marginBottom: 10 }} 
          placeholder={i18n.t('profile__search_user', { ns: 'candidateProfile' })} 
          onChange={(e) => handleSearch(e.target.value)} 
        />
          <Text type="secondary" style={{ fontStyle: 'italic' }}>
            {i18n.t('profile__visibility_disclaimer', { ns: 'candidateProfile' })}
          </Text>
          <Checkbox 
            style={{ marginLeft: 9, marginBottom: 5 }} 
            checked={isPublic} 
            disabled={!isPublic && selectedItems.length !== 0}
            onChange={() => setIsPublic(!isPublic)}
          >
            <Icon type="global" style={{ margin: 5, fontSize: 20 }}/>
            {i18n.t('profile__visible_for_everyone', { ns: 'candidateProfile' })}
          </Checkbox>
          {filteredOptions.map((option) => 
            <Checkbox
              key={option.value}
              style={{ padding: "5px 0"}}
              disabled={isPublic}
              checked={selectedItems.includes(option.value)}
              onChange={(e) => e.target.checked ? 
                setSelectedItems([...selectedItems, option.value]) : 
                setSelectedItems(selectedItems.filter(item => item !== option.value))
              }
            >
              <Avatar 
                size='small' 
                style={{ 
                  border: 'solid 0.5px #D9D9D9',
                  backgroundColor: '#F5F5F5',
                  color: '#454545',
                  padding: 1,
                  marginRight: 10 
                }}
              >
                {option.initials}
              </Avatar>
              {option.label}
            </Checkbox>
          )}
      </div>
    )
  }

  return (
    <Tooltip title="Privacidad">
      <Popover
        overlayClassName='rteditor__share-button'
        content={<PrivacyOptions options={options} />} 
        title="Nota visible para:" 
        trigger="click"
        style={{ height: 250, overflow: 'overlay' }}
      >
        {!isPublic ?
          <Badge count={selectedItems.length} showZero className='rteditor__badge'>
            <Button type='link'>      
              <Icon type="user" style={{ color: '#828282'}}/>
            </Button>
          </Badge> :
          <Button type='link'>      
            <Icon type="global" style={{ color: '#828282'}}/>
          </Button>
        }
      </Popover>
    </Tooltip>
  )
}

export const LinkButton = () => {
  const editor = useSlate()

  const [openModal, setOpenModal] = useState(false)
  const [url, setUrl] = useState('')
  const [text, setText] = useState('')

  const isLinkActive = editor => {
    const [link] = Editor.nodes(editor, {
      match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    })
    return !!link
  }

  const insertLink = () => {
    if (editor.selection) {
      wrapLink()
      setUrl('')
      setText('')
      setOpenModal(false)
    }
  }

  const unwrapLink = editor => {
    Transforms.unwrapNodes(editor, {
      match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    })
  }  

  const wrapLink = () => {
    if (isLinkActive(editor)) {
      unwrapLink(editor)
    }
    const { selection } = editor
    const isCollapsed = selection && Range.isCollapsed(selection)
    const link = {
      type: 'link',
      url,
      children: text.length > 0 ? [{ text }] : [{ text: url }],
    }
    if (isCollapsed) {
      Transforms.insertNodes(editor, link)
    } else {
      Transforms.wrapNodes(editor, link, { split: true })
      Transforms.collapse(editor, { edge: 'end' })
    }
    const pointAfterLink = Editor.after(
      editor, 
      selection.focus.path, 
      { distance: text.length > 0 ? text.length + 2 : url.length + 2 }
    )
    Transforms.select(editor, pointAfterLink)
  }
  return (
    <>
      <TemplateButton 
        tooltipTitle={i18n.t('interview__link')} 
        iconType='link' 
        onMouseDown={event => {
          event.preventDefault()
          const { selection } = editor
          if (!selection || !Range.isCollapsed(selection)) {
            const selectedText = Editor.string(editor, selection);
            setText(selectedText)
          }
          setOpenModal(true)
        }}
      />
      <Modal 
        title="Añadir enlace" 
        visible={openModal} 
        closable={false}
        footer={[
          <Button key="submit" type="primary" onClick={() => {
            if (!url) return
            insertLink()
          }}>
            Aceptar
          </Button>,
          <Button key="back" onClick={() => setOpenModal(false)}>
            Cancelar
          </Button>
        ]}
        style={{ padding: 0 }}
      >
        <Input value={text} style={{ marginBottom: 20 }} placeholder='Texto' onChange={(e) => setText(e.target.value)} />
        <Input value={url} style={{ marginBottom: 20 }} placeholder='Enlace' onChange={(e) => setUrl(e.target.value)}/>
        <Alert type="info" message={i18n.t('profile__not_responsible_for_content', { ns: 'candidateProfile' })} />
      </Modal>
    </>
  )
}

const InlineChromiumBugfix = () => (
  <span contentEditable={false}>
    {String.fromCodePoint(160) /* Non-breaking space */}
  </span>
)

const LinkComponent = ({ attributes, children, element }) => {
  const selected = useSelected()
  return (
    <a
      {...attributes}
      href={element.url}
      style={selected ? { boxShadow: '0 0 0 3px #ddd' } : {}}
    >
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </a>
  )
}

const Mention = ({ attributes, children, element }) => {
  const selected = useSelected()
  const focused = useFocused()
  const style = {
    padding: '3px 3px 2px',
    margin: '0 1px',
    verticalAlign: 'baseline',
    display: 'inline-block',
    borderRadius: '4px',
    backgroundColor: '#eee',
    fontSize: '0.9em',
    color: '#40A9FF',
    boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
  }

  if (element.children[0].bold) {
    style.fontWeight = 'bold'
  }
  if (element.children[0].italic) {
    style.fontStyle = 'italic'
  }
  return (
    <span
      {...attributes}
      contentEditable={false}
      data-cy={`mention-${element.character.replace(' ', '-')}`}
      style={style}
    >
      @{element.character}
      {children}
    </span>
  )
}

export const MentionButton = () => {
  const editor = useSlate()
  const insertAtSymbol = () => {
    const { selection } = editor;

    if (selection) {
      Transforms.insertText(editor, '@', { at: selection.anchor });
    }
  };

  return (
    <TemplateButton 
      tooltipTitle={'Menciones'}
      character="@"
      onMouseDown={event => {
        event.preventDefault()
        insertAtSymbol()
      }}
    />
  )
}

export const UploadButton = ({ attachedFiles, setAttachedFiles }) => {

  const handleAttachFile = (info) => {
    const file = info;
    let fileList = [...info.fileList];

    fileList = fileList.slice(-1);

    setAttachedFiles(fileList);
  };

  return (
    <Upload
      accept=".pdf,.doc,.docx,.ppt,.pptx,.xlx,.xlsx"
      name="attachments"
      beforeUpload={() => false}
      onChange={handleAttachFile}
      fileList={attachedFiles}
      className='rteditor__upload-button'
    >
      <TemplateButton 
        tooltipTitle={i18n.t('profile__add_files', { ns: 'candidateProfile' })} 
        iconType='paper-clip' 
      />
    </Upload>
  )
}

export const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

export const Element = props => {
  const { attributes, children, element } = props
  const style = { textAlign: element.align }
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      )
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      )
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      )
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      )
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      )
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      )
    case 'mention':
      return <Mention attributes={attributes} children={children} element={element} />
    case 'link':
      return <LinkComponent {...props} />
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      )
  }
}