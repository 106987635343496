import React from 'react';
import {
  Row,
  Col,
  Typography
} from 'antd';

const { Title, Text } = Typography;

const RawResult = ({ margin, score }) => {
  return (
    <Row type='flex' align='bottom' style={{ margin: `${margin}` }}>
      {
        score >= 0 && score <= 72 ?
          (
            <Col style={{ border: '1px solid #FF4F4F', maxHeight: '94px' }}>
              <Row type='flex' justify='center' align='middle' style={{ width: '176px', height: '52px', borderBottom: '1px solid #FF4F4F', background: '#FF4F4F' }}>
                <Title level={3} style={{ color: 'white', margin: '0' }}>
                  {score.toFixed(0)} puntos
                </Title>
              </Row>
              <Row type='flex' justify='center' align='middle' style={{ widows: '176px', height: '42px' }}>
                <Text style={{ color: '#FF4D4F' }}>
                  Bajo: 0 al 72
                </Text>
              </Row>
            </Col>
          ) :
          (
            <Col style={{ border: '1px solid #FF4F4F', maxHeight: '94px' }}>
              <Row style={{ width: '176px', height: '52px', borderBottom: '1px solid #FF4F4F' }} />
              <Row type='flex' justify='center' align='middle' style={{ widows: '176px', height: '42px' }}>
                <Text style={{ color: '#FF4D4F' }}>
                  Bajo: 0 al 72
                </Text>
              </Row>
            </Col>
          )
      }
      {
        score > 72 && score <= 83 ?
          (
            <Col style={{ border: '1px solid #FAAD14', maxHeight: '114px', margin: '0 30px' }}>
              <Row type='flex' justify='center' align='middle' style={{ width: '176px', height: '72px', borderBottom: '1px solid #FAAD14', background: '#FAAD14' }}>
                <Title level={3} style={{ color: 'white', margin: '0' }}>
                  {score.toFixed(0)} puntos
                </Title>
              </Row>
              <Row type='flex' justify='center' align='middle' style={{ widows: '176px', height: '42px' }}>
                <Text style={{ color: '#FAAD14' }}>
                  Promedio 73 al 83
                </Text>
              </Row>
            </Col>
          ) :
          (
            <Col style={{ border: '1px solid #FAAD14', maxHeight: '114px', margin: '0 30px' }}>
              <Row style={{ width: '176px', height: '72px', borderBottom: '1px solid #FAAD14' }} />
              <Row type='flex' justify='center' align='middle' style={{ widows: '176px', height: '42px' }}>
                <Text style={{ color: '#FAAD14' }}>
                  Promedio 73 al 83
                </Text>
              </Row>
            </Col>
          )
      }
      {
        score > 83 ?
          (
            <Col style={{ border: '1px solid #52C41A', maxHeight: '134px' }}>
              <Row type='flex' justify='center' align='middle' style={{ width: '176px', height: '92px', borderBottom: '1px solid #52C41A', background: '#52C41A' }}>
                <Title level={3} style={{ color: 'white', margin: '0' }}>
                  {score.toFixed(0)} puntos
                </Title>
              </Row>
              <Row type='flex' justify='center' align='middle' style={{ widows: '176px', height: '42px' }}>
                <Text style={{ color: '#52C41A' }}>
                  Alto 84 al 100*
                </Text>
              </Row>
            </Col>
          ) :
          (
            <Col style={{ border: '1px solid #52C41A', maxHeight: '134px' }}>
              <Row type='flex' justify='center' align='middle' style={{ width: '176px', height: '92px', borderBottom: '1px solid #52C41A' }} />
              <Row type='flex' justify='center' align='middle' style={{ widows: '176px', height: '42px' }}>
                <Text style={{ color: '#52C41A' }}>
                  Alto 84 al 100*
                </Text>
              </Row>
            </Col>
          )
      }
    </Row>
  );
}

export default RawResult;