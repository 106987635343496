import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../../../actions/data';
import { bindActionCreators } from 'redux';
import {
  Modal,
  Icon,
  Row,
  Col,
  Button,
  message,
  Select,
  Tooltip,
  Typography,
  List,
} from 'antd';

const { Text } = Typography;
const SHARE_URL = process.env.REACT_APP_SHARE_URL;

/**
 * Receive an array of user_id and generate shareable profile url
 * @param {*} props
 */

function ShareProfiles(props) {
  const [state, setState] = useState({
    accept: false,
    visible: false,
  });
  const [dateCreated, setDateCreated] = useState(undefined);

  const [tokenState, setTokenState] = useState([]);

  useEffect(() => {
    setTokenState([]);
    setState({ ...state, accept: false });
  }, [props.candidates]);

  const openModal = () => {
    setState({ ...state, visible: true });
  };

  const handleOk = () => {
    setState({ ...state, accept: true });
    fetchDataToShareProfile();
  };

  const handleCancel = () => {
    setState({ ...state, visible: false });
  };

  const fetchDataToShareProfile = async () => {
    let url = '/api/v1/gamesandtests/sharecandidateprofile/';
    let candidates = props.candidates;

    candidates.map(async (obj) => {
      try {
        let data = {
          candidate_id: obj.candidate,
          jobapp_id: props.jobAppId,
          creation_date: dateCreated ? dateCreated.toISOString() : undefined,
        };
        props.actions
          .fetchAndReturn(props.token, url, 'POST', data)
          .then((response) => {
            setTokenState((oldArray) => [
              ...oldArray,
              {
                name: response.body?.name,
                token: response.body?.token,
                personal_user_id: obj.personal_user_id,
                jobapp_id: props.jobAppId,
              },
            ]);
          });
      } catch (err) {
        message.error('Ocurrió un error obteniendo el link');
      }
    });
  };

  const copyToClipboard = () => {
    let textToCopy = tokenState.map(
      (obj) =>
        `${obj.name}: ${SHARE_URL}/sharedprofile/${obj.token}/${obj.jobapp_id}/${obj.personal_user_id}`
    );
    textToCopy = textToCopy.join('\n');
    navigator.clipboard.writeText(textToCopy);
    message.success('El link se copió al portapapeles.');
  };

  return (
    <Fragment>
      <Modal
        centered
        width={800}
        visible={state.visible}
        onOk={state.accept ? handleCancel : handleOk}
        onCancel={handleCancel}
      >
        {!tokenState.length ? (
          <Fragment>
            <Row>
              <Col xs={1}>
                <Icon
                  type="exclamation-circle"
                  style={{
                    marginTop: '7px',
                    fontSize: '17px',
                    color: 'orange',
                  }}
                />
              </Col>
              <Col xs={23}>
                <div>
                  <p>¡Advertencia!</p>
                  Al enviar este link estarás compartiendo información personal
                  y privada del candidato. Por seguridad este link tendrá una
                  vigencia por defecto de 72 hrs. y luego quedará deshabilitado.
                  Podrás extender la duración bajo tu responsabilidad a 7 o 14 días.
                  <br />
                  Genomawork se hará responsable de la protección de los datos e
                  integridad de la información de todos los usuarios que han
                  sido evaluados a través de la plataforma.
                  <br />
                  Por lo tanto, el cliente sólo podrá compartir este link con
                  terceros que tengan directa relación con la institución y
                  queda estrictamente prohibido utilizar esta infomación para
                  cualquier otro objetivo que no esté acorde a las finalidades
                  de este proceso.
                </div>
                <Select onChange={(option) => {
                  const newDateCreated = new Date();
                  if (option === "0") {
                    setDateCreated(undefined);
                    return;
                  };
                  newDateCreated.setDate(newDateCreated.getDate() + parseInt(option));
                  console.log(newDateCreated);
                  setDateCreated(newDateCreated);
                }}>
                  <Select.Option value="12">14 días.</Select.Option>
                  <Select.Option value="4">7 días.</Select.Option>
                  <Select.Option value="0">72 hrs.</Select.Option>
                </Select>
                <br />
                <small>
                  Para mayor detalle de nuestros términos y condiciones haz
                  click{' '}
                  <a
                    href="https://docs.wixstatic.com/ugd/1837b1_015a59889ae64150a63a822ab2fbfc50.pdf"
                    target="_blank"
                  >
                    aquí
                  </a>
                  .
                </small>
              </Col>
            </Row>
          </Fragment>
        ) : (
          <Fragment>
            <Button
              onClick={copyToClipboard}
              style={{
                margin: 'auto',
                display: 'flex',
                lineHeight: '14px',
                fontSize: '18px',
                height: 'auto',
                padding: '16px 22px 0',
                border: 'none',
                textDecoration: 'underline',
              }}
            >
              {' '}
              <Typography.Paragraph copyable>
                Copiar al portapapeles
              </Typography.Paragraph>{' '}
            </Button>

            {tokenState.map((obj, idx) => {
              return (
                <List.Item key={idx}>
                  {`${obj.name}: `}
                  <a
                    href={`${SHARE_URL}/sharedprofile/${obj.token}/${obj.jobapp_id}/${obj.personal_user_id}`}
                  >
                    {`${SHARE_URL}/sharedprofile/${obj.token}/${obj.jobapp_id}/${obj.personal_user_id}`}
                  </a>
                </List.Item>
              );
            })}
          </Fragment>
        )}
      </Modal>
      <Tooltip
        placement="topLeft"
        title={'Esta acción no soporta más de 5 candidatos'}
      >
        {props.disabled ? (
          <Button
            type="link"
            onClick={props.candidates.length <= 5 && openModal}
            disabled={props.disabled}
            style={{ padding: 0 }}
          >
            Compartir perfil
          </Button>
        ) : (
          <Text type="link" onClick={props.candidates.length <= 5 && openModal}>
            Compartir perfil
          </Text>
        )}
      </Tooltip>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    jobApplication: state.jobapp.jobApplication,
    token: state.auth.token,
  };
};

const mapDispathToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispathToProps)(ShareProfiles);
