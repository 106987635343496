import React from 'react';
import { Row, Typography } from 'antd';
import propTypes from 'prop-types';

const { Title } = Typography;

const SectionTitle = ({ text, background }) => {
  return (
    <Row
      className='section-title'
      type='flex'
      align='middle'
      style={{ background: background }}
    >
      <Title level={3}>{text}</Title>
    </Row>
  );
};

SectionTitle.propTypes = {
  text: propTypes.string.isRequired,
  background: propTypes.string,
};

export default SectionTitle;
