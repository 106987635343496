import React from 'react';
import { Row, Typography, Col, Rate } from 'antd';
import propTypes from 'prop-types';

const { Paragraph } = Typography;

const VideoInterview = ({ candidates, scrollRefs }) => {
  const questions = [];

  candidates.map((candidate) => {
    if (Object.values(candidate['video_interview_scores']).length > 0) {
      Object.values(candidate['video_interview_scores']).map((q) => {
        const questionNumber = Object.keys(
          candidate['video_interview_scores']
        ).filter((q) => q !== 'average').length;
        if (questions.length < questionNumber && typeof q === 'object') {
          questions.push(q.question);
        }
      });
    }
  });

  return (
    <div className='interview-section-container' ref={scrollRefs}>
      <Row
        type='flex'
        align='middle'
        justify='center'
        className='interview-section-container__info'
      >
        <Col span={2}>
          <Row
            type='flex'
            align='middle'
          >
            {candidates[0] ? (
              questions.length > 0 ? (
                questions.map((question, index) => (
                  <Row key={index} type='flex' align='middle'>
                    <Paragraph strong>{question}</Paragraph>
                  </Row>
                ))
              ) : (
                <Paragraph strong>Sin preguntas</Paragraph>
              )
            ) : null}
          </Row>
        </Col>

        <Col span={19}>
          <Row
            type='flex'
            align='middle'
            justify='space-around'
          >
            {
            // Reemplazar por logica del pdf, es mas clara y ordenada
            candidates[0]
              ? candidates.map((candidate, index) => (
                  <Col
                    key={index}
                  >
                    {Object.values(candidate['video_interview_scores']).length >
                    0 ? (
                      Object.values(candidate['video_interview_scores']).map(
                        (value, index) =>
                          typeof value === 'number' ? null : (
                            <Col key={index}>
                              <Row type='flex' justify='center'>
                                <Rate
                                  disabled
                                  allowHalf
                                  defaultValue={value.rating}
                                  style={{ color: '#F175A5' }}
                                />
                              </Row>
                            </Col>
                          )
                      )
                    ) : questions.length > 0 ? (
                      questions.map((q) => (
                        <Paragraph key={q}>
                          Sin evaluaciones
                        </Paragraph>
                      ))
                    ) : (
                      <Paragraph disabled>Sin evaluaciones</Paragraph>
                    )}
                  </Col>
                ))
              : null}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

VideoInterview.propTypes = {
  candidates: propTypes.array.isRequired,
};

export default VideoInterview;
