import React from 'react';
import moment from 'moment';

const BreadcrumPage = ({ candidateInfo, jobName }) => {
  const today = moment().format('LL')

  return (
    <span style={{ textAlign: 'center', fontSize: '8px', width: '100%' }}>
      Postulante: {candidateInfo.user_name} / Proceso: {jobName} / Fecha de descarga: {today}
    </span>
  );
}
 
export default BreadcrumPage;