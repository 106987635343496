import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import UploadImage from './uploadImage';
import { connect } from 'react-redux';

const LogoBusiness = (props) => {
  const [uploadedImg, setUploadedImg] = useState('https://genoma-assets.s3.us-east-2.amazonaws.com/logo-cuadrado.png')

  useEffect(() => {
    if (props.logoURL) {
      setUploadedImg(props.logoURL);
    }
  }, [props.logoURL])

  const getImg = (img, info) => {
    setUploadedImg(img)
    props.logo(info)
  }

  const exampleImg = (
    <div className='uploaded-bg'>
      {uploadedImg && <img src={uploadedImg} />}
    </div>
  )

  return (
    <Row className="gw-as__bu-logo" type="flex">
      <Col xs={24}>
        <UploadImage 
          type="logo"
          title="Cambiar logo de la empresa"
          description="Logo de tu empresa que se ve en la esquina superior izquierda del sitio. Archivos soportados JPG y PNG, con un peso máximo de 1MB. Mínimo 200x200 píxeles"
          getImage={getImg} 
        />
      </Col>
      <Col xs={24}>
        <div className="gw-as__bu-logo__preview">
          {exampleImg}
        </div>
      </Col>
    </Row>

  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    businessLogo: state.data.data.business_logo,
    logoURL: state.auth.logoURL,
    addOns: state.auth.addOns
  };
};

export default connect(mapStateToProps)(LogoBusiness);
export { LogoBusiness as LogoBusinessNotConnected };
