import React, { useState, useEffect } from 'react';
import { ResponsiveChoropleth } from '@nivo/geo';
import { ResponsiveBar } from '@nivo/bar';
import PropTypes from 'prop-types';

import {
  Row,
  Col,
  Spin
} from 'antd';

/**
 * 
 * @param {Object} dataSource [{"id": "CHL", "name": "Chile", "value": 100}, ...]
 * @returns React.Node
 */
const PaCandidatesGeomap = ({ dataSource, height }) => {
  const [loading, setLoading] = useState(true);
  const [mapFeatures, setMapFeatures] = useState([]);
  const [geoMapData, setGeoMapData] = useState([]);

  useEffect(() => {
    const url = 'https://genoma-assets.s3.us-east-2.amazonaws.com/custom_geo.json';
    downloadObject(url);
  }, [])

  useEffect(() => {
    const sorted = dataSource.sort((a, b) => {
      return a.value - b.value
    });
    setGeoMapData(sorted);
  }, [dataSource])

  async function downloadObject(url) {
    try {
      const fetchResponse = await fetch(url);
      const data = await fetchResponse.json();
      setLoading(false);
      setMapFeatures(data.features);
      return data;
    } catch (err) {
      console.error('error ', err);
    }
  }

  const getMaxValue = () => {
    if (geoMapData.length == 0) {
      return 0
    }
    const max = geoMapData.reduce((prev, current) => (prev.value > current.value) ? prev : current);
    return max.value;
  }

  return (
    <Row>
      <Col xs={0} md={10}>
        <Spin spinning={loading}>
          <div style={{ height: height }}>
            <ResponsiveChoropleth
              data={geoMapData}
              features={mapFeatures}
              margin={{ top: 40, right: 0, bottom: 0, left: 0 }}
              colors="blues"
              domain={[0, getMaxValue()]}
              unknownColor="#D9D9D9"
              label="name_long"
              projectionTranslation={[1, .5]}
              projectionRotation={[0, 0, 0]}
              projectionScale={55}
              enableGraticule={false}
              borderWidth={0.5}
              borderColor="#8c8c8c"
              isInteractive={true}
            />
          </div>
        </Spin>
      </Col>

      <Col xs={24} md={14}>
        <Spin spinning={loading}>
          <div style={{ height: height, minWidth: '100%' }}>
            <ResponsiveBar
              data={geoMapData.length <= 5 ? geoMapData : geoMapData.slice(geoMapData.length - 5, geoMapData.length)}
              keys={['value']}
              indexBy="name"
              enableLabel={false}
              margin={{ top: 20, right: 20, bottom: 50, left: 70 }}
              padding={.7}
              layout={'horizontal'}
              valueScale={{ type: 'linear' }}
              colors={'#096DD9'}
              enableGridX={true}
              enableGridY={false}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickValues: 6
              }}
              theme={{
                fontSize: 12,
                textColor: '#5E5E5E',
                grid: {
                  line: {
                    strokeWidth: 1,
                    strokeDasharray: "4 4"
                  }
                }
              }}
              tooltip={({ value, indexValue, }) => (
                <div
                  style={{
                    padding: 6,
                    background: '#FFFFFF',
                    boxShadow: '0px 1px 2px gray',
                    borderRadius: 2
                  }}
                >
                  <div style={{ fontSize: 14 }}>
                    {`${indexValue}: `}
                    <strong>
                      {value}
                    </strong>
                  </div>
                </div>
              )}

            />
          </div>
        </Spin>
      </Col>
    </Row>
  )
}

PaCandidatesGeomap.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.object),
  height: PropTypes.number
};

export default PaCandidatesGeomap;