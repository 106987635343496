import React, { useState, useEffect, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';

import * as actionCreators from '../../actions/data';

import {
  Card,
  Typography,
  Collapse,
  Form,
  Input,
  Icon,
  Button,
} from 'antd';
import './References.scss';
import i18n from '../../i18n';

const { Title, Text } = Typography;
const { Panel } = Collapse;

const AddReferences = (props) => {
  const [dataSent, setDataSent] = useState(false);
  const [referenceList, setReferenceList] = useState([
    {
      panel_id: 0,
      deletable: false,
      ready: false,
      name: '',
      email: '',
      organization: '',
      relation: '',
      phone: '',
    }
  ]);

  const getData = () => {
    const token = props.token;
    const url = `/api/v1/gamesandtests/userjobapplicationreferences/${props.match.params.reference_token}/`;
    props.actions.fetchAndReturn(token, url)
      .then((response) => {
        if (response.status >= 400) {
          props.dispatch(push('/notFound'));
        }
      });
  };

  useEffect(() => getData(), []);

  // TODO: MOVE TO UTILS
  const isValidEmail = (i) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(referenceList[i]['email'].toLowerCase());
  };

  const isValidPhone = (i) => {
    const re = /^\+\d{1,4}?\d{1,15}?$/;
    return re.test(referenceList[i]?.phone);
  };

  const handleChange = (index, item) => (event) => {
    let referencesArray = [...referenceList];
    referencesArray[index][item] = event.target.value;
    let ref = referencesArray[index];

    if (ref['name'] != '' && isValidEmail(index) && ref['organization'] != '' && ref['relation'] != '') {
      referencesArray[index]['ready'] = true;
    } else {
      referencesArray[index]['ready'] = false;
    }
    setReferenceList(referencesArray);
  };

  const addReference = () => {
    let referencesArray = [...referenceList];
    let newIndex = referencesArray[referencesArray.length - 1]['panel_id'] + 1;
    referencesArray.push({
      panel_id: newIndex,
      deletable: true,
      ready: false,
      name: '',
      email: '',
      organization: '',
      relation: '',
      phone: '',
    });
    setReferenceList(referencesArray);
  };

  const isReadyToSubmit = () => {
    let ready = true;
    referenceList.forEach(item => {
      ready = ready && item['ready']
    });
    return ready;
  };

  const deleteReference = (index) => {
    let referencesArray = [...referenceList];
    referencesArray.splice(index, 1);
    setReferenceList(referencesArray);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      references: referenceList,
    };
    const url = `gamesandtests/userjobapplicationreferences/${props.match.params.reference_token}/references/`;
    props.actions.sendData(props.token, url, JSON.stringify(data), 'POST')
      .then(() => setDataSent(true));
  };

  return (
    <div className='references-view'>
      <div className='references-view__title'>
        <Title level={4}>{i18n.t('references__title')}</Title>
      </div>
      {!dataSent ? (
        <div className='references-view__card-container'>
          <Card
            style={{ width: 750 }}
            actions={[
              <Fragment>
                <Button type="dashed" icon='plus' onClick={addReference} >
                  {i18n.t('references__add__more')}
                </Button>
                <Button
                  type='primary'
                  htmlType='submit'
                  onClick={handleSubmit}
                  disabled={!isReadyToSubmit()}
                >
                  {i18n.t('references__add__reference')}
                </Button>
              </Fragment>
            ]}
          >
            {referenceList.map((reference, index) => (
              <Collapse key={index} defaultActiveKey={['0']}>
                <Panel
                  key={index}
                  index={index}
                  header={
                    <div className='header-titles'>
                      <Text strong>
                        {referenceList[index]['name'] === ''
                          ? (i18n.t('references__name__reference'))
                          : (referenceList[index]['name'])}
                      </Text>
                      <Text>
                        {referenceList[index]['email'] === ''
                          ? 'referencia@corporativo.com'
                          : referenceList[index]['email']}
                      </Text>
                    </div>
                  }
                >
                  <Form onSubmit={handleSubmit}>
                    <Form.Item
                      label={i18n.t('references__name__and__lastname')+"*"}
                      required
                      hasFeedback
                      validateStatus={
                        referenceList[index]['name'] === undefined
                          ? null
                          : (referenceList[index]['name'] ? 'success' : 'error')}
                      help={!referenceList[index]['name'] && i18n.t('references__please__enter__name')}
                    >
                      <Input
                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        suffix={`${referenceList[index]['name'].length}/100`}
                        placeholder={i18n.t('references__name__here')}
                        value={referenceList[index]['name']}
                        onChange={handleChange(index, 'name')}
                        maxLength={100}
                      />
                    </Form.Item>

                    <Form.Item
                      label={i18n.t('commons__email')+"*"}
                      required
                      hasFeedback
                      validateStatus={!isValidEmail(index) ? 'error' : 'success'}
                      help={(!isValidEmail(index) && referenceList[index]['email'].length > 0) && i18n.t('login__email__error')}
                    >
                      <Input
                        prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={i18n.t('references__reference__example')}
                        value={referenceList[index]['email']}
                        onChange={handleChange(index, 'email')}
                      />
                    </Form.Item>

                    <Form.Item
                      label={i18n.t('references__reference__number')}
                      hasFeedback
                      validateStatus={
                        !(referenceList[index]?.phone)
                          ? null
                          : (isValidPhone(index) ? 'success' : 'error')
                      }
                    >
                      <Input
                        prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        suffix={`${referenceList[index]['phone'].length}/25`}
                        placeholder='Ej. +56999999999'
                        style={{ width: '100%' }}
                        value={referenceList[index]['phone']}
                        onChange={handleChange(index, 'phone')}
                        maxLength={25}
                      />
                    </Form.Item>

                    <Form.Item
                      label={`${i18n.t('references__organization__where__they__worked')}*`}
                      required={true}
                      hasFeedback
                      validateStatus={referenceList[index]['organization'] ? 'success' : 'error'}
                      help={!referenceList[index]['organization'] && i18n.t('references__please__enter__organization')}
                    >
                      <Input
                        prefix={<Icon type="global" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        suffix={`${referenceList[index]['organization'].length}/255`}
                        placeholder="Ej. Genomawork"
                        value={referenceList[index]['organization']}
                        onChange={handleChange(index, 'organization')}
                        maxLength={255}
                      />
                    </Form.Item>

                    <Form.Item
                      label={`${i18n.t('references__laboral__relationship')}*`}
                      required={true}
                      hasFeedback
                      validateStatus={referenceList[index]['relation'] ? 'success' : 'error'}
                      help={!referenceList[index]['relation'] &&i18n.t('references__please__enter__relationship')}
                      className='reference-relationship'
                    >
                      <Text>{referenceList[index]['name']} {i18n.t('references__it_was__my').split('}')[1]}</Text>
                      <Input
                        prefix={<Icon type="apartment" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        suffix={`${referenceList[index]['relation'].length}/255`}
                        placeholder={i18n.t('references__supervisor__example')}
                        value={referenceList[index]['relation']}
                        onChange={handleChange(index, 'relation')}
                        maxLength={255}
                      />
                    </Form.Item>

                    {referenceList[index]['deletable'] && (
                      <Button
                        onClick={() => deleteReference(index)}
                        type="secondary"
                      >
                        {i18n.t('references__delete__reference')}
                        <Icon type="delete" />
                      </Button>
                    )}
                  </Form>
                </Panel>
              </Collapse>
            ))}
          </Card>
        </div>
      ) : (
        <div className='references-view__card-container'>
          <Card style={{ width: 750 }}>
            <div className='references-sent' >
              <img src="https://genoma-assets.s3.us-east-2.amazonaws.com/genomines/happy-genomin.png" alt='Referencias Enviadas' />
              <Text strong >{i18n.t('references__send__success')}</Text>
            </div>
          </Card>
        </div>
      )}
    </div>
  )
}

AddReferences.defaultProps = {
  statusText: '',
};

AddReferences.propTypes = {
  dispatch: PropTypes.func.isRequired,
  statusText: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    data: state.data.data,
    isFetching: state.data.isFetching,
    error: state.data.error,
    logoURL: state.auth.logoURL
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    actions: bindActionCreators(actionCreators, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddReferences);
