import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../../i18n';

export const formatDate = string => {
  return string ? string.split('-').reverse().join('-') : null;
}

export const evaluations = {
  0: i18n.t('candidate_pdf__no_evaluated', {ns: 'candidatePdf'}),
  1: i18n.t('candidate_pdf__not_acceptable', {ns: 'candidatePdf'}),
  2: i18n.t('candidate_pdf__deficient', {ns: 'candidatePdf'}),
  3: i18n.t('candidate_pdf__moderate', {ns: 'candidatePdf'}),
  4: i18n.t('candidate_pdf__good', {ns: 'candidatePdf'}),
  5: i18n.t('feedback__excellent'),
}

export const evaluationDictionary = number => {
  return evaluations[number];
}

const InterviewGroupQuestion = ({ group }) => {
  const prepareQuestion = (q) => {
    const answer = group.answer.find(ans => ans.question_number == q.question_number);

    if (answer?.evaluation) {
      return (
        <div key={q.id}>
          <p>{answer.text}</p>
          <ul>
            <li>{answer.feedback_text}</li>
          </ul>
          <p>{evaluationDictionary(answer.evaluation)}</p>
        </div>
      );
    } else {
      return (
        <div key={q.id}>
          <p>{q.text}</p>
          <span>{i18n.t('candidate_pdf__no_answer', {ns: 'candidatePdf'})}</span>
          <p>{i18n.t('candidate_pdf__not_evaluated', {ns: 'candidatePdf'})}</p>
        </div>
      );
    }
  };

  return (
    <div className="report-pdf-interview-kits__group-question">
      <h6>{group.group}</h6>
      {group.detail.map((question) => (
        prepareQuestion(question)
      ))}
    </div>
  );
};

InterviewGroupQuestion.propTypes = {
  group: PropTypes.object.isRequired
}

export default InterviewGroupQuestion;
