export const AUTH_LOGIN_USER_REQUEST = 'AUTH_LOGIN_USER_REQUEST';
export const AUTH_LOGIN_USER_FAILURE = 'AUTH_LOGIN_USER_FAILURE';
export const AUTH_LOGIN_USER_SUCCESS = 'AUTH_LOGIN_USER_SUCCESS';
export const AUTH_FIRST_LOGIN_BUSINESSUSER_SUCCESS = 'AUTH_FIRST_LOGIN_BUSINESSUSER_SUCCESS';
export const AUTH_SOCIAL_LOGIN_USER_REQUEST = 'AUTH_SOCIAL_LOGIN_USER_REQUEST';
export const AUTH_SOCIAL_LOGIN_USER_FAILURE = 'AUTH_SOCIAL_LOGIN_USER_FAILURE';
export const AUTH_SOCIAL_LOGIN_USER_SUCCESS = 'AUTH_SOCIAL_LOGIN_USER_SUCCESS';
export const AUTH_LOGOUT_USER = 'AUTH_LOGOUT_USER';
export const AUTH_SIGNUP_USER_REQUEST = 'AUTH_SIGNUP_USER_REQUEST';
export const AUTH_SIGNUP_USER_FAILURE = 'AUTH_SIGNUP_USER_FAILURE';
export const AUTH_SIGNUP_USER_SUCCESS = 'AUTH_SIGNUP_USER_SUCCESS';
export const AUTH_SIGNUP_USER_ALREADY_EXISTS = 'AUTH_SIGNUP_USER_ALREADY_EXISTS';
export const AUTH_GO_BACK = 'AUTH_GO_BACK';
export const LOGO_UPDATE = 'LOGO_UPDATE';
export const LOGO_RESET = 'LOGO_RESET';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_TOS = 'UPDATE_TOS';
export const UPDATE_AUTH_DATA = 'UPDATE_AUTH_DATA';
export const UPDATE_NOTIFICATION_COUNT = 'UPDATE_NOTIFICATION_COUNT';

export const FILE_FETCH = 'FETCH_FILE';

export const DATA_FETCH_PROTECTED_DATA_REQUEST = 'DATA_FETCH_PROTECTED_DATA_REQUEST';
export const DATA_RECEIVE_PROTECTED_DATA = 'DATA_RECEIVE_PROTECTED_DATA';

export const DATA_FETCH = 'DATA_FETCH';
export const DATA_LANE_FETCH = 'DATA_LANE_FETCH';
export const DATA_RECEIVE_FAILURE = 'DATA_RECEIVE_FAILURE';
export const DATA_RECEIVE_SUCCESS = 'DATA_RECEIVE_SUCCESS';
export const DATA_LANE_RECEIVE_SUCCESS = 'DATA_LANE_RECEIVE_SUCCESS';
export const DATA_SIMPLE_LANE_FETCH = 'DATA_SIMPLE_LANE_FETCH';
export const DATA_SIMPLE_LANE_RECEIVE_SUCCESS = 'DATA_SIMPLE_LANE_RECEIVE_SUCCESS';

export const DATA_UPDATE = 'DATA_UPDATE';
export const DATA_UPDATE_SUCCESS = 'DATA_UPDATE_SUCCESS';

export const DATA_SEND = 'DATA_SEND';
export const DATA_SEND_FAILURE = 'DATA_SEND_FAILURE';
export const DATA_SEND_SUCCESS = 'DATA_SEND_SUCCESS';

export const GAME_DATA_SEND_BEGIN = 'GAME_DATA_SEND_BEGIN';
export const GAME_DATA_SEND_FAILURE = 'GAME_DATA_SEND_FAILURE';
export const GAME_DATA_SEND_SUCCESS = 'GAME_DATA_SEND_SUCCESS';

export const SUCCESS = 'ALERT_SUCCESS';
export const ERROR = 'ALERT_ERROR';
export const WARNING = 'ALERT_WARNING';
export const CLEAN = 'ALERT_CLEAN';

export const JOBAPP_FETCH_BEGIN = 'JOBAPP_FETCH_BEGIN';
export const JOBAPP_FETCH_SUCCESS = 'JOBAPP_FETCH_SUCCESS';
export const JOBAPP_UPDATE = 'JOBAPP_UPDATE';
export const JOBAPP_FETCH_FAILURE = 'JOBAPP_FETCH_FAILURE';
export const ACTIVITY_FETCH_BEGIN = 'ACTIVITY_FETCH_BEGIN';
export const ACTIVITY_FETCH_SUCCESS = 'ACTIVITY_FETCH_SUCCESS';
export const ACTIVITY_UPDATE = 'ACTIVITY_UPDATE';
export const ACTIVITY_REMOVE = 'ACTIVITY_REMOVE';
export const ACTIVITY_ADD = 'ACTIVITY_ADD';
export const ACTIVITY_FETCH_FAILURE = 'ACTIVITY_FETCH_FAILURE';
export const ACTIVITY_ADD_QUESTION = 'ACTIVITY_ADD_QUESTION';
export const ACTIVITY_REMOVE_QUESTION = 'ACTIVITY_REMOVE_QUESTION';
export const ACTIVITY_SWITCH_QUESTION = 'ACTIVITY_SWITCH_QUESTION';
export const ACTIVITY_ORIGINAL_REMOVE = 'ACTIVITY_ORIGINAL_REMOVE';
export const ACTIVITY_UPDATE_QUESTION = 'ACTIVITY_UPDATE_QUESTION';
export const ACTIVITY_START_EDIT_QUESTION = 'ACTIVITY_START_EDIT_QUESTION';
export const ASSIGNED_ACTIVITY_FETCH_BEGIN = 'ASSIGNED_ACTIVITY_FETCH_BEGIN';
export const ASSIGNED_ACTIVITY_RECEIVE_SUCCESS = 'ASSIGNED_ACTIVITY_RECEIVE_SUCCESS';
export const ASSIGNED_ACTIVITY_RECEIVE_FAILURE = 'ASSIGNED_ACTIVITY_RECEIVE_FAILURE';
export const SAVE_STARTING = 'SAVE_STARTING';
export const SAVE_IDEAL_GENOME = 'SAVE_IDEAL_GENOME';
export const SAVE_IDEAL_GENOME_SUCCESS = 'SAVE_IDEAL_GENOME_SUCCESS';
export const UPDATE_JOB_APPLICATION = 'UPDATE_JOB_APPLICATION';
export const SAVE_NEW_MEASURED_TRAITS = 'SAVE_NEW_MEASURED_TRAITS';
export const UPDATE_MEASURED_TRAITS = 'UPDATE_MEASURED_TRAITS';
export const DELETE_MEASURED_TRAITS = 'DELETE_MEASURED_TRAITS';
export const SAVE_NEW_ASSIGNED_ACTIVITIES = 'SAVE_NEW_ASSIGNED_ACTIVITIES';
export const UPDATE_ASSIGNED_ACTIVITIES = 'UPDATE_ASSIGNED_ACTIVITIES';
export const DELETE_ASSIGNED_ACTIVITIES = 'DELETE_ASSIGNED_ACTIVITIES';
export const POSITION_SEND_SUCCESS = 'POSITION_SEND_SUCCESS';
export const POSITION_SEND_FAILURE = 'POSITION_SEND_FAILURE';

export const JOBAPP_FETCH_BEGIN_TRAITS = "JOBAPP_FETCH_BEGIN_TRAITS";
export const TRAIT_FETCH_BEGIN = 'TRAIT_FETCH_BEGIN';
export const TRAIT_RECEIVE_FAILURE = 'TRAIT_RECEIVE_FAILURE';
export const TRAIT_RECEIVE_SUCCESS = 'TRAIT_RECEIVE_SUCCESS';
export const TRAIT_UPDATE = 'TRAIT_UPDATE';
export const TRAIT_REMOVE = 'TRAIT_REMOVE';
export const TRAITS_RELEVANCE = 'TRAITS_RELEVANCE';
export const MEASURED_TRAIT_FETCH_BEGIN = 'MEASURED_TRAIT_FETCH_BEGIN';
export const MEASURED_TRAIT_RECEIVE_FAILURE = 'MEASURED_TRAIT_RECEIVE_FAILURE';
export const MEASURED_TRAIT_RECEIVE_SUCCESS = 'MEASURED_TRAIT_RECEIVE_SUCCESS';
export const MEASURED_TRAIT_REMOVE = 'MEASURED_TRAIT_REMOVE';
export const MEASURED_TRAIT_ORIGINAL_REMOVE = 'MEASURED_TRAIT_ORIGINAL_REMOVE';
export const SAVE_TRAIT_SUCCESS = 'SAVE_TRAIT_SUCCESS';
export const RESET_MT = 'RESET_MT';

export const VIDEO_FETCH_BEGIN = 'VIDEO_FETCH_BEGIN';
export const VIDEO_FETCH_SUCCESS = 'VIDEO_FETCH_SUCCESS';
export const VIDEO_FETCH_FAILURE = 'VIDEO_FETCH_FAILURE';
export const VIDEO_RATE_BEGIN = 'VIDEO_RATE_BEGIN';
export const VIDEO_RATE_SUCCESS = 'VIDEO_RATE_SUCCESS';
export const VIDEO_RATE_FAILURE = 'VIDEO_RATE_FAILURE';

export const SEARCH_BEGIN = 'SEARCH_BEGIN';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'SEARCH_FAILURE';
export const RESET_VIDEO_STATE = 'RESET_VIDEO_STATE';

export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_MODAL = 'SHOW_MODAL';


export const FETCH_SUCCESS_V2 = 'FETCH_SUCCESS';
export const FETCH_BEGIN_V2 = 'FETCH_BEGIN';
export const FETCH_FAILURE_V2= 'FETCH_FAILURE';

export const SET_UTM_PARAMS = 'SET_UTM_PARAMS';
export const SET_SELECTED_BRANCHES = 'SET_SELECTED_BRANCHES';
