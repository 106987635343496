import React, { useEffect, useState } from 'react';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import CreatableSelect from 'react-select/lib/Creatable';
import { Modal, Button, Row, Col, Form, Select, Typography } from 'antd';
import { SERVER_URL } from '../../../../utils/config';
import i18n from '../../../../i18n';
import { careerAreaOptions, careerStateOptions, careerTypeOptions } from '../../../../constants/careers';

const { Option, OptGroup } = Select;

const initialState = {
  career_type: '',
  career_institution: '',
  career_name: '',
  career_area: '',
  career_state: '',
  career_entry_year: '',
  career_last_year: '',
  career_index: -1,
  errors: {
    career_type: false,
    career_institution: false,
    career_name: false,
    career_area: false,
    career_state: false,
    career_entry_year: false,
    career_last_year: false,
  },
  options: [],
  optionsUniversities: [],
  careerValue: '',
  universityValue: '',
  isLoadingOptions: false,
  isLoadingOptionsUniversities: false,
};

const deltaEndYears = 10;

// TODO: Deprecate this component
function EditCareerForm({
  openModal,
  setOpenModal,
  form,
  onEditCareer,
  onAddCareer,
  careerToEdit,
  token,
}) {
  const { getFieldDecorator } = form;
  const [state, setState] = useState(initialState);
  const [years, setYears] = useState([]);

  const validateInstitution = (rule, value, callback) => {
    if (state.career_institution === '' && state.confirmDirty) {
      form.validateFields(['career_institution'], { force: true });
    }
    callback();
  };

  const validateCareerName = (rule, value, callback) => {
    if (state.career_name === '' && state.confirmDirty) {
      form.validateFields(['career_name'], { force: true });
    }
    callback();
  };

  const searchAPI = (val, objectsURL, token) => {
    const options = [];
    if (objectsURL === 'careers') {
      setState({ ...state, isLoadingOptions: true });
    } else {
      setState({ ...state, isLoadingOptionsUniversities: true });
    }
    const url = `${SERVER_URL}/api/v1/accounts/${objectsURL}/?language=${i18n.language.split('-')[0]}&search_input=${val}`;
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Token ${token}`,
        'Content-Type': `application/json`,
      },
    })
      .then((response) => {
        // FIXME: Eliminar console.logs, manejar error correctamente
        if (response.status >= 400) {
          console.log('Ocurrio un error');
          console.error(response);
        }
        setState({
          ...state,
          isLoadingOptions: false,
          isLoadingOptionsUniversities: false,
        });
        return response.json();
      })
      .then((response) => {
        if (objectsURL === 'careers') {
          Object.keys(response).forEach((key) => {
            options.push({
              value: response[key].name,
              label: response[key].translation[i18n.language.split('-')[0]],
            });
          });
          
          setState({ ...state, options, isLoadingOptions: false });
        } else {
          Object.keys(response).forEach((key) => {
            options.push({
              value: response[key].name,
              label: response[key].name,
            });
          });
          setState({
            ...state,
            optionsUniversities: options,
            isLoadingOptionsUniversities: false,
          });
          
        }
      });
  };

  const searchAPIDebounced = AwesomeDebouncePromise(
    (val, url) => searchAPI(val, url, token),
    500
  );

  const handleInputChange = (value, request) => {
    if (value.length >= 2) {
      searchAPIDebounced(value, request);
    }
  };

  const handleChangeCareer = (newValue, actionMeta) => {
    const careerTxt = newValue ? newValue.label : '';
    setState({ ...state, careerValue: newValue, career_name: careerTxt });
    form.setFieldsValue({ career_name: newValue });
  };

  const handleChangeUniversity = (newValue, actionMeta) => {
    const universityTxt = newValue ? newValue.label : '';
    setState({
      ...state,
      universityValue: newValue,
      career_institution: universityTxt,
    });
    form.setFieldsValue({ career_institution: newValue });
  };

  const handleCreate = async (inputValue) => {
    const newOption = { value: inputValue, label: inputValue };
    setState({
      ...state,
      options: [...state.options, newOption],
      careerValue: newOption,
      career_name: newOption.label,
    });
    form.setFieldsValue({ career_name: newOption });
  };

  const handleCreateUniversity = async (inputValue) => {
    const { optionsUniversities } = state;
    const newOption = { value: inputValue, label: inputValue };
    setState({
      ...state,
      optionsUniversities: [...optionsUniversities, newOption],
      universityValue: newOption,
      career_institution: newOption.label,
    });
    form.setFieldsValue({ career_institution: newOption });
  };

  const handleConfirmBlur = (value) => {
    setState({ ...state, confirmDirty: state.confirmDirty || !!value });
  };

  const compareToFirstYear = (rule, value, callback) => {
    if (value && (value < form.getFieldValue('career_entry_year'))) {
      callback(
        i18n.t('form__dates__error')
      );
    } else {
      callback();
    }
  };

  const validateToNextYear = (rule, value, callback) => {
    if (value && state.confirmDirty) {
      form.validateFields(['career_last_year'], { force: true });
    }
    callback();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form
      .validateFields()
      .then(() => {
        if (onAddCareer && state.career_index < 0) {
          onAddCareer({
            career_type: state.career_type,
            career_institution: state.career_institution,
            career_name: state.career_name,
            career_area: state.career_area,
            career_state: state.career_state,
            career_entry_year: state.career_entry_year,
            career_last_year: state.career_last_year,
          });
        }

        else if (onEditCareer && !state.career_index) {
          onEditCareer({
            career_type: state.career_type,
            career_institution: state.career_institution,
            career_name: state.career_name,
            career_area: state.career_area,
            career_state: state.career_state,
            career_entry_year: state.career_entry_year,
            career_last_year: state.career_last_year,
          }, careerToEdit.id);
        }
        setOpenModal(false)
      })
      .catch((error) => {
        console.error('Ocurrió un error', error);
      });
  };
  useEffect(() => {
    const nowDate = new Date();
    const yearsArray = [];
    for (let i = nowDate.getFullYear() + deltaEndYears; i >= nowDate.getFullYear() - 70; i--) {
      yearsArray.push(i);
    }
    setYears(yearsArray);
  }, []);

  useEffect(() => {
    if (onEditCareer) {
      let options = [
        { value: careerToEdit.career_name, label: careerToEdit.career_name },
      ];
      let careerValue = {
        value: careerToEdit.career_name,
        label: careerToEdit.career_name,
      };
      let optionsUniversities = [
        {
          value: careerToEdit.career_institution,
          label: careerToEdit.career_institution,
        },
      ];
      let universityValue = {
        value: careerToEdit.career_institution,
        label: careerToEdit.career_institution,
      };

      setState({
        career_type: careerToEdit.career_type,
        career_institution: careerToEdit.career_institution,
        career_name: careerToEdit.career_name,
        career_area: careerToEdit.career_area,
        career_state: careerToEdit.career_state,
        career_entry_year: careerToEdit.career_entry_year,
        career_last_year: careerToEdit.career_last_year,
        career_index: careerToEdit.index,
        options,
        careerValue,
        optionsUniversities,
        universityValue,
      });
      form.setFieldsValue({
        career_type: careerTypeOptions.find(obj => obj.value === careerToEdit.career_type)?.label,
        career_institution: universityValue,
        career_name: careerValue,
        career_area: careerToEdit.career_area,
        career_state: careerStateOptions.find(obj => obj.value === careerToEdit.career_state)?.label,
        career_entry_year: careerToEdit.career_entry_year,
        career_last_year: careerToEdit.career_last_year,
        career_index: careerToEdit.career_index,
      });
    }
    else{
      setState(initialState);
    }
  }, [openModal]);

  return (
    <Modal
      visible={openModal}
      className='careerModal'
      footer={false}
      centered
      destroyOnClose={true}
      closable
      onCancel={() => setOpenModal(false)}
    >
      <Form onSubmit={handleSubmit}>
        <Row gutter={10}>
          <Col xs={24} className='header'>
            <Typography.Title level={2}>
              {i18n.t('form__edit__academic__info')}
            </Typography.Title>
          </Col>

          <Col xs={24}>
            <Form.Item label={i18n.t('profile__level_of_studies')}>
              {getFieldDecorator('career_type', {
                rules: [
                  {
                    required: true,
                    message: i18n.t('form__type_of_career__error'),
                  },
                ],
                onChange: (value) => setState({ ...state, career_type: value }),
              })(
                <Select placeholder={i18n.t('form__type_of_career')}>
                  {careerTypeOptions.map((opt, index) => (
                    <Option key={opt.value}>
                      {opt.label}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item name='career_name' label={i18n.t('form__select__career')}>
              {getFieldDecorator('career_name', {
                rules: [
                  {
                    required: true,
                    message: i18n.t('form__academic__modal__career__error'),
                  },
                  { validator: validateCareerName },
                ],
              })(
                <CreatableSelect
                  placeholder={i18n.t('form__select__career')}
                  isClearable
                  isLoading={state.isLoadingOptions}
                  noOptionsMessage={() =>
                    i18n.t('commons__career_options')
                  }
                  onInputChange={(value) => handleInputChange(value, 'careers')}
                  // formatCreateLabel={formatCreateLabel}
                  onChange={(e) => handleChangeCareer(e)}
                  onCreateOption={handleCreate}
                  options={state.options}
                  lang={i18n.language.split('-')[0]}
                />
              )}
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item name='career_institution' label={i18n.t('profile__institution')}>
              {getFieldDecorator('career_institution', {
                rules: [
                  {
                    required: true,
                    message: i18n.t('commons__university_options'),
                  },
                  { validator: validateInstitution },
                ],
              })(
                <CreatableSelect
                  initialValue={state.career_institution}
                  lang={i18n.language.split('-')[0]}
                  placeholder={i18n.t('form__institution__error')}
                  isClearable
                  isLoading={state.isLoadingOptionsUniversities}
                  noOptionsMessage={() =>
                    i18n.t('commons__university_options')
                  }
                  // formatCreateLabel={this.formatCreateLabel}
                  onInputChange={(value) =>
                    handleInputChange(value, 'universities')
                  }
                  onChange={(e) => handleChangeUniversity(e)}
                  onCreateOption={handleCreateUniversity}
                  options={state.optionsUniversities}
                />
              )}
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item label={i18n.t('form__area')}>
              {getFieldDecorator('career_area', {
                rules: [
                  { required: true, message: i18n.t('form__area__error') },
                ],
                onChange: (value) => setState({ ...state, career_area: value }),
              })(
                <Select
                  placeholder={i18n.t('form__area')}
                  showSearch
                  optionFilterProp='children'
                  notFoundContent={i18n.t('commons__no__results')}
                  filterOption={(input, option) => {
                    if (option.props.value) {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    } else {
                      return false;
                    }
                  }}
                >
                  {careerAreaOptions.map(({ groupName, groupLabels }) => (
                    <OptGroup key={groupName} label={groupName}>
                      {groupLabels.map((item, index) => (
                        <Option key={index + item.value} value={item.value}>
                          {item.label}
                        </Option>
                      ))}
                    </OptGroup>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item label={i18n.t('commons__status')}>
              {getFieldDecorator('career_state', {
                rules: [
                  { required: true, message: i18n.t('form__status__error') },
                ],
                onChange: (value) =>
                  setState({ ...state, career_state: value }),
              })(
                <Select placeholder={i18n.t('commons__status')}>
                  {careerStateOptions.map((opt, index) => (
                    <Option key={opt.value}>
                      {opt.label}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item label={i18n.t('form__start_year')}>
              {getFieldDecorator('career_entry_year', {
                rules: [
                  {
                    required: true,
                    message: i18n.t('form__start_date__error'),
                  },
                  { validator: validateToNextYear },
                ],
                onChange: (value) =>
                  setState({ ...state, career_entry_year: value }),
              })(
                <Select 
                  placeholder={i18n.t('commons__year')}
                  showSearch
                  optionFilterProp='children'
                  notFoundContent={i18n.t('commons__no__results')}
                >
                  {years.slice(-71).map(opt => (
                    <Option key={opt}>
                      {opt}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item label={i18n.t('form__end_year')}>
              {getFieldDecorator('career_last_year', {
                rules: [
                  {
                    required: true,
                    message: i18n.t('form__end_date__error'),
                  },
                  { validator: compareToFirstYear },
                ],
                onChange: (value) =>
                  setState({ ...state, career_last_year: value }),
              })(
                <Select 
                  placeholder= {i18n.t('commons__year')}
                  showSearch
                  optionFilterProp='children'
                  notFoundContent={i18n.t('commons__no__results')}
                  onBlur={(e) => handleConfirmBlur(e)}
                >
                  {years.map(opt => (
                    <Option key={opt}>
                      {opt}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col xs={24} className='footer'>
            <Button type='default' onClick={() => setOpenModal(false)}>
              {i18n.t('commons__cancel')}
            </Button>
            <Button
              htmlType='submit'
              type='primary'
              style={{ marginLeft: '10px' }}
            >
              {i18n.t('commons__save')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

const EditCareerModal = Form.create({ name: 'career-modal' })(EditCareerForm);
export default EditCareerModal;
