import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'react-router-redux';

import * as actionCreators from '../../actions/data';

import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Icon,
  Input,
  Layout,
  message,
  Modal,
  Radio,
  Row,
  Tabs,
  Typography,
} from 'antd';
import './references.scss';

import ReferenceQuestionForm from './ReferenceQuestion';

// import referenceQuestions from './utils/defaultReferences';

const { Title, Text, Paragraph } = Typography;
const { TabPane } = Tabs;

function ReferenceTemplateForm(props) {
  const [editing, setEditing] = useState(false);
  const [templateName, setTemplateName] = useState();
  const [templateDescription, setTemplateDescription] = useState();
  const [questions, setQuestions] = useState([
    {
      type: 'singleline',
      text: '',
      mandatory: true,
      configuration: {
        alternatives: []
      },
      position: 0,
    },
    {
      type: 'singleline',
      text: '',
      mandatory: true,
      configuration: {
        alternatives: []
      },
      position: 1,
    },
    {
      type: 'singleline',
      text: '',
      mandatory: true,
      configuration: {
        alternatives: []
      },
      position: 2,
    },
  ]);
  const [isPublic, setIsPublic] = useState(false);

  useEffect(() => {
    const business = props.business;
    const templateId = props.match.params.templateId;
    if (templateId) {
      const url = `/api/v1/gamesandtests/business/${business.id}/referencetemplates/${templateId}/`;
      props.actions.fetchAndReturn(props.token, url)
        .then(
          (response) => {
            setEditing(true);
            setIsPublic(response.body['public'])
            setTemplateName(response.body['name']);
            setTemplateDescription(response.body['description']);
            if (response.body['questions'].length){
              setQuestions(response.body['questions'].sort((a, b) => (a.position > b.position) ? 1 : -1));
            }
          }
        );
    }
  }, [props.match.params.templateId])

  const handleQuestionInputChange = (i, input) => {
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      const updatedQuestion = {
        ...prevQuestions[i],
        text: input,
      };
      newQuestions[i] = updatedQuestion;
      return newQuestions;
    })
  };

  const handleQuestionTypeChange = (i, type) => {
    setQuestions((prevQuestions) => {
      if (!prevQuestions[i].type.includes('selection') && type.includes('selection')) {
        prevQuestions[i].configuration.alternatives = ['', '', ''];
      } else if (prevQuestions[i].type.includes('selection') && !type.includes('selection')) {
        prevQuestions[i].configuration.alternatives = [];
      }
      prevQuestions[i].type = type;
      return [...prevQuestions];
    });
  };

  const addAlternativeInput = (i) => {
    setQuestions((prevQuestions) => {
      prevQuestions[i].configuration.alternatives.push('');
      return [...prevQuestions];
    })
  };

  const deleteAlternative = (i, alternativeIndex) => {
    setQuestions((prevQuestions) => {
      const prevQuestion = {...prevQuestions[i]};
      const prevConfig = {...prevQuestion.configuration};
      const prevAlternatives = [...prevConfig.alternatives];
      prevAlternatives.splice(alternativeIndex, 1);
      prevConfig.alternatives = [...prevAlternatives];
      prevQuestion.configuration = {...prevConfig};
      prevQuestions[i] = {...prevQuestion};
      return [...prevQuestions];
    });
  };

  const duplicateAlternative = (i, alternativeIndex) => {
    setQuestions((prevQuestions) => {
      const alternatives = [...prevQuestions[i].configuration.alternatives];
      alternatives.splice(alternativeIndex+1, 0, alternatives[alternativeIndex]);
      prevQuestions[i].configuration.alternatives = [ ...alternatives ];
      return [...prevQuestions];
    });
  };

  const handleQuestionAlternatives = (i, alternativeIndex, alternative) => {
    setQuestions((prevQuestions) => {
      prevQuestions[i].configuration.alternatives[alternativeIndex] = alternative;
      return [...prevQuestions];
    });
  };

  const handleDuplicateQuestion = (i) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [];
      for (let index = 0; index < i + 1; index++) {
        updatedQuestions.push({...questions[index]});
      }
      const newQuestion = {...prevQuestions[i]};
      newQuestion.position++;
      updatedQuestions.push(newQuestion);
      for (let index = i+1; index < prevQuestions.length; index++) {
        const question = {...questions[index]};
        question.position++;
        updatedQuestions.push({...question});
      }
      return [...updatedQuestions];
    });
  };

  const handleRemoveQuestion = (i) => setQuestions((questions) => {
    const updatedQuestions = [];
    for (let index = 0; index < i; index++) {
      updatedQuestions.push(questions[index]);
    }
    for (let index = i+1; index < questions.length; index++) {
      const question = questions[index];
      question.position = question.position - 1;
      updatedQuestions.push(question);
    }
    return updatedQuestions;
  });

  const saveTemplate = () => {
    message.loading(`${editing ? 'Modificando' : 'Creando'} plantilla...`);
    const url = `/api/v1/gamesandtests/business/${props.business.id}/referencetemplates/${editing ? `${props.match.params.templateId}/` : ''}`;
    const body = {
      name: templateName,
      description: templateDescription,
      questions: questions.filter(question => question.text !== '')
    };
    const method = editing ? 'PATCH' : 'POST';
    props.actions.fetchAndReturn(props.token, url, method, body)
      .then(response => {
        message.destroy();
        if (response.status >= 400) {
          Modal.error({
            title: `No fue posible ${editing ? 'modificar' : 'crear'} la plantilla`,
            okText: 'Salir',
            onOk: () => {props.dispatch(push('/references'))}
          });
        } else {
          Modal.success({
            title: `¡Plantilla ${editing ? 'modificada' : 'creada'} con éxito!`,
            closable: true,
            okText: 'Finalizar',
            onOk: () => {props.dispatch(push('/references'))},
          })
        }
      })
      .catch(() => {
        message.destroy();
        Modal.error({
          title: `No fue posible ${editing ? 'modificar' : 'crear'} la plantilla`,
          okText: 'Salir',
          onOk: () => {props.dispatch(push('/references'))}
        });
      });
  };

  return (
    <Layout className='reference-templates-form'>
      <Row style={{ margin: '10px 0px 20px 0px' }}>
        <Breadcrumb separator='>'>
          <Breadcrumb.Item>
            <Link to='/home'>Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to='/tools'>Mis Herramientas</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to='/tools'>Herramientas de Evaluación</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to='/references'>Referencias laborales</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Crear plantilla</Breadcrumb.Item>
        </Breadcrumb>
      </Row>

      <Row
        type='flex'
        align='middle'
        className='reference-templates-form-title'
      >
        <Link to='/references'>
          <Icon type='arrow-left' />
        </Link>
        <Title level={2}>
          {`${editing ? 'Editar' : 'Crear'}`} plantilla
        </Title>
      </Row>
  
      <Row>
        <Paragraph>
          Desde esta sección podrás editar una plantilla y establecer
          las preguntas que solicites para las referencias de tus candidatos.
        </Paragraph>
      </Row>

      <Row className='reference-templates-form-tabs'>
        <Tabs
          className='reference-templates'
          defaultActiveKey='1'
        >
          {!isPublic && (
            <TabPane
              tab='Vista de Edición'
              key='1'
            >
              <Row className='form-content' key={`${questions.length}`}>
                <Row
                  gutter={[0, 16]}
                  style={{ marginBottom: '35px', marginTop: '8px' }}
                >
                  <Text strong>Nombre de la plantilla</Text>
                  <Input
                    style={{ marginTop: '12px' }}
                    onChange={(e) => setTemplateName(e.target.value)}
                    value={templateName}
                  />
                </Row>

                <Row
                  gutter={[0, 16]}
                  style={{ marginBottom: '35px' }}
                >
                  <Text strong>Descripción</Text>
                  <Input.TextArea
                    style={{ marginTop: '12px' }}
                    onChange={(e) => setTemplateDescription(e.target.value)}
                    value={templateDescription}
                  />
                </Row>

                <Row
                  style={{ marginBottom: '12px' }}
                  type='flex'
                  justify='space-around'
                  align='middle'
                >
                  <Col span={16}>
                    <Text strong>Preguntas</Text>
                  </Col>
                  <Col span={6}>
                    <Text strong>Tipo de respuesta</Text>
                  </Col>
                  <Col span={2}>
                    <Text strong>Acciones</Text>
                  </Col>
                </Row>

                {/* TODO: DRAG AND DROP */}
                {questions.map((question, i) => (
                  <ReferenceQuestionForm
                    idx={i}
                    key={`${i}-${question.configuration.alternatives.length}`}
                    question={question}
                    addAlternativeInput={addAlternativeInput}
                    deleteAlternative={deleteAlternative}
                    duplicateAlternative={duplicateAlternative}
                    handleQuestionInputChange={handleQuestionInputChange}
                    handleQuestionTypeChange={handleQuestionTypeChange}
                    handleQuestionAlternatives={handleQuestionAlternatives}
                    handleRemoveQuestion={handleRemoveQuestion}
                    handleDuplicateQuestion={handleDuplicateQuestion}
                  />
                ))}

                <Row
                  className='add-question'
                  style={{ marginTop: '35px' }}
                >
                  <Col
                    onClick={() => setQuestions((questions) => {
                      const defaultQuestion = {
                        type: 'singleline',
                        text: '',
                        mandatory: true,
                        configuration: {
                          alternatives: []
                        },
                        position: questions.length,
                      };
                      return [...questions, defaultQuestion]
                    })}
                  >
                    <Icon type='plus-circle'/>
                    <Text>Agregar pregunta</Text>
                  </Col>
                </Row>
              </Row>
            </TabPane>
          )}
          <TabPane
            tab='Vista Previa'
            key='2'
            disabled={questions.filter(question => question.text != '').length == 0}
          >
            <Row className='form-content'>
              <Row className='reference-templates-form-title'
                style={{ marginBottom: '15px', marginTop: '8px' }}
              >
                <Title level={3}>{templateName}</Title>
              </Row>
              <Row style={{ marginBottom: '15px' }}>
                <Paragraph>{templateDescription}</Paragraph>
              </Row>
              {questions.map((question, i) => (
                <Row
                  key={`${i}-${question.type}-${question.text}`}
                  gutter={[24, 24]}
                  type='flex'
                  justify='space-around'
                >
                  <Col
                    span={24}
                    style={{ paddingRight: '0' }}
                  >
                    <Row type='flex' align='middle'>
                      <Col style={{ marginRight: '5px' }}>
                        {question.position+1}.
                      </Col>
                      <Col>
                        <Text>{question.text}</Text>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '5px' }}>
                      {question.type === 'singleline' && (
                        <Input disabled/>
                      )}
                      {question.type === 'multiline' && (
                        <Input.TextArea disabled />
                      )}
                      {question.type === 'integer' && (
                        <Input disabled style={{ width: '5vw' }}/>
                      )}
                      {question.type.includes('selection') && 
                        question.configuration.alternatives.map((alternativeText) => (
                          <Row>
                            {question.type.includes('single') ? (
                              <Radio disabled>{alternativeText}</Radio>
                            ) : (
                              <Checkbox disabled>{alternativeText}</Checkbox>
                            )}
                          </Row>
                        ))
                      }
                    </Row>
                  </Col>
                </Row>
              ))}
            </Row>
          </TabPane>
        </Tabs>
      </Row>

      <Row style={{ marginTop: '20px' }}>
        <Link to='/references'>
          <Col span={2}>
            <Button>Cancelar</Button>
          </Col>
        </Link>
        <Col span={6}>
          <Button type='primary' onClick={saveTemplate}>Guardar cambios</Button>
        </Col>
      </Row>
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    business: state.auth.currentBusiness,
    businessPermissions: state.auth.businessPermissions,
    token: state.auth.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferenceTemplateForm);
