import React, { Fragment, Component } from 'react';
import { Button, Typography, Modal } from 'antd';
import './Example.scss';

const { Text, Title } = Typography;

class VideoInterviewExample extends Component {

  state = {
    visiblePreview: false
  }

  showPreview = () => {
    this.setState({ visiblePreview: !this.state.visiblePreview });
  }

  render() {
    return (
      <Fragment>
        <Modal
          visible={this.state.visiblePreview}
          onOk={this.showPreview}
          onCancel={this.showPreview}
          className='vi-modal-preview'
          centered={true}
          footer={[
            <Button
              key="back"
              type="primary"
              onClick={this.showPreview}
            >
              Cerrar
            </Button>
          ]}
        >
          <Title level={4}>Esto es lo que verán los candidatos:</Title>

          <Text>
          Te damos la bienvenida "Nombre del candidato"
Has avanzado a la siguiente etapa y en esta instancia tendrás que responder unas preguntas que hemos preparado para conocerte mejor.
Tu video sólo podrá ser visualizado para el cargo que estás postulando.
          </Text>

          <div className="wrap-list">
            <Text>¿Qué esperar?</Text>
            <div className="pd__list">
              <div className="pd__list__item">Cada pregunta tiene un máximo de segundos para responder.</div>
              <div className="pd__list__item">Tendrás solo 1 intento por pregunta.</div>
              <div className="pd__list__item">Podrás leer la pregunta antes de grabar tu respuesta.</div>
            </div>
          </div>

          <div className='img-preview'>
            <img src='https://i.imgur.com/99DLLZ6.png' alt='example' />
          </div>
        </Modal>

        <div className='genomin-message' onClick={this.showPreview}>
          <img src='https://i.imgur.com/T3jmtmX.png' />
        </div>
      </Fragment>
    );
  }
}

export default VideoInterviewExample;