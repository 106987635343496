import React, { useState } from 'react';
import {
  Button,
  Dropdown,
  Icon,
  Menu,
  Tooltip,
} from 'antd'
import i18n from '../../../i18n';

export default function ActionsComponent({
  active,
  handleAction,
  allCandidatesWithEmail,
  activeCandidatesOnly,
  businessPermissions,
  passiveCandidatesOnly,
}) {
  const menu = (
    <Menu onClick={({ item, key }) => handleAction(key)}>
      <Menu.Item key="sendMail" disabled={!allCandidatesWithEmail}>
        <Tooltip title="Todos los candidatos seleccionados deben tener correo para esta funcionalidad">
          <Icon type="mail" /> Enviar un correo
        </Tooltip>
      </Menu.Item>
      <Menu.Item key="addTags" disabled={!activeCandidatesOnly}>
        <Tooltip title="Por el momento no disponible para candidatos pasivos">
          <Icon type="tag" /> Agregar etiquetas
        </Tooltip>
      </Menu.Item>
      <Menu.Item key="addToProcess" disabled={!passiveCandidatesOnly}>
        <Tooltip title="Solo para candidatos pasivos">
          <Icon type="plus" /> Agregar a proceso
        </Tooltip>
      </Menu.Item>
    </Menu>
  );

  return active ? (
    <Button.Group>
      <Tooltip title="Todos los candidatos seleccionados deben tener correo para esta funcionalidad">
        <Button
          disabled={!allCandidatesWithEmail || !businessPermissions.role?.can_manage_userjobapplications} 
          icon='right'
          onClick={() => handleAction('invite')}
          style={{ backgroundColor: '#597EF7', color: 'white' }}
        >
          {i18n.t('profile__invite_passive_candidate', { ns: 'candidateProfile' })}
        </Button>
      </Tooltip>
      {
        businessPermissions.role?.can_manage_userjobapplications &&
        <Dropdown overlay={menu}>
          <Button>
            Más... <Icon type="down" />
          </Button>
        </Dropdown>
      }
    </Button.Group>
  ) : null;
}

ActionsComponent.propTypes = {};
