export const filtersBase = {
  cv_null: null,
  fit_in_range: [0, 100],
  tags: [],
  date_in_range: [],
  career: null,
  experience_position_contains: null,
};

export const isInvalidValue = (value) => {
  return (
    value === '' ||
    value === null ||
    value === undefined ||
    (Array.isArray(value) &&
      (value.length === 0 ||
        value.some((v) => v === null || v === undefined || v === '')))
  );
};

export const clearEmptyKeys = (obj) =>
  Object.entries(obj)
    .filter(([key, value]) => {
      if (isInvalidValue(value)) {
        return false;
      } else {
        return true;
      }
    })
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
