import React from 'react';
import {
  Divider,
  Col,
  Row,
  Progress,
  Typography
} from 'antd';
import Segments from '../../Segments';

const { Text, Title } = Typography;

const Results = ({ scoresConfig, scores }) => {
  const segment = scoresConfig.segments.find((seg) => 
    seg.max >= scores.result && seg.min <= scores.result
  );

  const colorForSegment = {
    'MUY BAJO': 'rgb(255, 77, 79)',
    'BAJO': 'rgb(255, 122, 69)',
    'MEDIO': 'rgb(250, 173, 20)',
    'ALTO': 'rgb(186, 230, 55)',
    'MUY ALTO': 'rgb(82, 196, 26)'
  };
  return (
    <>
      <Title level={4}>Resultados del evaluado</Title>
      <Divider />
      <Row type="flex" justify="center" align="middle">
        <Col span={5}>
          <Progress
            style={{ marginLeft: 20 }}
            percent={scores.result}
            strokeColor={colorForSegment[segment.label]}
            strokeWidth={20}
            strokeLinecap="square"
            type="dashboard"
            gapDegree={126}
          />
          <Text strong style={{ fontSize: 22, display: 'flex', justifyContent: 'center' }}>
            {segment.label}
          </Text>
        </Col>
        <Col span={19}>
          <Segments scoresConfig={scoresConfig} scores={scores}/>
        </Col>
      </Row>
    </>
  )
};

export default Results;