// should use this useBoolean hook (with an api)
import React, { useState, useRef } from 'react';

const useBoolean = (initialValue) => {
  const [bool, setBool] = useState(initialValue);

  const updateBool = useRef({
    toggle: () => setBool((oldBool) => !oldBool),
    on: () => setBool(true),
    off: () => setBool(false),
  });

  return [bool, updateBool.current];
};

export default useBoolean;
