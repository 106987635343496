import React, { useState, useEffect } from 'react'
import {
  Row,
  Button,
  Typography,
  Modal,
  Radio,
  Icon,
  Spin,
} from 'antd';
import propTypes from 'prop-types';
import i18n from '../../../i18n'
import { countryData } from '../utils';

const { Text } = Typography;

/**
 * 
 * @param {String} searchText
 * @param {String} locationCountry
 * @param {String} searchLocation
 * @param {Function} fetchJobApplications
 * @param {Function} setPage
 * @param {Function} setLocationCountry 
 * @returns 
 */

const LocationCountry = ({ searchText, locationCountry, searchLocation, fetchJobApplications, setPage, setLocationCountry, country, countries }) => {
  const [showModal, setShowModal] = useState(false);
  // const [country, setCountry] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);


  const handleChange = (e) => {
    // setCountry(e.target.value)
    setLocationCountry(e.target.value)
  }

  useEffect(() => {
    if (!searchLocation) {
      setLoading(true)
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const geo = position.coords
          if ((geo.latitude >= -53.193351 && geo.latitude <= -18.415480) && (geo.longitude >= -73.00 && geo.longitude <= -69.00)) {
            setLoading(false)
            fetchJobApplications(null, 'CL')
          } else if ((geo.latitude >= -51.961783 && geo.latitude <= -22.89440) && (geo.longitude >= -68.00 && geo.latitude <= -56.691135)) {
            setLoading(false)
            fetchJobApplications(null, 'AR')
        
          } else if ((geo.latitude >= 0.85 && geo.latitude <= 11.556808) && (geo.longitude >= -77.224177 && geo.longitude <= -72.354197)) {
            setLoading(false)
            fetchJobApplications(null, 'CO')
    
          } else if ((geo.latitude >= -18.09 && geo.latitude <= -3.50) && (geo.longitude >= -81.00 && geo.longitude <= -69.924072)) {
            setLoading(false)
            fetchJobApplications(null, 'PE')

          } else if ((geo.latitude >= -34.930633 && geo.latitude <= -30.00) && (geo.longitude >= -56.660 && geo.longitude <= -54.120)) {
            setLoading(false)
            fetchJobApplications(null, 'UY')
   
          } else if ((geo.latitude >= -4.04 && geo.latitude <= -0.828182) && (geo.longitude >= -80.968576 && geo.longitude <= -76.864006)) {
            setLoading(false)
            fetchJobApplications(null, 'EC')
    
          } else if ((geo.latitude >= 8.00 && geo.latitude <= 9.635110) && (geo.longitude >= -82.811971 && geo.longitude <= -77.548131)) {
            setLoading(false)
            fetchJobApplications(null, 'PA')
  
          } else if ((geo.latitude >= -21.550922 && geo.latitude <= -11.025141) && (geo.longitude >= -68.363878 && geo.longitude <= -60.587892)) {
            setLoading(false)
            fetchJobApplications(null, 'BO')

          } else if ((geo.latitude >= -27.346664 && geo.latitude <= -22.291399) && (geo.longitude >= -57.651290 && geo.longitude <= -55.608642)) {
            setLoading(false)
            fetchJobApplications(null, 'PY')

          } else if ((geo.latitude >= 14.863298 && geo.latitude <= 32.5364) && (geo.longitude >= -115.19225 && geo.longitude <= -86.740475)) {
            setLoading(false)
            fetchJobApplications(null, 'MX')
       
          }
        }, e => {
          if (e.code === 1) {
            setLoading(false)
            setError(true)
            fetchJobApplications(searchText, searchLocation)
          }
        })
      }
    }
  }, [])

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  return (
    <Row type='flex' align='middle' className='location-country'>
      {
        loading ?
          (
            <Row type='flex' align='middle' className='flag-text'>
              <Spin size='small' style={{ marginRight: '10px' }}/>
              <Text>Calculando tu ubicación actual... </Text>
            </Row>
          ) : searchLocation ?
            (
              <Row type='flex' align='middle' className='flag-text'>
                { searchLocation === 'LATAM' ? <Icon type='flag' style={{ margin: '0 10px 0 0' }}/> : <img src={`https://genoma-assets.s3.us-east-2.amazonaws.com/flags/${searchLocation}.svg`} /> }
                <Text style={{ margin: '0' }}>
                  { `${i18n.t('job_portal__you__are__watching')} ${country[i18n.language.split('-')[0]]}.`}
                </Text>
              </Row>
            ) :
            (
              <Row type='flex' align='middle' className='flag-text'>
                <Icon type="warning" style={{ color: '#FAAD14', marginRight: '5px', fontSize: '20px' }} />
                <Text>{i18n.t('job_portal__lock__location')}</Text>
              </Row>
            )
      }
      {
        !loading ?
          (
            <Row className='button-modal'>
              <Button type='link' onClick={() => setShowModal(true)}>
                {i18n.t('job_portal__select__country')}
              </Button>
            </Row>
          ) : null
      }

      <Modal
        visible={showModal}
        onCancel={() => setShowModal(!showModal)}
        title={i18n.t('job_portal__select__country')}
        centered
        footer={[
          <Button
            type='primary'
            onClick={() => {
              fetchJobApplications(searchText, locationCountry, 1)
              setShowModal(false)
              setPage(1)
            }}>
            {i18n.t('job_portal__change__country')}
          </Button>
        ]}
      >
        <Radio.Group name='asdasd' onChange={handleChange} value={locationCountry} style={{ width: '100%' }}>
          {Object.keys(countries).map((country, index) => (
            <Row key={index} type='flex' justify='space-between'>
              <Row
                style={{ cursor: 'pointer' }}
                type='flex'
                align='middle'
                onClick={() => setLocationCountry(countries[country].iso_2)}
              >
                {
                  countries[country].iso_2 === 'LATAM' ?
                  <Icon type='flag' style={{ width: '30px', height: '22px' }}/> :
                  <img src={`https://genoma-assets.s3.us-east-2.amazonaws.com/flags/${countries[country].iso_2}.svg`} style={{ width: '30px', height: '22px' }} />
                }
                <Text style={{ marginLeft: '10px', fontSize: '16px' }}>{countries[country].translation[i18n.language.split('-')[0]]}</Text>
              </Row>
              <Radio style={radioStyle} value={countries[country].iso_2} />
            </Row>
          ))}
        </Radio.Group>
      </Modal>
    </Row>
  );
}

LocationCountry.propTypes = {
  searchText: propTypes.string,
  locationCountry: propTypes.string,
  searchLocation: propTypes.string,
  fetchJobApplications: propTypes.func,
  setPage: propTypes.func,
  setLocationCountry: propTypes.func
}

export default LocationCountry;