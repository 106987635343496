import React from 'react';
import { Form, Input, Switch } from 'antd';
import Actions from '../Actions';
import { AppContext } from '../JobForm';

const BusinessInfo = (props) => {
  const { getFieldDecorator } = props.form;

  return (
    <AppContext.Consumer>
      {(value)  => {
        const { contactInfo, confidential } = value.data;
        return (
          <>
            <Form onSubmit={(e) => value.submit(e, props.form, 'contactInfo')}>
              <div className='form-view' id='form-view'>
                <Form.Item label="¿Proceso confidencial?">
                  {getFieldDecorator('confidential', {
                    valuePropName: 'checked',
                    initialValue: Boolean(confidential),
                  })(<Switch />)}
                </Form.Item>

                <Form.Item label="Nombre de la empresa (opcional)">
                  {getFieldDecorator('companyName', {
                    initialValue: contactInfo.companyName
                  })(<Input />)}
                </Form.Item>

                <Form.Item label="Email (opcional)">
                  {getFieldDecorator('email', {
                    initialValue: contactInfo.email
                  })(<Input />)}
                </Form.Item>

                <Form.Item label="Nombre del contacto (opcional)">
                  {getFieldDecorator('contact', {
                    initialValue: contactInfo.contact
                  })(<Input />)}
                </Form.Item>

                <Form.Item label="Teléfono (opcional)">
                  {getFieldDecorator('contactPhone', {
                    initialValue: contactInfo.contactPhone
                  })(
                    <Input />
                  )}
                </Form.Item>
              </div>

              <Actions />
            </Form>
          </>
        )
      }}
    </AppContext.Consumer>
  )
}

const WrappedBusinessInfoForm = Form.create()(BusinessInfo);
export default WrappedBusinessInfoForm;