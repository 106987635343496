import React, { useContext, useEffect, useState } from 'react';
import { Typography } from 'antd';
import WelcomeView from '../../../../JobApplicationProcess/components/NewWelcomeView/index';
import JobAppContext from '../../context/JobAppContext';

function StartProcessContainer() {
  const { business, jobApplication } = useContext(JobAppContext);

  const [processedJobApp, setProcessedJobApp] = useState(
    {
      'description': '',
      'job_application': '',
      'language': '',
      'location_city': '',
      'location_country': '',
      'status': 'ACTIV',
      'welcome_text': '',
      'business': { 'name': '' }
    }
  );
  useEffect((() => {
    setProcessedJobApp(
      {
        'description': jobApplication.description,
        'job_application': jobApplication.name,
        'language': jobApplication.language,
        'location_city': jobApplication.city,
        'location_country': jobApplication.country,
        'status': 'ACTIV',
        'welcome_text': jobApplication.welcomeText,
        'is_confidential': jobApplication.is_confidential,
        'business': {
          'confidential_background_image': business.confidential_background_image,
          'confidential_name': business.confidential_name,
          'name': business.name,
          'business_info': business.businessInfo,
          'location_city': jobApplication.city,
          'location_country': jobApplication.country
        }
      }
    )
  }
  ), [jobApplication]);

  const checkBackgorundImageType = () =>{
    if (typeof(jobApplication.backgroundImage) === 'string' ) {
      return jobApplication.backgroundImage;
    };
    return jobApplication.backgroundImage.b64Img;
  }


  return (
    <>
      <div
        style={{
          padding: 16,
          borderRadius: '8px 8px 0px 0px',
          background: 'white',
        }}
      >
        <Typography.Text>
          Esta es la vista inicial que verán los candidatos en la página de tu
          proceso.
        </Typography.Text>
      </div>

      <WelcomeView
        theme={
          jobApplication.is_confidential
            ? business.confidential_color_theme
            : business.colorTheme
        }
        data={processedJobApp}
        start={() => console.log()}
        handleKnowMore={() => console.log()}
        banner={
          jobApplication.is_confidential
            ? business.confidential_background_image
            : jobApplication.backgroundImage
            ? checkBackgorundImageType()
            : business.backgroundImage
        }
      />
    </>
  );
}

export default StartProcessContainer;
