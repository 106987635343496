import React from 'react'
import { Radio, Typography } from "antd";

const { Text } = Typography;

const CustomRadioButton = ({rateValues, value}) => {
  return (
    <Radio.Group
      id="rating"
      style={{
        marginTop: "1em",
        minWidth: "100%",
      }}
      value={value}
      defaultValue={value}
    >
      <Radio.Button
        style={{ borderRadius: 2, width: "25%" }}
        value={0}
        disabled={true}
      >
        <Text style={{ color: "#D9D9D9" }}>Sin evaluar</Text>
      </Radio.Button>
      {rateValues.map((item) => (
        <Radio.Button
          key={item["key"]}
          disabled={true}
          style={{
            borderRadius: 2,
            textAlign: "center",
            width: "15%",
            border:
              value >= item["value"]
                ? `1px solid ${rateValues[value - 1]["color"]}`
                : '1px solid #D9D9D9',
            backgroundColor:
              value >= item["value"]
                ? rateValues[value - 1]["background"]
                : "#FFFFFF",
          }}
          value={item["value"]}
        >
          <Text
            style={{
              color:
                value >= item["value"]
                  ? rateValues[value - 1]["color"]
                  : "#D9D9D9",
            }}   
          >
            {item["value"]}
          </Text>
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};
export default CustomRadioButton;