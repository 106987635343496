import React from 'react';

import {
  Radio,
  Form,
  Row,
  Typography,
  Select,
} from 'antd';


const hasCorret = (alternatives) => {
  let hasCorrect = false
  alternatives.forEach(alt => {
    if (alt.correct) {
      hasCorrect = true
    }
  })
  return hasCorrect
}

const SingleSelection = props => {
  const { question, error, index, answer, alternatives, disabled, mandatory } = props
  const hasCorrectAnswer = hasCorret(alternatives)
  let color = '#757892'
  if (hasCorrectAnswer && disabled) {
    color = alternatives[parseInt(answer)].correct ? '#757892' : 'red'
  }

  return (
    <div>
      <Form.Item
        name={"multiselection"}
        label={
          <Typography.Text style={{ whiteSpace: 'normal' }}>
            {mandatory && <Typography.Text style={{ color: 'red' }}>* </Typography.Text>}
            {question}
          </Typography.Text>
        }
        labelAlign="left"
      >
        {
          alternatives.length <= 6 ?
            <Radio.Group disabled={disabled}>
              {alternatives.map((alt, i) => {
                return (
                  <Row key={i}>
                    <Radio
                      value={i.toString()}
                      onChange={event => props.handleChangeV2(index, event.target.value)}
                    >
                      {alt.text}
                    </Radio>
                  </Row>
                )
              })}
            </Radio.Group>
            :
            <Select disabled={disabled} placeholder="Selecciona una alternativa" style={{ minWidth: 300 }} onChange={value => props.handleChangeV2(index, value)}
            >
              {alternatives.map((alt, i) => {
                return (
                  <Select.Option
                    style={{ whiteSpace: 'normal', height: 'auto' }}
                    key={i}
                  >
                    {alt.text}
                  </Select.Option>
                )
              })}
            </Select>
        }
        {error &&
          <Typography style={{ color: 'red', marginTop: 3, fontSize: 12 }}>
            Tienes que responder esta pregunta para continuar.
          </Typography>
        }
      </Form.Item>
    </div>
  )
}

export default SingleSelection;