import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Typography,
  Icon,
  Input,
  Row,
  Tooltip,
  Result,
} from "antd";

import "./AtsIntegration.scss";
import { isMobile } from "react-device-detect";
const { Search } = Input;

const GetToken = ({
  createTokenFn,
  businessToken,
  onCopyTokenFn,
  loading,
  notInfo,
  deleteTokenFn,
  setCreateNewToken,
}) => {
  const [keyword, setKeyword] = useState("");
  const [accessTokensFiltered, setAccessTokensFiltered] =
    useState(businessToken);

  const getDays = (expirationDate) => {
    var parts = expirationDate.split("/");
    var expirationDateParse = new Date(
      parseInt(parts[2], 10),
      parseInt(parts[1], 10) - 1,
      parseInt(parts[0], 10)
    );
    let exp = new Date(expirationDateParse);
    let now = Date.now();
    let dayAsMilliseconds = 86400000;
    let diffInMillisenconds = exp - now;
    let diff_in_days = Math.ceil(diffInMillisenconds / dayAsMilliseconds);
    return diff_in_days;
  };
  useEffect(() => {
    setAccessTokensFiltered(businessToken);
  }, [businessToken]);

  const onChange = (newValue) => {
    setKeyword(newValue.target ? newValue.target.value.toLowerCase() : "");
    const value = newValue.target
      ? newValue.target.value.toLowerCase()
      : newValue.toLowerCase();
    const tokens = businessToken.reduce((tokens, token) => {
      if (
        token.name.toLowerCase().includes(value) ||
        token.token.toLowerCase().includes(value)
      ) {
        tokens.push(token);
      }
      return tokens;
    }, []);
    setAccessTokensFiltered(tokens);
  };

  return (
    <Row>
      <Row type={"flex"} gutter={[0, 24]} style={{ marginBottom: "0.5em" }}>
        <Col
          sm={12}
          md={12}
          lg={12}
          style={{ display: "flex", justifyContent: "start" }}
        >
          <Search
            placeholder="Buscar clave"
            onChange={(e) => {
              setAccessTokensFiltered([]);
              onChange(e);
            }}
            enterButton={
              <Button style={{ fontSize: 12 }} icon="search"></Button>
            }
            onSearch={onChange}
            style={{
              width: 220,
            }}
            value={keyword}
          />
        </Col>
        <Col sm={12} md={12} lg={12} justify="end" align="right">
          <Button
            type="primary"
            //createTokenFn
            onClick={() => {
              setCreateNewToken(true);
            }}
            loading={loading}
            icon="plus"
          ></Button>
        </Col>
      </Row>
      <div style={{ overflowY: "scroll", height: "15em" }}>
        {notInfo && <Result status="warning" title="Usted no tiene claves" />}{" "}
        {!!accessTokensFiltered.length &&
          accessTokensFiltered.map(
            ({ name, id, expiration_date, token }, i) => (
              <Row type={"flex"} align="middle" className="tokens-table-row">
                <Col sm={3} md={3} lg={4}>
                  <div
                    className={
                      !isMobile ? "token-circle" : "token-circle-mobile"
                    }
                  >
                    <div>
                      <Icon
                        type={"key"}
                        style={
                          !isMobile
                            ? { fontSize: "25px", color: "#F175A5" }
                            : { fontSize: "15px", color: "#F175A5" }
                        }
                      />
                    </div>
                  </div>
                </Col>
                <Col sm={16} md={16} lg={16}>
                  <Row type="flex" align="bottom">
                    <Typography.Text
                      ellipsis
                      strong
                      style={
                        !isMobile
                          ? { fontSize: "12px" }
                          : { fontSize: "11px", marginLeft: "0.5em" }
                      }
                    >
                      {name}
                    </Typography.Text>
                    <Typography.Text
                      type="secondary"
                      className="expiration-date-text"
                    >
                      Expira en {getDays(expiration_date)} días
                    </Typography.Text>
                  </Row>
                  <Row type="flex">
                    <Typography.Text
                      type="secondary"
                      ellipsis={false}
                      className="token-text"
                      style={
                        isMobile
                          ? { marginLeft: "0.5em" }
                          : { fontSize: "11px" }
                      }
                    >
                      {token}
                    </Typography.Text>
                  </Row>
                </Col>

                <Col sm={2} md={2} lg={2} justify="end">
                  <Tooltip placement="top" title="Copiar al portapapeles">
                    <Icon
                      className="copy-integration-icon"
                      type="copy"
                      onClick={() => onCopyTokenFn(token)}
                    />
                  </Tooltip>
                </Col>
                <Col sm={2} md={2} lg={2} justify="end">
                  <Tooltip placement="top" title="Eliminar Clave de acceso">
                    <Icon
                      className="copy-integration-icon"
                      type="delete"
                      onClick={() => deleteTokenFn(id)}
                    />
                  </Tooltip>
                </Col>
              </Row>
            )
          )}
      </div>
    </Row>
  );
};

export default GetToken;
