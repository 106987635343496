import React, { useContext } from 'react';
import { Icon, Modal, Row, Col, Typography, Tag, Dropdown, Menu } from 'antd';
import ParseHtml from '../../../../components/parseHtml';
import MailTemplatesContext from '../context/mailTemplates';

import '../template-mails.scss';

const { Text } = Typography;
const { Item } = Menu;

const PreviewModal = () => {
  const { previewModal } = useContext(MailTemplatesContext);
  const {
    categories,
    setOpenPreviewModal,
    createMailTemplateFn,
    openPreviewModal,
    templateToPreview,
  } = previewModal;

  return (
    <Modal
      title='Previsualizar plantilla'
      visible={openPreviewModal}
      width='56.188rem'
      okText='Volver'
      cancelText={
        <>
          <Dropdown
            placement='topRight'
            overlay={
              <Menu>
                {categories.data &&
                  categories.data.map(({ name, id }) => (
                    <Item
                      key={id}
                      onClick={() => {
                        createMailTemplateFn(true, id);
                      }}
                    >
                      {name}
                    </Item>
                  ))}
              </Menu>
            }
            trigger={['click']}
            overlayClassName='template-container__dropdown-menu'
          >
            <div onClick={(e) => e.preventDefault()}>
              <Icon type='copy' />
              Duplicar
            </div>
          </Dropdown>
        </>
      }
      centered={true}
      onOk={() => setOpenPreviewModal(false)}
    >
      <Row>
        <Row className='template-container__modal-preview-row'>
          <Col>
            <Text strong>Nombre de la plantilla</Text>
          </Col>
          <Col>
            <Text>{templateToPreview.name}</Text>
          </Col>
        </Row>
        <Row className='template-container__modal-preview-row'>
          <Col>
            <Text strong>Asunto</Text>
          </Col>
          <Col>
            <Text>{templateToPreview.subject}</Text>
          </Col>
        </Row>
        <Row className='template-container__modal-preview-row'>
          <Col>
            <Text strong>Texto</Text>
          </Col>
          <Col>{ParseHtml(templateToPreview.template)}</Col>
        </Row>
        <Row className='template-container__modal-preview-row'>
          <Col>
            <Text strong>Etiquetas</Text>
          </Col>
          <Col>
            {templateToPreview.tags &&
              previewModal?.templateToPreview.tags.map((tag, index) => (
                <Tag key={index}>{tag}</Tag>
              ))}
          </Col>
        </Row>
      </Row>
    </Modal>
  );
};

export default PreviewModal;
