import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../../actions/data';
import { SERVER_URL } from '../../../../utils/config';

const MceEditor = (props) => {

  const handleEditorChange = (text) => {
    props.handleChange(props.name)({ target: { value: text } })
  }

  return (
    <div>
      <Editor
        value={props.text}
        disabled={props.disabled}
        init={{
          placeholder: props.placeholder,
          entity_encoding: "raw",
          height: props.height ? props.height : 200,
          branding: false,
          menubar: false,
          elementpath: false,
          language: 'es',
          images_upload_url: `${SERVER_URL}/api/v1/gamesandtests/fileupload/`,
          plugins: "lists,anchor,link,image,code,visualchars",
          toolbar: props.toolbar??
            "fontsizeselect | forecolor | backcolor | bold | italic | underline | alignleft | aligncenter | link | undo | redo | visualchars | image | code"
        }}
        onEditorChange={handleEditorChange}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    data: state.data.data,
    isFetching: state.data.isFetching,
    error: state.data.error,
    alerts: state.alerts
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MceEditor);
