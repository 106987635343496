import React, { Fragment } from 'react';
import { Form, Input, Select } from 'antd';

const FileForm = ({ getFieldDecorator }) => (
  <Fragment>
    <Form.Item label='Nombre del documento'>
      {getFieldDecorator('fileName', {
        rules: [
          {
            required: true,
            message: "Por favor escribe el nombre del documento",
          },
        ],
      })(<Input />)}
    </Form.Item>

    <Form.Item label='Descripción del documento'>
      {getFieldDecorator('fileDescription', {
      })(
        <Input />
      )}
    </Form.Item>

    <Form.Item label='Tipos de archivos admitidos'>
      {getFieldDecorator('fileType', {
        initialValue: ['PDF']
      })(
        <Select mode="multiple" style={{ width: '100%', maxWidth: '100%' }}>
          {[
            { ext: 'JPG', type: 'Imagen' },
            { ext: 'JPEG', type: 'Imagen' },
            { ext: 'PNG', type: 'Imagen' },
            { ext: 'TIFF', type: 'Imagen' },
            { ext: 'BMP', type: 'Imagen' },
            { ext: 'PDF', type: 'Documento' },
            { ext: 'DOC', type: 'Documento' },
            { ext: 'DOCX', type: 'Documento' },
            { ext: 'XPS', type: 'Documento' },
          ].map((extension) => (
            <Select.Option key={extension['ext']} value={extension['ext']}>
              {(extension['ext'] + ' (' + extension['type'] + ')')}
            </Select.Option>
          ))}
        </Select>
      )}
    </Form.Item>
  </Fragment>
)

export default FileForm;