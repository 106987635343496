import React from 'react';

import BreadcrumPage from '../../../pdfCommons/Breadcrum';
import FooterPage from '../../../pdfCommons/FooterPage';

const Page1 = ({ candidateInfo, jobName, score }) => {
  return (
    <main className='dat-pdf-modal__mainsection__page1'>
      <section className='dat-pdf-modal__mainsection__page1__header'>
        <BreadcrumPage candidateInfo={candidateInfo} jobName={jobName} />
        <div className='dat-pdf-modal__mainsection__page1__header__candidateinformation'>
          <div>
            <h5>{candidateInfo.user_name}</h5>
            <p>Proceso: {jobName}</p>
            <p>DNI O RUT: <strong>{candidateInfo.dni}</strong></p>
            <p>Fecha de realización: <strong>{candidateInfo.date_completed}</strong></p>
          </div>
          <div>
            <img src='https://genoma-assets.s3.us-east-2.amazonaws.com/logo.svg' />
          </div>
        </div>
      </section>

      <hr />

      <section className='dat-pdf-modal__mainsection__page1__description'>
        <div className='dat-pdf-modal__mainsection__page1__description__title'>
          <img src='https://genoma-assets.s3.us-east-2.amazonaws.com/creativity_icon.svg' />
          <h6>Reporte Diccionario</h6>
        </div>
        <p className='dat-pdf-modal__mainsection__page1__description__text'>
          Este test evalúa la creatividad verbal y el pensamiento divergente. El evaluado debe escribir palabras que sean distantes a nivel semántico entre sí (es decir, en uso y contexto). Esta “distancia” es transformada de forma automática y objetiva en un puntaje, clasificando al evaluado en una de tres posibles categorías en base a su nivel obtenido: <span style={{ color: '#FF4D4F' }}>Bajo</span>, <span style={{ color: '#FAAD14' }}>Promedio</span> y <span style={{ color: '#52C41A' }}>Alto</span>.
        </p>
      </section>

      <hr />

      <section className='dat-pdf-modal__mainsection__page1__scores'>
        <h6>Resultado del evaluado(a)</h6>
        <main className='dat-pdf-modal__mainsection__page1__scores__graphics'>

          {
            score >= 0 && score <= 72 ?
              (
                <div className='dat-pdf-modal__mainsection__page1__scores__graphics__low'>
                  <div className='dat-pdf-modal__mainsection__page1__scores__graphics__low__up' style={{ background: '#FF4F4F' }}>
                    <h4 style={{ color: 'white', margin: 0 }}>
                      {score.toFixed(0)} puntos
                    </h4>
                  </div>
                  <div className='dat-pdf-modal__mainsection__page1__scores__graphics__low__below'>
                    <p>Bajo: 0 al 72</p>
                  </div>
                </div>
              ) :
              (
                <div className='dat-pdf-modal__mainsection__page1__scores__graphics__low'>
                  <div className='dat-pdf-modal__mainsection__page1__scores__graphics__low__up' />
                  <div className='dat-pdf-modal__mainsection__page1__scores__graphics__low__below'>
                    <p>Bajo: 0 al 72</p>
                  </div>
                </div>
              )
          }

          {
            score >= 73 && score <= 83 ?
              (
                <div className='dat-pdf-modal__mainsection__page1__scores__graphics__intermediate'>
                  <div className='dat-pdf-modal__mainsection__page1__scores__graphics__intermediate__up' style={{ background: '#FAAD14' }}>
                    <h4 style={{ color: 'white', margin: 0 }}>
                      {score.toFixed(0)} puntos
                    </h4>
                  </div>
                  <div className='dat-pdf-modal__mainsection__page1__scores__graphics__intermediate__below'>
                    <p>Promedio: 73 al 83</p>
                  </div>
                </div>
              ) :
              (
                <div className='dat-pdf-modal__mainsection__page1__scores__graphics__intermediate'>
                  <div className='dat-pdf-modal__mainsection__page1__scores__graphics__intermediate__up' />
                  <div className='dat-pdf-modal__mainsection__page1__scores__graphics__intermediate__below'>
                    <p>Promedio: 73 al 83</p>
                  </div>
                </div>
              )
          }
          {
            score >= 84 ?
              (
                <div className='dat-pdf-modal__mainsection__page1__scores__graphics__high'>
                  <div className='dat-pdf-modal__mainsection__page1__scores__graphics__high__up' style={{ background: '#52C41A' }}>
                    <h4 style={{ color: 'white', margin: 0 }}>
                      {score.toFixed(0)} puntos
                    </h4>
                  </div>
                  <div className='dat-pdf-modal__mainsection__page1__scores__graphics__high__below'>
                    <p>Alto: 84 al 100+</p>
                  </div>
                </div>
              ) :
              (
                <div className='dat-pdf-modal__mainsection__page1__scores__graphics__high'>
                  <div className='dat-pdf-modal__mainsection__page1__scores__graphics__high__up' />
                  <div className='dat-pdf-modal__mainsection__page1__scores__graphics__high__below'>
                    <p>Alto: 84 al 100+</p>
                  </div>
                </div>
              )
          }
        </main>
      </section>

      <section className='dat-pdf-modal__mainsection__page1__results'>
        <div className='dat-pdf-modal__mainsection__page1__results__high'>
          <div className='dat-pdf-modal__mainsection__page1__results__high__title'>
            <p>Alto:</p>
            <p>84 al 100+</p>
          </div>
          <div className='dat-pdf-modal__mainsection__page1__results__high__content'>
            <p>
              Un nivel alto significa que la persona tiene una creatividad verbal desarrollada y que es capaz de pensar en una variedad de soluciones originales a la hora de enfrentar un problema abierto, pudiendo generar ideas poco relacionadas entre sí.
            </p>
          </div>
        </div>
        <div className='dat-pdf-modal__mainsection__page1__results__intermediate'>
          <div className='dat-pdf-modal__mainsection__page1__results__intermediate__title'>
            <p>Promedio:</p>
            <p>73 al 83</p>
          </div>
          <div className='dat-pdf-modal__mainsection__page1__results__intermediate__content'>
            <p>
              Un nivel promedio significa que la persona tiene una creatividad verbal adecuada y que es capaz de pensar en soluciones satisfactorias a la hora de enfrentar un problema abierto, pudiendo generar ideas que presentan algún grado de relación entre sí.
            </p>
          </div>
        </div>
        <div className='dat-pdf-modal__mainsection__page1__results__low'>
          <div className='dat-pdf-modal__mainsection__page1__results__low__title'>
            <p>Bajo:</p>
            <p>0 al 72</p>
          </div>
          <div className='dat-pdf-modal__mainsection__page1__results__low__content'>
            <p>
              Un nivel bajo significa que la persona tiene una creatividad verbal limitada y que es capaz de pensar en soluciones acotadas a la hora de enfrentar un problema abierto, pudiendo generar ideas que presentan un alto grado de relación entre sí.
            </p>
          </div>
        </div>
      </section>

      <FooterPage />
    </main>
  );
}

export default Page1;