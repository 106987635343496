import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

const verticalLane =
  'https://genoma-assets.s3.us-east-2.amazonaws.com/verticalLane.svg';
const pinkDot = 'https://genoma-assets.s3.us-east-2.amazonaws.com/pinkDot.svg';

const ListItem = ({ key, text, sub }) => {
  return (
    <div className="new-modal-list-item" key={key}>
      <img src={pinkDot} alt="pink dot" />
      <img
        src={verticalLane}
        alt="vertical lane svg"
        className="new-modal-list-item__lane-svg"
      />
      <div>
        <Text strong>{text}</Text>
        <Text>{sub}</Text>
      </div>
    </div>
  );
};

export default ListItem;
