import React from 'react';
import { Row, Col } from 'antd';
import ProfilesPDF from './ProfilesPDF';
import PersonalityPDF from './PersonalityPDF';

import '../../pdf-css.scss';

function FourthPagePdf({ candidates }) {
  return (
    <Row>
      <Row>
        <Col style={{ marginTop: '48px' }}>
          <ProfilesPDF candidates={candidates} />
        </Col>
      </Row>
      <Row className='dividers'>
        <p>Personalidad</p>
      </Row>
      <Row>
        <Col>
          <PersonalityPDF candidates={candidates} />
        </Col>
      </Row>
      <Row style={{ minHeight: 45 }} />
    </Row>
  );
}

export default FourthPagePdf;
