import React, { useState, useRef, useEffect } from 'react';
import { Row, Modal, Typography, Icon, Upload, Spin, Button, Col } from 'antd';
import i18n from '../../../i18n';

const { Title, Text } = Typography;

export default function UploadCv({ setPersonalData, personalData }) {
  const iframeRef = useRef();
  const [iframeIntervalId, setIframeIntervalId] = useState(null);
  const [cvUrl, setCvUrl] = useState(personalData.cv);
  const [loading, setLoading] = useState(true);
  const [visiblePreviewModal, setVisiblePreviewModal] = useState(false);

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const handleOk = () => {
    setVisiblePreviewModal(false);
  };

  const handleChangeCV = (file) => {
    if (file.fileList.length > 1) {
      file.fileList.shift();
    }
    setPersonalData({ ...personalData, fileList: file.fileList });
  };

  const props = {
    name: 'file',
    accept: '.doc,.docx,.pdf,application/pdf,application/msword',
    listType: 'picture',
    defaultFileList: [...personalData.fileList],
    accept: '.doc,.docx,.pdf,application/pdf,application/msword',
  };

  const embedCV = (fileUrl) => {
    return fileUrl && fileUrl.includes('.pdf')
      ? fileUrl
      : `https://docs.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(
        fileUrl
      )}`;
  };

  useEffect(() => {
    if (personalData?.cv && personalData?.cv !== '') {
      setCvUrl(personalData.cv);
    }
  }, [personalData]);

  useEffect(() => {
    let intervalId;
    if (cvUrl) {
      intervalId = setInterval(() => {
        if (iframeRef.current) {
          iframeRef.current.src = embedCV(cvUrl);
        }
      }, 2000);
      setIframeIntervalId(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [cvUrl]);

  return (
    <Row className='candidate-form-section'>
      <Title level={4}>{i18n.t('form__cv')}</Title>
      <Row>
        <Text>
          {i18n.t('form__cv__extension__file')}
        </Text>
      </Row>
      {personalData?.has_cv && (
        <Row
          onClick={() => setVisiblePreviewModal(true)}
          style={{ margin: '15px 0', cursor: 'pointer' }}
        >
          <Text underline strong>
            {i18n.t('profile__has__cv')}
          </Text>
          <Icon type='check' />
        </Row>
      )}
      <Row type='flex' justify='center' align='middle' className='upload-Cv'>
        <Upload
          onChange={(file) => handleChangeCV(file)}
          customRequest={(response) => dummyRequest(response)}
          {...props}
        >
          <Spin spinning={personalData.loadingFile}>
            <Icon type='cloud-upload' style={{ margin: 10 }} />
            <Text>
              <span className='underline-span'>{i18n.t('form__upload__cv').split('}')[0].slice(1)}</span> 
              {personalData?.has_cv ? i18n.t('profile__edit__cv').split('}')[1] : i18n.t('form__upload__cv').split('}')[1]} 
            </Text>
          </Spin>
        </Upload>
      </Row>
      <Modal
        width={500}
        style
        visible={visiblePreviewModal}
        closable
        centered
        onCancel={handleOk}
        onOk={handleOk}
        footer={[
          <Button key='ok' type='primary' onClick={handleOk}>
            Ok
          </Button>,
        ]}
      >
        <Row type='flex' justify='center' align='middle'>
          <Col xs={24}>
            <Spin size='large' spinning={loading} style={{ width: '100%' }}>
              <iframe
                ref={iframeRef}
                credentialless=''
                id='cv-iframe'
                onLoad={() => {
                  clearInterval(iframeIntervalId);
                  setLoading(false);
                }}
                frameBorder='0'
                src={embedCV(cvUrl)}
                width='100%'
                height='500px'
              />
            </Spin>
          </Col>
        </Row>
      </Modal>
    </Row>
  );
}
