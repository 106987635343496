import React, { useEffect, useContext, useState } from "react";
import { Typography, Row, Col } from 'antd';
import TraitWeight from './TraitWeight'
import JobAppContext from "../../../../context/JobAppContext";

const styles = {
  heading: {
    fontWeight: "300",
    lineHeight: 1.0
  },
}

const { Text } = Typography;

const TraitList = ({ traits, showDistribution }) => {

  const [totalPoints, setTotalPoints] = useState(0);

  const {
    measuredTraits,
  } = useContext(JobAppContext);

  useEffect(() => {
    const total = measuredTraits.current.reduce((acc, obj) => acc + obj.points, 0);
    setTotalPoints(total);
  }, [measuredTraits]);

  return (
    <div className='trait-list-item'>
      <Row type="flex" justify="end">
        <Col xs={20} sm={20} style={{ display: 'flex', justifyContent: 'end' }}>
          {traits.length !== 0 &&
            <div>
              <Row type='flex' align='middle' style={{ marginBottom: 6 }}>
                <Text strong className={styles.heading}>
                  Genoma ideal
                </Text>
              </Row>
              {showDistribution &&
                <Row type='flex' align='middle' justify='space-around'>
                  <img style={{ height: '20px' }} src='https://i.imgur.com/1WKikM2.png' />
                  <Text strong type='secondary' style={{ color: '#FCA0D3' }}>
                    Promedio
                  </Text>
                </Row>
              }
            </div>
          }
        </Col>

        <Col xs={4} sm={4} style={{ display: 'flex', justifyContent: 'end' }}>
          <Text strong className={styles.heading}>
            Relevancia {totalPoints !== 0 ? Math.round(traits.reduce((init, next) => init + (next.points), 0) * 100 / totalPoints) : 0}%
          </Text>
        </Col>
      </Row>
      {traits.length ? (
        <div>
          {traits.map((trait, i) => {
            return <TraitWeight key={i} measuredTrait={trait} showDistribution={showDistribution} totalPoints={totalPoints}/>
          })}
        </div>
      ) : (
        <div className='no-data'>
          <Text>No hay juegos seleccionados para esta categoría</Text>
        </div>
      )}
    </div>
  );
}

export default TraitList;
