import React from 'react';
import './styles-pdf.scss';
import i18n from '../../../../../i18n';

const FooterPage = () => {
  return (
    <div className='pdf-modal__footer'>
      <hr style={{ color: '#D9D9D9'}}/>
        {i18n.t('candidate_pdf__not_authorization_to_share_report', {ns: 'candidatePdf'})}
    </div>
  );
}

export default FooterPage;