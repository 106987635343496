import React from 'react';
import { Row, Col, Typography, Icon } from 'antd';

import useBoolean from '../../../hooks/useBoolean';

const { Text, Title } = Typography;

const Collapse = ({ icon, title, children }) => {
  const [shouldCollapse, handleCollapse] = useBoolean(true);

  return (
    <div className="new-modal-collapse">
      <div
        className="new-modal-collapse__header"
        onClick={handleCollapse.toggle}
      >
        <Icon type={shouldCollapse ? 'right' : 'up'} />
        <Icon
          type={icon}
          className="new-modal-collapse__header__badge"
          style={{ color: '#FFFFFF' }}
        />
        <Text strong>{title}</Text>
      </div>
      {!shouldCollapse ? (
        <div className="new-modal-collapse__content">{children}</div>
      ) : null}
    </div>
  );
};

export default Collapse;
