import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Button, Card, Col, Divider, Icon, Modal, Radio, Row, Tag, Typography } from 'antd';

import ProcessStatusBadge from '../../components/ProcessStatusBadge';
import { CandidatePoolContext } from './config/CandidatePoolContext';

const { Meta } = Card;
const { Text } = Typography;

function CandidateDetail({
  handleShowProfile,
  visible, }) {
  const {
    candidateData,
    handleAddPassiveCandidateToJobApplications,
    handleAddPassiveCandidateVisible,
    loadCandidateData,
    openCandidate: candidate,
    setAddPassiveCandidateVisibleMethod,
    setCandidateData,
    setOpenCandidate, } = useContext(CandidatePoolContext);

  const [selectedUserJobApplication, setSelectedUserJobApplication] = useState({});

  const onCancel = () => {
    setOpenCandidate(null);
    setAddPassiveCandidateVisibleMethod('POST');
  }

  const candidateTag = {
    active: <Tag color="green">Candidato activo</Tag>,
    seed: <Tag color="pink">Candidato clonación</Tag>,
    passive: <Tag color="blue">Candidato pasivo</Tag>,
  };

  useEffect(() => {
    // Cargar los datos antes de hacer el render
    if (!visible) {
      setCandidateData({});
    } else {
      loadCandidateData();
    }
  }, [visible]);

  const moreThanOne = useMemo(() => candidateData?.applications?.length > 1, [candidateData]);

  return (
    <Modal
      bodyStyle={{ padding: 0 }}
      footer={null}
      height={600}
      onCancel={onCancel}
      visible={visible}
      width={973}
    >
      <Row type="flex">
        <Col span={8} style={{ backgroundColor: '#FBF4F8', padding: '1em' }}>
          <Row style={{ flexDirection: 'column' }} type="flex">
            <Col>
              <Row align="middle" type="flex">
                <Col style={{ marginRight: '1em' }}>
                  <Row
                    align="middle"
                    justify="center"
                    type="flex"
                    style={{
                      backgroundColor: '#FFFFFF',
                      border: '1px solid #D9D9D9',
                      borderRadius: '50%',
                      color: '#5E5E5E',
                      fontSize: '27px',
                      height: '2.5em',
                      padding: '0.33em',
                      textAlign: 'center',
                      width: '2.5em',
                    }}
                  >
                    <Text style={{ margin: 0, padding: 0 }}>{candidate?.name[0]}{candidate?.surname[0]}</Text>
                  </Row>
                </Col>
                <Col>
                  <Row align="middle" justify="space-between" type="flex">
                    <Col style={{ maxWidth: '180px' }}>
                      <Text strong style={{ fontSize: '20px' }}>
                        {candidate?.name} {candidate?.surname}
                      </Text>
                    </Col>
                    {candidate?.candidate_type === 'passive' ? (
                      <Col>
                        <Icon
                          onClick={() => {
                            setAddPassiveCandidateVisibleMethod('PATCH');
                            handleAddPassiveCandidateVisible.on();
                          }}
                          style={{ fontSize: '20px' }}
                          type="edit"
                        />
                      </Col>
                    ) : null}
                  </Row>
                  {candidateTag[candidate?.candidate_type]}
                </Col>
              </Row>
            </Col>

            <Col style={{ marginBottom: '0.5em', marginTop: '1em' }}>
              <Text strong>Información de contacto</Text>
            </Col>
            <Col style={{ marginBottom: '0.5em' }}>
              <Row align="middle" type="flex">
                <Col>
                  <div
                    style={{
                      backgroundColor: '#FFFFFF',
                      border: '1px solid #F175A5',
                      borderRadius: '2px',
                      color: '#F175A5',
                      height: '24px',
                      marginRight: '0.5em',
                      textAlign: 'center',
                      width: '24px',
                    }}
                  >
                    <Icon style={{ color: '#F175A5' }} type="mail" />
                  </div>
                </Col>
                <Col>{candidate?.email ?? 'Sin correo registrado'}</Col>
              </Row>            
            </Col>
            <Col>
              <Row type="flex">
                <Col>
                  <div
                    style={{
                      backgroundColor: '#FFFFFF',
                      border: '1px solid #F175A5',
                      borderRadius: '2px',
                      color: '#F175A5',
                      height: '24px',
                      marginRight: '0.5em',
                      textAlign: 'center',
                      width: '24px',
                    }}
                  >
                    <Icon style={{ color: '#F175A5' }} type="phone" />
                  </div>
                </Col>
                <Col>
                  {candidateData?.passive_candidate?.phone
                    ? `${candidateData.passive_candidate.phone_prefix} ${candidateData.passive_candidate.phone}`
                    : candidateData?.personal_user?.phone
                      ? `${candidateData.personal_user.phone_prefix} ${candidateData.personal_user.phone}`
                      : 'Sin teléfono registrado'}
                </Col>
              </Row>            
            </Col>

            <Col style={{ marginBottom: '0.5em', marginTop: '1em' }}>
              <Text strong>CV</Text>
            </Col>
            <Col>
              <Row type="flex">
                <Col>
                  <a href={candidate?.cv} target='_blank'>
                    <Icon
                      style={{ color: candidate?.cv ? '#F175A5' : '#D9D9D9', fontSize: '24px' }}
                      type="file-pdf"
                    />
                  </a>
                </Col>
              </Row>            
            </Col>

            {candidate?.candidate_type === 'passive'
              ? (
                <>
                  <Col style={{ marginTop: '1em' }}>
                    <Text strong>Origen del candidato</Text>
                  </Col>
                  <Col>

                    {candidate?.source ? <Tag>{candidate.source}</Tag> : 'Sin información'}
                  </Col>

                  <Col style={{ marginTop: '1em' }}>
                    <Text strong>Redes sociales</Text>
                  </Col>
                  <Col>
                    Linkedin: {candidateData?.passive_candidate?.other_contact_info?.linkedin ?? 'Sin información'}
                  </Col>
                </>
              ) : null}

          </Row>
        </Col>
        <Col span={16} style={{ backgroundColor: '#FFFFFF', height: '700px', padding: '1em' }}>
          <Row type="flex">
            <Text strong style={{ fontSize: '18px' }}>
              Procesos en los que ha participado
            </Text>
          </Row>
          <Divider />
          <Row
            align="middle"
            justify="space-between"
            style={{ backgroundColor: '#FFF0F6', height: '80px', padding: '0px 20px', width: '100%' }}
            type="flex"
          >
            <Col span={16} style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Row align="middle" type="flex">
                <img
                  src="https://genoma-assets.s3.us-east-2.amazonaws.com/genomin_1.svg"
                  style={{ height: '5vh', marginRight: '10px', maxHeight: '50px' }}
                />
                <Text style={{ maxWidth: '80%', overflowWrap: 'break-word', whiteSpace: 'normal' }}>
                  {candidateData?.applications?.length
                    ? `El candidato ha participado en ${moreThanOne ? 'más de' : ''} un proceso de selección.`
                    : 'El candidato no forma parte de ningún proceso aún.'}
                </Text>
              </Row>
            </Col>
            <Col>
              {candidate?.candidate_type === 'passive'
                ? (
                  <Button
                    onClick={() => {
                      setAddPassiveCandidateVisibleMethod('PATCH');
                      handleAddPassiveCandidateToJobApplications.on();
                      handleAddPassiveCandidateVisible.on();
                    }}
                    type="primary"
                  >
                    Agregar a procesos
                  </Button>
                ) : null}
            </Col>
          </Row>
          {candidateData?.applications?.length
            ? (
              <>
                <Row style={{ marginTop: '2em' }} type="flex">
                  <Text strong>Selecciona el perfil que deseas ver</Text>
                </Row>
                <Row>
                  <Col span={24} style={{ height: '400px', marginBottom: '2em', overflowY: 'auto' }}>
                    {/* TODO: Refactor, make it component */}
                    {candidateData.applications.map((uja, key) => {
                      const stageName = uja.job_application_stages.find(stage => stage.code === uja.status);
                      return (
                        <Row align='middle' key={key} type='flex'>
                          <Col span={2}>
                            <Radio
                              checked={selectedUserJobApplication.id === uja.id}
                              onChange={() => setSelectedUserJobApplication(uja)}
                            />
                          </Col>
                          <Col span={22}>
                            <Card style={{ border: '1px solid #D9D9D9', marginTop: 16, width: '100%' }}>
                              <Meta
                                avatar={<ProcessStatusBadge status={uja.job_application_status} />}
                                title={<>{uja.job_application_name}</>}
                                description={
                                  <Row type='flex'>
                                    <Col span={12}>
                                      <Row>
                                        <Text disabled>Etapa</Text>
                                      </Row>
                                      <Row>
                                        <Text>{stageName.name}</Text>
                                      </Row>
                                    </Col>
                                    <Col span={12}>
                                      <Row>
                                        <Text disabled>Postulación</Text>
                                      </Row>
                                      <Row>
                                        <Text>
                                          {new Date(uja.date_started).toLocaleDateString('es-CL')}
                                        </Text>
                                      </Row>
                                    </Col>
                                  </Row>
                                }
                              />
                            </Card>
                          </Col>
                        </Row>
                      )})}
                  </Col>
                </Row>
                <Row justify="end" style={{ width: '100%' }} type="flex">
                  <Col style={{ marginRight: '1em' }}>
                    <Button onClick={onCancel}>Cancelar</Button>
                  </Col>
                  <Col>
                    <Button
                      disabled={!selectedUserJobApplication}
                      onClick={() => {
                        if (!selectedUserJobApplication.id) {
                          onCancel();
                          return;
                        }
                        handleShowProfile({ ...selectedUserJobApplication });
                        setSelectedUserJobApplication({});
                      }}
                      type="primary"
                    >
                      Ver perfil
                    </Button>
                  </Col>
                </Row>
              </>
            ) : null}
        </Col>
      </Row>
    </Modal>
  );
}

export default CandidateDetail;
