import React from 'react';
import { Form, Col, Row, InputNumber } from 'antd';

const IntForm = ({ getFieldDecorator }) => (
  <Row gutter={15}>
    <Col xs={24} sm={12}>
      <Form.Item label='Desde'>
        {getFieldDecorator('intFrom', {
        })(
          <InputNumber
            min={0}
            placeholder='0'
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          />
        )}
      </Form.Item>
    </Col>

    <Col xs={24} sm={12}>
      <Form.Item label='Hasta'>
        {getFieldDecorator('intTo', {
        })(
          <InputNumber
            placeholder='0'
            min={0}
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          />
        )}
      </Form.Item>
    </Col>
  </Row>
)

export default IntForm;