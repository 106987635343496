import React from "react";
import { Form, Input, Row, Col, Typography } from "antd";

const { Text } = Typography;

const DefaultForm = ({ getFieldDecorator }) => {
  const [value, setValue] = React.useState("");
  return (
    <Form.Item label='¿Cuál es tu pregunta?'>
      {getFieldDecorator("question", {
        rules: [{ required: true, message: "Por favor escribe tu pregunta" }],
      })(
        <Input
          onChange={({ target }) => setValue(target.value)}
          maxLength={250}
        />
      )}
      <Row type='flex' justify='end'>
        <Col span={2.5}>
          <Text disabled>{value.length}/250</Text>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default DefaultForm;
