import React from "react";
import moment from 'moment'

import {
  Avatar,
  Button,
  Col,
  Comment as AntdComment,
  Icon,
  Popconfirm,
  Row,
  Tooltip
} from 'antd';

import ParseHtml from "../../../../components/parseHtml";
import i18n from "../../../../i18n";
import '../../styles.scss'

const Comment = ({ 
  comment, 
  name,
  isChild = false,
  setCommentToDelete = undefined,
  setFather = undefined, 
  usersWithAccess = [] 
}) => {
  const {
    author, 
    date_created,
    deleted,
    files,
    public: isPublic,
    initials,
    parsed_text
  } = comment; 

  const styles = isChild ? {
    border: '1px solid #D9D9D9', 
    paddingLeft: 60,
    backgroundColor: "#FAFAFA"
  } : {
    border: '1px solid #D9D9D9', 
    paddingLeft: 20,
  }

  const dateToCurrentLocale = (date) => {
    const newDate = moment(date, 'DD-MM-YYYY HH:mm Zz').local().format('DD MMMM YYYY HH:mm');
    return newDate;
  }

  const actions = () => {
    const commentActions = []

    if (isChild || deleted) return commentActions;

    commentActions.push(
      <Button type="link" onClick={() => setFather(comment)}>
        <Icon type="message" style={{ color: "#828282", fontSize: 18 }}/>
      </Button>)

    if (author !== name) return commentActions;

    commentActions.push(
      <Popconfirm 
        title={i18n.t('profile__confirm_delete_comment', { ns: 'candidateProfile'})}
        onConfirm={() => setCommentToDelete(comment)}
      >
        <Button type="link">
          <Icon type="delete" style={{ color: "#828282", fontSize: 18 }}/>
        </Button>
      </Popconfirm>
    )
    return commentActions;
}

  const CommentAvatar = () => (
    <Avatar 
      size='large' 
      style={{ 
        backgroundColor: '#F5F5F5', 
        color: '#454545', 
        border: '1px solid #D9D9D9'
      }}
    >
      {initials}
    </Avatar>
  )

  const Attachment = () => (
    <p>
      <Icon type="paper-clip"/>
      <Button type="link" style={{ padding: 0 }} onClick={() => window.open(files.file)}>
        {files.name}
      </Button>
    </p>
  )

  const AuthorWithPrivacy = ({ author, usersWithAccess }) => (
    <div>
      {author}
      <Tooltip title={
        usersWithAccess === '' ? 
          i18n.t('profile__visible_for_me', { ns: 'candidateProfile' }) :
          i18n.t('profile__visible_for', { ns: 'candidateProfile', usersWithAccess })
        }>
        <Icon type="lock" style={{ marginLeft: 5, fontSize: 16 }} />
      </Tooltip>
    </div>
  )

  return (
    <Row type='flex' align='top' justify='center'>
      <Col xs={24}>
        <AntdComment
          style={styles}
          author={isPublic ? author : <AuthorWithPrivacy author={author} usersWithAccess={usersWithAccess} />}
          avatar={CommentAvatar()}
          content={
          <>
            {deleted ? 
              i18n.t('profile__deleted_comment', { ns: 'candidateProfile' }) : 
              ParseHtml(parsed_text)}{files && <Attachment />}
          </>}
          datetime={dateToCurrentLocale(date_created)}
          className="comment"
          actions={actions()}
        /> 
      </Col>
    </Row >
  );
};

export default Comment;