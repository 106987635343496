import { ResponsiveBar } from '@nivo/bar'

import { generateColorArray } from '../../../../../components/charts/utils';

const StackedBarChart = ({ customDirection, data }) => {
  const borderColors = ['#0050B3', '#BAE7FF']; 

  return (
    <ResponsiveBar
      data={data}
      keys={Object.keys(data[0]).filter(key => key !== 'id')} 
      indexBy="id"
      margin={{ top: 10, right: 50, bottom: 70, left: 200 }}
      padding={0.4}
      label={(d) => `${d.value}`}
      layout={customDirection}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={generateColorArray(borderColors, data ? Object.keys(data[0]).length : 0)}
      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      enableGridX
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Días',
        legendPosition: 'middle',
        legendOffset: 50
      }}
      axisLeft={null}
      labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'top-left',
          direction: 'column',
          justify: false,
          translateX: -180,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
    />
  )
}

export default StackedBarChart;