import React, { useContext, useEffect } from 'react';
import { Col, Row, Icon, Typography } from 'antd';
import { CandidateContext } from '../config/CandidateContext';

import useBoolean from '../../../hooks/useBoolean';
import ParseHtml from '../../../components/parseHtml';
import i18n from '../../../i18n';

const { Text, Title } = Typography;

const stateToDef = {
  sending: { name: i18n.t('profile__sending', { ns: 'candidateProfile'}), color: '#FFA940' },
  deferred: { name: i18n.t('profile__late', { ns: 'candidateProfile'}), color: '#FFA940' },
  bounce: { name: i18n.t('profile__bounced', { ns: 'candidateProfile'}), color: '#EA3B2E' },
  delivered: { name: i18n.t('profile__received', { ns: 'candidateProfile' }), color: '#597EF7' },
  nostate: { name: i18n.t('profile__not_available', { ns: 'candidateProfile' }), color: '#FFA940' },
  open: {name: i18n.t('profile__read', { ns: 'candidateProfile' }), color: '#73D13D' },
  dropped: { name: i18n.t('profile__dropped', { ns: 'candidateProfile' }), color: '#EA3B2E' },
};

const translationFile = {ns: 'candidateProfile'}

const adaptTextLength = (string, limit) => {
  return string.length > limit
    ? `${string.slice(0, limit)}...`
    : string.slice();
};

const CustomCard = ({ children, toRender, customClass }) => {
  return (
    <Row className={`emails-history-custom-card ${customClass}`}>
      {children}
      {toRender}
    </Row>
  );
};

CustomCard.Header = ({ children, fn }) => {
  return <Row className="emails-history-custom-card__header" onClick={fn}>{children}</Row>;
};

const Mail = ({ email, openedMail }) => {
  const toRender = email => (
    <>
      <Row className="candidate-modal-mails-history__mail__remitent">
        <Col>
          <Text strong>{i18n.t('profile__from', translationFile)}</Text>
          <Text>{email.email_from}</Text>
        </Col>
        <Col>
          <Text strong>{i18n.t('profile__sent_date', translationFile)}</Text>
          <Text>{email.date_sent}</Text>
        </Col>
      </Row>
      <Row className="candidate-modal-mails-history__mail__content">
        <Text strong>{i18n.t('profile__content', translationFile)}</Text>
        {ParseHtml(email.content)}
      </Row>
    </>
  );

  const [showBody, handleBody] = useBoolean(false);
  
  useEffect(() => {
    if (openedMail === email.id) {
      handleBody.on();
    } else {
      handleBody.off();
    }
  }, [email, openedMail]);

  return (
    <CustomCard showBody={showBody} toRender={showBody && toRender(email)}>
      <CustomCard.Header fn={handleBody.toggle}>
        <Text
          style={{
            background: stateToDef[email.status].color,
            color: '#FFFFFF',
            marginRight: '1rem',
          }}
        >
          {stateToDef[email.status].name}
        </Text>
        <Title level={4}>{adaptTextLength(email.subject, 80)}</Title>
        <Icon type={showBody ? 'up' : 'down'} />
      </CustomCard.Header>
    </CustomCard>
  );
};

function MailHistory({ openedMail }) {
  const {
    application: { email_history },
    fetchMailHistory,
    translationFile,
    tabKey,
  } = useContext(CandidateContext);

  useEffect(() => {
    if (tabKey === '5') {
      fetchMailHistory();
    }
  }, [tabKey])

  const adaptEmailsByDate = emails => {
    return emails?.reduce((acc, email) => {
      if (!acc[email.date_sent]) {
        acc[email.date_sent] = [{ ...email }];
      } else {
        acc[email.date_sent] = [...acc[email.date_sent], { ...email }];
      }
      return acc;
    }, {});
  };

  return (
    <Col className="candidate-modal-mails-history">
      {email_history?.length
        ? Object.entries(adaptEmailsByDate(email_history)).map(day => (
          <Row className="candidate-modal-mails-history__day" key={day[0]}>
            <Text>{day[0]}</Text>
            <Row>
              {day[1]?.map(email => (
                <Mail email={email} key={email.id} openedMail={openedMail}/>
              ))}
            </Row>
          </Row>
        )) : (
          <div className="empty-tab">
            <img src="https://genoma-assets.s3.us-east-2.amazonaws.com/box.svg" alt="empty box" />
            <Title level={4}>{i18n.t('commons__without_mails', translationFile).split(" ")[0]}</Title>
            <Text>{i18n.t('commons__without_mails', translationFile).split("!")[1]}</Text>
          </div>
        )}
    </Col>
  );
}

export default MailHistory;
