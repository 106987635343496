import { Alert, Card, Col, Icon, Layout, Row, Tooltip, Typography } from 'antd';

import BarChart from './BarChart';
import Funnel from './Funnel';
import PaCandidateGeomap from '../../components/Content/sections/PaCandidatesGeoMap';
import PieChart from '../../../../../components/charts/PieChart';
import React from 'react';
import StackedBarChart from './StackedBarChart';
import StageCards from './StageCards';
import Timeline from '../../components/Content/sections/Timeline';
import { countryCodeGeoMap } from '../../assets/utils';
import i18n from '../../../../../i18n';

const { Text } = Typography;

const TemplateCard = ({
  templateText,
  templateGraphic,
  graphicType,
  topic,
  title,
  text,
  actionButton,
  data,
  subdata,
  flexDirection,
  fontSize,
  iconType,
  height,
  translateX,
  translateY,
  customDirection,
  customMargin,
  question,
  noMargin = false,
  alert = null,
  extra = null,
}) => {
  const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

  const colors = { 'No recomendado': '#FF7875', 'Quizás': '#FFD666', 'Recomendado': '#95DE64' }
  const getColor = (pie) => colors[pie.id]

  const getGraphic = (graphicType) => {
    switch (graphicType) {
      case 'pieChart':
        return <>
          <Col xs={24}>
            <PieChart
              colors={topic === 'FIT' ? getColor : ['#0050B3', '#1166c1', '#2a7cce', '#4492d9', '#5fa8e3', '#7cbdec', '#9ad2f5', '#bae7ff']}
              dataSource={data}
              height={height}
              customMargin={customMargin}
              translateX={translateX}
              translateY={translateY}
              customDirection={customDirection}
            />
          </Col>
          {(topic === 'gender' || topic === 'gender-hired') && (
            <Col xs={24} style={{ height: '325px' }}>
              <Row
                type="flex"
                justify="center"
                style={{ margin: '30px 0 0 0' }}
              >
                <Text style={{ textAlign: 'center', padding: '0 20px' }}>
                  <span style={{ color: '#1890FF', fontSize: '40px' }}>
                    {text && text.count}
                  </span>
                  <br />
                  De tus candidatos {topic === 'gender-hired' && ' contratados/as '} declaran género:{' '}
                  <span style={{ fontWeight: 700 }}>
                    {text && text.gender}
                  </span>{' '}
                  en todos tus procesos.
                </Text>
              </Row>
            </Col>
          )}
        </>
      case 'geoMap':
        return <>
          <Col xs={24} lg={17}>
            <PaCandidateGeomap
              dataSource={data
                .filter((elem) => elem.value && elem.label)
                .map((elem) => ({
                  id: countryCodeGeoMap[elem.label.toLowerCase()],
                  name: capitalize(elem.label),
                  value: elem.value,
                }))}
              height={height}
            />
          </Col>
          {!alert && (
            <Col xs={24} lg={6} style={{ height: height }}>
              <Row
                type="flex"
                justify="center"
                align="middle"
                style={{ height: '100%' }}
              >
                <Text style={{ textAlign: 'center' }}>
                  La mayoría de tus evaluados declaran una nacionalidad
                  de:
                  <br />
                  <span style={{ color: '#1890FF', fontSize: '30px' }}>
                    {text &&
                      text.label &&
                      text.label[0].toUpperCase() + text.label.slice(1)}
                    {text && text.code !== '' && (
                      <img
                        src={`https://genoma-assets.s3.us-east-2.amazonaws.com/flags/${text.code}.svg`}
                        style={{ margin: '0 0 0 5px' }}
                      />
                    )}
                  </span>
                </Text>
              </Row>
            </Col>
            )}
          </>
      case 'bar':
        return <>
          <Col xs={24} lg={24} style={{ height: height }}>
            {question && (
              <Row
                type="flex"
                justify="center"
                align="middle"
                style={{ padding: '10px 10px 0 10px' }}
              >
                <Text>{question}</Text>
              </Row>
            )}
            <BarChart 
              customDirection={customDirection} 
              customMargin={customMargin} 
              data={data}
              topic={topic}
              text={text}
            />
          </Col>
        </>
      case 'timeline':
        return <Timeline data={data} topic={topic}/>
      case 'funnel':
        return <Funnel />
      case 'stagecards':
        return <StageCards data={data} />
      case 'stackedBar':
        return <Col xs={24} lg={24} style={{ height: 300 }}>
            <StackedBarChart data={data} customDirection={customDirection}/>
          </Col>
    }

  }

  return (
    <Card
      title={
        <Row type="flex" style={{ height: 24, width: '100%' }}>
          <Col xs={actionButton ? 16 : 24}>
            <Row type="flex" justify="start">
              {<span style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>{title}</span>}
            </Row>
          </Col>
          <Col xs={actionButton ? 8 : 0}>
            <Row
              type="flex"
              justify="end"
              align="top"
              style={{ maxHeight: '100%' }}
            >
              {actionButton}
            </Row>
          </Col>
        </Row>
      }
      style={{
        border: '1px solid #E5E5E5',
        marginBottom: !noMargin ? '20px' : '',
      }}
      extra={extra ? <Tooltip title={extra}><Icon type="info-circle"/></Tooltip> : null}
    >
      <Layout
        style={{
          backgroundColor: '#FFFFFF',
        }}
      >
        {templateText &&
          (data !== undefined && data !== null ? (
            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{
                padding: '15px',
                flexDirection: flexDirection ?? 'column',
                height: `${height - 42}px`,
              }}
            >
              <Text
                style={{
                  fontSize: fontSize ?? '40px',
                  fontWeight: 600,
                  color: '#1890FF',
                  textAlign: 'center',
                }}
              >
                {iconType && (
                  <Icon type={iconType} style={{ margin: '0 5px 0 0' }} />
                )}
                {data}
              </Text>
              {subdata && subdata !== '' ? (
                <Text
                  style={{
                    fontSize: 25,
                    color: '#1890FF',
                  }}
                >
                  {subdata}
                </Text>
              ) : null}
              <Text
                style={{
                  fontSize: '16px',
                  margin: '0 0 0 5px',
                  textAlign: 'center',
                }}
              >
                {text}
              </Text>
            </Row>
          ) : (
            <Text
              strong
              style={{ 
                display: 'flex', 
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%', 
                height: `${height - 42}px`,
            }}
            >
              {i18n.t('commons__no_information')}
            </Text>
          ))}
        {templateGraphic && (
          <Row type="flex" style={{ gap: '2rem' }}>
            {graphicType !== 'funnel' && (!data || Object.keys(data).length === 0 )? (
              <Text
                strong
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: 300
                }}
             >
               {i18n.t('commons__no_information')}
             </Text>
            ) : 
              getGraphic(graphicType)
            }
            {(alert && (data?.length > 0)) && (
              <Alert
                message={alert}
                type="info"
                showIcon={false}
                style={{ width: '100%' }}
              />
            )}
          </Row>
        )}
      </Layout>
    </Card>
  );
};

export default TemplateCard;
