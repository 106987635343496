import React, { useContext, useState, useEffect } from 'react';
import {
  Button,
  Divider,
  Col,
  Row,
  Input,
  Form,
  Modal,
  Progress,
  Tag,
  Typography,
  message,
} from 'antd';
import { CandidateContext } from '../../../config/CandidateContext';


import '../styles.scss';
import ParseHtml from '../../../../../components/parseHtml';
import i18n from '../../../../../i18n';

const { Paragraph, Text, Title } = Typography;

export const DetailLikert = ({
  answers,
  questions,
  sortedQuestions,
  scores,
  scoresConfig,
  description,
  dateCreated,
  type,
}) => {

  const { customTestSurveys, requestCuTeSurvey, permissions } =
    useContext(CandidateContext);

  const [hideAnswers, setHideAnswers] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalState, setModalState] = useState({
    email: '',
    name: '',
  });
  const [modalStateError, setModalStateError] = useState({
    email: false,
    name: false,
  });

  const answerComponent = (answerId, idx) => {
    const answerValue = answers[answerId];
    const question = questions[answerId];

    const answerValueLabel = question['options']?.find(
      (obj) => obj['value'] === answerValue
    )?.label;

    const {
      label: questionLabel,
    } = question;

    return (
      <Col
        span={24}
        style={{
          marginBottom: '2rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.25rem',
        }}
      >
        <div>
          <div style={{ display: 'flex', gap: '0.25rem' }}>
            {idx + 1}. {ParseHtml(questionLabel)}
          </div>
        </div>
        <div
          className='cute-answer'
        >
          <div
            className="cute-divider"
          />
          <div style={{ flexGrow: 1 }}>{ParseHtml(answerValueLabel)}</div>
        </div>

      </Col>
    );
  };

  return (
    <>
      {dateCreated && (
        <Row style={{ margin: '15px 0 15px 0' }}>
          <Text>
            Fecha de finalizacion: <strong>{dateCreated}</strong>
          </Text>
        </Row>
      )}

      {description && (
        <>
          <Title level={4}>Descripción</Title>
          <Divider />
          <Row style={{ marginBottom: 24 }}>
            <Col span={24}>{ParseHtml(description)}</Col>
          </Row>
        </>
      )}

      <Title level={4}>Resultados del evaluado</Title>
      <Divider />
      <Row type="flex" justify="center" align="middle">
        <Col span={8}>
          <Row type="flex" align="middle" style={{ flexDirection: 'column' }}>
            <Text strong style={{ margin: '0 0 20px 0' }}>
              {` Puntaje: ${scores.result}`}
            </Text>
            <Progress
              percent={Math.round(
                ((scores.result + scoresConfig.max_value) / (scoresConfig.max_value * 2)) * 100
              )}
              strokeColor="95DE64"
              strokeWidth={20}
              strokeLinecap="square"
              type="dashboard"
              gapDegree={126}
            />
          </Row>
        </Col>
        {
          /** 
        <Col span={16}>
          <SegmentComponent />
        </Col>
        */
        }
      </Row>

      <Row type="flex" justify="space-between" style={{ marginTop: '15px' }}>
        <Col>
          <Title level={4}>{i18n.t('profile__answers', { ns: 'candidateProfile' })}</Title>
        </Col>
        <Col>
          <Button onClick={() => setHideAnswers((oldState) => !oldState)}>
            {hideAnswers ? 'Mostrar respuestas' : 'Ocultar Respuestas'}
          </Button>
        </Col>
      </Row>

      {!hideAnswers && (
        <Row type="flex">
          {sortedQuestions.map((elem, idx) => {
            return (
              <Col span={24} key={idx}>
                {answerComponent(elem, idx)}
              </Col>
            );
          })}
        </Row>
      )}

      <Row type="flex" justify="space-between" style={{ marginTop: '40px' }}>
        <Col>
          <Title level={4}>Encuesta</Title>
        </Col>
        <Col>
          {
            (permissions.business_admin || permissions.role?.can_manage_userjobapplications) &&
            <Button onClick={() => setModalVisible(true)}>
              {'Solicitar Encuesta'}
            </Button>
          }
        </Col>
      </Row>
      <Divider />
      {
        customTestSurveys
          .filter(obj => obj.custom_test === scoresConfig.survey)
          .map((obj, idx) => {
            if (obj.status === 'PENDING') {
              return (
                <Row>
                  <Col span={12}>{obj.user_email}</Col>
                  <Col span={12}>{"Pendiente"}</Col>
                </Row>
              )
            }
            else if (obj.status === 'DONE') {
              return (
                <Row>
                  <Col span={8}>{obj.user_email}</Col>
                  <Col span={8}>{"Completo"}</Col>
                  {
                    // The model supports multiple answers, but should ALWAYS return only 1
                  }
                  <Col span={8}>{`Puntaje: ${obj.survey_answers[0]?.scores.result}`}</Col>
                </Row>
              )
            }
          }
          )
      }

      <Modal
        footer={[
          <Button onClick={() => setModalVisible(false)} type="secondary">
            Cancelar
          </Button>,
          <Button
            onClick={() => {
              const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              if (modalState.email === '' || modalState.name === '') {
                message.error('Se deben completar todos los campos');
                return;
              }
              else if (!modalState.email.toLowerCase().match(re)) {
                message.error('Mail formato inválido');
                return;
              }
              else {
                requestCuTeSurvey(modalState.email, modalState.name, scoresConfig.survey);
                setModalVisible(false);
              }
            }}
            type="primary">
            Enviar
          </Button>
        ]}
        onCancel={() => setModalVisible(false)}
        title={(<Text strong>Solicitar Encuesta</Text>)}
        visible={modalVisible}
      >
        <Paragraph style={{ marginBottom: 0 }}>
          {
            //Descripción modal
          }
        </Paragraph>

        <Row style={{ marginTop: '1em' }} type="flex">
          <Form>
            <Form.Item
              label={(<Text style={{ fontSize: '16px' }}>Email</Text>)}
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                }
              ]}
            >
              <Input
                onChange={e => {
                  const val = e.target.value;
                  setModalState(oldSate => ({ ...oldSate, email: val }))
                }
                }
              />
            </Form.Item>
            <Form.Item label={(<Text style={{ fontSize: '16px' }}>Nombre</Text>)}>
              <Input
                onChange={e => {
                  const val = e.target.value;
                  setModalState(oldSate => ({ ...oldSate, name: val }))
                }
                }
              />
            </Form.Item>
          </Form>
        </Row>
      </Modal>
    </>
  );
};

DetailLikert.propTypes = {};
export default DetailLikert;
