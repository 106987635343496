import React from 'react';

import BreadcrumPage from '../../../pdfCommons/Breadcrum';
import CommonHeader from '../../../pdfCommons/CommonHeader';
import FooterPage from '../../../pdfCommons/FooterPage';

const Page3 = ({ candidateInfo, jobName }) => {
  return (
    <main className='dat-pdf-modal__mainsection__page3'>
      <section className='dat-pdf-modal__mainsection__page3__header'>
        <BreadcrumPage candidateInfo={candidateInfo} jobName={jobName}/>
        <CommonHeader reportName={"Diccionario"}/>
      </section>
      <hr style={{ margin: '14px 0 20px 0' }} />

      <section className='dat-pdf-modal__mainsection__page3__question'>
        <h6>¿Cómo se calcula la distancia entre las palabras?</h6>
        <p>
          Utilizando algoritmos de NLP (Procesamiento del Lenguaje Natural) en conjunto con un corpus de español masivo, se configuran puntajes de “distancia” entre las palabras, establecidos en base a cuán seguido se usan éstas en contextos similares. Por ejemplo, existe una gran cercanía entre las palabras “gato” y “perro”, pero existe una mayor distancia entre “gato” y “pétalo”. Una mayor distancia implica una menor relación y, por tanto, un mayor puntaje.
        </p>
        <p>
          Si bien la lista de palabras proviene de un diccionario masivo de español, se excluyen algunas palabras, en general altamente específicas, con el objetivo de evitar inflar artificialmente la puntuación. Esto debido a que, por ejemplo, algunas palabras pueden ser tan poco comunes que el algoritmo no tiene la data suficiente para contextualizarlas correctamente, lo cual se traduce en un puntaje alto para la palabra sin importar su relación con el resto, lo cual va en contra del propósito del test.
        </p>
        <p>
          El puntaje general del test corresponde al promedio de todas las distancias (y, por tanto, puntajes), entre las palabras escritas por el evaluado.
        </p>
      </section>

      <section className='dat-pdf-modal__mainsection__page3__question' style={{ margin: '20px 0 0 0' }}>
        <h6>¿Bajo qué criterio se establecen los niveles de Bajo - Promedio - Alto?</h6>
        <p>
          En base a una muestra original de participantes, los niveles fueron construidos considerando la distribución de sus puntajes, considerando los conceptos de media y desviación estándar (o las distancias entre el puntaje de las personas y el promedio general del grupo).
        </p>
        <p>
          El nivel <strong>promedio</strong>, como su nombre lo indica, fue construido en base a los puntajes más comunes, concentrados en torno a los 73 y 83 puntos.
        </p>
        <p>
          Los niveles <strong>bajo y alto</strong>, por lo anterior, se plantearon para personas que escapaban de este rango abarcando, respectivamente, puntuaciones menores (bajo 73) y mayores (sobre 83) que aquellas vistas en el nivel promedio.
        </p>
      </section>

      <FooterPage />
    </main>
  );
}
 
export default Page3;