import React from 'react';
import {
  Row,
  Col,
  Button,
  Divider,
  Typography
} from 'antd';

const { Title, Text } = Typography;

const DetailResult = ({ showExplanation, setShowExplanation }) => {
  return (
    <Row type='flex'>
      <Col span={24}>
        <Row type='flex' justify='space-between' align='middle' style={{ margin: '40px 0 0 0' }}>
          <Title level={4} style={{ color: '#5E5E5E', margin: '0' }}>
            Detalle del resultado
          </Title>
          {
            !showExplanation ?
              (
                <Button
                  size='small'
                  onClick={() => setShowExplanation(true)}
                >
                  Ver explicación
                </Button>
              ) :
              (
                <Button
                  size='small'
                  onClick={() => setShowExplanation(false)}
                >
                  Ocultar explicación
                </Button>
              )
          }
        </Row>
      </Col>

      <Divider style={{ margin: '8px 0' }} />

      <Col span={24}>
        <Text>
          Por cada par de palabras (1 palabra del eje vertical y 1 del eje horizontal) escritas por el evaluado, se muestra el puntaje asociado a la distancia entre ellas. Palabras más distantes entre sí se traducen en un puntaje mayor, mientras que palabras más cercanas entre sí se traducen en un puntaje menor.
        </Text>
      </Col>
    </Row>
  );
}

export default DetailResult;